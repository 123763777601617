import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as settingsActions from '../actions/customizationSetttings.actions';

export interface CustomizationSettingsState {
    customizationSettings: any;
}

export const initialState: CustomizationSettingsState = {
    customizationSettings: {}
};

export const SettingsReducer = createReducer(initialState,
    on(settingsActions.SetSettings, (state: CustomizationSettingsState, {customizationSettings}) => ({...state, customizationSettings})),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: CustomizationSettingsState | undefined, action: Action): any {
    return SettingsReducer(state, action);
}

export const getCustomizationSettings = (state: CustomizationSettingsState) => state.customizationSettings;
