<div class="new-design-round-border flex-col p-4 flex text-sm pb-0">
  <div class="text-[#1C1C1C66]">
    {{ "Email Communication Language" | translate }}
  </div>
  <div class="flex items-center gap-6">
    <ng-select
      [items]="availableLanguages"
      bindLabel="name"
      bindValue="code"
      [clearable]="false"
      [searchable]="false"
      style="width: 36%"
      class="my-4 text-sm new-dropdown"
      [formControl]="communicationLanguage"
      placeholder="{{ 'Select Language' | translate }}"
      (change)="setLanguage()"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.name }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
</div>
