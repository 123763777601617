import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SalesService } from '../../sales/sales.service';

@Component({
  selector: 'app-handle-pos-payment-redirection',
  templateUrl: './handle-pos-payment-redirection.component.html',
  styleUrls: ['./handle-pos-payment-redirection.component.scss']
})
export class HandlePosPaymentRedirectionComponent implements OnInit {

  response;
  transactionStatus;

  constructor(
    private route: ActivatedRoute,
    private salesService: SalesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({transactionId = ''}) => {
      if(transactionId){
        this.spinner.show();
        this.salesService.checkPaymentStatus(transactionId)
        .subscribe(resp => {
          if(resp?.success){
            this.response = resp?.data
            this.transactionStatus = resp?.data?.transactionStatus;
            if(resp?.data?.transactionStatus === 'Approved') {
              setTimeout(() => {
                window.close();
              }, 5000);
            }
          }
          else {
            this.toastr.error(resp?.message)
          }
          this.spinner.hide()
        }, (err) => {
          this.toastr.error(err?.error?.message);
          this.spinner.hide()
        })
      }
    });
  }

  close() {
   window.close() 
  }

}
