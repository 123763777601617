import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateVendorComponent } from './components/create-vendor/create-vendor.component';
import { VendorsListComponent } from './components/vendors-list/vendors-list.component';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';


const routes: Routes = [
    {
    path: '',
    component: VendorsListComponent
   },
   {
    path: 'vendors-list',
    component: VendorsListComponent
   },
   {
    path: 'create-vendor',
    component: CreateVendorComponent
   },
];

@NgModule({
  declarations: [
    VendorsListComponent,
    CreateVendorComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    Ng2SearchPipeModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class VendorsModule { }
