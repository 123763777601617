import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { DiscountCodeService } from '../../services/discount-code.service';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';

@Component({
  selector: 'app-pos-discount-management',
  styleUrls: ['pos-discount-management.component.scss'],
  templateUrl: './pos-discount-management.component.html',
})
export class POSDiscountManagement implements OnInit {
  showCreate = false;
  showView = false;
  tableHeadings = [
    'Discount Code',
    'Discount Value',
    'Usage Limit',
    'Customer Limit',
    'Total Times Used',
    'Status',
    'Action',
  ];
  tableKeys = ['code', 'discountValue', 'discountCount', 'customerUsageCount'];
  tableData: any = [];
  businessId: any;
  business$: Observable<any>;
  codeDetails: any;
  constructor(
    private discountCodeService: DiscountCodeService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private translateService: TranslateService,
    private rootStore: Store<RootReducerState>
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
  }
  ngOnInit() {
    this.loadBusiness();
  }
  loadBusiness(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId) {
        this.businessId = business?.businessId._id;
        this.loadDiscountCodes(this.businessId);
      }
    });
  }
  changeStatus(item: any) {
    const payload = {
      isActive: item.isActive,
    };
    this.spinner.show();
    this.discountCodeService.updateDiscountCode(item._id, payload).subscribe({
      next: (response) => {
        this.spinner.hide();
        this.toast.success(
          this.translateService.instant(
            'Discount code status updated successfully'
          )
        );
        console.log(response);
      },
      error: (error) => {
        this.spinner.hide();
        console.log(error);
        item.isActive = !item.isActive;
      },
    });
  }
  openEditModal(codeDetails: any) {
    this.codeDetails = codeDetails;
    this.showCreate = true;
  }

  openShowModal(codeDetails) {
    this.codeDetails = codeDetails;
    this.showView = true;
  }
  loadDiscountCodes(businessId: any) {
    this.spinner.show();
    this.discountCodeService.getDiscountCodes(businessId).subscribe({
      next: (response) => {
        this.spinner.hide();
        this.tableData = response.data;
        console.log(response);
      },
      error: (error) => {
        this.spinner.hide();
        console.log(error);
      },
    });
  }
}
