import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as ProductsActions from '../actions/product.action';

export interface ProductsState {
    products: Array<any>;
}

export const initialState: ProductsState = {
    products: []
};

export const ProductsReducers = createReducer(initialState,
    on(ProductsActions.SetProducts, (state: ProductsState, {products}) => ({...state, products})),
    on(ProductsActions.AddProduct, (state: ProductsState, {product}) => ({...state, products: [...state.products, {...product}]})),
    on(ProductsActions.UpdateProduct, (state: ProductsState, {product}) => {
        const mappedVendors = state.products.map((ogCustomer) => {
            if (ogCustomer._id === product._id) { return {...product}; }
            return ogCustomer;
        });
        return { ...state, vendors: [...mappedVendors] };
    }),
    on(ProductsActions.DeleteProduct, (state: ProductsState, {_id}) => {
        const filteredVendors = state.products.filter(vendor => vendor._id !== _id);
        return { ...state, products: [...filteredVendors] };
    }),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: ProductsState | undefined, action: Action): any {
    return ProductsReducers(state, action);
}

export const getProducts = (state: ProductsState) => state.products;
