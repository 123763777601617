import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NumplusContainerComponent } from './numplus-container.component';
import { FinancedashboardComponent } from './components/financedashboard/financedashboard.component';
import { SalesdashboardComponent } from './components/salesdashboard/salesdashboard.component';
import { CashflowdashboardComponent } from './components/cashflowdashboard/cashflowdashboard.component';
import { BudgetListComponent } from './components/budget/budget-list/budget-list.component';

const routes: Routes = [
  {
    path:'',
    component:NumplusContainerComponent,
    children:[
      {
        path:'finance-dashboard',
        component:FinancedashboardComponent
      },
      {
        path:'sales-dashboard',
        component:SalesdashboardComponent
      },
      {
        path:'cash-flow-dashboard',
        component: CashflowdashboardComponent
      },
      {
        path: 'budget',
        loadChildren: () => import('./components/budget/budget.module').then((m) => m.BudgetModule)
      },
    ]


  }
];


@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class NumplusRoutingModule { }
