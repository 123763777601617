import { Component, OnInit } from '@angular/core';
import { GlobalTimerService } from './global-timer.service';
import { GlobalTimerDto } from './global-timer.dto';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { of, Subject } from 'rxjs';

@Component({
  selector: 'app-global-timer',
  templateUrl: './global-timer.component.html',
  styleUrls: ['./global-timer.component.scss']
})
export class GlobalTimerComponent implements OnInit {
  
  timer: GlobalTimerDto;
  totalWork: number;
  unsubscribe$ = new Subject()
  constructor(private globalTimerService: GlobalTimerService) { }

  ngOnInit(): void {
    this.globalTimerService.timer$
      .pipe(
        tap((data) => {
          this.timer = data;
        }),
        catchError((error) => {
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()

      this.globalTimerService.totalWork$
      .pipe(
        tap((data) => {
          this.totalWork = data;
        }),
        catchError((error) => {
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe()
  }

}
