import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class LanguageService {
    storedLanguage: BehaviorSubject<string> = new BehaviorSubject<string>(localStorage.getItem('NuMetric|lang'));

  constructor(private httpService: HttpService,
              private translateService: TranslateService) { }

  setLanguage(language): void{
    this.storedLanguage.next(language);
  }
  getLanguage(): Observable<string>{
    return this.storedLanguage.asObservable();
  }

  translate( translationObject): any {
    let object = {...translationObject};
    for( const obj in object){
      object = {...object, [obj]:this.translateService.instant(object[obj])}
    }
    console.log(object);
    return object;
    
    // let objectKeysArray: any = Object.keys(object);
    // return objectKeysArray.forEach(key => ({...{[key]:this.translateService.instant(object[key])}}))
    // console.log(objectKeysArray);
    // return objectKeysArray;
    
    // return this.translateService.get('Test')
    // .pipe(map(() => {
    //   objectKeysArray.forEach((key) => { object[key] = this.translateService.instant(object[key]); });
    //   return object;
    // }));
  }

  translate2(initialKey, translateObject): any {
    const object = {...translateObject};
    Object.keys(object).forEach((key) => {
      if (typeof(object[key]) === 'object') {
        Object.keys(object[key]).forEach((keyLevel2) => {
          object[key][keyLevel2] =  this.translateService.instant(`${initialKey}${object[key][keyLevel2]}`);
        });
      } else {
        object[key] =  this.translateService.instant(`${initialKey}${key}`);
      }
    });
    return object;
  }

  translateArray(array: [] | any): any {
    return array.map((item: any) => {
      if(item && typeof(item) === 'string'){
        item = this.translateService.instant(item);
      }
      else {
        for(let key in item){
          if(item[key] && typeof(item[key]) === 'string'){
            item[key] = this.translateService.instant(item[key]);
          }
        }
      }
      return item
    }) 
  }

}
