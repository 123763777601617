<div class="w-full flex flex-col">
    <div class="ps-10 h-28 flex items-center mb-8 bg-gray-100" >
        <img src="assets/images/logo.png" class="m-4 ms-6 w-[150px]" alt="Logo">
    </div>
    <div class="flex-1 flex items-center justify-center " >
        <div class="shadow-md rounded p-8 w-3/5 space-y-3 mobile-select" >
            <h3 class="text-2xl Poppins-Bold text-center" >Select any one business to continue. </h3>
            <div class="border rounded text-xl p-4 flex justify-between items-center" *ngFor='let business of businesses; let i = index; trackBy: trackByFn text-md ' >
                <span>
                    {{business?.businessId?.companyName}}
                </span>
                <app-button type='primary' buttonText='Select' (click)='selectBusiness(business?.businessId)' ></app-button>
            </div>
        </div>
    </div>
</div>