<div class="h-full w-full flex flex-col">
  <ul
    class="hidden mb-4 text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex"
  >
    <li
      class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
      (click)="onTabChange('invoice')"
      [ngClass]="{
        'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]': selectedTab === 'invoice'
      }"
      tabindex="1"
    >
      <div
        class="inline-block w-full p-4 rounded-s-lg focus:ring-4 focus:ring-blue-300 focus:outline-none"
      >
        {{ "Invoice Customization" | translate }}
      </div>
    </li>
    <li
      class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
      (click)="onTabChange('pos')"
      [ngClass]="{
        'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]': selectedTab === 'pos'
      }"
      tabindex="2"
    >
      <div
        class="inline-block w-full p-4 focus:ring-4 focus:ring-blue-300 focus:outline-none"
      >
        {{ "POS & Cash Invoice Settings" | translate }}
      </div>
    </li>
    <li
      class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer"
      (click)="onTabChange('discountCode')"
      [ngClass]="{
        'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF] ':
          selectedTab === 'discountCode'
      }"
      tabindex="3"
    >
      <div
        class="inline-block w-full p-4 border-s-0 border-gray-200 rounded-e-lg focus:ring-4 focus:outline-none focus:ring-blue-300"
      >
        {{ "POS Discount Code Management" | translate }}
      </div>
    </li>
  </ul>

  <!-- OUTLET -->
  <div [ngSwitch]="selectedTab" class="h-[95%]">
    <app-invoice-customization *ngSwitchCase="'invoice'">
    </app-invoice-customization>
    <app-pos-cash-invoice *ngSwitchCase="'pos'"> </app-pos-cash-invoice>
    <app-pos-discount-management
      *ngSwitchCase="'discountCode'"
    ></app-pos-discount-management>
  </div>
</div>
