import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { UserManagementService } from '../../user-management.service';

@Component({
  selector: 'app-choose-user-role',
  templateUrl: './choose-user-role.component.html',
  styleUrls: ['./choose-user-role.component.scss']
})
export class ChooseUserRoleComponent implements OnInit {
  companyName: string = '';
  constructor(private userManagementService:UserManagementService) {
  }

  ngOnInit(): void {
    this.userManagementService.companyName.subscribe(name => {
      if(name){
        this.companyName = name;
      }
    })
  }

}
