import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { RecurringBillService } from '../../recurring-bill.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import * as moment from 'moment';

@Component({
  selector: 'app-list-recurring-bill',
  templateUrl: './list-recurring-bill.component.html',
  styleUrls: ['./list-recurring-bill.component.scss']
})
export class ListRecurringBillComponent implements OnInit, OnDestroy {

  tableData = [];
  tableKeys = [
    'recurringBillNumber',
    'vendorName',
    'createdAt',
    'startDate',
    'endDate',
    'billCount',
    'totalAmount',
  ];
  tableHeadings = [
    'Recurring Bill Number',
    'Vendor Name',
    'Created At',
    'Start Date',
    'End Date',
    'Bills Created',
    'Total Amount',
    'Status',
    'Actions'
  ];
  vendors = [];
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  projects = [];
  businessId = null;

  unsubscribe$ = new Subject();
  business$: Observable<any>;
  vendors$: Observable<any>;
  projects$: Observable<any>;


  constructor(
    private router: Router,
    private store: Store<RootReducerState>,
    private billService: RecurringBillService,
    private numberService: NumberService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private purchaseService: PurchasesService
  ) {
    this.vendors$ = store.pipe(select(selectedVendors));
    this.projects$ = this.store.pipe(select(selectProjects));
    this.business$ = store.pipe(select(selectBusiness));
   }

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadNumberConfig();
    this.loadVendors();
    this.loadProjects();
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$))
    .subscribe(business => {
      if(business?.businessId){
        this.businessId = business?.businessId?._id;
        this.loadBills();
      }
    })
  }

  getCurrencyDetails():void {
    this.purchaseService.currencyDetails
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((details: any)=>{
      if(details)
      this.currencyDetails = details;
    })
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  loadVendors(): void {
    this.vendors$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((vendors) => {
      this.vendors = vendors;
    },(err)=>{
      this.toastr.error(this.translateService.instant(err?.error?.message ??'Something went wrong!'));
    });
  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((projects) => {
      this.projects = projects;
    },(err)=>{
      this.toastr.error(this.translateService.instant(err?.error?.message ?? 'Something went wrong!'));
    });
  }

  loadBills(): void {
    this.spinner.show();
    this.billService.getRecurringBillsList(this.businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.tableData = this.mapDataForTable(resp?.data);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  mapDataForTable(bills) {
    return bills?.map(bill => {
      return {
        _id: bill?._id,
        recurringBillNumber: bill?.recurringBillNumber,
        vendorName: bill?.vendorDetails?.vendorName,
        createdAt: moment(bill?.createdAt).format('DD-MM-YYYY'),
        startDate: moment(bill?.startDate).format('DD-MM-YYYY'),
        endDate: moment(bill?.endDate).format('DD-MM-YYYY'),
        billCount: bill?.billCount ?? 0,
        isCanceled: bill?.isCanceled,
        isEnded: bill?.isEnded,
        totalAmount: bill?.totalAmount ?? 0
      }
    })
  }

  viewBill(id: string): void {
    console.log(id);
    
    this.router.navigate(['/purchases/recurring-bill/view-template'], { queryParams: { id }})
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
