import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
    providedIn: 'root'
})

export class ReceiptService {
    constructor(private httpService: HttpService) { }

    getReceiptById(id): Observable<any> {
        return this.httpService.get(`/receipt/getRecieptById/${id}`);
    }

    sendEmail(data): Observable<any> {
        return this.httpService.post('/receipt/shareReciept', data);
    }

    createReceiptPdf(data): Observable<any> {
        return this.httpService.postPdf('/receipt/createPdf', data);
    }
}
