import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {
  constructor(private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private store: Store<RootReducerState>,
              private rootStore: Store<RootReducerState>,
              private accountServices: AccountingService){
                this.business$ = this.rootStore.pipe(select(selectBusiness));
                this.accounts$ = this.store.pipe(select(selectAccounts));
              }
  openAccountForm = false;
  update = false;
  accounts$: Observable<any>;
  unsubscribe$ = new Subject();
  business$: Observable<any>;
  businessId = null;
  accountTypesBySection;
  accountToUpdate = null;
  data;
  accountEditData;

  defaultAccounts = [
    {
      accountType:"Cash and Bank",
      accountSection:"Assets",
      accountName:"Cash on Hand",
      accountId:null,
      description: "Cash you haven’t deposited in the bank. Add your bank and credit card accounts to accurately categorize transactions that aren't cash.",
      currency:"USD",
      editable: true
    },
    {
      accountType:"Expected Payments from Customers",
      accountSection:"Assets",
      accountName:"Accounts Receivable",
      accountId:null,
      description: null,
      currency:null,
      editable: false
    },
    {
      accountType: 'Property, Plant, Equipment',
      accountSection: 'Assets',
      accountName: 'Real estate',
      accountId: null,
      description: null,
      currency: null,
      editable: false,
    },
    {
      accountType: 'Property, Plant, Equipment',
      accountSection: 'Assets',
      accountName: 'Equipment',
      accountId: null,
      description: null,
      currency: null,
      editable: false,
    },
    {
      accountType: 'Property, Plant, Equipment',
      accountSection: 'Assets',
      accountName: 'Office furniture',
      accountId: null,
      description: null,
      currency: null,
      editable: false,
    },
    {
      accountType: 'Property, Plant, Equipment',
      accountSection: 'Assets',
      accountName: 'Computers',
      accountId: null,
      description: null,
      currency: null,
      editable: false,
    },
  
    {
      accountType: 'Property, Plant, Equipment',
      accountSection: 'Assets',
      accountName: 'Vehicles',
      accountId: null,
      description: null,
      currency: null,
      editable: false,
    },
  
    {
      accountType: 'Depreciation and Amortization',
      accountSection: 'Assets',
      accountName: 'Vehicles - Accumulated Depreciation',
      accountId: null,
      description: null,
      currency: null,
      editable: false
    },
  
    {
      accountType: 'Depreciation and Amortization',
      accountSection: 'Assets',
      accountName: 'Real estate - Accumulated Depreciation',
      accountId: null,
      description: null,
      currency: null,
      editable: false
    },
    {
      accountType: 'Depreciation and Amortization',
      accountSection: 'Assets',
      accountName: 'Equipment - Accumulated Depreciation',
      accountId: null,
      description: null,
      currency: null,
      editable: false
    },
    {
      accountType: 'Depreciation and Amortization',
      accountSection: 'Assets',
      accountName: 'Computers - Accumulated Depreciation',
      accountId: null,
      description: null,
      currency: null,
      editable: false
    },
    
    {
      accountType:"Expected Payments from Customers",
      accountSection:"Assets",
      accountName:"Accounts Receivable",
      accountId:null,
      description: null,
      currency:null,
      editable: false
    },
    {
      accountType:"Expected Payments to Vendors",
      accountSection:"Liablities",
      accountName:"Accounts Payable",
      accountId:null,
      description: null,
      currency:null,
      editable: false
    },
    {
      accountType:"Due For Payroll",
      accountSection:"Liablities",
      accountName:"Payroll Liabilities",
      accountId:null,
      description: "The total amount you owe for your payroll. This includes wages due to employees and payroll taxes owed to the government.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Income",
      accountSection:"Income",
      accountName:"Sales",
      accountId:null,
      description: "Payments from your customers for products and services that your business sold.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Uncategorized Income",
      accountSection:"Income",
      accountName:"Uncategorized Income",
      accountId:null,
      description: "Income you haven't categorized yet. Categorize it now to keep your records accurate.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Gain On Foreign Exchange",
      accountSection:"Income",
      accountName:"Gain on Foreign Exchange",
      accountId:null,
      description: "Foreign exchange gains happen when the exchange rate between your business's home currency and a foreign currency transaction changes and results in a gain. This can happen in the time between a transaction being entered in Wave and being settled, for example, between when you send an invoice and when your customer pays it. This can affect foreign currency invoice payments, bill payments, or foreign currency held in your bank account.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Accounting Fees",
      accountId:null,
      description: "Accounting or bookkeeping services for your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Advertising & Promotion",
      accountId:null,
      description: "Advertising or other costs to promote your business. Includes web or social media promotion.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Bank Service Charges",
      accountId:null,
      description: "Fees you pay to your bank like transaction charges, monthly charges, and overdraft charges.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Computer – Hardware",
      accountId:null,
      description: "Desktop or laptop computers, mobile phones, tablets, and accessories used for your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Computer – Hosting",
      accountId:null,
      description: "Fees for web storage and access, like hosting your business website or app.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Computer – Internet",
      accountId:null,
      description: "Internet services for your business. Does not include data access for mobile devices.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Computer – Software",
      accountId:null,
      description: "Apps, software, and web or cloud services you use for business on your mobile phone or computer. Includes one-time purchases and subscriptions.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Depreciation Expense",
      accountId:null,
      description: null,
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Dues & Subscriptions",
      accountId:null,
      description: "Fees or dues you pay to professional, business, and civic organizations. Does not include business licenses and permits or business memberships.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Equipment Lease or Rental",
      accountId:null,
      description: "Rent or lease of machinery or equipment used to conduct business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Insurance – Vehicles",
      accountId:null,
      description: "Insurance for the vehicle you use for business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Interest Expense",
      accountId:null,
      description: "Interest your business pays on loans and other forms of debt, including business loans, credit cards, mortgages, and vehicle payments.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Meals and Entertainment",
      accountId:null,
      description: "Food and beverages you consume while conducting business, with clients and vendors, or entertaining customers.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Office Supplies",
      accountId:null,
      description: "Office supplies and services for your business office or space.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Postage & Delivery",
      accountId:null,
      description: "Shipping, postage, or courier fees for your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Professional Fees",
      accountId:null,
      description: "Fees you pay to consultants or trained professionals for advice or services related to your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Rent Expense",
      accountId:null,
      description: "Costs to rent or lease property or furniture for your business office space. Does not include equipment rentals.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Repairs & Maintenance",
      accountId:null,
      description: "Repair and upkeep of property or equipment, as long as the repair doesn't add value to the property. Does not include replacements or upgrades.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Telephone – Land Line",
      accountId:null,
      description: "Land line phone services for your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Telephone – Wireless",
      accountId:null,
      description: "Mobile phone services for your business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Travel Expense",
      accountId:null,
      description: "Transportation and travel costs while traveling for business. Does not include daily commute costs.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Vehicle – Fuel",
      accountId:null,
      description: "Gas and fuel costs when driving for business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Operating Expense",
      accountSection:"Expenses",
      accountName:"Vehicle – Repairs & Maintenance",
      accountId:null,
      description: "Repairs and preventative maintenance of the vehicle you drive for business.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Cost of Goods Sold",
      accountSection:"Expenses",
      accountName:"Subcontracted Services",
      accountId:null,
      description: "Fees paid to a subcontractor that you hired to perform a specific task as part of an overall project.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Payroll Expense",
      accountSection:"Expenses",
      accountName:"Payroll Employer Taxes",
      accountId:null,
      description: "Payroll taxes paid by the employer based on employee wages. This doesn’t include taxes withheld from employees.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Payroll Expense",
      accountSection:"Expenses",
      accountName:"Payroll Gross Pay",
      accountId:null,
      description: "The total amount of salaries and wages that your employees have earned and you’ve paid through payroll.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Payroll Expense",
      accountSection:"Expenses",
      accountName:"Payroll – Employee Benefits",
      accountId:null,
      description: "Federal and provincial/state deductions taken from an employee's pay, like employment insurance. These are usually described as line deductions on the pay stub.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Payroll Expense",
      accountSection:"Expenses",
      accountName:"Payroll – Employer's Share of Benefits",
      accountId:null,
      description: "The portion of federal and provincial/state obligations your business is responsible for paying as an employer.	",
      currency:null,
      editable: true,
    },
    {
      accountType:"Payroll Expense",
      accountSection:"Expenses",
      accountName:"Payroll – Salary & Wages",
      accountId:null,
      description: "Wages and salaries paid to your employees.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Loss On Foreign Exchange",
      accountSection:"Expenses",
      accountName:"Loss on Foreign Exchange",
      accountId:null,
      description: "Foreign exchange losses happen when the exchange rate between your business's home currency and a foreign currency transaction changes and results in a loss. This can happen in the time between a transaction being entered in Wave and being settled, for example, between when you send an invoice and when your customer pays it. This can affect foreign currency invoice payments, bill payments, or foreign currency held in your bank account.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Uncategorized Expense",
      accountSection:"Expenses",
      accountName:"Uncategorized Expense",
      accountId:null,
      description: "A business cost you haven't categorized yet. Categorize it now to keep your records accurate.",
      currency:null,
      editable: false,
    },
    {
      accountType:"Business Owner Contribution and Drawing",
      accountSection:"Equity",
      accountName:"Owner Investment / Drawings",
      accountId:null,
      description: "Owner investment represents the amount of money or assets you put into your business, either to start the business or keep it running. An owner's draw is a direct withdrawal from business cash or assets for your personal use.",
      currency:null,
      editable: true,
    },
    {
      accountType:"Retained Earnings: Profit",
      accountSection:"Equity",
      accountName:"Owner's Equity",
      accountId:null,
      description: "Owner's equity is what remains after you subtract business liabilities from business assets. In other words, it's what's left over for you if you sell all your assets and pay all your debts.",
      currency:null,
      editable: true,
    },
  ]

  ngOnInit(): void {
    this.loadType();
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id)
      this.businessId = business.businessId._id;
    });
  }

  loadType(): void {
    this.route.params.subscribe((params) => {
      const accounts = loadAccountsOf(params.type);
      this.accounts$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((accountsFromStore) => {
        accounts.forEach(type => {
          type.accounts = accountsFromStore.filter((account) => 
            account.accountSection.toLowerCase() === type.section.toLowerCase() &&
            account.accountType === type.accountType
          );
        });
      });
      this.accountTypesBySection = accounts;
    });
  }

  addNewAcount(type?): void {
    this.openAccountForm = true;
    this.update = false;
    if (type) {
      this.data = { accountType: type.accountType, section: type.section };
    }
  }

  editAccount(data): void {
    this.update = true;
    this.openAccountForm = true;
    this.accountEditData = data;
    this.accountToUpdate = data._id;
  }

  handleAccountFormValue(formValue): void {
    if (formValue.update) {
      const body = {
        ...formValue.data,
        _id: this.accountToUpdate
      }
      this.updateAccount(body);
      this.update = false;
    } else {  
      const body = {
        ...formValue.data,
        businessId: this.businessId
      }
      this.addAccount(body);
    }
  }

  updateAccount(body): void {
    this.spinner.show();
    this.accountServices.updateAccounts(body).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        this.accountServices.updateAccountInStore(body);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  addAccount(body): void {
    this.spinner.show();
    this.accountServices.createAccounts(body).subscribe(
      (resp) => {
        this.spinner.hide();
        this.accountServices.addAccountInStore(resp.data);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

}
