import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-register-hours-form',
  templateUrl: './register-hours-form.component.html',
  styleUrls: ['./register-hours-form.component.scss'],
})
export class RegisterHoursFormComponent implements OnInit {
  constructor() {}
  @Input() registerHourForm: FormGroup;
  @Input() projects: any[] = [];
  @Input() customers: any[] = [];
  @Input() tasks: any[] = [];
  @Input() min: string= '';
  @Input() max: string= '';
  @Input() isTimer: boolean = true;
  @Input() disabled: boolean = false;
  @Input() formErrors: any;

  formId = 'registrationForm';

  ngOnInit(): void {}

}
