<div class="grid gap-6">
  <div class="grid grid-cols-3 gap-x-6">
    <!-- CURRENT PLAN -->
    <div class="p-6 rounded-lg bg-input-light flex flex-col">
      <div class="flex flex-col">
        <div class="text-sm Poppins-Medium">
          {{ "Current Plan" | translate }}
        </div>
        <ng-container *ngIf="currentTransactionSubscription">
          <div class="text-xxs font-medium flex">
            <span class="text-xxs text-[#1C1C1C66] mr-1">{{
              "Renews" | translate
            }}</span>
            {{ currentTransactionSubscription?.expiryDate | date }}
          </div>
        </ng-container>
      </div>
      <ng-container *ngIf="!currentTransactionSubscription && starterPlan">
        <div class="text-xxs font-medium flex">
          <span class="text-xxs text-[#1C1C1C66] mr-1">{{
            "Renews" | translate
          }}</span>
          {{ starterPlan?.expiryDate | date }}
        </div>
        <app-progress-bar
          [outerClass]="'mt-2 mb-2'"
          [progress]="transactionPercentage"
          [className]="'h-1 bg-[#6172F3] rounded-[80px]'"
        ></app-progress-bar>
        <div class="flex justify-between items-center text-xxs">
          <div>
            <span class="mr-1">
              {{
                starterPlan?.transactionLimit - currentUsage?.transactionLimit
              }}
            </span>
            <span class="text-[#1C1C1C66]">
              {{ "Remaining Transactions" | translate }}
            </span>
          </div>
          <span> {{ transactionPercentage.toFixed(2) }}% </span>
        </div>
      </ng-container>
      <ng-container *ngIf="currentTransactionSubscription">
        <app-progress-bar
          [outerClass]="'mt-2 mb-2'"
          [progress]="100 - transactionPercentage"
          [className]="'h-1 bg-[#6172F3] rounded-[80px]'"
        ></app-progress-bar>
        <div class="flex justify-between items-center text-xxs">
          <div>
            <span class="mr-1">
              {{ remainingTransactionLimit }}
            </span>
            <span class="text-[#1C1C1C66]">
              {{ "Remaining Transactions" | translate }}
            </span>
          </div>
          <span> {{ transactionPercentage.toFixed(2) }}% </span>
        </div>
      </ng-container>
      <ng-container *ngIf="currentStorageSubscription; else noStorage">
        <app-progress-bar
          [outerClass]="'mt-2 mb-2'"
          [progress]="100 - storagePercentage"
          [className]="'h-1 bg-[#F63D68] rounded-[80px]'"
        ></app-progress-bar>
        <div class="flex justify-between items-center text-xxs">
          <div>
            <span class="mr-1"> {{ storageLimit.toFixed(2) }}GB </span>
            <span class="text-[#1C1C1C66]">
              {{ "Remaining Storage" | translate }}
            </span>
          </div>
          <span> {{ storagePercentage.toFixed(2) }}% </span>
        </div>
      </ng-container>
      <ng-template #noStorage>
        <div class="mt-9 flex text-xxs items-center justify-center">
          {{ "No Storage Plan" | translate }}
        </div>
      </ng-template>
    </div>
    <!-- USER SEATS -->
    <div class="flex flex-col gap-[10px]">
      <div
        class="bg-black rounded-lg flex p-3 text-sm text-[#FFFFFFDB] justify-center items-center h-11 gap-10"
      >
        {{ "Go further with NuMetric" | translate }}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-7 h-7"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#ffffff"
        >
          <path
            d="M220-464 64-620l156-156 156 156-156 156ZM360-80v-200q-61-5-121-14.5T120-320l20-80q84 23 168.5 31.5T480-360q87 0 171.5-8.5T820-400l20 80q-59 16-119 25.5T600-280v200H360ZM220-576l44-44-44-44-44 44 44 44Zm260-104q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0 280q-33 0-56.5-23.5T400-480q0-33 23.5-56.5T480-560q33 0 56.5 23.5T560-480q0 33-23.5 56.5T480-400Zm0-360q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Zm202 280-68-120 68-120h136l68 120-68 120H682Zm46-80h44l22-40-22-40h-44l-22 40 22 40Zm-508-60Zm260-180Zm270 200Z"
          />
        </svg>
      </div>
      <div class="p-6 rounded-lg bg-input-light flex flex-col h-full">
        <div class="flex flex-col">
          <div class="text-sm Poppins-Medium">
            {{ "User Seats" | translate }}
          </div>
        </div>
        <app-progress-bar
          [outerClass]="'mt-[22px] mb-2'"
          [progress]="invitePercentage"
          [className]="'h-1 bg-[#F63D68] rounded-[80px]'"
        ></app-progress-bar>
        <div class="flex justify-between items-center text-xxs">
          <div>
            <span class="mr-1"> {{ remainingUser }}/{{ totalUser }} </span>
            <span class="text-[#1C1C1C66]">
              {{ "Remaining Accounts" | translate }}
            </span>
          </div>
          <span> {{ invitePercentage.toFixed(2) }}% </span>
        </div>
      </div>
    </div>
    <!-- Subscription -->
    <div
      class="p-6 rounded-lg pb-0 bg-input-light flex flex-col gap-4 relative"
    >
      <div class="flex flex-col">
        <div class="Poppins-Medium text-sm">
          {{ "Subscription History" | translate }}
        </div>
        <div
          *ngIf="subscriptions.length"
          class="relative text-xxs max-h-[6.5rem] overflow-hidden"
          [ngClass]="{
            '!overflow-scroll !max-h-[7.5rem]': showAllSubs
          }"
        >
          <table class="w-full text-left rtl:text-right text-[#1C1C1C66]">
            <thead>
              <tr>
                <td scope="col" class="">
                  {{ "Description" | translate }}
                </td>
                <td scope="col" class="">{{ "Amount" | translate }}</td>
                <td scope="col" class="">{{ "Status" | translate }}</td>
                <td scope="col" class=""></td>
              </tr>
            </thead>
            <tbody>
              <tr class="text-[#1C1C1CCC]" *ngFor="let item of subscriptions">
                <td class="pl-0">{{ item.name }}</td>
                <td class="pl-0">{{ item.amount }}</td>
                <td class="pl-0 flex items-center gap-1">
                  <div
                    class="h-2 w-2 rounded-full"
                    [ngClass]="{
                      'bg-[#F63D68]': !item.status,
                      'bg-[#12B76A]': item.status
                    }"
                  ></div>
                  <span
                    [ngClass]="{
                      'text-[#F63D68]': !item.status,
                      'text-[#12B76A]': item.status
                    }"
                  >
                    {{ item.status ? "Completed" : "Rejected" }}
                  </span>
                </td>
                <td
                  class="cursor-pointer w-6"
                  *ngIf="item?.status && item?.invoiceUrl"
                >
                  <a [href]="item.invoiceUrl" target="_blank" class="">
                    <img src="assets/images/download.png" class="w-6" alt="" />
                  </a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div *ngIf="!subscriptions.length" class="pl-16 pt-10 text-xxs">
          {{ "No Subscriptions Found" | translate }}
        </div>
        <div
          class="Poppins-Medium text-xxs cursor-pointer relative bottom-1 left-0 m-1"
          *ngIf="!showAllSubs && subscriptions.length"
          (click)="showAllSubs = !showAllSubs"
        >
          {{ "View All" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="grid grid-cols-3 gap-x-6">
    <div class="grid grid-cols-2 gap-3 gap-x-6 col-span-2">
      <!-- PAYROLL -->
      <div class="p-6 rounded-lg bg-input-light flex flex-col justify-between">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%] payroll-message">
            <div class="text-sm Poppins-Medium">
              {{ "Payroll" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "Payroll Info message" | translate }}
            </div>
          </div>
          <span
            class="bg-[#026AA2] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              width="24"
              viewBox="0 -960 960 960"
              height="24"
              fill="#ffffff"
            >
              <path
                d="M480-40q-112 0-206-51T120-227v107H40v-240h240v80h-99q48 72 126.5 116T480-120q75 0 140.5-28.5t114-77q48.5-48.5 77-114T840-480h80q0 91-34.5 171T791-169q-60 60-140 94.5T480-40Zm-36-160v-52q-47-11-76.5-40.5T324-370l66-26q12 41 37.5 61.5T486-314q33 0 56.5-15.5T566-378q0-29-24.5-47T454-466q-59-21-86.5-50T340-592q0-41 28.5-74.5T446-710v-50h70v50q36 3 65.5 29t40.5 61l-64 26q-8-23-26-38.5T482-648q-35 0-53.5 15T410-592q0 26 23 41t83 35q72 26 96 61t24 77q0 29-10 51t-26.5 37.5Q583-274 561-264.5T514-250v50h-70ZM40-480q0-91 34.5-171T169-791q60-60 140-94.5T480-920q112 0 206 51t154 136v-107h80v240H680v-80h99q-48-72-126.5-116T480-840q-75 0-140.5 28.5t-114 77q-48.5 48.5-77 114T120-480H40Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#026AA2] h-9 w-10"
            src="assets/images/Dollar-Oval.svg"
            alt=""
          /> -->
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: payrollPlan }"
        >
        </ng-container>
      </div>

      <!-- ADD USERS -->
      <div class="p-6 rounded-lg bg-input-light flex flex-col justify-between">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Add Users (5 extra seats)" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              <span>
                {{ "Grow your team and collaborate with" | translate }}
                <p class="Poppins-Medium inline">{{ "Connect." }}</p>
              </span>
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#FF6874] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="M500-482q29-32 44.5-73t15.5-85q0-44-15.5-85T500-798q60 8 100 53t40 105q0 60-40 105t-100 53Zm220 322v-120q0-36-16-68.5T662-406q51 18 94.5 46.5T800-280v120h-80Zm80-280v-80h-80v-80h80v-80h80v80h80v80h-80v80h-80Zm-480-40q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM0-160v-112q0-34 17.5-62.5T64-378q62-31 126-46.5T320-440q66 0 130 15.5T576-378q29 15 46.5 43.5T640-272v112H0Zm320-400q33 0 56.5-23.5T400-640q0-33-23.5-56.5T320-720q-33 0-56.5 23.5T240-640q0 33 23.5 56.5T320-560ZM80-240h480v-32q0-11-5.5-20T540-306q-54-27-109-40.5T320-360q-56 0-111 13.5T100-306q-9 5-14.5 14T80-272v32Zm240-400Zm0 400Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#FF6874] h-9 w-10 p-1"
            src="assets/images/Users-oval.svg"
            alt=""
          /> -->
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: inviteUser }"
        >
        </ng-container>
      </div>

      <!-- RECURRING -->
      <div class="p-6 rounded-lg bg-input-light flex flex-col justify-between">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Recurring" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "recurring info message" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#EE46BC] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="M200-640h560v-80H200v80Zm0 0v-80 80Zm0 560q-33 0-56.5-23.5T120-160v-560q0-33 23.5-56.5T200-800h40v-80h80v80h320v-80h80v80h40q33 0 56.5 23.5T840-720v227q-19-9-39-15t-41-9v-43H200v400h252q7 22 16.5 42T491-80H200Zm520 40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Zm67-105 28-28-75-75v-112h-40v128l87 87Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#EE46BC] h-9 w-10"
            src="assets/images/calendar-oval.svg"
            alt=""
          /> -->
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: recurringPlan }"
        >
        </ng-container>
      </div>

      <!-- INVENTORY -->

      <div class="p-6 rounded-lg bg-input-light flex flex-col justify-between">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Inventory +" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "Inventory info message" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#F79009] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="M620-163 450-333l56-56 114 114 226-226 56 56-282 282Zm220-397h-80v-200h-80v120H280v-120h-80v560h240v80H200q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h167q11-35 43-57.5t70-22.5q40 0 71.5 22.5T594-840h166q33 0 56.5 23.5T840-760v200ZM480-760q17 0 28.5-11.5T520-800q0-17-11.5-28.5T480-840q-17 0-28.5 11.5T440-800q0 17 11.5 28.5T480-760Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#F79009] h-9 w-10"
            src="assets/images/inventory-oval.svg"
            alt=""
          /> -->
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: inventoryPlan }"
        >
        </ng-container>
      </div>

      <!-- P/L Budget -->
      <div
        class="col-span-1 p-6 rounded-lg bg-input-light flex flex-col justify-between"
      >
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "P/L Budget" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "P&L Budget Info" | translate }}
            </div>
          </div>
          <svg
            width="199"
            height="199"
            viewBox="0 0 199 199"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="w-10 h-11"
          >
            <circle cx="99.5" cy="99.5" r="99.5" fill="black" />
            <path
              d="M36 120.5L33 109.5L83 60L112 89L149 52.5L155.5 61.5L112 104L83 75.5L36 120.5Z"
              fill="white"
              stroke="white"
            />
            <path
              d="M61.5 152V127L72 117C72.1667 130.167 72.4 156.7 72 157.5C71.6 158.3 64.8333 154.167 61.5 152Z"
              fill="white"
            />
            <path
              d="M91.5 162.5C90.7 162.9 91.6667 132.5 92 117L103 127V163C99.6667 162.833 92.3 162.1 91.5 162.5Z"
              fill="white"
            />
            <path d="M122.5 158.5V127L133 117V153L122.5 158.5Z" fill="white" />
            <path
              d="M153 135V96.5L164.5 87C164.333 92.6667 164.1 105.4 164.5 111C164.9 116.6 157 130 153 135Z"
              fill="white"
            />
            <path
              d="M61.5 152V127L72 117C72.1667 130.167 72.4 156.7 72 157.5C71.6 158.3 64.8333 154.167 61.5 152Z"
              stroke="white"
            />
            <path
              d="M91.5 162.5C90.7 162.9 91.6667 132.5 92 117L103 127V163C99.6667 162.833 92.3 162.1 91.5 162.5Z"
              stroke="white"
            />
            <path
              d="M122.5 158.5V127L133 117V153L122.5 158.5Z"
              stroke="white"
            />
            <path
              d="M153 135V96.5L164.5 87C164.333 92.6667 164.1 105.4 164.5 111C164.9 116.6 157 130 153 135Z"
              stroke="white"
            />
          </svg>
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: pAndLPlan }"
        >
        </ng-container>
      </div>
      <!-- TIMESHEET -->

      <div
        class="col-span-1 p-6 rounded-lg bg-input-light flex flex-col justify-between"
      >
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Time Sheet" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "Timesheet Info" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#02A24A] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#FFFFFF"
            >
              <path
                d="M360-840v-80h240v80H360Zm80 440h80v-240h-80v240Zm40 320q-74 0-139.5-28.5T226-186q-49-49-77.5-114.5T120-440q0-74 28.5-139.5T226-694q49-49 114.5-77.5T480-800q62 0 119 20t107 58l56-56 56 56-56 56q38 50 58 107t20 119q0 74-28.5 139.5T734-186q-49 49-114.5 77.5T480-80Zm0-80q116 0 198-82t82-198q0-116-82-198t-198-82q-116 0-198 82t-82 198q0 116 82 198t198 82Zm0-280Z"
              />
            </svg>
          </span>
        </div>
        <ng-container
          [ngTemplateOutlet]="fourSame"
          [ngTemplateOutletContext]="{ $implicit: timeSheetPlan }"
        >
        </ng-container>
      </div>

      <!-- Document & Files -->
      <div class="p-6 rounded-lg bg-input-light flex flex-col gap-4">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Document & Files" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "Document & Files info" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#36BFFA] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="m200-120-80-480h720l-80 480H200Zm67-80h426l51-320H216l51 320Zm133-160h160q17 0 28.5-11.5T600-400q0-17-11.5-28.5T560-440H400q-17 0-28.5 11.5T360-400q0 17 11.5 28.5T400-360ZM240-640q-17 0-28.5-11.5T200-680q0-17 11.5-28.5T240-720h480q17 0 28.5 11.5T760-680q0 17-11.5 28.5T720-640H240Zm80-120q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760H320Zm-53 560h426-426Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#36BFFA] h-9 w-10"
            src="assets/images/folders.svg"
            alt=""
          /> -->
        </div>

        <div class="flex items-center gap-2 justify-between">
          <div class="flex gap-2 flex-col" *ngIf="currentStorageSubscription">
            <div class="flex gap-1 items-center flex-col">
              <div class="flex gap-1 items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  height="48px"
                  viewBox="0 -960 960 960"
                  width="48px"
                  fill="#5f6368"
                >
                  <path
                    d="M360-360v-240h240v240H360ZM480-40q-108 0-202.5-49.5T120-228v108H40v-240h240v80h-98q51 75 129.5 117.5T480-120q115 0 208.5-66T820-361l78 18q-45 136-160 219.5T480-40ZM42-520q7-67 32-128.5T143-762l57 57q-32 41-52 87.5T123-520H42Zm214-241-57-57q53-44 114-69.5T440-918v80q-51 5-97 25t-87 52Zm449 0q-41-32-87.5-52T520-838v-80q67 6 128.5 31T762-818l-57 57Zm133 241q-5-51-25-97.5T761-705l57-57q44 52 69 113.5T918-520h-80Z"
                  />
                </svg>
                <span class="text-xxs text-[#1C1C1C66]">
                  {{ "Renews" | translate }}
                </span>
              </div>
              <span class="text-xxs">
                {{ currentStorageSubscription?.expiryDate | date }}
              </span>
            </div>
            <div class="flex gap-1 items-center">
              <div class="h-2 w-2 rounded-full bg-[#12B76A]"></div>
              <span class="text-xxs text-[#1C1C1C66]">
                {{ "Purchased" | translate }}
              </span>
            </div>
          </div>
          <ng-select
            [items]="storagePlans"
            class="border-0 new-dropdown focus:ring-0 text-xxs ms-auto w-[30%]"
            placeholder="Select one"
            [searchable]="false"
            [(ngModel)]="storagePlan"
            [clearable]="false"
            [bindLabel]="'planDisplayName'"
            (change)="changeStoragePlan($event)"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.planDisplayName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
    </div>
    <!-- Subscription COLUMN -->
    <div class="col-span-1 flex flex-col gap-4">
      <!-- Subscription -->
      <!-- <div class="col-span-1 p-6 rounded-lg bg-input-light flex flex-col gap-4">
      <div class="justify-between flex">
        <div class="flex flex-col">
          <div class="Poppins-SemiBold text-[14px]">
            {{ "Subscription History" | translate }}
          </div>
          <div
            *ngIf="subscriptions.length"
            class="relative text-xxs max-h-[5.5rem] overflow-hidden"
            [ngClass]="{
              'overflow-scroll max-h-[6.5rem]': showAllSubs
            }"
          >
            <table class="w-full text-left rtl:text-right text-[#1C1C1C66]">
              <thead class="">
                <tr>
                  <th scope="col" class="pr-3">
                    {{ "Description" | translate }}
                  </th>
                  <th scope="col" class="pr-3">{{ "Amount" | translate }}</th>
                  <th scope="col" class="pr-3">{{ "Status" | translate }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-[#1C1C1CCC]" *ngFor="let item of subscriptions">
                  <td class="pr-3 pl-0">{{ item.name }}</td>
                  <td class="pr-3 pl-0">{{ item.amount }}</td>
                  <td class="pr-3 pl-0 flex items-center gap-1">
                    <div
                      class="h-2 w-2 rounded-full"
                      [ngClass]="{
                        'bg-[#F63D68]': !item.status,
                        'bg-[#12B76A]': item.status
                      }"
                    ></div>
                    <span
                      [ngClass]="{
                        'text-[#F63D68]': !item.status,
                        'text-[#12B76A]': item.status
                      }"
                    >
                      {{ item.status ? "Completed" : "Rejected" }}
                    </span>
                  </td>
                  <td
                    class="cursor-pointer w-6"
                    *ngIf="item?.status && item?.invoiceUrl"
                  >
                    <a [href]="item.invoiceUrl" target="_blank" class="">
                      <img src="assets/images/download.png" alt="" />
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div *ngIf="!subscriptions.length" class="pl-16 pt-10 text-xxs">
            {{ "No Subscriptions Found" | translate }}
          </div>
          <div
            class="Poppins-SemiBold text-xxs cursor-pointer"
            *ngIf="!showAllSubs && subscriptions.length"
            (click)="showAllSubs = !showAllSubs"
          >
            {{ "View All" | translate }}
          </div>
        </div>
      </div>
    </div> -->

      <!-- Transactions -->
      <div class="col-span-1 p-6 rounded-lg bg-input-light flex flex-col gap-4">
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-sm Poppins-Medium">
              {{ "Transactions" | translate }}
            </div>
            <div class="text-xxs text-[#1C1C1C66] font-medium flex">
              {{ "Transactions Info message" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#455A64] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="M160-160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v480q0 33-23.5 56.5T800-160H160Zm0-80h280v-480H160v480Zm360 0h280v-480H520v480Zm-320-80h200v-80H200v80Zm0-120h200v-80H200v80Zm0-120h200v-80H200v80Zm360 240h200v-80H560v80Zm0-120h200v-80H560v80Zm0-120h200v-80H560v80ZM440-240v-480 480Z"
              />
            </svg>
          </span>
          <!-- <img
          class="rounded-[80px] bg-[#455A64] h-9 w-10"
          src="assets/images/twoPager.svg"
          alt=""
        /> -->
        </div>

        <div class="relative text-xxs">
          <table class="w-full text-left rtl:text-right text-[#1C1C1C66]">
            <tbody>
              <tr
                *ngFor="let plan of transactionPlans"
                class="text-[#1C1C1CCC]"
              >
                <td class="pr-3 pl-0 pb-3 flex">
                  <span>{{ plan?.planName }}</span>
                </td>
                <td class="pb-3">
                  <ng-container
                    *ngIf="
                      !currentTransactionSubscription ||
                        (!currentTransactionSubscription?.isCanceled &&
                          plan?._id !== currentTransactionSubscription?.subId);
                      else showInfo
                    "
                  >
                    <app-pay-button
                      *ngIf="
                        plan?.planPrice <
                          currentTransactionSubscription?.planPrice;
                        else upgrade
                      "
                      (buttonClick)="changeTransactionPlan(plan, false)"
                    >
                      <div class="p-2">
                        {{ "Downgrade" | translate }}
                      </div>
                    </app-pay-button>
                    <ng-template #upgrade>
                      <app-pay-button
                        classNames="w-full h-8"
                        (buttonClick)="changeTransactionPlan(plan, true)"
                      >
                        <div
                          class="flex justify-start items-center text-xxs pl-1 gap-1"
                        >
                          <span class="Poppins-SemiBold"
                            >{{ "Add" | translate }}
                          </span>
                          <span class="text-[#1C1C1CCC]"
                            >{{ plan?.planPrice | currency }}/year</span
                          >
                        </div>
                      </app-pay-button>
                    </ng-template>
                  </ng-container>
                  <ng-template #showInfo>
                    <div class="flex flex-col gap-2">
                      <div class="flex items-center justify-end gap-2">
                        <div class="h-2 w-2 rounded-full bg-[#12B76A]"></div>
                        <span class="pl-1 text-xxs text-[#1C1C1C66]">
                          {{ "Purchased" | translate }}
                        </span>
                      </div>
                      <div class="flex items-center gap-1 justify-end">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          height="48px"
                          viewBox="0 -960 960 960"
                          width="48px"
                          fill="#5f6368"
                        >
                          <path
                            d="M360-360v-240h240v240H360ZM480-40q-108 0-202.5-49.5T120-228v108H40v-240h240v80h-98q51 75 129.5 117.5T480-120q115 0 208.5-66T820-361l78 18q-45 136-160 219.5T480-40ZM42-520q7-67 32-128.5T143-762l57 57q-32 41-52 87.5T123-520H42Zm214-241-57-57q53-44 114-69.5T440-918v80q-51 5-97 25t-87 52Zm449 0q-41-32-87.5-52T520-838v-80q67 6 128.5 31T762-818l-57 57Zm133 241q-5-51-25-97.5T761-705l57-57q44 52 69 113.5T918-520h-80Z"
                          />
                        </svg>
                        <span class="text-xxs text-[#1C1C1C66]">
                          {{ "Renews" | translate }}
                        </span>
                        <span class="text-xxs">
                          {{
                            currentTransactionSubscription?.expiryDate
                              | date : "dd/MM/yyyy"
                          }}
                        </span>
                      </div>
                    </div>
                  </ng-template>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div
        class="col-span-1 cursor-pointer p-6 text-[#FFFFFF] rounded-lg bg-[#1C1C1C] flex flex-col gap-4 items-end self-end"
        (click)="openMarketPlace()"
      >
        <div class="justify-between flex">
          <div class="flex flex-col max-w-[80%]">
            <div class="text-[11px] Poppins-Medium">
              {{ "Marketplace & Integrations" | translate }}
            </div>
            <div class="text-xxs font-medium flex">
              {{ "Marketplace & Integrations info message" | translate }}
            </div>
          </div>
          <span
            class="w-10 h-10 bg-[#FB6514] p-1 rounded-full overflow-hidden flex justify-center items-center"
            style="width: 40px; height: 40px"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-full"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#ffffff"
            >
              <path
                d="m480-400-80-80 80-80 80 80-80 80Zm-85-235L295-735l185-185 185 185-100 100-85-85-85 85ZM225-295 40-480l185-185 100 100-85 85 85 85-100 100Zm510 0L635-395l85-85-85-85 100-100 185 185-185 185ZM480-40 295-225l100-100 85 85 85-85 100 100L480-40Z"
              />
            </svg>
          </span>
          <!-- <img
            class="rounded-[80px] bg-[#FB6514] h-9 w-10"
            src="assets/images/integrations.svg"
            alt=""
          /> -->
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #fourSame let-plan>
  <div class="flex flex-col gap-2" *ngIf="plan?.subHost; else notActive">
    <div class="flex items-center gap-2">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        class="h-4 w-4"
        height="48px"
        viewBox="0 -960 960 960"
        width="48px"
        fill="#5f6368"
      >
        <path
          d="M360-360v-240h240v240H360ZM480-40q-108 0-202.5-49.5T120-228v108H40v-240h240v80h-98q51 75 129.5 117.5T480-120q115 0 208.5-66T820-361l78 18q-45 136-160 219.5T480-40ZM42-520q7-67 32-128.5T143-762l57 57q-32 41-52 87.5T123-520H42Zm214-241-57-57q53-44 114-69.5T440-918v80q-51 5-97 25t-87 52Zm449 0q-41-32-87.5-52T520-838v-80q67 6 128.5 31T762-818l-57 57Zm133 241q-5-51-25-97.5T761-705l57-57q44 52 69 113.5T918-520h-80Z"
        />
      </svg>
      <span>
        <span class="text-xxs text-[#1C1C1C66]">
          {{ "Renews" | translate }}
        </span>
        <span class="text-xxs">
          {{ plan?.expiryDate | date }}
        </span>
      </span>
    </div>
    <div class="flex items-center gap-2">
      <div class="h-2 w-2 rounded-full bg-[#12B76A]"></div>
      <span class="pl-1 text-xxs text-[#1C1C1C66]">
        {{ "Purchased" | translate }}
      </span>
    </div>
  </div>
  <ng-template #notActive>
    <app-pay-button
      classNames="w-[50%] h-8 subscription-price"
      (buttonClick)="showConfiramtionDialoge(plan)"
    >
      <div
        class="flex justify-center items-center text-xxs gap-1 w-[70%] border-r border-[#1C1C1C66] h-full"
      >
        <span class="Poppins-Medium">{{ "Add" | translate }} </span>
        <span class="text-[#1C1C1CCC]"
          >${{ allPlans[plan.name]?.planPrice | empty : "-" }}/year</span
        >
      </div>
      <div class="flex justify-center items-center w-[30%]">
        <img class="w-4" src="assets/images/stripe-img.png" alt="" />
      </div>
    </app-pay-button>
  </ng-template>
</ng-template>

<app-modal-container [isOpen]="confirmSubscriptionPlanModal">
  <div header class="border-b Gilory-SemiBold pb-2">
    {{ "Purchase Subscription Plan" | translate }}
  </div>
  <div content>
    {{ "Are you sure you want to purchase this plan?" | translate }}
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      (handleClick)="getSubscriptionLink()"
      buttonText="{{ 'Yes' | translate }}"
      type="primary"
      rounded="true"
    ></app-button>
    <app-button
      buttonText="{{ 'No' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="confirmSubscriptionPlanModal = false"
    >
    </app-button>
  </div>
</app-modal-container>
<app-modal-container [isOpen]="showCardModal">
  <div content>
    <h3 class="text-lg text-black">{{ "Add Card details" | translate }}</h3>
    <ng-container *ngIf="elementsOptions?.clientSecret as clientSecret">
      <ngx-stripe-payment [clientSecret]="clientSecret"></ngx-stripe-payment>
    </ng-container>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="primary"
      (handleClick)="pay()"
      [buttonText]="'Add card details' | translate"
    ></app-button>
    <app-button
      type="danger"
      (handleClick)="showCardModal = false"
      [buttonText]="'Cancel' | translate"
    ></app-button>
  </div>
</app-modal-container>
