import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-language-config',
  templateUrl: 'language-config.component.html',
})
export class LanguageConfigComponent implements OnInit {
  availableLanguages = [
    { name: 'English', code: 'en' },
    { name: 'Arabic', code: 'ar' },
  ];
  communicationLanguage: FormControl = new FormControl('en', [
    Validators.required,
  ]);
  user$: Observable<any>;

  constructor(
    private store: Store<RootReducerState>,
    private toster: ToastrService,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  ngOnInit() {
    this.user$.subscribe((user) => {
      if (user) {
        console.log('gettingUser', user);
        this.communicationLanguage.setValue(user.language);
      }
    });
  }
  setLanguage(): void {
    this.spinner.show();
    const body = {
      language: this.communicationLanguage.value,
    };
    this.userService.setCommunicationLanguage(body).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.userService.setUserDetails(resp.data);
          this.toster.success('Language updated successfully');
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
