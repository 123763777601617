import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TaskServiceService } from '../../task-service.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { environment } from 'src/environments/environment';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { StorageService } from 'src/app/modules/storage/storage.service';
import { ToastrService } from 'ngx-toastr';
import { TranslateService } from '@ngx-translate/core';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ChatsService } from 'src/app/modules/chats/chats.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-view-task',
  templateUrl: './view-task.component.html',
  styleUrls: ['./view-task.component.scss']
})
export class ViewTaskComponent implements OnInit {
  baseURL = environment.apiBaseURL
  constructor(private location : Location, private deviceService: DeviceDetectorService, private languageService: LanguageService, private chatService: ChatsService, private router : Router, private translateService: TranslateService, private toastr: ToastrService, private storageService: StorageService, private spinner : NgxSpinnerService, private taskService : TaskServiceService, private fb : FormBuilder, private activatedRoute: ActivatedRoute,private store: Store<RootReducerState>) {
    this.user$ = this.store.pipe(select(selectUser))
   }
   user$: Observable<any>;
  addComment = new FormControl();
  completeTaskForm : FormGroup;
  taskId: string;
  taskDetails: any;
  openReminderModal = false;
  selectedReminderDate;
  reminderFieldError = false;
  today = new Date();
  isOpen = false
  userData:any;
  fileDetails;
  fileType;
  fileURL;
  fileData: string | Blob = '';
  assignedtoMe = false;
  createTaskFormErrors = {
    hoursConsume: '',
    note: '',
  };
  
  createTaskFormErrorMessages = {
    hoursConsume: {
      required: 'Field is required',
    },
    note: {
      required: 'Field is required',
    },
  };
  allNotifications: any;
  notificationsCount: any;
  chatNotification: any;
  lang: any;
  isMobileDevice = false;

  ngOnInit(): void {
    this.spinner.show();
    this.taskId = this.activatedRoute.snapshot.params.id;
    this.loadCompletedTaskFrom();
    this.loadUserDetails();
    this.languageService.getLanguage().subscribe((language) => {
      this.lang = language;      
    });
    this.isMobileDevice = this.deviceService.isMobile(); 
  }

  ngOnDestroy(){
  }

  loadUserDetails(): void {
    this.user$.subscribe((user) => {
      if (user) {
        this.userData = user;
        this.loadTaskDetails();
        console.log("this.userData", this.userData);
        this.chatService.emit('getAllNotifications', this.userData._id);
      }
    });
  }
  loadTaskDetails(): void {
    this.taskService.viewTask(this.taskId).subscribe((resp)=>{
      
      this.taskDetails = resp.data.taskDetails;
      console.log('taskDetails', this.taskDetails);
      
      this.assignedtoMe = this.taskDetails.assignToUserId._id ===  this.userData._id;

      this.taskDetails.dueDate = new Date(this.taskDetails.dueDate).toISOString().replace('Z', '');
      this.taskDetails.startDate = new Date(this.taskDetails.startDate).toISOString().replace('Z', '');
      this.selectedReminderDate = this.taskDetails.reminder;
      console.log(new Date(this.selectedReminderDate));
      
      if(this.taskDetails.status !== 'Completed' && !this.taskDetails.isStatusChanged) {
        if(moment().isBefore(moment(this.taskDetails.dueDate))) {
          this.taskDetails.status = 'Upcoming'
        }
        if(moment().isAfter(moment(this.taskDetails.dueDate))) {
          this.taskDetails.status = 'Overdue'
        }
        if(moment(this.taskDetails.dueDate).format('DD/MM/YYYY') === moment().format('DD/MM/YYYY')) {
          this.taskDetails.status = 'In Progress'
        }
      }

    this.chatNotification = this.chatService.listen('getAllNotifications').subscribe((data)=>{
      console.log('getAllNotifications12341243', data);
      
      this.allNotifications = data.filter((item)=>((item.toUser?.includes(this.userData?._id) || item.toUser === this.userData?._id) && (item.fromUser !== this.userData?._id || (item.fromUser === this.userData?._id && item.entityType === 'taskReminder')) && item.businessId === this.taskDetails.businessId)).slice().reverse();
      this.notificationsCount = this.allNotifications.filter((item)=>!item.seen).length;
      console.log("afterfiltering", this.allNotifications, this.notificationsCount);
      
    });
      this.spinner.hide();
    }, (error) => {
      console.log('error1234', error.error.status);
      if(error.error.status === 500){
        this.router.navigate(['/task'])
      }
      this.spinner.hide();
      
    })
  }
  loadCompletedTaskFrom() {
    this.completeTaskForm = this.fb.group({
      hoursConsume : [null, [Validators.required]],
      note : [null, [Validators.required]]
    })
    this.completeTaskForm.valueChanges.subscribe(() => {
      this.createTaskFormErrors = valueChanges(
        this.completeTaskForm,
        { ...this.createTaskFormErrors },
        this.createTaskFormErrorMessages,
        this.translateService
      );
    });
    
    this.onValueChanges();
  }

  onValueChanges(): void {    
    this.createTaskFormErrors = valueChanges(
      this.completeTaskForm,
      { ...this.createTaskFormErrors },
      this.createTaskFormErrorMessages,
      this.translateService
    );
  };

  addComments() {
    const payload = {
      comment : this.addComment.value
    }
    this.spinner.show()
    this.taskService.addCommentInTask(this.taskId,payload).subscribe({
      next : (response)=>{
        this.spinner.hide()
        if(this.taskDetails.createdByUserId !== this.taskDetails.assignToUserId._id) {
          this.chatService.emit('notification', {
            entityId: this.taskDetails._id,
            entityType: 'taskAssigned',
            message: `${this.userData.firstName + ' ' + this.userData.lastName} has added a comment in the task ${this.taskDetails.taskTitle}`,
            fromUser: this.userData._id,
            toUser: this.taskDetails.assignToUserId._id === this.userData._id ? this.taskDetails.createdByUserId : this.taskDetails.assignToUserId._id,
            businessId: this.taskDetails.businessId
          });
        }
        this.loadTaskDetails()
        this.addComment.reset()
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
    console.log(this.addComment.value)
  }
  editDetails(): void{
    this.completeTaskForm.setValue({
      hoursConsume: this.taskDetails.hoursConsume,
      note: this.taskDetails.note
    });
    this.isOpen = true;
  }
  changeStatus(status): void {
    const payload = {
      status: status
    }
    if(status === 'In Progress'){
      payload['hoursConsume'] = null;
      payload['note'] = null;
      payload['isStatusChanged'] = true;
    }
    this.spinner.show();
    this.taskService.markAsCompleted(this.taskId,JSON.stringify(payload)).subscribe({
      next : (response)=>{
        this.spinner.hide();
        this.isOpen = false;
        this.completeTaskForm.reset();
        this.loadTaskDetails();
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide();
        console.log(error)
      }
    })
  }
  submitForm(){
    if(this.completeTaskForm.invalid){
      this.completeTaskForm.markAllAsTouched();
      this.onValueChanges();
      return;
    }
    const payload = this.completeTaskForm.value
    payload['status'] = 'Completed'
    this.spinner.show();
    console.log("taskDetails", this.taskDetails);
    
    this.taskService.markAsCompleted(this.taskId,JSON.stringify(payload)).subscribe({
      next : (response)=>{
        this.spinner.hide();
        this.isOpen = false;
        this.completeTaskForm.reset();
        if(this.taskDetails.createdByUserId !== this.taskDetails.assignToUserId._id && this.taskDetails.createdByUserId !== this.userData._id) {
          this.chatService.emit('notification', {
            entityId: this.taskDetails._id,
            entityType: 'taskAssigned',
            message: `${this.userData.firstName + ' ' + this.userData.lastName} has completed the task ${this.taskDetails.taskTitle}`,
            fromUser: this.userData._id,
            toUser: this.taskDetails.createdByUserId,
            businessId: this.taskDetails.businessId
          });
        }
        this.loadTaskDetails();
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide();
        console.log(error)
      }
    })
  }
  back() {
    this.location.back()
  }

  addReminder(): void {
    if(!this.selectedReminderDate){
      this.reminderFieldError = true;
      return;
    }
    console.log("event", this.selectedReminderDate);

    const payload = {};
    const fullDate = this.selectedReminderDate

    // Extract date and time without seconds
    const dateWithoutSeconds = new Date(
        fullDate.getFullYear(),
        fullDate.getMonth(),
        fullDate.getDate(),
        fullDate.getHours(),
        fullDate.getMinutes()
    );
    payload['reminder'] = dateWithoutSeconds;
    console.log(payload, dateWithoutSeconds);
    
    this.spinner.show();
    this.taskService.addReminder(this.taskId, payload).subscribe((resp)=>{
      // this.spinner.hide();
      this.openReminderModal = false;
      this.loadTaskDetails();
    }, (err)=>{
      this.spinner.hide();
    })
  }

  viewFile(fileId: string): void {
    this.spinner.show();
    this.storageService.getFile(fileId)
      .subscribe(resp => {
        console.log('in resp', resp);
        if(resp?.success){
          console.log(resp?.data);
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data; // Adjust the property name based on your API response

          // Convert Buffer array to Uint8Array
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {type: resp?.data?.docData?.fileType});
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = resp?.data?.docData?.displayFileName;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        else{
          this.toastr.error(resp?.message)
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      })
  }

  onChange(event): void {
    console.log("event", event);

  }

  reminderDateChange(event): void {
    console.log(event, moment(event).isBefore(this.today));
    if(!event || moment(event).isBefore(this.today)){
      this.reminderFieldError = true;
    } else {
      this.reminderFieldError = false;
    }
  }

  goToNotification(item){
    console.log(item);
    switch (item.entityType) {
      case "taskAssigned":
        const payload = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payload);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "sharedFile":
        const payloadThree = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadThree);
        this.router.navigate([`/files/shared`]);
      break;
      case "taskReminder":
        const payloadTwo = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadTwo);
        this.router.navigate([`/task/view-task/${item.entityId}`]);
      break;
      case "mention":
        const payloadFour = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadFour);
        this.chatService.setredirectionChatId(item.entityId);
        this.router.navigate([`/chats`]);
        break;
    }

  }
}
