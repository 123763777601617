import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { InventoryService } from '../inventory/inventory.service';

@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(public httpService: HttpService, private inventoryService: InventoryService) { }

  private businessId = new BehaviorSubject(null);
  private currencyDetails = new BehaviorSubject(null);

  public businessId$ = this.businessId.asObservable();
  public currencyDetails$ = this.currencyDetails.asObservable();

  setBusinessId(id): void {
    if (id) { this.businessId.next(id); }
  }

  setCurrencyDetails(currencyDetails): void {
    console.log("Report Service",currencyDetails)
    if (currencyDetails) {
      this.currencyDetails.next(currencyDetails);
    }
  }

  getProfitLoss(data): Observable<any> {
    return this.httpService.post(`/reports/profitLoss/${data.businessId}`, data);
  }

  getBalanceSheet(data): Observable<any> {
    return this.httpService.post(`/reports/balanceSheet/${data.businessId}`, data);
  }

  getIncomeByCustomer(data): Observable<any> {
    return this.httpService.post(`/reports/incomeByCustomer/${data.businessId}`, data);
  }

  getPurchasesByVendor(data): Observable<any> {
    return this.httpService.post(`/reports/purchasesByVendor/${data.businessId}`, data);
  }

  getAgedRecieveables(data): Observable<any> {
    return this.httpService.post(`/reports/agedRecieveables/${data.businessId}`, data);
  }

  getAgedPayable(data): Observable<any> {
    return this.httpService.post(`/reports/agedPayables/${data.businessId}`, data);
  }

  getAccountBalances(data): Observable<any> {
    return this.httpService.post(`/reports/accountBalances/${data.businessId}`, data);
  }

  getAccountSection(businessId): Observable<any> {
    return this.httpService.get(`/account/getAccountSection/${businessId}`);
  }

  getTrialBalance(data): Observable<any> {
    return this.httpService.post(`/reports/trialBalance/${data.businessId}`, data);
  }

  getSalesTaxTransactionReport(data): Observable<any> {
    return this.httpService.post(`/reports/salesTaxTransactionReport`, data);
  }

  getAllTaxes(businessId): Observable<any> {
    return this.httpService.get(`/setting/getTaxs/${businessId}`);
  }

  getPartialAccountBalances(data): Observable<any> {
    return this.httpService.post(`/reports/getPartialAccountBalances/${data.businessId}`, data);
  }

  getAccountTransactions(data): Observable<any> {
    return this.httpService.post(`/reports/accountTransactions/${data.businessId}`, data);
  }

  getSalesTaxes(data): Observable<any> {
    return this.httpService.post(`/reports/salesTaxes/${data.businessId}`, data);
  }

  getAssetsReport(data: any): Observable<any> {
    return this.httpService.post(`/reports/assetReport/${this.businessId.value}`, data);
  }

  getAssets(data): Observable<any> {
    return this.httpService.get(`/purchase/getAssets/${data.businessId}`, data);
  }

  getInventoryItems(businessId): Observable<any> {
    return this.inventoryService.getProducts(businessId);
  }

  getItemSummary(itemId, data): Observable<any> {
    return this.httpService.post(`/inventory/getItemsSummary/${itemId}`, data);
  }

  downloadReport(data): Observable<any> {
    return this.httpService.postPdf(`/pdf/report`, data);
  }

  downloadSalesTaxReport(data): Observable<any> {
    return this.httpService.postPdf(`/pdf/createSalesTaxReportPdf`, data);
  }

  getInvoiceCustomizationSettings(): Observable<any> {
    return this.httpService.get(`/setting/getTemplateConfiguration/${this.businessId.getValue()}`);
  }
  getCashierAttendanceReport(businessId:any, payload:any) {
    return this.httpService.post(`/reports/attendanceReport/${businessId}`,payload)
  }
  getCashierSalesReport(businessId:any, payload:any) {
    return this.httpService.post(`/reports/cashierSalesReport/${businessId}`,payload)
  }
  getAllCashierByBusiness(businessId:any) {
    return this.httpService.get(`/users/getBusinessCashier/${businessId}`)
  }
}
