import {
  AfterContentInit,
  Component,
  ContentChildren,
  Input,
  QueryList,
} from '@angular/core';
import { TabComponent } from './tab.component';

@Component({
  selector: 'my-tabs',
  template: `
    <ul
      [class]="
        className +
        ' ' +
        'hidden mb-4 h-12 text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex'
      "
    >
      <li
        *ngIf="navigation"
        class="inline-block px-4 cursor-pointer"
        (click)="prevTab()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </li>
      <li
        *ngFor="let tab of tabs; let isLast = last; let isFirst = first"
        class="w-full flex items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
        (click)="selectTab(tab)"
        [ngClass]="{
          'bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]': tab.active,
          ' border-gray-200 border-r': !isLast
        }"
        tabindex="1"
      >
        <div
          class="inline-block w-full p-4 focus:ring-4 focus:ring-blue-300 focus:outline-none whitespace-nowrap"
          [ngClass]="{ 'rounded-s-lg': isFirst, 'rounded-e-lg': isLast }"
        >
          {{ tab?.title | translate }}
        </div>
      </li>
      <li
        *ngIf="navigation"
        class="inline-block px-4 cursor-pointer"
        (click)="nextTab()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M9 5l7 7-7 7"
          />
        </svg>
      </li>
    </ul>
    <ng-content></ng-content>
  `,
  styles: [
    `
      .tab-close {
        color: gray;
        text-align: end;
        cursor: pointer;
      }
    `,
  ],
  exportAs: 'tabs',
})
export class TabsComponent implements AfterContentInit {
  @ContentChildren(TabComponent) tabs: QueryList<TabComponent>;

  @Input() navigation = false;
  @Input() className = '';

  // contentChildren are set
  ngAfterContentInit() {
    const activeTabs = this.tabs.filter((tab) => tab.active);
    if (activeTabs.length === 0) {
      this.selectTab(this.tabs.first);
    }
  }
  selectTab(tab) {
    this.tabs.toArray().forEach((tab) => (tab.active = false));
    tab.active = true;
  }
  nextTab(): void {
    const activeTab = this.tabs.find((tab) => tab.active);
    const index = this.tabs.toArray().indexOf(activeTab);

    if (this.tabs.get(index) === this.tabs.last) {
      this.selectTab(this.tabs.first);
    } else {
      this.selectTab(this.tabs.get(index + 1));
    }
  }

  prevTab(): void {
    const activeTab = this.tabs.find((tab) => tab.active);
    const index = this.tabs.toArray().indexOf(activeTab);

    if (this.tabs.get(index) === this.tabs.first) {
      this.selectTab(this.tabs.last);
    } else {
      this.selectTab(this.tabs.get(index - 1));
    }
  }
}
