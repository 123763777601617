<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
<!-- <div class="flex justify-center p-4">
  <div class="w-full flex flex-col space-y-4"> -->
    <div>
      <div>
        <div class="">
          <h1 class="text-2xl Poppins-Medium inline-flex items-center">
            {{ viewProjectDetails?.projectDetails?.projectName }}
          </h1>
          <!-- </div>
          <div class="">
              <app-button (click)="editCustomer(viewCustomerDetail._id)" buttonText='{{"Edit Customer" | translate}}' type='primary'>{{'Edit Customer' | translate}}</app-button>
          </div>
        </div> -->
          <div class="flex">
            <div class="w-full">
              <div class="grid grid-cols-3 gap-4 mt-5">
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="flex Poppins-Medium text-xl">
                    {{
                      totalInvoiceAmount - totalBillAmount >= 0
                        ? "Profit"
                        : "Loss"
                    }}%
                    <svg
                      *ngIf="totalInvoiceAmount - totalBillAmount > 0"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ms-2 h-6 w-6 text-green-400"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 7h8m0 0v8m0-8l-8 8-4-4-6 6"
                      />
                    </svg>
                    <svg
                      *ngIf="totalInvoiceAmount - totalBillAmount < 0"
                      xmlns="http://www.w3.org/2000/svg"
                      class="ms-2 h-6 w-6 text-red-700"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M13 17h8m0 0V9m0 8l-8-8-4 4-6-6"
                      />
                    </svg>
                  </p>
                  <h3
                    [ngClass]="
                      totalInvoiceAmount - totalBillAmount > 0
                        ? 'text-green-400'
                        : totalInvoiceAmount - totalBillAmount < 0
                        ? 'text-red-700'
                        : ''
                    "
                    class="text-2xl font-Medium"
                  >
                    {{
                      ((totalInvoiceAmount - totalBillAmount) * 100) /
                        totalInvoiceAmount
                        | number : "1.2-2"
                        | empty : "--"
                    }}
                  </h3>
                </div>
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="Poppins-Medium text-xl">Total Earnings</p>
                  <h3 class="text-2xl font-Medium">
                    {{
                      totalInvoiceAmount
                        | currencywithspace:currencyDetails?.currency :'narrowSymbol'
                        | empty : "--"
                    }}
                  </h3>
                  <span class="text-sm text-gray-600"
                    >Estimated ({{
                      estimatedEarnings
                        | currencywithspace:currencyDetails?.currency :'narrowSymbol'
                        | empty : "--"
                    }})</span
                  >
                </div>
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="Poppins-Medium text-xl">Total Expenses</p>
                  <h3 class="text-2xl font-Medium">
                    {{
                      totalBillAmount
                        | currencywithspace:currencyDetails?.currency :'narrowSymbol'
                        | empty : "--"
                    }}
                  </h3>
                  <span class="text-sm text-gray-600"
                    >Estimated ({{
                      estimatedExpenses
                        | currencywithspace:currencyDetails?.currency :'narrowSymbol'
                        | empty : "--"
                    }})</span
                  >
                </div>
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="Poppins-Medium text-xl">Start Date</p>
                  <h3 class="text-2xl font-Medium">
                    {{ projectStartDate | empty : "--" }}
                  </h3>
                  <span class="text-sm text-gray-600"
                    >Estimated ({{ estimatedStartDate | empty : "--" }})</span
                  >
                </div>
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="Poppins-Medium text-xl">End Date</p>
                  <h3 class="text-2xl font-Medium">
                    {{ projectCloseDate | empty : "--" }}
                  </h3>
                  <span class="text-sm text-gray-600"
                    >Estimated ({{ estimatedEndDate | empty : "--" }})</span
                  >
                </div>
                <div class="bg-[#F7F9FB] rounded-xl p-4">
                  <p class="Poppins-Medium text-xl">Hours</p>
                  <h3 class="text-2xl font-Medium">
                    {{ projectHour | empty : "--" }}
                  </h3>
                  <span class="text-sm text-gray-600"
                    >Estimated ({{ estimatedHour | empty : "--" }}hrs)</span
                  >
                </div>
              </div>
              <h3 class="text-xl Poppins-Medium inline-flex items-center mt-6">
                {{ "Active Filters" | translate }}
              </h3>
              <div
                class="flex gap-x-2 mt-5"
                [formGroup]="filterForm"
              >
                <div class="self-end">
                  <label class="Poppins-Medium" for="startDate">{{
                    "From" | translate
                  }}</label>
                  <input
                    formControlName="fromDate"
                    type="date"
                    class="new-date"
                  />
                  <span
                    *ngIf="filterFormErrors.fromDate"
                    class="text-red-600"
                    >{{ filterFormErrors.fromDate }}</span
                  >
                </div>
                <div class="self-end">
                  <label class="Poppins-Medium" for="endDate">{{
                    "To" | translate
                  }}</label>
                  <input
                    formControlName="endDate"
                    type="date"
                    class="new-date"
                  />
                  <span *ngIf="filterFormErrors.endDate" class="text-red-600">{{
                    filterFormErrors.endDate
                  }}</span>
                </div>
                <div class="justify-self-start self-end">
                  <app-button
                    buttonText="{{ 'Filter Project Data' | translate }}"
                    type="primary"
                    (handleClick)="filterData()"
                  ></app-button>
                </div>
              </div>
              <div class="mt-5">
                <my-tabs className="w-[37%]">
                  <my-tab tabTitle="{{ 'Invoices' | translate }}">
                    <app-table-container
                      [tableHeadings]="invoiceTableHeadings"
                      [tableData]="invoices"
                      [keys]="invoiceTableKeys"
                    >
                    </app-table-container>
                  </my-tab>
                  <my-tab tabTitle="{{ 'Bills' | translate }}">
                    <app-table-container
                      [tableHeadings]="billTableHeadings"
                      [tableData]="bills"
                      [keys]="billTableKeys"
                    >
                    </app-table-container>
                  </my-tab>
                  <my-tab tabTitle="{{ 'Files' | translate }}">
                    <div class="flex flex-col space-y-4">
                      <app-file-upload
                        appAccessControl
                        moduleType="storage"
                        accessType="view"
                        [addedFiles]="addedFiles"
                        (emitter)="saveFiles($event)"
                        type="compact"
                        tableType="new"
                      ></app-file-upload>
                    </div>
                  </my-tab>
                </my-tabs>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
