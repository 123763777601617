<app-modal-container [isOpen]="isOpen">
  <div *ngIf="!update" header class="border-b pb-2 Poppins-ExtraMedium text-xl">
    {{ "Add an account" | translate }}
  </div>
  <div *ngIf="update" header class="border-b pb-2 Poppins-ExtraMedium text-xl">
    {{ "Update an account" | translate }}
  </div>
  <div content>
    <form [formGroup]="accountForm">
      <div class="mt-3 text-center space-y-4 sm:text-left">
        <div class="space-y-2">
          <div class="px-3 py-2 space-y-2">
            <label class="block text-sm Poppins-Medium border-0 font-color-05">{{
              "Account Type" | translate
            }}</label>
            <ng-select
              tabindex="1"
              [items]="accountTypes"
              class="new-dropdown"
              bindLabel="accountType"
              formControlName="accountType"
              placeholder="{{ 'Select One' | translate }}"
              groupBy="section"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.accountType }}
                  </div>
                </div>
              </ng-template>
            </ng-select>
            <ng-template ng-optgroup-tmp let-item="item">
              {{ item.section || "Unnamed group" }}
            </ng-template>
          </div>
          <div *ngIf="formErrors.accountType" class="text-sm text-red-400 ms-2">
            {{ formErrors.accountType | translate }}
          </div>
        </div>
        <div class="space-y-2">
          <div class="px-3 py-2 space-y-2">
            <label
              for="accountName"
              class="block text-sm Poppins-Medium font-color-05"
              >{{ "Account Name" | translate }}</label
            >
            <input
              tabindex="2"
              id="accountName"
              formControlName="accountName"
              type="text"
              class="new-input"
              placeholder="{{ 'Enter Account Name' | translate }}"
            />
          </div>
          <div *ngIf="formErrors.accountName" class="text-sm text-red-400 ms-2">
            {{ formErrors.accountName | translate }}
          </div>
        </div>
        <div class="space-y-2">
          <div class="px-3 py-2 space-y-2">
            <label
              for="accountID"
              class="block text-sm Poppins-Medium font-color-05"
              >{{ "Account ID" | translate }}</label
            >
            <input
              id="accountID"
              tabindex="4"
              type="text"
              formControlName="accountId"
              name="accountId"
              class="new-input"
              placeholder="{{ 'Enter Account ID' | translate }}"
            />
          </div>
        </div>
        <div class="space-y-2">
          <div class="px-3 py-2 space-y-2">
            <label
              for="description"
              class="block text-sm Poppins-Medium font-color-05"
              >{{ "Description" | translate }}</label
            >
            <textarea
              tabindex="5"
              id="description"
              formControlName="description"
              name="description"
              class="new-input !h-24"
              placeholder="{{ 'Enter Description' | translate }}"
            ></textarea>
          </div>
        </div>
        <div class="space-y-2" *ngIf="update">
          <div class="px-3 py-2 space-y-2">
            <label
              for="isArchive"
              class="block text-sm Poppins-Medium font-color-05"
              >{{ "Archive account" | translate }}</label
            >
            <div class="flex items-center">
              <input
                type="checkbox"
                class="me-2"
                name="isArchive"
                id="isArchive"
                formControlName="isArchive"
              />
              <span>{{ "Archive message" | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      *ngIf="!update"
      tabindex="6"
      buttonText="{{ 'Save' | translate }}"
      type="primary"
      (handleClick)="addAccount()"
    ></app-button>
    <app-button
      *ngIf="update"
      tabindex="6"
      buttonText="{{ 'Update' | translate }}"
      type="primary"
      (handleClick)="updateAccount()"
    ></app-button>
    <app-button
      tabindex="7"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="closeForm()"
    ></app-button>
  </div>
</app-modal-container>
