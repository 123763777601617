import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { initFlowbite } from 'flowbite';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { UserService } from 'src/app/shared/services/user.service';
import {
  dateLessThan,
  DateValidator,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import {
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_LOCAL,
  DATE_FORMAT_TIME_SHEET,
} from '../../constant';
import {
  DownloadTimesheetPdf,
  FilterDto,
  FilterTimeSheetDto,
  MarkAsDto,
  TimeSheetDto,
} from '../../time-sheet.dto';
import { LanguageService } from 'src/app/shared/services/language.service';
import { TimeSheetService } from '../../time-sheet.service';
import moment = require('moment');

@Component({
  selector: 'app-employees-report',
  templateUrl: './employees-report.component.html',
  styleUrls: ['./employees-report.component.scss'],
})
export class EmployeesReportComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private salesServices: SalesService,
    private timeSheetService: TimeSheetService,
    private spinner: NgxSpinnerService,
    private userService: UserService,
    private languageService: LanguageService,
    private translateService: TranslateService,
    private rootStore: Store<RootReducerState>,
    private toaster: ToastrService
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.projects$ = this.rootStore.pipe(select(selectProjects));
    this.customer$ = this.rootStore.pipe(select(selectCustomers));
  }
  dateTimeFormat = DATE_TIME_FORMAT;
  projects$: Observable<any>;
  maxDateTime: string;
  business$: Observable<any>;
  customer$: Observable<any>;
  businessId: string;
  unsubscribe$ = new Subject();
  filterForm: FormGroup;
  updateRegisterHourForm: FormGroup;
  buildDescription: FormControl = new FormControl('', [Validators.required]);
  dataItem: TimeSheetDto;
  itemsPerPage: number = 10;
  weeklyTotal: number = 0;
  currentPage: number = 1;
  totalCount: number = 0;
  pageTotal: number = 0;
  isEditable: boolean = false;
  showUpdateModal: boolean = false;
  showMarkAsBuildModal: boolean = false;
  confirmDeleteTimeSheetModal: boolean = false;
  updateId: string | null = null;
  projects = [];
  customers = [];
  employees = [];
  tasks = [];
  formErrors = {
    from: '',
    to: '',
  };

  updateFormErrors = {
    customerId: '',
    until: '',
    description: '',
    paused: '',
    by: '',
  };

  formErrorMessages = {
    customerId: {
      required:
        'Please select at least one of the following options: Customer, Project or Task.',
    },
    until: {
      required: 'Until cannot be empty',
      past: 'The deadline must not be before the start time.',
    },
    description: {
      required: 'Description cannot be empty',
    },
    paused: {
      greater: 'Break duration may not be longer than total duration.',
    },
    by: {
      required: 'From cannot be empty',
      future: 'From may not be in future.',
    },
    from: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date',
    },
    to: {
      required: 'Due Date is Required',
      invalidDate: 'Invalid Date',
      dates: 'Invalid Date',
    },
  };
  buildDescriptionError = '';
  tableData = [];
  tableKeys = [
    'by',
    'until',
    'description',
    'customerName',
    'projectName',
    'totalWorked',
    'paused',
  ];
  tableHeadings = [
    'From',
    'Until',
    'Description',
    'Customer',
    'Project',
    'Worked',
    'Paused',
    'Action',
    '',
  ];

  fieldNames = {
    timeSheetDate: 'Timesheet Date',
    timePeriod: 'Time Period',
    description: 'Description',
    customer: 'Customer Name',
    project: 'Project Name',
    worked: 'Total Worked',
    paused: 'Paused',
    employee: 'Employee Name',
    period: 'Period',
  };

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId._id;
        this.loadCustomers(business?.businessId._id);
      }
    });
    initFlowbite();
    this.loadEmployees();
    this.loadProjects();
    this.loadAllTasks();
    this.loadFilterForm();
    this.filterData(this.currentPage);
    this.maxDateTime = moment().format(DATE_TIME_FORMAT_LOCAL);
  }

  loadEmployees(): void {
    this.userService
      .getBusinessUsers(this.businessId)
      .pipe(
        tap((resp) => {
          this.employees = resp.data.map(({ _id, firstName, lastName }) => ({
            _id,
            employeeName: firstName + ' ' + lastName,
          }));
        }),
        catchError((error) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  loadAllTasks() {
    this.spinner.show();
    this.timeSheetService
      .getAssignedTask(this.businessId)
      .pipe(
        tap((resp) => {
          this.tasks = resp.data;
          this.spinner.hide();
        }),
        catchError((error) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group(
      {
        employeeId: [null],
        customerId: [null],
        projectId: [null],
        taskId: [null],
        from: [null, DateValidator()],
        to: [null, DateValidator()],
      },
      { validator: dateLessThan('from', 'to') }
    );

    this.updateRegisterHourForm = this.fb.group({
      description: ['', [Validators.required]],
      paused: [null],
      by: [null, [Validators.required]],
      customerId: [null],
      until: [null, [Validators.required]],
      taskId: [null],
      projectId: [null],
    });

    this.filterForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.filterForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    });

    this.formErrors = valueChanges(
      this.filterForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  loadProjects(): void {
    this.projects$.subscribe((projects) => {
      this.projects = projects;
    });
  }

  loadCustomers(id: string): void {
    this.spinner.show();
    this.salesServices
      .customerList(id)
      .pipe(
        tap((resp) => {
          this.customers = resp.data;
          this.spinner.hide();
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  filterData(page: number = 1, limit: number = this.itemsPerPage): void {
    this.currentPage = page;
    this.spinner.show();
    const from = this.filterForm.value.from
      ? moment(this.filterForm.value.from).format(DATE_FORMAT)
      : null;
    const to = this.filterForm.value.to
      ? moment(this.filterForm.value.to).format(DATE_FORMAT)
      : null;
    const body: FilterDto = { ...this.filterForm.value, page, limit, from, to };
    this.timeSheetService
      .filterTimeSheetByAdmin(this.businessId, body)
      .pipe(
        tap((response: FilterTimeSheetDto) => {
          this.tableData = response.data.timesheets;
          this.totalCount = response.data.totalTimesheetCount;
          this.weeklyTotal = response.data.totalWorked;
          this.pageTotal = response.data.pageTotal;
          this.updateRegisterHourForm.reset();
          this.spinner.hide();
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  openUpdateModal(dataItem: TimeSheetDto, isEditable: boolean = false) {
    this.isEditable = isEditable;
    this.dataItem = dataItem;
    this.updateRegisterHourForm.setValue({
      description: dataItem.description,
      paused: Math.floor(dataItem?.paused / 60) || 0,
      by: moment(dataItem.by).format(DATE_TIME_FORMAT_LOCAL),
      customerId: dataItem.customerId || null,
      until: moment(dataItem.until).format(DATE_TIME_FORMAT_LOCAL),
      projectId: dataItem.projectId || null,
      taskId: dataItem.taskId || null,
    });
    this.updateId = dataItem._id;
    this.showUpdateModal = true;
  }

  openConfirmDeleteTimeSheetModal(dataItem: TimeSheetDto): void {
    this.dataItem = dataItem;
    this.confirmDeleteTimeSheetModal = true;
  }

  handleDelete() {
    this.spinner.show();
    this.timeSheetService
      .deleteTimeSheet(this.dataItem._id)
      .pipe(
        tap((resp) => {
          this.filterData(this.currentPage);
          this.toaster.success(resp?.message);
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleUpdate(): void {
    const formData = this.updateRegisterHourForm.value;

    const tempBy = formData.by ? moment(formData.by) : null;
    const tempUntil = formData.until ? moment(formData.until) : null;
    const today = moment();

    if (!!tempBy && !!tempUntil) {
      if (!tempUntil.isAfter(tempBy)) {
        this.updateRegisterHourForm.get('until')?.setErrors({ past: true });
      }

      if (
        Math.abs(tempBy.diff(tempUntil, 'minutes')) < formData.paused ||
        (formData.until === null &&
          Math.abs(tempBy.diff(moment(), 'minutes')) < formData.paused)
      ) {
        this.updateRegisterHourForm.get('paused')?.setErrors({ greater: true });
      }
    }

    if (!formData.customerId && !formData.projectId && !formData.taskId) {
      this.updateRegisterHourForm
        .get('customerId')
        ?.setErrors({ required: true });
    } else {
      this.updateRegisterHourForm.get('customerId')?.setErrors(null);
    }

    if (this.updateRegisterHourForm.invalid) {
      this.updateRegisterHourForm.markAllAsTouched();
      this.updateFormErrors = valueChanges(
        this.updateRegisterHourForm,
        { ...this.updateFormErrors },
        this.formErrorMessages
      );
      return;
    }

    const by = tempBy.utc();
    let until = tempUntil.utc();

    if (by.isAfter(until)) {
      until = until.add(1, 'days');
    }

    this.spinner.show();

    const body = {
      ...this.updateRegisterHourForm.value,
      timeSheetDate: tempBy.format(DATE_FORMAT),
      businessId: this.businessId,
      paused: this.updateRegisterHourForm.value.paused,
      until,
      by,
    };
    this.timeSheetService
      .updateTimeSheet(this.updateId, body)
      .pipe(
        tap((resp) => {
          this.filterData(this.currentPage);
          this.showUpdateModal = false;
          this.updateId = null;
          this.toaster.success(resp?.message);
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handleMarkAsReview(id: string): void {
    this.spinner.show();
    const body: MarkAsDto = {
      isMark: true,
      businessId: this.businessId,
    };
    this.timeSheetService
      .markAsReview(id, body)
      .pipe(
        tap((resp) => {
          this.filterData(this.currentPage);
          this.toaster.success(resp?.message);
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  openMarkAsBuildModal(dataItem: TimeSheetDto): void {
    this.dataItem = dataItem;
    this.updateId = dataItem._id;
    this.showMarkAsBuildModal = true;
  }

  handleMarkAsBuild(): void {
    this.spinner.show();
    const body: MarkAsDto = {
      isBuild: true,
      buildDescription: this.buildDescription.value,
      businessId: this.businessId,
    };
    this.timeSheetService
      .markAsReview(this.updateId, body)
      .pipe(
        tap((resp) => {
          this.filterData(this.currentPage);
          this.showMarkAsBuildModal = false;
          this.toaster.success(resp?.message);
          this.buildDescription.reset();
          this.updateId = null;
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  closeUpdateModal(): void {
    this.showUpdateModal = false;
    this.updateFormErrors = {
      customerId: '',
      until: '',
      description: '',
      paused: '',
      by: '',
    };
    this.updateRegisterHourForm.reset();
  }

  closeMarkAsBuildModal(): void {
    this.showMarkAsBuildModal = false;
    this.buildDescription.reset();
  }

  ExportToPdf(): void {
    this.spinner.show();
    const from = this.filterForm.value.from
      ? moment(this.filterForm.value.from).format(DATE_FORMAT)
      : null;
    const to = this.filterForm.value.to
      ? moment(this.filterForm.value.to).format(DATE_FORMAT)
      : moment().format(DATE_FORMAT);
    const body: DownloadTimesheetPdf = {
      config: {
        reportTitle: 'Timesheet-Report',
        filter: {
          ...this.filterForm.value,
          businessId: this.businessId,
          from,
          to,
        },
        direction:
          localStorage.getItem('NuMetric|lang') === 'en' ? 'ltr' : 'rtl',
        fieldNames: this.languageService.translate(this.fieldNames),
      },
    };
    this.timeSheetService
      .downloadTimesheetPdfByAdmin(body)
      .pipe(
        tap((resp) => {
          this.spinner.hide();
          const a = document.createElement('a');
          const blob = new Blob([resp], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `Time-Sheet-Report.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.toaster.success('PDF downloaded');
        }),
        catchError((error) => {
          this.spinner.hide();
          this.toaster.error(
            this.translateService.instant('Something went wrong!')
          );
          console.log('Error occurred:', error);
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
