import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BusinessService } from 'src/app/shared/services/business.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { ProfileManagementService } from '../../profile-management.service';
import { getCurrencySymbol } from '@angular/common';

@Component({
  selector: 'app-mailing-config',
  templateUrl: './mailing-config.component.html',
  styleUrls: ['./mailing-config.component.scss']
})
export class MailingConfigComponent implements OnInit {

  formErrors = {
    email: ''
  }

  formErrorMessages = {
    email: {
      required: 'Email is required'
    }
  }

  business$: Observable<any>;
  businessId;
  businessDetails;
  addNewEmail = false;
  emailToDelete = null;
  deleteEmailModal = false;
  instructionModal = false;
  configMail = {
    email: '',
    isVerified: false
  }

  mailConfigForm: FormGroup
  constructor(private fb: FormBuilder,
    private businessService: BusinessService,
    private toastr: ToastrService,
    private profileService: ProfileManagementService,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>
    ) {
      this.business$ = store.pipe(select(selectBusiness));
     }

  ngOnInit(): void {
    this.createForm();
    this.getConfigMail();
  }

  getConfigMail(): void {
    this.business$.subscribe(business => {
      console.log(business);
      
      this.businessId = business?.businessId?._id;
      this.businessDetails = business;
      const { emailConfig: { email='', isVerified = false} = {} } = business?.businessId;
      this.configMail = { email, isVerified };
      console.log("configMail", business, this.configMail)
    })
  }

  createForm() {
    this.mailConfigForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    })

    this.formErrors = valueChanges(this.mailConfigForm, { ...this.formErrors }, this.formErrorMessages)
  }

  addNewMail() {
    if (this.mailConfigForm.invalid) {
      this.mailConfigForm.markAllAsTouched()
      this.formErrors = valueChanges(this.mailConfigForm, { ...this.formErrors }, this.formErrorMessages)
      return
    }
    this.spinner.show()
    this.businessService.addMailingId(this.businessId,this.mailConfigForm.value.email)
      .subscribe(resp => {
        this.spinner.hide()
        if (resp?.success) {
          this.configMail = {
            email: this.mailConfigForm.value.email,
            isVerified: this.mailConfigForm.value.isVerified
          };
          this.addNewEmail = false;
          const data = {
            businessId: resp.data,
            permission: this.businessDetails.permission,
            _id: this.businessDetails._id
          }
          this.businessService.setBusiness(data);
          this.toastr.success('Mailing configuration added, please check your mailbox.');
        }
        else{
          this.toastr.error(resp?.message);
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message || 'Something went wrong');
      })

  }

  editMail(): void {
    this.mailConfigForm.patchValue({
      email:this.configMail.email
    })
    this.addNewEmail = true;
  }

  verifyMailingId(): void {
    this.spinner.show();
    this.businessService.checkMailingId(this.businessId, this.configMail.email).subscribe(resp => {
      if(resp?.success){
        this.spinner.hide();
        this.configMail.isVerified = true;
        const data = {
          businessId: resp.data,
          permission: this.businessDetails.permission,
          _id: this.businessDetails._id
        }
        this.businessService.setBusiness(data);
        this.toastr.success(resp?.message)
      }
      else {
        this.spinner.hide();
        this.toastr.error(resp?.message)
      }
    },(err) => {
      this.spinner.hide();
      this.toastr.error(err?.message)
    })
  }


  disconnectEmailing(): void {
    this.spinner.show();
    this.businessService.deleteMailingId(this.businessId,this.configMail.email).subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.configMail = null;
        this.addNewEmail = true;
        const data = {
          businessId: resp.data,
          permission: this.businessDetails.permission,
          _id: this.businessDetails._id
        }
        this.businessService.setBusiness(data);
        this.toastr.success('Disconnect successfully');
      }
    }, (error) => {
      this.toastr.error(error?.error?.message || 'Internal server error');
      this.spinner.hide();
    });
  }

}
