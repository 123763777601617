import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser, selectUserBusinesses } from 'src/app/store/selectors/user.selectors';
import { SubscriptionService } from '../../settings-modules/subscription/subscription.service';
import { ToastrService } from 'ngx-toastr';
import { ProfileManagementService } from '../../settings-modules/profile-management/profile-management.service';
import { UserService } from 'src/app/shared/services/user.service';

@Component({
  selector: 'app-businesses-management',
  templateUrl: './businesses-management.component.html',
  styleUrls: ['./businesses-management.component.scss']
})
export class BusinessesManagementComponent implements OnInit, OnDestroy {

  constructor(private store: Store<RootReducerState>,
              private router: Router,
              private subscriptionService: SubscriptionService,
              private toastr: ToastrService,
              private userService: UserService,
              private profileManagementService: ProfileManagementService,
              private spinner: NgxSpinnerService) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.user$ = this.store.pipe(select(selectUser));
  }

  businesses$: Observable <any>;
  user$: Observable <any>;
  unsubscribe$ = new Subject();
  userId = '';
  businessCount;
  openDeleteAccountModal = false;
  userDetails = null;

  ngOnInit(): void {
    this.user$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((user) => {
      this.userId = user._id;
      this.userDetails = {...user, DOB: user?.DOB?.split('T')[0]};
    });
    this.businesses$.pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      this.businessCount = business;
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editBusiness(id): void {
    this.router.navigate(['/settings/businesses'], { queryParams: { id } });
  }

  route(): void {
    this.router.navigate(['/settings/businesses'], { queryParams: { showForm: true }});
  }


  deleteAccount(): void {
    console.log(this.userDetails);
    this.spinner.show();
    if (this.userDetails?.subscription?.subId) {
      const body = {
        subId: this.userDetails?.subscription?.subId
      };
      this.subscriptionService.cancelPlan(body)
      .subscribe(resp => {
        if (resp?.success){
         this.closeAccount();
        }
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message || 'Something went wrong');
      });
    } else {
      this.closeAccount();
    }
  }

  closeAccount(): void {
    this.profileManagementService.closeAccount().subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.toastr.success('Account Closed');
        // this.userService.logout();
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    });
  }

}
