export const CountryCodes = {
  countries: [
    {
      code: 'Abkhazia(+7 840)',
    },
    {
      code: 'Afghanistan(+93)',
    },
    {
      code: 'Albania(+355)',
    },
    {
      code: 'Algeria(+213)',
    },
    {
      code: 'American(+1 684)',
    },
    {
      code: 'Andorra(+376)',
    },
    {
      code: 'Angola(+244)',
    },
    {
      code: 'Anguilla(+1 264)',
    },
    {
      code: 'Antigua(+1 268)',
    },
    {
      code: 'Argentina(+54)',
    },
    {
      code: 'Armenia(+374)',
    },
    {
      code: 'Aruba(+297)',
    },
    {
      code: 'Ascension(+247)',
    },
    {
      code: 'Australia(+61)',
    },
    {
      code: 'Australian(+672)',
    },
    {
      code: 'Austria(+43)',
    },
    {
      code: 'Azerbaijan(+994)',
    },
    {
      code: 'Bahamas(+1 242)',
    },
    {
      code: 'Bahrain(+973)',
    },
    {
      code: 'Bangladesh(+880)',
    },
    {
      code: 'Barbados(+1 246)',
    },
    {
      code: 'Barbuda(+1 268)',
    },
    {
      code: 'Belarus(+375)',
    },
    {
      code: 'Belgium(+32)',
    },
    {
      code: 'Belize(+501)',
    },
    {
      code: 'Benin(+229)',
    },
    {
      code: 'Bermuda(+1 441)',
    },
    {
      code: 'Bhutan(+975)',
    },
    {
      code: 'Bolivia(+591)',
    },
    {
      code: 'Bosnia(+387)',
    },
    {
      code: 'Botswana(+267)',
    },
    {
      code: 'Brazil(+55)',
    },
    {
      code: 'British(+246)',
    },
    {
      code: 'British(+1 284)',
    },
    {
      code: 'Brunei(+673)',
    },
    {
      code: 'Bulgaria(+359)',
    },
    {
      code: 'Burkina(+226)',
    },
    {
      code: 'Burundi(+257)',
    },
    {
      code: 'Cambodia(+855)',
    },
    {
      code: 'Cameroon(+237)',
    },
    {
      code: 'Canada(+1)',
    },
    {
      code: 'Cape(+238)',
    },
    {
      code: 'Cayman(+ 345)',
    },
    {
      code: 'Central(+236)',
    },
    {
      code: 'Chad(+235)',
    },
    {
      code: 'Chile(+56)',
    },
    {
      code: 'China(+86)',
    },
    {
      code: 'Christmas(+61)',
    },
    {
      code: 'Cocos(+61)',
    },
    {
      code: 'Colombia(+57)',
    },
    {
      code: 'Comoros(+269)',
    },
    {
      code: 'Congo(+242)',
    },
    {
      code: 'Congo(+243)',
    },
    {
      code: 'Cook(+682)',
    },
    {
      code: 'Costa(+506)',
    },
    {
      code: 'Croatia(+385)',
    },
    {
      code: 'Cuba(+53)',
    },
    {
      code: 'Curacao(+599)',
    },
    {
      code: 'Cyprus(+537)',
    },
    {
      code: 'Czech(+420)',
    },
    {
      code: 'Denmark(+45)',
    },
    {
      code: 'Diego(+246)',
    },
    {
      code: 'Djibouti(+253)',
    },
    {
      code: 'Dominica(+1 767)',
    },
    {
      code: 'Dominican(+1 809)',
    },
    {
      code: 'East(+670)',
    },
    {
      code: 'Easter(+56)',
    },
    {
      code: 'Ecuador(+593)',
    },
    {
      code: 'Egypt(+20)',
    },
    {
      code: 'El(+503)',
    },
    {
      code: 'Equatorial(+240)',
    },
    {
      code: 'Eritrea(+291)',
    },
    {
      code: 'Estonia(+372)',
    },
    {
      code: 'Ethiopia(+251)',
    },
    {
      code: 'Falkland(+500)',
    },
    {
      code: 'Faroe(+298)',
    },
    {
      code: 'Fiji(+679)',
    },
    {
      code: 'Finland(+358)',
    },
    {
      code: 'France(+33)',
    },
    {
      code: 'French(+596)',
    },
    {
      code: 'French(+594)',
    },
    {
      code: 'French(+689)',
    },
    {
      code: 'Gabon(+241)',
    },
    {
      code: 'Gambia(+220)',
    },
    {
      code: 'Georgia(+995)',
    },
    {
      code: 'Germany(+49)',
    },
    {
      code: 'Ghana(+233)',
    },
    {
      code: 'Gibraltar(+350)',
    },
    {
      code: 'Greece(+30)',
    },
    {
      code: 'Greenland(+299)',
    },
    {
      code: 'Grenada(+1 473)',
    },
    {
      code: 'Guadeloupe(+590)',
    },
    {
      code: 'Guam(+1 671)',
    },
    {
      code: 'Guatemala(+502)',
    },
    {
      code: 'Guinea(+224)',
    },
    {
      code: 'Guinea(+245)',
    },
    {
      code: 'Guyana(+595)',
    },
    {
      code: 'Haiti(+509)',
    },
    {
      code: 'Honduras(+504)',
    },
    {
      code: 'Hong(+852)',
    },
    {
      code: 'Hungary(+36)',
    },
    {
      code: 'Iceland(+354)',
    },
    {
      code: 'India(+91)',
    },
    {
      code: 'Indonesia(+62)',
    },
    {
      code: 'Iran(+98)',
    },
    {
      code: 'Iraq(+964)',
    },
    {
      code: 'Ireland(+353)',
    },
    {
      code: 'Israel(+972)',
    },
    {
      code: 'Italy(+39)',
    },
    {
      code: 'Ivory(+225)',
    },
    {
      code: 'Jamaica(+1 876)',
    },
    {
      code: 'Japan(+81)',
    },
    {
      code: 'Jordan(+962)',
    },
    {
      code: 'Kazakhstan(+7 7)',
    },
    {
      code: 'Kenya(+254)',
    },
    {
      code: 'Kiribati(+686)',
    },
    {
      code: 'Kuwait(+965)',
    },
    {
      code: 'Kyrgyzstan(+996)',
    },
    {
      code: 'Laos(+856)',
    },
    {
      code: 'Latvia(+371)',
    },
    {
      code: 'Lebanon(+961)',
    },
    {
      code: 'Lesotho(+266)',
    },
    {
      code: 'Liberia(+231)',
    },
    {
      code: 'Libya(+218)',
    },
    {
      code: 'Liechtenstein(+423)',
    },
    {
      code: 'Lithuania(+370)',
    },
    {
      code: 'Luxembourg(+352)',
    },
    {
      code: 'Macau(+853)',
    },
    {
      code: 'Macedonia(+389)',
    },
    {
      code: 'Madagascar(+261)',
    },
    {
      code: 'Malawi(+265)',
    },
    {
      code: 'Malaysia(+60)',
    },
    {
      code: 'Maldives(+960)',
    },
    {
      code: 'Mali(+223)',
    },
    {
      code: 'Malta(+356)',
    },
    {
      code: 'Marshall(+692)',
    },
    {
      code: 'Martinique(+596)',
    },
    {
      code: 'Mauritania(+222)',
    },
    {
      code: 'Mauritius(+230)',
    },
    {
      code: 'Mayotte(+262)',
    },
    {
      code: 'Mexico(+52)',
    },
    {
      code: 'Micronesia(+691)',
    },
    {
      code: 'Midway(+1 808)',
    },
    {
      code: 'Moldova(+373)',
    },
    {
      code: 'Monaco(+377)',
    },
    {
      code: 'Mongolia(+976)',
    },
    {
      code: 'Montenegro(+382)',
    },
    {
      code: 'Montserrat(+1664)',
    },
    {
      code: 'Morocco(+212)',
    },
    {
      code: 'Myanmar(+95)',
    },
    {
      code: 'Namibia(+264)',
    },
    {
      code: 'Nauru(+674)',
    },
    {
      code: 'Nepal(+977)',
    },
    {
      code: 'Netherlands(+31)',
    },
    {
      code: 'Netherlands(+599)',
    },
    {
      code: 'Nevis(+1 869)',
    },
    {
      code: 'New(+687)',
    },
    {
      code: 'New(+64)',
    },
    {
      code: 'Nicaragua(+505)',
    },
    {
      code: 'Niger(+227)',
    },
    {
      code: 'Nigeria(+234)',
    },
    {
      code: 'Niue(+683)',
    },
    {
      code: 'Norfolk(+672)',
    },
    {
      code: 'North(+850)',
    },
    {
      code: 'Northern(+1 670)',
    },
    {
      code: 'Norway(+47)',
    },
    {
      code: 'Oman(+968)',
    },
    {
      code: 'Pakistan(+92)',
    },
    {
      code: 'Palau(+680)',
    },
    {
      code: 'Palestinian(+970)',
    },
    {
      code: 'Panama(+507)',
    },
    {
      code: 'Papua(+675)',
    },
    {
      code: 'Paraguay(+595)',
    },
    {
      code: 'Peru(+51)',
    },
    {
      code: 'Philippines(+63)',
    },
    {
      code: 'Poland(+48)',
    },
    {
      code: 'Portugal(+351)',
    },
    {
      code: 'Puerto(+1 787)',
    },
    {
      code: 'Qatar(+974)',
    },
    {
      code: 'Reunion(+262)',
    },
    {
      code: 'Romania(+40)',
    },
    {
      code: 'Russia(+7)',
    },
    {
      code: 'Rwanda(+250)',
    },
    {
      code: 'Samoa(+685)',
    },
    {
      code: 'San(+378)',
    },
    {
      code: 'Saudi(+966)',
    },
    {
      code: 'Senegal(+221)',
    },
    {
      code: 'Serbia(+381)',
    },
    {
      code: 'Seychelles(+248)',
    },
    {
      code: 'Sierra(+232)',
    },
    {
      code: 'Singapore(+65)',
    },
    {
      code: 'Slovakia(+421)',
    },
    {
      code: 'Slovenia(+386)',
    },
    {
      code: 'Solomon(+677)',
    },
    {
      code: 'South(+27)',
    },
    {
      code: 'South(+500)',
    },
    {
      code: 'South(+82)',
    },
    {
      code: 'Spain(+34)',
    },
    {
      code: 'Sri(+94)',
    },
    {
      code: 'Sudan(+249)',
    },
    {
      code: 'Suriname(+597)',
    },
    {
      code: 'Swaziland(+268)',
    },
    {
      code: 'Sweden(+46)',
    },
    {
      code: 'Switzerland(+41)',
    },
    {
      code: 'Syria(+963)',
    },
    {
      code: 'Taiwan(+886)',
    },
    {
      code: 'Tajikistan(+992)',
    },
    {
      code: 'Tanzania(+255)',
    },
    {
      code: 'Thailand(+66)',
    },
    {
      code: 'Timor(+670)',
    },
    {
      code: 'Togo(+228)',
    },
    {
      code: 'Tokelau(+690)',
    },
    {
      code: 'Tonga(+676)',
    },
    {
      code: 'Trinidad(+1 868)',
    },
    {
      code: 'Tunisia(+216)',
    },
    {
      code: 'Turkey(+90)',
    },
    {
      code: 'Turkmenistan(+993)',
    },
    {
      code: 'Turks(+1 649)',
    },
    {
      code: 'Tuvalu(+688)',
    },
    {
      code: 'U(+1 340)',
    },
    {
      code: 'Uganda(+256)',
    },
    {
      code: 'Ukraine(+380)',
    },
    {
      code: 'United(+971)',
    },
    {
      code: 'United(+44)',
    },
    {
      code: 'United(+1)',
    },
    {
      code: 'Uruguay(+598)',
    },
    {
      code: 'Uzbekistan(+998)',
    },
    {
      code: 'Vanuatu(+678)',
    },
    {
      code: 'Venezuela(+58)',
    },
    {
      code: 'Vietnam(+84)',
    },
    {
      code: 'Wake(+1 808)',
    },
    {
      code: 'Wallis(+681)',
    },
    {
      code: 'Yemen(+967)',
    },
    {
      code: 'Zambia(+260)',
    },
    {
      code: 'Zanzibar(+255)',
    },
    {
      code: 'Zimbabwe(+263)',
    },
  ],
};
