<div class="flex h-screen">
    <div class="p-8 md:p-14 lg:p-20 w-full lg:w-5/12 max-h-full overflow-y-auto no-scrollbar">
       <div class="mb-12"><a routerlink="/" href="/"><img src="assets/images/logo.png" width="150" alt="logo"></a></div>
       <div class="w-full lg:w-10/12">
          <div class="Poppins-Bold text-3xl">{{'Personal Details.' | translate}}</div>
          <p class="Poppins-SemiBold font-color-black-03 py-2">{{'Please enter your personal details here.' | translate }}</p>
          <div class="text-red-500" *ngIf='error'>{{error | translate}}</div>
          <form [formGroup]='personalDetailForm' novalidate class="py-2 px-0">
             <div class="mb-4">
                <div class="flex md:flex-row flex-col md:space-x-4 md:space-x-reverse">
                   <div class="md:w-1/2 w-full">
                      <label class="Poppins-Bold block font-color-black-05 mb-2">{{'First Name' | translate}}</label>
                      <input formControlName='firstName'
                         class="new-input w-full px-5 py-3" type="text" name="firstName">
                      <div *ngIf='formErrors.firstName' class="text-sm text-red-400 ms-2">{{formErrors.firstName | translate}}</div>
                   </div>
                   <div class="md:w-1/2 w-full">
                      <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Last Name' | translate}}</label>
                      <input formControlName='lastName'
                         class="new-input w-full px-5 py-3" type="text" name="lastName">
                      <div *ngIf='formErrors.lastName' class="text-sm text-red-400 ms-2">{{formErrors.lastName | translate}}</div>
                   </div>
                </div>
             </div>
             <div class="mb-4">
                <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Business Name' | translate}}</label>
                <input formControlName='businessName' class="new-input px-5 py-3 w-full" name="businessName">
                <div *ngIf='formErrors.businessName' class="text-sm text-red-400 ms-2">{{formErrors.businessName | translate}}</div>
             </div>
             <div class="mb-4">
                <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Business Industry' | translate}}</label>
                <ng-select id='industry-select' [items]="industries" class="custom-selector border-0 p-0 w-full focus:ring-0" bindLabel="text"
                    bindValue="text" [addTag]="true" addTagText='Create ' formControlName="businessIndustry" placeholder='{{"Select one" | translate}}'>
                    <ng-option *ngFor="let industry of industries" [value]="industry.id">{{industry.name | translate}}</ng-option>
                    </ng-select>
                <div *ngIf='formErrors.businessIndustry' class="text-sm text-red-400 ms-2">{{formErrors.businessIndustry | translate}}</div>
             </div>
             <div class="mb-4">
                <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Business Currency' | translate}}</label>
                <select formControlName='businessCurrency' id='currency-select' class="new-input px-5 py-3 w-full industry-select">
                  <ng-container *ngFor="let item of currencyCodes">
                     <option [value]="item.text">{{item.text}}</option>
                  </ng-container>
                </select>
                <div *ngIf='formErrors.businessCurrency' class="text-sm text-red-400 ms-2">{{formErrors.businessCurrency | translate}}</div>
             </div>
             <div class="mb-4">
                <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Business Country' | translate}}</label>
                <select formControlName='country' id='country-select' class="new-input px-5 py-3 w-full industry-select">
                  <ng-container *ngFor="let item of allCountries">
                     <option [value]="item.text">{{item.text}}</option>
                  </ng-container>
                </select>
                <div *ngIf='formErrors.country' class="text-sm text-red-400 ms-2">{{formErrors.country | translate}}</div>
             </div>
             <div class="mb-4">
                <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Type of organisation' | translate}}</label>
                <select id='organisation-select' formControlName='businessOrganisationType' class="new-input px-5 py-3 w-full industry-select">
                  <option value="view">{{'Sole' | translate}}</option>
                  <option value="edit">{{'Partnership' | translate }}</option>
                  <option value="none">{{'Corporation' | translate }}</option>
                  <option value="none">{{'Other' | translate }}</option>
               </select>
               <div *ngIf='formErrors.businessOrganisationType' class="text-sm text-red-400 ms-2">{{formErrors.businessOrganisationType | translate}}</div>
            </div>
            <div class="mb-8">
               <label class="Poppins-Bold block font-color-black-05 mb-2">{{'Fiscal year Details' | translate}}</label>
               <div class="flex md:flex-row flex-col md:space-x-4 md:space-x-reverse max-w-full">
                  <div class="md:w-1/2 w-full">
                     <select (change)="onMonthChange($event)" id='month-select' formControlName='month' class="new-input px-5 py-3 industry-select">
                        <ng-container *ngFor="let item of allMonths">
                           <option [value]="item.text">{{item.text}}</option>
                        </ng-container>
                     </select>
                     <div *ngIf='formErrors.month' class="text-sm text-red-400 ms-2">{{formErrors.month | translate}}</div>
                  </div>
                  <div class="md:w-1/2 w-full">
                     <select id='date-select' formControlName='date' class="new-input px-5 py-3 industry-select">
                        <ng-container *ngFor="let item of allDaysOfMonth">
                           <option [value]="item.text">{{item.text}}</option>
                        </ng-container>
                     </select>
                     <div *ngIf='formErrors.date' class="text-sm text-red-400 ms-2">{{formErrors.date | translate}}</div>
                  </div>
               </div>
             </div>
             <app-button buttonText='Good to Go!' type='primary' size='large' [fullWidth]='true' (handleClick)='sendPersonalDetails()' ></app-button>
          </form>
       </div>
    </div>
    <div class="w-0 lg:w-7/12 flex items-center justify-center bg-light-gray-1 min-h-screen">
      <img src="assets/images/login.png" width="600" alt="" srcset="">
    </div>
 </div>