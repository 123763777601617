import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserManagementComponent } from './user-management.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { InviteUserComponent } from './components/invite-user/invite-user.component';
import { ChooseUserRoleComponent } from './components/choose-user-role/choose-user-role.component';
import { UsersListComponent } from './components/users-list/users-list.component';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';

const routes: Routes = [
  {
    path: '',
    component: UserManagementComponent,
    children: [
      {
        path: '',
        component: UsersListComponent
      },
      {
        path: 'choose-user-role',
        component: ChooseUserRoleComponent
      },
      {
        path: 'invite-user/:role',
        component: InviteUserComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    UserManagementComponent,
    InviteUserComponent,
    ChooseUserRoleComponent,
    UsersListComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    RouterModule.forChild(routes),
    ReactiveFormsModule,
    DirectivesModule
  ]
})
export class UserManagementModule { }
