<div>
  <form [formGroup]="form">
    <div class="flex mb-3">
      <div class="w-full">
        <div class="">
          <fieldset [disabled]="!formEditable">
            <label
              for="date"
              class="block text-sm Poppins-Medium font-color-05"
              >{{ "Date" | translate }}</label
            >
            <input
              id="date"
              formControlName="date"
              type="date"
              class="new-date"
            />
            <span class="text-red-500 text-sm" *ngIf="formErrors?.date">{{
              formErrors.date
            }}</span>
          </fieldset>
        </div>
      </div>
    </div>
    <div class="flex mb-3">
      <div class="w-full">
        <label
          for="firstName"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Description" | translate }}</label
        >
        <textarea
          formControlName="description"
          placeholder="{{ 'Enter Description' | translate }}"
          name="description"
          class="new-input !h-32"
        ></textarea>
      </div>
    </div>
    <div class="flex mb-3">
      <div class="w-full">
        <label
          for="firstName"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Credit Account" | translate }}</label
        >
        <ng-select
          [items]="accounts$ | async"
          class="new-dropdown"
          bindLabel="accountName"
          placeholder="{{ 'Select Type' | translate }}"
          formControlName="creditFrom"
          [readonly]="!formEditable"
          groupBy="accountType"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.accountName }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <span class="text-red-500 text-sm" *ngIf="formErrors?.account">{{
          formErrors.account
        }}</span>
      </div>
    </div>
    <div class="flex mb-3">
      <div class="w-full">
        <label
          for="firstName"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Debit Account" | translate }}</label
        >
        <ng-select
          [items]="accounts$ | async"
          class="new-dropdown"
          bindLabel="accountName"
          placeholder="{{ 'Select Type' | translate }}"
          formControlName="debitTo"
          [readonly]="!formEditable"
          groupBy="accountType"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.accountName }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <span class="text-red-500 text-sm" *ngIf="formErrors?.account">{{
          formErrors.account
        }}</span>
      </div>
    </div>
    <div class="flex mb-3">
      <div class="w-full">
        <label
          for="firstName"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Category" | translate }}</label
        >
        <fieldset disabled="disabled">
          <input type="text" formControlName="category" class="new-input" />
        </fieldset>
      </div>
    </div>
    <div class="flex mb-3">
      <div class="w-full">
        <label for="" class="block text-sm Poppins-Medium font-color-05">{{
          "Amount" | translate
        }}</label>
        <fieldset [disabled]="!formEditable">
          <input
            formControlName="amount"
            type="number"
            class="new-input"
            placeholder="{{ 'Amount' | translate }}"
          />
        </fieldset>
        <span class="text-red-500 text-sm" *ngIf="formErrors?.amount">{{
          formErrors.amount
        }}</span>
        <span class="text-red-500 text-sm" *ngIf="invalidAmount">{{
          "Invalid Amount"
        }}</span>
      </div>
    </div>
  </form>
</div>
