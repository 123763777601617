<div
  [class]="
    classNames +
    ' ' +
    'rounded-md cursor-pointer float-right border-[0.5px] bg-white border-solid border-[#1C1C1C66] flex justify-center items-center'
  "
  (click)="buttonClick.emit()"
>
  <ng-content></ng-content>
</div>
