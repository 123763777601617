<div class="flex gap-4">
  <div class="flex flex-col gap-4 flex-grow">
    <!-- EMAIL -->
    <div class="flex flex-col new-design-round-border p-4 gap-3">
      <div class="flex flex-col">
        <div class="text-sm">
          {{ "Warehouse Contact" | translate }}
        </div>
        <div class="text-xxs">
          {{ "warehouse contact info" | translate }}
        </div>
      </div>
      <div class="flex gap-4 items-center">
        <label
          class="w-[28%] text-[#1C1C1C66] block"
          for="deliveryEmailAddress"
          >{{ "Email" | translate }}</label
        >
        <input
          id="deliveryEmailAddress"
          [formControl]="deliveryEmailAddress"
          placeholder="{{ 'Email of ware house owner' | translate }}"
          type="text"
          class="new-input"
        />
      </div>
    </div>
    <!-- FOOTER -->
    <div class="flex flex-col new-design-round-border p-4 gap-3">
      <div class="flex text-sm">
        {{ "POS Invoice footer" | translate }}
      </div>
      <div class="flex gap-4 items-center">
        <label class="block text-[#1C1C1C66]" for="deliveryEmailAddress">{{
          "Notes & Terms" | translate
        }}</label>
        <textarea
          rows="4"
          id="note"
          [formControl]="footerNote"
          placeholder="{{ 'Enter invoice footer note' | translate }}"
          class="new-input"
        ></textarea>
      </div>
    </div>
  </div>
  <div class="flex flex-col gap-4 flex-grow">
    <div
      class="new-design-round-border flex items-center p-4 gap-4 justify-between"
    >
      <span class="text-sm">
        {{ "Display company logo on POS Invoice" | translate }}
      </span>
      <label for="isLogo" class="flex items-center cursor-pointer relative">
        <input
          type="checkbox"
          [formControl]="isLogo"
          id="isLogo"
          class="sr-only peer"
        />
        <div
          [ngClass]="{
            'after:bg-white': isLogo?.value,
            'after:bg-black bg-[#d9d9d9]': !isLogo?.value
          }"
          class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
        ></div>
      </label>
    </div>
    <div
      class="new-design-round-border flex items-center p-4 gap-4 justify-between"
    >
      <span class="text-sm">
        {{ "Send Invoices by SMS" | translate }}
      </span>
      <label for="sendSms" class="flex items-center cursor-pointer relative">
        <input
          type="checkbox"
          [formControl]="sendSms"
          id="sendSms"
          class="sr-only peer"
        />
        <div
          [ngClass]="{
            'after:bg-white': sendSms?.value,
            'after:bg-black bg-[#d9d9d9]': !sendSms?.value
          }"
          class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
        ></div>
      </label>
    </div>
    <!-- BUTTONS -->
    <div class="flex flex-col gap-3 p-6 items-center justify-center">
      <app-submit
        className="bg-[#026AA2] text-white w-48"
        value="Save All Changes"
        (clicked)="updateBusiness()"
      >
      </app-submit>
      <app-submit
        className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w-48"
        value="Discard"
        (clicked)="cancelUpdate()"
      >
      </app-submit>
    </div>
  </div>
</div>
