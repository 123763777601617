export const SAC = [
    {
      "code": 99,
      "description": "All Services"
    },
    {
      "code": 9954,
      "description": "Construction services"
    },
    {
      "code": 995411,
      "description": "Construction services of single dwelling or multi dwelling or multi-storied residential buildings"
    },
    {
      "code": 995412,
      "description": "Construction services of other residential buildings such as old age homes, homeless shelters, hostels etc"
    },
    {
      "code": 995413,
      "description": "Construction services of industrial buildings such as buildings used for production activities (used for assembly line activities), workshops, storage buildings and other similar industrial buildings"
    },
    {
      "code": 995414,
      "description": "Construction services of commercial buildings such as office buildings, exhibition & marriage halls, malls, hotels, restaurants, airports, rail or road terminals, parking garages, petrol and service stations, theatres and other similar buildings."
    },
    {
      "code": 995415,
      "description": "Construction services of other non-residential buildings such as educational institutions, hospitals, clinics including vertinary clinics, religious establishments, courts, prisons, museums and other similar buildings"
    },
    {
      "code": 995416,
      "description": "Construction Services of other buildings n.e.c"
    },
    {
      "code": 995419,
      "description": "Services involving Repair, alterations, additions, replacements, renovation, maintenance or remodelling of the buildings covered above."
    },
    {
      "code": 995421,
      "description": "General construction services of highways, streets, roads, railways and airfield runways, bridges and tunnels"
    },
    {
      "code": 995422,
      "description": "General construction services of harbours, waterways, dams, water mains and lines, irrigation and other waterworks"
    },
    {
      "code": 995423,
      "description": "General construction services of long-distance underground/overland/submarine pipelines, communication and electric power lines (cables); pumping stations and related works; transformer stations and related works."
    },
    {
      "code": 995424,
      "description": "General construction services of local water & sewage pipelines, electricity and communication cables & related works"
    },
    {
      "code": 995425,
      "description": "General construction services of mines and industrial plants"
    },
    {
      "code": 995426,
      "description": "General Construction services of Power Plants and its related infrastructure"
    },
    {
      "code": 995427,
      "description": "General construction services of outdoor sport and recreation facilities"
    },
    {
      "code": 995428,
      "description": "General construction services of other civil engineering works n.e.c."
    },
    {
      "code": 995429,
      "description": "Services involving Repair, alterations, additions, replacements, renovation, maintenance or remodelling of the constructions covered above."
    },
    {
      "code": 995431,
      "description": "Demolition services"
    },
    {
      "code": 995432,
      "description": "Site formation and clearance services including preparation services to make sites ready for subsequent construction work, test drilling & boring & core extraction, digging of trenches."
    },
    {
      "code": 995433,
      "description": "Excavating and earthmoving services"
    },
    {
      "code": 995434,
      "description": "Water well drilling services and septic system installation services"
    },
    {
      "code": 995435,
      "description": "Other site preparation services n.e.c"
    },
    {
      "code": 995439,
      "description": "Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above."
    },
    {
      "code": 995441,
      "description": "Installation, assembly and erection services of prefabricated buildings"
    },
    {
      "code": 995442,
      "description": "Installation, assembly and erection services of other prefabricated structures and constructions"
    },
    {
      "code": 995443,
      "description": "Installation services of all types of street furniture (e.g., bus shelters, benches, telephone booths, public toilets, etc.)"
    },
    {
      "code": 995444,
      "description": "Other assembly and erection services n.e.c."
    },
    {
      "code": 995449,
      "description": "Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above."
    },
    {
      "code": 995451,
      "description": "Pile driving and foundation services"
    },
    {
      "code": 995452,
      "description": "Building framing & Roof Framing services"
    },
    {
      "code": 995453,
      "description": "Roofing and waterproofing services"
    },
    {
      "code": 995454,
      "description": "Concrete services"
    },
    {
      "code": 995455,
      "description": "Structural steel erection services"
    },
    {
      "code": 995456,
      "description": "Masonry services"
    },
    {
      "code": 995457,
      "description": "Scaffolding services"
    },
    {
      "code": 995458,
      "description": "Other special trade construction services n.e.c."
    },
    {
      "code": 995459,
      "description": "Services involving Repair, alterations, additions, replacements, maintenance of the constructions covered above."
    },
    {
      "code": 995461,
      "description": "Electrical installation services including Electrical wiring & fitting services, fire alarm installation services, burglar alarm system installation services."
    },
    {
      "code": 995462,
      "description": "Water plumbing and drain laying services"
    },
    {
      "code": 995463,
      "description": "Heating, ventilation and air conditioning equipment installation services"
    },
    {
      "code": 995464,
      "description": "Gas fitting installation services"
    },
    {
      "code": 995465,
      "description": "Insulation services"
    },
    {
      "code": 995466,
      "description": "Lift and escalator installation services"
    },
    {
      "code": 995468,
      "description": "Other installation services n.e.c."
    },
    {
      "code": 995469,
      "description": "Services involving Repair, alterations, additions, replacements, maintenance of the installations covered above."
    },
    {
      "code": 995471,
      "description": "Glazing services"
    },
    {
      "code": 995472,
      "description": "Plastering services"
    },
    {
      "code": 995473,
      "description": "Painting services"
    },
    {
      "code": 995474,
      "description": "Floor and wall tiling services"
    },
    {
      "code": 995475,
      "description": "Other floor laying, wall covering and wall papering services"
    },
    {
      "code": 995476,
      "description": "Joinery and carpentry services"
    },
    {
      "code": 995477,
      "description": "Fencing and railing services"
    },
    {
      "code": 995478,
      "description": "Other building completion and finishing services n.e.c."
    },
    {
      "code": 995479,
      "description": "Services involving Repair, alterations, additions, replacements, maintenance of the completion/finishing works covered above."
    },
    {
      "code": 9961,
      "description": "Services in wholesale trade"
    },
    {
      "code": 996111,
      "description": "Services provided for a fee/commission or contract basis on wholesale trade"
    },
    {
      "code": 9962,
      "description": "Services in retail trade"
    },
    {
      "code": 996211,
      "description": "Services provided for a fee/commission or contract basis on retail trade"
    },
    {
      "code": 9963,
      "description": "Accommodation, food and beverage services"
    },
    {
      "code": 996311,
      "description": "Room or unit accommodation services provided by Hotels, INN, Guest House, Club etc"
    },
    {
      "code": 996312,
      "description": "Camp site services"
    },
    {
      "code": 996313,
      "description": "Recreational and vacation camp services"
    },
    {
      "code": 996321,
      "description": "Room or unit accommodation services for students in student residences"
    },
    {
      "code": 996322,
      "description": "Room or unit accommodation services provided by Hostels, Camps, Paying Guest etc"
    },
    {
      "code": 996329,
      "description": "Other room or unit accommodation services n.e.c."
    },
    {
      "code": 996331,
      "description": "Services provided by Restaurants, Cafes and similar eating facilities including takeaway services, Room services and door delivery of food."
    },
    {
      "code": 996332,
      "description": "Services provided by Hotels, INN, Guest House, Club etc including Room services, takeaway services and door delivery of food."
    },
    {
      "code": 996333,
      "description": "Services provided in Canteen and other similar establishments"
    },
    {
      "code": 996334,
      "description": "Catering Services in Exhibition halls, Events, Marriage Halls and other outdoor/indoor functions."
    },
    {
      "code": 996335,
      "description": "Catering services in trains, flights etc."
    },
    {
      "code": 996336,
      "description": "Preparation and/or supply services of food, edible preparations, alchoholic & non-alchocholic beverages to airlines and other transportation operators"
    },
    {
      "code": 996337,
      "description": "Other contract food services"
    },
    {
      "code": 996339,
      "description": "Other food, edible preparations, alchoholic & non-alchocholic beverages serving services n.e.c."
    },
    {
      "code": 9964,
      "description": "Passenger transport services"
    },
    {
      "code": 996411,
      "description": "Local land transport services of passengers by railways, metro, monorail, bus, tramway, autos, three wheelers, scooters and other motor vehicles"
    },
    {
      "code": 996412,
      "description": "Taxi services including radio taxi & other similar services; "
    },
    {
      "code": 996413,
      "description": "Non-scheduled local bus and coach charter services"
    },
    {
      "code": 996414,
      "description": "Other land transportation services of passengers."
    },
    {
      "code": 996415,
      "description": "Local water transport services of passengers by ferries, cruises etc"
    },
    {
      "code": 996416,
      "description": "Sightseeing transportation services by rail, land, water & air"
    },
    {
      "code": 996419,
      "description": "Other local transportation services of passengers n.e.c."
    },
    {
      "code": 996421,
      "description": "Long-distance transport services of passengers through Rail network by Railways, Metro etc"
    },
    {
      "code": 996422,
      "description": "Long-distance transport services of passengers through Road by Bus, Car, non-scheduled long distance bus and coach services, stage carriage etc"
    },
    {
      "code": 996423,
      "description": "Taxi services including radio taxi & other similar services"
    },
    {
      "code": 996424,
      "description": "Coastal and transoceanic (overseas) water transport services of passengers by Ferries, Cruise Ships etc"
    },
    {
      "code": 996425,
      "description": "Domestic/International Scheduled Air transport services of passengers"
    },
    {
      "code": 996426,
      "description": "Domestic/international non-scheduled air transport services of Passengers"
    },
    {
      "code": 996427,
      "description": "Space transport services of passengers"
    },
    {
      "code": 996429,
      "description": "Other long-distance transportation services of passengers n.e.c."
    },
    {
      "code": 9965,
      "description": "Goods Transport Services"
    },
    {
      "code": 996511,
      "description": "Road transport services of Goods including letters, parcels, live animals, household & office furniture, containers etc by refrigerator vehicles, trucks, trailers, man or animal drawn vehicles or any other vehicles."
    },
    {
      "code": 996512,
      "description": "Railway transport services of Goods including letters, parcels, live animals, household & office furniture, intermodal containers, bulk cargo etc"
    },
    {
      "code": 996513,
      "description": "Transport services of petroleum & natural gas, water, sewerage and other goods via pipeline"
    },
    {
      "code": 996519,
      "description": "Other land transport services of goods n.e.c."
    },
    {
      "code": 996521,
      "description": "Coastal and transoceanic (overseas) water transport services of goods by refrigerator vessels, tankers, bulk cargo vessels, container ships etc"
    },
    {
      "code": 996522,
      "description": "Inland water transport services of goods by refrigerator vessels, tankers and other vessels."
    },
    {
      "code": 996531,
      "description": "Air transport services of letters & parcels and other goods"
    },
    {
      "code": 996532,
      "description": "Space transport services of freight"
    },
    {
      "code": 9966,
      "description": "Rental services of transport vehicles with or without operators"
    },
    {
      "code": 996601,
      "description": "Rental services of road vehicles including buses, coaches, cars, trucks and other motor vehicles, with or without operator"
    },
    {
      "code": 996602,
      "description": "Rental services of water vessels including passenger vessels, freight vessels etc with or without operator"
    },
    {
      "code": 996603,
      "description": "Rental services of aircraft including passenger aircrafts, freight aircrafts etc with or without operator"
    },
    {
      "code": 996609,
      "description": "Rental services of other transport vehicles n.e.c. with or without operator"
    },
    {
      "code": 9967,
      "description": "Supporting services in transport"
    },
    {
      "code": 996711,
      "description": "Container handling services"
    },
    {
      "code": 996712,
      "description": "Customs House Agent services"
    },
    {
      "code": 996713,
      "description": "Clearing and forwarding services"
    },
    {
      "code": 996719,
      "description": "Other cargo and baggage handling services"
    },
    {
      "code": 996721,
      "description": "Refrigerated storage services"
    },
    {
      "code": 996722,
      "description": "Bulk liquid or gas storage services"
    },
    {
      "code": 996729,
      "description": "Other storage and warehousing services"
    },
    {
      "code": 996731,
      "description": "Railway pushing or towing services"
    },
    {
      "code": 996739,
      "description": "Other supporting services for railway transport n.e.c."
    },
    {
      "code": 996741,
      "description": "Bus station services"
    },
    {
      "code": 996742,
      "description": "Operation services of National Highways, State Highways, Expressways, Roads & streets; bridges and tunnel operation services."
    },
    {
      "code": 996743,
      "description": "Parking lot services"
    },
    {
      "code": 996744,
      "description": "Towing services for commercial and private vehicles"
    },
    {
      "code": 996749,
      "description": "Other supporting services for road transport n.e.c."
    },
    {
      "code": 996751,
      "description": "Port and waterway operation services (excl. cargo handling) such as operation services of ports, docks, light houses, light ships etc"
    },
    {
      "code": 996752,
      "description": "Pilotage and berthing services"
    },
    {
      "code": 996753,
      "description": "Vessel salvage and refloating services"
    },
    {
      "code": 996759,
      "description": "Other supporting services for water transport n.e.c."
    },
    {
      "code": 996761,
      "description": "Airport operation services (excl. cargo handling)"
    },
    {
      "code": 996762,
      "description": "Air traffic control services"
    },
    {
      "code": 996763,
      "description": "Other supporting services for air transport"
    },
    {
      "code": 996764,
      "description": "Supporting services for space transport"
    },
    {
      "code": 996791,
      "description": "Goods transport agency services for road transport"
    },
    {
      "code": 996792,
      "description": "Goods transport agency services for other modes of transport"
    },
    {
      "code": 996793,
      "description": "Other goods transport services "
    },
    {
      "code": 996799,
      "description": "Other supporting transport services n.e.c"
    },
    {
      "code": 9968,
      "description": "Postal and courier services"
    },
    {
      "code": 996811,
      "description": "Postal services including post office counter services, mail box rental services."
    },
    {
      "code": 996812,
      "description": "Courier services"
    },
    {
      "code": 996813,
      "description": "Local delivery services"
    },
    {
      "code": 996819,
      "description": "Other Delivery Services n.e.c"
    },
    {
      "code": 9969,
      "description": "Electricity, gas, water and other distribution services"
    },
    {
      "code": 996911,
      "description": "Electricity transmission services"
    },
    {
      "code": 996912,
      "description": "Electricity distribution services"
    },
    {
      "code": 996913,
      "description": "Gas distribution services"
    },
    {
      "code": 996921,
      "description": "Water distribution services"
    },
    {
      "code": 996922,
      "description": "Services involving distribution of steam, hot water and air conditioning supply etc."
    },
    {
      "code": 996929,
      "description": "Other similar services."
    },
    {
      "code": 9971,
      "description": "Financial and related services"
    },
    {
      "code": 997111,
      "description": "Central banking services"
    },
    {
      "code": 997112,
      "description": "Deposit services"
    },
    {
      "code": 997113,
      "description": "Credit-granting services including stand-by commitment, guarantees & securities"
    },
    {
      "code": 997114,
      "description": "Financial leasing services"
    },
    {
      "code": 997119,
      "description": "Other financial services (except investment banking, insurance services and pension services)"
    },
    {
      "code": 997120,
      "description": "Investment banking services "
    },
    {
      "code": 997131,
      "description": "pension services"
    },
    {
      "code": 997132,
      "description": "Life insurance services  (excluding reinsurance services)"
    },
    {
      "code": 997133,
      "description": "Accident and health insurance services"
    },
    {
      "code": 997134,
      "description": "Motor vehicle insurance services"
    },
    {
      "code": 997135,
      "description": "Marine, aviation, and other transport insurance services"
    },
    {
      "code": 997136,
      "description": "Freight insurance services & Travel insurance services"
    },
    {
      "code": 997137,
      "description": "Other property insurance services"
    },
    {
      "code": 997139,
      "description": "Other non-life insurance services (excluding reinsurance services)"
    },
    {
      "code": 997141,
      "description": "Life reinsurance services"
    },
    {
      "code": 997142,
      "description": "Accident and health reinsurance services"
    },
    {
      "code": 997143,
      "description": "Motor vehicle reinsurance services"
    },
    {
      "code": 997144,
      "description": "Marine, aviation and other transport reinsurance ser"
    },
    {
      "code": 997145,
      "description": "services"
    },
    {
      "code": 997146,
      "description": "Freight reinsurance services"
    },
    {
      "code": 997147,
      "description": "Other property reinsurance services"
    },
    {
      "code": 997149,
      "description": "Other non-life reinsurance services "
    },
    {
      "code": 997151,
      "description": "Services related to investment banking such as mergers & acquisition services, corporate finance & venture capital services"
    },
    {
      "code": 997152,
      "description": "Brokerage and related securities and commodities services including commodity exchange services"
    },
    {
      "code": 997153,
      "description": "Portfolio management services except pension funds"
    },
    {
      "code": 997154,
      "description": "Trust and custody services"
    },
    {
      "code": 997155,
      "description": "Services related to the administration of financial markets"
    },
    {
      "code": 997156,
      "description": "Financial consultancy services"
    },
    {
      "code": 997157,
      "description": "Foreign exchange services"
    },
    {
      "code": 997158,
      "description": "Financial transactions processing and clearing house services"
    },
    {
      "code": 997159,
      "description": "Other services auxiliary to financial services"
    },
    {
      "code": 997161,
      "description": "Insurance brokerage and agency services"
    },
    {
      "code": 997162,
      "description": "Insurance claims adjustment services"
    },
    {
      "code": 997163,
      "description": "Actuarial services"
    },
    {
      "code": 997164,
      "description": "Pension fund management services"
    },
    {
      "code": 997169,
      "description": "Other services auxiliary to insurance and pensions "
    },
    {
      "code": 997171,
      "description": "Services of holding equity of subsidiary companies"
    },
    {
      "code": 997172,
      "description": "Services of holding securities and other assets of trusts and funds and similar financial entities"
    },
    {
      "code": 9972,
      "description": "Real estate services"
    },
    {
      "code": 997211,
      "description": "Rental or leasing services involving own or leased residential property"
    },
    {
      "code": 997212,
      "description": "Rental or leasing services involving own or leased non-residential property"
    },
    {
      "code": 997213,
      "description": "Trade services of buildings"
    },
    {
      "code": 997214,
      "description": "Trade services of time-share properties"
    },
    {
      "code": 997215,
      "description": "Trade services of vacant and subdivided land"
    },
    {
      "code": 997221,
      "description": "Property management services on a fee/commission basis or contract basis"
    },
    {
      "code": 997222,
      "description": "Building sales on a fee/commission basis or contract basis"
    },
    {
      "code": 997223,
      "description": "Land sales on a fee/commission basis or contract basis"
    },
    {
      "code": 997224,
      "description": "Real estate appraisal services on a fee/commission basis or contract basis"
    },
    {
      "code": 9973,
      "description": "Leasing or rental services with or without operator"
    },
    {
      "code": 997311,
      "description": "Leasing or rental services concerning transport equipments including containers, with or without operator"
    },
    {
      "code": 997312,
      "description": "Leasing or rental services concerning agricultural machinery and equipment with or without operator"
    },
    {
      "code": 997313,
      "description": "Leasing or rental services concerning construction machinery and equipment with or without operator"
    },
    {
      "code": 997314,
      "description": "Leasing or rental services concerning office machinery and equipment (except computers) with or without operator"
    },
    {
      "code": 997315,
      "description": "Leasing or rental services concerning computers with or without operators"
    },
    {
      "code": 997316,
      "description": "Leasing or rental services concerning telecommunications equipment with or without operator"
    },
    {
      "code": 997319,
      "description": "Leasing or rental services concerning other machinery and equipments with or without operator"
    },
    {
      "code": 997321,
      "description": "Leasing or rental services concerning televisions, radios, video cassette recorders, projectors, audio systems and related equipment and accessories (Home entertainment equipment )"
    },
    {
      "code": 997322,
      "description": "Leasing or rental services concerning video tapes and disks (Home entertainment equipment )"
    },
    {
      "code": 997323,
      "description": "Leasing or rental services concerning furniture and other household appliances"
    },
    {
      "code": 997324,
      "description": "Leasing or rental services concerning pleasure and leisure equipment."
    },
    {
      "code": 997325,
      "description": "Leasing or rental services concerning household linen."
    },
    {
      "code": 997326,
      "description": "Leasing or rental services concerning textiles, clothing and footwear."
    },
    {
      "code": 997327,
      "description": "Leasing or rental services concerning do-it-yourself machinery and equipment"
    },
    {
      "code": 997329,
      "description": "Leasing or rental services concerning other goods"
    },
    {
      "code": 997331,
      "description": "Licensing services for the right to use computer software and databases."
    },
    {
      "code": 997332,
      "description": "Licensing services for the right to broadcast and show original films, sound recordings, radio and television programme etc."
    },
    {
      "code": 997333,
      "description": "Licensing services for the right to reproduce original art works"
    },
    {
      "code": 997334,
      "description": "Licensing services for the right to reprint and copy manuscripts, books, journals and periodicals."
    },
    {
      "code": 997335,
      "description": "Licensing services for the right to use R&D products"
    },
    {
      "code": 997336,
      "description": "Licensing services for the right to use trademarks and franchises"
    },
    {
      "code": 997337,
      "description": "Licensing services for the right to use minerals including its exploration and evaluation"
    },
    {
      "code": 997338,
      "description": "Licensing services for right to use other natural resources including telecommunication spectrum"
    },
    {
      "code": 997339,
      "description": "Licensing services for the right to use other intellectual property products and other rescources n.e.c"
    },
    {
      "code": 9981,
      "description": "Research and development services"
    },
    {
      "code": 998111,
      "description": "Research and experimental development services in natural sciences"
    },
    {
      "code": 998112,
      "description": "Research and experimental development services in engineering and technology"
    },
    {
      "code": 998113,
      "description": "Research and experimental development services in medical sciences and pharmacy."
    },
    {
      "code": 998114,
      "description": "Research and experimental development services in agricultural sciences."
    },
    {
      "code": 998121,
      "description": "Research and experimental development services in social sciences."
    },
    {
      "code": 998122,
      "description": "Research and experimental development services in humanities"
    },
    {
      "code": 998130,
      "description": "Interdisciplinary research and experimental development services."
    },
    {
      "code": 998141,
      "description": "Research and development originals in pharmaceuticals"
    },
    {
      "code": 998142,
      "description": "Research and development originals in agriculture"
    },
    {
      "code": 998143,
      "description": "Research and development originals in biotechnology"
    },
    {
      "code": 998144,
      "description": "Research and development originals in computer related sciences"
    },
    {
      "code": 998145,
      "description": "Research and development originals in other fields n.e.c."
    },
    {
      "code": 9982,
      "description": "Legal and accounting services"
    },
    {
      "code": 998211,
      "description": "Legal advisory and representation services concerning criminal law."
    },
    {
      "code": 998212,
      "description": "Legal advisory and representation services concerning other fields of law."
    },
    {
      "code": 998213,
      "description": "Legal documentation and certification services concerning patents, copyrights and other intellectual property rights."
    },
    {
      "code": 998214,
      "description": "Legal documentation and certification services concerning other documents."
    },
    {
      "code": 998215,
      "description": "Arbitration and conciliation services"
    },
    {
      "code": 998216,
      "description": "Other legal services n.e.c."
    },
    {
      "code": 998221,
      "description": "Financial auditing services"
    },
    {
      "code": 998222,
      "description": "Accounting and bookkeeping services"
    },
    {
      "code": 998223,
      "description": "Payroll services"
    },
    {
      "code": 998224,
      "description": "Other similar services n.e.c"
    },
    {
      "code": 998231,
      "description": "Corporate tax consulting and preparation services"
    },
    {
      "code": 998232,
      "description": "Individual tax preparation and planning services"
    },
    {
      "code": 998240,
      "description": "Insolvency and receivership services"
    },
    {
      "code": 9983,
      "description": "Other professional, technical and business services"
    },
    {
      "code": 998311,
      "description": "Management consulting and management services including financial, strategic, human resources, marketing, operations and supply chain management."
    },
    {
      "code": 998312,
      "description": "Business consulting services including pubic relations services"
    },
    {
      "code": 998313,
      "description": "Information technology (IT) consulting and support services"
    },
    {
      "code": 998314,
      "description": "Information technology (IT) design and development services"
    },
    {
      "code": 998315,
      "description": "Hosting and information technology (IT) infrastructure provisioning services"
    },
    {
      "code": 998316,
      "description": "IT infrastructure and network management services"
    },
    {
      "code": 998319,
      "description": "Other information technology services n.e.c"
    },
    {
      "code": 998321,
      "description": "Architectural advisory services"
    },
    {
      "code": 998322,
      "description": "Architectural services for residential building projects"
    },
    {
      "code": 998323,
      "description": "Architectural services for non-residential building projects"
    },
    {
      "code": 998324,
      "description": "Historical restoration architectural services"
    },
    {
      "code": 998325,
      "description": "Urban planning services"
    },
    {
      "code": 998326,
      "description": "Rural land planning services"
    },
    {
      "code": 998327,
      "description": "Project site master planning services"
    },
    {
      "code": 998328,
      "description": "Landscape architectural services and advisory services"
    },
    {
      "code": 998331,
      "description": "Engineering advisory services"
    },
    {
      "code": 998332,
      "description": "Engineering services for building projects"
    },
    {
      "code": 998333,
      "description": "Engineering services for industrial and manufacturing projects"
    },
    {
      "code": 998334,
      "description": "Engineering services for transportation projects"
    },
    {
      "code": 998335,
      "description": "Engineering services for power projects"
    },
    {
      "code": 998336,
      "description": "Engineering services for telecommunications and broadcasting projects"
    },
    {
      "code": 998337,
      "description": "Engineering services for waste management projects (hazardous and non-hazardous), for water, sewerage and drainage projects."
    },
    {
      "code": 998338,
      "description": "Engineering services for other projects n.e.c."
    },
    {
      "code": 998339,
      "description": "Project management services for construction projects"
    },
    {
      "code": 998341,
      "description": "Geological and geophysical consulting services"
    },
    {
      "code": 998342,
      "description": "Subsurface surveying services"
    },
    {
      "code": 998343,
      "description": "Mineral exploration and evaluation"
    },
    {
      "code": 998344,
      "description": "Surface surveying and map-making services"
    },
    {
      "code": 998345,
      "description": "Weather forecasting and meteorological services"
    },
    {
      "code": 998346,
      "description": "Technical testing and analysis services"
    },
    {
      "code": 998347,
      "description": "Certification of ships, aircraft, dams, etc."
    },
    {
      "code": 998348,
      "description": "Certification and authentication of works of art"
    },
    {
      "code": 998349,
      "description": "Other technical and scientific services n.e.c."
    },
    {
      "code": 998351,
      "description": "Veterinary services for pet animals"
    },
    {
      "code": 998352,
      "description": "Veterinary services for livestock"
    },
    {
      "code": 998359,
      "description": "Other veterinary services n.e.c."
    },
    {
      "code": 998361,
      "description": "Advertising Services"
    },
    {
      "code": 998362,
      "description": "Purchase or sale of advertising space or time, on commission"
    },
    {
      "code": 998363,
      "description": "Sale of advertising space in print media (except on commission)"
    },
    {
      "code": 998364,
      "description": "Sale of TV and radio advertising time"
    },
    {
      "code": 998365,
      "description": "Sale of Internet advertising space"
    },
    {
      "code": 998366,
      "description": "Sale of other advertising space or time (except on commission)"
    },
    {
      "code": 998371,
      "description": "Market research services"
    },
    {
      "code": 998372,
      "description": "Public opinion polling services"
    },
    {
      "code": 998381,
      "description": "Portrait photography services"
    },
    {
      "code": 998382,
      "description": "Advertising and related photography services"
    },
    {
      "code": 998383,
      "description": "Event photography and event videography services"
    },
    {
      "code": 998384,
      "description": "Specialty photography services"
    },
    {
      "code": 998385,
      "description": "Restoration and retouching services of photography"
    },
    {
      "code": 998386,
      "description": "Photographic & videographic processing services"
    },
    {
      "code": 998387,
      "description": "Other Photography & Videography and their processing services n.e.c."
    },
    {
      "code": 998391,
      "description": "Specialty design services including interior design, fashion design, industrial design and other specialty design services"
    },
    {
      "code": 998392,
      "description": "Design originals"
    },
    {
      "code": 998393,
      "description": "Scientific and technical consulting services"
    },
    {
      "code": 998394,
      "description": "Original compilations of facts/information"
    },
    {
      "code": 998395,
      "description": "Translation and interpretation services"
    },
    {
      "code": 998396,
      "description": "Trademarks and franchises"
    },
    {
      "code": 998397,
      "description": "Sponsorship Services & Brand Promotion Services"
    },
    {
      "code": 998399,
      "description": "Other professional, technical and business services n.e.c."
    },
    {
      "code": 9984,
      "description": "Telecommunications, broadcasting and information supply services"
    },
    {
      "code": 998411,
      "description": "Carrier services"
    },
    {
      "code": 998412,
      "description": "Fixed telephony services"
    },
    {
      "code": 998413,
      "description": "Mobile telecommunications services"
    },
    {
      "code": 998414,
      "description": "Private network services"
    },
    {
      "code": 998415,
      "description": "Data transmission services"
    },
    {
      "code": 998419,
      "description": "Other telecommunications services including Fax services, Telex services n.e.c."
    },
    {
      "code": 998421,
      "description": "Internet backbone services"
    },
    {
      "code": 998422,
      "description": "Internet access services in wired and wireless mode."
    },
    {
      "code": 998423,
      "description": "Fax, telephony over the Internet"
    },
    {
      "code": 998424,
      "description": "Audio conferencing and video conferencing over the Internet"
    },
    {
      "code": 998429,
      "description": "Other Internet telecommunications services n.e.c."
    },
    {
      "code": 998431,
      "description": "On-line text based information such as online books, newpapers, periodicals, directories etc"
    },
    {
      "code": 998432,
      "description": "On-line audio content"
    },
    {
      "code": 998433,
      "description": "On-line video content"
    },
    {
      "code": 998434,
      "description": "Software downloads"
    },
    {
      "code": 998439,
      "description": "Other on-line contents n.e.c."
    },
    {
      "code": 998441,
      "description": "News agency services to newspapers and periodicals"
    },
    {
      "code": 998442,
      "description": "Services of independent journalists and press photographers"
    },
    {
      "code": 998443,
      "description": "News agency services to audiovisual media"
    },
    {
      "code": 998451,
      "description": "Library services"
    },
    {
      "code": 998452,
      "description": "Operation services of public archives including digital archives"
    },
    {
      "code": 998453,
      "description": "Operation services of historical archives including digital archives"
    },
    {
      "code": 998461,
      "description": "Radio broadcast originals"
    },
    {
      "code": 998462,
      "description": "Television broadcast originals"
    },
    {
      "code": 998463,
      "description": "Radio channel programmes"
    },
    {
      "code": 998464,
      "description": "Television channel programmes"
    },
    {
      "code": 998465,
      "description": "Broadcasting services"
    },
    {
      "code": 998466,
      "description": "Home programme distribution services"
    },
    {
      "code": 9985,
      "description": "Support services"
    },
    {
      "code": 998511,
      "description": "Executive/retained personnel search services"
    },
    {
      "code": 998512,
      "description": "Permanent placement services, other than executive search services"
    },
    {
      "code": 998513,
      "description": "Contract staffing services"
    },
    {
      "code": 998514,
      "description": "Temporary staffing services"
    },
    {
      "code": 998515,
      "description": "Long-term staffing (pay rolling) services"
    },
    {
      "code": 998516,
      "description": "Temporary staffing-to-permanent placement services"
    },
    {
      "code": 998517,
      "description": "Co-employment staffing services"
    },
    {
      "code": 998519,
      "description": "Other employment & labour supply services n.e.c"
    },
    {
      "code": 998521,
      "description": "Investigation services"
    },
    {
      "code": 998522,
      "description": "Security consulting services"
    },
    {
      "code": 998523,
      "description": "Security systems services"
    },
    {
      "code": 998524,
      "description": "Armoured car services"
    },
    {
      "code": 998525,
      "description": "Guard services"
    },
    {
      "code": 998526,
      "description": "Training of guard dogs"
    },
    {
      "code": 998527,
      "description": "Polygraph services"
    },
    {
      "code": 998528,
      "description": "Fingerprinting services"
    },
    {
      "code": 998529,
      "description": "Other security services n.e.c."
    },
    {
      "code": 998531,
      "description": "Disinfecting and exterminating services"
    },
    {
      "code": 998532,
      "description": "Window cleaning services"
    },
    {
      "code": 998533,
      "description": "General cleaning services"
    },
    {
      "code": 998534,
      "description": "Specialized cleaning services for reservoirs and tanks"
    },
    {
      "code": 998535,
      "description": "Sterilization of objects or premises (operating rooms)"
    },
    {
      "code": 998536,
      "description": "Furnace and chimney cleaning services"
    },
    {
      "code": 998537,
      "description": "Exterior cleaning of buildings of all types"
    },
    {
      "code": 998538,
      "description": "Cleaning of transportation equipment"
    },
    {
      "code": 998539,
      "description": "Other cleaning services n.e.c."
    },
    {
      "code": 998540,
      "description": "Packaging services of goods for others"
    },
    {
      "code": 998541,
      "description": "Parcel packing and gift wrapping"
    },
    {
      "code": 998542,
      "description": "Coin and currency packing services"
    },
    {
      "code": 998549,
      "description": "Other packaging services n.e.c"
    },
    {
      "code": 998551,
      "description": "Reservation services for transportation"
    },
    {
      "code": 998552,
      "description": "Reservation services for accommodation, cruises and package tours"
    },
    {
      "code": 998553,
      "description": "Reservation services for convention centres, congress centres and exhibition halls"
    },
    {
      "code": 998554,
      "description": "Reservation services for event tickets, cinema halls, entertainment and recreational services and other reservation services"
    },
    {
      "code": 998555,
      "description": "Tour operator services"
    },
    {
      "code": 998556,
      "description": "Tourist guide services"
    },
    {
      "code": 998557,
      "description": "Tourism promotion and visitor information services"
    },
    {
      "code": 998559,
      "description": "Other travel arrangement and related services n.e.c"
    },
    {
      "code": 998591,
      "description": "Credit reporting & rating services"
    },
    {
      "code": 998592,
      "description": "Collection agency services"
    },
    {
      "code": 998593,
      "description": "Telephone-based support services"
    },
    {
      "code": 998594,
      "description": "Combined office administrative services"
    },
    {
      "code": 998595,
      "description": "Specialized office support services such as duplicating services, mailing services, document preparation etc"
    },
    {
      "code": 998596,
      "description": "Events, Exhibitions, Conventions and trade shows organisation and assistance services"
    },
    {
      "code": 998597,
      "description": "Landscape care and maintenance services"
    },
    {
      "code": 998598,
      "description": "Other information services n.e.c."
    },
    {
      "code": 998599,
      "description": "Other support services n.e.c."
    },
    {
      "code": 9986,
      "description": "Support services to agriculture, hunting, forestry, fishing, mining and utilities"
    },
    {
      "code": 998611,
      "description": "Support services to crop production"
    },
    {
      "code": 998612,
      "description": "Animal husbandry services"
    },
    {
      "code": 998613,
      "description": "Support services to hunting"
    },
    {
      "code": 998614,
      "description": "Support services to forestry and logging"
    },
    {
      "code": 998615,
      "description": "Support services to fishing"
    },
    {
      "code": 998619,
      "description": "Other support services to agriculture, hunting, forestry and fishing"
    },
    {
      "code": 998621,
      "description": "Support services to oil and gas extraction"
    },
    {
      "code": 998622,
      "description": "Support services to other mining n.e.c."
    },
    {
      "code": 998631,
      "description": "Support services to electricity transmission and distribution"
    },
    {
      "code": 998632,
      "description": "Support services to gas distribution"
    },
    {
      "code": 998633,
      "description": "Support services to water distribution"
    },
    {
      "code": 998634,
      "description": "Support services to Distribution services of steam, hot water and air-conditioning supply"
    },
    {
      "code": 9987,
      "description": "Maintenance, repair and installation (except construction) services"
    },
    {
      "code": 998711,
      "description": "Maintenance and repair services of fabricated metal products, except machinery and equipment."
    },
    {
      "code": 998712,
      "description": "Maintenance and repair services of office and accounting machinery"
    },
    {
      "code": 998713,
      "description": "Maintenance and repair services of computers and peripheral equipment"
    },
    {
      "code": 998714,
      "description": "Maintenance and repair services of transport machinery and equipment"
    },
    {
      "code": 998715,
      "description": "Maintenance and repair services of electrical household appliances"
    },
    {
      "code": 998716,
      "description": "Maintenance and repair services of telecommunication equipments and apparatus"
    },
    {
      "code": 998717,
      "description": "Maintenance and repair services of commercial and industrial machinery."
    },
    {
      "code": 998718,
      "description": "Maintenance and repair services of elevators and escalators"
    },
    {
      "code": 998719,
      "description": "Maintenance and repair services of other machinery and equipments"
    },
    {
      "code": 998721,
      "description": "Repair services of footwear and leather goods"
    },
    {
      "code": 998722,
      "description": "Repair services of watches, clocks and jewellery"
    },
    {
      "code": 998723,
      "description": "Repair services of garments and household textiles"
    },
    {
      "code": 998724,
      "description": "Repair services of furniture"
    },
    {
      "code": 998725,
      "description": "Repair services of bicycles"
    },
    {
      "code": 998726,
      "description": "Maintenance and repair services of musical instruments"
    },
    {
      "code": 998727,
      "description": "Repair services for photographic equipment and cameras"
    },
    {
      "code": 998729,
      "description": "Maintenance and repair services of other goods n.e.c."
    },
    {
      "code": 998731,
      "description": "Installation services of fabricated metal products, except machinery and equipment."
    },
    {
      "code": 998732,
      "description": "Installation services of industrial, manufacturing and service industry machinery and equipment."
    },
    {
      "code": 998733,
      "description": "Installation services of office and accounting machinery and computers"
    },
    {
      "code": 998734,
      "description": "Installation services of radio, television and communications equipment and apparatus."
    },
    {
      "code": 998735,
      "description": "Installation services of professional medical machinery and equipment, and precision and optical instruments."
    },
    {
      "code": 998736,
      "description": "Installation services of electrical machinery and apparatus n.e.c."
    },
    {
      "code": 998739,
      "description": "Installation services of other goods n.e.c."
    },
    {
      "code": 9988,
      "description": "Manufacturing services on physical inputs (goods) owned by others"
    },
    {
      "code": 998811,
      "description": "Meat processing services"
    },
    {
      "code": 998812,
      "description": "Fish processing services"
    },
    {
      "code": 998813,
      "description": "Fruit and vegetables processing services"
    },
    {
      "code": 998814,
      "description": "Vegetable and animal oil and fat manufacturing services"
    },
    {
      "code": 998815,
      "description": "Dairy product manufacturing services"
    },
    {
      "code": 998816,
      "description": "Other food product manufacturing services"
    },
    {
      "code": 998817,
      "description": "Prepared animal feeds manufacturing services"
    },
    {
      "code": 998818,
      "description": "Beverage manufacturing services"
    },
    {
      "code": 998819,
      "description": "Tobacco manufacturing services n.e.c."
    },
    {
      "code": 998821,
      "description": "Textile manufacturing services"
    },
    {
      "code": 998822,
      "description": "Wearing apparel manufacturing services"
    },
    {
      "code": 998823,
      "description": "Leather and leather product manufacturing services"
    },
    {
      "code": 998831,
      "description": "Wood and wood product manufacturing services"
    },
    {
      "code": 998832,
      "description": "Paper and paper product manufacturing services"
    },
    {
      "code": 998841,
      "description": "Coke and refined petroleum product manufacturing services"
    },
    {
      "code": 998842,
      "description": "Chemical product manufacturing services"
    },
    {
      "code": 998843,
      "description": "Pharmaceutical product manufacturing services"
    },
    {
      "code": 998851,
      "description": "Rubber and plastic product manufacturing services"
    },
    {
      "code": 998852,
      "description": "Plastic product manufacturing services"
    },
    {
      "code": 998853,
      "description": "Other non-metallic mineral product manufacturing services"
    },
    {
      "code": 998860,
      "description": "Basic metal manufacturing services"
    },
    {
      "code": 998871,
      "description": "Structural metal product, tank, reservoir and steam generator manufacturing services"
    },
    {
      "code": 998872,
      "description": "Weapon and ammunition manufacturing services"
    },
    {
      "code": 998873,
      "description": "Other fabricated metal product manufacturing and metal treatment services"
    },
    {
      "code": 998874,
      "description": "Computer, electronic and optical product manufacturing services"
    },
    {
      "code": 998875,
      "description": "Electrical equipment manufacturing services"
    },
    {
      "code": 998876,
      "description": "General-purpose machinery manufacturing services n.e.c."
    },
    {
      "code": 998877,
      "description": "Special-purpose machinery manufacturing services"
    },
    {
      "code": 998881,
      "description": "Motor vehicle and trailer manufacturing services"
    },
    {
      "code": 998882,
      "description": "Other transport equipment manufacturing services"
    },
    {
      "code": 998891,
      "description": "Furniture manufacturing services"
    },
    {
      "code": 998892,
      "description": "Jewellery manufacturing services"
    },
    {
      "code": 998893,
      "description": "Imitation jewellery manufacturing services"
    },
    {
      "code": 998894,
      "description": "Musical instrument manufacturing services"
    },
    {
      "code": 998895,
      "description": "Sports goods manufacturing services"
    },
    {
      "code": 998896,
      "description": "Game and toy manufacturing services"
    },
    {
      "code": 998897,
      "description": "Medical and dental instrument and supply manufacturing services"
    },
    {
      "code": 998898,
      "description": "Other manufacturing services n.e.c."
    },
    {
      "code": 9989,
      "description": "Other manufacturing services; publishing, printing and reproduction services; materials recovery services"
    },
    {
      "code": 998911,
      "description": "Publishing, on a fee or contract basis"
    },
    {
      "code": 998912,
      "description": "Printing and reproduction services of recorded media, on a fee or contract basis"
    },
    {
      "code": 998920,
      "description": "Moulding, pressing, stamping, extruding and similar plastic manufacturing services"
    },
    {
      "code": 998931,
      "description": "Iron and steel casting services"
    },
    {
      "code": 998932,
      "description": "Non-ferrous metal casting services"
    },
    {
      "code": 998933,
      "description": "Metal forging, pressing, stamping, roll forming and powder metallurgy services"
    },
    {
      "code": 998941,
      "description": "Metal waste and scrap recovery (recycling) services, on a fee or contract basis"
    },
    {
      "code": 998942,
      "description": "Non-metal waste and scrap recovery (recycling) services, on a fee or contract basis"
    },
    {
      "code": 9991,
      "description": "Public administration and other services provided to the community as a whole; compulsory social security services"
    },
    {
      "code": 999111,
      "description": "Overall Government public services"
    },
    {
      "code": 999112,
      "description": "Public administrative services related to the provision of educational, health care, cultural and other social services, excluding social security service."
    },
    {
      "code": 999113,
      "description": "Public administrative services related to the more efficient operation of business."
    },
    {
      "code": 999119,
      "description": "Other administrative services of the government n.e.c."
    },
    {
      "code": 999121,
      "description": "Public administrative services related to external affairs, diplomatic and consular services abroad."
    },
    {
      "code": 999122,
      "description": "Services related to foreign economic aid"
    },
    {
      "code": 999123,
      "description": "Services related to foreign military aid"
    },
    {
      "code": 999124,
      "description": "Military defence services"
    },
    {
      "code": 999125,
      "description": "Civil defence services"
    },
    {
      "code": 999126,
      "description": "Police and fire protection services"
    },
    {
      "code": 999127,
      "description": "Public administrative services related to law courts"
    },
    {
      "code": 999128,
      "description": "Administrative services related to the detention or rehabilitation of criminals."
    },
    {
      "code": 999129,
      "description": "Public administrative services related to other public order and safety affairs n.e.c."
    },
    {
      "code": 999131,
      "description": "Administrative services related to sickness, maternity or temporary disablement benefit schemes."
    },
    {
      "code": 999132,
      "description": "Administrative services related to government employee pension schemes; old-age disability or survivors benefit schemes, other than for government employees"
    },
    {
      "code": 999133,
      "description": "Administrative services related to unemployment compensation benefit schemes"
    },
    {
      "code": 999134,
      "description": "Administrative services related to family and child allowance programmes"
    },
    {
      "code": 9992,
      "description": "Education services"
    },
    {
      "code": 999210,
      "description": "Pre-primary education services"
    },
    {
      "code": 999220,
      "description": "Primary education services"
    },
    {
      "code": 999231,
      "description": "Secondary education services, general"
    },
    {
      "code": 999232,
      "description": "Secondary education services, technical and vocational."
    },
    {
      "code": 999241,
      "description": "Higher education services, general"
    },
    {
      "code": 999242,
      "description": "Higher education services, technical"
    },
    {
      "code": 999243,
      "description": "Higher education services, vocational"
    },
    {
      "code": 999249,
      "description": "Other higher education services"
    },
    {
      "code": 999259,
      "description": "Specialised education services "
    },
    {
      "code": 999291,
      "description": "Cultural education services"
    },
    {
      "code": 999292,
      "description": "Sports and recreation education services"
    },
    {
      "code": 999293,
      "description": "Commercial training and coaching services"
    },
    {
      "code": 999294,
      "description": "Other education and training services n.e.c."
    },
    {
      "code": 999295,
      "description": "services involving conduct of examination for admission to educational institutions"
    },
    {
      "code": 999299,
      "description": "Other Educational support services"
    },
    {
      "code": 9993,
      "description": "Human health and social care services"
    },
    {
      "code": 999311,
      "description": "Inpatient services"
    },
    {
      "code": 999312,
      "description": "Medical and dental services"
    },
    {
      "code": 999313,
      "description": "Childbirth and related services"
    },
    {
      "code": 999314,
      "description": "Nursing and Physiotherapeutic services"
    },
    {
      "code": 999315,
      "description": "Ambulance services"
    },
    {
      "code": 999316,
      "description": "Medical Laboratory and Diagnostic-imaging services"
    },
    {
      "code": 999317,
      "description": "Blood, sperm and organ bank services"
    },
    {
      "code": 999319,
      "description": "Other human health services including homeopathy, unani, ayurveda, naturopathy, acupuncture etc."
    },
    {
      "code": 999321,
      "description": "Residential health-care services other than by hospitals"
    },
    {
      "code": 999322,
      "description": "Residential care services for the elderly and persons with disabilities"
    },
    {
      "code": 999331,
      "description": "Residential care services for children suffering from mental retardation, mental health illnesses or substance abuse"
    },
    {
      "code": 999332,
      "description": "Other social services with accommodation for children"
    },
    {
      "code": 999333,
      "description": "Residential care services for adults suffering from mental retardation, mental health illnesses or substance abuse"
    },
    {
      "code": 999334,
      "description": "Other social services with accommodation for adults"
    },
    {
      "code": 999341,
      "description": "Vocational rehabilitation services"
    },
    {
      "code": 999349,
      "description": "Other social services without accommodation for the elderly and disabled n.e.c."
    },
    {
      "code": 999351,
      "description": "Child day-care services"
    },
    {
      "code": 999352,
      "description": "Guidance and counseling services n.e.c. related to children"
    },
    {
      "code": 999353,
      "description": "Welfare services without accommodation"
    },
    {
      "code": 999359,
      "description": "Other social services without accommodation n.e.c."
    },
    {
      "code": 9994,
      "description": "Sewage and waste collection, treatment and disposal and other environmental protection services"
    },
    {
      "code": 999411,
      "description": "Sewerage and sewage treatment services"
    },
    {
      "code": 999412,
      "description": "Septic tank emptying and cleaning services"
    },
    {
      "code": 999421,
      "description": "Collection services of hazardous waste"
    },
    {
      "code": 999422,
      "description": "Collection services of non-hazardous recyclable materials"
    },
    {
      "code": 999423,
      "description": "General waste collection services, residential"
    },
    {
      "code": 999424,
      "description": "General waste collection services, other n.e.c."
    },
    {
      "code": 999431,
      "description": "Waste preparation, consolidation and storage services"
    },
    {
      "code": 999432,
      "description": "Hazardous waste treatment and disposal services"
    },
    {
      "code": 999433,
      "description": "Non-hazardous waste treatment and disposal services"
    },
    {
      "code": 999441,
      "description": "Site remediation and clean-up services"
    },
    {
      "code": 999442,
      "description": "Containment, control and monitoring services and other site remediation services"
    },
    {
      "code": 999443,
      "description": "Building remediation services"
    },
    {
      "code": 999449,
      "description": "Other remediation services n.e.c."
    },
    {
      "code": 999451,
      "description": "Sweeping and snow removal services"
    },
    {
      "code": 999459,
      "description": "Other sanitation services n.e.c."
    },
    {
      "code": 999490,
      "description": "Other environmental protection services n.e.c."
    },
    {
      "code": 9995,
      "description": "Services of membership organisations"
    },
    {
      "code": 999511,
      "description": "Services furnished by business and employers organizations"
    },
    {
      "code": 999512,
      "description": "Services furnished by professional organizations"
    },
    {
      "code": 999520,
      "description": "Services furnished by trade unions"
    },
    {
      "code": 999591,
      "description": "Religious services"
    },
    {
      "code": 999592,
      "description": "Services furnished by political organizations"
    },
    {
      "code": 999593,
      "description": "Services furnished by human rights organizations"
    },
    {
      "code": 999594,
      "description": "Cultural and recreational associations"
    },
    {
      "code": 999595,
      "description": "Services furnished by environmental advocacy groups"
    },
    {
      "code": 999596,
      "description": "Services provided by youth associations"
    },
    {
      "code": 999597,
      "description": "Other civic and social organizations"
    },
    {
      "code": 999598,
      "description": "Home owners associations"
    },
    {
      "code": 999599,
      "description": "Services provided by other membership organizations n.e.c."
    },
    {
      "code": 9996,
      "description": "Recreational, cultural and sporting services"
    },
    {
      "code": 999611,
      "description": "Sound recording services"
    },
    {
      "code": 999612,
      "description": "Motion picture, videotape, television and radio programme production services"
    },
    {
      "code": 999613,
      "description": "Audiovisual post-production services"
    },
    {
      "code": 999614,
      "description": "Motion picture, videotape and television programme distribution services"
    },
    {
      "code": 999615,
      "description": "Motion picture projection services"
    },
    {
      "code": 999621,
      "description": "Performing arts event promotion and organization services"
    },
    {
      "code": 999622,
      "description": "Performing arts event production and presentation services"
    },
    {
      "code": 999623,
      "description": "Performing arts facility operation services"
    },
    {
      "code": 999629,
      "description": "Other performing arts and live entertainment services n.e.c."
    },
    {
      "code": 999631,
      "description": "Services of performing artists including actors, readers, musicians, singers, dancers, TV personalities, independent models etc"
    },
    {
      "code": 999632,
      "description": "Services of authors, composers, sculptors and other artists, except performing artists"
    },
    {
      "code": 999633,
      "description": "Original works of authors, composers and other artists except performing artists, painters and sculptors"
    },
    {
      "code": 999641,
      "description": "Museum and preservation services of historical sites and buildings"
    },
    {
      "code": 999642,
      "description": "Botanical, zoological and nature reserve services"
    },
    {
      "code": 999651,
      "description": "Sports and recreational sports event promotion and organization services"
    },
    {
      "code": 999652,
      "description": "Sports and recreational sports facility operation services"
    },
    {
      "code": 999659,
      "description": "Other sports and recreational sports services n.e.c."
    },
    {
      "code": 999661,
      "description": "Services of athletes"
    },
    {
      "code": 999662,
      "description": "Support services related to sports and recreation"
    },
    {
      "code": 999691,
      "description": "Amusement park and similar attraction services"
    },
    {
      "code": 999692,
      "description": "Gambling and betting services including similar online services"
    },
    {
      "code": 999693,
      "description": "Coin-operated amusement machine services"
    },
    {
      "code": 999694,
      "description": "Lottery services"
    },
    {
      "code": 999699,
      "description": "Other recreation and amusement services n.e.c."
    },
    {
      "code": 9997,
      "description": "Other services"
    },
    {
      "code": 999711,
      "description": "Coin-operated laundry services"
    },
    {
      "code": 999712,
      "description": "Dry-cleaning services (including fur product cleaning services)"
    },
    {
      "code": 999713,
      "description": "Other textile cleaning services"
    },
    {
      "code": 999714,
      "description": "Pressing services"
    },
    {
      "code": 999715,
      "description": "Dyeing and colouring services"
    },
    {
      "code": 999719,
      "description": "Other washing, cleaning and dyeing services n.e.c"
    },
    {
      "code": 999721,
      "description": "Hairdressing and barbers services"
    },
    {
      "code": 999722,
      "description": "Cosmetic treatment (including cosmetic/plastic surgery), manicuring and pedicuring services"
    },
    {
      "code": 999723,
      "description": "Physical well-being services including health club & fitness centre"
    },
    {
      "code": 999729,
      "description": "Other beauty treatment services n.e.c."
    },
    {
      "code": 999731,
      "description": "Cemeteries and cremation services"
    },
    {
      "code": 999732,
      "description": "Undertaking services"
    },
    {
      "code": 999791,
      "description": "Services involving commercial use or exploitation of any event"
    },
    {
      "code": 999792,
      "description": "Agreeing to do an act"
    },
    {
      "code": 999793,
      "description": "Agreeing to refrain from doing an act"
    },
    {
      "code": 999794,
      "description": "Agreeing to tolerate an act"
    },
    {
      "code": 999795,
      "description": "Conduct of religious ceremonies/rituals by persons"
    },
    {
      "code": 999799,
      "description": "Other services n.e.c."
    },
    {
      "code": 9998,
      "description": "Domestic services"
    },
    {
      "code": 999800,
      "description": "Domestic services both part time & full time"
    },
    {
      "code": 9999,
      "description": "Services provided by extraterritorial organisations and bodies"
    },
    {
      "code": 999900,
      "description": "Services provided by extraterritorial organizations and bodies"
    }
  ];

export const HSN = [
    {
      "code": "01",
      "description": "Live Animals; Animal Products"
    },
    {
      "code": "0101",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES - Horses:"
    },
    {
      "code": "01011010",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES PURE-BRED BREEDING ANIMALS HORSES"
    },
    {
      "code": "01011020",
      "description": "LIVE HORSES, ASSES, MULESANDHINNIES PURE-BRED BREEDING ANIMALS ASSES"
    },
    {
      "code": "01011090",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES PURE-BRED BREEDING ANIMAL S OTHER"
    },
    {
      "code": "010121",
      "description": "Horses"
    },
    {
      "code": "01012100",
      "description": "Pure-bred bredding animals"
    },
    {
      "code": "010129",
      "description": "other"
    },
    {
      "code": "01012910",
      "description": "Horses for polo"
    },
    {
      "code": "01012990",
      "description": "other"
    },
    {
      "code": "010130",
      "description": "Asses"
    },
    {
      "code": "01013010",
      "description": "Pure-bred breeding animals"
    },
    {
      "code": "01013020",
      "description": "livestock"
    },
    {
      "code": "01013090",
      "description": "other"
    },
    {
      "code": "010190",
      "description": "Other"
    },
    {
      "code": "01019010",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES - OTHER - HORSES FOR POLO"
    },
    {
      "code": "01019020",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES - OTHER - ASSES, MULES AND HINNIES AS LIVE STOCK"
    },
    {
      "code": "01019030",
      "description": "Mules and hinnies as livestock"
    },
    {
      "code": "01019090",
      "description": "LIVE HORSES, ASSES, MULES AND HINNIES - OTHER - OTHER"
    },
    {
      "code": "0102",
      "description": "LIVE BOVINE ANIMALS - Cattle:"
    },
    {
      "code": "01021010",
      "description": "LIVE BOVINE ANIMALS - BULLS - PURE-BRED BREEDING ANIMALS : ADULT"
    },
    {
      "code": "01021020",
      "description": "LIVE BOVINE ANIMALS - BULLS - PURE-BRED BREEDING ANIMALS COWS, ADULT"
    },
    {
      "code": "01021030",
      "description": "LIVE BOVINE ANIMALS- BULLS - PURE-BRED BREEDING ANIMALS BUFFALOES, ADULT AND CALVES"
    },
    {
      "code": "01021090",
      "description": "LIVE BOVINE ANIMALS - BULLS - PURE-BRED BREEDING ANIMALS OTHER"
    },
    {
      "code": "010221",
      "description": "Pure-bred breeding animals"
    },
    {
      "code": "01022110",
      "description": "Bulls"
    },
    {
      "code": "01022120",
      "description": "Cows"
    },
    {
      "code": "010229",
      "description": "Other"
    },
    {
      "code": "01022910",
      "description": "Bulls"
    },
    {
      "code": "01022990",
      "description": "Other including calves"
    },
    {
      "code": "01023100",
      "description": "Pure-bred breeding animals"
    },
    {
      "code": "01023900",
      "description": "Others"
    },
    {
      "code": "010290",
      "description": "Other"
    },
    {
      "code": "01029010",
      "description": "LIVE BOVINE ANIMALS - OTHER - BULLS - ADULT"
    },
    {
      "code": "01029020",
      "description": "LIVE BOVINE ANIMALS - OTHER - BUFFALOES, ADULT AND CALVES"
    },
    {
      "code": "01029090",
      "description": "LIVE BOVINE ANIMALS - OTHER - OTHER"
    },
    {
      "code": "0103",
      "description": "LIVE SWINE"
    },
    {
      "code": "01031000",
      "description": "LIVE SWINE PURE-BRED BREEDING ANIMALS"
    },
    {
      "code": "01039100",
      "description": "LIVE SWINE - OTHER - WEIGHING LESS THAN 50 KG."
    },
    {
      "code": "01039200",
      "description": "LIVE SWINE- OTHER  WEIGHING 50KG. OR MORE"
    },
    {
      "code": "0104",
      "description": "LIVE SHEEP AND GOATS"
    },
    {
      "code": "010410",
      "description": "Sheep"
    },
    {
      "code": "01041010",
      "description": "LIVE SHEEP AND GOATS - SHEEP : SHEEP INCLUDING LAMB FOR BREEDING PURPOSE"
    },
    {
      "code": "01041090",
      "description": "LIVE SHEEP A ND GOATS SHEEP OTHER"
    },
    {
      "code": "01042000",
      "description": "LIVE SHEEP AND GOATS GOATS"
    },
    {
      "code": "0105",
      "description": "LIVE POULTRY, THAT IS TO SAY, FOWLS OF THE SPECIES GALLUS DOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEA FOWLS - Weighing not more than 185 g:"
    },
    {
      "code": "01051100",
      "description": "SPECIES GALLU SDOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEA FOWLS WEIGHING NOT MORE THAN 185G : FOWLSOFTHESPECIESGALLUSDOMESTICUS"
    },
    {
      "code": "01051200",
      "description": "SPECIES GALLU SDOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEA FOWLS WEIGHING NOT MORE THAN 185G : TURKEYS"
    },
    {
      "code": "01051300",
      "description": "Ducks"
    },
    {
      "code": "01051400",
      "description": "Geese"
    },
    {
      "code": "01051500",
      "description": "Guinea fowls"
    },
    {
      "code": "01051900",
      "description": "SPECIES GALLU SDOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEA FOWLS WEIGHING NOT MORE THAN 185G : OTHER"
    },
    {
      "code": "01059200",
      "description": "LIVE POULTRY, THAT IS TO SAY, FOWLS OF THE SPECIES GALLUS DOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEAFOWLS - OTHER - FOWLS OF THE SPECIES GALLUS DOMESTICUS WEIGHING NOT MORE THAN 2000G"
    },
    {
      "code": "01059300",
      "description": "LIVE POULTRY, THAT IS TO SAY, FOWLS OF THE SPECIES GALLUS DOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEAFOWLS - OTHER - FOWLS OF THE SPECIES GALLUSDOMESTICUS WEIGHING MORE THAN 2000G,"
    },
    {
      "code": "01059400",
      "description": "LIVE POULTRY, THAT IS TO SAY, FOWLS OF THE SPECIES GALLUS DOMESTICUS, DUCKS, GEESE, TURKEYS AND GUINEAFOWLS - OTHER - FOWLS OF THE SPECIES GALLUS DOMESTICUS"
    },
    {
      "code": "01059900",
      "description": "SPECIES GALLUSDOMESTICUS, DUCKS, GEESE,TURKEYS AND GUINEA FOWLS - OTHER - OTHER"
    },
    {
      "code": "0106",
      "description": "OTHER LIVE ANIMALS - Mammals:"
    },
    {
      "code": "01061100",
      "description": "OTHER LIVE ANIMALS MAMMALS : PRIMATES"
    },
    {
      "code": "01061200",
      "description": "OTHER LIVE ANIMALS MAMMALS WHALES,DOLPHINS AND PORPOISES (MAMMALS OF THE ORDER CETACEA); MANATEES AND DUGONGS(MAMMALS OF THE ORDER SIRENIA)"
    },
    {
      "code": "01061300",
      "description": "Camels and other camelids (Camelidae)"
    },
    {
      "code": "01061400",
      "description": "Rabits and Hares"
    },
    {
      "code": "01061900",
      "description": "OTHER LIVE ANIMALS MAMMALS OTHER"
    },
    {
      "code": "01062000",
      "description": "OTHER LIVE ANIMALS REPTILES (INCLUDING SNAKES AND TURTLES)"
    },
    {
      "code": "01063100",
      "description": "OTHER LIVE ANIMALS BIRDS : BIRDS OF PREY"
    },
    {
      "code": "01063200",
      "description": "OTHER LIVE ANIMALS BIRDS PSITTACIFORMES(INCLUDING PARROTS, PARAKEETS,MACAWS AND COCKATOOS)"
    },
    {
      "code": "01063300",
      "description": "Ostriches; emus(Dromaius novaehollandiae)"
    },
    {
      "code": "01063900",
      "description": "OTHER LIVE ANIMALS BIRDS OTHER"
    },
    {
      "code": "010641",
      "description": "Bees"
    },
    {
      "code": "01064110",
      "description": "Pureline stock"
    },
    {
      "code": "01064190",
      "description": "other"
    },
    {
      "code": "010649",
      "description": "Other"
    },
    {
      "code": "01064910",
      "description": "Pureline Stock"
    },
    {
      "code": "01064990",
      "description": "Other"
    },
    {
      "code": "010690",
      "description": "Other"
    },
    {
      "code": "01069000",
      "description": "Other"
    },
    {
      "code": "01069010",
      "description": "OTHERLIVE ANIMALS- OTHER - BEESANDOTHERINSECTS,NOTELSEWHEREINCLUDEDORSPECIFIED"
    },
    {
      "code": "01069020",
      "description": "OTHER LIVE ANIMALS - OTHER - PURE LINE STOCK"
    },
    {
      "code": "01069090",
      "description": "OTHER LIVE ANIMALS - OTHER - OTHER"
    },
    {
      "code": "02",
      "description": "Meat and edible meat offal"
    },
    {
      "code": "0201",
      "description": "MEAT OF BOVINE ANIMALS, FRESH AND CHILLED"
    },
    {
      "code": "02011000",
      "description": "MEAT OF BOVINE ANIMALS, FRESH AND CHILLED CARCASSES AND HALF CARCASSES"
    },
    {
      "code": "02012000",
      "description": "MEAT OF BOVINE ANIMALS, FRESH AND CHILLED OTHER CUTS WITH BONE IN"
    },
    {
      "code": "02013000",
      "description": "MEAT OF BOVINE ANIMALS, FRESH AND CHILLED  BONELESS"
    },
    {
      "code": "0202",
      "description": "MEAT OF BOVINE ANIMALS, FROZEN"
    },
    {
      "code": "02021000",
      "description": "MEAT OF BOVINE ANIMALS, FROZEN CARCASSES AND HALF-CARCASSES"
    },
    {
      "code": "02022000",
      "description": "MEAT OF BOVINE ANIMALS, FROZEN OTHER CUTS WITH BONE IN"
    },
    {
      "code": "02023000",
      "description": "MEAT OF BOVINE ANIMALS, FROZEN BONELESS"
    },
    {
      "code": "0203",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN - Fresh or chilled:"
    },
    {
      "code": "02031100",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN FRESH OR CHILLED : CARCASSES AND HALF-CARCASSES"
    },
    {
      "code": "02031200",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN HAMS, SHOULDERS AND CUTS THERE OF, WITH BONE IN"
    },
    {
      "code": "02031900",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN HAMS, SHOULDERS AND CUTS THERE OF, WITH BONE INOTHER"
    },
    {
      "code": "02032100",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN FROZEN : CARCASSES AND HALF-CARCASSES"
    },
    {
      "code": "02032200",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN FROZEN : HAMS, SHOULDERS AND CUTS THERE OF, WITH BONE IN"
    },
    {
      "code": "02032900",
      "description": "MEAT OF SWINE, FRESH, CHILLED OR FROZEN FROZEN OTHER"
    },
    {
      "code": "0204",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN"
    },
    {
      "code": "02041000",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN CARCASSES AND HALF CARCASSES OF LAMB, FRESH OR CHILLED"
    },
    {
      "code": "02042100",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHER MEAT OF SHEEP, FRESH OR CHILLED : CARCASSES AND HALF CARCASSES"
    },
    {
      "code": "02042200",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHERMEAT OF SHEEP, FRESH OR CHILLED : OTHER CUTS WITH BONE IN"
    },
    {
      "code": "02042300",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHER MEAT OF SHEEP, FRESH OR CHILLED : BONELESS"
    },
    {
      "code": "02043000",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN CARCASSES AND HALFCARCASSES OF LAMB, FROZEN"
    },
    {
      "code": "02044100",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHERMEAT OF SHEEP, FROZEN : CARCASSES AND HALF-CARCASSES"
    },
    {
      "code": "02044200",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHER MEAT OF SHEEP, FROZEN : OTHER CUTS WITH BONE IN"
    },
    {
      "code": "02044300",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN OTHER MEAT OF SHEEP, FROZEN : BONELESS"
    },
    {
      "code": "02045000",
      "description": "MEAT OF SHEEP OR GOATS, FRESH, CHILLED OR FROZEN MEAT OF GOATS"
    },
    {
      "code": "0205",
      "description": "MEAT OF HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN"
    },
    {
      "code": "02050000",
      "description": "MEAT OF HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN"
    },
    {
      "code": "0206",
      "description": "EDIBLE OFFAL OF BOVINE ANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN"
    },
    {
      "code": "02061000",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF BOVINE ANIMALS, FRESH OR CHILLED"
    },
    {
      "code": "02062100",
      "description": "EDIBLE OFFAL OF BOVINE ANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF BOVINE ANIMALS, FROZEN : TONGUES"
    },
    {
      "code": "02062200",
      "description": "EDIBLE OFFAL OF BOVINE ANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHI LLED OR FROZEN OF BOVINE ANIMALS, FROZEN : LIVERS"
    },
    {
      "code": "02062900",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF BOVINE ANIMALS, FROZEN : OTHER"
    },
    {
      "code": "02063000",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF SWINE, FRESH OR CHILLED"
    },
    {
      "code": "02064100",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF SWINE, FROZEN : LIVERS"
    },
    {
      "code": "02064900",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OF SWINE, FROZEN : OTHER"
    },
    {
      "code": "020680",
      "description": "Other, fresh or chilled"
    },
    {
      "code": "02068010",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OTHER, FRESH OR CHILLED OF SHEEP OR GOATS"
    },
    {
      "code": "02068090",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OTHER, FRESH OR CHILLED OTHER"
    },
    {
      "code": "020690",
      "description": "Other, frozen"
    },
    {
      "code": "02069010",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OTHER, FROZEN : OF SHEEP OR GOATS"
    },
    {
      "code": "02069090",
      "description": "EDIBLE OFFAL OF BOVINEANIMALS, SWINE, SHEEP, GOATS, HORSES, ASSES, MULES OR HINNIES, FRESH, CHILLED OR FROZEN OTHER, FROZEN : OTHER"
    },
    {
      "code": "0207",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN - Of fowls of the species Gallus domesticus:"
    },
    {
      "code": "02071100",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF FOWLS OF THE SPECIES GALLUSDOMESTICUS : NOT CUT IN PIECES, FRESH OR CHILLED"
    },
    {
      "code": "02071200",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF FOWLS OF THE SPECIES GALLUSDOMESTICUS : NOT CUT IN PIECES, FROZEN"
    },
    {
      "code": "02071300",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF FOWLS OF THE SPECIES GALLUSDOMESTICUS : CUTS AND OFFAL, FRESH OR CHILLED"
    },
    {
      "code": "02071400",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF FOWLS OF THE SPECIES GALLUSDOMESTICUS : CUTS AND OFFAL, FROZEN"
    },
    {
      "code": "02072400",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF TURKEYS NOT CUT IN PIECES, FRESH OR CHILLED"
    },
    {
      "code": "02072500",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN OF TURKEYS NOT CUT IN PIECES, FROZEN"
    },
    {
      "code": "02072600",
      "description": "MEAT, AND EDIBLE OFFAL, OF THEPOULTRY OF HEADING0105, FRESH, CHILLED OR FROZEN CUTS AND OFFAL, FRESH OR CHILLED"
    },
    {
      "code": "02072700",
      "description": "Cuts and offal, frozen"
    },
    {
      "code": "02074100",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF DUCKS --NOT CUT IN PIECES, FRESH OR CHILLED"
    },
    {
      "code": "02074200",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF DUCKES -- NOT CUT IN PIECES, FROZEN"
    },
    {
      "code": "02074300",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF DUCKS -- FATTY LIVERS, FRESH OR CHILLED"
    },
    {
      "code": "02074400",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN-OF DUCKS -- OTHER, FRESH OR CHILLED"
    },
    {
      "code": "02074500",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF DUCKS --OTHER, FROZEN"
    },
    {
      "code": "02075100",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF GEESE -- NOT CUT IN PIECES, FRESH OR CHILLED"
    },
    {
      "code": "02075200",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF GEESE -- NOT CUT IN PIECES, FROZEN"
    },
    {
      "code": "02075300",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF GEESE -- FATTY LIVERS, FRESH OR CHILLED"
    },
    {
      "code": "02075400",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN-OF GEESE --OTHER, FRESH OR CHILLED"
    },
    {
      "code": "02075500",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN-OF GEESE -- OTHER, FROZEN"
    },
    {
      "code": "02076000",
      "description": "MEAT, AND EDIBLE OFFAL, OF THE POULTRY OF HEADING 0105, FRESH, CHILLED OR FROZEN- OF GEESE - OF GUINEA FOWLS"
    },
    {
      "code": "0208",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN"
    },
    {
      "code": "02081000",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OF RABBITS OR HARES"
    },
    {
      "code": "02082000",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN FROGR LEGS"
    },
    {
      "code": "02083000",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OF PRIMATES"
    },
    {
      "code": "02084000",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OF WHALES, DOLPHINS AND P OR POISES(MAMMALS OF THE OR DER CATACEA); OF MANATEES AND DUGONGS(MAMMALS OF THE OR DER SIRENIA)"
    },
    {
      "code": "02085000",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OF REPTILES(INCLUDING SNAKES AND TURTLES)"
    },
    {
      "code": "02086000",
      "description": "Of whales, dolphins and porpoises (mammalsof the order cetacea); of manatees and dugongs (mammals of the order sirenia)- of camels and other camelids (Camelidae)"
    },
    {
      "code": "020890",
      "description": "Other"
    },
    {
      "code": "02089010",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OTHER : OF WILD ANIMALS"
    },
    {
      "code": "02089090",
      "description": "OTHER MEAT AND EDIBLE MEAT OFFAL, FRESH, CHILLED OR FROZEN OTHER : OTHER"
    },
    {
      "code": "0209",
      "description": "PIG FAT, FREE OF LEAN MEAT AND POUTRY FAT, NOT RENDERED OR OTHERWISE EXTRACTED, FRESH, CHILLED, FROZEN, SALTED, IN BRINE, DIRED OR SMOKED"
    },
    {
      "code": "020900",
      "description": "PIG FAT, FREE OF LEAN MEAT AND POULTRY FAT, NOT RENDERED OR OTHERWISE EXTRACTED, FRESH, CHILLED, FROZEN, SALTED, IN BRINE, DRIED OR SMOKED"
    },
    {
      "code": "02091000",
      "description": "PIG FAT, FREE OF LEAN MEAT, AND POULTRY FAT, NOT RENDERED OR OTHERWSE EXTRACTED, FRESH, CHILLED, FROZEN, SALTED, IN BRINE, DRIED OR SMOKED - OF PIGS"
    },
    {
      "code": "02099000",
      "description": "PIG FAT, FREE OF LEAN MEAT, AND POULTRY FAT, NOT RENDERED OR OTHERWSE EXTRACTED, FRESH, CHILLED, FROZEN, SALTED, IN BRINE, DRIED OR SMOKED - OTHER"
    },
    {
      "code": "0210",
      "description": "MEAT AND EDIBLE MEAT OFFAL, SALTED, IN BRINE, DRIED OR SMOKED; EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL - Meat of swine:"
    },
    {
      "code": "02101100",
      "description": "MEAT AND EDIBLEMEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLEFLOURS AND MEALS OF MEAT OR MEAT OFFALMEAT OF SWINE : HAMS, SHOULDERS AND CUTS THERE OF, WITH BONE IN"
    },
    {
      "code": "02101200",
      "description": "MEAT AND EDIBLEMEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLEFLOURS AND MEALS OF MEAT OR MEAT OFFALMEAT OF SWINE : BELLIES(STREAKY) AND CUTS THERE OF"
    },
    {
      "code": "02101900",
      "description": "MEAT AND EDIBLEMEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLEFLOURS AND MEALS OF MEAT OR MEAT OFFALMEAT OF SWINE : OTHER"
    },
    {
      "code": "02102000",
      "description": "MEAT AND EDIBLEMEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLEFLOURS AND MEALS OF MEAT OR MEAT OFFALMEAT OF BOVINE ANIMALS"
    },
    {
      "code": "02109100",
      "description": "MEAT AND EDIBLE MEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL-OTHER, INCLUDING EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL : OF PRIMATES"
    },
    {
      "code": "02109200",
      "description": "MEAT AND EDIBLEMEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL-OTHER, INCLUDING EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL : OF WHALES, DOLPHINS AND PORPOISES(MAMMALS OF THE ORDER CATECEA); OF MANATEES AND DUGONGS(MAMMALS OF THE ORDER SIRENIA)"
    },
    {
      "code": "02109300",
      "description": "MEAT AND EDIBLE MEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL-OTHER, INCLUDING EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL : OF REPTILES(INCLUDING SNAKES AND TURTLES)"
    },
    {
      "code": "02109900",
      "description": "MEAT AND EDIBLE MEAT OFFAL, SALTED, INBRINE, DRIED OR SMOKED;EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL-OTHER, INCLUDING EDIBLE FLOURS AND MEALS OF MEAT OR MEAT OFFAL -- OTHER"
    },
    {
      "code": "03",
      "description": "Fish and crustaceans, molluscs and other aquatic invertebrates"
    },
    {
      "code": "0301",
      "description": "LIVE FISH - Ornamental fish: OTHER FISH MEAT OF HEADING 0304 - Salmonidae, excluding livers and roes :\""
    },
    {
      "code": "03011100",
      "description": "LIVE FISH -ORNAMENTAL FISH--FRESHWATER"
    },
    {
      "code": "03011900",
      "description": "LIVE FISH -ORNAMENTAL FISH--OTHER"
    },
    {
      "code": "03019100",
      "description": "LIVE FISHOTHER LIVE FISH TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI, ONCORHYNCHUSAGUABONITA, ONCORHYNCHUS GILAE,ONCORHYNCHUS APACHE AND ONCORHYNCHUSCHRYSOGASTER"
    },
    {
      "code": "03019200",
      "description": "LIVE FISHOTHER LIVE FISH EELS (ANGUILLA SPP.)"
    },
    {
      "code": "03019300",
      "description": "Carp (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus,Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp."
    },
    {
      "code": "03019400",
      "description": "LIVE FISH-OTHER LIVE FISH-- BLUEFIN TUNAS (THUNNUS THYNNUS)"
    },
    {
      "code": "03019500",
      "description": "LIVE FISH-OTHER LIVE FISH--SOUTHERN BLUEFIN TUNAS (THUNNUS MACCOYII)"
    },
    {
      "code": "03019900",
      "description": "LIVE FISHOTHER LIVE FISH OTHER"
    },
    {
      "code": "0302",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304 - Salmonidae, excluding livers and roes:"
    },
    {
      "code": "03021100",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304SALMONIDAE, EXCLUDING LIVERS AND ROES : TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI, ONCORHYNCHUSAGUABONITA, ONCORHYNCHUS GILAE,ONCORHYNCHUS APACHE AND ONCORHYNCHUSCHRYSOGASTER)"
    },
    {
      "code": "03021300",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304-SALMONIDAE, EXCLUDING LIVERS AND ROES : --PACIFIC SALMON (ONCORHYNCHUS NERKA, ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS)"
    },
    {
      "code": "03021400",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304-SALMONIDAE, EXCLUDING LIVERS AND ROES : --ATLANTIC SALMON (SALMO SALAR) AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03021900",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304SALMONIDAE, EXCLUDING LIVERS AND ROES : OTHER"
    },
    {
      "code": "03022100",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEUTRONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : HALIBUT (REINHARDTIUS HIPPOGLOSSOIDES, HIPPOGLOSSUS HIPPOGLOSSUS, HIPPOGLOSSUSSTENOLEPIS)"
    },
    {
      "code": "03022200",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEUTRONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : PLAICE (PLEURONECTES PLATESSA)"
    },
    {
      "code": "03022300",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEUTRONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : SOLE (SOLEA SPP.)"
    },
    {
      "code": "03022400",
      "description": "Turbots (Psetta maxima)"
    },
    {
      "code": "03022900",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEUTRONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : OTHER"
    },
    {
      "code": "03023100",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : ALBACORE OR LONGFINNED TUNAS"
    },
    {
      "code": "03023200",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : YELLOWFIN TUNAS (THUNNUS ALBACARES)"
    },
    {
      "code": "03023300",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : SKIPJACK OR STRIPE BELLIED BONITO"
    },
    {
      "code": "03023400",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : BIGEYE TUNAS (THUNNUS OBESUS)"
    },
    {
      "code": "03023500",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : BLUEFIN TUNAS (THUNNUS THYNNUS)"
    },
    {
      "code": "03023600",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : SOUTHERN BLUEFIN TUNAS (THUNNUS MACCOYII)"
    },
    {
      "code": "03023900",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE?BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS], EXCLUDING LIVERS AND ROES : OTHER"
    },
    {
      "code": "03024000",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII) EXCLUDING LIVERS AND ROES"
    },
    {
      "code": "03024100",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII)"
    },
    {
      "code": "03024200",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- ANCHOVIESB(ENGRAULIS SPP.)"
    },
    {
      "code": "03024300",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS)"
    },
    {
      "code": "03024400",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:--  MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS)"
    },
    {
      "code": "03024500",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- JACK AND ORSE MACKEREL (TRACHURUS SPP.)"
    },
    {
      "code": "03024600",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND HORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- COBIA (RACHYCENTRON CANADUM)"
    },
    {
      "code": "03024700",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304-HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), ANCHOVIES (ENGRAULIS SPP.), SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS) , MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS), JACK AND ORSE MACKEREL (TRACHURUS SPP.), COBIA (RACHYCENTRON CANADUM) AND SWORDFISH (XIPHIAS GLADIUS), EXCLUDING LIVERS AND ROES:-- SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03024900",
      "description": "Other"
    },
    {
      "code": "03025000",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS), EXCLUDING LIVERS AND ROES"
    },
    {
      "code": "03025100",
      "description": "COD (GADUS MORHUA, GADUS OGAS, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03025200",
      "description": "HADDOCK (MELANOGRAMMUS AEGLEFINUS)"
    },
    {
      "code": "03025300",
      "description": "COALFISH (POLLACHIUS VIRENS)"
    },
    {
      "code": "03025400",
      "description": "HAKE (MERLUCCIUS SPP., UROPHYCIS SPP.)"
    },
    {
      "code": "03025500",
      "description": "ALASKA POLLACK(THERAGRA CHALCORRAMMA)"
    },
    {
      "code": "03025600",
      "description": "BLUE WHITINGS (MICROMEISITIUS POTASSOU, MICROMEISITIUS AUSTRALIS)"
    },
    {
      "code": "03025900",
      "description": "OTHER"
    },
    {
      "code": "03026100",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS)"
    },
    {
      "code": "03026200",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES HADDOCK (MELANOGRAMMUS AEGLEFINUS)"
    },
    {
      "code": "03026300",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES COALFISH (POLLACHIUS VIRENS)"
    },
    {
      "code": "03026400",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS)"
    },
    {
      "code": "03026500",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES DOGFISH AND OTHER SHARKS"
    },
    {
      "code": "03026600",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304- OTHER FISH, EXCLUDING LIVERS AND ROES:--  EELS (ANGUILLA SPP.)"
    },
    {
      "code": "03026700",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304- OTHER FISH, EXCLUDING LIVERS AND ROES:--  SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03026800",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304- OTHER FISH, EXCLUDING LIVERS AND ROES:-- TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03026910",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES  OTHER : HILSA"
    },
    {
      "code": "03026920",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES  OTHER DARA"
    },
    {
      "code": "03026930",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES  OTHER POMFRET"
    },
    {
      "code": "03026990",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES  OTHER OTHER"
    },
    {
      "code": "03027000",
      "description": "FISH, FRESH OR CHILLED, EXCLUDING FISHFILLETS AND OTHER FISH MEAT OF HEADING 0304LIVERS AND ROES"
    },
    {
      "code": "03027100",
      "description": "TILAPIAS (OREOCHROMIS SPP.P"
    },
    {
      "code": "03027200",
      "description": "CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.)"
    },
    {
      "code": "03027300",
      "description": "CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CLENPHARYNGADON IDELLUS, HYPOTASPP., MYLOPHARYNGODON PICEUS)LMICHTHYS SPP., CIRRHINUS"
    },
    {
      "code": "03027400",
      "description": "EELS (ANGUILLA SPP.)"
    },
    {
      "code": "03027900",
      "description": "OTHER"
    },
    {
      "code": "03028100",
      "description": "DOGFISH AND OTHER SHARKS"
    },
    {
      "code": "03028200",
      "description": "RAYS AND SKATES (RAJIDAE)"
    },
    {
      "code": "03028300",
      "description": "TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03028400",
      "description": "SEA BASS (DICENTRARCHUS SPP.)"
    },
    {
      "code": "03028500",
      "description": "SEABREAM(SPARIDAE)"
    },
    {
      "code": "030289",
      "description": "Others"
    },
    {
      "code": "03028910",
      "description": "HILSA"
    },
    {
      "code": "03028920",
      "description": "DARA"
    },
    {
      "code": "03028930",
      "description": "POMFRET"
    },
    {
      "code": "03028990",
      "description": "OTHER"
    },
    {
      "code": "03029000",
      "description": "LIVERS AND ROES"
    },
    {
      "code": "030291",
      "description": "Livers, roes and milt"
    },
    {
      "code": "03029110",
      "description": "livers, roes and milt"
    },
    {
      "code": "030292",
      "description": "Sharkfins"
    },
    {
      "code": "03029210",
      "description": "Shark fins"
    },
    {
      "code": "030299",
      "description": "Other"
    },
    {
      "code": "03029910",
      "description": "Fish fins other than shark fins; heads, tails and maws"
    },
    {
      "code": "03029990",
      "description": "Other edible fish offal"
    },
    {
      "code": "0303",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS AND OTHER FISH MEAT OF HEADING 0304 - Salmonidae, excluding livers and roes :"
    },
    {
      "code": "03031100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 PACIFIC SALMON (ONCORHYNCHUS NERKA,ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),EXCLUDING LIVERS AND ROES : SOCKEYE SALMON (RED-SALMON) (ONCORHYNCHUS NERKA)"
    },
    {
      "code": "03031200",
      "description": "OTHER PACIFIC NSALMON(ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),"
    },
    {
      "code": "03031300",
      "description": "ATLANTIC SALMON (SALMO SALAR) AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03031400",
      "description": "TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI,ONCORHYNCHUS AGUABONITA,ONCORHYNCHUS GILAE, ONCORHYNCHUS APACHE ANDONCORHYNCHUS CHRYSOGASTER)"
    },
    {
      "code": "03031900",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304? PACIFIC SALMON (ONCORHYNCHUS NERKA,ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),EXCLUDING LIVERS AND ROES : OTHER"
    },
    {
      "code": "03032100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER SALMONIDAE, EXCLUDING LIVERS AND ROES     TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI,ONCORHYNCHUS AGUABONITA,ONCORHYNCHUS GILAE, ONCORHYNCHUS APACHE ANDONCORHYNCHUS CHRYSOGASTER)"
    },
    {
      "code": "03032200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER SALMONIDAE, EXCLUDING LIVERS AND ROES     ATLANTIC SALMON (SALMO SALAR) AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03032300",
      "description": "tilapias(preochromia spp.)"
    },
    {
      "code": "03032400",
      "description": "CATFISH (PANGASIUS SPP., SILURUS SPP., CLARIAS SPP., ICTALURUS SPP.)"
    },
    {
      "code": "03032500",
      "description": "CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CLENPHARYNGADON IDELLUS, HYPOPHTHALMICTHYS SPP. CIRRHINUS SPP."
    },
    {
      "code": "03032600",
      "description": "EELS (ANGUILLA SPP.)"
    },
    {
      "code": "03032900",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER SALMONIDAE, EXCLUDING LIVERS AND ROES     OTHER"
    },
    {
      "code": "03033100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : HALIBUT (REINHARDTIUS HIPPOGLOSSOIDES, HIPPOGLOSSUS HIPPOGLOSSUS, HIPPOGLOSSUSSTENOLEPIS)"
    },
    {
      "code": "03033200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : PLAICE (PLEURONECTES PLATESSA)"
    },
    {
      "code": "03033300",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : SOLE (SOLEA SPP.)"
    },
    {
      "code": "03033400",
      "description": "Turbots (Psetta maxima)"
    },
    {
      "code": "03033900",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE), EXCLUDING LIVERS AND ROES : OTHER"
    },
    {
      "code": "03034100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : FLAT FISH ALBACORE OR LONGFINNED TUNAS (THUNNUS ALALUNGA)"
    },
    {
      "code": "03034200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : YELLOWFIN TUNAS (THUNNUS ALBACARES)"
    },
    {
      "code": "03034300",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : SKIPJACK OR STRIPE-BELLIED BONITO"
    },
    {
      "code": "03034400",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : BIGEYE TUNAS (THUNNUS OBESUS)"
    },
    {
      "code": "03034500",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : BLUEFIN TUNAS (THUNNUS THYNNUS)"
    },
    {
      "code": "03034600",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : SOUTHERN BLUEFIN TUNAS (THUNNUS MACCOYII)"
    },
    {
      "code": "03034900",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304TUNAS (OF THE GENUS THUNNUS), SKIPJACKOR STRIPE?BELLIED BONITO [EUTHYNNUS(KATSUWONUS) PELAMIS], EXCLUDING LIVERSAND ROES : OTHER"
    },
    {
      "code": "03035000",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII), EXCLUDING LIVERS AND ROES"
    },
    {
      "code": "03035100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 -HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII) AND COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS) EXCLUDING LIVERS AND ROES: --HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII)"
    },
    {
      "code": "03035200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 -HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII) AND COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS) EXCLUDING LIVERS AND ROES: -- COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03035300",
      "description": "SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.) BRISLING OR SPRATS(SPRATTUS SPRATTUS)"
    },
    {
      "code": "03035400",
      "description": "MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS),"
    },
    {
      "code": "03035500",
      "description": "ACK AND ORSE MACKEREL (TRACHURUS SPP.)"
    },
    {
      "code": "03035600",
      "description": "COBIA (RACHYCENTRON CANADUM)"
    },
    {
      "code": "03035700",
      "description": "SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03035910",
      "description": "Indian mackerels (Rastreliger spp.)"
    },
    {
      "code": "03035990",
      "description": "other"
    },
    {
      "code": "03036000",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS), EXCLUDING LIVERS AND ROES"
    },
    {
      "code": "03036100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 - SWORDFISH(XIPHIA GLADIUS) AND TOOTHFISH (DISSOSTICHUS SPP.) EXCLUDING LIVERS AND ROES: -- SWORDFISH(XIPHIA GLADIUS)"
    },
    {
      "code": "03036200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 - SWORDFISH(XIPHIA GLADIUS) AND TOOTHFISH (DISSOSTICHUS SPP.) EXCLUDING LIVERS AND ROES: -- TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03036300",
      "description": "COD (GADUS MORHUA, GADUS OGAS, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03036400",
      "description": "Haddock (Melangrammus aeglefinus)"
    },
    {
      "code": "03036500",
      "description": "COALFISH (POLLACHIUS VIRENS)"
    },
    {
      "code": "03036600",
      "description": "HAKE (MERLUCCIUS SPP., UROPHYCIS SPP.)"
    },
    {
      "code": "03036700",
      "description": "ALASKA POLLACK(THERAGRA CHALCORRAMMA)"
    },
    {
      "code": "03036800",
      "description": "BLUE WHITINGS (MICROMEISITIUS POTASSOU, MICROMEISITIUS AUSTRALIS)"
    },
    {
      "code": "03036900",
      "description": "other"
    },
    {
      "code": "03037100",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : SARDINES (SARDINA PILCHARDUS, SARDINOPS SPP.), SARDINELLA (SARDINELLA SPP.), BRISLING OR SPRATS(SPRATTUS SPRATTUS)"
    },
    {
      "code": "03037200",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : HADDOCK (MELANOGRAMMUS AEGLEFINUS)"
    },
    {
      "code": "03037300",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : COALFISH (POLLACHIUS VIRENS)"
    },
    {
      "code": "03037400",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : MACKEREL (SCOMBER SCOMBRUS, SCOMBER AUSTRALASICUS, SCOMBER JAPONICUS)"
    },
    {
      "code": "03037500",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : DOGFISH AND OTHER SHARKS"
    },
    {
      "code": "03037600",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : EELS (ANGUILLA SPP.)"
    },
    {
      "code": "03037700",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : SEA BASS (DICENTRARCHUS LABRAX, DICENTRARCHUS PUNCTATUS)"
    },
    {
      "code": "03037800",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER FISH, EXCLUDING LIVERS AND ROES : HAKE (MERLUCCIUS SPP., UROPHYCIS SPP.)"
    },
    {
      "code": "03037910",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : HILSA"
    },
    {
      "code": "03037920",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : DARA"
    },
    {
      "code": "03037930",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : RIBBON FISH"
    },
    {
      "code": "03037940",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : SEER"
    },
    {
      "code": "03037950",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : POMFRET (WHITE OR SILVER OR BLACK)"
    },
    {
      "code": "03037960",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : GHOLE"
    },
    {
      "code": "03037970",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : THREADFIN"
    },
    {
      "code": "03037980",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : CROACKER, GROUPER, HOUNDER"
    },
    {
      "code": "03037991",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : EDIBLE FISHMAWS OF WILD LIFE"
    },
    {
      "code": "03037992",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : EDIBLE SHARK FINS OF WILD LIFE"
    },
    {
      "code": "03037999",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304 OTHER : OTHER"
    },
    {
      "code": "03038010",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304LIVERS AND ROES  EGG OR EGG YOLK OF FISH INCLUDING SHRIMPS"
    },
    {
      "code": "03038090",
      "description": "FISH, FROZEN, EXCLUDING FISH FILLETS ANDOTHER FISH MEAT OF HEADING 0304LIVERS AND ROES  OTHER"
    },
    {
      "code": "030381",
      "description": "Dogfish and other sharks"
    },
    {
      "code": "03038110",
      "description": "DOGFISH"
    },
    {
      "code": "03038190",
      "description": "OTHER SHARKS"
    },
    {
      "code": "03038200",
      "description": "RAYS AND SKATES (RAJIDAE)"
    },
    {
      "code": "03038300",
      "description": "TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03038400",
      "description": "SEA BASS (DICENTRARCHUS SPP.)"
    },
    {
      "code": "030389",
      "description": "other"
    },
    {
      "code": "03038910",
      "description": "HILSA"
    },
    {
      "code": "03038920",
      "description": "DARA"
    },
    {
      "code": "03038930",
      "description": "RIBBON FISH"
    },
    {
      "code": "03038940",
      "description": "SEER"
    },
    {
      "code": "03038950",
      "description": "POMFRET (WHITE OR SILVER OR BLACK)"
    },
    {
      "code": "03038960",
      "description": "GHOLE"
    },
    {
      "code": "03038970",
      "description": "THR3ADFIN"
    },
    {
      "code": "03038980",
      "description": "CROACKERS GROUPERS, FLOUNDERS"
    },
    {
      "code": "03038990",
      "description": "Other"
    },
    {
      "code": "03038991",
      "description": "EDIBLE FISHMAWS OF WILD LIFE"
    },
    {
      "code": "03038992",
      "description": "EDIBLE SHARK FINS OF WILD LIFE"
    },
    {
      "code": "03038999",
      "description": "OTHER"
    },
    {
      "code": "03039010",
      "description": "EGG OR EGG YOLK OF FISH"
    },
    {
      "code": "03039090",
      "description": "OTHER"
    },
    {
      "code": "030391",
      "description": "livers, roes and milt"
    },
    {
      "code": "03039110",
      "description": "Egg or Egg yolk of fish"
    },
    {
      "code": "03039190",
      "description": "Other"
    },
    {
      "code": "030392",
      "description": "Shark fins"
    },
    {
      "code": "03039210",
      "description": "Shark fins"
    },
    {
      "code": "030399",
      "description": "Other"
    },
    {
      "code": "03039910",
      "description": "Fish fins other than shark fins; heads, tails and maws"
    },
    {
      "code": "03039990",
      "description": "Other edible fish offal"
    },
    {
      "code": "0304",
      "description": "FISH FILLETS AND OTHER FISH MEAT (WHETHER OR NOT MINCED), FRESH, CHILLED OR FROZEN - Fresh or chilled fillets of tilapias (Oreochromis spp.), catfish (Pangasius spp., Silurus spp., Clarias spp.,Ictalurus spp.),carp (Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp.,Mylopharyngodon piceus), eels (Anguilla spp.), Nile perch (Lates niloticus) and snakeheads (Channa spp.):"
    },
    {
      "code": "03041000",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FRESH OR CHILLED"
    },
    {
      "code": "03041100",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN -FRESH OR CHILLED: -- SWORDFISH (XIPHIA GLADIUS)"
    },
    {
      "code": "03041200",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FRESH OR CHILLED: -- TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03041900",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN - FRESH OR CHILLED: -- OTHER"
    },
    {
      "code": "03042010",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  HILSA"
    },
    {
      "code": "03042020",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  SHARK"
    },
    {
      "code": "03042030",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  SEER"
    },
    {
      "code": "03042040",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  TUNA"
    },
    {
      "code": "03042050",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  CUTTLEFISH"
    },
    {
      "code": "03042090",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN FROZEN FILLETS  OTHER"
    },
    {
      "code": "03042100",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03042200",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03042910",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS:-- OTHER: --- HILSA"
    },
    {
      "code": "03042920",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: --OTHER: ---  SHARK"
    },
    {
      "code": "03042930",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: --OTHER:--- SEER"
    },
    {
      "code": "03042940",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: --OTHER --- TUNA"
    },
    {
      "code": "03042950",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN - FROZEN FILLETS: --OTHER: --- CUTTLEFISH"
    },
    {
      "code": "03042990",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- FROZEN FILLETS: --OTHER: --- OTHER"
    },
    {
      "code": "03043100",
      "description": "TILAPIAS(OREOCHROMIS SPP.P"
    },
    {
      "code": "03043200",
      "description": "CATFISH (PANGASIUS SPP., SILURUS SPP., CLARIAS SPP., ICTALURUS SPP.)"
    },
    {
      "code": "03043300",
      "description": "NILE PERCH (LATES NILOTICUS)"
    },
    {
      "code": "03043900",
      "description": "OTHER"
    },
    {
      "code": "03044100",
      "description": "PACIFIC SALMON(ONCORHYNCHUS NERKA, ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),ATLANTIC SALMON (SALMO SALAR) AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03044200",
      "description": "TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI,ONCORHYNCHUS AGUABONITA,ONCORHYNCHUS GILAE, ONCORHYNCHUS APACHE ANDONCORHYNCHUS CHRYSOGASTER)"
    },
    {
      "code": "03044300",
      "description": "FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE),"
    },
    {
      "code": "03044400",
      "description": "FISH OF THE FAMILIES BREGMACEROLIDAE, EUCLICHTHYIDAE, GADIDAE, MACROURIDAE, MELANONIDAE, MERLUCCIIDAE, MORIDAE AND  MURAENOLEPIDIDAE"
    },
    {
      "code": "03044500",
      "description": "SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03044600",
      "description": "TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03044700",
      "description": "Dogfish and other sharks"
    },
    {
      "code": "03044800",
      "description": "Rays and skates (Rajidae)"
    },
    {
      "code": "030449",
      "description": "Other"
    },
    {
      "code": "03044910",
      "description": "HILSA"
    },
    {
      "code": "03044920",
      "description": "SHARK"
    },
    {
      "code": "03044930",
      "description": "SEER"
    },
    {
      "code": "03044940",
      "description": "TUNA"
    },
    {
      "code": "03044990",
      "description": "OTHER"
    },
    {
      "code": "03045100",
      "description": "TILAPIAS (OREOCHROMIS SPP) ,CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.) CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CTENPHARYNGODON IDELLUS, HYPOPHTHALMICHTHYS SPP., CIRRHINUS, MYLOPHARYNGODON PICEUS) , EELS(ANGUILLA SPP.),NILE PERCH,(LATES NILOTICUS) AND SNAKEHEADS(CHANNA SPP.)"
    },
    {
      "code": "03045200",
      "description": "SALMONIDAE"
    },
    {
      "code": "03045300",
      "description": "FISH OF THE FAMILIES BREGMACEROLIDAE, EUCLICHTHYIDAE, GADIDAE, MACROURIDAE, MELANONIDAE, MERLUCCIIDAE, MORIDAE AND MURAENOLEPIDIDAE"
    },
    {
      "code": "03045400",
      "description": "SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03045500",
      "description": "TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03045600",
      "description": "Dogfish and other sharks"
    },
    {
      "code": "03045700",
      "description": "Rays and skates (Rajidae)"
    },
    {
      "code": "030459",
      "description": "Other"
    },
    {
      "code": "03045910",
      "description": "HILSA"
    },
    {
      "code": "03045920",
      "description": "SHARK"
    },
    {
      "code": "03045930",
      "description": "SEER"
    },
    {
      "code": "03045940",
      "description": "TUNA"
    },
    {
      "code": "03045990",
      "description": "OTHER"
    },
    {
      "code": "03046100",
      "description": "tilapias(Oreochromia spp.)"
    },
    {
      "code": "03046200",
      "description": "CATFISH (PANGASIUS SPP., SILURUS SPP., CLARIAS SPP., ICTALURUS SPP.)"
    },
    {
      "code": "03046300",
      "description": "NILE PERCH (LATES NILOTICUS)"
    },
    {
      "code": "03046900",
      "description": "OTHER"
    },
    {
      "code": "03047100",
      "description": "COD (GADUS MORHUA, GADUS OGAS, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03047200",
      "description": "HADDOCK (MELANOGRAMMUS AEGLEFINUS)"
    },
    {
      "code": "03047300",
      "description": "COALFISH (POLLACHIUS VIRENS)"
    },
    {
      "code": "03047400",
      "description": "HAKE (MERLUCCIUS SPP., UROPHYCIS SPP.)"
    },
    {
      "code": "03047500",
      "description": "ALASKA POLLACK(THERAGRA CHALCORRAMMA)"
    },
    {
      "code": "03047900",
      "description": "OTHER"
    },
    {
      "code": "03048100",
      "description": "PACIFIC SALMON(ONCORHYNCHUS NERKA, ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),ATLANTIC SALMON (SALMO SALAR) AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03048200",
      "description": "TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI,ONCORHYNCHUS AGUABONITA,ONCORHYNCHUS GILAE, ONCORHYNCHUS APACHE ANDONCORHYNCHUS CHRYSOGASTER)"
    },
    {
      "code": "03048300",
      "description": "FLAT FISH (PLEURONECTIDAE, BOTHIDAE,CYNOGLOSSIDAE, SOLEIDAE, SCOPHTHALMIDAEAND CITHARIDAE),"
    },
    {
      "code": "03048400",
      "description": "SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03048500",
      "description": "TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03048600",
      "description": "HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII)"
    },
    {
      "code": "03048700",
      "description": "TUNAS (OF THE GENUS THUNNUS), SKIPJACK ORSTRIPE-BELLIED BONITO [EUTHYNNUS (KATSUWONUS)PELAMIS],"
    },
    {
      "code": "030488",
      "description": "Dogfish, other Sharks Rays and skates (Rajidae)"
    },
    {
      "code": "03048810",
      "description": "Dogfish"
    },
    {
      "code": "03048820",
      "description": "Other Sharks"
    },
    {
      "code": "03048830",
      "description": "Rays and skates (Rajidae)"
    },
    {
      "code": "030489",
      "description": "Other"
    },
    {
      "code": "03048910",
      "description": "HILSA"
    },
    {
      "code": "03048920",
      "description": "SHARK"
    },
    {
      "code": "03048930",
      "description": "SEER"
    },
    {
      "code": "03048940",
      "description": "Tuna"
    },
    {
      "code": "03048990",
      "description": "OTHER"
    },
    {
      "code": "03049000",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN-OTHER"
    },
    {
      "code": "03049100",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- OTHER: --SWORDFISH (XIPHIAS GLADIUS)"
    },
    {
      "code": "03049200",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- OTHER: --TOOTHFISH (DISSOSTICHUS SPP.)"
    },
    {
      "code": "03049300",
      "description": "TILAPIAS (OREOCHROMIS SPP) ,CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.) CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CTENPHARYNGODON IDELLUS, HYPOPHTHALMICHTHYS SPP., CIRRHINUS, MYLOPHARYNGODON PICEUS) , EELS(ANGUILLA SPP.),NILE PERCH,(LATES NILOTICUS) AND SNAKEHEADS(CHANNA SPP.)"
    },
    {
      "code": "03049400",
      "description": "ALASKA POLLACK(THERAGRA CHALCORRAMMA)"
    },
    {
      "code": "03049500",
      "description": "FISH OF THE FAMILIES BREGMACEROLIDAE, EUCLICHTHYIDAE, GADIDAE, MACROURIDAE, MELANONIDAE, MERLUCCIIDAE, MORIDAE AND MURAENOLEPIDIDAE , OTHER THAN ALASKA POLLACK(THERAGRA CHALCORRAMMA)"
    },
    {
      "code": "03049600",
      "description": "Dogfish and other sharks"
    },
    {
      "code": "03049700",
      "description": "Rays and skates (Rajidae)"
    },
    {
      "code": "03049900",
      "description": "FISH FILLETS AND OTHER FISH MEAT(WHETHER OR NOT MINCED), FRESH,CHILLED OR FROZEN- OTHER : -- OTHER"
    },
    {
      "code": "0305",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKED FISH, WHETHER OR NOT COOKED BEFORE OR DURING THE SMOKING PROCESS; FLOURS, MEALS AND PELLETS, OF FISH FIT FOR HUMAN CONSUMPTION"
    },
    {
      "code": "03051000",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTIONFLOURS, MEALS AND PELLETS, OF FISH FIT FOR HUMAN CONSUMPTION"
    },
    {
      "code": "03052000",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION LIVERS AND ROES OF FISH, DRIED, SMOKED, SALTED OR IN BRINE"
    },
    {
      "code": "03053000",
      "description": "FISH FILLETS, DRIED, SALTED OR IN BRINE, BUT NOT SMOKED"
    },
    {
      "code": "03053100",
      "description": "TILAPIAS (OREOCHROMIS SPP) ,CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.) CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CTENPHARYNGODON IDELLUS, HYPOPHTHALMICHTHYS SPP., CIRRHINUS, MYLOPHARYNGODON PICEUS) , EELS(ANGUILLA SPP.),NILE PERCH,(LATES NILOTICUS) AND SNAKEHEADS(CHANNA SPP.)"
    },
    {
      "code": "03053200",
      "description": "FISH OF THE FAMILIES BREGMACEROLIDAE, EUCLICHTHYIDAE, GADIDAE, MACROURIDAE, MELANONIDAE, MERLUCCIIDAE, MORIDAE AND MURAENOLEPIDIDAE"
    },
    {
      "code": "03053900",
      "description": "OTHER"
    },
    {
      "code": "03054100",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION SMOKED FISH, INCLUDING FILLETS : PACIFIC SALMON (ONCORHYNCHUS NERKA, ONCORHYNCHUS GORBUSCHA, ONCORHYNCHUSKETA, ONCORHYNCHUS TSCHAWYTSCHA,ONCORHYNCHUS KISUTCH, ONCORHYNCHUSMASOU AND ONCORHYNCHUS RHODURUS),ATLANTIC SALMON (SALMO SALAR)AND DANUBE SALMON (HUCHO HUCHO)"
    },
    {
      "code": "03054200",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION SMOKED FISH, INCLUDING FILLETS : HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII )"
    },
    {
      "code": "03054300",
      "description": "TROUT (SALMO TRUTTA, ONCORHYNCHUS MYKISS, ONCORHYNCHUS CLARKI,ONCORHYNCHUS AGUABONITA,ONCORHYNCHUS GILAE, ONCORHYNCHUS APACHE ANDONCORHYNCHUS CHRYSOGASTER)"
    },
    {
      "code": "03054400",
      "description": "TILAPIAS (OREOCHROMIS SPP) ,CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.) CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CTENPHARYNGODON IDELLUS, HYPOPHTHALMICHTHYS SPP., CIRRHINUS, MYLOPHARYNGODON PICEUS) , EELS(ANGUILLA SPP.),NILE PERCH,(LATES NILOTICUS) AND SNAKEHEADS(CHANNA SPP.)"
    },
    {
      "code": "03054900",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION SMOKED FISH, INCLUDING FILLETS : OTHER"
    },
    {
      "code": "03055100",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  DRIED FISH, WHETHER OR NOT SALTED BUT NOTSMOKED : COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03055200",
      "description": "Tilapias (Oreochromis spp.), catfish (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carp (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), eels (Anguilla spp.), Nile perch (Lates niloticus) and snakeheads (Channa spp.)"
    },
    {
      "code": "03055300",
      "description": "Fish of the families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae, other than cod ( Gadus morhua, Gadus ogac, Gadus macrocephalus)"
    },
    {
      "code": "03055400",
      "description": "Herrings (Clupea harengus, Clupea pallasii), anchovies (Engraulis spp.), sardines (Sardina pilchardus, Sardinops spp.), sardinella (Sardinella spp.), brisling or sprats (Sprattus sprattus), mackerel (Scomber scombrus, Scomber australasicus, Scomber japonicus), Indian mackerels (Rastrelliger spp.), seerfishes (Scomberomorus spp.), jack and horse mackerel (Trachurus spp.), jacks, crevalles (Caranx spp.), cobia (Rachycentron canadum), silver pomfrets (Pampus spp.), Pacific saury (Cololabis saira), scads (Decapterus spp.), capelin (Mallotus villosus), Sword fish (Xiphias gladius), Kawakawa (Euthynnus affinis), bonitos (Sarda spp.), marlins, sailfishes, spearfish (Istiophoridae)"
    },
    {
      "code": "030559",
      "description": "Other"
    },
    {
      "code": "03055910",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  DRIED FISH, WHETHER OR NOT SALTED BUT NOTSMOKED :   OTHER : MUMBAI DUCK"
    },
    {
      "code": "03055920",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  DRIED FISH, WHETHER OR NOT SALTED BUT NOTSMOKED :   OTHER : SEER WITHOUT HEAD"
    },
    {
      "code": "03055930",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  DRIED FISH, WHETHER OR NOT SALTED BUT NOTSMOKED :   OTHER : SPRATS"
    },
    {
      "code": "03055990",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  DRIED FISH, WHETHER OR NOT SALTED BUT NOTSMOKED :   OTHER : OTHER"
    },
    {
      "code": "03056100",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  FISH, SALTED BUT NOT DRIED OR SMOKED ANDFISH IN BRINE : HERRINGS (CLUPEA HARENGUS, CLUPEA PALLASII )"
    },
    {
      "code": "03056200",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  FISH, SALTED BUT NOT DRIED OR SMOKED ANDFISH IN BRINE : COD (GADUS MORHUA, GADUS OGAC, GADUS MACROCEPHALUS)"
    },
    {
      "code": "03056300",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  FISH, SALTED BUT NOT DRIED OR SMOKED ANDFISH IN BRINE : ANCHOVIES (ENGRAULIS SPP. )"
    },
    {
      "code": "03056400",
      "description": "TILAPIAS (OREOCHROMIS SPP) ,CATFISH (PANGALASIUS SPP.,SILURUS SPP.,CLARIAS SPP., ICTALURUS SPP.) CARP(CYPRINUS CARPIO, CARASSIUS CARASSIUS, CTENPHARYNGODON IDELLUS, HYPOPHTHALMICHTHYS SPP., CIRRHINUS, MYLOPHARYNGODON PICEUS) , EELS(ANGUILLA SPP.),NILE PERCH,(LATES NILOTICUS) AND SNAKEHEADS(CHANNA SPP.)"
    },
    {
      "code": "030569",
      "description": "Other"
    },
    {
      "code": "03056910",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  OTHER :   MUMBAI DUCK"
    },
    {
      "code": "03056920",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  OTHER :    SEER WITHOUT HEAD"
    },
    {
      "code": "03056930",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  OTHER :    SPRATS"
    },
    {
      "code": "03056990",
      "description": "FISH, DRIED, SALTED OR IN BRINE; SMOKEDFISH, WHETHER OR NOT COOKED BEFORE ORDURING THE SMOKING PROCESS; FLOURS,MEALS AND PELLETS, OF FISH FIT FOR HUMANCONSUMPTION  OTHER :    OTHER"
    },
    {
      "code": "03057100",
      "description": "SHARK FINS"
    },
    {
      "code": "03057200",
      "description": "FISH HEADS,TAILS AND MAWS"
    },
    {
      "code": "03057900",
      "description": "OTHER"
    },
    {
      "code": "0306",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT, LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTED OR IN BRINE; SMOKED CRUSTACEANS, WHETHER IN SHELL OR NOT, WHETHER OR NOT COOKED BEFORE OR DURING THE SMOKING PROCESS; CRUSTACEANS, IN SHELL, COOKED BY STEAMING OR BY BOILING IN WATER, WHETHER OR NOT CHILLED, FROZEN, DRIED, SALTED OR IN BRINE; FLOURS, MEALS AND PELLETS OF CRUSTACEANS, FIT FOR HUMAN CONSUMPTION. - Frozen :"
    },
    {
      "code": "03061100",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTION FROZEN : ROCK LOBSTER AND OTHER SEA CRAW FISH(PALINURUS SPP., PANULIRUS SPP., JASUS SPP.)"
    },
    {
      "code": "030612",
      "description": "Lobsters (Homarus spp.)"
    },
    {
      "code": "03061210",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONFROZEN : LOBSTERS (HOMARUS SPP.) WHOLE, COOKED"
    },
    {
      "code": "03061290",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONFROZEN   LOBSTERS (HOMARUS SPP.) OTHER"
    },
    {
      "code": "03061400",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONFROZEN : CRABS"
    },
    {
      "code": "030615",
      "description": "Norway lobsters"
    },
    {
      "code": "03061500",
      "description": "NORWAY LOBSTERS(NEPHOPS NORVEGICUS)"
    },
    {
      "code": "030616",
      "description": "Cold-water shrimps and prawns (Pandalus spp., Crangon crangon)"
    },
    {
      "code": "03061610",
      "description": "ACCELERATED FREEZE DRIED(AFD)"
    },
    {
      "code": "03061690",
      "description": "OTHER"
    },
    {
      "code": "030617",
      "description": "Other Shrimps and prawns Scampi (Macrobrachium spp.)"
    },
    {
      "code": "03061711",
      "description": "ACCELERATED FREEZE DRIED(AFD)"
    },
    {
      "code": "03061719",
      "description": "OTHER"
    },
    {
      "code": "03061720",
      "description": "Vannamei Shrimp (LITOPENAEUS VANNAMEI)"
    },
    {
      "code": "03061730",
      "description": "Indian White Shrimp (FENNEROPENAEUS INDICUS)"
    },
    {
      "code": "03061740",
      "description": "Black tiger shrimp (Penaeusmonodon)"
    },
    {
      "code": "03061750",
      "description": "Flower Shrimp (Penaeus semisulcalus)"
    },
    {
      "code": "03061790",
      "description": "OTHER"
    },
    {
      "code": "03061900",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONFROZEN : OTHER, INCLUDING FLOURS, MEALS AND PELLETS, OF CRUSTACEANS, FIT FOR HUMAN CONSUMPTION"
    },
    {
      "code": "03062100",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONNOT FROZEN : ROCK LOBSTER AND OTHER SEA CRAW FISH(PALINURUS SPP., PANULIRUS SPP., JASUS SPP.)"
    },
    {
      "code": "03062200",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONNOT FROZEN : LOBSTERS (HOMARUS SPP.)"
    },
    {
      "code": "03062400",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONNOT FROZEN : CRABS"
    },
    {
      "code": "03062500",
      "description": "NORWAY LOBSTERS(NEPHOPS NORVEGICUS)"
    },
    {
      "code": "03062600",
      "description": "COLD-WATER SHRIMPS AND PRAWNS (PANDALUS SPP, CRANGON CRANGON)"
    },
    {
      "code": "03062710",
      "description": "POWDERED"
    },
    {
      "code": "03062790",
      "description": "OTHER"
    },
    {
      "code": "03062900",
      "description": "CRUSTACEANS, WHETHER IN SHELL OR NOT,LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTEDOR IN BRINE; CRUSTACEANS, IN SHELL, COOKEDBY STEAMING OR BY BOILING IN WATER,WHETHER OR NOT CHILLED, FROZEN, DRIED,SALTED OR IN BRINE; FLOURS, MEALS ANDPELLETS, OF CRUSTACEANS, FIT FOR HUMANCONSUMPTIONNOT FROZEN : OTHER, INCLUDING FLOURS, MEALS AND PELLETS, OF CRUSTACEANS, FIT FOR HUMAN CONSUMPTION"
    },
    {
      "code": "03063100",
      "description": "Rock lobster and other sea craw fish (Palinurus spp., Jasus spp.)"
    },
    {
      "code": "03063200",
      "description": "Lobsters (Homarus spp.)"
    },
    {
      "code": "03063300",
      "description": "Crabs"
    },
    {
      "code": "03063400",
      "description": "Norway lobsters (Nephrops norvegicus)"
    },
    {
      "code": "03063500",
      "description": "Cold-water shrimps and prawns (Pandalus spp., Crangon crangon)"
    },
    {
      "code": "03063600",
      "description": "Other shrimps and prawns"
    },
    {
      "code": "03063900",
      "description": "Other, including flours, meals and pellets, of crustaceans, fit for human consumption"
    },
    {
      "code": "03069100",
      "description": "Rock lobster and other sea crawfish (Palinurus spp., Jasus spp.)"
    },
    {
      "code": "03069200",
      "description": "Lobsters ( Homarus spp.)"
    },
    {
      "code": "03069300",
      "description": "Crabs"
    },
    {
      "code": "03069400",
      "description": "Norway lobsters (Nephrops norvegicus)"
    },
    {
      "code": "03069500",
      "description": "Shrimps and prawns"
    },
    {
      "code": "03069900",
      "description": "Other, including flours, meals and pellets, of crustaceans, fit for human consumption"
    },
    {
      "code": "0307",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTED OR IN BRINE; SMOKED MOLLUSCS, WHETHER IN SHELL OR NOT, WHETHER ON NOT COOKED BEFORE OR DURING THE SMOKING PROCESS; FLOURS, MEALS AND PELLETS OF MOLLUSES, FIT FOR HUMAN CONSUMPTION. - Oysters :"
    },
    {
      "code": "03071100",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03071200",
      "description": "Frozen"
    },
    {
      "code": "03071900",
      "description": "OTHER"
    },
    {
      "code": "03072100",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THANCRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION SCALLOPS, INCLUDING QUEEN SCALLOPS, OF THEGENERA PECTEN, CHLAMYS OR PLACOPECTEN  LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03072200",
      "description": "Frozen"
    },
    {
      "code": "03072900",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION SCALLOPS, INCLUDING QUEEN SCALLOPS, OF THEGENERA PECTEN, CHLAMYS OR PLACOPECTEN  OTHER"
    },
    {
      "code": "03073100",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONMUSSELS (MYTILUS SPP., PERNA SPP.) LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03073200",
      "description": "Frozen"
    },
    {
      "code": "030739",
      "description": "Other"
    },
    {
      "code": "03073910",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONOTHER :    CLAMS, CLAM MEAT (BIVALVES-VICTORITA, SPP., MERTRIX SPP. AND KATALYSIA SPP.)"
    },
    {
      "code": "03073990",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONOTHER :    OTHER"
    },
    {
      "code": "03074110",
      "description": "MOLLUSCS, WHETHER IN SHELL OR NOT, LIVE,FRESH, CHILLED, FROZEN, DRIED, SALTED OR INBRINE; AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED CUTTLE FISH"
    },
    {
      "code": "03074120",
      "description": "INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED SQUID"
    },
    {
      "code": "030742",
      "description": "Live, fresh or chilled"
    },
    {
      "code": "03074210",
      "description": "Cuttle fish"
    },
    {
      "code": "03074220",
      "description": "Squid"
    },
    {
      "code": "030743",
      "description": "Frozen"
    },
    {
      "code": "03074310",
      "description": "Cuttle fish"
    },
    {
      "code": "03074320",
      "description": "Whole squids"
    },
    {
      "code": "03074330",
      "description": "Squid tubes"
    },
    {
      "code": "030749",
      "description": "Other"
    },
    {
      "code": "03074910",
      "description": "INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED OTHER :    SQUID TUBES, FROZEN"
    },
    {
      "code": "03074920",
      "description": "INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED OTHER :    WHOLE SQUIDS, FROZEN"
    },
    {
      "code": "03074930",
      "description": "INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED OTHER :    DRIED SQUIDS"
    },
    {
      "code": "03074940",
      "description": "Dried squids"
    },
    {
      "code": "03074990",
      "description": "INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH,CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONCUTTLE FISH (SEPIA OFFICINALIS, ROSSIAMACROSOMA, SEPTIOLA SPP.) AND SQUID(OMMASTREPHES SPP., LOLIGO SPP., NOTOTODARUSSPP., SEPIOTEUTHIS SPP.) : LIVE, FRESH AND CHILLED OTHER :    OTHER"
    },
    {
      "code": "03075100",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONOCTOPUS (OCTOPUS SPP.) LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03075200",
      "description": "Frozen"
    },
    {
      "code": "03075900",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONOCTOPUS (OCTOPUS SPP.) OTHER"
    },
    {
      "code": "03076000",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION SNAILS, OTHER THAN SEA SNAILS"
    },
    {
      "code": "03077100",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03077200",
      "description": "Frozen"
    },
    {
      "code": "03077900",
      "description": "OTHER"
    },
    {
      "code": "03078100",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03078200",
      "description": "Live, fresh or chilled stromboid conchs (Strombus spp.)"
    },
    {
      "code": "03078300",
      "description": "Frozen abalone (Haliotis spp.)"
    },
    {
      "code": "03078400",
      "description": "Frozen stromboid conchs (Strombus spp.)"
    },
    {
      "code": "03078700",
      "description": "Other abalone (Haliotis spp.)"
    },
    {
      "code": "03078800",
      "description": "Other stromboid conchs (Strombus spp.)"
    },
    {
      "code": "03078900",
      "description": "OTHER"
    },
    {
      "code": "03079100",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTIONOTHER, INCLUDING FLOURS, MEALS AND PELLETS,OF AQUATIC INVERTEBRATES OTHER THANCRUSTACEANS, FIT FOR HUMAN CONSUMPTION : LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03079200",
      "description": "Frozen"
    },
    {
      "code": "03079900",
      "description": "OTHER"
    },
    {
      "code": "03079910",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION OTHER :     SEA SHELL FLESH"
    },
    {
      "code": "03079920",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION OTHER :     JELLY FISH (RHOPELINA SPP.), DRIED SALTED OR FROZEN"
    },
    {
      "code": "03079990",
      "description": "CHILLED, FROZEN, DRIED, SALTED OR IN BRINE;FLOURS, MEALS AND PELLETS OF AQUATICINVERTEBRATES OTHER THAN CRUSTACEANS,FIT FOR HUMAN CONSUMPTION OTHER :    OTHER"
    },
    {
      "code": "0308",
      "description": "AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, LIVE, FRESH, CHILLED, FROZEN, DRIED, SALTED OR IN BRINE; SMOKED AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, WHETHER OR NOT COOKED BEFORE OR DURING THE SMOKING PROCESS; FLOURS, MEALS AND PELLETS OF AQUATIC INVERTEBRATES OTHER THAN CRUSTACEANS AND MOLLUSCS, FIT FOR HUMAN CONSUMPTION. - Sea cucumbers (Stichopus japonicus, Holothurioidea) :"
    },
    {
      "code": "03081100",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03081200",
      "description": "frozen"
    },
    {
      "code": "03081900",
      "description": "OTHER"
    },
    {
      "code": "03082100",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03082200",
      "description": "frozen"
    },
    {
      "code": "03082900",
      "description": "OTHER"
    },
    {
      "code": "030830",
      "description": "Jelly fish (Rhopilema spp.)"
    },
    {
      "code": "03083010",
      "description": "LIVE, FRESH OR CHILLED"
    },
    {
      "code": "03083020",
      "description": "DRIED, SALTED OR FROZEN"
    },
    {
      "code": "03089000",
      "description": "OTHER"
    },
    {
      "code": "04",
      "description": "Dairy produce; birds’ eggs; natural honey; edible products of animal origin, not elsewhere specified or included"
    },
    {
      "code": "0401",
      "description": "MILK AND CREAM, NOT CONCENTRATED NOR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "04011000",
      "description": "MILK AND CREAM-FAT CONTENT, BY WEIGHT, <= 1%"
    },
    {
      "code": "04012000",
      "description": "MILK AND CREAM-FAT CONTENT, BY WEIGHT >1% <= 6%"
    },
    {
      "code": "040140",
      "description": "of a fat content, by weight"
    },
    {
      "code": "04014000",
      "description": "Milk and cream, not concentrated nor containing added sugar or other sweetening matter - Of a fat content, by weight, exceeding 6% but not exceeding 10%"
    },
    {
      "code": "040150",
      "description": "of a fact content"
    },
    {
      "code": "04015000",
      "description": "Milk and cream, not concentrated nor containing added sugar or other sweetening matter - Of a fat content, by weight, exceeding 10%"
    },
    {
      "code": "0402",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "040210",
      "description": "In powder, granules or other solid forms, of a fat content, by weight not exceeding 1.5%"
    },
    {
      "code": "04021010",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER SKIMMED IN POWDER, GRANULES OR OTHER SOLID FORMS, OF A FAT CONTENT, BY WEIGHT NOT EXCEEDING 1.5% : SKIMMED MILK"
    },
    {
      "code": "04021020",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER IN POWDER, GRANULES OR OTHER SOLID FORMS, OF A FAT CONTENT, BY WEIGHT NOT EXCEEDING 1.5% : MILK FOOD FOR BABIES"
    },
    {
      "code": "04021090",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER IN POWDER, GRANULES OR OTHER SOLID FORMS, OF A FAT CONTENT, BY WEIGHT NOT EXCEEDING 1.5% : OTHER"
    },
    {
      "code": "04022100",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER IN POWDER, GRANULES OR OTHER SOLID FORMS, OF A FAT CONTENT, BY WEIGHT EXCEEDING 1.5% : NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER."
    },
    {
      "code": "040229",
      "description": "Other"
    },
    {
      "code": "04022910",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER WHOLE MILK"
    },
    {
      "code": "04022920",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER MILK FOR BABIES"
    },
    {
      "code": "04022990",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OTHER"
    },
    {
      "code": "040291",
      "description": "Not containing added sugar or other sweetening matter"
    },
    {
      "code": "04029110",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   OTHER : NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : CONDENSED MILK"
    },
    {
      "code": "04029190",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   OTHER : NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : OTHER"
    },
    {
      "code": "040299",
      "description": "Other"
    },
    {
      "code": "04029910",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   OTHER : OTHER : WHOLE MILK"
    },
    {
      "code": "04029920",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   OTHER : OTHER : CONDENSED MILK"
    },
    {
      "code": "04029990",
      "description": "MILK AND CREAM, CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   OTHER : OTHER : OTHER"
    },
    {
      "code": "0403",
      "description": "BUTTER MILK, CURDLED MILK AND CREAM, YOGURT, KEPHIR AND OTHER FERMENTED OR ACIDIFIED MILK AND CREAM, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR FLAVOURED OR CONTAINING ADDED FRUIT, NUTS OR COCOA"
    },
    {
      "code": "04031000",
      "description": "BUTTERMILK, CURDLED MILK AND CREAM, YOGURT, KEPHIR AND OTHER FERMENTED OR ACIDIFIED MILK AND CREAM, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR FLAVOURED OR CONTAINING ADDED FRUIT, NUTS OR COCOA YOGURT"
    },
    {
      "code": "040390",
      "description": "Other"
    },
    {
      "code": "04039010",
      "description": "BUTTERMILK, CURDLED MILK AND CREAM, YOGURT, KEPHIR AND OTHER FERMENTED OR ACIDIFIED MILK AND CREAM, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR FLAVOURED OR CONTAINING ADDED FRUIT, NUTS OR COCOA OTHER BUTTER MILK"
    },
    {
      "code": "04039090",
      "description": "BUTTERMILK, CURDLED MILK AND CREAM, YOGURT, KEPHIR AND OTHER FERMENTED OR ACIDIFIED MILK AND CREAM, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR FLAVOURED OR CONTAINING ADDED FRUIT, NUTS OR COCOA OTHER  OTHER"
    },
    {
      "code": "0404",
      "description": "WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER; PRODUCTS CONSISTING OF NATURAL MILK CONSTITUENTS, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "040410",
      "description": "Whey and modified whey, whether or not concentrated or containing added sugar or other sweetening matter"
    },
    {
      "code": "04041010",
      "description": "WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER; PRODUCTS CONSISTING OF NATURAL MILK CONSTITUENTS, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER, NOT ELSEWHERE SPECIFIED OR INCLUDED WHEY AND MODIFIED WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : WHEY, CONCENTRATED, EVAPORATED OR CONDENSED, LIQUID OR SEMI?SOLID"
    },
    {
      "code": "04041020",
      "description": "WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER; PRODUCTS CONSISTING OF NATURAL MILK CONSTITUENTS, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER, NOT ELSEWHERE SPECIFIED OR INCLUDED WHEY AND MODIFIED WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : WHEY, DRY, BLOCKS AND POWDERED"
    },
    {
      "code": "04041090",
      "description": "WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER; PRODUCTS CONSISTING OF NATURAL MILK CONSTITUENTS, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER, NOT ELSEWHERE SPECIFIED OR INCLUDED WHEY AND MODIFIED WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : OTHER"
    },
    {
      "code": "04049000",
      "description": "WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER; PRODUCTS CONSISTING OF NATURAL MILK CONSTITUENTS, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER, NOT ELSEWHERE SPECIFIED OR INCLUDED WHEY AND MODIFIED WHEY, WHETHER OR NOT CONCENTRATED OR CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER : OTHER"
    },
    {
      "code": "0405",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS"
    },
    {
      "code": "04051000",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS BUTTER"
    },
    {
      "code": "04052000",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS DAIRY SPREADS"
    },
    {
      "code": "040590",
      "description": "Other"
    },
    {
      "code": "04059010",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS OTHER : BUTTER OIL"
    },
    {
      "code": "04059020",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS OTHER GHEE"
    },
    {
      "code": "04059090",
      "description": "BUTTER AND OTHER FATS AND OILS DERIVED FROM MILK; DAIRY SPREADS OTHER OTHER"
    },
    {
      "code": "0406",
      "description": "CHEESE AND CURD"
    },
    {
      "code": "04061000",
      "description": "CHEESE AND CURD FRESH (UNRIPENED OR UNCURED) CHEESE, INCLUDING WHEY CHEESE AND CURD"
    },
    {
      "code": "04062000",
      "description": "CHEESE AND CURD GRATED OR POWDERED CHEESE, OF ALL KINDS"
    },
    {
      "code": "04063000",
      "description": "CHEESE AND CURD PROCESSED CHEESE NOT GRATED OR POWDERED"
    },
    {
      "code": "04064000",
      "description": "CHEESE AND CURD BLUE-VEINED CHEESE AND OTHER CHEESE CONTAINING VEINS PRODUCED BY PENICILLIUM ROQUEFORTI"
    },
    {
      "code": "04069000",
      "description": "CHEESE AND CURD  OTHER CHEESE"
    },
    {
      "code": "0407",
      "description": "BIRDS’ EGGS, IN SHELL, FRESH, PRESERVED OR COOKED"
    },
    {
      "code": "040700",
      "description": "Birds eggs, in shell, fresh, preserved or cooked"
    },
    {
      "code": "040711",
      "description": "of fowls of the species"
    },
    {
      "code": "04071100",
      "description": "OF FOWLS OF THE SPECIES GALLUS DOMESTICUS"
    },
    {
      "code": "040719",
      "description": "Other"
    },
    {
      "code": "04071910",
      "description": "OF DUCKS"
    },
    {
      "code": "04071990",
      "description": "OTHER OTHER FRESH EGGS :"
    },
    {
      "code": "040721",
      "description": "Other fresh eggs"
    },
    {
      "code": "04072100",
      "description": "OF FOWLS OF THE SPECIES GALLUS DOMESTICUS"
    },
    {
      "code": "040729",
      "description": "other"
    },
    {
      "code": "04072900",
      "description": "OTHER"
    },
    {
      "code": "040790",
      "description": "other"
    },
    {
      "code": "04079000",
      "description": "Other"
    },
    {
      "code": "0408",
      "description": "BIRDS’ EGGS, NOT IN SHELL, AND EGG YOLKS, FRESH, DRIED, COOKED BY STEAMING OR BY BOILING IN WATER, MOULDED, FROZEN OR OTHERWISE PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER - Egg yolks :"
    },
    {
      "code": "04081100",
      "description": "BIRDS EGGS, NOT IN SHELL, AND EGG YOLKS, FRESH, DRIED, COOKED BY STEAMING OR BY BOILING IN WATER, MOULDED, FROZEN OR OTHERWISE PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER   EGG YOLKS : DRIED"
    },
    {
      "code": "04081900",
      "description": "BIRDS EGGS, NOT IN SHELL, AND EGG YOLKS, FRESH, DRIED, COOKED BY STEAMING OR BY BOILING IN WATER, MOULDED, FROZEN OR OTHERWISE PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER EGG YOLKS : OTHER"
    },
    {
      "code": "04089100",
      "description": "BIRDS EGGS, NOT IN SHELL, AND EGG YOLKS, FRESH, DRIED, COOKED BY STEAMING OR BY BOILING IN WATER, MOULDED, FROZEN OR OTHERWISE PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OTHER : DRIED"
    },
    {
      "code": "04089900",
      "description": "BIRDS EGGS, NOT IN SHELL, AND EGG YOLKS, FRESH, DRIED, COOKED BY STEAMING OR BY BOILING IN WATER, MOULDED, FROZEN OR OTHERWISE PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OTHER : OTHER"
    },
    {
      "code": "0409",
      "description": "NATURAL HONEY"
    },
    {
      "code": "04090000",
      "description": "NATURAL HONEY"
    },
    {
      "code": "0410",
      "description": "EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "041000",
      "description": "Edible products of animal origin, not"
    },
    {
      "code": "04100010",
      "description": "EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED : OF WILD ANIMALS"
    },
    {
      "code": "04100020",
      "description": "EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED : OF WILD ANIMALS TURTLE EGGS AND SALANGANES NESTS (BIRDS NESTS)"
    },
    {
      "code": "04100090",
      "description": "EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED EDIBLE PRODUCTS OF ANIMAL ORIGIN, NOT ELSEWHERE SPECIFIED OR INCLUDED : OTHER"
    },
    {
      "code": "05",
      "description": "Products of animal origin, not elsewhere specified or included"
    },
    {
      "code": "0501",
      "description": "HUMAN HAIR, UNWORKED, WHETHER OR NOT WASHED OR SCOURED; WASTE OF HUMAN HAIR"
    },
    {
      "code": "050100",
      "description": "Human hair, unworked, whether or not washed or scoured; Waste of human hair"
    },
    {
      "code": "05010010",
      "description": "HUMAN HAIR, UNWORKED, WHETHER OR - NOT WASHED OR SCOURED; WASTE OF - HUMAN HAIR - HUMAN HAIR, UNWORKED, WHETHER OR NOT WASHED OR SCOURED; WASTE OF HUMAN HAIR : - HUMAN HAIR, UNWORKED,WHETHER OR NOT WASHED OR SCOURED"
    },
    {
      "code": "05010020",
      "description": "HUMAN HAIR, UNWORKED, WHETHER OR - NOT WASHED OR SCOURED; WASTE OF - HUMAN HAIR - HUMAN HAIR, UNWORKED, WHETHER OR NOT WASHED OR SCOURED; WASTE OF HUMAN HAIR : - WASTE OF HUMAN HAIR"
    },
    {
      "code": "0502",
      "description": "PIGS’, HOGS’ OR BOARS’ BRISTLES AND HAIR; BADGER HAIR AND OTHER BRUSH MAKING HAIR; WASTE OF SUCH BRISTLES OR HAIR"
    },
    {
      "code": "050210",
      "description": "Pigs, hogs or boars bristles and hair and waste thereof"
    },
    {
      "code": "05021010",
      "description": "PIGS, HOGSOR BOARSBRISTLES AND HAIR;BADGER HAIR AND OTHER BRUSH MAKINGHAIR; WASTE OF SUCH BRISTLES OR HAIR - PIGS, HOGS OR BOARS BRISTLES AND HAIR AND - WASTE THEREOF : - PIGS, HOGS OR BOARS BRISTLES AND HAIR"
    },
    {
      "code": "05021020",
      "description": "PIGS, HOGS OR BOARS BRISTLES AND HAIR;BADGER HAIR ANDOTHER BRUSH MAKING - HAIR; WASTE OF SUCH BRISTLES OR HAIR - PIGS, HOGS OR BOARS BRISTLES AND HAIR AND WASTE THEREOF : - WASTE OF PIGS, HOGS OR BOARS BRISTLES AND HAIR"
    },
    {
      "code": "050290",
      "description": "Other"
    },
    {
      "code": "05029010",
      "description": "PIGS, HOGS OR BOARS BRISTLES AND HAIR; - BADGER HAIR AND OTHER BRUSH MAKING - HAIR; WASTE OF SUCH BRISTLES OR HAIR - OTHER : - BADGER HAIR AND OTHER BRUSH MAKING HAIR"
    },
    {
      "code": "05029020",
      "description": "PIGS, HOGS OR BOARS BRISTLES AND HAIR; - BADGER HAIR AND OTHER BRUSH MAKING - HAIR; WASTE OF SUCH BRISTLES OR HAIR - OTHER : - YAK TAIL HAIR"
    },
    {
      "code": "05029090",
      "description": "PIGS, HOGS OR BOARS BRISTLES AND HAIR; - BADGER HAIR AND OTHER BRUSH MAKING - HAIR; WASTE OF SUCH BRISTLES OR HAIR - OTHER : - OTHER"
    },
    {
      "code": "0503",
      "description": "-"
    },
    {
      "code": "05030000",
      "description": "HORSEHAIR AND HORSEHAIR WASTE, WHETHER OR NOT PUT UP AS A LAYER WITH OR WITHOUT SUPPORTING MATERIAL"
    },
    {
      "code": "0504",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS (OTHER THAN FISH), WHOLE AND PIECES THEREOF, FRESH, CHILLED, FROZEN, SALTED, IN BRINE, DRIED OR SMOKED"
    },
    {
      "code": "050400",
      "description": "Guts, bladders and stomachs of animals (other than fish), whole and pieces thereof, fresh, chilled, frozen, salted, in brine, dried or smoked"
    },
    {
      "code": "05040010",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : - GUTS OF CATTLE FOR NATURAL FOOD CASINGS"
    },
    {
      "code": "05040020",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : - GUTS OF SHEEP AND GOATS FOR NATURAL FOOD CASINGS"
    },
    {
      "code": "05040031",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : GUTS OF OTHER ANIMALS FOR NATURAL - FOOD CASINGS : - OF WILD ANIMALS"
    },
    {
      "code": "05040039",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : GUTS OF OTHER ANIMALS FOR NATURAL - FOOD CASINGS : - OTHER"
    },
    {
      "code": "05040041",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : - GUTS OTHER THAN FOR NATURAL FOOD CASTINGS : - OF WILD ANIMALS"
    },
    {
      "code": "05040049",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : GUTS OTHER THAN FOR NATURAL FOOD CASTINGS : - OTHER"
    },
    {
      "code": "05040051",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : BLADDERS AND STOMACHS : - OF WILD ANIMALS"
    },
    {
      "code": "05040059",
      "description": "GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED - GUTS, BLADDERS AND STOMACHS OF ANIMALS - (OTHER THAN FISH), WHOLE AND PIECES - THEREOF, FRESH, CHILLED, FROZEN, SALTED, - IN BRINE, DRIED OR SMOKED : BLADDERS AND STOMACHS : - OTHER"
    },
    {
      "code": "0505",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR FEATHERS OR DOWN, FEATHERS AND PARTS OF FEATHERS (WHETHER OR NOT WITH TRIMMED EDGES) AND DOWN, NOT FURTHER WORKED THAN CLEANED, DISINFECTED OR TREATED FOR PRESERVATION; POWDER AND WASTE OF FEATHERS OR PARTS OF FEATHERS"
    },
    {
      "code": "050510",
      "description": "Feathers of a kind used for stuffing; down"
    },
    {
      "code": "05051010",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - FEATHERS OF A KIND USED FOR STUFFING; DOWN : - OF WILD BIRDS"
    },
    {
      "code": "05051090",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - FEATHERS OF A KIND USED FOR STUFFING; DOWN : - OTHER"
    },
    {
      "code": "050590",
      "description": "Other"
    },
    {
      "code": "05059010",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - PEACOCK TAIL AND WING FEATHER (TRIMMED OR NOT)"
    },
    {
      "code": "05059021",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - OTHER FEATHER (EXCLUDING FOR STUFFING - PURPOSE) : - OF WILD BIRDS"
    },
    {
      "code": "05059029",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - OTHER FEATHER (EXCLUDING FOR STUFFING - PURPOSE) : - OTHER"
    },
    {
      "code": "05059031",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - POWDER AND WASTE OF FEATHERS OR PARTS OF FEATHERS : - OF WILD BIRDS"
    },
    {
      "code": "05059039",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - POWDER AND WASTE OF FEATHERS OR PARTS OF - FEATHERS : - OTHER"
    },
    {
      "code": "05059091",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - SKINS AND OTHER PARTS : OF WILD BIRDS"
    },
    {
      "code": "05059099",
      "description": "SKINS AND OTHER PARTS OF BIRDS, WITH THEIR - FEATHERS OR DOWN, FEATHERS AND PARTS OF - FEATHERS (WHETHER OR NOT WITH TRIMMED - EDGES) AND DOWN, NOT FURTHER WORKED THAN - CLEANED, DISINFECTED OR TREATED FOR - PRESERVATION; POWDER AND WASTE OF - FEATHERS OR PARTS OF FEATHERS - OTHER : - SKINS AND OTHER PARTS : OTHER"
    },
    {
      "code": "0506",
      "description": "BONES AND HORN-CORES, UNWORKED, DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO SHAPE), TREATED WITH ACID OR DEGELATINISED POWDER AND WASTE OF THESE PRODUCTS"
    },
    {
      "code": "050610",
      "description": "Ossein and bones treated with acid"
    },
    {
      "code": "05061011",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : BONES, INCLUDING HORN-CORES, CRUSHED : - - OF WILD ANIMALS"
    },
    {
      "code": "05061019",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - - BONES, INCLUDING HORN-CORES, CRUSHED : - OTHER"
    },
    {
      "code": "05061021",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - BONE GRIST : OF WILD ANIMALS"
    },
    {
      "code": "05061029",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - BONE GRIST : OTHER"
    },
    {
      "code": "05061031",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - OSSEIN - OF WILD ANIMALS"
    },
    {
      "code": "05061039",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - OSSEIN - OTHER"
    },
    {
      "code": "05061041",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - BONES, HORN-CONES AND PARTS THEREOF, NOT - CRUSHED : - OF WILD ANIMALS"
    },
    {
      "code": "05061049",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OSSEIN AND BONES TREATED WITH ACID : - BONES, HORN-CONES AND PARTS THEREOF, NOT - CRUSHED : - OTHER"
    },
    {
      "code": "050690",
      "description": "Other"
    },
    {
      "code": "05069011",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OTHER : BONE MEAL : - OF WILD ANIMALS"
    },
    {
      "code": "05069019",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OTHER - BONE MEAL : - : OTHER"
    },
    {
      "code": "05069091",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OTHER : OTHER : OF WILD ANIMALS"
    },
    {
      "code": "05069099",
      "description": "BONES AND HORN-CORES, UNWORKED, - DEFATTED, SIMPLY PREPARED (BUT NOT CUT TO - SHAPE), TREATED WITH ACID OR DEGELATINISED - POWDER AND WASTE OF THESE PRODUCTS - OTHER : OTHER : OTHER"
    },
    {
      "code": "0507",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY PREPARED BUT NOT CUT TO SHAPE; POWDER AND WASTE OF THESE PRODUCTS"
    },
    {
      "code": "050710",
      "description": "Ivory; ivory powder and waste"
    },
    {
      "code": "05071010",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - IVORY; IVORY POWDER AND WASTE : - IVORY"
    },
    {
      "code": "05071020",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - IVORY; IVORY POWDER AND WASTE : - IVORY POWDER AND WASTE"
    },
    {
      "code": "050790",
      "description": "Other"
    },
    {
      "code": "05079010",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : HOOF MEAL"
    },
    {
      "code": "05079020",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : HORN MEAL"
    },
    {
      "code": "05079030",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : HOOVES, CLAWS, NAILS AND BEAKS"
    },
    {
      "code": "05079040",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : ANTLERS"
    },
    {
      "code": "05079050",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : BUFFALO HORNS"
    },
    {
      "code": "05079060",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : TORTOISE- SHELL"
    },
    {
      "code": "05079070",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : CLAWS AND WASTE OF TORTOISE SHELL"
    },
    {
      "code": "05079090",
      "description": "IVORY, TORTOISE-SHELL, WHALEBONE AND - WHALEBONE HAIR, HORNS, ANTLERS, HOOVES, - NAILS, CLAWS AND BEAKS, UNWORKED OR SIMPLY - PREPARED BUT NOT CUT TO SHAPE; POWDER AND - WASTE OF THESE PRODUCTS - OTHER : OTHER"
    },
    {
      "code": "0508",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED OR SIMPLY PREPARED BUT NOT OTHERWISE WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS OR ECHINODERMS AND CUTTLE-BONE, UNWORKED OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, POWDER AND WASTE THEREOF"
    },
    {
      "code": "050800",
      "description": "Coral and similar materials, unworked or simply prepared but not otherwise worked; shells of molluscs, crustaceans or echinoderms and cuttle-bone, unworked or simply prepared but not cut to shape, powder and waste thereof"
    },
    {
      "code": "05080010",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - CORAL"
    },
    {
      "code": "05080020",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - CHANKS"
    },
    {
      "code": "05080030",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - COWRIES"
    },
    {
      "code": "05080040",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - CUTTLEFISH BONES"
    },
    {
      "code": "05080050",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - SHELLS"
    },
    {
      "code": "05080090",
      "description": "CORAL AND SIMILAR MATERIALS, UNWORKED - OR SIMPLY PREPARED BUT NOT OTHERWISE - WORKED; SHELLS OF MOLLUSCS, CRUSTACEANS - OR ECHINODERMS AND CUTTLE-BONE, - UNWORKED OR SIMPLY PREPARED BUT NOT - CUT TO SHAPE, POWDER AND WASTE THEREOF - CORAL AND SIMILAR MATERIALS, UNWORKED OR - SIMPLY PREPARED BUT NOT OTHERWISE WORKED; - SHELLS OF MOLLUSCS, CRUSTACEANS OR - ECHINODERMS AND CUTTLE-BONE, UNWORKED - OR SIMPLY PREPARED BUT NOT CUT TO SHAPE, - POWDER AND WASTE THEREOF : - OTHER"
    },
    {
      "code": "0509",
      "description": "Omitted"
    },
    {
      "code": "05090010",
      "description": "NATURAL SPONGES OF ANIMAL ORIGIN - NATURAL SPONGES OF ANIMAL ORIGIN : - OF WILD LIFE"
    },
    {
      "code": "05090090",
      "description": "NATURAL SPONGES OF ANIMAL ORIGIN - NATURAL SPONGES OF ANIMAL ORIGIN : - OTHER"
    },
    {
      "code": "0510",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; CANTHARIDES; BILE, WHETHER OR NOT DRIED; GLANDS AND OTHER ANIMAL PRODUCTS USED IN THE PREPARATION OF PHARMACEUTICAL PRODUCTS, FRESH, CHILLED, FROZEN OR OTHERWISE PROVISIONALLY PRESERVED"
    },
    {
      "code": "051000",
      "description": "Ambergris, castoreum, civet and musk; cantharides; bile, whether or not dried; glands and other animal products used in the preparation of pharmaceutical products, fresh, chilled, frozen or otherwise provisionally preserved"
    },
    {
      "code": "05100010",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED - AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED : - BEZOAR, COW (GOOLOCHAN)"
    },
    {
      "code": "05100020",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED - AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED : - OX GALLSTONE"
    },
    {
      "code": "05100030",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED - AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED : - PLACENTA, FROZEN"
    },
    {
      "code": "05100091",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED - AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED : - OTHER : OF WILD ANIMALS"
    },
    {
      "code": "05100099",
      "description": "AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED - AMBERGRIS, CASTOREUM, CIVET AND MUSK; - CANTHARIDES; BILE, WHETHER OR NOT DRIED; - GLANDS AND OTHER ANIMAL PRODUCTS USED - IN THE PREPARATION OF PHARMACEUTICAL - PRODUCTS, FRESH, CHILLED, FROZEN OR - OTHERWISE PROVISIONALLY PRESERVED : - OTHER : OTHER"
    },
    {
      "code": "0511",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, UNFIT FOR HUMAN CONSUMPTION"
    },
    {
      "code": "05111000",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - BOVINE SEMEN"
    },
    {
      "code": "051191",
      "description": "Products of fish or crustaceans, molluscs or other aquatic invertebrates; dead animals of Chapter 3"
    },
    {
      "code": "05119110",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : - PRODUCTS OF FISH OR CRUSTACEANS, MOLLUSCS - OR OTHER AQUATIC INVERTEBRATES; DEAD ANIMALS - OF CHAPTER 3 : - FISH NAILS"
    },
    {
      "code": "05119120",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : - PRODUCTS OF FISH OR CRUSTACEANS, MOLLUSCS - OR OTHER AQUATIC INVERTEBRATES; DEAD ANIMALS - OF CHAPTER 3 : - FISH TAILS"
    },
    {
      "code": "05119130",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : - PRODUCTS OF FISH OR CRUSTACEANS, MOLLUSCS - OR OTHER AQUATIC INVERTEBRATES; DEAD ANIMALS - OF CHAPTER 3 : - OTHER FISH WASTE"
    },
    {
      "code": "05119190",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : - PRODUCTS OF FISH OR CRUSTACEANS, MOLLUSCS - OR OTHER AQUATIC INVERTEBRATES; DEAD ANIMALS - OF CHAPTER 3 : - OTHER"
    },
    {
      "code": "051199",
      "description": "Other"
    },
    {
      "code": "05119911",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - SILKWORM PUPAE : - ARTEMIA"
    },
    {
      "code": "05119919",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - SILKWORM PUPAE : - OTHER"
    },
    {
      "code": "05119921",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - SINEWS AND TENDONS : - OF WILD LIFE"
    },
    {
      "code": "05119929",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - SINEWS AND TENDONS : - OTHER"
    },
    {
      "code": "05119991",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - OTHER : - FROZEN SEMEN, OTHER THAN BOVINE; BOVINE EMBRYO"
    },
    {
      "code": "05119992",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - OTHER : - OF WILD LIFE"
    },
    {
      "code": "05119999",
      "description": "ANIMAL PRODUCTS NOT ELSEWHERE SPECIFIED - OR INCLUDED; DEAD ANIMALS OF CHAPTER 1 OR 3, - UNFIT FOR HUMAN CONSUMPTION - OTHER : OTHER : - OTHER : - OTHER"
    },
    {
      "code": "06",
      "description": "Vegetable Products"
    },
    {
      "code": "0601",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212"
    },
    {
      "code": "06011000",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212 BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT"
    },
    {
      "code": "060120",
      "description": "Bulbs, tubers, tuberous roots, corms, crowns and rhizomes, in growth or in flower; chicory plants and roots"
    },
    {
      "code": "06012010",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212 BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS : BULBS, HORTICULTURAL"
    },
    {
      "code": "06012021",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212 BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS : CHICORY PLANTS AND ROOTS : PLANTS"
    },
    {
      "code": "06012022",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212 BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS : CHICORY PLANTS AND ROOTS : ROOTS"
    },
    {
      "code": "06012090",
      "description": "BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, DORMANT, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS OTHER THAN ROOTS OF HEADING 1212 BULBS, TUBERS, TUBEROUS ROOTS, CORMS, CROWNS AND RHIZOMES, IN GROWTH OR IN FLOWER; CHICORY PLANTS AND ROOTS : OTHER"
    },
    {
      "code": "0602",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN"
    },
    {
      "code": "06021000",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN UNROOTED CUTTINGS AND SLIPS"
    },
    {
      "code": "060220",
      "description": "Trees, shrubs and bushes, grafted or not, of kinds, which bear edible fruits or nuts"
    },
    {
      "code": "06022010",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN TREES, SHRUBS AND BUSHES, GRAFTED OR NOT, OF KINDS, WHICH BEAR EDIBLE FRUITS OR NUTS : EDIBLE FRUIT OR NUT TREES, GRAFTED OR NOT"
    },
    {
      "code": "06022020",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN TREES, SHRUBS AND BUSHES, GRAFTED OR NOT, OF KINDS, WHICH BEAR EDIBLE FRUITS OR NUTS : CACTUS"
    },
    {
      "code": "06022090",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN TREES, SHRUBS AND BUSHES, GRAFTED OR NOT, OF KINDS, WHICH BEAR EDIBLE FRUITS OR NUTS : OTHER"
    },
    {
      "code": "06023000",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN RHODODENDRONS AND AZALEAS, GRAFTED OR NOT"
    },
    {
      "code": "06024000",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN ROSES, GRAFTED OR NOT"
    },
    {
      "code": "060290",
      "description": "Other"
    },
    {
      "code": "06029010",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN OTHER : MUSHROOM SPAWN"
    },
    {
      "code": "06029020",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN OTHER : FLOWERING PLANTS (EXCLUDING ROSES AND RHODODENDRONS)"
    },
    {
      "code": "06029030",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN OTHER : TISSUE CULTURE PLANT"
    },
    {
      "code": "06029090",
      "description": "OTHER LIVE PLANTS (INCLUDING THEIR ROOTS), CUTTINGS AND SLIPS; MUSHROOM SPAWN OTHER : OTHER"
    },
    {
      "code": "0603",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED - Fresh:"
    },
    {
      "code": "06031000",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED FRESH"
    },
    {
      "code": "06031100",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED -FRESH: -- ROSES"
    },
    {
      "code": "06031200",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED -FRESH: -- CARNATIONS"
    },
    {
      "code": "06031300",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED -FRESH: -- CHRYSANTHEMUMS"
    },
    {
      "code": "06031400",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED -FRESH: -- CHRYSANTHEMUMS"
    },
    {
      "code": "060315",
      "description": "Lilies"
    },
    {
      "code": "06031500",
      "description": "Lilies (Lilium spp.)"
    },
    {
      "code": "06031900",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED -FRESH: -- OTHER"
    },
    {
      "code": "06039000",
      "description": "CUT FLOWERS AND FLOWER BUDS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED OTHER"
    },
    {
      "code": "0604",
      "description": "FOLIAGE, BRANCHES AND OTHER PARTS OF PLANTS, WITHOUT FLOWERS OR FLOWER BUDS, AND GRASSES, MOSSES AND LICHENS, BEING GOODS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED"
    },
    {
      "code": "06041000",
      "description": "FOLIAGE, BRANCHES AND OTHER PARTS OF PLANTS, WITHOUT FLOWERS OR FLOWER BUDS, AND GRASSES, MOSSES AND LICHENS, BEING GOODS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED MOSSES AND LICHENS"
    },
    {
      "code": "060420",
      "description": "fresh"
    },
    {
      "code": "06042000",
      "description": "Fresh"
    },
    {
      "code": "060490",
      "description": "Other"
    },
    {
      "code": "06049000",
      "description": "Other"
    },
    {
      "code": "06049100",
      "description": "FOLIAGE, BRANCHES AND OTHER PARTS OF PLANTS, WITHOUT FLOWERS OR FLOWER BUDS, AND GRASSES, MOSSES AND LICHENS, BEING GOODS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED OTHER : FRESH"
    },
    {
      "code": "06049900",
      "description": "FOLIAGE, BRANCHES AND OTHER PARTS OF PLANTS, WITHOUT FLOWERS OR FLOWER BUDS, AND GRASSES, MOSSES AND LICHENS, BEING GOODS OF A KIND SUITABLE FOR BOUQUETS OR FOR ORNAMENTAL PURPOSES, FRESH, DRIED, DYED, BLEACHED, IMPREGNATED OR OTHERWISE PREPARED OTHER : OTHER"
    },
    {
      "code": "07",
      "description": "Edible vegetables and certain roots and tubers"
    },
    {
      "code": "0701",
      "description": "POTATOES, FRESH OR CHILLED"
    },
    {
      "code": "07011000",
      "description": "POTATOES, FRESH OR CHILLED SEED"
    },
    {
      "code": "07019000",
      "description": "POTATOES, FRESH OR CHILLED OTHER"
    },
    {
      "code": "0702",
      "description": "TOMATOES, FRESH OR CHILLED"
    },
    {
      "code": "07020000",
      "description": "TOMATOES, FRESH OR CHILLED"
    },
    {
      "code": "0703",
      "description": "ONIONS, SHALLOTS, GARLIC, LEEKS AND OTHER ALLIACEOUS VEGETABLES, FRESH OR CHILLED"
    },
    {
      "code": "070310",
      "description": "Onions and shallots"
    },
    {
      "code": "07031010",
      "description": "ONIONS, SHALLOTS, GARLIC, LEEKS AND OTHERALLIACEOUS VEGETABLES, FRESH OR CHILLED ONIONS AND SHALLOTS ONIONS"
    },
    {
      "code": "07031020",
      "description": "ONIONS, SHALLOTS, GARLIC, LEEKS AND OTHERALLIACEOUS VEGETABLES, FRESH OR CHILLED ONIONS AND SHALLOTS SHALLOTS"
    },
    {
      "code": "07031090",
      "description": "Other"
    },
    {
      "code": "07032000",
      "description": "ONIONS, SHALLOTS, GARLIC, LEEKS AND OTHERALLIACEOUS VEGETABLES, FRESH OR CHILLED GARLIC"
    },
    {
      "code": "07039000",
      "description": "ONIONS, SHALLOTS, GARLIC, LEEKS AND OTHERALLIACEOUS VEGETABLES, FRESH OR CHILLED LEEKS AND OTHER ALLIACEOUS VEGETABLES"
    },
    {
      "code": "0704",
      "description": "CABBAGES, CAULIFLOWERS, KOHLRABI, KALE AND SIMILAR EDIBLE BRASSICAS, FRESH OR CHILLED"
    },
    {
      "code": "07041000",
      "description": "CABBAGES, CAULIFLOWERS, KOHLRABI, KALEAND SIMILAR EDIBLE BRASSICAS, FRESH ORCHILLED CAULIFLOWERS AND HEADED BROCCOLI"
    },
    {
      "code": "07042000",
      "description": "CABBAGES, CAULIFLOWERS, KOHLRABI, KALEAND SIMILAR EDIBLE BRASSICAS, FRESH ORCHILLED BRUSSELS SPROUTS"
    },
    {
      "code": "07049000",
      "description": "CABBAGES, CAULIFLOWERS, KOHLRABI, KALEAND SIMILAR EDIBLE BRASSICAS, FRESH ORCHILLED OTHER"
    },
    {
      "code": "0705",
      "description": "LETTUCE (LACTUCASATIVA) AND CHICORY (CICHORIUM SPP.), FRESH OR CHILLED"
    },
    {
      "code": "07051100",
      "description": "LETTUCE (LACTUCASATIVA) AND CHICORY(CICHORIUM SPP. ), FRESH OR CHILLED LETTUCE : CABBAGE LETTUCE (HEAD LETTUCE)"
    },
    {
      "code": "07051900",
      "description": "LETTUCE (LACTUCASATIVA) AND CHICORY(CICHORIUM SPP. ), FRESH OR CHILLED LETTUCE : OTHER"
    },
    {
      "code": "07052100",
      "description": "LETTUCE (LACTUCASATIVA) AND CHICORY(CICHORIUM SPP. ), FRESH OR CHILLED CHICORY WITLOOF CHICORY (CICHORIUM INTYBUS VAR. FOLIOSUM)"
    },
    {
      "code": "07052900",
      "description": "LETTUCE (LACTUCASATIVA) AND CHICORY(CICHORIUM SPP. ), FRESH OR CHILLEDCHICORY OTHER"
    },
    {
      "code": "0706",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY, CELERIAC, RADISHES AND SIMILAR EDIBLE ROOTS, FRESH OR CHILLED"
    },
    {
      "code": "07061000",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY,CELERIAC, RADISHES AND SIMILAR EDIBLEROOTS, FRESH OR CHILLEDCARROTS AND TURNIPS"
    },
    {
      "code": "070690",
      "description": "Other"
    },
    {
      "code": "07069010",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY,CELERIAC, RADISHES AND SIMILAR EDIBLEROOTS, FRESH OR CHILLED OTHER : HORSE RADISH"
    },
    {
      "code": "07069020",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY,CELERIAC, RADISHES AND SIMILAR EDIBLEROOTS, FRESH OR CHILLED OTHER OTHER RADISH"
    },
    {
      "code": "07069030",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY,CELERIAC, RADISHES AND SIMILAR EDIBLEROOTS, FRESH OR CHILLED OTHER SALAD BEETROOT"
    },
    {
      "code": "07069090",
      "description": "CARROTS, TURNIPS, SALAD BEETROOT, SALSIFY,CELERIAC, RADISHES AND SIMILAR EDIBLEROOTS, FRESH OR CHILLED OTHER OTHER"
    },
    {
      "code": "0707",
      "description": "CUCUMBERS OR GHERKINS, FRESH OR CHILLED"
    },
    {
      "code": "07070000",
      "description": "CUCUMBERS OR GHERKINS, FRESH OR CHILLED"
    },
    {
      "code": "0708",
      "description": "LEGUMINOUS VEGETABLES, SHELLED OR UNSHELLED, FRESH OR CHILLED"
    },
    {
      "code": "07081000",
      "description": "LEGUMINOUS VEGETABLES, SHELLED ORUNSHELLED, FRESH OR CHILLED PEAS (PISUM SATIVUM)"
    },
    {
      "code": "07082000",
      "description": "LEGUMINOUS VEGETABLES, SHELLED ORUNSHELLED, FRESH OR CHILLED BEANS (VIGNA SPP., PHASEOLUS SPP.)"
    },
    {
      "code": "07089000",
      "description": "LEGUMINOUS VEGETABLES, SHELLED ORUNSHELLED, FRESH OR CHILLED OTHER LEGUMINOUS VEGETABLES"
    },
    {
      "code": "0709",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED"
    },
    {
      "code": "07091000",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED GLOBE ARTICHOKES"
    },
    {
      "code": "07092000",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED ASPARAGUS"
    },
    {
      "code": "07093000",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED AUBERGINES (EGG PLANTS)"
    },
    {
      "code": "07094000",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED CELERY OTHER THAN CELERIAC"
    },
    {
      "code": "07095100",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED MUSHROOMS AND TRUFFLES MUSHROOMS OF THE GENUS AGARICUS"
    },
    {
      "code": "07095200",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED MUSHROOMS AND TRUFFLES TRUFFLES"
    },
    {
      "code": "07095900",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED MUSHROOMS AND TRUFFLES OTHER"
    },
    {
      "code": "070960",
      "description": "Fruits of the genus Capsicum or of the genus pimenta"
    },
    {
      "code": "07096010",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED FRUITS OF THE GENUS CAPSICUM OR OF THEGENUS PIMENTA : GREEN CHILLY"
    },
    {
      "code": "07096090",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED FRUITS OF THE GENUS CAPSICUM OR OF THEGENUS PIMENTA : OTHER"
    },
    {
      "code": "07097000",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED SPINACH, NEW ZEALAND SPINACH AND ORACHE SPINACH (GARDEN SPINACH)"
    },
    {
      "code": "070990",
      "description": "Other"
    },
    {
      "code": "07099090",
      "description": "OTHER VEGETABLES, FRESH OR CHILLED OTHER : OTHER"
    },
    {
      "code": "070991",
      "description": "other testing"
    },
    {
      "code": "07099100",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --GLOBE ARTICHOKES"
    },
    {
      "code": "070992",
      "description": "other"
    },
    {
      "code": "07099200",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --OLIVES"
    },
    {
      "code": "070993",
      "description": "Pumpkins, squash and guards (Cucurbita spp.)"
    },
    {
      "code": "07099300",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --PUMPKINS,SQUASH AND GOURDS (CUCURBITA SPP.)"
    },
    {
      "code": "07099310",
      "description": "pumpkins"
    },
    {
      "code": "07099320",
      "description": "squash"
    },
    {
      "code": "07099330",
      "description": "Bitter guard"
    },
    {
      "code": "07099340",
      "description": "Bottle guard"
    },
    {
      "code": "07099350",
      "description": "Snake guard"
    },
    {
      "code": "07099360",
      "description": "Coccinia (Kundru)"
    },
    {
      "code": "07099390",
      "description": "Other"
    },
    {
      "code": "070999",
      "description": "Other"
    },
    {
      "code": "07099910",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --OTHER---GREEN PEPPER"
    },
    {
      "code": "07099920",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --OTHER---MIXED VEGETABLES"
    },
    {
      "code": "07099930",
      "description": "Okra/Lady finger (Bhindi)"
    },
    {
      "code": "07099990",
      "description": "OTHER VEGETABLES,FRESH OR CHILLED - OTHER --OTHER---OTHER"
    },
    {
      "code": "0710",
      "description": "VEGETABLES (UNCOOKED OR COOKED BY STEAMING OR BOILING IN WATER), FROZEN"
    },
    {
      "code": "07101000",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN POTATOES"
    },
    {
      "code": "07102100",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN LEGUMINOUS VEGETABLES, SHELLED OR UNSHELLED : PEAS (PISUM SATIVUM)"
    },
    {
      "code": "07102200",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN LEGUMINOUS VEGETABLES, SHELLED OR UNSHELLED BEANS (VIGNA SPP., PHASEOLUS SPP.)"
    },
    {
      "code": "07102900",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN LEGUMINOUS VEGETABLES, SHELLED OR UNSHELLED OTHER"
    },
    {
      "code": "07103000",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN SPINACH, NEW ZEALAND SPINACH AND ORACHE SPINACH (GARDEN SPINACH)"
    },
    {
      "code": "07104000",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN SWEET CORN"
    },
    {
      "code": "071080",
      "description": "Other vegetables"
    },
    {
      "code": "07108010",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN OTHER VEGETABLES TERRAGON"
    },
    {
      "code": "07108090",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN OTHER VEGETABLES OTHER"
    },
    {
      "code": "07109000",
      "description": "VEGETABLES (UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER), FROZEN MIXTURES OF VEGETABLES"
    },
    {
      "code": "0711",
      "description": "VEGETABLES PROVISIONALLY PRESERVED (FOR EXAMPLE, BY SULPHUR DIOXIDE GAS, IN BRINE, IN SULPHUR WATER OR IN OTHER PRESERVATIVE SOLUTIONS), BUT UNSUITABLE IN THAT STATE FOR IMMEDIATE CONSUMPTION"
    },
    {
      "code": "07112000",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS, BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION OLIVES"
    },
    {
      "code": "07113000",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION CAPERS"
    },
    {
      "code": "07114000",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTIONCUCUMBERS AND GHERKINS"
    },
    {
      "code": "07115100",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION MUSHROOMS AND TRUFFLES MUSHROOMS OF THE GENSUS AGARICUS"
    },
    {
      "code": "07115900",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION MUSHROOMS AND TRUFFLES OTHER"
    },
    {
      "code": "071190",
      "description": "Other vegetables; mixtures of vegetables"
    },
    {
      "code": "07119010",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION OTHER VEGETABLES; MIXTURES OF VEGETABLES : GREEN PEPPER IN BRINE"
    },
    {
      "code": "07119020",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION OTHER VEGETABLES; MIXTURES OF VEGETABLES : ASSORTED CANNED VEGETABLES"
    },
    {
      "code": "07119090",
      "description": "VEGETABLES PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION OTHER VEGETABLES; MIXTURES OF VEGETABLES : OTHER"
    },
    {
      "code": "0712",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED, BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED"
    },
    {
      "code": "07122000",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED ONIONS"
    },
    {
      "code": "07123100",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED MUSHROOMS, WOOD EARS (AURICULARIA SPP.),JELLY FUNGI (TREMELLA SPP.) AND TRUFFLES : MUSHROOMS OF THE GENUS LGARICUS"
    },
    {
      "code": "07123200",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED MUSHROOMS, WOOD EARS (AURICULARIA SPP.),JELLY FUNGI (TREMELLA SPP.) AND TRUFFLES : WOOD EARS (AURICULARIA SPP.)"
    },
    {
      "code": "07123300",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED ?MUSHROOMS, WOOD EARS (AURICULARIA SPP.),JELLY FUNGI (TREMELLA SPP.) AND TRUFFLES : JELLY FUNGI (TREMELLA SPP.)"
    },
    {
      "code": "07123900",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED ? MUSHROOMS, WOOD EARS (AURICULARIA SPP.),JELLY FUNGI (TREMELLA SPP.) AND TRUFFLES : OTHER"
    },
    {
      "code": "071290",
      "description": "Other vegetables; mixtures of vegetables"
    },
    {
      "code": "07129010",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : ASPARAGUS"
    },
    {
      "code": "07129020",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : DEHYDRATED GARLIC POWDER"
    },
    {
      "code": "07129030",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : DEHYDRATED GARLIC FLAKES"
    },
    {
      "code": "07129040",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : DRIED GARLIC"
    },
    {
      "code": "07129050",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : MARJORAM, OREGANO"
    },
    {
      "code": "07129060",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : POTATOES"
    },
    {
      "code": "07129090",
      "description": "DRIED VEGETABLES, WHOLE, CUT, SLICED,BROKEN OR IN POWDER, BUT NOT FURTHER PREPARED OTHER VEGETABLES; MIXTURES OF VEGETABLES : OTHER"
    },
    {
      "code": "0713",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED, WHETHER OR NOT SKINNED OR SPLIT"
    },
    {
      "code": "071310",
      "description": "Peas (PISUM SATIVUM)"
    },
    {
      "code": "07131000",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT PEAS (PISUM SATIVUM)"
    },
    {
      "code": "07131010",
      "description": "Yellow Peas"
    },
    {
      "code": "07131020",
      "description": "Green Peas"
    },
    {
      "code": "07131090",
      "description": "Other"
    },
    {
      "code": "071320",
      "description": "Chikpeas"
    },
    {
      "code": "07132000",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT CHICKPEAS (GARBANZOS) BEANS (VIGNA SPP., PHASEOLUS SPP.) :"
    },
    {
      "code": "07132010",
      "description": "kabuli Channa"
    },
    {
      "code": "07132020",
      "description": "Bengal gram (desi channa)"
    },
    {
      "code": "07132090",
      "description": "Other"
    },
    {
      "code": "07133100",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BEANS (VIGNA SPP., PHASEOLUS SPP.) : BEANS OF THE SPECIES VIGNA MUNGO (L.) HEPPER OR VIGNA RADIATA (L.) WILCZEK"
    },
    {
      "code": "07133110",
      "description": "BEANS OF THE SPECIES VIGNA MUNGO (L)HEPPER"
    },
    {
      "code": "07133190",
      "description": "BEANS OF THE SPECIES VIGNA RADIATA (L) WILCZEK"
    },
    {
      "code": "07133200",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BEANS (VIGNA SPP., PHASEOLUS SPP.) : SMALL RED (ADZUKI) BEANS (PHASEOLUS OR VIGNA ANGULARIS)"
    },
    {
      "code": "07133300",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BEANS (VIGNA SPP., PHASEOLUS SPP.) : KIDNEY BEANS, INCLUDING WHITE PEA BEANS"
    },
    {
      "code": "071334",
      "description": "Bambara beans"
    },
    {
      "code": "07133400",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT - BEANS --BAMBARA BEANS (VIGNA SUBTERRANEA OR VOANDZEIA SUBTERRANEA)"
    },
    {
      "code": "071335",
      "description": "Cow peas"
    },
    {
      "code": "07133500",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT - BEANS --COW PEAS (VIGNA UNGUICULATA)"
    },
    {
      "code": "071339",
      "description": "Other"
    },
    {
      "code": "07133910",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BEANS (VIGNA SPP., PHASEOLUS SPP.) : OTHER : GUAR SEEDS"
    },
    {
      "code": "07133990",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BEANS (VIGNA SPP., PHASEOLUS SPP.) : OTHER : OTHER"
    },
    {
      "code": "07134000",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT LENTILS"
    },
    {
      "code": "07135000",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT BROAD BEANS (VICIA FABA VAR MAJOR) AND HORSE BEANS (VICIA FABA VAR EQUINA, VICIA FABAVAR MINOR)"
    },
    {
      "code": "071360",
      "description": "Pigeon peas"
    },
    {
      "code": "07136000",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT - PIGEON PEAS (CAJANUS CAJAN)"
    },
    {
      "code": "071390",
      "description": "Other"
    },
    {
      "code": "07139010",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT -OTHER ---SPLIT"
    },
    {
      "code": "07139090",
      "description": "DRIED LEGUMINOUS VEGETABLES, SHELLED,WHETHER OR NOT SKINNED OR SPLIT - OTHER---OTHER"
    },
    {
      "code": "0714",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEM ARTICHOKES, SWEET POTATOES AND SIMILAR ROOTS AND TUBERS WITH HIGH STARCH OR INULIN CONTENT, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT SLICED OR IN THE FORM OF PELLETS; SAGO PITH"
    },
    {
      "code": "07141000",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH MANIOC (CASSAVA)"
    },
    {
      "code": "07142000",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH SWEET POTATOES"
    },
    {
      "code": "071430",
      "description": "Yams"
    },
    {
      "code": "07143000",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH-YAMS (DIOSCOREA SPP.)"
    },
    {
      "code": "071440",
      "description": "Taro"
    },
    {
      "code": "07144000",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH- TARO (COLOCASIA SPP.)"
    },
    {
      "code": "071450",
      "description": "Yautia"
    },
    {
      "code": "07145000",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH-YAUTIA (XANTHOSOMA SPP.)"
    },
    {
      "code": "071490",
      "description": "Other"
    },
    {
      "code": "07149010",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH OTHER : SAGO PITH"
    },
    {
      "code": "07149090",
      "description": "MANIOC, ARROWROOT, SALEP, JERUSALEMARTICHOKES, SWEET POTATOES AND SIMILARROOTS AND TUBERS WITH HIGH STARCH ORINULIN CONTENT, FRESH, CHILLED, FROZEN ORDRIED, WHETHER OR NOT SLICED OR IN THEFORM OF PELLETS; SAGO PITH OTHER : OTHER"
    },
    {
      "code": "08",
      "description": "Edible fruit and nuts; peel of citrus fruit or melons"
    },
    {
      "code": "0801",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED"
    },
    {
      "code": "08011100",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED  COCONUTS : DESICCATED"
    },
    {
      "code": "080112",
      "description": "In the inner shell (Endocarp)"
    },
    {
      "code": "08011210",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED  COCONUTS --IN THE INNER SHELL (ENDOCARP): ---FRESH"
    },
    {
      "code": "08011220",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED  COCONUTS --IN THE INNER SHELL ---DRIED"
    },
    {
      "code": "08011290",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED  COCONUTS --IN THE INNER SHELL (ENDOCARP)---OTHER"
    },
    {
      "code": "080119",
      "description": "Other"
    },
    {
      "code": "08011910",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED OTHER : FRESH"
    },
    {
      "code": "08011920",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED OTHER : DRIED"
    },
    {
      "code": "08011990",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELED OTHER : OTHER"
    },
    {
      "code": "08012100",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELEDBRAZIL NUTS : IN SHELL"
    },
    {
      "code": "08012200",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELEDBRAZIL NUTS : SHELLED"
    },
    {
      "code": "08013100",
      "description": "In shell"
    },
    {
      "code": "080132",
      "description": "Shelled"
    },
    {
      "code": "08013210",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELEDCASHEW NUTS SHELLED CASHEW KERNEL, BROKEN"
    },
    {
      "code": "08013220",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELEDCASHEW NUTS SHELLED SHELLED : CASHEW KERNEL, WHOLE"
    },
    {
      "code": "08013290",
      "description": "COCONUTS, BRAZIL NUTS AND CASHEW NUTS,FRESH OR DRIED, WHETHER OR NOT SHELLEDOR PEELEDCASHEW NUTS SHELLED  OTHER"
    },
    {
      "code": "0802",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED"
    },
    {
      "code": "08021100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEELEDALMONDS : IN SHELL"
    },
    {
      "code": "08021200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEELEDALMONDS : SHELLED"
    },
    {
      "code": "08022100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEE  HAZELNUTS OR FILBERTS (CORYLUS SPP.) : IN SHELL"
    },
    {
      "code": "08022200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEE  HAZELNUTS OR FILBERTS (CORYLUS SPP.) : SHELLED"
    },
    {
      "code": "08023100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEELED WALNUTS : IN SHELL"
    },
    {
      "code": "08023200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHEROR NOT SHELLED OR PEELED WALNUTS : SHELLED"
    },
    {
      "code": "080241",
      "description": "Chestnuts"
    },
    {
      "code": "08024100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - CHESTNUTS (CASTANEA SPP.) : -- IN SHELL"
    },
    {
      "code": "080242",
      "description": "Shelled"
    },
    {
      "code": "08024200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - CHESTNUTS (CASTANEA SPP.) : -- SHELLED"
    },
    {
      "code": "080251",
      "description": "Pistachios"
    },
    {
      "code": "08025100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - PISTACHIOS -- IN SHELL"
    },
    {
      "code": "080252",
      "description": "Shelled"
    },
    {
      "code": "08025200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - PISTACHIOS --SHELLED"
    },
    {
      "code": "080261",
      "description": "Macadamia nuts"
    },
    {
      "code": "08026100",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - MACADEMIA NUTS -- IN SHELL"
    },
    {
      "code": "080262",
      "description": "Shelled"
    },
    {
      "code": "08026200",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - MACADEMIA NUTS -- SHELLED"
    },
    {
      "code": "080270",
      "description": "Kola nuts"
    },
    {
      "code": "08027000",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - KOLA NUTS (COLA SPP.)"
    },
    {
      "code": "080280",
      "description": "Areca nuts"
    },
    {
      "code": "08028010",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - ARECA NUTS ---WHOLE"
    },
    {
      "code": "08028020",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - ARECA NUTS ---SPLIT"
    },
    {
      "code": "08028030",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - ARECA NUTS ---GROUND"
    },
    {
      "code": "08028090",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - ARECA NUTS ---OTHER"
    },
    {
      "code": "080290",
      "description": "Other"
    },
    {
      "code": "08029000",
      "description": "OTHER NUTS, FRESH OR DRIED, WHETHER OR NOT SHELLED OR PEELED - OTHER"
    },
    {
      "code": "0803",
      "description": "BANANAS, INCLUDING PLANTAINS, FRESH OR DRIED"
    },
    {
      "code": "080310",
      "description": "Plaintains"
    },
    {
      "code": "08031010",
      "description": "BANANAS, INCLUDING PLANTAINS, FRESH OR DRIED - PLANTAINS --- CURRY PLANTAIN"
    },
    {
      "code": "08031090",
      "description": "BANANAS, INCLUDING PLANTAINS, FRESH OR DRIED - PLANTAINS --- OTHER"
    },
    {
      "code": "080390",
      "description": "other"
    },
    {
      "code": "08039010",
      "description": "BANANAS, INCLUDING PLANTAINS, FRESH OR DRIED - OTHER --- BANANAS, FRESH"
    },
    {
      "code": "08039090",
      "description": "BANANAS, INCLUDING PLANTAINS, FRESH OR DRIED - OTHER --- OTHER"
    },
    {
      "code": "0804",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS, MANGOES, AND MANGOSTEENS, FRESH OR DRIED"
    },
    {
      "code": "080410",
      "description": "Dates"
    },
    {
      "code": "08041010",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDDATES : FRESH (EXCLUDING WET DATES)"
    },
    {
      "code": "08041020",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDDATES : SOFT (KHAYZUR OR WET DATES)"
    },
    {
      "code": "08041030",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDDATES : HARD (CHHOHARA OR KHAREK)"
    },
    {
      "code": "08041090",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDDATES : OTHER"
    },
    {
      "code": "080420",
      "description": "Figs"
    },
    {
      "code": "08042010",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDFIGS : FRESH"
    },
    {
      "code": "08042090",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDFIGS : OTHER"
    },
    {
      "code": "08043000",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIED PINEAPPLES"
    },
    {
      "code": "08044000",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDAVOCADOS"
    },
    {
      "code": "080450",
      "description": "Guavas, mangoes and mangosteens"
    },
    {
      "code": "08045010",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDGUAVAS, MANGOES AND MANGOSTEENS : GUAVAS, FRESH OR DRIED"
    },
    {
      "code": "08045020",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDGUAVAS, MANGOES AND MANGOSTEENS : MANGOES, FRESH"
    },
    {
      "code": "08045021",
      "description": "Alphonso (Hapus)"
    },
    {
      "code": "08045022",
      "description": "Banganapalli"
    },
    {
      "code": "08045023",
      "description": "chausa"
    },
    {
      "code": "08045024",
      "description": "Dashaeri"
    },
    {
      "code": "08045025",
      "description": "Langla"
    },
    {
      "code": "08045026",
      "description": "Kesar"
    },
    {
      "code": "08045027",
      "description": "Totapuri"
    },
    {
      "code": "08045028",
      "description": "Mallika"
    },
    {
      "code": "08045029",
      "description": "other"
    },
    {
      "code": "08045030",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDGUAVAS, MANGOES AND MANGOSTEENS : MANGOES, SLICED DRIED"
    },
    {
      "code": "08045040",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIEDGUAVAS, MANGOES AND MANGOSTEENS : MANGO PULP"
    },
    {
      "code": "08045090",
      "description": "DATES, FIGS, PINEAPPLES, AVOCADOS, GUAVAS,MANGOES, AND MANGOSTEENS, FRESH OR DRIED GUAVAS, MANGOES AND MANGOSTEENS : OTHER"
    },
    {
      "code": "0805",
      "description": "CITRUS FRUIT, FRESH OR DRIED"
    },
    {
      "code": "08051000",
      "description": "CITRUS FRUIT, FRESH OR DRIED ORANGES"
    },
    {
      "code": "08052000",
      "description": "CITRUS FRUIT, FRESH OR DRIED MANDARINS (INCLUDING TANGERINES AND SATSUMAS); CLEMENTINES, WILKINGS AND SIMILAR CITRUS HYBRIDS"
    },
    {
      "code": "08052100",
      "description": "Mandarins (including tangerines and satsumas)"
    },
    {
      "code": "08052200",
      "description": "Clementines"
    },
    {
      "code": "08052900",
      "description": "Other"
    },
    {
      "code": "08054000",
      "description": "CITRUS FRUIT, FRESH OR DRIED - GRAPE FRUIT, INCLUDING POMELOS"
    },
    {
      "code": "08055000",
      "description": "CITRUS FRUIT, FRESH OR DRIED LEMON (CITRUS LIMON, CITRUS LIMONUM) AND LIMES (CITRUS AURANTIFOLIA, CITRUS LATIFOLIA)"
    },
    {
      "code": "08059000",
      "description": "CITRUS FRUIT, FRESH OR DRIED OTHER"
    },
    {
      "code": "0806",
      "description": "GRAPES, FRESH OR DRIED"
    },
    {
      "code": "08061000",
      "description": "GRAPES, FRESH OR DRIED FRESH"
    },
    {
      "code": "080620",
      "description": "Dried"
    },
    {
      "code": "08062010",
      "description": "GRAPES, FRESH OR DRIED DRIED : RAISINS"
    },
    {
      "code": "08062090",
      "description": "GRAPES, FRESH OR DRIED DRIED : OTHER"
    },
    {
      "code": "0807",
      "description": "MELONS (INCLUDING WATERMELONS) AND PAPAWS (PAPAYAS), FRESH"
    },
    {
      "code": "08071100",
      "description": "MELONS (INCLUDING WATERMELONS) ANDPAPAWS (PAPAYAS), FRESH MELONS (INCLUDING WATERMELONS) : WATER MELONS"
    },
    {
      "code": "080719",
      "description": "other"
    },
    {
      "code": "08071900",
      "description": "MELONS (INCLUDING WATERMELONS) ANDPAPAWS (PAPAYAS), FRESH MELONS (INCLUDING WATERMELONS) : OTHER"
    },
    {
      "code": "08071910",
      "description": "Musk melons"
    },
    {
      "code": "08071990",
      "description": "Other"
    },
    {
      "code": "08072000",
      "description": "MELONS (INCLUDING WATERMELONS) ANDPAPAWS (PAPAYAS), FRESHPAPAWS (PAPAYAS)"
    },
    {
      "code": "0808",
      "description": "APPLES, PEARS AND QUINCES, FRESH"
    },
    {
      "code": "08081000",
      "description": "APPLES, PEARS AND QUINCES, FRESHAPPLES"
    },
    {
      "code": "080821",
      "description": "Sour Cherries"
    },
    {
      "code": "080829",
      "description": "Other"
    },
    {
      "code": "080830",
      "description": "Pears"
    },
    {
      "code": "08083000",
      "description": "APPLES, PEARS AND QUINCES, FRESH - PEARS"
    },
    {
      "code": "080840",
      "description": "Quinces"
    },
    {
      "code": "08084000",
      "description": "APPLES, PEARS AND QUINCES, FRESH - QUINCES"
    },
    {
      "code": "0809",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDING NECTARINES), PLUMS AND SOLES, FRESH"
    },
    {
      "code": "08091000",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDINGNECTARINES), PLUMS AND SOLES, FRESH APRICOTS"
    },
    {
      "code": "080921",
      "description": "Sour Cherries"
    },
    {
      "code": "08092100",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDING NECTARINES), PLUMS AND SOLES, FRESH - CHERRIES -- SOUR CHERRIES (PRUNUS CERASUS)"
    },
    {
      "code": "080929",
      "description": "other"
    },
    {
      "code": "08092900",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDING NECTARINES), PLUMS AND SOLES, FRESH - CHERRIES -- OTHER"
    },
    {
      "code": "08093000",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDINGNECTARINES), PLUMS AND SOLES, FRESH PEACHES, INCLUDING NECTARINE"
    },
    {
      "code": "08094000",
      "description": "APRICOTS, CHERRIES, PEACHES (INCLUDINGNECTARINES), PLUMS AND SOLES, FRESHPLUMS AND SLOES"
    },
    {
      "code": "0810",
      "description": "OTHER FRUIT, FRESH"
    },
    {
      "code": "08101000",
      "description": "OTHER FRUIT, FRESH STRAWBERRIES"
    },
    {
      "code": "08102000",
      "description": "OTHER FRUIT, FRESH RASPBERRIES, BLACKBERRIES, MULBERRIES AND LOGANBERRIES"
    },
    {
      "code": "081030",
      "description": "Blac, white or ret currants"
    },
    {
      "code": "08103000",
      "description": "OTHER FRUIT, FRESH BLACK, WHITE OR RED CURRANTS AND GOOSEBERRIES"
    },
    {
      "code": "08104000",
      "description": "OTHER FRUIT, FRESH CRANBERRIES, BILBERRIES AND OTHER FRUITS OF THE GENUS VACCINIUM"
    },
    {
      "code": "08105000",
      "description": "OTHER FRUIT, FRESH KIWI FRUIT"
    },
    {
      "code": "08106000",
      "description": "OTHER FRUIT, FRESH DURIANS"
    },
    {
      "code": "081070",
      "description": "Persimmons"
    },
    {
      "code": "08107000",
      "description": "OTHER FRUIT, FRESH - PERSIMMONS"
    },
    {
      "code": "081090",
      "description": "Other"
    },
    {
      "code": "08109010",
      "description": "OTHER FRUIT, FRESH OTHER : POMEGRANATES"
    },
    {
      "code": "08109020",
      "description": "OTHER FRUIT, FRESH OTHER : TAMARIND, FRESH"
    },
    {
      "code": "08109030",
      "description": "OTHER FRUIT, FRESH OTHER : SAPOTA (CHICO)"
    },
    {
      "code": "08109040",
      "description": "OTHER FRUIT, FRESH OTHER : CUSTARD-APPLE (ATA)"
    },
    {
      "code": "08109050",
      "description": "OTHER FRUIT, FRESH OTHER : BORE"
    },
    {
      "code": "08109060",
      "description": "OTHER FRUIT, FRESH OTHER : LICHI"
    },
    {
      "code": "08109090",
      "description": "OTHER FRUIT, FRESH OTHER : OTHER"
    },
    {
      "code": "0811",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BY STEAMING OR BOILING IN WATER, FROZEN, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "081110",
      "description": "Strawberries"
    },
    {
      "code": "08111010",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER STRAWBERRIES : CONTAINING ADDED SUGAR"
    },
    {
      "code": "08111020",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER STRAWBERRIES : NOT CONTAINING ADDED SUGAR"
    },
    {
      "code": "08111090",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER STRAWBERRIES : OTHER"
    },
    {
      "code": "081120",
      "description": "Raspberries, blackberries, mulberries, loganberries, black, white or red currants and gooseberries"
    },
    {
      "code": "08112010",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER RASPBERRIES, BLACKBERRIES, MULBERRIES,LOGANBERRIES, BLACK, WHITE OR RED CURRANTSAND GOOSEBERRIES : CONTAINING ADDED SUGAR"
    },
    {
      "code": "08112020",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER RASPBERRIES, BLACKBERRIES, MULBERRIES,LOGANBERRIES, BLACK, WHITE OR RED CURRANTSAND GOOSEBERRIES : NOT CONTAINING ADDED SUGAR"
    },
    {
      "code": "08112090",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER RASPBERRIES, BLACKBERRIES, MULBERRIES,LOGANBERRIES, BLACK, WHITE OR RED CURRANTSAND GOOSEBERRIES : OTHER"
    },
    {
      "code": "081190",
      "description": "Other"
    },
    {
      "code": "08119010",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OTHER : CONTAINING ADDED SUGAR"
    },
    {
      "code": "08119090",
      "description": "FRUIT AND NUTS, UNCOOKED OR COOKED BYSTEAMING OR BOILING IN WATER, FROZEN,WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OTHER  OTHER"
    },
    {
      "code": "0812",
      "description": "FRUIT AND NUTS PROVISIONALLY PRESERVED (FOR EXAMPLE, BY SULPHUR DIOXIDE GAS, IN BRINE, IN SULPHUR WATER OR IN OTHER PRESERVATIVE SOLUTIONS), BUT UNSUITABLE IN THAT STATE FOR IMMEDIATE CONSUMPTION"
    },
    {
      "code": "08121000",
      "description": "FRUIT AND NUTS PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTIONCHERRIES"
    },
    {
      "code": "081290",
      "description": "Other"
    },
    {
      "code": "08129010",
      "description": "FRUIT AND NUTS PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTION OTHER : MANGO SLICES IN BRINE"
    },
    {
      "code": "08129090",
      "description": "FRUIT AND NUTS PROVISIONALLY PRESERVED(FOR EXAMPLE, BY SULPHUR DIOXIDE GAS,IN BRINE, IN SULPHUR WATER OR IN OTHERPRESERVATIVE SOLUTIONS), BUT UNSUITABLEIN THAT STATE FOR IMMEDIATE CONSUMPTIONOTHER : OTHER"
    },
    {
      "code": "0813",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS 0801 TO 0806; MIXTURES OF NUTS OR DRIED FRUITS OF THIS CHAPTER"
    },
    {
      "code": "08131000",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTER APRICOTS"
    },
    {
      "code": "08132000",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTER PRUNES"
    },
    {
      "code": "08133000",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTER APPLES"
    },
    {
      "code": "081340",
      "description": "Other fruit"
    },
    {
      "code": "08134010",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTEROTHER FRUIT : TAMARIND, DRIED"
    },
    {
      "code": "08134020",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTEROTHER FRUIT : SINGODA WHOLE (WATER NUT)"
    },
    {
      "code": "08134090",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTEROTHER FRUIT : OTHER"
    },
    {
      "code": "081350",
      "description": "Mixtures of nuts or dried fruits of this Chapter"
    },
    {
      "code": "08135010",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTER MIXTURES OF NUTS"
    },
    {
      "code": "08135020",
      "description": "FRUIT, DRIED, OTHER THAN THAT OF HEADINGS0801 TO 0806; MIXTURES OF NUTS OR DRIEDFRUITS OF THIS CHAPTER MIXTURES OF DRIED FRUITS"
    },
    {
      "code": "0814",
      "description": "PEEL OF CITRUS FRUIT OR MELONS (INCLUDING WATERMELONS), FRESH, FROZEN, DRIED OR PROVISIONALLY PRESERVED IN BRINE, IN SULPHUR WATER OR IN OTHER PRESERVATIVE SOLUTIONS"
    },
    {
      "code": "08140000",
      "description": "PEEL OF CITRUS FRUIT OR MELONS (INCLUDING WATERMELONS), FRESH, FROZEN, DRIED OR PROVISIONALLY PRESERVED IN BRINE, IN SULPHUR WATER OR IN OTHER PRESERVATIVE SOLUTIONS"
    },
    {
      "code": "09",
      "description": "Coffee, tea, mate and spices"
    },
    {
      "code": "0901",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DECAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION"
    },
    {
      "code": "090111",
      "description": "Not decaffeinated"
    },
    {
      "code": "09011111",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica plantation : A Grade"
    },
    {
      "code": "09011112",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica plantation : B Grade"
    },
    {
      "code": "09011113",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica plantation : C Grade"
    },
    {
      "code": "09011119",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica plantation : Other"
    },
    {
      "code": "09011121",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica cherry : AB Grade"
    },
    {
      "code": "09011122",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica cherry : PB Grade"
    },
    {
      "code": "09011123",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica cherry : C Grade"
    },
    {
      "code": "09011124",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica cherry : B/B/B Grade"
    },
    {
      "code": "09011129",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Arabica cherry : Other"
    },
    {
      "code": "09011131",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob Parchment : AB Grade"
    },
    {
      "code": "09011132",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob Parchment : PB Grade"
    },
    {
      "code": "09011133",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob Parchment : C Grade"
    },
    {
      "code": "09011139",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob Parchment : Other"
    },
    {
      "code": "09011141",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : AB Grade"
    },
    {
      "code": "09011142",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : PB Grade"
    },
    {
      "code": "09011143",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : C Grade"
    },
    {
      "code": "09011144",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : B/B/B Grade"
    },
    {
      "code": "09011145",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : Bulk"
    },
    {
      "code": "09011149",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Rob cherry : Other"
    },
    {
      "code": "09011190",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Not decaffeinated : Other"
    },
    {
      "code": "09011200",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, not roasted : Decaffeinated"
    },
    {
      "code": "090121",
      "description": "Not decaffeinated"
    },
    {
      "code": "09012110",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, roasted : Not decaffeinated : In bulk packing"
    },
    {
      "code": "09012190",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, roasted : Not decaffeinated : Other"
    },
    {
      "code": "090122",
      "description": "Decaffeinated"
    },
    {
      "code": "09012210",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, roasted : Decaffeinated : In bulk packing"
    },
    {
      "code": "09012290",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Coffee, roasted : Decaffeinated : Other"
    },
    {
      "code": "090190",
      "description": "Other"
    },
    {
      "code": "09019010",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Other : Coffee husks and skins"
    },
    {
      "code": "09019020",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Other : Coffee substitutes containing coffee"
    },
    {
      "code": "09019090",
      "description": "COFFEE, WHETHER OR NOT ROASTED OR DACAFFEINATED; COFFEE HUSKS AND SKINS; COFFEE SUBSTITUTES CONTAINING COFFEE IN ANY PROPORTION Other : Other"
    },
    {
      "code": "0902",
      "description": "TEA, WHETHER OR NOT FLAVOURED"
    },
    {
      "code": "090210",
      "description": "Green tea (not fermented) in immediate packings of a content not exceeding 3 kg"
    },
    {
      "code": "09021010",
      "description": "TEA, WHETHER OR NOT FLAVOURED GREEN TEA (NOT FERMENTED) IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG : CONTENT NOT EXCEEDING 25 G ."
    },
    {
      "code": "09021020",
      "description": "TEA, WHETHER OR NOT FLAVOURED GREEN TEA (NOT FERMENTED) IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG : CONTENT EXCEEDING 25 G. BUT NOT EXCEEDING 1 KG."
    },
    {
      "code": "09021030",
      "description": "TEA, WHETHER OR NOT FLAVOURED GREEN TEA (NOT FERMENTED) IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG : CONTENT EXCEEDING 1 KG. BUT NOT EXCEEDING 3 KG."
    },
    {
      "code": "09021090",
      "description": "TEA, WHETHER OR NOT FLAVOURED GREEN TEA (NOT FERMENTED) IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG : OTHER"
    },
    {
      "code": "090220",
      "description": "Other green tea (not fermented)"
    },
    {
      "code": "09022010",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER GREEN TEA (NOT FERMENTED) : GREEN TEA IN PACKETS WITH CONTENTS EXCEEDING 3 KG BUT NOT EXCEEDING 20 KG"
    },
    {
      "code": "09022020",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER GREEN TEA (NOT FERMENTED) : GREEN TEA IN BULK"
    },
    {
      "code": "09022030",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER GREEN TEA (NOT FERMENTED) : GREEN TEA AGGLOMERATED IN FORMS SUCH AS BALL,BRICK AND TABLETS"
    },
    {
      "code": "09022040",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER GREEN TEA (NOT FERMENTED) : GREEN TEA WASTE"
    },
    {
      "code": "09022090",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER GREEN TEA (NOT FERMENTED) : OTHER"
    },
    {
      "code": "090230",
      "description": "Black tea (fermented) and partly fermented tea, in immediate packings of a content not exceeding 3 kg."
    },
    {
      "code": "09023010",
      "description": "TEA, WHETHER OR NOT FLAVOURED BLACK TEA (FERMENTED) AND PARTLY FERMENTED TEA, IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG. : CONTENT NOT EXCEEDING 25 G."
    },
    {
      "code": "09023020",
      "description": "TEA, WHETHER OR NOT FLAVOURED BLACK TEA (FERMENTED) AND PARTLY FERMENTED TEA, IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG. : CONTENT EXCEEDING 25 G. BUT NOT EXCEEDING 1 KG."
    },
    {
      "code": "09023030",
      "description": "TEA, WHETHER OR NOT FLAVOURED BLACK TEA (FERMENTED) AND PARTLY FERMENTED TEA, IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG. : CONTENT EXCEEDING 1 KG. BUT NOT EXCEEDING 3 KG."
    },
    {
      "code": "09023090",
      "description": "TEA, WHETHER OR NOT FLAVOURED BLACK TEA (FERMENTED) AND PARTLY FERMENTED TEA, IN IMMEDIATE PACKINGS OF A CONTENT NOT EXCEEDING 3 KG. : OTHER"
    },
    {
      "code": "090240",
      "description": "Other black tea (fermented) and other partly fermented tea"
    },
    {
      "code": "09024010",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : CONTENT EXCEEDING 3 KG. BUT NOT EXCEEDING 20 KG."
    },
    {
      "code": "09024020",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : BLACK TEA, LEAF IN BULK"
    },
    {
      "code": "09024030",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : BLACK TEA, DUST IN BULK"
    },
    {
      "code": "09024040",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : TEA BAGS"
    },
    {
      "code": "09024050",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : BLACK TEA, AGGLOMERATED IN FORMS SUCH AS BALL, BRICK AND TABLETS"
    },
    {
      "code": "09024060",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : BLACK TEA, WASTE"
    },
    {
      "code": "09024090",
      "description": "TEA, WHETHER OR NOT FLAVOURED OTHER BLACK TEA (FERMENTED) AND OTHER PARTLY FERMENTED TEA : OTHER"
    },
    {
      "code": "0903",
      "description": "MATE"
    },
    {
      "code": "09030000",
      "description": "MATE"
    },
    {
      "code": "0904",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA"
    },
    {
      "code": "090411",
      "description": "Neither crushed nor ground"
    },
    {
      "code": "09041110",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Pepper, long"
    },
    {
      "code": "09041120",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Light black pepper"
    },
    {
      "code": "09041130",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Black pepper, garbled"
    },
    {
      "code": "09041140",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Black pepper ungarbled"
    },
    {
      "code": "09041150",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHEDOR GROUND FRUITS OF THE GENUS CAPSICUM OROF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Green pepper, dehydrated"
    },
    {
      "code": "09041160",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Pepper pinheads"
    },
    {
      "code": "09041170",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Green pepper, frozen or dried"
    },
    {
      "code": "09041180",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Pepper other than green, frozen"
    },
    {
      "code": "09041190",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Neither crushed nor ground : Other"
    },
    {
      "code": "09041200",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA Pepper : Crushed or ground"
    },
    {
      "code": "090420",
      "description": "Fruits of the genus Capsicum or of the genus Pimenta, dried or crushed or ground"
    },
    {
      "code": "090421",
      "description": "Dried, neither crushed nor gorund"
    },
    {
      "code": "09042110",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- DRIED NEITHER CRUSHED NOR GROUND ---OF GENUS CAPSICUM"
    },
    {
      "code": "09042120",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- DRIED NEITHER CRUSHED NOR GROUND ---OF GENUS PIMENTA"
    },
    {
      "code": "090422",
      "description": "crushed or ground"
    },
    {
      "code": "09042211",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- CRUSHED OR GROUND --- OF GENUS CAPSICUM ---CHILLY POWDER"
    },
    {
      "code": "09042212",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- CRUSHED OR GROUND --- OF GENUS CAPSICUM---- CHILLY SEEDS"
    },
    {
      "code": "09042219",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- CRUSHED OR GROUND --- OF GENUS CAPSICUM----OTHER"
    },
    {
      "code": "09042221",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- CRUSHED OR GROUND --- OF GENUS PIMENTA ---- POWDER"
    },
    {
      "code": "09042229",
      "description": "PEPPER OF THE GENUS PIPER; DRIED OR CRUSHED OR GROUND  FRUITS OF THE GENUS CAPSICUM OF THE GENUS PIMENTA - FRUITS OF THE GENUS CAPSICUM OR OF THE GENUS PIMENTA -- CRUSHED OR GROUND --- OF GENUS PIMENTA ----OTHER"
    },
    {
      "code": "0905",
      "description": "VANILLA"
    },
    {
      "code": "090500",
      "description": "Vanilla"
    },
    {
      "code": "090510",
      "description": "Vannila"
    },
    {
      "code": "09051000",
      "description": "VANILLA - NEITHER CRUSHED NOR GROUND"
    },
    {
      "code": "090520",
      "description": "Crushed or ground"
    },
    {
      "code": "09052000",
      "description": "VANILLA - CRUSHED OR GROUND"
    },
    {
      "code": "0906",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS"
    },
    {
      "code": "09061010",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS NEITHER CRUSHED NOR GROUND : CASSIA"
    },
    {
      "code": "09061020",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS NEITHER CRUSHED NOR GROUND : CINNAMON BARK"
    },
    {
      "code": "09061030",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS NEITHER CRUSHED NOR GROUND : CINNAMON TREE FLOWERS"
    },
    {
      "code": "09061090",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS NEITHER CRUSHED NOR GROUND : OTHER"
    },
    {
      "code": "090611",
      "description": "Cinnamon (Cinnamomum Zeylanicum Blume)"
    },
    {
      "code": "09061110",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS - NEITHER CRUSHED NOR GROUND :-- CINNAMON (CINNAMOMUM ZEYLANICUM BLUME): --- CINNAMON BARK"
    },
    {
      "code": "09061120",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS - NEITHER CRUSHED NOR GROUND :-- CINNAMON (CINNAMOMUM ZEYLANICUM BLUME): --- CINNAMON TREE FLOWERS"
    },
    {
      "code": "09061190",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS - NEITHER CRUSHED NOR GROUND :-- CINNAMON (CINNAMOMUM ZEYLANICUM BLUME): --- OTHER"
    },
    {
      "code": "090619",
      "description": "Other"
    },
    {
      "code": "09061910",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS - NEITHER CRUSHED NOR GROUND :-- OTHER --- CASSIA"
    },
    {
      "code": "09061990",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS - NEITHER CRUSHED NOR GROUND :-- OTHER : --- OTHER"
    },
    {
      "code": "09062000",
      "description": "CINNAMON AND CINNAMON-TREE FLOWERS Crushed or ground"
    },
    {
      "code": "0907",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS)"
    },
    {
      "code": "090710",
      "description": "Neither crushed or ground"
    },
    {
      "code": "09071010",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS) - NEITHER CRUSHED NOR GROUND --- EXTRACTED"
    },
    {
      "code": "09071020",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS) - NEITHER CRUSHED NOR GROUND --- NOT EXTRACTED (OTHER THAN STEM)"
    },
    {
      "code": "09071030",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS) - NEITHER CRUSHED NOR GROUND ---STEM"
    },
    {
      "code": "09071090",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS) - NEITHER CRUSHED NOR GROUND --- OTHER"
    },
    {
      "code": "090720",
      "description": "crushed or ground"
    },
    {
      "code": "09072000",
      "description": "CLOVES (WHOLE FRUIT, CLOVES AND STEMS) - CRUSHED OR GROUND"
    },
    {
      "code": "0908",
      "description": "NUT MEG :"
    },
    {
      "code": "090810",
      "description": "Nutmeg"
    },
    {
      "code": "090811",
      "description": "Neither crushed nor ground"
    },
    {
      "code": "09081110",
      "description": "NUTMEG, MACE AND CARDAMOMS - NUTMEG -- NEITHER CRUSHED NOR GROUND --- IN SHELL"
    },
    {
      "code": "09081120",
      "description": "NUTMEG, MACE AND CARDAMOMS - NUTMEG -- NEITHER CRUSHED NOR GROUND---SHELLED"
    },
    {
      "code": "090812",
      "description": "crushed or gorund"
    },
    {
      "code": "09081200",
      "description": "NUTMEG, MACE AND CARDAMOMS - NUTMEG -- CRUSHED OR GROUND"
    },
    {
      "code": "090821",
      "description": "mace"
    },
    {
      "code": "09082100",
      "description": "NUTMEG, MACE AND CARDAMOMS - MACE--NEITHER CRUSHED NOR GROUND"
    },
    {
      "code": "090822",
      "description": "crushed or ground"
    },
    {
      "code": "09082200",
      "description": "NUTMEG, MACE AND CARDAMOMS - MACE--CRUSHED OR GROUND"
    },
    {
      "code": "090830",
      "description": "Cardamoms"
    },
    {
      "code": "090831",
      "description": "Cardomoms Neither crushed nor ground"
    },
    {
      "code": "09083110",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---LARGE (AMOMUM)"
    },
    {
      "code": "09083120",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---SMALL (ELLETTARIA), ALLEPPEY GREEN"
    },
    {
      "code": "09083130",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---SMALL , COORG GREEN"
    },
    {
      "code": "09083140",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---SMALL , BLEACHED, HALF BLEACHED OR BLEACHABLE"
    },
    {
      "code": "09083150",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---SMALL , MIXED"
    },
    {
      "code": "09083190",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- NEITHER CRUSHED NOR GROUND ---OTHER"
    },
    {
      "code": "090832",
      "description": "crushed or ground"
    },
    {
      "code": "09083210",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- CRUSHED OR GROUND ---POWDER"
    },
    {
      "code": "09083220",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS --  CRUSHED OR GROUND ---SMALL CARDAMOM SEEDS"
    },
    {
      "code": "09083230",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- CRUSHED OR GROUND ---CARDAMOM HUSK"
    },
    {
      "code": "09083290",
      "description": "NUTMEG, MACE AND CARDAMOMS - CARDAMOMS -- CRUSHED OR GROUND ---OTHER"
    },
    {
      "code": "0909",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CORIANDER-- NEITHER CRUSED NOR GROUND---OF SEED QUALITY"
    },
    {
      "code": "090910",
      "description": "Seeds of anise or badian"
    },
    {
      "code": "090920",
      "description": "Seeds of coriander"
    },
    {
      "code": "090921",
      "description": "Neither crushed nor ground"
    },
    {
      "code": "09092110",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CORIANDER-- NEITHER CRUSED NOR GROUND---OF SEED QUALITY"
    },
    {
      "code": "09092190",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CORIANDER-- NEITHER CRUSED NOR GROUND---OTHER"
    },
    {
      "code": "090922",
      "description": "Crushed or ground"
    },
    {
      "code": "09092200",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CORIANDER-- CRUSED OR GROUND"
    },
    {
      "code": "090930",
      "description": "Seeds of cumin"
    },
    {
      "code": "090931",
      "description": "Seeds of cumin Neither crushed nor ground"
    },
    {
      "code": "09093111",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CUMIN-- NEITHER CRUSHED NOR GROUND---CUMIN, OTHER THAN BLACK----OF SEED QUALITY"
    },
    {
      "code": "09093119",
      "description": "other"
    },
    {
      "code": "09093121",
      "description": "EEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CUMIN--NEITHER CRUSHED NOR GROUND---CUMIN,OTHER THAN BLACK----OF SEED QUALITY"
    },
    {
      "code": "09093129",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CUMIN--NEITHER CRUSHED NOR GROUND---CUMIN,OTHER THAN BLACK----OTHER"
    },
    {
      "code": "090932",
      "description": "Crushed or ground"
    },
    {
      "code": "09093200",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF CUMIN-- CRUSHED OR GROUND"
    },
    {
      "code": "090940",
      "description": "Seeds of caraway"
    },
    {
      "code": "090950",
      "description": "Seeds of fennel; juniper berries"
    },
    {
      "code": "090961",
      "description": "Seeds of anise, badian, caraway or fennel juniper berries: neither crushed nor gorund"
    },
    {
      "code": "09096111",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF ANISE----OF SEED QUALITY"
    },
    {
      "code": "09096119",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF ANISE----OTHER"
    },
    {
      "code": "09096121",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE,BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF BADIAN----OF SEED QUALITY"
    },
    {
      "code": "09096129",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF BADIAN----OTHER"
    },
    {
      "code": "09096131",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE,BADIAN, CARAWAY OR FENNEL;JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF CARAWAY OR FENNEL ---- OF SEED QUALITY"
    },
    {
      "code": "09096139",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---SEEDS OF CARAWAY OR FENNEL----OTHER"
    },
    {
      "code": "09096141",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---JUNIPER BERRIES----OF SEED QUALITY"
    },
    {
      "code": "09096149",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--NEITHER CRUSHED NOR GROUND---JUNIPER BERRIES----OTHER"
    },
    {
      "code": "090962",
      "description": "Crushed or ground"
    },
    {
      "code": "09096210",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--CRUSHED OR GROUND---ANISE"
    },
    {
      "code": "09096220",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--CRUSHED OR GROUND---BADIAN"
    },
    {
      "code": "09096230",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--CRUSHED OR GROUND---CARAWAY OR FENNEL"
    },
    {
      "code": "09096240",
      "description": "SEEDS OF ANISE,BADIAN,FENNEL, CORIANDER, CUMIN OR CARAWAY; JUNIPER BERRIES-SEEDS OF ANISE, BADIAN, CARAWAY OR FENNEL; JUNIPER BERRIES--CRUSHED OR GROUND---JUNIPER BERRIES"
    },
    {
      "code": "0910",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--NEITHER CRUSHED NOR GROUND---FRESH"
    },
    {
      "code": "091010",
      "description": "Ginger"
    },
    {
      "code": "091011",
      "description": "Ginger Neither crushed nor ground"
    },
    {
      "code": "09101110",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--NEITHER CRUSHED NOR GROUND---FRESH"
    },
    {
      "code": "09101120",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--NEITHER CRUSHED NOR GROUND---DRIED, UNBLEACHED"
    },
    {
      "code": "09101130",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--NEITHER CRUSHED NOR GROUND---DRIED, BLEACHED"
    },
    {
      "code": "09101190",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--NEITHER CRUSHED NOR GROUND---OTHER"
    },
    {
      "code": "091012",
      "description": "Crushed or ground"
    },
    {
      "code": "09101210",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--CRUSHED OR GROUND---POWDER"
    },
    {
      "code": "09101290",
      "description": "GINGER, SAFFRON, TURMERIC(CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES-GINGER--CRUSHED OR GROUND---OTHER"
    },
    {
      "code": "091020",
      "description": "Saffron"
    },
    {
      "code": "09102010",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Saffron : Saffron stigma"
    },
    {
      "code": "09102020",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Saffron : Saffron stamen"
    },
    {
      "code": "09102090",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Saffron : Other"
    },
    {
      "code": "091030",
      "description": "Turmeric (Curcuma)"
    },
    {
      "code": "09103010",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Turmeric (Curcuma) : Fresh"
    },
    {
      "code": "09103020",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Turmeric (Curcuma) : Dried"
    },
    {
      "code": "09103030",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Turmeric (Curcuma) : Powder"
    },
    {
      "code": "09103090",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Turmeric (Curcuma) : Other"
    },
    {
      "code": "09104010",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES THYME; BAY `LEAVES : TEJPAT (LEAVES OF CASSIA LIGNEA)"
    },
    {
      "code": "09104020",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES THYME; BAY LEAVES : THYMES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "09104030",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES THYME; BAY LEAVES : BAY LEAVES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "09105000",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES CURRY"
    },
    {
      "code": "09109100",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Mixtures referred to in Note 1(b) to this Chapter"
    },
    {
      "code": "091099",
      "description": "Other"
    },
    {
      "code": "09109911",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Celery"
    },
    {
      "code": "09109912",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Fenugreek"
    },
    {
      "code": "09109913",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Dill"
    },
    {
      "code": "09109914",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Ajwain"
    },
    {
      "code": "09109915",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Cassia torea"
    },
    {
      "code": "09109919",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Seed : Other"
    },
    {
      "code": "09109921",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Cassia"
    },
    {
      "code": "09109923",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Celery"
    },
    {
      "code": "09109924",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Fenugreek"
    },
    {
      "code": "09109925",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Dill"
    },
    {
      "code": "09109926",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Poppy"
    },
    {
      "code": "09109927",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Mustard"
    },
    {
      "code": "09109929",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Powder : Other"
    },
    {
      "code": "09109939",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Husk : Other"
    },
    {
      "code": "09109990",
      "description": "GINGER, SAFFRON, TURMERIC (CURCUMA), THYME, BAY LEAVES, CURRY AND OTHER SPICES Other spices : Other : Other"
    },
    {
      "code": "10",
      "description": "Cereals"
    },
    {
      "code": "1001",
      "description": "WHEAT AND MESLIN - Durum wheat :"
    },
    {
      "code": "100110",
      "description": "Durum wheat"
    },
    {
      "code": "10011010",
      "description": "WHEAT AND MESLIN DURUM WHEAT : OF SEED QUALITY"
    },
    {
      "code": "10011090",
      "description": "WHEAT AND MESLIN DURUM WHEAT : OTHER"
    },
    {
      "code": "100111",
      "description": "Durum Wheat"
    },
    {
      "code": "10011100",
      "description": "seed"
    },
    {
      "code": "100119",
      "description": "other"
    },
    {
      "code": "10011900",
      "description": "other"
    },
    {
      "code": "100190",
      "description": "Other"
    },
    {
      "code": "10019010",
      "description": "WHEAT AND MESLIN OTHER : WHEAT OF SEED QUALITY"
    },
    {
      "code": "10019020",
      "description": "WHEAT AND MESLIN OTHER : OTHER WHEAT"
    },
    {
      "code": "10019031",
      "description": "WHEAT AND MESLIN OTHER : MESLIN : OF SEED QUALITY"
    },
    {
      "code": "10019039",
      "description": "WHEAT AND MESLIN OTHER : MESLIN : OTHER"
    },
    {
      "code": "100191",
      "description": "other"
    },
    {
      "code": "10019100",
      "description": "seed"
    },
    {
      "code": "100199",
      "description": "other"
    },
    {
      "code": "10019910",
      "description": "wheat"
    },
    {
      "code": "10019920",
      "description": "Meslin"
    },
    {
      "code": "1002",
      "description": "RYE"
    },
    {
      "code": "100200",
      "description": "Rye"
    },
    {
      "code": "10020010",
      "description": "RYE RYE : OF SEED QUALITY"
    },
    {
      "code": "10020090",
      "description": "RYE RYE : OTHER"
    },
    {
      "code": "100210",
      "description": "rye seed"
    },
    {
      "code": "10021000",
      "description": "Seed"
    },
    {
      "code": "100290",
      "description": "Other"
    },
    {
      "code": "10029000",
      "description": "Other"
    },
    {
      "code": "1003",
      "description": "BARLEY"
    },
    {
      "code": "100300",
      "description": "Barley"
    },
    {
      "code": "10030010",
      "description": "BARLEY BARLEY : OF SEED QUALITY"
    },
    {
      "code": "10030090",
      "description": "BARLEY BARLEY : OTHER"
    },
    {
      "code": "100310",
      "description": "seed"
    },
    {
      "code": "10031000",
      "description": "Seed"
    },
    {
      "code": "100390",
      "description": "Other"
    },
    {
      "code": "10039000",
      "description": "Other"
    },
    {
      "code": "1004",
      "description": "OATS"
    },
    {
      "code": "100400",
      "description": "Oats"
    },
    {
      "code": "10040010",
      "description": "OATS OATS : OF SEED QUALITY"
    },
    {
      "code": "10040090",
      "description": "OATS OATS : OTHER"
    },
    {
      "code": "100410",
      "description": "Oats seed"
    },
    {
      "code": "10041000",
      "description": "Seed"
    },
    {
      "code": "100490",
      "description": "Other"
    },
    {
      "code": "10049000",
      "description": "Other"
    },
    {
      "code": "1005",
      "description": "MAIZE (CORN)"
    },
    {
      "code": "10051000",
      "description": "MAIZE (CORN) SEED"
    },
    {
      "code": "100590",
      "description": "other"
    },
    {
      "code": "10059000",
      "description": "MAIZE (CORN)OTHER"
    },
    {
      "code": "10059011",
      "description": "Yellow"
    },
    {
      "code": "10059019",
      "description": "other"
    },
    {
      "code": "10059020",
      "description": "Flint corn (Zeamays var. Indurata)"
    },
    {
      "code": "10059030",
      "description": "Popcorn (Zea mays ver,everta)"
    },
    {
      "code": "10059090",
      "description": "Other"
    },
    {
      "code": "1006",
      "description": "RICE"
    },
    {
      "code": "100610",
      "description": "Rice in the husk (paddy or rough)"
    },
    {
      "code": "10061010",
      "description": "RICE RICE IN THE HUSK (PADDY OR ROUGH) : OF SEED QUALITY"
    },
    {
      "code": "10061090",
      "description": "RICE RICE IN THE HUSK (PADDY OR ROUGH) : OTHER"
    },
    {
      "code": "10062000",
      "description": "RICE HUSKED (BROWN) RICE"
    },
    {
      "code": "100630",
      "description": "Semi-milled or wholly-milled rice, whether or not polished or glazed"
    },
    {
      "code": "10063010",
      "description": "RICE SEM-MILLED OR WHOLLY-MILLED RICE, WHETHER OR NOT POLISHED OR GLAZED : RICE, PARBOILED"
    },
    {
      "code": "10063020",
      "description": "RICE SEMI-MILLED OR WHOLLY-MILLED RICE, WHETHER OR NOT POLISHED OR GLAZED : BASMATI RICE"
    },
    {
      "code": "10063090",
      "description": "RICE SEMI-MILLED OR WHOLLY-MILLED RICE, WHETHER OR NOT POLISHED OR GLAZED : OTHER"
    },
    {
      "code": "10064000",
      "description": "RICE BROKEN RICE"
    },
    {
      "code": "1007",
      "description": "GRAIN SORGHUM"
    },
    {
      "code": "100700",
      "description": "Grain sorghum"
    },
    {
      "code": "10070010",
      "description": "GRAIN SORGHUM GRAIN SORGHUM : OF SEED QUALITY"
    },
    {
      "code": "10070090",
      "description": "GRAIN SORGHUM GRAIN SORGHUM : OTHER"
    },
    {
      "code": "100710",
      "description": "Seed"
    },
    {
      "code": "10071000",
      "description": "Seed"
    },
    {
      "code": "100790",
      "description": "Other"
    },
    {
      "code": "10079000",
      "description": "Other"
    },
    {
      "code": "1008",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS"
    },
    {
      "code": "100810",
      "description": "Buckwheat"
    },
    {
      "code": "10081010",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS BUCKWHEAT : OF SEED QUALITY"
    },
    {
      "code": "10081090",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS BUCKWHEAT : OTHER"
    },
    {
      "code": "100820",
      "description": "Millet"
    },
    {
      "code": "10082011",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : JAWAR : OF SEED QUALITY"
    },
    {
      "code": "10082019",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : JAWAR : OTHER"
    },
    {
      "code": "10082021",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : BAJRA : OF SEED QUALITY"
    },
    {
      "code": "10082029",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : BAJRA : OTHER"
    },
    {
      "code": "10082031",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : RAGI (FINGER MILLET) : OF SEED QUALITY"
    },
    {
      "code": "10082039",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS MILLET : RAGI (FINGER MILLET) : OTHER"
    },
    {
      "code": "100821",
      "description": "Millet Seed"
    },
    {
      "code": "10082110",
      "description": "Jawar"
    },
    {
      "code": "10082120",
      "description": "Bajra"
    },
    {
      "code": "10082130",
      "description": "Ragi"
    },
    {
      "code": "100829",
      "description": "Other"
    },
    {
      "code": "10082910",
      "description": "Jawar"
    },
    {
      "code": "10082920",
      "description": "Bajra"
    },
    {
      "code": "10082930",
      "description": "Ragi"
    },
    {
      "code": "100830",
      "description": "Canary seed"
    },
    {
      "code": "10083010",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS CANARY SEED : OF SEED QUALITY"
    },
    {
      "code": "10083090",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS CANARY SEED : OTHER"
    },
    {
      "code": "100840",
      "description": "Fonio"
    },
    {
      "code": "10084000",
      "description": "Fonio"
    },
    {
      "code": "100850",
      "description": "Quinoa"
    },
    {
      "code": "10085000",
      "description": "Quinoa (Chenopodium quinoa)"
    },
    {
      "code": "100860",
      "description": "Triticale"
    },
    {
      "code": "10086000",
      "description": "Triticale"
    },
    {
      "code": "100890",
      "description": "Other cereals"
    },
    {
      "code": "10089010",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS OTHER CEREALS : OF SEED QUALITY"
    },
    {
      "code": "10089090",
      "description": "BUCKWHEAT, MILLET AND CANARY SEED; OTHER CEREALS OTHER CEREALS : OTHER"
    },
    {
      "code": "1009",
      "description": "other"
    },
    {
      "code": "10091090",
      "description": "other"
    },
    {
      "code": "11",
      "description": "Products of the milling industry; malt; starches; inulin; wheat gluten"
    },
    {
      "code": "1101",
      "description": "WHEAT OR MESLIN FLOUR"
    },
    {
      "code": "11010000",
      "description": "WHEAT OR MESLIN FLOUR"
    },
    {
      "code": "1102",
      "description": "CEREAL FLOURS OTHER THAN THAT OF WHEAT OR MESLIN"
    },
    {
      "code": "11021000",
      "description": "CEREAL FLOURS OTHER THAN THAT OF WHEAT OR MESLIN RYE FLOUR"
    },
    {
      "code": "11022000",
      "description": "CEREAL FLOURS OTHER THAN THAT OF WHEAT OR MESLIN MAIZE (CORN) FLOUR"
    },
    {
      "code": "11023000",
      "description": "CEREAL FLOURS OTHER THAN THAT OF WHEAT OR MESLIN RICE FLOUR"
    },
    {
      "code": "110290",
      "description": "other"
    },
    {
      "code": "11029000",
      "description": "CEREAL FLOURS OTHER THAN THAT OF WHEAT OR MESLIN OTHER"
    },
    {
      "code": "11029021",
      "description": "Brown rice flour"
    },
    {
      "code": "11029022",
      "description": "white rice flour"
    },
    {
      "code": "11029029",
      "description": "other"
    },
    {
      "code": "11029090",
      "description": "Other"
    },
    {
      "code": "1103",
      "description": "CEREAL GROATS, MEAL AND PELLETS"
    },
    {
      "code": "110311",
      "description": "Of wheat"
    },
    {
      "code": "11031110",
      "description": "CEREAL GROATS, MEAL AND PELLETS GROATS AND MEAL : OF WHEAT : GROAT"
    },
    {
      "code": "11031120",
      "description": "CEREAL GROATS, MEAL AND PELLETS GROATS AND MEAL : OF WHEAT : MEAL"
    },
    {
      "code": "11031300",
      "description": "CEREAL GROATS, MEAL AND PELLETS GROATS AND MEAL : OF MAIZE (CORN)"
    },
    {
      "code": "11031900",
      "description": "CEREAL GROATS, MEAL AND PELLETS GROATS AND MEAL : OF OTHER CEREALS"
    },
    {
      "code": "11032000",
      "description": "CEREAL GROATS, MEAL AND PELLETS PELLETS"
    },
    {
      "code": "1104",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND"
    },
    {
      "code": "11041200",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND ROLLED OR FLAKED GRAINS : OF OATS"
    },
    {
      "code": "11041900",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND ROLLED OR FLAKED GRAINS : OF OTHER CEREALS"
    },
    {
      "code": "11042200",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND OTHER WORKED GRAINS (FOR EXAMPLE, HULLED, PEARLED, SLICED OR KIBBLED) : OF OATS"
    },
    {
      "code": "11042300",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND OTHER WORKED GRAINS (FOR EXAMPLE, HULLED, PEARLED, SLICED OR KIBBLED) : OF MAIZE (CORN)"
    },
    {
      "code": "11042900",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND OTHER WORKED GRAINS (FOR EXAMPLE, HULLED, PEARLED, SLICED OR KIBBLED) : OF OTHER CEREALS"
    },
    {
      "code": "11043000",
      "description": "CEREAL GRAINS OTHERWISE WORKED (FOR EXAMPLE, HULLED, ROLLED, FLAKED, PEARLED, SLICED, OR KIBBLED), EXCEPT RICE OF HEADING 1006; GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND GERM OF CEREALS, WHOLE, ROLLED, FLAKED OR GROUND"
    },
    {
      "code": "1105",
      "description": "FLOUR, MEAL, POWDER, FLAKES, GRANULES AND PELLETS OF POTATOES"
    },
    {
      "code": "11051000",
      "description": "FLOUR, MEAL, POWDER, FLAKES, GRANULES AND PELLETS OF POTATOES FLOUR, MEAL AND POWDER"
    },
    {
      "code": "11052000",
      "description": "FLOUR, MEAL, POWDER, FLAKES, GRANULES AND PELLETS OF POTATOES FLAKES, GRANULES AND PELLETS"
    },
    {
      "code": "1106",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8"
    },
    {
      "code": "110610",
      "description": "Of the dried leguminous vegetables of heading 0713"
    },
    {
      "code": "11061000",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713"
    },
    {
      "code": "11061010",
      "description": "Guar Meal"
    },
    {
      "code": "11061090",
      "description": "Others"
    },
    {
      "code": "110620",
      "description": "Of sago or of roots or tubers of heading 0714"
    },
    {
      "code": "11062010",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 : OF SAGO"
    },
    {
      "code": "11062020",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 : OF MANIOC (CASSAVA)"
    },
    {
      "code": "11062090",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 : OF OTHER ROOTS AND TUBERS"
    },
    {
      "code": "110630",
      "description": "Of the products of Chapter 8"
    },
    {
      "code": "11063010",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF THE PRODUCTS OF CHAPTER 8 : OF TAMARIND"
    },
    {
      "code": "11063020",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF THE PRODUCTS OF CHAPTER 8 : OF SINGODA"
    },
    {
      "code": "11063030",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF THE PRODUCTS OF CHAPTER 8 : MANGO FLOUR"
    },
    {
      "code": "11063090",
      "description": "FLOUR, MEAL AND POWDER OF THE DRIED LEGUMINOUS VEGETABLES OF HEADING 0713, OF SAGO OR OF ROOTS OR TUBERS OF HEADING 0714 OR OF THE PRODUCTS OF CHAPTER 8 OF THE PRODUCTS OF CHAPTER 8 : OTHER"
    },
    {
      "code": "1107",
      "description": "MALT, WHETHER OR NOT ROASTED"
    },
    {
      "code": "11071000",
      "description": "MALT, WHETHER OR NOT ROASTED NOT ROASTED"
    },
    {
      "code": "11072000",
      "description": "MALT, WHETHER OR NOT ROASTED ROASTED"
    },
    {
      "code": "1108",
      "description": "STARCHES; INULIN"
    },
    {
      "code": "11081100",
      "description": "STARCHES; INULIN STARCHES : WHEAT STARCH"
    },
    {
      "code": "11081200",
      "description": "STARCHES; INULIN STARCHES : MAIZE (CORN) STARCH"
    },
    {
      "code": "11081300",
      "description": "STARCHES; INULIN STARCHES : POTATO STARCH"
    },
    {
      "code": "11081400",
      "description": "STARCHES; INULIN STARCHES : MANIOC (CASSAVA) STARCH"
    },
    {
      "code": "110819",
      "description": "Other"
    },
    {
      "code": "11081910",
      "description": "STARCHES; INULIN STARCHES : OTHER : SAGO"
    },
    {
      "code": "11081990",
      "description": "STARCHES; INULIN STARCHES : OTHER : OTHER"
    },
    {
      "code": "11082000",
      "description": "STARCHES; INULIN INSULIN"
    },
    {
      "code": "1109",
      "description": "WHEAT GLUTEN, WHETHER OR NOT DRIED"
    },
    {
      "code": "11090000",
      "description": "WHEAT GLUTEN, WHETHER OR NOT DRIED"
    },
    {
      "code": "1111",
      "description": "AKASH test"
    },
    {
      "code": "12",
      "description": "Oil seeds and oleaginous fruits, miscellaneous grains, seeds and fruit; industrial or medicinal plants; straw and fodder"
    },
    {
      "code": "1201",
      "description": "SOYA BEANS, WHETHER OR NOT BROKEN"
    },
    {
      "code": "120100",
      "description": "Soya beans, whether or not broken"
    },
    {
      "code": "12010010",
      "description": "SOYA BEANS, WHETHER OR NOT BROKEN SOYA BEANS, WHETHER OR NOT BROKEN : OF SEED QUALITY"
    },
    {
      "code": "12010090",
      "description": "SOYA BEANS, WHETHER OR NOT BROKEN SOYA BEANS, WHETHER OR NOT BROKEN : OTHER"
    },
    {
      "code": "120110",
      "description": "seed"
    },
    {
      "code": "12011000",
      "description": "seed"
    },
    {
      "code": "120190",
      "description": "other"
    },
    {
      "code": "12019000",
      "description": "Other"
    },
    {
      "code": "1202",
      "description": "GROUND-NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN"
    },
    {
      "code": "120210",
      "description": "In shell"
    },
    {
      "code": "12021011",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN IN SHELL : H.P.S : OF SEED QUALITY"
    },
    {
      "code": "12021019",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN IN SHELL : H.P.S : OTHER"
    },
    {
      "code": "12021091",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN IN SHELL : OTHER : OF SEED QUALITY"
    },
    {
      "code": "12021099",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN IN SHELL : OTHER : OTHER"
    },
    {
      "code": "120220",
      "description": "Shelled, whether or not broken"
    },
    {
      "code": "12022010",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN SHELLED, WHETHER OR NOT BROKEN : KERNELS, H.P.S."
    },
    {
      "code": "12022090",
      "description": "GROUND?NUTS, NOT ROASTED OR OTHERWISE COOKED, WHETHER OR NOT SHELLED OR BROKEN SHELLED, WHETHER OR NOT BROKEN : OTHER"
    },
    {
      "code": "120230",
      "description": "seed"
    },
    {
      "code": "12023010",
      "description": "H.P.S."
    },
    {
      "code": "12023090",
      "description": "Other"
    },
    {
      "code": "120241",
      "description": "In shell"
    },
    {
      "code": "12024110",
      "description": "H.P.S."
    },
    {
      "code": "12024190",
      "description": "Other"
    },
    {
      "code": "120242",
      "description": "Shelled, whether or not broken"
    },
    {
      "code": "12024210",
      "description": "Kernels, H.P.S"
    },
    {
      "code": "12024220",
      "description": "Kernelgs, Other"
    },
    {
      "code": "12024290",
      "description": "Other"
    },
    {
      "code": "1203",
      "description": "COPRA"
    },
    {
      "code": "12030000",
      "description": "COPRA"
    },
    {
      "code": "1204",
      "description": "LINSEED, WHETHER OR NOT BROKEN"
    },
    {
      "code": "120400",
      "description": "Linseed, whether or not broken"
    },
    {
      "code": "12040010",
      "description": "LINSEED, WHETHER OR NOT BROKEN LINSEED, WHETHER OR NOT BROKEN : OF SEED QUALITY"
    },
    {
      "code": "12040090",
      "description": "LINSEED, WHETHER OR NOT BROKEN LINSEED, WHETHER OR NOT BROKEN : OTHER"
    },
    {
      "code": "1205",
      "description": "RAPE OR COLZA SEEDS, WHETHER OR NOT BROKEN"
    },
    {
      "code": "12051000",
      "description": "RAPE OR COLZA SEEDS, WHETHER OR NOT BROKEN LOW ERUCIC ACID RAPE OR COLZA SEEDS"
    },
    {
      "code": "12059000",
      "description": "RAPE OR COLZA SEEDS, WHETHER OR NOT BROKEN OTHER"
    },
    {
      "code": "1206",
      "description": "SUNFLOWER SEEDS, WHETHER OR NOT BROKEN"
    },
    {
      "code": "120600",
      "description": "Sunflower seeds, whether or not broken"
    },
    {
      "code": "12060010",
      "description": "SUNFLOWER SEEDS, WHETHER OR NOT BROKEN SUNFLOWER SEEDS, WHETHER OR NOT BROKEN : OF SEED QUALITY"
    },
    {
      "code": "12060090",
      "description": "SUNFLOWER SEEDS, WHETHER OR NOT BROKEN SUNFLOWER SEEDS, WHETHER OR NOT BROKEN : OTHER"
    },
    {
      "code": "1207",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN"
    },
    {
      "code": "120710",
      "description": "Palm nuts and kernels"
    },
    {
      "code": "12071010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN PALM NUTS AND KERNELS : OF SEED QUALITY"
    },
    {
      "code": "12071090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN PALM NUTS AND KERNELS : OTHER"
    },
    {
      "code": "120720",
      "description": "Cotton seeds"
    },
    {
      "code": "12072010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN COTTON SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12072090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN COTTON SEEDS : OTHER"
    },
    {
      "code": "120721",
      "description": "Cotton seeds"
    },
    {
      "code": "12072100",
      "description": "Seed"
    },
    {
      "code": "120729",
      "description": "Other"
    },
    {
      "code": "12072900",
      "description": "Other"
    },
    {
      "code": "120730",
      "description": "Castor Oil seeds"
    },
    {
      "code": "12073010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN CASTOR OIL SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12073090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN CASTOR OIL SEEDS : OTHER"
    },
    {
      "code": "120740",
      "description": "Sesamum seeds"
    },
    {
      "code": "12074010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN SESAMUM SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12074090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN SESAMUM SEEDS : OTHER"
    },
    {
      "code": "120750",
      "description": "Mustard seeds"
    },
    {
      "code": "12075010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN MUSTARD SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12075090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN MUSTARD SEEDS : OTHER"
    },
    {
      "code": "120760",
      "description": "Safflower (Carthamus tinctorius) seeds"
    },
    {
      "code": "12076010",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN SAFFLOWER SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12076090",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN SAFFLOWER SEEDS : OTHER"
    },
    {
      "code": "120770",
      "description": "Melon seeds"
    },
    {
      "code": "12077010",
      "description": "Of seed quality"
    },
    {
      "code": "12077090",
      "description": "Other"
    },
    {
      "code": "12079100",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN OTHER : POPPY SEEDS"
    },
    {
      "code": "120799",
      "description": "Other"
    },
    {
      "code": "12079910",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN OTHER : OTHER : AJAMS"
    },
    {
      "code": "12079920",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN OTHER : OTHER : MANGO KERNEL"
    },
    {
      "code": "12079930",
      "description": "OTHER OIL SEEDS AND OLEAGINO FRUITS, WHETHER OR NOT BROKEN OTHER : OTHER : NIGER SEED"
    },
    {
      "code": "12079940",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN OTHER : OTHER : KOKAM"
    },
    {
      "code": "12079990",
      "description": "OTHER OIL SEEDS AND OLEAGINOUS FRUITS, WHETHER OR NOT BROKEN OTHER : OTHER : OTHER"
    },
    {
      "code": "1208",
      "description": "FLOURS AND MEALS OF OIL SEEDS OR OLEAGINOUS FRUITS, OTHER THAN THOSE OF MUSTARD"
    },
    {
      "code": "12081000",
      "description": "FLOURS AND MEALS OF OIL SEEDS OR OLEAGINOUS FRUITS, OTHER THAN THOSE OF MUSTARD OF SOYA BEANS"
    },
    {
      "code": "12089000",
      "description": "FLOURS AND MEALS OF OIL SEEDS OR OLEAGINOUS FRUITS, OTHER THAN THOSE OF MUSTARD OTHER"
    },
    {
      "code": "1209",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING"
    },
    {
      "code": "12091000",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SUGAR BEET SEED"
    },
    {
      "code": "12092100",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : LUCERNE (ALFALFA) SEED"
    },
    {
      "code": "12092200",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : CLOVER (TRIFOLIUM SPP.) SEED"
    },
    {
      "code": "12092300",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : FESCUE SEED"
    },
    {
      "code": "12092400",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : KENTUCKY BLUE GRASS (POA PRATENSIS L.) SEED"
    },
    {
      "code": "12092500",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : RYE GRASS (LOLIUM MULTIFLORUM LAM., LOLIUM PERENNE L.) SEED"
    },
    {
      "code": "12092600",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : TIMOTHY GRASS SEED"
    },
    {
      "code": "120929",
      "description": "Other"
    },
    {
      "code": "12092910",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : OTHER : AUSTRALIAN LUPIN SEEDS"
    },
    {
      "code": "12092990",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF FORAGE PLANTS : OTHER : OTHER"
    },
    {
      "code": "12093000",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING SEEDS OF HERBACEOUS PLANTS CULTIVATED PRINCIPALLY FOR THEIR FLOWERS"
    },
    {
      "code": "120991",
      "description": "Vegetable seeds"
    },
    {
      "code": "12099110",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OF CABBAGE"
    },
    {
      "code": "12099120",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS OF CAULIFLOWER"
    },
    {
      "code": "12099130",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OF ONION"
    },
    {
      "code": "12099140",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OF PEA"
    },
    {
      "code": "12099150",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OF RADISH"
    },
    {
      "code": "12099160",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OF TOMATO"
    },
    {
      "code": "12099170",
      "description": "OF CHILLY OF GENUS CAPSICUM"
    },
    {
      "code": "12099190",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : VEGETABLE SEEDS : OTHER"
    },
    {
      "code": "120999",
      "description": "Other"
    },
    {
      "code": "12099910",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : OTHER : FRUIT SEEDS FOR PLANTING OR SOWING"
    },
    {
      "code": "12099990",
      "description": "SEEDS, FRUIT AND SPORES, OF A KIND USED FOR SOWING OTHER : OTHER : OTHER"
    },
    {
      "code": "1210",
      "description": "HOP CONES, FRESH OR DRIED, WHETHER OR NOT GROUND, POWDERED OR IN THE FORM OF PELLETS; LUPULIN"
    },
    {
      "code": "12101000",
      "description": "HOP CONES, FRESH OR DRIED, WHETHER OR NOT GROUND, POWDERED OR IN THE FORM OF PELLETS; LUPULIN HOP CONES, NEITHER GROUND NOR POWDERED NOR IN THE FORM OF PELLETS"
    },
    {
      "code": "12102000",
      "description": "HOP CONES, FRESH OR DRIED, WHETHER OR NOT GROUND, POWDERED OR IN THE FORM OF PELLETS; LUPULIN HOP CONES, GROUND, POWDERED OR IN THE FORM OF PELLETS; LUPULIN"
    },
    {
      "code": "1211",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED"
    },
    {
      "code": "12111000",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED LIQUORICE ROOTS"
    },
    {
      "code": "12112000",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED GINSENG ROOTS"
    },
    {
      "code": "12113000",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED COCA LEAF"
    },
    {
      "code": "12114000",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED POPPY STRAW"
    },
    {
      "code": "12115000",
      "description": "Ephedra"
    },
    {
      "code": "121190",
      "description": "Other"
    },
    {
      "code": "12119011",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : AMBRETTE SEEDS"
    },
    {
      "code": "12119012",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : NUXVOMICA, DRIED RIPE SEEDS"
    },
    {
      "code": "12119013",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : PSYLLIUM SEED (ISOBGUL)"
    },
    {
      "code": "12119014",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : NEEM SEED"
    },
    {
      "code": "12119015",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : JOJOBA SEED"
    },
    {
      "code": "12119019",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SEEDS : OTHER"
    },
    {
      "code": "12119021",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : BELLADONA LEAVES"
    },
    {
      "code": "12119022",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : SENNA LEAVES AND PODS"
    },
    {
      "code": "12119023",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : NEEM LEAVES, POWDER"
    },
    {
      "code": "12119024",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : GYMNEMA POWDER"
    },
    {
      "code": "12119025",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : CUBEB POWDER"
    },
    {
      "code": "12119026",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : PYRETHRUM"
    },
    {
      "code": "12119029",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : LEAVES, POWDER, FLOWERS AND PODS : OTHER"
    },
    {
      "code": "12119031",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : BARK, HUSK AND RIND : CASCARA SAGRADA BARK"
    },
    {
      "code": "12119032",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : BARK, HUSK AND RIND : PSYLLIUM HUSK (ISOBGUL HUSK)"
    },
    {
      "code": "12119033",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : BARK, HUSK AND RIND : GAMBOGE FRUIT RIND"
    },
    {
      "code": "12119039",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : BARK, HUSK AND RIND : OTHER"
    },
    {
      "code": "12119041",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : BELLADONA ROOTS"
    },
    {
      "code": "12119042",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : GALANGAL RHIZOMES AND ROOTS"
    },
    {
      "code": "12119043",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : IPECAC DRIED RHIZOME AND ROOTS"
    },
    {
      "code": "12119044",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : SERPENTINA ROOTS (ROWWALFIA SERPENTINA AND OTHER SPECIES OF ROWWALFIAS)"
    },
    {
      "code": "12119045",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : ZEDOVARY ROOTS"
    },
    {
      "code": "12119046",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : KUTH ROOT"
    },
    {
      "code": "12119047",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : SARASAPARILLA ROOTS"
    },
    {
      "code": "12119048",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : SWEET FLAG RHIZOMES"
    },
    {
      "code": "12119049",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : ROOTS AND RHIZOMES : OTHER"
    },
    {
      "code": "12119050",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : SANDALWOOD CHIPS AND DUST"
    },
    {
      "code": "12119060",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : VINCA ROSEA HERBS"
    },
    {
      "code": "12119070",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : MINT INCLUDING LEAVES (ALL SPECIES)"
    },
    {
      "code": "12119080",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : AGARWOOD"
    },
    {
      "code": "12119091",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : CHIRATA"
    },
    {
      "code": "12119092",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : TUKMARIA"
    },
    {
      "code": "12119093",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : UNAB (INDIAN JUJUBA OR CHINESE DATES)"
    },
    {
      "code": "12119094",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : BASIL, HYSSOP, ROSEMARY, SAGE AND SAVORY"
    },
    {
      "code": "12119095",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : LOVAGE"
    },
    {
      "code": "12119096",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : GARCENIA"
    },
    {
      "code": "12119099",
      "description": "PLANTS AND PARTS OF PLANTS (INCLUDING SEEDS AND FRUITS), OF A KIND USED PRIMARILY IN PERFUMERY, IN PHARMACY OR FOR INSECTICIDAL, FUNGICIDAL OR SIMILAR PURPOSE, FRESH OR DRIED, WHETHER OR NOT CUT, CRUSHED OR POWDERED OTHER : OTHER : OTHER"
    },
    {
      "code": "1212",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY Ci-chorium intybus sativum) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "12121010",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED LOCUST BEANS, INCLUDING LOCUST BEAN SEEDS : OF SEED QUALITY"
    },
    {
      "code": "12121090",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED LOCUST BEANS, INCLUDING LOCUST BEAN SEEDS : OTHER"
    },
    {
      "code": "121220",
      "description": "Seaweeds and other algae"
    },
    {
      "code": "12122010",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED SEAWEEDS AND OTHER ALGAE : SEAWEEDS"
    },
    {
      "code": "12122090",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED SEAWEEDS AND OTHER ALGAE : OTHER ALGAE"
    },
    {
      "code": "121221",
      "description": "Seaweeds and other algae Fit for human consumption"
    },
    {
      "code": "12122110",
      "description": "Seaweeds"
    },
    {
      "code": "12122190",
      "description": "Other algae"
    },
    {
      "code": "121229",
      "description": "Other"
    },
    {
      "code": "12122910",
      "description": "Seaweeds"
    },
    {
      "code": "12122990",
      "description": "Other algae"
    },
    {
      "code": "12123010",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED APRICOT, PEACH (INCLUDING NECTARINE) OR PLUM STONES AND KERNELS : APRICOT KERNELS"
    },
    {
      "code": "12123090",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED APRICOT, PEACH (INCLUDING NECTARINE) OR PLUM STONES AND KERNELS : OTHER"
    },
    {
      "code": "12129100",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : SUGAR BEET"
    },
    {
      "code": "121292",
      "description": "Locust beans"
    },
    {
      "code": "12129200",
      "description": "Locus beans"
    },
    {
      "code": "121293",
      "description": "Sugar cane"
    },
    {
      "code": "12129300",
      "description": "Sugar cane"
    },
    {
      "code": "121294",
      "description": "Chicory roots"
    },
    {
      "code": "12129400",
      "description": "Chicory roots"
    },
    {
      "code": "121299",
      "description": "Other"
    },
    {
      "code": "12129910",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OTHER : KOKAM (COCUM) FLOWERS"
    },
    {
      "code": "12129920",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OTHER : MOHUA FLOWERS"
    },
    {
      "code": "12129990",
      "description": "LOCUST BEANS, SEAWEEDS AND OTHER ALGAE, SUGAR BEET AND SUGARCANE, FRESH, CHILLED, FROZEN OR DRIED, WHETHER OR NOT GROUND; FRUIT STONES AND KERNELS AND OTHER VEGETABLE PRODUCTS (INCLUDING UNROASTED CHICORY ROOTS OF THE VARIETY CI-CHORIUM INTYBUS SATIVUM) OF A KIND USED PRIMARILY FOR HUMAN CONSUMPTION, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OTHER : OTHER"
    },
    {
      "code": "1213",
      "description": "CEREAL STRAW AND HUSKS, UNPREPARED,WHETHER OR NOT CHOPPED, GROUND, PRESSED OR IN THE FORM OF PELLETS"
    },
    {
      "code": "12130000",
      "description": "CEREAL STRAW AND HUSKS, UNPREPARED, WHETHER OR NOT CHOPPED, GROUND, PRESSEDOR IN THE FORM OF PELLETS"
    },
    {
      "code": "1214",
      "description": "SWEDES, MANGOLDS, FODDER ROOTS, HAY, LUCERNE (alfalfa), CLOVER, SAINFOIN, FORAGE KALE, LUPINES, VETCHES AND SIMILAR FORAGE PRODUCTS, WHETHER OR NOT IN THE FORM OF PELLETS"
    },
    {
      "code": "12141000",
      "description": "SWEDES, MANGOLDS, FODDER ROOTS, HAY, LUCERNE (ALFALFA), CLOVER, SAINFOIN, FORAGE KALE, LUPINES, VETCHES AND SIMILAR FORAGE PRODUCTS, WHETHER OR NOT IN THE FORM OF PELLETS LUCERNE (ALFALFA) MEAL AND PELLETS"
    },
    {
      "code": "12149000",
      "description": "SWEDES, MANGOLDS, FODDER ROOTS, HAY, LUCERNE (ALFALFA), CLOVER, SAINFOIN, FORAGE KALE, LUPINES, VETCHES AND SIMILAR FORAGE PRODUCTS, WHETHER OR NOT IN THE FORM OF PELLETS OTHER"
    },
    {
      "code": "13",
      "description": "Lac; gums, resins and other vegetable saps and extracts"
    },
    {
      "code": "1301",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)"
    },
    {
      "code": "13011010",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : SHELLAC"
    },
    {
      "code": "13011020",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : SEED LAC"
    },
    {
      "code": "13011030",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : STICK LAC"
    },
    {
      "code": "13011040",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : DEWAXED AND DECOLOURISED LAC"
    },
    {
      "code": "13011050",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : BLEACHED LAC"
    },
    {
      "code": "13011060",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : GASKET LAC"
    },
    {
      "code": "13011070",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : BUTTON LAC"
    },
    {
      "code": "13011080",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : GARNET LAC"
    },
    {
      "code": "13011090",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) LAC : OTHER"
    },
    {
      "code": "13012000",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) GUM ARABIC"
    },
    {
      "code": "130190",
      "description": "Other"
    },
    {
      "code": "13019011",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : ASIAN GUM"
    },
    {
      "code": "13019012",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : AFRICAN GUM"
    },
    {
      "code": "13019013",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : ASAFOETIDA"
    },
    {
      "code": "13019014",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : BENJAMIN RAS"
    },
    {
      "code": "13019015",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : BENJAMIN COWRIE"
    },
    {
      "code": "13019016",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : KARAYA GUM (INDIAN TRAGACANTH) HASTAB"
    },
    {
      "code": "13019017",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : TRAGACANTH (ADRAGANTH)"
    },
    {
      "code": "13019018",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : STORAX"
    },
    {
      "code": "13019019",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : NATURAL GUMS : OTHER"
    },
    {
      "code": "13019021",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : RESINS : COPAL"
    },
    {
      "code": "13019022",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : RESINS : DAMMAR BATU"
    },
    {
      "code": "13019029",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : RESINS : OTHER"
    },
    {
      "code": "13019031",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : GUM RESINS : MYRRH"
    },
    {
      "code": "13019032",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : GUM RESINS : OILBANUM OR FRANKINCENSE"
    },
    {
      "code": "13019033",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : GUM RESINS : MASTIC GUM"
    },
    {
      "code": "13019034",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : GUM RESINS : XANTHIUM GUM"
    },
    {
      "code": "13019039",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : GUM RESINS : OTHER"
    },
    {
      "code": "13019041",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF SEEDS"
    },
    {
      "code": "13019042",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF FRUITS"
    },
    {
      "code": "13019043",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF LEAVES"
    },
    {
      "code": "13019044",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF SPICES"
    },
    {
      "code": "13019045",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF FLOWERS"
    },
    {
      "code": "13019046",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OF ROOTS"
    },
    {
      "code": "13019049",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS)OTHER : OLEORESINS : OTHER"
    },
    {
      "code": "13019099",
      "description": "LAC; NATURAL GUMS, RESINS, GUM-RESINS AND OLEORESINS (FOR EXAMPLE, BALSAMS) -OTHER : --- OTHER: ---- OTHER"
    },
    {
      "code": "1302",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS"
    },
    {
      "code": "13021100",
      "description": "Opium"
    },
    {
      "code": "13021200",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OF LIQUORICE"
    },
    {
      "code": "13021300",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OF HOPS"
    },
    {
      "code": "13021400",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OF PYRETHRUM OR OF THE ROOTS OF PLANTS CONTAINING ROTENONE"
    },
    {
      "code": "130219",
      "description": "Other"
    },
    {
      "code": "13021911",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF BELLADONA"
    },
    {
      "code": "13021912",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF CASCARA SAGRADA"
    },
    {
      "code": "13021913",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF NUXVOMICA"
    },
    {
      "code": "13021914",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF GINSENG (INCLUDING POWDER)"
    },
    {
      "code": "13021915",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF AGAROSE"
    },
    {
      "code": "13021916",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF NEEM"
    },
    {
      "code": "13021917",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF GYMNEMA"
    },
    {
      "code": "13021918",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OF GARACENIA OR GAMBODGE"
    },
    {
      "code": "13021919",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : EXTRACTS : OTHER"
    },
    {
      "code": "13021920",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : CASHEW SHELL LIQUID (CNSL), CRUDE"
    },
    {
      "code": "13021930",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : PURIFIED AND DISTILLED CNSL (CARDANOL)"
    },
    {
      "code": "13021990",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS VEGETABLE SAPS AND EXTRACTS : OTHER : OTHER"
    },
    {
      "code": "13022000",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS PECTIC SUBSTANCES, PECTINATES AND PECTATES"
    },
    {
      "code": "13023100",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : AGAR-AGAR"
    },
    {
      "code": "130232",
      "description": "Mucilages and thickeners, whether or not modified, derived from locust beans, locust bean seeds or guar seeds"
    },
    {
      "code": "13023210",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM LOCUST BEANS, LOCUST BEAN SEEDS OR GUAR SEEDS : GUAR MEAL"
    },
    {
      "code": "13023220",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM LOCUST BEANS, LOCUST BEAN SEEDS OR GUAR SEEDS : GUARGUM REFINED SPLIT"
    },
    {
      "code": "13023230",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM LOCUST BEANS, LOCUST BEAN SEEDS OR GUAR SEEDS : GUARGUM TREATED AND PULVERISED"
    },
    {
      "code": "13023240",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM LOCUST BEANS, LOCUST BEAN SEEDS OR GUAR SEEDS : KAPPA CARRAGEENAN"
    },
    {
      "code": "13023290",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM LOCUST BEANS, LOCUST BEAN SEEDS OR GUAR SEEDS : OTHER"
    },
    {
      "code": "13023900",
      "description": "VEGETABLE SAPS AND EXTRACTS; PECTIC SUBSTANCES, PECTINATES AND PECTATES; AGAR-AGAR AND OTHER MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS MUCILAGES AND THICKENERS, WHETHER OR NOT MODIFIED, DERIVED FROM VEGETABLE PRODUCTS : OTHER"
    },
    {
      "code": "14",
      "description": "Vegetable plaiting materials; vegetable products not elsewhere specified or included"
    },
    {
      "code": "1401",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY FOR PLAITING (FOR EXAMPLE, BAMBOOS, RATTANS, REEDS, RUSHES, OSIER, RAFFIA, CLEANED, BLEACHED OR DYED CEREAL STRAW, AND LIME BARK)"
    },
    {
      "code": "14011000",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY FOR PLAITING (FOR EXAMPLE, BAMBOOS, RATTANS, REEDS, RUSHES, OSIER, RAFFIA, CLEANED, BLEACHED OR DYED CEREAL STRAW, AND LIME BARK)BAMBOOS"
    },
    {
      "code": "14012000",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY FOR PLAITING (FOR EXAMPLE, BAMBOOS, RATTANS, REEDS, RUSHES, OSIER, RAFFIA, CLEANED, BLEACHED OR DYED CEREAL STRAW, AND LIME BARK)RATTANS"
    },
    {
      "code": "140190",
      "description": "Other"
    },
    {
      "code": "14019010",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY FOR PLAITING (FOR EXAMPLE, BAMBOOS, RATTANS, REEDS, RUSHES, OSIER, RAFFIA, CLEANED, BLEACHED OR DYED CEREAL STRAW, AND LIME BARK)OTHER : CANES"
    },
    {
      "code": "14019090",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY FOR PLAITING (FOR EXAMPLE, BAMBOOS, RATTANS, REEDS, RUSHES, OSIER, RAFFIA, CLEANED, BLEACHED OR DYED CEREAL STRAW, AND LIME BARK)OTHER : OTHER"
    },
    {
      "code": "1402",
      "description": "-"
    },
    {
      "code": "14020000",
      "description": "VEGETABLE MATERIALS OF A KIND USED PRIMARILY AS STUFFING OR AS PADDING (FOR EXAMPLE, KAPOK, VEGETABLE HAIR AND EEL?GRASS), WHETHER OR NOT PUT UP AS A LAYER WITH OR WITHOUT SUPPORTING MATERIAL"
    },
    {
      "code": "1403",
      "description": "VEGETABLE MATERIAL OF A KIND USED PRIMARILY IN BROOMS OR IN BRUSHES (FOR EXAMPLE, BROOM-CORN, PIASSAVA, COUCH-GRASS AND ISTLE), WHETHER OR NOT IN HANKS OR BUNDLES"
    },
    {
      "code": "14030010",
      "description": "VEGETABLE MATERIAL OF A KIND USED PRIMARILY IN BROOMS OR IN BRUSHES (FOR EXAMPLE, BROOM-CORN, PIASSAVA, COUCH-GRASS AND ISTLE), WHETHER OR NOT IN HANKS OR BUNDLES VEGETABLE MATERIAL OF A KIND USED PRIMARILY IN BROOMS OR IN BRUSHES (FOR EXAMPLE, BROOM-CORN,PIASSAVA, COUCH-GRASS AND ISTLE), WHETHER OR NOT IN HANKS OR BUNDLES : PALM FIBRES FOR BRUSHES"
    },
    {
      "code": "14030090",
      "description": "VEGETABLE MATERIAL OF A KIND USED PRIMARILY IN BROOMS OR IN BRUSHES (FOR EXAMPLE, BROOM-CORN, PIASSAVA, COUCH-GRASS AND ISTLE), WHETHER OR NOT IN HANKS OR BUNDLES VEGETABLE MATERIAL OF A KIND USED PRIMARILY IN BROOMS OR IN BRUSHES (FOR EXAMPLE, BROOM-CORN,PIASSAVA, COUCH-GRASS AND ISTLE), WHETHER OR NOT IN HANKS OR BUNDLES : OTHER"
    },
    {
      "code": "1404",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "14041011",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : HENNA : HENNA LEAVES"
    },
    {
      "code": "14041019",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : HENNA : HENNA POWDER"
    },
    {
      "code": "14041020",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : RED SANDALWOOD POWDER"
    },
    {
      "code": "14041030",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : SAFFLOWER (INCLUDING BASTARD SAFFRON)"
    },
    {
      "code": "14041040",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : DYEING SUBSTANCES, EXCLUDING TURMERIC"
    },
    {
      "code": "14041050",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : GALLNUTS"
    },
    {
      "code": "14041061",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : MYROBALANS : AMLA (EMBELICA LINN)"
    },
    {
      "code": "14041069",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : MYROBALANS : OTHER"
    },
    {
      "code": "14041070",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : WATTLE BARK (MIMOSA BARK)"
    },
    {
      "code": "14041090",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED RAW VEGETABLE MATERIALS OF A KIND USED PRIMARILY IN DYEING OR TANNING : OTHER TANNING SUBSTANCES"
    },
    {
      "code": "14042000",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED COTTON LINTERS"
    },
    {
      "code": "140490",
      "description": "Other"
    },
    {
      "code": "14049010",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : BIDI WRAPPER LEAVES (TENDU)"
    },
    {
      "code": "14049021",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : SOAP-NUTS : POWDER"
    },
    {
      "code": "14049029",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : SOAP-NUTS : OTHER"
    },
    {
      "code": "14049030",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : HARD SEEDS, PIPS, HULLS AND NUTS, OF A KIND USED PRIMARILY FOR CARVING"
    },
    {
      "code": "14049040",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : BETEL LEAVES"
    },
    {
      "code": "14049050",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : INDIAN KATHA"
    },
    {
      "code": "14049060",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : COCONUT SHELL, UNWORKED"
    },
    {
      "code": "14049070",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : RUDRAKSHA SEEDS"
    },
    {
      "code": "14049090",
      "description": "VEGETABLE PRODUCTS NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OTHER"
    },
    {
      "code": "15",
      "description": "Animal or vegetable fats and oils and their cleavage products; prepared edible fats; animal or vegetable waxes"
    },
    {
      "code": "1501",
      "description": "PIG FATS (INCLUDING LARD) AND POULTRY FAT, OTHER THAN THAT OF HEADING 0209 OR 1503"
    },
    {
      "code": "150110",
      "description": "Lard"
    },
    {
      "code": "15011000",
      "description": "PIG FAT (INCLUDING LARD) AND POULTRY FAT, OTHER THAN THAT OF HEADING 0209 OR 1503 - LARD"
    },
    {
      "code": "150120",
      "description": "Other pig fat"
    },
    {
      "code": "15012000",
      "description": "PIG FAT (INCLUDING LARD) AND POULTRY FAT, OTHER THAN THAT OF HEADING 0209 OR 1503-OTHER PIG FAT"
    },
    {
      "code": "150190",
      "description": "Other"
    },
    {
      "code": "15019000",
      "description": "PIG FAT (INCLUDING LARD) AND POULTRY FAT, OTHER THAN THAT OF HEADING 0209 OR 1503-OTHER"
    },
    {
      "code": "1502",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503"
    },
    {
      "code": "150200",
      "description": "Fats of Bovine animals, Sheep or Goats, other than those of heading 1503"
    },
    {
      "code": "150210",
      "description": "Tallow"
    },
    {
      "code": "15021010",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503-TALLOW---MUTTON TALLOW"
    },
    {
      "code": "15021090",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503-TALLOW---OTHER THAN MUTTON TALLOW"
    },
    {
      "code": "150290",
      "description": "Other"
    },
    {
      "code": "15029010",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503-OTHER---UNRENDERED FATS"
    },
    {
      "code": "15029020",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503-OTHER---RENDERED FATS OR SOLVENT EXTRACTION FATS"
    },
    {
      "code": "15029090",
      "description": "FATS OF BOVINE ANIMALS, SHEEP OR GOATS, OTHER THAN THOSE OF HEADING 1503-OTHER---OTHER"
    },
    {
      "code": "1503",
      "description": "LARD STEARIN, LARD OIL, OLEOSTEARIN, OLEO-OIL AND TALLOW OIL, NOT EMULSIFIED OR MIXED OR OTHERWISE PREPARED"
    },
    {
      "code": "15030000",
      "description": "LARD STEARIN, LARD OIL, OLEOSTEARIN, OLEO-OIL AND TALLOW OIL, NOT EMULSIFIED OR MIXED OR OTHERWISE PREPARED"
    },
    {
      "code": "1504",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "150410",
      "description": "Fish liver oils and their fractions"
    },
    {
      "code": "15041010",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FISH LIVER OILS AND THEIR FRACTIONS : COD LIVER OIL"
    },
    {
      "code": "15041091",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FISH LIVER OILS AND THEIR FRACTIONS : OTHER : SQUID LIVER OIL"
    },
    {
      "code": "15041099",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FISH LIVER OILS AND THEIR FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "150420",
      "description": "Fats and oils and their fractions of fish, other than liver oils"
    },
    {
      "code": "15042010",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FATS AND OILS AND THEIR FRACTIONS OF FISH, OTHER THAN LIVER OILS : FISH BODY OIL"
    },
    {
      "code": "15042020",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FATS AND OILS AND THEIR FRACTIONS OF FISH, OTHER THAN LIVER OILS : FISH LIPID OIL"
    },
    {
      "code": "15042030",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FATS AND OILS AND THEIR FRACTIONS OF FISH, OTHER THAN LIVER OILS : SPERM OIL"
    },
    {
      "code": "15042090",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FATS AND OILS AND THEIR FRACTIONS OF FISH, OTHER THAN LIVER OILS : OTHER"
    },
    {
      "code": "15043000",
      "description": "FATS AND OILS AND THEIR FRACTIONS, OF FISH OR MARINE MAMMALS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED FATS AND OILS AND THEIR FRACTIONS, OF MARINE MAMMALS"
    },
    {
      "code": "1505",
      "description": "WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN)"
    },
    {
      "code": "150500",
      "description": "Wool grease and fatty substances derived therefrom (including lanolin)"
    },
    {
      "code": "15050010",
      "description": "WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) : WOOL ALCOHOL (INCLUDING LANOLIN ALCOHOL)"
    },
    {
      "code": "15050020",
      "description": "WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) : WOOL GREASE, CRUDE"
    },
    {
      "code": "15050090",
      "description": "WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) WOOL GREASE AND FATTY SUBSTANCES DERIVED THEREFROM (INCLUDING LANOLIN) : OTHER"
    },
    {
      "code": "1506",
      "description": "OTHER ANIMAL FATS AND OILS AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "150600",
      "description": "Other animal fats and oils and their fractions, whether or not refined, but not chemically modified"
    },
    {
      "code": "15060010",
      "description": "OTHER ANIMAL FATS AND OILS AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER ANIMAL FATS AND OILS AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED : NEATS FOOT OIL AND FATS FROM BONE OR WASTE"
    },
    {
      "code": "15060090",
      "description": "OTHER ANIMAL FATS AND OILS AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER ANIMAL FATS AND OILS AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED : OTHER"
    },
    {
      "code": "1507",
      "description": "SOYA-BEAN OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15071000",
      "description": "SOYA-BEAN OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED CRUDE OIL, WHETHER OR NOT DEGUMMED"
    },
    {
      "code": "150790",
      "description": "Other"
    },
    {
      "code": "15079010",
      "description": "SOYA-BEAN OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : EDIBLE GRADE"
    },
    {
      "code": "15079090",
      "description": "SOYA-BEAN OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER"
    },
    {
      "code": "1508",
      "description": "GROUND-NUT OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15081000",
      "description": "GROUND-NUT OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED CRUDE OIL"
    },
    {
      "code": "150890",
      "description": "Other"
    },
    {
      "code": "15089010",
      "description": "GROUND-NUT OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : DEODORIZED (SALAD OIL)"
    },
    {
      "code": "15089091",
      "description": "GROUND-NUT OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15089099",
      "description": "GROUND-NUT OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : OTHER"
    },
    {
      "code": "1509",
      "description": "OLIVE OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15091000",
      "description": "OLIVE OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED VIRGIN"
    },
    {
      "code": "150990",
      "description": "Other"
    },
    {
      "code": "15099010",
      "description": "OLIVE OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : EDIBLE GRADE"
    },
    {
      "code": "15099090",
      "description": "OLIVE OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER"
    },
    {
      "code": "1510",
      "description": "OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509"
    },
    {
      "code": "151000",
      "description": "Other oils and their fractions obtained solely from olives, whether or not refined, but not chemically modified, including blends of these oils or fractions with oils or fractions of heading 1509"
    },
    {
      "code": "15100010",
      "description": "OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509 : CRUDE OIL"
    },
    {
      "code": "15100091",
      "description": "OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509 OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509 : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15100099",
      "description": "OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509 OTHER OILS AND THEIR FRACTIONS OBTAINED SOLELY FROM OLIVES, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED, INCLUDING BLENDS OF THESE OILS OR FRACTIONS WITH OILS OR FRACTIONS OF HEADING 1509 : OTHER : OTHER"
    },
    {
      "code": "1511",
      "description": "PALM OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15111000",
      "description": "PALM OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED CRUDE OIL"
    },
    {
      "code": "151190",
      "description": "Other"
    },
    {
      "code": "15119010",
      "description": "PALM OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : REFINED BLEACHED DEODORISED PALM OIL"
    },
    {
      "code": "15119020",
      "description": "PALM OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : REFINED BLEACHED DEODORISED PALMOLEIN"
    },
    {
      "code": "15119030",
      "description": "Refinded bleached deodorised palm stearin"
    },
    {
      "code": "15119090",
      "description": "PALM OIL AND ITS FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER"
    },
    {
      "code": "1512",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND THEIR FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "151211",
      "description": "Crude oil"
    },
    {
      "code": "15121110",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : CRUDE OIL : SUNFLOWER SEED OIL"
    },
    {
      "code": "15121120",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : CRUDE OIL : SAFFLOWER SEED OIL (KARDI SEED OIL)"
    },
    {
      "code": "151219",
      "description": "Other"
    },
    {
      "code": "15121910",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : OTHER : SUNFLOWER OIL, EDIBLE GRADE"
    },
    {
      "code": "15121920",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : OTHER : SUNFLOWER OIL, NON-EDIBLE GRADE (OTHER THAN CRUDE OIL)"
    },
    {
      "code": "15121930",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : OTHER : SAFFOLA OIL, EDIBLE GRADE"
    },
    {
      "code": "15121940",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : OTHER : SAFFOLA OIL, NON-EDIBLE GRADE"
    },
    {
      "code": "15121990",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SUNFLOWER SEED OR SAFFLOWER OIL AND FRACTIONS THEREOF : OTHER : OTHER"
    },
    {
      "code": "15122100",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED COTTON-SEED OIL AND ITS FRACTIONS : CRUDE OIL, WHETHER OR NOT GOSSYPOL HAS BEEN REMOVED"
    },
    {
      "code": "151229",
      "description": "Other"
    },
    {
      "code": "15122910",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED COTTON-SEED OIL AND ITS FRACTIONS : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15122990",
      "description": "SUNFLOWER SEED, SAFFLOWER OR COTTON SEED OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED COTTON-SEED OIL AND ITS FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "1513",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15131100",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED COCONUT (COPRA) OIL AND ITS FRACTIONS : CRUDE OIL"
    },
    {
      "code": "15131900",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED COCONUT (COPRA) OIL AND ITS FRACTIONS : OTHER"
    },
    {
      "code": "151321",
      "description": "Crude Oil"
    },
    {
      "code": "15132110",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : CRUDE OIL : PALM KERNEL OIL"
    },
    {
      "code": "15132120",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : CRUDE OIL : BABASSU OIL"
    },
    {
      "code": "151329",
      "description": "Other"
    },
    {
      "code": "15132910",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : OTHER : PALM KERNEL OIL AND ITS FRACTIONS"
    },
    {
      "code": "15132920",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : OTHER : BABASSU OIL AND ITS FRACTIONS EDIBLE GRADE"
    },
    {
      "code": "15132930",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : OTHER : BABASSU OIL AND ITS FRACTIONS, OTHER THAN EDIBLE GRADE"
    },
    {
      "code": "15132990",
      "description": "COCONUT (COPRA), PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED PALM KERNEL OR BABASSU OIL AND FRACTIONS THEREOF : OTHER : OTHER"
    },
    {
      "code": "1514",
      "description": "RAPE, COLZA OR MUSTARD OIL AND ITS FRACTIONS THEREOF, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "151411",
      "description": "Crude oil"
    },
    {
      "code": "15141110",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : CRUDE OIL : COLZA OIL"
    },
    {
      "code": "15141120",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : CRUDE OIL : RAPE OIL"
    },
    {
      "code": "15141190",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : CRUDE OIL : OTHER"
    },
    {
      "code": "151419",
      "description": "Other"
    },
    {
      "code": "15141910",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : OTHER : REFINED COLZA OIL OF EDIBLE GRADE"
    },
    {
      "code": "15141920",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : OTHER : REFINED RAPESEED OIL OF EDIBLE GRADE"
    },
    {
      "code": "15141990",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED LOW ERUCIC ACID RAPE OR COLZA OIL AND ITS FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "151491",
      "description": "Crude oil"
    },
    {
      "code": "15149110",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : CRUDE OIL : COLZA OIL"
    },
    {
      "code": "15149120",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : CRUDE OIL : MUSTARD OIL"
    },
    {
      "code": "15149190",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : CRUDE OIL : RAPESEED OIL"
    },
    {
      "code": "151499",
      "description": "Other"
    },
    {
      "code": "15149910",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : REFINED COLZA OIL OF EDIBLE GRADE"
    },
    {
      "code": "15149920",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : REFINED MUSTARD OIL OF EDIBLE GRADE"
    },
    {
      "code": "15149930",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : REFINED RAPESEED OIL OF EDIBLE GRADE"
    },
    {
      "code": "15149990",
      "description": "RAPE, COLZA OR MUSTARD OIL AND THEIR FRACTIONS, WHETHER OR NOT REFINED , BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : OTHER"
    },
    {
      "code": "1515",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "15151100",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED LINSEED OIL AND ITS FRACTIONS : CRUDE OIL"
    },
    {
      "code": "151519",
      "description": "Other"
    },
    {
      "code": "15151910",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED LINSEED OIL AND ITS FRACTIONS : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15151990",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED LINSEED OIL AND ITS FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "15152100",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED MAIZE (CORN) OIL AND ITS FRACTIONS : CRUDE OIL"
    },
    {
      "code": "151529",
      "description": "Other"
    },
    {
      "code": "15152910",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED MAIZE (CORN) OIL AND ITS FRACTIONS : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15152990",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED MAIZE (CORN) OIL AND ITS FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "151530",
      "description": "Castor oil and its fractions"
    },
    {
      "code": "15153010",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED CASTOR OIL AND ITS FRACTIONS : EDIBLE GRADE"
    },
    {
      "code": "15153090",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED CASTOR OIL AND ITS FRACTIONS : OTHER"
    },
    {
      "code": "15154000",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED - TUNG OIL AND ITS FRACTIONS"
    },
    {
      "code": "151550",
      "description": "Seasame oil and its fractions"
    },
    {
      "code": "15155010",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SEASAME OIL AND ITS FRACTIONS : CRUDE OIL"
    },
    {
      "code": "15155091",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SEASAME OIL AND ITS FRACTIONS : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15155099",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED SEASAME OIL AND ITS FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "151590",
      "description": "Other"
    },
    {
      "code": "15159010",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : FIXED VEGETABLE OILS, NAMELY THE FOLLOWING : CHUL MOOGRA OIL, MAWRA OIL, KOKAM OIL,TOBACCO SEED OIL, SAL OIL"
    },
    {
      "code": "15159020",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : FIXED VEGETABLE OILS, NAMELY THE FOLLOWING : NEEM SEED OIL, KARANJ OIL, SILK COTTON SEED OIL,KHAKHON OIL, WATER MELON OIL, KUSUM OIL, RUBBERSEED OIL, DHUP OIL, UNDI OIL, MAROTI OIL, PISA OIL,NAHAR OIL"
    },
    {
      "code": "15159030",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : FIXED VEGETABLE OILS, NAMELY THE FOLLOWING : CARDAMOM OIL, CHILLIES OR CAPSICUM OIL, TURMERICOIL, AJWAIN SEED OIL, NIGER SEED OIL, GARLIC OIL"
    },
    {
      "code": "15159040",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : FIXED VEGETABLE OILS OF EDIBLE GRADE NAMELY THE FOLLOWING : MANGO KERNEL OIL, MAHUA OIL, RICE BRAN OIL"
    },
    {
      "code": "15159091",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15159099",
      "description": "OTHER FIXED VEGETABLE FATS AND OILS (INCLUDING JOJOBA OIL) AND THEIR FRACTIONS, WHETHER OR NOT REFINED, BUT NOT CHEMICALLY MODIFIED OTHER : OTHER : OTHER"
    },
    {
      "code": "1516",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED"
    },
    {
      "code": "15161000",
      "description": "ANIMAL FATS AND OILS AND THEIR FRACTIONS"
    },
    {
      "code": "151620",
      "description": "Vegetable fats and oils and their fractions"
    },
    {
      "code": "15162011",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : COTTON SEED OIL : EDIBLE GRADE"
    },
    {
      "code": "15162019",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : COTTON SEED OIL : OTHER"
    },
    {
      "code": "15162021",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : GROUNDNUT OIL : EDIBLE GRADE"
    },
    {
      "code": "15162029",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : GROUNDNUT OIL : OTHER"
    },
    {
      "code": "15162031",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : HYDROGENATED CASTOR OIL (OPAL-WAX) : EDIBLE GRADE"
    },
    {
      "code": "15162039",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : HYDROGENATED CASTOR OIL (OPAL-WAX) : OTHER"
    },
    {
      "code": "15162091",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : OTHER : EDIBLE GRADE"
    },
    {
      "code": "15162099",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, PARTLY OR WHOLLY HYDROGENATED, INTER-ESTERIFIED, RE-ESTERIFIED OR ELAIDINISED, WHETHER OR NOT REFINED, BUT NOT FURTHER PREPARED VEGETABLE FATS AND OILS AND THEIR FRACTIONS : OTHER : OTHER"
    },
    {
      "code": "1517",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516"
    },
    {
      "code": "151710",
      "description": "Margarine, excluding liquid margarine"
    },
    {
      "code": "15171010",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 MARGARINE, EXCLUDING LIQUID MARGARINE : OF ANIMAL ORIGIN"
    },
    {
      "code": "15171021",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 MARGARINE, EXCLUDING LIQUID MARGARINE : OF VEGETABLE ORIGIN : EDIBLE GRADE"
    },
    {
      "code": "15171022",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 MARGARINE, EXCLUDING LIQUID MARGARINE : OF VEGETABLE ORIGIN : LINOXYN"
    },
    {
      "code": "15171029",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 MARGARINE, EXCLUDING LIQUID MARGARINE : OF VEGETABLE ORIGIN : OTHER"
    },
    {
      "code": "151790",
      "description": "Other"
    },
    {
      "code": "15179010",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 OTHER : SAL FAT (PROCESSED OR REFINED)"
    },
    {
      "code": "15179030",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 OTHER : IMITATION LARD OF ANIMAL ORIGIN"
    },
    {
      "code": "15179040",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 OTHER : IMITATION LARD OF VEGETABLE ORIGIN"
    },
    {
      "code": "15179090",
      "description": "MARGARINE; EDIBLE MIXTURE OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, OTHER THAN EDIBLE FATS OR OILS OR THEIR FRACTIONS OF HEADING 1516 OTHER : OTHER"
    },
    {
      "code": "1518",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "151800",
      "description": "Animal or vegetable fats and oils and their fractions, boiled, oxidised, dehydrated, sulphurised, blown, polymerised by heat in vacuum or in inert gas or otherwise chemically modified, excluding those of heading 1516 ; inedible mixtures or preparations of animal or vegetable fats or oils or of fractions of different fats or oils of this Chapter, not elsewhere specified or included"
    },
    {
      "code": "15180011",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : LINSEED OIL : EDIBLE GRADE"
    },
    {
      "code": "15180019",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : LINSEED OIL : OTHER"
    },
    {
      "code": "15180021",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ? ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : CASTOR OIL, DEHYDRATED : EDIBLE GRADE"
    },
    {
      "code": "15180029",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ? ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : CASTOR OIL, DEHYDRATED : OTHER"
    },
    {
      "code": "15180031",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ? ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : OTHER VEGETABLE OIL AND ITS FATS : EDIBLE GRADE"
    },
    {
      "code": "15180039",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ? ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : OTHER VEGETABLE OIL AND ITS FATS : OTHER"
    },
    {
      "code": "15180040",
      "description": "ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDISED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERISED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516 ; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED ANIMAL OR VEGETABLE FATS AND OILS AND THEIR FRACTIONS, BOILED, OXIDIZED, DEHYDRATED, SULPHURISED, BLOWN, POLYMERIZED BY HEAT IN VACUUM OR IN INERT GAS OR OTHERWISE CHEMICALLY MODIFIED, EXCLUDING THOSE OF HEADING 1516; INEDIBLE MIXTURES OR PREPARATIONS OF ANIMAL OR VEGETABLE FATS OR OILS OR OF FRACTIONS OF DIFFERENT FATS OR OILS OF THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED : OTHER"
    },
    {
      "code": "1519",
      "description": "-"
    },
    {
      "code": "1520",
      "description": "GLYCEROL, CRUDE; GLYCEROL WATERS AND GLYCEROL LYES"
    },
    {
      "code": "15200000",
      "description": "GLYCEROL, CRUDE; GLYCEROL WATERS AND GLYCEROL LYES"
    },
    {
      "code": "1521",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED"
    },
    {
      "code": "152110",
      "description": "Vegetable Waxes"
    },
    {
      "code": "15211011",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED VEGETABLE WAXES : CARNAUBA WAXES : EDIBLE WAX FOR WAXING FRESH FRUITS AND VEGETABLES"
    },
    {
      "code": "15211019",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED VEGETABLE WAXES : CARNAUBA WAXES : OTHER"
    },
    {
      "code": "15211090",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED VEGETABLE WAXES : OTHER"
    },
    {
      "code": "152190",
      "description": "Other"
    },
    {
      "code": "15219010",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED OTHER : BEEWAX WHETHER OR NOT COLOURED"
    },
    {
      "code": "15219020",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED OTHER : SHELLAC WAX WHETHER OR NOT COLOURED"
    },
    {
      "code": "15219090",
      "description": "VEGETABLE WAXES (OTHER THAN TRIGLYCERIDES), BEESWAX, OTHER INSECT WAXES AND SPERMACETI, WHETHER OR NOT REFINED OR COLOURED OTHER : OTHER"
    },
    {
      "code": "1522",
      "description": "DEGRAS: RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES"
    },
    {
      "code": "152200",
      "description": "Degras residues resulting from the treatment of fatty substances or animal or vegetable waxes :"
    },
    {
      "code": "15220010",
      "description": "DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES : DEGRAS"
    },
    {
      "code": "15220020",
      "description": "DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES : SOAP STOCKS"
    },
    {
      "code": "15220090",
      "description": "DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES DEGRAS : RESIDUES RESULTING FROM THE TREATMENT OF FATTY SUBSTANCES OR ANIMAL OR VEGETABLE WAXES : OTHER"
    },
    {
      "code": "16",
      "description": "Preparations of meat, of fish or of crustaceans, molluscs or other aquatic invertebrates"
    },
    {
      "code": "1601",
      "description": "SAUSAGES AND SIMILAR PRODUCTS, OF MEAT, MEAT OFFAL OR BLOOD; FOOD PREPARATIONS BASED ON THESE PRODUCTS"
    },
    {
      "code": "16010000",
      "description": "SAUSAGES AND SIMILAR PRODUCTS, OF MEAT, MEAT OFFAL OR BLOOD; FOOD PREPARATIONS BASED ON THESE PRODUCTS"
    },
    {
      "code": "1602",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD"
    },
    {
      "code": "16021000",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD HOMOGENISED PREPARATIONS"
    },
    {
      "code": "16022000",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF LIVER OF ANY ANIMAL"
    },
    {
      "code": "16023100",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF POULTRY OF HEADING 0105 : OF TURKEYS"
    },
    {
      "code": "16023200",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF POULTRY OF HEADING 0105 : OF FOWLS OF THE SPECIES GALLUS DOMESTICUS"
    },
    {
      "code": "16023900",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF POULTRY OF HEADING 0105 : OTHER"
    },
    {
      "code": "16024100",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF SWINE : HAMS AND CUTS THEREOF"
    },
    {
      "code": "16024200",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF SWINE : SHOULDERS AND CUTS THEREOF"
    },
    {
      "code": "16024900",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF SWINE : OTHER, INCLUDING MIXTURES"
    },
    {
      "code": "16025000",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OF BOVINE ANIMALS"
    },
    {
      "code": "16029000",
      "description": "OTHER PREPARED OR PRESERVED MEAT, MEAT OFFAL OR BLOOD OTHER, INCLUDING PREPARATIONS OF BLOOD OF ANY ANIMAL"
    },
    {
      "code": "1603",
      "description": "EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES"
    },
    {
      "code": "160300",
      "description": "Extracts and juices of meat, fish or crustaceans, molluscs or other aquatic invertebrates"
    },
    {
      "code": "16030010",
      "description": "EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : EXTRACTS AND JUICES OF MEAT"
    },
    {
      "code": "16030020",
      "description": "EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : EXTRACTS OF FISH"
    },
    {
      "code": "16030090",
      "description": "EXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATESEXTRACTS AND JUICES OF MEAT, FISH OR CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : OTHER"
    },
    {
      "code": "1604",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS"
    },
    {
      "code": "16041100",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : SALMON"
    },
    {
      "code": "160412",
      "description": "Herrings"
    },
    {
      "code": "16041210",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : HERRINGS : PICKLED"
    },
    {
      "code": "16041290",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : HERRINGS : OTHER"
    },
    {
      "code": "160413",
      "description": "Sardines, sardinella and brisling or sprats"
    },
    {
      "code": "16041310",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : SARDINES, SARDINELLA AND BRISLING OR SPRATS : SARDINES, SARDINELLA AND BRISLING"
    },
    {
      "code": "16041320",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : SARDINES, SARDINELLA AND BRISLING OR SPRATS : SPRATS"
    },
    {
      "code": "160414",
      "description": "Tunas, skipjack and bonito (Sarda spp.)"
    },
    {
      "code": "16041410",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : TUNAS, SKIPJACK AND BONITO (SARDA SPP.) : TUNAS"
    },
    {
      "code": "16041490",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : TUNAS, SKIPJACK AND BONITO (SARDA SPP.) : OTHER"
    },
    {
      "code": "16041500",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : MACKEREL"
    },
    {
      "code": "16041600",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : ANCHOVIES"
    },
    {
      "code": "160417",
      "description": "Eels"
    },
    {
      "code": "16041700",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS-- Eels"
    },
    {
      "code": "16041800",
      "description": "Shark fins"
    },
    {
      "code": "16041900",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS FISH, WHOLE OR IN PIECES, BUT NOT MINCED : OTHER"
    },
    {
      "code": "16042000",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS OTHER PREPARED OR PRESERVED FISH"
    },
    {
      "code": "160431",
      "description": "Caviar"
    },
    {
      "code": "16043100",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS-CAVIAR AND CAVIAR SUBSTITUTES -- CAVIAR"
    },
    {
      "code": "160432",
      "description": "caviar substitutes"
    },
    {
      "code": "16043200",
      "description": "PREPARED OR PRESERVED FISH; CAVIAR AND CAVIAR SUBSTITUTES PREPARED FROM FISH EGGS- CAVIAR AND CAVIAR SUBSTITUTES -- CAVIAR SUBSTITUTES"
    },
    {
      "code": "1605",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED"
    },
    {
      "code": "16051000",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED CRAB"
    },
    {
      "code": "160521",
      "description": "Shrimps and prawns Not in airtight container"
    },
    {
      "code": "16052100",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED SHRIMPS - SHRIMPS AND PRAWNS --NOT IN AIRTIGHT CONTAINER"
    },
    {
      "code": "160529",
      "description": "Other"
    },
    {
      "code": "16052900",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED SHRIMPS AND PRAWNS-SHRIMPTS AND PRAWNS -- OTHER"
    },
    {
      "code": "16053000",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED LOBSTER"
    },
    {
      "code": "16054000",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED OTHER CRUSTACEANS"
    },
    {
      "code": "160551",
      "description": "Oysters"
    },
    {
      "code": "16055100",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS -- OYSTERS"
    },
    {
      "code": "160552",
      "description": "Scallops, including queen scallops"
    },
    {
      "code": "16055200",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS-- SCALLOPS, INCLUDING QUEEN SCALLOPS"
    },
    {
      "code": "160553",
      "description": "Mussels"
    },
    {
      "code": "16055300",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS--MUSSELS"
    },
    {
      "code": "160554",
      "description": "Cuttle fish and squid"
    },
    {
      "code": "16055400",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS--CUTTLE FISH AND SQUID"
    },
    {
      "code": "160555",
      "description": "Octopus"
    },
    {
      "code": "16055500",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS- OCTOPUS"
    },
    {
      "code": "160556",
      "description": "clams, cockles and arkshells"
    },
    {
      "code": "16055600",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS-CLAMS, COCKLES AND ARKSHELLS"
    },
    {
      "code": "160557",
      "description": "Abalone"
    },
    {
      "code": "16055700",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS--ABALONE"
    },
    {
      "code": "160558",
      "description": "Snails, other than sea snails"
    },
    {
      "code": "16055800",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS--SNAILS, OTHER THAN SEA SNAILS"
    },
    {
      "code": "160559",
      "description": "Other"
    },
    {
      "code": "16055900",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED- MOLLUSCS--OTHER"
    },
    {
      "code": "160561",
      "description": "Sea cucumbers"
    },
    {
      "code": "16056100",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED-OTHER AQUATIC INVERTEBRATES--SEA CUCUMBERS"
    },
    {
      "code": "160562",
      "description": "Sea urchins"
    },
    {
      "code": "16056200",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED-OTHER AQUATIC INVERTEBRATES-- SEA URCHINS"
    },
    {
      "code": "160563",
      "description": "Jellyfish"
    },
    {
      "code": "16056300",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED-OTHER AQUATIC INVERTEBRATES--JELLYFISH"
    },
    {
      "code": "160569",
      "description": "Other"
    },
    {
      "code": "16056900",
      "description": "CRUSTACEANS, MOLLUSCS AND OTHER AQUATIC INVERTEBRATES, PREPARED OR PRESERVED-OTHER AQUATIC INVERTEBRATES--OTHER"
    },
    {
      "code": "160590",
      "description": "Other"
    },
    {
      "code": "17",
      "description": "Sugars and sugar confectionery"
    },
    {
      "code": "1701",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM"
    },
    {
      "code": "170111",
      "description": "Cane sugar"
    },
    {
      "code": "17011200",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - RAW SUGAR NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER : BEET SUGAR"
    },
    {
      "code": "170113",
      "description": "cane sugar specified in Sub-heading Note 2 to this chapter"
    },
    {
      "code": "17011310",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring matter --CANE SUGAR SPECIFIED IN SUB-HEADING NOTE 2 TO THIS CHAPTER---CANE JAGGERY"
    },
    {
      "code": "17011320",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring matter --CANE SUGAR SPECIFIED IN SUB-HEADING NOTE 2 TO THIS CHAPTER---KHANDSARI SUGAR"
    },
    {
      "code": "17011390",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring matter --CANE SUGAR SPECIFIED IN SUB-HEADING NOTE 2 TO THIS CHAPTER---OTHER"
    },
    {
      "code": "170114",
      "description": "Other cane sugar"
    },
    {
      "code": "17011410",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring matter --OTHER CANE SUGAR--- CANE JAGGERY"
    },
    {
      "code": "17011420",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring matter --OTHER CANE SUGAR ---KHANDSARI SUGAR"
    },
    {
      "code": "17011490",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - Raw sugar not containing added flavouring or colouring mater--OTHER CANE SUGAR --- OTHER"
    },
    {
      "code": "17019100",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - OTHER : REFINED SUGAR CONTAINING ADDED FLAVOURING OR COLOURING MATTER"
    },
    {
      "code": "170199",
      "description": "Other"
    },
    {
      "code": "17019910",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - OTHER : OTHER : SUGAR CUBES"
    },
    {
      "code": "17019990",
      "description": "CANE OR BEET SUGAR AND CHEMICALLY PURE SUCROSE, IN SOLID FORM - OTHER : OTHER : SUGAR CUBES"
    },
    {
      "code": "1702",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL"
    },
    {
      "code": "170211",
      "description": "Containing by weight 99% or more lactose, expressed as anhydrous lactose, calculated on the dry matter"
    },
    {
      "code": "17021110",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - LACTOSE AND LACTOSE SYRUP : - CONTAINING BY WEIGHT 99% OR MORE LACTOSE, EXPRESSED AS ANHYDROUS LACTOSE, CALCULATED ON THE DRY MATTER : IN SOLID FORM"
    },
    {
      "code": "17021190",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- LACTOSE AND LACTOSE SYRUP : - CONTAINING BY WEIGHT 99% OR MORE LACTOSE, EXPRESSED AS ANHYDROUS LACTOSE, CALCULATED ON THE DRY MATTER : OTHER"
    },
    {
      "code": "170219",
      "description": "Other"
    },
    {
      "code": "17021910",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - LACTOSE AND LACTOSE SYRUP : OTHER : IN SOLID FORM"
    },
    {
      "code": "17021990",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - LACTOSE AND LACTOSE SYRUP : OTHER : OTHER"
    },
    {
      "code": "170220",
      "description": "Maple sugar and maple syrup"
    },
    {
      "code": "17022010",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - MAPLE SUGAR AND MAPLE SYRUP : IN SOLID FORM"
    },
    {
      "code": "17022090",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - MAPLE SUGAR AND MAPLE SYRUP : OTHER"
    },
    {
      "code": "170230",
      "description": "Glucose and glucose syrup, not containing fructose or containing in the dry state less than 20% by weight of fructose"
    },
    {
      "code": "17023010",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, NOT CONTAINING FRUCTOSE OR CONTAINING IN THE DRY STATE LESS THAN 20% BY WEIGHT OF FRUCTOSE : GLUCOSE, LIQUID"
    },
    {
      "code": "17023020",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, NOT CONTAINING FRUCTOSE OR CONTAINING IN THE DRY STATE LESS THAN 20% BY WEIGHT OF FRUCTOSE : GLUCOSE, SOLID"
    },
    {
      "code": "17023031",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, NOT CONTAINING FRUCTOSE OR CONTAINING IN THE DRY STATE LESS THAN 20% BY WEIGHT OF FRUCTOSE : - DEXTROSE : IN SOLID FORM"
    },
    {
      "code": "17023039",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, NOT CONTAINING FRUCTOSE OR CONTAINING IN THE DRY STATE LESS THAN 20% BY WEIGHT OF FRUCTOSE : - DEXTROSE : OTHER"
    },
    {
      "code": "170240",
      "description": "Glucose and glucose syrup, containing in the dry state at least 20% but less than 50% by weight of fructose, excluding invert sugar"
    },
    {
      "code": "17024010",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, CONTAINING IN THE DRY STATE AT LEAST 20% BUT LESS THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : GLUCOSE, LIQUID"
    },
    {
      "code": "17024020",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, CONTAINING IN THE DRY STATE AT LEAST 20% BUT LESS THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : GLUCOSE, SOLID"
    },
    {
      "code": "17024031",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, CONTAINING IN THE DRY STATE AT LEAST 20% BUT LESS THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : DEXTROSE : IN SOLID FORM"
    },
    {
      "code": "17024039",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- GLUCOSE AND GLUCOSE SYRUP, CONTAINING IN THE DRY STATE AT LEAST 20% BUT LESS THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : DEXTROSE : OTHER"
    },
    {
      "code": "17025000",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL-CHEMICALLY PURE FRUCTOSE"
    },
    {
      "code": "170260",
      "description": "Other fructose and fructose syrup, containing in the dry state more than 50% by weight of fructose, excluding invert sugar"
    },
    {
      "code": "17026010",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : IN SOLID FORM"
    },
    {
      "code": "17026090",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL- OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER"
    },
    {
      "code": "170290",
      "description": "Other, including invert sugar and other sugar and sugar syrup blends containing in the dry state 50% by weight of fructose"
    },
    {
      "code": "17029010",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : PALMYRA SUGAR"
    },
    {
      "code": "17029020",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : CHEMICALLY PURE MALTOSE"
    },
    {
      "code": "17029030",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY"
    },
    {
      "code": "17029040",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : CARAMEL"
    },
    {
      "code": "17029050",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : INSULIN SYRUP"
    },
    {
      "code": "17029090",
      "description": "OTHER SUGARS, INCLUDING CHEMICALLY PURE LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE, IN SOLID FORM; SUGAR SYRUPS NOT CONTAINING ADDED FLAVOURING OR COLOURING MATTER; ARTIFICIAL HONEY, WHETHER OR NOT MIXED WITH NATURAL HONEY; CARAMEL - OTHER FRUCTOSE AND FRUCTOSE SYRUP, CONTAINING IN THE DRY STATE MORE THAN 50% BY WEIGHT OF FRUCTOSE, EXCLUDING INVERT SUGAR : OTHER, INCLUDING INVERT SUGAR AND OTHER SUGAR AND SUGAR SYRUP BLENDS CONTAINING IN THE DRY STATE 50% BY WEIGHT OF FRUCTOSE : OTHER"
    },
    {
      "code": "1703",
      "description": "MOLASSES RESULTING FROM THE EXTRACTION OR REFINING OF SUGAR"
    },
    {
      "code": "17031000",
      "description": "MOLASSES RESULTING FROM THE EXTRACTION OR REFINING OF SUGAR - CANE MOLASSES"
    },
    {
      "code": "170390",
      "description": "Other"
    },
    {
      "code": "17039010",
      "description": "MOLASSES RESULTING FROM THE EXTRACTION OR REFINING OF SUGAR - OTHER : MOLASSES, EDIBLE"
    },
    {
      "code": "17039090",
      "description": "MOLASSES RESULTING FROM THE EXTRACTION OR REFINING OF SUGAR - OTHER : MOLASSES, EDIBLE"
    },
    {
      "code": "1704",
      "description": "SUGAR CONFECTIONERY (INCLUDING WHITE CHOCOLATE), NOT CONTAINING COCOA"
    },
    {
      "code": "17041000",
      "description": "SUGAR CONFECTIONERY (INCLUDING WHITE CHOCOLATE), NOT CONTAINIG COCOA - CHEWING GUM, WHETHER OR NOT SUGAR COATED"
    },
    {
      "code": "170490",
      "description": "Other"
    },
    {
      "code": "17049010",
      "description": "SUGAR CONFECTIONARY (INCLUDING WHITE CHOCOLATE), NOT CONTAINING COCOA - OTHER : JELLY CONFECTIONARY"
    },
    {
      "code": "17049020",
      "description": "SUGAR CONFECTIONARY (INCLUDING WHITE CHOCOLATE), NOT CONTAINING COCOA - OTHER : BOILED SWEETS, WHETHER OR NOT FILLED"
    },
    {
      "code": "17049030",
      "description": "SUGAR CONFECTIONARY (INCLUDING WHITE CHOCOLATE), NOT CONTAINING COCOA - OTHER : TOFFEES, CARAMELS AND SIMILAR SWEETS"
    },
    {
      "code": "17049090",
      "description": "SUGAR CONFECTIONARY (INCLUDING WHITE CHOCOLATE), NOT CONTAINING COCOA - OTHER : OTHER"
    },
    {
      "code": "18",
      "description": "Cocoa and cocoa preparations"
    },
    {
      "code": "1801",
      "description": "COCOA BEANS, WHOLE OR BROKEN, RAW OR ROASTED"
    },
    {
      "code": "18010000",
      "description": "COCOA BEANS, WHOLE OR BROKEN, RAW OR ROASTED"
    },
    {
      "code": "1802",
      "description": "COCOA SHELLS, HUSKS, SKINS AND OTHER COCOA WASTE"
    },
    {
      "code": "18020000",
      "description": "COCOA SHELLS, HUSKS, SKINS AND OTHER COCOA WASTE"
    },
    {
      "code": "1803",
      "description": "COCOA PASTE, WHETHER OR NOT DEFATTED"
    },
    {
      "code": "18031000",
      "description": "COCOA PASTE, WHETHER OR NOT DEFATTED NOT DEFATTED"
    },
    {
      "code": "18032000",
      "description": "COCOA PASTE, WHETHER OR NOT DEFATTED WHOLLY OR PARTLY DEFATTED"
    },
    {
      "code": "1804",
      "description": "COCOA BUTTER, FAT AND OIL"
    },
    {
      "code": "18040000",
      "description": "COCOA BUTTER, FAT AND OIL"
    },
    {
      "code": "1805",
      "description": "COCOA POWDER, NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "18050000",
      "description": "COCOA POWDER, NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "1806",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA"
    },
    {
      "code": "18061000",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - COCOA POWDER, CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "18062000",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER PREPARATIONS IN BLOCKS, SLABS OR BARS WEIGHING MORE THAN 2 KG OR IN LIQUID, PASTE, POWDER, GRANULAR OR OTHER BULK FORM IN CONTAINERS OR IMMEDIATE PACKINGS, OF A CONTENT EXCEEDING 2 KG"
    },
    {
      "code": "18063100",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER, IN BLOCKS, SLABS OR BARS : FILLED"
    },
    {
      "code": "18063200",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER, IN BLOCKS, SLABS OR BARS : NOT FILLED"
    },
    {
      "code": "180690",
      "description": "Other"
    },
    {
      "code": "18069010",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER : CHOCOLATE AND CHOCOLATE PRODUCTS"
    },
    {
      "code": "18069020",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER : SUGAR CONFECTIONARY CONTAINING COCOA"
    },
    {
      "code": "18069030",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER : SPREADS CONTAINING COCOA"
    },
    {
      "code": "18069040",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER : PREPARATIONS CONTAINING COCOA FOR MAKING BEVERAGES"
    },
    {
      "code": "18069090",
      "description": "CHOCOLATE AND OTHER FOOD PREPARATIONS CONTAINING COCOA - OTHER : OTHER"
    },
    {
      "code": "19",
      "description": "Preparations of cereals, flour, starch or milk; pastrycooks’ products"
    },
    {
      "code": "1901",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 6% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "190110",
      "description": "Preparations suitable for infants or young children, put up for retail sale"
    },
    {
      "code": "19011010",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 5% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARATIONS FOR INFANT USE, PUT UP FOR RETAIL SALE : MALTED MILK (INCLUDING POWDER)"
    },
    {
      "code": "19011090",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 5% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARATIONS FOR INFANT USE, PUT UP FOR RETAIL SALE : OTHER"
    },
    {
      "code": "19012000",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 5% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED - MIXES AND DOUGHS FOR THE PREPARATION OF BAKERS WARES OF HEADING 1905"
    },
    {
      "code": "190190",
      "description": "Other"
    },
    {
      "code": "19019010",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 5% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : MALT EXTRACT"
    },
    {
      "code": "19019090",
      "description": "MALT EXTRACT; FOOD PREPARATIONS OF FLOUR, GROATS, MEAL, STARCH OR MALT EXTRACT, NOT CONTAINING COCOA OR CONTAINING LESS THAN 40% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED; FOOD PREPARATIONS OF GOODS OF HEADINGS 0401 TO 0404, NOT CONTAINING COCOA OR CONTAINING LESS THAN 5% BY WEIGHT OF COCOA CALCULATED ON A TOTALLY DEFATTED BASIS, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OTHER"
    },
    {
      "code": "1902",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED"
    },
    {
      "code": "19021100",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - UNCOOKED PASTA, NOT STUFFED OR OTHERWISE PREPARED : CONTAINING EGGS"
    },
    {
      "code": "19021900",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - UNCOOKED PASTA, NOT STUFFED OR OTHERWISE PREPARED : OTHER"
    },
    {
      "code": "190220",
      "description": "Stuffed pasta, whether or not cooked or otherwise prepared"
    },
    {
      "code": "19022010",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - STUFFED PASTA, WHETHER OR NOT COOKED OR OTHERWISE PREPARED : COOKED"
    },
    {
      "code": "19022090",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - STUFFED PASTA, WHETHER OR NOT COOKED OR OTHERWISE PREPARED : OTHER"
    },
    {
      "code": "190230",
      "description": "Other Pasta"
    },
    {
      "code": "19023010",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - OTHER PASTA : DRIED"
    },
    {
      "code": "19023090",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - OTHER PASTA : OTHER"
    },
    {
      "code": "190240",
      "description": "Couscous"
    },
    {
      "code": "19024010",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - COUSCOUS : UNPREPARED"
    },
    {
      "code": "19024090",
      "description": "PASTA, WHETHER OR NOT COOKED OR STUFFED (WITH MEAT OR OTHER SUBSTANCES) OR OTHERWISE PREPARED, SUCH AS SPAGHETTI, MACARONI, NOODLES, LASAGNE, GNOCCHI, RAVIOLI, CANNELLONI; COUSCOUS, WHETHER OR NOT PREPARED - COUSCOUS : OTHER"
    },
    {
      "code": "1903",
      "description": "TAPIOCA AND SUBSTITUTES THEREFOR PREPARED FROM STARCH, IN THE FORM OF FLAKES, GRAINS, PEARLS, SIFTINGS OR IN SIMILAR FORMS"
    },
    {
      "code": "19030000",
      "description": "TAPIOCA AND SUBSTITUTES THEREFOR PREPARED FROM STARCH, IN THE FORM OF FLAKES, GRAINS, PEARLS, SIFTINGS OR IN SIMILAR FORMS"
    },
    {
      "code": "1904",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE-COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "190410",
      "description": "Prepared foods obtained by the swelling or roasting of cereals or cereal products"
    },
    {
      "code": "19041010",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS : CORN FLAKES"
    },
    {
      "code": "19041020",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS : PAWS, MUDI AND THE LIKE"
    },
    {
      "code": "19041030",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS : BULGUR WHEAT"
    },
    {
      "code": "19041090",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED - PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS : OTHER"
    },
    {
      "code": "19042000",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED PREPARED FOODS OBTAINED FROM UNROASTED CEREAL FLAKES OR FROM MIXTURES OF UNROASTED CEREAL FLAKES AND ROASTED CEREAL FLAKES OR SWELLED CEREALS"
    },
    {
      "code": "19043000",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED BULGUR WHEAT"
    },
    {
      "code": "19049000",
      "description": "PREPARED FOODS OBTAINED BY THE SWELLING OR ROASTING OF CEREALS OR CEREAL PRODUCTS (FOR EXAMPLE, CORN FLAKES); CEREALS [OTHER THAN MAIZE (CORN) ] IN GRAIN FORM OR IN THE FORM OF FLAKES OR OTHER WORKED GRAINS (EXCEPT FLOUR, GROATS AND MEAL), PRE - COOKED OR OTHERWISE PREPARED, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER"
    },
    {
      "code": "1905",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS"
    },
    {
      "code": "19051000",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS -CRISPBREAD"
    },
    {
      "code": "19052000",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS-GINGERBREAD AND THE LIKE"
    },
    {
      "code": "19053100",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERR WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS- SWEET BISCUITS; WAFFLES AND WAFERS--SWEET BISCUITS"
    },
    {
      "code": "190532",
      "description": "Waffles and wafers"
    },
    {
      "code": "19053211",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - - SWEET BISCUITS; WAFFLES AND WAFERS-- WAFFLES AND WAFERS : --- COMMUNION WAFERS : COATED WITH CHOCOLATE OR CONTAINING CHOCOLATE"
    },
    {
      "code": "19053219",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - - SWEET BISCUITS; WAFFLES AND WAFERS --WAFFLES AND WAFERS : - COMMUNION WAFERS : OTHER"
    },
    {
      "code": "19053290",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - SWEET BISCUITS; WAFFLES AND WAFERS --WAFFLES AND WAFERS : OTHER"
    },
    {
      "code": "19054000",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - RUSKS, TOASTED BREAD AND SIMILAR TOASTED PRODUCTS"
    },
    {
      "code": "190590",
      "description": "Other"
    },
    {
      "code": "19059010",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - OTHER : PASTRIES AND CAKES"
    },
    {
      "code": "19059020",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - OTHER : BISCUITS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "19059030",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - OTHER : EXTRUDED OR EXPANDED PRODUCTS, SAVOURY OR SALTED"
    },
    {
      "code": "19059040",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - OTHER : PAPAD"
    },
    {
      "code": "19059090",
      "description": "BREAD, PASTRY, CAKES, BISCUITS AND OTHER BAKERS WARES, WHETHER OR NOT CONTAINING COCOA; COMMUNION WAFERS, EMPTY CACHETS OF A KIND SUITABLE FOR PHARMACEUTICAL USE, SEALING WAFERS, RICE PAPER AND SIMILAR PRODUCTS - OTHER : OTHER"
    },
    {
      "code": "20",
      "description": "Preparations of vegetables, fruit, nuts or other parts of plants"
    },
    {
      "code": "2001",
      "description": "VEGETABLES, FRUIT, NUTS AND OTHER EDIBLE PARTS OF PLANTS, PREPARED OR PRESERVED BY VINEGAR OR ACETIC ACID"
    },
    {
      "code": "20011000",
      "description": "VEGETABLES, FRUIT, NUTS AND OTHER EDIBLEPARTS OF PLANTS, PREPARED OR PRESERVEDBY VINEGAR OR ACETIC ACID CUCUMBERS AND GHERKINS"
    },
    {
      "code": "20019000",
      "description": "VEGETABLES, FRUIT, NUTS AND OTHER EDIBLEPARTS OF PLANTS, PREPARED OR PRESERVEDBY VINEGAR OR ACETIC ACID OTHER"
    },
    {
      "code": "2002",
      "description": "TOMATOES PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID"
    },
    {
      "code": "20021000",
      "description": "TOMATOES PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID TOMATOES, WHOLE OR IN PIECES"
    },
    {
      "code": "20029000",
      "description": "TOMATOES PREPARED OR PRESERVED OTHERWISETHAN BY VINEGAR OR ACETIC ACID OTHER"
    },
    {
      "code": "2003",
      "description": "ACETIC ACID"
    },
    {
      "code": "20031000",
      "description": "MUSHROOMS AND TRUFFLES, PREPARED ORPRESERVED OTHERWISE THAN BY VINEGAR ORACETIC ACID MUSHROOMS OF THE GENUS AGARICUS"
    },
    {
      "code": "200390",
      "description": "Other"
    },
    {
      "code": "20039010",
      "description": "MUSHROOMS AND TRUFFLES, PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID-OTHER---TRUFFLES"
    },
    {
      "code": "20039090",
      "description": "MUSHROOMS AND TRUFFLES, PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID-OTHER---OTHER"
    },
    {
      "code": "2004",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID, FROZEN, OTHER THAN PRODUCTS OF HEADING 2006"
    },
    {
      "code": "20041000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 POTATOES"
    },
    {
      "code": "20049000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 OTHER VEGETABLES AND MIXTURES OF VEGETABLES"
    },
    {
      "code": "2005",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006"
    },
    {
      "code": "20051000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 HOMOGENISED VEGETABLES"
    },
    {
      "code": "20052000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 POTATOES"
    },
    {
      "code": "20054000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 PEAS (PISUM, SATIVUM)"
    },
    {
      "code": "20055100",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 - BEANS (VIGNA SPP., PHASEOLUS SPP.) : BEANS, SHELLED"
    },
    {
      "code": "20055900",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 - BEANS (VIGNA SPP., PHASEOLUS SPP.) : OTHER"
    },
    {
      "code": "20056000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 ASPARAGUS"
    },
    {
      "code": "20057000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 OLIVES"
    },
    {
      "code": "20058000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 SWEET CORN (ZEA MAYS VAR. SACCHARATA)"
    },
    {
      "code": "20059000",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 OTHER VEGETABLES AND MIXTURES OF VEGETABLES"
    },
    {
      "code": "20059100",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVEDOTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 -OTHER VEGETABLES AND MIXTURES OF VEGETABLES: -- BAMBOO SHOOTS"
    },
    {
      "code": "20059900",
      "description": "OTHER VEGETABLES PREPARED OR PRESERVED OTHERWISE THAN BY VINEGAR OR ACETIC ACID, NOT FROZEN, OTHER THAN PRODUCTS OF HEADING 2006 -OTHER VEGETABLES AND MIXTURES OF VEGETABLES: -- OTHER"
    },
    {
      "code": "2006",
      "description": "VEGETABLES, FRUITS, NUTS, FRUIT-PEEL AND OTHER PARTS OF PLANTS, PRESERVED BY SUGAR (DRAINED, GLACE OR CRYSTALLISED)"
    },
    {
      "code": "20060000",
      "description": "VEGETABLES, FRUITS, NUTS, FRUIT-PEEL AND OTHER PARTS OF PLANTS, PRESERVED BY SUGAR (DRAINED, GLACE OR CRYSTALLISED)"
    },
    {
      "code": "2007",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT OR NUT PUREE AND FRUIT OR NUT PASTES, OBTAINED BY COOKING, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "20071000",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER HOMOGENISED PREPARATIONS"
    },
    {
      "code": "20079100",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : CITRUS FRUIT"
    },
    {
      "code": "200799",
      "description": "Other"
    },
    {
      "code": "20079910",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : OTHER : MANGO"
    },
    {
      "code": "20079920",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : OTHER : GUAVA"
    },
    {
      "code": "20079930",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : OTHER : PINE APPLE"
    },
    {
      "code": "20079940",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : OTHER : APPLE"
    },
    {
      "code": "20079990",
      "description": "JAMS, FRUIT JELLIES, MARMALADES, FRUIT ORNUT PUREE AND FRUIT OR NUT PASTES, OBTAINEDBY COOKING, WHETHER OR NOT CONTAININGADDED SUGAR OR OTHER SWEETENING MATTER - OTHER : OTHER : OTHER"
    },
    {
      "code": "2008",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OF PLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR SPIRIT, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "20081100",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : GROUND-NUTS"
    },
    {
      "code": "200819",
      "description": "Other, including mixtures"
    },
    {
      "code": "20081910",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : OTHER, INCLUDING MIXTURES : CASHEW NUT, ROASTED, SALTED OR ROASTED AND SALTED"
    },
    {
      "code": "20081920",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : OTHER, INCLUDING MIXTURES : OTHER ROASTED NUTS AND SEEDS"
    },
    {
      "code": "20081930",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : OTHER, INCLUDING MIXTURES : OTHER NUTS, OTHERWISE PREPARED OR PRESERVED"
    },
    {
      "code": "20081940",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : OTHER, INCLUDING MIXTURES : OTHER ROASTED AND FRIED VEGETABLE PRODUCTS"
    },
    {
      "code": "20081990",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - NUTS, GROUND - NUTS AND OTHER SEEDS, WHETHER OR NOT MIXED TOGETHER : OTHER, INCLUDING MIXTURES : OTHER"
    },
    {
      "code": "20082000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED PINEAPPLES"
    },
    {
      "code": "200830",
      "description": "Citrus fruit"
    },
    {
      "code": "20083010",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - CITRUS FRUIT : ORANGE"
    },
    {
      "code": "20083090",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - CITRUS FRUIT : OTHER"
    },
    {
      "code": "20084000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - PEARS"
    },
    {
      "code": "20085000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - APRICOTS"
    },
    {
      "code": "20086000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -CHERRIES"
    },
    {
      "code": "20087000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - PEACHES, INCLUDING NECTARINES"
    },
    {
      "code": "20088000",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED - STRAWBERRIES"
    },
    {
      "code": "20089100",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : PALM HEARTS"
    },
    {
      "code": "200893",
      "description": "Cranberries"
    },
    {
      "code": "20089300",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OF PLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR SPIRIT, NOT ELSEWHERE SPECIFIED OR INCLUDED-Other, including mixtures other than those of sub-heading 2008 19 --CRANBERRIES(VACCINIUM MACROCARPON, VACCINIUM OXYCOCCOS VACCINIUM VITIS-IDAEA)"
    },
    {
      "code": "200897",
      "description": "Mixtures"
    },
    {
      "code": "20089700",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OF PLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR SPIRIT, NOT ELSEWHERE SPECIFIED OR INCLUDED-Other, including mixtures other than those of sub-heading 2008 19 --MIXTURES"
    },
    {
      "code": "200899",
      "description": "Other"
    },
    {
      "code": "20089911",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : SQUASH : MANGO"
    },
    {
      "code": "20089912",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : SQUASH : LEMON"
    },
    {
      "code": "20089913",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : SQUASH : ORANGE"
    },
    {
      "code": "20089914",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : SQUASH : PINEAPPLE"
    },
    {
      "code": "20089919",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -- OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADIN"
    },
    {
      "code": "20089991",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : OTHER : FRUIT COCKTAIL"
    },
    {
      "code": "20089992",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : OTHER : GRAPES"
    },
    {
      "code": "20089993",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : OTHER : APPLES"
    },
    {
      "code": "20089994",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -  - OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADING 2008 19 : OTHER : OTHER : GUAVA"
    },
    {
      "code": "20089999",
      "description": "FRUIT, NUTS AND OTHER EDIBLE PARTS OFPLANTS, OTHERWISE PREPARED OR PRESERVED, WHETHER OR NOT CONTAINING ADDED SUGAR OROTHER SWEETENING MATTER OR SPIRIT, NOTELSEWHERE SPECIFIED OR INCLUDED -- OTHER, INCLUDING MIXTURES OTHER THAN THOSE OF SUB-HEADIN"
    },
    {
      "code": "2009",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) AND VEGETABLE JUICES, UNFERMENTED AND NOT CONTAINING ADDED SPIRIT, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER"
    },
    {
      "code": "20091100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - ORANGE JUICE : FROZEN"
    },
    {
      "code": "20091200",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER  -  ORANGE JUICE  :  NOT FROZEN, OF A BRIX VALUE NOT EXCEEDING 20"
    },
    {
      "code": "20091900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - ORANGE JUICE : OTHER"
    },
    {
      "code": "20092100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - GRAPEFRUIT (INCLUDING POMELO)JUICE :-- OF A BRIX VALUE NOT EXCEEDING 20"
    },
    {
      "code": "20092900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - GRAPEFRUIT (INCLUDING POMELO) JUICE : -- OTHER"
    },
    {
      "code": "20093100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - JUICE OF ANY OTHER SINGLE CITRUS FRUIT : OF A BRIX VALUE NOT EXCEEDING 20"
    },
    {
      "code": "20093900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - JUICE OF ANY OTHER SINGLE CITRUS FRUIT : OTHER"
    },
    {
      "code": "20094100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - PINEAPPLE JUICE : OF A BRIX VALUE NOT EXCEEDING 20"
    },
    {
      "code": "20094900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - PINEAPPLE JUICE : OTHER"
    },
    {
      "code": "20095000",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER TOMATO JUICE"
    },
    {
      "code": "20096100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - GRAPE JUICE (INCLUDING GRAPE MUST) : OF A BRIX VALUE NOT EXCEEDING 30"
    },
    {
      "code": "20096900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - GRAPE JUICE (INCLUDING GRAPE MUST) : OTHER"
    },
    {
      "code": "20097100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - APPLE JUICE : OF A BRIX VALUE NOT EXCEEDING 20"
    },
    {
      "code": "20097900",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) ANDVEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER - APPLE JUICE : OTHER"
    },
    {
      "code": "200980",
      "description": "Juice of any other single fruit or vegetable"
    },
    {
      "code": "200981",
      "description": "Cranberry"
    },
    {
      "code": "20098100",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) AND VEGETABLE JUICES, UNFERMENTED AND NOT CONTAINING ADDED SPIRIT, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER-JUICE OF ANY OTHER SINGLE FRUIT OR VEGETABLE--CRANBERRY (VACCINIUM MACROCARPON,VACCINIUM OXYCOCCOS VACCINIUM VITIS-IDAEA) JUICE"
    },
    {
      "code": "200989",
      "description": "Other"
    },
    {
      "code": "20098910",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) AND VEGETABLE JUICES, UNFERMENTED AND NOT CONTAINING ADDED SPIRIT, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER-JUICE OF ANY OTHER SINGLE FRUIT OR VEGETABLE--OTHER ---MANGO JUICE"
    },
    {
      "code": "20098990",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) AND VEGETABLE JUICES, UNFERMENTED AND NOT CONTAINING ADDED SPIRIT, WHETHER OR NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER-JUICE OF ANY OTHER SINGLE FRUIT OR VEGETABLE--OTHER ---OTHER"
    },
    {
      "code": "200990",
      "description": "Mixtures of juices"
    },
    {
      "code": "20099000",
      "description": "FRUIT JUICES (INCLUDING GRAPE MUST) AND VEGETABLE JUICES, UNFERMENTED AND NOTCONTAINING ADDED SPIRIT, WHETHER OR NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER MIXTURES OF JUICES"
    },
    {
      "code": "2031",
      "description": "Fresh or chilled"
    },
    {
      "code": "2032",
      "description": "Frozen"
    },
    {
      "code": "2042",
      "description": "Other meat of sheep,fresh or chilled"
    },
    {
      "code": "2044",
      "description": "Other meat of sheep, frozen"
    },
    {
      "code": "2062",
      "description": "Of bovine animals, frozen"
    },
    {
      "code": "2064",
      "description": "Of swine,frozen"
    },
    {
      "code": "2071",
      "description": "Of fowls of the species Gallus domesticus"
    },
    {
      "code": "2072",
      "description": "Of turkeys"
    },
    {
      "code": "2073",
      "description": "Of ducks, geese or guinea fowls"
    },
    {
      "code": "2075",
      "description": "Of geese"
    },
    {
      "code": "21",
      "description": "Miscellaneous edible preparations"
    },
    {
      "code": "2101",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONS WITH A BASIS OF THESE PRODUCTS OR WITH A BASIS OF COFFEE, TEA OR MATE; ROASTED CHICORY AND OTHER ROASTED COFFEE SUBSTITUTES, AND EXTRACTS, ESSENCES AND CONCENTRATES THEREOF"
    },
    {
      "code": "210111",
      "description": "Extracts, essences and concentrates"
    },
    {
      "code": "21011110",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES OF COFFEE, AND PREPARATIONS WITH A BASIS OF THESE EXTRACTS, ESSENCES OR CONCENTRATES OR WITHA BASIS OF COFFEE : EXTRACTS, ESSENCES AND CONCENTRATES : INSTANT COFFEE, FLAVOURED"
    },
    {
      "code": "21011120",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES OF COFFEE, AND PREPARATIONS WITH A BASIS OF THESE EXTRACTS, ESSENCES OR CONCENTRATES OR WITHA BASIS OF COFFEE : EXTRACTS, ESSENCES AND CONCENTRATES : INSTANT COFFEE, NOT FLOVERED"
    },
    {
      "code": "21011130",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES OF COFFEE, AND PREPARATIONS WITH A BASIS OF THESE EXTRACTS, ESSENCES OR CONCENTRATES OR WITHA BASIS OF COFFEE : EXTRACTS, ESSENCES AND CONCENTRATES : COFFEE AROMA"
    },
    {
      "code": "21011190",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES OF COFFEE, AND PREPARATIONS WITH A BASIS OF THESE EXTRACTS, ESSENCES OR CONCENTRATES OR WITHA BASIS OF COFFEE : EXTRACTS, ESSENCES AND CONCENTRATES : OTHER"
    },
    {
      "code": "21011200",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES OF COFFEE, AND PREPARATIONS WITH A BASIS OF THESE EXTRACTS, ESSENCES OR CONCENTRATES OR WITHA BASIS OF COFFEE : PREPARATIONS WITH BASIS OF EXTRACTS, ESSENCES, CONCENTRATES OR WITH A BASIS OF COFFEE"
    },
    {
      "code": "210120",
      "description": "Extracts, essences and concentrates, of tea or mate, and preparations with a basis of these extracts, essences or concentrates or with a basis of tea or mate"
    },
    {
      "code": "21012010",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES, OF TEAOR MATE, AND PREPARATIONS WITH A BASIS OFTHESE EXTRACTS, ESSENCES OR CONCENTRATES ORWITH A BASIS OF TEA OR MATE : INSTANT TEA"
    },
    {
      "code": "21012020",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES, OF TEAOR MATE, AND PREPARATIONS WITH A BASIS OFTHESE EXTRACTS, ESSENCES OR CONCENTRATES ORWITH A BASIS OF TEA OR MATE : QUICK BREWING BLACK TEA"
    },
    {
      "code": "21012030",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES, OF TEAOR MATE, AND PREPARATIONS WITH A BASIS OFTHESE EXTRACTS, ESSENCES OR CONCENTRATES ORWITH A BASIS OF TEA OR MATE : TEA AROMA"
    },
    {
      "code": "21012090",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - EXTRACTS, ESSENCES AND CONCENTRATES, OF TEAOR MATE, AND PREPARATIONS WITH A BASIS OFTHESE EXTRACTS, ESSENCES OR CONCENTRATES ORWITH A BASIS OF TEA OR MATE : OTHER"
    },
    {
      "code": "210130",
      "description": "Roasted chicory and other roasted coffee substitutes, and extracts, essences and concentrates thereof"
    },
    {
      "code": "21013010",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - ROASTED CHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCES ANDCONCENTRATES THEREOF : ROASTED CHICORY"
    },
    {
      "code": "21013020",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - ROASTED CHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCES ANDCONCENTRATES THEREOF : ROASTED COFFEE SUBSTITUTES"
    },
    {
      "code": "21013090",
      "description": "EXTRACTS, ESSENCES AND CONCENTRATES, OF COFFEE, TEA OR MATE AND PREPARATIONSWITH A BASIS OF THESE PRODUCTS OR WITH ABASIS OF COFFEE, TEA OR MATE; ROASTEDCHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCESAND CONCENTRATES THEREOF - ROASTED CHICORY AND OTHER ROASTED COFFEESUBSTITUTES, AND EXTRACTS, ESSENCES ANDCONCENTRATES THEREOF : OTHER"
    },
    {
      "code": "2102",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLE CELL MICRO-ORGANISMS, DEAD (BUT NOT INCLUDING VACCINES OF HEADING 3002); PREPARED BAKING POWDERS"
    },
    {
      "code": "210210",
      "description": "Active yeasts"
    },
    {
      "code": "21021010",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLECELL MICRO - ORGANISMS, DEAD (BUT NOTINCLUDING VACCINES OF HEADING 3002);PREPARED BAKING POWDERS - ACTIVE YEASTS : CULTURE YEAST"
    },
    {
      "code": "21021020",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLECELL MICRO - ORGANISMS, DEAD (BUT NOTINCLUDING VACCINES OF HEADING 3002);PREPARED BAKING POWDERS - ACTIVE YEASTS : BAKERS YEAST"
    },
    {
      "code": "21021090",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLECELL MICRO - ORGANISMS, DEAD (BUT NOTINCLUDING VACCINES OF HEADING 3002);PREPARED BAKING POWDERS - ACTIVE YEASTS : OTHER"
    },
    {
      "code": "21022000",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLECELL MICRO - ORGANISMS, DEAD (BUT NOTINCLUDING VACCINES OF HEADING 3002);PREPARED BAKING POWDERS - INACTIVE YEASTS, OTHER SINGLE-CELL MICRO-ORGANISMS, DEAD"
    },
    {
      "code": "21023000",
      "description": "YEASTS (ACTIVE OR INACTIVE); OTHER SINGLECELL MICRO - ORGANISMS, DEAD (BUT NOTINCLUDING VACCINES OF HEADING 3002);PREPARED BAKING POWDERS - PREPARED BAKING POWDERS"
    },
    {
      "code": "2103",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXED CONDIMENTS AND MIXED SEASONINGS; MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD"
    },
    {
      "code": "21031000",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - SOYA SAUCE"
    },
    {
      "code": "21032000",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - TOMATO KETCHUP AND OTHER TOMATO SAUCES"
    },
    {
      "code": "21033000",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD"
    },
    {
      "code": "210390",
      "description": "Other"
    },
    {
      "code": "21039010",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - OTHER : CURRY PASTE"
    },
    {
      "code": "21039020",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - OTHER : CHILLI SAUCE"
    },
    {
      "code": "21039030",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - OTHER : MAJONNAISE AND SALAD DRESSINGS"
    },
    {
      "code": "21039040",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - OTHER : MIXED, CONDIMENTS AND MIXED SEASONING"
    },
    {
      "code": "21039090",
      "description": "SAUCES AND PREPARATIONS THEREFOR, MIXEDCONDIMENTS AND MIXED SEASONINGS;MUSTARD FLOUR AND MEAL AND PREPARED MUSTARD - OTHER : OTHER"
    },
    {
      "code": "2104",
      "description": "SOUPS AND BROTHS AND PREPARATIONS THEREFOR; HOMOGENISED COMPOSITE FOOD PREPARATIONS"
    },
    {
      "code": "210410",
      "description": "Soups and broths and preparations therefor"
    },
    {
      "code": "21041010",
      "description": "SOUPS AND BROTHS AND PREPARATIONSTHEREFOR; HOMOGENISED COMPOSITE FOOD PREPARATIONS - SOUPS AND BROTHS AND PREPARATIONS THEREFOR : DRIED"
    },
    {
      "code": "21041090",
      "description": "SOUPS AND BROTHS AND PREPARATIONSTHEREFOR; HOMOGENISED COMPOSITE FOOD PREPARATIONS - SOUPS AND BROTHS AND PREPARATIONS THEREFOR : OTHER"
    },
    {
      "code": "21042000",
      "description": "SOUPS AND BROTHS AND PREPARATIONSTHEREFOR; HOMOGENISED COMPOSITE FOOD PREPARATIONS HOMOGENISED COMPOSITE FOOD PREPARATIONS"
    },
    {
      "code": "2105",
      "description": "ICECREAM AND OTHER EDIBLE ICE, WHETHER OR NOT CONTAINING COCOA"
    },
    {
      "code": "21050000",
      "description": "ICECREAM AND OTHER EDIBLE ICE, WHETHER OR NOT CONTAINING COCOA"
    },
    {
      "code": "2106",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "21061000",
      "description": "PROTEIN CONCENTRATES AND TEXTURED PROTEIN SUBSTANCES"
    },
    {
      "code": "210690",
      "description": "Other"
    },
    {
      "code": "21069011",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : SOFT DRINK CONCENTRATES : SHARBAT"
    },
    {
      "code": "21069019",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : SOFT DRINK CONCENTRATES : OTHER"
    },
    {
      "code": "21069020",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : PAN MASALA"
    },
    {
      "code": "21069030",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : BETEL NUT PRODUCT KNOWN AS SUPARI"
    },
    {
      "code": "21069040",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : SUGAR-SYRUPS CONTAINING ADDED FLAVOURING OR COLOURING MATTER, NOT ELSEWHERE SPECIFIED ORINCLUDED; LACTOSE SYRUP; GLUCOSE SYRUP ANDMALTO DEXTRINE SYRUP"
    },
    {
      "code": "21069050",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : COMPOUND PREPARATIONS FOR MAKING NON-ALCOHOLIC BEVERAGES"
    },
    {
      "code": "21069060",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : FOOD FLAVOURING MATERIAL"
    },
    {
      "code": "21069070",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : CHURNA FOR PAN"
    },
    {
      "code": "21069080",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : CUSTARD POWDER"
    },
    {
      "code": "21069091",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OTHER : DIABETIC FOODS"
    },
    {
      "code": "21069092",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OTHER : STERILIZED OR PASTEURIZED MILTONE"
    },
    {
      "code": "21069099",
      "description": "FOOD PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OTHER : OTHER"
    },
    {
      "code": "2109",
      "description": "Other, including edible flours and meals of meat or meat offal"
    },
    {
      "code": "22",
      "description": "Beverages, spirits and vinegar"
    },
    {
      "code": "2201",
      "description": "WATERS, INCLUDING NATURAL OR ARTIFICIAL MINERAL WATERS AND AERATED WATERS, NOT CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER NOR FLAVOURED; ICE AND SNOW"
    },
    {
      "code": "220110",
      "description": "Mineral waters and aerated waters"
    },
    {
      "code": "22011010",
      "description": "WATERS, INCLUDING NATURAL OR ARTIFICIALMINERAL WATERS AND AERATED WATERS, NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER NOR FLAVOURED; ICEAND SNOW - MINERAL WATERS AND AERATED WATERS : MINERAL WATERS"
    },
    {
      "code": "22011020",
      "description": "WATERS, INCLUDING NATURAL OR ARTIFICIALMINERAL WATERS AND AERATED WATERS, NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER NOR FLAVOURED; ICEAND SNOW - MINERAL WATERS AND AERATED WATERS : AERATED WATERS"
    },
    {
      "code": "220190",
      "description": "Other"
    },
    {
      "code": "22019010",
      "description": "WATERS, INCLUDING NATURAL OR ARTIFICIALMINERAL WATERS AND AERATED WATERS, NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER NOR FLAVOURED; ICEAND SNOW - OTHER : ICE AND SNOW"
    },
    {
      "code": "22019090",
      "description": "WATERS, INCLUDING NATURAL OR ARTIFICIALMINERAL WATERS AND AERATED WATERS, NOTCONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER NOR FLAVOURED; ICEAND SNOW - OTHER : OTHER"
    },
    {
      "code": "2202",
      "description": "WATERS, INCLUDING MINERAL WATERS AND AERATED WATERS, CONTAINING ADDED SUGAR OR OTHER SWEETENING MATTER OR FLAVOURED, AND OTHER NON-ALCOHOLIC BEVERAGES, NOT INCLUDING FRUIT OR VEGETABLE JUICES OF HEADING 2009"
    },
    {
      "code": "220210",
      "description": "Waters, including mineral waters and aerated waters, containing added sugar or other sweetening matter or flavoured"
    },
    {
      "code": "22021010",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - WATERS, INCLUDING MINERAL WATERS AND AERATEDWATERS, CONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER OR FLAVOURED : AERATED WATERS"
    },
    {
      "code": "22021020",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - WATERS, INCLUDING MINERAL WATERS AND AERATEDWATERS, CONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER OR FLAVOURED : LEMONADE"
    },
    {
      "code": "22021090",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - WATERS, INCLUDING MINERAL WATERS AND AERATEDWATERS, CONTAINING ADDED SUGAR OR OTHERSWEETENING MATTER OR FLAVOURED : OTHER"
    },
    {
      "code": "220290",
      "description": "Other"
    },
    {
      "code": "22029010",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - OTHER : SOYA MILK DRINKS, WHETHER OR NOT SWEETENDED OR FLAVOURED"
    },
    {
      "code": "22029020",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - OTHER : FRUIT PULP OR FRUIT JUICE BASED DRINKS"
    },
    {
      "code": "22029030",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - OTHER : BEVERAGES CONTAINING MILK"
    },
    {
      "code": "22029090",
      "description": "WATERS, INCLUDING MINERAL WATERS ANDAERATED WATERS, CONTAINING ADDED SUGAROR OTHER SWEETENING MATTER OR FLAVOURED,AND OTHER NON - ALCOHOLIC BEVERAGES, NOTINCLUDING FRUIT OR VEGETABLE JUICES OFHEADING 2009 - OTHER : OTHER"
    },
    {
      "code": "22029100",
      "description": "Non alcoholic beer"
    },
    {
      "code": "220299",
      "description": "Other"
    },
    {
      "code": "22029910",
      "description": "Soya milk drinks, whether or not sweetended or flavoured"
    },
    {
      "code": "22029920",
      "description": "Fruit pulp or fruit juice based drinks"
    },
    {
      "code": "22029930",
      "description": "Beverages containing milk"
    },
    {
      "code": "22029990",
      "description": "Other"
    },
    {
      "code": "2203",
      "description": "BEER MADE FROM MALT"
    },
    {
      "code": "22030000",
      "description": "BEER MADE FROM MALT"
    },
    {
      "code": "2204",
      "description": "-"
    },
    {
      "code": "22041000",
      "description": "Sparkling wine Other wine; grape must with fermentation prevented or arrested by the addition of alcohol"
    },
    {
      "code": "220421",
      "description": "In containers holding 2 l or less"
    },
    {
      "code": "22042110",
      "description": "Port and other red wines"
    },
    {
      "code": "22042120",
      "description": "Sherry and other white wines"
    },
    {
      "code": "22042190",
      "description": "Other"
    },
    {
      "code": "220422",
      "description": "In containers holding more than 2 l but not more than 10 l"
    },
    {
      "code": "22042210",
      "description": "Port and other red wines"
    },
    {
      "code": "22042220",
      "description": "Sherry and other white wines"
    },
    {
      "code": "22042290",
      "description": "Other"
    },
    {
      "code": "220429",
      "description": "Other"
    },
    {
      "code": "22042910",
      "description": "Port and other red wines"
    },
    {
      "code": "22042920",
      "description": "Sherry and other white wines"
    },
    {
      "code": "22042990",
      "description": "Other"
    },
    {
      "code": "22043000",
      "description": "Other grape must"
    },
    {
      "code": "2205",
      "description": "-"
    },
    {
      "code": "22051000",
      "description": "In containers holding 2 l or less"
    },
    {
      "code": "22059000",
      "description": "Other"
    },
    {
      "code": "2206",
      "description": "OTHER FERMENTED BEVERAGES (FOR EXAMPLE, CIDER, PERRY, MEAD); MIXTURES  OF FERMENTED BEVERAGES AND NON-ALCOHOLIC BEVERAGES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "22060000",
      "description": "OTHER FERMENTED BEVERAGES (FOR EXAMPLE, CIDER, PERRY, MEAD); MIXTURES OF FERMENTED BEVERAGES AND NON-ALCOHOLIC BEVERAGES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "2207",
      "description": "ETHYL ALCOHOL AND OTHER SPIRITS, DENATURED, OF ANY STRENGTH"
    },
    {
      "code": "220710",
      "description": "Undenatured ethyl alcohol of an alcoholic strength by volume of 80% vol. or higher"
    },
    {
      "code": "22071011",
      "description": "Concentrates of alcoholic beverages"
    },
    {
      "code": "22071019",
      "description": "Other"
    },
    {
      "code": "22071090",
      "description": "Other"
    },
    {
      "code": "22072000",
      "description": "ETHYL ALCOHOL AND OTHER SPIRITS, DENATURED, OF ANY STRENGTH ETHYL ALCOHOL AND OTHER SPIRITS, DENATURED, OF ANY STRENGTH"
    },
    {
      "code": "2208",
      "description": "-"
    },
    {
      "code": "220820",
      "description": "Spirits obtained by distilling grape wine or grape marc"
    },
    {
      "code": "22082010",
      "description": "Spirits obtained by distilling grape wine or grape marc : --- In containers holding 2 l or less"
    },
    {
      "code": "22082011",
      "description": "Brandy"
    },
    {
      "code": "22082012",
      "description": "Liquors"
    },
    {
      "code": "22082019",
      "description": "Other"
    },
    {
      "code": "22082020",
      "description": "-"
    },
    {
      "code": "22082090",
      "description": "-"
    },
    {
      "code": "22082091",
      "description": "Brandy"
    },
    {
      "code": "22082092",
      "description": "Liquors"
    },
    {
      "code": "22082099",
      "description": "Other"
    },
    {
      "code": "220830",
      "description": "Whiskies l 182% -"
    },
    {
      "code": "22083010",
      "description": "-"
    },
    {
      "code": "22083011",
      "description": "Bourbon whiskey"
    },
    {
      "code": "22083012",
      "description": "Scotch"
    },
    {
      "code": "22083013",
      "description": "Blended"
    },
    {
      "code": "22083019",
      "description": "Other"
    },
    {
      "code": "22083020",
      "description": "-"
    },
    {
      "code": "22083030",
      "description": "-"
    },
    {
      "code": "22083090",
      "description": "-"
    },
    {
      "code": "22083091",
      "description": "Bourbon whiskey"
    },
    {
      "code": "22083092",
      "description": "Scotch"
    },
    {
      "code": "22083093",
      "description": "Blended"
    },
    {
      "code": "22083099",
      "description": "Other"
    },
    {
      "code": "220840",
      "description": "Rum and other spirits obtained by distilling fermented sugarcane products"
    },
    {
      "code": "22084010",
      "description": "-"
    },
    {
      "code": "22084011",
      "description": "Rum"
    },
    {
      "code": "22084012",
      "description": "Other"
    },
    {
      "code": "22084020",
      "description": "-"
    },
    {
      "code": "22084091",
      "description": "Rum"
    },
    {
      "code": "22084092",
      "description": "Other"
    },
    {
      "code": "220850",
      "description": "Gin and Geneva"
    },
    {
      "code": "22085010",
      "description": "-"
    },
    {
      "code": "22085011",
      "description": "-"
    },
    {
      "code": "22085012",
      "description": "Geneva"
    },
    {
      "code": "22085013",
      "description": "Vodka"
    },
    {
      "code": "22085020",
      "description": "-"
    },
    {
      "code": "22085091",
      "description": "Gin"
    },
    {
      "code": "22085092",
      "description": "Geneva"
    },
    {
      "code": "22085093",
      "description": "-"
    },
    {
      "code": "220860",
      "description": "Vodka"
    },
    {
      "code": "22086000",
      "description": "-"
    },
    {
      "code": "220870",
      "description": "Liqueurs and cordials"
    },
    {
      "code": "22087010",
      "description": "-"
    },
    {
      "code": "22087011",
      "description": "- Liqueurs"
    },
    {
      "code": "22087012",
      "description": "Cordials Other"
    },
    {
      "code": "22087020",
      "description": "-"
    },
    {
      "code": "22087091",
      "description": "Liqueurs"
    },
    {
      "code": "22087092",
      "description": "Cordials - Other"
    },
    {
      "code": "220890",
      "description": "Other"
    },
    {
      "code": "22089010",
      "description": "-"
    },
    {
      "code": "22089011",
      "description": "Tequila"
    },
    {
      "code": "22089012",
      "description": "Indenatured ethyl alcohol ---- Other"
    },
    {
      "code": "22089019",
      "description": "Other"
    },
    {
      "code": "22089020",
      "description": "-"
    },
    {
      "code": "22089090",
      "description": "-"
    },
    {
      "code": "22089091",
      "description": "Tequila"
    },
    {
      "code": "22089092",
      "description": "Indenatured ethyl alcohol"
    },
    {
      "code": "22089099",
      "description": "Other"
    },
    {
      "code": "2209",
      "description": "VINEGAR AND SUBSTITUTES FOR VINEGAR OBTAINED FROM ACETIC ACID"
    },
    {
      "code": "220900",
      "description": "Vinegar and substitutes for vinegar obtained from acetic acid"
    },
    {
      "code": "22090010",
      "description": "VINEGAR AND SUBSTITUTES FOR VINEGAROBTAINED FROM ACETIC ACID - VINEGAR AND SUBSTITUTES FOR VINEGAR OBTAINED FROM ACETIC ACID : BREWED VINEGAR"
    },
    {
      "code": "22090020",
      "description": "VINEGAR AND SUBSTITUTES FOR VINEGAROBTAINED FROM ACETIC ACID - VINEGAR AND SUBSTITUTES FOR VINEGAR OBTAINED FROM ACETIC ACID : SYNTHETIC VINEGAR"
    },
    {
      "code": "22090090",
      "description": "VINEGAR AND SUBSTITUTES FOR VINEGAROBTAINED FROM ACETIC ACID - VINEGAR AND SUBSTITUTES FOR VINEGAR OBTAINED FROM ACETIC ACID : OTHER"
    },
    {
      "code": "23",
      "description": "Residues and waste from the food industries; prepared animal fodder"
    },
    {
      "code": "2301",
      "description": "FLOURS, MEALS AND PELLETS, OF MEAT OR MEAT OFFAL, OF FISH OR OF CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES"
    },
    {
      "code": "230110",
      "description": "Flours, meals and pellets, of meat or meat offal; greaves"
    },
    {
      "code": "23011010",
      "description": "FLOURS, MEALS AND PELLETS, OF MEATOR MEAT OFFAL, OF FISH OR OFCRUSTACEANS, MOLLUSCS OR OTHERAQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES - FLOURS, MEALS AND PELLETS, OF MEAT OR MEAT OFFAL; GREAVES : MEAT MEALS AND PELLETS (INCLUDING TANKAGE)"
    },
    {
      "code": "23011090",
      "description": "FLOURS, MEALS AND PELLETS, OF MEATOR MEAT OFFAL, OF FISH OR OFCRUSTACEANS, MOLLUSCS OR OTHERAQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES - FLOURS, MEALS AND PELLETS, OF MEAT OR MEAT OFFAL; GREAVES : OTHER (INCLUDING GREAVES)"
    },
    {
      "code": "230120",
      "description": "Flours, meals and pellets, of fish or of crustaceans, molluscs or other aquatic invertebrates"
    },
    {
      "code": "23012011",
      "description": "FLOURS, MEALS AND PELLETS, OF MEATOR MEAT OFFAL, OF FISH OR OFCRUSTACEANS, MOLLUSCS OR OTHERAQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES - FLOURS, MEALS AND PELLETS, OF FISH OR OF CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : FISH MEAL, UNFIT FOR HUMAN CONSUMPTION : IN POWDER FORM"
    },
    {
      "code": "23012019",
      "description": "FLOURS, MEALS AND PELLETS, OF MEATOR MEAT OFFAL, OF FISH OR OFCRUSTACEANS, MOLLUSCS OR OTHERAQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES - FLOURS, MEALS AND PELLETS, OF FISH OR OF CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : FISH MEAL, UNFIT FOR HUMAN CONSUMPTION : OTHER"
    },
    {
      "code": "23012090",
      "description": "FLOURS, MEALS AND PELLETS, OF MEATOR MEAT OFFAL, OF FISH OR OFCRUSTACEANS, MOLLUSCS OR OTHERAQUATIC INVERTEBRATES, UNFIT FOR HUMAN CONSUMPTION; GREAVES - FLOURS, MEALS AND PELLETS, OF FISH OR OF CRUSTACEANS, MOLLUSCS OR OTHER AQUATIC INVERTEBRATES : OTHER"
    },
    {
      "code": "2302",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHER OR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS"
    },
    {
      "code": "230210",
      "description": "Of maize (corn)"
    },
    {
      "code": "23021010",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF MAIZE (CORN) : MAIZE BRAN"
    },
    {
      "code": "23021090",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF MAIZE (CORN) : OTHER"
    },
    {
      "code": "23022010",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF RICE : DE-OILED RICE BRAN"
    },
    {
      "code": "23022020",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF RICE : RICE BRAN, RAW"
    },
    {
      "code": "23022090",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF RICE : OTHER"
    },
    {
      "code": "23023000",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF WHEAT"
    },
    {
      "code": "23024000",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF OTHER CEREALS"
    },
    {
      "code": "23025000",
      "description": "BRAN, SHARPS AND OTHER RESIDUES, WHETHEROR NOT IN THE FORM OF PELLETS, DERIVED FROM THE SIFTING, MILLING OR OTHER WORKING OF CEREALS OR OF LEGUMINOUS PLANTS - OF LEGUMINOUS PLANTS"
    },
    {
      "code": "2303",
      "description": "RESIDUES OF STARCH MANUFACTURE AND SIMILAR RESIDUES, BEET-PULP, BAGASSE AND OTHER WASTE OF SUGAR MANUFACTURE, BREWING OR DISTILLING DREGS AND WASTE, WHETHER OR NOT IN THE FORM OF PELLETS"
    },
    {
      "code": "23031000",
      "description": "RESIDUES OF STARCH MANUFACTURE AND SIMILARRESIDUES, BEET-PULP, BAGASSE AND OTHERWASTE OF SUGAR MANUFACTURE, BREWING ORDISTILLING DREGS AND WASTE, WHETHER ORNOT IN THE FORM OF PELLETS RESIDUES OF STARCH MANUFACTURE AND SIMILAR RESIDUES"
    },
    {
      "code": "23032000",
      "description": "RESIDUES OF STARCH MANUFACTURE AND SIMILARRESIDUES, BEET-PULP, BAGASSE AND OTHERWASTE OF SUGAR MANUFACTURE, BREWING ORDISTILLING DREGS AND WASTE, WHETHER ORNOT IN THE FORM OF PELLETS BEET-PULP, BAGASSE AND OTHER WASTE OF SUGAR MANUFACTURE"
    },
    {
      "code": "23033000",
      "description": "RESIDUES OF STARCH MANUFACTURE AND SIMILARRESIDUES, BEET-PULP, BAGASSE AND OTHERWASTE OF SUGAR MANUFACTURE, BREWING ORDISTILLING DREGS AND WASTE, WHETHER ORNOT IN THE FORM OF PELLETS BREWING OR DISTILLING DREGS AND WASTE"
    },
    {
      "code": "2304",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF SOYABEAN OIL"
    },
    {
      "code": "230400",
      "description": "Oil-cake and other solid residues whether or not ground or in the form of pellets, resulting from the extraction of soyabean oil"
    },
    {
      "code": "23040010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFSOYABEAN OIL  - OIL-CAKE AND OTHER SOLID RESIDUES WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF SOYABEAN OIL : OIL-CAKE AND OIL-CAKE MEAL OF SOYABEAN, EXPELLER VARIETY"
    },
    {
      "code": "23040020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFSOYABEAN OIL  - OIL-CAKE AND OTHER SOLID RESIDUES WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF SOYABEAN OIL : OIL-CAKE OF SOYABEAN, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23040030",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFSOYABEAN OIL  - OIL-CAKE AND OTHER SOLID RESIDUES WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF SOYABEAN OIL : MEAL OF SOYABEAN, SOLVENT EXTRACTED (DEFATTED)"
    },
    {
      "code": "23040090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFSOYABEAN OIL  - OIL-CAKE AND OTHER SOLID RESIDUES WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF SOYABEAN OIL : OTHER"
    },
    {
      "code": "2305",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF GROUND-NUT OIL"
    },
    {
      "code": "230500",
      "description": "Oil-cake and other solid residues, whether or not ground or in the form of pellets, resulting from the extraction of ground-nut oil"
    },
    {
      "code": "23050010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFGROUND-NUT OIL - OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER ORNOT GROUND OR IN THE FORM OF PELLETS, RESULTINGFROM THE EXTRACTION OF GROUND-NUT OIL : OIL-CAKE AND OIL-CAKE MEAL OF GROUND-NUT, EXPELLER VARIETY"
    },
    {
      "code": "23050020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFGROUND-NUT OIL - OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER ORNOT GROUND OR IN THE FORM OF PELLETS, RESULTINGFROM THE EXTRACTION OF GROUND-NUT OIL : OIL-CAKE AND OIL-CAKE MEAL OF GROUND-NUT, SOLVENT EXTRACTED VARIETY (DEFATTED)"
    },
    {
      "code": "23050090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHEROR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OFGROUND-NUT OIL - OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER ORNOT GROUND OR IN THE FORM OF PELLETS, RESULTINGFROM THE EXTRACTION OF GROUND-NUT OIL : OTHER"
    },
    {
      "code": "2306",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OF PELLETS, RESULTING FROM THE EXTRACTION OF VEGETABLE FATS OR OILS, OTHER THAN THOSE OF HEADING 2304 OR 2305"
    },
    {
      "code": "230610",
      "description": "Of cotton seeds"
    },
    {
      "code": "23061010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COTTON SEEDS : OIL-CAKE AND OIL-CAKE MEAL, DECORTICATED EXPELLER VARIETY"
    },
    {
      "code": "23061020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COTTON SEEDS : OIL-CAKE AND OIL-CAKE MEAL, DECORTICATED, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23061030",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COTTON SEEDS : OIL-CAKE AND OIL-CAKE MEAL, UNDECORTICATED, EXPELLER VARIETY"
    },
    {
      "code": "23061040",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COTTON SEEDS : OIL-CAKE AND OIL-CAKE MEAL, UNDECORTICATED, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23061090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COTTON SEEDS : OTHER"
    },
    {
      "code": "230620",
      "description": "Of linseed"
    },
    {
      "code": "23062010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305  - OF LINSEED : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY"
    },
    {
      "code": "23062020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305  - OF LINSEED : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23062090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305  - OF LINSEED : OTHER"
    },
    {
      "code": "230630",
      "description": "Of sunflower seeds"
    },
    {
      "code": "23063010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF SUNFLOWER SEEDS : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY"
    },
    {
      "code": "23063020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF SUNFLOWER SEEDS : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23063090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF SUNFLOWER SEEDS : OTHER"
    },
    {
      "code": "23064100",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF RAPE OR COLZA SEEDS : OF LOW ERUCIC ACID RAPE OR COLZA SEEDS"
    },
    {
      "code": "23064900",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF RAPE OR COLZA SEEDS : OTHER"
    },
    {
      "code": "230650",
      "description": "Of coconut or copra"
    },
    {
      "code": "23065010",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COCONUT OR COPRA : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY"
    },
    {
      "code": "23065020",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COCONUT OR COPRA : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY"
    },
    {
      "code": "23065090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OF COCONUT OR COPRA : OTHER"
    },
    {
      "code": "23066000",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 OF PALM NUTS OR KERNELS"
    },
    {
      "code": "23067000",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 OF MAIZE (CORN) GERM"
    },
    {
      "code": "230690",
      "description": "Other"
    },
    {
      "code": "23069011",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF MOWRA SEEDS"
    },
    {
      "code": "23069012",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF MUSTARD SEEDS"
    },
    {
      "code": "23069013",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF NIGER SEEDS"
    },
    {
      "code": "23069014",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF SEASAMUM SEEDS"
    },
    {
      "code": "23069015",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF MANGO KERNEL"
    },
    {
      "code": "23069016",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF SAL (DE-OILED)"
    },
    {
      "code": "23069017",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF CASTOR SEEDS"
    },
    {
      "code": "23069018",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF NEEM SEEDS"
    },
    {
      "code": "23069019",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, EXPELLER VARIETY : OF OTHER SEEDS"
    },
    {
      "code": "23069021",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF MUSTARD SEEDS"
    },
    {
      "code": "23069022",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF NIGER SEEDS"
    },
    {
      "code": "23069023",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF CARDI SEEDS"
    },
    {
      "code": "23069024",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF SEASAMUM SEEDS"
    },
    {
      "code": "23069025",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF MANGO KERNEL"
    },
    {
      "code": "23069026",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF SAL (DE-OILED)"
    },
    {
      "code": "23069027",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF CASTOR SEEDS"
    },
    {
      "code": "23069028",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF NEEM SEEDS"
    },
    {
      "code": "23069029",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OIL-CAKE AND OIL-CAKE MEAL, SOLVENT EXTRACTED (DEFATTED) VARIETY : OF OTHER SEEDS"
    },
    {
      "code": "23069030",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : RESIDUES BABOOL SEED EXTRACTION"
    },
    {
      "code": "23069090",
      "description": "OIL-CAKE AND OTHER SOLID RESIDUES, WHETHER OR NOT GROUND OR IN THE FORM OFPELLETS, RESULTING FROM THE EXTRACTION OFVEGETABLE FATS OR OILS, OTHER THAN THOSEOF HEADING 2304 OR 2305 - OTHER : OTHER"
    },
    {
      "code": "2307",
      "description": "WINE LEES; ARGOL"
    },
    {
      "code": "23070000",
      "description": "WINE LEES; ARGOL"
    },
    {
      "code": "2308",
      "description": "VEGETABLE MATERIALS AND VEGETABLE WASTE, VEGETABLE RESIDUES AND BY-PRODUCTS, WHETHER OR NOT IN THE FORM OF PELLETS, OF A KIND USED IN ANIMAL FEEDING, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "23080000",
      "description": "VEGETABLE MATERIALS AND VEGETABLE WASTE, VEGETABLE RESIDUES AND BY-PRODUCTS, WHETHER OR NOT IN THE FORM OF PELLETS, OF A KIND USED IN ANIMAL FEEDING, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "2309",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING"
    },
    {
      "code": "23091000",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING DOG OR CAT FOOD, PUT UP FOR RETAIL SALE"
    },
    {
      "code": "230990",
      "description": "Other"
    },
    {
      "code": "23099010",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : COMPOUNDED ANIMAL FEED"
    },
    {
      "code": "23099020",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : CONCENTRATES FOR COMPOUND ANIMAL FEED"
    },
    {
      "code": "23099031",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : FEEDS FOR FISH (PRAWN, ETC.) : PRAWN AND SHRIMPS FEED"
    },
    {
      "code": "23099032",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : FEEDS FOR FISH (PRAWN, ETC.) : FISH MEAL IN POWDERED FORM"
    },
    {
      "code": "23099039",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : FEEDS FOR FISH (PRAWN, ETC.) : OTHER"
    },
    {
      "code": "23099090",
      "description": "PREPARATIONS OF A KIND USED IN ANIMAL FEEDING - OTHER : OTHER"
    },
    {
      "code": "24",
      "description": "Tobacco and manufactured tobacco substitutes"
    },
    {
      "code": "2401",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE"
    },
    {
      "code": "240110",
      "description": "Tobacco, not stemmed or stripped"
    },
    {
      "code": "24011010",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : FLUE CURED VIRGINIA TOBACCO"
    },
    {
      "code": "24011020",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : SUN CURED COUNTRY (NATU) TOBACCO"
    },
    {
      "code": "24011030",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : SUN CURED VIRGINIA TOBACCO"
    },
    {
      "code": "24011040",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : BURLEY TOBACCO"
    },
    {
      "code": "24011050",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF BIRIS, NOT STEMMED"
    },
    {
      "code": "24011060",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF CHEWING TOBACCO"
    },
    {
      "code": "24011070",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF CIGAR AND CHEROOT"
    },
    {
      "code": "24011080",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF HOOKAH TOBACCO"
    },
    {
      "code": "24011090",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, NOT STEMMED OR STRIPPED : OTHER"
    },
    {
      "code": "240120",
      "description": "Tobacco, partly or wholly stemmed or stripped"
    },
    {
      "code": "24012010",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : FLUE CURED VIRGINIA TOBACCO"
    },
    {
      "code": "24012020",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : SUN CURED COUNTRY (NATU) TOBACCO"
    },
    {
      "code": "24012030",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : SUN CURED VIRGINIA TOBACCO"
    },
    {
      "code": "24012040",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : BURLEY TOBACCO"
    },
    {
      "code": "24012050",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF BIRIS"
    },
    {
      "code": "24012060",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF CHEWING TOBACCO"
    },
    {
      "code": "24012070",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF CIGAR AND CHEROOT"
    },
    {
      "code": "24012080",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : TOBACCO FOR MANUFACTURE OF HOOKAH TOBACCO"
    },
    {
      "code": "24012090",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO, PARTLY OR WHOLLY STEMMED OR STRIPPED : OTHER"
    },
    {
      "code": "24013000",
      "description": "UNMANUFACTURED TOBACCO; TOBACCO REFUSE - TOBACCO REFUSE"
    },
    {
      "code": "2402",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES"
    },
    {
      "code": "240210",
      "description": "Cigars, cheroots and cigarillos, containing tobacco"
    },
    {
      "code": "24021010",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARS, CHEROOTS AND CIGARILLOS, CONTAINING TOBACCO : CIGAR AND CHEROOTS"
    },
    {
      "code": "24021020",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARS, CHEROOTS AND CIGARILLOS, CONTAINING TOBACCO : CIGARILLOS"
    },
    {
      "code": "240220",
      "description": "Cigarettes, containing tobacco"
    },
    {
      "code": "24022010",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARS, CHEROOTS AND CIGARILLOS, CONTAINING TOBACCO : CIGAR AND CHEROOTS"
    },
    {
      "code": "24022020",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARS, CHEROOTS AND CIGARILLOS, CONTAINING TOBACCO : CIGARILLOS"
    },
    {
      "code": "24022030",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARETTES, CONTAINING TOBACCO : FILTER CIGARETTES OF LENGTH (INCLUDING THE LENGTH OF THE FILTER, THE LENGTH OF FILTER BEING 11 MILLIMETRES OR ITS ACTUAL LENGTH, WHICHEVER IS MORE) NOT EXCEEDING 60 MILLIMETRES"
    },
    {
      "code": "24022040",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARETTES, CONTAINING TOBACCO : FILTER CIGARETTES OF LENGTH (INCLUDING THE LENGTH OF THE FILTER, THE LENGTH OF FILTER BEING 11 MILLIMETRES OR ITS ACTUAL LENGTH, WHICHEVER IS MORE) EXCEEDING 60 MILLIMETRES BUT NOT EXCEEDING 70 MILLIMETRES"
    },
    {
      "code": "24022050",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARETTES, CONTAINING TOBACCO : FILTER CIGARETTES OF LENGTH (INCLUDING THE LENGTH OF THE FILTER, THE LENGTH OF FILTER BEING 11 MILLIMETRES OR ITS ACTUAL LENGTH, WHICHEVER IS MORE) EXCEEDING 70 MILLIMETRES BUT NOT EXCEEDING 75 MILLIMETRES"
    },
    {
      "code": "24022090",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - CIGARETTES, CONTAINING TOBACCO : OTHER"
    },
    {
      "code": "240290",
      "description": "Other"
    },
    {
      "code": "24029010",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - OTHER : CIGARETTES OF TOBACCO SUBSTITUTES"
    },
    {
      "code": "24029020",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - OTHER : CIGARILLOS OF TOBACCO SUBSTITUTES"
    },
    {
      "code": "24029090",
      "description": "CIGARS, CHEROOTS, CIGARILLOS AND CIGARETTES, OF TOBACCO OR OF TOBACCO SUBSTITUTES - OTHER : OTHER"
    },
    {
      "code": "2403",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES; HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - Somking tobacco, wether or not contaiang tobacco substitue in any proportion;"
    },
    {
      "code": "240310",
      "description": "Smoking tobacco, whether or not containing tobacco substitutes in any proportion"
    },
    {
      "code": "240311",
      "description": "Water pipe tobacco specified in Sub-heading Note to this chapter"
    },
    {
      "code": "24031110",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--WATER PIPE TOBACCO SPECIFIED IN SUB-HEADING NOTE TO THIS CHAPTER---HUKKAH OR GUDAKU TOBACCO"
    },
    {
      "code": "24031190",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--WATER PIPE TOBACCO SPECIFIED IN SUB-HEADING NOTE TO THIS CHAPTER---OTHER"
    },
    {
      "code": "240319",
      "description": "Other"
    },
    {
      "code": "24031910",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--OTHER---SMOKING MIXTURES FOR PIPES AND CIGARETTES"
    },
    {
      "code": "24031921",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--OTHER---BIRIS---OTHER THAN PAPER ROLLED BIRIS, MANUFACTURED WITHOUT THE AID OF TOBACCO"
    },
    {
      "code": "24031929",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--OTHER---BIRIS----OTHER"
    },
    {
      "code": "24031990",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;\"HOMOGENISED\" OR \"RECONSTITUTED\" TOBACCO;TOBACCO EXTRACTS AND ESSENCES-SMOKING TOBACCO, WHETHER OR NTO CONTAINING TOBACCO SUBSTITUTES IN ANY PROPORTION--OTHER---OTHER"
    },
    {
      "code": "24039100",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : HOMOGENISED OR RECONSTITUTED TOBACCO"
    },
    {
      "code": "240399",
      "description": "Other"
    },
    {
      "code": "24039910",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : CHEWING TOBACCO"
    },
    {
      "code": "24039920",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : PREPARATIONS CONTAINING CHEWING TOBACCO"
    },
    {
      "code": "24039930",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : JARDA SCENTED TOBACCO"
    },
    {
      "code": "24039940",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : SNUFF"
    },
    {
      "code": "24039950",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : PREPARATIONS CONTAINING SNUFF"
    },
    {
      "code": "24039960",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : TOBACCO EXTRACTS AND ESSENCE"
    },
    {
      "code": "24039970",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : CUT-TOBACCO"
    },
    {
      "code": "24039990",
      "description": "OTHER MANUFACTURED TOBACCO AND MANUFACTURED TOBACCO SUBSTITUTES;HOMOGENISED OR RECONSTITUTED TOBACCO; TOBACCO EXTRACTS AND ESSENCES - OTHER : OTHER : OTHER"
    },
    {
      "code": "25",
      "description": "Mineral Products-Salt; sulphur; earths and stone; plastering materials, lime and cement"
    },
    {
      "code": "2501",
      "description": "SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER"
    },
    {
      "code": "250100",
      "description": "Salt (including table salt and denatured salt) and pure sodium chloride, whether or not in aqueous solution or containing added anti-caking or free flowing agents; Sea water"
    },
    {
      "code": "25010010",
      "description": "SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER - SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER: COMMON SALT (INCLUDING IODISED SALT)"
    },
    {
      "code": "25010020",
      "description": "SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER - SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER: ROCK SALT"
    },
    {
      "code": "25010090",
      "description": "SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER - SALT (INCLUDING TABLE SALT AND DENATURED SALT) AND PURE SODIUM CHLORIDE, WHETHER OR NOT IN AQUEOUS SOLUTION OR CONTAINING ADDED ANTI-CAKING OR FREE FLOWING AGENTS; SEA WATER: OTHER"
    },
    {
      "code": "2502",
      "description": "UNROASTED IRON PYRITES"
    },
    {
      "code": "25020000",
      "description": "UNROASTED IRON PYRITES"
    },
    {
      "code": "2503",
      "description": "SULPHUR OF ALL KINDS, OTHER THAN SUBLIMED SULPHUR, PRECIPITATED SULPHUR AND COLLODIAL SULPHUR"
    },
    {
      "code": "250300",
      "description": "Sulphur of all kinds, other than sublimed sulphur, precipitated sulphur and collodial sulphur"
    },
    {
      "code": "25030010",
      "description": "SULPHUR OF ALL KINDS, OTHER THAN SUBLIMED SULPHUR, PRECIPITATED SULPHUR AND COLLODIAL SULPHUR - SULPHUR OF ALL KINDS, OTHER THAN SUBLIMED SULPHUR, PRECIPITATED SULPHUR AND COLLODIAL SULPHUR: SULPHUR RECOVERED AS BY-PRODUCT IN REFINING OF CRUDE OIL"
    },
    {
      "code": "25030090",
      "description": "SULPHUR OF ALL KINDS, OTHER THAN SUBLIMED SULPHUR, PRECIPITATED SULPHUR AND COLLODIAL SULPHUR - SULPHUR OF ALL KINDS, OTHER THAN SUBLIMED SULPHUR, PRECIPITATED SULPHUR AND COLLODIAL SULPHUR: OTHER"
    },
    {
      "code": "2504",
      "description": "NATURAL GRAPHITE"
    },
    {
      "code": "250410",
      "description": "In powder or in flakes"
    },
    {
      "code": "25041010",
      "description": "NATURAL GRAPHITE - IN POWDER OR IN FLAKES:GRAPHITE, CRYSTALLINE"
    },
    {
      "code": "25041020",
      "description": "NATURAL GRAPHITE - IN POWDER OR IN FLAKES:GRAPHITE, AMORPHOUS"
    },
    {
      "code": "25041090",
      "description": "NATURAL GRAPHITE - IN POWDER OR IN FLAKES:OTHER"
    },
    {
      "code": "250490",
      "description": "Other"
    },
    {
      "code": "25049010",
      "description": "NATURAL GRAPHITE - OTHER: GRAPHITE, MICRONISED"
    },
    {
      "code": "25049090",
      "description": "NATURAL GRAPHITE - OTHER: OTHER"
    },
    {
      "code": "2505",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26"
    },
    {
      "code": "250510",
      "description": "Silica sands and quartz sands"
    },
    {
      "code": "25051011",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26 - SILICA SANDS AND QUARTZ SANDS: SILICA SANDS: PROCESSED (WHITE)"
    },
    {
      "code": "25051012",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26 - SILICA SANDS AND QUARTZ SANDS: SILICA SANDS: PROCESSED (BROWN)"
    },
    {
      "code": "25051019",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26 - SILICA SANDS AND QUARTZ SANDS: SILICA SANDS: OTHER"
    },
    {
      "code": "25051020",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26 - SILICA SANDS AND QUARTZ SANDS: QUARTZ SANDS"
    },
    {
      "code": "25059000",
      "description": "NATURAL SANDS OF ALL KINDS, WHETHER OR NOT COLOURED, OTHER THAN METAL-BEARING SANDS OF CHAPTER 26 OTHER"
    },
    {
      "code": "2506",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "250610",
      "description": "Quartz"
    },
    {
      "code": "25061010",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZ: IN LUMPS"
    },
    {
      "code": "25061020",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZ: IN POWDER"
    },
    {
      "code": "250620",
      "description": "Quartzite"
    },
    {
      "code": "25062010",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE --- IN LUMPS"
    },
    {
      "code": "25062020",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE --- IN POWDER"
    },
    {
      "code": "25062090",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE --- OTHER"
    },
    {
      "code": "25062110",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE : CRUDE OR ROUGHLY TRIMMED: IN LUMPS"
    },
    {
      "code": "25062120",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE : CRUDE OR ROUGHLY TRIMMED: IN POWDER"
    },
    {
      "code": "25062190",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE : CRUDE OR ROUGHLY TRIMMED: OTHER"
    },
    {
      "code": "25062900",
      "description": "QUARTZ (OTHER THAN NATURAL SANDS); QUARTZITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - QUARTZITE : OTHER"
    },
    {
      "code": "2507",
      "description": "KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED"
    },
    {
      "code": "250700",
      "description": "Kaolin and other kaolinic clays, whether or not calcined"
    },
    {
      "code": "25070010",
      "description": "KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED - KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED: CRUDE"
    },
    {
      "code": "25070021",
      "description": "KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED - KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED: OTHER: PHARMACEUTICAL GRADE"
    },
    {
      "code": "25070022",
      "description": "KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED - KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED: OTHER: CERAMIC GRADE"
    },
    {
      "code": "25070029",
      "description": "KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED - KAOLIN AND OTHER KAOLINIC CLAYS, WHETHER OR NOT CALCINED: OTHER: OTHER"
    },
    {
      "code": "2508",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS"
    },
    {
      "code": "250810",
      "description": "Bentonite"
    },
    {
      "code": "25081010",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - BENTONITE: CRUDE"
    },
    {
      "code": "25081090",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - BENTONITE: OTHER (INCLUDES PROCESSED AND GROUND)"
    },
    {
      "code": "25082010",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - DECOLOURISING EARTHS AND FULLERS EARTH: PROCESSED (INCLUDING ACTIVATED)"
    },
    {
      "code": "25082090",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - DECOLOURISING EARTHS AND FULLERS EARTH: OTHER"
    },
    {
      "code": "250830",
      "description": "Fire clay"
    },
    {
      "code": "25083010",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - FIRE CLAY: NON-PLASTIC"
    },
    {
      "code": "25083020",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - FIRE CLAY: SEMI-PLASTIC"
    },
    {
      "code": "25083030",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - FIRE CLAY: PLASTIC"
    },
    {
      "code": "25083090",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - FIRE CLAY: OTHER"
    },
    {
      "code": "250840",
      "description": "Other clays"
    },
    {
      "code": "25084010",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - OTHER CLAYS: BALL CLAY"
    },
    {
      "code": "25084020",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - OTHER CLAYS: EARTH CLAY"
    },
    {
      "code": "25084090",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - OTHER CLAYS: OTHER"
    },
    {
      "code": "250850",
      "description": "Andalusite, kyanite and sillimanite"
    },
    {
      "code": "25085010",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: ANDALUSITE"
    },
    {
      "code": "25085021",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: KYANITE: CRUDE, OTHER THAN CALCINED"
    },
    {
      "code": "25085022",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: KYANITE: PROCESSED, OTHER THAN CALCINED (WASHED OR GROUND OR SCREENED OR BENEFICIATED)"
    },
    {
      "code": "25085023",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: KYANITE: CALCINED"
    },
    {
      "code": "25085031",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: SILLIMANITE: LUMPS"
    },
    {
      "code": "25085032",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: SILLIMANITE: FINES (INCLUDING SAND)"
    },
    {
      "code": "25085039",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - ANDALUSITE, KYANITE AND SILLIMANITE: SILLIMANITE: OTHER"
    },
    {
      "code": "25086000",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - MULLITE"
    },
    {
      "code": "25087000",
      "description": "OTHER CLAYS (NOT INCLUDING EXPANDED CLAYS OF HEADING 6806), ANDALUSITE, KYANITE AND SILLIMANITE, WHETHER OR NOT CALCINED; MULLITE; CHAMOTTE OR DINAS EARTHS - CHAMOTTE OR DINAS EARTHS"
    },
    {
      "code": "2509",
      "description": "CHALK"
    },
    {
      "code": "25090000",
      "description": "CHALK"
    },
    {
      "code": "2510",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK"
    },
    {
      "code": "251010",
      "description": "Unground"
    },
    {
      "code": "25101010",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - UNGROUND: NATURAL CALCIUM PHOSPHATE"
    },
    {
      "code": "25101020",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - UNGROUND: NATURAL ALUMINIUM CALCIUM PHOSPHATE"
    },
    {
      "code": "25101030",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - UNGROUND: NATURAL CALCIUM PHOSPHATE APATITE"
    },
    {
      "code": "25101090",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - UNGROUND: OTHER"
    },
    {
      "code": "251020",
      "description": "Ground"
    },
    {
      "code": "25102010",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - GROUND: NATURAL CALCIUM PHOSPHATES"
    },
    {
      "code": "25102020",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - GROUND: NATURAL ALUMINIUM CALCIUM PHOSPHATE"
    },
    {
      "code": "25102030",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - GROUND: NATURAL CALCIUM PHOSPHATES APATITE"
    },
    {
      "code": "25102090",
      "description": "NATURAL CALCIUM PHOSPHATES, NATURAL ALUMINIUM CALCIUM PHOSPHATES AND PHOSPHATIC CHALK - GROUND: OTHER"
    },
    {
      "code": "2511",
      "description": "NATURAL BARIUM SULPHATE (BARYTES); NATURAL BARIUM CARBONATE (WITHERITE), WHETHER OR NOT CALCINED, OTHER THAN BARIUM OXIDE OF HEADING 2816"
    },
    {
      "code": "251110",
      "description": "Natural barium sulphate (barytes)"
    },
    {
      "code": "25111010",
      "description": "NATURAL BARIUM SULPHATE (BARYTES); NATURAL BARIUM CARBONATE (WITHERITE), WHETHER OR NOT CALCINED, OTHER THAN BARIUM OXIDE OF HEADING 2816 - NATURAL BARIUM SULPHATE (BARYTES): LUMPS"
    },
    {
      "code": "25111020",
      "description": "NATURAL BARIUM SULPHATE (BARYTES); NATURAL BARIUM CARBONATE (WITHERITE), WHETHER OR NOT CALCINED, OTHER THAN BARIUM OXIDE OF HEADING 2816 - NATURAL BARIUM SULPHATE (BARYTES): POWDER"
    },
    {
      "code": "25111090",
      "description": "NATURAL BARIUM SULPHATE (BARYTES); NATURAL BARIUM CARBONATE (WITHERITE), WHETHER OR NOT CALCINED, OTHER THAN BARIUM OXIDE OF HEADING 2816 - NATURAL BARIUM SULPHATE (BARYTES): OTHER"
    },
    {
      "code": "25112000",
      "description": "NATURAL BARIUM SULPHATE (BARYTES); NATURAL BARIUM CARBONATE (WITHERITE), WHETHER OR NOT CALCINED, OTHER THAN BARIUM OXIDE OF HEADING 2816 - NATURAL BARIUM CARBONATE (WITHERITE)"
    },
    {
      "code": "2512",
      "description": "SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS"
    },
    {
      "code": "251200",
      "description": "Siliceous fossil meals (for example, kieselguhr, tripolite and diatomite) and similar siliceous earths, whether or not calcined, of an apparent specific gravity of 1 or less"
    },
    {
      "code": "25120010",
      "description": "SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS - SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS: KIESELGUHR"
    },
    {
      "code": "25120020",
      "description": "SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS - SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS: TRIPOLITE"
    },
    {
      "code": "25120030",
      "description": "SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS - SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS: DIATOMITE"
    },
    {
      "code": "25120090",
      "description": "SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS - SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE AND DIATOMITE) AND SIMILAR SILICEOUS EARTHS, WHETHER OR NOT CALCINED, OF AN APPARENT SPECIFIC GRAVITY OF 1 OR LESS: OTHER"
    },
    {
      "code": "2513",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED"
    },
    {
      "code": "25131000",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - PUMICE STONE"
    },
    {
      "code": "25131100",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - PUMICE STONE: CRUDE OR IN IRREGULAR PIECES, INCLUDING CRUSHED PUMICE (BIMSKIES)"
    },
    {
      "code": "25131900",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - PUMICE STONE: OTHER"
    },
    {
      "code": "251320",
      "description": "Emery, natural corundum, natural garnet and other natural abrasives"
    },
    {
      "code": "25132010",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - EMERY, NATURAL CORUNDUM, NATURAL GARNETAND OTHER NATURAL ABRASIVES: EMERY"
    },
    {
      "code": "25132020",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - EMERY, NATURAL CORUNDUM, NATURAL GARNETAND OTHER NATURAL ABRASIVES: NATURAL CORUNDUM"
    },
    {
      "code": "25132030",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - EMERY, NATURAL CORUNDUM, NATURAL GARNETAND OTHER NATURAL ABRASIVES: NATURAL GARNET"
    },
    {
      "code": "25132090",
      "description": "PUMICE STONE; EMERY; NATURAL CORUNDUM, NATURAL GARNET AND OTHER NATURAL ABRASIVES, WHETHER OR NOT HEAT-TREATED - EMERY, NATURAL CORUNDUM, NATURAL GARNETAND OTHER NATURAL ABRASIVES: OTHER"
    },
    {
      "code": "2514",
      "description": "SLATE, WHETHER OR NOT ROUGHLY TRIMMED kg. Nil OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "25140000",
      "description": "SLATE, WHETHER OR NOT ROUGHLY TRIMMED kg. Nil OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "2515",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "25151100",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - MARBLE AND TRAVERTINE: CRUDE OR ROUGHLY TRIMMED"
    },
    {
      "code": "251512",
      "description": "Merely cut, by sawing or otherwise, into blocks or slabs of a rectangular (including square) shape"
    },
    {
      "code": "25151210",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - MARBLE AND TRAVERTINE: MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE: BLOCKS"
    },
    {
      "code": "25151220",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - MARBLE AND TRAVERTINE: MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE: SLABS"
    },
    {
      "code": "25151290",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - MARBLE AND TRAVERTINE: MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE: OTHER"
    },
    {
      "code": "251520",
      "description": "Ecaussine and other calcareous monumental or building stone; alabaster"
    },
    {
      "code": "25152010",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE; ALABASTER: ALABASTER"
    },
    {
      "code": "25152090",
      "description": "MARBLE, TRAVERTINE, ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE OF AN APPARENT SPECIFIC GRAVITY OF 2.5 OR MORE, AND ALABASTER, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - ECAUSSINE AND OTHER CALCAREOUS MONUMENTAL OR BUILDING STONE; ALABASTER: OTHER"
    },
    {
      "code": "2516",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "25161100",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - GRANITE : CRUDE OR ROUGHLY TRIMMED"
    },
    {
      "code": "25161200",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - GRANITE : MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "25162000",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - SANDSTONE"
    },
    {
      "code": "25162100",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - SANDSTONE: CRUDE OR ROUGHLY TRIMMED"
    },
    {
      "code": "25162200",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - SANDSTONE: MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE"
    },
    {
      "code": "251690",
      "description": "Other monumental or building stone"
    },
    {
      "code": "25169010",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - OTHER MONUMENTAL OR BUILDING STONE: PAKUR STONE"
    },
    {
      "code": "25169020",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - OTHER MONUMENTAL OR BUILDING STONE: STONE BOULDERS"
    },
    {
      "code": "25169090",
      "description": "GRANITE, PORPHYRY, BASALT, SANDSTONE AND OTHER MONUMENTAL OR BUILDING STONE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE - OTHER MONUMENTAL OR BUILDING STONE: OTHER"
    },
    {
      "code": "2517",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT  INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED"
    },
    {
      "code": "251710",
      "description": "Pebbles, gravel, broken or crushed stone, of a kind commonly used for concrete aggregates, for road metalling or for railway or other ballast, shingle and flint, whether or not heat-treated"
    },
    {
      "code": "25171010",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED - PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED: PAKUR STONE, CRUSHED OR BROKEN"
    },
    {
      "code": "25171020",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED: FLINT"
    },
    {
      "code": "25171090",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED: OTHER"
    },
    {
      "code": "25172000",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED -MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN SUB-HEADING 2517 10"
    },
    {
      "code": "25173000",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED TARRED MACADAM"
    },
    {
      "code": "25174100",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED - GRANULES, CHIPPINGS AND POWDER, OF STONE OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED: OF MARBLE"
    },
    {
      "code": "25174900",
      "description": "PEBBLES, GRAVEL, BROKEN OR CRUSHED STONE, OF A KIND COMMONLY USED FOR CONCRETE AGGREGATES, FOR ROAD METALLING OR FOR RAILWAY OR OTHER BALLAST, SHINGLE AND FLINT, WHETHER OR NOT HEAT-TREATED; MACADAM OF SLAG, DROSS OR SIMILAR INDUSTRIAL WASTE, WHETHER OR NOT INCORPORATING THE MATERIALS CITED IN THE FIRST PART OF THE HEADING; TARRED MACADAM; GRANULES, CHIPPINGS AND POWDER, OF STONES OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED - GRANULES, CHIPPINGS AND POWDER, OF STONE OF HEADING 2515 OR 2516, WHETHER OR NOT HEAT-TREATED: OTHER"
    },
    {
      "code": "2518",
      "description": "DOLOMITE, WHETHER OR NOT CALCINED OR SINTERED, INCLUDING DOLOMITE ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; DOLOMITE RAMMING MIX"
    },
    {
      "code": "25181000",
      "description": "DOLOMITE, WHETHER OR NOT CALCINED OR SINTERED, INCLUDING DOLOMITE ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; DOLOMITE RAMMING MIX -DOLOMITE NOT CALCINED OR SINTERED"
    },
    {
      "code": "25182000",
      "description": "DOLOMITE, WHETHER OR NOT CALCINED OR SINTERED, INCLUDING DOLOMITE ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; DOLOMITE RAMMING MIX -CALCINED OR SINTERED DOLOMITE"
    },
    {
      "code": "25183000",
      "description": "DOLOMITE, WHETHER OR NOT CALCINED OR SINTERED, INCLUDING DOLOMITE ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; DOLOMITE RAMMING MIX -DOLOMITE RAMMING MIX"
    },
    {
      "code": "2519",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE"
    },
    {
      "code": "25191000",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - NATURAL MAGNESIUM CARBONATE (MAGNESITE)"
    },
    {
      "code": "251990",
      "description": "Other"
    },
    {
      "code": "25199010",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - OTHER: FUSED MAGNESIA (NATURAL)"
    },
    {
      "code": "25199020",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - OTHER: DEAD-BURNT (SINTERED) MAGNESIA"
    },
    {
      "code": "25199030",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - OTHER: MAGNESIUM CALCINED (OTHER THAN DEAD-BURNT) NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "25199040",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - OTHER: MAGNESIUM OXIDE"
    },
    {
      "code": "25199090",
      "description": "NATURAL MAGNESIUM CARBONATE (MAGNESITE); FUSED MAGNESIA; DEAD-BURNED (SINTERED) MAGNESIA, WHETHER OR NOT CONTAINING SMALL QUANTITIES OF OTHER OXIDES ADDED BEFORE SINTERING; OTHER MAGNESIUM OXIDE, WHETHER OR NOT PURE - OTHER: OTHER"
    },
    {
      "code": "2520",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL UANTITIES OF ACCELERATORS OR RETARDERS"
    },
    {
      "code": "252010",
      "description": "Gypsum; anhydrite"
    },
    {
      "code": "25201010",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL QUANTITIES OF ACCELERATORS OR RETARDERS - GYPSUM; ANHYDRITE: NATURAL"
    },
    {
      "code": "25201020",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL QUANTITIES OF ACCELERATORS OR RETARDERS - GYPSUM; ANHYDRITE: MARINE"
    },
    {
      "code": "25201090",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL QUANTITIES OF ACCELERATORS OR RETARDERS - GYPSUM; ANHYDRITE: OTHER"
    },
    {
      "code": "252020",
      "description": "Plasters"
    },
    {
      "code": "25202010",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL QUANTITIES OF ACCELERATORS OR RETARDERS - PLASTERS: CALCINED"
    },
    {
      "code": "25202090",
      "description": "GYPSUM; ANHYDRITE; PLASTERS (CONSISTING OF CALCINED GYPSUM OR CALCIUM SULPHATE) WHETHER OR NOT COLOURED, WITH OR WITHOUT SMALL QUANTITIES OF ACCELERATORS OR RETARDERS - PLASTERS: OTHER"
    },
    {
      "code": "2521",
      "description": "LIMESTONE FLUX; LIMESTONE AND OTHER CALCAREOUS STONES, OF A KIND USED FOR THE MANUFACTURE OF LIME OR CEMENT"
    },
    {
      "code": "252100",
      "description": "Limestone flux; limestone and other calcareous stones, of a kind used for the manufacture of lime or cement"
    },
    {
      "code": "25210010",
      "description": "LIMESTONE FLUX; LIMESTONE AND OTHER CALCAREOUS STONES, OF A KIND USED FOR THE MANUFACTURE OF LIME OR CEMENT - LIMESTONE FLUX; LIMESTONE AND OTHER CALCAREOUS STONES, OF A KIND USED FOR THE MANUFACTURE OF LIME OR CEMENT: LIMESTONE FLUX (L.D., BELOW 1% SIO2)"
    },
    {
      "code": "25210090",
      "description": "LIMESTONE FLUX; LIMESTONE AND OTHER CALCAREOUS STONES, OF A KIND USED FOR THE MANUFACTURE OF LIME OR CEMENT - LIMESTONE FLUX; LIMESTONE AND OTHER CALCAREOUS STONES, OF A KIND USED FOR THE MANUFACTURE OF LIME OR CEMENT: OTHER"
    },
    {
      "code": "2522",
      "description": "QUICKLIME, SLAKED LIME AND HYDRAULIC LIME, OTHER THAN CALCIUM OXIDE AND HYDROXIDE OF HEADING 2825"
    },
    {
      "code": "25221000",
      "description": "QUICKLIME, SLAKED LIME AND HYDRAULIC LIME, OTHER THAN CALCIUM OXIDE AND HYDROXIDE OF HEADING 2825 QUICKLIME"
    },
    {
      "code": "25222000",
      "description": "QUICKLIME, SLAKED LIME AND HYDRAULIC LIME, OTHER THAN CALCIUM OXIDE AND HYDROXIDE OF HEADING 2825 SLAKED LIME"
    },
    {
      "code": "25223000",
      "description": "QUICKLIME, SLAKED LIME AND HYDRAULIC LIME, OTHER THAN CALCIUM OXIDE AND HYDROXIDE OF HEADING 2825 HYDRAULIC LIME"
    },
    {
      "code": "2523",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS"
    },
    {
      "code": "25231000",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS CEMENT CLINKERS"
    },
    {
      "code": "25232100",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: WHITE CEMENT, WHETHER OR NOT ARTIFICIALLY COLOURED"
    },
    {
      "code": "252329",
      "description": "Other"
    },
    {
      "code": "25232910",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: OTHER: ORDINARY PORTLAND CEMENT, DRY"
    },
    {
      "code": "25232920",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: OTHER: ORDINARY PORTLAND CEMENT, COLOURED"
    },
    {
      "code": "25232930",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: OTHER: PORTLAND POZZOLANA CEMENT"
    },
    {
      "code": "25232940",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: OTHER: PORTLAND SLAG CEMENT"
    },
    {
      "code": "25232990",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - PORTLAND CEMENT: OTHER: OTHER"
    },
    {
      "code": "25233000",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS -ALUMINOUS CEMENT"
    },
    {
      "code": "252390",
      "description": "Other hydraulic cements"
    },
    {
      "code": "25239010",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - OTHER HYDRAULIC CEMENTS: SAGOL; ASHMOH"
    },
    {
      "code": "25239020",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - OTHER HYDRAULIC CEMENTS: HIGH ALUMINA REFRACTORY CEMENT"
    },
    {
      "code": "25239090",
      "description": "PORTLAND CEMENT, ALUMINOUS CEMENT, SLAG CEMENT, SUPERSULPHATE CEMENT AND SIMILAR HYDRAULIC CEMENTS, WHETHER OR NOT COLOURED OR IN THE FORM OF CLINKERS - OTHER HYDRAULIC CEMENTS: OTHER"
    },
    {
      "code": "2524",
      "description": "ASBESTOS"
    },
    {
      "code": "25240011",
      "description": "ASBESTOS - ASBESTOS: ASBESTOS IN ROCK FORM: CHRYSOTILE"
    },
    {
      "code": "25240012",
      "description": "ASBESTOS - ASBESTOS: ASBESTOS IN ROCK FORM: AMPHIBOLE"
    },
    {
      "code": "25240019",
      "description": "ASBESTOS - ASBESTOS: ASBESTOS IN ROCK FORM: OTHER"
    },
    {
      "code": "25240021",
      "description": "ASBESTOS - ASBESTOS: FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH : CHRYSOLITE"
    },
    {
      "code": "25240022",
      "description": "ASBESTOS - ASBESTOS: FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH : AMOSITE"
    },
    {
      "code": "25240029",
      "description": "ASBESTOS - ASBESTOS: FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH : OTHER"
    },
    {
      "code": "25240031",
      "description": "ASBESTOS - ASBESTOS: FLAKES OR POWDER: AMOSITE"
    },
    {
      "code": "25240039",
      "description": "ASBESTOS - ASBESTOS: FLAKES OR POWDER: OTHER"
    },
    {
      "code": "25240091",
      "description": "ASBESTOS - ASBESTOS: OTHER: WASTE"
    },
    {
      "code": "25240099",
      "description": "ASBESTOS - ASBESTOS: OTHER: OTHER"
    },
    {
      "code": "25241000",
      "description": "ASBESTOS - CROCIDOLITE"
    },
    {
      "code": "25241010",
      "description": "Crocidolite"
    },
    {
      "code": "252490",
      "description": "Other"
    },
    {
      "code": "25249011",
      "description": "ASBESTOS - OTHER --- IN ROCK FORM ---- CHRYSOTILE"
    },
    {
      "code": "25249012",
      "description": "ASBESTOS - OTHER --- IN ROCK FORM ---- AMPHIBOLE"
    },
    {
      "code": "25249013",
      "description": "ASBESTOS - OTHER --- IN ROCK FORM ---- CRYSOLITE"
    },
    {
      "code": "25249014",
      "description": "ASBESTOS - OTHER --- IN ROCK FORM ---- AMOSITE"
    },
    {
      "code": "25249019",
      "description": "ASBESTOS - OTHER --- IN ROCK FORM ---- OTHER"
    },
    {
      "code": "25249021",
      "description": "ASBESTOS - OTHER --- FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH ---- CHRYSOTILE"
    },
    {
      "code": "25249022",
      "description": "ASBESTOS - OTHER --- FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH ---- AMPHIBOLE"
    },
    {
      "code": "25249023",
      "description": "ASBESTOS - OTHER --- FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH ---- CRYSOLITE"
    },
    {
      "code": "25249024",
      "description": "ASBESTOS - OTHER --- FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH ---- AMOSITE"
    },
    {
      "code": "25249029",
      "description": "ASBESTOS - OTHER --- FIBRE RAW, BEATEN OR WASHED OR GRADED TO LENGTH ---- OTHER"
    },
    {
      "code": "25249031",
      "description": "ASBESTOS - OTHER --- FLAKES OR POWDER ---- CHRYSOTILE"
    },
    {
      "code": "25249032",
      "description": "ASBESTOS - OTHER --- FLAKES OR POWDER ---- AMPHIBOLE"
    },
    {
      "code": "25249033",
      "description": "ASBESTOS - OTHER --- FLAKES OR POWDER ---- CRYSOLITE"
    },
    {
      "code": "25249034",
      "description": "ASBESTOS - OTHER --- FLAKES OR POWDER ---- AMOSITE"
    },
    {
      "code": "25249039",
      "description": "ASBESTOS - OTHER --- FLAKES OR POWDER ---- OTHER"
    },
    {
      "code": "25249091",
      "description": "ASBESTOS - OTHER --- OTHER ---- WASTE"
    },
    {
      "code": "25249099",
      "description": "ASBESTOS - OTHER --- OTHER ---- OTHER"
    },
    {
      "code": "2525",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE"
    },
    {
      "code": "252510",
      "description": "Crude mica and mica rifted into sheets or splittings"
    },
    {
      "code": "25251010",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - CRUDE MICA AND MICA RIFTED INTO SHEETS OR SPLITTINGS: MICA BLOCKS"
    },
    {
      "code": "25251020",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - CRUDE MICA AND MICA RIFTED INTO SHEETS OR SPLITTINGS: CONDENSOR FILMS TRIMMED BUT NOT CUT TO SHAPE"
    },
    {
      "code": "25251030",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - CRUDE MICA AND MICA RIFTED INTO SHEETS OR SPLITTINGS: MICA SPLITTINGS, BOOK FORM"
    },
    {
      "code": "25251040",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - CRUDE MICA AND MICA RIFTED INTO SHEETS OR SPLITTINGS: MICA SPLITTINGS, LOOSE"
    },
    {
      "code": "25251090",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - CRUDE MICA AND MICA RIFTED INTO SHEETS OR SPLITTINGS: OTHER"
    },
    {
      "code": "252520",
      "description": "Mica powder"
    },
    {
      "code": "25252010",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: MICA FLAKES, 2.20 MESH"
    },
    {
      "code": "25252020",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: MICA POWDER, DRY GROUND"
    },
    {
      "code": "25252030",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: MICA POWDER, MICRONISED"
    },
    {
      "code": "25252040",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: MICA POWDER, WET GROUND"
    },
    {
      "code": "25252050",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: MICA POWDER, CALCINED"
    },
    {
      "code": "25252090",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA POWDER: OTHER"
    },
    {
      "code": "252530",
      "description": "Mica waste"
    },
    {
      "code": "25253010",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA WASTE: MICA MINE SCRAP AND WASTE"
    },
    {
      "code": "25253020",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA WASTE: MICA FACTORY SCRAP"
    },
    {
      "code": "25253030",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA WASTE: MICA CUTTINGS BOOK FORM"
    },
    {
      "code": "25253090",
      "description": "MICA, INCLUDING SPLITTINGS; MICA WASTE - MICA WASTE: OTHER"
    },
    {
      "code": "2526",
      "description": "NATURAL STEATITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; TALC"
    },
    {
      "code": "252610",
      "description": "Not crushed, not powdered"
    },
    {
      "code": "25261010",
      "description": "NATURAL STEATITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; TALC - NOT CRUSHED, NOT POWDERED: STEATITE (SOAP STONE, ETC.) BLOCK"
    },
    {
      "code": "25261020",
      "description": "NATURAL STEATITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; TALC - NOT CRUSHED, NOT POWDERED: STEATITE (SOAP STONE, ETC.) LUMPS"
    },
    {
      "code": "25261090",
      "description": "NATURAL STEATITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; TALC - NOT CRUSHED, NOT POWDERED: OTHER"
    },
    {
      "code": "25262000",
      "description": "NATURAL STEATITE, WHETHER OR NOT ROUGHLY TRIMMED OR MERELY CUT, BY SAWING OR OTHERWISE, INTO BLOCKS OR SLABS OF A RECTANGULAR (INCLUDING SQUARE) SHAPE; TALC CRUSHED OR POWDERED"
    },
    {
      "code": "2528",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATUAL BRINE; NATURAL"
    },
    {
      "code": "252800",
      "description": "Natural borates and concentrates thereof (Whether or not calcined), but not including borates separated from natural brine; natural boric acid containing not more than 85% of H3BO3 Calculated on the dry weight"
    },
    {
      "code": "25280010",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATURAL BRINE;NATURAL BORIC ACID CONTAINING NOT MORE THAN 85% OF H2BO3 CALCULATED ON THE DRY WEIGHT---NATURAL SODIUM BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED)"
    },
    {
      "code": "25280020",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATURAL BRINE;NATURAL BORIC ACID CONTAINING NOT MORE THAN 85% OF H2BO3 CALCULATED ON THE DRY WEIGHT---NATURAL BORIC ACID (CONTAINING NOT MORE THAN 85% OF H2BO3)"
    },
    {
      "code": "25280030",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATURAL BRINE;NATURAL BORIC ACID CONTAINING NOT MORE THAN 85% OF H2BO3 CALCULATED ON THE DRY WEIGHT---NATURAL CALCIUM BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED)"
    },
    {
      "code": "25280090",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATURAL BRINE;NATURAL BORIC ACID CONTAINING NOT MORE THAN 85% OF H2BO3 CALCULATED ON THE DRY WEIGHT---OTHER"
    },
    {
      "code": "252890",
      "description": "Other"
    },
    {
      "code": "25289020",
      "description": "NATURAL BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED), BUT NOT INCLUDING BORATES SEPARATED FROM NATURAL BRINE; NATURAL BORIC ACID CONTAINING NOT MORE THAN 85% OF H3BO3 CALCULATED ON THE DRY WEIGHT- OTHER: NATURAL CALCIUM BORATES AND CONCENTRATES THEREOF (WHETHER OR NOT CALCINED)"
    },
    {
      "code": "2529",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR"
    },
    {
      "code": "252910",
      "description": "Feldspar"
    },
    {
      "code": "25291010",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR - FELDSPAR: LUMPS"
    },
    {
      "code": "25291020",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR - FELDSPAR: POWDER"
    },
    {
      "code": "25292100",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR - FLUORSPAR: CONTAINING BY WEIGHT 97% OR LESS OF CALCIUM FLUORIDE"
    },
    {
      "code": "25292200",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR - FLUORSPAR: CONTAINING BY WEIGHT MORE THAN 97% OF CALCIUM FLUORIDE"
    },
    {
      "code": "25293000",
      "description": "FELDSPAR; LEUCITE; NEPHELINE AND NEPHELINE SYENITE; FLUORSPAR - LEUCITE; NEPHELINE AND NEPHELINE SYENITE"
    },
    {
      "code": "2530",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "253010",
      "description": "Vermiculite, perlite and chlorites, unexpanded"
    },
    {
      "code": "25301010",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - VERMICULITE, PERLITE AND CHLORITES, UNEXPANDED: VERMICULITE"
    },
    {
      "code": "25301020",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - VERMICULITE, PERLITE AND CHLORITES, UNEXPANDED: PERLITE"
    },
    {
      "code": "25301090",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - VERMICULITE, PERLITE AND CHLORITES, UNEXPANDED: OTHERS (INCLUDING POWDER)"
    },
    {
      "code": "25302000",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED KIESERITE, EPSOMITE (NATURAL MAGNESIUM SULPHATES)"
    },
    {
      "code": "253090",
      "description": "Other"
    },
    {
      "code": "25309010",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: MEERSCHAUM (WHETHER OR NOT IN POLISHED PIECES) AND AMBER AGGLOMERATED; MEERACHAUM AND AGGLOMERATED AMBER IN PLATES, RODS, ETC., NOT WORKED AFTER MOULDING JET"
    },
    {
      "code": "25309020",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: NATURAL ARSENIC SULPHIDES (SUCH AS ORPIMENT)"
    },
    {
      "code": "25309030",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: CALCITE"
    },
    {
      "code": "25309040",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: ORES AND CONCENTRATES OF RARE EARTH METALS"
    },
    {
      "code": "25309050",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: WOLLASTONITE"
    },
    {
      "code": "25309060",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: EARTH COLOUR OCHRE, CRUDE"
    },
    {
      "code": "25309070",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER: OTHER PROCESSED EARTH COLOUR OCHRE"
    },
    {
      "code": "25309091",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER:OTHER: STRONTIUM SULPHATE (NATURAL ORE)"
    },
    {
      "code": "25309099",
      "description": "MINERAL SUBSTANCES NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER:OTHER: OTHER"
    },
    {
      "code": "26",
      "description": "Ores, slag and ash"
    },
    {
      "code": "2601",
      "description": "Iron ores and concentrates, INCLUDING ROASTED IRON PYRITES - Iron ores and concentrates, other than roasted iron pyrites :"
    },
    {
      "code": "260111",
      "description": "Non-agglomerated"
    },
    {
      "code": "26011111",
      "description": "---Iron ore lumps (60% Fe or more)---60% Fe or more but below 62% Fe"
    },
    {
      "code": "26011112",
      "description": "---Iron ore lumps (60% Fe or more)---62% Fe or more but below 62% Fe"
    },
    {
      "code": "26011119",
      "description": "---Iron ore lumps (60% Fe or more)--- above 65% Fe"
    },
    {
      "code": "26011121",
      "description": "---Iron ore lumps (60% Fe or more)--- below 55% Fe"
    },
    {
      "code": "26011122",
      "description": "---Iron ore lumps (60% Fe or more)--- 55% Fe or more but below 58% Fe"
    },
    {
      "code": "26011129",
      "description": "---Iron ore lumps (60% Fe or more)--- 58% Fe or more but below 60% Fe"
    },
    {
      "code": "26011131",
      "description": "---Iron ore lumps (60% Fe or more)--- 62% Fe or more but below 65% Fe"
    },
    {
      "code": "26011139",
      "description": "---Iron ore lumps (60% Fe or more)--- above 65% Fe"
    },
    {
      "code": "26011141",
      "description": "---Iron ore lumps (60% Fe or more)--- below 55% Fe"
    },
    {
      "code": "26011142",
      "description": "---Iron ore lumps (60% Fe or more)--- 55% Fe or more but below 58% Fe"
    },
    {
      "code": "26011143",
      "description": "---Iron ore lumps (60% Fe or more)--- 58% Fe or more but below 60% Fe"
    },
    {
      "code": "26011149",
      "description": "---Iron ore lumps (60% Fe or more)--- 58% Fe or more but below 62% Fe"
    },
    {
      "code": "26011150",
      "description": "IRON ORES AND CONCENTRATES, INCLUDING ROASTED IRON PYRITES - IRON ORES AND CONCENTRATES, OTHER THAN ROASTED IRON PYRITES : NON-AGGLOMERATED : IRON ORE CONCENTRATES"
    },
    {
      "code": "26011190",
      "description": "IRON ORES AND CONCENTRATES, INCLUDING ROASTED IRON PYRITES - IRON ORES AND CONCENTRATES, OTHER THAN ROASTED IRON PYRITES : NON-AGGLOMERATED : OTHER"
    },
    {
      "code": "260112",
      "description": "Agglomerated"
    },
    {
      "code": "26011210",
      "description": "IRON ORES AND CONCENTRATES, INCLUDING ROASTED IRON PYRITES - IRON ORES AND CONCENTRATES, OTHER THAN ROASTED IRON PYRITES : AGGLOMERATED : IRON ORE PELLETS"
    },
    {
      "code": "26011290",
      "description": "IRON ORES AND CONCENTRATES, INCLUDING ROASTED IRON PYRITES - IRON ORES AND CONCENTRATES, OTHER THAN ROASTED IRON PYRITES : AGGLOMERATED : OTHER"
    },
    {
      "code": "26012000",
      "description": "IRON ORES AND CONCENTRATES, INCLUDING ROASTED IRON PYRITES ROASTED IRON PYRITES"
    },
    {
      "code": "2602",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT"
    },
    {
      "code": "260200",
      "description": "Manganese ores and concentrates, including ferruginous manganese ores and concentrates with a manganese content of 20% or more, calculated on the dry weight"
    },
    {
      "code": "26020010",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE (46% OR MORE)"
    },
    {
      "code": "26020020",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE (44% OR MORE BUT BELOW 46%)"
    },
    {
      "code": "26020030",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE (40% OR MORE BUT BELOW 44%)"
    },
    {
      "code": "26020040",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE (35% OR MORE BUT BELOW 40%)"
    },
    {
      "code": "26020050",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE (30% OR MORE BUT BELOW 35%)"
    },
    {
      "code": "26020060",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: FERRUGINOUS (10% OR MORE BUT BELOW 30%)"
    },
    {
      "code": "26020070",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: MANGANESE ORE SINTERS, AGGLOMERATED"
    },
    {
      "code": "26020090",
      "description": "MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT - MANGANESE ORES AND CONCENTRATES, INCLUDING FERRUGINOUS MANGANESE ORES AND CONCENTRATES WITH A MANGANESE CONTENT OF 20% OR MORE, CALCULATED ON THE DRY WEIGHT: OTHER"
    },
    {
      "code": "2603",
      "description": "COPPER ORES AND CONCENTRATES"
    },
    {
      "code": "26030000",
      "description": "COPPER ORES AND CONCENTRATES"
    },
    {
      "code": "2604",
      "description": "NICKEL ORES AND CONCENTRATES"
    },
    {
      "code": "26040000",
      "description": "NICKEL ORES AND CONCENTRATES"
    },
    {
      "code": "2605",
      "description": "COBALT ORES AND CONCENTRATES"
    },
    {
      "code": "26050000",
      "description": "COBALT ORES AND CONCENTRATES"
    },
    {
      "code": "2606",
      "description": "ALUMINIUM ORES AND CONCENTRATES"
    },
    {
      "code": "260600",
      "description": "Aluminium ores and concentrates"
    },
    {
      "code": "26060010",
      "description": "ALUMINIUM ORES AND CONCENTRATES - ALUMINIUM ORES AND CONCENTRATES: BAUXITE (NATURAL), NOT CALCINED"
    },
    {
      "code": "26060020",
      "description": "ALUMINIUM ORES AND CONCENTRATES - ALUMINIUM ORES AND CONCENTRATES: BAUXITE (NATURAL), CALCINED"
    },
    {
      "code": "26060090",
      "description": "ALUMINIUM ORES AND CONCENTRATES - ALUMINIUM ORES AND CONCENTRATES: OTHER ALUMINIUM ORES AND CONCENTRATES"
    },
    {
      "code": "2607",
      "description": "LEAD ORES AND CONCENTRATES"
    },
    {
      "code": "26070000",
      "description": "LEAD ORES AND CONCENTRATES"
    },
    {
      "code": "2608",
      "description": "ZINC ORES AND CONCENTRATES"
    },
    {
      "code": "26080000",
      "description": "ZINC ORES AND CONCENTRATES"
    },
    {
      "code": "2609",
      "description": "TIN ORES AND CONCENTRATES"
    },
    {
      "code": "26090000",
      "description": "TIN ORES AND CONCENTRATES"
    },
    {
      "code": "2610",
      "description": "CHROMIUM ORES AND CONCENTRATES"
    },
    {
      "code": "261000",
      "description": "Chromium ores and concentrates"
    },
    {
      "code": "26100010",
      "description": "CHROMIUM ORES AND CONCENTRATES - CHROMIUM ORES AND CONCENTRATES: CHROME ORE LUMPS, CONTAINING 47% CR2O3 AND ABOVE"
    },
    {
      "code": "26100020",
      "description": "CHROMIUM ORES AND CONCENTRATES - CHROMIUM ORES AND CONCENTRATES: CHROME ORE LUMPS, CONTAINING 40% OR MORE BUT LESS THAN 47% CR2O3"
    },
    {
      "code": "26100030",
      "description": "CHROMIUM ORES AND CONCENTRATES - CHROMIUM ORES AND CONCENTRATES: CHROME ORE LUMPS BELOW 40% CR2O3"
    },
    {
      "code": "26100040",
      "description": "CHROMIUM ORES AND CONCENTRATES - CHROMIUM ORES AND CONCENTRATES: CHROME ORE FRIABLE AND CONCENTRATES FIXES CONTAINING 47% CR2O3 AND ABOVE"
    },
    {
      "code": "26100090",
      "description": "CHROMIUM ORES AND CONCENTRATES - CHROMIUM ORES AND CONCENTRATES: OTHER"
    },
    {
      "code": "2611",
      "description": "TUNGSTEN ORES AND CONCENTRATES"
    },
    {
      "code": "26110000",
      "description": "TUNGSTEN ORES AND CONCENTRATES"
    },
    {
      "code": "2612",
      "description": "URANIUM OR THORIUM ORES AND CONCENTRATES"
    },
    {
      "code": "26121000",
      "description": "URANIUM OR THORIUM ORES AND CONCENTRATES - URANIUM ORES AND CONCENTRATES"
    },
    {
      "code": "26122000",
      "description": "URANIUM OR THORIUM ORES AND CONCENTRATES - THORIUM ORES AND CONCENTRATES"
    },
    {
      "code": "2613",
      "description": "MOLYBDENUM ORES AND CONCENTRATES"
    },
    {
      "code": "26131000",
      "description": "MOLYBDENUM ORES AND CONCENTRATES - ROASTED"
    },
    {
      "code": "26139000",
      "description": "MOLYBDENUM ORES AND CONCENTRATES - OTHER"
    },
    {
      "code": "2614",
      "description": "TITANIUM ORES AND CONCENTRATES"
    },
    {
      "code": "261400",
      "description": "Titanium ores and concentrates"
    },
    {
      "code": "26140010",
      "description": "TITANIUM ORES AND CONCEN TRATES - TITANIUM ORES AND CONCENTRATES: LIMENITE, UNPROCESSED"
    },
    {
      "code": "26140020",
      "description": "TITANIUM ORES AND CONCEN TRATES - TITANIUM ORES AND CONCENTRATES: LIMENITE, UPGRADED (BENEFICIATED LIMENITE INCLUDING LIMENITE GROUND)"
    },
    {
      "code": "26140031",
      "description": "TITANIUM ORES AND CONCEN TRATES - TITANIUM ORES AND CONCENTRATES: RUTILE : RARE EARTH OXIDES INCLUDING RUTILE SAND"
    },
    {
      "code": "26140039",
      "description": "TITANIUM ORES AND CONCEN TRATES - TITANIUM ORES AND CONCENTRATES: RUTILE : OTHER"
    },
    {
      "code": "26140090",
      "description": "TITANIUM ORES AND CONCEN TRATES - TITANIUM ORES AND CONCENTRATES: OTHER"
    },
    {
      "code": "2615",
      "description": "NIOBIUM, TANTALUM, VANADIUM OR ZIRCONIUM ORES AND CONCENTRATE"
    },
    {
      "code": "26151000",
      "description": "NIOBIUM, TANTALUM, VANADIUM OR ZIRCONIUM ORES AND CONCENTRATES ZIRCONIUM ORES AND CONCENTRATES"
    },
    {
      "code": "261590",
      "description": "Other"
    },
    {
      "code": "26159010",
      "description": "NIOBIUM, TANTALUM, VANADIUM OR ZIRCONIUM ORES AND CONCENTRATES - OTHER : VANADIUM ORES AND CONCENTRATES"
    },
    {
      "code": "26159020",
      "description": "NIOBIUM, TANTALUM, VANADIUM OR ZIRCONIUM ORES AND CONCENTRATES - OTHER : NIOBIUM OR TANTALUM ORES AND CONCENTRATES"
    },
    {
      "code": "2616",
      "description": "PRECIOUS METAL ORES AND CONCENTRATES"
    },
    {
      "code": "26161000",
      "description": "PRECIOUS METAL ORES AND CONCENTRATES SILVER ORES AND CONCENTRATES"
    },
    {
      "code": "261690",
      "description": "Other"
    },
    {
      "code": "26169010",
      "description": "PRECIOUS METAL ORES AND CONCENTRATES - OTHER : GOLD ORES AND CONCENTRATES"
    },
    {
      "code": "26169090",
      "description": "PRECIOUS METAL ORES AND CONCENTRATES - OTHER : OTHER"
    },
    {
      "code": "2617",
      "description": "OTHER ORES AND CONCENTRATES"
    },
    {
      "code": "26171000",
      "description": "OTHER ORES AND CONCENTRATES - ANTIMONY ORES AND CONCENTRATES"
    },
    {
      "code": "26179000",
      "description": "OTHER ORES AND CONCENTRATES - OTHER"
    },
    {
      "code": "2618",
      "description": "GRANULATED SLAG (SLAG SAND) FROM THE MANUFACTURE OF IRON OR STEEL"
    },
    {
      "code": "26180000",
      "description": "GRANULATED SLAG (SLAG SAND) FROM THE MANUFACTURE OF IRON OR STEEL"
    },
    {
      "code": "2619",
      "description": "SLAG, DROSS (OTHER THAN GRANULATED SLAG), SCALINGS AND OTHER WASTE FROM THE MANUFACTURE OF IRON OR STEEL"
    },
    {
      "code": "261900",
      "description": "Slag, dross (other than granulated slag), scalings and other waste from the manufacture of iron or steel"
    },
    {
      "code": "26190010",
      "description": "SLAG, DROSS (OTHER THAN GRANULATED SLAG), SCALINGS AND OTHER WASTE FROM THE MANUFACTURE OF IRON OR STEEL - SLAG, DROSS (OTHER THAN GRANULATED SLAG), SCALINGS AND OTHER WASTE FROM THE MANUFACTURE OF IRON OR STEEL: CONVERTED SLAG (SCULL) OF BLAST FURNACE"
    },
    {
      "code": "26190090",
      "description": "SLAG, DROSS (OTHER THAN GRANULATED SLAG), SCALINGS AND OTHER WASTE FROM THE MANUFACTURE OF IRON OR STEEL - SLAG, DROSS (OTHER THAN GRANULATED SLAG), SCALINGS AND OTHER WASTE FROM THE MANUFACTURE OF IRON OR STEEL: OTHER"
    },
    {
      "code": "2620",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS"
    },
    {
      "code": "26201100",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY ZINC : HARD ZINC SPELTER"
    },
    {
      "code": "262019",
      "description": "Other"
    },
    {
      "code": "26201900",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY ZINC : OTHER"
    },
    {
      "code": "26201910",
      "description": "zinc dross"
    },
    {
      "code": "26201990",
      "description": "Other"
    },
    {
      "code": "26202100",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY LEAD: LEADED GASOLINE SLUDGES AND LEADED ANTI-KNOCK COMPOUND SLUDGES"
    },
    {
      "code": "262029",
      "description": "Other"
    },
    {
      "code": "26202900",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY LEAD: OTHER"
    },
    {
      "code": "26202910",
      "description": "Lead dross"
    },
    {
      "code": "26202990",
      "description": "Other"
    },
    {
      "code": "262030",
      "description": "Containing mainly copper"
    },
    {
      "code": "26203010",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY COPPER : BRASS DROSS"
    },
    {
      "code": "26203090",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY COPPER : OTHER"
    },
    {
      "code": "262040",
      "description": "Containing mainly aluminium"
    },
    {
      "code": "26204010",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY ALUMINIUM: ALUMINIUM DROSS"
    },
    {
      "code": "26204090",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - CONTAINING MAINLY ALUMINIUM: OTHER"
    },
    {
      "code": "26206000",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS -CONTAINING ARSENIC, MERCURY, THALLIUM OR THEIR MIXTURES, OF A KIND USED FOR THE EXTRACTION OF ARSENIC OR THOSE METALS OR FOR THE MANUFACTURE OF THEIR CHEMICAL COMPOUNDS"
    },
    {
      "code": "26209100",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - OTHER: CONTAINING ANTIMONY , BERYLLIUM, CADMIUM, CHROMIUM OR THEIR MIXTURES"
    },
    {
      "code": "26209900",
      "description": "SLAG, ASH AND RESIDUES (OTHER THAN FROM THE MANUFACTURE OF IRON OR STEEL), CONTAINING ARSENIC, METALS OR THEIR COMPOUNDS - OTHER: OTHER"
    },
    {
      "code": "2621",
      "description": "OTHER SLAG AND ASH, INLCUDING SEAWEED ASH (KELP); ASH AND RESIDUES FROM THE INCINERATION OF MUNICIPAL WASTE"
    },
    {
      "code": "26211000",
      "description": "OTHER SLAG AND ASH, INLCUDING SEAWEED ASH (KELP); ASH AND RESIDUES FROM THE INCINERATION OF MUNICIPAL WASTE - ASH AND RESIDUES FROM THE INCINERATION OF MUNICIPAL WASTE"
    },
    {
      "code": "26219000",
      "description": "OTHER SLAG AND ASH, INLCUDING SEAWEED ASH (KELP); ASH AND RESIDUES FROM THE INCINERATION OF MUNICIPAL WASTE - OTHER"
    },
    {
      "code": "27",
      "description": "Mineral fuels, mineral oils and products of their distillation; bituminous substances; mineral waxes"
    },
    {
      "code": "2701",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR SOLID FUELS MANUFACTURED FROM COAL"
    },
    {
      "code": "27011100",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - COAL, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED: ANTHRACITE"
    },
    {
      "code": "27011200",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - COAL, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED: BITUMINOUS COAL"
    },
    {
      "code": "270119",
      "description": "Other coal"
    },
    {
      "code": "27011910",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - COAL, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED: - OTHER COAL:  COKING COAL"
    },
    {
      "code": "27011920",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - COAL, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED: - OTHER COAL:  STEAM COAL"
    },
    {
      "code": "27011990",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - COAL, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED: - OTHER COAL:  OTHER"
    },
    {
      "code": "270120",
      "description": "Briquettes, ovoids and similar solid fuels manufactured from coal"
    },
    {
      "code": "27012010",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - BRIQUETTES, OVOIDS AND SIMILAR SOLID FUELS MANUFACTURED FROM COAL: ANTHRACITE AGGLOMERATED"
    },
    {
      "code": "27012090",
      "description": "COAL; BRIQUETTES, OVOIDS AND SIMILAR  SOLID FUELS MANUFACTURED FROM COAL - BRIQUETTES, OVOIDS AND SIMILAR SOLID FUELS MANUFACTURED FROM COAL: OTHER"
    },
    {
      "code": "2702",
      "description": "LIGNITE, WHETHER OR NOT AGGLOMERATED, EXCLUDING JET"
    },
    {
      "code": "27021000",
      "description": "LIGNITE, WHETHER OR NOT AGGLOMERATED, EXCLUDING JET  - LIGNITE, WHETHER OR NOT PULVERISED, BUT NOT AGGLOMERATED"
    },
    {
      "code": "27022000",
      "description": "LIGNITE, WHETHER OR NOT AGGLOMERATED, EXCLUDING JET - AGGLOMERATED LIGNITE"
    },
    {
      "code": "2703",
      "description": "PEAT (INCLUDING PEAT LITTER), WHETHER OR NOT AGGLOMERATED"
    },
    {
      "code": "270300",
      "description": "Peat (including peat litter), whether or not agglomerated"
    },
    {
      "code": "27030010",
      "description": "PEAT (INCLUDING PEAT LITTER), WHETHER OR NOT AGGLOMERATED - PEAT (INCLUDING PEAT LITTER), WHETHER OR NOT AGGLOMERATED:  PEAT WHETHER OR NOT COMPRESSED INTO BALES, BUT NOT AGGLOMERATED"
    },
    {
      "code": "27030090",
      "description": "PEAT (INCLUDING PEAT LITTER), WHETHER OR NOT AGGLOMERATED - PEAT (INCLUDING PEAT LITTER), WHETHER OR NOT AGGLOMERATED:  OTHER"
    },
    {
      "code": "2704",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON"
    },
    {
      "code": "270400",
      "description": "Coke and semi-coke of coal, of lignite or of peat, whether or not agglomerated; retort carbon"
    },
    {
      "code": "27040010",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE  OR OF PEAT, WHETHER OR NOT AGGLOMERATED;  RETORT CARBON - COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON: RETORT CARBON (GAS CARBON)"
    },
    {
      "code": "27040020",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE  OR OF PEAT, WHETHER OR NOT AGGLOMERATED;  RETORT CARBON - COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON: COKE AND SEMI-COKE OF LIGNITE OR OF PEAT"
    },
    {
      "code": "27040030",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE  OR OF PEAT, WHETHER OR NOT AGGLOMERATED;  RETORT CARBON - COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON:  HARD COKE OF COAL"
    },
    {
      "code": "27040040",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE  OR OF PEAT, WHETHER OR NOT AGGLOMERATED;  RETORT CARBON - COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON:  SOFT COKE OF COAL"
    },
    {
      "code": "27040090",
      "description": "COKE AND SEMI-COKE OF COAL, OF LIGNITE  OR OF PEAT, WHETHER OR NOT AGGLOMERATED;  RETORT CARBON - COKE AND SEMI-COKE OF COAL, OF LIGNITE OR OF PEAT, WHETHER OR NOT AGGLOMERATED; RETORT CARBON:  OTHER"
    },
    {
      "code": "2705",
      "description": "COAL GAS, WATER GAS, PRODUCER GAS AND SIMILAR GASES, OTHER THAN PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS"
    },
    {
      "code": "27050000",
      "description": "COAL GAS, WATER GAS, PRODUCER GAS AND SIMILAR GASES, OTHER THAN PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS"
    },
    {
      "code": "2706",
      "description": "TAR DISTILLED FROM COAL, FROM LIGNITE OR FROM PEAT AND OTHER MINERAL TARS, WHETHER OR NOT DEHYDRATED OR PARTIALLY DISTILLED, INCLUDING RECONSTITUTED TARS"
    },
    {
      "code": "270600",
      "description": "Tar distilled from coal, from lignite or from peat and other mineral tars, whether or not dehydrated or partially distilled, including reconstituted tars"
    },
    {
      "code": "27060010",
      "description": "TAR DISTILLED FROM COAL, FROM LIGNITE OR FROM PEAT AND OTHER MINERAL TARS, WHETHER OR NOT DEHYDRATED OR PARTIALLY DISTILLED, INCLUDING RECONSTITUTED TARS - TAR DISTILLED FROM COAL, FROM LIGNITE OR FROM PEAT AND OTHER MINERAL TARS, WHETHER OR NOT DEHYDRATED OR PARTIALLY DISTILLED, INCLUDING RECONSTITUTED TARS: COAL TAR"
    },
    {
      "code": "27060090",
      "description": "TAR DISTILLED FROM COAL, FROM LIGNITE OR FROM PEAT AND OTHER MINERAL TARS, WHETHER OR NOT DEHYDRATED OR PARTIALLY DISTILLED, INCLUDING RECONSTITUTED TARS - TAR DISTILLED FROM COAL, FROM LIGNITE OR FROM PEAT AND OTHER MINERAL TARS, WHETHER OR NOT DEHYDRATED OR PARTIALLY DISTILLED, INCLUDING RECONSTITUTED TARS: OTHER"
    },
    {
      "code": "2707",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION OF HIGH TEMPERATURE COAL TAR SIMILAR PRODUCTS IN WHICH THE WEIGHT OF THE AROMATIC CONSTITUENTS EXCEEDS THAT OF THE NON-AROMATIC CONSTITUENTS"
    },
    {
      "code": "27071000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS BENZOL (BENZENE)"
    },
    {
      "code": "27072000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS TOLUOL (TOLUENE)"
    },
    {
      "code": "27073000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS XYLOL (XYLENES)"
    },
    {
      "code": "27074000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS NAPHTHELENE"
    },
    {
      "code": "27075000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS OTHER AROMATIC HYDROCARBON MIXTURES OF    WHICH 65% OR MORE BY VOLUME (INCLUDING  LOSSES) DISTILS AT 250OC BY THE ASTM D 86  METHOD"
    },
    {
      "code": "27076000",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS PHENOLS"
    },
    {
      "code": "27079100",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS - OTHER: CREOSOTE OILS"
    },
    {
      "code": "27079900",
      "description": "OILS AND OTHER PRODUCTS OF THE DISTILLATION  OF HIGH TEMPERATURE COAL TAR SIMILAR  PRODUCTS IN WHICH THE WEIGHT OF THE  AROMATIC CONSTITUENTS EXCEEDS THAT OF THE  NON-AROMATIC CONSTITUENTS - OTHER: OTHER"
    },
    {
      "code": "2708",
      "description": "PITCH AND PITCH COKE, OBTAINED FROM COAL TAR OR FROM OTHER MINERAL TARS"
    },
    {
      "code": "270810",
      "description": "Pitch"
    },
    {
      "code": "27081010",
      "description": "PITCH AND PITCH COKE, OBTAINED FROM COAL TAR OR FROM OTHER MINERAL TARS - PITCH: OBTAINED BY BLENDING WITH CREOSOTE OIL OR OTHER COAL TAR DISTILLATES"
    },
    {
      "code": "27081090",
      "description": "PITCH AND PITCH COKE, OBTAINED FROM COAL TAR OR FROM OTHER MINERAL TARS - PITCH: OTHER"
    },
    {
      "code": "27082000",
      "description": "PITCH AND PITCH COKE, OBTAINED FROM COAL TAR OR FROM OTHER MINERAL TARS  - PITCH COKE"
    },
    {
      "code": "2709",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, CRUDE"
    },
    {
      "code": "27090000",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, CRUDE"
    },
    {
      "code": "27091000",
      "description": "Petroleum oils and oils obtained from bituminous minerals"
    },
    {
      "code": "27092000",
      "description": "Petroleum crude"
    },
    {
      "code": "2710",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINING BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS"
    },
    {
      "code": "271011",
      "description": "Light oils and preparations"
    },
    {
      "code": "271012",
      "description": "Light Oils and Preparations"
    },
    {
      "code": "27101211",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---MOTOR SPIRIT----SPECIAL BOILING POINT SPIRITS (OTHER THAN BENZENE, TOLUOL) WITH NOMINAL BOILING POINT RANGE 55-115 DEGREES CENTIGRADE"
    },
    {
      "code": "27101212",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---MOTOR SPIRIT----SPECIAL BOILING POINT SPIRITS (OTHER THAN BENZENE,BENZOL,TOLUENE AND TOLUOL) WITH NOMINAL BOILING POINT RANGE 63-70 DEGREES CENTIGRADE"
    },
    {
      "code": "27101213",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---MOTOR SPIRIT----OTHER SPECIAL BOILING POINT SPIRITS (OTHER THAN BENZENE, BENZOL, TOLUENE AND TOLUOL)"
    },
    {
      "code": "27101219",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---MOTOR SPIRIT----OTHER"
    },
    {
      "code": "27101220",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---NATURAL GASOLINE LIQUID (NCL)"
    },
    {
      "code": "27101221",
      "description": "Light Naptha"
    },
    {
      "code": "27101222",
      "description": "Heavy Naptha"
    },
    {
      "code": "27101229",
      "description": "Full orange naphtha"
    },
    {
      "code": "27101231",
      "description": "Solvent 60/80"
    },
    {
      "code": "27101232",
      "description": "solvent 50/120"
    },
    {
      "code": "27101239",
      "description": "Solvent 145/2015"
    },
    {
      "code": "27101241",
      "description": "Motor gasoline conforming to standard IS 2796,"
    },
    {
      "code": "27101242",
      "description": "e20 fuel conforming to standard IS 17021"
    },
    {
      "code": "27101249",
      "description": "M15 fuel conforming to standard IS 17076"
    },
    {
      "code": "27101250",
      "description": "Aviation gasoline conforming to standard IS 1604"
    },
    {
      "code": "27101290",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS, OTHER THAN CRUDE; PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINIGN BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS; WASTE OILS--LIGHT OILS AND PREPARATIONS---OTHER"
    },
    {
      "code": "271019",
      "description": "Other"
    },
    {
      "code": "27101910",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: SUPERIOR KEROSINE OIL (SKO)"
    },
    {
      "code": "27101920",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: AVIATION TURBINE FUEL (ATF)"
    },
    {
      "code": "27101930",
      "description": "HIGH SPEED DIESEL OIL"
    },
    {
      "code": "27101931",
      "description": "Kerosene intermediate"
    },
    {
      "code": "27101932",
      "description": "Kerosene conforming to standard IS1459"
    },
    {
      "code": "27101939",
      "description": "Aviation turbine fuels, kerosine type conforming to standard IS 1571"
    },
    {
      "code": "27101940",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: LIGHT DIESEL OIL (LDO)"
    },
    {
      "code": "27101941",
      "description": "Gas Oil"
    },
    {
      "code": "27101942",
      "description": "Vaccum gas oil"
    },
    {
      "code": "27101943",
      "description": "Light diesel oil conforming to standard IS 15770"
    },
    {
      "code": "27101944",
      "description": "Automotive diesel fuel not containing biodiesel CONFORMING TO STANDARD IS 1460"
    },
    {
      "code": "27101949",
      "description": "High flash high speed diesel fuel conformiing TO STANDARD IS 16861"
    },
    {
      "code": "27101950",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: FUEL OIL"
    },
    {
      "code": "27101951",
      "description": "Grade LV"
    },
    {
      "code": "27101952",
      "description": "Grade MV1"
    },
    {
      "code": "27101953",
      "description": "Grade MV2"
    },
    {
      "code": "27101959",
      "description": "Grade HV"
    },
    {
      "code": "27101960",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: BASE OIL"
    },
    {
      "code": "27101961",
      "description": "Distillate oil"
    },
    {
      "code": "27101969",
      "description": "Residual oil"
    },
    {
      "code": "27101970",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: JUTE BATCHING OIL AND TEXTILE OIL"
    },
    {
      "code": "27101971",
      "description": "Base oil"
    },
    {
      "code": "27101972",
      "description": "Engine Oil (internal combustion engine crankcase oils)conforming to standard IS 13656"
    },
    {
      "code": "27101973",
      "description": "Engine oil conforming to standard IS 14234"
    },
    {
      "code": "27101974",
      "description": "automotive gear oil conforming to standard IS 1118"
    },
    {
      "code": "27101975",
      "description": "Industrial gear oil conforming to standard IS 8406"
    },
    {
      "code": "27101976",
      "description": "General purpose machine and spindle oils conforming to standard IS 493"
    },
    {
      "code": "27101977",
      "description": "Turbine lubricating oil conforming to standard IS 1012"
    },
    {
      "code": "27101978",
      "description": "Other Lubricating oil, conforming to anyother BIS standard"
    },
    {
      "code": "27101979",
      "description": "Other Lubricating oil not conforming to any BIS standard"
    },
    {
      "code": "27101980",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER: LUBRICATING OIL"
    },
    {
      "code": "27101981",
      "description": "Cutting oil conforming to standard IS 1115"
    },
    {
      "code": "27101982",
      "description": "Cutting oil (neat) conforming to standard IS 3065"
    },
    {
      "code": "27101983",
      "description": "Hydraulic oil conforming to standard IS 3098 or IS 11656"
    },
    {
      "code": "27101984",
      "description": "Industrial white oil conforming to standard IS 1083"
    },
    {
      "code": "27101985",
      "description": "Insulating oil for transformer and circuit breaker (transformer and circuit breaker oils) conforming to standard IS 335 or IS 12463"
    },
    {
      "code": "27101986",
      "description": "Minseral oil for cosmetic industry conforming to standard IS 7299"
    },
    {
      "code": "27101987",
      "description": "Jute batching oil conforming to standard IS 1758"
    },
    {
      "code": "27101988",
      "description": "other cutting oil, hydraulic oil, industrial white oil, jute batching oil, mineral oil for cosmetic industry, transformer oil conforming to any other BIS standard"
    },
    {
      "code": "27101989",
      "description": "other cutting oil, hydraulic oil, industrial white oil, jute batching oil, mineral oil for cosmetic industry, transformer oil not conforming to any other BIS standard"
    },
    {
      "code": "27101990",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS - PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS (OTHER THAN CRUDE)  AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR  INCLUDED, CONTAINING BY WEIGHT 70% OR  MORE OF PETROLEUM OILS OR OF OILS OBTAINED  FROM BITUMINOUS MINERALS, THESE OILS BEING  THE BASIC CONSTITUENTS OF THE PREPARATIONS,  OTHER THAN WASTE OILS:  - OTHER:  OTHER"
    },
    {
      "code": "271020",
      "description": "Petroleum Oils"
    },
    {
      "code": "27102000",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM BITUMINOUS MINERALS (OTHER THAN CRUDE) AND PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, CONTAINING BY WEIGHT 70% OR MORE OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS, THESE OILS BEING THE BASIC CONSTITUENTS OF THE PREPARATIONS, CONTAINING BIO-DIESEL, OTHER THAN WASTE OILS"
    },
    {
      "code": "27102010",
      "description": "Automotive diesel fuel, containing biodiesel conforming to standard IS 1460"
    },
    {
      "code": "27102020",
      "description": "Diesel fuel blend (B6 to B20) conforming to standard IS 16531"
    },
    {
      "code": "27102090",
      "description": "Other"
    },
    {
      "code": "27109100",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS  - WASTE OIL: CONTAINING POLYCHLORINATED BIPHENYLS (PCBS), POLYCHLORINATED TERPHENYLS (PCTS) OR POLYBROMINATED BIPHENYLS (PBBS)"
    },
    {
      "code": "27109900",
      "description": "PETROLEUM OILS AND OILS OBTAINED FROM  BITUMINOUS MINERALS, OTHER THAN CRUDE;  PREPARATIONS NOT ELSEWHERE SPECIFIED  OR INCLUDED, CONTAINING BY WEIGHT 70%  OR MORE OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS,  THESE OILS BEING THE BASIC CONSTITUENTS  OF THE PREPARATIONS; WASTE OILS -OTHER"
    },
    {
      "code": "2711",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS"
    },
    {
      "code": "27111100",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - LIQUIFIED: NATURAL GAS"
    },
    {
      "code": "27111200",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - LIQUIFIED: PROPANE"
    },
    {
      "code": "27111300",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - LIQUIFIED: BUTANE"
    },
    {
      "code": "27111400",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - LIQUIFIED: ETHYLENE, PROPYLENE, BUTYLENE AND BUTADIENE"
    },
    {
      "code": "271119",
      "description": "Other"
    },
    {
      "code": "27111900",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - LIQUIFIED: OTHER"
    },
    {
      "code": "27111910",
      "description": "LPG (for non-automotive purposes) conforming to standard IS 4576"
    },
    {
      "code": "27111920",
      "description": "LPG (for automotive purposes) conforming to standard IS 14861"
    },
    {
      "code": "27111990",
      "description": "Other"
    },
    {
      "code": "27112100",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - IN GASEOUS STATE: NATURAL GAS"
    },
    {
      "code": "27112900",
      "description": "PETROLEUM GASES AND OTHER GASEOUS HYDROCARBONS - IN GASEOUS STATE: OTHER"
    },
    {
      "code": "2712",
      "description": "PETROLEUM JELLY, PARAFFIN WAX, MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT WAX, OTHER MINERAL WAXES, AND SIMILAR PRODUCTS OBTAINED BY SYNTHESIS OR BY OTHER PROCESSES, WHETHER OR NOT COLOURED"
    },
    {
      "code": "271210",
      "description": "Petroleum jelly"
    },
    {
      "code": "27121010",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED - PETROLEUM JELLY: CRUDE"
    },
    {
      "code": "27121090",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED - PETROLEUM JELLY: OTHER"
    },
    {
      "code": "271220",
      "description": "Paraffin wax containing by weight less than 0.75 % of oil"
    },
    {
      "code": "27122000",
      "description": "PARAFFIN WAX CONTAINING BY WEIGHT LESS THAN .75 PERCENT OF OIL"
    },
    {
      "code": "27122010",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED - PARAFFIN WAX CONTAINING BY WEIGHT LESS THAN 0.75 % OF OIL: CHLORINATED PARAFFIN WAX"
    },
    {
      "code": "27122090",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED - PARAFFIN WAX CONTAINING BY WEIGHT LESS THAN 0.75 % OF OIL: OTHER"
    },
    {
      "code": "271290",
      "description": "Other"
    },
    {
      "code": "27129010",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED- OTHER:  MICRO-CRYSTALLINE PETROLEUM WAX"
    },
    {
      "code": "27129020",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED- OTHER: LIGNITE WAX"
    },
    {
      "code": "27129030",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED- OTHER: SLACK WAX"
    },
    {
      "code": "27129040",
      "description": "PARAFFIN WAX CONTAINING BY WEIGHT 0.75 PERCENT OR MORE OF OIL"
    },
    {
      "code": "27129090",
      "description": "PETROLEUM JELLY, PARAFFIN WAX,  MICROCRYSTALLINE PETROLEUM WAX,  SLACK WAX, OZOKERITE, LIGNITE WAX, PEAT  WAX, OTHER MINERAL WAXES, AND SIMILAR  PRODUCTS OBTAINED BY SYNTHESIS OR BY  OTHER PROCESSES, WHETHER OR NOT COLOURED- OTHER:  OTHER"
    },
    {
      "code": "2713",
      "description": "PETROLEUM COKE, PETROLEUM BITUMEN AND OTHER RESIDUES OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "271311",
      "description": "Not calcined"
    },
    {
      "code": "27131100",
      "description": "PETROLEUM COKE, PETROLEUM BITUMEN AND  OTHER RESIDUES OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS - PETROLEUM COKE: NOT CALCINED"
    },
    {
      "code": "27131110",
      "description": "Raw Petroleum coke for anode making in aluminium industry conforming to standard IS 17049"
    },
    {
      "code": "27131190",
      "description": "Other"
    },
    {
      "code": "271312",
      "description": "Calcined"
    },
    {
      "code": "27131200",
      "description": "PETROLEUM COKE, PETROLEUM BITUMEN AND  OTHER RESIDUES OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS - PETROLEUM COKE: CALCINED"
    },
    {
      "code": "27131210",
      "description": "calcined petroleum code for anode making in aluminium industry conforming to standard IS 17049"
    },
    {
      "code": "27131290",
      "description": "other"
    },
    {
      "code": "27132000",
      "description": "PETROLEUM COKE, PETROLEUM BITUMEN AND  OTHER RESIDUES OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS  - PETROLEUM BITUMEN"
    },
    {
      "code": "27139000",
      "description": "PETROLEUM COKE, PETROLEUM BITUMEN AND  OTHER RESIDUES OF PETROLEUM OILS OR OF OILS  OBTAINED FROM BITUMINOUS MINERALS - OTHER RESIDUES OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "2714",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS OR OIL SHALE AND TAR SANDS; ASPHALTITES AND ASPHALTIC ROCKS"
    },
    {
      "code": "27141000",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS  OR OIL SHALE AND TAR SANDS; ASPHALTITES  AND ASPHALTIC ROCKS -BITUMINOUS OR OIL SHALE AND TAR SANDS"
    },
    {
      "code": "271490",
      "description": "Other"
    },
    {
      "code": "27149010",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS  OR OIL SHALE AND TAR SANDS; ASPHALTITES  AND ASPHALTIC ROCKS - OTHER:  ASPHALT, NATURAL"
    },
    {
      "code": "27149020",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS  OR OIL SHALE AND TAR SANDS; ASPHALTITES  AND ASPHALTIC ROCKS - OTHER:  BITUMEN, NATURAL"
    },
    {
      "code": "27149030",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS  OR OIL SHALE AND TAR SANDS; ASPHALTITES  AND ASPHALTIC ROCKS - OTHER:  GILSONETE"
    },
    {
      "code": "27149090",
      "description": "BITUMEN AND ASPHALT, NATURAL; BITUMINOUS  OR OIL SHALE AND TAR SANDS; ASPHALTITES  AND ASPHALTIC ROCKS - OTHER:  OTHER"
    },
    {
      "code": "2715",
      "description": "BITUMINOUS MIXTURES BASED ON NATURAL ASPHALT, ON NATURAL BITUMEN, ON PETROLEUM BITUMEN, ON MINERAL TAR OR ON MINERAL TAR PITCH (FOR EXAMPLE, BITUMINOUS MASTICS, CUT BACKS)"
    },
    {
      "code": "271500",
      "description": "Bituminous mixtures based on natural asphalt, on natural bitumen, on petroleum bitumen, on mineral tar or on mineral tar pitch (for example, bituminous mastics, cut backs)"
    },
    {
      "code": "27150010",
      "description": "BITUMINOUS MIXTURES BASED ON NATURAL  ASPHALT, ON NATURAL BITUMEN, ON PETROLEUM  BITUMEN, ON MINERAL TAR OR ON MINERAL  TAR PITCH (FOR EXAMPLE, BITUMINOUS  MASTICS, CUT BACKS) - BITUMINOUS MIXTURES BASED ON NATURAL  ASPHALT, ON NATURAL BITUMEN, ON PETROLEUM  BITUMEN, ON MINERAL TAR OR ON MINERAL TAR  PITCH (FOR EXAMPLE, BITUMINOUS MASTICS,  CUT BACKS):  CUT BACKS, BITUMINOUS OR ASPHALT"
    },
    {
      "code": "27150090",
      "description": "BITUMINOUS MIXTURES BASED ON NATURAL  ASPHALT, ON NATURAL BITUMEN, ON PETROLEUM  BITUMEN, ON MINERAL TAR OR ON MINERAL  TAR PITCH (FOR EXAMPLE, BITUMINOUS  MASTICS, CUT BACKS) - BITUMINOUS MIXTURES BASED ON NATURAL  ASPHALT, ON NATURAL BITUMEN, ON PETROLEUM  BITUMEN, ON MINERAL TAR OR ON MINERAL TAR  PITCH (FOR EXAMPLE, BITUMINOUS MASTICS,  CUT BACKS):  OTHER"
    },
    {
      "code": "2716",
      "description": "ELECTRICAL ENERGY"
    },
    {
      "code": "27160000",
      "description": "ELECTRICAL ENERGY"
    },
    {
      "code": "28",
      "description": "Inorganic chemicals, organic or inorganic compounds of precious metals, of rare-earth metals, of radioactive"
    },
    {
      "code": "2801",
      "description": "FLUORINE, CHLORINE, BROMINE AND IODINE"
    },
    {
      "code": "28011000",
      "description": "FLUORINE, CHLORINE, BROMINE AND IODINE - CHLORINE"
    },
    {
      "code": "28012000",
      "description": "FLUORINE, CHLORINE, BROMINE AND IODINE - IODINE"
    },
    {
      "code": "280130",
      "description": "Fluorine; bromine"
    },
    {
      "code": "28013010",
      "description": "FLUORINE, CHLORINE, BROMINE AND IODINE - FLUORINE; BROMINE : FLUORINE"
    },
    {
      "code": "28013020",
      "description": "FLUORINE, CHLORINE, BROMINE AND IODINE - FLUORINE; BROMINE : BROMINE"
    },
    {
      "code": "2802",
      "description": "SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR"
    },
    {
      "code": "280200",
      "description": "Sulphur, sublimed or precipitated; colloidal sulphur"
    },
    {
      "code": "28020010",
      "description": "SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR - SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR : SUBLIMED SULPHUR"
    },
    {
      "code": "28020020",
      "description": "SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR - SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR : PRECIPITATED SULPHUR"
    },
    {
      "code": "28020030",
      "description": "SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR - SULPHUR, SUBLIMED OR PRECIPITATED; COLLOIDAL SULPHUR : COLLOIDAL SULPHUR"
    },
    {
      "code": "2803",
      "description": "CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED)"
    },
    {
      "code": "280300",
      "description": "Carbon (carbon blacks and other forms of carbon not elsewhere specified or included)"
    },
    {
      "code": "28030010",
      "description": "CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) - CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) : CARBON BLACKS"
    },
    {
      "code": "28030020",
      "description": "CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) - CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) : ACETYLENE BLACK"
    },
    {
      "code": "28030090",
      "description": "CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) - CARBON (CARBON BLACKS AND OTHER FORMS OF CARBON NOT ELSEWHERE SPECIFIED OR INCLUDED) : OTHER"
    },
    {
      "code": "2804",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS"
    },
    {
      "code": "28041000",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - HYDROGEN"
    },
    {
      "code": "28042100",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - RARE GASES : ARGON"
    },
    {
      "code": "280429",
      "description": "Other"
    },
    {
      "code": "28042910",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - RARE GASES : - OTHER : HELIUM"
    },
    {
      "code": "28042990",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - RARE GASES : - OTHER : OTHER"
    },
    {
      "code": "28043000",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - NITROGEN"
    },
    {
      "code": "280440",
      "description": "Oxygen"
    },
    {
      "code": "28044010",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - OXYGEN :MEDICINAL GRADE"
    },
    {
      "code": "28044090",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - OXYGEN :OTHER"
    },
    {
      "code": "280450",
      "description": "Boron; tellurium"
    },
    {
      "code": "28045010",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - BORON; TELLURIUM : BORON"
    },
    {
      "code": "28045020",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - BORON; TELLURIUM : TELLURIUM"
    },
    {
      "code": "28046100",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - SILICON : CONTAINING BY WEIGHT NOT LESS THAN 99.99% OF SILICON"
    },
    {
      "code": "28046900",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - SILICON : OTHER"
    },
    {
      "code": "280470",
      "description": "Phosphorus"
    },
    {
      "code": "28047010",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - PHOSPHORUS : PHOSPHORUS, BLACK"
    },
    {
      "code": "28047020",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - PHOSPHORUS : PHOSPHORUS, RED"
    },
    {
      "code": "28047030",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS - PHOSPHORUS : PHOSPHORUS, WHITE OR YELLOW"
    },
    {
      "code": "28048000",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS- ARSENIC"
    },
    {
      "code": "28049000",
      "description": "HYDROGEN, RARE GASES AND OTHER NON-METALS- SELENIUM"
    },
    {
      "code": "2805",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY"
    },
    {
      "code": "28051100",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY - ALKALI OR ALKALINE-EARTH METALS : SODIUM"
    },
    {
      "code": "28051200",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY - ALKALI OR ALKALINE-EARTH METALS : CALCIUM"
    },
    {
      "code": "28051900",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY - ALKALI OR ALKALINE-EARTH METALS : OTHER"
    },
    {
      "code": "28053000",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY - RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED"
    },
    {
      "code": "28054000",
      "description": "ALKALI OR ALKALINE-EARTH METALS; RARE-EARTH METALS, SCANDIUM AND YTTRIUM, WHETHER OR NOT INTERMIXED OR INTERALLOYED; MERCURY - MERCURY"
    },
    {
      "code": "2806",
      "description": "HYDROGEN CHLORIDE (HYDROCHLORIC ACID); CHLOROSULPHURIC ACID"
    },
    {
      "code": "28061000",
      "description": "HYDROGEN CHLORIDE (HYDROCHLORIC ACID); CHLOROSULPHURIC ACID - HYDROGEN CHLORIDE (HYDROCHLORIC ACID)"
    },
    {
      "code": "28062000",
      "description": "HYDROGEN CHLORIDE (HYDROCHLORIC ACID); CHLOROSULPHURIC ACID - CHLOROSULPHURIC ACID"
    },
    {
      "code": "2807",
      "description": "SULPHURIC ACID; OLEUM"
    },
    {
      "code": "280700",
      "description": "Sulphuric acid; oleum"
    },
    {
      "code": "28070010",
      "description": "SULPHURIC ACID; OLEUM  SULPHURIC ACID; OLEUM : SULPHURIC ACID"
    },
    {
      "code": "28070020",
      "description": "SULPHURIC ACID; OLEUM  SULPHURIC ACID; OLEUM : OLEUM"
    },
    {
      "code": "2808",
      "description": "NITRIC ACID; SULPHONITRIC ACIDS"
    },
    {
      "code": "280800",
      "description": "Nitric acid; sulphonitric acids"
    },
    {
      "code": "28080010",
      "description": "NITRIC ACID; SULPHONITRIC ACIDS - NITRIC ACID; SULPHONITRIC ACIDS : NITRIC ACID"
    },
    {
      "code": "28080020",
      "description": "NITRIC ACID; SULPHONITRIC ACIDS - NITRIC ACID; SULPHONITRIC ACIDS : SULPHONITRIC ACIDS"
    },
    {
      "code": "2809",
      "description": "DIPHOSPHORUS PENTAOXIDE; PHOSPHORIC ACID; POLYPHOSPHORIC ACIDS, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "28091000",
      "description": "DIPHOSPHORUS PENTAOXIDE; PHOSPHORIC ACID; POLYPHOSPHORIC ACIDS, WHETHER OR NOT CHEMICALLY DEFINED - DIPHOSPHORUS PENTAOXIDE"
    },
    {
      "code": "280920",
      "description": "Phosphoric acid and polyphosphoric acids"
    },
    {
      "code": "28092010",
      "description": "DIPHOSPHORUS PENTAOXIDE; PHOSPHORIC ACID; POLYPHOSPHORIC ACIDS, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHORIC ACID AND POLYPHOSPHORIC ACIDS : PHOSPORIC ACID"
    },
    {
      "code": "28092020",
      "description": "DIPHOSPHORUS PENTAOXIDE; PHOSPHORIC ACID; POLYPHOSPHORIC ACIDS, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHORIC ACID AND POLYPHOSPHORIC ACIDS : POLYPHOSPHORIC ACIDS"
    },
    {
      "code": "2810",
      "description": "OXIDES OF BORON; BORIC ACIDS - Oxides of boron; boric acids:"
    },
    {
      "code": "28100010",
      "description": "OXIDES OF BORON; BORIC ACIDS - OXIDES OF BORON; BORIC ACIDS : OXIDES OF BORON"
    },
    {
      "code": "28100020",
      "description": "OXIDES OF BORON; BORIC ACIDS - OXIDES OF BORON; BORIC ACIDS : BORIC ACIDS"
    },
    {
      "code": "2811",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - Other inorganic acids :"
    },
    {
      "code": "28111100",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : HYDROGEN FLUORIDE (HYDROFLUORIC ACID)"
    },
    {
      "code": "28111200",
      "description": "Hydrogen cyanide (hydrocyanic acid)"
    },
    {
      "code": "281119",
      "description": "Other"
    },
    {
      "code": "28111910",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : - OTHER : HYDROCYANIC ACID (HYDROGEN CYANIDE, PRUSSIC ACID)"
    },
    {
      "code": "28111920",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : - OTHER : HYPOPHOSPHORUS ACID (PHOSPHINIC ACID)"
    },
    {
      "code": "28111930",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : - OTHER : ACIDS OF ARSENIC"
    },
    {
      "code": "28111940",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : - OTHER : SULPHONIC ACID"
    },
    {
      "code": "28111990",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC ACIDS : - OTHER : OTHER"
    },
    {
      "code": "281121",
      "description": "Carbon dioxide"
    },
    {
      "code": "28112110",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - CARBON DIOXIDE : DRY ICE"
    },
    {
      "code": "28112190",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - CARBON DIOXIDE : OTHER"
    },
    {
      "code": "28112200",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : SILICON DIOXIDE"
    },
    {
      "code": "28112300",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : SULPHUR DIOXIDE"
    },
    {
      "code": "281129",
      "description": "Other"
    },
    {
      "code": "28112910",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : ARSENIC PENTAOXIDE"
    },
    {
      "code": "28112920",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : ARSENIC TRIOXIDE"
    },
    {
      "code": "28112930",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : NITROUS OXIDE"
    },
    {
      "code": "28112940",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : CARBON MONOXIDE"
    },
    {
      "code": "28112950",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : SULPHUR TRIOXIDE (SULPHURIC ANHYDRIDE)"
    },
    {
      "code": "28112990",
      "description": "OTHER INORGANIC ACIDS AND OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS - OTHER INORGANIC OXYGEN COMPOUNDS OF NON-METALS : - OTHER : OTHER"
    },
    {
      "code": "2812",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS"
    },
    {
      "code": "281210",
      "description": "Chlorides and chloride oxides"
    },
    {
      "code": "28121010",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : PHOSGENE (CARBONYL CHLORIDE, CARBON OXY-CHLORIDE, CHLOROFORMYL CHLORIDE)"
    },
    {
      "code": "28121020",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : PHOSPHORUS TRICHLORIDE"
    },
    {
      "code": "28121021",
      "description": "PHOSPHORUS TRICHLORIDE"
    },
    {
      "code": "28121022",
      "description": "PHOSPHORUS PENTACHLORIDE"
    },
    {
      "code": "28121030",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : PHOSPHORUS OXYCHLORIDE"
    },
    {
      "code": "28121040",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : SULPHUR OXYCHLORIDE, THIONYL CHLORIDE"
    },
    {
      "code": "28121041",
      "description": "SULPHUR OXYCHLORIDE"
    },
    {
      "code": "28121042",
      "description": "SULPHUR MONOCHLORIDE"
    },
    {
      "code": "28121043",
      "description": "SULPHUR DICHLORIDE"
    },
    {
      "code": "28121047",
      "description": "THIONYL CHLORIDE"
    },
    {
      "code": "28121050",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : SILICON TETRACHLORIDE"
    },
    {
      "code": "28121060",
      "description": "ARSENOUS TRICHLORIDE"
    },
    {
      "code": "28121090",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - CHLORIDES AND CHLORIDE OXIDES : OTHER"
    },
    {
      "code": "28121100",
      "description": "Carbonyl dichloride (phosgene)"
    },
    {
      "code": "28121200",
      "description": "Phosphorous oxychloride"
    },
    {
      "code": "28121300",
      "description": "Phosphorous trichloride"
    },
    {
      "code": "28121400",
      "description": "Phosphorous pentachloride"
    },
    {
      "code": "28121500",
      "description": "Sulphur monochloride"
    },
    {
      "code": "28121600",
      "description": "Sulphur dichloride"
    },
    {
      "code": "28121700",
      "description": "Thionyl chloride"
    },
    {
      "code": "28121910",
      "description": "Sulphur oxychloride"
    },
    {
      "code": "28121920",
      "description": "Silicon tetrachloride"
    },
    {
      "code": "28121930",
      "description": "Arsenous trichloride"
    },
    {
      "code": "28121990",
      "description": "other"
    },
    {
      "code": "28129000",
      "description": "HALIDES AND HALIDE OXIDES OF NON-METALS - OTHER"
    },
    {
      "code": "2813",
      "description": "SULPHIDES OF NON-METALS; COMMERCIAL PHOSPHORUS TRISULPHIDE"
    },
    {
      "code": "28131000",
      "description": "SULPHIDES OF NON-METALS; COMMERCIAL PHOSPHORUS TRISULPHIDE -CARBON DISULPHIDE"
    },
    {
      "code": "281390",
      "description": "Other"
    },
    {
      "code": "28139010",
      "description": "SULPHIDES OF NON-METALS; COMMERCIAL PHOSPHORUS TRISULPHIDE - OTHER : ARSENIC DISULPHIDE (ARTIFICIAL)"
    },
    {
      "code": "28139020",
      "description": "SULPHIDES OF NON-METALS; COMMERCIAL PHOSPHORUS TRISULPHIDE - OTHER : COMMERCIAL PHOSPHORUS TRISULPHIDE"
    },
    {
      "code": "28139090",
      "description": "SULPHIDES OF NON-METALS; COMMERCIAL PHOSPHORUS TRISULPHIDE - OTHER : OTHER"
    },
    {
      "code": "2814",
      "description": "AMMONIA, ANHYDROUS OR IN AQUEOUS SOLUTION"
    },
    {
      "code": "28141000",
      "description": "AMMONIA, ANHYDROUS OR IN AQUEOUS SOLUTION - ANHYDROUS AMMONIA"
    },
    {
      "code": "28142000",
      "description": "AMMONIA, ANHYDROUS OR IN AQUEOUS SOLUTION - AMMONIA IN AQUEOUS SOLUTION"
    },
    {
      "code": "2815",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - Sodium hydroxide (caustic soda) :"
    },
    {
      "code": "281511",
      "description": "Solid"
    },
    {
      "code": "28151110",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - SODIUM HYDROXIDE (CAUSTIC SODA) : - SOLID : FLAKES"
    },
    {
      "code": "28151190",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - SODIUM HYDROXIDE (CAUSTIC SODA) : - SOLID : OTHER"
    },
    {
      "code": "28151200",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - SODIUM HYDROXIDE (CAUSTIC SODA) : IN AQUEOUS SOLUTION (SODA LYE OR LIQUID SODA)"
    },
    {
      "code": "28152000",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - POTASSIUM HYDROXIDE (CAUSTIC POTASH)"
    },
    {
      "code": "28153000",
      "description": "SODIUM HYDROXIDE (CAUSTIC SODA); POTASSIUM HYDROXIDE (CAUSTIC POTASH); PEROXIDES OF SODIUM OR POTASSIUM - PEROXIDES OF SODIUM OR POTASSIUM"
    },
    {
      "code": "2816",
      "description": "HYDROXIDE AND PEROXIDE OF MAGNESIUM; OXIDES, HYDROXIDES AND PEROXIDES, OF STRONTIUM OR BARIUM"
    },
    {
      "code": "281610",
      "description": "Hydroxide and peroxide of magnesium"
    },
    {
      "code": "28161010",
      "description": "HYDROXIDE AND PEROXIDE OF MAGNESIUM; OXIDES, HYDROXIDES AND PEROXIDES, OF STRONTIUM OR BARIUM - HYDROXIDE AND PEROXIDE OF MAGNESIUM : HYDROXIDE OF MAGNESIUM"
    },
    {
      "code": "28161020",
      "description": "HYDROXIDE AND PEROXIDE OF MAGNESIUM; OXIDES, HYDROXIDES AND PEROXIDES, OF STRONTIUM OR BARIUM - HYDROXIDE AND PEROXIDE OF MAGNESIUM : PEROXIDE OF MAGNESIUM"
    },
    {
      "code": "28164000",
      "description": "HYDROXIDE AND PEROXIDE OF MAGNESIUM; OXIDES, HYDROXIDES AND PEROXIDES, OF STRONTIUM OR BARIUM - OXIDES, HYDROXIDES AND PEROXIDES, OF STRONTIUM OR BARIUM"
    },
    {
      "code": "2817",
      "description": "ZINC OXIDE; ZINC PEROXIDE"
    },
    {
      "code": "281700",
      "description": "Zinc oxide; zinc peroxide"
    },
    {
      "code": "28170010",
      "description": "ZINC OXIDE; ZINC PEROXIDE - ZINC OXIDE; ZINC PEROXIDE : ZINC OXIDE"
    },
    {
      "code": "28170020",
      "description": "ZINC OXIDE; ZINC PEROXIDE - ZINC OXIDE; ZINC PEROXIDE : ZINC PEROXIDE"
    },
    {
      "code": "2818",
      "description": "ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED; ALUMINIUM OXIDE; ALUMINIUM HYDROXIDE"
    },
    {
      "code": "28181000",
      "description": "ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED; ALUMINIUM OXIDE; ALUMINIUM HYDROXIDE ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "281820",
      "description": "Aluminium oxide, other than artificial corundum"
    },
    {
      "code": "28182010",
      "description": "ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED; ALUMINIUM OXIDE; ALUMINIUM HYDROXIDE - ALUMINIUM OXIDE, OTHER THAN ARTIFICIAL CORUNDUM : ALUMINA, CALCINED"
    },
    {
      "code": "28182090",
      "description": "ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED; ALUMINIUM OXIDE; ALUMINIUM HYDROXIDE - ALUMINIUM OXIDE, OTHER THAN ARTIFICIAL CORUNDUM : OTHER"
    },
    {
      "code": "28183000",
      "description": "ARTIFICIAL CORUNDUM, WHETHER OR NOT CHEMICALLY DEFINED; ALUMINIUM OXIDE; ALUMINIUM HYDROXIDE -ALUMINIUM HYDROXIDE"
    },
    {
      "code": "2819",
      "description": "CHROMIUM OXIDES AND HYDROXIDES"
    },
    {
      "code": "28191000",
      "description": "CHROMIUM OXIDES AND HYDROXIDES - CHROMIUM TRIOXIDE"
    },
    {
      "code": "28199000",
      "description": "CHROMIUM OXIDES AND HYDROXIDES - OTHER"
    },
    {
      "code": "2820",
      "description": "MANGANESE OXIDES"
    },
    {
      "code": "28201000",
      "description": "MANGANESE OXIDES - MANGANESE DIOXIDE"
    },
    {
      "code": "28209000",
      "description": "MANGANESE OXIDES - OTHER"
    },
    {
      "code": "2821",
      "description": "IRON OXIDES AND HYDROXIDES; EARTH COLOURS CONTAINING 70% OR MORE BY WEIGHT OF COMBINED IRON EVALUATED AS Fe2O3"
    },
    {
      "code": "282110",
      "description": "Iron oxides and hydroxides"
    },
    {
      "code": "28211010",
      "description": "IRON OXIDES AND HYDROXIDES; EARTH COLOURS CONTAINING 70% OR MORE BY WEIGHT OF COMBINED IRON EVALUATED AS Fe2O3 - IRON OXIDES AND HYDROXIDES : IRON OXIDES"
    },
    {
      "code": "28211020",
      "description": "IRON OXIDES AND HYDROXIDES; EARTH COLOURS CONTAINING 70% OR MORE BY WEIGHT OF COMBINED IRON EVALUATED AS Fe2O3 - IRON OXIDES AND HYDROXIDES : IRON HYDROXIDES"
    },
    {
      "code": "28212000",
      "description": "IRON OXIDES AND HYDROXIDES; EARTH COLOURS CONTAINING 70% OR MORE BY WEIGHT OF COMBINED IRON EVALUATED AS Fe2O3 - EARTH COLOURS"
    },
    {
      "code": "2822",
      "description": "COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES"
    },
    {
      "code": "282200",
      "description": "Cobalt oxides and hydroxides; commercial cobalt oxides"
    },
    {
      "code": "28220010",
      "description": "COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES - COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES : COBALT OXIDES"
    },
    {
      "code": "28220020",
      "description": "COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES - COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES : COBALT HYDROXIDES"
    },
    {
      "code": "28220030",
      "description": "COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES - COBALT OXIDES AND HYDROXIDES; COMMERCIAL COBALT OXIDES : COMMERCIAL COBALT OXIDES"
    },
    {
      "code": "2823",
      "description": "TITANIUM OXIDES"
    },
    {
      "code": "282300",
      "description": "Titanium oxides"
    },
    {
      "code": "28230010",
      "description": "TITANIUM OXIDES - TITANIUM OXIDES : TITANIUM DIOXIDE"
    },
    {
      "code": "28230090",
      "description": "TITANIUM OXIDES - TITANIUM OXIDES : OTHER"
    },
    {
      "code": "2824",
      "description": "LEAD OXIDES; RED LEAD AND ORANGE LEAD"
    },
    {
      "code": "282410",
      "description": "Lead monoxide (litharge, massicot)"
    },
    {
      "code": "28241010",
      "description": "LEAD OXIDES; RED LEAD AND ORANGE LEAD - LEAD MONOXIDE (LITHARGE, MASSICOT) : LITHARGE"
    },
    {
      "code": "28241020",
      "description": "LEAD OXIDES; RED LEAD AND ORANGE LEAD - LEAD MONOXIDE (LITHARGE, MASSICOT) : MASSICOT"
    },
    {
      "code": "28242000",
      "description": "LEAD OXIDES; RED LEAD AND ORANGE LEAD -RED LEAD AND ORANGE LEAD"
    },
    {
      "code": "28249000",
      "description": "LEAD OXIDES; RED LEAD AND ORANGE LEAD - OTHER"
    },
    {
      "code": "2825",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES"
    },
    {
      "code": "282510",
      "description": "Hydrazine and hydroxylamine and their inorganic salts"
    },
    {
      "code": "28251010",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS : HYDRAZINE ANHYDROUS"
    },
    {
      "code": "28251020",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS : HYDRAZINE HYDRATE"
    },
    {
      "code": "28251030",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS : HYDRAZINE SULPHATE"
    },
    {
      "code": "28251040",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS : HYDROXYLAMINE SULPHATE"
    },
    {
      "code": "28251090",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS : OTHER"
    },
    {
      "code": "28252000",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - LITHIUM OXIDE AND HYDROXIDE"
    },
    {
      "code": "282530",
      "description": "Vanadium oxides and hydroxides"
    },
    {
      "code": "28253010",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - VANADIUM OXIDES AND HYDROXIDES : VANADIUM PENTAOXIDE FLAKES"
    },
    {
      "code": "28253090",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES  VANADIUM OXIDES AND HYDROXIDES : OTHER"
    },
    {
      "code": "28254000",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - NICKEL OXIDES AND HYDROXIDES"
    },
    {
      "code": "28255000",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - COPPER OXIDES AND HYDROXIDES"
    },
    {
      "code": "282560",
      "description": "Germanium oxides and zirconium dioxide"
    },
    {
      "code": "28256010",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - GERMANIUM OXIDES AND ZIRCONIUM DIOXIDE : GERMANIUM OXIDES"
    },
    {
      "code": "28256020",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - GERMANIUM OXIDES AND ZIRCONIUM DIOXIDE : ZIRCONIUM DIOXIDE"
    },
    {
      "code": "282570",
      "description": "Molybdenum oxides and hydroxides"
    },
    {
      "code": "28257010",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - MOLYBDENUM OXIDES AND HYDROXIDES : MOLYBDENUM TRIOXIDE"
    },
    {
      "code": "28257020",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - MOLYBDENUM OXIDES AND HYDROXIDES : MOLYBDIC ACID"
    },
    {
      "code": "28257090",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - MOLYBDENUM OXIDES AND HYDROXIDES : OTHER"
    },
    {
      "code": "28258000",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES ANTIMONY OXIDES"
    },
    {
      "code": "282590",
      "description": "Other"
    },
    {
      "code": "28259010",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : TIN OXIDE"
    },
    {
      "code": "28259020",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : CADMIUM OXIDE"
    },
    {
      "code": "28259030",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : MERCURY OXIDES (MERCURIC OXIDE)"
    },
    {
      "code": "28259040",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : CALCIUM HYDROXIDE"
    },
    {
      "code": "28259050",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : AMMONIUM HYDROXIDE"
    },
    {
      "code": "28259090",
      "description": "HYDRAZINE AND HYDROXYLAMINE AND THEIR INORGANIC SALTS; OTHER INORGANIC BASES; OTHER METAL OXIDES, HYDROXIDES AND PEROXIDES - OTHER : OTHER"
    },
    {
      "code": "2826",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - Fluorides :"
    },
    {
      "code": "28261110",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUORIDES : - OF AMMONIUM OR OF SODIUM : AMMONIUM FLUORIDES"
    },
    {
      "code": "28261120",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUORIDES : - OF AMMONIUM OR OF SODIUM : SODIUM FLUORIDES"
    },
    {
      "code": "28261200",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUORIDES : OF ALUMINIUM"
    },
    {
      "code": "282619",
      "description": "Other"
    },
    {
      "code": "28261910",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUORIDES : - OTHER : MAGNESIUM FLUORIDE"
    },
    {
      "code": "28261990",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUORIDES : - OTHER : OTHER"
    },
    {
      "code": "28262010",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUOROSILICATES OF SODIUM OR OF POTASSIUM : FLUOROSILICATES OF SODIUM"
    },
    {
      "code": "28262020",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - FLUOROSILICATES OF SODIUM OR OF POTASSIUM : FLUOROSILICATES OF POTASSIUM"
    },
    {
      "code": "28263000",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS -SODIUM HEXAFLUOROALUMINATE (SYNTHETIC CRYOLITE)"
    },
    {
      "code": "28269000",
      "description": "FLUORIDES; FLUOROSILICATES, FLUOROALUMINATES AND OTHER COMPLEX FLUORINE SALTS - OTHER"
    },
    {
      "code": "2827",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES"
    },
    {
      "code": "28271000",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - AMMONIUM CHLORIDE"
    },
    {
      "code": "28272000",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - CALCIUM CHLORIDE"
    },
    {
      "code": "28273100",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF MAGNESIUM"
    },
    {
      "code": "28273200",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF ALUMINIUM"
    },
    {
      "code": "28273300",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF IRON"
    },
    {
      "code": "28273400",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF COBALT"
    },
    {
      "code": "28273500",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF NICKEL"
    },
    {
      "code": "28273600",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : OF ZINC"
    },
    {
      "code": "282739",
      "description": "Other"
    },
    {
      "code": "28273910",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : - OTHER : MERCURIC CHLORIDE"
    },
    {
      "code": "28273920",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : - OTHER : MERCUROUS CHLORIDE"
    },
    {
      "code": "28273930",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : - OTHER : STRONTIUM CHLORIDE"
    },
    {
      "code": "28273940",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : - OTHER : CUPROUS CHLORIDE"
    },
    {
      "code": "28273990",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - OTHER CHLORIDES : - OTHER : OTHER"
    },
    {
      "code": "282741",
      "description": "Of copper"
    },
    {
      "code": "28274110",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - CHLORIDE OXIDES AND CHLORIDE HYDROXIDES : - OF COPPER : COPPER OXYCHLORIDE"
    },
    {
      "code": "28274190",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - CHLORIDE OXIDES AND CHLORIDE HYDROXIDES : - OF COPPER : OTHER"
    },
    {
      "code": "28274900",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - CHLORIDE OXIDES AND CHLORIDE HYDROXIDES : OTHER"
    },
    {
      "code": "282751",
      "description": "Bromides of sodium or of potassium"
    },
    {
      "code": "28275110",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - BROMIDES AND BROMIDE OXIDES - BROMIDES OF SODIUM OR OF POTASSIUM : BROMIDES OF SODIUM"
    },
    {
      "code": "28275120",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - BROMIDES AND BROMIDE OXIDES - BROMIDES OF SODIUM OR OF POTASSIUM : BROMIDES OF POTASSIUM"
    },
    {
      "code": "282759",
      "description": "Other"
    },
    {
      "code": "28275910",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - BROMIDES AND BROMIDE OXIDES - OTHER : MAGNESIUM BROMIDE"
    },
    {
      "code": "28275990",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - BROMIDES AND BROMIDE OXIDES - OTHER : OTHER"
    },
    {
      "code": "282760",
      "description": "Iodides and iodide oxides"
    },
    {
      "code": "28276010",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - IODIDES AND IODIDE OXIDES - POTASSIUM IODIDE"
    },
    {
      "code": "28276020",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - IODIDES AND IODIDE OXIDES - SODIUM IODIDE"
    },
    {
      "code": "28276090",
      "description": "CHLORIDES, CHLORIDE OXIDES AND CHLORIDE HYDROXIDES; BROMIDES AND BROMIDE OXIDES; IODIDES AND IODIDE OXIDES - IODIDES AND IODIDE OXIDES - OTHER"
    },
    {
      "code": "2828",
      "description": "HYPOCHLORITES ; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES"
    },
    {
      "code": "282810",
      "description": "Commercial calcium hypochlorite and other calcium hypochlorites"
    },
    {
      "code": "28281010",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - COMMERCIAL CALCIUM HYPOCHLORITE AND OTHER CALCIUM HYPOCHLORITES : COMMERCIAL CALCIUM HYPOCHLORITE (BLEACHING PASTE OR POWDER)"
    },
    {
      "code": "28281090",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - COMMERCIAL CALCIUM HYPOCHLORITE AND OTHER CALCIUM HYPOCHLORITES : OTHER"
    },
    {
      "code": "282890",
      "description": "Other"
    },
    {
      "code": "28289011",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : - SODIUM HYPOCHLORITES : BLEACHING PASTE OR POWDER"
    },
    {
      "code": "28289019",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : - SODIUM HYPOCHLORITES : OTHER"
    },
    {
      "code": "28289020",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : POTASSIUM HYPOCHLORITES"
    },
    {
      "code": "28289030",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : SODIUM CHLORITE"
    },
    {
      "code": "28289040",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : ALUMINIUM CHLORITE"
    },
    {
      "code": "28289050",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : HYPOBROMITES"
    },
    {
      "code": "28289060",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : BLEACHING PASTE OR POWDER OF OTHER HYPOCHLORITES"
    },
    {
      "code": "28289090",
      "description": "HYPOCHLORITES; COMMERCIAL CALCIUM HYPOCHLORITES; CHLORITES; HYPOBROMITES - OTHER : OTHER"
    },
    {
      "code": "2829",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - Chlorates :"
    },
    {
      "code": "28291100",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - CHLORATES : OF SODIUM"
    },
    {
      "code": "282919",
      "description": "Other"
    },
    {
      "code": "28291910",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - CHLORATES : - OTHER : BARIUM CHLORATE"
    },
    {
      "code": "28291920",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - CHLORATES : - OTHER : POTASSIUM CHLORATE"
    },
    {
      "code": "28291930",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - CHLORATES : - OTHER : MAGNESIUM CHLORATE"
    },
    {
      "code": "28291990",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - CHLORATES : - OTHER : OTHER"
    },
    {
      "code": "282990",
      "description": "Other"
    },
    {
      "code": "28299010",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - OTHER : PERCHLORATES"
    },
    {
      "code": "28299020",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - OTHER : BROMATES AND PERBROMATES"
    },
    {
      "code": "28299030",
      "description": "CHLORATES AND PERCHLORATES; BROMATES AND PERBROMATES; IODATES AND PERIODATES - OTHER : IODATES AND PERIODATES"
    },
    {
      "code": "2830",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "28301000",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED - SODIUM SULPHIDES"
    },
    {
      "code": "28302000",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED - ZINC SULPHIDE"
    },
    {
      "code": "28303000",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED - CADMIUM SULPHIDE"
    },
    {
      "code": "283090",
      "description": "Other"
    },
    {
      "code": "28309010",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER - SULPHIDES"
    },
    {
      "code": "28309020",
      "description": "SULPHIDES; POLYSULPHIDES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER - POLYSULPHIDES"
    },
    {
      "code": "2831",
      "description": "DITHIONITES AND SULPHOXYLATES"
    },
    {
      "code": "283110",
      "description": "Of sodium"
    },
    {
      "code": "28311010",
      "description": "DITHIONITES AND SULPHOXYLATES - OF SODIUM : SODIUM DITHIONITES (SODIUM HYDROSULPHITE)"
    },
    {
      "code": "28311020",
      "description": "DITHIONITES AND SULPHOXYLATES - OF SODIUM : SODIUM SULPHOXYLATES (INCLUDING SODIUM FORMALDEHYDE SULPHOXYLATE)"
    },
    {
      "code": "283190",
      "description": "Other"
    },
    {
      "code": "28319010",
      "description": "DITHIONITES AND SULPHOXYLATES - OTHER : DITHIONITES"
    },
    {
      "code": "28319020",
      "description": "DITHIONITES AND SULPHOXYLATES - OTHER : SULPHOXYLATES"
    },
    {
      "code": "2832",
      "description": "SULPHITES; THIOSULPHATES"
    },
    {
      "code": "283210",
      "description": "Sodium sulphites"
    },
    {
      "code": "28321010",
      "description": "SULPHITES; THIOSULPHATES - SODIUM SULPHITES : SODIUM BISULPHITE"
    },
    {
      "code": "28321020",
      "description": "SULPHITES; THIOSULPHATES - SODIUM SULPHITES : SODIUM HYDROSULPHITE"
    },
    {
      "code": "28321090",
      "description": "SULPHITES; THIOSULPHATES - SODIUM SULPHITES : OTHER"
    },
    {
      "code": "283220",
      "description": "Other sulphites"
    },
    {
      "code": "28322010",
      "description": "SULPHITES; THIOSULPHATES - OTHER SULPHITES: POTASSIUM METABISULPHITE"
    },
    {
      "code": "28322020",
      "description": "SULPHITES; THIOSULPHATES - OTHER SULPHITES: MAGNESIUM SULPHITE"
    },
    {
      "code": "28322090",
      "description": "SULPHITES; THIOSULPHATES - OTHER SULPHITES: OTHER"
    },
    {
      "code": "283230",
      "description": "Thiosulphates"
    },
    {
      "code": "28323010",
      "description": "SULPHITES; THIOSULPHATES - THIOSULPHATES : SODIUM THIOSULPHATE (HYPO)"
    },
    {
      "code": "28323020",
      "description": "SULPHITES; THIOSULPHATES - THIOSULPHATES : MAGNESIUM THIOSULPHATE"
    },
    {
      "code": "28323090",
      "description": "SULPHITES; THIOSULPHATES - THIOSULPHATES : OTHER"
    },
    {
      "code": "2833",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES)"
    },
    {
      "code": "28331100",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - SODIUM SULPHATES : DISODIUM SULPHATE"
    },
    {
      "code": "283319",
      "description": "Other"
    },
    {
      "code": "28331910",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - SODIUM SULPHATES : - OTHER : SODIUM HYDROGEN SULPHATE (ACID SULPHATE)"
    },
    {
      "code": "28331920",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - SODIUM SULPHATES : - OTHER : SODIUM PYROSULPHATE"
    },
    {
      "code": "28331990",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - SODIUM SULPHATES : - OTHER : OTHER"
    },
    {
      "code": "28332100",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : OF MAGNESIUM"
    },
    {
      "code": "283322",
      "description": "Of aluminium"
    },
    {
      "code": "28332210",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OF ALUMINIUM : ALUMINIUM SULPHATE (IRON FREE)"
    },
    {
      "code": "28332290",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OF ALUMINIUM : OTHER"
    },
    {
      "code": "28332300",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : OF CHROMIUM"
    },
    {
      "code": "28332400",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : OF NICKEL"
    },
    {
      "code": "28332500",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : OF COPPER"
    },
    {
      "code": "28332610",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OF ZINC : AGRICULTURAL GRADE ZINC SULPHATE ORDINARILY USED AS MICRONUTRIENT"
    },
    {
      "code": "28332690",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OF ZINC : OTHER"
    },
    {
      "code": "28332700",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : OF BARIUM"
    },
    {
      "code": "283329",
      "description": "Other"
    },
    {
      "code": "28332910",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : FERROUS SULPHATE"
    },
    {
      "code": "28332920",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : MERCURIC SULPHATE"
    },
    {
      "code": "28332930",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : QUINIDINE SULPHATE"
    },
    {
      "code": "28332940",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : MANGANESE SULPHATE"
    },
    {
      "code": "28332950",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : STRONTIUM SULPHATE"
    },
    {
      "code": "28332990",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - OTHER SULPHATES : - OTHER : OTHER"
    },
    {
      "code": "283330",
      "description": "Alums"
    },
    {
      "code": "28333010",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - ALUMS: AMMONIUM ALUM"
    },
    {
      "code": "28333020",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - ALUMS: FERRIC AMMONIUM ALUM"
    },
    {
      "code": "28333030",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - ALUMS: POTASH ALUM"
    },
    {
      "code": "28333090",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - ALUMS: OTHER"
    },
    {
      "code": "28334000",
      "description": "SULPHATES; ALUMS; PEROXOSULPHATES (PERSULPHATES) - PEROXOSULPHATES (PERSULPHATES)"
    },
    {
      "code": "2834",
      "description": "NITRITES; NITRATES"
    },
    {
      "code": "283410",
      "description": "Nitrites"
    },
    {
      "code": "28341010",
      "description": "NITRITES; NITRATES - NITRITES : SODIUM NITRITE"
    },
    {
      "code": "28341090",
      "description": "NITRITES; NITRATES - NITRITES : OTHER"
    },
    {
      "code": "28342100",
      "description": "NITRITES; NITRATES - NITRATES: OF POTASSIUM"
    },
    {
      "code": "283429",
      "description": "Other"
    },
    {
      "code": "28342910",
      "description": "NITRITES; NITRATES - NITRATES: - OTHER : STRONTIUM NITRATE"
    },
    {
      "code": "28342920",
      "description": "NITRITES; NITRATES - NITRATES: - OTHER : MAGNESIUM NITRATE"
    },
    {
      "code": "28342930",
      "description": "NITRITES; NITRATES - NITRATES: - OTHER : BARIUM NITRATE"
    },
    {
      "code": "28342990",
      "description": "NITRITES; NITRATES - NITRATES: - OTHER : OTHER"
    },
    {
      "code": "2835",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "283510",
      "description": "Phosphinates (hypophosphites) and phosphonates (phosphites)"
    },
    {
      "code": "28351010",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED PHOSPHINATES (HYPOPHOSPHITES) AND PHOSPHONATES (PHOSPHITES) : CALCIUM HYPOPHOSPHITE"
    },
    {
      "code": "28351020",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED PHOSPHINATES (HYPOPHOSPHITES) AND PHOSPHONATES (PHOSPHITES) : MAGNESIUM HYPOPHOSPHITE"
    },
    {
      "code": "28351090",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED PHOSPHINATES (HYPOPHOSPHITES) AND PHOSPHONATES (PHOSPHITES) :OTHER"
    },
    {
      "code": "28352200",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : OF MONO-OR DISODIUM"
    },
    {
      "code": "28352300",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : OF TRISODIUM"
    },
    {
      "code": "28352400",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : OF POTASSIUM"
    },
    {
      "code": "28352500",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : CALCIUM HYDROGENORTHOPHOSPHATE (\"DICALCIUM PHOSPHATE\")"
    },
    {
      "code": "283526",
      "description": "Other phosphates of calcium"
    },
    {
      "code": "28352610",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER PHOSPHATES OF CALCIUM : CALCIUM MONOBASIC PHOSPHATE"
    },
    {
      "code": "28352620",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER PHOSPHATES OF CALCIUM : CALCIUM TRIBASIC PHOSPHATE"
    },
    {
      "code": "28352690",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER PHOSPHATES OF CALCIUM : OTHER"
    },
    {
      "code": "283529",
      "description": "Other"
    },
    {
      "code": "28352910",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER : MAGNESIUM PHOSPHATE, MONOBASIC"
    },
    {
      "code": "28352920",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER : MAGNESIUM PHOSPHATE, DIBASIC"
    },
    {
      "code": "28352930",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER : MAGNESIUM PHOSPHATE, TRIBASIC"
    },
    {
      "code": "28352940",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER : SODIUM HEXAMETAPHOSPHATE"
    },
    {
      "code": "28352990",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - PHOSPHATES : - OTHER : OTHER"
    },
    {
      "code": "28353100",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - POLYPHOSPHATES : SODIUM TRIPHOSPHATE (SODIUM TRIPOLY- PHOSPHATE)"
    },
    {
      "code": "28353900",
      "description": "PHOSPHINATES (HYPOPHOSPHITES), PHOSPHONATES (PHOSPHITES) AND PHOSPHATES; POLYPHOSPHATES, WHETHER OR NOT CHEMICALLY DEFINED - POLYPHOSPHATES : OTHER"
    },
    {
      "code": "2836",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE"
    },
    {
      "code": "28361000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE COMMERCIAL AMMONIUM CARBONATE AND OTHER AMMONIUM CARBONATES"
    },
    {
      "code": "283620",
      "description": "Disodium carbonate"
    },
    {
      "code": "28362010",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - DISODIUM CARBONATE : DISODIUM CARBONATE, DENSE"
    },
    {
      "code": "28362020",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - DISODIUM CARBONATE : DISODIUM CARBONATE, LIGHT"
    },
    {
      "code": "28362090",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - DISODIUM CARBONATE : OTHER"
    },
    {
      "code": "28363000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE SODIUM HYDROGENCARBONATE (SODIUM BICARBONATE)"
    },
    {
      "code": "28364000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE POTASSIUM CARBONATES"
    },
    {
      "code": "28365000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE CALCIUM CARBONATE"
    },
    {
      "code": "28366000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE BARIUM CARBONATE"
    },
    {
      "code": "28367000",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE LEAD CARBONATE"
    },
    {
      "code": "28369100",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : LITHIUM CARBONATES"
    },
    {
      "code": "28369200",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : STRONTIUM CARBONATE"
    },
    {
      "code": "283699",
      "description": "Other"
    },
    {
      "code": "28369910",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : - OTHER : PERCARBONATES"
    },
    {
      "code": "28369920",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : - OTHER : MAGNESIUM CARBONATE"
    },
    {
      "code": "28369930",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : - OTHER : ALUMINIUM BICARBONATE"
    },
    {
      "code": "28369990",
      "description": "CARBONATES; PEROXOCARBONATES (PERCARBONATES); COMMERCIAL AMMONIUM CARBONATE CONTAINING AMMONIUM CARBAMATE - OTHER : - OTHER : OTHER"
    },
    {
      "code": "2837",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - Cyanides and cyanide oxides :"
    },
    {
      "code": "28371100",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - CYANIDES AND CYANIDE OXIDES : OF SODIUM"
    },
    {
      "code": "283719",
      "description": "Other"
    },
    {
      "code": "28371910",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - CYANIDES AND CYANIDE OXIDES : - OTHER : POTASSIUM CYANIDE"
    },
    {
      "code": "28371920",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - CYANIDES AND CYANIDE OXIDES : - OTHER : DOUBLE CYANIDE OF POTASSIUM AND SODIUM"
    },
    {
      "code": "28371990",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - CYANIDES AND CYANIDE OXIDES : - OTHER : OTHER"
    },
    {
      "code": "283720",
      "description": "Complex cyanides"
    },
    {
      "code": "28372010",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : AMMONIUM SULPHOCYANIDE"
    },
    {
      "code": "28372020",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : POTASSIUM FERRICYANIDE"
    },
    {
      "code": "28372030",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : POTASSIUM FERROCYANIDE"
    },
    {
      "code": "28372040",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : SODIUM FERROCYANIDE"
    },
    {
      "code": "28372050",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : SODIUM NITROPRUSSIDE (SODIUM NITROFERRICYANIDE)"
    },
    {
      "code": "28372090",
      "description": "CYANIDES, CYANIDE OXIDES AND COMPLEX CYANIDES - COMPLEX CYANIDES : OTHER"
    },
    {
      "code": "2838",
      "description": "FULMINATES, CYANATES AND THIOCYANATES - FULMINATES, CYANATES AND THIOCYANATES : FULMINATES"
    },
    {
      "code": "28380010",
      "description": "FULMINATES, CYANATES AND THIOCYANATES - FULMINATES, CYANATES AND THIOCYANATES : FULMINATES"
    },
    {
      "code": "28380020",
      "description": "FULMINATES, CYANATES AND THIOCYANATES - FULMINATES, CYANATES AND THIOCYANATES : CYANATES"
    },
    {
      "code": "28380030",
      "description": "FULMINATES, CYANATES AND THIOCYANATES - FULMINATES, CYANATES AND THIOCYANATES : THIOCYANATES"
    },
    {
      "code": "2839",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - Of sodium :"
    },
    {
      "code": "28391100",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - OF SODIUM : SODIUM METASILICATES"
    },
    {
      "code": "28391900",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - OF SODIUM : OTHER"
    },
    {
      "code": "28392000",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - OF POTASSIUM"
    },
    {
      "code": "283990",
      "description": "Other"
    },
    {
      "code": "28399010",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - OTHER : MAGNESIUM TRISILICATE"
    },
    {
      "code": "28399090",
      "description": "SILICATES; COMMERCIAL ALKALI METAL SILICATES - OTHER : OTHER"
    },
    {
      "code": "2840",
      "description": "BORATES; PEROXOBORATES (PERBORATES) - Disodium tetraborate (refined borax):"
    },
    {
      "code": "28401100",
      "description": "BORATES; PEROXOBORATES (PERBORATES) - DISODIUM TETRABORATE (REFINED BORAX) : ANHYDROUS"
    },
    {
      "code": "28401900",
      "description": "BORATES; PEROXOBORATES (PERBORATES) - DISODIUM TETRABORATE (REFINED BORAX) : OTHER"
    },
    {
      "code": "284020",
      "description": "Other borates"
    },
    {
      "code": "28402010",
      "description": "BORATES; PEROXOBORATES (PERBORATES) - OTHER BORATES : MAGNESIUM BORATE"
    },
    {
      "code": "28402090",
      "description": "BORATES; PEROXOBORATES (PERBORATES) - OTHER BORATES : OTHER"
    },
    {
      "code": "28403000",
      "description": "BORATES; PEROXOBORATES (PERBORATES) PEROXOBORATES (PERBORATES)"
    },
    {
      "code": "2841",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS"
    },
    {
      "code": "28411010",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - ALUMINATES : SODIUM ALUMINATE"
    },
    {
      "code": "28411090",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - ALUMINATES : OTHER"
    },
    {
      "code": "28412010",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - CHROMATES OF ZINC OR OF LEAD : CHROMATES OF ZINC"
    },
    {
      "code": "28412020",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - CHROMATES OF ZINC OR OF LEAD : CHROMATES OF LEAD"
    },
    {
      "code": "28413000",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS SODIUM DICHROMATE"
    },
    {
      "code": "284150",
      "description": "Other chromates and dichromates; peroxochromates"
    },
    {
      "code": "28415010",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - OTHER CHROMATES AND DICHROMATES; PEROXOCHROMATES : SODIUM CHROMATE"
    },
    {
      "code": "28415090",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - OTHER CHROMATES AND DICHROMATES; PEROXOCHROMATES : OTHER"
    },
    {
      "code": "28416100",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - MANGANITES, MANGANATES AND PERMANGANATES : POTASSIUM PERMANGANATE"
    },
    {
      "code": "28416900",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - MANGANITES, MANGANATES AND PERMANGANATES : OTHER"
    },
    {
      "code": "284170",
      "description": "Molybdates"
    },
    {
      "code": "28417010",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - MOLYBDATES : ALUMINIUM MOLYBDATE"
    },
    {
      "code": "28417020",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - MOLYBDATES : SODIUM MOLYBDATE"
    },
    {
      "code": "28417090",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - MOLYBDATES : OTHER"
    },
    {
      "code": "284180",
      "description": "Tungstates (wolframates)"
    },
    {
      "code": "28418010",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - TUNGSTATES (WOLFRAMATES) : SODIUM TUNGSTATE"
    },
    {
      "code": "28418020",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - TUNGSTATES (WOLFRAMATES) : MAGNESIUM TUNGSTATE"
    },
    {
      "code": "28418090",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - TUNGSTATES (WOLFRAMATES) : OTHER"
    },
    {
      "code": "28419000",
      "description": "SALTS OF OXOMETALLIC OR PEROXOMETALLIC ACIDS - OTHER"
    },
    {
      "code": "2842",
      "description": "OTHER SALTS OF INORGANIC ACIDS OR PEROXOACIDS, (INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED), OTHER THAN AZIDES"
    },
    {
      "code": "28421000",
      "description": "OTHER SALTS OF INORGANIC ACIDS OR PEROXOACIDS, (INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED), OTHER THAN AZIDES -DOUBLE OR COMPLEX SILICATES, INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "284290",
      "description": "Other"
    },
    {
      "code": "28429010",
      "description": "OTHER SALTS OF INORGANIC ACIDS OR PEROXOACIDS, (INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED), OTHER THAN AZIDES - OTHER : ARSENITES AND ARSENATES"
    },
    {
      "code": "28429020",
      "description": "OTHER SALTS OF INORGANIC ACIDS OR PEROXOACIDS, (INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED), OTHER THAN AZIDES - OTHER : BICHROMATES AND DICHROMATES"
    },
    {
      "code": "28429090",
      "description": "OTHER SALTS OF INORGANIC ACIDS OR PEROXOACIDS, (INCLUDING ALUMINOSILICATES, WHETHER OR NOT CHEMICALLY DEFINED), OTHER THAN AZIDES - OTHER : OTHER"
    },
    {
      "code": "2843",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS"
    },
    {
      "code": "284310",
      "description": "Colloidal precious metals"
    },
    {
      "code": "28431010",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - COLLOIDAL PRECIOUS METALS : OF GOLD"
    },
    {
      "code": "28431020",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - COLLOIDAL PRECIOUS METALS : OF SILVER"
    },
    {
      "code": "28431090",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - COLLOIDAL PRECIOUS METALS : OTHER"
    },
    {
      "code": "28432100",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - SILVER COMPOUNDS : SILVER NITRATE"
    },
    {
      "code": "28432900",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - SILVER COMPOUNDS : OTHER"
    },
    {
      "code": "28433000",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - GOLD COMPOUNDS"
    },
    {
      "code": "284390",
      "description": "Other compounds; amalgams"
    },
    {
      "code": "28439011",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - OTHER COMPOUNDS; AMALGAMS : - OTHER COMPOUNDS : SODIUM AUROUS THIOSULPHATE"
    },
    {
      "code": "28439012",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - OTHER COMPOUNDS; AMALGAMS : - OTHER COMPOUNDS : NOBLE METAL SOLUTIONS OF PLATINUM, RHODIUM AND PALLADIUM"
    },
    {
      "code": "28439019",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - OTHER COMPOUNDS; AMALGAMS : - OTHER COMPOUNDS : OTHER"
    },
    {
      "code": "28439020",
      "description": "COLLOIDAL PRECIOUS METALS; INORGANIC OR ORGANIC COMPOUNDS OF PRECIOUS METALS, WHETHER OR NOT CHEMICALLY DEFINED; AMALGAMS OF PRECIOUS METALS - OTHER COMPOUNDS; AMALGAMS : AMALGAMS"
    },
    {
      "code": "2844",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS"
    },
    {
      "code": "28441000",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - NATURAL URANIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING NATURAL URANIUM OR NATURAL URANIUM COMPOUNDS"
    },
    {
      "code": "28442000",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM ENRICHED IN U235 AND ITS COMPOUNDS; PLUTONIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM ENRICHED IN U235, PLUTONIUM OR COMPOUNDS OF THESE PRODUCTS"
    },
    {
      "code": "284430",
      "description": "Uranium depleted in U235 and its compounds; thorium and its compounds; alloys, dispersions (including cermets), ceramic products and mixtures containing uranium depleted in U235, thorium or compounds of these products"
    },
    {
      "code": "28443010",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : URANIUM DEPLETED IN U235 AND THORIUM AND THEIR ALLOYS, UNWROUGHT OR WROUGHT AND COMPOUNDS THEREOF"
    },
    {
      "code": "28443021",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : - COMPOUNDS OF THORIUM OR OF URANIUM DEPLETED IN U235 : THORIUM OXIDE"
    },
    {
      "code": "28443022",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : - COMPOUNDS OF THORIUM OR OF URANIUM DEPLETED IN U235 : THORIUM HYDROXIDE"
    },
    {
      "code": "28443023",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : - COMPOUNDS OF THORIUM OR OF URANIUM DEPLETED IN U235 : THORIUM NITRATE"
    },
    {
      "code": "28443029",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : - COMPOUNDS OF THORIUM OR OF URANIUM DEPLETED IN U235 : OTHER"
    },
    {
      "code": "28443030",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : WASTE AND SCRAP OF URANIUM DEPLETED IN U235 OR OF THORIUM"
    },
    {
      "code": "28443090",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - URANIUM DEPLETED IN U235 AND ITS COMPOUNDS; THORIUM AND ITS COMPOUNDS; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING URANIUM DEPLETED IN U235, THORIUM OR COMPOUNDS OF THESE PRODUCTS : OTHER"
    },
    {
      "code": "28444000",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - RADIOACTIVE ELEMENTS AND ISOTOPES AND COMPOUNDS OTHER THAN THOSE OF SUBHEADINGS 2844 10, 2844 20 OR 2844 30; ALLOYS, DISPERSIONS (INCLUDING CERMETS), CERAMIC PRODUCTS AND MIXTURES CONTAINING THESE ELEMENTS, ISOTOPES OR COMPOUNDS; RADIOACTIVE RESIDUES"
    },
    {
      "code": "28445000",
      "description": "RADIOACTIVE CHEMICAL ELEMENTS AND RADIOACTIVE ISOTOPES (INCLUDING THE FISSILE OR FERTILE CHEMICAL ELEMENTS AND ISOTOPES) AND THEIR COMPOUNDS; MIXTURES AND RESIDUES CONTAINING THESE PRODUCTS - SPENT (IRRADIATED) FUEL ELEMENTS (CARTRIDGES) OF NUCLEAR REACTORS"
    },
    {
      "code": "2845",
      "description": "ISOTOPES OTHER THAN THOSE OF HEADING 2844; COMPOUNDS, INORGANIC OR ORGANIC, OF SUCH ISOTOPES, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "28451000",
      "description": "ISOTOPES OTHER THAN THOSE OF HEADING 2844; COMPOUNDS, INORGANIC OR ORGANIC, OF SUCH ISOTOPES, WHETHER OR NOT CHEMICALLY DEFINED -HEAVY WATER (DEUTERIUM OXIDE)"
    },
    {
      "code": "284590",
      "description": "Other"
    },
    {
      "code": "28459010",
      "description": "ISOTOPES OTHER THAN THOSE OF HEADING 2844; COMPOUNDS, INORGANIC OR ORGANIC, OF SUCH ISOTOPES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER : NUCLEAR FUELS NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "28459090",
      "description": "ISOTOPES OTHER THAN THOSE OF HEADING 2844; COMPOUNDS, INORGANIC OR ORGANIC, OF SUCH ISOTOPES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER : OTHER"
    },
    {
      "code": "2846",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS"
    },
    {
      "code": "284610",
      "description": "Cerium compounds"
    },
    {
      "code": "28461010",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - CERIUM COMPOUNDS : CERIUM OXIDES"
    },
    {
      "code": "28461090",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - CERIUM COMPOUNDS : OTHER"
    },
    {
      "code": "284690",
      "description": "Other"
    },
    {
      "code": "28469010",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - OTHER : RARE-EARTH OXIDES NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "28469020",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - OTHER : RARE-EARTH FLUORIDES NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "28469030",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - OTHER : RARE-EARTH CHLORIDES NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "28469090",
      "description": "COMPOUNDS, INORGANIC OR ORGANIC, OF RARE-EARTH METALS, OF YTTRIUM OR OF SCANDIUM OR OF MIXTURES OF THESE METALS - OTHER : OTHER"
    },
    {
      "code": "2847",
      "description": "HYDROGEN PEROXIDE, WHETHER OR NOT SOLIDIFIED WITH UREA"
    },
    {
      "code": "28470000",
      "description": "HYDROGEN PEROXIDE, WHETHER OR NOT SOLIDIFIED WITH UREA"
    },
    {
      "code": "2848",
      "description": "PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS"
    },
    {
      "code": "284800",
      "description": "Phosphides, whether or not chemically defined, excluding ferrophosphorus"
    },
    {
      "code": "28480010",
      "description": "PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS - PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS : OF COPPER (PHOSPHOR COPPER), CONTAINING MORE THAN 15% BY WEIGHT OF PHOSPHORUS"
    },
    {
      "code": "28480020",
      "description": "PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS - PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS : OF ZINC"
    },
    {
      "code": "28480090",
      "description": "PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS - PHOSPHIDES, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING FERROPHOSPHORUS : OTHER"
    },
    {
      "code": "2849",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "28491000",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OF CALCIUM"
    },
    {
      "code": "284920",
      "description": "Of silicon"
    },
    {
      "code": "28492010",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OF SILICON : CARBORUNDUM"
    },
    {
      "code": "28492090",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OF SILICON : OTHER"
    },
    {
      "code": "284990",
      "description": "Other"
    },
    {
      "code": "28499010",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER :BORON CARBIDE"
    },
    {
      "code": "28499020",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER :TUNGSTEN CARBIDE"
    },
    {
      "code": "28499090",
      "description": "CARBIDES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER :OTHER"
    },
    {
      "code": "2850",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849"
    },
    {
      "code": "285000",
      "description": "Hydrides, nitrides, azides, silicides and borides, whether or not chemically defined, other than compounds which are also carbides of heading 2849"
    },
    {
      "code": "28500010",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : HYDRIDES"
    },
    {
      "code": "28500020",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : NITRIDES"
    },
    {
      "code": "28500030",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : AZIDES"
    },
    {
      "code": "28500041",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : - SILICIDES : OF CALCIUM"
    },
    {
      "code": "28500049",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : - SILICIDES : OTHER"
    },
    {
      "code": "28500050",
      "description": "HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 - HYDRIDES, NITRIDES, AZIDES, SILICIDES AND BORIDES, WHETHER OR NOT CHEMICALLY DEFINED, OTHER THAN COMPOUNDS WHICH ARE ALSO CARBIDES OF HEADING 2849 : BORIDES"
    },
    {
      "code": "2851",
      "description": "-"
    },
    {
      "code": "28510010",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY"
    },
    {
      "code": "28510020",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : LIQUID AIR, WHETHER OR NOT ANY FRACTION OF RARE GASES HAS BEEN REMOVED"
    },
    {
      "code": "28510030",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : COMPRESSED AIR"
    },
    {
      "code": "28510040",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS"
    },
    {
      "code": "28510090",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : OTHER"
    },
    {
      "code": "28510091",
      "description": "CYNOGEN CHLORIDE"
    },
    {
      "code": "28510099",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: OTHER"
    },
    {
      "code": "2852",
      "description": "INORGANIC OR ORGANIC COMPOUNDS OF MERCURY, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING AMALGAMS:"
    },
    {
      "code": "285210",
      "description": "Chemically defined"
    },
    {
      "code": "28521000",
      "description": "INORGANIC OR ORGANIC COMPOUNDS OF MERCURY, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING AMALGAMS;-CHEMICALLY DEFINED"
    },
    {
      "code": "285290",
      "description": "Other"
    },
    {
      "code": "28529000",
      "description": "INORGANIC OR ORGANIC COMPOUNDS OF MERCURY, WHETHER OR NOT CHEMICALLY DEFINED, EXCLUDING AMALGAMS;-OTHER"
    },
    {
      "code": "2853",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS"
    },
    {
      "code": "285300",
      "description": "Other inorganic compounds (including distilled or conductivity water and water of similar purity); liquid air (whether or not rare gases have been removed); compressed air; amalgams, other than amalgams of precious metals"
    },
    {
      "code": "28530010",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY"
    },
    {
      "code": "28530020",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- LIQUID AIR, WHETHER OR NOT ANY FRACTION OF RARE GASES HAS BEEN REMOVED"
    },
    {
      "code": "28530030",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- COMPRESSED AIR"
    },
    {
      "code": "28530040",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- AMALGAMS, OTHER THAN OF PRECIOUS METALS"
    },
    {
      "code": "28530091",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- OTHER ---- CYANOGEN CHLORIDE [(CN) CL]"
    },
    {
      "code": "28530099",
      "description": "OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS: - OTHER INORGANIC COMPOUNDS (INCLUDING DISTILLED OR CONDUCTIVITY WATER AND WATER OF SIMILAR PURITY); LIQUID AIR (WHETHER OR NOT RARE GASES HAVE BEEN REMOVED); COMPRESSED AIR; AMALGAMS, OTHER THAN AMALGAMS OF PRECIOUS METALS : AMALGAMS, OTHER THAN OF PRECIOUS METALS --- OTHER ---- OTHER"
    },
    {
      "code": "28531000",
      "description": "Cyanogen chloride (Chlorcyan)"
    },
    {
      "code": "28539010",
      "description": "Distilled or conductivity water and water of similar purity"
    },
    {
      "code": "28539020",
      "description": "Liquid air, whether or not rare gases has been removed"
    },
    {
      "code": "28539030",
      "description": "Compressed air"
    },
    {
      "code": "28539040",
      "description": "Amalgams, other than of precious metals"
    },
    {
      "code": "28539090",
      "description": "Other"
    },
    {
      "code": "29",
      "description": "elements or of isotopes"
    },
    {
      "code": "2901",
      "description": "ACYCLIC HYDROCARBONS"
    },
    {
      "code": "29011000",
      "description": "ACYCLIC HYDROCARBONS - SATURATED"
    },
    {
      "code": "29012100",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED : ETHYLENE"
    },
    {
      "code": "29012200",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED : PROPENE (PROPYLENE)"
    },
    {
      "code": "29012300",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED : BUTENE (BUTYLENE) AND ISOMERS THEREOF"
    },
    {
      "code": "29012400",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED : BUTA-1,3-DIENE AND ISOPRENE"
    },
    {
      "code": "290129",
      "description": "Other"
    },
    {
      "code": "29012910",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED :-OTHER : ACETYLENE, WHETHER OR NOT IN DISSOLVED CONDITION"
    },
    {
      "code": "29012920",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED :-OTHER : HEPTENE (HEPTYLENE)"
    },
    {
      "code": "29012930",
      "description": "Dihydromyrcene"
    },
    {
      "code": "29012940",
      "description": "Tetradecene"
    },
    {
      "code": "29012990",
      "description": "ACYCLIC HYDROCARBONS - UNSATURATED :-OTHER : OTHER"
    },
    {
      "code": "2902",
      "description": "CYCLIC HYDROCARBONS - Cyclanes, cyclenes and cycloterpenes :"
    },
    {
      "code": "29021100",
      "description": "CYCLIC HYDROCARBONS - CYCLANES, CYCLENES AND CYCLOTERPENES : CYCLOHEXANE"
    },
    {
      "code": "290219",
      "description": "Other"
    },
    {
      "code": "29021900",
      "description": "CYCLIC HYDROCARBONS - CYCLANES, CYCLENES AND CYCLOTERPENES : OTHER"
    },
    {
      "code": "29021910",
      "description": "Cyclopropyl acetetylene"
    },
    {
      "code": "29021990",
      "description": "Other"
    },
    {
      "code": "29022000",
      "description": "CYCLIC HYDROCARBONS- BENZENE"
    },
    {
      "code": "29023000",
      "description": "CYCLIC HYDROCARBONS -TOLUENE"
    },
    {
      "code": "29024100",
      "description": "CYCLIC HYDROCARBONS - XYLENES : O-XYLENE"
    },
    {
      "code": "29024200",
      "description": "CYCLIC HYDROCARBONS - XYLENES : M-XYLENE"
    },
    {
      "code": "29024300",
      "description": "CYCLIC HYDROCARBONS - XYLENES : P-XYLENE"
    },
    {
      "code": "29024400",
      "description": "CYCLIC HYDROCARBONS - XYLENES : MIXED XYLENE ISOMERS"
    },
    {
      "code": "29025000",
      "description": "CYCLIC HYDROCARBONS - STYRENE"
    },
    {
      "code": "29026000",
      "description": "CYCLIC HYDROCARBONS - ETHYLBENZENE"
    },
    {
      "code": "29027000",
      "description": "CYCLIC HYDROCARBONS - CUMENE"
    },
    {
      "code": "290290",
      "description": "Other"
    },
    {
      "code": "29029010",
      "description": "CYCLIC HYDROCARBONS - OTHER :DIPENTENE"
    },
    {
      "code": "29029020",
      "description": "CYCLIC HYDROCARBONS - OTHER :DIPHENYL METHANE"
    },
    {
      "code": "29029030",
      "description": "CYCLIC HYDROCARBONS - OTHER :DODECYCLIC BENZENES (EXCLUDING MIXED ALKYLARENES)"
    },
    {
      "code": "29029040",
      "description": "CYCLIC HYDROCARBONS - OTHER :NAPTHALENE, PURE"
    },
    {
      "code": "29029050",
      "description": "CYCLIC HYDROCARBONS - OTHER :ISOBUTYL BENZENE"
    },
    {
      "code": "29029060",
      "description": "N-propyl benzene"
    },
    {
      "code": "29029090",
      "description": "CYCLIC HYDROCARBONS - OTHER :OTHER"
    },
    {
      "code": "2903",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - Saturated chlorinated derivatives of acyclic hydrocarbons :"
    },
    {
      "code": "290311",
      "description": "Chloromethane (methyl chloride) and chloroethane (ethyl chloride)"
    },
    {
      "code": "29031110",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS :-CHLOROMETHANE (METHYL CHLORIDE) AND CHLOROETHANE (ETHYL CHLORIDE) : CHLOROMETHANE (METHYL CHLORIDE)"
    },
    {
      "code": "29031120",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS :-CHLOROMETHANE (METHYL CHLORIDE) AND CHLOROETHANE (ETHYL CHLORIDE) : CHLOROETHANE (ETHYL CHLORIDE)"
    },
    {
      "code": "29031200",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : DICHLOROMETHANE (METHYLENE CHLORIDE)"
    },
    {
      "code": "29031300",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : CHLOROFORM (TRICHLORO METHANE)"
    },
    {
      "code": "29031400",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : CARBON TETRACHLORIDE"
    },
    {
      "code": "29031500",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- ETHYLENE DICHLORIDE (ISO) (1,2-DICHLOROETHANE)"
    },
    {
      "code": "290319",
      "description": "Other"
    },
    {
      "code": "29031910",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS :-OTHER : TETRACHLOROETHANE"
    },
    {
      "code": "29031920",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS :-OTHER : TRICHLOROETHANE"
    },
    {
      "code": "29031990",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - SATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS :-OTHER : OTHER"
    },
    {
      "code": "29032100",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - UNSATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : VINYL CHLORIDE (CHLOROETHYLENE)"
    },
    {
      "code": "29032200",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - UNSATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : TRICHLOROETHYLENE"
    },
    {
      "code": "29032300",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - UNSATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : TETRACHLOROETHYLENE (PERCHLOROETHYLENE)"
    },
    {
      "code": "29032900",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - UNSATURATED CHLORINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : OTHER"
    },
    {
      "code": "29033010",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : FLUORINATED DERIVATIVES"
    },
    {
      "code": "29033011",
      "description": "1-PROPENDE, 1,1,3,3,3,-PENTAFLUORO-2(TRIFLUOROMETHYL(PFIB)"
    },
    {
      "code": "29033019",
      "description": "FLUORINATED DERIVATIVES - OTHERS"
    },
    {
      "code": "29033020",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : BROMINATED DERIVATIVES"
    },
    {
      "code": "29033030",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS : IODINATED DERIVATIVES"
    },
    {
      "code": "29033100",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- ETHYLENE DIBROMIDE (ISO) (1,2-DIBROMOETHANE)"
    },
    {
      "code": "290339",
      "description": "Other"
    },
    {
      "code": "29033911",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- OTHER --- FLUORINATED DERIVATIVES ---- 1-PROPENE, 1, 1, 3,3,3 PENTAFLUORO - 2-(TRIFLUOROMETHYL) (PFIB)"
    },
    {
      "code": "29033919",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- OTHER --- FLUORINATED DERIVATIVES ---- OTHER"
    },
    {
      "code": "29033920",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- OTHER --- FLUORINATED DERIVATIVES ---- BROMINATED DERIVATIVES"
    },
    {
      "code": "29033930",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- OTHER --- FLUORINATED DERIVATIVES ---- IODINATED DERIVATIVES"
    },
    {
      "code": "29033990",
      "description": "HALOGENATED DERIVATIVES OF HYDROCARBONS - FLUORINATED, BROMINATED OR IODINATED DERIVATIVES OF ACYCLIC HYDROCARBONS -- OTHER --- OTHER ---- OTHER"
    },
    {
      "code": "290371",
      "description": "Halogenated derivatives of acyclic hydrocarons containing two or more different halogens"
    },
    {
      "code": "29037100",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--CHLORODIFLUOROMETHANE"
    },
    {
      "code": "290372",
      "description": "Hologenated derivatives of acyclic hydrocarbons containing two or more different hologens"
    },
    {
      "code": "29037200",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--DICHLOROTRIFLUOROETHANES"
    },
    {
      "code": "290373",
      "description": "Hologenated derivatives of acyclic hydrocarbons containing two or more different hologens"
    },
    {
      "code": "29037300",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--DICHLOROFLUOROETHANES"
    },
    {
      "code": "290374",
      "description": "Hologenated derivatives of acyclic hydrocarbons containing two or more different hologens"
    },
    {
      "code": "29037400",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--CHLORODIFLUOROETHANES"
    },
    {
      "code": "290375",
      "description": "Hologenated derivatives of acyclic hydrocarbons containing two or more different hologens"
    },
    {
      "code": "29037500",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--DICHLOROPENTAFLUOROPROPANES"
    },
    {
      "code": "290376",
      "description": "Bromochlorodifluoromethane, bromotrifluoromethane and dibromotetrafluoroethanes"
    },
    {
      "code": "29037610",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--BROMOCHLORODIFLUOROMETHANE, BROMOTRIFLUOROMETHANE AND DIBROMOTETRAFLUOROETHANES; ---BROMOCHLORODIFLUOROMETHANE"
    },
    {
      "code": "29037620",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--BROMOCHLORODIFLUOROMETHANE, BROMOTRIFLUOROMETHANE AND DIBROMOTETRAFLUOROETHANES; ---BROMOTRIFLUOROMETHANE"
    },
    {
      "code": "29037630",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--BROMOCHLORODIFLUOROMETHANE, BROMOTRIFLUOROMETHANE AND DIBROMOTETRAFLUOROETHANES; ---DIBROMOTETRAFLUOROETHANES"
    },
    {
      "code": "290377",
      "description": "Other, perhalogenated only with fluorine and chlorine"
    },
    {
      "code": "29037711",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROMETHANES----CHLOROTRIFLUOROMETHANE"
    },
    {
      "code": "29037712",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROMETHANES----DICHLORODIFLUOROMETHANE"
    },
    {
      "code": "29037713",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROMETHANES----TRICHLOROFLUOROMETHANE"
    },
    {
      "code": "29037721",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROETHANES----CHLOROPENTAFLUOROETHANE"
    },
    {
      "code": "29037722",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROETHANES----1,2-DICHLOROTETRAFLUOROETHANE"
    },
    {
      "code": "29037723",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROETHANES----TRICHLOROTRIFLUOROETHANE"
    },
    {
      "code": "29037724",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROETHANES----TETRACHLORODIFLUOROETHANE"
    },
    {
      "code": "29037725",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROETHANES----PENTACHLOROFLUOROETHANE"
    },
    {
      "code": "29037731",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----CHLOROHEPTAFLUOROPROPANE"
    },
    {
      "code": "29037732",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----DICHLOROHEXAFLUOROPROPANE"
    },
    {
      "code": "29037733",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----TRICHLOROPENTAFLUOROPROPANE"
    },
    {
      "code": "29037734",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----TETRACHLOROTETRAFLUOROPROPANE"
    },
    {
      "code": "29037735",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----PENTACHLOROTRIFLUOROPROPANE"
    },
    {
      "code": "29037736",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES-HEXACHLORODIFLUOROPROPANE"
    },
    {
      "code": "29037737",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---CHLOROFLUOROPROPANES----HEPTACHLOROFLUOROPROPANE"
    },
    {
      "code": "29037790",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE---OTHER DERIVATIVES , PERHALOGENATEDONLY WITH FLUORINE AND CHLORINE"
    },
    {
      "code": "290378",
      "description": "other"
    },
    {
      "code": "29037800",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER,PERHALOGENATED DERIVATIVES"
    },
    {
      "code": "290379",
      "description": "other"
    },
    {
      "code": "29037900",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS--OTHER"
    },
    {
      "code": "290381",
      "description": "Halogenated derivatives of cyclanic, cyclenic or cycloterpenic hydrocarbons"
    },
    {
      "code": "29038100",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF CYCLANIC, CYCLENIC OR CYCLOTERPENIC HYDROCARBONS--1,2,3,4,5,6-HEXACHLOROCYCLOHEXANE [HCH (ISO)], INCLUDING LINDANE(ISO,INN)"
    },
    {
      "code": "290382",
      "description": "aldrin (ISO), chlordane (ISO)"
    },
    {
      "code": "29038200",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF CYCLANIC, CYCLENIC OR CYCLOTERPENIC HYDROCARBONS--ALDRIN (ISO),CHLORDANE(ISO)"
    },
    {
      "code": "29038300",
      "description": "Mirex (ISO)"
    },
    {
      "code": "290389",
      "description": "other"
    },
    {
      "code": "29038900",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF CYCLANIC, CYCLENIC OR CYCLOTERPENIC HYDROCARBONS--OTHER"
    },
    {
      "code": "290391",
      "description": "Chlorobenzene, o-dichlorobenzene and p-dichlorobenzene"
    },
    {
      "code": "29039110",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--CHLOROBENZENE,O-DICHLOROBENZENE AND P-DICHLOROBENZENE---CHLOROBENZENE (MONOCHLORO)"
    },
    {
      "code": "29039120",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--CHLORBENZENE,O-DICHLOROBENZENE AND P-DICHLOROBENZENE---O-DICHLOROBENZENE (ORTHODICHLOROBENZENE)"
    },
    {
      "code": "29039130",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--CHLORBENZENE,O-DICHLOROBENZENE AND P-DICHLOROBENZENE---P-DICHLOROBENZENE(PARADICHLOROBENZENE)"
    },
    {
      "code": "290392",
      "description": "Hexachlorobenzene (ISO) and DDT (ISO) (chlofenotane (INN), 1,1,1-trichloro-2,2-bis (p-chlorophenyl) ethane"
    },
    {
      "code": "29039210",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--HEXACHLOROBENZENE (ISO) AND DDT(ISO)[CLOFENOTANE(INN),1,11-TRICHLORO-2,2-BIS (P-CHLOROPHENYL) ETHANE]---HEXACHLOROBENZENE (ISO) AND DDT(ISO)[CLOFENOTANE(INN),1,11-TRICHLORO-2,2-BIS (P-CHLOROPHENYL) ETHANE]"
    },
    {
      "code": "29039221",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--HEXACHLOROBENZENE (ISO) AND DDT(ISO)[CLOFENOTANE(INN),1,11-TRICHLORO-2,2-BIS (P-CHLOROPHENYL) ETHANE----DDT TECHNICAL 75 WDP"
    },
    {
      "code": "29039229",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--HEXACHLOROBENZENE (ISO) AND DDT(ISO)[CLOFENOTANE(INN),1,11-TRICHLORO-2,2-BIS (P-CHLOROPHENYL) ETHANE----OTHER"
    },
    {
      "code": "29039300",
      "description": "Pentachlorobenzene (ISO)"
    },
    {
      "code": "29039400",
      "description": "Hexabromobiphenyls"
    },
    {
      "code": "290399",
      "description": "other"
    },
    {
      "code": "29039910",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS---OTHER---CHLOROFLUOROBENZENE"
    },
    {
      "code": "29039920",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER---BENZALCHLORIDE (BENZYL DICHLORIDE)"
    },
    {
      "code": "29039930",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER---BENZOTRICHLORIDE"
    },
    {
      "code": "29039940",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER----BENZYLCHLORIDE"
    },
    {
      "code": "29039950",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER----PARACHLOROTOLUENE (4-CHLOROMETHYL BENZENE)"
    },
    {
      "code": "29039960",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER----NAPHTHALENE,CHLORINATED"
    },
    {
      "code": "29039970",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER----CHLOROFLUORO ANILINE"
    },
    {
      "code": "29039990",
      "description": "HALOGENATED DERVIATIVES OF HYDROCARBONS-HALOGENATED DERIVATIVES OF AROMATIC HYDROCARBONS--OTHER----OTHER"
    },
    {
      "code": "2904",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED"
    },
    {
      "code": "290410",
      "description": "Derivatives containing only sulpho groups, their salts and ethyl esters"
    },
    {
      "code": "29041010",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ETHYL ESTERS : BENZENE SULPHONIC ACID"
    },
    {
      "code": "29041020",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ETHYL ESTERS : 1,5 NAPTHELENE DISULPHONIC ACID (ARMSTRONGS ACID)"
    },
    {
      "code": "29041030",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ETHYL ESTERS : NAPTHELENE SULPHONIC ACID"
    },
    {
      "code": "29041040",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ETHYL ESTERS : VINYL SULPHONE"
    },
    {
      "code": "29041090",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ETHYL ESTERS : OTHER"
    },
    {
      "code": "290420",
      "description": "Derivatives containing only nitro or only nitroso groups"
    },
    {
      "code": "29042010",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : NITROBENZENE"
    },
    {
      "code": "29042020",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : META DINITROBENZENE"
    },
    {
      "code": "29042030",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : META NITROTOLUENE"
    },
    {
      "code": "29042040",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : ORTHO NITROTOLUENE"
    },
    {
      "code": "29042050",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : PARA NITROTOLUENE"
    },
    {
      "code": "29042060",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : DINITROTOLUENE"
    },
    {
      "code": "29042090",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - DERIVATIVES CONTAINING ONLY NITRO OR ONLY NITROSO GROUPS : OTHER"
    },
    {
      "code": "29043100",
      "description": "Perfluorooctane sulphonic acid"
    },
    {
      "code": "29043200",
      "description": "Ammonium perfluorooctane sulphonate"
    },
    {
      "code": "29043300",
      "description": "Lithium perfluorooctane sulphonate"
    },
    {
      "code": "29043400",
      "description": "Lithium perfluorooctane sulphonate"
    },
    {
      "code": "29043500",
      "description": "Other salts of perfluorooctane sulphonic acid"
    },
    {
      "code": "29043600",
      "description": "Perfluorooctane sulphonyl fluoride"
    },
    {
      "code": "290490",
      "description": "Other"
    },
    {
      "code": "29049010",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : 2,5 DICHLORO NITROBENZENE"
    },
    {
      "code": "29049020",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : DINITROCHLOROBENZENE"
    },
    {
      "code": "29049030",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : META NITROCHLOROBENZENE"
    },
    {
      "code": "29049040",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : ORTHO NITROCHLOROBENZENE"
    },
    {
      "code": "29049050",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : PARA NITROCHLOROBENZENE"
    },
    {
      "code": "29049060",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : 2-NITROCHLOROTOLUENE"
    },
    {
      "code": "29049070",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : SODIUM META NITROBENZENE SULPHONATE"
    },
    {
      "code": "29049080",
      "description": "CHLOROPICRIN(TRICHLORONITRO-METHANE)"
    },
    {
      "code": "29049090",
      "description": "SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF HYDROCARBONS, WHETHER OR NOT HALOGENATED - OTHER : OTHER"
    },
    {
      "code": "29049100",
      "description": "Trichloronitromethane (chloropicrin)"
    },
    {
      "code": "29049910",
      "description": "2, 5 dichloronitrobenzene"
    },
    {
      "code": "29049920",
      "description": "Dinitrochlorebenzene"
    },
    {
      "code": "29049930",
      "description": "Meta nitrochlorobenzene"
    },
    {
      "code": "29049940",
      "description": "Ortho nitrochlorobenzene"
    },
    {
      "code": "29049950",
      "description": "Para nitrochlorobenzene"
    },
    {
      "code": "29049960",
      "description": "2-nitrochlorotoluene"
    },
    {
      "code": "29049970",
      "description": "Sodium meta nitrochlorobenzene sulphonate"
    },
    {
      "code": "29049990",
      "description": "Other"
    },
    {
      "code": "2905",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Saturated monohydric alcohols :"
    },
    {
      "code": "29051100",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : METHANOL (METHYL ALCOHOL)"
    },
    {
      "code": "290512",
      "description": "Propan-1-o1 (propyl alcohol) and propan-2-ol (isopropyl alcohol)"
    },
    {
      "code": "29051210",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-PROPAN-1-O1 (PROPYL ALCOHOL) AND PROPAN- 2-OL (ISOPROPYL ALCOHOL) : PROPYL ALCOHOL"
    },
    {
      "code": "29051220",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-PROPAN-1-O1 (PROPYL ALCOHOL) AND PROPAN- 2-OL (ISOPROPYL ALCOHOL) : ISOPROPYL ALCOHOL"
    },
    {
      "code": "29051300",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : BUTAN-1-OL (N-BUTYL ALCOHOL)"
    },
    {
      "code": "290514",
      "description": "Other butanols"
    },
    {
      "code": "29051410",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-OTHER BUTANOLS : ETHAMBUTOL, ETHAMBUTOL HCL"
    },
    {
      "code": "29051420",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-OTHER BUTANOLS : SALBUTAMOL SULPHATE"
    },
    {
      "code": "29051430",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-OTHER BUTANOLS : AMINO BUTANOL"
    },
    {
      "code": "29051490",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS :-OTHER BUTANOLS : OTHER"
    },
    {
      "code": "29051500",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : PENTANOL (AMYL ALCOHOL) AND ISOMERS THEREOF"
    },
    {
      "code": "290516",
      "description": "Octanol (octyl alcohol) and isomers thereof"
    },
    {
      "code": "29051610",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : OCTANOL (OCTYL ALCOHOL) AND ISOMERS THEREOF : DIMETHYL OCTANOL"
    },
    {
      "code": "29051620",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : OCTANOL (OCTYL ALCOHOL) AND ISOMERS THEREOF : 2-ETHYL HEXANOL"
    },
    {
      "code": "29051690",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : OCTANOL (OCTYL ALCOHOL) AND ISOMERS THEREOF : OTHER"
    },
    {
      "code": "29051700",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : DODECAN-1-OL (LAURYL ALCOHOL),HEXADECAN-1-OL (CETYL ALCOHOL) AND OCTADECAN-1-OL (STEARYL ALCOHOL)"
    },
    {
      "code": "290519",
      "description": "Other"
    },
    {
      "code": "29051900",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : OTHER"
    },
    {
      "code": "29051910",
      "description": "2-BUTANOL, 3, 3-DIMETHYL-"
    },
    {
      "code": "29051990",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - SATURATED MONOHYDRIC ALCOHOLS : OTHER"
    },
    {
      "code": "290522",
      "description": "Acyclic terpene alcohols"
    },
    {
      "code": "29052210",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS :-ACYCLIC TERPENE ALCOHOLS : CITRANELLOL"
    },
    {
      "code": "29052220",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS :-ACYCLIC TERPENE ALCOHOLS : GERANIOL"
    },
    {
      "code": "29052230",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS :-ACYCLIC TERPENE ALCOHOLS : LINALOOL"
    },
    {
      "code": "29052240",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS :-ACYCLIC TERPENE ALCOHOLS : RHODINOL"
    },
    {
      "code": "29052250",
      "description": "Dihydromyrcenol"
    },
    {
      "code": "29052290",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS :-ACYCLIC TERPENE ALCOHOLS : OTHER"
    },
    {
      "code": "29052900",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED MONOHYDRIC ALCOHOLS : OTHER"
    },
    {
      "code": "29053100",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - DIOLS : ETHYLENE GLYCOL (ETHANEDIOL)"
    },
    {
      "code": "29053200",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - DIOLS : PROPYLENE GLYCOL (PROPANE-1,2-DIOL)"
    },
    {
      "code": "290539",
      "description": "Other"
    },
    {
      "code": "29053910",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - DIOLS :-OTHER : 1,4/1,3/2,3-BUTYLENE GLYCOL"
    },
    {
      "code": "29053920",
      "description": "Hexylene glycol"
    },
    {
      "code": "29053990",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - DIOLS :-OTHER : OTHER"
    },
    {
      "code": "29054100",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS : 2- ETHYL-2-(HYDROXYMETHYL) PROPANE-1,3-DIOL (TRIMETHYLOLROPANE)"
    },
    {
      "code": "290542",
      "description": "Pentaerythritol"
    },
    {
      "code": "29054210",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS :-PENTAERYTHRITOL : DIPENTAERYTHRITOL"
    },
    {
      "code": "29054290",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS :-PENTAERYTHRITOL : OTHER"
    },
    {
      "code": "29054300",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS : MANNITOL"
    },
    {
      "code": "29054400",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS : D-GLUCITOL (SORBITOL)"
    },
    {
      "code": "29054500",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS : GLYCEROL"
    },
    {
      "code": "29054900",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER POLYHYDRIC ALCOHOLS : OTHER"
    },
    {
      "code": "29055100",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF ACYCLIC ALCOHOLS : ETHCHLORVYNOL (INN)"
    },
    {
      "code": "29055900",
      "description": "ACYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF ACYCLIC ALCOHOLS : OTHER"
    },
    {
      "code": "2906",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Cyclanic, cyclenic or cycloterpenic :"
    },
    {
      "code": "29061100",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC : MENTHOL"
    },
    {
      "code": "29061200",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC : CYCLOHEXANOL, METHYLCYCLOHEXANOLS AND DIMETHYLCYCLOHEXANOLS"
    },
    {
      "code": "290613",
      "description": "Sterols and inositols"
    },
    {
      "code": "29061310",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC :-STEROLS AND INOSITOLS : CHOLESTEROL"
    },
    {
      "code": "29061390",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC :-STEROLS AND INOSITOLS : OTHER"
    },
    {
      "code": "29061400",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC : TERPINEOLS"
    },
    {
      "code": "290619",
      "description": "Other"
    },
    {
      "code": "29061910",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC :-OTHER : BORNEOL"
    },
    {
      "code": "29061990",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC :-OTHER : OTHER"
    },
    {
      "code": "29062100",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC : BENZYL ALCOHOL"
    },
    {
      "code": "290629",
      "description": "Other"
    },
    {
      "code": "29062910",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC : OTHER : CINNAMIC ALCOHOL"
    },
    {
      "code": "29062920",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC : OTHER : PHENYLETHYL ALCOHOL"
    },
    {
      "code": "29062990",
      "description": "CYCLIC ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC : OTHER : OTHER"
    },
    {
      "code": "2907",
      "description": "PHENOLS; PHENOL-ALCOHOLS"
    },
    {
      "code": "290711",
      "description": "Phenol (hydroxybenzene) and its salts"
    },
    {
      "code": "29071110",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS :-PHENOL (HYDROXYBENZENE) AND ITS SALTS : PHENOL, AS PURE CARBOLIC ACID"
    },
    {
      "code": "29071190",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS :-PHENOL (HYDROXYBENZENE) AND ITS SALTS : OTHER"
    },
    {
      "code": "290712",
      "description": "Cresols and their salts"
    },
    {
      "code": "29071210",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS :-CRESOLS AND THEIR SALTS : PARA CRESOLS (P-CRESOLS)"
    },
    {
      "code": "29071220",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS :-CRESOLS AND THEIR SALTS : CRESYLIC ACID"
    },
    {
      "code": "29071290",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS :-CRESOLS AND THEIR SALTS : OTHER"
    },
    {
      "code": "29071300",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OCTYLPHENOL, NONYLPHENOL AND THEIR ISOMERS; SALTS THEREOF"
    },
    {
      "code": "29071400",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : XYLENOLS AND THEIR SALTS"
    },
    {
      "code": "290715",
      "description": "Naphthols and their salts"
    },
    {
      "code": "29071510",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : NAPHTHOLS AND THEIR SALTS : ALPHA NAPHTHOLS"
    },
    {
      "code": "29071520",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : NAPHTHOLS AND THEIR SALTS : BETA NAPHTHOLS"
    },
    {
      "code": "29071590",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : NAPHTHOLS AND THEIR SALTS : OTHER"
    },
    {
      "code": "290719",
      "description": "Other"
    },
    {
      "code": "29071910",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : O-PHENYL PHENOLS"
    },
    {
      "code": "29071920",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : P-PHENYL PHENOLS"
    },
    {
      "code": "29071930",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : THYMOL"
    },
    {
      "code": "29071940",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : PARA TERTIARY BUTYL PHENOL"
    },
    {
      "code": "29071950",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : ALKYL PHENOLS"
    },
    {
      "code": "29071990",
      "description": "PHENOLS; PHENOL-ALCOHOLS - MONOPHENOLS : OTHER : OTHER"
    },
    {
      "code": "29072100",
      "description": "PHENOLS; PHENOL-ALCOHOLS - POLYPHENOLS ; PHENOL-ALCOHOLS : RESORCINOL AND ITS SALTS"
    },
    {
      "code": "29072200",
      "description": "PHENOLS; PHENOL-ALCOHOLS - POLYPHENOLS ; PHENOL-ALCOHOLS : HYDROQUINONE (QUINOL) AND ITS SALTS"
    },
    {
      "code": "29072300",
      "description": "PHENOLS; PHENOL-ALCOHOLS - POLYPHENOLS ; PHENOL-ALCOHOLS : 4,4 -ISOPROPYLIDENEDIPHENOL (BIS-PHENOL A, DIPHENYLOLPROPANE) AND ITS SALTS"
    },
    {
      "code": "290729",
      "description": "Other"
    },
    {
      "code": "29072910",
      "description": "PHENOLS; PHENOL-ALCOHOLS - POLYPHENOLS ; PHENOL-ALCOHOLS : OTHER : 1,5- DIHYDROXY NAPHTHALENE"
    },
    {
      "code": "29072920",
      "description": "Tris (p-hydroxy phenyl) ethane"
    },
    {
      "code": "29072930",
      "description": "Tertiary butyl hydroquinone"
    },
    {
      "code": "29072990",
      "description": "PHENOLS; PHENOL-ALCOHOLS - POLYPHENOLS ; PHENOL-ALCOHOLS : OTHER : OTHER"
    },
    {
      "code": "2908",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOLALCOHOLS - Derivatives containing only halogen substituents and their salts:"
    },
    {
      "code": "29081000",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY HALOGEN SUBSTITUENTS AND THEIR SALTS"
    },
    {
      "code": "29081100",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY HALOGEN SUBSTITUENTS AND THEIR SALTS -- PENTACHLOROPHENOL (ISO)"
    },
    {
      "code": "29081900",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY HALOGEN SUBSTITUENTS AND THEIR SALTS -- OTHER"
    },
    {
      "code": "29082010",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : PHENOL SULPHONIC ACIDS"
    },
    {
      "code": "29082021",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : G ACIDS (2-NAPHTHOL-6,8-DISULPHONIC ACID)"
    },
    {
      "code": "29082022",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : SALTS OF G ACID"
    },
    {
      "code": "29082023",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : BETA NAPHTHOL SULPHONIC ACIDS"
    },
    {
      "code": "29082024",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : NEVILE -WINTHER ACID (1-NAPHTHOL- 4- SULPHONIC ACID)"
    },
    {
      "code": "29082025",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : SCHAEFFER ACID (2-NAPHTHOL-6-SULPHONIC ACID)"
    },
    {
      "code": "29082026",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : R ACIDS (2-NAPHTHOL- 3,6- DISULPHONIC ACID) AND ITS DISODIUM SALT (SALT OF R ACID)"
    },
    {
      "code": "29082027",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : CHROMOTROPIC ACID (1,8-DIHYDROXYNAPHTHALENE -3,6-DISULFONIC ACID)"
    },
    {
      "code": "29082029",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - DERIVATIVES CONTAINING ONLY SULPHO GROUPS, THEIR SALTS AND ESTERS : NAPHTHOL SULPHONIC ACIDS : OTHER"
    },
    {
      "code": "29089010",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER : PARA NITROPHENOL"
    },
    {
      "code": "29089020",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER : MUSK XYLOL"
    },
    {
      "code": "29089090",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER : OTHER"
    },
    {
      "code": "29089100",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER -- DINOSEB (ISO) AND ITS SALTS"
    },
    {
      "code": "290892",
      "description": "4,6-D initro-o-cresol"
    },
    {
      "code": "29089200",
      "description": "HALOGENATED,SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS-DERIVATIVES CONTAINING ONLY HALOGEN SUBSTITUENTS AND THEIR SALTS--4,6-DINITRO-O-CRESOL [DNOC (ISO)] AND ITS SALTS"
    },
    {
      "code": "290899",
      "description": "Other"
    },
    {
      "code": "29089910",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER -- OTHER --- PARA NITROPHENOL"
    },
    {
      "code": "29089920",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER -- OTHER --- MUSK XYLOL"
    },
    {
      "code": "29089990",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PHENOLS OR PHENOL-ALCOHOLS - OTHER -- OTHER --- OTHER"
    },
    {
      "code": "2909",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL-PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Acyclic ethers and their halogenated, sulphonated, nitrated or nitrosated derivatives :"
    },
    {
      "code": "29091100",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : DIETHYL ETHER"
    },
    {
      "code": "290919",
      "description": "Other"
    },
    {
      "code": "29091900",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29091910",
      "description": "Tertiary amyl methyl ether"
    },
    {
      "code": "29091920",
      "description": "Methyl tertiary butyl ether (MTBE)"
    },
    {
      "code": "29091990",
      "description": "Other"
    },
    {
      "code": "29092000",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES CYCLANIC, CYCLENIC OR CYCLOTERPENIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "290930",
      "description": "Aromatic ethers and their halogenated, sulphonated, nitrated or nitrosated derivatives"
    },
    {
      "code": "29093011",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : ANISOLE AND THEIR DERIVATIVES : 4-CHLORO-2-NITRO ANISOLE"
    },
    {
      "code": "29093012",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : ANISOLE AND THEIR DERIVATIVES : ORTHO NITRO ANISOLE"
    },
    {
      "code": "29093019",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : ANISOLE AND THEIR DERIVATIVES : OTHER"
    },
    {
      "code": "29093020",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : DIPHENYL OXIDE"
    },
    {
      "code": "29093030",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : MUSK AMBRETTE"
    },
    {
      "code": "29093090",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC ETHERS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29094100",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : 2,2-OXYDIETHANOL (DIETHYLENE GLYCOL, DIGOL)"
    },
    {
      "code": "29094200",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : MONOMETHYL ETHERS OF ETHYLENE GLYCOL OR OF DIETHYLENE GLYCOL"
    },
    {
      "code": "29094300",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : MONOBUTYL ETHERS OF ETHYLENE GLYCOL OR OF DIETHYLENE GLYCOL"
    },
    {
      "code": "29094400",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER MONOALKYLETHERS OF ETHYLENE GLYCOL OR OF DIETHYLENE GLYCOL"
    },
    {
      "code": "290949",
      "description": "Other"
    },
    {
      "code": "29094900",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-ALCOHOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29094910",
      "description": "Phenoxy ethanol"
    },
    {
      "code": "29094920",
      "description": "1-(4-phenoxyphenoxy) propan-2-ol"
    },
    {
      "code": "29094930",
      "description": "Meta phenoxy benzyl alcohol (MPBA)"
    },
    {
      "code": "29094990",
      "description": "Other"
    },
    {
      "code": "290950",
      "description": "Ether-phenols, ether-alcohol-phenols and their halogenated, sulphonated, nitrated or nitrosated derivatives"
    },
    {
      "code": "29095010",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-PHENOLS, ETHER-ALCOHOL-PHENOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : GUAIACOL"
    },
    {
      "code": "29095020",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-PHENOLS, ETHER-ALCOHOL-PHENOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : ISOEUGENOL"
    },
    {
      "code": "29095030",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-PHENOLS, ETHER-ALCOHOL-PHENOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : POTASSIUM GUAIACOL SULPHONATE"
    },
    {
      "code": "29095040",
      "description": "4-methoxy phenol (mono methyl ether of hydroquinone)"
    },
    {
      "code": "29095050",
      "description": "Butylated hydorxyanisole (BHA)"
    },
    {
      "code": "29095090",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ETHER-PHENOLS, ETHER-ALCOHOL-PHENOLS AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29096000",
      "description": "ETHERS, ETHER-ALCOHOLS, ETHER-PHENOLS, ETHERALCOHOL- PHENOLS, ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES (WHETHER OR NOT CHEMICALLY DEFINED), AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -ALCOHOL PEROXIDES, ETHER PEROXIDES, KETONE PEROXIDES AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "2910",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "29101000",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -OXIRANE (ETHYLENE OXIDE)"
    },
    {
      "code": "29102000",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -METHYLOXIRANE (PROPYLENE OXIDE)"
    },
    {
      "code": "29103000",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -1-CHLORO-2,3-EXPOXYPROPANE (EPICHLOROHYDRIN)"
    },
    {
      "code": "29104000",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - DIELDRIN (ISO, INN)"
    },
    {
      "code": "29105000",
      "description": "Endrin (ISO)"
    },
    {
      "code": "29109000",
      "description": "EPOXIDES, EPOXYALCOHOLS, EPOXYPHENOLS AND EXPOXYETHERS, WITH A THREE-MEMBERED RING, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -OTHER"
    },
    {
      "code": "2911",
      "description": "ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED,SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "291100",
      "description": "Acetals and hemiacetals, whether or not with other oxygen function, and their halogenated, sulphonated, nitrated or nitrosated derivatives"
    },
    {
      "code": "29110010",
      "description": "ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION"
    },
    {
      "code": "29110090",
      "description": "ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETALS AND HEMIACETALS, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "2912",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGENFUNCTION; CYCLIC POLYMERS OF ALDEHYDES;PARAFORMALDEHYDE- Acyclic aldehydes without other oxygen function :"
    },
    {
      "code": "29121100",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : METHANAL (FORMALDEHYDE)"
    },
    {
      "code": "29121200",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : ETHANAL (ACETALDEHYDE)"
    },
    {
      "code": "29121300",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : BUTANAL (BUTYRALDEHYDE, NORMAL ISOMER)"
    },
    {
      "code": "291219",
      "description": "Other"
    },
    {
      "code": "29121910",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : CROTONALDEHYDE"
    },
    {
      "code": "29121920",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : HEPTALDEHYDE (HEPTANAL)"
    },
    {
      "code": "29121930",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : GLYOXAL"
    },
    {
      "code": "29121990",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ACYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : OTHER"
    },
    {
      "code": "29122100",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - CYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : BENZALDEHYDE"
    },
    {
      "code": "291229",
      "description": "Other"
    },
    {
      "code": "29122910",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - CYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : CINNAMIC ALDEHYDE"
    },
    {
      "code": "29122920",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - CYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : PHENYL ACETALDEHYDE"
    },
    {
      "code": "29122930",
      "description": "Hexyl cinnamic aldehyde"
    },
    {
      "code": "29122990",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - CYCLIC ALDEHYDES WITHOUT OTHER OXYGEN FUNCTION : OTHER : OTHER"
    },
    {
      "code": "29124100",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : VANILLIN (4-HYDROXY-3-METHOXYBENZALDEHYDE)"
    },
    {
      "code": "29124200",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : ETHYLVANILLIN (3-ETHOXY-4-HYDROXY-BENZALDEHYDE)"
    },
    {
      "code": "291249",
      "description": "Other"
    },
    {
      "code": "29124910",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : OTHER :ANISIC ALDEHYDE (ANISALDEHYDE)"
    },
    {
      "code": "29124920",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : OTHER :HELIOTROPIN (PIPERONYL ALDEHYDE)"
    },
    {
      "code": "29124930",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : OTHER :THIACETAZONE"
    },
    {
      "code": "29124940",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION : OTHER :3,4,5-TRIMETHOXY-BENZALDEHYDE"
    },
    {
      "code": "29124991",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION -- OTHER ---OTHER----ALDEHYDE-ALCOHOLS"
    },
    {
      "code": "29124999",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE - ALDEHYDE ETHERS, ALDEHYDE PHENOLS AND ALDEHYDES WITH OTHER OXYGEN FUNCTION -- OTHER ---OTHER----OTHER"
    },
    {
      "code": "29125000",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE -CYCLIC POLYMERS OF ALDEHYDES"
    },
    {
      "code": "29126000",
      "description": "ALDEHYDES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION; CYCLIC POLYMERS OF ALDEHYDES; PARAFORMALDEHYDE -PARAFORMALDEHYDE"
    },
    {
      "code": "2913",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PRODUCTS OF HEADING 2912"
    },
    {
      "code": "291300",
      "description": "Halogenated, sulphonated, nitrated or nitrosated derivatives of products of heading 2912"
    },
    {
      "code": "29130010",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PRODUCTS OF HEADING 2912 2913 00 - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PRODUCTS OF HEADING 2912 : ORTHO-CHLORO-BENZALDEHYDE"
    },
    {
      "code": "29130090",
      "description": "HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PRODUCTS OF HEADING 2912 2913 00 - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES OF PRODUCTS OF HEADING 2912 : OTHER"
    },
    {
      "code": "2914",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Acyclic ketones without other oxygen function :"
    },
    {
      "code": "29141100",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC KETONES WITHOUT OTHER OXYGEN FUNCTION : ACETONE"
    },
    {
      "code": "29141200",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC KETONES WITHOUT OTHER OXYGEN FUNCTION : BUTANONE (METHYL ETHYL KETONE)"
    },
    {
      "code": "29141300",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC KETONES WITHOUT OTHER OXYGEN FUNCTION : 4-METHYLPENTAN-2-ONE (METHYL ISOBUTYL KETONE)"
    },
    {
      "code": "291419",
      "description": "Other"
    },
    {
      "code": "29141910",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC KETONES WITHOUT OTHER OXYGEN FUNCTION :-OTHER : ISOPHORON"
    },
    {
      "code": "29141990",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC KETONES WITHOUT OTHER OXYGEN FUNCTION :-OTHER : OTHER"
    },
    {
      "code": "291421",
      "description": "Camphor"
    },
    {
      "code": "29142200",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION : CYCLOHEXANONE AND METHYL-CYCLOHEXANONES"
    },
    {
      "code": "291423",
      "description": "Ionones and methylionones"
    },
    {
      "code": "29142310",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION :-IONONES AND METHYLIONONES : BETA-IONONE"
    },
    {
      "code": "29142320",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION :-IONONES AND METHYLIONONES : PSEUDO IONONE"
    },
    {
      "code": "29142390",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION :-IONONES AND METHYLIONONES : OTHER"
    },
    {
      "code": "291429",
      "description": "Other"
    },
    {
      "code": "29142910",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : L-CARAVONE"
    },
    {
      "code": "29142921",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED,SULPHONATED, NITRATED OR NITROSATED DERIVATIVES--OTHER---CAMPHOR----NATURAL"
    },
    {
      "code": "29142922",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED,SULPHONATED, NITRATED OR NITROSATED DERIVATIVES--OTHER---CAMPHOR----SYNTHETIC"
    },
    {
      "code": "29142930",
      "description": "Pentyl-2-cyclopenten - 1 -one"
    },
    {
      "code": "29142940",
      "description": "Cyclohexane dione"
    },
    {
      "code": "29142950",
      "description": "7-acetyl, 1,2,3,4,5,6,7,8-octahydro,1,6.7 -tetra methyl Napthalene/ 1-(2,3,8,8-tetramethyl - 1,2,3,4,5,6,7,8-octahydronaphthalene-2-yl) ethanone"
    },
    {
      "code": "29142990",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : OTHER"
    },
    {
      "code": "29143100",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : PHENYLACETONE (PHENYLPROPAN-2-ONE)"
    },
    {
      "code": "291439",
      "description": "Other"
    },
    {
      "code": "29143910",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : ACETO PHENONE"
    },
    {
      "code": "29143920",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : BENZANTHRONE"
    },
    {
      "code": "29143930",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : BENZOPHENONE"
    },
    {
      "code": "29143940",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : DIBENZANTHRONE (VIOLANTHRONE)"
    },
    {
      "code": "29143990",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC KETONES WITHOUT OTHER OXYGEN FUNCTION : OTHER : OTHER"
    },
    {
      "code": "29144000",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES KETONE-ALCOHOLS AND KETONE-ALDEHYDES"
    },
    {
      "code": "29145000",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES KETONE-PHENOLS AND KETONES WITH OTHER OXYGEN FUNCTION"
    },
    {
      "code": "29146100",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - QUINONES : ANTHRAQUINONE"
    },
    {
      "code": "29146200",
      "description": "Coenzyme Q10 (ubidecarenone (INN))"
    },
    {
      "code": "291469",
      "description": "Other"
    },
    {
      "code": "29146910",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - QUINONES :-OTHER : 1,4- DIHYDROXY ANTHRAQUINONE (QUINIZARIN)"
    },
    {
      "code": "29146920",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - QUINONES :-OTHER : METHYL ANTHRAQUINONE"
    },
    {
      "code": "29146990",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - QUINONES :-OTHER : OTHER"
    },
    {
      "code": "291470",
      "description": "Halogenated, sulphonated, nitrated or nitrosated derivatives"
    },
    {
      "code": "29147010",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : 1-CHLORO ANTHRA QUINONE"
    },
    {
      "code": "29147020",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : MUSK KETONE"
    },
    {
      "code": "29147090",
      "description": "KETONES AND QUINONES, WHETHER OR NOT WITH OTHER OXYGEN FUNCTION, AND THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29147100",
      "description": "Chlordecone (ISO)"
    },
    {
      "code": "291479",
      "description": "Other"
    },
    {
      "code": "29147910",
      "description": "1-chloro anthraquinone"
    },
    {
      "code": "29147920",
      "description": "Musk ketone"
    },
    {
      "code": "29147930",
      "description": "Tri fluro methyl acetophenone"
    },
    {
      "code": "29147940",
      "description": "Chloro-4-(4-chloro phenoxy) acetophenone"
    },
    {
      "code": "29147950",
      "description": "Dichloroacetophenone"
    },
    {
      "code": "29147990",
      "description": "Other"
    },
    {
      "code": "2915",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Formic acid, its salts and esters:"
    },
    {
      "code": "29151100",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - FORMIC ACID, ITS SALTS AND ESTERS : FORMIC ACID"
    },
    {
      "code": "291512",
      "description": "Salts of formic acid"
    },
    {
      "code": "29151210",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - FORMIC ACID, ITS SALTS AND ESTERS : SALTS OF FORMIC ACID : SODIUM FORMATE"
    },
    {
      "code": "29151290",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - FORMIC ACID, ITS SALTS AND ESTERS : SALTS OF FORMIC ACID : OTHER"
    },
    {
      "code": "29151300",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - FORMIC ACID, ITS SALTS AND ESTERS : ESTERS OF FORMIC ACID"
    },
    {
      "code": "29152100",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE : ACETIC ACID"
    },
    {
      "code": "29152200",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE : SODIUM ACETATE"
    },
    {
      "code": "29152300",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE : COBALT ACETATES"
    },
    {
      "code": "29152400",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE : ACETIC ANHYDRIDE"
    },
    {
      "code": "291529",
      "description": "Other"
    },
    {
      "code": "29152910",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE :-OTHER : CALCIUM ACETATE"
    },
    {
      "code": "29152920",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE :-OTHER : MAGNESIUM ACETATE"
    },
    {
      "code": "29152930",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE :-OTHER : MANGANESE ACETATE"
    },
    {
      "code": "29152990",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACETIC ACID AND ITS SALTS; ACETIC ANHYDRIDE :-OTHER : OTHER"
    },
    {
      "code": "29153100",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : ETHYL ACETATE"
    },
    {
      "code": "29153200",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : VINYL ACETATE"
    },
    {
      "code": "29153300",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : N-BUTYL ACETATE"
    },
    {
      "code": "29153400",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : ISOBUTYL ACETATE"
    },
    {
      "code": "29153500",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : 2-ETHOXYETHYL ACETATE"
    },
    {
      "code": "29153600",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID -- DINOSEB (ISO) ACETATE"
    },
    {
      "code": "291539",
      "description": "Other"
    },
    {
      "code": "29153910",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : BENZYL ACETATE"
    },
    {
      "code": "29153920",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : BORNYL ACETATE AND ISO BORNYL ACETATE"
    },
    {
      "code": "29153930",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : LINALYL ACETATE"
    },
    {
      "code": "29153940",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : METHYL ACETATE"
    },
    {
      "code": "29153950",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : PHENYL PROPYL ACETATE"
    },
    {
      "code": "29153960",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : TERPINYL ACETATE"
    },
    {
      "code": "29153970",
      "description": "Ortho tertiary butyl cyclohexyl acetate"
    },
    {
      "code": "29153980",
      "description": "Para tertiary butyl cyclohexyl acetate"
    },
    {
      "code": "29153990",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ESTERS OF ACETIC ACID : OTHER : OTHER"
    },
    {
      "code": "29153991",
      "description": "Methyl cyclohexyl acetate"
    },
    {
      "code": "29153992",
      "description": "Ethelene glycol mono ethyl ether acetate"
    },
    {
      "code": "29153999",
      "description": "Other"
    },
    {
      "code": "291540",
      "description": "Mono-, di- or trichloroacetic acids, their salts and esters"
    },
    {
      "code": "29154010",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - MONO-, DI- OR TRICHLOROACETIC ACIDS, THEIR SALTS AND ESTERS : MONOCHLOROACETIC ACID, THEIR SALTS AND ESTERS"
    },
    {
      "code": "29154020",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - MONO-, DI- OR TRICHLOROACETIC ACIDS, THEIR SALTS AND ESTERS : DICHLOROACETIC ACID, THEIR SALTS AND ESTERS"
    },
    {
      "code": "29154030",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - MONO-, DI- OR TRICHLOROACETIC ACIDS, THEIR SALTS AND ESTERS : TRICHLOROACETIC ACID, THEIR SALTS AND ESTERS"
    },
    {
      "code": "29155000",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PROPIONIC ACID, ITS SALTS AND ESTERS"
    },
    {
      "code": "291560",
      "description": "Butanoic acids, pentanoic acids, their salts and esters"
    },
    {
      "code": "29156010",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - BUTANOIC ACIDS, PENTANOIC ACIDS, THEIR SALTS AND ESTERS : BUTANOIC ACIDS, THEIR SALTS AND ESTERS"
    },
    {
      "code": "29156020",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - BUTANOIC ACIDS, PENTANOIC ACIDS, THEIR SALTS AND ESTERS : PENTANOIC ACIDS, THEIR SALTS AND ESTERS"
    },
    {
      "code": "291570",
      "description": "Palmitic acid, stearic acid, their salts and esters"
    },
    {
      "code": "29157010",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : PALMITIC ACID"
    },
    {
      "code": "29157020",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : STEARIC ACID"
    },
    {
      "code": "29157030",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : GLYCEROL MONOSTEARATE"
    },
    {
      "code": "29157040",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : H.C.O.FATTY ACID (INCLUDING 12-HYDROXY STEARIC ACID)"
    },
    {
      "code": "29157050",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : D.C.O. FATTY ACID"
    },
    {
      "code": "29157090",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PALMITIC ACID, STEARIC ACID, THEIR SALTS AND ESTERS : OTHER"
    },
    {
      "code": "291590",
      "description": "Other"
    },
    {
      "code": "29159010",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : ACETYL CHLORIDE"
    },
    {
      "code": "29159020",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : OCTOIC ACID (CAPRYLIC ACID)"
    },
    {
      "code": "29159030",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : HEXOIC ACID (CAPROIC ACID)"
    },
    {
      "code": "29159040",
      "description": "Pivoloyl chloride"
    },
    {
      "code": "29159050",
      "description": "N-valeryl chloride"
    },
    {
      "code": "29159060",
      "description": "N-Octanoyl chloride"
    },
    {
      "code": "29159070",
      "description": "Neodecanoyl chloride"
    },
    {
      "code": "29159090",
      "description": "SATURATED ACYCLIC MONOCARBOXYLIC ACIDS AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : OTHER"
    },
    {
      "code": "29159091",
      "description": "Hexoic acid (caproic acid)"
    },
    {
      "code": "29159092",
      "description": "Octoic acid (caprylic acid)"
    },
    {
      "code": "29159093",
      "description": "Trifluoro acetic acid"
    },
    {
      "code": "29159094",
      "description": "Ethyl difluoro acetate"
    },
    {
      "code": "29159095",
      "description": "Ethyl trifluoro acetate"
    },
    {
      "code": "29159099",
      "description": "Other"
    },
    {
      "code": "2916",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Unsaturated acyclic monocarboxylic acids, their anhydrides, halides, peroxides, peroxyacids and their derivatives:"
    },
    {
      "code": "29161100",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ACRYLIC ACID AND ITS SALTS"
    },
    {
      "code": "291612",
      "description": "Esters of acrylic acid"
    },
    {
      "code": "29161210",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ESTERS OF ACRYLIC ACID : BUTYL ACRYLATE"
    },
    {
      "code": "29161290",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ESTERS OF ACRYLIC ACID : OTHER"
    },
    {
      "code": "291613",
      "description": "Methacrylic acid and its salts"
    },
    {
      "code": "29161310",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : METHACRYLIC ACID AND ITS SALTS : METHACRYLIC ACID"
    },
    {
      "code": "29161320",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : METHACRYLIC ACID AND ITS SALTS : SALTS OF METHACRYLIC ACID"
    },
    {
      "code": "29161400",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ESTERS OF METHACRYLIC ACID"
    },
    {
      "code": "291615",
      "description": "Oleic, linoleic or linolenic acids, their salts and esters"
    },
    {
      "code": "29161510",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OLEIC, LINOLEIC OR LINOLENIC ACIDS, THEIR SALTS AND ESTERS : OLEIC ACID"
    },
    {
      "code": "29161590",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OLEIC, LINOLEIC OR LINOLENIC ACIDS, THEIR SALTS AND ESTERS : OTHER"
    },
    {
      "code": "291616",
      "description": "Binapacryl (ISO)"
    },
    {
      "code": "29161600",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS,CYCLIC MONCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS;THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES-UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES--BINAPACRYL(ISO)"
    },
    {
      "code": "291619",
      "description": "Other"
    },
    {
      "code": "29161910",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : UNDECYLENIC ACID"
    },
    {
      "code": "29161920",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : BISMUTH COMPOUNDS OF UNSATURATED ACYCLIC MONOACIDS"
    },
    {
      "code": "29161930",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : POTASSIUM COMPOUNDS OF UNSATURATED ACYCLIC MONOACIDS"
    },
    {
      "code": "29161940",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : SODIUM COMPOUNDS OF UNSATURATED ACYCLIC MONOACIDS"
    },
    {
      "code": "29161950",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : ESTERS OF UNSATURATED ACYCLIC MONOACIDS NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "29161960",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : SORBIC ACID"
    },
    {
      "code": "29161970",
      "description": "Erucic acid"
    },
    {
      "code": "29161990",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : OTHER"
    },
    {
      "code": "291620",
      "description": "Cyclanic, cyclenic or cycloterpenic, mono carboxylic acids, their anhydrides, halides, peroxides, peroxyacids and their derivatives"
    },
    {
      "code": "29162000",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CYCLANIC, CYCLENIC OR CYCLOTERPENIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES"
    },
    {
      "code": "29162010",
      "description": "D.V acid chloride/cypermethric acid chloride"
    },
    {
      "code": "29162090",
      "description": "Other"
    },
    {
      "code": "291631",
      "description": "Benzoic acid, its salts and esters"
    },
    {
      "code": "29163110",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : BENZOIC ACID"
    },
    {
      "code": "29163120",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : BENZYL BENZOATE"
    },
    {
      "code": "29163130",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : METHYL BENZOATE"
    },
    {
      "code": "29163140",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : SODIUM BENZOATE"
    },
    {
      "code": "29163150",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : BENZOCAINE (ETHYLPARA-AMINO BENZOATE)"
    },
    {
      "code": "29163160",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : ORTHOCHLORO BENZOIC ACID"
    },
    {
      "code": "29163190",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-BENZOIC ACID, ITS SALTS AND ESTERS : OTHER"
    },
    {
      "code": "29163200",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : BENZOYL PEROXIDE AND BENZOYL CHLORIDE"
    },
    {
      "code": "29163400",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : PHENYLACETIC ACID AND ITS SALTS"
    },
    {
      "code": "291639",
      "description": "Other"
    },
    {
      "code": "29163910",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : CINNAMIC ACID"
    },
    {
      "code": "29163920",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : BISMUTH COMPOUNDS OF AROMATIC MONOACIDS"
    },
    {
      "code": "29163930",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : POTASSIUM COMPOUNDS OF AROMATIC MONOACIDS"
    },
    {
      "code": "29163940",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : SODIUM COMPOUNDS OF AROMATIC MONOACIDS"
    },
    {
      "code": "29163950",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : ESTERS OF AROMATIC MONOACIDS NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "29163960",
      "description": "Dichlorophenyl acetyl chloride"
    },
    {
      "code": "29163990",
      "description": "UNSATURATED ACYCLIC MONOCARBOXYLIC ACIDS, CYCLIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC MONOCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : OTHER"
    },
    {
      "code": "2917",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Acyclic polycarboxylic acids, their anhydrides, halides, peroxides, peroxyacids and their derivatives :"
    },
    {
      "code": "291711",
      "description": "Oxalic acid, its salts and esters"
    },
    {
      "code": "29171110",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OXALIC ACID, ITS SALTS AND ESTERS : OXALIC ACID"
    },
    {
      "code": "29171120",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OXALIC ACID, ITS SALTS AND ESTERS : CALCIUM OXALATE"
    },
    {
      "code": "29171130",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OXALIC ACID, ITS SALTS AND ESTERS : STRONTIUM OXALATE"
    },
    {
      "code": "29171140",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OXALIC ACID, ITS SALTS AND ESTERS : DIETHYL OXALATE"
    },
    {
      "code": "29171190",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OXALIC ACID, ITS SALTS AND ESTERS : OTHER"
    },
    {
      "code": "29171200",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ADIPIC ACID, ITS SALTS AND ESTERS"
    },
    {
      "code": "291713",
      "description": "Azelaic acid, sebacicacid their salts and esters"
    },
    {
      "code": "29171300",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : AZELAIC ACID, SEBACIC ACID, THEIR SALTS AND ESTERS"
    },
    {
      "code": "29171310",
      "description": "Sebacic acid"
    },
    {
      "code": "29171390",
      "description": "Other"
    },
    {
      "code": "29171400",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : MALEIC ANHYDRIDE"
    },
    {
      "code": "291719",
      "description": "Other"
    },
    {
      "code": "29171910",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : MALEIC ACID"
    },
    {
      "code": "29171920",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : MALONIC ACID"
    },
    {
      "code": "29171930",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : SUCCINIC ACID"
    },
    {
      "code": "29171940",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : FERROUS FUMARATE"
    },
    {
      "code": "29171950",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : FUMARIC ACID"
    },
    {
      "code": "29171960",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : ITACONIC ACID"
    },
    {
      "code": "29171970",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : ETHOXY METHYLENE MALONATE, DIETHYL MALONATE"
    },
    {
      "code": "29171990",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - ACYCLIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-OTHER : OTHER"
    },
    {
      "code": "29172000",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES -CYCLANIC, CYCLENIC OR CYCLOTERPENIC, POLYCAROXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES"
    },
    {
      "code": "29173100",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :DIBUTYL ORTHOPHTHALATES"
    },
    {
      "code": "29173200",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :DIOCTYL ORTHOPHTHALATES"
    },
    {
      "code": "29173300",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : DINONYL OR DIDECYL ORTHOPHTHALATES"
    },
    {
      "code": "29173400",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :OTHER ESTERS OF ORTHOPHTHALIC ACID"
    },
    {
      "code": "29173500",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :PHTHALIC ANHYDRIDE"
    },
    {
      "code": "29173600",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : TEREPHTHALIC ACID AND ITS SALTS"
    },
    {
      "code": "29173700",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :DIMETHYL TEREPHTHALATE"
    },
    {
      "code": "291739",
      "description": "Other"
    },
    {
      "code": "29173910",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER :DIBUTYL PHTHALATE"
    },
    {
      "code": "29173920",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : DIOCTYL PHTHALATE"
    },
    {
      "code": "29173930",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : PHTHALIC ACID"
    },
    {
      "code": "29173940",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : DIMETHYL PHTHALATE"
    },
    {
      "code": "29173950",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : TRIMELLITIC ANHYDRIDE"
    },
    {
      "code": "29173960",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : ISOPHTHALIC ACID"
    },
    {
      "code": "29173990",
      "description": "POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - AROMATIC POLYCARBOXYLIC ACIDS, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : OTHER"
    },
    {
      "code": "2918",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Carboxylic acids with alcohol function, but without other oxygen function, their anhydrides, halides, peroxides, peroxyacids and their derivatives:"
    },
    {
      "code": "291811",
      "description": "Lactic acid, its salts and esters"
    },
    {
      "code": "29181110",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-LACTIC ACID, ITS SALTS AND ESTERS : LACTIC ACID"
    },
    {
      "code": "29181120",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-LACTIC ACID, ITS SALTS AND ESTERS : CALCIUM LACTATE"
    },
    {
      "code": "29181190",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-LACTIC ACID, ITS SALTS AND ESTERS : OTHER"
    },
    {
      "code": "29181200",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : TARTARIC ACID"
    },
    {
      "code": "291813",
      "description": "Salts and esters of tartaric acid"
    },
    {
      "code": "29181310",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF TARTARIC ACID : POTASSIUM BITARTRATE"
    },
    {
      "code": "29181320",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF TARTARIC ACID : METROPROLOL TARTRATE"
    },
    {
      "code": "29181390",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF TARTARIC ACID : OTHER"
    },
    {
      "code": "29181400",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : CITRIC ACID"
    },
    {
      "code": "291815",
      "description": "Salts and esters of citric acid"
    },
    {
      "code": "29181510",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : POTASSIUM CITRATE"
    },
    {
      "code": "29181520",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : SODIUM CITRATE"
    },
    {
      "code": "29181530",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : BISMUTH CITRATE"
    },
    {
      "code": "29181540",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : DISODIUM HYDROGEN CITRATE"
    },
    {
      "code": "29181550",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : FERRIC AMMONIUM CITRATE"
    },
    {
      "code": "29181590",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-SALTS AND ESTERS OF CITRIC ACID : OTHER"
    },
    {
      "code": "291816",
      "description": "Gluconic acid, its salts and esters"
    },
    {
      "code": "29181610",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-GLUCONIC ACID, ITS SALTS AND ESTERS : CALCIUM GLUCONATE"
    },
    {
      "code": "29181620",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-GLUCONIC ACID, ITS SALTS AND ESTERS : FERROUS GLUCONATE"
    },
    {
      "code": "29181690",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES :-GLUCONIC ACID, ITS SALTS AND ESTERS : OTHER"
    },
    {
      "code": "29181700",
      "description": "2, 2-Diphenyl-2-hydroxyacetic acid (benzilic acid)"
    },
    {
      "code": "29181800",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES -- CHLOROBENZILATE (ISO)"
    },
    {
      "code": "291819",
      "description": "Other;"
    },
    {
      "code": "29181900",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALCOHOL FUNCTION, BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER"
    },
    {
      "code": "29181910",
      "description": "BENZENEACETIC ACID, ALPHA-HYDROXY-ALPHA-PHENYL-"
    },
    {
      "code": "29181920",
      "description": "Cholic acid"
    },
    {
      "code": "29181930",
      "description": "Ricinoleic acid"
    },
    {
      "code": "29181990",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES: OTHER"
    },
    {
      "code": "291821",
      "description": "Salicylic acid and its salts"
    },
    {
      "code": "29182110",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : SALICYLIC ACID AND ITS SALTS : SALICYLIC ACID"
    },
    {
      "code": "29182120",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : SALICYLIC ACID AND ITS SALTS : SODIUM SALICYLATE"
    },
    {
      "code": "29182190",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : SALICYLIC ACID AND ITS SALTS : OTHER"
    },
    {
      "code": "29182200",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : O-ACETYLSALICYLIC ACID, ITS SALTS AND ESTERS"
    },
    {
      "code": "291823",
      "description": "Other esters of salicylic acid and their salts"
    },
    {
      "code": "29182310",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER ESTERS OF SALICYLIC ACID AND THEIR SALTS : METHYL SALICYLATE"
    },
    {
      "code": "29182320",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER ESTERS OF SALICYLIC ACID AND THEIR SALTS : AMINO SALICYLATE"
    },
    {
      "code": "29182330",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER ESTERS OF SALICYLIC ACID AND THEIR SALTS : SALICYLAMIDE"
    },
    {
      "code": "29182340",
      "description": "Benzyl salicylate"
    },
    {
      "code": "29182390",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER ESTERS OF SALICYLIC ACID AND THEIR SALTS : OTHER"
    },
    {
      "code": "291829",
      "description": "Other"
    },
    {
      "code": "29182910",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : GALLIC ACID"
    },
    {
      "code": "29182920",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : BETA HYDROXY NAPTHOIC ACID"
    },
    {
      "code": "29182930",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : PROPYL GALLATE"
    },
    {
      "code": "29182990",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH PHENOL FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER : OTHER"
    },
    {
      "code": "291830",
      "description": "Carboxylic acids with aldehyde or ketone function but without other oxygen function, their anhydrides, halides, peroxides, peroxyacids and their derivatives"
    },
    {
      "code": "29183010",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALDEHYDE OR KETONE FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : LEVULINIC ACID"
    },
    {
      "code": "29183020",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALDEHYDE OR KETONE FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : ETHYL ACETO ACETATE (ACETOACETIC ESTER)"
    },
    {
      "code": "29183030",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALDEHYDE OR KETONE FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : NALIDIXIC ACID"
    },
    {
      "code": "29183040",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALDEHYDE OR KETONE FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : METHYL ACETO ACETATE"
    },
    {
      "code": "29183050",
      "description": "Fluoro benzoyl butyric acid"
    },
    {
      "code": "29183090",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - CARBOXYLIC ACIDS WITH ALDEHYDE OR KETONE FUNCTION BUT WITHOUT OTHER OXYGEN FUNCTION, THEIR ANHYDRIDES, HALIDES, PEROXIDES, PEROXYACIDS AND THEIR DERIVATIVES : OTHER"
    },
    {
      "code": "29189000",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER"
    },
    {
      "code": "29189100",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- 2, 4, 5-T (ISO) (2, 4, 5-TRICHLOROPHENOXYACETIC ACID) ITS SALTS AND  ESTERS"
    },
    {
      "code": "291899",
      "description": "Other"
    },
    {
      "code": "29189900",
      "description": "CARBOXYLIC ACIDS WITH ADDITIONAL OXYGEN FUNCTION AND THEIR ANHYDRIDES, HALIDES, PEROXIDES AND PEROXYACIDS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- OTHER"
    },
    {
      "code": "29189910",
      "description": "Sodium phenoxy acetate"
    },
    {
      "code": "29189920",
      "description": "Methyl (E)-2-(2-chloro methyl) phenyl)-3-methoxyacrylate"
    },
    {
      "code": "29189990",
      "description": "Other"
    },
    {
      "code": "2919",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATE; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "29190010",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : GLYCEROPHOSPHORIC ACID"
    },
    {
      "code": "29190020",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : CALCIUM GLYCEROPHOSPHATE"
    },
    {
      "code": "29190030",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : IRON GLYCEROPHOSPHATE"
    },
    {
      "code": "29190040",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : SODIUM GLYCEROPHOSPHATE"
    },
    {
      "code": "29190050",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : TRICRESYL PHOSPHATE"
    },
    {
      "code": "29190090",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTOPHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO-PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES : OTHER"
    },
    {
      "code": "29191000",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - TRIS (2,3-DIBROMOPROPYL) PHOSPHATE"
    },
    {
      "code": "291990",
      "description": "Other"
    },
    {
      "code": "29199010",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- GLYCEROPHOSPHORIC ACID"
    },
    {
      "code": "29199020",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- CALCIUM GLYCEROPHOSPHATE"
    },
    {
      "code": "29199030",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- IRON GLYCEROPHOSPHATE"
    },
    {
      "code": "29199040",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- SODIUM GLYCEROPHOSPHATE"
    },
    {
      "code": "29199050",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- TRICRESYL PHOSPHATE"
    },
    {
      "code": "29199090",
      "description": "PHOSPHORIC ESTERS AND THEIR SALTS, INCLUDING LACTO PHOSPHATES; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER -- OTHER"
    },
    {
      "code": "2920",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN ALLIEDS AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - Thiophosphoric esters (phosphorothioates) and their salts; their halogenated, sulphonated, nitrated or nitrosated derivatives :"
    },
    {
      "code": "29201000",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - THIOPHOSPHORIC ESTERS (PHOSPHOROTHIOATES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "29201010",
      "description": "PHOSPHOROTHIOIC ACID, S [2-DIETHYLAMINO)ETHYL] O,O-DIETHYL E"
    },
    {
      "code": "29201020",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "29201100",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN ALLIEDS) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - THIOPHOSPHORIC ESTERS (PHOSPHOROTHIOATES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES: -- PARATHION (ISO) AND PARATHION-METHYL (ISO) (METHYLPARATHION)"
    },
    {
      "code": "292019",
      "description": "Other"
    },
    {
      "code": "29201910",
      "description": "Phosphorothioic acid, S[2 -(diethyl amino) ethyl] O, O-Diethyl ester"
    },
    {
      "code": "29201920",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN ALLIEDS) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - THIOPHOSPHORIC ESTERS (PHOSPHOROTHIOATES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES: -- OTHER --- THIOPHOSPHORIC ESTER (PHOSPHOROTHIOATES) AND THEIR SALTS, THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES"
    },
    {
      "code": "29201990",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN ALLIEDS) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - THIOPHOSPHORIC ESTERS (PHOSPHOROTHIOATES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES: -- OTHER --- OTHER"
    },
    {
      "code": "29202100",
      "description": "Dimethyl phosphite"
    },
    {
      "code": "29202200",
      "description": "Diethyl phosphite"
    },
    {
      "code": "29202300",
      "description": "Trimethyl phosphite"
    },
    {
      "code": "29202400",
      "description": "Triethyl phosphite"
    },
    {
      "code": "29202910",
      "description": "Dimethyl sulphate"
    },
    {
      "code": "29202920",
      "description": "Diethyl sulphate"
    },
    {
      "code": "29202930",
      "description": "Tris (2, 3 Dibromopropyl) phosphate"
    },
    {
      "code": "29202990",
      "description": "Other"
    },
    {
      "code": "29203000",
      "description": "Endosulfan (ISO)"
    },
    {
      "code": "292090",
      "description": "Other"
    },
    {
      "code": "29209000",
      "description": "Other"
    },
    {
      "code": "29209010",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : DIETHYL SULPHATE"
    },
    {
      "code": "29209020",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : DIMETHYL SULPHATE"
    },
    {
      "code": "29209030",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : TRIS (2,3 DI-BROMOPROPYL) PHOSPHATE"
    },
    {
      "code": "29209041",
      "description": "TRIMETHYL PHOSPHITE"
    },
    {
      "code": "29209042",
      "description": "TRIETHYL PHOSPHITE"
    },
    {
      "code": "29209043",
      "description": "DIMETHYL PHOSPHITE"
    },
    {
      "code": "29209044",
      "description": "DIETHYL PHOSPHITE"
    },
    {
      "code": "29209090",
      "description": "ESTERS OF OTHER INORGANIC ACIDS OF NON-METALS (EXCLUDING ESTERS OF HYDROGEN HALIDES) AND THEIR SALTS; THEIR HALOGENATED, SULPHONATED, NITRATED OR NITROSATED DERIVATIVES - OTHER : OTHER"
    },
    {
      "code": "29209099",
      "description": "OTHER"
    },
    {
      "code": "2921",
      "description": "AMINE- FUNCTION COMPOUNDS - Acyclic monoamines and their derivatives; salts thereof :"
    },
    {
      "code": "292111",
      "description": "Methylamine, di- or trimethylamine and their salts"
    },
    {
      "code": "29211110",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-METHYLAMINE, DI- OR TRIMETHYLAMINE AND THEIR SALTS : DIMETHYL FORMIDE"
    },
    {
      "code": "29211190",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-METHYLAMINE, DI- OR TRIMETHYLAMINE AND THEIR SALTS : OTHER"
    },
    {
      "code": "29211200",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : DIETHYLAMINE AND ITS SALTS"
    },
    {
      "code": "29211300",
      "description": "2-(N, N-Diethylamino)ethylchloride hydrochloride"
    },
    {
      "code": "29211400",
      "description": "2-(N, N-Diisopropylamino)ethylchloride hydrochloride"
    },
    {
      "code": "292119",
      "description": "Other"
    },
    {
      "code": "29211900",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29211910",
      "description": "2-Chloro N, N-Diisopropyl ethylamine"
    },
    {
      "code": "29211911",
      "description": "2-CHLORO N, N-DI-ISOPROPYL ETHYLAMINE"
    },
    {
      "code": "29211912",
      "description": "2-CHLORO N,N-DI-ISOPROPYL ETHYLAMINE AND ETHANAMINE,2-CHLORO-N,N-DIMETHYL N,N-DIETHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE, DI-METHYL AMINO ETHYLCHLORIDE HYDROCHLORIDE - - - -N,N-DIETHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE"
    },
    {
      "code": "29211913",
      "description": "2-CHLORO N,N-DI-ISOPROPYL ETHYLAMINE AND ETHANAMINE,2-CHLORO-N,N-DIMETHYL N,N-DIETHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE, DI-METHYL AMINO ETHYLCHLORIDE HYDROCHLORIDE - - - - DI-METHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE"
    },
    {
      "code": "29211914",
      "description": "ETHANAMINE, 2-CHLORO-N, N-DIMETHYL"
    },
    {
      "code": "29211920",
      "description": "2-Chloro N, N-Dimethyl ethanamine"
    },
    {
      "code": "29211990",
      "description": "OTHER"
    },
    {
      "code": "29212100",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF : ETHYLENEDIAMINE AND ITS SALTS"
    },
    {
      "code": "29212200",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF : HEXAMETHYLENEDIAMINE AND ITS SALTS"
    },
    {
      "code": "292129",
      "description": "Other"
    },
    {
      "code": "29212910",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : HEXAMETHYLENE TETRAMINE (HEXAMINE) NOT PUT UP AS FUEL OR MEDICAMENT"
    },
    {
      "code": "29212920",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : TRIMETHYLENE TRINIFRAMINE"
    },
    {
      "code": "29212940",
      "description": "Tetradecene"
    },
    {
      "code": "29212990",
      "description": "AMINE- FUNCTION COMPOUNDS - ACYCLIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : OTHER"
    },
    {
      "code": "292130",
      "description": "Cyclanic, cyclenic or cycloterpenic mono or polyamines, and their derivatives; salts thereof"
    },
    {
      "code": "29213010",
      "description": "AMINE- FUNCTION COMPOUNDS - -CYCLANIC, CYCLENIC OR CYCLOTERPENIC MONO OR POLYAMINES, AND THEIR DERIVATIVES; SALTS THEREOF : CYCLOHEXYLAMINE"
    },
    {
      "code": "29213090",
      "description": "AMINE- FUNCTION COMPOUNDS - CYCLANIC, CYCLENIC OR CYCLOTERPENIC MONO OR POLYAMINES, AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "292141",
      "description": "Aniline and its salts"
    },
    {
      "code": "29214110",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE AND ITS SALTS : ANILINE"
    },
    {
      "code": "29214120",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE AND ITS SALTS : ANILINE HYDROCHLORIDE"
    },
    {
      "code": "29214190",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE AND ITS SALTS : OTHER"
    },
    {
      "code": "292142",
      "description": "Aniline derivatives and their salts"
    },
    {
      "code": "29214211",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : PARA CHLOROANILINE, ORTHO CHLORO PARANITROANILINE, DICHLOROANILINE, 2, 6-DICHLORO PARANITROANILINE, 2-4-5-TRICHLOROANILINE : PARA CHLOROANILINE"
    },
    {
      "code": "29214212",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : PARA CHLOROANILINE, ORTHO CHLORO PARANITROANILINE, DICHLOROANILINE, 2, 6-DICHLORO PARANITROANILINE, 2-4-5-TRICHLOROANILINE : ORTHO CHLORO PARANITROANILINE"
    },
    {
      "code": "29214213",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : PARA CHLOROANILINE, ORTHO CHLORO PARANITROANILINE, DICHLOROANILINE, 2, 6-DICHLORO PARANITROANILINE, 2-4-5-TRICHLOROANILINE : DICHLOROANILINE"
    },
    {
      "code": "29214214",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : PARA CHLOROANILINE, ORTHO CHLORO PARANITROANILINE, DICHLOROANILINE, 2, 6-DICHLORO PARANITROANILINE, 2-4-5-TRICHLOROANILINE : 2, 6-DICHLORO PARANITROANILINE"
    },
    {
      "code": "29214215",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : PARA CHLOROANILINE, ORTHO CHLORO PARANITROANILINE, DICHLOROANILINE, 2, 6-DICHLORO PARANITROANILINE, 2-4-5-TRICHLOROANILINE : 2-4-5-TRICHLOROANILINE"
    },
    {
      "code": "29214221",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : BENZYL ETHYL ANILINE"
    },
    {
      "code": "29214222",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS :-BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : DIETHYLANILINE"
    },
    {
      "code": "29214223",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : DIMETHYLANILINE"
    },
    {
      "code": "29214224",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : ETHYL ANILINE"
    },
    {
      "code": "29214225",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : META NITROANILINE"
    },
    {
      "code": "29214226",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : BENZYL ETHYL ANILINE, ETHYL ANILINE, DIETHYLANILINE, DIMETHYLANILINE, META NITROANILINE, PARA NITROANILINE : PARA NITROANILINE"
    },
    {
      "code": "29214231",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : 2-AMINO 3, 5 XYLNE SULPHONIC ACID"
    },
    {
      "code": "29214232",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : BENZYL ETHYL ANILINE SULPHONIC ACID"
    },
    {
      "code": "29214233",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID)"
    },
    {
      "code": "29214234",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID)"
    },
    {
      "code": "29214235",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : ETHYL HYDROXY ETHYLANILINE"
    },
    {
      "code": "29214236",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE)"
    },
    {
      "code": "29214290",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-ANILINE DERIVATIVES AND THEIR SALTS : 2-AMINO 3, 5 XYLNE SULPHONIC ACID, BENZYL ETHYL ANILINE SULPHURIC ACID, METANILLIC ACID (META AMINO BENZENE SULPHONIC ACID), SULPHANILLIC ACID (PARA AMINOBENZENE SULPHONIC ACID PARA ANILINE SULPHONIC ACID), ETHYL HYDROXY ETHYLANILINE, METHYL DOPA (1-ALPHA METHYL-3, 4-DIHYDROXYPHENYLANILINE) : OTHER"
    },
    {
      "code": "29214310",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : DIETHYL TOLUIDINE"
    },
    {
      "code": "29214320",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : DIMETHYL TOLUIDINE"
    },
    {
      "code": "29214330",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : ORTHO TOLUIDINE"
    },
    {
      "code": "29214340",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : META TOLUIDINE"
    },
    {
      "code": "29214350",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : PARA TOLUIDINE"
    },
    {
      "code": "29214360",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : 2-CHLORO-5-TOLUIDINE-4-SULPHONIC ACID"
    },
    {
      "code": "29214370",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : 2-CHLORO-4-TOLUIDINE-5-SULPHONIC ACID (SODIUM SALT)"
    },
    {
      "code": "29214380",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : 4-TOLUIDINE-3-SULPHONIC ACID"
    },
    {
      "code": "29214390",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-TOLUIDINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "292144",
      "description": "Diphenylamine and its derivatives; salts thereof"
    },
    {
      "code": "29214410",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : DIPHENYLAMINE AND ITS DERIVATIVES; SALTS THEREOF : DIPHENYLAMINE"
    },
    {
      "code": "29214490",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : DIPHENYLAMINE AND ITS DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "292145",
      "description": "1-Naphthylamine (alpha-naphthylamine), 2-Naphthylamine (betanaph thylamine) and their derivatives; salts thereof"
    },
    {
      "code": "29214511",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : ALPHA NAPHTHYLAMINE"
    },
    {
      "code": "29214512",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : PHENYL ALPHA NAPHTHYLAMINE"
    },
    {
      "code": "29214513",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : PHENYL BETA NAPHTHYLAMINE"
    },
    {
      "code": "29214514",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : AMINO F-ACID"
    },
    {
      "code": "29214515",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : AMINOLINELI-R-ACID"
    },
    {
      "code": "29214516",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : ALPHA NAPHTHYLAMINE, PHENYL ALPHA NAPHTHYLAMINE, PHENYL BETA NAPHTHYLAMINE, AMINO F-ACID, AMINOLINELI-R-ACID, SODIUM NAPHTHIONATE : SODIUM NAPHTHIONATE"
    },
    {
      "code": "29214521",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID)"
    },
    {
      "code": "29214522",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : CLEVES ACID (1-NAPHTHYLAMINE-6-SULPHONIC ACID)"
    },
    {
      "code": "29214523",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : EPSILON ACID (1-NAPHTHYLAMINE-3,8-DISULPHONIC ACID)"
    },
    {
      "code": "29214524",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID)"
    },
    {
      "code": "29214525",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID)"
    },
    {
      "code": "29214526",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : BRONNERS ACID (2-NAPHTHYLAMINE-6-SULPHONIC ACID), CLEVES ACID (1-NAPHTHYLAMINE-6- SULPHONIC ACID), EPSILON ACID (1- NAPHTHYLAMINE-3,8-DISULPHONIC ACID), KOCHS ACID (1-NAPHTHYLAMINE-3,6,8-TRISULPHONIC ACID), LAURENTS ACID (1-NAPHTHYLAMINE-5-SULPHONIC ACID), TOBIAS ACID (2-NAPHTHYLAMINE-1- SULPHONIC ACID) : TOBIAS ACID (2-NAPHTHYLAMINE-1-SULPHONIC ACID)"
    },
    {
      "code": "29214531",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : NAPHTHIONIC ACID (1-NAPHTHYLAMINE-4- SULPHONIC ACID), PARA TOLYL PERI ACID (PARA TOLYL- 1-NAPHTHYLAMINE-8-SULPHONIC ACID), PHENYL PERI ACID (PHENYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID) : NAPHTHIONIC ACID (1-NAPHTHYLAMINE-4-SULPHONIC ACID)"
    },
    {
      "code": "29214532",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : NAPHTHIONIC ACID (1-NAPHTHYLAMINE-4- SULPHONIC ACID), PARA TOLYL PERI ACID (PARA TOLYL- 1-NAPHTHYLAMINE-8-SULPHONIC ACID), PHENYL PERI ACID (PHENYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID) : PARA TOLYL PERI ACID (PARA TOLYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID)"
    },
    {
      "code": "29214533",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : NAPHTHIONIC ACID (1-NAPHTHYLAMINE-4- SULPHONIC ACID), PARA TOLYL PERI ACID (PARA TOLYL- 1-NAPHTHYLAMINE-8-SULPHONIC ACID), PHENYL PERI ACID (PHENYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID) : PHENYL PERI ACID (PHENYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID)"
    },
    {
      "code": "29214590",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-1-NAPHTHYLAMINE (ALPHA-NAPHTHYLAMINE), 2- NAPHTHYLAMINE (BETANAPH THYLAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : NAPHTHIONIC ACID (1-NAPHTHYLAMINE-4- SULPHONIC ACID), PARA TOLYL PERI ACID (PARA TOLYL- 1-NAPHTHYLAMINE-8-SULPHONIC ACID), PHENYL PERI ACID (PHENYL-1-NAPHTHYLAMINE-8-SULPHONIC ACID) : OTHER"
    },
    {
      "code": "29214600",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : AMFETAMINE (INN), BENZFETAMINE (INN) DEXAMFETAMINE (INN), ETILAMFETAMINE (INN) FENCAMFAMIN(INN), LEFETAMINE (INN), LEVAMFETAMINE (INN), MEFENOREX (INN) AND PHENTERMINE (INN); SALTS THEREOF"
    },
    {
      "code": "292149",
      "description": "Other"
    },
    {
      "code": "29214910",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : XYLIDINES"
    },
    {
      "code": "29214920",
      "description": "Para cumidine"
    },
    {
      "code": "29214990",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC MONOAMINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : OTHER"
    },
    {
      "code": "292151",
      "description": "o-, m-, p-Phenylenediamine, diaminotoluenes, and their derivatives; salts thereof"
    },
    {
      "code": "29215110",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : O-PHENYLENEDIAMINE"
    },
    {
      "code": "29215120",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : M-PHENYLENEDIAMINE (M-DI AMINOBENZENE)"
    },
    {
      "code": "29215130",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : P-PHENYLENEDIAMINE"
    },
    {
      "code": "29215140",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : O-DIAMINOTOLUENE"
    },
    {
      "code": "29215150",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : M-DIAMINOTOLUENE"
    },
    {
      "code": "29215160",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : P-DIAMINOTOLUENE"
    },
    {
      "code": "29215170",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : PARA-AMINO ACETANILIDE"
    },
    {
      "code": "29215180",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : META TOLUYLENE DIAMINE"
    },
    {
      "code": "29215190",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-O-, M-, P-PHENYLENEDIAMINE, DIAMINOTOLUENES, AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "292159",
      "description": "Other"
    },
    {
      "code": "29215910",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : BENZIDINE"
    },
    {
      "code": "29215920",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : BENZIDINE DIHYDROCHLORIDE"
    },
    {
      "code": "29215930",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : 3, 3 DICHLOROBENZIDINE DIHYDROCHLORIDE SULPHATE"
    },
    {
      "code": "29215940",
      "description": "Diaminostibene 2,2-disulphonic acid (Dasda)"
    },
    {
      "code": "29215990",
      "description": "AMINE- FUNCTION COMPOUNDS - AROMATIC POLYAMINES AND THEIR DERIVATIVES; SALTS THEREOF :-OTHER : OTHER"
    },
    {
      "code": "2922",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - Amino-alcohols, other than those containing more than one kind of oxygen function, their ethers and esters; salts thereof :"
    },
    {
      "code": "292211",
      "description": "Monoethanolamine and its salts;"
    },
    {
      "code": "29221100",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : MONOETHANOLAMINE AND ITS SALTS"
    },
    {
      "code": "29221110",
      "description": "2-HYDROXYL N,N-DIISOPROPYL ETHYLAMINE, N,N-DIETHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE, DI-ETHYL AMINO ETHANETHIOL HYDROCHLORIDE, DI-METHYL AMINO ETHYL CHLORIDE HYDROCHLORIDE, DI-METHYL AMINO ETHANETHIOL, DI-METHYL AMINO ETHANETHIOL HYDROCHLORIDE- - - MONOETHANOLAMINE"
    },
    {
      "code": "29221190",
      "description": "OTHER"
    },
    {
      "code": "292212",
      "description": "Diethanolamine and its salts"
    },
    {
      "code": "29221200",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : DIETHANOLAMINE AND ITS SALTS"
    },
    {
      "code": "29221210",
      "description": "DIETHANOLAMINE"
    },
    {
      "code": "29221290",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : DIETHANOLAMINE AND ITS SALTS : ETHYLDIETHANOLAMINE AND METHYLDIETHANOLAMINE :OTHER"
    },
    {
      "code": "29221310",
      "description": "TRIETHANOLAMINE"
    },
    {
      "code": "29221390",
      "description": "OTHER TRIETHANOLAMINE SALTS"
    },
    {
      "code": "29221400",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : DEXTROPROPOXYPHENE (INN) AND ITS SALTS"
    },
    {
      "code": "29221500",
      "description": "Triethanolamine"
    },
    {
      "code": "29221600",
      "description": "Diethanolammonium perfluorooctane sulphonate"
    },
    {
      "code": "29221710",
      "description": "Methyldiethanolamine"
    },
    {
      "code": "29221720",
      "description": "Ethyldiethanolamine"
    },
    {
      "code": "29221800",
      "description": "2-(N, N-Diisopropylamino) ethanol"
    },
    {
      "code": "292219",
      "description": "Other"
    },
    {
      "code": "29221900",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER"
    },
    {
      "code": "29221910",
      "description": "3 Hydroxy N, N-Dulsopopylethylamine"
    },
    {
      "code": "29221940",
      "description": "2-HYDROXY N,N-DI-ISOPROPYL ETHYLAMINE"
    },
    {
      "code": "29221950",
      "description": "ETHYLDIETHANOLAMINE"
    },
    {
      "code": "29221960",
      "description": "METHYLDIETHANOLAMINE"
    },
    {
      "code": "29221990",
      "description": "OTHER"
    },
    {
      "code": "292221",
      "description": "Aminohydroxynaphthalene sulphonic acids and their salts"
    },
    {
      "code": "29222110",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : AMINO-G-ACID"
    },
    {
      "code": "29222120",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : AMINO-J-ACID"
    },
    {
      "code": "29222130",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : 1-AMINO-2-NAPHTHOL-4-SULPHONIC ACID"
    },
    {
      "code": "29222140",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : GAMMA ACID"
    },
    {
      "code": "29222150",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : J ACID (2-AMINO-5-NAPHTHOL-7-SULPHONIC ACID)"
    },
    {
      "code": "29222160",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : H ACID"
    },
    {
      "code": "29222170",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : ORTHO PHENYL SULPHONYL H-ACID"
    },
    {
      "code": "29222180",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : CHICAGO ACID"
    },
    {
      "code": "29222190",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF :-AMINOHYDROXYNAPHTHALENE SULPHONIC ACIDS AND THEIR SALTS : OTHER"
    },
    {
      "code": "29222210",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : ANISIDINES, DIANISIDINES, PHENETIDINES AND THEIR SALTS : ORTHO ANISIDINES"
    },
    {
      "code": "29222220",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : ANISIDINES, DIANISIDINES, PHENETIDINES AND THEIR SALTS : PARA ANISIDINES"
    },
    {
      "code": "29222230",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : ANISIDINES, DIANISIDINES, PHENETIDINES AND THEIR SALTS : ORTHO PHENETIDINE (2-AMINO-PHENITOLE)"
    },
    {
      "code": "29222290",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : ANISIDINES, DIANISIDINES, PHENETIDINES AND THEIR SALTS : OTHER"
    },
    {
      "code": "292229",
      "description": "Other"
    },
    {
      "code": "29222911",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO 4-NITROPHENOL, META AMINOPHENOL, PARA AMINOPHENOL, META DIETHYL AMINO-PHENOL: 2-AMINO 4-NITROPHENOL"
    },
    {
      "code": "29222912",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO 4-NITROPHENOL, META AMINOPHENOL, PARA AMINOPHENOL, META DIETHYL AMINO-PHENOL: META AMINOPHENOL"
    },
    {
      "code": "29222913",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO 4-NITROPHENOL, META AMINOPHENOL, PARA AMINOPHENOL, META DIETHYL AMINO-PHENOL: PARA AMINOPHENOL"
    },
    {
      "code": "29222914",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO 4-NITROPHENOL, META AMINOPHENOL, PARA AMINOPHENOL, META DIETHYL AMINO-PHENOL: META DIETHYL AMINO-PHENOL"
    },
    {
      "code": "29222921",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : 2-AMINO-1-PHENOL-4-SULPHONIC ACID"
    },
    {
      "code": "29222922",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : 6-NITRO-O-AMINOPHENOL-4-SULPHONIC ACID"
    },
    {
      "code": "29222923",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID)"
    },
    {
      "code": "29222924",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID)"
    },
    {
      "code": "29222925",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1-NAPHTHYLAMINE-8-SULPHONIC ACID)"
    },
    {
      "code": "29222926",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : 2-AMINO-1-PHENOL-4-SULPHONIC ACID, 6-NITRO-OAMINOPHENOL- 4-SULPHONIC ACID, PHENYL GAMMA ACID (PHENYL 2-AMINO-NAPHTHOL-6-SULPHONIC ACID), PHENYL J ACID (PHENYL-2-AMINO-8 NAPHTHOL-7-SULPHONIC ACID), S ACID, PERI ACID (1-AMINO-8-NAPHTHOL-4-4-SULPHOXINIC ACID, 1- NAPHTHYLAMINE-8-SULPHONIC ACID), METAPHENYLENE DIAMINE-4-SULPHONIC ACID : META-PHENYLENE DIAMINE-4-SULPHONIC ACID"
    },
    {
      "code": "29222931",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL),2, 5 DIMETHOXY ANILINE, PARA ACETYL AMINOPHENOL (PARACETAMOL), PARA CRESIDINE, PICRAMIC ACID (T-GRADE) : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL)"
    },
    {
      "code": "29222932",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL),2, 5 DIMETHOXY ANILINE, PARA ACETYL AMINOPHENOL (PARACETAMOL), PARA CRESIDINE, PICRAMIC ACID (T-GRADE) : 2, 5 DIMETHOXY ANILINE"
    },
    {
      "code": "29222933",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL),2, 5 DIMETHOXY ANILINE, PARA ACETYL AMINOPHENOL (PARACETAMOL), PARA CRESIDINE, PICRAMIC ACID (T-GRADE) : PARA ACETYL AMINOPHENOL (PARACETAMOL)"
    },
    {
      "code": "29222934",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL),2, 5 DIMETHOXY ANILINE, PARA ACETYL AMINOPHENOL (PARACETAMOL), PARA CRESIDINE, PICRAMIC ACID (T-GRADE) : PARA CRESIDINE"
    },
    {
      "code": "29222935",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : N-METHYL-PARA-AMINOPHENOL SULPHATE (MOTOL),2, 5 DIMETHOXY ANILINE, PARA ACETYL AMINOPHENOL (PARACETAMOL), PARA CRESIDINE, PICRAMIC ACID (T-GRADE) : PICRAMIC ACID (T-GRADE)"
    },
    {
      "code": "29222936",
      "description": "Para cresidine ortho sulphonic acid"
    },
    {
      "code": "29222990",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-NAPHTHOLS AND OTHER AMINO-PHENOLS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, THEIR ETHERS AND ESTERS; SALTS THEREOF : OTHER : OTHER"
    },
    {
      "code": "29223100",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALDEHYDES , AMINO-KETONES AND AMINOQUINONES, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION; SALTS THEREOF : AMFEPRAMONE (INN) AND SALTS THEREOF"
    },
    {
      "code": "29223900",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALDEHYDES , AMINO-KETONES AND AMINOQUINONES, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION; SALTS THEREOF : OTHER"
    },
    {
      "code": "29224100",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : LYSINE AND ITS ESTERS; SALTS THEREOF"
    },
    {
      "code": "292242",
      "description": "Glutamic acid and its salts"
    },
    {
      "code": "29224210",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF :-GLUTAMIC ACID AND ITS SALTS : GLUTAMIC ACID"
    },
    {
      "code": "29224220",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF :-GLUTAMIC ACID AND ITS SALTS : MONOSODIUM GLUTAMATE (AGINAMOTO)"
    },
    {
      "code": "29224290",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF :-GLUTAMIC ACID AND ITS SALTS : OTHER"
    },
    {
      "code": "29224300",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : ANTHRANILIC ACID AND ITS SALTS"
    },
    {
      "code": "29224400",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : TILIDINE (INN) AND ITS SALTS"
    },
    {
      "code": "292249",
      "description": "Other"
    },
    {
      "code": "29224910",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : OTHER : AMINO ACETIC ACID (GLYCINE)"
    },
    {
      "code": "29224920",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : OTHER : N-METHYL TAURINE"
    },
    {
      "code": "29224990",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ACIDS, OTHER THAN THOSE CONTAINING MORE THAN ONE KIND OF OXYGEN FUNCTION, AND THEIR ESTERS; SALTS THEREOF : OTHER : OTHER"
    },
    {
      "code": "292250",
      "description": "Amino-alcohol-phenols, amino-acid-phenols and other amino-compounds with oxygen function"
    },
    {
      "code": "29225011",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : PARA-AMINO-SALICYLIC ACID, METHYL ANTHRANILATE, PROCAINE HYDROCHLORIDE, AMINO ANISIC ACID ANILIDE,L-TYROSINE (P-HYDROXYPHENYL AMINE) : PARA-AMINO-SALICYLIC ACID"
    },
    {
      "code": "29225012",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : PARA-AMINO-SALICYLIC ACID, METHYL ANTHRANILATE, PROCAINE HYDROCHLORIDE, AMINO ANISIC ACID ANILIDE,L-TYROSINE (P-HYDROXYPHENYL AMINE) : METHYL ANTHRANILATE"
    },
    {
      "code": "29225013",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : PARA-AMINO-SALICYLIC ACID, METHYL ANTHRANILATE, PROCAINE HYDROCHLORIDE, AMINO ANISIC ACID ANILIDE,L-TYROSINE (P-HYDROXYPHENYL AMINE) : PROCAINE HYDROCHLORIDE"
    },
    {
      "code": "29225014",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : PARA-AMINO-SALICYLIC ACID, METHYL ANTHRANILATE, PROCAINE HYDROCHLORIDE, AMINO ANISIC ACID ANILIDE,L-TYROSINE (P-HYDROXYPHENYL AMINE) : AMINO ANISIC ACID ANILIDE"
    },
    {
      "code": "29225015",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : PARA-AMINO-SALICYLIC ACID, METHYL ANTHRANILATE, PROCAINE HYDROCHLORIDE, AMINO ANISIC ACID ANILIDE,L-TYROSINE (P-HYDROXYPHENYL AMINE) : L-TYROSINE (P-HYDROXYPHENYL AMINE)"
    },
    {
      "code": "29225021",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : FRUSEMIDE, AMINODIAL, N-ACETYL ANTHRANILIC ACID, DOMPERIDONE : FRUSEMIDE"
    },
    {
      "code": "29225022",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : FRUSEMIDE, AMINODIAL, N-ACETYL ANTHRANILIC ACID, DOMPERIDONE : AMINODIAL"
    },
    {
      "code": "29225023",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : FRUSEMIDE, AMINODIAL, N-ACETYL ANTHRANILIC ACID, DOMPERIDONE : N-ACETYL ANTHRANILIC ACID"
    },
    {
      "code": "29225024",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : FRUSEMIDE, AMINODIAL, N-ACETYL ANTHRANILIC ACID, DOMPERIDONE : DOMPERIDONE"
    },
    {
      "code": "29225090",
      "description": "OXYGEN-FUNCTION AMINO-COMPOUNDS - AMINO-ALCOHOL-PHENOLS, AMINO-ACID-PHENOLS AND OTHER AMINO-COMPOUNDS WITH OXYGEN FUNCTION : OTHER"
    },
    {
      "code": "2923",
      "description": "QUATERNARY AMMONIUM SALTS AND HYDROXIDES; LECITHINS AND OTHER PHOSPHOAMINOLIPIDS, WHETHER OR NOT CHEMICALLY DEFINED"
    },
    {
      "code": "29231000",
      "description": "QUATERNARY AMMONIUM SALTS AND HYDROXIDES; LECITHINS AND OTHER PHOSPHOAMINOLIPIDS, WHETHER OR NOT CHEMICALLY DEFINED CHOLINE AND ITS SALTS"
    },
    {
      "code": "292320",
      "description": "Lecithins and other phosphoaminolipids"
    },
    {
      "code": "29232010",
      "description": "QUATERNARY AMMONIUM SALTS AND HYDROXIDES; LECITHINS AND OTHER PHOSPHOAMINOLIPIDS, WHETHER OR NOT CHEMICALLY DEFINED - LECITHINS AND OTHER PHOSPHOAMINOLIPIDS : LECITHINS"
    },
    {
      "code": "29232090",
      "description": "QUATERNARY AMMONIUM SALTS AND HYDROXIDES; LECITHINS AND OTHER PHOSPHOAMINOLIPIDS, WHETHER OR NOT CHEMICALLY DEFINED - LECITHINS AND OTHER PHOSPHOAMINOLIPIDS : OTHER"
    },
    {
      "code": "29233000",
      "description": "Tetraethylammonium perfluorooctane sulphonate"
    },
    {
      "code": "29234000",
      "description": "Didecyldimethylammonium perfluorooctane sulphonate"
    },
    {
      "code": "29239000",
      "description": "QUATERNARY AMMONIUM SALTS AND HYDROXIDES; LECITHINS AND OTHER PHOSPHOAMINOLIPIDS, WHETHER OR NOT CHEMICALLY DEFINED OTHER"
    },
    {
      "code": "2924",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDEFUNCTION COMPOUNDS OF CARBONIC ACID - Acyclic amides (including acyclic carbamates) and their derivatives; salts thereof :"
    },
    {
      "code": "29241100",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - ACYCLIC AMIDES (INCLUDING ACYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : MEPROBAMATE (INN)"
    },
    {
      "code": "29241200",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - ACYCLIC AMIDES (INCLUDING ACYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : -- FLUOROACETAMIDE (ISO), MONOCROTOPHOS (ISO) AND PHOSPHAMIDON (IS0)"
    },
    {
      "code": "29241900",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - ACYCLIC AMIDES (INCLUDING ACYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "292421",
      "description": "Ureines and their derivatives; salts thereof"
    },
    {
      "code": "29242110",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF :-UREINES AND THEIR DERIVATIVES; SALTS THEREOF : DIETHYL DIPHENYL UREA"
    },
    {
      "code": "29242120",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF :-UREINES AND THEIR DERIVATIVES; SALTS THEREOF : DIMETHYL DIPHENYL UREA (ZENTRALIN)"
    },
    {
      "code": "29242130",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF :-UREINES AND THEIR DERIVATIVES; SALTS THEREOF : PARACHLORO BENZENE SULPHONYL UREA"
    },
    {
      "code": "29242190",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF :-UREINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29242300",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : 2-ACETAMIDOBENZOIC ACID (N-ACETYLANTHRANILIC ACID) AND ITS SALTS"
    },
    {
      "code": "29242400",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : ETHINAMATE (INN)"
    },
    {
      "code": "29242500",
      "description": "Alachlor (ISO)"
    },
    {
      "code": "292429",
      "description": "Other"
    },
    {
      "code": "29242910",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : ACETANILIDE"
    },
    {
      "code": "29242920",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : ACETO ACETANILIDE"
    },
    {
      "code": "29242930",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : ACETO ACETIC ORTHO CHLORANILIDE"
    },
    {
      "code": "29242940",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : ACETO ACETIC PARA CHLORANILIDE"
    },
    {
      "code": "29242950",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : PHENYL ACETAMIDE"
    },
    {
      "code": "29242960",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : PYRAZINAMIDE (PYRAZINE CARBOXAMIDE)"
    },
    {
      "code": "29242990",
      "description": "CARBOXYAMIDE-FUNCTION COMPOUNDS; AMIDE-FUNCTION COMPOUNDS OF CARBONIC ACID - CYCLIC AMIDES (INCLUDING CYCLIC CARBAMATES) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER : OTHER"
    },
    {
      "code": "2925",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - Imides and their derivatives; salts thereof :"
    },
    {
      "code": "29251100",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMIDES AND THEIR DERIVATIVES; SALTS THEREOF : SACCHARIN AND ITS SALTS"
    },
    {
      "code": "29251200",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMIDES AND THEIR DERIVATIVES; SALTS THEREOF : GLUTETHIMIDE (INN)"
    },
    {
      "code": "29251900",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMIDES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29252010",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMINES AND THEIR DERIVATIVES; SALTS THEREOF : GUANIDINE NITRATE"
    },
    {
      "code": "29252090",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMINES AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29252100",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMINES AND THEIR DERIVATIVES; SALTS THEREOF : CHLORDIMEFORM (ISO)"
    },
    {
      "code": "292529",
      "description": "Other"
    },
    {
      "code": "29252910",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMINES AND THEIR DERIVATIVES; SALTS THEREOF : -- OTHER --- GUANIDINE NITRATE"
    },
    {
      "code": "29252990",
      "description": "CARBOXYIMIDE-FUNCTION COMPOUNDS (INCLUDING SACCHARIN AND ITS SALTS) AND IMINE-FUNCTION COMPOUNDS - IMINES AND THEIR DERIVATIVES; SALTS THEREOF : -- OTHER --- OTHER"
    },
    {
      "code": "2926",
      "description": "NITRILE-FUNCTION COMPOUNDS"
    },
    {
      "code": "29261000",
      "description": "NITRILE-FUNCTION COMPOUNDS ACRYLONITRILE"
    },
    {
      "code": "29262000",
      "description": "NITRILE-FUNCTION COMPOUNDS 1-CYANOGUANIDINE (DICYANDIAMIDE)"
    },
    {
      "code": "29263000",
      "description": "NITRILE-FUNCTION COMPOUNDS FENPROPOREX (INN) AND ITS SALTS"
    },
    {
      "code": "29264000",
      "description": "Alpha-phenylacetoacetonitrile"
    },
    {
      "code": "29269000",
      "description": "NITRILE-FUNCTION COMPOUNDS OTHER"
    },
    {
      "code": "2927",
      "description": "DIAZO-, AZO- OR AZOXY-COMPOUNDS"
    },
    {
      "code": "292700",
      "description": "Diazo-, azo- or azoxy- compounds"
    },
    {
      "code": "29270010",
      "description": "DIAZO-, AZO- OR AZOXY-COMPOUNDS - DIAZO-, AZO- OR AZOXY- COMPOUNDS : PARA AMINO-AZO-BENZENE"
    },
    {
      "code": "29270090",
      "description": "DIAZO-, AZO- OR AZOXY-COMPOUNDS - DIAZO-, AZO- OR AZOXY- COMPOUNDS : OTHER"
    },
    {
      "code": "2928",
      "description": "ORGANIC DERIVATIVES OF HYDRAZINE OR OF HYDROXYLAMINE"
    },
    {
      "code": "292800",
      "description": "Organic derivatives of hydrazine or of hydroxylamine"
    },
    {
      "code": "29280010",
      "description": "ORGANIC DERIVATIVES OF HYDRAZINE OR OF HYDROXYLAMINE - ORGANIC DERIVATIVES OF HYDRAZINE OR OF HYDROXYLAMINE : ISONIAZID"
    },
    {
      "code": "29280090",
      "description": "ORGANIC DERIVATIVES OF HYDRAZINE OR OF HYDROXYLAMINE - ORGANIC DERIVATIVES OF HYDRAZINE OR OF HYDROXYLAMINE : OTHER"
    },
    {
      "code": "2929",
      "description": "COMPOUNDS WITH OTHER NITROGEN FUNCTION"
    },
    {
      "code": "292910",
      "description": "Isocyanates"
    },
    {
      "code": "29291010",
      "description": "COMPOUNDS WITH OTHER NITROGEN FUNCTION - ISOCYANATES : PHENYL ISOCYANATE"
    },
    {
      "code": "29291020",
      "description": "COMPOUNDS WITH OTHER NITROGEN FUNCTION - ISOCYANATES : TOLUENE DI-ISOCYANATE"
    },
    {
      "code": "29291090",
      "description": "COMPOUNDS WITH OTHER NITROGEN FUNCTION - ISOCYANATES : OTHER"
    },
    {
      "code": "29299010",
      "description": "N,N-Diethylphosphoramidicdichloride"
    },
    {
      "code": "29299020",
      "description": "N,N-Diisopropylphosphoramidicdichloride"
    },
    {
      "code": "29299030",
      "description": "N,N-DIPROPYLPHOSPHORAMIDICDICHLORIDE"
    },
    {
      "code": "29299040",
      "description": "N,N-DIMETHYLPHOSPHORAMIDICDICHLORIDE"
    },
    {
      "code": "29299050",
      "description": "DIETHYL N,N-DIMETHYLPHOSPHORAMIDATE"
    },
    {
      "code": "29299060",
      "description": "PHOSPHORAMIDIC ACID, DIETHYL, DIMETHYLESTER"
    },
    {
      "code": "29299090",
      "description": "OTHER"
    },
    {
      "code": "2930",
      "description": "ORGANO-SULPHUR COMPOUNDS"
    },
    {
      "code": "29301000",
      "description": "ORGANO-SULPHUR COMPOUNDS - DITHIOCARBONATES (XANTHATES)"
    },
    {
      "code": "29302000",
      "description": "ORGANO-SULPHUR COMPOUNDS - THIOCARBAMATES AND DITHIOCARBAMATES"
    },
    {
      "code": "29303000",
      "description": "ORGANO-SULPHUR COMPOUNDS - THIURAM MONO-, DI OR TETRASULPHIDES"
    },
    {
      "code": "29304000",
      "description": "ORGANO-SULPHUR COMPOUNDS - METHIONINE"
    },
    {
      "code": "29305000",
      "description": "ORGANOSULPHUR COMPOUNDS  CAPTAFOL ISO AND METHAMIDOPHOS ISO"
    },
    {
      "code": "29306000",
      "description": "2-(N, N-Diethylamino)ethanethiol"
    },
    {
      "code": "29307000",
      "description": "Bis(2-hydroxyethyl)sulfide (thiodiglycol (INN))"
    },
    {
      "code": "29308000",
      "description": "Aldicarb (ISO), captafol (ISO) and methamidophos (ISO)"
    },
    {
      "code": "293090",
      "description": "Other"
    },
    {
      "code": "29309010",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : THIOUREA (SULPHOUREA)"
    },
    {
      "code": "29309020",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : CALCIUM SALTS OF METHIONINE"
    },
    {
      "code": "29309030",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : THIO SULPHONIC ACID"
    },
    {
      "code": "29309040",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : L-CYSTINE (ALPHA-AMINO BETA-THIOPROPIONIC ACID)- SULPHUR CONTAINING AMINO ACID"
    },
    {
      "code": "29309050",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : SULPHINIC ACID"
    },
    {
      "code": "29309060",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : SULPHOXIDE"
    },
    {
      "code": "29309070",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : MERCAPTAN"
    },
    {
      "code": "29309080",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : ALLYL ISOTHIOCYANATE"
    },
    {
      "code": "29309090",
      "description": "ORGANO-SULPHUR COMPOUNDS - OTHER : OTHER"
    },
    {
      "code": "29309091",
      "description": "ETHANOL, 2,2-THIOBIS-"
    },
    {
      "code": "29309092",
      "description": "DI-METHYL AMINO ETHANETHIOL"
    },
    {
      "code": "29309093",
      "description": "DI-METHYL AMINO ETHANETHIOL HYDROCHLORIDE"
    },
    {
      "code": "29309094",
      "description": "DIETHYL AMINO ETHANETHIOL"
    },
    {
      "code": "29309095",
      "description": "DI-ETHYL AMINO ETHANETHIOL HYDROCHLORIDE"
    },
    {
      "code": "29309096",
      "description": "O-ETHYL S-PHENYL ETHYLPHOSPHONOTHIOLOTHIONATE (FONOFOS)"
    },
    {
      "code": "29309097",
      "description": "PHOSPHOROTHIOIC ACID,S[2-(DIETHYL AMINO) ETHYL]O, O-DIETHYL ESTER"
    },
    {
      "code": "29309098",
      "description": "Dichloro diphenyl sulphone"
    },
    {
      "code": "29309099",
      "description": "OTHER"
    },
    {
      "code": "2931",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS"
    },
    {
      "code": "293100",
      "description": "Other organo-inorganic compounds"
    },
    {
      "code": "29310010",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS - OTHER ORGANO-INORGANIC COMPOUNDS : ORGANO-MERCURY COMPOUNDS"
    },
    {
      "code": "29310041",
      "description": "PHOSPHONIC ACID,METHYL -"
    },
    {
      "code": "29310042",
      "description": "PHOSPHONIC ACID,ETHYL"
    },
    {
      "code": "29310043",
      "description": "PHOSPHONIC ACID,PROPYL"
    },
    {
      "code": "29310044",
      "description": "PHOSPHINIC ACID,METHYL-"
    },
    {
      "code": "29310051",
      "description": "PHOSPONIC ACIDE,[METHYL-(5-ETHYL-2-METHYL 2-OXIDO- 1,3,2-DIOXAPHOSPHORINAN-5-YL) METHYL]ESTER"
    },
    {
      "code": "29310052",
      "description": "PHOSPHONIC ACIDE,[METHYL-BIS (5-ETHYL-2-METHYL 2-OXIDO- 1,3,2-DIOXAPHOSPHORINAN-5-YL) METHYL] ESTER"
    },
    {
      "code": "29310053",
      "description": "PHOSPHONIC ACID,METHYL,DIMETHYL ESTER"
    },
    {
      "code": "29310054",
      "description": "PHOSPHONIC ACID PROPYL DIMETHYL ESTER"
    },
    {
      "code": "29310055",
      "description": "PHOSPHONIC ACID ETHYL-DIETHYL ESTER"
    },
    {
      "code": "29310056",
      "description": "PHOSPHONOCHLORIDIC ACID METHYL-,METHYL ESTER"
    },
    {
      "code": "29310057",
      "description": "PHOSPHONOUS ACID- METHYL DIETHYL ESTER"
    },
    {
      "code": "29310061",
      "description": "1 PROPANAMINIUM N,N,N TRIMETHYL 3-[1-OXO-9-OCTADECENYL AMINO] -(Z)-METHYL METHYL PHOSPHONATE"
    },
    {
      "code": "29310062",
      "description": "O,O-DIMETHYL METHYL PHOSPHONATE"
    },
    {
      "code": "29310071",
      "description": "PHOSPHONOTHIOIC DICHLORIDE,ETHYL-"
    },
    {
      "code": "29310072",
      "description": "PHOSPHONIC DICHLORIDE,METHYL"
    },
    {
      "code": "29310073",
      "description": "PHOSPHONOUS DICHLORIDE,METHYL-"
    },
    {
      "code": "293110",
      "description": "Tetramethyl lead and tetraethyl lead"
    },
    {
      "code": "29311010",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS-TETRAMETHYL LEAD AND TETRAETHYL LEAD---TETRAMETHYL LEAD"
    },
    {
      "code": "29311020",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS-TETRAMETHYL LEAD AND TETRAETHYL LEAD---TETRAETHYL LEAD"
    },
    {
      "code": "293120",
      "description": "Tributyltin compounds"
    },
    {
      "code": "29312000",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS -TRIBUTYLTIN COMPOUNDS"
    },
    {
      "code": "29313100",
      "description": "Dimethyl methylphosphonate"
    },
    {
      "code": "29313200",
      "description": "Dimethyl propylphosphonate"
    },
    {
      "code": "29313300",
      "description": "Diethyl ethylphosphonate"
    },
    {
      "code": "29313400",
      "description": "Sodium 3-(trihydroxysilyl)propyl methylphosphonate"
    },
    {
      "code": "29313500",
      "description": "2, 4, 6-Tripropyl-1, 3, 5, 2, 4, 6-trioxatriphosphinane 2, 4, 6-trioxide"
    },
    {
      "code": "29313600",
      "description": "(5-Ethyl-2-methyl-2-oxido-1, 3, 2-dioxaphosphinan-5-yl)methyl methylphosphonate"
    },
    {
      "code": "29313700",
      "description": "Bis[(5-ethyl-2-methyl-2-oxido-1, 3, 2-dioxaphosphinan-5-yl) methyl] methylphosphonate"
    },
    {
      "code": "29313800",
      "description": "Salt of methylphosphonic acid and (aminoiminomethyl)urea (1 1)"
    },
    {
      "code": "29313900",
      "description": "Other"
    },
    {
      "code": "293190",
      "description": "other"
    },
    {
      "code": "29319010",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS -OTHER---ORGANO ARSENIC COMPOUNDS"
    },
    {
      "code": "29319090",
      "description": "OTHER ORGANO-INORGANIC COMPOUNDS -OTHER---OTHER"
    },
    {
      "code": "2932",
      "description": "Heterocyclic compounds with oxygen heteroatom (s) only - Compounds containing an unfused furan ring (whether or not hydrogenated) in the structure :"
    },
    {
      "code": "29321100",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - COMPOUNDS CONTAINING AN UNFUSED FURAN RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : TETRAHYDROFURAN"
    },
    {
      "code": "29321200",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - COMPOUNDS CONTAINING AN UNFUSED FURAN RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : 2-FURALDEHYDE (FURFURALDEHYDE)"
    },
    {
      "code": "29321300",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - COMPOUNDS CONTAINING AN UNFUSED FURAN RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : FURFURYL ALCOHOL AND TETRAHYDROFURFURYL ALCOHOL"
    },
    {
      "code": "29321400",
      "description": "Sucralose"
    },
    {
      "code": "293219",
      "description": "Other"
    },
    {
      "code": "29321910",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - COMPOUNDS CONTAINING AN UNFUSED FURAN RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : OTHER : HYDROXY DIBENZFURAN CARBOXYLIC ACID"
    },
    {
      "code": "29321990",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - COMPOUNDS CONTAINING AN UNFUSED FURAN RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : OTHER : OTHER"
    },
    {
      "code": "293220",
      "description": "Lactones"
    },
    {
      "code": "29322010",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM(S) ONLY - LACTONES---COUMARIN, METHYLCOUMARINS AND ETHYLCOUMARINS"
    },
    {
      "code": "29322020",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM(S) ONLY - LACTONES---PHENOLPHTHALEIN"
    },
    {
      "code": "29322090",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM(S) ONLY - LACTONES---OTHER"
    },
    {
      "code": "293229",
      "description": "Other lactones"
    },
    {
      "code": "29329100",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : ISOSAFROLE"
    },
    {
      "code": "29329200",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : 1-(1,3-BENZODIOXOL-5-YL) PROPAN-2-ONE"
    },
    {
      "code": "29329300",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : PIPERONAL"
    },
    {
      "code": "29329400",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : SAFROLE"
    },
    {
      "code": "29329500",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : TETRAHYDROCANNABINOLS (ALL ISOMERS)"
    },
    {
      "code": "293299",
      "description": "Other"
    },
    {
      "code": "29329900",
      "description": "HETEROCYCLIC COMPOUNDS WITH OXYGEN HETERO-ATOM (S) ONLY - OTHER : OTHER"
    },
    {
      "code": "29329910",
      "description": "Cineole"
    },
    {
      "code": "29329990",
      "description": "other"
    },
    {
      "code": "2933",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM(S) ONLY - Compounds containing an unfused pyrazole ring (whether or not hydrogenated) in the structure :"
    },
    {
      "code": "29331100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : PHENAZONE (ANTIPYRIN) AND ITS DERIVATES"
    },
    {
      "code": "293319",
      "description": "Other"
    },
    {
      "code": "29331910",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : 3-CARBOXY (PARA SULPHO-PHENYL)-5- PYRAZOLONE"
    },
    {
      "code": "29331920",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : 1 (2,5- DICHLORO-4-SULPHO PHENYL)-3-METHYL-5-PYRAZOLONE"
    },
    {
      "code": "29331930",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : 3-METHYL-1(4-SULPHO-O-TOLUYL-5-PYRAZOLONE)"
    },
    {
      "code": "29331940",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : PHENYLMETHYLPYRAZOLONE"
    },
    {
      "code": "29331950",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : 1-PHENYL-5-PYRAZOLONE-3-CARBOXYLIC ACID ETHYLESTER"
    },
    {
      "code": "29331960",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : 1-(M-SULPHOPHENYL)-3-PYRAZOLONE"
    },
    {
      "code": "29331970",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : ANALGIN"
    },
    {
      "code": "29331980",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : OXYPHENBUTAZONE"
    },
    {
      "code": "29331990",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED PYRAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : OTHER"
    },
    {
      "code": "29331991",
      "description": "Fluoro-3-(difluoromethyl)-1-methyl-1h-pyrazole-4-carbonyl fluoride"
    },
    {
      "code": "29331999",
      "description": "Other"
    },
    {
      "code": "29332100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : HYDANTOIN AND ITS DERIVATIVES"
    },
    {
      "code": "293329",
      "description": "Other"
    },
    {
      "code": "29332910",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : TINIDAZOLE"
    },
    {
      "code": "29332920",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : METRONIDAZOLE, METRONIDIAZOLE BENZOATE"
    },
    {
      "code": "29332930",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : MEBENDAZOLE"
    },
    {
      "code": "29332940",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DIMETRIDAZOLE"
    },
    {
      "code": "29332950",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : ALBENDAZOLE"
    },
    {
      "code": "29332990",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED IMIDAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : OTHER"
    },
    {
      "code": "29333100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : PYRIDINE AND ITS SALTS"
    },
    {
      "code": "29333200",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : PIPERIDINE AND ITS SALTS"
    },
    {
      "code": "29333300",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : BROMAZEPAM (INN), METHYLPHENIDATE (INN), PENTAZOCINE (INN), PHENCYCLINDINE (INN) (PCP), PIPRADROL (INN), PROPIRAM (INN); SALTS THEREOF"
    },
    {
      "code": "293339",
      "description": "Other"
    },
    {
      "code": "29333911",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : AMINO PYRIDINE"
    },
    {
      "code": "29333912",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : ALPHA PICOLINE (2-METHYL PYRIDINE)"
    },
    {
      "code": "29333913",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : GAMMA PICOLINE (4-METHYL PYRIDINE)"
    },
    {
      "code": "29333914",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : CHLOROPHENIRAMINE MALEATE"
    },
    {
      "code": "29333915",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM(  S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING  (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER :  -  DERIVATIVES OF PYRIDINE :"
    },
    {
      "code": "29333916",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : BETA PICOLINE (3-METHYL PYRIDINE)"
    },
    {
      "code": "29333917",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : MORPHOLINE"
    },
    {
      "code": "29333918",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : LUTIDINE (DIMETHYL PYRIDINE)"
    },
    {
      "code": "29333919",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : DERIVATIVES OF PYRIDINE : OTHER"
    },
    {
      "code": "29333920",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : PIPERIDINE AND ITS DERIVATIVES"
    },
    {
      "code": "29333930",
      "description": "1-AZABICYCLO(2,2,2)OCTAN-3-OL"
    },
    {
      "code": "29333940",
      "description": "BENZENE ACETIC ACID,ALPHA -HYDROXY- ALPHA-PHENYL, 1-AZABICYCLO[2.2.2] OCT-3-YL ESTER"
    },
    {
      "code": "29333990",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAING AN UNFUSED PYRIDINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : OTHER"
    },
    {
      "code": "29334100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM(  S) ONLY - COMPOUNDS CONTAINING IN THE STRUCTURE A  QUINOLINE OR ISOQUINOLINE RING-SYSTEM (WHETHER  OR NOT HYDROGENATED), NOT FURTHER FUSED :"
    },
    {
      "code": "29334900",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING IN THE STRUCTURE A QUINOLINE OR ISOQUINOLINE RING-SYSTEM (WHETHER OR NOT HYDROGENATED), NOT FURTHER FUSED : OTHER"
    },
    {
      "code": "29335200",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE : MALONYLUREA (BARBITURIC ACID) AND ITS SALTS"
    },
    {
      "code": "29335300",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE : ALLOBARBITAL (INN), AMOBARBITAL (INN), BARBITAL (INN), BUTALBITAL (INN), BUTOBARBITAL (INN), CYCLOBARBITAL (INN), METHYLPHENOBARBITAL (INN), PENTOBARBITAL (INN), SECBUTABARBITAL (INN), PHENOBARBITAL (INN), SECOBARBITAL (INN), AND VINYLBITAL (INN); SALTS THEREOF"
    },
    {
      "code": "29335400",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE : OTHER DERIVATIVES OF MALONYLUREA (BARBITURIC ACID); SALTS THEREOF"
    },
    {
      "code": "29335500",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE : LOPRAZOLAM (INN), MECLOQUALONE (INN), METHAQUALONE (INN) AND ZIPEPROL (INN); SALTS THEREOF"
    },
    {
      "code": "293359",
      "description": "Other"
    },
    {
      "code": "29335910",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE :-OTHER : AMINOPHYLLINE (CORDOPHYLIN)"
    },
    {
      "code": "29335920",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE :-OTHER : TRIMETHOPRIM"
    },
    {
      "code": "29335930",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE :-OTHER : DIETHYL CARBAMAZINE CITRATE"
    },
    {
      "code": "29335940",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE :-OTHER : 1-AMINO-4-METHYL PIPERAZINE"
    },
    {
      "code": "29335990",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING A PYRIMIDINE RING (WHETHER OR NOT HYDROGENATED) OR PIPERAZINE RING IN THE STRUCTURE :-OTHER : OTHER"
    },
    {
      "code": "29336100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED TRIAZINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE : MELAMINE"
    },
    {
      "code": "293369",
      "description": "Other"
    },
    {
      "code": "29336910",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED TRIAZINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : CYANURIC ACID AND ITS SALTS"
    },
    {
      "code": "29336920",
      "description": "4-[4,6-bis(2,4-dimethylphenyl)-1,3,5-triazine-2-yl]-1,3-benzenediol"
    },
    {
      "code": "29336930",
      "description": "Tris(2-hydroxyethyl) isocyanurate"
    },
    {
      "code": "29336940",
      "description": "Ethylhexyltriazone"
    },
    {
      "code": "29336950",
      "description": "2,4,6-tri(2,4-dygydroxyl-3-methylphenyl)-1,3,5-triazine"
    },
    {
      "code": "29336990",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - COMPOUNDS CONTAINING AN UNFUSED TRIAZINE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE :-OTHER : OTHER"
    },
    {
      "code": "29337100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - LACTAMS : 6-HEXANELACTAM (EPSILON-CAPROLACTAM)"
    },
    {
      "code": "29337200",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - LACTAMS : CLOBAZAM (INN) AND METHYPRYLON (INN)"
    },
    {
      "code": "293379",
      "description": "Other lactams"
    },
    {
      "code": "29337900",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - LACTAMS : OTHER LACTAMS"
    },
    {
      "code": "29337910",
      "description": "N-methyl-2-pyrrolidone"
    },
    {
      "code": "29337920",
      "description": "N-ethyl-2-pyrrolidone"
    },
    {
      "code": "29337990",
      "description": "Other"
    },
    {
      "code": "29339100",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - OTHER : ALPRAZOLAM (INN), CAMAZEPAM (INN) CHLORIDIAZEPOXIDE (INN), CLONAZEPAM (INN), CLORAZEPATE, DELORAZEPAM (INN), DIAZEPAM (INN), ESTAZOLAM (INN), ETHYL LOFLAZEPATE (INN), FLUDIAZEPAM (INN), FLUNITRZEPAM (INN), FLURAZEPAM (INN), HALAZAEPAM (INN), LORAZEPAM (INN), LORMETAZEPAM (INN), MAZINDOL (INN), MEDAZEPAM (INN), MIDAZOLAM (INN), NIMETAZEPAM (INN), NITRAZEPAM (INN), NORDAZEPAM (INN), OXAZEPAM (INN), PINAZEPAM (INN), PRAZEPAM (INN), PYROVALERONE (INN), TAMAZEPAM (INN), TETRAZEPAM (INN) AND TRIAZOLAM (INN); SALTS THEREOF"
    },
    {
      "code": "29339200",
      "description": "Azinphos-methyl (ISO)"
    },
    {
      "code": "293399",
      "description": "Other"
    },
    {
      "code": "29339900",
      "description": "HETEROCYCLIC COMPOUNDS WITH NITROGEN HETEROATOM( S) ONLY - OTHER : OTHER"
    },
    {
      "code": "29339910",
      "description": "Imidazo pyridine methyl amine"
    },
    {
      "code": "29339990",
      "description": "Other"
    },
    {
      "code": "2934",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS"
    },
    {
      "code": "29341000",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS COMPOUNDS CONTAINING AN UNFUSED THIAZOLE RING (WHETHER OR NOT HYDROGENATED) IN THE STRUCTURE"
    },
    {
      "code": "29342000",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS COMPOUNDS CONTAINING IN THE STRUCTURE A BENZOTHIAZOLE RING-SYSTEM (WHETHER OR NOT HYDROGENATED) NOT FURTHER FUSED"
    },
    {
      "code": "29343000",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS COMPOUNDS CONTAINING IN THE STRUCTURE A PHENOTHIAZINE RING-SYSTEM (WHETHER OR NOT HYDROGENATED) NOT FURTHER FUSED"
    },
    {
      "code": "29349100",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS - OTHER : AMINOREX (INN), BROTIZOLAM (INN), CLOTIAZEPAM (INN), CLAXAZOLAM (INN), HALOXAZOLAM (INN), KETAZOLAM (INN), MESOCARB (INN), OXAZOLAM (INN), PEMOLINE (INN), PHENDIMETRAZINE (INN) AND PHENMETRAZINE (INN); SALTS THEREOF"
    },
    {
      "code": "293499",
      "description": "Other"
    },
    {
      "code": "29349900",
      "description": "NUCLEIC ACIDS AND THEIR SALTS; WHETHER OR NOT CHEMICALLY DEFINED; OTHER HETEROCYCLIC COMPOUNDS - OTHER : OTHER"
    },
    {
      "code": "29349910",
      "description": "Chloro thiophene-2-carboxylic acid"
    },
    {
      "code": "29349920",
      "description": "Morpholine"
    },
    {
      "code": "29349990",
      "description": "Other"
    },
    {
      "code": "2935",
      "description": "SULPHONAMIDES"
    },
    {
      "code": "293500",
      "description": "Sulphonamides"
    },
    {
      "code": "29350011",
      "description": "SULPHONAMIDES - SULPHONAMIDES : SULPHAMETHOXAZOLE, SULPHAFURAZOLE, SULPHADIAZINE, SULPHADIMIDINE, SULPHACETAMIDE : SULPHAMETHOXAZOLE"
    },
    {
      "code": "29350012",
      "description": "SULPHONAMIDES - SULPHONAMIDES : SULPHAMETHOXAZOLE, SULPHAFURAZOLE, SULPHADIAZINE, SULPHADIMIDINE, SULPHACETAMIDE : SULPHAFURAZOLE"
    },
    {
      "code": "29350013",
      "description": "SULPHONAMIDES - SULPHONAMIDES : SULPHAMETHOXAZOLE, SULPHAFURAZOLE, SULPHADIAZINE, SULPHADIMIDINE, SULPHACETAMIDE : SULPHADIAZINE"
    },
    {
      "code": "29350014",
      "description": "SULPHONAMIDES - SULPHONAMIDES : SULPHAMETHOXAZOLE, SULPHAFURAZOLE, SULPHADIAZINE, SULPHADIMIDINE, SULPHACETAMIDE : SULPHADIMIDINE"
    },
    {
      "code": "29350015",
      "description": "SULPHONAMIDES - SULPHONAMIDES : SULPHAMETHOXAZOLE, SULPHAFURAZOLE, SULPHADIAZINE, SULPHADIMIDINE, SULPHACETAMIDE : SULPHACETAMIDE"
    },
    {
      "code": "29350021",
      "description": "SULPHONAMIDES - SULPHONAMIDES :-SULPHAMETHOXYPYRIDARINE, SULPHAMETHIAZOLE, SULPHAMOXOLE, SULPHAMIDE : SULPHAMETHOXYPYRIDARINE"
    },
    {
      "code": "29350022",
      "description": "SULPHONAMIDES - SULPHONAMIDES :-SULPHAMETHOXYPYRIDARINE, SULPHAMETHIAZOLE, SULPHAMOXOLE, SULPHAMIDE : SULPHAMETHIAZOLE"
    },
    {
      "code": "29350023",
      "description": "SULPHONAMIDES - SULPHONAMIDES :-SULPHAMETHOXYPYRIDARINE, SULPHAMETHIAZOLE, SULPHAMOXOLE, SULPHAMIDE : SULPHAMOXOLE"
    },
    {
      "code": "29350024",
      "description": "SULPHONAMIDES - SULPHONAMIDES :-SULPHAMETHOXYPYRIDARINE, SULPHAMETHIAZOLE, SULPHAMOXOLE, SULPHAMIDE : SULPHAMIDE"
    },
    {
      "code": "29350090",
      "description": "SULPHONAMIDES - SULPHONAMIDES : OTHER"
    },
    {
      "code": "29351000",
      "description": "N-Methylperfluorooctane sulphonamide"
    },
    {
      "code": "29352000",
      "description": "N-Ethylperfluorooctane sulphonamide"
    },
    {
      "code": "29353000",
      "description": "N-Ethyl-N-(2-hydroxyethyl) perfluorooctane sulphonamide"
    },
    {
      "code": "29354000",
      "description": "N-(2-Hydroxyethyl)-N-methylperfluorooctane sulphonamide"
    },
    {
      "code": "29355000",
      "description": "Other perfluorooctane sulphonamides"
    },
    {
      "code": "29359011",
      "description": "Sulphamethoxazole"
    },
    {
      "code": "29359012",
      "description": "Sulphafurazole"
    },
    {
      "code": "29359013",
      "description": "Sulphadiazine"
    },
    {
      "code": "29359014",
      "description": "Sulphadimidine"
    },
    {
      "code": "29359015",
      "description": "Sulphacetamide"
    },
    {
      "code": "29359021",
      "description": "Sulphamethoxypyridarine"
    },
    {
      "code": "29359022",
      "description": "Sulphamethoxypyridarine"
    },
    {
      "code": "29359023",
      "description": "Sulphamoxole"
    },
    {
      "code": "29359024",
      "description": "Sulphamide"
    },
    {
      "code": "29359090",
      "description": "Other"
    },
    {
      "code": "2936",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - Vitamins and their derivatives, unmixed :"
    },
    {
      "code": "29361000",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - PROVITAMINS, UNMIXED"
    },
    {
      "code": "29362100",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : VITAMIN A AND THEIR DERIVATIVES"
    },
    {
      "code": "293622",
      "description": "Vitamin B1 and its derivatives"
    },
    {
      "code": "29362210",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B1 AND ITS DERIVATIVES : VITAMIN B1 [THIAMINE (INN), ANEURINE] AND ITS SALTS"
    },
    {
      "code": "29362290",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B1 AND ITS DERIVATIVES : OTHER"
    },
    {
      "code": "293623",
      "description": "Vitamin B2 and its derivatives"
    },
    {
      "code": "29362310",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B2 AND ITS DERIVATIVES : VITAMIN B2 [RIBOFLAVIN(INN), LACTOFLAVIN] AND ITS SALTS"
    },
    {
      "code": "29362390",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B2 AND ITS DERIVATIVES : OTHER"
    },
    {
      "code": "29362400",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : D- OR DL-PANTOTHENIC ACID (VITAMIN B3 OR VITAMIN B5) AND ITS DERIVATIVES"
    },
    {
      "code": "29362500",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : VITAMIN B6 AND ITS DERIVATIVES"
    },
    {
      "code": "293626",
      "description": "Vitamin B12 and its derivatives"
    },
    {
      "code": "29362610",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B12 AND ITS DERIVATIVES : VITAMIN B12 (CYANOCOBALAMIN (INN))"
    },
    {
      "code": "29362690",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED :-VITAMIN B12 AND ITS DERIVATIVES : OTHER"
    },
    {
      "code": "29362700",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : VITAMIN C (ASCORBIC ACID) AND ITS DERIVATIVES"
    },
    {
      "code": "29362800",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : VITAMIN E AND ITS DERIVATIVES"
    },
    {
      "code": "293629",
      "description": "Other vitamins and their derivatives"
    },
    {
      "code": "29362910",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : FOLIC ACID (VITAMIN B9)"
    },
    {
      "code": "29362920",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : NICOTINIC ACID AND NICOTINAMIDE (NIACINAMIDE OR NIACINE)"
    },
    {
      "code": "29362930",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : VITAMIN K (MENAPHTHONUM BP)"
    },
    {
      "code": "29362940",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : VITAMIN D"
    },
    {
      "code": "29362950",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : VITAMIN H (BIOTIN)"
    },
    {
      "code": "29362990",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT - VITAMINS AND THEIR DERIVATIVES, UNMIXED : OTHER VITAMINS AND THEIR DERIVATIVES : OTHER"
    },
    {
      "code": "29369000",
      "description": "PROVITAMINS AND VITAMINS, NATURAL OR REPRODUCED BY SYNTHESIS (INCLUDING NATURAL CONCENTRATES), DERIVATIVES THEREOF USED PRIMARILY AS VITAMINS, AND INTERMIXTURES OF THE FOREGOING, WHETHER OR NOT IN ANY SOLVENT -OTHER, INCLUDING NATURAL CONCENTRATES"
    },
    {
      "code": "2937",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - Polypeptide hormones, protein hormones and glycoprotein hormones, their dervatives and structural analogues :"
    },
    {
      "code": "29371100",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - POLYPEPTIDE HORMONES, PROTEIN HORMONES AND GLYCOPROTEIN HORMONES, THEIR DERVATIVES AND STRUCTURAL ANALOGUES : SOMATOTROPIN, ITS DERIVATIVES AND STRUCTURAL ANALOGUES"
    },
    {
      "code": "29371200",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - POLYPEPTIDE HORMONES, PROTEIN HORMONES AND GLYCOPROTEIN HORMONES, THEIR DERVATIVES AND STRUCTURAL ANALOGUES : INSULIN AND ITS SALTS"
    },
    {
      "code": "29371900",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - POLYPEPTIDE HORMONES, PROTEIN HORMONES AND GLYCOPROTEIN HORMONES, THEIR DERVATIVES AND STRUCTURAL ANALOGUES : OTHER"
    },
    {
      "code": "29372100",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - STEROIDAL HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES : CORTISONE, HYDROCORTISONE, PREDNISONE, (DEHYDROCORTISONE) AND PREDNISOLONE (DEHYDROHYDROCORTISONE)"
    },
    {
      "code": "29372200",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - STEROIDAL HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES : HALOGENATED DERIVATIVES OF CORTICOSTEROIDAL HARMONES"
    },
    {
      "code": "29372300",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - STEROIDAL HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES : OESTROGENS AND PROGESTOGENS"
    },
    {
      "code": "29372900",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - STEROIDAL HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES : OTHER"
    },
    {
      "code": "29373100",
      "description": "Epinephrine"
    },
    {
      "code": "29375000",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES - PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES"
    },
    {
      "code": "293790",
      "description": "Other Catecholamine harmones, their derivatives and structural analogues"
    },
    {
      "code": "29379011",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES -OTHER---CATECHOLAMINE HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES----EPINETHRINE"
    },
    {
      "code": "29379019",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES -OTHER---CATECHOLAMINE HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES----OTHER"
    },
    {
      "code": "29379020",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES -OTHER---CATECHOLAMINE HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES----AMINO-ACID DERIVATIVES"
    },
    {
      "code": "29379090",
      "description": "HORMONES, PROSTAGLANDINS, THROMBOXANES AND LEUKOTRIENES, NATURAL OR REPRODUCED BY SYNTHESIS; DERIVATIVES AND STRUCTURAL ANALOGUES THEREOF, INCLUDING CHAIN MODIFIED POLYPEPTIDES, USED PRIMARILY AS HORMONES -OTHER---CATECHOLAMINE HORMONES, THEIR DERIVATIVES AND STRUCTURAL ANALOGUES----OTHER"
    },
    {
      "code": "2938",
      "description": "GLYCOSIDES, NATURAL OR REPRODUCED BY SYNTHESIS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES"
    },
    {
      "code": "29381000",
      "description": "GLYCOSIDES, NATURAL OR REPRODUCED BY SYNTHESIS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES -RUTOSIDE (RUTIN) AND ITS DERIVATIVES"
    },
    {
      "code": "293890",
      "description": "Other"
    },
    {
      "code": "29389010",
      "description": "GLYCOSIDES, NATURAL OR REPRODUCED BY SYNTHESIS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : DIGOXIN"
    },
    {
      "code": "29389020",
      "description": "GLYCOSIDES, NATURAL OR REPRODUCED BY SYNTHESIS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : DIGITALIS GLYCOSIDES"
    },
    {
      "code": "29389090",
      "description": "GLYCOSIDES, NATURAL OR REPRODUCED BY SYNTHESIS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : OTHER"
    },
    {
      "code": "2939",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - Alkaloids of opium and their derivatives; salts thereof :"
    },
    {
      "code": "29391100",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF OPIUM AND THEIR DERIVATIVES; SALTS THEREOF : BUPRENORPHINE (INN), AND SALTS THEREOF"
    },
    {
      "code": "29391900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF OPIUM AND THEIR DERIVATIVES; SALTS THEREOF : -- OTHER"
    },
    {
      "code": "293920",
      "description": "Alkaloid of cinchona and their derivatives; salts thereof"
    },
    {
      "code": "29392010",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : --- QUININE ALKALOIDS"
    },
    {
      "code": "29392020",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : --- QUININE HYDROCHLORIDE"
    },
    {
      "code": "29392030",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : --- QUININE SULPHATE"
    },
    {
      "code": "29392040",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : --- CHLOROQUINE PHOSPHATE"
    },
    {
      "code": "29392090",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : --- OTHER"
    },
    {
      "code": "29392110",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF :-QUININE AND ITS SALTS : QUININE ALKALOIDS"
    },
    {
      "code": "29392120",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF :-QUININE AND ITS SALTS : QUININE HYDROCHLORIDE"
    },
    {
      "code": "29392130",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF :-QUININE AND ITS SALTS : QUININE SULPHATE"
    },
    {
      "code": "29392140",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF :-QUININE AND ITS SALTS : CHLOROQUINE PHOSPHATE"
    },
    {
      "code": "29392190",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF :-QUININE AND ITS SALTS : OTHER"
    },
    {
      "code": "29392900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF CINCHONA AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29392910",
      "description": "BENZENEACETIC ACID, ALPHA-HYDROXY-ALPHA-PHENYL, 1-AZABICYCLO"
    },
    {
      "code": "29392990",
      "description": "OTHER"
    },
    {
      "code": "29393000",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - CAFFEINE AND ITS SALTS"
    },
    {
      "code": "293941",
      "description": "Ephedrine and its salts"
    },
    {
      "code": "29394110",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS :-EPHEDRINE AND ITS SALTS : EPHEDRINE ALKALOIDS"
    },
    {
      "code": "29394120",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS :-EPHEDRINE AND ITS SALTS : EPHEDRINE HYDROCHLORIDE"
    },
    {
      "code": "29394190",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS :-EPHEDRINE AND ITS SALTS : OTHER"
    },
    {
      "code": "29394200",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS : PSEUDOEPHEDRINE (INN) AND ITS SALTS"
    },
    {
      "code": "29394300",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS : CATHINE (INN) AND ITS SALTS"
    },
    {
      "code": "293944",
      "description": "Norephedirine and its salts"
    },
    {
      "code": "29394400",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS --NOREPHEDRINE AND ITS SALTS"
    },
    {
      "code": "29394900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - EPHEDRINES AND THEIR SALTS : OTHER"
    },
    {
      "code": "29395100",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - THEOPHYLLINE AND AMINOPHYLLINE(THEOPHYLLINEETHYLENEDIAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : FENETYLLINE (INN) AND ITS SALTS"
    },
    {
      "code": "29395900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - THEOPHYLLINE AND AMINOPHYLLINE(THEOPHYLLINEETHYLENEDIAMINE) AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "293961",
      "description": "Ergometrine (INN) and its salts"
    },
    {
      "code": "29396110",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF :-ERGOMETRINE (INN) AND ITS SALTS : ERGOMETRINE (INN)"
    },
    {
      "code": "29396190",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF :-ERGOMETRINE (INN) AND ITS SALTS : OTHER"
    },
    {
      "code": "293962",
      "description": "Ergotamine (INN) and its salts"
    },
    {
      "code": "29396210",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF : ERGOTAMINE (INN) AND ITS SALTS : ERGOTAMINE TARTRATE"
    },
    {
      "code": "29396290",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF : ERGOTAMINE (INN) AND ITS SALTS : OTHER"
    },
    {
      "code": "29396300",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF : LYSERGIC ACID AND ITS SALTS"
    },
    {
      "code": "29396900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - ALKALOIDS OF RYE ERGOT AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "29397100",
      "description": "Cocaine, ecgonine, levometamfetamine, metamfetamine (INN), metamfetamine racemate; salts, esters and other derivatives thereof"
    },
    {
      "code": "293979",
      "description": "Other"
    },
    {
      "code": "29397910",
      "description": "Nicotine"
    },
    {
      "code": "29397990",
      "description": "Other"
    },
    {
      "code": "29398000",
      "description": "Other"
    },
    {
      "code": "29399100",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : LEVOMETAMFETAMINE, METAMFETAMINE(INN), METAMFETAMINE RACEMATE; SALTS, ESTERS AND OTHER DERIVATIVES THEREOF"
    },
    {
      "code": "29399900",
      "description": "VEGETABLE ALKALOIDS, NATURAL OR REPRODUCED BY SYNTHESIS, AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : OTHER"
    },
    {
      "code": "2940",
      "description": "SUGARS, CHEMICALLY PURE, OTHER THAN SUCROSE, LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE; SUGAR ETHERS, SUGAR ACETALS AND SUGAR ESTERS, AND THEIR SALTS, OTHER THAN PRODUCTS OF HEADINGS 2937, 2938 OR 2939"
    },
    {
      "code": "29400000",
      "description": "SUGARS, CHEMICALLY PURE, OTHER THAN SUCROSE, LACTOSE, MALTOSE, GLUCOSE AND FRUCTOSE; SUGAR ETHERS, SUGAR ACETALS AND SUGAR ESTERS, AND THEIR SALTS, OTHER THAN PRODUCTS OF HEADINGS 2937, 2938 OR 2939"
    },
    {
      "code": "2941",
      "description": "ANTIBIOTICS"
    },
    {
      "code": "294110",
      "description": "Penicillins and their derivative with a penicillanic acid structure; salts thereof"
    },
    {
      "code": "29411010",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : PENICILLINS AND ITS SALTS ( E.G. PROCAINE PENICILLIN, PENICILLIN G-POTASSIUM)"
    },
    {
      "code": "29411020",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : AMPICILLINE AND ITS SALTS"
    },
    {
      "code": "29411030",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : AMOXYCILLINE AND ITS SALTS"
    },
    {
      "code": "29411040",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : CIOXACILLINE AND ITS SALTS"
    },
    {
      "code": "29411050",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : 6-APA"
    },
    {
      "code": "29411090",
      "description": "ANTIBIOTICS - PENICILLINS AND THEIR DERIVATIVE WITH A PENICILLANIC ACID STRUCTURE; SALTS THEREOF : OTHER"
    },
    {
      "code": "294120",
      "description": "Streptomycins and their derivatives; salts thereof"
    },
    {
      "code": "29412010",
      "description": "ANTIBIOTICS - STREPTOMYCINS AND THEIR DERIVATIVES; SALTS THEREOF : STREPTOMYCIN"
    },
    {
      "code": "29412090",
      "description": "ANTIBIOTICS - STREPTOMYCINS AND THEIR DERIVATIVES; SALTS THEREOF : OTHER"
    },
    {
      "code": "294130",
      "description": "Tetracyclines and their derivatives, salts thereof"
    },
    {
      "code": "29413010",
      "description": "ANTIBIOTICS - TETRACYCLINES AND THEIR DERIVATIVES, SALTS THEREOF : DOXYCYCLINE AND ITS SALTS"
    },
    {
      "code": "29413020",
      "description": "ANTIBIOTICS - TETRACYCLINES AND THEIR DERIVATIVES, SALTS THEREOF : TETRACYCLINE/OXYTETRA-CYCLINE AND THEIR SALTS"
    },
    {
      "code": "29413090",
      "description": "ANTIBIOTICS - TETRACYCLINES AND THEIR DERIVATIVES, SALTS THEREOF : OTHER"
    },
    {
      "code": "29414000",
      "description": "ANTIBIOTICS - CHLORAMPHENICOL AND ITS DERIVATIVES; SALTS THEREOF"
    },
    {
      "code": "29415000",
      "description": "ANTIBIOTICS - ERYTHROMYCIN AND ITS DERIVATIVES; SALTS THEREOF"
    },
    {
      "code": "294190",
      "description": "Other"
    },
    {
      "code": "29419011",
      "description": "ANTIBIOTICS - OTHER : RIFAMPICIN AND ITS SALTS : RIFAMPICIN"
    },
    {
      "code": "29419012",
      "description": "ANTIBIOTICS - OTHER : RIFAMPICIN AND ITS SALTS : 3 FORMYL RIFA S V(RIFA INT)"
    },
    {
      "code": "29419013",
      "description": "ANTIBIOTICS - OTHER : RIFAMPICIN AND ITS SALTS : RIFA S OR RIFA S SODIUM (RIFAINT)"
    },
    {
      "code": "29419014",
      "description": "ANTIBIOTICS - OTHER : RIFAMPICIN AND ITS SALTS : 1-AMINO-4-METHYL PIPERAZINE (RIFAINT)"
    },
    {
      "code": "29419019",
      "description": "ANTIBIOTICS - OTHER : RIFAMPICIN AND ITS SALTS : OTHER"
    },
    {
      "code": "29419020",
      "description": "ANTIBIOTICS - OTHER : CEPHALEXIN AND ITS SALTS"
    },
    {
      "code": "29419030",
      "description": "ANTIBIOTICS - OTHER : CIPROFLOXACINE AND ITS SALTS"
    },
    {
      "code": "29419040",
      "description": "ANTIBIOTICS - OTHER : GENTAMYCIN AND ITS SALTS"
    },
    {
      "code": "29419050",
      "description": "ANTIBIOTICS - OTHER : NEOMYCIN"
    },
    {
      "code": "29419060",
      "description": "ANTIBIOTICS - OTHER : NORFLOXACIN AND ITS SALTS"
    },
    {
      "code": "29419090",
      "description": "ANTIBIOTICS - OTHER : OTHER"
    },
    {
      "code": "2942",
      "description": "OTHER ORGANIC COMPOUNDS"
    },
    {
      "code": "294200",
      "description": "Other organic compounds"
    },
    {
      "code": "29420011",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : CEFADROXIL AND ITS SALTS"
    },
    {
      "code": "29420012",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : IBUPROFANE"
    },
    {
      "code": "29420013",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : NIFEDIPINE"
    },
    {
      "code": "29420014",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : RANITIDINE"
    },
    {
      "code": "29420015",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : DANES SALT OF D(-) PHENYL GLYCINE"
    },
    {
      "code": "29420016",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : CEFADROXIL AND ITS SALTS, IBUPROFANE, NIFEDIPINE, RANITIDINE, DANES SALT OF D(-) PHENYL GLYCINE, D(-) PARA HYDROXY DANES SALTS : D(-) PARA HYDROXY DANES SALTS"
    },
    {
      "code": "29420021",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : TIMOLO MALEATE"
    },
    {
      "code": "29420022",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : TERBUTOLINE SULPHATE"
    },
    {
      "code": "29420023",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : D(-) PHENYL GLYCIN CHLORIDE HCL (DPGCH)"
    },
    {
      "code": "29420024",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : IMIPRAMINE HCL"
    },
    {
      "code": "29420025",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : AMITRYPTYLINE HCL"
    },
    {
      "code": "29420026",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : CYSTEANUNE HCL"
    },
    {
      "code": "29420027",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : TIMOLO MALEATE, TERBUTOLINE SULPHATE, D(-)PHENYL GLYCIN CHLORIDE HCL (DPGCH), IMIPRAMINE HCL, AMITRYPTYLINE HCL, CYSTEANUNE HCL, ATENOLOL, PROPRONALOL : ATENOLOL, PROPRONALOL"
    },
    {
      "code": "29420031",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : DILOXANIDE FUROATE, CIMETIDINE, OXYCLOZANIDE, FAMOTIDINE : DILOXANIDE FUROATE"
    },
    {
      "code": "29420032",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : DILOXANIDE FUROATE, CIMETIDINE, OXYCLOZANIDE, FAMOTIDINE : CIMETIDINE"
    },
    {
      "code": "29420033",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : DILOXANIDE FUROATE, CIMETIDINE, OXYCLOZANIDE, FAMOTIDINE : OXYCLOZANIDE"
    },
    {
      "code": "29420034",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : DILOXANIDE FUROATE, CIMETIDINE, OXYCLOZANIDE, FAMOTIDINE : FAMOTIDINE"
    },
    {
      "code": "29420090",
      "description": "OTHER ORGANIC COMPOUNDS - OTHER ORGANIC COMPOUNDS : OTHER"
    },
    {
      "code": "30",
      "description": "Pharmaceutical products"
    },
    {
      "code": "3001",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "30011010",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED GLANDS AND OTHER ORGANS, DRIED, WHETHER OR NOT POWDERED : PANCREATIN AND DRIED POWDER OF PANCREAS"
    },
    {
      "code": "30011091",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED GLANDS AND OTHER ORGANS, DRIED, WHETHER OR NOT POWDERED : OTHER : POWDERED"
    },
    {
      "code": "30011099",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED GLANDS AND OTHER ORGANS, DRIED, WHETHER OR NOT POWDERED : OTHER : OTHER"
    },
    {
      "code": "300120",
      "description": "Extracts of glands or other organs or of their secretions"
    },
    {
      "code": "30012010",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED - EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS: LIQUID EXTRACTS OF LIVER"
    },
    {
      "code": "30012020",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED - EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS: LIVER EXTRACTS, DRY"
    },
    {
      "code": "30012030",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED - EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS: SNAKE VENOM"
    },
    {
      "code": "30012090",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED - EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS: OTHER"
    },
    {
      "code": "300190",
      "description": "Other"
    },
    {
      "code": "30019010",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OF HUMAN ORIGIN"
    },
    {
      "code": "30019091",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : HEPARIN AND ITS SALTS"
    },
    {
      "code": "30019099",
      "description": "GLANDS AND OTHER ORGANS FOR ORGANO-THERAPEUTIC USES, DRIED, WHETHER OR NOT POWDERED; EXTRACTS OF GLANDS OR OTHER ORGANS OR OF THEIR SECRETIONS FOR ORGANO-THERAPEUTIC USES; HEPARIN AND ITS SALTS; OTHER HUMAN OR ANIMAL SUBSTANCES PREPARED FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER : OTHER : OTHER"
    },
    {
      "code": "3002",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA, OTHER BLOOD FRACTIONS AND IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT MODIFIED OR OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICROORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS"
    },
    {
      "code": "300210",
      "description": "Antisera, other blood fractions and immunological products, whether or not modified or obtained by biotechnological processes"
    },
    {
      "code": "30021011",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : ANTISERA : FOR DIPTHERIA"
    },
    {
      "code": "30021012",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : ANTISERA : FOR TETANUS"
    },
    {
      "code": "30021013",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : ANTISERA : FOR RABIES"
    },
    {
      "code": "30021014",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : ANTISERA : FOR SNAKE VENOM"
    },
    {
      "code": "30021019",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : ANTISERA : OTHER"
    },
    {
      "code": "30021020",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : HEMOGLOBIN BLOOD GLOBULINS AND SERUM GLOBULINS"
    },
    {
      "code": "30021091",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : OTHER : OF HUMAN ORIGIN"
    },
    {
      "code": "30021099",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES : OTHER : OTHER"
    },
    {
      "code": "30021100",
      "description": "Malaria diagnostic test kits"
    },
    {
      "code": "300212",
      "description": "Antisera and other blood fractions"
    },
    {
      "code": "30021210",
      "description": "For diphtheria"
    },
    {
      "code": "30021220",
      "description": "For tetanus"
    },
    {
      "code": "30021230",
      "description": "For rabies"
    },
    {
      "code": "30021240",
      "description": "For snake venom"
    },
    {
      "code": "30021290",
      "description": "Other"
    },
    {
      "code": "300213",
      "description": "Immunological products, unmixed, not put up in measured doses or in forms or packings for retail sale"
    },
    {
      "code": "30021310",
      "description": "Immunological products, unmixed, not put up in measured doses or in forms or packings for retail sale"
    },
    {
      "code": "300214",
      "description": "Immunological products, mixed, not put up in measured doses or in forms or packings for retail sale"
    },
    {
      "code": "30021410",
      "description": "Immunological products, mixed, not put up in measured doses or in forms or packings for retail sale"
    },
    {
      "code": "30021500",
      "description": "Immunological products, put up in measured doses or in forms or packings for retail sale"
    },
    {
      "code": "30021900",
      "description": "Other"
    },
    {
      "code": "300220",
      "description": "Vaccines for human medicine"
    },
    {
      "code": "30022011",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR CHOLERA AND TYPHOID"
    },
    {
      "code": "30022012",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR HEPATITIS"
    },
    {
      "code": "30022013",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR TETANUS"
    },
    {
      "code": "30022014",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR POLIO"
    },
    {
      "code": "30022015",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR TUBERCULOSIS"
    },
    {
      "code": "30022016",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR RABIES"
    },
    {
      "code": "30022017",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR JAPANESE ENCEPHALITIS"
    },
    {
      "code": "30022018",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : FOR WHOPPING COUGH (PERTUSIS)"
    },
    {
      "code": "30022019",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE : SINGLE VACCINES : OTHER"
    },
    {
      "code": "30022021",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE MIXED VACCINES : FOR DIPHTHERIA, PERTUSIS AND TETANUS (DPT)"
    },
    {
      "code": "30022022",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE MIXED VACCINES : FOR DIPHTHERIA AND TETANUS (DT)"
    },
    {
      "code": "30022023",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE MIXED VACCINES : FOR MEASLES, MUMPS AND RUBELLA (MMR)"
    },
    {
      "code": "30022024",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE MIXED VACCINES : FOR TYPHOID-PARATYPHOID (TAB) OR TYPHOID- PARATYPHOID-CHOLERA (TABC)"
    },
    {
      "code": "30022029",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR HUMAN MEDICINE MIXED VACCINES : OTHER"
    },
    {
      "code": "30023000",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS VACCINES FOR VETERINARY MEDICINE"
    },
    {
      "code": "300290",
      "description": "Other"
    },
    {
      "code": "30029010",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS OTHER : HUMAN BLOOD"
    },
    {
      "code": "30029020",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS OTHER : ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES"
    },
    {
      "code": "30029030",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS OTHER : CULTURES OF MICRO-ORGANISMS (EXCLUDING YEAST)"
    },
    {
      "code": "30029040",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS OTHER : TOXINS"
    },
    {
      "code": "30029090",
      "description": "HUMAN BLOOD; ANIMAL BLOOD PREPARED FOR THERAPEUTIC, PROPHYLACTIC OR DIAGNOSTIC USES; ANTISERA AND OTHER BLOOD FRACTIONS AND MODIFIED IMMUNOLOGICAL PRODUCTS, WHETHER OR NOT OBTAINED BY MEANS OF BIOTECHNOLOGICAL PROCESSES; VACCINES, TOXINS, CULTURES OF MICRO-ORGANISMS (EXCLUDING YEASTS) AND SIMILAR PRODUCTS OTHER : OTHER"
    },
    {
      "code": "3003",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE"
    },
    {
      "code": "30031000",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES"
    },
    {
      "code": "30032000",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS"
    },
    {
      "code": "30033100",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : CONTAINING INSULIN"
    },
    {
      "code": "30033900",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING OTHER"
    },
    {
      "code": "30034000",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS"
    },
    {
      "code": "30034100",
      "description": "Containing ephedrine or its salts"
    },
    {
      "code": "30034200",
      "description": "Containing pseudoephedrine (INN) or its salts"
    },
    {
      "code": "30034300",
      "description": "Containing norephedrine or its salts"
    },
    {
      "code": "30034900",
      "description": "Other"
    },
    {
      "code": "30036000",
      "description": "Other, containing antimalarial active principles described in Sub-heading Note 2 to this Chapter"
    },
    {
      "code": "300390",
      "description": "Other"
    },
    {
      "code": "30039011",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, SIDDHA, HOMOEOPATHIC OR BIO-CHEMIC SYSTEMS MEDICAMENTS : OF AYURVEDIC SYSTEM"
    },
    {
      "code": "30039012",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, SIDDHA, HOMOEOPATHIC OR BIO-CHEMIC SYSTEMS MEDICAMENTS : OF UNANI SYSTEM"
    },
    {
      "code": "30039013",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, SIDDHA, HOMOEOPATHIC OR BIO-CHEMIC SYSTEMS MEDICAMENTS : OF SIDDHA SYSTEM"
    },
    {
      "code": "30039014",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, SIDDHA, HOMOEOPATHIC OR BIO-CHEMIC SYSTEMS MEDICAMENTS : OF HOMOEOPATHIC SYSTEM"
    },
    {
      "code": "30039015",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, SIDDHA, HOMOEOPATHIC OR BIO-CHEMIC SYSTEMS MEDICAMENTS : OF BIO-CHEMIC SYSTEM"
    },
    {
      "code": "30039021",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : MENTHOL CRYSTALS AND MILK OF MAGNESIA : MENTHOL CRYSTALS"
    },
    {
      "code": "30039022",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALEOTHER : MENTHOL CRYSTALS AND MILK OF MAGNESIA : MILK OF MAGNESIA"
    },
    {
      "code": "30039031",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN"
    },
    {
      "code": "30039032",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME)"
    },
    {
      "code": "30039033",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : CALCIUM SENNOSIDE"
    },
    {
      "code": "30039034",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY"
    },
    {
      "code": "30039035",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : ALUMINIUM HYDROXIDE GEL"
    },
    {
      "code": "30039036",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : --- BOVINE ALBUMIN AND DRUGS OF ANIMAL ORIGIN, MERBROMINE NATIONAL FORMULARY XII (MERCUROCHROME), CALCIUM SENNOSIDE, ANAESTHETIC AGENTS USED IN HUMAN OR VETERINARY MEDICINE OR SURGERY, ALUMINIUM HYDROXIDE GEL : ---- KETAMINE"
    },
    {
      "code": "30039090",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF TWO OR MORE CONSTITUENTS WHICH HAVE BEEN MIXED TOGETHER FOR THERAPEUTIC OR PROPHYLACTIC USES, NOT PUT UP IN MEASURED DOSES OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER"
    },
    {
      "code": "3004",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE"
    },
    {
      "code": "300410",
      "description": "Containing penicillins or derivatives thereof, with a penicillanic acid structure, or streptomycins or their derivatives"
    },
    {
      "code": "30041010",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES : PENICILLINS"
    },
    {
      "code": "30041020",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES AMPICILLIN"
    },
    {
      "code": "30041030",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES AMOXYCILLIN"
    },
    {
      "code": "30041040",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES BECAMPICILLIN"
    },
    {
      "code": "30041050",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES CLOXACILLIN"
    },
    {
      "code": "30041060",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES AMPICILLIN AND CLOXACILLIN COMBINATIONS"
    },
    {
      "code": "30041070",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES STREPTOMYCIN"
    },
    {
      "code": "30041090",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING PENICILLINS OR DERIVATIVES THEREOF, WITH A PENICILLANIC ACID STRUCTURE, OR STREPTOMYCINS OR THEIR DERIVATIVES OTHER"
    },
    {
      "code": "300420",
      "description": "Other, containing antibiotics"
    },
    {
      "code": "30042011",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : CEPHALOSPORINS AND THEIR DERIVATIVES : CEFAZOLIN"
    },
    {
      "code": "30042012",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : CEPHALOSPORINS AND THEIR DERIVATIVES :CEPHALEXIN"
    },
    {
      "code": "30042013",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : CEPHALOSPORINS AND THEIR DERIVATIVES :CIPROFLOXACIN"
    },
    {
      "code": "30042014",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : CEPHALOSPORINS AND THEIR DERIVATIVES :CEFOXITIN"
    },
    {
      "code": "30042019",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : CEPHALOSPORINS AND THEIR DERIVATIVES : OTHER"
    },
    {
      "code": "30042020",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS : SULFONAMIDES AND COTRIMOXAZOLE"
    },
    {
      "code": "30042031",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: FLUOROQUINOLONES : NORFLOXACIN"
    },
    {
      "code": "30042032",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: FLUOROQUINOLONES : NALIDIXIC ACID"
    },
    {
      "code": "30042033",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: FLUOROQUINOLONES : CIPROFLOXACIN"
    },
    {
      "code": "30042034",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: FLUOROQUINOLONES : OFLOXACIN"
    },
    {
      "code": "30042039",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: FLUOROQUINOLONES : OTHER"
    },
    {
      "code": "30042041",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS TETRACYCLINES : CHLORTETRACYCLINE"
    },
    {
      "code": "30042042",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS TETRACYCLINES : OXYTETRACYCLINE"
    },
    {
      "code": "30042049",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS TETRACYCLINES : OTHER"
    },
    {
      "code": "30042050",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS CHLORAMPHENICOL"
    },
    {
      "code": "30042061",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : ERYTHROMYCIN"
    },
    {
      "code": "30042062",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : ROXITHROMYCIN"
    },
    {
      "code": "30042063",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : CLARITHROMYCIN"
    },
    {
      "code": "30042064",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : AZITHROMYCIN"
    },
    {
      "code": "30042069",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : OTHER"
    },
    {
      "code": "30042070",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS MACROLIDE : CEFADROXIL"
    },
    {
      "code": "30042091",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : ISONIAZID"
    },
    {
      "code": "30042092",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : RIFAMPICIN"
    },
    {
      "code": "30042093",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : PYRAZINAMIDE"
    },
    {
      "code": "30042094",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : ETHAMBUTOL"
    },
    {
      "code": "30042095",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : CLINDAMYCIN"
    },
    {
      "code": "30042096",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : VANCOMYCIN"
    },
    {
      "code": "30042097",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : POLYMYXIN B AND COLISTIN"
    },
    {
      "code": "30042099",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING OTHER ANTIBIOTICS: OTHER : OTHER"
    },
    {
      "code": "300431",
      "description": "Containing insulin"
    },
    {
      "code": "30043110",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : CONTAINING INSULIN : INSULIN INJECTION"
    },
    {
      "code": "30043190",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : CONTAINING INSULIN : OTHER"
    },
    {
      "code": "30043200",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : CONTAINING CORTICOSTEROID HORMONES, THEIR DERIVATIVES OR STRUCTURAL ANALOGUES"
    },
    {
      "code": "300439",
      "description": "Other"
    },
    {
      "code": "30043911",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : PITUITARY HORMONES; PREDNISOLONE; DEXAMETHASONE; DANAZOL; OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES: PITUITARY HORMONES"
    },
    {
      "code": "30043912",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : PITUITARY HORMONES; PREDNISOLONE; DEXAMETHASONE; DANAZOL; OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES: PREDNISOLONE"
    },
    {
      "code": "30043913",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : PITUITARY HORMONES; PREDNISOLONE; DEXAMETHASONE; DANAZOL; OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES: DEXAMETHASONE"
    },
    {
      "code": "30043914",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : PITUITARY HORMONES; PREDNISOLONE; DEXAMETHASONE; DANAZOL; OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES: DANAZOL"
    },
    {
      "code": "30043919",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : PITUITARY HORMONES; PREDNISOLONE; DEXAMETHASONE; DANAZOL; OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES: OTHER PROGESTOGEN AND OESTOGEN GROUP HORMONES"
    },
    {
      "code": "30043921",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : GONADOTROPHINS AND LUTEINISING HORMONE : GONADOTROPHINS"
    },
    {
      "code": "30043922",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : GONADOTROPHINS AND LUTEINISING HORMONE : LUTEINISING HORMONE"
    },
    {
      "code": "30043990",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING HORMONES OR OTHER PRODUCTS OF HEADING 2937 BUT NOT CONTAINING ANTIBIOTICS : OTHER : OTHER"
    },
    {
      "code": "300440",
      "description": "Containing alkaloids or derivatives thereof but not containing hormones, other products of heading 2937 or antibiotics"
    },
    {
      "code": "30044010",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS : ATROPIN AND SALTS THEREOF"
    },
    {
      "code": "30044020",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHERPRODUCTS OF HEADING 2937 OR ANTIBIOTICS CAFFEIN AND SALTS THEREOF"
    },
    {
      "code": "30044030",
      "description": "CODEINE AND ITS DERIVATIVES, WITH OR WITHOUT EPHEDRINE HYDROCHLORIDE"
    },
    {
      "code": "30044040",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS: ERGOT PREPERATIONS, ERGOTAMINE AND SALTS THEREOF"
    },
    {
      "code": "30044050",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS PAPAVERINE HYDROCHLORIDE"
    },
    {
      "code": "30044060",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS BROMOHEXIN AND SOLBUTAMOL"
    },
    {
      "code": "30044070",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS THEOPHYLLINE AND EPHEDRINE"
    },
    {
      "code": "30044090",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE CONTAINING ALKALOIDS OR DERIVATIVES THEREOF BUT NOT CONTAINING HORMONES, OTHER PRODUCTS OF HEADING 2937 OR ANTIBIOTICS OTHER"
    },
    {
      "code": "30044100",
      "description": "Containing ephedrine or its salts"
    },
    {
      "code": "30044200",
      "description": "Containing pseudoephedrine (INN) or its salts"
    },
    {
      "code": "30044300",
      "description": "Containing norephedrine or its salts"
    },
    {
      "code": "300449",
      "description": "Other"
    },
    {
      "code": "30044910",
      "description": "Atropin and salts thereof"
    },
    {
      "code": "30044920",
      "description": "Caffein and salts thereof"
    },
    {
      "code": "30044930",
      "description": "Codeine and derivatives, with or without ephidrine hydrochloride"
    },
    {
      "code": "30044940",
      "description": "Ergot preparations, ergotamine and salts thereof"
    },
    {
      "code": "30044950",
      "description": "Papavarine hydrochloride"
    },
    {
      "code": "30044960",
      "description": "Bromohexin and solbutamol"
    },
    {
      "code": "30044970",
      "description": "Theophylline and salts thereof"
    },
    {
      "code": "30044990",
      "description": "Other"
    },
    {
      "code": "300450",
      "description": "Other, containing vitamins or other products of heading 2936"
    },
    {
      "code": "30045010",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : HEAMATINICS AND ERYTHROPOIETIN PREPARATIONS"
    },
    {
      "code": "30045020",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF MINERALS AND THEIR SUPPLEMENTS"
    },
    {
      "code": "30045031",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN A"
    },
    {
      "code": "30045032",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN B1 AND B2 AND SALTS THEREOF"
    },
    {
      "code": "30045033",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN B9"
    },
    {
      "code": "30045034",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN B12"
    },
    {
      "code": "30045035",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN C"
    },
    {
      "code": "30045036",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN D"
    },
    {
      "code": "30045037",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OF VITAMIN E"
    },
    {
      "code": "30045039",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : PREPARATIONS OF VITAMINS : OTHER"
    },
    {
      "code": "30045090",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER MEDICAMENTS CONTAINING VITAMINS OR OTHER PRODUCTS OF HEADING 2936 : OTHER"
    },
    {
      "code": "30046000",
      "description": "Other, containing antimalarial active principles described in Sub-heading Note 2 to this Chapter"
    },
    {
      "code": "300490",
      "description": "Other"
    },
    {
      "code": "30049011",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, HOMOEOPATHIC, SIDDHA OR BIO-CHEMIC SYSTEMS MEDICAMENTS, PUT UP FOR RETAIL SALE : OF AYURVEDIC SYSTEM"
    },
    {
      "code": "30049012",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, HOMOEOPATHIC, SIDDHA OR BIO-CHEMIC SYSTEMS MEDICAMENTS, PUT UP FOR RETAIL SALE : OF UNANI SYSTEM"
    },
    {
      "code": "30049013",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, HOMOEOPATHIC, SIDDHA OR BIO-CHEMIC SYSTEMS MEDICAMENTS, PUT UP FOR RETAIL SALE : OF SIDDHA SYSTEM"
    },
    {
      "code": "30049014",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, HOMOEOPATHIC, SIDDHA OR BIO-CHEMIC SYSTEMS MEDICAMENTS, PUT UP FOR RETAIL SALE : OF HOMOEOPATHIC SYSTEM"
    },
    {
      "code": "30049015",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : AYURVEDIC, UNANI, HOMOEOPATHIC, SIDDHA OR BIO-CHEMIC SYSTEMS MEDICAMENTS, PUT UP FOR RETAIL SALE : OF BIO-CHEMIC SYSTEM"
    },
    {
      "code": "30049021",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : ANTHELMINTICS AND PREPARATIONS THEREOF"
    },
    {
      "code": "30049022",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : METRONIDAZOLE"
    },
    {
      "code": "30049023",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : TINIDAZOLE"
    },
    {
      "code": "30049024",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : SECNIDAZOLE"
    },
    {
      "code": "30049025",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : DILUXAMIDE FUROATE"
    },
    {
      "code": "30049026",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : SODIUM STIBOGLUCONATE"
    },
    {
      "code": "30049027",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : PENTAMIDINE"
    },
    {
      "code": "30049029",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTHELMINTICS DRUGS; ANTIAMOEBIC AND OTHER ANTIPROTOZAL DRUGS; ANTIFUNGAL DRUGS : OTHER"
    },
    {
      "code": "30049031",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS : PROMETHAZINE, CHLORPHENIRAMINE, ASTEMIZOLE AND CETEIRIZINE"
    },
    {
      "code": "30049032",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS : SODIUM BICARBONATE, MAGNESIUM HYDROXIDE (MILK OF MAGNESIA), MAGNESIUM CARBONATE, MAGNESIUM TRISILICATE, ALUMINIUM HYDROXIDE GEL, MAGALDARATE AND COMBINATIONS THEREOF"
    },
    {
      "code": "30049033",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS CIMETIDINE, RANTIDINE, NIZATIDINE AND ROXATIDINE"
    },
    {
      "code": "30049034",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS OMEPRAZOLE AND LANSOPRAZOLE"
    },
    {
      "code": "30049035",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS DICYCLOMINE, METOCLOPRAMIDE AND DEXAMETHASONE AND ONDANSETRON"
    },
    {
      "code": "30049036",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS CHENODIOL AND URSODIOL"
    },
    {
      "code": "30049039",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHISTAMINICS DRUGS; ANTACIDS PREPARATIONS; ANTIULCER DRUGS; ANTIEMITICS AND OTHER GASTROINTESTINAL DRUGS OTHER"
    },
    {
      "code": "30049041",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : CYCLOPHOSPHAMIDE"
    },
    {
      "code": "30049042",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : METHOTREXATE, 5-FLUOROURACIL(5-FU) AND FTORAFUR"
    },
    {
      "code": "30049043",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : BINCRISTINE AND VINBLASTINE"
    },
    {
      "code": "30049044",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : PACLITAXEL AND DOCETAXEL"
    },
    {
      "code": "30049045",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : ETOPOSIDE"
    },
    {
      "code": "30049046",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : ACTINOMYCIN D DACTINOMYCIN AND DOXORUBICIN"
    },
    {
      "code": "30049047",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : L-ASPARAGINASE, CISPLATIN AND CARBOPLATIN"
    },
    {
      "code": "30049048",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : TAMOXIFEN"
    },
    {
      "code": "30049049",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTICANCER DRUGS : OTHER"
    },
    {
      "code": "30049051",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS : ISONIAZID"
    },
    {
      "code": "30049052",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS :RIFAMPICIN"
    },
    {
      "code": "30049053",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS :PYRAZINAMIDE AND ETHAMBUTOL"
    },
    {
      "code": "30049054",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS :STREPTOMYCIN"
    },
    {
      "code": "30049055",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS : DAPSONE (DDS),ACEDAPSONE (DADDS), SOLOPSONE AND CLOFAZIMINE"
    },
    {
      "code": "30049056",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS :CHLOROQUINE, AMODIAQUINE, MEFLOQUINE, QUININE, CHLOROGUAMIDE, PYRIMETHAMINE"
    },
    {
      "code": "30049057",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS : OTHER ANTITUBERCULAR DRUGS"
    },
    {
      "code": "30049058",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS : OTHER ANTILEPROTIC DRUGS"
    },
    {
      "code": "30049059",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTITUBERCULAR DRUGS; ANTILEPROTIC DRUGS; ANTIMALARIAL DRUGS : OTHER ANTIMALARIAL DRUGS"
    },
    {
      "code": "30049061",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : ANALGIN WITH OR WITHOUT OTHER COMPOUNDS SUCH AS PARACETAMOL"
    },
    {
      "code": "30049062",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : ACETYL SALICYLIC ACID (ASPIRIN) AND FORMULATIONS THEREOF"
    },
    {
      "code": "30049063",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : IBUPROFEN WITH OR WITHOUT PARACETAMOL OR OTHER COMPOUNDS"
    },
    {
      "code": "30049064",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : OXYPHEN BUTAZONE, PHENYL BUTAZONE AND FORMULATIONS THEREOF"
    },
    {
      "code": "30049065",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : INDOMETHACIN"
    },
    {
      "code": "30049066",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : MEPHENAMIC ACID, DACTOFENAC SODIUM, PIROXICAM, TENOXICAM AND MELOXICAM"
    },
    {
      "code": "30049067",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : KETOROLAC, NIMESULIDE, NABUMETONE AND NEFOPAM"
    },
    {
      "code": "30049069",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : NONSTEROIDAL ANTIINFLAMMATORY, ANALGESICS AND ANTIPYRATIC DRUGS : OTHER"
    },
    {
      "code": "30049071",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS :CAPTOPRIL, ENALAPRIL, LISINOPRIL, PERINDOPRIL AND RAMIPRIL"
    },
    {
      "code": "30049072",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS : VERAPAMIL, NIFEDIPINE, AMLODIPINE AND LACIDIPINE"
    },
    {
      "code": "30049073",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS : LOSARTAN"
    },
    {
      "code": "30049074",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS :PROPRANOLOL, METOPROLOL, ATENOLOL AND LABETALOL"
    },
    {
      "code": "30049075",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS :PRAZOSIN, TERAZOSIN, PHENTOLAMINE AND PHENOXYBENZAMINE"
    },
    {
      "code": "30049076",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS :CLONIDINE, METHYLDOPA"
    },
    {
      "code": "30049077",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS :HYDRALAZINE, MINOXIDIL AND DIAZOXIDE"
    },
    {
      "code": "30049079",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIHYPERTENSIVE DRUGS : OTHER"
    },
    {
      "code": "30049081",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : PHENOBARBITONE, MEPHOBARBITONE, PRIMIDONE, PHENYTOIN, CARBAMAZEPINE, ETHOSUCCIMIDE, VALPORIC ACID ( SODIUM VALPORATE), DIAZEPAM, LAMOTRIGINE, GABAPENTIN, BIGABATRIN, PHENACEMIDE, TRIMETHADIONE AND ACETAZOLAMIDE"
    },
    {
      "code": "30049082",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : OTHER ANTIEPILEPTIC DRUGS"
    },
    {
      "code": "30049083",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : SULPHA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "30049084",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : PREPARATIONS OF ENZYMES"
    },
    {
      "code": "30049085",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "30049086",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : ORAL REHYDRATION SALTS"
    },
    {
      "code": "30049087",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : ANTIBACTERIAL FORMULATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "30049088",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : SEDATIVES"
    },
    {
      "code": "30049089",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : ANTIEPILIPTIC DRUGS; SULFA DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARATIONS OF ENZYMES; VETERINARY MEDICINAL PREPARATIONS, NOT FOR HUMAN USE,NOT ELSEWHERE SPECIFIED OR INCLUDED; ORAL REHYDRATION SALTS; ANTIBACTERIALFORMULATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED, SEDATIVES AND TRANQUILIZERS : TRANQUILIZERS"
    },
    {
      "code": "30049091",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : SALBUTAMOL, TERBUTALINE, EPHEDRINE, SALMETEROL AND METHYL XANTHIMES"
    },
    {
      "code": "30049092",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : PLASMA EXPANDERS"
    },
    {
      "code": "30049093",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : CHLOROPHENIRAMINE MALEATE,WITH OR WITHOUT OTHER COMPOUNDS (EXCLUDING STERIODS AND ALKALOIDS)"
    },
    {
      "code": "30049094",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : THEOPHYLLINE, AMINOPHYLLINE AND OTHER BRONCHO DILATORS"
    },
    {
      "code": "30049095",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : CARCINO-CHEMOTHERAPEUTIC DRUGS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "30049096",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : --- KETAMINE"
    },
    {
      "code": "30049099",
      "description": "MEDICAMENTS (EXCLUDING GOODS OF HEADING 3002, 3005 OR 3006) CONSISTING OF MIXED OR UNMIXED PRODUCTS FOR THERAPEUTIC OR PROPHYLACTIC USES, PUT UP IN MEASURED DOSES (INCLUDING THOSE IN THE FORM OF TRANSDERMAL ADMINISTRATION SYSTEMS) OR IN FORMS OR PACKINGS FOR RETAIL SALE OTHER : OTHER : OTHER"
    },
    {
      "code": "3005",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY PURPOSES"
    },
    {
      "code": "300510",
      "description": "Adhesive dressings and other articles having an adhesive layer"
    },
    {
      "code": "30051010",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES ADHESIVE DRESSINGS AND OTHER ARTICLES HAVING AN ADHESIVE LAYER : ADHESIVE GUAZE"
    },
    {
      "code": "30051020",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES ADHESIVE DRESSINGS AND OTHER ARTICLES HAVING AN ADHESIVE LAYER : ADHESIVE TAPE"
    },
    {
      "code": "30051090",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES ADHESIVE DRESSINGS AND OTHER ARTICLES HAVING AN ADHESIVE LAYER : OTHER"
    },
    {
      "code": "300590",
      "description": "Other"
    },
    {
      "code": "30059010",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : COTTON WOOL, MEDICATED"
    },
    {
      "code": "30059020",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : POULTICE OF KAOLIN"
    },
    {
      "code": "30059030",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : LINT, MEDICATED"
    },
    {
      "code": "30059040",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : BANDAGES"
    },
    {
      "code": "30059050",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : BURN THERAPY DRESSING SOAKED IN PROTECTIVE GEL"
    },
    {
      "code": "30059060",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : MICRO PORES SURGICAL TAPES"
    },
    {
      "code": "30059070",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : CORN REMOVERS AND CALLOUS REMOVERS"
    },
    {
      "code": "30059090",
      "description": "WADDING, GAUZE, BANDAGES AND SIMILAR ARTICLES (FOR EXAMPLE, DRESSINGS, ADHESIVE PLASTERS, POULTICES), IMPREGNATED OR COATED WITH PHARMACEUTICAL SUBSTANCES OR PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE FOR MEDICAL, SURGICAL,DENTAL OR VETERINARY PURPOSES OTHER : OTHER"
    },
    {
      "code": "3006",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER"
    },
    {
      "code": "300610",
      "description": "Sterile surgical catgut, similar sterile suture materials (including sterile absorbable surgical or dental yarns) and sterile tissue adhesives for surgical wound closure; sterile laminaria and sterile laminaria tents; sterile absorbable surgical or dental haemostatics; sterile surgical or dental adhesion barriers, whether or not absorbable"
    },
    {
      "code": "30061010",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER STERILE SURGICAL CATGUT, SIMILAR STERILE SUTURE MATERIALS (INCLUDING STERILE ABSORBABALE SURGICAL OR DENTAL YARNS)AND STERILE TISSUE ADHESIVES FOR SURGICAL WOUND CLOSURE; STERILE LAMINARIA AND STERILE LAMINARIA TENTS; STERILE ABSORBABLE SURGICAL OR DENTAL HAEMOSTATICS;STERILE SURGICAL OR DENTAL ADHESION BARRIERS, WHETHER OR NOT ABSORBABLE:STERILE SURGICAL CATGUT, SIMILAR STERILE SUTURE MATERIALS (INCLUDING STERILE ABSORBABALE SURGICAL OR DENTAL YARNS)AND STERILE TISSUE ADHESIVES FOR SURGICAL WOUND CLOSURE"
    },
    {
      "code": "30061020",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER STERILE SURGICAL CATGUT, SIMILAR STERILE SUTURE MATERIALS (INCLUDING STERILE ABSORBABALE SURGICAL OR DENTAL YARNS)AND STERILE TISSUE ADHESIVES FOR SURGICAL WOUND CLOSURE; STERILE LAMINARIA AND STERILE LAMINARIA TENTS; STERILE ABSORBABLE SURGICAL OR DENTAL HAEMOSTATICS;STERILE SURGICAL OR DENTAL ADHESION BARRIERS, WHETHER OR NOT ABSORBABLE: STERILE LAMINARIA AND STERILE LAMINARIA TENTS; STERILE ABSORBABLE SURGICAL OR DENTAL HAEMOSTATICS;STERILE SURGICAL OR DENTAL ADHESION BARRIERS, WHETHER OR NOT ABSORBABLE"
    },
    {
      "code": "30062000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER BLOOD GROUPING REAGENTS"
    },
    {
      "code": "30063000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER OPACIFYING PREPRATIONS FOR X-RAY EXAMINATIONS; DIAGNOSTIC REAGENTS DESIGNED TO BE ADMINISTERED TO THE PATIENT"
    },
    {
      "code": "30064000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER DENTAL CEMENTS AND OTHER DENTAL FILLINGS;BONE RECONSTRUCTION CEMENTS"
    },
    {
      "code": "30065000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER FIRST-AID BOXES AND KITS"
    },
    {
      "code": "300660",
      "description": "Chemical contraceptive preparations based on hormones, on other products of heading 2937 or on spermicides"
    },
    {
      "code": "30066010",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER CHEMICAL CONTRACEPTIVE PREPARATIONS BASED ON HORMONES, OR OTHER PRODUCTS OF HEADING 2937 OR ON SPERMICIDES :BASED ON HORMONES"
    },
    {
      "code": "30066020",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER CHEMICAL CONTRACEPTIVE PREPARATIONS BASED ON HORMONES, OR OTHER PRODUCTS OF HEADING 2937 OR ON SPERMICIDES :BASED ON OTHER PRODUCTS OF HEADING 2937"
    },
    {
      "code": "30066030",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER CHEMICAL CONTRACEPTIVE PREPARATIONS BASED ON HORMONES, OR OTHER PRODUCTS OF HEADING 2937 OR ON SPERMICIDES :BASED ON SPERMICIDES"
    },
    {
      "code": "30067000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER GEL PREPARATIONS DESIGNED TO BE USED IN HUMAN OR VETERINARY MEDICINE AS A LUBRICANT FOR PARTS OF THE BODY FOR SURGICAL OPERATIONS OR PHYSICAL EXAMINATIONS OR AS A COUPLING AGENT BETWEEN THE BODY AND MEDICAL INSTRUMENTS"
    },
    {
      "code": "30068000",
      "description": "PHARMACEUTICAL GOODS SPECIFIED IN NOTE 4 TO THIS CHAPTER WASTE PHARMACEUTICALS"
    },
    {
      "code": "30069100",
      "description": "APLLIANCES IDENTIFIED FOR OSOTOMY USE"
    },
    {
      "code": "30069200",
      "description": "WASTE PHARMACEUTICALS"
    },
    {
      "code": "3011",
      "description": "Ornamental fish"
    },
    {
      "code": "3019",
      "description": "Other Live Fish"
    },
    {
      "code": "3021",
      "description": "Salmonidae, excluding livers and roes"
    },
    {
      "code": "3022",
      "description": "Flat fish (Pleutronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae and Citharidae), excluding livers and roes"
    },
    {
      "code": "3023",
      "description": "Tunas (of the genus Thunnus), skipjack or stripe-bellied bonito [Euthynnus (Katsuwonus pelamis), excluding livers and roes"
    },
    {
      "code": "3024",
      "description": "Herrings (Clupea harengus, Clupea pallasii), anchovies (Engraulis spp), sardines (Sardina pilchardus, Sardinops spp), sardinella (Sardinella spp.), brisling or sprats (Sprattus sprattus), mackerel (Scomber scombrus, Scomber australascicus, scomber japonicus), jack and horse mackerel (trachurus spp.) cobia (Rachycentron canadum) and swordfish (Xiphias gladius), excluding livers and roes"
    },
    {
      "code": "3025",
      "description": "Fish of the families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merluccidae, Moridae and Muraenolepididae, excluding livers and roes"
    },
    {
      "code": "3026",
      "description": "Other fish, excluding livers and roes"
    },
    {
      "code": "3027",
      "description": "Tilapias (Oreochromis spp.), catfish (Pangasius spp. Silurus spp., Clarias spp., Ictalurus spp.) carp (Cyprinus carpio, Carassius carassius, Ctenopharyngodon idellus, Hypophthalmichthys spp. Cirrhinus spp. Mylopharyngodon piceus), eels (Anguila spp.), Nile perch (Lates niloticus) and snakeheads (Channa spp.) excluding livers and roes"
    },
    {
      "code": "3028",
      "description": "Other fish exculding edible fish offal of sub-headings 0302 91 to 0302 99"
    },
    {
      "code": "3029",
      "description": "Livers, roes, milt, fish fins, heads, tails, maws and other edible fish offal"
    },
    {
      "code": "3031",
      "description": "Pacific salmon (Oncorhynchus nerka, Oncorhynchus gorbuscha, Oncorhynchus keta, Oncorhynchus tschawytscha. Oncorhynchus kisutch, Oncorhynchus masou and Oncorhynchus rhodurus, excluding livers and roes"
    },
    {
      "code": "3032",
      "description": "Other salmonidae, excluding livers and roes"
    },
    {
      "code": "3033",
      "description": "Flat fish (Pleutronectidae, Bothidae, Cynoglossidae, Soleidae, Scophthalmidae and Citharidae), excluding livers and roes"
    },
    {
      "code": "3034",
      "description": "Tunas (of the genus Thunnus), skipjack or stripe-bellied bonito [Euthynnus (Katsuwonus) pelamis], excluding livers and roes"
    },
    {
      "code": "3035",
      "description": "Herrings (Clupea harengus, Culpea pallasii) and Cod (Gadus morhua, Gadus ogac, Gadus macrocephalus), excluding livers and roes"
    },
    {
      "code": "3036",
      "description": "Fish of the families Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae, excluding edible fish offal of sub-headings 0303 91 to 0303 99"
    },
    {
      "code": "3037",
      "description": "Other fish, excluding livers and roes"
    },
    {
      "code": "303799",
      "description": "Other"
    },
    {
      "code": "3038",
      "description": "Other fish, excluding edible fish offal of sub-headings 0303 91 to 0303 99"
    },
    {
      "code": "3039",
      "description": "Livers, roes, milt, fish fins, heads, tails, maws and other edible fish offal"
    },
    {
      "code": "3041",
      "description": "Fresh or chilled"
    },
    {
      "code": "3042",
      "description": "Frozen fillets"
    },
    {
      "code": "3045",
      "description": "Other, fresh or chilled"
    },
    {
      "code": "3046",
      "description": "Frozen fillets of tilapias (Oreochromis spp.), catfish (Pangasius spp., Silurus spp., Clarias spp., Ictalurus spp.), carp (Cyprinus spp., Carassius spp., Ctenopharyngodon idellus, Hypophthalmichthys spp., Cirrhinus spp., Mylopharyngodon piceus, Catla catla, Labeo spp., Osteochilus hasselti, Leptobarbus hoeveni, Megalobrama spp.), eels (Anguilla spp.), Nile perch (Lates niloticus) and snakeheads (Channa spp.)"
    },
    {
      "code": "3047",
      "description": "Frozen fillets of fish of Bregmacerotidae, Euclichthyidae, Gadidae, Macrouridae, Melanonidae, Merlucciidae, Moridae and Muraenolepididae"
    },
    {
      "code": "3048",
      "description": "Frozen fillets of other fish"
    },
    {
      "code": "3049",
      "description": "Other"
    },
    {
      "code": "3054",
      "description": "Smoked fish, including fillets"
    },
    {
      "code": "3055",
      "description": "Dried fish, whether or not suited but not smoked"
    },
    {
      "code": "3056",
      "description": "Fish, salted but not dried or smoked and fish in brine"
    },
    {
      "code": "3061",
      "description": "Frozen"
    },
    {
      "code": "306131",
      "description": "macrobactium"
    },
    {
      "code": "3062",
      "description": "Not frozen"
    },
    {
      "code": "3069",
      "description": "Other"
    },
    {
      "code": "3072",
      "description": "Scallops, including queen scallops, of the genera Pecten, Chlamys or Placopecten"
    },
    {
      "code": "3073",
      "description": "Mussels (Mytilus spp., Perna spp.)"
    },
    {
      "code": "3074",
      "description": "Cuttle fish and squid"
    },
    {
      "code": "3075",
      "description": "Octopus (Octopus spp.)"
    },
    {
      "code": "3078",
      "description": "Abalone (Haliotis Spp.) and stromboid conchs (Strombus spp.)"
    },
    {
      "code": "3079",
      "description": "Other, including flours, meals and pellets, of aquatic invertebrates other than crustaceans, fit for human consumption"
    },
    {
      "code": "3082",
      "description": "Sea urchins (Strongylocentrotus spp., Paracentrotus lividus, Loxechinus albus, Echinus esculentus)"
    },
    {
      "code": "31",
      "description": "Fertilizers"
    },
    {
      "code": "3101",
      "description": "ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT  MIXED TOGETHER OR CHEMICALLY TREATED; FERTILISERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS"
    },
    {
      "code": "310100",
      "description": "Animal or vegetable fertilisers, whether or not mixed together or chemically treated; fertilisers produced by the mixing or chemical treatment of animal or vegetable products"
    },
    {
      "code": "31010010",
      "description": "ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILISERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILIZERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS : GUANO"
    },
    {
      "code": "31010091",
      "description": "ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILISERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILIZERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS : OTHER : ANIMAL DUNG"
    },
    {
      "code": "31010092",
      "description": "ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILISERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILIZERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS : OTHER : ANIMAL EXCRETA"
    },
    {
      "code": "31010099",
      "description": "ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILISERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS ANIMAL OR VEGETABLE FERTILISERS, WHETHER OR NOT MIXED TOGETHER OR CHEMICALLY TREATED; FERTILIZERS PRODUCED BY THE MIXING OR CHEMICAL TREATMENT OF ANIMAL OR VEGETABLE PRODUCTS : OTHER : OTHER"
    },
    {
      "code": "3102",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS"
    },
    {
      "code": "31021000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS UREA, WHETHER OR NOT IN AQUEOUS SOLUTION"
    },
    {
      "code": "31022100",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS AMMONIUM SULPHATE; DOUBLE SALTS AND MIXTURES OF AMMONIUM SULPHATE AND AMMONIUM NITRATE: AMMONIUM SULPHATE"
    },
    {
      "code": "310229",
      "description": "Other"
    },
    {
      "code": "31022910",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS AMMONIUM SULPHATE; DOUBLE SALTS AND MIXTURES OF AMMONIUM SULPHATE AND AMMONIUM NITRATE: OTHER : AMMONIUM SULPHONITRATE"
    },
    {
      "code": "31022990",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS AMMONIUM SULPHATE; DOUBLE SALTS AND MIXTURES OF AMMONIUM SULPHATE AND AMMONIUM NITRATE: OTHER : OTHER"
    },
    {
      "code": "31023000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS AMMONIUM NITRATE, WHETHER OR NOT IN AQUEOUS SOLUTION"
    },
    {
      "code": "31024000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS MIXTURES OF AMMONIUM NITRATE WITH CALCIUM CARBONATE OR OTHER INORGANIC NON-FERTILISING SUBSTANCES"
    },
    {
      "code": "31025000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS SODIUM NITRATE"
    },
    {
      "code": "31026000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS DOUBLE SALTS AND MIXTURES OF CALCIUM NITRATE AND AMMONIUM NITRATE"
    },
    {
      "code": "31027000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS CALCIUM CYANAMIDE"
    },
    {
      "code": "31028000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS MIXTURES OF UREA AND AMMONIUM NITRATE IN AQUEOUS OR AMMONIACAL SOLUTION"
    },
    {
      "code": "310290",
      "description": "Other, including mixtures not specified in the foregoing sub-headings"
    },
    {
      "code": "31029010",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS OTHER, INCLUDING MIXTURES NOT SPECIFIED IN THE FOREGOING SUB-HEADINGS : DOUBLE SALTS OR MIXTURES OF CALCIUM NITRATE AND MAGNESIUM NITRATE"
    },
    {
      "code": "31029090",
      "description": "MINERAL OR CHEMICAL FERTILISERS, NITROGENOUS OTHER, INCLUDING MIXTURES NOT SPECIFIED IN THE FOREGOING SUB-HEADINGS : OTHER"
    },
    {
      "code": "3103",
      "description": "MINERAL OR CHEMICAL FERTILISERS, PHOSPHATIC"
    },
    {
      "code": "31031000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, PHOSPHATIC SUPERPHOSPHATES"
    },
    {
      "code": "31031100",
      "description": "Containing by weight 35 % or more of diphosphorus pentaoxide (P2O5)"
    },
    {
      "code": "31031900",
      "description": "Other"
    },
    {
      "code": "31032000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, PHOSPHATIC BASIC SLAG"
    },
    {
      "code": "31039000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, PHOSPHATIC OTHER"
    },
    {
      "code": "3104",
      "description": "MINERAL OR CHEMICAL FERTILISERS, POTASSIC"
    },
    {
      "code": "31041000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, POTASSIC CARNALLITE, SYLVITE AND OTHER CRUDE NATURAL POTASSIUM SALTS"
    },
    {
      "code": "31042000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, POTASSIC POTASSIUM CHLORIDE"
    },
    {
      "code": "31043000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, POTASSIC POTASSIUM SULPHATE"
    },
    {
      "code": "31049000",
      "description": "MINERAL OR CHEMICAL FERTILISERS, POTASSIC OTHER"
    },
    {
      "code": "3105",
      "description": "MINERAL OR CHEMICAL FERTILISERS CONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN, PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS; GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG"
    },
    {
      "code": "31051000",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG."
    },
    {
      "code": "31052000",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG MINERAL OR CHEMICAL FERTILISERS CONTAINING THE THREE FERTILISING ELEMENTS NITROGEN, PHOSPHORUS AND POTASSIUM"
    },
    {
      "code": "31053000",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG DIAMMONIUM HYDROGEN ORTHO PHOSPHATE (DIAMMONIUM PHOSPHATE)"
    },
    {
      "code": "31054000",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG AMMONIUM DIHYDROGEN ORTHO PHOSPHATE (MONOAMMONIUM PHOSPHATE) AND MIXTURES THEREOF WITH DIAMMONIUM HYDROGEN ORTHOPHOSPHATE (DIAMMONIUM PHOSPHATE)"
    },
    {
      "code": "31055100",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG OTHER MINERAL OR CHEMICAL FERTILISERS CONTAINING THE TWO FERTILISING ELEMENTS NITROGEN AND PHOSPHORUS : CONTAINING NITRATES AND PHOSPHATES"
    },
    {
      "code": "31055900",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG OTHER MINERAL OR CHEMICAL FERTILISERS CONTAINING THE TWO FERTILISING ELEMENTS NITROGEN AND PHOSPHORUS : OTHER"
    },
    {
      "code": "31056000",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG MINERAL OR CHEMICAL FERTILISERS CONTAINING THE TWO FERTILISING ELEMENTS PHOSPHORUS AND POTASSIUM"
    },
    {
      "code": "310590",
      "description": "Other"
    },
    {
      "code": "31059010",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG OTHER : MINERAL OR CHEMICAL FERTILISERS CONTAINING TWO FERTILISING ELEMENTS NAMELY NITROGEN AND POTASSIUM"
    },
    {
      "code": "31059090",
      "description": "MINERAL OR CHEMICAL FERTILISERSCONTAINING TWO OR THREE OF THE FERTILISING ELEMENTS NITROGEN,PHOSPHORUS AND POTASSIUM; OTHER FERTILISERS;GOODS OF THIS CHAPTER IN TABLETS OR SIMILAR FORMS OR IN PACKAGES OF A GROSS WEIGHT NOT EXCEEDING 10 KG OTHER : OTHER"
    },
    {
      "code": "32",
      "description": "Tanning or dyeing extracts; tannins and their derivatives; dyes, pigments and other colouring matter; paints and varnishes; putty and other mastics; inks"
    },
    {
      "code": "3201",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES"
    },
    {
      "code": "32011000",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES QUEBRACHO EXTRACT"
    },
    {
      "code": "32012000",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES WATTLE EXTRACT"
    },
    {
      "code": "320190",
      "description": "Other"
    },
    {
      "code": "32019010",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : GAMBIER EXTRACTS"
    },
    {
      "code": "32019020",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : MYROBALAN FRUIT EXTRACT"
    },
    {
      "code": "32019030",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : GALLOTANNIC ACID (TANNIN, DIGALLIC ACID)"
    },
    {
      "code": "32019090",
      "description": "TANNING EXTRACTS OF VEGETABLE ORIGIN; TANNINS AND THEIR SALTS, ETHERS, ESTERS AND OTHER DERIVATIVES - OTHER : OTHER"
    },
    {
      "code": "3202",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRETANNING"
    },
    {
      "code": "32021000",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRE - TANNING SYNTHETIC ORGANIC TANNING SUBSTANCES"
    },
    {
      "code": "320290",
      "description": "Other"
    },
    {
      "code": "32029010",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRE - TANNING - OTHER : INORGANIC TANNING SUBSTANCES"
    },
    {
      "code": "32029020",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRE - TANNING - OTHER : TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES"
    },
    {
      "code": "32029030",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRE - TANNING - OTHER : ENZYMATIC PREPARATIONS FOR PRE - TANNING"
    },
    {
      "code": "32029090",
      "description": "SYNTHETIC ORGANIC TANNING SUBSTANCES; INORGANIC TANNING SUBSTANCES; TANNING PREPARATIONS, WHETHER OR NOT CONTAINING NATURAL TANNING SUBSTANCES; ENZYMATIC PREPARATIONS FOR PRE - TANNING - OTHER : OTHER"
    },
    {
      "code": "3203",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN"
    },
    {
      "code": "320300",
      "description": "Colouring matter of vegetable or animal origin (including dyeing extracts but excluding animal black), whether or not chemically defined; preparations as specified in Note 3 to this Chapter based on colouring matter of vegetable or animal origin"
    },
    {
      "code": "32030010",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN - COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN : CUTCH (CATECHU) EXTRACTS"
    },
    {
      "code": "32030020",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN - COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN : FOOD COLOURS OTHER THAN SYNTHETIC"
    },
    {
      "code": "32030030",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN - COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN : LAC - DYE"
    },
    {
      "code": "32030040",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN - COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN : NATURAL INDIGO"
    },
    {
      "code": "32030090",
      "description": "COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN - COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN (INCLUDING DYEING EXTRACTS BUT EXCLUDING ANIMAL BLACK), WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOURING MATTER OF VEGETABLE OR ANIMAL ORIGIN : OTHER"
    },
    {
      "code": "3204",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - Synthetic organic colouring matter andpreparations based there on as specified in Note 3 to this Chapter :"
    },
    {
      "code": "320411",
      "description": "Disperse dyes and preparations based thereon"
    },
    {
      "code": "32041111",
      "description": "3204 SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE YELLOW : DISPERSE YELLOW 13 (DURANOL BRILL YELLOW 6 G)"
    },
    {
      "code": "32041119",
      "description": "3204 SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE YELLOW : OTHER"
    },
    {
      "code": "32041121",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE ORANGE : DISPERSE ORANGE 11 (DURANOL ORANGE G)"
    },
    {
      "code": "32041129",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE ORANGE : OTHER"
    },
    {
      "code": "32041131",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON :- DISPERSE RED : DISPERSE RED 3 (SERISOL FAST PINK B)"
    },
    {
      "code": "32041132",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON :- DISPERSE RED : DISPERSE RED 4 (CELLITON FAST PINK RF)"
    },
    {
      "code": "32041133",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON :- DISPERSE RED : DISPERSE RED 9 (DURANOL RED GN)"
    },
    {
      "code": "32041139",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON :- DISPERSE RED : OTHER"
    },
    {
      "code": "32041141",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE VIOLET : DISPERSE VIOLET 1 (DURANOL VIOLET 2R)"
    },
    {
      "code": "32041142",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE VIOLET : DISPERSE VIOLET 4 (DURANOL BRILL VIOLET B)"
    },
    {
      "code": "32041143",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE VIOLET : DISPERSE VIOLET 8 (DURANOL BRILL VIOLET BR)"
    },
    {
      "code": "32041149",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE VIOLET : OTHER"
    },
    {
      "code": "32041151",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 1 (DURANOL BRILL BLUE CB)"
    },
    {
      "code": "32041152",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 3 (DURANOL BRILL BLUE BBN)"
    },
    {
      "code": "32041153",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 5 (CELLITON FAST BLUE FFB)"
    },
    {
      "code": "32041154",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 6 (CELLITON FAST BLUE FFG)"
    },
    {
      "code": "32041155",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 14 (DURANOL BRILL BLUE G)"
    },
    {
      "code": "32041156",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : DISPERSE BLUE 24 (DURANOL BLUE 2G)"
    },
    {
      "code": "32041159",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : DISPERSE BLUE : OTHER"
    },
    {
      "code": "32041191",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE GREENS"
    },
    {
      "code": "32041192",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE BROWNS"
    },
    {
      "code": "32041193",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE BLACKS"
    },
    {
      "code": "32041194",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE BROWN MIXTURES"
    },
    {
      "code": "32041195",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE GREY MIXTURES"
    },
    {
      "code": "32041196",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : DISPERSE BLACK MIXTURES"
    },
    {
      "code": "32041199",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- DISPERSE DYES AND PREPARATIONS BASED THEREON : OTHER : OTHER"
    },
    {
      "code": "320412",
      "description": "Acid dyes, whether or not premetallised, and preparations based thereon;mordant dyes and preparations based thereon"
    },
    {
      "code": "32041211",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID YELLOWS"
    },
    {
      "code": "32041212",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID ORANGES"
    },
    {
      "code": "32041213",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID RED"
    },
    {
      "code": "32041214",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID VIOLETS"
    },
    {
      "code": "32041215",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID BLUES"
    },
    {
      "code": "32041216",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID GREENS"
    },
    {
      "code": "32041217",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID BROWN"
    },
    {
      "code": "32041218",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : ACID BLACKS"
    },
    {
      "code": "32041219",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : AZO DYES : OTHER"
    },
    {
      "code": "32041221",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : ACID GREEN 17 (SOLACET FAST GREEN 2G)"
    },
    {
      "code": "32041222",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : ACID GREEN 27 (CARBOLAN GREEN G)"
    },
    {
      "code": "32041223",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : ACID GREEN 28 (CARBOLAN BRILL GREEN 5G)"
    },
    {
      "code": "32041224",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : ACID GREEN 38 (ALIZARINE CYANINE GREEN 3G)"
    },
    {
      "code": "32041225",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : ACID GREEN 44 (ALIZARINE CYANINE GREEN GWA)"
    },
    {
      "code": "32041229",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID GREENS (NON - AZO) : OTHER"
    },
    {
      "code": "32041231",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLACK (NON - AZO) : ACID BLACK 2 (NIGROSINE)"
    },
    {
      "code": "32041232",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLACK (NON - AZO) : ACID BLACK 48 (COOMASIE FAST GREY 3G)"
    },
    {
      "code": "32041239",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLACK (NON - AZO) : OTHER"
    },
    {
      "code": "32041241",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 2 (ALIZARINE BRILL BLUE PFN)"
    },
    {
      "code": "32041242",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 14 (SOLACET FAST BLUE 4 G1)"
    },
    {
      "code": "32041243",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 23 (ALIZARINE LIGHT BLUE 4 G1)"
    },
    {
      "code": "32041244",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 25 (SOLWAY ULTRA BLUE B)"
    },
    {
      "code": "32041245",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 45 (SOLWAY BLUE RN)"
    },
    {
      "code": "32041246",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 51 (ALIZARINE SKY BLUE FFB)"
    },
    {
      "code": "32041247",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 52 (ALIZARINE LIGHT 5GL)"
    },
    {
      "code": "32041248",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 2, 14, 23, 25, 45, 51, 52 AND 78 (NON - AZO) : ACID BLUE 78 (SOLWAY SKY BLUE B)"
    },
    {
      "code": "32041251",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : ACID BLUE 93 (INK BLUE)"
    },
    {
      "code": "32041252",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : ACID BLUE 112 (COOMASIE ULTRA SKY SE)"
    },
    {
      "code": "32041253",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : ACID BLUE 127 (BRILL ALIZARINE MILLING BLUE G)"
    },
    {
      "code": "32041254",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : ACID BLUE 138 (CARBOLAN BLUE B)"
    },
    {
      "code": "32041255",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : ACID BLUE 140 (CARBOLAN BRILL BLUE 2R)"
    },
    {
      "code": "32041259",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : ACID BLUES 93, 112, 127, 138, 140 AND OTHERS (NON - AZO) : OTHER"
    },
    {
      "code": "32041261",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : YELLOWS"
    },
    {
      "code": "32041262",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : ORANGES"
    },
    {
      "code": "32041263",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : VIOLETS"
    },
    {
      "code": "32041264",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : BLUES"
    },
    {
      "code": "32041265",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : GREENS"
    },
    {
      "code": "32041266",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : BROWNS"
    },
    {
      "code": "32041267",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : BLACKS"
    },
    {
      "code": "32041268",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : RED II (ALIZARINE RED)"
    },
    {
      "code": "32041269",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : MORDANT DYES : OTHER"
    },
    {
      "code": "32041291",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : ACID YELLOWS"
    },
    {
      "code": "32041292",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : ACID ORANGES"
    },
    {
      "code": "32041293",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : ACID RED"
    },
    {
      "code": "32041294",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : ACID VIOLETS"
    },
    {
      "code": "32041295",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : ACID BROWNS"
    },
    {
      "code": "32041299",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : ACID DYES, WHETHER OR NOT PREMETALLISED, AND PREPARATIONS BASED THEREON; MORDANT DYES AND PREPARATIONS BASED THEREON : OTHER NON - AZO ACID DYES : OTHER"
    },
    {
      "code": "320413",
      "description": "Basic dyes and preparations based thereon"
    },
    {
      "code": "32041310",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC AZO DYES"
    },
    {
      "code": "32041321",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC YELLOW (NON - AZO) : YELLOW 2 (AURAMINE O)"
    },
    {
      "code": "32041329",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC YELLOW (NON - AZO) : OTHER"
    },
    {
      "code": "32041331",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC RED (NON - AZO) : RED 1 (RHODAMINE 6 G)"
    },
    {
      "code": "32041339",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC RED (NON - AZO) : OTHER"
    },
    {
      "code": "32041341",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC VIOLET (NON - AZO) : VIOLET 1 (METHYL VIOLET)"
    },
    {
      "code": "32041342",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC VIOLET (NON - AZO) : VIOLET 10 (RHODAMINE B)"
    },
    {
      "code": "32041343",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC VIOLET (NON - AZO) : VIOLET 14 (MAGENTA)"
    },
    {
      "code": "32041349",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC VIOLET (NON - AZO) : OTHER"
    },
    {
      "code": "32041351",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC BLUE (NON - AZO) : BLUE 9 (METHYLENE BLUE)"
    },
    {
      "code": "32041352",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC BLUE (NON - AZO) : BLUE 16 (VICTORIA BLUE B)"
    },
    {
      "code": "32041359",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC BLUE (NON - AZO) : OTHER"
    },
    {
      "code": "32041361",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC GREEN (NON - AZO) : GREEN 4 (MALACHITE GREEN)"
    },
    {
      "code": "32041369",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON : BASIC GREEN (NON - AZO) : OTHER"
    },
    {
      "code": "32041391",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON :- OTHER NON - AZO BASIC DYES : BASIC ORANGES"
    },
    {
      "code": "32041392",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON :- OTHER NON - AZO BASIC DYES : BASIC BROWNS"
    },
    {
      "code": "32041393",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON :- OTHER NON - AZO BASIC DYES : BASIC BLACK"
    },
    {
      "code": "32041399",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : BASIC DYES AND PREPARATIONS BASED THEREON :- OTHER NON - AZO BASIC DYES : OTHER"
    },
    {
      "code": "320414",
      "description": "Direct dyes and preparations based thereon"
    },
    {
      "code": "32041411",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT YELLOW (AZO) : YELLOW 12 (CHRYSOPHENINE G)"
    },
    {
      "code": "32041419",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT YELLOW (AZO) : OTHER"
    },
    {
      "code": "32041421",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT RED (AZO) : CONGO RED"
    },
    {
      "code": "32041429",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT RED (AZO) : OTHER"
    },
    {
      "code": "32041431",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT BLUE (AZO) : BLUE 1 (SKY BLUE FF)"
    },
    {
      "code": "32041439",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT BLUE (AZO) : OTHER"
    },
    {
      "code": "32041440",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT ORANGES (AZO)"
    },
    {
      "code": "32041450",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT GREENS (AZO)"
    },
    {
      "code": "32041460",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT BROWNS (AZO)"
    },
    {
      "code": "32041470",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT BLACKS (AZO)"
    },
    {
      "code": "32041481",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : YELLOWS"
    },
    {
      "code": "32041482",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : ORANGES"
    },
    {
      "code": "32041483",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : REDS"
    },
    {
      "code": "32041484",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : VIOLETS"
    },
    {
      "code": "32041485",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : BLUES"
    },
    {
      "code": "32041486",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : GREENS"
    },
    {
      "code": "32041487",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : BROWNS"
    },
    {
      "code": "32041488",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : BLACKS"
    },
    {
      "code": "32041489",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : DIRECT DYES (NON - AZO) : OTHER"
    },
    {
      "code": "32041490",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : DIRECT DYES AND PREPARATIONS BASED THEREON : OTHER"
    },
    {
      "code": "320415",
      "description": "Vat dyes (including those usable in that state as pigments) and preparations based thereon"
    },
    {
      "code": "32041511",
      "description": "(SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT YELLOW : VAT YELLOW GC)"
    },
    {
      "code": "32041512",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT YELLOW : VAT YELLOW 4 (INDATHRENE GOLDEN YELLOW GK)"
    },
    {
      "code": "32041519",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT YELLOW : OTHER"
    },
    {
      "code": "32041521",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT ORANGE : VAT ORANGES 3 (BRILL ORANGE RK)"
    },
    {
      "code": "32041522",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT ORANGE : VAT ORANGES 15 (GOLDEN ORANGE 3G)"
    },
    {
      "code": "32041529",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT ORANGE : OTHER"
    },
    {
      "code": "32041531",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT RED : VAT RED (BRILL PINK)"
    },
    {
      "code": "32041539",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT RED : OTHER"
    },
    {
      "code": "32041541",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT VIOLET : VAT VIOLET 1 (BRILL VIOLET 2R)"
    },
    {
      "code": "32041542",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT VIOLET : VAT VIOLET 3 (MAGENTA B)"
    },
    {
      "code": "32041549",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT VIOLET : OTHER"
    },
    {
      "code": "32041551",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 1 (SYNTHETIC INDIGO)"
    },
    {
      "code": "32041552",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 4"
    },
    {
      "code": "32041553",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 5 (BLUE 2B)"
    },
    {
      "code": "32041554",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 6 (BLUE BC)"
    },
    {
      "code": "32041555",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 20 (DARK BLUE 30)"
    },
    {
      "code": "32041556",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 29 (INDANTHRENE BRILL BLUE 4G)"
    },
    {
      "code": "32041557",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : VAT BLUE 43 (CARBAZOLE BLUE)"
    },
    {
      "code": "32041558",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : REDUCED VAT BLUES"
    },
    {
      "code": "32041559",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON :- VAT BLUE : OTHER"
    },
    {
      "code": "32041561",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT GREEN : VAT GREEN 1 (INDANTHRENE BRILL GREEN BFFB)"
    },
    {
      "code": "32041562",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT GREEN : VAT GREEN 2 (INDANTHRENE BRILL GREEN GG)"
    },
    {
      "code": "32041563",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT GREEN : VAT GREEN 4 (INDANTHRENE BRILL GREEN 3B)"
    },
    {
      "code": "32041564",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT GREEN : VAT GREEN 9 (BLACK BB)"
    },
    {
      "code": "32041569",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT GREEN : OTHER"
    },
    {
      "code": "32041571",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BROWN : VAT BROWN 1 (BROWN BR)"
    },
    {
      "code": "32041572",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BROWN : VAT BROWN 3 (BROWN RGR)"
    },
    {
      "code": "32041573",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BROWN : VAT BROWN 5 (BROWN RRD,G)"
    },
    {
      "code": "32041579",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BROWN : OTHER"
    },
    {
      "code": "32041581",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BLACK : VAT BLACK 9 (BLACK RB)"
    },
    {
      "code": "32041582",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BLACK : VAT BLACK 25 (OLIVE T)"
    },
    {
      "code": "32041583",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BLACK : VAT BACK 27 (OLIVE R)"
    },
    {
      "code": "32041584",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BLACK : VAT BLACK 29 (GREY BG)"
    },
    {
      "code": "32041589",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : VAT BLACK : OTHER"
    },
    {
      "code": "32041591",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT YELLOWS"
    },
    {
      "code": "32041592",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT ORANGES"
    },
    {
      "code": "32041593",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT REDS"
    },
    {
      "code": "32041594",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT VIOLETS"
    },
    {
      "code": "32041595",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT BLUES"
    },
    {
      "code": "32041596",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT GREENS"
    },
    {
      "code": "32041597",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT BLACKS"
    },
    {
      "code": "32041599",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : VAT DYES (INCLUDING THOSE USABLE IN THAT STATE AS PIGMENTS) AND PREPARATIONS THEREON : OTHER : SOLUBILISED VAT BLACKS - OTHER"
    },
    {
      "code": "320416",
      "description": "Reactive dyes and preparations based thereon"
    },
    {
      "code": "32041610",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : YELLOWS"
    },
    {
      "code": "32041620",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : ORANGES"
    },
    {
      "code": "32041630",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : REDS"
    },
    {
      "code": "32041640",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : VIOLETS"
    },
    {
      "code": "32041650",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : BLUES"
    },
    {
      "code": "32041660",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : GREENS"
    },
    {
      "code": "32041670",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : BROWNS"
    },
    {
      "code": "32041680",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : BLACKS"
    },
    {
      "code": "32041690",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- REACTIVE DYES AND PREPARATIONS BASED THEREON : OTHER"
    },
    {
      "code": "320417",
      "description": "Pigments and preparations based thereon"
    },
    {
      "code": "32041711",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT YELLOW : YELLOW 1 (HANSA YELLOW)"
    },
    {
      "code": "32041719",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT YELLOW : OTHER"
    },
    {
      "code": "32041720",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT ORANGES"
    },
    {
      "code": "32041731",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT RED : TOLUIDINE RED"
    },
    {
      "code": "32041739",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT RED : OTHER"
    },
    {
      "code": "32041740",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT VIOLETS"
    },
    {
      "code": "32041751",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT BLUES : BLUE 15 (PATHALOCYANINE BLUE)"
    },
    {
      "code": "32041759",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT BLUES : OTHER"
    },
    {
      "code": "32041761",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON :- PIGMENT GREENS : GREEN 7 (PATHALOVYANINE GREEN)"
    },
    {
      "code": "32041769",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON :- PIGMENT GREENS : OTHER"
    },
    {
      "code": "32041770",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT BROWNS"
    },
    {
      "code": "32041780",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : PIGMENT BLACKS"
    },
    {
      "code": "32041790",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER : PIGMENTS AND PREPARATIONS BASED THEREON : OTHER"
    },
    {
      "code": "320419",
      "description": "Other, including mixtures of colouring matter of two or more of the sub-headings 3204 11 to 3204 19"
    },
    {
      "code": "32041911",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 2 (NAPHTHOL AS)"
    },
    {
      "code": "32041912",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 4 (NAPHTHOL AS - BO)"
    },
    {
      "code": "32041913",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 5 (NAPHTHOL ASG)"
    },
    {
      "code": "32041914",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 7 (NAPHTHOL ASSW)"
    },
    {
      "code": "32041915",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 8 (NAPHTHOL ASTR)"
    },
    {
      "code": "32041916",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 2,4,5,7,8,13 : AZOIC COUPLING COMPONENT 13 (NAPHTHOL ASSG)"
    },
    {
      "code": "32041921",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : AZOIC COUPLING COMPONENT 14 (NAPHTHOL ASPH)"
    },
    {
      "code": "32041922",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : AZOIC COUPLING COMPONENT 15 (NAPHTHOL ASLB)"
    },
    {
      "code": "32041923",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : AZOIC COUPLING COMPONENT 17 (NAPHTHOL ASBS)"
    },
    {
      "code": "32041924",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : AZOIC COUPLING COMPONENT 18 (NAPHTHOL ASD)"
    },
    {
      "code": "32041925",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : AZOIC COUPLING COMPONENT 20 (NAPHTHOL ASOL)"
    },
    {
      "code": "32041929",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC COUPLING COMPONENTS 14,15,17,18,20 AND OTHERS : OTHER"
    },
    {
      "code": "32041931",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 1 (FAST BORDEAUX GP BASE)"
    },
    {
      "code": "32041932",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 2 (FAST ORANGE G/GC BASE)"
    },
    {
      "code": "32041933",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 3 (FAST SCARLET GGIGGS BASE)"
    },
    {
      "code": "32041934",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 4 (FAST GARMENT GBC BASE)"
    },
    {
      "code": "32041935",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 5 (FAST RED B BASE)"
    },
    {
      "code": "32041936",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 6 (FAST ORANGE GR BASE)"
    },
    {
      "code": "32041937",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 10 (FAST RED R BASE)"
    },
    {
      "code": "32041938",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 1,2,3,4,5,6,10,11 : AZOIC DIAZO COMPONENT 11 (FAST RED TR BASE)"
    },
    {
      "code": "32041941",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 12 (FAST SCARLET G BASE)"
    },
    {
      "code": "32041942",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 13 (FAST SCARLET R BASE)"
    },
    {
      "code": "32041943",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 20 (FAST BLUE BB BASE)"
    },
    {
      "code": "32041944",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 24 (FAST BLUE RR BASE)"
    },
    {
      "code": "32041945",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 32 (FAST RED KB BASE)"
    },
    {
      "code": "32041946",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 41 (FAST VIOLET B BASE)"
    },
    {
      "code": "32041947",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : AZOIC DIAZO COMPONENT 48 (FAST BLUE B BASE)"
    },
    {
      "code": "32041949",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : AZOIC DIAZO COMPONENT 12,13,20,24,32,41,48 AND OTHERS : OTHER"
    },
    {
      "code": "32041951",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : YELLOWS"
    },
    {
      "code": "32041952",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : ORANGES"
    },
    {
      "code": "32041953",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : REDS"
    },
    {
      "code": "32041954",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : VIOLETS"
    },
    {
      "code": "32041955",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : BLUES"
    },
    {
      "code": "32041956",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : GREENS"
    },
    {
      "code": "32041957",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : BROWNS"
    },
    {
      "code": "32041958",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : BLACKS"
    },
    {
      "code": "32041959",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 :- AZOIC COLOURS : OTHER"
    },
    {
      "code": "32041961",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : YELLOWS"
    },
    {
      "code": "32041962",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : ORANGES"
    },
    {
      "code": "32041963",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : REDS"
    },
    {
      "code": "32041964",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : BLUES"
    },
    {
      "code": "32041965",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : GREENS"
    },
    {
      "code": "32041966",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : BROWNS"
    },
    {
      "code": "32041967",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : BLACKS"
    },
    {
      "code": "32041969",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SULPHUR BASED COLOURING MATTERS : OTHER"
    },
    {
      "code": "32041971",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : YELLOWS"
    },
    {
      "code": "32041972",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : ORANGES"
    },
    {
      "code": "32041973",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : REDS"
    },
    {
      "code": "32041974",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : VIOLETS"
    },
    {
      "code": "32041975",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : BLUES"
    },
    {
      "code": "32041976",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : GREENS"
    },
    {
      "code": "32041977",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : BROWNS"
    },
    {
      "code": "32041978",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : BLACKS"
    },
    {
      "code": "32041979",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : SOLVENT BASED COLOURING MATTERS : OTHER"
    },
    {
      "code": "32041981",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : YELLOW 3 (SUNSET YELLOW)"
    },
    {
      "code": "32041982",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : YELLOW 4 (TARTRAZINE)"
    },
    {
      "code": "32041983",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : REDS 5 TO 8 (PONCEAN)"
    },
    {
      "code": "32041984",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : RED 9 (AMARANTH)"
    },
    {
      "code": "32041985",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : ORANGES"
    },
    {
      "code": "32041986",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : VIOLETS"
    },
    {
      "code": "32041987",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : GREENS"
    },
    {
      "code": "32041988",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : BROWNS"
    },
    {
      "code": "32041989",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : FOOD COLOURING MATTERS : OTHER"
    },
    {
      "code": "32041990",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC COLOURING MATTER AND PREPARATIONS BASED THEREON AS SPECIFIED IN NOTE 3 TO THIS CHAPTER :- OTHER, INCLUDING MIXTURES OF COLOURING MATTER OF TWO OR MORE OF THE SUB - HEADINGS 3204 11 TO 3204 19 : OTHER"
    },
    {
      "code": "320420",
      "description": "Synthetic organic products of a kind used as fluorescent brightening agents"
    },
    {
      "code": "32042010",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS : OPTICAL WHITENING AGENTS"
    },
    {
      "code": "32042090",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS : OTHER"
    },
    {
      "code": "32049000",
      "description": "SYNTHETIC ORGANIC COLOURING MATTER, WHETHER OR NOT CHEMICALLY DEFINED; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON SYNTHETIC ORGANIC COLOURING MATTER; SYNTHETIC ORGANIC PRODUCTS OF A KIND USED AS FLUORESCENT BRIGHTENING AGENTS OR AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED- OTHER"
    },
    {
      "code": "3205",
      "description": "COLOUR LAKES; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOUR LAKES"
    },
    {
      "code": "32050000",
      "description": "COLOUR LAKES; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER BASED ON COLOUR LAKES"
    },
    {
      "code": "3206",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADING 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - Pigments and preparations based on titanium dioxide :"
    },
    {
      "code": "320611",
      "description": "Containing 80% or more by weight of titanium dioxide calculated on the dry matter"
    },
    {
      "code": "32061110",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - PIGMENTS AND PREPARATIONS BASED ON TITANIUM DIOXIDE :- CONTAINING 80% OR MORE BY WEIGHT OF TITANIUM DIOXIDE CALCULATED ON THE DRY MATTER : PEARLSENT PIGMENT (TITANIUM DIOXIDE, COATED MICANANEOUS AND LUSTRES PEARL PIGMENT)"
    },
    {
      "code": "32061190",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - PIGMENTS AND PREPARATIONS BASED ON TITANIUM DIOXIDE :- CONTAINING 80% OR MORE BY WEIGHT OF TITANIUM DIOXIDE CALCULATED ON THE DRY MATTER : OTHER"
    },
    {
      "code": "32061900",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - PIGMENTS AND PREPARATIONS BASED ON TITANIUM DIOXIDE : OTHER"
    },
    {
      "code": "32062000",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED- PIGMENTS AND PREPARATIONS BASED ON CHROMIUM COMPOUNDS"
    },
    {
      "code": "32063000",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - PIGMENTS AND PREPARATIONS BASED ON CADMIUM COMPOUNDS"
    },
    {
      "code": "32064100",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : ULTRAMARINE AND PREPARATIONS BASED THEREON"
    },
    {
      "code": "32064200",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : LITHOPONE AND OTHER PIGMENTS AND PREPARATIONS BASED ON ZINC SULPHIDE"
    },
    {
      "code": "32064300",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : PIGMENTS AND PREPARATIONS BASED ON HEXACYANOFERRATES (FERROCYANIDES AND FERRICYANIDES)"
    },
    {
      "code": "320649",
      "description": "Other"
    },
    {
      "code": "32064910",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : OTHER : RED OXIDE"
    },
    {
      "code": "32064920",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : OTHER : PERSIAN RED"
    },
    {
      "code": "32064930",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : OTHER : YELLOW OCHRE"
    },
    {
      "code": "32064940",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : OTHER : BRONZE POWDER"
    },
    {
      "code": "32064990",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - OTHER COLOURING MATTER AND OTHER PREPARATIONS : OTHER : OTHER"
    },
    {
      "code": "32065000",
      "description": "OTHER COLOURING MATTER; PREPARATIONS AS SPECIFIED IN NOTE 3 TO THIS CHAPTER, OTHER THAN THOSE OF HEADINGS 3203, 3204 OR 3205; INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES, WHETHER OR NOT CHEMICALLY DEFINED - INORGANIC PRODUCTS OF A KIND USED AS LUMINOPHORES"
    },
    {
      "code": "3207",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES"
    },
    {
      "code": "320710",
      "description": "Prepared pigments, prepared opacifiers, prepared colours and similar preparations"
    },
    {
      "code": "32071010",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - PREPARED PIGMENTS, PREPARED OPACIFIERS, PREPARED COLOURS AND SIMILAR PREPARATIONS : PREPARED ORGANIC DYE - STUFF PIGMENTS, DRY"
    },
    {
      "code": "32071020",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - PREPARED PIGMENTS, PREPARED OPACIFIERS, PREPARED COLOURS AND SIMILAR PREPARATIONS : PREPARED ORGANIC DYE - STUFF PIGMENTS, PASTE"
    },
    {
      "code": "32071030",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - PREPARED PIGMENTS, PREPARED OPACIFIERS, PREPARED COLOURS AND SIMILAR PREPARATIONS : PREPARED INORGANIC PIGMENTS"
    },
    {
      "code": "32071040",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - PREPARED PIGMENTS, PREPARED OPACIFIERS, PREPARED COLOURS AND SIMILAR PREPARATIONS : PREPARED OPACIFIERS PREPARED COLOURS AND SIMILAR PREPARATIONS"
    },
    {
      "code": "32071090",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - PREPARED PIGMENTS, PREPARED OPACIFIERS, PREPARED COLOURS AND SIMILAR PREPARATIONS : OTHER"
    },
    {
      "code": "320720",
      "description": "Vitrifiable enamels and glazes, engobes (slips) and similar preparations"
    },
    {
      "code": "32072010",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS) AND SIMILAR PREPARATIONS : VITRIFIABLE ENAMELS AND GLAZES"
    },
    {
      "code": "32072020",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS) AND SIMILAR PREPARATIONS : ENGOBES (SLIPS) AND SIMILAR PREPARATIONS"
    },
    {
      "code": "32073000",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES -LIQUID LUSTRES AND SIMILAR PREPARATIONS"
    },
    {
      "code": "32074000",
      "description": "PREPARED PIGMENTS, PREPARED OPACIFIERS AND PREPARED COLOURS, VITRIFIABLE ENAMELS AND GLAZES, ENGOBES (SLIPS), LIQUID LUSTRES AND SIMILAR PREPARATIONS, OF A KIND USED IN THE CERAMIC ENAMELLING OR GLASS INDUSTRY; GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES - GLASS FRIT AND OTHER GLASS, IN THE FORM OF POWDER, GRANULES OR FLAKES"
    },
    {
      "code": "3208",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON-AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER"
    },
    {
      "code": "320810",
      "description": "Based on polyesters"
    },
    {
      "code": "32081010",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON POLYESTERS : ENAMELS"
    },
    {
      "code": "32081020",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON POLYESTERS : LACQUERS"
    },
    {
      "code": "32081030",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON POLYESTERS : VARNISHES"
    },
    {
      "code": "32081090",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON POLYESTERS : OTHER"
    },
    {
      "code": "320820",
      "description": "Based on acrylic or vinyl polymers"
    },
    {
      "code": "32082010",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON ACRYLIC OR VINYL POLYMERS : ENAMELS"
    },
    {
      "code": "32082020",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON ACRYLIC OR VINYL POLYMERS : LACQUERS"
    },
    {
      "code": "32082030",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON ACRYLIC OR VINYL POLYMERS : VARNISHES"
    },
    {
      "code": "32082090",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - BASED ON ACRYLIC OR VINYL POLYMERS : OTHER"
    },
    {
      "code": "320890",
      "description": "Other"
    },
    {
      "code": "32089011",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : BASED ON CELLULOSE NITRATE OR OTHER CELLULOSE DERIVATIVES : NITROCELLULOSE LACQUERS"
    },
    {
      "code": "32089019",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : BASED ON CELLULOSE NITRATE OR OTHER CELLULOSE DERIVATIVES : OTHER"
    },
    {
      "code": "32089021",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : ENAMELS : SYNTHETIC ENAMEL, ULTRA WHITE PAINTS"
    },
    {
      "code": "32089022",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : ENAMELS : SYNTHETIC ENAMEL, OTHER COLOURS"
    },
    {
      "code": "32089029",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : ENAMELS : OTHER"
    },
    {
      "code": "32089030",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : LACQUERS"
    },
    {
      "code": "32089041",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : VARNISHES : INSULATING VARNISH"
    },
    {
      "code": "32089049",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : VARNISHES : OTHER"
    },
    {
      "code": "32089050",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : SLIP AGENTS"
    },
    {
      "code": "32089090",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN A NON - AQUEOUS MEDIUM; SOLUTIONS AS DEFINED IN NOTE 4 TO THIS CHAPTER - OTHER : OTHER"
    },
    {
      "code": "3209",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM"
    },
    {
      "code": "320910",
      "description": "Based on acrylic or vinyl polymers"
    },
    {
      "code": "32091010",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM - BASED ON ACRYLIC OR VINYL POLYMERS : ACRYLIC EMULSION"
    },
    {
      "code": "32091090",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM - BASED ON ACRYLIC OR VINYL POLYMERS : OTHER"
    },
    {
      "code": "320990",
      "description": "Other"
    },
    {
      "code": "32099010",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM - OTHER : DISPERSION PAINTS"
    },
    {
      "code": "32099020",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM - OTHER : EMULSION PAINTS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "32099090",
      "description": "PAINTS AND VARNISHES (INCLUDING ENAMELS AND LACQUERS) BASED ON SYNTHETIC POLYMERS OR CHEMICALLY MODIFIED NATURAL POLYMERS, DISPERSED OR DISSOLVED IN AN AQUEOUS MEDIUM - OTHER : OTHER"
    },
    {
      "code": "3210",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER"
    },
    {
      "code": "321000",
      "description": "Other paints and varnishes (including enamels, lacquers and distempers); prepared water pigments of a kind used for finishing leather"
    },
    {
      "code": "32100011",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : DISTEMPERS : DRY DISTEMPER, INCLUDING CEMENT BASED WATER PAINTS"
    },
    {
      "code": "32100012",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : DISTEMPERS : OIL BOUND DISTEMPER"
    },
    {
      "code": "32100019",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : DISTEMPERS : OTHER"
    },
    {
      "code": "32100020",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER"
    },
    {
      "code": "32100030",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : METALLIC POWDER OR FLAKES PREPARED AS PAINTS"
    },
    {
      "code": "32100040",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : POLY TETRA FLUORO ETHYLENE (PTFE) OR SILICON RESIN BASED COATING MATERIALS"
    },
    {
      "code": "32100090",
      "description": "OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER - OTHER PAINTS AND VARNISHES (INCLUDING ENAMELS, LACQUERS AND DISTEMPERS); PREPARED WATER PIGMENTS OF A KIND USED FOR FINISHING LEATHER : OTHER"
    },
    {
      "code": "3211",
      "description": "PREPARED DRIERS"
    },
    {
      "code": "32110000",
      "description": "PREPARED DRIERS"
    },
    {
      "code": "3212",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON-AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE"
    },
    {
      "code": "32121000",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON - AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE STAMPING FOILS"
    },
    {
      "code": "321290",
      "description": "Other"
    },
    {
      "code": "32129010",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON - AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE - OTHER : PIGMENTS IN LINSEED OIL, WHITE SPIRIT, SPIRIT OF TURPENTINE, VARNISH AND OTHER PAINTS OR ENAMEL MEDIA NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "32129020",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON - AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE - OTHER : DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE"
    },
    {
      "code": "32129030",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON - AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE - OTHER : ALUMINIUM PASTE"
    },
    {
      "code": "32129090",
      "description": "PIGMENTS (INCLUDING METALLIC POWDERS AND FLAKES) DISPERSED IN NON - AQUEOUS MEDIA, IN LIQUID OR PASTE FORM, OF A KIND USED IN THE MANUFACTURE OF PAINTS (INCLUDING ENAMELS); STAMPING FOILS; DYES AND OTHER COLOURING MATTER PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE - OTHER : OTHER"
    },
    {
      "code": "3213",
      "description": "ARTISTS, STUDENTS OR SIGNBOARD PAINTERS COLOURS, MODIFIYING TINTS, AMUSEMENT COLOURS AND THE LIKE, IN TABLETS, TUBES, JARS, BOTTLES, PANS OR IN SIMILAR FORMS OR PACKINGS"
    },
    {
      "code": "32131000",
      "description": "ARTISTS, STUDENTS,OR SIGNBOARD PAINTER,COLOURS, MODIFIYING TINTS, AMUSEMENT COLOURS AND THE LIKE, IN TABLETS, TUBES, JARS, BOTTLES, PANS OR IN SIMILAR FORMS OR PACKINGS - COLOURS IN SETS"
    },
    {
      "code": "32139000",
      "description": "ARTISTS, STUDENTS OR SIGNBOARD PAINTERS COLOURS, MODIFIYING TINTS, AMUSEMENT COLOURS AND THE LIKE, IN TABLETS, TUBES, JARS, BOTTLES, PANS OR IN SIMILAR FORMS OR PACKINGS -OTHER"
    },
    {
      "code": "3214",
      "description": "GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS; NON-REFRACTORY SURFACING PREPARTIONS FOR FACADES, INDOOR WALLS, FLOORS, CEILINGS OR THE LIKE"
    },
    {
      "code": "32141000",
      "description": "GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS; NON - REFRACTORY SURFACING PREPARTIONS FOR FACADES, INDOOR WALLS, FLOORS, CEILINGS OR THE LIKE- GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS"
    },
    {
      "code": "321490",
      "description": "Other"
    },
    {
      "code": "32149010",
      "description": "GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS; NON - REFRACTORY SURFACING PREPARTIONS FOR FACADES, INDOOR WALLS, FLOORS, CEILINGS OR THE LIKE - OTHER : NON - REFRACTORY SURFACING PREPARATIONS"
    },
    {
      "code": "32149020",
      "description": "GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS; NON - REFRACTORY SURFACING PREPARTIONS FOR FACADES, INDOOR WALLS, FLOORS, CEILINGS OR THE LIKE - OTHER : RESIN CEMENT"
    },
    {
      "code": "32149090",
      "description": "GLAZIERS PUTTY, GRAFTING PUTTY, RESIN CEMENTS, CAULKING COMPOUNDS AND OTHER MASTICS; PAINTERS FILLINGS; NON - REFRACTORY SURFACING PREPARTIONS FOR FACADES, INDOOR WALLS, FLOORS, CEILINGS OR THE LIKE - OTHER : OTHER"
    },
    {
      "code": "3215",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - Printing ink :"
    },
    {
      "code": "321511",
      "description": "Black"
    },
    {
      "code": "32151110",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK :- BLACK : LITHOGRAPHIC INK AND JELLY"
    },
    {
      "code": "32151120",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK :- BLACK : NEWSPAPER INK"
    },
    {
      "code": "32151130",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK :- BLACK : ROTARY INK"
    },
    {
      "code": "32151140",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK :- BLACK : SCREEN PRINTING INK"
    },
    {
      "code": "32151190",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK :- BLACK : OTHER"
    },
    {
      "code": "321519",
      "description": "Other"
    },
    {
      "code": "32151910",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK : OTHER : LITHOGRAPHIC INK AND JELLY"
    },
    {
      "code": "32151920",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK : OTHER : NEWSPAPER INK"
    },
    {
      "code": "32151930",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK : OTHER : ROTARY INK"
    },
    {
      "code": "32151940",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK : OTHER : SCREEN PRINTING INK"
    },
    {
      "code": "32151990",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - PRINTING INK : OTHER : OTHER"
    },
    {
      "code": "321590",
      "description": "Other"
    },
    {
      "code": "32159010",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - OTHER : FOUNTAIN PEN INK"
    },
    {
      "code": "32159020",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - OTHER : BALL PEN INK"
    },
    {
      "code": "32159030",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - OTHER : INDELIBLE INK"
    },
    {
      "code": "32159040",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - OTHER : DRAWING INK"
    },
    {
      "code": "32159090",
      "description": "PRINTING INK, WRITING OR DRAWING INK AND OTHER INKS, WHETHER OR NOT CONCENTRATED OR SOLID - OTHER : OTHER"
    },
    {
      "code": "33",
      "description": "Essential oils and resinoids, perfumery, cosmetic or toilet preparations"
    },
    {
      "code": "3301",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED B Y ENFLEURAGE O R MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS"
    },
    {
      "code": "33011100",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS - ESSENTIAL OILS OF CITRUS FRUIT : OF BERGAMOT"
    },
    {
      "code": "33011200",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS - ESSENTIAL OILS OF CITRUS FRUIT : OF ORANGE"
    },
    {
      "code": "33011300",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS - ESSENTIAL OILS OF CITRUS FRUIT : OF LEMON"
    },
    {
      "code": "33011400",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS - ESSENTIAL OILS OF CITRUS FRUIT : OF LIME"
    },
    {
      "code": "330119",
      "description": "Other"
    },
    {
      "code": "33011910",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OF CITRUS FRUIT : OTHER : CITRONELLA OIL"
    },
    {
      "code": "33011990",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OF CITRUS FRUIT : OTHER : OTHER"
    },
    {
      "code": "33012100",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT:OF GERANIUM"
    },
    {
      "code": "33012210",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF JASMIN : JASMIN CONCENTRATE"
    },
    {
      "code": "33012290",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF JASMIN : OTHER"
    },
    {
      "code": "33012300",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF LAVENDER OR OF LAVANDIN"
    },
    {
      "code": "33012400",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF PEPPERMINT (MENTHA PIPERITA)"
    },
    {
      "code": "330125",
      "description": "Of other mints"
    },
    {
      "code": "33012510",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF OTHER MINTS : SPEARMINT OIL (EX-MENTHA SPICATA)"
    },
    {
      "code": "33012520",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF OTHER MINTS : WATER MINT-OIL (EX-MENTHA AQUATIC)"
    },
    {
      "code": "33012530",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF OTHER MINTS : HORSEMINT OIL (EX-MENTHA SYLVESTRIES)"
    },
    {
      "code": "33012540",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF OTHER MINTS : BERGAMENT OIL (EX-MENTHA CITRATE)"
    },
    {
      "code": "33012590",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF OTHER MINTS : OTHER"
    },
    {
      "code": "33012600",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OF VETIVER"
    },
    {
      "code": "330129",
      "description": "Other"
    },
    {
      "code": "33012911",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: ANISE OIL (ANISEED OIL)"
    },
    {
      "code": "33012912",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CAJEPUT OIL"
    },
    {
      "code": "33012913",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CANANGA OIL"
    },
    {
      "code": "33012914",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CARAWAY OIL"
    },
    {
      "code": "33012915",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CASSIA OIL"
    },
    {
      "code": "33012916",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CEDARWOOD OIL"
    },
    {
      "code": "33012917",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CINNAMON BARK OIL"
    },
    {
      "code": "33012918",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: ANISE OIL; CAJEPUT OIL; CANANGA OIL; CARAWAY OIL; CASSIA OIL; CEDARWOOD OIL; CINNAMON BARK OIL; CINNAMON LEAF OIL: CINNAMON LEAF OIL"
    },
    {
      "code": "33012921",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: CLOVE LEAF OR STEM OIL"
    },
    {
      "code": "33012922",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: CORIANDER SEED OIL"
    },
    {
      "code": "33012923",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: DILL OIL (ANETHUM OIL)"
    },
    {
      "code": "33012924",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER:CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: EUCALYPTUS OIL"
    },
    {
      "code": "33012925",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: FENNEL SEED OIL"
    },
    {
      "code": "33012926",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: GINGER OIL"
    },
    {
      "code": "33012927",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: GINGER GRASS OIL"
    },
    {
      "code": "33012928",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CLOVE LEAF OR STEM OIL; CORIANDER SEED OIL; DILL OIL; EUCALYPTUS OIL; FENNEL SEED OIL; GINGER OIL; GINGER GRASS OIL; CLOVE BUD OIL: CLOVE BUD OIL"
    },
    {
      "code": "33012931",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: TUBEROSE CONCENTRATE"
    },
    {
      "code": "33012932",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: NUTMEG OIL"
    },
    {
      "code": "33012933",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: PALMAROSA OIL"
    },
    {
      "code": "33012934",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: PATCHOULI OIL"
    },
    {
      "code": "33012935",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: PEPPER OIL"
    },
    {
      "code": "33012936",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: PETITGRAIN OIL"
    },
    {
      "code": "33012937",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: SANDALWOOD OIL"
    },
    {
      "code": "33012938",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: TUBEROSE CONCENTRATE; NUTMEG OIL; PALMAROSA OIL; PATCHOULI OIL; PEPPER OIL; PETITGRAIN OIL; SANDALWOOD OIL; ROSE OIL: ROSE OIL"
    },
    {
      "code": "33012941",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : CAMPHOR OIL"
    },
    {
      "code": "33012942",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : LEMON GRASS OIL"
    },
    {
      "code": "33012943",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : YLANG YLANG OIL"
    },
    {
      "code": "33012944",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : DAVANA OIL"
    },
    {
      "code": "33012945",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : CUMIN OIL"
    },
    {
      "code": "33012946",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : CELERY SEED OIL"
    },
    {
      "code": "33012947",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : GARLIC OIL"
    },
    {
      "code": "33012948",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : PAPRIKA OIL"
    },
    {
      "code": "33012949",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: CAMPHOR OIL; LEMON GRASS OIL; YLANG YLANG OIL; DAVANA OIL; CUMIN OIL; CELERY SEED OIL, GARLIC OIL, PAPRIKA OIL, TURMERIC OIL : TURMERIC OIL"
    },
    {
      "code": "33012950",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: SPICES OILS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "33012990",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS ESSENTIAL OILS OTHER THAN THOSE OF CITRUS FRUIT: OTHER: OTHER"
    },
    {
      "code": "330130",
      "description": "Resinoids"
    },
    {
      "code": "33013010",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS RESINOIDS: AGAR OIL"
    },
    {
      "code": "33013091",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS  RESINOIDS: OTHER: FLAVOURING ESSENCES, ALL TYPES, INCLUDING THOSE FOR LIQUORS"
    },
    {
      "code": "33013099",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS RESINOIDS: OTHER: OTHER"
    },
    {
      "code": "330190",
      "description": "Other"
    },
    {
      "code": "33019011",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: FENUGREEK OLEORESINS"
    },
    {
      "code": "33019012",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: GINGER OLEORESINS"
    },
    {
      "code": "33019013",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: PEPPER OLEORESINS"
    },
    {
      "code": "33019014",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: TURMERIC OLEORESINS"
    },
    {
      "code": "33019015",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: CARDAMOM OLEORESINS"
    },
    {
      "code": "33019016",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: CELERY SEED OLEORESINS"
    },
    {
      "code": "33019017",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: FENUGREEK, GINGER, PEPPER, TURMERIC, CARDAMOM, CELERY SEED AND NUTMEG OLEORESINS: NUTMEG OLEORESINS"
    },
    {
      "code": "33019021",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: CLOVE OLEORESINS"
    },
    {
      "code": "33019022",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: CAPSICUM OLEORESINS"
    },
    {
      "code": "33019023",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: CORIANDER OLEORESINS"
    },
    {
      "code": "33019024",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: CUMIN OLEORESINS"
    },
    {
      "code": "33019025",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: FENNEL OLEORESINS"
    },
    {
      "code": "33019029",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CLOVE, CAPSICUM, CORIANDER, CUMIN AND FENNEL OLEOREINS AND OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED: OLEORESINS OF SPICES NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "33019031",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: ATTARS OF ALL KINDS IN FIXED OIL BASE; MUSTARD OIL AROMA ESSENCE OF AMBRETTOLIDE (AMBRETTE SEED OIL ESSENCE): ATTARS OF ALL KINDS IN FIXED OIL BASE"
    },
    {
      "code": "33019032",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: ATTARS OF ALL KINDS IN FIXED OIL BASE; MUSTARD OIL AROMA ESSENCE OF AMBRETTOLIDE (AMBRETTE SEED OIL ESSENCE): MUSTARD OIL AROMA"
    },
    {
      "code": "33019033",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: ATTARS OF ALL KINDS IN FIXED OIL BASE; MUSTARD OIL AROMA ESSENCE OF AMBRETTOLIDE (AMBRETTE SEED OIL ESSENCE): ESSENCE OF AMBRETTOLIDE (AMBRETTE SEED OIL ESSENCE)"
    },
    {
      "code": "33019041",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS OR IN WAXES OR THE LIKE, OBTAINED BY COLD ABSORPTION OR BY MACERATION NOT ELSEWHERE SPECIFIED OR INCLUDED: FLAVOURING ESSENCES, ALL TYPES, INCLUDING THOSE FOR LIQUORS"
    },
    {
      "code": "33019049",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS OR IN WAXES OR THE LIKE, OBTAINED BY COLD ABSORPTION OR BY MACERATION NOT ELSEWHERE SPECIFIED OR INCLUDED: OTHER"
    },
    {
      "code": "33019051",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS: FLAVOURING ESSENCES, ALL TYPES, INCLUDING THOSE FOR LIQUORS"
    },
    {
      "code": "33019059",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS: OTHER"
    },
    {
      "code": "33019060",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: AQUEOUS DISTILLATES OF ESSENTIAL OILS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "33019071",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: AQUEOUS SOLUTIONS OF ESSENTIAL OILS: FLAVOURING ESSENCES, ALL TYPES, INCLUDING THOSE FOR LIQUORS"
    },
    {
      "code": "33019079",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: AQUEOUS SOLUTIONS OF ESSENTIAL OILS: OTHER"
    },
    {
      "code": "33019090",
      "description": "ESSENTIAL OILS (TERPENELESS OR NOT), INCLUDING CONCRETES AND ABSOLUTES; RESINOIDS; EXTRACTED OLEORESINS; CONCENTRATES OF ESSENTIAL OILS IN FATS, IN FIXED OILS, IN WAXES OR THE LIKE, OBTAINED BY ENFLEURAGE OR MACERATION; TERPENIC BY-PRODUCTS OF THE DETERPENATION OF ESSENTIAL OILS; AQUEOUS DISTILLATES AND AQUEOUS SOLUTIONS OF ESSENTIAL OILS OTHER: OTHER"
    },
    {
      "code": "3302",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE O F BEVERAGES"
    },
    {
      "code": "330210",
      "description": "Of a kind used in the food or drink industries"
    },
    {
      "code": "33021010",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES(INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OFONE OR MORE OF THESE SUBSTANCES, OF A KIND USEDAS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OF A KIND USED IN THE FOOD OR DRINK INDUSTRIES: SYNTHETIC FLAVOURING ESSENCES"
    },
    {
      "code": "33021090",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OF A KIND USED IN THE FOOD OR DRINK INDUSTRIES: OTHER"
    },
    {
      "code": "330290",
      "description": "Other"
    },
    {
      "code": "33029011",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OTHER: MIXTURES OF AROMATIC CHEMICALS AND ESSENTIAL OILS AS PERFUME BASE: SYNTHETIC PERFUMERY COMPOUNDS"
    },
    {
      "code": "33029012",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OTHER: MIXTURES OF AROMATIC CHEMICALS AND ESSENTIAL OILS AS PERFUME BASE: SYNTHETIC ESSENTIAL OIL"
    },
    {
      "code": "33029019",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OTHER: MIXTURES OF AROMATIC CHEMICALS AND ESSENTIAL OILS AS PERFUME BASE: OTHER"
    },
    {
      "code": "33029020",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OTHER:ALEURITIC ACID"
    },
    {
      "code": "33029090",
      "description": "MIXTURES OF ODORIFEROUS SUBSTANCES AND MIXTURES (INCLUDING ALCOHOLIC SOLUTIONS) WITH A BASIS OF ONE OR MORE OF THESE SUBSTANCES, OF A KIND USED AS RAW MATERIALS IN INDUSTRY; OTHER PREPARATIONS BASED ON ODORIFEROUS SUBSTANCES, OF A KIND USED FOR THE MANUFACTURE OF BEVERAGES OTHER:OTHER"
    },
    {
      "code": "3303",
      "description": "PERFUMES AND TOILET WATERS"
    },
    {
      "code": "330300",
      "description": "Perfumes and toilet waters"
    },
    {
      "code": "33030010",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: EAU-DE-COLOGNE"
    },
    {
      "code": "33030020",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: ROSE WATER"
    },
    {
      "code": "33030030",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: KEORA WATER"
    },
    {
      "code": "33030040",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: PERFUMES AND PERFUMERY COMPOUNDS NOT CONTAINING SPIRIT (EXCLUDING AQUEOUS DISTILLATES)"
    },
    {
      "code": "33030050",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: PERFUMES CONTAINING SPIRIT"
    },
    {
      "code": "33030060",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: SPIRITUOUS TOILET PREPARATIONS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "33030090",
      "description": "PERFUMES AND TOILET WATERS - PERFUMES AND TOILET WATERS: OTHER"
    },
    {
      "code": "3304",
      "description": "BEAUTY O R MAKE - U P PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS"
    },
    {
      "code": "33041000",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS LIP MAKE-UP PREPARATIONS"
    },
    {
      "code": "33042000",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS EYE MAKE-UP PREPARATIONS"
    },
    {
      "code": "33043000",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS MANICURE OR PEDICURE PREPARATIONS"
    },
    {
      "code": "330491",
      "description": "Powders, whether or not compressed"
    },
    {
      "code": "33049110",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: POWDERS, WHETHER OR NOT COMPRESSED: FACE POWDERS"
    },
    {
      "code": "33049120",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: POWDERS, WHETHER OR NOT COMPRESSED: TALCUM POWDERS"
    },
    {
      "code": "33049190",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: POWDERS, WHETHER OR NOT COMPRESSED: OTHER"
    },
    {
      "code": "330499",
      "description": "Other"
    },
    {
      "code": "33049910",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER: FACE CREAMS"
    },
    {
      "code": "33049920",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER NAIL POLISH OR LACQUERS"
    },
    {
      "code": "33049930",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER MOISTURISING LOTION"
    },
    {
      "code": "33049940",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER SINDUR, BINDI, KUMKUM"
    },
    {
      "code": "33049950",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER TURMERIC PREPARATIONS"
    },
    {
      "code": "33049990",
      "description": "BEAUTY OR MAKE-UP PREPARATIONS AND PREPARATIONS FOR THE CARE OF THE SKIN (OTHER THAN MEDICAMENTS), INCLUDING SUNSCREEN OR SUNTAN PREPARATIONS; MANICURE OR PEDICURE PREPARATIONS OTHER: OTHER OTHER"
    },
    {
      "code": "3305",
      "description": "PREPARATIONS FOR USE ON THE HAIR"
    },
    {
      "code": "330510",
      "description": "Shampoos"
    },
    {
      "code": "33051010",
      "description": "PREPARATIONS FOR USE ON THE HAIR SHAMPOOS: CONTAINING SPIRIT"
    },
    {
      "code": "33051090",
      "description": "PREPARATIONS FOR USE ON THE HAIR SHAMPOOS: OTHER"
    },
    {
      "code": "33052000",
      "description": "PREPARATIONS FOR USE ON THE HAIR PREPARATIONS FOR PERMANENT WAVING OR STRAIGHTENING"
    },
    {
      "code": "33053000",
      "description": "PREPARATIONS FOR USE ON THE HAIR HAIR LACQUERS"
    },
    {
      "code": "330590",
      "description": "Other"
    },
    {
      "code": "33059011",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: HAIR OIL: PERFUMED"
    },
    {
      "code": "33059019",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: HAIR OIL: OTHER"
    },
    {
      "code": "33059020",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: BRILLIANTINES (SPIRITUOUS)"
    },
    {
      "code": "33059030",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: HAIR CREAM"
    },
    {
      "code": "33059040",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: HAIR DYES (NATURAL, HERBAL OR SYNTHETIC)"
    },
    {
      "code": "33059050",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: HAIR FIXERS"
    },
    {
      "code": "33059090",
      "description": "PREPARATIONS FOR USE ON THE HAIR OTHER: OTHER"
    },
    {
      "code": "3306",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES"
    },
    {
      "code": "330610",
      "description": "Dentifrices"
    },
    {
      "code": "33061010",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES DENTIFRICES: IN POWDER"
    },
    {
      "code": "33061020",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES DENTIFRICES: IN PASTE"
    },
    {
      "code": "33061090",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES DENTIFRICES: OTHER"
    },
    {
      "code": "33062000",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS)"
    },
    {
      "code": "33069000",
      "description": "PREPARATIONS FOR ORAL OR DENTAL HYGIENE, INCLUDING DENTURE FIXATIVE PASTES AND POWDERS; YARN USED TO CLEAN BETWEEN THE TEETH (DENTAL FLOSS), IN INDIVIDUAL RETAIL PACKAGES OTHER"
    },
    {
      "code": "3307",
      "description": "PR E - S H AV E , S H AV I N G O R AFTER- SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS , DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES"
    },
    {
      "code": "330710",
      "description": "Pre-shave, shaving or after-shave preparations"
    },
    {
      "code": "33071010",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS: SHAVING CREAM"
    },
    {
      "code": "33071090",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS: OTHER"
    },
    {
      "code": "33072000",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PERSONAL DEODORANTS AND ANTI-PERSPIRANTS"
    },
    {
      "code": "330730",
      "description": "Perfumed bath salts and other bath preparations"
    },
    {
      "code": "33073010",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PERFUMED BATH SALTS AND OTHER BATH PREPARATIONS: BATH OIL (THAILAM)"
    },
    {
      "code": "33073090",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PERFUMED BATH SALTS AND OTHER BATH PREPARATIONS: OTHER"
    },
    {
      "code": "33074100",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PREPARATIONS FOR PERFUMING OR DEODORIZING ROOMS, INCLUDING ODORIFEROUS PREPARATIONS USED DURING RELIGIOUS RITES: AGARBATTI AND OTHER ODORIFEROUS PREPARATIONS WHICH OPERATE BY BURNING"
    },
    {
      "code": "33074900",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES PREPARATIONS FOR PERFUMING OR DEODORIZING ROOMS, INCLUDING ODORIFEROUS PREPARATIONS USED DURING RELIGIOUS RITES: OTHER"
    },
    {
      "code": "330790",
      "description": "Other"
    },
    {
      "code": "33079010",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES - OTHER: DEPILATORIES"
    },
    {
      "code": "33079020",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES - OTHER: STERILE CONTACT LENS CARE SOLUTION"
    },
    {
      "code": "33079090",
      "description": "PRE-SHAVE, SHAVING OR AFTER-SHAVE PREPARATIONS, PERSONAL DEODORANTS, BATH PREPARATIONS, DEPILATORIES AND OTHER PERFUMERY, COSMETIC OR TOILET PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED, PREPARED ROOM DEODORISERS, WHETHER OR NOT PERFUMED OR HAVING DISINFECTANT PROPERTIES - OTHER: OTHER"
    },
    {
      "code": "34",
      "description": "Soap, organic surface-active agents, washing preparations, lubricating preparations, artificial waxes, prepared waxes, polishing or scouring preparations, candles and similar articles, modelling pastes, dental waxes and dental preparations with a basis of plaster"
    },
    {
      "code": "3401",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS, CAKES, MOULDED PIECES O R SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT "
    },
    {
      "code": "340111",
      "description": "For toilet use (including medicated products)"
    },
    {
      "code": "34011110",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): MEDICATED TOILET SOAPS"
    },
    {
      "code": "34011120",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): SHAVING SOAPS OTHER THAN SHAVING CREAM"
    },
    {
      "code": "34011190",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): OTHER"
    },
    {
      "code": "340119",
      "description": "Other"
    },
    {
      "code": "34011911",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: BARS AND BLOCKS OF NOT LESS THAN 500 GM IN WEIGHT: INDUSTRIAL SOAP"
    },
    {
      "code": "34011919",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: BARS AND BLOCKS OF NOT LESS THAN 500 GM IN WEIGHT: OTHER"
    },
    {
      "code": "34011920",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: FLAKES, CHIPS AND POWDER"
    },
    {
      "code": "34011930",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: TABLETS AND CAKES"
    },
    {
      "code": "34011941",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: HOUSEHOLD AND LAUNDRY SOAPS NOT ELSEWHERE SPECIFIED OR INCLUDED HOUSEHOLD SOAPS"
    },
    {
      "code": "34011942",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: HOUSEHOLD AND LAUNDRY SOAPS NOT ELSEWHERE SPECIFIED OR INCLUDED LAUNDRY SOAPS"
    },
    {
      "code": "34011990",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP AND ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS, IN THE FORM OF BARS, CAKES,MOULDED PIECES OR SHAPES, AND PAPER,WADDING, FELT AND NONWOVENS, IMPREGNATED,COATED OR COVERED WITH SOAP OR DETERGENT: OTHER: OTHER"
    },
    {
      "code": "34012000",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT SOAP IN OTHER FORMS"
    },
    {
      "code": "340130",
      "description": "Organic surface-active products and preparations for washing the skin, in the form of liquid or cream and put up for retail sale, whether or not containing soap"
    },
    {
      "code": "34013011",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE,WHETHER OR NOT CONTAINING SOAP : FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): MEDICATED TOILET SOAPS"
    },
    {
      "code": "34013012",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE,WHETHER OR NOT CONTAINING SOAP : FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): SHAVING CREAM AND SHAVING GEL"
    },
    {
      "code": "34013019",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE,WHETHER OR NOT CONTAINING SOAP : FOR TOILET USE (INCLUDING MEDICATED PRODUCTS): OTHER"
    },
    {
      "code": "34013090",
      "description": "SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR USE AS SOAP, IN THE FORM OF BARS,CAKES, MOULDED PIECES OR SHAPES, WHETHER OR NOT CONTAINING SOAP; ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE, WHETHER OR NOT CONTAINING SOAP; PAPER, WADDING, FELT AND NONWOVENS, IMPREGNATED, COATED OR COVERED WITH SOAP OR DETERGENT ORGANIC SURFACE-ACTIVE PRODUCTS AND PREPARATIONS FOR WASHING THE SKIN, IN THE FORM OF LIQUID OR CREAM AND PUT UP FOR RETAIL SALE,WHETHER OR NOT CONTAINING SOAP : OTHER"
    },
    {
      "code": "3402",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401"
    },
    {
      "code": "340211",
      "description": "Anionic"
    },
    {
      "code": "34021110",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 ORGANIC SURFACE-ACTIVE AGENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE: ANIONIC: SILICONE SURFACTANT"
    },
    {
      "code": "34021190",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 ORGANIC SURFACE-ACTIVE AGENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE: ANIONIC: OTHER"
    },
    {
      "code": "34021200",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 ORGANIC SURFACE-ACTIVE AGENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE: CATIONIC"
    },
    {
      "code": "34021300",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 ORGANIC SURFACE-ACTIVE AGENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE: NON-IONIC"
    },
    {
      "code": "34021900",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 ORGANIC SURFACE-ACTIVE AGENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE: OTHER"
    },
    {
      "code": "340220",
      "description": "Preparations put up for retail sale"
    },
    {
      "code": "34022010",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 PREPARATIONS PUT UP FOR RETAIL SALE: WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34022020",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 PREPARATIONS PUT UP FOR RETAIL SALE: CLEANING OR DEGREASING PREPARATIONS NOT HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34022090",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 PREPARATIONS PUT UP FOR RETAIL SALE: OTHER"
    },
    {
      "code": "340290",
      "description": "Other;"
    },
    {
      "code": "34029011",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: SYNTHETIC DETERGENTS: WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029012",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: SYNTHETIC DETERGENTS: CLEANING OR DEGREASING PREPARATIONS NOT HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029019",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: SYNTHETIC DETERGENTS: OTHER"
    },
    {
      "code": "34029020",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: SULPHONATED OR SULPHATED OR OXIDIZED OR CHLORINATED CASTOR OIL; SULPHONATED OR SULPHATED OR OXIDIZED OR CHLORINATED FISH OIL; SULPHONATED OR SULPHATED OR OXIDIZED OR CHLORINATED SPERM OIL; SULPHONATED OR SULPHATED OR OXIDIZED OR CHLORINATED NEATS FOOT OIL"
    },
    {
      "code": "34029030",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: PENETRATORS"
    },
    {
      "code": "34029041",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WETTING AGENTS: WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029042",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WETTING AGENTS: CLEANING OR DEGREASING PREPARATIONS NOT HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029049",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WETTING AGENTS: OTHER"
    },
    {
      "code": "34029051",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WASHING PREPARATIONS WHETHER OR NOT CONTAINING SOAP : WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029052",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WASHING PREPARATIONS WHETHER OR NOT CONTAINING SOAP : CLEANING OR DEGREASING PREPARATIONS NOT HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029059",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: WASHING PREPARATIONS WHETHER OR NOT CONTAINING SOAP : OTHER"
    },
    {
      "code": "34029091",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: OTHER: WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029092",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: OTHER: CLEANING OR DEGREASING PREPARATIONS NOT HAVING A BASIS OF SOAP OR OTHER ORGANIC SURFACE ACTIVE AGENTS"
    },
    {
      "code": "34029099",
      "description": "ORGANIC SURFACE-ACTIVE AGENTS (OTHER THAN SOAP), SURFACE-ACTIVE PREPARATIONS, WASHING PREPARATIONS (INCLUDING AUXILIARY WASHING PREPARATIONS) AND CLEANING PREPARATIONS, WHETHER OR NOT CONTAINING SOAP, OTHER THAN THOSE OF HEADING 3401 OTHER: OTHER: OTHER"
    },
    {
      "code": "3403",
      "description": "LUBRICATING PREPARATIONS (INCLUDING CUTTINGOIL PREPARATIONS, BOLT OR NUT RELEASE PREPARATIONS, ANTI-RUST OR ANTI-CORROSION PREPARATIONS AND MOULD RELEASE PREPARATIONS, BASED ON LUBRICANTS) AND PREPARATIONS OF A KIND USED FOR THE OIL OR GREASE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS, BUT EXCLUDING PREPARATIONS CONTAINING, AS BASIC CONSTITUENTS, 70 % OR MORE BY WEIGHT OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "34031100",
      "description": "LUBRICATING PREPARATIONS (INCLUDING CUTTING-OIL PREPARATIONS, BOLT OR NUT RELEASE PREPARATIONS,ANTI-RUST OR ANTI-CORROSION PREPARATIONS AND MOULD RELEASE PREPARATIONS, BASED ON LUBRICANTS) AND PREPARATIONS OF A KIND USED FOR THE OIL OR GREASE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS, BUT EXCLUDING PREPARATIONS CONTAINING, AS BASIC CONSTITUENTS, 70 % OR MORE BY WEIGHT OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS CONTAINING PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS: PREPARATIONS FOR THE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS"
    },
    {
      "code": "34031900",
      "description": "LUBRICATING PREPARATIONS (INCLUDING CUTTING-OIL PREPARATIONS, BOLT OR NUT RELEASE PREPARATIONS,ANTI-RUST OR ANTI-CORROSION PREPARATIONS AND MOULD RELEASE PREPARATIONS, BASED ON LUBRICANTS) AND PREPARATIONS OF A KIND USED FOR THE OIL OR GREASE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS, BUT EXCLUDING PREPARATIONS CONTAINING, AS BASIC CONSTITUENTS, 70 % OR MORE BY WEIGHT OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS CONTAINING PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS: OTHER"
    },
    {
      "code": "34039100",
      "description": "LUBRICATING PREPARATIONS (INCLUDING CUTTING-OIL PREPARATIONS, BOLT OR NUT RELEASE PREPARATIONS,ANTI-RUST OR ANTI-CORROSION PREPARATIONS AND MOULD RELEASE PREPARATIONS, BASED ON LUBRICANTS) AND PREPARATIONS OF A KIND USED FOR THE OIL OR GREASE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS, BUT EXCLUDING PREPARATIONS CONTAINING, AS BASIC CONSTITUENTS, 70 % OR MORE BY WEIGHT OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS OTHER: PREPARATIONS FOR THE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS"
    },
    {
      "code": "34039900",
      "description": "LUBRICATING PREPARATIONS (INCLUDING CUTTING-OIL PREPARATIONS, BOLT OR NUT RELEASE PREPARATIONS,ANTI-RUST OR ANTI-CORROSION PREPARATIONS AND MOULD RELEASE PREPARATIONS, BASED ON LUBRICANTS) AND PREPARATIONS OF A KIND USED FOR THE OIL OR GREASE TREATMENT OF TEXTILE MATERIALS, LEATHER, FURSKINS OR OTHER MATERIALS, BUT EXCLUDING PREPARATIONS CONTAINING, AS BASIC CONSTITUENTS, 70 % OR MORE BY WEIGHT OF PETROLEUM OILS OR OF OILS OBTAINED FROM BITUMINOUS MINERALS OTHER: OTHER"
    },
    {
      "code": "3404",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES"
    },
    {
      "code": "34041000",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OF CHEMICALLY MODIFIED LIGNITE"
    },
    {
      "code": "34042000",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OF POLY (OXYETHYLENE) (POLYETHYLENE GLYCOL)"
    },
    {
      "code": "340490",
      "description": "Other"
    },
    {
      "code": "34049010",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: SEALING WAX (INCLUDING BOTTLE SEALING WAX) IN STICKS, CAKES OR SIMILAR FORMS"
    },
    {
      "code": "34049020",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: POLYETHYLENE WAX"
    },
    {
      "code": "34049031",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: ARTIFICIAL WAXES (INCLUDING WATER SOLUBLE WAXES) PREPARED WAXES, NOT EMULSIFIED OR CONTAINING SOLVENTS: POLY BROMINATED BIPHENYLS"
    },
    {
      "code": "34049032",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: ARTIFICIAL WAXES (INCLUDING WATER SOLUBLE WAXES) PREPARED WAXES, NOT EMULSIFIED OR CONTAINING SOLVENTS: POLY CHLORINATED BIPHENYLS"
    },
    {
      "code": "34049033",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: ARTIFICIAL WAXES (INCLUDING WATER SOLUBLE WAXES) PREPARED WAXES, NOT EMULSIFIED OR CONTAINING SOLVENTS: POLY CHLORINATED TERPHENYLS"
    },
    {
      "code": "34049039",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: ARTIFICIAL WAXES (INCLUDING WATER SOLUBLE WAXES) PREPARED WAXES, NOT EMULSIFIED OR CONTAINING SOLVENTS: OTHER"
    },
    {
      "code": "34049090",
      "description": "ARTIFICIAL WAXES AND PREPARED WAXES OTHER: OTHER"
    },
    {
      "code": "3405",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS , CELLULAR PLASTICS O R CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404"
    },
    {
      "code": "34051000",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 POLISHES, CREAMS AND SIMILAR PREPARATIONS FOR FOOTWEAR OR LEATHER"
    },
    {
      "code": "34052000",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 POLISHES, CREAMS AND SIMILAR PREPARATIONS FOR THE MAINTENANCE OF WOODEN FURNITURE, FLOORS OR OTHER WOOD WORK"
    },
    {
      "code": "34053000",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 POLISHES AND SIMILAR PREPARATIONS FOR COACH-WORK, OTHER THAN METAL POLISHES"
    },
    {
      "code": "34054000",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 SCOURING PASTES AND POWDERS AND OTHER"
    },
    {
      "code": "340590",
      "description": "Other"
    },
    {
      "code": "34059010",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 OTHER: POLISHES AND COMPOSITIONS FOR APPLICATION TO METAL INCLUDING DIAMOND POLISHING POWDER OR PASTE"
    },
    {
      "code": "34059090",
      "description": "POLISHES AND CREAMS, FOR FOOTWEAR, FURNITURE, FLOORS, COACHWORK, GLASS OR METAL, SCOURING PASTES AND POWDERS AND SIMILAR PREPARATIONS (WHETHER OR NOT IN THE FORM OF PAPER, WADDING, FELT, NONWOVENS,CELLULAR PLASTICS OR CELLULAR RUBBER, IMPREGNATED, COATED OR COVERED WITH SUCH PREPARATIONS), EXCLUDING WAXES OF HEADING 3404 OTHER: OTHER"
    },
    {
      "code": "3406",
      "description": "CANDLES, TAPERS AND THE LIKE"
    },
    {
      "code": "340600",
      "description": "Candles, tapers and the like"
    },
    {
      "code": "34060010",
      "description": "CANDLES, TAPERS AND THE LIKE CANDLES, TAPERS AND THE LIKE: CANDLES"
    },
    {
      "code": "34060090",
      "description": "CANDLES, TAPERS AND THE LIKE CANDLES, TAPERS AND THE LIKE: OTHER"
    },
    {
      "code": "3407",
      "description": "MODELLING PASTES, INCLUDING THOSE PUT UP FOR CHILDRENS AMUSEMENT; PREPARATIONS KNOWN AS  DENTAL WAX  OR AS  DENTAL IMPRESSION COMPOUNDS, PUT UP IN SETS, IN PACKINGS FOR RETAIL SALE OR IN PLATES, HORSESHOE SHAPES, STICKS OR SIMILAR FORMS; OTHER PREPARATIONS FOR USE IN DENTISTRY, WITH A BASIS OF PLASTER (OF CALCINED GYPSUM OR CALCIUM SULPHATE)"
    },
    {
      "code": "340700",
      "description": "Modelling pastes, including those put up for childrens amusement; preparations known as dental wax or as dental impression compounds, put up in sets, in packings for retail sale or in plates, horseshoe shapes, sticks or similar forms; other preparations for use in dentistry, with a basis of plaster (of calcined gypsum or calcium sulphate)"
    },
    {
      "code": "34070010",
      "description": "MODELLING PASTES, INCLUDING THOSE PUT UP FOR CHILDRENS AMUSEMENT; PREPARATIONS KNOWN AS DENTAL WAX OR AS DENTAL IMPRESSION COMPOUNDS, PUT UP IN SETS, IN PACKINGS FOR RETAIL SALE OR IN PLATES, HORSESHOE SHAPES, STICKS OR SIMILAR FORMS; OTHER PREPARATIONS FOR USE IN DENTISTRY, WITH A BASIS OF PLASTER (OF CALCINED GYPSUM OR CALCIUM SULPHATE) MODELLING PASTES, INCLUDING THOSE PUT UP FORCHILDRENS AMUSEMENT; PREPARATIONS KNOWN AS DENTAL WAX OR AS DENTAL IMPRESSION COMPOUNDS, PUT UP IN SETS, IN PACKINGS FOR RETAIL SALE OR IN PLATES, HORSESHOE SHAPES,STICKS OR SIMILAR FORMS; OTHER PREPARATIONS FOR USE IN DENTISTRY, WITH A BASIS OF PLASTER (OF CALCINED GYPSUM OR CALCIUM SULPHATE): MODELLING PASTES, INCLUDING THOSE PUT UP FOR CHILDRENS AMUSEMENT"
    },
    {
      "code": "34070090",
      "description": "MODELLING PASTES, INCLUDING THOSE PUT UP FOR CHILDRENS AMUSEMENT; PREPARATIONS KNOWN AS DENTAL WAX OR AS DENTAL IMPRESSION COMPOUNDS, PUT UP IN SETS, IN PACKINGS FOR RETAIL SALE OR IN PLATES, HORSESHOE SHAPES, STICKS OR SIMILAR FORMS; OTHER PREPARATIONS FOR USE IN DENTISTRY, WITH A BASIS OF PLASTER (OF CALCINED GYPSUM OR CALCIUM SULPHATE) MODELLING PASTES, INCLUDING THOSE PUT UP FORCHILDRENS AMUSEMENT; PREPARATIONS KNOWN AS DENTAL WAX OR AS DENTAL IMPRESSION COMPOUNDS, PUT UP IN SETS, IN PACKINGS FOR RETAIL SALE OR IN PLATES, HORSESHOE SHAPES,STICKS OR SIMILAR FORMS; OTHER PREPARATIONS FOR USE IN DENTISTRY, WITH A BASIS OF PLASTER (OF CALCINED GYPSUM OR CALCIUM SULPHATE): OTHER"
    },
    {
      "code": "35",
      "description": "Albuminoidal substances; modified starches; glues; enzymes"
    },
    {
      "code": "3501",
      "description": "CASEIN, CASEINATES AND OTHER CASEIN DERIVATIVES; CASEIN GLUES"
    },
    {
      "code": "35011000",
      "description": "CASEIN, CASEINATES AND OTHER CASEIN DERIVATIVES; CASEIN GLUES CASEIN"
    },
    {
      "code": "35019000",
      "description": "CASEIN, CASEINATES AND OTHER CASEIN DERIVATIVES; CASEIN GLUES OTHER"
    },
    {
      "code": "3502",
      "description": "ALBUMINS (INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS, CONTAINING BY WEIGHT MORE THAN 80% WHEY PROTEINS, CALCULATED ON THE DRY MATTER), ALBUMINATES AND OTHER ALBUMIN DERIVATIVES"
    },
    {
      "code": "35021100",
      "description": "ALBUMINS (INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS, CONTAINING BY WEIGHT MORE THAN 80% WHEY PROTEINS, CALCULATED ON THE DRY MATTER), ALBUMINATES AND OTHER ALBUMIN DERIVATIVES EGG ALBUMIN: DRIED"
    },
    {
      "code": "35021900",
      "description": "ALBUMINS (INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS, CONTAINING BY WEIGHT MORE THAN 80% WHEY PROTEINS, CALCULATED ON THE DRY MATTER), ALBUMINATES AND OTHER ALBUMIN DERIVATIVES EGG ALBUMIN: OTHER"
    },
    {
      "code": "35022000",
      "description": "ALBUMINS (INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS, CONTAINING BY WEIGHT MORE THAN 80% WHEY PROTEINS, CALCULATED ON THE DRY MATTER), ALBUMINATES AND OTHER ALBUMIN DERIVATIVES MILK ALBUMIN, INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS"
    },
    {
      "code": "35029000",
      "description": "ALBUMINS (INCLUDING CONCENTRATES OF TWO OR MORE WHEY PROTEINS, CONTAINING BY WEIGHT MORE THAN 80% WHEY PROTEINS, CALCULATED ON THE DRY MATTER), ALBUMINATES AND OTHER ALBUMIN DERIVATIVES OTHER"
    },
    {
      "code": "3503",
      "description": "GELATIN [INCLUDING GELATIN IN RECTANGULAR (INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CASEIN GLUES OF HEADING 3501"
    },
    {
      "code": "350300",
      "description": "Gelatin [including gelatin in rectangular (including square) sheets, whether or not surface-worked or coloured] and gelatin derivatives; isinglass; other glues of animal origin, excluding casein glues of heading 3501"
    },
    {
      "code": "35030010",
      "description": "GELATIN [INCLUDING GELATIN IN RECTANGULAR (INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CASEIN GLUES OF HEADING 3501 GELATIN [INCLUDING GELATIN IN RECTANGULAR(INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CAS EIN GLUES OF HEADING 3501: ISINGLASS"
    },
    {
      "code": "35030020",
      "description": "GELATIN [INCLUDING GELATIN IN RECTANGULAR (INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CASEIN GLUES OF HEADING 3501 GELATIN [INCLUDING GELATIN IN RECTANGULAR(INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CAS EIN GLUES OF HEADING 3501: GELATIN, EDIBLE GRADE AND NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "35030030",
      "description": "GELATIN [INCLUDING GELATIN IN RECTANGULAR (INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CASEIN GLUES OF HEADING 3501 GELATIN [INCLUDING GELATIN IN RECTANGULAR(INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CAS EIN GLUES OF HEADING 3501: GLUES DERIVED FROM BONES, HIDES AND SIMILAR ITEMS; FISH GLUES"
    },
    {
      "code": "35030090",
      "description": "GELATIN [INCLUDING GELATIN IN RECTANGULAR (INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CASEIN GLUES OF HEADING 3501 GELATIN [INCLUDING GELATIN IN RECTANGULAR(INCLUDING SQUARE) SHEETS, WHETHER OR NOT SURFACE-WORKED OR COLOURED] AND GELATIN DERIVATIVES; ISINGLASS; OTHER GLUES OF ANIMAL ORIGIN, EXCLUDING CAS EIN GLUES OF HEADING 3501: OTHER"
    },
    {
      "code": "3504",
      "description": "PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED"
    },
    {
      "code": "350400",
      "description": "Peptones and their derivatives; other protein substances and their derivatives, not elsewhere specified or included; hide powder, whether or not chromed"
    },
    {
      "code": "35040010",
      "description": "PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED: PEPTONES"
    },
    {
      "code": "35040091",
      "description": "PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED: OTHER: ISOLATED SOYA PROTEIN"
    },
    {
      "code": "35040099",
      "description": "PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED PEPTONES AND THEIR DERIVATIVES; OTHER PROTEIN SUBSTANCES AND THEIR DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; HIDE POWDER, WHETHER OR NOT CHROMED: OTHER: OTHERS"
    },
    {
      "code": "3505",
      "description": "DEXTRINS AND OTHER MODIFIED STARCHES (FOR EXAMPLE, PREGELATINISED OR ESTERIFIED STARCHES); GLUES BASED ON STARCHES, OR ON DEXTRINS OR OTHER MODIFIED STARCHES"
    },
    {
      "code": "350510",
      "description": "Dextrins and other modified starches"
    },
    {
      "code": "35051010",
      "description": "DEXTRINS AND OTHER MODIFIED STARCHES (FOR EXAMPLE, PREGELATINISED OR ESTERIFIED STARCHES); GLUES BASED ON STARCHES, OR ON DEXTRINS OR OTHER MODIFIED STARCHES DEXTRINS AND OTHER MODIFIED STARCHES: ESTERIFIED STARCHES"
    },
    {
      "code": "35051090",
      "description": "DEXTRINS AND OTHER MODIFIED STARCHES (FOR EXAMPLE, PREGELATINISED OR ESTERIFIED STARCHES); GLUES BASED ON STARCHES, OR ON DEXTRINS OR OTHER MODIFIED STARCHES DEXTRINS AND OTHER MODIFIED STARCHES: OTHER"
    },
    {
      "code": "35052000",
      "description": "DEXTRINS AND OTHER MODIFIED STARCHES (FOR EXAMPLE, PREGELATINISED OR ESTERIFIED STARCHES); GLUES BASED ON STARCHES, OR ON DEXTRINS OR OTHER MODIFIED STARCHES GLUES"
    },
    {
      "code": "3506",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 kg."
    },
    {
      "code": "35061000",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1KG : PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1KG"
    },
    {
      "code": "350691",
      "description": "Adhesives based on polymers of headings 3901 to 3913 or on rubber"
    },
    {
      "code": "35069110",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 KG : OTHER: ADHESIVES BASED ON POLYMERS OF HEADINGS 3901 TO 3913 OR ON RUBBER: BASED ON LATEX, PHENOL FORMALDEHYDE (PF), UREA FORMALDEHYDE (UF) AND POLYVINYL ALCOHOL (PVA)"
    },
    {
      "code": "35069190",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 KG : OTHER: ADHESIVES BASED ON POLYMERS OF HEADINGS 3901 TO 3913 OR ON RUBBER: OTHER"
    },
    {
      "code": "350699",
      "description": "Other"
    },
    {
      "code": "35069910",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 KG : OTHER: OTHER: SYNTHETIC GLUE WITH PHENOL UREA OR CRESOL (WITH FORMALDEHYDE) AS THE MAIN COMPONENT"
    },
    {
      "code": "35069991",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 KG : OTHER: OTHER: PREPARED GLUES AND OTHER PREPARED ADHESIVES NOT ELSEWHERE SPECIFIED OR INCLUDED: BASED ON STARCH, GUM, LATEX, PF, UF AND PVA"
    },
    {
      "code": "35069999",
      "description": "PREPARED GLUES AND OTHER PREPARED ADHESIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED; PRODUCTS SUITABLE FOR USE AS GLUES OR ADHESIVES, PUT UP FOR RETAIL SALE AS GLUES OR ADHESIVES, NOT EXCEEDING A NET WEIGHT OF 1 KG : OTHER: OTHER: PREPARED GLUES AND OTHER PREPARED ADHESIVES NOT ELSEWHERE SPECIFIED OR INCLUDED: OTHER"
    },
    {
      "code": "3507",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "350710",
      "description": "Rennet and concentrates thereof"
    },
    {
      "code": "35071011",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED RENNET AND CONCENTRATES THEREOF: MICROBIAL RENNET: ANIMAL RENNET"
    },
    {
      "code": "35071019",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED RENNET AND CONCENTRATES THEREOF: MICROBIAL RENNET: OTHER"
    },
    {
      "code": "35071091",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED RENNET AND CONCENTRATES THEREOF: OTHER: ANIMAL RENNET"
    },
    {
      "code": "35071099",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED RENNET AND CONCENTRATES THEREOF: OTHER: OTHER"
    },
    {
      "code": "350790",
      "description": "Other"
    },
    {
      "code": "35079010",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: INDUSTRIAL ENZYMES (TEXTILE ASSISTANT)"
    },
    {
      "code": "35079020",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: PANCRETIN PURE (EXCLUDING MEDICAMENT)"
    },
    {
      "code": "35079030",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: PEPSIN (EXCLUDING MEDICAMENT)"
    },
    {
      "code": "35079040",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: PECTIN ESTERASES PURE"
    },
    {
      "code": "35079050",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: PECTOLYTIC ENZYME (PECTIMASE)"
    },
    {
      "code": "35079061",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OTHER ENZYMES OF MICROBIAL ORIGIN: STREPTOKINASE"
    },
    {
      "code": "35079062",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OTHER ENZYMES OF MICROBIAL ORIGIN: AMYLASES ENZYMES"
    },
    {
      "code": "35079069",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OTHER ENZYMES OF MICROBIAL ORIGIN: OTHER"
    },
    {
      "code": "35079071",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ENZYMES FOR PHARMACEUTICAL USE, OTHER THAN STREPTOKINASE: PAPAIN, PURE, OF PHARMACEUTICAL GRADE"
    },
    {
      "code": "35079079",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ENZYMES FOR PHARMACEUTICAL USE, OTHER THAN STREPTOKINASE: OTHER"
    },
    {
      "code": "35079091",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OTHER: ENZYMATIC PREPARATIONS CONTAINING FOOD STUFFS"
    },
    {
      "code": "35079099",
      "description": "ENZYMES; PREPARED ENZYMES NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OTHER: OTHER"
    },
    {
      "code": "36",
      "description": "Explosives; pyrotechnic products; matches; pyrophoric alloys; certain combustible preparations"
    },
    {
      "code": "3601",
      "description": "PROPELLANT POWDERS"
    },
    {
      "code": "360100",
      "description": "Propellant powders"
    },
    {
      "code": "36010010",
      "description": "PROPELLANT POWDERS PROPELLANT POWDERS: BLASTING POWDER"
    },
    {
      "code": "36010020",
      "description": "PROPELLANT POWDERS PROPELLANT POWDERS: GUN POWDER"
    },
    {
      "code": "36010090",
      "description": "PROPELLANT POWDERS PROPELLANT POWDERS: OTHER"
    },
    {
      "code": "3602",
      "description": "PREPARED EXPLOSIVES, OTHER THAN PROPELLANT POWDERS"
    },
    {
      "code": "360200",
      "description": "Prepared explosives, other than propellant powders"
    },
    {
      "code": "36020010",
      "description": "PREPARED EXPLOSIVES, OTHER THAN PROPELLANT POWDERS PREPARED EXPLOSIVES, OTHER THAN PROPELLANT POWDERS: INDUSTRIAL EXPLOSIVES"
    },
    {
      "code": "36020090",
      "description": "PREPARED EXPLOSIVES, OTHER THAN PROPELLANT POWDERS PREPARED EXPLOSIVES, OTHER THAN PROPELLANT POWDERS: OTHER"
    },
    {
      "code": "3603",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS"
    },
    {
      "code": "360300",
      "description": "Safety fuses; detonating fuses; percussion or detonating caps; igniters; electric detonators"
    },
    {
      "code": "36030011",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: SAFETY FUSES: FOR MINE BLASTING"
    },
    {
      "code": "36030019",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: OTHER"
    },
    {
      "code": "36030020",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: DETONATING FUSES"
    },
    {
      "code": "36030031",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: PERCUSSION AND DETONATING CAPS: NONORDNANCE"
    },
    {
      "code": "36030039",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: PERCUSSION AND DETONATING CAPS: OTHER"
    },
    {
      "code": "36030041",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: IGNITERS: NONORDNANCE"
    },
    {
      "code": "36030049",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: IGNITERS: OTHER"
    },
    {
      "code": "36030051",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: ELECTRIC DETONATORS: CONTAINING EXPLOSIVES ELECTRICALLY IGNITED, NONORDNANCE"
    },
    {
      "code": "36030059",
      "description": "SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS SAFETY FUSES; DETONATING FUSES; PERCUSSION OR DETONATING CAPS; IGNITERS; ELECTRIC DETONATORS: ELECTRIC DETONATORS: OTHER"
    },
    {
      "code": "3604",
      "description": "FIREWORKS, SIGNALLING FLARES, RAIN ROCKETS, FOG SIGNALS AND OTHER PYROTECHNIC ARTICLES"
    },
    {
      "code": "36041000",
      "description": "FIREWORKS, SIGNALLING FLARES, RAIN ROCKETS, FOG SIGNALS AND OTHER PYROTECHNIC ARTICLES FIREWORKS"
    },
    {
      "code": "360490",
      "description": "Other"
    },
    {
      "code": "36049010",
      "description": "FIREWORKS, SIGNALLING FLARES, RAIN ROCKETS, FOG SIGNALS AND OTHER PYROTECHNIC ARTICLES OTHER: SHIP SIGNALS"
    },
    {
      "code": "36049090",
      "description": "FIREWORKS, SIGNALLING FLARES, RAIN ROCKETS, FOG SIGNALS AND OTHER PYROTECHNIC ARTICLES OTHER: OTHER"
    },
    {
      "code": "3605",
      "description": "MATCHES, OTHER THAN PYROTECHNIC ARTICLES OF HEADING 3604"
    },
    {
      "code": "360500",
      "description": "Matches, other than pyrotechnic articles of heading 3604"
    },
    {
      "code": "36050010",
      "description": "MATCHES, OTHER THAN PYROTECHNIC ARTICLES OF HEADING 3604 MATCHES, OTHER THAN PYROTECHNIC ARTICLES OF HEADING 3604: SAFETY MATCHES"
    },
    {
      "code": "36050090",
      "description": "MATCHES, OTHER THAN PYROTECHNIC ARTICLES OF HEADING 3604 MATCHES, OTHER THAN PYROTECHNIC ARTICLES OF HEADING 3604: OTHER"
    },
    {
      "code": "3606",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER"
    },
    {
      "code": "36061000",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER LIQUID OR LIQUEFIED GAS FUELS IN CONTAINERS OF A KIND USED FOR FILLING OR REFILLING CIGARETTE OR SIMILAR LIGHTERS AND OF A CAPACITY NOT EXCEEDING 300 CM3"
    },
    {
      "code": "360690",
      "description": "Other"
    },
    {
      "code": "36069010",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER OTHER: COMBUSTIBLE PREPARATIONS"
    },
    {
      "code": "36069091",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER OTHER: OTHER: FERROCERIUM, IN ALL FORMS"
    },
    {
      "code": "36069092",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER OTHER: OTHER: PYROPHORIC ALLOYS, IN ALL FORMS"
    },
    {
      "code": "36069093",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER OTHER: OTHER: DNPT (DINITROSO PENTA METHYLENE TETRAMINE)"
    },
    {
      "code": "36069099",
      "description": "FERRO-CERIUM AND OTHER PYROPHORIC ALLOYS IN ALL FORMS; ARTICLES OF COMBUSTIBLE MATERIALS AS SPECIFIED IN NOTE 2 TO THIS CHAPTER OTHER: OTHER: OTHERS"
    },
    {
      "code": "37",
      "description": "Photographic or cinematographic goods"
    },
    {
      "code": "3701",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS"
    },
    {
      "code": "370110",
      "description": "For X-ray"
    },
    {
      "code": "37011010",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS FOR X-RAY: MEDICAL"
    },
    {
      "code": "37011090",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS FOR X-RAY: OTHER"
    },
    {
      "code": "37012000",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS INSTANT PRINT FILM"
    },
    {
      "code": "37013000",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS OTHER PLATES AND FILM, WITH ANY SIDE EXCEEDING 255 MM"
    },
    {
      "code": "370191",
      "description": "For colour photography (Polychrome)"
    },
    {
      "code": "37019110",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS OTHER: FOR COLOUR PHOTOGRAPHY (POLYCHROME): CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37019190",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS OTHER: FOR COLOUR PHOTOGRAPHY (POLYCHROME): OTHER"
    },
    {
      "code": "370199",
      "description": "Other"
    },
    {
      "code": "37019910",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS OTHER: OTHER: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37019990",
      "description": "PHOTOGRAPHIC PLATES AND FILM IN THE FLAT, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT-FILM IN THE FLAT, SENSITISED, UNEXPOSED, WHETHER OR NOT IN PACKS OTHER: OTHER: OTHER"
    },
    {
      "code": "3702",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED"
    },
    {
      "code": "37021000",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED FOR XRAY"
    },
    {
      "code": "37022000",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED INSTANT PRINT FILM"
    },
    {
      "code": "370231",
      "description": "For colour photography (Polychrome)"
    },
    {
      "code": "37023110",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: FOR COLOUR PHOTOGRAPHY (POLYCHROME): CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37023190",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: FOR COLOUR PHOTOGRAPHY (POLYCHROME): OTHER"
    },
    {
      "code": "370232",
      "description": "Other, with silver halide emulsion"
    },
    {
      "code": "37023210",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: OTHER, WITH SILVER HALIDE EMULSION: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37023290",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: OTHER, WITH SILVER HALIDE EMULSION: OTHER"
    },
    {
      "code": "370239",
      "description": "Other"
    },
    {
      "code": "37023910",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: OTHER: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37023990",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH NOT EXCEEDING 105 MM: OTHER: OTHER"
    },
    {
      "code": "370241",
      "description": "Of a width exceeding 610 mm and of a length"
    },
    {
      "code": "37024110",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH EXCEEDING 200 M, FOR COLOUR PHOTOGRAPHY (POLYCHROME): CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37024190",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH EXCEEDING 200 M, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OTHER"
    },
    {
      "code": "370242",
      "description": "Of a width exceeding 610 mm and of a length exceeding 200 m, other than for colour photography"
    },
    {
      "code": "37024210",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH EXCEEDING 200 M, OTHER THAN FOR COLOUR PHOTOGRAPHY: PHOTOGRAPHIC FILM OF A WIDTH 620 MM IN ROLLS"
    },
    {
      "code": "37024220",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH EXCEEDING 200 M, OTHER THAN FOR COLOUR PHOTOGRAPHY: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37024290",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH EXCEEDING 200 M, OTHER THAN FOR COLOUR PHOTOGRAPHY: OTHER"
    },
    {
      "code": "370243",
      "description": "Of a width exceeding 610 mm and of a length not exceeding 200 m"
    },
    {
      "code": "37024310",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH NOT EXCEEDING 200 M: PHOTOGRAPHIC FILMS (BLACK AND WHITE) OF A WIDTH 620 MM"
    },
    {
      "code": "37024320",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH NOT EXCEEDING 200 M: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37024390",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 610 MM AND OF A LENGTH NOT EXCEEDING 200 M: OTHER"
    },
    {
      "code": "370244",
      "description": "Of a width exceeding 105 mm but not exceeding 610 mm"
    },
    {
      "code": "37024410",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 105 MM BUT NOT EXCEEDING 610 MM: PHOTOGRAPHIC FILMS OF A WIDTH 120 MM IN ROLLS"
    },
    {
      "code": "37024420",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 105 MM BUT NOT EXCEEDING 610 MM: CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37024490",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, WITHOUT PERFORATIONS, OF A WIDTH EXCEEDING 105 MM: OF A WIDTH EXCEEDING 105 MM BUT NOT EXCEEDING 610 MM: OTHER"
    },
    {
      "code": "370251",
      "description": "Of a width not exceeding 16 mm and of a length not exceeding 14 m"
    },
    {
      "code": "370252",
      "description": "Of a width not exceeding 16 mm and of a length exceeding 14 m"
    },
    {
      "code": "37025210",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH NOT EXCEEDING 16 MM AND OF A LENGTH EXCEEDING 14 M: FINISHED ROLLS OF CINEMATOGRAPHIC POSITIVE"
    },
    {
      "code": "37025220",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH NOT EXCEEDING 16 MM AND OF A LENGTH EXCEEDING 14 M: OTHER CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37025290",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH NOT EXCEEDING 16 MM AND OF A LENGTH EXCEEDING 14 M: OTHER"
    },
    {
      "code": "37025300",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30 M, FOR SLIDES"
    },
    {
      "code": "370254",
      "description": "Of a width exceeding 16 mm but not exceeding 35 mm and of a length not exceeding 30 m, other than for slides"
    },
    {
      "code": "37025410",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30 M, OTHER THAN FOR SLIDES: FINISHED ROLLS OF CINEMATOGRAPHIC POSITIVE"
    },
    {
      "code": "37025420",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30 M, OTHER THAN FOR SLIDES: OTHER CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37025490",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30 M, OTHER THAN FOR SLIDES: OTHER"
    },
    {
      "code": "370255",
      "description": "Of a width exceeding 16 mm but not exceeding 35 mm and of a length exceeding 30 m"
    },
    {
      "code": "37025510",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH EXCEEDING 30 M: FINISHED ROLLS OF CINEMATOGRAPHIC POSITIVE"
    },
    {
      "code": "37025520",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH EXCEEDING 30 M: OTHER CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37025590",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 16 MM BUT NOT EXCEEDING 35 MM AND OF A LENGTH EXCEEDING 30 M: OTHER"
    },
    {
      "code": "370256",
      "description": "Of a width exceeding 35 mm"
    },
    {
      "code": "37025610",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 35 MM: FINISHED ROLLS OF CINEMATOGRAPHIC POSITIVE"
    },
    {
      "code": "37025620",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 35 MM: OTHER CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37025690",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPER-BOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED OTHER FILM, FOR COLOUR PHOTOGRAPHY (POLYCHROME): OF A WIDTH EXCEEDING 35 MM: OTHER"
    },
    {
      "code": "370293",
      "description": "Of a width exceeding 16 mm but not exceeding 35 mm and of a length not exceeding 30 m"
    },
    {
      "code": "370294",
      "description": "Of a width exceeding 16 mm but not"
    },
    {
      "code": "370295",
      "description": "Of a width exceeding 35 mm"
    },
    {
      "code": "370296",
      "description": "Of a width not exceeding 35 mm and of a length not exceeding 30 m"
    },
    {
      "code": "37029611",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30M---CINEMATOGRAPHIC FILM----NOT EXCEEDING 16 MM"
    },
    {
      "code": "37029619",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH NOT EXCEEDING 35 MM AND OF A LENGTH NOT EXCEEDING 30M---CINEMATOGRAPHIC FILM----OTHER"
    },
    {
      "code": "370297",
      "description": "Of a width not exceeding 35 mm and of a length exceeding 30 m Cinematographic film"
    },
    {
      "code": "37029711",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH NOT EXCEEDING 35 MM AND OF A  LENGTH EXCEEDING 30 M ---CINEMATOGRAPHIC FILM----NOT EXCEEDING 16 MM"
    },
    {
      "code": "37029719",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH NOT EXCEEDING 35 MM AND OF A LENGTH EXCEEDING 30 M --- CINEMATOGRAPHIC FILM----OTHER"
    },
    {
      "code": "370298",
      "description": "Of a width exceeding 35 mm"
    },
    {
      "code": "37029810",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH EXCEEDING 35 MM---CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37029890",
      "description": "PHOTOGRAPHIC FILM IN ROLLS, SENSITISED, UNEXPOSED, OF ANY MATERIAL OTHER THAN PAPER, PAPERBOARD OR TEXTILES; INSTANT PRINT FILM IN ROLLS, SENSITISED, UNEXPOSED-OTHER--OF A WIDTH  EXCEEDING 35 MM---OTHER"
    },
    {
      "code": "3703",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED"
    },
    {
      "code": "370310",
      "description": "In rolls of a width exceeding 610 mm"
    },
    {
      "code": "37031010",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED IN ROLLS OF A WIDTH EXCEEDING 610 MM: PHOTOGRAPHIC PAPER OR PAPERBOARD"
    },
    {
      "code": "37031020",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED IN ROLLS OF A WIDTH EXCEEDING 610 MM: TEXTILES"
    },
    {
      "code": "370320",
      "description": "Other, for colour photography (polychrome)"
    },
    {
      "code": "37032010",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER, FOR COLOUR PHOTOGRAPHY (POLYCHROME): PHOTOGRAPHIC PAPER OR PAPERBOARD"
    },
    {
      "code": "37032020",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER, FOR COLOUR PHOTOGRAPHY (POLYCHROME): TEXTILES"
    },
    {
      "code": "370390",
      "description": "Other"
    },
    {
      "code": "37039010",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER: PHOTOGRAPHIC PAPER OR PAPERBOARD"
    },
    {
      "code": "37039020",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER: TEXTILES"
    },
    {
      "code": "3704",
      "description": "PHOTOGRAPHIC PLATES, FILM, PAPER, PAPERBOARD AND TEXTILES, EXPOSED BUT NOT DEVELOPED"
    },
    {
      "code": "370400",
      "description": "Photographic plates, film, paper, paper board and textiles, exposed but not developed"
    },
    {
      "code": "37040010",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED PHOTOGRAPHIC PLATES, FILM, PAPER, PAPER BOARD AND TEXTILES, EXPOSED BUT NOT DEVELOPED: PHOTOGRAPHIC PAPER, OR PAPERBOARD"
    },
    {
      "code": "37040020",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED PHOTOGRAPHIC PLATES, FILM, PAPER, PAPER BOARD AND TEXTILES, EXPOSED BUT NOT DEVELOPED: CINEMATOGRAPHIC PLATES AND FILM"
    },
    {
      "code": "37040030",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED PHOTOGRAPHIC PLATES, FILM, PAPER, PAPER BOARD AND TEXTILES, EXPOSED BUT NOT DEVELOPED: SENSITISED TEXTILES"
    },
    {
      "code": "37040090",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED PHOTOGRAPHIC PLATES, FILM, PAPER, PAPER BOARD AND TEXTILES, EXPOSED BUT NOT DEVELOPED: OTHER"
    },
    {
      "code": "3705",
      "description": "PHOTOGRAPHIC PLATES AND FILM, EXPOSED AND DEVELOPED, OTHER THAN CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37050000",
      "description": "PHOTOGRAPHIC PLATES AND FILM, EXPOSED AND DEVELOPED, OTHER THAN CINEMATOGRAPHIC FILM"
    },
    {
      "code": "37051000",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED FOR OFFSET REPRODUCTION"
    },
    {
      "code": "37052000",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED MICROFILMS"
    },
    {
      "code": "37059010",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER: MICROFICHES"
    },
    {
      "code": "37059090",
      "description": "PHOTOGRAPHIC PAPER, PAPERBOARD AND TEXTILES SENSITISED, UNEXPOSED OTHER: OTHER"
    },
    {
      "code": "3706",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK"
    },
    {
      "code": "370610",
      "description": "Of a width of 35 mm or more"
    },
    {
      "code": "37061011",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: FEATURE FILMS: MADE WHOLLY IN BLACK AND WHITE AND OF A LENGTH NOT EXCEEDING 4,000 M"
    },
    {
      "code": "37061012",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: FEATURE FILMS: MADE WHOLLY IN BLACK AND WHITE AND OF A LENGTH EXCEEDING 4,000 M"
    },
    {
      "code": "37061013",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: FEATURE FILMS: MADE WHOLLY OR PARTLY IN COLOUR AND OF A LENGTH NOT EXCEEDING 4,000 M"
    },
    {
      "code": "37061014",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: FEATURE FILMS: MADE WHOLLY OR PARTLY IN COLOUR AND OF A LENGTH EXCEEDING 4,000 M"
    },
    {
      "code": "37061015",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: FEATURE FILMS: CHILDRENS FILMS CERTIFIED BY THE CENTRAL BOARD OF FILM CERTIFICATION TO BE CHILDRENS FILM"
    },
    {
      "code": "37061020",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: DOCUMENTARY SHORTS, AND FILMS CERTIFIED AS SUCH BY THE CENTRAL BOARD OF FILM CERTIFICATION"
    },
    {
      "code": "37061030",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: NEWS REELS AND CLIPPINGS"
    },
    {
      "code": "37061041",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: ADVERTISEMENT SHORTS AND FILMS: MADE WHOLLY IN BLACK AND WHITE"
    },
    {
      "code": "37061042",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: ADVERTISEMENT SHORTS AND FILMS: MADE WHOLLY OR PARTLY IN COLOUR"
    },
    {
      "code": "37061051",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER CHILDRENS FILM: PATCH PRINTS, INCLUDING LOGOS INTENDED EXCLUSIVELY FOR THE ENTERTAINMENT OF CHILDREN"
    },
    {
      "code": "37061052",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER CHILDRENS FILM: CHILDRENS FILM CERTIFIED BY THE CENTRAL BOARD OF FILMS CERTIFICATION TO BE CHILDRENS FILM"
    },
    {
      "code": "37061059",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER CHILDRENS FILM: OTHER"
    },
    {
      "code": "37061061",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: EDUCATIONAL SHORTS, AND FILMS: CERTIFIED AS PREDOMINANTLY EDUCATIONAL BY THE CENTRAL BOARD OF FILM CERTIFICATION"
    },
    {
      "code": "37061062",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: EDUCATIONAL SHORTS, AND FILMS: PATCH PRINTS, INCLUDING LOGOS INTENDED EXCLUSIVELY FOR EDUCATIONAL PURPOSES"
    },
    {
      "code": "37061063",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: EDUCATIONAL SHORTS, AND FILMS: TEACHING AIDS INCLUDING FILM STRIPS OF EDUCATIONAL NATURE"
    },
    {
      "code": "37061069",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: EDUCATIONAL SHORTS, AND FILMS: OTHER"
    },
    {
      "code": "37061070",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: SHORT FILMS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "37061091",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER: AUDIO VISUAL NEWS OR AUDIO VISUAL VIEWS MATERIALS INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "37061092",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER: MASTER POSITIVES, EXPOSED NEGATIVES, DUPES AND RUSH PRINTS AS ARE NOT CLEARED FOR PUBLIC EXHIBITIONS"
    },
    {
      "code": "37061099",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OF A WIDTH OF 35 MM OR MORE: OTHER: OTHER"
    },
    {
      "code": "370690",
      "description": "Other"
    },
    {
      "code": "37069011",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: FEATURE FILMS: MADE WHOLLY IN BLACK AND WHITE AND OF A LENGTH NOT EXCEEDING 4,000 M"
    },
    {
      "code": "37069012",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: FEATURE FILMS: MADE WHOLLY IN BLACK AND WHITE AND OF A LENGTH EXCEEDING 4,000 M"
    },
    {
      "code": "37069013",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: FEATURE FILMS: MADE WHOLLY OR PARTLY IN COLOUR AND OF A LENGTH NOT EXCEEDING 4,000 M"
    },
    {
      "code": "37069014",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: FEATURE FILMS: MADE WHOLLY OR PARTLY IN COLOUR AND OF A LENGTH EXCEEDING 4,000 M"
    },
    {
      "code": "37069015",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: FEATURE FILMS: CHILDRENS FILMS CERTIFIED BY THE CENTRAL BOARD OF FILM CERTIFICATION TO BE CHILDRENS FILM"
    },
    {
      "code": "37069020",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: DOCUMENTARY SHOTS, AND FILMS CERTIFIED AS SUCH BY THE CENTRAL BOARD OF FILM CERTIFICATION"
    },
    {
      "code": "37069030",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: NEWS REELS AND CLIPPINGS"
    },
    {
      "code": "37069041",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: ADVERTISEMENT SHOTS AND FILMS: MADE WHOLLY IN BLACK AND WHITE"
    },
    {
      "code": "37069042",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: ADVERTISEMENT SHOTS AND FILMS: MADE WHOLLY OR PARTLY IN COLOUR"
    },
    {
      "code": "37069051",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER CHILDRENS FILM: PATCH PRINTS, INCLUDING LOGOS INTENDED EXCLUSIVELY FOR THE ENTERTAINMENT OF CHILDREN"
    },
    {
      "code": "37069052",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER CHILDRENS FILM: CHILDRENS FILM CERTIFIED BY THE CENTRAL BOARD OF FILMS CERTIFICATION TO BE CHILDRENS FILM"
    },
    {
      "code": "37069059",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER CHILDRENS FILM: OTHER"
    },
    {
      "code": "37069061",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: EDUCATIONAL SHOTS, AND FILMS: CERTIFIED AS PREDOMINANTLY EDUCATIONAL BY THE CENTRAL BOARD OF FILM CERTIFICATION"
    },
    {
      "code": "37069062",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: EDUCATIONAL SHOTS, AND FILMS: PATCH PRINTS, INCLUDING LOGOS INTENDED EXCLUSIVELY FOR EDUCATIONAL PURPOSES"
    },
    {
      "code": "37069063",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: EDUCATIONAL SHOTS, AND FILMS: TEACHING AIDS INCLUDING FILM STRIPS OF EDUCATIONAL NATURE"
    },
    {
      "code": "37069064",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: EDUCATIONAL SHOTS, AND FILMS: CERTIFIED AS PREDOMINANTLY EDUCATIONAL, BY CENTRAL BOARD OF FILM CERTIFICATION, OF WIDTH BELOW 30MM"
    },
    {
      "code": "37069069",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: EDUCATIONAL SHOTS, AND FILMS: OTHER"
    },
    {
      "code": "37069070",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: SHORT FILM NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "37069091",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER: AUDIO VISUAL NEWS OR AUDIO VISUAL VIEWS MATERIALS INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "37069092",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER: MASTER POSITIVES, EXPOSED NEGATIVES, DUPES AND RUSH PRINTS AS ARE NOT CLEARED FOR PUBLIC EXHIBITIONS"
    },
    {
      "code": "37069099",
      "description": "CINEMATOGRAPHIC FILM, EXPOSED AND DEVELOPED, WHETHER OR NOT INCORPORATING SOUND TRACK OR CONSISTING ONLY OF SOUND TRACK OTHER: OTHER: OTHER"
    },
    {
      "code": "3707",
      "description": "CHEMICAL PREPARATIONS FOR PHOTOGRAPHIC USES (OTHER THAN VARNISHES, GLUES, ADHESIVES AND SIMILAR PREPARATIONS); UNMIXED PRODUCTS FOR PHOTOGRAPHIC USES, PUT UP IN MEASURED PORTIONS OR PUT UP FOR RETAIL SALE IN A FORM READY FOR USE"
    },
    {
      "code": "37071000",
      "description": "CHEMICAL PREPARATIONS FOR PHOTOGRAPHIC USES (OTHER THAN VARNISHES, GLUES, ADHESIVES AND SIMILAR PREPARATIONS); UNMIXED PRODUCTS FOR PHOTOGRAPHIC USES, PUT UP IN MEASURED PORTIONS OR PUT UP FOR RETAIL SALE IN A FORM READY FOR USE SENSITIZING EMULSIONS"
    },
    {
      "code": "370790",
      "description": "Other"
    },
    {
      "code": "37079010",
      "description": "CHEMICAL PREPARATIONS FOR PHOTOGRAPHIC USES (OTHER THAN VARNISHES, GLUES, ADHESIVES AND SIMILAR PREPARATIONS); UNMIXED PRODUCTS FOR PHOTOGRAPHIC USES, PUT UP IN MEASURED PORTIONS OR PUT UP FOR RETAIL SALE IN A FORM READY FOR USE OTHER: CHEMICAL PRODUCTS MIXED OR COMPOUNDED FOR PHOTOGRAPHIC USES (FOR EXAMPLE, DEVELOPERS AND FIXERS), WHETHER OR NOT IN BULK"
    },
    {
      "code": "37079090",
      "description": "CHEMICAL PREPARATIONS FOR PHOTOGRAPHIC USES (OTHER THAN VARNISHES, GLUES, ADHESIVES AND SIMILAR PREPARATIONS); UNMIXED PRODUCTS FOR PHOTOGRAPHIC USES, PUT UP IN MEASURED PORTIONS OR PUT UP FOR RETAIL SALE IN A FORM READY FOR USE OTHER: OTHER"
    },
    {
      "code": "38",
      "description": "Miscellaneous chemical products"
    },
    {
      "code": "3801",
      "description": "ARTIFICIAL GRAPHITE ; COLLOIDAL O R SEMICOLLOIDAL GRAPHITE; PREPARATIONS BASED ON GRAPHITE OR OTHER CARBON IN THE FORM OF PASTES, BLOCKS, PLATES OR OTHER SEMIMANUFACTURES"
    },
    {
      "code": "38011000",
      "description": "ARTIFICIAL GRAPHITE; COLLOIDAL OR SEMI-COLLOIDAL GRAPHITE; PREPARATIONS BASED ON GRAPHITE OR OTHER CARBON IN THE FORM OF PASTES, BLOCKS, PLATES OR OTHER SEMI-MANUFACTURES ARTIFICIAL GRAPHITE"
    },
    {
      "code": "38012000",
      "description": "ARTIFICIAL GRAPHITE; COLLOIDAL OR SEMI-COLLOIDAL GRAPHITE; PREPARATIONS BASED ON GRAPHITE OR OTHER CARBON IN THE FORM OF PASTES, BLOCKS, PLATES OR OTHER SEMI-MANUFACTURES COLLOIDAL OR SEMICOLLOIDAL GRAPHITE"
    },
    {
      "code": "38013000",
      "description": "ARTIFICIAL GRAPHITE; COLLOIDAL OR SEMI-COLLOIDAL GRAPHITE; PREPARATIONS BASED ON GRAPHITE OR OTHER CARBON IN THE FORM OF PASTES, BLOCKS, PLATES OR OTHER SEMI-MANUFACTURES CARBONACEOUS PASTES FOR ELECTRODES AND SIMILAR PASTES FOR FURNACE LININGS"
    },
    {
      "code": "38019000",
      "description": "ARTIFICIAL GRAPHITE; COLLOIDAL OR SEMI-COLLOIDAL GRAPHITE; PREPARATIONS BASED ON GRAPHITE OR OTHER CARBON IN THE FORM OF PASTES, BLOCKS, PLATES OR OTHER SEMI-MANUFACTURES OTHER"
    },
    {
      "code": "3802",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK"
    },
    {
      "code": "38021000",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK ACTIVATED CARBON"
    },
    {
      "code": "380290",
      "description": "Other"
    },
    {
      "code": "38029011",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK OTHER: ACTIVATED NATURAL MINERAL PRODUCTS: ACTIVATED ALUMINA"
    },
    {
      "code": "38029012",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK OTHER: ACTIVATED NATURAL MINERAL PRODUCTS: ACTIVATED BAUXITE"
    },
    {
      "code": "38029019",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK OTHER: ACTIVATED NATURAL MINERAL PRODUCTS: OTHER"
    },
    {
      "code": "38029020",
      "description": "ACTIVATED CARBON; ACTIVATED NATURAL MINERAL PRODUCTS; ANIMAL BLACK, INCLUDING SPENT ANIMAL BLACK OTHER: ANIMAL BLACK (FOR EXAMPLE BONE BLACK, IVORY BLACK), INCLUDING SPENT ANIMAL BLACK"
    },
    {
      "code": "3803",
      "description": "TALL OIL, WHETHER OR NOT REFINED"
    },
    {
      "code": "38030000",
      "description": "TALL OIL, WHETHER OR NOT REFINED"
    },
    {
      "code": "3804",
      "description": "RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED O R CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803"
    },
    {
      "code": "380400",
      "description": "Residual lyes from the manufacture of wood pulp, whether or not concentrated, desugared or chemically treated, including lignin sulphonates, but excluding tall oil of heading 3803"
    },
    {
      "code": "38040010",
      "description": "RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803 RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803: LIGNIN SULPHONATES"
    },
    {
      "code": "38040020",
      "description": "RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803 RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803: CONCENTRATED SULPHATE LYE"
    },
    {
      "code": "38040090",
      "description": "RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803 RESIDUAL LYES FOR THE MANUFACTURE OF WOOD PULP, WHETHER OR NOT CONCENTRATED, DESUGARED OR CHEMICALLY TREATED, INCLUDING LIGNIN SULPHONATES, BUT EXCLUDING TALL OIL OF HEADING 3803: OTHER"
    },
    {
      "code": "3805",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA - TERPINEOL AS THE MAIN CONSTITUENT"
    },
    {
      "code": "380510",
      "description": "Gum, wood or sulphate turpentine oils"
    },
    {
      "code": "38051010",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT GUM, WOOD OR SULPHATE TURPENTINE OILS: WOOD TURPENTINE OIL AND SPIRIT OF TURPENTINE"
    },
    {
      "code": "38051020",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT GUM, WOOD OR SULPHATE TURPENTINE OILS: GUM TURPENTINE OIL"
    },
    {
      "code": "38051030",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT GUM, WOOD OR SULPHATE TURPENTINE OILS: SULPHATE TURPENTINE OIL"
    },
    {
      "code": "38052000",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT PINE OIL"
    },
    {
      "code": "380590",
      "description": "Other"
    },
    {
      "code": "38059010",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT OTHER: TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS"
    },
    {
      "code": "38059020",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT OTHER: CRUDE DIPENTENE"
    },
    {
      "code": "38059030",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT OTHER: SULPHITE TURPENTINE"
    },
    {
      "code": "38059090",
      "description": "GUM, WOOD OR SULPHATE TURPENTINE AND OTHER TERPENIC OILS PRODUCED BY THE DISTILLATION OR OTHER TREATMENT OF CONIFEROUS WOODS; CRUDE DIPENTENE; SULPHITE TURPENTINE AND OTHER CRUDE PARA-CYMENE; PINE OIL CONTAINING ALPHA-TERPINEOL AS THE MAIN CONSTITUENT OTHER: OTHER"
    },
    {
      "code": "3806",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS"
    },
    {
      "code": "380610",
      "description": "Rosin and resin acids"
    },
    {
      "code": "38061010",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS ROSIN AND RESIN ACIDS: GUM ROSIN"
    },
    {
      "code": "38061090",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS ROSIN AND RESIN ACIDS: OTHER"
    },
    {
      "code": "38062000",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS SALTS OF ROSIN, OF RESIN ACIDS OR OF DERIVATIVES OF ROSIN OR RESIN ACIDS, OTHER THAN SALTS OF ROSIN ADDUCTS"
    },
    {
      "code": "38063000",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS ESTER GUMS"
    },
    {
      "code": "380690",
      "description": "Other"
    },
    {
      "code": "38069010",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS OTHER: RUN GUMS"
    },
    {
      "code": "38069090",
      "description": "ROSIN AND RESIN ACIDS, AND DERIVATIVES THEREOF; ROSIN SPIRIT AND ROSIN OILS; RUN GUMS OTHER: OTHER"
    },
    {
      "code": "3807",
      "description": "WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE; WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH"
    },
    {
      "code": "380700",
      "description": "Wood tar; wood tar oils; wood creosote; wood naphtha; vegetable pitch; brewers pitch and similar preparations based on rosin, resin acids or on vegetable pitch"
    },
    {
      "code": "38070010",
      "description": "WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE; WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE;WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH: WOOD TAR"
    },
    {
      "code": "38070020",
      "description": "WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE; WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE;WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH: WOOD TAR OILS, WOOD CREOSOTE, WOOD NAPHTHA"
    },
    {
      "code": "38070030",
      "description": "WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE; WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH WOOD TAR; WOOD TAR OILS; WOOD CREOSOTE;WOOD NAPHTHA; VEGETABLE PITCH; BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR ON VEGETABLE PITCH: VEGETABLE PITCH, BREWERS PITCH AND SIMILAR PREPARATIONS BASED ON ROSIN, RESIN ACIDS OR VEGETABLE PITCH"
    },
    {
      "code": "3808",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI - SPROUTING PRODUCTS AND PLANT - GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)"
    },
    {
      "code": "38081011",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: ALDRIN"
    },
    {
      "code": "38081012",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: ALUMINIUM PHOSPHITE (FOR EXAMPLE PHOSTOXIN)"
    },
    {
      "code": "38081013",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: CALCIUM CYANIDE"
    },
    {
      "code": "38081014",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: CHLORDANE"
    },
    {
      "code": "38081015",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: CHLORO BENZILATE"
    },
    {
      "code": "38081016",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: D.D.V.P (DIMETHYL DICHLORO VINYL PHOSPHATE)"
    },
    {
      "code": "38081017",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ALDRIN, ALUMINIUM PHOSPHITE, CALCIUM CYANIDE, CHLORDANE, CHLORO BENZILATE, DDVP,DDT: D.D.T. (EXCLUDING D.D.T. OF HEADING 2903 62)"
    },
    {
      "code": "38081021",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: DIAGINAL"
    },
    {
      "code": "38081022",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: HEPTACHLOR"
    },
    {
      "code": "38081023",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: LINDANE"
    },
    {
      "code": "38081024",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: METHYL BROMIDE"
    },
    {
      "code": "38081025",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: PARATHION, METHYL"
    },
    {
      "code": "38081026",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: DIMETHOATE, TECHNICAL GRADE"
    },
    {
      "code": "38081027",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: DIAGINAL, HEPTACHLOR, LINDANE, METHYL BROMIDE, PARATHION METHYL, DIMETHOATE TECHNICAL, MALATHION: MALATHION"
    },
    {
      "code": "38081031",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM ENDOSULPHAN, TECHNICAL GRADE"
    },
    {
      "code": "38081032",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM QUINAL PHOS"
    },
    {
      "code": "38081033",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM ISOPROTURON"
    },
    {
      "code": "38081034",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM FENTHION"
    },
    {
      "code": "38081035",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM CIPERMETHRIN, TECHNICAL GRADE"
    },
    {
      "code": "38081036",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM ALLETHRIN"
    },
    {
      "code": "38081037",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: ENDOSULPHAN TECHNICAL, QUINAL PHOS, ISOPROTURON, FENTHION, CIPERMETHRIN TECHNICAL, ALLETHRIN, SYNTHETIC PYRETHRUM SYNTHETIC PYRETHRUM"
    },
    {
      "code": "38081091",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: OTHER: REPELLANTS FOR INSECTS SUCH AS FLIES, MOSQUITO"
    },
    {
      "code": "38081092",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: OTHER: PAPER IMPREGNATED OR COATED WITH INSECTICIDE SUCH AS D.D.T. COATED PAPER"
    },
    {
      "code": "38081099",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) INSECTICIDES: OTHER: OTHER"
    },
    {
      "code": "38082010",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: MANEB"
    },
    {
      "code": "38082020",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: SODIUM PENTA CHLOROPHENATE (SANTOBRITE)"
    },
    {
      "code": "38082030",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: THIRAM (TETRAMETHYL THIURAM DISULPHIDE)"
    },
    {
      "code": "38082040",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: ZINEB"
    },
    {
      "code": "38082050",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: COPPER OXYCHLORIDE"
    },
    {
      "code": "38082090",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) FUNGICIDES: OTHER"
    },
    {
      "code": "38083010",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: CHLOROMETHYL PHENOZY ACETIC ACID (M.C.P.A.)"
    },
    {
      "code": "38083020",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: 2:4 DICHLOROPHENOZY ACETIC ACID AND ITS ESTERS"
    },
    {
      "code": "38083030",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: GIBBERELLIC ACID"
    },
    {
      "code": "38083040",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: PLANTGROWTH REGULATORS"
    },
    {
      "code": "38083050",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: WEEDICIDES AND WEED KILLING AGENTS"
    },
    {
      "code": "38083090",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: OTHER"
    },
    {
      "code": "38084000",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) DISINFECTANTS"
    },
    {
      "code": "380850",
      "description": "aldrin (ISO); binapacryl (ISO); camphechlor(ISO) (toxaphene); captafol (ISO); chlordane (ISO); chlordimeform (ISO); chlorobenzilate (ISO); DDT (ISO) (clofenotane (INN), 1, 1, 1-trichloro-2,2- bis(p-chlorphenyl) ethane); dieldrin (ISO,INN), dinoseb (ISO), its salts or its esters; ethylene dibromide (ISO) (1,2-dibromoethane); ethylene dichloride (ISO); (1,2dicholoroethane);fluroacetamide (ISO); heptachlor(ISO); hexachlorobenzene (ISO); 1,2,3,4,5,6,-hexachlorocyclohexane (HCH (ISO)), including lindane (ISO, INN); mercury compounds; methamidophos (ISO; monocrotophos (ISO); oxirane parathion-methyl (ISO) (methyl-parathion (ISO); pentachlorophenol (ISO); phosphamidon (ISO); 2,4,5-T ((ISO) (2,4,5-trichlorophenoxyacetic acid, its salts or its esters;"
    },
    {
      "code": "38085000",
      "description": "NSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-GOODS SPECIFIED IN SUB-HEADING NOTE 1"
    },
    {
      "code": "38085200",
      "description": "DDT (ISO) (clofenotane (INN)), in packings of a net weight content not exceeding 300 g."
    },
    {
      "code": "38085900",
      "description": "Other"
    },
    {
      "code": "38086100",
      "description": "In packings of a net weight content not exceeding 300g"
    },
    {
      "code": "38086200",
      "description": "In packings of a net weight content exceeding 300 g but not exceeding 7.5 kg"
    },
    {
      "code": "38086900",
      "description": "Other"
    },
    {
      "code": "38089010",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) OTHER : PESTICIDES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38089090",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS) OTHER : OTHER"
    },
    {
      "code": "380891",
      "description": "Insecticides"
    },
    {
      "code": "38089111",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---- ALUMINIUM PHOSPHITE (FOR EXAMPLE PHOSTOXIN)"
    },
    {
      "code": "38089112",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----CALCIUM CYANIDE"
    },
    {
      "code": "38089113",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---- D.D.V.P.(DIMETHYLE-DICHLORO-VINYL- PHOSPHATE)"
    },
    {
      "code": "38089121",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---- DIAGINAL"
    },
    {
      "code": "38089122",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----METHYL BROMIDE"
    },
    {
      "code": "38089123",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----DIMETHOATE, TECHNICAL GRADE"
    },
    {
      "code": "38089124",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----MELATHION"
    },
    {
      "code": "38089131",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----ENDOSULPHAN, TECHNICAL GRADE"
    },
    {
      "code": "38089132",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----QUINAL PHOS"
    },
    {
      "code": "38089133",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----ISOPROTURON"
    },
    {
      "code": "38089134",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----FENTHION"
    },
    {
      "code": "38089135",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----CIPERMETHRIN, TECHNICAL GRADE"
    },
    {
      "code": "38089136",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----ALLETHRIN"
    },
    {
      "code": "38089137",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:----SYNTHETIC PYRETHRUM"
    },
    {
      "code": "38089191",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---OTHER:----REPELLANTS FOR INSECTS SUCH AS FLIES, MOSQUITO"
    },
    {
      "code": "38089192",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---OTHER:----PAPER IMPREGNATED OR COATED WITH INSECTICIDES SUCH AS D.D.T. COATED"
    },
    {
      "code": "38089199",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-OTHER-- INSECTICIDES:---OTHER:----OTHER"
    },
    {
      "code": "380892",
      "description": "Fungicides;"
    },
    {
      "code": "38089210",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- MANEB"
    },
    {
      "code": "38089220",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- SODIUM PENTA CHLOROPHENATE(SANTROBRITE)"
    },
    {
      "code": "38089230",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- THIRAM (TETRAMETHYL THIURAM DISULPHIDE)"
    },
    {
      "code": "38089240",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- ZINEB"
    },
    {
      "code": "38089250",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- COPPER OXYCHLORIDE"
    },
    {
      "code": "38089290",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHURTREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--: FUNGICIDES--- OTHER"
    },
    {
      "code": "380893",
      "description": "Herbicides, anti-sprouting products and plant-growth regulators"
    },
    {
      "code": "38089310",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS:--- CHLOROMETHYL PHENOZY ACETIC ACID (M.C.P.A)"
    },
    {
      "code": "38089320",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS: --- 2:4 DICHLORO PHENOY ACETIC ACID AND ITS ESTERS"
    },
    {
      "code": "38089330",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS:---GIBBERELLIC ACID"
    },
    {
      "code": "38089340",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS:--- PLANT GROWTH REGULATORS"
    },
    {
      "code": "38089350",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS:--- WEEDICIDES AND WEED KILLING AGENTS"
    },
    {
      "code": "38089390",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS:--- OTHER"
    },
    {
      "code": "38089400",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)--DISINFECTANTS"
    },
    {
      "code": "380899",
      "description": "Other"
    },
    {
      "code": "38089910",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- OTHER:--- PESTICIDES, NOT ELSE WHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38089990",
      "description": "INSECTICIDES, RODENTICIDES, FUNGICIDES, HERBICIDES, ANTI-SPROUTING PRODUCTS AND PLANT-GROWTH REGULATORS, DISINFECTANTS AND SIMILAR PRODUCTS, PUT UP IN FORMS OR PACKINGS FOR RETAIL SALE OR AS PREPARATIONS OR ARTICLES (FOR EXAMPLE, SULPHUR TREATED BANDS, WICKS AND CANDLES, AND FLY-PAPERS)-- OTHER:--- OTHER"
    },
    {
      "code": "3809",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38091000",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED: WITH A BASIS OF AMYLACEOUS SUBSTANCES"
    },
    {
      "code": "380991",
      "description": "Of a kind used in the textile or like industries"
    },
    {
      "code": "38099110",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS MORDANTING AGENTS"
    },
    {
      "code": "38099120",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS DESIZING AGENTS"
    },
    {
      "code": "38099130",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS DISPERSING AGENTS"
    },
    {
      "code": "38099140",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS EMULSIFYING AGENTS"
    },
    {
      "code": "38099150",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS HYDRO SULPHITE FORMALDEHYDE COMPOUND (RONGALITE OR FORMUSUL)"
    },
    {
      "code": "38099160",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTSTEXTILE PRESERVATIVES"
    },
    {
      "code": "38099170",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: TEXTILE ASSISTANTS WATER PROOFING AGENTS"
    },
    {
      "code": "38099180",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: PREPARED TEXTILE GLAZINGS, DRESSINGS AND MORDANTS"
    },
    {
      "code": "38099190",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE TEXTILE OR LIKE INDUSTRIES: OTHER"
    },
    {
      "code": "38099200",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE PAPER OR LIKE INDUSTRIES"
    },
    {
      "code": "380993",
      "description": "Of a kind used in the leather or like industries"
    },
    {
      "code": "38099310",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE LEATHER OR LIKE INDUSTRIES : FATTY OIL OR PULL UP OIL"
    },
    {
      "code": "38099390",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE LEATHER OR LIKE INDUSTRIES : OTHER"
    },
    {
      "code": "38099900",
      "description": "FINISHING AGENTS, DYE CARRIERS TO ACCELERATE THE DYEING OR FIXING OF DYE-STUFFS AND OTHER PRODUCTS AND PREPARATIONS (FOR EXAMPLE, DRESSINGS AND MORDANTS), OF A KIND USED IN THE TEXTILE, PAPER, LEATHER OR LIKE INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: OF A KIND USED IN THE LEATHER OR LIKE INDUSTRIES : OTHER"
    },
    {
      "code": "3810",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING O R WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS"
    },
    {
      "code": "381010",
      "description": "Pickling preparations for metal surfaces; soldering, brazing or welding powders and pastes consisting of metal and other materials"
    },
    {
      "code": "38101010",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING OR WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS PICKLING PREPARATIONS FOR METAL SURFACES;SOLDERING, BRAZING OR WELDING POWDERS AND PASTESCONSISTING OF METAL AND OTHER MATERIALS: PICKLING PREPARATIONS AND OTHER SOLDERING, BRAZING OR WELDING POWDERS OR PASTES"
    },
    {
      "code": "38101020",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING OR WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS PICKLING PREPARATIONS FOR METAL SURFACES;SOLDERING, BRAZING OR WELDING POWDERS AND PASTESCONSISTING OF METAL AND OTHER MATERIALS: THERMITE PORTION FOR WELDING (ALUMINA THERMIC HEAT GENERATORS)"
    },
    {
      "code": "38101090",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING OR WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS PICKLING PREPARATIONS FOR METAL SURFACES;SOLDERING, BRAZING OR WELDING POWDERS AND PASTESCONSISTING OF METAL AND OTHER MATERIALS: OTHER"
    },
    {
      "code": "381090",
      "description": "Other"
    },
    {
      "code": "38109010",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING OR WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS OTHER PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES AND RODS"
    },
    {
      "code": "38109090",
      "description": "PICKLING PREPARATIONS FOR METAL SURFACES; FLUXES AND OTHER AUXILIARY PREPARATIONS FOR SOLDERING, BRAZING OR WELDING; SOLDERING, BRAZING OR WELDING POWDERS AND PASTES CONSISTING OF METAL AND OTHER MATERIALS; PREPARATIONS OF A KIND USED AS CORES OR COATINGS FOR WELDING ELECTRODES OR RODS OTHER OTHER"
    },
    {
      "code": "3811",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS"
    },
    {
      "code": "38111100",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS ANTI-KNOCK PREPARATIONS: BASED ON LEAD COMPOUNDS"
    },
    {
      "code": "38111900",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS ANTI-KNOCK PREPARATIONS: OTHER"
    },
    {
      "code": "38112100",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS ADDITIVES FOR LUBRICATING OILS: CONTAINING PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "38112900",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS ADDITIVES FOR LUBRICATING OILS: OTHER"
    },
    {
      "code": "38119000",
      "description": "ANTI-KNOCK PREPARATIONS, OXIDATION INHIBITORS, GUM INHIBITORS, VISCOSITY IMPROVERS, ANTI-CORROSIVE PREPARATIONS AND OTHER PREPARED ADDITIVES, FOR MINERAL OILS (INCLUDING GASOLINE) OR FOR OTHER LIQUIDS USED FOR THE SAME PURPOSES AS MINERAL OILS ADDITIVES FOR LUBRICATING OILS: OTHER"
    },
    {
      "code": "3812",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS"
    },
    {
      "code": "38121000",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS PREPARED RUBBER ACCELERATORS"
    },
    {
      "code": "381220",
      "description": "Compound plasticisers for rubber or plastics"
    },
    {
      "code": "38122010",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS: PHTHALATE PLASTICISERS"
    },
    {
      "code": "38122090",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS: OTHER"
    },
    {
      "code": "381230",
      "description": "Anti-oxidising preparations and other compound stabilisers for rubber or plastics"
    },
    {
      "code": "38123010",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS: ANTI-OXIDANTS FOR RUBBER"
    },
    {
      "code": "38123020",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS: SOFTENERS FOR RUBBER"
    },
    {
      "code": "38123030",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS: VULCANISING AGENTS FOR RUBBER"
    },
    {
      "code": "38123090",
      "description": "PREPARED RUBBER ACCELERATORS; COMPOUND PLASTICISERS FOR RUBBER OR PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED; ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS ANTI-OXIDISING PREPARATIONS AND OTHER COMPOUND STABILISERS FOR RUBBER OR PLASTICS: OTHER"
    },
    {
      "code": "38123100",
      "description": "Mixtures of oligomers of 2, 2, 4-trimethyl-1, 2-dihydroquinoline (TMQ)"
    },
    {
      "code": "381239",
      "description": "Other"
    },
    {
      "code": "38123910",
      "description": "Anti-oxidants for rubber"
    },
    {
      "code": "38123920",
      "description": "Softeners for rubber"
    },
    {
      "code": "38123930",
      "description": "Vulcanizing agents for rubber"
    },
    {
      "code": "38123990",
      "description": "Other"
    },
    {
      "code": "3813",
      "description": "PREPARATIONS AND CHARGES FOR FIRE-EXTINGUISHERS; CHARGED FIRE-EXTINGUISHING GRENADES"
    },
    {
      "code": "38130000",
      "description": "PREPARATIONS AND CHARGES FOR FIRE-EXTINGUISHERS; CHARGED FIRE-EXTINGUISHING GRENADES"
    },
    {
      "code": "3814",
      "description": "ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED; PREPARED PAINT OR VARNISH REMOVERS"
    },
    {
      "code": "381400",
      "description": "Organic composite solvents and thinners, not elsewhere specified or included; prepared paint or varnish removers"
    },
    {
      "code": "38140010",
      "description": "ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED; PREPARED PAINT OR VARNISH REMOVERS ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED; PREPARED PAINT OR VARNISH REMOVERS: ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38140020",
      "description": "ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED; PREPARED PAINT OR VARNISH REMOVERS ORGANIC COMPOSITE SOLVENTS AND THINNERS, NOT ELSEWHERE SPECIFIED OR INCLUDED; PREPARED PAINT OR VARNISH REMOVERS: PREPARED PAINT OR VARNISH REMOVERS"
    },
    {
      "code": "3815",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38151100",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED SUPPORTED CATALYSTS: WITH NICKEL OR NICKEL COMPOUNDS AS THE ACTIVE SUBSTANCE"
    },
    {
      "code": "381512",
      "description": "With precious metal or precious metal compounds as the active substance"
    },
    {
      "code": "38151210",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED SUPPORTED CATALYSTS: WITH PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS AS THE ACTIVE SUBSTANCE: PLATINUM OR PALLADIUM CATALYSTS WITH A BASE OF ACTIVATED CARBON"
    },
    {
      "code": "38151290",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED SUPPORTED CATALYSTS: WITH PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS AS THE ACTIVE SUBSTANCE: OTHER"
    },
    {
      "code": "38151900",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED SUPPORTED CATALYSTS: OTHER"
    },
    {
      "code": "38159000",
      "description": "REACTION INITIATORS, REACTION ACCELERATORS AND CATALYTIC PREPARATIONS, NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER"
    },
    {
      "code": "3816",
      "description": "REFRACTORY CEMENTS, MORTARS, CONCRETES AND SIMILAR COMPOSITIONS, OTHER THAN PRODUCTS OF HEADING 3801"
    },
    {
      "code": "38160000",
      "description": "REFRACTORY CEMENTS, MORTARS, CONCRETES AND SIMILAR COMPOSITIONS, OTHER THAN PRODUCTS OF HEADING 3801"
    },
    {
      "code": "3817",
      "description": "MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902"
    },
    {
      "code": "381700",
      "description": "Mixed alkylbenzenes and mixed alkylnaphthalenes, other than those of heading 2707 or 2902"
    },
    {
      "code": "38170011",
      "description": "MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902 MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902: MIXED ALKYLBENZENES: LINEAR ALKYLBENZENES"
    },
    {
      "code": "38170019",
      "description": "MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902 MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902: MIXED ALKYLBENZENES: OTHER"
    },
    {
      "code": "38170020",
      "description": "MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902 MIXED ALKYLBENZENES AND MIXED ALKYLNAPHTHALENES, OTHER THAN THOSE OF HEADING 2707 OR 2902: MIXED ALKYLBENZENES: MIXED ALKYLNAPHTHALENES"
    },
    {
      "code": "3818",
      "description": "C HEMICAL ELEMENTS DOPED FOR USE IN ELECTRONICS, IN THE FORM OF DISCS, WAFERS OR SIMILAR FORMS; CHEMICAL COMPOUNDS DOPED FOR USE IN ELECTRONICS"
    },
    {
      "code": "381800",
      "description": "Chemical elements doped for use in electronics, in the form of discs, wafers or similar forms; chemical compounds doped for use in electronics"
    },
    {
      "code": "38180010",
      "description": "CHEMICAL ELEMENTS DOPED FOR USE IN ELECTRONICS, IN THE FORM OF DISCS, WAFERS OR SIMILAR FORMS; CHEMICAL COMPOUNDS DOPED FOR USE IN ELECTRONICS CHEMICAL ELEMENTS DOPED FOR USE IN ELECTRONICS, IN THE FORM OF DISCS, WAFERS OR SIMILAR FORMS; CHEMICAL COMPOUNDS DOPED FOR USE IN ELECTRONICS: UNDEFUSED SILICON WAFERS"
    },
    {
      "code": "38180090",
      "description": "CHEMICAL ELEMENTS DOPED FOR USE IN ELECTRONICS, IN THE FORM OF DISCS, WAFERS OR SIMILAR FORMS; CHEMICAL COMPOUNDS DOPED FOR USE IN ELECTRONICS CHEMICAL ELEMENTS DOPED FOR USE IN ELECTRONICS, IN THE FORM OF DISCS, WAFERS OR SIMILAR FORMS; CHEMICAL COMPOUNDS DOPED FOR USE IN ELECTRONICS: OTHER"
    },
    {
      "code": "3819",
      "description": "HYDRAULIC BRAKE FLUIDS AND OTHER PREPARED LIQUIDS FOR HYDRAULIC TRANSMISSION, NOT CONTAINING OR CONTAINING LESS THAN 70% BY WEIGHT OF PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "38190010",
      "description": "HYDRAULIC BRAKE FLUIDS AND OTHER PREPAREDLIQUIDS FOR HYDRAULIC TRANSMISSION, NOT CONTAINING OR CONTAINING LESS THAN 70% BY WEIGHT OF PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS HYDRAULIC BRAKE FLUIDS"
    },
    {
      "code": "38190090",
      "description": "HYDRAULIC BRAKE FLUIDS AND OTHER PREPAREDLIQUIDS FOR HYDRAULIC TRANSMISSION, NOT CONTAINING OR CONTAINING LESS THAN 70% BY WEIGHT OF PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS OTHER"
    },
    {
      "code": "3820",
      "description": "ANTI-FREEZING PREPARATIONS AND PREPARED DE-ICING FLUIDS"
    },
    {
      "code": "38200000",
      "description": "ANTI-FREEZING PREPARATIONS AND PREPARED DE-ICING FLUIDS"
    },
    {
      "code": "3821",
      "description": "PREPARED CULTURE MEDIA FOR DEVELOPMENT OR MAINTENANCE OF MICRO-ORGANISMS (INCLUDING VIRUSES AND THE LIKE) OR OF PLANT, HUMAN OR ANIMAL CELLS"
    },
    {
      "code": "38210000",
      "description": "PREPARED CULTURE MEDIA FOR DEVELOPMENT OR MAINTENANCE OF MICRO-ORGANISMS (INCLUDING VIRUSES AND THE LIKE) OR OF PLANT, HUMAN OR ANIMAL CELLS"
    },
    {
      "code": "3822",
      "description": "DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN] THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS"
    },
    {
      "code": "382200",
      "description": "Diagnostic or laboratory reagents on a backing, prepared diagnostic or laboratory reagents whether or not on a backing, other than those of heading 3002 or 3006; certified reference materials"
    },
    {
      "code": "38220011",
      "description": "DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS: FOR MEDICAL DIAGNOSIS: PREGNANCY CONFIRMATION REAGENTS"
    },
    {
      "code": "38220012",
      "description": "DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS: FOR MEDICAL DIAGNOSIS: REAGENTS FOR DIAGNOSING AIDS"
    },
    {
      "code": "38220019",
      "description": "DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS: FOR MEDICAL DIAGNOSIS: OTHER"
    },
    {
      "code": "38220090",
      "description": "DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS DIAGNOSTIC OR LABORATORY REAGENTS ON A BACKING, PREPARED DIAGNOSTIC OR LABORATORY REAGENTS WHETHER OR NOT ON A BACKING, OTHER THAN THOSE OF HEADING 3002 OR 3006; CERTIFIED REFERENCE MATERIALS: OTHER"
    },
    {
      "code": "3823",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS"
    },
    {
      "code": "382311",
      "description": "Stearic acid"
    },
    {
      "code": "38231100",
      "description": "Stearic Acid"
    },
    {
      "code": "38231111",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: STEARIC ACID: PALM STEARIN: CRUDE"
    },
    {
      "code": "38231112",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: STEARIC ACID: PALM STEARIN: RBD"
    },
    {
      "code": "38231119",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: STEARIC ACID: PALM STEARIN: OTHER"
    },
    {
      "code": "38231190",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: STEARIC ACID: OTHER STEARIC ACID OR STEARIN"
    },
    {
      "code": "38231200",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: OLEIC ACID"
    },
    {
      "code": "38231300",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: TALL OIL FATTY ACIDS"
    },
    {
      "code": "38231900",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING: OTHER"
    },
    {
      "code": "382370",
      "description": "Industrial fatty alcohols"
    },
    {
      "code": "38237010",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL FATTY ALCOHOLS: CETYL ALCOHOL"
    },
    {
      "code": "38237020",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL FATTY ALCOHOLS: LAURYL ALCOHOL"
    },
    {
      "code": "38237030",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL FATTY ALCOHOLS: OLEYL ALCOHOL"
    },
    {
      "code": "38237040",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL FATTY ALCOHOLS: STEARYL ALCOHOL"
    },
    {
      "code": "38237090",
      "description": "INDUSTRIAL MONOCARBOXYLIC FATTY ACIDS; ACID OILS FROM REFINING; INDUSTRIAL FATTY ALCOHOLS INDUSTRIAL FATTY ALCOHOLS: OTHER"
    },
    {
      "code": "3824",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "38241000",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES"
    },
    {
      "code": "38242010",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED NAPHTHENIC ACIDS, THEIR WATER-INSOLUBLE SALTS AND THEIR ESTERS: COPPER NAPHTHENATE"
    },
    {
      "code": "38242020",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED NAPHTHENIC ACIDS, THEIR WATER-INSOLUBLE SALTS AND THEIR ESTERS: NAPHTHENIC ACIDS"
    },
    {
      "code": "38242090",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED NAPHTHENIC ACIDS, THEIR WATER-INSOLUBLE SALTS AND THEIR ESTERS: OTHER"
    },
    {
      "code": "38243000",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED : NON-AGGLOMERATED METAL CARBIDES MIXED TOGETHER OR WITH METALLIC BINDERS"
    },
    {
      "code": "382440",
      "description": "Prepared additives for cements, mortars or concretes"
    },
    {
      "code": "38244010",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED PREPARED ADDITIVES FOR CEMENTS, MORTARS OR CONCRETES: DAMP PROOF OR WATER PROOF COMPOUNDS"
    },
    {
      "code": "38244090",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED PREPARED ADDITIVES FOR CEMENTS, MORTARS OR CONCRETES : OTHER"
    },
    {
      "code": "382450",
      "description": "Non-refractory mortars and concretes"
    },
    {
      "code": "38245010",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED  NON-REFRACTORY MORTARS AND CONCRETES : CONCRETES READY TO USE KNOWN AS READY- MIX CONCRETE (RMC)"
    },
    {
      "code": "38245090",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED  NON-REFRACTORY MORTARS AND CONCRETES : OTHER"
    },
    {
      "code": "382460",
      "description": "Sorbitol other than that of sub-heading 2905 44"
    },
    {
      "code": "38246010",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED  SORBITOL OTHER THAN THAT OF SUB-HEADING 2905 44 : IN AQUEOUS SOLUTION"
    },
    {
      "code": "38246090",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- SORBITOL OTHER THAN THAT OF SUB-HEADING 2905 44 :--- OTHER"
    },
    {
      "code": "38247100",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING CHLOROFLUOROCARBONS (CFCS), WHETHER OR NOT CONTAINING HYDROCHLOROFLUOROCARBONS(HCFCS), PERFLUOROCARBONS (PFCS) OR HYDROFLUOROCARBONS(HFCS)"
    },
    {
      "code": "38247110",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED MIXTURES CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS :CONTAINING ACYCLIC HYDROCARBONS PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE: CONTAINING OZONE DEPLETING SUBSTANCES"
    },
    {
      "code": "38247190",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED MIXTURES CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS :CONTAINING ACYCLIC HYDROCARBONS PERHALOGENATED ONLY WITH FLUORINE AND CHLORINE: OTHER"
    },
    {
      "code": "38247200",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING BROMOCHLORODIFLUOROMETHANE, BROMOTRIFLUOROMETHANE OR DIBROMOTETRAFLUORO-ETHANES"
    },
    {
      "code": "38247300",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING HYDROBROMOFLUOROCARBONS(HBFCS)"
    },
    {
      "code": "38247400",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING HYDROCHLOROFLUOROCARBONS(HCFCS), WHETHER OR NOT CONTAINING PERFLUOROCARBONS(PFCS) OR HYDROFLUOROCARBONS (HFCS), BUT NOT CONTAINING CHLOROFLUOROCARBONS(CFCS)"
    },
    {
      "code": "38247500",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING CARBON TETRACHLORIDE"
    },
    {
      "code": "38247600",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING 1,1,1-TRICHLOROETHANE (METHYL CHLOROFORM)"
    },
    {
      "code": "38247700",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING BROMOMETHANE (METHYL BROMIDE) OR BROMOCHLOROMETHANE"
    },
    {
      "code": "38247800",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- CONTAINING PERFLUOROCARBONS (PFCS) OR HYDROFLUOROCARBONS (HFCS), BUT NOT CONTAINING CHLOROFLUOROCARBONS (CFCS)OR HYDROCHLOROFLUOROCARBONS (HCFCS)"
    },
    {
      "code": "38247900",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES CONTAINING HALOGENATED DERIVATIVES OF METHANE, ETHANE OR PROPANE:-- OTHER"
    },
    {
      "code": "38247910",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED MIXTURES CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER: :CONTAINING OZONE DEPLETING SUBSTANCES"
    },
    {
      "code": "38247990",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED MIXTURES CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER: :OTHER"
    },
    {
      "code": "38248100",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES AND PREPARATIONS CONTAINING OXIRANE (ETHYLENE OXIDE), POLYBROMINATED BIPHENYLS (PBBS), POLYCHLORINATED BIPHENYLS (PCBS), POLYCHLORINATED TERPHENYLS (PCTS) OR TRIS (2,3-DIBROMOPROPYL) PHOSPHATE:-- CONTAINING OXIRANE (ETHYLENE OXIDE)"
    },
    {
      "code": "38248200",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES AND PREPARATIONS CONTAINING OXIRANE (ETHYLENE OXIDE), POLYBROMINATED BIPHENYLS (PBBS), POLYCHLORINATED BIPHENYLS (PCBS), POLYCHLORINATED TERPHENYLS (PCTS) OR TRIS (2,3-DIBROMOPROPYL) PHOSPHATE:-- CONTAINING POLYCHLORINATED BIPHENYLS (PCBS), POLYCHLORINATED TERPHENYLS (PCTS) OR POLYBROMINATED BIPHENYLS (PBBS)"
    },
    {
      "code": "38248300",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED- MIXTURES AND PREPARATIONS CONTAINING OXIRANE (ETHYLENE OXIDE), POLYBROMINATED BIPHENYLS (PBBS), POLYCHLORINATED BIPHENYLS (PCBS), POLYCHLORINATED TERPHENYLS (PCTS) OR TRIS (2,3-DIBROMOPROPYL) PHOSPHATE:-- CONTAINING TRIS (2,3-DIBROMOPROPYL) PHOSPHATE"
    },
    {
      "code": "38248400",
      "description": "Containing aldrin (ISO), camphechlor (ISO) (toxaphene), chlordane (ISO), chlordecone (ISO), DDT (ISO) (clofenotane (INN), 1, 1, 1- trichloro-2, 2-bis(p-chlorophenyl)ethane), dieldrin (ISO, INN), endosulfan (ISO), endrin (ISO), heptachlor (ISO) or mirex (ISO)"
    },
    {
      "code": "38248500",
      "description": "Containing 1, 2, 3, 4, 5, 6-hexachlorocyclohexane (HCH (ISO)), including lindane (ISO, INN)"
    },
    {
      "code": "38248600",
      "description": "Containing pentachlorobenzene (ISO) or hexachlorobenzene (ISO)"
    },
    {
      "code": "38248700",
      "description": "Containing perfluorooctane sulphonic acid, its salts, perfluorooctane sulphonamides, or perfluorooctane sulphonyl fluoride"
    },
    {
      "code": "38248800",
      "description": "Containing tetra-, penta-, hexa, hepta- or octabromodiphenyl ethers"
    },
    {
      "code": "382490",
      "description": "Other"
    },
    {
      "code": "38249011",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION"
    },
    {
      "code": "38249012",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS: CASE HARDENING COMPOUND"
    },
    {
      "code": "38249013",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS HEAT TRANSFER SALTS"
    },
    {
      "code": "38249014",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM"
    },
    {
      "code": "38249015",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS MIXED POLYETHYLENE GLYCOLS"
    },
    {
      "code": "38249016",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS SALTS FOR CURING OR SALTING"
    },
    {
      "code": "38249017",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: AMMONIACAL GAS LIQUORS AND SPENT OXIDE PRODUCED IN COAL GAS PURIFICATION, CASE HARDENING COMPOUND, HEAT TRANSFER SALTS; MIXTURE OF DIPHENYL AND DIPHENYL OXIDE AS HEAT TRANSFER MEDIUM, MIXED POLYETHYLENE GLYCOLS; SALTS FOR CURING OR SALTING, SURFACE TENSION REDUCING AGENTS SURFACE TENSION REDUCING AGENTS"
    },
    {
      "code": "38249021",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: ELECTROPLATING SALTS"
    },
    {
      "code": "38249022",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: WATER TREATMENT CHEMICALS, ION EXCHANGER (INN) SUCH AS PERMIUTITS, ZEOLITES)"
    },
    {
      "code": "38249023",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: GRAMOPHONE RECORDS MAKING MATERIAL"
    },
    {
      "code": "38249024",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: CORRECTING FLUID"
    },
    {
      "code": "38249025",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: PRECIPITATED SILICA AND SILICA GEL"
    },
    {
      "code": "38249026",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: ELECTROPLATING SALTS; WATER TREATMENT CHEMICALS; ION EXCHANGER; CORRECTING FLUID; PRECIPITATED SILICA AND SILICA GEL; OIL WELL CHEMICAL: OIL WELL CHEMICALS"
    },
    {
      "code": "38249031",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : MIXTURES CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE"
    },
    {
      "code": "38249032",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : FERRITE POWDER"
    },
    {
      "code": "38249033",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : CAPACITOR FLUIDS  PCB TYPE"
    },
    {
      "code": "38249034",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : DIPPING OIL FOR TREATMENT OF GRAPES"
    },
    {
      "code": "38249035",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS, POLY CHLORINATED TERPHENYLS, CROCIDOLITE"
    },
    {
      "code": "38249036",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : GOODS OF A KIND KNOWN AS HAZARDOUS WASTE"
    },
    {
      "code": "38249037",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: MIXTURE CONTAINING PERHALOGENATED DERIVATIVES OF ACYCLIC HYDROCARBONS CONTAINING TWO OR MORE DIFFERENT HALOGENS OTHER THAN CHLORINE AND FLUORINE; FERRITE POWDER; CAPACITOR FLUIDS  PCB TYPE; DIPPING OIL FOR TREATMENT OF GRAPES; POLY BROMINATED BIPHENYLS, POLY CHLORINATED BIPHENYLS,POLY-CHLORINATED TERPHENYLS, CROCIDOLITE; GOODS OF A KIND KNOWN AS HAZARDOUS WASTE; PHOSPHOGYPSUM : PHOSPHOGYPSUM"
    },
    {
      "code": "38249038",
      "description": "PHOSPHONIC ACID,METHYL COMPOUND WITH (AMINOIMINO METHYL) UREA (1:1)"
    },
    {
      "code": "38249090",
      "description": "PREPARED BINDERS FOR FOUNDRY MOULDS OR CORES; CHEMICAL PRODUCTS AND PREPARATIONS OF THE CHEMICAL OR ALLIED INDUSTRIES (INCLUDING THOSE CONSISTING OF MIXTURES OF NATURAL PRODUCTS), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER: - OTHER"
    },
    {
      "code": "38249100",
      "description": "Mixtures and preparations consisting mainly of (5-ethyl-2-methyl-2-oxido-1, 3, 2-dioxaphosphinan-5-yl)methyl methyl methylphosphonate and bis[(5-ethyl-2-methyl-2-oxido-1, 3, 2- dioxaphosphinan-5-yl) methyl] methylphosphonate"
    },
    {
      "code": "382499",
      "description": "Other"
    },
    {
      "code": "38249900",
      "description": "Other"
    },
    {
      "code": "3825",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER"
    },
    {
      "code": "38251000",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - MUNICIPAL WASTE"
    },
    {
      "code": "38252000",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - SEWAGE SLUDGE"
    },
    {
      "code": "38253000",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - CLINICAL WASTE"
    },
    {
      "code": "38254100",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - WASTE ORGANIC SOLVENTS:HALOGENATED"
    },
    {
      "code": "38254900",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - WASTE ORGANIC SOLVENTS: OTHER"
    },
    {
      "code": "38255000",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER WASTES OF METAL PICKLING LIQUORS, HYDRAULIC FLUIDS, BRAKE FLUIDS AND ANTI-FREEZE FLUIDS"
    },
    {
      "code": "38256100",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - OTHER WASTES FROM CHEMICAL OR ALLIED INDUSTRIES:MAINLY CONTAINING ORGANIC CONSTITUENTS"
    },
    {
      "code": "38256900",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER - OTHER WASTES FROM CHEMICAL OR ALLIED INDUSTRIES:OTHER"
    },
    {
      "code": "38259000",
      "description": "RESIDUAL PRODUCTS OF THE CHEMICAL OR ALLIED INDUSTRIES, NOT ELSEWHERE SPECIFIED OR INCLUDED; MUNICIPAL WASTE; SEWAGE SLUDGE; OTHER WASTES SPECIFIED IN NOTE 6 TO THIS CHAPTER OTHER"
    },
    {
      "code": "3826",
      "description": "BIODIESEL AND MIXTURES THEREOF, NOT CONTAINING OR CONTAINING LESS THAN 70% BY WEIGHT OF PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "382600",
      "description": "Biodiesel and mintrues thereof"
    },
    {
      "code": "38260000",
      "description": "BIODIESEL AND MIXTURES THEREOF, NOT CONTAINING OR CONTAINING LESS THAN 70% BY WEIGHT OF PETROLEUM OILS OR OILS OBTAINED FROM BITUMINOUS MINERALS"
    },
    {
      "code": "39",
      "description": "Plastics and articles thereof"
    },
    {
      "code": "3901",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS"
    },
    {
      "code": "390110",
      "description": "Polyethylene having a specific gravity of less than 0.94"
    },
    {
      "code": "39011010",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS - POLYETHYLENE HAVING A SPECIFIC GRAVITY OF LESS THAN 0.94 : LINEAR LOW DENSITY POLYETHYLENE (LLDPE)"
    },
    {
      "code": "39011020",
      "description": "Low density polyethylene (LDPE)"
    },
    {
      "code": "39011090",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS - POLYETHYLENE HAVING A SPECIFIC GRAVITY OF LESS THAN 0.94 : OTHER"
    },
    {
      "code": "39012000",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS POLYETHYLENE HAVING A SPECIFIC GRAVITY OF 0.94 OR MORE"
    },
    {
      "code": "39013000",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS ETHYLENE - VINYL ACETATE COPOLYMERS"
    },
    {
      "code": "390140",
      "description": "Ethylene-alpha-olefin copolymers, having a specific gravity of less than 0.94"
    },
    {
      "code": "39014010",
      "description": "Linear low density polyethylene (LLDPE), in which ethylene monomer unit contributes less than 95% by weight of the total polymer content"
    },
    {
      "code": "39014090",
      "description": "Other"
    },
    {
      "code": "390190",
      "description": "Other"
    },
    {
      "code": "39019000",
      "description": "Other"
    },
    {
      "code": "39019010",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS - OTHER: LINEAR MEDIUM DENSITY POLYETHYLENE (LMDPE)"
    },
    {
      "code": "39019090",
      "description": "POLYMERS OF ETHYLENE, IN PRIMARY FORMS - OTHER: OTHER"
    },
    {
      "code": "3902",
      "description": "POLYMERS OF PROPYLENE OR OF OTHER OLEFINS, IN PRIMARY FORMS"
    },
    {
      "code": "39021000",
      "description": "POLYMERS OF PROPYLENE OR OF OTHER OLEFINS, IN PRIMARY FORMS POLYPROPYLENE"
    },
    {
      "code": "39022000",
      "description": "POLYMERS OF PROPYLENE OR OF OTHER OLEFINS, IN PRIMARY FORMS POLY ISO BUTYLENE"
    },
    {
      "code": "39023000",
      "description": "POLYMERS OF PROPYLENE OR OF OTHER OLEFINS, IN PRIMARY FORMS PROPYLENE COPOLYMERS"
    },
    {
      "code": "39029000",
      "description": "POLYMERS OF PROPYLENE OR OF OTHER OLEFINS, IN PRIMARY FORMS OTHER"
    },
    {
      "code": "3903",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS"
    },
    {
      "code": "39031100",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - POLYSTYRENE: EXPANSIBLE"
    },
    {
      "code": "390319",
      "description": "Other"
    },
    {
      "code": "39031910",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - POLYSTYRENE: OTHER: MOULDING POWDER"
    },
    {
      "code": "39031990",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - POLYSTYRENE: OTHER: OTHER"
    },
    {
      "code": "39032000",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - STYRENE - ACRYLONITRILE (SAN) COPOLYMERS"
    },
    {
      "code": "39033000",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - ACRYLONITRILE - BUTADINE - STYRENE (ABS) COPOLYMERS"
    },
    {
      "code": "39039010",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - OTHER: COPOLYMERS, SOLELY OF STYRENE WITH ALLYL ALCOHOL, OF ANY ACETYL VALUE OF 175 OR MORE"
    },
    {
      "code": "39039020",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - OTHER: BROMINATED POLYSTYRENE, CONTAINING BY WEIGHT 58% OR MORE BUT NOT MORE THAN 71% OF BROMINE, IN ONE OF THE FORMS MENTIONED IN NOTE 6(B) TO THIS CHAPTER"
    },
    {
      "code": "39039090",
      "description": "POLYMERS OF STYRENE, IN PRIMARY FORMS - OTHER: OTHER"
    },
    {
      "code": "3904",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS"
    },
    {
      "code": "390410",
      "description": "Poly (Vinyl Chlodide) not mixed with any other substances"
    },
    {
      "code": "39041010",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - POLY (VINYL CHLORIDE), NOT MIXED WITH ANY OTHER SUBSTANCES:BINDERS FOR PIGMENTS"
    },
    {
      "code": "39041020",
      "description": "Suspension grade PVC resin"
    },
    {
      "code": "39041090",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - POLY (VINYL CHLORIDE), NOT MIXED WITH ANY OTHER SUBSTANCES:OTHER"
    },
    {
      "code": "390421",
      "description": "NON-PLASTICISED"
    },
    {
      "code": "39042100",
      "description": "Non-Plasticised"
    },
    {
      "code": "39042110",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER POLY (VINYL CHLORIDE): NON - PLASTICISED:POLY (VINYL CHLORIDE) RESINS"
    },
    {
      "code": "39042190",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER POLY (VINYL CHLORIDE): NON - PLASTICISED:OTHER"
    },
    {
      "code": "390422",
      "description": "Plasticised"
    },
    {
      "code": "39042200",
      "description": "Plasticised"
    },
    {
      "code": "39042210",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER POLY (VINYL CHLORIDE): PLASTICISED: POLY (VINYL CHLORIDE) (PVC) RESINS (EMULSION GRADE)"
    },
    {
      "code": "39042290",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER POLY (VINYL CHLORIDE): PLASTICISED:OTHER"
    },
    {
      "code": "390430",
      "description": "Vinyl chloride-vinyl acetate copolymers"
    },
    {
      "code": "39043010",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - VINYL CHLORIDE - VINYL ACETATE COPOLYMERS:POLY (VINYL DERIVATIVES)"
    },
    {
      "code": "39043090",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - VINYL CHLORIDE - VINYL ACETATE COPOLYMERS:OTHER"
    },
    {
      "code": "39044000",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER VINYL CHLORIDE COPOLYMERS"
    },
    {
      "code": "390450",
      "description": "Vinylidene chloride polymers"
    },
    {
      "code": "39045010",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - VINYLIDENE CHLORIDE POLYMERS COPOLYMER OF VINYLIDENE CHLORIDE WITH ACRYLONITRITE, IN THE FORM OF EXPANSIBLE BEADS OF A DIAMETER OF 4 MICROMETERS OR MORE BUT NOT MORE THAN 20 MICROMETERS"
    },
    {
      "code": "39045090",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - VINYLIDENE CHLORIDE POLYMERS OTHER"
    },
    {
      "code": "39046100",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - FLURO - POLYMERS : POLYTETRAFLUROETHYLENE"
    },
    {
      "code": "39046910",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - FLURO - POLYMERS : OTHER : POLY (VINYL FLUORIDE), IN ONE OF THE FORMS MENTIONED IN NOTE 6(B) TO THIS CHAPTER"
    },
    {
      "code": "39046990",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - FLURO - POLYMERS : OTHER : OTHER"
    },
    {
      "code": "390490",
      "description": "Other"
    },
    {
      "code": "39049000",
      "description": "POLYMERS OF VINYL CHLORIDE OR OF OTHER HALOGENATED OLEFINS, IN PRIMARY FORMS - OTHER"
    },
    {
      "code": "39049010",
      "description": "Chlorinated poly vinyl chloride (CPVC) resin"
    },
    {
      "code": "39049090",
      "description": "Other"
    },
    {
      "code": "3905",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS"
    },
    {
      "code": "390512",
      "description": "In aqueous dispersion"
    },
    {
      "code": "39051210",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : IN AQUEOUS DISPERSION:POLY (VINYL ACETATE) (PVA), MOULDING MATERIAL"
    },
    {
      "code": "39051220",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : IN AQUEOUS DISPERSION:POLY (VINYL ACETATE) RESINS"
    },
    {
      "code": "39051290",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : IN AQUEOUS DISPERSION: OTHER"
    },
    {
      "code": "390519",
      "description": "Other"
    },
    {
      "code": "39051910",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : OTHER :POLY (VINYL ACETATE) (PVA) MOULDING MATERIAL"
    },
    {
      "code": "39051920",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : OTHER :POLY (VINYL ACETATE) AND RESINS"
    },
    {
      "code": "39051990",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ACETATE) : OTHER :OTHER"
    },
    {
      "code": "39052100",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - VINYL ACETATE COPOLYMERS : IN AQUEOUS DISPERSION"
    },
    {
      "code": "39052900",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - VINYL ACETATE COPOLYMERS : OTHER"
    },
    {
      "code": "39053000",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - POLY (VINYL ALCOHOL), WHETHER OR NOT CONTAINING UNHYDROLYSED ACETATE GROUPS"
    },
    {
      "code": "39059100",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - OTHER : COPOLYMERS"
    },
    {
      "code": "390599",
      "description": "Other"
    },
    {
      "code": "39059910",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - OTHER : OTHER :POLY (VINYL PIROLIDONE) (P ALCOHOL)"
    },
    {
      "code": "39059990",
      "description": "POLYMERS OF VINYL ACETATE OR OF OTHER VINYL ESTERS, IN PRIMARY FORMS; OTHER VINYL POLYMERS IN PRIMARY FORMS - OTHER : OTHER :OTHER"
    },
    {
      "code": "3906",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS"
    },
    {
      "code": "39061010",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - POLY (METHYL METHACRYLATE): BINDERS FOR PIGMENTS OR INKS"
    },
    {
      "code": "39061090",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - POLY (METHYL METHACRYLATE): OTHER"
    },
    {
      "code": "390690",
      "description": "Other"
    },
    {
      "code": "39069010",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - OTHER :ACRYLIC RESINS"
    },
    {
      "code": "39069020",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - OTHER : POLYACRYLATE MOULDING POWDER"
    },
    {
      "code": "39069030",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - OTHER :COPOLYMERS OF ACRYLONITRILE"
    },
    {
      "code": "39069040",
      "description": "Poly (acrylic acid)"
    },
    {
      "code": "39069050",
      "description": "Polyacrylonitrile (PAN)"
    },
    {
      "code": "39069060",
      "description": "Copolymers of acrylonitrile"
    },
    {
      "code": "39069070",
      "description": "Sodium polyacrylate"
    },
    {
      "code": "39069090",
      "description": "ACRYLIC POLYMERS IN PRIMARY FORMS - OTHER :OTHER"
    },
    {
      "code": "3907",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS"
    },
    {
      "code": "39071000",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLYACETALS"
    },
    {
      "code": "390720",
      "description": "Other polyethers"
    },
    {
      "code": "39072010",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYETHERS:POLY (ETHER ALCOHOLS)"
    },
    {
      "code": "39072090",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYETHERS:OTHER"
    },
    {
      "code": "390730",
      "description": "Epoxide resins"
    },
    {
      "code": "39073010",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - EPOXIDE RESINS :EPOXY RESINS"
    },
    {
      "code": "39073090",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - EPOXIDE RESINS :OTHER"
    },
    {
      "code": "39074000",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLYCARBONATES"
    },
    {
      "code": "39075000",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - ALKYD RESINS"
    },
    {
      "code": "390760",
      "description": "Poly (ethylene terepthalate)"
    },
    {
      "code": "39076010",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLY (ETHYLENE TEREPTHALATE):HAVING AN INTRINSIC VISCOSITY OF LESS THAN 0.64 DL/G"
    },
    {
      "code": "39076020",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLY (ETHYLENE TEREPTHALATE): HAVING AN INTRINSIC VISCOSITY OF NOT LESS THAN 0.64 DL/G AND NOT GREATER THAN 0.72 DL/G"
    },
    {
      "code": "39076090",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLY (ETHYLENE TEREPTHALATE): OTHER (INCLUDING CLEAN, CLOURLESS GRADES)"
    },
    {
      "code": "390761",
      "description": "Having a viscosity number of 78 ml/g or higher"
    },
    {
      "code": "39076110",
      "description": "PET flake (chip)"
    },
    {
      "code": "39076190",
      "description": "Other primary form"
    },
    {
      "code": "390769",
      "description": "Other"
    },
    {
      "code": "39076930",
      "description": "PET flake (chip)"
    },
    {
      "code": "39076990",
      "description": "Other primary form"
    },
    {
      "code": "39077000",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - POLY(LACTIC ACID)"
    },
    {
      "code": "390791",
      "description": "Unsaturated"
    },
    {
      "code": "39079110",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : UNSATURATED :MALEIC RESINS"
    },
    {
      "code": "39079120",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : UNSATURATED :POLYESTER OR CONTRACT RESINS"
    },
    {
      "code": "39079130",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : UNSATURATED : FUMERIC RESINS"
    },
    {
      "code": "39079140",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : UNSATURATED :DIALLYLPHTHALATE RESINS"
    },
    {
      "code": "39079150",
      "description": "POLY (BUTYLENE TEREPTHALATE)"
    },
    {
      "code": "39079190",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : UNSATURATED :OTHER"
    },
    {
      "code": "390799",
      "description": "Other"
    },
    {
      "code": "39079900",
      "description": "Other"
    },
    {
      "code": "39079910",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : OTHER :DIALLYL PHTHALATE RESINS"
    },
    {
      "code": "39079920",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : OTHER : POLY(BUTYLENE TEREPTHALATE)"
    },
    {
      "code": "39079990",
      "description": "POLYACETALS, OTHER POLYETHERS AND EPOXIDE RESINS, IN PRIMARY FORMS; POLYCARBONATES, ALKYD RESINS, POLYALLYLESTERS AND OTHER POLYESTERS, IN PRIMARY FORMS - OTHER POLYESTERS : OTHER :OTHER"
    },
    {
      "code": "3908",
      "description": "POLYAMIDES IN PRIMARY FORMS"
    },
    {
      "code": "390810",
      "description": "Polyamide -6, -11,-12, -6, 6, -6, 9, -6, 10 or"
    },
    {
      "code": "39081010",
      "description": "POLYAMIDES IN PRIMARY FORMS - POLYAMIDE - 6, - 11, - 12, - 6, 6, - 6, 9, - 6, 10 OR - 6,12:NYLON MOULDING POWDER"
    },
    {
      "code": "39081011",
      "description": "Flake (chip)"
    },
    {
      "code": "39081019",
      "description": "Other primary form"
    },
    {
      "code": "39081021",
      "description": "Flake (chip)"
    },
    {
      "code": "39081029",
      "description": "Other primary form"
    },
    {
      "code": "39081031",
      "description": "Flake (chip)"
    },
    {
      "code": "39081039",
      "description": "Other primary form"
    },
    {
      "code": "39081041",
      "description": "Flake (chip)"
    },
    {
      "code": "39081049",
      "description": "Other primary form"
    },
    {
      "code": "39081051",
      "description": "Flake (chip)"
    },
    {
      "code": "39081059",
      "description": "Other primary form"
    },
    {
      "code": "39081061",
      "description": "Flake (chip)"
    },
    {
      "code": "39081069",
      "description": "Other primary form"
    },
    {
      "code": "39081071",
      "description": "Flake (chip)"
    },
    {
      "code": "39081079",
      "description": "Other primary form"
    },
    {
      "code": "39081090",
      "description": "POLYAMIDES IN PRIMARY FORMS - POLYAMIDE - 6, - 11, - 12, - 6, 6, - 6, 9, - 6, 10 OR - 6,12:OTHER"
    },
    {
      "code": "390890",
      "description": "Other"
    },
    {
      "code": "39089000",
      "description": "Other"
    },
    {
      "code": "39089010",
      "description": "POLYAMIDES IN PRIMARY FORMS - OTHER : NYLON MOULDING POWDER"
    },
    {
      "code": "39089020",
      "description": "POLYAMIDES IN PRIMARY FORMS - OTHER : NYLON IN OTHER PRIMARY FORMS"
    },
    {
      "code": "39089090",
      "description": "POLYAMIDES IN PRIMARY FORMS - OTHER : OTHER"
    },
    {
      "code": "3909",
      "description": "AMINO-RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS"
    },
    {
      "code": "390910",
      "description": "Urea resins; thiourea resins"
    },
    {
      "code": "39091010",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - UREA RESINS; THIOUREA RESINS :UREA FORMALDEHYDE RESINS"
    },
    {
      "code": "39091090",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - UREA RESINS; THIOUREA RESINS :OTHER"
    },
    {
      "code": "390920",
      "description": "Melamine resins"
    },
    {
      "code": "39092010",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - MELAMINE RESINS:MELAMINE FORMALDEHYDE RESINS"
    },
    {
      "code": "39092090",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - MELAMINE RESINS:OTHER"
    },
    {
      "code": "390930",
      "description": "Other amino-resins"
    },
    {
      "code": "39093010",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - OTHER AMINO - RESINS: POLY (PHENYLENE OXIDE)"
    },
    {
      "code": "39093090",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - OTHER AMINO - RESINS:OTHER"
    },
    {
      "code": "39093100",
      "description": "Poly(methylene phenyl isocyanate) (crude MDI, polymeric MDI)"
    },
    {
      "code": "390939",
      "description": "Other"
    },
    {
      "code": "39093910",
      "description": "Poly(phenylene oxide)"
    },
    {
      "code": "39093990",
      "description": "Other"
    },
    {
      "code": "390940",
      "description": "Phenolic resins"
    },
    {
      "code": "39094010",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS :CRESOL FORMALDEHYDE OXIDE"
    },
    {
      "code": "39094020",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS : PHENOL FORMALDEHYDE RESINS"
    },
    {
      "code": "39094030",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS :ALKYL PHENOL - FORMALDEHYDE RESINS"
    },
    {
      "code": "39094040",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS : KETONIC RESINS"
    },
    {
      "code": "39094050",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS : PHENOXI RESINS"
    },
    {
      "code": "39094060",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS : TERPENE PHENOLIC RESINS"
    },
    {
      "code": "39094090",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - PHENOLIC RESINS :OTHER"
    },
    {
      "code": "39095000",
      "description": "AMINO - RESINS, PHENOLIC RESINS AND POLYURETHANES, IN PRIMARY FORMS - POLYURETHANES"
    },
    {
      "code": "3910",
      "description": "SILICONES IN PRIMARY FORMS"
    },
    {
      "code": "391000",
      "description": "Silicones in primary forms"
    },
    {
      "code": "39100010",
      "description": "SILICONES IN PRIMARY FORMS - SILICONES IN PRIMARY FORMS - SILICONE RESINS"
    },
    {
      "code": "39100020",
      "description": "SILICONES IN PRIMARY FORMS - SILICONES IN PRIMARY FORMS - SILICONE OIL"
    },
    {
      "code": "39100090",
      "description": "SILICONES IN PRIMARY FORMS - SILICONES IN PRIMARY FORMS - OTHER"
    },
    {
      "code": "3911",
      "description": "P ETROLEUM RESINS , COUMARONE -INDENE RESINS , POLYTERPENES, POLYSULPHIDES, POLYSULPHONES AND OTHER PRODUCTS SPECIFIED IN NOTE 3 TO THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS"
    },
    {
      "code": "391110",
      "description": "Petroleum resins, coumarone, indene or coumarone-indene resins and polyterpenes"
    },
    {
      "code": "39111010",
      "description": "PETROLEUM RESINS, COUMARONE - INDENE RESINS, POLYTERPENES, POLYSULPHIDES, POLYSULPHONES AND OTHER PRODUCTS SPECIFIED IN NOTE 3 TO THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - PETROLEUM RESINS, COUMARONE - INDENE OR COUMARONE - INDENE RESINS AND POLYTERPENES:COUMARONE - INDENE RESINS"
    },
    {
      "code": "39111090",
      "description": "PETROLEUM RESINS, COUMARONE - INDENE RESINS, POLYTERPENES, POLYSULPHIDES, POLYSULPHONES AND OTHER PRODUCTS SPECIFIED IN NOTE 3 TO THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - PETROLEUM RESINS, COUMARONE - INDENE OR COUMARONE - INDENE RESINS AND POLYTERPENES:OTHER"
    },
    {
      "code": "391190",
      "description": "Other"
    },
    {
      "code": "39119010",
      "description": "PETROLEUM RESINS, COUMARONE - INDENE RESINS, POLYTERPENES, POLYSULPHIDES, POLYSULPHONES AND OTHER PRODUCTS SPECIFIED IN NOTE 3 TO THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER :POLYSULPHONES"
    },
    {
      "code": "39119090",
      "description": "PETROLEUM RESINS, COUMARONE - INDENE RESINS, POLYTERPENES, POLYSULPHIDES, POLYSULPHONES AND OTHER PRODUCTS SPECIFIED IN NOTE 3 TO THIS CHAPTER, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER :OTHER"
    },
    {
      "code": "3912",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS"
    },
    {
      "code": "391211",
      "description": "Non-plasticised"
    },
    {
      "code": "39121110",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : NON - PLASTICISED:CELLULOSE ACETATE FLAKES"
    },
    {
      "code": "39121120",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : NON - PLASTICISED:CELLULOSE ACETATE MOULDING POWDER"
    },
    {
      "code": "39121130",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : NON - PLASTICISED:CELLULOSE ACETOBUTYRATE MOULDING POWDER"
    },
    {
      "code": "39121140",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : NON - PLASTICISED: CELLULOSE NITRATE, DYNAMIC GRADE"
    },
    {
      "code": "39121190",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : NON - PLASTICISED:OTHER"
    },
    {
      "code": "391212",
      "description": "Plasticised"
    },
    {
      "code": "39121210",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : PLASTICISED :CELLULOSE ACETATE FLAKES"
    },
    {
      "code": "39121220",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : PLASTICISED :CELLULOSE ACETATE MOULDING POWDER"
    },
    {
      "code": "39121230",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : PLASTICISED :CELLULOSE ACETOBUTYRATE MOULDING POWDER"
    },
    {
      "code": "39121290",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ACETATES : PLASTICISED :OTHER"
    },
    {
      "code": "391220",
      "description": "Cellulose nitrates (including collodions)"
    },
    {
      "code": "39122011",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE NITRATES (INCLUDING COLLODIONS): NON - PLASTICISED:MOULDING POWDERS"
    },
    {
      "code": "39122019",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE NITRATES (INCLUDING COLLODIONS): NON - PLASTICISED:OTHER"
    },
    {
      "code": "39122021",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE NITRATES (INCLUDING COLLODIONS): PLASTICISED : MOULDING POWDERS"
    },
    {
      "code": "39122029",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE NITRATES (INCLUDING COLLODIONS): PLASTICISED : OTHER"
    },
    {
      "code": "39123100",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: CARBOXYMETHYL CELLULOSE AND ITS SALTS"
    },
    {
      "code": "391239",
      "description": "Other -"
    },
    {
      "code": "39123911",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : NON - PLASCISED:ETHYLCELLULOSE"
    },
    {
      "code": "39123912",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : NON - PLASCISED:METHYLCELLULOSE"
    },
    {
      "code": "39123919",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : NON - PLASCISED:OTHER CELLULOSE ETHERS"
    },
    {
      "code": "39123921",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : PLASTICISED: ETHYL CELLULOSE"
    },
    {
      "code": "39123922",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : PLASTICISED: METHYL CELLULOSE"
    },
    {
      "code": "39123929",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - CELLULOSE ETHERS: OTHER : PLASTICISED: OTHER CELLULOSE ETHER"
    },
    {
      "code": "391290",
      "description": "Other"
    },
    {
      "code": "39129010",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER: CELLULOSE PROPIONATE AND ACETO PROPIONATE, NON - PLASTICISED"
    },
    {
      "code": "39129020",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER:VISCOSE SPONGE"
    },
    {
      "code": "39129090",
      "description": "CELLULOSE AND ITS CHEMICAL DERIVATIVES, NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER:OTHER"
    },
    {
      "code": "3913",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS"
    },
    {
      "code": "391310",
      "description": "Alginic acid, its salts and esters"
    },
    {
      "code": "39131010",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - ALGINIC ACID, ITS SALTS AND ESTERS :SODIUM ALGINATE"
    },
    {
      "code": "39131090",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - ALGINIC ACID, ITS SALTS AND ESTERS :OTHER"
    },
    {
      "code": "391390",
      "description": "Other"
    },
    {
      "code": "39139011",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER : CHEMICAL DERIVATIVES OF NATURAL RUBBER:CHLORINATED RUBBER"
    },
    {
      "code": "39139019",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER : CHEMICAL DERIVATIVES OF NATURAL RUBBER:OTHER"
    },
    {
      "code": "39139020",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER :HARDENED PROTEINS (SUCH AS HARDENED CASEIN, GELATIN)"
    },
    {
      "code": "39139030",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER :DEXTRAN"
    },
    {
      "code": "39139090",
      "description": "NATURAL POLYMERS (FOR EXAMPLE, ALGINIC ACID) AND MODIFIED NATURAL POLYMERS (FOR EXAMPLE, HARDENED PROTEINS, CHEMICAL DERIVATIVES OF NATURAL RUBBER), NOT ELSEWHERE SPECIFIED OR INCLUDED, IN PRIMARY FORMS - OTHER :OTHER"
    },
    {
      "code": "3914",
      "description": "ION–EXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS"
    },
    {
      "code": "391400",
      "description": "Ion exchangers based on polymers of headings 3901 to 3913, in primary forms"
    },
    {
      "code": "39140010",
      "description": "IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS - IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS:ION - EXCHANGERS OF THE CONDENSATION, POLYCONDENSATION OR POLYADDITION TYPE"
    },
    {
      "code": "39140020",
      "description": "IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS - IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS:ION - EXCHANGERS OF POLYMERISATION OR CO - POLYMERISATION TYPE"
    },
    {
      "code": "39140090",
      "description": "IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS - IONEXCHANGERS BASED ON POLYMERS OF HEADINGS 3901 TO 3913, IN PRIMARY FORMS:OTHER"
    },
    {
      "code": "3915",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS"
    },
    {
      "code": "39151000",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF POLYMERS OF ETHYLENE"
    },
    {
      "code": "39152000",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF POLYMERS OF STYRENE"
    },
    {
      "code": "391530",
      "description": "Of polymers of vinyl chloride"
    },
    {
      "code": "39153010",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE:OF COPOLYMERS OF VINYL CHLORIDE"
    },
    {
      "code": "39153090",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE:OTHER"
    },
    {
      "code": "391590",
      "description": "Of other plastics"
    },
    {
      "code": "39159010",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS :OF POLYPROPYLENE"
    },
    {
      "code": "39159021",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF POLYMERS OF VINYL ACETATE : OF COPOLYMERS OF VINYL ACETATE"
    },
    {
      "code": "39159029",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF POLYMERS OF VINYL ACETATE : OTHER"
    },
    {
      "code": "39159030",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS :OF ACRYLIC POLYMERS AND METHLYACRYLIC COPOLYMERS"
    },
    {
      "code": "39159041",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF ALKYDS, POLYESTERS AND EPOXIDE RESINS : OF ALKYDS AND POLYESTERS"
    },
    {
      "code": "39159042",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF ALKYDS, POLYESTERS AND EPOXIDE RESINS : OF PET BOTTLES"
    },
    {
      "code": "39159049",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF ALKYDS, POLYESTERS AND EPOXIDE RESINS : OF EPOXIDE RESINS"
    },
    {
      "code": "39159050",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS :OF POLYAMIDES"
    },
    {
      "code": "39159061",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF AMINO RESINS; PHENOLIC RESINS AND POLYURETHANES :OF PHENOPLAST"
    },
    {
      "code": "39159062",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF AMINO RESINS; PHENOLIC RESINS AND POLYURETHANES :OF AMINOPLAST"
    },
    {
      "code": "39159063",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF AMINO RESINS; PHENOLIC RESINS AND POLYURETHANES :OF POLYURETHANES"
    },
    {
      "code": "39159071",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE AND ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE"
    },
    {
      "code": "39159072",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE AND ITS CHEMICAL DERIVATIVES: CELLULOSE PLASTIC WASTE SUCH AS CELLULOSE NITRATE FILM SCRAP NON - PLASTICISED"
    },
    {
      "code": "39159073",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE AND ITS CHEMICAL DERIVATIVES: CELLULOSE PLASTIC WASTE SUCH AS CELLULOSE NITRATE FILM SCRAP PLASTICIZED"
    },
    {
      "code": "39159074",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE AND ITS CHEMICAL DERIVATIVES: CELLULOSE PLASTIC WASTE SUCH AS CELLULOSE ACETATC FILM SCRAP NON - PLASTICISED"
    },
    {
      "code": "39159075",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE AND ITS CHEMICAL DERIVATIVES: CELLULOSE PLASTIC WASTE SUCH AS CELLULOSE ACETATC FILM SCRAP PLASTICIZED"
    },
    {
      "code": "39159090",
      "description": "WASTE, PARINGS AND SCRAP, OF PLASTICS - OF OTHER PLASTICS :OTHER"
    },
    {
      "code": "3916",
      "description": "MONOFILAMENT OF WHICH ANY CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE-WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS"
    },
    {
      "code": "391610",
      "description": "Of polymers of ethylene"
    },
    {
      "code": "39161010",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF ETHYLENE: RODS OF POLYETHYLENE"
    },
    {
      "code": "39161020",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF ETHYLENE:CANES"
    },
    {
      "code": "39161090",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF ETHYLENE:OTHER"
    },
    {
      "code": "391620",
      "description": "Of polymers of vinyl chloride"
    },
    {
      "code": "39162011",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE: OF POLY (VINYL CHLORIDE) COPOLYMERS :CANES"
    },
    {
      "code": "39162019",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE: OF POLY (VINYL CHLORIDE) COPOLYMERS :OTHER"
    },
    {
      "code": "39162091",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE: OF POLY (VINYL CHLORIDE) COPOLYMERS : OTHER: CANES"
    },
    {
      "code": "39162099",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF POLYMERS OF VINYL CHLORIDE: OF POLY (VINYL CHLORIDE) COPOLYMERS : OTHER: OTHER"
    },
    {
      "code": "391690",
      "description": "Of other plastics"
    },
    {
      "code": "39169010",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : CANES"
    },
    {
      "code": "39169021",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF PHENOPLAST"
    },
    {
      "code": "39169022",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF AMINOPLAST"
    },
    {
      "code": "39169023",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF ALKYDS AND POLYSTERS"
    },
    {
      "code": "39169024",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS : OF POLYAMIDES"
    },
    {
      "code": "39169025",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF POLYURETHANES"
    },
    {
      "code": "39169026",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP)"
    },
    {
      "code": "39169027",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF POLYPROPYLENE"
    },
    {
      "code": "39169028",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF PHENOPLAST, AMINOPLAST, ALKYDS AND POLYESTERS, POLYAMIDES, POLYURETHANES, EPOXIDE - RESINS (INCLUDING WASTE AND SCRAP), POLYPROPYLENE AND ACRYLIC, METHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS :OF ACRYLICMETHACRYLIC AND ACRYLOMETHACRYLIC POLYMERS"
    },
    {
      "code": "39169031",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF POLYMERISATION AND COPOLYMERISATION PRODUCTS OF POLYSTYRENE AND POLYMETHYL METHACRYLATE :OF POLYMERISATION AND COPOLYMERISATION PRODUCTS OF POLYSTYRENE"
    },
    {
      "code": "39169032",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF POLYMERISATION AND COPOLYMERISATION PRODUCTS OF POLYSTYRENE AND POLYMETHYL METHACRYLATE :OF POLYMETHYL METHACRYLATE"
    },
    {
      "code": "39169040",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS :OF REGENERATED CELLULOSE"
    },
    {
      "code": "39169050",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS :OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED"
    },
    {
      "code": "39169060",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS :OF VULCANIZED FIBRE"
    },
    {
      "code": "39169070",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF CELLULOSE ACETATE AND ACETATE BUTYRATE, WHETHER OR NOT PLASTICIZED"
    },
    {
      "code": "39169080",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS : OF VINYL PLASTIC"
    },
    {
      "code": "39169090",
      "description": "MONOFILAMENT OF WHICH ANY CROSS - SECTIONAL DIMENSION EXCEEDS 1MM, RODS, STICKS AND PROFILE SHAPES, WHETHER OR NOT SURFACE - WORKED BUT NOT OTHERWISE WORKED, OF PLASTICS - OF OTHER PLASTICS :OF OTHER POLYMERISATION AND COPOLYMERISATION PRODUCTS"
    },
    {
      "code": "3917",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS"
    },
    {
      "code": "39171010",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - ARTIFICIAL GUTS (SAUSAGE CASINGS) OF HARDENED PROTEIN OR OF CELLULOSIC MATERIALS: OF HARDENED PROTEIN"
    },
    {
      "code": "39171020",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - ARTIFICIAL GUTS (SAUSAGE CASINGS) OF HARDENED PROTEIN OR OF CELLULOSIC MATERIALS: OF CELLULOSIC MATERIALS"
    },
    {
      "code": "391721",
      "description": "Of polymers of ethylene"
    },
    {
      "code": "39172110",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF POLYMERS OF ETHYLENE:TUBES OF POLYETHYLENE"
    },
    {
      "code": "39172190",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF POLYMERS OF ETHYLENE:OTHER"
    },
    {
      "code": "39172200",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF POLYMERS OF PROPYLENE"
    },
    {
      "code": "391723",
      "description": "Of polymers of vinyl chloride"
    },
    {
      "code": "39172310",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF POLYMERS OF VINYL CHLORIDE :SEAMLESS TUBES"
    },
    {
      "code": "39172390",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF POLYMERS OF VINYL CHLORIDE :OTHER"
    },
    {
      "code": "391729",
      "description": "Of other plastics"
    },
    {
      "code": "39172910",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS : SEAMLESS TUBES OF COPOLYMERS OF VINYL ACETATE AND VINYL CHLORIDE"
    },
    {
      "code": "39172920",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS : SEAMLESS TUBES OF POLYMERS AND COPOLYMERS OF POLYSTYRENE"
    },
    {
      "code": "39172930",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS :TUBES OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICISED"
    },
    {
      "code": "39172940",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS :TUBES OF CELLULOSE ACETATE OR ACETATE BUTYRATE"
    },
    {
      "code": "39172950",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS :TUBES OF VINYL PLASTICS"
    },
    {
      "code": "39172990",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - TUBES, PIPES AND HOSES, RIGID: OF OTHER PLASTICS :OTHER"
    },
    {
      "code": "39173100",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: FLEXIBLE TUBES, PIPES AND HOSES, HAVING A MINIMUM BURST PRESSURE OF 27.6 MPA"
    },
    {
      "code": "39173210",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER, NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS, WITHOUT FITTINGS:OF CONDENSATION OR REARRANGEMENT POLYMERIZATION PRODUCTS, WHETHER OR NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "39173220",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER, NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS, WITHOUT FITTINGS:OF ADDITION POLYMERISATION PRODUCTS"
    },
    {
      "code": "39173290",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER, NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS, WITHOUT FITTINGS:OTHER"
    },
    {
      "code": "39173300",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER, NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS, WITH FITTINGS"
    },
    {
      "code": "391739",
      "description": "Other"
    },
    {
      "code": "39173910",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER:OF CONDENSATION OR REARRANGEMENT POLYMERIZATION PRODUCTS, WHETHER OR NOT CHEMICALLY MODIFIED"
    },
    {
      "code": "39173920",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER:OF ADDITION POLYMERISATION PRODUCTS"
    },
    {
      "code": "39173990",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - OTHER TUBES, PIPES AND HOSES: OTHER:OTHER"
    },
    {
      "code": "39174000",
      "description": "TUBES, PIPES AND HOSES, AND FITTINGS THEREFOR (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES), OF PLASTICS - FITTINGS"
    },
    {
      "code": "3918",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELFADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER"
    },
    {
      "code": "391810",
      "description": "Of polymers of vinyl chloride"
    },
    {
      "code": "39181010",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELF - ADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER - OF POLYMERS OF VINYL CHLORIDE : WALL OR CEILING COVERINGS COMBINED WITH KNITTED OR WOVEN FABRICS, NONWOVENS OR FELTS"
    },
    {
      "code": "39181090",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELF - ADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER - OF POLYMERS OF VINYL CHLORIDE :OTHER"
    },
    {
      "code": "391890",
      "description": "Of other plastics"
    },
    {
      "code": "39189010",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELF - ADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER - OF OTHER PLASTICS :FLOOR COVERINGS OF LINOXYNE"
    },
    {
      "code": "39189020",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELF - ADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER - OF OTHER PLASTICS :WALL OR CEILING COVERINGS COMBINED WITH KNITTED OR WOVEN FABRICS, NONWOVENS OR FELTS"
    },
    {
      "code": "39189090",
      "description": "FLOOR COVERINGS OF PLASTICS, WHETHER OR NOT SELF - ADHESIVE, IN ROLLS OR IN THE FORM OF TILES; WALL OR CEILING COVERINGS OF PLASTICS, AS DEFINED IN NOTE 9 TO THIS CHAPTER - OF OTHER PLASTICS :OTHER"
    },
    {
      "code": "3919",
      "description": "SELF-ADHESIVE PLATES, SHEETS, FILM, FOIL, TAPE, STRIP AND OTHER FLAT SHAPES, OF PLASTICS, WHETHER OR NOT IN ROLLS"
    },
    {
      "code": "39191000",
      "description": "SELF - ADHESIVE PLATES, SHEETS, FILM, FOIL, TAPE, STRIP AND OTHER FLAT SHAPES, OF PLASTICS, WHETHER OR NOT IN ROLLS - IN ROLLS OF WIDTH NOT EXCEEDING 20 CM"
    },
    {
      "code": "391990",
      "description": "Other"
    },
    {
      "code": "39199010",
      "description": "SELF - ADHESIVE PLATES, SHEETS, FILM, FOIL, TAPE, STRIP AND OTHER FLAT SHAPES, OF PLASTICS, WHETHER OR NOT IN ROLLS - OTHER : PLASTIC STICKERS, WHETHER OR NOT PRINTED, EMBOSSED, OR IMPREGNATED"
    },
    {
      "code": "39199020",
      "description": "SELF - ADHESIVE PLATES, SHEETS, FILM, FOIL, TAPE, STRIP AND OTHER FLAT SHAPES, OF PLASTICS, WHETHER OR NOT IN ROLLS - OTHER :CELLULOSE ADHESIVE TAPE"
    },
    {
      "code": "39199090",
      "description": "SELF - ADHESIVE PLATES, SHEETS, FILM, FOIL, TAPE, STRIP AND OTHER FLAT SHAPES, OF PLASTICS, WHETHER OR NOT IN ROLLS - OTHER : OTHER"
    },
    {
      "code": "3920",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON-CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS"
    },
    {
      "code": "392010",
      "description": "Of polymers of ehtylene"
    },
    {
      "code": "39201011",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: RIGID, PLAIN"
    },
    {
      "code": "39201012",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: FLEXIBLE, PLAIN"
    },
    {
      "code": "39201019",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: OTHER"
    },
    {
      "code": "39201091",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: OTHER : RIGID, PLAIN"
    },
    {
      "code": "39201092",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39201099",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF ETHYLENE: SHEETS OF POLYETHYLENE: OTHER : OTHER"
    },
    {
      "code": "392020",
      "description": "Of polymers of propylene"
    },
    {
      "code": "39202010",
      "description": "20 OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF PROPYLENE :RIGID, PLAIN"
    },
    {
      "code": "39202020",
      "description": "20 OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF PROPYLENE :FLEXIBLE, PLAIN"
    },
    {
      "code": "39202090",
      "description": "20 OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF PROPYLENE :OTHER"
    },
    {
      "code": "392030",
      "description": "Of polymers of styrene"
    },
    {
      "code": "39203010",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF STYRENE :RIGID, PLAIN"
    },
    {
      "code": "39203020",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF STYRENE :FLEXIBLE, PLAIN"
    },
    {
      "code": "39203090",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF STYRENE :OTHER"
    },
    {
      "code": "39204300",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF VINYL CHLORIDE:CONTAINING BY WEIGHT NOT LESS THAN 6% OF PLASTICIZERS"
    },
    {
      "code": "39204900",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYMERS OF VINYL CHLORIDE:OTHER"
    },
    {
      "code": "392051",
      "description": "Of poly (methyl methacrylate)"
    },
    {
      "code": "39205111",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS :RIGID, PLAIN"
    },
    {
      "code": "39205112",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS :FLEXIBLE, PLAIN"
    },
    {
      "code": "39205119",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS :OTHER"
    },
    {
      "code": "39205191",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS : OTHER : RIGID, PLAIN"
    },
    {
      "code": "39205192",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS : OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39205199",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OF POLY (METHYL METHACRYLATE): SHEETS : OTHER : OTHER"
    },
    {
      "code": "392059",
      "description": "Other"
    },
    {
      "code": "39205911",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : POLYACRYLATE SHEETS:RIGID, PLAIN"
    },
    {
      "code": "39205912",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : POLYACRYLATE SHEETS:FLEXIBLE, PLAIN"
    },
    {
      "code": "39205919",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : POLYACRYLATE SHEETS:OTHER"
    },
    {
      "code": "39205991",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : OTHER : RIGID, PLAIN"
    },
    {
      "code": "39205992",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39205999",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF ACRYLIC POLYMERS: OTHER : OTHER : OTHER"
    },
    {
      "code": "392061",
      "description": "Of polycarbonates"
    },
    {
      "code": "39206110",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLYCARBONATES :RIGID, PLAIN"
    },
    {
      "code": "39206120",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLYCARBONATES :FLEXIBLE, PLAIN"
    },
    {
      "code": "39206190",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLYCARBONATES :OTHER"
    },
    {
      "code": "392062",
      "description": "Of poly (ethylene terephthalate)"
    },
    {
      "code": "39206210",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLY (ETHYLENE TEREPHTHALATE):RIGID, PLAIN"
    },
    {
      "code": "39206220",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLY (ETHYLENE TEREPHTHALATE):FLEXIBLE, PLAIN"
    },
    {
      "code": "39206290",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF POLY (ETHYLENE TEREPHTHALATE):OTHER"
    },
    {
      "code": "392063",
      "description": "Of unsaturated polyesters"
    },
    {
      "code": "39206310",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF UNSATURATED POLYESTERS :RIGID, PLAIN"
    },
    {
      "code": "39206320",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF UNSATURATED POLYESTERS :FLEXIBLE, PLAIN"
    },
    {
      "code": "39206390",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF UNSATURATED POLYESTERS :OTHER"
    },
    {
      "code": "392069",
      "description": "Of other polyesters"
    },
    {
      "code": "39206911",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : PACKAGING FILM:RIGID, PLAIN"
    },
    {
      "code": "39206912",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : PACKAGING FILM:FLEXIBLE, PLAIN"
    },
    {
      "code": "39206919",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : PACKAGING FILM:OTHER"
    },
    {
      "code": "39206921",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : SUN AND/OR DUST CONTROL FILM : RIGID, PLAIN"
    },
    {
      "code": "39206922",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : SUN AND/OR DUST CONTROL FILM : FLEXIBLE, PLAIN"
    },
    {
      "code": "39206929",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : SUN AND/OR DUST CONTROL FILM : OTHER"
    },
    {
      "code": "39206931",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER FILM :RIGID, PLAIN"
    },
    {
      "code": "39206932",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER FILM : FLEXIBLE, PLAIN"
    },
    {
      "code": "39206939",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER FILM : OTHER"
    },
    {
      "code": "39206991",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER : RIGID, PLAIN"
    },
    {
      "code": "39206992",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39206999",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYCARBONATES, ALKYD RESINS, POLYALLYL ESTERS OR OTHER POLYESTERS : OF OTHER POLYESTERS : OTHER : OTHER"
    },
    {
      "code": "392071",
      "description": "Of regenerated cellulose"
    },
    {
      "code": "39207111",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: CELLO PHANE TRANSPARENT:FILM"
    },
    {
      "code": "39207119",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: CELLO PHANE TRANSPARENT:OTHER"
    },
    {
      "code": "39207121",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: SHEETS OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED :PLAIN"
    },
    {
      "code": "39207129",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: SHEETS OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED :OTHER"
    },
    {
      "code": "39207191",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: OTHER : RIGID, PLAIN"
    },
    {
      "code": "39207192",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: OTHER :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207199",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF REGENERATED CELLULOSE: OTHER :OTHER"
    },
    {
      "code": "39207210",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF VULCANISED FIBRE :RIGID, PLAIN"
    },
    {
      "code": "39207220",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF VULCANISED FIBRE :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207290",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF VULCANISED FIBRE : OTHER"
    },
    {
      "code": "392073",
      "description": "Of cellulose acetate"
    },
    {
      "code": "39207311",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEET OF CELLULOSE ACETATE, NON - PLASTICIZED:RIGID, PLAIN"
    },
    {
      "code": "39207312",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEET OF CELLULOSE ACETATE, NON - PLASTICIZED: FLEXIBLE, PLAIN"
    },
    {
      "code": "39207319",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEET OF CELLULOSE ACETATE, NON - PLASTICIZED:OTHER"
    },
    {
      "code": "39207321",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEETS OF CELLULOSE ACETATE, PLASTICIZED :RIGID, PLAIN"
    },
    {
      "code": "39207322",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEETS OF CELLULOSE ACETATE, PLASTICIZED :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207329",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: SHEETS OF CELLULOSE ACETATE, PLASTICIZED :OTHER"
    },
    {
      "code": "39207391",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: OTHER :RIGID, PLAIN"
    },
    {
      "code": "39207392",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: OTHER :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207399",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF CELLULOSE ACETATE: OTHER :OTHER"
    },
    {
      "code": "392079",
      "description": "Of other cellulose derivatives"
    },
    {
      "code": "39207911",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : SHEETS OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED :RIGID, PLAIN"
    },
    {
      "code": "39207912",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : SHEETS OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207919",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : SHEETS OF CELLULOSE NITRATE AND CELLULOID, WHETHER OR NOT PLASTICIZED :OTHER"
    },
    {
      "code": "39207991",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : OTHER :RIGID, PLAIN"
    },
    {
      "code": "39207992",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : OTHER :FLEXIBLE, PLAIN"
    },
    {
      "code": "39207999",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF CELLULOSE OR ITS CHEMICAL DERIVATIVES: OF OTHER CELLULOSE DERIVATIVES : OTHER :OTHER"
    },
    {
      "code": "392091",
      "description": "Of other plastics"
    },
    {
      "code": "39209110",
      "description": "Rigid, plain"
    },
    {
      "code": "39209111",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OF POLY (VINYL BUTYRAL): RIGID, PLAIN"
    },
    {
      "code": "39209112",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OF POLY (VINYL BUTYRAL): FLEXIBLE, PLAIN"
    },
    {
      "code": "39209119",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OF POLY (VINYL BUTYRAL): OTHER"
    },
    {
      "code": "39209120",
      "description": "Flexible, plain"
    },
    {
      "code": "39209190",
      "description": "Other"
    },
    {
      "code": "392092",
      "description": "Of polyamides"
    },
    {
      "code": "39209211",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: RIGID, PLAIN"
    },
    {
      "code": "39209212",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: FLEXIBLE, PLAIN"
    },
    {
      "code": "39209219",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: OTHER"
    },
    {
      "code": "39209291",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: OTHER : RIGID, PLAIN"
    },
    {
      "code": "39209292",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209299",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF POLYAMIDES: POLY (AMIDE FLUORIDE) FILM: OTHER : OTHER"
    },
    {
      "code": "392093",
      "description": "Of amino-resins"
    },
    {
      "code": "39209310",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF AMINO - RESINS : RIGID, PLAIN"
    },
    {
      "code": "39209320",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF AMINO - RESINS : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209390",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF AMINO - RESINS : OTHER"
    },
    {
      "code": "392094",
      "description": "Of phenolic resins"
    },
    {
      "code": "39209410",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF PHENOLIC RESINS : RIGID, PLAIN"
    },
    {
      "code": "39209420",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF PHENOLIC RESINS : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209490",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF PHENOLIC RESINS : OTHER"
    },
    {
      "code": "392099",
      "description": "Of other plastics"
    },
    {
      "code": "39209911",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, FILM, FOIL AND STRIP OF POLY(VINYL ACETATE) : RIGID, PLAIN"
    },
    {
      "code": "39209912",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, FILM, FOIL AND STRIP OF POLY(VINYL ACETATE) : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209919",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, FILM, FOIL AND STRIP OF POLY(VINYL ACETATE) : OTHER"
    },
    {
      "code": "39209921",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: FILM, SHEETS, STRIP OF VINYL PLASTICS : RIGID, PLAIN"
    },
    {
      "code": "39209922",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: FILM, SHEETS, STRIP OF VINYL PLASTICS : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209929",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: FILM, SHEETS, STRIP OF VINYL PLASTICS : OTHER"
    },
    {
      "code": "39209931",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, STRIP, FILM OR FOIL OF COPOLYMERS OF VINYL CHLORIDE AND VINYL ACETATE : RIGID, PLAIN"
    },
    {
      "code": "39209932",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, STRIP, FILM OR FOIL OF COPOLYMERS OF VINYL CHLORIDE AND VINYL ACETATE : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209939",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: PLATES, SHEETS, STRIP, FILM OR FOIL OF COPOLYMERS OF VINYL CHLORIDE AND VINYL ACETATE : OTHER"
    },
    {
      "code": "39209941",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: SHEET OF POLY (TETRAFLUORO - ETHYLENE) (PTFE): RIGID, PLAIN"
    },
    {
      "code": "39209942",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: SHEET OF POLY (TETRAFLUORO - ETHYLENE) (PTFE): FLEXIBLE, PLAIN"
    },
    {
      "code": "39209949",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: SHEET OF POLY (TETRAFLUORO - ETHYLENE) (PTFE): OTHER"
    },
    {
      "code": "39209951",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: RETRO REFLECTIVE SHEETING: RIGID, PLAIN"
    },
    {
      "code": "39209952",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: RETRO REFLECTIVE SHEETING: FLEXIBLE, PLAIN"
    },
    {
      "code": "39209959",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: RETRO REFLECTIVE SHEETING: OTHER"
    },
    {
      "code": "39209960",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: CLICKING BOARDS FOR LEATHER MACHINERY"
    },
    {
      "code": "39209991",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OTHER : RIGID, PLAIN"
    },
    {
      "code": "39209992",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OTHER : FLEXIBLE, PLAIN"
    },
    {
      "code": "39209999",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS, NON - CELLULAR AND NOT REINFORCED, LAMINATED, SUPPORTED OR SIMILARLY COMBINED WITH OTHER MATERIALS - OF OTHER PLASTICS: OTHER : OTHER"
    },
    {
      "code": "3921",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS"
    },
    {
      "code": "39211100",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF POLYMERS OF STYRENE"
    },
    {
      "code": "39211200",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF POLYMERS OF VINYL CHLORIDE"
    },
    {
      "code": "392113",
      "description": "Of polyurethanes"
    },
    {
      "code": "39211310",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF POLYURETHANES:FLEXIBLE"
    },
    {
      "code": "39211390",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF POLYURETHANES:OTHER"
    },
    {
      "code": "39211400",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF REGENERATED CELLULOSE"
    },
    {
      "code": "39211900",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - CELLULAR : OF OTHER PLASTICS"
    },
    {
      "code": "392190",
      "description": "Other"
    },
    {
      "code": "39219010",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : THERMOCOL"
    },
    {
      "code": "39219021",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : RIGID, LACQUERED"
    },
    {
      "code": "39219022",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : FLEXIBLE, LACQUERED"
    },
    {
      "code": "39219023",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : RIGID, METALLISED"
    },
    {
      "code": "39219024",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : FLEXIBLE, METALLISED"
    },
    {
      "code": "39219025",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : RIGID, LAMINATED"
    },
    {
      "code": "39219026",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : FLEXIBLE, LAMINATED"
    },
    {
      "code": "39219029",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF POLYMERS OF VINYL CHLORIDE : OTHER"
    },
    {
      "code": "39219031",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : RIGID, LACQUERED"
    },
    {
      "code": "39219032",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : FLEXIBLE, LACQUERED"
    },
    {
      "code": "39219033",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : RIGID, METALLISED"
    },
    {
      "code": "39219034",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : FLEXIBLE, METALLISED"
    },
    {
      "code": "39219035",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : RIGID, LAMINATED"
    },
    {
      "code": "39219036",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : FLEXIBLE, LAMINATED"
    },
    {
      "code": "39219039",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OF REGENERATED CELLULOSE : OTHER"
    },
    {
      "code": "39219091",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : RIGID, LACQUERED"
    },
    {
      "code": "39219092",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : FLEXIBLE, LACQUERED"
    },
    {
      "code": "39219093",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : RIGID, METALLISED"
    },
    {
      "code": "39219094",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : FLEXIBLE, METALLISED"
    },
    {
      "code": "39219095",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : RIGID, LAMINATED"
    },
    {
      "code": "39219096",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : FLEXIBLE, LAMINATED"
    },
    {
      "code": "39219099",
      "description": "OTHER PLATES, SHEETS, FILM, FOIL AND STRIP, OF PLASTICS - OTHER : OTHER : OTHER"
    },
    {
      "code": "3922",
      "description": "BATHS, SHOWER-BATHS, SINKS, WASH-BASINS, BIDETS, LAVATORY PANS, SEATS AND COVERS, FLUSHING CISTERNS AND SIMILAR SANITARY WARE, OF PLASTICS"
    },
    {
      "code": "39221000",
      "description": "BATHS, SHOWER - BATHS, SINKS, WASH - BASINS, BIDETS, LAVATORY PANS, SEATS AND COVERS, FLUSHING CISTERNS AND SIMILAR SANITARY WARE, OF PLASTICS - BATHS, SHOWER - BATHS, SINKS AND WASH BASINS"
    },
    {
      "code": "39222000",
      "description": "BATHS, SHOWER - BATHS, SINKS, WASH - BASINS, BIDETS, LAVATORY PANS, SEATS AND COVERS, FLUSHING CISTERNS AND SIMILAR SANITARY WARE, OF PLASTICS - LAVATORY SEATS AND COVERS"
    },
    {
      "code": "39229000",
      "description": "BATHS, SHOWER - BATHS, SINKS, WASH - BASINS, BIDETS, LAVATORY PANS, SEATS AND COVERS, FLUSHING CISTERNS AND SIMILAR SANITARY WARE, OF PLASTICS - OTHER"
    },
    {
      "code": "3923",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS"
    },
    {
      "code": "392310",
      "description": "Boxes, cases, crates and similar articles"
    },
    {
      "code": "39231010",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - BOXES, CASES, CRATES AND SIMILAR ARTICLES: PLASTIC CONTAINERS FOR AUDIO OR VIDEO CASSETTES, CASSETTE TAPES, FLOPPY DISK AND SIMILAR ARTICLES"
    },
    {
      "code": "39231020",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - BOXES, CASES, CRATES AND SIMILAR ARTICLES: WATCH - BOX, JEWELLERY BOX AND SIMILAR CONTAINERS OF PLASTICS"
    },
    {
      "code": "39231030",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - BOXES, CASES, CRATES AND SIMILAR ARTICLES: INSULATED WARE"
    },
    {
      "code": "39231040",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - BOXES, CASES, CRATES AND SIMILAR ARTICLES: PACKING FOR ACCOMMODATING CONNECTORS"
    },
    {
      "code": "39231090",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - BOXES, CASES, CRATES AND SIMILAR ARTICLES: OTHER"
    },
    {
      "code": "39232100",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - SACKS AND BAGS (INCLUDING CONES)OF POLYMERS OF ETHYLENE"
    },
    {
      "code": "392329",
      "description": "Of other plastics"
    },
    {
      "code": "39232910",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - SACKS AND BAGS (INCLUDING CONES)- OF OTHER PLASTICS: OF POLY (VINYL CHLORIDE)"
    },
    {
      "code": "39232990",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - SACKS AND BAGS (INCLUDING CONES)- OF OTHER PLASTICS: OTHER"
    },
    {
      "code": "392330",
      "description": "Carboys, bottles, flasks and similar articles"
    },
    {
      "code": "39233010",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - CARBOYS, BOTTLES, FLASKS AND SIMILAR ARTICLES: INSULATED WARE"
    },
    {
      "code": "39233090",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - CARBOYS, BOTTLES, FLASKS AND SIMILAR ARTICLES: OTHER"
    },
    {
      "code": "39234000",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - SPOOLS, COPS, BOBBINS AND SIMILAR SUPPORTS"
    },
    {
      "code": "392350",
      "description": "Stoppers, lids, caps and other closures"
    },
    {
      "code": "39235010",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - STOPPERS, LIDS, CAPS AND OTHER CLOSURES : CAPS AND CLOSURES FOR BOTTLES"
    },
    {
      "code": "39235090",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - STOPPERS, LIDS, CAPS AND OTHER CLOSURES : OTHER"
    },
    {
      "code": "392390",
      "description": "Other"
    },
    {
      "code": "39239010",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - OTHER : INSULATED WARE"
    },
    {
      "code": "39239020",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - OTHER : ASEPTIC BAGS"
    },
    {
      "code": "39239090",
      "description": "ARTICLES FOR THE CONVEYANCE OR PACKING OF GOODS, OF PLASTICS; STOPPERS, LIDS, CAPS AND OTHER CLOSURES, OF PLASTICS - OTHER : OTHER"
    },
    {
      "code": "3924",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS"
    },
    {
      "code": "392410",
      "description": "Tableware and kitchenware"
    },
    {
      "code": "39241010",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS - TABLEWARE AND KITCHENWARE : INSULATED WARE"
    },
    {
      "code": "39241090",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS - TABLEWARE AND KITCHENWARE : OTHER"
    },
    {
      "code": "392490",
      "description": "Other"
    },
    {
      "code": "39249010",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS - OTHER : TOILET ARTICLES"
    },
    {
      "code": "39249020",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS - OTHER : INSULATED WARE"
    },
    {
      "code": "39249090",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND HYGIENIC OR TOILET ARTICLES, OF PLASTICS - OTHER : OTHER"
    },
    {
      "code": "3925",
      "description": "BUILDERS’ WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "39251000",
      "description": "BUILDERS WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS, OF A CAPACITY EXCEEDING 300 L"
    },
    {
      "code": "39252000",
      "description": "BUILDERS WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED - DOORS, WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS"
    },
    {
      "code": "39253000",
      "description": "BUILDERS WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SHUTTERS, BLINDS (INCLUDING VENETIAN BLINDS) AND SIMILAR ARTICLES AND PARTS THEREOF"
    },
    {
      "code": "392590",
      "description": "Other"
    },
    {
      "code": "39259010",
      "description": "BUILDERS WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OF POLYURETHANE"
    },
    {
      "code": "39259090",
      "description": "BUILDERS WARE OF PLASTICS, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER : OTHER"
    },
    {
      "code": "3926",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914"
    },
    {
      "code": "392610",
      "description": "Office or school supplies"
    },
    {
      "code": "39261011",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914 - OFFICE OR SCHOOL SUPPLIES: OFFICE SUPPLIES OF A KIND CLASSIFIED AS STATIONERY OTHER THAN PINS, CLIPS, AND WRITING INSTRUMENTS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39261019",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914 - OFFICE OR SCHOOL SUPPLIES: OFFICE SUPPLIES OF A KIND CLASSIFIED AS STATIONERY OTHER THAN PINS, CLIPS, AND WRITING INSTRUMENTS : OTHER"
    },
    {
      "code": "39261091",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914 - OFFICE OR SCHOOL SUPPLIES: OTHER : OF POLYURETHANE FOAM"
    },
    {
      "code": "39261099",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914 - OFFICE OR SCHOOL SUPPLIES: OTHER : OTHER"
    },
    {
      "code": "392620",
      "description": "Articles of apparel and clothing accessories (including gloves, mittens and mitts)"
    },
    {
      "code": "39262011",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : I - GLOVES: DISPOSABLE"
    },
    {
      "code": "39262019",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : I - GLOVES: NON - DISPOSABLE"
    },
    {
      "code": "39262021",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : APRONS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39262029",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : APRONS : OTHER"
    },
    {
      "code": "39262031",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : PLASTIC STICKERS FOR GARMENTS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39262039",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : PLASTIC STICKERS FOR GARMENTS : OTHER"
    },
    {
      "code": "39262041",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : COLLAR STAYS, PATTIES, BUTTERFLY, SHOULDER - PADS AND OTHER STAYS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39262049",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : COLLAR STAYS, PATTIES, BUTTERFLY, SHOULDER - PADS AND OTHER STAYS : OTHER"
    },
    {
      "code": "39262091",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : OTHER : OF POLYURETHANE FOAM"
    },
    {
      "code": "39262099",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) : OTHER : OTHER"
    },
    {
      "code": "392630",
      "description": "Fittings for furniture, coach work or the like"
    },
    {
      "code": "39263010",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- FITTINGS FOR FURNITURE, COACH WORK OR THE LIKE : OF POLYURETHANE FOAM"
    },
    {
      "code": "39263090",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- FITTINGS FOR FURNITURE, COACH WORK OR THE LIKE : OTHER"
    },
    {
      "code": "392640",
      "description": "Statuettes and other ornamental articles"
    },
    {
      "code": "39264011",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : BANGLES : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264019",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : BANGLES : OTHER"
    },
    {
      "code": "39264021",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : BEADS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264029",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : BEADS : OTHER"
    },
    {
      "code": "39264031",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : STATUETTES : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264039",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : STATUETTES : OTHER"
    },
    {
      "code": "39264041",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : TABLE AND OTHER HOUSEHOLD ARTICLES (INCLUDING HOTEL AND RESTAURANT) FOR DECORATION : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264049",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : TABLE AND OTHER HOUSEHOLD ARTICLES (INCLUDING HOTEL AND RESTAURANT) FOR DECORATION : OTHER"
    },
    {
      "code": "39264051",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : DECORATIVE SHEETS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264059",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : DECORATIVE SHEETS : OTHER"
    },
    {
      "code": "39264060",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES :SEQUINE"
    },
    {
      "code": "39264091",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : OTHER : OF POLYURETHANE FOAM"
    },
    {
      "code": "39264099",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- STATUETTES AND OTHER ORNAMENTAL ARTICLES : OTHER : OTHER"
    },
    {
      "code": "392690",
      "description": "Other"
    },
    {
      "code": "39269010",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : PVC BELT CONVEYOR"
    },
    {
      "code": "39269021",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : COUPLERS, PACKING RINGS, O RINGS AND THE LIKE: OF POLYURETHANE FOAM"
    },
    {
      "code": "39269029",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : COUPLERS, PACKING RINGS, O RINGS AND THE LIKE: OTHER"
    },
    {
      "code": "39269031",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : LASTS, WITH OR WITHOUT STEEL HINGES ; EVA AND GRAPE SHEETS FOR SOLES AND HEELS; WELTS: OF POLYURETHANE FOAM"
    },
    {
      "code": "39269039",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : LASTS, WITH OR WITHOUT STEEL HINGES ; EVA AND GRAPE SHEETS FOR SOLES AND HEELS; WELTS: OTHER"
    },
    {
      "code": "39269041",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : RINGS, BUCKLES, TACKS, WASHERS AND OTHER DECORATIVE FITTINGS MADE OF PLASTIC USED AS TRIMMINGS AND EMBELLISHMENTS FOR LEATHER PRODUCTS; PATTERNS FOR LEATHER FOOT WEAR, LEATHER GARMENTS AND LEATHER GOODS: OF POLYURETHANE FOAM"
    },
    {
      "code": "39269049",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : RINGS, BUCKLES, TACKS, WASHERS AND OTHER DECORATIVE FITTINGS MADE OF PLASTIC USED AS TRIMMINGS AND EMBELLISHMENTS FOR LEATHER PRODUCTS; PATTERNS FOR LEATHER FOOT WEAR, LEATHER GARMENTS AND LEATHER GOODS: OTHER"
    },
    {
      "code": "39269051",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : RETROREFLECTIVE SHEETING OF OTHER THAN OF HEADING 3920 : OF POLYURETHANE FOAM"
    },
    {
      "code": "39269059",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : RETROREFLECTIVE SHEETING OF OTHER THAN OF HEADING 3920 : OTHER"
    },
    {
      "code": "39269061",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : HANGERS : OF POLYURETHANE FOAM"
    },
    {
      "code": "39269069",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : HANGERS : OTHER"
    },
    {
      "code": "39269071",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : PLASTIC OR NYLON TIPPED HAMMERS; INSULATING LINER OF NYLON , HDPE : OF POLYURETHANE FOAM"
    },
    {
      "code": "39269079",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : PLASTIC OR NYLON TIPPED HAMMERS; INSULATING LINER OF NYLON , HDPE : OTHER"
    },
    {
      "code": "39269080",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : POLYPROPYLENE ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "39269091",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : OTHER: OF POLYURETHANE FOAM"
    },
    {
      "code": "39269099",
      "description": "OTHER ARTICLES OF PLASTICS AND ARTICLES OF OTHER MATERIALS OF HEADINGS 3901 TO 3914- OTHER : OTHER: OTHER"
    },
    {
      "code": "40",
      "description": "Rubber and articles thereof"
    },
    {
      "code": "4001",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP"
    },
    {
      "code": "400110",
      "description": "Natural rubber latex, whether or not pre-vulcanised"
    },
    {
      "code": "40011010",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER LATEX, WHETHER OR NOT PREVULCANISED : PREVULCANISED"
    },
    {
      "code": "40011020",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER LATEX, WHETHER OR NOT PREVULCANISED : OTHER THAN PREVULCANISED"
    },
    {
      "code": "40012100",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : SMOKED SHEETS"
    },
    {
      "code": "40012200",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : TECHNICALLY SPECIFIED NATURAL RUBBER (TSNR)"
    },
    {
      "code": "400129",
      "description": "Other"
    },
    {
      "code": "40012910",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : OTHER : HEVEA"
    },
    {
      "code": "40012920",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : OTHER : PALE CREPE"
    },
    {
      "code": "40012930",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : OTHER : ESTATE BROWN CREPE"
    },
    {
      "code": "40012940",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : OTHER : OIL EXTENDED NATURAL RUBBER"
    },
    {
      "code": "40012990",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - NATURAL RUBBER IN OTHER FORMS : OTHER : OTHER"
    },
    {
      "code": "40013000",
      "description": "NATURAL RUBBER, BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP BALATA, GUTTA-PERCHA, GUAYULE, CHICLE AND SIMILAR NATURAL GUMS"
    },
    {
      "code": "4002",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - Styrene-butadiene rubber (SBR); carboxylated styrene-butadiene rubber (XSBR) :"
    },
    {
      "code": "40021100",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - STYRENE-BUTADIENE RUBBER (SBR); CARBOXYLATED STYRENE-BUTADIENE RUBBER (XSBR) : LATEX"
    },
    {
      "code": "400219",
      "description": "Other"
    },
    {
      "code": "40021910",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - STYRENE-BUTADIENE RUBBER (SBR); CARBOXYLATED STYRENE-BUTADIENE RUBBER (XSBR) : - OTHER : OIL EXTENDED STYRENE BUTADIENE RUBBER"
    },
    {
      "code": "40021920",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - STYRENE-BUTADIENE RUBBER (SBR); CARBOXYLATED STYRENE-BUTADIENE RUBBER (XSBR) : - OTHER : STYRENE BUTADIENE RUBBER WITH STYRENE CONTENT EXCEEDING 50%"
    },
    {
      "code": "40021930",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - STYRENE-BUTADIENE RUBBER (SBR); CARBOXYLATED STYRENE-BUTADIENE RUBBER (XSBR) : - OTHER : STYRENE BUTADIENE STYRENE OIL BOUND COPOLYMER"
    },
    {
      "code": "40021990",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - STYRENE-BUTADIENE RUBBER (SBR); CARBOXYLATED STYRENE-BUTADIENE RUBBER (XSBR) : - OTHER : OTHER"
    },
    {
      "code": "40022000",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIPBUTADIENE RUBBER (BR)"
    },
    {
      "code": "40023100",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- ISOBUTENE-ISOPRENE (BUTYL) RUBBER (IIR); HALO-ISOBUTENE-ISOPRENE RUBBER (CIIR OR BIIR) : ISOBUTENE-ISOPRENE (BUTYL) RUBBER (IIR)"
    },
    {
      "code": "40023900",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- ISOBUTENE-ISOPRENE (BUTYL) RUBBER (IIR); HALO-ISOBUTENE-ISOPRENE RUBBER (CIIR OR BIIR) : OTHER"
    },
    {
      "code": "40024100",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- CHLORPRENE (CHLOROBUTADIENE) RUBBER (CR) : LATEX"
    },
    {
      "code": "40024900",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- CHLORPRENE (CHLOROBUTADIENE) RUBBER (CR) : OTHER"
    },
    {
      "code": "40025100",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- ACRYLONITRILE-BUTADIENE RUBBER (NBR) : LATEX"
    },
    {
      "code": "40025900",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- ACRYLONITRILE-BUTADIENE RUBBER (NBR) : OTHER"
    },
    {
      "code": "40026000",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIPISOPRENE RUBBER (IR)"
    },
    {
      "code": "40027000",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIPETHYLENE-PROPYLENE-NON-CONJUGATED DIENE RUBBER (EPDM)"
    },
    {
      "code": "400280",
      "description": "Mixtures of any product of heading 40 01 with any product of this heading"
    },
    {
      "code": "40028010",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- MIXTURES OF ANY PRODUCT OF HEADING 40 01 WITH ANY PRODUCT OF THIS HEADING : LATEX"
    },
    {
      "code": "40028020",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- MIXTURES OF ANY PRODUCT OF HEADING 40 01 WITH ANY PRODUCT OF THIS HEADING : CHEMICALLY MODIFIED FORM OF NATURAL RUBBER INCLUDING GRAFT RUBBER"
    },
    {
      "code": "40028090",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- MIXTURES OF ANY PRODUCT OF HEADING 40 01 WITH ANY PRODUCT OF THIS HEADING : OTHER"
    },
    {
      "code": "40029100",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- OTHER : LATEX"
    },
    {
      "code": "400299",
      "description": "Other"
    },
    {
      "code": "40029910",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- OTHER : OTHER FACTICE (RUBBER SUBSTITUTE DERIVED FROM OIL)"
    },
    {
      "code": "40029920",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- OTHER : OTHERTREAD RUBBER COMPOUND, CUSHION COMPOUND, CUSHION GUM AND TREAD GUM FOR RESOLING OR REPAIRING OR RETREADING RUBBER TYRES"
    },
    {
      "code": "40029990",
      "description": "SYNTHETIC RUBBER AND FACTICE DERIVED FROM OILS, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP; MIXTURES OF ANY PRODUCT OF HEADING 4001 WITH ANY PRODUCT OF THIS HEADING, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP- OTHER : OTHEROTHER"
    },
    {
      "code": "4003",
      "description": "RECLAIMED RUBBER IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP"
    },
    {
      "code": "40030000",
      "description": "RECLAIMED RUBBER IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP"
    },
    {
      "code": "4004",
      "description": "WASTE, PARINGS AND SCRAP OF RUBBER (OTHER THAN HARD RUBBER) AND POWDERS AND GRANULES OBTAINED THEREFROM"
    },
    {
      "code": "40040000",
      "description": "WASTE, PARINGS AND SCRAP OF RUBBER (OTHER THAN HARD RUBBER) AND POWDERS AND GRANULES OBTAINED THEREFROM"
    },
    {
      "code": "4005",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP"
    },
    {
      "code": "40051000",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP COMPOUNDED WITH CARBON BLACK OR SILICA"
    },
    {
      "code": "400520",
      "description": "Solutions; dispersions other than those of sub-heading 4005 10"
    },
    {
      "code": "40052010",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - SOLUTIONS; DISPERSIONS OTHER THAN THOSE OF SUB-HEADING 4005 10 : CAN SEALING COMPOUND"
    },
    {
      "code": "40052090",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - SOLUTIONS; DISPERSIONS OTHER THAN THOSE OF SUB-HEADING 4005 10 : OTHER"
    },
    {
      "code": "400591",
      "description": "Plates, sheets and strip"
    },
    {
      "code": "40059110",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - OTHER : PLATES, SHEETS AND STRIP : HOSPITAL SHEETING"
    },
    {
      "code": "40059190",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - OTHER : PLATES, SHEETS AND STRIP : OTHER"
    },
    {
      "code": "400599",
      "description": "Other"
    },
    {
      "code": "40059910",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - OTHER : - OTHER : GRANULES OF UNVULCANISED NATURAL OR SYNTHETIC RUBBER, COMPOUNDED, READY FOR VULCANIZATION"
    },
    {
      "code": "40059990",
      "description": "COMPOUNDED RUBBER, UNVULCANISED, IN PRIMARY FORMS OR IN PLATES, SHEETS OR STRIP - OTHER : - OTHER : OTHER"
    },
    {
      "code": "4006",
      "description": "OTHER FORMS (FOR EXAMPLE, RODS, TUBES AND PROFILE SHAPES) AND ARTICLES (FOR EXAMPLE, DISCS AND RINGS), OF UNVULCANISED RUBBER"
    },
    {
      "code": "40061000",
      "description": "OTHER FORMS (FOR EXAMPLE, RODS, TUBES AND PROFILE SHAPES) AND ARTICLES (FOR EXAMPLE, DISCS AND RINGS), OF UNVULCANISED RUBBER STRIPS FOR RETREADING RUBBER TYRES"
    },
    {
      "code": "400690",
      "description": "Other"
    },
    {
      "code": "40069010",
      "description": "OTHER FORMS (FOR EXAMPLE, RODS, TUBES AND PROFILE SHAPES) AND ARTICLES (FOR EXAMPLE, DISCS AND RINGS), OF UNVULCANISED RUBBER - OTHER : THREAD, NOT COVERED"
    },
    {
      "code": "40069090",
      "description": "OTHER FORMS (FOR EXAMPLE, RODS, TUBES AND PROFILE SHAPES) AND ARTICLES (FOR EXAMPLE, DISCS AND RINGS), OF UNVULCANISED RUBBER - OTHER : OTHER"
    },
    {
      "code": "4007",
      "description": "VULCANISED RUBBER THREAD AND CORD"
    },
    {
      "code": "400700",
      "description": "Vulcanised rubber thread and cord"
    },
    {
      "code": "40070010",
      "description": "VULCANISED RUBBER THREAD AND CORD - VULCANISED RUBBER THREAD AND CORD : THREAD, NOT COVERED"
    },
    {
      "code": "40070020",
      "description": "VULCANISED RUBBER THREAD AND CORD - VULCANISED RUBBER THREAD AND CORD : CORD, NOT COVERED"
    },
    {
      "code": "40070090",
      "description": "VULCANISED RUBBER THREAD AND CORD - VULCANISED RUBBER THREAD AND CORD : OTHER"
    },
    {
      "code": "4008",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - Of cellular rubber :"
    },
    {
      "code": "400811",
      "description": "Plates, sheets and strip"
    },
    {
      "code": "40081110",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF CELLULAR RUBBER : - PLATES, SHEETS AND STRIP : OF MICRO-CELLULAR RUBBER"
    },
    {
      "code": "40081190",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF CELLULAR RUBBER : - PLATES, SHEETS AND STRIP : OTHER"
    },
    {
      "code": "400819",
      "description": "Other"
    },
    {
      "code": "40081910",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF CELLULAR RUBBER : - OTHER : BLOCKS OF MICRO-CELLULAR RUBBER BUT NOT OF LATEX FOAM SPONGE, USED IN THE MANUFACTURE OF SOLES, HEELS OR SOLES AND HEELS COMBINED, FOR FOOTWEAR"
    },
    {
      "code": "40081990",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF CELLULAR RUBBER : - OTHER : OTHER"
    },
    {
      "code": "400821",
      "description": "Plates, sheets and strip"
    },
    {
      "code": "40082110",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : PLATES, SHEETS AND STRIP : USED IN THE MANUFACTURE OF SOLES, HEELS OR SOLES AND HEELS COMBINED, FOR FOOTWEAR"
    },
    {
      "code": "40082120",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : PLATES, SHEETS AND STRIP : FOR RESOLING OR REPAIRING OR RETREADING RUBBER TYRES"
    },
    {
      "code": "40082190",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : PLATES, SHEETS AND STRIP : OTHER"
    },
    {
      "code": "400829",
      "description": "Other"
    },
    {
      "code": "40082910",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : OTHER : RUBBER SHEETS AND RESIN RUBBER SHEETS FOR SOLES AND HEELS"
    },
    {
      "code": "40082920",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : OTHER : BLOCKS USED IN THE MANUFACTURE OF SOLES, HEELS OR SOLES AND HEELS COMBINED, FOR FOOTWEAR"
    },
    {
      "code": "40082930",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : OTHER : LATEX FOAM SPONGE"
    },
    {
      "code": "40082940",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : OTHER : TREAD RUBBER AND TREAD PACKING STRIP FOR RESOLING OR REPAIRING OR RETREADING RUBBER TYRES"
    },
    {
      "code": "40082990",
      "description": "PLATES, SHEETS, STRIP, RODS AND PROFILE SHAPES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OF NON-CELLULAR RUBBER : OTHER : OTHER"
    },
    {
      "code": "4009",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - Not reinforced or otherwise combined with other materials:"
    },
    {
      "code": "40091100",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS : WITHOUT FITTINGS"
    },
    {
      "code": "40091200",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - NOT REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS : WITH FITTINGS"
    },
    {
      "code": "40092100",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED ONLY WITH METAL : WITHOUT FITTINGS"
    },
    {
      "code": "40092200",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED ONLY WITH METAL : WITH FITTINGS"
    },
    {
      "code": "40093100",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED ONLY WITH TEXTILE MATERIALS : WITHOUT FITTINGS"
    },
    {
      "code": "40093200",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED ONLY WITH TEXTILE MATERIALS : WITH FITTINGS"
    },
    {
      "code": "40094100",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS : WITHOUT FITTINGS"
    },
    {
      "code": "40094200",
      "description": "TUBES, PIPES AND HOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT THEIR FITTINGS (FOR EXAMPLE, JOINTS, ELBOWS, FLANGES) - REINFORCED OR OTHERWISE COMBINED WITH OTHER MATERIALS : WITH FITTINGS"
    },
    {
      "code": "4010",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - Conveyor belts or belting :"
    },
    {
      "code": "401011",
      "description": "Reinforced only with metal"
    },
    {
      "code": "40101110",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH METAL : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40101190",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH METAL : OTHER"
    },
    {
      "code": "401012",
      "description": "Reinforced only with textile materials"
    },
    {
      "code": "40101210",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH TEXTILE MATERIALS : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40101290",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "40101310",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH PLASTICS : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40101390",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - REINFORCED ONLY WITH PLASTICS : OTHER"
    },
    {
      "code": "401019",
      "description": "Other"
    },
    {
      "code": "40101910",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - OTHER : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40101990",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - CONVEYOR BELTS OR BELTING : - OTHER : OTHER"
    },
    {
      "code": "401031",
      "description": "Endless transmission belts of trapezoidal cross-section (V-belts), V-ribbed, of an outside circumference exceeding 60 cm but not exceeding 180 cm"
    },
    {
      "code": "40103110",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 180 CM BUT NOT EXCEEDING 240 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103190",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 180 CM BUT NOT EXCEEDING 240 CM : OTHER"
    },
    {
      "code": "401032",
      "description": "Endless transmission belts of trapezoidal crosssection (V-belts), other than V-ribbed, of an outside circumference exceeding 60 cm but not exceeding 180 cm"
    },
    {
      "code": "40103210",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), OTHER THAN V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 180 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103290",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), OTHER THAN V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 180 CM : OTHER"
    },
    {
      "code": "401033",
      "description": "Endless transmission belts of trapezoidal cross-section (V-belts), V-ribbed, of an outside circumference exceeding 180 cm but not exceeding 240 cm"
    },
    {
      "code": "40103310",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 180 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103390",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 180 CM : OTHER"
    },
    {
      "code": "401034",
      "description": "Endless transmission belts of trapezoidal crosssection (V-belts), other than V-ribbed, of an outside circumference exceeding 180 cm but not exceeding 240 cm"
    },
    {
      "code": "40103410",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), OTHER THAN V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 180 CM BUT NOT EXCEEDING 240 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103490",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS TRANSMISSION BELTS OF TRAPEZOIDAL CROSS-SECTION (V-BELTS), OTHER THAN V-RIBBED, OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 180 CM BUT NOT EXCEEDING 240 CM : OTHER"
    },
    {
      "code": "401035",
      "description": "Endless synchronous belts of an outside circumference exceeding 60 cm but not exceeding 150 cm"
    },
    {
      "code": "40103510",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS SYNCHRONOUS BELTS OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 150 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103590",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS SYNCHRONOUS BELTS OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 60 CM BUT NOT EXCEEDING 150 CM : OTHER"
    },
    {
      "code": "401036",
      "description": "Endless synchronous belts of an outside circumference exceeding 150 cm but not exceeding 198 cm"
    },
    {
      "code": "40103610",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS SYNCHRONOUS BELTS OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 150 CM BUT NOT EXCEEDING 198 CM : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT"
    },
    {
      "code": "40103690",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : ENDLESS SYNCHRONOUS BELTS OF AN OUTSIDE CIRCUMFERENCE EXCEEDING 150 CM BUT NOT EXCEEDING 198 CM : OTHER"
    },
    {
      "code": "401039",
      "description": "Other"
    },
    {
      "code": "40103911",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT : ENDLESS FLAT BELT"
    },
    {
      "code": "40103912",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT : PLY BELTING"
    },
    {
      "code": "40103919",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : WHERE THE RUBBER COMPOUND CONTENT IS LESS THAN 25% BY WEIGHT : OTHER"
    },
    {
      "code": "40103991",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : OTHER : ENDLESS FLAT BELT"
    },
    {
      "code": "40103992",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : OTHER : PLY BELTING"
    },
    {
      "code": "40103999",
      "description": "CONVEYOR OR TRANSMISSION BELTS OR BELTING OF VULCANISED RUBBER - TRANSMISSION BELTS OR BELTING : OTHER : OTHER : OTHER"
    },
    {
      "code": "4011",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER"
    },
    {
      "code": "401110",
      "description": "Of a kind used on motor cars (including station wagons and racing cars)"
    },
    {
      "code": "40111010",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON MOTOR CARS (INCLUDING STATION WAGONS AND RACING CARS) : RADIALS"
    },
    {
      "code": "40111090",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON MOTOR CARS (INCLUDING STATION WAGONS AND RACING CARS) : OTHER"
    },
    {
      "code": "401120",
      "description": "Of a kind used on buses or lorries"
    },
    {
      "code": "40112010",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON BUSES OR LORRIES : RADIALS"
    },
    {
      "code": "40112090",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON BUSES OR LORRIES : OTHER"
    },
    {
      "code": "40113000",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER OF A KIND USED ON AIRCRAFT"
    },
    {
      "code": "401140",
      "description": "Of a kind used on motor cycles"
    },
    {
      "code": "40114010",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON MOTOR CYCLES : FOR MOTOR CYCLES"
    },
    {
      "code": "40114020",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON MOTOR CYCLES : FOR MOTOR SCOOTERS"
    },
    {
      "code": "40114090",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON MOTOR CYCLES : OTHER"
    },
    {
      "code": "401150",
      "description": "Of a kind used on bicycles"
    },
    {
      "code": "40115010",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON BICYCLES : MULTI-CELLULAR POLYURETHANE (MCP) TUBELESS TYRES"
    },
    {
      "code": "40115090",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OF A KIND USED ON BICYCLES : OTHER, HAVING A HERRING-BONE ORSIMILAR TREAD"
    },
    {
      "code": "40116100",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER, HAVING A HERRING-BONE OR SIMILAR TREAD : OF A KIND USED ON AGRICULTURAL OR FORESTRY VEHICLES AND MACHINES"
    },
    {
      "code": "40116200",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER, HAVING A HERRING-BONE OR SIMILAR TREAD : OF A KIND USED ON CONSTRUCTION OR INDUSTRIAL HANDLING VEHICLES AND MACHINES AND HAVING A RIM SIZE NOT EXCEEDING 61 CM"
    },
    {
      "code": "40116300",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER, HAVING A HERRING-BONE OR SIMILAR TREAD : OF A KIND USED ON CONSTRUCTION OR INDUSTRIAL HANDLING VEHICLES AND MACHINES AND HAVING A RIM SIZE EXCEEDING 61 CM"
    },
    {
      "code": "40116900",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER, HAVING A HERRING-BONE OR SIMILAR TREAD : OTHER"
    },
    {
      "code": "40117000",
      "description": "Of a kind used on agricultural or forestry vehicles and machines"
    },
    {
      "code": "40118000",
      "description": "Of a kind used on construction, mining or industrial handling vehicles and machines"
    },
    {
      "code": "40119000",
      "description": "Other"
    },
    {
      "code": "40119200",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER : OF A KIND USED ON AGRICULTURAL OR FORESTRY VEHICLES AND MACHINES"
    },
    {
      "code": "40119300",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER : OF A KIND USED ON CONSTRUCTION OR INDUSTRIAL HANDLING VEHICLES AND MACHINES AND HAVING A RIM SIZE NOT EXCEEDING 61 CM"
    },
    {
      "code": "40119400",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER : OF A KIND USED ON CONSTRUCTION OR INDUSTRIAL HANDLING VEHICLES AND MACHINES AND HAVING A RIM SIZE EXCEEDING 61 CM"
    },
    {
      "code": "40119900",
      "description": "NEW PNEUMATIC TYRES, OF RUBBER - OTHER : OTHER"
    },
    {
      "code": "4012",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - Retreaded tyres :"
    },
    {
      "code": "40121100",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - RETREADED TYRES : OF A KIND USED ON MOTOR CARS (INCLUDING STATION WAGONS AND RACING CARS)"
    },
    {
      "code": "40121200",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - RETREADED TYRES : OF A KIND USED ON BUSES OR LORRIES"
    },
    {
      "code": "40121300",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - RETREADED TYRES : OF A KIND USED ON AIRCRAFT"
    },
    {
      "code": "401219",
      "description": "Other"
    },
    {
      "code": "40121910",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - RETREADED TYRES : - OTHER : FOR TWO WHEELERS"
    },
    {
      "code": "40121990",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - RETREADED TYRES : - OTHER : OTHER"
    },
    {
      "code": "401220",
      "description": "Used pneumatic tyres"
    },
    {
      "code": "40122010",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - USED PNEUMATIC TYRES : FOR BUSES, LORRIES AND EARTH MOVING EQUIPMENTS INCLUDING LIGHT COMMERCIAL VEHICLES"
    },
    {
      "code": "40122020",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - USED PNEUMATIC TYRES : FOR PASSENGER AUTOMOBILE VEHICLES, INCLUDING TWO WHEELERS, THREE WHEELERS AND PERSONAL TYPE VEHICLES"
    },
    {
      "code": "40122090",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - USED PNEUMATIC TYRES : OTHER"
    },
    {
      "code": "401290",
      "description": "Other"
    },
    {
      "code": "40129010",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : SOLID RUBBER TYRES FOR MOTOR VEHICLES"
    },
    {
      "code": "40129020",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : SOLID RUBBER TYRES FOR OTHER VEHICLES"
    },
    {
      "code": "40129030",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : TYRES WITH METAL FRAMEWORK"
    },
    {
      "code": "40129041",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : - TYRE FLAPS : OF A KIND USED IN TWO-WHEELED AND THREE-WHEELED MOTOR VEHICLES"
    },
    {
      "code": "40129049",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : - TYRE FLAPS : OTHER"
    },
    {
      "code": "40129050",
      "description": "T RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : YRE TREADS, INTERCHANGEABLE"
    },
    {
      "code": "40129090",
      "description": "RETREADED OR USED PNEUMATIC TYRES OF RUBBER, SOLID OR CUSHION TYRES, TYRE TREADS AND TYRE FLAPS, OF RUBBER - OTHER : OTHER"
    },
    {
      "code": "4013",
      "description": "INNER TUBES, OF RUBBER"
    },
    {
      "code": "401310",
      "description": "Of a kind used on motor cars (including station wagons and racing cars), buses or lorries"
    },
    {
      "code": "40131010",
      "description": "INNER TUBES, OF RUBBER - OF A KIND USED ON MOTOR CARS (INCLUDING STATION WAGONS AND RACING CARS), BUSES OR LORRIES : FOR MOTOR CARS"
    },
    {
      "code": "40131020",
      "description": "INNER TUBES, OF RUBBER - OF A KIND USED ON MOTOR CARS (INCLUDING STATION WAGONS AND RACING CARS), BUSES OR LORRIES : FOR LORRIES AND BUSES"
    },
    {
      "code": "40132000",
      "description": "INNER TUBES, OF RUBBER OF A KIND USED ON BICYCLES"
    },
    {
      "code": "401390",
      "description": "Other"
    },
    {
      "code": "40139010",
      "description": "INNER TUBES, OF RUBBER - OTHER : FOR AIRCRAFT"
    },
    {
      "code": "40139020",
      "description": "INNER TUBES, OF RUBBER - OTHER : FOR MOTOR CYCLE"
    },
    {
      "code": "40139030",
      "description": "INNER TUBES, OF RUBBER - OTHER : FOR OFF THE ROAD VEHICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "40139041",
      "description": "INNER TUBES, OF RUBBER - OTHER : - FOR TRACTORS : REAR TYRES"
    },
    {
      "code": "40139049",
      "description": "INNER TUBES, OF RUBBER - OTHER : - FOR TRACTORS : OTHER"
    },
    {
      "code": "40139050",
      "description": "INNER TUBES, OF RUBBER - OTHER : OF A KIND USED IN TYRES OF CYCLE RICKSHAWS AND THREE-WHEELED POWERED CYCLE-RICKSHAWS"
    },
    {
      "code": "40139090",
      "description": "INNER TUBES, OF RUBBER - OTHER : OTHER"
    },
    {
      "code": "4014",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER"
    },
    {
      "code": "401410",
      "description": "Sheath contraceptives"
    },
    {
      "code": "40141010",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - SHEATH CONTRACEPTIVES : RUBBER CONTRACEPTIVES, MALE (CONDOMS)"
    },
    {
      "code": "40141020",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - SHEATH CONTRACEPTIVES : RUBBER CONTRACEPTIVES, FEMALE (DIAPHRAGMS), SUCH AS CERVICAL CAPS"
    },
    {
      "code": "401490",
      "description": "Other"
    },
    {
      "code": "40149010",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - OTHER : HOT WATER BOTTLES"
    },
    {
      "code": "40149020",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - OTHER : ICE BAGS"
    },
    {
      "code": "40149030",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - OTHER : FEEDING BOTTLE NIPPLES"
    },
    {
      "code": "40149090",
      "description": "HYGIENIC OR PHARMACEUTICAL ARTICLES (INCLUDING TEATS), OF VULCANISED RUBBER OTHER THAN HARD RUBBER, WITH OR WITHOUT FITTINGS OF HARD RUBBER - OTHER : OTHER"
    },
    {
      "code": "4015",
      "description": "ARTICLES OF APPARELM AND CLOTHING ACCESSORIES (INCLUDING GLOVES , MITTENS AND MITTS ) FOR ALL PURPOSES , OF VULCANISED RUBBER OTHER THAN HARD RUBBER - Gloves, mittens and mitts:"
    },
    {
      "code": "40151100",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - GLOVES, MITTENS AND MITTS : SURGICAL"
    },
    {
      "code": "40151900",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - GLOVES, MITTENS AND MITTS : OTHER"
    },
    {
      "code": "401590",
      "description": "Other"
    },
    {
      "code": "40159010",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : RUBBER APRON"
    },
    {
      "code": "40159020",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : LABELS"
    },
    {
      "code": "40159030",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : INDUSTRIAL GLOVES"
    },
    {
      "code": "40159091",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : - OTHER : DIVING SUITS"
    },
    {
      "code": "40159099",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES (INCLUDING GLOVES, MITTENS AND MITTS) FOR ALL PURPOSES, OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : - OTHER : OTHER"
    },
    {
      "code": "4016",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER"
    },
    {
      "code": "40161000",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER OF CELLULAR RUBBER"
    },
    {
      "code": "40169100",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : FLOOR COVERINGS AND MATS"
    },
    {
      "code": "40169200",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : ERASERS"
    },
    {
      "code": "401693",
      "description": "Gaskets, washers and other seals"
    },
    {
      "code": "40169310",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : PATCHES FOR PUNCTURE REPAIR OF SELF-VULCANISING RUBBER OR A RUBBER BACKING"
    },
    {
      "code": "40169320",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : RUBBER RINGS (O-RING)"
    },
    {
      "code": "40169330",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : RUBBER SEALS (OIL SEALS AND THE LIKE)"
    },
    {
      "code": "40169340",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : GASKETS"
    },
    {
      "code": "40169350",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : WASHERS"
    },
    {
      "code": "40169360",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : PLUGS"
    },
    {
      "code": "40169390",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : GASKETS, WASHERS AND OTHER SEALS : OTHER"
    },
    {
      "code": "40169400",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : BOAT OR DOCK FENDERS, WHETHER OR NOT INFLATABLE"
    },
    {
      "code": "401695",
      "description": "Other inflatable articles"
    },
    {
      "code": "40169510",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER INFLATABLE ARTICLES : AIR MATTRESSES"
    },
    {
      "code": "40169590",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER INFLATABLE ARTICLES : OTHER"
    },
    {
      "code": "401699",
      "description": "Other"
    },
    {
      "code": "40169910",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER COTS FOR TEXTILE INDUSTRY"
    },
    {
      "code": "40169920",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER BANDS"
    },
    {
      "code": "40169930",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER THREADS"
    },
    {
      "code": "40169940",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER BLANKETS"
    },
    {
      "code": "40169950",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER CUSHIONS"
    },
    {
      "code": "40169960",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : RUBBER BUSHES"
    },
    {
      "code": "40169970",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : EAR PLUG"
    },
    {
      "code": "40169980",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : STOPPERS"
    },
    {
      "code": "40169990",
      "description": "OTHER ARTICLES OF VULCANISED RUBBER OTHER THAN HARD RUBBER - OTHER : OTHER : OTHER"
    },
    {
      "code": "4017",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS,INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER"
    },
    {
      "code": "401700",
      "description": "Hard rubber (for example, ebonite) in all forms, including waste and scrap; articles of hard rubber"
    },
    {
      "code": "40170010",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : PLATES, SHEETS, RODS AND TUBES OF EBONITE AND VULCANITE"
    },
    {
      "code": "40170020",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : SCRAP, WASTE AND POWDER OF HARDENED RUBBER (EBONITE AND VULCANITE)"
    },
    {
      "code": "40170030",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : PRINTERS ROLLERS"
    },
    {
      "code": "40170040",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : TEXTILE ROLLERS"
    },
    {
      "code": "40170050",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : TYPEWRITERS AND CYCLOSTYLING ROLLERS"
    },
    {
      "code": "40170090",
      "description": "HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER - HARD RUBBER (FOR EXAMPLE, EBONITE) IN ALL FORMS, INCLUDING WASTE AND SCRAP; ARTICLES OF HARD RUBBER : OTHER"
    },
    {
      "code": "4022",
      "description": "In powder, granules or other solid forms, of a fat content, by weight not exceeding 1.5%"
    },
    {
      "code": "4081",
      "description": "Egg yolks"
    },
    {
      "code": "4089",
      "description": "Other"
    },
    {
      "code": "41",
      "description": "Raw hides and skins (other than furskins) and leather"
    },
    {
      "code": "4101",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED , LIMED , PICKLED O R OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENTDRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT"
    },
    {
      "code": "410120",
      "description": "Whole hides and skins of a weight per skin not exceeding 8 kg. when simply dried, 10 kg. when dry-salted, or 16 kg. when fresh, wet-salted or otherwise preserved"
    },
    {
      "code": "41012010",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS OF A WEIGHT PER SKIN NOT EXCEEDING 8 KG WHEN SIMPLY DRIED, 10 KG WHEN DRY-SALTED, OR 16 KG WHEN FRESH, WET-SALTED OR OTHERWISE PRESERVED: OF COW, INCLUDING COW CALF"
    },
    {
      "code": "41012020",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS OF A WEIGHT PER SKIN NOT EXCEEDING 8 KG WHEN SIMPLY DRIED, 10 KG WHEN DRY-SALTED, OR 16 KG WHEN FRESH, WET-SALTED OR OTHERWISE PRESERVED: OF BUFFALO, INCLUDING BUFFALO CALF"
    },
    {
      "code": "41012090",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS OF A WEIGHT PER SKIN NOT EXCEEDING 8 KG WHEN SIMPLY DRIED, 10 KG WHEN DRY-SALTED, OR 16 KG WHEN FRESH, WET-SALTED OR OTHERWISE PRESERVED: OTHER"
    },
    {
      "code": "410150",
      "description": "Whole hides and skins, of a weight exceeding 16 kg."
    },
    {
      "code": "41015010",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS, OF A WEIGHT EXCEEDING 16 KG : OF COW, INCLUDING COW CALF"
    },
    {
      "code": "41015020",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS, OF A WEIGHT EXCEEDING 16 KG : OF BUFFALO, INCLUDING BUFFALO CALF"
    },
    {
      "code": "41015090",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - WHOLE HIDES AND SKINS, OF A WEIGHT EXCEEDING 16 KG : OTHER"
    },
    {
      "code": "410190",
      "description": "Other, including butts, bends and bellies"
    },
    {
      "code": "41019010",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - OTHER, INCLUDING BUTTS, BENDS AND BELLIES: OF COW, INCLUDING COW CALF"
    },
    {
      "code": "41019020",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - OTHER, INCLUDING BUTTS, BENDS AND BELLIES: OF BUFFALO, INCLUDING BUFFALO CALF"
    },
    {
      "code": "41019090",
      "description": "RAW HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS (FRESH OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT - OTHER, INCLUDING BUTTS, BENDS AND BELLIES: OTHER"
    },
    {
      "code": "4102",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENTDRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER"
    },
    {
      "code": "410210",
      "description": "With wool on"
    },
    {
      "code": "41021010",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITH WOOL ON: SHEEP SKINS"
    },
    {
      "code": "41021020",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITH WOOL ON: PELTS OF BABY LAMBS"
    },
    {
      "code": "41021030",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITH WOOL ON: LAMB SKINS, OTHER THAN PELTS"
    },
    {
      "code": "410221",
      "description": "Pickled"
    },
    {
      "code": "41022110",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITHOUT WOOL ON : - PICKLED : SHEEP SKINS"
    },
    {
      "code": "41022120",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITHOUT WOOL ON : - PICKLED : LAMB SKINS"
    },
    {
      "code": "41022130",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITHOUT WOOL ON : - PICKLED : LAMB PELT"
    },
    {
      "code": "410229",
      "description": "Other"
    },
    {
      "code": "41022910",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITHOUT WOOL ON : - OTHER : SHEEP SKINS"
    },
    {
      "code": "41022920",
      "description": "RAW SKINS OF SHEEP OR LAMBS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT WITH WOOL ON OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1 ( C ) TO THIS CHAPTER- WITHOUT WOOL ON : - OTHER : LAMB SKINS"
    },
    {
      "code": "4103",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENTDRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER"
    },
    {
      "code": "41031010",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF GOATS OR KIDS : GOAT SKINS, PICKLED (WET SALTED)"
    },
    {
      "code": "41031020",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF GOATS OR KIDS : GOAT SKINS, DRY SALTED"
    },
    {
      "code": "41031030",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF GOATS OR KIDS : GOAT SKINS, OTHERWISE CURED (DRIED, UNSALTED)"
    },
    {
      "code": "41031040",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF GOATS OR KIDS : KID SKINS"
    },
    {
      "code": "41031090",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF GOATS OR KIDS : OTHER"
    },
    {
      "code": "41032000",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF REPTILES"
    },
    {
      "code": "41033000",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OF SWINE"
    },
    {
      "code": "41039000",
      "description": "OTHER RAW HIDES AND SKINS (FRESH, OR SALTED, DRIED, LIMED, PICKLED OR OTHERWISE PRESERVED, BUT NOT TANNED, PARCHMENT-DRESSED OR FURTHER PREPARED), WHETHER OR NOT DEHAIRED OR SPLIT, OTHER THAN THOSE EXCLUDED BY NOTE 1(B) OR 1(C) TO THIS CHAPTER - OTHER"
    },
    {
      "code": "4104",
      "description": "TANNED OR CRUST HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED"
    },
    {
      "code": "41041100",
      "description": "TANNED OR CRUST HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE WET STATE (INCLUDING WET-BLUE): FULL GRAINS, UNSPLIT; GRAIN SPLITS"
    },
    {
      "code": "41041900",
      "description": "TANNED OR CRUST HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE WET STATE (INCLUDING WET-BLUE): OTHER"
    },
    {
      "code": "41044100",
      "description": "TANNED OR CRUST HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE DRY STATE (CRUST): FULL GRAINS, UNSPLIT; GRAIN SPLITS"
    },
    {
      "code": "41044900",
      "description": "TANNED OR CRUST HIDES AND SKINS OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE DRY STATE (CRUST): OTHER"
    },
    {
      "code": "4105",
      "description": "TANNED OR CRUST SKINS OF SHEEP OR LAMBS, WITHOUT WOOL ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED"
    },
    {
      "code": "41051000",
      "description": "TANNED OR CRUST SKINS OF SHEEP OR LAMBS, WITHOUT WOOL ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE WET STATE (INCLUDING WET-BLUE)"
    },
    {
      "code": "41053000",
      "description": "TANNED OR CRUST SKINS OF SHEEP OR LAMBS, WITHOUT WOOL ON, WHETHER OR NOT SPLIT, BUT NOT FURTHER PREPARED - IN THE DRY STATE (CRUST)"
    },
    {
      "code": "4106",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - Of goats or kids:"
    },
    {
      "code": "41062100",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OF GOATS OR KIDS: IN THE WET STATE (INCLUDING WET-BLUE)"
    },
    {
      "code": "41062200",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OF GOATS OR KIDS: IN THE DRY STATE (CRUST)"
    },
    {
      "code": "41063100",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OF SWINE: IN THE WET STATE (INCLUDING WET-BLUE)"
    },
    {
      "code": "41063200",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OF SWINE: IN THE DRY STATE (CRUST)"
    },
    {
      "code": "41064000",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OF REPTILES"
    },
    {
      "code": "41069100",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OTHER: IN THE WET STATE (INCLUDING WET-BLUE)"
    },
    {
      "code": "41069200",
      "description": "TANNED OR CRUST HIDES AND SKINS OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT BUT NOT FURTHER PREPARED - OTHER: IN THE DRY STATE (CRUST)"
    },
    {
      "code": "4107",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114- Whole hides and skins:"
    },
    {
      "code": "41071100",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - WHOLE HIDES AND SKINS: FULL GRAINS, UNSPLIT"
    },
    {
      "code": "41071200",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - WHOLE HIDES AND SKINS: GRAIN SPLITS"
    },
    {
      "code": "41071900",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - WHOLE HIDES AND SKINS: OTHER"
    },
    {
      "code": "41079100",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OTHER, INCLUDING SIDES: FULL GRAINS, UNSPLIT"
    },
    {
      "code": "41079200",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OTHER, INCLUDING SIDES: GRAIN SPLITS"
    },
    {
      "code": "41079900",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF BOVINE (INCLUDING BUFFALO) OR EQUINE ANIMALS, WITHOUT HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OTHER, INCLUDING SIDES: OTHER"
    },
    {
      "code": "4112",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF SHEEP OR LAMB, WITHOUT WOOL ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114"
    },
    {
      "code": "41120000",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF SHEEP OR LAMB, WITHOUT WOOL ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114"
    },
    {
      "code": "4113",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114"
    },
    {
      "code": "41131000",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OF GOATS OR KIDS"
    },
    {
      "code": "41132000",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OF SWINE"
    },
    {
      "code": "41133000",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OF REPTILES"
    },
    {
      "code": "41139000",
      "description": "LEATHER FURTHER PREPARED AFTER TANNING OR CRUSTING, INCLUDING PARCHMENT-DRESSED LEATHER, OF OTHER ANIMALS, WITHOUT WOOL OR HAIR ON, WHETHER OR NOT SPLIT, OTHER THAN LEATHER OF HEADING 4114 - OTHER"
    },
    {
      "code": "4114",
      "description": "CHAMOIS (INCLUDING COMBINATION CHAMOIS) LEATHER; PATENT LEATHER AND PATENT LAMINATED LEATHER ; METALLISED LEATHER"
    },
    {
      "code": "41141000",
      "description": "CHAMOIS (INCLUDING COMBINATION CHAMOIS) LEATHER; PATENT LEATHER AND PATENT LAMINATED LEATHER ; METALLISED LEATHER - CHAMOIS (INCLUDING COMBINATION CHAMOIS) LEATHER"
    },
    {
      "code": "411420",
      "description": "Patent leather and patent laminated leather; metallised leather"
    },
    {
      "code": "41142010",
      "description": "CHAMOIS (INCLUDING COMBINATION CHAMOIS) LEATHER; PATENT LEATHER AND PATENT LAMINATED LEATHER ; METALLISED LEATHER - PATENT LEATHER AND PATENT LAMINATED LEATHER; METALLISED LEATHER :PATENT LEATHER AND PATENT LAMINATED LEATHER"
    },
    {
      "code": "41142020",
      "description": "CHAMOIS (INCLUDING COMBINATION CHAMOIS) LEATHER; PATENT LEATHER AND PATENT LAMINATED LEATHER ; METALLISED LEATHER - PATENT LEATHER AND PATENT LAMINATED LEATHER; METALLISED LEATHER :METALLISED LEATHER"
    },
    {
      "code": "4115",
      "description": "COMPOSITION LEATHER WITH A BASIS OF LEATHER OR LEATHER FIBER, IN SLABS, SHEETS OR STRIP, WHETHER OR NOT IN ROLLS; PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES; LEATHER DUST, POWDER AND FLOUR"
    },
    {
      "code": "41151000",
      "description": "COMPOSITION LEATHER WITH A BASIS OF LEATHER OR LEATHER FIBER, IN SLABS, SHEETS OR STRIP, WHETHER OR NOT IN ROLLS; PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES; LEATHER DUST, POWDER AND FLOUR - COMPOSITION LEATHER WITH A BASIS OF LEATHER OR LEATHER FIBER, IN SLABS, SHEETS OR STRIP, WHETHER OR NOT IN ROLLS"
    },
    {
      "code": "411520",
      "description": "Parings and other waste of leather or of composition leather, not suitable for the manufacture of leather articles ; leather dust, powder and flour"
    },
    {
      "code": "41152010",
      "description": "COMPOSITION LEATHER WITH A BASIS OF LEATHER OR LEATHER FIBER, IN SLABS, SHEETS OR STRIP, WHETHER OR NOT IN ROLLS; PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES; LEATHER DUST, POWDER AND FLOUR - PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES ; LEATHER DUST, POWDER AND FLOUR: CUTTINGS OF LEATHER"
    },
    {
      "code": "41152090",
      "description": "COMPOSITION LEATHER WITH A BASIS OF LEATHER OR LEATHER FIBER, IN SLABS, SHEETS OR STRIP, WHETHER OR NOT IN ROLLS; PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES; LEATHER DUST, POWDER AND FLOUR - PARINGS AND OTHER WASTE OF LEATHER OR OF COMPOSITION LEATHER, NOT SUITABLE FOR THE MANUFACTURE OF LEATHER ARTICLES ; LEATHER DUST, POWDER AND FLOUR : OTHER"
    },
    {
      "code": "42",
      "description": "Articles of leather; saddlery and harness; travel goods, handbags and similar containers; articles of animal gut (other than silkworm gut)"
    },
    {
      "code": "4201",
      "description": "SADDLERY AND HARNESS FOR ANY ANIMAL (INCLUDING TRACES, LEADS, KNEE PADS, MUZZLES, SADDLE CLOTHS, SADDLE BAGS, DOG COATS AND THE LIKE), OF ANY MATERIAL"
    },
    {
      "code": "42010000",
      "description": "SADDLERY AND HARNESS FOR ANY ANIMAL (INCLUDING TRACES, LEADS, KNEE PADS, MUZZLES, SADDLE CLOTHS, SADDLE BAGS, DOG COATS AND THE LIKE), OF ANY MATERIAL"
    },
    {
      "code": "4202",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES , BRIEF - CASES , SCHOOL SATCHELS , SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS ; T R AV E L L I N G -BAGS , INSULATED FOOD O R BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAP - CASES , CIGARETTE- CASES , TOBACCO - POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLECASES, JEWELLERY BOXES , POWDER-BOXES , CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY O R MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - Trunks, suit-cases, vanity-cases, executivecases, brief-cases, school satchels and similar containers :"
    },
    {
      "code": "420211",
      "description": "With outer surface of leather, of composition leather or of patent leather"
    },
    {
      "code": "42021110",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : TRAVEL GOODS (TRUNKS, SUIT-CASES, SPORTS BAGS AND OTHER SIMILAR ITEMS ) OF LEATHER"
    },
    {
      "code": "42021120",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : TOILET-BAGS AND CASES, OF LEATHER"
    },
    {
      "code": "42021130",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : SATCHELS"
    },
    {
      "code": "42021140",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : BRIEF-CASES"
    },
    {
      "code": "42021150",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : EXECUTIVE-CASES"
    },
    {
      "code": "42021160",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : VANITY-CASES"
    },
    {
      "code": "42021170",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : ATTACHE-CASES"
    },
    {
      "code": "42021190",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : OTHER"
    },
    {
      "code": "420212",
      "description": "With outer surface of plastic or of textile materials"
    },
    {
      "code": "42021210",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : TOILET-CASES"
    },
    {
      "code": "42021220",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : PLASTIC MOULDED SUIT-CASES"
    },
    {
      "code": "42021230",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : PLASTIC MOULDED BRIEF-CASES"
    },
    {
      "code": "42021240",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : SATCHELS"
    },
    {
      "code": "42021250",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : OTHER TRAVEL-GOODS"
    },
    {
      "code": "42021260",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : BRIEF-CASES"
    },
    {
      "code": "42021270",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : EXECUTIVE-CASES OTHER THAN PLASTIC MOULDED"
    },
    {
      "code": "42021280",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : VANITY-CASES"
    },
    {
      "code": "42021290",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : WITH OUTER SURFACE OF PLASTIC OR OF TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "420219",
      "description": "Other"
    },
    {
      "code": "42021910",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : TRAVEL GOODS (TRUNKS, SUIT-CASES, SPORTS BAGS, AND OTHER SIMILAR ITEMS) OF LEATHER"
    },
    {
      "code": "42021920",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : TOILET-CASES"
    },
    {
      "code": "42021930",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : SATCHELS"
    },
    {
      "code": "42021940",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : BRIEF-CASES (OTHER THAN PLASTIC MOULDED)"
    },
    {
      "code": "42021950",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : EXECUTIVE-CASES"
    },
    {
      "code": "42021960",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : VANITY-CASES"
    },
    {
      "code": "42021990",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVE CASES, BRIEF-CASES, SCHOOL SATCHELS AND SIMILAR CONTAINERS : OTHER : OTHER"
    },
    {
      "code": "420221",
      "description": "With outer surface of leather, of composition leather or of patent leather"
    },
    {
      "code": "42022110",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : HAND-BAGS FOR LADIES"
    },
    {
      "code": "42022120",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : VANITY-BAGS"
    },
    {
      "code": "42022190",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : OTHER"
    },
    {
      "code": "420222",
      "description": "With outer surface of sheeting of plastics or of textile materials"
    },
    {
      "code": "42022210",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : HAND-BAGS AND SHOPPING BAGS, OF ARTIFICIAL PLASTIC MATERIAL"
    },
    {
      "code": "42022220",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : HAND-BAGS AND SHOPPING BAGS, OF COTTON"
    },
    {
      "code": "42022230",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : HAND-BAGS AND SHOPPING BAGS, OF JUTE"
    },
    {
      "code": "42022240",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : VANITY-BAGS"
    },
    {
      "code": "42022290",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "420229",
      "description": "Other"
    },
    {
      "code": "42022910",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : OTHER HAND BAGS OF OTHER MATERIALS EXCLUDING WICKER-WORK OR BASKET WORK"
    },
    {
      "code": "42022990",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - HAND-BAGS, WHETHER OR NOT WITH SHOULDER STRAP, INCLUDING THOSE WITHOUT HANDLE : OTHER OTHER"
    },
    {
      "code": "420231",
      "description": "With outer surface of leather, of composition leather or of patent leather"
    },
    {
      "code": "42023110",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : JEWELLERY BOX"
    },
    {
      "code": "42023120",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : WALLETS AND PURSES, OF LEATHER"
    },
    {
      "code": "42023190",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER : OTHER"
    },
    {
      "code": "420232",
      "description": "With outer surface of sheeting of plastics or of textile materials"
    },
    {
      "code": "42023210",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : JEWELLERY BOX"
    },
    {
      "code": "42023290",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "420239",
      "description": "Other"
    },
    {
      "code": "42023910",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : OTHER : JEWELLERY BOX"
    },
    {
      "code": "42023990",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - ARTICLES OF A KIND NORMALLY CARRIED IN THE POCKET OR IN THE HANDBAG : OTHER : OTHER"
    },
    {
      "code": "420291",
      "description": "With outer surfce of leather or of composition leather"
    },
    {
      "code": "42029100",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - OTHER : WITH OUTER SURFACE OF LEATHER, OF COMPOSITION LEATHER OR OF PATENT LEATHER"
    },
    {
      "code": "42029200",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - OTHER : WITH OUTER SURFACE OF PLASTIC SHEETING OR OF TEXTILE MATERIALS"
    },
    {
      "code": "42029900",
      "description": "TRUNKS, SUIT-CASES, VANITY-CASES, EXECUTIVECASES, BRIEF-CASES, SCHOOL SATCHELS, SPECTACLE CASES, BINOCULAR CASES, CAMERA CASES, MUSICAL INSTRUMENT CASES, GUN CASES, HOLSTERS AND SIMILAR CONTAINERS; TRAVELLING-BAGS, INSULATED FOOD OR BEVERAGES BAGS, TOILET BAGS, RUCKSACKS, HANDBAGS, SHOPPING-BAGS, WALLETS, PURSES, MAPCASES, CIGARETTE-CASES, TOBACCO- POUCHES, TOOL BAGS, SPORTS BAGS, BOTTLE-CASES, JEWELLERY BOXES, POWDER-BOXES, CUTLERY CASES AND SIMILAR CONTAINERS, OF LEATHER OR OF COMPOSITION LEATHER, OF SHEETING OF PLASTICS, OF TEXTILE MATERIALS, OF VULCANISED FIBRE OR OF PAPERBOARD, OR WHOLLY OR MAINLY COVERED WITH SUCH MATERIALS OR WITH PAPER - OTHER : OTHER"
    },
    {
      "code": "4203",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER"
    },
    {
      "code": "420310",
      "description": "Articles of apparel"
    },
    {
      "code": "42031010",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - ARTICLES OF APPAREL : JACKETS AND JERSEYS"
    },
    {
      "code": "42031090",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - ARTICLES OF APPAREL : OTHER"
    },
    {
      "code": "420321",
      "description": "Specially designed for use in sports"
    },
    {
      "code": "42032110",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - GLOVES, MITTENS AND MITTS : SPECIALLY DESIGNED FOR USE IN SPORTS : GLOVES"
    },
    {
      "code": "42032120",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - GLOVES, MITTENS AND MITTS : SPECIALLY DESIGNED FOR USE IN SPORTS : MITTENS AND MITTS"
    },
    {
      "code": "420329",
      "description": "Other"
    },
    {
      "code": "42032910",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - GLOVES, MITTENS AND MITTS : OTHER : GLOVES FOR USE IN INDUSTRY"
    },
    {
      "code": "42032920",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - GLOVES, MITTENS AND MITTS : OTHER : OTHER GLOVES"
    },
    {
      "code": "42032930",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - GLOVES, MITTENS AND MITTS : OTHER : MITTENS AND MITTS"
    },
    {
      "code": "42033000",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - BELTS AND BANDOLIERS"
    },
    {
      "code": "420340",
      "description": "Other clothing accessories"
    },
    {
      "code": "42034010",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - OTHER CLOTHING ACCESSORIES : APRONS"
    },
    {
      "code": "42034020",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - OTHER CLOTHING ACCESSORIES : SEMI-CHROME GRAIN GARMENTS"
    },
    {
      "code": "42034090",
      "description": "ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF LEATHER OR OF COMPOSITION LEATHER - OTHER CLOTHING ACCESSORIES : OTHER"
    },
    {
      "code": "4204",
      "description": "OMITTED"
    },
    {
      "code": "42040010",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : PICKERS FOR COTTON MACHINERY"
    },
    {
      "code": "42040020",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : PICKERS FOR JUTE MACHINERY"
    },
    {
      "code": "42040030",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : PICKING BANDS AND STRAPS FOR TEXTILE MACHINERY"
    },
    {
      "code": "42040040",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : LEATHER BELTING FOR MACHINERY"
    },
    {
      "code": "42040050",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : DIAPHRAGM LEATHER"
    },
    {
      "code": "42040060",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : LEATHER STRING"
    },
    {
      "code": "42040091",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : OTHER : LEATHER BOARD MADE FROM LEATHER SCRAP FOR THE MANUFACTURE OF COUNTERS AND STIFFNERS"
    },
    {
      "code": "42040099",
      "description": "ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES - ARTICLES OF LEATHER OR OF COMPOSITION LEATHER, OF A KIND USED IN MACHINERY OR MECHANICAL APPLIANCES OR FOR OTHER TECHNICAL USES : OTHER : OTHER"
    },
    {
      "code": "4205",
      "description": "OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER"
    },
    {
      "code": "420500",
      "description": "Other articles of leather or of composition leather"
    },
    {
      "code": "42050011",
      "description": "OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER - OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER : STRAPS OTHER THAN FOR MACHINERY OR HARNESS : WEL T"
    },
    {
      "code": "42050019",
      "description": "OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER - OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER : STRAPS OTHER THAN FOR MACHINERY OR HARNESS : OTHER"
    },
    {
      "code": "42050020",
      "description": "OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER - OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER : LEATHER SOFA COVER"
    },
    {
      "code": "42050090",
      "description": "OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER - OTHER ARTICLES OF LEATHER OR OF COMPOSITION LEATHER : OTHER"
    },
    {
      "code": "4206",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATERS SKIN, OF BLADDERS OR OF TENDONS"
    },
    {
      "code": "42060010",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATER`S SKIN, OF BLADDERS OR OF TENDONS --- FOR RACKETS"
    },
    {
      "code": "42060090",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATEPS SKIN, OF BLADDERS OR OF TENDONS --- OTHER"
    },
    {
      "code": "42061010",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATEQS SKIN, OF BLADDERS OR OF TENDONS - CATGUT : FOR RACKETS"
    },
    {
      "code": "42061090",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATEQS SKIN, OF BLADDERS OR OF TENDONS - CATGUT : OTHER"
    },
    {
      "code": "42069000",
      "description": "ARTICLES OF GUT (OTHER THAN SILK-WORM GUT), OF GOLDBEATEQS SKIN, OF BLADDERS OR OF TENDONS - OTHER"
    },
    {
      "code": "43",
      "description": "Furskins and artificial fur; manufactures thereof"
    },
    {
      "code": "4301",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS ANDOTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103"
    },
    {
      "code": "43011000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - OF MINK, WHOLE, WITH OR WITHOUT HEAD, TAIL OR PAWS"
    },
    {
      "code": "43013000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - OF LAMB, THE FOLLOWING : ASTRAKHAN, BROADTAIL, CARACUL, PERSIAN AND SIMILAR LAMB, INDIAN, CHINESE, MONGOLIAN OR TIBETAN LAMB, WHOLE, WITH OR WITHOUT HEAD, TAIL OR PAWS"
    },
    {
      "code": "43016000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - OF FOX, WHOLE, WITH OR WITHOUT HEAD, TAIL OR PAWS"
    },
    {
      "code": "43017000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - OF SEAL, WHOLE, WITH OR WITHOUT HEAD, TAIL OR PAWS"
    },
    {
      "code": "43018000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - OTHER FURSKINS, WHOLE, WITH OR WITHOUT HEAD, TAIL OR PAWS"
    },
    {
      "code": "43019000",
      "description": "RAW FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE), OTHER THAN RAW HIDES AND SKINS OF HEADING 4101, 4102 OR 4103 - HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, SUITABLE FOR FURRIERS USE"
    },
    {
      "code": "4302",
      "description": "TANNED OR DRESSED FuRSKINS (INCLuDING HEADS, TAILS, PAWS AND OTHER PIECES OR CuTTINGS), uNASSEMBLED, OR ASSEMBLED (WITHOuT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303- Whole skins, with or without head, tail or paws, not assembled:"
    },
    {
      "code": "43021100",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:OF MINK"
    },
    {
      "code": "43021300",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:OF LAMB, THE FOLLOWING : ASTRAKHAN, BROAD TAIL, CARACUL, PERSIAN AND SIMILAR LAMB, INDIAN, CHINESE, MONGOLIAN OR TIBETAN LAMB"
    },
    {
      "code": "430219",
      "description": "Other"
    },
    {
      "code": "43021910",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:- OTHER - CALF SKINS, WITH HAIR ON, TANNED OR DRESSED"
    },
    {
      "code": "43021920",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:- OTHER - HIDES OR SKINS OF OTHER BOVINE AND EQUINE ANIMALS WITH HAIR ON, TANNED OR DRESSED"
    },
    {
      "code": "43021930",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:- OTHER - GOAT (COMMON) AND KID SKINS WITH HAIR ON, TANNED OR DRESSED"
    },
    {
      "code": "43021940",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:- OTHER - TIGER-CAT SKINS"
    },
    {
      "code": "43021990",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 - WHOLE SKINS, WITH OR WITHOUT HEAD, TAIL OR PAWS, NOT ASSEMBLED:- OTHER - HIDES AND SKINS OF OTHER ANIMALS WITH HAIR ON, TANNED OR DRESSED"
    },
    {
      "code": "43022000",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS, NOT ASSEMBLED"
    },
    {
      "code": "43023000",
      "description": "TANNED OR DRESSED FURSKINS (INCLUDING HEADS, TAILS, PAWS AND OTHER PIECES OR CUTTINGS), UNASSEMBLED, OR ASSEMBLED (WITHOUT THE ADDITION OF OTHER MATERIALS) OTHER THAN THOSE OF HEADING 4303 WHOLE SKINS AND PIECES OR CUTTINGS THEREOF, ASSEMBLED"
    },
    {
      "code": "4303",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FuRSKIN"
    },
    {
      "code": "430310",
      "description": "Articles of apparel and clothing accessories"
    },
    {
      "code": "43031010",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - ARTICLES OF APPAREL AND CLOTHING ACCESSORIES: OF WILD ANIMALS COVERED UNDER THE WILD LIFE (PROTECTION) ACT, 1972"
    },
    {
      "code": "43031020",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - ARTICLES OF APPAREL AND CLOTHING ACCESSORIES: OF ANIMALS COVERED UNDER CONVENTION ON INTERNATIONAL TRADE OF ENDANGERED SPECIES (CITES), OTHER THAN THOSE OF TARIFF ITEM 4303 10 10"
    },
    {
      "code": "43031090",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - ARTICLES OF APPAREL AND CLOTHING ACCESSORIES: OTHER"
    },
    {
      "code": "430390",
      "description": "Other"
    },
    {
      "code": "43039010",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - OTHER: OF WILD ANIMALS COVERED UNDER THE WILD LIFE (PROTECTION) ACT, 1972"
    },
    {
      "code": "43039020",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - OTHER: OF ANIMALS COVERED UNDER CONVENTION ON INTERNATIONAL TRADE OF ENDANGERED SPECIES (CITES), OTHER THAN THOSE OF TARIFF ITEM 4303 90 10"
    },
    {
      "code": "43039090",
      "description": "ARTICLES OF APPAREL, CLOTHING ACCESSORIES AND OTHER ARTICLES OF FURSKIN - OTHER: OTHER"
    },
    {
      "code": "4304",
      "description": "ARTIFICIAL FuR AND ARTICLES THEREOF"
    },
    {
      "code": "430400",
      "description": "Artificial fur and articles thereof"
    },
    {
      "code": "43040011",
      "description": "ARTIFICIAL FUR AND ARTICLES THEREOF 4304 00 - ARTIFICIAL FUR AND ARTICLES THEREOF: ARTIFICIAL FUR : ARTIFICIAL FUR AS TRIMMINGS AND EMBELLISHMENTS FOR GARMENTS, MADE UPS, KNITWEAR, PLASTIC AND LEATHER GOODS"
    },
    {
      "code": "43040019",
      "description": "ARTIFICIAL FUR AND ARTICLES THEREOF 4304 00 - ARTIFICIAL FUR AND ARTICLES THEREOF: ARTIFICIAL FUR :OTHER"
    },
    {
      "code": "43040020",
      "description": "ARTIFICIAL FUR AND ARTICLES THEREOF 4304 00 - ARTIFICIAL FUR AND ARTICLES THEREOF: ARTICLES OF ARTIFICIAL FUR"
    },
    {
      "code": "44",
      "description": "Wood and articles of wood; wood charcoal"
    },
    {
      "code": "4401",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS"
    },
    {
      "code": "440110",
      "description": "Fuel wood, in logs, in billets, in twigs, in faggots or in similar forms"
    },
    {
      "code": "44011010",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS- FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS : IN LOGS"
    },
    {
      "code": "44011090",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS- FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS : OTHER"
    },
    {
      "code": "440111",
      "description": "Coniferous"
    },
    {
      "code": "44011110",
      "description": "In logs"
    },
    {
      "code": "44011190",
      "description": "Other"
    },
    {
      "code": "440112",
      "description": "Non-Coniferous"
    },
    {
      "code": "44011210",
      "description": "In logs"
    },
    {
      "code": "44011290",
      "description": "Other"
    },
    {
      "code": "44012100",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS - WOOD IN CHIPS OR PARTICLES : CONIFEROUS"
    },
    {
      "code": "44012200",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS - WOOD IN CHIPS OR PARTICLES : NON-CONIFEROUS"
    },
    {
      "code": "440131",
      "description": "wood pellets"
    },
    {
      "code": "44013100",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS - SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS--WOOD PELLETS"
    },
    {
      "code": "440139",
      "description": "Other"
    },
    {
      "code": "44013900",
      "description": "FUEL WOOD, IN LOGS, IN BILLETS, IN TWIGS, IN FAGGOTS OR IN SIMILAR FORMS; WOOD IN CHIPS OR PARTICLES; SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS - SAWDUST AND WOOD WASTE AND SCRAP, WHETHER OR NOT AGGLOMERATED IN LOGS, BRIQUETTES, PELLETS OR SIMILAR FORMS--OTHER"
    },
    {
      "code": "44014000",
      "description": "Sawdust and wood waste and scrap, not agglomerated"
    },
    {
      "code": "4402",
      "description": "WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED"
    },
    {
      "code": "44020010",
      "description": "WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED - WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED: OF COCONUT SHELL"
    },
    {
      "code": "44020090",
      "description": "WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED - WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED: OTHER"
    },
    {
      "code": "440210",
      "description": "Wood charcoal (including shell or nut charcoal), whether or not agglomerated"
    },
    {
      "code": "44021000",
      "description": "Of bamboo"
    },
    {
      "code": "44021010",
      "description": "WHETHER OR NOT AGGLOMERATED - WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL)WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED ---OF BAMBOO"
    },
    {
      "code": "440290",
      "description": "Other"
    },
    {
      "code": "44029010",
      "description": "WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED - OTHER : --- OF COCUNUT SHELL"
    },
    {
      "code": "44029090",
      "description": "WOOD CHARCOAL (INCLUDING SHELL OR NUT CHARCOAL), WHETHER OR NOT AGGLOMERATED: OTHER --- OTHER"
    },
    {
      "code": "4403",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED"
    },
    {
      "code": "44031000",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - TREATED WITH PAINT, STAINS, CREOSOTE OR OTHER PRESERVATIVES"
    },
    {
      "code": "44031100",
      "description": "Coniferous"
    },
    {
      "code": "44031200",
      "description": "Non-Coniferous"
    },
    {
      "code": "440320",
      "description": "Other, coniferous"
    },
    {
      "code": "44032010",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, CONIFEROUS : SAWLOGS AND VENEERLOGS"
    },
    {
      "code": "44032020",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, CONIFEROUS : POLES, PILLING AND POSTS"
    },
    {
      "code": "44032090",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, CONIFEROUS : OTHER"
    },
    {
      "code": "440321",
      "description": "Of pine (Pinus spp.), of which any cross-sectional dimension is 15 cm or more"
    },
    {
      "code": "44032110",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032120",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032190",
      "description": "other"
    },
    {
      "code": "44032210",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032220",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032290",
      "description": "Other"
    },
    {
      "code": "440323",
      "description": "Of fir (Abies spp.) and spruce (Picea spp.), of which any cross-sectional dimension is 15 cm or more"
    },
    {
      "code": "44032310",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032320",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032390",
      "description": "Other"
    },
    {
      "code": "440324",
      "description": "Of fir (Abies spp.) and spruce (Picea spp.), other"
    },
    {
      "code": "44032410",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032420",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032490",
      "description": "Other"
    },
    {
      "code": "440325",
      "description": "Other, of which any cross-sectional dimension is 15 cm or more"
    },
    {
      "code": "44032510",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032520",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032590",
      "description": "Other"
    },
    {
      "code": "440326",
      "description": "Other"
    },
    {
      "code": "44032610",
      "description": "Saw logs and veneer logs"
    },
    {
      "code": "44032620",
      "description": "Poles, pilling and posts"
    },
    {
      "code": "44032690",
      "description": "Other"
    },
    {
      "code": "44034100",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, OF TROPICAL WOOD SPECIFIED IN SUBHEADING NOTE 1 TO THIS CHAPTER : DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU"
    },
    {
      "code": "440349",
      "description": "Other"
    },
    {
      "code": "44034910",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, OF TROPICAL WOOD SPECIFIED IN SUBHEADING NOTE 1 TO THIS CHAPTER : OTHER :TEAK WOOD IN ROUGH"
    },
    {
      "code": "44034990",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER, OF TROPICAL WOOD SPECIFIED IN SUBHEADING NOTE 1 TO THIS CHAPTER : OTHER :OTHER"
    },
    {
      "code": "44039100",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OF OAK (QUERCUS SPP.)"
    },
    {
      "code": "44039200",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OF BEECH (FAGUS SPP.)"
    },
    {
      "code": "44039300",
      "description": "Of beech (Fagus spp.), of which any cross-sectional dimension is 15 cm or more"
    },
    {
      "code": "44039400",
      "description": "Of beech (Fagus spp.), other"
    },
    {
      "code": "44039500",
      "description": "Of birch (Betula spp.), of which any cross-sectional dimension is 15 cm or more"
    },
    {
      "code": "44039600",
      "description": "Of birch (Betula spp.), other"
    },
    {
      "code": "44039700",
      "description": "Of poplar and aspen (Populus spp.)"
    },
    {
      "code": "44039800",
      "description": "Of eucalyptus (Eucalyptus spp.)"
    },
    {
      "code": "440399",
      "description": "Other"
    },
    {
      "code": "44039911",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES)"
    },
    {
      "code": "44039912",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): BONSUM (PHOEBE GOALPARENSIS)"
    },
    {
      "code": "44039913",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): GURGAN (DIPTEROCARPUS ALATUS)"
    },
    {
      "code": "44039914",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): KHAIR (ACACIA CATECHU)"
    },
    {
      "code": "44039915",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): LAMPATI (DUABANGA GRANDIFLORA)"
    },
    {
      "code": "44039916",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): LAUREL (TERMINALIA ALATA)"
    },
    {
      "code": "44039917",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): PALIWOOD (PALAQUIUM ELLIPLICUM)"
    },
    {
      "code": "44039918",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): RED SANDERS(PTEROCAR PUS SAUTATINUS)"
    },
    {
      "code": "44039919",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : ANDAMAN PADAUK (PTEROCAROUS DALBAERGIODES) BONSUM (PHOEBE GOALPARENSIS) GURGAN (DIPTEROCARPUS ALATUS) KHAIR (ACACIA CATECHU) LAMPATI (DUABANGA GRANDIFLORA) LAUREL (TERMINALIA ALATA) PALIWOOD (PALAQUIUM ELLIPLICUM) AND RED SANDERS (PTEROCAR PUS SAUTANINUS) AND ROSE WOOD (DALBERGEA LATIFOLIO): ROSE WOOD (DALBERGEA LATIFOLIO)"
    },
    {
      "code": "44039921",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: SAL (CHOREA ROBUSTA)"
    },
    {
      "code": "44039922",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: SANDAL WOOD (SANTALUM ALBURN)"
    },
    {
      "code": "44039923",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: SEMUL (BOMBAX CEIBA)"
    },
    {
      "code": "44039924",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: WALNUT WOOD (JUGLANS BINATA)"
    },
    {
      "code": "44039925",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: ANJAM (HARDWICKIA BINATA)"
    },
    {
      "code": "44039926",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: BIRCH (BETULA SPP.)"
    },
    {
      "code": "44039927",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: SISSOO (DALBERGIA SISSO)"
    },
    {
      "code": "44039928",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: WHITE CEDAR (DYSOZYLUM MALABARICUM)"
    },
    {
      "code": "44039929",
      "description": "WOOD IN THE ROUGH, WHETHER OR NOT STRIPPED OF BARK OR SAPWOOD, OR ROUGHLY SQUARED - OTHER : OTHER : SAL (CHOREA ROBUSTA) SANDAL WOOD (SANTALUM ALBUR) SEMUL (BOMBAX CEIBA) WALNUT WOOD (JUGLANS BINATA) ANJAM (HARDWICKIA BINATA) BIRCH (BETULA SPP.) SISSOO (DALBERGIA SISSO) AND WHITE CEDAR (DYSOZYLUM) AND THE LIKE: OTHER"
    },
    {
      "code": "44039990",
      "description": "Other"
    },
    {
      "code": "4404",
      "description": "HOOPWOOD; SPLIT POLES; PILES, PICKETS AND STAKES OF WOOD, POINTED BUT NOT SAWN LENGTHWISE; WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, UMBRELLAS, TOOL HANDLES OR THE LIKE; CHIPWOOD AND THE LIKE"
    },
    {
      "code": "44041000",
      "description": "HOOPWOOD; SPLIT POLES; PILES, PICKETS AND STAKES OF WOOD, POINTED BUT NOT SAWN LENGTHWISE; WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, UMBRELLAS, TOOL HANDLES OR THE LIKE; CHIPWOOD AND THE LIKE - CONIFEROUS"
    },
    {
      "code": "440420",
      "description": "Non-coniferous"
    },
    {
      "code": "44042010",
      "description": "HOOPWOOD; SPLIT POLES; PILES, PICKETS AND STAKES OF WOOD, POINTED BUT NOT SAWN LENGTHWISE; WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, UMBRELLAS, TOOL HANDLES OR THE LIKE; CHIPWOOD AND THE LIKE - NON-CONIFEROUS : WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, TOOL HANDLES, SPLIT POLES, PILES, PICKETS, STAKES AND THE LIKE"
    },
    {
      "code": "44042020",
      "description": "HOOPWOOD; SPLIT POLES; PILES, PICKETS AND STAKES OF WOOD, POINTED BUT NOT SAWN LENGTHWISE; WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, UMBRELLAS, TOOL HANDLES OR THE LIKE; CHIPWOOD AND THE LIKE - NON-CONIFEROUS : DRAWN WOOD"
    },
    {
      "code": "44042090",
      "description": "HOOPWOOD; SPLIT POLES; PILES, PICKETS AND STAKES OF WOOD, POINTED BUT NOT SAWN LENGTHWISE; WOODEN STICKS, ROUGHLY TRIMMED BUT NOT TURNED, BENT OR OTHERWISE WORKED, SUITABLE FOR THE MANUFACTURE OF WALKING STICKS, UMBRELLAS, TOOL HANDLES OR THE LIKE; CHIPWOOD AND THE LIKE - NON-CONIFEROUS : OTHER"
    },
    {
      "code": "4405",
      "description": "WOOD WOOL; WOOD FLOUR"
    },
    {
      "code": "44050000",
      "description": "WOOD WOOL; WOOD FLOUR"
    },
    {
      "code": "4406",
      "description": "RAILWAY OR TRAMWAY SLEEPERS (CROSSTIES)"
    },
    {
      "code": "44061000",
      "description": "RAILWAY OR TRAMWAY SLEEPERS (CROSSTIES) OF WOOD - NOT IMPREGNATED"
    },
    {
      "code": "44061100",
      "description": "Coniferous"
    },
    {
      "code": "44061200",
      "description": "Non-coniferous"
    },
    {
      "code": "44069000",
      "description": "RAILWAY OR TRAMWAY SLEEPERS (CROSSTIES) OF WOOD - OTHER"
    },
    {
      "code": "44069100",
      "description": "Coniferous"
    },
    {
      "code": "44069200",
      "description": "Non-coniferous"
    },
    {
      "code": "4407",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR END-JOINTED, OF A THICKNESS EXCEEDING 6 MM"
    },
    {
      "code": "440710",
      "description": "Coniferrous"
    },
    {
      "code": "44071010",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - CONIFERROUS: DOGLAS FIR (PSCUDOTSUGA MENZIESIE)"
    },
    {
      "code": "44071020",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - CONIFERROUS: PINE (PINUS SPP.)"
    },
    {
      "code": "44071090",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - CONIFERROUS: OTHER"
    },
    {
      "code": "44071100",
      "description": "Of pine (Pinus spp.)"
    },
    {
      "code": "44071200",
      "description": "Of fir (Abies spp.) and Spruce ( Picea spp.)"
    },
    {
      "code": "440719",
      "description": "Other"
    },
    {
      "code": "44071910",
      "description": "Douglas fir (Pseudotsuga menziesii)"
    },
    {
      "code": "44071990",
      "description": "Other"
    },
    {
      "code": "44072100",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : MAHOGANY (SWIETENIA SPP.)"
    },
    {
      "code": "44072200",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : VIROLA,IMBUIA AND BALSA"
    },
    {
      "code": "44072400",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : VIROLA, MAHOGANY (SWIETENIA SPP.) IMBUIA AND BALSA"
    },
    {
      "code": "44072500",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU"
    },
    {
      "code": "44072600",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI AND ALAN"
    },
    {
      "code": "44072700",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : --SAPELLI"
    },
    {
      "code": "44072800",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : IROKO"
    },
    {
      "code": "440729",
      "description": "Other"
    },
    {
      "code": "44072910",
      "description": "29 WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER : TEAK WOOD"
    },
    {
      "code": "44072990",
      "description": "29 WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER : OTHER"
    },
    {
      "code": "44079100",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OF OAK (QUERCUS SPP.)"
    },
    {
      "code": "44079200",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OF BEECH (FAGUS SPP.)"
    },
    {
      "code": "44079300",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OF MAPLE (ACER SPP)"
    },
    {
      "code": "44079400",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : --OF CHERRY (PRUNUS SPP.)"
    },
    {
      "code": "44079500",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : -- OF ASH(FRAXINUS SPP.)"
    },
    {
      "code": "44079600",
      "description": "Of birch (Betula spp.)"
    },
    {
      "code": "44079700",
      "description": "Of poplar and aspen (Populus spp.)"
    },
    {
      "code": "440799",
      "description": "Other"
    },
    {
      "code": "44079910",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OTHER : OF BIRCH (BETULA SPP.)"
    },
    {
      "code": "44079920",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OTHER : WILLOW"
    },
    {
      "code": "44079990",
      "description": "WOOD SAWN OR CHIPPED LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED OR ENDJOINTED, OF A THICKNESS EXCEEDING 6 MM - OTHER : OTHER : OTHER"
    },
    {
      "code": "4408",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM"
    },
    {
      "code": "440810",
      "description": "Coniferous"
    },
    {
      "code": "44081010",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- CONIFEROUS: SHEETS FOR PLYWOOD"
    },
    {
      "code": "44081020",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- CONIFEROUS: OAK WOOD VENEER"
    },
    {
      "code": "44081030",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- CONIFEROUS: VENEER SHEETS, FOR MATCH BOXES AND MATCH SPLINTS"
    },
    {
      "code": "44081090",
      "description": "HEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- CONIFEROUS: OTHER"
    },
    {
      "code": "440831",
      "description": "Of Dark Red Meranti, Light Red Meranti and Meranti Bakau"
    },
    {
      "code": "44083110",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OF DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU: SHEETS FOR PLYWOOD"
    },
    {
      "code": "44083120",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OF DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU: VENEER SHEETS (OF ROSE WOOD)"
    },
    {
      "code": "44083130",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OF DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU: VENEER SHEETS, FOR MATCH BOXES AND MATCH SPLINTS"
    },
    {
      "code": "44083190",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OF DARK RED MERANTI, LIGHT RED MERANTI AND MERANTI BAKAU: OTHER"
    },
    {
      "code": "440839",
      "description": "Other"
    },
    {
      "code": "44083910",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER: SHEETS FOR PLYWOODS"
    },
    {
      "code": "44083920",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER: VENEER SHEETS (OF ROSE WOOD)"
    },
    {
      "code": "44083930",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM - OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER: VENEER SHEETS, FOR MATCH BOXES AND MATCH SPLINTS"
    },
    {
      "code": "44083990",
      "description": "HEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM --OTHER ---OTHER"
    },
    {
      "code": "440890",
      "description": "Other"
    },
    {
      "code": "44089010",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- OTHER: SHEETS FOR PLYWOODS"
    },
    {
      "code": "44089020",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- OTHER: VENEER SHEETS, FOR MATCH BOXES AND MATCH SPLINTS"
    },
    {
      "code": "44089090",
      "description": "SHEETS FOR VENEERING (INCLUDING THOSE OBTAINED BY SLICING LAMINATED WOOD), FOR PLYWOOD OR FOR SIMILAR LAMINATED WOOD AND OTHER WOOD, SAWN LENGTHWISE, SLICED OR PEELED, WHETHER OR NOT PLANED, SANDED, SPLICED OR END-JOINTED, OF A THICKNESS NOT EXCEEDING 6 MM- OTHER: OTHER"
    },
    {
      "code": "4409",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED"
    },
    {
      "code": "440910",
      "description": "Coniferous"
    },
    {
      "code": "44091010",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - CONIFEROUS: PLANED, TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, AND THE LIKE BUT NOT FURTHER MOULDED"
    },
    {
      "code": "44091020",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - CONIFEROUS: BEADINGS, AND MOULDINGS (INCLUDING MOULDED, SKIRTING AND OTHER MOULDED BOARDS)"
    },
    {
      "code": "44091090",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - CONIFEROUS: OTHER"
    },
    {
      "code": "44092010",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - NON-CONIFEROUS:PLANED, TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, AND THE LIKE BUT NOT FURTHER MOULDED"
    },
    {
      "code": "44092020",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - NON-CONIFEROUS:BEADINGS AND MOULDINGS (INCLUDING MOULDED SKIRTING AND OTHER MOULDED BOARDS)"
    },
    {
      "code": "44092090",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - NON-CONIFEROUS:OTHER"
    },
    {
      "code": "44092100",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - NON-CONIFEROUS:--OF BAMBOO"
    },
    {
      "code": "44092200",
      "description": "Of tropical wood"
    },
    {
      "code": "440929",
      "description": "Other"
    },
    {
      "code": "44092910",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - NON-CONIFEROUS:--OTHER ---PLANED, TONGUED, GROOVED,REBATED, CHAMFERED,V-JOINTED,AND THE LIKE BUT NOT FURTHER MOULDED"
    },
    {
      "code": "44092920",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED - OTHER: ---BEADINGS AND MOULDINGS (INCLUDING MOULDED SKIRTING AND OTHER MOULDED BOARDS)"
    },
    {
      "code": "44092990",
      "description": "WOOD (INCLUDING STRIPS AND FRIEZES FOR PARQUET FLOORING, NOT ASSEMBLED) CONTINUOUSLY SHAPED (TONGUED, GROOVED, REBATED, CHAMFERED, V-JOINTED, BEADED, MOULDED, ROUNDED OR THE LIKE) ALONG ANY OF ITS EDGES OR FACES, WHETHER OR NOT PLANED, SANDED OR END-JOINTED -- OTHER ---OTHER"
    },
    {
      "code": "4410",
      "description": "PARTICLE BOARD, ORIENTED STRAND BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - Of wood:"
    },
    {
      "code": "441011",
      "description": "Particle board"
    },
    {
      "code": "44101110",
      "description": "PARTICLE BOARD, ORIENTED STRAND BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OF WOOD:-- PARTICLE BOARD:PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44101120",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:  -- PARTICLE BOARD:--- INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44101130",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:  -- PARTICLE BOARD:--- VENEERED PARTICLE BOARD"
    },
    {
      "code": "44101190",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:  -- PARTICLE BOARD:--- OTHERS"
    },
    {
      "code": "441012",
      "description": "Oriented strand board (OSB)"
    },
    {
      "code": "44101210",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:  ORIENTED STRAND BOARD (OSB):--- UNWORKED OR NOT FURTHER WORKED THAN SANDED"
    },
    {
      "code": "44101290",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:  ORIENTED STRAND BOARD (OSB):OTHER"
    },
    {
      "code": "44101900",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OF WOOD:--OTHER"
    },
    {
      "code": "44102100",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - ORIENTED STRAND-BOARD AND WAFER BOARD, OF WOOD: UNWORKED OR NOT FURTHER WORKED THAN SANDED"
    },
    {
      "code": "44102900",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - ORIENTED STRAND-BOARD AND WAFER BOARD, OF WOOD: OTHER"
    },
    {
      "code": "44103110",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: UNWORKED OR NOT FURTHER WORKED THAN SANDED: PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44103120",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: UNWORKED OR NOT FURTHER WORKED THAN SANDED: PLAIN INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44103130",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: UNWORKED OR NOT FURTHER WORKED THAN SANDED: PLAIN VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS ON ANY FACE"
    },
    {
      "code": "44103190",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: UNWORKED OR NOT FURTHER WORKED THAN SANDED: PLAIN OTHER"
    },
    {
      "code": "44103210",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH MELAMINE IMPREGNATED PAPER: PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44103220",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH MELAMINE IMPREGNATED PAPER: INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44103230",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH MELAMINE IMPREGNATED PAPER: VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS ON ANY FACE"
    },
    {
      "code": "44103290",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH MELAMINE IMPREGNATED PAPER: OTHER"
    },
    {
      "code": "44103310",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH DECORATIVE LAMINATES OF PLASTICS: PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44103320",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH DECORATIVE LAMINATES OF PLASTICS: INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44103330",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH DECORATIVE LAMINATES OF PLASTICS: VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS ON ANY FACE"
    },
    {
      "code": "44103390",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: SURFACE-COVERED WITH DECORATIVE LAMINATES OF PLASTICS: OTHER"
    },
    {
      "code": "44103910",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: OTHER: PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44103920",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: OTHER: INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44103930",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: OTHER: VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS ON ANY FACE"
    },
    {
      "code": "44103990",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES - OTHER, OF WOOD: OTHER: OTHER"
    },
    {
      "code": "441090",
      "description": "Other"
    },
    {
      "code": "44109010",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES -- OTHER --- PLAIN PARTICLE BOARD"
    },
    {
      "code": "44109011",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: PLASTIC LAMINATED SHEETS: INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44109012",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: PLASTIC LAMINATED SHEETS: VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS OF ANY FACE"
    },
    {
      "code": "44109019",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: PLASTIC LAMINATED SHEETS: OTHER"
    },
    {
      "code": "44109020",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES -- OTHER --- INSULATION BOARD AND HARD BOARD"
    },
    {
      "code": "44109030",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES -- OTHER --- VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS ON ANY FACE"
    },
    {
      "code": "44109040",
      "description": "BOARD, OF COIR"
    },
    {
      "code": "44109050",
      "description": "BOARD, OF JUTE FIBRE"
    },
    {
      "code": "44109090",
      "description": "PARTICLE BOARD, ORIENTED STRAND  BOARD (OSB) AND SIMILAR BOARD (FOR EXAMPLE, WAFERBOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS,WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES -- OTHER --- OTHER"
    },
    {
      "code": "44109091",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: OTHER : PLAIN PARTICLE BOARDS"
    },
    {
      "code": "44109092",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: OTHER : INSULATION BOARD AND HARDBOARD"
    },
    {
      "code": "44109093",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: OTHER : VENEERED PARTICLE BOARD, NOT HAVING DECORATIVE VENEERS OF ANY FACE"
    },
    {
      "code": "44109099",
      "description": "PARTICLE BOARD AND SIMILAR BOARD (FOR EXAMPLE, ORIENTED STRAND BOARD AND WAFER BOARD) OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT AGGLOMERATED WITH RESINS OR OTHER ORGANIC BINDING SUBSTANCES- OTHER: OTHER : OTHER"
    },
    {
      "code": "4411",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - Medium density fibre board (MDF):"
    },
    {
      "code": "44111110",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.8 G/C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED : HARDBOARD"
    },
    {
      "code": "44111190",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.8 G/C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED : OTHER"
    },
    {
      "code": "44111200",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - MEDIUM DENSITY FIBRE BOARD (MDF):-- OF A THICKNESS NOT EXCEEDING 5 MM"
    },
    {
      "code": "44111300",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - MEDIUM DENSITY FIBRE BOARD (MDF):-- OF A THICKNESS EXCEEDING 5 MM BUT NOT EXCEEDING 9 MM"
    },
    {
      "code": "44111400",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - MEDIUM DENSITY FIBRE BOARD (MDF):-- OF A THICKNESS EXCEEDING 9 MM"
    },
    {
      "code": "44111910",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.8 G/C - M3 - : OTHER : HARDBOARD"
    },
    {
      "code": "44111990",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.8 G/C - M3 - : OTHER : OTHER"
    },
    {
      "code": "44112110",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.5 G/C - M3 - BUT NOT EXCEEDING 0.8 G/C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED:INSULATION BOARD"
    },
    {
      "code": "44112190",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.5 G/C - M3 - BUT NOT EXCEEDING 0.8 G/C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED:OTHER"
    },
    {
      "code": "44112910",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.5 G/C - M3 - BUT NOT EXCEEDING 0.8 G/C - M3 - : OTHER : INSULATION BOARD"
    },
    {
      "code": "44112990",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBREBOARD OF A DENSITY EXCEEDING 0.5 G/C - M3 - BUT NOT EXCEEDING 0.8 G/C - M3 - : OTHER : OTHER"
    },
    {
      "code": "44113110",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBRE BOARD OF A DENSITY EXCEEDING 0.35 G/C - M3 - BUT NOT EXCEEDING 0.5 G/ C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED: INSULATION BOARD"
    },
    {
      "code": "44113190",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBRE BOARD OF A DENSITY EXCEEDING 0.35 G/C - M3 - BUT NOT EXCEEDING 0.5 G/ C - M3 - : NOT MECHANICALLY WORKED OR SURFACE COVERED: OTHER"
    },
    {
      "code": "44113910",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBRE BOARD OF A DENSITY EXCEEDING 0.35 G/C - M3 - BUT NOT EXCEEDING 0.5 G/ C - M3 - : OTHER: INSULATION BOARD"
    },
    {
      "code": "44113990",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - FIBRE BOARD OF A DENSITY EXCEEDING 0.35 G/C - M3 - BUT NOT EXCEEDING 0.5 G/ C - M3 - : OTHER: OTHER"
    },
    {
      "code": "44119110",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : NOT MECHANICALLY WORKED OR SURFACE COVERED: INSULATION BOARD (HOMOGENEOUS)"
    },
    {
      "code": "44119120",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : NOT MECHANICALLY WORKED OR SURFACE COVERED: ACCOUSTIC INSULATION BOARD"
    },
    {
      "code": "44119130",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : NOT MECHANICALLY WORKED OR SURFACE COVERED: OTHER INSULATION BOARD"
    },
    {
      "code": "44119190",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : NOT MECHANICALLY WORKED OR SURFACE COVERED: OTHER"
    },
    {
      "code": "441192",
      "description": "Of a density exceeding 0.8 gm/cm3"
    },
    {
      "code": "44119211",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER: -- OF A DENSITY EXCEEDING 0.8 GM/CM3: --- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- HARDBOARD"
    },
    {
      "code": "44119219",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER: -- OF A DENSITY EXCEEDING 0.8 GM/CM3: --- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- OTHER"
    },
    {
      "code": "44119221",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER: -- OF A DENSITY EXCEEDING 0.8 GM/CM3: --- OTHER:---- HARDBOARD"
    },
    {
      "code": "44119229",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER: -- OF A DENSITY EXCEEDING 0.8 GM/CM3: --- OTHER:---- OTHER"
    },
    {
      "code": "441193",
      "description": "Of a density exceeding 0.5 gm/cm 3 but not exceeding 0.8 gm/cm 3"
    },
    {
      "code": "44119311",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:-- OF A DENSITY EXCEEDING 0.5 GM/CM3 BUT NOT EXCEEDING 0.8 GM/CM3:--- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- INSULATION BOARD"
    },
    {
      "code": "44119319",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:-- OF A DENSITY EXCEEDING 0.5 GM/CM3 BUT NOT EXCEEDING 0.8 GM/CM3:--- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- OTHER"
    },
    {
      "code": "44119321",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:-- OF A DENSITY EXCEEDING 0.5 GM/CM3 BUT NOT EXCEEDING 0.8 GM/CM3: --- OTHER:---- INSULATION BOARD"
    },
    {
      "code": "44119329",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:-- OF A DENSITY EXCEEDING 0.5 GM/CM3 BUT NOT EXCEEDING 0.8 GM/CM3: --- OTHER:---- OTHER"
    },
    {
      "code": "441194",
      "description": "Of a density not exceeding 0.5 gm/cm 3"
    },
    {
      "code": "44119411",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:OF A DENSITY NOT EXCEEDING 0.5 GM/CM3:--- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- INSULATION BOARD"
    },
    {
      "code": "44119419",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:OF A DENSITY NOT EXCEEDING 0.5 GM/CM3:--- NOT MECHANICALLY WORKED OR SURFACE COVERED:---- OTHER"
    },
    {
      "code": "44119421",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:OF A DENSITY NOT EXCEEDING 0.5 GM/CM3:--- OTHER: ----INSULATION BOARD"
    },
    {
      "code": "44119422",
      "description": "FIBRE BOARDS OF COIR"
    },
    {
      "code": "44119423",
      "description": "FIBRE BOARDS OF JUTE FIBRE"
    },
    {
      "code": "44119429",
      "description": "FIBRE BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER:OF A DENSITY NOT EXCEEDING 0.5 GM/CM3:--- OTHER: ----OTHER"
    },
    {
      "code": "44119910",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : OTHER: INSULATION BOARD (HOMOGENOUS)"
    },
    {
      "code": "44119920",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : OTHER: ACCOUSTIC ISULATION BOARD"
    },
    {
      "code": "44119930",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : OTHER: OTHER INSULATION BOARD"
    },
    {
      "code": "44119990",
      "description": "FIBREBOARD OF WOOD OR OTHER LIGNEOUS MATERIALS, WHETHER OR NOT BONDED WITH RESINS OR OTHER ORGANIC SUBSTANCES - OTHER : OTHER: OTHER"
    },
    {
      "code": "4412",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD"
    },
    {
      "code": "44121000",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OF BAMBOO"
    },
    {
      "code": "44121310",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : DECORATIVE PLYWOOD"
    },
    {
      "code": "44121320",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44121330",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER TEA CHEST PANELS"
    },
    {
      "code": "44121340",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44121350",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CENTIMETRES"
    },
    {
      "code": "44121390",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER : OTHER"
    },
    {
      "code": "44121410",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD : DECORATIVE PLYWOOD"
    },
    {
      "code": "44121420",
      "description": "TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44121430",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD : MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44121440",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD : CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44121490",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD : OTHER"
    },
    {
      "code": "44121910",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER : DECORATIVE PLYWOOD"
    },
    {
      "code": "44121920",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER : TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44121930",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER : MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44121940",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER : CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44121990",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD, EACH PLY NOT EXCEEDING 6 MM THICKNESS : OTHER : OTHER"
    },
    {
      "code": "44122210",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER - DECORATIVE PLYWOOD"
    },
    {
      "code": "44122220",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER - TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44122230",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER - MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44122240",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER - CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44122290",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER - OTHER"
    },
    {
      "code": "44122310",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: DECORATIVE PLYWOOD"
    },
    {
      "code": "44122320",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44122330",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44122340",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44122390",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: OTHER"
    },
    {
      "code": "44122910",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : ELASTIC LAMINATED PLYWOOD"
    },
    {
      "code": "44122920",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : DECORATIVE PLYWOOD"
    },
    {
      "code": "44122930",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44122940",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44122950",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44122990",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER, WITH AT LEAST ONE OUTER PLY OF NONCONIFEROUS WOOD : OTHER : OTHER"
    },
    {
      "code": "441231",
      "description": "With at least one outer ply of tropical wood"
    },
    {
      "code": "44123110",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- DECORATIVE PLYWOOD"
    },
    {
      "code": "44123120",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44123130",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- OTHER TEA CHEST PANELS"
    },
    {
      "code": "44123140",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44123150",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5CM"
    },
    {
      "code": "44123190",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER PLYWOOD, CONSISTING SOLELY OF SHEETS OF WOOD (OTHER THAN BAMBOO), EACH PLY NOT EXCEEDING 6 MM THICKNESS: WITH AT LEAST ONE OUTER PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER, NAMELY:- ABURA, ACAJOU, D?AFRIQUE, AFRORMOSIA, AKO, ALAN, ANDIROBA, ANINGRE, AVODIRE, AZOBE, BALAU, BALSA,  BOSSE CLAIR, BOSSE FONCE, CATIVO, CEDRO, DABEMA, DARK RED MERANTI, DIBETOU, DOUSSIE, FRAMIRE, FREIJO, FROMAGER, FUMA, GERONGGANG, ILOMBA, IMBUIA, IPE, IROKO, JABOTY, JELUTONG, JEQUITIBA, JONGKONG, KAPUR, KEMPAS, KERUING, KOSIPO, KOTIBE, KOTO, LIGHT RED MERANTI, LIMBA, LOURO, MACARANDUBA, MAHOGONY, MAKORE, MANDIOQUEIRA, MANSONIA, MENGKULANG, MERANTI BAKAU, MERAWAN, MERBAU, MERPAUH, MERSAWA, MOABI, NIANGON, NYATOH, OBECHE, OKOUME, ONZABILI, OREY, OVENGKOL, OZIGO, PADAUK, PALDAO, PALISSANDRE DE GUATEMALA, PALISSANDRE DE PARA, PALISSANDRE DE RIO, PALISSANDRE DE ROSE, PAU AMARELO, PAU MARFIM, PULAI, PUNAH, QUARUBA, RAMIN, SAPELLI, SAQUI- SAQUI, SEPETIR, SIPO, SUCUPIRA, SUREN, TAUARI,TEAK, TIAMA, TOLA, VIROLA, WHITE LAUAN, WHITE MERANTI, WHITE SERAYA, YELLOW MERANTI :--- OTHER"
    },
    {
      "code": "441232",
      "description": "Other, with at least one outer ply of non-coniferous wood"
    },
    {
      "code": "44123210",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTERPLY OF NON-CONIFEROUS WOOD:--- DECORATIVE PLYWOOD"
    },
    {
      "code": "44123220",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTERPLY OF NON-CONIFEROUS WOOD:--- TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44123230",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTERPLY OF NON-CONIFEROUS WOOD:--- MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44123240",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTERPLY OF NON-CONIFEROUS WOOD:CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5CM"
    },
    {
      "code": "44123290",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTERPLY OF NON-CONIFEROUS WOOD:--- OTHER"
    },
    {
      "code": "441233",
      "description": "Other, with at least one outer ply of non-coniferous wood of the species alder (Alnus spp.), ash (Fraxinus spp.), beech (Fagus spp.), birch (Betula spp.), cherry (Prunus spp.), chestnut (Castanea spp.), elm (Ulmus spp.), eucalyptus (Eucalyptus spp.), hickory (Carya spp.), horse chestnut (Aesculus spp.), lime (Tilia spp.), maple (Acer spp.), oak (Quercus spp.), plane tree (Platanus spp.), poplar and aspen (Populus spp.), robinia (Robinia spp.), tulipwood (Liriodendron spp.) or walnut (Juglans spp.)"
    },
    {
      "code": "44123310",
      "description": "Decorative plywood"
    },
    {
      "code": "44123320",
      "description": "Tea chest panels, shooks whether or not packed in sets"
    },
    {
      "code": "44123330",
      "description": "Marine and aircraft plywood"
    },
    {
      "code": "44123340",
      "description": "Cutting and trimmings of plywood of width not exceeding 5 cm"
    },
    {
      "code": "44123390",
      "description": "Other"
    },
    {
      "code": "441234",
      "description": "Other, with at least one outer ply of non-coniferous wood not specified under sub-heading 4412 33"
    },
    {
      "code": "44123410",
      "description": "Decorative plywood"
    },
    {
      "code": "44123420",
      "description": "Tea chest panels, shooks whether or not packed in sets"
    },
    {
      "code": "44123430",
      "description": "Marine and aircraft plywood"
    },
    {
      "code": "44123440",
      "description": "Cutting and trimmings of plywood of width not exceeding 5 cm"
    },
    {
      "code": "44123490",
      "description": "Other"
    },
    {
      "code": "441239",
      "description": "Other with both outer plies of coniferous wood:"
    },
    {
      "code": "44123910",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD:-- OTHER:--- DECORATIVE PLYWOOD"
    },
    {
      "code": "44123920",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD:-- OTHER:--- TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44123930",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD:-- OTHER:--- MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44123940",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD:-- OTHER:--- CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5CM"
    },
    {
      "code": "44123990",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD -- OTHER, WITH AT LEAST ONE OUTER PLY OF NON-CONIFEROUS WOOD:-- OTHER:--- OTHER"
    },
    {
      "code": "44129210",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: ELASTIC LAMINATED PLYWOOD"
    },
    {
      "code": "44129220",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: DECORATIVE PLYWOOD"
    },
    {
      "code": "44129230",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44129240",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44129250",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44129290",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : WITH AT LEAST ONE PLY OF TROPICAL WOOD SPECIFIED IN SUB-HEADING NOTE 1 TO THIS CHAPTER: OTHER"
    },
    {
      "code": "44129310",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: DECORATIVE PLYWOOD"
    },
    {
      "code": "44129320",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: TEA CHEST PANELS OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44129330",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44129340",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44129390",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER, CONTAINING AT LEAST ONE LAYER OF PARTICLE BOARD: OTHER"
    },
    {
      "code": "44129400",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- BLOCKBOARD, LAMINBOARD AND BATTENBOARD"
    },
    {
      "code": "441299",
      "description": "Other"
    },
    {
      "code": "44129910",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- OTHER:--- DECORATIVE PLYWOOD"
    },
    {
      "code": "44129920",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- OTHER:--- TEA CHEST PANEL OR SHOOKS, PACKED IN SETS"
    },
    {
      "code": "44129930",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- OTHER:--- MARINE AND AIRCRAFT PLYWOOD"
    },
    {
      "code": "44129940",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- OTHER:--- CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5CM"
    },
    {
      "code": "44129950",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER : OTHER : CUTTINGS AND TRIMMINGS OF PLYWOOD OF WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "44129990",
      "description": "PLYWOOD, VENEERED PANELS AND SIMILAR LAMINATED WOOD - OTHER: -- OTHER:--- OTHER"
    },
    {
      "code": "4413",
      "description": "DENSIFIED WOOD, IN BLOCKS, PLATES, STRIPS, OR PROFILE SHAPES"
    },
    {
      "code": "44130000",
      "description": "DENSIFIED WOOD, IN BLOCKS, PLATES, STRIPS, OR PROFILE SHAPES"
    },
    {
      "code": "4414",
      "description": "WOODEN FRAMES FOR PAINTINGS, PHOTOGRAPHS, MIRRORS OR SIMILAR OBJECTS"
    },
    {
      "code": "44140000",
      "description": "WOODEN FRAMES FOR PAINTINGS, PHOTOGRAPHS, MIRRORS OR SIMILAR OBJECTS"
    },
    {
      "code": "4415",
      "description": "PACKING CASES, BOXES, CRATES, DRUMS AND SIMILAR PACKINGS, OF WOOD; CABLE-DRUMS OF WOOD; PALLETS, BOX PALLETS AND OTHER LOAD BOARDS, OF WOOD; PALLET COLLARS OF WOOD"
    },
    {
      "code": "44151000",
      "description": "PACKING CASES, BOXES, CRATES, DRUMS AND SIMILAR PACKINGS, OF WOOD; CABLE-DRUMS OF WOOD; PALLETS, BOX PALLETS AND OTHER LOAD BOARDS, OF WOOD; PALLET COLLARS OF WOOD - CASES, BOXES, CRATES, DRUMS AND SIMILAR PACKINGS; CABLE-DRUMS"
    },
    {
      "code": "44152000",
      "description": "PACKING CASES, BOXES, CRATES, DRUMS AND SIMILAR PACKINGS, OF WOOD; CABLE-DRUMS OF WOOD; PALLETS, BOX PALLETS AND OTHER LOAD BOARDS, OF WOOD; PALLET COLLARS OF WOOD - PALLETS, BOX PALLETS AND OTHER LOAD BOARDS; PALLET COLLARS"
    },
    {
      "code": "4416",
      "description": "CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES"
    },
    {
      "code": "441600",
      "description": "Casks, barrels, vats, tubs and other coopers products and parts thereof, of wood, including staves"
    },
    {
      "code": "44160010",
      "description": "CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES - CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES: CASKS, BARRELS, VATS AND TUBS"
    },
    {
      "code": "44160020",
      "description": "CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES - CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES: OTHER COOPERS PRODUCTS"
    },
    {
      "code": "44160091",
      "description": "CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES - PARTS (OF WOOD): RIVEN OR SAWN STAVES OF WOOD NOT FURTHER PREPARED"
    },
    {
      "code": "44160099",
      "description": "CASKS, BARRELS, VATS, TUBS AND OTHER COOPERS PRODUCTS AND PARTS THEREOF, OF WOOD, INCLUDING STAVES - PARTS (OF WOOD): OTHER"
    },
    {
      "code": "4417",
      "description": "TOOLS, TOOL BODIES, TOOL HANDLES, BROOM OR BRUSH BODIES AND HANDLES, OF WOOD; BOOT OR SHOE LASTS AND TREES, OF WOOD"
    },
    {
      "code": "44170000",
      "description": "TOOLS, TOOL BODIES, TOOL HANDLES, BROOM OR BRUSH BODIES AND HANDLES, OF WOOD; BOOT OR SHOE LASTS AND TREES, OF WOOD"
    },
    {
      "code": "4418",
      "description": "BUILDERS JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES"
    },
    {
      "code": "44181000",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES WINDOWS, FRENCH-WINDOWS AND THEIR FRAMES"
    },
    {
      "code": "441820",
      "description": "Doors and their frames and thresholds"
    },
    {
      "code": "44182010",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - DOORS AND THEIR FRAMES AND THRESHOLDS: FLUSH DOORS"
    },
    {
      "code": "44182020",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - DOORS AND THEIR FRAMES AND THRESHOLDS: FRAMES AND THRESHOLDS OF FLUSH DOORS"
    },
    {
      "code": "44182090",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - DOORS AND THEIR FRAMES AND THRESHOLDS: OTHER"
    },
    {
      "code": "44183000",
      "description": "BUILDERR JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED PARQUET PANELS, SHINGLES AND SHAKES - PARQUET PANELS"
    },
    {
      "code": "44184000",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - SHUTTERING FOR CONCRETE CONSTRUCTIONAL WORK"
    },
    {
      "code": "44185000",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - SHINGLES AND SHAKES"
    },
    {
      "code": "44186000",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - POSTS AND BEAMS"
    },
    {
      "code": "44187100",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - ASSEMBLED FLOORING PANELS :-- FOR MOSAIC FLOORS"
    },
    {
      "code": "44187200",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - ASSEMBLED FLOORING PANELS :--OTHER, MULTILAYER"
    },
    {
      "code": "44187300",
      "description": "Of bamboo or with at least the top layer (wear layer) of bamboo"
    },
    {
      "code": "44187400",
      "description": "Other, for mosaic floors"
    },
    {
      "code": "44187500",
      "description": "Other, multilayer"
    },
    {
      "code": "44187900",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - ASSEMBLED FLOORING PANELS :--OTHER"
    },
    {
      "code": "44189000",
      "description": "BUILDERS` JOINERY AND CARPENTRY OF WOOD, INCLUDING CELLULAR WOOD PANELS, ASSEMBLED FLOORING PANELS, SHINGLES AND SHAKES - SHINGLES AND SHAKES"
    },
    {
      "code": "44189100",
      "description": "Of bamboo"
    },
    {
      "code": "44189900",
      "description": "Other"
    },
    {
      "code": "4419",
      "description": "TABLEWARE AND KITCHENWARE, OF WOOD"
    },
    {
      "code": "441900",
      "description": "Tableware and kitchenware, of wood"
    },
    {
      "code": "44190010",
      "description": "TABLEWARE AND KITCHENWARE, OF WOOD - TABLEWARE AND KITCHENWARE, OF WOOD: TABLEWARE"
    },
    {
      "code": "44190020",
      "description": "TABLEWARE AND KITCHENWARE, OF WOOD - TABLEWARE AND KITCHENWARE, OF WOOD: KITCHENWARE"
    },
    {
      "code": "44191100",
      "description": "Bread boards, chopping boards and similar boards"
    },
    {
      "code": "44191200",
      "description": "Chopsticks"
    },
    {
      "code": "44191900",
      "description": "Other"
    },
    {
      "code": "441990",
      "description": "Other"
    },
    {
      "code": "44199010",
      "description": "Bread boards, chopping boards and similar boards"
    },
    {
      "code": "44199020",
      "description": "Chopsticks"
    },
    {
      "code": "44199090",
      "description": "other"
    },
    {
      "code": "4420",
      "description": "WOOD MARQUETRY AND INLAID WOOD; CASKETS AND CASES FOR JEWELLERY OR CUTLERY, AND SIMILAR ARTICLES, OF WOOD; STATUETTES AND OTHER ORNAMENTS, OF WOOD; WOODEN ARTICLES OF FURNITURE NOT FALLING IN CHAPTER 94"
    },
    {
      "code": "44201000",
      "description": "WOOD MARQUETRY AND INLAID WOOD; CASKETS AND CASES FOR JEWELLERY OR CUTLERY, AND SIMILAR ARTICLES, OF WOOD; STATUETTES AND OTHER ORNAMENTS, OF WOOD; WOODEN ARTICLES OF FURNITURE NOT FALLING IN CHAPTER 94 - STATUETTES AND OTHER ORNAMENTS, OF WOOD"
    },
    {
      "code": "442090",
      "description": "Other"
    },
    {
      "code": "44209010",
      "description": "WOOD MARQUETRY AND INLAID WOOD; CASKETS AND CASES FOR JEWELLERY OR CUTLERY, AND SIMILAR ARTICLES, OF WOOD; STATUETTES AND OTHER ORNAMENTS, OF WOOD; WOODEN ARTICLES OF FURNITURE NOT FALLING IN CHAPTER 94 - OTHER : WOOD MARQUETRY AND INLAID WOOD"
    },
    {
      "code": "44209090",
      "description": "WOOD MARQUETRY AND INLAID WOOD; CASKETS AND CASES FOR JEWELLERY OR CUTLERY, AND SIMILAR ARTICLES, OF WOOD; STATUETTES AND OTHER ORNAMENTS, OF WOOD; WOODEN ARTICLES OF FURNITURE NOT FALLING IN CHAPTER 94 - OTHER : OTHER"
    },
    {
      "code": "4421",
      "description": "OTHER ARTICLES OF WOOD"
    },
    {
      "code": "44211000",
      "description": "OTHER ARTICLES OF WOOD - CLOTHES HANGERS"
    },
    {
      "code": "442190",
      "description": "Other"
    },
    {
      "code": "44219011",
      "description": "OTHER ARTICLES OF WOOD - OTHER : SPOOLS, COPS, BOBBINS, SEWING THREAD REELS AND THE LIKE, OF TURNED WOOD: FOR COTTON MACHINERY"
    },
    {
      "code": "44219012",
      "description": "OTHER ARTICLES OF WOOD - OTHER : SPOOLS, COPS, BOBBINS, SEWING THREAD REELS AND THE LIKE, OF TURNED WOOD: FOR JUTE MACHINERY"
    },
    {
      "code": "44219013",
      "description": "OTHER ARTICLES OF WOOD - OTHER : SPOOLS, COPS, BOBBINS, SEWING THREAD REELS AND THE LIKE, OF TURNED WOOD: FOR SILK REGENERATED AND SYNTHETIC FIBRES MACHINERY"
    },
    {
      "code": "44219014",
      "description": "OTHER ARTICLES OF WOOD - OTHER : SPOOLS, COPS, BOBBINS, SEWING THREAD REELS AND THE LIKE, OF TURNED WOOD: FOR OTHER MACHINERY"
    },
    {
      "code": "44219019",
      "description": "OTHER ARTICLES OF WOOD - OTHER : SPOOLS, COPS, BOBBINS, SEWING THREAD REELS AND THE LIKE, OF TURNED WOOD: OTHER"
    },
    {
      "code": "44219020",
      "description": "OTHER ARTICLES OF WOOD - OTHER : WOOD PAVING BLOCKS"
    },
    {
      "code": "44219030",
      "description": "OTHER ARTICLES OF WOOD - OTHER : MATCH SPLINTS"
    },
    {
      "code": "44219040",
      "description": "OTHER ARTICLES OF WOOD - OTHER : PENCIL SLATES"
    },
    {
      "code": "44219050",
      "description": "OTHER ARTICLES OF WOOD - OTHER : PARTS OF WOOD, NAMELY OARS, PADDLES AND RUDDERS FOR SHIPS, BOATS AND OTHER SIMILAR FLOATING STRUCTURES"
    },
    {
      "code": "44219060",
      "description": "OTHER ARTICLES OF WOOD - OTHER : PARTS OF DOMESTIC DECORATIVE ARTICLES USED AS TABLEWARE AND KITCHENWARE"
    },
    {
      "code": "44219070",
      "description": "OTHER ARTICLES OF WOOD - OTHER : ARTICLES OF DENSIFIED WOOD NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "44219090",
      "description": "OTHER ARTICLES OF WOOD - OTHER : OTHER"
    },
    {
      "code": "442191",
      "description": "Of Bamboo"
    },
    {
      "code": "44219111",
      "description": "For cotton machinery"
    },
    {
      "code": "44219112",
      "description": "FOR JUTE MACHINERY"
    },
    {
      "code": "44219113",
      "description": "FOR SILK REGENERATED AND SYNTHETIC FIBRE MACHINERY"
    },
    {
      "code": "44219114",
      "description": "FOR OTHER MACHINERY"
    },
    {
      "code": "44219119",
      "description": "Other"
    },
    {
      "code": "44219120",
      "description": "Wood Paving Blocks"
    },
    {
      "code": "44219130",
      "description": "Match splints"
    },
    {
      "code": "44219140",
      "description": "Pencil slats"
    },
    {
      "code": "44219150",
      "description": "Parts of wood, namely oars, paddles and rudders for ships, boats and other similarfloating structures"
    },
    {
      "code": "44219160",
      "description": "Parts of domestic decorative articles used as tableware and kitchenware"
    },
    {
      "code": "44219170",
      "description": "Articles of densified wood not included or specified elsewhere"
    },
    {
      "code": "44219190",
      "description": "Other"
    },
    {
      "code": "442199",
      "description": "Other"
    },
    {
      "code": "44219911",
      "description": "For cotton machinery"
    },
    {
      "code": "44219912",
      "description": "For jute machinery"
    },
    {
      "code": "44219913",
      "description": "For silk regenerated and synthetic fibre machinery"
    },
    {
      "code": "44219914",
      "description": "For other machinery"
    },
    {
      "code": "44219919",
      "description": "other"
    },
    {
      "code": "44219920",
      "description": "Wood paving blocks"
    },
    {
      "code": "44219930",
      "description": "Match splints"
    },
    {
      "code": "44219940",
      "description": "Pencil slats"
    },
    {
      "code": "44219950",
      "description": "Parts of wood, namely oars, paddles and rudders for ships, boats and other similar floating structures"
    },
    {
      "code": "44219960",
      "description": "Parts of domestic decorative articles used as tableware and kitchenware"
    },
    {
      "code": "44219970",
      "description": "Articles of densified wood not included or specified elsewhere"
    },
    {
      "code": "44219990",
      "description": "other"
    },
    {
      "code": "44229112",
      "description": "For jute machinery"
    },
    {
      "code": "44239113",
      "description": "For silk regenerated and synthetic fibre machinery"
    },
    {
      "code": "44249114",
      "description": "For other machinery"
    },
    {
      "code": "45",
      "description": "Cork and articles of cork"
    },
    {
      "code": "4501",
      "description": "NATURAL CORK, RAW OR SIMPLY SDF KSDF JKLSD PREPARED; WASTE CORK; CRUSHED, GRANULATED OR GROUND CORK"
    },
    {
      "code": "45011000",
      "description": "NATURAL CORK, RAW OR SIMPLY PREPARED; WASTE CORK; CRUSHED, GRANULATED OR GROUND CORK - NATURAL CORK, RAW OR SIMPLY PREPARED"
    },
    {
      "code": "45019000",
      "description": "NATURAL CORK, RAW OR SIMPLY PREPARED; WASTE CORK; CRUSHED, GRANULATED OR GROUND CORK - OTHER"
    },
    {
      "code": "4502",
      "description": " NATURAL CORK, DEBACKED OR ROUGHLY SQUARED, OR IN RECTANGULAR (INCLUDING SQUARE) BLOCKS, PLATES, SHEETS OR STRIP (INCLUDING SHARP-EDGED BLANKS FOR CORKS OR STOPPERS) "
    },
    {
      "code": "45020000",
      "description": "NATURAL CORK, DEBACKED OR ROUGHLY SQUARED, OR IN RECTANGULAR (INCLUDING SQUARE) BLOCKS, PLATES, SHEETS OR STRIP (INCLUDING SHARP-EDGED BLANKS FOR CORKS OR STOPPERS)"
    },
    {
      "code": "4503",
      "description": "ARTICLES OF NATURAL CORK"
    },
    {
      "code": "45031000",
      "description": "ARTICLES OF NATURAL CORK - CORKS AND STOPPERS"
    },
    {
      "code": "450390",
      "description": "Other"
    },
    {
      "code": "45039010",
      "description": "ARTICLES OF NATURAL CORK - OTHER : SHUTTLECOCK CORK BOTTOM"
    },
    {
      "code": "45039090",
      "description": "ARTICLES OF NATURAL CORK - OTHER : OTHER"
    },
    {
      "code": "4504",
      "description": "AGGLOMERATED CORK (WITH OR WITHOUT A BINDING SUBSTANCE) AND ARTICLES OF AGGLOMERATED CORK"
    },
    {
      "code": "450410",
      "description": "Blocks, plates, sheets and strip; tiles of any shape; solid cylinders, including discs"
    },
    {
      "code": "45041010",
      "description": "AGGLOMERATED CORK (WITH OR WITHOUT A BINDING SUBSTANCE) AND ARTICLES OF AGGLOMERATED CORK - BLOCKS, PLATES, SHEETS AND STRIP; TILES OF ANY SHAPE; SOLID CYLINDERS, INCLUDING DISCS : SHEETS"
    },
    {
      "code": "45041020",
      "description": "AGGLOMERATED CORK (WITH OR WITHOUT A BINDING SUBSTANCE) AND ARTICLES OF AGGLOMERATED CORK - BLOCKS, PLATES, SHEETS AND STRIP; TILES OF ANY SHAPE; SOLID CYLINDERS, INCLUDING DISCS : SLABS"
    },
    {
      "code": "45041090",
      "description": "AGGLOMERATED CORK (WITH OR WITHOUT A BINDING SUBSTANCE) AND ARTICLES OF AGGLOMERATED CORK - BLOCKS, PLATES, SHEETS AND STRIP; TILES OF ANY SHAPE; SOLID CYLINDERS, INCLUDING DISCS : OTHER"
    },
    {
      "code": "45049000",
      "description": "AGGLOMERATED CORK (WITH OR WITHOUT A BINDING SUBSTANCE) AND ARTICLES OF AGGLOMERATED CORK - OTHER"
    },
    {
      "code": "46",
      "description": "Manufactures of straw, of esparto or of other plaiting materials; basketware and wickerwork"
    },
    {
      "code": "4601",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - Mats, matting and screens of vegetable materials :"
    },
    {
      "code": "46012010",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS : MATS AND MATTING OF COIR, BOUND IN PARALLEL STRANDS"
    },
    {
      "code": "46012020",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS : MATS AND MATTING NOT ELSEWHERE INCLUDED OR SPECIFIED (FOR EXAMPLE, GRASS MATS)"
    },
    {
      "code": "46012090",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS : OTHER"
    },
    {
      "code": "46012100",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS:-- OF BAMBOO"
    },
    {
      "code": "46012200",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS:-- OF RATTAN"
    },
    {
      "code": "46012900",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - MATS, MATTING AND SCREENS OF VEGETABLE MATERIALS:-- OTHER"
    },
    {
      "code": "46019100",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - OTHER : OF VEGETABLE MATERIALS"
    },
    {
      "code": "46019200",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - OTHER -- OF BAMBOO"
    },
    {
      "code": "46019300",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - OTHER --OF RATTAN"
    },
    {
      "code": "46019400",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - OTHER -- OF OTHER VEGETABLE MATERIALS"
    },
    {
      "code": "46019900",
      "description": "PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, WHETHER OR NOT ASSEMBLED INTO STRIPS ; PLAITING MATERIALS, PLAITS AND SIMILAR PRODUCTS OF PLAITING MATERIALS, BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN, IN SHEET FORM, WHETHER OR NOT BEING FINISHED ARTICLES (FOR EXAMPLE, MATS, MATTING, SCREENS) - OTHER : OTHER"
    },
    {
      "code": "4602",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH - Of vegetable materials :"
    },
    {
      "code": "46021011",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : OF PALM LEAVES : BASKETS"
    },
    {
      "code": "46021019",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : OF PALM LEAVES : OTHER"
    },
    {
      "code": "46021090",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : OTHER"
    },
    {
      "code": "46021100",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH - OF VEGETABLE MATERIALS :--OF BAMBOO"
    },
    {
      "code": "46021200",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : -- OF RATTAN"
    },
    {
      "code": "460219",
      "description": "Other"
    },
    {
      "code": "46021911",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : -- OTHER: --- OF PALM LEAVES : ---- BASKET"
    },
    {
      "code": "46021919",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : -- OTHER: --- OF PALM LEAVES : ---- OTHER"
    },
    {
      "code": "46021990",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH OF VEGETABLE MATERIALS : -- OTHER: --- OTHER"
    },
    {
      "code": "46029000",
      "description": "BASKETWORK, WICKERWORK AND OTHER ARTICLES, MADE DIRECTLY TO SHAPE FROM PLAITING MATERIALS OR MADE UP FROM GOODS OF HEADING 46 01; ARTICLES OF LOOFAH - OTHER"
    },
    {
      "code": "47",
      "description": "Pulp of wood or of other fibrous cellulosic material; recovered"
    },
    {
      "code": "4701",
      "description": "MECHANICAL WOOD PULP"
    },
    {
      "code": "47010000",
      "description": "MECHANICAL WOOD PULP"
    },
    {
      "code": "4702",
      "description": "CHEMICAL WOOD PULP, DISSOLVING GRADES"
    },
    {
      "code": "47020000",
      "description": "CHEMICAL WOOD PULP, DISSOLVING GRADES"
    },
    {
      "code": "4703",
      "description": "CHEMICAL WOOD PULP, SODA OR SULPHATE, OTHER THAN DISSOLVING GRADES - Unbleached :"
    },
    {
      "code": "47031100",
      "description": "CHEMICAL WOOD PULP, SODA OR SULPHATE, OTHERTHAN DISSOLVING GRADES - UNBLEACHED : CONIFEROUS"
    },
    {
      "code": "47031900",
      "description": "CHEMICAL WOOD PULP, SODA OR SULPHATE, OTHERTHAN DISSOLVING GRADES - UNBLEACHED : NON-CONIFEROUS"
    },
    {
      "code": "47032100",
      "description": "CHEMICAL WOOD PULP, SODA OR SULPHATE, OTHERTHAN DISSOLVING GRADES - SEMI-BLEACHED OR BLEACHED : CONIFEROUS"
    },
    {
      "code": "47032900",
      "description": "CHEMICAL WOOD PULP, SODA OR SULPHATE, OTHERTHAN DISSOLVING GRADES - SEMI-BLEACHED OR BLEACHED : NON-CONIFEROUS"
    },
    {
      "code": "4704",
      "description": "CHEMICAL WOOD PULP, SULPHITE, OTHER THAN DISSOLVING GRADES - Unbleached :"
    },
    {
      "code": "47041100",
      "description": "CHEMICAL WOOD PULP, SULPHITE, OTHER THAN DISSOLVING GRADES - UNBLEACHED : CONIFEROUS"
    },
    {
      "code": "47041900",
      "description": "CHEMICAL WOOD PULP, SULPHITE, OTHER THAN DISSOLVING GRADES - UNBLEACHED : NON-CONIFEROUS"
    },
    {
      "code": "47042100",
      "description": "CHEMICAL WOOD PULP, SULPHITE, OTHER THAN DISSOLVING GRADES - SEMI-BLEACHED OR BLEACHED : CONIFEROUS"
    },
    {
      "code": "47042900",
      "description": "CHEMICAL WOOD PULP, SULPHITE, OTHER THAN DISSOLVING GRADES - SEMI-BLEACHED OR BLEACHED : NON-CONIFEROUS"
    },
    {
      "code": "4705",
      "description": " WOOD PULP OBTAINED BY A COMBINATION OF MECHANICAL AND CHEMICAL PULPING PROCESSES"
    },
    {
      "code": "47050000",
      "description": "WOOD PULP OBTAINED BY A COMBINATION OF MECHANICAL AND CHEMICAL PULPING PROCESSES"
    },
    {
      "code": "4706",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL"
    },
    {
      "code": "47061000",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - COTTON LINTERS PULP"
    },
    {
      "code": "47062000",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD"
    },
    {
      "code": "47063000",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - OTHER OF BAMBOO"
    },
    {
      "code": "47069100",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - OTHER : MECHANICAL"
    },
    {
      "code": "47069200",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - OTHER : CHEMICAL"
    },
    {
      "code": "470693",
      "description": "Obtained by a combination of mechanical and chemical processes"
    },
    {
      "code": "47069300",
      "description": "PULPS OF FIBRES DERIVED FROM RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD OR OF OTHER FIBROUS CELLULOSIC MATERIAL - OTHER : SEMI-CHEMICAL"
    },
    {
      "code": "4707",
      "description": "RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD"
    },
    {
      "code": "47071000",
      "description": "RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD - UNBLEACHED KRAFT PAPER OR PAPERBOARD OR CORRUGATED PAPER OR PAPERBOARD"
    },
    {
      "code": "47072000",
      "description": "RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD - OTHER PAPER OR PAPERBOARD MADE MAINLY OF BLEACHED CHEMICAL PULP, NOT COLOURED IN THE MASS"
    },
    {
      "code": "47073000",
      "description": "RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD - PAPER OR PAPERBOARD MADE MAINLY OF MECHANICAL PULP (FOR EXAMPLE, NEWSPAPERS, JOURNALS AND SIMILAR PRINTED MATTER)"
    },
    {
      "code": "47079000",
      "description": "RECOVERED (WASTE AND SCRAP) PAPER OR PAPERBOARD - OTHER, INCLUDING UNSORTED WASTE AND SCRAP"
    },
    {
      "code": "48",
      "description": "(waste and scrap) paper or paperboard"
    },
    {
      "code": "4801",
      "description": "NEWSPRINT, IN ROLLS OR SHEETS"
    },
    {
      "code": "480100",
      "description": "Newsprint, in rolls or sheets"
    },
    {
      "code": "48010010",
      "description": "NEWSPRINT, IN ROLLS OR SHEETS - NEWSPRINT, IN ROLLS OR SHEETS :GLAZED"
    },
    {
      "code": "48010090",
      "description": "NEWSPRINT, IN ROLLS OR SHEETS - NEWSPRINT, IN ROLLS OR SHEETS :OTHER"
    },
    {
      "code": "4802",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HANDMADE PAPER AND PAPERBOARD"
    },
    {
      "code": "480210",
      "description": "Hand-made paper and paperboard"
    },
    {
      "code": "48021010",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - HAND-MADE PAPER AND PAPERBOARD : PAPER"
    },
    {
      "code": "48021020",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - HAND-MADE PAPER AND PAPERBOARD : PAPERBOARD"
    },
    {
      "code": "480220",
      "description": "Paper and paperboard of a kind used as a base for photo-sensitive, heat-sensitive or electro-sensitive paper or paperboard"
    },
    {
      "code": "48022010",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - PAPER AND PAPERBOARD OF A KIND USED AS A BASE FOR PHOTO-SENSITIVE, HEAT-SENSITIVE OR ELECTRO-SENSITIVE PAPER OR PAPERBOARD : PHOTOGRAPHIC BASE PAPER, UNCOATED"
    },
    {
      "code": "48022090",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - PAPER AND PAPERBOARD OF A KIND USED AS A BASE FOR PHOTO-SENSITIVE, HEAT-SENSITIVE OR ELECTRO-SENSITIVE PAPER OR PAPERBOARD : OTHER"
    },
    {
      "code": "48023000",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD CARBONISING BASE PAPER"
    },
    {
      "code": "48024000",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD WALL PAPER BASE"
    },
    {
      "code": "480254",
      "description": "Weighing less than 40 g/m2"
    },
    {
      "code": "48025410",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: INDIA PAPER"
    },
    {
      "code": "48025420",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: LITHO AND OFFSET PAPER"
    },
    {
      "code": "48025430",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: DUPLICATING PAPER"
    },
    {
      "code": "48025440",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: AIRMAIL PAPER"
    },
    {
      "code": "48025450",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: TISSUE PAPER"
    },
    {
      "code": "48025490",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING LESS THAN 40 G/M2: OTHER"
    },
    {
      "code": "480255",
      "description": "Wieghing 40 g/m2 or more but less than 150 g/m2, in rolls"
    },
    {
      "code": "48025510",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : LITHO AND OFFSET PAPER"
    },
    {
      "code": "48025520",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : DRAWING PAPER"
    },
    {
      "code": "48025530",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : DUPLICATING PAPER"
    },
    {
      "code": "48025540",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : ACCOUNT BOOK PAPER"
    },
    {
      "code": "48025550",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : BANK, BOND AND CHEQUE PAPER"
    },
    {
      "code": "48025560",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : CURRENCY NOTE PAPER"
    },
    {
      "code": "48025570",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48025590",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN ROLLS : OTHER"
    },
    {
      "code": "480256",
      "description": "Weighing 40 g/m2 or more but not more than 150 g/m2, in sheets with one side not exceeding 435 mm and the other side not exceeding 297 mm in the unfolded state"
    },
    {
      "code": "48025610",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : LITHO AND OFFSET PAPER"
    },
    {
      "code": "48025620",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : DRAWING PAPER"
    },
    {
      "code": "48025630",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : DUPLICATING PAPER"
    },
    {
      "code": "48025640",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : ACCOUNT BOOK PAPER"
    },
    {
      "code": "48025650",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : BANK, BOND AND CHEQUE PAPER"
    },
    {
      "code": "48025660",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : CURRENCY NOTE PAPER"
    },
    {
      "code": "48025670",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48025690",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2, IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : OTHER"
    },
    {
      "code": "480257",
      "description": "Other, weighing 40 g/m2 or more but not more than 150 g/m2"
    },
    {
      "code": "48025710",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: LITHO AND OFFSET PAPER"
    },
    {
      "code": "48025720",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: DRAWING PAPER"
    },
    {
      "code": "48025730",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: DUPLICATING PAPER"
    },
    {
      "code": "48025740",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: ACCOUNT BOOK PAPER"
    },
    {
      "code": "48025750",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: BANK, BOND AND CHEQUE PAPER"
    },
    {
      "code": "48025760",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: CURRENCY NOTE PAPER"
    },
    {
      "code": "48025770",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48025790",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER, WEIGHING 40 G/M2OR MORE BUT NOT MORE THAN 150 G/M2: OTHER"
    },
    {
      "code": "480258",
      "description": "Weighing more than 150 g/m2"
    },
    {
      "code": "48025810",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: LITHO AND OFFSET PAPER"
    },
    {
      "code": "48025820",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: DRAWING PAPER"
    },
    {
      "code": "48025830",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: DUPLICATING PAPER"
    },
    {
      "code": "48025840",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: BANK, BOND AND CHEQUE PAPER"
    },
    {
      "code": "48025850",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48025890",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : WEIGHING MORE THAN 150 G/M2: OTHER"
    },
    {
      "code": "480261",
      "description": "In rolls"
    },
    {
      "code": "48026110",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : DRAWING PAPER"
    },
    {
      "code": "48026120",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : POSTER PAPER"
    },
    {
      "code": "48026130",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : PRINTING PAPER DYED OR MARBLED IN MASS"
    },
    {
      "code": "48026140",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : ACCOUNT BOOK PAPER"
    },
    {
      "code": "48026150",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : AUTOMATIC DATA PROCESSING MACHINE PAPER"
    },
    {
      "code": "48026160",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48026190",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN ROLLS : OTHER"
    },
    {
      "code": "480262",
      "description": "In sheets with one side not exceeding 435 mm and the other side not exceeding 297 mm in the unfolded state"
    },
    {
      "code": "48026210",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : DRAWING PAPER"
    },
    {
      "code": "48026220",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : POSTER PAPER"
    },
    {
      "code": "48026230",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : PRINTING PAPER DYED OR MARBLED IN MASS"
    },
    {
      "code": "48026240",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : ACCOUNT BOOK PAPER"
    },
    {
      "code": "48026250",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : AUTOMATIC DATA PROCESSING MACHINE PAPER"
    },
    {
      "code": "48026260",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48026290",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : OTHER"
    },
    {
      "code": "480269",
      "description": "Other"
    },
    {
      "code": "48026910",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : DRAWING PAPER"
    },
    {
      "code": "48026920",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : POSTER PAPER"
    },
    {
      "code": "48026930",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : PRINTING PAPER DYED OR MARBLED IN MASS"
    },
    {
      "code": "48026940",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : ACCOUNT BOOK PAPER"
    },
    {
      "code": "48026950",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : AUTOMATIC DATA PROCESSING MACHINE PAPER"
    },
    {
      "code": "48026960",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : PAPER FOR SECURITY PRINTING, CURRENCY PAPER, STAMP PAPER"
    },
    {
      "code": "48026990",
      "description": "UNCOATED PAPER AND PAPERBOARD, OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, AND NON-PERFORATED PUNCH CARD AND PUNCH TAPE PAPER, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS OF ANY SIZE, OTHER THAN PAPER OF HEADING 4801 OR 4803; HAND-MADE PAPER AND PAPERBOARD - OTHER PAPER AND PAPERBOARD, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER : OTHER"
    },
    {
      "code": "4803",
      "description": "TOILET OR FACIAL TISSUE STOCK, TOWEL OR NAPKIN STOCK AND SIMILAR PAPER OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, WHETHER OR NOT CREPED, CRINKLED, EMBOSSED,PERFORATED, SURFACE-COLOURED, SURFACEDECORATED OR PRINTED, IN ROLLS OR SHEETS"
    },
    {
      "code": "480300",
      "description": "Toilet or facial tissue stock, towel or napkin stock and similar paper of a kind used for household or sanitary purposes, cellulose wadding and webs of cellulose fibres, whether or not creped, crinkled, embossed, perforated, surface-coloured, surface-decorated or printed, in rolls or sheets"
    },
    {
      "code": "48030010",
      "description": "TOILET OR FACIAL TISSUE STOCK, TOWEL OR NAPKIN STOCK AND SIMILAR PAPER OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, WHETHER OR NOT CREPED, CRINKLED, EMBOSSED, PERFORATED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR SHEETS - TOILET OR FACIAL TISSUE STOCK, TOWEL OR NAPKIN STOCK AND SIMILAR PAPER OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, WHETHER OR NOT CREPED, CRINKLED, EMBOSSED, PERFORATED, SURFACE-COLOURED, SURFACEDECORATED OR PRINTED, IN ROLLS OR SHEETS : IN COMMERCIAL SIZEROLLS OF A WIDTH 36 CM AND ABOVE"
    },
    {
      "code": "48030090",
      "description": "TOILET OR FACIAL TISSUE STOCK, TOWEL OR NAPKIN STOCK AND SIMILAR PAPER OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, WHETHER OR NOT CREPED, CRINKLED, EMBOSSED, PERFORATED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR SHEETS - TOILET OR FACIAL TISSUE STOCK, TOWEL OR NAPKIN STOCK AND SIMILAR PAPER OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, WHETHER OR NOT CREPED, CRINKLED, EMBOSSED, PERFORATED, SURFACE-COLOURED, SURFACEDECORATED OR PRINTED, IN ROLLS OR SHEETS : OTHER"
    },
    {
      "code": "4804",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - Kraftliner :"
    },
    {
      "code": "48041100",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - KRAFTLINER : UNBLEACHED"
    },
    {
      "code": "48041900",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - KRAFTLINER : OTHER"
    },
    {
      "code": "48042100",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - SACK KRAFT PAPER : UNBLEACHED"
    },
    {
      "code": "48042900",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - SACK KRAFT PAPER : OTHER"
    },
    {
      "code": "48043100",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING 150 G/M2OR LESS: UNBLEACHED"
    },
    {
      "code": "48043900",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING 150 G/M2OR LESS: OTHER"
    },
    {
      "code": "48044100",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING MORE THAN 150 G/M2BUT LESS THAN 225 G/M2: UNBLEACHED"
    },
    {
      "code": "48044200",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING MORE THAN 150 G/M2BUT LESS THAN 225 G/M2: BLEACHED UNIFORMLY THROUGHOUT THE MASS AND OF WHICH MORE THAN 95% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF WOOD FIBRES OBTAINED BY A CHEMICAL PROCESS"
    },
    {
      "code": "48044900",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING MORE THAN 150 G/M2BUT LESS THAN 225 G/M2: OTHER"
    },
    {
      "code": "48045100",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING 225 G/M2OR MORE UNBLEACHED"
    },
    {
      "code": "48045200",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING 225 G/M2OR MORE BLEACHED UNIFORMLY THROUGHOUT THE MASS AND OF WHICH MORE THAN 95% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF WOOD FIBRES OBTAINED BY A CHEMICAL PROCESS"
    },
    {
      "code": "48045900",
      "description": "UNCOATED KRAFT PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, OTHER THAN THAT OF HEADING 4802 OR 4803 - OTHER KRAFT PAPER AND PAPERBOARD WEIGHING 225 G/M2OR MORE OTHER"
    },
    {
      "code": "4805",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - Fluting paper :"
    },
    {
      "code": "48051100",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - FLUTING PAPER : SEMI-CHEMICAL FLUTING PAPER"
    },
    {
      "code": "48051200",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - FLUTING PAPER : STRAW FLUTING PAPER"
    },
    {
      "code": "48051900",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - FLUTING PAPER : OTHER"
    },
    {
      "code": "48052400",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - TESTLINER (RECYCLED LINER BOARD) : WEIGHING 150 G/M2OR LESS"
    },
    {
      "code": "48052500",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - TESTLINER (RECYCLED LINER BOARD) : WEIGHING MORE THAN 150 G/M2"
    },
    {
      "code": "48053000",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - TESTLINER (RECYCLED LINER BOARD) : SULPHITE WRAPPING PAPER"
    },
    {
      "code": "48054000",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - TESTLINER (RECYCLED LINER BOARD) : FILTER PAPER AND PAPERBOARD"
    },
    {
      "code": "48055000",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - TESTLINER (RECYCLED LINER BOARD) : FELT PAPER AND PAPERBOARD"
    },
    {
      "code": "48059100",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - OTHER : WEIGHING 150 G/M2OR LESS"
    },
    {
      "code": "48059200",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - OTHER : WEIGHING MORE THAN 150 G/M2BUT LESS THAN 225 G/M2"
    },
    {
      "code": "48059300",
      "description": "OTHER UNCOATED PAPER AND PAPERBOARD, IN ROLLS OR SHEETS, NOT FURTHER WORKED OR PROCESSED THAN AS SPECIFIED IN NOTE 3 TO THIS CHAPTER - OTHER : WEIGHING 225 G/M2OR MORE"
    },
    {
      "code": "4806",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS"
    },
    {
      "code": "48061000",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS VEGETABLE PARCHMENT"
    },
    {
      "code": "48062000",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS GREASEPROOF PAPERS"
    },
    {
      "code": "48063000",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS TRACING PAPERS"
    },
    {
      "code": "480640",
      "description": "Glassine and other glazed transparent or translucent papers"
    },
    {
      "code": "48064010",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS - GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS : GLASSINE PAPERS"
    },
    {
      "code": "48064090",
      "description": "VEGETABLE PARCHMENT, GREASEPROOF PAPERS, TRACING PAPERS AND GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS, IN ROLLS OR SHEETS - GLASSINE AND OTHER GLAZED TRANSPARENT OR TRANSLUCENT PAPERS : OTHER"
    },
    {
      "code": "4807",
      "description": "COMPOSITE PAPER AND PAPERBOARD (MADE BY STICKING FLAT LAYERS OF PAPER OR PAPERBOARD TOGETHER WITH AN ADHESIVE), NOT SURFACECOATED OR IMPREGNATED, WHETHER OR NOT INTERNALLY REINFORCED, IN ROLLS OR SHEETS"
    },
    {
      "code": "480700",
      "description": "Composite paper and paperboard (made by sticking flat layers of paper or paperboard together with an adhesive), not surface-coated or impregnated, whether or not internally reinforced, in rolls or sheets"
    },
    {
      "code": "48070010",
      "description": "COMPOSITE PAPER AND PAPERBOARD (MADE BY STICKING FLAT LAYERS OF PAPER OR PAPERBOARD TOGETHER WITH AN ADHESIVE), NOT SURFACE-COATED OR IMPREGNATED, WHETHER OR NOT INTERNALLY REINFORCED, IN ROLLS OR SHEETS- COMPOSITE PAPER AND PAPERBOARD (MADE BY STICKING FLAT LAYERS OF PAPER OR PAPERBOARD TOGETHER WITH AN ADHESIVE), NOT SURFACE-COATED OR IMPREGNATED, WHETHER OR NOT INTERNALLY REINFORCED, IN ROLLS OR SHEETS : STRAW PAPER AND OTHER STRAW BOARD, WHETHER OR NOT COVERED WITH PAPER OTHER THAN STRAW PAPER"
    },
    {
      "code": "48070090",
      "description": "COMPOSITE PAPER AND PAPERBOARD (MADE BY STICKING FLAT LAYERS OF PAPER OR PAPERBOARD TOGETHER WITH AN ADHESIVE), NOT SURFACE-COATED OR IMPREGNATED, WHETHER OR NOT INTERNALLY REINFORCED, IN ROLLS OR SHEETS- COMPOSITE PAPER AND PAPERBOARD (MADE BY STICKING FLAT LAYERS OF PAPER OR PAPERBOARD TOGETHER WITH AN ADHESIVE), NOT SURFACE-COATED OR IMPREGNATED, WHETHER OR NOT INTERNALLY REINFORCED, IN ROLLS OR SHEETS : OTHER"
    },
    {
      "code": "4808",
      "description": "PAPER AND PAPERBOARD, CORRUGATED (WITH OR WITHOUT GLUED FLAT SURFACE SHEETS), CREPED, CRINKLED, EMBOSSED OR PERFORATED, IN ROLLS OR SHEETS, OTHER THAN PAPER OF THE KIND DESCRIBED IN HEADING 4803"
    },
    {
      "code": "480810",
      "description": "Kraft paper, creped or crinkled, whether or not embossed or Perforated"
    },
    {
      "code": "48081000",
      "description": "PAPER AND PAPERBOARD, CORRUGATED (WITH OR WITHOUT GLUED FLAT SURFACE SHEETS), CREPED, CRINKLED, EMBOSSED OR PERFORATED, IN ROLLS OR SHEETS, OTHER THAN PAPER OF THE KIND DESCRIBED IN HEADING 4803 - CORRUGATED PAPER AND PAPERBOARD, WHETHER OR NOT PERFORATE"
    },
    {
      "code": "480840",
      "description": "Kraft paper, creped or crinkled, whether or not embossed or perforated"
    },
    {
      "code": "48084010",
      "description": "PAPER AND PAPERBOARD, CORRUGATED (WITH OR WITHOUT GLUED FLAT SURFACE SHEETS), CREPED, CRINKLED, EMBOSSED OR PERFORATED, IN ROLLS OR SHEETS, OTHER THAN PAPER OF THE KIND DESCRIBED IN HEADING 4803 -KRAFT PAPER, CREPED OR CRINKLED, WHETHER OR NOT EMBOSSED OR PERFORATED--- SACK KRAFT PAPER, CREPED OR CRINKLED, WHETHER OR NOT EMBOSSED OR PERFORATED"
    },
    {
      "code": "48084090",
      "description": "PAPER AND PAPERBOARD, CORRUGATED (WITH OR WITHOUT GLUED FLAT SURFACE SHEETS), CREPED, CRINKLED, EMBOSSED OR PERFORATED, IN ROLLS OR SHEETS, OTHER THAN PAPER OF THE KIND DESCRIBED IN HEADING 4803 -KRAFT PAPER, CREPED OR CRINKLED, WHETHER OR NOT EMBOSSED OR PERFORATED--- OTHER KRAFT PAPER, CREPED OR CRINKLED, WHETHER OR NOT EMBOSSED OR PERFORATED"
    },
    {
      "code": "48089000",
      "description": "PAPER AND PAPERBOARD, CORRUGATED (WITH OR WITHOUT GLUED FLAT SURFACE SHEETS), CREPED, CRINKLED, EMBOSSED OR PERFORATED, IN ROLLS OR SHEETS, OTHER THAN PAPER OF THE KIND DESCRIBED IN HEADING 4803 - OTHER"
    },
    {
      "code": "4809",
      "description": "CARBON PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (INCLUDING COATED OR IMPREGNATED PAPER FOR DUPLICATOR STENCILS OR OFFSET PLATES), WHETHER OR NOT PRINTED, IN ROLLS OR SHEETS"
    },
    {
      "code": "48091010",
      "description": "CARBON PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (INCLUDING COATED OR IMPREGNATED PAPER FOR DUPLICATOR STENCILS OR OFFSET PLATES), WHETHER OR NOT PRINTED, IN ROLLS OR SHEETS - CARBON OR SIMILAR COPYING PAPERS : MANIFOLD PAPER"
    },
    {
      "code": "48091090",
      "description": "CARBON PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (INCLUDING COATED OR IMPREGNATED PAPER FOR DUPLICATOR STENCILS OR OFFSET PLATES), WHETHER OR NOT PRINTED, IN ROLLS OR SHEETS - CARBON OR SIMILAR COPYING PAPERS : OTHER"
    },
    {
      "code": "48092000",
      "description": "CARBON PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (INCLUDING COATED OR IMPREGNATED PAPER FOR DUPLICATOR STENCILS OR OFFSET PLATES), WHETHER OR NOT PRINTED, IN ROLLS OR SHEETS SELF-COPY PAPER"
    },
    {
      "code": "48099000",
      "description": "CARBON PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (INCLUDING COATED OR IMPREGNATED PAPER FOR DUPLICATOR STENCILS OR OFFSET PLATES), WHETHER OR NOT PRINTED, IN ROLLS OR SHEETS OTHER"
    },
    {
      "code": "4810",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - Paper and paperboard of a kind used for writing, printing or other graphic purposes, not containing fibres obtained by a mechanical or chemi-mechanical process or of which not more than 10% by weight of the total fibre content consists of such fibres :"
    },
    {
      "code": "481013",
      "description": "In rolls"
    },
    {
      "code": "48101310",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN ROLLS : IMITATION ART PAPER"
    },
    {
      "code": "48101320",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN ROLLS : ART PAPER"
    },
    {
      "code": "48101330",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN ROLLS : CHROME PAPER OR PAPERBOARD"
    },
    {
      "code": "48101390",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN ROLLS : OTHER"
    },
    {
      "code": "481014",
      "description": "In sheets with one side not exceeding 435 mm and the other side not exceeding 297 mm in the unfolded state"
    },
    {
      "code": "48101410",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : IMITATION ART PAPER"
    },
    {
      "code": "48101420",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : ART PAPER"
    },
    {
      "code": "48101430",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : CHROME PAPER OR PAPERBOARD"
    },
    {
      "code": "48101490",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : IN SHEETS WITH ONE SIDE NOT EXCEEDING 435 MM AND THE OTHER SIDE NOT EXCEEDING 297 MM IN THE UNFOLDED STATE : OTHER"
    },
    {
      "code": "481019",
      "description": "Other"
    },
    {
      "code": "48101910",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER : IMITATION ART PAPER"
    },
    {
      "code": "48101920",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER : ART PAPER"
    },
    {
      "code": "48101930",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER : CHROME PAPER OR PAPERBOARD"
    },
    {
      "code": "48101990",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, NOT CONTAINING FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS OR OF WHICH NOT MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF SUCH FIBRES : OTHER : OTHER"
    },
    {
      "code": "48102200",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : LIGHT-WEIGHT COATED PAPER"
    },
    {
      "code": "48102900",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - PAPER AND PAPERBOARD OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES, OF WHICH MORE THAN 10% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF FIBRES OBTAINED BY A MECHANICAL OR CHEMI-MECHANICAL PROCESS : OTHER"
    },
    {
      "code": "48103100",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : BLEACHED UNIFORMLY THROUGHOUT THE MASS AND OF WHICH MORE THAN 95% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF WOOD FIBRES OBTAINED BY A CHEMICAL PROCESS, AND WEIGHING 150 G/M2OR LESS"
    },
    {
      "code": "48103200",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : BLEACHED UNIFORMLY THROUGHOUT THE MASS AND OF WHICH MORE THAN 95% BY WEIGHT OF THE TOTAL FIBRE CONTENT CONSISTS OF WOOD FIBRES OBTAINED BY A CHEMICAL PROCESS, AND WEIGHING MORE THAN 150 G/M2"
    },
    {
      "code": "481039",
      "description": "Other"
    },
    {
      "code": "48103910",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : OTHER : INSULATING PAPER"
    },
    {
      "code": "48103920",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : OTHER : ELECTRIC INSULATING PRESS BOARD"
    },
    {
      "code": "48103930",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : OTHER : INSULATION BOARDS (HOMOGENOUS)"
    },
    {
      "code": "48103990",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - KRAFT PAPER AND PAPERBOARD, OTHER THAN THAT OF A KIND USED FOR WRITING, PRINTING OR OTHER GRAPHIC PURPOSES : OTHER : OTHER"
    },
    {
      "code": "48109200",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - OTHER PAPER AND PAPERBOARD : MULTI-PLY"
    },
    {
      "code": "48109900",
      "description": "PAPER AND PAPERBOARD, COATED ON ONE OR BOTH SIDES WITH KAOLIN (CHINA CLAY) OR OTHER INORGANIC SUBSTANCES, WITH OR WITHOUT A BINDER, AND WITH NO OTHER COATING, WHETHER OR NOT SURFACE - COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE - OTHER PAPER AND PAPERBOARD : OTHER"
    },
    {
      "code": "4811",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACEDECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810"
    },
    {
      "code": "48111000",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810 - TARRED, BITUMINISED OR ASPHALTED PAPER AND PAPERBOARD"
    },
    {
      "code": "48114100",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- GUMMED OR ADHESIVE PAPER AND PAPERBOARD : SELF-ADHESIVE"
    },
    {
      "code": "48114900",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- GUMMED OR ADHESIVE PAPER AND PAPERBOARD : OTHER"
    },
    {
      "code": "481151",
      "description": "Bleached weighing more than 150 g/sqm"
    },
    {
      "code": "48115110",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- PAPER AND PAPERBOARD, COATED, IMPREGNATED OR COVERED WITH PLASTICS (EXCLUDING ADHESIVES)--BLEACHED, WEIGHING MORE THAN 150 G/M---ASEPTIC PACKAGING PAPER"
    },
    {
      "code": "48115190",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- PAPER AND PAPERBOARD, COATED, IMPREGNATED OR COVERED WITH PLASTICS (EXCLUDING ADHESIVES)--BLEACHED, WEIGHING MORE THAN 150 G/M---OTHER"
    },
    {
      "code": "481159",
      "description": "Other"
    },
    {
      "code": "48115910",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- PAPER AND PAPERBOARD, COATED, IMPREGNATED OR COVERED WITH PLASTICS (EXCLUDING ADHESIVES)--OTHER---ASEPTIC PACKAGING PAPER"
    },
    {
      "code": "48115990",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- PAPER AND PAPERBOARD, COATED, IMPREGNATED OR COVERED WITH PLASTICS (EXCLUDING ADHESIVES)--OTHER---OTHER"
    },
    {
      "code": "48116000",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810 - PAPER AND PAPERBOARD, COATED, IMPREGNATED OR COVERED WITH WAX, PARAFFIN WAX, STEARINE, OIL OR GLYCEROL"
    },
    {
      "code": "481190",
      "description": "Other paper, paperboard, cellulose wadding and webs of cellulose fibres"
    },
    {
      "code": "48119011",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED"
    },
    {
      "code": "48119012",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : CHROMO AND ART PAPER, COATED"
    },
    {
      "code": "48119013",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : BUILDING BOARD OF PAPER OR PULP, IMPREGNATED"
    },
    {
      "code": "48119014",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : CHROMO BOARD"
    },
    {
      "code": "48119015",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : RAW BASE PAPER FOR SENSITISING, COATED"
    },
    {
      "code": "48119016",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : SURFACE MARBLED PAPER"
    },
    {
      "code": "48119017",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : LEATHER BOARD AND IMITATION LEATHER BOARD"
    },
    {
      "code": "48119018",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : HANDMADE PAPER AND PAPERBOARD, RULES, LINED OR SQUARED BUT NOT OTHERWISE PRINTED; CHROMO AND ART PAPER, COATED, BUILDING BOARD OF PAPER OR PULP, IMPREGNATED; CHROMO BOARD; RAW BASE PAPER FOR SENSITISING, COATED; SURFACE MARBLED PAPER; LEATHER BOARD AND IMITATION LEATHER BOARD; AND MATRIX BOARD : MATRIX BOARD"
    },
    {
      "code": "48119091",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : OTHER : GRAPE GUARD PAPER"
    },
    {
      "code": "48119093",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : OTHER : THERMAL PAPER FOR FAX MACHINES"
    },
    {
      "code": "48119094",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : OTHER : THERMAL PAPER IN JUMBO ROLLS (SIZE 1 MT AND ABOVE IN WIDTH AND 5,000 MT AND ABOVE IN LENGTH"
    },
    {
      "code": "48119099",
      "description": "PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, COATED, IMPREGNATED, COVERED, SURFACE-COLOURED, SURFACE-DECORATED OR PRINTED, IN ROLLS OR RECTANGULAR (INCLUDING SQUARE) SHEETS, OF ANY SIZE, OTHER THAN GOODS OF THE KIND DESCRIBED IN HEADING 4803, 4809 OR 4810- OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES : OTHER : OTHER"
    },
    {
      "code": "4812",
      "description": " FILTER BLOCKS, SLABS AND PLATES, OF PAPER PULP"
    },
    {
      "code": "48120000",
      "description": "FILTER BLOCKS, SLABS AND PLATES, OF PAPER PULP"
    },
    {
      "code": "4813",
      "description": "CIGARETTE PAPER, WHETHER OR NOT CUT TO SIZE OR IN THE FORM OF BOOKLETS OR TUBES"
    },
    {
      "code": "48131000",
      "description": "CIGARETTE PAPER, WHETHER OR NOT CUT TO SIZE OR IN THE FORM OF BOOKLETS OR TUBES IN THE FORM OF BOOKLETS OR TUBES"
    },
    {
      "code": "48132000",
      "description": "CIGARETTE PAPER, WHETHER OR NOT CUT TO SIZE OR IN THE FORM OF BOOKLETS OR TUBES IN ROLLS OF A WIDTH NOT EXCEEDING 5 CM"
    },
    {
      "code": "481390",
      "description": "Other"
    },
    {
      "code": "48139010",
      "description": "CIGARETTE PAPER, WHETHER OR NOT CUT TO SIZE OR IN THE FORM OF BOOKLETS OR TUBES - OTHER : CIGARETTE PAPER IN BULK, OR IN SHEETS"
    },
    {
      "code": "48139090",
      "description": "CIGARETTE PAPER, WHETHER OR NOT CUT TO SIZE OR IN THE FORM OF BOOKLETS OR TUBES - OTHER : OTHER"
    },
    {
      "code": "4814",
      "description": "WALLPAPER AND SIMILAR WALL COVERINGS; WINDOW TRANSPARENCIES OF PAPER"
    },
    {
      "code": "48142000",
      "description": "WALLPAPER AND SIMILAR WALL COVERINGS; WINDOW TRANSPARENCIES OF PAPER - WALLPAPER AND SIMILAR WALL COVERINGS, CONSISTING OF PAPER COATED OR COVERED, ON THE FACE SIDE, WITH A GRAINED, EMBOSSED, COLOURED, DESIGN-PRINTED OR OTHERWISE DECORATED LAYER OF PLASTICS"
    },
    {
      "code": "48143000",
      "description": "WALLPAPER AND SIMILAR WALL COVERINGS; WINDOW TRANSPARENCIES OF PAPER - WALLPAPER AND SIMILAR WALL COVERINGS, CONSISTING OF PAPER COVERED, ON THE FACE SIDE, WITH PLAITING MATERIAL, WHETHER OR NOT BOUND TOGETHER IN PARALLEL STRANDS OR WOVEN"
    },
    {
      "code": "48149000",
      "description": "WALLPAPER AND SIMILAR WALL COVERINGS; WINDOW TRANSPARENCIES OF PAPER - OTHER"
    },
    {
      "code": "4815",
      "description": "OMITTED"
    },
    {
      "code": "48150000",
      "description": "Omitted"
    },
    {
      "code": "4816",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES"
    },
    {
      "code": "48161000",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - CARBON OR SIMILAR COPYING PAPERS"
    },
    {
      "code": "481620",
      "description": "Self-copy paper"
    },
    {
      "code": "48162010",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - SELF-COPY PAPER : DUPLICATING PAPER, CUT TO SIZE"
    },
    {
      "code": "48162020",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - SELF-COPY PAPER : PAPER FOR FAX MACHINE"
    },
    {
      "code": "48162090",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - SELF-COPY PAPER : OTHER"
    },
    {
      "code": "48163000",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES DUPLICATOR STENCILS"
    },
    {
      "code": "481690",
      "description": "Other"
    },
    {
      "code": "48169010",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - OTHER : OTHER COPYING OR TRANSFER PAPERS (EXCLUDING PRINTED TRANSFER) CUT TO SIZE WHETHER OR NOT PUT UP IN BOXES"
    },
    {
      "code": "48169020",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - OTHER : CALCULATING MACHINE PAPER IN ROLLS AND STRIPS NOT EXCEEDING 15 CM IN WIDTH"
    },
    {
      "code": "48169090",
      "description": "CARBON-PAPER, SELF-COPY PAPER AND OTHER COPYING OR TRANSFER PAPERS (OTHER THAN THOSE OF HEADING 4809), DUPLICATOR STENCILS AND OFFSET PLATES, OF PAPER, WHETHER OR NOT PUT UP IN BOXES - OTHER : OTHER"
    },
    {
      "code": "4817",
      "description": "ENVELOPES, LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS, OF PAPER OR PAPERBOARD; BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY"
    },
    {
      "code": "48171000",
      "description": "ENVELOPES, LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS, OF PAPER OR PAPERBOARD; BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY - ENVELOPES"
    },
    {
      "code": "48172000",
      "description": "ENVELOPES, LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS, OF PAPER OR PAPERBOARD; BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY - LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS"
    },
    {
      "code": "481730",
      "description": "Boxes, pouches, wallets and writing compendiums, of paper or paperboard, containing an assortment of paper stationery"
    },
    {
      "code": "48173010",
      "description": "ENVELOPES, LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS, OF PAPER OR PAPERBOARD; BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY - BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY: WRITING BLOCKS"
    },
    {
      "code": "48173090",
      "description": "ENVELOPES, LETTER CARDS, PLAIN POSTCARDS AND CORRESPONDENCE CARDS, OF PAPER OR PAPERBOARD; BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY - BOXES, POUCHES, WALLETS AND WRITING COMPENDIUMS, OF PAPER OR PAPERBOARD, CONTAINING AN ASSORTMENT OF PAPER STATIONERY: OTHER"
    },
    {
      "code": "4818",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES"
    },
    {
      "code": "48181000",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, NAPKINS FOR BABIES, TAMPONS, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - TOILET PAPER"
    },
    {
      "code": "48182000",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, NAPKINS FOR BABIES, TAMPONS, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - HANDKERCHIEFS, CLEANING OR FACIAL TISSUES AND TOWEL"
    },
    {
      "code": "48183000",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, NAPKINS FOR BABIES, TAMPONS, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - TABLECLOTHS AND SERVIETTES"
    },
    {
      "code": "481840",
      "description": "Sanitary towels and tampons, napkins and napkin liners for babies and similar sanitary articles"
    },
    {
      "code": "48185000",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, NAPKINS FOR BABIES, TAMPONS, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES ARTICLES OF APPAREL AND CLOTHING ACCESSORIES"
    },
    {
      "code": "48189000",
      "description": "TOILET PAPER AND SIMILAR PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES, OF A KIND USED FOR HOUSEHOLD OR SANITARY PURPOSES, IN ROLLS OF A WIDTH NOT EXCEEDING 36 CM, OR CUT TO SIZE OR SHAPE; HANDKERCHIEFS, CLEANSING TISSUES, TOWELS, TABLE CLOTHS, SERVIETTES, NAPKINS FOR BABIES, TAMPONS, BED SHEETS AND SIMILAR HOUSEHOLD, SANITARY OR HOSPITAL ARTICLES, ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, OF PAPER PULP, PAPER, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES OTHER"
    },
    {
      "code": "4819",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE"
    },
    {
      "code": "481910",
      "description": "Cartons, boxes and cases, of corrugated paper or paperboard"
    },
    {
      "code": "48191010",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE 4819 10 CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE- CARTONS, BOXES AND CASES, OF CORRUGATED PAPER OR PAPERBOARD : BOXES"
    },
    {
      "code": "48191090",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE 4819 10 CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE- CARTONS, BOXES AND CASES, OF CORRUGATED PAPER OR PAPERBOARD : OTHER"
    },
    {
      "code": "481920",
      "description": "Folding cartons, boxes and cases, of non-corrugated paper and paperboard"
    },
    {
      "code": "48192010",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE- FOLDING CARTONS, BOXES AND CASES, OF NONCORRUGATED PAPER AND PAPERBOARD : CARTONS, BOXES, CASES, INTENDED FOR THE PACKING OF MATCH STICKS"
    },
    {
      "code": "48192020",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE- FOLDING CARTONS, BOXES AND CASES, OF NONCORRUGATED PAPER AND PAPERBOARD : BOXES"
    },
    {
      "code": "48192090",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE- FOLDING CARTONS, BOXES AND CASES, OF NONCORRUGATED PAPER AND PAPERBOARD : OTHER"
    },
    {
      "code": "48193000",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE - SACKS AND BAGS, HAVING A BASE OF A WIDTH OF 40 CM OR MORE"
    },
    {
      "code": "48194000",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE - OTHER SACKS AND BAGS, INCLUDING CONES"
    },
    {
      "code": "481950",
      "description": "Other packing containers, including record sleeves"
    },
    {
      "code": "48195010",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE - OTHER PACKING CONTAINERS, INCLUDING RECORD SLEEVES : MADE OF CORRUGATED PAPER OR PAPERBOARD"
    },
    {
      "code": "48195090",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE - OTHER PACKING CONTAINERS, INCLUDING RECORD SLEEVES : OTHER"
    },
    {
      "code": "48196000",
      "description": "CARTONS, BOXES, CASES, BAGS AND OTHER PACKING CONTAINERS, OF PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES; BOX FILES, LETTER TRAYS, AND SIMILAR ARTICLES, OF PAPER OR PAPERBOARD OF A KIND USED IN OFFICES, SHOPS OR THE LIKE - BOX FILES, LETTER TRAYS, STORAGE BOXES AND SIMILAR ARTICLES, OF A KIND USED IN OFFICES, SHOPS OR THE LIKE"
    },
    {
      "code": "4820",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSELEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD"
    },
    {
      "code": "482010",
      "description": "Registers, account books, note books, order books, receipt books, letter pads, memorandum pads, diaries and similar articles"
    },
    {
      "code": "48201010",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD- REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES : REGISTERS, ACCOUNT BOOKS"
    },
    {
      "code": "48201020",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD- REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES : LETTER PADS"
    },
    {
      "code": "48201090",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD- REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES : OTHER"
    },
    {
      "code": "48202000",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARDEXERCISE BOOKS"
    },
    {
      "code": "48203000",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARDBINDERS (OTHER THAN BOOK COVERS), FOLDERS AND FILE COVERS"
    },
    {
      "code": "48204000",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARDMANIFOLD BUSINESS FORMS AND INTERLEAVED CARBON SETS"
    },
    {
      "code": "48205000",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARDALBUMS FOR SAMPLES OR FOR COLLECTIONS"
    },
    {
      "code": "482090",
      "description": "Other"
    },
    {
      "code": "48209010",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD - OTHER: BLOTTING PAPERS CUT TO SIZE"
    },
    {
      "code": "48209090",
      "description": "REGISTERS, ACCOUNT BOOKS, NOTE BOOKS, ORDER BOOKS, RECEIPT BOOKS, LETTER PADS, MEMORANDUM PADS, DIARIES AND SIMILAR ARTICLES, EXCISE BOOKS, BLOTTING-PADS, BINDERS (LOOSE-LEAF OR OTHER), FOLDERS, FILE COVERS, MANIFOLD BUSINESS FORMS, INTERLEAVED CARBON SETS AND OTHER ARTICLES OF STATIONERY, OF PAPER OR PAPERBOARD; ALBUMS FOR SAMPLES OR FOR COLLECTIONS AND BOOK COVERS, OF PAPER OR PAPERBOARD - OTHER: OTHER"
    },
    {
      "code": "4821",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED"
    },
    {
      "code": "482110",
      "description": "Printed"
    },
    {
      "code": "48211010",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED - PRINTED : PAPER TAGS"
    },
    {
      "code": "48211020",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED - PRINTED : LABELS"
    },
    {
      "code": "48211090",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED - PRINTED : OTHER"
    },
    {
      "code": "482190",
      "description": "Other"
    },
    {
      "code": "48219010",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED - OTHER: LABELS"
    },
    {
      "code": "48219090",
      "description": "PAPER OR PAPERBOARD LABELS OF ALL KINDS, WHETHER OR NOT PRINTED - OTHER: OTHER"
    },
    {
      "code": "4822",
      "description": "BOBBINS, SPOOLS, COPS AND SIMILAR SUPPORTS OF PAPER PULP, PAPER OR PAPERBOARD (WHETHER OR NOT PERFORATED OR HARDENED)"
    },
    {
      "code": "48221000",
      "description": "BOBBINS, SPOOLS, COPS AND SIMILAR SUPPORTS OF PAPER PULP, PAPER OR PAPERBOARD (WHETHER OR NOT PERFORATED OR HARDENED) - OF A KIND USED FOR WINDING TEXTILE YARN"
    },
    {
      "code": "482290",
      "description": "Other"
    },
    {
      "code": "48229010",
      "description": "BOBBINS, SPOOLS, COPS AND SIMILAR SUPPORTS OF PAPER PULP, PAPER OR PAPERBOARD (WHETHER OR NOT PERFORATED OR HARDENED) - OTHER : PAPER TUBES"
    },
    {
      "code": "48229090",
      "description": "BOBBINS, SPOOLS, COPS AND SIMILAR SUPPORTS OF PAPER PULP, PAPER OR PAPERBOARD (WHETHER OR NOT PERFORATED OR HARDENED) - OTHER : OTHER"
    },
    {
      "code": "4823",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBERS, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPERPULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBERS"
    },
    {
      "code": "48231200",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - GUMMED OR ADHESIVE PAPER, IN STRIPS OR ROLLS : SELF-ADHESIVE"
    },
    {
      "code": "48231900",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - GUMMED OR ADHESIVE PAPER, IN STRIPS OR ROLLS : OTHER"
    },
    {
      "code": "48232000",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - FILTER PAPER AND PAPERBOARD"
    },
    {
      "code": "48234000",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - ROLLS, SHEETS AND DIALS, PRINTED FOR SELF-RECORDING APPARATUS"
    },
    {
      "code": "48236000",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - TRAYS, DISHES, PLATES, CUPS AND THE LIKE, OF PAPER AND PAPERBOARD"
    },
    {
      "code": "48236100",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - TRAYS,DISHES,PLATES, CUPS AND THE LIKE, OF PAPER OR PAPERBOARD : OF BAMBOO"
    },
    {
      "code": "48236900",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - TRAYS,DISHES,PLATES, CUPS AND THE LIKE, OF PAPER OR PAPERBOARD : OTHER"
    },
    {
      "code": "482370",
      "description": "Moulded or pressed article of paper pulp"
    },
    {
      "code": "48237010",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - MOULDED OR PRESSED ARTICLE OF PAPER PULP : PAPER PULP MOULDED TRAYS"
    },
    {
      "code": "48237020",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - MOULDED OR PRESSED ARTICLE OF PAPER PULP : WOOD PULP BOARD"
    },
    {
      "code": "48237030",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - MOULDED OR PRESSED ARTICLE OF PAPER PULP : ARTICLES MADE OF PAPER MACHE OTHER THAN ARTWARE AND MOULDED OR PRESSED GOODS OF WOOD PULP"
    },
    {
      "code": "48237090",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - MOULDED OR PRESSED ARTICLE OF PAPER PULP : OTHER"
    },
    {
      "code": "482390",
      "description": "Other"
    },
    {
      "code": "48239011",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : BRAILLE PAPER"
    },
    {
      "code": "48239012",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : CELLULOSE IN SOLE BOARD OR SHEET"
    },
    {
      "code": "48239013",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PACKING AND WRAPPING PAPER"
    },
    {
      "code": "48239014",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PAPER FOR CIGARETTE FILTER TIPS"
    },
    {
      "code": "48239015",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PAPER CONE FOR LOUD SPEAKER"
    },
    {
      "code": "48239016",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS"
    },
    {
      "code": "48239017",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES"
    },
    {
      "code": "48239018",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS CONTAINING MELAMINE, PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS"
    },
    {
      "code": "48239019",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : BRAILLE PAPER, CELLULOSE IN SOLE BOARD OR SHEET; PACKING AND WRAPPING PAPER; PAPER FOR CIGARETTE FILTER TIPS; PAPER CONE FOR LOUD SPEAKER; PATTERNS MADE OF PAPERS FOR LEATHER FOOTWEAR, LEATHER GARMENTS AND GOODS; PATTERNS MADE OF PAPER FOR ARTICLES OF APPAREL AND CLOTHING ACCESSORIES, PRODUCTS CONSISTING OF SHEETS OF PAPER OR PAPERBOARD, IMPREGNATED, COATED OR COVERED WITH PLASTICS (INCLUDING THERMOSET RESINS OR MIXTURES THEREOF OR CHEMICAL FORMULATIONS, CONTAINING MELAMINE PHENOL OR UREA FORMALDEHYDE WITH OR WITHOUT CURING AGENTS OR CATALYSTS), COMPRESSED TOGETHER IN ONE OR MORE OPERATIONS; DECORATIVE LAMINATES : DECORATIVE LAMINATES"
    },
    {
      "code": "48239021",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : PRE-PUNCHED CARDS; MONOTYPE AND NEWSTAPE PAPER IN STRIPS WITH PERFORATED EDGES, NOT EXCEEDING 15 CM IN WIDTH; TYPEWRITING PAPER CUT TO SIZE AND THE LIKE : PRE-PUNCHED CARDS"
    },
    {
      "code": "48239022",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : PRE-PUNCHED CARDS; MONOTYPE AND NEWSTAPE PAPER IN STRIPS WITH PERFORATED EDGES, NOT EXCEEDING 15 CM IN WIDTH; TYPEWRITING PAPER CUT TO SIZE AND THE LIKE : MONOTYPE AND NEWSTAPE PAPER IN STRIPS WITH PERFORATED EDGES, NOT EXCEEDING 15CM IN WIDTH"
    },
    {
      "code": "48239023",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : PRE-PUNCHED CARDS; MONOTYPE AND NEWSTAPE PAPER IN STRIPS WITH PERFORATED EDGES, NOT EXCEEDING 15 CM IN WIDTH; TYPEWRITING PAPER CUT TO SIZE AND THE LIKE : TYPEWRITING PAPER CUT TO SIZE"
    },
    {
      "code": "48239030",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : PLAIN OR EMBOSSED SEALS MADE OF PAPER, LAMINATED PAPER OR PAPER GASKETS"
    },
    {
      "code": "48239090",
      "description": "OTHER PAPER, PAPERBOARD, CELLULOSE WADDING AND WEBS OF CELLULOSE FIBRES, CUT TO SIZE OR SHAPE; OTHER ARTICLES OF PAPER PULP, PAPER, PAPERBOARD, CELLULOSE WADDING OR WEBS OF CELLULOSE FIBRES - OTHER : OTHER"
    },
    {
      "code": "49",
      "description": "Printed books, newspapers, pictures and other products of the printing industry; manuscripts, typescripts and plans"
    },
    {
      "code": "4901",
      "description": "PRINTED BOOKS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER, WHETHER OR NOT IN SINGLE SHEETS"
    },
    {
      "code": "490110",
      "description": "In single sheets, whether or not folded"
    },
    {
      "code": "49011010",
      "description": "PRINTED BOOKS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER, WHETHER OR NOT IN SINGLE SHEETS IN SINGLE SHEETS, WHETHER OR NOT FOLDED : PRINTED BOOKS"
    },
    {
      "code": "49011020",
      "description": "PRINTED BOOKS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER, WHETHER OR NOT IN SINGLE SHEETS IN SINGLE SHEETS, WHETHER OR NOT FOLDED : PAMPHLETS, BOOKLETS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER"
    },
    {
      "code": "49019100",
      "description": "PRINTED BOOKS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER, WHETHER OR NOT IN SINGLE SHEETS - OTHER : DICTIONARIES AND ENCYCLOPAEDIAS, AND SERIAL INSTALMENTS THEREOF"
    },
    {
      "code": "49019900",
      "description": "PRINTED BOOKS, BROCHURES, LEAFLETS AND SIMILAR PRINTED MATTER, WHETHER OR NOT IN SINGLE SHEETS - OTHER : OTHER"
    },
    {
      "code": "4902",
      "description": "NEWSPAPERS, JOURNALS AND PERIODICALS, WHETHER OR NOT I LLUSTRATED OR CONTAINING ADVERTISING MATERIAL"
    },
    {
      "code": "490210",
      "description": "Appearing at least four times a week"
    },
    {
      "code": "49021010",
      "description": "NEWSPAPERS, JOURNALS AND PERIODICALS, WHETHER OR NOT ILLUSTRATED OR CONTAINING ADVERTISING MATERIAL APPEARING AT LEAST FOUR TIMES A WEEK : NEWSPAPERS"
    },
    {
      "code": "49021020",
      "description": "NEWSPAPERS, JOURNALS AND PERIODICALS, WHETHER OR NOT ILLUSTRATED OR CONTAINING ADVERTISING MATERIAL APPEARING AT LEAST FOUR TIMES A WEEK : JOURNALS AND PERIODICALS"
    },
    {
      "code": "490290",
      "description": "Other"
    },
    {
      "code": "49029010",
      "description": "NEWSPAPERS, JOURNALS AND PERIODICALS, WHETHER OR NOT ILLUSTRATED OR CONTAINING ADVERTISING MATERIAL OTHER : NEWSPAPERS"
    },
    {
      "code": "49029020",
      "description": "NEWSPAPERS, JOURNALS AND PERIODICALS, WHETHER OR NOT ILLUSTRATED OR CONTAINING ADVERTISING MATERIAL OTHER : JOURNALS AND PERIODICALS"
    },
    {
      "code": "4903",
      "description": "CHILDREN'S PICTURE, DRAWING OR COLOURING BOOKS"
    },
    {
      "code": "490300",
      "description": "Childrens picture, drawing or colouring books"
    },
    {
      "code": "49030010",
      "description": "CHILDREN'S PICTURE, DRAWING OR COLOURING BOOKS CHILDREN'S PICTURE, DRAWING OR COLOURING BOOKS : PICTURE BOOKS"
    },
    {
      "code": "49030020",
      "description": "CHILDREN'S PICTURE, DRAWING OR COLOURING BOOKS CHILDREN'S PICTURE, DRAWING OR COLOURING BOOKS : DRAWING OR COLOURING BOOKS"
    },
    {
      "code": "4904",
      "description": "MUSIC, PRINTED OR IN MANUSCRIPT, WHETHER OR NOT FOUND OR ILLUSTRATED"
    },
    {
      "code": "49040000",
      "description": "MUSIC, PRINTED OR IN MANUSCRIPT, WHETHER OR NOT BOUND OR ILLUSTRATED"
    },
    {
      "code": "4905",
      "description": "MAPS AND HYDROGRAPHIC OR SIMILAR CHARTS OF ALL KINDS, INCLUDING ATLASES, WALL MAPS, TOPOGRAPHICAL PLANS AND GLOBES, PRINTED"
    },
    {
      "code": "49051000",
      "description": "MAPS AND HYDROGRAPHIC OR SIMILAR CHARTS OF ALL KINDS, INCLUDING ATLASES, WALL MAPS, TOPOGRAPHICAL PLANS AND GLOBES, PRINTED GLOBES"
    },
    {
      "code": "49059100",
      "description": "MAPS AND HYDROGRAPHIC OR SIMILAR CHARTS OF ALL KINDS, INCLUDING ATLASES, WALL MAPS, TOPOGRAPHICAL PLANS AND GLOBES, PRINTED - OTHER : IN BOOK FORM"
    },
    {
      "code": "490599",
      "description": "Other"
    },
    {
      "code": "49059910",
      "description": "MAPS AND HYDROGRAPHIC OR SIMILAR CHARTS OF ALL KINDS, INCLUDING ATLASES, WALL MAPS, TOPOGRAPHICAL PLANS AND GLOBES, PRINTED - OTHER : - OTHER: GEOGRAPHICAL, HYDROLOGICAL, ASTRONOMICAL MAPS OR CHARTS"
    },
    {
      "code": "49059990",
      "description": "MAPS AND HYDROGRAPHIC OR SIMILAR CHARTS OF ALL KINDS, INCLUDING ATLASES, WALL MAPS, TOPOGRAPHICAL PLANS AND GLOBES, PRINTED - OTHER : - OTHER: OTHER"
    },
    {
      "code": "4906",
      "description": "PLANS AND DRAWINGS FOR ARCHITECTURAL, ENGINEERING, INDUSTRIAL, COMMERCIAL, TOPOGRAPHICAL OR SIMILAR PURPOSES, BEING ORIGINALS DRAWN BY HAND; HAND-WRITTEN TEXTS; PHOTOGRAPHIC REPRODUCTIONS ON SENSITISED PAPER AND CARBON COPIES OF THE FOREGOING"
    },
    {
      "code": "49060000",
      "description": "PLANS AND DRAWINGS FORARCHITECTURAL,ENGINEERING,INDUSTRIAL, COMMERCIAL,TOPOGRAPHICAL OR SIMILARPURPOSES,BEING ORIGINALS RAWN BY HAND; HANDWRITTENTEXTS; PHOTOGRAPHIC REPRODUCTIONS NSENSITISED PAPER AND CARBON COPIES OF THE FOREGOING"
    },
    {
      "code": "4907",
      "description": "UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE; STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUE FORMS; STOCK, SHARE OR BOND CERTIFICATES AND SIMILAR DOCUMENTS OF TITLE"
    },
    {
      "code": "490700",
      "description": "Unused postage, revenue or similar stamps of current or new issue in the country in which they have, or will have, a recognized face value; stamp-impressed paper; bank notes; cheque forms; stock, share or bond certificates and similar documents of title"
    },
    {
      "code": "49070010",
      "description": "UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE; STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUE FORMS; STOCK, SHARE OR BOND CERTIFICATES AND SIMILAR DOCUMENTS OF TITLE UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OFCURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEYHAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE;STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUEFORMS; STOCK, SHARE OR BOND CERTIFICATES ANDSIMILAR DOCUMENTS OF TITLE : UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE"
    },
    {
      "code": "49070020",
      "description": "UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE; STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUE FORMS; STOCK, SHARE OR BOND CERTIFICATES AND SIMILAR DOCUMENTS OF TITLE UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OFCURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEYHAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE;STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUEFORMS; STOCK, SHARE OR BOND CERTIFICATES ANDSIMILAR DOCUMENTS OF TITLE : BANK NOTES"
    },
    {
      "code": "49070030",
      "description": "UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE; STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUE FORMS; STOCK, SHARE OR BOND CERTIFICATES AND SIMILAR DOCUMENTS OF TITLE UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OFCURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEYHAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE;STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUEFORMS; STOCK, SHARE OR BOND CERTIFICATES ANDSIMILAR DOCUMENTS OF TITLE : DOCUMENTS OF TITLE CONVEYING THE RIGHT TO USE INFORMATION TECHNOLOGY SOFTWARE"
    },
    {
      "code": "49070090",
      "description": "UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OF CURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEY HAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE; STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUE FORMS; STOCK, SHARE OR BOND CERTIFICATES AND SIMILAR DOCUMENTS OF TITLE UNUSED POSTAGE, REVENUE OR SIMILAR STAMPS OFCURRENT OR NEW ISSUE IN THE COUNTRY IN WHICH THEYHAVE, OR WILL HAVE, A RECOGNIZED FACE VALUE;STAMP-IMPRESSED PAPER; BANK NOTES; CHEQUEFORMS; STOCK, SHARE OR BOND CERTIFICATES ANDSIMILAR DOCUMENTS OF TITLE : OTHER"
    },
    {
      "code": "4908",
      "description": "TRANSFERS (DECALCOMANIAS)"
    },
    {
      "code": "49081000",
      "description": "TRANSFERS (DECALCOMANIAS) TRANSFERS (DECALCOMANIAS), VITRIFIABLE"
    },
    {
      "code": "49089000",
      "description": "TRANSFERS (DECALCOMANIAS) OTHER"
    },
    {
      "code": "4909",
      "description": "PRINTED OR ILLUSTRATED POSTCARDS; PRINTED CARDS BEARING PERSONAL GREETINGS, MESSAGES OR ANNOUNCEMENTS, WHETHER OR NOT ILLUSTRATED, WITH OR WITHOUT ENVELOPES OR TRIMMINGS"
    },
    {
      "code": "490900",
      "description": "Printed or illustrated postcards; printed cards bearing personal greetings, messages or announcements, whether or not illustrated, with or without envelopes or trimmings"
    },
    {
      "code": "49090010",
      "description": "PRINTED OR ILLUSTRATED POSTCARDS; PRINTED CARDS BEARING PERSONAL GREETINGS, MESSAGES OR ANNOUNCEMENTS, WHETHER OR NOT ILLUSTRATED, WITH OR WITHOUT ENVELOPES OR TRIMMINGS PRINTED OR ILLUSTRATED POSTCARDS; PRINTED CARDS BEARING PERSONAL GREETINGS, MESSAGES OR ANNOUNCEMENTS, WHETHER OR NOT ILLUSTRATED, WITH OR WITHOUT ENVELOPES OR TRIMMINGS : GREETING OR WEDDING CARDS"
    },
    {
      "code": "49090090",
      "description": "PRINTED OR ILLUSTRATED POSTCARDS; PRINTED CARDS BEARING PERSONAL GREETINGS, MESSAGES OR ANNOUNCEMENTS, WHETHER OR NOT ILLUSTRATED, WITH OR WITHOUT ENVELOPES OR TRIMMINGS PRINTED OR ILLUSTRATED POSTCARDS; PRINTED CARDS BEARING PERSONAL GREETINGS, MESSAGES OR ANNOUNCEMENTS, WHETHER OR NOT ILLUSTRATED, WITH OR WITHOUT ENVELOPES OR TRIMMINGS : OTHER"
    },
    {
      "code": "4910",
      "description": "CALENDARS OF ANY KIND, PRINTED, INCLUDING CALENDAR BLOCKS"
    },
    {
      "code": "491000",
      "description": "Calendars of any kind, printed, including calendar blocks"
    },
    {
      "code": "49100010",
      "description": "CALENDARS OF ANY KIND, PRINTED, INCLUDING CALENDAR BLOCKS - CALENDARS OF ANY KIND, PRINTED, INCLUDING CALENDAR BLOCKS : ADVERTISING CALENDAR"
    },
    {
      "code": "49100090",
      "description": "CALENDARS OF ANY KIND, PRINTED, INCLUDING CALENDAR BLOCKS - CALENDARS OF ANY KIND, PRINTED, INCLUDING CALENDAR BLOCKS : OTHER"
    },
    {
      "code": "4911",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS"
    },
    {
      "code": "491110",
      "description": "Trade advertising material, commercial catalogues and the like"
    },
    {
      "code": "49111010",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS TRADE ADVERTISING MATERIAL, COMMERCIAL CATALOGUES AND THE LIKE : POSTERS, PRINTED"
    },
    {
      "code": "49111020",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS TRADE ADVERTISING MATERIAL, COMMERCIAL CATALOGUES AND THE LIKE : COMMERCIAL CATALOGUES"
    },
    {
      "code": "49111030",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS TRADE ADVERTISING MATERIAL, COMMERCIAL CATALOGUES AND THE LIKE : PRINTED INLAY CARDS"
    },
    {
      "code": "49111090",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS TRADE ADVERTISING MATERIAL, COMMERCIAL CATALOGUES AND THE LIKE : OTHER"
    },
    {
      "code": "49119100",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS - OTHER : PICTURES, DESIGNS AND PHOTOGRAPHS"
    },
    {
      "code": "491199",
      "description": "Other"
    },
    {
      "code": "49119910",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS - OTHER : OTHER : HARD COPY (PRINTED) OF COMPUTER SOFTWARE"
    },
    {
      "code": "49119920",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS - OTHER : OTHER : PLAN AND DRAWINGS FOR ARCHITECTURAL ENGINEERING, INDUSTRIAL, COMMERCIAL, TOPOGRAPHICAL OR SIMILAR PURPOSES REPRODUCED WITH THE AID OF COMPUTER OR ANY OTHER DEVICES"
    },
    {
      "code": "49119990",
      "description": "OTHER PRINTED MATTER, INCLUDING PRINTED PICTURES AND PHOTOGRAPHS - OTHER : OTHER : OTHER"
    },
    {
      "code": "50",
      "description": "Textiles And Textile Articles"
    },
    {
      "code": "5001",
      "description": "SILK-WORM COCOONS SUITABLE FOR REELING"
    },
    {
      "code": "50010000",
      "description": "SILK-WORM COCOONS SUITABLE FOR REELING"
    },
    {
      "code": "5002",
      "description": "RAW SILK (NOT THROWN)"
    },
    {
      "code": "500200",
      "description": "Raw silk (not thrown)"
    },
    {
      "code": "50020010",
      "description": "RAW SILK (NOT THROWN) RAW SILK (NOT THROWN) MULBERRY RAW SILK"
    },
    {
      "code": "50020020",
      "description": "RAW SILK (NOT THROWN) RAW SILK (NOT THROWN) MULBERRY DUPION SILK"
    },
    {
      "code": "50020030",
      "description": "RAW SILK (NOT THROWN) RAW SILK (NOT THROWN) NON-MULBERRY SILK"
    },
    {
      "code": "5003",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK)"
    },
    {
      "code": "500300",
      "description": "Silk waste (including cocoons unsuitable for reeling, yarn waste and garneted stock)"
    },
    {
      "code": "50030010",
      "description": "MULBERRY SILK WASTE"
    },
    {
      "code": "50030020",
      "description": "TUSSAR SILK WASTE"
    },
    {
      "code": "50030030",
      "description": "DRI WASTE"
    },
    {
      "code": "50030040",
      "description": "MUNGA WASTE"
    },
    {
      "code": "50030090",
      "description": "SILK WASTE - OTHER"
    },
    {
      "code": "50031010",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) NOT CARDED OR COMBED MULBERRY SILK WASTE"
    },
    {
      "code": "50031020",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) NOT CARDED OR COMBED TUSSAR WASTE"
    },
    {
      "code": "50031090",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) NOT CARDED OR COMBED OTHER"
    },
    {
      "code": "50039010",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) - OTHER : MULBERRY SILK WASTE"
    },
    {
      "code": "50039020",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) - OTHER : TUSSAR WASTE"
    },
    {
      "code": "50039030",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) - OTHER : ERI WASTE"
    },
    {
      "code": "50039040",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) - OTHER : MUNGA WASTE"
    },
    {
      "code": "50039090",
      "description": "SILK WASTE (INCLUDING COCOONS UNSUITABLE FOR REELING, YARN WASTE AND GARNETTED STOCK) - OTHER : OTHER"
    },
    {
      "code": "5004",
      "description": "SILK YARN (OTHER THAN YARN SPUN FROM SILK WASTE) NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "500400",
      "description": "Silk yarn (other than yarn spun from silk waste) not put up for retail sale"
    },
    {
      "code": "50040010",
      "description": "(OTHER THAN YARN SPUN FROM SILK WASTE) NOT PUT UP FOR RETAIL SALE : 100 PERCENT MULBERRY DUPION SILK YARN"
    },
    {
      "code": "50040090",
      "description": "SILK YARN (OTHER THAN YARN SPUN FROM SILK WASTE) NOT PUT UP FOR RETAIL SALE SILK YARN (OTHER THAN YARN SPUN FROM SILK WASTE) NOT PUT UP FOR RETAIL SALE : OTHER"
    },
    {
      "code": "5005",
      "description": "YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "500500",
      "description": "Yarn spun from silk waste, not put up for retail sale"
    },
    {
      "code": "50050011",
      "description": "YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE: - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK WASTE: OTHER THAN NOIL SILK"
    },
    {
      "code": "50050012",
      "description": "YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE: - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK WASTE: FROM NOIL SILK"
    },
    {
      "code": "50050021",
      "description": "YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE: - CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SILK : OTHER THAN NOIL SILK"
    },
    {
      "code": "50050022",
      "description": "YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE YARN SPUN FROM SILK WASTE, NOT PUT UP FOR RETAIL SALE: - CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SILK : FROM NOIL SILK"
    },
    {
      "code": "5006",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT"
    },
    {
      "code": "500600",
      "description": "Silk yarn and yarn spun from silk waste, put up for retail sale; silk worm gut"
    },
    {
      "code": "50060011",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - SILK YARN : SILK EMBROIDERY THREAD"
    },
    {
      "code": "50060019",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - SILK YARN : OTHER"
    },
    {
      "code": "50060021",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK : SILK EMBROIDERY THREAD"
    },
    {
      "code": "50060029",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK : OTHER"
    },
    {
      "code": "50060031",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR LESS BY WEIGHT OF SILK : SPUN FROM SILK WASTE OTHER THAN NOIL SILK"
    },
    {
      "code": "50060032",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR LESS BY WEIGHT OF SILK : YARN SPUN FROM NOIL SILK"
    },
    {
      "code": "50060033",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR LESS BY WEIGHT OF SILK : SILK EMBROIDERY THREAD"
    },
    {
      "code": "50060039",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: - YARN SPUN FROM SILK WASTE CONTAINING 85 PERCENT OR LESS BY WEIGHT OF SILK : OTHER"
    },
    {
      "code": "50060090",
      "description": "SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK-WORM GUT SILK YARN AND YARN SPUN FROM SILK WASTE, PUT UP FOR RETAIL SALE; SILK WORM GUT: OTHER"
    },
    {
      "code": "5007",
      "description": "WOVEN FABRICS OF SILK OR OF SILK WASTE"
    },
    {
      "code": "50071000",
      "description": "WOVEN FABRICS OF SILK OR OF SILK WASTE FABRICS OF NOIL SILK"
    },
    {
      "code": "500720",
      "description": "Other fabrics, containing 85% or more by weight of silk or of silk waste other than noil silk"
    },
    {
      "code": "50072010",
      "description": "WOVEN FABRICS OF SILK OR OF SILK WASTE OTHER FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK OR OF SILK WASTE OTHER THAN NOIL SILK: SAREES"
    },
    {
      "code": "50072090",
      "description": "WOVEN FABRICS OF SILK OR OF SILK WASTE OTHER FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF SILK OR OF SILK WASTE OTHER THAN NOIL SILK: OTHER"
    },
    {
      "code": "500790",
      "description": "Other"
    },
    {
      "code": "50079010",
      "description": "OTHER HANDLOOM WOVEN FABRICS"
    },
    {
      "code": "50079090",
      "description": "WOVEN FABRICS OF SILK OR OF SILK WASTE - OTHER FABRICS"
    },
    {
      "code": "504004",
      "description": "Guts other than for natural food castings"
    },
    {
      "code": "504005",
      "description": "Bladders and stomachs"
    },
    {
      "code": "505902",
      "description": "Other feather (excluding for stuffing purpose)"
    },
    {
      "code": "505903",
      "description": "Powder and waste of feathers or parts of feathers"
    },
    {
      "code": "505909",
      "description": "Skins and other parts"
    },
    {
      "code": "506101",
      "description": "Bones, including horn-cores, crushed"
    },
    {
      "code": "506102",
      "description": "Bone grist"
    },
    {
      "code": "506103",
      "description": "Ossein"
    },
    {
      "code": "506104",
      "description": "Bones,horn-cones and parts thereof, not crushed"
    },
    {
      "code": "506901",
      "description": "Bone meal"
    },
    {
      "code": "506909",
      "description": "Other"
    },
    {
      "code": "51",
      "description": "Wool, fine or coarse animal hair; horsehair yarn and woven fabric"
    },
    {
      "code": "5100",
      "description": "Other"
    },
    {
      "code": "5101",
      "description": "WOOL, NOT CARDED OR COMBED : - Greasy, including fleece-washed wool :"
    },
    {
      "code": "51011100",
      "description": "WOOL, NOT CARDED OR COMBED : GREASY, INCLUDING FLEECE-WASHED WOOL : SHORN WOOL"
    },
    {
      "code": "51011900",
      "description": "WOOL, NOT CARDED OR COMBED : GREASY, INCLUDING FLEECE-WASHED WOOL : OTHER"
    },
    {
      "code": "51012100",
      "description": "WOOL, NOT CARDED OR COMBED : DEGREASED, NOT CARBONIZED SHORN WOOL"
    },
    {
      "code": "51012900",
      "description": "WOOL, NOT CARDED OR COMBED : DEGREASED, NOT CARBONIZED OTHER"
    },
    {
      "code": "51013000",
      "description": "WOOL, NOT CARDED OR COMBED : CARBONISED"
    },
    {
      "code": "5102",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED - Fine animal hair :"
    },
    {
      "code": "510211",
      "description": "Of Kashmir (cashmere) goats"
    },
    {
      "code": "51021110",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED FINE ANIMAL HAIR : OF KASHMIR (CASHMERE) GOATS : MARINE ANGORA"
    },
    {
      "code": "51021190",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED FINE ANIMAL HAIR : OF KASHMIR (CASHMERE) GOATS : OTHER"
    },
    {
      "code": "510219",
      "description": "Other"
    },
    {
      "code": "51021910",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED FINE ANIMAL HAIR :: OTHER : MARINE ANGORA"
    },
    {
      "code": "51021990",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED FINE ANIMAL HAIR : OTHER : OTHER"
    },
    {
      "code": "510220",
      "description": "Coarse animal hair"
    },
    {
      "code": "51022010",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED COARSE ANIMAL HAIR : GOAT HAIR (OTHER THAN ANGORA)"
    },
    {
      "code": "51022090",
      "description": "FINE OR COARSE ANIMAL HAIR, NOT CARDED OR COMBED COARSE ANIMAL HAIR : OTHER (EXCLUDING PIG AND BOAR BRISTLES)"
    },
    {
      "code": "5103",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK"
    },
    {
      "code": "510310",
      "description": "Noils of wool or of fine animal hair"
    },
    {
      "code": "51031010",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK NOILS OF WOOL OR OF FINE ANIMAL HAIR : NOILS OF WOOL"
    },
    {
      "code": "51031090",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK NOILS OF WOOL OR OF FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "510320",
      "description": "Other waste of wool or of fine animal hair"
    },
    {
      "code": "51032010",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK OTHER WASTE OF WOOL OR OF FINE ANIMAL HAIR : WASTE OF SHEEP'S AND LAMB'S WOOL"
    },
    {
      "code": "51032020",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK OTHER WASTE OF WOOL OR OF FINE ANIMAL HAIR : YARN WASTE"
    },
    {
      "code": "51032090",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK OTHER WASTE OF WOOL OR OF FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "51033000",
      "description": "WASTE OF WOOL OR OF FINE OR COARSE ANIMAL HAIR, INCLUDING YARN WASTE BUT EXCLUDING GARNETTED STOCK WASTE OF COARSE ANIMAL HAIR"
    },
    {
      "code": "5104",
      "description": "GARNETTED STOCK OF WOOL OR OF FINE OR COARSE ANIMAL HAIR"
    },
    {
      "code": "510400",
      "description": "Garnetted stock of wool or of fine or coarse animal hair"
    },
    {
      "code": "51040010",
      "description": "GARNETTED STOCK OF WOOL OR OF FINE OR COARSE ANIMAL HAIR - GARNETTED STOCK OF WOOL OR OF FINE OR COARSE ANIMAL HAIR : SHODDY WOOL"
    },
    {
      "code": "51040090",
      "description": "GARNETTED STOCK OF WOOL OR OF FINE OR COARSE ANIMAL HAIR - GARNETTED STOCK OF WOOL OR OF FINE OR COARSE ANIMAL HAIR : OTHER"
    },
    {
      "code": "5105",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS)"
    },
    {
      "code": "51051000",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) CARDED WOOL"
    },
    {
      "code": "51052100",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) - WOOL TOPS AND OTHER COMBED WOOL : COMBED WOOL IN FRAGMENTS"
    },
    {
      "code": "510529",
      "description": "Other"
    },
    {
      "code": "51052910",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) - WOOL TOPS AND OTHER COMBED WOOL : OTHER : WOOL TOPS"
    },
    {
      "code": "51052990",
      "description": "OOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) - WOOL TOPS AND OTHER COMBED WOOL : OTHER : OTHER"
    },
    {
      "code": "51053100",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) - FINE ANIMAL HAIR, CARDED OR COMBED : OF KASHMIR (CASHMERE) GOATS"
    },
    {
      "code": "51053900",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) - FINE ANIMAL HAIR, CARDED OR COMBED : OTHER"
    },
    {
      "code": "51054000",
      "description": "WOOL AND FINE OR COARSE ANIMAL HAIR, CARDED OR COMBED (INCLUDING COMBED WOOL IN FRAGMENTS) COARSE ANIMAL HAIR, CARDED OR COMBED"
    },
    {
      "code": "5106",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "510610",
      "description": "Containing 85% or more by weight of wool"
    },
    {
      "code": "51061010",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF WOOL : SHODDY WOOLLEN YARN"
    },
    {
      "code": "51061020",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : MUNGA WOOLLEN YARN"
    },
    {
      "code": "51061090",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : OTHER"
    },
    {
      "code": "510620",
      "description": "Containing less than 85% by weight of wool"
    },
    {
      "code": "51062010",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF WOOL : SHODDY WOOLLEN YARN"
    },
    {
      "code": "51062020",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF WOOL : MUNGA WOOLLEN YARN"
    },
    {
      "code": "51062090",
      "description": "YARN OF CARDED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF WOOL : OTHER"
    },
    {
      "code": "5107",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "510710",
      "description": "CONTAINING 85% OR MORE BY WEIGHT OF WOOL"
    },
    {
      "code": "51071010",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : WORSTED HOSIERY YARN"
    },
    {
      "code": "51071020",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : WORSTED KNITTED YARN"
    },
    {
      "code": "51071030",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : WORSTED WEAVING YARN"
    },
    {
      "code": "51071040",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : WOOLLEN CARPET YARN"
    },
    {
      "code": "51071090",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL : OTHER"
    },
    {
      "code": "510720",
      "description": "Containing less than 85% by weight of wool"
    },
    {
      "code": "51072010",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF WOOL : WORSTED HOSIERY YARN"
    },
    {
      "code": "51072020",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT  BY WEIGHT OF WOOL : WORSTED KNITTED YARN"
    },
    {
      "code": "51072030",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT  BY WEIGHT OF WOOL : WORSTED WEAVING YARN"
    },
    {
      "code": "51072040",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT  BY WEIGHT OF WOOL : WOOLLEN CARPET YARN"
    },
    {
      "code": "51072090",
      "description": "YARN OF COMBED WOOL, NOT PUT UP FOR RETAIL SALE CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF WOOL : OTHER"
    },
    {
      "code": "5108",
      "description": "YARN OF FINE ANIMAL HAIR (CARDED OR COMBED), NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "51081000",
      "description": "YARN OF FINE ANIMAL HAIR (CARDED OR COMBED), NOT PUT UP FOR RETAIL SALE CARDED"
    },
    {
      "code": "51082000",
      "description": "YARN OF FINE ANIMAL HAIR (CARDED OR COMBED), NOT PUT UP FOR RETAIL SALE COMBED"
    },
    {
      "code": "5109",
      "description": "YARN OF WOOL OR FINE ANIMAL HAIR, PUT UP FOR RETAIL SALE"
    },
    {
      "code": "510910",
      "description": "Containing 85% or more by weight of wool or of fine animal hair"
    },
    {
      "code": "51091010",
      "description": "YARN OF WOOL OR FINE ANIMAL HAIR, PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : HOISERY WOOL"
    },
    {
      "code": "51091090",
      "description": "YARN OF WOOL OR FINE ANIMAL HAIR, PUT UP FOR RETAIL SALE CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "51099000",
      "description": "YARN OF WOOL OR FINE ANIMAL HAIR, PUT UP FOR RETAIL SALE OTHER"
    },
    {
      "code": "5110",
      "description": "YARN OF COARSE ANIMAL HAIR OR OF HORSE HAIR (INCLUDING GIMPED HORSEHAIR YARN), WHETHER OR NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "511000",
      "description": "Yarn of coarse animal hair or of horsehair (including gimped horsehair yarn), whether or not put up for retail sale"
    },
    {
      "code": "51100010",
      "description": "YARN OF COARSE ANIMAL HAIR OR OF HORSE HAIR (INCLUDING GIMPED HORSEHAIR YARN), WHETHER OR NOT PUT UP FOR RETAIL SALE YARN OF COARSE ANIMAL HAIR OR OF HORSE-HAIR (INCLUDING GIMPED HORSEHAIR YARN), WHETHER OR NOT PUT UP FOR RETAIL SALE : PUT UP FOR RETAIL SALE"
    },
    {
      "code": "51100020",
      "description": "YARN OF COARSE ANIMAL HAIR OR OF HORSE HAIR (INCLUDING GIMPED HORSEHAIR YARN), WHETHER OR NOT PUT UP FOR RETAIL SALE YARN OF COARSE ANIMAL HAIR OR OF HORSE-HAIR (INCLUDING GIMPED HORSEHAIR YARN), WHETHER OR NOT PUT UP FOR RETAIL SALE : OTHER"
    },
    {
      "code": "5111",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - Containing 85% or more by weight of wool or of fine animal hair :"
    },
    {
      "code": "511111",
      "description": "Of a weight not exceeding 300 g/ m2"
    },
    {
      "code": "51111110",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 300 G/ M2: UNBLEACHED"
    },
    {
      "code": "51111120",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 300 G/ M2: BLEACHED"
    },
    {
      "code": "51111130",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 300 G/ M2: DYED"
    },
    {
      "code": "51111140",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 300 G/ M2: PRINTED"
    },
    {
      "code": "51111190",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 300 G/ M2: OTHER"
    },
    {
      "code": "511119",
      "description": "Other"
    },
    {
      "code": "51111910",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : UNBLEACHED"
    },
    {
      "code": "51111920",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : BLEACHED"
    },
    {
      "code": "51111930",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : DYED"
    },
    {
      "code": "51111940",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : PRINTED"
    },
    {
      "code": "51111990",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR - CONTAINING 85 PERCENT OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : OTHER"
    },
    {
      "code": "511120",
      "description": "Other, mixed mainly or solely with man-made filaments"
    },
    {
      "code": "51112010",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : UNBLEACHED"
    },
    {
      "code": "51112020",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : BLEACHED"
    },
    {
      "code": "51112030",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : DYED"
    },
    {
      "code": "51112040",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : PRINTED"
    },
    {
      "code": "51112090",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : OTHER"
    },
    {
      "code": "511130",
      "description": "Other, mixed mainly or solely with man-made staple fibres"
    },
    {
      "code": "51113010",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "51113020",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "51113030",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : DYED"
    },
    {
      "code": "51113040",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : PRINTED"
    },
    {
      "code": "51113090",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : OTHER"
    },
    {
      "code": "511190",
      "description": "Other"
    },
    {
      "code": "51119010",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER : UNBLEACHED"
    },
    {
      "code": "51119020",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER : BLEACHED"
    },
    {
      "code": "51119030",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER : DYED"
    },
    {
      "code": "51119040",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER : PRINTED"
    },
    {
      "code": "51119090",
      "description": "WOVEN FABRICS OF CARDED WOOL OR OF CARDED FINE ANIMAL HAIR OTHER : OTHER"
    },
    {
      "code": "5112",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - Containing 85% or more by weight of wool or of fine animal hair :"
    },
    {
      "code": "511211",
      "description": "Of a weight not exceeding 200 g/m2"
    },
    {
      "code": "51121110",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 200 G/M2: UNBLEACHED"
    },
    {
      "code": "51121120",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 200 G/M2: BLEACHED"
    },
    {
      "code": "51121130",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 200 G/M2: DYED"
    },
    {
      "code": "51121140",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 200 G/M2: PRINTED"
    },
    {
      "code": "51121190",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OF A WEIGHT NOT EXCEEDING 200 G/M2: OTHER"
    },
    {
      "code": "511219",
      "description": "Other"
    },
    {
      "code": "51121910",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : UNBLEACHED"
    },
    {
      "code": "51121920",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : BLEACHED"
    },
    {
      "code": "51121930",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : DYED"
    },
    {
      "code": "51121940",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : PRINTED"
    },
    {
      "code": "51121990",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR - CONTAINING 85% OR MORE BY WEIGHT OF WOOL OR OF FINE ANIMAL HAIR : OTHER : OTHER"
    },
    {
      "code": "511220",
      "description": "Other, mixed mainly or solely with man-made filaments"
    },
    {
      "code": "51122010",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS: UNBLEACHED"
    },
    {
      "code": "51122020",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS: BLEACHED"
    },
    {
      "code": "51122030",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS: DYED"
    },
    {
      "code": "51122040",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS: PRINTED"
    },
    {
      "code": "51122090",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS: OTHER"
    },
    {
      "code": "511230",
      "description": "Other, mixed mainly or solely with man-made staple fibres"
    },
    {
      "code": "51123010",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "51123020",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "51123030",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : DYED"
    },
    {
      "code": "51123040",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : PRINTED"
    },
    {
      "code": "51123090",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER, MIXED MAINLY OR SOLELY WITH MAN-MADE STAPLE FIBRES : OTHER"
    },
    {
      "code": "511290",
      "description": "Other"
    },
    {
      "code": "51129010",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER : UNBLEACHED"
    },
    {
      "code": "51129020",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER : BLEACHED"
    },
    {
      "code": "51129030",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER : DYED"
    },
    {
      "code": "51129040",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER : PRINTED"
    },
    {
      "code": "51129050",
      "description": "WOVEN FABRICS OF COMBED WOOL, OR OF COMBED FINE ANIMAL HAIR -OF HANDLOOM"
    },
    {
      "code": "51129090",
      "description": "WOVEN FABRICS OF COMBED WOOL OR OF COMBED FINE ANIMAL HAIR OTHER : OTHER"
    },
    {
      "code": "5113",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR of horse hair"
    },
    {
      "code": "511300",
      "description": "Woven fabrics of coarse animal hair or of horse hair"
    },
    {
      "code": "51130010",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR : UNBLEACHED"
    },
    {
      "code": "51130020",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR : BLEACHED"
    },
    {
      "code": "51130030",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR : DYED"
    },
    {
      "code": "51130040",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR : PRINTED"
    },
    {
      "code": "51130090",
      "description": "WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR WOVEN FABRICS OF COARSE ANIMAL HAIR OR OF HORSE HAIR : OTHER"
    },
    {
      "code": "511991",
      "description": "Silkworm pupae"
    },
    {
      "code": "511992",
      "description": "Sinews and tendons"
    },
    {
      "code": "511999",
      "description": "Other"
    },
    {
      "code": "52",
      "description": "Cotton"
    },
    {
      "code": "5201",
      "description": "COTTON, NOT CARDED OR COMBED"
    },
    {
      "code": "520100",
      "description": "Cotton, not carded or combed"
    },
    {
      "code": "52010011",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : BENGAL DESHI"
    },
    {
      "code": "52010012",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : INDIAN COTTON OF STAPLE LENGTHS 20.5 MM (25/32\") AND BELOW (E. G. OOMRAS, YELLOW PICKING, ASSAM COMILLAS)"
    },
    {
      "code": "52010013",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : INDIAN COTTON OF STAPLE LENGTH EXCEEDING 20.5MM (26/32\") BUT NOT EXCEEDING 24.5MM (30/32\")"
    },
    {
      "code": "52010014",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : INDIAN COTTON OF STAPLE LENGTH OVER 24.5 MM(31/32) TO 28 MM"
    },
    {
      "code": "52010015",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : INDIAN COTTON OF STAPLE LENGTH 28.5 (14/32) AND ABOVE BUT BELOW 34.5 MM MM"
    },
    {
      "code": "52010019",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : INDIAN COTTON : INDIAN COTTON OF ALL STAPLE LENGTH 34.5 AND ABOVE (112/32) MM"
    },
    {
      "code": "52010020",
      "description": "COTTON, NOT CARDED OR COMBED COTTON, NOT CARDED OR COMBED : COTTON, OTHER THAN INDIAN, OF ALL STAPLE LENGTHS"
    },
    {
      "code": "5202",
      "description": "COTTON WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK)"
    },
    {
      "code": "52021000",
      "description": "COTTON WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) YARN WASTE (INCLUDING THREAD WASTE)"
    },
    {
      "code": "52029100",
      "description": "COTTON WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER :GARNETTED STOCK"
    },
    {
      "code": "52029900",
      "description": "COTTON WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER :OTHER"
    },
    {
      "code": "5203",
      "description": "COTTON, CARDED OR COMBED"
    },
    {
      "code": "52030000",
      "description": "COTTON, CARDED OR COMBED"
    },
    {
      "code": "5204",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - Not put up for retail sale :"
    },
    {
      "code": "520411",
      "description": "Containing 85% or more by weight of cotton"
    },
    {
      "code": "52041110",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUTUP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : CONTAINING 85% OR MORE BY WEIGHT OFCOTTON : COTTON THREAD, SEWING,CONTAINING ANY SYNTHETIC STAPLE FIBRE"
    },
    {
      "code": "52041120",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUTUP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : CONTAINING 85% OR MORE BY WEIGHT OF COTTON : COTTON THREAD, DARNING"
    },
    {
      "code": "52041130",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : CONTAINING 85% OR MORE BY WEIGHT OF COTTON : EMBROIDERY COTTON THREAD"
    },
    {
      "code": "52041140",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : CONTAINING 85% OR MORE BY WEIGHT OF COTTON : COTTON SEWING THREAD, NOT CONTAINING ANY SYNTHETIC STAPLE FIBRE"
    },
    {
      "code": "52041190",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : CONTAINING 85% OR MORE BY WEIGHT OF COTTON : OTHER"
    },
    {
      "code": "52041900",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - NOT PUT UP FOR RETAIL SALE : OTHER"
    },
    {
      "code": "520420",
      "description": "Put up for retail sale"
    },
    {
      "code": "52042010",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - PUT UP FOR RETAIL SALE : COTTON THREAD, SEWING, CONTAINING ANY SYNTHETIC STAPLE FIBRE"
    },
    {
      "code": "52042020",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - PUT UP FOR RETAIL SALE : COTTON THREAD, DARNING"
    },
    {
      "code": "52042030",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - PUT UP FOR RETAIL SALE : EMBROIDERY COTTON THREAD"
    },
    {
      "code": "52042040",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - PUT UP FOR RETAIL SALE : COTTON SEWING THREAD, NOT CONTAINING ANY SYNTHETIC STAPLE FIBRE"
    },
    {
      "code": "52042090",
      "description": "COTTON SEWING THREAD, WHETHER OR NOT PUT UP FOR RETAIL SALE - PUT UP FOR RETAIL SALE : OTHER"
    },
    {
      "code": "5205",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - Single yarn, of uncombed fibres :"
    },
    {
      "code": "520511",
      "description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)"
    },
    {
      "code": "52051110",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : GREY"
    },
    {
      "code": "52051120",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52051130",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : DYED"
    },
    {
      "code": "52051190",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER -OTHER"
    },
    {
      "code": "520512",
      "description": "Measuring less than 714.29 decitex but not less than 232.56 decitex (exceeding 14 metric number but not exceeding 43 metric number)"
    },
    {
      "code": "52051210",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) GREY"
    },
    {
      "code": "52051220",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) BLEACHED"
    },
    {
      "code": "52051230",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) DYED"
    },
    {
      "code": "52051290",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) OTHER"
    },
    {
      "code": "520513",
      "description": "Measuring less than 232.56 decitex but not less than 192 .31 decitex (exceeding 43 metric number but not exceeding 52 metric number)"
    },
    {
      "code": "52051310",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192 .31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : GREY"
    },
    {
      "code": "52051320",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192 .31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52051330",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192 .31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : DYED"
    },
    {
      "code": "52051390",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192 .31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520514",
      "description": "Measuring less than 192.31 decitex but not less than 125 decitex (exceeding 52 metric number but not exceeding 80 metric number)"
    },
    {
      "code": "52051410",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : GREY"
    },
    {
      "code": "52051420",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52051430",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : DYED"
    },
    {
      "code": "52051490",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD),CONTAINING 85% OR MORE BY WEIGHT OF COTTON,NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520515",
      "description": "Measuring less than 125 decitex (exceeding 80 metric number)"
    },
    {
      "code": "52051510",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER) : GREY"
    },
    {
      "code": "52051520",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52051530",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER) : DYED"
    },
    {
      "code": "52051590",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520521",
      "description": "Measuring 714.29 decitex or more (not exceeding 14 metric number)"
    },
    {
      "code": "52052110",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : GREY"
    },
    {
      "code": "52052120",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052130",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : DYED"
    },
    {
      "code": "52052190",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520522",
      "description": "Measuring less than 714.29 decitex but not less than 232.56 decitex (exceeding 14 metric number but not exceeding 43 metric number)"
    },
    {
      "code": "52052210",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) : GREY"
    },
    {
      "code": "52052220",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052290",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520523",
      "description": "Measuring less than 232.56 decitex but not less than 192.31 decitex (exceeding 43 metric number but not exceeding 52 metric number)"
    },
    {
      "code": "52052310",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : GREY"
    },
    {
      "code": "52052320",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052390",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520524",
      "description": "Measuring less than 192.31 decitex but not less than 125 decitex (exceeding 52 metric number but not exceeding 80 metric number)"
    },
    {
      "code": "52052410",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : GREY"
    },
    {
      "code": "52052420",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052490",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520526",
      "description": "Measuring less than 125 decitex but not less than 106.38 decitex (exceeding 80 metric number but not exceeding 94 metric number)"
    },
    {
      "code": "52052610",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER) : DYED"
    },
    {
      "code": "52052620",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052690",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520527",
      "description": "Measuring less than 106.38 decitex but not less than 83.33 decitex (exceeding 94 metric number but not exceeding 120 metric number)"
    },
    {
      "code": "52052710",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC NUMBER) : DYED"
    },
    {
      "code": "52052720",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052790",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520528",
      "description": "Measuring less than 83.33 decitex (exceeding 120 metric number)"
    },
    {
      "code": "52052810",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER) : DYED"
    },
    {
      "code": "52052820",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER) : BLEACHED"
    },
    {
      "code": "52052890",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER) : OTHER"
    },
    {
      "code": "520531",
      "description": "Measuring per single yarn 714.29 decitex or more (not exceeding 14 metric number per single yarn)"
    },
    {
      "code": "52053110",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52053120",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52053130",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52053190",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520532",
      "description": "Measuring per single yarn less than 714.29 decitex but not less than 232.56 decitex (exceeding 14 metric number but not exceeding 43 metric number per single yarn)"
    },
    {
      "code": "52053210",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52053220",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52053290",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520533",
      "description": "Measuring per single yarn less than 232.56 decitex but not less than 192.31 decitex (exceeding 43 metric number but not exceeding 52 metric number per single yarn)"
    },
    {
      "code": "52053310",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52053320",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52053330",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52053390",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520534",
      "description": "Measuring per single yarn less than 192.31 decitex but not less than 125 decitex (exceeding 52 metric number but not exceeding 80 metric number per single yarn)"
    },
    {
      "code": "52053410",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52053420",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52053430",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52053490",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520535",
      "description": "Measuring per single yarn less than 125 decitex (exceeding 80 metric number per single yarn)"
    },
    {
      "code": "52053510",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52053590",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520541",
      "description": "Measuring per single yarn 714.29 decitex or more (not exceeding 14 metric number per single yarn)"
    },
    {
      "code": "52054110",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52054120",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52054130",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52054190",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520542",
      "description": "Measuring per single yarn less than 714.29 decitex but not less than 232.56 decitex (exceeding 14 metric number but not exceeding 43 metric number per single yarn)"
    },
    {
      "code": "52054210",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52054290",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520543",
      "description": "Measuring per single yarn less than 232.56 decitex but not less than192.31 decitex (exceeding 43 metric number but not exceeding 52 metric number per single yarn)"
    },
    {
      "code": "52054310",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52054320",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52054390",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520544",
      "description": "Measuring per single yarn less than 192.31 decitex but not less than 125 decitex (exceeding 52 metric number but not exceeding 80 metric number per single yarn)"
    },
    {
      "code": "52054410",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) :GREY"
    },
    {
      "code": "52054420",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) :BLEACHED"
    },
    {
      "code": "52054490",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN) :OTHER"
    },
    {
      "code": "520546",
      "description": "Measuring per single yarn less than 125 decitex but not less than 106.38 decitex (exceeding 80 metric number but not exceeding 94 metric number per single yarn)"
    },
    {
      "code": "52054610",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER PER SINGLE YARN) :GREY"
    },
    {
      "code": "52054620",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER PER SINGLE YARN) :BLEACHED"
    },
    {
      "code": "52054630",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER PER SINGLE YARN) :DYED"
    },
    {
      "code": "52054690",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX BUT NOT LESS THAN 106.38 DECITEX (EXCEEDING 80 METRIC NUMBER BUT NOT EXCEEDING 94 METRIC NUMBER PER SINGLE YARN) :OTHER"
    },
    {
      "code": "520547",
      "description": "Measuring per single yarn less than 106.38 decitex but not less than 83.33 decitex (exceeding 94 metric number but not exceeding 120 metric number per single yarn)"
    },
    {
      "code": "52054710",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC 272 NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52054720",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC 272 NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52054730",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC 272 NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52054790",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 106.38 DECITEX BUT NOT LESS THAN 83.33 DECITEX (EXCEEDING 94 METRIC NUMBER BUT NOT EXCEEDING 120 METRIC 272 NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "520548",
      "description": "Measuring per single yarn less than 83.33 decitex (exceeding 120 metric number per single yarn)"
    },
    {
      "code": "52054810",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER PER SINGLE YARN) : GREY"
    },
    {
      "code": "52054820",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER PER SINGLE YARN) : BLEACHED"
    },
    {
      "code": "52054830",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER PER SINGLE YARN) : DYED"
    },
    {
      "code": "52054890",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING 85% OR MORE BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 83.33 DECITEX (EXCEEDING 120 METRIC NUMBER PER SINGLE YARN) : OTHER"
    },
    {
      "code": "5206",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - Single yarn, of uncombed fibres :"
    },
    {
      "code": "52061100",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER)"
    },
    {
      "code": "52061200",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX (EXCEEDING 14 METRICNUMBER BUT NOT EXCEEDING 43 METRIC NUMBER)"
    },
    {
      "code": "52061300",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRICNUMBER BUT NOT EXCEEDING 52 METRIC NUMBER)"
    },
    {
      "code": "52061400",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRIC NUMBERBUT NOT EXCEEDING 80 METRIC NUMBER)"
    },
    {
      "code": "52061500",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN, OF UNCOMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER)"
    },
    {
      "code": "52062100",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER)"
    },
    {
      "code": "52062200",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX(EXCEEDING 14 METRICNUMBER BUT NOT EXCEEDING 43 METRIC NUMBER)"
    },
    {
      "code": "52062300",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX(EXCEEDING 43 METRICNUMBER BUT NOT EXCEEDING 52 METRIC NUMBER)"
    },
    {
      "code": "52062400",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX (EXCEEDING 52 METRICNUMBER BUT NOT EXCEEDING 80 METRIC NUMBER)"
    },
    {
      "code": "52062500",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - SINGLE YARN OF COMBED FIBRES : MEASURING LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER)"
    },
    {
      "code": "52063100",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52063200",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX(EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING 43 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52063300",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX(EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52063400",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX(EXCEEDING 52 METRIC NUMBER BUT NOTEXCEEDING 80 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52063500",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF UNCOMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBERPER SINGLE YARN)"
    },
    {
      "code": "52064100",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN 714.29 DECITEX OR MORE (NOT EXCEEDING 14 METRIC NUMBERPER SINGLE YARN)"
    },
    {
      "code": "52064200",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 714.29 DECITEX BUT NOT LESS THAN 232.56 DECITEX(EXCEEDING 14 METRIC NUMBER BUT NOT EXCEEDING43 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52064300",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 232.56 DECITEX BUT NOT LESS THAN 192.31 DECITEX (EXCEEDING 43 METRIC NUMBER BUT NOT EXCEEDING 52 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52064400",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 192.31 DECITEX BUT NOT LESS THAN 125 DECITEX(EXCEEDING 52 METRIC NUMBER BUT NOT EXCEEDING 80 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "52064500",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD), CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, NOT PUT UP FOR RETAIL SALE - MULTIPLE (FOLDED) OR CABLED YARN, OF COMBED FIBRES : MEASURING PER SINGLE YARN LESS THAN 125 DECITEX (EXCEEDING 80 METRIC NUMBER PER SINGLE YARN)"
    },
    {
      "code": "5207",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD) PUT UP FOR RETAIL SALE"
    },
    {
      "code": "52071000",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD) PUT UP FOR RETAIL SALE CONTAINING 85% OR MORE BY WEIGHT OF COTTON"
    },
    {
      "code": "52079000",
      "description": "COTTON YARN (OTHER THAN SEWING THREAD) PUT UP FOR RETAIL SALE OTHER"
    },
    {
      "code": "5208",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2 - Unbleached :"
    },
    {
      "code": "520811",
      "description": "Plain weave, weighing not more than 100 g/m2"
    },
    {
      "code": "52081110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : DHOTI"
    },
    {
      "code": "52081120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : SAREE"
    },
    {
      "code": "52081130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : SHIRTING FABRICS"
    },
    {
      "code": "52081140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : CASEMENT"
    },
    {
      "code": "52081190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : OTHER"
    },
    {
      "code": "520812",
      "description": "Plain weave, weighing more than 100 g/m2"
    },
    {
      "code": "52081210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :DHOTI"
    },
    {
      "code": "52081220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52081230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52081240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CASEMENT"
    },
    {
      "code": "52081250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHEETING(TAKIA, LEOPARD FABRICS, OTHER THAN FURNISHING FABRICS)"
    },
    {
      "code": "52081260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :VOILS"
    },
    {
      "code": "52081290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :OTHER"
    },
    {
      "code": "520813",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52081310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : SHIRTING FABRICS"
    },
    {
      "code": "52081320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : DOBBY FABRICS"
    },
    {
      "code": "52081390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : OTHER"
    },
    {
      "code": "520819",
      "description": "Other fabrics"
    },
    {
      "code": "52081910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: OTHER FABRICS : DEDSUTI, DOSUTI FABRICS"
    },
    {
      "code": "52081990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - UNBLEACHED: OTHER FABRICS : OTHER"
    },
    {
      "code": "520821",
      "description": "Plain weave, weighing not more than 100 g/m2"
    },
    {
      "code": "52082110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : DHOTI"
    },
    {
      "code": "52082120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : SAREE"
    },
    {
      "code": "52082130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : CASEMENT"
    },
    {
      "code": "52082140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : SHIRTING FABRICS"
    },
    {
      "code": "52082150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : CAMBRICS (INCLUDING MADAPOLLAM AND JACONET)"
    },
    {
      "code": "52082160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : MULLS (INCLUDING LIMBRIC AND WILLAYA)"
    },
    {
      "code": "52082170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : MUSLIN (INCLUDING LAWN, MULMUL AND ORGANDI)"
    },
    {
      "code": "52082180",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52082190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 : OTHER"
    },
    {
      "code": "520822",
      "description": "Plain weave, weighing more than 100 g/m2"
    },
    {
      "code": "52082210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :DHOTI"
    },
    {
      "code": "52082220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52082230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52082240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CASEMENT"
    },
    {
      "code": "52082250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CAMBRICS (INCLUDING MADAPOLLAM AND JACONET)"
    },
    {
      "code": "52082260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :LONG CLOTH (INCLUDING CALICO)"
    },
    {
      "code": "52082270",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHEETING (TAKIA AND THE LIKE)"
    },
    {
      "code": "52082280",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52082290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :OTHER"
    },
    {
      "code": "520823",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52082310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52082320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :PARMATTA FABRICS (INCLUDING ILESIA, POCKETING, ITALIAN TWILL)"
    },
    {
      "code": "52082330",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52082390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520829",
      "description": "Other fabrics"
    },
    {
      "code": "52082910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : OTHER FABRICS :DHOTI AND SAREE, ZARI BORDERED"
    },
    {
      "code": "52082920",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : OTHER FABRICS :DEDSUTI, DOSUTI FABRICS, CERETONNES AND OSAMBURGE"
    },
    {
      "code": "52082990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- - BLEACHED : OTHER FABRICS :OTHER"
    },
    {
      "code": "520831",
      "description": "Plain weave, weighing not more than 100 g/m2"
    },
    {
      "code": "52083110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:LUNGI"
    },
    {
      "code": "52083121",
      "description": "DYED-PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G PER M2 - SAREES OF HANDLOOM"
    },
    {
      "code": "52083129",
      "description": "DYED - PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G PER M2 -SAREES OTHER THAN HANDLOOM"
    },
    {
      "code": "52083130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:SHIRTING FABRICS"
    },
    {
      "code": "52083140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:CASEMENT"
    },
    {
      "code": "52083150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:CAMBRICS (INCLUDING MADAPOLLAM AND JACONET)"
    },
    {
      "code": "52083160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:MULL (INCLUDING LIMBRIC AND WILLAYA)"
    },
    {
      "code": "52083170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:MUSLIN (INCLUDING LAWN MULMUL AND ORGANDI) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52083180",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52083190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2:OTHER"
    },
    {
      "code": "520832",
      "description": "Plain weave, weighing more than 100 g/m2"
    },
    {
      "code": "52083210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:LUNGI"
    },
    {
      "code": "52083220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:SAREE"
    },
    {
      "code": "52083230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:SHIRTING FABRICS"
    },
    {
      "code": "52083240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:CASEMENT"
    },
    {
      "code": "52083250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:BEDTICKING, DOMESTIC"
    },
    {
      "code": "52083260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:CAMBRICS (INCLUDING MADAPOLLAM AND JACONET), LONG CLOTH(INCLUDING CALICO) AND VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52083270",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:COATING (INCLUDING SUITING)"
    },
    {
      "code": "52083280",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:FURNISHING FABRICS( EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52083290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/ M2:OTHER"
    },
    {
      "code": "520833",
      "description": "3-thread or 4- thread twill, including cross twill"
    },
    {
      "code": "52083310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52083320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :COATING (INCLUDING SUITING)"
    },
    {
      "code": "52083330",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING (INCLUDING MAZRI)"
    },
    {
      "code": "52083390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520839",
      "description": "Other fabrics"
    },
    {
      "code": "52083910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : OTHER FABRICS :ZARI BORDERED SAREES"
    },
    {
      "code": "52083990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- DYED : OTHER FABRICS :OTHER"
    },
    {
      "code": "520841",
      "description": "Plain weave, weighing not more than 100 g/m2"
    },
    {
      "code": "52084110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :BLEEDING MADRAS"
    },
    {
      "code": "52084120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52084121",
      "description": "OF YARN OF DIFFERENT COLOURS - PALIN WEAVE,WEIGHING NOT MORE THAN 100 G PER M2 - SAREES OF HANDLOOM"
    },
    {
      "code": "52084129",
      "description": "OF YARN OF DIFFERENT COLOURS - PALIN WEAVE,WEIGHING NOT MORE THAN 100 G PER M2 - SAREES OTHER THAN HANDLOOM"
    },
    {
      "code": "52084130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52084140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :BED TICKING, DOMESTIC"
    },
    {
      "code": "52084150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52084190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :OTHER"
    },
    {
      "code": "520842",
      "description": "Plain weave, weighing more than 100 g/m2"
    },
    {
      "code": "52084210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :BLEEDING MADRAS"
    },
    {
      "code": "52084220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52084230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52084240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CASEMENT"
    },
    {
      "code": "52084250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :BED TICKING, DOMESTIC"
    },
    {
      "code": "52084260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 : FURNISHING FABRICS, OTHER THAN PILE AND CHENILLE FABRIC"
    },
    {
      "code": "52084290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 : OTHER"
    },
    {
      "code": "520843",
      "description": "3-thread or 4- thread twill, including cross twill"
    },
    {
      "code": "52084310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :BLEADING MADRAS"
    },
    {
      "code": "52084320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52084330",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :BEDTICKING, DAMASK"
    },
    {
      "code": "52084340",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :FLANNELETTE"
    },
    {
      "code": "52084390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : 3-THREAD OR 4- THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520849",
      "description": "Other fabrics"
    },
    {
      "code": "52084910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : OTHER FABRICS :ZARI BORDERED SAREES"
    },
    {
      "code": "52084921",
      "description": "REAL MADRAS HANDKERCHIEFS OF HANDLOOM"
    },
    {
      "code": "52084929",
      "description": "REAL MADRAS HANDKERCHIEFS - OTHER THAN HANDLOOM"
    },
    {
      "code": "52084990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- OF YARN OF DIFFERENT COLOURS : OTHER FABRICS :OTHER"
    },
    {
      "code": "520851",
      "description": "Plain weave, weighing not more than 100 g/m2"
    },
    {
      "code": "52085110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :LUNGI"
    },
    {
      "code": "52085120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52085130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52085140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :CASEMENT"
    },
    {
      "code": "52085150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :CAMBRICS (INCLUDING MADAPOLLAM AND JACONET)"
    },
    {
      "code": "52085160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :MULL (INCLUDING LIMBRIC AND WILLAYA)"
    },
    {
      "code": "52085170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :MUSLIN (INCLUDING LAWN MULMUL AND ORGANDI) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52085180",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52085190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING NOT MORE THAN 100 G/M2 :OTHER"
    },
    {
      "code": "520852",
      "description": "Plain weave, weighing more than 100 g/m2"
    },
    {
      "code": "52085210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :LUNGI"
    },
    {
      "code": "52085220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SAREE"
    },
    {
      "code": "52085230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :SHIRTING FABRICS"
    },
    {
      "code": "52085240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CASEMENT"
    },
    {
      "code": "52085250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :CAMBRICS (INCLUDING MADAPOLLAM AND JACONET)"
    },
    {
      "code": "52085260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :MULL (INCLUDING LIMBRIC AND WILLAYA)"
    },
    {
      "code": "52085270",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :MUSLIN (INCLUDING LAWN MULMUL AND ORGANDI) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52085280",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :VOILS (EXCLUDING LENO FABRICS)"
    },
    {
      "code": "52085290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE, WEIGHING MORE THAN 100 G/M2 :OTHER"
    },
    {
      "code": "52085310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52085320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :BEDTICKING"
    },
    {
      "code": "52085390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520859",
      "description": "Others fabrics"
    },
    {
      "code": "52085910",
      "description": "Zari bordered sarees"
    },
    {
      "code": "52085920",
      "description": "OTHER FABRICS, SAREES,HANDLOOM"
    },
    {
      "code": "52085990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : OTHER FABRICS :OTHER"
    },
    {
      "code": "5209",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M 2 - Unbleached :"
    },
    {
      "code": "520911",
      "description": "Plain weave"
    },
    {
      "code": "52091111",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : HANDLOOM : DHOTI"
    },
    {
      "code": "52091112",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : HANDLOOM : SAREE"
    },
    {
      "code": "52091113",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : HANDLOOM : CASEMENT"
    },
    {
      "code": "52091114",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : HANDLOOM : SHEETING (TAKIA, LEOPARD CLOTH AND OTHER THAN FURNISHING)"
    },
    {
      "code": "52091119",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : HANDLOOM : OTHER"
    },
    {
      "code": "52091190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE : OTHER"
    },
    {
      "code": "520912",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52091210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:SAREE"
    },
    {
      "code": "52091220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:SHIRTING FABRICS"
    },
    {
      "code": "52091230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52091240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:SEERSUCKER"
    },
    {
      "code": "52091250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:CANVAS, INCLUDING DUCK  CARDED OR COMBED YARN"
    },
    {
      "code": "52091260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:FLANNELETTE"
    },
    {
      "code": "52091270",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:SHETTING (TAKIA, LEOPARD CLOTH)"
    },
    {
      "code": "52091290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL:OTHER"
    },
    {
      "code": "52091900",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :OTHER FABRICS"
    },
    {
      "code": "520921",
      "description": "Plain weave"
    },
    {
      "code": "52092110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52092120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE : SHIRTING FABRICS"
    },
    {
      "code": "52092130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE : FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52092140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE : SEERSUCKER"
    },
    {
      "code": "52092150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE : CANVAS (INCLUDING DUCK) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52092160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE :DHOTI"
    },
    {
      "code": "52092170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE :FLANNELLETE"
    },
    {
      "code": "52092180",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE :SHEETING( TAKIA, LEOPARDCLOTH)"
    },
    {
      "code": "52092190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "520922",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52092210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52092220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52092230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :DRILL"
    },
    {
      "code": "52092290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520929",
      "description": "Other fabrics"
    },
    {
      "code": "52092910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : OTHER FABRICS :DHOTI AND SAREE, ZARI BORDERED"
    },
    {
      "code": "52092920",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : OTHER FABRICS :DEDSUTI, DOSUTI FABRICS, CERETONNES AND OSAMBURGE"
    },
    {
      "code": "52092990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2 - BLEACHED : OTHER FABRICS :OTHER"
    },
    {
      "code": "520931",
      "description": "Plain weave"
    },
    {
      "code": "52093110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :LUNGI"
    },
    {
      "code": "52093120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52093130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52093140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :FURNISHING FABRICS(EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52093150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :SEERSUCKER"
    },
    {
      "code": "52093160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :BEDTICKING, DOMESTIC(OTHER THAN HAND DYED)"
    },
    {
      "code": "52093170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :CANVAS (INCLUDING DUCK), OF CARDED OR COMBED YARN"
    },
    {
      "code": "52093180",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :FLANNELLETE"
    },
    {
      "code": "52093190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "520932",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52093210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52093220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :FURNISHING FABRICS(EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52093230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :DRILL"
    },
    {
      "code": "52093290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520939",
      "description": "Other fabrics"
    },
    {
      "code": "52093910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : OTHER FABRICS :ZARI BORDERED SAREES"
    },
    {
      "code": "52093990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- DYED : OTHER FABRICS :OTHER"
    },
    {
      "code": "520941",
      "description": "Plain weave"
    },
    {
      "code": "52094110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :BLEEDING MADRAS"
    },
    {
      "code": "52094120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52094130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52094140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52094150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SEERSUCKER"
    },
    {
      "code": "52094160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :BEDTICKING, DOMESTIC (OTHER THAN HAND DYED)"
    },
    {
      "code": "52094170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :FLANNELETTE"
    },
    {
      "code": "52094190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :OTHER"
    },
    {
      "code": "52094200",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : DENIM"
    },
    {
      "code": "520943",
      "description": "Other fabrics of 3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52094310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : BLEEDING MADRAS"
    },
    {
      "code": "52094320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : SHIRTING FABRICS"
    },
    {
      "code": "52094330",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52094340",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : COATING (INCLUDING SUITING)"
    },
    {
      "code": "52094390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : OTHER"
    },
    {
      "code": "520949",
      "description": "Other fabrics"
    },
    {
      "code": "52094910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :ZARI BORDERED SARI"
    },
    {
      "code": "52094990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :OTHER"
    },
    {
      "code": "520951",
      "description": "Plain weave"
    },
    {
      "code": "52095111",
      "description": "LUNGIS OF HANDLOOM"
    },
    {
      "code": "52095119",
      "description": "LUNGIS - OTHER"
    },
    {
      "code": "52095120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52095130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52095140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52095150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :SEERSUCKER"
    },
    {
      "code": "52095160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :BEDTICKING, DOMESTIC"
    },
    {
      "code": "52095170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :FLANNELETTE"
    },
    {
      "code": "52095190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "520952",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52095210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52095220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52095290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "520959",
      "description": "Other fabrics"
    },
    {
      "code": "52095910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : OTHER FABRICS :ZARI BORDERED SAREE"
    },
    {
      "code": "52095990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING 85% OR MORE BY WEIGHT OF COTTON, WEIGHING MORE THAN 200 G/M2- PRINTED : OTHER FABRICS :OTHER"
    },
    {
      "code": "5210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M 2 - Unbleached :"
    },
    {
      "code": "521011",
      "description": "Plain weave"
    },
    {
      "code": "52101110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52101120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52101190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "52101210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52101290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "52101900",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- UNBLEACHED : OTHER FABRICS"
    },
    {
      "code": "521021",
      "description": "Plain weave"
    },
    {
      "code": "52102110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52102120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :POPLIN AND BROAD FABRICS"
    },
    {
      "code": "52102130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :SAREE"
    },
    {
      "code": "52102140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :SHIRTING (INCLUDING MAZRI)"
    },
    {
      "code": "52102150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :VOILE"
    },
    {
      "code": "52102190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :PLAIN WEAVE :OTHER"
    },
    {
      "code": "52102211",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : - HANDLOOM :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52102212",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : - HANDLOOM :SHIRTING FABRICS"
    },
    {
      "code": "52102219",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : - HANDLOOM :OTHER FABRICS"
    },
    {
      "code": "52102221",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : OTHER : :SHIRTING (INCLUDING MAZRI)"
    },
    {
      "code": "52102229",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : OTHER : OTHER"
    },
    {
      "code": "521029",
      "description": "Other fabrics"
    },
    {
      "code": "52102910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :OTHER FABRICS :DHOTI AND SAREE, ZARI BORDERED"
    },
    {
      "code": "52102920",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :OTHER FABRICS :DEDSUTI, DOSUTI, CERETONNES AND OSAMBURGE"
    },
    {
      "code": "52102990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- BLEACHED :OTHER FABRICS :OTHER"
    },
    {
      "code": "521031",
      "description": "Plain weave"
    },
    {
      "code": "52103110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52103120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :COATING (INCLUDING SUITINGS)"
    },
    {
      "code": "52103130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52103140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :POPLIN AND BROAD FABRICS"
    },
    {
      "code": "52103150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52103160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :VOILS"
    },
    {
      "code": "52103190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "521032",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52103210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52103220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52103230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :BEDTICKING, DAMASK"
    },
    {
      "code": "52103239",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "52103290",
      "description": "Other"
    },
    {
      "code": "521039",
      "description": "Other fabrics"
    },
    {
      "code": "52103910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : OTHER FABRICS :ZARI BORDERED SAREE"
    },
    {
      "code": "52103990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- DYED : OTHER FABRICS :OTHER"
    },
    {
      "code": "521041",
      "description": "Plain weave"
    },
    {
      "code": "52104110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :BLEEDING MADRAS"
    },
    {
      "code": "52104120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :CREPE FABRICS (EXCLUDING CREPE CHECKS)"
    },
    {
      "code": "52104130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52104140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SUITINGS"
    },
    {
      "code": "52104150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :POPLIN AND BROAD FABRICS"
    },
    {
      "code": "52104160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52104170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :VOILS"
    },
    {
      "code": "52104190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :OTHER"
    },
    {
      "code": "52104210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :BLEEDING MADRAS"
    },
    {
      "code": "52104220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52104230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52104240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SUITINGS"
    },
    {
      "code": "52104250",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :BEDTICKING, DAMASK"
    },
    {
      "code": "52104260",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTINGS (INCLUDING MAZRI)"
    },
    {
      "code": "52104290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "521049",
      "description": "Other fabrics"
    },
    {
      "code": "52104910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :ZARI BORDERED SAREE"
    },
    {
      "code": "52104990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :OTHER"
    },
    {
      "code": "521051",
      "description": "Plain weave"
    },
    {
      "code": "52105110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52105120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :CASEMENT"
    },
    {
      "code": "52105130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52105140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :POPLIN AND BROAD FABRICS"
    },
    {
      "code": "52105150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :VOILS"
    },
    {
      "code": "52105190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "52105210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52105220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52105290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "521059",
      "description": "Other fabrics"
    },
    {
      "code": "52105910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : OTHER FABRICS :ZARI BORDERED SAREE"
    },
    {
      "code": "52105990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING NOT MORE THAN 200 G/M2- PRINTED : OTHER FABRICS :OTHER"
    },
    {
      "code": "5211",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - Unbleached :"
    },
    {
      "code": "521111",
      "description": "Plain weave"
    },
    {
      "code": "52111110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52111120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE :SAREE"
    },
    {
      "code": "52111190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :PLAIN WEAVE :OTHER"
    },
    {
      "code": "521112",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52111210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL,` INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52111220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL,` INCLUDING CROSS TWILL :TWILL, NOT ELSEWHERE SPECIFIED (INCLUDING GABERDINE)"
    },
    {
      "code": "52111230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL,` INCLUDING CROSS TWILL :DAMASK"
    },
    {
      "code": "52111290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - UNBLEACHED :3-THREAD OR 4-THREAD TWILL,` INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "52111900",
      "description": "OTHER FABRICS"
    },
    {
      "code": "521120",
      "description": "Bleached"
    },
    {
      "code": "52112010",
      "description": "BLEACHED SHIRTING FABRICS"
    },
    {
      "code": "52112020",
      "description": "BLEACHED CANVAS (INCLUDING DUCK) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52112030",
      "description": "BLEACHED FLANNELETTE"
    },
    {
      "code": "52112040",
      "description": "BLEACHED SAREE"
    },
    {
      "code": "52112050",
      "description": "BLEACHED CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52112060",
      "description": "BLEACHED TWILL FABRICS"
    },
    {
      "code": "52112091",
      "description": "OTHER BLEACHED - ZARI BORDED SARI"
    },
    {
      "code": "52112092",
      "description": "OTHER BLEACHED - DEDSUTTI, DOSUTI, CERETONNES AND OSAMBURGE"
    },
    {
      "code": "52112099",
      "description": "OTHER BLEACHED-OTHER"
    },
    {
      "code": "52112110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52112120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :CANVAS (INCLUDING DUCK) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52112130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :FLANNELETTE"
    },
    {
      "code": "52112140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :SAREE"
    },
    {
      "code": "52112150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52112190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :PLAIN WEAVE :OTHER"
    },
    {
      "code": "52112210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52112220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52112230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :TWILL FABRICS"
    },
    {
      "code": "52112290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "52112910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :OTHER FABRICS :ZARI BORDERED SARI"
    },
    {
      "code": "52112920",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :OTHER FABRICS :DEDSUTI, DOSUTI, CERETONNES AND OSAMBURGE"
    },
    {
      "code": "52112990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - BLEACHED :OTHER FABRICS :OTHER"
    },
    {
      "code": "521131",
      "description": "Plain weave"
    },
    {
      "code": "52113110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52113120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :CANVAS (INCLUDING DUCK) OF CARDED OR COMBED YARN"
    },
    {
      "code": "52113130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :COATING (INCLUDING SUITINGS)"
    },
    {
      "code": "52113140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :FLANNELLETTE"
    },
    {
      "code": "52113150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52113190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "521132",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52113210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52113220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52113230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :TWILL, NOT ELSEWHERE SPECIFIED (INCLUDING GABERDINE)"
    },
    {
      "code": "52113240",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :TROUSERS OR PANT FABRICS (EXCLUDING JEANS AND CREPE)"
    },
    {
      "code": "52113290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "521139",
      "description": "Other fabrics"
    },
    {
      "code": "52113910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : OTHER FABRICS :ZARI BORDERED SAREES"
    },
    {
      "code": "52113990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - DYED : OTHER FABRICS :OTHER"
    },
    {
      "code": "521141",
      "description": "Plain weave"
    },
    {
      "code": "52114110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :BLEEDING MADRAS"
    },
    {
      "code": "52114120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :CHECK SHIRTING (EXCLUDING CREPE CHECKS)"
    },
    {
      "code": "52114130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SHIRTING"
    },
    {
      "code": "52114140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SUITINGS"
    },
    {
      "code": "52114150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :FLANNELETTE"
    },
    {
      "code": "52114160",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52114170",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :PARACHUTE FABRICS"
    },
    {
      "code": "52114190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : PLAIN WEAVE :OTHER"
    },
    {
      "code": "52114200",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : DENIM"
    },
    {
      "code": "521143",
      "description": "Other fabrics of 3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52114310",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : BLEEDING MADRAS"
    },
    {
      "code": "52114320",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : CREPE FABRICS"
    },
    {
      "code": "52114330",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : SHIRTING FABRICS"
    },
    {
      "code": "52114340",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : SUITINGS"
    },
    {
      "code": "52114390",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS OF 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL : OTHER"
    },
    {
      "code": "521149",
      "description": "Other fabrics"
    },
    {
      "code": "52114910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :ZARI BORDERED SAREES"
    },
    {
      "code": "52114990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER FABRICS :OTHER"
    },
    {
      "code": "521151",
      "description": "Plain weave"
    },
    {
      "code": "52115110",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :SHIRTING FABRICS"
    },
    {
      "code": "52115120",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :FURNISHING FABRICS (EXCLUDING PILE AND CHENILLE FABRICS)"
    },
    {
      "code": "52115130",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :FLANNELETTE"
    },
    {
      "code": "52115140",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :LONG CLOTH (CHINTZ)"
    },
    {
      "code": "52115150",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :SAREE"
    },
    {
      "code": "52115190",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : PLAIN WEAVE :OTHER"
    },
    {
      "code": "521152",
      "description": "3-thread or 4-thread twill, including cross twill"
    },
    {
      "code": "52115210",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :CREPE FABRICS INCLUDING CREPE CHECKS"
    },
    {
      "code": "52115220",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :SHIRTING FABRICS"
    },
    {
      "code": "52115230",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :TWILL, NOT ELSEWHERE SPECIFIED (INCLUDING GABERDINE)"
    },
    {
      "code": "52115290",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL :OTHER"
    },
    {
      "code": "521159",
      "description": "Other fabrics"
    },
    {
      "code": "52115910",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : OTHER FABRICS :ZARI BORDERED SAREE"
    },
    {
      "code": "52115990",
      "description": "WOVEN FABRICS OF COTTON, CONTAINING LESS THAN 85% BY WEIGHT OF COTTON, MIXED MAINLY OR SOLELY WITH MAN-MADE FIBRES, WEIGHING MORE THAN 200 G/M2 - PRINTED : OTHER FABRICS :OTHER"
    },
    {
      "code": "5212",
      "description": "OTHER WOVEN FABRICS OF COTTON - Weighing not more than 200 g/m2  :"
    },
    {
      "code": "52121100",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING NOT MORE THAN 200 G/M2 : UNBLEACHED"
    },
    {
      "code": "52121200",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING NOT MORE THAN 200 G/M2 : BLEACHED"
    },
    {
      "code": "52121300",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING NOT MORE THAN 200 G/M2 : DYED"
    },
    {
      "code": "52121400",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING NOT MORE THAN 200 G/M2 : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "52121500",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING NOT MORE THAN 200 G/M2 : PRINTED"
    },
    {
      "code": "52122100",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING MORE THAN 200 G/M2 : UNBLEACHED"
    },
    {
      "code": "52122200",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING MORE THAN 200 G/M2 : BLEACHED"
    },
    {
      "code": "52122300",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING MORE THAN 200 G/M2 : DYED"
    },
    {
      "code": "52122400",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING MORE THAN 200 G/M2 : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "52122500",
      "description": "OTHER WOVEN FABRICS OF COTTON - WEIGHING MORE THAN 200 G/M2 : PRINTED"
    },
    {
      "code": "53",
      "description": "Other vegetable textile fibres; paper yarn and woven fabrics of paper yarn"
    },
    {
      "code": "5301",
      "description": "FLAX, RAW OR PROCESSED BUT NOT SPUN; FLAX TOW AND WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK)"
    },
    {
      "code": "53011000",
      "description": "FLAX, RAW OR PROCESSED BUT NOT SPUN; FLAX TOW AND WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) FLAX, RAW OR RETTED"
    },
    {
      "code": "53012100",
      "description": "FLAX, RAW OR PROCESSED BUT NOT SPUN; FLAX TOW AND WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) FLAX, BROKEN, SCUTCHED, HACKLED OR OTHERWISE PROCESSED, BUT NOT SPUN :BROKEN OR SCUTCHED"
    },
    {
      "code": "53012900",
      "description": "FLAX, RAW OR PROCESSED BUT NOT SPUN; FLAX TOW AND WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) FLAX, BROKEN, SCUTCHED, HACKLED OR OTHERWISE PROCESSED, BUT NOT SPUN :OTHER"
    },
    {
      "code": "53013000",
      "description": "FLAX, RAW OR PROCESSED BUT NOT SPUN; FLAX TOW AND WASTE (INCLUDING YARN WASTE AND GARNETTED STOCK) FLAX TOW AND WASTE"
    },
    {
      "code": "5302",
      "description": "TRUE HEMP (CANNABIS SATIVA L ), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE  OF TRUE HEMP (INCLUDING YARN WASTE AND GARNETTED STOCK)"
    },
    {
      "code": "53021000",
      "description": "TRUE HEMP (CANNABIS SATIVA L ), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF TRUE HEMP (INCLUDING YARN WASTE AND GARNETTED STOCK) TRUE HEMP, RAW OR RETTED"
    },
    {
      "code": "53029000",
      "description": "TRUE HEMP (CANNABIS SATIVA L ), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF TRUE HEMP (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER"
    },
    {
      "code": "5303",
      "description": "JUTE AND OTHER TEXTILE BAST FIBRES (EXCLUDING FLAX, TRUE HEMP AND RAMIE), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK)"
    },
    {
      "code": "530310",
      "description": "Jute and other textile bast fibres, raw or retted"
    },
    {
      "code": "53031010",
      "description": "JUTE AND OTHER TEXTILE BAST FIBRES (EXCLUDING FLAX, TRUE HEMP AND RAMIE), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) JUTE AND OTHER TEXTILE BAST FIBRES, RAW OR RETTED :JUTE, RAW OR RETTED"
    },
    {
      "code": "53031090",
      "description": "JUTE AND OTHER TEXTILE BAST FIBRES (EXCLUDING FLAX, TRUE HEMP AND RAMIE), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) JUTE AND OTHER TEXTILE BAST FIBRES, RAW OR RETTED :OTHER"
    },
    {
      "code": "530390",
      "description": "Other"
    },
    {
      "code": "53039010",
      "description": "JUTE AND OTHER TEXTILE BAST FIBRES (EXCLUDING FLAX, TRUE HEMP AND RAMIE), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER : JUTE CUTTING"
    },
    {
      "code": "53039090",
      "description": "JUTE AND OTHER TEXTILE BAST FIBRES (EXCLUDING FLAX, TRUE HEMP AND RAMIE), RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER :OTHER"
    },
    {
      "code": "5304",
      "description": "OMITTED"
    },
    {
      "code": "53041010",
      "description": "SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW : SISAL FIBRE"
    },
    {
      "code": "53041020",
      "description": "SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW : ALOE FIBRE"
    },
    {
      "code": "53041090",
      "description": "SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW : OTHER"
    },
    {
      "code": "53049000",
      "description": "SISAL AND OTHER TEXTILE FIBRES OF THE GENUS AGAVE, RAW OR PROCESSED BUT NOT SPUN; TOW AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER"
    },
    {
      "code": "5305",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILES NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETED STOCK)"
    },
    {
      "code": "530500",
      "description": "Coconut, abaca (Manila hemp or Musa textils Nec), ramie and other vegetable textile fibres, not elsewhere specified or included, raw or processed but not spun, tow noils and waste of these fibres (including yarn waste and garneted stock)"
    },
    {
      "code": "53050010",
      "description": "COCONUT ,ABACA,RAMIE AND OTHER VEGETABLE TEXTILE FIBRES,NOT"
    },
    {
      "code": "53050030",
      "description": "CURLED OR MACHINE TWISTED COIR FIBRE"
    },
    {
      "code": "53050040",
      "description": "COIR PITH"
    },
    {
      "code": "53050050",
      "description": "VEGETABLE TEXTILE FIRES - OF ABACA"
    },
    {
      "code": "53050090",
      "description": "VEGETABLE TEXTILE FIBRES - OF OTHERS"
    },
    {
      "code": "53051110",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) : RAW : COIR BRISTLE FIBRE"
    },
    {
      "code": "53051120",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) : RAW : COIR MATTRESS FIBRE"
    },
    {
      "code": "53051130",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) : RAW : CURLED OR MACHINE TWISTED COIR FIBRE"
    },
    {
      "code": "53051140",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) : RAW : COIR PITH"
    },
    {
      "code": "53051190",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) :RAW : OTHER"
    },
    {
      "code": "53051900",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF COCONUT (COIR) :OTHER:"
    },
    {
      "code": "53052100",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF ABACA : RAW"
    },
    {
      "code": "53052900",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OF ABACA :OTHER"
    },
    {
      "code": "53059010",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER : RAMIE OR CHINA GRASS"
    },
    {
      "code": "53059090",
      "description": "COCONUT, ABACA (MANILA HEMP OR MUSA TEXTILIS NEE), RAMIE AND OTHER VEGETABLE TEXTILE FIBRES, NOT ELSEWHERE SPECIFIED OR INCLUDED, RAW OR PROCESSED BUT NOT SPUN; TOW, NOILS AND WASTE OF THESE FIBRES (INCLUDING YARN WASTE AND GARNETTED STOCK) OTHER :OTHER"
    },
    {
      "code": "5306",
      "description": "FLAX YARN"
    },
    {
      "code": "530610",
      "description": "Single"
    },
    {
      "code": "53061010",
      "description": "FLAX YARN SINGLE : PUT UP FOR RETAIL SALE"
    },
    {
      "code": "53061090",
      "description": "FLAX YARN SINGLE :OTHER"
    },
    {
      "code": "530620",
      "description": "Multiple (folded) or cabled"
    },
    {
      "code": "53062010",
      "description": "FLAX YARN MULTIPLE (FOLDED) OR CABLED :PUT UP FOR RETAIL SALE"
    },
    {
      "code": "53062090",
      "description": "FLAX YARN MULTIPLE (FOLDED) OR CABLED :OTHER"
    },
    {
      "code": "5307",
      "description": "YARN OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303"
    },
    {
      "code": "530710",
      "description": "Single"
    },
    {
      "code": "53071010",
      "description": "YARN OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303 SINGLE : OF JUTE"
    },
    {
      "code": "53071090",
      "description": "YARN OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303 SINGLE : OTHER"
    },
    {
      "code": "53072000",
      "description": "YARN OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303 MULTIPLE (FOLDED) OR CABLED"
    },
    {
      "code": "5308",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN"
    },
    {
      "code": "530810",
      "description": "Coir yarn"
    },
    {
      "code": "53081010",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN COIR YARN :BALED"
    },
    {
      "code": "53081020",
      "description": "SPOOLED HANKS"
    },
    {
      "code": "53081090",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN COIR YARN :OTHER"
    },
    {
      "code": "53082000",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN TRUE HEMP YARN"
    },
    {
      "code": "530890",
      "description": "Other"
    },
    {
      "code": "53089010",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN OTHER : RAMIE YARN"
    },
    {
      "code": "53089090",
      "description": "YARN OF OTHER VEGETABLE TEXTILE FIBRES; PAPER YARN OTHER :OTHER"
    },
    {
      "code": "5309",
      "description": "WOVEN FABRICS OF FLAX - Containing 85% or more by weight of flax :"
    },
    {
      "code": "530911",
      "description": "Unbleached or bleached"
    },
    {
      "code": "53091110",
      "description": "WOVEN FABRICS OF FLAX CONTAINING 85% OR MORE BY WEIGHT OF FLAX : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "53091120",
      "description": "WOVEN FABRICS OF FLAX CONTAINING 85% OR MORE BY WEIGHT OF FLAX : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "530919",
      "description": "Other"
    },
    {
      "code": "53091910",
      "description": "WOVEN FABRICS OF FLAX CONTAINING 85% OR MORE BY WEIGHT OF FLAX : OTHER : DYED"
    },
    {
      "code": "53091920",
      "description": "WOVEN FABRICS OF FLAX CONTAINING 85% OR MORE BY WEIGHT OF FLAX : OTHER : PRINTED"
    },
    {
      "code": "53091990",
      "description": "WOVEN FABRICS OF FLAX CONTAINING 85% OR MORE BY WEIGHT OF FLAX : OTHER : OTHER"
    },
    {
      "code": "530921",
      "description": "Unbleached or bleached"
    },
    {
      "code": "53092110",
      "description": "WOVEN FABRICS OF FLAX CONTAINING LESS THAN 85% BY WEIGHT OF FLAX : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "53092120",
      "description": "WOVEN FABRICS OF FLAX CONTAINING LESS THAN 85% BY WEIGHT OF FLAX : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "530929",
      "description": "Other"
    },
    {
      "code": "53092910",
      "description": "WOVEN FABRICS OF FLAX CONTAINING LESS THAN 85% BY WEIGHT OF FLAX : OTHER :DYED"
    },
    {
      "code": "53092920",
      "description": "WOVEN FABRICS OF FLAX CONTAINING LESS THAN 85% BY WEIGHT OF FLAX : OTHER :PRINTED"
    },
    {
      "code": "53092990",
      "description": "WOVEN FABRICS OF FLAX CONTAINING LESS THAN 85% BY WEIGHT OF FLAX : OTHER :OTHER"
    },
    {
      "code": "5310",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303"
    },
    {
      "code": "531010",
      "description": "Unbleached"
    },
    {
      "code": "53101011",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : CONTAINING 100% BY WEIGHT OF JUTE : CARPET BACKING FABRICS"
    },
    {
      "code": "53101012",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : CONTAINING 100% BY WEIGHT OF JUTE : SACKING FABRICS"
    },
    {
      "code": "53101013",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : CONTAINING 100% BY WEIGHT OF JUTE : HESSIAN FABRICS"
    },
    {
      "code": "53101014",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : CONTAINING 100% BY WEIGHT OF JUTE : JUTE CANVAS"
    },
    {
      "code": "53101019",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : CONTAINING 100% BY WEIGHT OF JUTE : OTHER"
    },
    {
      "code": "53101091",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : OTHER : WOVEN BLENDED FABRICS CONTAINING MORE THAN 50% BY WEIGHT OF JUTE"
    },
    {
      "code": "53101092",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : OTHER :STRANDED WOVEN FABRICS OF JUTE CONTAINING 50% OR MORE BY WEIGHT OF JUTE"
    },
    {
      "code": "53101093",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : OTHER :JUTE SWIM FABRICS"
    },
    {
      "code": "53101099",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 UNBLEACHED : OTHER :OTHER"
    },
    {
      "code": "531090",
      "description": "Other"
    },
    {
      "code": "53109010",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER : BLEACHED"
    },
    {
      "code": "53109020",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER :DECORATIVE FABRICS"
    },
    {
      "code": "53109091",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER :OTHER : BLEACHED"
    },
    {
      "code": "53109092",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER :OTHER :DYED"
    },
    {
      "code": "53109093",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER :OTHER :PRINTED"
    },
    {
      "code": "53109099",
      "description": "WOVEN FABRICS OF JUTE OR OF OTHER TEXTILE BASE FIBRES OF HEADING 5303 OTHER :OTHER :OTHER"
    },
    {
      "code": "5311",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN"
    },
    {
      "code": "531100",
      "description": "Woven fabrics of other vegetable textile fibres; woven fabrics of paper yarn"
    },
    {
      "code": "53110011",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF OTHER VEGETABLE TEXTILE FIBRES : UNBLEACHED"
    },
    {
      "code": "53110012",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF OTHER VEGETABLE TEXTILE FIBRES : BLEACHED"
    },
    {
      "code": "53110013",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF OTHER VEGETABLE TEXTILE FIBRES : DYED"
    },
    {
      "code": "53110014",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF OTHER VEGETABLE TEXTILE FIBRES : PRINTED"
    },
    {
      "code": "53110015",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES - OF COIR INCLUDING LOG FORM AND GEOTEXTILES"
    },
    {
      "code": "53110019",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF OTHER VEGETABLE TEXTILE FIBRES : OTHER"
    },
    {
      "code": "53110021",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF PAPER YARN : UNBLEACHED"
    },
    {
      "code": "53110022",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF PAPER YARN : BLEACHED"
    },
    {
      "code": "53110023",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF PAPER YARN : DYED"
    },
    {
      "code": "53110024",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF PAPER YARN : PRINTED"
    },
    {
      "code": "53110029",
      "description": "WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN WOVEN FABRICS OF OTHER VEGETABLE TEXTILE FIBRES; WOVEN FABRICS OF PAPER YARN : OF PAPER YARN : OTHER"
    },
    {
      "code": "54",
      "description": "Man-made filaments; strip and the like of man-made textile materials"
    },
    {
      "code": "5401",
      "description": "SEWING THREAD OF MAN-MADE FILAMENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "54011000",
      "description": "SEWING THREAD OF MAN-MADE FILAMENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE OF SYNTHETIC FILAMENTS"
    },
    {
      "code": "54012000",
      "description": "SEWING THREAD OF MAN-MADE FILAMENTS, WHETHER OR NOT PUT UP FOR RETAIL SALE OF ARTIFICIAL FILAMENTS"
    },
    {
      "code": "5402",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX - High tenacity yarn of nylon or other polyamides :"
    },
    {
      "code": "54021010",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES : NYLON TYRE YARN"
    },
    {
      "code": "54021090",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES : OTHER"
    },
    {
      "code": "54021110",
      "description": "HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "540219",
      "description": "Other;"
    },
    {
      "code": "54021910",
      "description": "OTHER HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "54021990",
      "description": "OTHER HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "540220",
      "description": "High tenacity yarn of polyesters, whether or not textured"
    },
    {
      "code": "54022010",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF POLYESTERS :OF TERYLENE DACRON"
    },
    {
      "code": "54022090",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF POLYESTERS :OTHER"
    },
    {
      "code": "54023100",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN : OF NYLON OR OTHER POLYAMIDES, MEASURING PER SINGLE YARN NOT MORE THAN 50 TEX"
    },
    {
      "code": "54023200",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OF NYLON OR OTHER POLYAMIDES, MEASURING PER SINGLE YARN MORE THAN 50 TEX"
    },
    {
      "code": "54023300",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OF POLYESTERS"
    },
    {
      "code": "54023400",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OF POLYPROPYLENE"
    },
    {
      "code": "540239",
      "description": "Other"
    },
    {
      "code": "54023910",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OTHER : POLYPROPYLENE FILAMENT YARN"
    },
    {
      "code": "54023920",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OTHER :ACRYLIC FILAMENT YARN"
    },
    {
      "code": "54023990",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :OTHER :OTHER"
    },
    {
      "code": "54024100",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "54024200",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF POLYESTERS, PARTIALLY ORIENTED"
    },
    {
      "code": "54024300",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF POLYESTERS, OTHER"
    },
    {
      "code": "54024400",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF ELASTOMERS"
    },
    {
      "code": "54024500",
      "description": "OTHER SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "54024600",
      "description": "OTHER SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF POLYESTERS, PARTIALLY ORIENTED"
    },
    {
      "code": "54024700",
      "description": "OTHER SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF POLYESTERS"
    },
    {
      "code": "54024800",
      "description": "OTHER SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, UNTWISTED OR WITH A TWIST NOT EXCEEDING 50 TURNS PER METRE : OF POLYPROPYLENE"
    },
    {
      "code": "54024900",
      "description": "OTHER HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "54025100",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, WITH A TWIST EXCEEDING 50 TURNS PER METRE : OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "54025200",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, WITH A TWIST EXCEEDING 50 TURNS PER METRE : OF POLYESTERS"
    },
    {
      "code": "54025300",
      "description": "Of polypropylene"
    },
    {
      "code": "540259",
      "description": "Other"
    },
    {
      "code": "54025910",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, WITH A TWIST EXCEEDING 50 TURNS PER METRE : OTHER : POLYPROPYLENE FILAMENT YARN"
    },
    {
      "code": "54025990",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE, WITH A TWIST EXCEEDING 50 TURNS PER METRE : OTHER : OTHER"
    },
    {
      "code": "54026100",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "54026200",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF POLYESTERS"
    },
    {
      "code": "54026300",
      "description": "Of polypropylene"
    },
    {
      "code": "540269",
      "description": "Other"
    },
    {
      "code": "54026910",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER : POLYVINYL ACETATE FILAMENT YARN"
    },
    {
      "code": "54026920",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER : POLYVINYL CHLORIDE FILAMENT YARN"
    },
    {
      "code": "54026930",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER :POLYPROPYLENE FILAMENT YARN"
    },
    {
      "code": "54026940",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER :ACRYLIC FILAMENT YARN"
    },
    {
      "code": "54026950",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER :POLYTETRAFLUOROETHYLENE YARN"
    },
    {
      "code": "54026990",
      "description": "SYNTHETIC FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT UP FOR RETAIL SALE, INCLUDING SYNTHETIC MONOFILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OTHER :OTHER"
    },
    {
      "code": "5403",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX"
    },
    {
      "code": "540310",
      "description": "High tenacity yarn of viscose rayon"
    },
    {
      "code": "54031010",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF VISCOSE RAYON : VISCOSE RAYON TYRE YARN  1,233 DECITEX"
    },
    {
      "code": "54031020",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF VISCOSE RAYON : VISCOSE RAYON TYRE YARN  1,833 DECITEX"
    },
    {
      "code": "54031090",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX HIGH TENACITY YARN OF VISCOSE RAYON : OTHER"
    },
    {
      "code": "54032000",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX TEXTURED YARN :"
    },
    {
      "code": "54033100",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE :OF VISCOSE RAYON, UNTWISTED OR WITH A TWIST NOT EXCEEDING 120 TURNS PER METRE"
    },
    {
      "code": "54033200",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE :OF VISCOSE RAYON, WITH A TWIST EXCEEDING 120 TURNS PER METRE"
    },
    {
      "code": "54033300",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, SINGLE :OF CELLULOSE ACETATE"
    },
    {
      "code": "540339",
      "description": "Other"
    },
    {
      "code": "54033910",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER : CUPRAMMONIUM RAYON"
    },
    {
      "code": "54033990",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER :OTHER"
    },
    {
      "code": "540341",
      "description": "Of viscose rayon"
    },
    {
      "code": "54034110",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : UP TO 67 DECITEX"
    },
    {
      "code": "54034120",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 83 DECITEX"
    },
    {
      "code": "54034130",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 111 DECITEX, BRIGHT"
    },
    {
      "code": "54034140",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 111 DECITEX, DULL"
    },
    {
      "code": "54034150",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 133 DECITEX, BRIGHT"
    },
    {
      "code": "54034160",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 133 DECITEX, DULL"
    },
    {
      "code": "54034170",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 167 DECITEX, BRIGHT"
    },
    {
      "code": "54034180",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OF 167 DECITEX, DULL"
    },
    {
      "code": "54034190",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED : OF VISCOSE RAYON : OTHER"
    },
    {
      "code": "540342",
      "description": "Of cellulose acetate"
    },
    {
      "code": "54034210",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :ACETATE RAYON FILAMENT YARN, 83 DECITEX"
    },
    {
      "code": "54034220",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :ACETATE RAYON FILAMENT YARN, 111 DECITEX"
    },
    {
      "code": "54034230",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :ACETATE RAYON FILAMENT YARN, 133 DECITEX"
    },
    {
      "code": "54034240",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :ACETATE RAYON FILAMENT YARN, 167 DECITEX"
    },
    {
      "code": "54034250",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :ACETATE RAYON FILAMENT YARN, 333 DECITEX"
    },
    {
      "code": "54034290",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OF CELLULOSE ACETATE :OTHER"
    },
    {
      "code": "540349",
      "description": "Other"
    },
    {
      "code": "54034911",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OF 33 DECITEX"
    },
    {
      "code": "54034912",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OF 44 DECITEX"
    },
    {
      "code": "54034913",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OF 67 DECITEX"
    },
    {
      "code": "54034914",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OF 83 DECITEX"
    },
    {
      "code": "54034915",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OF 89 DECITEX"
    },
    {
      "code": "54034919",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : CUPRAMMONIUM FILAMENT YARN : OTHER"
    },
    {
      "code": "54034990",
      "description": "ARTIFICIAL FILAMENT YARN (OTHER THAN SEWING THREAD), NOT PUT FOR RETAIL SALE, INCLUDING ARTIFICIAL MONO FILAMENT OF LESS THAN 67 DECITEX OTHER YARN, MULTIPLE (FOLDED) OR CABLED :OTHER : OTHER"
    },
    {
      "code": "5404",
      "description": "SYNTHETIC MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSSSECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF SYNTHETIC TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM - Monofilament"
    },
    {
      "code": "54041000",
      "description": "SYNTHETIC MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF SYNTHETIC TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM MONOFILAMENT"
    },
    {
      "code": "54041100",
      "description": "ELASTOMETIC MONOFILAMENT"
    },
    {
      "code": "54041200",
      "description": "OTHER MONOFILAMENT OF POLYPROPYLENE"
    },
    {
      "code": "540419",
      "description": "Other"
    },
    {
      "code": "54041910",
      "description": "OTHER MONOFILAMENTS - CATGUT IMITATION OF NON-STERILE SYNTHETIC YARN"
    },
    {
      "code": "54041920",
      "description": "MONOFILAMENTS"
    },
    {
      "code": "54041990",
      "description": "OTHER MONOFILAMENTS - NOT SPECIFIED ELSEWHERE"
    },
    {
      "code": "540490",
      "description": "Other"
    },
    {
      "code": "54049010",
      "description": "SYNTHETIC MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF SYNTHETIC TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM OTHER : CATGUT IMITATION OF SYNTHETIC YARN, NONSTERILE"
    },
    {
      "code": "54049020",
      "description": "SYNTHETIC MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF SYNTHETIC TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM OTHER : STRIP AND THE LIKE OF SYNTHETIC FIBRE MATERIALS"
    },
    {
      "code": "54049090",
      "description": "SYNTHETIC MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF SYNTHETIC TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM OTHER : OTHER"
    },
    {
      "code": "5405",
      "description": "ARTIFICIAL MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF ARTIFICIAL TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM"
    },
    {
      "code": "54050000",
      "description": "ARTIFICIAL MONOFILAMENT OF 67 DECITEX OR MORE AND OF WHICH NO CROSS-SECTIONAL DIMENSION EXCEEDS 1 MM; STRIP AND THE LIKE (FOR EXAMPLE, ARTIFICIAL STRAW) OF ARTIFICIAL TEXTILE MATERIALS OF AN APPARENT WIDTH NOT EXCEEDING 5 MM"
    },
    {
      "code": "5406",
      "description": "MAN-MADE FILAMENT YARN (OTHER THAN SEWING THREAD), PUT UP FOR RETAIL SALE"
    },
    {
      "code": "540600",
      "description": "Man-made filament yarn (other than sewing thread), put up for retail sale"
    },
    {
      "code": "54060010",
      "description": "MAN-MADE FILAMENT YARN (OTHER THAN SEWING THREAD), PUT UP FOR RETAIL SALE, SYNTHETIC FILAMENT YARN"
    },
    {
      "code": "54060020",
      "description": "MAN-MADE FILAMENT YARN (OTHER THAN SEWING THREAD), PUT UP FOR RETAIL SALE, ARTIFICIAL FILAMENT YARN"
    },
    {
      "code": "54061000",
      "description": "MAN-MADE FILAMENT YARN (OTHER THAN SEWING THREAD), PUT UP FOR RETAIL SALE SYNTHETIC FILAMENT YARN"
    },
    {
      "code": "54062000",
      "description": "MAN-MADE FILAMENT YARN (OTHER THAN SEWING THREAD), PUT UP FOR RETAIL SALE ARTIFICIAL FILAMENT YARN"
    },
    {
      "code": "5407",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404"
    },
    {
      "code": "540710",
      "description": "Woven fabrics obtained from high tenacity yarn of nylon or other polyamides or of polyesters"
    },
    {
      "code": "54071011",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : PARACHUTE FABRICS"
    },
    {
      "code": "54071012",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : TENT FABRICS"
    },
    {
      "code": "54071013",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : NYLON FURNISHING FABRICS"
    },
    {
      "code": "54071014",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : UMBRELLA CLOTH PANEL FABRICS"
    },
    {
      "code": "54071015",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : OTHER NYLON AND POLYAMIDE FABRICS (FILAMENT)"
    },
    {
      "code": "54071016",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : POLYESTER SUITINGS"
    },
    {
      "code": "54071019",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : UNBLEACHED : OTHER POLYESTER FABRICS"
    },
    {
      "code": "54071021",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : PARACHUTE FABRICS"
    },
    {
      "code": "54071022",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : TENT FABRICS"
    },
    {
      "code": "54071023",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : NYLON FURNISHING FABRICS"
    },
    {
      "code": "54071024",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : UMBRELLA CLOTH PANEL FABRICS"
    },
    {
      "code": "54071025",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : OTHER NYLON AND POLYAMIDE FABRICS OF FILAMENT YARN"
    },
    {
      "code": "54071026",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : POLYESTER SUITINGS"
    },
    {
      "code": "54071029",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : BLEACHED : OTHER"
    },
    {
      "code": "54071031",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :PARACHUTE FABRICS"
    },
    {
      "code": "54071032",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :TENT FABRICS"
    },
    {
      "code": "54071033",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :NYLON FURNISHING FABRICS"
    },
    {
      "code": "54071034",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :UMBRELLA CLOTH PANEL FABRICS"
    },
    {
      "code": "54071035",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :OTHER NYLON AND POLYAMIDE FABRICS (FILAMENT)"
    },
    {
      "code": "54071036",
      "description": "MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :POLYESTER SUITINGS"
    },
    {
      "code": "54071039",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : DYED :OTHER"
    },
    {
      "code": "54071041",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED : PARACHUTE FABRICS"
    },
    {
      "code": "54071042",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :TENT FABRICS"
    },
    {
      "code": "54071043",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :NYLON FURNISHING FABRICS"
    },
    {
      "code": "54071044",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :UMBRELLA CLOTH PANEL FABRICS"
    },
    {
      "code": "54071045",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :OTHER NYLON AND POLYAMIDE FABRICS (FILAMENT)"
    },
    {
      "code": "54071046",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :POLYESTER SUITINGS"
    },
    {
      "code": "54071049",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : PRINTED :POLYESTER SUITINGS"
    },
    {
      "code": "54071091",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER : PARACHUTE FABRICS"
    },
    {
      "code": "54071092",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :TENT FABRICS"
    },
    {
      "code": "54071093",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :NYLON FURNISHING FABRICS"
    },
    {
      "code": "54071094",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :UMBRELLA CLOTH PANEL FABRICS"
    },
    {
      "code": "54071095",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :OTHER NYLON AND POLYAMIDE FABRICS OF FILAMENT YARN"
    },
    {
      "code": "54071096",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :OTHER NYLON AND POLYAMIDE FABRICS OF FILAMENT YARN"
    },
    {
      "code": "54071099",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES OR OF POLYESTERS : OTHER :OTHER"
    },
    {
      "code": "540720",
      "description": "Woven fabrics obtained from strip or the like"
    },
    {
      "code": "54072010",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM STRIP OR THE LIKE :UNBLEACHED"
    },
    {
      "code": "54072020",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM STRIP OR THE LIKE : BLEACHED"
    },
    {
      "code": "54072030",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM STRIP OR THE LIKE :DYED"
    },
    {
      "code": "54072040",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM STRIP OR THE LIKE : PRINTED"
    },
    {
      "code": "54072090",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 WOVEN FABRICS OBTAINED FROM STRIP OR THE LIKE :OTHER"
    },
    {
      "code": "540730",
      "description": "Fabrics specified in Note 9 to Section XI"
    },
    {
      "code": "54073010",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 FABRICS SPECIFIED IN NOTE 9 TO SECTION XI UNBLEACHED"
    },
    {
      "code": "54073020",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 FABRICS SPECIFIED IN NOTE 9 TO SECTION XI BLEACHED"
    },
    {
      "code": "54073030",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 FABRICS SPECIFIED IN NOTE 9 TO SECTION XI DYED"
    },
    {
      "code": "54073040",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 FABRICS SPECIFIED IN NOTE 9 TO SECTION XI PRINTED"
    },
    {
      "code": "54073090",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 FABRICS SPECIFIED IN NOTE 9 TO SECTION XI OTHER"
    },
    {
      "code": "540741",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54074111",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON BRASSO"
    },
    {
      "code": "54074112",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON GEORGETTE"
    },
    {
      "code": "54074113",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON TAFETTA"
    },
    {
      "code": "54074114",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON SAREES"
    },
    {
      "code": "54074119",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : UNBLEACHED : OTHER"
    },
    {
      "code": "54074121",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : BLEACHED : NYLON BRASSO"
    },
    {
      "code": "54074122",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : BLEACHED : NYLON GEORGETTE"
    },
    {
      "code": "54074123",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : BLEACHED : NYLON TAFETTA"
    },
    {
      "code": "54074124",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : BLEACHED : NYLON SAREES"
    },
    {
      "code": "54074129",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : UNBLEACHED OR BLEACHED : BLEACHED : OTHER"
    },
    {
      "code": "540742",
      "description": "Dyed"
    },
    {
      "code": "54074210",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED : NYLON BRASSO"
    },
    {
      "code": "54074220",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED :NYLON GEORGETTE"
    },
    {
      "code": "54074230",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED :NYLON TAFETTA"
    },
    {
      "code": "54074240",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED :NYLON SAREES"
    },
    {
      "code": "54074290",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED :OTHER"
    },
    {
      "code": "54074300",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : DYED :OF YARN OF DIFFERENT COLOURS"
    },
    {
      "code": "540744",
      "description": "Printed"
    },
    {
      "code": "54074410",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : PRINTED : NYLON BRASSO"
    },
    {
      "code": "54074420",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : PRINTED :NYLON GEORGETTE"
    },
    {
      "code": "54074430",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : PRINTED :NYLON TAFETTA"
    },
    {
      "code": "54074440",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : PRINTED :NYLON SAREES,"
    },
    {
      "code": "54074490",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF FILAMENTS OF NYLON OR OTHER POLYAMIDES : PRINTED :OTHER"
    },
    {
      "code": "540751",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54075111",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: UNBLEACHED OR BLEACHED : UNBLEACHED : POLYESTER SHIRTINGS"
    },
    {
      "code": "54075119",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: UNBLEACHED OR BLEACHED : UNBLEACHED : OTHER"
    },
    {
      "code": "54075121",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: UNBLEACHED OR BLEACHED : BLEACHED : POLYESTER SHIRTINGS"
    },
    {
      "code": "54075129",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: UNBLEACHED OR BLEACHED : BLEACHED : OTHER"
    },
    {
      "code": "540752",
      "description": "Dyed"
    },
    {
      "code": "54075210",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 ERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED : POLYESTER SHIRTINGS"
    },
    {
      "code": "54075220",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED :POLYESTER SUITINGS"
    },
    {
      "code": "54075230",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED :TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54075240",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED :POLYESTER SAREES"
    },
    {
      "code": "54075290",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED :OTHER"
    },
    {
      "code": "54075300",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: DYED :OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "540754",
      "description": "Printed"
    },
    {
      "code": "54075410",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: PRINTED : TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54075420",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: PRINTED :POLYESTER SHIRTINGS"
    },
    {
      "code": "54075430",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: PRINTED :POLYESTER SAREES"
    },
    {
      "code": "54075490",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF TEXTURED POLYESTER FILAMENTS: PRINTED :OTHER"
    },
    {
      "code": "540761",
      "description": "Containing 85% or more by weight of nontextured polyester filaments"
    },
    {
      "code": "54076110",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF POLYESTER FILAMENTS : CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF NONTEXTURED POLYESTER FILAMENTS : POLYESTER SHIRTINGS"
    },
    {
      "code": "54076120",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF POLYESTER FILAMENTS : CONTAINING 85 PERCENT OR MORE BY WEIGHT OF NONTEXTURED POLYESTER FILAMENTS : POLYESTER SUITINGS"
    },
    {
      "code": "54076190",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT  OR MORE BY WEIGHT OF POLYESTER FILAMENTS : CONTAINING 85 PERCENT OR MORE BY WEIGHT OF NONTEXTURED POLYESTER FILAMENTS : OTHER"
    },
    {
      "code": "54076900",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85 PERCENT OR MORE BY WEIGHT OF POLYESTER FILAMENTS : OTHER"
    },
    {
      "code": "540771",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54077110",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC FILAMENTS : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "54077120",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC FILAMENTS : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "54077200",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC FILAMENTS : DYED"
    },
    {
      "code": "54077300",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC FILAMENTS : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "54077400",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC FILAMENTS : PRINTED"
    },
    {
      "code": "540781",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54078111",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON GEORGETTE"
    },
    {
      "code": "54078112",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT  BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : NYLON SAREES"
    },
    {
      "code": "54078113",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : POLYESTER SHIRTINGS"
    },
    {
      "code": "54078114",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : POLYESTER SUITINGS"
    },
    {
      "code": "54078115",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54078116",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : POLYESTER DHOTI"
    },
    {
      "code": "54078119",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85% BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED : OTHER"
    },
    {
      "code": "54078121",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : NYLON GEORGETTE"
    },
    {
      "code": "54078122",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : NYLON SAREES"
    },
    {
      "code": "54078123",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : POLYESTER SHIRTINGS"
    },
    {
      "code": "54078124",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : POLYESTER SUITINGS"
    },
    {
      "code": "54078125",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54078126",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : POLYESTER DHOTI"
    },
    {
      "code": "54078129",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85% BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED : OTHER"
    },
    {
      "code": "540782",
      "description": "Dyed"
    },
    {
      "code": "54078210",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED : NYLON GEORGETTE"
    },
    {
      "code": "54078220",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :NYLON SAREES"
    },
    {
      "code": "54078230",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :POLYESTER SHIRTINGS"
    },
    {
      "code": "54078240",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :POLYESTER SUITINGS"
    },
    {
      "code": "54078250",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54078260",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :LUNGIES"
    },
    {
      "code": "54078290",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85% BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :OTHER"
    },
    {
      "code": "54078300",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85% BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : DYED :OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "540784",
      "description": "Printed"
    },
    {
      "code": "54078410",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED : NYLON GEORGETTE"
    },
    {
      "code": "54078420",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :NYLON SAREES"
    },
    {
      "code": "54078430",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :POLYESTER SHIRTINGS"
    },
    {
      "code": "54078440",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :POLYESTER SUITINGS"
    },
    {
      "code": "54078450",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :TERYLENE AND DACRON SAREES"
    },
    {
      "code": "54078460",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :LUNGIES"
    },
    {
      "code": "54078470",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85 PERCENT BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :POLYESTER SAREES"
    },
    {
      "code": "54078490",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS, CONTAINING LESS THAN 85% BY WEIGHT OF SYNTHETIC FILAMENTS, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED :OTHER"
    },
    {
      "code": "540791",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54079110",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "54079120",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "54079200",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS : DYED"
    },
    {
      "code": "54079300",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "54079400",
      "description": "WOVEN FABRICS OF SYNTHETIC FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5404 OTHER WOVEN FABRICS : PRINTED"
    },
    {
      "code": "5408",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN,INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405"
    },
    {
      "code": "54081000",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405WOVEN FABRICS OBTAINED FROM HIGH TENACITY YARN OF VISCOSE RAYON"
    },
    {
      "code": "540821",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54082110",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "54082120",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "540822",
      "description": "Dyed"
    },
    {
      "code": "54082211",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON CREPE FABRICS"
    },
    {
      "code": "54082212",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON JACQUARDS"
    },
    {
      "code": "54082213",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON BROCADES"
    },
    {
      "code": "54082214",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON GEORGETTE"
    },
    {
      "code": "54082215",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON TAFETTA"
    },
    {
      "code": "54082216",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON SUITINGS"
    },
    {
      "code": "54082217",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON SHIRTINGS"
    },
    {
      "code": "54082218",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : RAYON SAREES"
    },
    {
      "code": "54082219",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF RAYON : OTHER"
    },
    {
      "code": "54082220",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : FABRICS OF CONTINUOUS FILAMENT, OTHER THAN RAYON"
    },
    {
      "code": "54082290",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : DYED : OTHER"
    },
    {
      "code": "54082300",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "540824",
      "description": "Printed"
    },
    {
      "code": "54082411",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON CREPE FABRICS"
    },
    {
      "code": "54082412",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON JACQUARDS"
    },
    {
      "code": "54082413",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON BROCADES"
    },
    {
      "code": "54082414",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON GEORGETTE"
    },
    {
      "code": "54082415",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON TAFETTA"
    },
    {
      "code": "54082416",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON SUITINGS"
    },
    {
      "code": "54082417",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON SHIRTINGS"
    },
    {
      "code": "54082418",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : RAYON SAREES"
    },
    {
      "code": "54082419",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OF RAYON : OTHER"
    },
    {
      "code": "54082490",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS, CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL FILAMENT OR STRIP OR THE LIKE : PRINTED : OTHER"
    },
    {
      "code": "540831",
      "description": "Unbleached or bleached"
    },
    {
      "code": "54083110",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "54083120",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "540832",
      "description": "Dyed"
    },
    {
      "code": "54083211",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : RAYON BROCADES"
    },
    {
      "code": "54083212",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : RAYON GEORGETTE"
    },
    {
      "code": "54083213",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : RAYON TAFETTA"
    },
    {
      "code": "54083214",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : RAYON SUITINGS"
    },
    {
      "code": "54083215",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : RAYON SHIRTINGS"
    },
    {
      "code": "54083219",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : FABRICS OF RAYON : OTHER"
    },
    {
      "code": "54083290",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : DYED : OTHER"
    },
    {
      "code": "54083300",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "540834",
      "description": "Printed"
    },
    {
      "code": "54083411",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON CREPE FABRICS"
    },
    {
      "code": "54083412",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON JACQUARDS"
    },
    {
      "code": "54083413",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON BROCADES"
    },
    {
      "code": "54083414",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON GEORGETTE"
    },
    {
      "code": "54083415",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON TAFETTA"
    },
    {
      "code": "54083416",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON SUITINGS"
    },
    {
      "code": "54083417",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON SHIRTINGS"
    },
    {
      "code": "54083418",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : RAYON SAREES"
    },
    {
      "code": "54083419",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRIC OF RAYON : OTHER"
    },
    {
      "code": "54083420",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED : FABRICS OF CONTINUOUS FILAMENT, OTHER THAN RAYON"
    },
    {
      "code": "54083490",
      "description": "WOVEN FABRICS OF ARTIFICIAL FILAMENT YARN, INCLUDING WOVEN FABRICS OBTAINED FROM MATERIALS OF HEADING 5405 OTHER WOVEN FABRICS : PRINTED :OTHER"
    },
    {
      "code": "55",
      "description": "Man-made staple fibres"
    },
    {
      "code": "5501",
      "description": "WOOL, NOT CARDED OR COMBED : SYNTHETIC FILAMENT TOW"
    },
    {
      "code": "55011000",
      "description": "SYNTHETIC FILAMENT TOW - OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "55012000",
      "description": "SYNTHETIC FILAMENT TOW - OF POLYESTERS"
    },
    {
      "code": "55013000",
      "description": "SYNTHETIC FILAMENT TOW - ACRYLIC OR MODACRYLIC"
    },
    {
      "code": "55014000",
      "description": "SYNTHETIC FILAMENT TOW - OF POLYPROPYLENE"
    },
    {
      "code": "550190",
      "description": "Other"
    },
    {
      "code": "55019010",
      "description": "SYNTHETIC FILAMENT TOW - OTHER : OF POLYPROPYLENE"
    },
    {
      "code": "55019090",
      "description": "SYNTHETIC FILAMENT TOW - OTHER : OTHER"
    },
    {
      "code": "5502",
      "description": "ARTIFICIAL FILAMENT TOW"
    },
    {
      "code": "550200",
      "description": "Artificial filament tow"
    },
    {
      "code": "55020010",
      "description": "ARTIFICIAL FILAMENT TOW - ARTIFICIAL FILAMENT TOW : ACETATE RAYON TOW"
    },
    {
      "code": "55020020",
      "description": "ARTIFICIAL FILAMENT TOW - ARTIFICIAL FILAMENT TOW : VISCOSE RAYON TOW"
    },
    {
      "code": "55020090",
      "description": "ARTIFICIAL FILAMENT TOW - ARTIFICIAL FILAMENT TOW : OTHER"
    },
    {
      "code": "550210",
      "description": "Of cellulose acetate;"
    },
    {
      "code": "55021010",
      "description": "Viscose rayon tow"
    },
    {
      "code": "55021090",
      "description": "Other"
    },
    {
      "code": "550290",
      "description": "Other;"
    },
    {
      "code": "55029010",
      "description": "Viscose rayon tow"
    },
    {
      "code": "55029090",
      "description": "other"
    },
    {
      "code": "5503",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - Of nylon or other polyamides"
    },
    {
      "code": "55031000",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "55031100",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF NYLON OR OTHER POLYAMIDES - OF ARAMIDES"
    },
    {
      "code": "55031900",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF NYLON OR OTHER POLYAMIDES-OTHERS"
    },
    {
      "code": "55032000",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF POLYESTERS"
    },
    {
      "code": "55033000",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ACRYLIC OR MODACRYLIC"
    },
    {
      "code": "55034000",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF POLYPROPYLENE"
    },
    {
      "code": "550390",
      "description": "Other"
    },
    {
      "code": "55039010",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER : POLYVINYL STAPLE FIBRE"
    },
    {
      "code": "55039020",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER : POLYVINYL CHLORIDE STAPLE FIBRE"
    },
    {
      "code": "55039090",
      "description": "SYNTHETIC STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER : OTHER"
    },
    {
      "code": "5504",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING"
    },
    {
      "code": "550410",
      "description": "Of viscose rayon"
    },
    {
      "code": "55041000",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF VISCOSE RAYON"
    },
    {
      "code": "55041010",
      "description": "Obtained from wood other than bamboo"
    },
    {
      "code": "55041020",
      "description": "Obtained from bamboo"
    },
    {
      "code": "55041090",
      "description": "Other"
    },
    {
      "code": "550490",
      "description": "Other"
    },
    {
      "code": "55049010",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER ACETATE RAYON STAPLE FIBRE"
    },
    {
      "code": "55049020",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER POLYNOSIC STAPLE FIBRE"
    },
    {
      "code": "55049030",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER HIGH WET MODULUS STAPLE FIBRE"
    },
    {
      "code": "55049090",
      "description": "ARTIFICIAL STAPLE FIBRES, NOT CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER OTHER"
    },
    {
      "code": "5505",
      "description": "WASTE (INCLUDING NOILS, YARN WASTE AND GARNETTED STOCK) OF  MAN-MADE FIBRES"
    },
    {
      "code": "550510",
      "description": "Of synthetic fibers"
    },
    {
      "code": "55051010",
      "description": "WASTE (INCLUDING NOILS, YARN WASTE AND GARNETTED STOCK) OF MAN-MADE FIBRES - OF SYNTHETIC FIBERS : OF ACRYLIC"
    },
    {
      "code": "55051090",
      "description": "WASTE (INCLUDING NOILS, YARN WASTE AND GARNETTED STOCK) OF MAN-MADE FIBRES - OF SYNTHETIC FIBERS : OTHER"
    },
    {
      "code": "55052000",
      "description": "WASTE (INCLUDING NOILS, YARN WASTE AND GARNETTED STOCK) OF MAN-MADE FIBRES OF ARTIFICIAL FIBRES"
    },
    {
      "code": "5506",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING"
    },
    {
      "code": "55061000",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF NYLON OR OTHER POLYAMIDES"
    },
    {
      "code": "55062000",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING - OF POLYESTERS"
    },
    {
      "code": "55063000",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING - ACRYLIC OR MODACRYLIC"
    },
    {
      "code": "55064000",
      "description": "Of polypropylene"
    },
    {
      "code": "550690",
      "description": "Other"
    },
    {
      "code": "55069010",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER : POLYPROPYLENE TOPS"
    },
    {
      "code": "55069090",
      "description": "SYNTHETIC STAPLE FIBRES, CARDED COMBED OR OTHERWISE PROCESSED FOR SPINNING - OTHER : OTHER"
    },
    {
      "code": "5507",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING"
    },
    {
      "code": "550700",
      "description": "Artificial staple fibres, carded, combed or otherwise processed for spinning"
    },
    {
      "code": "55070010",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING : ACETATE RAYON TOPS"
    },
    {
      "code": "55070020",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING : VISCOSE TOPS"
    },
    {
      "code": "55070030",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING : POLYNOSIC TOPS"
    },
    {
      "code": "55070040",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING : HIGH WET MODULUS TOPS"
    },
    {
      "code": "55070090",
      "description": "ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING - ARTIFICIAL STAPLE FIBRES, CARDED, COMBED OR OTHERWISE PROCESSED FOR SPINNING : OTHER"
    },
    {
      "code": "5508",
      "description": "SEWING THREAD OF MAN-MADE STAPLE FIBRES, WHETHER OR NOT PUT UP FOR RETAIL SALE"
    },
    {
      "code": "55081000",
      "description": "SEWING THREAD OF MAN-MADE STAPLE FIBRES, WHETHER OR NOT PUT UP FOR RETAIL SALE - OF SYNTHETIC STAPLE FIBRES"
    },
    {
      "code": "55082000",
      "description": "SEWING THREAD OF MAN-MADE STAPLE FIBRES, WHETHER OR NOT PUT UP FOR RETAIL SALE - OF ARTIFICIAL STAPLE FIBRES"
    },
    {
      "code": "5509",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - Containing 85% or more by weight of staple fibres of nylon or other polyamides"
    },
    {
      "code": "55091100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF STAPLE FIBRES OF NYLON OR OTHER POLYAMIDES : SINGLE YARN"
    },
    {
      "code": "55091200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF STAPLE FIBRES OF NYLON OR OTHER POLYAMIDES : MULTIPLE (FOLDED) OR CABLED YARN"
    },
    {
      "code": "55092100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : SINGLE YARN"
    },
    {
      "code": "55092200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN"
    },
    {
      "code": "55093100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : SINGLE YARN"
    },
    {
      "code": "55093200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN"
    },
    {
      "code": "550941",
      "description": "Single yarn"
    },
    {
      "code": "55094110",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : SINGLE YARN : POLYPROPYLENE SPUN YARN"
    },
    {
      "code": "55094120",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : SINGLE YARN : POLYVINYL ACETATE SPUN YARN"
    },
    {
      "code": "55094130",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : SINGLE YARN : POLYVINYL CHLORIDE SPUN YARN"
    },
    {
      "code": "55094190",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : SINGLE YARN : OTHER"
    },
    {
      "code": "550942",
      "description": "Multiple (folded) or cabled yarn"
    },
    {
      "code": "55094210",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : POLYPROPYLENE SPUN YARN"
    },
    {
      "code": "55094220",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : POLYVINYL ACETATE (PVA) SPUN YARN"
    },
    {
      "code": "55094230",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : POLYVINYL CHLORIDE (PVC) SPUN YARN"
    },
    {
      "code": "55094290",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : OTHER"
    },
    {
      "code": "55095100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH ARTIFICIAL STAPLE FIBRES"
    },
    {
      "code": "55095200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "55095300",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH COTTON"
    },
    {
      "code": "55095900",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF POLYESTER STAPLE FIBRES : OTHER"
    },
    {
      "code": "55096100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "55096200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH COTTON"
    },
    {
      "code": "55096900",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER"
    },
    {
      "code": "55099100",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "55099200",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : MIXED MAINLY OR SOLELY WITH COTTON"
    },
    {
      "code": "55099900",
      "description": "YARN (OTHER THAN SEWING THREAD) OF SYNTHETIC STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : OTHER"
    },
    {
      "code": "5510",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - Containing 85% or more by weight of artificial staple fibres"
    },
    {
      "code": "551011",
      "description": "Single yarn"
    },
    {
      "code": "55101110",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : SINGLE YARN : VISCOSE RAYON SPUN YARN"
    },
    {
      "code": "55101120",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : SINGLE YARN : ACETATE RAYON SPUN YARN"
    },
    {
      "code": "55101190",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : SINGLE YARN : OTHER"
    },
    {
      "code": "551012",
      "description": "Multiple (folded) or cabled yarn"
    },
    {
      "code": "55101210",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : VISCOSE RAYON SPUN YARN"
    },
    {
      "code": "55101220",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : ACETATE RAYON SPUN YARN"
    },
    {
      "code": "55101290",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : MULTIPLE (FOLDED) OR CABLED YARN : OTHER"
    },
    {
      "code": "551020",
      "description": "Other yarn, mixed mainly or solely with wool or fine animal hair"
    },
    {
      "code": "55102010",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : VISCOSE RAYON SPUN YARN"
    },
    {
      "code": "55102020",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : ACETATE RAYON SPUN YARN"
    },
    {
      "code": "55102090",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "551030",
      "description": "Other yarn, mixed mainly or solely with cotton"
    },
    {
      "code": "55103010",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH COTTON : VISCOSE RAYON SPUN YARN"
    },
    {
      "code": "55103020",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH COTTON : ACETATE RAYON SPUN YARN"
    },
    {
      "code": "55103090",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN, MIXED MAINLY OR SOLELY WITH COTTON : OTHER"
    },
    {
      "code": "551090",
      "description": "Other yarn"
    },
    {
      "code": "55109010",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : VISCOSE RAYON SPUN YARN"
    },
    {
      "code": "55109020",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : ACETATE RAYON SPUN YARN"
    },
    {
      "code": "55109090",
      "description": "YARN (OTHER THAN SEWING THREAD) OF ARTIFICIAL STAPLE FIBRES, NOT PUT UP FOR RETAIL SALE - OTHER YARN : OTHER"
    },
    {
      "code": "5511",
      "description": "YARN (OTHER THAN SEWING THREAD) OF MAN-MADE STAPLE FIBRES, PUT UP FOR RETAIL SALE"
    },
    {
      "code": "55111000",
      "description": "YARN (OTHER THAN SEWING THREAD) OF MANMADE STAPLE FIBRES, PUT UP FOR RETAIL SALE - OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SUCH FIBRES"
    },
    {
      "code": "55112000",
      "description": "YARN (OTHER THAN SEWING THREAD) OF MANMADE STAPLE FIBRES, PUT UP FOR RETAIL SALE - OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES"
    },
    {
      "code": "551130",
      "description": "Of artificial staple fibres"
    },
    {
      "code": "55113010",
      "description": "YARN (OTHER THAN SEWING THREAD) OF MANMADE STAPLE FIBRES, PUT UP FOR RETAIL SALE - OF ARTIFICIAL STAPLE FIBRES : CONTAINING MORE THAN 85% BY WEIGHT OF STAPLE FIBRE"
    },
    {
      "code": "55113090",
      "description": "YARN (OTHER THAN SEWING THREAD) OF MANMADE STAPLE FIBRES, PUT UP FOR RETAIL SALE - OF ARTIFICIAL STAPLE FIBRES : OTHER"
    },
    {
      "code": "5512",
      "description": "Woven fabrics of synthetic staple fibres, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - Containing 85% or more by weight of polyester staple fibres"
    },
    {
      "code": "551211",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55121110",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55121120",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "551219",
      "description": "Other"
    },
    {
      "code": "55121910",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : OTHER : DYED"
    },
    {
      "code": "55121920",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : OTHER : PRINTED"
    },
    {
      "code": "55121990",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF POLYESTER STAPLE FIBRES : OTHER : OTHER"
    },
    {
      "code": "551221",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55122110",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55122120",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "551229",
      "description": "Other"
    },
    {
      "code": "55122910",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : DYED"
    },
    {
      "code": "55122920",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : PRINTED"
    },
    {
      "code": "55122990",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : OTHER"
    },
    {
      "code": "551291",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55129110",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - OTHER : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55129120",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - OTHER : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "551299",
      "description": "Other"
    },
    {
      "code": "55129910",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - OTHER : OTHER : DYED"
    },
    {
      "code": "55129920",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - OTHER : OTHER : PRINTED"
    },
    {
      "code": "55129990",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING 85% OR MORE BY WEIGHT OF SYNTHETIC STAPLE FIBRES - OTHER : OTHER : OTHER"
    },
    {
      "code": "5513",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 g/m2 - Unbleached or bleached"
    },
    {
      "code": "551311",
      "description": "Of polyester staple fibres, plain weave"
    },
    {
      "code": "55131110",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE : UNBLEACHED"
    },
    {
      "code": "55131120",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE : BLEACHED"
    },
    {
      "code": "551312",
      "description": "3-thread or 4-thread twill, including cross twill, of polyester staple fibres"
    },
    {
      "code": "55131210",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "55131220",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "551313",
      "description": "Other woven fabrics of polyester staple fibres"
    },
    {
      "code": "55131310",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "55131320",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "551319",
      "description": "Other woven fabrics"
    },
    {
      "code": "55131910",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS : UNBLEACHED"
    },
    {
      "code": "55131920",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS : BLEACHED"
    },
    {
      "code": "55132100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - DYED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55132200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55132300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - DYED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55132900",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - DYED : OTHER WOVEN FABRICS"
    },
    {
      "code": "55133100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55133200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55133300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55133900",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER WOVEN FABRICS"
    },
    {
      "code": "55134100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - PRINTED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55134200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - PRINTED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55134300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - PRINTED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55134900",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT NOT EXCEEDING 170 G/M2 - PRINTED : OTHER WOVEN FABRICS"
    },
    {
      "code": "5514",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - Unbleached or bleached"
    },
    {
      "code": "551411",
      "description": "Of polyester staple fibres, plain weave"
    },
    {
      "code": "55141110",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE : UNBLEACHED"
    },
    {
      "code": "55141120",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE : BLEACHED"
    },
    {
      "code": "551412",
      "description": "3-thread or 4-thread twill, including cross twill, of polyester staple fibres"
    },
    {
      "code": "55141210",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "55141220",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "55141310",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "55141320",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "551419",
      "description": "Other"
    },
    {
      "code": "55141910",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER : UNBLEACHED"
    },
    {
      "code": "55141920",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - UNBLEACHED OR BLEACHED : OTHER : BLEACHED"
    },
    {
      "code": "55142100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - DYED : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55142200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - DYED : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55142300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - DYED : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55142900",
      "description": "OTHER WOVEN FABRICS"
    },
    {
      "code": "551430",
      "description": "Of yarns of different colours"
    },
    {
      "code": "55143011",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2-OF YARNS OF DIFFERENT COLOURS: ---- OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55143012",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2- OF YARNS OF DIFFERENT COLOURS: ---- 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55143013",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS: ---- OTHER WOVEN FBBRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55143019",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS:---- OTHER WOVEN FABRICS"
    },
    {
      "code": "55143100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55143200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55143300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55143900",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - OF YARNS OF DIFFERENT COLOURS : OTHER WOVEN FABRICS"
    },
    {
      "code": "55144100",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - PRINTED OF POLYESTER STAPLE FIBRES, PLAIN WEAVE"
    },
    {
      "code": "55144200",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - PRINTED 3-THREAD OR 4-THREAD TWILL, INCLUDING CROSS TWILL, OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55144300",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - PRINTED OTHER WOVEN FABRICS OF POLYESTER STAPLE FIBRES"
    },
    {
      "code": "55144900",
      "description": "WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES, CONTAINING LESS THAN 85% BY WEIGHT OF SUCH FIBRES, MIXED MAINLY OR SOLELY WITH COTTON, OF A WEIGHT EXCEEDING 170 G/M2 - PRINTED OTHER WOVEN FABRICS"
    },
    {
      "code": "5515",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - Of polyester staple fibres"
    },
    {
      "code": "551511",
      "description": "Mixed mainly or solely with viscos rayon staple fibres"
    },
    {
      "code": "55151110",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH VISCOSE RAYON STAPLE FIBRES : UNBLEACHED"
    },
    {
      "code": "55151120",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH VISCOSE RAYON STAPLE FIBRES : BLEACHED"
    },
    {
      "code": "55151130",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH VISCOSE RAYON STAPLE FIBRES : DYED"
    },
    {
      "code": "55151140",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH VISCOSE RAYON STAPLE FIBRES : PRINTED"
    },
    {
      "code": "55151190",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH VISCOSE RAYON STAPLE FIBRES : OTHER"
    },
    {
      "code": "551512",
      "description": "Mixed mainly or solely with man-made filaments"
    },
    {
      "code": "55151210",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : UNBLEACHED"
    },
    {
      "code": "55151220",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : BLEACHED"
    },
    {
      "code": "55151230",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : DYED"
    },
    {
      "code": "55151240",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : PRINTED"
    },
    {
      "code": "55151290",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : OTHER"
    },
    {
      "code": "551513",
      "description": "Mixed mainly or solely with wool or fine animal hair"
    },
    {
      "code": "55151310",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : UNBLEACHED"
    },
    {
      "code": "55151320",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : BLEACHED"
    },
    {
      "code": "55151330",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : DYED"
    },
    {
      "code": "55151340",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : PRINTED"
    },
    {
      "code": "55151390",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "551519",
      "description": "Other"
    },
    {
      "code": "55151910",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : OTHER : UNBLEACHED"
    },
    {
      "code": "55151920",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : OTHER : BLEACHED"
    },
    {
      "code": "55151930",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : OTHER : DYED"
    },
    {
      "code": "55151940",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : OTHER : PRINTED"
    },
    {
      "code": "55151990",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF POLYESTER STAPLE FIBRES : OTHER : OTHER"
    },
    {
      "code": "551521",
      "description": "Mixed mainly or solely with man-made filaments"
    },
    {
      "code": "55152110",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : UNBLEACHED"
    },
    {
      "code": "55152120",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : BLEACHED"
    },
    {
      "code": "55152130",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : DYED"
    },
    {
      "code": "55152140",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : PRINTED"
    },
    {
      "code": "55152190",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : OTHER"
    },
    {
      "code": "551522",
      "description": "Mixed mainly or solely with wool or fine animal hair"
    },
    {
      "code": "55152210",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : UNBLEACHED"
    },
    {
      "code": "55152220",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : BLEACHED"
    },
    {
      "code": "55152230",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : DYED"
    },
    {
      "code": "55152240",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : PRINTED"
    },
    {
      "code": "55152290",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "551529",
      "description": "Other"
    },
    {
      "code": "55152910",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : UNBLEACHED"
    },
    {
      "code": "55152920",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : BLEACHED"
    },
    {
      "code": "55152930",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : DYED"
    },
    {
      "code": "55152940",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : PRINTED"
    },
    {
      "code": "55152990",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OF ACRYLIC OR MODACRYLIC STAPLE FIBRES : OTHER : OTHER"
    },
    {
      "code": "551591",
      "description": "Mixed mainly or solely with man-made filaments"
    },
    {
      "code": "55159110",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : UNBLEACHED"
    },
    {
      "code": "55159120",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : BLEACHED"
    },
    {
      "code": "55159130",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : DYED"
    },
    {
      "code": "55159140",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : PRINTED"
    },
    {
      "code": "55159190",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH MAN-MADE FILAMENTS : OTHER"
    },
    {
      "code": "55159210",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : UNBLEACHED"
    },
    {
      "code": "55159220",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : BLEACHED"
    },
    {
      "code": "55159230",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : DYED"
    },
    {
      "code": "55159240",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : PRINTED"
    },
    {
      "code": "55159290",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "551599",
      "description": "Other"
    },
    {
      "code": "55159910",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : OTHER : UNBLEACHED"
    },
    {
      "code": "55159920",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : OTHER : BLEACHED"
    },
    {
      "code": "55159930",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : OTHER : DYED"
    },
    {
      "code": "55159940",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : OTHER : PRINTED"
    },
    {
      "code": "55159990",
      "description": "OTHER WOVEN FABRICS OF SYNTHETIC STAPLE FIBRES - OTHER WOVEN FABRICS : OTHER : OTHER"
    },
    {
      "code": "5516",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - Containing 85% or more by weight of artificial staple fibres"
    },
    {
      "code": "551611",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55161110",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55161120",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "55161200",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : DYED"
    },
    {
      "code": "55161300",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "551614",
      "description": "Printed"
    },
    {
      "code": "55161410",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : PRINTED : SPUN RAYON PRINTED SHANTUNG"
    },
    {
      "code": "55161420",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : PRINTED : SPUN RAYON PRINTED LINEN"
    },
    {
      "code": "55161490",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING 85% OR MORE BY WEIGHT OF ARTIFICIAL STAPLE FIBRES : PRINTED : OTHER"
    },
    {
      "code": "551621",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55162110",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH MANMADE FILAMENTS : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55162120",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH MANMADE FILAMENTS : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "55162200",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH MANMADE FILAMENTS : DYED"
    },
    {
      "code": "55162300",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH MANMADE FILAMENTS : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "55162400",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH MANMADE FILAMENTS : PRINTED"
    },
    {
      "code": "551631",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55163110",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55163120",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "55163200",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : DYED"
    },
    {
      "code": "55163300",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "55163400",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH WOOL OR FINE ANIMAL HAIR : PRINTED"
    },
    {
      "code": "551641",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55164110",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55164120",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH COTTON : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "55164200",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH COTTON : DYED"
    },
    {
      "code": "55164300",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH COTTON : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "55164400",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - CONTAINING LESS THAN 85% BY WEIGHT OF ARTIFICIAL STAPLE FIBRES, MIXED MAINLY OR SOLELY WITH COTTON : PRINTED"
    },
    {
      "code": "551691",
      "description": "Unbleached or bleached"
    },
    {
      "code": "55169110",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - OTHER : UNBLEACHED OR BLEACHED : UNBLEACHED"
    },
    {
      "code": "55169120",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - OTHER : UNBLEACHED OR BLEACHED : BLEACHED"
    },
    {
      "code": "55169200",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - OTHER : DYED"
    },
    {
      "code": "55169300",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - OTHER : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "55169400",
      "description": "WOVEN FABRICS OF ARTIFICIAL STAPLE FIBRES - OTHER : PRINTED"
    },
    {
      "code": "56",
      "description": "Wadding, felt and nonwovens; special yarns; twine, cordage, ropes and cables and articles thereof"
    },
    {
      "code": "5601",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS ---- Wadding; other articles of wadding"
    },
    {
      "code": "56011000",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS SANITARY TOWELS AND TAMPONS, NAPKINS AND NAPKIN LINERS FOR BABIES AND SIMILAR SANITARY ARTICLES, OF WADDING"
    },
    {
      "code": "560121",
      "description": "Of cotton"
    },
    {
      "code": "56012110",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS - WADDING; OTHER ARTICLES OF WADDING : OF COTTON : ABSORBENT COTTON WOOL"
    },
    {
      "code": "56012190",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS - WADDING; OTHER ARTICLES OF WADDING : OF COTTON : OTHER"
    },
    {
      "code": "56012200",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS - WADDING; OTHER ARTICLES OF WADDING : OF MAN-MADE FIBRES"
    },
    {
      "code": "56012900",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS - WADDING; OTHER ARTICLES OF WADDING : OTHER"
    },
    {
      "code": "56013000",
      "description": "WADDING OF TEXTILE MATERIALS AND ARTICLES THEREOF; TEXTILE FIBRES, NOT EXCEEDING 5 MM IN LENGTH (FLOCK), TEXTILE DUST AND MILL NEPS TEXTILE FLOCK AND DUST AND MILL NEPS"
    },
    {
      "code": "5602",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED"
    },
    {
      "code": "56021000",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED NEEDLELOOM FELT AND STITCH-BONDED FIBRE FABRICS"
    },
    {
      "code": "56022100",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER FELT, NOT IMPREGNATED, COATED, COVERED OR LAMINATED : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "560229",
      "description": "Of other textile materials"
    },
    {
      "code": "56022910",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER FELT, NOT IMPREGNATED, COATED, COVERED OR LAMINATED : - OF OTHER TEXTILE MATERIALS : FOR MACHINES OTHER THAN COTTON MACHINERY"
    },
    {
      "code": "56022920",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER FELT, NOT IMPREGNATED, COATED, COVERED OR LAMINATED : - OF OTHER TEXTILE MATERIALS : OF JUTE (INCLUDING BLENDED OR UNION JUTE), OTHER THAN FOR MACHINERY"
    },
    {
      "code": "56022990",
      "description": "FELT, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER FELT, NOT IMPREGNATED, COATED, COVERED OR LAMINATED : - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "56029010",
      "description": "FELT,WHETHER OR NOT IMPREGNATED, COATED,COVERED OR LAMINATED -OTHER -OF RUBBERISED COIR NEEDLED FELT"
    },
    {
      "code": "56029090",
      "description": "FELT,WHETHER OR NOT IMPREGNATED, COATED,COVERED OR LAMINATED -OTHER - - - OTHER"
    },
    {
      "code": "5603",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - Of man-made filaments"
    },
    {
      "code": "56031100",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OF MAN-MADE FILAMENTS : WEIGHING NOT MORE THAN 25 G/M2"
    },
    {
      "code": "56031200",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OF MAN-MADE FILAMENTS : WEIGHING MORE THAN 25 G/M2 BUT NOT MORE THAN 70 G/M2"
    },
    {
      "code": "56031300",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OF MAN-MADE FILAMENTS : WEIGHING MORE THAN 70 G/M2 BUT NOT MORE THAN 150 G/M2"
    },
    {
      "code": "56031400",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OF MAN-MADE FILAMENTS : WEIGHING MORE THAN 150 G/M2"
    },
    {
      "code": "56039100",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER : WEIGHING NOT MORE THAN 25 G/M2"
    },
    {
      "code": "56039200",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER : WEIGHING MORE THAN 25 G/M2 BUT NOT MORE THAN 70 G/M2"
    },
    {
      "code": "56039300",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER : WEIGHING MORE THAN 70 G/M2 BUT NOT MORE THAN 150 G/M2"
    },
    {
      "code": "56039400",
      "description": "NONWOVENS, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED - OTHER : WEIGHING MORE THAN 150 G/M2"
    },
    {
      "code": "5604",
      "description": "RUBBER THREAD AND CORD, TEXTILE COVERED; TEXTILE YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS"
    },
    {
      "code": "56041000",
      "description": "RUBBER THREAD AND CORD, TEXTILE COVERED; TEXTILE YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - RUBBER THREAD AND CORD, TEXTILE COVERED"
    },
    {
      "code": "56042010",
      "description": "RUBBER THREAD AND CORD, TEXTILE COVERED; TEXTILE YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - HIGH TENACITY YARN OF POLYESTERS, OF NYLON OR OTHER POLYAMIDES OR OF VISCOSE RAYON, IMPREGNATED OR COATED : IMITATION GUT (NYLON) FOR RACKETS"
    },
    {
      "code": "56042090",
      "description": "RUBBER THREAD AND CORD, TEXTILE COVERED; TEXTILE YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - HIGH TENACITY YARN OF POLYESTERS, OF NYLON OR OTHER POLYAMIDES OR OF VISCOSE RAYON, IMPREGNATED OR COATED : OTHER"
    },
    {
      "code": "56049000",
      "description": "RUBBER THREAD AND CORD, TEXTILE COVERED; TEXTILE YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OTHER"
    },
    {
      "code": "5605",
      "description": "METALLISED YARN, WHETHER OR NOT GIMPED BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL"
    },
    {
      "code": "560500",
      "description": "Metallised yarn, whether or not gimped, being textile yarn, or strip or the like of heading 5404 or 5405, combined with metal in the form of thread, strip or powder or covered with metal"
    },
    {
      "code": "56050010",
      "description": "METALLISED YARN, WHETHER OR NOT GIMPED BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL - METALLISED YARN, WHETHER OR NOT GIMPED, BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL : REAL ZARI THREAD (GOLD) AND SILVER THREAD COMBINED WITH TEXTILE THREAD"
    },
    {
      "code": "56050020",
      "description": "METALLISED YARN, WHETHER OR NOT GIMPED BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL - METALLISED YARN, WHETHER OR NOT GIMPED, BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL : IMITATION ZARI THREAD"
    },
    {
      "code": "56050090",
      "description": "METALLISED YARN, WHETHER OR NOT GIMPED BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL - METALLISED YARN, WHETHER OR NOT GIMPED, BEING TEXTILE YARN, OR STRIP OR THE LIKE OF HEADING 5404 OR 5405, COMBINED WITH METAL IN THE FORM OF THREAD, STRIP OR POWDER OR COVERED WITH METAL : OTHER"
    },
    {
      "code": "5606",
      "description": "GIMPED YARN, AND STRIP AND THE LIKE  OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN"
    },
    {
      "code": "560600",
      "description": "Gimped yarn, and strip and the like of heading 5404 or 5405, gimped (other than those of heading 5605 and gimped horsehair yarn); chenille yarn (including flock chenille yarn); loop wale-yarn"
    },
    {
      "code": "56060010",
      "description": "GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN - GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN : TRIMMINGS, OF COTTON"
    },
    {
      "code": "56060020",
      "description": "GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN - GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN : TRIMMINGS, OF MAN-MADE FIBRES"
    },
    {
      "code": "56060030",
      "description": "GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN - GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN : TRIMMINGS, OF ZARI"
    },
    {
      "code": "56060090",
      "description": "GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN - GIMPED YARN, AND STRIP AND THE LIKE OF HEADING 5404 OR 5405, GIMPED (OTHER THAN THOSE OF HEADING 5605 AND GIMPED HORSEHAIR YARN); CHENILLE YARN (INCLUDING FLOCK CHENILLE YARN); LOOP WALE-YARN : OTHER"
    },
    {
      "code": "5607",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - Of sisal or other textile fibres of the genus Agave"
    },
    {
      "code": "56071010",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF JUTE OR OTHER TEXTILE BAST FIBRES OF HEADING 5303 : CORDAGE, CABLE, ROPE AND TWINE OF JUTE"
    },
    {
      "code": "56071090",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF JUTE OR OTHER TEXTILE BAST FIBRES OF HEADING 5303 : OTHER"
    },
    {
      "code": "56072100",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF SISAL OR OTHER TEXTILE FIBRES OF THE GENUS AGAVE : BINDER OR BALER TWINE"
    },
    {
      "code": "56072900",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF SISAL OR OTHER TEXTILE FIBRES OF THE GENUS AGAVE : OTHER"
    },
    {
      "code": "56073000",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS OF ABACA (MANILA HEMP OR MUSA TEXTILLIS NEE) OR OTHER HARD (LEAF) FIBRES"
    },
    {
      "code": "56074100",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF POLYETHYLENE OR POLYPROPYLENE : BINDER OR BALER TWINE"
    },
    {
      "code": "56074900",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF POLYETHYLENE OR POLYPROPYLENE : OTHER"
    },
    {
      "code": "560750",
      "description": "Of other synthetic fibres"
    },
    {
      "code": "56075010",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF OTHER SYNTHETIC FIBRES : NYLON FISH NET TWINE"
    },
    {
      "code": "56075020",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF OTHER SYNTHETIC FIBRES : NYLON TYRE CORD"
    },
    {
      "code": "56075030",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF OTHER SYNTHETIC FIBRES : VISCOSE TYRE CORD"
    },
    {
      "code": "56075040",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF OTHER SYNTHETIC FIBRES : NYLON ROPE"
    },
    {
      "code": "56075090",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OF OTHER SYNTHETIC FIBRES : OTHER"
    },
    {
      "code": "560790",
      "description": "Other"
    },
    {
      "code": "56079010",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OTHER : COIR, CORDAGE AND ROPES, OTHER THAN OF COTTON"
    },
    {
      "code": "56079020",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OTHER : CORDAGE, CABLE, ROPES AND TWINE, OF COTTON"
    },
    {
      "code": "56079090",
      "description": "TWINE, CORDAGE, ROPES AND CABLES, WHETHER OR NOT PLAITED OR BRAIDED AND WHETHER OR NOT IMPREGNATED, COATED, COVERED OR SHEATHED WITH RUBBER OR PLASTICS - OTHER : OTHER"
    },
    {
      "code": "5608",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - Of man-made textile materials"
    },
    {
      "code": "560811",
      "description": "Made up fishing nets"
    },
    {
      "code": "56081110",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OF MAN-MADE TEXTILE MATERIALS : - MADE UP FISHING NETS : MADE UP FISHING NETS OF NYLON"
    },
    {
      "code": "56081190",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OF MAN-MADE TEXTILE MATERIALS : - MADE UP FISHING NETS : OTHER"
    },
    {
      "code": "56081900",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OF MAN-MADE TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "560890",
      "description": "Other"
    },
    {
      "code": "56089010",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OTHER : OF COTTON"
    },
    {
      "code": "56089020",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OTHER : OF JUTE"
    },
    {
      "code": "56089090",
      "description": "KNOTTED NETTING OF TWINE, CORDAGE OR ROPE; MADE UP FISHING NETS AND OTHER MADE UP NETS, OF TEXTILE MATERIALS - OTHER : OTHER"
    },
    {
      "code": "5609",
      "description": "ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "560900",
      "description": "Articles of yarn, strip or the like of heading 5404 or 5405, twine, cordage, rope or cables, not elsewhere specified or included"
    },
    {
      "code": "56090010",
      "description": "ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED - ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED : PRODUCTS OF COIR"
    },
    {
      "code": "56090020",
      "description": "ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED - ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED : ARTICLES MADE UP OF COTTON YARN"
    },
    {
      "code": "56090030",
      "description": "ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED - ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED : ARTICLES MADE UP OF JUTE"
    },
    {
      "code": "56090090",
      "description": "ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED - ARTICLES OF YARN, STRIP OR THE LIKE OF HEADING 5404 OR 5405, TWINE, CORDAGE, ROPE OR CABLES, NOT ELSEWHERE SPECIFIED OR INCLUDED : OTHER"
    },
    {
      "code": "57",
      "description": "Carpets and other textile floor coverings"
    },
    {
      "code": "5701",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, KNOTTED, WHETHER OR NOT MADE UP"
    },
    {
      "code": "570110",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "57011000",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, KNOTTED, WHETHER OR NOT MADE UP - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "57011010",
      "description": "Hand-made"
    },
    {
      "code": "57011090",
      "description": "Other"
    },
    {
      "code": "570190",
      "description": "Of other textile materials;"
    },
    {
      "code": "57019010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, KNOTTED, WHETHER OR NOT MADE UP - OF OTHER TEXTILE MATERIALS : OF COTTON"
    },
    {
      "code": "57019011",
      "description": "Hand-made"
    },
    {
      "code": "57019019",
      "description": "Other"
    },
    {
      "code": "57019020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, KNOTTED, WHETHER OR NOT MADE UP - OF COIR INCLUDING GEO TEXTILE"
    },
    {
      "code": "57019031",
      "description": "Hand-made"
    },
    {
      "code": "57019039",
      "description": "Other"
    },
    {
      "code": "57019090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, KNOTTED, WHETHER OR NOT MADE UP - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "5702",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS,WOVEN, NOT TUFTED ORFLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND-WOVEN RUGS"
    },
    {
      "code": "57021000",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND-WOVEN RUGS"
    },
    {
      "code": "570220",
      "description": "Floor coverings of coconut fibres (coir)"
    },
    {
      "code": "57022010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - FLOOR COVERINGS OF COCONUT FIBRES (COIR) : COIR MATTING, WOVEN"
    },
    {
      "code": "57022020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - FLOOR COVERINGS OF COCONUT FIBRES (COIR) : COIR CARPETS AND OTHER RUGS"
    },
    {
      "code": "57022090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - FLOOR COVERINGS OF COCONUT FIBRES (COIR) : OTHER"
    },
    {
      "code": "570231",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "57023110",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : CARPETS"
    },
    {
      "code": "57023120",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : DRUGGETS"
    },
    {
      "code": "57023130",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : MATS AND MATTING"
    },
    {
      "code": "57023140",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : CARPETING , FLOOR RUGS AND THE LIKE"
    },
    {
      "code": "57023190",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "570232",
      "description": "Of man-made textile material"
    },
    {
      "code": "57023210",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIAL : CARPETS, CARPETING AND RUGS AND THE LIKE"
    },
    {
      "code": "57023220",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIAL : MATS AND MATTING"
    },
    {
      "code": "57023290",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIAL : OTHER"
    },
    {
      "code": "570239",
      "description": "Of other textile materials"
    },
    {
      "code": "57023910",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS OTHER THAN DURRIES OF COTTON"
    },
    {
      "code": "57023920",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF SILK"
    },
    {
      "code": "570241",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "57024110",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : CARPETS"
    },
    {
      "code": "57024120",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : DRUGGETS"
    },
    {
      "code": "57024130",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : MATS AND MATTING"
    },
    {
      "code": "57024140",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "57024190",
      "description": "Other"
    },
    {
      "code": "570242",
      "description": "Of man-made textile materials"
    },
    {
      "code": "57024210",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF MAN MADE TEXTILE MATERIALS : CARPETS, CARPETING AND RUGS"
    },
    {
      "code": "57024220",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF MAN MADE TEXTILE MATERIALS : MATS AND MATTINGS"
    },
    {
      "code": "57024230",
      "description": "CARPETS, RUGS AND MATS OF HANDLOOM"
    },
    {
      "code": "57024290",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF MAN MADE TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "570249",
      "description": "Of other textile materials"
    },
    {
      "code": "57024910",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS OTHER THAN DURRIES OF COTTON"
    },
    {
      "code": "57024920",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF SILK"
    },
    {
      "code": "57024990",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "570250",
      "description": "Of man-made textile materials;"
    },
    {
      "code": "57025021",
      "description": "CARPETS, CARPETING AND RUGS - OF MAN MADE TEXTILE MATERIALS"
    },
    {
      "code": "57025022",
      "description": "MATS AND MATTING - OF MAN-MADE TEXTILE MATERIALS"
    },
    {
      "code": "57025029",
      "description": "OTHERS - NOT ELSEWHERE SPECIFIED - OF MAN-MADE TEXTILE MATERIALS"
    },
    {
      "code": "57025031",
      "description": "CARPETS AND OTHER FLOOR COVERINGS, OF COTTON OTHER THAN DURRIES - OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "57025032",
      "description": "CARPETS AND OTHER FLOOR COVERINGS, OF SILK"
    },
    {
      "code": "57025033",
      "description": "PLACE MAT AND OTHER SIMILAR GOODS - OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "57025039",
      "description": "OTHERS NOT ELSEWHERE SPECIFIED - OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "57025110",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : CARPETS"
    },
    {
      "code": "57025120",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : DRUGGETS"
    },
    {
      "code": "57025130",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : MATS AND MATTING"
    },
    {
      "code": "57025140",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "57025210",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIALS : CARPETS, CARPETING AND RUGS"
    },
    {
      "code": "57025220",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIALS : MATS AND MATTINGS"
    },
    {
      "code": "57025290",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF MAN - MADE TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "57025910",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF COTTON OTHER THAN DURRIE"
    },
    {
      "code": "57025920",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF SILK"
    },
    {
      "code": "57025930",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : PLACE MAT AND OTHER SIMILAR GOODS"
    },
    {
      "code": "57025990",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, NOT MADE UP : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "570291",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "57029110",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : CARPETS"
    },
    {
      "code": "57029120",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : DRUGGETS"
    },
    {
      "code": "57029130",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : MATS AND MATTING"
    },
    {
      "code": "57029140",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "57029190",
      "description": "Other"
    },
    {
      "code": "570292",
      "description": "Of man-made textile materials"
    },
    {
      "code": "57029210",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF MAN - MADE TEXTILE MATERIALS : CARPETS, CARPETING AND RUGS"
    },
    {
      "code": "57029220",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF MAN - MADE TEXTILE MATERIALS : MATS AND MATTINGS"
    },
    {
      "code": "57029290",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF MAN - MADE TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "570299",
      "description": "Of other textile materials"
    },
    {
      "code": "57029910",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF COTTON OTHER THAN DURRIES"
    },
    {
      "code": "57029920",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF SILK"
    },
    {
      "code": "57029990",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WOVEN, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP, INCLUDING KELEM, SCHUMACKS, KARAMANIE AND SIMILAR HAND - WOVEN RUGS - OTHER, NOT OF PILE CONSTRUCTION, MADE UP : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "5703",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP"
    },
    {
      "code": "570310",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "57031010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF WOOL OR FINE ANIMAL HAIR : CARPETS"
    },
    {
      "code": "57031020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF WOOL OR FINE ANIMAL HAIR : MATS AND MATTING"
    },
    {
      "code": "57031090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "570320",
      "description": "Of nylon or other polyamides"
    },
    {
      "code": "57032010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF NYLON OR OTHER POLYAMIDES : CARPETS, CARPETING AND RUGS"
    },
    {
      "code": "57032020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF NYLON OR OTHER POLYAMIDES : 100% POLYAMIDE TUFTED VELOUR, CUT PILE OR LOOP PILE CARPET MATS WITH JUTE, RUBBER LATEX OR PU FOAM BACKING"
    },
    {
      "code": "57032090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF NYLON OR OTHER POLYAMIDES : OTHER"
    },
    {
      "code": "570330",
      "description": "Of other man-made textile materials"
    },
    {
      "code": "57033010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF OTHER MAN - MADE TEXTILE MATERIALS : CARPETS, CARPETING AND RUGS"
    },
    {
      "code": "57033020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF OTHER MAN - MADE TEXTILE MATERIALS : 100% POLYPROPYLENE CARPET MATS WITH JUTE, RUBBER, LATEX OR PU FOAM BACKING"
    },
    {
      "code": "57033090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF OTHER MAN - MADE TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "570390",
      "description": "Of other textile materials"
    },
    {
      "code": "57039010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF OTHER TEXTILE MATERIALS : CARPETS AND OTHER FLOOR COVERINGS, OF COTTON, OTHER THAN DURRIES"
    },
    {
      "code": "57039020",
      "description": "CARPETS AND FLOOR COVERINGS OF COIR"
    },
    {
      "code": "57039090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, TUFTED, WHETHER OR NOT MADE UP - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "5704",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, OF FELT, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP"
    },
    {
      "code": "57041000",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, OF FELT, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP - TILES, HAVING A MAXIMUM SURFACE AREA OF 0.3 M2"
    },
    {
      "code": "570420",
      "description": "Tiles, having a maximum surface area exceeding 0.3 m2 but not exceeding 1 m2"
    },
    {
      "code": "57042010",
      "description": "Cotton"
    },
    {
      "code": "57042020",
      "description": "woollen, other than artware"
    },
    {
      "code": "57042090",
      "description": "Other"
    },
    {
      "code": "570490",
      "description": "Other"
    },
    {
      "code": "57049010",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, OF FELT, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP - OTHER : COTTON"
    },
    {
      "code": "57049020",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, OF FELT, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP - OTHER : WOOLLEN, OTHER THAN ARTWARE"
    },
    {
      "code": "57049090",
      "description": "CARPETS AND OTHER TEXTILE FLOOR COVERINGS, OF FELT, NOT TUFTED OR FLOCKED, WHETHER OR NOT MADE UP - OTHER : OTHER"
    },
    {
      "code": "5705",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP"
    },
    {
      "code": "570500",
      "description": "Other carpets and other textile floor coverings whether or not made-up"
    },
    {
      "code": "57050011",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : CARPETS : OF SILK"
    },
    {
      "code": "57050019",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : CARPETS : OTHER"
    },
    {
      "code": "57050021",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : DURRIES : DURRIES COTTON"
    },
    {
      "code": "57050022",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : DURRIES : DURRIES OF MAN-MADE FIBRES"
    },
    {
      "code": "57050023",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : DURRIES : DURRIES OF WOOL"
    },
    {
      "code": "57050024",
      "description": "COTTON DURRIES OF HANDLOOM (INCLUDING CHINDI DURRIES, COTTON CHENILLE DURRIES, RAG RUG DURRIE, PRINTED DURRIES, DRUGGETS)"
    },
    {
      "code": "57050029",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : DURRIES : OTHER"
    },
    {
      "code": "57050031",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : OF JUTE : OF BLENDED JUTE"
    },
    {
      "code": "57050032",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : OF JUTE : OF COIR JUTE"
    },
    {
      "code": "57050039",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : OF JUTE : OTHER"
    },
    {
      "code": "57050041",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : CARPETS, CARPETING, RUGS, MATS AND MATTINGS : KNITTED"
    },
    {
      "code": "57050042",
      "description": "MATS AND MATTINGS INCLUDING BATH MATS, WHERE COTTON PREDOMINATES BY WEIGHT, OF HANDLOOM, COTTON RUGS OF HANDLOOM"
    },
    {
      "code": "57050049",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : CARPETS, CARPETING, RUGS, MATS AND MATTINGS : OTHER"
    },
    {
      "code": "57050090",
      "description": "OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP - OTHER CARPETS AND OTHER TEXTILE FLOOR COVERINGS, WHETHER OR NOT MADE UP : OTHER"
    },
    {
      "code": "58",
      "description": "Special woven fabrics; tufted textile fabrics; lace; tapestries; trimmings; embroidery"
    },
    {
      "code": "5801",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806"
    },
    {
      "code": "58011000",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "58012100",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON:UNCUT WEFT PILE FABRICS"
    },
    {
      "code": "580122",
      "description": "Cut corduroy"
    },
    {
      "code": "58012210",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 -  OF COTTON:-  CUT CORDUROY: SOLELY OF COTTON"
    },
    {
      "code": "58012290",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON:- CUT CORDUROY:OTHER"
    },
    {
      "code": "58012300",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON:OTHER WEFT PILE FABRICS"
    },
    {
      "code": "58012600",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON:CHENILLE FABRICS"
    },
    {
      "code": "580127",
      "description": "Warp pile fabrics"
    },
    {
      "code": "58012710",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON--WARP PILE FABRICS---WARP PILE FABRICS,\"EPINGLE\"(UNCUT)"
    },
    {
      "code": "58012720",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON--WARP PILE FABRICS---WARP PILE FABRICS,CUT"
    },
    {
      "code": "58012790",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON--WARP PILE FABRICS---OTHER"
    },
    {
      "code": "58013100",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF MAN-MADE FIBRES: UNCUT WEFT PILE FABRICS"
    },
    {
      "code": "58013200",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF MAN-MADE FIBRES: CUT CORDUROY"
    },
    {
      "code": "58013300",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF MAN-MADE FIBRES: OTHER WEFT PILE FABRICS"
    },
    {
      "code": "580134",
      "description": "Warp pile fabrics, epingle (uncut)"
    },
    {
      "code": "580136",
      "description": "Chenille fabrics"
    },
    {
      "code": "58013610",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF MAN-MADE FIBRES: - CHENILLE FABRICS:CARDUROYS"
    },
    {
      "code": "58013690",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF MAN-MADE FIBRES: - CHENILLE FABRICS:OTHER"
    },
    {
      "code": "580137",
      "description": "Warp pile fabrics"
    },
    {
      "code": "58013710",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806-WARP PILE FABRICS--WARP PILE FABRICS, UNCUT"
    },
    {
      "code": "58013720",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON--WARP PILE FABRICS---WARP PILE FABRICS,CUT"
    },
    {
      "code": "58013790",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806 - OF COTTON--WARP PILE FABRICS---OTHER"
    },
    {
      "code": "580190",
      "description": "Of other textile materials"
    },
    {
      "code": "58019010",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806- OF OTHER TEXTILE MATERIALS:PILE FABRICS AND CHENILLE FABRICS OF SILK CONTAINING MORE THAN 50% BY WEIGHT OF SILK, BUT NOT CONTAINING WOOL OR HAIR"
    },
    {
      "code": "58019090",
      "description": "WOVEN PILE FABRICS AND CHENILLE FABRICS, OTHER THAN FABRICS OF HEADING 5802 OR 5806- OF OTHER TEXTILE MATERIALS:PILE FABRICS AND CHENILLE FABRICS NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "5802",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - Terry towelling and similar woven terry fabrics, of cotton"
    },
    {
      "code": "58021100",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON:UNBLEACHED"
    },
    {
      "code": "580219",
      "description": "Other"
    },
    {
      "code": "58021910",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON: - OTHER:BLEACHED"
    },
    {
      "code": "58021920",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON: - OTHER:PIECE DYED"
    },
    {
      "code": "58021930",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON: - OTHER:YARN DYED"
    },
    {
      "code": "58021940",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON: - OTHER:PRINTED"
    },
    {
      "code": "58021950",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON - OF HANDLOOM"
    },
    {
      "code": "58021990",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF COTTON: - OTHER:OTHER"
    },
    {
      "code": "58022000",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "58023000",
      "description": "TERRY TOWELLING AND SIMILAR WOVEN TERRY FABRICS, OTHER THAN NARROW FABRICS OF HEADING 5806; TUFTED TEXTILE FABRICS, OTHER THAN PRODUCTS OF HEADING 5703 - TUFTED TEXTILE FABRICS"
    },
    {
      "code": "5803",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806"
    },
    {
      "code": "580300",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806"
    },
    {
      "code": "58030011",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON:---- UNBLEACHED"
    },
    {
      "code": "58030012",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON: ---- BLEACHED"
    },
    {
      "code": "58030013",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON: ---- PIECE DYED"
    },
    {
      "code": "58030014",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON: ---- YARN DYED"
    },
    {
      "code": "58030015",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON: ---- PRINTED"
    },
    {
      "code": "58030019",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF COTTON: ---- OTHER"
    },
    {
      "code": "58030091",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF OTHER TEXTILE MATERIALS: ---- OF SILK OR SILK WASTE"
    },
    {
      "code": "58030092",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF OTHER TEXTILE MATERIALS :---- OF SYNTHETIC FIBRE"
    },
    {
      "code": "58030093",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF OTHER TEXTILE MATERIALS: ----OF ARTIFICIAL FIBRE"
    },
    {
      "code": "58030099",
      "description": "GAUZE,OTHER THAN NARROW FABRICS OF HEADING 5806 - GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 --- OF OTHER TEXTILE MATERIALS : ---- OTHER"
    },
    {
      "code": "58031010",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:UNBLEACHED"
    },
    {
      "code": "58031020",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:BLEACHED"
    },
    {
      "code": "58031030",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:PIECE DYED"
    },
    {
      "code": "58031040",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:YARN DYED"
    },
    {
      "code": "58031050",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:PRINTED"
    },
    {
      "code": "58031090",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF COTTON:OTHER"
    },
    {
      "code": "58039010",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF OTHER TEXTILE MATERIALS:OF SILK OR SILK WASTE"
    },
    {
      "code": "58039020",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF OTHER TEXTILE MATERIALS:OF SYNTHETIC FIBER"
    },
    {
      "code": "58039030",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF OTHER TEXTILE MATERIALS:OF ARTIFICIAL FIBRE"
    },
    {
      "code": "58039090",
      "description": "GAUZE, OTHER THAN NARROW FABRICS OF HEADING 5806 5803 10 - OF OTHER TEXTILE MATERIALS:OTHER"
    },
    {
      "code": "5804",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006"
    },
    {
      "code": "580410",
      "description": "Tulles and other net fabrics"
    },
    {
      "code": "58041010",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006TULLES AND OTHER NET FABRICS:OF COTTON"
    },
    {
      "code": "58041090",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006TULLES AND OTHER NET FABRICS:OTHER"
    },
    {
      "code": "58042100",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006- MECHANICALLY MADE LACE: OF MAN-MADE FIBRES"
    },
    {
      "code": "580429",
      "description": "Of other textile materials"
    },
    {
      "code": "58042910",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006- MECHANICALLY MADE LACE:- OF OTHER TEXTILE MATERIALS:OF COTTON"
    },
    {
      "code": "58042990",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006- MECHANICALLY MADE LACE:- OF OTHER TEXTILE MATERIALS:OTHER"
    },
    {
      "code": "58043000",
      "description": "TULLES AND OTHER NET FABRICS, NOT INCLUDING WOVEN, KNITTED OR CROCHETED FABRICS; LACE IN THE PIECE, IN STRIPS OR IN MOTIFS, OTHER THAN FABRICS OF HEADINGS 6002 TO 6006 - HAND-MADE LACE"
    },
    {
      "code": "5805",
      "description": "HAND-WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE-WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP"
    },
    {
      "code": "580500",
      "description": "Hand-woven tapestries of the type gobelins, flanders, aubusson, beauvais and the like, and needle-worked tapestries (for example, petit point, cross stitch), whether or not made up"
    },
    {
      "code": "58050010",
      "description": "HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP 5805 00 - HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP:TAPESTRIES HAND MADE OR NEEDLE WORKED BY HAND, OF COTTON"
    },
    {
      "code": "58050020",
      "description": "HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP 5805 00 - HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP:TAPESTRIES OF JUTE"
    },
    {
      "code": "58050090",
      "description": "HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP 5805 00 - HAND - WOVEN TAPESTRIES OF THE TYPE GOBELINS, FLANDERS, AUBUSSON, BEAUVAIS AND THE LIKE, AND NEEDLE - WORKED TAPESTRIES (FOR EXAMPLE, PETIT POINT, CROSS STITCH), WHETHER OR NOT MADE UP:OTHER"
    },
    {
      "code": "5806",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)"
    },
    {
      "code": "58061000",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS) - WOVEN PILE FABRICS (INCLUDING TERRY TOWELLING AND SIMILAR TERRY FABRICS) AND CHENILLE FABRICS"
    },
    {
      "code": "58062000",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS) - OTHER WOVEN FABRICS, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD"
    },
    {
      "code": "580631",
      "description": "Of cotton"
    },
    {
      "code": "58063110",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF COTTON:TYPEWRITER RIBBON CLOTH"
    },
    {
      "code": "58063120",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF COTTON:NEWAR COTTON"
    },
    {
      "code": "58063190",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF COTTON:OTHER"
    },
    {
      "code": "58063200",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: OF MAN-MADE FIBRES"
    },
    {
      "code": "580639",
      "description": "Of other textile materials"
    },
    {
      "code": "58063910",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF OTHER TEXTILE MATERIALS:GOAT HAIR PUTTIS TAPE"
    },
    {
      "code": "58063920",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF OTHER TEXTILE MATERIALS: JUTE WEBBING"
    },
    {
      "code": "58063930",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF OTHER TEXTILE MATERIALS: OTHER NARROW FABRICS OF JUTE"
    },
    {
      "code": "58063990",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)- OTHER WOVEN FABRICS: - OF OTHER TEXTILE MATERIALS:OTHER"
    },
    {
      "code": "58064000",
      "description": "NARROW WOVEN FABRICS OTHER THAN GOODS OF HEADING 5807; NARROW FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS) - FABRICS CONSISTING OF WARP WITHOUT WEFT ASSEMBLED BY MEANS OF AN ADHESIVE (BOLDUCS)"
    },
    {
      "code": "5807",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED"
    },
    {
      "code": "580710",
      "description": "Woven"
    },
    {
      "code": "58071010",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED - WOVEN:OF COTTON"
    },
    {
      "code": "58071020",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED - WOVEN:OF MAN-MADE FIBRE"
    },
    {
      "code": "58071090",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED - WOVEN:OTHER"
    },
    {
      "code": "580790",
      "description": "Other"
    },
    {
      "code": "58079010",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED - OTHER:FELT OR NON-WOVEN"
    },
    {
      "code": "58079090",
      "description": "LABELS, BADGES AND SIMILAR ARTICLES OF TEXTILE MATERIALS, IN THE PIECE, IN STRIPS OR CUT TO SHAPE OR SIZE, NOT EMBROIDERED - OTHER:OTHER"
    },
    {
      "code": "5808",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES"
    },
    {
      "code": "580810",
      "description": "Braids, in the piece"
    },
    {
      "code": "58081010",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - BRAIDS, IN THE PIECE:OF COTTON"
    },
    {
      "code": "58081090",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - BRAIDS, IN THE PIECE:OTHER"
    },
    {
      "code": "580890",
      "description": "Other"
    },
    {
      "code": "58089010",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER:TAPES, ORNAMENTAL OR COTTON"
    },
    {
      "code": "58089020",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER:HAIR BAND OF NARROW FABRICS"
    },
    {
      "code": "58089030",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER:OTHER BRAIDS"
    },
    {
      "code": "58089040",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER:RIBBONS OF RAYON WITH ORNAMENTAL TRIMMINGS"
    },
    {
      "code": "58089050",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER:SAREE FALLS, BORDERS (OTHER THAN ZARI), FRINGS OF COTTON"
    },
    {
      "code": "58089060",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER: SAREE FALLS, BORDERS (OTHER THAN ZARI), FRINGS OF MAN-MADE FIBRE"
    },
    {
      "code": "58089090",
      "description": "BRAIDS IN THE PIECE; ORNAMENTAL TRIMMINGS IN THE PIECE, WITHOUT EMBROIDERY, OTHER THAN KNITTED OR CROCHETED; TASSELS, POMPONS AND SIMILAR ARTICLES - OTHER: OTHER"
    },
    {
      "code": "5809",
      "description": "WOVEN FABRICS OF METAL THREAD AND WOVEN FABRICS OF METALLISED YARN OF HEADING 5605, OF A KIND USED IN APPAREL, AS FURNISHING FABRICS OR FOR SIMILAR PURPOSES, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "580900",
      "description": "Woven fabrics of metal thread and woven fabrics of metallised yarn of heading 5605, of a kind used in apparel, as furnishing fabrics or for similar purposes, not elsewhere specified or included"
    },
    {
      "code": "58090010",
      "description": "WOVEN FABRICS OF METAL THREAD AND WOVEN FABRICS OF METALLISED YARN OF HEADING 5605, OF A KIND USED IN APPAREL, AS FURNISHING FABRICS OR FOR SIMILAR PURPOSES, NOT ELSEWHERE SPECIFIED OR INCLUDED - WOVEN FABRICS OF METAL THREAD AND WOVEN FABRICS OF METALLISED YARN OF HEADING 5605, OF A KIND USED IN APPAREL, AS FURNISHING FABRICS OR FOR SIMILAR PURPOSES, NOT ELSEWHERE SPECIFIED OR INCLUDED:ZARI BORDER"
    },
    {
      "code": "58090090",
      "description": "WOVEN FABRICS OF METAL THREAD AND WOVEN FABRICS OF METALLISED YARN OF HEADING 5605, OF A KIND USED IN APPAREL, AS FURNISHING FABRICS OR FOR SIMILAR PURPOSES, NOT ELSEWHERE SPECIFIED OR INCLUDED - WOVEN FABRICS OF METAL THREAD AND WOVEN FABRICS OF METALLISED YARN OF HEADING 5605, OF A KIND USED IN APPAREL, AS FURNISHING FABRICS OR FOR SIMILAR PURPOSES, NOT ELSEWHERE SPECIFIED OR INCLUDED:OTHER"
    },
    {
      "code": "5810",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS"
    },
    {
      "code": "58101000",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS - EMBROIDERY WITHOUT VISIBLE GROUND"
    },
    {
      "code": "58109100",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS - OTHER EMBROIDERY: OF COTTON"
    },
    {
      "code": "581092",
      "description": "Of man-made fibres"
    },
    {
      "code": "58109210",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS - OTHER EMBROIDERY: - OF MAN-MADE FIBRES :EMBROIDERED BADGES, MOTIFS AND THE LIKE"
    },
    {
      "code": "58109290",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS - OTHER EMBROIDERY: - OF MAN-MADE FIBRES :OTHER"
    },
    {
      "code": "58109900",
      "description": "EMBROIDERY IN THE PIECE, IN STRIPS OR IN MOTIFS - OTHER EMBROIDERY: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "5811",
      "description": "QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810"
    },
    {
      "code": "581100",
      "description": "Quilted textile products in the piece, composed of one or more layers of textile materials assembled with padding by stitching or otherwise, other than embroidery of heading 5810"
    },
    {
      "code": "58110010",
      "description": "QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810 - QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810:KANTHA (MULTILAYER STITCHED TEXTILE FABRICS IN PIECE USED FOR BEDDING, MATTRESS PADS OR CLOTHING)"
    },
    {
      "code": "58110020",
      "description": "QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810 - QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810:QUILTED WADDING"
    },
    {
      "code": "58110090",
      "description": "QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810  -  QUILTED TEXTILE PRODUCTS IN THE PIECE, COMPOSED OF ONE OR MORE LAYERS OF TEXTILE MATERIALS ASSEMBLED WITH PADDING BY STITCHING OR OTHERWISE, OTHER THAN EMBROIDERY OF HEADING 5810:OTHER"
    },
    {
      "code": "59",
      "description": "Impregnated, coated, covered or laminated textile fabrics; textile articles of a kind suitable for industrial use"
    },
    {
      "code": "5901",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS"
    },
    {
      "code": "590110",
      "description": "Textile fabrics coated with gum or amylaceous substances, of a kind used for the outer covers of books or the like"
    },
    {
      "code": "59011010",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE : OF COTTON"
    },
    {
      "code": "59011020",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE : PREPARED PAINTING CANVAS"
    },
    {
      "code": "59011090",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE : OTHER"
    },
    {
      "code": "590190",
      "description": "Other"
    },
    {
      "code": "59019010",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - OTHER : TRACING CLOTH OF COTTON"
    },
    {
      "code": "59019020",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - OTHER : VARNISHED CAMBRIC FABRICS (EMPIRE FABRICS) TAPES"
    },
    {
      "code": "59019090",
      "description": "TEXTILE FABRICS COATED WITH GUM OR AMYLACEOUS SUBSTANCES, OF A KIND USED FOR THE OUTER COVERS OF BOOKS OR THE LIKE; TRACING CLOTH; PREPARED PAINTING CANVAS; BUCKRAM AND SIMILAR STIFFENED TEXTILE FABRICS OF A KIND USED FOR HAT FOUNDATIONS - OTHER : OTHER"
    },
    {
      "code": "5902",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON"
    },
    {
      "code": "590210",
      "description": "Of nylon or other polyamides"
    },
    {
      "code": "59021010",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OF NYLON OR OTHER POLYAMIDES : IMPREGNATED WITH RUBBER"
    },
    {
      "code": "59021090",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OF NYLON OR OTHER POLYAMIDES : OTHER"
    },
    {
      "code": "590220",
      "description": "Of polyesters"
    },
    {
      "code": "59022010",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OF POLYESTERS : IMPREGNATED WITH RUBBER"
    },
    {
      "code": "59022090",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OF POLYESTERS : OTHER"
    },
    {
      "code": "59023010",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OTHER : IMPREGNATED WITH RUBBER"
    },
    {
      "code": "59023090",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OTHER : OTHER"
    },
    {
      "code": "590290",
      "description": "Other"
    },
    {
      "code": "59029010",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OTHER : IMPREGNATED WITH RUBBER"
    },
    {
      "code": "59029090",
      "description": "TYRE CORD FABRIC OF HIGH TENACITY YARN OF NYLON OR OTHER POLYAMIDES, POLYESTERS OR VISCOSE RAYON - OTHER : OTHER"
    },
    {
      "code": "5903",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902"
    },
    {
      "code": "590310",
      "description": "With polyvinyl chloride"
    },
    {
      "code": "59031010",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902- WITH POLYVINYL CHLORIDE : IMITATION LEATHER FABRICS OF COTTON"
    },
    {
      "code": "59031090",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902- WITH POLYVINYL CHLORIDE : OTHER"
    },
    {
      "code": "590320",
      "description": "With polyurethane"
    },
    {
      "code": "59032010",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902- WITH POLYURETHANE : IMITATION LEATHER FABRICS, OF COTTON"
    },
    {
      "code": "59032090",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902- WITH POLYURETHANE : OTHER"
    },
    {
      "code": "590390",
      "description": "Other"
    },
    {
      "code": "59039010",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : OF COTTON"
    },
    {
      "code": "59039020",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : POLYETHYLENE LAMINATED JUTE FABRICS"
    },
    {
      "code": "59039090",
      "description": "TEXTILE FABRICS, IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : OTHER"
    },
    {
      "code": "5904",
      "description": "LINOLEUM, WHETHER OR NOT CUT TO SHAPE; FLOOR COVERINGS CONSISTING OF A COATING OR COVERING APPLIED ON A TEXTILE BACKING, WHETHER OR NOT CUT TO SHAPE"
    },
    {
      "code": "59041000",
      "description": "LINOLEUM, WHETHER OR NOT CUT TO SHAPE; FLOOR COVERINGS CONSISTING OF A COATING OR COVERING APPLIED ON A TEXTILE BACKING, WHETHER OR NOT CUT TO SHAPE - LINOLEUM"
    },
    {
      "code": "590490",
      "description": "Other"
    },
    {
      "code": "59049010",
      "description": "LINOLEUM, WHETHER OR NOT CUT TO SHAPE; FLOOR COVERINGS CONSISTING OF A COATING OR COVERING APPLIED ON A TEXTILE BACKING, WHETHER OR NOT CUT TO SHAPE - OTHER : FLOOR COVERINGS WITH JUTE BASE"
    },
    {
      "code": "59049090",
      "description": "LINOLEUM, WHETHER OR NOT CUT TO SHAPE; FLOOR COVERINGS CONSISTING OF A COATING OR COVERING APPLIED ON A TEXTILE BACKING, WHETHER OR NOT CUT TO SHAPE - OTHER : OTHER"
    },
    {
      "code": "5905",
      "description": "TEXTILE WALL COVERINGS"
    },
    {
      "code": "590500",
      "description": "Textile wall coverings"
    },
    {
      "code": "59050010",
      "description": "TEXTILE WALL COVERINGS - TEXTILE WALL COVERINGS : FIXED ON THE BACKING OF ANY MATERIAL"
    },
    {
      "code": "59050090",
      "description": "TEXTILE WALL COVERINGS - TEXTILE WALL COVERINGS : OTHER"
    },
    {
      "code": "59051010",
      "description": "TEXTILE WALL COVERINGS - TEXTILE WALL COVERINGS : FIXED ON THE BACKING OF ANY MATERIAL"
    },
    {
      "code": "59051090",
      "description": "TEXTILE WALL COVERINGS - TEXTILE WALL COVERINGS : OTHER"
    },
    {
      "code": "5906",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902"
    },
    {
      "code": "59061000",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - ADHESIVE TAPE OF A WIDTH NOT EXCEEDING 20 CM"
    },
    {
      "code": "590691",
      "description": "Knitted or crocheted"
    },
    {
      "code": "59069110",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : - KNITTED OR CROCHETED : OF COTTON"
    },
    {
      "code": "59069190",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : - KNITTED OR CROCHETED : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "590699",
      "description": "Other"
    },
    {
      "code": "59069910",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : - OTHER : INSULATING TAPE, ELECTRICAL OF COTTON"
    },
    {
      "code": "59069920",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : - OTHER : RUBBERISED COTTON FABRICS, OTHER THAN KNITTED OR CROCHETED"
    },
    {
      "code": "59069990",
      "description": "RUBBERISED TEXTILE FABRICS, OTHER THAN THOSE OF HEADING 5902 - OTHER : - OTHER : OTHER"
    },
    {
      "code": "5907",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK-CLOTHS OR THE LIKE"
    },
    {
      "code": "590700",
      "description": "Textile fabrics otherwise impregnated, coated or covered; painted canvas being theatrical scenery, studio back-cloths or the like"
    },
    {
      "code": "59070011",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - FABRICS COVERED PARTIALLY OR FULLY WITH TEXTILE FLOCKS, OR WITH PREPARATION CONTAINING TEXTILE FLOCKS : ON THE BASE FABRICS OF COTTON"
    },
    {
      "code": "59070012",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - FABRICS COVERED PARTIALLY OR FULLY WITH TEXTILE FLOCKS, OR WITH PREPARATION CONTAINING TEXTILE FLOCKS : ON THE BASE FABRICS OF MAN-MADE TEXTILE MATERIAL"
    },
    {
      "code": "59070019",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - FABRICS COVERED PARTIALLY OR FULLY WITH TEXTILE FLOCKS, OR WITH PREPARATION CONTAINING TEXTILE FLOCKS : ON BASE FABRICS OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "59070091",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - OTHER : COTTON FABRICS COATED OR IMPREGNATED WITH OIL OR PREPARATIONS WITH BASIS OF DRYING OIL"
    },
    {
      "code": "59070092",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - OTHER : OTHER TEXTILE FABRICS COATED OR IMPREGNATED WITH OIL OR OIL PREPARATIONS"
    },
    {
      "code": "59070093",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - OTHER : JUTE FABRICS OTHERWISE IMPREGNATED OR COATED"
    },
    {
      "code": "59070099",
      "description": "TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE - TEXTILE FABRICS OTHERWISE IMPREGNATED, COATED OR COVERED; PAINTED CANVAS BEING THEATRICAL SCENERY, STUDIO BACK - CLOTHS OR THE LIKE : - OTHER : OTHER"
    },
    {
      "code": "5908",
      "description": "TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED"
    },
    {
      "code": "590800",
      "description": "Textile wicks, woven, plaited or knitted, for"
    },
    {
      "code": "59080010",
      "description": "TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED - TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED : WICKS AND GAS MANTLE FABRICS, OF COTTON"
    },
    {
      "code": "59080020",
      "description": "TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED - TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED : GAS MANTLES OF RAYON"
    },
    {
      "code": "59080090",
      "description": "TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED - TEXTILE WICKS, WOVEN, PLAITED OR KNITTED, FOR LAMPS, STOVES, LIGHTERS, CANDLES OR THE LIKE; INCANDESCENT GAS MANTLES AND TUBULAR KNITTED GAS MANTLE FABRIC THEREFOR, WHETHER OR NOT IMPREGNATED : OTHER"
    },
    {
      "code": "5909",
      "description": "TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS"
    },
    {
      "code": "590900",
      "description": "Textile hose piping and similar textile tubing, with or without lining, armour or accessories of other materials"
    },
    {
      "code": "59090010",
      "description": "TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS - TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS : OF COTTON"
    },
    {
      "code": "59090020",
      "description": "TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS - TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS : OF MAN-MADE FIBRE"
    },
    {
      "code": "59090090",
      "description": "TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS - TEXTILE HOSE PIPING AND SIMILAR TEXTILE TUBING, WITH OR WITHOUT LINING, ARMOUR OR ACCESSORIES OF OTHER MATERIALS : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "5910",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL"
    },
    {
      "code": "591000",
      "description": "Transmission or conveyor belts or belting, of textile material, whether or not impregnated, coated, covered or laminated with plastics, or reinforced with metal or other material"
    },
    {
      "code": "59100010",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : COTTON CANVAS PLY BELTING"
    },
    {
      "code": "59100020",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : RUBBERISED COTTON BELTING"
    },
    {
      "code": "59100030",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : OTHER TRANSMISSION, CONVEYER OR ELEVATOR BELTS OR BELTING OF COTTON"
    },
    {
      "code": "59100040",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : HAIR BELTING"
    },
    {
      "code": "59100050",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : FLAX CANVAS PLY BELTING"
    },
    {
      "code": "59100060",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : FIBRE BELT CONVEYOR"
    },
    {
      "code": "59100090",
      "description": "TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL - TRANSMISSION OR CONVEYOR BELTS OR BELTING, OF TEXTILE MATERIAL, WHETHER OR NOT IMPREGNATED, COATED, COVERED OR LAMINATED WITH PLASTICS, OR REINFORCED WITH METAL OR OTHER MATERIAL : OTHER"
    },
    {
      "code": "5911",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER"
    },
    {
      "code": "59111000",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS, FELT AND FELT-LINED WOVEN FABRICS, COATED, COVERED OR LAMINATED WITH RUBBER, LEATHER OR OTHER MATERIAL, OF A KIND USED FOR CARD CLOTHING, AND SIMILAR FABRICS OF A KIND USED FOR OTHER TECHNICAL PURPOSES, INCLUDING NARROW FABRICS MADE OF VELVET IMPREGNATED WITH RUBBER, FOR COVERING WEAVING SPINDLES (WEAVING BEAMS)"
    },
    {
      "code": "59112000",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - BOLTING CLOTH, WHETHER OR NOT MADE UP"
    },
    {
      "code": "591131",
      "description": "Weighing less than 650 g/m2"
    },
    {
      "code": "59113110",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : FELT FOR COTTON TEXTILE INDUSTRIES, WOVEN"
    },
    {
      "code": "59113120",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : WOVEN TEXTILES FELT, WHETHER OR NOT IMPREGNATED OR COATED, OF A KIND COMMONLY USED IN OTHER MACHINES"
    },
    {
      "code": "59113130",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : COTTON FABRICS AND ARTICLES USED IN MACHINERY AND PLANT"
    },
    {
      "code": "59113140",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : JUTE FABRICS AND ARTICLES USED IN MACHINERY OR PLANT"
    },
    {
      "code": "59113150",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : TEXTILE FABRICS OF METALISED YARN OF A KIND COMMONLY USED IN PAPER MAKING OR OTHERMACHINERY"
    },
    {
      "code": "59113190",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING LESS THAN 650 G/ M2 : OTHER"
    },
    {
      "code": "591132",
      "description": "Weighing 650 g/m2 or more"
    },
    {
      "code": "59113210",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : FELT FOR COTTON TEXTILE INDUSTRIES, WOVEN"
    },
    {
      "code": "59113220",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : WOVEN TEXTILES FELT, WHETHER OR NOT IMPREGNATED OR COATED, OF A KIND COMMONLY USED IN OTHER MACHINES"
    },
    {
      "code": "59113230",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : COTTON FABRICS AND ARTICLES USED IN MACHINERY AND PLANT"
    },
    {
      "code": "59113240",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : JUTE FABRICS AND ARTICLES USED IN MACHINERY OR PLAN"
    },
    {
      "code": "59113250",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : TEXTILE FABRICS OF METALISED YARN OF A KIND COMMONLY USED IN PAPER MAKING OR OTHER MACHINERY"
    },
    {
      "code": "59113290",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - TEXTILE FABRICS AND FELTS, ENDLESS OR FITTED WITH LINKING DEVICES, OF A KIND USED IN PAPERMAKING OR SIMILAR MACHINES (FOR EXAMPLE, FOR PULP OR ASBESTOS - CEMENT) : - WEIGHING 650 G/ M2OR MORE : OTHER"
    },
    {
      "code": "59114000",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - STRAINING CLOTH OF A KIND USED IN OIL PRESSES OR THE LIKE, INCLUDING THAT OF HUMAN HAIR"
    },
    {
      "code": "591190",
      "description": "Other"
    },
    {
      "code": "59119010",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - OTHER : PAPER MAKERS FELT, WOVEN"
    },
    {
      "code": "59119020",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - OTHER : GASKETS, WASHERS, POLISHING DISCS AND OTHER MACHINERY PARTS OF TEXTILE ARTICLES"
    },
    {
      "code": "59119090",
      "description": "TEXTILE PRODUCTS AND ARTICLES, FOR TECHNICAL USES, SPECIFIED IN NOTE 7 TO THIS CHAPTER - OTHER : OTHER"
    },
    {
      "code": "60",
      "description": "Knitted or crocheted fabrics"
    },
    {
      "code": "6001",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED"
    },
    {
      "code": "600110",
      "description": "Long pile fabrics"
    },
    {
      "code": "60011010",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LONG PILE FABRICS: OF COTTON"
    },
    {
      "code": "60011020",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LONG PILE FABRICS: OF MAN-MADE FIBRES"
    },
    {
      "code": "60011090",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LONG PILE FABRICS: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "60012100",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LOOPED PILE FABRICS:OF COTTON"
    },
    {
      "code": "60012200",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LOOPED PILE FABRICS:OF MAN-MADE FIBRES"
    },
    {
      "code": "60012900",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - LOOPED PILE FABRICS:OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "60019100",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - OTHER:OF COTTON"
    },
    {
      "code": "60019200",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - OTHER: OF MAN-MADE FIBRES"
    },
    {
      "code": "600199",
      "description": "Of other textile materials"
    },
    {
      "code": "60019910",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - OTHER:- OF OTHER TEXTILE MATERIALS:OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "60019990",
      "description": "PILE FABRICS, INCLUDING LONG PILE FABRICS AND TERRY FABRICS, KNITTED OR CROCHETED - OTHER:- OF OTHER TEXTILE MATERIALS:OTHER"
    },
    {
      "code": "6002",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001"
    },
    {
      "code": "60024000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001 - CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN BUT NOT CONTAINING RUBBER THREAD"
    },
    {
      "code": "60029000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001- OTHER"
    },
    {
      "code": "6003",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002"
    },
    {
      "code": "60031000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002 - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "60032000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002 - OF COTTON"
    },
    {
      "code": "60033000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002 - OF SYNTHETIC FIBRES"
    },
    {
      "code": "60034000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002 - OF ARTIFICIAL FIBRES"
    },
    {
      "code": "60039000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH NOT EXCEEDING 30 CM, OTHER THAN THOSE OF HEADING 6001 OR 6002 - OTHER"
    },
    {
      "code": "6004",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001"
    },
    {
      "code": "60041000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001 - CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN BUT NOT CONTAINING RUBBER THREAD"
    },
    {
      "code": "60049000",
      "description": "KNITTED OR CROCHETED FABRICS OF A WIDTH EXCEEDING 30 CM, CONTAINING BY WEIGHT 5% OR MORE OF ELASTOMERIC YARN OR RUBBER THREAD, OTHER THAN THOSE OF HEADING 6001 - OTHER"
    },
    {
      "code": "6005",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 ---- Of cotton"
    },
    {
      "code": "60051000",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "60052100",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF COTTON:UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60052200",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF COTTON:DYED"
    },
    {
      "code": "60052300",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF COTTON:OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60052400",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF COTTON:PRINTED"
    },
    {
      "code": "60053100",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF SYNTHETIC FIBRES :UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60053200",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF SYNTHETIC FIBRES :DYED"
    },
    {
      "code": "60053300",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF SYNTHETIC FIBRES : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60053400",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF SYNTHETIC FIBRES :PRINTED"
    },
    {
      "code": "60053500",
      "description": "Fabrics specified in Sub-heading Note 1 to this Chapter"
    },
    {
      "code": "60053600",
      "description": "Other, unbleached or bleached"
    },
    {
      "code": "60053700",
      "description": "Other, dyed"
    },
    {
      "code": "60053800",
      "description": "Other, of yarns of different colours"
    },
    {
      "code": "60053900",
      "description": "Other, printed"
    },
    {
      "code": "60054100",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF ARTIFICIAL FIBRES : UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60054200",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF ARTIFICIAL FIBRES : DYED"
    },
    {
      "code": "60054300",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF ARTIFICIAL FIBRES : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60054400",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OF ARTIFICIAL FIBRES : PRINTED"
    },
    {
      "code": "60059000",
      "description": "WARP KNIT FABRICS (INCLUDING THOSE MADE ON GALLOON KNITTING MACHINES), OTHER THAN THOSE OF HEADINGS 6001 TO 6004 - OTHER"
    },
    {
      "code": "6006",
      "description": "OTHER KNITTED OR CROCHETED FABRICS"
    },
    {
      "code": "60061000",
      "description": "OTHER KNITTED OR CROCHETED FABRICS - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "60062100",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF COTTON: UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60062200",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF COTTON: DYED"
    },
    {
      "code": "60062300",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF COTTON: OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60062400",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF COTTON: PRINTED"
    },
    {
      "code": "60063100",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF SYNTHETIC FIBRES: UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60063200",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF SYNTHETIC FIBRES: DYED"
    },
    {
      "code": "60063300",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF SYNTHETIC FIBRES: OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60063400",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF SYNTHETIC FIBRES: PRINTED"
    },
    {
      "code": "60064100",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF ARTIFICIAL FIBRES : UNBLEACHED OR BLEACHED"
    },
    {
      "code": "60064200",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF ARTIFICIAL FIBRES : DYED"
    },
    {
      "code": "60064300",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF ARTIFICIAL FIBRES : OF YARNS OF DIFFERENT COLOURS"
    },
    {
      "code": "60064400",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OF ARTIFICIAL FIBRES : PRINTED"
    },
    {
      "code": "60069000",
      "description": "OTHER KNITTED OR CROCHETED FABRICS- OTHER"
    },
    {
      "code": "601202",
      "description": "Chicory plants and roots"
    },
    {
      "code": "6031",
      "description": "Fresh"
    },
    {
      "code": "6049",
      "description": "Other"
    },
    {
      "code": "61",
      "description": "Articles of apparel and clothing accessories, knitted or crocheted"
    },
    {
      "code": "6101",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103"
    },
    {
      "code": "61011010",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF WOOL OR FINE ANIMAL HAIR : OF WOOL"
    },
    {
      "code": "61011020",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF WOOL OR FINE ANIMAL HAIR : OF KASHMIR (CASHMERE) GOATS"
    },
    {
      "code": "61011090",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "61012000",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF COTTON"
    },
    {
      "code": "610130",
      "description": "Of man-made fibres"
    },
    {
      "code": "61013010",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61013020",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610190",
      "description": "Other"
    },
    {
      "code": "61019010",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OTHER : OF SILK"
    },
    {
      "code": "61019090",
      "description": "MENS OR BOYS OVERCOATS, CARCOATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6103 - OTHER : OTHER"
    },
    {
      "code": "6102",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WINDJACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104"
    },
    {
      "code": "61021000",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61022000",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OF COTTON"
    },
    {
      "code": "610230",
      "description": "Of man-made fibres"
    },
    {
      "code": "61023010",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61023020",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610290",
      "description": "Other"
    },
    {
      "code": "61029010",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OTHER : OF SILK"
    },
    {
      "code": "61029090",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, KNITTED OR CROCHETED, OTHER THAN THOSE OF HEADING 6104 - OTHER : OTHER"
    },
    {
      "code": "6103",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIBAND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED"
    },
    {
      "code": "610310",
      "description": "Suits"
    },
    {
      "code": "61031010",
      "description": "SUITS OF SILK"
    },
    {
      "code": "61031020",
      "description": "SUITS OF COTTON"
    },
    {
      "code": "61031030",
      "description": "SUITS OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61031090",
      "description": "OTHER SUITS"
    },
    {
      "code": "61031100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61031200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61031910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OTHER : OF SILK"
    },
    {
      "code": "61031920",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OTHER : OF COTTON"
    },
    {
      "code": "61031930",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OTHER : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61031990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OTHER : OTHER"
    },
    {
      "code": "61032100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61032200",
      "description": "MEN OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF COTTON"
    },
    {
      "code": "61032300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610329",
      "description": "Of other textile materials"
    },
    {
      "code": "61032910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61032920",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61032990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61033100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61033200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF COTTON"
    },
    {
      "code": "61033300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610339",
      "description": "Of other textile materials"
    },
    {
      "code": "61033910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61033920",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61033990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61034100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61034200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF COTTON"
    },
    {
      "code": "61034300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610349",
      "description": "Of other textile materials"
    },
    {
      "code": "61034910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61034920",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61034990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6104",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS,TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED ---- Suits"
    },
    {
      "code": "61041100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61041200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS : OF COTTON"
    },
    {
      "code": "61041300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610419",
      "description": "Of other textile materials"
    },
    {
      "code": "61041910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61041920",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61041990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SUITS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61042100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61042200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF COTTON"
    },
    {
      "code": "61042300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610429",
      "description": "Of other textile materials"
    },
    {
      "code": "61042910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61042920",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61042990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - ENSEMBLES :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61043100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61043200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF COTTON"
    },
    {
      "code": "61043300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610439",
      "description": "Of other textile materials"
    },
    {
      "code": "61043910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61043920",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61043990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - JACKETS AND BLAZERS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61044100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61044200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES : OF COTTON"
    },
    {
      "code": "61044300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61044400",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610449",
      "description": "Of other textile materials"
    },
    {
      "code": "61044910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61044990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - DRESSES :OF OTHER TEXTILE MATERIALS : OF OTHER FIBRES"
    },
    {
      "code": "61045100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61045200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS : OF COTTON"
    },
    {
      "code": "61045300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610459",
      "description": "Of other textile materials"
    },
    {
      "code": "61045910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61045920",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61045990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - SKIRTS AND DIVIDED SKIRTS :OF OTHER TEXTILE MATERIALS : OF OTHER FIBRES"
    },
    {
      "code": "61046100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61046200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF COTTON"
    },
    {
      "code": "61046300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "610469",
      "description": "Of other textile materials"
    },
    {
      "code": "61046910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61046920",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61046990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIM WEAR), KNITTED OR CROCHETED - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6105",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED"
    },
    {
      "code": "610510",
      "description": "Of cotton"
    },
    {
      "code": "61051010",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF COTTON : SHIRTS, HAND CROCHETED"
    },
    {
      "code": "61051020",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF COTTON : KNIT SHIRTS (OTHER THAN T-SHIRTS) AND SWEAT SHIRTS, OTHER THAN HAND CROCHETED"
    },
    {
      "code": "61051090",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF COTTON : OTHER"
    },
    {
      "code": "610520",
      "description": "Of man-made fibres"
    },
    {
      "code": "61052010",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61052020",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610590",
      "description": "Of other textile materials"
    },
    {
      "code": "61059010",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61059090",
      "description": "MENS OR BOYS SHIRTS, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6106",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED"
    },
    {
      "code": "61061000",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF COTTON"
    },
    {
      "code": "610620",
      "description": "Of man-made fibres"
    },
    {
      "code": "61062010",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61062020",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610690",
      "description": "Of other textile materials"
    },
    {
      "code": "61069010",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61069020",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61069090",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6107",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED ---- Underpants and briefs"
    },
    {
      "code": "61071100",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - UNDERPANTS AND BRIEFS : OF COTTON"
    },
    {
      "code": "610712",
      "description": "Of man-made fibres"
    },
    {
      "code": "61071210",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - UNDERPANTS AND BRIEFS : OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61071220",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - UNDERPANTS AND BRIEFS : OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610719",
      "description": "Of other textile materials"
    },
    {
      "code": "61071910",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - UNDERPANTS AND BRIEFS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61071990",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - UNDERPANTS AND BRIEFS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61072100",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS : OF COTTON"
    },
    {
      "code": "610722",
      "description": "Of man-made fibres"
    },
    {
      "code": "61072210",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61072220",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610729",
      "description": "Of other textile materials"
    },
    {
      "code": "61072910",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61072920",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61072990",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT SHIRTS AND PYJAMAS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "610791",
      "description": "Of cotton"
    },
    {
      "code": "61079110",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF COTTON : GENGIS (VESTS), OTHER THAN HAND CROCHETED"
    },
    {
      "code": "61079190",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF COTTON : OTHER"
    },
    {
      "code": "61079210",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61079220",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610799",
      "description": "Of other textile materials"
    },
    {
      "code": "61079910",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61079920",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61079990",
      "description": "MENS OR BOYS UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6108",
      "description": "WOMENS OR GIRLSSLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED ---- Slips and petticoats"
    },
    {
      "code": "610811",
      "description": "Of man-made fibres"
    },
    {
      "code": "61081110",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - SLIPS AND PETTICOATS :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61081120",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - SLIPS AND PETTICOATS :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610819",
      "description": "Of other textile materials"
    },
    {
      "code": "61081910",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - SLIPS AND PETTICOATS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61081920",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - SLIPS AND PETTICOATS :OF OTHER TEXTILE MATERIALS : OF COTTON"
    },
    {
      "code": "61081990",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - SLIPS AND PETTICOATS :OF OTHER TEXTILE MATERIALS : OF OTHER FIBRES"
    },
    {
      "code": "61082100",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - BRIEFS AND PANTIES : OF COTTON"
    },
    {
      "code": "610822",
      "description": "Of man-made fibres"
    },
    {
      "code": "61082210",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - BRIEFS AND PANTIES :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61082220",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - BRIEFS AND PANTIES :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610829",
      "description": "Of other textile materials"
    },
    {
      "code": "61082910",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - BRIEFS AND PANTIES :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61082990",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - BRIEFS AND PANTIES :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61083100",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT DRESSES AND PYJAMAS : OF COTTON"
    },
    {
      "code": "610832",
      "description": "Of man-made fibres"
    },
    {
      "code": "61083210",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT DRESSES AND PYJAMAS :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61083220",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT DRESSES AND PYJAMAS :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610839",
      "description": "Of other textile materials"
    },
    {
      "code": "61083910",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT DRESSES AND PYJAMAS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61083990",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - NIGHT DRESSES AND PYJAMAS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61089100",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER : OF COTTON"
    },
    {
      "code": "610892",
      "description": "Of man-made fibres"
    },
    {
      "code": "61089210",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61089220",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "610899",
      "description": "Of other textile materials"
    },
    {
      "code": "61089910",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61089920",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61089990",
      "description": "WOMENS OR GIRLS SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHT DRESSES, PYJAMAS, NEGLIGEES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6109",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED"
    },
    {
      "code": "61091000",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED - OF COTTON"
    },
    {
      "code": "610990",
      "description": "Of other textile materials"
    },
    {
      "code": "61099010",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED -OF OTHER TEXTILE MATERIALS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61099020",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED -OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61099030",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED -OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61099040",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED -OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61099090",
      "description": "T-SHIRTS, SINGLETS AND OTHER VESTS, KNITTED OR CROCHETED -OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6110",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED ---- Of wool or fine animal hair"
    },
    {
      "code": "611011",
      "description": "Of wool"
    },
    {
      "code": "61101110",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR :OF WOOL : JERSEYS"
    },
    {
      "code": "61101120",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR :OF WOOL : SWEATERS AND CARDIGANS"
    },
    {
      "code": "61101190",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR :OF WOOL : OTHER"
    },
    {
      "code": "61101200",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR : OF KASHMIR (CASHMERE) GOATS"
    },
    {
      "code": "61101900",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "61102000",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF COTTON"
    },
    {
      "code": "611030",
      "description": "Of man-made fibres"
    },
    {
      "code": "61103010",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61103020",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61109000",
      "description": "JERSEYS, PULLOVERS, CARDIGANS, WAISTCOATS AND SIMILAR ARTICLES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6111",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED"
    },
    {
      "code": "61111000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61112000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF COTTON"
    },
    {
      "code": "61113000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF SYNTHETIC FIBRES"
    },
    {
      "code": "611190",
      "description": "Of other textile materials"
    },
    {
      "code": "61119010",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61119020",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61119090",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6112",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED ---- Track suits"
    },
    {
      "code": "61121100",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS : OF COTTON"
    },
    {
      "code": "61121200",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "611219",
      "description": "Of other textile materials"
    },
    {
      "code": "61121910",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61121920",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61121930",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61121990",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - TRACK SUITS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "611220",
      "description": "Ski suits"
    },
    {
      "code": "61122010",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OF SILK"
    },
    {
      "code": "61122020",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61122030",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OF COTTON"
    },
    {
      "code": "61122040",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61122050",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61122090",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - SKI SUITS : OTHER"
    },
    {
      "code": "61123100",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - MENS OR BOYS SWIMWEAR : OF SYNTHETIC FIBRES"
    },
    {
      "code": "611239",
      "description": "Of other textile materials"
    },
    {
      "code": "61123910",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - MENS OR BOYS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61123920",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - MENS OR BOYS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61123990",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED - MENS OR BOYS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61124100",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED WOMENS OR GIRLS SWIMWEAR : OF SYNTHETIC FIBRE"
    },
    {
      "code": "611249",
      "description": "Of other textile materials"
    },
    {
      "code": "61124910",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED WOMENS OR GIRLS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61124920",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED WOMENS OR GIRLS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61124990",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR, KNITTED OR CROCHETED WOMENS OR GIRLS SWIMWEAR :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6113",
      "description": "GARMENTS, MADE UP OF KNITTED OR CROCHETED FABRICS OF HEADING 5903, 5906 OR 5907"
    },
    {
      "code": "61130000",
      "description": "GARMENTS, MADE UP OF KNITTED OR CROCHETED FABRICS OF HEADING 5903, 5906 OR 5907"
    },
    {
      "code": "6114",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED"
    },
    {
      "code": "61141000",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61142000",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF COTTON"
    },
    {
      "code": "611430",
      "description": "Of man-made fibres"
    },
    {
      "code": "61143010",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61143020",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF MAN-MADE FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "611490",
      "description": "Of other textile materials"
    },
    {
      "code": "61149010",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61149090",
      "description": "OTHER GARMENTS, KNITTED OR CROCHETED - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6115",
      "description": "PANTYHOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING GRADUATED COMPRE SSION HOSIERY (FOR EXAMPLE, STOCKINGS FOR VARICOSE VEINS) AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED"
    },
    {
      "code": "61151000",
      "description": "GRADUATED COMPRESSION HOSIERY(EX.FOR VARICOSE VEINS)"
    },
    {
      "code": "61151100",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS : OF SYNTHETIC FIBRES, MEASURING PER SINGLE YARN LESS THAN 67 DECITEX"
    },
    {
      "code": "61151200",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS : OF SYNTHETIC FIBRES MEASURING PER SINGLE YARN 67 DECITEX OR MORE"
    },
    {
      "code": "61151910",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS :OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "61151920",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61151930",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61151990",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - PANTY HOSE AND TIGHTS :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "61152010",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED WOMENS FULL-LENGTH OR KNEE-LENGTH HOSIERY MEASURING PER SINGLE YARN LESS THAN 67 DECITEX : OF COTTON"
    },
    {
      "code": "61152090",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED WOMENS FULL-LENGTH OR KNEE-LENGTH HOSIERY MEASURING PER SINGLE YARN LESS THAN 67 DECITEX : OTHER"
    },
    {
      "code": "611521",
      "description": "Other panty hose and tights"
    },
    {
      "code": "61152100",
      "description": "OTHER PANTY HOSE AND TIGHTS, OF SYN. FIBRES"
    },
    {
      "code": "61152200",
      "description": "OTHER PANTY HOSE AND TIGHTS, OF SYN. FIBRES"
    },
    {
      "code": "611529",
      "description": "Of other textile materials"
    },
    {
      "code": "61152910",
      "description": "PANTY HOSE AND TIGHTS OF SILK"
    },
    {
      "code": "61152920",
      "description": "PANTYHOSE AND TIGHTS OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61152930",
      "description": "PANTY HOSE AND TIGHTS OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61152990",
      "description": "PANTY HOSE AND TIGHTS- NOT SPECIFIED EARLIER"
    },
    {
      "code": "61153000",
      "description": "OTHER WOMENS FULL OR KNEE LENGHT HOSIERY"
    },
    {
      "code": "61159100",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61159200",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER : OF COTTON"
    },
    {
      "code": "61159300",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER : OF SYNTHETIC FIBRES"
    },
    {
      "code": "61159400",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61159500",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF COTTON"
    },
    {
      "code": "61159600",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "611599",
      "description": "Of other textile materials"
    },
    {
      "code": "61159910",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61159990",
      "description": "PANTY HOSE, TIGHTS, STOCKINGS, SOCKS AND OTHER HOSIERY, INCLUDING STOCKINGS FOR VARICOSE VEINS AND FOOTWEAR WITHOUT APPLIED SOLES, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6116",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED"
    },
    {
      "code": "61161000",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED -IMPREGNATED, COATED OR COVERED WITH PLASTICS OR RUBBER"
    },
    {
      "code": "61169100",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED - OTHER : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "61169200",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED  OR CROCHETED - OTHER : OF COTTON"
    },
    {
      "code": "61169300",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED - OTHER : OF SYNTHETIC FIBRES"
    },
    {
      "code": "611699",
      "description": "Of other textile materials"
    },
    {
      "code": "61169910",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "61169990",
      "description": "GLOVES, MITTENS AND MITTS, KNITTED OR CROCHETED - OTHER :OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6117",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES"
    },
    {
      "code": "611710",
      "description": "Shawls, scarves, mufflers, mantillas, veils and the like"
    },
    {
      "code": "61171010",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE : OF SILK"
    },
    {
      "code": "61171020",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE : OF WOOL"
    },
    {
      "code": "61171030",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE : OF COTTON"
    },
    {
      "code": "61171040",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE : OF MAN-MADE FIBRES"
    },
    {
      "code": "61171090",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE : OTHER"
    },
    {
      "code": "61172010",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES TIES, BOW TIES AND CRAVATS : OF SILK"
    },
    {
      "code": "61172020",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES TIES, BOW TIES AND CRAVATS : OF WOOL"
    },
    {
      "code": "61172030",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES TIES, BOW TIES AND CRAVATS : OF COTTON"
    },
    {
      "code": "61172040",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES TIES, BOW TIES AND CRAVATS : OF MAN-MADE FIBRES"
    },
    {
      "code": "61172090",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES TIES, BOW TIES AND CRAVATS : OF OTHER FIBRES"
    },
    {
      "code": "611780",
      "description": "Other accessories"
    },
    {
      "code": "61178010",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - OTHER ACCESSORIES : OF SILK"
    },
    {
      "code": "61178020",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - OTHER ACCESSORIES : OF WOOL"
    },
    {
      "code": "61178030",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - OTHER ACCESSORIES : OF COTTON"
    },
    {
      "code": "61178040",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - OTHER ACCESSORIES : OF MAN-MADE FIBRES"
    },
    {
      "code": "61178090",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - OTHER ACCESSORIES : OTHER"
    },
    {
      "code": "61179000",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES, KNITTED OR CROCHETED; KNITTED OR CROCHETED PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES - PARTS"
    },
    {
      "code": "62",
      "description": "Articles of apparel and clothing accessories, not knitted or crocheted"
    },
    {
      "code": "6201",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 ---- Overcoats, raincoats, car-coats, capes, cloaks and similar articles"
    },
    {
      "code": "62011100",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF WOOL AND FINE ANIMAL HAIR"
    },
    {
      "code": "620112",
      "description": "Of cotton"
    },
    {
      "code": "62011210",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF COTTON : RAINCOATS"
    },
    {
      "code": "62011290",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF COTTON : OTHER"
    },
    {
      "code": "620113",
      "description": "Of man-made fibres"
    },
    {
      "code": "62011310",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF MAN-MADE FIBRES : RAINCOATS"
    },
    {
      "code": "62011390",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF MAN-MADE FIBRES : OTHER"
    },
    {
      "code": "620119",
      "description": "Of other textile materials"
    },
    {
      "code": "62011910",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62011990",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF OTHER TEXTILE MATERIALS : OF OTHER TEXTILE FIBRES"
    },
    {
      "code": "62019100",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OTHER : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62019200",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OTHER : OF COTTON"
    },
    {
      "code": "62019300",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OTHER : OF MAN-MADE FIBRES"
    },
    {
      "code": "620199",
      "description": "Of other textile materials"
    },
    {
      "code": "62019910",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OTHER : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62019990",
      "description": "MENS OR BOYS OVERCOATS, CAR-COATS, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WINDCHEATERS, WIND-JACKETS AND SIMILAR ARTICLES OTHER THAN THOSE OF HEADING 6203 - OTHER : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6202",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKIJACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 ---- Overcoats, raincoats, car-coats,capes, cloaks and similar articles"
    },
    {
      "code": "620211",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62021110",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF WOOL OR FINE ANIMAL HAIR : COATS"
    },
    {
      "code": "62021190",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "62021200",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF COTTON"
    },
    {
      "code": "62021300",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF MAN-MADE FIBRES"
    },
    {
      "code": "620219",
      "description": "Of other textile materials"
    },
    {
      "code": "62021910",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF OTHER TEXTILE MATERIALS : COATS OF SILK"
    },
    {
      "code": "62021920",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF OTHER TEXTILE MATERIALS : COATS OF ALL OTHER FIBRES"
    },
    {
      "code": "62021990",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OVERCOATS, RAINCOATS, CAR-COATS, CAPES, CLOAKS AND SIMILAR ARTICLES : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620291",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62029110",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF WOOL OR FINE ANIMAL HAIR : WIND AND SKI-JACKETS, WIND-CHEATERS"
    },
    {
      "code": "62029190",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "620292",
      "description": "Of cotton"
    },
    {
      "code": "62029210",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF COTTON : WIND AND SKI-JACKETS, WIND-CHEATERS"
    },
    {
      "code": "62029290",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF COTTON : OTHER"
    },
    {
      "code": "620293",
      "description": "Of man-made fibres"
    },
    {
      "code": "62029310",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF MAN-MADE FIBRES : WIND AND SKI-JACKETS, WIND-CHEATERS"
    },
    {
      "code": "62029390",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF MAN-MADE FIBRES : OTHER"
    },
    {
      "code": "620299",
      "description": "Of other textile materials"
    },
    {
      "code": "62029911",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF OTHER TEXTILE MATERIALS : OF SILK : WIND AND SKI-JACKETS"
    },
    {
      "code": "62029919",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62029990",
      "description": "WOMENS OR GIRLS OVERCOATS, CAR-COATS, CAPES, CLOAKS, ANORAKS (INCLUDING SKI-JACKETS), WIND-CHEATERS, WIND-JACKETS AND SIMILAR ARTICLES, OTHER THAN THOSE OF HEADING 6204 - OTHER : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6203",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVEB RALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) ---- Suits"
    },
    {
      "code": "62031100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62031200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620319",
      "description": "Of other textile materials"
    },
    {
      "code": "62031910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF OTHER TEXTILE MATERIALS : OF COTTON"
    },
    {
      "code": "62031990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "62032100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62032200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF COTTON"
    },
    {
      "code": "62032300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620329",
      "description": "Of other textile materials"
    },
    {
      "code": "62032900",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "62032911",
      "description": "Khadi"
    },
    {
      "code": "62032919",
      "description": "Other"
    },
    {
      "code": "62032990",
      "description": "Other"
    },
    {
      "code": "620331",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62033100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62033110",
      "description": "Khadi"
    },
    {
      "code": "62033190",
      "description": "Oher"
    },
    {
      "code": "62033200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF COTTON"
    },
    {
      "code": "62033300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620339",
      "description": "Of other textile materials"
    },
    {
      "code": "62033910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62033911",
      "description": "Khadi"
    },
    {
      "code": "62033919",
      "description": "Other"
    },
    {
      "code": "62033990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "62034100",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "620342",
      "description": "Of cotton"
    },
    {
      "code": "62034200",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF COTTON"
    },
    {
      "code": "62034210",
      "description": "Handloom"
    },
    {
      "code": "62034290",
      "description": "Other"
    },
    {
      "code": "62034300",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620349",
      "description": "Of other textile materials"
    },
    {
      "code": "62034910",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62034990",
      "description": "MENS OR BOYS SUITS, ENSEMBLES, JACKETS, BLAZERS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6204",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) ---- Suits"
    },
    {
      "code": "62041100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF WOOL OF FINE ANIMAL HAIR"
    },
    {
      "code": "62041200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF COTTON"
    },
    {
      "code": "62041300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620419",
      "description": "Of other textile materials"
    },
    {
      "code": "62041911",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF OTHER TEXTILE MATERIALS : OF SILK : SEQUINNED OR BEADED WITH CHATTONS OR EMBROIDERED"
    },
    {
      "code": "62041919",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62041990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SUITS : OF OTHER TEXTILE MATERIALS : OF ALL OTHER FIBRES"
    },
    {
      "code": "62042100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "620422",
      "description": "Of cotton"
    },
    {
      "code": "62042210",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF COTTON : BLOUSES COMBINED WITH SKIRTS, TROUSERS OR SHORTS"
    },
    {
      "code": "62042290",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF COTTON : OTHER"
    },
    {
      "code": "62042300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620429",
      "description": "Of other textile materials"
    },
    {
      "code": "62042911",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF OTHER TEXTILE MATERIALS : OF SILK : SEQUINNED OR BEADED"
    },
    {
      "code": "62042912",
      "description": "Khadi"
    },
    {
      "code": "62042919",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62042990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - ENSEMBLES : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620431",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62043100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62043110",
      "description": "Khadi"
    },
    {
      "code": "62043190",
      "description": "Other"
    },
    {
      "code": "62043200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF COTTON"
    },
    {
      "code": "62043300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620439",
      "description": "Of other textile materials"
    },
    {
      "code": "62043911",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF OTHER TEXTILE MATERIALS : OF SILK : SEQUINNED OR BEADED WITH CHATTONS OR EMBROIDERED"
    },
    {
      "code": "62043912",
      "description": "Khadi"
    },
    {
      "code": "62043919",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62043990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - JACKETS AND BLAZERS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620441",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62044110",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF WOOL OR FINE ANIMAL HAIR : HOUSE COATS AND LIKE DRESSES"
    },
    {
      "code": "62044120",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF WOOL OR FINE ANIMAL HAIR : BLAZERS"
    },
    {
      "code": "62044190",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "620442",
      "description": "Of cotton"
    },
    {
      "code": "62044210",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF COTTON : HOUSE COATS AND THE LIKE DRESSES"
    },
    {
      "code": "62044220",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF COTTON : DRESSES"
    },
    {
      "code": "62044290",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF COTTON : OTHER"
    },
    {
      "code": "620443",
      "description": "Of synthetic fibres"
    },
    {
      "code": "62044310",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF SYNTHETIC FIBRES : HOUSE COATS AND THE LIKE"
    },
    {
      "code": "62044390",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF SYNTHETIC FIBRES : OTHER"
    },
    {
      "code": "62044400",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF SYNTHETIC FIBRES : OF ARTIFICIAL FIBRES"
    },
    {
      "code": "620449",
      "description": "Of other textile materials"
    },
    {
      "code": "62044911",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF OTHER TEXTILE MATERIALS : OF SILK : HOUSE COATS AND THE LIKE DRESSES"
    },
    {
      "code": "62044919",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62044990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - DRESSES : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "62045100",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SKIRTS AND DIVIDED SKIRTS : OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62045200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SKIRTS AND DIVIDED SKIRTS : OF COTTON"
    },
    {
      "code": "62045300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SKIRTS AND DIVIDED SKIRTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620459",
      "description": "Of other textile materials"
    },
    {
      "code": "62045910",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SKIRTS AND DIVIDED SKIRTS : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62045990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - SKIRTS AND DIVIDED SKIRTS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620461",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62046110",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF WOOL OR FINE ANIMAL HAIR : TROUSERS AND SHORTS"
    },
    {
      "code": "62046190",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "620462",
      "description": "Of cotton"
    },
    {
      "code": "62046200",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF COTTON"
    },
    {
      "code": "62046210",
      "description": "Handloom"
    },
    {
      "code": "62046290",
      "description": "Other"
    },
    {
      "code": "62046300",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "620469",
      "description": "Of other textile materials"
    },
    {
      "code": "62046911",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF OTHER TEXTILE MATERIALS : OF SILK : SEQUINNED OR BEADED OR EMBROIDERED"
    },
    {
      "code": "62046919",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62046990",
      "description": "WOMENS OR GIRLS SUITS, ENSEMBLES, JACKETS, BLAZERS, DRESSES, SKIRTS, DIVIDED SKIRTS, TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS (OTHER THAN SWIMWEAR) - TROUSERS, BIB AND BRACE OVERALLS, BREECHES AND SHORTS : 69 - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6205",
      "description": "MEN’S OR BOYS’ SHIRTS"
    },
    {
      "code": "62051000",
      "description": "MENS OR BOYS SHIRTS - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "620520",
      "description": "Of cotton"
    },
    {
      "code": "62052000",
      "description": "MENS OR BOYS SHIRTS - OF COTTON"
    },
    {
      "code": "62052010",
      "description": "Handloom"
    },
    {
      "code": "62052090",
      "description": "Other"
    },
    {
      "code": "62053000",
      "description": "MENS OR BOYS SHIRTS - OF MAN-MADE FIBRES"
    },
    {
      "code": "620590",
      "description": "Of other textile materials"
    },
    {
      "code": "62059010",
      "description": "MENS OR BOYS SHIRTS - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62059011",
      "description": "Khadi"
    },
    {
      "code": "62059019",
      "description": "Other"
    },
    {
      "code": "62059090",
      "description": "MENS OR BOYS SHIRTS - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6206",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES"
    },
    {
      "code": "620610",
      "description": "Of silk or silk waste"
    },
    {
      "code": "62061010",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF SILK OR SILK WASTE : OF SILK"
    },
    {
      "code": "62061090",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF SILK OR SILK WASTE : OTHER"
    },
    {
      "code": "62062000",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "620630",
      "description": "Of cotton"
    },
    {
      "code": "62063000",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF COTTON"
    },
    {
      "code": "62063010",
      "description": "Handloom"
    },
    {
      "code": "62063090",
      "description": "Other"
    },
    {
      "code": "62064000",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF MAN-MADE FIBRES"
    },
    {
      "code": "62069000",
      "description": "WOMENS OR GIRLS BLOUSES, SHIRTS AND SHIRT-BLOUSES - OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6207",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES ---- Underpants and brif"
    },
    {
      "code": "62071100",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - UNDERPANTS AND BRIEFS : OF COTTON"
    },
    {
      "code": "620719",
      "description": "Of other textile materials"
    },
    {
      "code": "62071910",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - UNDERPANTS AND BRIEFS : OF OTHER TEXTILE MATERIALS : OF SYNTHETIC FIBRES"
    },
    {
      "code": "62071920",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - UNDERPANTS AND BRIEFS : OF OTHER TEXTILE MATERIALS : OF WOOL"
    },
    {
      "code": "62071930",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - UNDERPANTS AND BRIEFS : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62071990",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - UNDERPANTS AND BRIEFS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620721",
      "description": "Of cotton"
    },
    {
      "code": "62072110",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHT SHIRTS AND PYJAMAS : OF COTTON"
    },
    {
      "code": "62072190",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHT SHIRTS AND PYJAMAS : OTHER"
    },
    {
      "code": "62072200",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHT SHIRTS AND PYJAMAS : OF MAN-MADE FIBRES"
    },
    {
      "code": "62072900",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHT SHIRTS AND PYJAMAS : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "620791",
      "description": "Of cotton"
    },
    {
      "code": "62079110",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF COTTON : DRESSING GOWNS AND BATHROBES"
    },
    {
      "code": "62079120",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF COTTON : UNDER SHIRTS OTHER THAN HAND PRINTED"
    },
    {
      "code": "62079190",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF COTTON : OTHER"
    },
    {
      "code": "62079200",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF MAN-MADE FIBRES"
    },
    {
      "code": "620799",
      "description": "Of other textile materials"
    },
    {
      "code": "62079911",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OF SILK : DRESSING GOWNS AND BATHROBES"
    },
    {
      "code": "62079919",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OF SILK : OTHER"
    },
    {
      "code": "62079921",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OF WOOL : DRESSING GOWNS AND BATHROBES"
    },
    {
      "code": "62079929",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OF WOOL : OTHER"
    },
    {
      "code": "62079990",
      "description": "MENS OR BOYS SINGLETS AND OTHER VESTS, UNDERPANTS, BRIEFS, NIGHTSHIRTS, PYJAMAS, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6208",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES ---- Slips and petticoats"
    },
    {
      "code": "62081100",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - SLIPS AND PETTICOATS : OF MAN-MADE FIBRES"
    },
    {
      "code": "620819",
      "description": "Of other textile materials"
    },
    {
      "code": "62081910",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - SLIPS AND PETTICOATS : OF OTHER TEXTILE MATERIALS : OF COTTON OTHER THAN HAND PRINTED"
    },
    {
      "code": "62081990",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - SLIPS AND PETTICOATS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620821",
      "description": "Of cotton"
    },
    {
      "code": "62082100",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHTDRESSES AND PYJAMAS : OF COTTON"
    },
    {
      "code": "62082110",
      "description": "Handloom"
    },
    {
      "code": "62082190",
      "description": "Other"
    },
    {
      "code": "62082200",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHTDRESSES AND PYJAMAS : OF MAN-MADE FIBRES"
    },
    {
      "code": "620829",
      "description": "Of other textile materials"
    },
    {
      "code": "62082910",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHTDRESSES AND PYJAMAS : OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62082920",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHTDRESSES AND PYJAMAS : OF OTHER TEXTILE MATERIALS : OF WOOL"
    },
    {
      "code": "62082990",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - NIGHTDRESSES AND PYJAMAS : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "620891",
      "description": "Of cotton"
    },
    {
      "code": "62089110",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF COTTON : DRESSING GOWNS AND BATHROBES"
    },
    {
      "code": "62089190",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF COTTON : OTHER"
    },
    {
      "code": "620892",
      "description": "Of man-made fibres"
    },
    {
      "code": "62089210",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF MAN-MADE FIBRES : DRESSING GOWNS AND BATHROBES"
    },
    {
      "code": "62089290",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF MAN-MADE FIBRES : OTHER"
    },
    {
      "code": "620899",
      "description": "Of other textile materials"
    },
    {
      "code": "62089910",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : DRESSING GOWNS AND BATHROBES OF WOOL"
    },
    {
      "code": "62089920",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : DRESSING GOWNS AND BATHROBES OF SILK"
    },
    {
      "code": "62089990",
      "description": "WOMENS OR GIRLS SINGLETS AND OTHER VESTS, SLIPS, PETTICOATS, BRIEFS, PANTIES, NIGHTDRESSES, PYJAMAS, NEGLIGES, BATHROBES, DRESSING GOWNS AND SIMILAR ARTICLES - OTHER : OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6209",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES"
    },
    {
      "code": "62091000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES - OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "620920",
      "description": "Of cotton"
    },
    {
      "code": "62092000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES - OF COTTON"
    },
    {
      "code": "62092010",
      "description": "Handloom"
    },
    {
      "code": "62092090",
      "description": "Other"
    },
    {
      "code": "62093000",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES - OF SYNTHETIC FIBRES"
    },
    {
      "code": "620990",
      "description": "Of other textile materials"
    },
    {
      "code": "62099010",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES - OF OTHER TEXTILE MATERIALS : OF SILK"
    },
    {
      "code": "62099090",
      "description": "BABIES GARMENTS AND CLOTHING ACCESSORIES - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6210",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907"
    },
    {
      "code": "62101000",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OF FABRICS OF HEADING 5602 OR 5603"
    },
    {
      "code": "621020",
      "description": "Other garments, of the type described in sub-headings 6201 11 to 6201 19"
    },
    {
      "code": "62102010",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6201 11 TO 6201 19 : OUTER GARMENTS, OF RUBBERISED TEXTILE FABRICS"
    },
    {
      "code": "62102020",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6201 11 TO 6201 19 : OUTER GARMENTS, OF FABRICS IMPREGNATED, COATED, COVERED OR LAMINATED WITH PREPARATIONS OF CELLULOSE DERIVATIVES AND OTHER ARTIFICIAL PLASTIC MATERIALS"
    },
    {
      "code": "62102030",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6201 11 TO 6201 19 : OUTER GARMENTS, OF FABRICS OTHERWISE IMPREGNATED OR COATED"
    },
    {
      "code": "62102090",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6201 11 TO 6201 19 : OTHER"
    },
    {
      "code": "621030",
      "description": "Other garments, of the type described in sub-headings 6202 11 to 6202 19"
    },
    {
      "code": "62103010",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6202 11 TO 6202 19 : OUTER GARMENTS, OF FABRICS IMPREGNATED, COATED, COVERED OR LAMINATED WITH PREPARATIONS OF CELLULOSE DERIVATIVES AND OTHER ARTIFICIAL PLASTIC MATERIALS"
    },
    {
      "code": "62103020",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6202 11 TO 6202 19 : OUTER GARMENTS, OF RUBBERISED TEXTILE FABRICS"
    },
    {
      "code": "62103030",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6202 11 TO 6202 19 : OUTER GARMENTS, OF FABRICS OTHERWISE IMPREGNATED"
    },
    {
      "code": "62103090",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER GARMENTS, OF THE TYPE DESCRIBED IN SUBHEADINGS 6202 11 TO 6202 19 : OTHER"
    },
    {
      "code": "621040",
      "description": "Other mens or boys garments"
    },
    {
      "code": "62104010",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER MENS OR BOYS GARMENTS : BULLET PROOF JACKET, BOMB DISPOSAL JACKET AND THE LIKE"
    },
    {
      "code": "62104090",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 - OTHER MENS OR BOYS GARMENTS : OTHER"
    },
    {
      "code": "62105000",
      "description": "GARMENTS, MADE UP OF FABRICS OF HEADING 5602, 5603, 5903, 5906 OR 5907 OTHER WOMENS OR GIRLS GARMENTS"
    },
    {
      "code": "6211",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS ---- Swimwear"
    },
    {
      "code": "62111100",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - SWIMWEAR : MENS OR BOYS"
    },
    {
      "code": "62111200",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - SWIMWEAR : WOMENS OR GIRLS"
    },
    {
      "code": "62112000",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - SKI SUITS"
    },
    {
      "code": "62113100",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, MENS OR BOYS: OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62113200",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, MENS OR BOYS : OF COTTON"
    },
    {
      "code": "62113300",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, MENS OR BOYS : OF MAN-MADE FIBRES"
    },
    {
      "code": "621139",
      "description": "Of other textile materials"
    },
    {
      "code": "62113900",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, MENS OR BOYS : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "62113911",
      "description": "Handloom"
    },
    {
      "code": "62113919",
      "description": "Other"
    },
    {
      "code": "62113990",
      "description": "Other"
    },
    {
      "code": "621142",
      "description": "Of cotton"
    },
    {
      "code": "62114210",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, WOMENS OR GIRLS : OF COTTON : KURTA AND SALWAR WITH OR WITHOUT DUPPATTA"
    },
    {
      "code": "62114290",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, WOMENS OR GIRLS : OF COTTON : OTHER"
    },
    {
      "code": "62114300",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, WOMENS OR GIRLS : OF MAN-MADE FIBRES"
    },
    {
      "code": "621149",
      "description": "Of other textile materials"
    },
    {
      "code": "62114910",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, WOMENS OR GIRLS --OF OTHER TEXTILE MATERIALS---OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "62114921",
      "description": "Khadi"
    },
    {
      "code": "62114929",
      "description": "Other"
    },
    {
      "code": "62114990",
      "description": "TRACK SUITS, SKI SUITS AND SWIMWEAR; OTHER GARMENTS - OTHER GARMENTS, WOMENS OR GIRLS --OF OTHER TEXTILE MATERIALS---OTHER"
    },
    {
      "code": "6212",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED"
    },
    {
      "code": "62121000",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED - BRASSIERES"
    },
    {
      "code": "62122000",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED - GIRDLES AND PANTY-GIRDLES"
    },
    {
      "code": "62123000",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED - CORSELETTES"
    },
    {
      "code": "621290",
      "description": "Other"
    },
    {
      "code": "62129010",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED - OTHER : SUSPENDER BELTS, BRACES, SUSPENDER GARTERS AND THE LIKE"
    },
    {
      "code": "62129090",
      "description": "BRASSIERES, GIRDLES, CORSETS, BRACES, SUSPENDERS, GARTERS AND SIMILAR ARTICLES AND PARTS THEREOF, WHETHER OR NOT KNITTED OR CROCHETED - OTHER : OTHER"
    },
    {
      "code": "6213",
      "description": "HANDKERCHIEFS"
    },
    {
      "code": "62131000",
      "description": "HANDKERCHIEFS - OF SILK OR SILK WASTE"
    },
    {
      "code": "62132000",
      "description": "HANDKERCHIEFS - OF COTTON"
    },
    {
      "code": "621390",
      "description": "Other"
    },
    {
      "code": "62139010",
      "description": "HANDKERCHIEFS - OTHER : OF MAN-MADE FIBRES"
    },
    {
      "code": "62139090",
      "description": "HANDKERCHIEFS - OTHER : OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6214",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE"
    },
    {
      "code": "621410",
      "description": "Of silk or silk waste"
    },
    {
      "code": "62141010",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF SILK OR SILK WASTE : SCARVES OF SILK MEASURING 60 CMS OR LESS"
    },
    {
      "code": "62141020",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF SILK OR SILK WASTE : SHAWLS, SCARVES (EXCEEDING 60 C SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF SILK OR SILK WASTE : MS) AND THE LIKE"
    },
    {
      "code": "62141030",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF HANDLOOM"
    },
    {
      "code": "62141090",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF SILK OR SILK WASTE : OTHER"
    },
    {
      "code": "621420",
      "description": "Of wool or fine animal hair"
    },
    {
      "code": "62142010",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF WOOL OR FINE ANIMAL HAIR : SHAWLS"
    },
    {
      "code": "62142020",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF WOOL OR FINE ANIMAL HAIR : SCARVES"
    },
    {
      "code": "62142021",
      "description": "Khadi"
    },
    {
      "code": "62142029",
      "description": "Other"
    },
    {
      "code": "62142030",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF WOOL OR FINE ANIMAL HAIR : MUFFLERS"
    },
    {
      "code": "62142031",
      "description": "Khadi"
    },
    {
      "code": "62142039",
      "description": "Other"
    },
    {
      "code": "62142090",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF WOOL OR FINE ANIMAL HAIR : OTHER"
    },
    {
      "code": "62143000",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF SYNTHETIC FIBRES"
    },
    {
      "code": "62144000",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF ARTIFICIAL FIBRES"
    },
    {
      "code": "621490",
      "description": "Of other textile materials"
    },
    {
      "code": "62149010",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : ABRABROOMAL, COTTON"
    },
    {
      "code": "62149021",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : CHADARS, OF COTTON : GREY"
    },
    {
      "code": "62149022",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : CHADARS, OF COTTON : WHITE BLEACHED"
    },
    {
      "code": "62149029",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : CHADARS, OF COTTON : OTHER"
    },
    {
      "code": "62149031",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : ODHANI, OF COTTON : GREY"
    },
    {
      "code": "62149032",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : ODHANI, OF COTTON : WHITE BLEACHED"
    },
    {
      "code": "62149039",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : ODHANI, OF COTTON : OTHER"
    },
    {
      "code": "62149040",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : SCARVES, OF COTTON"
    },
    {
      "code": "62149050",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : SHAWLS, MUFFLERS AND THE LIKE, OF COTTON"
    },
    {
      "code": "62149060",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : SHAWLS, MUFFLERS AND THE LIKE OF MAN-MADE FIBRES"
    },
    {
      "code": "62149090",
      "description": "SHAWLS, SCARVES, MUFFLERS, MANTILLAS, VEILS AND THE LIKE - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6215",
      "description": "TIES, BOW TIES AND CRAVATS"
    },
    {
      "code": "621510",
      "description": "Of silk or silk waste"
    },
    {
      "code": "62151000",
      "description": "TIES, BOW TIES AND CRAVATS - OF SILK OR SILK WASTE"
    },
    {
      "code": "62151010",
      "description": "Khadi"
    },
    {
      "code": "62151090",
      "description": "Other"
    },
    {
      "code": "62152000",
      "description": "TIES, BOW TIES AND CRAVATS - OF MAN-MADE FIBRES"
    },
    {
      "code": "621590",
      "description": "Of other textile materials"
    },
    {
      "code": "62159010",
      "description": "TIES, BOW TIES AND CRAVATS - OF OTHER TEXTILE MATERIALS : OF COTTON"
    },
    {
      "code": "62159090",
      "description": "TIES, BOW TIES AND CRAVATS - OF OTHER TEXTILE MATERIALS : OTHER"
    },
    {
      "code": "6216",
      "description": "GLOVES, MITTENS AND MITTS"
    },
    {
      "code": "621600",
      "description": "Gloves, mittens and mitts"
    },
    {
      "code": "62160010",
      "description": "GLOVES, MITTENS AND MITTS - GLOVES, MITTENS AND MITTS : OF COTTON"
    },
    {
      "code": "62160020",
      "description": "GLOVES, MITTENS AND MITTS - OF HANDLOOM"
    },
    {
      "code": "62160090",
      "description": "GLOVES, MITTENS AND MITTS - GLOVES, MITTENS AND MITTS : OTHER"
    },
    {
      "code": "6217",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212"
    },
    {
      "code": "621710",
      "description": "Accessories"
    },
    {
      "code": "62171010",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF COTTON"
    },
    {
      "code": "62171020",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF SYNTHETIC FIBRES"
    },
    {
      "code": "62171030",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF WOOL"
    },
    {
      "code": "62171040",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF SILK"
    },
    {
      "code": "62171050",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF REGENERATED FIBRE"
    },
    {
      "code": "62171060",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : FOR ARTICLES OF APPAREL OF OTHER FIBRES"
    },
    {
      "code": "62171070",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : STOCKINGS, SOCKS SOCKETTES AND THE LIKE OF COTTON"
    },
    {
      "code": "62171090",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - ACCESSORIES : OTHER"
    },
    {
      "code": "621790",
      "description": "Parts"
    },
    {
      "code": "62179010",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - PARTS : COLLAR CUFFS AND THE LIKE OF COTTON"
    },
    {
      "code": "62179020",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - PARTS : OF SILK"
    },
    {
      "code": "62179030",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - PARTS : OF WOOL"
    },
    {
      "code": "62179040",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - PARTS : SEPARATELY PRESENTED REMOVABLE LININGS FOR RAINCOATS AND OTHER"
    },
    {
      "code": "62179090",
      "description": "OTHER MADE UP CLOTHING ACCESSORIES; PARTS OF GARMENTS OR OF CLOTHING ACCESSORIES, OTHER THAN THOSE OF HEADING 6212 - PARTS : OTHER"
    },
    {
      "code": "63",
      "description": "Other made up textile articles; sets; worn clothing and worn textile articles; rags"
    },
    {
      "code": "6301",
      "description": "BLANKETS AND TRAVELLING RUGS"
    },
    {
      "code": "63011000",
      "description": "BLANKETS AND TRAVELLING RUGS ELECTRIC BLANKETS"
    },
    {
      "code": "63012000",
      "description": "BLANKETS AND TRAVELLING RUGS BLANKETS (OTHER THAN ELECTRIC BLANKETS) AND TRAVELLING RUGS, OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "63013000",
      "description": "BLANKETS AND TRAVELLING RUGS BLANKETS (OTHER THAN ELECTRIC BLANKETS) AND TRAVELLING RUGS, OF COTTON"
    },
    {
      "code": "63014000",
      "description": "BLANKETS AND TRAVELLING RUGS BLANKETS (OTHER THAN ELECTRIC BLANKETS) AND TRAVELLING RUGS, OF SYNTHETIC FIBRES"
    },
    {
      "code": "630190",
      "description": "Other blankets and travelling rugs"
    },
    {
      "code": "63019010",
      "description": "BLANKETS AND TRAVELLING RUGS OTHER BLANKETS AND TRAVELLING RUGS:JUTE BLANKETS INCLUDING BLANKETS OF BLENDED JUTE"
    },
    {
      "code": "63019090",
      "description": "BLANKETS AND TRAVELLING RUGS OTHER BLANKETS AND TRAVELLING RUGS: OTHER"
    },
    {
      "code": "6302",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN"
    },
    {
      "code": "630210",
      "description": "Bed linen, knitted or crocheted"
    },
    {
      "code": "63021010",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN BED LINEN, KNITTED OR CROCHETED: OF COTTON"
    },
    {
      "code": "63021090",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN BED LINEN, KNITTED OR CROCHETED: OTHER"
    },
    {
      "code": "630221",
      "description": "of cotton"
    },
    {
      "code": "63022110",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN - OF COTTON - OF HANDLOOM"
    },
    {
      "code": "63022190",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN - OF COTTON - OTHER"
    },
    {
      "code": "63022200",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER BED LINEN, PRINTED: OF MAN-MADE FIBRES"
    },
    {
      "code": "63022900",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER BED LINEN, PRINTED: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "63023100",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER BED LINEN: OF COTTON"
    },
    {
      "code": "63023200",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER BED LINEN: OF MAN-MADE FIBRES"
    },
    {
      "code": "63023900",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER BED LINEN: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "630240",
      "description": "Table linen, knitted or crocheted"
    },
    {
      "code": "63024010",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN TABLE LINEN, KNITTED OR CROCHETED: OF SILK"
    },
    {
      "code": "63024020",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN TABLE LINEN, KNITTED OR CROCHETED: OF WOOL OR FINE ANIMAL HAIR"
    },
    {
      "code": "63024030",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN TABLE LINEN, KNITTED OR CROCHETED: OF COTTON"
    },
    {
      "code": "63024040",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN TABLE LINEN, KNITTED OR CROCHETED: OF MAN-MADE FIBRES"
    },
    {
      "code": "63024090",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN TABLE LINEN, KNITTED OR CROCHETED: OTHER"
    },
    {
      "code": "630251",
      "description": "OF COTTON"
    },
    {
      "code": "63025110",
      "description": "OTHER TABLE LINEN - OF COTTON - OF HANDLOOM"
    },
    {
      "code": "63025190",
      "description": "OTHER TABLE LINEN- OF COTTON - OTHER"
    },
    {
      "code": "63025200",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER TABLE LINEN: OF FLAX"
    },
    {
      "code": "63025300",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER TABLE LINEN: OF MAN-MADE FIBRES"
    },
    {
      "code": "63025900",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER TABLE LINEN: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "63026010",
      "description": "TOILET LINEN AND KITCHEN LINEN, OF TERRY TOWELLING OR SIMILAR TERRY FABRICS, OF COTTON - OF HANDLOOM"
    },
    {
      "code": "63026090",
      "description": "TOILET LINEN AND KITCHEN LINEN, OF TERRY TOWELLING OR SIMILAR TERRY FABRICS, OF COTTON - OTHER"
    },
    {
      "code": "63029110",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN -  OF COTTON - HANDLOOM"
    },
    {
      "code": "63029190",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN - OF COTTON - OTHER THAN HANDLOOM"
    },
    {
      "code": "63029200",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER: OF FLAX"
    },
    {
      "code": "63029300",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER: OF MAN-MADE FIBRES"
    },
    {
      "code": "63029900",
      "description": "BED LINEN, TABLE LINEN, TOILET LINEN AND KITCHEN LINEN OTHER: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6303",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES - Knitted or crocheted :"
    },
    {
      "code": "63031100",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES KNITTED OR CROCHETED: OF COTTON"
    },
    {
      "code": "63031200",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES KNITTED OR CROCHETED:  OF SYNTHETIC FIBRES"
    },
    {
      "code": "63031900",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES KNITTED OR CROCHETED:  OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "63039100",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES OTHER: OF COTTON"
    },
    {
      "code": "63039200",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES OTHER: OF SYNTHETIC FIBRES"
    },
    {
      "code": "630399",
      "description": "Of other textile materials"
    },
    {
      "code": "63039910",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES OTHER: OF OTHER TEXTILE MATERIALS: SILK SHOWER CURTAINS"
    },
    {
      "code": "63039990",
      "description": "CURTAINS (INCLUDING DRAPES) AND INTERIOR BLINDS; CURTAIN OR BED VALANCES OTHER: OF OTHER TEXTILE MATERIALS: OTHER"
    },
    {
      "code": "6304",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 - Bedspreads"
    },
    {
      "code": "63041100",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 BEDSPREADS: KNITTED OR CROCHETED"
    },
    {
      "code": "630419",
      "description": "Other"
    },
    {
      "code": "63041910",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 BEDSPREADS: OTHER: BEDSHEETS AND BEDCOVERS, OF COTTON"
    },
    {
      "code": "63041920",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 BEDSPREADS: OTHER: BEDSPREADS OF SILK"
    },
    {
      "code": "63041930",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 BEDSPREADS: OTHER: BEDSHEETS AND BEDCOVERS OF MAN-MADE FIBRES"
    },
    {
      "code": "63041940",
      "description": "BED SHEETS AND BED COVERS OF COTTON, HANDLOOM"
    },
    {
      "code": "63041990",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 BEDSPREADS: OTHER: OTHER"
    },
    {
      "code": "63042000",
      "description": "Bed nets, of warp knit fabrics specified in Sub-heading Note 1 to this Chapter"
    },
    {
      "code": "630491",
      "description": "Knitted or crocheted"
    },
    {
      "code": "63049110",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: KNITTED OR CROCHETED: SILK BELT"
    },
    {
      "code": "63049120",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: KNITTED OR CROCHETED:WOOLLEN CUSHION COVER"
    },
    {
      "code": "63049190",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: KNITTED OR CROCHETED: OTHER"
    },
    {
      "code": "630492",
      "description": "Not knitted or crochetd, of cotton"
    },
    {
      "code": "63049211",
      "description": "COUNTERPANES OF HANDLOOM"
    },
    {
      "code": "63049219",
      "description": "COUNTERPANES - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049221",
      "description": "NAPKINS OF HANDLOOM"
    },
    {
      "code": "63049229",
      "description": "NAPKINS - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049231",
      "description": "PILLOW CASES AND PILLOW SLIPS - OF HANDLOOM"
    },
    {
      "code": "63049239",
      "description": "PILLOW CASES AND PILLOW SLIPS - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049241",
      "description": "TABLE CLOTH AND TABLE COVERS - OF HANDLOOM"
    },
    {
      "code": "63049249",
      "description": "TABLE CLOTH AND TABLE COVERS - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049250",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: NOT KNITTED OR CROCHETED, OF COTTON: TERRY TOWEL"
    },
    {
      "code": "63049260",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: NOT KNITTED OR CROCHETED, OF COTTON: TOWELS, OTHER THAN TERRY TOWEL"
    },
    {
      "code": "63049270",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: NOT KNITTED OR CROCHETED, OF COTTON: MOSQUITO NETS"
    },
    {
      "code": "63049281",
      "description": "CUSHION COVERS OF HANDLOOM"
    },
    {
      "code": "63049289",
      "description": "CUSHION COVERS - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049291",
      "description": "OTHER FURNISHING ARTICLES OF HANDLOOM"
    },
    {
      "code": "63049299",
      "description": "OTHER FURNISHING ARTICLES - OTHER THAN HANDLOOM"
    },
    {
      "code": "63049300",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: NOT KNITTED OR CROCHETED, OF SYNTHETIC FIBRES"
    },
    {
      "code": "630499",
      "description": "Not knitted or crocheted, of other textile material"
    },
    {
      "code": "63049910",
      "description": "OTHER FURNISHING ARTICLES, EXCLUDING THOSE OF HEADING 9404 OTHER: NOT KNITTED OR CROCHETED, OF OTHER TEXTILE MATERIAL: SILK CUSHION COVERS"
    },
    {
      "code": "63049991",
      "description": "OTHER FURNISHING ARTICLES - NOT KNITTED OR CROCHETED, OF OTHER TEXTILE MATERIAL - OF SILK, HANDLOOM"
    },
    {
      "code": "63049992",
      "description": "OTHER FURNISHING ARTICLES - NOT KNITTED OR CROCHETED, OF OTHER TEXTILE MATERIAL - OF WOOL, HANDLOOM"
    },
    {
      "code": "63049999",
      "description": "OTHER FURNISHING ARTICLES - NOT KNITTED OR CROCHETED, OF OTHER TEXTILE MATERIAL - OTHER THAN OF SILK, HANDLOOM OR"
    },
    {
      "code": "6305",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS"
    },
    {
      "code": "630510",
      "description": "Of jute or of other textile bast fibres of heading 5303"
    },
    {
      "code": "63051010",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE BAGGING FOR RAW COTTON"
    },
    {
      "code": "63051020",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE CORN (GRAINS) SACKS"
    },
    {
      "code": "63051030",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE HESSIAN BAGS"
    },
    {
      "code": "63051040",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE SACKING BAGS"
    },
    {
      "code": "63051050",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE WOOL SACKS"
    },
    {
      "code": "63051060",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: PLASTIC COATED OR PAPER CUM POLYTHENE LINED JUTE BAGS AND SACKS"
    },
    {
      "code": "63051070",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: PAPER LAMINATED HESSIAN JUTE"
    },
    {
      "code": "63051080",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: JUTE SOIL SAVERS"
    },
    {
      "code": "63051090",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF JUTE OR OF OTHER TEXTILE BAST FIBRES OF HEADING 5303: OTHER"
    },
    {
      "code": "63052000",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF COTTON"
    },
    {
      "code": "63053200",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF MAN-MADE TEXTILE MATERIALS: FLEXIBLE INTERMEDIATE BULK CONTAINERS"
    },
    {
      "code": "63053300",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF MAN-MADE TEXTILE MATERIALS: OTHER, OF POLYETHYLENE OR POLYPROPYLENE STRIP OR THE LIKE"
    },
    {
      "code": "63053900",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF MAN-MADE TEXTILE MATERIALS: OTHER"
    },
    {
      "code": "63059000",
      "description": "SACKS AND BAGS, OF A KIND USED FOR THE PACKING OF GOODS OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6306",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS - Tarpaulins, awnings and sunblinds"
    },
    {
      "code": "63061100",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS: OF COTTON"
    },
    {
      "code": "63061200",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS: OF SYNTHETIC FIBRES"
    },
    {
      "code": "630619",
      "description": "Of other textile materials"
    },
    {
      "code": "63061910",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS:OF OTHER TEXTILE MATERIALS: JUTE TARPAULINS (INCLUDING DW TARPAULIN)"
    },
    {
      "code": "63061920",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS:OF OTHER TEXTILE MATERIALS: BLINDS OR AWNINGS OF COIR"
    },
    {
      "code": "63061930",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS:OF OTHER TEXTILE MATERIALS: VENETIAN OR AUSTRIAN BLINDS"
    },
    {
      "code": "63061990",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TARPAULINS, AWNINGS AND SUNBLINDS:OF OTHER TEXTILE MATERIALS: OTHER"
    },
    {
      "code": "63062100",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TENTS: OF COTTON"
    },
    {
      "code": "63062200",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TENTS: OF SYNTHETIC FIBRES"
    },
    {
      "code": "630629",
      "description": "Of other textile materials"
    },
    {
      "code": "63062910",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS TENTS: OF OTHER TEXTILE MATERIALS: OF JUTE"
    },
    {
      "code": "63062990",
      "description": "OTHERS"
    },
    {
      "code": "63063000",
      "description": "SAILS"
    },
    {
      "code": "63063100",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS SAILS: OF SYNTHETIC FIBRES"
    },
    {
      "code": "63063910",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS SAILS: OF OTHER TEXTILE MATERIALS: OF COTTON"
    },
    {
      "code": "63063990",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS SAILS: OF OTHER TEXTILE MATERIALS: OTHER"
    },
    {
      "code": "630640",
      "description": "Pneumatic mattresses"
    },
    {
      "code": "63064000",
      "description": "PNEUMATIC MATTRESSES"
    },
    {
      "code": "63064100",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS PNEUMATIC MATTRESSES: OF COTTON"
    },
    {
      "code": "63064900",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS; TENTS; SAILS FOR BOATS, SAILBOARDS OR LANDCRAFT; CAMPING GOODS PNEUMATIC MATTRESSES: OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "630690",
      "description": "Other"
    },
    {
      "code": "63069010",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS;TENTS;SAILS FOR BOATS,SAILBOARDS OR LANDCRAFT;CAMPING GOODS-OTHER---OF COTTON"
    },
    {
      "code": "63069090",
      "description": "TARPAULINS, AWNINGS AND SUNBLINDS;TENTS;SAILS FOR BOATS,SAILBOARDS OR LANDCRAFT;CAMPING GOODS-OTHER---OF OTHER TEXTILE MATERIALS"
    },
    {
      "code": "6307",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS"
    },
    {
      "code": "630710",
      "description": "Floor-cloths, dish cloths, dusters and similar cleaning cloths"
    },
    {
      "code": "63071010",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS FLOOR-CLOTHS, DISH-CLOTHS, DUSTERS AND SIMILAR CLEANING CLOTHS: OF COTTON"
    },
    {
      "code": "63071020",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS FLOOR-CLOTHS, DISH-CLOTHS, DUSTERS AND SIMILAR CLEANING CLOTHS: OF MAN-MADE FIBRES"
    },
    {
      "code": "63071030",
      "description": "FLOOR CLOTHES, DISH CLOTHES, DUSTERS AND SIMILAR CLEANING CLOTHES - OF COTTON,HANDLOOM"
    },
    {
      "code": "63071090",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS FLOOR-CLOTHS, DISH-CLOTHS, DUSTERS AND SIMILAR CLEANING CLOTHS: OTHER"
    },
    {
      "code": "630720",
      "description": "Life-jackets and life-belts"
    },
    {
      "code": "63072010",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS LIFE-JACKETS AND LIFE-BELTS:OF COTTON"
    },
    {
      "code": "63072090",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS LIFE-JACKETS AND LIFE-BELTS:OTHER"
    },
    {
      "code": "630790",
      "description": "Other"
    },
    {
      "code": "63079011",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER: DRESS MATERIALS HAND PRINTED: OF COTTON"
    },
    {
      "code": "63079012",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER: DRESS MATERIALS HAND PRINTED:OF SILK"
    },
    {
      "code": "63079013",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER: DRESS MATERIALS HAND PRINTED: OF MAN-MADE FIBRES"
    },
    {
      "code": "63079019",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER: DRESS MATERIALS HAND PRINTED:OTHER"
    },
    {
      "code": "63079020",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER:MADE UP ARTICLES OF COTTON"
    },
    {
      "code": "63079090",
      "description": "OTHER MADE UP ARTICLES, INCLUDING DRESS PATTERNS OTHER: OTHER"
    },
    {
      "code": "6308",
      "description": "II..SETS SETS CONSISTING OF WOVEN FABRIC AND YARN, WHETHER OR NOT WITH ACCESSORIES, FOR MAKING UP INTO RUGS, TAPESTRIES, EMBROIDERED TABLE CLOTHS OR SERVIETTES, OR SIMILAR TEXTILE ARTICLES, PUT UP IN PACKINGSFOR RETAIL SALE"
    },
    {
      "code": "63080000",
      "description": "SETS CONSISTING OF WOVEN FABRIC AND YARN, WHETHER OR NOT WITH ACCESSORIES, FOR MAKING UP INTO RUGS,TAPESTRIES, EMBROIDERED TABLE CLOTHS OR SERVIETTES,OR SIMILAR TEXTILE ARTICLES, PUT UP IN PACKINGSFOR RETAIL SALE"
    },
    {
      "code": "6309",
      "description": "WORN CLOTHING AND OTHER WORN ARTICLES"
    },
    {
      "code": "63090000",
      "description": "WORN CLOTHING AND OTHER WORN ARTICLES"
    },
    {
      "code": "6310",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS"
    },
    {
      "code": "631010",
      "description": "Sorted"
    },
    {
      "code": "63101010",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS SORTED: WOOLLEN RAGS"
    },
    {
      "code": "63101020",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS SORTED: COTTON RAGS"
    },
    {
      "code": "63101030",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS SORTED: GUNNY CUTTINGS"
    },
    {
      "code": "63101090",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS SORTED: OTHER"
    },
    {
      "code": "63109010",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS OTHER: WOOLEN RAGS"
    },
    {
      "code": "63109020",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS OTHER: COTTON RAGS"
    },
    {
      "code": "63109030",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS OTHER: GUNNY CUTTINGS"
    },
    {
      "code": "63109040",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS OTHER: SYNTHETIC RAGS ."
    },
    {
      "code": "63109090",
      "description": "USED OR NEW RAGS, SCRAP TWINE, CORDAGE, ROPE AND CABLES AND WORN OUT ARTICLES OF TWINE, CORDAGE, ROPE OR CABLES, OF TEXTILE MATERIALS OTHER: OTHER"
    },
    {
      "code": "64",
      "description": "Footwear, gaiters and the like; parts of such articles"
    },
    {
      "code": "6401",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES"
    },
    {
      "code": "640110",
      "description": "Footwear incorporating a protective metal toe-cap"
    },
    {
      "code": "64011010",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES FOOTWEAR INCORPORATING A PROTECTIVE METAL TOE-CAP: OF RUBBER"
    },
    {
      "code": "64011090",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES FOOTWEAR INCORPORATING A PROTECTIVE METAL TOE-CAP: OTHER"
    },
    {
      "code": "64019110",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : COVERING THE KNEE: OF RUBBER"
    },
    {
      "code": "64019190",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : COVERING THE KNEE: OTHER"
    },
    {
      "code": "640192",
      "description": "Covering the ankle but not covering the knee"
    },
    {
      "code": "64019210",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : COVERING THE ANKLE BUT NOT COVERING THE KNEE: OF RUBBER"
    },
    {
      "code": "64019290",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : COVERING THE ANKLE BUT NOT COVERING THE KNEE: OTHER"
    },
    {
      "code": "640199",
      "description": "Other"
    },
    {
      "code": "64019910",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : OTHER: OF RUBBER"
    },
    {
      "code": "64019990",
      "description": "WATERPROOF FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR OF PLASTICS, THE UPPERS OF WHICH ARE NEITHER FIXED TO THE SOLE NOR ASSEMBLED BY STITCHING, RIVETING, NAILING, SCREWING, PLUGGING OR SIMILAR PROCESSES OTHER FOOTWEAR : OTHER: OTHER"
    },
    {
      "code": "6402",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS - Sports footwear"
    },
    {
      "code": "640212",
      "description": "Ski-boots, cross-country ski footwear and snowboard boots"
    },
    {
      "code": "64021210",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS SPORTS FOOTWEAR : SKI-BOOTS, CROSS-COUNTRY SKI FOOTWEAR AND SNOWBOARD BOOTS: OF RUBBER"
    },
    {
      "code": "64021290",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS SPORTS FOOTWEAR : SKI-BOOTS, CROSS-COUNTRY SKI FOOTWEAR AND SNOWBOARD BOOTS: OTHER"
    },
    {
      "code": "640219",
      "description": "Other"
    },
    {
      "code": "64021910",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS SPORTS FOOTWEAR : OTHER : OF RUBBER"
    },
    {
      "code": "64021990",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS SPORTS FOOTWEAR : OTHER : OTHER"
    },
    {
      "code": "640220",
      "description": "Footwear with upper straps or thongs assembled to the sole by means of plugs"
    },
    {
      "code": "64022010",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS FOOTWEAR WITH UPPER STRAPS OR THONGS ASSEMBLED TO THE SOLE BY MEANS OF PLUGS: OF RUBBER"
    },
    {
      "code": "64022090",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS FOOTWEAR WITH UPPER STRAPS OR THONGS ASSEMBLED TO THE SOLE BY MEANS OF PLUGS: OTHER"
    },
    {
      "code": "64023010",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR, INCORPORATING A PROTECTIVE METAL TOE-CAP: OF RUBBER"
    },
    {
      "code": "64023090",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR, INCORPORATING A PROTECTIVE METAL TOE-CAP: OTHER"
    },
    {
      "code": "640291",
      "description": "Covering the ankle"
    },
    {
      "code": "64029110",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR :COVERING THE ANKLE:OF RUBBER"
    },
    {
      "code": "64029190",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR :COVERING THE ANKLE:OTHER"
    },
    {
      "code": "640299",
      "description": "Other"
    },
    {
      "code": "64029910",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR :OTHER: OF RUBBER"
    },
    {
      "code": "64029990",
      "description": "OTHER FOOTWEAR WITH OUTER SOLES AND UPPERS OF RUBBER OR PLASTICS OTHER FOOTWEAR :OTHER: OTHER"
    },
    {
      "code": "6403",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER - Sports footwear"
    },
    {
      "code": "64031200",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER SPORTS FOOTWEAR : SKI-BOOTS, CROSS-COUNTRY SKI FOOTWEAR AND SNOWBOARD BOOTS"
    },
    {
      "code": "640319",
      "description": "Other"
    },
    {
      "code": "64031910",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER SPORTS FOOTWEAR : OTHER: WITH OUTER SOLES OF LEATHER"
    },
    {
      "code": "64031920",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER SPORTS FOOTWEAR : OTHER: WITH OUTER SOLES OF RUBBER"
    },
    {
      "code": "64031990",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER SPORTS FOOTWEAR :OTHER: OTHER"
    },
    {
      "code": "640320",
      "description": "Footwear with outer soles of leather, and uppers which consist of leather straps across the instep and around the big toe"
    },
    {
      "code": "64032011",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, CLOSED TOE: FOR MEN"
    },
    {
      "code": "64032012",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, CLOSED TOE: FOR WOMEN"
    },
    {
      "code": "64032013",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, CLOSED TOE: FOR CHILDREN"
    },
    {
      "code": "64032019",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, CLOSED TOE: OTHER"
    },
    {
      "code": "64032021",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, OPEN TOE: FOR MEN"
    },
    {
      "code": "64032022",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, OPEN TOE:FOR WOMEN"
    },
    {
      "code": "64032023",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, OPEN TOE:FOR CHILDREN"
    },
    {
      "code": "64032029",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: ALL LEATHER, OPEN TOE:OTHERS"
    },
    {
      "code": "64032030",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: OF LEATHER SOLES WITH EMBROIDERED UPPERS"
    },
    {
      "code": "64032040",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: KOLAPURI CHAPPALS AND SIMILAR FOOTWEAR"
    },
    {
      "code": "64032090",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR WITH OUTER SOLES OF LEATHER, AND UPPERS WHICH CONSIST OF LEATHER STRAPS ACROSS THE INSTEP AND AROUND THE BIG TOE: OTHER"
    },
    {
      "code": "64033000",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER FOOTWEAR MADE ON A BASE OR PLATFORM OF WOOD, NOT HAVING AN INNER SOLE OR A PROTECTIVE METAL TOE-CAP"
    },
    {
      "code": "64034000",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, INCORPORATING A PROTECTIVE METAL TOE-CAP"
    },
    {
      "code": "640351",
      "description": "Covering the ankle"
    },
    {
      "code": "64035111",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : COVERING THE ANKLE: ALL LEATHER SHOES: FOR MEN"
    },
    {
      "code": "64035112",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : COVERING THE ANKLE: ALL LEATHER SHOES: FOR WOMEN"
    },
    {
      "code": "64035113",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : COVERING THE ANKLE: ALL LEATHER SHOES: FOR CHILDREN"
    },
    {
      "code": "64035119",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : COVERING THE ANKLE: ALL LEATHER SHOES: OTHER"
    },
    {
      "code": "64035190",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : COVERING THE ANKLE: OTHER"
    },
    {
      "code": "640359",
      "description": "Other"
    },
    {
      "code": "64035910",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : OTHER: FOR MEN"
    },
    {
      "code": "64035920",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : OTHER: FOR WOMEN"
    },
    {
      "code": "64035930",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : OTHER: FOR CHILDREN"
    },
    {
      "code": "64035990",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR, WITH OUTER SOLES OF LEATHER : OTHER: OTHER"
    },
    {
      "code": "640391",
      "description": "Covering the ankle"
    },
    {
      "code": "64039110",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: COVERING THE ANKLE: LEATHER BOOTS AND OTHER FOOTWEAR WITH RUBBER SOLE"
    },
    {
      "code": "64039120",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: COVERING THE ANKLE: LEATHER FOOTWEAR WITH PLASTIC AND SYNTHETIC SOLE"
    },
    {
      "code": "64039190",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: COVERING THE ANKLE: OTHER"
    },
    {
      "code": "640399",
      "description": "Other"
    },
    {
      "code": "64039910",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: OTHER: LEATHER SANDALS WITH RUBBER SOLE"
    },
    {
      "code": "64039920",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: OTHER: LEATHER SANDALS WITH PLASTIC OR SYNTHETIC SOLE"
    },
    {
      "code": "64039990",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF LEATHER OTHER FOOTWEAR: OTHER: OTHER"
    },
    {
      "code": "6404",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS - Footwear with outer soles of rubber or plastics"
    },
    {
      "code": "640411",
      "description": "Sports footwear; tennis shoes, basketball shoes, gym shoes, training shoes and the like"
    },
    {
      "code": "64041110",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : SPORTS FOOTWEAR; TENNIS SHOES, BASKETBALL SHOES, GYM SHOES, TRAINING SHOES AND THE LIKE: OF RUBBER SOLE WITH CANVAS UPPER"
    },
    {
      "code": "64041120",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : SPORTS FOOTWEAR; TENNIS SHOES, BASKETBALL SHOES, GYM SHOES, TRAINING SHOES AND THE LIKE: OF RUBBER SOLE WITH LEATHER CLOTH UPPERS"
    },
    {
      "code": "64041190",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : SPORTS FOOTWEAR; TENNIS SHOES, BASKETBALL SHOES, GYM SHOES, TRAINING SHOES AND THE LIKE: OTHER"
    },
    {
      "code": "640419",
      "description": "Other"
    },
    {
      "code": "64041910",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : OTHER: OF RUBBER SOLE WITH CANVAS UPPER"
    },
    {
      "code": "64041920",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : OTHER: OF RUBBER SOLE WITH LEATHER CLOTH UPPERS"
    },
    {
      "code": "64041990",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF RUBBER OR PLASTICS : OTHER: OTHER"
    },
    {
      "code": "64042000",
      "description": "FOOTWEAR WITH OUTER SOLES OF RUBBER, PLASTICS, LEATHER OR COMPOSITION LEATHER AND UPPERS OF TEXTILE MATERIALS FOOTWEAR WITH OUTER SOLES OF LEATHER OR COMPOSITION LEATHER"
    },
    {
      "code": "6405",
      "description": "OTHER FOOTWEAR"
    },
    {
      "code": "64051000",
      "description": "OTHER FOOTWEAR WITH UPPERS OF LEATHER OR COMPOSITION LEATHER"
    },
    {
      "code": "64052000",
      "description": "OTHER FOOTWEAR WITH UPPERS OF TEXTILE MATERIALS"
    },
    {
      "code": "64059000",
      "description": "OTHER FOOTWEAR OTHER"
    },
    {
      "code": "6406",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF"
    },
    {
      "code": "640610",
      "description": "Uppers and parts thereof, other"
    },
    {
      "code": "64061010",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF UPPERS AND PARTS THEREOF, OTHER THAN STIFFENERS: EMBROIDERED UPPERS OF TEXTILE MATERIALS"
    },
    {
      "code": "64061020",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF UPPERS AND PARTS THEREOF, OTHER THAN STIFFENERS:LEATHER UPPERS (PREPARED)"
    },
    {
      "code": "64061030",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF UPPERS AND PARTS THEREOF, OTHER THAN STIFFENERS:GOAT LINING"
    },
    {
      "code": "64061040",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF UPPERS AND PARTS THEREOF, OTHER THAN STIFFENERS: SHEEP LINING"
    },
    {
      "code": "64061090",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF UPPERS AND PARTS THEREOF, OTHER THAN STIFFENERS:OTHER"
    },
    {
      "code": "640620",
      "description": "Outer soles and heels, of rubber and plastics"
    },
    {
      "code": "64062000",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF OUTER SOLES AND HEELS, OF RUBBER OR PLASTICS"
    },
    {
      "code": "640690",
      "description": "Other"
    },
    {
      "code": "64069010",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---OF WOOD"
    },
    {
      "code": "64069020",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---LEATHER PARTS OTHER THAN SOLES AND PREPARED UPPERS"
    },
    {
      "code": "64069030",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---LEATHER SOLES"
    },
    {
      "code": "64069040",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---GAITERS, LEGGINGS AND SIMILAR ARTICLES"
    },
    {
      "code": "64069050",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---PARTS OF GAITERS, LEGGINGS AND SIMILAR ARTICLES"
    },
    {
      "code": "64069090",
      "description": "PARTS OF FOOTWEAR (INCLUDING UPPERS WHETHER OR NOT ATTACHED TO SOLES OTHER THAN OUTER SOLES); REMOVABLE IN-SOLES, HEEL CUSHIONS AND SIMILAR ARTICLES; GAITERS, LEGGINGS AND SIMILAR ARTICLES, AND PARTS THEREOF- OTHER---OTHER"
    },
    {
      "code": "640699",
      "description": "Of other materials"
    },
    {
      "code": "65",
      "description": "Headgear and parts thereof"
    },
    {
      "code": "6501",
      "description": "HATS AND OTHER HEADGER, KNITTED OR CROCHETED, OR MADE UP FROM LACE, FELT OR OTHER TEXTILE FABRIC, IN THE PIECE (BUT NOT IN STRIPS), WHETHER OR NOT LINED OR TRIMMED; HAIR-NETS OF ANY MATERIAL, WHETHER OR NOT LINED OR TRIMMED"
    },
    {
      "code": "650100",
      "description": "Hat-forms, hat bodies and hoods of felt, neither blocked to shape nor with made brims; plateaux and manchons (including slit manchons), of felt"
    },
    {
      "code": "65010010",
      "description": "HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT: OF COTTON"
    },
    {
      "code": "65010020",
      "description": "HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT: OF WOOL AND FUR FELT"
    },
    {
      "code": "65010090",
      "description": "HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT HAT-FORMS, HAT BODIES AND HOODS OF FELT, NEITHER BLOCKED TO SHAPE NOR WITH MADE BRIMS; PLATEAUX AND MANCHONS (INCLUDING SLIT MANCHONS), OF FELT: OTHER"
    },
    {
      "code": "6502",
      "description": "HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED"
    },
    {
      "code": "650200",
      "description": "Hat-shapes, plaited or made by assembling strips of any material, neither blocked to shape, nor with made brims, nor lined, nor trimmed"
    },
    {
      "code": "65020010",
      "description": "HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED: OF COTTON"
    },
    {
      "code": "65020020",
      "description": "HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED: OF WOOL"
    },
    {
      "code": "65020090",
      "description": "HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED HAT-SHAPES, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, NEITHER BLOCKED TO SHAPE, NOR WITH MADE BRIMS, NOR LINED, NOR TRIMMED: OTHER"
    },
    {
      "code": "6503",
      "description": "OMITTED"
    },
    {
      "code": "65030000",
      "description": "Omitted"
    },
    {
      "code": "6504",
      "description": "HATS AND OTHER HEADGEAR, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, WHETHER ORNOT LINED OR TRIMMED"
    },
    {
      "code": "650400",
      "description": "Hats and other head gear"
    },
    {
      "code": "65040000",
      "description": "HATS AND OTHER HEADGEAR, PLAITED OR MADE BY ASSEMBLING STRIPS OF ANY MATERIAL, WHETHER ORNOT LINED OR TRIMMED"
    },
    {
      "code": "6505",
      "description": "HATS AND OTHER HEADGEAR, KNITTED OR CROCHETED, OR MADE UP FROM LACE, FELT OR OTHER TEXTILE FABRIC, IN THE PIECE (BUT NOT IN STRIPS), WHETHER OR NOT LINED OR TRIMMED; HAIR-NETS OF ANY MATERIAL, WHETHER OR NOT LINED OR TRIMMED"
    },
    {
      "code": "650500",
      "description": "Hats and other headgear, knitted or crocheted, or made up from lace, felt or other textile fabric, in the piece (but not in strips), whether or not lined or trimmed; hair-nets of any material, whether or not lined or trimmed"
    },
    {
      "code": "65050010",
      "description": "HATS AND OTHER HEADGEAR, KNITTED OR CROCHETED, OR MADE UP FROM LACE, FELT OR OTHER TEXTILE FABRIC, IN THE PIECE (BUT NOT IN STRIPS), WHETHER OR NOT LINED OR TRIMMED; HAIR-NETS OF ANY MATERIAL, WHETHER OR NOT LINED OR TRIMMED--- HAIR-NETS"
    },
    {
      "code": "65050090",
      "description": "HATS AND OTHER HEADGEAR, KNITTED OR CROCHETED, OR MADE UP FROM LACE, FELT OR OTHER TEXTILE FABRIC, IN THE PIECE (BUT NOT IN STRIPS), WHETHER OR NOT LINED OR TRIMMED; HAIR-NETS OF ANY MATERIAL, WHETHER OR NOT LINED OR TRIMME---OTHER"
    },
    {
      "code": "6506",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED"
    },
    {
      "code": "650610",
      "description": "Safety headgear"
    },
    {
      "code": "65061010",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED SAFETY HEADGEAR: SPEED GLASS WELDING HELMETS OR OTHER HELMETS MEANT FOR INDUSTRIAL USE"
    },
    {
      "code": "65061090",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED SAFETY HEADGEAR: OTHER"
    },
    {
      "code": "65069100",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED OTHER:OF RUBBER OR OF PLASTICS"
    },
    {
      "code": "65069200",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED OTHER:OF FURSKIN"
    },
    {
      "code": "65069900",
      "description": "OTHER HEADGEAR, WHETHER OR NOT LINED OR TRIMMED OTHER:OF OTHER MATERIALS"
    },
    {
      "code": "6507",
      "description": "HEAD-BANDS, LININGS, COVERS, HAT FOUNDATIONS, HAT FRAMES, PEAKS AND CHINSTRAPS, FOR HEADGEAR"
    },
    {
      "code": "65070000",
      "description": "HEAD-BANDS, LININGS, COVERS, HAT FOUNDATIONS, HAT FRAMES, PEAKS AND CHINSTRAPS, FOR HEADGEAR"
    },
    {
      "code": "66",
      "description": "Umbrellas, sun umbrellas, walking-sticks, seat-sticks, whips, riding-crops and parts thereof"
    },
    {
      "code": "6601",
      "description": "UMBRELLAS AND SUN UMBRELLAS (INCLUDING WALKING-STICK UMBRELLAS, GARDEN UMBRELLAS AND SIMILAR UMBRELLAS)"
    },
    {
      "code": "66011000",
      "description": "UMBRELLAS AND SUN UMBRELLAS (INCLUDING WALKINGSTICK UMBRELLAS, GARDEN UMBRELLAS AND SIMILAR UMBRELLAS) - GARDEN OR SIMILAR UMBRELLAS"
    },
    {
      "code": "66019100",
      "description": "UMBRELLAS AND SUN UMBRELLAS (INCLUDING WALKINGSTICK UMBRELLAS, GARDEN UMBRELLAS AND SIMILAR UMBRELLAS)- OTHER: HAVING A TELESCOPIC SHAFT"
    },
    {
      "code": "66019900",
      "description": "UMBRELLAS AND SUN UMBRELLAS (INCLUDING WALKINGSTICK UMBRELLAS, GARDEN UMBRELLAS AND SIMILAR UMBRELLAS)- OTHER: OTHER"
    },
    {
      "code": "6602",
      "description": "WALKING-STICKS, SEAT-STICKS, WHIPS, RIDING CROPS AND THE LIKE"
    },
    {
      "code": "66020000",
      "description": "WALKING-STICKS, SEAT-STICKS, WHIPS, RIDING CROPS AND THE LIKE"
    },
    {
      "code": "6603",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602"
    },
    {
      "code": "66031010",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602- HANDLES AND KNOBS:UMBRELLA HANDLES WITH OR WITHOUT STEMS, PLASTIC"
    },
    {
      "code": "66031090",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602- HANDLES AND KNOBS:OTHER"
    },
    {
      "code": "66032000",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602 - UMBRELLA FRAMES, INCLUDING FRAMES MOUNTED ON SHAFTS (STICKS)"
    },
    {
      "code": "660390",
      "description": "Other"
    },
    {
      "code": "66039010",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602- OTHER:UMBRELLA RIBS"
    },
    {
      "code": "66039090",
      "description": "PARTS, TRIMMINGS AND ACCESSORIES OF ARTICLES OF HEADING 6601 TO 6602- OTHER:OTHER"
    },
    {
      "code": "67",
      "description": "Prepared feathers and down and articles made of feathers or of down; artificial flowers; articles of human hair"
    },
    {
      "code": "6701",
      "description": "SKINS AND OTHER PARTS OF BIRDSWITH THEIR FEATHERS OR DOWN, FEATHERS, PARTS OF FEATHERS, DOWN AND ARTICLES THEREOF (OTHER THAN GOODS OF HEADING 0505 AND WORKED QUILLS AND SCAPES)"
    },
    {
      "code": "670100",
      "description": "Skins and other parts of birds with their feathers or down, feathers, parts of feathers, down and articles thereof (other than goods of heading 0505 and worked quills and scapes)"
    },
    {
      "code": "67010010",
      "description": "SKINS AND OTHER PARTS OF BIRDS WITH THEIR FEATHERS OR DOWN, FEATHERS, PARTS OF FEATHERS, DOWN AND ARTICLES THEREOF (OTHER THAN GOODS OF HEADING 0505 AND WORKED QUILLS AND SCAPES) SKINS AND OTHER PARTS OF BIRDS WITH THEIR FEATHERS OR DOWN, FEATHERS, PARTS OF FEATHERS, DOWN AND ARTICLES THEREOF (OTHER THAN GOODS OF HEADING 0505 AND WORKED QUILLS AND SCAPES): FEATHER DUSTERS"
    },
    {
      "code": "67010090",
      "description": "SKINS AND OTHER PARTS OF BIRDS WITH THEIR FEATHERS OR DOWN, FEATHERS, PARTS OF FEATHERS, DOWN AND ARTICLES THEREOF (OTHER THAN GOODS OF HEADING 0505 AND WORKED QUILLS AND SCAPES) SKINS AND OTHER PARTS OF BIRDS WITH THEIR FEATHERS OR DOWN, FEATHERS, PARTS OF FEATHERS, DOWN AND ARTICLES THEREOF (OTHER THAN GOODS OF HEADING 0505 AND WORKED QUILLS AND SCAPES): OTHER"
    },
    {
      "code": "6702",
      "description": "ARTIFICIAL FLOWERS, FOLIAGE AND FRUIT AND PARTS THEREOF; ARTICLES MADE OF ARTIFICIAL FLOWERS, FOLIAGE OR FRUIT"
    },
    {
      "code": "670210",
      "description": "Of plastics"
    },
    {
      "code": "67021010",
      "description": "ARTIFICIAL FLOWERS, FOLIAGE AND FRUIT AND PARTS THEREOF; ARTICLES MADE OF ARTIFICIAL FLOWERS, FOLIAGE OR FRUIT OF PLASTICS: DECORATIVE PLANTS"
    },
    {
      "code": "67021090",
      "description": "ARTIFICIAL FLOWERS, FOLIAGE AND FRUIT AND PARTS THEREOF; ARTICLES MADE OF ARTIFICIAL FLOWERS, FOLIAGE OR FRUIT OF PLASTICS: OTHER"
    },
    {
      "code": "670290",
      "description": "Of other materials"
    },
    {
      "code": "67029010",
      "description": "ARTIFICIAL FLOWERS, FOLIAGE AND FRUIT AND PARTS THEREOF; ARTICLES MADE OF ARTIFICIAL FLOWERS, FOLIAGE OR FRUIT OF OTHER MATERIALS: OF JUTE"
    },
    {
      "code": "67029090",
      "description": "ARTIFICIAL FLOWERS, FOLIAGE AND FRUIT AND PARTS THEREOF; ARTICLES MADE OF ARTIFICIAL FLOWERS, FOLIAGE OR FRUIT OF OTHER MATERIALS: OTHER"
    },
    {
      "code": "6703",
      "description": "HUMAN HAIR, DRESSED, THINNED, BLEACHED OR OTHERWISE WORKED; WOOL OR OTHER ANIMAL HAIR OR OTHER TEXTILE MATERIALS, PREPARED FOR USE IN MAKING WIGS OR THE LIKE"
    },
    {
      "code": "670300",
      "description": "Human hair, dressed, thinned, bleached or otherwise worked; wool or other animal hair or other textile materials, prepared for use in making wigs or the like"
    },
    {
      "code": "67030010",
      "description": "HUMAN HAIR, DRESSED, THINNED, BLEACHED OR OTHERWISE WORKED; WOOL OR OTHER ANIMAL HAIR OR OTHER TEXTILE ATERIALS, PREPARED FOR USE IN MAKING WIGS OR THE LIKE HUMAN HAIR, DRESSED, THINNED, BLEACHED OR OTHERWISE WORKED; WOOL OR OTHERANIMAL HAIROR OTHER TEXTILE MATERIALS, PREPARED FOR USE INMAKING WIGS OR THE LIKE: HUMAN HAIR, DRESSED, THINNED, BLEACHED OTHERWISE WORKED"
    },
    {
      "code": "67030020",
      "description": "HUMAN HAIR, DRESSED, THINNED, BLEACHED OR OTHERWISE WORKED; WOOL OR OTHER ANIMAL HAIR OR OTHER TEXTILE ATERIALS, PREPARED FOR USE IN MAKING WIGS OR THE LIKE HUMAN HAIR, DRESSED, THINNED, BLEACHED OR OTHERWISE WORKED; WOOL OR OTHERANIMAL HAIROR OTHER TEXTILE MATERIALS, PREPARED FOR USE INMAKING WIGS OR THE LIKE: WOOL OR OTHER ANIMAL HAIR OR OTHER TEXTILE MATERIALS, PREPARED FOR USE IN MAKING WIGS OR THE LIKE"
    },
    {
      "code": "6704",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED - Of synthetic textile materials"
    },
    {
      "code": "67041100",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES,SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED - OF SYNTHETIC TEXTILE MATERIALS: COMPLETE WIGS"
    },
    {
      "code": "670419",
      "description": "Other"
    },
    {
      "code": "67041910",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED - OF SYNTHETIC TEXTILE MATERIALS: OTHER: HAIR NETS"
    },
    {
      "code": "67041990",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED - OF SYNTHETIC TEXTILE MATERIALS: OTHER: OTHER"
    },
    {
      "code": "670420",
      "description": "Of human hair"
    },
    {
      "code": "67042010",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED OF HUMAN HAIR: WIGS"
    },
    {
      "code": "67042020",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED OF HUMAN HAIR: HAIR NETS"
    },
    {
      "code": "67042090",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED OF HUMAN HAIR: OTHER"
    },
    {
      "code": "67049000",
      "description": "WIGS, FALSE BEARDS, EYEBROWS AND EYELASHES, SWITCHES AND THE LIKE, OF HUMAN OR ANIMAL HAIR OR OF TEXTILE MATERIALS; ARTICLES OF HUMAN HAIR NOT ELSEWHERE SPECIFIED OR INCLUDED OF OTHER MATERIALS"
    },
    {
      "code": "68",
      "description": "Articles of stone, plaster, cement, asbestos, mica or similar materials"
    },
    {
      "code": "6801",
      "description": "SETTS, CURBSTONES AND FLAGSTONES, OF NATURAL STONE (EXCEPT SLATE) "
    },
    {
      "code": "68010000",
      "description": "SETTS, CURBSTONES AND FLAGSTONES, OF NATURAL STONE (EXCEPT SLATE)"
    },
    {
      "code": "6802",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE)"
    },
    {
      "code": "68021000",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE), THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM; ARTIFICIALLY COLOURED GRANULES,"
    },
    {
      "code": "680221",
      "description": "Marble, travertine and alabaster"
    },
    {
      "code": "68022110",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : MARBLE, TRAVERTINE AND ALABASTER: MARBLE BLOCKS OR TILES"
    },
    {
      "code": "68022120",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : MARBLE, TRAVERTINE AND ALABASTER: MARBLE MONUMENTAL STONE"
    },
    {
      "code": "68022190",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : MARBLE, TRAVERTINE AND ALABASTER: OTHER"
    },
    {
      "code": "68022200",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : OTHER CALCAREOUS STONE"
    },
    {
      "code": "680223",
      "description": "Granite"
    },
    {
      "code": "68022310",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : GRANITE: GRANITE BLOCKS OR TILES"
    },
    {
      "code": "68022390",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : GRANITE: OTHER"
    },
    {
      "code": "68022900",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER MONUMENTAL OR BUILDING STONE AND ARTICLES THEREOF, SIMPLY CUT OR SAWN, WITH A FLAT OR EVEN SURFACE : OTHER STONE"
    },
    {
      "code": "68029100",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER: MARBLE, TRAVERTINE AND ALABASTER"
    },
    {
      "code": "68029200",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER: OTHER CALCAREOUS STONE"
    },
    {
      "code": "68029300",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER: GRANITE"
    },
    {
      "code": "68029900",
      "description": "WORKED MONUMENTAL OR BUILDING STONE (EXCEPT SLATE) AND ARTICLES THEREOF, OTHER THAN GOODS OF HEADING 6801; MOSAIC CUBES AND THE LIKE, OF NATURAL STONE (INCLUDING SLATE), WHETHER OR NOT ON A BACKING; ARTIFICIALLY COLOURED GRANULES, CHIPPINGS AND POWDER, OF NATURAL STONE (INCLUDING SLATE) - OTHER: OTHER STONE"
    },
    {
      "code": "6803",
      "description": "WORKED SLATE AND ARTICLES OF SLATE OR OF AGGLOMERATED SLATE"
    },
    {
      "code": "68030000",
      "description": "WORKED SLATE AND ARTICLES OF SLATE OR OF AGGLOMERATED SLATE"
    },
    {
      "code": "6804",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING,HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS"
    },
    {
      "code": "68041000",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS MILLSTONES AND GRINDSTONES FOR MILLING, GRINDING OR PULPING"
    },
    {
      "code": "680421",
      "description": "Of agglomerated synthetic or natural diamond"
    },
    {
      "code": "68042110",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF AGGLOMERATED SYNTHETIC OR NATURAL DIAMOND: DIAMOND IMPREGNATED WHEELS"
    },
    {
      "code": "68042190",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF AGGLOMERATED SYNTHETIC OR NATURAL DIAMOND: OTHER"
    },
    {
      "code": "680422",
      "description": "Of other agglomerated abrasives or of ceramics"
    },
    {
      "code": "68042210",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS OF OTHER AGGLOMERATED ABRASIVES OR OF CERAMICS: GRINDING WHEELS OF SYNTHETIC ABRASIVES"
    },
    {
      "code": "68042220",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF OTHER AGGLOMERATED ABRASIVES OR OF CERAMICS: GRINDING WHEELS OF OTHER MATERIALS"
    },
    {
      "code": "68042290",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF OTHER AGGLOMERATED ABRASIVES OR OF CERAMICS: OTHER"
    },
    {
      "code": "680423",
      "description": "Of natural stone"
    },
    {
      "code": "68042310",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF NATURAL STONE: GRINDING WHEELS MADE OF NATURAL STONE"
    },
    {
      "code": "68042390",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS - OTHER MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE: OF NATURAL STONE: OTHER"
    },
    {
      "code": "680430",
      "description": "Hand sharpening or polishing stones"
    },
    {
      "code": "68043010",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS HAND SHARPENING OR POLISHING STONES: POLISHING STONES"
    },
    {
      "code": "68043020",
      "description": "MILLSTONES, GRINDSTONES, GRINDING WHEELS AND THE LIKE, WITHOUT FRAMEWORKS, FOR GRINDING, SHARPENING, POLISHING, TRUEING OR CUTTING, HAND SHARPENING OR POLISHING STONES, AND PARTS THEREOF, OF NATURAL STONE, OF AGGLOMERATED NATURAL OR ARTIFICIAL ABRASIVES, OR OF CERAMICS, WITH OR WITHOUT PARTS OF OTHER MATERIALS HAND SHARPENING OR POLISHING STONES: SHARPENING STONES"
    },
    {
      "code": "6805",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP"
    },
    {
      "code": "680510",
      "description": "On a base of woven textile fabric only"
    },
    {
      "code": "68051010",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF WOVEN TEXTILE FABRIC ONLY: ABRASIVE CLOTH"
    },
    {
      "code": "68051090",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF WOVEN TEXTILE FABRIC ONLY: OTHER"
    },
    {
      "code": "680520",
      "description": "On a base of paper or paperboard only"
    },
    {
      "code": "68052010",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF PAPER OR PAPERBOARD ONLY: EMERY OR CORUNDUM COATED PAPER"
    },
    {
      "code": "68052020",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF PAPER OR PAPERBOARD ONLY: FLINT COATED PAPER"
    },
    {
      "code": "68052030",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF PAPER OR PAPERBOARD ONLY: GLASS OR SAND COATED PAPER"
    },
    {
      "code": "68052040",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF PAPER OR PAPERBOARD ONLY: OTHER ABRASIVE PAPER"
    },
    {
      "code": "68052090",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF PAPER OR PAPERBOARD ONLY: OTHER"
    },
    {
      "code": "68053000",
      "description": "NATURAL OR ARTIFICIAL ABRASIVE POWDER OR GRAIN, ON A BASE OF TEXTILE MATERIAL, OF PAPER, OF PAPERBOARD OR OF OTHER MATERIALS, WHETHER OR NOT CUT TO SHAPE OR SEWN OR OTHERWISE MADE UP ON A BASE OF OTHER MATERIALS"
    },
    {
      "code": "6806",
      "description": "SLAG WOOL, ROCK WOOL AND SIMILAR MINERAL WOOLS; EXFOLIATED VERMICULITE, EXPANDED CLAYS, FOAMED SLAG AND SIMILAR EXPANDED MINERAL MATERIALS; MIXTURES AND ARTICLES OF HEAT-INSULATING, SOUND-INSULATING OR SOUND-ABSORBING MINERAL MATERIALS, OTHER THAN THOSE OF HEADING 6811 OR 6812 OR OF CHAPTER 69"
    },
    {
      "code": "68061000",
      "description": "SLAG WOOL, ROCK WOOL AND SIMILAR MINERAL WOOLS; EXFOLIATED VERMICULITE, EXPANDED CLAYS, FOAMED SLAG AND SIMILAR EXPANDED MINERAL MATERIALS; MIXTURES AND ARTICLES OF HEAT-INSULATING, SOUND-INSULATING OR SOUND-ABSORBING MINERAL MATERIALS, OTHER THAN THOSE OF HEADING 6811 OR 6812 OR OF CHAPTER 69 SLAG WOOL, ROCK WOOL AND SIMILAR MINERAL WOOLS (INCLUDING INTERMIXTURES THEREOF), IN BULK, SHEETS OR ROLLS"
    },
    {
      "code": "68062000",
      "description": "SLAG WOOL, ROCK WOOL AND SIMILAR MINERAL WOOLS; EXFOLIATED VERMICULITE, EXPANDED CLAYS, FOAMED SLAG AND SIMILAR EXPANDED MINERAL MATERIALS; MIXTURES AND ARTICLES OF HEAT-INSULATING, SOUND-INSULATING OR SOUND-ABSORBING MINERAL MATERIALS, OTHER THAN THOSE OF HEADING 6811 OR 6812 OR OF CHAPTER 69 EXFOLIATED VERMICULITE, EXPANDED CLAYS, FOAMED SLAG AND SIMILAR EXPANDED MINERAL MATERIALS (INCLUDING INTERMIXTURES THEREOF)"
    },
    {
      "code": "68069000",
      "description": "SLAG WOOL, ROCK WOOL AND SIMILAR MINERAL WOOLS; EXFOLIATED VERMICULITE, EXPANDED CLAYS, FOAMED SLAG AND SIMILAR EXPANDED MINERAL MATERIALS; MIXTURES AND ARTICLES OF HEAT-INSULATING, SOUND-INSULATING OR SOUND-ABSORBING MINERAL MATERIALS, OTHER THAN THOSE OF HEADING 6811 OR 6812 OR OF CHAPTER 69 OTHER"
    },
    {
      "code": "6807",
      "description": "ARTICLES OF ASPHALT OR OF SIMILAR MATERIAL (FOR EXAMPLE, PETROLEUM BITUMEN OR COAL TAR PITCH)"
    },
    {
      "code": "680710",
      "description": "In-rolls"
    },
    {
      "code": "68071010",
      "description": "ARTICLES OF ASPHALT OR OF SIMILAR MATERIAL (FOR EXAMPLE, PETROLEUM BITUMEN OR COAL TAR PITCH) IN-ROLLS: TARFELT ROOFING"
    },
    {
      "code": "68071090",
      "description": "ARTICLES OF ASPHALT OR OF SIMILAR MATERIAL (FOR EXAMPLE, PETROLEUM BITUMEN OR COAL TAR PITCH) IN-ROLLS: OTHER"
    },
    {
      "code": "680790",
      "description": "Other"
    },
    {
      "code": "68079010",
      "description": "ARTICLES OF ASPHALT OR OF SIMILAR MATERIAL (FOR EXAMPLE, PETROLEUM BITUMEN OR COAL TAR PITCH) OTHER: TARFELT ROOFING"
    },
    {
      "code": "68079090",
      "description": "ARTICLES OF ASPHALT OR OF SIMILAR MATERIAL (FOR EXAMPLE, PETROLEUM BITUMEN OR COAL TAR PITCH) OTHER: OTHER"
    },
    {
      "code": "6808",
      "description": "PANELS, BOARDS, TILES, BLOCKS AND SIMILAR ARTICLES OF VEGETABLE FIBRE, OF STRAW OR OF SHAVINGS, CHIPS, PARTICLES, SAWDUST OR OTHER WASTE, OF WOOD, AGGLOMERATED WITH CEMENT, PLASTER OR OTHER MINERAL BINDERS"
    },
    {
      "code": "68080000",
      "description": "PANELS, BOARDS, TILES, BLOCKS AND SIMILAR ARTICLES OF VEGETABLE FIBRE, OF STRAW OR OF SHAVINGS, CHIPS, PARTICLES, SAWDUST OR OTHER WASTE, OF WOOD, AGGLOMERATED WITH CEMENT, PLASTER OR OTHER MINERAL BINDERS"
    },
    {
      "code": "6809",
      "description": "ARTICLES OF PLASTER OR OF COMPOSITIONS BASED ON PLASTER - Boards, sheets, panels, tiles and similar articles, not ornamented"
    },
    {
      "code": "68091100",
      "description": "ARTICLES OF PLASTER OR OF COMPOSITIONS BASED ON PLASTER - BOARDS, SHEETS, PANELS, TILES AND SIMILAR ARTICLES, NOT ORNAMENTED : FACED OR REINFORCED WITH PAPER OR PAPERBOARD ONLY"
    },
    {
      "code": "68091900",
      "description": "ARTICLES OF PLASTER OR OF COMPOSITIONS BASED ON PLASTER - BOARDS, SHEETS, PANELS, TILES AND SIMILAR ARTICLES, NOT ORNAMENTED : OTHER"
    },
    {
      "code": "68099000",
      "description": "ARTICLES OF PLASTER OR OF COMPOSITIONS BASED ON PLASTER - BOARDS, SHEETS, PANELS, TILES AND SIMILAR ARTICLES, NOT ORNAMENTED : OTHER ARTICLES"
    },
    {
      "code": "6810",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED - Tiles, flagstones, bricks and similar articles"
    },
    {
      "code": "681011",
      "description": "Building blocks and bricks"
    },
    {
      "code": "68101110",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED - TILES, FLAGSTONES, BRICKS AND SIMILAR ARTICLES: BUILDING BLOCKS AND BRICKS: CEMENT BRICKS"
    },
    {
      "code": "68101190",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED - TILES, FLAGSTONES, BRICKS AND SIMILAR ARTICLES: BUILDING BLOCKS AND BRICKS: OTHER"
    },
    {
      "code": "681019",
      "description": "Other"
    },
    {
      "code": "68101910",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED - TILES, FLAGSTONES, BRICKS AND SIMILAR ARTICLES: OTHER: CEMENT TILES FOR MOSAIC"
    },
    {
      "code": "68101990",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED - TILES, FLAGSTONES, BRICKS AND SIMILAR ARTICLES: OTHER: OTHER"
    },
    {
      "code": "68109100",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED OTHER ARTICLES: PREFABRICATED STRUCTURAL COMPONENTS FOR BUILDING OR CIVIL ENGINEERING"
    },
    {
      "code": "681099",
      "description": "Other"
    },
    {
      "code": "68109910",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED OTHER ARTICLES: OTHER:CONCRETE BOULDER"
    },
    {
      "code": "68109990",
      "description": "ARTICLES OF CEMENT, OF CONCRETE OR OF ARTIFICIAL STONE, WHETHER OR NOT REINFORCED OTHER ARTICLES: OTHER: OTHER"
    },
    {
      "code": "6811",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRE-CEMENT OR THE LIKE"
    },
    {
      "code": "68111000",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE CORRUGATED SHEETS"
    },
    {
      "code": "68112010",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE OTHER SHEETS, PANELS, TILES AND SIMILAR ARTICLES: ASBESTOS-CEMENT SHEETS"
    },
    {
      "code": "68112020",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE OTHER SHEETS, PANELS, TILES AND SIMILAR ARTICLES: ASBESTOS-CEMENT TILES"
    },
    {
      "code": "68112090",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE OTHER SHEETS, PANELS, TILES AND SIMILAR ARTICLES: OTHER"
    },
    {
      "code": "68113010",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE TUBES, PIPES AND TUBE OR PIPE FITTINGS: ASBESTOS-CEMENT PIPES"
    },
    {
      "code": "68113090",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE TUBES, PIPES AND TUBE OR PIPE FITTINGS: OTHER"
    },
    {
      "code": "681140",
      "description": "Containing asbestos"
    },
    {
      "code": "68114010",
      "description": "ASBESTOS CEMENT SHEETS"
    },
    {
      "code": "68114020",
      "description": "ASBESTOS CEMENT TILES"
    },
    {
      "code": "68114090",
      "description": "OTHERS"
    },
    {
      "code": "68118100",
      "description": "CORRUGATED SHEETS NOT CONTAINING ASBESTOS"
    },
    {
      "code": "68118200",
      "description": "OTHER SHEETS, TILES ETC. NOT CONTAINING ASBESTOS"
    },
    {
      "code": "681189",
      "description": "other articles"
    },
    {
      "code": "68118910",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRE-CEMENT OR THE LIKE-NOT CONTAINING ASBESTOS--OTHER ARTICLES---TUBES,PIPES AND TUBE OR PIPE FITTINGS"
    },
    {
      "code": "68118990",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRE-CEMENT OR THE LIKE-NOT CONTAINING ASBESTOS--OTHER ARTICLES---OTHER"
    },
    {
      "code": "68119000",
      "description": "ARTICLES OF ASBESTOS-CEMENT, OF CELLULOSE FIBRECEMENT OR THE LIKE OTHER ARTICLES"
    },
    {
      "code": "6812",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813"
    },
    {
      "code": "68125000",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 CLOTHING, CLOTHING ACCESSORIES, FOOTWEAR AND HEADGEAR"
    },
    {
      "code": "68126011",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 PAPER, MILLBOARD AND FELT: MILLBOARD: ASBESTOS"
    },
    {
      "code": "68126019",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 PAPER, MILLBOARD AND FELT: MILLBOARD: OTHER"
    },
    {
      "code": "68126090",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 PAPER, MILLBOARD AND FELT: OTHER"
    },
    {
      "code": "68127000",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 COMPRESSED ASBESTOS FIBRE JOINTING, IN SHEETS OR ROLLS"
    },
    {
      "code": "68128000",
      "description": "GOODS, OF CROCIDOLITE"
    },
    {
      "code": "68129011",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 OTHER: LAGGING COMPOUNDS: ASBESTOS"
    },
    {
      "code": "68129019",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 OTHER: LAGGING COMPOUNDS: OTHER"
    },
    {
      "code": "68129021",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 OTHER: ASBESTOS PACKING JOINTS AND GASKETS: PACKING JOINTS"
    },
    {
      "code": "68129022",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 OTHER: ASBESTOS PACKING JOINTS AND GASKETS: GASKETS"
    },
    {
      "code": "68129090",
      "description": "FABRICATED ASBESTOS FIBRES; MIXTURES WITH A BASIS OF ASBESTOS OR WITH A BASIS OF ASBESTOS AND MAGNESIUM CARBONATE; ARTICLES OF SUCH MIXTURES OR OF ASBESTOS (FOR EXAMPLE, THREAD, WOVEN FABRIC, CLOTHING, HEADGEAR, FOOTWEAR, GASKETS), WHETHER OR NOT REINFORCED, OTHER THAN GOODS OF HEADING 6811 OR 6813 OTHER: ASBESTOS PACKING JOINTS AND GASKETS: OTHER"
    },
    {
      "code": "68129100",
      "description": "CLOTHING, CLOTHING ACCESSORIES, FOOTWEAR AND HEADWEAR"
    },
    {
      "code": "681292",
      "description": "Paper, millboard and felt"
    },
    {
      "code": "68129211",
      "description": "ASBESTOS MILLBOARD"
    },
    {
      "code": "68129219",
      "description": "PAPER, MILLBOARD AND FELT"
    },
    {
      "code": "68129290",
      "description": "PAPER, MILLBOARD AND FELT --- OTHERS"
    },
    {
      "code": "68129300",
      "description": "COMPRESSED ASBESTOS FIBRE JOINTING IN SHEETS OR ROLLS"
    },
    {
      "code": "681299",
      "description": "Other"
    },
    {
      "code": "68129911",
      "description": "ASBESTOS LAGGING COMPOUNDS"
    },
    {
      "code": "68129919",
      "description": "LAGGING COMPOUNDS - OTHERS"
    },
    {
      "code": "68129921",
      "description": "ASBESTOS PACKING JOINTS"
    },
    {
      "code": "68129922",
      "description": "ASBESTOS GASKETS"
    },
    {
      "code": "68129990",
      "description": "ASBESTOS PACKING JOINTS AND GASKETS - OTHERS"
    },
    {
      "code": "6813",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS"
    },
    {
      "code": "68131000",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS BRAKE LININGS AND PADS"
    },
    {
      "code": "681320",
      "description": "Containing asbestos"
    },
    {
      "code": "68132010",
      "description": "BRAKE LINING AND PADS CONTAINING ASBESTOS"
    },
    {
      "code": "68132090",
      "description": "ASBESTOS FRICTION MATERIALS"
    },
    {
      "code": "68138100",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS OTHER: -- NOT CONTAINING ASBESTOS -- BRAKE LINING AND PADS"
    },
    {
      "code": "68138900",
      "description": "OTHER MATERIALS NOT CONTAINING ASBESTOS"
    },
    {
      "code": "68139010",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS OTHER: ASBESTOS FRICTION MATERIALS"
    },
    {
      "code": "68139090",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS OTHER: OTHER"
    },
    {
      "code": "68139100",
      "description": "FRICTION MATERIAL AND ARTICLES THEREOF (FOR EXAMPLE, SHEETS, ROLLS, STRIPS, SEGMENTS, DISCS, WASHERS, PADS), NOT MOUNTED, FOR BRAKES, FOR CLUTCHES OR THE LIKE, WITH A BASIS OF ASBESTOS, OF OTHER MINERAL SUBSTANCES OR OF CELLULOSE, WHETHER OR NOT COMBINED WITH TEXTILE OR OTHER MATERIALS OTHER: -- NOT CONTAINING ASBESTOS -- OTHER"
    },
    {
      "code": "6814",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS"
    },
    {
      "code": "681410",
      "description": "Plates, sheets and strips of agglomerated or reconstituted mica, whether or not on a support"
    },
    {
      "code": "68141010",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS PLATES, SHEETS AND STRIPS OF AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT: CUT MICA CONDENSER FILMS OR PLATES"
    },
    {
      "code": "68141020",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS PLATES, SHEETS AND STRIPS OF AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT: SHEETS AND STRIPS CUT TO SHAPE"
    },
    {
      "code": "68141030",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS PLATES, SHEETS AND STRIPS OF AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT: WASHERS AND DISCS"
    },
    {
      "code": "68141090",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS PLATES, SHEETS AND STRIPS OF AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT: OTHER"
    },
    {
      "code": "681490",
      "description": "Other"
    },
    {
      "code": "68149010",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: MICA STACKED UNITS"
    },
    {
      "code": "68149020",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: SILVERED MICA, CAPACITOR PLATES OR SILVERED MICA PLATES"
    },
    {
      "code": "68149030",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: MICANITE AND ALL SORTS OF BUILT UP MICA"
    },
    {
      "code": "68149040",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: MICAPAPER OR RECONSTITUTED MICA PAPER"
    },
    {
      "code": "68149050",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: MOULDED GLASS BONDED OR GLASS BONDED MICA"
    },
    {
      "code": "68149060",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: MICA BRICKS"
    },
    {
      "code": "68149090",
      "description": "WORKED MICA AND ARTICLES OF MICA, INCLUDING AGGLOMERATED OR RECONSTITUTED MICA, WHETHER OR NOT ON A SUPPORT OF PAPER, PAPERBOARD OR OTHER MATERIALS OTHER: OTHER"
    },
    {
      "code": "6815",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "681510",
      "description": "Non-electrical articles of graphite or other carbon"
    },
    {
      "code": "68151010",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED NON-ELECTRICAL ARTICLES OF GRAPHITE OR OTHER CARBON: GRAPHITE FILTER CANDLE"
    },
    {
      "code": "68151020",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED NON-ELECTRICAL ARTICLES OF GRAPHITE OR OTHER CARBON: NON-ELECTRICAL ARTICLES OF GRAPHITE"
    },
    {
      "code": "68151090",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED NON-ELECTRICAL ARTICLES OF GRAPHITE OR OTHER CARBON: OTHER"
    },
    {
      "code": "68152000",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED NON-ELECTRICAL ARTICLES OF GRAPHITE OR OTHER CARBON: ARTICLES OF PEAT"
    },
    {
      "code": "68159100",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER ARTICLES: CONTAINING MAGNESITE, DOLOMITE OR CHROMITE"
    },
    {
      "code": "681599",
      "description": "Other"
    },
    {
      "code": "68159910",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER ARTICLES: OTHER: BRICKS AND TILES OF FLY ASH"
    },
    {
      "code": "68159920",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER ARTICLES: OTHER: SANITARY WARES, KITCHEN WARES AND OTHER MADE UP ARTICLES OF FLY ASH"
    },
    {
      "code": "68159990",
      "description": "ARTICLES OF STONE OR OF OTHER MINERAL SUBSTANCES (INCLUDING CARBON FIBRES, ARTICLES OF CARBON FIBRES AND ARTICLES OF PEAT), NOT ELSEWHERE SPECIFIED OR INCLUDED OTHER ARTICLES: OTHER: OTHER"
    },
    {
      "code": "69",
      "description": "Ceramic products"
    },
    {
      "code": "6901",
      "description": "BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS"
    },
    {
      "code": "690100",
      "description": "Bricks, blocks, tiles and other ceramic goods of siliceous fossil meals (for example, kieselguhr, tripolite or diatomite) or of similar siliceous earths"
    },
    {
      "code": "69010010",
      "description": "BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS - BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS:BRICKS"
    },
    {
      "code": "69010020",
      "description": "BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS - BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS:BLOCKS"
    },
    {
      "code": "69010030",
      "description": "BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS - BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS:TILES"
    },
    {
      "code": "69010090",
      "description": "BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS - BRICKS, BLOCKS, TILES AND OTHER CERAMIC GOODS OF SILICEOUS FOSSIL MEALS (FOR EXAMPLE, KIESELGUHR, TRIPOLITE OR DIATOMITE) OR OF SIMILAR SILICEOUS EARTHS:OTHER"
    },
    {
      "code": "6902",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS"
    },
    {
      "code": "690210",
      "description": "Containing by weight, singly or together, more than 50% of the elements Mg, Ca or Cr, expressed as MgO, CaO or Cr2O3"
    },
    {
      "code": "69021010",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: MAGNESITE BRICKS AND SHAPES"
    },
    {
      "code": "69021020",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: CHROME MAGNESITE BRICKS"
    },
    {
      "code": "69021030",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: MAGNESITE CHROME BRICKS AND SHAPES"
    },
    {
      "code": "69021040",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: MAGNESIA CARBON BRICKS AND SHAPES"
    },
    {
      "code": "69021050",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: DIRECT BONDED BASIC BRICKS AND SHAPES"
    },
    {
      "code": "69021090",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT, SINGLY OR TOGETHER, MORE THAN 50% OF THE ELEMENTS MG, CA OR CR, EXPRESSED AS MGO, CAO OR CR2O3: OTHER"
    },
    {
      "code": "690220",
      "description": "Containing by weight more than 50% of alumina (Al2O3), of silica (SiO2) or of a mixture or compound of these products"
    },
    {
      "code": "69022010",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: SILICA BRICKS AND SHAPES"
    },
    {
      "code": "69022020",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: HIGH ALUMINA BRICKS AND SHAPES"
    },
    {
      "code": "69022030",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: ALUMINA CARBON BRICKS AND SHAPES"
    },
    {
      "code": "69022040",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: SILICON CARBIDE BRICKS AND SHAPES"
    },
    {
      "code": "69022050",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: MULLITE BRICKS"
    },
    {
      "code": "69022090",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3), OF SILICA (SIO2) OR OF A MIXTURE OR COMPOUND OF THESE PRODUCTS: OTHER"
    },
    {
      "code": "690290",
      "description": "Other"
    },
    {
      "code": "69029010",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - OTHER: FIRE CLAY BRICKS AND SHAPES"
    },
    {
      "code": "69029020",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - OTHER: GRAPHITE BRICKS AND SHAPES"
    },
    {
      "code": "69029030",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - OTHER: VERMICULITE INSULATION BRICKS"
    },
    {
      "code": "69029040",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - OTHER: CLAY GRAPHITE STOPPER HEADS"
    },
    {
      "code": "69029090",
      "description": "REFRACTORY BRICKS, BLOCKS, TILES AND SIMILAR REFRACTORY CERAMIC CONSTRUCTIONAL GOODS, OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR SIMILAR SILICEOUS EARTHS - OTHER: OTHER"
    },
    {
      "code": "6903",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS"
    },
    {
      "code": "690310",
      "description": "Containing by weight more than 50% of graphite or other carbon or of a mixture of these products"
    },
    {
      "code": "69031010",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF GRAPHITE OR OTHER CARBON OR OF A MIXTURE OF THESE PRODUCTS : MAGNESIA CARBON BRICKS, SHAPES AND GRAPHETISED ALUMINA"
    },
    {
      "code": "69031090",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF GRAPHITE OR OTHER CARBON OR OF A MIXTURE OF THESE PRODUCTS :OTHER"
    },
    {
      "code": "690320",
      "description": "Containing by weight more than 50% of alumina (Al2O3) or of a mixture or compound of alumina and of silica (SiO2)"
    },
    {
      "code": "69032010",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3) OR OF A MIXTURE OR COMPOUND OF ALUMINA AND OF SILICA (SIO2): SILICON CARBIDE CRUCIBLES"
    },
    {
      "code": "69032090",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - CONTAINING BY WEIGHT MORE THAN 50% OF ALUMINA (AL2O3) OR OF A MIXTURE OR COMPOUND OF ALUMINA AND OF SILICA (SIO2): OTHER"
    },
    {
      "code": "690390",
      "description": "Other"
    },
    {
      "code": "69039010",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - OTHER: ZIRCON OR ZIRCON-MULLITE REFRACTORIES"
    },
    {
      "code": "69039020",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - OTHER: BASALT TILES"
    },
    {
      "code": "69039030",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - OTHER: CERAMIC FIBRES"
    },
    {
      "code": "69039040",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - OTHER: MONOLITHICS OR CASTABLES (FIRE-CLAY, BASIC, SILICA, HIGH ALUMINA, INSULATING)"
    },
    {
      "code": "69039090",
      "description": "OTHER REFRACTORY CERAMIC GOODS (FOR EXAMPLE, RETORTS, CRUCIBLES, MUFFLES, NOZZLES, PLUGS, SUPPORTS, CUPELS, TUBES, PIPES, SHEATHS AND RODS), OTHER THAN THOSE OF SILICEOUS FOSSIL MEALS OR OF SIMILAR SILICEOUS EARTHS - OTHER: OTHER"
    },
    {
      "code": "6904",
      "description": "CERAMIC BUILDING BRICKS, FLOORING BLOCKS, SUPPORT OR FILLER TILES AND THE LIKE"
    },
    {
      "code": "69041000",
      "description": "CERAMIC BUILDING BRICKS, FLOORING BLOCKS, SUPPORT OR FILLER TILES AND THE LIKE - BUILDING BRICKS"
    },
    {
      "code": "69049000",
      "description": "CERAMIC BUILDING BRICKS, FLOORING BLOCKS, SUPPORT OR FILLER TILES AND THE LIKE - OTHER"
    },
    {
      "code": "6905",
      "description": "ROOFING TILES, CHIMNEY-POTS, COWLS, CHIMNEY LINERS, ARCHITECTURAL ORNAMENTS AND OTHER CERAMIC CONSTRUCTIONAL GOODS"
    },
    {
      "code": "69051000",
      "description": "ROOFING TILES, CHIMNEY-POTS, COWLS, CHIMNEY LINERS, ARCHITECTURAL ORNAMENTS AND OTHER CERAMIC CONSTRUCTIONAL GOODS - ROOFING TILES"
    },
    {
      "code": "69059000",
      "description": "ROOFING TILES, CHIMNEY-POTS, COWLS, CHIMNEY LINERS, ARCHITECTURAL ORNAMENTS AND OTHER CERAMIC CONSTRUCTIONAL GOODS - OTHER"
    },
    {
      "code": "6906",
      "description": " CERAMIC PIPES, CONDUITS, GUTTERING AND PIPE FITTINGS"
    },
    {
      "code": "69060000",
      "description": "CERAMIC PIPES, CONDUITS, GUTTERING AND PIPE FITTINGS"
    },
    {
      "code": "6907",
      "description": "UNGLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; UNGLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING"
    },
    {
      "code": "690710",
      "description": "Tiles, cubes and similar articles, whether or not rectangular, the largest surface area of which is capable of being enclosed in a square the side of which is less than 7 cm"
    },
    {
      "code": "69071010",
      "description": "UNGLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; UNGLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR, THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM: VITRIFIED TILES, WHETHER POLISHED OR NOT"
    },
    {
      "code": "69071090",
      "description": "UNGLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; UNGLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR, THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM: OTHER"
    },
    {
      "code": "69072100",
      "description": "Of a water absorption coefficient by weight not exceeding 0.5%"
    },
    {
      "code": "69072200",
      "description": "Of a water absorption coefficient by weight exceeding 0.5% but not exceeding 10%"
    },
    {
      "code": "69072300",
      "description": "Of a water absorption coefficient by weight exceeding 10%"
    },
    {
      "code": "690730",
      "description": "Mosaic cubes and the like, other than those of sub-heading 6907 40"
    },
    {
      "code": "69073010",
      "description": "MOSAIC CUBES AND THE LIKE, OTHER THAN THOSE OF SUB HEADING 690740"
    },
    {
      "code": "690740",
      "description": "Finishing ceramics"
    },
    {
      "code": "69074010",
      "description": "Finishing ceramics"
    },
    {
      "code": "690790",
      "description": "Other"
    },
    {
      "code": "69079010",
      "description": "UNGLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; UNGLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - OTHER: VITRIFIED TILES, WHETHER POLISHED OR NOT"
    },
    {
      "code": "69079090",
      "description": "UNGLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; UNGLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - OTHER: OTHER"
    },
    {
      "code": "6908",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING"
    },
    {
      "code": "69081010",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR, THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM: CERAMIC MOSAIC CUBES"
    },
    {
      "code": "69081020",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR, THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM: CERAMIC MOSAIC TILES"
    },
    {
      "code": "69081090",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - TILES, CUBES AND SIMILAR ARTICLES, WHETHER OR NOT RECTANGULAR, THE LARGEST SURFACE AREA OF WHICH IS CAPABLE OF BEING ENCLOSED IN A SQUARE THE SIDE OF WHICH IS LESS THAN 7 CM: OTHER"
    },
    {
      "code": "69089010",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - OTHER: CERAMIC MOSAIC CUBES"
    },
    {
      "code": "69089020",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - OTHER: CERAMIC MOSAIC TILES"
    },
    {
      "code": "69089090",
      "description": "GLAZED CERAMIC FLAGS AND PAVING, HEARTH OR WALL TILES; GLAZED CERAMIC MOSAIC CUBES AND THE LIKE, WHETHER OR NOT ON A BACKING - OTHER: OTHER"
    },
    {
      "code": "6909",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS - Ceramic wares for laboratory, chemical or other technical uses"
    },
    {
      "code": "69091100",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS - CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES : OF PORCELAIN OR CHINA"
    },
    {
      "code": "69091200",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS - CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES : ARTICLES HAVING A HARDNESS EQUIVALENT TO 9 OR MORE ON THE MOHS SCALE"
    },
    {
      "code": "690919",
      "description": "Other"
    },
    {
      "code": "69091910",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS - CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES : OTHER: CERAMIC FILTER CANDLE"
    },
    {
      "code": "69091990",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS - CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES : OTHER: OTHER"
    },
    {
      "code": "69099000",
      "description": "CERAMIC WARES FOR LABORATORY, CHEMICAL OR OTHER TECHNICAL USES; CERAMIC TROUGHS, TUBS AND SIMILAR RECEPTACLES OF A KIND USED IN AGRICULTURE; CERAMIC POTS, JARS AND SIMILAR ARTICLES OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS OTHER"
    },
    {
      "code": "6910",
      "description": "CERAMIC SINKS, WASH BASINS, WASH BASIN PEDESTALS, BATHS, BIDETS, WATER CLOSET PANS, FLUSHING CISTERNS, URINALS AND SIMILAR SANITARY FIXTURES"
    },
    {
      "code": "69101000",
      "description": "CERAMIC SINKS, WASH BASINS, WASH BASIN PEDESTALS, BATHS, BIDETS, WATER CLOSET PANS, FLUSHING CISTERNS, URINALS AND SIMILAR SANITARY FIXTURES - OF PORCELAIN OR CHINA"
    },
    {
      "code": "69109000",
      "description": "CERAMIC SINKS, WASH BASINS, WASH BASIN PEDESTALS, BATHS, BIDETS, WATER CLOSET PANS, FLUSHING CISTERNS, URINALS AND SIMILAR SANITARY FIXTURES - OTHER"
    },
    {
      "code": "6911",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA"
    },
    {
      "code": "691110",
      "description": "Tableware and kitchenware"
    },
    {
      "code": "69111011",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA 6911 10 TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - TABLEWARE AND KITCHENWARE:TABLEWARE: OF BONE CHINA AND SOFT PORCELAIN"
    },
    {
      "code": "69111019",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA 6911 10 TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - TABLEWARE AND KITCHENWARE:TABLEWARE: OTHER"
    },
    {
      "code": "69111021",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA 6911 10 TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - TABLEWARE AND KITCHENWARE:KITCHENWARE: OF BONE CHINA AND SOFT PORCELAIN"
    },
    {
      "code": "69111029",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA 6911 10 TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - TABLEWARE AND KITCHENWARE:KITCHENWARE: OTHER"
    },
    {
      "code": "691190",
      "description": "Other"
    },
    {
      "code": "69119010",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - OTHER: TOILET ARTICLES"
    },
    {
      "code": "69119020",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - OTHER: WATER FILTERS OF A CAPACITY NOT EXCEEDING 40 LITRES"
    },
    {
      "code": "69119090",
      "description": "TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OF PORCELAIN OR CHINA - OTHER: OTHER"
    },
    {
      "code": "6912",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA"
    },
    {
      "code": "691200",
      "description": "Ceramic tableware, kitchenware, other household articles and toilet articles, other than of porcelain or china"
    },
    {
      "code": "69120010",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA - CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA: TABLEWARE"
    },
    {
      "code": "69120020",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA - CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA: KITCHENWARE"
    },
    {
      "code": "69120030",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA - CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA: TOILET ARTICLES"
    },
    {
      "code": "69120040",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA - CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA: CLAY ARTICLES"
    },
    {
      "code": "69120090",
      "description": "CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA - CERAMIC TABLEWARE, KITCHENWARE, OTHER HOUSEHOLD ARTICLES AND TOILET ARTICLES, OTHER THAN OF PORCELAIN OR CHINA: OTHER"
    },
    {
      "code": "6913",
      "description": "STATUETTES AND OTHER ORNAMENTAL CERAMIC ARTICLES"
    },
    {
      "code": "69131000",
      "description": "STATUETTES AND OTHER ORNAMENTAL CERAMIC ARTICLES - OF PORCELAIN OR CHINA"
    },
    {
      "code": "69139000",
      "description": "STATUETTES AND OTHER ORNAMENTAL CERAMIC ARTICLES - OTHER"
    },
    {
      "code": "6914",
      "description": "OTHER CERAMIC ARTICLE"
    },
    {
      "code": "69141000",
      "description": "OTHER CERAMIC ARTICLES - OF PORCELAIN OR CHINA"
    },
    {
      "code": "69149000",
      "description": "OTHER CERAMIC ARTICLES - OTHER"
    },
    {
      "code": "70",
      "description": "Glass and glassware"
    },
    {
      "code": "7001",
      "description": "CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS"
    },
    {
      "code": "700100",
      "description": "Cullet and other waste and scrap of glass; glass in the mass"
    },
    {
      "code": "70010010",
      "description": "CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS - CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS: CULLET AND OTHER WASTE AND SCRAP OF GLASS"
    },
    {
      "code": "70010020",
      "description": "CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS - CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS: ENAMEL GLASS IN THE MASS"
    },
    {
      "code": "70010090",
      "description": "CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS - CULLET AND OTHER WASTE AND SCRAP OF GLASS; GLASS IN THE MASS: OTHER"
    },
    {
      "code": "7002",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED"
    },
    {
      "code": "70021000",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - BALLS"
    },
    {
      "code": "700220",
      "description": "Rods"
    },
    {
      "code": "70022010",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - RODS: ENAMEL GLASS RODS"
    },
    {
      "code": "70022090",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - RODS: OTHER"
    },
    {
      "code": "70023100",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - TUBES: OF FUSED QUARTZ OR OTHER FUSED SILICA"
    },
    {
      "code": "70023200",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - TUBES: OF OTHER GLASS HAVING A LINEAR COEFFICIENT OF EXPANSION NOT EXCEEDING 5 X 10-6 PER KELVIN WITHIN A TEMPERATURE RANGE OF 00C TO 3000C"
    },
    {
      "code": "70023900",
      "description": "GLASS IN BALLS (OTHER THAN MICROSPHERES OF HEADING 7018), RODS OR TUBES, UNWORKED - TUBES: OTHER"
    },
    {
      "code": "7003",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - Non-wired sheets"
    },
    {
      "code": "700312",
      "description": "Coloured throughout the mass (body-tinted), opacified, flashed or having an absorbent, reflecting or non-reflecting layer"
    },
    {
      "code": "70031210",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED SHEETS : COLOURED THROUGHOUT THE MASS (BODY-TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: TINTED"
    },
    {
      "code": "70031290",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED SHEETS : COLOURED THROUGHOUT THE MASS (BODY-TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: OTHER"
    },
    {
      "code": "700319",
      "description": "Other"
    },
    {
      "code": "70031910",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED SHEETS : OTHER: TINTED"
    },
    {
      "code": "70031990",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED SHEETS : OTHER: OTHER"
    },
    {
      "code": "700320",
      "description": "Wired sheets"
    },
    {
      "code": "70032010",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - WIRED SHEETS: TINTED"
    },
    {
      "code": "70032090",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - WIRED SHEETS: OTHER"
    },
    {
      "code": "700330",
      "description": "Profiles"
    },
    {
      "code": "70033010",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - PROFILES: TINTED"
    },
    {
      "code": "70033090",
      "description": "CAST GLASS AND ROLLED GLASS, IN SHEETS OR PROFILES, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - PROFILES: OTHER"
    },
    {
      "code": "7004",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED"
    },
    {
      "code": "700420",
      "description": "Glass, coloured throughout the mass (body tinted), opacified, flashed or having an absorbent, reflecting or non-reflecting layer"
    },
    {
      "code": "70042011",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - GLASS, COLOURED THROUGHOUT THE MASS (BODY TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: WINDOW GLASS (SHEET GLASS): TINTED"
    },
    {
      "code": "70042019",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - GLASS, COLOURED THROUGHOUT THE MASS (BODY TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: WINDOW GLASS (SHEET GLASS): OTHER"
    },
    {
      "code": "70042091",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - GLASS, COLOURED THROUGHOUT THE MASS (BODY TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: WINDOW GLASS (SHEET GLASS): OTHER: TINTED"
    },
    {
      "code": "70042099",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - GLASS, COLOURED THROUGHOUT THE MASS (BODY TINTED), OPACIFIED, FLASHED OR HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: WINDOW GLASS (SHEET GLASS): OTHER: OTHER"
    },
    {
      "code": "700490",
      "description": "Other glass"
    },
    {
      "code": "70049011",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER GLASS: WINDOW GLASS (SHEET GLASS): TINTED"
    },
    {
      "code": "70049019",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER GLASS: WINDOW GLASS (SHEET GLASS): OTHER"
    },
    {
      "code": "70049091",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER GLASS: OTHER: TINTED"
    },
    {
      "code": "70049099",
      "description": "DRAWN GLASS AND BLOWN GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NONREFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER GLASS: OTHER: OTHER"
    },
    {
      "code": "7005",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED"
    },
    {
      "code": "700510",
      "description": "Non-wired glass, having an absorbent, reflecting or non-reflecting layer"
    },
    {
      "code": "70051010",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED GLASS, HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: TINTED"
    },
    {
      "code": "70051090",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - NON-WIRED GLASS, HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER: OTHER"
    },
    {
      "code": "700521",
      "description": "Coloured throughout the mass (body tinted),opacified, flashed or merely surface ground"
    },
    {
      "code": "70052110",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER NON-WIRED GLASS : COLOURED THROUGHOUT THE MASS (BODY TINTED) OPACIFIED, FLASHED OR MERELY SURFACE GROUND: TINTED"
    },
    {
      "code": "70052190",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER NON-WIRED GLASS : COLOURED THROUGHOUT THE MASS (BODY TINTED) OPACIFIED, FLASHED OR MERELY SURFACE GROUND: OTHER"
    },
    {
      "code": "700529",
      "description": "Other"
    },
    {
      "code": "70052910",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER NON-WIRED GLASS : OTHER: TINTED"
    },
    {
      "code": "70052990",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - OTHER NON-WIRED GLASS : OTHER: OTHER"
    },
    {
      "code": "700530",
      "description": "Wired glass"
    },
    {
      "code": "70053010",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - WIRED GLASS: TINTED"
    },
    {
      "code": "70053090",
      "description": "FLOAT GLASS AND SURFACE GROUND OR POLISHED GLASS, IN SHEETS, WHETHER OR NOT HAVING AN ABSORBENT, REFLECTING OR NON-REFLECTING LAYER, BUT NOT OTHERWISE WORKED - WIRED GLASS: OTHER"
    },
    {
      "code": "7006",
      "description": "OMITTED"
    },
    {
      "code": "70060000",
      "description": "GLASS OF HEADING 7003, 7004 OR 7005, BENT, EDGE-WORKED, ENGRAVED, DRILLED, ENAMELLED OR OTHERWISE WORKED, BUT NOT FRAMED OR FITTED WITH OTHER MATERIALS"
    },
    {
      "code": "7007",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - Toughened (tempered) safety glass"
    },
    {
      "code": "70071100",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - TOUGHENED (TEMPERED) SAFETY GLASS : OF SIZE AND SHAPE SUITABLE FOR INCORPORATION IN VEHICLES, AIRCRAFT, SPACECRAFT OR VESSELS"
    },
    {
      "code": "70071900",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - TOUGHENED (TEMPERED) SAFETY GLASS : OTHER"
    },
    {
      "code": "700721",
      "description": "Of size and shape suitable for incorporation in vehicles, aircraft, spacecraft or vessels"
    },
    {
      "code": "70072110",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - LAMINATED SAFETY GLASS : OF SIZE AND SHAPE SUITABLE FOR INCORPORATION IN VEHICLES, AIRCRAFT, SPACECRAFT OR VESSELS: BULLET PROOF GLASS"
    },
    {
      "code": "70072190",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - LAMINATED SAFETY GLASS : OF SIZE AND SHAPE SUITABLE FOR INCORPORATION IN VEHICLES, AIRCRAFT, SPACECRAFT OR VESSELS: OTHER"
    },
    {
      "code": "70072900",
      "description": "SAFETY GLASS, CONSISTING OF TOUGHENED (TEMPERED) OR LAMINATED GLASS - LAMINATED SAFETY GLASS : OTHER"
    },
    {
      "code": "7008",
      "description": "MULTIPLE-WALLED INSULATING UNITS OF GLASS"
    },
    {
      "code": "700800",
      "description": "Multiple-walled insulating units of glass"
    },
    {
      "code": "70080010",
      "description": "MULTIPLE-WALLED INSULATING UNITS OF GLASS - MULTIPLE-WALLED INSULATING UNITS OF GLASS: GLAZED GLASS, DOUBLE WALLED"
    },
    {
      "code": "70080020",
      "description": "MULTIPLE-WALLED INSULATING UNITS OF GLASS - MULTIPLE-WALLED INSULATING UNITS OF GLASS: GLAZED GLASS, MULTIPLE WALLED"
    },
    {
      "code": "70080090",
      "description": "MULTIPLE-WALLED INSULATING UNITS OF GLASS - MULTIPLE-WALLED INSULATING UNITS OF GLASS: OTHER"
    },
    {
      "code": "7009",
      "description": "GLASS MIRRORS, WHETHER OR NOT FRAMED, INCLUDING REAR-VIEW MIRRORS"
    },
    {
      "code": "700910",
      "description": "Rear-view mirrors for vehicles"
    },
    {
      "code": "70091010",
      "description": "GLASS MIRRORS, WHETHER OR NOT FRAMED, INCLUDING REAR-VIEW MIRRORS - REAR-VIEW MIRRORS FOR VEHICLES: PRISMATIC REAR-VIEW MIRROR FOR VEHICLES"
    },
    {
      "code": "70091090",
      "description": "GLASS MIRRORS, WHETHER OR NOT FRAMED, INCLUDING REAR-VIEW MIRRORS - REAR-VIEW MIRRORS FOR VEHICLES: OTHER"
    },
    {
      "code": "70099100",
      "description": "GLASS MIRRORS, WHETHER OR NOT FRAMED, INCLUDING REAR-VIEW MIRRORS - OTHER : UNFRAMED"
    },
    {
      "code": "70099200",
      "description": "GLASS MIRRORS, WHETHER OR NOT FRAMED, INCLUDING REAR-VIEW MIRRORS - OTHER : FRAMED"
    },
    {
      "code": "7010",
      "description": "CARBOYS, BOTTLES, FLASKS, JARS, POTS, PHIALS, AMPOULES AND OTHER CONTAINERS, OF GLASS, OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS; PRESERVING JARS OF GLASS; STOPPERS, LIDS AND OTHER CLOSURES, OF GLASS"
    },
    {
      "code": "70101000",
      "description": "CARBOYS, BOTTLES, FLASKS, JARS, POTS, PHIALS, AMPOULES AND OTHER CONTAINERS, OF GLASS, OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS; PRESERVING JARS OF GLASS; STOPPERS, LIDS AND OTHER CLOSURES, OF GLASS - AMPOULES"
    },
    {
      "code": "70102000",
      "description": "CARBOYS, BOTTLES, FLASKS, JARS, POTS, PHIALS, AMPOULES AND OTHER CONTAINERS, OF GLASS, OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS; PRESERVING JARS OF GLASS; STOPPERS, LIDS AND OTHER CLOSURES, OF GLASS - STOPPERS, LIDS AND OTHER CLOSURES"
    },
    {
      "code": "70109000",
      "description": "CARBOYS, BOTTLES, FLASKS, JARS, POTS, PHIALS, AMPOULES AND OTHER CONTAINERS, OF GLASS, OF A KIND USED FOR THE CONVEYANCE OR PACKING OF GOODS; PRESERVING JARS OF GLASS; STOPPERS, LIDS AND OTHER CLOSURES, OF GLASS - OTHER"
    },
    {
      "code": "7011",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE"
    },
    {
      "code": "701110",
      "description": "For electric lighting"
    },
    {
      "code": "70111010",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - FOR ELECTRIC LIGHTING: GLASS ENVELOPES FOR FLUORESCENT LAMPS"
    },
    {
      "code": "70111020",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - FOR ELECTRIC LIGHTING: GLASS ENVELOPES FOR FILAMENT LAMPS"
    },
    {
      "code": "70111090",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - FOR ELECTRIC LIGHTING: OTHER"
    },
    {
      "code": "70112000",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - FOR CATHODE-RAY TUBES"
    },
    {
      "code": "701190",
      "description": "Other"
    },
    {
      "code": "70119010",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - OTHER: GLASS ENVELOPES FOR ELECTRONIC VALVES"
    },
    {
      "code": "70119090",
      "description": "GLASS ENVELOPES (INCLUDING BULBS AND TUBES), OPEN, AND GLASS PARTS THEREOF, WITHOUT FITTINGS, FOR ELECTRIC LAMPS, CATHODE-RAY TUBES OR THE LIKE - OTHER: OTHER"
    },
    {
      "code": "7012",
      "description": "OMITTED"
    },
    {
      "code": "70120000",
      "description": "Omitted"
    },
    {
      "code": "7013",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018)"
    },
    {
      "code": "70131000",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) OF GLASS-CERAMICS"
    },
    {
      "code": "70132100",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - DRINKING GLASSES OTHER THAN OF GLASS-CERAMICS : OF LEAD CRYSTAL"
    },
    {
      "code": "70132200",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - STEMWARE DRINKING GLASSES, OTHER THAN OF GLASS-CERAMICS: -- OF LEAD CRYSTAL"
    },
    {
      "code": "70132800",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - STEMWARE DRINKING GLASSES, OTHER THAN OF GLASS-CERAMICS: -- OTHER"
    },
    {
      "code": "70132900",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - DRINKING GLASSES OTHER THAN OF GLASS-CERAMICS : OTHER"
    },
    {
      "code": "70133100",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSES OTHER THAN OF GLASS-CERAMICS : OF LEAD CRYSTAL"
    },
    {
      "code": "70133200",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSES OTHER THAN OF GLASS-CERAMICS : OF GLASS HAVING A LINEAR COEFFICIENT OF EXPANSION NOT EXCEEDING 5 X 10-6 PER KELVIN WITHIN A TEMPERATURE RANGE OF 00C TO 3000C"
    },
    {
      "code": "70133300",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER DRINKING GLASSES, OTHER THAN OF GLASS-CERAMICS: -- OF LEAD CRYSTAL"
    },
    {
      "code": "70133700",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER DRINKING GLASSES, OTHER THAN OF GLASS-CERAMICS: -- OTHER"
    },
    {
      "code": "70133900",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSES OTHER THAN OF GLASS-CERAMICS : OTHER"
    },
    {
      "code": "70134100",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSED, OTHER THAN OF GLASS-CERAMICS: -- OF LEAD CRYSTAL"
    },
    {
      "code": "70134200",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSED, OTHER THAN OF GLASS-CERAMICS: -- OF GLASS HAVING A LINEAR COEFICIENT OF EXPANSION NOT EXCEEDING 5 X 10 TO THE POWER OF MINUS 6 PER KELVIN WITHIN A TEMPERATURE RANGE OF 0 DEGREE C TO 300 DEGREEE C"
    },
    {
      "code": "70134900",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSED, OTHER THAN OF GLASS-CERAMICS: -- OTHER"
    },
    {
      "code": "70139100",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSED, OTHER THAN OF GLASS-CERAMICS: - OTHER -- OF LEAD CRYSTAL"
    },
    {
      "code": "70139110",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER GLASSWARE : OF LEAD CRYSTAL: GLASS STATUES"
    },
    {
      "code": "70139190",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER GLASSWARE : OF LEAD CRYSTAL: OTHER"
    },
    {
      "code": "70139900",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - GLASSWARE OF A KIND USED FOR TABLE (OTHER THAN DRINKING GLASSES) OR KITCHEN PURPOSED, OTHER THAN OF GLASS-CERAMICS: - OTHER -- OTHER"
    },
    {
      "code": "70139910",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER GLASSWARE : OTHER: GLASS STATUES"
    },
    {
      "code": "70139990",
      "description": "GLASSWARE OF A KIND USED FOR TABLE, KITCHEN, TOILET, OFFICE, INDOOR DECORATION OR SIMILAR PURPOSES (OTHER THAN THAT OF HEADING 7010 OR 7018) - OTHER GLASSWARE : OTHER: OTHER"
    },
    {
      "code": "7014",
      "description": "SIGNALLING GLASSWARE AND OPTICAL ELEMENTS OF GLASS (OTHER THAN THOSE OF HEADING 7012.5), NOT OPTICALLY WORKED"
    },
    {
      "code": "701400",
      "description": "Signalling glassware and optical elements of glass (other than those of heading 7012.5), not optically worked"
    },
    {
      "code": "70140010",
      "description": "SIGNALLING GLASSWARE AND OPTICAL ELEMENTS OF GLASS (OTHER THAN THOSE OF HEADING 7015), NOT OPTICALLY WORKED - SIGNALLING GLASSWARE AND OPTICAL ELEMENTS OF GLASS (OTHER THAN THOSE OF HEADING 7015), NOT OPTICALLY WORKED: SIGNALLING GLASSWARE"
    },
    {
      "code": "70140020",
      "description": "SIGNALLING GLASSWARE AND OPTICAL ELEMENTS OF GLASS (OTHER THAN THOSE OF HEADING 7015), NOT OPTICALLY WORKED - SIGNALLING GLASSWARE AND OPTICAL ELEMENTS OF GLASS (OTHER THAN THOSE OF HEADING 7015), NOT OPTICALLY WORKED: OPTICAL ELEMENTS"
    },
    {
      "code": "7015",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES"
    },
    {
      "code": "701510",
      "description": "Glasses for corrective spectacles"
    },
    {
      "code": "70151010",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - GLASSES FOR CORRECTIVE SPECTACLES: OPHTHALMIC ROUGH BLANKS"
    },
    {
      "code": "70151020",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - GLASSES FOR CORRECTIVE SPECTACLES: FLINT BUTTON"
    },
    {
      "code": "70151090",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - GLASSES FOR CORRECTIVE SPECTACLES: OTHER"
    },
    {
      "code": "701590",
      "description": "Other"
    },
    {
      "code": "70159010",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - OTHER: CLOCK AND WATCH GLASSES AND SIMILAR GLASSES, CURVED, BENT, HOLLOWED AND THE LIKE, GLASS SPHERES AND SEGMENTS OF SPHERES FOR THE MANUFACTURE OF SUCH GLASSES"
    },
    {
      "code": "70159020",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - OTHER: GLASS FOR SUN GLASSES"
    },
    {
      "code": "70159090",
      "description": "CLOCK OR WATCH GLASSES AND SIMILAR GLASSES, GLASSES FOR NON-CORRECTIVE OR CORRECTIVE SPECTACLES, CURVED, BENT, HOLLOWED OR THE LIKE; NOT OPTICALLY WORKED; HOLLOW GLASS SPHERES AND THEIR SEGMENTS, FOR THE MANUFACTURE OF SUCH GLASSES - OTHER: OTHER"
    },
    {
      "code": "7016",
      "description": "PAVING BLOCKS, SLABS, BRICKS, SQUARES, TILES AND OTHER ARTICLES OF PRESSED OR MOULDED GLASS, WHETHER OR NOT WIRED, OF A KIND USED FOR BUILDING OR CONSTRUCTION PURPOSES; GLASS CUBES AND OTHER GLASS SMALL WARES, WHETHER OR NOT ON A BACKING, FOR MOSAICS OR SIMILAR DECORATIVE PURPOSES; LEADED LIGHTS AND THE LIKE; MULTI-CELLULAR OR FOAM GLASS IN BLOCKS, PANELS, PLATES, SHELLS OR SIMILAR FORMS"
    },
    {
      "code": "70161000",
      "description": "PAVING BLOCKS, SLABS, BRICKS, SQUARES, TILES AND OTHER ARTICLES OF PRESSED OR MOULDED GLASS, WHETHER OR NOT WIRED, OF A KIND USED FOR BUILDING OR CONSTRUCTION PURPOSES; GLASS CUBES AND OTHER GLASS SMALL WARES, WHETHER OR NOT ON A BACKING, FOR MOSAICS OR SIMILAR DECORATIVE PURPOSES; LEADED LIGHTS AND THE LIKE; MULTI-CELLULAR OR FOAM GLASS IN BLOCKS, PANELS, PLATES, SHELLS OR SIMILAR FORMS - GLASS CUBES AND OTHER GLASS SMALLWARES, WHETHER OR NOT ON A BACKING, FOR MOSAICS OR SIMILAR DECORATIVE PURPOSES"
    },
    {
      "code": "70169000",
      "description": "PAVING BLOCKS, SLABS, BRICKS, SQUARES, TILES AND OTHER ARTICLES OF PRESSED OR MOULDED GLASS, WHETHER OR NOT WIRED, OF A KIND USED FOR BUILDING OR CONSTRUCTION PURPOSES; GLASS CUBES AND OTHER GLASS SMALL WARES, WHETHER OR NOT ON A BACKING, FOR MOSAICS OR SIMILAR DECORATIVE PURPOSES; LEADED LIGHTS AND THE LIKE; MULTI-CELLULAR OR FOAM GLASS IN BLOCKS, PANELS, PLATES, SHELLS OR SIMILAR FORMS - OTHER"
    },
    {
      "code": "7017",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED"
    },
    {
      "code": "70171000",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OF FUSED QUARTZ OR OTHER FUSED SILICA"
    },
    {
      "code": "70172000",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OF OTHER GLASS HAVING A LINEAR COEFFICIENT OF EXPANSION NOT EXCEEDING 5 X 10-6 PER KELVIN WITHIN A TEMPERATURE RANGE OF 00C TO 3000C"
    },
    {
      "code": "701790",
      "description": "Other"
    },
    {
      "code": "70179010",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OTHER: GRADUATED OR CALIBRATED LABORATORY GLASSWARE"
    },
    {
      "code": "70179020",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OTHER: PHARMACEUTICAL GLASSWARE"
    },
    {
      "code": "70179030",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OTHER: HYGIENIC GLASSWARE"
    },
    {
      "code": "70179090",
      "description": "LABORATORY, HYGIENIC OR PHARMACEUTICAL GLASSWARE, WHETHER OR NOT GRADUATED OR CALIBRATED - OTHER: OTHER"
    },
    {
      "code": "7018",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER"
    },
    {
      "code": "701810",
      "description": "Glass beads, imitation pearls, imitation precious or"
    },
    {
      "code": "70181010",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES: BANGLES"
    },
    {
      "code": "70181020",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES: BEADS"
    },
    {
      "code": "70181090",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES: OTHER"
    },
    {
      "code": "70182000",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER"
    },
    {
      "code": "701890",
      "description": "Other"
    },
    {
      "code": "70189010",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - OTHER: GLASS STATUES"
    },
    {
      "code": "70189090",
      "description": "GLASS BEADS, IMITATION PEARLS, IMITATION PRECIOUS OR SEMI-PRECIOUS STONES AND SIMILAR GLASS SMALLWARES, AND ARTICLES THEREOF OTHER THAN IMITATION JEWELLERY, GLASS EYES OTHER THAN PROSTHETIC ARTICLES; STATUETTES AND OTHER ORNAMENTS OF LAMP-WORKED GLASS, OTHER THAN IMITATION JEWELLERY; GLASS MICROSPHERES NOT EXCEEDING 1 MM IN DIAMETER - OTHER: OTHER"
    },
    {
      "code": "7019",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - Slivers, rovings, yarn and chopped strands"
    },
    {
      "code": "70191100",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - SLIVERS, ROVINGS, YARN AND CHOPPED STRANDS : CHOPPED STRANDS, OF A LENGTH OF NOT MORE THAN 50 MM"
    },
    {
      "code": "70191200",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - SLIVERS, ROVINGS, YARN AND CHOPPED STRANDS : ROVINGS"
    },
    {
      "code": "70191900",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - SLIVERS, ROVINGS, YARN AND CHOPPED STRANDS : OTHER"
    },
    {
      "code": "70193100",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - THIN SHEETS (VOILES), WEBS, MATS, MATTRESSES, BOARDS AND SIMILAR NON-WOVEN PRODUCTS : MATS"
    },
    {
      "code": "70193200",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - THIN SHEETS (VOILES), WEBS, MATS, MATTRESSES, BOARDS AND SIMILAR NON-WOVEN PRODUCTS : THIN SHEETS (VOILES)"
    },
    {
      "code": "70193900",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - THIN SHEETS (VOILES), WEBS, MATS, MATTRESSES, BOARDS AND SIMILAR NON-WOVEN PRODUCTS : OTHER"
    },
    {
      "code": "70194000",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - WOVEN FABRICS OF ROVINGS"
    },
    {
      "code": "70195100",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - OTHER WOVEN FABRICS : OF A WIDTH NOT EXCEEDING 30 CM"
    },
    {
      "code": "70195200",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - OTHER WOVEN FABRICS : OF A WIDTH EXCEEDING 30 CM, PLAIN WEAVE, WEIGHING LESS THAN 250 G/SQ. METRE, OF FILAMENTS MEASURING PER SINGLE YARN NOT MORE THAN 136 TEX"
    },
    {
      "code": "70195900",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - OTHER WOVEN FABRICS : OTHER"
    },
    {
      "code": "701990",
      "description": "Other"
    },
    {
      "code": "70199010",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - OTHER: GLASS WOOL OR GLASS FIBRE"
    },
    {
      "code": "70199090",
      "description": "GLASS FIBRES (INCLUDING GLASS WOOL) AND ARTICLES THEREOF (FOR EXAMPLE, YARN, WOVEN FABRICS) - OTHER: OTHER"
    },
    {
      "code": "7020",
      "description": "OTHER ARTICLES OF GLASS"
    },
    {
      "code": "702000",
      "description": "Other articles of glass"
    },
    {
      "code": "70200011",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: GLASS SHELLS, GLASS GLOBES AND GLASS FOUNTS: GLOBES FOR LAMPS AND LANTERNS"
    },
    {
      "code": "70200012",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: GLASS SHELLS, GLASS GLOBES AND GLASS FOUNTS: FOUNTS FOR KEROSENE WICK LAMPS"
    },
    {
      "code": "70200019",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: GLASS SHELLS, GLASS GLOBES AND GLASS FOUNTS: OTHER"
    },
    {
      "code": "70200021",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: GLASS CHIMNEYS: FOR LAMPS AND LANTERNS"
    },
    {
      "code": "70200029",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: GLASS CHIMNEYS: OTHER"
    },
    {
      "code": "70200090",
      "description": "OTHER ARTICLES OF GLASS OTHER ARTICLES OF GLASS: OTHER"
    },
    {
      "code": "7051",
      "description": "Lettuce"
    },
    {
      "code": "7052",
      "description": "Chicory"
    },
    {
      "code": "7095",
      "description": "Mushrooms and truffles"
    },
    {
      "code": "71",
      "description": "Natural or cultured pearls, precious or semi-precious stones, precious metals, metals clad with precious metal, and articles thereof; imitation jewellery; coin"
    },
    {
      "code": "7101",
      "description": "PEARLS, NATURAL OR CULTURED, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; PEARLS, NATURAL OR CULTURED, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT"
    },
    {
      "code": "710110",
      "description": "Natural pearls"
    },
    {
      "code": "71011010",
      "description": "PEARLS, NATURAL OR CULTURED, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; PEARLS, NATURAL OR CULTURED, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - NATURAL PEARLS: UNWORKED"
    },
    {
      "code": "71011020",
      "description": "PEARLS, NATURAL OR CULTURED, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; PEARLS, NATURAL OR CULTURED, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - NATURAL PEARLS: WORKED"
    },
    {
      "code": "71012100",
      "description": "PEARLS, NATURAL OR CULTURED, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; PEARLS, NATURAL OR CULTURED, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - CULTURED PEARLS: UNWORKED"
    },
    {
      "code": "71012200",
      "description": "PEARLS, NATURAL OR CULTURED, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; PEARLS, NATURAL OR CULTURED, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - CULTURED PEARLS: WORKED"
    },
    {
      "code": "7102",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET"
    },
    {
      "code": "71021000",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - UNSORTED"
    },
    {
      "code": "710221",
      "description": "Unworked or simply sawn, cleaved or bruted"
    },
    {
      "code": "71022110",
      "description": "21 DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - INDUSTRIAL : UNWORKED OR SIMPLY SAWN, CLEAVED OR BRUTED: SORTED"
    },
    {
      "code": "71022120",
      "description": "21 DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - INDUSTRIAL : UNWORKED OR SIMPLY SAWN, CLEAVED OR BRUTED: UNSORTED"
    },
    {
      "code": "710229",
      "description": "Other"
    },
    {
      "code": "71022910",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - INDUSTRIAL : OTHER: CRUSHED"
    },
    {
      "code": "71022990",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - INDUSTRIAL : OTHER: OTHER"
    },
    {
      "code": "71023100",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - NON-INDUSTRIAL: UNWORKED OR SIMPLY SAWN, CLEAVED OR BRUTED"
    },
    {
      "code": "710239",
      "description": "Others"
    },
    {
      "code": "71023910",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - NON-INDUSTRIAL: OTHERS: DIAMOND, CUT OR OTHERWISE WORKED BUT NOT MOUNTED OR SET"
    },
    {
      "code": "71023990",
      "description": "DIAMONDS, WHETHER OR NOT WORKED, BUT NOT MOUNTED OR SET - NON-INDUSTRIAL: OTHERS: OTHER"
    },
    {
      "code": "7103",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT"
    },
    {
      "code": "710310",
      "description": "Unworked or simply sawn or roughly shaped"
    },
    {
      "code": "71031011",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: PRECIOUS STONES: EMERALD"
    },
    {
      "code": "71031012",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: PRECIOUS STONES: RUBY AND SAPPHIRE"
    },
    {
      "code": "71031019",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: PRECIOUS STONES: OTHER"
    },
    {
      "code": "71031021",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: SEMI-PRECIOUS STONES: FELDSPAR (MOON STONE)"
    },
    {
      "code": "71031022",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: SEMI-PRECIOUS STONES: GARNET"
    },
    {
      "code": "71031023",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: SEMI-PRECIOUS STONES: AGATE"
    },
    {
      "code": "71031024",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: SEMI-PRECIOUS STONES: GREEN AVENTURINE"
    },
    {
      "code": "71031029",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED: SEMI-PRECIOUS STONES: OTHER"
    },
    {
      "code": "71031031",
      "description": "Emerald"
    },
    {
      "code": "71031032",
      "description": "Yellow/golden/pink/red/green beryl"
    },
    {
      "code": "71031033",
      "description": "Chrysoberyl (including chrysoberyl cats eye)"
    },
    {
      "code": "71031034",
      "description": "Alexandrite (including alexandrite cats eye)"
    },
    {
      "code": "71031039",
      "description": "Other"
    },
    {
      "code": "71031041",
      "description": "Ruby"
    },
    {
      "code": "71031042",
      "description": "Sapphire"
    },
    {
      "code": "71031043",
      "description": "Moonstone"
    },
    {
      "code": "71031049",
      "description": "Other"
    },
    {
      "code": "71031051",
      "description": "Garnet"
    },
    {
      "code": "71031052",
      "description": "Lapis-lazuli"
    },
    {
      "code": "71031059",
      "description": "Other"
    },
    {
      "code": "71031061",
      "description": "Prehnite"
    },
    {
      "code": "71031062",
      "description": "Agate"
    },
    {
      "code": "71031063",
      "description": "Aventurine"
    },
    {
      "code": "71031064",
      "description": "Chalcedony"
    },
    {
      "code": "71031069",
      "description": "Other"
    },
    {
      "code": "71031071",
      "description": "Tourmaline"
    },
    {
      "code": "71031072",
      "description": "Tanzanite"
    },
    {
      "code": "71031079",
      "description": "Other"
    },
    {
      "code": "71031090",
      "description": "Other"
    },
    {
      "code": "710391",
      "description": "Ruby, sapphire and emeralds"
    },
    {
      "code": "71039100",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: RUBY, SAPPHIRE AND EMERALDS"
    },
    {
      "code": "71039110",
      "description": "Ruby"
    },
    {
      "code": "71039120",
      "description": "Sapphire"
    },
    {
      "code": "71039130",
      "description": "Emeralds"
    },
    {
      "code": "710399",
      "description": "Other"
    },
    {
      "code": "71039910",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: OTHER: FELDSPAR (MOON STONE)"
    },
    {
      "code": "71039911",
      "description": "Yellow/golden/pink/red/green beryl"
    },
    {
      "code": "71039912",
      "description": "Chrysoberyl (including chrysoberyl cats eye)"
    },
    {
      "code": "71039913",
      "description": "Alexandrite (including alexandrite cats eye)"
    },
    {
      "code": "71039919",
      "description": "Other"
    },
    {
      "code": "71039920",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: OTHER: GARNET"
    },
    {
      "code": "71039921",
      "description": "Moonstone"
    },
    {
      "code": "71039929",
      "description": "Other"
    },
    {
      "code": "71039930",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: OTHER: AGATE"
    },
    {
      "code": "71039931",
      "description": "Garnet"
    },
    {
      "code": "71039932",
      "description": "Lapis-lazuli"
    },
    {
      "code": "71039939",
      "description": "Other"
    },
    {
      "code": "71039940",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: OTHER: CHALCEDONY"
    },
    {
      "code": "71039941",
      "description": "Prehnite"
    },
    {
      "code": "71039942",
      "description": "Agate"
    },
    {
      "code": "71039943",
      "description": "Aventurine"
    },
    {
      "code": "71039944",
      "description": "Chalcedony"
    },
    {
      "code": "71039949",
      "description": "Other"
    },
    {
      "code": "71039951",
      "description": "Tourmaline"
    },
    {
      "code": "71039952",
      "description": "Tanzanite"
    },
    {
      "code": "71039959",
      "description": "Other"
    },
    {
      "code": "71039990",
      "description": "PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED PRECIOUS STONES (OTHER THAN DIAMONDS) AND SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHERWISE WORKED: OTHER: OTHER"
    },
    {
      "code": "7104",
      "description": "SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT"
    },
    {
      "code": "71041000",
      "description": "SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - PIEZO-ELECTRIC QUARTZ"
    },
    {
      "code": "710420",
      "description": "Other, unworked or simply sawn or roughly shaped"
    },
    {
      "code": "71042000",
      "description": "SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMIPRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG, MOUNTED OR SET; UNGRADED SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT - OTHER, UNWORKED OR SIMPLY SAWN OR ROUGHLY SHAPED"
    },
    {
      "code": "71042010",
      "description": "Laboratory-created or laboratory grown or manmade or cultured or synthetic diamonds"
    },
    {
      "code": "71042090",
      "description": "Other"
    },
    {
      "code": "71049010",
      "description": "SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG,MOUNTED OR SET; UNGRADED SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT-OTHER--LABORATORY CREATED OR LABORATORY GROWN OR MANMADE OR CULTURED OR SYNTHETIC DIAMONDS"
    },
    {
      "code": "71049090",
      "description": "SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, WHETHER OR NOT WORKED OR GRADED BUT NOT STRUNG,MOUNTED OR SET; UNGRADED SYNTHETIC OR RECONSTRUCTED PRECIOUS OR SEMI-PRECIOUS STONES, TEMPORARILY STRUNG FOR CONVENIENCE OF TRANSPORT-OTHER--LABORATORY CREATED OR LABORATORY GROWN OR MANMADE OR CULTURED OR SYNTHETIC DIAMONDS--OTHER"
    },
    {
      "code": "7105",
      "description": "DUST AND POWDER OF NATURAL OR SYNTHETIC PRECIOUS OR SEMI-PRECIOUS STONES"
    },
    {
      "code": "71051000",
      "description": "DUST AND POWDER OF NATURAL OR SYNTHETIC PRECIOUS OR SEMI-PRECIOUS STONES - OF DIAMOND"
    },
    {
      "code": "71059000",
      "description": "DUST AND POWDER OF NATURAL OR SYNTHETIC PRECIOUS OR SEMI-PRECIOUS STONES - OTHER"
    },
    {
      "code": "7106",
      "description": "SILVER (INCLUDING SILVER PLATED WITH GOLD OR PLATINUM), UNWROUGHT OR IN SEMIMANUFACTURED FORMS, OR IN POWDER FORM"
    },
    {
      "code": "71061000",
      "description": "SILVER (INCLUDING SILVER PLATED WITH GOLD OR PLATINUM), UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - POWDER"
    },
    {
      "code": "710691",
      "description": "Unwrought"
    },
    {
      "code": "71069100",
      "description": "SILVER (INCLUDING SILVER PLATED WITH GOLD OR PLATINUM), UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - OTHER : UNWROUGHT"
    },
    {
      "code": "71069110",
      "description": "Grains"
    },
    {
      "code": "71069190",
      "description": "Other"
    },
    {
      "code": "710692",
      "description": "Semi-manufactured"
    },
    {
      "code": "71069210",
      "description": "SILVER (INCLUDING SILVER PLATED WITH GOLD OR PLATINUM), UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM SEMI-MANUFACTURED : SHEETS, PLATES, STRIPS, TUBES AND PIPES"
    },
    {
      "code": "71069220",
      "description": "Bar"
    },
    {
      "code": "71069290",
      "description": "SILVER (INCLUDING SILVER PLATED WITH GOLD OR PLATINUM), UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM SEMI-MANUFACTURED : OTHER"
    },
    {
      "code": "7107",
      "description": "BASE METALS CLAD WITH SILVER, NOT FURTHER WORKED THAN SEMI-MANUFACTURED"
    },
    {
      "code": "71070000",
      "description": "BASE METALS CLAD WITH SILVER, NOT FURTHER WORKED THAN SEMI-MANUFACTURED"
    },
    {
      "code": "7108",
      "description": "GOLD (INCLUDING GOLD PLATED WITH PLATINUM) UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - Non-monetary"
    },
    {
      "code": "71081100",
      "description": "GOLD (INCLUDING GOLD PLATED WITH PLATINUM) UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - NON-MONETARY : POWDER"
    },
    {
      "code": "71081200",
      "description": "GOLD (INCLUDING GOLD PLATED WITH PLATINUM) UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - NON-MONETARY : OTHER UNWROUGHT FORMS"
    },
    {
      "code": "71081300",
      "description": "GOLD (INCLUDING GOLD PLATED WITH PLATINUM) UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM - NON-MONETARY : OTHER SEMI-MANUFACTURED FORMS"
    },
    {
      "code": "71082000",
      "description": "GOLD (INCLUDING GOLD PLATED WITH PLATINUM) UNWROUGHT OR IN SEMI-MANUFACTURED FORMS, OR IN POWDER FORM MONETARY"
    },
    {
      "code": "7109",
      "description": "BASE METALS OR SILVER, CLAD WITH GOLD, NOT FURTHER WORKED THAN SEMI-MANUFACTURED"
    },
    {
      "code": "71090000",
      "description": "BASE METALS OR SILVER, CLAD WITH GOLD, NOT FURTHER WORKED THAN SEMI-MANUFACTURED"
    },
    {
      "code": "7110",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - Platinum"
    },
    {
      "code": "711011",
      "description": "Unwrought or in powder form"
    },
    {
      "code": "71101110",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - PLATINUM : UNWROUGHT OR IN POWDER FORM: UNWROUGHT FORM"
    },
    {
      "code": "71101120",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - PLATINUM : UNWROUGHT OR IN POWDER FORM: IN POWDER FORM"
    },
    {
      "code": "71101900",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - PLATINUM : OTHER"
    },
    {
      "code": "71102100",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - PALLADIUM: UNWROUGHT OR IN POWDER FORM"
    },
    {
      "code": "71102900",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - PALLADIUM: OTHER"
    },
    {
      "code": "71103100",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - RHODIUM: UNWROUGHT OR IN POWDER FROM"
    },
    {
      "code": "71103900",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - RHODIUM: OTHER"
    },
    {
      "code": "71104100",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - IRIDIUM, OSMIUM AND RUTHENIUM: UNWROUGHT OR IN POWDER FROM"
    },
    {
      "code": "71104900",
      "description": "PLATINUM, UNWROUGHT OR IN SEMI-MANUFACTURED FORM, OR IN POWDER FORM - IRIDIUM, OSMIUM AND RUTHENIUM: OTHER"
    },
    {
      "code": "7111",
      "description": "BASE METALS, SILVER OR GOLD, CLAD WITH PLATINUM, NOT FURTHER WORKED THAN SEMIMANUFACTURED"
    },
    {
      "code": "71110000",
      "description": "BASE METALS, SILVER OR GOLD, CLAD WITH PLATINUM, NOT FURTHER WORKED THAN SEMIMANUFACTURED"
    },
    {
      "code": "7112",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL"
    },
    {
      "code": "71123000",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - ASH CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS"
    },
    {
      "code": "71129100",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - OTHER : OF GOLD, INCLUDING METAL CLAD WITH GOLD BUT EXCLUDING SWEEPINGS CONTAINING OTHER PRECIOUS METALS"
    },
    {
      "code": "71129200",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - OTHER : OF PLATINUM, INCLUDING METAL CLAD WITH PLATINUM BUT EXCLUDING SWEEPINGS CONTAINING OTHER PRECIOUS METALS"
    },
    {
      "code": "711299",
      "description": "Other"
    },
    {
      "code": "71129910",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - OTHER : OTHER: OF SILVER, INCLUDING METAL CLAD WITH SILVER BUT EXCLUDING SWEEPINGS CONTAINING OTHER PRECIOUS METALS"
    },
    {
      "code": "71129920",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - OTHER : OTHER: SWEEPINGS CONTAINING GOLD OR SILVER"
    },
    {
      "code": "71129990",
      "description": "WASTE AND SCRAP OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL; OTHER WASTE AND SCRAP CONTAINING PRECIOUS METAL OR PRECIOUS METAL COMPOUNDS, OF A KIND USED PRINCIPALLY FOR THE RECOVERY OF PRECIOUS METAL - OTHER : OTHER: OTHER"
    },
    {
      "code": "7113",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - Of precious metal whether or not plated or clad with precious metal:"
    },
    {
      "code": "711311",
      "description": "Of silver, whether or not plated or clad with other precious metal"
    },
    {
      "code": "71131110",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH OTHER PRECIOUS METAL: JEWELLERY WITH FILIGREE WORK"
    },
    {
      "code": "71131120",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH OTHER PRECIOUS METAL: JEWELLERY STUDDED WITH GEMS"
    },
    {
      "code": "71131130",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH OTHER PRECIOUS METAL: OTHER ARTICLES OF JEWELLERY"
    },
    {
      "code": "71131190",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH OTHER PRECIOUS METAL: PARTS"
    },
    {
      "code": "711319",
      "description": "Of other precious metal, whether or not plated"
    },
    {
      "code": "71131910",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF GOLD, UNSTUDDED"
    },
    {
      "code": "71131920",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF GOLD, SET WITH PEARLS"
    },
    {
      "code": "71131930",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF GOLD, SET WITH DIAMONDS"
    },
    {
      "code": "71131940",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF GOLD, SET WITH OTHER PRECIOUS AND SEMI- PRECIOUS STONES"
    },
    {
      "code": "71131950",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF PLATINUM, UNSTUDDED"
    },
    {
      "code": "71131960",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: PARTS"
    },
    {
      "code": "71131990",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL - OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OTHER"
    },
    {
      "code": "71132000",
      "description": "ARTICLES OF JEWELLERY AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF BASE METAL CLAD WITH PRECIOUS METAL"
    },
    {
      "code": "7114",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - Of precious metal, whether or not plated or clad with precious metal"
    },
    {
      "code": "711411",
      "description": "Of silver, whether or not plated or clad with precious metal"
    },
    {
      "code": "71141110",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL:ARTICLES"
    },
    {
      "code": "71141120",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF SILVER, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL:PARTS"
    },
    {
      "code": "711419",
      "description": "Of other precious metal, whether or not plated or clad with precious metal"
    },
    {
      "code": "71141910",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: ARTICLES OF GOLD"
    },
    {
      "code": "71141920",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: ARTICLES OF PLATINUM"
    },
    {
      "code": "71141930",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: OF OTHER PRECIOUS METAL, WHETHER OR NOT PLATED OR CLAD WITH PRECIOUS METAL: PARTS"
    },
    {
      "code": "711420",
      "description": "Of base metal clad with precious metal"
    },
    {
      "code": "71142010",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF BASE METAL CLAD WITH PRECIOUS METAL : ARTICLES CLAD WITH GOLD"
    },
    {
      "code": "71142020",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF BASE METAL CLAD WITH PRECIOUS METAL : OTHER ARTICLES"
    },
    {
      "code": "71142030",
      "description": "ARTICLES OF GOLDSMITHS OR SILVERSMITHS WARES AND PARTS THEREOF, OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OF BASE METAL CLAD WITH PRECIOUS METAL : PARTS"
    },
    {
      "code": "7115",
      "description": "OTHER ARTICLES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL"
    },
    {
      "code": "71151000",
      "description": "OTHER ARTICLES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - CATALYSTS IN THE FROM OF WIRE CLOTH OR GRILL, OF PLATINUM"
    },
    {
      "code": "711590",
      "description": "Other"
    },
    {
      "code": "71159010",
      "description": "OTHER ARTICLES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER: LABORATORY AND INDUSTRIAL ARTICLES OF PRECIOUS METAL"
    },
    {
      "code": "71159020",
      "description": "OTHER ARTICLES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER: SPINNERETS MADE MAINLY OF GOLD"
    },
    {
      "code": "71159090",
      "description": "OTHER ARTICLES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER: OTHER"
    },
    {
      "code": "7116",
      "description": "ARTICLES OF NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES(NATURAL, SYNTHETIC OR RECONSTRUCTED)"
    },
    {
      "code": "71161000",
      "description": "ARTICLES OF NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES(NATURAL, SYNTHETIC OR RECONSTRUCTED) - OF NATURAL OR CULTURED PEARLS"
    },
    {
      "code": "71162000",
      "description": "ARTICLES OF NATURAL OR CULTURED PEARLS, PRECIOUS OR SEMI-PRECIOUS STONES(NATURAL, SYNTHETIC OR RECONSTRUCTED) - OF PRECIOUS OR SEMI-PRECIOUS STONES (NATURAL, SYNTHETIC OR RECONSTRUCTED)"
    },
    {
      "code": "7117",
      "description": "IMITATION JEWELLERY"
    },
    {
      "code": "71171100",
      "description": "IMITATION JEWELLERY - OF BASE METAL, WHETHER OR NOT PLATED WITH PRECIOUS METAL : CUFF-LINKS AND STUDS"
    },
    {
      "code": "711719",
      "description": "Other"
    },
    {
      "code": "71171910",
      "description": "IMITATION JEWELLERY - OF BASE METAL, WHETHER OR NOT PLATED WITH PRECIOUS METAL : OTHER: BANGLES"
    },
    {
      "code": "71171920",
      "description": "IMITATION JEWELLERY - OF BASE METAL, WHETHER OR NOT PLATED WITH PRECIOUS METAL : OTHER: GERMAN SILVER JEWELLERY"
    },
    {
      "code": "71171990",
      "description": "IMITATION JEWELLERY - OF BASE METAL, WHETHER OR NOT PLATED WITH PRECIOUS METAL : OTHER: OTHER"
    },
    {
      "code": "711790",
      "description": "Other"
    },
    {
      "code": "71179010",
      "description": "IMITATION JEWELLERY - OTHER: JEWELLERY STUDDED WITH IMITATION PEARLS OR IMITATION OR SYNTHETIC STONES"
    },
    {
      "code": "71179090",
      "description": "IMITATION JEWELLERY - OTHER: OTHER"
    },
    {
      "code": "7118",
      "description": "COIN"
    },
    {
      "code": "71181000",
      "description": "COIN - COIN (OTHER THAN GOLD COIN), NOT BEING LEGAL TENDER"
    },
    {
      "code": "71189000",
      "description": "COIN - OTHER"
    },
    {
      "code": "7123",
      "description": "Mushrooms, wood ears (Auricularia spp.), jelly fungi (Tremella spp.) and truffles"
    },
    {
      "code": "7133",
      "description": "Beans (Vigna spp., Phaseolus spp.)"
    },
    {
      "code": "713909",
      "description": "Other"
    },
    {
      "code": "72",
      "description": "Iron and steel"
    },
    {
      "code": "7201",
      "description": "PIG IRON AND SPIEGELEISEN IN PIGS, BLOCKS OR OTHER PRIMARY FORMS"
    },
    {
      "code": "72011000",
      "description": "PIG IRON AND SPIEGELEISEN IN PIGS, BLOCKS OR OTHER PRIMARY FORMS - NON-ALLOY PIG IRON CONTAINING BY WEIGHT 0.5% OR LESS OF PHOSPHORUS"
    },
    {
      "code": "72012000",
      "description": "PIG IRON AND SPIEGELEISEN IN PIGS, BLOCKS OR OTHER PRIMARY FORMS - NON-ALLOY PIG IRON CONTAINING BY WEIGHT MORE THAN 0.5% OF PHOSPHORUS"
    },
    {
      "code": "720150",
      "description": "Alloy pig iron; spiegeleisen"
    },
    {
      "code": "72015010",
      "description": "PIG IRON AND SPIEGELEISEN IN PIGS, BLOCKS OR OTHER PRIMARY FORMS - ALLOY PIG IRON; SPIEGELEISEN : CAST IRON"
    },
    {
      "code": "72015090",
      "description": "PIG IRON AND SPIEGELEISEN IN PIGS, BLOCKS OR OTHER PRIMARY FORMS - ALLOY PIG IRON; SPIEGELEISEN : OTHER"
    },
    {
      "code": "7202",
      "description": "FERRO-ALLOYS"
    },
    {
      "code": "72021100",
      "description": "FERRO-ALLOYS - FERRO-MANGANESE : CONTAINING BY WEIGHT MORE THAN 2% OF CARBON"
    },
    {
      "code": "72021900",
      "description": "FERRO-ALLOYS - FERRO-MANGANESE : OTHER"
    },
    {
      "code": "72022100",
      "description": "FERRO-ALLOYS - FERRO-SILICON: CONTAINING BY WEIGHT MORE THAN 55% OF SILICON"
    },
    {
      "code": "72022900",
      "description": "FERRO-ALLOYS - FERRO-SILICON: OTHER"
    },
    {
      "code": "72023000",
      "description": "FERRO-ALLOYS - FERRO-SILICO-MANGANESE"
    },
    {
      "code": "72024100",
      "description": "FERRO-ALLOYS - FERRO-CHROMIUM : CONTAINING BY WEIGHT MORE THAN 4% OF CARBON"
    },
    {
      "code": "72024900",
      "description": "FERRO-ALLOYS - FERRO-CHROMIUM : OTHER"
    },
    {
      "code": "72025000",
      "description": "FERRO-ALLOYS - FERRO-SILICO-CHROMIUM"
    },
    {
      "code": "72026000",
      "description": "FERRO-ALLOYS - FERRO-NICKEL"
    },
    {
      "code": "72027000",
      "description": "FERRO-ALLOYS - FERRO-MOLYBDENUM"
    },
    {
      "code": "72028000",
      "description": "FERRO-ALLOYS - FERRO TUNGSTEN AND FERRO-SILICO-TUNGSTEN"
    },
    {
      "code": "72029100",
      "description": "FERRO-ALLOYS - OTHER : FERRO-TITANIUM AND FERRO-SILICO-TITANIUM"
    },
    {
      "code": "72029200",
      "description": "FERRO-ALLOYS - OTHER : FERRO-VANADIUM"
    },
    {
      "code": "72029300",
      "description": "FERRO-ALLOYS - OTHER : FERRO-NIOBIUM"
    },
    {
      "code": "720299",
      "description": "Other"
    },
    {
      "code": "72029911",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-PHOSPHORUS"
    },
    {
      "code": "72029912",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-SELENIUM"
    },
    {
      "code": "72029913",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-COBALT"
    },
    {
      "code": "72029914",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-COLUMBIUM"
    },
    {
      "code": "72029915",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-ZIRCONIUM"
    },
    {
      "code": "72029916",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-PHOSPHORUS, FERRO-SELENIUM, FERROCOBALT, FERRO-COLUMBIUM, FERRO-ZIRCONIUM, FERRO-TANTALUM: FERRO-TANTALUM"
    },
    {
      "code": "72029921",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-SILICO-ZIRCONIUM, FERRO-SILICO-MAGNESIUM: FERRO-SILICO-ZIRCONIUM"
    },
    {
      "code": "72029922",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-SILICO-ZIRCONIUM, FERRO-SILICO-MAGNESIUM: FERRO-SILICO-MAGNESIUM"
    },
    {
      "code": "72029931",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-BORON, CHARGE-CHROME : FERRO-BORON"
    },
    {
      "code": "72029932",
      "description": "FERRO-ALLOYS - OTHER : OTHER : FERRO-BORON, CHARGE-CHROME : CHARGE-CHROME"
    },
    {
      "code": "72029990",
      "description": "FERRO-ALLOYS - OTHER : OTHER : OTHER"
    },
    {
      "code": "7203",
      "description": "FERROUS PRODUCTS OBTAINED BY DIRECT REDUCTION OF IRON ORE AND OTHER SPONGY FERROUS PRODUCTS, IN LUMPS, PELLETS OR SIMILAR FORMS; IRON HAVING MINIMUM PURITY BY WEIGHT OF 99.94%, IN LUMPS, PELLETS OR SIMILAR FORMS"
    },
    {
      "code": "72031000",
      "description": "FERROUS PRODUCTS OBTAINED BY DIRECT REDUCTION OF IRON ORE AND OTHER SPONGY FERROUS PRODUCTS, IN LUMPS, PELLETS OR SIMILAR FORMS; IRON HAVING MINIMUM PURITY BY WEIGHT OF 99.94%, IN LUMPS, PELLETS OR SIMILAR FORMS - FERROUS PRODUCTS OBTAINED BY DIRECT REDUCTION OF IRON ORE"
    },
    {
      "code": "72039000",
      "description": "FERROUS PRODUCTS OBTAINED BY DIRECT REDUCTION OF IRON ORE AND OTHER SPONGY FERROUS PRODUCTS, IN LUMPS, PELLETS OR SIMILAR FORMS; IRON HAVING MINIMUM PURITY BY WEIGHT OF 99.94%, IN LUMPS, PELLETS OR SIMILAR FORMS - OTHER"
    },
    {
      "code": "7204",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL"
    },
    {
      "code": "72041000",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF CAST IRON"
    },
    {
      "code": "720421",
      "description": "Of stainless steel"
    },
    {
      "code": "72042110",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF ALLOY STEEL : OF STAINLESS STEEL : EMPTY OR DISCHARGED CARTRIDGES OF ALL BORES AND SIZES"
    },
    {
      "code": "72042190",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF ALLOY STEEL : OF STAINLESS STEEL : OTHER"
    },
    {
      "code": "720429",
      "description": "Other"
    },
    {
      "code": "72042910",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF ALLOY STEEL : OTHER : EMPTY OR DISCHARGED CARTRIDGES OF ALL BORES AND SIZES"
    },
    {
      "code": "72042920",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF ALLOY STEEL : OTHER : OF HIGH SPEED STEEL"
    },
    {
      "code": "72042990",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF ALLOY STEEL : OTHER : OTHER"
    },
    {
      "code": "72043000",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - WASTE AND SCRAP OF TINNED IRON OR STEEL"
    },
    {
      "code": "72044100",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - OTHER WASTE AND SCRAP : TURNINGS, SHAVINGS, CHIPS, MILLING WASTE, SAW DUST, FILLINGS, TRIMMINGS AND STAMPINGS, WHETHER OR NOT ` IN BUNDLES"
    },
    {
      "code": "72044900",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - OTHER WASTE AND SCRAP : OTHER"
    },
    {
      "code": "72045000",
      "description": "FERROUS WASTE AND SCRAP; REMELTING SCRAP INGOTS OF IRON OR STEEL - REMELTING SCRAP INGOTS"
    },
    {
      "code": "7205",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL"
    },
    {
      "code": "720510",
      "description": "Granules"
    },
    {
      "code": "72051011",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF IRON : SHOT AND ANGULAR GRIT"
    },
    {
      "code": "72051012",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF IRON : WIRE PELLETS"
    },
    {
      "code": "72051019",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF IRON : OTHER"
    },
    {
      "code": "72051021",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF ALLOY STEEL : SHOT AND ANGULAR GRIT"
    },
    {
      "code": "72051022",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF ALLOY STEEL : WIRE PELLETS"
    },
    {
      "code": "72051029",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OF ALLOY STEEL : OTHER"
    },
    {
      "code": "72051090",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - GRANULES : OTHER"
    },
    {
      "code": "72052100",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - POWDERS : OF ALLOY STEEL"
    },
    {
      "code": "720529",
      "description": "Other"
    },
    {
      "code": "72052910",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - POWDERS : OTHER : OF IRON"
    },
    {
      "code": "72052990",
      "description": "GRANULES AND POWDERS, OF PIG IRON, SPIEGELEISEN, IRON OR STEEL - POWDERS : OTHER : OTHER"
    },
    {
      "code": "7206",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203)"
    },
    {
      "code": "720610",
      "description": "Ingots"
    },
    {
      "code": "72061010",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - INGOTS : OF IRON"
    },
    {
      "code": "72061020",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - INGOTS : OF HIGH CARBON STEEL"
    },
    {
      "code": "72061090",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - INGOTS : OTHER"
    },
    {
      "code": "720690",
      "description": "Other"
    },
    {
      "code": "72069011",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OF IRON : PUDDLED BARS AND PILINGS"
    },
    {
      "code": "72069012",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OF IRON : BLOCKS, LUMPS AND SIMILAR FORMS"
    },
    {
      "code": "72069019",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OF IRON : OTHER"
    },
    {
      "code": "72069091",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OTHER : PUDDLED BARS AND PILINGS"
    },
    {
      "code": "72069092",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OTHER : BLOCKS, LUMPS AND SIMILAR FORMS"
    },
    {
      "code": "72069099",
      "description": "IRON AND NON-ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS (EXCLUDING IRON OF HEADING 7203) - OTHER : OTHER : OTHER"
    },
    {
      "code": "7207",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL"
    },
    {
      "code": "720711",
      "description": "Of rectangular (including square) cross-section, the width measuring less than twice the thickness"
    },
    {
      "code": "72071110",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : 7207 11 SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OF RECTANGULAR (INCLUDING SQUARE) CROSS-SECTION, THE WIDTH MEASURING LESS THAN TWICE THE THICKNESS: ELECTRICAL QUALITY"
    },
    {
      "code": "72071120",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : 7207 11 SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OF RECTANGULAR (INCLUDING SQUARE) CROSS-SECTION, THE WIDTH MEASURING LESS THAN TWICE THE THICKNESS: FORGING QUALITY"
    },
    {
      "code": "72071130",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : 7207 11 SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OF RECTANGULAR (INCLUDING SQUARE) CROSS-SECTION, THE WIDTH MEASURING LESS THAN TWICE THE THICKNESS: SEAMLESS STEEL TUBE QUALITY"
    },
    {
      "code": "72071190",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : 7207 11 SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OF RECTANGULAR (INCLUDING SQUARE) CROSS-SECTION, THE WIDTH MEASURING LESS THAN TWICE THE THICKNESS: OTHER"
    },
    {
      "code": "720712",
      "description": "Other, of rectangular (other than square) cross-section"
    },
    {
      "code": "72071210",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER, OF RECTANGULAR (OTHER THAN SQUARE) CROSSSECTION : ELECTRICAL QUALITY"
    },
    {
      "code": "72071220",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER, OF RECTANGULAR (OTHER THAN SQUARE) CROSSSECTION : FORGING QUALITY"
    },
    {
      "code": "72071230",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER, OF RECTANGULAR (OTHER THAN SQUARE) CROSSSECTION : SEAMLESS STEEL TUBE QUALITY"
    },
    {
      "code": "72071290",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER, OF RECTANGULAR (OTHER THAN SQUARE) CROSSSECTION : OTHER"
    },
    {
      "code": "720719",
      "description": "Other"
    },
    {
      "code": "72071910",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER :FORGED BLANKS OF NON-ALLOY STEEL"
    },
    {
      "code": "72071920",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER :MILD STEEL BILLETS"
    },
    {
      "code": "72071990",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON : OTHER : OTHER"
    },
    {
      "code": "720720",
      "description": "Containing by weight 0.20% or more of carbon"
    },
    {
      "code": "72072010",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT 0.25% OR MORE OF CARBON : FORGING QUALITY"
    },
    {
      "code": "72072020",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT 0.25% OR MORE OF CARBON : SPRING STEEL QUALITY"
    },
    {
      "code": "72072030",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT 0.25% OR MORE OF CARBON : SEAMLESS STEEL TUBE QUALITY"
    },
    {
      "code": "72072090",
      "description": "SEMI-FINISHED PRODUCTS OF IRON OR NON-ALLOY STEEL - CONTAINING BY WEIGHT 0.25% OR MORE OF CARBON : OTHER"
    },
    {
      "code": "7208",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOTROLLED, NOT CLAD, PLATED OR COATED"
    },
    {
      "code": "72081000",
      "description": "IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF"
    },
    {
      "code": "720825",
      "description": "Of a thickness of 4.75 mm or more"
    },
    {
      "code": "72082510",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 4.75 MM OR MORE : PLATES"
    },
    {
      "code": "72082520",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 4.75 MM OR MORE : UNIVERSAL PLATES"
    },
    {
      "code": "72082530",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 4.75 MM OR MORE : SHEETS"
    },
    {
      "code": "72082540",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 4.75 MM OR MORE : STRIP"
    },
    {
      "code": "72082590",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 4.75 MM OR MORE : OTHER"
    },
    {
      "code": "720826",
      "description": "Of a thickness of 3 mm or more but less than 4.75 mm"
    },
    {
      "code": "72082610",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : PLATES"
    },
    {
      "code": "72082620",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72082630",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : SHEETS"
    },
    {
      "code": "72082640",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : STRIP"
    },
    {
      "code": "72082690",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "720827",
      "description": "Of a thickness of less than 3 mm"
    },
    {
      "code": "72082710",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF LESS THAN 3 MM : PLATES"
    },
    {
      "code": "72082720",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF LESS THAN 3 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72082730",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF LESS THAN 3 MM : SHEETS"
    },
    {
      "code": "72082740",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF LESS THAN 3 MM : STRIP"
    },
    {
      "code": "72082790",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOTROLLED, PICKLED : OF A THICKNESS OF LESS THAN 3 MM : OTHER"
    },
    {
      "code": "720836",
      "description": "Of a thickness exceeding 10 mm"
    },
    {
      "code": "72083610",
      "description": "36 FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS EXCEEDING 10 MM : PLATES"
    },
    {
      "code": "72083620",
      "description": "36 FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72083630",
      "description": "36 FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS EXCEEDING 10 MM : SHEETS"
    },
    {
      "code": "72083640",
      "description": "36 FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS EXCEEDING 10 MM : STRIP"
    },
    {
      "code": "72083690",
      "description": "36 FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS EXCEEDING 10 MM : OTHER"
    },
    {
      "code": "720837",
      "description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm"
    },
    {
      "code": "72083710",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : PLATES"
    },
    {
      "code": "72083720",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72083730",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : SHEETS"
    },
    {
      "code": "72083740",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : STRIP"
    },
    {
      "code": "72083790",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : OTHER"
    },
    {
      "code": "720838",
      "description": "Of a thickness of 3 mm or more but less than 4.75 mm"
    },
    {
      "code": "72083810",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : PLATES"
    },
    {
      "code": "72083820",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72083830",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : SHEETS"
    },
    {
      "code": "72083840",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : STRIP"
    },
    {
      "code": "72083890",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "720839",
      "description": "Of a thickness of less than 3 mm"
    },
    {
      "code": "72083910",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF LESS THAN 3 MM : PLATES"
    },
    {
      "code": "72083920",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF LESS THAN 3 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72083930",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF LESS THAN 3 MM : SHEETS"
    },
    {
      "code": "72083940",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF LESS THAN 3 MM : STRIP"
    },
    {
      "code": "72083990",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED: OF A THICKNESS OF LESS THAN 3 MM : OTHER"
    },
    {
      "code": "720840",
      "description": "Not in coils, not further worked than hot-rolled, with patterns in relief"
    },
    {
      "code": "72084010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED NOT IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF : PLATES"
    },
    {
      "code": "72084020",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED NOT IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF : UNIVERSAL PLATES"
    },
    {
      "code": "72084030",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED NOT IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF : SHEETS"
    },
    {
      "code": "72084040",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED NOT IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF : STRIP"
    },
    {
      "code": "72084090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED NOT IN COILS, NOT FURTHER WORKED THAN HOT-ROLLED, WITH PATTERNS IN RELIEF : OTHER"
    },
    {
      "code": "720851",
      "description": "Of a thickness exceeding 10 mm"
    },
    {
      "code": "72085110",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS EXCEEDING 10 MM : PLATES"
    },
    {
      "code": "72085120",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72085130",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS EXCEEDING 10 MM : SHEETS"
    },
    {
      "code": "72085140",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS EXCEEDING 10 MM : STRIP"
    },
    {
      "code": "72085190",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS EXCEEDING 10 MM : OTHER"
    },
    {
      "code": "720852",
      "description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm"
    },
    {
      "code": "72085210",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : PLATES"
    },
    {
      "code": "72085220",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72085230",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : SHEETS"
    },
    {
      "code": "72085240",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : STRIP"
    },
    {
      "code": "72085290",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : OTHER"
    },
    {
      "code": "720853",
      "description": "Of a thickness of 3 mm or more but less than 4.75 mm"
    },
    {
      "code": "72085310",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : PLATES"
    },
    {
      "code": "72085320",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72085330",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : SHEETS"
    },
    {
      "code": "72085340",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : STRIP"
    },
    {
      "code": "72085390",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "720854",
      "description": "Of a thickness of less than 3 mm"
    },
    {
      "code": "72085410",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF LESS THAN 3 MM : PLATES"
    },
    {
      "code": "72085420",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF LESS THAN 3 MM : UNIVERSAL PLATES"
    },
    {
      "code": "72085430",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF LESS THAN 3 MM : SHEETS"
    },
    {
      "code": "72085440",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF LESS THAN 3 MM : STRIP"
    },
    {
      "code": "72085490",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER, NOT IN COILS, NOT FURTHER WORKED THAN HOTROLLED : OF A THICKNESS OF LESS THAN 3 MM : OTHER"
    },
    {
      "code": "72089000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, HOT-ROLLED, NOT CLAD, PLATED OR COATED - OTHER"
    },
    {
      "code": "7209",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLDROLLED (COLD-REDUCED), NOT CLAD, PLATED OR COATED - In coils, not further worked than cold-rolled (coldreduced)"
    },
    {
      "code": "720915",
      "description": "Of a thickness of 3 mm or more"
    },
    {
      "code": "72091510",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : PLATES"
    },
    {
      "code": "72091520",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : SHEETS"
    },
    {
      "code": "72091530",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : STRIP"
    },
    {
      "code": "72091590",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : OTHER"
    },
    {
      "code": "720916",
      "description": "Of a thickness exceeding 1 mm but less than 3 mm"
    },
    {
      "code": "72091610",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : PLATES"
    },
    {
      "code": "72091620",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : SHEETS"
    },
    {
      "code": "72091630",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : STRIP"
    },
    {
      "code": "72091690",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : OTHER"
    },
    {
      "code": "720917",
      "description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm"
    },
    {
      "code": "72091710",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : PLATES"
    },
    {
      "code": "72091720",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : SHEETS"
    },
    {
      "code": "72091730",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : STRIP"
    },
    {
      "code": "72091790",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : OTHER"
    },
    {
      "code": "720918",
      "description": "Of a thickness of less than 0.5 mm"
    },
    {
      "code": "72091810",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : PLATES"
    },
    {
      "code": "72091820",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : SHEETS"
    },
    {
      "code": "72091830",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : STRIP"
    },
    {
      "code": "72091890",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : OTHER"
    },
    {
      "code": "720925",
      "description": "Of a thickness of 3 mm or more"
    },
    {
      "code": "72092510",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : PLATES"
    },
    {
      "code": "72092520",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : SHEETS"
    },
    {
      "code": "72092530",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : STRIP"
    },
    {
      "code": "72092590",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM OR MORE : OTHER"
    },
    {
      "code": "720926",
      "description": "Of a thickness excddeing 1 mm but less than 3 mm"
    },
    {
      "code": "72092610",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : PLATES"
    },
    {
      "code": "72092620",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : SHEETS"
    },
    {
      "code": "72092630",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : STRIP"
    },
    {
      "code": "72092690",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : OTHER"
    },
    {
      "code": "720927",
      "description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm"
    },
    {
      "code": "72092710",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : PLATES"
    },
    {
      "code": "72092720",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : SHEETS"
    },
    {
      "code": "72092730",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : STRIP"
    },
    {
      "code": "72092790",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : OTHER"
    },
    {
      "code": "720928",
      "description": "Of a thickness of less than 0.5 mm"
    },
    {
      "code": "72092810",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : PLATES"
    },
    {
      "code": "72092820",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : SHEETS"
    },
    {
      "code": "72092830",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : STRIP"
    },
    {
      "code": "72092890",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - NOT IN COILS, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : OTHER"
    },
    {
      "code": "72099000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, COLD-ROLLED (COLDREDUCED), NOT CLAD, PLATED OR COATED - OTHER"
    },
    {
      "code": "7210",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - Plated or coated with tin"
    },
    {
      "code": "721011",
      "description": "Of a thickness of 0.5 mm or more"
    },
    {
      "code": "72101110",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OF A THICKNESS OF 0.5 MM OR MORE : OTS/MR TYPE"
    },
    {
      "code": "72101190",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OF A THICKNESS OF 0.5 MM OR MORE : OTHER"
    },
    {
      "code": "721012",
      "description": "Of a thickness of less than 0.5 mm"
    },
    {
      "code": "72101210",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OF A THICKNESS OF LESS THAN 0.5 MM: OTS/MR TYPE"
    },
    {
      "code": "72101290",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OF A THICKNESS OF LESS THAN 0.5 MM: OTHER"
    },
    {
      "code": "72102000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH LEAD, INCLUDING TERNE-PLATE"
    },
    {
      "code": "721030",
      "description": "Electrolytically plated or coated with zinc"
    },
    {
      "code": "72103010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - ELECTROLYTICALLY PLATED OR COATED WITH ZINC :CORRUGATED"
    },
    {
      "code": "72103090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - ELECTROLYTICALLY PLATED OR COATED WITH ZINC :OTHER"
    },
    {
      "code": "72104100",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED WITH ZINC : CORRUGATED"
    },
    {
      "code": "72104900",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED WITH ZINC : OTHER"
    },
    {
      "code": "72105000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH CHROMIUM OXIDES OR WITH CHROMIUM AND CHROMIUM OXIDES"
    },
    {
      "code": "72106100",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH ALUMINIUM : PLATED OR COATED WITH ALUMINIUM-ZINC ALLOYS"
    },
    {
      "code": "72106900",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PLATED OR COATED WITH ALUMINIUM : OTHER"
    },
    {
      "code": "72107000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - PAINTED, VARNISHED OR COATED WITH PLASTICS"
    },
    {
      "code": "721090",
      "description": "Other"
    },
    {
      "code": "72109010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - OTHER : LACQUERED"
    },
    {
      "code": "72109090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE, CLAD, PLATED OR COATED - OTHER : OTHER"
    },
    {
      "code": "7211",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - Not further worked than hot-rolled"
    },
    {
      "code": "72111300",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : ROLLED ON FOUR FACES OR IN A CLOSED BOX PASS, OF A WIDTH EXCEEDING 150 MM AND A THICKNESS OF NOT LESS THAN 4 MM, NOT IN COILS AND WITHOUT PATTERNS IN RELIEF"
    },
    {
      "code": "721114",
      "description": "Other, of a thickness of 4.75 mm or more"
    },
    {
      "code": "72111410",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : FLATS"
    },
    {
      "code": "72111420",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : UNIVERSAL PLATES"
    },
    {
      "code": "72111430",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : HOOPS"
    },
    {
      "code": "72111440",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : SHEETS"
    },
    {
      "code": "72111450",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : STRIP"
    },
    {
      "code": "72111460",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : SKELP"
    },
    {
      "code": "72111490",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER, OF A THICKNESS OF 4.75 MM OR MORE : OTHER"
    },
    {
      "code": "721119",
      "description": "Other"
    },
    {
      "code": "72111910",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : FLATS"
    },
    {
      "code": "72111920",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : UNIVERSAL PLATES"
    },
    {
      "code": "72111930",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : HOOPS"
    },
    {
      "code": "72111940",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : SHEETS"
    },
    {
      "code": "72111950",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : STRIP"
    },
    {
      "code": "72111960",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : SKELP"
    },
    {
      "code": "72111990",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN HOT-ROLLED : OTHER : OTHER"
    },
    {
      "code": "721123",
      "description": "Containing by weight less than 0.20% of carbon"
    },
    {
      "code": "72112310",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: FLATS"
    },
    {
      "code": "72112320",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: UNIVERSAL PLATES"
    },
    {
      "code": "72112330",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: HOOPS"
    },
    {
      "code": "72112340",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: SHEETS"
    },
    {
      "code": "72112350",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: STRIP"
    },
    {
      "code": "72112390",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : CONTAINING BY WEIGHT LESS THAN 0.25% OF CARBON: OTHER"
    },
    {
      "code": "721129",
      "description": "Other"
    },
    {
      "code": "72112910",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : FLATS"
    },
    {
      "code": "72112920",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : UNIVERSAL PLATES"
    },
    {
      "code": "72112930",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : HOOPS"
    },
    {
      "code": "72112940",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : SHEETS"
    },
    {
      "code": "72112950",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : STRIP"
    },
    {
      "code": "72112960",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : SKELP"
    },
    {
      "code": "72112990",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OTHER : OTHER"
    },
    {
      "code": "721190",
      "description": "Other"
    },
    {
      "code": "72119011",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - OTHER : UNIVERSAL PLATES : OF BOILER QUALITY"
    },
    {
      "code": "72119012",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - OTHER : UNIVERSAL PLATES : OF HIGH TENSILE QUALITY"
    },
    {
      "code": "72119013",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - OTHER : UNIVERSAL PLATES : OF SHIP BUILDING QUALITY"
    },
    {
      "code": "72119090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, NOT CLAD, PLATED OR COATED - OTHER : OTHER"
    },
    {
      "code": "7212",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED"
    },
    {
      "code": "721210",
      "description": "Plated or coated with tin"
    },
    {
      "code": "72121010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OTS OR MR TYPE"
    },
    {
      "code": "72121090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - PLATED OR COATED WITH TIN : OTHER"
    },
    {
      "code": "721220",
      "description": "Electrolytically plated or coated with zinc"
    },
    {
      "code": "72122010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - ELECTROLYTICALLY PLATED OR COATED WITH ZINC : CORRUGATED"
    },
    {
      "code": "72122090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - ELECTROLYTICALLY PLATED OR COATED WITH ZINC : OTHER"
    },
    {
      "code": "721230",
      "description": "Otherwise plated or coated with zinc"
    },
    {
      "code": "72123010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED WITH ZINC : CORRUGATED"
    },
    {
      "code": "72123090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED WITH ZINC : OTHER"
    },
    {
      "code": "72124000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - PAINTED, VARNISHED OR COATED WITH PLASTICS"
    },
    {
      "code": "721250",
      "description": "Otherwise plated or coated"
    },
    {
      "code": "72125010",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED : PLATED OR COATED WITH LEAD"
    },
    {
      "code": "72125020",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED : LACQUERED"
    },
    {
      "code": "72125090",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - OTHERWISE PLATED OR COATED : OTHER"
    },
    {
      "code": "72126000",
      "description": "FLAT-ROLLED PRODUCTS OF IRON OR NON-ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM, CLAD, PLATED OR COATED - CLAD"
    },
    {
      "code": "7213",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL"
    },
    {
      "code": "721310",
      "description": "Containing indentations, ribs, grooves or other deformations produced during the rolling process"
    },
    {
      "code": "72131010",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - CONTAINING INDENTATIONS, RIBS, GROOVES OR OTHER DEFORMATIONS PRODUCED DURING THE ROLLING PROCESS : OF FREE CUTTING STEEL"
    },
    {
      "code": "72131090",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - CONTAINING INDENTATIONS, RIBS, GROOVES OR OTHER DEFORMATIONS PRODUCED DURING THE ROLLING PROCESS : OTHER"
    },
    {
      "code": "721320",
      "description": "Other, of free-cutting steel"
    },
    {
      "code": "72132010",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER, OF FREE-CUTTING STEEL : ELECTRODE QUALITY"
    },
    {
      "code": "72132020",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER, OF FREE-CUTTING STEEL : COLD HEADING QUALITY"
    },
    {
      "code": "72132090",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER, OF FREE-CUTTING STEEL : OTHER"
    },
    {
      "code": "721391",
      "description": "Of circular cross-section measuring less than 14 mm in diameter"
    },
    {
      "code": "72139110",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OF CIRCULAR CROSS-SECTION MEASURING LESS THAN 14 MM IN DIAMETER : ELECTRODE QUALITY"
    },
    {
      "code": "72139120",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OF CIRCULAR CROSS-SECTION MEASURING LESS THAN 14 MM IN DIAMETER : COLD HEADING QUALITY"
    },
    {
      "code": "72139190",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OF CIRCULAR CROSS-SECTION MEASURING LESS THAN 14 MM IN DIAMETER : OTHER"
    },
    {
      "code": "721399",
      "description": "Other"
    },
    {
      "code": "72139910",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : ELECTRODE QUALITY"
    },
    {
      "code": "72139920",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : COLD HEADING QUALITY"
    },
    {
      "code": "72139990",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7214",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOTROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING"
    },
    {
      "code": "721410",
      "description": "Forged"
    },
    {
      "code": "72141010",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - FORGED : SPRING STEEL QUALITY"
    },
    {
      "code": "72141090",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - FORGED : OTHER"
    },
    {
      "code": "721420",
      "description": "Containing indentations, ribs, grooves or other deformations produced during the rolling process or twisted after rolling"
    },
    {
      "code": "72142010",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - CONTAINING INDENTATIONS, RIBS, GROOVES OR OTHER DEFORMATIONS PRODUCED DURING THE ROLLING PROCESS OR TWISTED AFTER ROLLING : SPRING STEEL QUALITY"
    },
    {
      "code": "72142090",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - CONTAINING INDENTATIONS, RIBS, GROOVES OR OTHER DEFORMATIONS PRODUCED DURING THE ROLLING PROCESS OR TWISTED AFTER ROLLING : OTHER"
    },
    {
      "code": "72143000",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - OTHER, OF FREE CUTTING STEEL"
    },
    {
      "code": "721491",
      "description": "Of rectangular (other than square) cross-section"
    },
    {
      "code": "72149110",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - OTHER : OF RECTANGULAR (OTHER THAN SQUARE) CROSS-SECTION : MILD STEEL BRIGHT BAR"
    },
    {
      "code": "72149190",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - OTHER : OF RECTANGULAR (OTHER THAN SQUARE) CROSS-SECTION : OTHER"
    },
    {
      "code": "721499",
      "description": "Other"
    },
    {
      "code": "72149910",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - OTHER : OTHER : OF SPRING STEEL QUALITY"
    },
    {
      "code": "72149990",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL, NOT FURTHER WORKED THAN FORGED, HOT-ROLLED, HOT-DRAWN OR HOT-EXTRUDED, BUT INCLUDING THOSE TWISTED AFTER ROLLING - OTHER : OTHER : OTHER"
    },
    {
      "code": "7215",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL"
    },
    {
      "code": "72151000",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OF FREE-CUTTING STEEL, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED"
    },
    {
      "code": "721550",
      "description": "Other, not further worked than cold-formed or cold-finished"
    },
    {
      "code": "72155010",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OTHER, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : MILD STEEL BRIGHT BAR"
    },
    {
      "code": "72155090",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OTHER, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : OTHER"
    },
    {
      "code": "721590",
      "description": "Other"
    },
    {
      "code": "72159010",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OTHER : PLATED OR COATED WITH ZINC"
    },
    {
      "code": "72159020",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OTHER : PLATED OR COATED WITH OTHER BASE METALS"
    },
    {
      "code": "72159090",
      "description": "OTHER BARS AND RODS OF IRON OR NON-ALLOY STEEL - OTHER : OTHER"
    },
    {
      "code": "7216",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NONALLOY STEEL"
    },
    {
      "code": "72161000",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL U, I OR H SECTIONS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED, OF A HEIGHT OF LESS THAN 80 MM"
    },
    {
      "code": "72162100",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - L OR T SECTIONS, NOT FURTHER WORKED THAN HOTROLLED, HOT-DRAWN OR EXTRUDED, OF A HEIGHT OF LESS THAN 80 MM : L SECTIONS"
    },
    {
      "code": "72162200",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - L OR T SECTIONS, NOT FURTHER WORKED THAN HOTROLLED, HOT-DRAWN OR EXTRUDED, OF A HEIGHT OF LESS THAN 80 MM : T SECTIONS"
    },
    {
      "code": "72163100",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - U, I OR H SECTIONS, NOT FURTHER WORKED THAN HOTROLLED, HOT-DRAWN OR EXTRUDED OF A HEIGHT OF 80 MM OR MORE : U SECTIONS"
    },
    {
      "code": "72163200",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - U, I OR H SECTIONS, NOT FURTHER WORKED THAN HOTROLLED, HOT-DRAWN OR EXTRUDED OF A HEIGHT OF 80 MM OR MORE : I SECTIONS"
    },
    {
      "code": "72163300",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - U, I OR H SECTIONS, NOT FURTHER WORKED THAN HOTROLLED, HOT-DRAWN OR EXTRUDED OF A HEIGHT OF 80 MM OR MORE : H SECTIONS"
    },
    {
      "code": "72164000",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - L OR T SECTIONS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED, OF A HEIGHT OF 80 MM OR MORE"
    },
    {
      "code": "72165000",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER ANGLES, SHAPES AND SECTIONS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED"
    },
    {
      "code": "72166100",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : OBTAINED FROM FLAT-ROLLED PRODUCTS"
    },
    {
      "code": "72166900",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : OTHER"
    },
    {
      "code": "72169100",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: COLD-FORMED OR COLD-FINISHED FROM FLAT ROLLED PRODUCTS"
    },
    {
      "code": "721699",
      "description": "Other"
    },
    {
      "code": "72169910",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: OTHER : PLATED OR COATED WITH ZINC"
    },
    {
      "code": "72169920",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: OTHER : PLATED OR COATED WITH BASE METALS OTHER THAN ZINC"
    },
    {
      "code": "72169930",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: OTHER : SLOTTED ANGLES AND SLOTTED CHANNELS"
    },
    {
      "code": "72169940",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: OTHER : FORGED"
    },
    {
      "code": "72169990",
      "description": "ANGLES, SHAPES AND SECTIONS OF IRON OR NON-ALLOY STEEL - OTHER: OTHER : OTHER"
    },
    {
      "code": "7217",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL"
    },
    {
      "code": "721710",
      "description": "Not plated or coated, whether or not polished"
    },
    {
      "code": "72171010",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - NOT PLATED OR COATED, WHETHER OR NOT POLISHED : OF A THICKNESS OF 18 SWG AND BELOW"
    },
    {
      "code": "72171020",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - NOT PLATED OR COATED, WHETHER OR NOT POLISHED : OF A THICKNESS ABOVE 18 SWG BUT UP TO 26 SWG"
    },
    {
      "code": "72171030",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - NOT PLATED OR COATED, WHETHER OR NOT POLISHED : OF A THICKNESS ABOVE 26 SWG"
    },
    {
      "code": "721720",
      "description": "Plated or coated with zinc"
    },
    {
      "code": "72172010",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH ZINC : OF A THICKNESS OF 18 SWG AND BELOW"
    },
    {
      "code": "72172020",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH ZINC : OF A THICKNESS ABOVE 18 SWG BUT UP TO 26 SWG"
    },
    {
      "code": "72172030",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH ZINC : OF A THICKNESS ABOVE 26 SWG"
    },
    {
      "code": "721730",
      "description": "Plated or coated with other base metals"
    },
    {
      "code": "72173010",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH OTHER BASE METALS : OF A THICKNESS OF 18 SWG AND BELOW"
    },
    {
      "code": "72173020",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH OTHER BASE METALS : OF A THICKNESS ABOVE 18 SWG BUT UP TO 26 SWG"
    },
    {
      "code": "72173030",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - PLATED OR COATED WITH OTHER BASE METALS : OF A THICKNESS ABOVE 26 SWG"
    },
    {
      "code": "721790",
      "description": "Other"
    },
    {
      "code": "72179011",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRE : OF CROSS SECTION  HALF ROUND"
    },
    {
      "code": "72179012",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRE : OF CROSS SECTION  FLAT AND RECTANGULAR"
    },
    {
      "code": "72179013",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRE : OF CROSS SECTION  `Z SHAPED"
    },
    {
      "code": "72179019",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRE : OF CROSS SECTION  OTHER SHAPES"
    },
    {
      "code": "72179091",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : HIGH TENSILE QUALITY"
    },
    {
      "code": "72179092",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : ELECTRODE QUALITY"
    },
    {
      "code": "72179093",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : ELECTRIC RESISTANCE WIRE (INCLUDING ELECTRIC RESISTANCE HEATING WIRE)"
    },
    {
      "code": "72179099",
      "description": "WIRE OF IRON OR NON-ALLOY STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7218",
      "description": "STAINLESS STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF STAINLESS STEEL"
    },
    {
      "code": "72181000",
      "description": "STAINLESS STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMIFINISHED PRODUCTS OF STAINLESS STEEL - INGOTS AND OTHER PRIMARY FORMS"
    },
    {
      "code": "72189100",
      "description": "STAINLESS STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMIFINISHED PRODUCTS OF STAINLESS STEEL - OTHER : OF RECTANGULAR (OTHER THAN SQUARE) CROSS-SECTION"
    },
    {
      "code": "721899",
      "description": "Other"
    },
    {
      "code": "72189910",
      "description": "STAINLESS STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMIFINISHED PRODUCTS OF STAINLESS STEEL - OTHER : OTHER : BILLETS"
    },
    {
      "code": "72189990",
      "description": "STAINLESS STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMIFINISHED PRODUCTS OF STAINLESS STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7219",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - Not further worked than hot-rolled, in coils"
    },
    {
      "code": "721911",
      "description": "Of a thickness exceeding 10 mm"
    },
    {
      "code": "72191111",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS EXCEEDING 10 MM : CHROMIUM TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72191112",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS EXCEEDING 10 MM : CHROMIUM TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72191190",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS EXCEEDING 10 MM : CHROMIUM TYPE : OTHER"
    },
    {
      "code": "72191200",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM"
    },
    {
      "code": "72191300",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM"
    },
    {
      "code": "72191400",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF A THICKNESS OF LESS THAN 3 MM"
    },
    {
      "code": "721921",
      "description": "Of a thickness exceeding 10 mm"
    },
    {
      "code": "72192111",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL OR HEAT RESISTING STEEL AND CHROMIUM TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72192112",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL OR HEAT RESISTING STEEL AND CHROMIUM TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72192121",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL HEAT RESISTING STEEL, NICKEL CHROMIUM AUSTENITIC TYPE: NOT EXCEEDING 14 MM"
    },
    {
      "code": "72192122",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL HEAT RESISTING STEEL, NICKEL CHROMIUM AUSTENITIC TYPE: EXCEEDING 14 MM"
    },
    {
      "code": "72192131",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : OTHER CHROMIUM TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72192132",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : OTHER CHROMIUM TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72192141",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : OTHER NICKEL CHROMIUM AUSTENITIC TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72192142",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : OTHER NICKEL CHROMIUM AUSTENITIC TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72192190",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS EXCEEDING 10 MM : OTHER"
    },
    {
      "code": "721922",
      "description": "Of a thickness of 4.75 mm or more but not exceeding 10 mm"
    },
    {
      "code": "72192211",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL OR HEAT RESISTING STEEL : CHROMIUM TYPE"
    },
    {
      "code": "72192212",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL OR HEAT RESISTING STEEL : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72192219",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : UNIVERSAL PLATES OF STAINLESS STEEL OR HEAT RESISTING STEEL : OTHER"
    },
    {
      "code": "72192291",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : OTHER : CHROMIUM TYPE"
    },
    {
      "code": "72192292",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : OTHER : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72192299",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 4.75 MM OR MORE BUT NOT EXCEEDING 10 MM : OTHER : OTHER"
    },
    {
      "code": "721923",
      "description": "Of a thickness of 3 mm or more but less than 4.75 mm"
    },
    {
      "code": "72192310",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : CHROMIUM TYPE"
    },
    {
      "code": "72192320",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72192390",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "721924",
      "description": "Of a thickness of less than 3 mm"
    },
    {
      "code": "72192411",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : CHROMIUM TYPE, OF A THICKNESS : BELOW 0.35 MM"
    },
    {
      "code": "72192412",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : CHROMIUM TYPE, OF A THICKNESS : 0.35 MM AND ABOVE BUT BELOW 0.56 MM"
    },
    {
      "code": "72192413",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : CHROMIUM TYPE, OF A THICKNESS : 0.56 MM AND ABOVE BUT BELOW 0.90 MM"
    },
    {
      "code": "72192419",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : CHROMIUM TYPE, OF A THICKNESS : OTHER"
    },
    {
      "code": "72192421",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : NICKEL CHROMIUM AUSTENITIC TYPE, OF A THICKNESS : BELOW 0.35 MM"
    },
    {
      "code": "72192422",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : NICKEL CHROMIUM AUSTENITIC TYPE, OF A THICKNESS : 0.35 MM AND ABOVE BUT BELOW 0.56 MM"
    },
    {
      "code": "72192423",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : NICKEL CHROMIUM AUSTENITIC TYPE, OF A THICKNESS : 0.56 MM AND ABOVE BUT BELOW 0.90 MM"
    },
    {
      "code": "72192429",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : NICKEL CHROMIUM AUSTENITIC TYPE, OF A THICKNESS : OTHER"
    },
    {
      "code": "72192490",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF LESS THAN 3 MM : OTHER"
    },
    {
      "code": "721931",
      "description": "Of a thickness of 4.75 mm or more"
    },
    {
      "code": "72193111",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 4.75 MM OR MORE : CHROMIUM TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72193112",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 4.75 MM OR MORE : CHROMIUM TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72193121",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 4.75 MM OR MORE : NICKEL CHROMIUM AUSTENITIC TYPE : NOT EXCEEDING 14 MM"
    },
    {
      "code": "72193122",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 4.75 MM OR MORE : NICKEL CHROMIUM AUSTENITIC TYPE : EXCEEDING 14 MM"
    },
    {
      "code": "72193190",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 4.75 MM OR MORE : NICKEL CHROMIUM AUSTENITIC TYPE : OTHER"
    },
    {
      "code": "721932",
      "description": "Of a thickness of 3 mm or more but less than 4.75 mm"
    },
    {
      "code": "72193210",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : CHROMIUM TYPE"
    },
    {
      "code": "72193220",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72193290",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 3 MM OR MORE BUT LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "721933",
      "description": "Of a thickness exceeding 1 mm but less than 3 mm"
    },
    {
      "code": "72193310",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : CHROMIUM TYPE"
    },
    {
      "code": "72193320",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72193390",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS EXCEEDING 1 MM BUT LESS THAN 3 MM : OTHER"
    },
    {
      "code": "721934",
      "description": "Of a thickness of 0.5 mm or more but not exceeding 1 mm"
    },
    {
      "code": "72193410",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : CHROMIUM TYPE"
    },
    {
      "code": "72193420",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72193490",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF 0.5 MM OR MORE BUT NOT EXCEEDING 1 MM : OTHER"
    },
    {
      "code": "721935",
      "description": "Of a thickness of less than 0.5 mm"
    },
    {
      "code": "72193510",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : CHROMIUM TYPE"
    },
    {
      "code": "72193520",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72193590",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED) : OF A THICKNESS OF LESS THAN 0.5 MM : OTHER"
    },
    {
      "code": "721990",
      "description": "Other"
    },
    {
      "code": "72199011",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : SHEETS AND PLATES : THICKNESS MORE THAN 4.75 MM"
    },
    {
      "code": "72199012",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : SHEETS AND PLATES : THICKNESS 3 MM TO 4.75 MM"
    },
    {
      "code": "72199013",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : SHEETS AND PLATES : THICKNESS LESS THAN 3 MM"
    },
    {
      "code": "72199090",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : OTHER"
    },
    {
      "code": "7220",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - Not further worked than hot-rolled"
    },
    {
      "code": "722011",
      "description": "Of a thickness of 4.75 mm or more"
    },
    {
      "code": "72201110",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 4.75 MM OR MORE : SKELP FOR PIPES AND TUBES"
    },
    {
      "code": "72201121",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 4.75 MM OR MORE : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : CHROMIUM TYPE"
    },
    {
      "code": "72201122",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 4.75 MM OR MORE : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72201129",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 4.75 MM OR MORE : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : OTHER"
    },
    {
      "code": "72201190",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 4.75 MM OR MORE : OTHER"
    },
    {
      "code": "722012",
      "description": "Of a thickness of less than 4.75 mm"
    },
    {
      "code": "72201210",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF LESS THAN 4.75 MM : SKELP FOR PIPES AND TUBES"
    },
    {
      "code": "72201221",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF LESS THAN 4.75 MM : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : CHROMIUM TYPE"
    },
    {
      "code": "72201222",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF LESS THAN 4.75 MM : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72201229",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF LESS THAN 4.75 MM : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : OTHER"
    },
    {
      "code": "72201290",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF LESS THAN 4.75 MM : OTHER"
    },
    {
      "code": "722020",
      "description": "Not further worked than cold-rolled (cold- reduced)"
    },
    {
      "code": "72202010",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED): SKELP FOR PIPES AND TUBES"
    },
    {
      "code": "72202021",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED): STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : CHROMIUM TYPE"
    },
    {
      "code": "72202022",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED): STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72202029",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED): STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : OTHER"
    },
    {
      "code": "72202090",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - NOT FURTHER WORKED THAN COLD-ROLLED (COLD REDUCED): OTHER"
    },
    {
      "code": "722090",
      "description": "Other"
    },
    {
      "code": "72209010",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER : SKELP (STRIPS FOR PIPES AND TUBES)"
    },
    {
      "code": "72209021",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : CHROMIUM TYPE"
    },
    {
      "code": "72209022",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72209029",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER : STRIPS FOR PIPES AND TUBES (OTHER THAN SKELP) : OTHER"
    },
    {
      "code": "72209090",
      "description": "FLAT-ROLLED PRODUCTS OF STAINLESS STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER :OTHER"
    },
    {
      "code": "7221",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL"
    },
    {
      "code": "722100",
      "description": "Bars and rods, hot-rolled, in irregularly wound coils, of stainless steel"
    },
    {
      "code": "72210011",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL - BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL : BRIGHT BARS : CHROMIUM TYPE"
    },
    {
      "code": "72210012",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL - BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL : BRIGHT BARS : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72210019",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL - BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL : BRIGHT BARS : OTHER"
    },
    {
      "code": "72210090",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF STAINLESS STEEL - OTHER"
    },
    {
      "code": "7222",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - Bars and rods, not further worked than hot-rolled, hot-drawn or extruded"
    },
    {
      "code": "722211",
      "description": "Of circular cross section"
    },
    {
      "code": "72221111",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : BRIGHT BARS : CHROMIUM TYPE"
    },
    {
      "code": "72221112",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : BRIGHT BARS : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72221119",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : BRIGHT BARS : OTHER"
    },
    {
      "code": "72221191",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : OTHER : CHROMIUM TYPE"
    },
    {
      "code": "72221192",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : OTHER : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72221199",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OF CIRCULAR CROSS SECTION : OTHER : OTHER"
    },
    {
      "code": "722219",
      "description": "Other"
    },
    {
      "code": "72221911",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : BRIGHT BARS : CHROMIUM TYPE"
    },
    {
      "code": "72221912",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : BRIGHT BARS : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72221919",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : BRIGHT BARS : OTHER"
    },
    {
      "code": "72221991",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : OTHER : CHROMIUM TYPE"
    },
    {
      "code": "72221992",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : OTHER : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72221999",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN HOT ROLLED, HOT-DRAWN OR EXTRUDED: OTHER : OTHER : OTHER"
    },
    {
      "code": "722220",
      "description": "Bars and rods, not further worked than cold-formed or cold-finished"
    },
    {
      "code": "72222011",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : BRIGHT BARS : CHROMIUM TYPE"
    },
    {
      "code": "72222012",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : BRIGHT BARS : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72222019",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : BRIGHT BARS : OTHER"
    },
    {
      "code": "72222091",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : OTHER : CHROMIUM TYPE"
    },
    {
      "code": "72222092",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : OTHER : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72222099",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - BARS AND RODS, NOT FURTHER WORKED THAN COLD FORMED OR COLD-FINISHED : OTHER : OTHER"
    },
    {
      "code": "722230",
      "description": "Other bars and rods"
    },
    {
      "code": "72223011",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : BRIGHT BARS : CHROMIUM TYPE"
    },
    {
      "code": "72223012",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : BRIGHT BARS : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72223019",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : BRIGHT BARS : OTHER"
    },
    {
      "code": "72223091",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : OTHER : CHROMIUM TYPE"
    },
    {
      "code": "72223092",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : OTHER : NICKEL CHROMIUM AUSTENITIC TYPE"
    },
    {
      "code": "72223099",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - OTHER BARS AND RODS : OTHER : OTHER"
    },
    {
      "code": "722240",
      "description": "Angles, shapes and sections"
    },
    {
      "code": "72224010",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - ANGLES, SHAPES AND SECTIONS : OF THICKNESS OF 80 MM AND ABOVE"
    },
    {
      "code": "72224020",
      "description": "OTHER BARS AND RODS OF STAINLESS STEEL; ANGLES, SHAPES AND SECTIONS OF STAINLESS STEEL - ANGLES, SHAPES AND SECTIONS : OF BELOW 80 MM"
    },
    {
      "code": "7223",
      "description": "WIRE OF STAINLESS STEEL"
    },
    {
      "code": "722300",
      "description": "Wire of stainless steel"
    },
    {
      "code": "72230010",
      "description": "WIRE OF STAINLESS STEEL - WIRE OF STAINLESS STEEL :ELECTRODE QUALITY"
    },
    {
      "code": "72230091",
      "description": "WIRE OF STAINLESS STEEL - WIRE OF STAINLESS STEEL : OTHER : OF THICKNESS OF ABOVE 1.5 MM"
    },
    {
      "code": "72230092",
      "description": "WIRE OF STAINLESS STEEL - WIRE OF STAINLESS STEEL : OTHER : OF THICKNESS OF 0.46 MM AND ABOVE BUT NOT EXCEEDING 1.5 MM"
    },
    {
      "code": "72230099",
      "description": "WIRE OF STAINLESS STEEL - WIRE OF STAINLESS STEEL : OTHER : OF THICKNESS OF BELOW 0.46 MM"
    },
    {
      "code": "7224",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL"
    },
    {
      "code": "72241000",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL INGOTS AND OTHER PRIMARY FORMS"
    },
    {
      "code": "722490",
      "description": "Other"
    },
    {
      "code": "72249010",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : OF TOOL STEEL QUALITY"
    },
    {
      "code": "72249020",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : OF DIE STEEL QUALITY"
    },
    {
      "code": "72249030",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : OF COBALT BEARING HIGH SPEED STEEL QUALITY"
    },
    {
      "code": "72249040",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : FORGED BLANKS OF ALLOY STEEL"
    },
    {
      "code": "72249091",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : OTHER : BILLETS"
    },
    {
      "code": "72249099",
      "description": "OTHER ALLOY STEEL IN INGOTS OR OTHER PRIMARY FORMS; SEMI-FINISHED PRODUCTS OF OTHER ALLOY STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7225",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - Of silicon-electrical steel"
    },
    {
      "code": "72251100",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF SILICON-ELECTRICAL STEEL : GRAIN-ORIENTED"
    },
    {
      "code": "722519",
      "description": "Other"
    },
    {
      "code": "72251910",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - HOT ROLLED"
    },
    {
      "code": "72251920",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - COLD ROLLED"
    },
    {
      "code": "72251990",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - OTHER THAN HOT OR COLD ROLLED"
    },
    {
      "code": "72252011",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF HIGH SPEED STEEL : HOT-ROLLED : IN COILS"
    },
    {
      "code": "72252019",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF HIGH SPEED STEEL : HOT-ROLLED : NOT IN COILS"
    },
    {
      "code": "72252021",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF HIGH SPEED STEEL : COLD-ROLLED : IN COILS"
    },
    {
      "code": "72252029",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OF HIGH SPEED STEEL : COLD-ROLLED : NOT IN COILS"
    },
    {
      "code": "722530",
      "description": "Other, not further worked than hot-rolled, in coils"
    },
    {
      "code": "72253010",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OF SPRING STEEL QUALITY"
    },
    {
      "code": "72253090",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, IN COILS : OTHER"
    },
    {
      "code": "722540",
      "description": "Other, not further worked than hot-rolled, not in coils"
    },
    {
      "code": "72254011",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF ABOVE 4.75 MM : BOILER QUALITY"
    },
    {
      "code": "72254012",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF ABOVE 4.75 MM : PRESSURE VESSEL QUALITY"
    },
    {
      "code": "72254013",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF ABOVE 4.75 MM : HIGH TENSILE QUALITY"
    },
    {
      "code": "72254019",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF ABOVE 4.75 MM : OTHER"
    },
    {
      "code": "72254020",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF 3 MM AND ABOVE BUT NOT EXCEEDING 4.75 MM"
    },
    {
      "code": "72254030",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN HOT-ROLLED, NOT IN COILS : OF A THICKNESS OF BELOW 3 MM"
    },
    {
      "code": "722550",
      "description": "Other, not further worked than cold-rolled (cold-reduced)"
    },
    {
      "code": "72255010",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF LESS THAN 3 MM"
    },
    {
      "code": "72255020",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF 3 MM TO 4.75 MM"
    },
    {
      "code": "72255030",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER, NOT FURTHER WORKED THAN COLD-ROLLED (COLD-REDUCED) : OF A THICKNESS OF ABOVE 4.75 MM"
    },
    {
      "code": "72259100",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : ELECTROLYTICALLY PLATED OR COATED WITH ZINC"
    },
    {
      "code": "72259200",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : OTHERWISE PLATED OR COATED WITH ZINC"
    },
    {
      "code": "72259900",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF 600 MM OR MORE - OTHER : OTHER"
    },
    {
      "code": "7226",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - Of silicon-electrical steel"
    },
    {
      "code": "72261100",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF SILICON-ELECTRICAL STEEL : GRAIN-ORIENTED"
    },
    {
      "code": "722619",
      "description": "Other"
    },
    {
      "code": "72261910",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM- OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - HOT ROLLED"
    },
    {
      "code": "72261920",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM- OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - COLD ROLLED"
    },
    {
      "code": "72261990",
      "description": "FLAT ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM- OF SILICON-ELECTRICAL STEEL - OTHER THAN GRAIN ORIENTED - OTHER THAN HOT ROLLED OR COLD ROLLED"
    },
    {
      "code": "722620",
      "description": "Of high speed steel"
    },
    {
      "code": "72262011",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF HIGH SPEED STEEL : HOT-ROLLED : IN COILS"
    },
    {
      "code": "72262012",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF HIGH SPEED STEEL : HOT-ROLLED : OTHER"
    },
    {
      "code": "72262021",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF HIGH SPEED STEEL : COLD-ROLLED : IN COILS"
    },
    {
      "code": "72262022",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF HIGH SPEED STEEL : COLD-ROLLED : OTHER"
    },
    {
      "code": "72262030",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OF HIGH SPEED STEEL : HOOPS AND STRIPS"
    },
    {
      "code": "722691",
      "description": "Not further worked than hot-rolled"
    },
    {
      "code": "72269110",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF BELOW 3 MM"
    },
    {
      "code": "72269120",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN HOT-ROLLED : OF A THICKNESS OF 3 MM AND ABOVE BUT NOT EXCEEDING 4.75 MM"
    },
    {
      "code": "72269130",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN HOT-ROLLED : HIGH TENSILE QUALITY OF A THICKNESS OF ABOVE 4.75 MM"
    },
    {
      "code": "72269190",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN HOT-ROLLED : OTHER"
    },
    {
      "code": "722692",
      "description": "Not further worked than cold-rolled (cold- reduced)"
    },
    {
      "code": "72269210",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN COLD-ROLLED (COLDREDUCED): OF A THICKNESS OF BELOW 3 MM"
    },
    {
      "code": "72269220",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN COLD-ROLLED (COLDREDUCED): OF A THICKNESS OF 3 MM AND ABOVE BUT NOT EXCEEDING 4.75 MM"
    },
    {
      "code": "72269230",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: NOT FURTHER WORKED THAN COLD-ROLLED (COLDREDUCED): OF A THICKNESS OF ABOVE 4.75 MM"
    },
    {
      "code": "72269300",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: ELECTROLYTICALLY PLATED OR COATED WITH ZINC"
    },
    {
      "code": "72269400",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHERWISE PLATED OR COATED WITH ZINC"
    },
    {
      "code": "722699",
      "description": "Other"
    },
    {
      "code": "72269910",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : OF A THICKNESS OF ABOVE 4.75 MM"
    },
    {
      "code": "72269920",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : OF A THICKNESS OF ABOVE 3 MM AND BUT NOT EXCEEDING 4.75 MM"
    },
    {
      "code": "72269930",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : OF A THICKNESS OF BELOW 3 MM"
    },
    {
      "code": "72269940",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : SKELP (STRIPS FOR PIPES AND TUBES)"
    },
    {
      "code": "72269951",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : HOOPS AND STRIPS : HOT ROLLED"
    },
    {
      "code": "72269952",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : HOOPS AND STRIPS : COLD ROLLED"
    },
    {
      "code": "72269953",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : HOOPS AND STRIPS : OF SPRING STEEL, OTHER THAN SKELP"
    },
    {
      "code": "72269960",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : HOOPS AND STRIPS : SKELPS AND STRIPS FOR RAZOR BLADES AND SAW BLADES"
    },
    {
      "code": "72269990",
      "description": "FLAT-ROLLED PRODUCTS OF OTHER ALLOY STEEL, OF A WIDTH OF LESS THAN 600 MM - OTHER: OTHER : HOOPS AND STRIPS : OTHER"
    },
    {
      "code": "7227",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL"
    },
    {
      "code": "72271000",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OF HIGH SPEED STEEL"
    },
    {
      "code": "72272000",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OF SILICO-MANGANESE STEEL"
    },
    {
      "code": "722790",
      "description": "Other"
    },
    {
      "code": "72279010",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : VALVE SPRING STEEL QUALITY"
    },
    {
      "code": "72279020",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : OTHER SPRING STEEL QUALITY"
    },
    {
      "code": "72279030",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : BALL BEARING QUALITY"
    },
    {
      "code": "72279040",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : COLD HEADING QUALITY"
    },
    {
      "code": "72279050",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : LEAD-FREE CUTTING QUALITY"
    },
    {
      "code": "72279060",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : SULPHUR FREE CUTTING QUALITY"
    },
    {
      "code": "72279090",
      "description": "BARS AND RODS, HOT-ROLLED, IN IRREGULARLY WOUND COILS, OF OTHER ALLOY STEEL - OTHER : OTHER"
    },
    {
      "code": "7228",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL"
    },
    {
      "code": "722810",
      "description": "Bars and rods, of high speed steel"
    },
    {
      "code": "72281010",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - BARS AND RODS, OF HIGH SPEED STEEL : BRIGHT BARS"
    },
    {
      "code": "72281090",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - BARS AND RODS, OF HIGH SPEED STEEL : OTHER"
    },
    {
      "code": "72282000",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - BARS AND RODS, OF SILICO-MANGANESE STEEL"
    },
    {
      "code": "722830",
      "description": "Other bars and rods, not further worked than hot-rolled, hot-drawn or extruded"
    },
    {
      "code": "72283011",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : BRIGHT BARS : OF ALLOY TOOL STEEL"
    },
    {
      "code": "72283019",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : BRIGHT BARS : OTHER"
    },
    {
      "code": "72283021",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OTHER : LEAD BEARING STEEL"
    },
    {
      "code": "72283022",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OTHER : SPRING STEEL"
    },
    {
      "code": "72283023",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OTHER : SULPHUR BEARING STEEL"
    },
    {
      "code": "72283024",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OTHER : TOOL AND DIE STEEL"
    },
    {
      "code": "72283029",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OTHER : OTHER"
    },
    {
      "code": "72284000",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN FORGED"
    },
    {
      "code": "722850",
      "description": "Other bars and rods, not further worked than cold-formed or cold-finished"
    },
    {
      "code": "72285010",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : OF ENGINE VALVES AND COLD HEADING STEEL"
    },
    {
      "code": "72285090",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS, NOT FURTHER WORKED THAN COLD-FORMED OR COLD-FINISHED : OTHER"
    },
    {
      "code": "722860",
      "description": "Other bars and rods"
    },
    {
      "code": "72286011",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : BRIGHT BARS : OF ALLOY TOOL STEEL"
    },
    {
      "code": "72286012",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : BRIGHT BARS : OTHER"
    },
    {
      "code": "72286091",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : OTHER : LEAD BEARING STEEL"
    },
    {
      "code": "72286092",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : OTHER : SPRING STEEL"
    },
    {
      "code": "72286093",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : OTHER : SULPHUR BEARING STEEL"
    },
    {
      "code": "72286094",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : OTHER : TOOL AND DIE STEEL"
    },
    {
      "code": "72286099",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - OTHER BARS AND RODS : OTHER : OTHER"
    },
    {
      "code": "722870",
      "description": "Angles, shapes and sections"
    },
    {
      "code": "72287011",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS : NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OF 80 MM OR MORE"
    },
    {
      "code": "72287012",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS : NOT FURTHER WORKED THAN HOT-ROLLED, HOT-DRAWN OR EXTRUDED : OF LESS THAN 80 MM"
    },
    {
      "code": "72287021",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS : NOT FURTHER WORKED THAN COLD-FORMED OR COLD FINISHED: OF 80 MM OR MORE"
    },
    {
      "code": "72287022",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - ANGLES, SHAPES AND SECTIONS : NOT FURTHER WORKED THAN COLD-FORMED OR COLD FINISHED: OF LESS THAN 80 MM"
    },
    {
      "code": "722880",
      "description": "Hollow drill bars and rods"
    },
    {
      "code": "72288010",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - HOLLOW DRILL BARS AND RODS : OF ALLOY STEEL"
    },
    {
      "code": "72288020",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - HOLLOW DRILL BARS AND RODS : OF NON ALLOY STEEL, FORGED"
    },
    {
      "code": "72288090",
      "description": "OTHER BARS AND RODS OF OTHER ALLOY STEEL; ANGLES, SHAPES AND SECTIONS, OF OTHER ALLOY STEEL; HOLLOW DRILL BARS AND RODS, OF ALLOY OR NON-ALLOY STEEL - HOLLOW DRILL BARS AND RODS : OTHER"
    },
    {
      "code": "7229",
      "description": "WIRE OF OTHER ALLOY STEEL"
    },
    {
      "code": "72291000",
      "description": "WIRE OF OTHER ALLOY STEEL OF HIGH SPEED STEEL"
    },
    {
      "code": "72292000",
      "description": "WIRE OF OTHER ALLOY STEEL OF SILICO-MANGANESE STEEL"
    },
    {
      "code": "722990",
      "description": "Other"
    },
    {
      "code": "72299011",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : TINNED WIRE"
    },
    {
      "code": "72299012",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : BRONZE COATED WIRE"
    },
    {
      "code": "72299013",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : TRAPEZOIDAL WIRE"
    },
    {
      "code": "72299014",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : HALF ROUND WIRE"
    },
    {
      "code": "72299015",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : CRIMPED WIRE"
    },
    {
      "code": "72299016",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : TINNED WIRE, BRONZE COATED WIRE, TRAPEZOIDAL WIRE, HALF ROUND WIRE, CRIMPED WIRE AND COPPER COATED WIRE, NOT INSULATED : COPPER COATED WIRE"
    },
    {
      "code": "72299021",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : ELECTRODE QUALITY, WIRE ROPE QUALITY AND ACSR QUALITY NOT INSULATED : ELECTRODE QUALITY"
    },
    {
      "code": "72299022",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : ELECTRODE QUALITY, WIRE ROPE QUALITY AND ACSR QUALITY NOT INSULATED : WIRE ROPE QUALITY"
    },
    {
      "code": "72299023",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : ELECTRODE QUALITY, WIRE ROPE QUALITY AND ACSR QUALITY NOT INSULATED : ACSR QUALITY"
    },
    {
      "code": "72299031",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : WIRE (EXCLUDING WIRE TYPE LEAD)"
    },
    {
      "code": "72299032",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : SPRING WIRE"
    },
    {
      "code": "72299033",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : HIGH TENSILE WIRE"
    },
    {
      "code": "72299034",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : HARDENED AND TEMPERED WIRE"
    },
    {
      "code": "72299040",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : OTHER WIRE"
    },
    {
      "code": "72299051",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRES OF CROSS-SECTION : HALF ROUND"
    },
    {
      "code": "72299052",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRES OF CROSS-SECTION : FLAT AND RECTANGULAR"
    },
    {
      "code": "72299053",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRES OF CROSS-SECTION : `L SHAPE"
    },
    {
      "code": "72299054",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRES OF CROSS-SECTION : `Z SHAPE"
    },
    {
      "code": "72299059",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : SHAPED AND PROFILED WIRES OF CROSS-SECTION : OTHER"
    },
    {
      "code": "72299060",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : ELECTRIC RESISTANCE WIRE (INCLUDING ELECTRIC RESISTANCE HEATING WIRE)"
    },
    {
      "code": "72299070",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : CRIMPED WIRE"
    },
    {
      "code": "72299090",
      "description": "WIRE OF OTHER ALLOY STEEL - OTHER : WIRE (EXCLUDING WIRE TYPE LEAD), SPRING, HIGH TENSILE, HARDENED AND TEMPERED WIRES, NOT INSULATED : OTHER"
    },
    {
      "code": "73",
      "description": "Articles of iron or steel"
    },
    {
      "code": "7301",
      "description": "SHEET PILING OF IRON OR STEEL, WHETHER OR NOT DRILLED, PUNCHED OR MADE FROM ASSEMBLED ELEMENTS; WELDED ANGLES, SHAPES AND SECTIONS, OF IRON OR STEEL"
    },
    {
      "code": "73011000",
      "description": "SHEET PILING OF IRON OR STEEL, WHETHER OR NOT DRILLED, PUNCHED OR MADE FROM ASSEMBLED ELEMENTS; WELDED ANGLES, SHAPES AND SECTIONS, OF IRON OR STEEL - SHEET PILING"
    },
    {
      "code": "730120",
      "description": "Angles, shapes and sections"
    },
    {
      "code": "73012010",
      "description": "SHEET PILING OF IRON OR STEEL, WHETHER OR NOT DRILLED, PUNCHED OR MADE FROM ASSEMBLED ELEMENTS; WELDED ANGLES, SHAPES AND SECTIONS, OF IRON OR STEEL - ANGLES, SHAPES AND SECTIONS : STEEL SLOTTED ANGLES"
    },
    {
      "code": "73012090",
      "description": "SHEET PILING OF IRON OR STEEL, WHETHER OR NOT DRILLED, PUNCHED OR MADE FROM ASSEMBLED ELEMENTS; WELDED ANGLES, SHAPES AND SECTIONS, OF IRON OR STEEL - ANGLES, SHAPES AND SECTIONS : OTHER"
    },
    {
      "code": "7302",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH- PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS"
    },
    {
      "code": "730210",
      "description": "Rails"
    },
    {
      "code": "73021010",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- RAILS : FOR RAILWAYS"
    },
    {
      "code": "73021020",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- RAILS : FOR TRAMWAYS"
    },
    {
      "code": "73021090",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- RAILS : OTHER"
    },
    {
      "code": "73023000",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES"
    },
    {
      "code": "73024000",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- FISH-PLATES AND SOLE PLATES"
    },
    {
      "code": "730290",
      "description": "Other"
    },
    {
      "code": "73029010",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- - OTHER : MATERIAL FOR JOINTING OR FIXING RAILS"
    },
    {
      "code": "73029090",
      "description": "RAILWAY OR TRAMWAY TRACK CONSTRUCTION MATERIAL OF IRON OR STEEL, THE FOLLOWING: RAILS, CHECK-RAILS AND RACK RAILS, SWITCH BLADES, CROSSING FROGS, POINT RODS AND OTHER CROSSING PIECES, SLEEPERS (CROSS-TIES), FISH - PLATES, CHAIRS, CHAIR WEDGES, SOLE PLATES (BASE PLATES), RAIL CLIPS, BEDPLATES, TIES AND OTHER MATERIAL SPECIALIZED FOR JOINTING OR FIXING RAILS- - OTHER : OTHER"
    },
    {
      "code": "7303",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON"
    },
    {
      "code": "730300",
      "description": "Tubes, pipes and hollow profiles, of cast iron"
    },
    {
      "code": "73030010",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON - TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON: RAIN WATER PIPE"
    },
    {
      "code": "73030020",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON - TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON: SOIL PIPE"
    },
    {
      "code": "73030030",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON - TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON: SPUN PIPE"
    },
    {
      "code": "73030090",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON - TUBES, PIPES AND HOLLOW PROFILES, OF CAST IRON: OTHER"
    },
    {
      "code": "7304",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - Line pipe of a kind used for oil and gas pipelines"
    },
    {
      "code": "73041011",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : PIPES OF IRON OR STEEL : OF IRON"
    },
    {
      "code": "73041019",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : PIPES OF IRON OR STEEL : OTHER"
    },
    {
      "code": "73041021",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : BLANKS FOR TUBES AND PIPES: OF IRON"
    },
    {
      "code": "73041029",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : BLANKS FOR TUBES AND PIPES: OTHER"
    },
    {
      "code": "730411",
      "description": "Of stainless steel"
    },
    {
      "code": "73041110",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OF STAINLESS STEEL: --- TUBES AND PIPES"
    },
    {
      "code": "73041120",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OF STAINLESS STEEL: --- BLANKS FOR TUBES AND PIPES"
    },
    {
      "code": "73041190",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OF STAINLESS STEEL: --- OTHER"
    },
    {
      "code": "730419",
      "description": "Other"
    },
    {
      "code": "73041910",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OTHER --- TUBES AND PIPES"
    },
    {
      "code": "73041920",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OTHER --- BLANKS FOR TUBES AND PIPES"
    },
    {
      "code": "73041990",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : -- OTHER --- OTHER"
    },
    {
      "code": "73042110",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS : DRILL PIPE : OF IRON"
    },
    {
      "code": "73042190",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS : DRILL PIPE : OTHER"
    },
    {
      "code": "73042200",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- DRILL PIPE OF STAILESS STEEL"
    },
    {
      "code": "730423",
      "description": "Other drill pipe"
    },
    {
      "code": "73042310",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- OTHER DRILL PIPE: --- OF IRON"
    },
    {
      "code": "73042390",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- OTHER DRILL PIPE: --- OTHER"
    },
    {
      "code": "73042400",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- OTHER, OF STAILESS STEEL"
    },
    {
      "code": "730429",
      "description": "Other;"
    },
    {
      "code": "73042910",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- OTHER --- OF IRON"
    },
    {
      "code": "73042990",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - CASING, TUBING AND DRILL PIPE, OF A KIND USED IN DRILLING FOR OIL OR GAS: -- OTHER --- OTHER"
    },
    {
      "code": "730431",
      "description": "Cold-drawn or cold-rolled (cold-reduced)"
    },
    {
      "code": "73043111",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- UP TO 114.3 MM OUTER DIAMETER: ---- OF IRON"
    },
    {
      "code": "73043119",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- UP TO 114.3 MM OUTER DIAMETER: ---- OTHER"
    },
    {
      "code": "73043121",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- ABOVE 114.3 MM BUT UP TO 219.1 MM OUTER DIAMETER: ---- OF IRON"
    },
    {
      "code": "73043129",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- ABOVE 114.3 MM BUT UP TO 219.1 MM  OUTER DIAMETER: ---- OTHER"
    },
    {
      "code": "73043131",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- ABOVE 219.1 MM DIAMETER: ---- OF IRON"
    },
    {
      "code": "73043139",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- COLD-DRAWN OR COLD-ROLLED (COLD-REDUCED): --- ABOVE 219.1 MM DIAMETER: ---- OTHER"
    },
    {
      "code": "730439",
      "description": "Other"
    },
    {
      "code": "73043911",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- UP TO 114.3 MM OUTER DIAMETER -- OF IRON"
    },
    {
      "code": "73043919",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- UP TO 114.3 MM OUTER DIAMETER ---- OTHER"
    },
    {
      "code": "73043921",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- ABOVE 114.3 MM BUT UP TO 219.1 MM OUTER DIAMETER: ---- OF IRON"
    },
    {
      "code": "73043929",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- ABOVE 114.3 MM BUT UP TO 219.1 MM OUTER DIAMETER: ---- OTHER"
    },
    {
      "code": "73043931",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- ABOVE  219.1 MM DIAMETER: ---- OF IRON"
    },
    {
      "code": "73043939",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF IORN OR NON-ALLOY STEEL: -- OTHER --- ABOVE  219.1 MM DIAMETER: ---- OTHER"
    },
    {
      "code": "73044100",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF STAILESS STEEL: -- COLD DRAWN OR COLD-ROLLED (COLD-REDUCED)"
    },
    {
      "code": "73044900",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF STAILESS STEEL: -- OTHER"
    },
    {
      "code": "730451",
      "description": "Cold-drawn or cold rolled (cold-reduced)"
    },
    {
      "code": "73045110",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- COLD DRAWN OR COLD-ROLLED (COLD-REDUCED) --- UP TO 114.3 MM DIAMETER"
    },
    {
      "code": "73045120",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- COLD DRAWN OR COLD-ROLLED (COLD-REDUCED) --- ABOVE 114.3 MM BUT UP TO 219.1 MM OUTER DIAMETER"
    },
    {
      "code": "73045130",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- COLD DRAWN OR COLD-ROLLED (COLD-REDUCED) --- ABOVE 219.1 MM OUTER DIAMETER"
    },
    {
      "code": "730459",
      "description": "Other"
    },
    {
      "code": "73045910",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- OTHER --- UPTO 114.3 MM DIAMETER"
    },
    {
      "code": "73045920",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- OTHER --- ABOVE 114.3 MM BUT UP TO 219.1 MM OUTER DIAMETER"
    },
    {
      "code": "73045930",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL: -- OTHER --- ABOVE 219.1 MM OUTER DIAMETER"
    },
    {
      "code": "73049000",
      "description": "TUBES, PIPES AND HOLLOW PROFILES, SEAMLESS, OF IRON (OTHER THAN CAST IRON) OR STEEL - OTHER"
    },
    {
      "code": "7305",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - Line pipe of a kind used for oil or gas pipelines"
    },
    {
      "code": "730511",
      "description": "Longitudinally submerged arc welded"
    },
    {
      "code": "73051111",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : LONGITUDINALLY SUBMERGED ARC WELDED : GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73051119",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : LONGITUDINALLY SUBMERGED ARC WELDED : GALVANISED PIPES : OTHER"
    },
    {
      "code": "73051121",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : NON-GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73051129",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : NON-GALVANISED PIPES : OTHER"
    },
    {
      "code": "730512",
      "description": "Other, longitudinally welded"
    },
    {
      "code": "73051211",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER, LONGITUDINALLY WELDED : GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73051219",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER, LONGITUDINALLY WELDED : GALVANISED PIPES : OTHER"
    },
    {
      "code": "73051221",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER, LONGITUDINALLY WELDED : NON-GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73051229",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER, LONGITUDINALLY WELDED : NON-GALVANISED PIPES : OTHER"
    },
    {
      "code": "730519",
      "description": "Other"
    },
    {
      "code": "73051911",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER: GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73051919",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER: GALVANISED PIPES : OTHER"
    },
    {
      "code": "73051921",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER: NON-GALVANISED (BLACK) PIPES : OF IRON"
    },
    {
      "code": "73051929",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : OTHER: NON-GALVANISED (BLACK) PIPES : OTHER"
    },
    {
      "code": "730520",
      "description": "Casing of a kind used in drilling for oil or gas"
    },
    {
      "code": "73052010",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - CASING OF A KIND USED IN DRILLING FOR OIL OR GAS : OF IRON"
    },
    {
      "code": "73052090",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - CASING OF A KIND USED IN DRILLING FOR OIL OR GAS : OTHER"
    },
    {
      "code": "730531",
      "description": "Longitudinally welded"
    },
    {
      "code": "73053110",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER, WELDED : LONGITUDINALLY WELDED: OF IRON"
    },
    {
      "code": "73053190",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER, WELDED : LONGITUDINALLY WELDED: OTHER"
    },
    {
      "code": "730539",
      "description": "Other"
    },
    {
      "code": "73053910",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER, WELDED : OTHER : OF IRON"
    },
    {
      "code": "73053990",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER, WELDED : OTHER : OTHER"
    },
    {
      "code": "730590",
      "description": "Other"
    },
    {
      "code": "73059010",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER : HIGH PRESSURE HYDROELECTRIC CONDUITS OF STEEL"
    },
    {
      "code": "73059021",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER : ERW PRECISION TUBES : OF IRON"
    },
    {
      "code": "73059029",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER : ERW PRECISION TUBES : OTHER"
    },
    {
      "code": "73059091",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER : OTHER : OF IRON"
    },
    {
      "code": "73059099",
      "description": "OTHER TUBES AND PIPES (FOR EXAMPLE, WELDED, RIVETED OR SIMILARLY CLOSED), HAVING CIRCULAR CROSS-SECTIONS, THE EXTERNAL DIAMETER OF WHICH EXCEEDS 406.4 MM, OF IRON OR STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7306",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - Line pipe of a kind used for oil or gas pipelines"
    },
    {
      "code": "73061011",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73061019",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : GALVANISED PIPES : OTHER"
    },
    {
      "code": "73061021",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : NON-GALVANISED PIPES : OF IRON"
    },
    {
      "code": "73061029",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINES : NON-GALVANISED PIPES : OTHER"
    },
    {
      "code": "73061100",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINE -- WELDED, OF STAILESS STEEL"
    },
    {
      "code": "730619",
      "description": "Other"
    },
    {
      "code": "73061911",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINE -- OTHER --- GALVANIZED PIPES: ---- OF IRON"
    },
    {
      "code": "73061919",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINE -- OTHER --- GALVANIZED PIPES: ---- OTHER"
    },
    {
      "code": "73061921",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINE -- OTHER --- NON-GALVANIZED PIPES: ---- OF IRON"
    },
    {
      "code": "73061929",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - LINE PIPE OF A KIND USED FOR OIL OR GAS PIPELINE -- OTHER --- NON-GALVANIZED PIPES: ---- OTHER"
    },
    {
      "code": "73062010",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - CASING AND TUBING OF A KIND USED IN DRILLING FOR OIL OR GAS : OF IRON"
    },
    {
      "code": "73062090",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - CASING AND TUBING OF A KIND USED IN DRILLING FOR OIL OR GAS : OTHER"
    },
    {
      "code": "73062100",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - CASING AND TUBING OF A KIND USED IN DRILLING FOR OIL OR GAS -- WELDED, OF STAILESS STEEL"
    },
    {
      "code": "730629",
      "description": "Other"
    },
    {
      "code": "73062911",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - CASING AND TUBING OF A KIND USED IN DRILLING FOR OIL OR GAS -- OTHER ---- OF IRON"
    },
    {
      "code": "73062919",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - CASING AND TUBING OF A KIND USED IN DRILLING FOR OIL OR GAS -- OTHER ---- OTHER"
    },
    {
      "code": "730630",
      "description": "Other, welded, of circular cross-section, of iron or non-alloy steel"
    },
    {
      "code": "73063010",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF CIRCULAR CROSS-SECTION, OF IRON OR NON-ALLOY STEEL: --- OF IRON"
    },
    {
      "code": "73063090",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF CIRCULAR CROSS-SECTION, OF IRON OR NON-ALLOY STEEL: --- OTHER"
    },
    {
      "code": "73064000",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF CIRCULAR CROSS-SECTION, OF STAILESS STEEL"
    },
    {
      "code": "73065000",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF CIRCULAR CROSS-SECTION, OF OTHER ALLOY STEEL"
    },
    {
      "code": "73066010",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF NON-CIRCULAR CROSS-SECTION : OF IRON"
    },
    {
      "code": "73066090",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF NON-CIRCULAR CROSS-SECTION : OTHER"
    },
    {
      "code": "73066100",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF NON-CIRCULAR CROSS-SECTION: -- OF SQUARE OR RECTANGULAR CROSS-SECTION"
    },
    {
      "code": "73066900",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER, WELDED, OF NON-CIRCULAR CROSS-SECTION: -- OF OTHER NON-CIRCULAR CROSS-SECTION"
    },
    {
      "code": "730690",
      "description": "Other"
    },
    {
      "code": "73069011",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER -- ERW PRECISION TUBES: ---- OF IRON"
    },
    {
      "code": "73069019",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER -- ERW PRECISION TUBES: ---- OTHER"
    },
    {
      "code": "73069090",
      "description": "OTHER TUBES, PIPES AND HOLLOW PROFILES (FOR EXAMPLE, OPEN SEAM OR WELDED, RIVETED OR SIMILARLY CLOSED), OF IRON OR STEEL - OTHER -- ERW PRECISION TUBES: --- OTHER"
    },
    {
      "code": "7307",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - Cast fittings"
    },
    {
      "code": "730711",
      "description": "Of non-malleable cast iron"
    },
    {
      "code": "73071110",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - CAST FITTINGS : OF NON-MALLEABLE CAST IRON : SPONGE IRON CAST FITTINGS"
    },
    {
      "code": "73071120",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - CAST FITTINGS : OF NON-MALLEABLE CAST IRON : SG IRON CAST FITTINGS"
    },
    {
      "code": "73071190",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - CAST FITTINGS : OF NON-MALLEABLE CAST IRON : OTHER"
    },
    {
      "code": "73071900",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - CAST FITTINGS : OTHER"
    },
    {
      "code": "73072100",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER, OF STAINLESS STEEL : FLANGES"
    },
    {
      "code": "73072200",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER, OF STAINLESS STEEL : THREADED ELBOWS, BENDS AND SLEEVES"
    },
    {
      "code": "73072300",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER, OF STAINLESS STEEL : BUTT WELDING FITTINGS"
    },
    {
      "code": "73072900",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER, OF STAINLESS STEEL : OTHER"
    },
    {
      "code": "730791",
      "description": "Flanges"
    },
    {
      "code": "73079110",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : FLANGES : GALVANISED"
    },
    {
      "code": "73079190",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : FLANGES : OTHER"
    },
    {
      "code": "730792",
      "description": "Threaded elbows, bends and sleeves"
    },
    {
      "code": "73079210",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : THREADED ELBOWS, BENDS AND SLEEVES : GALVANISED"
    },
    {
      "code": "73079290",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : THREADED ELBOWS, BENDS AND SLEEVES : OTHER"
    },
    {
      "code": "730793",
      "description": "Butt welding fittings"
    },
    {
      "code": "73079310",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : BUTT WELDING FITTINGS : GALVANISED"
    },
    {
      "code": "73079390",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : BUTT WELDING FITTINGS : OTHER"
    },
    {
      "code": "730799",
      "description": "Other"
    },
    {
      "code": "73079910",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : OTHER : GALVANISED"
    },
    {
      "code": "73079990",
      "description": "TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES), OF IRON OR STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7308",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK- GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL"
    },
    {
      "code": "73081000",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - BRIDGES AND BRIDGE-SECTIONS"
    },
    {
      "code": "730820",
      "description": "Towers and lattice masts"
    },
    {
      "code": "73082011",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - TOWERS AND LATTICE MASTS : TOWERS, WHETHER OR NOT ASSEMBLED : FOR TRANSMISSION LINE"
    },
    {
      "code": "73082019",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - TOWERS AND LATTICE MASTS : TOWERS, WHETHER OR NOT ASSEMBLED : OTHER"
    },
    {
      "code": "73082020",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - TOWERS AND LATTICE MASTS : LATTICE MASTS"
    },
    {
      "code": "73083000",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - DOORS, WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS"
    },
    {
      "code": "73084000",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - EQUIPMENT FOR SCAFFOLDING, SHUTTERING, PROPPING OR PIT-PROPPING"
    },
    {
      "code": "730890",
      "description": "Other"
    },
    {
      "code": "73089010",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : BEAMS, CHANNELS, PILLARS AND GIRDERS PREPARED FOR USE IN STRUCTURES"
    },
    {
      "code": "73089020",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : DROP RODS"
    },
    {
      "code": "73089030",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : HATCHWAY, RAILS AND BULKHEADS FOR SHIPS OR BOATS AND PARTS OF HULL"
    },
    {
      "code": "73089040",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : GALVANISED TENSION BARS"
    },
    {
      "code": "73089050",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : STRUCTURES AND SUPER STRUCTURES FOR MINING"
    },
    {
      "code": "73089060",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : TRUSS RODS"
    },
    {
      "code": "73089070",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : TUBULAR STEEL POLES FOR ELECTRIC TRANSMISSION AND DISTRIBUTION LINES"
    },
    {
      "code": "73089090",
      "description": "STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, LOCK-GATES, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAME-WORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, SHUTTERS, BALUSTRADES, PILLARS AND COLUMNS), OF IRON OR STEEL; PLATES, RODS, ANGLES, SHAPES, SECTIONS, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES, OF IRON OR STEEL - OTHER : OTHER"
    },
    {
      "code": "7309",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT"
    },
    {
      "code": "730900",
      "description": "Reservoirs, tanks, vats and similar containers for any material (other than compressed or liquefied gas), of iron or steel, of a capacity exceeding 300 l, whether or not lined or heat-insulated, but not fitted with mechanical or thermal equipment"
    },
    {
      "code": "73090010",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT : GALVANIZED IRON TANKS"
    },
    {
      "code": "73090020",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT : GALVANIZED IRON BARRELS AND DRUMS"
    },
    {
      "code": "73090030",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT : PRESSED STEEL TANKS"
    },
    {
      "code": "73090040",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT : PRESSURE VESSELS"
    },
    {
      "code": "73090090",
      "description": "RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT : OTHER"
    },
    {
      "code": "7310",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT"
    },
    {
      "code": "731010",
      "description": "Of a capacity of 50 l or more"
    },
    {
      "code": "73101010",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT- OF A CAPACITY OF 50 L OR MORE : TIN PLATE CONTAINERS"
    },
    {
      "code": "73101020",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT- OF A CAPACITY OF 50 L OR MORE : TRUNKS AND CASES"
    },
    {
      "code": "73101090",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT- OF A CAPACITY OF 50 L OR MORE : OTHER"
    },
    {
      "code": "731021",
      "description": "Cans which are to be closed by soldering or crimping"
    },
    {
      "code": "73102110",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OF A CAPACITY OF LESS THAN 50 L : CANS WHICH ARE TO BE CLOSED BY SOLDERING OR CRIMPING : TIN PLATE CONTAINERS"
    },
    {
      "code": "73102190",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OF A CAPACITY OF LESS THAN 50 L : CANS WHICH ARE TO BE CLOSED BY SOLDERING OR CRIMPING : OTHER"
    },
    {
      "code": "731029",
      "description": "Other"
    },
    {
      "code": "73102910",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OF A CAPACITY OF LESS THAN 50 L : OTHER : TIN PLATE CONTAINERS"
    },
    {
      "code": "73102920",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OF A CAPACITY OF LESS THAN 50 L : OTHER : TRUNKS AND CASES"
    },
    {
      "code": "73102990",
      "description": "TANKS, CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF IRON OR STEEL, OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEATINSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OF A CAPACITY OF LESS THAN 50 L : OTHER : OTHER"
    },
    {
      "code": "7311",
      "description": "CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL"
    },
    {
      "code": "731100",
      "description": "Containers for compressed or liquefied gas, of iron or steel"
    },
    {
      "code": "73110010",
      "description": "CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL - CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL : LIQUEFIED PETROLEUM GAS (LPG) CYLINDER"
    },
    {
      "code": "73110020",
      "description": "CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL - CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL : LOW PRESSURE CYLINDER (WORKING PRESSURE UP TO 35.2 KG/SQ.CM OTHER THAN LPG)"
    },
    {
      "code": "73110030",
      "description": "CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL - CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL : HIGH PRESSURE CYLINDER (WORKING PRESSURE EXCEEDING 35.2 KG/SQ.CM)"
    },
    {
      "code": "73110090",
      "description": "CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL - CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS, OF IRON OR STEEL : OTHER"
    },
    {
      "code": "7312",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED"
    },
    {
      "code": "731210",
      "description": "Stranded wire, ropes and cables"
    },
    {
      "code": "73121010",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED - STRANDED WIRE, ROPES AND CABLES : WIRE ROPES, BLACK"
    },
    {
      "code": "73121020",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED - STRANDED WIRE, ROPES AND CABLES : WIRE ROPES, GALVANISED"
    },
    {
      "code": "73121030",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED - STRANDED WIRE, ROPES AND CABLES : STRANDED WIRE"
    },
    {
      "code": "73121090",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED - STRANDED WIRE, ROPES AND CABLES : OTHER"
    },
    {
      "code": "73129000",
      "description": "STRANDED WIRE, ROPES, CABLES, PLAITED BANDS, SLINGS AND THE LIKE, OF IRON OR STEEL, NOT ELECTRICALLY INSULATED - OTHER"
    },
    {
      "code": "7313",
      "description": "BARBED WIRE OF IRON OR STEEL; TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING, OF IRON OR STEEL"
    },
    {
      "code": "731300",
      "description": "Barbed wire of iron or steel; twisted hoop or single flat wire, barbed or not, and loosely twisted double wire, of a kind used for fencing, of iron or steel"
    },
    {
      "code": "73130010",
      "description": "BARBED WIRE OF IRON OR STEEL; TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING, OF IRON OR STEEL - BARBED WIRE OF IRON OR STEEL; TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING, OF IRON OR STEEL : BARBED WIRE"
    },
    {
      "code": "73130020",
      "description": "BARBED WIRE OF IRON OR STEEL; TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING, OF IRON OR STEEL - BARBED WIRE OF IRON OR STEEL; TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING, OF IRON OR STEEL : TWISTED HOOP OR SINGLE FLAT WIRE, BARBED OR NOT, AND LOOSELY TWISTED DOUBLE WIRE, OF A KIND USED FOR FENCING"
    },
    {
      "code": "7314",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - Woven cloth"
    },
    {
      "code": "73141200",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : ENDLESS BANDS FOR MACHINERY, OF STAINLESS STEEL"
    },
    {
      "code": "73141300",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : OTHER ENDLESS BANDS FOR MACHINERY"
    },
    {
      "code": "731414",
      "description": "Other woven cloth, of stainless steel"
    },
    {
      "code": "73141410",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : OTHER WOVEN CLOTH, OF STAINLESS STEEL : WIRE GAUZE (WIRE CLOTH, WIRE MESH)"
    },
    {
      "code": "73141490",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : OTHER WOVEN CLOTH, OF STAINLESS STEEL : OTHER"
    },
    {
      "code": "731419",
      "description": "Other"
    },
    {
      "code": "73141910",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : OTHER : WIRE GAUZE (WIRE CLOTH, WIRE MESH)"
    },
    {
      "code": "73141990",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - WOVEN CLOTH : OTHER : OTHER"
    },
    {
      "code": "731420",
      "description": "Grill, netting and fencing, welded at the intersection, of wire with a maximum crosssectional dimension of 3 mm or more and having a mesh size of 100 cm2 or more"
    },
    {
      "code": "73142010",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - GRILL, NETTING AND FENCING, WELDED AT THE INTERSECTION, OF WIRE WITH A MAXIMUM CROSSSECTIONAL DIMENSION OF 3 MM OR MORE AND HAVING A MESH SIZE OF 100 CM2 OR MORE :WIRE NETTING"
    },
    {
      "code": "73142090",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - GRILL, NETTING AND FENCING, WELDED AT THE INTERSECTION, OF WIRE WITH A MAXIMUM CROSSSECTIONAL DIMENSION OF 3 MM OR MORE AND HAVING A MESH SIZE OF 100 CM2 OR MORE :OTHER"
    },
    {
      "code": "73143100",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER GRILL, NETTING AND FENCING, WELDED AT THE INTERSECTION : PLATED OR COATED WITH ZINC"
    },
    {
      "code": "73143900",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER GRILL, NETTING AND FENCING, WELDED AT THE INTERSECTION : OTHER"
    },
    {
      "code": "731441",
      "description": "Plated or coated with zinc"
    },
    {
      "code": "73144110",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : PLATED OR COATED WITH ZINC : WIRE NETTING"
    },
    {
      "code": "73144190",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : PLATED OR COATED WITH ZINC : OTHER"
    },
    {
      "code": "731442",
      "description": "Coated with plastics"
    },
    {
      "code": "73144210",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : COATED WITH PLASTICS : WIRE NETTING"
    },
    {
      "code": "73144290",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : COATED WITH PLASTICS : OTHER"
    },
    {
      "code": "731449",
      "description": "Other"
    },
    {
      "code": "73144910",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : OTHER : WIRE NETTING"
    },
    {
      "code": "73144990",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - OTHER CLOTH, GRILL, NETTING AND FENCING : OTHER : OTHER"
    },
    {
      "code": "73145000",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL, NETTING AND FENCING, OF IRON OR STEEL WIRE; EXPANDED METAL OF IRON OR STEEL - EXPANDED METAL"
    },
    {
      "code": "7315",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL"
    },
    {
      "code": "73151100",
      "description": "ROLLER CHAIN"
    },
    {
      "code": "731512",
      "description": "Other chain"
    },
    {
      "code": "73151210",
      "description": "LIFTING AND HOISTING CHAIN"
    },
    {
      "code": "73151220",
      "description": "SHIP CHAIN"
    },
    {
      "code": "73151290",
      "description": "OTHER"
    },
    {
      "code": "73151900",
      "description": "PARTS"
    },
    {
      "code": "73152000",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL - ARTICULATED LINK CHAIN AND PARTS THEREOF : SKID CHAIN"
    },
    {
      "code": "73158100",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL - OTHER CHAIN : STUD-LINK"
    },
    {
      "code": "73158200",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL - OTHER CHAIN : OTHER, WELDED LINK"
    },
    {
      "code": "73158900",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL - OTHER CHAIN : OTHER"
    },
    {
      "code": "73159000",
      "description": "CHAIN AND PARTS THEREOF, OF IRON OR STEEL - OTHER PARTS"
    },
    {
      "code": "7316",
      "description": "ANCHORS, GRAPNELS AND PARTS THEREOF, OF IRON OR STEEL"
    },
    {
      "code": "731600",
      "description": "Anchors, grapnels and parts thereof, of iron or steel"
    },
    {
      "code": "73160010",
      "description": "ANCHORS, GRAPNELS AND PARTS THEREOF, OF IRON OR STEEL - ANCHORS, GRAPNELS AND PARTS THEREOF, OF IRON OR STEEL : ANCHORS AND GRAPNELS"
    },
    {
      "code": "73160090",
      "description": "ANCHORS, GRAPNELS AND PARTS THEREOF, OF IRON OR STEEL - ANCHORS, GRAPNELS AND PARTS THEREOF, OF IRON OR STEEL : PARTS"
    },
    {
      "code": "7317",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER"
    },
    {
      "code": "731700",
      "description": "Nails, tacks, drawing pins, corrugated nails, staples (other than those of heading 8305) and similar articles, of iron or steel, whether or not with heads of other material, but excluding such articles with heads of copper"
    },
    {
      "code": "73170011",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : NAILS : FOR ANIMAL SHOES"
    },
    {
      "code": "73170012",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : NAILS : FOR FIXING HEEL STRIPS AND TOE PLATES"
    },
    {
      "code": "73170013",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : NAILS : WIRE NAILS"
    },
    {
      "code": "73170019",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : NAILS : OTHER"
    },
    {
      "code": "73170021",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : SPIKES : DOG SPIKES"
    },
    {
      "code": "73170029",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : SPIKES : OTHER"
    },
    {
      "code": "73170030",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : TACKS"
    },
    {
      "code": "73170091",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : OTHER : STAPLES OTHER THAN IN STRIPS, AND DRAWING PINS"
    },
    {
      "code": "73170099",
      "description": "NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER - NAILS, TACKS, DRAWING PINS, CORRUGATED NAILS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF IRON OR STEEL, WHETHER OR NOT WITH HEADS OF OTHER MATERIAL, BUT EXCLUDING SUCH ARTICLES WITH HEADS OF COPPER : OTHER : OTHER"
    },
    {
      "code": "7318",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES,OF IRON OR STEEL - Threaded articles"
    },
    {
      "code": "731811",
      "description": "Coach screws"
    },
    {
      "code": "73181110",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : COACH SCREWS : MACHINE SCREWS"
    },
    {
      "code": "73181190",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : COACH SCREWS : OTHER"
    },
    {
      "code": "73181200",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : OTHER WOOD SCREWS"
    },
    {
      "code": "73181300",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : SCREW HOOKS AND SCREW RINGS"
    },
    {
      "code": "73181400",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : SELF-TAPPING SCREWS"
    },
    {
      "code": "73181500",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : OTHER SCREWS AND BOLTS, WHETHER OR NOT WITH THEIR NUTS OR WASHERS"
    },
    {
      "code": "73181600",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : NUTS"
    },
    {
      "code": "73181900",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - THREADED ARTICLES : OTHER"
    },
    {
      "code": "73182100",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : SPRING WASHERS AND OTHER LOCK WASHERS"
    },
    {
      "code": "73182200",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : OTHER WASHERS"
    },
    {
      "code": "73182300",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : RIVETS"
    },
    {
      "code": "73182400",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : COTTERS AND COTTER-PINS"
    },
    {
      "code": "731829",
      "description": "Other"
    },
    {
      "code": "73182910",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : OTHER : CIRCLIPS"
    },
    {
      "code": "73182990",
      "description": "SCREWS, BOLTS, NUTS, COACH-SCREWS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF IRON OR STEEL - NON-THREADED ARTICLES : OTHER : OTHER"
    },
    {
      "code": "7319",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "73191010",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - SEWING, DARNING OR EMBROIDERY NEEDLES : HARNESS NEEDLES"
    },
    {
      "code": "73191020",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - SEWING, DARNING OR EMBROIDERY NEEDLES : OTHER SEWING NEEDLES"
    },
    {
      "code": "73191090",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - SEWING, DARNING OR EMBROIDERY NEEDLES : OTHER"
    },
    {
      "code": "731940",
      "description": "Safety pings and other pings"
    },
    {
      "code": "73194010",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAFETY PINS AND OTHER PINS --- SAFETY PINS"
    },
    {
      "code": "73194090",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAFETY PINS AND OTHER PINS --- OTHER PINS"
    },
    {
      "code": "73199000",
      "description": "SEWING NEEDLES, KNITTING NEEDLES, BODKINS, CROCHET HOOKS, EMBROIDERY STILETTOS AND SIMILAR ARTICLES, FOR USE IN THE HAND, OF IRON OR STEEL; SAFETY PINS AND OTHER PINS, OF IRON OR STEEL, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER"
    },
    {
      "code": "7320",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL"
    },
    {
      "code": "732010",
      "description": "Leaf-springs and leaves therefor"
    },
    {
      "code": "73201011",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - LEAF-SPRINGS AND LEAVES THEREFOR : LEAF-SPRINGS : FOR MOTOR VEHICLES"
    },
    {
      "code": "73201012",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - LEAF-SPRINGS AND LEAVES THEREFOR : LEAF-SPRINGS : FOR RAILWAYS AND TRAMWAYS"
    },
    {
      "code": "73201019",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - LEAF-SPRINGS AND LEAVES THEREFOR : LEAF-SPRINGS : OTHER"
    },
    {
      "code": "73201020",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - LEAF-SPRINGS AND LEAVES THEREFOR: -- LEAF SPRINGS --- LEAVES FOR SPRINGS"
    },
    {
      "code": "73202000",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - HELICAL SPRINGS"
    },
    {
      "code": "732090",
      "description": "Other"
    },
    {
      "code": "73209010",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - OTHER : COIL SPRING FOR RAILWAYS, TRAMWAYS"
    },
    {
      "code": "73209020",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - OTHER : SPRING PINS"
    },
    {
      "code": "73209090",
      "description": "SPRINGS AND LEAVES FOR SPRINGS, OF IRON OR STEEL - OTHER : OTHER"
    },
    {
      "code": "7321",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - Cooking appliances and plate warmers"
    },
    {
      "code": "732111",
      "description": "For gas fuel or for both gas and other fuels"
    },
    {
      "code": "73211110",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR GAS FUEL OR FOR BOTH GAS AND OTHER FUELS : COOKERS AND KITCHEN STOVES"
    },
    {
      "code": "73211120",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR GAS FUEL OR FOR BOTH GAS AND OTHER FUELS : OTHER STOVES"
    },
    {
      "code": "73211190",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR GAS FUEL OR FOR BOTH GAS AND OTHER FUELS : OTHER"
    },
    {
      "code": "732112",
      "description": "For liquid fuel"
    },
    {
      "code": "73211210",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR LIQUID FUEL: COOKERS AND KITCHEN STOVES"
    },
    {
      "code": "73211220",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR LIQUID FUEL: OTHER STOVES"
    },
    {
      "code": "73211290",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR LIQUID FUEL: OTHER"
    },
    {
      "code": "73211310",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR SOLID FUEL: COOKERS AND KITCHEN STOVES"
    },
    {
      "code": "73211320",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR SOLID FUEL: OTHER STOVES"
    },
    {
      "code": "73211390",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: FOR SOLID FUEL: OTHER"
    },
    {
      "code": "732119",
      "description": "Other, including appliances for solid fuel"
    },
    {
      "code": "73211910",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: -- OTHER, INCLUDING APPLIANCES FOR SOLID FUEL --- COOKERS AND KITCHEN STOVES"
    },
    {
      "code": "73211990",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: -- OTHER, INCLUDING APPLIANCES FOR SOLID FUEL --- OTHER STOVES AND APPLIANCES"
    },
    {
      "code": "73218100",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - OTHER APPLIANCES: FOR GAS FUEL OR FOR BOTH GAS AND OTHER FUELS"
    },
    {
      "code": "73218200",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - OTHER APPLIANCES: FOR LIQUID FUEL"
    },
    {
      "code": "73218310",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - OTHER APPLIANCES: FOR SOLID FUEL : CLAY TANDOOR (OVEN WITH IRON OR STEEL BODY AND EARTHEN GRATES)"
    },
    {
      "code": "73218390",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - OTHER APPLIANCES: FOR SOLID FUEL : OTHER"
    },
    {
      "code": "732189",
      "description": "Other, including appliances for solid fuel"
    },
    {
      "code": "73218910",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: -- OTHER, INCLUDING APPLIANCES FOR SOLID FUEL --- CLAY TANDOOR (OVEN WITH IRON OR STEEL BODY AND EARTHEN GRATES)"
    },
    {
      "code": "73218990",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - COOKING APPLIANCES AND PLATE WARMERS: -- OTHER, INCLUDING APPLIANCES FOR SOLID FUEL --- OTHER"
    },
    {
      "code": "73219000",
      "description": "STOVES, RANGES, GRATES, COOKERS (INCLUDING THOSE WITH SUBSIDIARY BOILERS FOR CENTRAL HEATING), BARBECUES, BRAZIERS, GAS-RINGS, PLATE WARMERS AND SIMILAR NON-ELECTRIC DOMESTIC APPLIANCES, AND PARTS THEREOF, OF IRON OR STEEL - PARTS"
    },
    {
      "code": "7322",
      "description": "RADIATORS FOR CENTRAL HEATING, NOT ELECTRICALLY HEATED, AND PARTS THEREOF, OF IRON OR STEEL; AIR HEATERS AND HOT AIR DISTRIBUTORS (INCLUDING DISTRIBUTORS WHICH CAN ALSO DISTRIBUTE FRESH OR CONDITIONED AIR), NOT ELECTRICALLY HEATED, INCORPORATING A MOTORDRIVEN FAN OR BLOWER, AND PARTS THEREOF, OF IRON OR STEEL - Radiators and parts thereof"
    },
    {
      "code": "73221100",
      "description": "RADIATORS FOR CENTRAL HEATING, NOT ELECTRICALLY HEATED, AND PARTS THEREOF, OF IRON OR STEEL; AIR HEATERS AND HOT AIR DISTRIBUTORS (INCLUDING DISTRIBUTORS WHICH CAN ALSO DISTRIBUTE FRESH OR CONDITIONED AIR), NOT ELECTRICALLY HEATED, INCORPORATING A MOTOR-DRIVEN FAN OR BLOWER, AND PARTS THEREOF, OF IRON OR STEEL - RADIATORS AND PARTS THEREOF : OF CAST IRON"
    },
    {
      "code": "73221900",
      "description": "RADIATORS FOR CENTRAL HEATING, NOT ELECTRICALLY HEATED, AND PARTS THEREOF, OF IRON OR STEEL; AIR HEATERS AND HOT AIR DISTRIBUTORS (INCLUDING DISTRIBUTORS WHICH CAN ALSO DISTRIBUTE FRESH OR CONDITIONED AIR), NOT ELECTRICALLY HEATED, INCORPORATING A MOTOR-DRIVEN FAN OR BLOWER, AND PARTS THEREOF, OF IRON OR STEEL - RADIATORS AND PARTS THEREOF : OTHER"
    },
    {
      "code": "732290",
      "description": "Other"
    },
    {
      "code": "73229010",
      "description": "RADIATORS FOR CENTRAL HEATING, NOT ELECTRICALLY HEATED, AND PARTS THEREOF, OF IRON OR STEEL; AIR HEATERS AND HOT AIR DISTRIBUTORS (INCLUDING DISTRIBUTORS WHICH CAN ALSO DISTRIBUTE FRESH OR CONDITIONED AIR), NOT ELECTRICALLY HEATED, INCORPORATING A MOTOR-DRIVEN FAN OR BLOWER, AND PARTS THEREOF, OF IRON OR STEEL - OTHER : AIR HEATERS AND HOT AIR DISTRIBUTORS"
    },
    {
      "code": "73229090",
      "description": "RADIATORS FOR CENTRAL HEATING, NOT ELECTRICALLY HEATED, AND PARTS THEREOF, OF IRON OR STEEL; AIR HEATERS AND HOT AIR DISTRIBUTORS (INCLUDING DISTRIBUTORS WHICH CAN ALSO DISTRIBUTE FRESH OR CONDITIONED AIR), NOT ELECTRICALLY HEATED, INCORPORATING A MOTOR-DRIVEN FAN OR BLOWER, AND PARTS THEREOF, OF IRON OR STEEL - OTHER : PARTS OF AIR HEATERS AND HOT AIR DISTRIBUTORS"
    },
    {
      "code": "7323",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL"
    },
    {
      "code": "73231000",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE"
    },
    {
      "code": "732391",
      "description": "Of cast iron, not enamelled"
    },
    {
      "code": "73239110",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF CAST IRON, NOT ENAMELLED : PANS"
    },
    {
      "code": "73239190",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF CAST IRON, NOT ENAMELLED : OTHER"
    },
    {
      "code": "73239200",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF CAST IRON, ENAMELLED"
    },
    {
      "code": "732393",
      "description": "Of stainless steel"
    },
    {
      "code": "73239310",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF STAINLESS STEEL : PRESSURE COOKERS"
    },
    {
      "code": "73239390",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF STAINLESS STEEL : OTHER"
    },
    {
      "code": "732394",
      "description": "Of iron (other than cast iron) or steel, enamelled"
    },
    {
      "code": "73239410",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF IRON (OTHER THAN CAST IRON) OR STEEL, ENAMELLED : GHAMELLAS"
    },
    {
      "code": "73239420",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF IRON (OTHER THAN CAST IRON) OR STEEL, ENAMELLED : UTENSILS"
    },
    {
      "code": "73239490",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OF IRON (OTHER THAN CAST IRON) OR STEEL, ENAMELLED : OTHER"
    },
    {
      "code": "732399",
      "description": "Other"
    },
    {
      "code": "73239910",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OTHER : UTENSILS OF GALVANISED IRON"
    },
    {
      "code": "73239920",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OTHER : OTHER UTENSILS"
    },
    {
      "code": "73239990",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF IRON OR STEEL; IRON OR STEEL WOOL; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF IRON OR STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "7324",
      "description": "SANITARY WARE AND PARTS THEREOF, OF IRON OR STEEL"
    },
    {
      "code": "73241000",
      "description": "SANITARY WARE AND PARTS THEREOF, OF IRON OR STEEL - SINKS AND WASH BASINS, OF STAINLESS STEEL"
    },
    {
      "code": "73242100",
      "description": "SANITARY WARE AND PARTS THEREOF, OF IRON OR STEEL - BATHS : OF CAST IRON, WHETHER OR NOT ENAMELLED"
    },
    {
      "code": "73242900",
      "description": "SANITARY WARE AND PARTS THEREOF, OF IRON OR STEEL - BATHS : OTHER"
    },
    {
      "code": "73249000",
      "description": "SANITARY WARE AND PARTS THEREOF, OF IRON OR STEEL - OTHER, INCLUDING PARTS"
    },
    {
      "code": "7325",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL"
    },
    {
      "code": "73251000",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OF NON-MALLEABLE CAST IRON"
    },
    {
      "code": "73259100",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : GRINDING BALLS AND SIMILAR ARTICLES FOR MILLS"
    },
    {
      "code": "732599",
      "description": "Other"
    },
    {
      "code": "73259910",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OF IRON"
    },
    {
      "code": "73259920",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OF ALLOY STEEL"
    },
    {
      "code": "73259930",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OF STAINLESS STEEL"
    },
    {
      "code": "73259991",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OTHER : RUDDERS FOR SHIPS OR BOATS"
    },
    {
      "code": "73259992",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OTHER : DRAIN COVERS"
    },
    {
      "code": "73259993",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OTHER : PLATES AND FRAMES FOR SEWAGE WATER OR SIMILAR SYSTEM"
    },
    {
      "code": "73259999",
      "description": "OTHER CAST ARTICLES OF IRON OR STEEL - OTHER : OTHER : OTHER : OTHER"
    },
    {
      "code": "7326",
      "description": "OTHER ARTICLES OF IRON OR STEEL - Forged or stamped, but not further worked"
    },
    {
      "code": "73261100",
      "description": "OTHER ARTICLES OF IRON OR STEEL - FORGED OR STAMPED, BUT NOT FURTHER WORKED : GRINDING BALLS AND SIMILAR ARTICLES FOR MILLS"
    },
    {
      "code": "732619",
      "description": "Other"
    },
    {
      "code": "73261910",
      "description": "OTHER ARTICLES OF IRON OR STEEL - FORGED OR STAMPED, BUT NOT FURTHER WORKED : OTHER : FOR AUTOMOBILES AND EARTH MOVING EQUIPMENTS"
    },
    {
      "code": "73261990",
      "description": "OTHER ARTICLES OF IRON OR STEEL - FORGED OR STAMPED, BUT NOT FURTHER WORKED : OTHER : OTHER"
    },
    {
      "code": "732620",
      "description": "Articles of iron or steel wire"
    },
    {
      "code": "73262010",
      "description": "OTHER ARTICLES OF IRON OR STEEL - ARTICLES OF IRON OR STEEL WIRE : TYRE BEAD WIRE RINGS INTENDED FOR USE IN THE MANUFACTURE OF TYRES FOR CYCLES AND CYCLERICKSHAWS"
    },
    {
      "code": "73262090",
      "description": "OTHER ARTICLES OF IRON OR STEEL - ARTICLES OF IRON OR STEEL WIRE : OTHER"
    },
    {
      "code": "732690",
      "description": "Other"
    },
    {
      "code": "73269010",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER :BELT LACING OF STEEL"
    },
    {
      "code": "73269020",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER :BELT FASTENERS FOR MACHINERY BELTS"
    },
    {
      "code": "73269030",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER :DRAIN COVERS, PLATES, AND FRAMES FOR SEWAGES, WATER OR SIMILAR SYSTEM"
    },
    {
      "code": "73269040",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER : ENAMELLED IRON WARE"
    },
    {
      "code": "73269050",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER : GRINDING MEDIA BALLS AND CYLPEBS"
    },
    {
      "code": "73269060",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER : MANUFACTURES OF STAINLESS STEEL"
    },
    {
      "code": "73269070",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER :ARTICLES OF CLAD METAL"
    },
    {
      "code": "73269080",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER :PARTS OF SHIPS, FLOATING STRUCTURE AND VESSELS (EXCLUDING HULL, PROPELLERS AND PADDLE-WHEELS)"
    },
    {
      "code": "73269091",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER : OTHER : SHANKS"
    },
    {
      "code": "73269099",
      "description": "OTHER ARTICLES OF IRON OR STEEL - OTHER : OTHER : OTHER"
    },
    {
      "code": "74",
      "description": "Copper and articles thereof"
    },
    {
      "code": "7401",
      "description": "COPPER MATTES; CEMENT COPPER (PRECIPITATED COPPER)"
    },
    {
      "code": "740100",
      "description": "Copper mattes; Cement copper (precipitated copper)"
    },
    {
      "code": "74010010",
      "description": "COPPER MATTES; CEMENT COPPER (PRECIPITATED COPPER) - COPPER MATTES"
    },
    {
      "code": "74010090",
      "description": "COPPER MATTES; CEMENT COPPER (PRECIPITATED COPPER) - CEMENT COPPER (PRECIPITATED COPPER)"
    },
    {
      "code": "74011000",
      "description": "COPPER MATTES; CEMENT COPPER (PRECIPITATED COPPER) - COPPER MATTES"
    },
    {
      "code": "74012000",
      "description": "COPPER MATTES; CEMENT COPPER (PRECIPITATED COPPER) - CEMENT COPPER (PRECIPITATED COPPER)"
    },
    {
      "code": "7402",
      "description": "UNREFINED COPPER; COPPER ANODES FOR ELECTROLYTIC REFINING"
    },
    {
      "code": "740200",
      "description": "Unrefined copper; copper anodes for electrolytic refining"
    },
    {
      "code": "74020010",
      "description": "UNREFINED COPPER; COPPER ANODES FOR ELECTROLYTIC REFINING - UNREFINED COPPER; COPPER ANODES FOR ELECTROLYTIC REFINING : BLISTER COPPER"
    },
    {
      "code": "74020090",
      "description": "UNREFINED COPPER; COPPER ANODES FOR ELECTROLYTIC REFINING - UNREFINED COPPER; COPPER ANODES FOR ELECTROLYTIC REFINING : OTHER"
    },
    {
      "code": "7403",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - Refined copper"
    },
    {
      "code": "74031100",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - REFINED COPPER : CATHODES AND SECTIONS OF CATHODES"
    },
    {
      "code": "74031200",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - REFINED COPPER : WIRE-BARS"
    },
    {
      "code": "74031300",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - REFINED COPPER : BILLETS"
    },
    {
      "code": "74031900",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - REFINED COPPER : OTHER"
    },
    {
      "code": "74032100",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : COPPER-ZINC BASE ALLOYS (BRASS)"
    },
    {
      "code": "740322",
      "description": "Copper-tin base alloys (bronze)"
    },
    {
      "code": "74032210",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : COPPER-TIN BASE ALLOYS (BRONZE) : PHOSPHOR BRONZE"
    },
    {
      "code": "74032290",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : COPPER-TIN BASE ALLOYS (BRONZE) : OTHER"
    },
    {
      "code": "74032310",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : COPPER-NICKEL BASE ALLOYS( CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL)"
    },
    {
      "code": "74032320",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : COPPER-NICKEL BASE ALLOYS( CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : COPPER-NICKEL-ZINC BASE ALLOYS(NICKEL SILVER)"
    },
    {
      "code": "74032900",
      "description": "REFINED COPPER AND COPPER ALLOYS, UNWROUGHT - COPPER ALLOYS : OTHER COPPER ALLOYS (OTHER THAN MASTER ALLOYS OF HEADING 7405)"
    },
    {
      "code": "7404",
      "description": "COPPER WASTE AND SCRAP"
    },
    {
      "code": "740400",
      "description": "Copper waste and scrap"
    },
    {
      "code": "74040011",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER : EMPTY OR DISCHARGED CARTRIDGES OF ALL BORES AND SIZES"
    },
    {
      "code": "74040012",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER :COPPER SCRAP, NAMELY THE FOLLOWING : COPPER WIRE SCRAP COVERED BY ISRI CODE WORDS BARLEY, BERRY AND BIRCH; HEAVY COPPER SCRAP COVERED BY ISRI CODE WORD CANDY; UNALLOYED COPPER SCRAP COVERED BY ISRI CODE WORD CLIFF; COPPER WIRE NODULES SCRAP COVERED BY ISRI CODE WORDS CLOVE, COBRA AND COCOA; LIGHT COPPER SCRAP COVERED BY ISRI CODE WORD DREAM; MUNTZ METAL TUBES COVERED BY ISRI CODE WORD PALMS"
    },
    {
      "code": "74040019",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER :OTHER"
    },
    {
      "code": "74040021",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER ALLOYS : EMPTY OR DISCHARGED CARTRIDGES OF ALL BORES AND SIZES"
    },
    {
      "code": "74040022",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER ALLOYS : BRASS SCRAP, NAMELY THE FOLLOWING : REFINERY BRASS SCRAP COVERED BY ISRI CODE WORD DRINK; COMPOSITION OF RED BRASS SCRAP COVERED BY ISRI CODE WORD EBONY; RED BRASS COMPOSITION TURNINGS SCRAP COVERED BY ISRI CODE WORD ENERV; GENUINE BABBIT-LINED BRASS BUSHINGS SCRAP COVERED BY ISRI CODE WORD ELDER; MACHINERY OR HARD BRASS SOLIDS SCRAP COVERED BY ISRI CODE WORD ENGEL; MACHINERY OR HAND BRASS SOLIDS SCRAP COVERED BY ISRI CODE WORD ERIN; COCKS AND FAUCETS SCRAP COVERED BY ISRI CODE WORD GRAPE; YELLOW BRASS SCRAP COVERED BY ISRI CODE WORD HONEY; YELLOW BRASS CASTINGS COVERED BY ISRI CODE WORD IVORY; NEW BRASS CLIPPINGS COVERED BY ISRI CODE WORD LABEL; YELLOW BRASS PRIMER COVERED BY ISRI CODE WORD LARK; BRASS PIPE COVERED BY ISRI CODE WORD MELON; YELLOW BRASS ROD TURNINGS COVERED BY ISRI CODE WORD NIGHT; NEW YELLOW BRASS ROD ENDS COVERED BY ISRI CODE WORD NOBLE; YELLOW BRASS TURNINGS COVERED BY ISRI CODE WORD NORMAD; MIXED UNSWEATED AUTO RADIATORS COVERED BY ISRI CODE WORD OCEAN; ADMIRALTY BRASS CONDENSER TUBES COVERED BY ISRI CODE WORD PALES; ALUMINIUM BRASS CONDENSER TUBES COVERED BY ISRI CODE WORD PALLU; MANGANESE BRONZE SOLIDS COVERED BY ISRI CODE WORD PARCH"
    },
    {
      "code": "74040023",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP--- OF COPPER ALLOYS ----NICKEL SILVER SCRAP NAMELY THE FOLLOWING: MIXED NEW NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD 'MAIZE', NEW NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD 'MAJOR', NEW SEGREGATED NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD 'MALAR', OLD NICKEL SILVER COVERED BY ISRI CODE WORD 'MALIC', NICKEL SILVER CASTINGS COVERED BY ISRI CODE WORD 'NAGGY', NICKEL SILVER TURNINGS COVERED BY ISRI CODE WORD 'NIECE'"
    },
    {
      "code": "74040024",
      "description": "Bronze scrap, including the following:manganese bronze soilds covered ISRI code word Parch;High lead bronze solids and borings covered by ISRI code word Elias"
    },
    {
      "code": "74040025",
      "description": "Copper nickel scrap, including the following: new cupro nickel clips and solids covered by ISRI code word Dandy; cupro nickel solids covered by ISRI code word Daunt; soldered cupro-nickel solids covered by ISRI code word Delta; cupro nickel spinnings, turnings, borings covered by ISRI code word Decoy;"
    },
    {
      "code": "74040029",
      "description": "COPPER WASTE AND SCRAP - COPPER WASTE AND SCRAP: OF COPPER ALLOYS : OTHER"
    },
    {
      "code": "7405",
      "description": "MASTER ALLOYS OF COPPER"
    },
    {
      "code": "74050000",
      "description": "MASTER ALLOYS OF COPPER"
    },
    {
      "code": "7406",
      "description": "COPPER POWDERS AND FLAKES"
    },
    {
      "code": "74061000",
      "description": "COPPER POWDERS AND FLAKES - POWDERS OF NON-LAMELLAR STRUCTURE"
    },
    {
      "code": "74062000",
      "description": "COPPER POWDERS AND FLAKES - POWDERS OF LAMELLAR STRUCTURE; FLAKES"
    },
    {
      "code": "7407",
      "description": "COPPER BARS, RODS AND PROFILES"
    },
    {
      "code": "740710",
      "description": "Of refined copper"
    },
    {
      "code": "74071010",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER :ELECTROLYTIC COPPER RODS OR BLACK COPPER RODS"
    },
    {
      "code": "74071020",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER :OTHER COPPER RODS"
    },
    {
      "code": "74071030",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER : COPPER BARS (EXCLUDING HOLLOW BARS)"
    },
    {
      "code": "74071040",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER :HOLLOW BARS OF COPPER"
    },
    {
      "code": "74071051",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER : PROFILES : HOLLOW PROFILES"
    },
    {
      "code": "74071059",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER : PROFILES : OTHER"
    },
    {
      "code": "74071090",
      "description": "COPPER BARS, RODS AND PROFILES - OF REFINED COPPER :OTHER"
    },
    {
      "code": "740721",
      "description": "Of copper-zinc base alloys (brass)"
    },
    {
      "code": "74072110",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : BARS"
    },
    {
      "code": "74072120",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : RODS"
    },
    {
      "code": "74072130",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : HOLLOW BARS"
    },
    {
      "code": "74072190",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : OTHER"
    },
    {
      "code": "74072210",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : HOLLOW BARS"
    },
    {
      "code": "74072220",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : BARS AND RODS"
    },
    {
      "code": "740729",
      "description": "Other"
    },
    {
      "code": "74072910",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OTHER : RODS OF BRONZE AND SIMILAR ALLOYS"
    },
    {
      "code": "74072921",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OTHER : PROFILES : HOLLOW"
    },
    {
      "code": "74072929",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OTHER : PROFILES : OTHER"
    },
    {
      "code": "74072990",
      "description": "COPPER BARS, RODS AND PROFILES - OF COPPER ALLOYS : OTHER : OTHER"
    },
    {
      "code": "7408",
      "description": "COPPER WIRE - Of refined copper"
    },
    {
      "code": "740811",
      "description": "Of which the maximum cross-sectional dimension exceeds 6 mm"
    },
    {
      "code": "74081110",
      "description": "COPPER WIRE - OF REFINED COPPER : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM : COPPER WELD WIRE"
    },
    {
      "code": "74081190",
      "description": "COPPER WIRE - OF REFINED COPPER : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM : OTHER"
    },
    {
      "code": "740819",
      "description": "Other"
    },
    {
      "code": "74081910",
      "description": "COPPER WIRE - OF REFINED COPPER : OTHER : COPPER WELD WIRE"
    },
    {
      "code": "74081920",
      "description": "COPPER WIRE - OF REFINED COPPER : OTHER : WELDING WIRE"
    },
    {
      "code": "74081990",
      "description": "COPPER WIRE - OF REFINED COPPER : OTHER : OTHER"
    },
    {
      "code": "740821",
      "description": "Of copper-zinc base alloys (brass)"
    },
    {
      "code": "74082110",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM"
    },
    {
      "code": "74082190",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS) : OTHER"
    },
    {
      "code": "740822",
      "description": "Of copper-nickel base alloys (cupro-nickel) or copper-nickel-zinc base alloys (nickel silver)"
    },
    {
      "code": "74082210",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : SILVER PLATED FLATTENED WIRE OF COPPER (LAMETTA)"
    },
    {
      "code": "74082220",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : OTHER OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM"
    },
    {
      "code": "74082290",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER) : OTHER"
    },
    {
      "code": "740829",
      "description": "Other"
    },
    {
      "code": "74082910",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OTHER : WIRE OF BRONZE AND SIMILAR ALLOYS"
    },
    {
      "code": "74082990",
      "description": "COPPER WIRE - OF COPPER ALLOYS : OTHER : OTHER"
    },
    {
      "code": "7409",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.12.5 MM - Of refined copper"
    },
    {
      "code": "74091100",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF REFINED COPPER IN COILS"
    },
    {
      "code": "74091900",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF REFINED COPPER OTHER"
    },
    {
      "code": "74092100",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF COPPER-ZINC BASE ALLOYS (BRASS) : IN COILS"
    },
    {
      "code": "74092900",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF COPPER-ZINC BASE ALLOYS (BRASS) : OTHER"
    },
    {
      "code": "74093100",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF COPPER-TIN BASE ALLOYS (BRONZE) : IN COILS"
    },
    {
      "code": "74093900",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF COPPER-TIN BASE ALLOYS (BRONZE) : OTHER"
    },
    {
      "code": "74094000",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER)"
    },
    {
      "code": "74099000",
      "description": "COPPER PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.15 MM - OF OTHER COPPER ALLOYS"
    },
    {
      "code": "7410",
      "description": "COPPER FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.12.5 MM - Not backed"
    },
    {
      "code": "74101100",
      "description": "COPPER FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.15 MM - NOT BACKED : OF REFINED COPPER"
    },
    {
      "code": "74101200",
      "description": "COPPER FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.15 MM - NOT BACKED : OF COPPER ALLOYS"
    },
    {
      "code": "74102100",
      "description": "COPPER FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.15 MM - BACKED : OF REFINED COPPER"
    },
    {
      "code": "74102200",
      "description": "COPPER FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.15 MM - BACKED : OF COPPER ALLOYS"
    },
    {
      "code": "7411",
      "description": "COPPER TUBES AND PIPES"
    },
    {
      "code": "74111000",
      "description": "COPPER TUBES AND PIPES - OF REFINED COPPER"
    },
    {
      "code": "74112100",
      "description": "COPPER TUBES AND PIPES - OF COPPER ALLOYS : OF COPPER-ZINC BASE ALLOYS (BRASS)"
    },
    {
      "code": "74112200",
      "description": "COPPER TUBES AND PIPES - OF COPPER ALLOYS : OF COPPER-NICKEL BASE ALLOYS (CUPRO-NICKEL) OR COPPER-NICKEL-ZINC BASE ALLOYS (NICKEL SILVER)"
    },
    {
      "code": "74112900",
      "description": "COPPER TUBES AND PIPES - OF COPPER ALLOYS : OTHER"
    },
    {
      "code": "7412",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)"
    },
    {
      "code": "74121000",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) OF REFINED COPPER"
    },
    {
      "code": "741220",
      "description": "Of copper alloys"
    },
    {
      "code": "74122011",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - OF COPPER ALLOYS : BRASS : TUBE-WELL STRAINER"
    },
    {
      "code": "74122012",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - OF COPPER ALLOYS : BRASS : HOSE CONNECTORS"
    },
    {
      "code": "74122019",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - OF COPPER ALLOYS : BRASS : OTHER"
    },
    {
      "code": "74122090",
      "description": "COPPER TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - OF COPPER ALLOYS : FITTINGS OF BRONZE OR OTHER ALLOYS OF COPPER"
    },
    {
      "code": "7413",
      "description": " STRANDED WIRE, CABLES, PLATED BANDS AND THE LIKE, OF COPPER, NOT ELECTRICALLY INSULATED"
    },
    {
      "code": "74130000",
      "description": "STRANDED WIRE, CABLES, PLATED BANDS AND THE LIKE, OF COPPER, NOT ELECTRICALLY INSULATED"
    },
    {
      "code": "7414",
      "description": "OMITTED"
    },
    {
      "code": "74142010",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL AND NETTING, OF COPPER WIRE; EXPANDED METAL OF COPPER - CLOTH : ENDLESS BANDS, FOR MACHINERY"
    },
    {
      "code": "74142090",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL AND NETTING, OF COPPER WIRE; EXPANDED METAL OF COPPER - CLOTH : OTHER"
    },
    {
      "code": "74149010",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL AND NETTING, OF COPPER WIRE; EXPANDED METAL OF COPPER - OTHER :WIRE GAUZE AND NETTING"
    },
    {
      "code": "74149020",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL AND NETTING, OF COPPER WIRE; EXPANDED METAL OF COPPER - OTHER :EXPANDED METAL OF COPPER AND COPPER ALLOYS"
    },
    {
      "code": "74149090",
      "description": "CLOTH (INCLUDING ENDLESS BANDS), GRILL AND NETTING, OF COPPER WIRE; EXPANDED METAL OF COPPER - OTHER :OTHER"
    },
    {
      "code": "7415",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER"
    },
    {
      "code": "74151000",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - NAILS AND TACKS, DRAWING PINS, STAPLES AND SIMILAR ARTICLES"
    },
    {
      "code": "74152100",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER ARTICLES, NOT THREADED : WASHERS (INCLUDING SPRING WASHERS)"
    },
    {
      "code": "74152900",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER ARTICLES, NOT THREADED : OTHER"
    },
    {
      "code": "741533",
      "description": "Screws; bolts and nuts"
    },
    {
      "code": "74153310",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER THREADED ARTICLES : SCREWS; BOLTS AND NUTS : SCREWS FOR WOOD"
    },
    {
      "code": "74153390",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER THREADED ARTICLES : SCREWS; BOLTS AND NUTS : OTHER"
    },
    {
      "code": "741539",
      "description": "Other"
    },
    {
      "code": "74153910",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER THREADED ARTICLES : OTHER : RIVETS (EXCLUDING TUBULAR OR BIFURCATED)"
    },
    {
      "code": "74153990",
      "description": "NAILS, TACKS, DRAWING PINS, STAPLES (OTHER THAN THOSE OF HEADING 8305) AND SIMILAR ARTICLES, OF COPPER OR OF IRON OR STEEL WITH HEADS OF COPPER; SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTERPINS, WASHERS (INCLUDING SPRING WASHERS) AND SIMILAR ARTICLES, OF COPPER - OTHER THREADED ARTICLES : OTHER : OTHER"
    },
    {
      "code": "7416",
      "description": "COPPER SPRINGS"
    },
    {
      "code": "74160000",
      "description": "COPPER SPRINGS"
    },
    {
      "code": "7417",
      "description": "OMITTED"
    },
    {
      "code": "74170011",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : STOVE : OIL PRESSURE STOVE"
    },
    {
      "code": "74170019",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : STOVE : OTHER"
    },
    {
      "code": "74170020",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : STOVE : OTHER COOKING AND HEATING APPARATUS"
    },
    {
      "code": "74170091",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : PARTS : BURNERS"
    },
    {
      "code": "74170092",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : PARTS : OTHER PARTS OF STOVE"
    },
    {
      "code": "74170099",
      "description": "COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER - COOKING OR HEATING APPARATUS OF A KIND USED FOR DOMESTIC PURPOSES, NON-ELECTRIC, AND PARTS THEREOF, OF COPPER : PARTS : OTHER"
    },
    {
      "code": "7418",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER"
    },
    {
      "code": "741810",
      "description": "Table, kitcehn or other house hold articles and parts thereof Pot scourers and scouring orpolishing pads, gloves and the like"
    },
    {
      "code": "74181010",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE"
    },
    {
      "code": "74181021",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---UTENSILS----OF BRASS"
    },
    {
      "code": "74181022",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---UTENSILS----OF COPPER"
    },
    {
      "code": "74181023",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---UTENSILS----OF OTHER COPPER ALLOYS"
    },
    {
      "code": "74181024",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---UTENSILS----E.P.N/.S. WARE"
    },
    {
      "code": "74181031",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---OTHER----OF E.P.N.S."
    },
    {
      "code": "74181039",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ----OTHER----OTHER"
    },
    {
      "code": "74181090",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE ---OTHER----PARTS"
    },
    {
      "code": "74181100",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE : POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE"
    },
    {
      "code": "741819",
      "description": "Other"
    },
    {
      "code": "741820",
      "description": "Sanitary ware and parts thereof"
    },
    {
      "code": "74182010",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - SANITARY WARE AND PARTS THEREOF : SANITARY WARE"
    },
    {
      "code": "74182020",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF COPPER; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF COPPER; SANITARY WARE AND PARTS THEREOF, OF COPPER - SANITARY WARE AND PARTS THEREOF : PARTS OF SANITARY WARE"
    },
    {
      "code": "7419",
      "description": "OTHER ARTICLES OF COPPER"
    },
    {
      "code": "741910",
      "description": "Chain and parts thereof"
    },
    {
      "code": "74191010",
      "description": "OTHER ARTICLES OF COPPER - CHAIN AND PARTS THEREOF : CHAIN"
    },
    {
      "code": "74191021",
      "description": "OTHER ARTICLES OF COPPER - CHAIN AND PARTS THEREOF : PARTS : OF COPPER CHAINS"
    },
    {
      "code": "74191029",
      "description": "OTHER ARTICLES OF COPPER - CHAIN AND PARTS THEREOF : PARTS : OTHER"
    },
    {
      "code": "74199100",
      "description": "OTHER ARTICLES OF COPPER - OTHER : CAST, MOULDED, STAMPED OR FORGED, BUT NOT FURTHER WORKED"
    },
    {
      "code": "741999",
      "description": "Other"
    },
    {
      "code": "74199910",
      "description": "OTHER ARTICLES OF COPPER - OTHER : OTHER :RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS OF A CAPCITY ABOVE 300 L"
    },
    {
      "code": "74199920",
      "description": "OTHER ARTICLES OF COPPER - OTHER : OTHER :ARTICLES OF COPPER ALLOYS ELECTRO-PLATED WITH NICKEL-SILVER"
    },
    {
      "code": "74199930",
      "description": "OTHER ARTICLES OF COPPER - OTHER : OTHER :ARTICLES OF BRASS"
    },
    {
      "code": "74199940",
      "description": "OTHER ARTICLES OF COPPER - OTHER : OTHER :COPPER WORKED ARTICLES"
    },
    {
      "code": "74199990",
      "description": "OTHER ARTICLES OF COPPER - OTHER : OTHER : OTHER ARTICLES OF COPPER"
    },
    {
      "code": "75",
      "description": "Nickel and articles thereof"
    },
    {
      "code": "7501",
      "description": "NICKEL MATTES, NICKEL OXIDE SINTERS AND OTHER INTERMEDIATE PRODUCTS OF NICKEL METALLURGY"
    },
    {
      "code": "75011000",
      "description": "NICKEL MATTES, NICKEL OXIDE SINTERS AND OTHER INTERMEDIATE PRODUCTS OF NICKEL METALLURGY - NICKEL MATTES"
    },
    {
      "code": "75012000",
      "description": "NICKEL MATTES, NICKEL OXIDE SINTERS AND OTHER INTERMEDIATE PRODUCTS OF NICKEL METALLURGY - NICKEL OXIDE SINTERS AND OTHER INTERMEDIATE PRODUCTS OF NICKEL METALLURGY"
    },
    {
      "code": "7502",
      "description": "UNWROUGHT NICKEL"
    },
    {
      "code": "75021000",
      "description": "UNWROUGHT NICKEL - NICKEL, NOT ALLOYED"
    },
    {
      "code": "750220",
      "description": "Nickel alloys"
    },
    {
      "code": "75022010",
      "description": "UNWROUGHT NICKEL - NICKEL ALLOYS :CUPRO-NICKEL CONTAINING MORE THAN 40% BY WEIGHT OF NICKEL"
    },
    {
      "code": "75022020",
      "description": "UNWROUGHT NICKEL - NICKEL ALLOYS :MONEL METAL INCLUDING `K MONEL"
    },
    {
      "code": "75022030",
      "description": "UNWROUGHT NICKEL - NICKEL ALLOYS :NICKEL ALLOYS CONTAINING MORE THAN 40% BY WEIGHT OF NICKEL"
    },
    {
      "code": "75022040",
      "description": "UNWROUGHT NICKEL - NICKEL ALLOYS :NICKEL ALLOYS CONTAINING MORE THAN 10 % BUT NOT MORE THAN 40 % BY WEIGHT OF NICKEL"
    },
    {
      "code": "75022090",
      "description": "UNWROUGHT NICKEL - NICKEL ALLOYS :OTHER"
    },
    {
      "code": "7503",
      "description": "NICKEL WASTE AND SCRAP"
    },
    {
      "code": "750300",
      "description": "Nickel waste and scrap"
    },
    {
      "code": "75030010",
      "description": "NICKEL WASTE AND SCRAP - NICKEL WASTE AND SCRAP : NICKEL SCRAP, NAMELY THE FOLLOWING : NEW NICKEL SCRAP COVERED BY ISRI CODE WORD `AROMA; OLD NICKEL SCRAP COVERED BY ISRI CODE WORD `BURLY; NEW CUPRO NICKEL CLIPS AND SOLIDS COVERED BY ISRI CODE WORD `DANDY; CUPRO NICKEL SOLIDS COVERED BY ISRI CODE WORD `DAUNT; SOLDERED CUPRO-NICKEL SOLIDS COVERED BY ISRI CODE WORD `DELTA; CUPRO NICKEL SPINNINGS, TURNINGS, BORINGS COVERED BY ISRI CODE WORD `DECOY; MISCELLANEOUS NICKEL-COPPER AND NICKEL-COPPER IRON COVERED BY ISRI CODE WORD `DEPTH; MIXED NEW NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD `MAIZE; MIXED NEW NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD `MAJOR; NEW SEGREGATED NICKEL SILVER CLIPPINGS COVERED BY ISRI CODE WORD `MALAR; OLD NICKEL SILVER COVERED BY ISRI CODE WORD `MALIC; NICKEL SILVER CASTINGS COVERED BY ISRI CODE WORD `NAGGY; NICKEL SILVER TURNINGS COVERED BY ISRI CODE WORD `NIECE; NEW R-MONEL CLIPPINGS SOLIDS COVERED BY ISRI CODE WORD `HITCH; NEW MIXED MONEL SOLIDS AND CLIPPINGS COVERED BY ISRI CODE WORD `HOUSE; OLD MONEL SHEET AND SOLIDS COVERED BY ISRI CODE WORD `IDEAL; K-MONEL SOLIDS COVERED BY ISRI CODE WORD `INDIAN; SOLDERED MONEL SHEET AND SOLIDS COVERED BY ISRI CODE WORD `JUNTO; MONEL CASTINGS COVERED BY ISRI CODE`LEMON; MONEL TURNINGS COVERED BY ISRI CODE WORD `LEMUR; NICKEL SCRAP OBTAINED BY BREAKING UP OF SHIPS, BOATS AND OTHER FLOATING STRUCTURES"
    },
    {
      "code": "75030090",
      "description": "NICKEL WASTE AND SCRAP - NICKEL WASTE AND SCRAP : OTHER"
    },
    {
      "code": "7504",
      "description": "NICKEL POWDERS AND FLAKES "
    },
    {
      "code": "75040000",
      "description": "NICKEL POWDERS AND FLAKES"
    },
    {
      "code": "7505",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - Bars, rods and profiles"
    },
    {
      "code": "750511",
      "description": "Of nickel, not alloyed"
    },
    {
      "code": "75051110",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - BARS, RODS AND PROFILES : OF NICKEL, NOT ALLOYED : HOLLOW BARS"
    },
    {
      "code": "75051120",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - BARS, RODS AND PROFILES : OF NICKEL, NOT ALLOYED : OTHER BARS; RODS AND PROFILES"
    },
    {
      "code": "750512",
      "description": "Of nickel alloys"
    },
    {
      "code": "75051210",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - BARS, RODS AND PROFILES : OF NICKEL ALLOYS : HOLLOW BARS"
    },
    {
      "code": "75051220",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - BARS, RODS AND PROFILES : OF NICKEL ALLOYS : OTHER BARS; RODS AND PROFILES"
    },
    {
      "code": "75052100",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - WIRE : OF NICKEL, NOT ALLOYED"
    },
    {
      "code": "75052200",
      "description": "NICKEL BARS, RODS, PROFILES AND WIRE - WIRE : OF NICKEL ALLOYS"
    },
    {
      "code": "7506",
      "description": "NICKEL PLATES, SHEETS, STRIP AND FOIL"
    },
    {
      "code": "75061000",
      "description": "NICKEL PLATES, SHEETS, STRIP AND FOIL - OF NICKEL, NOT ALLOYED"
    },
    {
      "code": "75062000",
      "description": "NICKEL PLATES, SHEETS, STRIP AND FOIL - OF NICKEL ALLOYS"
    },
    {
      "code": "7507",
      "description": "NICKEL TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - Tubes and pipes"
    },
    {
      "code": "75071100",
      "description": "NICKEL TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - TUBES AND PIPES : OF NICKEL, NOT ALLOYED"
    },
    {
      "code": "75071200",
      "description": "NICKEL TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - TUBES AND PIPES : OF NICKEL ALLOYS"
    },
    {
      "code": "75072000",
      "description": "NICKEL TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) - TUBE OR PIPE FITTINGS"
    },
    {
      "code": "7508",
      "description": "OTHER ARTICLES OF NICKEL"
    },
    {
      "code": "75081000",
      "description": "OTHER ARTICLES OF NICKEL - CLOTH, GRILL AND NETTING, OF NICKEL WIRE"
    },
    {
      "code": "750890",
      "description": "Other"
    },
    {
      "code": "75089010",
      "description": "OTHER ARTICLES OF NICKEL - OTHER : ELECTROPLATING ANODES OF NICKEL"
    },
    {
      "code": "75089020",
      "description": "OTHER ARTICLES OF NICKEL - OTHER : BLANKS ORDINARILY USED FOR MANUFACTURING TUBES AND PIPES OF NICKEL"
    },
    {
      "code": "75089030",
      "description": "OTHER ARTICLES OF NICKEL - OTHER : NICKEL SCREEN"
    },
    {
      "code": "75089090",
      "description": "OTHER ARTICLES OF NICKEL - OTHER : OTHER ARTICLES OF NICKEL AND NICKEL ALLOY"
    },
    {
      "code": "76",
      "description": "Aluminium and articles thereof"
    },
    {
      "code": "7601",
      "description": "UNWROUGHT ALUMINIUM"
    },
    {
      "code": "760110",
      "description": "Aluminium, not alloyed"
    },
    {
      "code": "76011010",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM, NOT ALLOYED : INGOTS"
    },
    {
      "code": "76011020",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM, NOT ALLOYED : BILLETS"
    },
    {
      "code": "76011030",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM, NOT ALLOYED : WIRE BARS"
    },
    {
      "code": "76011040",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM, NOT ALLOYED : WIRE RODS"
    },
    {
      "code": "76011090",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM, NOT ALLOYED : OTHER"
    },
    {
      "code": "760120",
      "description": "Aluminium alloys"
    },
    {
      "code": "76012010",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM ALLOYS : INGOTS"
    },
    {
      "code": "76012020",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM ALLOYS : BILLETS"
    },
    {
      "code": "76012030",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM ALLOYS : WIRE BARS"
    },
    {
      "code": "76012040",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM ALLOYS : WIRE RODS"
    },
    {
      "code": "76012090",
      "description": "UNWROUGHT ALUMINIUM - ALUMINIUM ALLOYS : OTHER"
    },
    {
      "code": "7602",
      "description": "ALUMINIUM WASTE AND SCRAP"
    },
    {
      "code": "760200",
      "description": "Aluminium waste and scrap"
    },
    {
      "code": "76020010",
      "description": "ALUMINIUM WASTE AND SCRAP - ALUMINIUM WASTE AND SCRAP : ALUMINIUM SCRAP, NAMELY THE FOLLOWING : CLEAN ALUMINIUM LITHOGRAPHIC SHEETS (ISRI) NEW, CLEAN ALUMINIUM LITHOGRAPHIC SHEETS (ISRI)MIXED LOW COPPER ALUMINIUM CLIPPINGS AND SOLIDS (ISRI) CLEAN MIXED OLD ALLOY SHEET ALUMINIUM (ISRI) NEW ALUMINIUM CAN STOCK (ISRI)  OLD CAN STOCK (ISRI)  SHREDDED ALUMINIUM USED BEVERAGES CAN (U) SCRAP (ISRI) DENSIFIED ALUMINIUM USED BEVERAGES CAN (UBC) SCRAP (ISRI)BALED ALUMINIUM USED BEVERAGE CAN (UBC) SCRAP (ISRI) BRIQUETTED ALUMINIUM USED BEVERAGE CAN (UBC) SCRAP (ISRI)  PAINTED SIDING (ISRI) COATED SCRAP (ISRI) ALUMINIUM SCRAP RADIATORS (ISRI) E.C. ALUMINIUM NODULES (ISRI) NEW PURE ALUMINIUM WIRE AND CABLE (ISRI)  NEW MIXED ALUMINIUM WIRE AND CABLE (ISRI) OLD PURE ALUMINIUM WIRE AND CABLE (ISRI) OLD MIXED ALUMINIUM WIRE AND CABLE (ISRI) ALUMINIUM PISTONS (ISRI)  SEGREGATED ALUMINIUM BORINGS AND TURNINGS (ISRI) MIXED ALUMINIUM CASTINGS (ISRI)  MIXED ALUMINIUM CASTINGS (ISRI)  WRECKED AIRPLANE SHEET ALUMINIUM (ISRI) NEW ALUMINIUM FOIL (ISRI)  OLD ALUMINIUM FOIL (ISRI)  ALUMINIUM GRINDINGS (ISRI)  SWEATED ALUMINIUM (ISRI) SEGREGATED NEW ALUMINIUM ALLOY CLIPPINGS AND SOLIDS (ISRI) MIXED NEW ALUMINIUM ALLOY CLIPPINGS AND SOLIDS (ISRI) SEGREGATED NEW ALUMINIUM CASTINGS, FORGINGS AND EXTRUSIONS (ISRI) ALUMINIUM AUTO CASTINGS (ISRI) INSULATED ALUMINIUM WIRE SCRAP (ISRI) ALUMINIUM AIRPLANE CASTINGS (ISRI) FRAGMENTIZER ALUMINIUM SCRAP"
    },
    {
      "code": "76020090",
      "description": "ALUMINIUM WASTE AND SCRAP - ALUMINIUM WASTE AND SCRAP : OTHER WASTE AND SCRAP"
    },
    {
      "code": "7603",
      "description": "ALUMINIUM POWDERS AND FLAKES"
    },
    {
      "code": "760310",
      "description": "Powders of non-lamellar structure"
    },
    {
      "code": "76031010",
      "description": "ALUMINIUM POWDERS AND FLAKES - POWDERS OF NON-LAMELLAR STRUCTURE : ALUMINIUM POWDER FOR THERMIT PROCESS"
    },
    {
      "code": "76031090",
      "description": "ALUMINIUM POWDERS AND FLAKES - POWDERS OF NON-LAMELLAR STRUCTURE : OTHER"
    },
    {
      "code": "76032000",
      "description": "ALUMINIUM POWDERS AND FLAKES - POWDERS OF LAMELLAR STRUCTURE; FLAKES"
    },
    {
      "code": "7604",
      "description": "ALUMINIUM BARS, RODS AND PROFILES"
    },
    {
      "code": "760410",
      "description": "Of aluminium, not alloyed"
    },
    {
      "code": "76041010",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM, NOT ALLOYED : WIRE RODS"
    },
    {
      "code": "76041020",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM, NOT ALLOYED : BARS AND RODS, OTHER THAN WIRE RODS"
    },
    {
      "code": "76041031",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM, NOT ALLOYED : PROFILES : HOLLOW"
    },
    {
      "code": "76041039",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM, NOT ALLOYED : PROFILES : OTHER"
    },
    {
      "code": "76042100",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM ALLOYS : HOLLOW PROFILES"
    },
    {
      "code": "760429",
      "description": "Other"
    },
    {
      "code": "76042910",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM ALLOYS : OTHER : HARD DRAWN BARE ALUMINIUM CONDUCTORS STEEL RE-INFORCED (A.C.S.R.)"
    },
    {
      "code": "76042920",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM ALLOYS : OTHER : WIRE RODS"
    },
    {
      "code": "76042930",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM ALLOYS : OTHER : BARS AND RODS, OTHER THAN WIRE RODS"
    },
    {
      "code": "76042990",
      "description": "ALUMINIUM BARS, RODS AND PROFILES - OF ALUMINIUM ALLOYS : OTHER : OTHER"
    },
    {
      "code": "7605",
      "description": "ALUMINIUM WIRE"
    },
    {
      "code": "76051100",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM, NOT ALLOYED : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 7 MM"
    },
    {
      "code": "760519",
      "description": "Other"
    },
    {
      "code": "76051910",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM, NOT ALLOYED : OTHER : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM BUT DOES NOT EXCEED 7 MM"
    },
    {
      "code": "76051991",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM, NOT ALLOYED : OTHER : OTHER : HARD DRAWN BARE-SOLID"
    },
    {
      "code": "76051999",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM, NOT ALLOYED : OTHER : OTHER : OTHER"
    },
    {
      "code": "76052100",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM ALLOYS : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 7 MM"
    },
    {
      "code": "760529",
      "description": "Other"
    },
    {
      "code": "76052910",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM ALLOYS : OTHER : OF WHICH THE MAXIMUM CROSS-SECTIONAL DIMENSION EXCEEDS 6 MM BUT DOES NOT EXCEED 7 MM"
    },
    {
      "code": "76052990",
      "description": "ALUMINIUM WIRE - OF ALUMINIUM ALLOYS : OTHER : OTHER"
    },
    {
      "code": "7606",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 mm"
    },
    {
      "code": "760611",
      "description": "Of aluminium, not alloyed"
    },
    {
      "code": "76061110",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - RECTANGULAR (INCLUDING SQUARE) : OF ALUMINIUM, NOT ALLOYED : ELECTROLYTIC PLATES OR SHEETS"
    },
    {
      "code": "76061190",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - RECTANGULAR (INCLUDING SQUARE) : OF ALUMINIUM, NOT ALLOYED : OTHER"
    },
    {
      "code": "76061200",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - RECTANGULAR (INCLUDING SQUARE) : OF ALUMINIUM ALLOYS"
    },
    {
      "code": "760691",
      "description": "Of aluminium, not alloyed"
    },
    {
      "code": "76069110",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - OTHER : OF ALUMINIUM, NOT ALLOYED : CIRCLES"
    },
    {
      "code": "76069120",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - OTHER : OF ALUMINIUM, NOT ALLOYED : ELECTROLYTIC PLATES OR SHEETS"
    },
    {
      "code": "76069190",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - OTHER : OF ALUMINIUM, NOT ALLOYED : OTHER"
    },
    {
      "code": "760692",
      "description": "Of aluminium alloys"
    },
    {
      "code": "76069210",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - OTHER : OF ALUMINIUM ALLOYS : CIRCLES"
    },
    {
      "code": "76069290",
      "description": "ALUMINIUM PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - OTHER : OF ALUMINIUM ALLOYS : OTHER"
    },
    {
      "code": "7607",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - Not backed"
    },
    {
      "code": "760711",
      "description": "Rolled but not further worked"
    },
    {
      "code": "76071110",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : ROLLED BUT NOT FURTHER WORKED : ORDINARILY USED FOR TEA CHEST LINING"
    },
    {
      "code": "76071190",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : ROLLED BUT NOT FURTHER WORKED : OTHER"
    },
    {
      "code": "760719",
      "description": "Other"
    },
    {
      "code": "76071910",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : ORDINARILY USED FOR TEA CHEST LINING"
    },
    {
      "code": "76071991",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : PLAIN"
    },
    {
      "code": "76071992",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : EMBOSSED"
    },
    {
      "code": "76071993",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : PERFORATED OR CUT-TO-SHAPE"
    },
    {
      "code": "76071994",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : COATED"
    },
    {
      "code": "76071995",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : PRINTED"
    },
    {
      "code": "76071999",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - NOT BACKED : OTHER : OTHER : OTHER"
    },
    {
      "code": "760720",
      "description": "Backed"
    },
    {
      "code": "76072010",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - BACKED : ORDINARILY USED FOR TEA CHEST LINING"
    },
    {
      "code": "76072090",
      "description": "ALUMINIUM FOIL (WHETHER OR NOT PRINTED OR BACKED WITH PAPER, PAPERBOARD, PLASTICS OR SIMILAR BACKING MATERIALS) OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM - BACKED : OTHER"
    },
    {
      "code": "7608",
      "description": "ALUMINIUM TUBES AND PIPES"
    },
    {
      "code": "76081000",
      "description": "ALUMINIUM TUBES AND PIPE - OF ALUMINIUM, NOT ALLOYED"
    },
    {
      "code": "76082000",
      "description": "ALUMINIUM TUBES AND PIPE - OF ALUMINIUM ALLOYS"
    },
    {
      "code": "7609",
      "description": "ALUMINIUM TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)"
    },
    {
      "code": "76090000",
      "description": "ALUMINIUM TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)"
    },
    {
      "code": "7610",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES"
    },
    {
      "code": "76101000",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES - DOORS, WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS"
    },
    {
      "code": "761090",
      "description": "Other"
    },
    {
      "code": "76109010",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES - OTHER : STRUCTURES"
    },
    {
      "code": "76109020",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES - OTHER : PARTS OF STRUCTURES, NOT ELSEWHERE SPECIFIED"
    },
    {
      "code": "76109030",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES - OTHER : ALUMINIUM PLATES , RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURE"
    },
    {
      "code": "76109090",
      "description": "ALUMINIUM STRUCTURES (EXCLUDING PREFABRICATED BUILDINGS OF HEADING 9406) AND PARTS OF STRUCTURES (FOR EXAMPLE, BRIDGES AND BRIDGE-SECTIONS, TOWERS, LATTICE MASTS, ROOFS, ROOFING FRAMEWORKS, DOORS AND WINDOWS AND THEIR FRAMES AND THRESHOLDS FOR DOORS, BALUSTRADES, PILLARS AND COLUMNS); ALUMINIUM PLATES, RODS, PROFILES, TUBES AND THE LIKE, PREPARED FOR USE IN STRUCTURES - OTHER : OTHER"
    },
    {
      "code": "7611",
      "description": "ALUMINIUM RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT"
    },
    {
      "code": "76110000",
      "description": "ALUMINIUM RESERVOIRS, TANKS, VATS AND SIMILAR CONTAINERS, FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT"
    },
    {
      "code": "7612",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 l, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT"
    },
    {
      "code": "761210",
      "description": "Collapsible tubular containers"
    },
    {
      "code": "76121010",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - COLLAPSIBLE TUBULAR CONTAINERS : PLAIN"
    },
    {
      "code": "76121020",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - COLLAPSIBLE TUBULAR CONTAINERS : LACQUERED"
    },
    {
      "code": "76121030",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - COLLAPSIBLE TUBULAR CONTAINERS : PRINTED"
    },
    {
      "code": "76121090",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - COLLAPSIBLE TUBULAR CONTAINERS : OTHER"
    },
    {
      "code": "761290",
      "description": "Other"
    },
    {
      "code": "76129010",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OTHER : PLAIN"
    },
    {
      "code": "76129020",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OTHER : LACQUERED"
    },
    {
      "code": "76129030",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OTHER : PRINTED"
    },
    {
      "code": "76129090",
      "description": "ALUMINIUM CASKS, DRUMS, CANS, BOXES AND SIMILAR CONTAINERS (INCLUDING RIGID OR COLLAPSIBLE TUBULAR CONTAINERS), FOR ANY MATERIAL (OTHER THAN COMPRESSED OR LIQUEFIED GAS), OF A CAPACITY NOT EXCEEDING 300 L, WHETHER OR NOT LINED OR HEAT-INSULATED, BUT NOT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT - OTHER : OTHER"
    },
    {
      "code": "7613",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS"
    },
    {
      "code": "761300",
      "description": "Aluminium containers for compressed or liquefied gas"
    },
    {
      "code": "76130011",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : LOW PRESSURE CYLINDERS : PLAIN"
    },
    {
      "code": "76130012",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : LOW PRESSURE CYLINDERS : LACQUERED"
    },
    {
      "code": "76130013",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : LOW PRESSURE CYLINDERS : PRINTED"
    },
    {
      "code": "76130019",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : LOW PRESSURE CYLINDERS : OTHER"
    },
    {
      "code": "76130021",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : HIGH PRESSURE CYLINDERS : PLAIN"
    },
    {
      "code": "76130022",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : HIGH PRESSURE CYLINDERS : LACQUERED"
    },
    {
      "code": "76130023",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : HIGH PRESSURE CYLINDERS : PRINTED"
    },
    {
      "code": "76130029",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : HIGH PRESSURE CYLINDERS : OTHER"
    },
    {
      "code": "76130091",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : OTHER : PLAIN"
    },
    {
      "code": "76130092",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : OTHER : LACQUERED"
    },
    {
      "code": "76130093",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : OTHER : PRINTED"
    },
    {
      "code": "76130099",
      "description": "ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS - ALUMINIUM CONTAINERS FOR COMPRESSED OR LIQUEFIED GAS : OTHER : OTHER"
    },
    {
      "code": "7614",
      "description": "STRANDED WIRE, CABLES, PLAITED BANDS AND THE LIKE, OF ALUMINIUM, NOT ELECTRICALLY INSULATED"
    },
    {
      "code": "76141000",
      "description": "STRANDED WIRE, CABLES, PLAITED BANDS AND THE LIKE, OF ALUMINIUM, NOT ELECTRICALLY INSULATED - WITH STEEL CORE"
    },
    {
      "code": "76149000",
      "description": "STRANDED WIRE, CABLES, PLAITED BANDS AND THE LIKE, OF ALUMINIUM, NOT ELECTRICALLY INSULATED - OTHER"
    },
    {
      "code": "7615",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF ALUMINIUM; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF ALUMINIUM; SANITARY WARE AND PARTS THEREOF, OF ALUMINIUM"
    },
    {
      "code": "761510",
      "description": "Table, kitchen or other household articles and parts thereof; pot scourers and scouring or polishing pads, gloves and the like"
    },
    {
      "code": "76151011",
      "description": "PRESSURE COOKERS"
    },
    {
      "code": "76151012",
      "description": "SOLAR COLLECTORS"
    },
    {
      "code": "76151021",
      "description": "NON-STICK"
    },
    {
      "code": "76151029",
      "description": "OTHER"
    },
    {
      "code": "76151030",
      "description": "OTHER TABLE,KITCHEN OR HOUSEHOLD ARTICLES"
    },
    {
      "code": "76151040",
      "description": "POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE"
    },
    {
      "code": "76151090",
      "description": "PARTS"
    },
    {
      "code": "76151100",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF ALUMINIUM; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF ALUMINIUM; SANITARY WARE AND PARTS THEREOF, OF ALUMINIUM - TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE : POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE"
    },
    {
      "code": "761519",
      "description": "Other"
    },
    {
      "code": "761520",
      "description": "Sanitary ware and parts thereof"
    },
    {
      "code": "76152010",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF ALUMINIUM; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF ALUMINIUM; SANITARY WARE AND PARTS THEREOF, OF ALUMINIUM- SANITARY WARE AND PARTS THEREOF : SANITARY WARE OF ALUMINIUM AND ALUMINIUM ALLOYS FOR INDOOR USE"
    },
    {
      "code": "76152020",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF ALUMINIUM; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF ALUMINIUM; SANITARY WARE AND PARTS THEREOF, OF ALUMINIUM- SANITARY WARE AND PARTS THEREOF : PARTS"
    },
    {
      "code": "76152090",
      "description": "TABLE, KITCHEN OR OTHER HOUSEHOLD ARTICLES AND PARTS THEREOF, OF ALUMINIUM; POT SCOURERS AND SCOURING OR POLISHING PADS, GLOVES AND THE LIKE, OF ALUMINIUM; SANITARY WARE AND PARTS THEREOF, OF ALUMINIUM- SANITARY WARE AND PARTS THEREOF : OTHER"
    },
    {
      "code": "7616",
      "description": "OTHER ARTICLES OF ALUMINIUM"
    },
    {
      "code": "76161000",
      "description": "OTHER ARTICLES OF ALUMINIUM - NAILS, TACKS, STAPLES (OTHER THAN THOSE OF HEADING 8305), SCREWS, BOLTS, NUTS, SCREW HOOKS, RIVETS, COTTERS, COTTER-PMILAR ARTICLES"
    },
    {
      "code": "76169100",
      "description": "OTHER ARTICLES OF ALUMINIUM - OTHER : CLOTH, GRILL, NETTING AND FENCING, OF ALUMINIUM WIRE"
    },
    {
      "code": "761699",
      "description": "Other"
    },
    {
      "code": "76169910",
      "description": "OTHER ARTICLES OF ALUMINIUM - OTHER : OTHER : EXPANDED METAL OF ALUMINIUM AND ALUMINIUM ALLOYS"
    },
    {
      "code": "76169920",
      "description": "OTHER ARTICLES OF ALUMINIUM - OTHER : OTHER :CHAINS"
    },
    {
      "code": "76169930",
      "description": "OTHER ARTICLES OF ALUMINIUM - OTHER : OTHER : BOBBINS"
    },
    {
      "code": "76169990",
      "description": "OTHER ARTICLES OF ALUMINIUM - OTHER : OTHER : OTHER"
    },
    {
      "code": "77",
      "description": "(Reserved for possible future use)"
    },
    {
      "code": "78",
      "description": "Lead and articles thereof"
    },
    {
      "code": "7801",
      "description": "UNWROUGHT LEAD"
    },
    {
      "code": "78011000",
      "description": "UNWROUGHT LEAD : REFINED LEAD"
    },
    {
      "code": "78019100",
      "description": "UNWROUGHT LEAD - OTHER: CONTAINING BY WEIGHT ANTIMONY AS THE PRINCIPAL OTHER ELEMENT"
    },
    {
      "code": "780199",
      "description": "Other"
    },
    {
      "code": "78019910",
      "description": "UNWROUGHT LEAD - OTHER: OTHER: PIG LEAD"
    },
    {
      "code": "78019920",
      "description": "UNWROUGHT LEAD - OTHER: OTHER: UNREFINED LEAD"
    },
    {
      "code": "78019930",
      "description": "UNWROUGHT LEAD - OTHER: OTHER: UNREFINED LEAD ALLOYS"
    },
    {
      "code": "78019990",
      "description": "UNWROUGHT LEAD - OTHER: OTHER: OTHER"
    },
    {
      "code": "7802",
      "description": "LEAD WASTE AND SCRAP"
    },
    {
      "code": "780200",
      "description": "Lead waste and scrap"
    },
    {
      "code": "78020010",
      "description": "LEAD WASTE AND SCRAP - LEAD WASTE AND SCRAP: LEAD SCRAP, NAMELY THE FOLLOWING: SCRAP LEAD-SOFT COVERED BY ISRI CODE WORD `RACKS; MIXED HARD OR SOFT SCRAP LEAD COVERED BY ISRI CODE WORD `RADIO; LEAD COVERED COPPER CABLE COVERED BY ISRI CODE WORD `RELAY; WHEEL WEIGHTS COVERED BY ISRI CODE WORD `ROPES; MIXED COMMON BABBIT COVERED BY ISRI CODE WORD `ROSES"
    },
    {
      "code": "78020090",
      "description": "LEAD WASTE AND SCRAP - LEAD WASTE AND SCRAP: OTHER"
    },
    {
      "code": "7803",
      "description": "OMITTED"
    },
    {
      "code": "78030011",
      "description": "LEAD BARS, RODS, PROFILES AND WIRE - LEAD BARS, RODS, PROFILES AND WIRE: BARS AND RODS: HOLLOW BARS"
    },
    {
      "code": "78030019",
      "description": "LEAD BARS, RODS, PROFILES AND WIRE - LEAD BARS, RODS, PROFILES AND WIRE: BARS AND RODS: OTHER"
    },
    {
      "code": "78030021",
      "description": "LEAD BARS, RODS, PROFILES AND WIRE - LEAD BARS, RODS, PROFILES AND WIRE: PROFILES: HOLLOW"
    },
    {
      "code": "78030029",
      "description": "LEAD BARS, RODS, PROFILES AND WIRE - LEAD BARS, RODS, PROFILES AND WIRE: PROFILES: OTHER"
    },
    {
      "code": "78030030",
      "description": "LEAD BARS, RODS, PROFILES AND WIRE - LEAD BARS, RODS, PROFILES AND WIRE: WIRE"
    },
    {
      "code": "7804",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES"
    },
    {
      "code": "780411",
      "description": "Sheets, strip and foil of a thickness (excluding any backing) not exceeding 0.2 mm"
    },
    {
      "code": "78041110",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES - PLATES, SHEETS, STRIP AND FOIL: SHEETS, STRIP AND FOIL OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM: SHEETS AND STRIP"
    },
    {
      "code": "78041120",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES - PLATES, SHEETS, STRIP AND FOIL: SHEETS, STRIP AND FOIL OF A THICKNESS (EXCLUDING ANY BACKING) NOT EXCEEDING 0.2 MM: FOIL"
    },
    {
      "code": "780419",
      "description": "Other"
    },
    {
      "code": "78041910",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES - PLATES, SHEETS, STRIP AND FOIL: OTHER: PLATES"
    },
    {
      "code": "78041990",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES - PLATES, SHEETS, STRIP AND FOIL: OTHER: OTHER"
    },
    {
      "code": "78042000",
      "description": "LEAD PLATES, SHEETS, STRIP AND FOIL; LEAD POWDERS AND FLAKES : POWDERS AND FLAKES"
    },
    {
      "code": "7805",
      "description": "OMITTED"
    },
    {
      "code": "78050010",
      "description": "LEAD TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLING, ELBOWS, SLEEVES) - LEAD TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLING, ELBOWS, SLEEVES): TUBES AND PIPES"
    },
    {
      "code": "78050020",
      "description": "LEAD TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLING, ELBOWS, SLEEVES) - LEAD TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLING, ELBOWS, SLEEVES): TUBE OR PIPE FITTINGS"
    },
    {
      "code": "7806",
      "description": "OTHER ARTICLES OF LEAD"
    },
    {
      "code": "780600",
      "description": "Other articles of lead"
    },
    {
      "code": "78060010",
      "description": "OTHER ARTICLES OF LEAD - OTHER ARTICLES OF LEAD : SANITARY FIXTURES"
    },
    {
      "code": "78060020",
      "description": "OTHER ARTICLES OF LEAD - OTHER ARTICLES OF LEAD : INDIAN LEAD SEALS"
    },
    {
      "code": "78060030",
      "description": "OTHER ARTICLES OF LEAD - OTHER ARTICLES OF LEAD : BLANKS"
    },
    {
      "code": "78060090",
      "description": "OTHER ARTICLES OF LEAD - OTHER ARTICLES OF LEAD : OTHER"
    },
    {
      "code": "79",
      "description": "Zinc and articles thereof"
    },
    {
      "code": "7901",
      "description": "UNWROUGHT ZINC - Zinc, not alloyed"
    },
    {
      "code": "79011100",
      "description": "UNWROUGHT ZINC - ZINC, NOT ALLOYED : CONTAINING BY WEIGHT 99.99% OR MORE OF ZINC"
    },
    {
      "code": "79011200",
      "description": "UNWROUGHT ZINC - ZINC, NOT ALLOYED : CONTAINING BY WEIGHT LESS THAN 99.99% OF ZINC"
    },
    {
      "code": "790120",
      "description": "Zinc alloys"
    },
    {
      "code": "79012010",
      "description": "UNWROUGHT ZINC - ZINC ALLOYS :MOZAK OR ALLOYS OF ZINC AND ALUMINIUM CONTAINING NOT LESS THAN 94% BY WEIGHT OF ZINC"
    },
    {
      "code": "79012090",
      "description": "UNWROUGHT ZINC - ZINC ALLOYS : OTHER"
    },
    {
      "code": "7902",
      "description": "ZINC WASTE AND SCRAP"
    },
    {
      "code": "790200",
      "description": "Zinc waste and scrap"
    },
    {
      "code": "79020010",
      "description": "ZINC WASTE AND SCRAP - ZINC WASTE AND SCRAP : ZINC SCRAP, NAMELY THE FOLLOWING : OLD ZINC DIE CAST SCRAP COVERED BY ISRI CODE WORD `SAVES; NEW ZINC DIE CAST SCRAP COVERED BY ISRI CODE WORD `SCABS; NEW PLATED ZINC DIE CAST SCRAP COVERED BY ISRI CODE WORD `SCOPE; ZINC DIE CAST AUTOMOTIVE GRILLS COVERED BY ISRI CODE WORD `SCOOT; OLD SCRAP ZINC (ISRI) `SCORE; NEW ZINC CLIPPINGS (ISRI) `SCREEN; ZINC DIE CAST SLABS OR PIGS COVERED BY ISRI CODE WORD `SCULL; CRUSHED CLEAN SORTED FRAGMENTIZERS DIE CAST SCRAP, AS PRODUCED FROM AUTOMOBILE FRAGMENTIZERS (ISRI) `SCRIBE; HOT DIP GALVANIZERS SLAB ZINC DROSS (BATCH PROCESS) (ISRI) `SCRUB (MINIMUM 92% ZINC)  FREE OF SKIMMINGS; CONTINUOUS LINE GALVANIZING SLAB ZINC TOP DROSS (ISRI) `SEAL (MINIMUM 90% ZINC)  FREE OF SKIMMINGS; CONTINUOUS LINE GALVANIZING SLAB ZINC BOTTOM DROSS (ISRI) `SEAM (MINIMUM 92% ZINC)  FREE OF SKIMMINGS; PRIME ZINC DIE CAST (ISRI) `SHELF (85% ZINC)  FREE FROM CORROSION OR OXIDATION"
    },
    {
      "code": "79020090",
      "description": "ZINC WASTE AND SCRAP - ZINC WASTE AND SCRAP :OTHER"
    },
    {
      "code": "7903",
      "description": "ZINC DUST, POWDERS AND FLAKES"
    },
    {
      "code": "79031000",
      "description": "ZINC DUST, POWDERS AND FLAKES : ZINC DUST"
    },
    {
      "code": "79039000",
      "description": "ZINC DUST, POWDERS AND FLAKES : OTHER"
    },
    {
      "code": "7904",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE"
    },
    {
      "code": "790400",
      "description": "Zinc bars, rods, profiles and wire"
    },
    {
      "code": "79040011",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : BARS AND RODS :HOLLOW BARS"
    },
    {
      "code": "79040012",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : BARS AND RODS :RODS, INCLUDING WIRE RODS"
    },
    {
      "code": "79040019",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : BARS AND RODS :OTHER"
    },
    {
      "code": "79040021",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : PROFILES : HOLLOW"
    },
    {
      "code": "79040022",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : PROFILES : ANGLES, SHAPES AND SECTIONS"
    },
    {
      "code": "79040029",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : PROFILES : OTHER"
    },
    {
      "code": "79040030",
      "description": "ZINC BARS, RODS, PROFILES AND WIRE - ZINC BARS, RODS, PROFILES AND WIRE : WIRE"
    },
    {
      "code": "7905",
      "description": "ZINC PLATES, SHEETS, STRIP AND FOIL"
    },
    {
      "code": "790500",
      "description": "Zinc plates, sheets, strip and foil"
    },
    {
      "code": "79050010",
      "description": "ZINC PLATES, SHEETS, STRIP AND FOIL - ZINC PLATES, SHEETS, STRIP AND FOIL :CALOTS"
    },
    {
      "code": "79050020",
      "description": "ZINC PLATES, SHEETS, STRIP AND FOIL - ZINC PLATES, SHEETS, STRIP AND FOIL :PLATES"
    },
    {
      "code": "79050030",
      "description": "ZINC PLATES, SHEETS, STRIP AND FOIL - ZINC PLATES, SHEETS, STRIP AND FOIL :SHEETS, STRIP AND CIRCLES"
    },
    {
      "code": "79050040",
      "description": "ZINC PLATES, SHEETS, STRIP AND FOIL - ZINC PLATES, SHEETS, STRIP AND FOIL :FOIL"
    },
    {
      "code": "7906",
      "description": "OMITTED"
    },
    {
      "code": "79060010",
      "description": "ZINC TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)- ZINC TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) :TUBES AND PIPES"
    },
    {
      "code": "79060020",
      "description": "ZINC TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)- ZINC TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) : TUBE OR PIPE FITTINGS"
    },
    {
      "code": "7907",
      "description": "OTHER ARTICLES OF ZINC"
    },
    {
      "code": "790700",
      "description": "Other articles of zinc"
    },
    {
      "code": "79070010",
      "description": "OTHER ARTICLES OF ZINC : SANITARY FIXTURES"
    },
    {
      "code": "79070090",
      "description": "OTHER ARTICLES OF ZINC : OTHER"
    },
    {
      "code": "80",
      "description": "Tin and articles thereof"
    },
    {
      "code": "8001",
      "description": "UNWROUGHT TIN"
    },
    {
      "code": "800110",
      "description": "Tin, not alloyed"
    },
    {
      "code": "80011010",
      "description": "UNWROUGHT TIN - TIN, NOT ALLOYED : BLOCKS"
    },
    {
      "code": "80011090",
      "description": "UNWROUGHT TIN - TIN, NOT ALLOYED : INGOTS, PIGS, SLABS AND OTHER PRIMARY FORMS OF TIN"
    },
    {
      "code": "80012000",
      "description": "UNWROUGHT TIN TIN ALLOYS"
    },
    {
      "code": "8002",
      "description": "TIN WASTE AND SCRAP"
    },
    {
      "code": "800200",
      "description": "Tin waste and scrap"
    },
    {
      "code": "80020010",
      "description": "TIN WASTE AND SCRAP - TIN WASTE AND SCRAP : TIN SCRAP, NAMELY THE FOLLOWING : BLOCK TIN COVERED BY ISRI CODE WORD `RANCH; HIGH TIN BASE BABBIT COVERED BY ISRI CODE WORD `RAVES; PEWTER COVERED BY ISRI CODE WORD `RANKS"
    },
    {
      "code": "80020090",
      "description": "TIN WASTE AND SCRAP - TIN WASTE AND SCRAP : OTHER"
    },
    {
      "code": "8003",
      "description": "TIN BARS, RODS, PROFILES AND WIRE"
    },
    {
      "code": "800300",
      "description": "Tin bars, rods, profiles and wire"
    },
    {
      "code": "80030010",
      "description": "TIN BARS, RODS, PROFILES AND WIRE - TIN BARS, RODS, PROFILES AND WIRE : HOLLOW BARS"
    },
    {
      "code": "80030020",
      "description": "TIN BARS, RODS, PROFILES AND WIRE - TIN BARS, RODS, PROFILES AND WIRE : BARS, OTHER THAN HOLLOW BARS; RODS"
    },
    {
      "code": "80030030",
      "description": "TIN BARS, RODS, PROFILES AND WIRE - TIN BARS, RODS, PROFILES AND WIRE : PROFILES"
    },
    {
      "code": "80030040",
      "description": "TIN BARS, RODS, PROFILES AND WIRE - TIN BARS, RODS, PROFILES AND WIRE : WIRE"
    },
    {
      "code": "8004",
      "description": "OMITTED"
    },
    {
      "code": "80040010",
      "description": "TIN PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - TIN PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM : ELECTROLYTIC PLATES AND SHEETS"
    },
    {
      "code": "80040090",
      "description": "TIN PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM - TIN PLATES, SHEETS AND STRIP, OF A THICKNESS EXCEEDING 0.2 MM : OTHER"
    },
    {
      "code": "8005",
      "description": "OMITTED"
    },
    {
      "code": "80050000",
      "description": "Omitted"
    },
    {
      "code": "8006",
      "description": "OMITTED"
    },
    {
      "code": "80060010",
      "description": "TIN TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)- TIN TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) : TUBES AND PIPES"
    },
    {
      "code": "80060020",
      "description": "TIN TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES)- TIN TUBES, PIPES AND TUBE OR PIPE FITTINGS (FOR EXAMPLE, COUPLINGS, ELBOWS, SLEEVES) : TUBE OR PIPE FITTINGS"
    },
    {
      "code": "8007",
      "description": "OTHER ARTICLES OF TIN"
    },
    {
      "code": "800700",
      "description": "Other articles of tin"
    },
    {
      "code": "80070010",
      "description": "OTHER ARTICLES OF TIN - OTHER ARTICLES OF TIN : BLANKS"
    },
    {
      "code": "80070090",
      "description": "OTHER ARTICLES OF TIN - OTHER ARTICLES OF TIN : OTHER"
    },
    {
      "code": "8011",
      "description": "Coconuts"
    },
    {
      "code": "8012",
      "description": "Brazil nuts"
    },
    {
      "code": "8013",
      "description": "Cashew nuts"
    },
    {
      "code": "8021",
      "description": "Almonds"
    },
    {
      "code": "8022",
      "description": "Hazelnuts or fiberts (Corylus spp.)"
    },
    {
      "code": "8023",
      "description": "Walnuts"
    },
    {
      "code": "802901",
      "description": "Betel nuts"
    },
    {
      "code": "8071",
      "description": "Melons (including watermelons)"
    },
    {
      "code": "81",
      "description": "Other base metals; cermets; articles thereof"
    },
    {
      "code": "8101",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81011000",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP POWDERS"
    },
    {
      "code": "81019400",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: UNWROUGHT TUNGSTEN, INCLUDING BARS AND RODS OBTAINED SIMPLY BY SINTERING"
    },
    {
      "code": "81019510",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - BARS AND RODS, OTHER THAN THOSE OBTAINED SIMPLY BY SINTERING, PROFILES, PLATES, SHEETS, STRIP AND FOIL: HOLLOW BARS AND RODS"
    },
    {
      "code": "81019590",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - BARS AND RODS, OTHER THAN THOSE OBTAINED SIMPLY BY SINTERING, PROFILES, PLATES, SHEETS, STRIP AND FOIL: OTHER"
    },
    {
      "code": "81019600",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: WIRE"
    },
    {
      "code": "81019700",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: WASTE AND SCRAP"
    },
    {
      "code": "810199",
      "description": "Other"
    },
    {
      "code": "81019910",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - OTHER: TUNGSTEN FILAMENT"
    },
    {
      "code": "81019990",
      "description": "TUNGSTEN (WOLFRAM) AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - OTHER: OTHER"
    },
    {
      "code": "8102",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81021000",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP POWDERS"
    },
    {
      "code": "81029400",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: UNWROUGHT MOLYBDENUM, INCLUDING BARS AND RODS OBTAINED SIMPLY BY SINTERING"
    },
    {
      "code": "810295",
      "description": "Bars and rods, other than those obtained simply by sintering, profiles, plates, sheets, strip and foil"
    },
    {
      "code": "81029510",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - BARS AND RODS, OTHER THAN THOSE OBTAINED SIMPLY BY SINTERING, PROFILES, PLATES, SHEETS, STRIP AND FOIL: HOLLOW BARS"
    },
    {
      "code": "81029590",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: - BARS AND RODS, OTHER THAN THOSE OBTAINED SIMPLY BY SINTERING, PROFILES, PLATES, SHEETS, STRIP AND FOIL: OTHER"
    },
    {
      "code": "81029600",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: WIRE"
    },
    {
      "code": "81029700",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: WASTE AND SCRAP"
    },
    {
      "code": "81029900",
      "description": "MOLYBDENUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER: OTHER"
    },
    {
      "code": "8103",
      "description": "TANTALUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "810320",
      "description": "Unwrought tantalum, including bars and rods obtained simply by sintering; powders"
    },
    {
      "code": "81032010",
      "description": "TANTALUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - UNWROUGHT TANTALUM, INCLUDING BARS AND RODS OBTAINED SIMPLY BY SINTERING; POWDERS: HOLLOW BARS"
    },
    {
      "code": "81032090",
      "description": "TANTALUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - UNWROUGHT TANTALUM, INCLUDING BARS AND RODS OBTAINED SIMPLY BY SINTERING; POWDERS: OTHER"
    },
    {
      "code": "81033000",
      "description": "TANTALUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - WASTE AND SCRAP"
    },
    {
      "code": "81039000",
      "description": "TANTALUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER"
    },
    {
      "code": "8104",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - Unwrought magnesium"
    },
    {
      "code": "81041100",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - UNWROUGHT MAGNESIUM:CONTAINING AT LEAST 99.8% BY WEIGHT OF MAGNESIUM"
    },
    {
      "code": "81041900",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - UNWROUGHT MAGNESIUM:OTHER"
    },
    {
      "code": "810420",
      "description": "Waste and scrap"
    },
    {
      "code": "81042010",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - WASTE AND SCRAP:MAGNESIUM SCRAP, NAMELY THE FOLLOWING: MAGNESIUM CLIPS COVERED BY ISRI CODE WORD `WAFER; MAGNESIUM SCRAP COVERED BY ISRI CODE WORD `WALNUT; MAGNESIUM ENGRAVER PLATES COVERED BY ISRI CODE WORD `WINE; MAGNESIUM DOCK BOARDS COVERED BY ISRI CODE WORD `WOOD; MAGNESIUM TURNINGS COVERED BY ISRI CODE WORD `WORLD"
    },
    {
      "code": "81042090",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - WASTE AND SCRAP: OTHER"
    },
    {
      "code": "810430",
      "description": "Raspings, turnings and granules, graded according to size; powders"
    },
    {
      "code": "81043010",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - RASPINGS, TURNINGS AND GRANULES, GRADED ACCORDING TO SIZE; POWDERS:RASPINGS, TURNINGS AND GRANULES, GRADED ACCORDING TO SIZE"
    },
    {
      "code": "81043020",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - RASPINGS, TURNINGS AND GRANULES, GRADED ACCORDING TO SIZE; POWDERS:POWDERS"
    },
    {
      "code": "810490",
      "description": "Other"
    },
    {
      "code": "81049010",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:OTHER MAGNESIUM AND MAGNESIUM BASE ALLOYS, WROUGHT"
    },
    {
      "code": "81049020",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:FLAKES"
    },
    {
      "code": "81049030",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:WIRE"
    },
    {
      "code": "81049090",
      "description": "MAGNESIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:OTHER"
    },
    {
      "code": "8105",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "810520",
      "description": "Cobalt mattes and other intermediate products of cobalt metallurgy; unwrought cobalt; powders"
    },
    {
      "code": "81052010",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; UNWROUGHT COBALT; POWDERS: COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY"
    },
    {
      "code": "81052020",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; UNWROUGHT COBALT; POWDERS:COBALT UNWROUGHT"
    },
    {
      "code": "81052030",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; UNWROUGHT COBALT; POWDERS:POWDERS"
    },
    {
      "code": "81053000",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP WASTE AND SCRAP"
    },
    {
      "code": "81059000",
      "description": "COBALT MATTES AND OTHER INTERMEDIATE PRODUCTS OF COBALT METALLURGY; COBALT AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP OTHER"
    },
    {
      "code": "8106",
      "description": "BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "810600",
      "description": "Bismuth and articles thereof, including waste and scrap"
    },
    {
      "code": "81060010",
      "description": "BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:BISMUTH, UNWROUGHT"
    },
    {
      "code": "81060020",
      "description": "BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:WASTE AND SCRAP OF BISMUTH AND BISMUTH ALLOYS"
    },
    {
      "code": "81060030",
      "description": "BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:BISMUTH, WROUGHT"
    },
    {
      "code": "81060090",
      "description": "BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - BISMUTH AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:OTHER"
    },
    {
      "code": "8107",
      "description": "CADMIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81072000",
      "description": "CADMIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - UNWROUGHT CADMIUM; POWDERS"
    },
    {
      "code": "81073000",
      "description": "CADMIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - WASTE AND SCRAP"
    },
    {
      "code": "810790",
      "description": "Other"
    },
    {
      "code": "81079010",
      "description": "CADMIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:CADMIUM, WROUGHT"
    },
    {
      "code": "81079090",
      "description": "CADMIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:OTHER"
    },
    {
      "code": "8108",
      "description": "TITANIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81082000",
      "description": "TITANIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP UNWROUGHT TITANIUM; POWDERS"
    },
    {
      "code": "81083000",
      "description": "TITANIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP WASTE AND SCRAP"
    },
    {
      "code": "810890",
      "description": "Other"
    },
    {
      "code": "81089010",
      "description": "TITANIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:TITANIUM, WROUGHT"
    },
    {
      "code": "81089090",
      "description": "TITANIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - OTHER:OTHER"
    },
    {
      "code": "8109",
      "description": "ZIRCONIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81092000",
      "description": "ZIRCONIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP UNWROUGHT ZIRCONIUM; POWDERS"
    },
    {
      "code": "81093000",
      "description": "ZIRCONIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP WASTE AND SCRAP"
    },
    {
      "code": "81099000",
      "description": "ZIRCONIUM AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP OTHER"
    },
    {
      "code": "8110",
      "description": "ANTIMONY AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "81101000",
      "description": "ANTIMONY AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP UNWROUGHT ANTIMONY; POWDERS"
    },
    {
      "code": "81102000",
      "description": "ANTIMONY AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP WASTE AND SCRAP"
    },
    {
      "code": "81109000",
      "description": "ANTIMONY AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP OTHER"
    },
    {
      "code": "8111",
      "description": "MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "811100",
      "description": "Manganese and articles thereof, including waste and scrap"
    },
    {
      "code": "81110010",
      "description": "MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:UNWROUGHT MANGANESE AND MANGANESE BASE ALLOYS"
    },
    {
      "code": "81110020",
      "description": "MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:WASTE AND SCRAP OF MANGANESE BASE ALLOYS"
    },
    {
      "code": "81110030",
      "description": "MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:WROUGHT MANGANESE"
    },
    {
      "code": "81110090",
      "description": "MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - MANGANESE AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:OTHER"
    },
    {
      "code": "8112",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - Beryllium"
    },
    {
      "code": "81121200",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - BERYLLIUM: UNWROUGHT; POWDERS"
    },
    {
      "code": "81121300",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - BERYLLIUM: WASTE AND SCRAP"
    },
    {
      "code": "81121900",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - BERYLLIUM: OTHER"
    },
    {
      "code": "81122100",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - CHROMIUM: UNWROUGHT; POWDERS"
    },
    {
      "code": "81122200",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - CHROMIUM: WASTE AND SCRAP"
    },
    {
      "code": "81122900",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - CHROMIUM: OTHER"
    },
    {
      "code": "81123010",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - GERMANIUM:UNWROUGHT"
    },
    {
      "code": "81123020",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - GERMANIUM: WASTE AND SCRAP"
    },
    {
      "code": "81123030",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - GERMANIUM:WROUGHT"
    },
    {
      "code": "81123090",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - GERMANIUM:OTHER"
    },
    {
      "code": "81124010",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - VANADIUM:UNWROUGHT"
    },
    {
      "code": "81124020",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - VANADIUM:WASTE AND SCRAP"
    },
    {
      "code": "81124030",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - VANADIUM: WROUGHT"
    },
    {
      "code": "81124090",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - VANADIUM:OTHER"
    },
    {
      "code": "81125100",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - THALLIUM: UNWROUGHT; POWDERS"
    },
    {
      "code": "81125200",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - THALLIUM: WASTE AND SCRAP"
    },
    {
      "code": "81125900",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - THALLIUM: OTHER"
    },
    {
      "code": "81129200",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - OTHER: UNWROUGHT; WASTE AND SCRAP; POWDERS"
    },
    {
      "code": "81129900",
      "description": "BERYLLIUM, CHROMIUM, GERMANIUM, VANADIUM, GALLIUM, HAFNIUM, INDIUM, NIOBIUM (COLUMBIUM), RHENIUM AND THALLIUM, AND ARTICLES OF THESE METALS, INCLUDING WASTE AND SCRAP - OTHER: OTHER"
    },
    {
      "code": "8113",
      "description": "CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP"
    },
    {
      "code": "811300",
      "description": "Cermets and articles thereof, including waste and scrap"
    },
    {
      "code": "81130010",
      "description": "CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:UNWROUGHT CERMETS"
    },
    {
      "code": "81130020",
      "description": "CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:WASTE AND SCRAP OF CERMETS"
    },
    {
      "code": "81130030",
      "description": "CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:ARTICLES OF CERMETS"
    },
    {
      "code": "81130090",
      "description": "CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP - CERMETS AND ARTICLES THEREOF, INCLUDING WASTE AND SCRAP:OTHER"
    },
    {
      "code": "82",
      "description": "Tools, implements, cutlery, spoons and forks, of base metal; parts thereof of base metal"
    },
    {
      "code": "8201",
      "description": "HAND TOOLS, THE FOLLOWING: SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY."
    },
    {
      "code": "82011000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : SPADES AND SHOVELS"
    },
    {
      "code": "82013000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : MATTOCKS, PICKS, HOES AND RAKES"
    },
    {
      "code": "82014000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : AXES, BILL HOOKS AND SIMILAR HEWING TOOLS"
    },
    {
      "code": "82015000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : SECATEURS AND SIMILAR ONE-HANDED PRUNERS AND SHEARS (INCLUDING POULTRY SHEARS)"
    },
    {
      "code": "82016000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : HEDGE SHEARS, TWO-HANDED PRUNING SHEARS AND SIMILAR TWO-HANDED SHEARS"
    },
    {
      "code": "82019000",
      "description": "HAND TOOLS, THE FOLLOWING : SPADES, SHOVELS, MATTOCKS, PICKS, HOES, FORKS AND RAKES; AXES, BILL HOOKS AND SIMILAR HEWING TOOLS; SECATEURS AND PRUNERS OF ANY KIND; SCYTHES, SICKLES, HAY KNIVES, HEDGE SHEARS, TIMBER WEDGES AND OTHER TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY : OTHER HAND TOOLS OF A KIND USED IN AGRICULTURE, HORTICULTURE OR FORESTRY"
    },
    {
      "code": "8202",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)"
    },
    {
      "code": "820210",
      "description": "Hand saws"
    },
    {
      "code": "82021010",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES) - HAND SAWS : METAL WORKING HAND SAWS"
    },
    {
      "code": "82021020",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES) - HAND SAWS : WOOD WORKING AND SIMILAR HAND SAWS"
    },
    {
      "code": "82021090",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES) - HAND SAWS : OTHER"
    },
    {
      "code": "82022000",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)BAND SAW BLADES"
    },
    {
      "code": "82023100",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- CIRCULAR SAW BLADES (INCLUDING SLITTING OR SLOTTING SAW BLADES) : WITH WORKING PART OF STEEL"
    },
    {
      "code": "82023900",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- CIRCULAR SAW BLADES (INCLUDING SLITTING OR SLOTTING SAW BLADES) : OTHER, INCLUDING PARTS"
    },
    {
      "code": "82024000",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)CHAIN SAW BLADES"
    },
    {
      "code": "820291",
      "description": "Straight saw blades, for working metal"
    },
    {
      "code": "82029110",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- OTHER SAW BLADES : - STRAIGHT SAW BLADES, FOR WORKING METAL : MACHINE OPERATED"
    },
    {
      "code": "82029120",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- OTHER SAW BLADES : - STRAIGHT SAW BLADES, FOR WORKING METAL : HAND OPERATED"
    },
    {
      "code": "820299",
      "description": "Other"
    },
    {
      "code": "82029910",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- OTHER SAW BLADES : - OTHER : HACKSAW FRAMES"
    },
    {
      "code": "82029990",
      "description": "HAND SAWS; BLADES FOR SAWS OF ALL KINDS (INCLUDING SLITTING, SLOTTING OR TOOTHLESS SAW BLADES)- OTHER SAW BLADES : - OTHER : OTHER"
    },
    {
      "code": "8203",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPECUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS"
    },
    {
      "code": "82031000",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS - FILES, RASPS AND SIMILAR TOOLS"
    },
    {
      "code": "82032000",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS - PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS AND SIMILAR TOOLS"
    },
    {
      "code": "82033000",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS - METAL CUTTING SHEARS AND SIMILAR TOOLS"
    },
    {
      "code": "820340",
      "description": "Pipe-cutters, bolt croppers, perforating punches and similar tools"
    },
    {
      "code": "82034010",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS - PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR TOOLS : PERFORATING PUNCHES AND PIPE CUTTERS"
    },
    {
      "code": "82034090",
      "description": "FILES, RASPS, PLIERS (INCLUDING CUTTING PLIERS), PINCERS, TWEEZERS, METAL CUTTING SHEARS, PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR HAND TOOLS - PIPE- CUTTERS, BOLT CROPPERS, PERFORATING PUNCHES AND SIMILAR TOOLS : OTHER"
    },
    {
      "code": "8204",
      "description": "HAND-OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - Hand-operated spanners and wrenches"
    },
    {
      "code": "820411",
      "description": "Non-adjustable"
    },
    {
      "code": "82041110",
      "description": "HAND- OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - HAND- OPERATED SPANNERS AND WRENCHES : NON- ADJUSTABLE : SPANNERS"
    },
    {
      "code": "82041120",
      "description": "HAND- OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - HAND- OPERATED SPANNERS AND WRENCHES : NON- ADJUSTABLE : WRENCHES"
    },
    {
      "code": "820412",
      "description": "Adjustable"
    },
    {
      "code": "82041210",
      "description": "HAND- OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - HAND- OPERATED SPANNERS AND WRENCHES : ADJUSTABLE : SPANNERS"
    },
    {
      "code": "82041220",
      "description": "HAND- OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - HAND- OPERATED SPANNERS AND WRENCHES : ADJUSTABLE : WRENCHES"
    },
    {
      "code": "82042000",
      "description": "HAND- OPERATED SPANNERS AND WRENCHES (INCLUDING TORQUE METER WRENCHES BUT NOT INCLUDING TAP WRENCHES); INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES - INTERCHANGEABLE SPANNER SOCKETS, WITH OR WITHOUT HANDLES"
    },
    {
      "code": "8205",
      "description": "HAND TOOLS (INCLUDING GLAZIERS’ DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND-OR PEDALOPERATED GRINDING WHEELS WITH FRAMEWORKS"
    },
    {
      "code": "82051000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - DRILLING, THREADING OR TAPPING TOOLS"
    },
    {
      "code": "82052000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - HAMMERS AND SLEDGE HAMMERS"
    },
    {
      "code": "82053000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - PLANES, CHISELS, GOUGES AND SIMILAR CUTTING TOOLS FOR WORKING WOOD"
    },
    {
      "code": "82054000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - SCREWDRIVERS"
    },
    {
      "code": "820551",
      "description": "Household tools"
    },
    {
      "code": "82055110",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : - HOUSEHOLD TOOLS : CAN OR CORK OPENERS"
    },
    {
      "code": "82055190",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : - HOUSEHOLD TOOLS : OTHER"
    },
    {
      "code": "820559",
      "description": "Other"
    },
    {
      "code": "82055910",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : OTHER : GREASE GUNS (EXCLUDING COMPRESSED AIR TYPE)"
    },
    {
      "code": "82055920",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : OTHER : METAL WORKING HAND TOOLS"
    },
    {
      "code": "82055930",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : OTHER : HAND TOOLS FOR SPECIFIED USES, SUCH AS WATCH MAKING TOOLS, GOLDSMITH TOOLS"
    },
    {
      "code": "82055940",
      "description": "FORKS OTHER THAN THOSE OF HEADING 8201 AND 8215"
    },
    {
      "code": "82055990",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS)- OTHER HAND TOOLS (INCLUDING GLAZIERS DIAMONDS) : OTHER : OTHER"
    },
    {
      "code": "82056000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - BLOW LAMPS"
    },
    {
      "code": "82057000",
      "description": "HAND TOOLS (INCLUDING GLAZIERS DIAMONDS), NOT ELSEWHERE SPECIFIED OR INCLUDED; BLOW LAMPS; VICES; CLAMPS AND THE LIKE, OTHER THAN ACCESSORIES FOR AND PARTS OF, MACHINE TOOLS; ANVILS; PORTABLE FORGES; HAND- OR PEDAL- OPERATED GRINDING WHEELS WITH FRAMEWORKS - VICES, CLAMPS AND THE LIKE"
    },
    {
      "code": "820580",
      "description": "Anvils; portable forges; hand or pedal-operated grinding wheels with frameworks"
    },
    {
      "code": "820590",
      "description": "Other, including sets of articles of two or more subheadins of this heading"
    },
    {
      "code": "82059010",
      "description": "ANVILS AND PORTABLE FORGES"
    },
    {
      "code": "82059020",
      "description": "GRINDING WHEELS WITH FRAME, HAND-OR- PEDAL-OPERATED"
    },
    {
      "code": "82059030",
      "description": "SETS OF ARTICLES OF TWO OR MORE OF THE FOREGOING SUB-HEADINGS"
    },
    {
      "code": "82059090",
      "description": "OTHER"
    },
    {
      "code": "8206",
      "description": "TOOLS OF TWO OR MORE OF THE HEADINGS 8202 TO 8205, PUT UP IN SETS FOR RETAIL SALE"
    },
    {
      "code": "820600",
      "description": "Tools of two or more of the headings 8202 to 8205, put up in sets for retail sale"
    },
    {
      "code": "82060010",
      "description": "TOOLS OF TWO OR MORE OF THE HEADINGS 8202 TO 8205, PUT UP IN SETS FOR RETAIL SALE - TOOLS OF TWO OR MORE OF THE HEADINGS 8202 TO 8205, PUT UP IN SETS FOR RETAIL SALE : GARAGE TOOLS IN SETS"
    },
    {
      "code": "82060090",
      "description": "TOOLS OF TWO OR MORE OF THE HEADINGS 8202 TO 8205, PUT UP IN SETS FOR RETAIL SALE - TOOLS OF TWO OR MORE OF THE HEADINGS 8202 TO 8205, PUT UP IN SETS FOR RETAIL SALE : OTHER"
    },
    {
      "code": "8207",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER-OPERATED, OR FOR MACHINE-TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - Rock drilling or earth boring tools"
    },
    {
      "code": "82071300",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- ROCK DRILLING OR EARTH BORING TOOLS : WITH WORKING PART OF CERMETS"
    },
    {
      "code": "82071900",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- ROCK DRILLING OR EARTH BORING TOOLS : OTHER, INCLUDING PARTS"
    },
    {
      "code": "82072000",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - DIES FOR DRAWING OR EXTRUDING METAL"
    },
    {
      "code": "82073000",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - TOOLS FOR PRESSING, STAMPING OR PUNCHING"
    },
    {
      "code": "820740",
      "description": "Tools for tapping or threading"
    },
    {
      "code": "82074010",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR TAPPING OR THREADING : CHASERS"
    },
    {
      "code": "82074090",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR TAPPING OR THREADING : OTHER"
    },
    {
      "code": "82075000",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS TOOLS FOR DRILLING, OTHER THAN FOR ROCK DRILLING"
    },
    {
      "code": "820760",
      "description": "Tools for boring or broaching"
    },
    {
      "code": "82076010",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR BORING OR BROACHING : REAMERS"
    },
    {
      "code": "82076090",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR BORING OR BROACHING : OTHER"
    },
    {
      "code": "820770",
      "description": "Tools for milling"
    },
    {
      "code": "82077010",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR MILLING : CUTTERS"
    },
    {
      "code": "82077090",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS- TOOLS FOR MILLING : OTHER"
    },
    {
      "code": "82078000",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - TOOLS FOR TURNING"
    },
    {
      "code": "820790",
      "description": "Other interchangeable tools"
    },
    {
      "code": "82079010",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - OTHER INTERCHANGEABLE TOOLS : FOR METAL WORKING HAND TOOLS"
    },
    {
      "code": "82079020",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - OTHER INTERCHANGEABLE TOOLS : FOR WOOD WORKING HAND TOOLS"
    },
    {
      "code": "82079030",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - OTHER INTERCHANGEABLE TOOLS : LATHE TOOLS AND TOOL BELTS"
    },
    {
      "code": "82079090",
      "description": "INTERCHANGEABLE TOOLS FOR HAND TOOLS, WHETHER OR NOT POWER- OPERATED, OR FOR MACHINE- TOOLS (FOR EXAMPLE, FOR PRESSING, STAMPING, PUNCHING, TAPPING, THREADING, DRILLING, BORING, BROACHING, MILLING, TURNING OR SCREW DRIVING), INCLUDING DIES FOR DRAWING OR EXTRUDING METAL, AND ROCK DRILLING OR EARTH BORING TOOLS - OTHER INTERCHANGEABLE TOOLS : OTHER"
    },
    {
      "code": "8208",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES"
    },
    {
      "code": "82081000",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - FOR METAL WORKING"
    },
    {
      "code": "82082000",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - FOR WOOD WORKING"
    },
    {
      "code": "82083000",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - FOR KITCHEN APPLIANCES OR FOR MACHINES USED BY THE FOOD INDUSTRY"
    },
    {
      "code": "82084000",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - FOR AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINES"
    },
    {
      "code": "820890",
      "description": "Other"
    },
    {
      "code": "82089010",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - OTHER : KNIVES AND CUTTING BLADES FOR PAPER CUTTING MACHINES"
    },
    {
      "code": "82089020",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - OTHER : BELL SKIVING KNIVES"
    },
    {
      "code": "82089030",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - OTHER : BAND KNIVES FOR SPLITTING MACHINE"
    },
    {
      "code": "82089040",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - OTHER : CUTTING AND CLICKING DIES"
    },
    {
      "code": "82089090",
      "description": "KNIVES AND CUTTING BLADES, FOR MACHINES OR FOR MECHANICAL APPLIANCES - OTHER : OTHER"
    },
    {
      "code": "8209",
      "description": "PLATES, STICKS, TIPS AND THE LIKE FOR TOOLS, UNMOUNTED, OF CERMETS"
    },
    {
      "code": "820900",
      "description": "Plates, sticks, tips and the like for tools, unmounted, of cermets"
    },
    {
      "code": "82090010",
      "description": "PLATES, STICKS, TIPS AND THE LIKE FOR TOOLS, UNMOUNTED, OF CERMETS - PLATES, STICKS, TIPS AND THE LIKE FOR TOOLS, UNMOUNTED, OF CERMETS : TUNGSTEN CARBIDE TIPS"
    },
    {
      "code": "82090090",
      "description": "PLATES, STICKS, TIPS AND THE LIKE FOR TOOLS, UNMOUNTED, OF CERMETS - PLATES, STICKS, TIPS AND THE LIKE FOR TOOLS, UNMOUNTED, OF CERMETS : OTHER"
    },
    {
      "code": "8210",
      "description": "HAND-OPERATED MECHANICAL APPLIANCES, WEIGHING 10 KG OR LESS, USED IN THE PREPARATION, CONDITIONING OR SERVING OF FOOD OR DRINK"
    },
    {
      "code": "82100000",
      "description": "HAND-OPERATED MECHANICAL APPLIANCES, WEIGHING 10 KG. OR LESS, USED IN THE PREPARATION, CONDITIONING OR SERVING OF FOOD OR DRINK"
    },
    {
      "code": "8211",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR"
    },
    {
      "code": "82111000",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - SETS OF ASSORTED ARTICLES"
    },
    {
      "code": "82119100",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : TABLE KNIVES HAVING FIXED BLADES"
    },
    {
      "code": "82119200",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : OTHER KNIVES HAVING FIXED BLADES"
    },
    {
      "code": "821193",
      "description": "Knives having other than fixed blades"
    },
    {
      "code": "82119310",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : - KNIVES HAVING OTHER THAN FIXED BLADES : POCKET KNIVES"
    },
    {
      "code": "82119390",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : - KNIVES HAVING OTHER THAN FIXED BLADES : OTHER"
    },
    {
      "code": "82119400",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : BLADES"
    },
    {
      "code": "82119500",
      "description": "KNIVES WITH CUTTING BLADES, SERRATED OR NOT (INCLUDING PRUNING KNIVES), OTHER THAN KNIVES OF HEADING 8208, AND BLADES THEREFOR - OTHER : HANDLES OF BASE METAL"
    },
    {
      "code": "8212",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)"
    },
    {
      "code": "821210",
      "description": "Razors"
    },
    {
      "code": "82121010",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)- RAZORS : TWIN TYPE SHAVING"
    },
    {
      "code": "82121090",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)- RAZORS : OTHER"
    },
    {
      "code": "821220",
      "description": "Safety razor blades, including razor blade blanks in strips"
    },
    {
      "code": "82122011",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)- SAFETY RAZOR BLADES, INCLUDING RAZOR BLADE BLANKS IN STRIPS : - SAFETY RAZOR BLADES : DISPOSABLE CATRIDGE BLADES"
    },
    {
      "code": "82122019",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)- SAFETY RAZOR BLADES, INCLUDING RAZOR BLADE BLANKS IN STRIPS : - SAFETY RAZOR BLADES : OTHER"
    },
    {
      "code": "82122020",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)- SAFETY RAZOR BLADES, INCLUDING RAZOR BLADE BLANKS IN STRIPS : SAFETY RAZOR BLADE BLANKS, IN STRIPS"
    },
    {
      "code": "82129000",
      "description": "RAZORS AND RAZOR BLADES (INCLUDING RAZOR BLADE BLANKS IN STRIPS)OTHER PARTS"
    },
    {
      "code": "8213",
      "description": "SCISSORS, TAILORS SHEARS AND SIMILAR SHEARS, AND BLADES THEREFOR"
    },
    {
      "code": "82130000",
      "description": "SCISSORS, TAILORS’ SHEARS AND SIMILAR SHEARS, AND BLADES THEREFOR"
    },
    {
      "code": "8214",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES)"
    },
    {
      "code": "821410",
      "description": "Paper knives, letter openers, erasing knives, pencil sharpeners and blades therefor"
    },
    {
      "code": "82141010",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - PAPER KNIVES, LETTER OPENERS, ERASING KNIVES, PENCIL SHARPENERS AND BLADES THEREFOR : PAPER KNIVES, LETTER OPENERS, ERASING KNIVES, PENCIL SHARPENERS"
    },
    {
      "code": "82141090",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - PAPER KNIVES, LETTER OPENERS, ERASING KNIVES, PENCIL SHARPENERS AND BLADES THEREFOR : BLADES"
    },
    {
      "code": "821420",
      "description": "Manicure or pedicure sets and instruments (including nail files)"
    },
    {
      "code": "82142010",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) : NAIL CUTTERS"
    },
    {
      "code": "82142090",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) : OTHER"
    },
    {
      "code": "821490",
      "description": "Other"
    },
    {
      "code": "82149010",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - OTHER : HANDLES OF CUTLERY OF BASE METAL"
    },
    {
      "code": "82149090",
      "description": "OTHER ARTICLES OF CUTLERY (FOR EXAMPLE, HAIR CLIPPERS, BUTCHERS OR KITCHEN CLEAVERS, CHOPPERS AND MINCING KNIVES, PAPER KNIVES); MANICURE OR PEDICURE SETS AND INSTRUMENTS (INCLUDING NAIL FILES) - OTHER : OTHER"
    },
    {
      "code": "8215",
      "description": "SPOONS, FORKS, LADLES, SKIMMERS, CAKE-SERVERS, FISH-KNIVES, BUTTER-KNIVES, SUGAR TONGS AND SIMILAR KITCHEN OR TABLEWARE"
    },
    {
      "code": "82151000",
      "description": "SPOONS, FORKS, LADLES, SKIMMERS, CAKE- SERVERS, FISHKNIVES, BUTTER- KNIVES, SUGAR TONGS AND SIMILAR KITCHEN OR TABLEWARE SETS OF ASSORTED ARTICLES CONTAINING AT LEAST ONE ARTICLE PLATED WITH PRECIOUS METAL"
    },
    {
      "code": "82152000",
      "description": "SPOONS, FORKS, LADLES, SKIMMERS, CAKE- SERVERS, FISHKNIVES, BUTTER- KNIVES, SUGAR TONGS AND SIMILAR KITCHEN OR TABLEWARE OTHER SETS OF ASSORTED ARTICLES"
    },
    {
      "code": "82159100",
      "description": "SPOONS, FORKS, LADLES, SKIMMERS, CAKE- SERVERS, FISHKNIVES, BUTTER- KNIVES, SUGAR TONGS AND SIMILAR KITCHEN OR TABLEWARE - OTHER : PLATED WITH PRECIOUS METAL"
    },
    {
      "code": "82159900",
      "description": "SPOONS, FORKS, LADLES, SKIMMERS, CAKE- SERVERS, FISHKNIVES, BUTTER- KNIVES, SUGAR TONGS AND SIMILAR KITCHEN OR TABLEWARE - OTHER : OTHER"
    },
    {
      "code": "83",
      "description": "Miscellaneous articles of base metal"
    },
    {
      "code": "8301",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL"
    },
    {
      "code": "83011000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - PADLOCKS"
    },
    {
      "code": "83012000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - LOCKS OF A KIND USED FOR MOTOR VEHICLES"
    },
    {
      "code": "83013000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - LOCKS OF A KIND USED FOR FURNITURE"
    },
    {
      "code": "830140",
      "description": "Other locks"
    },
    {
      "code": "83014010",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - OTHER LOCKS: COMBINATION LOCKS"
    },
    {
      "code": "83014090",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - OTHER LOCKS: OTHER"
    },
    {
      "code": "83015000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS"
    },
    {
      "code": "83016000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - PARTS"
    },
    {
      "code": "83017000",
      "description": "PADLOCKS AND LOCKS (KEY, COMBINATION OR ELECTRICALLY OPERATED), OF BASE METAL; CLASPS AND FRAMES WITH CLASPS, INCORPORATING LOCKS, OF BASE METAL; KEYS FOR ANY OF THE FOREGOING ARTICLES, OF BASE METAL - KEYS PRESENTED SEPARATELY"
    },
    {
      "code": "8302",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT-RACKS, HAT-PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL"
    },
    {
      "code": "830210",
      "description": "Hinges"
    },
    {
      "code": "83021010",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - HINGES:OF STEEL"
    },
    {
      "code": "83021020",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - HINGES:OF BRASS"
    },
    {
      "code": "83021090",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - HINGES:OTHER"
    },
    {
      "code": "83022000",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL CASTORS"
    },
    {
      "code": "830230",
      "description": "Other mountings, fittings and similar articles suitable for motor vehicles"
    },
    {
      "code": "83023010",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR MOTOR VEHICLES:CURVE DRIVE STAKES"
    },
    {
      "code": "83023090",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR MOTOR VEHICLES:OTHER"
    },
    {
      "code": "830241",
      "description": "Suitable for buildings"
    },
    {
      "code": "83024110",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES: - SUITABLE FOR BUILDINGS:FITTINGS FOR DOORS AND WINDOWS"
    },
    {
      "code": "83024120",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES: - SUITABLE FOR BUILDINGS:TOWER BOLTS"
    },
    {
      "code": "83024190",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES: - SUITABLE FOR BUILDINGS:OTHER"
    },
    {
      "code": "83024200",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES: OTHER, SUITABLE FOR FURNITURE"
    },
    {
      "code": "83024900",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL - OTHER MOUNTINGS, FITTINGS AND SIMILAR ARTICLES: OTHER"
    },
    {
      "code": "83025000",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES"
    },
    {
      "code": "83026000",
      "description": "BASE METAL MOUNTINGS, FITTINGS AND SIMILAR ARTICLES SUITABLE FOR FURNITURE, DOORS, STAIRCASES, WINDOWS, BLINDS, COACHWORK, SADDLERY, TRUNKS, CHESTS, CASKETS OR THE LIKE; BASE METAL HAT - RACKS, HAT - PEGS, BRACKETS AND SIMILAR FIXTURES; CASTORS WITH MOUNTINGS OF BASE METAL; AUTOMATIC DOOR CLOSERS OF BASE METAL AUTOMATIC DOOR CLOSERS"
    },
    {
      "code": "8303",
      "description": "ARMOURED OR REINFORCED SAFES, STRONG - BOXES - AND DOORS AND SAFE DEPOSIT LOCKERS FOR STRONG - ROOMS, CASH OR DEED BOXES AND THE LIKE, OF BASE METAL"
    },
    {
      "code": "83030000",
      "description": "ARMOURED OR REINFORCED SAFES, STRONG-BOXES AND DOORS AND SAFE DEPOSIT LOCKERS FOR STRONG-ROOMS, CASH OR DEED BOXES AND THE LIKE, OF BASE METAL"
    },
    {
      "code": "8304",
      "description": "FILING, CABINETS, CARD - INDEX CABINETS, PAPER TRAYS, PAPER RESTS, PEN TRAYS, OFFICE-STAMP STANDS AND SIMILAR OFFICE OR DESK EQUIPMENT, OF BASE METAL, OTHER THAN OFFICE FURNITURE OF HEADING 9403"
    },
    {
      "code": "83040000",
      "description": "FILING, CABINETS, CARD-INDEX CABINETS, PAPER TRAYS, PAPER RESTS, PEN TRAYS, OFFICE-STAMP STANDS AND SIMILAR OFFICE OR DESK EQUIPMENT, OF BASE METAL, OTHER THAN OFFICE FURNITURE OF HEADING 9403"
    },
    {
      "code": "8305",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL"
    },
    {
      "code": "83051000",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL - FITTINGS FOR LOOSE-LEAF BINDERS OR FILES"
    },
    {
      "code": "83052000",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL - STAPLES IN STRIPS"
    },
    {
      "code": "830590",
      "description": "Other, including parts"
    },
    {
      "code": "83059010",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL - OTHER, INCLUDING PARTS:PINS (OTHER THAN THOSE OF HEADING 7317)"
    },
    {
      "code": "83059020",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL - OTHER, INCLUDING PARTS : CLIPS"
    },
    {
      "code": "83059090",
      "description": "FITTINGS FOR LOOSE-LEAF BINDERS OR FILES, LETTER CLIPS, LETTER CORNERS, PAPER CLIPS, INDEXING TAGS AND SIMILAR OFFICE ARTICLES, OF BASE METAL; STAPLES IN STRIPS (FOR EXAMPLE, FOR OFFICES, UPHOLSTERY, PACKAGING), OF BASE METAL - OTHER, INCLUDING PARTS:OTHER"
    },
    {
      "code": "8306",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL"
    },
    {
      "code": "83061000",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - BELLS, GONGS AND THE LIKE"
    },
    {
      "code": "830621",
      "description": "Plated with precious metal"
    },
    {
      "code": "83062110",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - PLATED WITH PRECIOUS METAL:STATUETTES"
    },
    {
      "code": "83062120",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - PLATED WITH PRECIOUS METAL:TROPHIES"
    },
    {
      "code": "83062190",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - PLATED WITH PRECIOUS METAL:OTHER"
    },
    {
      "code": "830629",
      "description": "Other"
    },
    {
      "code": "83062910",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - OTHER:STATUETTES"
    },
    {
      "code": "83062920",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - OTHER:TROPHIES"
    },
    {
      "code": "83062990",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - STATUETTES AND OTHER ORNAMENTS: - OTHER:OTHER"
    },
    {
      "code": "83063000",
      "description": "BELLS, GONGS AND THE LIKE, NON-ELECTRIC, OF BASE METAL; STATUETTES AND OTHER ORNAMENTS, OF BASE METAL; PHOTOGRAPH, PICTURE OR SIMILAR FRAMES, OF BASE METAL; MIRRORS OF BASE METAL - PHOTOGRAPH, PICTURE OR SIMILAR FRAMES; MIRRORS"
    },
    {
      "code": "8307",
      "description": "FLEXIBLE TUBING OF BASE METAL, WITH OR WITHOUT"
    },
    {
      "code": "83071000",
      "description": "FLEXIBLE TUBING OF BASE METAL, WITH OR WITHOUT FITTINGS OF IRON OR STEEL"
    },
    {
      "code": "83079000",
      "description": "FLEXIBLE TUBING OF BASE METAL, WITH OR WITHOUT FITTINGS OF OTHER BASE METAL"
    },
    {
      "code": "8308",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLECLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS"
    },
    {
      "code": "830810",
      "description": "Hooks, eyes and eyelets"
    },
    {
      "code": "83081010",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - HOOKS, EYES AND EYELETS:HOOKS AND EYES"
    },
    {
      "code": "83081021",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - HOOKS, EYES AND EYELETS: - EYELETS: FOR FOOTWEAR"
    },
    {
      "code": "83081029",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - HOOKS, EYES AND EYELETS: - EYELETS: OTHER"
    },
    {
      "code": "83082000",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - TUBULAR OR BIFURCATED RIVETS"
    },
    {
      "code": "830890",
      "description": "Other, including parts"
    },
    {
      "code": "83089011",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - BUCKLES:FOR FOOTWEAR"
    },
    {
      "code": "83089019",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - BUCKLES:OTHER"
    },
    {
      "code": "83089020",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: IMITATION ZARI SPANGLES"
    },
    {
      "code": "83089031",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - BEADS AND SPANGLES OF BASE METAL: FOR GARMENTS, MADE UPS, KNITWEAR, PLASTIC AND LEATHER GOODS"
    },
    {
      "code": "83089039",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - BEADS AND SPANGLES OF BASE METAL: OTHER"
    },
    {
      "code": "83089040",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: FITTINGS FOR TRAVEL REQUISITES AND LEATHER GOODS"
    },
    {
      "code": "83089091",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - OTHER: FOR GARMENTS, MADE UPS, KNITWEAR, PLASTIC AND LEATHER GOODS"
    },
    {
      "code": "83089099",
      "description": "CLASPS, FRAMES WITH CLASPS, BUCKLES, BUCKLE-CLASPS, HOOKS, EYES, EYELETS AND THE LIKE, OF BASE METAL, OF A KIND USED FOR CLOTHING, FOOTWEAR, AWNINGS, HANDBAGS, TRAVEL GOODS OR OTHER MADE UP ARTICLES; TUBULAR OR BIFURCATED RIVETS, OF BASE METAL; BEADS AND SPANGLES, OF BASE METALS - OTHER, INCLUDING PARTS: - OTHER: OTHER"
    },
    {
      "code": "8309",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL"
    },
    {
      "code": "83091000",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL CROWN CORKS"
    },
    {
      "code": "830990",
      "description": "Other"
    },
    {
      "code": "83099010",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL - OTHER : PILFER PROOF CAPS FOR PACKAGING, ALL SORTS, WITH OR WITHOUT WASHERS OR OTHER FITTINGS, OF CORK, RUBBER, POLYETHYLENE OR ANY OTHER MATERIAL"
    },
    {
      "code": "83099020",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL - OTHER:ALUMINIUM CAPS, SEALS, CAPSULES AND CLOSERS"
    },
    {
      "code": "83099030",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL - OTHER:OTHER SEALS"
    },
    {
      "code": "83099090",
      "description": "STOPPERS, CAPS AND LIDS (INCLUDING CROWN CORKS, SCREW CAPS AND POURING STOPPERS), CAPSULES FOR BOTTLES, THREADED BUNGS, BUNG COVERS, SEALS AND OTHER PACKING ACCESSORIES, OF BASE METAL - OTHER:OTHER"
    },
    {
      "code": "8310",
      "description": "SIGN-PLATES, NAME-PLATES, ADDRESS-PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405"
    },
    {
      "code": "831000",
      "description": "Sign-plates, name-plates, address-plates and similar plates, numbers, letters and other symbols, of base metal, excluding those of heading 9405"
    },
    {
      "code": "83100010",
      "description": "SIGN - PLATES, NAME - PLATES, ADDRESS - PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405 - SIGN - PLATES, NAME - PLATES, ADDRESS - PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405:ENAMEL IRON SIGNBOARD"
    },
    {
      "code": "83100090",
      "description": "SIGN - PLATES, NAME - PLATES, ADDRESS - PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405 - SIGN - PLATES, NAME - PLATES, ADDRESS - PLATES AND SIMILAR PLATES, NUMBERS, LETTERS AND OTHER SYMBOLS, OF BASE METAL, EXCLUDING THOSE OF HEADING 9405:OTHER"
    },
    {
      "code": "8311",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING"
    },
    {
      "code": "83111000",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING - COATED ELECTRODES OF BASE METAL, FOR ELECTRIC ARC - WELDING"
    },
    {
      "code": "83112000",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING - CORED WIRE OF BASE METAL, FOR ELECTRIC ARC - WELDING"
    },
    {
      "code": "831130",
      "description": "Coated rods and cored wire, of base metal, for soldering, brazing or welding by flame"
    },
    {
      "code": "83113010",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING - COATED RODS AND CORED WIRE, OF BASE METAL, FOR SOLDERING, BRAZING OR WELDING BY FLAME:WIRE AND RODS OF AGGLOMERATED BASE METAL"
    },
    {
      "code": "83113090",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING - COATED RODS AND CORED WIRE, OF BASE METAL, FOR SOLDERING, BRAZING OR WELDING BY FLAME:OTHER"
    },
    {
      "code": "83119000",
      "description": "WIRE, RODS, TUBES, PLATES, ELECTRODES AND SIMILAR PRODUCTS, OF BASE METAL OR OF METAL CARBIDES, COATED OR CORED WITH FLUX MATERIAL, OF A KIND USED FOR SOLDERING, BRAZING, WELDING OR DEPOSITION OF METAL OR OF METAL CARBIDES; WIRE AND RODS, OF AGGLOMERATED BASE METAL POWDER, USED FOR METAL SPRAYING OTHER"
    },
    {
      "code": "84",
      "description": "Nuclear reactors, boilers, machinery and mechanical appliances; parts thereof"
    },
    {
      "code": "8401",
      "description": "NUCLEAR REACTORS; FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED, FOR NUCLEAR REACTORS; MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION"
    },
    {
      "code": "84011000",
      "description": "NUCLEAR REACTORS; FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED, FOR NUCLEAR REACTORS; MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION NUCLEAR REACTORS"
    },
    {
      "code": "84012000",
      "description": "NUCLEAR REACTORS; FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED, FOR NUCLEAR REACTORS; MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION, AND PARTS THEREOF"
    },
    {
      "code": "84013000",
      "description": "NUCLEAR REACTORS; FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED, FOR NUCLEAR REACTORS; MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED"
    },
    {
      "code": "84014000",
      "description": "NUCLEAR REACTORS; FUEL ELEMENTS (CARTRIDGES), NON-IRRADIATED, FOR NUCLEAR REACTORS; MACHINERY AND APPARATUS FOR ISOTOPIC SEPARATION PARTS OF NUCLEAR REACTORS"
    },
    {
      "code": "8402",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPER-HEATED WATER BOILERS - Steam or other vapour generating boilers"
    },
    {
      "code": "84021100",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - STEAM OR OTHER VAPOUR GENERATING BOILERS: WATERTUBE BOILERS WITH A STEAM PRODUCTION EXCEEDING 45 T PER HOUR"
    },
    {
      "code": "84021200",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - STEAM OR OTHER VAPOUR GENERATING BOILERS: WATER TUBE BOILERS WITH A STEAM PRODUCTION NOT EXCEEDING 45 T PER HOUR"
    },
    {
      "code": "840219",
      "description": "Other vapour generating boilers, including hybrid boilers"
    },
    {
      "code": "84021910",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - STEAM OR OTHER VAPOUR GENERATING BOILERS: OTHER VAPOUR GENERATING BOILERS, INCLUDING HYBRID BOILERS: FIRE TUBE HORIZONTAL (LANCASHIRE) BOILERS"
    },
    {
      "code": "84021920",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - STEAM OR OTHER VAPOUR GENERATING BOILERS: OTHER VAPOUR GENERATING BOILERS, INCLUDING HYBRID BOILERS:FIRE TUBE BOILERS VERTICAL"
    },
    {
      "code": "84021990",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - STEAM OR OTHER VAPOUR GENERATING BOILERS: OTHER VAPOUR GENERATING BOILERS, INCLUDING HYBRID BOILERS:OTHER"
    },
    {
      "code": "84022000",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - SUPER-HEATED WATER BOILERS"
    },
    {
      "code": "840290",
      "description": "Parts"
    },
    {
      "code": "84029010",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - PARTS: PARTS OF FIRE TUBE BOILERS"
    },
    {
      "code": "84029020",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - PARTS: PARTS OF WATERTUBE BOILERS"
    },
    {
      "code": "84029090",
      "description": "STEAM OR OTHER VAPOUR GENERATING BOILERS (OTHER THAN CENTRAL HEATING HOT WATER BOILERS CAPABLE ALSO OF PRODUCING LOW PRESSURE STEAM); SUPERHEATED WATER BOILERS - PARTS: OTHER"
    },
    {
      "code": "8403",
      "description": "CENTRAL HEATING BOILERS OTHER THAN THOSEOF HEADING 8402"
    },
    {
      "code": "84031000",
      "description": "CENTRAL HEATING BOILERS OTHER THAN THOSE OF HEADING 8402 - BOILERS"
    },
    {
      "code": "84039000",
      "description": "CENTRAL HEATING BOILERS OTHER THAN THOSE OF HEADING 8402 - PARTS"
    },
    {
      "code": "8404",
      "description": "AUXILIARY PLANT FOR USE WITH BOILERS OF HEADING 8402 OR 8403 (FOR EXAMPLE, ECONOMISERS, SUPER-HEATERS, SOOT REMOVERS, GAS RECOVERERS); CONDENSERS FOR STEAM OR OTHER VAPOUR POWER UNITS"
    },
    {
      "code": "84041000",
      "description": "AUXILIARY PLANT FOR USE WITH BOILERS OF HEADING 8402 OR 8403 (FOR EXAMPLE, ECONOMISERS, SUPERHEATERS, SOOT REMOVERS, GAS RECOVERERS); CONDENSERS FOR STEAM OR OTHER VAPOUR POWER UNITS AUXILIARY PLANTS FOR USE WITH BOILERS OF HEADING 8402 OR 8403"
    },
    {
      "code": "84042000",
      "description": "AUXILIARY PLANT FOR USE WITH BOILERS OF HEADING 8402 OR 8403 (FOR EXAMPLE, ECONOMISERS, SUPERHEATERS, SOOT REMOVERS, GAS RECOVERERS); CONDENSERS FOR STEAM OR OTHER VAPOUR POWER UNITS CONDENSERS FOR STEAM OR OTHER VAPOUR POWER UNITS"
    },
    {
      "code": "84049000",
      "description": "AUXILIARY PLANT FOR USE WITH BOILERS OF HEADING 8402 OR 8403 (FOR EXAMPLE, ECONOMISERS, SUPERHEATERS, SOOT REMOVERS, GAS RECOVERERS); CONDENSERS FOR STEAM OR OTHER VAPOUR POWER UNITS PARTS"
    },
    {
      "code": "8405",
      "description": "PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS"
    },
    {
      "code": "840510",
      "description": "Producer gas or water gas generators, with or without their purifiers; acetylene gas generators and similar water process gas generators, with or without their purifiers"
    },
    {
      "code": "84051010",
      "description": "PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS - PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS: PRODUCER GAS OR WATER GAS GENERATORS"
    },
    {
      "code": "84051020",
      "description": "PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS - PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS:ACETYLENE GAS GENERATORS"
    },
    {
      "code": "84051090",
      "description": "PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS - PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS: OTHER"
    },
    {
      "code": "84059000",
      "description": "PRODUCER GAS OR WATER GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS; ACETYLENE GAS GENERATORS AND SIMILAR WATER PROCESS GAS GENERATORS, WITH OR WITHOUT THEIR PURIFIERS - PARTS"
    },
    {
      "code": "8406",
      "description": "STEAM TURBINES AND OTHER VAPOUR TURBINES"
    },
    {
      "code": "84061000",
      "description": "STEAM TURBINES AND OTHER VAPOUR TURBINES TURBINES FOR MARINE PROPULSION"
    },
    {
      "code": "84068100",
      "description": "STEAM TURBINES AND OTHER VAPOUR TURBINES - OTHER TURBINES: OF AN OUTPUT EXCEEDING 40 MW"
    },
    {
      "code": "84068200",
      "description": "STEAM TURBINES AND OTHER VAPOUR TURBINES - OTHER TURBINES: OF AN OUTPUT NOT EXCEEDING 40 MW"
    },
    {
      "code": "84069000",
      "description": "STEAM TURBINES AND OTHER VAPOUR TURBINES - PARTS"
    },
    {
      "code": "8407",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES"
    },
    {
      "code": "84071000",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES AIRCRAFT ENGINES"
    },
    {
      "code": "84072100",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - MARINE PROPULSION ENGINES: OUTBOARD MOTORS"
    },
    {
      "code": "84072900",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - MARINE PROPULSION ENGINES: OTHER"
    },
    {
      "code": "840731",
      "description": "Of a cylinder capacity not exceeding 50 cc"
    },
    {
      "code": "84073110",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY NOT EXCEEDING 50 CC: FOR MOTOR CYCLES"
    },
    {
      "code": "84073190",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY NOT EXCEEDING 50 CC: OTHER"
    },
    {
      "code": "840732",
      "description": "Of a cylinder capacity exceeding 50 cc but not exceeding 250 cc"
    },
    {
      "code": "84073210",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC: FOR MOTOR CYCLES"
    },
    {
      "code": "84073290",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC: OTHER"
    },
    {
      "code": "840733",
      "description": "Of a cylinder capacity exceeding 250 cc but not exceeding"
    },
    {
      "code": "84073310",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 1,000 CC : FOR MOTOR CARS"
    },
    {
      "code": "84073320",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 1,000 CC : FOR MOTOR CYCLES"
    },
    {
      "code": "84073390",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 1,000 CC : OTHER"
    },
    {
      "code": "840734",
      "description": "Of a cylinder capacity exceeding 1,000 cc"
    },
    {
      "code": "84073410",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 1,000 CC:FOR MOTOR CARS"
    },
    {
      "code": "84073490",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - RECIPROCATING PISTON ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF A CYLINDER CAPACITY EXCEEDING 1,000 CC:OTHER"
    },
    {
      "code": "840790",
      "description": "Other engines"
    },
    {
      "code": "84079010",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - OTHER ENGINES: PETROL ENGINES"
    },
    {
      "code": "84079020",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - OTHER ENGINES: KEROSENE ENGINES"
    },
    {
      "code": "84079090",
      "description": "SPARK-IGNITION RECIPROCATING OR ROTARY INTERNAL COMBUSTION PISTON ENGINES - OTHER ENGINES: OTHER"
    },
    {
      "code": "8408",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES)"
    },
    {
      "code": "840810",
      "description": "Marine propulsion engines"
    },
    {
      "code": "84081010",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - MARINE PROPULSION ENGINES:OUTBOARD ENGINES"
    },
    {
      "code": "84081091",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - MARINE PROPULSION ENGINES: OTHER OF A CYLINDER CAPACITY NOT EXCEEDING 100 CC"
    },
    {
      "code": "84081092",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - MARINE PROPULSION ENGINES: OTHER OF A CYLINDER CAPACITY EXCEEDING 100 CC BUT NOT EXCEEDING 250 CC"
    },
    {
      "code": "84081093",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - MARINE PROPULSION ENGINES: OTHER OF A CYLINDER CAPACITY EXCEEDING 250 CC"
    },
    {
      "code": "840820",
      "description": "Engines of a kind used for the propulsion of vehicles of Chapter 87"
    },
    {
      "code": "84082010",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: OF CYLINDER CAPACITY NOT EXCEEDING 250 CC"
    },
    {
      "code": "84082020",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - ENGINES OF A KIND USED FOR THE PROPULSION OF VEHICLES OF CHAPTER 87: ENGINES OF CYLINDER CAPACITY EXCEEDING 250 CC"
    },
    {
      "code": "840890",
      "description": "Other engines"
    },
    {
      "code": "84089010",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - OTHER ENGINES: STATIONARY ENGINES OF CYLINDER CAPACITY EXCEEDING 50 CC"
    },
    {
      "code": "84089090",
      "description": "COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES) - OTHER ENGINES: OTHER"
    },
    {
      "code": "8409",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408"
    },
    {
      "code": "84091000",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 FOR AIRCRAFT ENGINES"
    },
    {
      "code": "840991",
      "description": "Suitable for use solely or principally with spark-ignition internal combustion piston engines"
    },
    {
      "code": "84099111",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : VALVES, INLET AND EXHAUST"
    },
    {
      "code": "84099112",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : PISTONS"
    },
    {
      "code": "84099113",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : PISTON RINGS"
    },
    {
      "code": "84099114",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : PISTON ASSEMBLIES"
    },
    {
      "code": "84099120",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: FUEL INJECTION EQUIPMENT EXCLUDING INJECTION PUMPS"
    },
    {
      "code": "84099191",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: OTHER : OF PETROL ENGINES FOR MOTOR VEHICLES"
    },
    {
      "code": "84099192",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: OTHER : OF OTHER PETROL ENGINES"
    },
    {
      "code": "84099193",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: OTHER : OF KEROSENE ENGINES"
    },
    {
      "code": "84099194",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: OTHER : OF GAS ENGINES"
    },
    {
      "code": "84099199",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : 8409 91 - SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH SPARK IGNITION INTERNAL COMBUSTION PISTON ENGINES: OTHER : OTHER"
    },
    {
      "code": "840999",
      "description": "Other"
    },
    {
      "code": "84099911",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : VALVE, INLET AND EXHAUST"
    },
    {
      "code": "84099912",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES :PISTONS"
    },
    {
      "code": "84099913",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES :PISTON RINGS"
    },
    {
      "code": "84099914",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : VALVES, INLET AND EXHAUST, PISTON, PISTON RINGS, PISTON ASSEMBLIES : PISTON ASSEMBLIES"
    },
    {
      "code": "84099920",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : FUEL NOZZLES"
    },
    {
      "code": "84099930",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : FUEL INJECTION EQUIPMENT EXCLUDING INJECTION PUMPS"
    },
    {
      "code": "84099941",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : OTHER PARTS OF DIESEL ENGINE: OF DIESEL ENGINES FOR MOTOR VEHICLES"
    },
    {
      "code": "84099942",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : OTHER PARTS OF DIESEL ENGINE: OF OUTBOARD ENGINE"
    },
    {
      "code": "84099949",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : OTHER PARTS OF DIESEL ENGINE: OTHER"
    },
    {
      "code": "84099990",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE ENGINES OF HEADING 8407 OR 8408 - OTHER : OTHER : OTHER"
    },
    {
      "code": "8410",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, ANDREGULATORS THEREFOR - Hydraulic turbines and water wheels"
    },
    {
      "code": "84101100",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - HYDRAULIC TURBINES AND WATER WHEELS : OF A POWER NOT EXCEEDING 1,000 KW"
    },
    {
      "code": "841012",
      "description": "Of a power exceeding 1,000 kW but not exceeding 10,000 kW"
    },
    {
      "code": "84101210",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - HYDRAULIC TURBINES AND WATER WHEELS : OF A POWER EXCEEDING 1,000 KW BUT NOT EXCEEDING 10,000 KW: OF POWER EXCEEDING 1,000 KW BUT NOT EXCEEDING 5,000 KW"
    },
    {
      "code": "84101220",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - HYDRAULIC TURBINES AND WATER WHEELS : OF A POWER EXCEEDING 1,000 KW BUT NOT EXCEEDING 10,000 KW: OF POWER EXCEEDING 5,000 KW BUT NOT EXCEEDING 10,000 KW"
    },
    {
      "code": "841013",
      "description": "Of a power exceeding 10,000 kW"
    },
    {
      "code": "84101310",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - OF A POWER EXCEEDING 10,000 KW: OF POWER EXCEEDING 10,000 KW BUT NOT EXCEEDING 30,000 KW"
    },
    {
      "code": "84101320",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - OF A POWER EXCEEDING 10,000 KW: OF POWER EXCEEDING 30,000 KW BUT NOT EXCEEDING 80,000 KW"
    },
    {
      "code": "84101390",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - OF A POWER EXCEEDING 10,000 KW: OF POWER EXCEEDING 80,000 KW"
    },
    {
      "code": "84109000",
      "description": "HYDRAULIC TURBINES, WATER WHEELS, AND REGULATORS THEREFOR - PARTS, INCLUDING REGULATORS"
    },
    {
      "code": "8411",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - Turbo-jets"
    },
    {
      "code": "84111100",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - TURBO-JETS: OF A THRUST NOT EXCEEDING 25KN"
    },
    {
      "code": "84111200",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - TURBO-JETS: OF A THRUST EXCEEDING 25 KN"
    },
    {
      "code": "84112100",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - TURBO-PROPELLERS: OF A POWER NOT EXCEEDING 1,100 KW"
    },
    {
      "code": "84112200",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - TURBO-PROPELLERS: OF A POWER EXCEEDING 1,100 KW"
    },
    {
      "code": "84118100",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES: OF A POWER NOT EXCEEDING 5,000 KW"
    },
    {
      "code": "841182",
      "description": "Of a power exceeding 5,000 kW"
    },
    {
      "code": "84118210",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 5,000 KW BUT NOT EXCEEDING 15,000 KW"
    },
    {
      "code": "84118220",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 15,000 KW BUT NOT EXCEEDING 30,000 KW"
    },
    {
      "code": "84118230",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 30,000 KW BUT NOT EXCEEDING 60,000 KW"
    },
    {
      "code": "84118240",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 60,000 KW BUT NOT EXCEEDING 90,000 KW"
    },
    {
      "code": "84118250",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 90,000 KW BUT NOT EXCEEDING 1,15,000 KW"
    },
    {
      "code": "84118260",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - OTHER GAS TURBINES:- OF A POWER EXCEEDING 5,000 KW: OF POWER EXCEEDING 1,15,000 KW"
    },
    {
      "code": "84119100",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - PARTS: OF TURBO-JETS OR TURBO-PROPELLERS"
    },
    {
      "code": "84119900",
      "description": "TURBO-JETS, TURBO-PROPELLERS AND OTHER GAS TURBINES - PARTS: OTHER"
    },
    {
      "code": "8412",
      "description": "OTHER ENGINES AND MOTORS"
    },
    {
      "code": "84121000",
      "description": "OTHER ENGINES AND MOTORS REACTION ENGINES OTHER THAN TURBO JETS"
    },
    {
      "code": "84122100",
      "description": "OTHER ENGINES AND MOTORS - HYDRAULIC POWER ENGINES AND MOTORS: LINEAR ACTING (CYLINDERS)"
    },
    {
      "code": "841229",
      "description": "Other"
    },
    {
      "code": "84122910",
      "description": "OTHER ENGINES AND MOTORS HYDRAULIC POWER ENGINES AND MOTORS: OTHER : HYDROJET (HYDRAULIC JET ENGINES)"
    },
    {
      "code": "84122990",
      "description": "OTHER ENGINES AND MOTORS HYDRAULIC POWER ENGINES AND MOTORS: OTHER : OTHER"
    },
    {
      "code": "84123100",
      "description": "OTHER ENGINES AND MOTORS - PNEUMATIC POWER ENGINES AND MOTORS: LINEAR ACTING (CYLINDERS)"
    },
    {
      "code": "84123900",
      "description": "OTHER ENGINES AND MOTORS - PNEUMATIC POWER ENGINES AND MOTORS: OTHER"
    },
    {
      "code": "841280",
      "description": "Other"
    },
    {
      "code": "84128011",
      "description": "OTHER ENGINES AND MOTORS OTHER: STEAM OR OTHER VAPOUR POWER : STATIONARY"
    },
    {
      "code": "84128019",
      "description": "OTHER ENGINES AND MOTORS OTHER: STEAM OR OTHER VAPOUR POWER : OTHER"
    },
    {
      "code": "84128020",
      "description": "OTHER ENGINES AND MOTORS OTHER: MOTORS, SPRING OPERATED EXCLUDING CLOCK AND WATCH MOVEMENTS"
    },
    {
      "code": "84128030",
      "description": "OTHER ENGINES AND MOTORS OTHER: WIND TURBINE OR ENGINE"
    },
    {
      "code": "84128090",
      "description": "OTHER ENGINES AND MOTORS OTHER: OTHER"
    },
    {
      "code": "841290",
      "description": "Parts"
    },
    {
      "code": "84129010",
      "description": "OTHER ENGINES AND MOTORS PARTS: OF STEAM ENGINES INCORPORATING BOILERS"
    },
    {
      "code": "84129020",
      "description": "OTHER ENGINES AND MOTORS PARTS: OF OTHER STEAM ENGINES AND OTHER VAPOUR POWER UNITS NOT INCORPORATING BOILERS"
    },
    {
      "code": "84129030",
      "description": "OTHER ENGINES AND MOTORS PARTS: OF HYDRAULIC ENGINES AND MOTORS"
    },
    {
      "code": "84129090",
      "description": "OTHER ENGINES AND MOTORS PARTS: OTHER"
    },
    {
      "code": "8413",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - Pumps fitted or designed to be fitted with a measuring device"
    },
    {
      "code": "841311",
      "description": "Pumps for dispensing fuel or lubricants, of the type used in filling stations or in garages"
    },
    {
      "code": "84131110",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PUMPS FITTED OR DESIGNED TO BE FITTED WITH A MEASURING DEVICE: 8413 11 - PUMPS FOR DISPENSING FUEL OR LUBRICANTS, OF THE TYPE USED IN FILLING STATIONS OR IN GARAGES: HAND PUMPS"
    },
    {
      "code": "84131191",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PUMPS FITTED OR DESIGNED TO BE FITTED WITH A MEASURING DEVICE: 8413 11 - PUMPS FOR DISPENSING FUEL OR LUBRICANTS, OF THE TYPE USED IN FILLING STATIONS OR IN GARAGES: OTHER :PUMPS FOR DISPENSING FUEL"
    },
    {
      "code": "84131199",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PUMPS FITTED OR DESIGNED TO BE FITTED WITH A MEASURING DEVICE: 8413 11 - PUMPS FOR DISPENSING FUEL OR LUBRICANTS, OF THE TYPE USED IN FILLING STATIONS OR IN GARAGES: OTHER :OTHER"
    },
    {
      "code": "841319",
      "description": "Other"
    },
    {
      "code": "84131910",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PUMPS FITTED OR DESIGNED TO BE FITTED WITH A MEASURING DEVICE: OTHER :- OTHER: HAND PUMPS"
    },
    {
      "code": "84131990",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PUMPS FITTED OR DESIGNED TO BE FITTED WITH A MEASURING DEVICE: OTHER :- OTHER: OTHER"
    },
    {
      "code": "84132000",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - HAND PUMPS, OTHER THAN THOSE OF SUB-HEADING 8413 11 OR 8413 19"
    },
    {
      "code": "841330",
      "description": "Fuel, lubricating or cooling medium pumps for internal combustion piston engines"
    },
    {
      "code": "84133010",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - FUEL, LUBRICATING OR COOLING MEDIUM PUMPS FOR INTERNAL COMBUSTION PISTON ENGINES: INJECTION PUMPS FOR DIESEL ENGINES"
    },
    {
      "code": "84133020",
      "description": "- PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - FUEL, LUBRICATING OR COOLING MEDIUM PUMPS FOR INTERNAL COMBUSTION PISTON ENGINES: OIL PUMP"
    },
    {
      "code": "84133030",
      "description": "- PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - FUEL, LUBRICATING OR COOLING MEDIUM PUMPS FOR INTERNAL COMBUSTION PISTON ENGINES: WATER PUMP"
    },
    {
      "code": "84133090",
      "description": "- PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - FUEL, LUBRICATING OR COOLING MEDIUM PUMPS FOR INTERNAL COMBUSTION PISTON ENGINES: OTHER"
    },
    {
      "code": "84134000",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS CONCRETE PUMPS"
    },
    {
      "code": "841350",
      "description": "Other reciprocating positive displacement pumps"
    },
    {
      "code": "84135010",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER RECIPROCATING POSITIVE DISPLACEMENT PUMPS: METERING AND DOSING PUMPS"
    },
    {
      "code": "84135021",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER RECIPROCATING POSITIVE DISPLACEMENT PUMPS: PRIMARILY DESIGNED FOR HANDLING WATER : DEEP TUBE WELL TURBINE PUMP"
    },
    {
      "code": "84135029",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER RECIPROCATING POSITIVE DISPLACEMENT PUMPS: PRIMARILY DESIGNED FOR HANDLING WATER : OTHER"
    },
    {
      "code": "84135090",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER RECIPROCATING POSITIVE DISPLACEMENT PUMPS: OTHER"
    },
    {
      "code": "841360",
      "description": "Other rotary positive displacement pumps"
    },
    {
      "code": "84136010",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER ROTARY POSITIVE DISPLACEMENT PUMPS: GEAR TYPE PUMPS"
    },
    {
      "code": "84136020",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER ROTARY POSITIVE DISPLACEMENT PUMPS: SCREW TYPE PUMPS"
    },
    {
      "code": "84136090",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER ROTARY POSITIVE DISPLACEMENT PUMPS: OTHER"
    },
    {
      "code": "841370",
      "description": "Other centrifugal pumps"
    },
    {
      "code": "84137010",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: PRIMARILY DESIGNED TO HANDLE WATER"
    },
    {
      "code": "84137091",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: SINGLE AND MULTISTAGE CHEMICAL PROCESS PUMPS"
    },
    {
      "code": "84137092",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: HORIZONTAL SPLIT CASING PUMPS"
    },
    {
      "code": "84137093",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: HORIZONTAL SELF PRIMING PUMPS"
    },
    {
      "code": "84137094",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: VERTICAL TURBINE DRIVEN PUMPS"
    },
    {
      "code": "84137095",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: BOILER FEED PUMPS"
    },
    {
      "code": "84137096",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: SLURRY PUMPS"
    },
    {
      "code": "84137097",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: DREDGER PUMPS"
    },
    {
      "code": "84137099",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER CENTRIFUGAL PUMPS: OTHER: OTHER"
    },
    {
      "code": "841381",
      "description": "Pumps"
    },
    {
      "code": "84138110",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER PUMPS-LIQUID ELEVATORS: 8413 81 - PUMPS : GAS PUMPS"
    },
    {
      "code": "84138120",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER PUMPS-LIQUID ELEVATORS: 8413 81 - PUMPS : HYDRAULIC RAM"
    },
    {
      "code": "84138130",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER PUMPS-LIQUID ELEVATORS: 8413 81 - PUMPS : AXIAL FLOW AND MIXED FLOW VERTICAL PUMP DESIGNED PRIMARILY FOR HANDLING WATER"
    },
    {
      "code": "84138190",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER PUMPS-LIQUID ELEVATORS: 8413 81 - PUMPS : OTHER"
    },
    {
      "code": "84138200",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - OTHER PUMPS-LIQUID ELEVATORS: 8413 81 - LIQUID ELEVATORS"
    },
    {
      "code": "841391",
      "description": "Of pumps"
    },
    {
      "code": "84139110",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: 8413 91 - OF PUMPS: OF RECIPROCATING PUMPS"
    },
    {
      "code": "84139120",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: 8413 91 - OF PUMPS: OF CENTRIFUGAL PUMPS"
    },
    {
      "code": "84139130",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: 8413 91 - OF PUMPS: OF DEEP WELL TURBINE PUMPS AND OF OTHER ROTARY PUMPS"
    },
    {
      "code": "84139140",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: 8413 91 - OF PUMPS: OF HAND PUMP FOR HANDLING WATER"
    },
    {
      "code": "84139190",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: 8413 91 - OF PUMPS: OTHER"
    },
    {
      "code": "84139200",
      "description": "PUMPS FOR LIQUIDS, WHETHER OR NOT FITTED WITH A MEASURING DEVICE; LIQUID ELEVATORS - PARTS: OF LIQUID ELEVATORS"
    },
    {
      "code": "8414",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS"
    },
    {
      "code": "84141000",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS VACUUM PUMPS"
    },
    {
      "code": "841420",
      "description": "Hand or foot-operated air pumps"
    },
    {
      "code": "84142010",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - HAND OR FOOT-OPERATED AIR PUMPS : BICYCLE PUMPS"
    },
    {
      "code": "84142020",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - HAND OR FOOT-OPERATED AIR PUMPS : OTHER HAND PUMPS"
    },
    {
      "code": "84142090",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - HAND OR FOOT-OPERATED AIR PUMPS : OTHER"
    },
    {
      "code": "84143000",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS COMPRESSORS OF A KIND USED IN REFRIGERATING EQUIPMENT"
    },
    {
      "code": "841440",
      "description": "Air compressors mounted on a wheeled chassis for towing"
    },
    {
      "code": "84144010",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - AIR COMPRESSORS MOUNTED ON A WHEELED CHASSIS FOR TOWING: RECIPROCATING AIR COMPRESSORS"
    },
    {
      "code": "84144020",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - AIR COMPRESSORS MOUNTED ON A WHEELED CHASSIS FOR TOWING: CENTRIFUGAL AIR COMPRESSORS"
    },
    {
      "code": "84144030",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - AIR COMPRESSORS MOUNTED ON A WHEELED CHASSIS FOR TOWING: SCREW AIR COMPRESSORS"
    },
    {
      "code": "84144090",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - AIR COMPRESSORS MOUNTED ON A WHEELED CHASSIS FOR TOWING: OTHER"
    },
    {
      "code": "841451",
      "description": "Table, floor, wall, window, ceiling or roof fans, with a self-contained electric motor of an output not exceeding 125 W"
    },
    {
      "code": "84145110",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS FANS : 8414 51 - TABLE, FLOOR, WALL, WINDOW, CEILING OR ROOF FANS, WITH A SELF-CONTAINED ELECTRIC MOTOR OF AN OUTPUT NOT EXCEEDING 125 W: TABLE FANS"
    },
    {
      "code": "84145120",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : 8414 51 - TABLE, FLOOR, WALL, WINDOW, CEILING OR ROOF FANS, WITH A SELF-CONTAINED ELECTRIC MOTOR OF AN OUTPUT NOT EXCEEDING 125 W: CEILING FANS"
    },
    {
      "code": "84145130",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : 8414 51 - TABLE, FLOOR, WALL, WINDOW, CEILING OR ROOF FANS, WITH A SELF-CONTAINED ELECTRIC MOTOR OF AN OUTPUT NOT EXCEEDING 125 W: PEDESTAL FANS"
    },
    {
      "code": "84145140",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : 8414 51 - TABLE, FLOOR, WALL, WINDOW, CEILING OR ROOF FANS, WITH A SELF-CONTAINED ELECTRIC MOTOR OF AN OUTPUT NOT EXCEEDING 125 W: RAILWAY CARRIAGE FANS"
    },
    {
      "code": "84145150",
      "description": "Wall fans"
    },
    {
      "code": "84145190",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : 8414 51 - TABLE, FLOOR, WALL, WINDOW, CEILING OR ROOF FANS, WITH A SELF-CONTAINED ELECTRIC MOTOR OF AN OUTPUT NOT EXCEEDING 125 W: OTHER"
    },
    {
      "code": "841459",
      "description": "Other"
    },
    {
      "code": "84145910",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : OTHER: AIR CIRCULATOR"
    },
    {
      "code": "84145920",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : OTHER: BLOWERS, PORTABLE"
    },
    {
      "code": "84145930",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : OTHER: INDUSTRIAL FANS AND BLOWERS"
    },
    {
      "code": "84145990",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - FANS : OTHER: OTHER"
    },
    {
      "code": "84146000",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS HOODS HAVING A MAXIMUM HORIZONTAL SIDE NOT EXCEEDING 120 CM"
    },
    {
      "code": "841480",
      "description": "Other"
    },
    {
      "code": "84148011",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - OTHER: GAS COMPRESSORS: OF A KIND USED IN AIR CONDITIONING EQUIPMENT"
    },
    {
      "code": "84148019",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - OTHER: GAS COMPRESSORS: OTHER"
    },
    {
      "code": "84148020",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - OTHER: FREE PISTON GENERATORS FOR GAS TURBINE"
    },
    {
      "code": "84148030",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - OTHER: TURBO CHARGER"
    },
    {
      "code": "84148090",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - OTHER: OTHER"
    },
    {
      "code": "841490",
      "description": "Parts"
    },
    {
      "code": "84149011",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF AIR OR VACUUM PUMPS AND COMPRESSORS: OF GAS COMPRESSORS OF A KIND USED IN REFRIGERATING AND AIR CONDITIONING APPLIANCES AND MACHINERY"
    },
    {
      "code": "84149012",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF AIR OR VACUUM PUMPS AND COMPRESSORS: OF BICYCLE PUMPS"
    },
    {
      "code": "84149019",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF AIR OR VACUUM PUMPS AND COMPRESSORS: OTHER"
    },
    {
      "code": "84149020",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF FREE PISTON GENERATORS"
    },
    {
      "code": "84149030",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF ELECTRIC FANS"
    },
    {
      "code": "84149040",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OF INDUSTRIAL FANS, BLOWERS"
    },
    {
      "code": "84149090",
      "description": "AIR OR VACUUM PUMPS, AIR OR OTHER GAS COMPRESSORS AND FANS; VENTILATING OR RECYCLING HOODS INCORPORATING A FAN, WHETHER OR NOT FITTED WITH FILTERS - PARTS : OTHER"
    },
    {
      "code": "8415",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTOR- DRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED"
    },
    {
      "code": "841510",
      "description": "Of a kind designed to be fixed to a window, wall, ceiling or floor, self-contained or split-system"
    },
    {
      "code": "84151010",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED 8415 10 - WINDOW OR WALL TYPES, SELF-CONTAINED OR SPLIT SYSTEM: SPLIT SYSTEM"
    },
    {
      "code": "84151090",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED 8415 10 - WINDOW OR WALL TYPES, SELF-CONTAINED OR SPLIT SYSTEM: OTHER"
    },
    {
      "code": "841520",
      "description": "Of a kind used for persons in motor vehicles"
    },
    {
      "code": "84152010",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OF A KIND USED FOR PERSONS IN MOTOR VEHICLES : FOR BUSES"
    },
    {
      "code": "84152090",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OF A KIND USED FOR PERSONS IN MOTOR VEHICLES : OTHER"
    },
    {
      "code": "841581",
      "description": "Incorporating a refrigerating unit and a valve for reversal of the cooling or heat cycle (reversible heat pumps)"
    },
    {
      "code": "84158110",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : INCORPORATING A REFRIGERATING UNIT AND A VALVE FOR REVERSAL OF THE COOLING OR HEAT CYCLE (REVERSIBLE HEAT PUMPS): SPLIT AIR CONDITIONER TWO TONNES AND ABOVE"
    },
    {
      "code": "84158190",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : INCORPORATING A REFRIGERATING UNIT AND A VALVE FOR REVERSAL OF THE COOLING OR HEAT CYCLE (REVERSIBLE HEAT PUMPS): OTHER"
    },
    {
      "code": "841582",
      "description": "Other, incorporating a refrigerating unit"
    },
    {
      "code": "84158210",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : OTHER, INCORPORATING A REFRIGERATING UNIT: SPLIT AIR CONDITIONER TWO TONNES AND ABOVE"
    },
    {
      "code": "84158290",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : OTHER, INCORPORATING A REFRIGERATING UNIT: OTHER"
    },
    {
      "code": "841583",
      "description": "Not incorporating a refrigerating unit"
    },
    {
      "code": "84158310",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : NOT INCORPORATING A REFRIGERATING UNIT : SPLIT AIR CONDITIONER TWO TONNES AND ABOVE"
    },
    {
      "code": "84158390",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - OTHER : NOT INCORPORATING A REFRIGERATING UNIT : OTHER"
    },
    {
      "code": "84159000",
      "description": "AIR CONDITIONING MACHINES, COMPRISING A MOTORDRIVEN FAN AND ELEMENTS FOR CHANGING THE TEMPERATURE AND HUMIDITY, INCLUDING THOSE MACHINES IN WHICH THE HUMIDITY CANNOT BE SEPARATELY REGULATED - PARTS"
    },
    {
      "code": "8416",
      "description": "FURNACE BURNERS FOR LIQUID FUEL, FOR PULVERISED SOLID FUEL OR FOR GAS; MECHANICAL STOKERS, INCLUDING THEIR MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES"
    },
    {
      "code": "84161000",
      "description": "FURNACE BURNERS FOR LIQUID FUEL, FOR PULVERISED SOLID FUEL OR FOR GAS; MECHANICAL STOKERS, INCLUDING THEIR MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES - FURNACE BURNERS FOR LIQUID FUEL"
    },
    {
      "code": "84162000",
      "description": "FURNACE BURNERS FOR LIQUID FUEL, FOR PULVERISED SOLID FUEL OR FOR GAS; MECHANICAL STOKERS, INCLUDING THEIR MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES - OTHER FURNACE BURNERS, INCLUDING COMBINATION BURNERS"
    },
    {
      "code": "84163000",
      "description": "FURNACE BURNERS FOR LIQUID FUEL, FOR PULVERISED SOLID FUEL OR FOR GAS; MECHANICAL STOKERS, INCLUDING THEIR MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES - MECHANICAL STOKERS, MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES"
    },
    {
      "code": "84169000",
      "description": "FURNACE BURNERS FOR LIQUID FUEL, FOR PULVERISED SOLID FUEL OR FOR GAS; MECHANICAL STOKERS, INCLUDING THEIR MECHANICAL GRATES, MECHANICAL ASH DISCHARGERS AND SIMILAR APPLIANCES - PARTS"
    },
    {
      "code": "8417",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC"
    },
    {
      "code": "84171000",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC - FURNACES AND OVENS FOR THE ROASTING, MELTING OR OTHER HEAT TREATMENT OF ORES, PYRITES OR OF METALS"
    },
    {
      "code": "84172000",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC - BAKERY OVENS, INCLUDING BISCUIT OVENS"
    },
    {
      "code": "841780",
      "description": "Other"
    },
    {
      "code": "84178010",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC - OTHER : FOR CEMENT INDUSTRY"
    },
    {
      "code": "84178090",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC - OTHER : OTHER"
    },
    {
      "code": "84179000",
      "description": "INDUSTRIAL OR LABORATORY FURNACES AND OVENS, INCLUDING INCINERATORS, NON-ELECTRIC - PARTS"
    },
    {
      "code": "8418",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8412.5"
    },
    {
      "code": "841810",
      "description": "Combined refrigerator-freezers, fitted with separate external doors"
    },
    {
      "code": "84181010",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 8418 10 - COMBINED REFRIGERATOR-FREEZERS, FITTED WITH SEPARATE EXTERNAL DOORS: COMMERCIAL TYPE"
    },
    {
      "code": "84181090",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 8418 10 - COMBINED REFRIGERATOR-FREEZERS, FITTED WITH SEPARATE EXTERNAL DOORS: OTHER"
    },
    {
      "code": "84182100",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - REFRIGERATORS, HOUSEHOLD TYPE: COMPRESSION TYPE"
    },
    {
      "code": "84182200",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - REFRIGERATORS, HOUSEHOLD TYPE: ABSORPTION/TYPE, ELECTRICAL"
    },
    {
      "code": "84182900",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - REFRIGERATORS, HOUSEHOLD TYPE: OTHER"
    },
    {
      "code": "841830",
      "description": "Freezers of the chest type, not exceeding 800 l capacity"
    },
    {
      "code": "84183010",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - FREEZERS OF THE CHEST TYPE, NOT EXCEEDING 800 L CAPACITY: COMMERCIAL TYPE ELECTRICAL"
    },
    {
      "code": "84183090",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - FREEZERS OF THE CHEST TYPE, NOT EXCEEDING 800 L CAPACITY: OTHER"
    },
    {
      "code": "841840",
      "description": "Freezers of the upright type, not exceeding 900 l capacity"
    },
    {
      "code": "84184010",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- FREEZERS OF THE UPRIGHT TYPE, NOT EXCEEDING 900 L CAPACITY: ELECTRICAL"
    },
    {
      "code": "84184090",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- FREEZERS OF THE UPRIGHT TYPE, NOT EXCEEDING 900 L CAPACITY: OTHER"
    },
    {
      "code": "84185000",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415 - OTHER FURNITURE (CHESTS, CABINETS, DISPLAY COUNTERS, SHOWCASES AND THE LIKE) FOR STORAGE AND DISPLAY,INCORPORATING REFRIGERATING OR FREEZING EQUIPMENT? ;"
    },
    {
      "code": "84186100",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS:--HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415"
    },
    {
      "code": "841869",
      "description": "Other"
    },
    {
      "code": "84186910",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: ICE MAKING MACHINERY"
    },
    {
      "code": "84186920",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: WATER COOLER"
    },
    {
      "code": "84186930",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: VENDING MACHINE, OTHER THAN AUTOMATIC VENDING MACHINE"
    },
    {
      "code": "84186940",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: REFRIGERATION EQUIPMENT OR DEVICES SPECIALLY USED IN LEATHER INDUSTRIES FOR MANUFACTURING OF LEATHER ARTICLES"
    },
    {
      "code": "84186950",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: REFRIGERATED FARM TANKS, INDUSTRIAL ICE CREAM FREEZER"
    },
    {
      "code": "84186990",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- OTHER REFRIGERATING OR FREEZING EQUIPMENT; HEAT PUMPS: OTHER: OTHER"
    },
    {
      "code": "84189100",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- PARTS: FURNITURE DESIGNED TO RECEIVE REFRIGERATING OR FREEZING EQUIPMENT"
    },
    {
      "code": "84189900",
      "description": "REFRIGERATORS, FREEZERS AND OTHER REFRIGERATING OR FREEZING EQUIPMENT, ELECTRIC OR OTHER; HEAT PUMPS OTHER THAN AIR CONDITIONING MACHINES OF HEADING 8415- PARTS: OTHER"
    },
    {
      "code": "8419",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING,                                                            STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - Instantaneous or storage water heaters,nonelectric"
    },
    {
      "code": "841911",
      "description": "Instantaneous gas water heaters"
    },
    {
      "code": "84191110",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - INSTANTANEOUS OR STORAGE WATER HEATERS NON-ELECTRIC: 8419 11 - INSTANTANEOUS GAS WATER HEATERS : DOMESTIC TYPE"
    },
    {
      "code": "84191190",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - INSTANTANEOUS OR STORAGE WATER HEATERS NON-ELECTRIC: 8419 11 - INSTANTANEOUS GAS WATER HEATERS : OTHER"
    },
    {
      "code": "841919",
      "description": "Other"
    },
    {
      "code": "84191910",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - INSTANTANEOUS GAS WATER HEATERS : OTHER: DOMESTIC TYPE"
    },
    {
      "code": "84191920",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - INSTANTANEOUS GAS WATER HEATERS : OTHER: OTHER"
    },
    {
      "code": "841920",
      "description": "Medical, surgical or laboratory sterilisers"
    },
    {
      "code": "84192010",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC- MEDICAL, SURGICAL OR LABORATORY STERILISERS:AUTO CLAVES"
    },
    {
      "code": "84192090",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC- MEDICAL, SURGICAL OR LABORATORY STERILISERS: OTHER"
    },
    {
      "code": "84193100",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DRYERS: FOR AGRICULTURAL PRODUCTS"
    },
    {
      "code": "84193200",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DRYERS: FOR WOOD, PAPER PULP, PAPER OR PAPER, BOARD"
    },
    {
      "code": "84193900",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DRYERS: OTHER"
    },
    {
      "code": "841940",
      "description": "Distilling or rectifying plant"
    },
    {
      "code": "84194010",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DISTILLING OR RECTIFYING PLANT: FOR PETROLEUM REFINING"
    },
    {
      "code": "84194020",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DISTILLING OR RECTIFYING PLANT: OTHER DISTILLING EQUIPMENT"
    },
    {
      "code": "84194090",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - DISTILLING OR RECTIFYING PLANT: OTHER"
    },
    {
      "code": "841950",
      "description": "Heat exchange units"
    },
    {
      "code": "84195010",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - HEAT EXCHANGE UNITS: SHELL AND TUBE TYPE"
    },
    {
      "code": "84195020",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - HEAT EXCHANGE UNITS: PLATE TYPE"
    },
    {
      "code": "84195030",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - HEAT EXCHANGE UNITS: SPIRAL TYPE"
    },
    {
      "code": "84195090",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - HEAT EXCHANGE UNITS: OTHER"
    },
    {
      "code": "84196000",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC MACHINERY FOR LIQUEFYING AIR OR OTHER GASES"
    },
    {
      "code": "841981",
      "description": "For making hot drinks or for cooking or heating food"
    },
    {
      "code": "84198110",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT: FOR MAKING HOT DRINKS OR FOR COOKING OR HEATING FOOD: FRIERS"
    },
    {
      "code": "84198120",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT: FOR MAKING HOT DRINKS OR FOR COOKING OR HEATING FOOD: OTHER KITCHEN MACHINES"
    },
    {
      "code": "84198190",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT: FOR MAKING HOT DRINKS OR FOR COOKING OR HEATING FOOD: OTHER"
    },
    {
      "code": "841989",
      "description": "Other"
    },
    {
      "code": "84198910",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: PRESSURE VESSELS, REACTORS, COLUMNS OR TOWERS OR CHEMICAL STORAGE TANKS"
    },
    {
      "code": "84198920",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: GLASS LINED EQUIPMENT"
    },
    {
      "code": "84198930",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: AUTO CLAVES OTHER THAN FOR COOKING OR HEATING FOOD, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "84198940",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: COOLING TOWERS AND SIMILAR PLANTS FOR DIRECT COOLING (WITHOUT A SEPARATING WALL) BY MEANS OF RE-CIRCULATED WATER"
    },
    {
      "code": "84198950",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: PASTEURIZERS"
    },
    {
      "code": "84198960",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: PLANT GROWTH CHAMBERS AND ROOMS AND TISSUE CULTURE CHAMBERS AND ROOMS HAVING TEMPERATURE, HUMIDITY OR LIGHT CONTROL"
    },
    {
      "code": "84198970",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: APPARATUS FOR RAPID HEATING OF SEMI CONDUCTOR DEVICES; APPARATUS FOR CHEMICAL OR PHYSICAL VAPOUR DEPOSITION ON SEMICONDUCTOR WAFERS; APPARATUS FOR CHEMICAL VAPOUR DEPOSITION ON LCD SUBSTRATUS"
    },
    {
      "code": "84198980",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: VACUUM VAPOUR PLANT FOR DEPOSITION OF METALS"
    },
    {
      "code": "84198990",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - OTHER MACHINERY, PLANT AND EQUIPMENT - OTHER: OTHER"
    },
    {
      "code": "841990",
      "description": "Parts"
    },
    {
      "code": "84199010",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - PARTS: PARTS OF INSTANTANEOUS OR STORAGE WATER HEATERS (DOMESTIC TYPE)"
    },
    {
      "code": "84199090",
      "description": "MACHINERY, PLANT OR LABORATORY EQUIPMENT, WHETHER OR NOT ELECTRICALLY HEATED (EXCLUDING FURNACES, OVENS AND OTHER EQUIPMENT OF HEADING 8514), FOR THE TREATMENT OF MATERIALS BY A PROCESS INVOLVING A CHANGE OF TEMPERATURE SUCH AS HEATING, COOKING, ROASTING, DISTILLING, RECTIFYING, STERILISING, PASTEURISING, STEAMING, DRYING, EVAPORATING, VAPORISING, CONDENSING OR COOLING, OTHER THAN MACHINERY OR PLANT OF A KIND USED FOR DOMESTIC PURPOSES; INSTANTANEOUS OR STORAGE WATER HEATERS, NON-ELECTRIC - PARTS: OTHER"
    },
    {
      "code": "8420",
      "description": "CALENDERING OR OTHER ROLLING MACHINES, OTHER THAN FOR METALS OR GLASS, AND CYLINDERS THEREFOR"
    },
    {
      "code": "84201000",
      "description": "CALENDERING OR OTHER ROLLING MACHINES, OTHER THAN FOR METALS OR GLASS, AND CYLINDERS THEREFOR CALENDERING OR OTHER ROLLING MACHINES"
    },
    {
      "code": "84209100",
      "description": "CALENDERING OR OTHER ROLLING MACHINES, OTHER THAN FOR METALS OR GLASS, AND CYLINDERS THEREFOR - PARTS: CYLINDERS"
    },
    {
      "code": "84209900",
      "description": "CALENDERING OR OTHER ROLLING MACHINES, OTHER THAN FOR METALS OR GLASS, AND CYLINDERS THEREFOR - PARTS: OTHER"
    },
    {
      "code": "8421",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - Centrifuges, including centrifugal dryers"
    },
    {
      "code": "84211100",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS: CREAM SEPARATORS"
    },
    {
      "code": "84211200",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS: CLOTHES DRYERS"
    },
    {
      "code": "842119",
      "description": "Other"
    },
    {
      "code": "84211910",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: BOWL CENTRIFUGES"
    },
    {
      "code": "84211920",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: BASKET CENTRIFUGES"
    },
    {
      "code": "84211930",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: CONTINUOUS AUTOMATIC CENTRIFUGES"
    },
    {
      "code": "84211940",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: SELF CLEANING CENTRIFUGES"
    },
    {
      "code": "84211950",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: DECANTER CENTRIFUGES HORIZONTAL BOWL"
    },
    {
      "code": "84211960",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER: SCREW CONVEYOR CENTRIFUGES"
    },
    {
      "code": "84211991",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER:- OTHER : FOR CHEMICAL INDUSTRIES"
    },
    {
      "code": "84211999",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS:- OTHER:- OTHER : OTHER"
    },
    {
      "code": "842121",
      "description": "For filtering or purifying water"
    },
    {
      "code": "84212110",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - - FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS - FOR FILTERING OR PURIFYING WATER : ION EXCHANGER PLANT OR APPARATUS"
    },
    {
      "code": "84212120",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - - FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS - FOR FILTERING OR PURIFYING WATER :HOUSEHOLD TYPE FILTERS"
    },
    {
      "code": "84212190",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - - FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS - FOR FILTERING OR PURIFYING WATER : OTHER"
    },
    {
      "code": "84212200",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS: FOR FILTERING OR PURIFYING BEVERAGES OTHER THAN WATER"
    },
    {
      "code": "84212300",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS: OIL OR PETROL-FILTERS FOR INTERNAL COMBUSTION ENGINES"
    },
    {
      "code": "84212900",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR LIQUIDS: OTHER"
    },
    {
      "code": "84213100",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR GASES: INTAKE AIR FILTERS FOR INTERNAL COMBUSTION ENGINES"
    },
    {
      "code": "842139",
      "description": "Other"
    },
    {
      "code": "84213910",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR GASES: OTHER: AIR SEPARATORS TO BE EMPLOYED IN THE PROCESSING, SMELTING OR REFINING OF MINERALS, ORES OR METALS; AIR STRIPPERS"
    },
    {
      "code": "84213920",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR GASES: OTHER: AIR PURIFIERS OR CLEANERS"
    },
    {
      "code": "84213990",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES FILTERING OR PURIFYING MACHINERY AND APPARATUS FOR GASES: OTHER: OTHER"
    },
    {
      "code": "84219100",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - PARTS: OF CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS"
    },
    {
      "code": "84219900",
      "description": "CENTRIFUGES, INCLUDING CENTRIFUGAL DRYERS; FILTERING OR PURIFYING MACHINERY AND APPARATUS, FOR LIQUIDS OR GASES - PARTS: OTHER"
    },
    {
      "code": "8422",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - Dish washing machines"
    },
    {
      "code": "84221100",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - DISH WASHING MACHINES: OF THE HOUSEHOLD TYPE"
    },
    {
      "code": "84221900",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - DISH WASHING MACHINES: OTHER"
    },
    {
      "code": "84222000",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS"
    },
    {
      "code": "84223000",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; MACHINERY FOR AERATING BEVERAGES"
    },
    {
      "code": "84224000",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT SHRINK WRAPPING MACHINERY)"
    },
    {
      "code": "842290",
      "description": "Parts"
    },
    {
      "code": "84229010",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - PARTS: OF MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS"
    },
    {
      "code": "84229020",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - PARTS: OF DISH WASHING MACHINES OF HOUSEHOLD TYPE"
    },
    {
      "code": "84229090",
      "description": "DISH WASHING MACHINES; MACHINERY FOR CLEANING OR DRYING BOTTLES OR OTHER CONTAINERS; MACHINERY FOR FILLING, CLOSING, SEALING OR LABELLING BOTTLES, CANS, BOXES, BAGS OR OTHER CONTAINERS; MACHINERY FOR CAPSULING BOTTLES, JARS, TUBES AND SIMILAR CONTAINERS; OTHER PACKING OR WRAPPING MACHINERY (INCLUDING HEAT-SHRINK WRAPPING MACHINERY); MACHINERY FOR AERATING BEVERAGES - PARTS: OF OTHER MACHINERY"
    },
    {
      "code": "8423",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS"
    },
    {
      "code": "84231000",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS PERSONAL WEIGHING MACHINES, INCLUDING BABY SCALES; HOUSEHOLD SCALES"
    },
    {
      "code": "84232000",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS SCALES FOR CONTINUOUS WEIGHING OF GOODS ON CONVEYORS"
    },
    {
      "code": "84233000",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS CONSTANT WEIGHT SCALES AND SCALES FORDISCHARGING A PREDETERMINED WEIGHT OF MATERIAL INTO A BAG OR CONTAINER, INCLUDING HOPPER SCALES"
    },
    {
      "code": "842381",
      "description": "Having a maximum weighing capacity not exceeding 30 kg"
    },
    {
      "code": "84238110",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - OTHER WEIGHING MACHINERY- HAVING A MAXIMUM WEIGHING CAPACITY NOT EXCEEDING 30 KG : BEAM SCALE"
    },
    {
      "code": "84238190",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - OTHER WEIGHING MACHINERY- HAVING A MAXIMUM WEIGHING CAPACITY NOT EXCEEDING 30 KG : OTHER"
    },
    {
      "code": "842382",
      "description": "Having a maximum weighing capacity exceeding 30 kg but not exceeding 5,000 kg"
    },
    {
      "code": "84238210",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - OTHER WEIGHING MACHINERY- HAVING A MAXIMUM WEIGHING CAPACITY EXCEEDING 30 KG BUT NOT EXCEEDING 5,000 KG: BEAM SCALE"
    },
    {
      "code": "84238290",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - OTHER WEIGHING MACHINERY- HAVING A MAXIMUM WEIGHING CAPACITY EXCEEDING 30 KG BUT NOT EXCEEDING 5,000 KG: OTHER"
    },
    {
      "code": "84238900",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - OTHER WEIGHING MACHINERY- OTHER"
    },
    {
      "code": "842390",
      "description": "Weighing machine weights of all kinds; parts of weighing machinery"
    },
    {
      "code": "84239010",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - WEIGHING MACHINE WEIGHTS OF ALL KINDS; PARTS OF WEIGHING MACHINERY: WEIGHING MACHINE WEIGHTS OF ALL KINDS"
    },
    {
      "code": "84239020",
      "description": "WEIGHING MACHINERY (EXCLUDING BALANCES OF A SENSITIVITY OF 5 CENTIGRAMS OR BETTER), INCLUDING WEIGHT OPERATED COUNTING OR CHECKING MACHINES; WEIGHING MACHINE WEIGHTS OF ALL KINDS - WEIGHING MACHINE WEIGHTS OF ALL KINDS; PARTS OF WEIGHING MACHINERY: PARTS OF WEIGHING MACHINERY"
    },
    {
      "code": "8424",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES"
    },
    {
      "code": "84241000",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED"
    },
    {
      "code": "84242000",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES SPRAY GUNS AND SIMILAR APPLIANCES"
    },
    {
      "code": "84243000",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES"
    },
    {
      "code": "84244100",
      "description": "Portable sprayers"
    },
    {
      "code": "84244900",
      "description": "Other"
    },
    {
      "code": "84248100",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES- OTHER APPLIANCES: AGRICULTURAL OR HORTICULTURAL"
    },
    {
      "code": "84248200",
      "description": "Agricultural or horticultural"
    },
    {
      "code": "842489",
      "description": "Other"
    },
    {
      "code": "84248910",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES- OTHER APPLIANCES: OTHER: PAINTING EQUIPMENT, INCLUDING ELECTROSTATIC PHOSPHATING AND POWDER COATING EQUIPMENT"
    },
    {
      "code": "84248920",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES- OTHER APPLIANCES: OTHER: INDUSTRIAL BELLOWS"
    },
    {
      "code": "84248990",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES- OTHER APPLIANCES: OTHER: OTHER"
    },
    {
      "code": "84249000",
      "description": "MECHANICAL APPLIANCES (WHETHER OR NOT HANDOPERATED) FOR PROJECTING, DISPERSING OR SPRAYING LIQUIDS OR POWDERS; FIRE EXTINGUISHERS, WHETHER OR NOT CHARGED; SPRAY GUNS AND SIMILAR APPLIANCES; STEAM OR SAND BLASTING MACHINES AND SIMILAR JET PROJECTING MACHINES- PARTS"
    },
    {
      "code": "8425",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - Pulley tackles and hoists other than skip hoists or hoists of a kind used for raising vehicles"
    },
    {
      "code": "842511",
      "description": "Powered by electric motor"
    },
    {
      "code": "84251110",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - PULLEY TACKLES AND HOISTS OTHER THAN SKIP HOISTS OR HOISTS OF A KIND USED FOR RAISING VEHICLES: POWERED BY ELECTRIC MOTOR: HOISTS"
    },
    {
      "code": "84251120",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - PULLEY TACKLES AND HOISTS OTHER THAN SKIP HOISTS OR HOISTS OF A KIND USED FOR RAISING VEHICLES: POWERED BY ELECTRIC MOTOR: PULLEY TACKLE"
    },
    {
      "code": "842519",
      "description": "Other"
    },
    {
      "code": "84251910",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - PULLEY TACKLES AND HOISTS OTHER THAN SKIP HOISTS OR HOISTS OF A KIND USED FOR RAISING VEHICLES: OTHER : HOISTS MACHINE"
    },
    {
      "code": "84251920",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - PULLEY TACKLES AND HOISTS OTHER THAN SKIP HOISTS OR HOISTS OF A KIND USED FOR RAISING VEHICLES: OTHER : PULLEY TACKLE"
    },
    {
      "code": "84252000",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS PIT HEAD WINDING GEAR; WINCHES SPECIALLY DESIGNED FOR USE UNDERGROUND"
    },
    {
      "code": "84253100",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - OTHER WINCHES; CAPSTANS: POWERED BY ELECTRIC MOTOR"
    },
    {
      "code": "84253900",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - OTHER WINCHES; CAPSTANS: OTHER"
    },
    {
      "code": "84254100",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - JACKS; HOISTS OF A KIND USED FOR RAISING VEHICLES: BUILT-IN JACKING SYSTEM OF A TYPE USED IN GARAGES"
    },
    {
      "code": "84254200",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - JACKS; HOISTS OF A KIND USED FOR RAISING VEHICLES: OTHER JACKS AND HOISTS, HYDRAULIC"
    },
    {
      "code": "84254900",
      "description": "PULLEY TACKLE AND HOISTS OTHER THAN SKIP HOISTS; WINCHES AND CAPSTANS; JACKS - JACKS; HOISTS OF A KIND USED FOR RAISING VEHICLES: OTHER"
    },
    {
      "code": "8426",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE Overhead travelling cranes, transporter cranes, gantry cranes, bridge cranes, mobile lifting frames and straddle carriers"
    },
    {
      "code": "84261100",
      "description": "SHIPRRICKS;CRANESINCLUDINGCABLECRANES;MOBILELIFTINGFRAMES,STRADDLECARRIERSANDWORKSTRUCKSFITTEDWITHACRANE-OVERHEADTRAVELLINGCRANES,TRANSPORTERCRANES,GANTRYCRANES,BRIDGECRANES,MOBILELIFTINGFRAMESANDSTRADDLECARRIERS"
    },
    {
      "code": "84261200",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OVERHEAD TRAVELLING CRANES, TRANSPORTER CRANES, GANTRY CRANES, BRIDGE CRANES, MOBILE LIFTING FRAMES AND STRADDLE CARRIERS: MOBILE LIFTING FRAMES ON TYRES AND STRADDLE CARRIERS"
    },
    {
      "code": "84261900",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OVERHEAD TRAVELLING CRANES, TRANSPORTER CRANES, GANTRY CRANES, BRIDGE CRANES, MOBILE LIFTING FRAMES AND STRADDLE CARRIERS: OTHER"
    },
    {
      "code": "84262000",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - TOWER CRANES"
    },
    {
      "code": "84263000",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - PORTAL OR PEDESTAL JIB CRANES"
    },
    {
      "code": "84264100",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OTHER MACHINERY, SELF-PROPELLED: ON TYRES"
    },
    {
      "code": "84264900",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OTHER MACHINERY, SELF-PROPELLED: OTHER"
    },
    {
      "code": "84269100",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OTHER MACHINERY : DESIGNED FOR MOUNTING ON ROAD VEHICLES"
    },
    {
      "code": "842699",
      "description": "Other"
    },
    {
      "code": "84269910",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OTHER MACHINERY : OTHER: ROPEWAY AND TELPHERS"
    },
    {
      "code": "84269990",
      "description": "SHIPS DERRICKS; CRANES INCLUDING CABLE CRANES; MOBILE LIFTING FRAMES, STRADDLE CARRIERS AND WORKS TRUCKS FITTED WITH A CRANE - OTHER MACHINERY : OTHER: OTHER"
    },
    {
      "code": "8427",
      "description": "FORK-LIFT TRUCKS; OTHER WORKS TRUCKS FITTED WITH LIFTING OR HANDLING EQUIPMENT"
    },
    {
      "code": "84271000",
      "description": "FORK-LIFT TRUCKS; OTHER WORKS TRUCKS FITTED WITH LIFTING OR HANDLING EQUIPMENT SELF-PROPELLED TRUCKS POWERED BY AN ELECTRIC MOTOR"
    },
    {
      "code": "84272000",
      "description": "8427 FORK-LIFT TRUCKS; OTHER WORKS TRUCKS FITTED WITH LIFTING OR HANDLING EQUIPMENT OTHER SELF-PROPELLED TRUCKS"
    },
    {
      "code": "84279000",
      "description": "8427 FORK-LIFT TRUCKS; OTHER WORKS TRUCKS FITTED WITH LIFTING OR HANDLING EQUIPMENT OTHER TRUCKS"
    },
    {
      "code": "8428",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS)"
    },
    {
      "code": "842810",
      "description": "Lifts and skip hoists"
    },
    {
      "code": "84281011",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) 8428 10 - LIFTS AND SKIP HOISTS: LIFTS: LIFTS OF A KIND USED IN BUILDINGS"
    },
    {
      "code": "84281019",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) 8428 10 - LIFTS AND SKIP HOISTS: LIFTS: OTHER"
    },
    {
      "code": "84281020",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - LIFTS AND SKIP HOISTS: SKIP HOISTS"
    },
    {
      "code": "842820",
      "description": "Pneumatic elevators and conveyors"
    },
    {
      "code": "84282011",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - PNEUMATIC ELEVATORS AND CONVEYORS: CONVEYORS: BELT CONVEYORS"
    },
    {
      "code": "84282019",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - PNEUMATIC ELEVATORS AND CONVEYORS: CONVEYORS: OTHER"
    },
    {
      "code": "84282020",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - PNEUMATIC ELEVATORS AND CONVEYORS PNEUMATIC ELEVATORS"
    },
    {
      "code": "84283100",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER CONTINUOUS-ACTION ELEVATORS AND CONVEYORS, FOR GOODS OR MATERIALS: SPECIALLY DESIGNED FOR UNDERGROUND USE"
    },
    {
      "code": "84283200",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER CONTINUOUS-ACTION ELEVATORS AND CONVEYORS, FOR GOODS OR MATERIALS: OTHER, BUCKET TYPE"
    },
    {
      "code": "84283300",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER CONTINUOUS-ACTION ELEVATORS AND CONVEYORS, FOR GOODS OR MATERIALS: OTHER, BELT TYPE"
    },
    {
      "code": "84283900",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER CONTINUOUS-ACTION ELEVATORS AND CONVEYORS, FOR GOODS OR MATERIALS: OTHER"
    },
    {
      "code": "84284000",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - ESCALATORS AND MOVING WALKWAYS"
    },
    {
      "code": "84285010",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - MINE WAGON PUSHERS, LOCOMOTIVE OR WAGON TRAVERSERS, WAGON TIPPERS AND SIMILAR RAILWAY WAGON HANDLING EQUIPMENT: WAGON MARSHALLING EQUIPMENT"
    },
    {
      "code": "84285020",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - MINE WAGON PUSHERS, LOCOMOTIVE OR WAGON TRAVERSERS, WAGON TIPPERS AND SIMILAR RAILWAY WAGON HANDLING EQUIPMENT: WAGON TIPPERS"
    },
    {
      "code": "84285090",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - MINE WAGON PUSHERS, LOCOMOTIVE OR WAGON TRAVERSERS, WAGON TIPPERS AND SIMILAR RAILWAY WAGON HANDLING EQUIPMENT: OTHER"
    },
    {
      "code": "84286000",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - TELEFERICS, CHAIR-LIFTS, SKI-RAGLINES, TRACTION MECHANISMS FOR FUNICULARS"
    },
    {
      "code": "842890",
      "description": "Other machinery"
    },
    {
      "code": "84289010",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER MACHINERY : FOR COAL HANDLING"
    },
    {
      "code": "84289020",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER MACHINERY : FOR ASH HANDLING"
    },
    {
      "code": "84289090",
      "description": "OTHER LIFTING, HANDLING, LOADING OR UNLOADING MACHINERY (FOR EXAMPLE, LIFTS, ESCALATORS, CONVEYORS, TELEFERICS) - OTHER MACHINERY : OTHER"
    },
    {
      "code": "8429",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - Bulldozers and angledozers"
    },
    {
      "code": "842911",
      "description": "Track laying"
    },
    {
      "code": "84291110",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - BULLDOZERS AND ANGLEDOZERS - TRACK LAYING :ANGLEDOZERS"
    },
    {
      "code": "84291120",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - BULLDOZERS AND ANGLEDOZERS - TRACK LAYING : BULLDOZERS"
    },
    {
      "code": "842919",
      "description": "Other"
    },
    {
      "code": "84291910",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - BULLDOZERS AND ANGLEDOZERS - OTHER: ANGLEDOZERS"
    },
    {
      "code": "84291920",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - BULLDOZERS AND ANGLEDOZERS - OTHER:BULLDOZERS"
    },
    {
      "code": "84292000",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - GRADERS AND LEVELLERS"
    },
    {
      "code": "84293000",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - SCRAPPERS"
    },
    {
      "code": "842940",
      "description": "Tamping machines and road rollers"
    },
    {
      "code": "84294010",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - TAMPING MACHINES AND ROAD ROLLERS :ROAD ROLLERS UPTO 5 TONS CAPACITY"
    },
    {
      "code": "84294020",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - TAMPING MACHINES AND ROAD ROLLERS :ROAD ROLLERS ABOVE 5 TONS CAPACITY"
    },
    {
      "code": "84294030",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - TAMPING MACHINES AND ROAD ROLLERS :TAMPING MACHINES"
    },
    {
      "code": "84295100",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - MECHANICAL SHOVELS, EXCAVATORS AND SHOVEL LOADERS: FRONT-END SHOVEL LOADERS"
    },
    {
      "code": "84295200",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - MECHANICAL SHOVELS, EXCAVATORS AND SHOVEL LOADERS: MACHINERY WITH A 360 DEGREES REVOLVING SUPERSTRUCTURE"
    },
    {
      "code": "84295900",
      "description": "SELF-PROPELLED BULLDOZERS, ANGLEDOZERS, GRADERS, LEVELLERS, SCRAPERS, MECHANICAL SHOVELS, EXCAVATORS, SHOVEL LOADERS, TAMPING MACHINES AND ROAD ROLLERS - MECHANICAL SHOVELS, EXCAVATORS AND SHOVEL LOADERS: OTHER"
    },
    {
      "code": "8430",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILE-DRIVERS AND PILE-EXTRACTORS; SNOWPLOUGHS AND SNOW-BLOWERS"
    },
    {
      "code": "843010",
      "description": "Pile-drivers and pile-extractors"
    },
    {
      "code": "84301010",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - PILE-DRIVERS AND PILE-EXTRACTORS: PILE-DRIVERS"
    },
    {
      "code": "84301020",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - PILE-DRIVERS AND PILE-EXTRACTORS: PILE-EXTRACTORS"
    },
    {
      "code": "84302000",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - SNOW-PLOUGHS AND SNOW-BLOWERS"
    },
    {
      "code": "843031",
      "description": "Self-propelled"
    },
    {
      "code": "84303110",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - COAL OR ROCK CUTTERS AND TUNNELING MACHINERY: SELF-PROPELLED: COAL CUTTERS"
    },
    {
      "code": "84303120",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - COAL OR ROCK CUTTERS AND TUNNELING MACHINERY: SELF-PROPELLED: TUNNELING MACHINERY"
    },
    {
      "code": "84303190",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - COAL OR ROCK CUTTERS AND TUNNELING MACHINERY: SELF-PROPELLED: OTHER"
    },
    {
      "code": "84303900",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - COAL OR ROCK CUTTERS AND TUNNELING MACHINERY: OTHER"
    },
    {
      "code": "843041",
      "description": "Self-propelled"
    },
    {
      "code": "84304110",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - - OTHER BORING OR SINKING MACHINERY - SELF-PROPELLED: TUBE WELL DRILLING AND CORE DRILLING MACHINERY"
    },
    {
      "code": "84304120",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - - OTHER BORING OR SINKING MACHINERY - SELF-PROPELLED: PETROLEUM AND GAS WELL DRILLING MACHINERY"
    },
    {
      "code": "84304130",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - - OTHER BORING OR SINKING MACHINERY - SELF-PROPELLED: ROCK DRILLING MACHINERY"
    },
    {
      "code": "84304190",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - - OTHER BORING OR SINKING MACHINERY - SELF-PROPELLED: OTHER"
    },
    {
      "code": "84304900",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - OTHER BORING OR SINKING MACHINERY - OTHER"
    },
    {
      "code": "843050",
      "description": "Other machinery, self-propelled"
    },
    {
      "code": "84305010",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - OTHER MACHINERY, SELF-PROPELLED: MINING MACHINERY (EXCLUDING COAL MINING)"
    },
    {
      "code": "84305090",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - OTHER MACHINERY, SELF-PROPELLED: OTHER"
    },
    {
      "code": "84306100",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - OTHER MACHINERY, NOT SELF-PROPELLED: TAMPING OR COMPACTING MACHINERY"
    },
    {
      "code": "84306900",
      "description": "OTHER MOVING, GRADING, LEVELLING, SCRAPING, EXCAVATING, TAMPING, COMPACTING, EXTRACTING OR BORING MACHINERY, FOR EARTH, MINERALS OR ORES; PILEDRIVERS AND PILE-EXTRACTORS; SNOW-PLOUGHS AND SNOW-BLOWERS - OTHER MACHINERY, NOT SELF-PROPELLED: OTHER"
    },
    {
      "code": "8431",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430"
    },
    {
      "code": "843110",
      "description": "Of machinery of heading 8425"
    },
    {
      "code": "84311010",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8425 :OF PULLEY TACKLE AND HOISTS, OTHER THAN SHIP HOISTS, WINCHES OR CAPSTANS"
    },
    {
      "code": "84311090",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8425 :OTHER"
    },
    {
      "code": "843120",
      "description": "Of machinery of heading 8427"
    },
    {
      "code": "84312010",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8427 :OF FORK LIFT TRUCKS"
    },
    {
      "code": "84312090",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8427 :OTHER"
    },
    {
      "code": "84313100",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8428: OF LIFTS, SKIP HOISTS OR ESCALATORS"
    },
    {
      "code": "843139",
      "description": "Other"
    },
    {
      "code": "84313910",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8428: OTHER : OF ELEVATORS, CONVEYORS AND MOVING EQUIPMENTS"
    },
    {
      "code": "84313990",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8428: OTHER : OTHER"
    },
    {
      "code": "84314100",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430 :BUCKETS, SHOVELS, GRABS AND GRIPS"
    },
    {
      "code": "84314200",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430 :BULLDOZERS OR ANGLEDOZER BLADES"
    },
    {
      "code": "843143",
      "description": "Parts of boring or sinking machinery of sub-heading 8430 41 or 8430 49"
    },
    {
      "code": "84314310",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- PARTS OF BORING OR SINKING MACHINERY OF SUB-HEADING 8430 41 OR 8430 49: OF BORING OR SINKING MACHINERY, SELF-PROPELLED"
    },
    {
      "code": "84314390",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- PARTS OF BORING OR SINKING MACHINERY OF SUB-HEADING 8430 41 OR 8430 49: OTHER"
    },
    {
      "code": "843149",
      "description": "Other"
    },
    {
      "code": "84314910",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- OTHER : OF ROAD ROLLERS, MECHANICALLY PROPELLED"
    },
    {
      "code": "84314920",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- OTHER : OF SHIPS DERRICKS AND CRANES"
    },
    {
      "code": "84314930",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- OTHER : OF OTHER EXCAVATING, LEVELLING, TAMPING OR EXCAVATING MACHINERY FOR EARTH, MINERAL OR ORES"
    },
    {
      "code": "84314940",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- OTHER : OF PILE DRIVER, SNOW PLOUGH, NOT SELF- PROPELLED"
    },
    {
      "code": "84314990",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINERY OF HEADINGS 8425 TO 8430 - OF MACHINERY OF HEADING 8426, 8429 OR 8430- OTHER : OTHER"
    },
    {
      "code": "8432",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTS- GROUND ROLLERS"
    },
    {
      "code": "843210",
      "description": "Ploughs"
    },
    {
      "code": "84321010",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS 8432 10 - PLOUGHS: DISC PLOUGHS"
    },
    {
      "code": "84321020",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS 8432 10 - PLOUGHS: OTHER TRACTOR PLOUGHS"
    },
    {
      "code": "84321090",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS 8432 10 - PLOUGHS: OTHER"
    },
    {
      "code": "84322100",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - HARROWS, SCARIFIERS, CULTIVATORS, WEEDERS AND HOES: DISC HARROWS"
    },
    {
      "code": "843229",
      "description": "Other"
    },
    {
      "code": "84322910",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - HARROWS, SCARIFIERS, CULTIVATORS, WEEDERS AND HOES: OTHER: ROTARY HOES"
    },
    {
      "code": "84322990",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - HARROWS, SCARIFIERS, CULTIVATORS, WEEDERS AND HOES: OTHER: OTHER"
    },
    {
      "code": "84323000",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS SEEDERS, PLANTERS AND TRANSPLANTERS"
    },
    {
      "code": "84323100",
      "description": "No-till direct seeders, planters and transplanters"
    },
    {
      "code": "84323900",
      "description": "Other"
    },
    {
      "code": "84324000",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS MANURE SPREADERS AND FERTILIZER DISTRIBUTORS"
    },
    {
      "code": "84324100",
      "description": "Manure spreaders"
    },
    {
      "code": "84324200",
      "description": "Fertiliser distributors"
    },
    {
      "code": "843280",
      "description": "Other machinery"
    },
    {
      "code": "84328010",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - OTHER MACHINERY: LAWN OR SPORTS GROUND ROLLERS"
    },
    {
      "code": "84328020",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - OTHER MACHINERY: ROTARY TILLER"
    },
    {
      "code": "84328090",
      "description": "AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - OTHER MACHINERY: OTHER"
    },
    {
      "code": "843290",
      "description": "Parts"
    },
    {
      "code": "84329010",
      "description": "90 AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - PARTS: PARTS OF AGRICULTURAL MACHINERY FALLING WITHIN HEADINGS 8432 10, 8432 21, 8432 29, 8432 30 AND 8432 40"
    },
    {
      "code": "84329090",
      "description": "90 AGRICULTURAL, HORTICULTURAL OR FORESTRY MACHINERY FOR SOIL PREPARATION OR CULTIVATION; LAWN OR SPORTSGROUND ROLLERS - PARTS: OTHER"
    },
    {
      "code": "8433",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - Mowers for lawns, parks or sports-grounds"
    },
    {
      "code": "843311",
      "description": "Powered with the cutting device rotating in a horizontal plane"
    },
    {
      "code": "84331110",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - MOWERS FOR LAWNS, PARKS OR SPORTS-GROUNDS - POWERED WITH THE CUTTING DEVICE ROTATING IN A HORIZONTAL PLANE: POWERED WITH 3 HP OR MORE"
    },
    {
      "code": "84331190",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - MOWERS FOR LAWNS, PARKS OR SPORTS-GROUNDS - POWERED WITH THE CUTTING DEVICE ROTATING IN A HORIZONTAL PLANE: OTHER"
    },
    {
      "code": "843319",
      "description": "Other"
    },
    {
      "code": "84331910",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - MOWERS FOR LAWNS, PARKS OR SPORTS-GROUNDS - OTHER: NON-POWERED MOWERS, HAVING WIDTH OF 75 CM OR MORE"
    },
    {
      "code": "84331990",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - MOWERS FOR LAWNS, PARKS OR SPORTS-GROUNDS - OTHER: OTHER"
    },
    {
      "code": "84332000",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - OTHER MOWERS, INCLUDING CUTTER BARS FOR TRACTOR MOUNTING"
    },
    {
      "code": "84333000",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - OTHER HAYMAKING MACHINERY"
    },
    {
      "code": "84334000",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - STRAW OR FODDER BALERS, INCLUDING PICK-UP BALERS"
    },
    {
      "code": "84335100",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- OTHER HARVESTING MACHINERY; THRESHING MACHINERY: COMBINE HARVESTER- THRESHERS"
    },
    {
      "code": "84335200",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- OTHER HARVESTING MACHINERY; THRESHING MACHINERY : OTHER THRESHING MACHINERY"
    },
    {
      "code": "84335300",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- OTHER HARVESTING MACHINERY; THRESHING MACHINERY :ROOT OR TUBER HARVESTING MACHINES"
    },
    {
      "code": "84335900",
      "description": "HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- OTHER HARVESTING MACHINERY; THRESHING MACHINERY: OTHER"
    },
    {
      "code": "843360",
      "description": "Machines for cleaning, sorting or grading eggs, fruit or other agricultural produce"
    },
    {
      "code": "84336010",
      "description": "60 HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE: MACHINES FOR CLEANING"
    },
    {
      "code": "84336020",
      "description": "60 HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437- MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE: MACHINES FOR SORTING OR GRADING"
    },
    {
      "code": "84339000",
      "description": "60 HARVESTING OR THRESHING MACHINERY, INCLUDING STRAW OR FODDER BALERS; GRASS OR HAY MOWERS; MACHINES FOR CLEANING, SORTING OR GRADING EGGS, FRUIT OR OTHER AGRICULTURAL PRODUCE, OTHER THAN MACHINERY OF HEADING 8437 - PARTS"
    },
    {
      "code": "8434",
      "description": "MILKING MACHINES AND DAIRY MACHINERY"
    },
    {
      "code": "84341000",
      "description": "MILKING MACHINES AND DAIRY MACHINERY MILKING MACHINES"
    },
    {
      "code": "84342000",
      "description": "MILKING MACHINES AND DAIRY MACHINERY DAIRY MACHINERY"
    },
    {
      "code": "843490",
      "description": "Parts"
    },
    {
      "code": "84349010",
      "description": "MILKING MACHINES AND DAIRY MACHINERY - PARTS :OF MILKING MACHINERY"
    },
    {
      "code": "84349020",
      "description": "MILKING MACHINES AND DAIRY MACHINERY - PARTS :OF DAIRY MACHINERY"
    },
    {
      "code": "8435",
      "description": "PRESSES, CRUSHERS AND SIMILAR MACHINERY USED IN THE MANUFACTURE OF WINE, CIDER, FRUIT JUICES OR SIMILAR BEVERAGES"
    },
    {
      "code": "84351000",
      "description": "PRESSES, CRUSHERS AND SIMILAR MACHINERY USED IN THE MANUFACTURE OF WINE, CIDER, FRUIT JUICES OR SIMILAR BEVERAGES -MACHINERY"
    },
    {
      "code": "84359000",
      "description": "PRESSES, CRUSHERS AND SIMILAR MACHINERY USED IN THE MANUFACTURE OF WINE, CIDER, FRUIT JUICES OR SIMILAR BEVERAGES - PARTS"
    },
    {
      "code": "8436",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS"
    },
    {
      "code": "84361000",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - MACHINERY FOR PREPARING ANIMAL FEEDING STUFFS"
    },
    {
      "code": "84362100",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - POULTRY-KEEPING MACHINERY; POULTRY INCUBATORS AND BROODERS :POULTRY INCUBATORS AND BROODERS"
    },
    {
      "code": "84362900",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - POULTRY-KEEPING MACHINERY; POULTRY INCUBATORS AND BROODERS: OTHER"
    },
    {
      "code": "843680",
      "description": "Other machinery"
    },
    {
      "code": "84368010",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - OTHER MACHINERY: GERMINATION PLANT FITTED WITH MECHANICAL AND THERMAL EQUIPMENT"
    },
    {
      "code": "84368090",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - OTHER MACHINERY: OTHER"
    },
    {
      "code": "84369100",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - PARTS: OF POULTRY-KEEPING MACHINERY OR POULTRY INCUBATORS AND BROODERS"
    },
    {
      "code": "84369900",
      "description": "OTHER AGRICULTURAL, HORTICULTURAL, FORESTRY, POULTRY-KEEPING OR BEE-KEEPING MACHINERY, INCLUDING GERMINATION PLANT FITTED WITH MECHANICAL OR THERMAL EQUIPMENT; POULTRY INCUBATORS AND BROODERS - PARTS: OTHER"
    },
    {
      "code": "8437",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY"
    },
    {
      "code": "84371000",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES"
    },
    {
      "code": "843780",
      "description": "Other machinery"
    },
    {
      "code": "84378010",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - OTHER MACHINERY: FLOUR MILL MACHINERY"
    },
    {
      "code": "84378020",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - OTHER MACHINERY: RICE MILL MACHINERY"
    },
    {
      "code": "84378090",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - OTHER MACHINERY: OTHER"
    },
    {
      "code": "843790",
      "description": "Parts"
    },
    {
      "code": "84379010",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - PARTS : OF FLOUR MILL MACHINERY"
    },
    {
      "code": "84379020",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - PARTS : OF RICE MILL MACHINERY"
    },
    {
      "code": "84379090",
      "description": "MACHINES FOR CLEANING, SORTING OR GRADING SEED, GRAIN OR DRIED LEGUMINOUS VEGETABLES; MACHINERY USED IN THE MILLING INDUSTRY OR FOR THE WORKING OF CEREALS OR DRIED LEGUMINOUS VEGETABLES, OTHER THAN FARM-TYPE MACHINERY - PARTS : OTHER"
    },
    {
      "code": "8438",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS"
    },
    {
      "code": "843810",
      "description": "Bakery machinery and machinery for the manufacture of macaroni, spaghetti or similar products"
    },
    {
      "code": "84381010",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - BAKERY MACHINERY AND MACHINERY FOR THE MANUFACTURE OF MACARONI, SPAGHETTI OR SIMILAR PRODUCTS: BAKERY MACHINERY"
    },
    {
      "code": "84381020",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - BAKERY MACHINERY AND MACHINERY FOR THE MANUFACTURE OF MACARONI, SPAGHETTI OR SIMILAR PRODUCTS: MACHINERY FOR MANUFACTURE OF MACARONI OR SPAGHETTI OR SIMILAR PRODUCTS"
    },
    {
      "code": "84382000",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - MACHINERY FOR THE MANUFACTURE OF CONFECTIONERY, COCOA OR CHOCOLATE"
    },
    {
      "code": "843830",
      "description": "Machinery for sugar manufacture"
    },
    {
      "code": "84383010",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - MACHINERY FOR SUGAR MANUFACTURE: SUGAR CANE CRUSHERS"
    },
    {
      "code": "84383090",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - MACHINERY FOR SUGAR MANUFACTURE: OTHER"
    },
    {
      "code": "84384000",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - BREWERY MACHINERY"
    },
    {
      "code": "84385000",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - MACHINERY FOR THE PREPARATION OF MEAT OR POULTRY"
    },
    {
      "code": "84386000",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - MACHINERY FOR THE PREPARATION OF FRUITS, NUTS OR VEGETABLES"
    },
    {
      "code": "843880",
      "description": "Other machinery"
    },
    {
      "code": "84388010",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - OTHER MACHINERY: AUXILIARY EQUIPMENT FOR EXTRUSION COOKING PLANT"
    },
    {
      "code": "84388020",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - OTHER MACHINERY: FOR PRODUCTION OF SOYA MILK OR OTHER SOYA PRODUCTS (OTHER THAN SOYA OIL)"
    },
    {
      "code": "84388030",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - OTHER MACHINERY: DIFFUSING MACHINES (DIFFUSERS)"
    },
    {
      "code": "84388040",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - OTHER MACHINERY: TEA LEAF ROLLING OR CUTTING MACHINE"
    },
    {
      "code": "84388090",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - - OTHER MACHINERY: OTHER"
    },
    {
      "code": "843890",
      "description": "Parts"
    },
    {
      "code": "84389010",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - PARTS: OF SUGAR MANUFACTURING MACHINERY"
    },
    {
      "code": "84389090",
      "description": "MACHINERY, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER, FOR THE INDUSTRIAL PREPARATION OR MANUFACTURE OF FOOD OR DRINK, OTHER THAN MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS 8438 10 - PARTS: OF OTHER MACHINERY"
    },
    {
      "code": "8439",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD"
    },
    {
      "code": "84391000",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL"
    },
    {
      "code": "84392000",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD MACHINERY FOR MAKING PAPER OR PAPERBOARD"
    },
    {
      "code": "843930",
      "description": "Machinery for finishing paper or paperboard"
    },
    {
      "code": "84393010",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD - MACHINERY FOR FINISHING PAPER OR PAPERBOARD: PAPER LAMINATING MACHINE"
    },
    {
      "code": "84393090",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD - MACHINERY FOR FINISHING PAPER OR PAPERBOARD: OTHER"
    },
    {
      "code": "84399100",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD - PARTS: OF MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL"
    },
    {
      "code": "84399900",
      "description": "MACHINERY FOR MAKING PULP OF FIBROUS CELLULOSIC MATERIAL OR FOR MAKING OR FINISHING PAPER OR PAPERBOARD - PARTS: OTHER"
    },
    {
      "code": "8440",
      "description": "BOOK-BINDING MACHINERY, INCLUDING BOOKSEWING MACHINES"
    },
    {
      "code": "844010",
      "description": "Machinery"
    },
    {
      "code": "84401010",
      "description": "BOOK-BINDING MACHINERY, INCLUDING BOOK-SEWING MACHINES - MACHINERY: WIRE STITCHING MACHINERY, SINGLE HEADED"
    },
    {
      "code": "84401090",
      "description": "BOOK-BINDING MACHINERY, INCLUDING BOOK-SEWING MACHINES - MACHINERY: OTHER"
    },
    {
      "code": "84409000",
      "description": "BOOK-BINDING MACHINERY, INCLUDING BOOK-SEWING MACHINES - PARTS"
    },
    {
      "code": "8441",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS"
    },
    {
      "code": "844110",
      "description": "Cutting machines"
    },
    {
      "code": "84411010",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - CUTTING MACHINES: PAPER CUTTING MACHINES, EXCLUDING MACHINES WITH DEVICES SUCH AS AUTOMATIC PROGRAMME CUTTING OR THREE KNIFE TRIMMERS"
    },
    {
      "code": "84411090",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - CUTTING MACHINES: OTHER"
    },
    {
      "code": "84412000",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - MACHINES FOR MAKING BAGS, SACKS OR ENVELOPES"
    },
    {
      "code": "84413000",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - MACHINES FOR MAKING CARTONS, BOXES, CASES, TUBES, DRUMS OR SIMILAR CONTAINERS, OTHER THAN BY MOULDING"
    },
    {
      "code": "84414000",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - MACHINES FOR MOULDING ARTICLES IN PAPER PULP, PAPER OR PAPERBOARD"
    },
    {
      "code": "84418000",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - OTHER MACHINERY"
    },
    {
      "code": "84419000",
      "description": "OTHER MACHINERY FOR MAKING UP PAPER PULP, PAPER OR PAPERBOARD, INCLUDING CUTTING MACHINES OF ALL KINDS - PARTS"
    },
    {
      "code": "8442",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THEN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHO GRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED)"
    },
    {
      "code": "84421000",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) PHOTOTYPE-SETTING AND COMPOSING MACHINES"
    },
    {
      "code": "84422010",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) MACHINERY, APPARATUS AND EQUIPMENT FOR TYPE-SETTING OR COMPOSING BY OTHER PROCESSES, WITH OR WITHOUT FOUNDING DEVICE: STEREO-TYPING APPARATUS"
    },
    {
      "code": "84422020",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - MACHINERY, APPARATUS AND EQUIPMENT FOR TYPE-SETTING OR COMPOSING BY OTHER PROCESSES, WITH OR WITHOUT FOUNDING DEVICE: TYPE-CASTING (FOUNDING) MACHINES"
    },
    {
      "code": "84422030",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - MACHINERY, APPARATUS AND EQUIPMENT FOR TYPE-SETTING OR COMPOSING BY OTHER PROCESSES, WITH OR WITHOUT FOUNDING DEVICE: TYPE-SETTING MACHINES"
    },
    {
      "code": "84422090",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - MACHINERY, APPARATUS AND EQUIPMENT FOR TYPE-SETTING OR COMPOSING BY OTHER PROCESSES, WITH OR WITHOUT FOUNDING DEVICE: OTHER"
    },
    {
      "code": "844230",
      "description": "machinery, apparatus and equipment"
    },
    {
      "code": "84423010",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED);MACHINERY, APPARATUS AND EQUIPMENT:--- BRASS RULES"
    },
    {
      "code": "84423020",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED);MACHINERY, APPARATUS AND EQUIPMENT:---CHASES"
    },
    {
      "code": "84423090",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED);MACHINERY, APPARATUS AND EQUIPMENT:---OTHER"
    },
    {
      "code": "84424000",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED);- PARTS OF THE FOREGOING MACHINERY, APPARATUS OR EQUIPMENT"
    },
    {
      "code": "844250",
      "description": "Plates, cylinders and other printing components; plates, cylinders and lithographic stones, prepared for printing purposes (for example, planed, grained or polished)"
    },
    {
      "code": "84425010",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):--- PLATES AND CYLINDERS"
    },
    {
      "code": "84425020",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):-- LITHOGRAPHIC PLATES"
    },
    {
      "code": "84425031",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):--- PLATE, CYLINDER AND LITHOGRAPHIC STONES PREPARED FOR PRINTING PURPOSES:---- PLATE AND CYLINDER FOR TEXTILE PRINTING MACHINE"
    },
    {
      "code": "84425032",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - PLATE, BLOCK, CYLINDER AND LITHOGRAPHIC STONES PREPARED FOR PRINTING PURPOSES: PRINTING BLOCKS"
    },
    {
      "code": "84425039",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):--- PLATE, CYLINDER AND LITHOGRAPHIC STONES PREPARED FOR PRINTING PURPOSES:---- OTHER"
    },
    {
      "code": "84425040",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED): --- HIGHLY POLISHED COPPER SHEETS FOR MAKING BLOCKS"
    },
    {
      "code": "84425050",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):--- HIGHLY POLISHED ZINC SHEETS FOR MAKING PROCESS BLOCKS"
    },
    {
      "code": "84425060",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE-TOOLS OF HEADINGS 8456 TO 8465), FOR TYPE-FOUNDING OR TYPE-SETTING, FOR PREPARING OR MAKING PRINTING BLOCKS, PLATES, CYLINDERS OR OTHER PRINTING COMPONENTS; PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED) - PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; BLOCKS, PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED): HIGHLY POLISHED ZINC SHEETS FOR MAKING PROCESS BLOCKS"
    },
    {
      "code": "84425090",
      "description": "MACHINERY, APPARATUS AND EQUIPMENT (OTHER THAN THE MACHINE TOOLS OF HEADINGS 8456 TO 8465) FOR PREPARING OR MAKING PLATES, PRINTING COMPONENTS; PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES (FOR EXAMPLE, PLANED, GRAINED OR POLISHED); - PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS; PLATES, CYLINDERS AND LITHOGRAPHIC STONES, PREPARED FOR PRINTING PURPOSES ( FOR EXAMPLE, PLANED, GRAINED OR POLISHED):--- OTHER"
    },
    {
      "code": "8443",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES AND FACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - Printing machinery used for printing by means of plates,cylinders and other printing components of heading 8442 :"
    },
    {
      "code": "84431100",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OFFSET PRINTING MACHINERY, REEL-FED"
    },
    {
      "code": "84431200",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OFFSET PRINTING MACHINERY, SHEET-FED OFFICE TYPE (USING SHEETS WITH ONE SIDE NOT EXCEEDING 22 CM AND THE OTHER SIDE NOT EXCEEDING 36 CM IN THE UNFOLDED STATE)"
    },
    {
      "code": "84431300",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER OFFSET PRINTING MACHINERY"
    },
    {
      "code": "84431400",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- LETTERPRESS PRINTING MACHINERY, REEL FED, EXCLUDING FLEXOGRAPHIC PRINTING"
    },
    {
      "code": "84431500",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- LETTERPRESS PRINTING MACHINERY, OTHER THAN REEL FED, EXCLUDING FLEXOGRAPHIC PRINTING"
    },
    {
      "code": "84431600",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- FLEXOGRAPHIC PRINTING MACHINERY"
    },
    {
      "code": "84431700",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- GRAVURE PRINTING MACHINERY"
    },
    {
      "code": "844319",
      "description": "Other"
    },
    {
      "code": "84431900",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OFFSET PRINTING MACHINERY: OTHER"
    },
    {
      "code": "84431910",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER:---- FLAT BED PRINTING PRESSES"
    },
    {
      "code": "84431920",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER: --- PLATEN PRINTING PRESSES"
    },
    {
      "code": "84431930",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER:--- PROOF PRESSES"
    },
    {
      "code": "84431941",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :  ---  MACHINERY FOR PRINTING REPETITIVE WORD OR DESIGN OR COLOUR: ---- ON COTTON TEXTILE"
    },
    {
      "code": "84431949",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :  ---  MACHINERY FOR PRINTING REPETITIVE WORD OR DESIGN OR COLOUR: ---- OTHER"
    },
    {
      "code": "84431990",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :  ---  MACHINERY FOR PRINTING REPETITIVE WORD OR DESIGN OR COLOUR: --- OTHER"
    },
    {
      "code": "84432100",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - LETTERPRESS PRINTING MACHINERY, EXCLUDING FLEXOGRAPHIC PRINTING: REEL FED"
    },
    {
      "code": "84432900",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - LETTERPRESS PRINTING MACHINERY, EXCLUDING FLEXOGRAPHIC PRINTING: OTHER"
    },
    {
      "code": "84433000",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING FLEXOGRAPHIC PRINTING MACHINERY"
    },
    {
      "code": "84433100",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :- OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED:-- MACHINES WHICH PERFORM TWO OR MORE OF THE FUNCTIONS OF PRINTING, COPYING OR FACSIMILE TRANSMISSION, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING MACHINE OR TO A NETWORK"
    },
    {
      "code": "844332",
      "description": "Other, capable of connecting to an automatic data processing machine or to a network"
    },
    {
      "code": "84433210",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:--- LINE PRINTER"
    },
    {
      "code": "84433220",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:--- DOT MATRIX PRINTER"
    },
    {
      "code": "84433230",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:   --- LETTER QUALITY DAISY WHEEL PRINTER"
    },
    {
      "code": "84433240",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:  --- LASER JET PRINTER"
    },
    {
      "code": "84433250",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:   --- INK JET PRINTER"
    },
    {
      "code": "84433260",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK: --- FACSIMILE MACHINE"
    },
    {
      "code": "84433290",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:   --- OTHER"
    },
    {
      "code": "84433910",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :OTHER, CAPABLE OF CONNECTING TO AN AUTOMATIC DATA PROCESSING   MACHINE OR TO A NETWORK:  --- OTHER"
    },
    {
      "code": "84433920",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- ELECTROSTATIC PHOTOCOPYING APPARATUS OPERATED BY REPRODUCING THE ORIGINAL  IMAGE DIRECTLY ONTO THE COPY (DIRECT PROCESS)"
    },
    {
      "code": "84433930",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- ELECTROSTATIC PHOTOCOPYING APPARATUS OPERATED BY REPRODUCING THE ORIGINAL  IMAGE VIA AN INTERMEDIATE ONTO THE COPY (INDIRECT PROCESS)"
    },
    {
      "code": "84433940",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER ---  OTHER PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM"
    },
    {
      "code": "84433950",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- OTHER PHOTOCOPYING APPARATUS OF THE CONTACT TYPE"
    },
    {
      "code": "84433960",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- THERMO-COPYING APPARATUS"
    },
    {
      "code": "84433970",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- FACSIMILE MACHINE NOT CAPABLE OF GETTING CONNECTED TO AUTOMATIC DATA PROCESSING MACHINE"
    },
    {
      "code": "84433990",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- OTHER"
    },
    {
      "code": "84434000",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING:- GRAVURE PRINTING MACHINERY"
    },
    {
      "code": "84435100",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: INK-JET PRINTING MACHINES"
    },
    {
      "code": "84435910",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: FLAT BED PRINTING PRESSES"
    },
    {
      "code": "84435920",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: PLATEN PRINTING PRESSES"
    },
    {
      "code": "84435930",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: PROOF PRESSES"
    },
    {
      "code": "84435941",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: MACHINERY FOR PRINTING REPETITIVE WORD OR DESIGN OR COLOUR: ON COTTON TEXTILE"
    },
    {
      "code": "84435949",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: MACHINERY FOR PRINTING REPETITIVE WORD OR DESIGN OR COLOUR: OTHER"
    },
    {
      "code": "84435990",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: OTHER: OTHER"
    },
    {
      "code": "84436010",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: MACHINES FOR USES ANCILLARY TO PRINTING: AUTOMATIC FEEDERS AND SHEET DELIVERING MACHINE"
    },
    {
      "code": "84436020",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: MACHINES FOR USES ANCILLARY TO PRINTING: SERIAL NUMBERING MACHINES"
    },
    {
      "code": "84436030",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: MACHINES FOR USES ANCILLARY TO PRINTING: FOLDERS, GUZMNOS PERFORATORS AND STAPLERS"
    },
    {
      "code": "84436090",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - OTHER PRINTING MACHINERY: MACHINES FOR USES ANCILLARY TO PRINTING: OTHER"
    },
    {
      "code": "84439010",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - - PARTS: PARTS OF PRINTING PRESSES"
    },
    {
      "code": "84439090",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF THE PRINTING TYPE, BLOCKS, PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; INK-JET PRINTING MACHINES, OTHER THAN THOSE OF HEADING 8471; MACHINES FOR USES ANCILLARY TO PRINTING - - PARTS: OTHER"
    },
    {
      "code": "84439100",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 :-- PARTS AND ACCESSORIES OF PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442"
    },
    {
      "code": "844399",
      "description": "Other"
    },
    {
      "code": "84439910",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER :--- AUTOMATIC DOCUMENT FEEDERES OF COPYING MACHINES"
    },
    {
      "code": "84439920",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER --- PAPER FEEDERS OF COPYING MACHINES"
    },
    {
      "code": "84439930",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER : --- SORTERS OF COPYING MACHINES"
    },
    {
      "code": "84439940",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER :--- OTHER PARTS OF COPYING MACHINES"
    },
    {
      "code": "84439950",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER :--- PARTS AND ACCESSORIES OF GOODS OF SUBHEADINGS 844331,844332"
    },
    {
      "code": "84439951",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PARTS AND ACCESSORIES -- OTHER  --- OF SUBHEADING 8443 31, 8443 32 ---- INK CARTRIDGES, WITH PRINT HEAD ASSEMBLY"
    },
    {
      "code": "84439952",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES AND FACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PARTS AND ACCESSORIES -- OTHER  --- OF SUBHEADING 8443 31, 8443 32 ---- INK SPRAY NOZZLE"
    },
    {
      "code": "84439953",
      "description": "INK SPRAY NOZZLE"
    },
    {
      "code": "84439959",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PARTS AND ACCESSORIES -- OTHER  --- OF SUBHEADING 8443 31, 8443 32 ---- OTHER"
    },
    {
      "code": "84439960",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PARTS AND ACCESSORIES -- OTHER  --- OF SUBHEADING 8443 39"
    },
    {
      "code": "84439970",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER : --- PARTS AND ACCESSORIES OF GOODS OF SUBHEADING 844339"
    },
    {
      "code": "84439990",
      "description": "PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHERPRINTING COMPONENTS OF HEADING 8442; OTHER PRINTERS, COPYING MACHINES ANDFACSIMILE MACHINES, WHETHER OR NOT COMBINED; PARTS AND ACCESSORIES THEREOF - PRINTING MACHINERY USED FOR PRINTING BY MEANS OF PLATES, CYLINDERS AND OTHER PRINTING COMPONENTS OF HEADING 8442 : -- OTHER :--- OTHER"
    },
    {
      "code": "8444",
      "description": "MACHINES FOR EXTRUDING, DRAWING, TEXTURING OR CUTTING MAN-MADE TEXTILE MATERIALS"
    },
    {
      "code": "844400",
      "description": "Machines for extruding, drawing, texturing or cutting man-made textile materials"
    },
    {
      "code": "84440010",
      "description": "MACHINES FOR EXTRUDING, DRAWING, TEXTURING OR CUTTING MAN-MADE TEXTILE MATERIALS 8444 00 - MACHINES FOR EXTRUDING, DRAWING, TEXTURING OR CUTTING MAN-MADE TEXTILE MATERIALS: MACHINES FOR EXTRUDING MAN-MADE TEXTILE MATERIALS"
    },
    {
      "code": "84440090",
      "description": "MACHINES FOR EXTRUDING, DRAWING, TEXTURING OR CUTTING MAN-MADE TEXTILE MATERIALS 8444 00 - MACHINES FOR EXTRUDING, DRAWING, TEXTURING OR CUTTING MAN-MADE TEXTILE MATERIALS: OTHER"
    },
    {
      "code": "8445",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFTWINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - Machines for preparing textile fibres"
    },
    {
      "code": "844511",
      "description": "Carding machines"
    },
    {
      "code": "84451110",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - CARDING MACHINES : COTTON CARDING MACHINES"
    },
    {
      "code": "84451190",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - CARDING MACHINES : OTHER"
    },
    {
      "code": "844512",
      "description": "Combing machines"
    },
    {
      "code": "84451210",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - COMBING MACHINES: COTTON COMBING MACHINES"
    },
    {
      "code": "84451290",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - COMBING MACHINES: OTHER"
    },
    {
      "code": "84451300",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - DRAWING OR ROVING MACHINES"
    },
    {
      "code": "844519",
      "description": "Other"
    },
    {
      "code": "84451910",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:COTTON PROCESSING MACHINES (INCLUDING COTTON GINNING MACHINE)"
    },
    {
      "code": "84451920",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:JUTE FIBRE PROCESSING MACHINES"
    },
    {
      "code": "84451930",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:REGENERATED FIBRES AND SYNTHETIC FIBRES PROCESSING MACHINES"
    },
    {
      "code": "84451940",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:SILK PROCESSING MACHINES"
    },
    {
      "code": "84451950",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:WOOL PROCESSING MACHINES"
    },
    {
      "code": "84451960",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:BLOWROOM MACHINES"
    },
    {
      "code": "84451990",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - MACHINES FOR PREPARING TEXTILE FIBRES - OTHER:OTHER"
    },
    {
      "code": "844520",
      "description": "Textile spinning machines"
    },
    {
      "code": "84452011",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : COTTON SPINNING MACHINES : DRAWING FRAMES"
    },
    {
      "code": "84452012",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : COTTON SPINNING MACHINES : INTERMEDIATE FRAMES"
    },
    {
      "code": "84452013",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : COTTON SPINNING MACHINES : RING FRAMES"
    },
    {
      "code": "84452014",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : COTTON SPINNING MACHINES : ROVING FRAMES"
    },
    {
      "code": "84452019",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : COTTON SPINNING MACHINES : OTHER"
    },
    {
      "code": "84452020",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : JUTE FIBRES SPINNING MACHINES"
    },
    {
      "code": "84452030",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : REGENERATED FIBRES AND SYNTHETIC FIBRES SPINNING MACHINES"
    },
    {
      "code": "84452040",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : SILK FIBRES SPINNING MACHINES"
    },
    {
      "code": "84452050",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : WOOL SPINNING MACHINES"
    },
    {
      "code": "84452090",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE SPINNING MACHINES : OTHER"
    },
    {
      "code": "844530",
      "description": "Textile doubling or twisting machines"
    },
    {
      "code": "84453011",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : COTTON FIBRE DOUBLING OR TWISTING MACHINES : DOUBLING FRAMES"
    },
    {
      "code": "84453019",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : COTTON FIBRE DOUBLING OR TWISTING MACHINES : OTHER"
    },
    {
      "code": "84453020",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : JUTE FIBRE DOUBLING OR TWISTING MACHINES"
    },
    {
      "code": "84453030",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : REGENERATED FIBRE AND SYNTHETIC FIBRE YARN DOUBLING OR TWISTING MACHINES"
    },
    {
      "code": "84453040",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : SILK FIBRE DOUBLING OR TWISTING MACHINES"
    },
    {
      "code": "84453050",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : WOOL FIBRE DOUBLING OR TWISTING MACHINES"
    },
    {
      "code": "84453090",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE DOUBLING OR TWISTING MACHINES : OTHER FIBRE DOUBLING OR TWISTING MACHINES"
    },
    {
      "code": "844540",
      "description": "Textile winding (including weft-winding) or reeling machines"
    },
    {
      "code": "84454010",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: COTTON FIBRE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES, AUTOMATIC OR OTHERWISE"
    },
    {
      "code": "84454020",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: JUTE FIBRE REELING (INCLUDING WEFT-WINDING) MACHINES"
    },
    {
      "code": "84454030",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: REGENERATED FIBRES YARN AND SYNTHETIC FIBRES YARN REELING (INCLUDING WEFT-WINDING) MACHINES"
    },
    {
      "code": "84454040",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: SILK FIBRE REELING (INCLUDING WEFT-WINDING) MACHINES"
    },
    {
      "code": "84454050",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: WOOL FIBRE REELING (INCLUDING WEFT-WINDING) MACHINES"
    },
    {
      "code": "84454090",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - TEXTILE WINDING (INCLUDING WEFT-WINDING) OR REELING MACHINES: OTHER"
    },
    {
      "code": "84459000",
      "description": "MACHINES FOR PREPARING TEXTILE FIBRES; SPINNING, DOUBLING OR TWISTING MACHINES AND OTHER MACHINERY FOR PRODUCING TEXTILE YARNS; TEXTILE REELING OR WINDING (INCLUDING WEFT-WINDING) MACHINES AND MACHINES FOR PREPARING TEXTILE YARNS FOR USE ON THE MACHINES OF HEADING 8446 OR 8447 - OTHER"
    },
    {
      "code": "8446",
      "description": "WEAVING MACHINES (LOOMS)"
    },
    {
      "code": "844610",
      "description": "For weaving fabrics of a width not exceeding 30 cm"
    },
    {
      "code": "84461011",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM: COTTON WEAVING MACHINES : AUTOMATIC, POWERLOOM"
    },
    {
      "code": "84461012",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM: COTTON WEAVING MACHINES : PLAIN, POWERLOOM"
    },
    {
      "code": "84461019",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM: COTTON WEAVING MACHINES : OTHER"
    },
    {
      "code": "84461090",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM: OTHER"
    },
    {
      "code": "844621",
      "description": "Power looms"
    },
    {
      "code": "84462110",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM, SHUTTLE TYPE - POWER LOOMS: COTTON WEAVING MACHINES, AUTOMATIC"
    },
    {
      "code": "84462190",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM, SHUTTLE TYPE - POWER LOOMS: OTHER"
    },
    {
      "code": "844629",
      "description": "Other"
    },
    {
      "code": "84462910",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM, SHUTTLE TYPE - OTHER : COTTON WEAVING MACHINES"
    },
    {
      "code": "84462990",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH NOT EXCEEDING 30 CM, SHUTTLE TYPE - OTHER : OTHER"
    },
    {
      "code": "844630",
      "description": "For weaving fabrics of a width exceeding30 cm, shuttleless type"
    },
    {
      "code": "84463011",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH EXCEEDING 30 CM, SHUTTLELESS TYPE: COTTON WEAVING MACHINES : AUTOMATIC, POWERLOOM"
    },
    {
      "code": "84463012",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH EXCEEDING 30 CM, SHUTTLELESS TYPE: COTTON WEAVING MACHINES :PLAIN, POWERLOOM"
    },
    {
      "code": "84463019",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH EXCEEDING 30 CM, SHUTTLELESS TYPE: COTTON WEAVING MACHINES : OTHER"
    },
    {
      "code": "84463090",
      "description": "WEAVING MACHINES (LOOMS) - FOR WEAVING FABRICS OF A WIDTH EXCEEDING 30 CM, SHUTTLELESS TYPE: OTHER"
    },
    {
      "code": "8447",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - Circular knitting machines"
    },
    {
      "code": "844711",
      "description": "With cylinder diameter not exceeding 165 mm"
    },
    {
      "code": "84471111",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER NOT EXCEEDING 165 MM: WOOL KNITTING MACHINES : HAND KNITTING MACHINES"
    },
    {
      "code": "84471119",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER NOT EXCEEDING 165 MM: WOOL KNITTING MACHINES : OTHER"
    },
    {
      "code": "84471120",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER NOT EXCEEDING 165 MM: COTTON HOSIERY MACHINES"
    },
    {
      "code": "84471190",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER NOT EXCEEDING 165 MM: OTHER"
    },
    {
      "code": "844712",
      "description": "With cylinder diameter exceeding 165 mm"
    },
    {
      "code": "84471211",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER EXCEEDING 165 MM: WOOL KNITTING MACHINES: HAND KNITTING MACHINES"
    },
    {
      "code": "84471219",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER EXCEEDING 165 MM: WOOL KNITTING MACHINES: OTHER"
    },
    {
      "code": "84471220",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER EXCEEDING 165 MM: COTTON HOSIERY MACHINES"
    },
    {
      "code": "84471290",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - CIRCULAR KNITTING MACHINES - WITH CYLINDER DIAMETER EXCEEDING 165 MM: OTHER"
    },
    {
      "code": "844720",
      "description": "Flat kniting machines; stitch-bonding machines"
    },
    {
      "code": "84472010",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - FLAT KNITING MACHINES; STITCH-BONDING MACHINES: HAND KNITTING MACHINES FOR WOOL"
    },
    {
      "code": "84472020",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - FLAT KNITING MACHINES; STITCH-BONDING MACHINES: OTHER KNITTING MACHINES FOR WOOL"
    },
    {
      "code": "84472030",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - FLAT KNITING MACHINES; STITCH-BONDING MACHINES: COTTON HOSIERY MACHINES"
    },
    {
      "code": "84472090",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - FLAT KNITING MACHINES; STITCH-BONDING MACHINES: OTHER"
    },
    {
      "code": "844790",
      "description": "Other"
    },
    {
      "code": "84479010",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - OTHER: MACHINES FOR MAKING OF TULLE AND LACE"
    },
    {
      "code": "84479020",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - OTHER: MACHINES FOR MAKING EMBROIDERY"
    },
    {
      "code": "84479030",
      "description": "KNITTING MACHINES, STITCH-BONDING MACHINES AND MACHINES FOR MAKING GIMPED YARN, TULLE, LACE, EMBROIDERY, TRIMMINGS, BRAID OR NET AND MACHINES FOR TUFTING - OTHER: OTHER"
    },
    {
      "code": "8448",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - Auxiliary machinery for machines of heading 8444, 8445, 8446 or 8447"
    },
    {
      "code": "844811",
      "description": "Dobbies and jacquards; card reducing, copying, punching or"
    },
    {
      "code": "84481110",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - AUXILIARY MACHINERY FOR MACHINES OF HEADING 8444, 8445, 8446 OR 8447: DOBBIES AND JACQUARDS; CARD REDUCING, COPYING, PUNCHING OR ASSEMBLING MACHINES FOR USE THEREWITH: JACQUARDS AND HARNESS LINER CARDS FOR COTTON TEXTILE MACHINERY"
    },
    {
      "code": "84481190",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - AUXILIARY MACHINERY FOR MACHINES OF HEADING 8444, 8445, 8446 OR 8447: DOBBIES AND JACQUARDS; CARD REDUCING, COPYING, PUNCHING OR ASSEMBLING MACHINES FOR USE THEREWITH: OTHER"
    },
    {
      "code": "84481900",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - AUXILIARY MACHINERY FOR MACHINES OF HEADING 8444, 8445, 8446 OR 8447: OTHER"
    },
    {
      "code": "84482000",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8444 OR OF THEIR AUXILIARY MACHINERY"
    },
    {
      "code": "84483100",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: CARD CLOTHING"
    },
    {
      "code": "844832",
      "description": "Of machines for preparing textile fibres, other than card clothing"
    },
    {
      "code": "84483210",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OF MACHINES FOR PREPARING TEXTILE FIBRES, OTHER THAN CARD CLOTHING: FOR COTTON PROCESSING MACHINES"
    },
    {
      "code": "84483220",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OF MACHINES FOR PREPARING TEXTILE FIBRES, OTHER THAN CARD CLOTHING: FOR JUTE PROCESSING MACHINES"
    },
    {
      "code": "84483230",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OF MACHINES FOR PREPARING TEXTILE FIBRES, OTHER THAN CARD CLOTHING: FOR SILK AND MANMADE (REGENERATED AND SYNTHETIC FIBRES PROCESSING MACHINES)"
    },
    {
      "code": "84483240",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OF MACHINES FOR PREPARING TEXTILE FIBRES, OTHER THAN CARD CLOTHING: FOR WOOL PROCESSING MACHINES"
    },
    {
      "code": "84483290",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OF MACHINES FOR PREPARING TEXTILE FIBRES, OTHER THAN CARD CLOTHING: OTHER"
    },
    {
      "code": "844833",
      "description": "Spindles, spindle flyers, spinning rings and ring travellers"
    },
    {
      "code": "84483310",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: SPINDLES, SPINDLE FLYERS, SPINNING RINGS AND RING TRAVELLERS: FOR COTTON SPINNING MACHINES"
    },
    {
      "code": "84483320",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: SPINDLES, SPINDLE FLYERS, SPINNING RINGS AND RING TRAVELLERS:FOR JUTE SPINNING MACHINES"
    },
    {
      "code": "84483330",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: SPINDLES, SPINDLE FLYERS, SPINNING RINGS AND RING TRAVELLERS:FOR SILK AND MAN- MADE (REGENERATED AND SYNTHETIC) FIBRE SPINNING MACHINES"
    },
    {
      "code": "84483340",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: SPINDLES, SPINDLE FLYERS, SPINNING RINGS AND RING TRAVELLERS:FOR WOOL SPINNING MACHINES"
    },
    {
      "code": "84483390",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: SPINDLES, SPINDLE FLYERS, SPINNING RINGS AND RING TRAVELLERS:FOR OTHER TEXTILE FIBRE SPINNING MACHINES"
    },
    {
      "code": "844839",
      "description": "Other"
    },
    {
      "code": "84483910",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OTHER: COMBS FOR COTTON TEXTILE MACHINERY"
    },
    {
      "code": "84483920",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OTHER: GILLS FOR GILL BOXES"
    },
    {
      "code": "84483990",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8445 OR OF THEIR AUXILIARY MACHINERY: OTHER: OTHER"
    },
    {
      "code": "84484110",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES: OF COTTON WEAVING MACHINERY"
    },
    {
      "code": "84484120",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES:OF JUTE WEAVING MACHINERY"
    },
    {
      "code": "84484130",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES: OF SILK AND MAN-MADE FIBRES WEAVING MACHINERY"
    },
    {
      "code": "84484140",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES: OF WOOL WEAVING MACHINERY"
    },
    {
      "code": "84484150",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES:OF OTHER TEXTILE FIBRES WEAVING MACHINERY"
    },
    {
      "code": "84484160",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES: OF AUXILIARY MACHINERY OF HEADING 8448"
    },
    {
      "code": "84484190",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY : 8448 41 - SHUTTLES:OTHER"
    },
    {
      "code": "844842",
      "description": "Reeds for looms, healds and heald-frames"
    },
    {
      "code": "84484210",
      "description": "42 AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - REEDS FOR LOOMS, HEALDS AND HEALD-FRAMES: HEALDS (EXCLUDING WIRE HEALDS) AND REEDS FOR COTTON MACHINERY"
    },
    {
      "code": "84484220",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - REEDS FOR LOOMS, HEALDS AND HEALD-FRAMES: HEALDS, WIRE"
    },
    {
      "code": "84484290",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - REEDS FOR LOOMS, HEALDS AND HEALD-FRAMES: OTHER"
    },
    {
      "code": "844849",
      "description": "Other"
    },
    {
      "code": "84484910",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: PARTS OF COTTON WEAVING MACHINERY"
    },
    {
      "code": "84484920",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: PARTS OF JUTE WEAVING MACHINERY"
    },
    {
      "code": "84484930",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: PARTS OF SILK AND MAN-MADE FIBRES WEAVING MACHINERY"
    },
    {
      "code": "84484940",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: PARTS OF WOOL WEAVING MACHINERY"
    },
    {
      "code": "84484950",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: PARTS OF OTHER TEXTILE WEAVING MACHINERY"
    },
    {
      "code": "84484990",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF WEAVING MACHINES (LOOMS) OR OF THEIR AUXILIARY MACHINERY - OTHER: OTHER"
    },
    {
      "code": "844851",
      "description": "Sinkers, needles and other articles used in forming stitches"
    },
    {
      "code": "84485110",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8447 OR OF THEIR AUXILIARY MACHINERY: 8448 51 - SINKERS, NEEDLES AND OTHER ARTICLES USED IN FORMING STITCHES: OF COTTON HOSIERY MACHINE"
    },
    {
      "code": "84485120",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8447 OR OF THEIR AUXILIARY MACHINERY: 8448 51 - SINKERS, NEEDLES AND OTHER ARTICLES USED IN FORMING STITCHES: OF WOOL KNITTING MACHINES"
    },
    {
      "code": "84485130",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8447 OR OF THEIR AUXILIARY MACHINERY: 8448 51 - SINKERS, NEEDLES AND OTHER ARTICLES USED IN FORMING STITCHES: OF MACHINES FOR TULLE, LACE"
    },
    {
      "code": "84485190",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8447 OR OF THEIR AUXILIARY MACHINERY: 8448 51 - SINKERS, NEEDLES AND OTHER ARTICLES USED IN FORMING STITCHES: OTHER"
    },
    {
      "code": "84485900",
      "description": "AUXILIARY MACHINERY FOR USE WITH MACHINES OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, DOBBIES, JACQUARDS, AUTOMATIC STOP MOTIONS, SHUTTLE CHANGING MECHANISMS); PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF THIS HEADING OR OF HEADING 8444, 8445, 8446 OR 8447 (FOR EXAMPLE, SPINDLES AND SPINDLE FLYERS, CARD CLOTHING, COMBS, EXTRUDING NIPPLES, SHUTTLES, HEALDS AND HEALD FRAMES, HOSIERY NEEDLES) - PARTS AND ACCESSORIES OF MACHINES OF HEADING 8447 OR OF THEIR AUXILIARY MACHINERY: 8448 51 OTHER"
    },
    {
      "code": "8449",
      "description": "MACHINERY FOR THE MANUFACTURE OR FINISHING OF FELT OR NONWOVENS IN THE PIECE OR IN SHAPES, INCLUDING MACHINERY FOR MAKING FELT HATS; BLOCKS FOR MAKING HATS"
    },
    {
      "code": "844900",
      "description": "Machinery for the manufacture or finishing of felt or nonwovens in the piece or in shapes, including machinery for making felt hats; blocks for making hats"
    },
    {
      "code": "84490010",
      "description": "MACHINERY FOR THE MANUFACTURE OR FINISHING OF FELT OR NONWOVENS IN THE PIECE OR IN SHAPES, INCLUDING MACHINERY FOR MAKING FELT HATS; BLOCKS FOR MAKING HATS 8449 00 - MACHINERY FOR THE MANUFACTURE OR FINISHING OF FELT OR NONWOVENS IN THE PIECE OR IN SHAPES, INCLUDING MACHINERY FOR MAKING FELT HATS; BLOCKS FOR MAKING HATS: MACHINERY FOR MANUFACTURE OR FINISHING OF FELT IN PIECE OR IN SHAPES (INCLUDING FELT HAT-MAKING MACHINES AND HAT MAKING BLOCKS)"
    },
    {
      "code": "84490090",
      "description": "MACHINERY FOR THE MANUFACTURE OR FINISHING OF FELT OR NONWOVENS IN THE PIECE OR IN SHAPES, INCLUDING MACHINERY FOR MAKING FELT HATS; BLOCKS FOR MAKING HATS 8449 00 - MACHINERY FOR THE MANUFACTURE OR FINISHING OF FELT OR NONWOVENS IN THE PIECE OR IN SHAPES, INCLUDING MACHINERY FOR MAKING FELT HATS; BLOCKS FOR MAKING HATS: OTHER"
    },
    {
      "code": "8450",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - Machines, each of a dry linen capacity not exceeding 10 kg"
    },
    {
      "code": "84501100",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - MACHINES, EACH OF A DRY LINEN CAPACITY NOT EXCEEDING 10 KG: FULLY-AUTOMATIC MACHINES"
    },
    {
      "code": "84501200",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - MACHINES, EACH OF A DRY LINEN CAPACITY NOT EXCEEDING 10 KG: OTHER MACHINES, WITH BUILT-IN CENTRIFUGAL DRIER"
    },
    {
      "code": "84501900",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - MACHINES, EACH OF A DRY LINEN CAPACITY NOT EXCEEDING 10 KG: OTHER"
    },
    {
      "code": "84502000",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY MACHINES, EACH OF A DRY LINEN CAPACITY EXCEEDING 10 KG"
    },
    {
      "code": "845090",
      "description": "Parts"
    },
    {
      "code": "84509010",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - PARTS: PARTS OF HOUSEHOLD TYPE MACHINES"
    },
    {
      "code": "84509090",
      "description": "HOUSEHOLD OR LAUNDRY-TYPE WASHING MACHINES, INCLUDING MACHINES WHICH BOTH WASH AND DRY - PARTS: OTHER"
    },
    {
      "code": "8451",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS"
    },
    {
      "code": "845110",
      "description": "Dry-cleaning machines"
    },
    {
      "code": "84511010",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - DRY-CLEANING MACHINES: DRY-CLEANING MACHINES FOR COTTON TEXTILE"
    },
    {
      "code": "84511090",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - DRY-CLEANING MACHINES:OTHER"
    },
    {
      "code": "84512100",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- DRYING MACHINES:EACH OF A DRY LINEN CAPACITY NOT EXCEEDING10 KG"
    },
    {
      "code": "84512900",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- DRYING MACHINES:OTHER"
    },
    {
      "code": "845130",
      "description": "Ironing machines and presses (including fusing presses)"
    },
    {
      "code": "84513010",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - IRONING MACHINES AND PRESSES (INCLUDING FUSING PRESSES): HAND IRONING PRESS"
    },
    {
      "code": "84513090",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - IRONING MACHINES AND PRESSES (INCLUDING FUSING PRESSES): OTHER"
    },
    {
      "code": "845140",
      "description": "Washing, bleaching or dyeing machines"
    },
    {
      "code": "84514011",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- WASHING, BLEACHING OR DYEING MACHINES: BLEACHING MACHINE:FOR COTTON TEXTILE"
    },
    {
      "code": "84514019",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- WASHING, BLEACHING OR DYEING MACHINES: BLEACHING MACHINE:OTHER"
    },
    {
      "code": "84514021",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- WASHING, BLEACHING OR DYEING MACHINES: DYEING MACHINES: FOR COTTON TEXTILE"
    },
    {
      "code": "84514029",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS- WASHING, BLEACHING OR DYEING MACHINES: DYEING MACHINES: FOR OTHER TEXTILE"
    },
    {
      "code": "84514091",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - WASHING, BLEACHING OR DYEING MACHINES: OTHER: FOR WASHING AND CLEANING FOR WOOLLEN TEXTILE"
    },
    {
      "code": "84514099",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - WASHING, BLEACHING OR DYEING MACHINES: OTHER: OTHER"
    },
    {
      "code": "84515000",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS"
    },
    {
      "code": "845180",
      "description": "Other machinery"
    },
    {
      "code": "84518011",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY: SIZING AND DRESSING MACHINES: FOR COTTON TEXTILE"
    },
    {
      "code": "84518019",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY: SIZING AND DRESSING MACHINES: OTHER"
    },
    {
      "code": "84518021",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY: FINISHING MACHINES: FOR COATING OR IMPREGNATING YARNOR FABRICS"
    },
    {
      "code": "84518022",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY: FINISHING MACHINES: OTHER FINISHING PROCESSES FOR COTTON TEXTILE"
    },
    {
      "code": "84518029",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY: FINISHING MACHINES: OTHER"
    },
    {
      "code": "84518090",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS - OTHER MACHINERY - OTHER"
    },
    {
      "code": "84519000",
      "description": "MACHINERY (OTHER THAN MACHINES OF HEADING 8450) FOR WASHING, CLEANING, WRINGING, DRYING, IRONING, PRESSING (INCLUDING FUSING PRESSES), BLEACHING, DYEING, DRESSING, FINISHING, COATING OR IMPREGNATING TEXTILE YARNS, FABRICS OR MADE UP TEXTILE ARTICLES AND MACHINES FOR APPLYING THE PASTE TO THE BASE FABRIC OR OTHER SUPPORT USED IN THE MANUFACTURE OF FLOOR COVERINGS SUCH AS LINOLEUM; MACHINES FOR REELING, UNREELING, FOLDING, CUTTING OR PINKING TEXTILE FABRICS PARTS"
    },
    {
      "code": "8452",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES"
    },
    {
      "code": "845210",
      "description": "Sewing machines of the household type"
    },
    {
      "code": "84521011",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - SEWING MACHINES OF THE HOUSEHOLD TYPE: COMPLETE, WITH STAND OR TABLE:WITH ELECTRONIC CONTROLS OR ELECTRIC MOTORS"
    },
    {
      "code": "84521012",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - SEWING MACHINES OF THE HOUSEHOLD TYPE: COMPLETE, WITH STAND OR TABLE:HAND OPERATED"
    },
    {
      "code": "84521019",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - SEWING MACHINES OF THE HOUSEHOLD TYPE: COMPLETE, WITH STAND OR TABLE:OTHER"
    },
    {
      "code": "84521021",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES 8452 10 - SEWING MACHINES OF THE HOUSEHOLD TYPE:- WITHOUT STAND OR TABLE (HEADS): WITH ELECTRONIC CONTROLS OR ELECTRIC MOTORS"
    },
    {
      "code": "84521022",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES 8452 10 - SEWING MACHINES OF THE HOUSEHOLD TYPE:- WITHOUT STAND OR TABLE (HEADS): HAND OPERATED"
    },
    {
      "code": "84521029",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES 8452 10 - SEWING MACHINES OF THE HOUSEHOLD TYPE:- WITHOUT STAND OR TABLE (HEADS): OTHER"
    },
    {
      "code": "845221",
      "description": "Automatic units"
    },
    {
      "code": "84522110",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - OTHER SEWING MACHINES: AUTOMATIC UNITS: INDUSTRIAL SEWING MACHINES HAVING A MOTOR OF 150 WATTS CAPACITY AND ABOVE AND HAVING A SPEED OF 1,500 STITCHES PER MINUTE OR MORE"
    },
    {
      "code": "84522120",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - OTHER SEWING MACHINES: AUTOMATIC UNITS: OTHER WITH ELECTRONIC CONTROLS OR ELECTRIC MOTORS"
    },
    {
      "code": "84522190",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - OTHER SEWING MACHINES: AUTOMATIC UNITS: OTHER"
    },
    {
      "code": "84522900",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES OTHER"
    },
    {
      "code": "845230",
      "description": "Sewing machine needles"
    },
    {
      "code": "84523010",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - SEWING MACHINE NEEDLES : FOR HOUSEHOLD TYPE SEWING MACHINES"
    },
    {
      "code": "84523090",
      "description": "SEWING MACHINES, OTHER THAN BOOK-SEWING MACHINES OF HEADING 8440; FURNITURE, BASES AND COVERS SPECIALLY DESIGNED FOR SEWING MACHINES; SEWING MACHINE NEEDLES - SEWING MACHINE NEEDLES : OTHER"
    },
    {
      "code": "845240",
      "description": "Furniture, bases and covers for sewing machines and parts thereof"
    },
    {
      "code": "845290",
      "description": "Other parts of sewing machines"
    },
    {
      "code": "84529011",
      "description": "FURNITURE, BASES AND COVERS"
    },
    {
      "code": "84529019",
      "description": "PARTS OF FURNITURE, BASES AND COVER FOR SEWING MACHINES"
    },
    {
      "code": "84529091",
      "description": "OF HOUSEHOLD SEWING MACHINES"
    },
    {
      "code": "84529099",
      "description": "OTHER"
    },
    {
      "code": "8453",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES"
    },
    {
      "code": "84531000",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES - MACHINERY FOR PREPARING, TANNING OR WORKINGHIDES, SKINS OR LEATHER"
    },
    {
      "code": "84532000",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES - MACHINERY FOR MAKING OR REPAIRING FOOTWEAR"
    },
    {
      "code": "84538000",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES - OTHER MACHINERY"
    },
    {
      "code": "845390",
      "description": "Parts"
    },
    {
      "code": "84539010",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES - PARTS : OF BOOT AND SHOE MANUFACTURING MACHINERY"
    },
    {
      "code": "84539090",
      "description": "MACHINERY FOR PREPARING, TANNING OR WORKING HIDES, SKINS OR LEATHER OR FOR MAKING OR REPAIRING FOOTWEAR OR OTHER ARTICLES OF HIDES, SKINS OR LEATHER, OTHER THAN SEWING MACHINES - PARTS : OTHER"
    },
    {
      "code": "8454",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES"
    },
    {
      "code": "84541000",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES CONVERTERS"
    },
    {
      "code": "845420",
      "description": "Ingot moulds and ladles"
    },
    {
      "code": "84542010",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES - INGOT MOULDS AND LADLES : LADLES"
    },
    {
      "code": "84542020",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES - INGOT MOULDS AND LADLES : INGOT MOULDS"
    },
    {
      "code": "845430",
      "description": "Casting machines"
    },
    {
      "code": "84543010",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES - CASTING MACHINES: DIE-CASTING MACHINES"
    },
    {
      "code": "84543020",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES - CASTING MACHINES: CONTINUOUS CASTING MACHINES"
    },
    {
      "code": "84543090",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES - CASTING MACHINES: OTHER"
    },
    {
      "code": "84549000",
      "description": "CONVERTERS, LADLES, INGOT MOULDS AND CASTING MACHINES, OF A KIND USED IN METALLURGY OR IN METAL FOUNDRIES PARTS"
    },
    {
      "code": "8455",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR"
    },
    {
      "code": "84551000",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR TUBE MILLS"
    },
    {
      "code": "845521",
      "description": "Hot or combination hot and cold"
    },
    {
      "code": "84552110",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR - OTHER ROLLING MILLS: HOT OR COMBINATION HOT AND COLD: HOT"
    },
    {
      "code": "84552120",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR - OTHER ROLLING MILLS: HOT OR COMBINATION HOT AND COLD: COMBINATION HOT AND COLD"
    },
    {
      "code": "84552200",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR - OTHER ROLLING MILLS: COLD"
    },
    {
      "code": "84553000",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR - ROLLS FOR ROLLING MILLS"
    },
    {
      "code": "84559000",
      "description": "METAL-ROLLING MILLS AND ROLLS THEREFOR - OTHER PARTS"
    },
    {
      "code": "8456",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRODISCHARGE, ELECTRO-CHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES; WATERJET CUTTING MACHINES"
    },
    {
      "code": "84561000",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OPERATED BY LASER OR OTHER LIGHT OR PHOTONBEAM PROCESSES"
    },
    {
      "code": "84561100",
      "description": "Operated by laser"
    },
    {
      "code": "84561200",
      "description": "Operated by other light or photon beam processes"
    },
    {
      "code": "84562000",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OPERATED BY ULTRASONIC PROCESSES"
    },
    {
      "code": "84563000",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OPERATED BY ELECTRO-DISCHARGE PROCESSES"
    },
    {
      "code": "84564000",
      "description": "Operated by plasma arc processes"
    },
    {
      "code": "84565000",
      "description": "Water-jet cutting machines"
    },
    {
      "code": "845690",
      "description": "Other"
    },
    {
      "code": "84569010",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: --- FOR DRY ETCH PATTERN ON SEMI CONDUCTOR MATERIAL"
    },
    {
      "code": "84569020",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: --- ELECTRO CHEMICAL MACHINES"
    },
    {
      "code": "84569090",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: --- OTHER"
    },
    {
      "code": "84569100",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: FOR DRY-ETCHING PATTERNS ON SEMI-CONDUCTOR MATERIALS"
    },
    {
      "code": "84569910",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: OTHER - ELECTRO CHEMICAL MACHINES"
    },
    {
      "code": "84569990",
      "description": "MACHINE-TOOLS FOR WORKING ANY MATERIAL BY REMOVAL OF MATERIAL, BY LASER OR OTHER LIGHT OR PHOTON BEAM, ULTRA-SONIC, ELECTRO-DISCHARGE, ELECTROCHEMICAL, ELECTRON BEAM, IONIC-BEAM OR PLASMA ARC PROCESSES - OTHER: OTHER - OTHER"
    },
    {
      "code": "8457",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL"
    },
    {
      "code": "845710",
      "description": "Machining centres"
    },
    {
      "code": "84571010",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - MACHINING CENTRES:HORIZONTAL"
    },
    {
      "code": "84571020",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - MACHINING CENTRES:VERTICAL"
    },
    {
      "code": "845720",
      "description": "Unit construction machines (single station)"
    },
    {
      "code": "84572010",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - UNIT CONSTRUCTION MACHINES (SINGLE STATION): UNIT HEAD BORING"
    },
    {
      "code": "84572020",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - UNIT CONSTRUCTION MACHINES (SINGLE STATION): UNIT HEAD DRILLING"
    },
    {
      "code": "84572090",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - UNIT CONSTRUCTION MACHINES (SINGLE STATION): OTHER"
    },
    {
      "code": "845730",
      "description": "Multi-station transfer machines"
    },
    {
      "code": "84573010",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - MULTI-STATION TRANSFER MACHINES:ROTARY TYPE"
    },
    {
      "code": "84573020",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - MULTI-STATION TRANSFER MACHINES: IN-LINE TYPE"
    },
    {
      "code": "84573090",
      "description": "MACHINING CENTRES, UNIT CONSTRUCTION MACHINES (SINGLE STATION) AND MULTI-STATION TRANSFER MACHINES FOR WORKING METAL - MULTI-STATION TRANSFER MACHINES:OTHER"
    },
    {
      "code": "8458",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - Horizontal lathes"
    },
    {
      "code": "84581100",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:NUMERICALLY CONTROLLED"
    },
    {
      "code": "845819",
      "description": "Other"
    },
    {
      "code": "84581911",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:- OTHER: AUTOMATIC, SINGLE SPINDLE: HORIZONTAL BAR, SWISS TYPE"
    },
    {
      "code": "84581912",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:- OTHER: AUTOMATIC, SINGLE SPINDLE: BASE SLIDING HEAD TYPE"
    },
    {
      "code": "84581913",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:- OTHER: AUTOMATIC, SINGLE SPINDLE: HORIZONTAL CHUCKING"
    },
    {
      "code": "84581919",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:- OTHER: AUTOMATIC, SINGLE SPINDLE: OTHER"
    },
    {
      "code": "84581990",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - HORIZONTAL LATHES:- OTHER: OTHER"
    },
    {
      "code": "84589100",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: NUMERICALLY CONTROLLED"
    },
    {
      "code": "845899",
      "description": "Other"
    },
    {
      "code": "84589910",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: AUTOMATIC, MULTI SPINDLE BAR"
    },
    {
      "code": "84589920",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: AUTOMATIC, MULTI SPINDLE CHUCKING"
    },
    {
      "code": "84589931",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CAPSTANS, TURRETS, CAPSTAN AND TURRET COMBINATION, COPING, MULTI TOOL AND PRODUCTION LATHES: CAPSTANS LATHES"
    },
    {
      "code": "84589932",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CAPSTANS, TURRETS, CAPSTAN AND TURRET COMBINATION, COPING, MULTI TOOL AND PRODUCTION LATHES: TURRETS LATHES"
    },
    {
      "code": "84589933",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CAPSTANS, TURRETS, CAPSTAN AND TURRET COMBINATION, COPING, MULTI TOOL AND PRODUCTION LATHES: CAPSTAN AND TURRET COMBINATION LATHES"
    },
    {
      "code": "84589934",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CAPSTANS, TURRETS, CAPSTAN AND TURRET COMBINATION, COPING, MULTI TOOL AND PRODUCTION LATHES: COPYING LATHES"
    },
    {
      "code": "84589935",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CAPSTANS, TURRETS, CAPSTAN AND TURRET COMBINATION, COPING, MULTI TOOL AND PRODUCTION LATHES: MULTI-TOOL AND PRODUCTION LATHES"
    },
    {
      "code": "84589941",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CRANKSHAFT, RELIEVING, WHEEL AND AXLE LATHES: CRANKSHAFT LATHES"
    },
    {
      "code": "84589942",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CRANKSHAFT, RELIEVING, WHEEL AND AXLE LATHES: RELIEVING LATHES"
    },
    {
      "code": "84589943",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CRANKSHAFT, RELIEVING, WHEEL AND AXLE LATHES: WHEEL AND AXLE LATHES"
    },
    {
      "code": "84589951",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CENTRE LATHES: TOOL-ROOM TYPE"
    },
    {
      "code": "84589959",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: CENTRE LATHES: OTHER"
    },
    {
      "code": "84589990",
      "description": "LATHES (INCLUDING TURNING CENTRES) FOR REMOVING METAL - OTHER LATHES: OTHER: OTHER"
    },
    {
      "code": "8459",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, TREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458"
    },
    {
      "code": "84591000",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 WAY TYPE UNIT HEAD MACHINES"
    },
    {
      "code": "84592100",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES:NUMERICALLY CONTROLLED"
    },
    {
      "code": "845929",
      "description": "Other"
    },
    {
      "code": "84592910",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: BENCH AND PILLAR"
    },
    {
      "code": "84592920",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: PILLAR OR COLUMNS, MULTI SPINDLE"
    },
    {
      "code": "84592930",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: RADIAL"
    },
    {
      "code": "84592940",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: DEEP HOLE"
    },
    {
      "code": "84592950",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: MULTI HEAD DRILLING MACHINES"
    },
    {
      "code": "84592990",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER DRILLING MACHINES: OTHER: OTHER"
    },
    {
      "code": "84593100",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING-MILLING MACHINES: NUMERICALLY CONTROLLED"
    },
    {
      "code": "845939",
      "description": "Other"
    },
    {
      "code": "84593910",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING-MILLING MACHINES: OTHER: VERTICAL TURNING OR BORING"
    },
    {
      "code": "84593990",
      "description": "OTHER"
    },
    {
      "code": "845940",
      "description": "Other boring machines"
    },
    {
      "code": "84594010",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING MACHINES : JIG BORING MACHINES HORIZONTAL"
    },
    {
      "code": "84594020",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING MACHINES : FINE BORING MACHINES, HORIZONTAL"
    },
    {
      "code": "84594030",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING MACHINES : FINE BORING MACHINES, VERTICAL"
    },
    {
      "code": "84594090",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER BORING MACHINES : OTHER"
    },
    {
      "code": "845941",
      "description": "Numerically controlled"
    },
    {
      "code": "84594110",
      "description": "Jig boring machines, horizontal"
    },
    {
      "code": "84594120",
      "description": "Fine boring machines, horizontal"
    },
    {
      "code": "84594130",
      "description": "Fine boring machines, vertical"
    },
    {
      "code": "84594190",
      "description": "Other"
    },
    {
      "code": "845949",
      "description": "Other"
    },
    {
      "code": "84594910",
      "description": "Jig boring machines, horizontal"
    },
    {
      "code": "84594920",
      "description": "Fine boring machines, horizontal"
    },
    {
      "code": "84594930",
      "description": "Fine boring machines, vertical"
    },
    {
      "code": "84594990",
      "description": "Other"
    },
    {
      "code": "845951",
      "description": "Numerically controlled"
    },
    {
      "code": "84595110",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: NUMERICALLY CONTROLLED: HORIZONTAL"
    },
    {
      "code": "84595120",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: NUMERICALLY CONTROLLED: VERTICAL"
    },
    {
      "code": "84595130",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: NUMERICALLY CONTROLLED: UNIVERSAL"
    },
    {
      "code": "84595190",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: NUMERICALLY CONTROLLED: OTHER"
    },
    {
      "code": "845959",
      "description": "Other"
    },
    {
      "code": "84595910",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: HORIZONTAL"
    },
    {
      "code": "84595920",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: VERTICAL"
    },
    {
      "code": "84595930",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: UNIVERSAL"
    },
    {
      "code": "84595940",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: RAM TYPE"
    },
    {
      "code": "84595950",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: DIE-SINKING OR PANTOGRAPH"
    },
    {
      "code": "84595990",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - MILLING MACHINE, KNEE TYPE: OTHER: OTHER"
    },
    {
      "code": "845961",
      "description": "Numerically controlled"
    },
    {
      "code": "84596110",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER MILLING MACHINES: NUMERICALLY CONTROLLED: PIANO MILLING"
    },
    {
      "code": "84596190",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458 - OTHER MILLING MACHINES: NUMERICALLY CONTROLLED: OTHER"
    },
    {
      "code": "845969",
      "description": "Other"
    },
    {
      "code": "84596910",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER: BED TYPE, HORIZONTAL"
    },
    {
      "code": "84596920",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER: BED TYPE, VERTICAL"
    },
    {
      "code": "84596930",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER: PIANO MILLING, SINGLE COLUMN"
    },
    {
      "code": "84596940",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER: PIANO MILLING, DOUBLE COLUMN"
    },
    {
      "code": "84596990",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER: OTHER"
    },
    {
      "code": "845970",
      "description": "Other threading or tapping machines"
    },
    {
      "code": "84597010",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER THREADING OR TAPPING MACHINES :THREADING MACHINES"
    },
    {
      "code": "84597020",
      "description": "MACHINE-TOOLS (INCLUDING WAY-TYPE UNIT HEAD MACHINES) FOR DRILLING, BORING, MILLING, THREADING OR TAPPING BY REMOVING METAL, OTHER THAN LATHES (INCLUDING TURNING CENTRES) OF HEADING 8458- OTHER THREADING OR TAPPING MACHINES :TAPPING MACHINES"
    },
    {
      "code": "8460",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - Flat-surface grinding machines, in which the positioning in any one axis can be set up to an accuracy of at least 0.01 mm"
    },
    {
      "code": "84601100",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - FLAT-SURFACE GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM:NUMERICALLY CONTROLLED"
    },
    {
      "code": "84601200",
      "description": "Numerically controlled"
    },
    {
      "code": "84601900",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - FLAT-SURFACE GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM:OTHER"
    },
    {
      "code": "84602100",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: NUMERICALLY CONTROLLED"
    },
    {
      "code": "84602200",
      "description": "Centreless grinding machines, numerically controlled"
    },
    {
      "code": "84602300",
      "description": "Other cylindrical grinding machines, numerically controlled"
    },
    {
      "code": "84602400",
      "description": "Other, numerically controlled"
    },
    {
      "code": "846029",
      "description": "Other"
    },
    {
      "code": "84602910",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: OTHER: CYLINDRICAL GRINDERS"
    },
    {
      "code": "84602920",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: OTHER: INTERNAL GRINDERS"
    },
    {
      "code": "84602930",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: OTHER: CENTRELESS GRINDERS"
    },
    {
      "code": "84602940",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: OTHER: PROFILE GRINDERS"
    },
    {
      "code": "84602990",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER GRINDING MACHINES, IN WHICH THE POSITIONING IN ANY ONE AXIS CAN BE SET UP TO AN ACCURACY OF AT LEAST 0.01 MM: OTHER: OTHER"
    },
    {
      "code": "84603100",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - SHARPENING (TOOL OR CUTTER GRINDING) MACHINES: NUMERICALLY CONTROLLED"
    },
    {
      "code": "846039",
      "description": "Other"
    },
    {
      "code": "84603910",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - SHARPENING (TOOL OR CUTTER GRINDING) MACHINES: OTHER: GRINDER, TOOL OR CUTTER"
    },
    {
      "code": "84603990",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - SHARPENING (TOOL OR CUTTER GRINDING) MACHINES: OTHER: OTHER"
    },
    {
      "code": "846040",
      "description": "Honing or lapping machines"
    },
    {
      "code": "84604011",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - HONING OR LAPPING MACHINES: HONING MACHINES: VERTICAL, SINGLE SPINDLE"
    },
    {
      "code": "84604012",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - HONING OR LAPPING MACHINES: HONING MACHINES: VERTICAL, MULTI SPINDLE"
    },
    {
      "code": "84604013",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - HONING OR LAPPING MACHINES: - HONING MACHINES: HORIZONTAL"
    },
    {
      "code": "84604019",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - HONING OR LAPPING MACHINES: HONING MACHINES: OTHER"
    },
    {
      "code": "84604020",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - HONING OR LAPPING MACHINES: LAPPING MACHINES"
    },
    {
      "code": "846090",
      "description": "Other"
    },
    {
      "code": "84609010",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER: POLISHING AND BUFFING MACHINES"
    },
    {
      "code": "84609090",
      "description": "MACHINE-TOOLS FOR DEBURRING, SHARPENING, GRINDING, HONING, LAPPING, POLISHING OR OTHERWISE FINISHING METAL, OR CERMETS BY MEANS OF GRINDING STONES, ABRASIVES OR POLISHING PRODUCTS, OTHER THAN GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES OF HEADING 8461 - OTHER: OTHER"
    },
    {
      "code": "8461",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "846120",
      "description": "Shaping or slotting machines"
    },
    {
      "code": "84612011",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SHAPING OR SLOTTING MACHINES: SHAPING MACHINES: DIE AND PUNCH SHAPING MACHINES"
    },
    {
      "code": "84612019",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SHAPING OR SLOTTING MACHINES: SHAPING MACHINES: OTHER"
    },
    {
      "code": "84612020",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SHAPING OR SLOTTING MACHINES: SLOTTING MACHINES"
    },
    {
      "code": "846130",
      "description": "Broaching machines"
    },
    {
      "code": "84613010",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - BROACHING MACHINES: VERTICAL"
    },
    {
      "code": "84613020",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - BROACHING MACHINES: HORIZONTAL"
    },
    {
      "code": "84613090",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - BROACHING MACHINES: OTHER"
    },
    {
      "code": "846140",
      "description": "Gear cutting, gear grinding or gear finishing machines"
    },
    {
      "code": "84614011",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES: GEAR CUTTING MACHINES: BEVEL GEAR CUTTING"
    },
    {
      "code": "84614012",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES: GEAR CUTTING MACHINES: GEAR CUTTING SPIRAL BEVEL AND /OR HYPOID"
    },
    {
      "code": "84614013",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES: GEAR CUTTING MACHINES: GEAR SLOTTER OR PLANAR FORMED CUTTER TYPE"
    },
    {
      "code": "84614014",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES: GEAR CUTTING MACHINES: GEAR MILLING FORMED DISC CUTTER TYPE"
    },
    {
      "code": "84614019",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES: GEAR CUTTING MACHINES: OTHER"
    },
    {
      "code": "84614021",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : SINGLE OR DOUBLE WHEEL DISC TYPE GEAR GRINDER"
    },
    {
      "code": "84614022",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : FORMED WHEEL GEAR GRINDER"
    },
    {
      "code": "84614023",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : GEAR SHAVER"
    },
    {
      "code": "84614024",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : GEAR TOOTH, ROUNDING, CHAMFERING OR BURRING"
    },
    {
      "code": "84614025",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : GEAR SHAPER, SPUR AND HELICAL"
    },
    {
      "code": "84614026",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : GEAR HOBBER, SPUR AND HELICAL"
    },
    {
      "code": "84614029",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING MACHINES- GEAR GRINDING OR GEAR FINISHING MACHINES : OTHER"
    },
    {
      "code": "846150",
      "description": "Sawing or cutting-off machines"
    },
    {
      "code": "84615011",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: BAND SAW, HORIZONTAL"
    },
    {
      "code": "84615012",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: BAND SAW, VERTICAL"
    },
    {
      "code": "84615013",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: CIRCULAR SAW, COLD"
    },
    {
      "code": "84615014",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: CIRCULAR SAW, HOT"
    },
    {
      "code": "84615015",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: HACK SAW"
    },
    {
      "code": "84615019",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: SAWING MACHINES: OTHER"
    },
    {
      "code": "84615021",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: CUTTING-OFF MACHINES: ABRASIVE WHEEL CUTTING-OFF MACHINES"
    },
    {
      "code": "84615029",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - SAWING OR CUTTING-OFF MACHINES: CUTTING-OFF MACHINES: OTHER"
    },
    {
      "code": "84619000",
      "description": "MACHINE-TOOLS FOR PLANING, SHAPING, SLOTTING, BROACHING, GEAR CUTTING, GEAR GRINDING OR GEAR FINISHING, SAWING, CUTTING-OFF AND OTHER MACHINE TOOLS WORKING BY REMOVING METAL, OR CERMETS, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER"
    },
    {
      "code": "8462",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES ) FOR WORKING METAL BY FORGING, HAMMERING OR DIESTAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE"
    },
    {
      "code": "846210",
      "description": "Forging or die-stamping machines (including presses) and hammers"
    },
    {
      "code": "84621011",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: HAMMERS: STEAM OR AIR, SINGLE FRAME"
    },
    {
      "code": "84621012",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: HAMMERS: STEAM OR AIR, DOUBLE FRAME"
    },
    {
      "code": "84621013",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: HAMMERS: HEADERS AND UPSETTERS"
    },
    {
      "code": "84621014",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: HAMMERS: DOUBLE ACTING COUNTER BLOW, AIR OR STEAM"
    },
    {
      "code": "84621019",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: HAMMERS: OTHER"
    },
    {
      "code": "84621020",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: FORGING MACHINES"
    },
    {
      "code": "84621030",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - FORGING OR DIE-STAMPING MACHINES (INCLUDING PRESSES) AND HAMMERS: DIE STAMPING MACHINES"
    },
    {
      "code": "84622100",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - BENDING, FOLDING, STRAIGHTENING OR FLATTENING MACHINES (INCLUDING PRESSES): NUMERICALLY CONTROLLED"
    },
    {
      "code": "846229",
      "description": "Other"
    },
    {
      "code": "84622910",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - BENDING, FOLDING, STRAIGHTENING OR FLATTENING MACHINES (INCLUDING PRESSES): OTHER: BENDING AND STRAIGHTENING MACHINES"
    },
    {
      "code": "84622920",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - BENDING, FOLDING, STRAIGHTENING OR FLATTENING MACHINES (INCLUDING PRESSES): OTHER: PRESS BRAKES"
    },
    {
      "code": "84622930",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - BENDING, FOLDING, STRAIGHTENING OR FLATTENING MACHINES (INCLUDING PRESSES): OTHER: OTHER ROTARY HEAD AND RAM TYPE"
    },
    {
      "code": "84622990",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - BENDING, FOLDING, STRAIGHTENING OR FLATTENING MACHINES (INCLUDING PRESSES): OTHER: OTHER"
    },
    {
      "code": "84623100",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- SHEARING MACHINES (INCLUDING PRESSES), OTHER THAN COMBINED PUNCHING AND SHEARING MACHINES : NUMERICALLY CONTROLLED"
    },
    {
      "code": "846239",
      "description": "Other"
    },
    {
      "code": "84623910",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- SHEARING MACHINES (INCLUDING PRESSES), OTHER THAN COMBINED PUNCHING AND SHEARING MACHINES : OTHER: PLATE AND SHEET SHEARS (GUILLOTINE)"
    },
    {
      "code": "84623920",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- SHEARING MACHINES (INCLUDING PRESSES), OTHER THAN COMBINED PUNCHING AND SHEARING MACHINES : OTHER: BAR AND ANGLE SHEARING AND CROPPING"
    },
    {
      "code": "84623990",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- SHEARING MACHINES (INCLUDING PRESSES), OTHER THAN COMBINED PUNCHING AND SHEARING MACHINES : OTHER: OTHER"
    },
    {
      "code": "84624100",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- PUNCHING OR NOTCHING MACHINES (INCLUDING PRESSES), INCLUDING COMBINED PUNCHING AND SHEARING MACHINES: NUMERICALLY CONTROLLED"
    },
    {
      "code": "846249",
      "description": "Other"
    },
    {
      "code": "84624910",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- PUNCHING OR NOTCHING MACHINES (INCLUDING PRESSES), INCLUDING COMBINED PUNCHING AND SHEARING MACHINES: OTHER: PUNCHING MACHINES (INCLUDING TURRET)"
    },
    {
      "code": "84624920",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- PUNCHING OR NOTCHING MACHINES (INCLUDING PRESSES), INCLUDING COMBINED PUNCHING AND SHEARING MACHINES: OTHER: COMBINATION OF PUNCHING, SHEARING AND CROPPING MACHINES"
    },
    {
      "code": "84624930",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- PUNCHING OR NOTCHING MACHINES (INCLUDING PRESSES), INCLUDING COMBINED PUNCHING AND SHEARING MACHINES: OTHER: NIBBLING MACHINES"
    },
    {
      "code": "84624990",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE- PUNCHING OR NOTCHING MACHINES (INCLUDING PRESSES), INCLUDING COMBINED PUNCHING AND SHEARING MACHINES: OTHER: OTHER"
    },
    {
      "code": "846291",
      "description": "Hydraulic presses"
    },
    {
      "code": "84629110",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - HYDRAULIC PRESSES: HYDRAULIC EXTENSION"
    },
    {
      "code": "84629190",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - HYDRAULIC PRESSES:OTHER"
    },
    {
      "code": "846299",
      "description": "Other"
    },
    {
      "code": "84629911",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: PNEUMATIC PRESSES"
    },
    {
      "code": "84629912",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: INCLINABLE PRESSES"
    },
    {
      "code": "84629913",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: VERTICAL GAP OF FRAME PRESSES"
    },
    {
      "code": "84629914",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: VERTICAL STRAIGHT PRESSES"
    },
    {
      "code": "84629915",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: VERTICAL FORGING PRESSES"
    },
    {
      "code": "84629919",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - PNEUMATIC, INCLINABLE AND VERTICAL PRESSES: OTHER"
    },
    {
      "code": "84629920",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER -DIEING OR LOBBING MACHINE PRESSES"
    },
    {
      "code": "84629930",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER -TRANSFER AND MULTIPLE PRESSES"
    },
    {
      "code": "84629940",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - HORIZONTAL PRESSES"
    },
    {
      "code": "84629950",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - FRICTION SCREW PRESSES"
    },
    {
      "code": "84629960",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - KNUCKLE JOINT PRESSES"
    },
    {
      "code": "84629970",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER -COINING JOINT PRESSES"
    },
    {
      "code": "84629990",
      "description": "MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY FORGING, HAMMERING OR DIE-STAMPING; MACHINE-TOOLS (INCLUDING PRESSES) FOR WORKING METAL BY BENDING, FOLDING, STRAIGHTENING, FLATTENING, SHEARING, PUNCHING OR NOTCHING; PRESSES FOR WORKING METAL OR METAL CARBIDES, NOT SPECIFIED ABOVE - OTHER - OTHER"
    },
    {
      "code": "8463",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL"
    },
    {
      "code": "846310",
      "description": "Draw-benches for bars, tubes, profiles, wire or the like"
    },
    {
      "code": "84631010",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - DRAW-BENCHES FOR BARS, TUBES, PROFILES, WIRE OR THE LIKE:WIRE AND METAL RIBBON DRAWING MACHINES"
    },
    {
      "code": "84631020",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - DRAW-BENCHES FOR BARS, TUBES, PROFILES, WIRE OR THE LIKE:OTHER WIRE MAKING MACHINES"
    },
    {
      "code": "84631030",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - DRAW-BENCHES FOR BARS, TUBES, PROFILES, WIRE OR THE LIKE:TUBE DRAWING MACHINES"
    },
    {
      "code": "84631090",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - DRAW-BENCHES FOR BARS, TUBES, PROFILES, WIRE OR THE LIKE:OTHER"
    },
    {
      "code": "84632000",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL THREAD ROLLING MACHINES"
    },
    {
      "code": "846330",
      "description": "Machines for working wire"
    },
    {
      "code": "84633010",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - MACHINES FOR WORKING WIRE : WIRE GRILL OR KNITTING MACHINE"
    },
    {
      "code": "84633020",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - MACHINES FOR WORKING WIRE : SPRING COILING"
    },
    {
      "code": "84633030",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - MACHINES FOR WORKING WIRE : CHAIN MAKING"
    },
    {
      "code": "84633040",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - MACHINES FOR WORKING WIRE : NAIL MAKING MACHINE"
    },
    {
      "code": "846390",
      "description": "Other"
    },
    {
      "code": "84639010",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - OTHER: RIVETING MACHINES (EXCLUDING PORTABLE HAND OPERATED MACHINE)"
    },
    {
      "code": "84639020",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - OTHER: STRIP PROFILING"
    },
    {
      "code": "84639030",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - OTHER: SEAMING MACHINE FOR EXAMPLE FOR CANS"
    },
    {
      "code": "84639090",
      "description": "OTHER MACHINE-TOOLS FOR WORKING METAL, OR CERMETS, WITHOUT REMOVING MATERIAL - OTHER: OTHER"
    },
    {
      "code": "8464",
      "description": "MACHINE-TOOLS FOR WORKING STONE, CERAMICS CONCRETE, ASBESTOS-CEMENT OR LIKE MINERALMATERIALS OR FOR COLD WORKING GLASS"
    },
    {
      "code": "846410",
      "description": "Sawing machine"
    },
    {
      "code": "84641010",
      "description": "MACHINE-TOOLS FOR WORKING STONE, CERAMICS, CONCRETE, ASBESTOS-CEMENT OR LIKE MINERAL MATERIALS OR FOR COLD WORKING GLASS - SAWING MACHINE: GRANITE CUTTING MACHINES OR EQUIPMENT"
    },
    {
      "code": "84641090",
      "description": "MACHINE-TOOLS FOR WORKING STONE, CERAMICS, CONCRETE, ASBESTOS-CEMENT OR LIKE MINERAL MATERIALS OR FOR COLD WORKING GLASS - SAWING MACHINE: OTHER"
    },
    {
      "code": "84642000",
      "description": "MACHINE-TOOLS FOR WORKING STONE, CERAMICS, CONCRETE, ASBESTOS-CEMENT OR LIKE MINERAL MATERIALS OR FOR COLD WORKING GLASS GRINDING OR POLISHING MACHINES"
    },
    {
      "code": "84649000",
      "description": "MACHINE-TOOLS FOR WORKING STONE, CERAMICS, CONCRETE, ASBESTOS-CEMENT OR LIKE MINERAL MATERIALS OR FOR COLD WORKING GLASS OTHER"
    },
    {
      "code": "8465",
      "description": "MACHINE-TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS"
    },
    {
      "code": "84651000",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - MACHINES WHICH CAN CARRY OUT DIFFERENT TYPES OF MACHINING OPERATIONS WITHOUT TOOL CHANGE BETWEEN SUCH OPERATIONS"
    },
    {
      "code": "84652000",
      "description": "Machining centres"
    },
    {
      "code": "84659100",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : SAWING MACHINES"
    },
    {
      "code": "84659200",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : PLANING, MILLING OR MOULDING (BY CUTTING) MACHINES"
    },
    {
      "code": "84659300",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : GRINDING, SANDING OR POLISHING MACHINES"
    },
    {
      "code": "84659400",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : BENDING OR ASSEMBLING MACHINES"
    },
    {
      "code": "84659500",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : DRILLING OR MORTICING MACHINES"
    },
    {
      "code": "84659600",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : SPLITTING, SLICING OR PARING MACHINES"
    },
    {
      "code": "846599",
      "description": "Other"
    },
    {
      "code": "84659910",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : OTHER: LATHES"
    },
    {
      "code": "84659990",
      "description": "MACHINE TOOLS (INCLUDING MACHINES FOR NAILING, STAPLING, GLUEING OR OTHERWISE ASSEMBLING) FOR WORKING WOOD, CORK, BONE, HARD RUBBER, HARD PLASTICS OR SIMILAR HARD MATERIALS - OTHER : OTHER: OTHER"
    },
    {
      "code": "8466",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 T O 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINETOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND"
    },
    {
      "code": "846610",
      "description": "Tool holders and self-opening dieheads"
    },
    {
      "code": "84661010",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - TOOL HOLDERS AND SELF-OPENING DIEHEADS: TOOL HOLDERS"
    },
    {
      "code": "84661020",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - TOOL HOLDERS AND SELF-OPENING DIEHEADS: SELF-OPENING DIEHEADS"
    },
    {
      "code": "84662000",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND WORK HOLDERS"
    },
    {
      "code": "846630",
      "description": "Dividing heads and other special attachments for machines"
    },
    {
      "code": "84663010",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS: CHUCKS"
    },
    {
      "code": "84663020",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS: JIGS AND FIXTURES"
    },
    {
      "code": "84663090",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS: OTHER"
    },
    {
      "code": "84669100",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - OTHER:FOR MACHINES OF HEADING 8464"
    },
    {
      "code": "84669200",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - OTHER:FOR MACHINES OF HEADING 8465"
    },
    {
      "code": "846693",
      "description": "For machines of headings 8456 to 8461"
    },
    {
      "code": "84669310",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - OTHER - FOR MACHINES OF HEADINGS 8456 TO 8461: PARTS AND ACCESSORIES OF MACHINE TOOLS, FOR WORKING METALS"
    },
    {
      "code": "84669390",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - OTHER - FOR MACHINES OF HEADINGS 8456 TO 8461:OTHER"
    },
    {
      "code": "84669400",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADINGS 8456 TO 8465, INCLUDING WORK OR TOOL HOLDERS, SELF-OPENING DIEHEADS, DIVIDING HEADS AND OTHER SPECIAL ATTACHMENTS FOR MACHINE-TOOLS; TOOL HOLDERS FOR ANY TYPE OF TOOL, FOR WORKING IN THE HAND - OTHER: FOR MACHINES OF HEADING 8462 OR 8463"
    },
    {
      "code": "8467",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF- CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - Pneumatic"
    },
    {
      "code": "846711",
      "description": "Rotary type (including combined rotary percussion)"
    },
    {
      "code": "84671110",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - ROTARY TYPE (INCLUDING COMBINED ROTARY PERCUSSION): DRILLS"
    },
    {
      "code": "84671120",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - ROTARY TYPE (INCLUDING COMBINED ROTARY PERCUSSION): HAMMERS"
    },
    {
      "code": "84671190",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - ROTARY TYPE (INCLUDING COMBINED ROTARY PERCUSSION): OTHER"
    },
    {
      "code": "84671900",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - OTHER"
    },
    {
      "code": "84672100",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - WITH SELF-CONTAINED ELECTRIC MOTOR: DRILLS OF ALL KINDS"
    },
    {
      "code": "84672200",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - WITH SELF-CONTAINED ELECTRIC MOTOR: SAWS"
    },
    {
      "code": "84672900",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PNEUMATIC - WITH SELF-CONTAINED ELECTRIC MOTOR: OTHER"
    },
    {
      "code": "84678100",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - OTHER TOOLS: CHAIN SAWS"
    },
    {
      "code": "846789",
      "description": "Other"
    },
    {
      "code": "84678910",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - OTHER TOOLS: OTHER: COMPRESSED AIR GREASE GUNS, LUBRICATORS AND SIMILAR APPLIANCES"
    },
    {
      "code": "84678920",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - OTHER TOOLS: OTHER: VIBRATORS"
    },
    {
      "code": "84678990",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - OTHER TOOLS: OTHER: OTHER"
    },
    {
      "code": "84679100",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PARTS: OF CHAIN SAWS"
    },
    {
      "code": "84679200",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PARTS: OF PNEUMATIC TOOLS"
    },
    {
      "code": "84679900",
      "description": "TOOLS FOR WORKING IN THE HAND, PNEUMATIC, HYDRAULIC OR WITH SELF-CONTAINED ELECTRIC OR NON-ELECTRIC MOTOR - PARTS: OTHER"
    },
    {
      "code": "8468",
      "description": "MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8512.5; GAS- OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES"
    },
    {
      "code": "84681000",
      "description": "8468 MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8515; GAS-OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES HAND-HELD BLOW PIPES"
    },
    {
      "code": "846820",
      "description": "Other gas-operated machinery and apparatus"
    },
    {
      "code": "84682010",
      "description": "MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8515; GAS-OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES - OTHER GAS-OPERATED MACHINERY AND APPARATUS: WELDING OR CUTTING MACHINES"
    },
    {
      "code": "84682090",
      "description": "MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8515; GAS-OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES - OTHER GAS-OPERATED MACHINERY AND APPARATUS: OTHER"
    },
    {
      "code": "84688000",
      "description": "MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8515; GAS-OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES OTHER MACHINERY AND APPARATUS"
    },
    {
      "code": "84689000",
      "description": "MACHINERY AND APPARATUS FOR SOLDERING, BRAZING OR WELDING, WHETHER OR NOT CAPABLE OF CUTTING, OTHER THAN THOSE OF HEADING 8515; GAS-OPERATED SURFACE TEMPERING MACHINES AND APPLIANCES PARTS"
    },
    {
      "code": "8469",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8443; WORD-PROCESSING MACHINES"
    },
    {
      "code": "846900",
      "description": "Tyewriters other than printers of heading 8443 word-processing machines:"
    },
    {
      "code": "84690010",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8443; WORD-PROCESSING MACHINES -- TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8443; WORD-PROCESSING MACHINES ---WORD-PROCESSING MACHINES"
    },
    {
      "code": "84690020",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES --TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES ---AUTOMATIC TYPEWRITERS"
    },
    {
      "code": "84690030",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES --TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES ---BRAIELLE TYPEWRITERS ELECTRIC"
    },
    {
      "code": "84690040",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES --TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES ---BRAIELLE TYPEWRITERS NON-ELECTRIC"
    },
    {
      "code": "84690090",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES --TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES ---OTHER TYPEWRITERS, ELECTRIC OR NON-ELECTRIC"
    },
    {
      "code": "84691100",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - AUTOMATIC TYPEWRITERS AND WORD-PROCESSING MACHINES: WORD-PROCESSING MACHINES"
    },
    {
      "code": "84691200",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - AUTOMATIC TYPEWRITERS AND WORD-PROCESSING MACHINES: AUTOMATIC TYPEWRITERS"
    },
    {
      "code": "84692010",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - OTHER TYPEWRITERS ,ELECTRIC : BRAILLE TYPEWRITERS"
    },
    {
      "code": "84692090",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - OTHER TYPEWRITERS ,ELECTRIC : OTHER"
    },
    {
      "code": "84693010",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - OTHER TYPEWRITERS, NON-ELECTRIC: BRAILLE TYPEWRITERS"
    },
    {
      "code": "84693090",
      "description": "TYPEWRITERS OTHER THAN PRINTERS OF HEADING 8471; WORD-PROCESSING MACHINES - OTHER TYPEWRITERS, NON-ELECTRIC: OTHER"
    },
    {
      "code": "8470",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS ; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS"
    },
    {
      "code": "84701000",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS ELECTRONIC CALCULATORS CAPABLE OF OPERATIONWITHOUT AN EXTERNAL SOURCE OF ELECTRIC POWER AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS"
    },
    {
      "code": "84702100",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - OTHER ELECTRONIC CALCULATING MACHINES: INCORPORATING A PRINTING DEVICE"
    },
    {
      "code": "84702900",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - OTHER ELECTRONIC CALCULATING MACHINES: OTHER"
    },
    {
      "code": "84703000",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS OTHER CALCULATING MACHINES"
    },
    {
      "code": "84704010",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - ACCOUNTING MACHINES : ELECTRICALLY OPERATED"
    },
    {
      "code": "84704020",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - ACCOUNTING MACHINES : MANUALLY OPERATED"
    },
    {
      "code": "847050",
      "description": "Cash registers"
    },
    {
      "code": "84705010",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - CASH REGISTERS: ELECTRICALLY OPERATED"
    },
    {
      "code": "84705020",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - CASH REGISTERS: MANUALLY OPERATED"
    },
    {
      "code": "847090",
      "description": "Other"
    },
    {
      "code": "84709010",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - OTHER: ELECTRICALLY OPERATED"
    },
    {
      "code": "84709020",
      "description": "CALCULATING MACHINES AND POCKET-SIZE DATA RECORDING, REPRODUCING AND DISPLAYING MACHINES WITH CALCULATING FUNCTIONS; ACCOUNTING MACHINES, POSTAGE-FRANKING MACHINES, TICKET-ISSUING MACHINES AND SIMILAR MACHINES, INCORPORATING A CALCULATING DEVICE; CASH REGISTERS - OTHER: MANUALLY OPERATED"
    },
    {
      "code": "8471",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "84711000",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED ANALOGUE OR HYBRID AUTOMATIC DATA PROCESSING MACHINES"
    },
    {
      "code": "847130",
      "description": "Portable automatic data processing machines, weighing not more than 10 kg, consisting of at least a central processing unit, a keyboard and a display"
    },
    {
      "code": "84713010",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - PORTABLE AUTOMATIC DATA PROCESSING MACHINES, WEIGHING NOT MORE THAN 10 KG, CONSISTING OF AT LEAST A CENTRAL PROCESSING UNIT, A KEYBOARD AND A DISPLAY:PERSONAL COMPUTER"
    },
    {
      "code": "84713090",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - PORTABLE AUTOMATIC DATA PROCESSING MACHINES, WEIGHING NOT MORE THAN 10 KG, CONSISTING OF AT LEAST A CENTRAL PROCESSING UNIT, A KEYBOARD AND A DISPLAY:OTHER"
    },
    {
      "code": "847141",
      "description": "Comprising in the same housing at least a central processing unit and an input and output unit, whether or not combined"
    },
    {
      "code": "84714110",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER DIGITAL AUTOMATIC DATA PROCESSING MACHINES - COMPRISING IN THE SAME HOUSING AT LEAST A CENTRAL PROCESSING UNIT AND AN INPUT AND OUTPUT UNIT, WHETHER OR NOT COMBINED :MICRO COMPUTER"
    },
    {
      "code": "84714120",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER DIGITAL AUTOMATIC DATA PROCESSING MACHINES - COMPRISING IN THE SAME HOUSING AT LEAST A CENTRAL PROCESSING UNIT AND AN INPUT AND OUTPUT UNIT, WHETHER OR NOT COMBINED :LARGE OR MAIN FRAME COMPUTER"
    },
    {
      "code": "84714190",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER DIGITAL AUTOMATIC DATA PROCESSING MACHINES - COMPRISING IN THE SAME HOUSING AT LEAST A CENTRAL PROCESSING UNIT AND AN INPUT AND OUTPUT UNIT, WHETHER OR NOT COMBINED :OTHER"
    },
    {
      "code": "84714900",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER DIGITAL AUTOMATIC DATA PROCESSING MACHINES - OTHER, PRESENTED IN THE FORM OF SYSTEMS"
    },
    {
      "code": "84715000",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - PROCESSING UNITS OTHER THAN THOSE OFSUB-HEADINGS 8471 41 OR 8471 49, WHETHER OR NOT CONTAINING IN THE SAME HOUSING ONE OR TWO OF THE FOLLOWING TYPES OF UNIT: STORAGE UNITS, INPUT UNITS, OUTPUT UNITS"
    },
    {
      "code": "847160",
      "description": "Input or output units, whether or not containing storage units in the same housing"
    },
    {
      "code": "84716010",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: COMBINED INPUT OR OUT PUT UNITS"
    },
    {
      "code": "84716021",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: LINE PRINTER"
    },
    {
      "code": "84716022",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: DOT MATRIX PRINTER"
    },
    {
      "code": "84716023",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: LETTER QUALITY DAISY WHEEL PRINTER"
    },
    {
      "code": "84716024",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: GRAPHIC PRINTER"
    },
    {
      "code": "84716025",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: PLOTTER"
    },
    {
      "code": "84716026",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: LASER JET PRINTER"
    },
    {
      "code": "84716027",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: INK JET PRINTER"
    },
    {
      "code": "84716029",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: PRINTER: OTHER"
    },
    {
      "code": "84716030",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: MONITOR"
    },
    {
      "code": "84716040",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: KEYBOARD"
    },
    {
      "code": "84716050",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: SCANNERS"
    },
    {
      "code": "84716060",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: MOUSE"
    },
    {
      "code": "84716090",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - INPUT OR OUTPUT UNITS, WHETHER OR NOT CONTAINING STORAGE UNITS IN THE SAME HOUSING: OTHER"
    },
    {
      "code": "847170",
      "description": "Storage units"
    },
    {
      "code": "84717010",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: FLOPPY DISC DRIVES"
    },
    {
      "code": "84717020",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: HARD DISC DRIVES"
    },
    {
      "code": "84717030",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: REMOVABLE OR EXCHANGEABLE DISC DRIVES"
    },
    {
      "code": "84717040",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: MAGNETIC TAPE DRIVES"
    },
    {
      "code": "84717050",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: CARTRIDGE TAPE DRIVE"
    },
    {
      "code": "84717060",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: CD-ROM DRIVE"
    },
    {
      "code": "84717070",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: DIGITAL VIDEO DISC DRIVE"
    },
    {
      "code": "84717090",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - STORAGE UNITS: OTHER"
    },
    {
      "code": "84718000",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER UNITS OF AUTOMATIC DATA PROCESSING MACHINES"
    },
    {
      "code": "84719000",
      "description": "AUTOMATIC DATA PROCESSING MACHINES AND UNITS THEREOF; MAGNETIC OR OPTICAL READERS, MACHINES FOR TRANSCRIBING DATA ON TO DATA MEDIA IN CODED FORM AND MACHINES FOR PROCESSING SUCH DATA, NOT ELSEWHERE SPECIFIED OR INCLUDED - OTHER"
    },
    {
      "code": "8472",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCILSHARPENING MACHINES, PERFORATING OR STAPLING MACHINES)"
    },
    {
      "code": "84721000",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) DUPLICATING MACHINES"
    },
    {
      "code": "84722000",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) ADDRESSING MACHINES AND ADDRESS PLATE EMBOSSING MACHINES"
    },
    {
      "code": "84723000",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) MACHINES FOR SORTING OR FOLDING MAIL OR FOR INSERTING MAIL IN ENVELOPES OR BANDS, MACHINES FOR OPENING, CLOSING OR SEALING MAIL AND MACHINES FOR AFFIXING OR CANCELING POSTAGE STAMPS"
    },
    {
      "code": "847290",
      "description": "Other"
    },
    {
      "code": "84729010",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) - OTHER: STAPLING MACHINES (STAPLERS)"
    },
    {
      "code": "84729020",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) - OTHER: DIGITAL DUPLICATOR"
    },
    {
      "code": "84729030",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) - OTHER: AUTOMATIC BANK NOTE DISPENSERS"
    },
    {
      "code": "84729040",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) - OTHER: COIN SORTING MACHINES, COIN-COUNTING OR WRAPPING MACHINES"
    },
    {
      "code": "84729090",
      "description": "OTHER OFFICE MACHINES (FOR EXAMPLE, HECTOGRAPH OR STENCIL DUPLICATING MACHINES, ADDRESSING MACHINES, AUTOMATIC BANKNOTE DISPENSERS, COIN SORTING MACHINES, COIN COUNTING OR WRAPPING MACHINES, PENCIL-SHARPENING MACHINES, PERFORATING OR STAPLING MACHINES) - OTHER: OTHER"
    },
    {
      "code": "84729091",
      "description": "Word-processing machines"
    },
    {
      "code": "84729092",
      "description": "Automatic typewriters"
    },
    {
      "code": "84729093",
      "description": "Braille typewriters, electric"
    },
    {
      "code": "84729094",
      "description": "Braille typewriters, non-electric"
    },
    {
      "code": "84729095",
      "description": "Other typewriters, electric or non-electric"
    },
    {
      "code": "84729099",
      "description": "Other"
    },
    {
      "code": "8473",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472"
    },
    {
      "code": "84731000",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8469"
    },
    {
      "code": "84732100",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8470: OF THE ELECTRONIC CALCULATING MACHINES OF SUB-HEADING 8470 10, 8470 21 OR 8471 29"
    },
    {
      "code": "84732900",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8470: OTHER"
    },
    {
      "code": "847330",
      "description": "Parts and accessories of the machines of heading 8471"
    },
    {
      "code": "84733010",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: MICROPROCESSORS"
    },
    {
      "code": "84733020",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: MOTHERBOARDS"
    },
    {
      "code": "84733030",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: OTHER MOUNTED PRINTED CIRCUIT BOARDS"
    },
    {
      "code": "84733040",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: HEAD STACK"
    },
    {
      "code": "84733050",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: INK CARTRIDGES WITH PRINT HEAD ASSEMBLY"
    },
    {
      "code": "84733060",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471: INK SPRAY NOZZLE"
    },
    {
      "code": "84733091",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471:- OTHER : NETWORK ACCESS CONTROLLERS"
    },
    {
      "code": "84733092",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471:- OTHER :GRAPHIC AND INTELLIGENCE BASED SCRIPT TECHNOLOGY (GIST) CARDS FOR MULTILINGUAL COMPUTERS"
    },
    {
      "code": "84733099",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8471:- OTHER :OTHER"
    },
    {
      "code": "847340",
      "description": "Parts and accessories of the machines of heading 8472"
    },
    {
      "code": "84734010",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8472: PARTS OF DUPLICATING, HECTOGRAPH OR STENCIL MACHINES"
    },
    {
      "code": "84734090",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 - PARTS AND ACCESSORIES OF THE MACHINES OF HEADING 8472: OTHER"
    },
    {
      "code": "84735000",
      "description": "PARTS AND ACCESSORIES (OTHER THAN COVERS, CARRYING CASES AND THE LIKE) SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH MACHINES OF HEADINGS 8469 TO 8472 PARTS AND ACCESSORIES EQUALLY SUITABLE FOR USE WITH MACHINES OF TWO OR MORE OF THE HEADINGS 8469 TO 8472"
    },
    {
      "code": "8474",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND"
    },
    {
      "code": "847410",
      "description": "Sorting, screening, separating or washing machines"
    },
    {
      "code": "84741010",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - SORTING, SCREENING, SEPARATING OR WASHING MACHINES: FOR COAL"
    },
    {
      "code": "84741090",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - SORTING, SCREENING, SEPARATING OR WASHING MACHINES: OTHER"
    },
    {
      "code": "847420",
      "description": "Crushing or grinding machines"
    },
    {
      "code": "84742010",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - CRUSHING OR GRINDING MACHINES: FOR STONE AND MINERAL"
    },
    {
      "code": "84742020",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - CRUSHING OR GRINDING MACHINES: FOR COAL"
    },
    {
      "code": "84742090",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - CRUSHING OR GRINDING MACHINES: OTHER"
    },
    {
      "code": "847431",
      "description": "Concrete or mortar mixers"
    },
    {
      "code": "84743110",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - MIXING OR KNEADING MACHINES - CONCRETE OR MORTAR MIXERS : CONCRETE MIXERS"
    },
    {
      "code": "84743120",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - MIXING OR KNEADING MACHINES - CONCRETE OR MORTAR MIXERS : MORTAR MIXERS"
    },
    {
      "code": "84743200",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - MIXING OR KNEADING MACHINES - MACHINES FOR MIXING MINERAL SUBSTANCES WITH BITUMEN"
    },
    {
      "code": "84743900",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - MIXING OR KNEADING MACHINES - OTHER"
    },
    {
      "code": "847480",
      "description": "Other machinery"
    },
    {
      "code": "84748010",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - OTHER MACHINERY: BRICK AND TILE MAKING MACHINERY"
    },
    {
      "code": "84748020",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - OTHER MACHINERY: CERAMIC AND CLAY MAKING MACHINERY"
    },
    {
      "code": "84748030",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - OTHER MACHINERY: MACHINERY FOR FORMING FOUNDRY MOULDS OF SAND"
    },
    {
      "code": "84748090",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - OTHER MACHINERY: OTHER"
    },
    {
      "code": "84749000",
      "description": "MACHINERY FOR SORTING, SCREENING, SEPARATING, WASHING, CRUSHING, GRINDING, MIXING OR KNEADING EARTH, STONE, ORES OR OTHER MINERAL SUBSTANCES, IN SOLID (INCLUDING POWDER OR PASTE) FORM; MACHINERY FOR AGGLOMERATING, SHAPING OR MOULDING SOLID MINERAL FUELS, CERAMIC PASTE, UNHARDENED CEMENTS, PLASTERING MATERIALS OR OTHER MINERAL PRODUCTS IN POWDER OR PASTE FORM; MACHINES FOR FORMING FOUNDRY MOULDS OF SAND - PARTS"
    },
    {
      "code": "8475",
      "description": "MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASHBULBS, IN GLASS ENVELOPES; MACHINES OR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE"
    },
    {
      "code": "84751000",
      "description": "MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASH-BULBS, IN GLASS ENVELOPES; MACHINES OR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASH-BULBS, IN GLASS ENVELOPES"
    },
    {
      "code": "84752100",
      "description": "MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASH-BULBS, IN GLASS ENVELOPES; MACHINES OR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE - MACHINES FOR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE: MACHINES FOR MAKING OPTICAL FIBRES AND PREFORMS THEREOF"
    },
    {
      "code": "84752900",
      "description": "MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASH-BULBS, IN GLASS ENVELOPES; MACHINES OR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE - MACHINES FOR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE: OTHER"
    },
    {
      "code": "84759000",
      "description": "MACHINES FOR ASSEMBLING ELECTRIC OR ELECTRONIC LAMPS, TUBES OR VALVES OR FLASH-BULBS, IN GLASS ENVELOPES; MACHINES OR MANUFACTURING OR HOT WORKING GLASS OR GLASSWARE - PARTS"
    },
    {
      "code": "8476",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - Automatic beverage-vending machines"
    },
    {
      "code": "847621",
      "description": "Incorporating heating or refrigerating devices"
    },
    {
      "code": "84762110",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - AUTOMATIC BEVERAGE-VENDING MACHINE - INCORPORATING HEATING OR REFRIGERATING DEVICES: INCORPORATING REFRIGERATING DEVICES"
    },
    {
      "code": "84762120",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - AUTOMATIC BEVERAGE-VENDING MACHINE - INCORPORATING HEATING OR REFRIGERATING DEVICES: NCORPORATING HEATING DEVICES"
    },
    {
      "code": "84762900",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - AUTOMATIC BEVERAGE-VENDING MACHINE - OTHER"
    },
    {
      "code": "847681",
      "description": "Incorporating heating or refrigerating devices"
    },
    {
      "code": "84768110",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - INCORPORATING HEATING OR REFRIGERATING DEVICES: INCORPORATING REFRIGERATING DEVICES"
    },
    {
      "code": "84768120",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - INCORPORATING HEATING OR REFRIGERATING DEVICES: INCORPORATING HEATING DEVICES"
    },
    {
      "code": "847689",
      "description": "Other"
    },
    {
      "code": "84768910",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - OTHER: MONEY CHANGING MACHINES"
    },
    {
      "code": "84768920",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - OTHER: POSTAGE STAMPS VENDING MACHINES"
    },
    {
      "code": "84768930",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - OTHER: CIGARETTE VENDING MACHINES"
    },
    {
      "code": "84768990",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - OTHER MACHINES - OTHER: OTHER"
    },
    {
      "code": "847690",
      "description": "Parts"
    },
    {
      "code": "84769010",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - PARTS:OF MACHINES OF SUB-HEADING 8476 21"
    },
    {
      "code": "84769090",
      "description": "AUTOMATIC GOODS-VENDING MACHINES (FOR EXAMPLE, POSTAGE STAMPS, CIGARETTE, FOOD OR BEVERAGE MACHINES), INCLUDING MONEY CHANGING MACHINES - PARTS:OTHER"
    },
    {
      "code": "8477",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "84771000",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER INJECTION-MOULDING MACHINES"
    },
    {
      "code": "84772000",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER EXTRUDERS"
    },
    {
      "code": "84773000",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER BLOW MOULDING MACHINES"
    },
    {
      "code": "84774000",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER VACUUM MOULDING MACHINES AND OTHER THERMOFORMING MACHINES"
    },
    {
      "code": "84775100",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINERY FOR MOULDING OR OTHERWISE FORMING: FOR MOULDING OR RETREADING PNEUMATIC TYRES OR FOR MOULDING OR OTHERWISE FORMING INNER TUBES"
    },
    {
      "code": "84775900",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINERY FOR MOULDING OR OTHERWISE FORMING: OTHER"
    },
    {
      "code": "847780",
      "description": "Other machinery"
    },
    {
      "code": "84778010",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINERY: MACHINERY FOR MAKING RUBBER GOODS"
    },
    {
      "code": "84778090",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINERY: OTHER"
    },
    {
      "code": "84779000",
      "description": "MACHINERY FOR WORKING RUBBER OR PLASTICS OR FOR THE MANUFACTURE OF PRODUCTS FROM THESE MATERIALS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER PARTS"
    },
    {
      "code": "8478",
      "description": "MACHINERY FOR PREPARING OR MAKING UP TOBACCO , NOT SPECIFIED O R INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "847810",
      "description": "Machinery"
    },
    {
      "code": "84781010",
      "description": "MACHINERY FOR PREPARING OR MAKING UP TOBACCO, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MACHINERY: CIGAR MAKING MACHINERY"
    },
    {
      "code": "84781020",
      "description": "MACHINERY FOR PREPARING OR MAKING UP TOBACCO, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MACHINERY: CIGARETTE MAKING MACHINERY"
    },
    {
      "code": "84781090",
      "description": "MACHINERY FOR PREPARING OR MAKING UP TOBACCO, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MACHINERY: OTHER"
    },
    {
      "code": "84789000",
      "description": "MACHINERY FOR PREPARING OR MAKING UP TOBACCO, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS"
    },
    {
      "code": "8479",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "84791000",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER MACHINERY FOR PUBLIC WORKS, BUILDING OR THE LIKE"
    },
    {
      "code": "847920",
      "description": "Machinery for the extraction or preparation of animal or fixed vegetable fats or oils"
    },
    {
      "code": "84792010",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS: OIL-SEED CRUSHING OR GRINDING MACHINERY INCLUDING PURIFYING TANKS"
    },
    {
      "code": "84792090",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MACHINERY FOR THE EXTRACTION OR PREPARATION OF ANIMAL OR FIXED VEGETABLE FATS OR OILS: OTHER"
    },
    {
      "code": "84793000",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER PRESSES FOR THE MANUFACTURE OF PARTICLE BOARD OR FIBRE BUILDING BOARD OF WOOD OR OTHER LIGNEOUS MATERIALS AND OTHER MACHINERY FOR TREATING WOOD OR CORK"
    },
    {
      "code": "84794000",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER ROPE OR CABLE-MAKING MACHINES"
    },
    {
      "code": "84795000",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER INDUSTRIAL ROBOTS, NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "84796000",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER EVAPORATIVE AIR COOLERS"
    },
    {
      "code": "847971",
      "description": "Of a kind use in airports"
    },
    {
      "code": "84797100",
      "description": "OF A KIND USED IN AIRPORTS"
    },
    {
      "code": "847979",
      "description": "other"
    },
    {
      "code": "84797900",
      "description": "OTHER"
    },
    {
      "code": "84798100",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: FOR TREATING METAL, INCLUDING ELECTRIC WIRE COIL-WINDERS"
    },
    {
      "code": "84798200",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: MIXING, KNEADING, CRUSHING, GRINDING, SCREENING, SIFTING, HOMOGENISING, EMULSIFYING OR STIRRING MACHINES"
    },
    {
      "code": "847989",
      "description": "Other"
    },
    {
      "code": "84798910",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: SOAP CUTTING OR MOULDING MACHINERY"
    },
    {
      "code": "84798920",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: AIR HUMIDIFIERS OR DEHUMIDIFIERS (OTHER THAN THOSE FALLING UNDER HEADING 8415 OR 8424)"
    },
    {
      "code": "84798930",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: MECHANICAL SHIFTING MACHINES"
    },
    {
      "code": "84798940",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: ULTRASONIC TRANSDUCERS"
    },
    {
      "code": "84798950",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: CAR WASHING MACHINES AND RELATED APPLIANCES"
    },
    {
      "code": "84798960",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: COKE OVEN PLANTS"
    },
    {
      "code": "84798970",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: MACHINERY FOR THE MANUFACTURE OF CHEMICAL AND PHARMACEUTICALS GOODS"
    },
    {
      "code": "84798991",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: OTHER : INDUSTRIAL VACUUM CLEANERS"
    },
    {
      "code": "84798992",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: OTHER : BRIQUETTING PLANT AND MACHINERY INTENDED FOR MANUFACTURE OF BRIQUETTES FROM AGRICULTURAL AND MUNICIPAL WASTE"
    },
    {
      "code": "84798999",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND MECHANICAL APPLIANCES: OTHER: OTHER : OTHER"
    },
    {
      "code": "847990",
      "description": "Parts"
    },
    {
      "code": "84799010",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS: OF MACHINES FOR PUBLIC WORKS, BUILDING OR THE LIKE"
    },
    {
      "code": "84799020",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS: OF MACHINES FOR THE EXTRACTION OF ANIMAL OR FRUIT AND VEGETABLE FATS OR OIL"
    },
    {
      "code": "84799030",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS: OF MACHINES AND MECHANICAL APPLIANCES FOR TREATING WOOD"
    },
    {
      "code": "84799040",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS: OF MACHINERY USED FOR MANUFACTURE OF CHEMICALS AND PHARMACEUTICALS"
    },
    {
      "code": "84799090",
      "description": "MACHINES AND MECHANICAL APPLIANCES HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS: OTHER"
    },
    {
      "code": "8480",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS"
    },
    {
      "code": "84801000",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS MOULDING BOXES FOR METAL FOUNDRY"
    },
    {
      "code": "84802000",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS MOULD BASES"
    },
    {
      "code": "84803000",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS MOULDING PATTERNS"
    },
    {
      "code": "84804100",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS - MOULDS FOR METAL OR METAL CARBIDES: INJECTION OR COMPRESSION TYPES"
    },
    {
      "code": "84804900",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS - MOULDS FOR METAL OR METAL CARBIDES: OTHER"
    },
    {
      "code": "84805000",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS MOULDS FOR GLASS"
    },
    {
      "code": "84806000",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS MOULDS FOR MINERAL MATERIALS"
    },
    {
      "code": "84807100",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS - MOULDS FOR RUBBER OR PLASTICS: INJECTION OR COMPRESSION TYPES"
    },
    {
      "code": "84807900",
      "description": "MOULDING BOXES FOR METAL FOUNDRY; MOULD BASES; MOULDING PATTERNS; MOULDS FOR METAL (OTHER THAN INGOT MOULDS), METAL CARBIDES, GLASS, MINERAL MATERIALS, RUBBER OR PLASTICS - MOULDS FOR RUBBER OR PLASTICS: OTHER"
    },
    {
      "code": "8481",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THE RMOSTATICALLY CONTROLLED VALVES"
    },
    {
      "code": "84811000",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES PRESSURE-REDUCING VALVES"
    },
    {
      "code": "84812000",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES VALVES FOR OLEOHYDRAULIC OR PNEUMATIC TRANSMISSIONS"
    },
    {
      "code": "84813000",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES CHECK (NON-RETURN) VALVES"
    },
    {
      "code": "84814000",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES SAFETY OR RELIEF VALVES"
    },
    {
      "code": "848180",
      "description": "Other appliances"
    },
    {
      "code": "84818010",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: TAPS, COCKS AND SIMILAR APPLIANCES OF IRON OR STEEL"
    },
    {
      "code": "84818020",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: TAPS, COCKS AND SIMILAR APPLIANCES OF NON-FERROUS METAL"
    },
    {
      "code": "84818030",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: INDUSTRIAL VALVES (EXCLUDING PRESSURE-REDUCING VALVES, AND THERMOSTATICALLY CONTROLLED VALVES)"
    },
    {
      "code": "84818041",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: INNER TUBE VALVES: FOR BICYCLES"
    },
    {
      "code": "84818049",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: INNER TUBE VALVES: OTHER"
    },
    {
      "code": "84818050",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: EXPANSION VALVES AND SOLENOID VALVES FOR REFRIGERATING AND AIR CONDITIONING APPLIANCES AND MACHINERY"
    },
    {
      "code": "84818090",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - OTHER APPLIANCES: OTHER"
    },
    {
      "code": "848190",
      "description": "Parts"
    },
    {
      "code": "84819010",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - PARTS : BICYCLES VALVES"
    },
    {
      "code": "84819090",
      "description": "TAPS, COCKS, VALVES AND SIMILAR APPLIANCES FOR PIPES, BOILER SHELLS, TANKS, VATS OR THE LIKE, INCLUDING PRESSURE-REDUCING VALVES AND THERMOSTATICALLY CONTROLLED VALVES - PARTS : OTHER"
    },
    {
      "code": "8482",
      "description": "BALL OR ROLLER BEARINGS "
    },
    {
      "code": "848210",
      "description": "Ball bearings"
    },
    {
      "code": "84821011",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS: ADAPTER BALL BEARINGS (RADIAL TYPE) :NOT EXCEEDING 50MM OF BORE DIAMETER"
    },
    {
      "code": "84821012",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS: ADAPTER BALL BEARINGS (RADIAL TYPE) :OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84821013",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS: ADAPTER BALL BEARINGS (RADIAL TYPE) :OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84821020",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS:OTHER BALL BEARING (RADIAL TYPE) OF BORE DIAMETER NOT EXCEEDING 50 MM"
    },
    {
      "code": "84821030",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS - OTHER BALL BEARING (RADIAL TYPE) OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84821040",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84821051",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS - THRUST BALL BEARINGS :OF BORE DIAMETER NOT EXCEEDING 50 MM"
    },
    {
      "code": "84821052",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS - THRUST BALL BEARINGS :OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84821053",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS - THRUST BALL BEARINGS :OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84821090",
      "description": "BALL OR ROLLER BEARINGS - BALL BEARINGS - THRUST BALL BEARINGS :OTHER"
    },
    {
      "code": "848220",
      "description": "Tapered roller bearings, including cone and tapered roller assemblies"
    },
    {
      "code": "84822011",
      "description": "BALL OR ROLLER BEARINGS - TAPERED ROLLER BEARINGS, INCLUDING CONE AND TAPERED ROLLER ASSEMBLIES: TAPERED ROLLER BEARINGS (RADIAL TYPE) : OF BORE DIAMETER NOT EXCEEDING 50 MM"
    },
    {
      "code": "84822012",
      "description": "BALL OR ROLLER BEARINGS - TAPERED ROLLER BEARINGS, INCLUDING CONE AND TAPERED ROLLER ASSEMBLIES: TAPERED ROLLER BEARINGS (RADIAL TYPE) : OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84822013",
      "description": "BALL OR ROLLER BEARINGS - TAPERED ROLLER BEARINGS, INCLUDING CONE AND TAPERED ROLLER ASSEMBLIES: TAPERED ROLLER BEARINGS (RADIAL TYPE) : OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84822090",
      "description": "BALL OR ROLLER BEARINGS - TAPERED ROLLER BEARINGS, INCLUDING CONE AND TAPERED ROLLER ASSEMBLIES: OTHER"
    },
    {
      "code": "84823000",
      "description": "BALL OR ROLLER BEARINGS SPHERICAL ROLLER BEARINGS"
    },
    {
      "code": "84824000",
      "description": "BALL OR ROLLER BEARINGS NEEDLE ROLLER BEARINGS"
    },
    {
      "code": "848250",
      "description": "Other cylindrical roller bearings"
    },
    {
      "code": "84825011",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS: RADIAL TYPE : OF BORE DIAMETER NOT EXCEEDING 50 MM"
    },
    {
      "code": "84825012",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS: RADIAL TYPE : OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84825013",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS: RADIAL TYPE : OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84825021",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS:- THRUST ROLLER BEARINGS: OF BORE DIAMETER NOT EXCEEDING 50 MM"
    },
    {
      "code": "84825022",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS:- THRUST ROLLER BEARINGS: OF BORE DIAMETER EXCEEDING 50 MM BUT NOT EXCEEDING 100 MM"
    },
    {
      "code": "84825023",
      "description": "BALL OR ROLLER BEARINGS - OTHER CYLINDRICAL ROLLER BEARINGS:- THRUST ROLLER BEARINGS: OF BORE DIAMETER EXCEEDING 100 MM"
    },
    {
      "code": "84828000",
      "description": "BALL OR ROLLER BEARINGS - OTHER, INCLUDING COMBINED BALL OR ROLLER BEARINGS"
    },
    {
      "code": "848291",
      "description": "Balls, needles and rollers"
    },
    {
      "code": "84829111",
      "description": "BALL OR ROLLER BEARINGS - PARTS: BALLS, NEEDLES AND ROLLERS: BALLS: OF NICKEL ALLOYS"
    },
    {
      "code": "84829112",
      "description": "BALL OR ROLLER BEARINGS - PARTS: BALLS, NEEDLES AND ROLLERS: BALLS: OF TUNGSTEN CARBIDE"
    },
    {
      "code": "84829113",
      "description": "BALL OR ROLLER BEARINGS - PARTS: BALLS, NEEDLES AND ROLLERS: BALLS: OF SPECIAL STAINLESS STEEL"
    },
    {
      "code": "84829114",
      "description": "BALL OR ROLLER BEARINGS - PARTS: BALLS, NEEDLES AND ROLLERS: BALLS: OF HIGH SPEED STEEL"
    },
    {
      "code": "84829119",
      "description": "BALL OR ROLLER BEARINGS - PARTS: BALLS, NEEDLES AND ROLLERS: BALLS: OTHER"
    },
    {
      "code": "84829120",
      "description": "BALL OR ROLLER BEARINGS - PARTS NEEDLES"
    },
    {
      "code": "84829130",
      "description": "BALL OR ROLLER BEARINGS - PARTS ROLLERS"
    },
    {
      "code": "84829900",
      "description": "BALL OR ROLLER BEARINGS - PARTS OTHER"
    },
    {
      "code": "8483",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS)"
    },
    {
      "code": "848310",
      "description": "Transmission shafts (including cam shafts and crank shafts) and cranks"
    },
    {
      "code": "84831010",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS : CRANK SHAFTS FOR SEWING MACHINES"
    },
    {
      "code": "84831091",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - OTHER : CRANK SHAFT FOR ENGINES OF HEADING 8407"
    },
    {
      "code": "84831092",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - OTHER : CRANK SHAFT FOR ENGINES OF HEADING 8408"
    },
    {
      "code": "84831099",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - OTHER : OTHER"
    },
    {
      "code": "84832000",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) -BEARING HOUSINGS, INCORPORATING BALL OR ROLLER BEARINGS"
    },
    {
      "code": "84833000",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) -BEARING HOUSINGS, NOT INCORPORATING BALL OR ROLLER BEARINGS; PLAIN SHAFT BEARINGS"
    },
    {
      "code": "84834000",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) -GEARS AND GEARING, OTHER THAN TOOTHED WHEELS, CHAIN SPROCKETS AND OTHER TRANSMISSION ELEMENTS PRESENTED SEPARATELY; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS"
    },
    {
      "code": "848350",
      "description": "Flywheels and pulleys, including pulley blocks"
    },
    {
      "code": "84835010",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS: PULLEYS, POWER TRANSMISSION"
    },
    {
      "code": "84835090",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS: OTHER"
    },
    {
      "code": "848360",
      "description": "Clutches and shaft couplings (including universal joints)"
    },
    {
      "code": "84836010",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) ? CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS): FLEXIBLE COUPLING"
    },
    {
      "code": "84836020",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) ? CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS): FLUID COUPLING"
    },
    {
      "code": "84836090",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) ? CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS): OTHER"
    },
    {
      "code": "84839000",
      "description": "TRANSMISSION SHAFTS (INCLUDING CAM SHAFTS AND CRANK SHAFTS) AND CRANKS; BEARING HOUSINGS AND PLAIN SHAFT BEARINGS; GEARS AND GEARING; BALL OR ROLLER SCREWS; GEAR BOXES AND OTHER SPEED CHANGERS, INCLUDING TORQUE CONVERTERS; FLYWHEELS AND PULLEYS, INCLUDING PULLEY BLOCKS; CLUTCHES AND SHAFT COUPLINGS (INCLUDING UNIVERSAL JOINTS) - TOOTHED WHEELS, CHAIN SPROCKETS AND OTHER TRANSMISSION ELEMENTS PARTS PRESENTED SEPARATELY"
    },
    {
      "code": "8484",
      "description": "GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL; SETS OR ASSORTMENTS OF GASKETS AND SIMILAR JOINTS, DISSIMILAR IN COMPOSITION, PUT UP IN POUCHES, ENVELOPES OR SIMILAR PACKINGS; MECHANICAL SEALS"
    },
    {
      "code": "848410",
      "description": "Gaskets and similar joints of metal sheeting combined with other material or of two or more layers of metal"
    },
    {
      "code": "84841010",
      "description": "GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL; SETS OR ASSORTMENTS OF GASKETS AND SIMILAR JOINTS, DISSIMILAR IN COMPOSITION, PUT UP IN POUCHES, ENVELOPES OR SIMILAR PACKINGS; MECHANICAL SEALS - GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL: ASBESTOS METALLIC PACKINGS AND GASKETS (EXCLUDING GASKETS OF ASBESTOS BOARD REINFORCED WITH METAL GAUZE OR WIRE)"
    },
    {
      "code": "84841090",
      "description": "GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL; SETS OR ASSORTMENTS OF GASKETS AND SIMILAR JOINTS, DISSIMILAR IN COMPOSITION, PUT UP IN POUCHES, ENVELOPES OR SIMILAR PACKINGS; MECHANICAL SEALS - GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL: OTHER"
    },
    {
      "code": "84842000",
      "description": "GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL; SETS OR ASSORTMENTS OF GASKETS AND SIMILAR JOINTS, DISSIMILAR IN COMPOSITION, PUT UP IN POUCHES, ENVELOPES OR SIMILAR PACKINGS; MECHANICAL SEALS - MECHANICAL SEALS"
    },
    {
      "code": "84849000",
      "description": "GASKETS AND SIMILAR JOINTS OF METAL SHEETING COMBINED WITH OTHER MATERIAL OR OF TWO OR MORE LAYERS OF METAL; SETS OR ASSORTMENTS OF GASKETS AND SIMILAR JOINTS, DISSIMILAR IN COMPOSITION, PUT UP IN POUCHES, ENVELOPES OR SIMILAR PACKINGS; MECHANICAL SEALS - OTHER"
    },
    {
      "code": "8485",
      "description": "MACHINERY PARTS, NOT CONTAINING ELECTRICAL CONNECTORS, INSULATORS, COILS, CONTACTS OR OTHER ELECTRICAL FEATURES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER SHIPR OR BOATR PROPELLERS AND BLADES THEREFOR"
    },
    {
      "code": "84851000",
      "description": "MACHINERY PARTS, NOT CONTAINING ELECTRICAL CONNECTORS, INSULATORS, COILS, CONTACTS OR OTHER ELECTRICAL FEATURES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER SHIPR OR BOATR PROPELLERS AND BLADES THEREFOR"
    },
    {
      "code": "84859000",
      "description": "MACHINERY PARTS, NOT CONTAINING ELECTRICAL CONNECTORS, INSULATORS, COILS, CONTACTS OR OTHER ELECTRICAL FEATURES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER"
    },
    {
      "code": "8486",
      "description": "MACHINES AND APPARATUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMICONDUCTOR BOULES OR WAFERS, SEMICONDUCTOR DEVICES, ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARATUS SPECIFIED IN NOTE 9(C)TO THIS CHAPTER; PARTS AND ACCESSORIES"
    },
    {
      "code": "84861000",
      "description": "MACHINES AND APPARUTUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMI CONDUCTOR BOULES OR WAFERS,SEMI CONDUCTOR DEVICES,ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER ; PARTS AND ACCESSORIES - MACHINES AND APPARUTUS FOR THE MANUFACTURE OF BOULES OR WAFERS"
    },
    {
      "code": "84862000",
      "description": "MACHINES AND APPARUTUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMI CONDUCTOR BOULES OR WAFERS,SEMI CONDUCTOR DEVICES,ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER ; PARTS AND ACCESSORIES - MACHINES AND APPARUTUS FOR THE MANUFACTURE OF SEMI CONDUCTOR DEVICES OR OF ELECTRONIC INTEGRATED CIRCUITS"
    },
    {
      "code": "84863000",
      "description": "MACHINES AND APPARUTUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMI CONDUCTOR BOULES OR WAFERS,SEMI CONDUCTOR DEVICES,ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER ; PARTS AND ACCESSORIES - MACHINES AND APPARUTUS FOR THE MANUFACTURE OF FLAT PANEL DISPLAYS"
    },
    {
      "code": "84864000",
      "description": "MACHINES AND APPARUTUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMI CONDUCTOR BOULES OR WAFERS,SEMI CONDUCTOR DEVICES,ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER ; PARTS AND ACCESSORIES -  MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER"
    },
    {
      "code": "84869000",
      "description": "MACHINES AND APPARUTUS OF A KIND USED SOLELY OR PRINCIPALLY FOR THE MANUFACTURE OF SEMI CONDUCTOR BOULES OR WAFERS,SEMI CONDUCTOR DEVICES,ELECTRONIC INTEGRATED CIRCUITS OR FLAT PANEL DISPLAYS; MACHINES AND APPARUTUS SPECIFIED IN NOTE 9(C) TO THIS CHAPTER ; PARTS AND ACCESSORIES - PARTS AND ACCESSORIES"
    },
    {
      "code": "8487",
      "description": "MACHINES PARTS, NOT CONTAINING ELECTRICAL CONNECTORS, INSULATORS, COILS, CONTACTS OR OTHER ELECTRICAL FEATURES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "84871000",
      "description": "MACHINERY PARTS, NOT CONTAINING ELECTRICAL CONNECTORS,INSULATORS,COILS, CONTACTS OR OTHER ELECTRICAL FEATURES NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; - SHIPS OR BOATS PROPELLERS"
    },
    {
      "code": "84879000",
      "description": "MACHINERY PARTS, NOT CONTAINING ELECTRICAL CONNECTORS,INSULATORS,COILS, CONTACTS OR OTHER ELECTRICAL FEATURES NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER;- OTHER"
    },
    {
      "code": "85",
      "description": "Electrical machinery and equipment and parts thereof; sound recorders and reproducers, television image and sound recorders and reproducers, and parts and accessories of such articles"
    },
    {
      "code": "8501",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS)"
    },
    {
      "code": "850110",
      "description": "Motors of an output not exceeding 37.5 W"
    },
    {
      "code": "85011011",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) MOTORS OF AN OUTPUT NOT EXCEEDING 37.5 W: - DC MOTOR: MICRO MOTOR"
    },
    {
      "code": "85011012",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) MOTORS OF AN OUTPUT NOT EXCEEDING 37.5 W: - DC MOTOR: STEPPER MOTOR"
    },
    {
      "code": "85011013",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) MOTORS OF AN OUTPUT NOT EXCEEDING 37.5 W: - DC MOTOR: WIPER MOTOR"
    },
    {
      "code": "85011019",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) MOTORS OF AN OUTPUT NOT EXCEEDING 37.5 W: - DC MOTOR: OTHER"
    },
    {
      "code": "85011020",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) MOTORS OF AN OUTPUT NOT EXCEEDING 37.5 W:AC MOTOR"
    },
    {
      "code": "85012000",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) UNIVERSAL AC OR DC MOTORS OF AN OUTPUT EXCEEDING 37.5 W"
    },
    {
      "code": "850131",
      "description": "Of an output not exceeding 750 W"
    },
    {
      "code": "85013111",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT NOT EXCEEDING 750 W: - DC MOTORS: MICRO MOTOR"
    },
    {
      "code": "85013112",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT NOT EXCEEDING 750 W: - DC MOTORS: STEPPER MOTOR"
    },
    {
      "code": "85013113",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT NOT EXCEEDING 750 W: - DC MOTORS: WIPER MOTOR"
    },
    {
      "code": "85013119",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT NOT EXCEEDING 750 W: - DC MOTORS: OTHER"
    },
    {
      "code": "85013120",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT NOT EXCEEDING 750 W: DC GENERATORS"
    },
    {
      "code": "850132",
      "description": "Of an output exceeding 750 W but not exceeding 75 kW"
    },
    {
      "code": "85013210",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT EXCEEDING 750 W BUT NOT EXCEEDING 75 KW: DC MOTOR"
    },
    {
      "code": "85013220",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS: - OF AN OUTPUT EXCEEDING 750 W BUT NOT EXCEEDING 75 KW: DC GENERATORS"
    },
    {
      "code": "850133",
      "description": "Of an output exceeding 75 kW but not exceeding 375 kW"
    },
    {
      "code": "85013310",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 75 KW BUT NOT EXCEEDING 375 KW: DC MOTORS"
    },
    {
      "code": "85013320",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 75 KW BUT NOT EXCEEDING 375 KW: DC GENERATORS"
    },
    {
      "code": "850134",
      "description": "Of an output exceeding 375 kW"
    },
    {
      "code": "85013410",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 375 KW:OF AN OUTPUT EXCEEDING 375 KW BUT NOT EXCEEDING 1,000 KW"
    },
    {
      "code": "85013420",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 375 KW:OF AN OUTPUT EXCEEDING 1,000 KW BUT NOT EXCEEDING 2,000 KW"
    },
    {
      "code": "85013430",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 375 KW:OF AN OUTPUT EXCEEDING 2,000 KW BUT NOT EXCEEDING 5,000 KW"
    },
    {
      "code": "85013440",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 375 KW:OF AN OUTPUT EXCEEDING 5,000 KW BUT NOT EXCEEDING 10,000 KW"
    },
    {
      "code": "85013450",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER DC MOTORS; DC GENERATORS:- OF AN OUTPUT EXCEEDING 375 KW: OF AN OUTPUT EXCEEDING 10,000 KW"
    },
    {
      "code": "850140",
      "description": "Other AC motors, single-phase"
    },
    {
      "code": "85014010",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:FRACTIONAL HORSE POWER MOTOR"
    },
    {
      "code": "85014090",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:THER"
    },
    {
      "code": "850151",
      "description": "Of an output not exceeding 750 W"
    },
    {
      "code": "85015110",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE: - OF AN OUTPUT NOT EXCEEDING 750 W: SQUIRREL CAGE INDUCTION MOTOR, 3 PHASE TYPE"
    },
    {
      "code": "85015120",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE: - OF AN OUTPUT NOT EXCEEDING 750 W: SLIPRING MOTOR"
    },
    {
      "code": "85015190",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE: - OF AN OUTPUT NOT EXCEEDING 750 W: OTHER"
    },
    {
      "code": "850152",
      "description": "Of an output exceeding 750 W but not exceeding 75 kW"
    },
    {
      "code": "85015210",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:- OF AN OUTPUT EXCEEDING 750 W BUT NOT EXCEEDING 75 KW: SQUIRREL CAGE INDUCTION MOTOR, 3 PHASE TYPE"
    },
    {
      "code": "85015220",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:- OF AN OUTPUT EXCEEDING 750 W BUT NOT EXCEEDING 75 KW: SLIPRING MOTOR"
    },
    {
      "code": "85015290",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:- OF AN OUTPUT EXCEEDING 750 W BUT NOT EXCEEDING 75 KW: OTHER"
    },
    {
      "code": "850153",
      "description": "Of an output exceeding 75 kW"
    },
    {
      "code": "85015310",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:-- OF AN OUTPUT EXCEEDING 75 KW: SQUIRREL CAGE INDUCTION MOTOR, 3 PHASE TYPE"
    },
    {
      "code": "85015320",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:-- OF AN OUTPUT EXCEEDING 75 KW: SLIPRING MOTOR"
    },
    {
      "code": "85015330",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:-- OF AN OUTPUT EXCEEDING 75 KW: TRACTION MOTOR"
    },
    {
      "code": "85015390",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - OTHER AC MOTORS, SINGLE-PHASE:- OTHER AC MOTORS, MULTI-PHASE:-- OF AN OUTPUT EXCEEDING 75 KW: OTHER"
    },
    {
      "code": "85016100",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): OF AN OUTPUT NOT EXCEEDING 75 KVA"
    },
    {
      "code": "85016200",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): OF AN OUTPUT EXCEEDING 75 KVA BUT NOT EXCEEDING 375 KVA"
    },
    {
      "code": "85016300",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): OF AN OUTPUT EXCEEDING 375 KVA BUT NOT EXCEEDING 750 KVA"
    },
    {
      "code": "850164",
      "description": "Of an output exceeding 750 kVA"
    },
    {
      "code": "85016410",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 750 KVA BUT NOT EXCEEDING 2,000 KVA"
    },
    {
      "code": "85016420",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 2,000 KVA BUT NOT EXCEEDING 5,000 KVA"
    },
    {
      "code": "85016430",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 5,000 KVA BUT NOT EXCEEDING 15,000 KVA"
    },
    {
      "code": "85016440",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 15,000 KVA BUT NOT EXCEEDING 37,500 KVA"
    },
    {
      "code": "85016450",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 37,500 KVA BUT NOT EXCEEDING 75,000 KVA"
    },
    {
      "code": "85016460",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 75,000 KVA BUT NOT EXCEEDING 1,37,500 KVA"
    },
    {
      "code": "85016470",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 1,37,500 KVA BUT NOT EXCEEDING 3,12,500 KVA"
    },
    {
      "code": "85016480",
      "description": "ELECTRIC MOTORS AND GENERATORS (EXCLUDING GENERATING SETS) - AC GENERATORS (ALTERNATORS): - OF AN OUTPUT EXCEEDING 750 KVA: OF AN OUTPUT EXCEEDING 3,12,500 KVA"
    },
    {
      "code": "8502",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - Generating sets with compression-ignition internal combustion piston engines (diesel or semi-diesel engines)"
    },
    {
      "code": "85021100",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): OF AN OUTPUT NOT EXCEEDING 75 KVA"
    },
    {
      "code": "85021200",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): OF AN OUTPUT EXCEEDING 75 KVA BUT NOT EXCEEDING 375 KVA"
    },
    {
      "code": "850213",
      "description": "Of an output exceeding 375 kVA"
    },
    {
      "code": "85021310",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 375 KVA BUT NOT EXCEEDING 1,000 KVA"
    },
    {
      "code": "85021320",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 1,000 KVA BUT NOT EXCEEDING 1,500 KVA"
    },
    {
      "code": "85021330",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 1,500 KVA BUT NOT EXCEEDING 2,000 KVA"
    },
    {
      "code": "85021340",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 2,000 KVA BUT NOT EXCEEDING 5,000 KVA"
    },
    {
      "code": "85021350",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 5,000 KVA BUT NOT EXCEEDING 10,000 KVA"
    },
    {
      "code": "85021360",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - GENERATING SETS WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINES (DIESEL OR SEMI-DIESEL ENGINES): - OF AN OUTPUT EXCEEDING 375 KVA : OF AN OUTPUT EXCEEDING 10,000 KVA"
    },
    {
      "code": "850220",
      "description": "Generating sets with spark-ignition internal combustion piston engines"
    },
    {
      "code": "85022010",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS -GENERATING SETS WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINES:ELECTRIC PORTABLE GENERATORS OF AN OUTPUT NOT EXCEEDING 3.5 KVA"
    },
    {
      "code": "85022090",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS -GENERATING SETS WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINES:OTHER"
    },
    {
      "code": "85023100",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - OTHER GENERATING SETS : WIND-POWERED"
    },
    {
      "code": "850239",
      "description": "Other"
    },
    {
      "code": "85023910",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - OTHER GENERATING SETS : - OTHER: POWERED BY STEAM ENGINE"
    },
    {
      "code": "85023920",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - OTHER GENERATING SETS : - OTHER: POWERED BY WATER TURBINE"
    },
    {
      "code": "85023990",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - OTHER GENERATING SETS : - OTHER: OTHER"
    },
    {
      "code": "85024000",
      "description": "ELECTRIC GENERATING SETS AND ROTARY CONVERTERS - ELECTRIC ROTARY CONVERTERS"
    },
    {
      "code": "8503",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502"
    },
    {
      "code": "850300",
      "description": "Parts suitable for use solely or principally with the machines of heading 8501 or 8502"
    },
    {
      "code": "85030010",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502 - PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502:PARTS OF GENERATOR (AC OR DC)"
    },
    {
      "code": "85030021",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502 - PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502:- PARTS OF ELECTRIC MOTOR : OF DC MOTOR"
    },
    {
      "code": "85030029",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502 - PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502:- PARTS OF ELECTRIC MOTOR : OTHER"
    },
    {
      "code": "85030090",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE MACHINES OF HEADING 8501 OR 8502 - OTHER"
    },
    {
      "code": "8504",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS"
    },
    {
      "code": "850410",
      "description": "Ballasts for discharge lamps or tubes"
    },
    {
      "code": "85041010",
      "description": "8504 ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -BALLASTS FOR DISCHARGE LAMPS OR TUBES: CONVENTIONAL TYPE"
    },
    {
      "code": "85041020",
      "description": "8504 ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -BALLASTS FOR DISCHARGE LAMPS OR TUBES: FOR COMPACT FLUORESCENT LAMPS"
    },
    {
      "code": "85041090",
      "description": "8504 ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -BALLASTS FOR DISCHARGE LAMPS OR TUBES: OTHER"
    },
    {
      "code": "85042100",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: HAVING A POWER HANDLING CAPACITY NOT EXCEEDING 650 KVA"
    },
    {
      "code": "85042200",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: HAVING A POWER HANDLING CAPACITY EXCEEDING 650 KVA BUT NOT EXCEEDING 10,000 KVA"
    },
    {
      "code": "850423",
      "description": "Having a power handling capacity exceeding 10,000 kVA"
    },
    {
      "code": "85042310",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: - HAVING A POWER HANDLING CAPACITY EXCEEDING 10,000 KVA: HAVING A POWER HANDLING CAPACITY EXCEEDING 10,000 KVA BUT NOT EXCEEDING 50,000 KVA"
    },
    {
      "code": "85042320",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: - HAVING A POWER HANDLING CAPACITY EXCEEDING 10,000 KVA: HAVING A POWER HANDLING CAPACITY EXCEEDING 50,000 KVA BUT NOT EXCEEDING 1,00,000 KVA"
    },
    {
      "code": "85042330",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: - HAVING A POWER HANDLING CAPACITY EXCEEDING 10,000 KVA: HAVING A POWER HANDLING CAPACITY EXCEEDING 1,00,000 KVA BUT NOT EXCEEDING 2,50,000 KVA"
    },
    {
      "code": "85042340",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - LIQUID DIELECTRIC TRANSFORMERS: - HAVING A POWER HANDLING CAPACITY EXCEEDING 10,000 KVA: HAVING A POWER HANDLING CAPACITY EXCEEDING 2,50,000 KVA"
    },
    {
      "code": "85043100",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER TRANSFORMERS: HAVING A POWER HANDLING CAPACITY NOT EXCEEDING 1 KVA"
    },
    {
      "code": "85043200",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER TRANSFORMERS: HAVING A POWER HANDLING CAPACITY EXCEEDING 1 KVA BUT NOT EXCEEDING 16 KVA"
    },
    {
      "code": "85043300",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER TRANSFORMERS: HAVING A POWER HANDLING CAPACITY EXCEEDING 16 KVA BUT NOT EXCEEDING 500 KVA"
    },
    {
      "code": "85043400",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER TRANSFORMERS: HAVING A POWER HANDLING CAPACITY EXCEEDING 500 KVA"
    },
    {
      "code": "850440",
      "description": "Static converters"
    },
    {
      "code": "85044010",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: ELECTRIC INVERTER"
    },
    {
      "code": "85044021",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: - RECTIFIER: DIP BRIDGE RECTIFIER"
    },
    {
      "code": "85044029",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: - RECTIFIER: OTHER"
    },
    {
      "code": "85044030",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: BATTERY CHARGERS"
    },
    {
      "code": "85044040",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: VOLTAGE REGULATOR AND STABILIZERS (OTHER THAN AUTOMATIC)"
    },
    {
      "code": "85044090",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS -STATIC CONVERTERS: OTHER"
    },
    {
      "code": "850450",
      "description": "Other inductors"
    },
    {
      "code": "85045010",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER INDUCTORS: CHOKE COILS (CHOKES)"
    },
    {
      "code": "85045090",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER INDUCTORS: OTHER"
    },
    {
      "code": "850490",
      "description": "Parts"
    },
    {
      "code": "85049010",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER INDUCTORS: -PARTS : OF TRANSFORMERS"
    },
    {
      "code": "85049090",
      "description": "ELECTRICAL TRANSFORMERS, STATIC CONVERTERS (FOR EXAMPLE, RECTIFIERS) AND INDUCTORS - OTHER INDUCTORS: -PARTS : OTHER"
    },
    {
      "code": "8505",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTROMAGNETIC LIFTING HEADS - Permanent magnets and articles intended to become permanent magnets after magneti-sation"
    },
    {
      "code": "850511",
      "description": "Of metal"
    },
    {
      "code": "85051110",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION: - OF METAL:FERRITE CORES"
    },
    {
      "code": "85051190",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION: - OF METAL:OTHER"
    },
    {
      "code": "85051900",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION: - OTHER"
    },
    {
      "code": "85052000",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES"
    },
    {
      "code": "85053000",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - ELECTRO-MAGNETIC LIFTING HEADS"
    },
    {
      "code": "85059000",
      "description": "ELECTRO-MAGNETS; PERMANENT MAGNETS AND ARTICLES INTENDED TO BECOME PERMANENT MAGNETS AFTER MAGNETISATION; ELECTRO-MAGNETIC OR PERMANENT MAGNET CHUCKS, CLAMPS AND SIMILAR HOLDING DEVICES; ELECTRO-MAGNETIC COUPLINGS, CLUTCHES AND BRAKES; ELECTRO-MAGNETIC LIFTING HEADS - OTHER, INCLUDING PARTS"
    },
    {
      "code": "8506",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES"
    },
    {
      "code": "85061000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES MANGANESE DIOXIDE"
    },
    {
      "code": "85063000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES MERCURIC OXIDE"
    },
    {
      "code": "85064000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES SILVER OXIDE"
    },
    {
      "code": "85065000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES LITHIUM"
    },
    {
      "code": "85066000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES AIR-ZINC"
    },
    {
      "code": "850680",
      "description": "Other primary cells and primary batteries"
    },
    {
      "code": "85068010",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES - OTHER PRIMARY CELLS AND PRIMARY BATTERIES:BUTTON CELLS"
    },
    {
      "code": "85068090",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES - OTHER PRIMARY CELLS AND PRIMARY BATTERIES:OTHER"
    },
    {
      "code": "85069000",
      "description": "PRIMARY CELLS AND PRIMARY BATTERIES -PARTS"
    },
    {
      "code": "8507",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE)"
    },
    {
      "code": "85071000",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) LEAD-ACID, OF A KIND USED FOR STARTING PISTON ENGINES"
    },
    {
      "code": "85072000",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) OTHER LEAD-ACID ACCUMULATORS"
    },
    {
      "code": "85073000",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) NICKEL-CADMIUM"
    },
    {
      "code": "85074000",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) NICKEL-IRON"
    },
    {
      "code": "850750",
      "description": "Nickel-metal hydride"
    },
    {
      "code": "85075000",
      "description": "NICKEL-METAL HYDRIDE"
    },
    {
      "code": "850760",
      "description": "Lithium-ion"
    },
    {
      "code": "85076000",
      "description": "LITHIUM-ION"
    },
    {
      "code": "85078000",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) OTHER ACCUMULATORS"
    },
    {
      "code": "850790",
      "description": "Parts"
    },
    {
      "code": "85079010",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) -PARTS: ACCUMULATOR CASES MADE OF HARD RUBBER AND SEPARATORS"
    },
    {
      "code": "85079090",
      "description": "ELECTRIC ACCUMULATORS, INCLUDING SEPARATORS THEREFOR, WHETHER OR NOT RECTANGULAR (INCLUDING SQUARE) -PARTS: OTHER"
    },
    {
      "code": "8508",
      "description": "VACUUM CLEANERS"
    },
    {
      "code": "85081100",
      "description": "VACCUM CLEANERS - WITH SELF-CONTAINED ELECTIC MOTOR: -- OF A POWER NOT EXCEEDING 1500 W AND HAVING A DUST BAG OR OTHER RECEPTACLE CAPACITY NOT EXCEEDING 20 L"
    },
    {
      "code": "85081900",
      "description": "VACCUM CLEANERS - WITH SELF-CONTAINED ELECTIC MOTOR: -- OTHER"
    },
    {
      "code": "85086000",
      "description": "VACCUM CLEANERS - OTHER VACCUM CLEANERS"
    },
    {
      "code": "85087000",
      "description": "VACCUM CLEANERS - PARTS"
    },
    {
      "code": "8509",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF CONTAINED ELECTRIC MOTOR, OTHER THAN VACUUM CLEANERS OF HEADING 8508."
    },
    {
      "code": "85091000",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR VACUUM CLEANERS, INCLUDING DRY AND WET VACUUM CLEANERS"
    },
    {
      "code": "85092000",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR FLOOR POLISHERS"
    },
    {
      "code": "85093000",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR KITCHEN WASTE DISPOSERS"
    },
    {
      "code": "850940",
      "description": "Food grinders and mixers; fruit or vegetable juice extractors"
    },
    {
      "code": "85094010",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR, OTHER THAN VACUUM CLEANERS OF HEADING 8508 -FOOD GRINDERS AND MIXERS; FRUIT OR VEGETABLE JUICE EXTRACTORS: --- FOOD GRINDERS"
    },
    {
      "code": "85094090",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR, OTHER THAN VACUUM CLEANERS OF HEADING 8508 -FOOD GRINDERS AND MIXERS; FRUIT OR VEGETABLE JUICE EXTRACTORS: --- OTHER"
    },
    {
      "code": "85098000",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR, OTHER THAN VACUUM CLEANERS OF HEADING 8508 - OTHER APPLIANCES"
    },
    {
      "code": "85099000",
      "description": "ELECTRO-MECHANICAL DOMESTIC APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR, OTHER THAN VACUUM CLEANERS OF HEADING 8508 - PARTS"
    },
    {
      "code": "8510",
      "description": "SHAVERS, HAIR CLIPPERS AND HAIR-REMOVING APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR"
    },
    {
      "code": "85101000",
      "description": "SHAVERS, HAIR CLIPPERS AND HAIR-REMOVING APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR SHAVERS"
    },
    {
      "code": "85102000",
      "description": "SHAVERS, HAIR CLIPPERS AND HAIR-REMOVING APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR HAIR CLIPPERS"
    },
    {
      "code": "85103000",
      "description": "SHAVERS, HAIR CLIPPERS AND HAIR-REMOVING APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR HAIR-REMOVING APPLIANCES"
    },
    {
      "code": "85109000",
      "description": "SHAVERS, HAIR CLIPPERS AND HAIR-REMOVING APPLIANCES, WITH SELF-CONTAINED ELECTRIC MOTOR PARTS"
    },
    {
      "code": "8511",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSIONIGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETODYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUT- OUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES"
    },
    {
      "code": "85111000",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES SPARKING PLUGS"
    },
    {
      "code": "851120",
      "description": "Ignition magnetos; magneto-dynamos; magnetic flywheels"
    },
    {
      "code": "85112010",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES -IGNITION MAGNETOS; MAGNETO-DYNAMOS; MAGNETIC FLYWHEELS: ELECTRONIC IGNITION MAGNETOS"
    },
    {
      "code": "85112090",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES -IGNITION MAGNETOS; MAGNETO-DYNAMOS; MAGNETIC FLYWHEELS: OTHER"
    },
    {
      "code": "851130",
      "description": "Distributors; ignition coils"
    },
    {
      "code": "85113010",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES -DISTRIBUTORS; IGNITION COILS: DISTRIBUTORS"
    },
    {
      "code": "85113020",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES -DISTRIBUTORS; IGNITION COILS: IGNITION COILS"
    },
    {
      "code": "85114000",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES STARTER MOTORS AND DUAL PURPOSE STARTER- GENERATORS"
    },
    {
      "code": "85115000",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES OTHER GENERATORS"
    },
    {
      "code": "85118000",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES OTHER EQUIPMENT"
    },
    {
      "code": "85119000",
      "description": "ELECTRICAL IGNITION OR STARTING EQUIPMENT OF A KIND USED FOR SPARK-IGNITION OR COMPRESSION-IGNITION INTERNAL COMBUSTION ENGINES (FOR EXAMPLE, IGNITION MAGNETOS, MAGNETO-DYNAMOS, IGNITION COILS, SPARKING PLUGS AND GLOW PLUGS, STARTER MOTORS); GENERATORS (FOR EXAMPLE, DYNAMOS, ALTERNATORS) AND CUTOUTS OF A KIND USED IN CONJUNCTION WITH SUCH ENGINES PARTS"
    },
    {
      "code": "8512",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES"
    },
    {
      "code": "85121000",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES LIGHTING OR VISUAL SIGNALLING EQUIPMENT OF A KIND USED ON BICYCLES"
    },
    {
      "code": "851220",
      "description": "Other lighting or visual signalling equipment"
    },
    {
      "code": "85122010",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES - OTHER LIGHTING OR VISUAL SIGNALLING EQUIPMENT: HEAD LAMPS, TAIL LAMPS, STOP LAMPS, SIDE LAMPS AND BLINKERS"
    },
    {
      "code": "85122020",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES - OTHER LIGHTING OR VISUAL SIGNALLING EQUIPMENT: OTHER AUTOMOBILE LIGHTING EQUIPMENT"
    },
    {
      "code": "85122090",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES - OTHER LIGHTING OR VISUAL SIGNALLING EQUIPMENT: OTHER"
    },
    {
      "code": "851230",
      "description": "Sound signalling equipment"
    },
    {
      "code": "85123010",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES -SOUND SIGNALLING EQUIPMENT: HORNS"
    },
    {
      "code": "85123090",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES -SOUND SIGNALLING EQUIPMENT: OTHER"
    },
    {
      "code": "85124000",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS"
    },
    {
      "code": "85129000",
      "description": "ELECTRICAL LIGHTING OR SIGNALLING EQUIPMENT (EXCLUDING ARTICLES OF HEADING 8539), WINDSCREEN WIPERS, DEFROSTERS AND DEMISTERS, OF A KIND USED FOR CYCLES OR MOTOR VEHICLES PARTS"
    },
    {
      "code": "8513",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512"
    },
    {
      "code": "851310",
      "description": "Lamps"
    },
    {
      "code": "85131010",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 -LAMPS: TORCH"
    },
    {
      "code": "85131020",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 -LAMPS: OTHER FLASH-LIGHTS EXCLUDING THOSE FOR PHOTOGRAPHIC PURPOSES"
    },
    {
      "code": "85131030",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 -LAMPS: MINERS SAFETY LAMPS"
    },
    {
      "code": "85131040",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 -LAMPS: MAGNETO LAMPS"
    },
    {
      "code": "85131090",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 -LAMPS: OTHER"
    },
    {
      "code": "85139000",
      "description": "PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 8513 10 PORTABLE ELECTRIC LAMPS DESIGNED TO FUNCTION BY THEIR OWN SOURCE OF ENERGY (FOR EXAMPLE, DRY BATTERIES, ACCUMULATORS, MAGNETOS), OTHER THAN LIGHTING EQUIPMENT OF HEADING 8512 - PARTS"
    },
    {
      "code": "8514",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS"
    },
    {
      "code": "85141000",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS RESISTANCE HEATED FURNACES AND OVENS"
    },
    {
      "code": "85142000",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS FURNACES AND OVENS FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS"
    },
    {
      "code": "851430",
      "description": "Other furnaces and ovens"
    },
    {
      "code": "85143010",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS - OTHER FURNACES AND OVENS: FOR MELTING"
    },
    {
      "code": "85143090",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS - OTHER FURNACES AND OVENS: OTHER"
    },
    {
      "code": "85144000",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS OTHER EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS"
    },
    {
      "code": "85149000",
      "description": "INDUSTRIAL OR LABORATORY ELECTRIC FURNACES AND OVENS (INCLUDING THOSE FUNCTIONING BY INDUCTION OR DIELECTRIC LOSS); OTHER INDUSTRIAL OR LABORATORY EQUIPMENT FOR THE HEAT TREATMENT OF MATERIALS BY INDUCTION OR DIELECTRIC LOSS PARTS"
    },
    {
      "code": "8515",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS"
    },
    {
      "code": "85151100",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - BRAZING OR SOLDERING MACHINES AND APPARATUS: SOLDERING IRONS AND GUNS"
    },
    {
      "code": "85151900",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - BRAZING OR SOLDERING MACHINES AND APPARATUS: OTHER"
    },
    {
      "code": "851521",
      "description": "Fully or partly automatic"
    },
    {
      "code": "85152110",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR RESISTANCE WELDING OF METAL: - FULLY OR PARTLY AUTOMATIC: AUTOMATIC SPOT WELDING MACHINERY"
    },
    {
      "code": "85152120",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR RESISTANCE WELDING OF METAL: - FULLY OR PARTLY AUTOMATIC: AUTOMATIC BUTT WELDING MACHINERY"
    },
    {
      "code": "85152190",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR RESISTANCE WELDING OF METAL: - FULLY OR PARTLY AUTOMATIC: OTHER"
    },
    {
      "code": "85152900",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR RESISTANCE WELDING OF METAL: OTHER"
    },
    {
      "code": "85153100",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR ARC (INCLUDING PLASMA ARC) WELDING OF METALS: FULLY OR PARTLY AUTOMATIC"
    },
    {
      "code": "851539",
      "description": "Other"
    },
    {
      "code": "85153910",
      "description": "39 ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR ARC (INCLUDING PLASMA ARC) WELDING OF METALS: - OTHER: AC ARC WELDING MACHINERY"
    },
    {
      "code": "85153920",
      "description": "39 ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR ARC (INCLUDING PLASMA ARC) WELDING OF METALS: - OTHER: ARGON ARC WELDING MACHINERY"
    },
    {
      "code": "85153990",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - MACHINES AND APPARATUS FOR ARC (INCLUDING PLASMA ARC) WELDING OF METALS: - OTHER: OTHER"
    },
    {
      "code": "851580",
      "description": "Other machines and apparatus"
    },
    {
      "code": "85158010",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - OTHER MACHINES AND APPARATUS: HIGH-FREQUENCY PLASTIC WELDING MACHINE"
    },
    {
      "code": "85158090",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS - OTHER MACHINES AND APPARATUS: OTHER"
    },
    {
      "code": "85159000",
      "description": "ELECTRIC (INCLUDING ELECTRICALLY HEATED GAS), LASER OR OTHER LIGHT OR PHOTO BEAM, ULTRASONIC, ELECTRON BEAM, MAGNETIC PULSE OR PLASMA ARC SOLDERING, BRAZING OR WELDING MACHINES AND APPARATUS, WHETHER OR NOT CAPABLE OF CUTTING; ELECTRIC MACHINES AND APPARATUS FOR HOT SPRAYING OF METALS OR CERMETS PARTS"
    },
    {
      "code": "8516",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTRO-THERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545"
    },
    {
      "code": "85161000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS"
    },
    {
      "code": "85162100",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - ELECTRIC SPACE HEATING APPARATUS AND ELECTRIC SOIL HEATING APPARATUS: STORAGE HEATING RADIATORS"
    },
    {
      "code": "85162900",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - ELECTRIC SPACE HEATING APPARATUS AND ELECTRIC SOIL HEATING APPARATUS: OTHER"
    },
    {
      "code": "85163100",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - ELECTRO-THERMIC HAIR-DRESSING OR HAND-DRYING APPARATUS : HAIR DRYERS"
    },
    {
      "code": "85163200",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - ELECTRO-THERMIC HAIR-DRESSING OR HAND-DRYING APPARATUS : OTHER HAIR-DRESSING APPARATUS"
    },
    {
      "code": "85163300",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - ELECTRO-THERMIC HAIR-DRESSING OR HAND-DRYING APPARATUS : HAND-DRYING APPARATUS"
    },
    {
      "code": "85164000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 ELECTRIC SMOOTHING IRONS"
    },
    {
      "code": "85165000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 MICROWAVE OVENS"
    },
    {
      "code": "85166000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 OTHER OVENS; COOKERS, COOKING PLATES, BOILING RINGS, GRILLERS AND ROASTERS"
    },
    {
      "code": "85167100",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - OTHER ELECTRO-THERMIC APPLIANCES: COFFEE OR TEA MAKERS"
    },
    {
      "code": "85167200",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - OTHER ELECTRO-THERMIC APPLIANCES: TOASTERS"
    },
    {
      "code": "851679",
      "description": "Other"
    },
    {
      "code": "85167910",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - OTHER ELECTRO-THERMIC APPLIANCES: - OTHER: ELECTRO-THERMIC FLUID HEATERS"
    },
    {
      "code": "85167920",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - OTHER ELECTRO-THERMIC APPLIANCES: - OTHER: ELECTRICAL OR ELECTRONIC DEVICES FOR REPELLING INSECTS (FOR EXAMPLE, MOSQUITOES OR OTHER SIMILAR KIND OF INSECTS)"
    },
    {
      "code": "85167990",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 - OTHER ELECTRO-THERMIC APPLIANCES: - OTHER: OTHER"
    },
    {
      "code": "85168000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 ELECTRIC HEATING RESISTORS"
    },
    {
      "code": "85169000",
      "description": "ELECTRIC INSTANTANEOUS OR STORAGE WATER HEATERS AND IMMERSION HEATERS; ELECTRIC SPACE HEATING APPARATUS AND SOIL HEATING APPARATUS; ELECTROTHERMIC HAIR-DRESSING APPARATUS (FOR EXAMPLE, HAIR DRYERS, HAIR CURLERS, CURLING TONG HEATERS) AND HAND DRYERS; ELECTRIC SMOOTHING IRONS; OTHER ELECTRO-THERMIC APPLIANCES OF A KIND USED FOR DOMESTIC PURPOSES; ELECTRIC HEATING RESISTORS, OTHER THAN THOSE OF HEADING 8545 PARTS"
    },
    {
      "code": "8517",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - Telephone sets, including telephones for cellular networks or for other wireless networks"
    },
    {
      "code": "851711",
      "description": "Line telephone sets with cordless handsets"
    },
    {
      "code": "85171110",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- LINE TELEPHONE SETS WITH CORDLESS HANDSETS: --- PUSH BUTTON TYPE"
    },
    {
      "code": "85171190",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- LINE TELEPHONE SETS WITH CORDLESS HANDSETS: --- OTHER"
    },
    {
      "code": "851712",
      "description": "Telephones for cellular networks or for other wireless networks"
    },
    {
      "code": "85171210",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: --- PUSH BUTTON TYPE"
    },
    {
      "code": "85171211",
      "description": "Mobils phone, other than push button type"
    },
    {
      "code": "85171219",
      "description": "Mobile phone, push button type"
    },
    {
      "code": "85171290",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: --- OTHER"
    },
    {
      "code": "851718",
      "description": "Other"
    },
    {
      "code": "85171810",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- OTHER: --- PUSH BUTTON TYPE"
    },
    {
      "code": "85171890",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS: -- OTHER: --- OTHER"
    },
    {
      "code": "85171911",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - TELEPHONE SETS; VIDEOPHONES: - OTHER: - TELEPHONE SETS: PUSH BUTTON TYPE"
    },
    {
      "code": "85171912",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - TELEPHONE SETS; VIDEOPHONES: - OTHER: - TELEPHONE SETS: ROTARY DIAL TYPE"
    },
    {
      "code": "85171919",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - TELEPHONE SETS; VIDEOPHONES: - OTHER: - TELEPHONE SETS: OTHER"
    },
    {
      "code": "85171920",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - TELEPHONE SETS; VIDEOPHONES: - OTHER: VIDEOPHONES"
    },
    {
      "code": "85172100",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - FACSIMILE MACHINES AND TELEPRINTERS: FASCIMILE MACHINES"
    },
    {
      "code": "85172200",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - FACSIMILE MACHINES AND TELEPRINTERS: TELEPRINTERS"
    },
    {
      "code": "85173000",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES TELEPHONIC OR TELEGRAPHIC SWITCHING APPARATUS"
    },
    {
      "code": "85175010",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: PLCC EQUIPMENT"
    },
    {
      "code": "85175020",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: VOICE FREQUENCY TELEGRAPHY"
    },
    {
      "code": "85175030",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: MODEMS (MODULATORS-DEMODULATORS)"
    },
    {
      "code": "85175040",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: HIGH BIT RATE DIGITAL SUBSCRIBER LINE SYSTEM (HDSL)"
    },
    {
      "code": "85175050",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: DIGITAL LOOP CARRIER SYSTEM (DLC)"
    },
    {
      "code": "85175060",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: SYNCHRONOUS DIGITAL HIERARCHY SYSTEM (SDH)"
    },
    {
      "code": "85175070",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: MULTIPLEXER, STATISTICAL MULTIPLEXER"
    },
    {
      "code": "85175091",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: - OTHER: ISDN TERMINALS"
    },
    {
      "code": "85175092",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: - OTHER: ISDN TERMINAL ADAPTERS"
    },
    {
      "code": "85175093",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: - OTHER: ROUTERS"
    },
    {
      "code": "85175094",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: - OTHER: X25 PADS"
    },
    {
      "code": "85175099",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS, FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS: - OTHER: OTHER"
    },
    {
      "code": "85176100",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- BASE STATIONS"
    },
    {
      "code": "851762",
      "description": "Machines for the reception, conversion and transmission or regeneration of voice, images or other data, including switching and routing apparatus"
    },
    {
      "code": "85176210",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- PLCC EQUIPMENT"
    },
    {
      "code": "85176220",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- VOICE FREQUENCY TELEGRAPHY"
    },
    {
      "code": "85176230",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- MODEMS (MODULATORS-DEMODULATORS)"
    },
    {
      "code": "85176240",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- HIGH BIT RATE DIGITAL SUBSCRIBER LINE SYSTEM (HSDL)"
    },
    {
      "code": "85176250",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- DIGIAL LOOP CARRIER SYSTEM (DLC)"
    },
    {
      "code": "85176260",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- SYNCHRONOUS DIGITAL HIERARCHY SYSTEM (SDH)"
    },
    {
      "code": "85176270",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- MULTIPLEXERS, STATISTICAL MULTIPLEXERS"
    },
    {
      "code": "85176290",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- MACHINES FOR THE RECEPTION, CONVERSION AND TRANSMISSION OR REGENRATION OF VOICE, IMAGES OR OTHER DATA, INCLUDING SWITCHING AND ROUTING APPARATUS: --- OTHER"
    },
    {
      "code": "851769",
      "description": "Other"
    },
    {
      "code": "85176910",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- ISDN SYSTEM"
    },
    {
      "code": "85176920",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- ISDN TERMINAL ADAPTOR"
    },
    {
      "code": "85176930",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- ROUTERS"
    },
    {
      "code": "85176940",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- X25 PADS"
    },
    {
      "code": "85176950",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- SUBSCRIBER END EQUIPMENT"
    },
    {
      "code": "85176960",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- SET TOP BOXES FOR GAINING ACCESS TO INTERNET"
    },
    {
      "code": "85176970",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- ATTACHMENT FOR TELEPHONES"
    },
    {
      "code": "85176990",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK) -- OTHER: --- OTHER"
    },
    {
      "code": "851770",
      "description": "Parts"
    },
    {
      "code": "85177010",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - PARTS --- POPULATED, LOADED OR STUFFED PRINTED CIRCUIT BOARDS"
    },
    {
      "code": "85177090",
      "description": "TELEPHONE SETS, INCLUDING TELEPHONES FOR CELLULAR NETWORKS OR FOR OTHER WIRELESS NETWORKS; OTHER APPARATUS FOR THE TRANSMISSION OR RECEPTION OF VOICE, IMAGES OR OTHER DATA, INCLUDING APPARATUS FOR COMMUNICATION IN A WIRED OR WIRELESS NETWORK (SUCH AS A LOCAL OR WIDE AREA NETWORK), OTHER THAN TRANSMISSION OR RECEPTION APPARATUS OF HEADING 8443, 8525, 8527 OR 8528 - PARTS --- OTHER"
    },
    {
      "code": "85178010",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS: ATTACHMENTS FOR TELEPHONES"
    },
    {
      "code": "85178020",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS: SUBSCRIBER END EQUIPMENT"
    },
    {
      "code": "85178030",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS: SET TOP BOXES FOR GAINING ACCESS TO THE INTERNET"
    },
    {
      "code": "85178090",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - OTHER APPARATUS: OTHER"
    },
    {
      "code": "85179010",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - PARTS: POPULATED, LOADED OR STUFFED PRINTED CIRCUIT BOARDS"
    },
    {
      "code": "85179090",
      "description": "ELECTRICAL APPARATUS FOR LINE TELEPHONY OR LINE TELEGRAPHY, INCLUDING LINE TELEPHONE SETS WITH CORDLESS HANDSETS AND TELECOMMUNICATION APPARATUS FOR CARRIER-CURRENT LINE SYSTEMS OR FOR DIGITAL LINE SYSTEMS; VIDEOPHONES - PARTS: OTHER"
    },
    {
      "code": "8518",
      "description": "MICROPHONES AND STANDS THEREFOR: LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES: HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LUUDSPEAKERS: AUDIO-FREQUENCY ELECTRIC AMPLIFIERS: ELECTRIC SOUND AMPLIFIER SETS"
    },
    {
      "code": "85181000",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET MICROPHONES AND STANDS THEREFOR"
    },
    {
      "code": "85182100",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET - LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES: SINGLE LOUDSPEAKERS, MOUNTED IN THEIR ENCLOSURES"
    },
    {
      "code": "85182200",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET - LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES: MULTIPLE LOUDSPEAKERS, MOUNTED IN THE SAME ENCLOSURE"
    },
    {
      "code": "85182900",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET - LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES: OTHER"
    },
    {
      "code": "85183000",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS"
    },
    {
      "code": "85184000",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET AUDIO-FREQUENCY ELECTRIC AMPLIFIERS"
    },
    {
      "code": "85185000",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET ELECTRIC SOUND AMPLIFIER SETS"
    },
    {
      "code": "85189000",
      "description": "MICROPHONES AND STANDS THEREFOR; LOUDSPEAKERS, WHETHER OR NOT MOUNTED IN THEIR ENCLOSURES; HEADPHONES AND EARPHONES, WHETHER OR NOT COMBINED WITH A MICROPHONE, AND SETS CONSISTING OF A MICROPHONE AND ONE OR MORE LOUDSPEAKERS; AUDIOFREQUENCY ELECTRIC AMPLIFIERS; ELECTRIC SOUND AMPLIFIER SET PARTS"
    },
    {
      "code": "8519",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "85191000",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE COIN OR DISC-OPERATED RECORD-PLAYERS"
    },
    {
      "code": "85192000",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - APPARATUS OPERATED BY COINS, BANK NOTES, BANK CARDS, TOKENS OR BY OTHER MEANS OF PAYMENT"
    },
    {
      "code": "85192100",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER RECORD-PLAYERS: WITHOUT LOUDSPEAKER"
    },
    {
      "code": "85192900",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER RECORD-PLAYERS: OTHER"
    },
    {
      "code": "85193000",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS -TURNTABLES (RECORD-DECKS)"
    },
    {
      "code": "85193100",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - TURNTABLES (RECORD-DECKS): WITH AUTOMATIC RECORD CHANGING MECHANISM"
    },
    {
      "code": "85193900",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - TURNTABLES (RECORD-DECKS): OTHER"
    },
    {
      "code": "85194000",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE TRANSCRIBING MACHINES"
    },
    {
      "code": "85195000",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS -TELEPHONE ANSWERING MACHINES"
    },
    {
      "code": "85198100",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- USING MAGNETIC, OPTICAL OR SEMICONDUCTOR MEDIA"
    },
    {
      "code": "851989",
      "description": "Other"
    },
    {
      "code": "85198910",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- OTHER --- AUDIO COMPACT DISC PLAYER"
    },
    {
      "code": "85198920",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- OTHER --- COMPACT DISC CHANGER INCLUDING MINI DISC PLAYER OR LASER DISC PLAYER"
    },
    {
      "code": "85198930",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- OTHER --- TIME CODE RECORDER"
    },
    {
      "code": "85198940",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- OTHER --- MP-3 PLAYER"
    },
    {
      "code": "85198990",
      "description": "SOUND RECORDING OR REPRODUCING APPARATUS - OTHER APPARATUS -- OTHER --- OTHER"
    },
    {
      "code": "85199200",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER SOUND REPRODUCING APPARATUS: POCKET-SIZE CASSETTE PLAYERS"
    },
    {
      "code": "85199300",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER SOUND REPRODUCING APPARATUS: OTHER, CASSETTE-TYPE"
    },
    {
      "code": "85199910",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER : - OTHER SOUND REPRODUCING APPARATUS: AUDIO COMPACT DISC PLAYERS"
    },
    {
      "code": "85199920",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER : - OTHER SOUND REPRODUCING APPARATUS: COMPACT DISC CHANGER INCLUDING MINI DISC PLAYER OR LASER DISC PLAYER"
    },
    {
      "code": "85199930",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER : - OTHER SOUND REPRODUCING APPARATUS: TIME CODE READERS"
    },
    {
      "code": "85199940",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER SOUND REPRODUCING APPARATUS: - OTHER : MP-3 PLAYER"
    },
    {
      "code": "85199990",
      "description": "TURNTABLES (RECORD-DECKS), RECORD-PLAYERS, CASSETTE-PLAYERS AND OTHER SOUND REPRODUCING APPARATUS, NOT INCORPORATING A SOUND RECORDING DEVICE - OTHER : - OTHER SOUND REPRODUCING APPARATUS: OTHER"
    },
    {
      "code": "8520",
      "description": "OMITTED"
    },
    {
      "code": "85201000",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE DICTATING MACHINES NOT CAPABLE OF OPERATING WITHOUT AN EXTERNAL SOURCE OF POWER"
    },
    {
      "code": "85202000",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE TELEPHONE ANSWERING MACHINES"
    },
    {
      "code": "85203200",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER MAGNETIC TAPE RECORDERS INCORPORATING SOUND REPRODUCING APPARATUS: DIGITAL AUDIO TYPE"
    },
    {
      "code": "85203310",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER MAGNETIC TAPE RECORDERS INCORPORATING SOUND REPRODUCING APPARATUS: - OTHER, CASSETTE-TYPE: HEAVY-DUTY TAPE RECORDERS"
    },
    {
      "code": "85203390",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER MAGNETIC TAPE RECORDERS INCORPORATING SOUND REPRODUCING APPARATUS: - OTHER, CASSETTE-TYPE: OTHER"
    },
    {
      "code": "85203910",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER MAGNETIC TAPE RECORDERS INCORPORATING SOUND REPRODUCING APPARATUS: - OTHER : HEAVY-DUTY TAPE RECORDERS"
    },
    {
      "code": "85203990",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER MAGNETIC TAPE RECORDERS INCORPORATING SOUND REPRODUCING APPARATUS: - OTHER : OTHER"
    },
    {
      "code": "85209010",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER: COMPACT DISC RECORDING APPARATUS"
    },
    {
      "code": "85209090",
      "description": "MAGNETIC TAPE RECORDERS AND OTHER SOUND RECORDING APPARATUS, WHETHER OR NOT INCORPORATING A SOUND REPRODUCING DEVICE - OTHER: OTHER"
    },
    {
      "code": "8521",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER"
    },
    {
      "code": "852110",
      "description": "Magnetic tape-type"
    },
    {
      "code": "85211011",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - CASSETTE TAPE-TYPE: PROFESSIONAL VIDEO TAPE RECORDERS WITH  OR 1 TAPE"
    },
    {
      "code": "85211012",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - CASSETTE TAPE-TYPE: VIDEO RECORDERS BETACAM OR BETACAM SP OR DIGITAL BETACAM S-VHS OR DIGITAL-S"
    },
    {
      "code": "85211019",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - CASSETTE TAPE-TYPE: OTHER"
    },
    {
      "code": "85211021",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - SPOOL TYPE: PROFESSIONAL VIDEO TAPE RECORDERS WITH  OR 1 TAPE"
    },
    {
      "code": "85211022",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - SPOOL TYPE: VIDEO RECORDERS BETACAM OR BETACAM SP OR DIGITAL BETACAM S-VHS OR DIGITAL-S"
    },
    {
      "code": "85211029",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - SPOOL TYPE: OTHER"
    },
    {
      "code": "85211091",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - OTHER: PROFESSIONAL VIDEO TAPE RECORDERS WITH  OR 1 TAPE SOLID STATE OR OTHERWISE"
    },
    {
      "code": "85211092",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - OTHER: VIDEO RECORDERS BETACAM OR BETACAM SP OR DIGITAL BETACAM S-VHS OR DIGITAL-S"
    },
    {
      "code": "85211099",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER -MAGNETIC TAPE-TYPE: - OTHER: OTHER"
    },
    {
      "code": "852190",
      "description": "Other"
    },
    {
      "code": "85219010",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER OTHER : VIDEO DUPLICATING SYSTEM WITH MASTER AND SLAVE CONTROL"
    },
    {
      "code": "85219020",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER OTHER : DVD PLAYER"
    },
    {
      "code": "85219090",
      "description": "VIDEO RECORDING OR REPRODUCING APPARATUS, WHETHER OR NOT INCORPORATING A VIDEO TUNER OTHER : OTHER"
    },
    {
      "code": "8522",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8519 OR 8521"
    },
    {
      "code": "85221000",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8519 TO 8521PICK-UP CARTRIDGES"
    },
    {
      "code": "85229000",
      "description": "PARTS AND ACCESSORIES SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8519 TO 8521- OTHER"
    },
    {
      "code": "8523",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, \"\"SMART CARDS\"\" AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37"
    },
    {
      "code": "85231111",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM : - FOR RECORDING SOUND : AUDIO CASSETTES"
    },
    {
      "code": "85231119",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM : - FOR RECORDING SOUND : OTHER"
    },
    {
      "code": "85231121",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM : - FOR RECORDING PHENOMENA OTHER THAN SOUND: VIDEO CASSETTES"
    },
    {
      "code": "85231122",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM : - FOR RECORDING PHENOMENA OTHER THAN SOUND: OTHER VIDEO MAGNETIC TAPE INCLUDING THOSE IN HUBS AND REELS, ROLLS, PANCAKES AND JUMBO ROLLS"
    },
    {
      "code": "85231129",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM : - FOR RECORDING PHENOMENA OTHER THAN SOUND: OTHER"
    },
    {
      "code": "85231211",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - FOR RECORDING SOUND: AUDIO CASSETTES"
    },
    {
      "code": "85231219",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - FOR RECORDING SOUND: OTHER"
    },
    {
      "code": "85231221",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : VIDEO CASSETTES"
    },
    {
      "code": "85231222",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : OTHER VIDEO MAGNETIC TAPE INCLUDING THOSE IN HUBS AND REELS, ROLLS, PANCAKES AND JUMBO ROLLS"
    },
    {
      "code": "85231229",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : OTHER"
    },
    {
      "code": "85231311",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING SOUND : AUDIO CASSETTE TAPE OF WIDTH EXCEEDING 6.5 MM BUT NOT EXCEEDING 35 MM, AND 16 MM SPROCKET TAPES"
    },
    {
      "code": "85231312",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING SOUND : OTHER AUDIO CASSETTE TAPE"
    },
    {
      "code": "85231313",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING SOUND : OTHER AUDIO MAGNETIC TAPE OF WIDTH EXCEEDING6.5 MM BUT NOT EXCEEDING 35 MM, AND 16 MM SPROCKET TAPES"
    },
    {
      "code": "85231319",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING SOUND : OTHER"
    },
    {
      "code": "85231321",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND :  AND 1 VIDEO CASSETTES"
    },
    {
      "code": "85231322",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND :# VIDEO CASSETTE SUITABLE TO WORK WITH BETACAM, BETACAM SP/M II AND VHS TYPE VCR"
    },
    {
      "code": "85231323",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : OTHER VIDEO CASSETTES"
    },
    {
      "code": "85231324",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND :  AND 1 VIDEO TAPES"
    },
    {
      "code": "85231325",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : OTHER VIDEO TAPES"
    },
    {
      "code": "85231329",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM: - FOR RECORDING PHENOMENA OTHER THAN SOUND : OTHER"
    },
    {
      "code": "85232010",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC DISCS: HARD DISC PACK"
    },
    {
      "code": "85232020",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC DISCS: FLOPPY DISC OR DISKETTES"
    },
    {
      "code": "85232090",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - MAGNETIC DISCS: OTHER"
    },
    {
      "code": "85232100",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- CARDS INCORPORATING A MAGNETIC STRIPE"
    },
    {
      "code": "852329",
      "description": "Other"
    },
    {
      "code": "85232910",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- AUDIO CASSETTES"
    },
    {
      "code": "85232920",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- VIDEO CASSETTES"
    },
    {
      "code": "85232930",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- VIDEO MAGNETIC TAPE INCLUDING THOSE IN HUBS AND REELS, ROLLS, PANCAKES AND JUMBO ROLLS"
    },
    {
      "code": "85232940",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- 3/4 INCH AND 1 INCH VIDEO CASSETTES"
    },
    {
      "code": "85232950",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- 1/2 INCH VIDEO CASSETTES SUITABLE TO WORK WITH BETACAM, BETACAM SP/M II AND VHS TYPE VCR"
    },
    {
      "code": "85232960",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- OTHER VIDEO CASSETTES AND TAPES 3/4 INCH AND 1 INCH VIDEO CASSETTES"
    },
    {
      "code": "85232970",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- ALL KINDS OF MAGNETIC DISCS"
    },
    {
      "code": "85232980",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- CARTRIDGE TAPE"
    },
    {
      "code": "85232990",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - MAGNETIC MEDIA -- OTHER --- OTHER"
    },
    {
      "code": "85233000",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 CARDS INCORPORATING A MAGNETIC STRIPE"
    },
    {
      "code": "852340",
      "description": "Optical media"
    },
    {
      "code": "852341",
      "description": "Unrecorded"
    },
    {
      "code": "85234110",
      "description": "COMPACT DISC (AUDIO/VIDEO)"
    },
    {
      "code": "85234120",
      "description": "BLANK MASTER DISCS (THAT IS, SUBSTRATE) FOR PRODUCING STAMPER FOR COMPACT DISC"
    },
    {
      "code": "85234130",
      "description": "MATRICES FOR PRODUCTION OF RECORDS; PREPARED RECORD BLANKS"
    },
    {
      "code": "85234140",
      "description": "CARTRIDGE TAPE"
    },
    {
      "code": "85234150",
      "description": "1/2 INCH VIDEO CASSETTE SUITABLE TO WORK WITH DIGITAL VCR"
    },
    {
      "code": "85234160",
      "description": "DVD"
    },
    {
      "code": "85234190",
      "description": "OTHER"
    },
    {
      "code": "852349",
      "description": "Other"
    },
    {
      "code": "85234910",
      "description": "COMPACT DISC (AUDIO)"
    },
    {
      "code": "85234920",
      "description": "COMPACT DISC (VIDEO)"
    },
    {
      "code": "85234930",
      "description": "STAMPER FOR CD AUDIO, CD VIDEO AND CD-ROM"
    },
    {
      "code": "85234940",
      "description": "DVD"
    },
    {
      "code": "85234950",
      "description": "MATRICES FOR PRODUCTION OF RECORDS; PREPARED RECORD BLANKS"
    },
    {
      "code": "85234960",
      "description": "CARTRIDGE TAPE"
    },
    {
      "code": "85234970",
      "description": "1/2 INCH VIDEO CASSETTE SUITABLE TO WORK WITH DIGITAL VCR"
    },
    {
      "code": "85234990",
      "description": "OTHER"
    },
    {
      "code": "85235100",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- SOLID-STATE NON-VOLATILE STORAGE DEVICES"
    },
    {
      "code": "852352",
      "description": "Smart cards"
    },
    {
      "code": "85235210",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- SMART CARDS --- SIM CARDS"
    },
    {
      "code": "85235220",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- SMART CARDS --- MEMORY CARDS"
    },
    {
      "code": "85235290",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- SMART CARDS --- OTHER"
    },
    {
      "code": "852359",
      "description": "Other"
    },
    {
      "code": "85235910",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- OTHER --- PROXIMITY CARDS AND TAGS"
    },
    {
      "code": "85235990",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - SEMI-CONDUCTOR MEDIA -- OTHER --- OTHER"
    },
    {
      "code": "852380",
      "description": "Other"
    },
    {
      "code": "85238010",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- GRAMAPHONE RECORDS"
    },
    {
      "code": "85238020",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- INFORMATION TECHNOLOGY SOFTWARE"
    },
    {
      "code": "85238030",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- AUDIO-VISUAL NEWS OR AUDIO VISUAL VIEWS"
    },
    {
      "code": "85238040",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- CHILDRENS VIDEO FILMS"
    },
    {
      "code": "85238050",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- VIDEO TAPES OF EDUCATIONAL NATURE"
    },
    {
      "code": "85238060",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- 2-D/3-D COMPUTER GRAPHICS"
    },
    {
      "code": "85238090",
      "description": "DISCS, TAPES, SOLID-STATE NON-VOLATILE STORAGE DEVICES, SMART CARDS AND OTHER MEDIA FOR THE RECORDING OF SOUND OR OF OTHER PHENOMENA, WHETHER OR NOT RECORDED, INCLUDING MATRICES AND MASTERS PRODUCTION OF DISCS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER --- OTHER"
    },
    {
      "code": "85239010",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: MATRICES FOR THE PRODUCTION OF RECORDS; PREPARED RECORD BLANKS"
    },
    {
      "code": "85239020",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: CARTRIDGE TAPE"
    },
    {
      "code": "85239030",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER:  VIDEO CASSETTE SUITABLE TO WORK WITH DIGITAL TYPE VCR"
    },
    {
      "code": "85239040",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: UNRECORDED COMPACT DISC (AUDIO)"
    },
    {
      "code": "85239050",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: COMPACT DISC RECORDABLE"
    },
    {
      "code": "85239060",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: BLANK MASTER DISC (I.E. SUBSTRATE) FOR PRODUCING STAMPER FOR COMPACT DISC"
    },
    {
      "code": "85239090",
      "description": "PREPARED UNRECORDED MEDIA FOR SOUND RECORDING OR SIMILAR RECORDING OF OTHER PHENOMENA, OTHER THAN PRODUCTS OF CHAPTER 37 - OTHER: OTHER"
    },
    {
      "code": "8524",
      "description": "OMITTED"
    },
    {
      "code": "85241010",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - GRAMOPHONE RECORDS: LEARNING AIDS, SUCH AS, LANGUAGE RECORDS"
    },
    {
      "code": "85241090",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - GRAMOPHONE RECORDS: OTHER"
    },
    {
      "code": "85243111",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: INFORMATION TECHNOLOGY SOFTWARE"
    },
    {
      "code": "85243119",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: OTHER"
    },
    {
      "code": "85243190",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: OTHER"
    },
    {
      "code": "85243210",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - FOR REPRODUCING SOUND ONLY: PRE-RECORDED AUDIO COMPACT DISCS OR PRE-RECORDED AUDIO MINI DISCS"
    },
    {
      "code": "85243290",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - FOR REPRODUCING SOUND ONLY: OTHER"
    },
    {
      "code": "85243910",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - OTHER: VIDEO COMPACT DISC OF EDUCATIONAL NATURE"
    },
    {
      "code": "85243920",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - OTHER: OTHER VIDEO COMPACT DISCS"
    },
    {
      "code": "85243930",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - OTHER: DIGITAL VIDEO DISCS"
    },
    {
      "code": "85243990",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - DISCS FOR LASER READING SYSTEMS: - OTHER: OTHER"
    },
    {
      "code": "85244011",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 -MAGNETIC TAPES FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: INFORMATION TECHNOLOGY SOFTWARE"
    },
    {
      "code": "85244019",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 -MAGNETIC TAPES FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: OTHER"
    },
    {
      "code": "85244090",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 -MAGNETIC TAPES FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: OTHER"
    },
    {
      "code": "85245111",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: - LEARNING AIDS: AUDIO CASSETTES"
    },
    {
      "code": "85245112",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: - LEARNING AIDS: VIDEO TAPES OF EDUCATIONAL NATURE"
    },
    {
      "code": "85245119",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: - LEARNING AIDS: OTHER"
    },
    {
      "code": "85245120",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: AUDIO-VISUAL NEWS OR AUDIO-VISUAL VIEWS MATERIAL INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "85245130",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: CHILDRENS VIDEO FILMS"
    },
    {
      "code": "85245140",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: OTHER VIDEO FILMS"
    },
    {
      "code": "85245190",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH NOT EXCEEDING 4 MM: OTHER"
    },
    {
      "code": "85245211",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - LEARNING AIDS: AUDIO CASSETTES"
    },
    {
      "code": "85245212",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - LEARNING AIDS: VIDEO TAPES OF EDUCATIONAL NATURE"
    },
    {
      "code": "85245219",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: - LEARNING AIDS: OTHER"
    },
    {
      "code": "85245220",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: AUDIO-VISUAL NEWS OR AUDIO-VISUAL VIEWS MATERIAL INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "85245230",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: CHILDRENS VIDEO FILMS"
    },
    {
      "code": "85245240",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: OTHER VIDEO FILMS"
    },
    {
      "code": "85245290",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 4 MM BUT NOT EXCEEDING 6.5 MM: OTHER"
    },
    {
      "code": "85245311",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : - LEARNING AIDS: AUDIO CASSETTES"
    },
    {
      "code": "85245312",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : - LEARNING AIDS: VIDEO TAPES OF EDUCATIONAL NATURE"
    },
    {
      "code": "85245319",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : - LEARNING AIDS: OTHER"
    },
    {
      "code": "85245320",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : AUDIO-VISUAL NEWS OR AUDIO-VISUAL MATERIAL INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "85245330",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : CHILDREMS VIDEO FILMS"
    },
    {
      "code": "85245340",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : OTHER VIDEO FILMS"
    },
    {
      "code": "85245390",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER MAGNETIC TAPES: - OF A WIDTH EXCEEDING 6.5 MM : OTHER"
    },
    {
      "code": "85246000",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - CARDS INCORPORATING A MAGNETIC STRIPE"
    },
    {
      "code": "85249111",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: INFORMATION TECHNOLOGY SOFTWARE ON FLOPPY DISC OR CARTRIDGE TAPE"
    },
    {
      "code": "85249112",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: INFORMATION TECHNOLOGY SOFTWARE ON DISC OR ON CD ROM"
    },
    {
      "code": "85249113",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: INFORMATION TECHNOLOGY SOFTWARE ON OTHER MEDIA"
    },
    {
      "code": "85249119",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: - SOFTWARE: OTHER"
    },
    {
      "code": "85249190",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - FOR REPRODUCING PHENOMENA OTHER THAN SOUND OR IMAGE: OTHER"
    },
    {
      "code": "85249910",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - OTHER : AUDIO-VISUAL NEWS OR AUDIO-VISUAL VIEWS MATERIAL INCLUDING NEWS CLIPPINGS"
    },
    {
      "code": "85249920",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - OTHER : 2-D/3D COMPUTER GRAPHICS"
    },
    {
      "code": "85249930",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - OTHER : STAMPER FOR CD AUDIO, CD VIDEO AND CD-ROM"
    },
    {
      "code": "85249991",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - OTHER : - OTHER: MATRICES FOR THE PRODUCTION OF RECORDS; PREPARED RECORD BLANKS"
    },
    {
      "code": "85249999",
      "description": "RECORDS, TAPES AND OTHER RECORDED MEDIA FOR SOUND OR OTHER SIMILARLY RECORDED PHENOMENA, INCLUDING MATRICES AND MASTERS FOR THE PRODUCTION OF RECORDS, BUT EXCLUDING PRODUCTS OF CHAPTER 37 - OTHER: - OTHER : - OTHER: OTHER"
    },
    {
      "code": "8525",
      "description": "TRANSMISSION APPARATUS FOR RADIOBROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATION RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS ; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS"
    },
    {
      "code": "85251010",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: RADIO BROADCAST TRANSMITTER"
    },
    {
      "code": "85251020",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: TV BROADCAST TRANSMITTER"
    },
    {
      "code": "85251030",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: BROADCAST EQUIPMENT SUB-SYSTEM"
    },
    {
      "code": "85251040",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85251050",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: WIRELESS MICROPHONE"
    },
    {
      "code": "85251090",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS: OTHER"
    },
    {
      "code": "85252011",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: WALKIE TALKIE SET"
    },
    {
      "code": "85252012",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: CORDLESS HANDSET"
    },
    {
      "code": "85252013",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: CAR TELEPHONE"
    },
    {
      "code": "85252014",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: TRANSPORTABLE TELEPHONE"
    },
    {
      "code": "85252015",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: MARINE RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85252016",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: AMATEUR RADIO EQUIPMENT"
    },
    {
      "code": "85252017",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: CELLULAR TELEPHONE"
    },
    {
      "code": "85252019",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - TWO WAY RADIO COMMUNICATION EQUIPMENT: OTHER"
    },
    {
      "code": "85252091",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - OTHER: VSAT TERMINALS"
    },
    {
      "code": "85252092",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - OTHER: OTHER SATELLITE COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85252099",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS: - OTHER: OTHER"
    },
    {
      "code": "85253000",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERASTELEVISION CAMERAS"
    },
    {
      "code": "85254000",
      "description": "TRANSMISSION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY, RADIO-BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS; STILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERASSTILL IMAGE VIDEO CAMERAS AND OTHER VIDEO CAMERA RECORDERS; DIGITAL CAMERAS"
    },
    {
      "code": "852550",
      "description": "Transmission apparatus"
    },
    {
      "code": "85255010",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS --- RADIO BROADCAST TRANSMITTER"
    },
    {
      "code": "85255020",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS --- TV BROADCAST TRANSMITTER"
    },
    {
      "code": "85255030",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS --- BROADCAST EQUIPMENT SUB-SYSTEM"
    },
    {
      "code": "85255040",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS --- COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85255050",
      "description": "Wireless microphone"
    },
    {
      "code": "85255090",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS --- OTHER"
    },
    {
      "code": "852560",
      "description": "Transmission apparatus incorporating reception apparatus"
    },
    {
      "code": "85256000",
      "description": "Transmission apparatus incorporating reception apparatus"
    },
    {
      "code": "85256011",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- TWO WAY RADIO COMMUNICATION EQUIPMENT ---- WALKIE TALKIE SET"
    },
    {
      "code": "85256012",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- TWO WAY RADIO COMMUNICATION EQUIPMENT ---- MARINE RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85256013",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- TWO WAY RADIO COMMUNICATION EQUIPMENT ---- AMATEUR RADIO EQUIPMENT"
    },
    {
      "code": "85256019",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- TWO WAY RADIO COMMUNICATION EQUIPMENT ---- OTHER"
    },
    {
      "code": "85256091",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- OTHER ---- VSAT TERMINALS"
    },
    {
      "code": "85256092",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- OTHER ---- OTHER SATELLITE COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85256099",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TRANSMISSION APPARATUS INCORPORATING RECEPTION APPARATUS --- OTHER ---- OTHER"
    },
    {
      "code": "852580",
      "description": "Television cameras, digital cameras and video camera recorders"
    },
    {
      "code": "85258010",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS --- TELEVISION CAMERAS"
    },
    {
      "code": "85258020",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS --- DIGITAL CAMERAS"
    },
    {
      "code": "85258030",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS --- VIDEO CAMERA RECORDERS"
    },
    {
      "code": "85258090",
      "description": "TRANSMISSION APPARATUS FOR RADIO BROADCASTING OR TELEVISION, WHETHER OR NOT INCORPORATING RECEPTION APPARATUS OR SOUND RECORDING OR REPRODUCING APPARATUS; TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS - TELEVISION CAMERAS, DIGITAL CAMERAS AND VIDEO CAMERA RECORDERS --- OTHER"
    },
    {
      "code": "8526",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS"
    },
    {
      "code": "85261000",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS RADAR APPARATUS"
    },
    {
      "code": "852691",
      "description": "Radio navigational aid apparatus"
    },
    {
      "code": "85269110",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS - OTHER: - RADIO NAVIGATIONAL AID APPARATUS: DIRECTION MEASURING EQUIPMENT"
    },
    {
      "code": "85269120",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS - OTHER: - RADIO NAVIGATIONAL AID APPARATUS: INSTRUMENT LANDING SYSTEM"
    },
    {
      "code": "85269130",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS - OTHER: - RADIO NAVIGATIONAL AID APPARATUS: DIRECTION FINDING EQUIPMENT"
    },
    {
      "code": "85269140",
      "description": "NON-DIRECTIONAL BEACON"
    },
    {
      "code": "85269150",
      "description": "VHF OMNI RANGE EQUIPMENT"
    },
    {
      "code": "85269190",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS - OTHER: - RADIO NAVIGATIONAL AID APPARATUS: OTHER"
    },
    {
      "code": "85269200",
      "description": "RADAR APPARATUS, RADIO NAVIGATIONAL AID APPARATUS AND RADIO REMOTE CONTROL APPARATUS - OTHER: RADIO REMOTE CONTROL APPARATUS"
    },
    {
      "code": "8527",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - Radio-broadcast receivers capable of operating without an external source of power"
    },
    {
      "code": "85271200",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - RADIO-BROADCAST RECEIVERS CAPABLE OF OPERATING WITHOUT AN EXTERNAL SOURCE OF POWER -- POCKET-SIZE RADIO CASSETTE-PLAYERS"
    },
    {
      "code": "85271300",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - RADIO-BROADCAST RECEIVERS CAPABLE OF OPERATING WITHOUT AN EXTERNAL SOURCE OF POWER -- OTHER APPARATUS COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "85271900",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - RADIO-BROADCAST RECEIVERS CAPABLE OF OPERATING WITHOUT AN EXTERNAL SOURCE OF POWER -- OTHER"
    },
    {
      "code": "85272100",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - RADIO RECEIVERS NOT CAPABLE OF OPERATING WITHOUT AN EXTERNAL SOURCE OF POWER, OF A KIND USED IN MOTOR VEHICLES -- COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "85272900",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK -- OTHER"
    },
    {
      "code": "85273100",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER RADIO-BROADCAST RECEIVERS, INCLUDING APPARATUS CAPABLE OF RECEIVING ALSO RADIOTELEPHONY OR RADIO-TELEGRAPHY: COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "85273200",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER RADIO-BROADCAST RECEIVERS, INCLUDING APPARATUS CAPABLE OF RECEIVING ALSO RADIOTELEPHONY OR RADIO-TELEGRAPHY: NOT COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS BUT COMBINED WITH A CLOCK"
    },
    {
      "code": "85273900",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER RADIO-BROADCAST RECEIVERS, INCLUDING APPARATUS CAPABLE OF RECEIVING ALSO RADIOTELEPHONY OR RADIO-TELEGRAPHY: OTHER"
    },
    {
      "code": "85279011",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER APPARATUS: - RADIO COMMUNICATION RECEIVERS: RADIO PAGERS"
    },
    {
      "code": "85279012",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER APPARATUS: - RADIO COMMUNICATION RECEIVERS: DEMODULATORS"
    },
    {
      "code": "85279019",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER APPARATUS: - RADIO COMMUNICATION RECEIVERS: OTHER"
    },
    {
      "code": "85279090",
      "description": "RECEPTION APPARATUS FOR RADIO-TELEPHONY, RADIOTELEGRAPHY OR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER APPARATUS: OTHER"
    },
    {
      "code": "85279100",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "85279200",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- NOT COMBINED WITH SOUND RECORDING OR REPRODUCING APPARATUS BUT COMBINED WITH A CLOCK"
    },
    {
      "code": "852799",
      "description": "Other"
    },
    {
      "code": "85279900",
      "description": "Other"
    },
    {
      "code": "85279911",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- OTHER --- RADIO COMMUNICATION RECEIVERS ---- RADIO PAGERS"
    },
    {
      "code": "85279912",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- OTHER --- RADIO COMMUNICATION RECEIVERS ---- DEMODULATORS"
    },
    {
      "code": "85279919",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- OTHER --- RADIO COMMUNICATION RECEIVERS ---- OTHER"
    },
    {
      "code": "85279990",
      "description": "RECEPTION APPARATUS FOR RADIO-BROADCASTING, WHETHER OR NOT COMBINED, IN THE SAME HOUSING, WITH SOUND RECORDING OR REPRODUCING APPARATUS OR A CLOCK - OTHER -- OTHER --- OTHER"
    },
    {
      "code": "8528",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS, RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCASTRECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - Cathode-ray tube monitors"
    },
    {
      "code": "85281211",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE UPTO 36 CM"
    },
    {
      "code": "85281212",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 36 CM BUT NOT EXCEEDING 54 CM"
    },
    {
      "code": "85281213",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 54 CM BUT NOT EXCEEDING 68 CM"
    },
    {
      "code": "85281214",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 68 CM BUT NOT EXCEEDING 74 CM"
    },
    {
      "code": "85281215",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 74 CM BUT NOT EXCEEDING 87 CM"
    },
    {
      "code": "85281216",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 87 CM BUT NOT EXCEEDING 105 CM"
    },
    {
      "code": "85281217",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : TELEVISION SET OF SCREEN SIZE EXCEEDING 105 CM"
    },
    {
      "code": "85281218",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : LIQUID CRYSTAL DISPLAY TELEVISION SET OF SCREEN SIZE BELOW 63 CM"
    },
    {
      "code": "85281219",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : OTHER"
    },
    {
      "code": "85281291",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : OTHER - SATELLITE RECEIVERS"
    },
    {
      "code": "85281299",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: - COLOUR : OTHER - OTHER"
    },
    {
      "code": "85281310",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: BLACK AND WHITE OR OTHER MONOCHROME: LIQUID CRYSTAL DISPLAY TELEVISION SET OF SCREEN SIZE BELOW 25 CM"
    },
    {
      "code": "85281390",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS: BLACK AND WHITE OR OTHER MONOCHROME: OTHER"
    },
    {
      "code": "85282110",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO MONITORS: - COLOUR: PROFESSIONAL MONITORS FOR STUDIO USE (OF RESOLUTION 800 LINES AND ABOVE)"
    },
    {
      "code": "85282190",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO MONITORS: - COLOUR: OTHER"
    },
    {
      "code": "85282200",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO MONITORS: BLACK AND WHITE OR OTHER MONOCHROME"
    },
    {
      "code": "85283010",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO PROJECTORS: COLOUR, WITH FLAT PANEL SCREEN"
    },
    {
      "code": "85283020",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO PROJECTORS: COLOUR"
    },
    {
      "code": "85283030",
      "description": "RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS; VIDEO MONITORS AND VIDEO PROJECTORS - VIDEO PROJECTORS: BLACK AND WHITE OR OTHER MONOCHOROME"
    },
    {
      "code": "85284100",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - CATHODE-RAY TUBE MONITORS -- OF A KIND SOLELY OR PRINCIPALLY USED IN AN AUTOMATIC DATA PROCESSING SYSTEM OF HEADING 8471"
    },
    {
      "code": "85284200",
      "description": "Capable of directly connecting to and designed for use with an automatic data processing machine of heading 8471"
    },
    {
      "code": "85284900",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - CATHODE-RAY TUBE MONITORS -- OTHER"
    },
    {
      "code": "85285100",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - OTHER MONITORS -- OF A KIND SOLELY OR PRINCIPALLY USED IN AN AUTOMATIC DATA PROCESSING SYSTEM OF HEADING 8471"
    },
    {
      "code": "85285200",
      "description": "Capable of directly connecting to and designed for use with an automatic data processing machine of heading 8471"
    },
    {
      "code": "85285900",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - OTHER MONITORS -- OTHER"
    },
    {
      "code": "85286100",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - PROJECTORS"
    },
    {
      "code": "85286200",
      "description": "Capable of directly connecting to and designed for use with an automatic data processing machine of heading 8471"
    },
    {
      "code": "85286900",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - PROJECTORS -- OTHER"
    },
    {
      "code": "85287100",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS -- NOT DESIGNED TO INCORPORATE A VIDEO DISPLAY OR SCREEN"
    },
    {
      "code": "852872",
      "description": "Other, colour"
    },
    {
      "code": "85287211",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE UPTO 36 CM"
    },
    {
      "code": "85287212",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECO"
    },
    {
      "code": "85287213",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE EXCEEDING 54 CM BUT NOT EXCEEDING 68 CM"
    },
    {
      "code": "85287214",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE EXCEEDING 68 CM BUT NOT EXCEEDING 74 CM"
    },
    {
      "code": "85287215",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE EXCEEDING 74 CM BUT NOT EXCEEDING 87 CM"
    },
    {
      "code": "85287216",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE EXCEEDING 87 CM BUT NOT EXCEEDING 105 CM"
    },
    {
      "code": "85287217",
      "description": "SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- TELEVISION SET OF SCREEN SIZE  EXCEEDING 105 CM"
    },
    {
      "code": "85287218",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- LIQUID CRYSTAL DISPLAY TELEVISION SET OF SCREEN SIZE BELOW 63 CM"
    },
    {
      "code": "85287219",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, COLOUR --- OTHER"
    },
    {
      "code": "852873",
      "description": "Other, black and white or other monochrome"
    },
    {
      "code": "85287310",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER, BLACK AND WHITE OR OTHER MONOCHROME ---LIQUID CRYSTAL DISPLAY TELEVISIONSET OF SCREEN SIZE BELOW 25 CM"
    },
    {
      "code": "85287390",
      "description": "MONITORS AND PROJECTORS, NOT INCORPORATING TELEVISION RECEPTION APPARATUS. RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVER OR SOUND OR VIDEO RECORDING OR REPRODUCING APPARATUS - RECEPTION APPARATUS FOR TELEVISION, WHETHER OR NOT INCORPORATING RADIO-BROADCAST RECEIVERS OR SOUND OR VIDEO RECORDING ON REPRODUCING APPARATUS -- OTHER"
    },
    {
      "code": "8529",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528"
    },
    {
      "code": "852910",
      "description": "Aerials and aerial reflectors of all kinds; parts suitable for use therewith"
    },
    {
      "code": "85291011",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - DISH ANTENNA: FOR COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85291012",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - DISH ANTENNA: FOR AMATEUR RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85291019",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - DISH ANTENNA: OTHER"
    },
    {
      "code": "85291021",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - OTHER AERIALS OR ANTENNA: FOR COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85291022",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - OTHER AERIALS OR ANTENNA: FOR AMATEUR RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85291029",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: - OTHER AERIALS OR ANTENNA: OTHER"
    },
    {
      "code": "85291091",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: OTHER: FOR COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85291092",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: OTHER: FOR AMATEUR RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85291099",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 -AERIALS AND AERIAL REFLECTORS OF ALL KINDS; PARTS SUITABLE FOR USE THEREWITH: OTHER: OTHER"
    },
    {
      "code": "852990",
      "description": "Other"
    },
    {
      "code": "85299010",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 - OTHER: FOR COMMUNICATION JAMMING EQUIPMENT"
    },
    {
      "code": "85299020",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 - OTHER: FOR AMATEUR RADIO COMMUNICATION EQUIPMENT"
    },
    {
      "code": "85299030",
      "description": "Open cell for television set"
    },
    {
      "code": "85299090",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8525 TO 8528 - OTHER: OTHER"
    },
    {
      "code": "8530",
      "description": "ELECTRICAL SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATIONS OR AIRFIELDS (OTHER THAN THOSE OF HEADING 8608)"
    },
    {
      "code": "853010",
      "description": "Equipment for railways or tramways"
    },
    {
      "code": "85301010",
      "description": "ELECTRICAL SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATIONS OR AIRFIELDS (OTHER THAN THOSE OF HEADING 8608) -EQUIPMENT FOR RAILWAYS OR TRAMWAYS: FOR RAILWAYS"
    },
    {
      "code": "85301020",
      "description": "ELECTRICAL SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATIONS OR AIRFIELDS (OTHER THAN THOSE OF HEADING 8608) -EQUIPMENT FOR RAILWAYS OR TRAMWAYS: FOR TRAMWAYS"
    },
    {
      "code": "85308000",
      "description": "ELECTRICAL SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATIONS OR AIRFIELDS (OTHER THAN THOSE OF HEADING 8608) - OTHER EQUIPMENT"
    },
    {
      "code": "85309000",
      "description": "ELECTRICAL SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATIONS OR AIRFIELDS (OTHER THAN THOSE OF HEADING 8608) - PARTS"
    },
    {
      "code": "8531",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530"
    },
    {
      "code": "853110",
      "description": "Burglar or fire alarms and similar apparatus"
    },
    {
      "code": "85311010",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 -BURGLAR OR FIRE ALARMS AND SIMILAR APPARATUS: BURGLAR ALARM"
    },
    {
      "code": "85311020",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 -BURGLAR OR FIRE ALARMS AND SIMILAR APPARATUS: FIRE ALARM"
    },
    {
      "code": "85311090",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 - BURGLAR OR FIRE ALARMS AND SIMILAR APPARATUS: OTHER"
    },
    {
      "code": "85312000",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 INDICATOR PANELS INCORPORATING LIQUID CRYSTALDEVICES (LCD) OR LIGHT EMITTING DIODES (LED)"
    },
    {
      "code": "85318000",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 OTHER APPARATUS"
    },
    {
      "code": "85319000",
      "description": "ELECTRIC SOUND OR VISUAL SIGNALLING APPARATUS (FOR EXAMPLE, BELLS, SIRENS, INDICATOR PANELS, BURGLAR OR FIRE ALARMS), OTHER THAN THOSE OF HEADING 8512 OR 8530 PARTS"
    },
    {
      "code": "8532",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET)"
    },
    {
      "code": "85321000",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) FIXED CAPACITORS DESIGNED FOR USE IN 50 OR 60 HZ CIRCUITS AND HAVING A REACTIVE POWER HANDLING CAPACITY OF NOT LESS THAN 0.5 KVAR (POWER CAPACITORS)"
    },
    {
      "code": "85322100",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: TANTALUM"
    },
    {
      "code": "85322200",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: ALUMINIUM ELECTROLYTIC"
    },
    {
      "code": "85322300",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: CERAMIC DIELECTRIC, SINGLE LAYER"
    },
    {
      "code": "85322400",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: CERAMIC DIELECTRIC, MULTILAYER"
    },
    {
      "code": "85322500",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: DIELECTRIC OF PAPER OR PLASTICS"
    },
    {
      "code": "853229",
      "description": "Other"
    },
    {
      "code": "85322910",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: - OTHER: OF DIELECTRIC OF MICA"
    },
    {
      "code": "85322990",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) - OTHER FIXED CAPACITORS: - OTHER: OTHER"
    },
    {
      "code": "85323000",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) VARIABLE OR ADJUSTABLE (PRE-SET) CAPACITORS"
    },
    {
      "code": "85329000",
      "description": "ELECTRICAL CAPACITORS, FIXED, VARIABLE OR ADJUSTABLE (PRE-SET) PARTS"
    },
    {
      "code": "8533",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS"
    },
    {
      "code": "85331000",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS FIXED CARBON RESISTORS, COMPOSITION OR FILM TYPES"
    },
    {
      "code": "853321",
      "description": "For a power handling capacity not exceeding 20 W"
    },
    {
      "code": "85332111",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: - OF BARE WIRE : OF NICHROME"
    },
    {
      "code": "85332119",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: - OF BARE WIRE : OTHER"
    },
    {
      "code": "85332121",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: - OF INSULATED WIRE : OF NICHROME"
    },
    {
      "code": "85332129",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: - OF INSULATED WIRE : OTHER"
    },
    {
      "code": "853329",
      "description": "Other"
    },
    {
      "code": "85332911",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - OTHER: - OF BARE WIRE: OF NICHROME"
    },
    {
      "code": "85332919",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - OTHER: - OF BARE WIRE: OTHER"
    },
    {
      "code": "85332921",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - OTHER: - OF INSULATED WIRE : OF NICHROME"
    },
    {
      "code": "85332929",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER FIXED RESISTORS: - OTHER: - OF INSULATED WIRE : OTHER"
    },
    {
      "code": "853331",
      "description": "For a power handling capacity not exceeding 20 W"
    },
    {
      "code": "85333110",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: POTENTIOMETERS"
    },
    {
      "code": "85333120",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: RHEOSTATS"
    },
    {
      "code": "85333190",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - FOR A POWER HANDLING CAPACITY NOT EXCEEDING 20 W: OTHER"
    },
    {
      "code": "853339",
      "description": "Other"
    },
    {
      "code": "85333910",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - OTHER: POTENTIOMETERS"
    },
    {
      "code": "85333920",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - OTHER: RHEOSTATS"
    },
    {
      "code": "85333990",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - WIREWOUND VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: - OTHER: OTHER"
    },
    {
      "code": "853340",
      "description": "Other variable resistors, including rheostats and potentiometers"
    },
    {
      "code": "85334010",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: POTENTIOMETERS"
    },
    {
      "code": "85334020",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: RHEOSTATS"
    },
    {
      "code": "85334030",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: THERMISTORS"
    },
    {
      "code": "85334090",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - OTHER VARIABLE RESISTORS, INCLUDING RHEOSTATS AND POTENTIOMETERS: OTHER"
    },
    {
      "code": "85339000",
      "description": "ELECTRICAL RESISTORS (INCLUDING RHEOSTATS AND POTENTIOMETERS), OTHER THAN HEATING RESISTORS - PARTS"
    },
    {
      "code": "8534",
      "description": "PRINTED CIRCUITS"
    },
    {
      "code": "85340000",
      "description": "PRINTED CIRCUITS"
    },
    {
      "code": "8535",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS AND OTHER CONNECTORS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS"
    },
    {
      "code": "853510",
      "description": "Fuses"
    },
    {
      "code": "85351010",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: FOR SWITCHES HAVING RATING UPTO 15 AMPS, REWIREABLE"
    },
    {
      "code": "85351020",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: FOR SWITCHES HAVING RATING ABOVE 15 AMPS, HIGH RUPTURING CAPACITY OR REWIREABLE"
    },
    {
      "code": "85351030",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: OTHER REWIREABLE FUSES"
    },
    {
      "code": "85351040",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: OTHER HIGH RUPTURING CAPACITY FUSES"
    },
    {
      "code": "85351050",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: FUSES GEAR"
    },
    {
      "code": "85351090",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS -FUSES: OTHER"
    },
    {
      "code": "853521",
      "description": "For a voltage of less than 72.5 kV"
    },
    {
      "code": "85352111",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - SF6 CIRCUIT BREAKERS : FOR A VOLTAGE OF 11 KV"
    },
    {
      "code": "85352112",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - SF6 CIRCUIT BREAKERS : FOR A VOLTAGE OF 33 KV"
    },
    {
      "code": "85352113",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - SF6 CIRCUIT BREAKERS : FOR A VOLTAGE OF 66 KV"
    },
    {
      "code": "85352119",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - SF6 CIRCUIT BREAKERS : OTHER"
    },
    {
      "code": "85352121",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 11 KV"
    },
    {
      "code": "85352122",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 33 KV"
    },
    {
      "code": "85352123",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 66 KV"
    },
    {
      "code": "85352129",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: - VACUUM CIRCUIT BREAKERS : OTHER"
    },
    {
      "code": "85352190",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - FOR A VOLTAGE OF LESS THAN 72.5 KV: OTHER"
    },
    {
      "code": "853529",
      "description": "Other"
    },
    {
      "code": "85352911",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - SF6 CIRCUITS BREAKERS : FOR A VOLTAGE OF 132 KV"
    },
    {
      "code": "85352912",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - SF6 CIRCUITS BREAKERS : FOR A VOLTAGE OF 220 KV"
    },
    {
      "code": "85352913",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - SF6 CIRCUITS BREAKERS : FOR A VOLTAGE OF 400 KV"
    },
    {
      "code": "85352919",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - SF6 CIRCUITS BREAKERS : OTHER"
    },
    {
      "code": "85352921",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 132 KV"
    },
    {
      "code": "85352922",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 220 KV"
    },
    {
      "code": "85352923",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - VACUUM CIRCUIT BREAKERS : FOR A VOLTAGE OF 400 KV"
    },
    {
      "code": "85352929",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : - VACUUM CIRCUIT BREAKERS : OTHER"
    },
    {
      "code": "85352990",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - AUTOMATIC CIRCUIT BREAKERS: - OTHER : OTHER"
    },
    {
      "code": "853530",
      "description": "Isolating switches and make-and-break switches"
    },
    {
      "code": "85353010",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - ISOLATING SWITCHES AND MAKE-AND-BREAK SWITCHES: OF PLASTIC"
    },
    {
      "code": "85353090",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - ISOLATING SWITCHES AND MAKE-AND-BREAK SWITCHES: OTHER"
    },
    {
      "code": "853540",
      "description": "Lightning arresters, voltage limiters and"
    },
    {
      "code": "85354010",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - LIGHTNING ARRESTERS, VOLTAGE LIMITERS AND SURGE SUPPRESSORS: LIGHTNING ARRESTERS"
    },
    {
      "code": "85354020",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - LIGHTNING ARRESTERS, VOLTAGE LIMITERS AND SURGE SUPPRESSORS: VOLTAGE LIMITERS"
    },
    {
      "code": "85354030",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - LIGHTNING ARRESTERS, VOLTAGE LIMITERS AND SURGE SUPPRESSORS: SURGE SUPPRESSORS"
    },
    {
      "code": "853590",
      "description": "Other"
    },
    {
      "code": "85359010",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - OTHER : MOTOR STARTERS FOR AC MOTORS"
    },
    {
      "code": "85359020",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - OTHER : CONTROL GEAR AND STARTERS FOR DC MOTORS"
    },
    {
      "code": "85359030",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - OTHER : OTHER CONTROL AND SWITCHGEARS"
    },
    {
      "code": "85359040",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - OTHER : JUNCTION BOXES"
    },
    {
      "code": "85359090",
      "description": "FUSES, LIGHTNING ARRESTERS, VOLTAGE LIMITERS, SURGE SUPPRESSORS, PLUGS, JUNCTION BOXES), FOR A VOLTAGE EXCEEDING 1,000 VOLTS - OTHER : OTHER"
    },
    {
      "code": "8536",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS AND OTHER CONNECTORS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS: CONNECTORS FOR OPTICAL FIBRES, OPTICAL FIBER BUNDLES OR CABLES."
    },
    {
      "code": "853610",
      "description": "Fuses"
    },
    {
      "code": "85361010",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: FOR SWITCHES HAVING RATING UPTO 15 AMPS, REWIREABLE"
    },
    {
      "code": "85361020",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: FOR SWITCHES HAVING RATING ABOVE 15 AMPS, HIGH RUPTURING CAPACITY OR REWIREABLE"
    },
    {
      "code": "85361030",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: OTHER REWIREABLE FUSES"
    },
    {
      "code": "85361040",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: OTHER HIGH RUPTURING CAPACITY FUSES"
    },
    {
      "code": "85361050",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: FUSES GEAR"
    },
    {
      "code": "85361060",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: ELECTRONIC FUSES"
    },
    {
      "code": "85361090",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - FUSES: OTHER"
    },
    {
      "code": "853620",
      "description": "Automatic circuit breakers"
    },
    {
      "code": "85362010",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS -AUTOMATIC CIRCUIT BREAKERS: AIR CIRCUIT BREAKERS"
    },
    {
      "code": "85362020",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS -AUTOMATIC CIRCUIT BREAKERS: MOULDED CASE CIRCUIT BREAKERS"
    },
    {
      "code": "85362030",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS -AUTOMATIC CIRCUIT BREAKERS: MINIATURE CIRCUIT BREAKERS"
    },
    {
      "code": "85362040",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS -AUTOMATIC CIRCUIT BREAKERS: EARTH LEAK CIRCUIT BREAKERS"
    },
    {
      "code": "85362090",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS -AUTOMATIC CIRCUIT BREAKERS: OTHER"
    },
    {
      "code": "85363000",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS OTHER APPARATUS FOR PROTECTING ELECTRICAL CIRCUITS"
    },
    {
      "code": "85364100",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - RELAYS: FOR A VOLTAGE NOT EXCEEDING 60 V"
    },
    {
      "code": "85364900",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - RELAYS: OTHER"
    },
    {
      "code": "853650",
      "description": "Other switches"
    },
    {
      "code": "85365010",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER SWITCHES: CONTROL AND SWITCH GEARS"
    },
    {
      "code": "85365020",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER SWITCHES: OTHER SWITCHES OF PLASTIC"
    },
    {
      "code": "85365090",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER SWITCHES: OTHER"
    },
    {
      "code": "853661",
      "description": "Lamp-holders"
    },
    {
      "code": "85366110",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - LAMP-HOLDERS, PLUGS AND SOCKETS: - LAMP-HOLDERS: OF PLASTIC"
    },
    {
      "code": "85366190",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - LAMP-HOLDERS, PLUGS AND SOCKETS: - LAMP-HOLDERS: OF OTHER MATERIALS"
    },
    {
      "code": "853669",
      "description": "Other"
    },
    {
      "code": "85366910",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - LAMP-HOLDERS, PLUGS AND SOCKETS: - OTHER: OF PLASTIC"
    },
    {
      "code": "85366990",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - LAMP-HOLDERS, PLUGS AND SOCKETS: - OTHER: OF OTHER MATERIALS"
    },
    {
      "code": "85367000",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - CONNECTORS FOR OPTICAL FIBRES,OPTICAL FIBRE BUNDLES OR CABLES"
    },
    {
      "code": "853690",
      "description": "Other apparatus"
    },
    {
      "code": "85369010",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER APPARATUS: MOTOR STARTERS FOR AC MOTORS"
    },
    {
      "code": "85369020",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER APPARATUS: MOTOR STARTERS FOR DC MOTORS"
    },
    {
      "code": "85369030",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER APPARATUS: JUNCTION BOXES"
    },
    {
      "code": "85369090",
      "description": "ELECTRICAL APPARATUS FOR SWITCHING OR PROTECTING ELECTRICAL CIRCUITS, OR FOR MAKING CONNECTIONS TO OR IN ELECTRICAL CIRCUITS (FOR EXAMPLE, SWITCHES, RELAYS, FUSES, SURGE SUPPRESSORS, PLUGS, SOCKETS, LAMP-HOLDERS, JUNCTION BOXES), FOR A VOLTAGE NOT EXCEEDING 1,000 VOLTS - OTHER APPARATUS: OTHER"
    },
    {
      "code": "8537",
      "description": "BOARDS, PANELS, CONSOLES, DESKS, CABINETS AND OTHER BASES, EQUIPPED WITH TWO OR MORE APPARATUS OF HEADING 8535 OR 8536, FOR ELECTRIC CONTROL OR THE DISTRIBUTION OF ELECTRICITY, INCLUDING THOSE INCORPORATING INSTRUMENTS OR APPARATUS OF CHAPTER 90, AND NUMERICAL CONTROL APPARATUS, OTHER THAN SWITCHING APPARATUS OF HEADING 8517"
    },
    {
      "code": "85371000",
      "description": "BOARDS, PANELS, CONSOLES, DESKS, CABINETS AND OTHER BASES, EQUIPPED WITH TWO OR MORE APPARATUS OF HEADING 8535 OR 8536, FOR ELECTRIC CONTROL OR THE DISTRIBUTION OF ELECTRICITY, INCLUDING THOSE INCORPORATING INSTRUMENTS OR APPARATUS OF CHAPTER 90, AND NUMERICAL CONTROL APPARATUS, OTHER THAN SWITCHING APPARATUS OF HEADING 8517 FOR A VOLTAGE NOT EXCEEDING 1,000 V"
    },
    {
      "code": "85372000",
      "description": "BOARDS, PANELS, CONSOLES, DESKS, CABINETS AND OTHER BASES, EQUIPPED WITH TWO OR MORE APPARATUS OF HEADING 8535 OR 8536, FOR ELECTRIC CONTROL OR THE DISTRIBUTION OF ELECTRICITY, INCLUDING THOSE INCORPORATING INSTRUMENTS OR APPARATUS OF CHAPTER 90, AND NUMERICAL CONTROL APPARATUS, OTHER THAN SWITCHING APPARATUS OF HEADING 8517 FOR A VOLTAGE EXCEEDING 1,000 V"
    },
    {
      "code": "8538",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8535, 8536 OR 8537"
    },
    {
      "code": "853810",
      "description": "Boards, panels, consoles, desks, cabinets and other bases for the goods of heading 8537, not equipped with their apparatus"
    },
    {
      "code": "85381010",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8535, 8536 OR 8537 -BOARDS, PANELS, CONSOLES, DESKS, CABINETS AND OTHER BASES FOR THE GOODS OF HEADING 8537, NOT EQUIPPED WITH THEIR APPARATUS: FOR INDUSTRIAL USE"
    },
    {
      "code": "85381090",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8535, 8536 OR 8537 -BOARDS, PANELS, CONSOLES, DESKS, CABINETS AND OTHER BASES FOR THE GOODS OF HEADING 8537, NOT EQUIPPED WITH THEIR APPARATUS: OTHER"
    },
    {
      "code": "85389000",
      "description": "PARTS SUITABLE FOR USE SOLELY OR PRINCIPALLY WITH THE APPARATUS OF HEADINGS 8535, 8536 OR 8537 OTHER"
    },
    {
      "code": "8539",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRAVIOLET OR INFRA-RED LAMPS; ARC-LAMPS"
    },
    {
      "code": "85391000",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS SEALED BEAM LAMP UNITS"
    },
    {
      "code": "853921",
      "description": "Tungsten halogen"
    },
    {
      "code": "85392110",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - TUNGSTEN HALOGEN: - OTHER: MINIATURE HALOGEN LAMPS WITH FITTINGS"
    },
    {
      "code": "85392120",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - TUNGSTEN HALOGEN: - OTHER: OTHER FOR AUTOMOBILES"
    },
    {
      "code": "85392190",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - TUNGSTEN HALOGEN: - OTHER: OTHER"
    },
    {
      "code": "85392200",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: OTHER, OF A POWER NOT EXCEEDING 200 W AND FOR A VOLTAGE EXCEEDING 100 V"
    },
    {
      "code": "853929",
      "description": "Other"
    },
    {
      "code": "85392910",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - OTHER: OF RETAIL SALE PRICE NOT EXCEEDING RUPEES 20 PER BULB"
    },
    {
      "code": "85392920",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - OTHER: BULB, FOR TORCHES"
    },
    {
      "code": "85392930",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - OTHER: MINIATURE BULBS"
    },
    {
      "code": "85392940",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - OTHER: OTHER FOR AUTOMOBILE LAMPS"
    },
    {
      "code": "85392990",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - OTHER FILAMENT LAMPS, EXCLUDING ULTRA-VIOLET OR INFRA-RED LAMPS: - OTHER: OTHER"
    },
    {
      "code": "853931",
      "description": "Fluorescent, hot cathode"
    },
    {
      "code": "85393110",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - FLUORESCENT, HOT CATHODE: COMPACT FLUORESCENT LAMPS"
    },
    {
      "code": "85393190",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - FLUORESCENT, HOT CATHODE: OTHER"
    },
    {
      "code": "853932",
      "description": "Mercury or sodium vapour lamps; metal halide lamps"
    },
    {
      "code": "85393210",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - MERCURY OR SODIUM VAPOUR LAMPS; METAL HALIDE LAMPS: MERCURY VAPOUR LAMPS"
    },
    {
      "code": "85393220",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - MERCURY OR SODIUM VAPOUR LAMPS; METAL HALIDE LAMPS: SODIUM VAPOUR LAMPS"
    },
    {
      "code": "85393230",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - MERCURY OR SODIUM VAPOUR LAMPS; METAL HALIDE LAMPS: METAL HALIDE LAMPS"
    },
    {
      "code": "853939",
      "description": "Other"
    },
    {
      "code": "85393910",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - OTHER: ENERGY EFFICIENT TRIPHOSPHOR FLUORESCENT LAMPS"
    },
    {
      "code": "85393990",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - DISCHARGE LAMPS, OTHER THAN ULTRA-VIOLET LAMPS: - OTHER: OTHER"
    },
    {
      "code": "85394100",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - ULTRA-VIOLET OR INFRA-RED LAMPS; ARC-LAMPS : ARC-LAMPS"
    },
    {
      "code": "85394900",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - ULTRA-VIOLET OR INFRA-RED LAMPS; ARC-LAMPS : OTHER"
    },
    {
      "code": "85395000",
      "description": "Light-emitting diode (LED) lamps"
    },
    {
      "code": "853990",
      "description": "Parts"
    },
    {
      "code": "85399010",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - PARTS: PARTS OF FLUORESCENT TUBE LAMPS"
    },
    {
      "code": "85399020",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - PARTS: PARTS OF ARC-LAMPS"
    },
    {
      "code": "85399090",
      "description": "ELECTRIC FILAMENT OR DISCHARGE LAMPS, INCLUDING SEALED BEAM LAMP UNITS AND ULTRA-VIOLET OR INFRA RED LAMPS; ARC-LAMPS - PARTS: OTHER"
    },
    {
      "code": "8540",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - Cathode-ray television picture tubes, including video monitor cathode-ray tubes"
    },
    {
      "code": "854011",
      "description": "Colour"
    },
    {
      "code": "85401110",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - CATHODE-RAY TELEVISION PICTURE TUBES, INCLUDING VIDEO MONITOR CATHODE-RAY TUBES: - COLOUR: TELEVISION PICTURE TUBES OF 20 AND 21 SIZE, EXCEPT 21 FLAT AND FULL SQUARE (F AND FST) COLOUR TV PICTURE TUBES"
    },
    {
      "code": "85401120",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - CATHODE-RAY TELEVISION PICTURE TUBES, INCLUDING VIDEO MONITOR CATHODE-RAY TUBES: - COLOUR: VIDEO MONITOR CATHODE-RAY TUBES"
    },
    {
      "code": "85401190",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - CATHODE-RAY TELEVISION PICTURE TUBES, INCLUDING VIDEO MONITOR CATHODE-RAY TUBES: - COLOUR: OTHER"
    },
    {
      "code": "854012",
      "description": "Monochrome"
    },
    {
      "code": "85401200",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - CATHODE-RAY TELEVISION PICTURE TUBES, INCLUDING VIDEO MONITOR CATHODE-RAY TUBES: BLACK AND WHITE OR OTHER MONOCHROME"
    },
    {
      "code": "85402000",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) TELEVISION CAMERA TUBES; IMAGE CONVERTERS AND INTENSIFIERS; OTHER PHOTOS-CATHODE TUBES"
    },
    {
      "code": "854040",
      "description": "Data or graphic display tubes, monochrine data or graphic display tubes, colour with a phosphor dot screen pitch smaller than 0.4 mm:"
    },
    {
      "code": "85404010",
      "description": "DATA OR GRAPHIC DISPLAY TUBES, MONOCHROME"
    },
    {
      "code": "85404020",
      "description": "DATA OR GRAPHIC DISPLAY TUBES, COLOUR, WITH A PHOSPHOR DOT SCREEN PITCH SMALLER THAN 0.4 MM"
    },
    {
      "code": "85406000",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) OTHER CATHODE-RAY TUBES"
    },
    {
      "code": "85407100",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - MICROWAVE TUBES (FOR EXAMPLE, MAGNETRONS, KLYSTRONS, TRAVELLING WAVE TUBES, CARCINOTRONS), EXCLUDING GRID-CONTROLLED TUBES: MAGNETRONS"
    },
    {
      "code": "85407900",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - MICROWAVE TUBES (FOR EXAMPLE, MAGNETRONS, KLYSTRONS, TRAVELLING WAVE TUBES, CARCINOTRONS), EXCLUDING GRID-CONTROLLED TUBES: OTHER"
    },
    {
      "code": "85408100",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - OTHER VALVES AND TUBES: RECEIVER OR AMPLIFIER VALVES AND TUBES"
    },
    {
      "code": "85408900",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - OTHER VALVES AND TUBES: OTHER"
    },
    {
      "code": "85409100",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - PARTS: OF CATHODE-RAY TUBES"
    },
    {
      "code": "85409900",
      "description": "THERMIONIC, COLD CATHODE OR PHOTO-CATHODE VALVES AND TUBES (FOR EXAMPLE, VACUUM OR VAPOUR OR GAS FILLED VALVES AND TUBES, MERCURY ARC RECTIFYING VALVES AND TUBES, CATHODE-RAY TUBES, TELEVISION CAMERA TUBES) - PARTS: OTHER"
    },
    {
      "code": "8541",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMICONDUCTOR DEVICES; PHOTOSENSITIVE SEMICONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS"
    },
    {
      "code": "85411000",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS DIODES, OTHER THAN PHOTOSENSITIVE OR LIGHT EMITTING DIODES"
    },
    {
      "code": "85412100",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS - TRANSISTORS, OTHER THAN PHOTOSENSITIVE TRANSISTORSWITH A DISSIPATION RATE OF LESS THAN 1 W"
    },
    {
      "code": "85412900",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS - TRANSISTORS, OTHER THAN PHOTOSENSITIVE TRANSISTORSOTHER"
    },
    {
      "code": "854130",
      "description": "Thyristors, diacs and triacs, other than photosensitive devices"
    },
    {
      "code": "85413010",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -THYRISTORS, DIACS AND TRIACS, OTHER THAN PHOTOSENSITIVE DEVICES: THYRISTORS"
    },
    {
      "code": "85413090",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -THYRISTORS, DIACS AND TRIACS, OTHER THAN PHOTOSENSITIVE DEVICES: OTHER"
    },
    {
      "code": "854140",
      "description": "Photosensitive semi-conductor devices, including photo voltaic cells whether or not assembled in modules or made up into panels; light-emitting diodes (LED)"
    },
    {
      "code": "85414011",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES: - PHOTOCELLS: SOLAR CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR PANELS"
    },
    {
      "code": "85414012",
      "description": "Solar cells, assembled in modules or made up into panels"
    },
    {
      "code": "85414019",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES: - PHOTOCELLS: OTHER"
    },
    {
      "code": "85414020",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES: LIGHT EMITTING DIODES (ELECTRO-LUMINESCENT)"
    },
    {
      "code": "85414090",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS -PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES: OTHER"
    },
    {
      "code": "85415000",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS OTHER SEMI-CONDUCTORS DEVICES"
    },
    {
      "code": "85416000",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS MOUNTED PIEZO-ELECTRIC CRYSTALS"
    },
    {
      "code": "85419000",
      "description": "DIODES, TRANSISTORS AND SIMILAR SEMI-CONDUCTOR DEVICES; PHOTOSENSITIVE SEMI-CONDUCTOR DEVICES, INCLUDING PHOTOVOLTAIC CELLS WHETHER OR NOT ASSEMBLED IN MODULES OR MADE UP INTO PANELS; LIGHT EMITTING DIODES; MOUNTED PIEZO-ELECTRIC CRYSTALS PARTS"
    },
    {
      "code": "8542",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - Electronic integrated circuits"
    },
    {
      "code": "85421010",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES -CARDS INCORPORATING AN ELECTRONIC INTEGRATED CIRCUIT *SMARV CARDS): SIM CARDS"
    },
    {
      "code": "85421020",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES -CARDS INCORPORATING AN ELECTRONIC INTEGRATED CIRCUIT *SMARV CARDS): MEMORY CARDS"
    },
    {
      "code": "85421090",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES -CARDS INCORPORATING AN ELECTRONIC INTEGRATED CIRCUIT *SMARV CARDS): OTHER"
    },
    {
      "code": "85422100",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES - MONOLITHIC INTEGRATED CIRCUITS: DIGITAL"
    },
    {
      "code": "85422910",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES - MONOLITHIC INTEGRATED CIRCUITS: - OTHER: CARDS INCORPORATING ONLY A SINGLE ELECTRONIC INTEGRATED CIRCUIT WITH OPTICAL STRIP"
    },
    {
      "code": "85422990",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES - MONOLITHIC INTEGRATED CIRCUITS: - OTHER: OTHER"
    },
    {
      "code": "85423100",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - ELECTRONIC INTEGRATED CIRCUITS -- PROCESSORS AND CONTROLLERS, WHETHER OR NOT COMBINED WITH MEMORIES, CONVERTERS, LOGIC CIRCUITS,AMPLIFIERS, CLOCK AND TIMING CIRCUITS, OR OTHER CIRCUITS"
    },
    {
      "code": "85423200",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - ELECTRONIC INTEGRATED CIRCUITS -- MEMORIES"
    },
    {
      "code": "85423300",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - ELECTRONIC INTEGRATED CIRCUITS - AMPLIFIERS"
    },
    {
      "code": "85423900",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - ELECTRONIC INTEGRATED CIRCUITS -- OTHER"
    },
    {
      "code": "85426000",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES HYBRID INTEGRATED CIRCUITS"
    },
    {
      "code": "85427000",
      "description": "ELECTRONIC INTEGRATED CIRCUITS AND MICRO-ASSEMBLIES ELECTRONIC MICROASSEMBLIES"
    },
    {
      "code": "85429000",
      "description": "ELECTRONIC INTEGRATED CIRCUITS - PARTS"
    },
    {
      "code": "8543",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDING ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "854310",
      "description": "Particle accelerators"
    },
    {
      "code": "85431010",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS --- ION IMPLANTERS FOR DOPING SEMI CONDUCTOR MATERIAL"
    },
    {
      "code": "85431020",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS --- VANE GRAFF, COCK CROFT, WALTON ACCELERATORS"
    },
    {
      "code": "85431030",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS --- SYNCHROCYCLOTRONS, SYNCHROTRONS"
    },
    {
      "code": "85431090",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS --- OTHER INCLUDING CYCLOTRONS"
    },
    {
      "code": "85431100",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS: ION IMPLANTERS FOR DOPING SEMI-CONDUCTOR MATERIALS"
    },
    {
      "code": "85431910",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS: - OTHER: VANE GRAFF, COCK-CROFT, WALTON ACCELERATORS"
    },
    {
      "code": "85431920",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS: - OTHER: SYNCHROCYCLOTRONS, SYNCHROTRONS"
    },
    {
      "code": "85431990",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTICLE ACCELERATORS: - OTHER: OTHER INCLUDING CYCLOTRONS"
    },
    {
      "code": "854320",
      "description": "Signal generators"
    },
    {
      "code": "85432010",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - SIGNAL GENERATORS: SWEEP GENERATORS"
    },
    {
      "code": "85432020",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - SIGNAL GENERATORS: IMPULSE GENERATORS"
    },
    {
      "code": "85432030",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - SIGNAL GENERATORS: TACHO GENERATORS"
    },
    {
      "code": "85432090",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - SIGNAL GENERATORS: OTHER"
    },
    {
      "code": "85433000",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER MACHINES AND APPARATUS FOR ELECTROPLATING, ELECTROLYSIS OR ELECTROPHORESIS"
    },
    {
      "code": "85434000",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER ELECTRIC FENCE ENERGISERS"
    },
    {
      "code": "854370",
      "description": "Other machines and apparatus"
    },
    {
      "code": "85437011",
      "description": "Proximity card and tags"
    },
    {
      "code": "85437012",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS ---- METAL DETECTOR"
    },
    {
      "code": "85437013",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS ---- MINE DETECTOR"
    },
    {
      "code": "85437019",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS ---- OTHER"
    },
    {
      "code": "85437021",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AUDIO SPECIAL EFFECT EQUIPMENT ---- DIGITAL REVERBERATORS"
    },
    {
      "code": "85437022",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AUDIO SPECIAL EFFECT EQUIPMENT ---- MIXING SYSTEM OR CONSOLES"
    },
    {
      "code": "85437029",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AUDIO SPECIAL EFFECT EQUIPMENT ---- OTHER"
    },
    {
      "code": "85437031",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- VIDEO MIXING SYSTEM OR CONSOLES"
    },
    {
      "code": "85437032",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- VIDEO EFFECT SYSTEM"
    },
    {
      "code": "85437033",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- DIGITAL LAYERING MACHINE"
    },
    {
      "code": "85437034",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- PAINT BOX"
    },
    {
      "code": "85437035",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- VIDEO TYPEWRITER"
    },
    {
      "code": "85437036",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- VIDEO MATTING MACHINE"
    },
    {
      "code": "85437039",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- VIDEO SPECIAL EFFECT EQUIPMENTS ---- OTHER"
    },
    {
      "code": "85437041",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- EDIT CONTROL UNIT ---- COMPUTERISED EDITING SYSTEM CONTROLLING MORE THAN THREE VIDEO EDITING MACHINES"
    },
    {
      "code": "85437042",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- EDIT CONTROL UNIT ---- OTHER VIDEO CONTROL UNIT"
    },
    {
      "code": "85437049",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- EDIT CONTROL UNIT ---- OTHER"
    },
    {
      "code": "85437050",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- EDIT CONTROL UNIT ---- COLOUR CORRECTOR"
    },
    {
      "code": "85437061",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AMPLIFIER ---- BROADCAST AMPLIFIER"
    },
    {
      "code": "85437062",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AMPLIFIER ---- LIMITING AMPLIFIER, VIDEO DISTRIBUTION AMPLIFIER AND STABILIZING AMPLIFIERS"
    },
    {
      "code": "85437069",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- AMPLIFIER ---- OTHER"
    },
    {
      "code": "85437071",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- GRAPHIC EQUALIZER AND SYNTHESIZED RECEIVERS ---- GRAPHIC EQUALIZER"
    },
    {
      "code": "85437072",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- GRAPHIC EQUALIZER AND SYNTHESIZED RECEIVERS ---- SYNTHESISED , RECEIVERS"
    },
    {
      "code": "85437091",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- RF (RADIO FREQUENCY) POWER AMPLIFIER AND NOISE GENERATORS FOR COMMUNICATION JAMMING EQUIPMENT STATIC AND MOBILE OR MAN-PORTABLE"
    },
    {
      "code": "85437092",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- EQUIPMENT GADGETS BASED ON SOLAR ENERGY"
    },
    {
      "code": "85437093",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- PROFESSIONAL BEAUTY CARE EQUIPMENT"
    },
    {
      "code": "85437094",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- AUDIO VISUAL STEREO ENCODERS"
    },
    {
      "code": "85437095",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- TIME CODE GENERATOR"
    },
    {
      "code": "85437099",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS --- OTHER ---- OTHER"
    },
    {
      "code": "85438100",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: PROXIMITY CARDS AND TAGS"
    },
    {
      "code": "85438910",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: METAL DETECTORS"
    },
    {
      "code": "85438920",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: MINE DETECTORS"
    },
    {
      "code": "85438931",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AUDIO SPECIAL EFFECT EQUIPMENTS: DIGITAL REVERBERATORS"
    },
    {
      "code": "85438932",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AUDIO SPECIAL EFFECT EQUIPMENTS: MIXING SYSTEMS OR CONSOLES"
    },
    {
      "code": "85438939",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AUDIO SPECIAL EFFECT EQUIPMENTS: OTHER"
    },
    {
      "code": "85438941",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: VIDEO MIXING SYSTEM OR CONSOLES"
    },
    {
      "code": "85438942",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: VIDEO EFFECT SYSTEM"
    },
    {
      "code": "85438943",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: DIGITAL LAYERING MACHINE"
    },
    {
      "code": "85438944",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: PAINT BOX"
    },
    {
      "code": "85438945",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: VIDEO TYPEWRITER"
    },
    {
      "code": "85438946",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: VIDEO MATTING MACHINES"
    },
    {
      "code": "85438949",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - VIDEO SPECIAL EFFECT EQUIPMENTS: OTHER"
    },
    {
      "code": "85438951",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - EDIT CONTROL UNIT: COMPUTERISED EDITING SYSTEM CONTROLLING MORE THAN THREE VIDEO EDITING MACHINES"
    },
    {
      "code": "85438952",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - EDIT CONTROL UNIT: OTHER VIDEO CONTROL UNITS"
    },
    {
      "code": "85438959",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - EDIT CONTROL UNIT: OTHER"
    },
    {
      "code": "85438960",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: COLOUR CORRECTORS"
    },
    {
      "code": "85438971",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AMPLIFIER: BROADCAST AMPLIFIER"
    },
    {
      "code": "85438972",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AMPLIFIER: LIMITING AMPLIFIER, VIDEO DISTRIBUTION AMPLIFIER AND STABILISING AMPLIFIERS"
    },
    {
      "code": "85438979",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - AMPLIFIER: OTHER"
    },
    {
      "code": "85438981",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - GRAPHIC EQUALISER AND SYNTHESISED RECEIVERS: GRAPHIC EQUALISERS"
    },
    {
      "code": "85438982",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - GRAPHIC EQUALISER AND SYNTHESISED RECEIVERS: SYNTHESISED RECEIVERS"
    },
    {
      "code": "85438991",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : RF (RADIO FREQUENCY) POWER AMPLIFIERS AND NOISE GENERATORS FOR COMMUNICATION JAMMING EQUIPMENT, STATIC OR MOBILE OR MANPORTABLE"
    },
    {
      "code": "85438992",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : EQUIPMENT OR GADGETS BASED ON SOLAR ENERGY"
    },
    {
      "code": "85438993",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : PROFESSIONAL BEAUTY CARE EQUIPMENT"
    },
    {
      "code": "85438994",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : AUDIO VIDEO STEREO ENCODERS"
    },
    {
      "code": "85438995",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : TIME CODE GENERATOR"
    },
    {
      "code": "85438999",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER MACHINES AND APPARATUS: - OTHER: - OTHER : OTHER"
    },
    {
      "code": "85439000",
      "description": "ELECTRICAL MACHINES AND APPARATUS HAVING INDIVIDUAL FUNCTIONS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - PARTS"
    },
    {
      "code": "8544",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - Winding wire"
    },
    {
      "code": "854411",
      "description": "Of copper"
    },
    {
      "code": "85441110",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OF COPPER: ENAMELLED"
    },
    {
      "code": "85441190",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OF COPPER: OTHER"
    },
    {
      "code": "854419",
      "description": "Other"
    },
    {
      "code": "85441910",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OTHER: ASBESTOS COVERED"
    },
    {
      "code": "85441920",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OTHER: PLASTIC INSULATED"
    },
    {
      "code": "85441930",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OTHER: RUBBER INSULATED"
    },
    {
      "code": "85441990",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - WINDING WIRE: - OTHER: OTHER"
    },
    {
      "code": "854420",
      "description": "Co-axial cable and other co-axial electric conductors"
    },
    {
      "code": "85442010",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS- CO-AXIAL CABLE AND OTHER CO-AXIAL ELECTRIC CONDUCTORS: CO-AXIAL CABLE"
    },
    {
      "code": "85442090",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS- CO-AXIAL CABLE AND OTHER CO-AXIAL ELECTRIC CONDUCTORS: OTHER"
    },
    {
      "code": "85443000",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS  - IGNITION WIRING SETS AND OTHER WIRING SETS OF A KIND USED IN VEHICLES, AIRCRAFT OR SHIPS"
    },
    {
      "code": "85444111",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: - TELEPHONE CABLES: DRY CORE PAPER INSULATED"
    },
    {
      "code": "85444119",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: - TELEPHONE CABLES: OTHER"
    },
    {
      "code": "85444120",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: PAPER INSULATED"
    },
    {
      "code": "85444130",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: PLASTIC INSULATED"
    },
    {
      "code": "85444140",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: RUBBER INSULATED"
    },
    {
      "code": "85444190",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - FITTED WITH CONNECTORS: OTHER"
    },
    {
      "code": "854442",
      "description": "Fitted with connectors"
    },
    {
      "code": "85444210",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- PAPER INSULATED"
    },
    {
      "code": "85444220",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- PLASTIC INSULATED"
    },
    {
      "code": "85444230",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- RUBBER INSULATED"
    },
    {
      "code": "85444290",
      "description": "OTHER"
    },
    {
      "code": "85444291",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- OTHER : PAPER INSULATED OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444292",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- OTHER : PLASTIC INSULATED OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444293",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- OTHER : RUBBER INSULATED , OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444299",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 1000 V -- FITTED WITH CONNECTORS --- OTHER : ----OTHER"
    },
    {
      "code": "854449",
      "description": "Other"
    },
    {
      "code": "85444910",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- PAPER INSULATED"
    },
    {
      "code": "85444911",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - OTHER : - TELEPHONE CABLES : DRY CORE  PAPER INSULATED"
    },
    {
      "code": "85444919",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - OTHER : - TELEPHONE CABLES : OTHER"
    },
    {
      "code": "85444920",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- PLASTIC INSULATED"
    },
    {
      "code": "85444930",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- RUBBER INSULATED"
    },
    {
      "code": "85444990",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V: - OTHER : OTHER"
    },
    {
      "code": "85444991",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- OTHER ----PAPER INSULATED OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444992",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- OTHER : ---- PLASTIC INSULATED OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444993",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- OTHER ----RUBBER INSULATED OF A KIND USED IN TELECOMMUNICATION"
    },
    {
      "code": "85444999",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE NOT EXCEEDING 80 V -- OTHER --- OTHER ---- OTHER"
    },
    {
      "code": "85445110",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: PAPER INSULATED"
    },
    {
      "code": "85445120",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: PLASTIC INSULATED, OF A KIND USED FOR TELECOMMUNICATION"
    },
    {
      "code": "85445130",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: OTHER PLASTIC INSULATED"
    },
    {
      "code": "85445140",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: RUBBER INSULATED OF A KIND USED FOR TELECOMMUNICATION"
    },
    {
      "code": "85445150",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: OTHER RUBBER INSULATED"
    },
    {
      "code": "85445190",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - FITTED WITH CONNECTORS: OTHER"
    },
    {
      "code": "85445910",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : PAPER INSULATED"
    },
    {
      "code": "85445920",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : PLASTIC INSULATED OF A KIND USED FOR TELECOMMUNICATION"
    },
    {
      "code": "85445930",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : OTHER PLASTIC INSULATED"
    },
    {
      "code": "85445940",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : RUBBER INSULATED OF A KIND USED FOR TELECOMMUNICATION"
    },
    {
      "code": "85445950",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : OTHER RUBBER INSULATED"
    },
    {
      "code": "85445990",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 80 V BUT NOT EXCEEDING 1,000 V: - OTHER : OTHER"
    },
    {
      "code": "854460",
      "description": "Other electric conductors, for a voltage exceeding 1000 V"
    },
    {
      "code": "85446010",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 1000 V: PAPER INSULATED"
    },
    {
      "code": "85446020",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 1000 V: PLASTIC INSULATED"
    },
    {
      "code": "85446030",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 1000 V: RUBBER INSULATED"
    },
    {
      "code": "85446090",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OTHER ELECTRIC CONDUCTORS, FOR A VOLTAGE EXCEEDING 1000 V: OTHER"
    },
    {
      "code": "854470",
      "description": "Optical fibre cables"
    },
    {
      "code": "85447010",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OPTICAL FIBRE CABLES: LEAD ALLOY SHEATHED CABLES FOR LIGHTING PURPOSES"
    },
    {
      "code": "85447090",
      "description": "INSULATED (INCLUDING ENAMELLED OR ANODISED) WIRE, CABLE (INCLUDING CO-AXIAL CABLE) AND OTHER INSULATED ELECTRIC CONDUCTORS, WHETHER OR NOT FITTED WITH CONNECTORS; OPTICAL FIBRE CABLES, MADE UP OF INDIVIDUALLY SHEATHED FIBRES, WHETHER OR NOT ASSEMBLED WITH ELECTRIC CONDUCTORS OR FITTED WITH CONNECTORS - OPTICAL FIBRE CABLES: OTHER"
    },
    {
      "code": "8545",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES Electrodes"
    },
    {
      "code": "85451100",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES - ELECTRODES: OF A KIND USED FOR FURNACES"
    },
    {
      "code": "85451900",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES - ELECTRODES: OTHER"
    },
    {
      "code": "85452000",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES BRUSHES"
    },
    {
      "code": "854590",
      "description": "Other"
    },
    {
      "code": "85459010",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES ARC-LAMP CARBON"
    },
    {
      "code": "85459020",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES BATTERY CARBON"
    },
    {
      "code": "85459090",
      "description": "CARBON ELECTRODES, CARBON BRUSHES, LAMP CARBONS, BATTERY CARBONS AND OTHER ARTICLES OF GRAPHITE OR OTHER CARBON, WITH OR WITHOUT METAL, OF A KIND USED FOR ELECTRICAL PURPOSES OTHER"
    },
    {
      "code": "8546",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL"
    },
    {
      "code": "85461000",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF GLASS"
    },
    {
      "code": "854620",
      "description": "Of ceramics"
    },
    {
      "code": "85462011",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS: - PORCELAIN DISCS AND STRINGS : PORCELAIN BELOW 6.6 KV"
    },
    {
      "code": "85462019",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS: - PORCELAIN DISCS AND STRINGS : OTHER"
    },
    {
      "code": "85462021",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS:- PORCELAIN POST INSULATORS : BELOW 6.6 KV"
    },
    {
      "code": "85462022",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS:- PORCELAIN POST INSULATORS : 6.6 KV OR ABOVE BUT UPTO 11 KV"
    },
    {
      "code": "85462023",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS:- PORCELAIN POST INSULATORS : ABOVE 11 KV BUT UPTO 66 KV"
    },
    {
      "code": "85462024",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS:- PORCELAIN POST INSULATORS : ABOVE 66 KV BUT UPTO 132 KV"
    },
    {
      "code": "85462029",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS:- PORCELAIN POST INSULATORS : ABOVE 132 KV"
    },
    {
      "code": "85462031",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - PORCELAIN PIN INSULATORS: BELOW 6.6 KV"
    },
    {
      "code": "85462032",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - PORCELAIN PIN INSULATORS: 6.6 KV OR ABOVE BUT UP TO 11 KV"
    },
    {
      "code": "85462033",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - PORCELAIN PIN INSULATORS: ABOVE 11 KV BUT UPTO 66 KV"
    },
    {
      "code": "85462039",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - PORCELAIN PIN INSULATORS: ABOVE 66 KV"
    },
    {
      "code": "85462040",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - OTHER HIGH TENSION PROCELAIN SOLID CORE INSULATORS"
    },
    {
      "code": "85462050",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - OTHER LOW TENSION PROCELAIN INSULATORS INCLUDING TELEGRAPH AND TELEPHONE INSULATORS"
    },
    {
      "code": "85462090",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL - OF CERAMICS - PORCELAIN PIN INSULATORS: OTHER"
    },
    {
      "code": "854690",
      "description": "Other"
    },
    {
      "code": "85469010",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL  OTHER : HEAT SHRINKABLE COMPONENTS"
    },
    {
      "code": "85469090",
      "description": "ELECTRICAL INSULATORS OF ANY MATERIAL  OTHER : OTHER"
    },
    {
      "code": "8547",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL"
    },
    {
      "code": "854710",
      "description": "Insulating fittings of ceramics"
    },
    {
      "code": "85471010",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF CERAMICS: PORCELAIN BUSHING BELOW 6.6 KV"
    },
    {
      "code": "85471020",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF CERAMICS: PORCELAIN BUSHINGS FOR VOLTAGE 6.6 KV OR ABOVE BUT BELOW 11 KV"
    },
    {
      "code": "85471030",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF CERAMICS: PORCELAIN BUSHINGS FOR VOLTAGE 11 KV OR ABOVE BUT UPTO 66 KV"
    },
    {
      "code": "85471040",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF CERAMICS: PORCELAIN BUSHINGS FOR VOLTAGE 66 KV OR ABOVE"
    },
    {
      "code": "85471090",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF CERAMICS: OTHER"
    },
    {
      "code": "85472000",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - INSULATING FITTINGS OF PLASTICS"
    },
    {
      "code": "854790",
      "description": "Other"
    },
    {
      "code": "85479010",
      "description": "INSULATING FITTINGS FOR ELECTRICAL MACHINES, APPLIANCES OR EQUIPMENT, BEING FITTINGS WHOLLY OF INSULATING MATERIAL APART FROM ANY MINOR COMPONENTS OF METAL (FOR EXAMPLE, THREADED SOCKETS) INCORPORATED DURING MOULDING SOLELY FOR THE PURPOSES OF ASSEMBLY, OTHER THAN INSULATORS OF HEADING 8546; ELECTRICAL CONDUIT TUBING AND JOINTS THEREFOR, OF BASE METAL LINED WITH INSULATING MATERIAL - OTHER : ELECTRICAL INSULATING FITTINGS OF GLASS"
    },
    {
      "code": "85479020",
      "description": "ELECTRICAL CONDUIT TUBING AND JOINS THEREFOR"
    },
    {
      "code": "85479090",
      "description": "OTHER"
    },
    {
      "code": "8548",
      "description": "WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC ACCUMULATORS; ELECTRICAL PARTS OF MACHINERY OR APPARATUS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "854810",
      "description": "Waste and scrap of primary cells, primary batteries and electric accumulators; spent primary cells, spent primary batteries and spent electric accumulators"
    },
    {
      "code": "85481010",
      "description": "WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC CCUMULATORS; ELECTRICAL PARTS OF MACHINERY OR APPARATUS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC ACCUMULATORS: BATTERY SCRAP, NAMELY THE FOLLOWING: LEAD BATTERY PLATES COVERED BY ISRI CODE WORD RAILS; BATTERY LUGS COVERED BY ISRI CODE WORD RAKES."
    },
    {
      "code": "85481020",
      "description": "WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC CCUMULATORS; ELECTRICAL PARTS OF MACHINERY OR APPARATUS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC ACCUMULATORS: BATTERY WASTE, NAMELY THE FOLLOWING: SCRAP DRAINED OR DRY WHILE INTACT, LEAD BATTERIES COVERED BY ISRI CODE WORD RAINS; SCRAP WET WHOLE INTACT LEAD BATTERIES COVERED BY ISRI CODE WORD RINK; SCRAP INDUSTRIAL INTACT LEAD CELLS COVERED BY ISRI CODE WORD RONO; SCRAP WHOLE INTACT INDUSTRIAL LEAD BATTERIES COVERED BY ISRI CODE WORD ROPER; EDISON BATTERIES COVERED BY ISRI CODE WORD VAUNT"
    },
    {
      "code": "85481090",
      "description": "WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC CCUMULATORS; ELECTRICAL PARTS OF MACHINERY OR APPARATUS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC ACCUMULATORS: OTHER WASTE AND SCRAP"
    },
    {
      "code": "85489000",
      "description": "WASTE AND SCRAP OF PRIMARY CELLS, PRIMARY BATTERIES AND ELECTRIC ACCUMULATORS; SPENT PRIMARY CELLS, SPENT PRIMARY BATTERIES AND SPENT ELECTRIC CCUMULATORS; ELECTRICAL PARTS OF MACHINERY OR APPARATUS, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER"
    },
    {
      "code": "86",
      "description": "Railway or tramway locomotives, rolling-stock and parts thereof; railway or tramway track fixtures and fittings and parts thereof; mechanical (including electro-mechanical) traffic signalling equipment of all kinds"
    },
    {
      "code": "8601",
      "description": "RAIL LOCOMOTIVES POWERED FROM AN EXTERNAL SOURCE OF ELECTRICITY OR BY ELECTRIC ACCUMULATORS"
    },
    {
      "code": "86011000",
      "description": "RAIL LOCOMOTIVES POWERED FROM AN EXTERNAL SOURCE OF ELECTRICITY OR BY ELECTRIC ACCUMULATORS POWERED FROM AN EXTERNAL SOURCE OF ELECTRICITY"
    },
    {
      "code": "86012000",
      "description": "RAIL LOCOMOTIVES POWERED FROM AN EXTERNAL SOURCE OF ELECTRICITY OR BY ELECTRIC ACCUMULATORS POWERED BY ELECTRIC ACCUMULATORS"
    },
    {
      "code": "8602",
      "description": "OTHER RAIL LOCOMOTIVES; LOCOMOTIVE TENDERs"
    },
    {
      "code": "86021000",
      "description": "OTHER RAIL LOCOMOTIVES; LOCOMOTIVE TENDERS DIESEL-ELECTRIC LOCOMOTIVES"
    },
    {
      "code": "860290",
      "description": "Other"
    },
    {
      "code": "86029010",
      "description": "OTHER RAIL LOCOMOTIVES; LOCOMOTIVE TENDERS - OTHER : STEAM LOCOMOTIVES AND TENDERS THEREOF"
    },
    {
      "code": "86029090",
      "description": "OTHER RAIL LOCOMOTIVES; LOCOMOTIVE TENDERS - OTHER : OTHER"
    },
    {
      "code": "8603",
      "description": "SELF-PROPELLED RAILWAY OR TRAMWAY COACHES, VANS AND TRUCKS, OTHER THAN THOSE OF HEADING 8604"
    },
    {
      "code": "86031000",
      "description": "SELF-PROPELLED RAILWAY OR TRAMWAY COACHES, VANS AND TRUCKS, OTHER THAN THOSE OF HEADING 8604 POWERED FROM AN EXTERNAL SOURCE OF ELECTRICITY"
    },
    {
      "code": "86039000",
      "description": "SELF-PROPELLED RAILWAY OR TRAMWAY COACHES, VANS AND TRUCKS, OTHER THAN THOSE OF HEADING 8604 OTHER"
    },
    {
      "code": "8604",
      "description": "RAILWAY OR TRAMWAY MAINTENANCE OR SERVICE VEHICLES WHETHER OR NOT SELF-PROPELLED (FOR EXAMPLE, WORKSHOPS, CRANES, BALLAST TAMPERS, TRACK-LINERS, TESTING COACHES AND TRACK INSPECTION VEHICLES)"
    },
    {
      "code": "86040000",
      "description": "RAILWAY OR TRAMWAY MAINTENANCE OR SERVICE VEHICLES WHETHER OR NOT SELF-PROPELLED (FOR EXAMPLE, WORKSHOPS, CRANES, BALLAST TAMPERS, TRACK-LINERS,TESTING COACHES AND TRACK INSPECTION VEHICLES)"
    },
    {
      "code": "8605",
      "description": "RAILWAY OR TRAMWAY PASSENGER COACHES, NOT SELF-PROPELLED; LUGGAGE VANS, POST OFFICE COACHES AND OTHER SPECIAL PURPOSE RAILWAY OR TRAMWAY COACHES, NOT SELF-PROPELLED (EXCLUDING THOSE OF HEADING 8604)"
    },
    {
      "code": "86050000",
      "description": "RAILWAY OR TRAMWAY PASSENGER COACHES, NOT  SELF-PROPELLED; LUGGAGE VANS, POST OFFICE COACHES AND OTHER SPECIAL PURPOSE RAILWAY OR TRAMWAY COACHES, NOT SELF-PROPELLED (EXCLUDING THOSE OF HEADING 8604)"
    },
    {
      "code": "8606",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED"
    },
    {
      "code": "860610",
      "description": "Tank wagons and the like"
    },
    {
      "code": "86061010",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - TANK WAGONS AND THE LIKE : FOUR WHEELER TANK WAGONS OF PAY-LOAD EXCEEDING 23 TONNES"
    },
    {
      "code": "86061020",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - TANK WAGONS AND THE LIKE : EIGHT WHEELER TANK WAGONS OF PAY-LOAD NOT EXCEEDING 60 TONNES"
    },
    {
      "code": "86061090",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - TANK WAGONS AND THE LIKE : OTHER"
    },
    {
      "code": "86062000",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - INSULATED OR REFRIGERATED VANS AND WAGONS, OTHER THAN THOSE OF SUB-HEADING 8606 10"
    },
    {
      "code": "86063000",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - SELF-DISCHARGING VANS AND WAGONS, OTHER THAN THOSE OF SUB-HEADING 8606 10"
    },
    {
      "code": "860691",
      "description": "Covered and closed"
    },
    {
      "code": "86069110",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: COVERED AND CLOSED : METER GUAGE EIGHT WHEELER COVERED WAGONS OF PAY-LOAD NOT EXCEEDING 38 TONNES"
    },
    {
      "code": "86069120",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: COVERED AND CLOSED : BROAD GUAGE EIGHT WHEELER COVERED WAGONS OF PAY-LOAD NOT EXCEEDING 60 TONNES"
    },
    {
      "code": "86069190",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: COVERED AND CLOSED : OTHER"
    },
    {
      "code": "860692",
      "description": "Open with non-removable sides of a height exceeding 60 cms"
    },
    {
      "code": "86069210",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: OPEN WITH NON-REMOVABLE SIDES OF A HEIGHT EXCEEDING 60 CMS: BOGIE EIGHT WHEELER WAGONS OF PAY-LOAD NOT EXCEEDING 60 TONNES"
    },
    {
      "code": "86069220",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: OPEN WITH NON-REMOVABLE SIDES OF A HEIGHT EXCEEDING 60 CMS: BROAD GUAGE BOGIE EIGHT WHEELER WAGONS OF PAY-LOAD EXCEEDING 60 TONNES BUT NOT EXCEEDING 67 TONNES"
    },
    {
      "code": "86069290",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: OPEN WITH NON-REMOVABLE SIDES OF A HEIGHT EXCEEDING 60 CMS: OTHER"
    },
    {
      "code": "86069900",
      "description": "RAILWAY OR TRAMWAY GOODS VANS AND WAGONS, NOT SELF-PROPELLED - OTHER: OTHER"
    },
    {
      "code": "8607",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - Bogies, bissel-bogies, axles and wheels, and parts thereof"
    },
    {
      "code": "86071100",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : DRIVING BOGIES AND BISSEL-BOGIES"
    },
    {
      "code": "86071200",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : OTHER BOGIES AND BISSEL-BOGIES"
    },
    {
      "code": "860719",
      "description": "Other including parts"
    },
    {
      "code": "86071910",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : OTHER INCLUDING PARTS : AXLES, WHEELS FOR COACHES, VAN AND WAGONS (ROLLING-STOCK)"
    },
    {
      "code": "86071920",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : OTHER INCLUDING PARTS : AXLES AND WHEELS FOR LOCOMOTIVES"
    },
    {
      "code": "86071930",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : OTHER INCLUDING PARTS : AXLE BOXES (LUBRICATING OR GREASE BOX)"
    },
    {
      "code": "86071990",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BOGIES, BISSEL-BOGIES, AXLES AND WHEELS, AND PARTS THEREOF : OTHER INCLUDING PARTS : OTHER PARTS OF AXLES AND WHEELS"
    },
    {
      "code": "86072100",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BRAKES AND PARTS THEREOF : AIR BRAKES AND PARTS THEREOF"
    },
    {
      "code": "86072900",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - BRAKES AND PARTS THEREOF : OTHER"
    },
    {
      "code": "860730",
      "description": "Hooks and other coupling devices, buffers and parts thereof"
    },
    {
      "code": "86073010",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK HOOKS AND OTHER COUPLING DEVICES, BUFFERS AND PARTS THEREOF: BUFFERS AND COUPLING DEVICES"
    },
    {
      "code": "86073090",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK HOOKS AND OTHER COUPLING DEVICES, BUFFERS AND PARTS THEREOF: OTHER"
    },
    {
      "code": "86079100",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - OTHER : OF LOCOMOTIVES"
    },
    {
      "code": "860799",
      "description": "Other"
    },
    {
      "code": "86079910",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - OTHER : OTHER : PARTS OF COACH WORK OF RAILWAY RUNNING STOCK"
    },
    {
      "code": "86079920",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - OTHER : OTHER : PARTS OF TRAMWAY, LOCOMOTIVES AND RUNNING STOCK"
    },
    {
      "code": "86079930",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - OTHER : OTHER : HYDRAULIC SHOCK ABSORBERS FOR RAILWAY BOGIES"
    },
    {
      "code": "86079990",
      "description": "PARTS OF RAILWAY OR TRAMWAY LOCOMOTIVES OR ROLLING-STOCK - OTHER : OTHER : OTHER"
    },
    {
      "code": "8608",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTOMECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING"
    },
    {
      "code": "860800",
      "description": "Railway or tramway track fixtures and fittings; mechanical (including electomechanical) signalling, safety or traffic control equipment for railway, tramways, roads, inland waterways, parking facilities, port installation or air-fields; parts of the foregoing"
    },
    {
      "code": "86080010",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING - RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING : RAILWAY AND TRAMWAY TRACK FIXTURES AND FITTINGS"
    },
    {
      "code": "86080020",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING - RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING : MECHANICAL EQUIPMENT, NOT ELECTRICALLY POWERED FOR SIGNALLING TO, OR CONTROLLING, ROAD RAIL OR OTHER VEHICLES, SHIPS OR AIRCRAFT"
    },
    {
      "code": "86080030",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING - RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING : OTHER TRAFFIC CONTROL EQUIPMENT FOR RAILWAYS"
    },
    {
      "code": "86080040",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING - RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING : OTHER TRAFFIC CONTROL EQUIPMENT FOR ROADS OR INLAND WATERWAYS INCLUDING AUTOMATIC TRAFFIC CONTROL EQUIPMENT FOR USE AT PORTS AND AIRPORTS"
    },
    {
      "code": "86080090",
      "description": "RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING - RAILWAY OR TRAMWAY TRACK FIXTURES AND FITTINGS; MECHANICAL (INCLUDING ELECTO-MECHANICAL) SIGNALLING, SAFETY OR TRAFFIC CONTROL EQUIPMENT FOR RAILWAY, TRAMWAYS, ROADS, INLAND WATERWAYS, PARKING FACILITIES, PORT INSTALLATION OR AIR-FIELDS; PARTS OF THE FOREGOING : OTHER"
    },
    {
      "code": "8609",
      "description": "CONTAINERS (INCLUDING CONTAINERS FOR THE TRANSPORT OF FLUIDS) SPECIALLY DESIGNED AND EQUIPPED FOR CARRIAGE BY ONE OR MORE MODES OF TRANSPORT"
    },
    {
      "code": "86090000",
      "description": "CONTAINERS (INCLUDING CONTAINERS FOR THE TRANSPORT OF FLUIDS) SPECIALLY DESIGNED AND EQUIPPED FOR CARRIAGE BY ONE OR MORE MODES OF TRANSPORT"
    },
    {
      "code": "87",
      "description": "Vehicles other than railway or tramway rolling-stock, and parts and accessories thereof"
    },
    {
      "code": "8701",
      "description": "TRACTORS (OTHER THAN TRACTORS OF HEADING 8709)"
    },
    {
      "code": "87011000",
      "description": "TRACTORS (OTHER THAN TRACTORS OF HEADING 8709) PEDESTRIAN CONTROLLED TRACTORS"
    },
    {
      "code": "870120",
      "description": "Road tractors for semi-trailers"
    },
    {
      "code": "87012010",
      "description": "TRACTORS (OTHER THAN TRACTORS OF HEADING 8709) ROAD TRACTORS FOR SEMI-TRAILERS OF ENGINE CAPACITY NOT EXCEEDING 1,800 CC"
    },
    {
      "code": "87012090",
      "description": "TRACTORS (OTHER THAN TRACTORS OFHEADING 8709) ROAD TRACTORS FOR SEMI-TRAILERS OTHER"
    },
    {
      "code": "870130",
      "description": "Track-laying tractors"
    },
    {
      "code": "87013011",
      "description": "TRACTORS (OTHER THAN TRACTORS OFHEADING 8709 TRACK-LAYING TRACTORS : GARDEN TRACTORS:OF ENGINE CAPACITY NOT EXCEEDING 1,800 CC"
    },
    {
      "code": "87013019",
      "description": "TRACTORS (OTHER THAN TRACTORS OFHEADING 8709 TRACK-LAYING TRACTORS GARDEN TRACTORS: OTHER"
    },
    {
      "code": "87013091",
      "description": "TRACTORS (OTHER THAN TRACTORS OF HEADING 8709 OTHER :OF ENGINE CAPACITY NOT EXCEEDING 1,800 CC"
    },
    {
      "code": "87013099",
      "description": "TRACTORS (OTHER THAN TRACTORS OFHEADING 8709 OTHER OTHER"
    },
    {
      "code": "870190",
      "description": "Other"
    },
    {
      "code": "87019010",
      "description": "TRACTORS (OTHER THAN TRACTORS OF HEADING 8709 OTHER : OF ENGINE CAPACITY NOT EXCEEDING 1,800 CC"
    },
    {
      "code": "87019090",
      "description": "TRACTORS (OTHER THAN TRACTORS OFHEADING 8709 OTHER :TRACTORS (OTHER THAN TRACTORS OFHEADING 8709 OTHER"
    },
    {
      "code": "87019100",
      "description": "Not exceeding 18 kW"
    },
    {
      "code": "87019200",
      "description": "Exceeding 18 kW but not exceeding 37 kW"
    },
    {
      "code": "87019300",
      "description": "Exceeding 37 kW but not exceeding 75 kW"
    },
    {
      "code": "87019400",
      "description": "Exceeding 75 kW but not exceeding 130 kW"
    },
    {
      "code": "87019500",
      "description": "Exceeding 130 kW"
    },
    {
      "code": "8702",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "870210",
      "description": "With only compression-ignition internal combustion piston engine (diesel or semi-diesel)"
    },
    {
      "code": "87021011",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER WITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL) VEHICLES FOR TRANSPORT OF NOT MORE THAN3 PERSONS, INCLUDING THE DRIVER: INTEGRATED MONOCOQUE VEHICLE"
    },
    {
      "code": "87021012",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER WITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL) VEHICLES FOR TRANSPORT OF NOT MORE THAN 13 PERSONS, INCLUDING THE DRIVER:AIR-CONDITIONED VEHICLE"
    },
    {
      "code": "87021018",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87021019",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVERWITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL) VEHICLES FOR TRANSPORT OF NOT MORE THAN 13 PERSONS, INCLUDING THE DRIVER:OTHER"
    },
    {
      "code": "87021021",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87021022",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87021028",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87021029",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "87021091",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVERWITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL)OTHER : INTEGRATED MONCOQUE VEHICLE"
    },
    {
      "code": "87021092",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER WITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL)OTHER : AIR-CONDITIONED VEHICLE"
    },
    {
      "code": "87021099",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER WITH COMPRESSION-IGNITION INTERNALCOMBUSTION PISTON ENGINE (DIESEL OR SEMIDIESEL)OTHER :OTHER"
    },
    {
      "code": "870220",
      "description": "With both compression-ignition internal combustion piston engine (diesel or semi-diesel) and electric motor as motors for propulsion"
    },
    {
      "code": "87022011",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87022012",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87022018",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87022019",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "87022021",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87022022",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87022028",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87022029",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "870230",
      "description": "With both spark-ignition internal combustion reciprocating piston engine and electric motor as motors for propulsion"
    },
    {
      "code": "87023011",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87023012",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87023018",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87023019",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "87023021",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87023022",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87023028",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87023029",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "870240",
      "description": "With only electric motor for propulsion"
    },
    {
      "code": "87024011",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87024012",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87024018",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87024019",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "87024021",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87024022",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87024028",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87024029",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "870290",
      "description": "Other"
    },
    {
      "code": "87029011",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER VEHICLES FOR TRANSPORT OF NOT MORE THAN13 PERSONS, INCLUDING THE DRIVER: INTEGRATED MONOCOQUE VEHICLE"
    },
    {
      "code": "87029012",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER : VEHICLES FOR TRANSPORT OF NOT MORE THAN13 PERSONS, INCLUDING THE DRIVER:AIR-CONDITIONED VEHICLE"
    },
    {
      "code": "87029013",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER : VEHICLES FOR TRANSPORT OF NOT MORE THAN13 PERSONS, INCLUDING THE DRIVER: ELECTRICALLY OPERATED"
    },
    {
      "code": "87029018",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87029019",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER VEHICLES FOR TRANSPORT OF NOT MORE THAN13 PERSONS, INCLUDING THE DRIVER OTHER"
    },
    {
      "code": "87029020",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER VEHICLES FOR TRANSPORT OF NOT MORE THAN13 PERSONS, INCLUDING THE DRIVER:ELECTRICALLY OPERATED VEHICLES NOT ELSEWHERE INCLUDED OR SPECIFIED"
    },
    {
      "code": "87029021",
      "description": "Integrated monocoque vehicle, air-conditioned"
    },
    {
      "code": "87029022",
      "description": "Integrated monocoque vehicle, non air-conditioned"
    },
    {
      "code": "87029028",
      "description": "Other, air-conditioned"
    },
    {
      "code": "87029029",
      "description": "Other, non air-conditioned"
    },
    {
      "code": "87029091",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER INTEGRATED MONOCOQUE VEHICLE"
    },
    {
      "code": "87029092",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER :AIR-CONDITIONED VEHICLE"
    },
    {
      "code": "87029099",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF TEN OR MORE PERSONS, INCLUDING THE DRIVER OTHER :OTHER"
    },
    {
      "code": "8703",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS"
    },
    {
      "code": "870310",
      "description": "Vehicles specially designed for travelling on snow; golf cars and similar vehicles"
    },
    {
      "code": "87031010",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THANTHOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS VEHICLES SPECIALLY DESIGNED FOR TRAVELLING ONSNOW; GOLF CARS AND SIMILAR VEHICLES ELECTRICALLY OPERATED"
    },
    {
      "code": "87031090",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONSAND RACING CARSVEHICLES SPECIALLY DESIGNED FOR TRAVELLING ONSNOW; GOLF CARS AND SIMILAR VEHICLESOTHER"
    },
    {
      "code": "870321",
      "description": "Of a cylinder capacity not exceeding 1,000 cc"
    },
    {
      "code": "87032110",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY NOT EXCEEDING 1,000 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87032120",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY NOT EXCEEDING 1,000 CC : THREE-WHEELED VEHICLES"
    },
    {
      "code": "87032191",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY NOT EXCEEDING 1,000 CC : OTHERS MOTOR CARS"
    },
    {
      "code": "87032192",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY NOT EXCEEDING 1,000 CC : OTHERS SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87032199",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY NOT EXCEEDING 1,000 CC : OTHERS OTHER"
    },
    {
      "code": "870322",
      "description": "Of a cylinder capacity exceeding 1,000 cc but not exceeding 1,500 cc"
    },
    {
      "code": "87032210",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,000 CC BUT NOT EXCEEDING 1,500 CC VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87032220",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,000 CC BUT NOT EXCEEDING 1,500 CC SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87032230",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,000 CC BUT NOT EXCEEDING 1,500 CC THREE-WHEELED VEHICLES"
    },
    {
      "code": "87032291",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,000 CC BUT NOT EXCEEDING 1,500 CC OTHER MOTOR CARS"
    },
    {
      "code": "87032299",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,000 CC BUT NOT EXCEEDING 1,500 CC OTHER OTHER"
    },
    {
      "code": "870323",
      "description": "Of a cylinder capacity exceeding 1,500 cc but not exceeding 3,000 cc"
    },
    {
      "code": "87032310",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 3,000 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS INCLUDING THE DRIVER"
    },
    {
      "code": "87032320",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 3,000 CC THREE-WHEELED VEHICLES"
    },
    {
      "code": "87032391",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 3,000 CC OTHERS MOTOR CARS"
    },
    {
      "code": "87032392",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 3,000 CC SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87032399",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 3,000 CC OTHER"
    },
    {
      "code": "870324",
      "description": "Of a cylinder capacity exceeding 3,000 cc"
    },
    {
      "code": "87032410",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 3,000 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87032420",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 3,000 CC : THREE-WHEELED VEHICLES"
    },
    {
      "code": "87032491",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 3,000 CC : OTHERS MOTOR CARS"
    },
    {
      "code": "87032492",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 3,000 CC : OTHERS SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87032499",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH SPARK-IGNITION INTERNAL COMBUSTION RECIPROCATING PISTON ENGINE : OF A CYLINDER CAPACITY EXCEEDING 3,000 CC : OTHERS OTHER"
    },
    {
      "code": "870331",
      "description": "Of a cylinder capacity not exceeding 1,500 cc"
    },
    {
      "code": "87033110",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY NOT EXCEEDING 1,500 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87033120",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY NOT EXCEEDING 1,500 CC : THREE-WHEELED VEHICLES"
    },
    {
      "code": "87033191",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY NOT EXCEEDING 1,500 CC : OTHER MOTOR CARS"
    },
    {
      "code": "87033192",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY NOT EXCEEDING 1,500 CC : OTHER SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87033199",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY NOT EXCEEDING 1,500 CC : OTHER"
    },
    {
      "code": "870332",
      "description": "Of a cylinder capacity exceeding 1,500 cc but not exceeding 2,500 cc"
    },
    {
      "code": "87033210",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : ? OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 2,500 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87033220",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 2,500 CC THREE-WHEELED VEHICLES"
    },
    {
      "code": "87033291",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 2,500 CC OTHERS: MOTOR CARS"
    },
    {
      "code": "87033292",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 2,500 CC OTHERS: SPECIALIZED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87033299",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 1,500 CC BUT NOT EXCEEDING 2,500 CC OTHERS: OTHER"
    },
    {
      "code": "870333",
      "description": "Of a cylinder capacity exceeding 2,500 cc"
    },
    {
      "code": "87033310",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 2,500 CC : VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF MORE THAN SEVEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87033320",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 2,500 CC : THREE-WHEELED VEHICLES"
    },
    {
      "code": "87033391",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 2,500 CC OTHERS:: MOTOR CARS"
    },
    {
      "code": "87033392",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 2,500 CC OTHERS:: SPECIALISED TRANSPORT VEHICLES SUCH AS AMBULANCES, PRISON VANS AND THE LIKE"
    },
    {
      "code": "87033399",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHER VEHICLES, WITH COMPRESSION IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : OF A CYLINDER CAPACITY EXCEEDING 2,500 CC OTHERS:: OTHER"
    },
    {
      "code": "870340",
      "description": "Other vehicles, with both spark-ignition internal combustion reciprocating piston engine and electric motor as motors for propulsion, other than those capable of being charged by plugging to external source of electric power"
    },
    {
      "code": "87034010",
      "description": "Vehicles principally designed for transport of more than seven persons, including driver"
    },
    {
      "code": "87034020",
      "description": "Specialised transport vehicles such as ambulances, prison vans and the like"
    },
    {
      "code": "87034030",
      "description": "Motor cars"
    },
    {
      "code": "87034040",
      "description": "Three-wheeled vehicles"
    },
    {
      "code": "87034090",
      "description": "Other"
    },
    {
      "code": "870350",
      "description": "Other vehicles, with both compression-ignition internal combustion piston engine (diesel or semi-diesel) and electric motor as motors for propulsion, other than those capable of being charged by plugging to external source of electric power"
    },
    {
      "code": "87035010",
      "description": "Vehicles principally designed for transport of more than seven persons, including driver"
    },
    {
      "code": "87035020",
      "description": "Specialised transport vehicles such as ambulances, prison vans and the like"
    },
    {
      "code": "87035030",
      "description": "Motor cars"
    },
    {
      "code": "87035040",
      "description": "Three-wheeled vehicles"
    },
    {
      "code": "87035090",
      "description": "Other"
    },
    {
      "code": "870360",
      "description": "Other vehicles, with both spark-ignition internal combustion reciprocating piston engine and electric motor as motors for propulsion, capable of being charged by plugging to external source of electric power"
    },
    {
      "code": "87036010",
      "description": "Vehicles principally designed for transport of more than seven persons, including driver"
    },
    {
      "code": "87036020",
      "description": "Specialised transport vehicles such as ambulances, prison vans and the like"
    },
    {
      "code": "87036030",
      "description": "Motor cars"
    },
    {
      "code": "87036040",
      "description": "Three-wheeled vehicles"
    },
    {
      "code": "87036090",
      "description": "other"
    },
    {
      "code": "870370",
      "description": "Other vehicles, with both compression-ignition internal combustion piston engine (diesel or semi-diesel) and electric motor as motors for propulsion, capable of being charged by plugging to external source of electric power"
    },
    {
      "code": "87037010",
      "description": "Vehicles principally designed for transport of more than seven persons, including driver"
    },
    {
      "code": "87037020",
      "description": "Specialised transport vehicles such as ambulances, prison vans and the like"
    },
    {
      "code": "87037030",
      "description": "Motor cars"
    },
    {
      "code": "87037040",
      "description": "Three wheeled vehicle"
    },
    {
      "code": "87037090",
      "description": "Other"
    },
    {
      "code": "870380",
      "description": "Other vehicles, with only electric motor for propulsion"
    },
    {
      "code": "87038010",
      "description": "Vehicles principally designed for transport of more than seven persons, including driver"
    },
    {
      "code": "87038020",
      "description": "Specialised transport vehicles such as ambulances, prison vans and the like"
    },
    {
      "code": "87038030",
      "description": "Motor cars"
    },
    {
      "code": "87038040",
      "description": "Three wheeled vehicle"
    },
    {
      "code": "87038090",
      "description": "Other"
    },
    {
      "code": "870390",
      "description": "Other"
    },
    {
      "code": "87039000",
      "description": "Other"
    },
    {
      "code": "87039010",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHERS: ELECTRICALLY OPERATED"
    },
    {
      "code": "87039090",
      "description": "MOTOR CARS AND OTHER MOTOR VEHICLES PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS (OTHER THAN THOSE OF HEADING 8702), INCLUDING STATION WAGONS AND RACING CARS OTHERS: OTHER"
    },
    {
      "code": "8704",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS"
    },
    {
      "code": "870410",
      "description": "Dumpers designed for off-highway use"
    },
    {
      "code": "87041010",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS DUMPERS DESIGNED FOR OFF-HIGHWAY USE: :WITH NET WEIGHT (EXCLUDING PAY-LOAD) EXCEEDING 8 TONNES AND MAXIMUM PAY-LOAD CAPACITY NOT LESS THAN 10 TONNES"
    },
    {
      "code": "87041090",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS DUMPERS DESIGNED FOR OFF-HIGHWAY USE: OTHER"
    },
    {
      "code": "870421",
      "description": "g.v.w. not exceeding 5 tonnes"
    },
    {
      "code": "87042110",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. NOT EXCEEDING 5 TONNES REFRIGERATED"
    },
    {
      "code": "87042120",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. NOT EXCEEDING 5 TONNES THREE-WHEELED MOTOR VEHICLES"
    },
    {
      "code": "87042190",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. NOT EXCEEDING 5 TONNES OTHER"
    },
    {
      "code": "870422",
      "description": "g.v.w. exceeding 5 tonnes but not exceeding 20 tonnes"
    },
    {
      "code": "87042211",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 5 TONNES BUT NOT EXCEEDING 20 TONNES LORRIES AND TRUCKS REFRIGERATED"
    },
    {
      "code": "87042219",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 5 TONNES BUT NOT EXCEEDING 20 TONNES LORRIES AND TRUCKS OTHER"
    },
    {
      "code": "87042290",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 5 TONNES BUT NOT EXCEEDING 20 TONNES OTHER"
    },
    {
      "code": "870423",
      "description": "g.v.w. exceeding 20 tonnes"
    },
    {
      "code": "87042311",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 20 TONNES : LORRIES AND TRUCKS: REFRIGERATED"
    },
    {
      "code": "87042319",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 20 TONNES : LORRIES AND TRUCKS: OTHER"
    },
    {
      "code": "87042390",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH COMPRESSION-IGNITION INTERNAL COMBUSTION PISTON ENGINE (DIESEL OR SEMI-DIESEL) : G.V.W. EXCEEDING 20 TONNES OTHER"
    },
    {
      "code": "870431",
      "description": "g.v.w. not exceeding 5 tonnes"
    },
    {
      "code": "87043110",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE : G.V.W. NOT EXCEEDING 5 TONNES : REFRIGERATED"
    },
    {
      "code": "87043120",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE : G.V.W. NOT EXCEEDING 5 TONNES THREE-WHEELED MOTOR VEHICLES"
    },
    {
      "code": "87043190",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE : G.V.W. NOT EXCEEDING 5 TONNES OTHER"
    },
    {
      "code": "870432",
      "description": "g.v.w. exceeding 5 tonnes"
    },
    {
      "code": "87043211",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE G.V.W. EXCEEDING 5 TONNES: LORRIES AND TRUCKS REFRIGERATED"
    },
    {
      "code": "87043219",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE G.V.W. EXCEEDING 5 TONNES: LORRIES AND TRUCKS OTHER"
    },
    {
      "code": "87043290",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER, WITH SPARK-IGNITION INTERNAL COMBUSTION PISTON ENGINE G.V.W. EXCEEDING 5 TONNES: OTHER"
    },
    {
      "code": "870490",
      "description": "Other"
    },
    {
      "code": "87049011",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER : LORRIES AND TRUCKS: REFRIGERATED"
    },
    {
      "code": "87049012",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER : LORRIES AND TRUCKS: ELECTRICALLY OPERATED"
    },
    {
      "code": "87049019",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER : LORRIES AND TRUCKS: OTHER"
    },
    {
      "code": "87049090",
      "description": "MOTOR VEHICLES FOR THE TRANSPORT OF GOODS OTHER : LORRIES AND TRUCKS: OTHER OTHER"
    },
    {
      "code": "8705",
      "description": "SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS)"
    },
    {
      "code": "87051000",
      "description": "8705 SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS) CRANE LORRIES"
    },
    {
      "code": "87052000",
      "description": "8705 SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS) MOBILE DRILLING DERRICKS"
    },
    {
      "code": "87053000",
      "description": "8705 SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS) FIRE FIGHTING VEHICLES"
    },
    {
      "code": "87054000",
      "description": "8705 SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS) CONCRETE-MIXER LORRIES"
    },
    {
      "code": "87059000",
      "description": "8705 SPECIAL PURPOSE MOTOR VEHICLES, OTHER THAN THOSE PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS OR GOODS (FOR EXAMPLE, BREAKDOWN LORRIES, CRANE LORRIES, FIRE FIGHTING VEHICLES, CONCRETE-MIXERS LORRIES, SPRAYING LORRIES, MOBILE WORKSHOPS, MOBILE RADIOLOGICAL UNITS) OTHER"
    },
    {
      "code": "8706",
      "description": "CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705"
    },
    {
      "code": "870600",
      "description": "Chassis fitted with engines, for the motor vehicles of headings 8701 to 8705"
    },
    {
      "code": "87060011",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705: ? FOR THE TRACTORS OF HEADING 8701 : OF ENGINE CAPACITY NOT EXCEEDING 1,800 CC"
    },
    {
      "code": "87060019",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705: ? FOR THE TRACTORS OF HEADING 8701 : OTHER"
    },
    {
      "code": "87060021",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE VEHICLES OF HEADING 8702 FOR TRANSPORT OF NOT MORE THAN THIRTEEN PERSONS, INCLUDING THE DRIVER"
    },
    {
      "code": "87060029",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE VEHICLES OF HEADING 8702 OTHER"
    },
    {
      "code": "87060031",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8703 FOR THREE-WHEELED VEHICLES"
    },
    {
      "code": "87060039",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8703 OTHER"
    },
    {
      "code": "87060041",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8704: FOR THREE-WHEELED MOTOR VEHICLE"
    },
    {
      "code": "87060042",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8704: FOR VEHICLES, OTHER THAN PETROL DRIVEN"
    },
    {
      "code": "87060043",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8704: FOR DUMPERS COVERED IN THE HEADING 8704"
    },
    {
      "code": "87060049",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8704: OTHER"
    },
    {
      "code": "87060050",
      "description": "8706 CHASSIS FITTED WITH ENGINES, FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE MOTOR VEHICLES OF HEADING 8705"
    },
    {
      "code": "8707",
      "description": "BODIES (INCLUDING CABS), FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705"
    },
    {
      "code": "87071000",
      "description": "BODIES (INCLUDING CABS), FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 FOR THE VEHICLES OF HEADING 8703"
    },
    {
      "code": "87079000",
      "description": "BODIES (INCLUDING CABS), FOR THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 OTHER"
    },
    {
      "code": "8708",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705"
    },
    {
      "code": "870810",
      "description": "Bumpers and parts thereof"
    },
    {
      "code": "87081010",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 BUMPERS AND PARTS THEREOF FOR TRACTORS"
    },
    {
      "code": "87081090",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 BUMPERS AND PARTS THEREOF OTHER"
    },
    {
      "code": "87082100",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 OTHER PARTS AND ACCESSORIES OF BODIES (INCLUDING CABS) : SAFETY SEAT BELTS"
    },
    {
      "code": "87082900",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES OF BODIES (INCLUDING CABS) -- OTHER"
    },
    {
      "code": "87083000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - BRAKES AND SERVO-BRAKES ; PARTS THEREOF"
    },
    {
      "code": "87083100",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 BRAKES AND SERVO-BRAKES AND PARTS THEREOF MOUNTED BRAKE LININGS"
    },
    {
      "code": "87083900",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 BRAKES AND SERVO-BRAKES AND PARTS THEREOF OTHER"
    },
    {
      "code": "87084000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - GEAR BOXES AND PARTS THEREOF"
    },
    {
      "code": "87085000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - DRIVE-AXLES WITH DIFFERENTIAL, WHETHER OR NOT PROVIDED WITH OTHER TRANSMISSION COMPONENTS, AND NON-DRIVING AXLES, PARTS THEREOF"
    },
    {
      "code": "87086000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 BRAKES AND SERVO-BRAKES AND PARTS THEREOF NON-DRIVING AXLES AND PARTS THEREOF"
    },
    {
      "code": "87087000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - ROAD WHEELS AND PARTS AND ACCESSORIES THEREOF"
    },
    {
      "code": "87088000",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - SUSPENSION SYSTEMS AND PARTS THEREOF (INCLUDING SHOCK ABSORBERS)"
    },
    {
      "code": "87089100",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- RADIATORS AND PARTS THEREOF"
    },
    {
      "code": "87089200",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- SILENCERS (MUFFLERS) AND EXHAUST PIPES; PARTS THEREOF"
    },
    {
      "code": "87089300",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- CLUTCHES AND PARTS THEREOF"
    },
    {
      "code": "87089400",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- STEERING WHEELS, STEERING COLUMNS AND STEERING BOXES, PARTS THEREOF"
    },
    {
      "code": "87089500",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- SAFETY AIRBAGS WITH INFLATER SYSTEM; PARTS THEREOF"
    },
    {
      "code": "87089900",
      "description": "PARTS AND ACCESSORIES OF THE MOTOR VEHICLES OF HEADINGS 8701 TO 8705 - OTHER PARTS AND ACCESSORIES -- OTHER"
    },
    {
      "code": "8709",
      "description": "WORKS TRUCKS, SELF-PROPELLED, NOT FITTED WITH LIFTING OR HANDLING EQUIPMENT, OF THE TYPE USED IN FACTORIES, WAREHOUSES, DOCK AREAS OR AIRPORTS FOR SHORT DISTANCE TRANSPORT OF GOODS; TRACTORS OF THE TYPE USED ON RAILWAY STATION PLATFORMS; PARTS OF THE FOREGOING VEHICLES - Vehicles"
    },
    {
      "code": "87091100",
      "description": "WORKS TRUCKS, SELF-PROPELLED, NOT FITTED WITH LIFTING OR HANDLING EQUIPMENT, OF THE TYPE USED IN FACTORIES, WAREHOUSES, DOCK AREAS OR AIRPORTS FOR SHORT DISTANCE TRANSPORT OF GOODS; TRACTORS OF THE TYPE USED ON RAILWAY STATION PLATFORMS; PARTS OF THE FOREGOING VEHICLES VEHICLES : ELECTRICAL"
    },
    {
      "code": "87091900",
      "description": "WORKS TRUCKS, SELF-PROPELLED, NOT FITTED WITH LIFTING OR HANDLING EQUIPMENT, OF THE TYPE USED IN FACTORIES, WAREHOUSES, DOCK AREAS OR AIRPORTS FOR SHORT DISTANCE TRANSPORT OF GOODS; TRACTORS OF THE TYPE USED ON RAILWAY STATION PLATFORMS; PARTS OF THE FOREGOING VEHICLES VEHICLES : OTHER"
    },
    {
      "code": "87099000",
      "description": "WORKS TRUCKS, SELF-PROPELLED, NOT FITTED WITH LIFTING OR HANDLING EQUIPMENT, OF THE TYPE USED IN FACTORIES, WAREHOUSES, DOCK AREAS OR AIRPORTS FOR SHORT DISTANCE TRANSPORT OF GOODS; TRACTORS OF THE TYPE USED ON RAILWAY STATION PLATFORMS; PARTS OF THE FOREGOING VEHICLES PARTS"
    },
    {
      "code": "8710",
      "description": "TANKS AND OTHER ARMOURED FIGHTING VEHICLES, MOTORISED, WHETHER OR NOT FITTED WITH WEAPONS, AND PARTS OF SUCH VEHICLES"
    },
    {
      "code": "87100000",
      "description": "TANKS AND OTHER ARMOURED FIGHTING VEHICLES, MOTORISED, WHETHER OR NOT FITTED WITH WEAPONS, AND PARTS OF SUCH VEHICLES"
    },
    {
      "code": "8711",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS;"
    },
    {
      "code": "871110",
      "description": "With reciprocating internal combustion piston engine of a cylinder capacity not exceeding 50 cc"
    },
    {
      "code": "87111010",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY NOT EXCEEDING 50 CC: MOPEDS"
    },
    {
      "code": "87111020",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY NOT EXCEEDING 50 CC MOTORISED CYCLES"
    },
    {
      "code": "87111090",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY NOT EXCEEDING 50 CC OTHER"
    },
    {
      "code": "871120",
      "description": "With reciprocating internal combustion piston engine of a cylinder capacity exceeding 50 cc but not exceeding 250 cc"
    },
    {
      "code": "87112011",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : SCOOTERS : OF CYLINDER CAPACITY NOT EXCEEDING 75 CC"
    },
    {
      "code": "87112019",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : SCOOTERS : OTHER"
    },
    {
      "code": "87112021",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : MOTOR CYCLES OF CYLINDER CAPACITY NOT EXCEEDING 75 CC"
    },
    {
      "code": "87112029",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : MOTOR CYCLES OTHER"
    },
    {
      "code": "87112031",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : MOPEDS : OF CYLINDER CAPACITY NOT EXCEEDING 75 CC"
    },
    {
      "code": "87112039",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : MOPEDS : OTHER"
    },
    {
      "code": "87112091",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : OTHER : OF CYLINDER CAPACITY NOT EXCEEDING 75 CC"
    },
    {
      "code": "87112099",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 50 CC BUT NOT EXCEEDING 250 CC : OTHER : OTHER"
    },
    {
      "code": "871130",
      "description": "With reciprocating internal combustion piston engine of a cylinder capacity exceeding 250 cc but not exceeding 500 cc"
    },
    {
      "code": "87113010",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 500 CC : SCOOTERS"
    },
    {
      "code": "87113020",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 500 CC : MOTOR-CYCLES"
    },
    {
      "code": "87113090",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 250 CC BUT NOT EXCEEDING 500 CC : OTHER"
    },
    {
      "code": "871140",
      "description": "With reciprocating internal combustion piston engine of a cylinder capacity exceeding 500 cc but not exceeding 800 cc"
    },
    {
      "code": "87114010",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 500 CC BUT NOT EXCEEDING 800 CC : MOTOR-CYCLES"
    },
    {
      "code": "87114090",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 500 CC BUT NOT EXCEEDING 800 CC : OTHER"
    },
    {
      "code": "87115000",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; WITH RECIPROCATING INTERNAL COMBUSTION PISTON ENGINE OF A CYLINDER CAPACITY EXCEEDING 800 CC"
    },
    {
      "code": "871160",
      "description": "With electric motor for propulsion"
    },
    {
      "code": "87116010",
      "description": "Motor cycles"
    },
    {
      "code": "87116020",
      "description": "Scooters"
    },
    {
      "code": "87116030",
      "description": "Mopeds"
    },
    {
      "code": "87116090",
      "description": "Others"
    },
    {
      "code": "871190",
      "description": "Other"
    },
    {
      "code": "87119010",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; OTHER : SIDE-CARS"
    },
    {
      "code": "87119090",
      "description": "Other"
    },
    {
      "code": "87119091",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; OTHER : OTHER : ELECTRICALLY OPERATED"
    },
    {
      "code": "87119099",
      "description": "MOTORCYCLES (INCLUDING MOPEDS) AND CYCLES FITTED WITH AN AUXILIARY MOTOR, WITH OR WITHOUT SIDE-CARS; OTHER : OTHER : OTHER"
    },
    {
      "code": "8712",
      "description": "BICYCLES AND OTHER CYCLES (INCLUDING DELIVERY TRICYCLES), NOT MOTORISED"
    },
    {
      "code": "871200",
      "description": "Bicycles and other cycles (including delivery tricycles), not motorised"
    },
    {
      "code": "87120010",
      "description": "BICYCLES AND OTHER CYCLES (INCLUDING DELIVERY TRICYCLES), NOT MOTORISED BICYCLES AND OTHER CYCLES (INCLUDING DELIVERY TRICYCLES), NOT MOTORISED BICYCLES"
    },
    {
      "code": "87120090",
      "description": "BICYCLES AND OTHER CYCLES (INCLUDING DELIVERY TRICYCLES), NOT MOTORISED BICYCLES AND OTHER CYCLES (INCLUDING DELIVERY TRICYCLES), NOT MOTORIZED OTHER"
    },
    {
      "code": "8713",
      "description": "-"
    },
    {
      "code": "871310",
      "description": "Not mechanically propelled"
    },
    {
      "code": "87131010",
      "description": "CARRIAGES FOR DISABLED PERSONS, WHETHER OR NOT MOTORISED OR OTHERWISE MECHANICALLY PROPELLED NOT MECHANICALLY PROPELLED WHEEL CHAIRS FOR INVALID"
    },
    {
      "code": "87131090",
      "description": "CARRIAGES FOR DISABLED PERSONS, WHETHER OR NOT MOTORISED OR OTHERWISE MECHANICALLY PROPELLED NOT MECHANICALLY PROPELLED OTHER"
    },
    {
      "code": "871390",
      "description": "Other"
    },
    {
      "code": "87139010",
      "description": "CARRIAGES FOR DISABLED PERSONS, WHETHER OR NOT MOTORISED OR OTHERWISE MECHANICALLY PROPELLED OTHER WHEEL CHAIRS FOR INVALID"
    },
    {
      "code": "87139090",
      "description": "CARRIAGES FOR DISABLED PERSONS, WHETHER OR NOT MOTORISED OR OTHERWISE MECHANICALLY PROPELLED OTHER OTHER"
    },
    {
      "code": "8714",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711 TO 8713"
    },
    {
      "code": "871410",
      "description": "Of motorcycles (including mopeds)"
    },
    {
      "code": "87141010",
      "description": "SADDLES"
    },
    {
      "code": "87141090",
      "description": "OTHER"
    },
    {
      "code": "871420",
      "description": "Of carriages for disabled persons"
    },
    {
      "code": "87142010",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OF CARRIAGES FOR DISABLED PERSONS MECHANICALLY PROPELLED"
    },
    {
      "code": "87142020",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OF CARRIAGES FOR DISABLED PERSONS NON-MECHANICALLY PROPELLED"
    },
    {
      "code": "87142090",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OF CARRIAGES FOR DISABLED PERSONS OTHER"
    },
    {
      "code": "87149100",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OTHER : FRAMES AND FORKS, AND PARTS THEREOF"
    },
    {
      "code": "871492",
      "description": "Wheel rims and spokes"
    },
    {
      "code": "87149210",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 WHEEL RIMS AND SPOKES : BICYCLE RIMS"
    },
    {
      "code": "87149220",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 WHEEL RIMS AND SPOKES BICYCLE SPOKES"
    },
    {
      "code": "87149290",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 WHEEL RIMS AND SPOKES OTHER"
    },
    {
      "code": "871493",
      "description": "Hubs, other than coaster braking hubs and hub brakes, and free-wheel sprocket-wheels"
    },
    {
      "code": "87149310",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 HUBS, OTHER THAN COASTER BRAKING HUBS AND HUB BRAKES, AND FREE-WHEEL SPROCKET-WHEELS : BICYCLE HUBS"
    },
    {
      "code": "87149320",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 HUBS, OTHER THAN COASTER BRAKING HUBS AND HUB BRAKES, AND FREE-WHEEL SPROCKET-WHEELS : BICYCLE FREE-WHEELS"
    },
    {
      "code": "87149390",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 HUBS, OTHER THAN COASTER BRAKING HUBS AND HUB BRAKES, AND FREE-WHEEL SPROCKET-WHEELS : OTHER"
    },
    {
      "code": "87149400",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 BRAKES, INCLUDING COASTER BRAKING HUBS AND HUB BRAKES, AND PARTS THEREOF"
    },
    {
      "code": "871495",
      "description": "Saddles"
    },
    {
      "code": "87149510",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 SADDLES : BICYCLE SADDLES"
    },
    {
      "code": "87149590",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 SADDLES OTHER"
    },
    {
      "code": "87149600",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 PEDALS AND CRANK-GEAR, AND PARTS THEREOF"
    },
    {
      "code": "871499",
      "description": "Other"
    },
    {
      "code": "87149910",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OTHER : BICYCLE CHAINS"
    },
    {
      "code": "87149920",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OTHER BICYCLE WHEELS"
    },
    {
      "code": "87149990",
      "description": "PARTS AND ACCESSORIES OF VEHICLES OF HEADINGS 8711TO 8713 OTHER OTHER"
    },
    {
      "code": "8715",
      "description": "BABY CARRIAGES AND PARTS THEREOF"
    },
    {
      "code": "871500",
      "description": "Baby carriages and parts thereof"
    },
    {
      "code": "87150010",
      "description": "BABY CARRIAGES AND PARTS THEREOF BABY CARRIAGES AND PARTS THEREOF: BABY CARRIAGES"
    },
    {
      "code": "87150020",
      "description": "BABY CARRIAGES AND PARTS THEREOF BABY CARRIAGES AND PARTS THEREOF PARTS"
    },
    {
      "code": "8716",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF"
    },
    {
      "code": "87161000",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF TRAILERS AND SEMI-TRAILERS OF THE CARAVAN TYPE, FOR HOUSING OR CAMPING"
    },
    {
      "code": "87162000",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF SELF-LOADING OR SELF-UNLOADING TRAILERS AND SEMI-TRAILERS FOR AGRICULTURAL PURPOSES"
    },
    {
      "code": "87163100",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER TRAILERS AND SEMI-TRAILERS FOR THE TRANSPORT OF GOODS : TANKER TRAILERS AND TANKER SEMI-TRAILERS"
    },
    {
      "code": "87163900",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER TRAILERS AND SEMI-TRAILERS FOR THE TRANSPORT OF GOODS : OTHER"
    },
    {
      "code": "87164000",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER TRAILERS AND SEMI-TRAILERS FOR THE TRANSPORT OF GOODS : OTHER TRAILERS AND SEMI-TRAILERS"
    },
    {
      "code": "871680",
      "description": "Other vehicles"
    },
    {
      "code": "87168010",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER VEHICLES HAND PROPELLED VEHICLES (E.G. HAND CARTS, RICKSHAWS AND THE LIKE)"
    },
    {
      "code": "87168020",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER VEHICLES ANIMAL DRAWN VEHICLES"
    },
    {
      "code": "87168090",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF OTHER VEHICLES OTHER"
    },
    {
      "code": "871690",
      "description": "Parts"
    },
    {
      "code": "87169010",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF PARTS : PARTS AND ACCESSORIES OF TRAILERS"
    },
    {
      "code": "87169090",
      "description": "TRAILERS AND SEMI-TRAILERS; OTHER VEHICLES, NOT MECHANICALLY PROPELLED; PARTS THEREOF PARTS OTHER"
    },
    {
      "code": "88",
      "description": "Aircraft, spacecraft, and parts thereof"
    },
    {
      "code": "8801",
      "description": "BALLOONS AND DIRIGIBLES, GLIDERS, HAND GLIDERS AND OTHER NON-POWERED AIRCRAFT"
    },
    {
      "code": "880100",
      "description": "Balloons and dirigibles; gliders, hang gliders and other non-powered aircraft"
    },
    {
      "code": "88010010",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT -BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT:--- GLIDERS AND HANG GLIDERS"
    },
    {
      "code": "88010020",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT-BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT: --BALLOONS"
    },
    {
      "code": "88010090",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT-BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT:---OTHER"
    },
    {
      "code": "88011000",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT GLIDERS AND HANG GLIDERS"
    },
    {
      "code": "88019010",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT - OTHER: BALLOONS"
    },
    {
      "code": "88019090",
      "description": "BALLOONS AND DIRIGIBLES; GLIDERS, HANG GLIDERS AND OTHER NON-POWERED AIRCRAFT - OTHER: OTHER"
    },
    {
      "code": "8802",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES - Helicopter"
    },
    {
      "code": "88021100",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES - HELICOPTERS: OF AN UNLADEN WEIGHT NOT EXCEEDING 2,000 KG"
    },
    {
      "code": "88021200",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES - HELICOPTERS: OF AN UNLADEN WEIGHT EXCEEDING 2,000 KG"
    },
    {
      "code": "88022000",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES AEROPLANES AND OTHER AIRCRAFT, OF AN UNLADEN WEIGHT NOT EXCEEDING 2,000 KG"
    },
    {
      "code": "88023000",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES AEROPLANES AND OTHER AIRCRAFT, OF AN UNLADEN WEIGHT EXCEEDING 2,000 KG BUT NOT EXCEEDING 15,000 KG"
    },
    {
      "code": "88024000",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES AEROPLANES AND OTHER AIRCRAFT, OF AN UNLADEN WEIGHT EXCEEDING 15,000 KG"
    },
    {
      "code": "88026000",
      "description": "OTHER AIRCRAFT (FOR EXAMPLE, HELICOPTERS, AEROPLANES); SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES SPACECRAFT (INCLUDING SATELLITES) AND SUBORBITAL AND SPACECRAFT LAUNCH VEHICLES"
    },
    {
      "code": "8803",
      "description": "PARTS OF GOODS OF HEADING 8801 OR 8802"
    },
    {
      "code": "88031000",
      "description": "PARTS OF GOODS OF HEADING 8801 OR 8802 - - PROPELLERS AND ROTORS AND PARTS THEREOF"
    },
    {
      "code": "88032000",
      "description": "PARTS OF GOODS OF HEADING 8801 OR 8802 - - UNDER-CARRIAGES AND PARTS THEREOF"
    },
    {
      "code": "88033000",
      "description": "PARTS OF GOODS OF HEADING 8801 OR 8802 - - OTHER PARTS OF AEROPLANES OR HELICOPTERS"
    },
    {
      "code": "88039000",
      "description": "PARTS OF GOODS OF HEADING 8801 OR 8802 - OTHER"
    },
    {
      "code": "8804",
      "description": "PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND ROTOCHUTES; PARTS THEREOF AND ACCESSORIES THERETO"
    },
    {
      "code": "880400",
      "description": "Parachutes (including dirigible parachutes and paragliders) and rotochutes; parts thereof and accessories thereto"
    },
    {
      "code": "88040010",
      "description": "PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND ROTOCHUTES; PARTS THEREOF AND ACCESSORIES THERETO - PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND ROTOCHUTES; PARTS THEREOF AND ACCESSORIES THERETO: PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND PARTS AND ACCESSORIES THEREOF"
    },
    {
      "code": "88040020",
      "description": "PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND ROTOCHUTES; PARTS THEREOF AND ACCESSORIES THERETO - PARACHUTES (INCLUDING DIRIGIBLE PARACHUTES AND PARAGLIDERS) AND ROTOCHUTES; PARTS THEREOF AND ACCESSORIES THERETO: ROTOCHUTES; PARTS AND ACCESSORIES THEREOF"
    },
    {
      "code": "8805",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES"
    },
    {
      "code": "880510",
      "description": "Aircraft launching gear and parts thereof; deck arrestor or similar gear and parts thereof"
    },
    {
      "code": "88051010",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES - AIRCRAFT LAUNCHING GEAR AND PARTS THEREOF; DECK ARRESTOR OR SIMILAR GEAR AND PARTS THEREOF: AIRCRAFT LAUNCHING GEAR"
    },
    {
      "code": "88051020",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES - AIRCRAFT LAUNCHING GEAR AND PARTS THEREOF; DECK ARRESTOR OR SIMILAR GEAR AND PARTS THEREOF: DECK ARRESTOR OR SIMILAR GEAR"
    },
    {
      "code": "88051030",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES - AIRCRAFT LAUNCHING GEAR AND PARTS THEREOF; DECK ARRESTOR OR SIMILAR GEAR AND PARTS THEREOF: PART OF AIRCRAFT LAUNCHING GEAR AND DECK-ARRESTOR OR SIMILAR GEAR"
    },
    {
      "code": "88052100",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES - GROUND FLYING TRAINERS AND PARTS THEREOF: AIR COMBAT SIMULATORS AND PARTS THEREOF"
    },
    {
      "code": "88052900",
      "description": "AIRCRAFT LAUNCHING GEAR; DECK-ARRESTOR OR SIMILAR GEAR; GROUND FLYING TRAINERS; PARTS OF THE FOREGOING ARTICLES - GROUND FLYING TRAINERS AND PARTS THEREOF: OTHER"
    },
    {
      "code": "89",
      "description": "Ships, boats and floating structures"
    },
    {
      "code": "8901",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS"
    },
    {
      "code": "890110",
      "description": "Cruise ships, excursion boats and similar vessels principally designed for the transport of persons; ferryboats of all kinds"
    },
    {
      "code": "89011010",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - CRUISE SHIPS, EXCURSION BOATS AND SIMILAR VESSELS PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS; FERRY-BOATS OF ALL KINDS: SHIPS"
    },
    {
      "code": "89011020",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - CRUISE SHIPS, EXCURSION BOATS AND SIMILAR VESSELS PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS; FERRY-BOATS OF ALL KINDS: LAUNCHES"
    },
    {
      "code": "89011030",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - CRUISE SHIPS, EXCURSION BOATS AND SIMILAR VESSELS PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS; FERRY-BOATS OF ALL KINDS: BOATS"
    },
    {
      "code": "89011040",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - CRUISE SHIPS, EXCURSION BOATS AND SIMILAR VESSELS PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS; FERRY-BOATS OF ALL KINDS: BARGES"
    },
    {
      "code": "89011090",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - CRUISE SHIPS, EXCURSION BOATS AND SIMILAR VESSELS PRINCIPALLY DESIGNED FOR THE TRANSPORT OF PERSONS; FERRY-BOATS OF ALL KINDS: OTHER"
    },
    {
      "code": "89012000",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - TANKERS"
    },
    {
      "code": "89013000",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - REFRIGERATED VESSELS, OTHER THAN THOSE OF SUB-HEADING 8901 20"
    },
    {
      "code": "89019000",
      "description": "CRUISE SHIPS, EXCURSION BOATS , FERRY-BOATS, CARGO SHIPS, BARGES AND SIMILAR VESSELS FOR THE TRANSPORT OF PERSONS OR GOODS - OTHER VESSELS FOR TRANSPORT OF THE GOODS AND OTHER VESSELS FOR THE TRANSPORT OF BOTH PERSONS AND GOODS"
    },
    {
      "code": "8902",
      "description": "FISHING VESSELS; FACTORY SHIPS AND OTHER VESSELS FOR PROCESSING OR PRESERVING FISHERY PRODUCTS"
    },
    {
      "code": "890200",
      "description": "Fishing vessels; factory ships and other vessels for processing or preserving fishery products"
    },
    {
      "code": "89020010",
      "description": "FISHING VESSELS; FACTORY SHIPS AND OTHER VESSELS FOR PROCESSING OR PRESERVING FISHERY PRODUCTS 8902 00 - FISHING VESSELS; FACTORY SHIPS AND OTHER VESSELS FOR PROCESSING OR PRESERVING FISHERY PRODUCTS : TRAWLERS AND OTHER FISHING VESSELS"
    },
    {
      "code": "89020090",
      "description": "FISHING VESSELS; FACTORY SHIPS AND OTHER VESSELS FOR PROCESSING OR PRESERVING FISHERY PRODUCTS 8902 00 - FISHING VESSELS; FACTORY SHIPS AND OTHER VESSELS FOR PROCESSING OR PRESERVING FISHERY PRODUCTS : OTHER"
    },
    {
      "code": "8903",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES"
    },
    {
      "code": "89031000",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES INFLATABLE"
    },
    {
      "code": "89039100",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES - OTHER : SAIL BOATS, WITH OR WITHOUT AUXILIARY MOTOR"
    },
    {
      "code": "89039200",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES - OTHER : MOTORBOATS, OTHER THAN OUTBOARD MOTORBOATS"
    },
    {
      "code": "890399",
      "description": "Other"
    },
    {
      "code": "89039910",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES - OTHER : - OTHER : CANOES"
    },
    {
      "code": "89039990",
      "description": "YACHTS AND OTHER VESSELS FOR PLEASURE OR SPORTS; ROWING BOATS AND CANOES - OTHER : - OTHER : OTHER"
    },
    {
      "code": "8904",
      "description": "TUGS AND PUSHER CRAFT"
    },
    {
      "code": "89040000",
      "description": "TUGS AND PUSHER CRAFT"
    },
    {
      "code": "8905",
      "description": "LIGHT-VESSELS, FIRE-FLOATS, DREDGERS, FLOATING CRANES, AND OTHER VESSELS THE NAVIGABILITY OF WHICH IS SUBSIDIARY TO THEIR MAIN FUNCTION; FLOATING DOCKS; FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS"
    },
    {
      "code": "89051000",
      "description": "LIGHT-VESSELS, FIRE-FLOATS, DREDGERS, FLOATING CRANES, AND OTHER VESSELS THE NAVIGABILITY OF WHICH IS SUBSIDIARY TO THEIR MAIN FUNCTION; FLOATING DOCKS; FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS DREDGERS"
    },
    {
      "code": "89052000",
      "description": "LIGHT-VESSELS, FIRE-FLOATS, DREDGERS, FLOATING CRANES, AND OTHER VESSELS THE NAVIGABILITY OF WHICH IS SUBSIDIARY TO THEIR MAIN FUNCTION; FLOATING DOCKS; FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS"
    },
    {
      "code": "890590",
      "description": "Other"
    },
    {
      "code": "89059010",
      "description": "LIGHT-VESSELS, FIRE-FLOATS, DREDGERS, FLOATING CRANES, AND OTHER VESSELS THE NAVIGABILITY OF WHICH IS SUBSIDIARY TO THEIR MAIN FUNCTION; FLOATING DOCKS; FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS - OTHER : FLOATING DOCKS"
    },
    {
      "code": "89059090",
      "description": "LIGHT-VESSELS, FIRE-FLOATS, DREDGERS, FLOATING CRANES, AND OTHER VESSELS THE NAVIGABILITY OF WHICH IS SUBSIDIARY TO THEIR MAIN FUNCTION; FLOATING DOCKS; FLOATING OR SUBMERSIBLE DRILLING OR PRODUCTION PLATFORMS - OTHER : OTHER"
    },
    {
      "code": "8906",
      "description": "OTHER VESSELS, INCLUDING WARSHIPS AND LIFEBOATS OTHER THAN ROWING BOATS"
    },
    {
      "code": "89061000",
      "description": "OTHER VESSELS, INCLUDING WARSHIPS AND LIFEBOATS OTHER THAN ROWING BOATS WARSHIPS"
    },
    {
      "code": "89069000",
      "description": "OTHER VESSELS, INCLUDING WARSHIPS AND LIFEBOATS OTHER THAN ROWING BOATS OTHER"
    },
    {
      "code": "8907",
      "description": "OTHER FLOATING STRUCTURES ( FOR EXAMPLE, RAFTS, TANKS, COFFER-DAMS, LANDING-STAGES, BUOYS AND BEACONS)"
    },
    {
      "code": "89071000",
      "description": "OTHER FLOATING STRUCTURES ( FOR EXAMPLE, RAFTS, TANKS, COFFER-DAMS, LANDING-STAGES, BUOYS AND BEACONS) INFLATABLE RAFTS"
    },
    {
      "code": "89079000",
      "description": "OTHER FLOATING STRUCTURES ( FOR EXAMPLE, RAFTS, TANKS, COFFER-DAMS, LANDING-STAGES, BUOYS AND BEACONS) OTHER"
    },
    {
      "code": "8908",
      "description": "VESSELS AND OTHER FLOATING STRUCTURES FOR BREAKING UP"
    },
    {
      "code": "89080000",
      "description": "VESSELS AND OTHER FLOATING STRUCTURES FOR BREAKING UP"
    },
    {
      "code": "90",
      "description": "Optical, photographic, cinematographic, measuring, checking, precision, medical or surgical instruments and apparatus; parts and accessories thereof"
    },
    {
      "code": "9001",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED"
    },
    {
      "code": "90011000",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED OPTICAL FIBRES, OPTICAL FIBRES BUNDLES AND CABLES"
    },
    {
      "code": "90012000",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED SHEETS AND PLATES OF POLARISING MATERIAL"
    },
    {
      "code": "90013000",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED CONTACT LENSES"
    },
    {
      "code": "900140",
      "description": "Spectacle lenses of glass"
    },
    {
      "code": "90014010",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - SPECTACLE LENSES OF GLASS : POLARISED GLASS"
    },
    {
      "code": "90014090",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - SPECTACLE LENSES OF GLASS : OTHER"
    },
    {
      "code": "90015000",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED SPECTACLE LENSES OF OTHER MATERIALS"
    },
    {
      "code": "900190",
      "description": "Other"
    },
    {
      "code": "90019010",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - OTHER: OPTICAL CALCITE CRYSTAL"
    },
    {
      "code": "90019090",
      "description": "OPTICAL FIBRES AND OPTICAL FIBRE BUNDLES; OPTICAL FIBRE CABLES OTHER THAN THOSE OF HEADING 8544; SHEETS AND PLATES OF POLARISING MATERIAL; LENSES (INCLUDING CONTACT LENSES), PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, UNMOUNTED, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - OTHER: OTHER"
    },
    {
      "code": "9002",
      "description": "LENSES, PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, MOUNTED, BEING PARTS OF OR FITTINGS FOR INSTRUMENTS OR APPARATUS, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - Objective lenses"
    },
    {
      "code": "90021100",
      "description": "LENSES, PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, MOUNTED, BEING PARTS OF OR FITTINGS FOR INSTRUMENTS OR APPARATUS, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - OBJECTIVE LENSES: FOR CAMERAS, PROJECTORS OR PHOTOGRAPHIC ENLARGERS OR REDUCERS"
    },
    {
      "code": "90021900",
      "description": "LENSES, PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, MOUNTED, BEING PARTS OF OR FITTINGS FOR INSTRUMENTS OR APPARATUS, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - OBJECTIVE LENSES: OTHER"
    },
    {
      "code": "90022000",
      "description": "LENSES, PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, MOUNTED, BEING PARTS OF OR FITTINGS FOR INSTRUMENTS OR APPARATUS, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED - OBJECTIVE LENSES: FILTERS"
    },
    {
      "code": "90029000",
      "description": "LENSES, PRISMS, MIRRORS AND OTHER OPTICAL ELEMENTS, OF ANY MATERIAL, MOUNTED, BEING PARTS OF OR FITTINGS FOR INSTRUMENTS OR APPARATUS, OTHER THAN SUCH ELEMENTS OF GLASS NOT OPTICALLY WORKED OTHER"
    },
    {
      "code": "9003",
      "description": "FRAMES AND MOUNTINGS FOR SPECTACLES, GOGGLES OR THE LIKE, AND PARTS THEREOF - Frames and mountings"
    },
    {
      "code": "90031100",
      "description": "FRAMES AND MOUNTINGS FOR SPECTACLES, GOGGLES OR THE LIKE, AND PARTS THEREOF - FRAMES AND MOUNTINGS: OF PLASTICS"
    },
    {
      "code": "90031900",
      "description": "FRAMES AND MOUNTINGS FOR SPECTACLES, GOGGLES OR THE LIKE, AND PARTS THEREOF - FRAMES AND MOUNTINGS: OF OTHER MATERIALS"
    },
    {
      "code": "90039000",
      "description": "FRAMES AND MOUNTINGS FOR SPECTACLES, GOGGLES OR THE LIKE, AND PARTS THEREOF PARTS"
    },
    {
      "code": "9004",
      "description": "SPECTACLES, GOGGLES AND THE LIKE, CORRECTIVE, PROTECTIVE OR OTHER"
    },
    {
      "code": "90041000",
      "description": "SPECTACLES, GOGGLES AND THE LIKE, CORRECTIVE, PROTECTIVE OR OTHER SUNGLASSES"
    },
    {
      "code": "900490",
      "description": "Other"
    },
    {
      "code": "90049010",
      "description": "SPECTACLES, GOGGLES AND THE LIKE, CORRECTIVE, PROTECTIVE OR OTHER - OTHER: PASSIVE NIGHT VISION GOGGLES"
    },
    {
      "code": "90049020",
      "description": "SPECTACLES, GOGGLES AND THE LIKE, CORRECTIVE, PROTECTIVE OR OTHER - OTHER: PRISMATIC EYEGLASSES FOR READING"
    },
    {
      "code": "90049090",
      "description": "SPECTACLES, GOGGLES AND THE LIKE, CORRECTIVE, PROTECTIVE OR OTHER - OTHER: OTHER"
    },
    {
      "code": "9005",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY"
    },
    {
      "code": "90051000",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY BINOCULARS"
    },
    {
      "code": "900580",
      "description": "Other instruments"
    },
    {
      "code": "90058010",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY - OTHER INSTRUMENTS: MONOCULAR AND REFRACTING TELESCOPES"
    },
    {
      "code": "90058020",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY - OTHER INSTRUMENTS: ASTRONOMICAL INSTRUMENTS"
    },
    {
      "code": "90058090",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY - OTHER INSTRUMENTS: OTHER"
    },
    {
      "code": "900590",
      "description": "Parts and accessories (including mountings)"
    },
    {
      "code": "90059010",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY PARTS AND ACCESSORIES (INCLUDING MOUNTINGS): OF BINOCULAR OR TELESCOPES OF HEADING 9005, OTHER THAN MOUNTINGS"
    },
    {
      "code": "90059020",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY PARTS AND ACCESSORIES (INCLUDING MOUNTINGS): MOUNTINGS FOR ASTRONOMICAL INSTRUMENTS"
    },
    {
      "code": "90059090",
      "description": "BINOCULARS, MONOCULARS, OTHER OPTICAL TELESCOPES, AND MOUNTINGS THEREFOR; OTHER ASTRONOMICAL INSTRUMENTS AND MOUNTINGS THEREFOR, BUT NOT INCLUDING INSTRUMENTS FOR RADIO-ASTRONOMY PARTS AND ACCESSORIES (INCLUDING MOUNTINGS): OTHER"
    },
    {
      "code": "9006",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539"
    },
    {
      "code": "90061000",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 CAMERAS OF A KIND USED FOR PREPARING PRINTING PLATES OR CYLINDERS"
    },
    {
      "code": "90062000",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 CAMERAS OF A KIND USED FOR RECORDING DOCUMENTS ON MICROFILM, MICROFICHE OR OTHER MICROFORMS"
    },
    {
      "code": "90063000",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 CAMERAS SPECIALLY DESIGNED FOR UNDERWATER USE, FOR AERIAL SURVEY OR FOR MEDICAL OR SURGICAL EXAMINATION OF INTERNAL ORGANS; COMPARISON CAMERAS FOR FORENSIC OR CRIMINOLOGICAL PURPOSES"
    },
    {
      "code": "90064000",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 INSTANT PRINT CAMERAS"
    },
    {
      "code": "90065100",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: WITH A THROUGH-THE-LENS VIEW-FINDER [SINGLE LENS REFLEX (SLR)] FOR ROLL FILM OF A WIDTH NOT EXCEEDING 35 MM"
    },
    {
      "code": "90065200",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: OTHER, FOR ROLL FILM OF A WIDTH LESS THAN 35 MM"
    },
    {
      "code": "900653",
      "description": "Other, for roll film of a width of 35 mm"
    },
    {
      "code": "90065310",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: - OTHER, FOR ROLL FILM OF A WIDTH OF 35 MM: FIXED FOCUS 35 MM CAMERAS"
    },
    {
      "code": "90065390",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: - OTHER, FOR ROLL FILM OF A WIDTH OF 35 MM: OTHER"
    },
    {
      "code": "900659",
      "description": "Other"
    },
    {
      "code": "90065910",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: - OTHER: FIXED FOCUS 110 MM CAMERAS"
    },
    {
      "code": "90065990",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - OTHER CAMERAS: - OTHER: OTHER"
    },
    {
      "code": "90066100",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASHBULBS: DISCHARGE LAMP (\"ELECTRONIC\") FLASHLIGHT APPARATUS"
    },
    {
      "code": "90066200",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASHBULBS: FLASHBULBS, FLASHCUBES AND THE LIKE"
    },
    {
      "code": "90066900",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASHBULBS: OTHER"
    },
    {
      "code": "90069100",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - PARTS AND ACCESSORIES: FOR CAMERAS"
    },
    {
      "code": "90069900",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) CAMERAS; PHOTOGRAPHIC FLASHLIGHT APPARATUS AND FLASH BULBS OTHER THAN DISCHARGE LAMPS OF HEADING 8539 - PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9007",
      "description": "CINEMATOGRAPHIC CAMERAS AND PROJECTORS, WHETHER OR NOT INCORPORATING SOUND RECORDING OR REPRODUCING APPARATUS"
    },
    {
      "code": "900710",
      "description": "Cameras"
    },
    {
      "code": "90071010",
      "description": "FOR FILM OF LESS THAN 16 MM WIDTH OR FOR DOUBLE- 8 MM FILM"
    },
    {
      "code": "90071090",
      "description": "OTHER"
    },
    {
      "code": "900720",
      "description": "Projectors"
    },
    {
      "code": "90072010",
      "description": "CINEMATOGRAPHIC CAMERAS AND PROJECTORS, WHETHER OR NOT INCORPORATING SOUND RECORDING OR REPRODUCING APPARATUS - PROJECTORS: FOR FILM OF LESS THAN 16 MM WIDTH"
    },
    {
      "code": "90072090",
      "description": "CINEMATOGRAPHIC CAMERAS AND PROJECTORS, WHETHER OR NOT INCORPORATING SOUND RECORDING OR REPRODUCING APPARATUS - PROJECTORS: OTHER"
    },
    {
      "code": "90079100",
      "description": "CINEMATOGRAPHIC CAMERAS AND PROJECTORS, WHETHER OR NOT INCORPORATING SOUND RECORDING OR REPRODUCING APPARATUS - PARTS AND ACCESSORIES: FOR CAMERAS"
    },
    {
      "code": "90079200",
      "description": "CINEMATOGRAPHIC CAMERAS AND PROJECTORS, WHETHER OR NOT INCORPORATING SOUND RECORDING OR REPRODUCING APPARATUS - PARTS AND ACCESSORIES: FOR PROJECTORS"
    },
    {
      "code": "9008",
      "description": "IMAGE PROJECTORS, OTHER THAN CINEMATOGRAPHIC; PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) ENLARGERS AND REDUCERS"
    },
    {
      "code": "900850",
      "description": "Projectors, enlargers and reducers"
    },
    {
      "code": "90085010",
      "description": "SLIDE PROJECTORS"
    },
    {
      "code": "90085020",
      "description": "MICROFILM, MICROFICHE OR OTHER MICROFORM READERS, WHETHER OR NOT CAPABLE OF PRODUCING COPIES"
    },
    {
      "code": "90085030",
      "description": "OTHER IMAGE PROJECTORS"
    },
    {
      "code": "90085040",
      "description": "PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) ENLARGERS AND REDUCERS"
    },
    {
      "code": "90089000",
      "description": "IMAGE PROJECTORS, OTHER THAN CINEMATOGRAPHIC; PHOTOGRAPHIC (OTHER THAN CINEMATOGRAPHIC) ENLARGERS AND REDUCERS PARTS AND ACCESSORIES"
    },
    {
      "code": "9009",
      "description": "OMITTED"
    },
    {
      "code": "90091100",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - ELECTROSTATIC PHOTOCOPYING APPARATUS: OPERATING BY REPRODUCING THE ORIGINAL IMAGE DIRECTLY ONTO THE COPY (DIRECT PROCESS)"
    },
    {
      "code": "90091200",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - ELECTROSTATIC PHOTOCOPYING APPARATUS: OPERATING BY REPRODUCING THE ORIGINAL IMAGE VIA AN INTERMEDIATE ONTO THE COPY (INDIRECT PROCESS)"
    },
    {
      "code": "90092100",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - OTHER PHOTOCOPYING APPARATUS: INCORPORATING AN OPTICAL SYSTEM"
    },
    {
      "code": "90092200",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - OTHER PHOTOCOPYING APPARATUS: OF THE CONTACT TYPE"
    },
    {
      "code": "90093000",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS THERMO-COPYING APPARATUS"
    },
    {
      "code": "90099100",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - PARTS AND ACCESSORIES: AUTOMATIC DOCUMENT FEEDERS"
    },
    {
      "code": "90099200",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - PARTS AND ACCESSORIES: PAPER FEEDERS"
    },
    {
      "code": "90099300",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - PARTS AND ACCESSORIES: SORTERS"
    },
    {
      "code": "90099900",
      "description": "PHOTOCOPYING APPARATUS INCORPORATING AN OPTICAL SYSTEM OR OF THE CONTACT TYPE AND THERMO-COPYING APPARATUS - PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9010",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS"
    },
    {
      "code": "90101000",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS - APPARATUS AND EQUIPMENT FOR AUTOMATICALLY DEVELOPING PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) FILM OR PAPER IN ROLLS OR FOR AUTOMATICALLY EXPOSING DEVELOPED FILM TO ROLLS OF PHOTOGRAPHIC PAPER"
    },
    {
      "code": "90104100",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS - APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS: DIRECT WRITE-ON-WAFER APPARATUS"
    },
    {
      "code": "90104200",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS - APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS: STEP AND REPEAT ALIGNERS"
    },
    {
      "code": "90104900",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS - APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS: OTHER"
    },
    {
      "code": "90105000",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS OTHER APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES; NEGATOSCOPES"
    },
    {
      "code": "90106000",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS PROJECTION SCREENS"
    },
    {
      "code": "90109000",
      "description": "APPARATUS AND EQUIPMENT FOR PHOTOGRAPHIC (INCLUDING CINEMATOGRAPHIC) LABORATORIES (INCLUDING APPARATUS FOR THE PROJECTION OR DRAWING OF CIRCUIT PATTERNS ON SENSITISED SEMICONDUCTOR MATERIALS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; NEGATOSCOPES; PROJECTION SCREENS PARTS AND ACCESSORIES"
    },
    {
      "code": "9011",
      "description": "COMPOUND OPTICAL MICROSCOPES, INCLUDING THOSE FOR PHOTOMICRO-GRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION"
    },
    {
      "code": "90111000",
      "description": "COMPOUND OPTICAL MICROSCOPES, INCLUDING THOSE FOR PHOTOMICRO-GRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION - STEREOSCOPIC MICROSCOPES"
    },
    {
      "code": "901111",
      "description": "Arabica plantation"
    },
    {
      "code": "901112",
      "description": "Arabica cherry"
    },
    {
      "code": "901113",
      "description": "Rob Parchment"
    },
    {
      "code": "901114",
      "description": "Rob cherry"
    },
    {
      "code": "90112000",
      "description": "COMPOUND OPTICAL MICROSCOPES, INCLUDING THOSE FOR PHOTOMICRO-GRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION - OTHER MICROSCOPES, FOR PHOTOMICROGRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION"
    },
    {
      "code": "90118000",
      "description": "COMPOUND OPTICAL MICROSCOPES, INCLUDING THOSE FOR PHOTOMICRO-GRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION - OTHER MICROSCOPES"
    },
    {
      "code": "90119000",
      "description": "COMPOUND OPTICAL MICROSCOPES, INCLUDING THOSE FOR PHOTOMICRO-GRAPHY, CINEPHOTOMICROGRAPHY OR MICROPROJECTION - PARTS AND ACCESSORIES"
    },
    {
      "code": "9012",
      "description": "MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS"
    },
    {
      "code": "901210",
      "description": "Microscopes other than optical microscopes; diffraction apparatus"
    },
    {
      "code": "90121010",
      "description": "MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS - MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS: ELECTRON MICROSCOPES FITTED WITH EQUIPMENT SPECIFICALLY DESIGNED FOR THE HANDLING AND TRANSPORT OF SEMICONDUCTOR WAFERS OR RETICLES"
    },
    {
      "code": "90121090",
      "description": "MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS - MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS: OTHER"
    },
    {
      "code": "90129000",
      "description": "MICROSCOPES OTHER THAN OPTICAL MICROSCOPES; DIFFRACTION APPARATUS PARTS AND ACCESSORIES"
    },
    {
      "code": "9013",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER"
    },
    {
      "code": "901310",
      "description": "Telescopic sights for fitting to arms; periscopes; telescopes designed to form parts of machines, appliances, instruments or apparatus of this Chapter or Section XVI"
    },
    {
      "code": "90131010",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - TELESCOPIC SIGHTS FOR FITTING TO ARMS; PERISCOPES; TELESCOPES DESIGNED TO FORM PARTS OF MACHINES, APPLIANCES, INSTRUMENTS OR APPARATUS OF THIS CHAPTER OR SECTION XVI: TELESCOPIC SIGHTS FOR FITTING TO ARMS"
    },
    {
      "code": "90131020",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - TELESCOPIC SIGHTS FOR FITTING TO ARMS; PERISCOPES; TELESCOPES DESIGNED TO FORM PARTS OF MACHINES, APPLIANCES, INSTRUMENTS OR APPARATUS OF THIS CHAPTER OR SECTION XVI: PERISCOPES"
    },
    {
      "code": "90131090",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - TELESCOPIC SIGHTS FOR FITTING TO ARMS; PERISCOPES; TELESCOPES DESIGNED TO FORM PARTS OF MACHINES, APPLIANCES, INSTRUMENTS OR APPARATUS OF THIS CHAPTER OR SECTION XVI: OTHER"
    },
    {
      "code": "90132000",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER LASERS, OTHER THAN LASER DIODES"
    },
    {
      "code": "901380",
      "description": "Other devices, appliances and instruments"
    },
    {
      "code": "90138010",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - OTHER DEVICES, APPLIANCES AND INSTRUMENTS: LIQUID CRYSTAL DEVICES (LCD)"
    },
    {
      "code": "90138090",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - OTHER DEVICES, APPLIANCES AND INSTRUMENTS: OTHER"
    },
    {
      "code": "901390",
      "description": "Parts and accessories"
    },
    {
      "code": "90139010",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - PARTS AND ACCESSORIES: FOR LIQUID CRYSTAL DEVICES (LCD)"
    },
    {
      "code": "90139090",
      "description": "LIQUID CRYSTAL DEVICES NOT CONSTITUTING ARTICLES PROVIDED FOR MORE SPECIFICALLY IN OTHER HEADINGS; LASERS, OTHER THAN LASER DIODES; OTHER OPTICAL APPLIANCES AND INSTRUMENTS, NOT SPECIFIED OR INCLUDED ELSE WHERE IN THIS CHAPTER - PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9014",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES"
    },
    {
      "code": "90141000",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES DIRECTION FINDING COMPASSES"
    },
    {
      "code": "90142000",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES INSTRUMENTS AND APPLIANCES FOR AERONAUTICAL OR SPACE NAVIGATION (OTHER THAN COMPASSES)"
    },
    {
      "code": "901480",
      "description": "Other instruments and appliances"
    },
    {
      "code": "90148010",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES - OTHER INSTRUMENTS AND APPLIANCES: ECHO SOUNDING INSTRUMENT"
    },
    {
      "code": "90148020",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES - OTHER INSTRUMENTS AND APPLIANCES: SEXTANTS"
    },
    {
      "code": "90148090",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES - OTHER INSTRUMENTS AND APPLIANCES: OTHER"
    },
    {
      "code": "90149000",
      "description": "DIRECTION FINDING COMPASSES; OTHER NAVIGATIONAL INSTRUMENTS AND APPLIANCES PARTS AND ACCESSORIES"
    },
    {
      "code": "9015",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS"
    },
    {
      "code": "90151000",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS RANGEFINDERS"
    },
    {
      "code": "90152000",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS THEODOLITIES AND TACHYMETERS (TACHEOMETERS)"
    },
    {
      "code": "901530",
      "description": "Levels"
    },
    {
      "code": "90153010",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - LEVELS: DUMPY LEVELS OR ENGINEER'S LEVELS OR BUILDERS LEVELS (NOT AUTOMATIC) AND QUICK SET LEVELS WITH OR WITHOUT HORIZONTAL CIRCLES"
    },
    {
      "code": "90153090",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - LEVELS: OTHER"
    },
    {
      "code": "90154000",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS PHOTOGRAMMETRICAL SURVEYING INSTRUMENTS AND APPLIANCES"
    },
    {
      "code": "901580",
      "description": "Other instruments and appliances"
    },
    {
      "code": "90158010",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - OTHER INSTRUMENTS AND APPLIANCES: HYDROGRAPHIC INSTRUMENTS"
    },
    {
      "code": "90158020",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - OTHER INSTRUMENTS AND APPLIANCES: METEOROLOGICAL INSTRUMENTS"
    },
    {
      "code": "90158030",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - OTHER INSTRUMENTS AND APPLIANCES: GEOPHYSICAL INSTRUMENTS"
    },
    {
      "code": "90158090",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS - OTHER INSTRUMENTS AND APPLIANCES: OTHER"
    },
    {
      "code": "90159000",
      "description": "SURVEYING (INCLUDING PHOTOGRAMMETRICAL SURVEYING), HYDROGRAPHIC, OCEANOGRAPHIC, HYDROLOGICAL, METEOROLOGICAL OR GEOPHYSICAL INSTRUMENTS AND APPLIANCES, EXCLUDING COMPASSES; RANGEFINDERS PARTS AND ACCESSORIES"
    },
    {
      "code": "9016",
      "description": "BALANCES OF A SENSITIVITY OF 5 cg OR BETTER, WITH OR WITHOUT WEIGHTS"
    },
    {
      "code": "901600",
      "description": "Balances of a sensitivity of 5cg or better, with or without weights"
    },
    {
      "code": "90160010",
      "description": "BALANCES OF A SENSITIVITY OF 5 CG OR BETTER, WITH OR WITHOUT WEIGHTS - BALANCES OF A SENSITIVITY OF 5CG OR BETTER, WITH OR WITHOUT WEIGHTS: ELECTRIC BALANCES"
    },
    {
      "code": "90160020",
      "description": "BALANCES OF A SENSITIVITY OF 5 CG OR BETTER, WITH OR WITHOUT WEIGHTS - BALANCES OF A SENSITIVITY OF 5CG OR BETTER, WITH OR WITHOUT WEIGHTS: OTHER BALANCES"
    },
    {
      "code": "90160090",
      "description": "BALANCES OF A SENSITIVITY OF 5 CG OR BETTER, WITH OR WITHOUT WEIGHTS - BALANCES OF A SENSITIVITY OF 5CG OR BETTER, WITH OR WITHOUT WEIGHTS: PARTS"
    },
    {
      "code": "9017",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER"
    },
    {
      "code": "90171000",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER DRAFTING TABLES AND MACHINES, WHETHER OR NOT AUTOMATIC"
    },
    {
      "code": "901720",
      "description": "Other drawing, marking-out or mathematical calculating instruments"
    },
    {
      "code": "90172010",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS: DRAWING AND MARKING-OUT INSTRUMENTS"
    },
    {
      "code": "90172020",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS: MATHEMATICAL CALCULATING INSTRUMENTS"
    },
    {
      "code": "90172030",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS: PANTOGRAPH"
    },
    {
      "code": "90172090",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS: OTHER"
    },
    {
      "code": "901730",
      "description": "Micrometers, calipers and gauges"
    },
    {
      "code": "90173010",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MICROMETERS, CALIPERS AND GAUGES: MICRO-METERS AND CALIPERS"
    },
    {
      "code": "90173021",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MICROMETERS, CALIPERS AND GAUGES: - GAUGES: PLUG"
    },
    {
      "code": "90173022",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MICROMETERS, CALIPERS AND GAUGES: - GAUGES: RING"
    },
    {
      "code": "90173023",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MICROMETERS, CALIPERS AND GAUGES: - GAUGES: SLIP"
    },
    {
      "code": "90173029",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - MICROMETERS, CALIPERS AND GAUGES: - GAUGES: OTHER"
    },
    {
      "code": "901780",
      "description": "Other instruments"
    },
    {
      "code": "90178010",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER INSTRUMENTS: MEASURING RODS AND TAPES AND DIVIDED SCALES"
    },
    {
      "code": "90178090",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER - OTHER INSTRUMENTS: OTHER"
    },
    {
      "code": "90179000",
      "description": "DRAWING, MARKING-OUT OR MATHEMATICAL CALCULATING INSTRUMENTS (FOR EXAMPLE, DRAFTING MACHINES, PANTOGRAPHS, PROTRACTORS, DRAWING SETS, SLIDE RULES, DISC CALCULATORS); INSTRUMENTS FOR MEASURING LENGTH, FOR USE IN THE HAND (FOR EXAMPLE, MEASURING RODS AND TAPES, MICROMETERS, CALLIPERS), NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER PARTS AND ACCESSORIES"
    },
    {
      "code": "9018",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS Electro-diagnostic apparatus (including apparatus for functional exploratory examinations or for checking physiological parameters)"
    },
    {
      "code": "90181100",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): ELECTRO-CARDIOGRAPHS"
    },
    {
      "code": "901812",
      "description": "Ultrasonic scanning apparatus"
    },
    {
      "code": "90181210",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): - ULTRASONIC SCANNING APPARATUS: LINEAR ULTRASOUND SCANNER"
    },
    {
      "code": "90181290",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): - ULTRASONIC SCANNING APPARATUS: OTHER"
    },
    {
      "code": "90181300",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): MAGNETIC RESONANCE IMAGING APPARATUS"
    },
    {
      "code": "90181400",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): SCIENTIGRAPHIC APPARATUS"
    },
    {
      "code": "901819",
      "description": "Other"
    },
    {
      "code": "90181910",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): - OTHER: ELECTRO ENCEPHALOGRAPHS"
    },
    {
      "code": "90181920",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): - OTHER: ECHO CARDIOGRAPH"
    },
    {
      "code": "90181990",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - ELECTRO-DIAGNOSTIC APPARATUS (INCLUDING APPARATUS FOR FUNCTIONAL EXPLORATORY EXAMINATIONS OR FOR CHECKING PHYSIOLOGICAL PARAMETERS): - OTHER: OTHER"
    },
    {
      "code": "90182000",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS ULTRA-VIOLET OR INFRA-RED RAY APPARATUS"
    },
    {
      "code": "90183100",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: SYRINGES, WITH OR WITHOUT NEEDLES"
    },
    {
      "code": "901832",
      "description": "Tubular metal needles and needles for sutures"
    },
    {
      "code": "90183210",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - TUBULAR METAL NEEDLES AND NEEDLES FOR SUTURES: NEEDLES FOR SUTURE"
    },
    {
      "code": "90183220",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - TUBULAR METAL NEEDLES AND NEEDLES FOR SUTURES: HOLLOW NEEDLES FOR INJECTION, ASPIRATION, BIOPSY AND TRANSFUSION"
    },
    {
      "code": "90183230",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - TUBULAR METAL NEEDLES AND NEEDLES FOR SUTURES: HILERIO VENUS FISTULA NEEDLES"
    },
    {
      "code": "90183290",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - TUBULAR METAL NEEDLES AND NEEDLES FOR SUTURES: OTHER"
    },
    {
      "code": "901839",
      "description": "Other"
    },
    {
      "code": "90183910",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - OTHER: CATHETERS (FOR URINE, STOOL)"
    },
    {
      "code": "90183920",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - OTHER: CARDIAC CATHETERS"
    },
    {
      "code": "90183930",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - OTHER: CANNULAE"
    },
    {
      "code": "90183990",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - SYRINGES, NEEDLES, CATHETERS, CANNULAE AND THE LIKE: - OTHER: OTHER"
    },
    {
      "code": "90184100",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES, USED IN DENTAL SCIENCES: DENTAL DRILL ENGINES, WHETHER OR NOT COMBINED ON A SINGLE BASE WITH OTHER DENTAL EQUIPMENT"
    },
    {
      "code": "90184900",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES, USED IN DENTAL SCIENCES: OTHER"
    },
    {
      "code": "901850",
      "description": "Other ophthalmic instruments and appliances"
    },
    {
      "code": "90185010",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER OPHTHALMIC INSTRUMENTS AND APPLIANCES: OPHTHALMOSCOPES"
    },
    {
      "code": "90185020",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER OPHTHALMIC INSTRUMENTS AND APPLIANCES: TONOMETERS"
    },
    {
      "code": "90185030",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER OPHTHALMIC INSTRUMENTS AND APPLIANCES: OPHTHALMIC LASERS"
    },
    {
      "code": "90185090",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER OPHTHALMIC INSTRUMENTS AND APPLIANCES: OTHER"
    },
    {
      "code": "901890",
      "description": "Other instruments and appliances"
    },
    {
      "code": "90189011",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - DIAGNOSTIC INSTRUMENTS AND APPARATUS: INSTRUMENT AND APPARATUS FOR MEASURING BLOOD PRESSURE"
    },
    {
      "code": "90189012",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - DIAGNOSTIC INSTRUMENTS AND APPARATUS: STETHOSCOPES"
    },
    {
      "code": "90189019",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - DIAGNOSTIC INSTRUMENTS AND APPARATUS: OTHER"
    },
    {
      "code": "90189021",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: BONE SAWS, DRILLS AND TREPHINES"
    },
    {
      "code": "90189022",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: KNIVES, SCISSORS AND BLADES"
    },
    {
      "code": "90189023",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: FORCEPS, FORCEP CLAMPS, CLIPS, NEEDLE HOLDERS, INTRODUCERS, CEPHALOTRIBE BONE HOLDING AND OTHER HOLDING INSTRUMENTS"
    },
    {
      "code": "90189024",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: CHISEL, GAUGES, ELEVATORS, RASPATONES, OSTEOTOME, CRANIOTOME, BONE CUTTERS"
    },
    {
      "code": "90189025",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: RETRACTORS, SPATULAPROBES, HOOKS DIALATORS, SOUNDS, MALLETS"
    },
    {
      "code": "90189029",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - SURGICAL TOOLS: OTHER"
    },
    {
      "code": "90189031",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - RENAL DIALYSIS EQUIPMENT, BLOOD TRANSFUSION APPARATUS AND HAEMOFILTRATION INSTRUMENTS: RENAL DIALYSIS EQUIPMENT(ARTIFICIAL KIDNEYS, KIDNEY MACHINES AND DIALYSERS)"
    },
    {
      "code": "90189032",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - RENAL DIALYSIS EQUIPMENT, BLOOD TRANSFUSION APPARATUS AND HAEMOFILTRATION INSTRUMENTS: BLOOD TRANFUSION APPARATUS"
    },
    {
      "code": "90189033",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - RENAL DIALYSIS EQUIPMENT, BLOOD TRANSFUSION APPARATUS AND HAEMOFILTRATION INSTRUMENTS: HAEMOFILTRATION INSTRUMENTS"
    },
    {
      "code": "90189041",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - ANESTHETIC APPARATUS AND INSTRUMENTS, ENT PRECISION INSTRUMENTS, ACUPUNCTURE APPARATUS, AND ENDOSCOPES: ANESTHETIC APPARATUS AND INSTRUMENTS"
    },
    {
      "code": "90189042",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - ANESTHETIC APPARATUS AND INSTRUMENTS, ENT PRECISION INSTRUMENTS, ACUPUNCTURE APPARATUS, AND ENDOSCOPES: ENT PRECISION INSTRUMENTS"
    },
    {
      "code": "90189043",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - ANESTHETIC APPARATUS AND INSTRUMENTS, ENT PRECISION INSTRUMENTS, ACUPUNCTURE APPARATUS, AND ENDOSCOPES: ACUPUNCTURE APPARATUS"
    },
    {
      "code": "90189044",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - ANESTHETIC APPARATUS AND INSTRUMENTS, ENT PRECISION INSTRUMENTS, ACUPUNCTURE APPARATUS, AND ENDOSCOPES: ENDOSCOPES"
    },
    {
      "code": "90189091",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: HILERIAL OR VENOUS SHUNTS"
    },
    {
      "code": "90189092",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: BABY INCUBATORS"
    },
    {
      "code": "90189093",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: HEART-LUNG MACHINES"
    },
    {
      "code": "90189094",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: DEFIBRILLATORS"
    },
    {
      "code": "90189095",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: FIBRESCOPES"
    },
    {
      "code": "90189096",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: LAPROSCOPES"
    },
    {
      "code": "90189097",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: VETRASONIC LITHOTRIPSY INSTRUMENTS"
    },
    {
      "code": "90189098",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: APPARATUS FOR NERVE STIMULATION"
    },
    {
      "code": "90189099",
      "description": "INSTRUMENTS AND APPLIANCES USED IN MEDICAL, SURGICAL, DENTAL OR VETERINARY SCIENCES, INCLUDING SCIENTIGRAPHIC APPARATUS, OTHER ELECTROMEDICAL APPARATUS AND SIGHT-TESTING INSTRUMENTS - OTHER INSTRUMENTS AND APPLIANCES: - OTHER: OTHER"
    },
    {
      "code": "9019",
      "description": "MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS"
    },
    {
      "code": "901910",
      "description": "Mechano-therapy appliances; massage apparatus; psychological aptitude-testing apparatus"
    },
    {
      "code": "90191010",
      "description": "MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS- MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS: MECHANO-THERAPY APPLIANCES"
    },
    {
      "code": "90191020",
      "description": "MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS- MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS: MASSAGE APPARATUS"
    },
    {
      "code": "90191090",
      "description": "MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS- MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS: OTHER"
    },
    {
      "code": "901920",
      "description": "Ozone therapy, oxygen therapy, aerosol therapy, artificial respiration or other therapeutic respiration apparatus"
    },
    {
      "code": "90192010",
      "description": "20 MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS- OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS: OXYGEN THERAPY APPARATUS"
    },
    {
      "code": "90192090",
      "description": "20 MECHANO-THERAPY APPLIANCES; MASSAGE APPARATUS; PSYCHOLOGICAL APTITUDE-TESTING APPARATUS; OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS- OZONE THERAPY, OXYGEN THERAPY, AEROSOL THERAPY, ARTIFICIAL RESPIRATION OR OTHER THERAPEUTIC RESPIRATION APPARATUS: OTHER"
    },
    {
      "code": "9020",
      "description": "OTHER BREATHING APPLIANCES AND GAS MASKS, EXCLUDING PROTECTIVE MASKS HAVING NEITHER MECHANICAL PARTS NOR REPLACEABLE FILTERS"
    },
    {
      "code": "90200000",
      "description": "OTHER BREATHING APPLIANCES AND GAS MASKS, EXCLUDING PROTECTIVE MASKS HAVING NEITHER MECHANICAL PARTS NOR REPLACEABLE FILTERS"
    },
    {
      "code": "9021",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY TO COMPENSATE FOR A DEFECT OR DISABILITY"
    },
    {
      "code": "90211000",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY ORTHOPEAEDIC OR FRACTURE APPLIANCES"
    },
    {
      "code": "90212100",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - ARTIFICIAL TEETH AND DENTAL FITTINGS: ARTIFICIAL TEETH"
    },
    {
      "code": "90212900",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - ARTIFICIAL TEETH AND DENTAL FITTINGS: OTHER"
    },
    {
      "code": "90213100",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY OTHER ARTIFICIAL PARTS OF THE BODY : ARTIFICIAL JOINTS"
    },
    {
      "code": "90213900",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY OTHER ARTIFICIAL PARTS OF THE BODY : OTHER"
    },
    {
      "code": "902140",
      "description": "Hearing aids, excluding parts and accessories"
    },
    {
      "code": "90214010",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - HEARING AIDS, EXCLUDING PARTS AND ACCESSORIES: FREQUENCY MODULATED HEARING AID SYSTEM USED FOR HEARING BY HANDICAPPED PERSONS IN GROUP SITUATION"
    },
    {
      "code": "90214090",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - HEARING AIDS, EXCLUDING PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "90215000",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY PACEMAKERS FOR STIMULATING HEART MUSCLES, EXCLUDING PARTS AND ACCESSORIES"
    },
    {
      "code": "902190",
      "description": "Other"
    },
    {
      "code": "90219010",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - OTHER: PARTS AND ACCESSORIES OF HEARING AIDS"
    },
    {
      "code": "90219090",
      "description": "ORTHOPAEDIC APPLIANCES, INCLUDING CRUTCHES, SURGICAL BELTS AND TRUSSES; SPLINTS AND OTHER FRACTURE APPLIANCES; ARTIFICIAL PARTS OF THE BODY; HEARING AIDS AND OTHER APPLIANCES WHICH ARE WORN OR CARRIED, OR IMPLANTED IN THE BODY, TO COMPENSATE FOR A DEFECT OR DISABILITY - OTHER: OTHER"
    },
    {
      "code": "9022",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS,X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - Apparatus based on the use of X-rays, whether or not for medical, surgical, dental or veterinary uses, including radiography or radiotherapy apparatus"
    },
    {
      "code": "90221200",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: COMPUTED TOMOGRAPHY APPARATUS"
    },
    {
      "code": "90221300",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: OTHER, FOR DENTAL USES"
    },
    {
      "code": "902214",
      "description": "Other, for medical, surgical or veterinary uses"
    },
    {
      "code": "90221410",
      "description": "14 APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: - OTHER, FOR MEDICAL, SURGICAL OR VETERINARY USES: X-RAY GENERATORS AND APPARATUS (NON-PORTABLE)"
    },
    {
      "code": "90221420",
      "description": "14 APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: - OTHER, FOR MEDICAL, SURGICAL OR VETERINARY USES: PORTABLE X-RAY MACHINE"
    },
    {
      "code": "90221490",
      "description": "14 APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: - OTHER, FOR MEDICAL, SURGICAL OR VETERINARY USES: OTHER"
    },
    {
      "code": "90221900",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF X-RAYS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: FOR OTHER USES"
    },
    {
      "code": "90222100",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF ALPHA, BETA OR GAMMA RADIATION, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES"
    },
    {
      "code": "90222900",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - APPARATUS BASED ON THE USE OF ALPHA, BETA OR GAMMA RADIATION, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS: FOR OTHER USES"
    },
    {
      "code": "90223000",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE X-RAY TUBES"
    },
    {
      "code": "902290",
      "description": "Other, including parts and accessories"
    },
    {
      "code": "90229010",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - OTHER, INCLUDING PARTS AND ACCESSORIES: X-RAY VALVES"
    },
    {
      "code": "90229020",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - OTHER, INCLUDING PARTS AND ACCESSORIES: RADIATION GENERATION UNITS"
    },
    {
      "code": "90229030",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - OTHER, INCLUDING PARTS AND ACCESSORIES: RADIATION BEAM DELIVERY UNITS"
    },
    {
      "code": "90229040",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - OTHER, INCLUDING PARTS AND ACCESSORIES: X-RAY EXAMINATION OR TREATMENT TABLE, CHAIRS AND THE LIKE"
    },
    {
      "code": "90229090",
      "description": "APPARATUS BASED ON THE USE OF X-RAYS OR OF ALPHA, BETA OR GAMMA RADIATIONS, WHETHER OR NOT FOR MEDICAL, SURGICAL, DENTAL OR VETERINARY USES, INCLUDING RADIOGRAPHY OR RADIOTHERAPY APPARATUS, X-RAY TUBES AND OTHER X-RAY GENERATORS, HIGH TENSION GENERATORS, CONTROL PANELS AND DESKS, SCREENS, EXAMINATION OR TREATMENT TABLES, CHAIRS AND THE LIKE - OTHER, INCLUDING PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9023",
      "description": "INSTRUMENTS, APPARATUS AND MODELS, DESIGNED FOR DEMONSTRATIONAL PURPOSES (FOR EXAMPLE, IN EDUCATION OR EXHIBITIONS), UNSUITABLE FOR OTHER USES"
    },
    {
      "code": "902300",
      "description": "Instruments, apparatus and models, designed for demonstrational purposes (for example, in education or exhibitions), unsuitable for other uses"
    },
    {
      "code": "90230010",
      "description": "INSTRUMENTS, APPARATUS AND MODELS, DESIGNED FOR DEMONSTRATIONAL PURPOSES (FOR EXAMPLE, IN EDUCATION OR EXHIBITIONS), UNSUITABLE FOR OTHER USES - INSTRUMENTS, APPARATUS AND MODELS, DESIGNED FOR DEMONSTRATIONAL PURPOSES (FOR EXAMPLE, IN EDUCATION OR EXHIBITIONS), UNSUITABLE FOR OTHER USES: TEACHING AIDS"
    },
    {
      "code": "90230090",
      "description": "INSTRUMENTS, APPARATUS AND MODELS, DESIGNED FOR DEMONSTRATIONAL PURPOSES (FOR EXAMPLE, IN EDUCATION OR EXHIBITIONS), UNSUITABLE FOR OTHER USES - INSTRUMENTS, APPARATUS AND MODELS, DESIGNED FOR DEMONSTRATIONAL PURPOSES (FOR EXAMPLE, IN EDUCATION OR EXHIBITIONS), UNSUITABLE FOR OTHER USES: OTHER"
    },
    {
      "code": "9024",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS)"
    },
    {
      "code": "90241000",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS) MACHINES AND APPLIANCES FOR TESTING METALS"
    },
    {
      "code": "902480",
      "description": "Other machines and appliances"
    },
    {
      "code": "90248010",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS) - OTHER MACHINES AND APPLIANCES: FOR TESTING TEXTILES, PAPER AND PAPERBOARD"
    },
    {
      "code": "90248091",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS) - OTHER MACHINES AND APPLIANCES: - OTHER: FOR TESTING HARDNESS"
    },
    {
      "code": "90248099",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS) - OTHER MACHINES AND APPLIANCES: - OTHER: OTHER"
    },
    {
      "code": "90249000",
      "description": "MACHINES AND APPLIANCES FOR TESTING THE HARDNESS, STRENGTH, COMPRESSIBILITY, ELASTICITY OR OTHER MECHANICAL PROPERTIES OF MATERIALS (FOR EXAMPLE, METALS, WOOD, TEXTILES, PAPER, PLASTICS) - OTHER MACHINES AND APPLIANCES: - OTHER: PARTS AND ACCESSORIES"
    },
    {
      "code": "9025",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - Thermometers and pyrometers, not combined with other instruments"
    },
    {
      "code": "902511",
      "description": "Liquid-filled, for direct reading"
    },
    {
      "code": "90251110",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - THERMOMETERS AND PYROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS: - LIQUID-FILLED, FOR DIRECT READING: CLINICAL THERMOMETERS"
    },
    {
      "code": "90251190",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - THERMOMETERS AND PYROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS: - LIQUID-FILLED, FOR DIRECT READING: OTHER"
    },
    {
      "code": "902519",
      "description": "Other"
    },
    {
      "code": "90251910",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - THERMOMETERS AND PYROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS: - OTHER: DIGITAL THERMOMETERS"
    },
    {
      "code": "90251920",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - THERMOMETERS AND PYROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS: - OTHER: PYROMETERS"
    },
    {
      "code": "90251990",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - THERMOMETERS AND PYROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS: - OTHER: OTHER"
    },
    {
      "code": "902580",
      "description": "Other instruments"
    },
    {
      "code": "90258010",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - OTHER INSTRUMENTS: HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS"
    },
    {
      "code": "90258020",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - OTHER INSTRUMENTS: BAROMETERS, NOT COMBINED WITH OTHER INSTRUMENTS"
    },
    {
      "code": "90258030",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - OTHER INSTRUMENTS: LACTOMETER"
    },
    {
      "code": "90258090",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS - OTHER INSTRUMENTS: OTHER"
    },
    {
      "code": "90259000",
      "description": "HYDROMETERS AND SIMILAR FLOATING INSTRUMENTS, THERMOMETERS, PYROMETERS, BAROMETERS, HYGROMETERS AND PSYCHROMETERS, RECORDING OR NOT, AND ANY COMBINATION OF THESE INSTRUMENTS PARTS AND ACCESSORIES"
    },
    {
      "code": "9026",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032"
    },
    {
      "code": "902610",
      "description": "For measuring or checking the flow or level of liquids"
    },
    {
      "code": "90261010",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 - FOR MEASURING OR CHECKING THE FLOW OR LEVEL OF LIQUIDS: FLOW METERS"
    },
    {
      "code": "90261020",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 - FOR MEASURING OR CHECKING THE FLOW OR LEVEL OF LIQUIDS: LEVEL GAUGES"
    },
    {
      "code": "90261090",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 - FOR MEASURING OR CHECKING THE FLOW OR LEVEL OF LIQUIDS: OTHER"
    },
    {
      "code": "90262000",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 FOR MEASURING OR CHECKING PRESSURE"
    },
    {
      "code": "902680",
      "description": "Other instruments or apparatus"
    },
    {
      "code": "90268010",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 - OTHER INSTRUMENTS OR APPARATUS: HEAT METERS"
    },
    {
      "code": "90268090",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 - OTHER INSTRUMENTS OR APPARATUS: OTHER"
    },
    {
      "code": "90269000",
      "description": "INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING THE FLOW, LEVEL, PRESSURE OR OTHER VARIABLES OF LIQUIDS OR GASES (FOR EXAMPLE, FLOW METERS, LEVEL GAUGES, MANOMETERS, HEAT METERS), EXCLUDING INSTRUMENTS AND APPARATUS OF HEADING 9014, 9015, 9028 OR 9032 PARTS AND ACCESSORIES"
    },
    {
      "code": "9027",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES"
    },
    {
      "code": "90271000",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES GAS OR SMOKE ANALYSIS APPARATUS"
    },
    {
      "code": "90272000",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES CHROMATOGRAPHS AND ELECTROPHORESIS INSTRUMENTS"
    },
    {
      "code": "902730",
      "description": "Spectrometers, spectrophotometers and spectrographs using optical radiations (UV, visible, IR)"
    },
    {
      "code": "90273010",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - SPECTROMETERS, SPECTROPHOTOMETERS AND SPECTROGRAPHS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): SPECTROMETERS"
    },
    {
      "code": "90273020",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - SPECTROMETERS, SPECTROPHOTOMETERS AND SPECTROGRAPHS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): SPECTROPHOTOMETERS"
    },
    {
      "code": "90273090",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - SPECTROMETERS, SPECTROPHOTOMETERS AND SPECTROGRAPHS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): OTHER"
    },
    {
      "code": "90274000",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES EXPOSURE METERS"
    },
    {
      "code": "902750",
      "description": "Other instruments and apparatus using optical radiations (UV, visible, IR)"
    },
    {
      "code": "90275010",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): PHOTOMETERS"
    },
    {
      "code": "90275020",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): REFRACTOMETERS"
    },
    {
      "code": "90275030",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): POLARIMETERS"
    },
    {
      "code": "90275090",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS USING OPTICAL RADIATIONS (UV, VISIBLE, IR): OTHER"
    },
    {
      "code": "902780",
      "description": "Other instruments and apparatus"
    },
    {
      "code": "90278010",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS: VISCOMETERS"
    },
    {
      "code": "90278020",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS: CALORIMETERS"
    },
    {
      "code": "90278030",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS: INSTRUMENTS AND APPARATUS FOR MEASURING THE SURFACE OR INTERFOCIAL TENSION OF LIQUIDS"
    },
    {
      "code": "90278040",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS: NUCLEAR MAGNETIC RESONANCE INSTRUMENTS"
    },
    {
      "code": "90278090",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - OTHER INSTRUMENTS AND APPARATUS: OTHER"
    },
    {
      "code": "902790",
      "description": "Microtomes; parts and accessories"
    },
    {
      "code": "90279010",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - MICROTOMES; PARTS AND ACCESSORIES : MICROTOMES, INCLUDING PARTS AND ACCESSORIES THEREOF"
    },
    {
      "code": "90279020",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - MICROTOMES; PARTS AND ACCESSORIES : PRINTED CIRCUIT ASSEMBLIES FOR THE GOODS OF SUB-HEADING 9027 80"
    },
    {
      "code": "90279090",
      "description": "INSTRUMENTS AND APPARATUS FOR PHYSICAL OR CHEMICAL ANALYSIS (FOR EXAMPLE, POLARIMETERS, REFRACTOMETERS, SPECTROMETERS, GAS OR SMOKE ANALYSIS APPARATUS); INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING VISCOSITY, POROSITY, EXPANSION, SURFACE TENSION OR THE LIKE; INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING QUANTITIES OF HEAT, SOUND OR LIGHT (INCLUDING EXPOSURE METERS); MICROTOMES - MICROTOMES; PARTS AND ACCESSORIES : OTHER"
    },
    {
      "code": "9028",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR"
    },
    {
      "code": "90281000",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR GAS METERS"
    },
    {
      "code": "90282000",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR LIQUID METERS"
    },
    {
      "code": "902830",
      "description": "Electricity meters"
    },
    {
      "code": "90283010",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR - ELECTRICITY METERS: FOR ALTERNATING CURRENT"
    },
    {
      "code": "90283090",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR - ELECTRICITY METERS: OTHER"
    },
    {
      "code": "902890",
      "description": "Parts and accessories"
    },
    {
      "code": "90289010",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR - PARTS AND ACCESSORIES : FOR ELECTRICITY METERS"
    },
    {
      "code": "90289090",
      "description": "GAS, LIQUID OR ELECTRICITY SUPPLY OR PRODUCTION METERS, INCLUDING CALIBRATING METERS THEREFOR - PARTS AND ACCESSORIES : OTHER"
    },
    {
      "code": "9029",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES"
    },
    {
      "code": "902910",
      "description": "Revolution counters, production counters, taximeters, mileometers, pedometers and the like"
    },
    {
      "code": "90291010",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE: TAXIMETERS"
    },
    {
      "code": "90291090",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE: OTHER"
    },
    {
      "code": "902920",
      "description": "Speed indicators and tachometers; stroboscopes"
    },
    {
      "code": "90292010",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - SPEED INDICATORS AND TACHOMETERS; STROBOSCOPES: TACHOMETERS, NON-ELECTRICAL"
    },
    {
      "code": "90292020",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - SPEED INDICATORS AND TACHOMETERS; STROBOSCOPES: SPEEDOMETERS, NON-ELECTRICAL"
    },
    {
      "code": "90292030",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - SPEED INDICATORS AND TACHOMETERS; STROBOSCOPES: STROBOSCOPES"
    },
    {
      "code": "90292090",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES - SPEED INDICATORS AND TACHOMETERS; STROBOSCOPES: OTHER"
    },
    {
      "code": "90299000",
      "description": "REVOLUTION COUNTERS, PRODUCTION COUNTERS, TAXIMETERS, MILEOMETERS, PEDOMETERS AND THE LIKE; SPEED INDICATORS AND TACHOMETERS, OTHER THAN THOSE OF HEADING 9014 OR 9015; STROBOSCOPES PARTS AND ACCESSORIES"
    },
    {
      "code": "9030",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS"
    },
    {
      "code": "90301000",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING IONISING RADIATIONS"
    },
    {
      "code": "90302000",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OSCILLOSCOPES AND OSCILLOGRAPHS"
    },
    {
      "code": "90303100",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- MULTIMETERS WITHOUT A RECORDING DEVICE"
    },
    {
      "code": "90303200",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- MULTIMETERS WITH A RECORDING DEVICE"
    },
    {
      "code": "903033",
      "description": "Other, without a recording device"
    },
    {
      "code": "90303310",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:AMMETERS, VOLT METERS AND WATT METERS"
    },
    {
      "code": "90303320",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:SPECTRUM RESISTANCE METERS"
    },
    {
      "code": "90303330",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:CAPACITANCE METER"
    },
    {
      "code": "90303340",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:FREQUENCY MEASURING APPARATUS"
    },
    {
      "code": "90303350",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:MEGA METERS"
    },
    {
      "code": "90303390",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITHOUT A RECORDING DEVICE:OTHER"
    },
    {
      "code": "90303900",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER: -- OTHER, WITH A RECORDING DEVICE"
    },
    {
      "code": "90303910",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: AMMETERS, VOLT METERS AND WATT METERS"
    },
    {
      "code": "90303920",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: SPECTRUM RESISTANCE METERS"
    },
    {
      "code": "90303930",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: CAPACITANCE METER"
    },
    {
      "code": "90303940",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: FREQUENCY MEASURING APPARATUS"
    },
    {
      "code": "90303950",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: MEGA METERS"
    },
    {
      "code": "90303990",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS - OTHER INSTRUMENTS AND APPARATUS, FOR MEASURING OR CHECKING VOLTAGE, CURRENT, RESISTANCE OR POWER, WITHOUT A RECORDING DEVICE: - OTHER: OTHER"
    },
    {
      "code": "90304000",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS OTHER INSTRUMENTS AND APPARATUS, SPECIALLY DESIGNED FOR TELECOMMUNICATIONS (FOR EXAMPLE, CROSS-TALK METERS, GAIN MEASURING INSTRUMENTS, DISTORTION FACTOR METERS, PSOPHOMETERS)"
    },
    {
      "code": "90308200",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: FOR MEASURING OR CHECKING SEMICONDUCTOR WAFERS OR DEVICES"
    },
    {
      "code": "90308300",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: OTHER, WITH A RECORDING DEVICE"
    },
    {
      "code": "90308400",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: OTHER, WITH A RECORDING DEVICE"
    },
    {
      "code": "903089",
      "description": "Other"
    },
    {
      "code": "90308910",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: - OTHER: SCINTILLATOR COUNTERS"
    },
    {
      "code": "90308920",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: - OTHER: VECTROSCOPE"
    },
    {
      "code": "90308990",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS- OTHER INSTRUMENTS AND APPARATUS: - OTHER: OTHER"
    },
    {
      "code": "903090",
      "description": "Parts and accessories"
    },
    {
      "code": "90309010",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS-PARTS AND ACCESSORIES:OF METERS AND COUNTERS"
    },
    {
      "code": "90309090",
      "description": "OSCILLOSCOPES, SPECTRUM ANALYSERS AND OTHER INSTRUMENTS AND APPARATUS FOR MEASURING OR CHECKING ELECTRICAL QUANTITIES, EXCLUDING METERS OF HEADING 9028; INSTRUMENTS AND APPARATUS FOR MEASURING OR DETECTING ALPHA, BETA, GAMMA, X-RAY, COSMIC OR OTHER IONISING RADIATIONS-PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9031",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS"
    },
    {
      "code": "90311000",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS MACHINES FOR BALANCING MECHANICAL PARTS"
    },
    {
      "code": "90312000",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS TEST BENCHES"
    },
    {
      "code": "90313000",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS PROFILE PROJECTORS"
    },
    {
      "code": "90314100",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS - OTHER OPTICAL INSTRUMENTS AND APPLIANCES: FOR INSPECTING SEMICONDUCTOR WAFERS, OR DEVICES OR FOR INSPECTING PHOTO-MASKS OR RETICLES USED IN MANUFACTURING SEMICONDUCTOR DEVICES"
    },
    {
      "code": "90314900",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS - OTHER OPTICAL INSTRUMENTS AND APPLIANCES: OTHER"
    },
    {
      "code": "90318000",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS OTHER INSTRUMENTS, APPLIANCES AND MACHINES"
    },
    {
      "code": "90319000",
      "description": "MEASURING OR CHECKING INSTRUMENTS, APPLIANCES AND MACHINES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; PROFILE PROJECTORS PARTS AND ACCESSORIES"
    },
    {
      "code": "9032",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS"
    },
    {
      "code": "903210",
      "description": "Thermostats"
    },
    {
      "code": "90321010",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS 9032 10 AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - THERMOSTATS: FOR REFRIGERATING AND AIR-CONDITIONING APPLIANCES AND MACHINERY"
    },
    {
      "code": "90321090",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS 9032 10 AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - THERMOSTATS: OTHER"
    },
    {
      "code": "903220",
      "description": "Manostats"
    },
    {
      "code": "90322010",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - MANOSTATS: FOR REFRIGERATING AND AIR-CONDITIONING APPLIANCES AND MACHINERY"
    },
    {
      "code": "90322090",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - MANOSTATS: OTHER"
    },
    {
      "code": "90328100",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - OTHER INSTRUMENTS AND APPARATUS: HYDRAULIC OR PNEUMATIC"
    },
    {
      "code": "903289",
      "description": "Other"
    },
    {
      "code": "90328910",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - OTHER INSTRUMENTS AND APPARATUS: - OTHER: ELECTRONIC AUTOMATIC REGULATORS"
    },
    {
      "code": "90328990",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS - OTHER INSTRUMENTS AND APPARATUS: - OTHER: OTHER"
    },
    {
      "code": "90329000",
      "description": "AUTOMATIC REGULATING OR CONTROLLING INSTRUMENTS AND APPARATUS PARTS AND ACCESSORIES"
    },
    {
      "code": "9033",
      "description": "PARTS AND ACCESSORIES (NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER) FOR MACHINES, APPLIANCES, INSTRUMENTS OR APPARATUS OF CHAPTER 90"
    },
    {
      "code": "90330000",
      "description": "PARTS AND ACCESSORIES (NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER) FOR MACHINES, APPLIANCES, INSTRUMENTS OR APPARATUS OF CHAPTER 90"
    },
    {
      "code": "9070",
      "description": "Cloves (whole fruit, cloves and stems)"
    },
    {
      "code": "909101",
      "description": "Seeds of anise"
    },
    {
      "code": "909102",
      "description": "Seeds of badian"
    },
    {
      "code": "909301",
      "description": "Cumin, black"
    },
    {
      "code": "909302",
      "description": "Cumin, other than black"
    },
    {
      "code": "909501",
      "description": "Fennel seeds"
    },
    {
      "code": "909502",
      "description": "Juniper berries"
    },
    {
      "code": "91",
      "description": "Clocks and watches and parts thereof"
    },
    {
      "code": "9101",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - Wrist-watches, electrically operated whether or not incorporating a stop-watch facility"
    },
    {
      "code": "91011100",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - WRIST-WATCHES, ELECTRICALLY OPERATED WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH MECHANICAL DISPLAY ONLY"
    },
    {
      "code": "91011200",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - WRIST-WATCHES, ELECTRICALLY OPERATED WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH OPTO-ELECTRONIC DISPLAY ONLY"
    },
    {
      "code": "91011900",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - WRIST-WATCHES, ELECTRICALLY OPERATED WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: OTHER"
    },
    {
      "code": "91012100",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER WRIST-WATCHES, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH AUTOMATIC WINDING"
    },
    {
      "code": "91012900",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER WRIST-WATCHES, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: OTHER"
    },
    {
      "code": "910191",
      "description": "Electrically operated"
    },
    {
      "code": "91019110",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : - ELECTRICALLY OPERATED: POCKET WATCHES"
    },
    {
      "code": "91019120",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : - ELECTRICALLY OPERATED: STOP WATCHES"
    },
    {
      "code": "91019190",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : - ELECTRICALLY OPERATED: OTHER"
    },
    {
      "code": "910199",
      "description": "Other"
    },
    {
      "code": "91019910",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : OTHER: POCKET WATCHES"
    },
    {
      "code": "91019920",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : OTHER: STOP WATCHES"
    },
    {
      "code": "91019990",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP-WATCHES, WITH CASE OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL - OTHER : OTHER: OTHER"
    },
    {
      "code": "9102",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - WRIST-WATCHES, ELECTRICALLY OPERATED, WHETHER OR NOT incorporating a stop-watch facility"
    },
    {
      "code": "91021100",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING 9101 - WRIST-WATCHES, ELECTRICALLY OPERATED, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH MECHANICAL DISPLAY ONLY"
    },
    {
      "code": "91021200",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING 9101 - WRIST-WATCHES, ELECTRICALLY OPERATED, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH OPTO-ELECTRONIC DISPLAY ONLY"
    },
    {
      "code": "91021900",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING 9101 - WRIST-WATCHES, ELECTRICALLY OPERATED, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: OTHER"
    },
    {
      "code": "91022100",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING 9101- OTHER WRIST-WATCHES, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: WITH AUTOMATIC WINDING"
    },
    {
      "code": "91022900",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING 9101- OTHER WRIST-WATCHES, WHETHER OR NOT INCORPORATING A STOP-WATCH FACILITY: OTHER"
    },
    {
      "code": "910291",
      "description": "Electrically operated"
    },
    {
      "code": "91029110",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER: ELECTRICALLY OPERATED: POCKET WATCHES"
    },
    {
      "code": "91029120",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER: ELECTRICALLY OPERATED: STOP WATCHES"
    },
    {
      "code": "91029190",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER: ELECTRICALLY OPERATED: OTHER"
    },
    {
      "code": "910299",
      "description": "Other"
    },
    {
      "code": "91029910",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER - OTHER: POCKET WATCHES"
    },
    {
      "code": "91029920",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER - OTHER: STOP WATCHES"
    },
    {
      "code": "91029990",
      "description": "WRIST-WATCHES, POCKET-WATCHES AND OTHER WATCHES, INCLUDING STOP WATCHES, OTHER THAN THOSE OF HEADING - OTHER - OTHER: OTHER"
    },
    {
      "code": "9103",
      "description": "CLOCKS WITH WATCH MOVEMENTS, EXCLUDING CLOCKS OF HEADING 9104"
    },
    {
      "code": "91031000",
      "description": "CLOCKS WITH WATCH MOVEMENTS, EXCLUDING CLOCKS OF HEADING 9104 - ELECTRICALLY OPERATED"
    },
    {
      "code": "91039000",
      "description": "CLOCKS WITH WATCH MOVEMENTS, EXCLUDING CLOCKS OF HEADING 9104 - OTHER"
    },
    {
      "code": "9104",
      "description": " INSTRUMENT PANEL CLOCKS AND CLOCKS OF A SIMILAR TYPE FOR VEHICLES, AIRCRAFT, SPACECRAFT OR VESSELS"
    },
    {
      "code": "91040000",
      "description": "INSTRUMENT PANEL CLOCKS AND CLOCKS OF A SIMILAR TYPE FOR VEHICLES, AIRCRAFT, SPACECRAFT OR VESSELS"
    },
    {
      "code": "9105",
      "description": "OTHER CLOCKS"
    },
    {
      "code": "91051090",
      "description": "other"
    },
    {
      "code": "91051100",
      "description": "OTHER CLOCKS - ALARM CLOCKS: ELECTRICALLY OPERATED"
    },
    {
      "code": "91051900",
      "description": "OTHER CLOCKS - ALARM CLOCKS: OTHER"
    },
    {
      "code": "91052100",
      "description": "OTHER CLOCKS - WALL CLOCKS: ELECTRICALLY OPERATED"
    },
    {
      "code": "91052900",
      "description": "OTHER CLOCKS - WALL CLOCKS: OTHER"
    },
    {
      "code": "91059100",
      "description": "OTHER CLOCKS - OTHER: ELECTRICALLY OPERATED"
    },
    {
      "code": "910599",
      "description": "Other"
    },
    {
      "code": "91059910",
      "description": "OTHER CLOCKS - OTHER: OTHER: TIME PIECES"
    },
    {
      "code": "91059990",
      "description": "OTHER CLOCKS - OTHER: OTHER: OTHER"
    },
    {
      "code": "9106",
      "description": "TIME OF DAY RECORDING APPARATUS AND APPARATUS FOR MEASURING, RECORDING OR OTHERWISE INDICATING INTERVALS OF TIME, WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR (FOR EXAMPLE, TIMEREGISTERS, TIME-RECORDERS)"
    },
    {
      "code": "91061000",
      "description": "TIME OF DAY RECORDING APPARATUS AND APPARATUS FOR MEASURING, RECORDING OR OTHERWISE INDICATING INTERVALS OF TIME, WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR (FOR EXAMPLE, TIME-REGISTERS, TIMERECORDERS) TIME-REGISTERS; TIME-RECORDERS"
    },
    {
      "code": "91062000",
      "description": "TIME OF DAY RECORDING APPARATUS AND APPARATUS FOR MEASURING, RECORDING OR OTHERWISE INDICATING INTERVALS OF TIME, WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR (FOR EXAMPLE, TIME-REGISTERS, TIMERECORDERS) PARKING METERS"
    },
    {
      "code": "91069000",
      "description": "TIME OF DAY RECORDING APPARATUS AND APPARATUS FOR MEASURING, RECORDING OR OTHERWISE INDICATING INTERVALS OF TIME, WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR (FOR EXAMPLE, TIME-REGISTERS, TIMERECORDERS) OTHER"
    },
    {
      "code": "9107",
      "description": "TIME SWITCHES WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR"
    },
    {
      "code": "91070000",
      "description": "TIME SWITCHES WITH CLOCK OR WATCH MOVEMENT OR WITH SYNCHRONOUS MOTOR"
    },
    {
      "code": "9108",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED - Electrically operated"
    },
    {
      "code": "91081100",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED - ELECTRICALLY OPERATED: WITH MECHANICAL DISPLAY ONLY OR WITH A DEVICE TO WHICH A MECHANICAL DISPLAY CAN BE INCORPORATED"
    },
    {
      "code": "91081200",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED - ELECTRICALLY OPERATED: WITH OPTO-ELECTRONIC DISPLAY ONLY"
    },
    {
      "code": "91081900",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED - ELECTRICALLY OPERATED: OTHER"
    },
    {
      "code": "91082000",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED WITH AUTOMATIC WINDING"
    },
    {
      "code": "91089000",
      "description": "WATCH MOVEMENTS, COMPLETE AND ASSEMBLED OTHER"
    },
    {
      "code": "9109",
      "description": "CLOCK MOVEMENTS, COMPLETE AND ASSEMBLED"
    },
    {
      "code": "910910",
      "description": "Electrically operated"
    },
    {
      "code": "91091010",
      "description": "of alarm clocks"
    },
    {
      "code": "91091090",
      "description": "CLOCK MOVEMENTS, COMPLETE AND ASSEMBLED - ELECTRICALLY OPERATED ---OTHER"
    },
    {
      "code": "91099000",
      "description": "CLOCK MOVEMENTS, COMPLETE AND ASSEMBLED - OTHER"
    },
    {
      "code": "910991",
      "description": "Seed"
    },
    {
      "code": "910992",
      "description": "Powder"
    },
    {
      "code": "910993",
      "description": "Husk"
    },
    {
      "code": "9110",
      "description": "COMPLETE WATCH OR CLOCK MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS); INCOMPLETE WATCH OR CLOCK MOVEMENTS, ASSEMBLED; ROUGH WATCH OR CLOCK MOVEMENTS - Of watches"
    },
    {
      "code": "91101100",
      "description": "COMPLETE WATCH OR CLOCK MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS); INCOMPLETE WATCH OR CLOCK MOVEMENTS, ASSEMBLED; ROUGH WATCH OR CLOCK MOVEMENTS - OF WATCHES: COMPLETE MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS)"
    },
    {
      "code": "91101200",
      "description": "COMPLETE WATCH OR CLOCK MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS); INCOMPLETE WATCH OR CLOCK MOVEMENTS, ASSEMBLED; ROUGH WATCH OR CLOCK MOVEMENTS - OF WATCHES: INCOMPLETE MOVEMENTS, ASSEMBLED"
    },
    {
      "code": "91101900",
      "description": "COMPLETE WATCH OR CLOCK MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS); INCOMPLETE WATCH OR CLOCK MOVEMENTS, ASSEMBLED; ROUGH WATCH OR CLOCK MOVEMENTS - OF WATCHES: ROUGH MOVEMENTS"
    },
    {
      "code": "91109000",
      "description": "COMPLETE WATCH OR CLOCK MOVEMENTS, UNASSEMBLED OR PARTLY ASSEMBLED (MOVEMENT SETS); INCOMPLETE WATCH OR CLOCK MOVEMENTS, ASSEMBLED; ROUGH WATCH OR CLOCK MOVEMENTS - OTHER"
    },
    {
      "code": "9111",
      "description": "WATCH CASES AND PARTS THEREOF"
    },
    {
      "code": "91111000",
      "description": "WATCH CASES AND PARTS THEREOF CASES OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL"
    },
    {
      "code": "91112000",
      "description": "WATCH CASES AND PARTS THEREOF CASES OF BASE METAL, WHETHER OR NOT GOLD- OR SILVER-PLATED"
    },
    {
      "code": "91118000",
      "description": "WATCH CASES AND PARTS THEREOF WATCH CASES AND PARTS THEREOF OTHER CASES"
    },
    {
      "code": "91119000",
      "description": "PARTS"
    },
    {
      "code": "9112",
      "description": "CLOCK CASES AND CASES OF A SIMILAR TYPE FOR OTHER GOODS OF THIS CHAPTER, AND PARTS THEREOF"
    },
    {
      "code": "91122000",
      "description": "CLOCK CASES AND CASES OF A SIMILAR TYPE FOR OTHER GOODS OF THIS CHAPTER, AND PARTS THEREOF CASES"
    },
    {
      "code": "91129000",
      "description": "CLOCK CASES AND CASES OF A SIMILAR TYPE FOR OTHER GOODS OF THIS CHAPTER, AND PARTS THEREOF PARTS"
    },
    {
      "code": "9113",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF"
    },
    {
      "code": "91131000",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF OF PRECIOUS METAL OR OF METAL CLAD WITH PRECIOUS METAL"
    },
    {
      "code": "911320",
      "description": "Of base metal, whether or not gold- or silver-plated"
    },
    {
      "code": "91132010",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF OF BASE METAL, WHETHER OR NOT GOLD- OR SILVERPLATED:PARTS"
    },
    {
      "code": "91132090",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF OF BASE METAL, WHETHER OR NOT GOLD- OR SILVERPLATED:OTHER"
    },
    {
      "code": "911390",
      "description": "Other"
    },
    {
      "code": "91139010",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF - OTHER: PARTS"
    },
    {
      "code": "91139090",
      "description": "WATCH STRAPS, WATCH BANDS AND WATCH BRACELETS, AND PARTS THEREOF - OTHER: OTHER"
    },
    {
      "code": "9114",
      "description": "OTHER CLOCK OR WATCH PARTS"
    },
    {
      "code": "911410",
      "description": "Springs, including hair-springs"
    },
    {
      "code": "91141010",
      "description": "OTHER CLOCK OR WATCH PARTS SPRINGS, INCLUDING HAIR-SPRINGS:FOR WATCHES"
    },
    {
      "code": "91141020",
      "description": "OTHER CLOCK OR WATCH PARTS SPRINGS, INCLUDING HAIR-SPRINGS:FOR CLOCKS"
    },
    {
      "code": "911430",
      "description": "Dials"
    },
    {
      "code": "91143010",
      "description": "OTHER CLOCK OR WATCH PARTS- DIALS:FOR WATCHES"
    },
    {
      "code": "91143020",
      "description": "OTHER CLOCK OR WATCH PARTS- DIALS:FOR CLOCKS"
    },
    {
      "code": "911440",
      "description": "Plates and bridges"
    },
    {
      "code": "91144010",
      "description": "OTHER CLOCK OR WATCH PARTS- PLATES AND BRIDGES:FOR WATCHES"
    },
    {
      "code": "91144020",
      "description": "OTHER CLOCK OR WATCH PARTS- PLATES AND BRIDGES:FOR CLOCKS"
    },
    {
      "code": "911490",
      "description": "Other"
    },
    {
      "code": "91149030",
      "description": "JEWELS"
    },
    {
      "code": "91149091",
      "description": "FOR WATCHES"
    },
    {
      "code": "91149092",
      "description": "FOR CLOCKS"
    },
    {
      "code": "92",
      "description": "Musical instruments; parts and accessories of such articles"
    },
    {
      "code": "9201",
      "description": "PIANOS, INCLUDING AUTOMATIC PIANOS; HARPSI-CHORDS AND OTHER KEYBOARD STRINGED INSTRUMENTS"
    },
    {
      "code": "92011000",
      "description": "PIANOS, INCLUDING AUTOMATIC PIANOS; HARPSI-CHORDS AND OTHER KEYBOARD STRINGED INSTRUMENTS Upright pianos"
    },
    {
      "code": "92012000",
      "description": "PIANOS, INCLUDING AUTOMATIC PIANOS; HARPSI-CHORDS AND OTHER KEYBOARD STRINGED INSTRUMENTS Grand pianos"
    },
    {
      "code": "92019000",
      "description": "PIANOS, INCLUDING AUTOMATIC PIANOS; HARPSI-CHORDS AND OTHER KEYBOARD STRINGED INSTRUMENTS Other"
    },
    {
      "code": "9202",
      "description": "OTHER STRING MUSICAL INSTRUMENTS (FOR EXAMPLE, GUITARS, VIOLINS, HARPS)"
    },
    {
      "code": "92021000",
      "description": "OTHER STRING MUSICAL INSTRUMENTS (FOR EXAMPLE, GUITARS, VIOLINS, HARPS) Played with a bow"
    },
    {
      "code": "92029000",
      "description": "OTHER STRING MUSICAL INSTRUMENTS (FOR EXAMPLE, GUITARS, VIOLINS, HARPS) Other"
    },
    {
      "code": "9203",
      "description": "OMITTED"
    },
    {
      "code": "92030010",
      "description": "KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS 9203 00? KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS: HARMONIUMS AND SIMILAR KEY BOARD INSTRUMENTS WITH FREE METAL REEDS"
    },
    {
      "code": "92030090",
      "description": "KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS 9203 00? KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS KEYBOARD PIPE ORGANS; HARMONIUMS AND SIMILAR KEYBOARD INSTRUMENTS WITH FREE METAL REEDS: OTHER"
    },
    {
      "code": "9204",
      "description": "OMITTED"
    },
    {
      "code": "92041000",
      "description": "ACCORDIONS AND SIMILAR INSTRUMENTS; MOUTH ORGANS ACCORDIONS AND SIMILAR INSTRUMENTS"
    },
    {
      "code": "92042000",
      "description": "ACCORDIONS AND SIMILAR INSTRUMENTS; MOUTH ORGANS MOUTH ORGANS"
    },
    {
      "code": "9205",
      "description": "WIND MUSICAL INSTRUMENTS (FOR EXAMPLE, KEYBOARD PIPE ORGANS, ACCORDIONS, CLARINETS, TRUMPETS, BAGPIPES) OTHER THAN FAIRGROUND ORGANS AND |MECHANICAL STREET ORGANS"
    },
    {
      "code": "92051000",
      "description": "OTHER WIND MUSICAL INSTRUMENTS (FOR EXAMPLE, CLARINETS, TRUMPETS, BAGPIPES) Brass-wind instruments"
    },
    {
      "code": "920590",
      "description": "Other"
    },
    {
      "code": "92059010",
      "description": "OTHER WIND MUSICAL INSTRUMENTS (FOR EXAMPLE, CLARINETS, TRUMPETS, BAGPIPES) - Other: Flutes"
    },
    {
      "code": "92059020",
      "description": "OTHER WIND MUSICAL INSTRUMENTS (FOR EXAMPLE, CLARINETS, TRUMPETS, BAGPIPES) - Other: Clarinets"
    },
    {
      "code": "92059090",
      "description": "OTHER WIND MUSICAL INSTRUMENTS (FOR EXAMPLE, CLARINETS, TRUMPETS, BAGPIPES) - Other: Other"
    },
    {
      "code": "9206",
      "description": " PERCUSSION MUSICAL INSTRUMENTS (FOR EXAMPLE, DRUMS, XYLOPHONES, CYMBOLS, CASTANETS, MARACAS)"
    },
    {
      "code": "92060000",
      "description": "PERCUSSION MUSICAL INSTRUMENTS (FOR EXAMPLE, DRUMS, XYLOPHONES, CYMBOLS, CASTANETS, MARACAS)"
    },
    {
      "code": "9207",
      "description": "MUSICAL INSTRUMENTS, THE SOUND OF WHICH IS PRODUCED, OR MUST BE AMPLIFIED, ELECTRICALLY (FOR EXAMPLE, ORGANS, GUITARS, ACCORDIONS)"
    },
    {
      "code": "92071000",
      "description": "MUSICAL INSTRUMENTS, THE SOUND OF WHICH IS PRODUCED, OR MUST BE AMPLIFIED, ELECTRICALLY (FOR EXAMPLE, ORGANS, GUITARS, ACCORDIONS)Keyboard instruments, other than accordions"
    },
    {
      "code": "92079000",
      "description": "MUSICAL INSTRUMENTS, THE SOUND OF WHICH IS PRODUCED, OR MUST BE AMPLIFIED, ELECTRICALLY (FOR EXAMPLE, ORGANS, GUITARS, ACCORDIONS)Other"
    },
    {
      "code": "9208",
      "description": "MUSICAL BOXES, FAIRGROUND ORGANS, MECHANICAL STREET ORGANS, MECHANICAL SINGING BIRDS, MUSICAL SAWS AND OTHER MUSICAL INSTRUMENTS NOT FALLING WITHIN ANY OTHER HEADING OF THIS CHAPTER; DECOY CALLS OF ALL KINDS; WHISTLES, CALL HORNS AND OTHER MOUTH-BLOWN SOUND SIGNALLING INSTRUMENTS"
    },
    {
      "code": "92081000",
      "description": "MUSICAL BOXES, FAIRGROUND ORGANS, MECHANICAL STREET ORGANS, MECHANICAL SINGING BIRDS, MUSICAL SAWS AND OTHER MUSICAL INSTRUMENTS NOT FALLING WITHIN ANY OTHER HEADING OF THIS CHAPTER; DECOY CALLS OF ALL KINDS; WHISTLES, CALL HORNS AND OTHER MOUTH-BLOWN SOUND SIGNALLING INSTRUMENTS Musical boxes"
    },
    {
      "code": "92082000",
      "description": "MUSICAL BOXES, FAIRGROUND ORGANS, MECHANICAL STREET ORGANS, MECHANICAL SINGING BIRDS, MUSICAL SAWS AND OTHER MUSICAL INSTRUMENTS NOT FALLING WITHIN ANY OTHER HEADING OF THIS CHAPTER; DECOY CALLS OF ALL KINDS; WHISTLES, CALL HORNS AND OTHER MOUTH-BLOWN SOUND SIGNALLING INSTRUMENTS OTHER"
    },
    {
      "code": "92089000",
      "description": "MUSICAL BOXES, FAIRGROUND ORGANS, MECHANICAL STREET ORGANS, MECHANICAL SINGING BIRDS, MUSICAL SAWS AND OTHER MUSICAL INSTRUMENTS NOT FALLING WITHIN ANY OTHER HEADING OF THIS CHAPTER; DECOY CALLS OF ALL KINDS; WHISTLES, CALL HORNS AND OTHER MOUTH-BLOWN SOUND SIGNALLING INSTRUMENTS OTHER"
    },
    {
      "code": "9209",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS"
    },
    {
      "code": "92091000",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDSMETRONOMES, TUNING FORKS AND PITCH PIPES"
    },
    {
      "code": "92092000",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDSMECHANISMS FOR MUSICAL BOXES"
    },
    {
      "code": "92093000",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDSMusical instrument strings"
    },
    {
      "code": "92099100",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS- Other: Parts and accessories for pianos"
    },
    {
      "code": "92099200",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS- Other: Parts and accessories for the musical instruments of heading 9202"
    },
    {
      "code": "92099300",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS- OTHER: PARTS AND ACCESSORIES FOR THE MUSICAL INSTRUMENTS OF HEADING 9203"
    },
    {
      "code": "92099400",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS- Other: Parts and accessories for the musical instruments of heading 9207"
    },
    {
      "code": "92099900",
      "description": "PARTS (FOR EXAMPLE, MECHANISMS FOR MUSICAL BOXES) AND ACCESSORIES (FOR EXAMPLE, CARDS, DISCS AND ROLLS FOR MECHANICAL INSTRUMENTS) OF MUSICAL INSTRUMENTS; METRONOMES, TUNING FORKS AND PITCH PIPES OF ALL KINDS- Other: Other"
    },
    {
      "code": "93",
      "description": "Arms and ammunition; parts and accessories thereof"
    },
    {
      "code": "9301",
      "description": "MILITARY WEAPONS, OTHER THAN REVOLVERS, PISTOLS AND THE ARMS OF HEADING"
    },
    {
      "code": "930110",
      "description": "Artillery weapons (for exaple, gunds, howitzers and mortars)"
    },
    {
      "code": "93011010",
      "description": "SELF PROPELLED"
    },
    {
      "code": "93011090",
      "description": "OTHER"
    },
    {
      "code": "93012000",
      "description": "MILITARY WEAPONS, OTHER THAN REVOLVERS, PISTOLS AND THE ARMS OF HEADING 9307 - Rocket launchers; flame-throwers; grenade launchers; torpedo tubes and similar projectors"
    },
    {
      "code": "93019000",
      "description": "MILITARY WEAPONS, OTHER THAN REVOLVERS, PISTOLS AND THE ARMS OF HEADING 9307 - Other"
    },
    {
      "code": "9302",
      "description": "REVOLVERS AND PISTOLS, OTHER THAN THOSE OF HEADING 9303 OR 9304"
    },
    {
      "code": "93020000",
      "description": "REVOLVERS AND PISTOLS, OTHER THAN THOSE OF HEADING 9303 OR 9304"
    },
    {
      "code": "9303",
      "description": "OTHER FIREARMS AND SIMILAR DEVICES WHICH OPERATE BY THE FIRING OF AN EXPLOSIVE CHARGE (FOR EXAMPLE, SPORTING SHOTGUNS AND RIFLES, MUZZLE-LOADING FIREARMS, VERY PISTOLS AND OTHER DEVICES DESIGNED TO PROJECT ONLY SIGNAL FLARES, PISTOLS AND REVOLVERS FOR FIRING BLANK AMMUNITION, CAPTIVE-BOLT HUMANE KILLERS, LINETHROWING GUNS)"
    },
    {
      "code": "93031000",
      "description": "OTHER FIREARMS AND SIMILAR DEVICES WHICH OPERATE BY THE FIRING OF AN EXPLOSIVE CHARGE (FOR EXAMPLE, SPORTING SHOTGUNS AND RIFLES, MUZZLE-LOADING FIREARMS, VERY PISTOLS AND OTHER DEVICES DESIGNED TO PROJECT ONLY SIGNAL FLARES, PISTOLS AND REVOLVERS FOR FIRING BLANK AMMUNITION, CAPTIVE-BOLT HUMANE KILLERS, LINE-THROWING GUNS) Muzzle-loading firearms"
    },
    {
      "code": "93032000",
      "description": "OTHER FIREARMS AND SIMILAR DEVICES WHICH OPERATE BY THE FIRING OF AN EXPLOSIVE CHARGE (FOR EXAMPLE, SPORTING SHOTGUNS AND RIFLES, MUZZLE-LOADING FIREARMS, VERY PISTOLS AND OTHER DEVICES DESIGNED TO PROJECT ONLY SIGNAL FLARES, PISTOLS AND REVOLVERS FOR FIRING BLANK AMMUNITION, CAPTIVE-BOLT HUMANE KILLERS, LINE-THROWING GUNS) Other sporting, hunting or target-shooting shotguns, including combination shotgun-rifles"
    },
    {
      "code": "93033000",
      "description": "OTHER FIREARMS AND SIMILAR DEVICES WHICH OPERATE BY THE FIRING OF AN EXPLOSIVE CHARGE (FOR EXAMPLE, SPORTING SHOTGUNS AND RIFLES, MUZZLE-LOADING FIREARMS, VERY PISTOLS AND OTHER DEVICES DESIGNED TO PROJECT ONLY SIGNAL FLARES, PISTOLS AND REVOLVERS FOR FIRING BLANK AMMUNITION, CAPTIVE-BOLT HUMANE KILLERS, LINE-THROWING GUNS) Other sporting, hunting or target-shooting rifles"
    },
    {
      "code": "93039000",
      "description": "OTHER FIREARMS AND SIMILAR DEVICES WHICH OPERATE BY THE FIRING OF AN EXPLOSIVE CHARGE (FOR EXAMPLE, SPORTING SHOTGUNS AND RIFLES, MUZZLE-LOADING FIREARMS, VERY PISTOLS AND OTHER DEVICES DESIGNED TO PROJECT ONLY SIGNAL FLARES, PISTOLS AND REVOLVERS FOR FIRING BLANK AMMUNITION, CAPTIVE-BOLT HUMANE KILLERS, LINE-THROWING GUNS) Other"
    },
    {
      "code": "9304",
      "description": "OTHER ARMS (FOR EXAMPLE, SPRING, AIR OR GAS GUNS AND PISTOLS, TRUNCHEONS), EXCLUDING THOSE OF HEADING 9307"
    },
    {
      "code": "93040000",
      "description": "OTHER ARMS (FOR EXAMPLE, SPRING, AIR OR GAS GUNS AND PISTOLS, TRUNCHEONS), EXCLUDING THOSE OF HEADING 9307"
    },
    {
      "code": "9305",
      "description": "PARTS AND ACCESSORIES OF ARTICLES OF HEADINGS 9301 TO 9304"
    },
    {
      "code": "93051000",
      "description": "PARTS AND ACCESSORIES OF ARTICLES OF HEADINGS 9301 TO 9304 Of revolvers or pistols"
    },
    {
      "code": "930520",
      "description": "Of shotgunds or rifles of heading 9303"
    },
    {
      "code": "93052010",
      "description": "SHOTGUN BARRELS"
    },
    {
      "code": "93052090",
      "description": "OTHER"
    },
    {
      "code": "93059100",
      "description": "PARTS AND ACCESSORIES OF ARTICLES OF HEADINGS 9301 TO 9304 - Other : Of military weapons of heading 9301"
    },
    {
      "code": "93059900",
      "description": "PARTS AND ACCESSORIES OF ARTICLES OF HEADINGS 9301 TO 9304 - Other : Other"
    },
    {
      "code": "9306",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS - Shotgun cartridges and parts thereof; air gun pellets"
    },
    {
      "code": "93061000",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS CARTRIDGES FOR RIVETING OR SIMILAR TOOLS OR FOR CAPTIVE-BOLT HUMANE KILLERS AND PARTS THEREOF"
    },
    {
      "code": "93062100",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS - Shotgun cartridges and parts thereof; air gun pellets: Cartridges"
    },
    {
      "code": "93062900",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS - Shotgun cartridges and parts thereof; air gun pellets: Other"
    },
    {
      "code": "93063000",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS Other cartridges and parts thereof"
    },
    {
      "code": "93069000",
      "description": "BOMBS , GRENADES, TORPEDOES, MINES, MISSILES, AND SIMILAR MUNITIONS OF WAR AND PARTS THEREOF; CARTRIDGES AND OTHER AMMUNITION AND PROJECTILES AND PARTS THEREOF, INCLUDING SHOT AND CARTRIDGE WADS Other"
    },
    {
      "code": "9307",
      "description": "SWORDS, CUT LASSES, BAYONETS, LANCES AND SIMILAR ARMS AND PARTS THEREOF AND SCABBARDS AND SHEATHS THEREFOR"
    },
    {
      "code": "93070000",
      "description": "SWORDS, CUT LASSES, BAYONETS, LANCES AND SIMILAR ARMS AND PARTS THEREOF AND SCABBARDS AND SHEATHS THEREFOF"
    },
    {
      "code": "94",
      "description": "Furniture; bedding, mattresses, mattress supports, cushions and similar stuffed furnishing; lamps and lighting fittings, not elsewhere specified or included; illuminated signs, illuminated name-plates and the like; prefabricated building"
    },
    {
      "code": "9401",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF"
    },
    {
      "code": "94011000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Seats of a kind used for aircraft"
    },
    {
      "code": "94012000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Seats of a kind used for motor vehicles"
    },
    {
      "code": "94013000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Swivel seats and variable height adjustment"
    },
    {
      "code": "94014000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Seats other than garden seats or camping equipment, convertible into beds"
    },
    {
      "code": "94015000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF SEATS OF CANE, OSIER, BAMBOO OR SIMILAR MATERIALS"
    },
    {
      "code": "94015100",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF -SEATS OF CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: -- OF BAMBOO OR RATTAN"
    },
    {
      "code": "94015200",
      "description": "Of Bamboo"
    },
    {
      "code": "94015300",
      "description": "Of Rattan"
    },
    {
      "code": "94015900",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF - SEATS OF CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: -- OTHER"
    },
    {
      "code": "94016100",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF - Other seats, with wooden frames: Upholstered"
    },
    {
      "code": "94016900",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF - Other seats, with wooden frames: Other"
    },
    {
      "code": "94017100",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF - Other seats, with metal frames: Upholstered"
    },
    {
      "code": "94017900",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF - Other seats, with metal frames: Other"
    },
    {
      "code": "94018000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Other seats"
    },
    {
      "code": "94019000",
      "description": "SEATS (OTHER THAN THOSE OF HEADING 9402), WHETHER OR NOT CONVERTIBLE INTO BEDS, AND PARTS THEREOF Parts"
    },
    {
      "code": "9402",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS CHAIRS); BARBERS CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES"
    },
    {
      "code": "940210",
      "description": "Dentists, barbers or similar chairs and parts thereof"
    },
    {
      "code": "94021010",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS' CHAIRS); BARBERS' CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES - Dentists', barbers' or similar chairs and parts thereof: Dentists' chairs and parts thereof"
    },
    {
      "code": "94021090",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS' CHAIRS); BARBERS' CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES - Dentists', barbers' or similar chairs and parts thereof: Other"
    },
    {
      "code": "940290",
      "description": "Other"
    },
    {
      "code": "94029010",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS' CHAIRS); BARBERS' CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES- Other : Hospital beds with mechanical fittings"
    },
    {
      "code": "94029020",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS' CHAIRS); BARBERS' CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES- Other : Parts"
    },
    {
      "code": "94029090",
      "description": "MEDICAL, SURGICAL, DENTAL OR VETERINARY FURNITURE (FOR EXAMPLE, OPERATING TABLES, EXAMINATION TABLES, HOSPITAL BEDS WITH MECHANICAL FITTINGS, DENTISTS' CHAIRS); BARBERS' CHAIRS AND SIMILAR CHAIRS, HAVING ROTATING AS WELL AS BOTH RECLINING AND ELEVATING MOVEMENTS; PARTS OF THE FOREGOING ARTICLES- Other : Other"
    },
    {
      "code": "9403",
      "description": "OTHER FURNITURE AND PARTS THEREOF"
    },
    {
      "code": "940310",
      "description": "Metal furniture of a kind used in offices"
    },
    {
      "code": "94031010",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Metal furniture of a kind used in offices: Of steel"
    },
    {
      "code": "94031090",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Metal furniture of a kind used in offices: Other"
    },
    {
      "code": "940320",
      "description": "Other metal furniture"
    },
    {
      "code": "94032010",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Other metal furniture:Of steel"
    },
    {
      "code": "94032090",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Other metal furniture:Other"
    },
    {
      "code": "940330",
      "description": "Wooden furniture of a kind used in offices"
    },
    {
      "code": "94033010",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Wooden furniture of a kind used in offices:Cabinetware"
    },
    {
      "code": "94033090",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Wooden furniture of a kind used in offices: Other"
    },
    {
      "code": "94034000",
      "description": "OTHER FURNITURE AND PARTS THEREOF - Wooden furniture of a kind used in the kitchen"
    },
    {
      "code": "940350",
      "description": "Wooden furniture of a kind used in the bed room"
    },
    {
      "code": "94035010",
      "description": "OTHER FURNITURE AND PARTS THEREOF - - Wooden furniture of a kind used in the bed room: Bed stead"
    },
    {
      "code": "94035090",
      "description": "OTHER FURNITURE AND PARTS THEREOF - - Wooden furniture of a kind used in the bed room: Other"
    },
    {
      "code": "94036000",
      "description": "OTHER FURNITURE AND PARTS THEREOF -Other wooden furniture"
    },
    {
      "code": "94037000",
      "description": "OTHER FURNITURE AND PARTS THEREOF -Furniture of plastics"
    },
    {
      "code": "94038010",
      "description": "OTHER FURNITURE AND PARTS THEREOF - FURNITURE OF OTHER MATERIALS, INCLUDING CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: FURNITURE OF WICKER WORK OR BAMBOO"
    },
    {
      "code": "94038090",
      "description": "OTHER FURNITURE AND PARTS THEREOF - FURNITURE OF OTHER MATERIALS, INCLUDING CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: OTHER"
    },
    {
      "code": "94038100",
      "description": "OTHER FURNITURE AND PARTS THEREOF - FURNITURE OF OTHER MATERIALS, INCLUDING CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: OF BAMBOO OR RATTAN"
    },
    {
      "code": "94038200",
      "description": "OF BAMBOO"
    },
    {
      "code": "94038300",
      "description": "OF RATTAN"
    },
    {
      "code": "94038900",
      "description": "OTHER FURNITURE AND PARTS THEREOF - FURNITURE OF OTHER MATERIALS, INCLUDING CANE, OSIER, BAMBOO OR SIMILAR MATERIALS: OTHER"
    },
    {
      "code": "94039000",
      "description": "OTHER FURNITURE AND PARTS THEREOF -Parts"
    },
    {
      "code": "9404",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED"
    },
    {
      "code": "94041000",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED Mattress supports"
    },
    {
      "code": "940421",
      "description": "Of cellular rubber or plastics, whether or not covered"
    },
    {
      "code": "94042110",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Mattresses: Of cellular rubber or plastics, whether or not covered: Of rubber"
    },
    {
      "code": "94042190",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Mattresses: Of cellular rubber or plastics, whether or not covered: Of plastic"
    },
    {
      "code": "940429",
      "description": "Of other materials"
    },
    {
      "code": "94042910",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Mattresses: Of other materials: Spring interior"
    },
    {
      "code": "94042920",
      "description": "MATTRESS SUPPORTS;ARTICLES OF BEDDING AND SIMILAR FURNISHING - OF RUBBERISED COIR WITH OR WITHOUT COMBINATION OF OTHER MATERIALS, WHETHER OR NOT WITH METALLIC SPRINGS"
    },
    {
      "code": "94042990",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Mattresses: Of other materials: other"
    },
    {
      "code": "940430",
      "description": "Sleeping bags"
    },
    {
      "code": "94043010",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Sleeping bags: Filled with feathers or down"
    },
    {
      "code": "94043090",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Sleeping bags: Other"
    },
    {
      "code": "940490",
      "description": "Other"
    },
    {
      "code": "94049011",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Other : - Quilts: Filled with feathers or down"
    },
    {
      "code": "94049019",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Other : - Quilts: Other"
    },
    {
      "code": "94049091",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Other : - Other : Filled with feathers or down"
    },
    {
      "code": "94049099",
      "description": "MATTRESS SUPPORTS; ARTICLES OF BEDDING AND SIMILAR FURNISHING (FOR EXAMPLE, MATTRESSES, QUILTS, EIDERDOWNS, CUSHIONS, POUFFES AND PILLOWS) FITTED WITH SPRINGS OR STUFFED OR INTERNALLY FITTED WITH ANY MATERIAL OR OF CELLULAR RUBBER OR PLASTICS, WHETHER OR NOT COVERED - Other : - Other : Other"
    },
    {
      "code": "9405",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED"
    },
    {
      "code": "940510",
      "description": "Chandeliers and other electric ceiling or wall lighting fittings, excluding those of a kind used for lighting public open spaces or thorough fares"
    },
    {
      "code": "94051010",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Chandeliers and other electric ceiling or wall lighting fittings, excluding those of a kind used for lighting public open spaces or thorough fares: Hanging lamps, complete fittings"
    },
    {
      "code": "94051020",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Chandeliers and other electric ceiling or wall lighting fittings, excluding those of a kind used for lighting public open spaces or thorough fares: Wall lamps"
    },
    {
      "code": "94051090",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Chandeliers and other electric ceiling or wall lighting fittings, excluding those of a kind used for lighting public open spaces or thorough fares: Other"
    },
    {
      "code": "940520",
      "description": "Electric table, desk, bedside or floor-standing lamps"
    },
    {
      "code": "94052010",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Electric table, desk, bedside or floor-standing lamps: Table lamps, complete fittings"
    },
    {
      "code": "94052090",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Electric table, desk, bedside or floor-standing lamps: Other"
    },
    {
      "code": "94053000",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDEDLighting sets of a kind used for Christmas trees"
    },
    {
      "code": "940540",
      "description": "Other electric lamps and lighting fittings"
    },
    {
      "code": "94054010",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Other electric lamps and lighting fittings: Searchlights and sportlights"
    },
    {
      "code": "94054090",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Other electric lamps and lighting fittings: Other"
    },
    {
      "code": "940550",
      "description": "Non-electrical lamps and lighting fittings"
    },
    {
      "code": "94055010",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: Hurricane lanterns"
    },
    {
      "code": "94055020",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: Miner's safety lamps"
    },
    {
      "code": "94055031",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: - Oil pressure lamps: Kerosene pressure lanterns"
    },
    {
      "code": "94055039",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: - Oil pressure lamps: Other"
    },
    {
      "code": "94055040",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: Solar lanterns or lamps"
    },
    {
      "code": "94055051",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: - Other oil lamps: Metal"
    },
    {
      "code": "94055059",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Non-electrical lamps and lighting fittings: - Other oil lamps: Other"
    },
    {
      "code": "940560",
      "description": "Illuminated signs, illuminated name-plates and the like"
    },
    {
      "code": "94056010",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Illuminated signs, illuminated name-plates and the like : Of plastic"
    },
    {
      "code": "94056090",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED- Illuminated signs, illuminated name-plates and the like : Of other materials"
    },
    {
      "code": "94059100",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Parts: Of glass"
    },
    {
      "code": "94059200",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Parts: Of plastics"
    },
    {
      "code": "94059900",
      "description": "LAMPS AND LIGHTING FITTINGS INCLUDING SEARCHLIGHTS AND SPOTLIGHTS AND PARTS THEREOF, NOT ELSEWHERE SPECIFIED OR INCLUDED; ILLUMINATED SIGNS, ILLUMINATED NAME-PLATES AND THE LIKE, HAVING A PERMANENTLY FIXED LIGHT SOURCE, AND PARTS THEREOF NOT ELSEWHERE SPECIFIED OR INCLUDED - Parts: Other"
    },
    {
      "code": "9406",
      "description": "PREFABRICATED BUILDINGS"
    },
    {
      "code": "940600",
      "description": "Prefabricated buildings"
    },
    {
      "code": "94060011",
      "description": "PREFABRICATED BUILDINGS - Green houses: Green house-in ready to assemble sets"
    },
    {
      "code": "94060019",
      "description": "PREFABRICATED BUILDINGS - Green houses: Other"
    },
    {
      "code": "94060091",
      "description": "PREFABRICATED BUILDINGS - Other : Prefabricated housing material"
    },
    {
      "code": "94060092",
      "description": "PREFABRICATED BUILDINGS - Other : Prefabricated construction for cold storage"
    },
    {
      "code": "94060093",
      "description": "PREFABRICATED BUILDINGS - Other : Silos for storing ensilage"
    },
    {
      "code": "94060099",
      "description": "PREFABRICATED BUILDINGS - Other : Other"
    },
    {
      "code": "940610",
      "description": "Of wood"
    },
    {
      "code": "94061010",
      "description": "Green-houses"
    },
    {
      "code": "94061020",
      "description": "For cold storage"
    },
    {
      "code": "94061030",
      "description": "Silos for storing ensilage"
    },
    {
      "code": "94061090",
      "description": "Other"
    },
    {
      "code": "940690",
      "description": "Other"
    },
    {
      "code": "94069010",
      "description": "Green-houses"
    },
    {
      "code": "94069020",
      "description": "For cold storage"
    },
    {
      "code": "94069030",
      "description": "Silos for storing ensilage"
    },
    {
      "code": "94069090",
      "description": "Other"
    },
    {
      "code": "95",
      "description": "Toys, games and sports requisites; parts and accessories thereof"
    },
    {
      "code": "9501",
      "description": "OMITTED"
    },
    {
      "code": "95010010",
      "description": "WHEELED TOYS DESIGNED TO BE RIDDEN BY CHILDREN (FOR EXAMPLE, TRICYCLES, SCOOTERS, PEDAL CARS); DOLLR CARRIAGES - WHEELED TOYS DESIGNED TO BE RIDDEN BY CHILDREN (FOR EXAMPLE, TRICYCLES, SCOOTERS, PEDAL CARS); DOLLR CARRIAGES: WHEEL TOYS DESIGNED TO BE RIDDEN BY CHILDREN; DOLLS CARRIAGES"
    },
    {
      "code": "95010090",
      "description": "WHEELED TOYS DESIGNED TO BE RIDDEN BY CHILDREN (FOR EXAMPLE, TRICYCLES, SCOOTERS, PEDAL CARS); DOLLR CARRIAGES - WHEELED TOYS DESIGNED TO BE RIDDEN BY CHILDREN (FOR EXAMPLE, TRICYCLES, SCOOTERS, PEDAL CARS); DOLLR CARRIAGES: PARTS"
    },
    {
      "code": "9502",
      "description": "OMITTED"
    },
    {
      "code": "95021010",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: OF WOOD"
    },
    {
      "code": "95021020",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: OF METAL"
    },
    {
      "code": "95021030",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: OF PLASTICS"
    },
    {
      "code": "95021090",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: OTHER"
    },
    {
      "code": "95029100",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: - PARTS AND ACCESSORIES: GARMENTS AND ACCESSORIES THEREFOR, FOOT-WEAR AND HEADGEAR"
    },
    {
      "code": "95029900",
      "description": "DOLLS REPRESENTING ONLY HUMAN BEINGS - DOLLS, WHETHER OR NOT DRESSED: - PARTS AND ACCESSORIES: OTHER"
    },
    {
      "code": "9503",
      "description": "TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (\"\"SCALE\"\") MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALLKINDS"
    },
    {
      "code": "950300",
      "description": "Tricycles, scooters, pedal cars and similar wheeled toys; dolls carriages; dolls; other toys; reduced-size (scale) models and similar recreational models, working or not; puzzles of all kinds"
    },
    {
      "code": "95030010",
      "description": "TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS` CARRIAGES ; DOLLS` OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS --- OF WOOD"
    },
    {
      "code": "95030020",
      "description": "TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS --- OF METAL"
    },
    {
      "code": "95030030",
      "description": "TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS --- OF PLASTICS"
    },
    {
      "code": "95030090",
      "description": "TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS; OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS -TRICYCLES, SCOOTERS, PEDAL CARS AND SIMILAR WHEELED TOYS; DOLLS CARRIAGES; DOLLS;  OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS --- OTHER"
    },
    {
      "code": "95031000",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS ELECTRIC TRAINS, INCLUDING TRACKS, SIGNALS AND OTHER ACCESSORIES THEREFORE"
    },
    {
      "code": "95032000",
      "description": "OTHER TOYS; REDUCED-SIZE (SCALE) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS REDUCED-SIZE (SCALE) MODEL ASSEMBLY KITS, WHETHER OR NOT WORKING MODELS, EXCLUDING THOSE OF SUB-HEADING 9503 10"
    },
    {
      "code": "95033000",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS OTHER CONSTRUCTION SETS AND CONSTRUCTIONAL TOYS"
    },
    {
      "code": "95034100",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOYS REPRESENTING ANIMALS OR NON-HUMAN CREATURES: STUFFED"
    },
    {
      "code": "95034910",
      "description": "OTHER TOYS; REDUCED-SIZE (SCALE) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOYS REPRESENTING ANIMALS OR NON-HUMAN CREATURES: - OTHER : OF WOOD"
    },
    {
      "code": "95034920",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOYS REPRESENTING ANIMALS OR NON-HUMAN CREATURES: - OTHER : OF METAL"
    },
    {
      "code": "95034930",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOYS REPRESENTING ANIMALS OR NON-HUMAN CREATURES: - OTHER : OF PLASTICS"
    },
    {
      "code": "95034990",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOYS REPRESENTING ANIMALS OR NON-HUMAN CREATURES: - OTHER : OTHER"
    },
    {
      "code": "95035010",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOY MUSICAL INSTRUMENTS AND APPARATUS: INSTRUMENTS AND APPARATUS"
    },
    {
      "code": "95035090",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - TOY MUSICAL INSTRUMENTS AND APPARATUS: PARTS"
    },
    {
      "code": "95036010",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - PUZZLES: OF WOOD"
    },
    {
      "code": "95036090",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - PUZZLES: OTHER"
    },
    {
      "code": "95037010",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER TOYS, PUT UP IN SETS OR OUTFITS : OF PLASTICS"
    },
    {
      "code": "95037090",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER TOYS, PUT UP IN SETS OR OUTFITS : OTHER"
    },
    {
      "code": "95038010",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER TOYS AND MODELS, INCORPORATING A MOTOR: TOYS AND MODELS"
    },
    {
      "code": "95038090",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER TOYS AND MODELS, INCORPORATING A MOTOR: PARTS"
    },
    {
      "code": "95039010",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER: TOY WEAPONS"
    },
    {
      "code": "95039020",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER: OTHER TOYS"
    },
    {
      "code": "95039090",
      "description": "OTHER TOYS; REDUCED-SIZE (*SCALE*) MODELS AND SIMILAR RECREATIONAL MODELS, WORKING OR NOT; PUZZLES OF ALL KINDS - OTHER: PARTS"
    },
    {
      "code": "9504",
      "description": "VIDEO GAME CONSOLES AND MACHINBES, ARTICALS OF FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT"
    },
    {
      "code": "95042000",
      "description": "ARTICLES FOR FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT- ARTICLES AND ACCESSORIES FOR BILLIARDS OF ALL KINDS"
    },
    {
      "code": "950430",
      "description": "Other games, operated by coins, bank notes, bank cards, tokens or by other means of payment, other than bowling alley equipment"
    },
    {
      "code": "95043000",
      "description": "ARTICLES FOR FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT - OTHER GAMES, OPERATED BY COINS, BANK NOTES, BANK CARDS, TOKENS OR BY OTHER MEANS OF PAYMENT, OTHER THAN BOWLING ALLEY EQUIPMENT"
    },
    {
      "code": "95044000",
      "description": "ARTICLES FOR FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT - PLAYING CARDS"
    },
    {
      "code": "950450",
      "description": "Video game"
    },
    {
      "code": "95045000",
      "description": "VIDEO GAME CONSOLES AND MACHINES OTHER THAN SUB-HEADING 9504 30"
    },
    {
      "code": "950490",
      "description": "Other"
    },
    {
      "code": "95049010",
      "description": "ARTICLES FOR FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT - Other : Chess set, all types"
    },
    {
      "code": "95049020",
      "description": "CAROM BOARD, WITH OR WITHOUT COINS AND STRIKERS"
    },
    {
      "code": "95049090",
      "description": "ARTICLES FOR FUNFAIR, TABLE OR PARLOUR GAMES, INCLUDING PINTABLES, BILLIARDS, SPECIAL TABLES FOR CASINO GAMES AND AUTOMATIC BOWLING ALLEY EQUIPMENT - Other : Other"
    },
    {
      "code": "9505",
      "description": "FESTIVE, CARNIVAL OR OTHER ENTERTAINMENT ARTICLES, INCLUDING CONJURING TRICKS AND NOVELTY JOKES"
    },
    {
      "code": "95051000",
      "description": "FESTIVE, CARNIVAL OR OTHER ENTERTAINMENT ARTICLES, INCLUDING CONJURING TRICKS AND NOVELTY JOKES - Articles for Christmas festivities"
    },
    {
      "code": "950590",
      "description": "Other"
    },
    {
      "code": "95059010",
      "description": "FESTIVE, CARNIVAL OR OTHER ENTERTAINMENT ARTICLES, INCLUDING CONJURING TRICKS AND NOVELTY JOKES - Other : Magical equipments"
    },
    {
      "code": "95059090",
      "description": "FESTIVE, CARNIVAL OR OTHER ENTERTAINMENT ARTICLES, INCLUDING CONJURING TRICKS AND NOVELTY JOKES - Other : Other"
    },
    {
      "code": "9506",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLE-TENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Snow-skis and other snow-ski equipment"
    },
    {
      "code": "95061100",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Snow-skis and other snow-ski equipment : Skis"
    },
    {
      "code": "95061200",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Snow-skis and other snow-ski equipment : Ski-fastenings (ski-bindings)"
    },
    {
      "code": "95061900",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Snow-skis and other snow-ski equipment : Other"
    },
    {
      "code": "95062100",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Water-skis, surf-boards, sailboards and other water-sport equipment: Sailboards"
    },
    {
      "code": "95062900",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Water-skis, surf-boards, sailboards and other water-sport equipment: Other"
    },
    {
      "code": "95063100",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Golf clubs and other golf equipment: Clubs, complete"
    },
    {
      "code": "95063200",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Golf clubs and other golf equipment: Balls"
    },
    {
      "code": "95063900",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Golf clubs and other golf equipment: Other"
    },
    {
      "code": "95064000",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS Articles and equipment for table-tennis"
    },
    {
      "code": "95065100",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Tennis, badminton or similar rackets, whether or not strung: Lawn-tennis rackets, whether or not strung"
    },
    {
      "code": "950659",
      "description": "Other"
    },
    {
      "code": "95065910",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Tennis, badminton or similar rackets, whether or not strung: - Other : Squash or racketball badminton rackets, whether or not strung"
    },
    {
      "code": "95065990",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Tennis, badminton or similar rackets, whether or not strung: - Other : Other"
    },
    {
      "code": "95066100",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: Lawn-tennis balls"
    },
    {
      "code": "950662",
      "description": "Inflatable"
    },
    {
      "code": "95066210",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Inflatable: Football"
    },
    {
      "code": "95066220",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Inflatable: Volley ball"
    },
    {
      "code": "95066230",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Inflatable: Basket ball"
    },
    {
      "code": "95066290",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Inflatable: Other"
    },
    {
      "code": "950669",
      "description": "Other"
    },
    {
      "code": "95066910",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Other : Hockey ball"
    },
    {
      "code": "95066920",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Other : Cricket ball"
    },
    {
      "code": "95066930",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Other : Golf ball"
    },
    {
      "code": "95066940",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Other : Rugby ball"
    },
    {
      "code": "95066990",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Balls, other than golf balls and table-tennis balls: - Other : Other"
    },
    {
      "code": "95067000",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS Ice skates and roller skates, including skating boots with skates attached"
    },
    {
      "code": "950691",
      "description": "Articles and equipment for general physical exercise, gymnastics or athletics"
    },
    {
      "code": "95069110",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Articles and equipment for general physical exercise, gymnastics or athletics: Boxing equipment"
    },
    {
      "code": "95069190",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Articles and equipment for general physical exercise, gymnastics or athletics: Other"
    },
    {
      "code": "950699",
      "description": "Other"
    },
    {
      "code": "95069910",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Badminton shuttle cocks"
    },
    {
      "code": "95069920",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Leg pads and bats for cricket"
    },
    {
      "code": "95069930",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Shoulder pads for football"
    },
    {
      "code": "95069940",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Hockey sticks and blades"
    },
    {
      "code": "95069950",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Polo sticks including blades, shafts and heads"
    },
    {
      "code": "95069960",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Sports net"
    },
    {
      "code": "95069970",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Tennis and badminton racket pressures"
    },
    {
      "code": "95069980",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Shin-guards and elbow or shoulders pads excluding those for football; waist, thigh and hip protective equipment"
    },
    {
      "code": "95069990",
      "description": "ARTICLES AND EQUIPMENT FOR GENERAL PHYSICAL EXERCISE, GYMNASTICS, ATHLETICS, OTHER SPORTS (INCLUDING TABLETENNIS) OR OUT-DOOR GAMES, NOT SPECIFIED OR INCLUDED ELSEWHERE IN THIS CHAPTER; SWIMMING POOLS AND PADDLING POOLS - Other : - Other : Other"
    },
    {
      "code": "9507",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES"
    },
    {
      "code": "95071000",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES Fishing rods"
    },
    {
      "code": "95072000",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES Fish-hooks, whether or not snelled"
    },
    {
      "code": "95073000",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES Fishing reels"
    },
    {
      "code": "950790",
      "description": "Other"
    },
    {
      "code": "95079010",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES - Other : Fish landing and butterfly nets"
    },
    {
      "code": "95079090",
      "description": "FISHING RODS, FISH-HOOKS AND OTHER LINE FISHING TACKLE; FISH LANDING NETS, BUTTERFLY NETS AND SIMILAR NETS; DECOY BIRDS (OTHER THAN THOSE OF HEADING 9208 OR 9705) AND SIMILAR HUNTING OR SHOOTING REQUISITES - Other : Other"
    },
    {
      "code": "9508",
      "description": "ROUNDABOUTS, SWINGS, SHOOTING GALLERIES AND OTHER FAIRGROUND AMUSEMENTS; TRAVELLING CIRCUSES, TRAVELLING MENAGERIES AND TRAVELLING THEATRES"
    },
    {
      "code": "95081000",
      "description": "ROUNDABOUTS, SWINGS, SHOOTING GALLERIES AND OTHER FAIRGROUND AMUSEMENTS; TRAVELLING CIRCUSES, TRAVELLING MENAGERIES AND TRAVELLING THEATRES Travelling circuses and travelling menageries"
    },
    {
      "code": "95089000",
      "description": "ROUNDABOUTS, SWINGS, SHOOTING GALLERIES AND OTHER FAIRGROUND AMUSEMENTS; TRAVELLING CIRCUSES, TRAVELLING MENAGERIES AND TRAVELLING THEATRES Other"
    },
    {
      "code": "96",
      "description": "Miscellaneous manufactured articles"
    },
    {
      "code": "9601",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING)"
    },
    {
      "code": "96011000",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) Worked ivory and articles of ivory"
    },
    {
      "code": "960190",
      "description": "Other"
    },
    {
      "code": "96019010",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) - Other : Worked tortoise-shell and articles thereof"
    },
    {
      "code": "96019020",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) - Other : Worked mother-of-pearl and articles thereof"
    },
    {
      "code": "96019030",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) - Other : Worked bone (excluding whale bone) and articles thereof"
    },
    {
      "code": "96019040",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) - Other : Worked horn, coral and other animal carving material and articles thereof"
    },
    {
      "code": "96019090",
      "description": "WORKED IVORY, BONE, TORTOISE-SHELL, HORN, ANTLERS, CORAL, MOTHER-OF-PEARL AND OTHER ANIMAL CARVING MATERIAL, AND ARTICLES OF THESE MATERIALS (INCLUDING ARTICLES OBTAINED BY MOULDING) - Other : Other"
    },
    {
      "code": "9602",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN"
    },
    {
      "code": "960200",
      "description": "Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin"
    },
    {
      "code": "96020010",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN - Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin: Worked vegetable carving material and articles thereof"
    },
    {
      "code": "96020020",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN - Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin: Moulded or carved articles of wax, stearin, natural gums and resins and other moulded or carved articles"
    },
    {
      "code": "96020030",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN - Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin: Gelatin capsules, empty"
    },
    {
      "code": "96020040",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN - Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin: Other articles of unhardened gelatin"
    },
    {
      "code": "96020090",
      "description": "WORKED VEGETABLE OR MINERAL CARVING MATERIAL AND ARTICLES OF THESE MATERIALS MOULDED OR CARVED ARTICLES OF WAX, OF STEARIN, OF NATURAL GUMS OR NATURAL RESINS OR OF MODELLING PASTES, AND OTHER MOULDED OR CARVED ARTICLES, NOT ELSEWHERE SPECIFIED OR INCLUDED; WORKED, UNHARDENED GELATIN (EXCEPT GELATIN OF HEADING 3503) AND ARTICLES OF UNHARDENED GELATIN - Worked vegetable or mineral carving material and articles of these materials moulded or carved articles of wax, of stearin, of natural gums or natural resins or of modelling pastes, and other moulded or carved articles, not elsewhere specified or included; worked, unhardened gelatin (except gelatin of heading 3503) and articles of unhardened gelatin: Other"
    },
    {
      "code": "9603",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HANDOPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES)"
    },
    {
      "code": "96031000",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) Brooms and brushes, consisting of twigs or other vegetable materials, bound together, with or without handles"
    },
    {
      "code": "96032100",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Tooth brushes, shaving brushes, hair brushes, nail brushes, eyelash brushes and other toilet brushes for use on the person, including such brushes constituting parts of appliances: Tooth brushes including dental-plate brushes"
    },
    {
      "code": "96032900",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Tooth brushes, shaving brushes, hair brushes, nail brushes, eyelash brushes and other toilet brushes for use on the person, including such brushes constituting parts of appliances: Other"
    },
    {
      "code": "960330",
      "description": "Artists brushes, writing brushes and similar brushes for the application of cosmetics"
    },
    {
      "code": "96033010",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Artists' brushes, writing brushes and similar brushes for the application of cosmetics: Artist brushes"
    },
    {
      "code": "96033020",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Artists' brushes, writing brushes and similar brushes for the application of cosmetics: Brushes for the application of cosmetics"
    },
    {
      "code": "96033090",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Artists' brushes, writing brushes and similar brushes for the application of cosmetics: Other"
    },
    {
      "code": "960340",
      "description": "Paint, distemper, varnish or similar brushes (other than brushes of sub-heading 9603 30); paint pads and rollers"
    },
    {
      "code": "96034010",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Paint, distemper, varnish or similar brushes (other than brushes of sub-heading 9603 30); paint pads and rollers:Paint, distemper, varnish or similar brushes (other than brushes of sub-heading 9603 30);"
    },
    {
      "code": "96034020",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) - Paint, distemper, varnish or similar brushes (other than brushes of sub-heading 9603 30); paint pads and rollers:Paint pads and rollers"
    },
    {
      "code": "96035000",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) Other brushes constituting parts of machines, appliances or vehicles"
    },
    {
      "code": "96039000",
      "description": "BROOMS, BRUSHES (INCLUDING BRUSHES CONSTITUTING PARTS OF MACHINES, APPLIANCES OR VEHICLES), HAND-OPERATED MECHANICAL FLOOR SWEEPERS, NOT MOTORISED, MOPS AND FEATHER DUSTERS; PREPARED KNOTS AND TUFTS FOR BROOM OR BRUSH MAKING; PAINT PADS AND ROLLERS; SQUEEGEES (OTHER THAN ROLLER SQUEEGEES) Other"
    },
    {
      "code": "9604",
      "description": "HAND SIEVES AND HAND RIDDLES"
    },
    {
      "code": "96040000",
      "description": "HAND SIEVES AND HAND RIDDLES"
    },
    {
      "code": "9605",
      "description": "TRAVEL SETS FOR PERSONAL TOILET, SEWING OR SHOE OR CLOTHES CLEANING"
    },
    {
      "code": "960500",
      "description": "Travel sets for personal toilet, sewing or shoe or clothes cleaning"
    },
    {
      "code": "96050010",
      "description": "TRAVEL SETS FOR PERSONAL TOILET, SEWING OR SHOE OR CLOTHES CLEANING - Travel sets for personal toilet, sewing or shoe or clothes cleaning : For personal toilet"
    },
    {
      "code": "96050090",
      "description": "TRAVEL SETS FOR PERSONAL TOILET, SEWING OR SHOE OR CLOTHES CLEANING - Travel sets for personal toilet, sewing or shoe or clothes cleaning : Other"
    },
    {
      "code": "9606",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESS-STUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS"
    },
    {
      "code": "960610",
      "description": "Press-fasteners, snap-fasteners and press-studs and parts thereof"
    },
    {
      "code": "96061010",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Press-fasteners, snap-fasteners and press-studs and parts thereof : Press-fasteners, snap-fasteners and press-studs"
    },
    {
      "code": "96061020",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Press-fasteners, snap-fasteners and press-studs and parts thereof : Parts"
    },
    {
      "code": "96062100",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Buttons : Of plastics, not covered with textile material"
    },
    {
      "code": "96062200",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Buttons : Of base metals, not covered with textile material"
    },
    {
      "code": "960629",
      "description": "Other"
    },
    {
      "code": "96062910",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Buttons : - Other : Button of coconut shell or wood"
    },
    {
      "code": "96062990",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Buttons : - Other : Other"
    },
    {
      "code": "960630",
      "description": "Button moulds and other parts of buttons; button blanks"
    },
    {
      "code": "96063010",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Button moulds and other parts of buttons; button blanks : Button blanks"
    },
    {
      "code": "96063090",
      "description": "BUTTONS, PRESS-FASTENERS, SNAP-FASTENERS AND PRESSSTUDS, BUTTON MOULDS AND OTHER PARTS OF THESE ARTICLES; BUTTON BLANKS - Button moulds and other parts of buttons; button blanks : Other"
    },
    {
      "code": "9607",
      "description": "SLIDE FASTENERS AND PARTS THEREOF - Slide fasteners"
    },
    {
      "code": "960711",
      "description": "Fitted with chain scoops of base metal"
    },
    {
      "code": "96071110",
      "description": "SLIDE FASTENERS AND PARTS THEREOF - Slide fasteners : - Fitted with chain scoops of base metal : Zip fasteners"
    },
    {
      "code": "96071190",
      "description": "SLIDE FASTENERS AND PARTS THEREOF - Slide fasteners : - Fitted with chain scoops of base metal : Other"
    },
    {
      "code": "960719",
      "description": "Other"
    },
    {
      "code": "96071910",
      "description": "SLIDE FASTENERS AND PARTS THEREOF - Slide fasteners : - Other : Zip fasteners"
    },
    {
      "code": "96071990",
      "description": "SLIDE FASTENERS AND PARTS THEREOF - Slide fasteners : - Other : Other"
    },
    {
      "code": "96072000",
      "description": "SLIDE FASTENERS AND PARTS THEREOF Parts"
    },
    {
      "code": "9608",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUSTIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609"
    },
    {
      "code": "960810",
      "description": "Ball point pens"
    },
    {
      "code": "96081011",
      "description": "HIGH VALU BALL POINT PENS"
    },
    {
      "code": "96081012",
      "description": "BALL POINT PENS With body or cap of precious metal or rolled precious metal"
    },
    {
      "code": "96081019",
      "description": "OTHER"
    },
    {
      "code": "96081091",
      "description": "HIGH VALU BALL POINT PENS"
    },
    {
      "code": "96081092",
      "description": "BALL POINT PENS With body or cap of precious metal or rolled precious metal"
    },
    {
      "code": "96081099",
      "description": "other"
    },
    {
      "code": "960820",
      "description": "felt tipped pen"
    },
    {
      "code": "96082000",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 Felt tipped and other porous-tipped pens and markers"
    },
    {
      "code": "960830",
      "description": "Fountain pens, stylograph pens and other pens fountain pens"
    },
    {
      "code": "96083011",
      "description": "HIGH VALUE FOUNTAIN PENS"
    },
    {
      "code": "96083012",
      "description": "With body or cap of precious metal or rolled precious metal"
    },
    {
      "code": "96083019",
      "description": "OTHER"
    },
    {
      "code": "96083021",
      "description": "HIGH VALUE PENS"
    },
    {
      "code": "96083022",
      "description": "With body or cap of precious metal or rolled precious metal"
    },
    {
      "code": "96083029",
      "description": "OTHER"
    },
    {
      "code": "96083091",
      "description": "HIGH VALUE PENS"
    },
    {
      "code": "96083092",
      "description": "With body or cap of precious metal or rolled precious metal"
    },
    {
      "code": "96083099",
      "description": "OTHER"
    },
    {
      "code": "960831",
      "description": "Indian ink drawing pens"
    },
    {
      "code": "960839",
      "description": "Other"
    },
    {
      "code": "96084000",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 Propelling or sliding pencils"
    },
    {
      "code": "96085000",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 Sets of articles from two or more of the foregoing sub-headings"
    },
    {
      "code": "960860",
      "description": "Refills for ball point pens, comprising the ball point and ink-reservoir"
    },
    {
      "code": "96086010",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : With liquid ink (for rolling ball-pen)"
    },
    {
      "code": "96086090",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : Other"
    },
    {
      "code": "960891",
      "description": "Pen nibs and nib points"
    },
    {
      "code": "96089110",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Pen nibs and nib points : Nib points for pen"
    },
    {
      "code": "96089120",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Pen nibs and nib points : Nibs of wool felt or plastics for use in the manufacture of porous tip pen or markers"
    },
    {
      "code": "96089130",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Pen nibs and nib points : Other pen nibs"
    },
    {
      "code": "96089191",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Pen nibs and nib points : - Other : Of metal"
    },
    {
      "code": "96089199",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Pen nibs and nib points : - Other : Other"
    },
    {
      "code": "960899",
      "description": "Other"
    },
    {
      "code": "96089910",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Other : Pen holders, pencil holders and similar holders"
    },
    {
      "code": "96089990",
      "description": "BALL POINT PENS; FELT TIPPED AND OTHER POROUS-TIPPED PENS AND MARKERS; FOUNTAIN PENS; STYLOGRAPH PENS AND OTHER PENS; DUPLICATING STYLOS; PROPELLING OR SLIDING PENCILS; PEN HOLDERS, PENCIL HOLDERS AND SIMILAR HOLDERS; PARTS (INCLUDING CAPS AND CLIPS) OF THE FOREGOING ARTICLES, OTHER THAN THOSE OF HEADING 9609 - Refills for ball point pens, comprising the ball point and ink-reservoir : - Other : - Other : Other"
    },
    {
      "code": "9609",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS CHALKS"
    },
    {
      "code": "96091000",
      "description": "Pencils and crayons, with leads encased in a rigid sheath"
    },
    {
      "code": "96092000",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS' CHALKS - Pencil leads, black or coloured"
    },
    {
      "code": "960990",
      "description": "Other"
    },
    {
      "code": "96099010",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS' CHALKS - Other : Slate pencils"
    },
    {
      "code": "96099020",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS' CHALKS - Other : Other pencils"
    },
    {
      "code": "96099030",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS' CHALKS - Other : Pastels, drawing charcoals and writing or drawing chalks and tailors chalks"
    },
    {
      "code": "96099090",
      "description": "PENCILS (OTHER THAN PENCILS OF HEADING 9608 ), CRAYONS, PENCIL LEADS, PASTELS, DRAWING CHARCOALS, WRITING OR DRAWING CHALKS AND TAILORS' CHALKS - Other : Other"
    },
    {
      "code": "9610",
      "description": "SLATES AND BOARDS, WITH WRITING OR DRAWING SURFACES, WHETHER OR NOT FRAMED"
    },
    {
      "code": "96100000",
      "description": "SLATES AND BOARDS, WITH WRITING OR DRAWING SURFACES, WHETHER OR NOT FRAMED"
    },
    {
      "code": "9611",
      "description": "DATE, SEALING OR NUMBERING STAMPS, AND THE LIKE (INCLUDING DEVICES FOR PRINTING OR EMBOSSING LABELS), DESIGNED FOR OPERATING IN THE HAND; HAND-OPERATED COMPOSING STICKS AND HAND PRINTING SETS INCORPORATING SUCH COMPOSING STICKS"
    },
    {
      "code": "96110000",
      "description": "DATE, SEALING OR NUMBERING STAMPS, AND THE LIKE (INCLUDING DEVICES FOR PRINTING OR EMBOSSING LABELS), DESIGNED FOR OPERATING IN THE HAND; HAND-OPERATED COMPOSING STICKS AND HAND PRINTING SETS INCORPORATING SUCH COMPOSING STICKS"
    },
    {
      "code": "9612",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES"
    },
    {
      "code": "961210",
      "description": "Ribbons"
    },
    {
      "code": "96121010",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES - Ribbons: Computer printer ribbon"
    },
    {
      "code": "96121020",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES - Ribbons: Ribbon for typewriters, other than electronic and similar machines"
    },
    {
      "code": "96121030",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES - Ribbons: Ribbon for electronic typewriter"
    },
    {
      "code": "96121090",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES - Ribbons: Other"
    },
    {
      "code": "96122000",
      "description": "TYPEWRITER OR SIMILAR RIBBONS, INKED OR OTHERWISE PREPARED FOR GIVING IMPRESSIONS, WHETHER OR NOT ON SPOOLS OR IN CARTRIDGES; INK-PADS, WHETHER OR NOT INKED, WITH OR WITHOUT BOXES - Ink-pads"
    },
    {
      "code": "9613",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS"
    },
    {
      "code": "96131000",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS - Pocket lighters, gas fuelled, non-refillable"
    },
    {
      "code": "96132000",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS - Pocket lighters, gas fuelled, refillable"
    },
    {
      "code": "961380",
      "description": "Other lighters"
    },
    {
      "code": "96138010",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS - Other lighters:Electronic"
    },
    {
      "code": "96138090",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS - Other lighters:Other"
    },
    {
      "code": "96139000",
      "description": "CIGARETTE LIGHTERS AND OTHER LIGHTERS, WHETHER OR NOT MECHANICAL OR ELECTRICAL, AND PARTS THEREOF OTHER THAN FLINTS AND WICKS - Parts"
    },
    {
      "code": "9614",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR  OR CIGARETTE HOLDERS AND PARTS THEREOF"
    },
    {
      "code": "96140000",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR  OR CIGARETTE HOLDERS AND PARTS THEREOF"
    },
    {
      "code": "96142000",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR OR CIGARETTE HOLDERS, AND PARTS THEREOF PIPES AND PIPE BOWLS"
    },
    {
      "code": "96149010",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR OR CIGARETTE HOLDERS, AND PARTS THEREOF - OTHER : HUBLE AND BUBLE OF BASE METAL"
    },
    {
      "code": "96149020",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR OR CIGARETTE HOLDERS, AND PARTS THEREOF - Other : Hookah of coconut shell"
    },
    {
      "code": "96149090",
      "description": "SMOKING PIPES (INCLUDING PIPE BOWLS) AND CIGAR OR CIGARETTE HOLDERS, AND PARTS THEREOF - OTHER : OTHER"
    },
    {
      "code": "9615",
      "description": "COMBS, HAIR-SLIDES AND THE LIKE, HAIRPINS, CURLING PINS, CURLING GRIPS, HAIR-CURLERS AND THE LIKE, OTHER THAN THOSE OF HEADING 8516, AND PARTS THEREOF"
    },
    {
      "code": "96151100",
      "description": "COMBS, HAIR-SLIDES AND THE LIKE, HAIRPINS, CURLING PINS, CURLING GRIPS, HAIR-CURLERS AND THE LIKE, OTHER THAN THOSE OF HEADING 8516, AND PARTS THEREOF - Combs, hair-slides and the like: Of hard rubber or plastics"
    },
    {
      "code": "96151900",
      "description": "COMBS, HAIR-SLIDES AND THE LIKE, HAIRPINS, CURLING PINS, CURLING GRIPS, HAIR-CURLERS AND THE LIKE, OTHER THAN THOSE OF HEADING 8516, AND PARTS THEREOF - Combs, hair-slides and the like: Other"
    },
    {
      "code": "96159000",
      "description": "COMBS, HAIR-SLIDES AND THE LIKE, HAIRPINS, CURLING PINS, CURLING GRIPS, HAIR-CURLERS AND THE LIKE, OTHER THAN THOSE OF HEADING 8516, AND PARTS THEREOF Other"
    },
    {
      "code": "9616",
      "description": "SCENT SPRAYS AND SIMILAR TOILET SPRAYS, AND MOUNTS AND HEADS THEREFOR; POWDER-PUFFS AND PADS FOR THE APPLICATION OF COSMETICS OR TOILET PREPARATIONS"
    },
    {
      "code": "961610",
      "description": "Scent sprays and similar toilet sprays, and mounts and heads therefor"
    },
    {
      "code": "96161010",
      "description": "SCENT SPRAYS AND SIMILAR TOILET SPRAYS, AND MOUNTS AND HEADS THEREFOR; POWDER-PUFFS AND PADS FOR THE APPLICATION OF COSMETICS OR TOILET PREPARATIONS - Scent sprays and similar toilet sprays, and mounts and heads therefor : Scent sprays and similar toilet sprays"
    },
    {
      "code": "96161020",
      "description": "SCENT SPRAYS AND SIMILAR TOILET SPRAYS, AND MOUNTS AND HEADS THEREFOR; POWDER-PUFFS AND PADS FOR THE APPLICATION OF COSMETICS OR TOILET PREPARATIONS - Scent sprays and similar toilet sprays, and mounts and heads therefor : Mounts and heads"
    },
    {
      "code": "96162000",
      "description": "SCENT SPRAYS AND SIMILAR TOILET SPRAYS, AND MOUNTS AND HEADS THEREFOR; POWDER-PUFFS AND PADS FOR THE APPLICATION OF COSMETICS OR TOILET PREPARATIONS Powder-puffs and pads for the application of cosmetics or toilet preparations"
    },
    {
      "code": "9617",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS"
    },
    {
      "code": "961700",
      "description": "Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners"
    },
    {
      "code": "96170011",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS - Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners: - Vacuum flasks and other vacuum vessels, complete with case: Vacuum flasks having a capacity not exceeding 0.75 l"
    },
    {
      "code": "96170012",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS - Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners: - Vacuum flasks and other vacuum vessels, complete with case: Vacuum flasks having a capacity exceeding 0.75 l"
    },
    {
      "code": "96170013",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS - Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners: - Vacuum flasks and other vacuum vessels, complete with case: Casserol and other vacuum containers"
    },
    {
      "code": "96170019",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS - Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners: - Vacuum flasks and other vacuum vessels, complete with case: Other"
    },
    {
      "code": "96170090",
      "description": "VACUUM FLASKS AND OTHER VACUUM VESSELS, COMPLETE WITH CASES; PARTS THEREOF OTHER THAN GLASS INNERS - Vacuum flasks and other vacuum vessels, complete with cases; parts thereof other than glass inners: Parts (other than glass inners)"
    },
    {
      "code": "9618",
      "description": "TAILORS DUMMIES AND OTHER LAY FIGURES; AUTOMATA AND OTHER ANIMATED DISPLAYS, USED FOR SHOP WINDOW DRESSING"
    },
    {
      "code": "96180000",
      "description": "TAILORS DUMMIES AND OTHER LAY FIGURES; AUTOMATA AND OTHER ANIMATED DISPLAYS, USED FOR SHOP WINDOW DRESSING"
    },
    {
      "code": "9619",
      "description": "SANITARY TOWELS (PADS) AND TAMPONS, NAPKINS AND NAPKIN LINERS FOR BABIES AND SIMILAR ARTICLES, OF ANY MATERIAL"
    },
    {
      "code": "961900",
      "description": "Sanitary towels (Pads) and tampons, napkins and napkin liners for babies and similar articles, of any material"
    },
    {
      "code": "96190010",
      "description": "sanitary towels 9pads) or sanitary napkins"
    },
    {
      "code": "96190020",
      "description": "TAMPONS"
    },
    {
      "code": "96190030",
      "description": "NAPKINS AND NAPKIN LINERS FOR BABIES"
    },
    {
      "code": "96190040",
      "description": "CLINICAL DIAPERS"
    },
    {
      "code": "96190090",
      "description": "OTHER"
    },
    {
      "code": "96200000",
      "description": "MONOPODS, BIPODS, TRIPODS AND SIMILAR ARTICLES"
    },
    {
      "code": "97",
      "description": "Works of art, collectors' pieces and antiques"
    },
    {
      "code": "9701",
      "description": "PAINTINGS, DRAWINGS AND PASTELS, EXECUTED ENTIRELY BY HAND, OTHER THAN DRAWINGS OF HEADING 4906 AND OTHER THAN HAND-PAINTED OR HAND-DECORATED MANUFACTURED ARTICLES; COLLAGES AND SIMILAR DECORATIVE PLAQUES"
    },
    {
      "code": "970110",
      "description": "Paintings, drawings and pastels :"
    },
    {
      "code": "97011010",
      "description": "Madhubani paintings (on textiles)"
    },
    {
      "code": "97011020",
      "description": "Kalamkari paintings (on textiles)"
    },
    {
      "code": "97011030",
      "description": "Rajasthani paintings (on textiles)"
    },
    {
      "code": "97011090",
      "description": "Other"
    },
    {
      "code": "970190",
      "description": "Other"
    },
    {
      "code": "97019091",
      "description": "Domestic articles of wood (hand decorated )"
    },
    {
      "code": "97019092",
      "description": "Restaurant decoration of plastics"
    },
    {
      "code": "97019099",
      "description": "Other"
    },
    {
      "code": "9702",
      "description": "ORIGINAL ENGRAVINGS, PRINTS AND LITHOGRAPHS"
    },
    {
      "code": "97020000",
      "description": "ORIGINAL ENGRAVINGS, PRINTS AND LITHOGRAPHS"
    },
    {
      "code": "9703",
      "description": "ORIGINAL SCULPTURES AND STATUARY, IN ANY MATERIAL"
    },
    {
      "code": "970300",
      "description": "Original sculptures and statuary, in any material"
    },
    {
      "code": "97030010",
      "description": "Original sculptures and statuary, in metal"
    },
    {
      "code": "97030020",
      "description": "Original sculptures and statuary, in stone"
    },
    {
      "code": "97030090",
      "description": "Original sculptures and statuary, in other materials"
    },
    {
      "code": "9704",
      "description": "POSTAGE OR REVENUE STAMPS, STAMP-POST MARKS, FIRST-DAY COVERS, POSTAL STATIONERY (STAMPED PAPER), AND THE LIKE, USED OR UNUSED, OTHER THAN THOSE OF HEADING 4907"
    },
    {
      "code": "970400",
      "description": "Postage or revenue stamps, stamp-post marks, first-day covers, postal stationery (stamped paper), and the like, used or unused, other than those of heading 4907"
    },
    {
      "code": "97040010",
      "description": "Used postal stamp"
    },
    {
      "code": "97040020",
      "description": "Used or unused first-day covers for philatelists"
    },
    {
      "code": "97040090",
      "description": "Other"
    },
    {
      "code": "9705",
      "description": "COLLECTIONS AND COLLECTORS PIECES OF ZOOLOGICAL, BOTANICAL, MINERALOGICAL, ANATOMICAL, HISTORICAL, ARCHAEOLOGICAL, PALAEONTOLOGICAL, ETHNOGRAPHIC OR NUMISMATIC INTEREST"
    },
    {
      "code": "970500",
      "description": "Collections and collectors' pieces of zoological, botanical, mineralogical, anatomical, historical, archaeological, palaeontological, ethnographic or numismatic interest"
    },
    {
      "code": "97050010",
      "description": "Stuffed animals and birds (taxidermy)"
    },
    {
      "code": "97050090",
      "description": "Other"
    },
    {
      "code": "9706",
      "description": "ANTIQUES OF AN AGE EXCEEDING ONE HUNDRED YEARS"
    },
    {
      "code": "97060000",
      "description": "ANTIQUES OF AN AGE EXCEEDING ONE HUNDRED YEAR"
    },
    {
      "code": "98",
      "description": "Project imports; laboratory chemicals; passengers baggage, personal importations by air or post; ship stores"
    },
    {
      "code": "9801",
      "description": "ALL ITEMS OF MACHINERY INCLUDING PRIME MOVERS, INSTRUMENTS, APPARATUS AND APPLIANCES, CONTROL GEAR AND TRANSMISSION EQUIPMENT, AUXILIARY EQUIPMENT (INCLUDING THOSE REQUIRED FOR RESEARCH AND DEVELOPMENT PURPOSES, TESTING AND QUALITY CONTROL), AS WELL AS ALL COMPONENTS (WHETHER FINISHED OR NOT) OR RAW MATERIALS FOR THE MANUFACTURE OF THE AFORESAID ITEMS AND THEIR COMPONENTS, REQUIRED FOR THE INITIAL SETTING UP OF A UNIT, OR THE SUBSTANTIAL EXPANSION OF AN EXISTING UNIT, OF A SPECIFIED: (1) INDUSTRIAL PLANT, (2) IRRIGATION PROJECT, (3) POWER PROJECT, (4) MINING PROJECT, (5) PROJECT FOR THE EXPLORATION FOR OIL OR OTHER MINERALS, AND (6) SUCH OTHER PROJECTS AS THE CENTRAL GOVERNMENT MAY, HAVING REGARD TO THE ECONOMIC DEVELOPMENT OF THE COUNTRY NOTIFY IN THE OFFICIAL GAZETTE IN THIS BEHALF; AND SPARE PARTS, OTHER RAW MATERIALS (INCLUDING SEMI-FINISHED MATERIAL) OR CONSUMABLE STORES NOT EXCEEDING 10% OF THE VALUE OF THE GOODS SPECIFIED ABOVE PROVIDED THAT SUCH SPARE PARTS, RAW MATERIALS OR CONSUMABLE STORES ARE ESSENTIAL FOR THE MAINTENANCE OF THE PLANT OR PROJECT MENTIONED IN (1) TO (6) ABOVE"
    },
    {
      "code": "980100",
      "description": "All items of machinery including prime movers, instruments, apparatus and appliances, control gear and transmission equipment, auxiliary equipment (including those required for research and development purposes, testing and quality control), as well as all components (whether finished or not) or raw materials for the manufacture of the aforesaid items and their components, required for the initial setting up of a unit, or the substantial expansion of an existing unit, of a specified : (1) industrial plant, (2) irrigation project, (3) power project, (4) mining project, (5) project for the exploration for oil or other minerals, and (6) such other projects as the Central Government may, having regard to the economic development of the country notify in the Official Gazette in this behalf; and spare parts, other raw materials (including semi-finished materials of consumable stores) not exceeding 10% of the value of the goods specified above, provided that such spare parts, raw materials or consumable stores are essential for the maintenance of the plant or project mentioned in (1) to (6) above : --- Machinery"
    },
    {
      "code": "98010011",
      "description": "For industrial plant project"
    },
    {
      "code": "98010012",
      "description": "For irrigation plant"
    },
    {
      "code": "98010013",
      "description": "For power project"
    },
    {
      "code": "98010014",
      "description": "For mining project"
    },
    {
      "code": "98010015",
      "description": "Project for exploration of oil or other minerals"
    },
    {
      "code": "98010019",
      "description": "For other projects"
    },
    {
      "code": "98010020",
      "description": "Components (whether or not finished or not) or raw materials for the manufacture of aforesaid items required for the initial setting up of a unit or the substantial expansion of a unit"
    },
    {
      "code": "98010030",
      "description": "Spare parts and other raw materials (including semi-finished materials or consumable stores for the maintenance of plant or project"
    },
    {
      "code": "9802",
      "description": "LABORATORY CHEMICALS"
    },
    {
      "code": "98020000",
      "description": "LABORATORY CHEMICALS"
    },
    {
      "code": "9803",
      "description": "ALL DUTIABLE ARTICLES, IMPORTED BY A PASSENGER OR A MEMBER OF A CREW IN HIS BAGGAGE"
    },
    {
      "code": "98030000",
      "description": "ALL DUTIABLE ARTICLES, IMPORTED BY A PASSENGER OR MEMBER OF A CREW IN HIS BAGGAGE"
    },
    {
      "code": "9804",
      "description": "ALL DUTIABLE ARTICLES, INTENDED FOR PERSONAL USE, IMPORTED BY POST OR AIR"
    },
    {
      "code": "98041000",
      "description": "Drugs and medicines"
    },
    {
      "code": "98049000",
      "description": "Other"
    },
    {
      "code": "9805",
      "description": "THE FOLLOWING ARTICLES OF STORES ON BOARD OF A VESSEL OR AIRCRAFT ON WHICH DUTY IS LEVIABLE UNDER THE CUSTOMS ACT, 1962 (52 OF 1962), NAMELY"
    },
    {
      "code": "98051000",
      "description": "Prepared or preserved meat, fish and vegetables; dairy products; soup; lard; fresh fruits"
    },
    {
      "code": "98059000",
      "description": "All other consumable stores excluding fuel, lubricating oil, alcoholic drinks and tobacco products"
    },
    {
      "code": "98060000",
      "description": "All goods originating in or exported from the Islamic republic of Pakistan"
    }
  ];

  const config = {
      'hsn': HSN,
      'sac': SAC
  }

  export function filterHSNSAC(from, keyword) {
      const filterFrom: [any] = config[from];
      return filterFrom.filter(item => item['description'].toLowerCase().match(keyword))
  }