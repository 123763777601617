import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { ProfileManagementService } from '../../../services/profile-management.service';
import { SubscriptionService } from '../../../subscriptions/services/subscription.service';
import { Observable } from 'rxjs';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-delete-account',
  templateUrl: 'delete-account.component.html',
})
export class DeleteAccountComponent implements OnInit {
  user$: Observable<any>;
  userDetails = null;
  openDeleteAccountModal = false;

  constructor(
    private store: Store<RootReducerState>,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    private profileManagementService: ProfileManagementService,
    private spinner: NgxSpinnerService
  ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  ngOnInit() {}

  deleteAccount(): void {
    console.log(this.userDetails);
    this.spinner.show();
    if (this.userDetails?.subscription?.subId) {
      const body = {
        subId: this.userDetails?.subscription?.subId,
      };
      this.subscriptionService.cancelPlan(body).subscribe(
        (resp) => {
          if (resp?.success) {
            this.closeAccount();
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message || 'Something went wrong');
        }
      );
    } else {
      this.closeAccount();
    }
  }

  closeAccount(): void {
    this.profileManagementService.closeAccount().subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.toastr.success('Account Closed');
          this.openDeleteAccountModal = false;
          // this.userService.logout();
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(error?.error?.message || 'Something went wrong');
      }
    );
  }
}
