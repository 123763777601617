import { Component, Input, OnInit } from '@angular/core';
import { env } from 'process';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-modal-container',
  templateUrl: './modal-container.component.html',
  styleUrls: ['./modal-container.component.scss'],
})
export class ModalContainerComponent implements OnInit {
  constructor(private utility: UtilityService) {}
  @Input() isOpen = false;
  @Input() modalType;
  ngOnInit(): void {}

  clickedOutside(value) {
    console.log('showHideSettingsModal1');
    // if (
    //   !(
    //     value?.target?.id === 'settings' ||
    //     value?.target?.parentElement?.id === 'settings' ||
    //     value?.target?.className?.includes('ngx-spinner-overlay')
    //   )
    // ) {
    //   this.utility.showHideSettingsModal.next({ open: false });
    // }
  }
}
