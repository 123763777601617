import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpertService } from 'src/app/modules/experts/expert.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-application-history',
  templateUrl: './application-history.component.html',
  styleUrls: ['./application-history.component.scss'],
})
export class ApplicationHistoryComponent implements OnInit {
  logo = '/assets/images/expert-logo.png';
  appliedData: any;
  unsubscribe$ = new Subject();
  user$: Observable<any>;
  userId: string;

  constructor(
    private expertService: ExpertService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private toster: ToastrService,
    private store: Store<RootReducerState>
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userId = user._id;
    });
  }

  ngOnInit(): void {
    this.spinner.show();
    this.expertService.getApplicationStatusLambda({id: this.userId}).subscribe(
      (result) => {
        if (result.data) {
          this.appliedData = result.data[0];
          this.spinner.hide();
        }
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }
}
