import { Action, createReducer, on } from '@ngrx/store';
import * as AccountActions from '../actions/account.actions';
import * as UserActions from '../actions/user.actions';

export interface AccountsState {
    accounts: Array<any>;
}

export const initialState: AccountsState = {
    accounts: []
};

export const AccountsReducer = createReducer(initialState,
    on(AccountActions.SetAccounts, (state: AccountsState, {accounts}) => ({...state, accounts})),
    on(AccountActions.AddAccount, (state: AccountsState, {account}) => ({...state, accounts: [...state.accounts, {...account}]})),
    on(AccountActions.updateAccount, (state: AccountsState, {account}) => {
        const mappedAccounts = state.accounts.map((ogAccount) => {
            if (ogAccount._id === account._id) { return {...account}; }
            return ogAccount;
        });
        return { ...state, accounts: [...mappedAccounts] };
    }),
    on(UserActions.UserLogout, (state: AccountsState) => ({...initialState}))
);

export function reducer(state: AccountsState | undefined, action: Action): any {
    return AccountsReducer(state, action);
}

export const getAccounts = (state: AccountsState) => state.accounts;
