import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReportsComponent } from './reports.component';
import { RouterModule, Routes } from '@angular/router';
import { ReportsContainerComponent } from './components/reports-container/reports-container.component';
import { ReportsFilterFormComponent } from './components/reports-filter-form/reports-filter-form.component';
import { ReportsViewComponent } from './components/reports-view/reports-view.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReportsStatsComponent } from './components/reports-stats/reports-stats.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { InventoryModule } from '../inventory/inventory.module';

const routes: Routes = [
  {
    path: '',
    component: ReportsComponent
  },
  {
    path: 'view/:type',
    component: ReportsContainerComponent
  }
];

@NgModule({
  declarations: [
    ReportsComponent,
    ReportsContainerComponent,
    ReportsFilterFormComponent,
    ReportsViewComponent,
    ReportsStatsComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class ReportsModule { }
