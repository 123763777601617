import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ProfileManagementComponent } from './profile-management.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { PersonalInformationComponent } from '../profile-management/components/personal-information/personal-information.component';
import { EmailsComponent } from './components/emails/emails.component';
import { EmailNotificationsComponent } from './components/email-notifications/email-notifications.component';
import { PasswordSettingsComponent } from './components/password-settings/password-settings.component';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from 'ngx-spinner';
import { SettingsSharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { MailingConfigComponent } from './components/mailing-config/mailing-config.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { TwoFactorAuthenticationComponent } from './components/two-factor-authentication/two-factor-authentication.component';

const routes: Routes = [
  {
    path: '',
    component: ProfileManagementComponent
  }
];

@NgModule({
  declarations: [
    ProfileManagementComponent,
    PersonalInformationComponent,
    EmailsComponent,
    EmailNotificationsComponent,
    PasswordSettingsComponent,
    MailingConfigComponent,
    TwoFactorAuthenticationComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SettingsSharedModule,
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    ClickOutsideModule,
    RouterModule.forChild(routes)
  ]
})
export class ProfileManagementModule { }
