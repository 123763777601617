import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-marketplace',
  templateUrl: './marketplace.component.html',
})
export class MarketPlaceComponent implements OnInit, OnDestroy {
  userSubscriptions = [];
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  business$: Observable<any>;
  currentSubscription$: Observable<any>;
  businessId: any;
  isPayrollPurchased = false;

  constructor(
    private store: Store<RootReducerState>,
    public router: Router,
    private utility: UtilityService
  ) {
    this.currentSubscription$ = this.store.pipe(select(selectUserSubscription));
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  ngOnInit() {
    this.currentSubscription$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((userResp) => {
        this.userSubscriptions = userResp?.subscriptions;
        this.isPayrollPurchased = this.userSubscriptions?.find(
          (sub) => sub?.subHost === 'Payroll'
        );
      });
  }

  redirect(platformName: string): void {
    let a = document.createElement('a');
    const token = localStorage.getItem('refresh_token');
    if (platformName === 'payroll') {
      if (this.isPayrollPurchased) {
        a.href = `https://payroll.numetric.work/auth/login?redirect=${token}&db=numetric&businessId=${this.businessId}`;
      } else {
        this.router.navigate(['/payroll']);
        this.utility.showHideSettingsModal.next({ open: false });
      }
    } else a.href = `https://learn.numetric.work/autoLogin/${token}/numetric`;
    a.target = 'blank';
    a.click();
  }
}
