import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import {
  selectUser,
  selectUserBusinesses,
} from 'src/app/store/selectors/user.selectors';
import { ToastrService } from 'ngx-toastr';
import { UserService } from 'src/app/shared/services/user.service';
import { ProfileManagementService } from '../../../services/profile-management.service';
import { SubscriptionService } from '../../../subscriptions/services/subscription.service';

@Component({
  selector: 'app-business-list',
  templateUrl: './business-list.component.html',
})
export class BussinessListComponent implements OnInit, OnDestroy {
  constructor(
    private store: Store<RootReducerState>,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService,
    private userService: UserService,
    private profileManagementService: ProfileManagementService,
    private spinner: NgxSpinnerService
  ) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.user$ = this.store.pipe(select(selectUser));
  }
  tableHeadings = [
    { name: 'Business Name', key: 'companyName' },
    { name: 'Industry', key: 'businessType' },
    { name: 'Country', key: 'country' },
    { name: 'Currency', key: 'currency' },
    { name: 'Organization', key: 'organization' },
  ];

  businesses$: Observable<any>;
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  userId = '';
  businessCount;
  openDeleteAccountModal = false;
  userDetails = null;
  @Output()
  editBusiness = new EventEmitter();
  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userId = user._id;
      this.userDetails = { ...user, DOB: user?.DOB?.split('T')[0] };
    });
    this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        this.businessCount = business;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
