import { getCurrencySymbol } from '@angular/common';
import { AfterViewChecked, AfterViewInit, Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { InvoicesService } from '../../sales/components/invoices/invoices.servcies';
import { OpenService } from '../open.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ToastrService } from 'ngx-toastr';
import * as _html2canvas from "html2canvas";
import jsPDF from 'jspdf';

@Component({
  selector: 'app-invoice',
  templateUrl: './invoice.component.html',
  styleUrls: ['./invoice.component.scss'],
})
export class InvoiceComponent implements OnInit, OnDestroy {
  constructor(
    private invoiceService: InvoicesService,
    private openService: OpenService,
    private route: ActivatedRoute,
    private router: Router,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private businessService: BusinessService,
    private numberService: NumberService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) {}
  invoiceData;
  customizationSettings;
  fieldNames = {
    type: 'Invoice',
    invoiceNumber: 'Invoice Number',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    purchaseOrder: 'PO Number',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Terms & Conditions',
    itemName: 'Item Name',
    itemDescription: 'Item Description',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Bill To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By',
    entityId: 'Entity ID',
    taxId: 'Tax ID',
    refundDate: 'Refund Date',
    amount: 'Refund Amount',
    paymentMethod: 'Payment Metod',
    memo: 'Memo',
  };
  paytabsFormErrors = {
    amount: '',
  };

  paytabsFormErrorMessages = {
    amount: {
      required: 'Amount is required',
      invalid: 'Payment amount cannot be more than due amount',
    },
  };

  number = '1.2-2';
  decimalSize = 2;
  paytabsForm: FormGroup;
  payByPaytabsModal: boolean = false;
  businessData;
  newTemplate = false;

  ngOnInit(): void {
    this.route.params.subscribe(({ businessId, invoiceId }) => {
      if (businessId && invoiceId) {
        this.loadData(businessId, invoiceId);
        this.loadPaytabsForm();
      } else {
        this.router.navigate(['/']);
      }
    });

    window.addEventListener('focus', this.handleFocusEvent.bind(this));
  }


  handleFocusEvent() {
    this.loadData(this.businessData?._id, this.invoiceData?._id);
  }

  loadPaytabsForm(): void {
    this.paytabsForm = this.fb.group({
      amount: [0, Validators.required],
      memo: [''],
      fullAmount: [true],
    });
    this.paytabsForm.valueChanges.subscribe(({ amount, fullAmount }) => {
      if (fullAmount) {
        this.paytabsForm.patchValue(
          {
            amount: this.invoiceData?.dueAmount,
          },
          { emitEvent: false }
        );
        return;
      }

      if (amount > this.invoiceData?.dueAmount) {
        this.paytabsForm
          .get('amount')
          .setErrors({ invalid: true }, { emitEvent: false });
      }
      console.log(this.paytabsForm);

      this.paytabsFormErrors = valueChanges(
        this.paytabsForm,
        this.paytabsFormErrors,
        this.paytabsFormErrorMessages,
        this.translateService
      );
    });
  }

  loadData(businessId, invoiceId): void {
    const apiList = [
      this.invoiceService
        .getInvoice(invoiceId)
        .pipe(catchError((error) => of(null))),
      this.openService
        .getInvoiceCustomizationSettings(businessId)
        .pipe(catchError((error) => of(null))),
    ];

    this.businessService
      .getBusinessDetailsById(businessId)
      .subscribe((resp) => {
        if (resp?.success) {
          this.businessData = resp?.data;
          const businessData = resp.data;
          const decimalSize = businessData?.decimalSize ?? 2;
          this.decimalSize = decimalSize;
          this.numberService.currency.next(businessData.currency);
          this.numberService.decimalSize.next(decimalSize);
          this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
          this.number = `1.${decimalSize}-${decimalSize}`;
        }
      });

    this.spinner.show();
    forkJoin(apiList).subscribe(
      (results) => {
        this.spinner.hide();
        if (results[0].success) {
          this.invoiceData = results[0].data;
          this.paytabsForm
            .get('amount')
            .setValue(this.invoiceData?.dueAmount ?? 0);
          this.businessData = this.invoiceData?.businessDetails?.businessId;
          const businessData = this.invoiceData?.businessDetails?.businessId;
          const decimalSize = businessData?.decimalSize ?? 2;
          this.decimalSize = decimalSize;
          this.numberService.currency.next(businessData.currency);
          this.numberService.decimalSize.next(decimalSize);
          this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
          this.number = `1.${decimalSize}-${decimalSize}`;
        }
        if (results[1].success) {
          this.customizationSettings = results[1].data;

          this.newTemplate = this.customizationSettings?.selectedTemplate === 'templateOption1' ||
          this.customizationSettings?.selectedTemplate === 'templateOption2' ||
          this.customizationSettings?.selectedTemplate === 'jordan' ||
          this.customizationSettings?.selectedTemplate === 'saudi';
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  loadCustomizationSettings(): void {}

  downloadPdf(type: string): void {
    console.log('check1', this.customizationSettings?.selectedTemplate);
    if (
      this.customizationSettings?.selectedTemplate === 'templateOption1' ||
      this.customizationSettings?.selectedTemplate === 'templateOption2' ||
      this.customizationSettings?.selectedTemplate === 'jordan' ||
      this.customizationSettings?.selectedTemplate === 'saudi'
    ) {
      this.spinner.show();
      const html2canvas: any = _html2canvas;
      let mainContent = document.getElementById('template-option-one');
      let footerContent = document.getElementById('pdf-footer');
      let footerContentDisplay = document.getElementById('pdf-footer-display');

      footerContent.style.display = 'none';

      if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
            footerContentDisplay.style.display = 'none';
      }

      html2canvas(mainContent, { scale: 2 }).then((canvas) => {
          const imgData = canvas.toDataURL('image/png');

          
          footerContent.style.padding = this.customizationSettings?.selectedTemplate === 'templateOption1' ? '0rem 5rem 5rem 5rem' : this.customizationSettings?.selectedTemplate === 'templateOption2' ? '0rem' : '0rem 2rem 2.5rem 2rem';

          if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
            footerContentDisplay.style.display = 'block';
            footerContentDisplay.style.padding = '0rem';
          }
          footerContent.style.display = 'block';

          html2canvas(footerContent, { scale: 2 }).then((footerCanvas) => {
              const footerImgData = footerCanvas.toDataURL('image/png');

              const pdf = new jsPDF({
                  orientation: 'portrait',
                  unit: 'mm',
                  format: 'a3',
              });

              const pdfWidth = 297;
              const pdfHeight = 420;
              const imgProps = pdf.getImageProperties(imgData);
              const imgWidth = pdfWidth;
              const imgHeight = (imgProps.height * imgWidth) / imgProps.width;

              const footerProps = pdf.getImageProperties(footerImgData);
              const footerWidth = pdfWidth;
              const footerHeight = (footerProps.height * footerWidth) / footerProps.width;

              const pageHeight = pdfHeight - footerHeight;
              const pageCount = Math.ceil(imgHeight / pageHeight);

              for (let i = 0; i < pageCount; i++) {
                  if (i > 0) {
                      pdf.addPage();
                  }
                  const yOffset = -i * pageHeight;
                  pdf.addImage(
                      imgData,
                      'PNG',
                      0,
                      yOffset,
                      imgWidth,
                      imgHeight,
                      '',
                      'FAST'
                  );

                  // Add footer to each page
                  pdf.addImage(
                      footerImgData,
                      'PNG',
                      0,
                      pdfHeight - footerHeight,
                      footerWidth,
                      footerHeight,
                      '',
                      'FAST'
                  );
              }

              pdf.save(this.invoiceData?.invoiceNumber + '.pdf');
              footerContent.style.padding = '';
              if(this.customizationSettings?.selectedTemplate === 'templateOption2'){
                footerContent.style.display = 'none';
              }
              this.spinner.hide();
          });
      });

    } else {
      const body = {
        config: {
          data: {
            ...this.invoiceData,
            items: this.invoiceData.items.map((item) => {
              return {
                ...item,
                price: this.numberService.currencier(item.price),
                totalPrice: this.numberService.currencier(
                  item.price * item.unit
                ),
              };
            }),
            refundAmount:
              this.invoiceData?.refundHistory.length > 0
                ? this.numberService.currencier(
                    this.invoiceData?.refundHistory
                      .map((i) => i.amount)
                      .reduce((a, b) => a + b)
                  )
                : 0.0,
            dueDate: moment(this.invoiceData.dueDate).format('DD-MM-YYYY'),
            invoiceDate: moment(this.invoiceData.invoiceDate).format(
              'DD-MM-YYYY'
            ),
            subtotal: this.numberService.currencier(this.invoiceData.subtotal),
            discount: this.invoiceData.discount
              ? this.numberService.currencier(this.invoiceData.discount)
              : 0,
            discountValue: this.numberService.currencier(
              this.invoiceData.discountValue
            ),
            discountGiven: this.numberService.currencier(
              this.invoiceData.subtotal - this.invoiceData.discountValue
            ),
            tax: this.numberService.currencier(this.invoiceData.tax),
            totalAmount: this.numberService.currencier(
              this.invoiceData.totalAmount
            ),
            currencyDetails: {
              currency: this.invoiceData.currencyDetails?.currencySymbol,
              currencySymbol: getCurrencySymbol(
                this.invoiceData.currencyDetails?.currencySymbol,
                'narrow'
              ),
            },
            companylogo: this.customizationSettings?.companyLogo,
          },
          type: type,
          direction:
            localStorage.getItem('NuMetric|lang') === 'ar' ? 'rtl' : 'ltr',
          showTax: false,
          decimalSize: this.decimalSize,
          signatureDate: moment(this.invoiceData.updatedAt).format('LLL'),
          fieldNames: this.languageService.translate(this.fieldNames),
        },
      };
      this.spinner.show();
      this.invoiceService.createInvoicePdf(body).subscribe(
        (resp) => {
          const a = document.createElement('a');
          const blob = new Blob([resp], { type: 'application/pdf' });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = `Invoice ${this.invoiceData.invoiceNumber}.pdf`;
          a.click();
          window.URL.revokeObjectURL(url);
          this.spinner.hide();
        },
        (error) => {
          this.spinner.hide();
        }
      );
    }
  }

  generatePaymentLink(): void {
    const data = {
      ...this.paytabsForm.value,
      merchantName: 'PayTabs',
      invoiceId: this.invoiceData?._id,
    };
    this.spinner.show();
    this.invoiceService.generatePaymentLink(data).subscribe(
      (resp) => {
        if (resp?.success) {
          this.payByPaytabsModal = false;
          window.open(resp?.data, '_blank', 'popup');
        } else {
          this.toastr.error(resp?.message ?? 'Something went wrong!');
        }
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      }
    );
  }

  ngOnDestroy(): void {
    window.removeEventListener('focus', this.handleFocusEvent.bind(this));
  }

  paymentClick(event): void {
    console.log("paymentClick2", event);
    this.payByPaytabsModal = event;
    
  }
}
