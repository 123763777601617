import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-pay-button',
  templateUrl: 'pay-button.component.html',
})
export class PayButtonComponent implements OnInit {
  @Output() buttonClick = new EventEmitter();
  @Input() classNames = ''
  constructor() {}

  ngOnInit() {}
}
