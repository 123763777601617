import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'customer-statement',
  templateUrl: './customer-statement.component.html',
  styleUrls: ['./customer-statement.component.scss']
})
export class CustomerStatementComponent implements OnInit {

  constructor() { }

  @Input() config = {
    businessInfo: {
      businessName: '',
      businessAddress: {
        addressLineFirst: null,
        addressLineSecond: null,
        city: null,
        state: null,
        country: null
      },
      businessLogo: ''
    },
    customerInfo: {
      customerName: '',
      customerFirstName: '',
      customerLastName: ''
    },
    tableHeadings: [],
    tableData: [],
    tableKeys: [],
    outstandingBalance: 0,
    yetToBePaid: 0,
    overdueAmount: 0,
    fromDate: '',
    endDate: '',
    type: '',
    quickStats: [],
    userId: {
      firstName: '',
      lastName: ''
    }
  }

  ngOnInit(): void {
  }

}
