import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { SetBusiness } from 'src/app/store/actions/business.actions';
import { RootReducerState } from 'src/app/store/reducers';

@Component({
  selector: 'app-select-business',
  templateUrl: './select-business.component.html',
  styleUrls: ['./select-business.component.scss']
})
export class SelectBusinessComponent implements OnInit {

  businesses= [];
  unsubscribe$ = new Subject()

  constructor(
      private userService: UserService,
      private spinner: NgxSpinnerService,
      private toaster: ToastrService,
      private translate: TranslateService,
      private router: Router,
      private store: Store<RootReducerState>
      ) { }

  ngOnInit(): void {
    console.log('select business');
    
    if(localStorage.getItem('selectedBusiness')){
      let toNavigate = localStorage.getItem('currentLocation') || '/dashboard';
      let queryParams = null;
      if (toNavigate.includes('?')) {
        if (toNavigate?.split('?')[1]?.split('=').length > 1) {
          const key = toNavigate?.split('?')[1]?.split('=')[0]
          const value = toNavigate?.split('?')[1]?.split('=')[1]
          queryParams = { [key]: value };
        }
        toNavigate = toNavigate?.split('?')[0];
      }
      this.router.navigate([toNavigate], { queryParams });
      return;
    }
    this.getBusinesses();
  }

  getBusinesses() { 
    this.spinner.show();
    this.userService.getUserDetails()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.spinner.hide();
      if(resp?.success){
        this.businesses = resp?.data?.businesses;
        console.log(this.businesses);
      }
    }, (err) => {
      this.spinner.hide();
      this.toaster.error(this.translate.instant('Something went wrong!'));
    })
  }

  selectBusiness(business): void {
    const businessToSet = this.businesses?.find(bus => {
      return bus?.businessId?._id === business?._id
    })    
    localStorage.setItem('selectedBusiness', business?._id);
    this.store.dispatch(SetBusiness(businessToSet));
    this.router.navigate(['/dashboard']);
  }

  trackByFn(index: number): number {
    return index;
  }

}
