import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { InvoiceCustomizationService } from '../../services/invoice-customization.service';
import { Observable, Subject } from 'rxjs';

@Component({
  selector: 'app-invoice-customization',
  templateUrl: 'invoice-customization.component.html',
})
export class InvoiceCustomization implements OnInit, OnDestroy {
  openModal: boolean;
  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload',
    },
  };
  openDeleteModal: boolean;
  response: string;
  error: any;
  constructor(
    private invoiceCustomizationService: InvoiceCustomizationService,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private fb: FormBuilder
  ) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }
  business$: Observable<any>;
  unsubscribe$ = new Subject();
  businessId = null;
  settings = null;
  invoiceCustomizationForm = new FormGroup({});
  selectedTemplate = 'classic';

  fileEvent(event): void {
    this.openModal = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 16 / 9,
        title: 'Upload',
      },
    };
  }

  discard() {
    this.invoiceCustomizationService.setInvoiceCustomizationSettings(
      this.settings
    );
  }

  loadForm(settings?): void {
    const estimateSettings = settings?.estimateSettings ?? {};
    const invoiceSettings = settings?.invoiceSettings ?? {};
    this.invoiceCustomizationForm = this.fb.group({
      estimateSettings: this.fb.group({
        title: [estimateSettings?.title ? estimateSettings.title : ''],
        footer: [estimateSettings?.footer ? estimateSettings.footer : ''],
        memo: [estimateSettings?.memo ? estimateSettings.memo : ''],
      }),
      invoiceSettings: this.fb.group({
        title: [invoiceSettings?.title ? invoiceSettings.title : ''],
        footer: [invoiceSettings?.footer ? invoiceSettings.footer : ''],
        terms: [invoiceSettings?.terms ? invoiceSettings.terms : ''],
      }),
      sendSMS: new FormControl(settings?.sendSMS ?? false),
      reminder: new FormControl(settings?.reminder ?? false),
      selectedTemplate: new FormControl(
        settings?.selectedTemplate ?? 'classic'
      ),
      item: new FormControl(settings?.item ? settings.item : 'Items'),
      price: new FormControl(settings?.price ? settings.price : 'Price'),
      units: new FormControl(settings?.units ? settings.units : 'Quantity'),
      amount: new FormControl(settings?.amount ? settings.amount : 'Amount'),
    });
  }

  companyLogo = 'assets/images/business.jpg';
  ngOnInit() {
    this.loadForm();
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.invoiceCustomizationService
          .getInvoiceCustomizationSettings(business.businessId._id)
          .subscribe(
            (resp) => {
              this.spinner.hide();
              this.selectedTemplate = resp?.data?.selectedTemplate;
              this.invoiceCustomizationService.setInvoiceCustomizationSettings(
                resp.data,
                business.businessId._id
              );
            },
            (error) => {
              this.spinner.hide();
            }
          );
      }
    });
    this.invoiceCustomizationService.invoiceCustomizationSettings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings) => {
        this.settings = settings;
        if (settings?.companyLogo) this.companyLogo = settings.companyLogo;
        this.loadForm(settings);
      });
  }

  saveImage(image): void {
    this.openModal = false;
    this.spinner.show();
    this.invoiceCustomizationService
      .saveInvoiceCustomizationSettings({ companyLogo: image })
      .subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.companyLogo = image;
            this.invoiceCustomizationService.setInvoiceCustomizationSettings({
              ...this.settings,
              companyLogo: this.companyLogo,
            });
            const fileInput = document.getElementById(
              'file'
            ) as HTMLInputElement;
            fileInput.value = null;
          }
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }

  removeLogo(): void {
    this.spinner.show();
    this.openDeleteModal = true;
    this.invoiceCustomizationService
      .saveInvoiceCustomizationSettings({ companyLogo: '' })
      .subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.companyLogo = 'assets/images/business.jpg';
            this.invoiceCustomizationService.setInvoiceCustomizationSettings({
              ...this.settings,
              companyLogo: this.companyLogo,
            });
            const fileInput = document.getElementById(
              'file'
            ) as HTMLInputElement;
            fileInput.value = null;
          }
          this.openDeleteModal = false;
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  templateChange(event) {
    this.invoiceCustomizationForm.get('selectedTemplate')?.setValue(event);
  }

  saveChanges(): void {
    this.spinner.show();
    this.invoiceCustomizationService
      .saveInvoiceCustomizationSettings(this.invoiceCustomizationForm.value)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          this.response = 'Changes Saved';
          setTimeout(() => (this.response = ''), 2000);
          console.log(resp);
        },
        (error) => {
          this.spinner.hide();
          this.error = error?.error?.message || 'Internal server error';
        }
      );
    // console.log(this.invoiceCustomizationForm.value);
  }
}
