import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import SlimSelect from 'slim-select';
import * as cc from 'currency-codes';
import { select, Store } from '@ngrx/store';
import { getAllMonths, getDates } from 'src/app/shared/utils/monthDateData';
import { RootReducerState } from 'src/app/store/reducers';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { ActivatedRoute, Router } from '@angular/router';
import { BusinessService } from 'src/app/shared/services/business.service';
import { takeUntil } from 'rxjs/operators';



@Component({
  selector: 'app-date-and-currency',
  templateUrl: './date-and-currency.component.html',
  styleUrls: ['./date-and-currency.component.scss']
})
export class DateAndCurrencyComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private businessService: BusinessService,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) {
   this.businesses$ = this.store.pipe(select(selectBusiness));
}
  fiscalform: FormGroup;
  error = '';
  response = '';
  currencySelect;
  dateSelect;
  monthSelect;
  months;
  dates;
  businesses$: Observable<any>;
  unsubscribe$ = new Subject();
  businessId = null;
  update = false;
  loginRespMessage = '';

  formErrors = {
    month: '',
    date: '',
  };

  formErrorMessages = {
    month: {
      required: 'Month is required'
    },
    date: {
      required: 'Date is required'
    }
  };

  ngOnInit(): void {
    this.loadForm();
    this.loadMonth();
    this.businesses$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business.businessId._id,
        this.currencySelect = business?.businessId?.currencySymbol;
        console.log(business?.businessId?.currencySymbol);
        
        this.loadForm(business.businessId);
      }
    });
  }

  loadForm(business?): void {
    this.fiscalform = this.fb.group({
      month: [business?.accounting?.month ? business.accounting?.month : '', [Validators.required]],
      date: [business?.accounting?.date ? business.accounting?.date : '', [Validators.required]],
      // currency: [business?.currency ? business.currency : '', [Validators.required]]
    });
    this.setSelectData(this.fiscalform.value);
    this.fiscalform.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(this.fiscalform, {...this.formErrors}, this.formErrorMessages);
    });
    this.formErrors = valueChanges(this.fiscalform, {...this.formErrors}, this.formErrorMessages);
  }


  loadMonth(): void {
    const months = getAllMonths();
    this.months = getAllMonths();
  }

  chnageMonthEvent(event): void{
    const {text} = event;
    const result = getDates(text);
    this.dates = result;
  }

  setSelectData(data): void {
    if (data.month) {
      const monthData = getAllMonths().map(month => {
        if (data.month === month.text) {
          return { ...month, selected: true };
        }
        return month;
      });
      this.monthSelect = [...monthData];
    }
    if (data.date) {
      const dateData = getDates(data.month).map(date => {
        if (data.date === date.text) {
          return { ...date, selected: true };
        }
        return date;
      });
    }
  }

  addFiscal(): void{
    this.error = '';
    this.response = '';
    if (this.fiscalform.invalid) {
      this.fiscalform.markAllAsTouched();
      this.formErrors = valueChanges(this.fiscalform, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    console.log('huss', this.fiscalform.value);
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      accounting:
       {
        month: this.fiscalform.value.month,
        date: this.fiscalform.value.date
      },
      ...this.fiscalform.value
    };
    this.businessService.updateBusiness(body).subscribe((updatedBusiness) => {
      this.spinner.hide();
      this.businessService.updateBusinessInStore(updatedBusiness.data);
      this.response = 'Date and Month update sucessful';
      setTimeout(() => { this.response = ''; }, 2000);
      this.fiscalform.reset();
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
