import { Component, OnInit } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-settings-container',
  templateUrl: './settings-container.component.html',
})
export class SettingsContainerComponent implements OnInit {

  constructor(private store: Store<RootReducerState>,
              private userService: UserService,
              private router: Router,
              private spinner: NgxSpinnerService) {
    this.user$ = this.store.pipe(select(selectUser));
   }

  user$: Observable<any>;

  ngOnInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.spinner.show();
      }
      if (event instanceof NavigationEnd) {
        this.spinner.hide();
      }
    });
  }

}
