<div class="flex justify-center px-14 py-11 bg-white rounded-lg">
  <div class="w-full flex flex-col space-y-4">
    <div class="ms-8">
      <div [formGroup]="form" class="flex">
        <div class="w-1/5 me-4 flex flex-col gap-[10px]">
          <label class="block text-base Poppins-Medium mb-1">{{
            "Customer" | translate
          }}</label>
          <ng-select
            class="new-dropdown"
            [items]="customers"
            bindLabel="display"
            formControlName="customer"
            placeholder="{{ 'Type Customer Number' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.display }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="w-1/5 me-4 flex flex-col gap-[10px]">
          <label class="block text-base Poppins-Medium mb-1">{{
            "Type" | translate
          }}</label>
          <ng-select
            class="new-dropdown"
            [items]="['Outstanding Invoices', 'Account Activity']"
            [clearable]="false"
            [searchable]="false"
            formControlName="type"
            placeholder="{{ 'Select Type' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div
          [ngClass]="
            form.get('type').value === 'Account Activity' ? '' : 'hidden'
          "
          class="w-1/5 me-4 flex flex-col gap-[10px]"
        >
          <label for="" class="block Poppins-Medium text-base">{{
            "From Date" | translate
          }}</label>
          <input type="date" class="new-date mt-1" formControlName="fromDate" />
        </div>
        <div
          [ngClass]="
            form.get('type').value === 'Account Activity' ? '' : 'hidden'
          "
          class="w-1/5 me-4 flex flex-col gap-[10px]"
        >
          <label for="" class="block Poppins-Medium text-base">{{
            "End Date" | translate
          }}</label>
          <input type="date" class="new-date mt-1" formControlName="endDate" />
        </div>
        <div class="w-1/5 flex items-end pb-[0.2rem]">
          <app-button
            (handleClick)="createStatement()"
            type="primary"
            size="large"
            buttonText="{{ 'Create Statement' | translate }}"
          ></app-button>
        </div>
      </div>
      <div class="flex space-x-3 pt-8">
        <div class="w-1/5">
          <div *ngIf="formErrors.customer" class="text-red-500 text-sm">
            {{ formErrors.customer }}
          </div>
        </div>
        <div class="w-1/5"></div>
        <div class="w-1/5">
          <div *ngIf="formErrors.fromDate" class="text-red-500 text-sm">
            {{ formErrors.fromDate }}
          </div>
        </div>
        <div class="w-1/5">
          <div *ngIf="formErrors.endDate" class="text-red-500 text-sm">
            {{ formErrors.endDate }}
          </div>
        </div>
        </div>
        <div *ngIf='!showStatement'>
            <!-- <img src="assets/images/images.png" class="block m-auto w-80 " />  -->
            <p class="text-center Poppins-Bold text-black text-lg">{{'Keep customers informed' | translate}}</p>
            <p class="text-center m-auto w-1/2">{{'Statement Info' | translate}}</p>
        </div>
        <div class="flex justify-end gap-x-2 my-4" *ngIf='showStatement'>
            <div class="border rounded-xl flex items-center ps-2 me-2">
                <a [href]='shareURL' target="_blank" class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis">
                  {{shareURL}}
                </a>
                <app-button type='primary' [buttonText]='copyButtonText | translate' (handleClick)='copyLink()'></app-button>
            </div>
            <app-button buttonText="{{'Download PDF' | translate}}" type='primary' (handleClick)='downloadPdf()'></app-button>
            <app-button buttonText='{{"Send Statement" | translate}}' type='primary' (handleClick)='sendStatementModalOpen = true'></app-button>
        </div>
        <customer-statement *ngIf='showStatement' [config]='config'></customer-statement>
    </div>
</div>

<app-modal-container [isOpen]="sendStatementModalOpen">
  <div header>{{ "Email Customer Statement" | translate }}</div>
  <div content>
    <form [formGroup]="emailForm">
      <div class="flex flex-col">
        <label for="" class="block Poppins-Bold mb-1">{{
          "Email" | translate
        }}</label>
        <input formControlName="email" type="email" class="new-input" />
        <span *ngIf="emailFormErrors.email">{{ emailFormErrors.email }}</span>
      </div>
    </form>
  </div>
  <div footer class="flex gap-2">
    <app-button
      (handleClick)="sendStatementEmail()"
      type="primary"
      buttonText="{{ 'Send' | translate }}"
      class="me-2"
    ></app-button>
    <app-button
      (handleClick)="sendStatementModalOpen = false"
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
    ></app-button>
  </div>
</app-modal-container>
