import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BusinessService } from 'src/app/shared/services/business.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';

@Component({
  selector: 'app-online-payment',
  templateUrl: './online-payment.component.html',
  styleUrls: ['./online-payment.component.scss']
})
export class OnlinePaymentComponent implements OnInit {

  profileIdConfiguration = `1. Login to PayTabs: Go to the PayTabs platform and enter your login credentials.
  2. Navigate to Profile: Once logged in, locate the "Profile" option in the left sidebar menu. Click on it to access your profile settings.
  3. Find Profile ID: On the first line of your profile information, you will find your unique Profile ID.
  It is typically displayed prominently at the top of the page.
  `;
  
  authorizationToken = `1. Log In to PayTabs: Begin by logging into your PayTabs account using your credentials.
  2. Navigate to Developers: Look for the "Developers" option in the left sidebar menu. Click on it to access the developer tools.
  3. Access API Keys: Inside the Developer section, find and click on "Key Management." This is where you manage your API keys.
  4. Retrieve Test API Key: Within Key Management, locate the "Test API Key." The associated server key is already displayed in the "Server Key" column. 
  This is your authorization token`

  business$: Observable<any>;
  businessData;
  onlinePaymentForm: FormGroup;
  countries = [
    {
      name: 'Global',
    },
    {
      name: 'Saudi Arabia',
    },
    {
      name: 'Oman',
    },
    {
      name: 'United Arab Emirates',
    },
    {
      name: 'Jordan'
    }
  ];

  constructor(private fb: FormBuilder, private spinner: NgxSpinnerService, private toastr: ToastrService, private businessService: BusinessService, private store: Store<RootReducerState>) {
    this.business$ = store.pipe(select(selectBusiness))
    this.loadForm();
   }

  ngOnInit(): void {
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$.subscribe(business => {
      this.businessData = business?.businessId;
      this.onlinePaymentForm.setValue({
        paytabsProfileId: this.businessData?.paytabsProfileId ?? '',
        paytabsAuthorizationToken: this.businessData?.paytabsAuthorizationToken ?? '',
        paytabsCountry: this.businessData?.paytabsCountry ?? null
      })
    })
  }
  
  loadForm(): void {
    this.onlinePaymentForm = this.fb.group({
      paytabsProfileId: [''],
      paytabsAuthorizationToken: [''],
      paytabsCountry: [this.businessData?.paytabsCountry ?? null, [Validators.required]]
    })
  }

  saveDetails(): void {
    this.onlinePaymentForm.markAllAsTouched();
    if(this.onlinePaymentForm.invalid){
      return;
    }

    const data = {
      ...this.businessData,
      ...this.onlinePaymentForm.value,
      businessId: this.businessData?._id
    }
    const formData = new FormData();
    formData.append('payload', JSON.stringify(data))
    this.spinner.show();
    this.businessService.updateBusiness(formData)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }



}
