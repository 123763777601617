import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
} from '@angular/core';
import moment = require('moment');
import { DATE_FORMAT } from 'src/app/modules/time-sheet/constant';
import { WeekDayDto, WeekDto } from 'src/app/modules/time-sheet/time-sheet.dto';

@Component({
  selector: 'app-week-paginator',
  templateUrl: './week-paginator.component.html',
  styleUrls: ['./week-paginator.component.scss'],
})
export class WeekPaginatorComponent implements OnInit, OnChanges {
  constructor() {}
  @Input() inputDate: string;
  @Input() week: WeekDto;
  @Output() handlePagination = new EventEmitter<string>();
  @Output() handleActiveDate = new EventEmitter<string>();
  weekDays: WeekDayDto[] = [];
  next: string;
  previous: string;

  ngOnInit(): void {
    this.week = this.generateTimeSheet();
    this.getData();
  }

  handleActive(date: string): void {
    // const dateString = moment(date).format(DATE_FORMAT)
    this.handleActiveDate.emit(date);
  }

  ngOnChanges(): void {
    this.getData();
  }

  paginatorClick(date: string) {
    this.handlePagination.emit(date);
  }

  getData(): void {
    this.weekDays = this.week?.days;
    this.weekDays?.forEach((day) =>
      day.timeSheetDate === this.inputDate
        ? (day.active = true)
        : (day.active = false)
    );
    this.next = this.week?.next;
    this.previous = this.week?.previous;
  }

  generateTimeSheet(): WeekDto {
    const days: WeekDayDto[] = [];

    const startOfWeek = moment().startOf('isoWeek');

    for (let i = 0; i < 7; i++) {
      const date = startOfWeek.clone().add(i, 'days');

      const dayRecord: WeekDayDto = {
        day: date.format('ddd DD'),
        timeSheetDate: date.format(DATE_FORMAT),
        dailyTotalWorked: 0,
      };

      days.push(dayRecord);
    }

    const next = startOfWeek.clone().add(7, 'days').format(DATE_FORMAT);
    const previous = startOfWeek
      .clone()
      .subtract(1, 'days')
      .format(DATE_FORMAT);

    console.log(
      `🚀 ~ WeekPaginatorComponent ~ generateTimeSheet ~ {
      days,
      next,
      previous,
      weeklyTotalWorked: 0,
    }:`,
      {
        days,
        next,
        previous,
        weeklyTotalWorked: 0,
      }
    );
    return {
      days,
      next,
      previous,
      weeklyTotalWorked: 0,
    };
  }
}
