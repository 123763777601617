import { Subject } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-column-settings',
  templateUrl: './invoice-column-settings.component.html',
})
export class InvoiceColumnSettingsComponent {
  constructor() {}
  unsubscribed = new Subject();
  @Input()
  columnSettingsForm: FormGroup;
  columns = [
    {
      name: 'Items',
      options: ['Items', 'Products', 'Services'],
      key: 'item',
    },
    {
      name: 'Units',
      options: ['Quantity', 'Hours'],
      key: 'units',
    },
    {
      name: 'Price',
      options: ['Price', 'Rate'],
      key: 'price',
    },
  ];
}
