import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { NumplusService } from '../../numplus.service';

@Component({
  selector: 'app-cashflowdashboard',
  templateUrl: './cashflowdashboard.component.html',
  styleUrls: ['./cashflowdashboard.component.scss']
})
export class CashflowdashboardComponent implements OnInit {

  public chartColors: any[] = [];

  public years: Array<any>= [
    {
      name:moment().format('YYYY'),
      value: moment().format('YYYY')
    },
    {
      name:(Number(moment().format('YYYY')) + 1).toString(),
      value:(Number(moment().format('YYYY')) +1).toString()
    },
    {
      name:(Number(moment().format('YYYY')) + 2).toString(),
      value:(Number(moment().format('YYYY')) +2).toString()
    }
  ]

  public monthsName:Array<any> = [
    {
      name:'January',
      value:'January'
    },
    {
      name:'February',
      value:'February'
    },
    {
      name:'March',
      value:'March'
    },
    {
      name:'April',
      value:'April'
    },
    {
      name:'May',
      value:'May'
    },
    {
      name:'June',
      value:'June'
    },
    {
      name:'July',
      value:'July'
    },
    {
      name:'August',
      value:'August'
    },
    {
      name:'September',
      value:'September'
    }, {
      name:'October',
      value:'October'
    }, {
      name:'November',
      value:'November'
    }, {
      name:'December',
      value:'December'
    }]

    public monthsData = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
    'September', 'October', 'November', 'December']

    public netOperatingCashFlowOptions: ChartOptions = {
      responsive: true,
      scales:{
        yAxes:[{
          gridLines:{
            display:false
          },
          ticks:{
            fontFamily:'Poppins',
            beginAtZero : true,
          },
          stacked:false,
          display:true
        }],
        xAxes:[{
          gridLines:{
            display:false
          },
          ticks:{
            fontFamily:'Poppins'
          },
          stacked:false,
          display:true
        }]
      },
      plugins:{
        datalabels:{
          color:'#333',
          anchor: 'start',
          align:'top',
          rotation:270,
          font: {
            weight: 'bold'
          }

        }
      }
    };

    business$: Observable<any>;
    businessId = null;
    unsubscribe$ = new Subject();
    unsubscribe = new Subject();
    fiscalMonth;
    public selectedMonth;
    public selectedYear;
    flowData;
    labels;
    netCashFlowData;
    accounts$:Observable<any>;


  public netOperatingCashFlowLabels: Label[] = [];
  public netOperatingCashFlowType: ChartType = 'bar';
  public netOperatingCashFlowLegend = true;
  public netOperatingCashFlowPlugins = [
    DataLabelsPlugin
  ];

  public netOperatingCashFlowData: Array<any> = [
      {
        data:[],
        label:this.translateService.instant('Net Operation Cash Flow'),
        type:'bar',
        backgroundColor:[],
        borderColor:[]
      },
    ];

  constructor(
    private store: Store<RootReducerState>,
    private rootStore:Store<RootReducerState>,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private numplusService: NumplusService,
    private toastr: ToastrService) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
    }

  ngOnInit(): void {
    // this.selectedYear = moment().format('YYYY');
    // this.selectedMonth = moment().format('MMMM');

    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
        this.years= this.years.map(item => item.name +"(" + this.fiscalMonth.slice(0,3) +" " + item.name + "-" + this.createMonthsAccToFiscalMonth(this.monthsData,this.fiscalMonth, item.name) +")" )
      }
    })
    this.selectedYear = moment().format('YYYY') +"(" + this.fiscalMonth.slice(0,3) +" " + moment().format('YYYY') + "-" + this.createMonthsAccToFiscalMonth(this.monthsData,this.fiscalMonth,  moment().format('YYYY')) +")"

    this.dataChange()
  }

  dataChange(): void {
    this.netOperatingCashFlowLabels = []
    this.netOperatingCashFlowData[0].data =[]
    const filterData = {
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear.split('(')[0]
    }
    this.getCashFlowData(filterData);
  }

  createMonthsAccToFiscalMonth(monthsData:any, fiscalMonth:string, year:any){
    const index = monthsData.findIndex((month) => month === fiscalMonth)
    const firstHalf = monthsData.slice().splice(0,index)
    console.log("First Half", firstHalf)
    const secondHalf = monthsData.slice().splice(index,monthsData.length)
    let newMOnths = [...secondHalf,...firstHalf]
    if(firstHalf.length > 0 ){
      let newYear = Number(year) + 1
      return newMOnths[11].slice(0,3) +" " + newYear
    }
    return newMOnths[11].slice(0,3) +" " + year
  }

  getCashFlowData(filterData){
    this.numplusService.businessId$.subscribe((id) =>{
      console.log("Business Id", id)
      if(id){
        this.spinner.show();
        this.numplusService.getCashFlow(filterData).subscribe(resp =>{
          this.flowData = resp.data.statisticArray
          this.flowData.forEach(data =>{
            this.netOperatingCashFlowLabels.push(data.month + "-" + data.year)
            let data2 = data.netCashFlow
            let colorCode = data2  < 0 ? 'rgb(206, 19, 19)' : 'rgb(12, 156, 213)';
            this.netOperatingCashFlowData[0].backgroundColor.push(colorCode);
            this.netOperatingCashFlowData[0].data.push(data2)

          })

          this.spinner.hide();
        }, (error)=>{
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        })
      }
    })

  }

  ngOnDestroy():void{
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
