<div class="flex gap-4">
  <div class="space-y-4 w-1/2">
    <div *ngIf="!update; else elseUpdate">
      <div class="Poppins-Medium text-lg">
        {{ "Invite new user to" | translate }} {{ companyName }}
      </div>
      <p class="text-sm">
        {{ "Send email invitation to invite user" | translate }}
      </p>
    </div>
    <ng-template #elseUpdate>
      <div class="Poppins-Medium text-lg">
        {{ "Edit invited user permissions" | translate }}
      </div>
    </ng-template>
    <form [formGroup]="inviteForm" class="flex flex-col gap-4">
      <div class="new-design-round-border p-4 text-sm gap-2 flex flex-col">
        <div class="flex w-full items-center justify-between gap-3">
          <label for="firstName" class="text-sm">{{
            "First Name" | translate
          }}</label>
          <fieldset [disabled]="update">
            <input
              id="firstName"
              formControlName="firstName"
              type="text"
              class="new-input"
              placeholder="{{ 'Enter first name' | translate }}"
            />
          </fieldset>
        </div>
        <div *ngIf="formErrors.firstName" class="text-sm text-red-400 ms-2">
          {{ formErrors.firstName }}
        </div>

        <div class="flex w-full items-center justify-between gap-3">
          <label for="lastName" class="text-sm">{{
            "Last Name" | translate
          }}</label>
          <fieldset [disabled]="update">
            <input
              id="lastName"
              formControlName="lastName"
              type="text"
              class="new-input"
              placeholder="{{ 'Enter last name' | translate }}"
            />
          </fieldset>
        </div>
        <div *ngIf="formErrors.lastName" class="text-sm text-red-400 ms-2">
          {{ formErrors.lastName }}
        </div>

        <div class="flex w-full items-center justify-between gap-3">
          <label for="email" class="text-sm">{{
            "Email Address" | translate
          }}</label>
          <fieldset [disabled]="update">
            <input
              id="email"
              formControlName="email"
              type="text"
              class="new-input"
              placeholder="{{ 'Enter email' | translate }}"
            />
          </fieldset>
        </div>
        <div *ngIf="formErrors.email" class="text-sm text-red-400 ms-2">
          {{ formErrors.email }}
        </div>
      </div>

      <div class="space-y-1 new-design-round-border p-4 text-sm flex flex-col">
        <div class="text-lg Poppins-Medium">{{ "Position" | translate }}</div>
        <div>
          <label class="text-sm inline-flex items-center gap-x-2">
            <input
              type="radio"
              formControlName="position"
              value="admin"
              name="position"
            />
            <span>{{ "Admin" | translate }}</span>
          </label>
        </div>
        <div>
          <label class="text-sm inline-flex items-center">
            <input
              type="radio"
              value="employee"
              formControlName="position"
              name="position"
            />
            <span class="ms-2">{{ "Employee" | translate }}</span>
          </label>
        </div>
        <div>
          <label class="text-sm inline-flex items-center">
            <input
              type="radio"
              value="custom"
              formControlName="position"
              name="position"
            />
            <span class="ms-2">{{ "3rd Party" | translate }}</span>
          </label>
        </div>
      </div>
      <div class="flex items-center gap-x-2">
        <app-submit
          className="bg-[#026AA2] text-white h-10"
          [value]="(update ? 'Update' : 'Invite user') | translate"
          (clicked)="update ? updateUser() : inviteUser()"
        >
        </app-submit>
        <span *ngIf="error" class="text-red-500">{{ error }}</span>
        <span *ngIf="response" class="text-green-500">{{ response }}</span>
      </div>
    </form>
  </div>
  <div class="space-y-4 flex justify-end">
    <div [formGroup]="permissionsForm" class="flex flex-col gap-4">
      <div>
        <div class="text-lg">
          {{
            inviteForm.controls.position.value == "custom"
              ? "3rd Party"
              : (inviteForm.controls.position.value | titlecase)
          }}
          {{ "permissions" | translate }}
        </div>
        <p
          *ngIf="inviteForm.controls.position.value === 'owner'"
          class="text-sm mt-2"
        >
          {{ "Best for anyone who needs full access" | translate }}
        </p>
        <p
          *ngIf="inviteForm.controls.position.value === 'admin'"
          class="text-sm mt-2"
        >
          {{ "Best for anyone who needs full access" | translate }}
        </p>
        <p
          *ngIf="inviteForm.controls.position.value === 'employee'"
          class="text-sm mt-2"
        >
          {{ "Best for anyone who needs view-only access" | translate }}
        </p>
        <p
          *ngIf="inviteForm.controls.position.value === 'custom'"
          class="text-sm mt-2"
        >
          {{ "Customize your own set of permissions" | translate }}
        </p>
      </div>
      <div class="new-design-round-border p-4 text-sm flex flex-col">
        <div class="Poppins-Medium">{{ "Administration" | translate }}</div>
        <ul class="ps-5">
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Dashboard" | translate }}</p>
              <select
                formControlName="dashboard"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Mark As Reviewed" | translate }}</p>
              <select
                (change)="handleMarkAsReviewed($event)"
                formControlName="markAsReviewed"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Manage Chat Channels" | translate }}</p>
              <select
                formControlName="mangeChannel"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Users Management" | translate }}</p>
              <select
                formControlName="userManagement"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">
                {{ "Setting and Subscriptions" | translate }}
              </p>
              <select
                formControlName="setting"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
      <div class="new-design-round-border p-4 text-sm flex flex-col">
        <div class="Poppins-Medium">{{ "Accounting" | translate }}</div>
        <ul class="ps-5">
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Sales" | translate }}</p>
              <select
                formControlName="sales"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Purchases" | translate }}</p>
              <select
                formControlName="purchase"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Accounting" | translate }}</p>
              <select
                (change)="handleMarkAsReviewed($event)"
                formControlName="accounting"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Reports" | translate }}</p>
              <select
                formControlName="reports"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Projects" | translate }}</p>
              <select
                formControlName="projects"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>

      <div class="new-design-round-border p-4 text-sm flex flex-col">
        <div class="Poppins-Medium">{{ "Add-Ons" | translate }}</div>
        <ul class="ps-5">
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Inventory" | translate }}</p>
              <select
                formControlName="inventory"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Time Sheet" | translate }}</p>
              <select
                formControlName="timeSheets"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "P/L Budget" | translate }}</p>
              <select
                formControlName="budget"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Files" | translate }}</p>
              <select
                formControlName="files"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>

      <div class="new-design-round-border p-4 text-sm flex flex-col">
        <div class="Poppins-Medium">{{ "Platforms" | translate }}</div>
        <ul class="ps-5">
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "POS" | translate }}</p>
              <select
                formControlName="pos"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Expert" | translate }}</p>
              <select
                formControlName="expert"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
          <li class="py-2">
            <div class="flex items-center justify-between">
              <p class="">{{ "Payroll" | translate }}</p>
              <select
                formControlName="payroll"
                class="rounded-lg border-gray-300 ml-5 text-sm py-1 focus:ring-0"
              >
                <option value="view">{{ "View Only" | translate }}</option>
                <option value="edit">{{ "Edit" | translate }}</option>
                <option value="none">{{ "None" | translate }}</option>
              </select>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
