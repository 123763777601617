import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  emitFiles = new BehaviorSubject(false);

  constructor(private http: HttpService) { }

  deleteFile(id): Observable<any> {
    console.log(id, 'in service');
    
    return this.http.delete(`/storage/deleteDocument/${id}`);
  }

  uploadFileForDocument(data): Observable<any> {
    return this.http.postMultipart('/storage/uploadFiles', data);
  }
}
