import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import {
  matchPassword,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { ProfileManagementService } from '../../../services/profile-management.service';

@Component({
  selector: 'app-passwords',
  templateUrl: 'password-settings.component.html',
})
export class PasswordSettingsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private profileManagementService: ProfileManagementService,
    private spinner: NgxSpinnerService
  ) {}

  changePasswordForm: FormGroup;

  showOldPassword = false;
  showNewPassword = false;
  showConfirmPassword = false;

  respMessage = '';
  error = '';

  formErrors = {
    currentPassword: '',
    newPassword: '',
    confirmPassword: '',
  };
  resetErrorMessages = {
    currentPassword: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
    },
    newPassword: {
      required: 'New Password is required',
      minlength: 'New Password must be of least 8 characters',
      maxlength: 'New Password cannot be more than of 128 characters',
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
      mustMatch: 'Password did not match',
    },
  };

  ngOnInit(): void {
    this.loadForm();
  }

  loadForm(): void {
    this.changePasswordForm = this.fb.group(
      {
        currentPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(128),
          ],
        ],
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(128),
          ],
        ],
        confirmPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.maxLength(128),
          ],
        ],
      },
      { validators: matchPassword('newPassword', 'confirmPassword') }
    );

    this.changePasswordForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.changePasswordForm,
        { ...this.formErrors },
        this.resetErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.changePasswordForm,
      { ...this.formErrors },
      this.resetErrorMessages
    );
  }

  changePassword(): void {
    this.respMessage = '';
    this.error = '';
    if (this.changePasswordForm.invalid) {
      this.changePasswordForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.changePasswordForm,
        { ...this.formErrors },
        this.resetErrorMessages
      );
      return;
    }

    const { currentPassword, newPassword } = this.changePasswordForm.value;
    const body = { currentPassword, newPassword };

    this.spinner.show();
    this.profileManagementService.changePassword(body).subscribe(
      (resp) => {
        this.spinner.hide();
        this.respMessage = resp.message;
      },
      (error) => {
        this.error = error?.error?.message || 'Internal Server Error';
        this.spinner.hide();
      }
    );
  }
}
