import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as ExpertDetails from '../actions/expertDetails.action';

export interface ExpertDetailsState {
  expertDetails: any;
}

export const initialState: ExpertDetailsState = {
  expertDetails: '',
};

export const expertDetailsReducer = createReducer(
  initialState,
  on(
    ExpertDetails.SetExpertDetails,
    (state: ExpertDetailsState, { expertDetails }) => ({
      ...state,
      expertDetails,
    })
  ),
  on(UserLogout, () => ({...initialState}))
);

export function reducer(
  state: ExpertDetailsState | undefined,
  action: Action
): any {
  return expertDetailsReducer(state, action);
}

export const getExpertDetails = (state: ExpertDetailsState) =>
  state.expertDetails;
