<div class="flex flex-col items-center p-4">
    <div class="bg-light-gray-1 rounded-3xl w-3/4 p-6 flex flex-col mb-4">
        <h3 class="text-2xl Poppins-Bold">{{'Payment settings' | translate }}</h3>
        <form [ngClass]='showPaypalSettingsForm ? "" : "hidden"' [formGroup]='paypalForm' class="w-1/2 space-y-4">
            <div class="text-lg Poppins-Bold">{{'Paypal Configuration form' | translate }}</div>
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='email' class="block text-sm Poppins-Bold font-color-05">{{'Paypal email' | translate }}</label>
                    <input id='email' formControlName='email' type="email" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Paypal Email' | translate }}" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.email'>{{formErrors.email}}</div>
            </div>
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='clientId' class="block text-sm Poppins-Bold font-color-05">{{'Client Id' | translate }}</label>
                    <input id='clientId' formControlName='clientId' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Client ID' | translate }}" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.clientId'>{{formErrors.clientId}}</div>
            </div>
            <div class="flex-1">
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='secretCode' class="block text-sm Poppins-Bold font-color-05">{{'Secret Code' | translate }}</label>
                    <input id='secretCode' formControlName='secretCode' type="text" class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200" placeholder="{{'Enter Secret Code' | translate }}" />
                </div>
                <div class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.secretCode'>{{formErrors.secretCode}}</div>
            </div>
            <div>
                <app-button buttonText='{{"Connect" | translate }}' type='primary' (handleClick)='connectToPaypal()'></app-button>
            </div>
        </form>
        <div [ngClass]='showPaypalDisconnectButton ? "" : "hidden"'>
            <div class="my-2 Poppins-Bold text-lg">{{'Paypal Configuration' | translate }}</div>
            <div class="mb-2" *ngIf='business'><span class="Poppins-Bold">{{'Paypal Email:' | translate }}</span> {{business.paypalEmail ? business.paypalEmail : ''}}</div>
            <div class="mb-4 text-gray-500 flex">
              <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
              {{'Paypal Security message' | translate }}</div>
              <app-button buttonText='{{"Disconnect" | translate }}' type='danger' (handleClick)='disconnectPaypal()'></app-button>
        </div>
    </div>
    <div dir="auto" class="bg-light-gray-1 rounded-3xl w-3/4 p-6 flex flex-col">
        <div class="text-lg Poppins-Bold">Instruction to setup PayPal</div>
        <div  class="mt-2">
            <p class="text-base mb-2">
              {{'Paypal steps message' | translate}}
            </p>
            <ul  class="list-disc list-outside">
                <li>{{'Paypal step 1' | translate }}<a class="underline text-blue-500" href="https://developer.paypal.com/home/">https://developer.paypal.com/home/</a></li>
                <li>{{'Paypal step 2' | translate }}</li>
                <li>{{'Paypal step 3' | translate}}</li>
                <li>{{'Paypal step 4' | translate }}</li>
                <li>{{'Paypal step 5' | translate }}</li>
                <li>{{'Paypal step 6' | translate }}</li>
                <li>{{'Paypal step 7' | translate }}</li>
                <li>{{'Paypal step 8' | translate }}</li>
                <li>{{'Paypal step 9' | translate }}</li>
                <li>{{'Paypal step 10' | translate }}</li>
                <li>{{'Paypal step 11' | translate }}</li>
                <li>{{'Paypal step 12' | translate }} https://api.numetric.work/paypal/paypalWebhook.</li>
                <li>{{'Paypal step 13' | translate }}</li>
                <li>{{'Paypal step 14' | translate }}</li>
            </ul>
          </div>
    </div>
</div>