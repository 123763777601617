import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ExpertService } from 'src/app/modules/experts/expert.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-enroll-expert',
  templateUrl: './enroll-expert.component.html',
  styleUrls: ['./enroll-expert.component.scss'],
})
export class EnrollExpertComponent implements OnInit {
  logo: string;
  formImg: string;
  getVerifiedImg: string;
  verifiedUserImg: string;
  groupImg: string;
  expertStatus: string;
  unsubscribe$ = new Subject();
  user$: Observable<any>;
  userId: String;

  constructor(
    private router: Router,
    private expertService: ExpertService,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private toster: ToastrService,
    private translateService: TranslateService
  ) {
    this.router.navigate(['/experts/hire-expert']);
    // this.spinner.show();
    // this.user$ = this.store.pipe(select(selectUser));
    // this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
    //   this.userId = user._id;

    //   this.expertService
    //     .getApplicationStatusLambda({ id: this.userId })
    //     .subscribe(
    //       (result) => {
    //         this.expertStatus = result.data[0]?.status;

    //         this.spinner.hide();
    //         if (!this.expertStatus) {
    //           this.router.navigate(['/experts/become-expert']);
    //         } else if (
    //           this.expertStatus === 'In-Process' ||
    //           this.expertStatus === 'Rejected'
    //         ) {
    //           this.router.navigate([
    //             '/experts/become-expert/application-history',
    //           ]);
    //         } else if (this.expertStatus === 'Approved') {
    //           let a = document.createElement('a');
    //           const token = localStorage.getItem('refresh_token');
    //           a.href = `http://localhost:4200/login?redirect=${token}&db=numetric`;
    //           // a.href = `http://expert.numetric.work/login?redirect=${token}&db=numetric`;
    //           a.target = 'blank';
    //           a.click();
    //         }
    //       },
    //       (error) => {
    //         this.spinner.hide();
    //         this.toster.error(
    //           this.translateService.instant('Something went wrong!')
    //         );
    //       }
    //     );
    // });
  }

  ngOnInit(): void {
    this.logo = '/assets/images/expert-logo.png';
    this.formImg = '/assets/images/form-edit.svg';
    this.getVerifiedImg = '/assets/images/user-verified.svg';
    this.verifiedUserImg = '/assets/images/verified-expert.svg';
    this.groupImg = '/assets/images/expert-group.png';
  }

  enrollAsExpert(): void {
    if (!this.expertStatus) {
      this.router.navigate(['/experts/become-expert/expert-application']);
    } else if (
      this.expertStatus === 'In-Process' ||
      this.expertStatus === 'Rejected'
    ) {
      this.router.navigate(['/experts/become-expert/application-history']);
    } else if (this.expertStatus === 'Approved') {
      let a = document.createElement('a');
      const token = localStorage.getItem('refresh_token');
      a.href = `http://localhost:4200/login?redirect=${token}&db=numetric`;
      a.target = 'blank';
      a.click();
    }
  }
}
