import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { TaskManagerComponent } from './task-manager.component';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgFileDragDropModule } from 'ng-file-drag-drop';
import { ViewTaskComponent } from './components/view-task/view-task.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
const routes:Routes = [
  {
    path : '',
    component : TaskManagerComponent
  },
  {
    path: 'view-task/:id',
    component : ViewTaskComponent
  }
]

@NgModule({
  declarations: [
    TaskManagerComponent,
    ViewTaskComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TranslateModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    NgFileDragDropModule,
    ClickOutsideModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
  ],
  providers: [
    DatePipe
  ]
})
export class TaskManagerModule { }
