import { CurrencyPipe } from '@angular/common';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { CurrencywithspacePipe } from 'src/app/pipes/currencywithspace.pipe';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import {
  dateLessThan,
  DateValidator,
  valueChanges,
} from 'src/app/shared/utils/formValidator';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { AccountingService } from '../../accounting.service';

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.scss'],
  providers: [CurrencyPipe],
})
export class TransactionComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private router: Router,
    private accountService: AccountingService,
    private salesService: SalesService,
    private purchaseService: PurchasesService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private currencyPipe: CurrencyPipe,
    private numberService: NumberService,
    private fileUploadService: FileUploadService,
    private toster: ToastrService
  ) {
    this.businessId$ = this.store.pipe(select(selectBusiness));
    this.usage$ = this.store.pipe(select(selectUsage));
  }
  @ViewChild('fileUploadComponent') fileUploadComponent: ElementRef;
  filterForm: FormGroup;
  openFilterModal = false;
  addIncomeModal = false;
  businessId$: Observable<any>;
  usage$: Observable<any>;
  unsubscribe$ = new Subject();
  transactionLimit;
  businessId;
  currencyDetails = {
    currency: '',
    currencySymbol: '',
  };

  number = '1.2-2';
  transaction;
  tableHeadings = [
    'Date',
    'Serial ID',
    'Transaction ID',
    'Description',
    'Category',
    'Amount',
    'Actions',
  ];
  tableKeys = [
    'date',
    'serialNumber',
    'transactionNumber',
    'description',
    'category',
    'displayAmount',
  ];
  tableData = [];
  files: File[] = [];
  addedFiles = [];
  form: FormGroup;
  amountDue = null;
  invalidAmount = false;
  formErrors = {
    amount: '',
    account: '',
    date: '',
  };

  formErrorMessages = {
    amount: {
      required: 'Amount is required',
      invalid: 'Amount is invalid',
    },
    account: {
      required: 'Account is required',
    },
    date: {
      required: 'Date is required',
      invalidDate: 'Invalid Date',
    },
  };

  filterFormErrors = {
    customDateRangeTo: '',
    customDateRangefrom: '',
  };
  filterFormErrorMessages = {
    customDateRangeTo: {
      dates: 'End date is invalid',
      invalidDate: 'Invalid Date',
    },
    customDateRangefrom: {
      invalidDate: 'Invalid Date',
    },
  };

  addTransactionModal = {
    isOpen: false,
    heading: '',
    type: 'income',
    formEditable: true,
    to: 'save',
    id: null,
    amountCheck: false,
  };

  transactionToDelete = {
    recordId: null,
    transactionId: null,
    referanceId: null,
    type: null,
  };

  confirmDeleteTransactionModal = false;

  fieldNames = {
    transaction: 'Transaction',
    category: 'Category',
    transactionNumber: 'Transaction ID',
    date: 'Date',
    serialNumber: 'Serial ID',
    total: 'Total',
    amount: 'Amount',
    type: 'Type',
    description: 'Description',
    account: 'Account',
    debit: 'Debit',
    credit: 'Credit',
    issuer: 'Issued By',
  };

  sorted = false;

  ngOnInit(): void {
    this.loadForm();
    this.loadFilterForm();
    this.loadData();
    this.getTransactionLimit();
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage?.currentUsage) {
        const { transactionLimit } = currentUsage?.currentUsage;
        this.transactionLimit = transactionLimit;
      }
    });
  }

  loadNumberConfig(): void {
    this.numberService.number
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number) => {
        this.number = number;
      });
  }

  // getCustomizationSettings(): void {
  //   this.purchaseService.getCustomizationSettings()
  //   .pipe(takeUntil(this.unsubscribe))
  //   .subscribe(resp => {
  //     if(resp?.success)
  //     this.customizationSettings = resp?.data;
  //     console.log(this.customizationSettings);

  //   })
  // }

  loadData(): void {
    this.businessId$.subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId._id;
        this.currencyDetails = (({ currency, currencySymbol }) => ({
          currency,
          currencySymbol,
        }))(business?.businessId);
        this.spinner.show();
        this.accountService.getTransactions(this.businessId).subscribe(
          (resp) => {
            this.spinner.hide();
            if (resp.success) {
              this.tableData = resp.data
                .map((transaction) => ({
                  ...transaction,
                  date: transaction.date?.split('T')[0],
                  displayAmount: this.numberService.currencier(
                    transaction?.amount
                  ),
                }))
                .reverse();
            }
          },
          (error) => {
            this.spinner.hide();
            this.toster.error(
              this.translateService.instant('Something went wrong!')
            );
          }
        );
      }
    });
  }

  loadForm(): void {
    this.form = this.fb.group({
      date: [null, [Validators.required, DateValidator()]],
      description: [null],
      creditFrom: [null, [Validators.required]],
      debitTo: [null, [Validators.required]],
      category: [null],
      amount: [null, [Validators.required]],
      notes: [null],
    });

    this.form.valueChanges.subscribe(({ amount }) => {
      this.formErrors = valueChanges(
        this.form,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      amount > this.amountDue &&
      !this.formErrors.amount &&
      this.addTransactionModal.to === 'edit' &&
      this.addTransactionModal.amountCheck
        ? (this.invalidAmount = true)
        : (this.invalidAmount = false);
    });

    this.formErrors = valueChanges(
      this.form,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group(
      {
        category: [null],
        status: [null],
        type: [null],
        customDateRangefrom: [null],
        customDateRangeTo: [null],
      },
      { validator: dateLessThan('customDateRangefrom', 'customDateRangeTo') }
    );
    this.filterForm.valueChanges.subscribe(() => {
      console.log(this.filterForm);
      this.filterFormErrors = valueChanges(
        this.filterForm,
        { ...this.filterFormErrors },
        this.filterFormErrorMessages,
        this.translateService
      );
    });

    this.filterFormErrors = valueChanges(
      this.filterForm,
      { ...this.filterFormErrors },
      this.filterFormErrorMessages,
      this.translateService
    );
  }

  openFilter(): void {
    this.openFilterModal = true;
  }

  addTransaction(type): void {
    this.addTransactionModal.isOpen = true;
    this.addTransactionModal.to = 'save';
    this.addTransactionModal.formEditable = true;
    this.files = [];
    this.addedFiles = [];
    switch (type) {
      case 'income':
        this.addTransactionModal.heading = 'Add Credit Note';
        this.addTransactionModal.type = 'income';
        this.form.get('category').setValue('Credit Note');
        break;
      case 'expense':
        this.addTransactionModal.heading = 'Add Debit Note';
        this.addTransactionModal.type = 'expense';
        this.form.get('category').setValue('Debit Note');
        break;
    }
  }

  saveTransaction(type, to): void {
    if (this.form.invalid) {
      return;
    }
    if (this.invalidAmount) {
      return;
    }
    let data = {};
    switch (type) {
      case 'income':
        data = this.transactionMapperForDB(this.form.value, 'income');
        break;
      case 'expense':
        data = this.transactionMapperForDB(this.form.value, 'expense');
        break;
    }
    this.addTransactionModal.isOpen = false;
    this.spinner.show();
    if (to == 'save') {
      const formData = new FormData();
      this.fileUploadService.emitFiles.next(true);
      this.fileUploadService.emitFiles.next(false);
      this.files.forEach((file, i) => {
        formData.append(`file${i}`, file);
      });
      formData.append('payload', JSON.stringify(data));
      this.accountService
        .createTransaction({ formData, businessId: this.businessId })
        .subscribe(
          (resp) => {
            this.spinner.hide();
            if (resp?.success) {
              this.toster.success(
                resp?.message ??
                  this.translateService.instant(
                    'Transaction saved successfully'
                  )
              );
              this.tableData.unshift(this.transactionMapperForFE(resp.data));
              this.store.dispatch(
                updateCurrentTransactionLimit({
                  transactionLimit: this.transactionLimit + 1,
                })
              );
              this.files = [];
              this.addedFiles = [];
              this.fileUploadComponent['files'] = [];
              this.fileUploadComponent['filesArrToDisplay'] = [];
            } else {
              this.toster.error(
                this.translateService.instant(
                  resp?.message ?? 'Something went wrong!'
                )
              );
            }
          },
          (error) => {
            console.log(error);
            this.spinner.hide();
            this.toster.error(
              this.translateService.instant(
                error?.error?.message ?? 'Something went wrong!'
              )
            );
          }
        );
    } else {
      data['_id'] = this.addTransactionModal.id;
      const formData = new FormData();
      this.fileUploadService.emitFiles.next(true);
      this.fileUploadService.emitFiles.next(false);
      this.files.forEach((file, i) => {
        formData.append(`file${i}`, file);
      });
      formData.append('payload', JSON.stringify(data));
      this.accountService
        .updateTransaction(formData, this.addTransactionModal.id)
        .subscribe(
          (resp) => {
            if (resp?.success) {
              this.tableData = this.tableData.map((transaction) => {
                if (transaction._id === this.addTransactionModal.id) {
                  return this.transactionMapperForFE(resp.data);
                }
                return transaction;
              });
              this.fileUploadComponent['files'] = [];
              this.fileUploadComponent['filesArrToDisplay'] = [];
              this.files = [];
              this.addedFiles = [];
              this.toster.success(
                this.translateService.instant(
                  'Transaction updated successfully'
                )
              );
            } else {
              this.toster.error(resp?.message);
            }
            this.spinner.hide();
          },
          (error) => {
            this.spinner.hide();
            this.toster.error(
              this.translateService.instant(
                error?.error?.message ?? 'Something went wrong!'
              )
            );
          }
        );
    }
    this.form.reset();
  }

  transactionMapperForDB(data, type): any {
    return {
      type,
      account:
        type === 'income'
          ? data?.creditFrom?.accountType
          : data?.debitTo?.accountType,
      accountName:
        type === 'income'
          ? data?.creditFrom?.accountName
          : data?.debitTo?.accountName,
      accountId: type === 'income' ? data?.creditFrom?._id : data?.debitTo?._id,
      creditFrom: {
        account: data?.creditFrom?.accountType,
        accountName: data?.creditFrom?.accountName,
        accountId: data?.creditFrom?._id,
      },
      debitTo: {
        account: data?.debitTo?.accountType,
        accountName: data?.debitTo?.accountName,
        accountId: data?.debitTo?._id,
      },
      description: data?.description,
      category: data?.category,
      amount: this.numberService.toFixed(data?.amount),
      date: data?.date,
      businessId: this.businessId,
      notes: data?.notes,
    };
  }

  transactionMapperForFE(data): any {
    return {
      ...data,
      displayAmount: this.numberService.currencier(data?.amount),
      date: data?.date?.split('T')[0],
    };
  }

  editTransaction(transaction): void {
    if (
      transaction.type === 'journal voucher' ||
      transaction.type === 'journal'
    ) {
      this.router.navigate(['/accounting/journal-entry'], {
        queryParams: { id: transaction._id },
      });
      return;
    }
    this.addTransactionModal.to = 'edit';
    this.addTransactionModal.id = transaction._id;
    this.addTransactionModal.amountCheck = false;
    transaction.referanceId
      ? (this.addTransactionModal.formEditable = false)
      : (this.addTransactionModal.formEditable = true);
    this.addedFiles = transaction?.files ?? [];
    switch (transaction.type) {
      case 'income':
        this.addTransactionModal.heading = 'Edit Credit Note';
        this.addTransactionModal.type = 'income';
        if (transaction.referanceId) {
          this.spinner.show();
          this.salesService.getInvoice(transaction.referanceId).subscribe(
            (resp) => {
              this.spinner.hide();
              if (resp.success) {
                this.amountDue = this.numberService.toFixed(
                  resp?.data?.dueAmount
                );
                const data = this.createFormData(transaction);
                this.form.setValue(data);
                this.addTransactionModal.isOpen = true;
                this.addTransactionModal.amountCheck = true;
              }
            },
            (error) => {
              this.spinner.hide();
            }
          );
          return;
        }
        break;
      case 'expense':
        this.addTransactionModal.heading = 'Edit Debit Note';
        this.addTransactionModal.type = 'expense';
        if (transaction.referanceId) {
          this.spinner.show();
          this.purchaseService
            .getBillDetails(transaction.referanceId)
            .subscribe(
              (resp) => {
                this.spinner.hide();
                if (resp.success) {
                  this.amountDue = this.numberService.toFixed(
                    resp?.data?.dueAmount
                  );
                  const data = this.createFormData(transaction);
                  this.form.setValue(data);
                  this.addTransactionModal.isOpen = true;
                  this.addTransactionModal.amountCheck = true;
                }
              },
              (error) => {
                this.spinner.hide();
              }
            );
          return;
        }
        break;
    }
    console.log(transaction);
    const data = this.createFormData(transaction);
    console.log('data', data);
    this.addTransactionModal.isOpen = true;
    this.form.setValue(data);
    console.log('data', this.form.value);
  }

  createFormData(transaction): any {
    return {
      date: transaction.date,
      description: transaction?.description ?? null,
      // account: {accountType: transaction?.accountType, accountName: transaction?.accountName},
      creditFrom: {
        accountType: transaction.creditFrom?.accountType,
        accountName: transaction.creditFrom?.accountName,
        accountId: transaction.creditFrom?.accountId,
      },
      debitTo: {
        accountType: transaction.debitTo?.accountType,
        accountName: transaction.debitTo?.accountName,
        accountId: transaction.debitTo?.accountId,
      },
      category: transaction?.category ?? null,
      amount: this.numberService.toFixed(transaction?.amount),
      notes: transaction?.notes ?? null,
    };
  }

  reviewTransaction(transaction): void {
    this.spinner.show();
    const data = {
      transactionId: transaction?._id,
      isReviewed: !transaction?.isReviewed,
    };
    this.accountService.markAsReviewTransaction(data).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        if (resp.success) {
          this.tableData = this.tableData.map((entry) => {
            if (entry._id === transaction._id) {
              return {
                ...entry,
                amount: this.numberService.toFixed(transaction?.amount),
                isReviewed: data.isReviewed,
              };
            }
            return entry;
          });
        }
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  deleteTransaction(transaction): void {
    this.transactionToDelete = {
      transactionId: transaction._id,
      recordId: transaction.recordId,
      referanceId: transaction.referanceId,
      type: transaction.referanceId ? transaction.type : null,
    };
    this.confirmDeleteTransactionModal = true;
  }

  confirmDeleteTransaction(): void {
    this.spinner.show();
    this.accountService.deleteTransaction(this.transactionToDelete).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.toster.success(
            this.translateService.instant('Transaction deleted successfully')
          );
          this.confirmDeleteTransactionModal = false;
          this.store.dispatch(
            updateCurrentTransactionLimit({
              transactionLimit: this.transactionLimit - 1,
            })
          );
          this.tableData = this.tableData.filter(
            (transaction) =>
              transaction._id !== this.transactionToDelete.transactionId
          );
        }
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  closeModal(): void {
    this.form.reset();
    this.fileUploadComponent['files'] = [];
    this.fileUploadComponent['filesArrToDisplay'] = [];
    console.log(this.files);

    this.addTransactionModal.isOpen = false;
  }

  submitFilterForm(): void {
    if (this.filterForm.invalid) return;
    const filters = (({
      category,
      status,
      type,
      customDateRangefrom,
      customDateRangeTo,
    }) => ({
      category,
      status: status === 'Reviewed' ? true : false,
      type,
      fromDate: customDateRangefrom || null,
      endDate: customDateRangeTo || null,
    }))(this.filterForm.value);

    this.spinner.show();
    this.openFilterModal = false;
    this.accountService.filterTransactions(filters, this.businessId).subscribe(
      (resp) => {
        if (resp.success) {
          this.tableData = resp?.data?.map((item) =>
            this.transactionMapperForFE(item)
          );
        } else {
          this.toster.error(
            this.translateService.instant('Something went wrong!')
          );
        }
        this.spinner.hide();
      },
      () => {
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
        this.spinner.hide();
      }
    );
  }

  revereseList() {
    const data = this.tableData;
    const arrayOfObjects = data.map((el) => {
      const year = Number(el.serialNumber.replace(/^\D+|\D.*$/g, ''));
      const count = Number(el.serialNumber.split('-')[1]);
      return { ...el, year, count };
    });
    if (this.sorted) {
      arrayOfObjects.sort((a, b) => {
        if (a.year === b.year) {
          return a.count < b.count ? 1 : -1;
        } else {
          return a.year < b.year ? 1 : -1;
        }
      });
    } else {
      arrayOfObjects.sort((a, b) => {
        if (a.year === b.year) {
          return a.count < b.count ? -1 : 1;
        } else {
          return a.year < b.year ? -1 : 1;
        }
      });
    }
    this.tableData = JSON.parse(JSON.stringify(arrayOfObjects));
    this.sorted = !this.sorted;
  }

  closeFilterForm(): void {
    this.openFilterModal = false;
  }

  downloadTransactionPdf(transaction) {
    this.spinner.show();
    const body = {
      transactionId: transaction._id,
      config: {
        direction:
          localStorage.getItem('NuMetric|lang') === 'en' ? 'ltr' : 'rtl',
        fieldNames: this.languageService.translate(this.fieldNames),
        data: {
          journal: transaction.journal.map((el) => {
            return {
              ...el,
              credit: this.currencier(el.credit ?? 0),
              debit: this.currencier(el.debit ?? 0),
            };
          }),
        },
        amount: this.currencier(transaction.amount),
      },
    };

    if (
      transaction.category === 'Credit Note' ||
      transaction.category === 'Debit Note'
    ) {
      body.config.data.journal.push({
        accountName: transaction.creditFrom.accountName,
        type: 'Credit',
        credit: this.currencier(transaction.amount),
        debit: this.currencier(0),
        description: '-',
      });
      body.config.data.journal.push({
        accountName: transaction.debitTo.accountName,
        type: 'Debit',
        debit: this.currencier(transaction.amount),
        credit: this.currencier(0),
        description: '-',
      });
    }
    this.accountService.downloadPdf(body).subscribe(
      (resp) => {
        this.spinner.hide();
        const a = document.createElement('a');
        const blob = new Blob([resp], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Transaction.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        // this.toaster.success('PDF downloaded');
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  currencier(amount): string {
    const currencyWithSpacePipe = new CurrencywithspacePipe();
    return currencyWithSpacePipe.transform(
      amount,
      this.currencyDetails.currency,
      'narrowSymbol',
      this.number
    );
  }
}
