<div class="flex h-screen">
    <div class="p-8 md:p-14 lg:p-20 w-full lg:w-5/12 max-h-full overflow-y-auto no-scrollbar">
      <div class="mb-12"><a routerLink="/"><img src="assets/images/logo.png" width="150" alt="logo"></a></div>
      <div class="w-full lg:w-10/12">
        <div class="Poppins-ExtraBold text-2xl mb-3">Forgot Password.</div>
        <p class="Poppins-SemiBold font-color-black-03 py-2">Please enter your e-mail address, we'll send you an confirmation mail to verify your account.</p>
        <div class='text-green-400' *ngIf='forgotRespMessage'>{{forgotRespMessage}}</div>
        <div class='text-red-400' *ngIf='error'>{{error}}</div>
        <form novalidate class="py-2 px-0">
          <div class="mb-4">
            <label class="Poppins-Bold font-color-black-05 block mb-2">Your e-mail</label>
            <input [formControl]='email' class="new-input px-5 py-3" type="text" name="email">
            <div *ngIf='email.getError("required") && email.dirty' class="text-sm text-red-400 ms-2">Email is required</div>
            <div *ngIf='email.getError("pattern") && email.dirty' class="text-sm text-red-400 ms-2">Email must be in correct format</div>
          </div>
          <button class="Poppins-Bold bg-gradient px-4 py-2 text-white w-full mt-3 rounded-xl" type="button" (click)='sendEmail()'>Verify Account</button>
        </form>
      </div>
    </div>
    <div class="w-0 lg:w-7/12 flex items-center justify-center bg-light-gray-1 min-h-screen">
      <img src="assets/images/login.png" width="600" alt="" srcset="">
    </div>
  </div>