import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import * as ProjectActions from 'src/app/store/actions/projects.actions';

@Injectable({
  providedIn: 'root'
})
export class ProjectsService {
  
  constructor(private http: HttpService,private rootStore:Store<RootReducerState>) { 
  }

  getAllProjects(businessId): Observable<any> {
    return this.http.get(`/project/getAllProjects/${businessId}`)
  }

  getProjectDetails(projectId): Observable<any> {
    return this.http.get(`/project/getProjectDetails/${projectId}`)
  }

  createProject(payload,buisnessId): Observable<any>{
   return this.http.postMultipart(`/project/createProject/${buisnessId}`,payload)
  }
  updateProject(payload,id): Observable<any>{
   return this.http.putMultipart(`/project/updateProject/${id}`,payload)
  }

  setProjectsInStore(projects): void {
    this.rootStore.dispatch(ProjectActions.SetProject({projects}));
  }

  addProjectInStore(project): void {
    this.rootStore.dispatch(ProjectActions.AddProject({project}));
  }

  updateProjectInStore(project): void {
    this.rootStore.dispatch(ProjectActions.updateProject({project}));
  }

  filterProject(fromDate,endDate, projectId): Observable<any> {
    return this.http.post(`/project/filterProject/${projectId}`, {fromDate, endDate})
  }
}
