<div class="flex justify-center p-8 create-bill bg-[#F9F9F9]">
  <div class="w-full flex flex-col space-y-4">
    <div>
      <form [formGroup]="estimateForm" class="p-12 bg-white">
        <div class="flex flex-col justify-between">
          <div class="flex gap-2 text-xl items-center pb-2">
            <label class="text-[#1C1C1C66]">{{
              "Estimate #" | translate
            }}</label>
            <fieldset disabled>
              <input
                id="estimateNumber"
                formControlName="estimateNumber"
                type="text"
                class="w-full pl-3 text-xl bg-input-sales border-0 focus:ring-0"
              />
            </fieldset>
            <div
              class="text-sm text-red-500 ms-2 mt-1"
              *ngIf="formErrors.estimateNumber"
            >
              {{ formErrors.estimateNumber }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 gap-x-6">
            <div class="flex-grow flex flex-col gap-2">
              <div class="flex justify-between items-end">
                <label class="text-base Poppins-Medium">{{
                  "Select Customer" | translate
                }}</label>
                <span
                  class="text-xxs leading-0 text-[#898989] cursor-pointer"
                  routerLink="/sales/customers/create-customer"
                >
                  {{ "Add New Customer" | translate }}
                </span>
              </div>

              <ng-select
                [items]="customers"
                class="new-dropdown"
                bindLabel="customerName"
                bindValue="_id"
                formControlName="customerId"
                placeholder="{{ 'Select one' | translate }}"
                (change)="changeCustomer($event)"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.customerName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.customerId"
              >
                {{ formErrors.customerId }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Date of Estimate Creation" | translate
              }}</label>
              <input
                id="date"
                formControlName="date"
                type="date"
                class="new-date"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.date"
              >
                {{ formErrors.date }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Date of Expiration" | translate
              }}</label>
              <input
                id="expiryDate"
                formControlName="expiryDate"
                type="date"
                class="new-date"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.expiryDate"
              >
                {{ formErrors.expiryDate }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Purchase Order" | translate
              }}</label>
              <input
                id="purchaseOrder"
                formControlName="purchaseOrder"
                type="text"
                class="new-input"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.purchaseOrder"
              >
                {{ formErrors.purchaseOrder }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Summary" | translate
              }}</label>
              <input
                id="subheading"
                formControlName="subheading"
                type="text"
                class="new-input"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.subheading"
              >
                {{ formErrors.subheading }}
              </div>
            </div>
          </div>
          <h3 class="text-lg mt-6">{{ "Items" | translate }}</h3>
          <table class="min-w-full divide-y divide-gray-300 mt-[2rem]">
            <thead class="h-12 text-[#1C1C1C66] border-[#1C1C1C33] border-b">
              <tr class="text-start">
                <td class="ps-5">{{ "Item" | translate }}</td>
                <td class="px-2">{{ "Description" | translate }}</td>
                <td class="px-2">{{ "Quantity" | translate }}</td>
                <td class="px-2">{{ "Unit Price" | translate }}</td>
                <td class="px-2">{{ "Select Tax" | translate }}</td>
                <td class="px-2">{{ "Total" | translate }}</td>
                <td class="rounded-te-xl"></td>
              </tr>
            </thead>
            <tbody>
              <ng-container formArrayName="items">
                <ng-container
                  *ngFor="let itemList of items.controls; let i = index"
                >
                  <tr [formGroup]="itemList" class="">
                    <td class="w-2/12 p-2">
                      <ng-select
                        [items]="availableProducts"
                        class="new-dropdown"
                        bindLabel="name"
                        bindValue="name"
                        formControlName="item"
                        [clearable]="false"
                        placeholder="{{ 'Select one' | translate }}"
                        (change)="changeEvent($event, i)"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-2">
                      <input
                        id="description"
                        formControlName="description"
                        type="text"
                        class="new-input"
                        placeholder="Description"
                      />
                    </td>
                    <td class="p-2">
                      <input
                        id="quantity"
                        formControlName="quantity"
                        type="number"
                        class="new-input"
                        placeholder="0"
                      />
                    </td>
                    <td class="p-2">
                      <input
                        id="unitPrice"
                        formControlName="unitPrice"
                        type="number"
                        class="new-input"
                        placeholder="0"
                      />
                    </td>
                    <td class="p-2 w-2/12">
                      <ng-select
                        class="new-dropdown border-0 p-0"
                        [items]="salesTaxes"
                        bindLabel="name"
                        formControlName="tax"
                        placeholder="{{ 'Select Tax' | translate }}"
                      >
                        <ng-template ng-label-tmp let-item="item">
                          <span class="ng-value-label"
                            >{{ item.taxName }}
                            {{ item.tax | number : number }}%</span
                          >
                        </ng-template>
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-2">
                      <p>
                        {{
                          items.controls[i].get("quantity").value *
                            items.controls[i].get("unitPrice").value
                            | currency
                              : currencyDetails?.currency
                              : "symbol-narrow"
                              : number
                        }}
                      </p>
                    </td>
                    <td class="w-1/12">
                      <svg
                        (click)="removeItem(i)"
                        xmlns="http://www.w3.org/2000/svg"
                        name="delete"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="mx-auto h-5 w-5 text-red-500"
                      >
                        <path
                          _ngcontent-hsm-c58=""
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>
                      <p
                        class="text-red-500 whitespace-nowrap"
                        *ngIf="itemList.get('quantity').hasError('min')"
                      >
                        {{ "Quantity should be more than 0" }}
                      </p>
                    </td>
                    <td colspan="4"></td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="items.controls.length === 0">
                  <tr>
                    <td colspan="6" class="text-center pt-12">No items found</td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <td vAlign="top" colspan="4">
                  <div class="ps-2 text-black">
                    <button
                      (click)="addNewItem()"
                      id="add"
                      class="flex items-center space-x-4"
                    >
                      <span
                        class="p-2 rounded-sm border border-[#1C1C1C0D] bg-[#f6f6f6c9] flex gap-2 me-2"
                      >
                        <img
                          src="assets/images/delete-icon.svg"
                          style="transform: rotate(45deg)"
                          alt=""
                        />
                        {{ "Add a Line" | translate }}
                      </span>
                    </button>
                  </div>
                </td>
                <td colspan="2">
                  <div class="flex justify-between py-2">
                    <p>{{ "Subtotal:" | translate }}</p>
                    <p>
                      {{
                        subTotal
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ "Tax:" | translate }}</p>
                    <p>
                      {{
                        totalTax
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                  </div>
                  <div
                    class="flex justify-between py-2 border-t Poppins-Medium"
                  >
                    <p>{{ "Total Amount:" | translate }}</p>
                    <p>
                      {{
                        totalAmount
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="mt-8 flex flex-col gap-[10px]">
            <label class="block text-sm Poppins-Medium">{{
              "Notes & Terms" | translate
            }}</label>
            <div class="flex gap-8">
              <textarea
                formControlName="memo"
                class="new-input !h-[7rem] !w-1/3"
                placeholder="{{ 'Add some notes' | translate }}"
              ></textarea>
              <app-file-upload
                appAccessControl
                moduleType="storage"
                accessType="edit"
                [addedFiles]="addedFiles"
                (emitter)="saveFiles($event)"
                [type]="'compact'"
                class="flex-1"
              ></app-file-upload>
              <div
                appAccessControl
                moduleType="sales"
                accessType="edit"
                class="flex flex-col gap-4"
              >
                <app-submit
                  [value]="update ? 'Update' : 'Save'"
                  className="bg-[#026AA2] text-white w-44"
                  (clicked)="update ? updateEstimate() : createEstimate()"
                ></app-submit>
                <app-submit
                  value="Discard"
                  className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn"
                  routerLink="../"
                ></app-submit>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <app-modal-container [isOpen]="outOfStock">
    <div header class="text-start border-b pb-2">
      {{ "Check Items" | translate }}
    </div>
    <div class="text-start" content>
      <p>Following items have insufficient quantity in inventory:</p>
      <ul>
        <li class="Poppins list-disc" *ngFor="let item of checkQuantityInStock">
          <div>
            {{ item?.item }} added with {{ item?.quantityAdded }} units has
            {{ item?.openingStock }} units only
          </div>
        </li>
      </ul>
    </div>
    <div footer class="flex items-center ms-auto gap-x-2">
      <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
      <app-button
        buttonText="{{ 'Ok' | translate }}"
        type="third"
        rounded="true"
        (handleClick)="outOfStock = false"
      ></app-button>
    </div>
  </app-modal-container>
</div>
