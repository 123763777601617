import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  loginForm: FormGroup;
  forgotRespMessage = '';
  error = '';
  email: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  constructor(private authService: AuthService, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
  }

  sendEmail(): void{
    this.forgotRespMessage = '';
    this.error = '';
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    this.spinner.show();
    this.authService.forgotPassword({email: this.email.value}).subscribe((resp) => {
      this.spinner.hide();
      this.forgotRespMessage = resp.message;
    }, (error) => {
      this.error = error.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }
}
