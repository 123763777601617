import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { RootReducerState } from 'src/app/store/reducers';
import * as AccountsActions from 'src/app/store/actions/account.actions';

@Injectable({
  providedIn: 'root',
})
export class AccountingService {
  constructor(
    private httpService: HttpService,
    private store: Store<RootReducerState>
  ) {}

  createSalesTax(data): Observable<any> {
    return this.httpService.post('/setting/saveTax', data);
  }

  updateSalesTax(data): Observable<any> {
    return this.httpService.put('/setting/updateTax', data);
  }

  deleteSalesTax(taxId): Observable<any> {
    return this.httpService.delete(`/setting/deleteTax/${taxId}`);
  }

  listSalesTaxes(businessId): Observable<any> {
    return this.httpService.get(`/setting/getTaxs/${businessId}`);
  }

  getTax(taxId): Observable<any> {
    return this.httpService.get(`/setting/getTax/${taxId}`);
  }

  getAllAccounts(businessId): Observable<any> {
    return this.httpService.get(`/account/getAccounts/${businessId}`);
  }

  setAccountsInStore(accounts): void {
    this.store.dispatch(AccountsActions.SetAccounts({ accounts }));
  }
}
