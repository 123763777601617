import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { matchPassword, valueChanges } from 'src/app/shared/utils/formValidator';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService) { }

  resetRespMessage = '';
  error = '';
  email: FormControl = new FormControl('', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]);
  resetPasswordForm: FormGroup;
  showPassword = false;
  showConfirmPassword = false;
  id = '';

  formErrors = {
    password: '',
    confirmPassword: ''
   };
  resetErrorMessages = {
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters'
    },
    confirmPassword: {
      required: 'Confirm Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
      mustMatch: 'Password did not match'
    }
  };

  ngOnInit(): void {
    this.loadForm();
    this.route.params.subscribe((params) => this.id = params.id);
  }

  loadForm(): void {
    this.resetPasswordForm = this.fb.group({
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
      confirmPassword: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]]
    }, { validators: matchPassword('password', 'confirmPassword')});

    this.resetPasswordForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.resetPasswordForm, {...this.formErrors}, this.resetErrorMessages);
    });
    this.formErrors = valueChanges(this.resetPasswordForm, {...this.formErrors}, this.resetErrorMessages);
  }

  resetPassword(): void{
    this.resetRespMessage = '';
    this.error = '';
    if (this.resetPasswordForm.invalid) {
      this.resetPasswordForm.markAllAsTouched();
      this.formErrors = valueChanges(this.resetPasswordForm, {...this.formErrors}, this.resetErrorMessages);
      return;
    }

    const body = {
      _id: this.id,
      password: this.resetPasswordForm.value.password
    };

    this.spinner.show();
    this.authService.resetPassword(body).subscribe((resp) => {
      this.spinner.hide();
      this.resetRespMessage = resp.message;
      setTimeout(() => { this.router.navigate(['/auth/login']); }, 2500);
    }, (error) => {
      this.error = error?.error?.message || 'Internal Server Error';
      this.spinner.hide();
    });
  }

}
