import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { SalesReducerState } from '../../state/reducers';
import * as CustomerActions from '../../state/actions/customers.action';
import { SalesService } from '../../sales.service';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {

  constructor(private salesService: SalesService,
              private store: Store<SalesReducerState>) { }

  createCustomer(body): Observable<any> {
    return this.salesService.createCustomer(body);
  }
  updateCustomer(data): Observable<any> {
    return this.salesService.updateCustomer(data);
  }
  deleteCustomer(id): Observable<any> {
    return this.salesService.deleteCustomer(id);
  }
  getCustomer(id): Observable<any> {
    return this.salesService.getCustomer(id);
  }
  getCustomerDetails(id): Observable<any> {
    return this.salesService.getCustomerDetails(id);
  }
  getCustomerStats(customerId): Observable<any> {
    return this.salesService.getCustomerStats(customerId);
  }
  getCustomerByPhoneNumber(data): Observable<any> {
    return this.salesService.getCustomerByPhoneNumber(data);
  }
  deleteCustomerInStore(id): void {
    this.store.dispatch(CustomerActions.DeleteCustomer({_id: id}));
  }
  addCustomerInStore(customer): void {
    this.store.dispatch(CustomerActions.AddCusomter({customer}));
  }
  updateCustomerInStore(customer): void {
    this.store.dispatch(CustomerActions.UpdateCustomer({customer}));
  }
}
