import { createAction, props } from '@ngrx/store';
import { User, IUserLogin } from '../models/user';

export const UserLogin = createAction('[User] Login', (userLoginData: IUserLogin) => ({userLoginData}));
export const UserLoginSuccess = createAction('[User] Login Success', props<{ user: User }>());
export const UserLoginFailure = createAction('[User] Login Failure', props<{ error: any }>());
export const UserSignUp = createAction('[User] SignUp', (userSignUpData: IUserLogin) => ({userSignUpData}));
export const UserSignUpSuccess = createAction('[User] SignUp Success', props<{user: any, token: string, refreshtoken: string}>());
export const UserSignUpFailure = createAction('[User] SignUp Failure', props<{error: any}>());
export const SetUser = createAction('[User] Set User', props<{ user: User }>());
export const UserLogout = createAction('[User] Logout');
export const UserEmailRemove = createAction('[User] User Email Remove', props<{email: any}>());
export const UpdateTransactionLimit = createAction('[User] Update Transaction Limit', props<{transactionLimit: any}>());
export const UpdateBusinessLimit = createAction('[User] Update Business Limit', props<{businessLimit: any}>());
export const UpdateAdminLimit = createAction('[User] Update Admin Limit', props<{adminLimit: any}>());
export const UpdateSupportEmailLimit = createAction('[User] Update Support Email Limit', props<{supportEmailLimit: any}>());