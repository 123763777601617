import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-submit',
  templateUrl: 'submit-button.component.html',
})
export class SubmitButtonComponent implements OnInit {
  constructor() {}
  @Input() className = '';
  @Input() value = '';
  @Output() clicked = new EventEmitter();

  ngOnInit() {}
}
