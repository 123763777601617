import { createSelector } from '@ngrx/store';
import { expertDetailsState } from '../reducers';
import * as fromExpertDetails from '../reducers/expertDetails.reducer';

const getExpertDetailsState = (state: expertDetailsState) =>
  state.expertDetails;

export const selectExpertDetails = createSelector(
  getExpertDetailsState,
  fromExpertDetails.getExpertDetails
);
