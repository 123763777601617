<div class="mt-4">
  <app-table-container 
      [tableHeadings]='tableHeadings'
      [tableData]='allFiles'
      [keys]='tableKeys'
      [actionTemplate]='actionTemplate'
      class="task-grid-table">
      <ng-template let-item #actionTemplate>
          <td class="p-2"><p>{{(item.sharedDate | date) || (item.updatedAt | date)}}</p></td>
          <td class="p-2"><p>{{item.uploadedByUserId.firstName + ' ' + item.uploadedByUserId.lastName}}</p></td>
          <td class="p-2 ltr:pr-8 rtl:pl-8">
            <svg (click)="viewFileInNewTab(item.files[0]?._id)" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
            </svg>                          
        </td>
          <td class="p-2 ltr:pr-10 rtl:pl-10">
              <svg (click)='viewOrDownloadFile(item.files[0]?._id)' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-full h-6 cursor-pointer">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75l3 3m0 0l3-3m-3 3v-7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
              </svg>
          </td>
      </ng-template>
  </app-table-container>
</div>
