import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class DiscountCodeService {

  constructor(private http : HttpService) { }

  createDiscountCode(businessId:any, payload:any) {
    return this.http.post(`/discount/createDiscountCode/${businessId}`,payload)
  }
  getDiscountCodes(businessId:any) {
    return this.http.get(`/discount/discountDetails/${businessId}`)
  }
  getDiscountCodeDetails(codeId:any) {
    return this.http.get(`/discount/discountDetailsById/${codeId}`)
  }
  updateDiscountCode(codeId,payload) {
    return this.http.put(`/discount/updateDiscount/${codeId}`,payload)
  }
}
