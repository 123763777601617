import { CurrencyPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrencywithspacePipe } from 'src/app/pipes/currencywithspace.pipe';

@Injectable({
  providedIn: 'root',
})
export class NumberService {
  constructor(private currenyPipe: CurrencyPipe) {}

  decimalSize = new BehaviorSubject(2);
  currency = new BehaviorSubject('INR');
  number = new BehaviorSubject('1.2-2');

  toFixed(num: string | Number): number {
    return Number(Number(num).toFixed(this.decimalSize.value));
  }

  currencier(amount): string {
    const currencyWithSpacePipe = new CurrencywithspacePipe();
    return currencyWithSpacePipe.transform(
      amount,
      this.currency.value,
      'narrowSymbol',
      `1.${this.decimalSize.value}-${this.decimalSize.value}`
    );
  }
}
