<div
  (click)="clicked.emit()"
  [class]="
    className +
    ' ' +
    ' text-base flex justify-center px-4 py-2 rounded-[4px] cursor-pointer items-center'
  "
  style="line-height: 24px;"
>
  {{ value | translate }}
</div>
