import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { CreateTimeSheetDto, DownloadTimesheetPdf, FilterDto, getTimeSheet, MarkAsDto, ResumeTimeSheetDto, StopTimeSheetDto, TimeSheetDto, TimeSheetWeeklyReportDto } from './time-sheet.dto';

@Injectable({
  providedIn: 'root',
})
export class TimeSheetService {
  constructor(private httpService: HttpService) {}

  businessId = ''

  getTimeSheetWeeklyReport(businessId: string, date: string): Observable<TimeSheetWeeklyReportDto> {
    return this.httpService.get(
      `/timeSheet/timesheetWeeklyReport/${businessId}`, {date}
    );
  }

  getTimeSheet(businessId: string, data: getTimeSheet): Observable<any> {
    return this.httpService.get(`/timeSheet/timeSheetDailyReport/${businessId}`, {...data});
  }

  createTimeSheet(body: CreateTimeSheetDto): Observable<any> {
    return this.httpService.post(`/timeSheet/createTimesheet`, body);
  }

  deleteTimeSheet(timeSheetId: string): Observable<any> {
    return this.httpService.delete(`/timeSheet/deleteTimeSheet/${timeSheetId}`);
  }

  filterTimeSheet(businessId: string, body: FilterDto): Observable<any> {
    return this.httpService.post(`/timeSheet/filterTimesheet/${businessId}`, body);
  }

  filterTimeSheetByAdmin(businessId: string, body: FilterDto): Observable<any> {
    return this.httpService.post(`/timeSheet/filterTimesheetByAdmin/${businessId}`, body);
  }

  updateTimeSheet(timeSheetId: string, body: TimeSheetDto): Observable<any> {
    return this.httpService.patch(`/timeSheet/updateTimeSheet/${timeSheetId}`, body);
  }

  markAsReview(timeSheetId: string, body: MarkAsDto): Observable<any> {
    return this.httpService.patch(`/timeSheet/markAsReview/${timeSheetId}`, body);
  }

  resumeTimeSheet(timeSheetId: string, body: ResumeTimeSheetDto): Observable<any> {
    return this.httpService.patch(`/timeSheet/resumeTimeSheet/${timeSheetId}`, body);
  }

  stopTimeSheet(timeSheetId: string, body: StopTimeSheetDto): Observable<any> {
    return this.httpService.patch(`/timeSheet/stopTimeSheet/${timeSheetId}`, body);
  }

  updateActiveTimer(businessId: string, body: StopTimeSheetDto): Observable<any> {
    return this.httpService.patch(`/timeSheet/updateActiveTimer/${businessId}`, body);
  }

  downloadTimesheetPdf(body: DownloadTimesheetPdf): Observable<any> {
    return this.httpService.postPdf(`/timeSheet/downloadTimesheetPdf`, body);
  }

  downloadTimesheetPdfByAdmin(body: DownloadTimesheetPdf): Observable<any> {
    return this.httpService.postPdf(`/timeSheet/downloadTimesheetPdfByAdmin`, body);
  }
  
  getAssignedTask(businessId: string): Observable<any> {
    return this.httpService.get(`/timeSheet/getAssignedTask/${businessId}`, );
  }

  getActiveTimer(businessId: string, date: string): Observable<any> {
    this.businessId = businessId;
    return this.httpService.get(`/timeSheet/getActiveTimer/${businessId}`, {date});
  }
}
