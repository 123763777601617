import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { ProfileManagementService } from '../../profile-management.service';
import { SetUser } from 'src/app/store/actions/user.actions';

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss']
})
export class TwoFactorAuthenticationComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();
  user$: Observable<any>;
  user = null;
  authentication = new FormControl(false);
  confirm2FA: boolean = false;

  constructor(
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private store: Store<RootReducerState>,
    private profileManagementService: ProfileManagementService
  ) {
    this.user$ = store.pipe(select(selectUser))
   }

  ngOnInit(): void {
    this.loadUser();
  }

  loadUser(): void {
    this.user$
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(user => {
      this.user = user;
      this.authentication.setValue(user?.authentication ?? false);
    })
  }

  changeAuthentication(): void {
    this.spinner.show();
    this.profileManagementService.updateAuthentication(this.authentication.value)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.confirm2FA = false;
        this.toastr.success(resp?.message);
        this.store.dispatch(SetUser({user: {...this.user, authentication: this.authentication.value}}));
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
