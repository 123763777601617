<div class="w-full flex flex-col gap-2 new-design-round-border p-4">
  <div class="text-sm">
    {{ "Choose Your Template" | translate }}
  </div>
  <div class="flex-col flex gap-4">
    <div class="overflow-y-auto max-w-full flex gap-4 flex-wrap">
      <div
        class="flex flex-col gap-2 mx-2"
        *ngFor="let template of templateList"
      >
        <div
          class="h-60 w-40 border-solid cursor-pointer"
          (click)="templateClick(template)"
          [ngClass]="{
            'border-[0.5px] border-black': !template.isSelected,
            'border-[6px] border-[#A8C5DA]': template.isSelected
          }"
        >
          <img [src]="template.url" alt="" />
        </div>
        <div class="flex justify-between gap-2">
          <div class="text-sm">
            {{ template.name | translate }}
          </div>
          <div
            class="text-xs text-[#1C1C1CCC] underline cursor-pointer"
            (click)="forPreview = template; openPreviewModal = true"
          >
            {{ "Preview" | translate }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="openPreviewModal">
  <div header class="Poppins-Bold border-b pb-2">
    {{ "Preview of Template" | translate }}
  </div>
  <div content class="flex justify-center">
    <img
      width="350"
      class="border"
      [src]="forPreview.url"
      alt="invoicePreview"
    />
  </div>
  <div footer>
    <app-button
      buttonText="{{ 'Close' | translate }}"
      rounded="true"
      type="third"
      (handleClick)="openPreviewModal = false"
    ></app-button>
  </div>
</app-modal-container>
