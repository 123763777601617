import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { NumplusService } from '../../numplus.service';


@Injectable({
  providedIn: 'root'
})
export class BudgetService {

  constructor(private numplusService: NumplusService) { }

  getBudgetList(): Observable<any>{
    return this.numplusService.getBudgetList()
  }

  createBudget(data): Observable<any> {
    return this.numplusService.createBudget(data);
  }

  getBusinessBudget(data):Observable<any>{
    return this.numplusService.getBusinessBudget(data)
  }

  updateBudget(data):Observable<any>{
    return this.numplusService.updateBudget(data)
  }

  deleteBudget(data):Observable<any>{
    return this.numplusService.deleteBudget(data)
  }

  excelReportOfBudget(data): Observable<any>{
    return this.numplusService.excelReportOfBudget(data)
  }


}
