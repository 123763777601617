import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { getCodesFromSymbol } from 'currency-code-symbol-map/lib/methods';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SalesService } from '../../sales.service';
import { SalesReducerState } from '../../state/reducers';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private store: Store<SalesReducerState>,
              private salesService: SalesService,
              private toaster: ToastrService,
              private spinner: NgxSpinnerService) {}

  getInvoiceNumber(data): Observable<any> {
    return this.salesService.getInvoiceNumber(data);
  }

  getInvoices(currentPage, invoiceType): Observable<any> {
    return this.salesService.getInvoiceList(currentPage, invoiceType);
  }

  createInvoice(data): Observable<any> {
    return this.salesService.createInvoice(data);
  }

  updateInvoice(data): Observable<any> {
    return this.salesService.updateInvoice(data);
  }

  deleteInvoice(id): Observable<any> {
    return this.salesService.deleteInvoice(id);
  }

  getInvoice(id): Observable<any> {
    return this.salesService.getInvoice(id);
  }

  approveInvoice(data): Observable<any> {
    return this.salesService.approveInvoice(data);
  }

  markAsSent(id): Observable<any> {
    return this.salesService.markAsSent(id);
  }

  sendEmail(data): Observable<any> {
    return this.salesService.sendInvoiceEmail(data);
  }

  recordPayment(data): Observable<any> {
    return this.salesService.recordPayment(data);
  }

  refundPayment(data): Observable<any> {
    return this.salesService.refundPayment(data);
  }

  filterInvoice(data): Observable<any> {
    return this.salesService.filterInvoice(data);
  }

  updateRecordPayment(data): Observable<any> {
    return this.salesService.updateRecordPayment(data);
  }

  deleteRecordPayment(data): Observable<any> {
    return this.salesService.deleteRecordPayment(data);
  }

  convertCurrency(data): Observable<any> {
    return this.salesService.convertCurrency(data);
  }

  generatePaymentLink(data): Observable<any> {
    return this.salesService.generatePaymentLink(data);
  }

  createInvoicePdf(data): Observable<any> {
    return this.salesService.createInvoicePdf(data);
  }

  updatePaypalInvoice(data): Observable<any> {
    return this.salesService.updatePaypalInvoice(data);
  }

  generatePaypalData(invoiceData): any {
    console.log('Invocie data', invoiceData);
    const paypalInvoiceData = {
      detail: {
        invoice_number: `${new Date().getTime() % 1000000}-${invoiceData.invoiceNumber}`,
        invoice_date: moment(invoiceData.invoiceDate.split('T')[0]).subtract(1, 'd').format('YYYY-MM-DD'),
        currency_code: 'USD',
        term: invoiceData.notesTerms
      },
      invoicer: {
          name: {
              full_name: invoiceData.businessDetails.businessId.companyName
          },
          address: {
              address_line_1: invoiceData.businessDetails.businessId.address.addressLineFirst ?? '',
              address_line_2: invoiceData.businessDetails.businessId.address.addressLineSecond ?? '',
              admin_area_1: invoiceData.businessDetails.businessId.address.state ?? '',
              admin_area_2: invoiceData.businessDetails.businessId.address.city ?? '',
              admin_area_3: invoiceData.businessDetails.businessId.country ?? ''
          },
          email_address: invoiceData.businessDetails.businessId.paypalEmail,
      },
      primary_recipients: [
          {
              billing_info: {
                  name: {
                      full_name: invoiceData.customerDetails.customerId.customerName
                  },
                  address: {
                      address_line_1: invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1 || '' ,
                      address_line_2: invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2 ?? '',
                      admin_area_1: invoiceData?.customerDetails?.customerId?.billingAddress?.state ?? '',
                      admin_area_2: invoiceData?.customerDetails?.customerId?.billingAddress?.city ?? '',
                      admin_area_3: invoiceData?.customerDetails?.customerId?.billingAddress?.country ?? '',
                      postal_code: `${invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}` ?? ''
                  },
                  email_address: invoiceData?.customerDetails?.customerId?.email
              },
              shipping_info: {
                  name: {
                      full_name: invoiceData.customerDetails.customerId.customerName
                  },
                  address: {
                    address_line_1: invoiceData.customerDetails.customerId.shippingDetails.addressLine1,
                    address_line_2: invoiceData.customerDetails.customerId.shippingDetails.addressLine2 ?? '',
                    admin_area_1: invoiceData.customerDetails.customerId.shippingDetails.state ?? '',
                    admin_area_2: invoiceData.customerDetails.customerId.shippingDetails.city ?? '',
                    admin_area_3: invoiceData.customerDetails.customerId.shippingDetails.country ?? '',
                    postal_code: `${invoiceData.customerDetails.customerId.shippingDetails.postalCode}` ?? ''
                  }
              }
          }
      ],
      items: [],
      configuration: {
          tax_calculated_after_discount: true,
          tax_inclusive: false,
          partial_payment: {
            allow_partial_payment: true,
          },
      }
    };
    invoiceData.items.forEach((item) => {
      paypalInvoiceData.items.push({
        name: item.item,
        quantity: item.unit,
        unit_amount: {
          currency_code: 'USD',
          value: item.price
        },
        tax: {
          name: item.tax.length ? item.tax[0].taxName : 'Tax',
          percent: item.tax.length ? item.tax[0].tax : 0
        },
        discount: {
          percent: invoiceData.discount || '0'
        },
        unit_of_measure: 'QUANTITY'
      });
    });
    if (invoiceData.currencyDetails.currencySymbol !== '$') {
      paypalInvoiceData.items = paypalInvoiceData.items.map((item) => {
        return {
          ...item,
          unit_amount: {
            currency_code: 'USD',
            value: (item.unit_amount.value / invoiceData.currencyDetails.currencyValue)
          }
        };
      });
    }
    return paypalInvoiceData;
  }
}
