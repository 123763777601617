<div class="flex justify-center p-8 overflow-scroll max-h-full">
  <div class="w-full flex flex-col space-y-4">
    <div class="relative">
      <ng-container *ngIf="view; else addDataTable">
        <div class="flex gap-x-2">
          <form [formGroup]="firstForm" class="w-full">
            <div class="flex items-center gap-4">
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium text-base">{{
                  "Name" | translate
                }}</label>
                <input
                  name="name"
                  formControlName="budgetName"
                  type="text"
                  Placeholder="Enter name"
                  class="new-input"
                />
                <div class="text-red-500" *ngIf="firstFormError.budgetName">
                  {{ firstFormError.budgetName }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="Poppins-Medium text-base">{{
                  "Financial Year" | translate
                }}</label>
                <ng-select
                  [items]="newPeriod"
                  class="new-dropdown"
                  bindLabel="name"
                  bindValue="value"
                  formControlName="period"
                  [clearable]="false"
                  placeholder="Select Financial Period"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div class="text-red-500" *ngIf="firstFormError.period">
                  {{ firstFormError.period }}
                </div>
              </div>
              <div class="mt-8">
                <app-button
                  [buttonText]="'Next' | translate"
                  type="primary"
                  (handleClick)="getNameAndYearOfBudget()"
                ></app-button>
              </div>
            </div>
          </form>
        </div>

        <div class="flex flex-col my-4">
          <div class="p-2 mx-5 border-b border-[#1C1C1C33]">
            <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
              {{ "Income" | translate }}
            </p>
          </div>
          <div class="px-10">
            <div class="max-w-[90rem] overflow-x-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 min-w-[10rem] sticky border-[#1C1C1C33] border-b top-0 bg-input-light z-30 left-0"
                      *ngFor="let tabcol of tableIncomeCol"
                      [ngClass]="{ 'left-0 z-30': tabcol === 'INCOME' }"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let income of incomeData; index as i"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ income?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-center border border-[#1C1C1C11]"
                      *ngFor="let in of counter(13); let j = index"
                    >
                      {{ "" }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let in of counter(13); let j = index"
                    >
                      {{
                        "0.00"
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="p-2 mx-5 !mt-12 border-b border-[#1C1C1C33]">
            <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
              {{ "Expenses" | translate }}
            </p>
          </div>
          <div class="px-10">
            <div class="max-w-[90rem] overflow-y-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 sticky min-w-[10rem] border-[#1C1C1C33] border-b top-0 bg-input-light z-30 left-0"
                      *ngFor="let tabcol of tableExpensesCol"
                      [ngClass]="{ 'left-0 z-30': tabcol === 'EXPENSES' }"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let expense of expensesData; index as exp"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ expense?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-center border border-[#1C1C1C11]"
                      *ngFor="let in of counter(13); let j = index"
                    >
                      {{ "" }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let in of counter(13); let j = index"
                    >
                      {{
                        "0.00"
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-template #addDataTable>
        <div class="flex gap-x-2">
          <div class="sticky left-0">
            <form [formGroup]="secondForm">
              <div class="flex gap-4">
                <div class="">
                  <label class="Poppins-Bold">{{ "Name" | translate }}</label>
                  <input
                    name="name"
                    type="text"
                    formControlName="name"
                    class="new-input"
                    disabled
                  />
                </div>
                <div class="">
                  <label class="Poppins-Bold">{{ "Year" | translate }}</label>
                  <input
                    name="name"
                    type="text"
                    formControlName="year"
                    class="new-input"
                    disabled
                  />
                </div>
              </div>
            </form>
          </div>
        </div>

        <ng-template [ngIf]="createBudgetModal">
          <div
            #createModal
            [style]="ycod"
            class="bg-white overflow-x-scroll shadow-lg sticky z-10 h-14"
            id="createModal"
          >
            <form [formGroup]="yearBudget" class="max-w-[90rem]">
              <table class="">
                <tr>
                  <td
                    class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                  >
                    {{ accountText | translate }}
                  </td>
                  <td *ngFor="let data of newMonthsName">
                    <input
                      name="{{ data }}"
                      type="number"
                      class="new-input !w-52"
                      placeholder="{{ data }}"
                      formControlName="{{ data }}"
                    />
                  </td>
                  <td class="sticky right-0">
                    <div class="flex flex-row gap-1 bg-white p-2">
                      <app-button
                        [buttonText]="(update ? 'Update' : 'Save') | translate"
                        type="primary"
                        (handleClick)="update ? updateBudget() : addBudget()"
                      ></app-button>
                      <app-button
                        [buttonText]="'Cancel' | translate"
                        type="primary"
                        (handleClick)="closeCreateBudget()"
                      ></app-button>
                    </div>
                  </td>
                </tr>
              </table>
            </form>
          </div>
        </ng-template>

        <div class="flex my-4" [style]="tablemargin">
          <div class="">
            <div class="max-w-[90rem] overflow-x-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-input-light min-w-[10rem]"
                      *ngFor="let tabcol of tableIncomeCol"
                      [ngClass]="{ 'left-0 z-30': tabcol === 'INCOME' }"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    class="h-12 mb-4 py-4 px-6 hover:bg-gray-100"
                    *ngFor="let income of incomeData; index as incomeIndex"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ income?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-right border border-[#1C1C1C11]"
                      *ngFor="let data of getDataArray(income?.accountName)"
                      (click)="onEdit(income, $event)"
                    >
                      {{
                        data?.amount
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let data of netotalIncome"
                    >
                      {{
                        data
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="max-w-[90rem] overflow-x-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-input-light min-w-[10rem]"
                      *ngFor="let tabcol of tableExpensesCol"
                      [ngClass]="{ 'left-0 z-30': tabcol === 'EXPENSES' }"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let expense of expensesData; index as exp"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ expense?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-center border border-[#1C1C1C11]"
                      *ngFor="let data of getDataArray2(expense?.accountName)"
                      (click)="onEdit(expense, $event)"
                    >
                      {{
                        data?.amount
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let data of netotalExpense"
                    >
                      {{
                        data
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
