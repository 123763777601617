import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SalesService } from '../../sales/sales.service';
import { ToastrModule, ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-handle-payment-redirection',
  templateUrl: './handle-payment-redirection.component.html',
  styleUrls: ['./handle-payment-redirection.component.scss']
})
export class HandlePaymentRedirectionComponent implements OnInit {

  response;

  constructor(
    private route: ActivatedRoute,
    private salesService: SalesService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(({transactionId = ''}) => {
      if(transactionId){
        this.spinner.show();
        this.salesService.checkPaymentStatus(transactionId)
        .subscribe(resp => {
          if(resp?.success){
            this.response = resp?.data
            setTimeout(() => {
              window.close();
            }, 3000);
          }
          else {
            this.toastr.error(resp?.message)
          }
          this.spinner.hide()
        }, (err) => {
          this.toastr.error(err?.error?.message);
          this.spinner.hide()
        })
      }
    });
  }

  close() {
   window.close() 
  }

}
