import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { ReferrralService } from 'src/app/shared/services/referrral.service';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-subscription-plans',
  templateUrl: './subscription-plans.component.html',
  styleUrls: ['./subscription-plans.component.scss']
})
export class SubscriptionPlansComponent implements OnInit {

  constructor(private subscriptionService: SubscriptionService,
              private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService,
              private store: Store<RootReducerState>,
              private referralService: ReferrralService,
              private userService: UserService) {
                this.user$ = store.pipe(select(selectUser))
               }
              @ViewChild('Invoicing') Invoicing: TemplateRef<any>;
              @ViewChild('Lite') Lite: TemplateRef<any>;
              @ViewChild('Essential') Essential: TemplateRef<any>;
              @ViewChild('Starter') Starter: TemplateRef<any>;

  subscriptionPlans = [];
  referalCodeExist;
  user;
  user$: Observable<any>
  ngOnInit(): void {
    this.getUser();
  }

  getUser(): void {
    this.spinner.show();
    let businessId = localStorage.getItem('selectedBusiness');
    this.userService.getUserDetails(businessId).subscribe((resp) => {
      const user = resp.data;
      this.user = user;
      this.referalCodeExist = user?.referralCode && !user?.subscription?.subscriptionId ? true : false;
      this.referralService.getReferralCode(this.user?.referralCode).subscribe(resp => {
        if(resp?.success){
          const discountPercentage = resp?.data?.percentage || 0;
          this.loadSubscriptions(discountPercentage);
        }
        else {
          this.referalCodeExist = false
          this.loadSubscriptions();
        }
      })
    })
  }

  loadSubscriptions(discountPercentage?): void {
    this.subscriptionService.getPlans().subscribe((resp) => {
      if (resp.success) {
        this.subscriptionPlans = resp.data.filter(plan => !plan.isHidden);

        this.subscriptionPlans = this.subscriptionPlans.filter(({isDiscount}) => {
          return this.referalCodeExist? isDiscount : !isDiscount
        })
        if(this.referalCodeExist){
          this.subscriptionPlans = this.subscriptionPlans.filter(({percentage}) => {
            return discountPercentage === percentage
          })
        }
        
      this.spinner.hide();
      } else {
        this.spinner.hide();
        this.toaster.error('Something went wrong');
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Something went wrong');
    });
    
  }

  subscribePlan(plan): void {
    const body = {
      id: plan._id,
      planId: plan.paypalData.planId,
      expiryDate: null
    };
    this.spinner.show();
    this.subscriptionService.getSubscriptionLink(body).subscribe(resp => {
      this.spinner.hide();
      const url = resp.data.links[0].href;
      window.location.href = url;
    }, error => {
      this.spinner.hide();
      this.toaster.error('Something went wrong');
      console.log(error);
    });
  }

  injectPlan(plan){
    let body = {
      planDetails: plan,
    }
    this.spinner.show();
    this.subscriptionService.subscribeStarter(body).subscribe(resp => {
      console.log(resp)
      this.spinner.hide();
      this.router.navigate(['/auth/personal-details']);

    }, error => {
      this.spinner.hide();
      this.toaster.error('Something went wrong');
      console.log(error);
    });

  }


  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.userService.logout();
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
      this.toaster.error('Something went wrong');
    })
  }

}
