import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, tap } from 'rxjs/operators';
import { AuthService } from 'src/app/modules/auth/auth.service';
import * as UserActions from '../actions/user.actions';


@Injectable()
export class UserEffects {
  userLogin$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UserActions.UserLogin),
      mergeMap((login) => this.authService.userLogin(login.userLoginData).pipe(
          map((resp) => UserActions.UserLoginSuccess({user: {...resp.data, token: resp.token, refreshtoken: resp.refreshtoken}})),
          catchError(error => of(UserActions.UserLoginFailure({error: error?.error?.message || 'Internal Server Error'})))
        )
      )
    );
  });

  userSignUp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserSignUp),
      mergeMap((data) => this.authService.userSignup(data.userSignUpData).pipe(
          map((resp) => UserActions.UserSignUpSuccess({user: resp?.data, token: resp.token, refreshtoken: resp.refreshtoken})),
          catchError(error => of(UserActions.UserSignUpFailure({error: error?.error?.message || 'Internal Server Error'})))
        )
      )
    )
  );

  userSignUpSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserSignUpSuccess),
      tap(({user, token, refreshtoken}) => this.authService.onSignupSuccess(user, token, refreshtoken))
    ), { dispatch: false }
  );

  userLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(UserActions.UserLoginSuccess),
      tap(({user}) => this.authService.onLoginSuccess(user))
    ), { dispatch: false }
  );

  // setUser$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(UserActions.SetUser),
  //     tap(({user}) => this.authService.onLoginSuccess(user))
  //   ), { dispatch: false }
  // );

  constructor(private actions$: Actions, private authService: AuthService) {}

}


