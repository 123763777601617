import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { EstimatesService } from '../../sales/components/estimates/estimates.service';
import { OpenService } from '../open.service';

@Component({
  selector: 'app-estimate',
  templateUrl: './estimate.component.html',
  styleUrls: ['./estimate.component.scss']
})
export class EstimateComponent implements OnInit {

  constructor(private estimateService: EstimatesService,
              private route: ActivatedRoute,
              private languageService: LanguageService,
              private spinner: NgxSpinnerService,
              private openService: OpenService,
              private numberService: NumberService,
              private businessService: BusinessService,
              private router: Router) { }

  estimateData;
  customizationSettings;
  fieldNames = {
    type: 'Invoice',
    estimateNumber: 'Estimate Number',
    date: 'Estimate Date',
    expiryDate: 'Expiry Date',
    purchaseOrder: 'Reference Number',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Memo',
    itemName: 'Item Name',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Sent To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By'
  };
  number = '1.2-2';
  decimalSize = 2;
  currencyDetails = {
    currency: ''
  }
  ngOnInit(): void {
    this.route.params.subscribe(({businessId, estimateId}) => {
      if (businessId && estimateId) {
        this.loadData(businessId, estimateId);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  loadData(businessId, estimateId): void {
    console.log(estimateId);
    this.openService.getInvoiceCustomizationSettings(businessId).subscribe(resp => {
      if(resp?.success){
        this.customizationSettings = resp.data
      }
    })
    this.businessService.getBusinessDetailsById(businessId).subscribe((resp) => {
      if (resp?.success) {
        const businessData = resp.data;
        const decimalSize = businessData?.decimalSize ?? 2;
        this.decimalSize = 2;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
        this.number = `1.${decimalSize}-${decimalSize}`;
        this.currencyDetails.currency = businessData.currency;
        this.numberService.currency.next(businessData.currency)
      }
    })
    this.estimateService.getEstimate(estimateId).subscribe((resp) => {
      console.log(resp.data);
      this.estimateData = resp.data;
    }, error => {
      console.log(error);
    });
  }

  downloadPdf(): void {
    const body = {
      config: {
        data: {
          ...this.estimateData,
          items: this.estimateData.items.map(item => {
            return {...item,
              unitPrice: this.numberService.currencier(item?.unitPrice),
              totalCost: this.numberService.currencier((item.unitPrice * item.quantity))
            }
          }),
          expiryDate: moment(this.estimateData.expiryDate).format('DD-MM-YYYY'),
          date: moment(this.estimateData.date).format('DD-MM-YYYY'),
          subTotal: this.numberService.currencier(this.estimateData.subTotal),
          tax: this.numberService.currencier(this.estimateData.tax),
          totalAmount: this.numberService.currencier(this.estimateData.totalAmount),
          currencyDetails: { currencySymbol: getCurrencySymbol(this.estimateData?.businessId?.currency, 'narrow') },
          companylogo: this.customizationSettings.companyLogo
        },
        direction: localStorage.getItem('NuMetric|lang')=== 'en'? 'ltr': 'rtl',
        showTax: this.estimateData.tax ? true : false,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinner.show();
    this.estimateService.createEstimatePdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Estimate ${this.estimateData.estimateNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, (error) => {
      this.spinner.hide();
    });
  }

}
