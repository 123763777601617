import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { ReferrralService } from 'src/app/shared/services/referrral.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ResetErrors } from 'src/app/store/actions/reset.actions';
import { UserSignUp } from 'src/app/store/actions/user.actions';
import { IUserLogin } from 'src/app/store/models/user';
import { RootReducerState } from 'src/app/store/reducers';
import {
  selectUserError,
  selectUserLoading,
} from 'src/app/store/selectors/user.selectors';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  signupForm: FormGroup;
  signupRespMessage = '';
  signupError$: Observable<any>;
  signupLoading$: Observable<boolean>;
  showPassword = false;
  showModal: boolean = false;
  country: any = 'Global';
  redirectLink: string = '';
  countries = [
    { countryName: 'Global', _id: 'Global' },
    { countryName: 'Jordan', _id: 'Jordan' },
    { countryName: 'Saudi Arabia', _id: 'Saudi Arabia' },
  ];
  formErrors = {
    email: '',
    password: '',
    country: '',
  };
  signupErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format',
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
    },
    country: {
      required: 'Please select country',
    },
  };

  constructor(
    private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private referralService: ReferrralService,
    private toast: ToastrService,
    private router: Router
  ) {
    this.signupError$ = this.store.pipe(select(selectUserError));
    this.signupLoading$ = this.store.pipe(select(selectUserLoading));
  }

  ngOnInit(): void {
    this.loadForm();
    this.store.dispatch(ResetErrors());
    this.signupForm.get('country').setValue(this.country);
    this.signupLoading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
  }

  loadForm(): void {
    this.signupForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(128),
        ],
      ],
      country: ['', [Validators.required]],
      referralCode: [''],
      signupLanguage: [localStorage.getItem('NuMetric|lang')]
    });
    this.signupForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.signupErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.signupForm,
      { ...this.formErrors },
      this.signupErrorMessages
    );
  }

  signup(): void {
    this.signupRespMessage = '';
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.signupErrorMessages
      );
      return;
    }
    if (this.signupForm.value.referralCode) {
      this.spinner.show();
      // this.referralService.getReferralCode(this.signupForm.value.referralCode)
      this.referralService.getExpertReferralCode().subscribe((resp) => {
        // if(!resp?.success){
        //   this.spinner.hide()
        //   this.toast.error('Invalid Referral Code');
        // }
        // else {
        //   this.spinner.hide()
        //   const user: IUserLogin = {...this.signupForm.value};
        //   this.store.dispatch(UserSignUp(user));
        // }
        if (resp.data.length > 0) {
          console.log('check1');

          const codeExists = resp.data.filter(
            (item) => item.refCode === this.signupForm.value.referralCode
          );
          if (codeExists.length > 0) {
            this.spinner.hide();
            console.log('code');

            let data = { ...this.signupForm.value };
            data.expertReferralCode = this.signupForm.value.referralCode;
            const user: IUserLogin = { ...data };
            this.store.dispatch(UserSignUp(user));
          } else {
            this.spinner.hide();
            this.toast.error('Invalid Referral Code');
          }
        } else {
          this.spinner.hide();
          this.toast.error('Invalid Referral Code');
        }
      });
    } else {
      const user: IUserLogin = { ...this.signupForm.value };
      this.store.dispatch(UserSignUp(user));
    }
  }

  changeCountry(event): void {
    this.country = event._id;
    this.signupForm.get('country').setValue(event._id);
    if (this.country == 'Jordan' || this.country == 'Saudi Arabia') {
      this.showModal = true;
      this.redirectLink =
        this.country == 'Jordan' ? 'jo.numetric.work' : 'sa.numetric.work';
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
  }
}
