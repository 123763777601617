import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateCustomerComponent } from './components/create-customer/create-customer.component';
import { CustomersListComponent } from './components/customers-list/customers-list.component';
import { RouterModule, Routes } from '@angular/router';
import { ViewCustomerComponent } from './components/view-customer/view-customer.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AccountingModule } from 'src/app/modules/settings/settings-modules/accounting/accounting.module';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';

const routes: Routes = [
  {
    path: '',
    component: CustomersListComponent
  },
  {
    path: 'customers-list',
    component: CustomersListComponent
  },
  {
    path: 'create-customer',
    component: CreateCustomerComponent
  },
  {
    path: 'view-customer',
    component: ViewCustomerComponent
  },
];

@NgModule({
  declarations: [
    CreateCustomerComponent,
    CustomersListComponent,
    ViewCustomerComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClickOutsideModule,
    FormsModule,
    NgSelectModule,
    TranslateModule,
    ReactiveFormsModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class CustomersModule { }
