import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ChatsHomeComponent } from './component/chats-home/chats-home.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { QuillModule } from 'ngx-quill';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { MessageFilterPipe } from './pipes/message-filter.pipe';
// import { NgxEditorModule } from 'ngx-editor';


const router: Routes = [
  {
    path:'',
    component: ChatsHomeComponent
  }
]

@NgModule({
  declarations: [ChatsHomeComponent, MessageFilterPipe],
  imports: [
    RouterModule.forChild(router),
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    DirectivesModule,
    Ng2SearchPipeModule,
    QuillModule.forRoot()
  ]
})

export class ChatsModule { }
