import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { BusinessesComponent } from './businesses.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SettingsSharedModule } from '../../shared/shared.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
const routes: Routes = [
  {
    path: '',
    component: BusinessesComponent
  }
];

@NgModule({
  declarations: [
    BusinessesComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    SettingsSharedModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(routes)
  ]
})
export class BusinessesModule { }
