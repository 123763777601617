
<div permissionCheck moduleType="dashboard"  class="flex min-h-screen">
  <div class="w-full h-screen overflow-y-auto no-scrollbar">
      <!-- <router-outlet></router-outlet> -->
      <div class="w-full flex flex-col space-y-4">
          <div class="flex items-center ps-5 pt-4 pe-7 justify-between">
              <div class="">
                  <h1 class="text-2xl Gilroy-Bold inline-flex items-center">
                      <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                        </svg>
                      {{'Dashboard' | translate}}
                  </h1>
              </div>
              <div class="flex gap-4 items-center">
                  <!-- <app-dropdown #notificationTray (clickOutside)='notificationTray.open = false' class="flex notification-tray">
                      <span button (click)='notificationTray.open = true' class="relative inline-block">
                          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                              <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 0 0 5.454-1.31A8.967 8.967 0 0 1 18 9.75V9A6 6 0 0 0 6 9v.75a8.967 8.967 0 0 1-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 0 1-5.714 0m5.714 0a3 3 0 1 1-5.714 0" />
                          </svg>
                          <span class="absolute -top-2 ltr:-right-1 rtl:-left-1 text-[8px] text-white bg-red-500 w-4 h-4 flex items-center justify-center rounded-full">{{notificationsCount}}</span>
                      </span>
                      <div options class="py-4">
                          <h3 class="text-sm px-4 Gilroy-SemiBold">
                              {{ "Notifications" | translate }}
                          </h3>
                          <ng-container *ngIf="allNotifications?.length > 0; else noNotification">
                              <ng-container *ngFor="let item of allNotifications">
                                  <div (click)="goToNotification(item)" [ngClass]="{'bg-blue-200': !item.seen}" class="px-4 cursor-pointer border-t">
                                      <p id="menu-item-0" class="text-dark-700 py-1 text-sm text-start rounded-xl">{{item.message}}</p>
                                      <p class="text-dark-700 text-xs text-start py-1 rounded-xl">{{item.createdAt | date : 'MMM d, y, h:mm a'}}</p>
                                  </div>
                              </ng-container>
                          </ng-container>
                          <ng-template #noNotification><span class="p-4">No data available</span></ng-template>
                      </div>
                  </app-dropdown> -->
                  <app-dropdown appAccessControl moduleType="dashboard" accessType="edit" #dropdown class="">  
                      <button appSubscriptionCheck featureToCheck='transactionLimit' button (click)='dropdown.open = true' (clickOutside)='dropdown.open = false' class="inline-block rounded-xl py-2 px-6 h-10 text-sm bg-gradient text-white">{{'Create a new' | translate}}</button>
                      <div options class="absolute mt-2 w-full rounded-xl shadow-lg bg-gray-50 focus:outline-none" >
                          <a appAccessControl moduleType='sales' accessType='edit' routerLink='/sales/invoices/create-invoice' id="menu-item-0" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 text-center rounded-xl">{{'Invoice' | translate }}</a>
                          <a appAccessControl moduleType='sales' accessType='edit' routerLink='/sales/estimates/create-estimate' id="menu-item-0" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 text-center rounded-xl">{{'Estimate' | translate }}</a>
                          <a appAccessControl moduleType='purchase' accessType='edit' routerLink='/purchases/bills/create-bill' id="menu-item-0" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 text-center rounded-xl">{{'Bill' | translate }}</a>
                          <a appAccessControl moduleType='purchase' accessType='edit' routerLink='/purchases/purchase-order/create-purchase-order' id="menu-item-0" class="text-dark-700 cursor-pointer block px-4 py-2 text-sm hover:bg-gray-200 text-center rounded-xl">{{'Purchase Order' | translate }}</a>
                      </div>
                  </app-dropdown>
              </div>
          </div>
          <div class="flex">
              <div class="w-3/5 px-5">
                  <div class="flex items-center justify-between mb-3">
                      <h1 class="text-xl Gilroy-Bold">{{'Cash Flow' | translate}}</h1>
                      <ng-select
                          [items]='filters'
                          bindLabel='text'
                          [searchable]=false
                          [clearable]="false"
                          [(ngModel)]='selectedFilter'
                          (change)='cashFlowChange($event)'
                      ></ng-select>
                  </div>
                  <div class="bg-proile p-5 mb-3 rounded-lg" style="display: block">
                      <p>{{'Cash flow message' | translate}}</p>
                      <canvas baseChart 
                      [datasets]="barChartData" 
                      [labels]="barChartLabels"
                      [options]="barChartOptions" 
                      [legend]="barChartLegend" 
                      [colors]="chartColors"
                      [chartType]="barChartType">
                      </canvas>
                  </div>
                  <div class="flex items-center justify-between mb-3">
                      <h1 class="text-xl Gilroy-Bold">{{'Profit And Loss' | translate}}</h1>
                      <ng-select
                          [items]='filters'
                          bindLabel='text'
                          [searchable]=false
                          [clearable]="false"
                          [(ngModel)]='profitLossSelectedFilter'
                          (change)='profitLossChange($event)'
                      ></ng-select>
                  </div>
                  <div class="bg-proile p-5 mb-3 rounded-lg" style="display: block">
                      <p>{{'Income and expenses' | translate}}</p>
                      <canvas baseChart 
                      [datasets]="profitLossChartData" 
                      [labels]="profitLossChartLabels"
                      [options]="profitLossChartOptions"
                      [legend]="profitLossChartLegend" 
                      [chartType]="profitLossChartType">
                      </canvas>
                  </div>
                  <h1 class="text-xl Gilroy-Bold inline-flex items-center mt-3 mb-3">{{'Payable & Owing' | translate}}</h1>
                  <div class="bg-proile mb-3 p-5 rounded-lg">
                      <div class="flex">
                          <div class="w-1/2 me-4">
                              <div class="Gilroy-Medium text-lg pb-1 mb-1 border-b-2">{{'Invoices payable to you' | translate}}</div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'1-30 days overdue' | translate}} </div>
                                  <div>{{payable.firstPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'31-60 days overdue' | translate}}</div>
                                  <div>{{payable.secondPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'61-90 days overdue' | translate}}</div>
                                  <div>{{payable.thirdPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex justify-between">
                                  <div>> {{'90 days overdue' | translate}}</div>
                                  <div>{{payable.lastPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                          </div>
                          <div class="w-1/2">
                              <div class="Gilroy-Medium text-lg mb-1 pb-1 border-b-2">{{'Bill you owe' | translate}}</div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'1-30 days overdue' | translate}}</div>
                                  <div>{{owing.firstPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'31-60 days overdue' | translate}}</div>
                                  <div>{{owing.secondPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex pb-1 justify-between">
                                  <div>{{'61-90 days overdue' | translate}}</div>
                                  <div>{{owing.thirdPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                              <div class="flex pb-1 justify-between">
                                  <div>> {{'90 days overdue' | translate}}</div>
                                  <div>{{owing.lastPhase | currency : currencyName : 'symbol-narrow' : number}}</div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <!-- <h1 class="text-xl Gilroy-Bold inline-flex mb-3 mt-3 items-center">Expense Breakdown</h1>
                  <div>
                      <div class="bg-proile mr-8 p-5 rounded-lg" style="display: block">
                          <canvas baseChart 
                          [data]="doughnutChartData" 
                          [colors]="chartColors1"
                          [chartType]="doughnutChartType">
                      </canvas>
                      </div>
                  </div> -->
              </div>
              <div class="w-2/5">
                  <h1 class="text-xl Gilroy-Bold inline-flex items-center mb-3">{{'Overdue invoices & bills' | translate}}</h1>
                  <div class="bg-proile me-8 p-5 rounded-lg">
                      <h3 class="Gilroy-Medium text-lg">{{'Overdue invoices' | translate}} </h3>
                      <table class="w-full">
                          <thead>
                              <tr class="border-b-2">
                                 <td class="rtl:text-right ltr:text-left Gilroy-Medium">{{'Invoice Name' | translate}} </td>
                                 <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{'Due Amount' | translate}} </td>
                              </tr>
                          </thead>

                          <tbody>
                              <tr *ngFor='let invoice of overdueInvoices'>
                                  <td>{{invoice.customers.customerName}}</td>
                                  <td class="ltr:text-right rtl:text-left" *ngIf='invoice.dueAmount'>{{invoice.dueAmount | currency : currencyName : 'symbol-narrow' : number}}</td>
                                  <td class="ltr:text-right rtl:text-left" *ngIf='!invoice.dueAmount'>{{'N/A' | translate}} </td>
                              </tr>
                              <tr *ngIf='overdueInvoices.length==0'>
                                  <td class="text-center py-2" colspan="2">{{'No Data found' | translate}} </td>
                              </tr>
                          </tbody>
                      </table>

                      <h3 class="mt-6 Gilroy-Medium text-lg">{{'Overdue Bills' | translate}} </h3>
                      <table class="w-full">
                          <thead>
                              <tr class="border-b-2">
                                  <td class="rtl:text-right ltr:text-left Gilroy-Medium">{{'Bill Name' | translate}} </td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{'Amount' | translate}} </td>
                              </tr>
                          </thead>

                          <tbody>
                              <tr *ngFor='let bill of overdueBills'>
                                  <td>{{bill.vendor.vendorName}}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium" *ngIf='bill.totalAmount'>{{bill.totalAmount | currency : currencyName : 'symbol-narrow' : number}}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium" *ngIf='!bill.totalAmount'>{{'N/A' | translate}} </td>
                              </tr>
                              <tr *ngIf='overdueBills.length==0'>
                                  <td colspan="2" class="text-center py-2">{{'No Data found' | translate}} </td>
                              </tr>
                          </tbody>
                      </table>
                  </div>
                  
                  <h1 class="text-xl Gilroy-Bold inline-flex items-center mt-3 mb-3">{{'Net Income' | translate }}</h1>
                  <div class="bg-proile me-8 p-5 rounded-lg">
                      <table class="w-full">
                          <thead>
                              <tr class="border-b-2">
                                  <td class="rtl:text-right ltr:text-left Gilroy-Medium">{{'Fiscal Year' | translate}} </td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{previousYear}}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{year}}</td>
                              </tr>
                          </thead>

                          <tbody>
                              <tr>
                                  <td>{{'Income' | translate}} </td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.previousYear.income | currency : currencyName : 'symbol-narrow' : number}}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.currentYear.income | currency : currencyName : 'symbol-narrow' : number}}</td>
                              </tr>
                              <tr>
                                  <td>{{'Expense' | translate}} </td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.previousYear.outcome | currency : currencyName : 'symbol-narrow' : number }}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.currentYear.outcome | currency : currencyName : 'symbol-narrow' : number}}</td>
                              </tr>
                              <tr>
                                  <td>{{'Net Income' | translate}} </td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.previousYear.income - netIncome.previousYear.outcome | currency : currencyName : 'symbol-narrow' : number }}</td>
                                  <td class="ltr:text-right rtl:text-left Gilroy-Medium">{{netIncome.currentYear.income - netIncome.currentYear.outcome | currency : currencyName : 'symbol-narrow' : number }}</td>
                              </tr>

                          </tbody>
                      </table>
                  </div>
              </div>

          </div>

      </div>
  </div>
</div>

<!-- <div routeToSubscription fromComponent='dashboard' class="absolute bottom-6 z-[29] w-[400px] left-0 right-0 m-auto">
  <app-send-to-sub   ></app-send-to-sub>
</div> -->