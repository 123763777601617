import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { EstimatesListComponent } from './components/estimates-list/estimates-list.component';
import { CreateEstimateComponent } from './components/create-estimate/create-estimate.component';
import { ViewEstimateComponent } from './components/view-estimate/view-estimate.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';

const routes: Routes = [
  {
    path: '',
    component: EstimatesListComponent
  },
  {
    path: 'estimate-list',
    component: EstimatesListComponent
  },
  {
    path: 'create-estimate',
    component: CreateEstimateComponent
  },
  {
    path: 'view-estimate',
    component: ViewEstimateComponent
  }
];

@NgModule({
  declarations: [
    EstimatesListComponent,
    CreateEstimateComponent,
    ViewEstimateComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class EstimatesModule { }
