import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SalesComponent } from './sales.component';
import { RouterModule, Routes } from '@angular/router';
import { InvoiceCustomizationComponent } from './components/invoice-customization/invoice-customization.component';
import { PaymentSettingsComponent } from './components/payment-settings/payment-settings.component';
import { GeneralSettingsComponent } from './components/invoice-customization/components/general-settings/general-settings.component';
import { InvoiceSettingsComponent } from './components/invoice-customization/components/invoice-settings/invoice-settings.component';
import { ReminderSettingsComponent } from './components/invoice-customization/components/reminder-settings/reminder-settings.component';
import { InvoiceColumnSettingsComponent } from './components/invoice-customization/components/invoice-column-settings/invoice-column-settings.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { EstimateSettingsComponent } from './components/invoice-customization/components/estimate-settings/estimate-settings.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ImageCropperModule } from 'ngx-image-cropper';
import { TranslateModule } from '@ngx-translate/core';
import { CashInvoiceSmsSettingsComponent } from './components/invoice-customization/components/cash-invoice-sms-settings/cash-invoice-sms-settings.component';
import { PosSettingsComponent } from './components/pos-settings/pos-settings.component';
import {DiscountCodeManagementComponent} from './components/discount-code-management/discount-code-management.component'
import { ViewDiscountCodeComponent } from './components/view-discount-code/view-discount-code.component'
import { ClickOutsideModule } from 'ng-click-outside';
const routes: Routes = [
  {
    path: '',
    component: SalesComponent,
    children: [
      {
        path: '',
        component: InvoiceCustomizationComponent
      },
      {
        path: 'invoice-customization',
        component: InvoiceCustomizationComponent
      },
      {
        path: 'pos-settings',
        component: PosSettingsComponent
      },
      {
        path : 'discount-code',
        component : DiscountCodeManagementComponent,
      },
      {
        path : 'view-discount-code/:id',
        component : ViewDiscountCodeComponent
      }
    ]
  }
];

@NgModule({
  declarations: [
    SalesComponent,
    InvoiceCustomizationComponent,
    PaymentSettingsComponent,
    GeneralSettingsComponent,
    InvoiceSettingsComponent,
    ReminderSettingsComponent,
    InvoiceColumnSettingsComponent,
    EstimateSettingsComponent,
    CashInvoiceSmsSettingsComponent,
    PosSettingsComponent,
    DiscountCodeManagementComponent,
    ViewDiscountCodeComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule.forChild(routes),
    FormsModule,
    ClickOutsideModule
  ]
})
export class SalesModule { }
