import { createAction, props } from '@ngrx/store';

export const setUsage = createAction('[Usage and Limit] Set Usage', props<{ subscriptionLimit, currentUsage }>());
export const UpdateTransactionLimit = createAction('[Usage and Limit] Update Transaction Limit', props<{
    transactionLimit: any, subscriptionTransactionLimit: any
}>());
export const UpdateBusinessLimit = createAction('[Usage and Limit] Update Business Limit', ({businessLimit, subscriptionBusinessLimit}) => {
    console.log('Ac', businessLimit);
    return {businessLimit, subscriptionBusinessLimit};
});
export const UpdateAdminLimit = createAction('[Usage and Limit] Update Admin Limit', props<{
    adminLimit: any, subscriptionAdminLimit: any
}>());
export const UpdateSupportEmailLimit = createAction('[Usage and Limit] Update Support Email Limit', props<{
    supportEmail: any, subscriptionSupportEmail: any
}>());
export const UpdateStoreLimit = createAction('[Usage and Limit] Update Store Limit', props<{storeLimit: any, subscriptionStoreLimit: any}>());
export const updateCurrentTransactionLimit = createAction('[Usage and Limit] Update Current Transactions', props<{transactionLimit}>());
export const updateCurrentAdminLimit = createAction('[Usage and Limit] Update Current Admins', props<{adminLimit}>());
export const updateCurrentBusinessLimit = createAction('[Usage and Limit] Update Current Businesses', props<{businessLimit}>());
export const updateCurrentSupportLimit = createAction('[Usage and Limit] Update Current Support Emails', props<{supportEmail}>());
export const updateCurrentStoreLimit = createAction('[Usage and Limit] Update Current Store Emails', props<{storeLimit}>());
