<div class="flex justify-center p-4">
    <div class="w-[90%] flex flex-col">
        <div class="flex items-center mb-4">
            <h1 class="text-2xl Poppins-Bold inline-flex items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                    <path d="M160-80q-33 0-56.5-23.5T80-160v-400q0-33 23.5-56.5T160-640h640q33 0 56.5 23.5T880-560v400q0 33-23.5 56.5T800-80H160Zm0-80h640v-400H160v400Zm240-40 240-160-240-160v320ZM160-680v-80h640v80H160Zm120-120v-80h400v80H280ZM160-160v-400 400Z"/>
                </svg>
                {{'Subscriptions' | translate }}
            </h1>
        </div>
        <div class="flex items-baseline space-x-4 rtl:space-x-reverse mb-4">
            <a routerLink='subscription-plans' routerLinkActive='underline' class="Poppins-Bold text-lg">{{'Subscription Plans' | translate }}</a>
            <a routerLink='subscription-history' routerLinkActive='underline' class="Poppins-Bold text-lg">{{'Subscription History' | translate }}</a>
        </div>
        <div class="bg-gradient rounded-3xl p-8 mb-4">
            <div *ngIf="currentSubscription else noSubscription" class="flex justify-between">
                <div class="flex space-x-8 rtl:space-x-reverse">
                    <div>
                        <p class="Poppins-SemiBold text-white text-md">{{'Current Plan' | translate }}</p>
                        <p class="Poppins-SemiBold text-white text-xl">{{currentSubscription.planName}}</p>
                    </div>
                    <!-- <div *ngIf='currentSubscription.nextPlan'>
                        <p class="Poppins-SemiBold text-white text-md">{{'Next Plan' | translate }}</p>
                        <p class="Poppins-SemiBold text-white text-xl">{{currentSubscription.nextPlan}}</p>
                    </div> -->
                </div>
                <div>
                    <p class="Poppins-SemiBold text-white text-sm text-end mb-1 opacity-75">{{'Subscription Date:' | translate }} {{currentSubscription.date | date}}</p>
                    <p *ngIf="currentSubscription.planName != 'Starter'" class="Poppins-SemiBold text-white text-sm text-end opacity-75">{{'Subscription Validity:' | translate }} {{currentSubscription.expiryDate | date}}</p>
                    <p class="Poppins-SemiBold text-white text-sm text-end opacity-75" >{{'Remaining Usage' | translate }}: {{remainingLimit >= 0 ? remainingLimit : "Unlimited"}}</p>
                    <p class="Poppins-SemiBold text-white text-sm text-end opacity-75" >{{'Total Storage' | translate }}: {{currentSubscription?.totalStorage ?? 0 + ' GB' }}</p>

                </div>
            </div>
            <ng-template #noSubscription>
                <p class="Poppins-SemiBold text-white text-md">{{'No plan subscribed yet.' | translate }}</p>
            </ng-template>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>