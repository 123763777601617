import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as SalesTaxesActions from '../actions/salesTaxes.action';

export interface SalesTaxesState {
    salesTaxes: Array<any>;
}

export const initialState: SalesTaxesState = {
    salesTaxes: []
};

export const SalesReducer = createReducer(initialState,
    on(SalesTaxesActions.SetSalesTaxes, (state: SalesTaxesState, {salesTaxes}) => ({...state, salesTaxes})),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: SalesTaxesState | undefined, action: Action): any {
    return SalesReducer(state, action);
}

export const getSalesTaxes = (state: SalesTaxesState) => state.salesTaxes;
