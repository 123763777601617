import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-reports-stats',
  templateUrl: './reports-stats.component.html',
  styleUrls: ['./reports-stats.component.scss']
})
export class ReportsStatsComponent implements OnInit {

  constructor() { }

  @Input() stats = [];
  ngOnInit(): void {
  }

}
