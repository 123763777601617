<div class="flex min-h-screen">
  <div class="w-full">
    <div class="w-full flex flex-col space-y-4">
      <!-- header start  -->
      <div class="flex items-center ps-5 pt-4 w-full mx-auto ml-2">
        <div class="">
          <!-- <h1 class="text-2xl Poppins-Bold inline-flex items-center"> -->
          <!-- <svg
              xmlns="http://www.w3.org/2000/svg"
              class="h-6 w-6 me-2"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
              />
            </svg> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
              <path d="M520-600v-240h320v240H520ZM120-440v-400h320v400H120Zm400 320v-400h320v400H520Zm-400 0v-240h320v240H120Zm80-400h160v-240H200v240Zm400 320h160v-240H600v240Zm0-480h160v-80H600v80ZM200-200h160v-80H200v80Zm160-320Zm240-160Zm0 240ZM360-280Z"/>
            </svg>
            {{ "P/L Analysis" | translate }}
          </h1> -->
        </div>
        <div
          class="flex gap-2 ml-[169px] items-center"
          [ngClass]="{ yearmargin: selectedType === 'monthright' }"
        >
          <div class="w-36 z-20">
            <ng-select
              [items]="typeOfData"
              class="new-dropdown"
              bindLabel="name"
              [searchable]="false"
              placeholder="Select type Of Data"
              [clearable]="false"
              bindValue="value"
              [(ngModel)]="selectedType"
              (change)="dataChange()"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </ng-template></ng-select
            >
          </div>
          <ng-template [ngIf]="monthsData || monthrightData">
            <div class="w-36 z-20">
              <ng-select
                [items]="monthsName"
                bindLabel="name"
                class="new-dropdown"
                [searchable]="false"
                bindValue="value"
                placeholder="Months"
                [clearable]="false"
                [(ngModel)]="selectedMonth"
                (change)="dataChange()"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.value }}
                    </div>
                  </div>
                </ng-template></ng-select
              >
            </div>
          </ng-template>

          <div class="w-36 z-20" *ngIf="yearlyData || monthsData">
            <ng-select
              [items]="years"
              bindLabel="name"
              class="new-dropdown"
              [searchable]="false"
              [clearable]="false"
              placeholder="Data type"
              bindValue="value"
              [(ngModel)]="selectedYear"
              (change)="dataChange()"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </ng-template></ng-select
            >
          </div>
          <div class="w-36 z-20" *ngIf="yearlyData">
            <ng-select
              [items]="preference"
              bindLabel="name"
              [searchable]="false"
              [clearable]="false"
              placeholder="Preference"
              bindValue="value"
              class="new-dropdown"
              [(ngModel)]="preferenceType"
              (change)="preferenceChange()"
            >
              <ng-template ng-option-tmp let-item="item" let-index="index">
                <div class="px-2 py-1">
                  <div
                    class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ item.name }}
                  </div>
                </div>
              </ng-template></ng-select
            >
          </div>

          <ng-template [ngIf]="monthsData">
            <div class="">
              <app-button
                buttonText="{{ 'Export PDF' | translate }}"
                type="primary"
                (handleClick)="pdfReport()"
              ></app-button>
            </div>
            <div class="">
              <app-button
                buttonText="{{ 'Export Excel' | translate }}"
                type="primary"
                (handleClick)="excelReport()"
              ></app-button>
            </div>
          </ng-template>
          <ng-template [ngIf]="yearlyData">
            <div class="" *ngIf="preferenceData">
              <app-button
                buttonText="{{ 'Export PDF' | translate }}"
                type="primary"
                (handleClick)="pdfReportYear()"
              ></app-button>
            </div>
            <div class="" *ngIf="!preferenceData">
              <app-button
                buttonText="{{ 'Export PDF' | translate }}"
                type="primary"
                (handleClick)="pdfReportYearMonthly()"
              ></app-button>
            </div>
            <div class="">
              <app-button
                buttonText="{{ 'Export Excel' | translate }}"
                type="primary"
                (handleClick)="excelReportYear()"
              ></app-button>
            </div>
          </ng-template>
          <ng-template [ngIf]="monthrightData">
            <div class="">
              <app-button
                buttonText="{{ 'Export PDF' | translate }}"
                type="primary"
                (handleClick)="pdfReportMonthRight()"
              ></app-button>
            </div>
            <div class="">
              <app-button
                buttonText="{{ 'Export Excel' | translate }}"
                type="primary"
                (handleClick)="excelReportMonthRight()"
              ></app-button>
            </div>
          </ng-template>
        </div>
      </div>
      <!-- header End -->

      <!-- Grid options start -->
      <div
        *ngIf="!yearlyData"
        class="mr-auto px-6"
        style="margin-top: 72px"
      >
        <div
          class="bg-input-light flex items-center justify-center gap-6 shadow-card rounded-7 p-3 text-[#030723B2]"
        >
          <div class="flex flex-col items-center justify-center gap-4">
            <p class="text-sm Poppins-Medium">
              {{ "Profit" | translate }} ({{ currencyName }})
            </p>
            <h3 class="text-2xl leading-5">{{ profitMonth }}</h3>
          </div>
          <div *ngIf="!monthData?.isFinalYear" class="flex flex-col items-center justify-center gap-4">
            <p class="text-sm Poppins-Medium">
              {{ "YoY%" }}
            </p>
            <h3 class="text-2xl leading-5">{{ growthMonth ?? 0 }}%</h3>
          </div>
        </div>
      </div>
      <!-- Grid option end  -->

      <!-- Year Grid -->
      <div class="mr-auto px-6" style="margin-top: 72px" *ngIf="yearlyData">
        <div
          class="bg-input-light flex items-center justify-center gap-6 shadow-card rounded-7 p-3 text-[#030723B2]"
        >
          <div class="flex flex-col items-center justify-center gap-4">
            <p class="text-sm Poppins-Medium">
              {{ "Profit Year" | translate }}
            </p>
            <h3 class="text-2xl leading-5">{{ profitYear }}</h3>
          </div>
          <div *ngIf="!yearData?.isFinalYear" class="flex flex-col items-center justify-center gap-4">
            <p class="text-sm Poppins-Medium">
              {{ "YoY%" }}
            </p>
            <h3 class="text-2xl leading-5">{{ growthYear ?? 0 }}%</h3>
          </div>
        </div>
      </div>

      <!-- Year Grid Ends -->

      <!-- Month Data -->
      <!-- Income Data -->
      <ng-template [ngIf]="monthsData">
        <div class="p-2 mx-5 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ "Income" | translate }}
          </p>
        </div>
        <div class="">
          <!-- Left  -->
          <div class="px-10">
            <table class="relative z-10">
              <thead class="h-12 text-[#1C1C1C66]">
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 border-[#1C1C1C33] border-b bg-input-light z-30"
                    *ngFor="let i of tableColLeftIncome"
                    [ngClass]="{ 'account bg-input-light': i === 'Income' }"
                  >
                    {{ i }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let accountType of monthData?.incomeFinalArray;
                    index as tw
                  "
                >
                  <tr class="h-12">
                    <td
                      class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white"
                      [colSpan]="tableColLeftExpenses.length"
                    >
                      {{ accountType?.[0]?.accountType }}
                    </td>
                  </tr>
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let rowData of accountType"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ rowData?.account }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.income0 }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.income1 }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.income2 }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.budget }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.historicIncome }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.yoy }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>

          <!-- IncomeData Ends -->

          <!-- Expense Data  -->
          <div class="p-2 !mt-12 mx-5 border-b border-[#1C1C1C33]">
            <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
              {{ "Expenses" | translate }}
            </p>
          </div>
          <div class="flex">
            <!-- Left -->
            <div class="overflow-y-auto px-10">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 sticky w-48 border-[#1C1C1C33] border-b top-0 left-0 z-30 bg-white"
                      *ngFor="let i of tableColLeftExpenses"
                      [ngClass]="{ 'account bg-input-light': i === 'Expenses' }"
                    >
                      {{ i }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <ng-container
                    *ngFor="
                      let accountType of monthData?.expenseFinalArray;
                      index as tw
                    "
                  >
                    <tr class="h-12">
                      <td
                        class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white"
                        [colSpan]="tableColLeftExpenses.length"
                      >
                        {{ accountType?.[0]?.accountType }}
                      </td>
                    </tr>

                    <tr
                      class="h-12 mb-4 py-4 px-6"
                      *ngFor="let rowData of accountType"
                    >
                      <td
                        class="Poppins-Medium text-base text-center px-4 py-2 w-64 bg-input-light text-[#1c1c1ccc]"
                      >
                        {{ rowData?.account }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.expense0 }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.expense1 }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.expense2 }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.budget }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.historicExpense }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.yoy }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData?.budgetVariance }}%
                      </td>
                    </tr>
                  </ng-container>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
      <!-- Expense Data Ends -->
      <!-- Month data End -->

      <!-- Year Data -->
      <ng-template [ngIf]="yearlyData">
        <div class="p-2 mx-5 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ "Income" | translate }}
          </p>
        </div>
        <div class="px-10">
          <!-- Income -->
          <div class="max-w-[90rem] overflow-y-scroll">
            <table class="my-4">
              <thead class="h-12 text-[#1C1C1C66]">
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                  ></td>
                  <td
                    colspan="3"
                    class="px-4 py-4 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                    *ngFor="let in of counter(12); let i = index"
                  >
                    {{
                      yearData?.incomeFinalArray[0][0]["month" + i] +
                        " " +
                        yearData?.incomeFinalArray[0][0]["year" + i]
                    }}
                  </td>
                </tr>
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 sticky left-0 z-30 border-[#1C1C1C33] border-b top-0 bg-input-light"
                  >
                    {{ "Income" }}
                  </td>
                  <ng-container *ngFor="let in of counter(12)">
                    <td
                      class="px-4 py-4 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                      *ngFor="let i of tableColYearIncome"
                    >
                      {{ i }}
                    </td>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let accountType of yearData?.incomeFinalArray;
                    index as tw;
                  "
                >
                  <tr class="h-12">
                    <td
                      class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white sticky left-0"
                    >
                      {{ accountType?.[0]?.accountType }}
                    </td>
                    <td
                      class="border-b border-[#1C1C1C33]"
                      [colSpan]="36"
                    ></td>
                  </tr>
                  <tr
                    class=""
                    *ngFor="let rowData of accountType"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ rowData?.account }}
                    </td>
                    <ng-container *ngFor="let in of counter(12); let j = index">
                      <td class="px-4 py-2 text-center">
                        {{ rowData["income" + j] }}
                      </td>
                      <td class="px-4 py-2 text-center">
                        {{ rowData["budget" + j] }}
                      </td>
                      <!-- <td class="px-4 py-2 text-center inc">{{rowData["previousIncome"+ j]}}</td> -->
                      <td
                        class="px-4 py-2 text-center border-r border-[#1C1C1C11]"
                      >
                        {{ rowData["budgetVariance" + j] }}%
                      </td>
                      <!-- <td class="px-4 py-2 text-center">{{"0"}}</td> -->
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Expense -->
        <div class="p-2 mx-5 !mt-12 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ "Expenses" | translate }}
          </p>
        </div>
        <div class="px-10">
          <div class="max-w-[90rem] overflow-y-scroll">
            <table class="my-4">
              <thead class="h-12 text-[#1C1C1C66]">
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                  ></td>
                  <td
                    colspan="3"
                    class="px-4 py-4 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                    *ngFor="let in of counter(12); let i = index"
                  >
                    {{
                      yearData?.expenseFinalArray[0][0]["month" + i] +
                        " " +
                        yearData?.expenseFinalArray[0][0]["year" + i]
                    }}
                  </td>
                </tr>
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 sticky left-0 z-30 border-[#1C1C1C33] border-b top-0 bg-input-light"
                  >
                    {{ "Expenses" }}
                  </td>
                  <ng-container *ngFor="let in of counter(12)">
                    <td
                      class="px-4 py-4 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-white"
                      *ngFor="let i of tableColYearIncome"
                    >
                      {{ i }}
                    </td>
                  </ng-container>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let accountType of yearData?.expenseFinalArray;
                    index as tw;
                  "
                >
                  <tr class="h-12">
                    <td
                      class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white sticky left-0"
                    >
                      {{ accountType?.[0]?.accountType }}
                    </td>
                    <td
                      class="border-b border-[#1C1C1C33]"
                      [colSpan]="36"
                    ></td>
                  </tr>
                  <tr
                    class=""
                    *ngFor="let rowData of accountType"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ rowData?.account }}
                    </td>
                    <ng-container *ngFor="let in of counter(12); let j = index">
                      <td class="px-4 py-2 text-center">
                        {{ rowData["expense" + j] }}
                      </td>
                      <!-- <td class="px-4 py-2 text-center">{{rowData["previousExpense"+ j]}}</td> -->
                      <td class="px-4 py-2 text-center">
                        {{ rowData["budget" + j] }}
                      </td>
                      <td
                        class="px-4 py-2 text-center border-r border-[#1C1C1C11]"
                      >
                        {{ rowData["budgetVariance" + j] }}%
                      </td>
                      <!-- <td class="px-4 py-2 text-center">{{"0"}}</td> -->
                    </ng-container>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
      <!-- Year Data Ends -->

      <ng-template [ngIf]="monthrightData">
        <div class="p-2 mx-5 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ "Income" | translate }}
          </p>
        </div>
        <div class="flex px-10">
          <div class="max-w-[90rem] overflow-y-scroll">
            <table class="my-4">
              <thead class="h-12 text-[#1C1C1C66]">
                <tr class="text-center">
                  <td
                    class="px-4 py-2 w-48 sticky left-0 z-30 border-[#1C1C1C33] border-b top-0 bg-white"
                    *ngFor="let i of tableColRightIncome"
                    [ngClass]="{ 'account bg-input-light': i === 'Income' }"
                  >
                    {{ i }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let accountType of monthData?.incomeFinancialFinalArray;
                    index as tw
                  "
                >
                  <tr class="h-12">
                    <td
                      class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white"
                      [colSpan]="tableColLeftExpenses.length"
                    >
                      {{ accountType?.[0]?.accountType }}
                    </td>
                  </tr>
                  <tr
                    *ngFor="let rowData of accountType"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ rowData?.account }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.actualAmount }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.actualBudget }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.historicIncome }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.yoy }}
                    </td>
                    <td class="px-4 py-2 text-center">
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>

        <div class="p-2 !mt-12 mx-5 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ "Expenses" | translate }}
          </p>
        </div>
        <div class="flex px-10">
          <div class="max-w-[90rem] overflow-y-scroll">
            <table class="my-4">
              <thead class="h-12 text-[#1C1C1C66]">
                <tr class="text-center">
                  <td
                    class="px-4 py-2 sticky w-48 border-[#1C1C1C33] border-b top-0 bg-white"
                    *ngFor="let i of tableColRightExpense"
                    [ngClass]="{ 'account bg-input-light': i === 'Expenses' }"
                  >
                    {{ i }}
                  </td>
                </tr>
              </thead>
              <tbody>
                <ng-container
                  *ngFor="
                    let accountType of monthData?.expenseFinancialFinalArray;
                    index as tw;
                    last as l;
                  "
                >
                  <tr class="h-12">
                    <td
                      class="Poppins text-[#1C1C1C66] text-lg ps-3 pb-2 pt-4 border-b border-[#1C1C1C33] bg-white"
                      [colSpan]="tableColLeftExpenses.length"
                    >
                      {{ accountType?.[0]?.accountType }}
                    </td>
                  </tr>
                  <tr
                    *ngFor="let rowData of accountType"
                  >
                    <td
                      class="px-4 py-2 text-center sticky left-0 bg-input-light min-w-[16rem] w-64"
                    >
                      {{ rowData?.account }}
                    </td>
                    <td class="px-4 py-4 text-center">
                      {{ rowData?.actualAmount }}
                    </td>
                    <td class="px-4 py-4 text-center">
                      {{ rowData?.actualBudget }}
                    </td>
                    <td class="px-4 py-4 text-center">
                      {{ rowData?.historicExpense }}
                    </td>
                    <td class="px-4 py-4 text-center">
                      {{ rowData?.yoy }}
                    </td>
                    <td class="px-4 py-4 text-center">
                      {{ rowData?.budgetVariance }}%
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
