import { getCurrencySymbol } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { initFlowbite } from 'flowbite';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { filter, takeUntil, tap } from 'rxjs/operators';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { ChatsService } from 'src/app/modules/chats/chats.service';
import { ProjectsService } from 'src/app/modules/projects/projects.service';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { DATE_FORMAT } from 'src/app/modules/time-sheet/constant';
import { TimeSheetService } from 'src/app/modules/time-sheet/time-sheet.service';
import { GlobalTimerService } from 'src/app/shared/components/global-timer/global-timer.service';
import { PermissionDto, UserDetailsDto } from 'src/app/shared/dtos/user.dto';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { setSubscriptionPlans } from 'src/app/store/actions/subscription.actions';
import {
  UpdateStoreLimit,
  UpdateSupportEmailLimit,
  UpdateTransactionLimit,
} from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import {
  selectUser,
  selectUserBusinesses,
  selectUserSubscription,
} from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
import { CollapsibleSidebarService } from './collapsible-sidebar.service';
import moment = require('moment');

interface DropdownDto {
  connect: boolean;
  sales: boolean;
  purchases: boolean;
  inventory: boolean;
  accounting: boolean;
  'time-sheet': boolean;
  plus: boolean;
  experts: boolean;
}

@Component({
  selector: 'app-collapsible-sidebar',
  templateUrl: './collapsible-sidebar.component.html',
  styleUrls: ['./collapsible-sidebar.component.scss'],
})
export class CollapsibleSidebarComponent implements OnInit, AfterViewInit {
  @ViewChild('navbarOptions') navbarOptions: ElementRef;

  logo = 'assets/images/logo.svg';
  defaultAvatar = 'assets/images/avatar.jpg';
  collapsed: boolean;
  show: DropdownDto = {} as DropdownDto;
  showText: boolean;
  showBusiness: boolean;
  showProfile: boolean;
  showNotification: boolean;
  user$: Observable<any>;
  businesses$: Observable<any>;
  business$: Observable<any>;
  account$: Observable<any>;
  subscription$: Observable<any>;
  usage$: Observable<any>;
  unsubscribe$ = new Subject();
  open = false;
  hasTimeSheetEditPolicy = false;
  toggleBusiness = false;
  selectedBusiness = {
    businessId: {
      _id: localStorage.getItem('selectedBusiness') || '',
      companyName: '',
    },
  };
  baseURL = environment.apiBaseURL;
  storedLang = 'en';
  arabicSelected = false;
  starterPlan: boolean = false;
  haveStoragePlan: boolean = false;
  haveTimeSheetPlan: boolean = false;
  userSubscriptions = [];
  userId: any;
  chatUpdated: any;
  chatUpdateSubscription: any;
  sendUnreadCount: any;
  markAsReadCount: any;
  taskUpdateSubscribe: any;
  taskUpdateCount: any;
  fileUpdateCount: any;
  taskCountNumber: any;
  fileCountNumber: any;
  getAllNotifications: any;
  businessId: any;
  openSettingsModal: boolean = false;
  selectedTab: string = 'userProfile';
  totalWorked: number = 0;
  userDetails;
  userPermission: PermissionDto;

  private scrollListener: () => void;

  constructor(
    public router: Router,
    private store: Store<RootReducerState>,
    private userService: UserService,
    private spinner: NgxSpinnerService,
    private businessService: BusinessService,
    private subscriptionService: SubscriptionService,
    private projectService: ProjectsService,
    private timeSheetService: TimeSheetService,
    private globalTimerService: GlobalTimerService,
    private accountingService: AccountingService,
    private toaster: ToastrService,
    private numberService: NumberService,
    private chatService: ChatsService,
    private languageService: LanguageService,
    private collapsibleSidebarService: CollapsibleSidebarService,
    private renderer: Renderer2,
    private utlityService: UtilityService
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = this.store.pipe(select(selectBusiness));
    this.account$ = this.store.pipe(select(selectAccounts));
    this.usage$ = store.pipe(select(selectUsage));
    this.subscription$ = store.pipe(select(selectUserSubscription));
  }

  ngOnInit(): void {
    console.log("router.url.split('/')[1]", this.router.url.split('/')[1]);
    this.showNotification = true;
    this.reset();
    this.init();
    this.initLogo();
    const connect = ['chats', 'task', 'files'];
    const activeRoute = this.router.url.split('/')[1];
    if ([...Object.keys(this.show), ...connect].includes(activeRoute)) {
      this.reset();
      if (connect.includes(activeRoute)) {
        this.show.connect = true;
      } else {
        this.show[activeRoute] = true;
      }
    }
    this.utlityService.showHideSettingsModal.subscribe((data) => {
      console.log('showHideSettingsModal1', data);

      this.openSettingsModal = data.open;
      if (data.tab) {
        this.selectedTab = data.tab;
      }
    });
  }

  ngAfterViewInit() {
    if (this.navbarOptions) {
      this.scrollListener = this.renderer.listen(
        this.navbarOptions.nativeElement,
        'scroll',
        () => {
          for (let option of Object.keys(this.show)) {
            document.getElementById(option)?.classList?.add('hidden');
            document.getElementById(option)?.classList?.remove('block');
          }
        }
      );
    }
  }

  reset(): void {
    this.show.connect = false;
    this.show.sales = false;
    this.show.purchases = false;
    this.show.inventory = false;
    this.show.accounting = false;
    this.show['time-sheet'] = false;
    this.show.plus = false;
    this.show.experts = false;
  }

  //? ********************************************************************************************

  init(): void {
    let route = this.router.url.split('/')[2];
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationStart),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((events) => {
        const connect = ['chats', 'task', 'files'];
        const activeRoute = events['url'].split('/')[1];
        this.reset();
        if (this.collapsed && events['url'].split('/')[1] !== 'settings') {
          this.logo = 'assets/images/Collapsed-logo.svg';
        } else {
          this.logo = 'assets/images/logo.svg';
        }
        if ([...Object.keys(this.show), ...connect].includes(activeRoute)) {
          if (connect.includes(activeRoute)) {
            this.show.connect = true;
          } else {
            this.show[activeRoute] = true;
          }
        }
      });
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.unsubscribe$)
      )
      .subscribe((events) => {
        if (events['url'] !== '/auth/select-business') {
          localStorage.setItem('currentLocation', events['url']);
        }
        route = this.router.url.split('/')[2];
        this.toggleBarIndicatorFor(route);
      });
    setTimeout(() => {
      this.toggleBarIndicatorFor(route);
    }, 10);

    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      let businessId = localStorage.getItem('selectedBusiness');
      this.userDetails = user;
      if (!localStorage.getItem('auth_token')) {
        this.router.navigate(['/auth/login']);
        return;
      }
    });

    let businessId = localStorage.getItem('selectedBusiness');
    this.userService.getUserDetails(businessId ?? '').subscribe(
      (userResp: UserDetailsDto) => {
        this.userId = userResp.data._id;
        if (this.userId && this.businessId) {
          this.chatService.emit('sendUnreadCount', {
            userId: this.userId,
            businessId: this.businessId,
          });
        }

        if (userResp?.data?.subscription?.planName === 'Starter') {
          this.starterPlan = true;
        } else {
          this.starterPlan = false;
        }
        this.userSubscriptions = userResp?.data?.subscription?.subscriptions;
        if (this.userSubscriptions?.find((sub) => sub?.subHost === 'Storage')) {
          this.haveStoragePlan = true;
        }
        if (
          this.userSubscriptions?.find((sub) => sub?.subHost === 'Timesheet')
        ) {
          this.haveTimeSheetPlan = true;
        }
        if (
          userResp.data?.businesses?.find(
            (business) => business.businessId._id === businessId
          ).permission.timeSheets === 'edit'
        ) {
          this.hasTimeSheetEditPolicy = true;
        }

        this.userPermission = userResp.data.businesses.find(
          (key) => key.businessId._id === businessId
        ).permission;
        if (this.userPermission?.dashboard === 'none') {
          this.router.navigate(['/chats']);
        }

        userResp.data['currentUsage'] = userResp?.currentUsage;

        if (!this.userDetails?.token) {
          this.userService.checkUserSubscription(userResp?.data);
        }
        this.userService.onGetUserDetailsSuccess(userResp?.data);

        this.userService.setUserLimits(userResp?.data);
        this.utlityService.userUsage.next(userResp?.currentUsage);

        setTimeout(() => {
          initFlowbite();
        }, 0);
      },
      (error) => {
        this.spinner.hide();
        this.logout();
      }
    );

    if (businessId) {
      this.timeSheetService
        .getActiveTimer(businessId, moment().format(DATE_FORMAT))
        .pipe(
          tap((data) => {
            if (data?.data) {
              this.globalTimerService.show({
                customer: data.data?.customerName,
                id: data.data._id,
                project: data.data.projectName,
                until: data.data.until,
                totalWorked: data.data.totalWorked,
              });
              this.globalTimerService.totalWork$
                .pipe(
                  tap((data) => {
                    this.totalWorked = data;
                  })
                )
                .subscribe();
            }
          }),
          takeUntil(this.unsubscribe$)
        )
        .subscribe();
    }
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      const businessId = business?.businessId?._id;
      this.businessId = business?.businessId?._id;
      if (businessId) {
        this.loadStores(businessId);
        this.selectedBusiness.businessId._id = business?.businessId?._id;
        this.numberService.currency.next(business?.businessId?.currency);
        const decimalSize = business?.businessId?.decimalSize ?? 2;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
        this.accountingService.getAllAccounts(businessId).subscribe((resp) => {
          if (resp.success) {
            this.accountingService.setAccountsInStore(resp.data);
          }
        });
        this.projectService.getAllProjects(businessId).subscribe((resp) => {
          if (resp.success) {
            this.projectService.setProjectsInStore(resp.data);
          }
        });
        this.businessService.getUsage(businessId).subscribe((resp) => {
          if (resp?.success) {
            const { currentUses, subscriptionLimit } = resp?.data;
            this.store.dispatch(
              UpdateTransactionLimit({
                transactionLimit: currentUses?.transactionLimit,
                subscriptionTransactionLimit:
                  subscriptionLimit?.transactionLimit,
              })
            );
            this.store.dispatch(
              UpdateStoreLimit({
                storeLimit: currentUses?.storeLimit,
                subscriptionStoreLimit: subscriptionLimit?.storeLimit,
              })
            );
            this.store.dispatch(
              UpdateSupportEmailLimit({
                supportEmail: currentUses?.supportEmail,
                subscriptionSupportEmail: subscriptionLimit?.supportEmail,
              })
            );
          }
        });
      }
    });
    this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((businesses: Array<any>) => {
        if (businesses.length > 0) {
          let index = businesses.findIndex(
            (business) =>
              business.businessId._id === this.selectedBusiness.businessId._id
          );
          if (index < 0) {
            index = 0;
          }
          const currencySymbol = getCurrencySymbol(
            businesses[index].businessId.currency,
            'narrow'
          );
          const data = {
            ...businesses[index],
            businessId: {
              ...businesses[index].businessId,
              currencySymbol,
            },
          };
          this.businessService.setBusiness(data);
        }
      });

    this.subscriptionService.getPlans().subscribe((resp) => {
      if (resp.success) {
        this.store.dispatch(
          setSubscriptionPlans({ subscriptionPlans: [...resp.data] })
        );
      }
    });

    if (localStorage.getItem('NuMetric|lang')) {
      this.storedLang = localStorage.getItem('NuMetric|lang');
      this.storedLang == 'en'
        ? (this.arabicSelected = false)
        : (this.arabicSelected = true);
    } else {
      localStorage.setItem('NuMetric|lang', 'en');
      this.arabicSelected = false;
    }

    this.getSubscription();

    this.chatUpdateSubscription = this.chatService
      .listen('anyUpdate')
      .subscribe((data) => {
        // console.log('allNotifications', data, this.router.url, this.chatUpdated);
        const item = data.chatDetails.chatUsers.find(
          (item) => item._id === this.userId
        );
        this.chatService.emit('sendUnreadCount', {
          userId: this.userId,
          businessId: this.businessId,
        });
        if (
          item &&
          data.sendBy._id !== this.userId &&
          this.router.url !== '/chats'
        ) {
          console.log('isItRunning', data);

          this.toaster.info(
            `${
              data.sendBy.firstName + ' ' + data.sendBy.lastName
            } has sent a message in the ${
              data.chatDetails.chatType === 'Individual'
                ? 'Personal'
                : data.chatDetails.channelGroupName
            } chat`
          );
        }
      });
    console.log('okay okay');

    this.sendUnreadCount = this.chatService
      .listen('sendUnreadCount')
      .subscribe((data) => {
        console.log('sendUnreadCount', data);
        if (data.userId === this.userId) {
          this.chatUpdated = data.data.reduce(
            (n, { unreadCount }) => n + unreadCount,
            0
          );
          console.log('chatUpdated', this.chatUpdated);
        }
      });
    this.markAsReadCount = this.chatService
      .listen('markAsRead')
      .subscribe((data) => {
        // console.log('sendUnreadCount', data);
        if (data === this.userId) {
          setTimeout(() => {
            this.chatService.emit('sendUnreadCount', {
              userId: this.userId,
              businessId: this.businessId,
            });
          }, 2000);
        }
      });
    this.taskUpdateSubscribe = this.chatService
      .listen('notification')
      .subscribe((data) => {
        console.log('sendUnreadCount', data);
        if (
          data.toUser === this.userId &&
          (data.entityType === 'taskReminder' ||
            data.entityType === 'taskAssigned')
        ) {
          this.toaster.info(data.message);
        } else if (
          data.toUser === this.userId &&
          data.entityType === 'sharedFile'
        ) {
          this.toaster.info(data.message);
        }
        setTimeout(() => {
          this.chatService.emit('getAllNotifications', this.userId);
        }, 1000);
      });
    this.taskUpdateCount = this.chatService
      .listen('notificationUnreadCount')
      .subscribe((data) => {
        const count = data.filter(
          (item) =>
            item.toUser.includes(this.userId) &&
            item.businessId === this.businessId
        );
        this.taskCountNumber = count.length;
        console.log('notificationUnreadCount', this.taskCountNumber);
      });
    this.fileUpdateCount = this.chatService
      .listen('getAllNotifications')
      .subscribe((data) => {
        console.log('getAllNotificationsSidebar', data);
        const count = data.filter(
          (item) =>
            (item.toUser?.includes(this.userId) ||
              item.toUser === this.userId) &&
            item.entityType === 'sharedFile' &&
            item.seen === false &&
            item.businessId === this.businessId
        );
        this.fileCountNumber = count.length;
      });
  }

  loadStores(businessId): void {
    this.businessService
      .getStores(businessId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((resp) => {
        if (resp?.success) {
          console.log(resp?.data, 'data');
          this.businessService.setStore(resp?.data);
        }
      });
  }

  getSubscription(): void {
    this.subscription$.subscribe((subs) => {
      if (subs?.planName === 'Invoicing') {
        this.logo = 'assets/images/invoicing.svg';
        // this.logo2 = 'assets/images/invoicingWhite.svg'
        // this.logo = 'assets/images/Group 4653.svg'
      }
    });
  }

  logout(): void {
    this.spinner.show();
    this.timeSheetService
      .updateActiveTimer(this.timeSheetService.businessId, {
        state: 'STOP',
        totalWorked: this.totalWorked,
        until: moment().utc().format(),
      })
      .subscribe();
    this.globalTimerService.hide();
    this.userService.logoutAPI().subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.userService.logout();
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
        this.toaster.error('Something went wrong');
      }
    );
  }

  selectBusiness(business): void {
    this.spinner.show();
    const currencySymbol = getCurrencySymbol(
      business.businessId.currency,
      'narrow'
    );
    const data = {
      ...business,
      businessId: {
        ...business.businessId,
        currencySymbol,
      },
    };
    localStorage.setItem('selectedBusiness', business?.businessId?._id);
    this.accountingService
      .getAllAccounts(business.businessId._id)
      .subscribe((resp) => {
        if (resp.success) {
          this.accountingService.setAccountsInStore(resp.data);
        }
      });
    this.projectService
      .getAllProjects(business.businessId._id)
      .subscribe((resp) => {
        if (resp.success) {
          this.projectService.setProjectsInStore(resp.data);
        }
      });
    this.businessService.getUsage(business.businessId._id).subscribe((resp) => {
      if (resp?.success) {
        const { currentUses, subscriptionLimit } = resp?.data;
        this.store.dispatch(
          UpdateTransactionLimit({
            transactionLimit: currentUses?.transactionLimit,
            subscriptionTransactionLimit: subscriptionLimit?.transactionLimit,
          })
        );
        this.store.dispatch(
          UpdateSupportEmailLimit({
            supportEmail: currentUses?.supportEmail,
            subscriptionSupportEmail: subscriptionLimit?.supportEmail,
          })
        );
      }
    });
    this.userPermission = data.permission;
    this.businessService.setBusiness(data);
    this.userService.getUserDetails(business.businessId._id ?? '').subscribe(
      (userResp: UserDetailsDto) => {
        this.userService.checkUserSubscription(userResp?.data);
        this.userService.onGetUserDetailsSuccess(userResp?.data);
        // this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.logout();
      }
    );
    this.selectedBusiness = JSON.parse(JSON.stringify(business));
    // this.spinner.hide();
    this.router.navigate(['/dashboard']);
    this.showBusiness = false;
    document.getElementById('business')?.classList?.add('hidden');
    document.getElementById('business')?.classList?.remove('block');
  }

  toggleBarIndicatorFor(routeName): void {
    const salesBar = document.getElementById('sales-bar');
    const subscriptionBar = document.getElementById('subscription-bar');
    const accountingBar = document.getElementById('accounting-bar');

    if (routeName === 'sales') {
      if (salesBar) {
        salesBar.style.opacity = '1';
      }
    } else {
      if (salesBar) {
        salesBar.style.opacity = '0';
      }
    }
    if (routeName === 'subscription') {
      if (subscriptionBar) {
        subscriptionBar.style.opacity = '1';
      }
    } else {
      if (subscriptionBar) {
        subscriptionBar.style.opacity = '0';
      }
    }
    if (routeName === 'accounting') {
      if (accountingBar) {
        accountingBar.style.opacity = '1';
      }
    } else {
      if (accountingBar) {
        accountingBar.style.opacity = '0';
      }
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.chatUpdateSubscription?.unsubscribe();
    this.sendUnreadCount?.unsubscribe();
    this.markAsReadCount?.unsubscribe();
    this.taskUpdateSubscribe?.unsubscribe();
    this.taskUpdateCount?.unsubscribe();
    this.fileUpdateCount?.unsubscribe();
    this.getAllNotifications?.unsubscribe();
  }

  changeLanguage(): void {
    let lang = 'en';
    this.arabicSelected = !this.arabicSelected;
    this.arabicSelected ? (lang = 'ar') : (lang = 'en');
    localStorage.setItem('NuMetric|lang', lang);
    this.languageService.setLanguage(lang);
    window.location.reload();
    this.showProfile = false;
  }

  redirect(platformName: string): void {
    let a = document.createElement('a');
    const token = localStorage.getItem('refresh_token');
    if (platformName === 'payroll') {
      if (this.userSubscriptions?.find((sub) => sub?.subHost === 'Payroll')) {
        a.href = `https://payroll.numetric.work/auth/login?redirect=${token}&db=numetric&businessId=${this.selectedBusiness.businessId._id}`;
      } else {
        this.router.navigate(['/payroll']);
      }
    } else if (platformName === 'help') {
      a.href = 'https://learn.numetric.work/pages/support/list';
    } else {
      a.href = `https://learn.numetric.work/autoLogin/${token}/numetric`;
    }
    a.target = 'blank';
    a.click();
  }

  //? **********************************************************************************************

  initLogo(): void {
    this.collapsibleSidebarService.collapsed$.subscribe((t: boolean): void => {
      if (t) {
        this.logo = 'assets/images/Collapsed-logo.svg';
        this.showText = false;
      } else {
        setTimeout((): void => {
          this.logo = 'assets/images/logo.svg';
          this.showText = true;
        }, 300);
      }
      this.collapsed = t;
    });
  }

  collapse(): void {
    this.showBusiness = false;
    this.showProfile = false;
    this.collapsibleSidebarService.toggle();
    this.showNotification = true;
  }

  active(activeRoute: string): void {
    this.reset();
    this.show[activeRoute] = true;
    if (this.collapsed && activeRoute === 'connect') {
      this.showNotification = !this.showNotification;
    }
  }

  isActiveRoute(routes: string): boolean {
    const currentRoute = this.router.url.split('/')[1];
    return routes === currentRoute;
  }

  businessLogic(): void {
    this.showBusiness = !this.showBusiness;
  }

  profileLogic(): void {
    this.showProfile = !this.showProfile;
  }

  closeProfile(): void {
    this.showProfile = !this.showProfile;
    this.settingsLogo();
    const ele = document.getElementById('profile');
    ele?.classList?.remove('block');
    ele?.classList?.add('hidden');
  }

  toggleSubmenu(submenu: string): void {
    this.show[submenu] = !this.show[submenu];
  }

  closeDropdown(submenu: string): void {
    const ele = document.getElementById(submenu);
    ele.classList.remove('block');
    ele.classList.add('hidden');
  }

  showNotifications(): void {
    if (this.collapsed) this.showNotification = true;
  }

  settingsLogo() {
    // this.logo = 'assets/images/logo.svg';
    this.showProfile = !this.showProfile;
    this.openSettingsModal = true;
    this.utlityService.showHideSettingsModal.next({
      open: this.openSettingsModal,
    });
  }

  closeSettingsModal(): void {
    this.openSettingsModal = false;
    this.utlityService.showHideSettingsModal.next({
      open: this.openSettingsModal,
    });
  }

  @HostListener('window:unload', ['$event'])
  unloadNotification($event: any) {
    if (this.shouldPreventClosing()) {
      $event.preventDefault();
      $event.returnValue = '';
    }
  }

  shouldPreventClosing(): boolean {
    if (this.totalWorked !== 0) {
      this.timeSheetService
        .updateActiveTimer(this.timeSheetService.businessId, {
          state: 'STOP',
          totalWorked: this.totalWorked,
          until: moment().utc().format(),
        })
        .subscribe();
    }
    return true;
  }
}
