import { Action, createReducer, on } from '@ngrx/store';
import * as ProjectActions from '../actions/projects.actions';
import * as UserActions from '../actions/user.actions';

export interface ProjectState {
    projects: Array<any>;
}

export const initialState: ProjectState = {
    projects: []
};

export const ProjectsReducer = createReducer(initialState,
    on(ProjectActions.SetProject, (state: ProjectState, {projects}) => ({...state, projects})),
    on(ProjectActions.AddProject, (state: ProjectState, {project}) => ({...state, projects: [...state.projects, {...project}]})),
    on(ProjectActions.updateProject, (state: ProjectState, {project}) => {
        const mappedProjects = state.projects.map((ogProject) => {
            if (ogProject._id === project._id) { return {...project}; }
            return ogProject;
        });
        return { ...state, projects: [...mappedProjects] };
    }),
    on(UserActions.UserLogout, (state: ProjectState) => ({...initialState}))
);

export function reducer(state: ProjectState | undefined, action: Action): any {
    return ProjectsReducer(state, action);
}

export const getProjects = (state: ProjectState) => state.projects;
