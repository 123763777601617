import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { toFixed } from 'src/app/shared/utils/numberFormatting';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { SalesService } from '../../sales.service';
import { selectProducts } from '../../state/selectors/products.selector';
import { selectSalesTaxes } from '../../state/selectors/salesTaxes.selector';
import { ProductsService } from './products.service';

@Component({
  selector: 'app-products-and-services',
  templateUrl: './products-and-services.component.html',
  styleUrls: ['./products-and-services.component.scss']
})
export class ProductsAndServicesComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private productService: ProductsService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private salesService: SalesService) {
    this.business$ = this.store.pipe(select(selectBusiness));
    this.products$ = this.store.pipe(select(selectProducts));
    this.salesTaxes$ = this.store.pipe(select(selectSalesTaxes));
    this.accounts$ = this.store.pipe(select(selectAccounts));
  }
  createProductForm: FormGroup;
  openAddProductModal = false;
  openDeleteConfirmModal = false;
  openUpdateProduct = false;
  error = '';
  products$: Observable<any>;
  business$: Observable<any>;
  salesTaxes$: Observable<any>;
  accounts$: Observable<any>;
  businessId = null;
  productToUpdate = null;
  productToDelete = null;
  salesTaxes = [];
  incomeAccounts = [];
  id;
  update = false;
  unsubscribe$ = new Subject();
  response = '';
  formErrors = {
    name: '',
    description: '',
    accountDetails: '',
    price: '',
  };
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };

  selectedCar;

  formErrorMessages = {
    name: {
      required: 'Name is Required'
    },
    description: {
      required: 'Description is Required'
    },
    price: {
      required: 'Price is Required'
    },
    accountDetails: {
      required: 'Income account is Required'
    },
  };
  tableHeadings = [
    'Name',
    'Description',
    'Price',
    'Actions'
  ];
  tableDataKeys = [];

  products = [
    {
      _id: '',
      name: '',
      description: '',
      price: '',
    }
  ];

  tableData = [
    {
      name: '',
      description: '',
      price: '',
    }
  ];

  ngOnInit(): void {
    this.products$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(products => {
      this.tableData = products.map(item => ({
        ...item,
        price: this.numberService.currencier(item?.price.toFixed(2))
      }));
      this.products = products;
      this.tableDataKeys = ['name', 'description', 'price'];
    });
    this.loadForm();
    this.loadSalesTaxes();
    this.loadAccounts();
    this.getCurrencyDetails();
  }

  loadSalesTaxes(): void {
    this.salesTaxes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(taxes => {
      this.salesTaxes = taxes.map(item => ({...item, tax: this.numberService.toFixed(item?.taxRate)}));
    });
  }

  getCurrencyDetails(): void {
    this.salesService.currencyDetail
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(details => {
      if (details) {
        this.currencyDetails = details;
      }
    });
  }

  loadAccounts(): void {
    this.accounts$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(accounts => {
      if (accounts?.length > 0) {
        console.log(accounts);
        this.incomeAccounts = accounts
        .filter(account => account.accountType === 'Income' && account.accountSection === 'Income' && !account.isArchive);
      }
    });
  }

  loadForm(): void {
    this.createProductForm = this.fb.group({
      name: ['', [Validators.required]],
      description: ['', [Validators.required]],
      tax: [[]],
      price: [0, [Validators.required]],
      accountDetails: [null, [Validators.required]]
    });

    this.createProductForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
  }
  updateForm(product: any): void {
    const mappedTaxes = product?.tax.map(({taxName, tax, accountDetails}) => ({taxName, tax: this.numberService.toFixed(tax), accountDetails}));
    const mappedAccountDetails = {
      ...product?.accountDetails,
      _id: product?.accountDetails?.accountId,
    };
    const productToUpdate = (({
      name,
      description,
      price
    }) => ({
      name,
      description,
      accountDetails: mappedAccountDetails,
      price: this.numberService.toFixed(price),
      tax: mappedTaxes
    }))(product);
    this.createProductForm.setValue(productToUpdate);
  }

  taxChange(taxes): void {
    const mappedTaxes = taxes.map(({taxName, tax, accountDetails}) => ({taxName, tax: this.numberService.toFixed(tax), accountDetails}));
    this.createProductForm.get('tax').setValue(mappedTaxes);
  }

  createProduct(): void {
    if (this.createProductForm.invalid) {
      this.createProductForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    const body = {
      _id: this.businessId,
      ...this.createProductForm.value,
      accountDetails: {
        accountId: this.createProductForm.value.accountDetails._id,
        accountName: this.createProductForm.value.accountDetails.accountName,
        accountSection: this.createProductForm.value.accountDetails.accountSection,
      },
      price: this.numberService.toFixed(this.createProductForm.value?.price),
      productType: 'sell'
    };
    this.spinner.show();
    this.productService.createProducts(body).subscribe((resp) => {
      this.spinner.hide();
      this.openAddProductModal = false;
      this.toastr.success(resp?.message);
      this.productService.addProductToStore(resp.data);
      this.createProductForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }

  modalProductUpdate(id): void {
    this.update = true;
    const product = this.products.find(({ _id }) => _id === id);
    this.openAddProductModal = true;
    this.updateForm(product);
    this.productToUpdate = id;
  }

  closeProductUpdateModal(): void {
    this.openAddProductModal = false;
    this.createProductForm.reset();
  }

  updateProduct(): void {
    this.error = '';
    this.response = '';
    if (this.createProductForm.invalid) {
      this.createProductForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createProductForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }
    const body = {
      _id: this.productToUpdate,
      ...this.createProductForm.value,
      accountDetails: {
        accountId: this.createProductForm.value.accountDetails._id,
        accountName: this.createProductForm.value.accountDetails.accountName,
        accountSection: this.createProductForm.value.accountDetails.accountSection,
      },
      price: this.numberService.toFixed(this.createProductForm.value?.price)
    };
    this.spinner.show();
    this.productService.updateProduct(body).subscribe((resp) => {
      this.spinner.hide();
      this.openAddProductModal = false;
      if (resp?.success){
        this.toastr.success(resp?.message);
        this.productService.updateProductInStore(body);
      }
      this.createProductForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }

  openDeleteConfirmation(id): void {
    this.productToDelete = id;
    this.openDeleteConfirmModal = true;
  }

  deleteProduct(): void {
    this.spinner.show();
    this.productService.deleteProducts(this.productToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.openDeleteConfirmModal = false;
      if (resp?.success){
        this.productService.deleteProductInStore(this.productToDelete);
        this.toastr.success(resp?.message);
      }
      // this.tableData = this.products.filter(({ _id }) => _id !== this.productToDelete);
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
