import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as cc from 'currency-codes';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { getAllCountries } from 'src/app/shared/utils/countryStateData';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { getAllMonths, getDates } from 'src/app/shared/utils/monthDateData';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-personal-details',
  templateUrl: './personal-details.component.html',
  styleUrls: ['./personal-details.component.scss'],
})
export class PersonalDetailsComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private store: Store<RootReducerState>,
    private userService: UserService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private utility: UtilityService
  ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  user$: Observable<any>;

  personalDetailForm: FormGroup;
  error = '';
  show = false;
  formErrors = {
    firstName: '',
    lastName: '',
    businessName: '',
    businessIndustry: '',
    businessCurrency: '',
    businessOrganisationType: '',
    country: '',
    month: '',
    date: '',
  };
  formErrorMessages = {
    firstName: {
      required: 'First Name is Required',
    },
    lastName: {
      required: 'Last Name is Required',
    },
    businessName: {
      required: 'Business Name is Required',
    },
    businessIndustry: {
      required: 'Business Industry is Required',
    },
    businessCurrency: {
      required: 'Business Currency is Required',
    },
    businessOrganisationType: {
      required: 'Organisation Type is Required',
    },
    country: {
      required: 'Business Country is Reqired',
    },
    month: {
      required: 'Month is Required',
    },
    date: {
      required: 'Date is Required',
    },
  };

  industrySelect;
  currenySelect;
  organisationSelect;
  countrySelect;
  dateSelect;
  monthSelect;
  allMonths = getAllMonths();
  allCountries = getAllCountries();
  allDaysOfMonth;

  industries = [
    { text: 'Business services' },
    { text: 'Accounting' },
    { text: 'Advisory' },
    { text: 'Taxation' },
    { text: 'Advertising' },
    { text: 'Engineering' },
    { text: 'Legal' },
    { text: 'Research' },
    { text: 'Agencies' },
    { text: 'Computer' },
    { text: 'Programming' },
    { text: 'Technology' },
  ];

  organisations = [
    { text: 'Sole' },
    { text: 'Partnership' },
    { text: 'Corporation' },
    { text: 'Other' },
  ];

  currencyCodes;

  ngOnInit(): void {
    console.log('allMonths', this.allMonths);

    this.loadForm();
    this.loadSelectData();
    this.user$.subscribe((user) => {
      if (!user.token) {
        let businessId = localStorage.getItem('selectedBusiness');
        this.userService.getUserDetails(businessId).subscribe(
          (userResp) => {
            this.userService.onGetUserDetailsSuccess(userResp.data);
          },
          (error) => this.logout()
        );
      }
      if (user.token && user.businesses.length > 0) {
        this.router.navigate(['/dashboard']);
        this.utility.showHideSettingsModal.next({
          open: true,
          tab: 'userProfile',
        });
      }
    });
  }

  loadForm(): void {
    this.personalDetailForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      businessName: ['', [Validators.required]],
      businessIndustry: [null, Validators.required],
      businessCurrency: ['', Validators.required],
      businessOrganisationType: ['', Validators.required],
      country: ['', [Validators.required]],
      month: ['', Validators.required],
      date: ['', Validators.required],
    });
    this.personalDetailForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(
        this.personalDetailForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    });
    this.formErrors = valueChanges(
      this.personalDetailForm,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.userService.logout();
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }

  loadSelectData(): void {
    // this.industrySelect = new SlimSelect({
    //   select: '#industry-select',
    //   placeholder: 'Select Industry',
    //   data: [...this.industries]
    // });
    // this.organisationSelect = new SlimSelect({
    //   select: '#organisation-select',
    //   placeholder: 'Select Organisation Type',
    //   data: [...this.organisations]
    // });
    this.currencyCodes = cc.codes().map((code) => ({ text: code }));
    // this.currenySelect = new SlimSelect({
    //   select: '#currency-select',
    //   placeholder: 'Select Business Currency',
    //   data: [...currencyCodes]
    // });

    // this.countrySelect =  new SlimSelect({
    //   select: '#country-select',
    //   placeholder: 'Select Business Country',
    //   data: [...getAllCountries()]
    // });

    // this.dateSelect = new SlimSelect({
    //   select: '#date-select',
    //   placeholder: 'Select Date'
    // });

    // this.monthSelect = new SlimSelect({
    //   select: '#month-select',
    //   placeholder: 'Select Month',
    //   data: [...getAllMonths()],
    //   onChange: (info: any) => {
    //     this.personalDetailForm.controls.date.patchValue(getDates(info.value)[0].text);
    //     const data = getDates(info.value).map(date => {
    //       if (date === 1) {
    //         return { ...date, selected: true };
    //       }
    //       return date;
    //     });
    //     this.dateSelect.setData([...data]);
    //   }
    // });
  }

  onMonthChange(event): void {
    this.personalDetailForm.controls.date.patchValue(
      getDates(event.target.value)[0].text
    );
    const data = getDates(event.target.value).map((date) => {
      // if (date === 1) {
      //   return { ...date, selected: true };
      // }
      return date;
    });
    console.log('event', data);
    this.allDaysOfMonth = data;
    // this.dateSelect.setData([...data]);
  }

  sendPersonalDetails(): void {
    this.error = '';
    if (this.personalDetailForm.invalid) {
      this.personalDetailForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.personalDetailForm,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    } else {
      const {
        firstName,
        lastName,
        businessName,
        businessIndustry,
        businessCurrency,
        businessOrganisationType,
        country,
        month,
        date,
      } = this.personalDetailForm.value;
      const body = {
        personalDetails: { firstName, lastName },
        businessDetails: {
          businessName,
          businessType: businessIndustry,
          currency: businessCurrency,
          organization: businessOrganisationType,
          country,
          month,
          date,
        },
      };
      this.spinner.show();
      this.authService.addPersonDetails(body).subscribe(
        (resp) => {
          this.spinner.hide();
          this.authService.onAddPersonalDetailsSuccess(resp);
        },
        (error) => {
          this.error = error.error?.message || 'Internal Server Error';
          this.spinner.hide();
        }
      );
    }
  }
}
