import { state } from '@angular/animations';
import { createReducer, Action, on } from '@ngrx/store';
import { number } from 'currency-codes';
import * as usageActions from '../actions/usage.action';
import * as UserActions from "../actions/user.actions";

export interface UsageState {
    subscriptionLimit: {
        transactionLimit: number,
        businessLimit: number,
        adminLimit: number,
        supportEmail: number,
        storeLimit: number
    };
    currentUsage: {
        transactionLimit: number,
        businessLimit: number,
        adminLimit: number,
        supportEmail: number,
        storeLimit: number
    };
}

export const initialState: UsageState = {
    subscriptionLimit: {
        transactionLimit: 0,
        businessLimit: 0,
        adminLimit: 0,
        supportEmail: 0,
        storeLimit: 0
    },
    currentUsage: {
        transactionLimit: 0,
        businessLimit: 0,
        adminLimit: 0,
        supportEmail: 0,
        storeLimit: 0
    }
};

export const UsageReducer = createReducer(initialState,
    on(usageActions.UpdateTransactionLimit, (state: UsageState, { transactionLimit, subscriptionTransactionLimit }) => {
        return {
            ...state,
            currentUsage: { ...state.currentUsage, transactionLimit },
            subscriptionLimit: { ...state.subscriptionLimit, transactionLimit: subscriptionTransactionLimit }
        };
    }),
    on(usageActions.UpdateBusinessLimit, (state: UsageState, { businessLimit, subscriptionBusinessLimit }) => {
        return {
            ...state,
            currentUsage: { ...state.currentUsage, businessLimit },
            subscriptionLimit: { ...state.subscriptionLimit, businessLimit: subscriptionBusinessLimit }
        };
    }),
    on(usageActions.UpdateSupportEmailLimit, (state: UsageState, { supportEmail, subscriptionSupportEmail }) => {
        return { ...state, currentUsage: { ...state.currentUsage, supportEmail }, subscriptionLimit: { ...state.subscriptionLimit, supportEmail: subscriptionSupportEmail } };
    }),
    on(usageActions.UpdateAdminLimit, (state: UsageState, { adminLimit, subscriptionAdminLimit }) => {
        return { ...state, currentUsage: { ...state.currentUsage, adminLimit }, subscriptionLimit: { ...state.subscriptionLimit, adminLimit: subscriptionAdminLimit } };
    }),
    on(usageActions.UpdateStoreLimit, (state: UsageState, { storeLimit, subscriptionStoreLimit }) => {
        return { ...state, currentUsage: { ...state.currentUsage, storeLimit }, subscriptionLimit: { ...state.subscriptionLimit, storeLimit: subscriptionStoreLimit } };
    }),
    on(usageActions.updateCurrentTransactionLimit, (state: UsageState, {transactionLimit}) => {
        return {... state, currentUsage: {...state.currentUsage, transactionLimit}}
    }),
    on(usageActions.updateCurrentBusinessLimit, (state: UsageState, {businessLimit}) => {
        return {... state, currentUsage: {...state.currentUsage, businessLimit}}
    }),
    on(usageActions.updateCurrentAdminLimit, (state: UsageState, {adminLimit}) => {
        return {... state, currentUsage: {...state.currentUsage, adminLimit}}
    }),
    on(usageActions.updateCurrentSupportLimit, (state: UsageState, {supportEmail}) => {
        return {... state, currentUsage: {...state.currentUsage, supportEmail}}
    }),
    on(usageActions.updateCurrentStoreLimit, (state: UsageState, {storeLimit}) => {
        return {... state, currentUsage: {...state.currentUsage, storeLimit}}
    }),
);


export function reducer(state: UsageState | undefined, action: Action): any {
    return UsageReducer(state, action);
}

export const getUsage = (state: UsageState) => state;

