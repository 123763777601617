import { HttpClient } from '@angular/common/http';
import { Component, HostListener, OnInit, Renderer2 } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { initFlowbite } from 'flowbite';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment.prod';
import Tap from 'src/tapfiliate';
import { LanguageService } from './shared/services/language.service';
import { RootReducerState } from './store/reducers';
import { selectUserSubscription } from './store/selectors/subscription.selectors';
import { VersionCheckService } from './version-check.service';
import { CollapsibleSidebarService } from './modules/sidebar/collapsible-sidebar/collapsible-sidebar.service';
import { GlobalTimerService } from './shared/components/global-timer/global-timer.service';
import { TimeSheetService } from './modules/time-sheet/time-sheet.service';
import moment = require('moment');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  direction = 'ltr';
  lang = 'en';
  showSidebar = false;
  showSettingsSidebar = false;
  showPlusSidebar: boolean;
  userIp: any;
  unsubscribed = new Subject();
  lat: any;
  lng: any;
  showModal: boolean = false;
  redirectLink: string = '';
  collapsed: boolean;
  showTimer: boolean;

  userCountry: string = '';
  isMobileDevice = false;

  subscription$: Observable<any>;
  constructor(
    private languageService: LanguageService,
    private translateService: TranslateService,
    private store: Store<RootReducerState>,
    private router: Router,
    private versionCheck: VersionCheckService,
    private httpClient: HttpClient,
    private toastr: ToastrService,
    private renderer: Renderer2,
    private deviceService: DeviceDetectorService,
    private sidebar: CollapsibleSidebarService,
    private globalTimerService: GlobalTimerService,
    private timeSheetService: TimeSheetService,
  ) {
    this.subscription$ = store.pipe(select(selectUserSubscription));
    this.translateService.setDefaultLang('en');
    this.languageService.getLanguage().subscribe((language) => {
      this.lang = language;
    });
    if (this.lang === 'ar') {
      this.direction = 'rtl';
    }

    this.translateService.use(this.lang || 'en');

    this.isMobileDevice = this.deviceService.isMobile();
    console.log('isMobile', this.isMobileDevice);
  }

  ngOnInit(): void {
    initFlowbite();
    this.versionCheck.initVersionCheck(environment.versionCheckUrl);
    Tap.init('28462-1b2a4b');
    let route = this.router.url.split('/')[1];
    this.checkRoute(route);
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        const script = this.renderer.createElement('script');
        script.type = 'text/javascript';
        script.src = 'https://app.termly.io/embed.min.js';
        script.setAttribute('data-auto-block', 'on');
        script.setAttribute(
          'data-website-uuid',
          '1c69d0cb-7f80-4752-9acf-33672bf7ab66'
        );
        if (this.router.url === '/') {
          this.renderer.appendChild(document.body, script);
        } else {
          const scripts = document.querySelectorAll(
            `script[src="https://app.termly.io/embed.min.js"]`
          );
          scripts.forEach((script) => script.remove());
          const popup = document.querySelector('#termly-code-snippet-support');
          if (popup) {
            popup.remove();
          }
        }
        route = this.router.url.split('/')[1];
        this.checkRoute(route);
        this.getUserLocation(route);
      });
    this.sidebar.collapsed$.subscribe((t) => (this.collapsed = t));
    this.globalTimerService.showTimer$
      .pipe(
        tap((data) => {
          this.showTimer = data;
        })
      )
      .subscribe()
  }

  checkRoute(route): void {
    const routes = [
      '',
      'auth',
      'open',
      'contact',
      'zapier',
      'termandconditions',
      'privacyandpolicy',
      'eula',
      'connect-with-zapier',
    ];
    if (
      routes.includes(route) ||
      (route.startsWith('#') && this.isMobileDevice)
    ) {
      this.showSidebar = false;
    } else {
      this.showSidebar = true;
      route === 'settings'
        ? (this.showSettingsSidebar = true)
        : (this.showSettingsSidebar = false);
      route === 'plus'
        ? (this.showPlusSidebar = true)
        : (this.showPlusSidebar = false);
      // this.router.url.split('/')[3] === 'create-budget'
      //   ? this.showSidebar = false
      //   : this.showSidebar= true
    }
    console.log('showSidebar', this.showSidebar);
  }

  getUserLocation(currentRoute) {
    if (currentRoute !== 'open' && this.router.url === '/') {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            if (position) {
              console.log(
                'Latitude: ' +
                  position.coords.latitude +
                  'Longitude: ' +
                  position.coords.longitude
              );
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;
              console.log(this.lat);
              console.log(this.lng);
            }
          },
          (error) => console.log(error)
        );
      } else {
        console.log('Geolocation is not supported by this browser.');
      }
      this.httpClient
        .get(
          `https://api.bigdatacloud.net/data/reverse-geocode-client?latitude=${this.lat}&longitude=${this.lng}&localityLanguage=en`
        )
        .subscribe(
          (resp: any) => {
            this.userCountry = resp.countryName;
            console.log(this.userCountry);
            if (
              this.userCountry == 'Jordan' ||
              this.userCountry == 'Saudi Arabia'
            ) {
              if (!localStorage.getItem('modalChecked')) {
                this.showModal = true;
                this.redirectLink =
                  this.userCountry == 'Jordan'
                    ? 'http://jo.numetric.work'
                    : 'http://sa.numetric.work';
              }
            }
          },
          (error) => {
            console.log('Location not found');
          }
        );
    }
  }

  toggleModal() {
    this.showModal = !this.showModal;
    localStorage.setItem('modalChecked', 'true');
  }
}
