import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { HttpService } from './http.service';
import * as BusinessActions from 'src/app/store/actions/business.actions';
import * as StoreActions from 'src/app/store/actions/store.actions';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {
    constructor(private httpService: HttpService, private store: Store<RootReducerState>) {}

    createBusiness(data): Observable<any> {
        return this.httpService.post(`/business/createBusiness`, data).pipe(catchError((error) => throwError(error)));
    }

    getBusinessDetailsById(id): Observable<any> {
        return this.httpService.get(`/business/businessDetails/${id}`).pipe(catchError((error) => throwError(error)));
    }

    setBusiness(business): void {
        this.store.dispatch(BusinessActions.SetBusiness({business}));
    }

    updateBusiness(data): Observable<any> {
        return this.httpService.putMultipart('/business/updateBusiness', data);
    }

    updateBusinessInStore(business): void {
        return this.store.dispatch(BusinessActions.UpdateBusiness({business}));
    }

    getUsage(businessId): Observable<any> {
        return this.httpService.get(`/business/getLimitBusiness/${businessId}`);
    }
    addMailingId(businessId, email): Observable<any> {
        return this.httpService.post(`/business/verifyEmailForBusiness/${businessId}`, {email});
    }
    checkMailingId(businessId, email): Observable<any> {
        return this.httpService.post(`/business/injectVerifyEmail/${businessId}`, {email});
    }
    deleteMailingId(businessId, email): Observable<any> {
        return this.httpService.delete(`/business/deleteVerifyEmail/${businessId}`, {email});
    }
    getStores(businessId): Observable<any> {
        return this.httpService.get(`/store/getStores/${businessId}`);
    }
    getStoreDetails(storeId): Observable<any> {
        return this.httpService.get(`/store/getStoreDetails/${storeId}`);
    }
    updateStore(store): Observable<any> {
        return this.httpService.post(`/store/updateStore/${store?.storeId}`, store);
    }
    setStore(stores): void {
        console.log(stores, 'service');
        
       return this.store.dispatch(StoreActions.SetStore({stores}))
    }
    updateStoreInStore(store): void {
        return this.store.dispatch(StoreActions.updateStore(store));
    }
    addStoreInStore(newStore): void {
        return this.store.dispatch(StoreActions.AddStore({store:{...newStore}}));
    }
    createStore(store, businessId): Observable<any> {
        return this.httpService.post(`/store/createStore/${businessId}`, store);
    }
    deleteStore(storeId): Observable<any> {
        return this.httpService.delete(`/store/deleteStore/${storeId}`);
    }
    deleteStoreFromStore(storeId): void {
        return this.store.dispatch(StoreActions.DeleteStore({storeId}));
    }
}
