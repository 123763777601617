import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-payroll-subscription-list',
  templateUrl: './payroll-subscription-list.component.html',
  styleUrls: ['./payroll-subscription-list.component.scss']
})
export class PayrollSubscriptionListComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();
  subscriptionPlans = [];
  currentSubscription = null;
  cancelSubscriptionPlanModal: boolean = false;

  constructor(
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private toastr:ToastrService,
  ) { }

  ngOnInit(): void {
    this.loadCurrentSubscription();
    this.loadSubscriptionPlans();
  }

  loadSubscriptionPlans(): void{
    this.spinner.show();
    this.subscriptionService.getPayrollPlans()
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        if(resp?.data?.length > 0){
          this.subscriptionPlans = resp?.data;
          this.spinner.hide();
        }
      }
      else {
        this.toastr.error(resp?.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  loadCurrentSubscription(): void {
    this.spinner.show();
    this.subscriptionService.getPayrollSubscriptionInfo()
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.currentSubscription = resp?.data;
        this.spinner.hide();
      }
      else{
        this.toastr.success(resp?.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  getSubscriptionLink(plan): void {
    let body = {
      id: plan._id,
      planId: plan.paypalData.planId,
      expiryDate: this.currentSubscription.expiryDate
    };
    this.spinner.show();
    this.subscriptionService.getPayrollSubscriptionLink(body).subscribe(resp => {
      this.spinner.hide();
      const url = resp.data.links[0].href;
      window.location.href = url;
    }, error => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message);
    });
  }

  cancelPlan(): void {
    const body ={
      subId: this.currentSubscription?.subId
    }
    this.spinner.show();
    this.cancelSubscriptionPlanModal = false;
    this.subscriptionService.cancelPlan(body)
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message);
      }
      this.spinner.hide();
    },(err) =>{
      this.spinner.hide();
      this.toastr.error(err?.message || 'Something went wrong');
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
