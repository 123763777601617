import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FilesListComponent } from './components/files-list/files-list.component';
import { SharedWithMeComponent } from './components/shared-with-me/shared-with-me.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { FileManagerComponent } from './file-manager.component';
import { ClickOutsideModule } from 'ng-click-outside';


const router: Routes = [
  {
    path: '',
    component: FileManagerComponent,
    children: [
      {
        path: '',
        component: FilesListComponent
      },
      {
        path: 'shared',
        component: SharedWithMeComponent
      }
    ]
  }
]

@NgModule({
  declarations: [
    FilesListComponent,
    SharedWithMeComponent,
    FileManagerComponent
  ],
  imports: [
    RouterModule.forChild(router),
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    RouterModule,
    ClickOutsideModule
  ]
})
export class FileManagerModule { }
