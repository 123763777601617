<div class="w-full p-8 space-y-6">
  <div class="bg-white px-14 py-11 rounded-xl space-y-2 flex flex-col">
    <div class="w-full flex justify-between">
      <h4 class="text-base text-gray-600 self-end">
        {{ "Filter" | translate }}
      </h4>
      <div class="flex gap-2 items-center">
        <app-button
          buttonText="{{ 'Export' | translate }}"
          size="large"
          type="primary"
          (handleClick)="ExportToPdf()"
        ></app-button>
        <div
          class="px-3 h-[50px] flex text-center bg-input-light justify-center items-center rounded-lg shadow gap-2"
        >
          <div class="text-sm font-Medium">{{ "Total Work:" | translate }}</div>
          <div class="text-sm">
            {{ weeklyTotal | timeFormat }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-full flex justify-between">
      <div class="flex gap-2 flex-wrap" [formGroup]="filterForm">
        <div class="">
          <label class="Poppins">{{ "Select Customer" | translate }}</label>
          <ng-select
            [items]="customers"
            class="new-dropdown"
            bindLabel="customerName"
            bindValue="_id"
            formControlName="customerId"
            placeholder="{{ 'Type Customer Name' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.customerName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="self-end">
          <label class="Poppins">{{ "Select Projects" | translate }}</label>
          <ng-select
            [items]="projects"
            class="new-dropdown"
            bindLabel="projectName"
            bindValue="_id"
            formControlName="projectId"
            placeholder="{{ 'Type Project Name' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.projectName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="self-end">
          <label class="Poppins">{{ "Select Task" | translate }}</label>
          <ng-select
            [items]="tasks"
            class="new-dropdown"
            bindLabel="taskTitle"
            bindValue="_id"
            formControlName="taskId"
            placeholder="{{ 'Type Task Name' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.taskTitle }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
        <div class="self-end">
          <label class="Poppins" for="form">{{ "From" | translate }}</label>
          <input formControlName="from" type="date" class="new-date" />
        </div>
        <div class="self-end">
          <label class="Poppins" for="to">{{ "To" | translate }}</label>
          <input formControlName="to" type="date" class="new-date" />
        </div>
        <div class="self-end">
          <app-button
            buttonText="{{ 'Filter' | translate }}"
            type="primary"
            (handleClick)="filterData()"
          ></app-button>
        </div>
      </div>
    </div>
    <table
      class="w-full max-w-[90rem] justify-self-center border-separate mt-2"
      style="border-spacing: 0 1rem"
    >
      <thead>
        <tr class="h-12 mb-4">
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left w-40"
          >
            {{ "Time-Sheet Date" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left w-32"
          >
            {{ "Time Period" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left"
          >
            {{ "Description" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left"
          >
            {{ "Customer" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left"
          >
            {{ "Project" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left"
          >
            {{ "Task" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 rtl:text-right ltr:text-left w-20"
          >
            {{ "Type" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left w-24"
          >
            {{ "Status" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 w-20"
          >
            {{ "Paused" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 w-20"
          >
            {{ "Worked" | translate }}
          </td>
          <td
            class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 px-3 rtl:text-right ltr:text-left w-20"
          >
            {{ "Action" | translate }}
          </td>
        </tr>
      </thead>
      <tbody class="divide-y">
        <tr
          *ngFor="
            let dataItem of tableData
              | paginate
                : {
                    itemsPerPage: itemsPerPage,
                    currentPage: currentPage,
                    totalItems: totalCount
                  }
          "
          class="h-12"
        >
          <td class="p-2">
            {{ dataItem?.timeSheetDate | date : "MMM dd, y" | empty }}
          </td>
          <td class="p-2">
            {{ dataItem?.by | date : "HH:mm" | empty }} -
            {{ dataItem?.until | date : "HH:mm" | empty }}
          </td>
          <td class="p-2">{{ dataItem?.description | empty }}</td>
          <td class="p-2">{{ dataItem?.customerName | empty }}</td>
          <td class="p-2">{{ dataItem?.projectName | empty }}</td>
          <td class="p-2">{{ dataItem?.taskTitle | empty }}</td>
          <td class="p-2">
            {{ (dataItem?.isManual ? "Manual" : "Auto") | translate }}
          </td>
          <td class="p-2 w-8 first:ps-5">
            <span
              class="px-2 text-sm font-semiMedium whitespace-nowrap"
              [title]="dataItem?.isMark ? 'Reviewed' : 'Not Reviewed yet'"
              [ngClass]="
                dataItem?.isMark ? 'text-green-400' : 'text-[#1C1C1C66]'
              "
              >{{
                (dataItem?.isMark ? "Reviewed" : "Not Reviewed") | translate
              }}</span
            >
          </td>
          <td class="p-2 px-3">{{ dataItem?.paused | timeFormat }}</td>
          <td class="p-2 px-3">
            {{ dataItem?.totalWorked | timeFormat }}
          </td>
          <td class="p-2 text-center">
            <app-dropdown #dropdown>
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options class="p-2">
                <div
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] rtl:text-right ltr:text-left"
                  (click)="openUpdateModal(dataItem)"
                >
                  {{ "View" | translate }}
                </div>
                <div
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] rtl:text-right ltr:text-left"
                  (click)="openUpdateModal(dataItem, true)"
                  *ngIf="!dataItem.isMark"
                >
                  {{ "Edit" | translate }}
                </div>
                <div
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] rtl:text-right ltr:text-left text-red-600"
                  (click)="openConfirmDeleteTimeSheetModal(dataItem)"
                  *ngIf="!dataItem.isMark"
                >
                  {{ "Delete" | translate }}
                </div>
              </div>
            </app-dropdown>
          </td>
        </tr>
        <tr *ngIf="tableData.length !== 0">
          <td colspan="11" class="text-right Poppins-Medium pe-16">
            {{ "Page Total" | translate }} - {{ pageTotal | timeFormat }}
          </td>
        </tr>
        <tr *ngIf="tableData.length === 0">
          <td colspan="11" class="text-center">
            {{ "No record found" | translate }}
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      class="new-pagination"
      previousLabel=""
      nextLabel=""
      autoHide="true"
      (pageChange)="filterData($event)"
    ></pagination-controls>
  </div>
</div>

<app-modal-container [isOpen]="showUpdateModal">
  <div header class="Poppins-Medium" *ngIf="!isEditable">
    {{ "Time Sheet Detail" | translate }}
  </div>
  <div header class="Poppins-Medium" *ngIf="isEditable">
    {{ "Update Time Sheet" | translate }}
  </div>
  <div
    content
    class="flex flex-col space-y-2"
    [formGroup]="updateRegisterHourForm"
  >
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label for="status" class="block text-sm Poppins-Medium font-color-05">
        {{ "Status" | translate }}
      </label>
      <div class="flex gap-4">
        <div class="flex gap-4">
          <span
            class="text-white py-2 px-4 rounded-xl text-sm font-semiMedium"
            [title]="dataItem?.isMark ? 'Reviewed' : 'Not Reviewed yet'"
            [ngClass]="dataItem?.isMark ? 'bg-green-400' : 'bg-gray-300'"
            >{{ "Reviewed" | translate }}</span
          >
        </div>
      </div>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label
        for="time-sheet-date"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Time Sheet Date" | translate }}
      </label>
      {{ dataItem?.timeSheetDate | date : "MMM dd, y" | empty }}
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="from" class="block text-sm Poppins-Medium font-color-05">
        {{ "From" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="from"
        formControlName="by"
        type="datetime-local"
        class="new-date"
        placeholder="00:00"
        [min]="minDateTime"
        [max]="maxDateTime"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.by | date : "MMM dd, y HH:mm" | empty }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.by" class="text-red-600">
      {{ updateFormErrors?.by }}
    </span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="until" class="block text-sm Poppins-Medium font-color-05">
        {{ "Until" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="until"
        formControlName="until"
        type="datetime-local"
        class="new-date"
        placeholder="00:00"
        [min]="minDateTime"
        [max]="maxDateTime"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.until | date : "MMM dd, y HH:mm" | empty }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.until" class="text-red-600">{{
      updateFormErrors?.until
    }}</span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="paused" class="block text-sm Poppins-Medium font-color-05">
        {{ "Break duration" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="paused"
        formControlName="paused"
        type="number"
        min="0"
        class="new-input"
        placeholder="{{ 'Enter break duration in minutes' | translate }}"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.paused | timeFormat }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.paused" class="text-red-600">{{
      updateFormErrors?.paused
    }}</span>
    <div
      class="rounded-xl px-3 py-2 space-y-2 bg-white"
      *ngIf="!isEditable && dataItem?.taskDuration"
    >
      <label for="paused" class="block text-sm Poppins-Medium font-color-05">
        {{ "Task duration" | translate }}
      </label>
      <p>
        {{ dataItem?.taskDuration * 3600 | timeFormat }}
      </p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label
        for="time-sheet-date"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Total Work" | translate }}
      </label>
      <p>
        {{ dataItem?.totalWorked | timeFormat }}
      </p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label
        for="description"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Description" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="description"
        formControlName="description"
        type="text"
        class="new-input"
        placeholder="Enter description"
      />
      <p *ngIf="!isEditable">{{ dataItem?.description | empty }}</p>
    </div>
    <span *ngIf="updateFormErrors?.description" class="text-red-600">{{
      updateFormErrors?.description
    }}</span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="customer" class="block text-sm Poppins-Medium font-color-05">
        {{ "Customer" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="5"
        [items]="customers"
        class="new-dropdown"
        bindLabel="customerName"
        bindValue="_id"
        formControlName="customerId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.customerName }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.customerName | empty }}</p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="project" class="block text-sm Poppins-Medium font-color-05">
        {{ "Project" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="6"
        [items]="projects"
        class="new-dropdown"
        bindLabel="projectName"
        bindValue="_id"
        formControlName="projectId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.projectName }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.projectName | empty }}</p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="task" class="block text-sm Poppins-Medium font-color-05">
        {{ "Task" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="6"
        [items]="tasks"
        class="new-dropdown"
        bindLabel="taskTitle"
        bindValue="_id"
        formControlName="taskId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.taskTitle }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.taskTitle | empty }}</p>
    </div>
    <span *ngIf="updateFormErrors?.customerId" class="text-red-600">{{
      updateFormErrors?.customerId
    }}</span>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      *ngIf="isEditable"
      type="primary"
      buttonText="{{ 'Update' | translate }}"
      (handleClick)="handleUpdate()"
    ></app-button>
    <app-button
      *ngIf="isEditable"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="closeUpdateModal()"
    ></app-button>
    <app-button
      *ngIf="!isEditable"
      buttonText="{{ 'Close' | translate }}"
      (handleClick)="showUpdateModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="confirmDeleteTimeSheetModal">
  <div header class="Poppins-Medium">
    {{ "Delete Time Sheet" | translate }}
  </div>
  <div content>
    <p>{{ "Are you sure you want to delete this time sheet?" | translate }}</p>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="danger"
      buttonText="{{ 'Delete' | translate }}"
      (handleClick)="handleDelete()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="confirmDeleteTimeSheetModal = false"
    ></app-button>
  </div>
</app-modal-container>
