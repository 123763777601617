import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-zapier-working',
  templateUrl: './zapier-working.component.html',
  styleUrls: ['./zapier-working.component.scss']
})
export class ZapierWorkingComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
