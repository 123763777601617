import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { HttpService } from 'src/app/shared/services/http.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import * as AccountsActions from 'src/app/store/actions/account.actions';

@Injectable({
  providedIn: 'root'
})
export class NumplusService {

  constructor(
    private store: Store<RootReducerState>,
            private httpService: HttpService,
  ) { }

  private businessId = new BehaviorSubject(null);
  currencyDetail = new BehaviorSubject(null);
  businessId$ = this.businessId.asObservable();

  setBusinessId(id): void {
    this.businessId.next(id);
  }

  setCurrencyDetails(details): void{
    if(details){
      this.currencyDetail.next(details);
    }
  }

  setAccountsInStore(accounts): void {
    this.store.dispatch(AccountsActions.SetAccounts({accounts}));
  }

  getAllAccounts(businessId): Observable<any> {
    return this.httpService.get(`/account/getAccounts/${businessId}`);
  }

  getBudgetList():Observable<any>{
    return this.httpService.get(`/budget/getBudgetList/${this.businessId.getValue()}`);
  }

  createBudget(data): Observable<any> {
    return this.httpService.post(`/budget/createBudget/`, data);
  }


  getBusinessBudget(data): Observable<any>{
    return this.httpService.post(`/budget/getBusinessBudget/${this.businessId.getValue()}`, data)
  }

  updateBudget(data):Observable<any>{
    return this.httpService.post('/budget/updateBudget', data)
  }

  deleteBudget(data):Observable<any>{
    return this.httpService.delete(`/budget/deleteBusinessBudget/${this.businessId.getValue()}`, data)
  }

  excelReportOfBudget(data):Observable<any>{
    return this.httpService.post(`/budget/getYearAccountExcelFile/${this.businessId.getValue()}`,data)
  }

  reportOfBudgetMonth(data):Observable<any>{
    return this.httpService.post(`/budget/getMonthAccountBudgetExcelData/${this.businessId.getValue()}`, data)
  }


  getFinanceMonth(data):Observable<any>{
    return this.httpService.post(`/budget/getMonthAccountBudgetData/${this.businessId.getValue()}`, data)
  }

  getFinanceYear(data):Observable<any>{
    return this.httpService.post(`/budget/getYearAccountBudgetData/${this.businessId.getValue()}`, data)
  }

  getCashFlow(data): Observable<any>{
    return this.httpService.post(`/budget/getCashFlow/${this.businessId.getValue()}`, data)
  }

  getSalesStatisticFlow(data): Observable<any>{
    return this.httpService.post(`/budget/getSalesStatisticData/${this.businessId.getValue()}`, data)
  }

  getAccountBudgetStatisticFlow(data): Observable<any>{
    return this.httpService.post(`/budget/getAccountBudgetStatisticData/${this.businessId.getValue()}`, data)
  }

  monthlyPdfForBudget(data): Observable<any>{
    return this.httpService.post(`/budget/monthlyPdfForBudget/${this.businessId.getValue()}`, data)
  }

  reportMonthRight(data):Observable<any>{
    return this.httpService.post(`/budget/getYearlyAccountExcelPdf/${this.businessId.getValue()}`, data)
  }

}
