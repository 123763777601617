<div class="p-7 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Poppins-Bold text-xl mb-4">{{'Reminder settings' | translate }}</h3>
    <p class="Poppins-SemiBold mb-4">{{'Reminder message' | translate }}</p>
    <div class="flex flex-col gap-5 mb-4">
        <div>
            <label class="inline-flex items-center">
                <input [formControl]='reminder' type="checkbox" class="me-2">
                <span class="block Poppins-SemiBold">{{'Remind customers 3, 7, and 14 days after due date' | translate}}</span>
            </label>
            <p>{{'You can edit the reminders on individual invoices anytime.' | translate }}</p>
        </div>
        <label class="inline-flex items-center">
            <input [formControl]='isInstallmentReminder' type="checkbox" class="me-2">
            <span class="block Poppins-SemiBold">{{'Send installment reminders to clients on due dates' | translate}}</span>
        </label>
        <label class="inline-flex items-center">
            <input [formControl]='isPaymentReminder' type="checkbox" class="me-2">
            <span class="block Poppins-SemiBold">{{'Automatically email receipts to customers whenever a payment is recorded' | translate}}</span>
        </label>
    </div>
    <div class="flex items-center gap-x-2">
        <app-button buttonText='{{"Save changes" | translate }}' type='primary' rounded='true' (handleClick)='saveChanges()'></app-button>
        <span *ngIf='response' class="text-green-500">{{response}}</span>
        <span *ngIf='error' class="text-red-500">{{error}}</span>
    </div>
</div>