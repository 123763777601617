<h2 class="text-2xl Poppins-Medium mb-4">{{ title | translate }}</h2>
<div
  class="flex space-x-4 rtl:space-x-reverse mb-4 rounded-xl flex-wrap gap-4 items-end"
>
  <div class="flex flex-col" [ngClass]="lastDate ? 'flex' : 'hidden'">
    <label class="block Poppins-Medium b">{{ "From Date" | translate }}</label>
    <input type="date" [formControl]="startDate" class="new-date" />
    <div
      *ngIf="startDate.dirty && startDate.getError('invalidDate')"
      class="text-red-500 ms-4"
    >
      {{ "Invalid Date" | translate }}
    </div>
  </div>
  <div class="flex flex-col" [ngClass]="asOfDate ? 'flex' : 'hidden'">
    <label class="block Poppins-Medium b" *ngIf="!lastDate">{{
      "As of Date" | translate
    }}</label>
    <label class="block Poppins-Medium" *ngIf="lastDate">{{
      "End Date" | translate
    }}</label>
    <input type="date" [formControl]="endDate" class="new-date" />
    <div
      *ngIf="endDate.dirty && endDate.getError('invalidDate')"
      class="text-red-500 ms-4"
    >
      {{ "Invalid Date" | translate }}
    </div>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="projectsFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Projects" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="projectName"
      bindValue="_id"
      placeholder="{{ 'Select Project' | translate }}"
      [items]="projects"
      [formControl]="project"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.projectName }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="accountsFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Accounts" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="accountName"
      groupBy="accountType"
      placeholder="{{ 'Select Account' | translate }}"
      [items]="accounts"
      [formControl]="account"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.accountName }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div class="flex flex-col w-1/5" [ngClass]="itemFilter ? 'flex' : 'hidden'">
    <label class="block Poppins-Medium">{{ "Items" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="name"
      bindValue="_id"
      placeholder="{{ 'Select Items' | translate }}"
      [items]="products"
      [formControl]="item"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.name }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="contactsFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Contact" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="accountName"
      groupBy="accountType"
      placeholder="{{ 'Select Contact' | translate }}"
      [items]="contacts"
      [formControl]="contact"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.accountName }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="assetsAccountFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{
      "Asset Account" | translate
    }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="accountName"
      bindValue="_id"
      placeholder="{{ 'Select Account' | translate }}"
      [items]="assetAccounts"
      [formControl]="assetId"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.accountName }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="cashierFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Cashier" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="name"
      placeholder="{{ 'Select Cashier' | translate }}"
      [items]="cashiers"
      [formControl]="cashierEmail"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.name }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="partialTrialFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Account" | translate }}</label>
    <ng-select
      class="new-dropdown"
      placeholder="{{ 'Select Account' | translate }}"
      [items]="assetCategory"
      [formControl]="assetValue"
      (change)="filterSubCategory($event)"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="partialTrialFilter ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Category" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="accountType"
      bindValue="accountType"
      placeholder="{{ 'Select Category' | translate }}"
      [items]="filteredSubCategory"
      [formControl]="subAssetValue"
      (change)="subCategoryChange($event)"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.accountType }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex flex-col w-1/5"
    [ngClass]="salesTaxTransaction ? 'flex' : 'hidden'"
  >
    <label class="block Poppins-Medium">{{ "Tax" | translate }}</label>
    <ng-select
      class="new-dropdown"
      bindLabel="taxName"
      bindValue="accountDetails.accountId"
      placeholder="{{ 'Select Tax' | translate }}"
      [items]="allTaxes"
      [formControl]="selectedTax"
    >
      <ng-template ng-option-tmp let-item="item" let-index="index">
        <div class="px-2 py-1">
          <div class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]">
            {{ item.taxName }}
          </div>
        </div>
      </ng-template>
    </ng-select>
  </div>
  <div
    class="flex space-x-2 self-end pb-2 items-center"
    [ngClass]="depreciationFilter ? 'flex' : 'hidden'"
  >
    <input
      type="checkbox"
      [formControl]="withDepreciation"
      class="p-3 bg-input"
    />
    <label class="block Poppins-Medium b">{{
      "With Depreciation" | translate
    }}</label>
  </div>
  <div
    class="flex flex-col justify-end"
    [ngClass]="{ '!ml-0': partialTrialFilter }"
  >
    <!-- <label class="block opacity-0 mb-1">button</label> -->
    <app-button
      (handleClick)="emitFormData()"
      type="primary"
      buttonText="{{ 'Generate Report' | translate }}"
    ></app-button>
  </div>
  <div
    class="flex flex-col justify-end"
    [ngClass]="{ '!ml-0': partialTrialFilter }"
  >
    <!-- <label class="block opacity-0 mb-1">button</label> -->
    <app-button
      (handleClick)="downloadPdf()"
      type="primary"
      buttonText="{{ 'Download' | translate }}"
    ></app-button>
  </div>
</div>
