<div
  class="flex flex-col p-4 gap-6 pb-0"
  *ngIf="!(showStoreCreate || showBusinessUpdate)"
>
  <app-business-list
    (editBusiness)="businessId = $event; showBusinessUpdate = true"
  ></app-business-list>

  <div class="flex gap-6">
    <app-store-list
      class="flex-grow"
      (editStore)="storeId = $event; showStoreCreate = true"
    ></app-store-list>
    <app-submit
      [value]="'Create'"
      className="bg-[#026AA2] text-white w-28 h-10 mx-6"
      (clicked)="storeId =null;showStoreCreate = true"
    ></app-submit>
  </div>
</div>

<app-store-edit-create
  *ngIf="showStoreCreate"
  (close)="showStoreCreate = false"
  [storeId]="storeId"
></app-store-edit-create>

<app-business-update
  *ngIf="showBusinessUpdate"
  (close)="showBusinessUpdate = false"
  [businessId]="businessId"
>
</app-business-update>
