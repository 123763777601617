<div class="new-design-round-border p-4">
  <div class="flex justify-between items-center">
    <div class="text-sm self-stretch">
      {{ "2-step authentication" | translate }}
    </div>
    <label for="toggle" class="flex items-center cursor-pointer relative">
      <input
        type="checkbox"
        (change)="confirm2FA = true"
        [formControl]="authentication"
        id="toggle"
        class="sr-only peer"
      />
      <div
        [ngClass]="{
          'after:bg-white': authentication?.value,
          'after:bg-black bg-[#d9d9d9]': !authentication?.value
        }"
        class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
      ></div>
    </label>
  </div>
  <div class="flex space-x-4 mt-6 rtl:space-x-reverse items-center">
    <p class="text-xxs text-[#1C1C1C66]">
      {{ "Two step auth config message" | translate }}
    </p>
  </div>
</div>

<app-modal-container [isOpen]="confirm2FA">
  <div header class="border-b pb-2">
    {{ !authentication?.value ? "Disable" : "Enable" }}
    {{ "Two-Factor Authentication" | translate }}
  </div>
  <div content>
    <p class="mb-2">
      {{ "Are you sure you want to" | translate }}
      {{ !authentication?.value ? "disable" : "enable" }}
      {{ "Two-Factor Authentication" }}?
    </p>
  </div>
  <div footer class="flex items-center gap-3">
    <app-submit
      className="bg-[#026AA2] text-white h-10 w-28"
      value="Ok"
      (clicked)="changeAuthentication()"
    >
    </app-submit>
    <app-submit
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10"
      value="Cancel"
      (clicked)="
        confirm2FA = false; authentication.setValue(!authentication?.value)
      "
    >
    </app-submit>
  </div>
</app-modal-container>
