<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <form [formGroup]="journalEntryForm">
      <my-tabs className="w-[37%]">
        <div class="flex justify-between mb-4">
          <div class="flex">
            <div>
              <div class="rounded-xl bg-white px-3 py-2 w-64">
                <fieldset [disabled]="transaction?.type !== 'journal voucher'">
                  <label
                    for="date"
                    class="block text-sm Poppins-Medium bg-transparent font-color-05"
                    >{{ "Date" | translate }}</label
                  >
                  <!-- <input id='date' formControlName="date" type="date"
                                        class="w-full p-0 border-0 focus:ring-0 mt-2"> -->
                  <input
                    id="date"
                    formControlName="date"
                    type="date"
                    class="new-date"
                  />
                </fieldset>
              </div>
              <p *ngIf="formErrors.date" class="text-red-500 ms-4">
                {{ formErrors.date }}
              </p>
            </div>
            <div class="rounded-xl bg-white px-3 py-2 ms-5">
              <label
                for="description"
                class="block text-sm Poppins-Medium bg-transparent font-color-05"
                >{{ "Description" | translate }}</label
              >
              <input
                id="description"
                formControlName="description"
                placeholder="{{ 'Enter Description' | translate }}"
                type="text"
                class="new-input"
              />
            </div>
            <div class="rounded-xl bg-white px-3 py-2 w-96 ms-5 me-8">
              <label class="block text-sm Poppins-Medium font-color-05">{{
                "Add a Project" | translate
              }}</label>
              <fieldset>
                <ng-select
                  [items]="projects"
                  class="new-dropdown"
                  bindLabel="projectName"
                  bindValue="_id"
                  formControlName="projectId"
                  placeholder="{{ 'Select Project Name' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.projectName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </fieldset>
            </div>
          </div>
        </div>
        <my-tab tabTitle="{{ 'Details' | translate }}">
          <table class="w-full">
            <tr>
              <td class="p-3 bg-input Poppins-Medium rounded-s-xl w-1/12">
                {{ "Type" | translate }}
              </td>
              <td class="p-3 bg-input Poppins-Medium w-3/12">
                {{ "Description" | translate }}
              </td>
              <td class="p-3 bg-input Poppins-Medium">
                {{ "Account" | translate }}
              </td>
              <td class="p-3 bg-input Poppins-Medium w-2/12">
                {{ "Debit" | translate }}
              </td>
              <td class="p-3 bg-input Poppins-Medium w-2/12 last:rounded-e-xl">
                {{ "Credit" | translate }}
              </td>
              <td
                *ngIf="transaction?.type !== 'journal voucher'"
                class="p-3 bg-input Poppins-Medium w-1/12 last:rounded-e-xl"
              ></td>
            </tr>
            <ng-container formArrayName="journal">
              <ng-container
                *ngFor="let control of journal.controls; let i = index"
              >
                <tr [formGroup]="control" class="">
                  <td class="w-2/12 p-2">
                    <ng-select
                      [items]="['Credit', 'Debit']"
                      class="new-dropdown"
                      formControlName="type"
                      placeholder="{{ 'Select one' | translate }}"
                      [searchable]="false"
                      [clearable]="false"
                      [readonly]="transaction?.type !== 'journal voucher'"
                      (change)="changeType($event, i)"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="w-3/12 p-2">
                    <input
                      formControlName="description"
                      type="text"
                      class="new-input"
                    />
                  </td>
                  <td class="p-2">
                    <ng-select
                      [items]="accounts"
                      class="new-drodown"
                      bindLabel="accountName"
                      formControlName="account"
                      placeholder="{{ 'Select one' | translate }}"
                      groupBy="accountType"
                      [readonly]="transaction?.type !== 'journal voucher'"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.accountName }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2 w-2/12">
                    <fieldset
                      [disabled]="transaction?.type !== 'journal voucher'"
                    >
                      <input
                        [ngClass]="
                          control.value.type === 'Credit' ? 'hidden' : 'block'
                        "
                        formControlName="debit"
                        type="number"
                        [class.new-input]="control.value.type === 'Debit'"
                      />
                    </fieldset>
                  </td>
                  <td class="p-2 w-2/12">
                    <fieldset
                      [disabled]="transaction?.type !== 'journal voucher'"
                    >
                      <input
                        [ngClass]="
                          control.value.type === 'Debit' ? 'hidden' : 'block'
                        "
                        formControlName="credit"
                        type="number"
                        [class.new-input]="control.value.type === 'Credit'"
                      />
                    </fieldset>
                  </td>
                  <td
                    *ngIf="transaction?.type === 'journal voucher'"
                    class="p-2 w-1/12"
                  >
                    <svg
                      (click)="removeLine(i)"
                      xmlns="http://www.w3.org/2000/svg"
                      name="delete"
                      class="h-5 w-5 text-red-500"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      />
                    </svg>
                  </td>
                </tr>
              </ng-container>
              <ng-container *ngIf="journal.controls.length === 0">
                <tr>
                  <td colspan="7" class="text-center p-2">
                    {{ "No items added" | translate }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td vAlign="top" colspan="3">
                <div
                  *ngIf="
                    transaction?.type === 'journal voucher' &&
                    journal.controls.length !== 20
                  "
                  class="ps-2"
                >
                  <app-button (handleClick)="addLine()" type="add-line">
                    <img
                      src="assets/images/delete-icon.svg"
                      style="transform: rotate(45deg)"
                      alt=""
                    />
                    {{ "Add a Line" | translate }}
                  </app-button>
                </div>
              </td>
              <td colspan="3" class="p-3 bg-[#F7F9FB] rounded-xl">
                <div class="flex justify-between py-2">
                  <p class="">{{ "Total Debits" | translate }}</p>
                  <p></p>
                  <p class="">{{ "Total Credits" | translate }}</p>
                </div>
                <div class="flex justify-between">
                  <p class="text-lg Poppins-Medium">
                    {{
                      debitTotal
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                  <p>{{ creditTotal === debitTotal ? "=" : "≠" }}</p>
                  <p class="text-lg Poppins-Medium">
                    {{
                      creditTotal
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                </div>
                <div class="flex justify-center">
                  <p>
                    <span> {{ "Difference" | translate }} </span> =
                    <span
                      [ngClass]="
                        difference === 0 ? 'text-green-600' : 'text-red-600'
                      "
                      >{{
                        difference
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}</span
                    >
                  </p>
                </div>
              </td>
            </tr>
          </table>
        </my-tab>
        <my-tab tabTitle="{{ 'Notes' | translate }}">
          <div class="px-6">
            <textarea
              name="notes"
              id="notes"
              formControlName="notes"
              placeholder="{{ 'Write notes here' | translate }}"
              class="new-input h-[11rem]"
              cols="30"
              rows="11"
            ></textarea>
          </div>
        </my-tab>
        <my-tab
          appAccessControl
          moduleType="storage"
          accessType="view"
          tabTitle="{{ 'Files' | translate }}"
        >
          <app-file-upload
            [addedFiles]="addedFiles"
            (emitter)="saveFiles($event)"
            type="compact"
            tableType="new"
          ></app-file-upload>
        </my-tab>
      </my-tabs>
    </form>
    <div class="flex items-center px-8 border-t py-4">
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        type="third"
        routerLink="/accounting/transaction"
      >
      </app-button>
      <div class="ms-auto flex items-center">
        <span *ngIf="issuedBy" class="me-2">
          <span class="Poppins-Medium me-2"
            >{{ "Issued By" | translate }} -</span
          >
          <span>{{ issuedBy }}</span>
        </span>
        <app-tooltip
          customClass="mb-10"
          class="me-4"
          tooltipName="{{ 'Mark as reviewed' | translate }}"
          *ngIf="update && !transaction?.isReviewed"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            (click)="reviewTransaction()"
            class="ml-auto h-10 w-10 text-gray-500 cursor-pointer"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </app-tooltip>
        <app-tooltip
          customClass="mb-10"
          class="me-4"
          tooltipName="{{ 'Reviewed' | translate }}"
          *ngIf="update && transaction?.isReviewed"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            (click)="reviewTransaction()"
            class="ml-auto h-10 w-10 text-green-500 cursor-pointer"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fill-rule="evenodd"
              d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
              clip-rule="evenodd"
            />
          </svg>
        </app-tooltip>
        <app-button
          buttonText="{{ 'Save' | translate }}"
          [disabled]="!enableSave"
          (handleClick)="update ? (showDialog = true) : saveJournal()"
          type="primary"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="showConfirmDialog">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Confirm Change" | translate }}
  </div>
  <div class="text-start" content>
    <p>
      {{
        "The customer's balance from this JV has already been used in Cash Invoice/s. If you wish to proceed, you may notice difference in report amount which can't be reversed."
          | translate
      }}
    </p>
    <!-- <ul>
            <li class="Poppins list-disc" *ngFor="let item of checkQuantityInStock">
                <div>{{item?.item}} added with {{item?.quantityAdded}} units has {{item?.openingStock}} units only</div>
            </li>
        </ul> -->
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="saveJournal()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="danger"
      rounded="true"
      (handleClick)="showConfirmDialog = false"
    ></app-button>
  </div>
</app-modal-container>
<app-modal-container [isOpen]="showDialog">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Confirm Change" | translate }}
  </div>
  <div class="text-start" content>
    <p>
      {{
        "You may incur difference in balance of the account used in the JV, do you still want to proceed?"
          | translate
      }}
    </p>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="primary"
      (handleClick)="saveJournal()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="showDialog = false"
    ></app-button>
  </div>
</app-modal-container>
