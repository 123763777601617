import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchasesService } from '../../purchases.service';
import * as VendorActions from '../../state/actions/vendors.action';
import { Store } from '@ngrx/store';
import { PurchaseReducerState } from '../../state/reducers';



@Injectable({
  providedIn: 'root'
})
export class PurchaseOrderService {


  constructor(private httpService: HttpService,
              private store: Store<PurchaseReducerState>,
              private purchaseServices: PurchasesService) {}

    createPurchaseOrder(data): Observable<any> {
        return this.purchaseServices.createPurchaseOrder(data);
    }

    generatePurchaseOrderNumber(data): Observable<any> {
      return this.purchaseServices.generatePurchaseOrderNumber(data);
    }

    getPurchaseOrders(id): Observable<any> {
      return this.purchaseServices.getPurchaseOrders(id);
    }

    getOrderId(id): Observable<any> {
      return this.purchaseServices.getVendorDetails(id);
    }
    deleteOrder(id): Observable<any> {
      return this.purchaseServices.deleteOrder(id);
    }
    updateOrderDetails(body): Observable<any> {
      return this.purchaseServices.updateOrderDetails(body);
    }
    OrderDetails(body): Observable<any> {
      return this.purchaseServices.getOrderDetails(body);
    }
    sendEmail(data): Observable<any> {
      return this.purchaseServices.sendPurchaseOrder(data);
    }
    generatePdf(data): Observable<any> {
      return this.purchaseServices.generatePOPdf(data);
    }
}
