import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DiscountCodeService } from '../../services/discount-code.service';

@Component({
  selector: 'app-discount-code-view',
  templateUrl: './discount-code-view.component.html',
})
export class DiscountCodeViewComponent implements OnInit {
  @Input() codeToView = null;
  @Output() close = new EventEmitter();

  tableHeadings = [
    { heading: 'Discount Code', key: 'code' },
    { heading: 'Discount Value', key: 'discountValue' },
    { heading: 'Usage Limit', key: 'discountCount' },
    { heading: 'Customer Limit', key: 'customerUsageCount' },
    { heading: 'Total Times Used', key: 'usedByMobileCount' },
    { heading: 'Date Created', key: 'createdAt', isDate: true },
    { heading: 'Expiry Date', key: 'expiryDate', isDate: true },
  ];

  customerDetails = ['Mobile Number', 'Customer Name', 'Customer Email'];
  customerTable;
  constructor(
    private discountCodeService: DiscountCodeService,
    private spinner: NgxSpinnerService
  ) {}

  ngOnInit() {
    if (this.codeToView) {
      this.codeToView.usedByMobileCount = this.codeToView?.usedByMobile?.length;
      this.loadDiscountCodeDetails(this.codeToView._id);
    }
  }
  loadDiscountCodeDetails(codeId) {
    this.spinner.show();
    this.discountCodeService.getDiscountCodeDetails(codeId).subscribe({
      next: (response) => {
        this.spinner.hide();
        this.codeToView = response.data;
        this.customerTable = this.codeToView.usedByMobile.map((customer) => {
          return {
            mobileNumber: customer.contact.mobileNumber,
            name: customer.firstName + ' ' + customer.lastName,
            email: customer.email,
          };
        });
        console.log(response);
      },
      error: (error) => {
        this.spinner.hide();
        console.log(error);
      },
    });
  }
}
