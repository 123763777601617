import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError, retry } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
// import { expertDetailsState } from './state/reducers';
import { Store, select } from '@ngrx/store';
import * as ExpertDetailsAction from './state/actions/expertDetails.action';
import { selectExpertDetails } from './state/selectors/expertDetails.selector';

@Injectable({
  providedIn: 'root',
})
export class ExpertService {
  constructor(
    private httpService: HttpService,
    private http: HttpClient,
    private router: Router,
    private rootStore: Store<any>
  ) {}

  createApplication(data): Observable<any> {
    return this.httpService.postMultipart(`/expert/expertApplication`, data);
  }

  createApplicationLambda(data): Observable<any> {
    return this.http.post(
      `https://tflkl7avjmgglnh6bjlx43ajdu0dvqxn.lambda-url.us-east-2.on.aws/`,
      data
    );
  }

  getAllExperts(): Observable<any> {
    return this.httpService.get(`/expert/getAllExperts`);
  }

  getAllExpertsLambda(data: any): Observable<any> {
    return this.http.post(
      `https://lmwjluaguol5hpdqnulcwqrary0lmens.lambda-url.us-east-2.on.aws/`,
      data
    );
  }

  getApplicationStatus(): Observable<any> {
    return this.httpService.get(`/expert/applicationStatus`);
  }

  getApplicationStatusLambda(data: any): Observable<any> {
    return this.http.post(
      `https://kwpebfklucq7o2vfhs6cwmgw3i0rtwrh.lambda-url.us-east-2.on.aws/`,
      JSON.stringify(data)
    );
  }

  getAllApplications(): Observable<any> {
    return this.http.get('https://nggutjtcm5ac6aftldigrmq57y0fjweh.lambda-url.us-east-2.on.aws/')
    // return this.http.get('https://7tjelztiulvskvn42aej7y5gni0pymds.lambda-url.eu-north-1.on.aws/')
  }

  getCertificatesLambda(data: any): Observable<any> {
    console.log("data", data);
    
    return this.http.post(
      `https://e5hmwdec4ov33yyt7vwxekj2iu0snagb.lambda-url.us-east-2.on.aws/`,
      JSON.stringify(data)
    );
  }

  getExpertDetails(id: string): Observable<any> {
    return this.httpService.get(`/expert/getExpertDetails/${id}`);
  }

  setExpertDetails(details): void {
    console.log('details', details.data);
    let expertDetails = details.data;

    this.rootStore.dispatch(
      ExpertDetailsAction.SetExpertDetails({ expertDetails })
    );
  }

  // submitEnquiry(data): Observable<any> {
  //   return this.httpService.post(`/expert/submitEnquiry`, data);
  // }

  submitEnquiry(data): Observable<any> {
    return this.httpService.post(
      `/arn:aws:lambda:eu-north-1:970086437021:function:testFunction`,
      data
    );
  }

  submitEnquiryLambda(body: any): Observable<any> {
    return this.http
      .post(
        `${'https://vjhgmk5d6pvdhgknu26t7jkrja0uhwdh.lambda-url.us-east-2.on.aws/'}`,
        body
      )
      .pipe(retry(1), catchError(this.errorHandler.bind(this)));
  }

  getAllEnquiriesLambda(data: any): Observable<any> {
    // return this.http.post(
    //   `https://berg43k25m3w5ooivk5z65hani0cpyoz.lambda-url.eu-north-1.on.aws/`,
    //   data
    // )
    return this.http.post(
      `https://idossrbhmhl2ylxminvc3cgzr40zgscg.lambda-url.us-east-2.on.aws/`,
      data
    )
  }

  uploadImage(image: any): Observable<any> {
    return this.httpService.postMultipart(`/expert/uploadImage`, image);
  }

  private errorHandler(response: any): Observable<any> {
    const error = response.error;
    const keys = Object.keys(error);
    const key = keys[0];
    let message = error[key];
    if (response.status === 401) {
      localStorage.clear();
      this.router.navigate(['/login']);
      // auth token delete
      // redirect login page
    }
    if (error[key] instanceof Array) {
      message = error[key][0];
    }
    if (key === 'isTrusted') {
      // this will occur when not connected to internet
    } else {
      message = key + ' : ' + message;
    }
    // call snackbar and show error with message
    return throwError({ messages: message, error, status: response?.status });
  }
}
