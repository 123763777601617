import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { SalesService } from '../sales/sales.service';
import moment = require('moment');

@Component({
  selector: 'app-time-sheet',
  templateUrl: './time-sheet.component.html',
  styleUrls: ['./time-sheet.component.scss'],
})
export class TimeSheetComponent implements OnInit {
  constructor(
    private salesServices: SalesService,
    private rootStore: Store<RootReducerState>
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
  }

  business$: Observable<any>;
  unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business?.businessId?._id) {
        const id = business?.businessId._id;
        this.salesServices.setBusinessId(id);
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
