import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchasesService } from '../../purchases.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecurringBillService {

  constructor(private http: HttpService,private purchaseService: PurchasesService) { }

  getRecurringBillsList(businessId): Observable<any> {
    return this.purchaseService.getRecurringBills(businessId);
  }

  getBillById(billId: string): Observable<any> {
    return this.purchaseService.getRecurringBillDetails(billId);
  }

  getPaymentTerms(businessId): Observable<any> {
    return this.http.get(`/setting/getPaymentTerms/${businessId}`);
  }

  createBill(payload): Observable<any> {
    return this.purchaseService.createRecurringBill(payload);
  }

  createPaymentTerm(payload, businessId): Observable<any> {
    return this.http.post(`/setting/savePaymentTerm/${businessId}`, payload);
  }

  stopRecurringBill(billId): Observable<any> {
    return this.purchaseService.cancelRecurringBill(billId);
  }

  updateBill(endDate, billId): Observable<any> {
    return this.purchaseService.updateRecurringBill(billId, {endDate});
  }
}
