import { Component, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-verify-success',
  templateUrl: './verify-success.component.html',
  styleUrls: ['./verify-success.component.scss'],
})
export class VerifySuccessComponent implements OnInit {
  constructor(private utility: UtilityService) {}

  ngOnInit(): void {}
  route() {
    this.utility.showHideSettingsModal.next({
      open: true,
      tab: 'userProfile',
    });
  }
}
