export const Countries = [
  {
    "text": "Afghanistan",
    "isoCode": "AF",
    "states": [
      {
        "text": "Badakhshan"
      },
      {
        "text": "Badghis"
      },
      {
        "text": "Baghlan"
      },
      {
        "text": "Balkh"
      },
      {
        "text": "Bamyan"
      },
      {
        "text": "Daykundi"
      },
      {
        "text": "Farah"
      },
      {
        "text": "Faryab"
      },
      {
        "text": "Ghazni"
      },
      {
        "text": "Ghōr"
      },
      {
        "text": "Helmand"
      },
      {
        "text": "Herat"
      },
      {
        "text": "Jowzjan"
      },
      {
        "text": "Kabul"
      },
      {
        "text": "Kandahar"
      },
      {
        "text": "Kapisa"
      },
      {
        "text": "Khost"
      },
      {
        "text": "Kunar"
      },
      {
        "text": "Kunduz Province"
      },
      {
        "text": "Laghman"
      },
      {
        "text": "Logar"
      },
      {
        "text": "Nangarhar"
      },
      {
        "text": "Nimruz"
      },
      {
        "text": "Nuristan"
      },
      {
        "text": "Paktia"
      },
      {
        "text": "Paktika"
      },
      {
        "text": "Panjshir"
      },
      {
        "text": "Parwan"
      },
      {
        "text": "Samangan"
      },
      {
        "text": "Sar-e Pol"
      },
      {
        "text": "Takhar"
      },
      {
        "text": "Urozgan"
      },
      {
        "text": "Zabul"
      }
    ],
    "code": "+93"
  },
  {
    "text": "Aland Islands",
    "isoCode": "AX",
    "states": []
  },
  {
    "text": "Albania",
    "isoCode": "AL",
    "states": [
      {
        "text": "Berat County"
      },
      {
        "text": "Berat District"
      },
      {
        "text": "Bulqizë District"
      },
      {
        "text": "Delvinë District"
      },
      {
        "text": "Devoll District"
      },
      {
        "text": "Dibër County"
      },
      {
        "text": "Dibër District"
      },
      {
        "text": "Durrës County"
      },
      {
        "text": "Durrës District"
      },
      {
        "text": "Elbasan County"
      },
      {
        "text": "Fier County"
      },
      {
        "text": "Fier District"
      },
      {
        "text": "Gjirokastër County"
      },
      {
        "text": "Gjirokastër District"
      },
      {
        "text": "Gramsh District"
      },
      {
        "text": "Has District"
      },
      {
        "text": "Kavajë District"
      },
      {
        "text": "Kolonjë District"
      },
      {
        "text": "Korçë County"
      },
      {
        "text": "Korçë District"
      },
      {
        "text": "Krujë District"
      },
      {
        "text": "Kukës County"
      },
      {
        "text": "Kukës District"
      },
      {
        "text": "Kurbin District"
      },
      {
        "text": "Kuçovë District"
      },
      {
        "text": "Lezhë County"
      },
      {
        "text": "Lezhë District"
      },
      {
        "text": "Librazhd District"
      },
      {
        "text": "Lushnjë District"
      },
      {
        "text": "Mallakastër District"
      },
      {
        "text": "Malësi e Madhe District"
      },
      {
        "text": "Mat District"
      },
      {
        "text": "Mirditë District"
      },
      {
        "text": "Peqin District"
      },
      {
        "text": "Pogradec District"
      },
      {
        "text": "Pukë District"
      },
      {
        "text": "Përmet District"
      },
      {
        "text": "Sarandë District"
      },
      {
        "text": "Shkodër County"
      },
      {
        "text": "Shkodër District"
      },
      {
        "text": "Skrapar District"
      },
      {
        "text": "Tepelenë District"
      },
      {
        "text": "Tirana County"
      },
      {
        "text": "Tirana District"
      },
      {
        "text": "Tropojë District"
      },
      {
        "text": "Vlorë County"
      },
      {
        "text": "Vlorë District"
      }
    ],
    "code": "+355"
  },
  {
    "text": "Algeria",
    "isoCode": "DZ",
    "states": [
      {
        "text": "Adrar"
      },
      {
        "text": "Algiers"
      },
      {
        "text": "Annaba"
      },
      {
        "text": "Aïn Defla"
      },
      {
        "text": "Aïn Témouchent"
      },
      {
        "text": "Batna"
      },
      {
        "text": "Biskra"
      },
      {
        "text": "Blida"
      },
      {
        "text": "Bordj Baji Mokhtar"
      },
      {
        "text": "Bordj Bou Arréridj"
      },
      {
        "text": "Boumerdès"
      },
      {
        "text": "Bouïra"
      },
      {
        "text": "Béchar"
      },
      {
        "text": "Béjaïa"
      },
      {
        "text": "Béni Abbès"
      },
      {
        "text": "Chlef"
      },
      {
        "text": "Constantine"
      },
      {
        "text": "Djanet"
      },
      {
        "text": "Djelfa"
      },
      {
        "text": "El Bayadh"
      },
      {
        "text": "El M'ghair"
      },
      {
        "text": "El Menia"
      },
      {
        "text": "El Oued"
      },
      {
        "text": "El Tarf"
      },
      {
        "text": "Ghardaïa"
      },
      {
        "text": "Guelma"
      },
      {
        "text": "Illizi"
      },
      {
        "text": "In Guezzam"
      },
      {
        "text": "In Salah"
      },
      {
        "text": "Jijel"
      },
      {
        "text": "Khenchela"
      },
      {
        "text": "Laghouat"
      },
      {
        "text": "M'Sila"
      },
      {
        "text": "Mascara"
      },
      {
        "text": "Mila"
      },
      {
        "text": "Mostaganem"
      },
      {
        "text": "Médéa"
      },
      {
        "text": "Naama"
      },
      {
        "text": "Oran"
      },
      {
        "text": "Ouargla"
      },
      {
        "text": "Ouled Djellal"
      },
      {
        "text": "Oum El Bouaghi"
      },
      {
        "text": "Relizane"
      },
      {
        "text": "Saïda"
      },
      {
        "text": "Sidi Bel Abbès"
      },
      {
        "text": "Skikda"
      },
      {
        "text": "Souk Ahras"
      },
      {
        "text": "Sétif"
      },
      {
        "text": "Tamanghasset"
      },
      {
        "text": "Tiaret"
      },
      {
        "text": "Timimoun"
      },
      {
        "text": "Tindouf"
      },
      {
        "text": "Tipasa"
      },
      {
        "text": "Tissemsilt"
      },
      {
        "text": "Tizi Ouzou"
      },
      {
        "text": "Tlemcen"
      },
      {
        "text": "Touggourt"
      },
      {
        "text": "Tébessa"
      }
    ],
    "code": "+213"
  },
  {
    "text": "American Samoa",
    "isoCode": "AS",
    "states": []
  },
  {
    "text": "Andorra",
    "isoCode": "AD",
    "states": [
      {
        "text": "Andorra la Vella"
      },
      {
        "text": "Canillo"
      },
      {
        "text": "Encamp"
      },
      {
        "text": "Escaldes-Engordany"
      },
      {
        "text": "La Massana"
      },
      {
        "text": "Ordino"
      },
      {
        "text": "Sant Julià de Lòria"
      }
    ],
    "code": "+376"
  },
  {
    "text": "Angola",
    "isoCode": "AO",
    "states": [
      {
        "text": "Bengo Province"
      },
      {
        "text": "Benguela Province"
      },
      {
        "text": "Bié Province"
      },
      {
        "text": "Cabinda Province"
      },
      {
        "text": "Cuando Cubango Province"
      },
      {
        "text": "Cuanza Norte Province"
      },
      {
        "text": "Cuanza Sul"
      },
      {
        "text": "Cunene Province"
      },
      {
        "text": "Huambo Province"
      },
      {
        "text": "Huíla Province"
      },
      {
        "text": "Luanda Province"
      },
      {
        "text": "Lunda Norte Province"
      },
      {
        "text": "Lunda Sul Province"
      },
      {
        "text": "Malanje Province"
      },
      {
        "text": "Moxico Province"
      },
      {
        "text": "Uíge Province"
      },
      {
        "text": "Zaire Province"
      }
    ],
    "code": "+244"
  },
  {
    "text": "Anguilla",
    "isoCode": "AI",
    "states": []
  },
  {
    "text": "Antarctica",
    "isoCode": "AQ",
    "states": []
  },
  {
    "text": "Antigua And Barbuda",
    "isoCode": "AG",
    "states": [
      {
        "text": "Barbuda"
      },
      {
        "text": "Redonda"
      },
      {
        "text": "Saint George Parish"
      },
      {
        "text": "Saint John Parish"
      },
      {
        "text": "Saint Mary Parish"
      },
      {
        "text": "Saint Paul Parish"
      },
      {
        "text": "Saint Peter Parish"
      },
      {
        "text": "Saint Philip Parish"
      }
    ]
  },
  {
    "text": "Argentina",
    "isoCode": "AR",
    "states": [
      {
        "text": "Autonomous City Of Buenos Aires"
      },
      {
        "text": "Buenos Aires Province"
      },
      {
        "text": "Catamarca Province"
      },
      {
        "text": "Chaco Province"
      },
      {
        "text": "Chubut Province"
      },
      {
        "text": "Corrientes"
      },
      {
        "text": "Córdoba Province"
      },
      {
        "text": "Entre Ríos Province"
      },
      {
        "text": "Formosa Province"
      },
      {
        "text": "Jujuy Province"
      },
      {
        "text": "La Pampa"
      },
      {
        "text": "La Rioja Province"
      },
      {
        "text": "Mendoza"
      },
      {
        "text": "Misiones Province"
      },
      {
        "text": "Neuquén Province"
      },
      {
        "text": "Río Negro Province"
      },
      {
        "text": "Salta Province"
      },
      {
        "text": "San Juan Province"
      },
      {
        "text": "San Luis Province"
      },
      {
        "text": "Santa Cruz Province"
      },
      {
        "text": "Santa Fe Province"
      },
      {
        "text": "Santiago del Estero Province"
      },
      {
        "text": "Tierra del Fuego Province"
      },
      {
        "text": "Tucumán Province"
      }
    ],
    "code": "+54"
  },
  {
    "text": "Armenia",
    "isoCode": "AM",
    "states": [
      {
        "text": "Aragatsotn Region"
      },
      {
        "text": "Ararat Province"
      },
      {
        "text": "Armavir Region"
      },
      {
        "text": "Gegharkunik Province"
      },
      {
        "text": "Kotayk Region"
      },
      {
        "text": "Lori Region"
      },
      {
        "text": "Shirak Region"
      },
      {
        "text": "Syunik Province"
      },
      {
        "text": "Tavush Region"
      },
      {
        "text": "Vayots Dzor Region"
      },
      {
        "text": "Yerevan"
      }
    ],
    "code": "+374"
  },
  {
    "text": "Aruba",
    "isoCode": "AW",
    "states": []
  },
  {
    "text": "Australia",
    "isoCode": "AU",
    "states": [
      {
        "text": "Australian Capital Territory"
      },
      {
        "text": "New South Wales"
      },
      {
        "text": "Northern Territory"
      },
      {
        "text": "Queensland"
      },
      {
        "text": "South Australia"
      },
      {
        "text": "Tasmania"
      },
      {
        "text": "Victoria"
      },
      {
        "text": "Western Australia"
      }
    ],
    "code": "+61"
  },
  {
    "text": "Austria",
    "isoCode": "AT",
    "states": [
      {
        "text": "Burgenland"
      },
      {
        "text": "Carinthia"
      },
      {
        "text": "Lower Austria"
      },
      {
        "text": "Salzburg"
      },
      {
        "text": "Styria"
      },
      {
        "text": "Tyrol"
      },
      {
        "text": "Upper Austria"
      },
      {
        "text": "Vienna"
      },
      {
        "text": "Vorarlberg"
      }
    ],
    "code": "+43"
  },
  {
    "text": "Azerbaijan",
    "isoCode": "AZ",
    "states": [
      {
        "text": "Absheron District"
      },
      {
        "text": "Agdam District"
      },
      {
        "text": "Agdash District"
      },
      {
        "text": "Aghjabadi District"
      },
      {
        "text": "Agstafa District"
      },
      {
        "text": "Agsu District"
      },
      {
        "text": "Astara District"
      },
      {
        "text": "Babek District"
      },
      {
        "text": "Baku"
      },
      {
        "text": "Balakan District"
      },
      {
        "text": "Barda District"
      },
      {
        "text": "Beylagan District"
      },
      {
        "text": "Bilasuvar District"
      },
      {
        "text": "Dashkasan District"
      },
      {
        "text": "Fizuli District"
      },
      {
        "text": "Ganja"
      },
      {
        "text": "Gobustan District"
      },
      {
        "text": "Goranboy District"
      },
      {
        "text": "Goychay"
      },
      {
        "text": "Goygol District"
      },
      {
        "text": "Gədəbəy"
      },
      {
        "text": "Hajigabul District"
      },
      {
        "text": "Imishli District"
      },
      {
        "text": "Ismailli District"
      },
      {
        "text": "Jabrayil District"
      },
      {
        "text": "Jalilabad District"
      },
      {
        "text": "Julfa District"
      },
      {
        "text": "Kalbajar District"
      },
      {
        "text": "Kangarli District"
      },
      {
        "text": "Khachmaz District"
      },
      {
        "text": "Khizi District"
      },
      {
        "text": "Khojali District"
      },
      {
        "text": "Kurdamir District"
      },
      {
        "text": "Lachin District"
      },
      {
        "text": "Lankaran"
      },
      {
        "text": "Lankaran District"
      },
      {
        "text": "Lerik District"
      },
      {
        "text": "Martuni"
      },
      {
        "text": "Masally District"
      },
      {
        "text": "Mingachevir"
      },
      {
        "text": "Nakhchivan Autonomous Republic"
      },
      {
        "text": "Neftchala District"
      },
      {
        "text": "Oghuz District"
      },
      {
        "text": "Ordubad District"
      },
      {
        "text": "Qabala District"
      },
      {
        "text": "Qakh District"
      },
      {
        "text": "Qazakh District"
      },
      {
        "text": "Quba District"
      },
      {
        "text": "Qubadli District"
      },
      {
        "text": "Qusar District"
      },
      {
        "text": "Saatly District"
      },
      {
        "text": "Sabirabad District"
      },
      {
        "text": "Sadarak District"
      },
      {
        "text": "Salyan District"
      },
      {
        "text": "Samukh District"
      },
      {
        "text": "Shabran District"
      },
      {
        "text": "Shahbuz District"
      },
      {
        "text": "Shaki"
      },
      {
        "text": "Shaki District"
      },
      {
        "text": "Shamakhi District"
      },
      {
        "text": "Shamkir District"
      },
      {
        "text": "Sharur District"
      },
      {
        "text": "Shirvan"
      },
      {
        "text": "Shusha District"
      },
      {
        "text": "Siazan District"
      },
      {
        "text": "Sumqayit"
      },
      {
        "text": "Tartar District"
      },
      {
        "text": "Tovuz District"
      },
      {
        "text": "Ujar District"
      },
      {
        "text": "Yardymli District"
      },
      {
        "text": "Yevlakh"
      },
      {
        "text": "Yevlakh District"
      },
      {
        "text": "Zangilan District"
      },
      {
        "text": "Zaqatala District"
      },
      {
        "text": "Zardab District"
      }
    ],
    "code": "+994"
  },
  {
    "text": "Bahamas The",
    "isoCode": "BS",
    "states": [
      {
        "text": "Acklins"
      },
      {
        "text": "Acklins and Crooked Islands"
      },
      {
        "text": "Berry Islands"
      },
      {
        "text": "Bimini"
      },
      {
        "text": "Black Point"
      },
      {
        "text": "Cat Island"
      },
      {
        "text": "Central Abaco"
      },
      {
        "text": "Central Andros"
      },
      {
        "text": "Central Eleuthera"
      },
      {
        "text": "Crooked Island"
      },
      {
        "text": "East Grand Bahama"
      },
      {
        "text": "Exuma"
      },
      {
        "text": "Freeport"
      },
      {
        "text": "Fresh Creek"
      },
      {
        "text": "Governor's Harbour"
      },
      {
        "text": "Grand Cay"
      },
      {
        "text": "Green Turtle Cay"
      },
      {
        "text": "Harbour Island"
      },
      {
        "text": "High Rock"
      },
      {
        "text": "Hope Town"
      },
      {
        "text": "Inagua"
      },
      {
        "text": "Kemps Bay"
      },
      {
        "text": "Long Island"
      },
      {
        "text": "Mangrove Cay"
      },
      {
        "text": "Marsh Harbour"
      },
      {
        "text": "Mayaguana District"
      },
      {
        "text": "New Providence"
      },
      {
        "text": "Nichollstown and Berry Islands"
      },
      {
        "text": "North Abaco"
      },
      {
        "text": "North Andros"
      },
      {
        "text": "North Eleuthera"
      },
      {
        "text": "Ragged Island"
      },
      {
        "text": "Rock Sound"
      },
      {
        "text": "Rum Cay District"
      },
      {
        "text": "San Salvador Island"
      },
      {
        "text": "San Salvador and Rum Cay"
      },
      {
        "text": "Sandy Point"
      },
      {
        "text": "South Abaco"
      },
      {
        "text": "South Andros"
      },
      {
        "text": "South Eleuthera"
      },
      {
        "text": "Spanish Wells"
      },
      {
        "text": "West Grand Bahama"
      }
    ]
  },
  {
    "text": "Bahrain",
    "isoCode": "BH",
    "states": [
      {
        "text": "Capital Governorate"
      },
      {
        "text": "Central Governorate"
      },
      {
        "text": "Muharraq Governorate"
      },
      {
        "text": "Northern Governorate"
      },
      {
        "text": "Southern Governorate"
      }
    ],
    "code": "+973"
  },
  {
    "text": "Bangladesh",
    "isoCode": "BD",
    "states": [
      {
        "text": "Bagerhat District"
      },
      {
        "text": "Bahadia"
      },
      {
        "text": "Bandarban District"
      },
      {
        "text": "Barguna District"
      },
      {
        "text": "Barisal District"
      },
      {
        "text": "Barisal Division"
      },
      {
        "text": "Bhola District"
      },
      {
        "text": "Bogra District"
      },
      {
        "text": "Brahmanbaria District"
      },
      {
        "text": "Chandpur District"
      },
      {
        "text": "Chapai Nawabganj District"
      },
      {
        "text": "Chittagong District"
      },
      {
        "text": "Chittagong Division"
      },
      {
        "text": "Chuadanga District"
      },
      {
        "text": "Comilla District"
      },
      {
        "text": "Cox's Bazar District"
      },
      {
        "text": "Dhaka District"
      },
      {
        "text": "Dhaka Division"
      },
      {
        "text": "Dinajpur District"
      },
      {
        "text": "Faridpur District"
      },
      {
        "text": "Feni District"
      },
      {
        "text": "Gaibandha District"
      },
      {
        "text": "Gazipur District"
      },
      {
        "text": "Gopalganj District"
      },
      {
        "text": "Habiganj District"
      },
      {
        "text": "Jamalpur District"
      },
      {
        "text": "Jessore District"
      },
      {
        "text": "Jhalokati District"
      },
      {
        "text": "Jhenaidah District"
      },
      {
        "text": "Joypurhat District"
      },
      {
        "text": "Khagrachari District"
      },
      {
        "text": "Khulna District"
      },
      {
        "text": "Khulna Division"
      },
      {
        "text": "Kishoreganj District"
      },
      {
        "text": "Kurigram District"
      },
      {
        "text": "Kushtia District"
      },
      {
        "text": "Lakshmipur District"
      },
      {
        "text": "Lalmonirhat District"
      },
      {
        "text": "Madaripur District"
      },
      {
        "text": "Meherpur District"
      },
      {
        "text": "Moulvibazar District"
      },
      {
        "text": "Munshiganj District"
      },
      {
        "text": "Mymensingh District"
      },
      {
        "text": "Mymensingh Division"
      },
      {
        "text": "Naogaon District"
      },
      {
        "text": "Narail District"
      },
      {
        "text": "Narayanganj District"
      },
      {
        "text": "Natore District"
      },
      {
        "text": "Netrokona District"
      },
      {
        "text": "Nilphamari District"
      },
      {
        "text": "Noakhali District"
      },
      {
        "text": "Pabna District"
      },
      {
        "text": "Panchagarh District"
      },
      {
        "text": "Patuakhali District"
      },
      {
        "text": "Pirojpur District"
      },
      {
        "text": "Rajbari District"
      },
      {
        "text": "Rajshahi District"
      },
      {
        "text": "Rajshahi Division"
      },
      {
        "text": "Rangamati Hill District"
      },
      {
        "text": "Rangpur District"
      },
      {
        "text": "Rangpur Division"
      },
      {
        "text": "Satkhira District"
      },
      {
        "text": "Shariatpur District"
      },
      {
        "text": "Sherpur District"
      },
      {
        "text": "Sirajganj District"
      },
      {
        "text": "Sunamganj District"
      },
      {
        "text": "Sylhet District"
      },
      {
        "text": "Sylhet Division"
      },
      {
        "text": "Tangail District"
      },
      {
        "text": "Thakurgaon District"
      }
    ],
    "code": "+880"
  },
  {
    "text": "Barbados",
    "isoCode": "BB",
    "states": [
      {
        "text": "Christ Church"
      },
      {
        "text": "Saint Andrew"
      },
      {
        "text": "Saint George"
      },
      {
        "text": "Saint James"
      },
      {
        "text": "Saint John"
      },
      {
        "text": "Saint Joseph"
      },
      {
        "text": "Saint Lucy"
      },
      {
        "text": "Saint Michael"
      },
      {
        "text": "Saint Peter"
      },
      {
        "text": "Saint Philip"
      },
      {
        "text": "Saint Thomas"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Belarus",
    "isoCode": "BY",
    "states": [
      {
        "text": "Brest Region"
      },
      {
        "text": "Gomel Region"
      },
      {
        "text": "Grodno Region"
      },
      {
        "text": "Minsk"
      },
      {
        "text": "Minsk Region"
      },
      {
        "text": "Mogilev Region"
      },
      {
        "text": "Vitebsk Region"
      }
    ],
    "code": "+375"
  },
  {
    "text": "Belgium",
    "isoCode": "BE",
    "states": [
      {
        "text": "Antwerp"
      },
      {
        "text": "Brussels-Capital Region"
      },
      {
        "text": "East Flanders"
      },
      {
        "text": "Flanders"
      },
      {
        "text": "Flemish Brabant"
      },
      {
        "text": "Hainaut"
      },
      {
        "text": "Limburg"
      },
      {
        "text": "Liège"
      },
      {
        "text": "Luxembourg"
      },
      {
        "text": "Namur"
      },
      {
        "text": "Wallonia"
      },
      {
        "text": "Walloon Brabant"
      },
      {
        "text": "West Flanders"
      }
    ],
    "code": "+32"
  },
  {
    "text": "Belize",
    "isoCode": "BZ",
    "states": [
      {
        "text": "Belize District"
      },
      {
        "text": "Cayo District"
      },
      {
        "text": "Corozal District"
      },
      {
        "text": "Orange Walk District"
      },
      {
        "text": "Stann Creek District"
      },
      {
        "text": "Toledo District"
      }
    ],
    "code": "+501"
  },
  {
    "text": "Benin",
    "isoCode": "BJ",
    "states": [
      {
        "text": "Alibori Department"
      },
      {
        "text": "Atakora Department"
      },
      {
        "text": "Atlantique Department"
      },
      {
        "text": "Borgou Department"
      },
      {
        "text": "Collines Department"
      },
      {
        "text": "Donga Department"
      },
      {
        "text": "Kouffo Department"
      },
      {
        "text": "Littoral Department"
      },
      {
        "text": "Mono Department"
      },
      {
        "text": "Ouémé Department"
      },
      {
        "text": "Plateau Department"
      },
      {
        "text": "Zou Department"
      }
    ],
    "code": "+229"
  },
  {
    "text": "Bermuda",
    "isoCode": "BM",
    "states": [
      {
        "text": "Devonshire Parish"
      },
      {
        "text": "Hamilton Municipality"
      },
      {
        "text": "Hamilton Parish"
      },
      {
        "text": "Paget Parish"
      },
      {
        "text": "Pembroke Parish"
      },
      {
        "text": "Saint George's Municipality"
      },
      {
        "text": "Saint George's Parish"
      },
      {
        "text": "Sandys Parish"
      },
      {
        "text": "Smith's Parish,"
      },
      {
        "text": "Southampton Parish"
      },
      {
        "text": "Warwick Parish"
      }
    ]
  },
  {
    "text": "Bhutan",
    "isoCode": "BT",
    "states": [
      {
        "text": "Bumthang District"
      },
      {
        "text": "Chukha District"
      },
      {
        "text": "Dagana District"
      },
      {
        "text": "Gasa District"
      },
      {
        "text": "Haa District"
      },
      {
        "text": "Lhuntse District"
      },
      {
        "text": "Mongar District"
      },
      {
        "text": "Paro District"
      },
      {
        "text": "Pemagatshel District"
      },
      {
        "text": "Punakha District"
      },
      {
        "text": "Samdrup Jongkhar District"
      },
      {
        "text": "Samtse District"
      },
      {
        "text": "Sarpang District"
      },
      {
        "text": "Thimphu District"
      },
      {
        "text": "Trashigang District"
      },
      {
        "text": "Trongsa District"
      },
      {
        "text": "Tsirang District"
      },
      {
        "text": "Wangdue Phodrang District"
      },
      {
        "text": "Zhemgang District"
      }
    ],
    "code": "+975"
  },
  {
    "text": "Bolivia",
    "isoCode": "BO",
    "states": [
      {
        "text": "Beni Department"
      },
      {
        "text": "Chuquisaca Department"
      },
      {
        "text": "Cochabamba Department"
      },
      {
        "text": "La Paz Department"
      },
      {
        "text": "Oruro Department"
      },
      {
        "text": "Pando Department"
      },
      {
        "text": "Potosí Department"
      },
      {
        "text": "Santa Cruz Department"
      },
      {
        "text": "Tarija Department"
      }
    ],
    "code": "+591"
  },
  {
    "text": "Bosnia and Herzegovina",
    "isoCode": "BA",
    "states": [
      {
        "text": "Bosnian Podrinje Canton"
      },
      {
        "text": "Brčko District"
      },
      {
        "text": "Canton 10"
      },
      {
        "text": "Central Bosnia Canton"
      },
      {
        "text": "Federation of Bosnia and Herzegovina"
      },
      {
        "text": "Herzegovina-Neretva Canton"
      },
      {
        "text": "Posavina Canton"
      },
      {
        "text": "Republika Srpska"
      },
      {
        "text": "Sarajevo Canton"
      },
      {
        "text": "Tuzla Canton"
      },
      {
        "text": "Una-Sana Canton"
      },
      {
        "text": "West Herzegovina Canton"
      },
      {
        "text": "Zenica-Doboj Canton"
      }
    ],
    "code": "+387"
  },
  {
    "text": "Botswana",
    "isoCode": "BW",
    "states": [
      {
        "text": "Central District"
      },
      {
        "text": "Ghanzi District"
      },
      {
        "text": "Kgalagadi District"
      },
      {
        "text": "Kgatleng District"
      },
      {
        "text": "Kweneng District"
      },
      {
        "text": "Ngamiland"
      },
      {
        "text": "North-East District"
      },
      {
        "text": "North-West District"
      },
      {
        "text": "South-East District"
      },
      {
        "text": "Southern District"
      }
    ],
    "code": "+267"
  },
  {
    "text": "Bouvet Island",
    "isoCode": "BV",
    "states": []
  },
  {
    "text": "Brazil",
    "isoCode": "BR",
    "states": [
      {
        "text": "Acre"
      },
      {
        "text": "Alagoas"
      },
      {
        "text": "Amapá"
      },
      {
        "text": "Amazonas"
      },
      {
        "text": "Bahia"
      },
      {
        "text": "Ceará"
      },
      {
        "text": "Espírito Santo"
      },
      {
        "text": "Federal District"
      },
      {
        "text": "Goiás"
      },
      {
        "text": "Maranhão"
      },
      {
        "text": "Mato Grosso"
      },
      {
        "text": "Mato Grosso do Sul"
      },
      {
        "text": "Minas Gerais"
      },
      {
        "text": "Paraná"
      },
      {
        "text": "Paraíba"
      },
      {
        "text": "Pará"
      },
      {
        "text": "Pernambuco"
      },
      {
        "text": "Piauí"
      },
      {
        "text": "Rio Grande do Norte"
      },
      {
        "text": "Rio Grande do Sul"
      },
      {
        "text": "Rio de Janeiro"
      },
      {
        "text": "Rondônia"
      },
      {
        "text": "Roraima"
      },
      {
        "text": "Santa Catarina"
      },
      {
        "text": "Sergipe"
      },
      {
        "text": "São Paulo"
      },
      {
        "text": "Tocantins"
      }
    ],
    "code": "+55"
  },
  {
    "text": "British Indian Ocean Territory",
    "isoCode": "IO",
    "states": []
  },
  {
    "text": "Brunei",
    "isoCode": "BN",
    "states": [
      {
        "text": "Belait District"
      },
      {
        "text": "Brunei-Muara District"
      },
      {
        "text": "Temburong District"
      },
      {
        "text": "Tutong District"
      }
    ],
    "code": "+673"
  },
  {
    "text": "Bulgaria",
    "isoCode": "BG",
    "states": [
      {
        "text": "Blagoevgrad Province"
      },
      {
        "text": "Burgas Province"
      },
      {
        "text": "Dobrich Province"
      },
      {
        "text": "Gabrovo Province"
      },
      {
        "text": "Haskovo Province"
      },
      {
        "text": "Kardzhali Province"
      },
      {
        "text": "Kyustendil Province"
      },
      {
        "text": "Lovech Province"
      },
      {
        "text": "Montana Province"
      },
      {
        "text": "Pazardzhik Province"
      },
      {
        "text": "Pernik Province"
      },
      {
        "text": "Pleven Province"
      },
      {
        "text": "Plovdiv Province"
      },
      {
        "text": "Razgrad Province"
      },
      {
        "text": "Ruse Province"
      },
      {
        "text": "Shumen"
      },
      {
        "text": "Silistra Province"
      },
      {
        "text": "Sliven Province"
      },
      {
        "text": "Smolyan Province"
      },
      {
        "text": "Sofia City Province"
      },
      {
        "text": "Sofia Province"
      },
      {
        "text": "Stara Zagora Province"
      },
      {
        "text": "Targovishte Province"
      },
      {
        "text": "Varna Province"
      },
      {
        "text": "Veliko Tarnovo Province"
      },
      {
        "text": "Vidin Province"
      },
      {
        "text": "Vratsa Province"
      },
      {
        "text": "Yambol Province"
      }
    ],
    "code": "+359"
  },
  {
    "text": "Burkina Faso",
    "isoCode": "BF",
    "states": [
      {
        "text": "Balé Province"
      },
      {
        "text": "Bam Province"
      },
      {
        "text": "Banwa Province"
      },
      {
        "text": "Bazèga Province"
      },
      {
        "text": "Boucle du Mouhoun Region"
      },
      {
        "text": "Bougouriba Province"
      },
      {
        "text": "Boulgou"
      },
      {
        "text": "Cascades Region"
      },
      {
        "text": "Centre"
      },
      {
        "text": "Centre-Est Region"
      },
      {
        "text": "Centre-Nord Region"
      },
      {
        "text": "Centre-Ouest Region"
      },
      {
        "text": "Centre-Sud Region"
      },
      {
        "text": "Comoé Province"
      },
      {
        "text": "Est Region"
      },
      {
        "text": "Ganzourgou Province"
      },
      {
        "text": "Gnagna Province"
      },
      {
        "text": "Gourma Province"
      },
      {
        "text": "Hauts-Bassins Region"
      },
      {
        "text": "Houet Province"
      },
      {
        "text": "Ioba Province"
      },
      {
        "text": "Kadiogo Province"
      },
      {
        "text": "Komondjari Province"
      },
      {
        "text": "Kompienga Province"
      },
      {
        "text": "Kossi Province"
      },
      {
        "text": "Koulpélogo Province"
      },
      {
        "text": "Kouritenga Province"
      },
      {
        "text": "Kourwéogo Province"
      },
      {
        "text": "Kénédougou Province"
      },
      {
        "text": "Loroum Province"
      },
      {
        "text": "Léraba Province"
      },
      {
        "text": "Mouhoun"
      },
      {
        "text": "Nahouri Province"
      },
      {
        "text": "Namentenga Province"
      },
      {
        "text": "Nayala Province"
      },
      {
        "text": "Nord Region, Burkina Faso"
      },
      {
        "text": "Noumbiel Province"
      },
      {
        "text": "Oubritenga Province"
      },
      {
        "text": "Oudalan Province"
      },
      {
        "text": "Passoré Province"
      },
      {
        "text": "Plateau-Central Region"
      },
      {
        "text": "Poni Province"
      },
      {
        "text": "Sahel Region"
      },
      {
        "text": "Sanguié Province"
      },
      {
        "text": "Sanmatenga Province"
      },
      {
        "text": "Sissili Province"
      },
      {
        "text": "Soum Province"
      },
      {
        "text": "Sourou Province"
      },
      {
        "text": "Sud-Ouest Region"
      },
      {
        "text": "Séno Province"
      },
      {
        "text": "Tapoa Province"
      },
      {
        "text": "Tuy Province"
      },
      {
        "text": "Yagha Province"
      },
      {
        "text": "Yatenga Province"
      },
      {
        "text": "Ziro Province"
      },
      {
        "text": "Zondoma Province"
      },
      {
        "text": "Zoundwéogo Province"
      }
    ],
    "code": "+226"
  },
  {
    "text": "Burundi",
    "isoCode": "BI",
    "states": [
      {
        "text": "Bubanza Province"
      },
      {
        "text": "Bujumbura Mairie Province"
      },
      {
        "text": "Bujumbura Rural Province"
      },
      {
        "text": "Bururi Province"
      },
      {
        "text": "Cankuzo Province"
      },
      {
        "text": "Cibitoke Province"
      },
      {
        "text": "Gitega Province"
      },
      {
        "text": "Karuzi Province"
      },
      {
        "text": "Kayanza Province"
      },
      {
        "text": "Kirundo Province"
      },
      {
        "text": "Makamba Province"
      },
      {
        "text": "Muramvya Province"
      },
      {
        "text": "Muyinga Province"
      },
      {
        "text": "Mwaro Province"
      },
      {
        "text": "Ngozi Province"
      },
      {
        "text": "Rumonge Province"
      },
      {
        "text": "Rutana Province"
      },
      {
        "text": "Ruyigi Province"
      }
    ],
    "code": "+257"
  },
  {
    "text": "Cambodia",
    "isoCode": "KH",
    "states": [
      {
        "text": "Banteay Meanchey Province"
      },
      {
        "text": "Battambang Province"
      },
      {
        "text": "Kampong Cham Province"
      },
      {
        "text": "Kampong Chhnang Province"
      },
      {
        "text": "Kampong Speu Province"
      },
      {
        "text": "Kampot Province"
      },
      {
        "text": "Kandal Province"
      },
      {
        "text": "Kep Province"
      },
      {
        "text": "Koh Kong Province"
      },
      {
        "text": "Kratié Province"
      },
      {
        "text": "Mondulkiri Province"
      },
      {
        "text": "Oddar Meanchey Province"
      },
      {
        "text": "Pailin Province"
      },
      {
        "text": "Phnom Penh"
      },
      {
        "text": "Preah Vihear Province"
      },
      {
        "text": "Prey Veng Province"
      },
      {
        "text": "Pursat Province"
      },
      {
        "text": "Ratanakiri Province"
      },
      {
        "text": "Siem Reap Province"
      },
      {
        "text": "Sihanoukville Province"
      },
      {
        "text": "Stung Treng Province"
      },
      {
        "text": "Svay Rieng Province"
      },
      {
        "text": "Tai Po District"
      },
      {
        "text": "Takéo Province"
      }
    ],
    "code": "+855"
  },
  {
    "text": "Cameroon",
    "isoCode": "CM",
    "states": [
      {
        "text": "Adamawa"
      },
      {
        "text": "Centre"
      },
      {
        "text": "East"
      },
      {
        "text": "Far North"
      },
      {
        "text": "Littoral"
      },
      {
        "text": "North"
      },
      {
        "text": "Northwest"
      },
      {
        "text": "South"
      },
      {
        "text": "Southwest"
      },
      {
        "text": "West"
      }
    ],
    "code": "+237"
  },
  {
    "text": "Canada",
    "isoCode": "CA",
    "states": [
      {
        "text": "Alberta"
      },
      {
        "text": "British Columbia"
      },
      {
        "text": "Manitoba"
      },
      {
        "text": "New Brunswick"
      },
      {
        "text": "Newfoundland and Labrador"
      },
      {
        "text": "Northwest Territories"
      },
      {
        "text": "Nova Scotia"
      },
      {
        "text": "Nunavut"
      },
      {
        "text": "Ontario"
      },
      {
        "text": "Prince Edward Island"
      },
      {
        "text": "Quebec"
      },
      {
        "text": "Saskatchewan"
      },
      {
        "text": "Yukon"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Cape Verde",
    "isoCode": "CV",
    "states": [
      {
        "text": "Barlavento Islands"
      },
      {
        "text": "Boa Vista"
      },
      {
        "text": "Brava"
      },
      {
        "text": "Maio Municipality"
      },
      {
        "text": "Mosteiros"
      },
      {
        "text": "Paul"
      },
      {
        "text": "Porto Novo"
      },
      {
        "text": "Praia"
      },
      {
        "text": "Ribeira Brava Municipality"
      },
      {
        "text": "Ribeira Grande"
      },
      {
        "text": "Ribeira Grande de Santiago"
      },
      {
        "text": "Sal"
      },
      {
        "text": "Santa Catarina"
      },
      {
        "text": "Santa Catarina do Fogo"
      },
      {
        "text": "Santa Cruz"
      },
      {
        "text": "Sotavento Islands"
      },
      {
        "text": "São Domingos"
      },
      {
        "text": "São Filipe"
      },
      {
        "text": "São Lourenço dos Órgãos"
      },
      {
        "text": "São Miguel"
      },
      {
        "text": "São Vicente"
      },
      {
        "text": "Tarrafal"
      },
      {
        "text": "Tarrafal de São Nicolau"
      }
    ],
    "code": "+238"
  },
  {
    "text": "Cayman Islands",
    "isoCode": "KY",
    "states": []
  },
  {
    "text": "Central African Republic",
    "isoCode": "CF",
    "states": [
      {
        "text": "Bamingui-Bangoran Prefecture"
      },
      {
        "text": "Bangui"
      },
      {
        "text": "Basse-Kotto Prefecture"
      },
      {
        "text": "Haut-Mbomou Prefecture"
      },
      {
        "text": "Haute-Kotto Prefecture"
      },
      {
        "text": "Kémo Prefecture"
      },
      {
        "text": "Lobaye Prefecture"
      },
      {
        "text": "Mambéré-Kadéï"
      },
      {
        "text": "Mbomou Prefecture"
      },
      {
        "text": "Nana-Grébizi Economic Prefecture"
      },
      {
        "text": "Nana-Mambéré Prefecture"
      },
      {
        "text": "Ombella-M'Poko Prefecture"
      },
      {
        "text": "Ouaka Prefecture"
      },
      {
        "text": "Ouham Prefecture"
      },
      {
        "text": "Ouham-Pendé Prefecture"
      },
      {
        "text": "Sangha-Mbaéré"
      },
      {
        "text": "Vakaga Prefecture"
      }
    ],
    "code": "+236"
  },
  {
    "text": "Chad",
    "isoCode": "TD",
    "states": [
      {
        "text": "Bahr el Gazel"
      },
      {
        "text": "Batha Region"
      },
      {
        "text": "Borkou"
      },
      {
        "text": "Ennedi Region"
      },
      {
        "text": "Ennedi-Est"
      },
      {
        "text": "Ennedi-Ouest"
      },
      {
        "text": "Guéra Region"
      },
      {
        "text": "Hadjer-Lamis"
      },
      {
        "text": "Kanem Region"
      },
      {
        "text": "Lac Region"
      },
      {
        "text": "Logone Occidental Region"
      },
      {
        "text": "Logone Oriental Region"
      },
      {
        "text": "Mandoul Region"
      },
      {
        "text": "Mayo-Kebbi Est Region"
      },
      {
        "text": "Mayo-Kebbi Ouest Region"
      },
      {
        "text": "Moyen-Chari Region"
      },
      {
        "text": "N'Djamena"
      },
      {
        "text": "Ouaddaï Region"
      },
      {
        "text": "Salamat Region"
      },
      {
        "text": "Sila Region"
      },
      {
        "text": "Tandjilé Region"
      },
      {
        "text": "Tibesti Region"
      },
      {
        "text": "Wadi Fira Region"
      }
    ],
    "code": "+235"
  },
  {
    "text": "Chile",
    "isoCode": "CL",
    "states": [
      {
        "text": "Antofagasta Region"
      },
      {
        "text": "Araucanía Region"
      },
      {
        "text": "Arica y Parinacota Region"
      },
      {
        "text": "Atacama Region"
      },
      {
        "text": "Aysén Region"
      },
      {
        "text": "Bío Bío Region"
      },
      {
        "text": "Coquimbo Region"
      },
      {
        "text": "Los Lagos Region"
      },
      {
        "text": "Los Ríos Region"
      },
      {
        "text": "Magellan and the Chilean Antarctic Region"
      },
      {
        "text": "Maule Region"
      },
      {
        "text": "O'Higgins"
      },
      {
        "text": "Santiago Metropolitan Region"
      },
      {
        "text": "Tarapacá Region"
      },
      {
        "text": "Valparaíso"
      },
      {
        "text": "Ñuble Region"
      }
    ],
    "code": "+56"
  },
  {
    "text": "China",
    "isoCode": "CN",
    "states": [
      {
        "text": "Anhui"
      },
      {
        "text": "Beijing"
      },
      {
        "text": "Chongqing"
      },
      {
        "text": "Fujian"
      },
      {
        "text": "Gansu"
      },
      {
        "text": "Guangdong"
      },
      {
        "text": "Guangxi Zhuang Autonomous Region"
      },
      {
        "text": "Guizhou"
      },
      {
        "text": "Hainan"
      },
      {
        "text": "Hebei"
      },
      {
        "text": "Heilongjiang"
      },
      {
        "text": "Henan"
      },
      {
        "text": "Hong Kong"
      },
      {
        "text": "Hubei"
      },
      {
        "text": "Hunan"
      },
      {
        "text": "Inner Mongolia"
      },
      {
        "text": "Jiangsu"
      },
      {
        "text": "Jiangxi"
      },
      {
        "text": "Jilin"
      },
      {
        "text": "Keelung"
      },
      {
        "text": "Liaoning"
      },
      {
        "text": "Macau"
      },
      {
        "text": "Ningxia Hui Autonomous Region"
      },
      {
        "text": "Qinghai"
      },
      {
        "text": "Shaanxi"
      },
      {
        "text": "Shandong"
      },
      {
        "text": "Shanghai"
      },
      {
        "text": "Shanxi"
      },
      {
        "text": "Sichuan"
      },
      {
        "text": "Taiwan Province, People's Republic of China"
      },
      {
        "text": "Tibet Autonomous Region"
      },
      {
        "text": "Xinjiang"
      },
      {
        "text": "Yunnan"
      },
      {
        "text": "Zhejiang"
      }
    ],
    "code": "+86"
  },
  {
    "text": "Christmas Island",
    "isoCode": "CX",
    "states": []
  },
  {
    "text": "Cocos (Keeling) Islands",
    "isoCode": "CC",
    "states": []
  },
  {
    "text": "Colombia",
    "isoCode": "CO",
    "states": [
      {
        "text": "Amazonas Department"
      },
      {
        "text": "Antioquia Department"
      },
      {
        "text": "Arauca Department"
      },
      {
        "text": "Archipelago of Saint Andréws, Providence and Saint Catalina"
      },
      {
        "text": "Atlántico Department"
      },
      {
        "text": "Bolívar Department"
      },
      {
        "text": "Boyacá Department"
      },
      {
        "text": "Caldas Department"
      },
      {
        "text": "Caquetá Department"
      },
      {
        "text": "Casanare Department"
      },
      {
        "text": "Cauca Department"
      },
      {
        "text": "Cesar Department"
      },
      {
        "text": "Chocó Department"
      },
      {
        "text": "Cundinamarca Department"
      },
      {
        "text": "Córdoba Department"
      },
      {
        "text": "Guainía Department"
      },
      {
        "text": "Guaviare Department"
      },
      {
        "text": "Huila Department"
      },
      {
        "text": "La Guajira Department"
      },
      {
        "text": "Magdalena Department"
      },
      {
        "text": "Meta"
      },
      {
        "text": "Nariño Department"
      },
      {
        "text": "Norte de Santander Department"
      },
      {
        "text": "Putumayo Department"
      },
      {
        "text": "Quindío Department"
      },
      {
        "text": "Risaralda Department"
      },
      {
        "text": "Santander Department"
      },
      {
        "text": "Sucre Department"
      },
      {
        "text": "Tolima Department"
      },
      {
        "text": "Valle del Cauca Department"
      },
      {
        "text": "Vaupés Department"
      },
      {
        "text": "Vichada Department"
      }
    ],
    "code": "+57"
  },
  {
    "text": "Comoros",
    "isoCode": "KM",
    "states": [
      {
        "text": "Anjouan"
      },
      {
        "text": "Grande Comore"
      },
      {
        "text": "Mohéli"
      }
    ],
    "code": "+269"
  },
  {
    "text": "Congo",
    "isoCode": "CG",
    "states": [
      {
        "text": "Bouenza Department"
      },
      {
        "text": "Brazzaville"
      },
      {
        "text": "Cuvette Department"
      },
      {
        "text": "Cuvette-Ouest Department"
      },
      {
        "text": "Kouilou Department"
      },
      {
        "text": "Likouala Department"
      },
      {
        "text": "Lékoumou Department"
      },
      {
        "text": "Niari Department"
      },
      {
        "text": "Plateaux Department"
      },
      {
        "text": "Pointe-Noire"
      },
      {
        "text": "Pool Department"
      },
      {
        "text": "Sangha Department"
      }
    ]
  },
  {
    "text": "Congo The Democratic Republic Of The",
    "isoCode": "CD",
    "states": [
      {
        "text": "Bandundu Province"
      },
      {
        "text": "Bas-Congo province"
      },
      {
        "text": "Bas-Uele"
      },
      {
        "text": "Haut-Katanga Province"
      },
      {
        "text": "Haut-Lomami District"
      },
      {
        "text": "Haut-Uele"
      },
      {
        "text": "Ituri Interim Administration"
      },
      {
        "text": "Kasaï District"
      },
      {
        "text": "Kasaï-Occidental"
      },
      {
        "text": "Kasaï-Oriental"
      },
      {
        "text": "Katanga Province"
      },
      {
        "text": "Kinshasa"
      },
      {
        "text": "Kwango District"
      },
      {
        "text": "Kwilu District"
      },
      {
        "text": "Lomami Province"
      },
      {
        "text": "Mai-Ndombe Province"
      },
      {
        "text": "Maniema"
      },
      {
        "text": "Mongala District"
      },
      {
        "text": "Nord-Ubangi District"
      },
      {
        "text": "North Kivu"
      },
      {
        "text": "Orientale Province"
      },
      {
        "text": "Sankuru District"
      },
      {
        "text": "South Kivu"
      },
      {
        "text": "Sud-Ubangi"
      },
      {
        "text": "Tanganyika Province"
      },
      {
        "text": "Tshopo District"
      },
      {
        "text": "Tshuapa District"
      },
      {
        "text": "Équateur"
      }
    ]
  },
  {
    "text": "Cook Islands",
    "isoCode": "CK",
    "states": []
  },
  {
    "text": "Costa Rica",
    "isoCode": "CR",
    "states": [
      {
        "text": "Alajuela Province"
      },
      {
        "text": "Guanacaste Province"
      },
      {
        "text": "Heredia Province"
      },
      {
        "text": "Limón Province"
      },
      {
        "text": "Provincia de Cartago"
      },
      {
        "text": "Puntarenas Province"
      },
      {
        "text": "San José Province"
      }
    ],
    "code": "+506"
  },
  {
    "text": "Cote D'Ivoire (Ivory Coast)",
    "isoCode": "CI",
    "states": [
      {
        "text": "Abidjan"
      },
      {
        "text": "Agnéby"
      },
      {
        "text": "Bafing Region"
      },
      {
        "text": "Bas-Sassandra District"
      },
      {
        "text": "Bas-Sassandra Region"
      },
      {
        "text": "Comoé District"
      },
      {
        "text": "Denguélé District"
      },
      {
        "text": "Denguélé Region"
      },
      {
        "text": "Dix-Huit Montagnes"
      },
      {
        "text": "Fromager"
      },
      {
        "text": "Gôh-Djiboua District"
      },
      {
        "text": "Haut-Sassandra"
      },
      {
        "text": "Lacs District"
      },
      {
        "text": "Lacs Region"
      },
      {
        "text": "Lagunes District"
      },
      {
        "text": "Lagunes region"
      },
      {
        "text": "Marahoué Region"
      },
      {
        "text": "Montagnes District"
      },
      {
        "text": "Moyen-Cavally"
      },
      {
        "text": "Moyen-Comoé"
      },
      {
        "text": "N'zi-Comoé"
      },
      {
        "text": "Sassandra-Marahoué District"
      },
      {
        "text": "Savanes Region"
      },
      {
        "text": "Sud-Bandama"
      },
      {
        "text": "Sud-Comoé"
      },
      {
        "text": "Vallée du Bandama District"
      },
      {
        "text": "Vallée du Bandama Region"
      },
      {
        "text": "Woroba District"
      },
      {
        "text": "Worodougou"
      },
      {
        "text": "Yamoussoukro"
      },
      {
        "text": "Zanzan Region"
      }
    ]
  },
  {
    "text": "Croatia (Hrvatska)",
    "isoCode": "HR",
    "states": [
      {
        "text": "Bjelovar-Bilogora County"
      },
      {
        "text": "Brod-Posavina County"
      },
      {
        "text": "Dubrovnik-Neretva County"
      },
      {
        "text": "Istria County"
      },
      {
        "text": "Koprivnica-Križevci County"
      },
      {
        "text": "Krapina-Zagorje County"
      },
      {
        "text": "Lika-Senj County"
      },
      {
        "text": "Međimurje County"
      },
      {
        "text": "Osijek-Baranja County"
      },
      {
        "text": "Požega-Slavonia County"
      },
      {
        "text": "Primorje-Gorski Kotar County"
      },
      {
        "text": "Sisak-Moslavina County"
      },
      {
        "text": "Split-Dalmatia County"
      },
      {
        "text": "Varaždin County"
      },
      {
        "text": "Virovitica-Podravina County"
      },
      {
        "text": "Vukovar-Syrmia County"
      },
      {
        "text": "Zadar County"
      },
      {
        "text": "Zagreb"
      },
      {
        "text": "Zagreb County"
      },
      {
        "text": "Šibenik-Knin County"
      }
    ]
  },
  {
    "text": "Cuba",
    "isoCode": "CU",
    "states": [
      {
        "text": "Artemisa Province"
      },
      {
        "text": "Camagüey Province"
      },
      {
        "text": "Ciego de Ávila Province"
      },
      {
        "text": "Cienfuegos Province"
      },
      {
        "text": "Granma Province"
      },
      {
        "text": "Guantánamo Province"
      },
      {
        "text": "Havana Province"
      },
      {
        "text": "Holguín Province"
      },
      {
        "text": "Isla de la Juventud"
      },
      {
        "text": "Las Tunas Province"
      },
      {
        "text": "Matanzas Province"
      },
      {
        "text": "Mayabeque Province"
      },
      {
        "text": "Pinar del Río Province"
      },
      {
        "text": "Sancti Spíritus Province"
      },
      {
        "text": "Santiago de Cuba Province"
      },
      {
        "text": "Villa Clara Province"
      }
    ],
    "code": "+53"
  },
  {
    "text": "Cyprus",
    "isoCode": "CY",
    "states": [
      {
        "text": "Famagusta District"
      },
      {
        "text": "Kyrenia District"
      },
      {
        "text": "Larnaca District"
      },
      {
        "text": "Limassol District"
      },
      {
        "text": "Nicosia District"
      },
      {
        "text": "Paphos District"
      }
    ],
    "code": "+357"
  },
  {
    "text": "Czech Republic",
    "isoCode": "CZ",
    "states": [
      {
        "text": "Benešov District"
      },
      {
        "text": "Beroun District"
      },
      {
        "text": "Blansko District"
      },
      {
        "text": "Brno-City District"
      },
      {
        "text": "Brno-Country District"
      },
      {
        "text": "Bruntál District"
      },
      {
        "text": "Břeclav District"
      },
      {
        "text": "Central Bohemian Region"
      },
      {
        "text": "Cheb District"
      },
      {
        "text": "Chomutov District"
      },
      {
        "text": "Chrudim District"
      },
      {
        "text": "Domažlice District"
      },
      {
        "text": "Děčín District"
      },
      {
        "text": "Frýdek-Místek District"
      },
      {
        "text": "Havlíčkův Brod District"
      },
      {
        "text": "Hodonín District"
      },
      {
        "text": "Horní Počernice"
      },
      {
        "text": "Hradec Králové District"
      },
      {
        "text": "Hradec Králové Region"
      },
      {
        "text": "Jablonec nad Nisou District"
      },
      {
        "text": "Jeseník District"
      },
      {
        "text": "Jihlava District"
      },
      {
        "text": "Jindřichův Hradec District"
      },
      {
        "text": "Jičín District"
      },
      {
        "text": "Karlovy Vary District"
      },
      {
        "text": "Karlovy Vary Region"
      },
      {
        "text": "Karviná District"
      },
      {
        "text": "Kladno District"
      },
      {
        "text": "Klatovy District"
      },
      {
        "text": "Kolín District"
      },
      {
        "text": "Kroměříž District"
      },
      {
        "text": "Liberec District"
      },
      {
        "text": "Liberec Region"
      },
      {
        "text": "Litoměřice District"
      },
      {
        "text": "Louny District"
      },
      {
        "text": "Mladá Boleslav District"
      },
      {
        "text": "Moravian-Silesian Region"
      },
      {
        "text": "Most District"
      },
      {
        "text": "Mělník District"
      },
      {
        "text": "Nový Jičín District"
      },
      {
        "text": "Nymburk District"
      },
      {
        "text": "Náchod District"
      },
      {
        "text": "Olomouc District"
      },
      {
        "text": "Olomouc Region"
      },
      {
        "text": "Opava District"
      },
      {
        "text": "Ostrava-City District"
      },
      {
        "text": "Pardubice District"
      },
      {
        "text": "Pardubice Region"
      },
      {
        "text": "Pelhřimov District"
      },
      {
        "text": "Plzeň Region"
      },
      {
        "text": "Plzeň-City District"
      },
      {
        "text": "Plzeň-North District"
      },
      {
        "text": "Plzeň-South District"
      },
      {
        "text": "Prachatice District"
      },
      {
        "text": "Prague"
      },
      {
        "text": "Prague 1"
      },
      {
        "text": "Prague 10"
      },
      {
        "text": "Prague 11"
      },
      {
        "text": "Prague 12"
      },
      {
        "text": "Prague 13"
      },
      {
        "text": "Prague 14"
      },
      {
        "text": "Prague 15"
      },
      {
        "text": "Prague 16"
      },
      {
        "text": "Prague 2"
      },
      {
        "text": "Prague 21"
      },
      {
        "text": "Prague 3"
      },
      {
        "text": "Prague 4"
      },
      {
        "text": "Prague 5"
      },
      {
        "text": "Prague 6"
      },
      {
        "text": "Prague 7"
      },
      {
        "text": "Prague 8"
      },
      {
        "text": "Prague 9"
      },
      {
        "text": "Prague-East District"
      },
      {
        "text": "Prague-West District"
      },
      {
        "text": "Prostějov District"
      },
      {
        "text": "Písek District"
      },
      {
        "text": "Přerov District"
      },
      {
        "text": "Příbram District"
      },
      {
        "text": "Rakovník District"
      },
      {
        "text": "Rokycany District"
      },
      {
        "text": "Rychnov nad Kněžnou District"
      },
      {
        "text": "Semily District"
      },
      {
        "text": "Sokolov District"
      },
      {
        "text": "South Bohemian Region"
      },
      {
        "text": "South Moravian Region"
      },
      {
        "text": "Strakonice District"
      },
      {
        "text": "Svitavy District"
      },
      {
        "text": "Tachov District"
      },
      {
        "text": "Teplice District"
      },
      {
        "text": "Trutnov District"
      },
      {
        "text": "Tábor District"
      },
      {
        "text": "Třebíč District"
      },
      {
        "text": "Uherské Hradiště District"
      },
      {
        "text": "Vsetín District"
      },
      {
        "text": "Vysočina Region"
      },
      {
        "text": "Vyškov District"
      },
      {
        "text": "Zlín District"
      },
      {
        "text": "Zlín Region"
      },
      {
        "text": "Znojmo District"
      },
      {
        "text": "Ústí nad Labem District"
      },
      {
        "text": "Ústí nad Labem Region"
      },
      {
        "text": "Ústí nad Orlicí District"
      },
      {
        "text": "Česká Lípa District"
      },
      {
        "text": "České Budějovice District"
      },
      {
        "text": "Český Krumlov District"
      },
      {
        "text": "Šumperk District"
      },
      {
        "text": "Žďár nad Sázavou District"
      }
    ],
    "code": "+420"
  },
  {
    "text": "Denmark",
    "isoCode": "DK",
    "states": [
      {
        "text": "Capital Region of Denmark"
      },
      {
        "text": "Central Denmark Region"
      },
      {
        "text": "North Denmark Region"
      },
      {
        "text": "Region Zealand"
      },
      {
        "text": "Region of Southern Denmark"
      }
    ],
    "code": "+45"
  },
  {
    "text": "Djibouti",
    "isoCode": "DJ",
    "states": [
      {
        "text": "Ali Sabieh Region"
      },
      {
        "text": "Arta Region"
      },
      {
        "text": "Dikhil Region"
      },
      {
        "text": "Djibouti"
      },
      {
        "text": "Obock Region"
      },
      {
        "text": "Tadjourah Region"
      }
    ],
    "code": "+253"
  },
  {
    "text": "Dominica",
    "isoCode": "DM",
    "states": [
      {
        "text": "Saint Andrew Parish"
      },
      {
        "text": "Saint David Parish"
      },
      {
        "text": "Saint George Parish"
      },
      {
        "text": "Saint John Parish"
      },
      {
        "text": "Saint Joseph Parish"
      },
      {
        "text": "Saint Luke Parish"
      },
      {
        "text": "Saint Mark Parish"
      },
      {
        "text": "Saint Patrick Parish"
      },
      {
        "text": "Saint Paul Parish"
      },
      {
        "text": "Saint Peter Parish"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Dominican Republic",
    "isoCode": "DO",
    "states": [
      {
        "text": "Azua Province"
      },
      {
        "text": "Baoruco Province"
      },
      {
        "text": "Barahona Province"
      },
      {
        "text": "Dajabón Province"
      },
      {
        "text": "Distrito Nacional"
      },
      {
        "text": "Duarte Province"
      },
      {
        "text": "El Seibo Province"
      },
      {
        "text": "Espaillat Province"
      },
      {
        "text": "Hato Mayor Province"
      },
      {
        "text": "Hermanas Mirabal Province"
      },
      {
        "text": "Independencia"
      },
      {
        "text": "La Altagracia Province"
      },
      {
        "text": "La Romana Province"
      },
      {
        "text": "La Vega Province"
      },
      {
        "text": "María Trinidad Sánchez Province"
      },
      {
        "text": "Monseñor Nouel Province"
      },
      {
        "text": "Monte Cristi Province"
      },
      {
        "text": "Monte Plata Province"
      },
      {
        "text": "Pedernales Province"
      },
      {
        "text": "Peravia Province"
      },
      {
        "text": "Puerto Plata Province"
      },
      {
        "text": "Samaná Province"
      },
      {
        "text": "San Cristóbal Province"
      },
      {
        "text": "San José de Ocoa Province"
      },
      {
        "text": "San Juan Province"
      },
      {
        "text": "San Pedro de Macorís"
      },
      {
        "text": "Santiago Province"
      },
      {
        "text": "Santiago Rodríguez Province"
      },
      {
        "text": "Santo Domingo Province"
      },
      {
        "text": "Sánchez Ramírez Province"
      },
      {
        "text": "Valverde Province"
      }
    ],
    "code": "+1"
  },
  {
    "text": "East Timor",
    "isoCode": "TL",
    "states": [
      {
        "text": "Aileu municipality"
      },
      {
        "text": "Ainaro Municipality"
      },
      {
        "text": "Baucau Municipality"
      },
      {
        "text": "Bobonaro Municipality"
      },
      {
        "text": "Cova Lima Municipality"
      },
      {
        "text": "Dili municipality"
      },
      {
        "text": "Ermera District"
      },
      {
        "text": "Lautém Municipality"
      },
      {
        "text": "Liquiçá Municipality"
      },
      {
        "text": "Manatuto District"
      },
      {
        "text": "Manufahi Municipality"
      },
      {
        "text": "Viqueque Municipality"
      }
    ],
    "code": "+670"
  },
  {
    "text": "Ecuador",
    "isoCode": "EC",
    "states": [
      {
        "text": "Azuay Province"
      },
      {
        "text": "Bolívar Province"
      },
      {
        "text": "Carchi Province"
      },
      {
        "text": "Cañar Province"
      },
      {
        "text": "Chimborazo Province"
      },
      {
        "text": "Cotopaxi Province"
      },
      {
        "text": "El Oro Province"
      },
      {
        "text": "Esmeraldas"
      },
      {
        "text": "Galápagos Province"
      },
      {
        "text": "Guayas Province"
      },
      {
        "text": "Imbabura Province"
      },
      {
        "text": "Los Ríos Province"
      },
      {
        "text": "Manabí Province"
      },
      {
        "text": "Morona-Santiago Province"
      },
      {
        "text": "Napo Province"
      },
      {
        "text": "Orellana Province"
      },
      {
        "text": "Pastaza Province"
      },
      {
        "text": "Pichincha Province"
      },
      {
        "text": "Santa Elena Province"
      },
      {
        "text": "Santo Domingo de los Tsáchilas Province"
      },
      {
        "text": "Sucumbíos Province"
      },
      {
        "text": "Tungurahua Province"
      },
      {
        "text": "Zamora-Chinchipe Province"
      }
    ],
    "code": "+593"
  },
  {
    "text": "Egypt",
    "isoCode": "EG",
    "states": [
      {
        "text": "Alexandria Governorate"
      },
      {
        "text": "Aswan Governorate"
      },
      {
        "text": "Asyut Governorate"
      },
      {
        "text": "Beheira Governorate"
      },
      {
        "text": "Beni Suef Governorate"
      },
      {
        "text": "Cairo Governorate"
      },
      {
        "text": "Dakahlia Governorate"
      },
      {
        "text": "Damietta Governorate"
      },
      {
        "text": "Faiyum Governorate"
      },
      {
        "text": "Gharbia Governorate"
      },
      {
        "text": "Giza Governorate"
      },
      {
        "text": "Ismailia Governorate"
      },
      {
        "text": "Kafr el-Sheikh Governorate"
      },
      {
        "text": "Luxor Governorate"
      },
      {
        "text": "Matrouh Governorate"
      },
      {
        "text": "Minya Governorate"
      },
      {
        "text": "Monufia Governorate"
      },
      {
        "text": "New Valley Governorate"
      },
      {
        "text": "North Sinai Governorate"
      },
      {
        "text": "Port Said Governorate"
      },
      {
        "text": "Qalyubia Governorate"
      },
      {
        "text": "Qena Governorate"
      },
      {
        "text": "Red Sea Governorate"
      },
      {
        "text": "Sohag Governorate"
      },
      {
        "text": "South Sinai Governorate"
      },
      {
        "text": "Suez Governorate"
      }
    ],
    "code": "+20"
  },
  {
    "text": "El Salvador",
    "isoCode": "SV",
    "states": [
      {
        "text": "Ahuachapán Department"
      },
      {
        "text": "Cabañas Department"
      },
      {
        "text": "Chalatenango Department"
      },
      {
        "text": "Cuscatlán Department"
      },
      {
        "text": "La Libertad Department"
      },
      {
        "text": "La Paz Department"
      },
      {
        "text": "La Unión Department"
      },
      {
        "text": "Morazán Department"
      },
      {
        "text": "San Miguel Department"
      },
      {
        "text": "San Salvador Department"
      },
      {
        "text": "San Vicente Department"
      },
      {
        "text": "Santa Ana Department"
      },
      {
        "text": "Sonsonate Department"
      },
      {
        "text": "Usulután Department"
      }
    ],
    "code": "+503"
  },
  {
    "text": "Equatorial Guinea",
    "isoCode": "GQ",
    "states": [
      {
        "text": "Annobón Province"
      },
      {
        "text": "Bioko Norte Province"
      },
      {
        "text": "Bioko Sur Province"
      },
      {
        "text": "Centro Sur Province"
      },
      {
        "text": "Insular Region"
      },
      {
        "text": "Kié-Ntem Province"
      },
      {
        "text": "Litoral Province"
      },
      {
        "text": "Río Muni"
      },
      {
        "text": "Wele-Nzas Province"
      }
    ],
    "code": "+240"
  },
  {
    "text": "Eritrea",
    "isoCode": "ER",
    "states": [
      {
        "text": "Anseba Region"
      },
      {
        "text": "Debub Region"
      },
      {
        "text": "Gash-Barka Region"
      },
      {
        "text": "Maekel Region"
      },
      {
        "text": "Northern Red Sea Region"
      },
      {
        "text": "Southern Red Sea Region"
      }
    ],
    "code": "+291"
  },
  {
    "text": "Estonia",
    "isoCode": "EE",
    "states": [
      {
        "text": "Harju County"
      },
      {
        "text": "Hiiu County"
      },
      {
        "text": "Ida-Viru County"
      },
      {
        "text": "Järva County"
      },
      {
        "text": "Jõgeva County"
      },
      {
        "text": "Lääne County"
      },
      {
        "text": "Lääne-Viru County"
      },
      {
        "text": "Pärnu County"
      },
      {
        "text": "Põlva County"
      },
      {
        "text": "Rapla County"
      },
      {
        "text": "Saare County"
      },
      {
        "text": "Tartu County"
      },
      {
        "text": "Valga County"
      },
      {
        "text": "Viljandi County"
      },
      {
        "text": "Võru County"
      }
    ],
    "code": "+372"
  },
  {
    "text": "Ethiopia",
    "isoCode": "ET",
    "states": [
      {
        "text": "Addis Ababa"
      },
      {
        "text": "Afar Region"
      },
      {
        "text": "Amhara Region"
      },
      {
        "text": "Benishangul-Gumuz Region"
      },
      {
        "text": "Dire Dawa"
      },
      {
        "text": "Gambela Region"
      },
      {
        "text": "Harari Region"
      },
      {
        "text": "Oromia Region"
      },
      {
        "text": "Somali Region"
      },
      {
        "text": "Southern Nations, Nationalities, and Peoples' Region"
      },
      {
        "text": "Tigray Region"
      }
    ],
    "code": "+251"
  },
  {
    "text": "Falkland Islands",
    "isoCode": "FK",
    "states": []
  },
  {
    "text": "Faroe Islands",
    "isoCode": "FO",
    "states": []
  },
  {
    "text": "Fiji Islands",
    "isoCode": "FJ",
    "states": [
      {
        "text": "Ba"
      },
      {
        "text": "Bua"
      },
      {
        "text": "Cakaudrove"
      },
      {
        "text": "Central Division"
      },
      {
        "text": "Eastern Division"
      },
      {
        "text": "Kadavu"
      },
      {
        "text": "Lau"
      },
      {
        "text": "Lomaiviti"
      },
      {
        "text": "Macuata"
      },
      {
        "text": "Nadroga-Navosa"
      },
      {
        "text": "Naitasiri"
      },
      {
        "text": "Namosi"
      },
      {
        "text": "Northern Division"
      },
      {
        "text": "Ra"
      },
      {
        "text": "Rewa"
      },
      {
        "text": "Rotuma"
      },
      {
        "text": "Serua"
      },
      {
        "text": "Tailevu"
      },
      {
        "text": "Western Division"
      }
    ]
  },
  {
    "text": "Finland",
    "isoCode": "FI",
    "states": [
      {
        "text": "Central Finland"
      },
      {
        "text": "Central Ostrobothnia"
      },
      {
        "text": "Eastern Finland Province"
      },
      {
        "text": "Finland Proper"
      },
      {
        "text": "Kainuu"
      },
      {
        "text": "Kymenlaakso"
      },
      {
        "text": "Lapland"
      },
      {
        "text": "North Karelia"
      },
      {
        "text": "Northern Ostrobothnia"
      },
      {
        "text": "Northern Savonia"
      },
      {
        "text": "Ostrobothnia"
      },
      {
        "text": "Oulu Province"
      },
      {
        "text": "Pirkanmaa"
      },
      {
        "text": "Päijänne Tavastia"
      },
      {
        "text": "Satakunta"
      },
      {
        "text": "South Karelia"
      },
      {
        "text": "Southern Ostrobothnia"
      },
      {
        "text": "Southern Savonia"
      },
      {
        "text": "Tavastia Proper"
      },
      {
        "text": "Uusimaa"
      },
      {
        "text": "Åland Islands"
      }
    ],
    "code": "+358"
  },
  {
    "text": "France",
    "isoCode": "FR",
    "states": [
      {
        "text": "Alo"
      },
      {
        "text": "Alsace"
      },
      {
        "text": "Aquitaine"
      },
      {
        "text": "Auvergne"
      },
      {
        "text": "Auvergne-Rhône-Alpes"
      },
      {
        "text": "Bourgogne-Franche-Comté"
      },
      {
        "text": "Brittany"
      },
      {
        "text": "Burgundy"
      },
      {
        "text": "Centre-Val de Loire"
      },
      {
        "text": "Champagne-Ardenne"
      },
      {
        "text": "Corsica"
      },
      {
        "text": "Franche-Comté"
      },
      {
        "text": "French Guiana"
      },
      {
        "text": "French Polynesia"
      },
      {
        "text": "Grand Est"
      },
      {
        "text": "Guadeloupe"
      },
      {
        "text": "Hauts-de-France"
      },
      {
        "text": "Languedoc-Roussillon"
      },
      {
        "text": "Limousin"
      },
      {
        "text": "Lorraine"
      },
      {
        "text": "Lower Normandy"
      },
      {
        "text": "Martinique"
      },
      {
        "text": "Mayotte"
      },
      {
        "text": "Nord-Pas-de-Calais"
      },
      {
        "text": "Normandy"
      },
      {
        "text": "Nouvelle-Aquitaine"
      },
      {
        "text": "Occitania"
      },
      {
        "text": "Paris"
      },
      {
        "text": "Pays de la Loire"
      },
      {
        "text": "Picardy"
      },
      {
        "text": "Poitou-Charentes"
      },
      {
        "text": "Provence-Alpes-Côte d'Azur"
      },
      {
        "text": "Rhône-Alpes"
      },
      {
        "text": "Réunion"
      },
      {
        "text": "Saint Barthélemy"
      },
      {
        "text": "Saint Martin"
      },
      {
        "text": "Saint Pierre and Miquelon"
      },
      {
        "text": "Sigave"
      },
      {
        "text": "Upper Normandy"
      },
      {
        "text": "Uvea"
      },
      {
        "text": "Wallis and Futuna"
      },
      {
        "text": "Île-de-France"
      }
    ],
    "code": "+33"
  },
  {
    "text": "French Guiana",
    "isoCode": "GF",
    "states": []
  },
  {
    "text": "French Polynesia",
    "isoCode": "PF",
    "states": []
  },
  {
    "text": "French Southern Territories",
    "isoCode": "TF",
    "states": []
  },
  {
    "text": "Gabon",
    "isoCode": "GA",
    "states": [
      {
        "text": "Estuaire Province"
      },
      {
        "text": "Haut-Ogooué Province"
      },
      {
        "text": "Moyen-Ogooué Province"
      },
      {
        "text": "Ngounié Province"
      },
      {
        "text": "Nyanga Province"
      },
      {
        "text": "Ogooué-Ivindo Province"
      },
      {
        "text": "Ogooué-Lolo Province"
      },
      {
        "text": "Ogooué-Maritime Province"
      },
      {
        "text": "Woleu-Ntem Province"
      }
    ],
    "code": "+241"
  },
  {
    "text": "Gambia The",
    "isoCode": "GM",
    "states": [
      {
        "text": "Banjul"
      },
      {
        "text": "Central River Division"
      },
      {
        "text": "Lower River Division"
      },
      {
        "text": "North Bank Division"
      },
      {
        "text": "Upper River Division"
      },
      {
        "text": "West Coast Division"
      }
    ]
  },
  {
    "text": "Georgia",
    "isoCode": "GE",
    "states": [
      {
        "text": "Adjara"
      },
      {
        "text": "Autonomous Republic of Abkhazia"
      },
      {
        "text": "Guria"
      },
      {
        "text": "Imereti"
      },
      {
        "text": "Kakheti"
      },
      {
        "text": "Khelvachauri Municipality"
      },
      {
        "text": "Kvemo Kartli"
      },
      {
        "text": "Mtskheta-Mtianeti"
      },
      {
        "text": "Racha-Lechkhumi and Kvemo Svaneti"
      },
      {
        "text": "Samegrelo-Zemo Svaneti"
      },
      {
        "text": "Samtskhe-Javakheti"
      },
      {
        "text": "Senaki Municipality"
      },
      {
        "text": "Shida Kartli"
      },
      {
        "text": "Tbilisi"
      }
    ],
    "code": "+995"
  },
  {
    "text": "Germany",
    "isoCode": "DE",
    "states": [
      {
        "text": "Baden-Württemberg"
      },
      {
        "text": "Bavaria"
      },
      {
        "text": "Berlin"
      },
      {
        "text": "Brandenburg"
      },
      {
        "text": "Bremen"
      },
      {
        "text": "Hamburg"
      },
      {
        "text": "Hesse"
      },
      {
        "text": "Lower Saxony"
      },
      {
        "text": "Mecklenburg-Vorpommern"
      },
      {
        "text": "North Rhine-Westphalia"
      },
      {
        "text": "Rhineland-Palatinate"
      },
      {
        "text": "Saarland"
      },
      {
        "text": "Saxony"
      },
      {
        "text": "Saxony-Anhalt"
      },
      {
        "text": "Schleswig-Holstein"
      },
      {
        "text": "Thuringia"
      }
    ],
    "code": "+49"
  },
  {
    "text": "Ghana",
    "isoCode": "GH",
    "states": [
      {
        "text": "Ashanti Region"
      },
      {
        "text": "Brong-Ahafo Region"
      },
      {
        "text": "Central Region"
      },
      {
        "text": "Eastern Region"
      },
      {
        "text": "Greater Accra Region"
      },
      {
        "text": "Northern Region"
      },
      {
        "text": "Upper East Region"
      },
      {
        "text": "Upper West Region"
      },
      {
        "text": "Volta Region"
      },
      {
        "text": "Western Region"
      }
    ],
    "code": "+233"
  },
  {
    "text": "Gibraltar",
    "isoCode": "GI",
    "states": []
  },
  {
    "text": "Greece",
    "isoCode": "GR",
    "states": [
      {
        "text": "Achaea Regional Unit"
      },
      {
        "text": "Aetolia-Acarnania Regional Unit"
      },
      {
        "text": "Arcadia Prefecture"
      },
      {
        "text": "Argolis Regional Unit"
      },
      {
        "text": "Attica Region"
      },
      {
        "text": "Boeotia Regional Unit"
      },
      {
        "text": "Central Greece Region"
      },
      {
        "text": "Central Macedonia"
      },
      {
        "text": "Chania Regional Unit"
      },
      {
        "text": "Corfu Prefecture"
      },
      {
        "text": "Corinthia Regional Unit"
      },
      {
        "text": "Crete Region"
      },
      {
        "text": "Drama Regional Unit"
      },
      {
        "text": "East Attica Regional Unit"
      },
      {
        "text": "East Macedonia and Thrace"
      },
      {
        "text": "Epirus Region"
      },
      {
        "text": "Euboea"
      },
      {
        "text": "Grevena Prefecture"
      },
      {
        "text": "Imathia Regional Unit"
      },
      {
        "text": "Ioannina Regional Unit"
      },
      {
        "text": "Ionian Islands Region"
      },
      {
        "text": "Karditsa Regional Unit"
      },
      {
        "text": "Kastoria Regional Unit"
      },
      {
        "text": "Kefalonia Prefecture"
      },
      {
        "text": "Kilkis Regional Unit"
      },
      {
        "text": "Kozani Prefecture"
      },
      {
        "text": "Laconia"
      },
      {
        "text": "Larissa Prefecture"
      },
      {
        "text": "Lefkada Regional Unit"
      },
      {
        "text": "Pella Regional Unit"
      },
      {
        "text": "Peloponnese Region"
      },
      {
        "text": "Phthiotis Prefecture"
      },
      {
        "text": "Preveza Prefecture"
      },
      {
        "text": "Serres Prefecture"
      },
      {
        "text": "South Aegean"
      },
      {
        "text": "Thessaloniki Regional Unit"
      },
      {
        "text": "West Greece Region"
      },
      {
        "text": "West Macedonia Region"
      }
    ],
    "code": "+30"
  },
  {
    "text": "Greenland",
    "isoCode": "GL",
    "states": []
  },
  {
    "text": "Grenada",
    "isoCode": "GD",
    "states": [
      {
        "text": "Carriacou and Petite Martinique"
      },
      {
        "text": "Saint Andrew Parish"
      },
      {
        "text": "Saint David Parish"
      },
      {
        "text": "Saint George Parish"
      },
      {
        "text": "Saint John Parish"
      },
      {
        "text": "Saint Mark Parish"
      },
      {
        "text": "Saint Patrick Parish"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Guadeloupe",
    "isoCode": "GP",
    "states": []
  },
  {
    "text": "Guam",
    "isoCode": "GU",
    "states": []
  },
  {
    "text": "Guatemala",
    "isoCode": "GT",
    "states": [
      {
        "text": "Alta Verapaz Department"
      },
      {
        "text": "Baja Verapaz Department"
      },
      {
        "text": "Chimaltenango Department"
      },
      {
        "text": "Chiquimula Department"
      },
      {
        "text": "El Progreso Department"
      },
      {
        "text": "Escuintla Department"
      },
      {
        "text": "Guatemala Department"
      },
      {
        "text": "Huehuetenango Department"
      },
      {
        "text": "Izabal Department"
      },
      {
        "text": "Jalapa Department"
      },
      {
        "text": "Jutiapa Department"
      },
      {
        "text": "Petén Department"
      },
      {
        "text": "Quetzaltenango Department"
      },
      {
        "text": "Quiché Department"
      },
      {
        "text": "Retalhuleu Department"
      },
      {
        "text": "Sacatepéquez Department"
      },
      {
        "text": "San Marcos Department"
      },
      {
        "text": "Santa Rosa Department"
      },
      {
        "text": "Sololá Department"
      },
      {
        "text": "Suchitepéquez Department"
      },
      {
        "text": "Totonicapán Department"
      }
    ],
    "code": "+502"
  },
  {
    "text": "Guernsey and Alderney",
    "isoCode": "GG",
    "states": []
  },
  {
    "text": "Guinea",
    "isoCode": "GN",
    "states": [
      {
        "text": "Beyla Prefecture"
      },
      {
        "text": "Boffa Prefecture"
      },
      {
        "text": "Boké Prefecture"
      },
      {
        "text": "Boké Region"
      },
      {
        "text": "Conakry"
      },
      {
        "text": "Coyah Prefecture"
      },
      {
        "text": "Dabola Prefecture"
      },
      {
        "text": "Dalaba Prefecture"
      },
      {
        "text": "Dinguiraye Prefecture"
      },
      {
        "text": "Dubréka Prefecture"
      },
      {
        "text": "Faranah Prefecture"
      },
      {
        "text": "Forécariah Prefecture"
      },
      {
        "text": "Fria Prefecture"
      },
      {
        "text": "Gaoual Prefecture"
      },
      {
        "text": "Guéckédou Prefecture"
      },
      {
        "text": "Kankan Prefecture"
      },
      {
        "text": "Kankan Region"
      },
      {
        "text": "Kindia Prefecture"
      },
      {
        "text": "Kindia Region"
      },
      {
        "text": "Kissidougou Prefecture"
      },
      {
        "text": "Koubia Prefecture"
      },
      {
        "text": "Koundara Prefecture"
      },
      {
        "text": "Kouroussa Prefecture"
      },
      {
        "text": "Kérouané Prefecture"
      },
      {
        "text": "Labé Prefecture"
      },
      {
        "text": "Labé Region"
      },
      {
        "text": "Lola Prefecture"
      },
      {
        "text": "Lélouma Prefecture"
      },
      {
        "text": "Macenta Prefecture"
      },
      {
        "text": "Mali Prefecture"
      },
      {
        "text": "Mamou Prefecture"
      },
      {
        "text": "Mamou Region"
      },
      {
        "text": "Mandiana Prefecture"
      },
      {
        "text": "Nzérékoré Prefecture"
      },
      {
        "text": "Nzérékoré Region"
      },
      {
        "text": "Pita Prefecture"
      },
      {
        "text": "Siguiri Prefecture"
      },
      {
        "text": "Tougué Prefecture"
      },
      {
        "text": "Télimélé Prefecture"
      },
      {
        "text": "Yomou Prefecture"
      }
    ],
    "code": "+224"
  },
  {
    "text": "Guinea-Bissau",
    "isoCode": "GW",
    "states": [
      {
        "text": "Bafatá"
      },
      {
        "text": "Biombo Region"
      },
      {
        "text": "Bolama Region"
      },
      {
        "text": "Cacheu Region"
      },
      {
        "text": "Gabú Region"
      },
      {
        "text": "Leste Province"
      },
      {
        "text": "Norte Province"
      },
      {
        "text": "Oio Region"
      },
      {
        "text": "Quinara Region"
      },
      {
        "text": "Sul Province"
      },
      {
        "text": "Tombali Region"
      }
    ],
    "code": "+245"
  },
  {
    "text": "Guyana",
    "isoCode": "GY",
    "states": [
      {
        "text": "Barima-Waini"
      },
      {
        "text": "Cuyuni-Mazaruni"
      },
      {
        "text": "Demerara-Mahaica"
      },
      {
        "text": "East Berbice-Corentyne"
      },
      {
        "text": "Essequibo Islands-West Demerara"
      },
      {
        "text": "Mahaica-Berbice"
      },
      {
        "text": "Pomeroon-Supenaam"
      },
      {
        "text": "Potaro-Siparuni"
      },
      {
        "text": "Upper Demerara-Berbice"
      },
      {
        "text": "Upper Takutu-Upper Essequibo"
      }
    ],
    "code": "+592"
  },
  {
    "text": "Haiti",
    "isoCode": "HT",
    "states": [
      {
        "text": "Artibonite"
      },
      {
        "text": "Centre"
      },
      {
        "text": "Grand'Anse"
      },
      {
        "text": "Nippes"
      },
      {
        "text": "Nord"
      },
      {
        "text": "Nord-Est"
      },
      {
        "text": "Nord-Ouest"
      },
      {
        "text": "Ouest"
      },
      {
        "text": "Sud"
      },
      {
        "text": "Sud-Est"
      }
    ],
    "code": "+509"
  },
  {
    "text": "Heard Island and McDonald Islands",
    "isoCode": "HM",
    "states": []
  },
  {
    "text": "Honduras",
    "isoCode": "HN",
    "states": [
      {
        "text": "Atlántida Department"
      },
      {
        "text": "Bay Islands Department"
      },
      {
        "text": "Choluteca Department"
      },
      {
        "text": "Colón Department"
      },
      {
        "text": "Comayagua Department"
      },
      {
        "text": "Copán Department"
      },
      {
        "text": "Cortés Department"
      },
      {
        "text": "El Paraíso Department"
      },
      {
        "text": "Francisco Morazán Department"
      },
      {
        "text": "Gracias a Dios Department"
      },
      {
        "text": "Intibucá Department"
      },
      {
        "text": "La Paz Department"
      },
      {
        "text": "Lempira Department"
      },
      {
        "text": "Ocotepeque Department"
      },
      {
        "text": "Olancho Department"
      },
      {
        "text": "Santa Bárbara Department"
      },
      {
        "text": "Valle Department"
      },
      {
        "text": "Yoro Department"
      }
    ],
    "code": "+504"
  },
  {
    "text": "Hong Kong S.A.R.",
    "isoCode": "HK",
    "states": [
      {
        "text": "Central and Western District"
      },
      {
        "text": "Eastern"
      },
      {
        "text": "Islands District"
      },
      {
        "text": "Kowloon City"
      },
      {
        "text": "Kwai Tsing"
      },
      {
        "text": "Kwun Tong"
      },
      {
        "text": "North"
      },
      {
        "text": "Sai Kung District"
      },
      {
        "text": "Sha Tin"
      },
      {
        "text": "Sham Shui Po"
      },
      {
        "text": "Southern"
      },
      {
        "text": "Tsuen Wan District"
      },
      {
        "text": "Tuen Mun"
      },
      {
        "text": "Wan Chai"
      },
      {
        "text": "Wong Tai Sin"
      },
      {
        "text": "Yau Tsim Mong"
      },
      {
        "text": "Yuen Long District"
      }
    ]
  },
  {
    "text": "Hungary",
    "isoCode": "HU",
    "states": [
      {
        "text": "Baranya County"
      },
      {
        "text": "Borsod-Abaúj-Zemplén County"
      },
      {
        "text": "Budapest"
      },
      {
        "text": "Bács-Kiskun County"
      },
      {
        "text": "Békés County"
      },
      {
        "text": "Békéscsaba"
      },
      {
        "text": "Csongrád County"
      },
      {
        "text": "Debrecen"
      },
      {
        "text": "Dunaújváros"
      },
      {
        "text": "Eger"
      },
      {
        "text": "Fejér County"
      },
      {
        "text": "Győr"
      },
      {
        "text": "Győr-Moson-Sopron County"
      },
      {
        "text": "Hajdú-Bihar County"
      },
      {
        "text": "Heves County"
      },
      {
        "text": "Hódmezővásárhely"
      },
      {
        "text": "Jász-Nagykun-Szolnok County"
      },
      {
        "text": "Kaposvár"
      },
      {
        "text": "Kecskemét"
      },
      {
        "text": "Miskolc"
      },
      {
        "text": "Nagykanizsa"
      },
      {
        "text": "Nyíregyháza"
      },
      {
        "text": "Nógrád County"
      },
      {
        "text": "Pest County"
      },
      {
        "text": "Pécs"
      },
      {
        "text": "Salgótarján"
      },
      {
        "text": "Somogy County"
      },
      {
        "text": "Sopron"
      },
      {
        "text": "Szabolcs-Szatmár-Bereg County"
      },
      {
        "text": "Szeged"
      },
      {
        "text": "Szekszárd"
      },
      {
        "text": "Szolnok"
      },
      {
        "text": "Szombathely"
      },
      {
        "text": "Székesfehérvár"
      },
      {
        "text": "Tatabánya"
      },
      {
        "text": "Tolna County"
      },
      {
        "text": "Vas County"
      },
      {
        "text": "Veszprém"
      },
      {
        "text": "Veszprém County"
      },
      {
        "text": "Zala County"
      },
      {
        "text": "Zalaegerszeg"
      },
      {
        "text": "Érd"
      }
    ],
    "code": "+36"
  },
  {
    "text": "Iceland",
    "isoCode": "IS",
    "states": [
      {
        "text": "Capital Region"
      },
      {
        "text": "Eastern Region"
      },
      {
        "text": "Northeastern Region"
      },
      {
        "text": "Northwestern Region"
      },
      {
        "text": "Southern Peninsula Region"
      },
      {
        "text": "Southern Region"
      },
      {
        "text": "Western Region"
      },
      {
        "text": "Westfjords"
      }
    ],
    "code": "+354"
  },
  {
    "text": "India",
    "isoCode": "IN",
    "states": [
      {
        "text": "Andaman and Nicobar Islands"
      },
      {
        "text": "Andhra Pradesh"
      },
      {
        "text": "Arunachal Pradesh"
      },
      {
        "text": "Assam"
      },
      {
        "text": "Bihar"
      },
      {
        "text": "Chandigarh"
      },
      {
        "text": "Chhattisgarh"
      },
      {
        "text": "Dadra and Nagar Haveli and Daman and Diu"
      },
      {
        "text": "Delhi"
      },
      {
        "text": "Goa"
      },
      {
        "text": "Gujarat"
      },
      {
        "text": "Haryana"
      },
      {
        "text": "Himachal Pradesh"
      },
      {
        "text": "Jammu and Kashmir"
      },
      {
        "text": "Jharkhand"
      },
      {
        "text": "Karnataka"
      },
      {
        "text": "Kerala"
      },
      {
        "text": "Ladakh"
      },
      {
        "text": "Lakshadweep"
      },
      {
        "text": "Madhya Pradesh"
      },
      {
        "text": "Maharashtra"
      },
      {
        "text": "Manipur"
      },
      {
        "text": "Meghalaya"
      },
      {
        "text": "Mizoram"
      },
      {
        "text": "Nagaland"
      },
      {
        "text": "Odisha"
      },
      {
        "text": "Puducherry"
      },
      {
        "text": "Punjab"
      },
      {
        "text": "Rajasthan"
      },
      {
        "text": "Sikkim"
      },
      {
        "text": "Tamil Nadu"
      },
      {
        "text": "Telangana"
      },
      {
        "text": "Tripura"
      },
      {
        "text": "Uttar Pradesh"
      },
      {
        "text": "Uttarakhand"
      },
      {
        "text": "West Bengal"
      }
    ],
    "code": "+91"
  },
  {
    "text": "Indonesia",
    "isoCode": "ID",
    "states": [
      {
        "text": "Aceh"
      },
      {
        "text": "Bali"
      },
      {
        "text": "Bangka Belitung Islands"
      },
      {
        "text": "Banten"
      },
      {
        "text": "Bengkulu"
      },
      {
        "text": "Central Java"
      },
      {
        "text": "Central Kalimantan"
      },
      {
        "text": "Central Sulawesi"
      },
      {
        "text": "East Java"
      },
      {
        "text": "East Kalimantan"
      },
      {
        "text": "East Nusa Tenggara"
      },
      {
        "text": "Gorontalo"
      },
      {
        "text": "Jakarta"
      },
      {
        "text": "Jambi"
      },
      {
        "text": "Kalimantan"
      },
      {
        "text": "Lampung"
      },
      {
        "text": "Lesser Sunda Islands"
      },
      {
        "text": "Maluku"
      },
      {
        "text": "Maluku Islands"
      },
      {
        "text": "North Kalimantan"
      },
      {
        "text": "North Maluku"
      },
      {
        "text": "North Sulawesi"
      },
      {
        "text": "North Sumatra"
      },
      {
        "text": "Papua"
      },
      {
        "text": "Riau"
      },
      {
        "text": "Riau Islands"
      },
      {
        "text": "South Kalimantan"
      },
      {
        "text": "South Sulawesi"
      },
      {
        "text": "South Sumatra"
      },
      {
        "text": "Southeast Sulawesi"
      },
      {
        "text": "Special Region of Yogyakarta"
      },
      {
        "text": "Sulawesi"
      },
      {
        "text": "Sumatra"
      },
      {
        "text": "West Java"
      },
      {
        "text": "West Nusa Tenggara"
      },
      {
        "text": "West Papua"
      },
      {
        "text": "West Sulawesi"
      },
      {
        "text": "West Sumatra"
      }
    ],
    "code": "+62"
  },
  {
    "text": "Iran",
    "isoCode": "IR",
    "states": [
      {
        "text": "Alborz Province"
      },
      {
        "text": "Ardabil Province"
      },
      {
        "text": "Bushehr Province"
      },
      {
        "text": "Chaharmahal and Bakhtiari Province"
      },
      {
        "text": "East Azerbaijan Province"
      },
      {
        "text": "Fars Province"
      },
      {
        "text": "Gilan Province"
      },
      {
        "text": "Golestan Province"
      },
      {
        "text": "Hamadan Province"
      },
      {
        "text": "Hormozgan Province"
      },
      {
        "text": "Ilam Province"
      },
      {
        "text": "Isfahan Province"
      },
      {
        "text": "Kerman Province"
      },
      {
        "text": "Kermanshah Province"
      },
      {
        "text": "Khuzestan Province"
      },
      {
        "text": "Kohgiluyeh and Boyer-Ahmad Province"
      },
      {
        "text": "Kurdistan Province"
      },
      {
        "text": "Lorestan Province"
      },
      {
        "text": "Markazi Province"
      },
      {
        "text": "Mazandaran Province"
      },
      {
        "text": "North Khorasan Province"
      },
      {
        "text": "Qazvin Province"
      },
      {
        "text": "Qom Province"
      },
      {
        "text": "Razavi Khorasan Province"
      },
      {
        "text": "Semnan Province"
      },
      {
        "text": "Sistan and Baluchestan"
      },
      {
        "text": "South Khorasan Province"
      },
      {
        "text": "Tehran Province"
      },
      {
        "text": "West Azarbaijan Province"
      },
      {
        "text": "Yazd Province"
      },
      {
        "text": "Zanjan Province"
      }
    ],
    "code": "+98"
  },
  {
    "text": "Iraq",
    "isoCode": "IQ",
    "states": [
      {
        "text": "Al Anbar Governorate"
      },
      {
        "text": "Al Muthanna Governorate"
      },
      {
        "text": "Al-Qādisiyyah Governorate"
      },
      {
        "text": "Babylon Governorate"
      },
      {
        "text": "Baghdad Governorate"
      },
      {
        "text": "Basra Governorate"
      },
      {
        "text": "Dhi Qar Governorate"
      },
      {
        "text": "Diyala Governorate"
      },
      {
        "text": "Dohuk Governorate"
      },
      {
        "text": "Erbil Governorate"
      },
      {
        "text": "Karbala Governorate"
      },
      {
        "text": "Kirkuk Governorate"
      },
      {
        "text": "Maysan Governorate"
      },
      {
        "text": "Najaf Governorate"
      },
      {
        "text": "Nineveh Governorate"
      },
      {
        "text": "Saladin Governorate"
      },
      {
        "text": "Sulaymaniyah Governorate"
      },
      {
        "text": "Wasit Governorate"
      }
    ],
    "code": "+964"
  },
  {
    "text": "Ireland",
    "isoCode": "IE",
    "states": [
      {
        "text": "Connacht"
      },
      {
        "text": "County Carlow"
      },
      {
        "text": "County Cavan"
      },
      {
        "text": "County Clare"
      },
      {
        "text": "County Cork"
      },
      {
        "text": "County Donegal"
      },
      {
        "text": "County Dublin"
      },
      {
        "text": "County Galway"
      },
      {
        "text": "County Kerry"
      },
      {
        "text": "County Kildare"
      },
      {
        "text": "County Kilkenny"
      },
      {
        "text": "County Laois"
      },
      {
        "text": "County Limerick"
      },
      {
        "text": "County Longford"
      },
      {
        "text": "County Louth"
      },
      {
        "text": "County Mayo"
      },
      {
        "text": "County Meath"
      },
      {
        "text": "County Monaghan"
      },
      {
        "text": "County Offaly"
      },
      {
        "text": "County Roscommon"
      },
      {
        "text": "County Sligo"
      },
      {
        "text": "County Tipperary"
      },
      {
        "text": "County Waterford"
      },
      {
        "text": "County Westmeath"
      },
      {
        "text": "County Wexford"
      },
      {
        "text": "County Wicklow"
      },
      {
        "text": "Leinster"
      },
      {
        "text": "Munster"
      },
      {
        "text": "Ulster"
      }
    ],
    "code": "+353"
  },
  {
    "text": "Israel",
    "isoCode": "IL",
    "states": [
      {
        "text": "Central District"
      },
      {
        "text": "Haifa District"
      },
      {
        "text": "Jerusalem District"
      },
      {
        "text": "Northern District"
      },
      {
        "text": "Southern District"
      },
      {
        "text": "Tel Aviv District"
      }
    ],
    "code": "+972"
  },
  {
    "text": "Italy",
    "isoCode": "IT",
    "states": [
      {
        "text": "Abruzzo"
      },
      {
        "text": "Aosta Valley"
      },
      {
        "text": "Apulia"
      },
      {
        "text": "Basilicata"
      },
      {
        "text": "Benevento Province"
      },
      {
        "text": "Calabria"
      },
      {
        "text": "Campania"
      },
      {
        "text": "Emilia-Romagna"
      },
      {
        "text": "Friuli–Venezia Giulia"
      },
      {
        "text": "Lazio"
      },
      {
        "text": "Libero consorzio comunale di Agrigento"
      },
      {
        "text": "Libero consorzio comunale di Caltanissetta"
      },
      {
        "text": "Libero consorzio comunale di Enna"
      },
      {
        "text": "Libero consorzio comunale di Ragusa"
      },
      {
        "text": "Libero consorzio comunale di Siracusa"
      },
      {
        "text": "Libero consorzio comunale di Trapani"
      },
      {
        "text": "Liguria"
      },
      {
        "text": "Lombardy"
      },
      {
        "text": "Marche"
      },
      {
        "text": "Metropolitan City of Bari"
      },
      {
        "text": "Metropolitan City of Bologna"
      },
      {
        "text": "Metropolitan City of Cagliari"
      },
      {
        "text": "Metropolitan City of Catania"
      },
      {
        "text": "Metropolitan City of Florence"
      },
      {
        "text": "Metropolitan City of Genoa"
      },
      {
        "text": "Metropolitan City of Messina"
      },
      {
        "text": "Metropolitan City of Milan"
      },
      {
        "text": "Metropolitan City of Naples"
      },
      {
        "text": "Metropolitan City of Palermo"
      },
      {
        "text": "Metropolitan City of Reggio Calabria"
      },
      {
        "text": "Metropolitan City of Rome"
      },
      {
        "text": "Metropolitan City of Turin"
      },
      {
        "text": "Metropolitan City of Venice"
      },
      {
        "text": "Molise"
      },
      {
        "text": "Pesaro and Urbino Province"
      },
      {
        "text": "Piedmont"
      },
      {
        "text": "Province of Alessandria"
      },
      {
        "text": "Province of Ancona"
      },
      {
        "text": "Province of Ascoli Piceno"
      },
      {
        "text": "Province of Asti"
      },
      {
        "text": "Province of Avellino"
      },
      {
        "text": "Province of Barletta-Andria-Trani"
      },
      {
        "text": "Province of Belluno"
      },
      {
        "text": "Province of Bergamo"
      },
      {
        "text": "Province of Biella"
      },
      {
        "text": "Province of Brescia"
      },
      {
        "text": "Province of Brindisi"
      },
      {
        "text": "Province of Campobasso"
      },
      {
        "text": "Province of Carbonia-Iglesias"
      },
      {
        "text": "Province of Caserta"
      },
      {
        "text": "Province of Catanzaro"
      },
      {
        "text": "Province of Chieti"
      },
      {
        "text": "Province of Como"
      },
      {
        "text": "Province of Cosenza"
      },
      {
        "text": "Province of Cremona"
      },
      {
        "text": "Province of Crotone"
      },
      {
        "text": "Province of Cuneo"
      },
      {
        "text": "Province of Fermo"
      },
      {
        "text": "Province of Ferrara"
      },
      {
        "text": "Province of Foggia"
      },
      {
        "text": "Province of Forlì-Cesena"
      },
      {
        "text": "Province of Frosinone"
      },
      {
        "text": "Province of Gorizia"
      },
      {
        "text": "Province of Grosseto"
      },
      {
        "text": "Province of Imperia"
      },
      {
        "text": "Province of Isernia"
      },
      {
        "text": "Province of L'Aquila"
      },
      {
        "text": "Province of La Spezia"
      },
      {
        "text": "Province of Latina"
      },
      {
        "text": "Province of Lecce"
      },
      {
        "text": "Province of Lecco"
      },
      {
        "text": "Province of Livorno"
      },
      {
        "text": "Province of Lodi"
      },
      {
        "text": "Province of Lucca"
      },
      {
        "text": "Province of Macerata"
      },
      {
        "text": "Province of Mantua"
      },
      {
        "text": "Province of Massa and Carrara"
      },
      {
        "text": "Province of Matera"
      },
      {
        "text": "Province of Medio Campidano"
      },
      {
        "text": "Province of Modena"
      },
      {
        "text": "Province of Monza and Brianza"
      },
      {
        "text": "Province of Novara"
      },
      {
        "text": "Province of Nuoro"
      },
      {
        "text": "Province of Ogliastra"
      },
      {
        "text": "Province of Olbia-Tempio"
      },
      {
        "text": "Province of Oristano"
      },
      {
        "text": "Province of Padua"
      },
      {
        "text": "Province of Parma"
      },
      {
        "text": "Province of Pavia"
      },
      {
        "text": "Province of Perugia"
      },
      {
        "text": "Province of Pescara"
      },
      {
        "text": "Province of Piacenza"
      },
      {
        "text": "Province of Pisa"
      },
      {
        "text": "Province of Pistoia"
      },
      {
        "text": "Province of Pordenone"
      },
      {
        "text": "Province of Potenza"
      },
      {
        "text": "Province of Prato"
      },
      {
        "text": "Province of Ravenna"
      },
      {
        "text": "Province of Reggio Emilia"
      },
      {
        "text": "Province of Rieti"
      },
      {
        "text": "Province of Rimini"
      },
      {
        "text": "Province of Rovigo"
      },
      {
        "text": "Province of Salerno"
      },
      {
        "text": "Province of Sassari"
      },
      {
        "text": "Province of Savona"
      },
      {
        "text": "Province of Siena"
      },
      {
        "text": "Province of Sondrio"
      },
      {
        "text": "Province of Taranto"
      },
      {
        "text": "Province of Teramo"
      },
      {
        "text": "Province of Terni"
      },
      {
        "text": "Province of Treviso"
      },
      {
        "text": "Province of Trieste"
      },
      {
        "text": "Province of Udine"
      },
      {
        "text": "Province of Varese"
      },
      {
        "text": "Province of Verbano-Cusio-Ossola"
      },
      {
        "text": "Province of Vercelli"
      },
      {
        "text": "Province of Verona"
      },
      {
        "text": "Province of Vibo Valentia"
      },
      {
        "text": "Province of Vicenza"
      },
      {
        "text": "Province of Viterbo"
      },
      {
        "text": "Sardinia"
      },
      {
        "text": "Sicily"
      },
      {
        "text": "South Tyrol"
      },
      {
        "text": "Trentino"
      },
      {
        "text": "Trentino-South Tyrol"
      },
      {
        "text": "Tuscany"
      },
      {
        "text": "Umbria"
      },
      {
        "text": "Veneto"
      }
    ],
    "code": "+39"
  },
  {
    "text": "Jamaica",
    "isoCode": "JM",
    "states": [
      {
        "text": "Clarendon Parish"
      },
      {
        "text": "Hanover Parish"
      },
      {
        "text": "Kingston Parish"
      },
      {
        "text": "Manchester Parish"
      },
      {
        "text": "Portland Parish"
      },
      {
        "text": "Saint Andrew"
      },
      {
        "text": "Saint Ann Parish"
      },
      {
        "text": "Saint Catherine Parish"
      },
      {
        "text": "Saint Elizabeth Parish"
      },
      {
        "text": "Saint James Parish"
      },
      {
        "text": "Saint Mary Parish"
      },
      {
        "text": "Saint Thomas Parish"
      },
      {
        "text": "Trelawny Parish"
      },
      {
        "text": "Westmoreland Parish"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Japan",
    "isoCode": "JP",
    "states": [
      {
        "text": "Aichi Prefecture"
      },
      {
        "text": "Akita Prefecture"
      },
      {
        "text": "Aomori Prefecture"
      },
      {
        "text": "Chiba Prefecture"
      },
      {
        "text": "Ehime Prefecture"
      },
      {
        "text": "Fukui Prefecture"
      },
      {
        "text": "Fukuoka Prefecture"
      },
      {
        "text": "Fukushima Prefecture"
      },
      {
        "text": "Gifu Prefecture"
      },
      {
        "text": "Gunma Prefecture"
      },
      {
        "text": "Hiroshima Prefecture"
      },
      {
        "text": "Hokkaidō Prefecture"
      },
      {
        "text": "Hyōgo Prefecture"
      },
      {
        "text": "Ibaraki Prefecture"
      },
      {
        "text": "Ishikawa Prefecture"
      },
      {
        "text": "Iwate Prefecture"
      },
      {
        "text": "Kagawa Prefecture"
      },
      {
        "text": "Kagoshima Prefecture"
      },
      {
        "text": "Kanagawa Prefecture"
      },
      {
        "text": "Kumamoto Prefecture"
      },
      {
        "text": "Kyōto Prefecture"
      },
      {
        "text": "Mie Prefecture"
      },
      {
        "text": "Miyagi Prefecture"
      },
      {
        "text": "Miyazaki Prefecture"
      },
      {
        "text": "Nagano Prefecture"
      },
      {
        "text": "Nagasaki Prefecture"
      },
      {
        "text": "Nara Prefecture"
      },
      {
        "text": "Niigata Prefecture"
      },
      {
        "text": "Okayama Prefecture"
      },
      {
        "text": "Okinawa Prefecture"
      },
      {
        "text": "Saga Prefecture"
      },
      {
        "text": "Saitama Prefecture"
      },
      {
        "text": "Shiga Prefecture"
      },
      {
        "text": "Shimane Prefecture"
      },
      {
        "text": "Shizuoka Prefecture"
      },
      {
        "text": "Tochigi Prefecture"
      },
      {
        "text": "Tokushima Prefecture"
      },
      {
        "text": "Tokyo"
      },
      {
        "text": "Tottori Prefecture"
      },
      {
        "text": "Toyama Prefecture"
      },
      {
        "text": "Wakayama Prefecture"
      },
      {
        "text": "Yamagata Prefecture"
      },
      {
        "text": "Yamaguchi Prefecture"
      },
      {
        "text": "Yamanashi Prefecture"
      },
      {
        "text": "Ōita Prefecture"
      },
      {
        "text": "Ōsaka Prefecture"
      }
    ],
    "code": "+81"
  },
  {
    "text": "Jersey",
    "isoCode": "JE",
    "states": []
  },
  {
    "text": "Jordan",
    "isoCode": "JO",
    "states": [
      {
        "text": "Ajloun Governorate"
      },
      {
        "text": "Amman Governorate"
      },
      {
        "text": "Aqaba Governorate"
      },
      {
        "text": "Balqa Governorate"
      },
      {
        "text": "Irbid Governorate"
      },
      {
        "text": "Jerash Governorate"
      },
      {
        "text": "Karak Governorate"
      },
      {
        "text": "Ma'an Governorate"
      },
      {
        "text": "Madaba Governorate"
      },
      {
        "text": "Mafraq Governorate"
      },
      {
        "text": "Tafilah Governorate"
      },
      {
        "text": "Zarqa Governorate"
      }
    ],
    "code": "+962"
  },
  {
    "text": "Kazakhstan",
    "isoCode": "KZ",
    "states": [
      {
        "text": "Akmola Region"
      },
      {
        "text": "Aktobe Region"
      },
      {
        "text": "Almaty"
      },
      {
        "text": "Almaty Region"
      },
      {
        "text": "Atyrau Region"
      },
      {
        "text": "Baikonur"
      },
      {
        "text": "East Kazakhstan Region"
      },
      {
        "text": "Jambyl Region"
      },
      {
        "text": "Karaganda Region"
      },
      {
        "text": "Kostanay Region"
      },
      {
        "text": "Kyzylorda Region"
      },
      {
        "text": "Mangystau Region"
      },
      {
        "text": "North Kazakhstan Region"
      },
      {
        "text": "Nur-Sultan"
      },
      {
        "text": "Pavlodar Region"
      },
      {
        "text": "Turkestan Region"
      },
      {
        "text": "West Kazakhstan Province"
      }
    ],
    "code": "+7"
  },
  {
    "text": "Kenya",
    "isoCode": "KE",
    "states": [
      {
        "text": "Baringo County"
      },
      {
        "text": "Bomet County"
      },
      {
        "text": "Bungoma County"
      },
      {
        "text": "Busia County"
      },
      {
        "text": "Central Province"
      },
      {
        "text": "Coast Province"
      },
      {
        "text": "Eastern Province"
      },
      {
        "text": "Elgeyo-Marakwet County"
      },
      {
        "text": "Embu County"
      },
      {
        "text": "Garissa County"
      },
      {
        "text": "Homa Bay County"
      },
      {
        "text": "Isiolo County"
      },
      {
        "text": "Kajiado County"
      },
      {
        "text": "Kakamega County"
      },
      {
        "text": "Kericho County"
      },
      {
        "text": "Kiambu County"
      },
      {
        "text": "Kilifi County"
      },
      {
        "text": "Kirinyaga County"
      },
      {
        "text": "Kisii County"
      },
      {
        "text": "Kisumu County"
      },
      {
        "text": "Kitui County"
      },
      {
        "text": "Kwale County"
      },
      {
        "text": "Laikipia County"
      },
      {
        "text": "Lamu County"
      },
      {
        "text": "Machakos County"
      },
      {
        "text": "Makueni County"
      },
      {
        "text": "Mandera County"
      },
      {
        "text": "Marsabit County"
      },
      {
        "text": "Meru County"
      },
      {
        "text": "Migori County"
      },
      {
        "text": "Mombasa County"
      },
      {
        "text": "Muranga County"
      },
      {
        "text": "Nairobi"
      },
      {
        "text": "Nakuru District"
      },
      {
        "text": "Nandi District"
      },
      {
        "text": "Narok County"
      },
      {
        "text": "North Eastern Province"
      },
      {
        "text": "Nyamira District"
      },
      {
        "text": "Nyandarua County"
      },
      {
        "text": "Nyanza Province"
      },
      {
        "text": "Nyeri County"
      },
      {
        "text": "Rift Valley Province"
      },
      {
        "text": "Samburu County"
      },
      {
        "text": "Siaya County"
      },
      {
        "text": "Taita–Taveta County"
      },
      {
        "text": "Tana River County"
      },
      {
        "text": "Tharaka Nithi County"
      },
      {
        "text": "Trans-Nzoia District"
      },
      {
        "text": "Turkana County"
      },
      {
        "text": "Uasin Gishu District"
      },
      {
        "text": "Vihiga District"
      },
      {
        "text": "Wajir County"
      },
      {
        "text": "West Pokot County"
      },
      {
        "text": "Western Province"
      }
    ],
    "code": "+254"
  },
  {
    "text": "Kiribati",
    "isoCode": "KI",
    "states": [
      {
        "text": "Gilbert Islands"
      },
      {
        "text": "Line Islands"
      },
      {
        "text": "Phoenix Islands"
      }
    ],
    "code": "+686"
  },
  {
    "text": "Korea North",
    "isoCode": "KP",
    "states": [
      {
        "text": "Chagang Province"
      },
      {
        "text": "Kangwon Province"
      },
      {
        "text": "North Hamgyong Province"
      },
      {
        "text": "North Hwanghae Province"
      },
      {
        "text": "North Pyongan Province"
      },
      {
        "text": "Pyongyang"
      },
      {
        "text": "Rason"
      },
      {
        "text": "Ryanggang Province"
      },
      {
        "text": "South Hamgyong Province"
      },
      {
        "text": "South Hwanghae Province"
      },
      {
        "text": "South Pyongan Province"
      }
    ]
  },
  {
    "text": "Korea South",
    "isoCode": "KR",
    "states": [
      {
        "text": "Busan"
      },
      {
        "text": "Daegu"
      },
      {
        "text": "Daejeon"
      },
      {
        "text": "Gangwon Province"
      },
      {
        "text": "Gwangju"
      },
      {
        "text": "Gyeonggi Province"
      },
      {
        "text": "Incheon"
      },
      {
        "text": "Jeju"
      },
      {
        "text": "North Chungcheong Province"
      },
      {
        "text": "North Gyeongsang Province"
      },
      {
        "text": "North Jeolla Province"
      },
      {
        "text": "Sejong City"
      },
      {
        "text": "Seoul"
      },
      {
        "text": "South Chungcheong Province"
      },
      {
        "text": "South Gyeongsang Province"
      },
      {
        "text": "South Jeolla Province"
      },
      {
        "text": "Ulsan"
      }
    ]
  },
  {
    "text": "Kuwait",
    "isoCode": "KW",
    "states": [
      {
        "text": "Al Ahmadi Governorate"
      },
      {
        "text": "Al Farwaniyah Governorate"
      },
      {
        "text": "Al Jahra Governorate"
      },
      {
        "text": "Capital Governorate"
      },
      {
        "text": "Hawalli Governorate"
      },
      {
        "text": "Mubarak Al-Kabeer Governorate"
      }
    ],
    "code": "+965"
  },
  {
    "text": "Kyrgyzstan",
    "isoCode": "KG",
    "states": [
      {
        "text": "Batken Region"
      },
      {
        "text": "Bishkek"
      },
      {
        "text": "Chuy Region"
      },
      {
        "text": "Issyk-Kul Region"
      },
      {
        "text": "Jalal-Abad Region"
      },
      {
        "text": "Naryn Region"
      },
      {
        "text": "Osh"
      },
      {
        "text": "Osh Region"
      },
      {
        "text": "Talas Region"
      }
    ],
    "code": "+996"
  },
  {
    "text": "Laos",
    "isoCode": "LA",
    "states": [
      {
        "text": "Attapeu Province"
      },
      {
        "text": "Bokeo Province"
      },
      {
        "text": "Bolikhamsai Province"
      },
      {
        "text": "Champasak Province"
      },
      {
        "text": "Houaphanh Province"
      },
      {
        "text": "Khammouane Province"
      },
      {
        "text": "Luang Namtha Province"
      },
      {
        "text": "Luang Prabang Province"
      },
      {
        "text": "Oudomxay Province"
      },
      {
        "text": "Phongsaly Province"
      },
      {
        "text": "Sainyabuli Province"
      },
      {
        "text": "Salavan Province"
      },
      {
        "text": "Savannakhet Province"
      },
      {
        "text": "Sekong Province"
      },
      {
        "text": "Vientiane Prefecture"
      },
      {
        "text": "Vientiane Province"
      },
      {
        "text": "Xaisomboun"
      },
      {
        "text": "Xaisomboun Province"
      },
      {
        "text": "Xiangkhouang Province"
      }
    ],
    "code": "+856"
  },
  {
    "text": "Latvia",
    "isoCode": "LV",
    "states": [
      {
        "text": "Aglona Municipality"
      },
      {
        "text": "Aizkraukle Municipality"
      },
      {
        "text": "Aizpute Municipality"
      },
      {
        "text": "Aknīste Municipality"
      },
      {
        "text": "Aloja Municipality"
      },
      {
        "text": "Alsunga Municipality"
      },
      {
        "text": "Alūksne Municipality"
      },
      {
        "text": "Amata Municipality"
      },
      {
        "text": "Ape Municipality"
      },
      {
        "text": "Auce Municipality"
      },
      {
        "text": "Babīte Municipality"
      },
      {
        "text": "Baldone Municipality"
      },
      {
        "text": "Baltinava Municipality"
      },
      {
        "text": "Balvi Municipality"
      },
      {
        "text": "Bauska Municipality"
      },
      {
        "text": "Beverīna Municipality"
      },
      {
        "text": "Brocēni Municipality"
      },
      {
        "text": "Burtnieki Municipality"
      },
      {
        "text": "Carnikava Municipality"
      },
      {
        "text": "Cesvaine Municipality"
      },
      {
        "text": "Cibla Municipality"
      },
      {
        "text": "Cēsis Municipality"
      },
      {
        "text": "Dagda Municipality"
      },
      {
        "text": "Daugavpils"
      },
      {
        "text": "Daugavpils Municipality"
      },
      {
        "text": "Dobele Municipality"
      },
      {
        "text": "Dundaga Municipality"
      },
      {
        "text": "Durbe Municipality"
      },
      {
        "text": "Engure Municipality"
      },
      {
        "text": "Garkalne Municipality"
      },
      {
        "text": "Grobiņa Municipality"
      },
      {
        "text": "Gulbene Municipality"
      },
      {
        "text": "Iecava Municipality"
      },
      {
        "text": "Ikšķile Municipality"
      },
      {
        "text": "Ilūkste Municipality"
      },
      {
        "text": "Inčukalns Municipality"
      },
      {
        "text": "Jaunjelgava Municipality"
      },
      {
        "text": "Jaunpiebalga Municipality"
      },
      {
        "text": "Jaunpils Municipality"
      },
      {
        "text": "Jelgava"
      },
      {
        "text": "Jelgava Municipality"
      },
      {
        "text": "Jēkabpils"
      },
      {
        "text": "Jēkabpils Municipality"
      },
      {
        "text": "Jūrmala"
      },
      {
        "text": "Kandava Municipality"
      },
      {
        "text": "Kocēni Municipality"
      },
      {
        "text": "Koknese Municipality"
      },
      {
        "text": "Krimulda Municipality"
      },
      {
        "text": "Krustpils Municipality"
      },
      {
        "text": "Krāslava Municipality"
      },
      {
        "text": "Kuldīga Municipality"
      },
      {
        "text": "Kārsava Municipality"
      },
      {
        "text": "Lielvārde Municipality"
      },
      {
        "text": "Liepāja"
      },
      {
        "text": "Limbaži Municipality"
      },
      {
        "text": "Lubāna Municipality"
      },
      {
        "text": "Ludza Municipality"
      },
      {
        "text": "Līgatne Municipality"
      },
      {
        "text": "Līvāni Municipality"
      },
      {
        "text": "Madona Municipality"
      },
      {
        "text": "Mazsalaca Municipality"
      },
      {
        "text": "Mālpils Municipality"
      },
      {
        "text": "Mārupe Municipality"
      },
      {
        "text": "Mērsrags Municipality"
      },
      {
        "text": "Naukšēni Municipality"
      },
      {
        "text": "Nereta Municipality"
      },
      {
        "text": "Nīca Municipality"
      },
      {
        "text": "Ogre Municipality"
      },
      {
        "text": "Olaine Municipality"
      },
      {
        "text": "Ozolnieki Municipality"
      },
      {
        "text": "Preiļi Municipality"
      },
      {
        "text": "Priekule Municipality"
      },
      {
        "text": "Priekuļi Municipality"
      },
      {
        "text": "Pārgauja Municipality"
      },
      {
        "text": "Pāvilosta Municipality"
      },
      {
        "text": "Pļaviņas Municipality"
      },
      {
        "text": "Rauna Municipality"
      },
      {
        "text": "Riebiņi Municipality"
      },
      {
        "text": "Riga"
      },
      {
        "text": "Roja Municipality"
      },
      {
        "text": "Ropaži Municipality"
      },
      {
        "text": "Rucava Municipality"
      },
      {
        "text": "Rugāji Municipality"
      },
      {
        "text": "Rundāle Municipality"
      },
      {
        "text": "Rēzekne"
      },
      {
        "text": "Rēzekne Municipality"
      },
      {
        "text": "Rūjiena Municipality"
      },
      {
        "text": "Sala Municipality"
      },
      {
        "text": "Salacgrīva Municipality"
      },
      {
        "text": "Salaspils Municipality"
      },
      {
        "text": "Saldus Municipality"
      },
      {
        "text": "Saulkrasti Municipality"
      },
      {
        "text": "Sigulda Municipality"
      },
      {
        "text": "Skrunda Municipality"
      },
      {
        "text": "Skrīveri Municipality"
      },
      {
        "text": "Smiltene Municipality"
      },
      {
        "text": "Stopiņi Municipality"
      },
      {
        "text": "Strenči Municipality"
      },
      {
        "text": "Sēja Municipality"
      },
      {
        "text": "Talsi Municipality"
      },
      {
        "text": "Tukums Municipality"
      },
      {
        "text": "Tērvete Municipality"
      },
      {
        "text": "Vaiņode Municipality"
      },
      {
        "text": "Valka Municipality"
      },
      {
        "text": "Valmiera"
      },
      {
        "text": "Varakļāni Municipality"
      },
      {
        "text": "Vecpiebalga Municipality"
      },
      {
        "text": "Vecumnieki Municipality"
      },
      {
        "text": "Ventspils"
      },
      {
        "text": "Ventspils Municipality"
      },
      {
        "text": "Viesīte Municipality"
      },
      {
        "text": "Viļaka Municipality"
      },
      {
        "text": "Viļāni Municipality"
      },
      {
        "text": "Vārkava Municipality"
      },
      {
        "text": "Zilupe Municipality"
      },
      {
        "text": "Ērgļi Municipality"
      },
      {
        "text": "Ķegums Municipality"
      },
      {
        "text": "Ķekava Municipality"
      }
    ],
    "code": "+371"
  },
  {
    "text": "Lebanon",
    "isoCode": "LB",
    "states": [
      {
        "text": "Akkar Governorate"
      },
      {
        "text": "Baalbek-Hermel Governorate"
      },
      {
        "text": "Beirut Governorate"
      },
      {
        "text": "Beqaa Governorate"
      },
      {
        "text": "Mount Lebanon Governorate"
      },
      {
        "text": "Nabatieh Governorate"
      },
      {
        "text": "North Governorate"
      },
      {
        "text": "South Governorate"
      }
    ],
    "code": "+961"
  },
  {
    "text": "Lesotho",
    "isoCode": "LS",
    "states": [
      {
        "text": "Berea District"
      },
      {
        "text": "Butha-Buthe District"
      },
      {
        "text": "Leribe District"
      },
      {
        "text": "Mafeteng District"
      },
      {
        "text": "Maseru District"
      },
      {
        "text": "Mohale's Hoek District"
      },
      {
        "text": "Mokhotlong District"
      },
      {
        "text": "Qacha's Nek District"
      },
      {
        "text": "Quthing District"
      },
      {
        "text": "Thaba-Tseka District"
      }
    ],
    "code": "+266"
  },
  {
    "text": "Liberia",
    "isoCode": "LR",
    "states": [
      {
        "text": "Bomi County"
      },
      {
        "text": "Bong County"
      },
      {
        "text": "Gbarpolu County"
      },
      {
        "text": "Grand Bassa County"
      },
      {
        "text": "Grand Cape Mount County"
      },
      {
        "text": "Grand Gedeh County"
      },
      {
        "text": "Grand Kru County"
      },
      {
        "text": "Lofa County"
      },
      {
        "text": "Margibi County"
      },
      {
        "text": "Maryland County"
      },
      {
        "text": "Montserrado County"
      },
      {
        "text": "Nimba"
      },
      {
        "text": "River Cess County"
      },
      {
        "text": "River Gee County"
      },
      {
        "text": "Sinoe County"
      }
    ],
    "code": "+231"
  },
  {
    "text": "Libya",
    "isoCode": "LY",
    "states": [
      {
        "text": "Al Wahat District"
      },
      {
        "text": "Benghazi"
      },
      {
        "text": "Derna District"
      },
      {
        "text": "Ghat District"
      },
      {
        "text": "Jabal al Akhdar"
      },
      {
        "text": "Jabal al Gharbi District"
      },
      {
        "text": "Jafara"
      },
      {
        "text": "Jufra"
      },
      {
        "text": "Kufra District"
      },
      {
        "text": "Marj District"
      },
      {
        "text": "Misrata District"
      },
      {
        "text": "Murqub"
      },
      {
        "text": "Murzuq District"
      },
      {
        "text": "Nalut District"
      },
      {
        "text": "Nuqat al Khams"
      },
      {
        "text": "Sabha District"
      },
      {
        "text": "Sirte District"
      },
      {
        "text": "Tripoli District"
      },
      {
        "text": "Wadi al Hayaa District"
      },
      {
        "text": "Wadi al Shatii District"
      },
      {
        "text": "Zawiya District"
      }
    ],
    "code": "+218"
  },
  {
    "text": "Liechtenstein",
    "isoCode": "LI",
    "states": [
      {
        "text": "Balzers"
      },
      {
        "text": "Eschen"
      },
      {
        "text": "Gamprin"
      },
      {
        "text": "Mauren"
      },
      {
        "text": "Planken"
      },
      {
        "text": "Ruggell"
      },
      {
        "text": "Schaan"
      },
      {
        "text": "Schellenberg"
      },
      {
        "text": "Triesen"
      },
      {
        "text": "Triesenberg"
      },
      {
        "text": "Vaduz"
      }
    ],
    "code": "+423"
  },
  {
    "text": "Lithuania",
    "isoCode": "LT",
    "states": [
      {
        "text": "Akmenė District Municipality"
      },
      {
        "text": "Alytus City Municipality"
      },
      {
        "text": "Alytus County"
      },
      {
        "text": "Alytus District Municipality"
      },
      {
        "text": "Birštonas Municipality"
      },
      {
        "text": "Biržai District Municipality"
      },
      {
        "text": "Druskininkai municipality"
      },
      {
        "text": "Elektrėnai municipality"
      },
      {
        "text": "Ignalina District Municipality"
      },
      {
        "text": "Jonava District Municipality"
      },
      {
        "text": "Joniškis District Municipality"
      },
      {
        "text": "Jurbarkas District Municipality"
      },
      {
        "text": "Kaišiadorys District Municipality"
      },
      {
        "text": "Kalvarija municipality"
      },
      {
        "text": "Kaunas City Municipality"
      },
      {
        "text": "Kaunas County"
      },
      {
        "text": "Kaunas District Municipality"
      },
      {
        "text": "Kazlų Rūda municipality"
      },
      {
        "text": "Kelmė District Municipality"
      },
      {
        "text": "Klaipeda City Municipality"
      },
      {
        "text": "Klaipėda County"
      },
      {
        "text": "Klaipėda District Municipality"
      },
      {
        "text": "Kretinga District Municipality"
      },
      {
        "text": "Kupiškis District Municipality"
      },
      {
        "text": "Kėdainiai District Municipality"
      },
      {
        "text": "Lazdijai District Municipality"
      },
      {
        "text": "Marijampolė County"
      },
      {
        "text": "Marijampolė Municipality"
      },
      {
        "text": "Mažeikiai District Municipality"
      },
      {
        "text": "Molėtai District Municipality"
      },
      {
        "text": "Neringa Municipality"
      },
      {
        "text": "Pagėgiai municipality"
      },
      {
        "text": "Pakruojis District Municipality"
      },
      {
        "text": "Palanga City Municipality"
      },
      {
        "text": "Panevėžys City Municipality"
      },
      {
        "text": "Panevėžys County"
      },
      {
        "text": "Panevėžys District Municipality"
      },
      {
        "text": "Pasvalys District Municipality"
      },
      {
        "text": "Plungė District Municipality"
      },
      {
        "text": "Prienai District Municipality"
      },
      {
        "text": "Radviliškis District Municipality"
      },
      {
        "text": "Raseiniai District Municipality"
      },
      {
        "text": "Rietavas municipality"
      },
      {
        "text": "Rokiškis District Municipality"
      },
      {
        "text": "Skuodas District Municipality"
      },
      {
        "text": "Tauragė County"
      },
      {
        "text": "Tauragė District Municipality"
      },
      {
        "text": "Telšiai County"
      },
      {
        "text": "Telšiai District Municipality"
      },
      {
        "text": "Trakai District Municipality"
      },
      {
        "text": "Ukmergė District Municipality"
      },
      {
        "text": "Utena County"
      },
      {
        "text": "Utena District Municipality"
      },
      {
        "text": "Varėna District Municipality"
      },
      {
        "text": "Vilkaviškis District Municipality"
      },
      {
        "text": "Vilnius City Municipality"
      },
      {
        "text": "Vilnius County"
      },
      {
        "text": "Vilnius District Municipality"
      },
      {
        "text": "Visaginas Municipality"
      },
      {
        "text": "Zarasai District Municipality"
      },
      {
        "text": "Šakiai District Municipality"
      },
      {
        "text": "Šalčininkai District Municipality"
      },
      {
        "text": "Šiauliai City Municipality"
      },
      {
        "text": "Šiauliai County"
      },
      {
        "text": "Šiauliai District Municipality"
      },
      {
        "text": "Šilalė District Municipality"
      },
      {
        "text": "Šilutė District Municipality"
      },
      {
        "text": "Širvintos District Municipality"
      },
      {
        "text": "Švenčionys District Municipality"
      }
    ],
    "code": "+370"
  },
  {
    "text": "Luxembourg",
    "isoCode": "LU",
    "states": [
      {
        "text": "Canton of Capellen"
      },
      {
        "text": "Canton of Clervaux"
      },
      {
        "text": "Canton of Diekirch"
      },
      {
        "text": "Canton of Echternach"
      },
      {
        "text": "Canton of Esch-sur-Alzette"
      },
      {
        "text": "Canton of Grevenmacher"
      },
      {
        "text": "Canton of Luxembourg"
      },
      {
        "text": "Canton of Mersch"
      },
      {
        "text": "Canton of Redange"
      },
      {
        "text": "Canton of Remich"
      },
      {
        "text": "Canton of Vianden"
      },
      {
        "text": "Canton of Wiltz"
      },
      {
        "text": "Diekirch District"
      },
      {
        "text": "Grevenmacher District"
      },
      {
        "text": "Luxembourg District"
      }
    ],
    "code": "+352"
  },
  {
    "text": "Macau S.A.R.",
    "isoCode": "MO",
    "states": []
  },
  {
    "text": "Macedonia",
    "isoCode": "MK",
    "states": [
      {
        "text": "Aerodrom Municipality"
      },
      {
        "text": "Aračinovo Municipality"
      },
      {
        "text": "Berovo Municipality"
      },
      {
        "text": "Bitola Municipality"
      },
      {
        "text": "Bogdanci Municipality"
      },
      {
        "text": "Bogovinje Municipality"
      },
      {
        "text": "Bosilovo Municipality"
      },
      {
        "text": "Brvenica Municipality"
      },
      {
        "text": "Butel Municipality"
      },
      {
        "text": "Centar Municipality"
      },
      {
        "text": "Centar Župa Municipality"
      },
      {
        "text": "Debarca Municipality"
      },
      {
        "text": "Delčevo Municipality"
      },
      {
        "text": "Demir Hisar Municipality"
      },
      {
        "text": "Demir Kapija Municipality"
      },
      {
        "text": "Dojran Municipality"
      },
      {
        "text": "Dolneni Municipality"
      },
      {
        "text": "Drugovo Municipality"
      },
      {
        "text": "Gazi Baba Municipality"
      },
      {
        "text": "Gevgelija Municipality"
      },
      {
        "text": "Gjorče Petrov Municipality"
      },
      {
        "text": "Gostivar Municipality"
      },
      {
        "text": "Gradsko Municipality"
      },
      {
        "text": "Greater Skopje"
      },
      {
        "text": "Ilinden Municipality"
      },
      {
        "text": "Jegunovce Municipality"
      },
      {
        "text": "Karbinci"
      },
      {
        "text": "Karpoš Municipality"
      },
      {
        "text": "Kavadarci Municipality"
      },
      {
        "text": "Kisela Voda Municipality"
      },
      {
        "text": "Kičevo Municipality"
      },
      {
        "text": "Konče Municipality"
      },
      {
        "text": "Kočani Municipality"
      },
      {
        "text": "Kratovo Municipality"
      },
      {
        "text": "Kriva Palanka Municipality"
      },
      {
        "text": "Krivogaštani Municipality"
      },
      {
        "text": "Kruševo Municipality"
      },
      {
        "text": "Kumanovo Municipality"
      },
      {
        "text": "Lipkovo Municipality"
      },
      {
        "text": "Lozovo Municipality"
      },
      {
        "text": "Makedonska Kamenica Municipality"
      },
      {
        "text": "Makedonski Brod Municipality"
      },
      {
        "text": "Mavrovo and Rostuša Municipality"
      },
      {
        "text": "Mogila Municipality"
      },
      {
        "text": "Negotino Municipality"
      },
      {
        "text": "Novaci Municipality"
      },
      {
        "text": "Novo Selo Municipality"
      },
      {
        "text": "Ohrid Municipality"
      },
      {
        "text": "Oslomej Municipality"
      },
      {
        "text": "Pehčevo Municipality"
      },
      {
        "text": "Petrovec Municipality"
      },
      {
        "text": "Plasnica Municipality"
      },
      {
        "text": "Prilep Municipality"
      },
      {
        "text": "Probištip Municipality"
      },
      {
        "text": "Radoviš Municipality"
      },
      {
        "text": "Rankovce Municipality"
      },
      {
        "text": "Resen Municipality"
      },
      {
        "text": "Rosoman Municipality"
      },
      {
        "text": "Saraj Municipality"
      },
      {
        "text": "Sopište Municipality"
      },
      {
        "text": "Staro Nagoričane Municipality"
      },
      {
        "text": "Struga Municipality"
      },
      {
        "text": "Strumica Municipality"
      },
      {
        "text": "Studeničani Municipality"
      },
      {
        "text": "Sveti Nikole Municipality"
      },
      {
        "text": "Tearce Municipality"
      },
      {
        "text": "Tetovo Municipality"
      },
      {
        "text": "Valandovo Municipality"
      },
      {
        "text": "Vasilevo Municipality"
      },
      {
        "text": "Veles Municipality"
      },
      {
        "text": "Vevčani Municipality"
      },
      {
        "text": "Vinica Municipality"
      },
      {
        "text": "Vraneštica Municipality"
      },
      {
        "text": "Vrapčište Municipality"
      },
      {
        "text": "Zajas Municipality"
      },
      {
        "text": "Zelenikovo Municipality"
      },
      {
        "text": "Zrnovci Municipality"
      },
      {
        "text": "Čair Municipality"
      },
      {
        "text": "Čaška Municipality"
      },
      {
        "text": "Češinovo-Obleševo Municipality"
      },
      {
        "text": "Čučer-Sandevo Municipality"
      },
      {
        "text": "Štip Municipality"
      },
      {
        "text": "Šuto Orizari Municipality"
      },
      {
        "text": "Želino Municipality"
      }
    ],
    "code": "+389"
  },
  {
    "text": "Madagascar",
    "isoCode": "MG",
    "states": [
      {
        "text": "Antananarivo Province"
      },
      {
        "text": "Antsiranana Province"
      },
      {
        "text": "Fianarantsoa Province"
      },
      {
        "text": "Mahajanga Province"
      },
      {
        "text": "Toamasina Province"
      },
      {
        "text": "Toliara Province"
      }
    ],
    "code": "+261"
  },
  {
    "text": "Malawi",
    "isoCode": "MW",
    "states": [
      {
        "text": "Balaka District"
      },
      {
        "text": "Blantyre District"
      },
      {
        "text": "Central Region"
      },
      {
        "text": "Chikwawa District"
      },
      {
        "text": "Chiradzulu District"
      },
      {
        "text": "Chitipa district"
      },
      {
        "text": "Dedza District"
      },
      {
        "text": "Dowa District"
      },
      {
        "text": "Karonga District"
      },
      {
        "text": "Kasungu District"
      },
      {
        "text": "Likoma District"
      },
      {
        "text": "Lilongwe District"
      },
      {
        "text": "Machinga District"
      },
      {
        "text": "Mangochi District"
      },
      {
        "text": "Mchinji District"
      },
      {
        "text": "Mulanje District"
      },
      {
        "text": "Mwanza District"
      },
      {
        "text": "Mzimba District"
      },
      {
        "text": "Nkhata Bay District"
      },
      {
        "text": "Nkhotakota District"
      },
      {
        "text": "Northern Region"
      },
      {
        "text": "Nsanje District"
      },
      {
        "text": "Ntcheu District"
      },
      {
        "text": "Ntchisi District"
      },
      {
        "text": "Phalombe District"
      },
      {
        "text": "Rumphi District"
      },
      {
        "text": "Salima District"
      },
      {
        "text": "Southern Region"
      },
      {
        "text": "Thyolo District"
      },
      {
        "text": "Zomba District"
      }
    ],
    "code": "+265"
  },
  {
    "text": "Malaysia",
    "isoCode": "MY",
    "states": [
      {
        "text": "Johor"
      },
      {
        "text": "Kedah"
      },
      {
        "text": "Kelantan"
      },
      {
        "text": "Kuala Lumpur"
      },
      {
        "text": "Labuan"
      },
      {
        "text": "Malacca"
      },
      {
        "text": "Negeri Sembilan"
      },
      {
        "text": "Pahang"
      },
      {
        "text": "Penang"
      },
      {
        "text": "Perak"
      },
      {
        "text": "Perlis"
      },
      {
        "text": "Putrajaya"
      },
      {
        "text": "Sabah"
      },
      {
        "text": "Sarawak"
      },
      {
        "text": "Selangor"
      },
      {
        "text": "Terengganu"
      }
    ],
    "code": "+60"
  },
  {
    "text": "Maldives",
    "isoCode": "MV",
    "states": [
      {
        "text": "Addu Atoll"
      },
      {
        "text": "Alif Alif Atoll"
      },
      {
        "text": "Alif Dhaal Atoll"
      },
      {
        "text": "Central Province"
      },
      {
        "text": "Dhaalu Atoll"
      },
      {
        "text": "Faafu Atoll"
      },
      {
        "text": "Gaafu Alif Atoll"
      },
      {
        "text": "Gaafu Dhaalu Atoll"
      },
      {
        "text": "Gnaviyani Atoll"
      },
      {
        "text": "Haa Alif Atoll"
      },
      {
        "text": "Haa Dhaalu Atoll"
      },
      {
        "text": "Kaafu Atoll"
      },
      {
        "text": "Laamu Atoll"
      },
      {
        "text": "Lhaviyani Atoll"
      },
      {
        "text": "Malé"
      },
      {
        "text": "Meemu Atoll"
      },
      {
        "text": "Noonu Atoll"
      },
      {
        "text": "North Central Province"
      },
      {
        "text": "North Province"
      },
      {
        "text": "Raa Atoll"
      },
      {
        "text": "Shaviyani Atoll"
      },
      {
        "text": "South Central Province"
      },
      {
        "text": "South Province"
      },
      {
        "text": "Thaa Atoll"
      },
      {
        "text": "Upper South Province"
      },
      {
        "text": "Vaavu Atoll"
      }
    ],
    "code": "+960"
  },
  {
    "text": "Mali",
    "isoCode": "ML",
    "states": [
      {
        "text": "Bamako"
      },
      {
        "text": "Gao Region"
      },
      {
        "text": "Kayes Region"
      },
      {
        "text": "Kidal Region"
      },
      {
        "text": "Koulikoro Region"
      },
      {
        "text": "Mopti Region"
      },
      {
        "text": "Ménaka Region"
      },
      {
        "text": "Sikasso Region"
      },
      {
        "text": "Ségou Region"
      },
      {
        "text": "Taoudénit Region"
      },
      {
        "text": "Tombouctou Region"
      }
    ],
    "code": "+223"
  },
  {
    "text": "Malta",
    "isoCode": "MT",
    "states": [
      {
        "text": "Attard"
      },
      {
        "text": "Balzan"
      },
      {
        "text": "Birgu"
      },
      {
        "text": "Birkirkara"
      },
      {
        "text": "Birżebbuġa"
      },
      {
        "text": "Cospicua"
      },
      {
        "text": "Dingli"
      },
      {
        "text": "Fgura"
      },
      {
        "text": "Floriana"
      },
      {
        "text": "Fontana"
      },
      {
        "text": "Gudja"
      },
      {
        "text": "Għajnsielem"
      },
      {
        "text": "Għarb"
      },
      {
        "text": "Għargħur"
      },
      {
        "text": "Għasri"
      },
      {
        "text": "Għaxaq"
      },
      {
        "text": "Gżira"
      },
      {
        "text": "Iklin"
      },
      {
        "text": "Kalkara"
      },
      {
        "text": "Kerċem"
      },
      {
        "text": "Kirkop"
      },
      {
        "text": "Lija"
      },
      {
        "text": "Luqa"
      },
      {
        "text": "Marsa"
      },
      {
        "text": "Marsaskala"
      },
      {
        "text": "Marsaxlokk"
      },
      {
        "text": "Mdina"
      },
      {
        "text": "Mellieħa"
      },
      {
        "text": "Mosta"
      },
      {
        "text": "Mqabba"
      },
      {
        "text": "Msida"
      },
      {
        "text": "Mtarfa"
      },
      {
        "text": "Munxar"
      },
      {
        "text": "Mġarr"
      },
      {
        "text": "Nadur"
      },
      {
        "text": "Naxxar"
      },
      {
        "text": "Paola"
      },
      {
        "text": "Pembroke"
      },
      {
        "text": "Pietà"
      },
      {
        "text": "Qala"
      },
      {
        "text": "Qormi"
      },
      {
        "text": "Qrendi"
      },
      {
        "text": "Rabat"
      },
      {
        "text": "Saint Lawrence"
      },
      {
        "text": "San Ġwann"
      },
      {
        "text": "Sannat"
      },
      {
        "text": "Santa Luċija"
      },
      {
        "text": "Santa Venera"
      },
      {
        "text": "Senglea"
      },
      {
        "text": "Siġġiewi"
      },
      {
        "text": "Sliema"
      },
      {
        "text": "St. Julian's"
      },
      {
        "text": "St. Paul's Bay"
      },
      {
        "text": "Swieqi"
      },
      {
        "text": "Ta' Xbiex"
      },
      {
        "text": "Tarxien"
      },
      {
        "text": "Valletta"
      },
      {
        "text": "Victoria"
      },
      {
        "text": "Xagħra"
      },
      {
        "text": "Xewkija"
      },
      {
        "text": "Xgħajra"
      },
      {
        "text": "Ħamrun"
      },
      {
        "text": "Żabbar"
      },
      {
        "text": "Żebbuġ Gozo"
      },
      {
        "text": "Żebbuġ Malta"
      },
      {
        "text": "Żejtun"
      },
      {
        "text": "Żurrieq"
      }
    ],
    "code": "+356"
  },
  {
    "text": "Man (Isle of)",
    "isoCode": "IM",
    "states": []
  },
  {
    "text": "Marshall Islands",
    "isoCode": "MH",
    "states": [
      {
        "text": "Ralik Chain"
      },
      {
        "text": "Ratak Chain"
      }
    ],
    "code": "+692"
  },
  {
    "text": "Martinique",
    "isoCode": "MQ",
    "states": []
  },
  {
    "text": "Mauritania",
    "isoCode": "MR",
    "states": [
      {
        "text": "Adrar Region"
      },
      {
        "text": "Assaba Region"
      },
      {
        "text": "Brakna Region"
      },
      {
        "text": "Dakhlet Nouadhibou"
      },
      {
        "text": "Gorgol Region"
      },
      {
        "text": "Guidimaka Region"
      },
      {
        "text": "Hodh Ech Chargui Region"
      },
      {
        "text": "Hodh El Gharbi Region"
      },
      {
        "text": "Inchiri Region"
      },
      {
        "text": "Nouakchott-Nord Region"
      },
      {
        "text": "Nouakchott-Ouest Region"
      },
      {
        "text": "Nouakchott-Sud Region"
      },
      {
        "text": "Tagant Region"
      },
      {
        "text": "Tiris Zemmour Region"
      },
      {
        "text": "Trarza Region"
      }
    ],
    "code": "+222"
  },
  {
    "text": "Mauritius",
    "isoCode": "MU",
    "states": [
      {
        "text": "Agaléga"
      },
      {
        "text": "Beau Bassin-Rose Hill"
      },
      {
        "text": "Cargados Carajos"
      },
      {
        "text": "Curepipe"
      },
      {
        "text": "Flacq District"
      },
      {
        "text": "Grand Port District"
      },
      {
        "text": "Moka District"
      },
      {
        "text": "Pamplemousses District"
      },
      {
        "text": "Plaines Wilhems District"
      },
      {
        "text": "Port Louis"
      },
      {
        "text": "Port Louis District"
      },
      {
        "text": "Quatre Bornes"
      },
      {
        "text": "Rivière Noire District"
      },
      {
        "text": "Rivière du Rempart District"
      },
      {
        "text": "Rodrigues"
      },
      {
        "text": "Savanne District"
      },
      {
        "text": "Vacoas-Phoenix"
      }
    ],
    "code": "+230"
  },
  {
    "text": "Mayotte",
    "isoCode": "YT",
    "states": []
  },
  {
    "text": "Mexico",
    "isoCode": "MX",
    "states": [
      {
        "text": "Aguascalientes"
      },
      {
        "text": "Baja California"
      },
      {
        "text": "Baja California Sur"
      },
      {
        "text": "Campeche"
      },
      {
        "text": "Chiapas"
      },
      {
        "text": "Chihuahua"
      },
      {
        "text": "Coahuila"
      },
      {
        "text": "Colima"
      },
      {
        "text": "Durango"
      },
      {
        "text": "Guanajuato"
      },
      {
        "text": "Guerrero"
      },
      {
        "text": "Hidalgo"
      },
      {
        "text": "Jalisco"
      },
      {
        "text": "Mexico City"
      },
      {
        "text": "Michoacán"
      },
      {
        "text": "Morelos"
      },
      {
        "text": "México"
      },
      {
        "text": "Nayarit"
      },
      {
        "text": "Nuevo León"
      },
      {
        "text": "Oaxaca"
      },
      {
        "text": "Puebla"
      },
      {
        "text": "Querétaro"
      },
      {
        "text": "Quintana Roo"
      },
      {
        "text": "San Luis Potosí"
      },
      {
        "text": "Sinaloa"
      },
      {
        "text": "Sonora"
      },
      {
        "text": "Tabasco"
      },
      {
        "text": "Tamaulipas"
      },
      {
        "text": "Tlaxcala"
      },
      {
        "text": "Veracruz"
      },
      {
        "text": "Yucatán"
      },
      {
        "text": "Zacatecas"
      }
    ],
    "code": "+52"
  },
  {
    "text": "Micronesia",
    "isoCode": "FM",
    "states": [
      {
        "text": "Chuuk State"
      },
      {
        "text": "Kosrae State"
      },
      {
        "text": "Pohnpei State"
      },
      {
        "text": "Yap State"
      }
    ],
    "code": "+691"
  },
  {
    "text": "Moldova",
    "isoCode": "MD",
    "states": [
      {
        "text": "Anenii Noi District"
      },
      {
        "text": "Basarabeasca District"
      },
      {
        "text": "Bender Municipality"
      },
      {
        "text": "Briceni District"
      },
      {
        "text": "Bălți Municipality"
      },
      {
        "text": "Cahul District"
      },
      {
        "text": "Cantemir District"
      },
      {
        "text": "Chișinău Municipality"
      },
      {
        "text": "Cimișlia District"
      },
      {
        "text": "Criuleni District"
      },
      {
        "text": "Călărași District"
      },
      {
        "text": "Căușeni District"
      },
      {
        "text": "Dondușeni District"
      },
      {
        "text": "Drochia District"
      },
      {
        "text": "Dubăsari District"
      },
      {
        "text": "Edineț District"
      },
      {
        "text": "Florești District"
      },
      {
        "text": "Fălești District"
      },
      {
        "text": "Gagauzia"
      },
      {
        "text": "Glodeni District"
      },
      {
        "text": "Hîncești District"
      },
      {
        "text": "Ialoveni District"
      },
      {
        "text": "Nisporeni District"
      },
      {
        "text": "Ocnița District"
      },
      {
        "text": "Orhei District"
      },
      {
        "text": "Rezina District"
      },
      {
        "text": "Rîșcani District"
      },
      {
        "text": "Soroca District"
      },
      {
        "text": "Strășeni District"
      },
      {
        "text": "Sîngerei District"
      },
      {
        "text": "Taraclia District"
      },
      {
        "text": "Telenești District"
      },
      {
        "text": "Transnistria autonomous territorial unit"
      },
      {
        "text": "Ungheni District"
      },
      {
        "text": "Șoldănești District"
      },
      {
        "text": "Ștefan Vodă District"
      }
    ],
    "code": "+373"
  },
  {
    "text": "Monaco",
    "isoCode": "MC",
    "states": [
      {
        "text": "La Colle"
      },
      {
        "text": "La Condamine"
      },
      {
        "text": "Moneghetti"
      }
    ],
    "code": "+377"
  },
  {
    "text": "Mongolia",
    "isoCode": "MN",
    "states": [
      {
        "text": "Arkhangai Province"
      },
      {
        "text": "Bayan-Ölgii Province"
      },
      {
        "text": "Bayankhongor Province"
      },
      {
        "text": "Bulgan Province"
      },
      {
        "text": "Darkhan-Uul Province"
      },
      {
        "text": "Dornod Province"
      },
      {
        "text": "Dornogovi Province"
      },
      {
        "text": "Dundgovi Province"
      },
      {
        "text": "Govi-Altai Province"
      },
      {
        "text": "Govisümber Province"
      },
      {
        "text": "Khentii Province"
      },
      {
        "text": "Khovd Province"
      },
      {
        "text": "Khövsgöl Province"
      },
      {
        "text": "Orkhon Province"
      },
      {
        "text": "Selenge Province"
      },
      {
        "text": "Sükhbaatar Province"
      },
      {
        "text": "Töv Province"
      },
      {
        "text": "Uvs Province"
      },
      {
        "text": "Zavkhan Province"
      },
      {
        "text": "Ömnögovi Province"
      },
      {
        "text": "Övörkhangai Province"
      }
    ],
    "code": "+976"
  },
  {
    "text": "Montenegro",
    "isoCode": "ME",
    "states": [
      {
        "text": "Andrijevica Municipality"
      },
      {
        "text": "Bar Municipality"
      },
      {
        "text": "Berane Municipality"
      },
      {
        "text": "Bijelo Polje Municipality"
      },
      {
        "text": "Budva Municipality"
      },
      {
        "text": "Danilovgrad Municipality"
      },
      {
        "text": "Gusinje Municipality"
      },
      {
        "text": "Kolašin Municipality"
      },
      {
        "text": "Kotor Municipality"
      },
      {
        "text": "Mojkovac Municipality"
      },
      {
        "text": "Nikšić Municipality"
      },
      {
        "text": "Old Royal Capital Cetinje"
      },
      {
        "text": "Petnjica Municipality"
      },
      {
        "text": "Plav Municipality"
      },
      {
        "text": "Pljevlja Municipality"
      },
      {
        "text": "Plužine Municipality"
      },
      {
        "text": "Podgorica Municipality"
      },
      {
        "text": "Rožaje Municipality"
      },
      {
        "text": "Tivat Municipality"
      },
      {
        "text": "Ulcinj Municipality"
      },
      {
        "text": "Šavnik Municipality"
      },
      {
        "text": "Žabljak Municipality"
      }
    ],
    "code": "+382"
  },
  {
    "text": "Montserrat",
    "isoCode": "MS",
    "states": []
  },
  {
    "text": "Morocco",
    "isoCode": "MA",
    "states": [
      {
        "text": "Al Haouz Province"
      },
      {
        "text": "Al Hoceïma Province"
      },
      {
        "text": "Aousserd Province"
      },
      {
        "text": "Assa-Zag Province"
      },
      {
        "text": "Azilal Province"
      },
      {
        "text": "Ben Slimane Province"
      },
      {
        "text": "Berkane Province"
      },
      {
        "text": "Boujdour Province"
      },
      {
        "text": "Boulemane Province"
      },
      {
        "text": "Béni Mellal-Khénifra"
      },
      {
        "text": "Béni-Mellal Province"
      },
      {
        "text": "Casablanca-Settat"
      },
      {
        "text": "Chefchaouen Province"
      },
      {
        "text": "Chichaoua Province"
      },
      {
        "text": "Dakhla-Oued Ed-Dahab"
      },
      {
        "text": "Drâa-Tafilalet"
      },
      {
        "text": "El Hajeb Province"
      },
      {
        "text": "El Jadida Province"
      },
      {
        "text": "Errachidia Province"
      },
      {
        "text": "Es Semara Province"
      },
      {
        "text": "Essaouira Province"
      },
      {
        "text": "Fahs Anjra Province"
      },
      {
        "text": "Figuig Province"
      },
      {
        "text": "Fès-Meknès"
      },
      {
        "text": "Guelmim Province"
      },
      {
        "text": "Guelmim-Oued Noun"
      },
      {
        "text": "Ifrane Province"
      },
      {
        "text": "Inezgane-Aït Melloul Prefecture"
      },
      {
        "text": "Jerada Province"
      },
      {
        "text": "Kelaat Sraghna Province"
      },
      {
        "text": "Khouribga Province"
      },
      {
        "text": "Khémisset Province"
      },
      {
        "text": "Khénifra Province"
      },
      {
        "text": "Kénitra Province"
      },
      {
        "text": "Larache Province"
      },
      {
        "text": "Laâyoune Province"
      },
      {
        "text": "Laâyoune-Sakia El Hamra"
      },
      {
        "text": "Marrakesh-Safi"
      },
      {
        "text": "Mediouna Province"
      },
      {
        "text": "Moulay Yacoub Province"
      },
      {
        "text": "Nador Province"
      },
      {
        "text": "Nouaceur Province"
      },
      {
        "text": "Oriental"
      },
      {
        "text": "Ouarzazate Province"
      },
      {
        "text": "Oued Ed-Dahab Province"
      },
      {
        "text": "Safi Province"
      },
      {
        "text": "Sefrou Province"
      },
      {
        "text": "Settat Province"
      },
      {
        "text": "Shtouka Ait Baha Province"
      },
      {
        "text": "Sidi Kacem Province"
      },
      {
        "text": "Sidi Youssef Ben Ali"
      },
      {
        "text": "Souss-Massa"
      },
      {
        "text": "Tan-Tan Province"
      },
      {
        "text": "Tanger-Tétouan-Al Hoceïma"
      },
      {
        "text": "Taounate Province"
      },
      {
        "text": "Taourirt Province"
      },
      {
        "text": "Taroudant Province"
      },
      {
        "text": "Tata Province"
      },
      {
        "text": "Taza Province"
      },
      {
        "text": "Tiznit Province"
      },
      {
        "text": "Tétouan Province"
      },
      {
        "text": "Zagora Province"
      }
    ],
    "code": "+212"
  },
  {
    "text": "Mozambique",
    "isoCode": "MZ",
    "states": [
      {
        "text": "Cabo Delgado Province"
      },
      {
        "text": "Gaza Province"
      },
      {
        "text": "Inhambane Province"
      },
      {
        "text": "Manica Province"
      },
      {
        "text": "Maputo"
      },
      {
        "text": "Maputo Province"
      },
      {
        "text": "Nampula Province"
      },
      {
        "text": "Niassa Province"
      },
      {
        "text": "Sofala Province"
      },
      {
        "text": "Tete Province"
      },
      {
        "text": "Zambezia Province"
      }
    ],
    "code": "+258"
  },
  {
    "text": "Myanmar",
    "isoCode": "MM",
    "states": [
      {
        "text": "Ayeyarwady Region"
      },
      {
        "text": "Bago"
      },
      {
        "text": "Chin State"
      },
      {
        "text": "Kachin State"
      },
      {
        "text": "Kayah State"
      },
      {
        "text": "Kayin State"
      },
      {
        "text": "Magway Region"
      },
      {
        "text": "Mandalay Region"
      },
      {
        "text": "Mon State"
      },
      {
        "text": "Naypyidaw Union Territory"
      },
      {
        "text": "Rakhine State"
      },
      {
        "text": "Sagaing Region"
      },
      {
        "text": "Shan State"
      },
      {
        "text": "Tanintharyi Region"
      },
      {
        "text": "Yangon Region"
      }
    ],
    "code": "+95"
  },
  {
    "text": "Namibia",
    "isoCode": "NA",
    "states": [
      {
        "text": "Erongo Region"
      },
      {
        "text": "Hardap Region"
      },
      {
        "text": "Karas Region"
      },
      {
        "text": "Kavango East Region"
      },
      {
        "text": "Kavango West Region"
      },
      {
        "text": "Khomas Region"
      },
      {
        "text": "Kunene Region"
      },
      {
        "text": "Ohangwena Region"
      },
      {
        "text": "Omaheke Region"
      },
      {
        "text": "Omusati Region"
      },
      {
        "text": "Oshana Region"
      },
      {
        "text": "Oshikoto Region"
      },
      {
        "text": "Otjozondjupa Region"
      },
      {
        "text": "Zambezi Region"
      }
    ],
    "code": "+264"
  },
  {
    "text": "Nauru",
    "isoCode": "NR",
    "states": [
      {
        "text": "Aiwo District"
      },
      {
        "text": "Anabar District"
      },
      {
        "text": "Anetan District"
      },
      {
        "text": "Anibare District"
      },
      {
        "text": "Baiti District"
      },
      {
        "text": "Boe District"
      },
      {
        "text": "Buada District"
      },
      {
        "text": "Denigomodu District"
      },
      {
        "text": "Ewa District"
      },
      {
        "text": "Ijuw District"
      },
      {
        "text": "Meneng District"
      },
      {
        "text": "Nibok District"
      },
      {
        "text": "Uaboe District"
      },
      {
        "text": "Yaren District"
      }
    ],
    "code": "+674"
  },
  {
    "text": "Nepal",
    "isoCode": "NP",
    "states": [
      {
        "text": "Bagmati Zone"
      },
      {
        "text": "Bheri Zone"
      },
      {
        "text": "Central Region"
      },
      {
        "text": "Dhaulagiri Zone"
      },
      {
        "text": "Eastern Development Region"
      },
      {
        "text": "Far-Western Development Region"
      },
      {
        "text": "Gandaki Zone"
      },
      {
        "text": "Janakpur Zone"
      },
      {
        "text": "Karnali Zone"
      },
      {
        "text": "Kosi Zone"
      },
      {
        "text": "Lumbini Zone"
      },
      {
        "text": "Mahakali Zone"
      },
      {
        "text": "Mechi Zone"
      },
      {
        "text": "Mid-Western Region"
      },
      {
        "text": "Narayani Zone"
      },
      {
        "text": "Rapti Zone"
      },
      {
        "text": "Sagarmatha Zone"
      },
      {
        "text": "Seti Zone"
      },
      {
        "text": "Western Region"
      }
    ],
    "code": "+977"
  },
  {
    "text": "Bonaire, Sint Eustatius and Saba",
    "isoCode": "BQ",
    "states": []
  },
  {
    "text": "Netherlands The",
    "isoCode": "NL",
    "states": [
      {
        "text": "Bonaire"
      },
      {
        "text": "Drenthe"
      },
      {
        "text": "Flevoland"
      },
      {
        "text": "Friesland"
      },
      {
        "text": "Gelderland"
      },
      {
        "text": "Groningen"
      },
      {
        "text": "Limburg"
      },
      {
        "text": "North Brabant"
      },
      {
        "text": "North Holland"
      },
      {
        "text": "Overijssel"
      },
      {
        "text": "Saba"
      },
      {
        "text": "Sint Eustatius"
      },
      {
        "text": "South Holland"
      },
      {
        "text": "Utrecht"
      },
      {
        "text": "Zeeland"
      }
    ]
  },
  {
    "text": "New Caledonia",
    "isoCode": "NC",
    "states": []
  },
  {
    "text": "New Zealand",
    "isoCode": "NZ",
    "states": [
      {
        "text": "Auckland Region"
      },
      {
        "text": "Bay of Plenty Region"
      },
      {
        "text": "Canterbury Region"
      },
      {
        "text": "Chatham Islands"
      },
      {
        "text": "Gisborne District"
      },
      {
        "text": "Hawke's Bay Region"
      },
      {
        "text": "Manawatu-Wanganui Region"
      },
      {
        "text": "Marlborough Region"
      },
      {
        "text": "Nelson Region"
      },
      {
        "text": "Northland Region"
      },
      {
        "text": "Otago Region"
      },
      {
        "text": "Southland Region"
      },
      {
        "text": "Taranaki Region"
      },
      {
        "text": "Tasman District"
      },
      {
        "text": "Waikato Region"
      },
      {
        "text": "Wellington Region"
      },
      {
        "text": "West Coast Region"
      }
    ],
    "code": "+64"
  },
  {
    "text": "Nicaragua",
    "isoCode": "NI",
    "states": [
      {
        "text": "Boaco Department"
      },
      {
        "text": "Carazo Department"
      },
      {
        "text": "Chinandega Department"
      },
      {
        "text": "Chontales Department"
      },
      {
        "text": "Estelí Department"
      },
      {
        "text": "Granada Department"
      },
      {
        "text": "Jinotega Department"
      },
      {
        "text": "León Department"
      },
      {
        "text": "Madriz Department"
      },
      {
        "text": "Managua Department"
      },
      {
        "text": "Masaya Department"
      },
      {
        "text": "Matagalpa Department"
      },
      {
        "text": "North Caribbean Coast Autonomous Region"
      },
      {
        "text": "Rivas Department"
      },
      {
        "text": "Río San Juan Department"
      },
      {
        "text": "South Caribbean Coast Autonomous Region"
      }
    ],
    "code": "+505"
  },
  {
    "text": "Niger",
    "isoCode": "NE",
    "states": [
      {
        "text": "Agadez Region"
      },
      {
        "text": "Diffa Region"
      },
      {
        "text": "Dosso Region"
      },
      {
        "text": "Maradi Region"
      },
      {
        "text": "Tahoua Region"
      },
      {
        "text": "Tillabéri Region"
      },
      {
        "text": "Zinder Region"
      }
    ],
    "code": "+227"
  },
  {
    "text": "Nigeria",
    "isoCode": "NG",
    "states": [
      {
        "text": "Abia State"
      },
      {
        "text": "Adamawa State"
      },
      {
        "text": "Akwa Ibom State"
      },
      {
        "text": "Anambra State"
      },
      {
        "text": "Bauchi State"
      },
      {
        "text": "Bayelsa State"
      },
      {
        "text": "Benue State"
      },
      {
        "text": "Borno State"
      },
      {
        "text": "Cross River State"
      },
      {
        "text": "Delta State"
      },
      {
        "text": "Ebonyi State"
      },
      {
        "text": "Edo State"
      },
      {
        "text": "Ekiti State"
      },
      {
        "text": "Enugu State"
      },
      {
        "text": "Federal Capital Territory"
      },
      {
        "text": "Gombe State"
      },
      {
        "text": "Imo State"
      },
      {
        "text": "Jigawa State"
      },
      {
        "text": "Kaduna State"
      },
      {
        "text": "Kano State"
      },
      {
        "text": "Katsina State"
      },
      {
        "text": "Kebbi State"
      },
      {
        "text": "Kogi State"
      },
      {
        "text": "Kwara State"
      },
      {
        "text": "Lagos"
      },
      {
        "text": "Nasarawa State"
      },
      {
        "text": "Niger State"
      },
      {
        "text": "Ogun State"
      },
      {
        "text": "Ondo State"
      },
      {
        "text": "Osun State"
      },
      {
        "text": "Oyo State"
      },
      {
        "text": "Plateau State"
      },
      {
        "text": "Sokoto State"
      },
      {
        "text": "Taraba State"
      },
      {
        "text": "Yobe State"
      },
      {
        "text": "Zamfara State"
      }
    ],
    "code": "+234"
  },
  {
    "text": "Niue",
    "isoCode": "NU",
    "states": []
  },
  {
    "text": "Norfolk Island",
    "isoCode": "NF",
    "states": []
  },
  {
    "text": "Northern Mariana Islands",
    "isoCode": "MP",
    "states": []
  },
  {
    "text": "Norway",
    "isoCode": "NO",
    "states": [
      {
        "text": "Akershus"
      },
      {
        "text": "Buskerud"
      },
      {
        "text": "Finnmark"
      },
      {
        "text": "Hedmark"
      },
      {
        "text": "Hordaland"
      },
      {
        "text": "Jan Mayen"
      },
      {
        "text": "Møre og Romsdal"
      },
      {
        "text": "Nord-Trøndelag"
      },
      {
        "text": "Nordland"
      },
      {
        "text": "Oppland"
      },
      {
        "text": "Oslo"
      },
      {
        "text": "Rogaland"
      },
      {
        "text": "Sogn og Fjordane"
      },
      {
        "text": "Svalbard"
      },
      {
        "text": "Sør-Trøndelag"
      },
      {
        "text": "Telemark"
      },
      {
        "text": "Troms"
      },
      {
        "text": "Trøndelag"
      },
      {
        "text": "Vest-Agder"
      },
      {
        "text": "Vestfold"
      },
      {
        "text": "Østfold"
      }
    ],
    "code": "+47"
  },
  {
    "text": "Oman",
    "isoCode": "OM",
    "states": [
      {
        "text": "Ad Dakhiliyah Governorate"
      },
      {
        "text": "Ad Dhahirah Governorate"
      },
      {
        "text": "Al Batinah North Governorate"
      },
      {
        "text": "Al Batinah Region"
      },
      {
        "text": "Al Batinah South Governorate"
      },
      {
        "text": "Al Buraimi Governorate"
      },
      {
        "text": "Al Wusta Governorate"
      },
      {
        "text": "Ash Sharqiyah North Governorate"
      },
      {
        "text": "Ash Sharqiyah Region"
      },
      {
        "text": "Ash Sharqiyah South Governorate"
      },
      {
        "text": "Dhofar Governorate"
      },
      {
        "text": "Musandam Governorate"
      },
      {
        "text": "Muscat Governorate"
      }
    ],
    "code": "+968"
  },
  {
    "text": "Pakistan",
    "isoCode": "PK",
    "states": [
      {
        "text": "Azad Kashmir"
      },
      {
        "text": "Balochistan"
      },
      {
        "text": "Federally Administered Tribal Areas"
      },
      {
        "text": "Gilgit-Baltistan"
      },
      {
        "text": "Islamabad Capital Territory"
      },
      {
        "text": "Khyber Pakhtunkhwa"
      },
      {
        "text": "Punjab"
      },
      {
        "text": "Sindh"
      }
    ],
    "code": "+92"
  },
  {
    "text": "Palau",
    "isoCode": "PW",
    "states": [
      {
        "text": "Aimeliik"
      },
      {
        "text": "Airai"
      },
      {
        "text": "Angaur"
      },
      {
        "text": "Hatohobei"
      },
      {
        "text": "Kayangel"
      },
      {
        "text": "Koror"
      },
      {
        "text": "Melekeok"
      },
      {
        "text": "Ngaraard"
      },
      {
        "text": "Ngarchelong"
      },
      {
        "text": "Ngardmau"
      },
      {
        "text": "Ngatpang"
      },
      {
        "text": "Ngchesar"
      },
      {
        "text": "Ngeremlengui"
      },
      {
        "text": "Ngiwal"
      },
      {
        "text": "Peleliu"
      },
      {
        "text": "Sonsorol"
      }
    ],
    "code": "+680"
  },
  {
    "text": "Palestinian Territory Occupied",
    "isoCode": "PS",
    "states": []
  },
  {
    "text": "Panama",
    "isoCode": "PA",
    "states": [
      {
        "text": "Bocas del Toro Province"
      },
      {
        "text": "Chiriquí Province"
      },
      {
        "text": "Coclé Province"
      },
      {
        "text": "Colón Province"
      },
      {
        "text": "Darién Province"
      },
      {
        "text": "Emberá-Wounaan Comarca"
      },
      {
        "text": "Guna Yala"
      },
      {
        "text": "Herrera Province"
      },
      {
        "text": "Los Santos Province"
      },
      {
        "text": "Ngöbe-Buglé Comarca"
      },
      {
        "text": "Panamá Oeste Province"
      },
      {
        "text": "Panamá Province"
      },
      {
        "text": "Veraguas Province"
      }
    ],
    "code": "+507"
  },
  {
    "text": "Papua new Guinea",
    "isoCode": "PG",
    "states": [
      {
        "text": "Bougainville"
      },
      {
        "text": "Central Province"
      },
      {
        "text": "Chimbu Province"
      },
      {
        "text": "East New Britain"
      },
      {
        "text": "Eastern Highlands Province"
      },
      {
        "text": "Enga Province"
      },
      {
        "text": "Gulf"
      },
      {
        "text": "Hela"
      },
      {
        "text": "Jiwaka Province"
      },
      {
        "text": "Madang Province"
      },
      {
        "text": "Manus Province"
      },
      {
        "text": "Milne Bay Province"
      },
      {
        "text": "Morobe Province"
      },
      {
        "text": "New Ireland Province"
      },
      {
        "text": "Oro Province"
      },
      {
        "text": "Port Moresby"
      },
      {
        "text": "Sandaun Province"
      },
      {
        "text": "Southern Highlands Province"
      },
      {
        "text": "West New Britain Province"
      },
      {
        "text": "Western Highlands Province"
      },
      {
        "text": "Western Province"
      }
    ]
  },
  {
    "text": "Paraguay",
    "isoCode": "PY",
    "states": [
      {
        "text": "Alto Paraguay Department"
      },
      {
        "text": "Alto Paraná Department"
      },
      {
        "text": "Amambay Department"
      },
      {
        "text": "Boquerón Department"
      },
      {
        "text": "Caaguazú"
      },
      {
        "text": "Caazapá"
      },
      {
        "text": "Canindeyú"
      },
      {
        "text": "Central Department"
      },
      {
        "text": "Concepción Department"
      },
      {
        "text": "Cordillera Department"
      },
      {
        "text": "Guairá Department"
      },
      {
        "text": "Itapúa"
      },
      {
        "text": "Misiones Department"
      },
      {
        "text": "Paraguarí Department"
      },
      {
        "text": "Presidente Hayes Department"
      },
      {
        "text": "San Pedro Department"
      },
      {
        "text": "Ñeembucú Department"
      }
    ],
    "code": "+595"
  },
  {
    "text": "Peru",
    "isoCode": "PE",
    "states": [
      {
        "text": "Amazonas"
      },
      {
        "text": "Apurímac"
      },
      {
        "text": "Arequipa"
      },
      {
        "text": "Ayacucho"
      },
      {
        "text": "Cajamarca"
      },
      {
        "text": "Callao"
      },
      {
        "text": "Cusco"
      },
      {
        "text": "Huancavelica"
      },
      {
        "text": "Huanuco"
      },
      {
        "text": "Ica"
      },
      {
        "text": "Junín"
      },
      {
        "text": "La Libertad"
      },
      {
        "text": "Lambayeque"
      },
      {
        "text": "Lima"
      },
      {
        "text": "Madre de Dios"
      },
      {
        "text": "Moquegua"
      },
      {
        "text": "Pasco"
      },
      {
        "text": "Piura"
      },
      {
        "text": "Puno"
      },
      {
        "text": "San Martín"
      },
      {
        "text": "Tacna"
      },
      {
        "text": "Tumbes"
      },
      {
        "text": "Ucayali"
      },
      {
        "text": "Áncash"
      }
    ],
    "code": "+51"
  },
  {
    "text": "Philippines",
    "isoCode": "PH",
    "states": [
      {
        "text": "Abra"
      },
      {
        "text": "Agusan del Norte"
      },
      {
        "text": "Agusan del Sur"
      },
      {
        "text": "Aklan"
      },
      {
        "text": "Albay"
      },
      {
        "text": "Antique"
      },
      {
        "text": "Apayao"
      },
      {
        "text": "Aurora"
      },
      {
        "text": "Autonomous Region in Muslim Mindanao"
      },
      {
        "text": "Basilan"
      },
      {
        "text": "Bataan"
      },
      {
        "text": "Batanes"
      },
      {
        "text": "Batangas"
      },
      {
        "text": "Benguet"
      },
      {
        "text": "Bicol Region"
      },
      {
        "text": "Biliran"
      },
      {
        "text": "Bohol"
      },
      {
        "text": "Bukidnon"
      },
      {
        "text": "Bulacan"
      },
      {
        "text": "Cagayan"
      },
      {
        "text": "Cagayan Valley"
      },
      {
        "text": "Calabarzon"
      },
      {
        "text": "Camarines Norte"
      },
      {
        "text": "Camarines Sur"
      },
      {
        "text": "Camiguin"
      },
      {
        "text": "Capiz"
      },
      {
        "text": "Caraga"
      },
      {
        "text": "Catanduanes"
      },
      {
        "text": "Cavite"
      },
      {
        "text": "Cebu"
      },
      {
        "text": "Central Luzon"
      },
      {
        "text": "Central Visayas"
      },
      {
        "text": "Compostela Valley"
      },
      {
        "text": "Cordillera Administrative Region"
      },
      {
        "text": "Cotabato"
      },
      {
        "text": "Davao Occidental"
      },
      {
        "text": "Davao Oriental"
      },
      {
        "text": "Davao Region"
      },
      {
        "text": "Davao del Norte"
      },
      {
        "text": "Davao del Sur"
      },
      {
        "text": "Dinagat Islands"
      },
      {
        "text": "Eastern Samar"
      },
      {
        "text": "Eastern Visayas"
      },
      {
        "text": "Guimaras"
      },
      {
        "text": "Ifugao"
      },
      {
        "text": "Ilocos Norte"
      },
      {
        "text": "Ilocos Region"
      },
      {
        "text": "Ilocos Sur"
      },
      {
        "text": "Iloilo"
      },
      {
        "text": "Isabela"
      },
      {
        "text": "Kalinga"
      },
      {
        "text": "La Union"
      },
      {
        "text": "Laguna"
      },
      {
        "text": "Lanao del Norte"
      },
      {
        "text": "Lanao del Sur"
      },
      {
        "text": "Leyte"
      },
      {
        "text": "Maguindanao"
      },
      {
        "text": "Marinduque"
      },
      {
        "text": "Masbate"
      },
      {
        "text": "Metro Manila"
      },
      {
        "text": "Mimaropa"
      },
      {
        "text": "Misamis Occidental"
      },
      {
        "text": "Misamis Oriental"
      },
      {
        "text": "Mountain Province"
      },
      {
        "text": "Negros Occidental"
      },
      {
        "text": "Negros Oriental"
      },
      {
        "text": "Northern Mindanao"
      },
      {
        "text": "Northern Samar"
      },
      {
        "text": "Nueva Ecija"
      },
      {
        "text": "Nueva Vizcaya"
      },
      {
        "text": "Occidental Mindoro"
      },
      {
        "text": "Oriental Mindoro"
      },
      {
        "text": "Palawan"
      },
      {
        "text": "Pampanga"
      },
      {
        "text": "Pangasinan"
      },
      {
        "text": "Quezon"
      },
      {
        "text": "Quirino"
      },
      {
        "text": "Rizal"
      },
      {
        "text": "Romblon"
      },
      {
        "text": "Sarangani"
      },
      {
        "text": "Siquijor"
      },
      {
        "text": "Soccsksargen"
      },
      {
        "text": "Sorsogon"
      },
      {
        "text": "South Cotabato"
      },
      {
        "text": "Southern Leyte"
      },
      {
        "text": "Sultan Kudarat"
      },
      {
        "text": "Sulu"
      },
      {
        "text": "Surigao del Norte"
      },
      {
        "text": "Surigao del Sur"
      },
      {
        "text": "Tarlac"
      },
      {
        "text": "Tawi-Tawi"
      },
      {
        "text": "Western Visayas"
      },
      {
        "text": "Zambales"
      },
      {
        "text": "Zamboanga Peninsula"
      },
      {
        "text": "Zamboanga Sibugay"
      },
      {
        "text": "Zamboanga del Norte"
      },
      {
        "text": "Zamboanga del Sur"
      }
    ],
    "code": "+63"
  },
  {
    "text": "Pitcairn Island",
    "isoCode": "PN",
    "states": []
  },
  {
    "text": "Poland",
    "isoCode": "PL",
    "states": [
      {
        "text": "Greater Poland Voivodeship"
      },
      {
        "text": "Kuyavian-Pomeranian Voivodeship"
      },
      {
        "text": "Lesser Poland Voivodeship"
      },
      {
        "text": "Lower Silesian Voivodeship"
      },
      {
        "text": "Lublin Voivodeship"
      },
      {
        "text": "Lubusz Voivodeship"
      },
      {
        "text": "Masovian Voivodeship"
      },
      {
        "text": "Opole Voivodeship"
      },
      {
        "text": "Podkarpackie Voivodeship"
      },
      {
        "text": "Podlaskie Voivodeship"
      },
      {
        "text": "Pomeranian Voivodeship"
      },
      {
        "text": "Silesian Voivodeship"
      },
      {
        "text": "Warmian-Masurian Voivodeship"
      },
      {
        "text": "West Pomeranian Voivodeship"
      },
      {
        "text": "Łódź Voivodeship"
      },
      {
        "text": "Świętokrzyskie Voivodeship"
      }
    ],
    "code": "+48"
  },
  {
    "text": "Portugal",
    "isoCode": "PT",
    "states": [
      {
        "text": "Aveiro District"
      },
      {
        "text": "Azores"
      },
      {
        "text": "Beja District"
      },
      {
        "text": "Braga District"
      },
      {
        "text": "Bragança District"
      },
      {
        "text": "Castelo Branco District"
      },
      {
        "text": "Coimbra District"
      },
      {
        "text": "Faro District"
      },
      {
        "text": "Guarda District"
      },
      {
        "text": "Leiria District"
      },
      {
        "text": "Lisbon District"
      },
      {
        "text": "Madeira"
      },
      {
        "text": "Portalegre District"
      },
      {
        "text": "Porto District"
      },
      {
        "text": "Santarém District"
      },
      {
        "text": "Setúbal District"
      },
      {
        "text": "Viana do Castelo District"
      },
      {
        "text": "Vila Real District"
      },
      {
        "text": "Viseu District"
      },
      {
        "text": "Évora District"
      }
    ],
    "code": "+351"
  },
  {
    "text": "Puerto Rico",
    "isoCode": "PR",
    "states": []
  },
  {
    "text": "Qatar",
    "isoCode": "QA",
    "states": [
      {
        "text": "Al Daayen"
      },
      {
        "text": "Al Khor"
      },
      {
        "text": "Al Rayyan Municipality"
      },
      {
        "text": "Al Wakrah"
      },
      {
        "text": "Al-Shahaniya"
      },
      {
        "text": "Doha"
      },
      {
        "text": "Madinat ash Shamal"
      },
      {
        "text": "Umm Salal Municipality"
      }
    ],
    "code": "+974"
  },
  {
    "text": "Reunion",
    "isoCode": "RE",
    "states": []
  },
  {
    "text": "Romania",
    "isoCode": "RO",
    "states": [
      {
        "text": "Alba"
      },
      {
        "text": "Arad County"
      },
      {
        "text": "Arges"
      },
      {
        "text": "Bacău County"
      },
      {
        "text": "Bihor County"
      },
      {
        "text": "Bistrița-Năsăud County"
      },
      {
        "text": "Botoșani County"
      },
      {
        "text": "Braila"
      },
      {
        "text": "Brașov County"
      },
      {
        "text": "Bucharest"
      },
      {
        "text": "Buzău County"
      },
      {
        "text": "Caraș-Severin County"
      },
      {
        "text": "Cluj County"
      },
      {
        "text": "Constanța County"
      },
      {
        "text": "Covasna County"
      },
      {
        "text": "Călărași County"
      },
      {
        "text": "Dolj County"
      },
      {
        "text": "Dâmbovița County"
      },
      {
        "text": "Galați County"
      },
      {
        "text": "Giurgiu County"
      },
      {
        "text": "Gorj County"
      },
      {
        "text": "Harghita County"
      },
      {
        "text": "Hunedoara County"
      },
      {
        "text": "Ialomița County"
      },
      {
        "text": "Iași County"
      },
      {
        "text": "Ilfov County"
      },
      {
        "text": "Maramureș County"
      },
      {
        "text": "Mehedinți County"
      },
      {
        "text": "Mureș County"
      },
      {
        "text": "Neamț County"
      },
      {
        "text": "Olt County"
      },
      {
        "text": "Prahova County"
      },
      {
        "text": "Satu Mare County"
      },
      {
        "text": "Sibiu County"
      },
      {
        "text": "Suceava County"
      },
      {
        "text": "Sălaj County"
      },
      {
        "text": "Teleorman County"
      },
      {
        "text": "Timiș County"
      },
      {
        "text": "Tulcea County"
      },
      {
        "text": "Vaslui County"
      },
      {
        "text": "Vrancea County"
      },
      {
        "text": "Vâlcea County"
      }
    ],
    "code": "+40"
  },
  {
    "text": "Russia",
    "isoCode": "RU",
    "states": [
      {
        "text": "Altai Krai"
      },
      {
        "text": "Altai Republic"
      },
      {
        "text": "Amur Oblast"
      },
      {
        "text": "Arkhangelsk"
      },
      {
        "text": "Astrakhan Oblast"
      },
      {
        "text": "Belgorod Oblast"
      },
      {
        "text": "Bryansk Oblast"
      },
      {
        "text": "Chechen Republic"
      },
      {
        "text": "Chelyabinsk Oblast"
      },
      {
        "text": "Chukotka Autonomous Okrug"
      },
      {
        "text": "Chuvash Republic"
      },
      {
        "text": "Irkutsk"
      },
      {
        "text": "Ivanovo Oblast"
      },
      {
        "text": "Jewish Autonomous Oblast"
      },
      {
        "text": "Kabardino-Balkar Republic"
      },
      {
        "text": "Kaliningrad"
      },
      {
        "text": "Kaluga Oblast"
      },
      {
        "text": "Kamchatka Krai"
      },
      {
        "text": "Karachay-Cherkess Republic"
      },
      {
        "text": "Kemerovo Oblast"
      },
      {
        "text": "Khabarovsk Krai"
      },
      {
        "text": "Khanty-Mansi Autonomous Okrug"
      },
      {
        "text": "Kirov Oblast"
      },
      {
        "text": "Komi Republic"
      },
      {
        "text": "Kostroma Oblast"
      },
      {
        "text": "Krasnodar Krai"
      },
      {
        "text": "Krasnoyarsk Krai"
      },
      {
        "text": "Kurgan Oblast"
      },
      {
        "text": "Kursk Oblast"
      },
      {
        "text": "Leningrad Oblast"
      },
      {
        "text": "Lipetsk Oblast"
      },
      {
        "text": "Magadan Oblast"
      },
      {
        "text": "Mari El Republic"
      },
      {
        "text": "Moscow"
      },
      {
        "text": "Moscow Oblast"
      },
      {
        "text": "Murmansk Oblast"
      },
      {
        "text": "Nenets Autonomous Okrug"
      },
      {
        "text": "Nizhny Novgorod Oblast"
      },
      {
        "text": "Novgorod Oblast"
      },
      {
        "text": "Novosibirsk"
      },
      {
        "text": "Omsk Oblast"
      },
      {
        "text": "Orenburg Oblast"
      },
      {
        "text": "Oryol Oblast"
      },
      {
        "text": "Penza Oblast"
      },
      {
        "text": "Perm Krai"
      },
      {
        "text": "Primorsky Krai"
      },
      {
        "text": "Pskov Oblast"
      },
      {
        "text": "Republic of Adygea"
      },
      {
        "text": "Republic of Bashkortostan"
      },
      {
        "text": "Republic of Buryatia"
      },
      {
        "text": "Republic of Dagestan"
      },
      {
        "text": "Republic of Ingushetia"
      },
      {
        "text": "Republic of Kalmykia"
      },
      {
        "text": "Republic of Karelia"
      },
      {
        "text": "Republic of Khakassia"
      },
      {
        "text": "Republic of Mordovia"
      },
      {
        "text": "Republic of North Ossetia-Alania"
      },
      {
        "text": "Republic of Tatarstan"
      },
      {
        "text": "Rostov Oblast"
      },
      {
        "text": "Ryazan Oblast"
      },
      {
        "text": "Saint Petersburg"
      },
      {
        "text": "Sakha Republic"
      },
      {
        "text": "Sakhalin"
      },
      {
        "text": "Samara Oblast"
      },
      {
        "text": "Saratov Oblast"
      },
      {
        "text": "Sevastopol"
      },
      {
        "text": "Smolensk Oblast"
      },
      {
        "text": "Stavropol Krai"
      },
      {
        "text": "Sverdlovsk"
      },
      {
        "text": "Tambov Oblast"
      },
      {
        "text": "Tomsk Oblast"
      },
      {
        "text": "Tula Oblast"
      },
      {
        "text": "Tuva Republic"
      },
      {
        "text": "Tver Oblast"
      },
      {
        "text": "Tyumen Oblast"
      },
      {
        "text": "Udmurt Republic"
      },
      {
        "text": "Ulyanovsk Oblast"
      },
      {
        "text": "Vladimir Oblast"
      },
      {
        "text": "Volgograd Oblast"
      },
      {
        "text": "Vologda Oblast"
      },
      {
        "text": "Voronezh Oblast"
      },
      {
        "text": "Yamalo-Nenets Autonomous Okrug"
      },
      {
        "text": "Yaroslavl Oblast"
      },
      {
        "text": "Zabaykalsky Krai"
      }
    ],
    "code": "+7"
  },
  {
    "text": "Rwanda",
    "isoCode": "RW",
    "states": [
      {
        "text": "Eastern Province"
      },
      {
        "text": "Kigali district"
      },
      {
        "text": "Northern Province"
      },
      {
        "text": "Southern Province"
      },
      {
        "text": "Western Province"
      }
    ],
    "code": "+250"
  },
  {
    "text": "Saint Helena",
    "isoCode": "SH",
    "states": []
  },
  {
    "text": "Saint Kitts And Nevis",
    "isoCode": "KN",
    "states": [
      {
        "text": "Christ Church Nichola Town Parish"
      },
      {
        "text": "Nevis"
      },
      {
        "text": "Saint Anne Sandy Point Parish"
      },
      {
        "text": "Saint George Gingerland Parish"
      },
      {
        "text": "Saint James Windward Parish"
      },
      {
        "text": "Saint John Capisterre Parish"
      },
      {
        "text": "Saint John Figtree Parish"
      },
      {
        "text": "Saint Kitts"
      },
      {
        "text": "Saint Mary Cayon Parish"
      },
      {
        "text": "Saint Paul Capisterre Parish"
      },
      {
        "text": "Saint Paul Charlestown Parish"
      },
      {
        "text": "Saint Peter Basseterre Parish"
      },
      {
        "text": "Saint Thomas Lowland Parish"
      },
      {
        "text": "Saint Thomas Middle Island Parish"
      },
      {
        "text": "Trinity Palmetto Point Parish"
      }
    ]
  },
  {
    "text": "Saint Lucia",
    "isoCode": "LC",
    "states": [
      {
        "text": "Anse la Raye Quarter"
      },
      {
        "text": "Canaries"
      },
      {
        "text": "Castries Quarter"
      },
      {
        "text": "Choiseul Quarter"
      },
      {
        "text": "Dauphin Quarter"
      },
      {
        "text": "Dennery Quarter"
      },
      {
        "text": "Gros Islet Quarter"
      },
      {
        "text": "Laborie Quarter"
      },
      {
        "text": "Micoud Quarter"
      },
      {
        "text": "Praslin Quarter"
      },
      {
        "text": "Soufrière Quarter"
      },
      {
        "text": "Vieux Fort Quarter"
      }
    ],
    "code": "+1"
  },
  {
    "text": "Saint Pierre and Miquelon",
    "isoCode": "PM",
    "states": []
  },
  {
    "text": "Saint Vincent And The Grenadines",
    "isoCode": "VC",
    "states": [
      {
        "text": "Charlotte Parish"
      },
      {
        "text": "Grenadines Parish"
      },
      {
        "text": "Saint Andrew Parish"
      },
      {
        "text": "Saint David Parish"
      },
      {
        "text": "Saint George Parish"
      },
      {
        "text": "Saint Patrick Parish"
      }
    ]
  },
  {
    "text": "Saint-Barthelemy",
    "isoCode": "BL",
    "states": []
  },
  {
    "text": "Saint-Martin (French part)",
    "isoCode": "MF",
    "states": []
  },
  {
    "text": "Samoa",
    "isoCode": "WS",
    "states": [
      {
        "text": "A'ana"
      },
      {
        "text": "Aiga-i-le-Tai"
      },
      {
        "text": "Atua"
      },
      {
        "text": "Fa'asaleleaga"
      },
      {
        "text": "Gaga'emauga"
      },
      {
        "text": "Gaga'ifomauga"
      },
      {
        "text": "Palauli"
      },
      {
        "text": "Satupa'itea"
      },
      {
        "text": "Tuamasaga"
      },
      {
        "text": "Va'a-o-Fonoti"
      },
      {
        "text": "Vaisigano"
      }
    ],
    "code": "+685"
  },
  {
    "text": "San Marino",
    "isoCode": "SM",
    "states": [
      {
        "text": "Acquaviva"
      },
      {
        "text": "Borgo Maggiore"
      },
      {
        "text": "Chiesanuova"
      },
      {
        "text": "Domagnano"
      },
      {
        "text": "Faetano"
      },
      {
        "text": "Fiorentino"
      },
      {
        "text": "Montegiardino"
      },
      {
        "text": "San Marino"
      },
      {
        "text": "Serravalle"
      }
    ],
    "code": "+378"
  },
  {
    "text": "Sao Tome and Principe",
    "isoCode": "ST",
    "states": [
      {
        "text": "Príncipe Province"
      },
      {
        "text": "São Tomé Province"
      }
    ],
    "code": "+239"
  },
  {
    "text": "Saudi Arabia",
    "isoCode": "SA",
    "states": [
      {
        "text": "'Asir Region"
      },
      {
        "text": "Al Bahah Region"
      },
      {
        "text": "Al Jawf Region"
      },
      {
        "text": "Al Madinah Region"
      },
      {
        "text": "Al-Qassim Region"
      },
      {
        "text": "Eastern Province"
      },
      {
        "text": "Ha'il Region"
      },
      {
        "text": "Jizan Region"
      },
      {
        "text": "Makkah Region"
      },
      {
        "text": "Najran Region"
      },
      {
        "text": "Northern Borders Region"
      },
      {
        "text": "Riyadh Region"
      },
      {
        "text": "Tabuk Region"
      }
    ],
    "code": "+966"
  },
  {
    "text": "Senegal",
    "isoCode": "SN",
    "states": [
      {
        "text": "Dakar"
      },
      {
        "text": "Diourbel Region"
      },
      {
        "text": "Fatick"
      },
      {
        "text": "Kaffrine"
      },
      {
        "text": "Kaolack"
      },
      {
        "text": "Kolda"
      },
      {
        "text": "Kédougou"
      },
      {
        "text": "Louga"
      },
      {
        "text": "Matam"
      },
      {
        "text": "Saint-Louis"
      },
      {
        "text": "Sédhiou"
      },
      {
        "text": "Tambacounda Region"
      },
      {
        "text": "Thiès Region"
      },
      {
        "text": "Ziguinchor"
      }
    ],
    "code": "+221"
  },
  {
    "text": "Serbia",
    "isoCode": "RS",
    "states": [
      {
        "text": "Belgrade"
      },
      {
        "text": "Bor District"
      },
      {
        "text": "Braničevo District"
      },
      {
        "text": "Central Banat District"
      },
      {
        "text": "Jablanica District"
      },
      {
        "text": "Kolubara District"
      },
      {
        "text": "Mačva District"
      },
      {
        "text": "Moravica District"
      },
      {
        "text": "Nišava District"
      },
      {
        "text": "North Banat District"
      },
      {
        "text": "North Bačka District"
      },
      {
        "text": "Pirot District"
      },
      {
        "text": "Podunavlje District"
      },
      {
        "text": "Pomoravlje District"
      },
      {
        "text": "Pčinja District"
      },
      {
        "text": "Rasina District"
      },
      {
        "text": "Raška District"
      },
      {
        "text": "South Banat District"
      },
      {
        "text": "South Bačka District"
      },
      {
        "text": "Srem District"
      },
      {
        "text": "Toplica District"
      },
      {
        "text": "Vojvodina"
      },
      {
        "text": "West Bačka District"
      },
      {
        "text": "Zaječar District"
      },
      {
        "text": "Zlatibor District"
      },
      {
        "text": "Šumadija District"
      }
    ],
    "code": "+381"
  },
  {
    "text": "Seychelles",
    "isoCode": "SC",
    "states": [
      {
        "text": "Anse Boileau"
      },
      {
        "text": "Anse Royale"
      },
      {
        "text": "Anse-aux-Pins"
      },
      {
        "text": "Au Cap"
      },
      {
        "text": "Baie Lazare"
      },
      {
        "text": "Baie Sainte Anne"
      },
      {
        "text": "Beau Vallon"
      },
      {
        "text": "Bel Air"
      },
      {
        "text": "Bel Ombre"
      },
      {
        "text": "Cascade"
      },
      {
        "text": "Glacis"
      },
      {
        "text": "Grand'Anse Mahé"
      },
      {
        "text": "Grand'Anse Praslin"
      },
      {
        "text": "La Digue"
      },
      {
        "text": "La Rivière Anglaise"
      },
      {
        "text": "Les Mamelles"
      },
      {
        "text": "Mont Buxton"
      },
      {
        "text": "Mont Fleuri"
      },
      {
        "text": "Plaisance"
      },
      {
        "text": "Pointe La Rue"
      },
      {
        "text": "Port Glaud"
      },
      {
        "text": "Roche Caiman"
      },
      {
        "text": "Saint Louis"
      },
      {
        "text": "Takamaka"
      }
    ],
    "code": "+248"
  },
  {
    "text": "Sierra Leone",
    "isoCode": "SL",
    "states": [
      {
        "text": "Eastern Province"
      },
      {
        "text": "Northern Province"
      },
      {
        "text": "Southern Province"
      },
      {
        "text": "Western Area"
      }
    ],
    "code": "+232"
  },
  {
    "text": "Singapore",
    "isoCode": "SG",
    "states": [
      {
        "text": "Central Singapore Community Development Council"
      },
      {
        "text": "North East Community Development Council"
      },
      {
        "text": "North West Community Development Council"
      },
      {
        "text": "South East Community Development Council"
      },
      {
        "text": "South West Community Development Council"
      }
    ],
    "code": "+65"
  },
  {
    "text": "Slovakia",
    "isoCode": "SK",
    "states": [
      {
        "text": "Banská Bystrica Region"
      },
      {
        "text": "Bratislava Region"
      },
      {
        "text": "Košice Region"
      },
      {
        "text": "Nitra Region"
      },
      {
        "text": "Prešov Region"
      },
      {
        "text": "Trenčín Region"
      },
      {
        "text": "Trnava Region"
      },
      {
        "text": "Žilina Region"
      }
    ],
    "code": "+421"
  },
  {
    "text": "Slovenia",
    "isoCode": "SI",
    "states": [
      {
        "text": "Ajdovščina Municipality"
      },
      {
        "text": "Ankaran Municipality"
      },
      {
        "text": "Beltinci Municipality"
      },
      {
        "text": "Benedikt Municipality"
      },
      {
        "text": "Bistrica ob Sotli Municipality"
      },
      {
        "text": "Bled Municipality"
      },
      {
        "text": "Bloke Municipality"
      },
      {
        "text": "Bohinj Municipality"
      },
      {
        "text": "Borovnica Municipality"
      },
      {
        "text": "Bovec Municipality"
      },
      {
        "text": "Braslovče Municipality"
      },
      {
        "text": "Brda Municipality"
      },
      {
        "text": "Brezovica Municipality"
      },
      {
        "text": "Brežice Municipality"
      },
      {
        "text": "Cankova Municipality"
      },
      {
        "text": "Cerklje na Gorenjskem Municipality"
      },
      {
        "text": "Cerknica Municipality"
      },
      {
        "text": "Cerkno Municipality"
      },
      {
        "text": "Cerkvenjak Municipality"
      },
      {
        "text": "City Municipality of Celje"
      },
      {
        "text": "City Municipality of Novo Mesto"
      },
      {
        "text": "Destrnik Municipality"
      },
      {
        "text": "Divača Municipality"
      },
      {
        "text": "Dobje Municipality"
      },
      {
        "text": "Dobrepolje Municipality"
      },
      {
        "text": "Dobrna Municipality"
      },
      {
        "text": "Dobrova–Polhov Gradec Municipality"
      },
      {
        "text": "Dobrovnik Municipality"
      },
      {
        "text": "Dol pri Ljubljani Municipality"
      },
      {
        "text": "Dolenjske Toplice Municipality"
      },
      {
        "text": "Domžale Municipality"
      },
      {
        "text": "Dornava Municipality"
      },
      {
        "text": "Dravograd Municipality"
      },
      {
        "text": "Duplek Municipality"
      },
      {
        "text": "Gorenja Vas–Poljane Municipality"
      },
      {
        "text": "Gorišnica Municipality"
      },
      {
        "text": "Gorje Municipality"
      },
      {
        "text": "Gornja Radgona Municipality"
      },
      {
        "text": "Gornji Grad Municipality"
      },
      {
        "text": "Gornji Petrovci Municipality"
      },
      {
        "text": "Grad Municipality"
      },
      {
        "text": "Grosuplje Municipality"
      },
      {
        "text": "Hajdina Municipality"
      },
      {
        "text": "Hodoš Municipality"
      },
      {
        "text": "Horjul Municipality"
      },
      {
        "text": "Hoče–Slivnica Municipality"
      },
      {
        "text": "Hrastnik Municipality"
      },
      {
        "text": "Hrpelje–Kozina Municipality"
      },
      {
        "text": "Idrija Municipality"
      },
      {
        "text": "Ig Municipality"
      },
      {
        "text": "Ivančna Gorica Municipality"
      },
      {
        "text": "Izola Municipality"
      },
      {
        "text": "Jesenice Municipality"
      },
      {
        "text": "Jezersko Municipality"
      },
      {
        "text": "Juršinci Municipality"
      },
      {
        "text": "Kamnik Municipality"
      },
      {
        "text": "Kanal ob Soči Municipality"
      },
      {
        "text": "Kidričevo Municipality"
      },
      {
        "text": "Kobarid Municipality"
      },
      {
        "text": "Kobilje Municipality"
      },
      {
        "text": "Komen Municipality"
      },
      {
        "text": "Komenda Municipality"
      },
      {
        "text": "Koper City Municipality"
      },
      {
        "text": "Kostanjevica na Krki Municipality"
      },
      {
        "text": "Kostel Municipality"
      },
      {
        "text": "Kozje Municipality"
      },
      {
        "text": "Kočevje Municipality"
      },
      {
        "text": "Kranj City Municipality"
      },
      {
        "text": "Kranjska Gora Municipality"
      },
      {
        "text": "Križevci Municipality"
      },
      {
        "text": "Kungota"
      },
      {
        "text": "Kuzma Municipality"
      },
      {
        "text": "Laško Municipality"
      },
      {
        "text": "Lenart Municipality"
      },
      {
        "text": "Lendava Municipality"
      },
      {
        "text": "Litija Municipality"
      },
      {
        "text": "Ljubljana City Municipality"
      },
      {
        "text": "Ljubno Municipality"
      },
      {
        "text": "Ljutomer Municipality"
      },
      {
        "text": "Logatec Municipality"
      },
      {
        "text": "Log–Dragomer Municipality"
      },
      {
        "text": "Lovrenc na Pohorju Municipality"
      },
      {
        "text": "Loška Dolina Municipality"
      },
      {
        "text": "Loški Potok Municipality"
      },
      {
        "text": "Lukovica Municipality"
      },
      {
        "text": "Luče Municipality"
      },
      {
        "text": "Majšperk Municipality"
      },
      {
        "text": "Makole Municipality"
      },
      {
        "text": "Maribor City Municipality"
      },
      {
        "text": "Markovci Municipality"
      },
      {
        "text": "Medvode Municipality"
      },
      {
        "text": "Mengeš Municipality"
      },
      {
        "text": "Metlika Municipality"
      },
      {
        "text": "Mežica Municipality"
      },
      {
        "text": "Miklavž na Dravskem Polju Municipality"
      },
      {
        "text": "Miren–Kostanjevica Municipality"
      },
      {
        "text": "Mirna Municipality"
      },
      {
        "text": "Mirna Peč Municipality"
      },
      {
        "text": "Mislinja Municipality"
      },
      {
        "text": "Mokronog–Trebelno Municipality"
      },
      {
        "text": "Moravske Toplice Municipality"
      },
      {
        "text": "Moravče Municipality"
      },
      {
        "text": "Mozirje Municipality"
      },
      {
        "text": "Municipality of Apače"
      },
      {
        "text": "Municipality of Cirkulane"
      },
      {
        "text": "Municipality of Ilirska Bistrica"
      },
      {
        "text": "Municipality of Krško"
      },
      {
        "text": "Municipality of Škofljica"
      },
      {
        "text": "Murska Sobota City Municipality"
      },
      {
        "text": "Muta Municipality"
      },
      {
        "text": "Naklo Municipality"
      },
      {
        "text": "Nazarje Municipality"
      },
      {
        "text": "Nova Gorica City Municipality"
      },
      {
        "text": "Odranci Municipality"
      },
      {
        "text": "Oplotnica"
      },
      {
        "text": "Ormož Municipality"
      },
      {
        "text": "Osilnica Municipality"
      },
      {
        "text": "Pesnica Municipality"
      },
      {
        "text": "Piran Municipality"
      },
      {
        "text": "Pivka Municipality"
      },
      {
        "text": "Podlehnik Municipality"
      },
      {
        "text": "Podvelka Municipality"
      },
      {
        "text": "Podčetrtek Municipality"
      },
      {
        "text": "Poljčane Municipality"
      },
      {
        "text": "Polzela Municipality"
      },
      {
        "text": "Postojna Municipality"
      },
      {
        "text": "Prebold Municipality"
      },
      {
        "text": "Preddvor Municipality"
      },
      {
        "text": "Prevalje Municipality"
      },
      {
        "text": "Ptuj City Municipality"
      },
      {
        "text": "Puconci Municipality"
      },
      {
        "text": "Radenci Municipality"
      },
      {
        "text": "Radeče Municipality"
      },
      {
        "text": "Radlje ob Dravi Municipality"
      },
      {
        "text": "Radovljica Municipality"
      },
      {
        "text": "Ravne na Koroškem Municipality"
      },
      {
        "text": "Razkrižje Municipality"
      },
      {
        "text": "Rače–Fram Municipality"
      },
      {
        "text": "Renče–Vogrsko Municipality"
      },
      {
        "text": "Rečica ob Savinji Municipality"
      },
      {
        "text": "Ribnica Municipality"
      },
      {
        "text": "Ribnica na Pohorju Municipality"
      },
      {
        "text": "Rogatec Municipality"
      },
      {
        "text": "Rogaška Slatina Municipality"
      },
      {
        "text": "Rogašovci Municipality"
      },
      {
        "text": "Ruše Municipality"
      },
      {
        "text": "Selnica ob Dravi Municipality"
      },
      {
        "text": "Semič Municipality"
      },
      {
        "text": "Sevnica Municipality"
      },
      {
        "text": "Sežana Municipality"
      },
      {
        "text": "Slovenj Gradec City Municipality"
      },
      {
        "text": "Slovenska Bistrica Municipality"
      },
      {
        "text": "Slovenske Konjice Municipality"
      },
      {
        "text": "Sodražica Municipality"
      },
      {
        "text": "Solčava Municipality"
      },
      {
        "text": "Središče ob Dravi"
      },
      {
        "text": "Starše Municipality"
      },
      {
        "text": "Straža Municipality"
      },
      {
        "text": "Sveta Ana Municipality"
      },
      {
        "text": "Sveta Trojica v Slovenskih Goricah Municipality"
      },
      {
        "text": "Sveti Andraž v Slovenskih Goricah Municipality"
      },
      {
        "text": "Sveti Jurij ob Ščavnici Municipality"
      },
      {
        "text": "Sveti Jurij v Slovenskih Goricah Municipality"
      },
      {
        "text": "Sveti Tomaž Municipality"
      },
      {
        "text": "Tabor Municipality"
      },
      {
        "text": "Tišina Municipality"
      },
      {
        "text": "Tolmin Municipality"
      },
      {
        "text": "Trbovlje Municipality"
      },
      {
        "text": "Trebnje Municipality"
      },
      {
        "text": "Trnovska Vas Municipality"
      },
      {
        "text": "Trzin Municipality"
      },
      {
        "text": "Tržič Municipality"
      },
      {
        "text": "Turnišče Municipality"
      },
      {
        "text": "Velika Polana Municipality"
      },
      {
        "text": "Velike Lašče Municipality"
      },
      {
        "text": "Veržej Municipality"
      },
      {
        "text": "Videm Municipality"
      },
      {
        "text": "Vipava Municipality"
      },
      {
        "text": "Vitanje Municipality"
      },
      {
        "text": "Vodice Municipality"
      },
      {
        "text": "Vojnik Municipality"
      },
      {
        "text": "Vransko Municipality"
      },
      {
        "text": "Vrhnika Municipality"
      },
      {
        "text": "Vuzenica Municipality"
      },
      {
        "text": "Zagorje ob Savi Municipality"
      },
      {
        "text": "Zavrč Municipality"
      },
      {
        "text": "Zreče Municipality"
      },
      {
        "text": "Črenšovci Municipality"
      },
      {
        "text": "Črna na Koroškem Municipality"
      },
      {
        "text": "Črnomelj Municipality"
      },
      {
        "text": "Šalovci Municipality"
      },
      {
        "text": "Šempeter–Vrtojba Municipality"
      },
      {
        "text": "Šentilj Municipality"
      },
      {
        "text": "Šentjernej Municipality"
      },
      {
        "text": "Šentjur Municipality"
      },
      {
        "text": "Šentrupert Municipality"
      },
      {
        "text": "Šenčur Municipality"
      },
      {
        "text": "Škocjan Municipality"
      },
      {
        "text": "Škofja Loka Municipality"
      },
      {
        "text": "Šmarje pri Jelšah Municipality"
      },
      {
        "text": "Šmarješke Toplice Municipality"
      },
      {
        "text": "Šmartno ob Paki Municipality"
      },
      {
        "text": "Šmartno pri Litiji Municipality"
      },
      {
        "text": "Šoštanj Municipality"
      },
      {
        "text": "Štore Municipality"
      },
      {
        "text": "Žalec Municipality"
      },
      {
        "text": "Železniki Municipality"
      },
      {
        "text": "Žetale Municipality"
      },
      {
        "text": "Žiri Municipality"
      },
      {
        "text": "Žirovnica Municipality"
      },
      {
        "text": "Žužemberk Municipality"
      }
    ],
    "code": "+386"
  },
  {
    "text": "Solomon Islands",
    "isoCode": "SB",
    "states": [
      {
        "text": "Central Province"
      },
      {
        "text": "Choiseul Province"
      },
      {
        "text": "Guadalcanal Province"
      },
      {
        "text": "Honiara"
      },
      {
        "text": "Isabel Province"
      },
      {
        "text": "Makira-Ulawa Province"
      },
      {
        "text": "Malaita Province"
      },
      {
        "text": "Rennell and Bellona Province"
      },
      {
        "text": "Temotu Province"
      },
      {
        "text": "Western Province"
      }
    ],
    "code": "+677"
  },
  {
    "text": "Somalia",
    "isoCode": "SO",
    "states": [
      {
        "text": "Awdal Region"
      },
      {
        "text": "Bakool"
      },
      {
        "text": "Banaadir"
      },
      {
        "text": "Bari"
      },
      {
        "text": "Bay"
      },
      {
        "text": "Galguduud"
      },
      {
        "text": "Gedo"
      },
      {
        "text": "Hiran"
      },
      {
        "text": "Lower Juba"
      },
      {
        "text": "Lower Shebelle"
      },
      {
        "text": "Middle Juba"
      },
      {
        "text": "Middle Shebelle"
      },
      {
        "text": "Mudug"
      },
      {
        "text": "Nugal"
      },
      {
        "text": "Sanaag Region"
      },
      {
        "text": "Togdheer Region"
      }
    ],
    "code": "+252"
  },
  {
    "text": "South Africa",
    "isoCode": "ZA",
    "states": [
      {
        "text": "Eastern Cape"
      },
      {
        "text": "Free State"
      },
      {
        "text": "Gauteng"
      },
      {
        "text": "KwaZulu-Natal"
      },
      {
        "text": "Limpopo"
      },
      {
        "text": "Mpumalanga"
      },
      {
        "text": "North West"
      },
      {
        "text": "Northern Cape"
      },
      {
        "text": "Western Cape"
      }
    ],
    "code": "+27"
  },
  {
    "text": "South Georgia",
    "isoCode": "GS",
    "states": []
  },
  {
    "text": "South Sudan",
    "isoCode": "SS",
    "states": [
      {
        "text": "Central Equatoria"
      },
      {
        "text": "Eastern Equatoria"
      },
      {
        "text": "Jonglei State"
      },
      {
        "text": "Lakes"
      },
      {
        "text": "Northern Bahr el Ghazal"
      },
      {
        "text": "Unity"
      },
      {
        "text": "Upper Nile"
      },
      {
        "text": "Warrap"
      },
      {
        "text": "Western Bahr el Ghazal"
      },
      {
        "text": "Western Equatoria"
      }
    ],
    "code": "+211"
  },
  {
    "text": "Spain",
    "isoCode": "ES",
    "states": [
      {
        "text": "Andalusia"
      },
      {
        "text": "Aragon"
      },
      {
        "text": "Asturias"
      },
      {
        "text": "Balearic Islands"
      },
      {
        "text": "Basque Country"
      },
      {
        "text": "Burgos Province"
      },
      {
        "text": "Canary Islands"
      },
      {
        "text": "Cantabria"
      },
      {
        "text": "Castile and León"
      },
      {
        "text": "Castilla La Mancha"
      },
      {
        "text": "Catalonia"
      },
      {
        "text": "Ceuta"
      },
      {
        "text": "Extremadura"
      },
      {
        "text": "Galicia"
      },
      {
        "text": "La Rioja"
      },
      {
        "text": "Léon"
      },
      {
        "text": "Madrid"
      },
      {
        "text": "Melilla"
      },
      {
        "text": "Murcia"
      },
      {
        "text": "Navarra"
      },
      {
        "text": "Palencia Province"
      },
      {
        "text": "Salamanca Province"
      },
      {
        "text": "Segovia Province"
      },
      {
        "text": "Soria Province"
      },
      {
        "text": "Valencia"
      },
      {
        "text": "Valladolid Province"
      },
      {
        "text": "Zamora Province"
      },
      {
        "text": "Ávila"
      }
    ],
    "code": "+34"
  },
  {
    "text": "Sri Lanka",
    "isoCode": "LK",
    "states": [
      {
        "text": "Ampara District"
      },
      {
        "text": "Anuradhapura District"
      },
      {
        "text": "Badulla District"
      },
      {
        "text": "Batticaloa District"
      },
      {
        "text": "Central Province"
      },
      {
        "text": "Colombo District"
      },
      {
        "text": "Eastern Province"
      },
      {
        "text": "Galle District"
      },
      {
        "text": "Gampaha District"
      },
      {
        "text": "Hambantota District"
      },
      {
        "text": "Jaffna District"
      },
      {
        "text": "Kalutara District"
      },
      {
        "text": "Kandy District"
      },
      {
        "text": "Kegalle District"
      },
      {
        "text": "Kilinochchi District"
      },
      {
        "text": "Mannar District"
      },
      {
        "text": "Matale District"
      },
      {
        "text": "Matara District"
      },
      {
        "text": "Monaragala District"
      },
      {
        "text": "Mullaitivu District"
      },
      {
        "text": "North Central Province"
      },
      {
        "text": "North Western Province"
      },
      {
        "text": "Northern Province"
      },
      {
        "text": "Nuwara Eliya District"
      },
      {
        "text": "Polonnaruwa District"
      },
      {
        "text": "Puttalam District"
      },
      {
        "text": "Ratnapura district"
      },
      {
        "text": "Sabaragamuwa Province"
      },
      {
        "text": "Southern Province"
      },
      {
        "text": "Trincomalee District"
      },
      {
        "text": "Uva Province"
      },
      {
        "text": "Vavuniya District"
      },
      {
        "text": "Western Province"
      }
    ],
    "code": "+94"
  },
  {
    "text": "Sudan",
    "isoCode": "SD",
    "states": [
      {
        "text": "Al Jazirah"
      },
      {
        "text": "Al Qadarif"
      },
      {
        "text": "Blue Nile"
      },
      {
        "text": "Central Darfur"
      },
      {
        "text": "East Darfur"
      },
      {
        "text": "Kassala"
      },
      {
        "text": "Khartoum"
      },
      {
        "text": "North Darfur"
      },
      {
        "text": "North Kordofan"
      },
      {
        "text": "Northern"
      },
      {
        "text": "Red Sea"
      },
      {
        "text": "River Nile"
      },
      {
        "text": "Sennar"
      },
      {
        "text": "South Darfur"
      },
      {
        "text": "South Kordofan"
      },
      {
        "text": "West Darfur"
      },
      {
        "text": "West Kordofan"
      },
      {
        "text": "White Nile"
      }
    ],
    "code": "+249"
  },
  {
    "text": "Suriname",
    "isoCode": "SR",
    "states": [
      {
        "text": "Brokopondo District"
      },
      {
        "text": "Commewijne District"
      },
      {
        "text": "Coronie District"
      },
      {
        "text": "Marowijne District"
      },
      {
        "text": "Nickerie District"
      },
      {
        "text": "Para District"
      },
      {
        "text": "Paramaribo District"
      },
      {
        "text": "Saramacca District"
      },
      {
        "text": "Sipaliwini District"
      },
      {
        "text": "Wanica District"
      }
    ],
    "code": "+597"
  },
  {
    "text": "Svalbard And Jan Mayen Islands",
    "isoCode": "SJ",
    "states": []
  },
  {
    "text": "Swaziland",
    "isoCode": "SZ",
    "states": [
      {
        "text": "Hhohho District"
      },
      {
        "text": "Lubombo District"
      },
      {
        "text": "Manzini District"
      },
      {
        "text": "Shiselweni District"
      }
    ],
    "code": "+268"
  },
  {
    "text": "Sweden",
    "isoCode": "SE",
    "states": [
      {
        "text": "Blekinge"
      },
      {
        "text": "Dalarna County"
      },
      {
        "text": "Gotland County"
      },
      {
        "text": "Gävleborg County"
      },
      {
        "text": "Halland County"
      },
      {
        "text": "Jönköping County"
      },
      {
        "text": "Kalmar County"
      },
      {
        "text": "Kronoberg County"
      },
      {
        "text": "Norrbotten County"
      },
      {
        "text": "Skåne County"
      },
      {
        "text": "Stockholm County"
      },
      {
        "text": "Södermanland County"
      },
      {
        "text": "Uppsala County"
      },
      {
        "text": "Värmland County"
      },
      {
        "text": "Västerbotten County"
      },
      {
        "text": "Västernorrland County"
      },
      {
        "text": "Västmanland County"
      },
      {
        "text": "Västra Götaland County"
      },
      {
        "text": "Örebro County"
      },
      {
        "text": "Östergötland County"
      }
    ],
    "code": "+46"
  },
  {
    "text": "Switzerland",
    "isoCode": "CH",
    "states": [
      {
        "text": "Aargau"
      },
      {
        "text": "Appenzell Ausserrhoden"
      },
      {
        "text": "Appenzell Innerrhoden"
      },
      {
        "text": "Basel-Landschaft"
      },
      {
        "text": "Canton of Fribourg"
      },
      {
        "text": "Canton of Geneva"
      },
      {
        "text": "Canton of Jura"
      },
      {
        "text": "Canton of Lucerne"
      },
      {
        "text": "Canton of Neuchâtel"
      },
      {
        "text": "Canton of Schaffhausen"
      },
      {
        "text": "Canton of Solothurn"
      },
      {
        "text": "Canton of St. Gallen"
      },
      {
        "text": "Canton of Valais"
      },
      {
        "text": "Canton of Vaud"
      },
      {
        "text": "Canton of Zug"
      },
      {
        "text": "Glarus"
      },
      {
        "text": "Graubünden"
      },
      {
        "text": "Nidwalden"
      },
      {
        "text": "Obwalden"
      },
      {
        "text": "Schwyz"
      },
      {
        "text": "Thurgau"
      },
      {
        "text": "Ticino"
      },
      {
        "text": "Uri"
      },
      {
        "text": "canton of Bern"
      },
      {
        "text": "canton of Zürich"
      }
    ],
    "code": "+41"
  },
  {
    "text": "Syria",
    "isoCode": "SY",
    "states": [
      {
        "text": "Al-Hasakah Governorate"
      },
      {
        "text": "Al-Raqqah Governorate"
      },
      {
        "text": "Aleppo Governorate"
      },
      {
        "text": "As-Suwayda Governorate"
      },
      {
        "text": "Damascus Governorate"
      },
      {
        "text": "Daraa Governorate"
      },
      {
        "text": "Deir ez-Zor Governorate"
      },
      {
        "text": "Hama Governorate"
      },
      {
        "text": "Homs Governorate"
      },
      {
        "text": "Idlib Governorate"
      },
      {
        "text": "Latakia Governorate"
      },
      {
        "text": "Quneitra Governorate"
      },
      {
        "text": "Rif Dimashq Governorate"
      },
      {
        "text": "Tartus Governorate"
      }
    ],
    "code": "+963"
  },
  {
    "text": "Taiwan",
    "isoCode": "TW",
    "states": [
      {
        "text": "Changhua County"
      },
      {
        "text": "Chiayi City"
      },
      {
        "text": "Chiayi County"
      },
      {
        "text": "Hsinchu"
      },
      {
        "text": "Hsinchu County"
      },
      {
        "text": "Hualien County"
      },
      {
        "text": "Kaohsiung"
      },
      {
        "text": "Kaohsiung County"
      },
      {
        "text": "Kinmen"
      },
      {
        "text": "Lienchiang County"
      },
      {
        "text": "Miaoli County"
      },
      {
        "text": "Nantou County"
      },
      {
        "text": "Penghu County"
      },
      {
        "text": "Pingtung County"
      },
      {
        "text": "Taichung"
      },
      {
        "text": "Taichung County"
      },
      {
        "text": "Tainan"
      },
      {
        "text": "Tainan County"
      },
      {
        "text": "Taipei"
      },
      {
        "text": "Taitung County"
      },
      {
        "text": "Taoyuan City"
      },
      {
        "text": "Yilan County"
      },
      {
        "text": "Yunlin County"
      }
    ],
    "code": "+886"
  },
  {
    "text": "Tajikistan",
    "isoCode": "TJ",
    "states": [
      {
        "text": "Gorno-Badakhshan Autonomous Province"
      },
      {
        "text": "Khatlon Province"
      },
      {
        "text": "Sughd Province"
      },
      {
        "text": "districts of Republican Subordination"
      }
    ],
    "code": "+992"
  },
  {
    "text": "Tanzania",
    "isoCode": "TZ",
    "states": [
      {
        "text": "Arusha Region"
      },
      {
        "text": "Dar es Salaam Region"
      },
      {
        "text": "Dodoma Region"
      },
      {
        "text": "Geita Region"
      },
      {
        "text": "Iringa Region"
      },
      {
        "text": "Kagera Region"
      },
      {
        "text": "Katavi Region"
      },
      {
        "text": "Kigoma Region"
      },
      {
        "text": "Kilimanjaro Region"
      },
      {
        "text": "Lindi Region"
      },
      {
        "text": "Manyara Region"
      },
      {
        "text": "Mara Region"
      },
      {
        "text": "Morogoro Region"
      },
      {
        "text": "Mtwara Region"
      },
      {
        "text": "Mwanza Region"
      },
      {
        "text": "Njombe Region"
      },
      {
        "text": "North Pemba Region"
      },
      {
        "text": "Pwani Region"
      },
      {
        "text": "Rukwa Region"
      },
      {
        "text": "Ruvuma Region"
      },
      {
        "text": "Shinyanga Region"
      },
      {
        "text": "Simiyu Region"
      },
      {
        "text": "Singida Region"
      },
      {
        "text": "South Pemba Region"
      },
      {
        "text": "Tabora Region"
      },
      {
        "text": "Tanga Region"
      },
      {
        "text": "Zanzibar Central/South Region"
      },
      {
        "text": "Zanzibar North Region"
      },
      {
        "text": "Zanzibar Urban/West Region"
      }
    ],
    "code": "+255"
  },
  {
    "text": "Thailand",
    "isoCode": "TH",
    "states": [
      {
        "text": "Amnat Charoen"
      },
      {
        "text": "Ang Thong"
      },
      {
        "text": "Bangkok"
      },
      {
        "text": "Bueng Kan"
      },
      {
        "text": "Buri Ram"
      },
      {
        "text": "Chachoengsao"
      },
      {
        "text": "Chai Nat"
      },
      {
        "text": "Chanthaburi"
      },
      {
        "text": "Chiang Mai"
      },
      {
        "text": "Chiang Rai"
      },
      {
        "text": "Chon Buri"
      },
      {
        "text": "Chumphon"
      },
      {
        "text": "Kalasin"
      },
      {
        "text": "Kamphaeng Phet"
      },
      {
        "text": "Kanchanaburi"
      },
      {
        "text": "Khon Kaen"
      },
      {
        "text": "Krabi"
      },
      {
        "text": "Lampang"
      },
      {
        "text": "Lamphun"
      },
      {
        "text": "Loei"
      },
      {
        "text": "Lopburi"
      },
      {
        "text": "Mae Hong Son"
      },
      {
        "text": "Maha Sarakham"
      },
      {
        "text": "Mukdahan"
      },
      {
        "text": "Nakhon Nayok"
      },
      {
        "text": "Nakhon Pathom"
      },
      {
        "text": "Nakhon Phanom"
      },
      {
        "text": "Nakhon Ratchasima"
      },
      {
        "text": "Nakhon Sawan"
      },
      {
        "text": "Nakhon Si Thammarat"
      },
      {
        "text": "Nan"
      },
      {
        "text": "Narathiwat"
      },
      {
        "text": "Nong Bua Lam Phu"
      },
      {
        "text": "Nong Khai"
      },
      {
        "text": "Nonthaburi"
      },
      {
        "text": "Pathum Thani"
      },
      {
        "text": "Pattani"
      },
      {
        "text": "Pattaya"
      },
      {
        "text": "Phang Nga"
      },
      {
        "text": "Phatthalung"
      },
      {
        "text": "Phayao"
      },
      {
        "text": "Phetchabun"
      },
      {
        "text": "Phetchaburi"
      },
      {
        "text": "Phichit"
      },
      {
        "text": "Phitsanulok"
      },
      {
        "text": "Phra Nakhon Si Ayutthaya"
      },
      {
        "text": "Phrae"
      },
      {
        "text": "Phuket"
      },
      {
        "text": "Prachin Buri"
      },
      {
        "text": "Prachuap Khiri Khan"
      },
      {
        "text": "Ranong"
      },
      {
        "text": "Ratchaburi"
      },
      {
        "text": "Rayong"
      },
      {
        "text": "Roi Et"
      },
      {
        "text": "Sa Kaeo"
      },
      {
        "text": "Sakon Nakhon"
      },
      {
        "text": "Samut Prakan"
      },
      {
        "text": "Samut Sakhon"
      },
      {
        "text": "Samut Songkhram"
      },
      {
        "text": "Saraburi"
      },
      {
        "text": "Satun"
      },
      {
        "text": "Si Sa Ket"
      },
      {
        "text": "Sing Buri"
      },
      {
        "text": "Songkhla"
      },
      {
        "text": "Sukhothai"
      },
      {
        "text": "Suphanburi"
      },
      {
        "text": "Surat Thani"
      },
      {
        "text": "Surin"
      },
      {
        "text": "Tak"
      },
      {
        "text": "Trang"
      },
      {
        "text": "Trat"
      },
      {
        "text": "Ubon Ratchathani"
      },
      {
        "text": "Udon Thani"
      },
      {
        "text": "Uthai Thani"
      },
      {
        "text": "Uttaradit"
      },
      {
        "text": "Yala"
      },
      {
        "text": "Yasothon"
      }
    ],
    "code": "+66"
  },
  {
    "text": "Togo",
    "isoCode": "TG",
    "states": [
      {
        "text": "Centrale Region"
      },
      {
        "text": "Kara Region"
      },
      {
        "text": "Maritime"
      },
      {
        "text": "Plateaux Region"
      },
      {
        "text": "Savanes Region"
      }
    ],
    "code": "+228"
  },
  {
    "text": "Tokelau",
    "isoCode": "TK",
    "states": []
  },
  {
    "text": "Tonga",
    "isoCode": "TO",
    "states": [
      {
        "text": "Haʻapai"
      },
      {
        "text": "Niuas"
      },
      {
        "text": "Tongatapu"
      },
      {
        "text": "Vavaʻu"
      },
      {
        "text": "ʻEua"
      }
    ],
    "code": "+676"
  },
  {
    "text": "Trinidad And Tobago",
    "isoCode": "TT",
    "states": [
      {
        "text": "Arima"
      },
      {
        "text": "Chaguanas"
      },
      {
        "text": "Couva-Tabaquite-Talparo Regional Corporation"
      },
      {
        "text": "Diego Martin Regional Corporation"
      },
      {
        "text": "Eastern Tobago"
      },
      {
        "text": "Penal-Debe Regional Corporation"
      },
      {
        "text": "Point Fortin"
      },
      {
        "text": "Port of Spain"
      },
      {
        "text": "Princes Town Regional Corporation"
      },
      {
        "text": "Rio Claro-Mayaro Regional Corporation"
      },
      {
        "text": "San Fernando"
      },
      {
        "text": "San Juan-Laventille Regional Corporation"
      },
      {
        "text": "Sangre Grande Regional Corporation"
      },
      {
        "text": "Siparia Regional Corporation"
      },
      {
        "text": "Tunapuna-Piarco Regional Corporation"
      },
      {
        "text": "Western Tobago"
      }
    ]
  },
  {
    "text": "Tunisia",
    "isoCode": "TN",
    "states": [
      {
        "text": "Ariana Governorate"
      },
      {
        "text": "Ben Arous Governorate"
      },
      {
        "text": "Bizerte Governorate"
      },
      {
        "text": "Gabès Governorate"
      },
      {
        "text": "Gafsa Governorate"
      },
      {
        "text": "Jendouba Governorate"
      },
      {
        "text": "Kairouan Governorate"
      },
      {
        "text": "Kasserine Governorate"
      },
      {
        "text": "Kassrine"
      },
      {
        "text": "Kebili Governorate"
      },
      {
        "text": "Kef Governorate"
      },
      {
        "text": "Mahdia Governorate"
      },
      {
        "text": "Manouba Governorate"
      },
      {
        "text": "Medenine Governorate"
      },
      {
        "text": "Monastir Governorate"
      },
      {
        "text": "Sfax Governorate"
      },
      {
        "text": "Sidi Bouzid Governorate"
      },
      {
        "text": "Siliana Governorate"
      },
      {
        "text": "Sousse Governorate"
      },
      {
        "text": "Tataouine Governorate"
      },
      {
        "text": "Tozeur Governorate"
      },
      {
        "text": "Tunis Governorate"
      },
      {
        "text": "Zaghouan Governorate"
      }
    ],
    "code": "+216"
  },
  {
    "text": "Turkey",
    "isoCode": "TR",
    "states": [
      {
        "text": "Adana Province"
      },
      {
        "text": "Adıyaman Province"
      },
      {
        "text": "Afyonkarahisar Province"
      },
      {
        "text": "Aksaray Province"
      },
      {
        "text": "Amasya Province"
      },
      {
        "text": "Ankara Province"
      },
      {
        "text": "Antalya Province"
      },
      {
        "text": "Ardahan Province"
      },
      {
        "text": "Artvin Province"
      },
      {
        "text": "Aydın Province"
      },
      {
        "text": "Ağrı Province"
      },
      {
        "text": "Balıkesir Province"
      },
      {
        "text": "Bartın Province"
      },
      {
        "text": "Batman Province"
      },
      {
        "text": "Bayburt Province"
      },
      {
        "text": "Bilecik Province"
      },
      {
        "text": "Bingöl Province"
      },
      {
        "text": "Bitlis Province"
      },
      {
        "text": "Bolu Province"
      },
      {
        "text": "Burdur Province"
      },
      {
        "text": "Bursa Province"
      },
      {
        "text": "Denizli Province"
      },
      {
        "text": "Diyarbakır Province"
      },
      {
        "text": "Düzce Province"
      },
      {
        "text": "Edirne Province"
      },
      {
        "text": "Elazığ Province"
      },
      {
        "text": "Erzincan Province"
      },
      {
        "text": "Erzurum Province"
      },
      {
        "text": "Eskişehir Province"
      },
      {
        "text": "Gaziantep Province"
      },
      {
        "text": "Giresun Province"
      },
      {
        "text": "Gümüşhane Province"
      },
      {
        "text": "Hakkâri Province"
      },
      {
        "text": "Hatay Province"
      },
      {
        "text": "Isparta Province"
      },
      {
        "text": "Istanbul Province"
      },
      {
        "text": "Iğdır Province"
      },
      {
        "text": "Kahramanmaraş Province"
      },
      {
        "text": "Karabük Province"
      },
      {
        "text": "Karaman Province"
      },
      {
        "text": "Kars Province"
      },
      {
        "text": "Kastamonu Province"
      },
      {
        "text": "Kayseri Province"
      },
      {
        "text": "Kilis Province"
      },
      {
        "text": "Kocaeli Province"
      },
      {
        "text": "Konya Province"
      },
      {
        "text": "Kütahya Province"
      },
      {
        "text": "Kırklareli Province"
      },
      {
        "text": "Kırıkkale Province"
      },
      {
        "text": "Kırşehir Province"
      },
      {
        "text": "Malatya Province"
      },
      {
        "text": "Manisa Province"
      },
      {
        "text": "Mardin Province"
      },
      {
        "text": "Mersin Province"
      },
      {
        "text": "Muğla Province"
      },
      {
        "text": "Muş Province"
      },
      {
        "text": "Nevşehir Province"
      },
      {
        "text": "Niğde Province"
      },
      {
        "text": "Ordu Province"
      },
      {
        "text": "Osmaniye Province"
      },
      {
        "text": "Rize Province"
      },
      {
        "text": "Sakarya Province"
      },
      {
        "text": "Samsun Province"
      },
      {
        "text": "Siirt Province"
      },
      {
        "text": "Sinop Province"
      },
      {
        "text": "Sivas Province"
      },
      {
        "text": "Tekirdağ Province"
      },
      {
        "text": "Tokat Province"
      },
      {
        "text": "Trabzon Province"
      },
      {
        "text": "Tunceli Province"
      },
      {
        "text": "Uşak Province"
      },
      {
        "text": "Van Province"
      },
      {
        "text": "Yalova Province"
      },
      {
        "text": "Yozgat Province"
      },
      {
        "text": "Zonguldak Province"
      },
      {
        "text": "Çanakkale Province"
      },
      {
        "text": "Çankırı Province"
      },
      {
        "text": "Çorum Province"
      },
      {
        "text": "İzmir Province"
      },
      {
        "text": "Şanlıurfa Province"
      },
      {
        "text": "Şırnak Province"
      }
    ],
    "code": "+90"
  },
  {
    "text": "Turkmenistan",
    "isoCode": "TM",
    "states": [
      {
        "text": "Ahal Region"
      },
      {
        "text": "Ashgabat"
      },
      {
        "text": "Balkan Region"
      },
      {
        "text": "Daşoguz Region"
      },
      {
        "text": "Lebap Region"
      },
      {
        "text": "Mary Region"
      }
    ],
    "code": "+993"
  },
  {
    "text": "Turks And Caicos Islands",
    "isoCode": "TC",
    "states": []
  },
  {
    "text": "Tuvalu",
    "isoCode": "TV",
    "states": [
      {
        "text": "Funafuti"
      },
      {
        "text": "Nanumanga"
      },
      {
        "text": "Nanumea"
      },
      {
        "text": "Niutao Island Council"
      },
      {
        "text": "Nui"
      },
      {
        "text": "Nukufetau"
      },
      {
        "text": "Nukulaelae"
      },
      {
        "text": "Vaitupu"
      }
    ],
    "code": "+688"
  },
  {
    "text": "Uganda",
    "isoCode": "UG",
    "states": [
      {
        "text": "Abim District"
      },
      {
        "text": "Adjumani District"
      },
      {
        "text": "Agago District"
      },
      {
        "text": "Alebtong District"
      },
      {
        "text": "Amolatar District"
      },
      {
        "text": "Amudat District"
      },
      {
        "text": "Amuria District"
      },
      {
        "text": "Amuru District"
      },
      {
        "text": "Apac District"
      },
      {
        "text": "Arua District"
      },
      {
        "text": "Budaka District"
      },
      {
        "text": "Bududa District"
      },
      {
        "text": "Bugiri District"
      },
      {
        "text": "Buhweju District"
      },
      {
        "text": "Buikwe District"
      },
      {
        "text": "Bukedea District"
      },
      {
        "text": "Bukomansimbi District"
      },
      {
        "text": "Bukwo District"
      },
      {
        "text": "Bulambuli District"
      },
      {
        "text": "Buliisa District"
      },
      {
        "text": "Bundibugyo District"
      },
      {
        "text": "Bunyangabu District"
      },
      {
        "text": "Bushenyi District"
      },
      {
        "text": "Busia District"
      },
      {
        "text": "Butaleja District"
      },
      {
        "text": "Butambala District"
      },
      {
        "text": "Butebo District"
      },
      {
        "text": "Buvuma District"
      },
      {
        "text": "Buyende District"
      },
      {
        "text": "Central Region"
      },
      {
        "text": "Dokolo District"
      },
      {
        "text": "Eastern Region"
      },
      {
        "text": "Gomba District"
      },
      {
        "text": "Gulu District"
      },
      {
        "text": "Ibanda District"
      },
      {
        "text": "Iganga District"
      },
      {
        "text": "Isingiro District"
      },
      {
        "text": "Jinja District"
      },
      {
        "text": "Kaabong District"
      },
      {
        "text": "Kabale District"
      },
      {
        "text": "Kabarole District"
      },
      {
        "text": "Kaberamaido District"
      },
      {
        "text": "Kagadi District"
      },
      {
        "text": "Kakumiro District"
      },
      {
        "text": "Kalangala District"
      },
      {
        "text": "Kaliro District"
      },
      {
        "text": "Kalungu District"
      },
      {
        "text": "Kampala District"
      },
      {
        "text": "Kamuli District"
      },
      {
        "text": "Kamwenge District"
      },
      {
        "text": "Kanungu District"
      },
      {
        "text": "Kapchorwa District"
      },
      {
        "text": "Kasese District"
      },
      {
        "text": "Katakwi District"
      },
      {
        "text": "Kayunga District"
      },
      {
        "text": "Kibaale District"
      },
      {
        "text": "Kiboga District"
      },
      {
        "text": "Kibuku District"
      },
      {
        "text": "Kiruhura District"
      },
      {
        "text": "Kiryandongo District"
      },
      {
        "text": "Kisoro District"
      },
      {
        "text": "Kitgum District"
      },
      {
        "text": "Koboko District"
      },
      {
        "text": "Kole District"
      },
      {
        "text": "Kotido District"
      },
      {
        "text": "Kumi District"
      },
      {
        "text": "Kween District"
      },
      {
        "text": "Kyankwanzi District"
      },
      {
        "text": "Kyegegwa District"
      },
      {
        "text": "Kyenjojo District"
      },
      {
        "text": "Kyotera District"
      },
      {
        "text": "Lamwo District"
      },
      {
        "text": "Lira District"
      },
      {
        "text": "Luuka District"
      },
      {
        "text": "Luwero District"
      },
      {
        "text": "Lwengo District"
      },
      {
        "text": "Lyantonde District"
      },
      {
        "text": "Manafwa District"
      },
      {
        "text": "Maracha District"
      },
      {
        "text": "Masaka District"
      },
      {
        "text": "Masindi District"
      },
      {
        "text": "Mayuge District"
      },
      {
        "text": "Mbale District"
      },
      {
        "text": "Mbarara District"
      },
      {
        "text": "Mitooma District"
      },
      {
        "text": "Mityana District"
      },
      {
        "text": "Moroto District"
      },
      {
        "text": "Moyo District"
      },
      {
        "text": "Mpigi District"
      },
      {
        "text": "Mubende District"
      },
      {
        "text": "Mukono District"
      },
      {
        "text": "Nakapiripirit District"
      },
      {
        "text": "Nakaseke District"
      },
      {
        "text": "Nakasongola District"
      },
      {
        "text": "Namayingo District"
      },
      {
        "text": "Namisindwa District"
      },
      {
        "text": "Namutumba District"
      },
      {
        "text": "Napak District"
      },
      {
        "text": "Nebbi District"
      },
      {
        "text": "Ngora District"
      },
      {
        "text": "Northern Region"
      },
      {
        "text": "Ntoroko District"
      },
      {
        "text": "Ntungamo District"
      },
      {
        "text": "Nwoya District"
      },
      {
        "text": "Omoro District"
      },
      {
        "text": "Otuke District"
      },
      {
        "text": "Oyam District"
      },
      {
        "text": "Pader District"
      },
      {
        "text": "Pakwach District"
      },
      {
        "text": "Pallisa District"
      },
      {
        "text": "Rakai District"
      },
      {
        "text": "Rubanda District"
      },
      {
        "text": "Rubirizi District"
      },
      {
        "text": "Rukiga District"
      },
      {
        "text": "Rukungiri District"
      },
      {
        "text": "Sembabule District"
      },
      {
        "text": "Serere District"
      },
      {
        "text": "Sheema District"
      },
      {
        "text": "Sironko District"
      },
      {
        "text": "Soroti District"
      },
      {
        "text": "Tororo District"
      },
      {
        "text": "Wakiso District"
      },
      {
        "text": "Western Region"
      },
      {
        "text": "Yumbe District"
      },
      {
        "text": "Zombo District"
      }
    ],
    "code": "+256"
  },
  {
    "text": "Ukraine",
    "isoCode": "UA",
    "states": [
      {
        "text": "Autonomous Republic of Crimea"
      },
      {
        "text": "Cherkasy Oblast"
      },
      {
        "text": "Chernihiv Oblast"
      },
      {
        "text": "Chernivtsi Oblast"
      },
      {
        "text": "Dnipropetrovsk Oblast"
      },
      {
        "text": "Donetsk Oblast"
      },
      {
        "text": "Ivano-Frankivsk Oblast"
      },
      {
        "text": "Kharkiv Oblast"
      },
      {
        "text": "Kherson Oblast"
      },
      {
        "text": "Khmelnytsky Oblast"
      },
      {
        "text": "Kiev"
      },
      {
        "text": "Kirovohrad Oblast"
      },
      {
        "text": "Kyiv Oblast"
      },
      {
        "text": "Luhansk Oblast"
      },
      {
        "text": "Lviv Oblast"
      },
      {
        "text": "Mykolaiv Oblast"
      },
      {
        "text": "Odessa Oblast"
      },
      {
        "text": "Rivne Oblast"
      },
      {
        "text": "Sumy Oblast"
      },
      {
        "text": "Ternopil Oblast"
      },
      {
        "text": "Vinnytsia Oblast"
      },
      {
        "text": "Volyn Oblast"
      },
      {
        "text": "Zakarpattia Oblast"
      },
      {
        "text": "Zaporizhzhya Oblast"
      },
      {
        "text": "Zhytomyr Oblast"
      }
    ],
    "code": "+380"
  },
  {
    "text": "United Arab Emirates",
    "isoCode": "AE",
    "states": [
      {
        "text": "Abu Dhabi Emirate"
      },
      {
        "text": "Ajman Emirate"
      },
      {
        "text": "Dubai"
      },
      {
        "text": "Fujairah"
      },
      {
        "text": "Ras al-Khaimah"
      },
      {
        "text": "Sharjah Emirate"
      },
      {
        "text": "Umm al-Quwain"
      }
    ],
    "code": "+971"
  },
  {
    "text": "United Kingdom",
    "isoCode": "GB",
    "states": [
      {
        "text": "Aberdeen"
      },
      {
        "text": "Aberdeenshire"
      },
      {
        "text": "Angus"
      },
      {
        "text": "Antrim"
      },
      {
        "text": "Antrim and Newtownabbey"
      },
      {
        "text": "Ards"
      },
      {
        "text": "Ards and North Down"
      },
      {
        "text": "Argyll and Bute"
      },
      {
        "text": "Armagh City and District Council"
      },
      {
        "text": "Armagh, Banbridge and Craigavon"
      },
      {
        "text": "Ascension Island"
      },
      {
        "text": "Ballymena Borough"
      },
      {
        "text": "Ballymoney"
      },
      {
        "text": "Banbridge"
      },
      {
        "text": "Barnsley"
      },
      {
        "text": "Bath and North East Somerset"
      },
      {
        "text": "Bedford"
      },
      {
        "text": "Belfast district"
      },
      {
        "text": "Birmingham"
      },
      {
        "text": "Blackburn with Darwen"
      },
      {
        "text": "Blackpool"
      },
      {
        "text": "Blaenau Gwent County Borough"
      },
      {
        "text": "Bolton"
      },
      {
        "text": "Bournemouth"
      },
      {
        "text": "Bracknell Forest"
      },
      {
        "text": "Bradford"
      },
      {
        "text": "Bridgend County Borough"
      },
      {
        "text": "Brighton and Hove"
      },
      {
        "text": "Buckinghamshire"
      },
      {
        "text": "Bury"
      },
      {
        "text": "Caerphilly County Borough"
      },
      {
        "text": "Calderdale"
      },
      {
        "text": "Cambridgeshire"
      },
      {
        "text": "Carmarthenshire"
      },
      {
        "text": "Carrickfergus Borough Council"
      },
      {
        "text": "Castlereagh"
      },
      {
        "text": "Causeway Coast and Glens"
      },
      {
        "text": "Central Bedfordshire"
      },
      {
        "text": "Ceredigion"
      },
      {
        "text": "Cheshire East"
      },
      {
        "text": "Cheshire West and Chester"
      },
      {
        "text": "City and County of Cardiff"
      },
      {
        "text": "City and County of Swansea"
      },
      {
        "text": "City of Bristol"
      },
      {
        "text": "City of Derby"
      },
      {
        "text": "City of Kingston upon Hull"
      },
      {
        "text": "City of Leicester"
      },
      {
        "text": "City of London"
      },
      {
        "text": "City of Nottingham"
      },
      {
        "text": "City of Peterborough"
      },
      {
        "text": "City of Plymouth"
      },
      {
        "text": "City of Portsmouth"
      },
      {
        "text": "City of Southampton"
      },
      {
        "text": "City of Stoke-on-Trent"
      },
      {
        "text": "City of Sunderland"
      },
      {
        "text": "City of Westminster"
      },
      {
        "text": "City of Wolverhampton"
      },
      {
        "text": "City of York"
      },
      {
        "text": "Clackmannanshire"
      },
      {
        "text": "Coleraine Borough Council"
      },
      {
        "text": "Conwy County Borough"
      },
      {
        "text": "Cookstown District Council"
      },
      {
        "text": "Cornwall"
      },
      {
        "text": "County Durham"
      },
      {
        "text": "Coventry"
      },
      {
        "text": "Craigavon Borough Council"
      },
      {
        "text": "Cumbria"
      },
      {
        "text": "Darlington"
      },
      {
        "text": "Denbighshire"
      },
      {
        "text": "Derbyshire"
      },
      {
        "text": "Derry City Council"
      },
      {
        "text": "Derry City and Strabane"
      },
      {
        "text": "Devon"
      },
      {
        "text": "Doncaster"
      },
      {
        "text": "Dorset"
      },
      {
        "text": "Down District Council"
      },
      {
        "text": "Dudley"
      },
      {
        "text": "Dumfries and Galloway"
      },
      {
        "text": "Dundee"
      },
      {
        "text": "Dungannon and South Tyrone Borough Council"
      },
      {
        "text": "East Ayrshire"
      },
      {
        "text": "East Dunbartonshire"
      },
      {
        "text": "East Lothian"
      },
      {
        "text": "East Renfrewshire"
      },
      {
        "text": "East Riding of Yorkshire"
      },
      {
        "text": "East Sussex"
      },
      {
        "text": "Edinburgh"
      },
      {
        "text": "England"
      },
      {
        "text": "Essex"
      },
      {
        "text": "Falkirk"
      },
      {
        "text": "Fermanagh District Council"
      },
      {
        "text": "Fermanagh and Omagh"
      },
      {
        "text": "Fife"
      },
      {
        "text": "Flintshire"
      },
      {
        "text": "Gateshead"
      },
      {
        "text": "Glasgow"
      },
      {
        "text": "Gloucestershire"
      },
      {
        "text": "Gwynedd"
      },
      {
        "text": "Halton"
      },
      {
        "text": "Hampshire"
      },
      {
        "text": "Hartlepool"
      },
      {
        "text": "Herefordshire"
      },
      {
        "text": "Hertfordshire"
      },
      {
        "text": "Highland"
      },
      {
        "text": "Inverclyde"
      },
      {
        "text": "Isle of Wight"
      },
      {
        "text": "Isles of Scilly"
      },
      {
        "text": "Kent"
      },
      {
        "text": "Kirklees"
      },
      {
        "text": "Knowsley"
      },
      {
        "text": "Lancashire"
      },
      {
        "text": "Larne Borough Council"
      },
      {
        "text": "Leeds"
      },
      {
        "text": "Leicestershire"
      },
      {
        "text": "Limavady Borough Council"
      },
      {
        "text": "Lincolnshire"
      },
      {
        "text": "Lisburn City Council"
      },
      {
        "text": "Lisburn and Castlereagh"
      },
      {
        "text": "Liverpool"
      },
      {
        "text": "London Borough of Barking and Dagenham"
      },
      {
        "text": "London Borough of Barnet"
      },
      {
        "text": "London Borough of Bexley"
      },
      {
        "text": "London Borough of Brent"
      },
      {
        "text": "London Borough of Bromley"
      },
      {
        "text": "London Borough of Camden"
      },
      {
        "text": "London Borough of Croydon"
      },
      {
        "text": "London Borough of Ealing"
      },
      {
        "text": "London Borough of Enfield"
      },
      {
        "text": "London Borough of Hackney"
      },
      {
        "text": "London Borough of Hammersmith and Fulham"
      },
      {
        "text": "London Borough of Haringey"
      },
      {
        "text": "London Borough of Harrow"
      },
      {
        "text": "London Borough of Havering"
      },
      {
        "text": "London Borough of Hillingdon"
      },
      {
        "text": "London Borough of Hounslow"
      },
      {
        "text": "London Borough of Islington"
      },
      {
        "text": "London Borough of Lambeth"
      },
      {
        "text": "London Borough of Lewisham"
      },
      {
        "text": "London Borough of Merton"
      },
      {
        "text": "London Borough of Newham"
      },
      {
        "text": "London Borough of Redbridge"
      },
      {
        "text": "London Borough of Richmond upon Thames"
      },
      {
        "text": "London Borough of Southwark"
      },
      {
        "text": "London Borough of Sutton"
      },
      {
        "text": "London Borough of Tower Hamlets"
      },
      {
        "text": "London Borough of Waltham Forest"
      },
      {
        "text": "London Borough of Wandsworth"
      },
      {
        "text": "Magherafelt District Council"
      },
      {
        "text": "Manchester"
      },
      {
        "text": "Medway"
      },
      {
        "text": "Merthyr Tydfil County Borough"
      },
      {
        "text": "Metropolitan Borough of Wigan"
      },
      {
        "text": "Mid Ulster"
      },
      {
        "text": "Mid and East Antrim"
      },
      {
        "text": "Middlesbrough"
      },
      {
        "text": "Midlothian"
      },
      {
        "text": "Milton Keynes"
      },
      {
        "text": "Monmouthshire"
      },
      {
        "text": "Moray"
      },
      {
        "text": "Moyle District Council"
      },
      {
        "text": "Neath Port Talbot County Borough"
      },
      {
        "text": "Newcastle upon Tyne"
      },
      {
        "text": "Newport"
      },
      {
        "text": "Newry and Mourne District Council"
      },
      {
        "text": "Newry, Mourne and Down"
      },
      {
        "text": "Newtownabbey Borough Council"
      },
      {
        "text": "Norfolk"
      },
      {
        "text": "North Ayrshire"
      },
      {
        "text": "North Down Borough Council"
      },
      {
        "text": "North East Lincolnshire"
      },
      {
        "text": "North Lanarkshire"
      },
      {
        "text": "North Lincolnshire"
      },
      {
        "text": "North Somerset"
      },
      {
        "text": "North Tyneside"
      },
      {
        "text": "North Yorkshire"
      },
      {
        "text": "Northamptonshire"
      },
      {
        "text": "Northern Ireland"
      },
      {
        "text": "Northumberland"
      },
      {
        "text": "Nottinghamshire"
      },
      {
        "text": "Oldham"
      },
      {
        "text": "Omagh District Council"
      },
      {
        "text": "Orkney Islands"
      },
      {
        "text": "Outer Hebrides"
      },
      {
        "text": "Oxfordshire"
      },
      {
        "text": "Pembrokeshire"
      },
      {
        "text": "Perth and Kinross"
      },
      {
        "text": "Poole"
      },
      {
        "text": "Powys"
      },
      {
        "text": "Reading"
      },
      {
        "text": "Redcar and Cleveland"
      },
      {
        "text": "Renfrewshire"
      },
      {
        "text": "Rhondda Cynon Taf"
      },
      {
        "text": "Rochdale"
      },
      {
        "text": "Rotherham"
      },
      {
        "text": "Royal Borough of Greenwich"
      },
      {
        "text": "Royal Borough of Kensington and Chelsea"
      },
      {
        "text": "Royal Borough of Kingston upon Thames"
      },
      {
        "text": "Rutland"
      },
      {
        "text": "Saint Helena"
      },
      {
        "text": "Salford"
      },
      {
        "text": "Sandwell"
      },
      {
        "text": "Scotland"
      },
      {
        "text": "Scottish Borders"
      },
      {
        "text": "Sefton"
      },
      {
        "text": "Sheffield"
      },
      {
        "text": "Shetland Islands"
      },
      {
        "text": "Shropshire"
      },
      {
        "text": "Slough"
      },
      {
        "text": "Solihull"
      },
      {
        "text": "Somerset"
      },
      {
        "text": "South Ayrshire"
      },
      {
        "text": "South Gloucestershire"
      },
      {
        "text": "South Lanarkshire"
      },
      {
        "text": "South Tyneside"
      },
      {
        "text": "Southend-on-Sea"
      },
      {
        "text": "St Helens"
      },
      {
        "text": "Staffordshire"
      },
      {
        "text": "Stirling"
      },
      {
        "text": "Stockport"
      },
      {
        "text": "Stockton-on-Tees"
      },
      {
        "text": "Strabane District Council"
      },
      {
        "text": "Suffolk"
      },
      {
        "text": "Surrey"
      },
      {
        "text": "Swindon"
      },
      {
        "text": "Tameside"
      },
      {
        "text": "Telford and Wrekin"
      },
      {
        "text": "Thurrock"
      },
      {
        "text": "Torbay"
      },
      {
        "text": "Torfaen"
      },
      {
        "text": "Trafford"
      },
      {
        "text": "United Kingdom"
      },
      {
        "text": "Vale of Glamorgan"
      },
      {
        "text": "Wakefield"
      },
      {
        "text": "Wales"
      },
      {
        "text": "Walsall"
      },
      {
        "text": "Warrington"
      },
      {
        "text": "Warwickshire"
      },
      {
        "text": "West Berkshire"
      },
      {
        "text": "West Dunbartonshire"
      },
      {
        "text": "West Lothian"
      },
      {
        "text": "West Sussex"
      },
      {
        "text": "Wiltshire"
      },
      {
        "text": "Windsor and Maidenhead"
      },
      {
        "text": "Wirral"
      },
      {
        "text": "Wokingham"
      },
      {
        "text": "Worcestershire"
      },
      {
        "text": "Wrexham County Borough"
      }
    ],
    "code": "+44"
  },
  {
    "text": "United States",
    "isoCode": "US",
    "states": [
      {
        "text": "Alabama"
      },
      {
        "text": "Alaska"
      },
      {
        "text": "American Samoa"
      },
      {
        "text": "Arizona"
      },
      {
        "text": "Arkansas"
      },
      {
        "text": "Baker Island"
      },
      {
        "text": "California"
      },
      {
        "text": "Colorado"
      },
      {
        "text": "Connecticut"
      },
      {
        "text": "Delaware"
      },
      {
        "text": "District of Columbia"
      },
      {
        "text": "Florida"
      },
      {
        "text": "Georgia"
      },
      {
        "text": "Guam"
      },
      {
        "text": "Hawaii"
      },
      {
        "text": "Howland Island"
      },
      {
        "text": "Idaho"
      },
      {
        "text": "Illinois"
      },
      {
        "text": "Indiana"
      },
      {
        "text": "Iowa"
      },
      {
        "text": "Jarvis Island"
      },
      {
        "text": "Johnston Atoll"
      },
      {
        "text": "Kansas"
      },
      {
        "text": "Kentucky"
      },
      {
        "text": "Kingman Reef"
      },
      {
        "text": "Louisiana"
      },
      {
        "text": "Maine"
      },
      {
        "text": "Maryland"
      },
      {
        "text": "Massachusetts"
      },
      {
        "text": "Michigan"
      },
      {
        "text": "Midway Atoll"
      },
      {
        "text": "Minnesota"
      },
      {
        "text": "Mississippi"
      },
      {
        "text": "Missouri"
      },
      {
        "text": "Montana"
      },
      {
        "text": "Navassa Island"
      },
      {
        "text": "Nebraska"
      },
      {
        "text": "Nevada"
      },
      {
        "text": "New Hampshire"
      },
      {
        "text": "New Jersey"
      },
      {
        "text": "New Mexico"
      },
      {
        "text": "New York"
      },
      {
        "text": "North Carolina"
      },
      {
        "text": "North Dakota"
      },
      {
        "text": "Northern Mariana Islands"
      },
      {
        "text": "Ohio"
      },
      {
        "text": "Oklahoma"
      },
      {
        "text": "Oregon"
      },
      {
        "text": "Palmyra Atoll"
      },
      {
        "text": "Pennsylvania"
      },
      {
        "text": "Puerto Rico"
      },
      {
        "text": "Rhode Island"
      },
      {
        "text": "South Carolina"
      },
      {
        "text": "South Dakota"
      },
      {
        "text": "Tennessee"
      },
      {
        "text": "Texas"
      },
      {
        "text": "United States Minor Outlying Islands"
      },
      {
        "text": "United States Virgin Islands"
      },
      {
        "text": "Utah"
      },
      {
        "text": "Vermont"
      },
      {
        "text": "Virginia"
      },
      {
        "text": "Wake Island"
      },
      {
        "text": "Washington"
      },
      {
        "text": "West Virginia"
      },
      {
        "text": "Wisconsin"
      },
      {
        "text": "Wyoming"
      }
    ],
    "code": "+1"
  },
  {
    "text": "United States Minor Outlying Islands",
    "isoCode": "UM",
    "states": []
  },
  {
    "text": "Uruguay",
    "isoCode": "UY",
    "states": [
      {
        "text": "Artigas Department"
      },
      {
        "text": "Canelones Department"
      },
      {
        "text": "Cerro Largo Department"
      },
      {
        "text": "Colonia Department"
      },
      {
        "text": "Durazno Department"
      },
      {
        "text": "Flores Department"
      },
      {
        "text": "Florida Department"
      },
      {
        "text": "Lavalleja Department"
      },
      {
        "text": "Maldonado Department"
      },
      {
        "text": "Montevideo Department"
      },
      {
        "text": "Paysandú Department"
      },
      {
        "text": "Rivera Department"
      },
      {
        "text": "Rocha Department"
      },
      {
        "text": "Río Negro Department"
      },
      {
        "text": "Salto Department"
      },
      {
        "text": "San José Department"
      },
      {
        "text": "Soriano Department"
      },
      {
        "text": "Tacuarembó Department"
      },
      {
        "text": "Treinta y Tres Department"
      }
    ],
    "code": "+598"
  },
  {
    "text": "Uzbekistan",
    "isoCode": "UZ",
    "states": [
      {
        "text": "Andijan Region"
      },
      {
        "text": "Bukhara Region"
      },
      {
        "text": "Fergana Region"
      },
      {
        "text": "Jizzakh Region"
      },
      {
        "text": "Karakalpakstan"
      },
      {
        "text": "Namangan Region"
      },
      {
        "text": "Navoiy Region"
      },
      {
        "text": "Qashqadaryo Region"
      },
      {
        "text": "Samarqand Region"
      },
      {
        "text": "Sirdaryo Region"
      },
      {
        "text": "Surxondaryo Region"
      },
      {
        "text": "Tashkent"
      },
      {
        "text": "Tashkent Region"
      },
      {
        "text": "Xorazm Region"
      }
    ],
    "code": "+998"
  },
  {
    "text": "Vanuatu",
    "isoCode": "VU",
    "states": [
      {
        "text": "Malampa"
      },
      {
        "text": "Penama"
      },
      {
        "text": "Sanma"
      },
      {
        "text": "Shefa"
      },
      {
        "text": "Tafea"
      },
      {
        "text": "Torba"
      }
    ],
    "code": "+678"
  },
  {
    "text": "Vatican City State (Holy See)",
    "isoCode": "VA",
    "states": []
  },
  {
    "text": "Venezuela",
    "isoCode": "VE",
    "states": [
      {
        "text": "Amazonas"
      },
      {
        "text": "Anzoátegui"
      },
      {
        "text": "Apure"
      },
      {
        "text": "Aragua"
      },
      {
        "text": "Barinas"
      },
      {
        "text": "Bolívar"
      },
      {
        "text": "Capital District"
      },
      {
        "text": "Carabobo"
      },
      {
        "text": "Cojedes"
      },
      {
        "text": "Delta Amacuro"
      },
      {
        "text": "Falcón"
      },
      {
        "text": "Federal Dependencies of Venezuela"
      },
      {
        "text": "Guárico"
      },
      {
        "text": "Lara"
      },
      {
        "text": "Miranda"
      },
      {
        "text": "Monagas"
      },
      {
        "text": "Mérida"
      },
      {
        "text": "Nueva Esparta"
      },
      {
        "text": "Portuguesa"
      },
      {
        "text": "Sucre"
      },
      {
        "text": "Trujillo"
      },
      {
        "text": "Táchira"
      },
      {
        "text": "Vargas"
      },
      {
        "text": "Yaracuy"
      },
      {
        "text": "Zulia"
      }
    ],
    "code": "+58"
  },
  {
    "text": "Vietnam",
    "isoCode": "VN",
    "states": [
      {
        "text": "An Giang"
      },
      {
        "text": "Bà Rịa-Vũng Tàu"
      },
      {
        "text": "Bình Dương"
      },
      {
        "text": "Bình Phước"
      },
      {
        "text": "Bình Thuận"
      },
      {
        "text": "Bình Định"
      },
      {
        "text": "Bạc Liêu"
      },
      {
        "text": "Bắc Giang"
      },
      {
        "text": "Bắc Kạn"
      },
      {
        "text": "Bắc Ninh"
      },
      {
        "text": "Bến Tre"
      },
      {
        "text": "Cao Bằng"
      },
      {
        "text": "Cà Mau"
      },
      {
        "text": "Da Nang"
      },
      {
        "text": "Gia Lai"
      },
      {
        "text": "Haiphong"
      },
      {
        "text": "Hanoi"
      },
      {
        "text": "Ho Chi Minh City"
      },
      {
        "text": "Hà Giang"
      },
      {
        "text": "Hà Nam"
      },
      {
        "text": "Hà Tây"
      },
      {
        "text": "Hà Tĩnh"
      },
      {
        "text": "Hòa Bình"
      },
      {
        "text": "Hưng Yên"
      },
      {
        "text": "Hải Dương"
      },
      {
        "text": "Hậu Giang"
      },
      {
        "text": "Khánh Hòa"
      },
      {
        "text": "Kiên Giang"
      },
      {
        "text": "Kon Tum"
      },
      {
        "text": "Lai Châu"
      },
      {
        "text": "Long An"
      },
      {
        "text": "Lào Cai"
      },
      {
        "text": "Lâm Đồng"
      },
      {
        "text": "Lạng Sơn"
      },
      {
        "text": "Nam Định"
      },
      {
        "text": "Nghệ An"
      },
      {
        "text": "Ninh Bình"
      },
      {
        "text": "Ninh Thuận"
      },
      {
        "text": "Phú Thọ"
      },
      {
        "text": "Phú Yên"
      },
      {
        "text": "Quảng Bình"
      },
      {
        "text": "Quảng Nam"
      },
      {
        "text": "Quảng Ngãi"
      },
      {
        "text": "Quảng Ninh"
      },
      {
        "text": "Quảng Trị"
      },
      {
        "text": "Sóc Trăng"
      },
      {
        "text": "Sơn La"
      },
      {
        "text": "Thanh Hóa"
      },
      {
        "text": "Thái Bình"
      },
      {
        "text": "Thái Nguyên"
      },
      {
        "text": "Thừa Thiên-Huế"
      },
      {
        "text": "Tiền Giang"
      },
      {
        "text": "Trà Vinh"
      },
      {
        "text": "Tuyên Quang"
      },
      {
        "text": "Tây Ninh"
      },
      {
        "text": "Vĩnh Long"
      },
      {
        "text": "Vĩnh Phúc"
      },
      {
        "text": "Yên Bái"
      },
      {
        "text": "Điện Biên"
      },
      {
        "text": "Đắk Lắk"
      },
      {
        "text": "Đắk Nông"
      },
      {
        "text": "Đồng Nai"
      },
      {
        "text": "Đồng Tháp"
      }
    ],
    "code": "+84"
  },
  {
    "text": "Virgin Islands (British)",
    "isoCode": "VG",
    "states": []
  },
  {
    "text": "Virgin Islands (US)",
    "isoCode": "VI",
    "states": []
  },
  {
    "text": "Wallis And Futuna Islands",
    "isoCode": "WF",
    "states": []
  },
  {
    "text": "Western Sahara",
    "isoCode": "EH",
    "states": []
  },
  {
    "text": "Yemen",
    "isoCode": "YE",
    "states": [
      {
        "text": "'Adan Governorate"
      },
      {
        "text": "'Amran Governorate"
      },
      {
        "text": "Abyan Governorate"
      },
      {
        "text": "Al Bayda' Governorate"
      },
      {
        "text": "Al Hudaydah Governorate"
      },
      {
        "text": "Al Jawf Governorate"
      },
      {
        "text": "Al Mahrah Governorate"
      },
      {
        "text": "Al Mahwit Governorate"
      },
      {
        "text": "Dhamar Governorate"
      },
      {
        "text": "Hadhramaut Governorate"
      },
      {
        "text": "Hajjah Governorate"
      },
      {
        "text": "Ibb Governorate"
      },
      {
        "text": "Lahij Governorate"
      },
      {
        "text": "Ma'rib Governorate"
      },
      {
        "text": "Raymah Governorate"
      },
      {
        "text": "Saada Governorate"
      },
      {
        "text": "Sana'a"
      },
      {
        "text": "Sana'a Governorate"
      },
      {
        "text": "Shabwah Governorate"
      },
      {
        "text": "Socotra Governorate"
      },
      {
        "text": "Ta'izz Governorate"
      }
    ],
    "code": "+967"
  },
  {
    "text": "Zambia",
    "isoCode": "ZM",
    "states": [
      {
        "text": "Central Province"
      },
      {
        "text": "Copperbelt Province"
      },
      {
        "text": "Eastern Province"
      },
      {
        "text": "Luapula Province"
      },
      {
        "text": "Lusaka Province"
      },
      {
        "text": "Muchinga Province"
      },
      {
        "text": "Northern Province"
      },
      {
        "text": "Northwestern Province"
      },
      {
        "text": "Southern Province"
      },
      {
        "text": "Western Province"
      }
    ],
    "code": "+260"
  },
  {
    "text": "Zimbabwe",
    "isoCode": "ZW",
    "states": [
      {
        "text": "Bulawayo Province"
      },
      {
        "text": "Harare Province"
      },
      {
        "text": "Manicaland"
      },
      {
        "text": "Mashonaland Central Province"
      },
      {
        "text": "Mashonaland East Province"
      },
      {
        "text": "Mashonaland West Province"
      },
      {
        "text": "Masvingo Province"
      },
      {
        "text": "Matabeleland North Province"
      },
      {
        "text": "Matabeleland South Province"
      },
      {
        "text": "Midlands Province"
      }
    ],
    "code": "+263"
  },
  {
    "text": "Kosovo",
    "isoCode": "XK",
    "states": [
      {
        "text": "Gjilan District"
      },
      {
        "text": "Kosovska Mitrovica District"
      },
      {
        "text": "Peć District"
      },
      {
        "text": "Pristina (Priştine)"
      },
      {
        "text": "Prizren District"
      },
      {
        "text": "Uroševac District (Ferizaj)"
      },
      {
        "text": "Đakovica District (Gjakove)"
      }
    ]
  },
  {
    "text": "Curaçao",
    "isoCode": "CW",
    "states": []
  },
  {
    "text": "Sint Maarten (Dutch part)",
    "isoCode": "SX",
    "states": []
  }
];

export const getAllCountries = (): any => {
    return Countries.map(({text}) => ({text}));
};

export const getAllStates = (name): any => {
    return Countries.find((country) => country?.text === name)?.states;
};

export const getCountryPhoneCode = (): any => {
  return Countries.map(({text, code}) =>  ({country: text,code,text: `${text}(${code})`}))
};
