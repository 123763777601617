<div class="p-8 rounded-3xl bg-light-gray-1">
    <div class="flex items-center justify-between">
        <h3 class="Poppins-Bold text-lg">{{'Stores' | translate }}</h3>
        <app-button  type='primary' buttonText='{{"Create Store" | translate }}' [rounded]='true' (handleClick)='route()'></app-button>
    </div>
    <table class="mt-5 min-w-full divide-y divide-gray-300">
        <thead>
            <tr class="h-12 text-start">
                <th class="Poppins-Bold">{{'Store Name' | translate }}</th>
                <th class="Poppins-Bold">{{'Store Address' | translate }}</th>
                <th class="Poppins-Bold">{{'Country' | translate }}</th>
                <th class="Poppins-Bold text-center" >{{'Actions' | translate}}</th>
            </tr>
        </thead>
        <tbody *ngIf="allStores.length" class="divide-y divide-gray-300">
                <tr *ngFor="let store of allStores" class="h-12">
                    <td>{{store?.storeName}}</td>
                    <td>{{store?.address?.addressLineFirst}}{{store?.address?.addressLineSecond ? ', ' + store?.address?.addressLineSecond : ''}}</td>
                    <td>{{store?.address?.country}}</td>
                    <td>
                        <div class="flex space-x-2 justify-between p-3">
                            <button *ngIf='userId === store?.userId' (click)='editStore(store?._id)'>
                                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                                </svg>
                            </button>
                            <button *ngIf='userId === store?.userId && store?.position !== "Primary"' (click)='showDeleteModal(store?._id)'>
                                <svg xmlns="http://www.w3.org/2000/svg" name="delete" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="mx-auto h-5 w-5 text-red-500"><path _ngcontent-hsm-c58="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path></svg>
                            </button>
                        </div>
                    </td>
                </tr>    
        </tbody> 
        <tbody>       
                <tr *ngIf="!allStores.length" class="w-full text-center text-xl Poppins mt-3">
                    <td colspan="4" class="text-center" >No Data Found</td>
                </tr>
        </tbody>
    </table>
</div>

<app-modal-container [isOpen]='openDeleteModal'>
    <div header class="border-b pb-2 Poppins-Bold">{{'Delete Store' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center gap-x-2">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteStore()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='openDeleteModal = false'></app-button>
    </div>
</app-modal-container>