import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { PurchaseOrderService } from '../../purchases/components/purchase-order/PurchaseOrder.service';
import { OpenService } from '../open.service';

@Component({
  selector: 'app-purchase-order',
  templateUrl: './purchase-order.component.html',
  styleUrls: ['./purchase-order.component.scss']
})
export class PurchaseOrderComponent implements OnInit {

  number = '1.2-2';
  decimalSize = 2;
  purchaseOrderDetail;
  customizationSettings = null;
  fieldNames = {
    purchase: 'Purchase Order',
    purchaseOrder: 'P.O. Number',
    date: 'P.O. Date',
    dueDate: 'Due Date',
    vendorDetails: 'Bill To',
    notes: 'Notes',
    itemName: 'Item Name',
    quantity: 'Unit',
    price: 'Unit Price',
    tax: 'Tax',
    totalAmount: 'Total Amount',
    total: 'Total',
    subtotal: 'Subtotal',
    issuer: 'Issued By'
  };
  currencyDetails = {
    currency: ''
  }

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private purchaseOrderService: PurchaseOrderService,
    private spinner: NgxSpinnerService,
    private openService: OpenService,
    private languageService: LanguageService,
    private toaster: ToastrService,
    private numberService: NumberService,
    private businessService: BusinessService,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(({ businessId, purchaseId }) => {
      if (businessId && purchaseId) {
        this.loadData(businessId, purchaseId);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  loadData(businessId, purchaseId): void {
    const apiList = [
      this.purchaseOrderService.OrderDetails(purchaseId).pipe(catchError((error) => of(null))),
      this.openService.getInvoiceCustomizationSettings(businessId).pipe(catchError((error) => of(null)))
    ];

    this.businessService.getBusinessDetailsById(businessId).subscribe((resp) => {
      if (resp?.success) {
        const businessData = resp.data;
        const decimalSize = businessData?.decimalSize ?? 2;
        this.decimalSize = decimalSize;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`);
        this.number = `1.${decimalSize}-${decimalSize}`;
      }
    })

    this.spinner.show();
    forkJoin(apiList)
      .subscribe((results) => {
        this.spinner.hide();
        if (results[0].success) {
          this.purchaseOrderDetail = results[0].data;
          this.currencyDetails.currency = this.purchaseOrderDetail.businessId.currency
        }
        if (results[1].success) {
          this.customizationSettings = results[1].data;
        }
      }, (error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  downloadPdf(): void {
    const body = {
      config: {
        data: {
          ...this.purchaseOrderDetail,
          items: this.purchaseOrderDetail.items.map((item) => {
            return {
              ...item,
              price: this.numberService.currencier(item.price),
              totalPrice: this.numberService.currencier(item.price * item.unit)
            };
          }),
          date: moment(this.purchaseOrderDetail.date).format('DD-MM-YYYY'),
          dueDate: moment(this.purchaseOrderDetail.dueDate).format('DD-MM-YYYY'),
          subtotal: this.numberService.currencier(this.purchaseOrderDetail.subtotal),
          tax: this.numberService.currencier(this.purchaseOrderDetail.tax),
          toatalAmount: this.numberService.currencier(this.purchaseOrderDetail.toatalAmount),
          currencyDetails: { currencySymbol: getCurrencySymbol(this.purchaseOrderDetail.businessId.currency, 'narrow') },
          companylogo: this.customizationSettings.companyLogo
        },
        direction: localStorage.getItem('NuMetric|lang') === 'ar'? 'rtl' : 'ltr',
        showTax: this.purchaseOrderDetail.tax ? true : false,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    };
    this.spinner.show();
    this.purchaseOrderService.generatePdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], { type: 'application/pdf' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${this.purchaseOrderDetail.purchaseOrder}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toaster.success(this.translateService.instant('PDF downloaded'));
    }, (error) => {
      this.spinner.hide();
      const errorMessage = error?.error?.message || this.translateService.instant('Something went wrong!');
      this.toaster.error(errorMessage);
    });
  }

}
