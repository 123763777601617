<div class="flex justify-center px-14 py-11 bg-white rounded-lg">
  <div class="w-full flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <div class="flex ms-auto space-x-4 rtl:space-x-reverse">
        <app-button
          appAccessControl
          moduleType="sales"
          accessType="edit"
          routerLink="create-customer"
          buttonText="{{ 'Add a Customer' | translate }}"
          type="primary"
          size="large"
        ></app-button>
      </div>
    </div>
    <app-table-container
      [tableHeadings]="tableHeadings"
      [tableData]="tableData"
      [keys]="tableKeys"
      [actionTemplate]="actionTemplate"
    >
      <ng-template let-item #actionTemplate>
        <td align="right" class="p-2 last:pe-10 text-center">
          <app-dropdown #dropdown [isFromSettings]="true">
            <div
              button
              (click)="dropdown.open = true"
              (clickOutside)="dropdown.open = false"
            >
              <div
                class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <div options class="new-shadow p-1 text-start">
              <div
                (click)="viewCustomer(item?._id)"
                class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ "View" | translate }}
              </div>
              <div
                (click)="editCustomer(item?._id)"
                class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ "Edit" | translate }}
              </div>
            </div>
          </app-dropdown>
        </td>
      </ng-template>
    </app-table-container>
  </div>
</div>

<app-modal-container [isOpen]='deleteConfirmationModal'>
    <div header class="border-b pb-2 Poppins-Bold">{{'Delete Customer' | translate}}</div>
    <div content>{{'Are you sure?' | translate}}</div>
    <div footer class="flex items-center gap-x-2">
        <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteCustomer()' ></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='deleteConfirmationModal = false'></app-button>
    </div>
</app-modal-container>
