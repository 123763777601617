import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectSubscriptionPlans, selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { SubscriptionService } from './subscription.service';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';


@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.scss']
})
export class SubscriptionComponent implements OnInit, OnDestroy {

  constructor(private store: Store<RootReducerState>,
              private spinner: NgxSpinnerService,
              private subscriptionService: SubscriptionService) {
    this.currentSubscription$ = this.store.pipe(select(selectUserSubscription));
    this.subscripitonPlans$ = this.store.pipe(select(selectSubscriptionPlans));
    this.usage$ = this.store.pipe(select(selectUsage));

  }

  subscriptionPlans = [];
  usage$: Observable<any>;
  currentSubscription$: Observable<any>;
  subscripitonPlans$: Observable<any>;
  unsubscribe$ = new Subject();
  currentSubscription = null;
  transactionLimit;
  remainingLimit;

  ngOnInit(): void {
    this.loadSubscriptionPlans();
    // this.getTransactionLimit();
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        this.transactionLimit = currentUsage?.transactionLimit;
        this.remainingLimit = this.currentSubscription?.transactionLimit - this.transactionLimit
      }
    })
  }

  loadCurrentSubscription(plans): void {
    this.getTransactionLimit();
    this.currentSubscription$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((subscription) => {
      if (subscription?.subscriptionId) {
        this.currentSubscription = subscription;
        console.log(this.currentSubscription)
        this.subscriptionService.setCurrentSubscription(this.currentSubscription);
        this.getTransactionLimit()
      }
      if (subscription?.subId) {
        this.spinner.show();
        this.subscriptionService.getSubscriptionInfo({subId: subscription.subId}).subscribe((resp) => {
          this.spinner.hide();
          const planId = resp.data?.plan_id;
          if(planId){
            this.currentSubscription = { ...this.currentSubscription };
            this.subscriptionService.setCurrentSubscription(this.currentSubscription);
            this.getTransactionLimit();
          }
        }, (error) => {
          this.spinner.hide();
          console.log('Error', error);
        });
      }
      
    });
  }

  loadSubscriptionPlans(): void {
    this.spinner.show();
    this.subscripitonPlans$.subscribe((plans) => {
      this.spinner.hide();
      if (plans.length > 0) {
        console.log("current subscription",plans.length)
        this.subscriptionService.setSubscriptionPlans(plans);
        this.loadCurrentSubscription(plans);
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
