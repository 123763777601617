<app-document-container [templateSelected]="true">
    <div id="template-option-one" class="w-56 bg-white shadow-md m-auto py-6 px-8" style="width: 915px; max-width: 1150px; box-shadow: 0px 0px 14px #0000001a;">
        <div class="grid grid-cols-3 h-40">
            <div class="col-span-1">
                <h1 class="text-4xl font-bold leading-relaxed">TAX INVOICE</h1>
                <img style="width: 14rem; margin-top: 0.5rem;" src="assets/images/saudiTemplateInvoice.png" alt="">
            </div>
            <div class="col-span-1">
                <ng-container *ngIf="invoiceData?.eSign">
                    <img src="{{invoiceData?.qrCodeWithUuid}}" alt="QR code" class="h-36 mx-auto" />
                </ng-container>
            </div>
            <div class="col-span-1 flex justify-end">
                <ng-container *ngIf="invoiceData?.companylogo">
                    <img [src]="invoiceData?.companylogo" alt="Logo" class="h-36" style="width: 15rem;" />
                </ng-container>
            </div>
        </div>
        <div class="grid grid-cols-5">
            <div class="col-span-2">
                <div class="text-base font-semibold leading-tight">Bill To</div>
                <div class="text-base font-semibold leading-tight">العميل</div>
                <div class="text-base font-semibold mt-4">{{invoiceData?.customerDetails?.customerId?.customerName | titlecase}}</div>
                <div class="leading-tight mt-4" style="font-size: 16px;">
                    {{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1}} <br />
                    {{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2}} <br />
                    {{invoiceData?.customerDetails?.customerId?.billingAddress?.city ? invoiceData?.customerDetails?.customerId?.billingAddress?.city + ',' : ''}} {{invoiceData?.customerDetails?.customerId?.billingAddress?.state}} <br />
                    {{invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}} <br />
                    {{invoiceData?.customerDetails?.customerId?.billingAddress?.country}} <br />
                    {{invoiceData?.customerDetails?.customerId?.mobileNumber}}
                </div>
            </div>
            <div class="col-span-1">
                <div class="text-base leading-tight">Invoice Number</div>
                <div class="text-base leading-tight">رقم الفاتورة</div>
                <div class="text-base leading-tight font-semibold">
                    {{invoiceData?.invoiceNumber}}
                </div>
                <div class="text-base leading-tight mt-4">Date</div>
                <div class="text-base leading-tight">التاريخ</div>
                <div class="text-base leading-tight font-semibold">{{invoiceData?.invoiceDate | date}}</div>
                <div class="text-base leading-tight mt-4">Due Date</div>
                <div class="text-base leading-tight">تاريخ الستحقاق</div>
                <div class="text-base leading-tight font-semibold">{{invoiceData?.dueDate | date}}</div>
            </div>
            <div class="col-span-2 ltr:text-right rtl:text-left">
                <div class="text-base leading-tight font-semibold">
                    {{invoiceData?.businessDetails?.businessId?.companyName}}
                </div>
                <div class="text-base leading-tight mt-4">
                    {{invoiceData?.businessDetails?.businessId?.address?.addressLineFirst}} <br />
                    {{invoiceData?.businessDetails?.businessId?.address?.addressLine2 ? invoiceData?.businessDetails?.businessId?.address?.addressLine2 + ',' : ''}} {{invoiceData?.businessDetails?.businessId?.address?.city ? invoiceData?.businessDetails?.businessId?.address?.city + ',' : '' }} {{invoiceData?.businessDetails?.businessId?.address?.state || ''}} <br />
                    {{invoiceData?.businessDetails?.businessId?.country}}
                </div>
                <ng-container *ngIf="invoiceData?.businessDetails?.businessId?.taxId">
                    <div class="text-base leading-tight mt-4">
                        Tax Registration Number
                    </div>
                    <div class="text-base leading-tight">رقم التسجيل الضريبي</div>
                    <div class="text-sm leading-tight">{{invoiceData?.businessDetails?.businessId?.taxId ? invoiceData?.businessDetails?.businessId?.taxId : '--'}}</div>
                </ng-container>
                <ng-container *ngIf="invoiceData?.businessDetails?.businessId?.entityId">
                    <div class="text-ba leading-tightse leading-tight mt-4">
                        Entity ID
                    </div>
                    <div class="text-base leading-tight">رقم التسجيل</div>
                    <div class="text-sm leading-tight">{{invoiceData?.businessDetails?.businessId?.entityId ? invoiceData?.businessDetails?.businessId?.entityId : '--'}}</div>
                </ng-container>
            </div>
        </div>
        <div style="min-height: 460px;">
            <table class="w-full mt-6">
                <thead>
                    <tr class="border-t-2 leading-10">
                        <th class="ltr:text-left rtl:text-right w-2/6" style="font-weight: 600; padding-bottom: 8px;">ITEM / Description</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">QTY</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">PRICE</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">AMOUNT</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">TAX</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">TAX VALUE</th>
                        <th style="font-weight: 600; padding-bottom: 8px;">Total</th>
                    </tr>
                    <tr class="border-t-2 leading-10">
                        <th class="ltr:text-left rtl:text-right" style="padding-bottom: 8px;">النتج</th>
                        <th style="padding-bottom: 8px;">الكمية</th>
                        <th style="padding-bottom: 8px;">السعر</th>
                        <th style="padding-bottom: 8px;">الجموع</th>
                        <th style="padding-bottom: 8px;">الضريبة</th>
                        <th style="padding-bottom: 8px;">قيمة الضريبة</th>
                        <th style="padding-bottom: 8px;">الجموع</th>
                    </tr>
                </thead>
                <tbody>
                    <ng-container *ngFor='let item of invoiceData?.items'>
                    <tr class="border-t-2 leading-10">
                        <td class="text-sm pt-2 text-gray-900" style="padding-bottom: 8px;">{{item.item}}</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{item.unit }}</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{item.unit * item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{item?.tax?.tax}}%</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{(item.unit * item.price) * ((item?.tax?.tax)/100) | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        <td class="text-sm pt-2 text-gray-900 text-center" style="padding-bottom: 8px;">{{(item.unit * item.price) + ((item.unit * item.price) * ((item?.tax?.tax)/100)) | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                    </tr>
                    <tr>
                        <td colspan="7" class="ps-14 text-sm pb-2 text-gray-900 italic mt-4 border-b-2 text-justify leading-tight" style="padding-bottom: 16px;">
                            {{item.itemDescription }}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
            <!-- <p
                class="ps-14 text-sm text-gray-900 italic mt-4 border-b-2 pb-2 text-justify leading-tight">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua. Nulla porttitor
                massa id neque. Integer eget aliquet nibh praesent tristique magna sit.
                Faucibus in ornare quam viverra. Dolor sit amet consectetur adipiscing
                elit pellentesque. Ac turpis egestas sed tempus urna et pharetra
                pharetra massa. Eget nullam non nisi est sit. Euismod elementum nisi
                quis eleifend quam adipiscing. Arnet nulla facilisi morbi tempus
                iaculis. Viverra tellus in hoc habitasse platea. Pellentesque dignissim
                enim sit amet venenatis urna cursus. Sit amet venenatis urna cursus eget
                nunc. Id diam maecenas ultricies mi eget mauris.
            </p> -->
            <div class="grid grid-cols-12 mt-8">
                <div class="col-span-7"></div>
                <div class="col-span-5">
                    <div class="grid grid-cols-2 px-2">
                        <div class="grid-cols-1">
                            <div class="text-size-14 leading-tight italic">Total before TAX</div>
                            <div class="text-size-14 leading-tight">الجمالي قبل الضريبة</div>
                        </div>
                        <div class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">{{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    </div>
                    <div *ngIf='invoiceData?.discount' class="grid grid-cols-2 px-2 pt-2">
                        <div class="grid-cols-1">
                            <div class="text-size-14 leading-tight italic">{{'Discount:' | translate }} ({{invoiceData?.discount | number : number}}%)</div>
                            <div class="text-size-14 leading-tight">الخصم</div>
                        </div>
                        <div class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">-{{invoiceData?.subtotal - invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    </div>
                    <div class="grid grid-cols-2 px-2 pt-2">
                        <div class="grid-cols-1">
                            <div class="text-size-14 leading-tight italic">
                                Total Taxable Amount
                            </div>
                            <div class="text-size-14 leading-tight">الجمالي الخاضع للضريبة</div>
                        </div>
                        <div *ngIf="!invoiceData?.discount" class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">{{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                        <div *ngIf="invoiceData?.discount" class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">{{invoiceData?.subtotal - (invoiceData?.subtotal - invoiceData?.discountValue) | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    </div>
                    <div class="grid grid-cols-2 px-2 pt-2">
                        <div class="grid-cols-1">
                            <div class="text-size-14 italic">TAX</div>
                            <div class="text-size-14">ضريبة القيمة الضافة</div>
                        </div>
                        <div class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">{{invoiceData?.tax | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    </div>
                    <div
                        class="grid grid-cols-2 px-2 pt-2 border-t-2 border-black font-bold mt-2">
                        <div class="grid-cols-1">
                            <div class="text-size-14 leading-tight">Total</div>
                            <div class="text-size-14 leading-tight">الجمالي مع الضريبة</div>
                        </div>
                        <div class="grid-cols-1 text-size-14 ltr:text-right rtl:text-left">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pdf-footer">
            <div class="grid grid-cols-6" style="min-height: 7rem;">
                <div *ngIf="invoiceData?.eSign" class="col-span-3">
                    <p class="text-sm font-bold leading-tight">
                        Electronically Signed / موقعة الكترونيا
                    </p>
                    <img class="w-48 mt-8" src="{{invoiceData?.eSign}}" />
                </div>
                <div *ngIf="!invoiceData?.eSign" class="col-span-3">
                    <img src="{{invoiceData?.qrCodeWithUuid}}" alt="QR code" class="h-36" />
                    <p class="text-sm leading-tight">
                        This QR code is encoded as per ZATCA e-invoiceing requirments
                    </p>
                    <p class="text-sm leading-tight">
                        رمز الستجابة السريعة مشفر بحسب متطلبات هيئة الزكاة والضريبة والجمارك
                        للفوترة اللكترونية
                    </p>
                </div>
                <div *ngIf="invoiceData?.notesTerms" class="col-span-3 flex justify-end flex-col">
                    <p class="font-bold text-sm">
                        Terms &amp; Conditions / الشروط والحكام
                    </p>
                    <p class="text-sm leading-tight text-justify">
                        {{invoiceData?.notesTerms}}
                    </p>
                </div>
            </div>
            <p *ngIf="invoiceData?.Footer" class="text-sm text-center m-4 leading-tight" style="min-height: 2rem;">
                {{invoiceData?.Footer}}
            </p>
            <div class="grid grid-cols-3 mt-4">
                <div class="col-span-1 text-sm">Powered by NuMetric</div>
                <div class="col-span-1 text-sm text-center">Page 1/1</div>
                <div class="col-span-1 text-sm ltr:text-right rtl:text-left">Issued By - {{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</div>
            </div>
        </div>
    </div>

    <!-- <div class="w-56 bg-white shadow-md m-auto py-6 px-8">
        <div class="grid grid-cols-3 h-40">
            <div class="col-span-1">
                <h1 class="text-4xl font-bold leading-relaxed">TAX INVOICE</h1>
                <h1 class="text-4xl font-bold leading-relaxed">فاتورة ضريبية</h1>
            </div>
            <div class="col-span-1">
                <img src="./qr.png" alt="QR code" class="h-36 mx-auto" />
            </div>
            <div class="col-span-1 ml-auto">
                <img src="./logo.png" alt="Logo" class="h-36" />
            </div>
        </div>
        <div class="grid grid-cols-5">
            <div class="col-span-2">
                <div class="text-base leading-tight font-semibold">Bill To</div>
                <div class="text-base leading-tight font-semibold">العميل</div>
                <div class="text-base leading-tight font-semibold mt-4">
                    Digital Dreams Lth.
                </div>
                <div class="text-sm leading-tight mt-4">
                    456 Tech Parkway, Silicon Valley, SV 67890 Silicon Valley,
                    California <br />
                    67890 <br />
                    United States <br />
                    1456123456789
                </div>
            </div>
            <div class="col-span-1">
                <div class="text-base leading-tight">Invoice Number</div>
                <div class="text-base leading-tight">رقم الفاتورة</div>
                <div class="text-base leading-tight font-semibold">
                    INV2024-000076
                </div>
                <div class="text-base leading-tight mt-4">Date</div>
                <div class="text-base leading-tight">التاريخ</div>
                <div class="text-base leading-tight font-semibold">01-05-2024</div>
                <div class="text-base leading-tight mt-4">Due Date</div>
                <div class="text-base leading-tight">تاريخ الستحقاق</div>
                <div class="text-base leading-tight font-semibold">01-05-2024</div>
            </div>
            <div class="col-span-2 text-right">
                <div class="text-base leading-tight font-semibold">
                    BrightPath Innovations
                </div>
                <div class="text-base leading-tight mt-4">
                    185 Wasfi AITal Street <br />
                    Ammon Oasis Complex Office 507 Amman,<br />
                    Amman Governorate Jordan
                </div>
                <div class="text-base leading-tight mt-4">
                    Tax Registration Number
                </div>
                <div class="text-base leading-tight">رقم التسجيل الضريبي</div>
                <div class="text-sm leading-tight">12345678</div>
                <div class="text-base leading-tight mt-4">
                    Commercial Registration Certificate
                </div>
                <div class="text-base leading-tight">رقم التسجيل التجاري</div>
                <div class="text-sm leading-tight">12345678</div>
            </div>
        </div>
        <table class="w-full mt-6">
            <thead>
                <tr class="border-t-2 leading-10">
                    <th class="text-left w-2/6">ITEM / Description</th>
                    <th>QTY</th>
                    <th>PRICE</th>
                    <th>AMOUNT</th>
                    <th>TAX</th>
                    <th>TAX VALUE</th>
                    <th>Total</th>
                </tr>
                <tr class="border-t-2 leading-10">
                    <th class="text-left">النتج</th>
                    <th>الكمية</th>
                    <th>السعر</th>
                    <th>الجموع</th>
                    <th>الضريبة</th>
                    <th>قيمة الضريبة</th>
                    <th>الجموع</th>
                </tr>
            </thead>
            <tbody>
                <tr class="border-t-2 leading-10">
                    <td class="text-sm text-gray-900">Services</td>
                    <td class="text-sm text-gray-900 text-center">50</td>
                    <td class="text-sm text-gray-900 text-center">SAR 100.000</td>
                    <td class="text-sm text-gray-900 text-center">SAR 5000.000</td>
                    <td class="text-sm text-gray-900 text-center">15%</td>
                    <td class="text-sm text-gray-900 text-center">SAR 750.000</td>
                    <td class="text-sm text-gray-900 text-center">SAR 5750.000</td>
                </tr>
            </tbody>
        </table>
        <p
            class="ps-14 text-sm text-gray-900 italic mt-4 border-b-2 pb-2 text-justify leading-tight">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Nulla porttitor
            massa id neque. Integer eget aliquet nibh praesent tristique magna sit.
            Faucibus in ornare quam viverra. Dolor sit amet consectetur adipiscing
            elit pellentesque. Ac turpis egestas sed tempus urna et pharetra
            pharetra massa. Eget nullam non nisi est sit. Euismod elementum nisi
            quis eleifend quam adipiscing. Arnet nulla facilisi morbi tempus
            iaculis. Viverra tellus in hoc habitasse platea. Pellentesque dignissim
            enim sit amet venenatis urna cursus. Sit amet venenatis urna cursus eget
            nunc. Id diam maecenas ultricies mi eget mauris.
        </p>
        <div class="grid grid-cols-12 mt-8">
            <div class="col-span-7"></div>
            <div class="col-span-5">
                <div class="grid grid-cols-2 px-2">
                    <div class="grid-cols-1">
                        <div class="text-base leading-tight italic">Total before VAT</div>
                        <div class="text-base leading-tight">الجمالي قبل الضريبة</div>
                    </div>
                    <div class="grid-cols-1 ml-auto">SAR 5000.000</div>
                </div>
                <div class="grid grid-cols-2 px-2 pt-2">
                    <div class="grid-cols-1">
                        <div class="text-base leading-tight italic">Discount 10%</div>
                        <div class="text-base leading-tight">الخصم</div>
                    </div>
                    <div class="grid-cols-1 ml-auto">SAR 500.000</div>
                </div>
                <div class="grid grid-cols-2 px-2 pt-2">
                    <div class="grid-cols-1">
                        <div class="text-base leading-tight italic">
                            Total Taxable Amount
                        </div>
                        <div class="text-base leading-tight">الجمالي الخاضع للضريبة</div>
                    </div>
                    <div class="grid-cols-1 ml-auto">SAR 4500.000</div>
                </div>
                <div class="grid grid-cols-2 px-2 pt-2">
                    <div class="grid-cols-1">
                        <div class="text-base leading-tight italic">VAT 15%</div>
                        <div class="text-base leading-tight">ضريبة القيمة الضافة</div>
                    </div>
                    <div class="grid-cols-1 ml-auto">SAR 675.000</div>
                </div>
                <div
                    class="grid grid-cols-2 px-2 pt-2 border-t-2 border-black font-bold mt-2">
                    <div class="grid-cols-1">
                        <div class="text-base leading-tight">Total</div>
                        <div class="text-base leading-tight">الجمالي مع الضريبة</div>
                    </div>
                    <div class="grid-cols-1 ml-auto">SAR 675.000</div>
                </div>
            </div>
        </div>
        <div class="grid grid-cols-5 mt-8">
            <div class="col-span-2">
                <p class="text-sm font-bold leading-tight">
                    Electronically Signed / موقعة الكترونيا
                </p>
            </div>
            <div class="col-span-3">
                <p class="font-bold text-sm">
                    Terms &amp; Conditions / الشروط والحكام
                </p>
                <p class="text-sm leading-tight text-justify">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                    eiusmod tempor incididunt ut labore et dolore magna aliqua. Nulla
                    porttitor massa id neque. Integer eget aliquet nibh praesent
                    tristique magna sit. Faucibus in ornare quam viverra. Dolor sit amet
                    consectetur adipiscing elit pellentesque. Ac turpis egestas sed
                    tempus urna et pharetra pharetra massa. Eget nullam non nisi est
                    sit. Euismod elementum nisi quis eleifend quam adipiscing. Arnet
                    nulla facilisi morbi tempus iaculis.
                </p>
            </div>
        </div>
        <p class="text-sm text-center m-4 leading-tight">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
            tempor incididunt ut labore et dolore magna aliqua. Nulla porttitor
            massa id neque. Integer eget aliquet nibh praesent tristique magna sit.
            Faucibus in ornare quam viverra. Dolor sit amet
        </p>
        <div class="grid grid-cols-3 mt-4">
            <div class="col-span-1 text-sm">Powered by sa.numetric.work</div>
            <div class="col-span-1 text-sm text-center">Page 2/2</div>
            <div class="col-span-1 text-sm text-right">Issued By - Omar</div>
        </div>
    </div> -->
</app-document-container>