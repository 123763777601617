import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ContactComponent } from './component/contact/contact.component';
import { LandingPageComponent } from './component/landing-page/landing-page.component';
import { LandingComponent } from './landing.component';
import { ZapierComponent } from "./component/zapier/zapier.component";
import { TermandconditionsComponent } from './component/termandconditions/termandconditions.component';
import { PrivacypolicyComponent } from './component/privacypolicy/privacypolicy.component';
import { EulaComponent } from './component/eula/eula.component';
import { ZapierWorkingComponent } from './component/zapier-working/zapier-working.component';


const routes: Routes = [
    {
        path: '',
        component: LandingComponent,
        children:[
            {
                path:'',
                component:LandingPageComponent
            },
            {
                path: 'contact',
                component: ContactComponent,
            },
            {
                path: 'zapier',
                component: ZapierComponent
            },
            {
                path:'termandconditions',
                component: TermandconditionsComponent
            },
            {
                path:'privacyandpolicy',
                component:PrivacypolicyComponent
            },
            {
                path:'eula',
                component:EulaComponent
            },
            {
                path:'connect-with-zapier',
                component: ZapierWorkingComponent
            }
        ]
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class LandingRoutingModule {}
