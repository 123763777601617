<div class="w-full">
  <form [formGroup]="form">
    <div class="flex gap-2">
      <div class="w-52">
        <label
          for="category"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Category" | translate }}</label
        >
        <ng-select
          [items]="category"
          class="new-dropdown"
          placeholder="Select one"
          formControlName="category"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item }}
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="w-52">
        <label
          for="status"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Status" | translate }}</label
        >
        <ng-select
          [items]="status"
          class="new-dropdown"
          placeholder="Select one"
          formControlName="status"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item }}
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
      <div class="w-52">
        <label
          for="customDateRangefrom"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Custom range from" | translate }}</label
        >
        <input
          id="customDateRangefrom"
          formControlName="customDateRangefrom"
          type="date"
          class="new-date"
        />
        <div *ngIf="formErrors.customDateRangefrom" class="text-red-500 ms-4">
          {{ formErrors.customDateRangefrom }}
        </div>
      </div>
      <div class="w-52">
        <label
          for="customDateRangeTo"
          class="block text-sm Poppins-Medium font-color-05"
          >{{ "Custom range to" | translate }}</label
        >
        <input
          type="date"
          formControlName="customDateRangeTo"
          class="new-date"
        />
        <div *ngIf="formErrors.customDateRangeTo" class="text-red-500 ms-4">
          {{ formErrors.customDateRangeTo }}
        </div>
      </div>
    </div>
  </form>
</div>
