import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-business-and-stores',
  templateUrl: './business-and-stores.component.html',
})
export class BusinessAndStoresComponent implements OnInit {
  constructor() {}
  showStoreCreate = false;
  showBusinessUpdate = false;
  businessId = null;
  storeId = null;

  ngOnInit(): void {}
}
