import { Component, Input, OnInit } from '@angular/core';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss'],
})
export class UserSettingsComponent implements OnInit {
  constructor(private utility: UtilityService) {
    const browser = window.navigator.userAgent.toLowerCase();
    this.isSafari = browser.includes('safari') && !browser.includes('chrome');
  }
  @Input() modalType;
  isSafari = false;
  ngOnInit(): void {}

  closeModal(value) {
    if (value?.target?.id === 'modal-parent-click')
      this.utility.showHideSettingsModal.next({ open: false });
  }
}
