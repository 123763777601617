<div class="space-y-4 px-14 py-11 bg-white rounded-lg">
  <div class="flex items-center justify-between">
    <div></div>
    <!-- <h1 class="text-2xl Poppins-Bold inline-flex items-center"> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
              </svg> -->
    <!-- <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                <path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/>
            </svg>
            {{'Estimates' | translate}}
        </h1> -->
    <app-button
      appAccessControl
      moduleType="sales"
      accessType="edit"
      type="primary"
      buttonText="{{ 'Create an estimate' | translate }}"
      size="large"
      routerLink="create-estimate"
    ></app-button>
  </div>
  <app-table-container
    [tableHeadings]="tableHeadings"
    [tableData]="tableData"
    [keys]="tableKeys"
    [actionTemplate]="actionTemplate"
  >
    <ng-template let-item #actionTemplate>
      <td align="right" class="p-2 last:pe-10 text-center">
        <app-dropdown [isFromSettings]="true" #dropdown>
          <div
            button
            (click)="dropdown.open = true"
            (clickOutside)="dropdown.open = false"
          >
            <div
              class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-4 w-4 m-auto leading-8 text-center"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M19 9l-7 7-7-7"
                />
              </svg>
            </div>
          </div>
          <div options class="new-shadow p-1 text-start">
            <div
              (click)="viewEstimate(item._id)"
              class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ "View" | translate }}
            </div>
            <div
              (click)="updateEstimate(item._id)"
              appAccessControl
              moduleType="sales"
              accessType="edit"
              class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ "Edit" | translate }}
            </div>
            <div
              (click)="deleteEstimate(item._id)"
              appAccessControl
              moduleType="sales"
              accessType="edit"
              class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ "Delete" | translate }}
            </div>
          </div>
        </app-dropdown>
      </td>
    </ng-template>
  </app-table-container>
</div>

<app-modal-container [isOpen]="deleteOpenModal">
  <div header class="text-start border-b pb-2 Poppins-Bold">
    {{ "Delete Estimate" | translate }}
  </div>
  <div class="text-start" content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Delete' | translate }}"
      type="danger"
      rounded="true"
      (handleClick)="deleteEstimateslist()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="deleteOpenModal = false"
    ></app-button>
  </div>
</app-modal-container>
