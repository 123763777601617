import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from './accounting.service';

@Component({
  selector: 'app-accounting',
  templateUrl: './accounting.component.html',
  styleUrls: ['./accounting.component.scss']
})
export class AccountingComponent implements OnInit {

  constructor(private accountingService: AccountingService,
              private rootStore: Store<RootReducerState>,
              private spinner: NgxSpinnerService) {
              this.business$ = this.rootStore.pipe(select(selectBusiness));
               }

  business$: Observable<any>;
  businessId = null;
  unsubscribe$ = new Subject();

  apiData = [
  ];

  ngOnInit(): void {
    // this.loadAccount();
  }

  loadAccount(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.accountingService.getAllAccounts(business.businessId._id).subscribe(resp => {
          this.spinner.hide();
          this.accountingService.setAccountsInStore(resp.data);
        }, (error) => {
          this.spinner.hide();
          console.log(error);
        });
      }
    });
  }
}
