import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceCustomizationService } from '../../invoice-customization.service';

@Component({
  selector: 'app-cash-invoice-sms-settings',
  templateUrl: './cash-invoice-sms-settings.component.html',
  styleUrls: ['./cash-invoice-sms-settings.component.scss']
})
export class CashInvoiceSmsSettingsComponent implements OnInit {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
    private spinner: NgxSpinnerService) { }

sendSMS = new FormControl(true);
response = null;
error = null;
ngOnInit(): void {
this.invoiceCustomizationService.invoiceCustomizationSettings$.subscribe((settings) => {
  this.sendSMS.patchValue(settings?.sendSMS ?? false);
});
}

saveChanges(): void {
this.spinner.show();
this.invoiceCustomizationService.saveInvoiceCustomizationSettings({sendSMS: this.sendSMS.value}).subscribe((resp) => {
this.response = 'Changes Saved';
setTimeout(() => this.response = '', 2000);
this.spinner.hide();
}, (error) => {
this.spinner.hide();
this.error = error?.error?.message || 'Internal server error';
});
}

}
