<div class="flex justify-center px-14 py-11 bg-white rounded-lg">
  <div class="w-full flex flex-col space-y-4">
    <div class="w-full m-auto">
      <form class="formSelect" [formGroup]="createCustomerForm">
        <div class="bg-white rounded-xl">
          <div class="bg-profile py-6">
            <h3 *ngIf="!update" class="Poppins-Medium leading-5 text-lg mb-4">
              {{ "Customer Details" | translate }}
            </h3>
          </div>
          <div class="space-y-6 w-full">
            <div class="grid grid-cols-3 gap-8">
              <div class="flex flex-col gap-[10px]">
                <label
                  for="customerName"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Customer Name" | translate }} <span class="text-red-500">*</span></label
                >
                <input
                  type=""
                  id="customerName"
                  formControlName="customerName"
                  class="new-input"
                  placeholder="{{ 'Customer Name' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.customerName"
                >
                  {{ formErrors.customerName }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="firstName"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "First Name" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="firstName"
                  formControlName="firstName"
                  type=""
                  class="new-input"
                  placeholder="{{ 'First Name' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.firstName"
                >
                  {{ formErrors.firstName }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="lastName"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Last Name" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="lastName"
                  formControlName="lastName"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Last Name' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.lastName"
                >
                  {{ formErrors.lastName }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="countryCode"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Country Code" | translate }}<span class="text-red-500">*</span></label
                >
                <fieldset>
                  <ng-select
                    [items]="countryWithCode"
                    class="new-dropdown"
                    bindLabel="text"
                    bindValue="code"
                    formControlName="countryCode"
                    placeholder="{{ 'Select Country Code' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.text }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div
                    class="text-sm text-red-500"
                    *ngIf="formErrors.countryCode"
                  >
                    {{ formErrors.countryCode }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="mobileNumber"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Mobile Number" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="mobileNumber"
                  pattern="^[1-9][0-9]*$"
                  oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                  formControlName="mobileNumber"
                  type="number"
                  class="new-input"
                  placeholder="{{ 'Mobile Number' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.mobileNumber"
                >
                  {{ formErrors.mobileNumber }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="email"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Email Address" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="email"
                  formControlName="email"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Email Address' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.email"
                >
                  {{ formErrors.email }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="taxId"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Tax ID" | translate }}</label
                >
                <input
                  id="taxId"
                  formControlName="taxId"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Tax Id' | translate }}"
                />
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="tollFree"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Landline" | translate }}</label
                >
                <input
                  id="tollFree"
                  formControlName="tollFree"
                  type="number"
                  class="new-input"
                  placeholder="{{ 'Landline' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.tollFree"
                >
                  {{ formErrors.tollFree }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="fax"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Fax" | translate }}</label
                >
                <input
                  id="fax"
                  formControlName="fax"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Fax' | translate }}"
                />
              </div>
            </div>
            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex flex-col gap-[10px] w-full">
                <label
                  for="webSite"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Website" | translate }}</label
                >
                <input
                  id="webSite"
                  formControlName="webSite"
                  type="text"
                  class="new-input"
                />
              </div>
            </div>

            <div class="flex space-x-8 rtl:space-x-reverse w-full">
              <div class="flex flex-col gap-[10px] w-full">
                <label
                  for="notes"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Notes" | translate }}</label
                >
                <textarea
                  id="notes"
                  formControlName="notes"
                  class="new-input !h-40"
                  placeholder="{{ 'Add some notes' | translate }}"
                ></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white mt-6 rounded-xl">
          <div class="bg-profile py-6">
            <h3 class="Poppins-Medium leading-5 text-lg mb-4">
              {{ "Billing Details" | translate }}
            </h3>
          </div>
          <div sclass="space-y-6 w-full">
            <div class="grid grid-cols-3 gap-8 w-full">
              <div class="flex flex-col gap-[10px]">
                <label
                  for="addressLine1"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Address Line 1" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="addressLine1"
                  formControlName="addressLine1"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Address Line 1' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.addressLine1"
                >
                  {{ formErrors.addressLine1 }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="addressLine2"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Address Line 2" | translate }}</label
                >
                <input
                  id="addressLine2"
                  formControlName="addressLine2"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Address Line 2' | translate }}"
                />
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="country"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Country" | translate }}<span class="text-red-500">*</span></label
                >
                <ng-select
                  [items]="countries"
                  class="new-dropdown"
                  bindLabel="text"
                  bindValue="text"
                  formControlName="country"
                  (change)="onBillingCountryChange($event)"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.text }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.country"
                >
                  {{ formErrors.country }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label for="state" class="text-base Poppins-Medium leading-5 flex gap-1">{{
                  "State" | translate
                }}<span class="text-red-500">*</span></label>
                <ng-select
                  [items]="billingStates"
                  class="new-dropdown"
                  bindLabel="text"
                  bindValue="text"
                  formControlName="state"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.text }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <!-- <select formControlName='state' id='state-select' class="bg-input h-12 rounded-xl w-full ps-5 pr-5">
                  </select> -->
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.state"
                >
                  {{ formErrors.state }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="city"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "City" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="city"
                  formControlName="city"
                  type=""
                  class="new-input"
                  placeholder="{{ 'City' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.city"
                >
                  {{ formErrors.city }}
                </div>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label
                  for="postalCode"
                  class="text-base Poppins-Medium leading-5 flex gap-1"
                  >{{ "Postal Code" | translate }}<span class="text-red-500">*</span></label
                >
                <input
                  id="postalCode"
                  formControlName="postalCode"
                  type=""
                  class="new-input"
                  placeholder="{{ 'Postal Code' | translate }}"
                />
                <div
                  class="text-sm text-red-500 ms-2"
                  *ngIf="formErrors.postalCode"
                >
                  {{ formErrors.postalCode }}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bg-white mt-6 rounded-xl">
          <div class="bg-profile py-6 flex items-center">
            <span class="Poppins-Medium leading-5 text-lg me-4">{{
              "Shipping Details" | translate
            }}</span>
            <span class="me-2">{{ "Same as Billing" | translate }}</span>
            <input
              type="checkbox"
              name="sameAsBilling"
              (change)="sameAsBilling()"
              [(ngModel)]="sameAsBillingAddress"
              [ngModelOptions]="{ standalone: true }"
            />
          </div>
          <div class="space-y-6 w-full">
            <div class="flex w-full">
              <div class="grid grid-cols-3 gap-8 w-full">
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shipTo"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Ship To" | translate }}</label
                  >
                  <input
                    type=""
                    id="shipTo"
                    formControlName="shipTo"
                    class="new-input"
                    placeholder="{{ 'Customer Name' | translate }}"
                  />
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.shipTo"
                  >
                    {{ formErrors.shipTo }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingaddressLine1"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Address Line 1" | translate }}</label
                  >
                  <input
                    id="shippingaddressLine1"
                    formControlName="shippingaddressLine1"
                    type=""
                    class="new-input"
                    placeholder="{{ 'Address Line 1' | translate }}"
                  />
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.shippingaddressLine1"
                  >
                    {{ formErrors.shippingaddressLine1 }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingLine2"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Address Line 2" | translate }}</label
                  >
                  <input
                    id="shippingLine2"
                    formControlName="shippingLine2"
                    type=""
                    class="new-input"
                    placeholder="{{ 'Address Line 2' | translate }}"
                  />
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="country-select2"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Country" | translate }}<span class="text-red-500">*</span></label
                  >
                  <ng-select
                    [items]="countries"
                    class="new-dropdown"
                    bindLabel="text"
                    bindValue="text"
                    formControlName="shippingcountry"
                    (change)="onShippingCountryChange($event)"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.text }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.country"
                  >
                    {{ formErrors.country }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="state-select2"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "State" | translate }}<span class="text-red-500">*</span></label
                  >
                  <ng-select
                    [items]="shippingStates"
                    class="new-dropdown"
                    bindLabel="text"
                    bindValue="text"
                    formControlName="shippingstate"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.text }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.state"
                  >
                    {{ formErrors.state }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingcity"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "City" | translate }}</label
                  >
                  <input
                    id="shippingcity"
                    formControlName="shippingcity"
                    type=""
                    class="new-input"
                    placeholder="{{ 'City' | translate }}"
                  />
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.shippingcity"
                  >
                    {{ formErrors.shippingcity }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingpostalCode"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Postal Code" | translate }}</label
                  >
                  <input
                    id="shippingpostalCode"
                    formControlName="shippingpostalCode"
                    type=""
                    class="new-input"
                    placeholder="{{ 'Postal Code' | translate }}"
                  />
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.shippingpostalCode"
                  >
                    {{ formErrors.shippingpostalCode }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingmobileNumber"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Mobile Number" | translate }}<span class="text-red-500">*</span></label
                  >
                  <input
                    id="shippingmobileNumber"
                    formControlName="shippingmobileNumber"
                    type="number"
                    class="new-input"
                    placeholder="{{ 'Mobile Number' | translate }}"
                  />
                  <div
                    class="text-sm text-red-500 ms-2"
                    *ngIf="formErrors.shippingmobileNumber"
                  >
                    {{ formErrors.shippingmobileNumber }}
                  </div>
                </div>
                <div class="flex flex-col gap-[10px]">
                  <label
                    for="shippingemailAddress"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Email Address" | translate }}</label
                  >
                  <input
                    id="shippingemailAddress"
                    formControlName="shippingemailAddress"
                    type=""
                    class="new-input"
                    placeholder="{{ 'Email Address' | translate }}"
                  />
                </div>
                <div class="flex flex-col gap-[10px] col-span-2">
                  <label
                    for="instruction"
                    class="text-base Poppins-Medium leading-5 flex gap-1"
                    >{{ "Delivery Instructions" | translate }}</label
                  >
                  <textarea
                    id="instruction"
                    formControlName="instruction"
                    class="new-input !h-28"
                    placeholder="{{ 'Write Here...' | translate }}"
                  ></textarea>
                </div>
                <div
                  appAccessControl
                  moduleType="sales"
                  accessType="edit"
                  style="margin-block-end: auto"
                  class="flex flex-col gap-6 rtl:space-x-reverse col-span-1 w-full items-center"
                >
                  <app-submit
                    [value]="update ? 'Update' : 'Create'"
                    className="bg-[#026AA2] text-white w-44"
                    (clicked)="update ? updateCustomer() : createCustomer()"
                  ></app-submit>
                  <app-submit
                    value="Discard"
                    className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn"
                    routerLink="../"
                  ></app-submit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>
