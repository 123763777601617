import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AccessControlGuard } from './guards/access-control.guard';
import { LandingComponent } from './modules/landing/landing.component';
import { PayrollComponent } from './modules/payroll/payroll.component';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./modules/landing/landing.module').then((m) => m.LandingModule)
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then((m) => m.AuthModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then((m) => m.DashboardModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/settings/settings.module').then((m) => m.SettingsModule)
  },
  {
    path: 'plus',
    loadChildren: () => import('./modules/numplus/numplus.module').then((m) => m.NumplusModule)
  },
  {
    path: 'sales',
    canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/sales/sales.module').then((m) => m.SalesModule)
  },
  {
    path: 'purchases',
    canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/purchases/purchases.module').then((m) => m.PurchasesModule)
  },
  {
    path: 'accounting',
    canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/accounting/accounting.module').then((m) => m.AccountingModule)
  },
  {
    path: 'time-sheet',
    canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/time-sheet/time-sheet.module').then((m) => m.TimeSheetModule)
  },
  {
    path: 'reports',
    // canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/reports/reports.module').then((m) => m.ReportsModule)
  },
  {
    path: 'open',
    loadChildren: () => import('./modules/open/open.module').then((m) => m.OpenModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./modules/projects/projects.module').then((m) => m.ProjectsModule)
  },
  {
    path: 'inventory',
    loadChildren: () => import('./modules/inventory/inventory.module').then((m) => m.InventoryModule)
  },
  {
    path: 'experts',
    canActivate: [AccessControlGuard],
    loadChildren: () => import('./modules/experts/experts.module').then((m) => m.ExpertsModule)
  },
  {
    path: 'chats',
    loadChildren: () => import('./modules/chats/chats.module').then((m) => m.ChatsModule)
  },
  {
    path: 'payroll',
    component: PayrollComponent
  },
  {
    path: 'storage',
    loadChildren: () => import('./modules/storage/storage.module').then(m => m.StorageModule)
  },
  {
    path: 'task',
    loadChildren: () => import('./modules/task-manager/task-manager.module').then(m=>m.TaskManagerModule)
  },
  {
    path: 'files',
    loadChildren: () => import('./modules/file-manager/file-manager.module').then(m=>m.FileManagerModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
