import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CreateInvoiceComponent } from './components/create-invoice/create-invoice.component';
import { InvoicesListComponent } from './components/invoices-list/invoices-list.component';
import { ViewInvoiceComponent } from './components/view-invoice/view-invoice.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ReceiptComponent } from 'src/app/shared/documents/receipt/receipt.component';

const routes: Routes = [
  {
    path: '',
    component: InvoicesListComponent
  },
  {
    path: 'invoice-list',
    component: InvoicesListComponent
  },
  {
    path: 'create-invoice',
    component: CreateInvoiceComponent
  },
  {
    path: 'view-invoice',
    component: ViewInvoiceComponent
  },
  {
    path: 'view-receipt/:id',
    component: ReceiptComponent
  }
];

@NgModule({
  declarations: [
    CreateInvoiceComponent,
    InvoicesListComponent,
    ViewInvoiceComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    ClickOutsideModule,
    NgxSpinnerModule,
    NgSelectModule,
    FormsModule,
    NgxPaginationModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class InvoicesModule { }
