import { Injectable } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import * as moment from "moment";
import { Observable } from "rxjs";
import { HttpService } from "src/app/shared/services/http.service";
import { NumberService } from "src/app/shared/services/number.service";
import { SalesService } from "../../sales.service";

@Injectable({
    providedIn: 'root'
})

export class CustomerStatementService {

    constructor (private salesService: SalesService,
        private httpService: HttpService,
        private numberService: NumberService,
        private translateService: TranslateService) {}

    loadCustomer(id): Observable<any> {
        return this.salesService.getCustomerDetails(id);
    }

    loadCustomerActivity(data): Observable<any> {
        return this.salesService.getCustomerActivity(data);
    }

    getInvoiceCustomizationSettings(businessId): Observable<any> {
        return this.httpService.get(`/setting/getTemplateConfiguration/${businessId}`);
    }

    sendStatementEmail(data): Observable<any> {
        return this.salesService.sendStatementEmail(data);
    }

    generateOutstandingData(config, data): any {
        let outstandingBalance = 0;
        let overdueAmount = 0;
        let yetToBePaid = 0;
        const tableData = data
        .filter((invoice) => {
        if (invoice.status.primaryStatus === 'Overdue' || invoice.status.primaryStatus === 'Unpaid') {
            return invoice
        }
        })
        .map((invoice) => {
        outstandingBalance += invoice.dueAmount;
        if (invoice.status.primaryStatus === 'Overdue') {
            overdueAmount += invoice.dueAmount;
        }
        if (invoice.status.primaryStatus === 'Unpaid') {
            yetToBePaid += invoice.dueAmount;
        }
        return {
            totalAmount: this.numberService.currencier(invoice?.totalAmount),
            dueAmount: this.numberService.currencier(invoice?.dueAmount),
            paidAmount: this.numberService.currencier((invoice?.totalAmount - invoice?.dueAmount)),
            dueDate: invoice?.dueDate.split('T')[0],
            invoiceDate: invoice?.invoiceDate?.split('T')[0],
            invoiceNumber: invoice?.invoiceNumber,
            status: invoice?.status?.primaryStatus
        };
        });
        const newConfig = {
        ...config,
        tableHeadings: ['Invoice Number', 'Invoice Date', 'Due Date', 'Status', 'Total', 'Paid', 'Due'],
        tableKeys: ['invoiceNumber', 'invoiceDate', 'dueDate', 'status', 'totalAmount','paidAmount', 'dueAmount'],
        tableData,
        type: this.translateService.instant('Outstanding invoices'),
        quickStats: [
            {
            type: this.translateService.instant(`Overdue amount`),
            value: this.numberService.currencier(overdueAmount)
            },
            {
            type: this.translateService.instant('Yet to be paid'),
            value: this.numberService.currencier(yetToBePaid)
            },
            {
            type: this.translateService.instant('Outstanding Balance'),
            value: this.numberService.currencier(outstandingBalance)
            }
        ]
        }
        return newConfig;
    }

    generateActivityData(config, data): any {
        // let invoiced = 0;
        // let paid = 0;
        console.log(data);
        
        const tableData = data
        .map((invoice) => {
        return {
            amount: invoice?.credit ? this.numberService.toFixed(invoice?.credit) : null || this.numberService.toFixed(invoice?.debit),
            credit: this.numberService.toFixed(invoice?.credit) ?? 0,
            debit: this.numberService.toFixed(invoice?.debit) ?? 0,
            balance: this.numberService.toFixed(invoice?.balanced) ?? 0,
            date: moment(invoice?.date?.split('T')[0]).format('DD-MM-YYYY'),
            description: invoice?.description,
            transactionNumber: invoice?.transactionNumber,
            item: invoice.description 
        };
        });
        const openingBalance  = {
            date: config.fromDate,
            item: this.translateService.instant("Opening Balance"),
            balance: this.numberService.toFixed(config?.openingBalnce || 0),
          }
          tableData.unshift(openingBalance);
          const closingBalance = {
            date: config.endDate,
            item: this.translateService.instant("Closing Balance"),
            balance: this.numberService.toFixed(tableData[tableData.length - 1]?.balance || 0),
        } 
        tableData.push(closingBalance);
        const newConfig = {
            ...config,
            tableHeadings: ['Date', 'Descrption', 'Transaction Number', 'Amount', 'Balance'],
            tableKeys: ['date', 'item', 'transactionNumber', 'amount', 'balance'],
            tableData: tableData.map(item=>({
                ...item,
                amount: this.numberService.currencier(item?.amount || 0),
                balance: this.numberService.currencier(item?.balance),
            })),
            type: this.translateService.instant('Account Activity'),
            quickStats: [
                {
                    type: `${this.translateService.instant('Opening Balance on')} ${config.fromDate}`,
                    value: this.numberService.currencier(config.openingBalnce)
                },
                {
                    type: `${this.translateService.instant('Closing Balance on')} ${config.endDate}`,
                    value: this.numberService.currencier(closingBalance?.balance)
                }
            ]
        }
        return newConfig;
    }

    createCustomerStatementPdf(data): Observable<any> {
        return this.salesService.createCustomerStatementPdf(data);
    }
}