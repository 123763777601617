import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RootReducerState } from '../reducers';
import * as fromUser from '../reducers/user.reducer';

const getUserState = (state: RootReducerState) => state.user;

export const selectUser = createSelector(getUserState, fromUser.getUser);
export const selectUserBusinesses = createSelector(getUserState, fromUser.getUserBusinesses);
export const selectUserEmails = createSelector(getUserState, fromUser.getUserEmails);
export const selectUserLoading = createSelector(getUserState, fromUser.getLoading);
export const selectUserError = createSelector(getUserState, fromUser.getError);
export const selectUserSubscription = createSelector(getUserState, fromUser.getUserSubscription);
