<div class="p-8">
    <div class="flex justify-center bg-white p-8 rounded-lg" inventoryCheck>
        <div class="w-full flex flex-col space-y-4">
            <div>
                <div class="flex items-center justify-between">
                    <div class="">
                        <!-- <h1 class="text-2xl Poppins-Medium inline-flex items-center"> -->
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z" />
                              </svg> -->
                            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                                <path d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"/>
                            </svg>
                            {{'Write Off Products' | translate}}
                        </h1> -->
                    </div>
                    <div class="">
                        <app-button appAccessControl moduleType='sales' accessType='edit' size='large' buttonText='{{"Create Write Off" | translate}}' (click)="openWriteOff = true;" type='primary'></app-button>
                    </div>
                </div>
            </div>
            <app-table-container [tableHeadings]='tableHeadings' [tableData]='tableData' [keys]='tableKeys'>
            </app-table-container>
        </div>
    </div>
</div>


<div routeToSubscription fromComponent='inventory' class="hidden absolute bottom-6 z-[29] w-[456px] left-0 right-0 m-auto h-4/6">
    <app-send-to-sub from="inventory"></app-send-to-sub>
</div>


<app-modal-container [isOpen]='openWriteOff'>
    <div header class="Poppins-Medium">{{ "Create Write Off Product" | translate}}</div>
    <div content class="flex flex-col">
        <div class="w-full mb-3">
            <label for='Paytabs Country' class="items-center space-x-2 block text-sm Poppins-Medium font-color-05 pb-2">
                {{'Item to Write Off' | translate }}
            </label>
            <ng-select class="new-dropdown" [clearable]="false" bindLabel="name" bindValue="_id" [(ngModel)]="selectedItemToWriteOff" [items]="allToWriteOffItems" placeholder="Select Item">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                        <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                        {{ item.name }}
                        </div>
                    </div>
                </ng-template>
            </ng-select> 
        </div>
        <div class="w-full mb-3">
            <label for='Paytabs Country' class="items-center space-x-2 block text-sm Poppins-Medium font-color-05 pb-2">
                {{'Account' | translate }}
            </label>
            <ng-select class="new-dropdown" [clearable]="false" bindLabel="accountName" bindValue="_id" [(ngModel)]="selectedAccountToWriteOff" [items]="accountsList" placeholder="Select Account">
                <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                        <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                        {{ item.accountName }}
                        </div>
                    </div>
                </ng-template>
            </ng-select> 
        </div>
        <div class="flex mb-3">
            <div class="w-full">
                <label for="" class="block text-sm Poppins-Medium font-color-05 pb-2">{{'Quantity' | translate}}</label>
                <input [(ngModel)]='quantity' type="number" class="new-input" placeholder="{{'Enter quantity' | translate}}" >
            </div>
        </div>
    </div>
    <div footer class='flex gap-x-2 p-2'>
      <app-submit type='primary' className="bg-[#026AA2] text-white w-44" value='{{"Run" | translate}}' (clicked)='submitWriteOff()'></app-submit>
      <app-submit type='third' className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn" value='{{"Cancel" | translate}}' (clicked)='openWriteOff = false; quantity = null; selectedItemToWriteOff = null;'></app-submit>
    </div>
</app-modal-container>
