import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CollapsibleSidebarService {
  private collapsed = new BehaviorSubject<boolean>(
    Boolean(localStorage.getItem('collapsed') === 'true')
  );
  collapsed$ = this.collapsed.asObservable();
  toggle() {
    this.collapsed.next(!this.collapsed.value);
    localStorage.setItem('collapsed', this.collapsed.value.toString());
  }
}
