import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
    providedIn: 'root'
})
export class ProfileManagementService {
    constructor(private httpService: HttpService) {}

    changePassword(data): Observable<any> {
        return this.httpService.post('/users/changePassword', data).pipe(catchError((error) => throwError(error)));
    }

    closeAccount(): Observable<any> {
        return this.httpService.delete('/users/deleteAccount');
    }

    addConfigMail(data): Observable<any> {
        return this.httpService.post('/users/configEmail', data).pipe(catchError((error) => throwError(error)));
    }

    removeConfigMail(): Observable<any> {
        return this.httpService.post('/users/disConnectEmail', {});
    }

    updateAuthentication(authentication): Observable<any> {
        return this.httpService.put(`/users/updateUserAuthentication`, {authentication});
    }
}
