import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ExpertsListComponent } from './components/experts-list/experts-list.component';
import { ExpertDetailsComponent } from './components/expert-details/expert-details.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';

const routes: Routes = [
  {
    path: '',
    component: ExpertsListComponent,
  },
  {
    path: 'expert-details/:id',
    component: ExpertDetailsComponent,
  },
];

@NgModule({
  declarations: [ExpertsListComponent, ExpertDetailsComponent],
  imports: [
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    RouterModule.forChild(routes),
  ],
})
export class HireExpertModule {}
