import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/services/user.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { AuthService } from '../../auth.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-signup-for-invited',
  templateUrl: './signup-for-invited.component.html',
  styleUrls: ['./signup-for-invited.component.scss'],
})
export class SignupForInvitedComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private userService: UserService,
    private router: Router,
    private utility: UtilityService
  ) {}

  signupForm: FormGroup;
  error = '';
  showPassword = false;

  formErrors = {
    email: '',
    password: '',
    firstName: '',
    lastName: '',
  };

  formErrorMessages = {
    firstName: {
      required: 'Firstname is required',
    },
    lastName: {
      required: 'Lastname is required',
    },
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format',
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters',
    },
  };

  businessId = '';
  inviteId = '';

  ngOnInit(): void {
    this.route.params.subscribe(({ inviteId, businessId }) => {
      this.businessId = businessId;
      this.inviteId = inviteId;
      this.findInvite(inviteId);
    });
    this.loadSignupForm();
  }

  findInvite(inviteId): void {
    this.spinner.show();
    this.authService.findInvite(inviteId).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        this.signupForm.controls.email.patchValue(resp.data.email);
        this.signupForm.controls.firstName.patchValue(resp.data.firstName);
        this.signupForm.controls.lastName.patchValue(resp.data.lastName);
      },
      (error) => {
        this.error = error?.error?.message || 'Internal server error';
        this.spinner.hide();
      }
    );
  }

  loadSignupForm(): void {
    this.signupForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      password: [
        '',
        [
          Validators.required,
          Validators.minLength(8),
          Validators.maxLength(128),
        ],
      ],
      referralCode: [''],
    });

    this.signupForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.signupForm,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }

  signupAndJoin(): void {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    console.log(this.signupForm.value);
    const body = {
      ...this.signupForm.value,
      inviteId: this.inviteId,
      expertReferralCode: this.signupForm.value.referralCode,
    };
    this.spinner.show();
    this.authService.signupForInvitedUser(body).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        this.userService.setUserDetailsWithToken({
          user: {
            ...resp.data,
            token: resp.token,
            refreshtoken: resp.refreshtoken,
          },
        });
        this.router.navigate(['/dashboard']);
        this.utility.showHideSettingsModal.next({
          open: true,
          tab: 'userProfile',
        });
      },
      (error) => {
        this.spinner.hide();
        this.error = error?.error?.message || 'Internal server error';
      }
    );
  }
}
