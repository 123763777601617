import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ExpertsRoutingModule } from './experts-routing.module';
import { ExpertsComponent } from './experts.component';
import { EnquiryHistoryComponent } from './components/enquiry-history/enquiry-history.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { AccordionModule } from 'ngx-accordion';
import { NgxSpinnerModule } from 'ngx-spinner';
import { StoreModule } from '@ngrx/store';
import { expertDetailsReducerMap } from './state/reducers';
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [ExpertsComponent, EnquiryHistoryComponent],
  imports: [
    CommonModule,
    AccordionModule,
    ExpertsRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    NgxSpinnerModule,
    StoreModule.forFeature('expertDetails', expertDetailsReducerMap),
  ],
})
export class ExpertsModule {}
