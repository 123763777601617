import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { getAllAccountType } from 'src/app/shared/utils/accountType';
import { RootReducerState } from 'src/app/store/reducers';

@Component({
  selector: 'app-add-income-form',
  templateUrl: './add-income-form.component.html',
  styleUrls: ['./add-income-form.component.scss']
})
export class AddIncomeFormComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>) {
                this.accounts$ = this.store.pipe(
                  select(selectAccounts),
                  map((accounts) => accounts.filter((account) => !account.isArchive)))
              }
  transactiontype;
  accounts$: Observable<any>;
  unsubscribe = new Subject();
  @Input() form: FormGroup;
  @Input() formEditable = true;
  @Input() formErrors;
  @Input() invalidAmount = false;

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


}
