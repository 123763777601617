import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class InvoiceCustomizationService {

  constructor(private httpService: HttpService) { }

  private businessId = null;
  private invoiceCustomizationSettings: BehaviorSubject<any> = new BehaviorSubject(null);
  invoiceCustomizationSettings$ = this.invoiceCustomizationSettings.asObservable();

  getInvoiceCustomizationSettings(businessId): Observable<any> {
    return this.httpService.get(`/setting/getTemplateConfiguration/${businessId}`);
  }

  setInvoiceCustomizationSettings(settings, businessId?): void {
    if (businessId) {
      this.businessId = businessId;
    }
    this.invoiceCustomizationSettings.next(settings);
  }

  updateInvoiceCustomizationSettings(data): Observable<any> {
    return this.httpService.put('/setting/updateTemplate', { businessId: this.businessId, ...data});
  }

  saveInvoiceCustomizationSettings(data): Observable<any> {
    return this.httpService.post('/setting/saveTemplate', { businessId: this.businessId, ...data});
  }
}
