import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceCustomizationService } from '../../invoice-customization.service';

@Component({
  selector: 'app-general-settings',
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss']
})
export class GeneralSettingsComponent implements OnInit {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
              private spinner: NgxSpinnerService) { }

  openModal = false;
  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload'
    }
  };
  companyLogo = 'assets/images/business.jpg';
  settings = null;
  selectedTemplate = new FormControl('classic');
  openPreviewModal = false;
  selectedPreview = '';
  openDeleteModal = false;

  ngOnInit(): void {
    this.invoiceCustomizationService.invoiceCustomizationSettings$.subscribe((settings) => {
      this.settings = settings;
      if (settings?.companyLogo) {
        this.companyLogo = settings?.companyLogo;
      }
      if (settings?.selectedTemplate) {
        this.selectedTemplate.patchValue(settings?.selectedTemplate);
      }
    });
  }

  openPreview(url): void {
    this.selectedPreview = url;
    this.openPreviewModal  = true;
  }

  fileEvent(event): void {
    this.openModal = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 16 / 9,
        title: 'Upload'
      }
    };
  }

  saveTemplate(): void {
    this.spinner.show();
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings({ selectedTemplate: this.selectedTemplate.value })
    .subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        const newSettings = {...this.settings, selectedTemplate: this.selectedTemplate.value };
        this.invoiceCustomizationService.setInvoiceCustomizationSettings(newSettings);
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  saveImage(image): void {
    this.openModal = false;
    this.spinner.show();
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings({ companyLogo: image })
    .subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.companyLogo = image;
        this.invoiceCustomizationService.setInvoiceCustomizationSettings({...this.settings, companyLogo: this.companyLogo });
        const fileInput = document.getElementById('file') as HTMLInputElement;
        fileInput.value = null;
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  removeLogo(): void {
    this.spinner.show();
    this.openDeleteModal = true;
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings({ companyLogo: '' })
    .subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.companyLogo = 'assets/images/business.jpg';
        this.invoiceCustomizationService.setInvoiceCustomizationSettings({...this.settings, companyLogo: this.companyLogo });
        const fileInput = document.getElementById('file') as HTMLInputElement;
        fileInput.value = null;
      }
      this.openDeleteModal = false;
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

}
