<div appFeatureCheck feature="users">
  <div class="flex flex-col space-y-5">
    <div class="flex items-center">
      <button
        *ngIf="showInviteUser"
        (click)="back()"
        class="bg-input-light p-3 rounded-full me-2 border"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-4 w-4"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M15 19l-7-7 7-7"
          />
        </svg>
      </button>
      <app-submit
        className="bg-[#026AA2] text-white h-10"
        value="{{ 'Invite user' | translate }}"
        (clicked)="showInviteUser = true"
        appSubscriptionCheck
        featureToCheck="supportEmail"
        class="ms-auto"
        *ngIf="isThisMyBusiness && !showInviteUser"
      >
      </app-submit>
    </div>
    <app-users-list
      *ngIf="!showInviteUser"
      (editUser)="editInvite($event)"
    ></app-users-list>
    <app-invite-user
      [role]="selectedRole"
      [inviteId]="inviteId"
      (close)="showInviteUser = false"
      *ngIf="showInviteUser"
    ></app-invite-user>
  </div>
</div>

<!-- <div routeToSubscription fromComponent='users' class="absolute bottom-6 z-[29] w-[400px] left-0 right-0 m-auto">
    <app-send-to-sub   ></app-send-to-sub>
</div> -->
