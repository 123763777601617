import { NgModule } from '@angular/core';
import { EmptyPipe } from './empty.pipe';
import { CurrencywithspacePipe } from './currencywithspace.pipe';

@NgModule({
  imports: [],
  exports: [EmptyPipe],
  declarations: [EmptyPipe],
  providers: [],
})
export class PipesModule {}
