import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreateBillsComponent } from './Components/create-bills/create-bills.component';
import { BillsListComponent } from './Components/bills-list/bills-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ViewBillComponent } from './Components/view-bill/view-bill.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';
import { ReceiptComponent } from 'src/app/shared/documents/receipt/receipt.component';

const routes: Routes = [
    {
    path: '',
    component: BillsListComponent
   },
  {
    path: 'create-bill',
    component: CreateBillsComponent
  },
  {
    path: 'bills-list',
    component: BillsListComponent
  },
  {
    path: 'view-bill',
    component: ViewBillComponent
  },
  {
    path: 'view-receipt/:id',
    component: ReceiptComponent
  }
];

@NgModule({
  declarations: [
    BillsListComponent,
    CreateBillsComponent,
    ViewBillComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class BillsModule { }
