<ul
  class="font-medium h-12 hidden mb-4 rounded-lg shadow sm:flex text-center text-gray-500 text-sm"
>
  <li
    routerLink="/accounting/charts-of-account/assets"
    routerLinkActive="bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]"
    class="new-tab rounded-s-lg"
  >
    <div
      class="new-tab-link rounded-s-lg"
    >
      {{ "Assets" | translate }}
    </div>
  </li>
  <li
    routerLink="/accounting/charts-of-account/liabilities"
    routerLinkActive="bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]"
    class="new-tab"
  >
    <div
      class="new-tab-link"
    >
      {{ "Liabilities" | translate }}
    </div>
  </li>
  <li
    routerLink="/accounting/charts-of-account/income"
    routerLinkActive="bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]"
    class="new-tab"
  >
    <div
      class="new-tab-link"
    >
      {{ "Income" | translate }}
    </div>
  </li>
  <li
    routerLink="/accounting/charts-of-account/expenses"
    routerLinkActive="bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]"
    class="new-tab"
  >
    <div
      class="new-tab-link"
    >
      {{ "Expenses" | translate }}
    </div>
  </li>
  <li
    routerLink="/accounting/charts-of-account/equity"
    routerLinkActive="bg-[#F0F5FF] text-black hover:!bg-[#F0F5FF]"
    class="new-tab rounded-e-lg"
  >
    <div
      class="new-tab-link rounded-e-lg"
    >
      {{ "Equity" | translate }}
    </div>
  </li>
</ul>
