<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-5">
        <div class="flex items-center">
            <h1 class="text-2xl Poppins-Bold inline-flex items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                  </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                    <path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/>
                </svg>
                {{'Invoice Customization' | translate }}
            </h1>
        </div>
        <app-general-settings></app-general-settings>
        <app-invoice-settings></app-invoice-settings>
        <app-reminder-settings></app-reminder-settings>
        <app-cash-invoice-sms-settings></app-cash-invoice-sms-settings>
        <app-estimate-settings></app-estimate-settings>
        <app-invoice-column-settings></app-invoice-column-settings>
    </div>
</div>