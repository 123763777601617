import { Component, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { ReferrralService } from 'src/app/shared/services/referrral.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ResetErrors } from 'src/app/store/actions/reset.actions';
import { UserSignUp } from 'src/app/store/actions/user.actions';
import { IUserLogin } from 'src/app/store/models/user';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUserError, selectUserLoading } from 'src/app/store/selectors/user.selectors';
export {}; declare global { interface Window { Calendly: any; } }

@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  type:string ='professional'
  signupForm: FormGroup;
  signupRespMessage = '';
  signupError$: Observable<any>;
  signupLoading$: Observable<boolean>;
  showPassword = false;
  showModal: boolean = false;
  country:any = 'Global'
  redirectLink:string = ''
  countries = [
    {countryName:"Global", _id:"Global"},
    {countryName:"Jordan", _id:"Jordan"},
    {countryName:"Saudi Arabia", _id:"Saudi Arabia"}
  ]
  formErrors = {
    email: '',
    password: '',
    country:''
   };
  signupErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Email must be in correct format'
    },
    password: {
      required: 'Password is required',
      minlength: 'Password must be of least 8 characters',
      maxlength: 'Password cannot be more than of 128 characters'
    },
    country:{
      required:"Please select country",
    }
  };
  subscriptionPlans = [];
  transactionPlans = [];
  storagePlans = [];

  constructor(private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private referralService: ReferrralService,
    private subscriptionService: SubscriptionService,
    private router: Router,
    private toast: ToastrService) {
      this.signupError$ = this.store.pipe(select(selectUserError));
      this.signupLoading$ = this.store.pipe(select(selectUserLoading));
  }
  @ViewChild('Budget') Budget: TemplateRef<any>;
  @ViewChild('Payroll') Payroll: TemplateRef<any>;
  @ViewChild('Inventory') Inventory: TemplateRef<any>;
  @ViewChild('Recurring') Recurring: TemplateRef<any>;
  @ViewChild('Storage') Storage: TemplateRef<any>;

  ngOnInit(): void {
    this.loadForm();
    this.loadSubscriptionpalns();
    this.store.dispatch(ResetErrors());
    this.signupLoading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
  }

  loadSubscriptionpalns(): void {
    this.spinner.show();
    this.subscriptionService.getPlans()
    .subscribe(resp => {
      if(resp?.success){
        let plans = resp?.data.filter(plan => plan?.planName !== 'Starter');
        this.subscriptionPlans = plans?.filter(plan => !plan?.planName?.startsWith('Transaction') && !plan?.planName?.startsWith('Files')).sort((a, b) => a.planPrice - b.planPrice);
        this.transactionPlans = plans?.filter(plan => plan?.planName?.startsWith('Transaction'));
        this.storagePlans = plans?.filter(plan => plan?.planName?.startsWith('Files'));
      }
    })
  }

  loadForm(): void {
    this.signupForm = this.fb.group({
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)]],
      password: ['', [Validators.required, Validators.minLength(8), Validators.maxLength(128)]],
      expertReferralCode: [''],
      country:['', [Validators.required]],
    });
    this.signupForm.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(this.signupForm, {...this.formErrors}, this.signupErrorMessages);
    });
    this.formErrors = valueChanges(this.signupForm, {...this.formErrors}, this.signupErrorMessages);
  }

  signup(): void{
    this.signupRespMessage = '';
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      this.formErrors = valueChanges(this.signupForm, {...this.formErrors}, this.signupErrorMessages);
      return;
    }
    if(this.signupForm.value.expertReferralCode){
      this.spinner.show()
      this.referralService.getExpertReferralCode()
      .subscribe(resp => {
        if(resp.data.length > 0){
          console.log("check1");
          
          const codeExists = resp.data.filter(item=>item.refCode === this.signupForm.value.expertReferralCode);
          if(codeExists.length>0) {
            this.spinner.hide()
            console.log("code");
            
            const user: IUserLogin = {...this.signupForm.value};
            this.store.dispatch(UserSignUp(user));
          } else {
            this.spinner.hide()
            this.toast.error('Invalid Referral Code');
          }
        }
        else {
          this.spinner.hide()
          this.toast.error('Invalid Referral Code');
        }
      })
    }
    else {
      console.log("check2");
      
      const user: IUserLogin = {...this.signupForm.value};
      this.store.dispatch(UserSignUp(user));
    }
  }

  changeType(type:string){
    this.type = type
  }

  changeCountry(event): void {
    this.country = event._id
    this.signupForm.get('country').setValue(event._id)
    if(this.country == 'Jordan' || this.country =='Saudi Arabia'){
      this.showModal = true
      this.redirectLink = this.country == 'Jordan'? "jo.numetric.work" : "sa.numetric.work"
    }

  }

  toggleModal(){
    this.showModal = !this.showModal
  }

  openModal(){

  }

  sendToSignup(): void {
    this.router.navigate(['/auth/signup'])
  }

  // changeType(type:string){
  //   this.type = type
  // }

}
