import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as VendorActions from '../actions/vendors.action';

export interface VendorsState {
    vendors: Array<any>;
}

export const initialState: VendorsState = {
    vendors: []
};

export const VendorsReducer = createReducer(initialState,
    on(VendorActions.SetVendors, (state: VendorsState, {vendors}) => ({...state, vendors})),
    on(VendorActions.AddVendor, (state: VendorsState, {vendor}) => ({...state, vendors: [...state.vendors, {...vendor}]})),
    on(VendorActions.UpdateVendor, (state: VendorsState, {vendor}) => {
        const mappedVendors = state.vendors.map((ogCustomer) => {
            if (ogCustomer._id === vendor._id) { return {...vendor}; }
            return ogCustomer;
        });
        return { ...state, vendors: [...mappedVendors] };
    }),
    on(VendorActions.DeleteVendor, (state: VendorsState, {_id}) => {
        const filteredVendors = state.vendors.filter(vendor => vendor._id !== _id);
        return { ...state, vendors: [...filteredVendors] };
    }),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: VendorsState | undefined, action: Action): any {
    return VendorsReducer(state, action);
}

export const getVendors = (state: VendorsState) => state.vendors;
