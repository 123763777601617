<div class="p-7 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Poppins-Bold text-xl">{{'Invoice column settings' | translate }}</h3>
    <p class="my-4 Poppins-SemiBold"> {{'Edit the titles of the columns on your invoices:' | translate }}</p>
    <form [formGroup]='columnSettingsForm'>
        <div class="space-y-2 mb-2">
            <h3 class="mb-2 text-lg Poppins-Bold">{{'Items' | translate }}</h3>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='item' name='item' value="Items" type="radio" class="me-2">
                    <span>{{'Items' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='item' name='item' value="Products" type="radio" class="me-2">
                    <span>{{'Products' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='item' name='item' value="Services" type="radio" class="me-2">
                    <span>{{'Services' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center gap-x-2">
                    <input #otherItem name='item' [checked]='itemsOther'  type="radio">
                    <span>{{'Other' | translate}}</span>
                    <input [hidden]='!otherItem.checked' formControlName='item' type="text" class="rounded-full px-4 py-1 custom-input-box-shadow border-none w-52 focus:ring-0">
                </label>
            </div>
        </div>
        <div class="space-y-2 mb-2">
            <h3 class="mb-2 text-lg Poppins-Bold">{{'Units' | translate }}</h3>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='units' name='units' value="Quantity" type="radio" class="me-2">
                    <span>{{'Quantity' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='units' name='units' value="Hours" type="radio" class="me-2">
                    <span>{{'Hours' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center gap-x-2">
                    <input #otherUnits [checked]='unitsOther' name='units' type="radio">
                    <span>{{'Other' | translate }}</span>
                    <input [hidden]='!otherUnits.checked' formControlName='units' name='units' type="text" class="rounded-full px-4 py-1 custom-input-box-shadow border-none w-52 focus:ring-0">
                </label>
            </div>
        </div>
        <div class="space-y-2 mb-2">
            <h3 class="mb-2 text-lg Poppins-Bold">{{'Price' | translate }}</h3>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='price' name='price' value="Price" type="radio" class="me-2">
                    <span>{{'Price' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='price' name='price' value="Rate" type="radio" class="me-2">
                    <span>{{'Rate' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center gap-x-2">
                    <input #otherPrice [checked]='priceOther' name="price" type="radio">
                    <span>{{'Other' | translate }}</span>
                    <input [hidden]='!otherPrice.checked' formControlName='price' type="text" class="rounded-full px-4 py-1 custom-input-box-shadow border-none w-52 focus:ring-0">
                </label>
            </div>
        </div>
        <div class="space-y-2 mb-4">
            <h3 class="mb-2 text-lg Poppins-Bold">{{'Amount' | translate}}</h3>
            <div>
                <label class="inline-flex items-center">
                    <input formControlName='amount' name='amount' value='Amount' type="radio" class="me-2">
                    <span>{{'Amount' | translate }}</span>
                </label>
            </div>
            <div>
                <label class="inline-flex items-center gap-x-2">
                    <input #otherAmount [checked]='amountOther' name='amount' type="radio">
                    <span>{{'Other' | translate}}</span>
                    <input [hidden]='!otherAmount.checked' formControlName='amount' type="text" class="rounded-full px-4 py-1 custom-input-box-shadow border-none w-52 focus:ring-0">
                </label>
            </div>
        </div>
    </form>
    <div class="flex items-center gap-x-2">
        <app-button buttonText='{{"Save changes" | translate }}' rounded='true' type='primary' (handleClick)='saveChanges()'></app-button>
        <span *ngIf='response' class="text-green-500">{{response}}</span>
        <span *ngIf='error' class="text-red-500">{{error}}</span>
    </div>
</div>