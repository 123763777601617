import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ListInvoicesComponent } from './list-invoices/list-invoices.component';
import { ViewInvoiceTemplateComponent } from './view-invoice-template/view-invoice-template.component';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { RecurringInvoicesComponent } from './recurring-invoices.component';

const routes: Routes = [
  {
    path: '',
    component: RecurringInvoicesComponent,
    children: [
      {
        path: '',
        component: ListInvoicesComponent
      },
      {
        path: 'view-template',
        component: ViewInvoiceTemplateComponent
      },
      {
        path: 'create-invoice',
        component: CreateInvoiceComponent
      },
      {
        path: '*',
        component: ListInvoicesComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecurringInvoicesRoutingModule { }
