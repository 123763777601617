import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor(private httpService: HttpService) { }

  getStatisticsData(businessId: string): Observable<any> {
    return this.httpService.get(`/storage/storageStatistic/${businessId}`);
  }

  getAllSectionData(businessId: string): Observable<any> {
    return this.httpService.get(`/storage/getParentDocGroupData/${businessId}`);
  }

  getFilesBbySection(documentType, businessId): Observable<any> {
    return this.httpService.post(`/storage/getDocumentTypeGroupData/${businessId}`, {documentType});
  }

  getFile(fileId: string): Observable<any> {
    return this.httpService.get(`/storage/getFile/${fileId}`);
  }

  getReferenceZip(referanceId: string): Observable<any> {
    return this.httpService.get(`/storage/getReferenceZip/${referanceId}`);
  }
}
