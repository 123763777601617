<div class="p-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <span class="flex items-center">
    <h3 class="Poppins-Bold text-lg">{{'Mailing Config' | translate }}</h3>
    </span>
    <p class="mt-2 Gilory-Medium">{{'Mailing config message' | translate }}</p>
    <div *ngIf='configMail?.email' class="flex justify-between mt-6 min-w-full border-t pt-2">
        <div>
            <span class="Poppins-Bold me-2">{{'Email Address' | translate }}:</span>
            <span>{{configMail.email}}</span>
        </div>
        <app-button *ngIf='configMail?.isVerified' (handleClick)='disconnectEmailing()' type='danger' buttonText='Disconnect' [rounded]='true'>
        </app-button>
        <div class="flex space-x-2">
            <app-button (handleClick)='editMail()' *ngIf='!configMail?.isVerified' type='primary' buttonText='Edit' >
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                </svg>
            </app-button>
            <app-button *ngIf='!configMail?.isVerified' (handleClick)='verifyMailingId()' type='primary' buttonText='Check' [rounded]='true'>
            </app-button>
        </div>
    </div>
    <div class="mt-6">
        <button *ngIf='!addNewEmail && !configMail?.email' (click)='addNewEmail = true' id='add'
            class="flex items-center Poppins-Bold space-x-4 rtl:space-x-reverse">
            <span class="rounded-full button-new-class text-white p-1 me-2">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                        d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
                </svg>
            </span>
            {{'Add email for sending mail' | translate }}
        </button>
        <!-- <div *ngIf="response" class="text-green-500 py-1">{{response}}</div> -->
        <div [formGroup]='mailConfigForm' *ngIf='addNewEmail'
            class="flex flex-col border-t border-gray-300 space-y-4 pt-4 w-full lg:w-1/2">
            <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                <label for='email' class="block text-sm Poppins-Bold font-color-05">{{'Email Address' | translate
                    }}</label>
                <input formControlName='email' type="email"
                    class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200"
                    placeholder="{{'Enter email' | translate }}" />
            </div>
            <div class="flex gap-x-2">
                <app-button buttonText='{{"Add" | translate }}' type='primary' (handleClick)='addNewMail()'>
                </app-button>
                <app-button buttonText='{{"Cancel" | translate }}' type='third' (handleClick)="addNewEmail=false">
                </app-button>
            </div>
        </div>
    </div>
</div>

<!-- <app-modal-container [isOpen]='instructionModal'>
    <div header class="Poppins-Bold">{{"Instructions" | translate }}</div>
    <div content>
        <my-tabs>
            <my-tab tabTitle='Gmail'>
                <ol class="list-decimal ms-3">
                    <li>Enable 2-Step-Verification</li>
                    <li>Go to your Google Account.</li>
                    <li>Select Security.</li>
                    <li>Under "Signing in to Google," select App Passwords. You may need to sign in. If you don’t have
                        this</li>
                    <li>option, it might be because:</li>
                    <li>2-Step Verification is not set up for your account.</li>
                    <li>2-Step Verification is only set up for security keys.</li>
                    <li>Your account is through work, school, or other organization.</li>
                    <li>You turned on Advanced Protection.</li>
                    <li>At the bottom, choose Select app and choose the app you using  Select device and choose the
                        device</li>
                    <li>you’re using  Generate.</li>
                    <li>Follow the instructions to enter the App Password. The App Password is the 16-character code in
                        the</li>
                    <li>yellow bar on your device.</li>
                    <li>Tap Done.</li>
                </ol>
                You can learn about app password for Gmail <a target="_blank" class="text-blue-600"
                    href="https://support.google.com/accounts/answer/185833?hl=en">Here</a>.
            </my-tab>
            <my-tab tabTitle='OutLook'>
                <ol class="ms-3 list-decimal">
                    <li>Enable 2-Step-Verification Go to the Security basics page and sign in to your Microsoft account.
                    </li>
                    <li>Select More security options. </li>
                    <li>Under App passwords, select Create a new app password. A new app password is generated and
                        appears on your screen.</li>
                    <li>Enter this app password where you would enter your normal Microsoft account password in the
                        application.</li>
                </ol>
                You can learn about app password for Outlook <a target="_blank" class="text-blue-600"
                    href="https://support.microsoft.com/en-us/account-billing/using-app-passwords-with-apps-that-don-t-support-two-step-verification-5896ed9b-4263-e681-128a-a6f2979a7944">Here</a>.
            </my-tab>
        </my-tabs>
    </div>
    <div footer class="flex justify-center gap-x-2">
        <app-button (click)='instructionModal=false' type='primary' buttonText='{{"OK" | translate }}'></app-button>
    </div>
</app-modal-container> -->

<!-- <app-modal-container [isOpen]='deleteEmailModal'>
    <div header class="Poppins-Bold border-b pb-2">{{'Remove email' | translate }}</div>
    <div content>
        <p class="mb-2">
            {{'Delete email confirmation message' | translate }}
        </p>
        <p *ngIf='emailToDelete'>{{'Email -' | translate }} {{emailToDelete}}</p>
    </div>
    <div footer class="flex space-x-4">
        <app-button type='danger' buttonText='{{"Remove" | translate }}' rounded='true' (handleClick)='deleteEmail()'></app-button>
        <app-button type='third' buttonText='{{"Cancel" | translate }}' rounded='true' (handleClick)='deleteEmailModal = false'></app-button>
    </div>
</app-modal-container> -->