
<div (click)='downloadPdf()' class="w-2/3 mx-auto flex flex-row space-x-2 my-4">
    <app-button buttonText='Download PDF' type='primary'></app-button>
</div>
<table *ngIf='estimateData' class="w-2/3 m-auto d-block overflow-hidden box-shadow-sales rounded-xl mb-4">
    <div style="padding: 2rem;">
      <table class="w-full" style="border-collapse: collapse;">
        <tr>
          <td valign='top' class="ps-8" >
            <div>
              <h1 class="text-2xl font-semibold"> {{'ESTIMATE' | translate}} </h1>   
            </div>
            <div class="mb-4">{{estimateData.subheading}}</div>
          </td>
          <td align="right" class="pe-8" style='width: 50%;'>
            <div *ngIf='customizationSettings?.companyLogo' class="w-[200px] h-[112px] mb-2 ms-auto">
              <img [src]="customizationSettings?.companyLogo" alt="">
            </div>
            <div>{{estimateData.businessId.companyName}}</div>
            <div>{{estimateData?.businessId?.address?.addressLineFirst}}</div>
            <div>{{estimateData?.businessId?.address?.addressLine2}}</div>
            <div>{{estimateData?.businessId?.address?.city ? estimateData?.businessId?.address?.city + ',' : ''}} {{estimateData?.businessId?.address?.state}}</div>
            <div>{{estimateData.businessId.country}}</div>
          </td>
        </tr>
      </table>
    </div>
    <div class="px-16" >
      <table class="w-full" style="border-collapse: collapse;">
        <tr>
          <td>
            <table>
              <tr>
                <td>
                  <div class="Poppins-Bold">{{'SENT TO' | translate}}</div>
                  <div>{{estimateData?.customerDetails?.customerId?.customerName}}</div>
                  <div>{{estimateData?.customerDetails?.customerId?.email}}</div> 
                  <div>{{estimateData?.customerDetails?.customerId?.billingAddress?.addressLine1}}</div> 
                  <div>{{estimateData?.customerDetails?.customerId?.billingAddress?.addressLine2}}</div> 
                  <div>{{estimateData?.customerDetails?.customerId?.billingAddress?.city}}, {{estimateData?.customerDetails?.customerId?.billingAddress?.state}}</div> 
                  <div>{{estimateData?.customerDetails?.customerId?.billingAddress?.postalCode}}</div> 
                  <div>{{estimateData?.customerDetails?.customerId?.billingAddress?.country}}</div> 
                </td>
              </tr>
            </table>
          </td>
          <td align="right">
            <table style="border-collapse: collapse;">
              <tr>
                <td class="Poppins-Bold">{{'Estimate Number:' | translate}}</td>
                <td align="right" class="ps-4">{{estimateData.estimateNumber}}</td>
              </tr>
              <tr>
                <td class="Poppins-Bold">{{'Reference Number:' | translate }}</td>
                <td align="right" class="ps-4">{{estimateData.purchaseOrder}}</td>
              </tr>
              <tr>
                <td class="Poppins-Bold">{{'Estimate Date:' | translate }}</td>
                <td align="right" class="ps-4">{{estimateData.date | date}}</td>
              </tr>
              <tr>
                <td class="Poppins-Bold">{{'Expires On:' | translate }}</td>
                <td align="right" class="ps-4">{{estimateData.expiryDate | date}}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <table class="w-full" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid #0C4D71;">
        <thead class="bg-light-gray-1 h-12 ps-2 p-2">
          <tr>  
            <td class="Poppins-Bold ps-4 text-start">{{'Item Name' | translate }}</td>
            <td align="center" class="Poppins-Bold">{{'Quantity' | translate }}</td>
            <td align="center" class="Poppins-Bold">{{'Unit Price' | translate }}</td>
            <td class="Poppins-Bold pe-4 text-end">{{'Total Amount' | translate }}</td>
          </tr>
        </thead>
        <tbody>
          <tr class="row-1" *ngFor="let item of estimateData.items">
            <td class="text-start ps-4" style="padding: 0.5em;">
                <h2 class="Poppins-Bold">{{item.item}}</h2>
            </td>
            <td align="center" style="padding: 0.5em;">{{item.quantity}}</td>
            <td align="center" style="padding: 0.5em;">{{item.unitPrice | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
            <td class="text-end pe-4" style="padding: 0.5em;">{{item.totalCost | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
          </tr>
        </tbody>
      </table>
      <table class="w-full" style="margin-bottom: 2em;">
        <tr>
          <td style="width: 65%;"></td>
          <td align="right" style="width: 35%;">
            <table class="w-full">
              <tr>
                <td>{{'Subtotal' | translate }}</td>
                <td align="right">{{estimateData.subTotal | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              </tr>
              <tr *ngIf='estimateData.tax'>
                <td style="padding-bottom: 0.5em;">{{'Tax' | translate }}</td>
                <td align="right" style="padding-bottom: 0.5em;">{{estimateData.tax | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              </tr>
              <tr class="border-top" style="border-top: 1px solid #0C4D71;">
                <td class="bold" style="font-weight: bold; padding-top: 0.5em;">{{'Total' | translate }}</td>
                <td align="right" class="bold" style="font-weight: bold; padding-top: 0.5em;">{{estimateData.totalAmount | currency: currencyDetails?.currency : 'symbol-narrow' : number}}</td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
    </div>
    <div class="px-16" *ngIf='estimateData.memo'>
      <div class="Poppins-Bold">{{'Memo' | translate}}</div>
      <pre class="whitespace-pre-wrap Poppins">{{estimateData.memo}}</pre>
    </div>
    <div *ngIf='estimateData.footer' class="mx-16" style="padding-top: 1em; padding-bottom: 1em; text-align: center; border-top: 1px solid #000; margin-top: 0.5em;" >{{estimateData.footer}}</div>
    <div class="flex justify-end px-16 py-4 gap-x-2" >
      <h4 class="Poppins-Bold" >{{'Issued By'}}:</h4>
      <span>{{estimateData?.userId?.firstName+' '+estimateData?.userId?.lastName}}</span>
    </div>
</table>