import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InventoryComponent } from './components/inventory/inventory.component';
import { RouterModule, Routes } from '@angular/router';
import { AddItemComponent } from './components/add-item/add-item.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { LanguageService } from 'src/app/shared/services/language.service';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { BundledProductsComponent } from './components/bundled-products/bundled-products.component';
import { AddBundledItemComponent } from './components/add-bundled-item/add-bundled-item.component';
import { ProductionRunComponent } from './components/production-run/production-run.component';
import { WriteOffComponent } from './components/write-off/write-off.component';

const router: Routes = [
  {
    path:'single-product',
    component: InventoryComponent
  },
  {
    path: 'add-item',
    component: AddItemComponent
  },
  {
    path: 'bundled-products',
    component: BundledProductsComponent
  },
  {
    path: 'add-bundled-item',
    component: AddBundledItemComponent
  },
  {
    path: 'production-run',
    component: ProductionRunComponent
  },
  {
    path: 'write-off',
    component: WriteOffComponent
  }
]


@NgModule({
  declarations: [
    InventoryComponent,
    AddItemComponent,
    BundledProductsComponent,
    AddBundledItemComponent,
    ProductionRunComponent,
    WriteOffComponent
  ],
  imports: [
    RouterModule.forChild(router),
    CommonModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    NgSelectModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    DirectivesModule
  ],
  providers: [LanguageService]
})
export class InventoryModule { }
