import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {

  constructor(private httpService: HttpService) { }

  loadInflowDataByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getInflowByYear/${data.businessId}`, data);
  }

  loadOutFlowDataByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getOutflowByYear/${data.businessId}`, data);
  }

  loadInflowDataByWeek(data): Observable<any> {
    return this.httpService.post(`/dashboard/getInflowByWeek/${data.businessId}`, data);
  }
  
  loadOutFlowDataByWeek(data): Observable<any> {
    return this.httpService.post(`/dashboard/getOutflowByWeek/${data.businessId}`, data);
  }

  loadInflowDataByMonth(data): Observable<any> {
    return this.httpService.post(`/dashboard/getInflowByMonth/${data.businessId}`, data);
  }

  loadOutFlowDataByMonth(data): Observable<any> {
    return this.httpService.post(`/dashboard/getOutflowByMonth/${data.businessId}`, data);
  }

  loadProfitByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getProfitByYear/${data.businessId}`, data);
  }
  loadLossByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getLossByYear/${data.businessId}`, data);
  }

  loadProfitByWeek(data): Observable<any> {
    return this.httpService.post(`/dashboard/getProfitByWeek/${data.businessId}`, data);
  }

  loadLossByWeek(data): Observable<any> {
    return this.httpService.post(`/dashboard/getLossByWeek/${data.businessId}`, data);
  }

  loadProfitByMonth(data): Observable<any> {
    return this.httpService.post(`/dashboard/getProfitByMonth/${data.businessId}`, data);
  }

  loadLossByMonth(data): Observable<any> {
    return this.httpService.post(`/dashboard/getLossByMonth/${data.businessId}`, data);
  }
  loadOverdueInvoiceData(data): Observable<any> {
    return this.httpService.get(`/dashboard/getOverDueInvoices/${data.businessId}`)
  }

  loadOverdueBillData(data): Observable<any> {
    return this.httpService.get(`/dashboard/getOverDueBills/${data.businessId}`)
  }

  loadPayableData(data): Observable<any> {
    return this.httpService.get(`/dashboard/getPaybleStatics/${data.businessId}`);
  }

  loadOwingData(data): Observable<any> {
    return this.httpService.get(`/dashboard/getOweStatics/${data.businessId}`);
  }

  getCashAndBankAmount(data): Observable<any> {
    return this.httpService.get(`/dashboard/getCashAndBankAmount/${data.businessId}`);
  }

  loadIncomeByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getIncomeByYear/${data.businessId}`, data);
  }

  loadOutcomeByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getOutcomeByYear/${data.businessId}`, data);
  }

  loadIncomeExpenseByYear(data): Observable<any> {
    return this.httpService.post(`/dashboard/getNetProfit/${data?.businessId}`, data);
  }
}
