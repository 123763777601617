import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { SalesService } from '../sales/sales.service';
import { AccountingService } from '../settings/settings-modules/accounting/accounting.service';
import { PurchaseProductsService } from './components/products-and-services/products.service';
import { VendorsService } from './components/vendors/vendors.services';
import { PurchasesService } from './purchases.service';

@Component({
  selector: 'app-purchase-container',
  templateUrl: './purchase-container.component.html',
  styleUrls: ['./purchase-container.component.scss']
})
export class PurchaseContainerComponent implements OnInit {

    constructor(private store: Store<RootReducerState>,
                private salesServices: SalesService,
                private productService: PurchaseProductsService,
                private accoutingService: AccountingService,
                private purchaseService: PurchasesService){
                this.business$ = this.store.pipe(select(selectBusiness));
              }

  business$: Observable<any>;
  unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        const id = business?.businessId._id;
        this.purchaseService.setBusinessId(id);
        const currencyDetails = (({currency,currencySymbol})=>({currency,currencySymbol}))(business?.businessId)
        this.purchaseService.setCurrencyDetails(currencyDetails);
        this.loadVendors(id);
        this.loadSalesTaxes(id);
        this.loadProducts(id);
      }
    });
  }
  loadVendors(id): void {
    this.purchaseService.getAllVendors(id).subscribe(resp => {
      this.purchaseService.setVendors(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  loadProducts(id): void {
    this.productService.listProduct(id).subscribe(resp => {
      this.purchaseService.setProducts(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  loadSalesTaxes(id): void {
    this.accoutingService.listSalesTaxes(id).subscribe(resp => {
      this.purchaseService.setSalesTaxes(resp.data);
    }, (error) => {
      console.log(error);
    });
  }

}
