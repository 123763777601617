import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { SalesService } from '../../sales.service';

@Injectable({
  providedIn: 'root'
})
export class RecurringInvoicesService {

  constructor(private http: HttpService,private salesService: SalesService) { }

  getRecurringInvoicesList(): Observable<any> {
    return this.salesService.getReccurringInoviceList();
  }

  getInvoiceById(invoiceId: string): Observable<any> {
    return this.salesService.getRecurringInvoice(invoiceId);
  }

  getPaymentTerms(businessId): Observable<any> {
    return this.http.get(`/setting/getPaymentTerms/${businessId}`);
  }

  createInvoice(payload): Observable<any> {
    return this.salesService.createRecurringInvoice(payload);
  }

  createPaymentTerm(payload, businessId): Observable<any> {
    return this.http.post(`/setting/savePaymentTerm/${businessId}`, payload);
  }

  stopRecurringInvoice(invoiceId): Observable<any> {
    return this.salesService.cancelRecurringInvoice(invoiceId);
  }

  updateInvoice(endDate, invoiceId): Observable<any> {
    return this.salesService.updateRecurringInvoice(invoiceId, {endDate});
  }


}
