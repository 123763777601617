<div
  class="w-full flex flex-col relative h-full new-design-round-border p-4 gap-3"
>
  <!-- max-h-[14.1rem] -->
  <div class="flex items-center justify-start ml-4">
    <p class="text-sm">
      {{ "Stores belonging to selected business" | translate }}
    </p>
  </div>
  <table class="w-full text-xs text-left rtl:text-right">
    <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
      <tr>
        <td *ngFor="let column of tableHeadings" scope="col" class="px-4 py-2">
          {{ column.name | translate }}
        </td>
        <td scope="col" class="px-4 py-2">
          {{ "Actions" | translate }}
        </td>
      </tr>
    </thead>
    <ng-container *ngIf="stores$ | async as tableData">
      <ng-container *ngIf="tableData.length; else noData">
        <tbody>
          <tr
            *ngFor="let store of tableData; trackBy: row?._id"
            class="bg-white"
          >
            <td class="px-6 py-4">
              {{ store?.storeName | empty : "-" }}
            </td>
            <td class="px-6 py-4">
              {{ store?.address?.addressLineFirst | empty : "-"
              }}{{
                (store?.address?.addressLineSecond
                  ? ", " + store?.address?.addressLineSecond
                  : ""
                ) | empty : "-"
              }}
            </td>
            <td class="px-6 py-4">
              {{ store?.address?.country | empty : "-" }}
            </td>
            <td class="pl-6 py-4">
              <app-dropdown #dropdown [isFromSettings]="true">
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1">
                  <div
                    (click)="editStore.emit(store?._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Edit" | translate }}
                  </div>
                  <div
                    (click)="showDeleteModal(store?._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Delete" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </ng-container>
  </table>
</div>
<ng-template #noData class="flex items-center justify-center">
  <tbody>
    <tr>
      <td class="pt-8 text-center" colspan="4">
        {{ "No Data to Show" | translate }}
      </td>
    </tr>
  </tbody>
</ng-template>

<app-modal-container [isOpen]="openDeleteModal">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Delete Store" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-3">
    <app-submit
      className="bg-[#026AA2] text-white h-10"
      value="Delete"
      (clicked)="deleteStore()"
    >
    </app-submit>
    <app-submit
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10"
      value="Cancel"
      (clicked)="openDeleteModal = false"
    >
    </app-submit>
  </div>
</app-modal-container>
