import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { InvoiceCustomizationService } from '../../services/invoice-customization.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-template-list',
  templateUrl: './template-list.component.html',
})
export class TemplateListComponent implements OnInit, OnDestroy {
  constructor(
    private invoiceCustomizationService: InvoiceCustomizationService
  ) {}
  unsubscribe$ = new Subject();

  @Output() selectedTemplate = new EventEmitter();
  ngOnInit() {
    this.invoiceCustomizationService.invoiceCustomizationSettings$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((settings) => {
        let selectedTemplate = null;
        this.templateList.forEach((template) => {
          if (settings?.selectedTemplate === template.value)
            selectedTemplate = template;
        });
        if (selectedTemplate) {
          selectedTemplate.isSelected = true;
        }
      });
  }

  openPreviewModal = false;

  templateList = [
    {
      name: 'Contemporary',
      isSelected: false,
      url: 'assets/images/ScreenShot_Invoice_3.PNG',
      value: 'contemporary',
    },
    {
      name: 'Classic',
      isSelected: false,
      url: 'assets/images/ScreenShot_Invoice_2.PNG',
      value: 'classic',
    },
    {
      name: 'Modern',
      isSelected: false,
      url: 'assets/images/ScreenShot_Invoice_1.PNG',
      value: 'modern',
    },
    {
      name: 'Minimal',
      isSelected: false,
      url: 'assets/images/TemplateOption1.png',
      value: 'templateOption1',
    },
    {
      name: 'Elegance',
      isSelected: false,
      url: 'assets/images/TemplateOption2.png',
      value: 'templateOption2',
    },
    {
      name: 'Jordan',
      isSelected: false,
      url: 'assets/images/Jordan.png',
      value: 'jordan',
    },
    {
      name: 'Saudi',
      isSelected: false,
      url: 'assets/images/Saudi.png',
      value: 'saudi',
    },
  ];

  forPreview = this.templateList[0];

  templateClick(event) {
    this.selectedTemplate.emit(event.value);
    this.templateList.forEach((template) => {
      template.isSelected = false;
    });
    event.isSelected = true;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
