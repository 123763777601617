<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-xl">
    <div class="flex items-center justify-between">
      <app-accounts-header></app-accounts-header>
      <!-- <h1 class="text-2xl Poppins-Medium inline-flex items-center"> -->
      <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" class="h-6 w-6 me-2">
          <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
            d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
          </path>
        </svg> -->
      <!-- <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
          <path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
        </svg>
        {{'Charts of Accounts' | translate}}
      </h1> -->
      <app-button
        appAccessControl
        moduleType="accounting"
        accessType="edit"
        type="primary"
        buttonText="{{ 'Add new account' | translate }}"
        size="large"
        (handleClick)="addNewAcount()"
      ></app-button>
    </div>

    <div class="w-full">
      <div *ngFor="let accountType of accountTypesBySection">
        <div class="p-2 mx-5 border-b border-[#1C1C1C33]">
          <p class="Poppins text-[#1C1C1C66] text-lg ps-3">
            {{ accountType.accountType | translate }}
          </p>
        </div>
        <div class="ps-5 pe-5 pb-5 mx-5 pt-0">
          <div class="pb-5">
            <div
              *ngFor="let account of accountType.accounts"
              class="flex items-center py-2"
            >
              <div class="w-1/6 break-words">
                <p>{{ account.accountId | translate }}</p>
              </div>
              <div class="w-2/6 break-words">
                <h3 class="Poppins text-lg">
                  {{ account.accountName | translate }}
                </h3>
              </div>
              <div class="w-2/6 break-words">
                <div class="flex">
                  <p class="text-black Poppins">
                    {{ account.description | translate }}
                  </p>
                </div>
              </div>
              <div class="w-1/6 inline-flex items-center justify-end">
                <span
                  class="bg-gray-500 text-sm text-white rounded p-1 me-2"
                  *ngIf="account.isArchive"
                  >{{ "Archived" | translate }}</span
                >
                <app-tooltip
                  appAccessControl
                  moduleType="accounting"
                  accessType="edit"
                  customClass="mb-8"
                  class=""
                  tooltipName="{{ 'Edit' | translate }}"
                >
                  <div
                    (click)="editAccount(account)"
                    class="h-8 w-8 rounded-lg flex justify-center items-center bg-gray-50 border border-gray-200 cursor-pointer"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                      />
                    </svg>
                  </div>
                </app-tooltip>
              </div>
            </div>
          </div>
          <div class="">
            <app-button
              appAccessControl
              moduleType="accounting"
              accessType="edit"
              (handleClick)="addNewAcount(accountType)"
              type="add-line"
            >
              <img
                src="assets/images/delete-icon.svg"
                style="transform: rotate(45deg)"
                alt=""
              />
              {{ "Add account" | translate }}
            </app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-account-form
  [data]="data"
  [update]="update"
  [accountData]="accountEditData"
  [isOpen]="openAccountForm"
  (accountFormValue)="handleAccountFormValue($event)"
  (closeAccountForm)="openAccountForm = false"
></app-account-form>
