<div
  class="flex p-2 w-[97%] items-center justify-center gap-2 rounded-lg bg-[#1C1C1C0D] text-[#1C1C1C0D]"
>
  <div>
    <span
      class="text-sm text-[#FF4747] cursor-pointer"
      (click)="openDeleteAccountModal = true"
    >
      {{ "Delete my account" | translate }}
    </span>
    <div class="text-[#1C1C1CCC] text-xxs">
      {{ "Delete Account message" | translate }}
    </div>
  </div>
  <img class="h-10 w-10 mr-3" src="/assets/images/warning.svg" alt="" />
</div>

<app-modal-container [isOpen]="openDeleteAccountModal">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Close my Account" | translate }}
  </div>
  <div content>
    <p>
      {{
        "Are you sure? You want to delete your account permanently, this action is irreversible."
          | translate
      }}
    </p>
    <p class="text-gray-600">
      {{
        "Note - Your subscription will be cancelled immediately." | translate
      }}
    </p>
  </div>
  <div footer class="flex items-center gap-3">
    <app-submit
      className="bg-[#026AA2] text-white h-10"
      value="Delete Account"
      (clicked)="deleteAccount()"
    >
    </app-submit>
    <app-submit
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10"
      value="Cancel"
      (clicked)="openDeleteAccountModal = false"
    >
    </app-submit>
  </div>
</app-modal-container>
