import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, Subject } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import {
  DATE_FORMAT,
  DATE_FORMAT_LOCAL,
  DATE_TIME_FORMAT,
  DATE_TIME_FORMAT_LOCAL,
  TIME_FORMAT,
} from '../../constant';
import {
  CreateTimeSheetDto,
  ResumeTimeSheetDto,
  StopTimeSheetDto,
  TimeSheetDto,
  WeekDto,
} from '../../time-sheet.dto';
import { TimeSheetService } from '../../time-sheet.service';
import moment = require('moment');
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { TaskServiceService } from 'src/app/modules/task-manager/task-service.service';
import { GlobalTimerService } from 'src/app/shared/components/global-timer/global-timer.service';

@Component({
  selector: 'app-register-hours',
  templateUrl: './register-hours.component.html',
  styleUrls: ['./register-hours.component.scss'],
})
export class RegisterHoursComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private rootStore: Store<RootReducerState>,
    private salesServices: SalesService,
    private timeSheetService: TimeSheetService,
    private taskService: TaskServiceService,
    private translateService: TranslateService,
    private toaster: ToastrService,
    private globalTimerService: GlobalTimerService,
    private spinner: NgxSpinnerService
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.projects$ = this.rootStore.pipe(select(selectProjects));
    this.customer$ = this.rootStore.pipe(select(selectCustomers));
  }
  projects$: Observable<any>;
  business$: Observable<any>;
  customer$: Observable<any>;
  businessId: string;
  unsubscribe$ = new Subject();
  activeDate: string;
  next: string;
  minDateTime: string;
  maxDateTime: string;
  registerHourForm: FormGroup;
  updateRegisterHourForm: FormGroup;
  week: WeekDto;
  itemsPerPage: number = 10;
  currentPage: number = 1;
  totalCount: number = 0;
  weeklyTotal: number;
  pageTotal: number = 0;
  previousId: string | null = null;
  dataItem: TimeSheetDto;
  isEditable: boolean = false;
  showUpdateModal: boolean = false;
  notClickable: boolean = false;
  isManual: boolean = false;
  formDisabled: boolean = false;
  isTimer: boolean = true;
  confirmDeleteTimeSheetModal: boolean = false;
  updateId: string | null = null;
  timer: NodeJS.Timeout;
  intervalId: NodeJS.Timeout;
  projects = [];
  customers = [];
  tasks = [];
  tableData: TimeSheetDto[] = [];
  tableKeys = ['by', 'until', 'description', 'customerName', 'projectName'];
  tableHeadings = [
    'From',
    'Until',
    'Description',
    'Customer',
    'Project',
    'Worked',
    'Paused',
    'Action',
  ];

  formErrors = {
    customerId: '',
    until: '',
    description: '',
    paused: '',
    by: '',
  };

  updateFormErrors = {
    customerId: '',
    until: '',
    description: '',
    paused: '',
    by: '',
  };

  formErrorMessages = {
    customerId: {
      required:
        'Please select at least one of the following options: Customer, Project or Task.',
    },
    until: {
      required: 'Until cannot be empty',
      past: 'The deadline must not be before or same to the start time.',
      // same: 'From and until can\'t be same'
    },
    description: {
      required: 'Description cannot be empty',
    },
    paused: {
      greater: 'Break duration may not be longer than total duration.',
    },
    by: {
      required: 'From cannot be empty',
      future: 'From may not be in future.',
    },
  };

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId._id;
        this.loadCustomers(business?.businessId._id);
      }
    });
    this.activeDate = moment().format(DATE_FORMAT);
    this.handlePagination(this.activeDate);
    this.loadProjects();
    this.loadAllTasks();
    this.loadForm();
  }

  today(): void {
    this.handlePagination(moment().format(DATE_FORMAT));
  }

  loadForm(): void {
    this.registerHourForm = this.fb.group({
      description: ['', [Validators.required]],
      paused: [0],
      by: [null],
      customerId: [null],
      until: [null],
      projectId: [null],
      taskId: [null],
    });

    this.updateRegisterHourForm = this.fb.group({
      description: ['', [Validators.required]],
      paused: [0],
      by: [null, [Validators.required]],
      customerId: [null],
      until: [null, [Validators.required]],
      projectId: [null],
      taskId: [null],
    });

    this.registerHourForm.statusChanges.subscribe(() => {
      this.resetFormErrors();
      console.log('formDataError', this.formErrors);
      if (this.isAnyFieldTouched(this.registerHourForm)) {
        if (this.intervalId) {
          clearInterval(this.intervalId);
          this.intervalId = null;
        }
        if (this.registerHourForm.controls['until'].dirty) {
          this.isTimer = false;
        }
        // console.log('this.registerHourForm.controls[until].touched || this.registerHourForm.controls[until].dirty', this.registerHourForm.controls['until'].touched, this.registerHourForm.controls['until'].dirty)
      }
    });

    this.updateRegisterHourForm.statusChanges.subscribe(() => {
      if (this.isAnyFieldTouched(this.updateRegisterHourForm)) {
        this.resetFormErrors();
      }
    });

    this.registerHourForm.get('by').valueChanges.subscribe((value) => {
      if (value) {
        this.isManual = true;
      }
    });

    this.registerHourForm.get('until').valueChanges.subscribe((value) => {
      if (value) {
        this.isManual = true;
      }
    });

    this.registerHourForm.get('paused').valueChanges.subscribe((value) => {
      if (value) {
        this.isManual = true;
      }
    });

    this.updateRegisterHourForm.get('by').valueChanges.subscribe(() => {
      this.isManual = true;
    });

    this.updateRegisterHourForm.get('until').valueChanges.subscribe(() => {
      this.isManual = true;
    });

    this.updateRegisterHourForm.get('paused').valueChanges.subscribe(() => {
      this.isManual = true;
    });
  }

  private resetFormErrors() {
    this.formErrors = {
      customerId: '',
      until: '',
      description: '',
      paused: '',
      by: '',
    };

    this.updateFormErrors = {
      customerId: '',
      until: '',
      description: '',
      paused: '',
      by: '',
    };
  }

  private isAnyFieldTouched(form: FormGroup): boolean {
    const controls = form.controls;
    for (const name in controls) {
      if (controls[name].touched || controls[name].dirty) {
        return true;
      }
    }
    return false;
  }

  loadProjects(): void {
    this.projects$.subscribe((projects) => {
      this.projects = projects;
    });
  }

  loadCustomers(id: string): void {
    this.spinner.show();
    this.salesServices
      .customerList(id)
      .pipe(
        tap((resp) => {
          this.customers = resp.data;
          this.spinner.hide();
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  loadAllTasks() {
    this.spinner.show();
    this.timeSheetService
      .getAssignedTask(this.businessId)
      .pipe(
        tap((resp) => {
          this.tasks = resp.data;
          this.spinner.hide();
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  handlePagination(date: string) {
    this.activeDate = date;
    this.spinner.show();
    this.timeSheetService
      .getTimeSheetWeeklyReport(this.businessId, date)
      .pipe(
        tap((response) => {
          this.spinner.hide();
          this.week = response.data || ({} as WeekDto);
          this.weeklyTotal = response.data.weeklyTotalWorked;
          this.handleActiveDate(this.activeDate);
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
    this.spinner.show();
  }

  handleActiveDate(
    date: string,
    page: number = 1,
    limit: number = this.itemsPerPage
  ): void {
    this.registerHourForm.reset({ paused: 0 });
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.activeDate = date;
    this.notClickable = false;
    this.isManual = false;
    this.spinner.show();
    this.formErrors = {
      customerId: '',
      until: '',
      description: '',
      paused: '',
      by: '',
    };
    this.timeSheetService
      .getTimeSheet(this.businessId, { date, page, limit })
      .pipe(
        tap((response) => {
          this.spinner.hide();
          this.minDateTime = moment(date, DATE_FORMAT).format(
            DATE_FORMAT_LOCAL + 'T00:00'
          );
          this.maxDateTime = moment(date, DATE_FORMAT).format(
            DATE_FORMAT_LOCAL + 'T23:59'
          );
          this.registerHourForm.disable();
          this.formDisabled = true;
          this.isTimer = false;
          if (moment(date, DATE_FORMAT).isSame(moment(), 'day')) {
            this.isTimer = true;
          }
          if (
            moment(date, DATE_FORMAT).isSame(moment(), 'day') ||
            moment(date, DATE_FORMAT).isSame(
              moment().subtract(1, 'days'),
              'day'
            )
          ) {
            this.registerHourForm.enable();
            this.formDisabled = false;
          }
          this.intervalId = setInterval(() => {
            const currentTime = moment().format(TIME_FORMAT);
            this.registerHourForm
              .get('by')
              ?.setValue(currentTime, { emitEvent: false });
          }, 1000);
          this.tableData = response.data.timesheets;
          this.pageTotal = response.data.totalWorked;
          this.currentPage = response.data.currentPage;
          this.totalCount = response.data.totalTimesheetCount;
          this.previousId = null;
          this.tableData.forEach((item) => {
            item.active = false;
            if (item.state === 'STOP' || item.state === null) {
              clearInterval(this.timer);
            }
          });
          const activeItem = this.tableData.find(
            (item) => item.state === 'START' || item.state === 'RESUME'
          );
          if (activeItem) {
            // activeItem.tempTotalWorked = 0;
            this.tableData = this.tableData.filter(
              (item) => item._id !== activeItem._id
            );
            this.tableData.unshift(activeItem);

            setTimeout(() => {
              this.tableData[0].active = true;
              setTimeout(() => {
                this.tableData[0].active = false;
                setTimeout(() => {
                  this.tableData[0].active = true;
                  setTimeout(() => {
                    this.tableData[0].active = false;
                  }, 200);
                }, 200);
              }, 200);
            }, 200);

            if (activeItem.state === 'START') {
              this.previousId = activeItem._id;
              this.globalTimerService.show({
                customer: activeItem.customerName,
                id: activeItem._id,
                project: activeItem.projectName,
                until: activeItem.until,
                totalWorked: activeItem.totalWorked,
              });
              // activeItem.totalWorked += moment(activeItem.until).diff(
              //   moment(activeItem.by),
              //   'seconds'
              // );
              this.timer = setInterval(() => {
                const localTime = moment.utc(activeItem.until).local();
                activeItem.tempTotalWorked = Math.abs(
                  moment().diff(localTime, 'seconds')
                );
              }, 1000);
            } else if (activeItem.state === 'RESUME') {
              this.previousId = activeItem._id;
              this.globalTimerService.show({
                customer: activeItem.customerName,
                id: activeItem._id,
                project: activeItem.projectName,
                until: activeItem.until,
                totalWorked: activeItem.totalWorked,
              });
              activeItem.tempTotalWorked = 0;
              this.timer = setInterval(() => {
                const localTime = moment.utc(activeItem.until).local();
                activeItem.tempTotalWorked = Math.abs(
                  moment().diff(localTime, 'seconds')
                );
              }, 1000);
            }
          }
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  openConfirmDeleteTimeSheetModal(dataItem: TimeSheetDto) {
    this.dataItem = dataItem;
    this.confirmDeleteTimeSheetModal = true;
  }

  handleDelete() {
    this.timeSheetService
      .deleteTimeSheet(this.dataItem._id)
      .pipe(
        tap((response) => {
          this.handlePagination(this.activeDate);
          this.toaster.success(response?.message);
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  openUpdateModal(dataItem: TimeSheetDto, isEditable: boolean = false) {
    this.dataItem = dataItem;
    this.isEditable = isEditable;
    this.updateRegisterHourForm.setValue({
      description: dataItem.description,
      paused: Math.floor(dataItem?.paused / 60) || 0,
      by: moment(dataItem.by).format(DATE_TIME_FORMAT_LOCAL),
      customerId: dataItem.customerId || null,
      until: moment(dataItem.until).format(DATE_TIME_FORMAT_LOCAL),
      projectId: dataItem.projectId || null,
      taskId: dataItem.taskId || null,
    });
    this.updateId = dataItem._id;
    this.isManual = dataItem.isManual;
    this.showUpdateModal = true;
  }

  handleUpdate(): void {
    if (this.notClickable) return;
    this.notClickable = true;

    const formData = this.updateRegisterHourForm.value;

    const tempBy = formData.by ? moment(formData.by) : null;
    const tempUntil = formData.until ? moment(formData.until) : null;
    // const today = moment();

    if (!!tempBy && !!tempUntil) {
      if (!tempUntil.isAfter(tempBy)) {
        this.updateRegisterHourForm.get('until')?.setErrors({ past: true });
      }

      if (
        Math.abs(tempBy.diff(tempUntil, 'minutes')) < formData.paused ||
        (formData.until === null &&
          Math.abs(tempBy.diff(moment(), 'minutes')) < formData.paused)
      ) {
        this.updateRegisterHourForm.get('paused')?.setErrors({ greater: true });
      }
    }

    if (!formData.customerId && !formData.projectId && !formData.taskId) {
      this.updateRegisterHourForm
        .get('customerId')
        ?.setErrors({ required: true });
    } else {
      this.updateRegisterHourForm.get('customerId')?.setErrors(null);
    }

    if (this.updateRegisterHourForm.invalid) {
      this.updateRegisterHourForm.markAllAsTouched();
      this.updateFormErrors = valueChanges(
        this.updateRegisterHourForm,
        { ...this.updateFormErrors },
        this.formErrorMessages
      );
      this.notClickable = false;
      return;
    }

    const by = tempBy.utc();
    let until = tempUntil.utc();

    if (by.isAfter(until)) {
      until = until.add(1, 'days');
    }

    const body = {
      ...this.updateRegisterHourForm.value,
      by,
      until,
      timeSheetDate: by.format(DATE_FORMAT),
      businessId: this.businessId,
      paused: this.updateRegisterHourForm.value.paused,
      isManual: this.isManual,
    };

    this.spinner.show();

    this.timeSheetService
      .updateTimeSheet(this.updateId, body)
      .pipe(
        tap((response) => {
          this.handlePagination(this.activeDate);
          this.showUpdateModal = false;
          this.updateId = null;
          this.toaster.success(response?.message);
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          this.notClickable = false;
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  createTimeSheet(_: any): void {
    if (this.notClickable) return;

    this.notClickable = true;

    const formData = this.registerHourForm.value;

    console.log('formData', formData);

    const [date, month, year] = this.activeDate.split('-');

    const checkBy = `${year}-${month}-${date}T${formData.by}:00.000`;
    const checkUntil = `${year}-${month}-${date}T${formData.until}:00.000`;

    const tempBy = moment(checkBy);
    const tempUntil = moment(checkUntil);
    const today = moment();

    if (!formData.until && !today.isSame(tempBy, 'date')) {
      this.registerHourForm.get('until')?.setErrors({ required: true });
    }

    if (!formData.until && moment(formData.by, 'HH:mm').isAfter(moment())) {
      this.registerHourForm.get('by')?.setErrors({ future: true });
    }

    if (
      formData.until &&
      moment(formData.by, 'HH:mm').isSame(moment(formData.until, 'HH:mm'))
    ) {
      this.registerHourForm.get('until')?.setErrors({ past: true });
    }

    if (
      Math.abs(tempBy.diff(tempUntil, 'minutes')) < formData.paused ||
      (formData.until === null &&
        Math.abs(tempBy.diff(moment(), 'minutes')) < formData.paused)
    ) {
      this.registerHourForm.get('paused')?.setErrors({ greater: true });
    }

    if (!formData.customerId && !formData.projectId && !formData.taskId) {
      this.registerHourForm.get('customerId')?.setErrors({ required: true });
    } else {
      this.registerHourForm.get('customerId')?.setErrors(null);
    }

    if (this.registerHourForm.invalid) {
      this.registerHourForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.registerHourForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      this.notClickable = false;
      return;
    }
    this.spinner.show();

    const by = tempBy.utc();
    let body = {} as CreateTimeSheetDto;

    if (!this.registerHourForm.value.until) {
      let prevTimeSheet = null;
      if (this.previousId) {
        prevTimeSheet = this.tableData.find(
          (ele) => ele._id === this.previousId
        );
      }
      body = {
        ...this.registerHourForm.value,
        by,
        timeSheetDate: this.activeDate,
        businessId: this.businessId,
        paused: this.registerHourForm.value.paused || 0,
        until: moment().utc(),
        isManual: this.isManual,
        state: 'START',
        previous: prevTimeSheet
          ? {
              _id: prevTimeSheet?._id,
              totalWorked:
                prevTimeSheet?.totalWorked + prevTimeSheet?.tempTotalWorked,
              until: moment().utc(),
            }
          : null,
      };
    } else {
      const checkUntil = `${year}-${month}-${date}T${this.registerHourForm.value.until}:00.000`;
      let until = moment(checkUntil).utc();
      if (tempBy.isAfter(until)) {
        until = until.add(1, 'days');
      }

      body = {
        ...this.registerHourForm.value,
        until,
        timeSheetDate: this.activeDate,
        businessId: this.businessId,
        paused: this.registerHourForm.value.paused || 0,
        state: null,
        by: tempBy,
        isManual: this.isManual,
      };
    }

    this.timeSheetService
      .createTimeSheet(body)
      .pipe(
        tap((response) => {
          this.handlePagination(this.activeDate);
          this.toaster.success(response?.message);
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          this.notClickable = false;
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  closeUpdateModal(): void {
    this.showUpdateModal = false;
    this.resetFormErrors();
    this.updateRegisterHourForm.reset();
  }

  stopTimer(id: string): void {
    this.spinner.show();
    const timeSheet: TimeSheetDto = this.tableData.find(
      (ele) => ele._id === id
    );

    let body = {} as StopTimeSheetDto;
    body = {
      totalWorked: timeSheet.totalWorked + timeSheet.tempTotalWorked,
      until: moment().utc().format(),
      state: 'STOP',
    };
    this.timeSheetService
      .stopTimeSheet(id, body)
      .pipe(
        tap(() => {
          this.handlePagination(this.activeDate);
          this.globalTimerService.hide();
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  resumeTimer(id: string): void {
    this.spinner.show();
    const timeSheet = this.tableData.find((ele) => ele._id === id);
    let prevTimeSheet: TimeSheetDto | null = null;
    if (this.previousId) {
      prevTimeSheet = this.tableData.find((ele) => ele._id === this.previousId);
    }
    const body: ResumeTimeSheetDto = {
      previous: prevTimeSheet
        ? {
            _id: prevTimeSheet?._id,
            totalWorked:
              prevTimeSheet?.totalWorked + prevTimeSheet?.tempTotalWorked,
          }
        : null,
      current: {
        paused:
          timeSheet.paused + moment().diff(moment(timeSheet.until), 'seconds'),
        state: 'RESUME',
      },
      until: moment().utc().format(),
    };

    this.timeSheetService
      .resumeTimeSheet(id, body)
      .pipe(
        tap(() => {
          this.handlePagination(this.activeDate);
        }),
        catchError((error: HttpErrorResponse) => {
          this.spinner.hide();
          console.log('Error occurred:', error);
          if (error.status >= 500) {
            this.toaster.error(
              this.translateService.instant('Something went wrong!')
            );
          } else {
            this.toaster.error(error.error.message);
          }
          return of(null);
        }),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    clearInterval(this.timer);
    clearInterval(this.intervalId);
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
