<div class="flex justify-between">
    <div class=" space-y-4 border-gray-300 border-r w-1/2">
        <div *ngIf='!update else elseUpdate'>
            <h3 class="text-2xl Poppins-Bold">{{'Invite new user to' | translate }} {{companyName}}</h3>
            <p class="Poppins-SemiBold text-sm mt-2">{{'Send email invitation to invite user' | translate }}</p>
        </div>
        <ng-template #elseUpdate>
            <h3 class="text-2xl Poppins-Bold">{{'Edit invited user permissions' | translate }}</h3>
        </ng-template>
        <form [formGroup]="inviteForm" class="space-y-4 w-10/12">
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='firstName' class="block text-sm Poppins-Bold font-color-05">{{'First Name' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='firstName' formControlName='firstName' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter first name' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.firstName' class="text-sm text-red-400 ms-2">{{formErrors.firstName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='lastName' class="block text-sm Poppins-Bold font-color-05">{{'Last Name' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='lastName' formControlName='lastName' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter last name' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.lastName' class="text-sm text-red-400 ms-2">{{formErrors.lastName}}</div>
            </div>
            <div>
                <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='email' class="block text-sm Poppins-Bold font-color-05">{{'Email Address' | translate }}</label>
                    <fieldset [disabled]="update">
                        <input id='email' formControlName='email' type="text"
                            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-300" placeholder="{{'Enter email' | translate }}" />
                    </fieldset>
                </div>
                <div *ngIf='formErrors.email' class="text-sm text-red-400 ms-2">{{formErrors.email}}</div>
            </div>
            <div class="space-y-1">
                <div class="text-lg Poppins-Bold">{{'Position' | translate }}</div>
                <div>
                    <label class="inline-flex items-center gap-x-2">
                        <input type="radio" formControlName='position' value="admin" name="position">
                        <span>{{'Admin' | translate }}</span>
                    </label>
                </div>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" value="employee" formControlName='position' name="position">
                        <span class="ms-2">{{'Employee' | translate }}</span>
                    </label>
                </div>
                <div>
                    <label class="inline-flex items-center">
                        <input type="radio" value="custom" formControlName='position' name="position">
                        <span class="ms-2">{{'Custom' | translate }}</span>
                    </label>
                </div>
            </div>
            <div class="flex items-center gap-x-2">
                <app-button *ngIf='!update' buttonText='{{"Invite" | translate }}' rounded='full' size='large' type='primary' (handleClick)='inviteUser()'></app-button>
                <app-button *ngIf='update' buttonText='{{"Update" | translate }}' rounded='full' size='large' type='primary' (handleClick)='updateUser()'></app-button>
                <span *ngIf="error" class="text-red-500">{{error}}</span>
                <span *ngIf="response" class="text-green-500">{{response}}</span>
            </div>
        </form>
    </div>
    <div class="w-1/2 flex justify-end">
        <div class="space-y-4 w-10/12">
            <div>
                <h3 class="text-2xl Poppins-Bold">{{inviteForm.controls.position.value | titlecase}} {{'permissions' | translate }}</h3>
                <p *ngIf="inviteForm.controls.position.value === 'owner'" class="Poppins-SemiBold text-sm mt-2">{{'Best for anyone who needs full access' | translate }}</p>
                <p *ngIf="inviteForm.controls.position.value === 'admin'" class="Poppins-SemiBold text-sm mt-2">{{'Best for anyone who needs full access' | translate }}</p>
                <p *ngIf="inviteForm.controls.position.value === 'employee'" class="Poppins-SemiBold text-sm mt-2">{{'Best for anyone who needs view-only access' | translate }}</p>
                <p *ngIf="inviteForm.controls.position.value === 'custom'" class="Poppins-SemiBold text-sm mt-2">{{'Customize your own set of permissions' | translate }}</p>
            </div>
            <div [formGroup]='permissionsForm'>
                <h2 class="Poppins-Bold text-lg mt-2">Administration</h2>
                <ul class="ps-5">
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Dashboard</p>
                            <select formControlName='dashboard' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Mark As Reviewed</p>
                            <select (change)="handleMarkAsReviewed($event)" formControlName='markAsReviewed' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Manage Chat Channels</p>
                            <select formControlName='mangeChannel' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Users Management</p>
                            <select formControlName='userManagement' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">{{ 'Setting and Subscriptions' | translate }}</p>
                            <select formControlName='setting' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
                
                <h2 class="Poppins-Bold text-lg mt-2">Accounting</h2>
                <ul class="ps-5">
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">{{'Sales' | translate }}</p>
                            <select formControlName='sales' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Purchase</p>
                            <select formControlName='purchase' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Accounting</p>
                            <select (change)="handleMarkAsReviewed($event)" formControlName='accounting' class="rounded-lg border-gray-300  py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                        
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Reports</p>
                            <select formControlName='reports' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Projects</p>
                            <select formControlName='projects' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
                
                <h2 class="Poppins-Bold text-lg mt-2">Add-Ons</h2>
                <ul class="ps-5"> 
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Inventory</p>
                            <select formControlName='inventory' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Time Sheets</p>
                            <select formControlName='timeSheets' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">P/L Budget</p>
                            <select formControlName='budget' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Files</p>
                            <select formControlName='files' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
                
                <h2 class="Poppins-Bold text-lg mt-2">Platforms</h2>
                <ul class="ps-5"> 
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">POS</p>
                            <select formControlName='pos' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Expert</p>
                            <select formControlName='expert' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                    <li class="border-b py-2">
                        <div class="flex items-center justify-between">
                            <p class="Poppins-SemiBold">Payroll</p>
                            <select formControlName='payroll' class="rounded-lg border-gray-300 py-1 focus:ring-0">
                                <option value="view">{{'View Only' | translate}}</option>
                                <option value="edit">{{'Edit' | translate }}</option>
                                <option value="none">{{'None' | translate }}</option>
                            </select>
                        </div>
                    </li>
                </ul>
                <!-- <li class="border-b py-2">
                    <div class="flex items-center justify-between">
                        <p class="Poppins-SemiBold">Data Export</p>
                        <select formControlName='dataExport' class="rounded-lg border-gray-300  py-1 focus:ring-0">
                            <option value="view">View Only</option>
                            <option value="edit">Edit</option>

                        </select>
                    </div>
                </li> -->
            </div>
        </div>
    </div>
</div>