import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { DiscountCodeService } from '../../discount-code.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-discount-code-management',
  templateUrl: './discount-code-management.component.html',
  styleUrls: ['./discount-code-management.component.scss']
})
export class DiscountCodeManagementComponent implements OnInit {
  showDiscountCodeModal = false;
  discountCodeForm:FormGroup;
  formErrors = {
    code : '',
    discountType : '',
    discountValue : '',
    discountCount : '',
    expiryDate : '',
    customerUsageCount : '',
    description : '',
  }
  formErrorMessages = {
    code : {
      required : 'Discount code is required'
    },
    discountType : {
      required : 'Discount type is required'
    },
    discountValue : {
      required : 'Discount value is required'
    },
    discountCount : { 
      required : 'Usage limit is required'
    },
    customerUsageCount : { 
      required : 'Single customer usage limit is required'
    },
    expiryDate : {
      required : 'Expiry Date is required'
    },
    description : {
      required : 'Description is required'
    }
  }
  tableHeadings = [
    'Discount Code',
    'Discount Type',
    'Discount Value',
    'Limit Usage',
    'Single Customer Usage Limit',
    'Code Used',
    'Expiry Date',
    'Status',
    'Actions'
  ]
  tableKeys = [
    'code',
    'discountType',
    'discountValue',
    'discountCount',
    'customerUsageCount'
  ]
  tableData:any = []
  businessId:any;
  business$: Observable<any>;
  editCode = false;
  codeId:any;
  constructor(
    private fb : FormBuilder,
    private discountCodeService : DiscountCodeService,
    private spinner : NgxSpinnerService,
    private toast : ToastrService,
    private rootStore: Store<RootReducerState>,
    private translateService : TranslateService,
    private router : Router
    ) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
     }
  
  ngOnInit(): void {
    this.loadDiscountCodeForm()
    this.loadBusiness()
  }
  loadBusiness(): void {
    this.business$.subscribe(business => {
      if(business?.businessId){
        this.businessId = business?.businessId._id;
        this.loadDiscountCodes(this.businessId)
      }
    });
  }
  
  loadDiscountCodeForm() {
    this.discountCodeForm = this.fb.group({
      code : [null, [Validators.required]],
      discountType : [null, [Validators.required]],
      discountValue : [null, [Validators.required]],
      discountCount : [null, Validators.required],
      customerUsageCount : [null, Validators.required],
      expiryDate : [null, [Validators.required]],
      description : [null,Validators.required]
    })
    this.discountCodeForm.valueChanges.subscribe(()=>{
      this.formErrors = valueChanges(
        this.discountCodeForm,
        {...this.formErrors},
        this.formErrorMessages
      )
    })
    this.formErrors = valueChanges(
      this.discountCodeForm,
      {...this.formErrors},
      this.formErrorMessages
    )
  }
  loadDiscountCodes(businessId:any) {
    this.spinner.show();
    this.discountCodeService.getDiscountCodes(businessId).subscribe({
      next : (response)=>{
        this.spinner.hide()
        this.tableData = response.data
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
  }
  createDiscountCode() {
    if(this.discountCodeForm.invalid) {
      this.discountCodeForm.markAllAsTouched()
      this.formErrors = valueChanges(
        this.discountCodeForm,
        {...this.formErrors},
        this.formErrorMessages
      )
      return;
    }
    const payload = {
      ...this.discountCodeForm.value
    }
    this.spinner.show()
    this.discountCodeService.createDiscountCode(this.businessId,payload).subscribe({
      next : (response)=>{
        this.spinner.hide()
        this.showDiscountCodeModal = false;
        this.discountCodeForm.reset();
        this.loadDiscountCodes(this.businessId)
        this.toast.success(this.translateService.instant('Discount code created successfully'))
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
  }
  updateDiscountCode() {
    const payload = {
      ...this.discountCodeForm.value
    }
    this.spinner.show();
    this.discountCodeService.updateDiscountCode(this.codeId, payload).subscribe({
      next : (response)=>{
        this.spinner.hide();
        this.showDiscountCodeModal = false;
        this.editCode = false;
        this.loadDiscountCodes(this.businessId)
        this.toast.success(this.translateService.instant('Discount code updated successfully'))
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide();
        console.log(error)
      }
    })
  }
  changeStatus(item:any) {
    const payload = {
      isActive : item.isActive
    }
    this.spinner.show();
    this.discountCodeService.updateDiscountCode(item._id, payload).subscribe({
      next : (response)=>{
        this.spinner.hide();
        this.toast.success(this.translateService.instant('Discount code status updated successfully'))
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide();
        console.log(error)
      }
    })
  }
  openDiscountModal() {
    this.showDiscountCodeModal = true
    this.editCode = false
    this.discountCodeForm.reset()
  }
  openEditModal(codeDetails:any) {
    this.codeId = codeDetails._id
    this.editCode = true
    this.showDiscountCodeModal = true;
    const expiryDate = codeDetails.expiryDate.split('T')[0]
    this.discountCodeForm.patchValue(codeDetails)
    this.discountCodeForm.get('expiryDate').setValue(expiryDate)
  }
  navToView(id:any) {
    this.router.navigate([`/settings/sales/view-discount-code/${id}`])
  }
}
