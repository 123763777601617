import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { throwError } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { SubscriptionService } from 'src/app/modules/settings/settings-modules/subscription/subscription.service';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from '../../auth.service';
import Tap from 'src/tapfiliate';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-verify-subcription',
  templateUrl: './verify-subcription.component.html',
  styleUrls: ['./verify-subcription.component.scss'],
})
export class VerifySubcriptionComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private subcriptionService: SubscriptionService,
    private utility: UtilityService
  ) {}

  verifingSubscription = true;
  subscripitionVerified = false;
  subscripitionNotVerified = false;
  userDataNotFetched = false;
  currentUrl = window.location.href;
  routeTo = null;
  dialogConf = {
    open: false,
    tab: '',
  };

  ngOnInit(): void {
    this.route.queryParams.subscribe(
      (params) => {
        if (params.subscription_id && params.id) {
          const body = {
            subId: params.subscription_id,
            subscriptionId: params.id,
          };
          this.verifySubscription(body);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  verifySubscription(body): void {
    let businessId = localStorage.getItem('selectedBusiness');
    this.userService
      .getUserDetails(businessId)
      .pipe(
        mergeMap((userResp) =>
          this.subcriptionService.getPlans().pipe(
            map((plansData) => {
              const subscriptionPlans = plansData.data;
              const selectedPlan = subscriptionPlans.find(
                (plan) => plan._id === body.subscriptionId
              );
              const currentPlan = subscriptionPlans.find(
                ({ _id }) => _id === userResp.data.subscription.subscriptionId
              );
              if (userResp?.data?.businesses?.length === 0) {
                this.routeTo = '/auth/personal-details';
              } else if (userResp?.data?.businesses?.length > 0) {
                const doIhaveBusiness = userResp.data.businesses.find(
                  (business) => {
                    if (business.businessId.userId === userResp.data._id) {
                      return business;
                    }
                  }
                );
                if (doIhaveBusiness) {
                  this.routeTo = '/settings/subscription/subscription-plans';
                  this.dialogConf = {
                    open: true,
                    tab: 'subscriptions',
                  };
                } else {
                  this.routeTo = '/settings/businesses?showForm=true';
                  this.dialogConf = {
                    open: true,
                    tab: 'business',
                  };
                }
              }
              body = {
                ...body,
                planName: selectedPlan.planName,
                planPrice: selectedPlan.planPrice,
              };
              if (
                currentPlan &&
                currentPlan.planPrice > selectedPlan.planPrice
              ) {
                body = { ...body, type: 'downgrade' };
              }
              return body;
            }),
            catchError((error) => throwError(error))
          )
        ),
        catchError((error) => throwError(error))
      )
      .subscribe(
        (finalData) => {
          this.spinner.show();
          console.log(finalData);
          debugger;
          this.authService.verifySubscription(body).subscribe(
            (resp) => {
              this.spinner.hide();
              console.log(resp);
              if (resp.success) {
                Tap.conversion(resp?.data?.id, 500);
                this.verifingSubscription = false;
                this.subscripitionVerified = true;
                setTimeout(() => {
                  // this.router.navigate([this.routeTo]);
                  this.utility.showHideSettingsModal.next(this.dialogConf);
                }, 3000);
              }
            },
            (error) => {
              this.spinner.hide();
              this.subscripitionNotVerified = true;
              this.verifingSubscription = false;
              console.log(error);
            }
          );
        },
        (error) => {
          this.userDataNotFetched = true;
          this.verifingSubscription = false;
          this.spinner.hide();
          console.log(error);
        }
      );
  }
}
