<app-document-container [templateSelected]="true">
    <div id="template-option-one" class="bg-white m-auto w-A3 text-zinc-600 relative" style="width: 1020px!important; box-shadow: 0px 0px 14px #0000001a;">
        <div class="grid grid-cols-3">
            <div class="col-span-1 bg-[#F6F6F6] py-6">
                <img
                    [src]="invoiceData?.companylogo" 
                    alt="logo"
                    class="w-44 object-contain mt-20 mx-auto" />
                <div style="padding: 0rem 3.5rem;">
                    <div class="text-sm font-semibold mt-12">{{invoiceData?.invoiceDate | date}}</div>
                    <div class="text-sm mt-4">{{ "Billing To" | translate }}:</div>
                    <div class="text-sm mt-4 font-semibold">{{invoiceData?.customerDetails?.customerId?.customerName | titlecase}}</div>
                    <div class="text-sm mt-4">{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1}}</div>
                    <div class="text-sm">{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2}}</div>
                    <div class="text-sm">{{invoiceData?.customerDetails?.customerId?.billingAddress?.city ? invoiceData?.customerDetails?.customerId?.billingAddress?.city + ',' : ''}} {{invoiceData?.customerDetails?.customerId?.billingAddress?.state}}</div>
                    <div class="text-sm">{{invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}}</div>
                    <div class="text-sm">{{invoiceData?.customerDetails?.customerId?.billingAddress?.country}} </div>
                    <div class="text-sm mt-4">{{invoiceData?.customerDetails?.customerId?.mobileNumber}}</div>

                    <hr class="border-t-4 border-white my-12" />

                    <div class="text-sm">{{"Payment Methods" | translate}}:</div>
                    <div
                        class="ps-4 mt-4 font-bold before:w-2 before:h-2 before:bg-[#D4D4D4] relative before:absolute before:rounded-full ltr:before:left-0 rtl:before:right-0 before:top-1/2 before:-translate-y-1/2">
                        {{"Pay Online" | translate}}:
                    </div>
                    <div
                        class="ps-4 mt-4 before:w-[2px] before:h-28 before:bg-[#D4D4D4] relative before:absolute ltr:before:left-[3px] rtl:before:right-[3px] before:-top-[32px] before:bottom-[-32px]">
                        <div>
                            Visa, MasterCard, <span (click)="paymentLink()" *ngIf="businessData?.paytabsAuthorizationToken && businessData?.paytabsProfileId && invoiceData?.dueAmount > 0 && invoiceData?.acceptOnlinePayment" class="font-light text-blue-500 cursor-pointer">Click Here</span>
                        </div>
                        <div>Cliq ({{invoiceData?.businessDetails?.businessId?.companyName}})</div>
                    </div>
                    <div
                        class="ps-4 mt-4 font-bold before:w-2 before:h-2 before:bg-[#D4D4D4] relative before:absolute before:rounded-full ltr:before:left-0 rtl:before:right-0 before:top-1/2 before:-translate-y-1/2">
                        Wire Transfer IBAN:
                    </div>
                    <div class="ps-4 mt-4">
                        <div>{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                        <!-- <div class="font-medium">
                            JO94 CBJO 0010 0000 0000 0131 0003 02
                        </div> -->
                    </div>
                </div>
                <div class="bg-[#EFF1F1] my-12 ps-14 py-4">
                    <div class="text-xl">Total Due:</div>
                    <div class="text-2xl font-semibold">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                    <div class="text-xl mt-3">Due {{invoiceData?.dueDate | date}}</div>
                </div>
                <div *ngIf="invoiceData?.notesTerms" style="padding: 0rem 3.5rem;">
                    <div class="text-sm">{{"Terms & Conditions" | translate}}:</div>
                    <div
                        class="ps-4 mt-4 text-sm before:w-2 before:h-2 before:bg-[#D4D4D4] relative before:absolute before:rounded-full ltr:before:left-0 rtl:before:right-0 before:top-2">
                        {{invoiceData?.notesTerms}}
                    </div>
                    <!-- <div
                        class="ps-4 mt-3 text-sm before:w-2 before:h-2 before:bg-[#D4D4D4] relative before:absolute before:rounded-full before:left-0 before:top-2">
                        Duis autem wisi enim minim veniam, nos tation ullamcorper suscipit
                        lobortis nisl aliquip commodo consequat.
                    </div> -->
                </div>
            </div>
            <div class="col-span-2 pt-6 px-8">
                <div class="mt-20">
                    <h1 class="text-5xl ltr:text-right rtl:text-left  tracking-widest">{{"INVOICE" | translate}}</h1>
                    <h2 class="text-base ltr:text-right rtl:text-left  tracking-widest mt-2">
                        {{invoiceData?.invoiceNumber}}
                    </h2>
                </div>
                <div style="min-height: 980px;">
                    <table class="w-full border-y-2 pb-12 mt-12 border-[#929292]">
                        <thead>
                            <tr class="border-b-2 border-[#929292]">
                                <th class="py-2 pb-3 tracking-widest ltr:text-left rtl:text-right w-2/5">{{"PRODUCT" | translate}}</th>
                                <th class="py-2 pb-3 tracking-widest ltr:text-left rtl:text-right">{{"UNIT PRICE" | translate}}</th>
                                <th class="py-2 pb-3 tracking-widest">{{"QTY" | translate}}</th>
                                <th class="py-2 pb-3 tracking-widest text-end">{{"AMOUNT" | translate}}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngFor='let item of invoiceData?.items'>
                                <tr>
                                    <td class="pt-8 pb-3 font-medium">{{item.item}}</td>
                                    <td class="pt-8 pb-3">{{item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                    <td class="pt-8 pb-3 text-center">{{item.unit}}</td>
                                    <td class="pt-8 pb-3 text-end">{{item.unit * item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                                </tr>
                                <tr *ngIf="item.itemDescription">
                                    <td colspan="4">
                                        <div
                                            class="ms-12 text-justify ps-4 mt-3 text-sm before:w-2 before:h-2 before:bg-[#D4D4D4] relative before:absolute before:rounded-full ltr:before:left-0 rtl:before-right-0 before:top-2">
                                            {{item.itemDescription }}
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                    </table>
                    <div class="grid grid-cols-2 border-b-2 border-b-[#929292] pb-6">
                        <div class="col-span-1 ps-3">
                            <div class="pt-6 font-medium">{{"Sub-Total" | translate}}</div>
                            <div class="pt-2">{{"Tax" | translate}}</div>
                            <div *ngIf='invoiceData?.discount' class="pt-2">{{"Discount" | translate}} - ({{invoiceData?.discount | number : number}}%)</div>
                        </div>
                        <div class="col-span-1 ltr:text-right rtl:text-left ">
                            <div class="pt-6 font-medium">{{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                            <div class="pt-2">{{invoiceData?.tax | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                            <div *ngIf='invoiceData?.discount' class="pt-2">-{{invoiceData?.subtotal - invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 ps-3">
                            <div class="pt-6 font-medium" style="letter-spacing: 5px;">
                                {{"GRAND TOTAL" | translate}}
                            </div>
                        </div>
                        <div class="col-span-1 ltr:text-right rtl:text-left ">
                            <div class="pt-6 font-medium">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</div>
                        </div>
                    </div>
                    <div class="grid grid-cols-2">
                        <div class="col-span-1 ps-3"></div>
                        <ng-container *ngIf="invoiceData?.eSign">
                            <div class="pt-12 font-medium text-xs">
                                {{"Electronically Signed By" | translate}}
                            </div>
                            <div class="text-xs">{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                            <img class="w-300" src="{{invoiceData?.eSign}}"class="w-44 object-contain ltr:float-right rtl:float-left mt-6"  />
                        </ng-container>
                    </div>
                </div>
                <div class="grid grid-cols-2">
                    <div class="col-span-1 ps-3">
                        <ng-container *ngIf="invoiceData?.qrCodeWithUuid">
                            <img src="{{invoiceData?.qrCodeWithUuid}}" alt="" class="w-44 object-contain" />
                        </ng-container>
                    </div>
                    <div class="col-span-1 ltr:text-right rtl:text-left ">
                        <div class="font-medium text-xs">
                            {{invoiceData?.businessDetails?.businessId?.companyName}}
                        </div>
                        <div class="text-sm mt-6">{{invoiceData?.businessDetails?.businessId?.address?.addressLineFirst}}</div>
                        <div class="text-sm">{{invoiceData?.businessDetails?.businessId?.address?.addressLine2 ? invoiceData?.businessDetails?.businessId?.address?.addressLine2 + ',' : ''}} {{invoiceData?.businessDetails?.businessId?.address?.city ? invoiceData?.businessDetails?.businessId?.address?.city + ',' : '' }} {{invoiceData?.businessDetails?.businessId?.address?.state || ''}}</div>
                        <div class="text-sm">{{invoiceData?.businessDetails?.businessId?.country}}</div>
                        <!-- <div class="text-sm flex justify-end gap-2 mt-6"><b>M</b> <span>+962 77 777 7777</span></div>
                        <div class="text-sm flex justify-end gap-2">
                            <b>E</b> <a href="#" class="underline">hello@brightpath.ws</a>
                        </div> -->
                        <div class="grid grid-cols-5 mt-4 w-52 ltr:float-right rtl:float-left">
                            <div class="col-span-2 ps-5">
                                <div *ngIf="invoiceData?.businessDetails?.businessId?.entityId" class="text-sm font-bold ltr:text-left rtl:text-right">{{"Entity ID" | translate}}</div>
                                <div *ngIf="invoiceData?.businessDetails?.businessId?.taxId" class="text-sm font-bold ltr:text-left rtl:text-right">{{"TAX ID" | translate}}</div>
                            </div>
                            <div class="col-span-3">
                                <div *ngIf="invoiceData?.businessDetails?.businessId?.entityId" class="text-sm">{{invoiceData?.businessDetails?.businessId?.entityId ? invoiceData?.businessDetails?.businessId?.entityId : '--'}}</div>
                                <div *ngIf="invoiceData?.businessDetails?.businessId?.taxId" class="text-sm">{{invoiceData?.businessDetails?.businessId?.taxId ? invoiceData?.businessDetails?.businessId?.taxId : '--'}}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div id="pdf-footer" style="display: none!important;">
            <div class="flex justify-between items-center px-8 w-full pb-6">
                <div>{{"POWERED BY NuMetric" | translate}}</div>
                <div>{{"Page" | translate}} 1 of 1</div>
                <div>{{"ISSUED BY" | translate}} <span class="font-medium">{{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</span></div>
            </div>
        </div>
        <div id="pdf-footer-display">
            <div class="flex justify-between items-center px-8 w-full py-6">
                <div>{{"POWERED BY NuMetric" | translate}}</div>
                <div>{{"Page" | translate}} 1 of 1</div>
                <div>{{"ISSUED BY" | translate}} <span class="font-medium">{{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</span></div>
            </div>
        </div>
    </div>
</app-document-container>
