import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'empty',
})
export class EmptyPipe implements PipeTransform {
  /**
   * Transforms the input value by returning a default empty value if the input is undefined, null, or an empty string.
   * @param value The value to transform. Can be undefined, null, a string, or a number.
   * @param emptyValue The value to return if the input is considered empty. Default is '--'.
   * @returns The original value as a string if it is not empty, or the emptyValue if it is.
   */
  transform(value: undefined | null | string | number, emptyValue: string = '-'): string {
    // Handle undefined and null values
    if (value === undefined || value === null) {
      return emptyValue;
    }

    // Handle string and numeric values
    const stringValue = value.toString().trim();
    if (stringValue === '') {
      return emptyValue;
    }

    // Return the original value as a string
    return stringValue;
  }
}

