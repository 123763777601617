<div class="">
  <div class="bg-white rounded-lg p-8">
    <div class="flex justify-between mb-4">
      <div class="flex flex-col">
        <img
          *ngIf="config?.businessInfo?.businessLogo"
          width="240"
          [src]="config?.businessInfo?.businessLogo"
          srcset=""
        />
        <span class="Poppins-Bold">{{
          config?.businessInfo?.businessName
        }}</span>
        <div *ngIf="config.businessInfo.businessAddress.addressLineFirst">
          {{ config.businessInfo.businessAddress.addressLineFirst }}
        </div>
        <div *ngIf="config.businessInfo.businessAddress.addressLineSecond">
          {{ config.businessInfo.businessAddress.addressLineSecond }}
        </div>
        <div>
          {{ config?.businessInfo?.businessAddress?.city
          }}{{ config?.businessInfo?.businessAddress?.city ? "," : "" }}
          {{ config.businessInfo.businessAddress.state }}
        </div>
        <div>{{ config.businessInfo.businessAddress.country }}</div>
      </div>
      <div class="flex flex-col self-center">
        <h1 class="text-3xl Poppins-Bold mb-1">
          {{ "Statement of Account" | translate }}
        </h1>
        <h2 class="text-xl Poppins-Bold">{{ config.type }}</h2>
      </div>
    </div>
    <div class="flex justify-between">
      <div class="flex flex-col">
        <span class="text-gray-700 mb-1">{{ "Bill To" | translate }}</span>
        <span class="Poppins-Bold">{{ config.customerInfo.customerName }}</span>
        <span class=""
          >{{ config.customerInfo.customerFirstName }}
          {{ config.customerInfo.customerLastName }}</span
        >
      </div>
      <div class="flex flex-col w-1/2">
        <table>
          <tr *ngIf="config.fromDate && config.endDate">
            <td class="p-2 text-end">{{ "From Date" | translate }}</td>
            <td class="p-2 text-end">{{ config.fromDate }}</td>
          </tr>
          <tr class="border-b" *ngIf="config.fromDate && config.endDate">
            <td class="p-2 text-end">{{ "End Date" | translate }}</td>
            <td class="p-2 text-end">{{ config.endDate }}</td>
          </tr>
          <tr class="border-b" *ngIf="config.fromDate && !config.endDate">
            <td></td>
            <td class="p-2 text-end">
              {{ "As of" | translate }} {{ config.fromDate | date : "short" }}
            </td>
          </tr>
          <tr *ngFor="let stat of config.quickStats; let last = last">
            <td
              [ngClass]="last ? 'Poppins-Bold bg-input' : ''"
              class="p-2 text-end"
            >
              {{ stat.type }}
            </td>
            <td
              [ngClass]="last ? 'Poppins-Bold bg-input' : ''"
              class="p-2 text-end"
            >
              {{ stat.value }}
            </td>
          </tr>
          <tr>
            <td colspan="2" align="right">
              {{
                config?.userId?.firstName
                  ? "Issued By: " +
                    config?.userId?.firstName +
                    " " +
                    config?.userId?.lastName
                  : "--"
              }}
            </td>
          </tr>
        </table>
      </div>
    </div>
    <app-table-container
      [tableHeadings]="config.tableHeadings"
      [tableData]="config.tableData"
      [keys]="config.tableKeys"
    >
    </app-table-container>
  </div>
</div>
