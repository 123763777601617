import { Injectable } from '@angular/core';
import {HttpClient } from '@angular/common/http'

@Injectable({
  providedIn: 'root'
})
export class VersionCheckService {

  private currentHash = '{{POST_BUILD_ENTERS_HASH_HERE}}';

  constructor(private http:HttpClient) { }

  public initVersionCheck(url, frequency = 1000 * 60 * 10){
    setInterval(() =>{
      console.log("Check version change")
      this.checkVersion(url)
    }, frequency)
  }

  private checkVersion(url){
    this.http.get(url + '?t=' + new Date().getTime())
      .subscribe((response: any) =>{
        const hash = response.hash;
        console.log("hash", hash)
        console.log("Current hash", this.currentHash)
        const hashChanged = this.hasHashChanged(this.currentHash, hash);
        console.log("hash Changed", hashChanged);
        if(hashChanged){
          window.location.reload();
          console.log("Reloaded")
        }
        this.currentHash = hash
        console.log("Current hash update", this.currentHash)
      }, (err) =>{
        console.log(err, 'Could not get version')
      });
  }

  private hasHashChanged(currentHash, newHash){
    if(!currentHash || currentHash === '{{POST_BUILD_ENTERS_HASH_HERE}}'){
      return false;
    }
    return currentHash !== newHash
  }  

}
