import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { Observable, Subject } from 'rxjs';
import { DateValidator } from 'src/app/shared/utils/formValidator';
import { RecurringBillService } from '../../recurring-bill.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { NumberService } from 'src/app/shared/services/number.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-view-recurring-bill',
  templateUrl: './view-recurring-bill.component.html',
  styleUrls: ['./view-recurring-bill.component.scss']
})
export class ViewRecurringBillComponent implements OnInit {

  unsubscribe = new Subject();
  customizationSettings$: Observable<any>;
  business$: Observable<any>;

  minDate = moment().format('YYYY-MM-DD')
  updatedDate = new FormControl(moment().format('YYYY-MM-DD'), [Validators.required, DateValidator()]);

  customizationSettings;
  billData = null;
  number;
  decimalSize;
  createdBills = [];
  files: File[] = [];
  addedFiles = [];
  showExtendModal: boolean = false;
  showStopModal: boolean = false;

  constructor(
    private route: ActivatedRoute, private router: Router,
    private recurringBillService: RecurringBillService,
    private spinner: NgxSpinnerService, private toastr: ToastrService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService
  ) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      console.log(params);
      if(params?.id){
        this.loadBillData(params?.id);
        this.loadNumberConfig();
      }
      else {
        this.router.navigate(['/purchases/recurring-bill']);
      }
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })

    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((decimalSize) => {
      this.decimalSize = decimalSize
    })
  }

  loadBillData(id): void {
    this.spinner.show();
    this.recurringBillService.getBillById(id)
    .pipe(
      takeUntil(
        this.unsubscribe
      )
    )
    .subscribe(resp => {
      if(resp?.success){
        this.billData = resp?.data;
        this.addedFiles = resp?.data?.files;
        this.createdBills = resp?.recurringBills;
        this.updatedDate.setValue(moment(this.billData?.startDate).isAfter(moment()) ? moment(this.billData.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD'))
        this.minDate = moment(this.billData?.startDate).isAfter(moment()) ? moment(this.billData.startDate).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD');
      }
      else{
        this.toastr.error(resp?.message);
        this.router.navigate(['/purchases/recurring-bill']);
      }
      this.spinner.hide()
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.router.navigate(['/purchases/recurring-bill']);
    })
  }

  stopRecurringBill(): void {
    this.spinner.show();
    this.recurringBillService.stopRecurringBill(this.billData?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.billData.isCanceled = true;
        this.billData.cancelDate = moment().format('YYYY-MM-DD');
        this.toastr.success(resp?.message);
        this.showStopModal = false;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  showUpdateModal(): void {
    this.updatedDate.setValue(moment(this.billData?.endDate).format('YYYY-MM-DD'));
    this.showExtendModal = true;
  }

  updateBillDate(): void {
    if(this.updatedDate.invalid){
      this.updatedDate.markAllAsTouched();
      return;
    }
    this.spinner.show();
    this.recurringBillService.updateBill(this.updatedDate.value, this.billData?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.billData.endDate = this.updatedDate.value;
        this.billData.isEnded = resp?.data?.isEnded;
        this.showExtendModal = false;
        this.toastr.success(resp?.message);
      }
      else {
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  viewBill(billId): void {
    this.router.navigate(['/purchases/bills/view-bill'], {queryParams: {id: billId}});
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toastr.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.billData?.businessDetails?.businessId?._id,
      referenceId: this.billData?._id,
      documentType: 'Recurring-Bill',
      parentDocType: 'Purchases'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view recurring bill');
        this.addedFiles = resp?.data?.files ?? [];
        this.toastr.success(resp?.message);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]){
    this.files = files;
  }

}
