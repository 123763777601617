import { Component, Input, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';

@Component({
  selector: 'app-template-two',
  templateUrl: './template-two.component.html',
  styleUrls: ['./template-two.component.scss']
})
export class TemplateTwoComponent implements OnInit {

  constructor(private numberService: NumberService) { }

  @Input() invoiceData;

  unsubscribe = new Subject();

  number = '1.2-2';

  ngOnInit(): void {
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }

}
