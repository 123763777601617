<div class="flex min-h-screen">
  <div class="w-full flex flex-col space-y-4">
    <!-- Charts starts -->
    <div class="flex w-full">
      <div
        class="px-5 flex flex-col justify-center items-center w-full gap-8"
      >
        <div class="flex gap-3 items-center justify-center">
          <label class="text-base Poppins-Medium text-center">{{
            "Type of Chart"
          }}</label>
          <div class="">
            <div class="flex">
              <div class="w-64">
                <ng-select
                  [items]="typeOfChart"
                  bindLabel="name"
                  class="new-dropdown"
                  [searchable]="false"
                  [clearable]="false"
                  placeholder="Chart Type"
                  bindValue="value"
                  [(ngModel)]="selectedType"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.name }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div class="">
            <div class="flex">
              <div class="w-64">
                <ng-select
                  [items]="years"
                  bindLabel="name"
                  [searchable]="false"
                  class="new-dropdown"
                  [clearable]="false"
                  placeholder="Years"
                  [(ngModel)]="selectedYear"
                  bindValue="value"
                  (change)="dataChange()"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item }}
                      </div>
                    </div>
                  </ng-template></ng-select
                >
              </div>
            </div>
          </div>
        </div>

        <!-- chart for sales Growth -->
        <div class="flex flex-col mt-5" *ngIf="selectedType === 'sales'">
          <!-- <div class="flex items-center justify-between mb-3 mt-3" >
              <h1 class="text-xl Poppins-Bold">{{'Sales Growth' |translate}}</h1>
            </div> -->
          <div class="flex justify-center flex-col relative">
            <div class="flex gap-8 w-full">
              <div class="flex items-center gap-1 m-8">
                <div
                  class="w-2 h-2 rounded-full"
                  style="background-color: rgb(12, 156, 213)"
                ></div>
                <div class="text-xs Poppins-Medium">
                  {{ "Sales in %" }}
                </div>
              </div>
            </div>
            <div
              class="bg-white p-2 mb-3 rounded-lg"
              style="width: 997px; height: 565px"
            >
              <!-- <div class="flex justify-center">
                  <div class="bg-blue-500 w-9 h-3 absolute"></div>
                  <div class="text-xs Poppins-Medium text-gray-500">{{'Sales Growth in %'}}</div>
                </div> -->
              <canvas
                baseChart
                [datasets]="salesGrowData"
                [labels]="salesGrowLabels"
                [options]="salesGrowOptions"
                [legend]="salesGrowLegend"
                [chartType]="salesGrowType"
              >
              </canvas>
            </div>
          </div>
        </div>

        <!-- chart for Sales Growth ends here -->

        <!--chart for actual sales and target -->
        <div class="flex flex-col mt-5" *ngIf="selectedType === 'salestarget'">
          <!-- <div class="flex items-center justify-between mb-3 mt-3">
              <h1 class="text-xl Poppins-Bold">{{'Actual Sales And Target' | translate}}</h1>
            </div> -->
          <div class="flex justify-center flex-col relative">
            <div class="flex gap-8 w-full">
              <div class="flex items-center gap-1 m-8">
                <div
                  class="w-2 h-2 rounded-full"
                  style="background-color: rgb(12, 156, 213)"
                ></div>
                <div class="text-xs Poppins-Medium">
                  {{ "Actural >= 100% of Target" }}
                </div>
              </div>
              <div class="flex items-center gap-1">
                <div
                  class="w-2 h-2 rounded-full"
                  style="background-color: rgb(255, 234, 0)"
                ></div>
                <div class="text-xs Poppins-Medium">
                  {{ "Actural < 100% and >=90% of Target" }}
                </div>
              </div>
              <div class="flex items-center gap-1">
                <div
                  class="w-2 h-2 rounded-full"
                  style="background-color: rgb(206, 19, 19)"
                ></div>
                <div class="text-xs Poppins-Medium">
                  {{ "Actual <90% of Target" }}
                </div>
              </div>
              <div class="flex items-center gap-1">
                <div
                  class="w-2 h-2 rounded-full"
                  style="background-color: rgb(220, 220, 220)"
                ></div>
                <div class="text-xs Poppins-Medium">
                  {{ "Target" }}
                </div>
              </div>
            </div>
            <div
              class="bg-white p-5 mb-3 rounded-lg"
              style="display: block; width: 997px; height: 565px"
            >
              <canvas
                baseChart
                [datasets]="salesAndTargetData"
                [labels]="salesAndTargetLabels"
                [options]="salesAndTargetOptions"
                [legend]="salesAndTargetLegend"
                [chartType]="salesAndTargetType"
              >
              </canvas>
            </div>
          </div>
        </div>
        <!-- chart actual sales and target ends here -->
      </div>
    </div>
    <!-- Charts ends here -->
  </div>
</div>
