<div class="flex gap-4 p-4 pb-0">
  <div class="new-design-round-border p-4 text-sm flex-grow w-full">
    <form [formGroup]="storeForm" novalidate class="flex flex-col gap-4">
      <div class="flex flex-col gap-2">
        <label for="storeName">{{ "Store Name" | translate }}</label>
        <input
          id="storeName"
          formControlName="storeName"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Store Name' | translate }}"
        />

        <label *ngIf="formErrors.storeName" class="text-red-400 ms-2">{{
          formErrors.storeName
        }}</label>
      </div>
      <div class="flex flex-col gap-2">
        <label for="addressLine1">{{
          "Enter Address Line 1" | translate
        }}</label>
        <input
          id="addressLine1"
          formControlName="addressLineFirst"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Address' | translate }}"
        />
        <div *ngIf="formErrors.addressLineFirst" class="text-red-400 ms-2">
          {{ formErrors.addressLineFirst }}
        </div>
      </div>
      <div class="flex flex-col gap-2">
        <label for="addressLine2">{{
          "Enter Address Line 2" | translate
        }}</label>
        <input
          id="addressLine2"
          formControlName="addressLineSecond"
          type="text"
          class="new-input"
        />
      </div>
      <div class="flex flex-col gap-2">
        <label for="city">{{ "Enter City" | translate }}</label>
        <input
          id="city"
          formControlName="city"
          type="text"
          class="new-input"
          placeholder="{{ 'Enter Address' | translate }}"
        />
        <div *ngIf="formErrors.city" class="text-red-400 ms-2">
          {{ formErrors.city }}
        </div>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="country" class="">{{ "Country" | translate }}</label>
        <ng-select
          id="country-select"
          [items]="countrySelect"
          class="p-0 w-full new-dropdown  text-sm focus:ring-0"
          bindLabel="text"
          bindValue="text"
          (change)="changeEventContry($event)"
          formControlName="country"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="formErrors.country" class="text-red-400 ms-2">
          {{ formErrors.country }}
        </div>
      </div>
      <div class="flex flex-col gap-2 w-1/2">
        <label for="state" class="">{{ "State" | translate }}</label>
        <ng-select
          id="state"
          [items]="states"
          class="p-0 w-full focus:ring-0"
          bindLabel="text"
          bindValue="text"
          formControlName="state"
          placeholder="{{ 'Select one' | translate }}"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item.text }}
              </div>
            </div>
          </ng-template>
        </ng-select>
        <div *ngIf="formErrors.state" class="text-red-400 ms-2">
          {{ formErrors.state }}
        </div>
      </div>
    </form>
  </div>
  <div class="flex w-[45%] justify-around">
    <app-submit
      [value]="update ? 'Update' : 'Create'"
      className="bg-[#026AA2] text-white w-28 h-10"
      (clicked)="update ? updateStore() : createStore()"
    ></app-submit>
    <app-submit
      value="Discard"
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w-28 h-10"
      (clicked)="close.emit()"
    ></app-submit>
  </div>
</div>
