import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { CustomerService } from '../../customer.service';

@Component({
  selector: 'app-view-customer',
  templateUrl: './view-customer.component.html',
  styleUrls: ['./view-customer.component.scss']
})
export class ViewCustomerComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private router: Router,
              private route: ActivatedRoute,
              private customerService: CustomerService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private salesService: SalesService,
              private numberService: NumberService,
              private spinner: NgxSpinnerService) {
              this.business$ = this.store.pipe(select(selectBusiness));
              }
business$: Observable<any>;
unsubscribe$ = new Subject();
businessId = null;
viewCustomerDetail = null;
customerStats;
balance:number = 0

number = '1.2-2';
currencyDetails = {
  currency: '',
  currencySymbol: ''
};

tableData = [];
tableKeys = [
  'invoiceNumber',
  'invoiceDate',
  'dueDate',
  'totalAmount'
];
tableHeadings = [
  'Invoice Number',
  'Invoice Date',
  'Due Date',
  'Total Amount',
  'Action'
];

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.getBusinessId();
    this.route.queryParams.subscribe(({id}) => {
      if (id) {
        this.businessId = id;
        this.spinner.show();
        this.loadCustomer(id);
        this.customerService.getCustomer(id).subscribe((resp) => {
          this.spinner.hide();
          this.getCustomerStats(id);
          this.viewCustomerDetail  = resp.data;
          this.balance = resp.balance
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(error);
        });
      }
    });
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  getCustomerStats(id): void {
    this.customerService.getCustomerStats(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.customerStats = resp?.data;
      }
    })
  }

  getBusinessId(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      this.businessId = business.businessId._id;
    });
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail.subscribe(details=>{
      if(details)
      this.currencyDetails = details;
    })
  }

  loadCustomer(id): void {
    this.customerService.getCustomerDetails(id).subscribe((resp) => {
      this.tableData = resp.data.invoices.map(invoice => ({
        ...invoice,
        customerName: invoice?.customerDetails?.customerId?.customerName,
        invoiceDate: moment(invoice?.invoiceDate.split('T')[0]).format('DD-MM-YYYY'),
        dueDate: moment(invoice?.dueDate.split('T')[0]).format('DD-MM-YYYY'),
        totalAmount: this.numberService.currencier(invoice?.totalAmount),
        tax: this.numberService.currencier(invoice?.tax),
        subtotal: this.numberService.currencier(invoice?.subtotal),
        dueAmount: this.numberService.currencier(invoice?.dueAmount),
        discountValue: this.numberService.currencier(invoice?.discountValue),
        discount: this.numberService.currencier(invoice?.discount)
      }));
      this.spinner.hide();
    },(error)=>{
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  editCustomer(id): void{
    this.router.navigate(['/sales/customers/create-customer'], { queryParams: { id } });
  }

  viewInvoice(invoice): void {
    this.router.navigate([ invoice?.isCashInvoice ? '/sales/cash-invoice/view-cash-invoice' : '/sales/invoices/view-invoice'], { queryParams: { id:invoice?._id } });
  }
}
