<div class="w-full">
  <form [formGroup]="registerHourForm" [attr.id]="formId">
    <div class="grid grid-cols-7 gap-4">
      <div class="space-y-2 col-span-1">
        <div class="space-y-2">
          <label
            for="from"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "From" | translate }}
          </label>
          <input
            tabindex="1"
            id="from"
            formControlName="by"
            type="time"
            [min]="min"
            [max]="max"
            class="new-date"
            [ngClass]="{ 'border-red-600': formErrors?.by }"
            placeholder="00:00"
          />
        </div>
        <div *ngIf="formErrors?.by" class="text-red-600 text-sm">
          {{ formErrors?.by }}
        </div>
      </div>
      <div class="space-y-2 col-span-1">
        <div class="space-y-2">
          <label
            for="until"
            class="block text-sm Poppins-Medium font-color-05"
            >{{ "Until" | translate }}</label
          >
          <input
            tabindex="2"
            id="until"
            formControlName="until"
            type="time"
            class="new-date"
            [ngClass]="{ 'border-red-600': formErrors?.until }"
            placeholder="00:00"
          />
        </div>
        <div *ngIf="formErrors?.until" class="text-red-600 text-sm">
          {{ formErrors?.until }}
        </div>
      </div>
      <div class="space-y-2 col-span-1">
        <div class="space-y-2">
          <label
            for="Pause"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "Break duration" | translate }}
          </label>
          <input
            tabindex="3"
            id="Pause"
            formControlName="paused"
            type="number"
            min="0"
            class="new-input"
            [ngClass]="{ 'border-red-600': formErrors?.paused }"
            placeholder="00:00"
          />
          <!-- <input type="time" name="time" placeholder="hrs:mins" pattern="^([0-1]?[0-9]|2[0-4]):([0-5][0-9])(:[0-5][0-9])?$" class="inputs time" required> -->
        </div>
        <div *ngIf="formErrors?.paused" class="text-red-600 text-sm">
          {{ formErrors?.paused }}
        </div>
      </div>
      <div class="space-y-2 col-span-4">
        <div class="space-y-2">
          <label
            for="desc"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "Description" | translate }}
          </label>
          <input
            tabindex="4"
            id="desc"
            formControlName="description"
            type="text"
            class="new-input"
            [ngClass]="{ 'border-red-600': formErrors?.description }"
            placeholder="{{ 'Enter description' | translate }}"
          />
        </div>
        <div *ngIf="formErrors?.description" class="text-red-600 text-sm">
          {{ formErrors?.description }}
        </div>
      </div>
      <div class="space-y-2 col-span-2">
        <div class="space-y-2">
          <label
            for="contact"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "Customer" | translate }}
          </label>
          <ng-select
            tabindex="5"
            [items]="customers"
            class="new-dropdown"
            [ngClass]="{ 'border-red-600': formErrors?.customerId }"
            bindLabel="customerName"
            bindValue="_id"
            formControlName="customerId"
            placeholder="{{ 'Select One' | translate }}"
            groupBy="section"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.customerName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="space-y-2 col-span-2">
        <div class="space-y-2">
          <label
            for="project"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "Project" | translate }}
          </label>
          <ng-select
            tabindex="6"
            [items]="projects"
            class="new-dropdown"
            [ngClass]="{ 'border-red-600': formErrors?.customerId }"
            bindLabel="projectName"
            bindValue="_id"
            formControlName="projectId"
            placeholder="{{ 'Select One' | translate }}"
            groupBy="section"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.projectName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="space-y-2 col-span-2">
        <div class="space-y-2">
          <label
            for="task"
            class="block text-sm Poppins-Medium font-color-05"
          >
            {{ "Task" | translate }}
          </label>
          <ng-select
            tabindex="7"
            [items]="tasks"
            class="new-dropdown"
            [ngClass]="{ 'border-red-600': formErrors?.customerId }"
            bindLabel="taskTitle"
            bindValue="_id"
            formControlName="taskId"
            placeholder="{{ 'Select One' | translate }}"
            groupBy="section"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.taskTitle }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </div>
      </div>
      <div class="space-y-2 col-span-1 flex justify-end items-end">
        <app-button
          tabindex="8"
          buttonText="{{ (isTimer ? 'Start Timer' : 'Save') | translate }}"
          type="primary"
          [formId]="formId"
          [disabled]="registerHourForm.disabled"
        ></app-button>
      </div>
    </div>
    <div *ngIf="formErrors?.customerId" class="text-red-600 text-sm">
      {{ formErrors?.customerId }}
    </div>
  </form>
</div>
