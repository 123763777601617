<div class="p-7 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Poppins-Bold text-xl mb-4">{{'SMS settings' | translate }}</h3>
    <p class="Poppins-SemiBold mb-4">{{'Send SMS' | translate }}</p>
    <label class="inline-flex items-center mb-4">
        <input [formControl]='sendSMS' type="checkbox" class="me-2">
        <span class="block Poppins-SemiBold">{{'Send SMS with invoice link when creating cash-invoice.' | translate}}</span>
    </label>
    <!-- <p class="mb-4">{{'You can edit the reminders on individual invoices anytime.' | translate }}</p> -->
    <div class="flex items-center gap-x-2">
        <app-button buttonText='{{"Save changes" | translate }}' type='primary' rounded='true' (handleClick)='saveChanges()'></app-button>
        <span *ngIf='response' class="text-green-500">{{response}}</span>
        <span *ngIf='error' class="text-red-500">{{error}}</span>
    </div>
</div>