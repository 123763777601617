import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateRecurringJvComponent } from './components/create-recurring-jv/create-recurring-jv.component';
import { ListRecurringJvComponent } from './components/list-recurring-jv/list-recurring-jv.component';
import { ViewRecurringJvComponent } from './components/view-recurring-jv/view-recurring-jv.component';

const routes: Routes = [
  {
    path: '',
    component: ListRecurringJvComponent
  },
  {
    path: 'create-recurring-jv',
    component: CreateRecurringJvComponent
  },
  {
    path: 'view-recurring-jv',
    component: ViewRecurringJvComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecurringJvRoutingModule { }
