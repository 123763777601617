import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { NumplusService } from '../../../numplus.service';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { takeUntil } from 'rxjs/operators';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ThemeService } from 'ng2-charts';
import { BudgetService } from '../budget.service';
import * as moment from 'moment';

@Component({
  selector: 'app-create-budget',
  templateUrl: './create-budget.component.html',
  styleUrls: ['./create-budget.component.scss']
})
export class CreateBudgetComponent implements OnInit {

  financialPeriod:Array<any> = [
   {
      name:moment().format('YYYY'),
      value: moment().format('YYYY')
    },
    {
      name:(Number(moment().format('YYYY')) + 1).toString(),
      value:(Number(moment().format('YYYY')) +1).toString()
    },
    {
      name:(Number(moment().format('YYYY')) + 2).toString(),
      value:(Number(moment().format('YYYY')) +2).toString()
    }
  ]

  selectedRowDetais;

  interval:Array<string> =['Monthly', 'Yearly', 'Quarterly']

   public tableIncomeCol: Array<string> = ["INCOME","APR", "MAY", "JUN", "JUL",
  "AUG", "SEP", "OCT", "NOV", "DEC",
  "JAN", "FEB", "MAR", "TOTALS"];

  public tableExpensesCol: Array<string> = ["EXPENSES","APR", "MAY", "JUN", "JUL",
  "AUG", "SEP", "OCT", "NOV", "DEC",
  "JAN", "FEB", "MAR", "TOTALS"];

  public monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
                       'September', 'October', 'November', 'December']

  public newMonthsName:Array<string> = []

  tableData: Array<any> = []

  budgetCreation: Array<any> = []

  budgetSingleRow: Array<any> = [
    {
      "month": "",
      "year": "",
      "amount": null
  },
  {
      "month": "",
      "year": "",
      "amount": null
  },
  {
      "month": "",
      "year": "",
      "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "Total": null
  }
]
  incomeBudget:Array<any> = []

  expensesBudget:Array<any> = []


  view:boolean = true;
  editData:boolean = false

  firstForm: FormGroup;
  secondForm:FormGroup;
  yearBudget:FormGroup;

  firstFormError = {
    budgetName:'',
    period:'',
  }

  firstFormErrorMessage= {
    budgetName:{
      required:'Name is required'
    },
    period:{
      required:'Financial Period is required'
    },
  }

  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };

  accounts$:Observable<any>;
  business$: Observable<any>;
  businessId = null;
  fiscalMonth =null
  unsubscribe$ = new Subject();
  accountTypesBySection;
  accountData;
  incomeData;
  createBudgetModal = false;
  accountText = ""
  budget:Array<any> = []
  expensesData;
  netotalIncome:Array<number> = []
  netotalExpense:Array<number> = []
  update:boolean = false
  selectedRowForUpdate;
  ycod;
  viewRecord = null
  tablemargin
  newPeriod = [ ]

  @ViewChild('createModal', { static: false }) divCreateModal: ElementRef;

  constructor(
    private activateRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private rootStore:Store<RootReducerState>,
    private numplusService: NumplusService,
    private fb:FormBuilder,
    private budgetService: BudgetService,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2) {
     this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
  }

  ngOnInit(): void {
    this.loadSecondForm()
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
      }
      this.loadBudgetList()
      this.getCurrencyDetails()
      this.viewRecord = this.activateRoute.snapshot.params['id']
      console.log("view Record", this.viewRecord)
      if(this.viewRecord !== undefined && this.businessId !== null ){
        this.spinner.show()
        this.loadAccount()

        this.updateFormatAccToFiscalMonth(this.monthsName, this.fiscalMonth)
        this.loadYearBudget()
        this.retrieveData(this.viewRecord)
        this.view = false
        this.editData = true
        this.spinner.hide()
      }
    })
    if(this.viewRecord === undefined){
      this.loadAccount()
      this.loadFirstForm()
      // this.loadSecondForm()
      this.updateFormatAccToFiscalMonth(this.monthsName, this.fiscalMonth)
      this.loadYearBudget()
    }
  }

  loadAccount(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.numplusService.getAllAccounts(business.businessId._id).subscribe(resp => {
          this.spinner.hide();
          this.accountData = resp.data;
          this.incomeData = this.accountData.filter((data) =>{
             return data.accountSection.toLowerCase() === 'income';
          })
          console.log(this.incomeData)
          this.expensesData = this.accountData.filter((data) =>{
            return data.accountSection.toLowerCase() === 'expenses'
         })
        }
        , (error) => {
          this.spinner.hide();
          console.log(error);
        });
      }
    });
  }

  loadBudgetList():void{
    this.numplusService.businessId$.subscribe((id) =>{
      if(id){
        this.spinner.show();
        this.budgetService.getBudgetList().subscribe(resp =>{
          this.spinner.hide();
          let listdata = resp.data
          this.newPeriod = this.financialPeriod.filter(el => { return !listdata.find(element => {return element._id === el.value;});});
          console.log(this.newPeriod)
        }, (error)=>{
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        })
      }
    })
  }

  getCurrencyDetails(): void {
    this.numplusService.currencyDetail.subscribe(details => {
      if (details) {
      this.currencyDetails = details;
      }
    });
  }

  updateFormatAccToFiscalMonth(monthsData:any, fiscalMonth:string){
    let newMonths = this.createMonthsAccToFiscalMonth(monthsData,fiscalMonth)
    this.newMonthsName.push(...newMonths)
    this.tableIncomeCol.splice(1,this.tableIncomeCol.length-2)
    this.tableExpensesCol.splice(1,this.tableExpensesCol.length-2)
    this.tableIncomeCol.splice(1,0, ...newMonths)
    this.tableExpensesCol.splice(1,0,...newMonths)
  }


  createMonthsAccToFiscalMonth(monthsData:any, fiscalMonth:string){
    const index = monthsData.findIndex((month) => month === fiscalMonth)
    const firstHalf = monthsData.slice().splice(0,index)
    const secondHalf = monthsData.slice().splice(index,monthsData.length)
    return [...secondHalf,...firstHalf]
  }

  loadFirstForm():void{
    this.firstForm = this.fb.group({
      budgetName:["", [Validators.required]],
      period:["", [Validators.required]]
    });
    this.firstForm.valueChanges.subscribe(() =>{
      this.firstFormError = valueChanges(this.firstForm, {...this.firstFormError}, this.firstFormErrorMessage, this.translateService);
    });
    this.firstFormError = valueChanges(this.firstForm, {...this.firstFormError}, this.firstFormErrorMessage, this.translateService)
  }

  loadSecondForm():void{
    this.secondForm= this.fb.group({
      name:[],
      year:[]
    })
  }

  loadYearBudget():void{
    let form = {}
    for(let i=0; i< this.newMonthsName.length; i++){
      form[this.newMonthsName[i]] = new FormControl(0)
    }
    this.yearBudget = new FormGroup(form)
  }

  getNameAndYearOfBudget(){
    console.log(this.firstForm.value)
    if (this.firstForm.invalid) {
      this.firstForm.markAllAsTouched();
      this.firstFormError = valueChanges(this.firstForm, {...this.firstFormError}, this.firstFormErrorMessage, this.translateService);
      return;
    }
    this.spinner.show()
    this.secondForm.get('name').setValue(this.firstForm.value.budgetName)
    this.secondForm.get('year').setValue(this.firstForm.value.period)
    this.budgetCreation.push(
      {
        name:this.firstForm.value.budgetName,
        period: this.firstForm.value.period
      }
    )
    this.retrieveData(this.firstForm.value.period)
    this.view = false
    this.editData = true
    this.spinner.hide()
    console.log(this.budgetCreation)
  }

  onEdit(accData:any, cod){
    this.tablemargin = `margin-top:-28px`
    this.selectedRowDetais = accData
    console.log("Selected Row Data", this.selectedRowDetais)
    this.yearBudget.reset()
    this.setDataInForm(accData)
    this.openCreateBudget(accData?.accountName, cod)
  }

  setDataInForm(accData){
    if(accData.accountSection === 'Income' && this.incomeBudget.length !==0){
      let index = this.incomeBudget.filter((data) => {
        return accData.accountName === data.accountName
      })
      this.update =false
      if(index.length !== 0){
        this.update =true
        this.selectedRowForUpdate = index[0]
        for(let i=0; i< this.newMonthsName.length; i++){
            this.yearBudget.get(this.newMonthsName[i]).setValue(index[0].budget[i].amount)
        }

      }
    }else if(accData.accountSection === 'Expenses' && this.expensesBudget.length !==0){
      let index = this.expensesBudget.filter((data) => {
        return accData.accountName === data.accountName
      })
      this.update =false
      if(index.length !== 0){
        this.update =true
        this.selectedRowForUpdate = index[0]
        for(let i=0; i < this.newMonthsName.length; i++){
            this.yearBudget.get(this.newMonthsName[i]).setValue(index[0]?.budget[i]?.amount)
        }
      }
    }
    console.log(this.yearBudget.value)
  }

  openCreateBudget(accName:string, cod): void {
    this.getPosition(cod)
    this.createBudgetModal = true;
    this.accountText = accName
  }

  closeCreateBudget():void{
    this.tablemargin = `margin-top:10px`
    this.createBudgetModal = false;
    this.update = false
    this.accountText = ""
    this.yearBudget.reset()
  }

  createBudget(body){
    // if(this.incomeBudget.length === 0 && this.expensesBudget.length === 0){
    //   this.spinner.hide()
    //   this.toastr.error(this.translateService.instant('Please Add Data to rows according as required to create Budget'));
    //   return
    // }
    // let body ={
    //   "name": this.firstForm.get('budgetName').value,
    //   "period":this.firstForm.get('period').value,
    //   "budget":[
    //     ...this.incomeBudget,
    //     ...this.expensesBudget
    //   ]
    // }
    // this.spinner.show()
    // this.budgetService.createBudget(body).subscribe(
    //   (resp) =>{
    //     this.spinner.hide()
    //     this.router.navigate(['/plus/budget'])
    //   },
    //   (error) => {
    //     this.spinner.hide();
    //     this.toastr.error(this.translateService.instant('Something went wrong!'));
    //   }
    // )


  }

  counter(i:number){
    return new Array(i);
  }

  addBudget():void{
    this.tablemargin = `margin-top:10px`
    let year
    let name
  if(this.viewRecord !== undefined){
    year = this.viewRecord
    name = this.secondForm.get('name').value
  } else {
    year = this.firstForm.value.period
    name = this.firstForm.get('budgetName').value
  }
  let body = {
      "name": name,
      "businessId": this.businessId,
      "userId": this.selectedRowDetais.userId,
      "accountType": this.selectedRowDetais.accountType,
      "accountSection": this.selectedRowDetais.accountSection,
      "accountName": this.selectedRowDetais.accountName,
      "year": year,
      "accountId": this.selectedRowDetais._id,
      "budget": []
    }

    for(let i=0; i<this.newMonthsName.length; i++){
      body.budget.push({
        "month": this.newMonthsName[i],
        "year":year,
        "amount":this.yearBudget.get(this.newMonthsName[i]).value
      })
    }
    body.budget.push({
      "month":"Total",
      "amount": this.totalAmount(this.yearBudget.value)
    })
    console.log(this.yearBudget.value)
    console.log("Body", body)
    this.spinner.show()
    this.budgetService.createBudget(body).subscribe(
      (resp) =>{
        if(resp.status === 200){
          this.toastr.success(this.translateService.instant('Row Added'));
          this.retrieveData(year)
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    )
    console.log("Income", this.incomeBudget)
    console.log("Expenses", this.expensesBudget)
    this.createBudgetModal = false;
    this.accountText = ""
    this.yearBudget.reset()
  }

  updateBudget(){
    this.tablemargin = `margin-top:10px`
    let {accountId, accountName, accountSection, accountType, businessId, year, _id, userId} = this.selectedRowForUpdate
    let body = {
      "_id": _id,
      "name": this.secondForm.get('name').value,
      "businessId": businessId,
      "userId": userId,
      "accountType": accountType,
      "accountSection": accountSection,
      "accountName": accountName,
      "year": year,
      "accountId": accountId,
      "fiscalMonth": this.fiscalMonth,
      "budget": []
    }

    for(let i=0; i<this.newMonthsName.length; i++){
      body.budget.push({
        "month": this.newMonthsName[i],
        "year":year,
        "amount":this.yearBudget.get(this.newMonthsName[i]).value
      })
    }
    body.budget.push({
      "month":"Total",
      "amount": this.totalAmount(this.yearBudget.value)
    })
    console.log("Body", body)
    this.spinner.show()
    this.budgetService.updateBudget(body).subscribe(
      (resp) =>{
        if(resp.status === 200){
          this.toastr.success(this.translateService.instant('Row Update'));
          this.retrieveData(year)
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    )
    console.log("Income", this.incomeBudget)
    console.log("Expenses", this.expensesBudget)
    this.createBudgetModal = false;
    this.update = false
    this.accountText = ""
    this.yearBudget.reset()
  }

  retrieveData(year){
    let retrieveBody = {
      "year":year
    }
    this.budgetService.getBusinessBudget(retrieveBody).subscribe(
      (resp) =>{
        if(resp.status === 200){
          let retriveData = resp.data
          console.table(retriveData)
          console.log("Retrieve Data", retriveData)
          this.secondForm.get('name').setValue(retriveData[0].name)
          this.secondForm.get('year').setValue(retriveData[0].year)
          this.incomeBudget = retriveData.filter((data) =>{
            return data.accountSection.toLowerCase() === 'income';
         })
         this.netTotalFor("Income")
         this.expensesBudget = retriveData.filter((data) =>{
           return data.accountSection.toLowerCase() === 'expenses'
        })
        this.netTotalFor("Expenses")
        this.spinner.hide()
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    )
  }

  getDataArray(incomeName:any){
    for(let i=0; i < this.incomeBudget.length; i++){
      if(this.incomeBudget[i].accountName === incomeName){
        return  this.incomeBudget[i].budget
      }
    }
    return this.budgetSingleRow
  }

  getDataArray2(expenseName:any){
    for(let i=0; i < this.expensesBudget.length; i++){
      if(this.expensesBudget[i].accountName === expenseName){
        return  this.expensesBudget[i].budget
      }
    }
    return this.budgetSingleRow
  }

  totalAmount(arr){
    let arry = Object.values(arr)
    console.log(arry)
    const total = arry.reduce((previousValue:any, currentValue:any) => previousValue + currentValue,0);
    return total
  }

  netTotalFor(netTotalOf){
    let total =0
    if(netTotalOf === 'Income'){
      this.netotalIncome.splice(0,this.netotalIncome.length)
      for( let j=0; j<13;j++){
        for(let i=0; i < this.incomeBudget.length; i++){
           total += this.incomeBudget[i]?.budget[j]?.amount ? this.incomeBudget[i]?.budget[j]?.amount : 0
        }
      this.netotalIncome.push(total)
      total = 0
      }
    } else if(netTotalOf === 'Expenses'){
      this.netotalExpense.splice(0,this.netotalExpense.length)
      for( let j=0; j<13;j++){
        for(let i=0; i < this.expensesBudget.length; i++){
           total += this.expensesBudget[i]?.budget[j]?.amount ?  this.expensesBudget[i]?.budget[j]?.amount : 0
        }
      this.netotalExpense.push(total)
      total = 0
    }
  }
}

getPosition(ypos){
  this.ycod =`top: ${ypos.screenY-97}px`

  console.log("Y coordinate",this.ycod)
  // this.renderer.setStyle(this.divCreateModal.nativeElement,'top', 'ycod' + 'px');
}

ngAfterViewInit(){
  this.elementRef.nativeElement.focus();
}





}
