const generateDays = (days: number): number[] => {
    return Array.from({ length: days }, (_, i) => i + 1);
};

const generateMonthsAndDates = (): { [key: string]: number[] } => {
    const monthsAndDays: { [key: string]: number } = {
        January: 31,
        February: 28,
        March: 31,
        April: 30,
        May: 31,
        June: 30,
        July: 31,
        August: 31,
        September: 30,
        October: 31,
        November: 30,
        December: 31
    };

    const MonthsAndDates: { [key: string]: number[] } = {};

    for (const month in monthsAndDays) {
        MonthsAndDates[month] = generateDays(monthsAndDays[month]);
    }

    return MonthsAndDates;
};

const MonthsAndDates = generateMonthsAndDates();

export const getAllMonths = () => {
    return Object.keys(MonthsAndDates).map(m => ({text: m}));
};

export const getDates = (monthName) => {
    return MonthsAndDates[monthName].map((d => ({text: d})));
};


