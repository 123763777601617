<div class="flex justify-center p-8 min-h-screen">
  <div class="w-full flex flex-col">
    <div class="relative w-max">
      <div class="flex gap-x-2">
        <div class="sticky left-0">
          <form [formGroup]="secondForm">
            <div class="flex gap-4">
              <div class="">
                <label class="Poppins-Medium text-base">{{
                  "Name" | translate
                }}</label>
                <input
                  name="name"
                  type="text"
                  formControlName="name"
                  class="new-input"
                  disabled
                />
              </div>
              <div class="">
                <label class="Poppins-Medium text-base">{{
                  "Year" | translate
                }}</label>
                <input
                  name="name"
                  type="text"
                  formControlName="year"
                  class="new-input"
                  disabled
                />
              </div>
            </div>
          </form>
        </div>
      </div>

      <div class="flex my-4">
        <div class="">
          <div class="">
            <div class="max-w-[90rem] overflow-x-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-input-light z-30 left-0"
                      *ngFor="let tabcol of tableIncomeCol"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody class="">
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let income of incomeData; index as incomeIndex"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ income?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-center border border-[#1C1C1C11]"
                      *ngFor="let data of getDataArray(income?.accountName)"
                    >
                      {{
                        data?.amount
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let data of netotalIncome"
                    >
                      {{
                        data
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <div class="max-w-[90rem] overflow-y-scroll">
              <table class="my-4">
                <thead class="h-12 text-[#1C1C1C66]">
                  <tr class="text-center">
                    <td
                      class="px-4 py-2 w-48 sticky border-[#1C1C1C33] border-b top-0 bg-input-light z-30 left-0"
                      *ngFor="let tabcol of tableExpensesCol"
                    >
                      {{ tabcol }}
                    </td>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    class="h-12 mb-4 py-4 px-6"
                    *ngFor="let expense of expensesData; index as exp"
                  >
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      {{ expense?.accountName }}
                    </td>
                    <td
                      class="px-4 py-2 text-center border border-[#1C1C1C11]"
                      *ngFor="let data of getDataArray2(expense?.accountName)"
                    >
                      {{
                        data?.amount
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                  <tr class="px-4 py-2 text-center">
                    <td
                      class="Poppins-Medium text-base text-center text-[#1c1c1ccc] px-4 py-2 sticky left-0 min-w-[16rem] w-64 bg-input-light"
                    >
                      >
                      {{ "Total Net" }}
                    </td>
                    <td
                      class="px-4 py-2 text-right"
                      *ngFor="let data of netotalExpense"
                    >
                      {{
                        data
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
