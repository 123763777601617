import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ImageCroppedEvent } from 'ngx-image-cropper';

@Component({
  selector: 'app-image-cropper',
  templateUrl: './image-cropper.component.html',
  styleUrls: ['./image-cropper.component.scss']
})
export class ImageCropperComponent implements OnInit {

  constructor() { }

  @Input() isOpen = false;
  @Output() closeImage = new EventEmitter();
  @Output() base64Image = new EventEmitter<string>();
  @Input() imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload Image'
    }
  };
  previewImage = false;
  croppedImage = '';
  ngOnInit(): void {
  }
  imageCropped(event: ImageCroppedEvent): void {
    this.croppedImage = event.base64;
  }
  imageLoaded(): void{
    console.log('imageLoaded');
  }
  saveImage(): void {
    this.previewImage = false;
    this.base64Image.emit(this.croppedImage);
  }
  close(): void {
    this.closeImage.emit();
  }
}
