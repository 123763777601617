<form [formGroup]="discountCodeForm" class="">
  <div class="flex gap-4 p-4 new-design-round-border">
    <div class="flex flex-col w-1/2 gap-4">
      <div class="w-full flex flex-col gap-2">
        <label for="discountCode" class="block text-sm">{{
          "Discount Code" | translate
        }}</label>
        <input
          id="discountCode"
          formControlName="code"
          placeholder="{{ 'Discount Code' | translate }}"
          type="text"
          class="new-input"
        />
        <p *ngIf="formErrors.code" class="text-red-500 text-sm">
          {{ formErrors.code }}
        </p>
      </div>

      <div class="w-full flex flex-col gap-2">
        <label class="block text-sm">{{ "Discount Type" | translate }}</label>
        <div class="flex space-x-2 items-center">
          <input
            type="radio"
            formControlName="discountType"
            value="Percentage"
            name="discountType"
          />
          <p class="">{{ "Percentage" | translate }}</p>
        </div>
        <p *ngIf="formErrors.discountType" class="text-red-500 text-sm">
          {{ formErrors.discountType }}
        </p>
      </div>

      <div class="w-full flex flex-col gap-2">
        <label for="discountValue" class="block text-sm">{{
          "Discount Value" | translate
        }}</label>
        <input
          id="discountValue"
          formControlName="discountValue"
          placeholder="{{ 'Discount Value' | translate }}"
          type="number"
          class="new-input"
        />
        <p *ngIf="formErrors.discountValue" class="text-red-500 text-sm">
          {{ formErrors.discountValue }}
        </p>
      </div>
      <div class="w-full flex flex-col gap-2">
        <label for="expiryDate" class="block text-sm">{{
          "Expiry Date" | translate
        }}</label>
        <input
          id="expiryDate"
          formControlName="expiryDate"
          placeholder="{{ 'Expiry Date' | translate }}"
          type="date"
          class="new-input"
        />
        <p *ngIf="formErrors.expiryDate" class="text-red-500 text-sm">
          {{ formErrors.expiryDate }}
        </p>
      </div>
      <div class="w-full flex flex-col gap-2">
        <label for="description" class="block text-sm">{{
          "Description" | translate
        }}</label>
        <textarea
          id="description"
          formControlName="description"
          placeholder="{{ 'Description' | translate }}"
          rows="4"
          class="new-input"
        ></textarea>
        <p *ngIf="formErrors.description" class="text-red-500 text-sm">
          {{ formErrors.description }}
        </p>
      </div>
    </div>
    <div class="flex flex-col w-1/2 gap-4">
      <div class="w-full flex flex-col gap-2">
        <label for="limit" class="block text-sm">{{
          "Usage Limit" | translate
        }}</label>
        <input
          id="limit"
          formControlName="discountCount"
          placeholder="{{ 'Usage Limit' | translate }}"
          type="number"
          class="new-input"
        />
        <p *ngIf="formErrors.discountCount" class="text-red-500 text-sm">
          {{ formErrors.discountCount }}
        </p>
      </div>
      <div class="w-full flex flex-col gap-2">
        <label for="singleCustomer" class="block text-sm">{{
          "Single Customer Usage Limit" | translate
        }}</label>
        <input
          id="singleCustomer"
          formControlName="customerUsageCount"
          placeholder="{{ 'Single Customer Usage Limit' | translate }}"
          type="number"
          class="new-input"
        />
        <p *ngIf="formErrors.customerUsageCount" class="text-red-500 text-sm">
          {{ formErrors.customerUsageCount }}
        </p>
      </div>
      <div class="flex items-center justify-around">
        <app-submit
          value="{{ (editCode ? 'Update' : 'Create') | translate }}"
          className="bg-[#026AA2] text-white h-10 w-28"
          (clicked)="editCode ? updateDiscountCode() : createDiscountCode()"
        ></app-submit>
        <app-submit
          value="{{ 'Discard' | translate }}"
          className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10 w-28"
          (clicked)="discard.emit()"
        ></app-submit>
      </div>
    </div>
  </div>
</form>
