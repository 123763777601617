import { Component, OnInit } from '@angular/core';
import { StorageService } from '../../storage.service';
import { ActivatedRoute } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-file-view',
  templateUrl: './file-view.component.html',
  styleUrls: ['./file-view.component.scss']
})
export class FileViewComponent implements OnInit {

  fileId: string = null;
  fileData: string | Blob = '';
  fileDetails;
  fileType;
  fileURL;

  constructor(private storageService: StorageService, private route: ActivatedRoute, private spinner: NgxSpinnerService) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe(({fileId = ''}) => {
      if(fileId){
        this.fileId = fileId;
        this.viewFile(fileId)
      }
    })
  }

  viewFile(fileId: string): void {
    this.spinner.show();
    this.storageService.getFile(fileId)
    .subscribe(resp => {
      if(resp?.success){
        this.fileDetails = resp?.data?.docData
        this.fileType = this.fileDetails?.fileType?.startsWith('image') ? 'image' : 'pdf';
        const arrayBufferView = new Uint8Array(resp?.data?.fileBuffer?.data);
        this.fileData = new Blob([arrayBufferView], {type: this.fileDetails?.fileType});
        if(this.fileType === 'image'){
          const reader = new FileReader();
          reader.onloadend = () => {
            this.fileURL = reader.result as string;
          };

          reader.readAsDataURL(this.fileData);
        }
        else{
          this.fileURL = new TextDecoder('utf-8').decode(arrayBufferView.buffer)
        }
        // console.log(resp?.data?.fileBuffer, typeof resp?.data?.fileBuffer );
        // console.log(this.fileURL);
        
        this.spinner.hide()
      }
    })
  }

}
