import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSpinnerModule } from 'ngx-spinner';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateCashInvoiceComponent } from './create-cash-invoice/create-cash-invoice.component';
import { ListCashInvoiceComponent } from './list-cash-invoice/list-cash-invoice.component';
import { ViewCashInvoiceComponent } from './view-cash-invoice/view-cash-invoice.component';

const routes: Routes = [
  {
    path: '',
    component: ListCashInvoiceComponent,
  },
  {
    path: 'create-cash-invoice',
    component: CreateCashInvoiceComponent,
  },
  {
    path: 'view-cash-invoice',
    component: ViewCashInvoiceComponent,
  },
];

@NgModule({
  declarations: [
    ListCashInvoiceComponent,
    CreateCashInvoiceComponent,
    ViewCashInvoiceComponent,
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    SharedModule,
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule,
    NgxPaginationModule,
    RouterModule.forChild(routes),
  ],
})
export class CashInvoiceModule {}
