import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';

@Component({
  selector: 'app-template-five',
  templateUrl: './template-five.component.html',
  styleUrls: ['./template-five.component.scss']
})
export class TemplateFiveComponent implements OnInit {
    @Output() paymentClick = new EventEmitter();

  constructor(private numberService: NumberService) { }

  @Input() invoiceData;
  @Input() businessData;

  unsubscribe = new Subject();

  number = '1.2-2';

  ngOnInit(): void {
    this.loadNumberConfig();
    console.log("invoiceData", this.invoiceData);
    
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  ngOnDestroy(): void {
      this.unsubscribe.next();
      this.unsubscribe.complete();
  }

  paymentLink(): void {
    console.log("paymentClick1");
    
    this.paymentClick.emit(true);
  }

}