<div class="h-screen w-full bg-white relative flex flex-col justify-around" id="secondary-sidebar">
  <span
    class="border p-1 hover:shadow-lg absolute bg-white top-8 ltr:right-0 rtl:left-0 rounded-lg ltr:translate-x-4 rtl:-translate-x-4 cursor-pointer z-10"
    (click)="collapse()"
  >
    <svg
      class="h-5 w-5 transition-transform delay-200"
      [ngClass]="{
        'ltr:rotate-180 rtl:rotate-0': collapsed,
        'rtl:rotate-180 ltr:rotate-0': !collapsed
      }"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4714 4.47141C10.7318 4.21107 10.7318 3.78896 10.4714 3.52861C10.2111 3.26826 9.78897 3.26826 9.52862 3.52861L5.52862 7.52861C5.26827 7.78895 5.26827 8.21106 5.52862 8.47141L9.52862 12.4714C9.78897 12.7318 10.2111 12.7318 10.4714 12.4714C10.7318 12.2111 10.7318 11.789 10.4714 11.5286L6.94283 8.00001L10.4714 4.47141Z"
        fill="black"
      />
    </svg>
  </span>

  <div class="px-4 pt-4 ms-1">
    <!-- Logo -->
    <img
      [src]="logo"
      class="h-11 self-start justify-self-center ease-in-out ms-1"
      alt="Logo"
    />
  </div>

  <div class="px-4"><hr class="my-4 w-full" /></div>

  <div
    class="flex flex-col ps-4"
    [ngClass]="{ 'pe-2': collapsed, 'pe-4': !collapsed }"
    (clickOutside)="showBusiness = false"
  >
    <ng-container *ngIf="business$ | async as currentBusiness">
      <!-- Business tooltip -->
      <ng-container
        [ngTemplateOutlet]="tooltipOutlet"
        [ngTemplateOutletContext]="{
          $implicit: 'businessTooltip',
          label: currentBusiness?.businessId?.companyName
        }"
      ></ng-container>

      <!-- Business -->
      <div
        class="flex justify-between items-center gap-3 cursor-pointer"
        [ngClass]="{
          'pe-3 rounded-2xl ltr:rounded-l-11 rtl:rounded-r-11': showText,
          'bg-[#F0F5FF]': !collapsed && showBusiness,
          'rounded-full has-tooltip': collapsed,
          'hover:bg-[#F0F5FF]': !collapsed
        }"
        (click)="!collapsed && businessLogic()"
        id="businessButton"
        data-dropdown-toggle="business"
        data-dropdown-placement="right"
      >
        <img
          [src]="
            currentBusiness?.businessId?.businessLogo
              ? baseURL + currentBusiness?.businessId?.businessLogo + '.png'
              : defaultAvatar
          "
          onerror="this.src='assets/images/avatar.jpg'"
          alt="Profile"
          class="w-11 rounded-full"
          [ngClass]="{ 'justify-self-center': collapsed }"
          data-tooltip-target="businessTooltip"
          data-tooltip-placement="right"
          data-tooltip-trigger="hover"
        />
        <span
          *ngIf="collapsed"
          class="Poppins-SemiBold tooltip whitespace-nowrap bg-[#455A64] font-semibold text-white"
          >{{ currentBusiness?.businessId?.companyName }}</span
        >
        <ng-container *ngIf="showText">
          <span
            class="w-full flex justify-between ease-in-out transition-opacity duration-200"
            [ngClass]="{
              'opacity-0': !showText,
              'opacity-100 delay-300': showText
            }"
          >
            <span
              class="Poppins text-sm text-gray-700"
              [ngClass]="{ 'text-black font-bold-900': showBusiness }"
              >{{ currentBusiness?.businessId?.companyName }}</span
            >
            <span>
              <svg
                class="h-5 w-5 transition-all duration-200"
                [ngClass]="{ 'rotate-180': showBusiness }"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                  fill="black"
                />
              </svg>
            </span>
          </span>
        </ng-container>
      </div>
      <!-- Business dropdown -->
      <div
        id="business"
        [ngClass]="{
          'z-50 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
            collapsed
        }"
        *ngIf="businesses$ | async as businesses"
      >
        <ul
          *ngIf="collapsed"
          class="p-2 text-sm flex flex-col w-full"
          aria-labelledby="businessButton"
        >
          <li
            class="hover:bg-[#F0F5FF] flex py-2 px-3 gap-3 rounded-lg cursor-pointer"
            routerLink="/chats"
            *ngFor="let business of businesses"
            (click)="selectBusiness(business)"
          >
            <img
              [src]="
                business?.businessId?.businessLogo
                  ? baseURL + business?.businessId?.businessLogo + '.png'
                  : defaultAvatar
              "
              alt="Profile"
              onerror="this.src='assets/images/avatar.jpg'"
              class="w-6 rounded-full"
            />
            <span> {{ business?.businessId?.companyName }} </span>
          </li>
        </ul>
      </div>
      <!-- Business submenu -->
      <div
        class="transition-all ease-in-out duration-300 text-sm"
        [ngClass]="{
          'h-0 overflow-hidden opacity-0': !showBusiness,
          'h-full overflow-visible opacity-100':
            showBusiness && businesses.length < 3,
          'h-36 overflow-scroll opacity-100':
            showBusiness && businesses.length >= 3
        }"
        *ngIf="!collapsed && businesses$ | async as businesses"
      >
        <div
          class="flex gap-3 items-center ms-6 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          (click)="selectBusiness(business)"
          *ngFor="let business of businesses"
        >
          <img
            [src]="
              business?.businessId?.businessLogo
                ? baseURL + business?.businessId?.businessLogo + '.png'
                : defaultAvatar
            "
            alt="Profile"
            onerror="this.src='assets/images/avatar.jpg'"
            class="w-5 rounded-full"
          />
          <span
            class="ease-in-out transition-opacity duration-100 text-[#455A64]"
            [ngClass]="{
              'opacity-0': !showText,
              'opacity-100 delay-300': showText
            }"
          >
            {{ business?.businessId?.companyName }}
          </span>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="px-4"><hr class="mt-4 w-full" /></div>

  <div
    class="flex flex-col h-full overflow-auto text-sm ps-4 pe-6 overflow-x-hidden scrollbar-gutter-stable"
    #navbarOptions
  >
    <!-- Connect tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'connectTooltip',
        label: 'Connect'
      }"
    ></ng-container>

    <!-- Connect -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] ps-3 gap-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active:
          isActiveRoute('chats') ||
          isActiveRoute('task') ||
          isActiveRoute('files')
      }"
      (click)="collapsed && active('connect')"
      (clickOutside)="showNotifications()"
      id="connectButton"
      data-dropdown-toggle="connect"
      data-dropdown-placement="right"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="connectTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/chats'] : []"
      >
        <path
          d="M240-40q-50 0-85-35t-35-85q0-50 35-85t85-35q14 0 26 3t23 8l57-71q-28-31-39-70t-5-78l-81-27q-17 25-43 40t-58 15q-50 0-85-35T0-580q0-50 35-85t85-35q50 0 85 35t35 85v8l81 28q20-36 53.5-61t75.5-32v-87q-39-11-64.5-42.5T360-840q0-50 35-85t85-35q50 0 85 35t35 85q0 42-26 73.5T510-724v87q42 7 75.5 32t53.5 61l81-28v-8q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35q-32 0-58.5-15T739-515l-81 27q6 39-5 77.5T614-340l57 70q11-5 23-7.5t26-2.5q50 0 85 35t35 85q0 50-35 85t-85 35q-50 0-85-35t-35-85q0-20 6.5-38.5T624-232l-57-71q-41 23-87.5 23T392-303l-56 71q11 15 17.5 33.5T360-160q0 50-35 85t-85 35ZM120-540q17 0 28.5-11.5T160-580q0-17-11.5-28.5T120-620q-17 0-28.5 11.5T80-580q0 17 11.5 28.5T120-540Zm120 420q17 0 28.5-11.5T280-160q0-17-11.5-28.5T240-200q-17 0-28.5 11.5T200-160q0 17 11.5 28.5T240-120Zm240-680q17 0 28.5-11.5T520-840q0-17-11.5-28.5T480-880q-17 0-28.5 11.5T440-840q0 17 11.5 28.5T480-800Zm0 440q42 0 71-29t29-71q0-42-29-71t-71-29q-42 0-71 29t-29 71q0 42 29 71t71 29Zm240 240q17 0 28.5-11.5T760-160q0-17-11.5-28.5T720-200q-17 0-28.5 11.5T680-160q0 17 11.5 28.5T720-120Zm120-420q17 0 28.5-11.5T880-580q0-17-11.5-28.5T840-620q-17 0-28.5 11.5T800-580q0 17 11.5 28.5T840-540ZM480-840ZM120-580Zm360 120Zm360-120ZM240-160Zm480 0Z"
        />
      </svg>
      <span class="relative" *ngIf="collapsed">
        <span
          *ngIf="chatUpdated > 0 || taskCountNumber > 0 || fileCountNumber > 0"
          class="absolute bg-red-600 rounded-full p-1 top-0 right-0 translate-x-1 -translate-y-6"
          [ngClass]="{ hidden: !showNotification }"
        ></span>
      </span>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between items-center ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            (click)="active('connect')"
            routerLink="/chats"
            >{{ "Connect" | translate }}</span
          >
          <span class="flex">
            <button
              (click)="toggleSubmenu('connect')"
              class="hover:bg-[#d2e1ff] p-3 rounded-lg"
            >
              <svg
                class="w-5 transition-all duration-200"
                viewBox="0 0 16 16"
                [ngClass]="{ 'rotate-180': show.connect }"
                fill="none"
              >
                <path
                  d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                  fill="black"
                />
              </svg>
            </button>
            <span class="relative">
              <span
                *ngIf="
                  chatUpdated > 0 || taskCountNumber > 0 || fileCountNumber > 0
                "
                class="bg-red-600 rounded-full p-1 absolute top-0 right-0 translate-x-1/2 -translate-y-1/2"
                [ngClass]="{ hidden: show.connect }"
              ></span>
            </span>
          </span>
        </span>
      </ng-container>
    </div>
    <!-- Connect dropdown -->
    <div
      id="connect"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="connectButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer flex items-center justify-between"
          routerLink="/chats"
          routerLinkActive="active"
          (click)="closeDropdown('connect')"
        >
          <span> {{ "Chat" | translate }} </span>
          <span
            *ngIf="chatUpdated > 0"
            class="bg-red-600 rounded-full p-1"
          ></span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1 flex items-center justify-between"
          routerLink="/task"
          routerLinkActive="active"
          (click)="closeDropdown('connect')"
        >
          <span> {{ "Task-Manager" | translate }} </span>
          <span
            *ngIf="taskCountNumber > 0"
            class="bg-red-600 rounded-full p-1"
          ></span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1 flex items-center justify-between"
          routerLink="/files"
          routerLinkActive="active"
          (click)="closeDropdown('connect')"
        >
          <span> {{ "Files" | translate }} </span>
          <span
            *ngIf="fileCountNumber > 0"
            class="bg-red-600 rounded-full p-1"
          ></span>
        </li>
      </ul>
    </div>
    <!-- Connect submenu -->
    <div
      *ngIf="!collapsed"
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.connect }"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1 flex items-center justify-between"
        routerLink="/chats"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Chat" | translate }}
        </span>
        <span
          *ngIf="chatUpdated > 0"
          class="bg-red-600 rounded-full p-1"
        ></span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1 flex items-center justify-between"
        routerLink="/task"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Task-Manager" | translate }}
        </span>
        <span
          *ngIf="taskCountNumber > 0"
          class="bg-red-600 rounded-full p-1"
        ></span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1 flex items-center justify-between"
        routerLink="/files"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Files" | translate }}
        </span>
        <span
          *ngIf="fileCountNumber > 0"
          class="bg-red-600 rounded-full p-1"
        ></span>
      </div>
    </div>

    <!-- Dashboard tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'dashboardTooltip',
        label: 'Dashboard'
      }"
    ></ng-container>
    <!-- Dashboard -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 p-3 cursor-pointer mt-1"
      [ngClass]="{
        'justify-center w-11': collapsed,
        active: isActiveRoute('dashboard')
      }"
      routerLink="/dashboard"
      routerLinkActive="active"
      appAccessControl
      moduleType="dashboard"
      [accessType]="userPermission?.dashboard"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="dashboardTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      >
        <path
          d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h240v-560H200v560Zm320 0h240v-280H520v280Zm0-360h240v-200H520v200Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="font-bold-900">{{ "Dashboard" | translate }}</span>
        </span>
      </ng-container>
    </div>

    <!-- Sales tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{ $implicit: 'salesTooltip', label: 'Sales' }"
    ></ng-container>

    <!-- Sales -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('sales')
      }"
      (click)="collapsed && active('sales')"
      id="salesButton"
      data-dropdown-toggle="sales"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="sales"
      [accessType]="userPermission?.sales"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="salesTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/sales/estimates'] : []"
      >
        <path
          d="M280-640q-33 0-56.5-23.5T200-720v-80q0-33 23.5-56.5T280-880h400q33 0 56.5 23.5T760-800v80q0 33-23.5 56.5T680-640H280Zm0-80h400v-80H280v80ZM160-80q-33 0-56.5-23.5T80-160v-40h800v40q0 33-23.5 56.5T800-80H160ZM80-240l139-313q10-22 30-34.5t43-12.5h376q23 0 43 12.5t30 34.5l139 313H80Zm260-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/sales/estimates"
            >{{ "Sales" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('sales')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.sales }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Sales dropdown -->
    <div
      id="sales"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="salesButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/sales/estimates"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Estimates" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/sales/invoices"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Regular Invoices" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/sales/recurring-invoices"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Recurring Invoices" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/sales/cash-invoice"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Cash Invoices" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/sales/customers"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Customers" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/sales/customers-statement"
          routerLinkActive="active"
          (click)="closeDropdown('sales')"
        >
          <span> {{ "Customers Statement" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Sales submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.sales }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="sales"
      [accessType]="userPermission?.sales"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/estimates"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Estimates" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/invoices"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Regular Invoices" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/recurring-invoices"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Recurring Invoices" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/cash-invoice"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Cash Invoices" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/customers"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Customers" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/sales/customers-statement"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Customers Statement" | translate }}
        </span>
      </div>
    </div>

    <!-- Purchases tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'purchaseTooltip',
        label: 'Purchase'
      }"
    ></ng-container>

    <!-- Purchases -->
    <div
      class="flex rounded-lg justify-between items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('purchases')
      }"
      (click)="collapsed && active('purchases')"
      id="purchaseButton"
      data-dropdown-toggle="purchase"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="purchase"
      [accessType]="userPermission?.purchase"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="purchaseTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/purchases/purchase-order'] : []"
      >
        <path
          d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/purchases/purchase-order"
            >{{ "Purchases" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('purchases')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.purchases }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Purchases dropdown -->
    <div
      id="purchase"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="purchaseButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/purchases/purchase-order"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Purchase Order" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/purchases/expenses"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Expenses" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/purchases/bills"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Bills" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/purchases/recurring-bill"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Recurring Bill" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/purchases/vendors"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Vendors" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/purchases/assets"
          routerLinkActive="active"
          (click)="closeDropdown('purchase')"
        >
          <span> {{ "Assets" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Purchases submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.purchases }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="purchase"
      [accessType]="userPermission?.purchase"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/purchase-order"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Purchase Order" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/expenses"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Expenses" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/bills"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Bills" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/recurring-bill"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Recurring Bill" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/vendors"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Vendors" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/purchases/assets"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Assets" | translate }}
        </span>
      </div>
    </div>

    <!-- Inventory tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'inventoryTooltip',
        label: 'Inventory'
      }"
    ></ng-container>
    <!-- Inventory -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('inventory')
      }"
      (click)="collapsed && active('inventory')"
      id="inventoryButton"
      data-dropdown-toggle="inventory"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="inventory"
      [accessType]="userPermission?.inventory"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="inventoryTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/inventory/single-product'] : []"
      >
        <path
          d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/inventory/single-product"
            >{{ "Inventory" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('inventory')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.inventory }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Inventory dropdown -->
    <div
      id="inventory"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="inventoryButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/inventory/single-product"
          routerLinkActive="active"
          (click)="closeDropdown('inventory')"
        >
          <span> {{ "Item" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/inventory/bundled-products"
          routerLinkActive="active"
          (click)="closeDropdown('inventory')"
        >
          <span> {{ "Bundled Products" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/inventory/production-run"
          routerLinkActive="active"
          (click)="closeDropdown('inventory')"
        >
          <span> {{ "Production Run" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/inventory/write-off"
          routerLinkActive="active"
          (click)="closeDropdown('inventory')"
        >
          <span> {{ "Write Off" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Inventory submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.inventory }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="inventory"
      [accessType]="userPermission?.inventory"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/inventory/single-product"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Item" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/inventory/bundled-products"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Bundled Products" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/inventory/production-run"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Production Run" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/inventory/write-off"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Write Off" | translate }}
        </span>
      </div>
    </div>

    <!-- Accounting tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'accountingTooltip',
        label: 'Accounting'
      }"
    ></ng-container>

    <!-- Accounting -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('accounting')
      }"
      (click)="collapsed && active('accounting')"
      id="accountingButton"
      data-dropdown-toggle="accounting"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="accounting"
      [accessType]="userPermission?.accounting"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="accountingTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/accounting/transaction'] : []"
      >
        <path
          d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/accounting/transaction"
            >{{ "Accounting" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('accounting')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.accounting }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Accounting dropdown -->
    <div
      id="accounting"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="accountingButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/accounting/transaction"
          routerLinkActive="active"
          (click)="closeDropdown('accounting')"
        >
          <span> {{ "Transactions" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/accounting/recurring-jv"
          routerLinkActive="active"
          (click)="closeDropdown('accounting')"
        >
          <span> {{ "Recurring JV" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/accounting/charts-of-account"
          routerLinkActive="active"
          (click)="closeDropdown('accounting')"
        >
          <span> {{ "Charts of Accounts" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Accounting submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.accounting }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="accounting"
      [accessType]="userPermission?.accounting"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/accounting/transaction"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Transactions" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/accounting/recurring-jv"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Recurring JV" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/accounting/charts-of-account"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Charts of Accounts" | translate }}
        </span>
      </div>
    </div>

    <!-- Time Sheet tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'timeSheetTooltip',
        label: 'Time Sheet'
      }"
    ></ng-container>

    <!-- Time Sheet -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('time-sheet')
      }"
      (click)="collapsed && active('time-sheet')"
      id="timeSheetButton"
      data-dropdown-toggle="timeSheet"
      data-dropdown-placement="right"
      *ngIf="haveTimeSheetPlan"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="timeSheetTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/time-sheet/register'] : []"
      >
        <path
          d="m612-292 56-56-148-148v-184h-80v216l172 172ZM480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-400Zm0 320q133 0 226.5-93.5T800-480q0-133-93.5-226.5T480-800q-133 0-226.5 93.5T160-480q0 133 93.5 226.5T480-160Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/time-sheet/register"
            >{{ "Time Sheet" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('time-sheet')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show['time-sheet'] }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Time Sheet dropdown -->
    <div
      id="timeSheet"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="accountingButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/time-sheet/register"
          routerLinkActive="active"
          (click)="closeDropdown('timeSheet')"
        >
          <span> {{ "Register" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/time-sheet/hours-report"
          routerLinkActive="active"
          (click)="closeDropdown('timeSheet')"
        >
          <span> {{ "Hours Report" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/time-sheet/employees-report"
          routerLinkActive="active"
          (click)="closeDropdown('timeSheet')"
          *ngIf="hasTimeSheetEditPolicy"
        >
          <span> {{ "Employees Report" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Time Sheet submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show['time-sheet'] }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="time-sheet"
      [accessType]="userPermission?.timeSheets"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/time-sheet/register"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Register" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/time-sheet/hours-report"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Hours Report" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/time-sheet/employees-report"
        routerLinkActive="active"
        *ngIf="hasTimeSheetEditPolicy"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Employees Report" | translate }}
        </span>
      </div>
    </div>

    <!-- Projects tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'projectTooltip',
        label: 'Projects'
      }"
    ></ng-container>
    <!-- Projects -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 p-3 cursor-pointer mt-1"
      [ngClass]="{
        'justify-center w-11': collapsed,
        active: isActiveRoute('projects')
      }"
      (click)="active('projects')"
      routerLink="/projects/list"
      routerLinkActive="active"
      appAccessControl
      moduleType="projects"
      [accessType]="userPermission?.projects"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="projectTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      >
        <path
          d="m656-120-56-56 63-64-63-63 56-57 64 64 63-64 57 57-64 63 64 64-57 56-63-63-64 63Zm-416-80q17 0 28.5-11.5T280-240q0-17-11.5-28.5T240-280q-17 0-28.5 11.5T200-240q0 17 11.5 28.5T240-200Zm0 80q-50 0-85-35t-35-85q0-50 35-85t85-35q37 0 67.5 20.5T352-284q39-11 63.5-43t24.5-73v-160q0-83 58.5-141.5T640-760h46l-63-63 57-57 160 160-160 160-57-56 63-64h-46q-50 0-85 35t-35 85v160q0 73-47 128.5T354-203q-12 37-43.5 60T240-120Zm-64-480-56-56 63-64-63-63 56-57 64 64 63-64 57 57-64 63 64 64-57 56-63-63-64 63Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="font-bold-900">{{ "Projects" | translate }}</span>
        </span>
      </ng-container>
    </div>

    <!-- P/L Budget tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'budgetTooltip',
        label: 'P/L Budget'
      }"
    ></ng-container>

    <!-- P/L Budget -->
    <div
      class="flex justify-between rounded-lg items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('plus')
      }"
      (click)="collapsed && active('plus')"
      id="plusButton"
      data-dropdown-toggle="plus"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="budget"
      [accessType]="userPermission?.budget"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="budgetTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/plus/budget'] : []"
      >
        <path
          d="M640-160v-280h160v280H640Zm-240 0v-640h160v640H400Zm-240 0v-440h160v440H160Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="py-3 w-full font-bold-900" routerLink="/plus/budget">{{
            "P/L Budget" | translate
          }}</span>
          <button
            (click)="toggleSubmenu('plus')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.plus }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- P/L Budget dropdown -->
    <div
      id="plus"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="plusButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/plus/budget"
          routerLinkActive="active"
          (click)="closeDropdown('plus')"
        >
          <span> {{ "P/L Budget" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/plus/finance-dashboard"
          routerLinkActive="active"
          (click)="closeDropdown('plus')"
        >
          <span> {{ "P/L Analysis" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/plus/sales-dashboard"
          routerLinkActive="active"
          (click)="closeDropdown('plus')"
        >
          <span> {{ "Sales Dashboard" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- P/L Budget submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.plus }"
      *ngIf="!collapsed"
      appAccessControl
      moduleType="budget"
      [accessType]="userPermission?.budget"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/plus/budget"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "P/L Budget" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/plus/finance-dashboard"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "P/L Analysis" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/plus/sales-dashboard"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Sales Dashboard" | translate }}
        </span>
      </div>
    </div>

    <!-- Reports tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'reportTooltip',
        label: 'Reports'
      }"
    ></ng-container>

    <!-- Reports -->
    <div
      class="flex rounded-lg justify-between items-center hover:bg-[#F0F5FF] gap-3 p-3 cursor-pointer mt-1"
      [ngClass]="{
        'justify-center w-11': collapsed,
        active: isActiveRoute('reports')
      }"
      (click)="active('reports')"
      routerLink="/reports"
      routerLinkActive="active"
      appAccessControl
      moduleType="reports"
      [accessType]="userPermission?.reports"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="reportTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      >
        <path
          d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="font-bold-900">{{ "Reports" | translate }}</span>
        </span>
      </ng-container>
    </div>

    <!-- Expert tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'expertsTooltip',
        label: 'Expert'
      }"
    ></ng-container>

    <!-- Expert -->
    <div
      class="flex rounded-lg justify-between items-center hover:bg-[#F0F5FF] gap-3 ps-3 cursor-pointer mt-1"
      [ngClass]="{
        'p-3': !showText,
        'justify-center w-11': collapsed,
        active: isActiveRoute('experts')
      }"
      id="expertsButton"
      data-dropdown-toggle="experts"
      data-dropdown-placement="right"
      appAccessControl
      moduleType="sales"
      [accessType]="userPermission?.expert"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="expertsTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
        [routerLink]="!collapsed ? ['/experts/hire-expert'] : []"
      >
        <path
          d="m387-412 35-114-92-74h114l36-112 36 112h114l-93 74 35 114-92-71-93 71ZM240-40v-309q-38-42-59-96t-21-115q0-134 93-227t227-93q134 0 227 93t93 227q0 61-21 115t-59 96v309l-240-80-240 80Zm240-280q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-159l160-41 160 41v-124q-35 20-75.5 31.5T480-240q-44 0-84.5-11.5T320-283v124Zm160-62Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="py-3 w-full font-bold-900"
            routerLink="/experts/hire-expert"
            >{{ "Expert" | translate }}</span
          >
          <button
            (click)="toggleSubmenu('experts')"
            class="hover:bg-[#d2e1ff] p-3 rounded-lg"
          >
            <svg
              class="w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': show.experts }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </button>
        </span>
      </ng-container>
    </div>
    <!-- Expert dropdown -->
    <div
      id="experts"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="expertsButton"
      >
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer"
          routerLink="/experts/hire-expert"
          routerLinkActive="active"
          (click)="closeDropdown('experts')"
        >
          <span>{{ "Hire an Expert" | translate }} </span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
          routerLink="/experts/enquiry-history"
          routerLinkActive="active"
          (click)="closeDropdown('experts')"
        >
          <span> {{ "Enquiry History" | translate }} </span>
        </li>
      </ul>
    </div>
    <!-- Expert submenu -->
    <div
      class="transition-height ease-in-out duration-200"
      [ngClass]="{ 'h-0 overflow-hidden': !show.experts }"
      *ngIf="!collapsed"
    >
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/experts/hire-expert"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Hire an Expert" | translate }}
        </span>
      </div>
      <div
        class="ms-9 hover:bg-[#F0F5FF] py-2 px-3 rounded-lg cursor-pointer mt-1"
        routerLink="/experts/enquiry-history"
        routerLinkActive="active"
      >
        <span
          class="ease-in-out transition-opacity duration-100 text-[#455A64]"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          {{ "Enquiry History" | translate }}
        </span>
      </div>
    </div>

    <!-- Document tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'storageTooltip',
        label: 'Document'
      }"
    ></ng-container>

    <!-- Document -->
    <div
      class="flex rounded-lg justify-between items-center hover:bg-[#F0F5FF] gap-3 p-3 cursor-pointer mt-1"
      [ngClass]="{
        'justify-center w-11': collapsed,
        active: isActiveRoute('storage')
      }"
      (click)="active('documents')"
      routerLink="/storage"
      routerLinkActive="active"
      *ngIf="haveStoragePlan"
      appAccessControl
      moduleType="sales"
      [accessType]="userPermission?.files"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="storageTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      >
        <path
          d="m200-120-80-480h720l-80 480H200Zm67-80h426l51-320H216l51 320Zm133-160h160q17 0 28.5-11.5T600-400q0-17-11.5-28.5T560-440H400q-17 0-28.5 11.5T360-400q0 17 11.5 28.5T400-360ZM240-640q-17 0-28.5-11.5T200-680q0-17 11.5-28.5T240-720h480q17 0 28.5 11.5T760-680q0 17-11.5 28.5T720-640H240Zm80-120q-17 0-28.5-11.5T280-800q0-17 11.5-28.5T320-840h320q17 0 28.5 11.5T680-800q0 17-11.5 28.5T640-760H320Zm-53 560h426-426Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="font-bold-900">{{ "Documents" | translate }}</span>
        </span>
      </ng-container>
    </div>

    <!-- Learn and Support tooltip -->
    <ng-container
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'learnTooltip',
        label: 'Learn and Support'
      }"
    ></ng-container>

    <!-- Learn and Support -->
    <div
      class="flex rounded-lg justify-between items-center hover:bg-[#F0F5FF] gap-3 p-3 cursor-pointer mt-1"
      [ngClass]="{
        'justify-center w-11': collapsed
      }"
      (click)="redirect('education')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 -960 960 960"
        class="w-5 min-w-[1.25rem] focus:outline-none"
        fill="#5E6267"
        data-tooltip-target="learnTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      >
        <path
          d="M480-120 200-272v-240L40-600l440-240 440 240v320h-80v-276l-80 44v240L480-120Zm0-332 274-148-274-148-274 148 274 148Zm0 241 200-108v-151L480-360 280-470v151l200 108Zm0-241Zm0 90Zm0 0Z"
        />
      </svg>
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between ease-in-out transition-opacity duration-200"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span class="font-bold-900">{{
            "Learn and Support" | translate
          }}</span>
        </span>
      </ng-container>
    </div>
  </div>

  <div class="px-4"><hr class="mb-4 w-full" /></div>

  <div
    class="flex relative ps-4 pb-4"
    [ngClass]="{ 'pe-2': collapsed, 'pe-4': !collapsed }"
    (clickOutside)="showProfile = false"
  >
    <!-- Profile tooltip -->
    <ng-container
      *ngIf="user$ | async as user"
      [ngTemplateOutlet]="tooltipOutlet"
      [ngTemplateOutletContext]="{
        $implicit: 'profileTooltip',
        label: user.firstName + ' ' + user.lastName
      }"
    ></ng-container>

    <!-- Profile -->
    <div
      class="flex items-center w-full gap-3 cursor-pointer"
      [ngClass]="{
        'pe-3 rounded-2xl ltr:rounded-l-11 rtl:rounded-r-11': showText,
        'bg-[#F0F5FF]': !collapsed && showProfile,
        'rounded-full': collapsed,
        'hover:bg-[#F0F5FF]': !collapsed
      }"
      (click)="profileLogic()"
      data-dropdown-toggle="profile"
      data-dropdown-placement="right"
    >
      <img
        *ngIf="user$ | async as user"
        [src]="
          user.profileImageName
            ? baseURL + user.profileImageName
            : defaultAvatar
        "
        onerror="this.src='assets/images/avatar.jpg'"
        alt="Profile"
        class="w-11 rounded-full"
        [ngClass]="{ 'justify-self-center': collapsed }"
        data-tooltip-target="profileTooltip"
        data-tooltip-placement="right"
        data-tooltip-trigger="hover"
      />
      <ng-container *ngIf="showText">
        <span
          class="w-full flex justify-between items-center ease-in-out transition-opacity duration-100 text-[#455A64] gap-3"
          [ngClass]="{
            'opacity-0': !showText,
            'opacity-100 delay-300': showText
          }"
        >
          <span
            class="Poppins-SemiBold text-[#455A64]"
            *ngIf="user$ | async as user"
            >{{ user.firstName }} {{ user.lastName }}</span
          >
          <span>
            <svg
              class="h-5 w-5 transition-all duration-200"
              viewBox="0 0 16 16"
              [ngClass]="{ 'rotate-180': !showProfile }"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.47138 5.52857C4.21103 5.26823 3.78892 5.26823 3.52858 5.52858C3.26823 5.78892 3.26823 6.21103 3.52858 6.47138L7.52858 10.4714C7.78893 10.7317 8.21104 10.7317 8.47138 10.4714L12.4714 6.47138C12.7317 6.21103 12.7317 5.78892 12.4714 5.52857C12.211 5.26822 11.7889 5.26822 11.5286 5.52857L7.99998 9.05717L4.47138 5.52857Z"
                fill="black"
              />
            </svg>
          </span>
        </span>
      </ng-container>
    </div>
    <!-- Profile dropdown -->
    <div
      id="profile"
      [ngClass]="{
        'z-10 hidden bg-white divide-y divide-gray-100 rounded-lg shadow-2xl w-52 border':
          collapsed
      }"
    >
      <ul
        *ngIf="collapsed"
        class="p-2 text-sm text-gray-800 flex flex-col w-full"
        aria-labelledby="connectButton"
      >
        <li
          class="hover:bg-[#F0F5FF] flex py-2 px-3 gap-3 rounded-lg cursor-pointer"
          routerLinkActive="active"
          (click)="closeProfile()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 min-w-[1.25rem] focus:outline-none"
            viewBox="0 -960 960 960"
            fill="#5E6267"
          >
            <path
              d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"
            />
          </svg>
          <span>{{ "Settings" | translate }}</span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] flex py-2 px-3 gap-3 rounded-lg cursor-pointer mt-1"
          (click)="redirect('help')"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 min-w-[1.25rem] focus:outline-none"
            viewBox="0 -960 960 960"
            fill="#5E6267"
          >
            <path
              d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM364-182l48-110q-42-15-72.5-46.5T292-412l-110 46q23 64 71 112t111 72Zm-72-366q17-42 47.5-73.5T412-668l-46-110q-64 24-112 72t-72 112l110 46Zm188 188q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm116 178q63-24 110.5-71.5T778-364l-110-48q-15 42-46 72.5T550-292l46 110Zm72-368 110-46q-24-63-71.5-110.5T596-778l-46 112q41 15 71 45.5t47 70.5Z"
            />
          </svg>
          <span>{{ "Help" | translate }}</span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] flex py-2 px-3 gap-3 rounded-lg cursor-pointer mt-1"
          (click)="changeLanguage()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 min-w-[1.25rem] focus:outline-none"
            viewBox="0 -960 960 960"
            fill="#5E6267"
          >
            <path
              d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"
            />
          </svg>
          <span>{{ arabicSelected ? "English" : ("Arabic" | translate) }}</span>
        </li>
        <li
          class="hover:bg-[#F0F5FF] flex py-2 px-3 gap-3 rounded-lg cursor-pointer mt-1"
          (click)="logout()"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="w-5 min-w-[1.25rem] focus:outline-none"
            viewBox="0 -960 960 960"
            fill="#FF0900"
          >
            <path
              d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
            />
          </svg>
          <span class="text-red-600">{{ "Logout" | translate }}</span>
        </li>
      </ul>
    </div>
    <!-- Profile submenu -->
    <div
      class="absolute flex flex-col p-2 px-4 bottom-full left-0 origin-bottom bg-white w-full transition-height duration-1000 negative-shadow"
      [ngClass]="{
        'h-0 overflow-hidden hidden': !showProfile,
        'h-[188px]': showProfile
      }"
      *ngIf="!collapsed"
    >
      <div
        class="flex ps-14 gap-3 py-2 pe-3 cursor-pointer hover:bg-[#F0F5FF]"
        routerLinkActive="active"
        (click)="settingsLogo()"
        id="settings"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 min-w-[1.25rem] focus:outline-none"
          viewBox="0 -960 960 960"
          fill="#5E6267"
        >
          <path
            d="m370-80-16-128q-13-5-24.5-12T307-235l-119 50L78-375l103-78q-1-7-1-13.5v-27q0-6.5 1-13.5L78-585l110-190 119 50q11-8 23-15t24-12l16-128h220l16 128q13 5 24.5 12t22.5 15l119-50 110 190-103 78q1 7 1 13.5v27q0 6.5-2 13.5l103 78-110 190-118-50q-11 8-23 15t-24 12L590-80H370Zm70-80h79l14-106q31-8 57.5-23.5T639-327l99 41 39-68-86-65q5-14 7-29.5t2-31.5q0-16-2-31.5t-7-29.5l86-65-39-68-99 42q-22-23-48.5-38.5T533-694l-13-106h-79l-14 106q-31 8-57.5 23.5T321-633l-99-41-39 68 86 64q-5 15-7 30t-2 32q0 16 2 31t7 30l-86 65 39 68 99-42q22 23 48.5 38.5T427-266l13 106Zm42-180q58 0 99-41t41-99q0-58-41-99t-99-41q-59 0-99.5 41T342-480q0 58 40.5 99t99.5 41Zm-2-140Z"
          />
        </svg>
        <span class="text-sm text-[#455A64]">{{ "Settings" | translate }}</span>
      </div>
      <div
        class="flex ps-14 gap-3 py-2 pe-3 cursor-pointer hover:bg-[#F0F5FF] mt-1"
        (click)="redirect('help')"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 min-w-[1.25rem] focus:outline-none"
          viewBox="0 -960 960 960"
          fill="#5E6267"
        >
          <path
            d="M480-80q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80ZM364-182l48-110q-42-15-72.5-46.5T292-412l-110 46q23 64 71 112t111 72Zm-72-366q17-42 47.5-73.5T412-668l-46-110q-64 24-112 72t-72 112l110 46Zm188 188q50 0 85-35t35-85q0-50-35-85t-85-35q-50 0-85 35t-35 85q0 50 35 85t85 35Zm116 178q63-24 110.5-71.5T778-364l-110-48q-15 42-46 72.5T550-292l46 110Zm72-368 110-46q-24-63-71.5-110.5T596-778l-46 112q41 15 71 45.5t47 70.5Z"
          />
        </svg>
        <span class="text-sm text-[#455A64]">{{ "Help" | translate }}</span>
      </div>
      <div
        class="flex ps-14 gap-3 py-2 pe-3 cursor-pointer hover:bg-[#F0F5FF] mt-1"
        (click)="changeLanguage()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 min-w-[1.25rem] focus:outline-none"
          viewBox="0 -960 960 960"
          fill="#5E6267"
        >
          <path
            d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"
          />
        </svg>
        <span class="text-sm text-[#455A64]">{{
          arabicSelected ? "English" : "Arabic"
        }}</span>
      </div>
      <div
        class="flex ps-14 gap-3 py-2 pe-3 cursor-pointer hover:bg-[#F0F5FF] mt-1"
        (click)="logout()"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-5 min-w-[1.25rem] focus:outline-none"
          viewBox="0 -960 960 960"
          fill="#FF0900"
        >
          <path
            d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h280v80H200Zm440-160-55-58 102-102H360v-80h327L585-622l55-58 200 200-200 200Z"
          />
        </svg>
        <span class="text-sm text-[#FF0A00]">{{ "Logout" | translate }}</span>
      </div>
    </div>
  </div>
</div>
<!-- Settings modal -->
<app-user-settings *ngIf="openSettingsModal">
  <div content class="flex justify-start -mb-14 -mt-10 -mx-5 bg-white h-[72vh]">
    <div class="h-full w-[29%] flex justify-between flex-col">
      <div class="gap-1 flex p-4 flex-col overflow-auto h-full max-h-full">
        <div
          class="h-14 justify-start grid-flow-col grid hover:bg-[#F0F5FF] hover:transition-all duration-300 items-center gap-2 rounded-7 p-4 cursor-pointer"
          (click)="selectedTab = 'userProfile'"
          [ngClass]="{
            activeSetting: selectedTab == 'userProfile'
          }"
          tabindex="1"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M353.77-383q-22.77 0-38.27-15.73-15.5-15.72-15.5-38.5 0-22.77 15.73-38.27 15.72-15.5 38.5-15.5 22.77 0 38.27 15.73 15.5 15.72 15.5 38.5 0 22.77-15.73 38.27-15.72 15.5-38.5 15.5Zm253 0q-22.77 0-38.27-15.73-15.5-15.72-15.5-38.5 0-22.77 15.73-38.27 15.72-15.5 38.5-15.5 22.77 0 38.27 15.73 15.5 15.72 15.5 38.5 0 22.77-15.73 38.27-15.72 15.5-38.5 15.5ZM480-140q142.38 0 241.19-98.95T820-480.47q0-25.53-4-50.53t-10-46q-20 5-43.26 7-23.26 2-48.74 2-97.11 0-183.56-40Q444-648 383-722q-34 81-97.5 141.5T140-487v7q0 142.37 98.81 241.19Q337.63-140 480-140Zm0 60q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm-92-727q88 103 162.5 141T714-628q24 0 38-1t31-6q-45-81-122.5-133T480-820q-27 0-51 4t-41 9ZM149-558q48-18 109.5-81.5T346-793q-87 39-131.5 99.5T149-558Zm239-249Zm-42 14Z"
            />
          </svg>
          <span class="text-sm">{{ "Profile Management" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          (click)="selectedTab = 'userManage'"
          [ngClass]="{
            activeSetting: selectedTab == 'userManage'
          }"
          tabindex="1"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M38-160v-94q0-35 18-63.5t50-42.5q73-32 131.5-46T358-420q62 0 120 14t131 46q32 14 50.5 42.5T678-254v94H38Zm700 0v-94q0-63-32-103.5T622-423q69 8 130 23.5t99 35.5q33 19 52 47t19 63v94H738ZM358-481q-66 0-108-42t-42-108q0-66 42-108t108-42q66 0 108 42t42 108q0 66-42 108t-108 42Zm360-150q0 66-42 108t-108 42q-11 0-24.5-1.5T519-488q24-25 36.5-61.5T568-631q0-45-12.5-79.5T519-774q11-3 24.5-5t24.5-2q66 0 108 42t42 108ZM98-220h520v-34q0-16-9.5-31T585-306q-72-32-121-43t-106-11q-57 0-106.5 11T130-306q-14 6-23 21t-9 31v34Zm260-321q39 0 64.5-25.5T448-631q0-39-25.5-64.5T358-721q-39 0-64.5 25.5T268-631q0 39 25.5 64.5T358-541Zm0 321Zm0-411Z"
            />
          </svg>
          <span class="text-sm">{{ "User Management" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'subscriptions'
          }"
          (click)="selectedTab = 'subscriptions'"
          tabindex="3"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="m516-243 84-50 84 50-22-95 73-64-97-8-38-90-38 90-97 8 73 64-22 95ZM260-380v60H140q-24.75 0-42.37-17.63Q80-355.25 80-380v-440q0-24.75 17.63-42.38Q115.25-880 140-880h440q24.75 0 42.38 17.62Q640-844.75 640-820v120h-60v-120H140v440h120ZM380-80q-24.75 0-42.37-17.63Q320-115.25 320-140v-440q0-24.75 17.63-42.38Q355.25-640 380-640h440q24.75 0 42.38 17.62Q880-604.75 880-580v440q0 24.75-17.62 42.37Q844.75-80 820-80H380Zm0-60h440v-440H380v440Zm220-220Z"
            />
          </svg>
          <span class="text-sm">{{ "Subscriptions" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'marketplace'
          }"
          (click)="selectedTab = 'marketplace'"
          tabindex="4"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M480-375 375-480l105-105 105 105-105 105Zm-85-294-83-83 168-168 168 168-83 83-85-85-85 85ZM208-312 40-480l168-168 83 83-85 85 85 85-83 83Zm544 0-83-83 85-85-85-85 83-83 168 168-168 168ZM480-40 312-208l83-83 85 85 85-85 83 83L480-40Z"
            />
          </svg>
          <span class="text-sm">{{ "Marketplace" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'sales'
          }"
          (click)="selectedTab = 'sales'"
          tabindex="5"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M180-160q-24 0-42-18t-18-42v-600q0-24 18-42t42-18h600q24 0 42 18t18 42v247q-14-6-29-11t-31-8v-228H180v404h170q19 29 43.5 50t54.5 29q-2 16-1 31t3 30q-37-6-70-26.5T326-356H180v136h284q6 16 13.5 31t17.5 29H180Zm0-60h284-284Zm517 100-5-48q-23-7-42-18.5T617-211l-42 20-35-54 38-30q-5-19-5-41.5t5-41.5l-38-30 35-55 42 20q14-12 33-23.5t42-18.5l5-49h60l6 49q23 7 42 18.5t33 23.5l42-20 35 55-38 30q5 19 5 41.5t-5 41.5l38 30-35 54-42-20q-14 13-33 24.5T763-168l-6 48h-60Zm30-95q44 0 73-29t29-73q0-44-29-73t-73-29q-44 0-73 29t-29 73q0 44 29 73t73 29Z"
            />
          </svg>
          <span class="text-sm">{{ "Sales Settings" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'accounting'
          }"
          (click)="selectedTab = 'accounting'"
          tabindex="6"
        >
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M566-80v-60h214v-430H180v220h-60v-410q0-24 18-42t42-18h65v-60h65v60h340v-60h65v60h65q24 0 42 18t18 42v620q0 24-18 42t-42 18H566ZM320-14l-42-42 113-114H50v-60h341L278-344l42-42 186 186L320-14ZM180-630h600v-130H180v130Zm0 0v-130 130Z"
            />
          </svg>
          <span class="text-sm">{{ "Accounting & Tax" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'business'
          }"
          (click)="selectedTab = 'business'"
          tabindex="7"
        >
          <svg
            class="h-6 w-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M840-519v339q0 24-18 42t-42 18H179q-24 0-42-18t-18-42v-339q-28-24-37-59t2-70l43-135q8-27 28-42t46-15h553q28 0 49 15.5t29 41.5l44 135q11 35 1.5 70T840-519Zm-270-31q29 0 49-19t16-46l-25-165H510v165q0 26 17 45.5t43 19.5Zm-187 0q28 0 47.5-19t19.5-46v-165H350l-25 165q-4 26 14 45.5t44 19.5Zm-182 0q24 0 41.5-16.5T263-607l26-173H189l-46 146q-10 31 8 57.5t50 26.5Zm557 0q32 0 50.5-26t8.5-58l-46-146H671l26 173q3 24 20.5 40.5T758-550ZM179-180h601v-311q1 1-6.5 1H758q-25 0-47.5-10.5T666-533q-16 20-40 31.5T573-490q-30 0-51.5-8.5T480-527q-15 18-38 27.5t-52 9.5q-31 0-55-11t-41-32q-24 21-47 32t-46 11h-13.5q-6.5 0-8.5-1v311Zm601 0H179h601Z"
            />
          </svg>
          <span class="text-sm">{{ "Bussiness Settings" | translate }}</span>
        </div>
        <div
          class="hover:bg-[#F0F5FF] hover:transition-all duration-300 h-14 justify-start grid-flow-col grid items-center gap-2 rounded-7 p-4 cursor-pointer"
          [ngClass]="{
            activeSetting: selectedTab == 'payment'
          }"
          (click)="selectedTab = 'payment'"
          tabindex="8"
        >
          <svg
            class="w-6 h-6"
            xmlns="http://www.w3.org/2000/svg"
            height="48px"
            viewBox="0 -960 960 960"
            width="48px"
            fill="#5f6368"
          >
            <path
              d="M880-740v520q0 24-18 42t-42 18H140q-24 0-42-18t-18-42v-520q0-24 18-42t42-18h680q24 0 42 18t18 42ZM140-631h680v-109H140v109Zm0 129v282h680v-282H140Zm0 282v-520 520Z"
            />
          </svg>
          <span class="text-sm">{{ "Payment Gateway" | translate }}</span>
        </div>
      </div>
      <div
        class="flex items-center p-[0.4rem] justify-center cursor-pointer"
        (click)="closeSettingsModal()"
      >
        <div class="bg-white flex items-center justify-center text-sm">
          {{ "Back to Dashboard" | translate }}
        </div>
      </div>
    </div>
    <div
      style="
        z-index: 1;
        height: 104%;
        width: 1px;
        margin-top: -8px;
        background-color: #e8e8e8;
      "
    ></div>
    <div [ngSwitch]="selectedTab" class="overflow-x-auto p-4 w-full me-1">
      <app-profile *ngSwitchCase="'userProfile'"></app-profile>
      <app-subscriptions *ngSwitchCase="'subscriptions'"></app-subscriptions>
      <app-marketplace *ngSwitchCase="'marketplace'"></app-marketplace>
      <app-sales-settings
        appFeatureCheck
        feature="sales"
        *ngSwitchCase="'sales'"
      ></app-sales-settings>
      <app-accounting-and-taxes
        appFeatureCheck
        feature="accounting"
        *ngSwitchCase="'accounting'"
      ></app-accounting-and-taxes>
      <app-business-and-stores *ngSwitchCase="'business'">
      </app-business-and-stores>
      <app-user-management
        appFeatureCheck
        feature="users"
        *ngSwitchCase="'userManage'"
      ></app-user-management>
      <app-online-payment *ngSwitchCase="'payment'"></app-online-payment>
    </div>
  </div>
</app-user-settings>

<ng-template #tooltipOutlet let-id let-label="label">
  <div
    [id]="id"
    role="tooltip"
    [ngClass]="{
      'absolute z-10 invisible inline-block px-3 py-2 text-sm font-medium text-white bg-black rounded-lg shadow-sm opacity-0 tooltip whitespace-nowrap':
        collapsed
    }"
  >
    {{ collapsed ? label : ("" | translate) }}
    <svg
      xmlns="http://www.w3.org/2000/svg"
      class="absolute top-1/2 ltr:-left-2 rtl:-right-2 rtl:rotate-180 -translate-y-1/2"
      *ngIf="collapsed"
      [ngClass]="{ hidden: !collapsed }"
      width="12"
      height="26"
      viewBox="0 0 12 26"
      fill="none"
    >
      <path
        d="M0.832188 11.1233L11.9555 0V25.9111L0.832189 14.7877C-0.179701 13.7758 -0.179702 12.1352 0.832188 11.1233Z"
        fill="black"
      />
    </svg>
  </div>
</ng-template>
