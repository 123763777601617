import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { EstimatesService } from '../../estimates.service';

@Component({
  selector: 'app-estimates-list',
  templateUrl: './estimates-list.component.html',
  styleUrls: ['./estimates-list.component.scss'],
  providers: [DatePipe]
})
export class EstimatesListComponent implements OnInit, OnDestroy {

  constructor(private estimateService: EstimatesService,
              private salesService: SalesService,
              private router: Router,
              private spinner: NgxSpinnerService,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private numberService: NumberService,
              private datePipe: DatePipe) { }
  deleteOpenModal = false;
  esimateToDelete = null;
  currencyDetails ={
    currency: '',
    currencySymbol: ''
  }
  unsubscribe = new Subject();
  tableHeadings = [
    'Date',
    'Estimate Number',
    'Customer',
    'Status',
    'Amount',
    'Actions'
  ];

  number = '1.2-2';

  tableKeys = [
    'date',
    'estimateNumber',
    'customerName',
    'status',
    'totalAmount'
  ];

  tableData = [];

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadEstimateList();
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number;
    })
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail.subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })
  }

  loadEstimateList(): void {
    this.salesService.businessId$.subscribe((id) => {
      if (id) {
        this.spinner.show();
        this.estimateService.getEstimates().subscribe(resp => {
          this.spinner.hide();
          this.tableData = resp.data.map(estimate =>
            ({
              ...estimate,
              totalAmount: this.numberService.currencier(estimate?.totalAmount),
              date: moment(estimate?.date?.split('T')[0]).format('DD-MM-YYYY'),
              customerName: estimate?.customerDetails?.customerId?.customerName
            })).reverse();
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }

  viewEstimate(id): void {
    this.router.navigate(['/sales/estimates/view-estimate'], { queryParams: { id } });
  }
  updateEstimate(id): void {
    this.router.navigate(['/sales/estimates/create-estimate'], { queryParams: { id } });
  }

  deleteEstimate(id): void {
    this.esimateToDelete = id;
    this.deleteOpenModal = true;
  }

  deleteEstimateslist(): void {
    this.spinner.show();
    this.estimateService.deleteEstimate(this.esimateToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.deleteOpenModal = false;
      this.tableData = this.tableData.filter(({_id}) => _id !== this.esimateToDelete);
      this.toastr.success(this.translateService.instant('Estimate deleted successfully'));
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
