import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-recurring-bill',
  templateUrl: './recurring-bill.component.html',
  styleUrls: ['./recurring-bill.component.scss']
})
export class RecurringBillComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
