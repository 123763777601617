<div class="p-4 flex flex-col space-y-3">
<p>
    Zapier automatically moves info between Slack and the other apps you use every day, so you can focus on your most important work.Zapier supports thousands of apps like Gmail, HubSpot, Typeform, and more. In just a few minutes, you can set up automated workflows (we call them Zaps) that turn Slack into your team's command center. Bring calendar events, social media mentions, or new lead details straight into chat, automatically create a to-do list item when you save a Slack message, and so much more.Some things you can do with Zapier + Slack
</p>
<ul>

</ul>
<li class="list-disc">Post details of new orders in your eCommerce app to a Slack channel</li>
<li class="list-disc">Move important tasks out of Slack and into a project management or to-do app when you save a message</li>
<li class="list-disc">Send form responses to a Slack channelHow to get started</li>
<li class="list-disc">Sign up for Zapier (Zapier has a free forever plan. Paid plans scale with usage.)</li>
<li class="list-disc">Check out our Getting Started Guide</li>
<li class="list-disc">Need more info? Check out our website.What people are saying about Zapier</li>

    <p class="italic"> "I would go as far to say that it has increased my personal efficiency by more than 400 percent."
    </p>
    <p class="font-bold">
        - Garrett Grohman, Indiegogo
    </p>

    <p class="italic">
        "Zapier helps us to work faster and smarter by removing manual processes. We can now focus on adding a personal touch to our work."
    </p>
<p class="font-bold">
    - Olivia Jardine, Meister
</p>
<p class="italic">
    "Zapier probably saves me about 10 hours a week, when it comes to running our design operations. If I tap into my math skills, I do believe it’s 25% more productive!"
</p>
<p class="font-bold">
    - Lindsey Redinger, InVision
</p>
</div>