import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class PaymentSettingService {
    constructor (private httpService: HttpService) { }

    connectPaypal(data): Observable<any> {
        return this.httpService.post('/paypal/connectpaypal', data);
    }

    disconnectPaypal(data): Observable<any> {
        return this.httpService.post('/paypal/disconnectPaypal', data);
    }
}