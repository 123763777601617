import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { NumplusService } from './numplus.service';
import { BudgetService } from './components/budget/budget.service';


@Component({
  selector: 'app-numplus-container',
  templateUrl: './numplus-container.component.html',
  styleUrls: ['./numplus-container.component.scss']
})
export class NumplusContainerComponent implements OnInit {

  business$: Observable<any>;
  businessId = null;
  unsubscribe$ = new Subject();
  accountData;

  constructor(
    private numplusService: NumplusService,
    private rootStore: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private budgetService: BudgetService ) {
      this.business$ = this.rootStore.pipe(select(selectBusiness))
   }

  ngOnInit(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        const id = business?.businessId._id;
        const currencyDetails = (({currency,currencySymbol})=>({currency,currencySymbol}))(business?.businessId);
        this.numplusService.setCurrencyDetails(currencyDetails);
        this.numplusService.setBusinessId(id);

      }
    });
  }


}
