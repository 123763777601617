import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as CustomerActions from '../actions/customers.action'

export interface CustomersState {
    customers: Array<any>
}

export const initialState: CustomersState = {
    customers: []
};

export const CustomersReducer = createReducer(initialState,
    on(CustomerActions.SetCustomers, (state: CustomersState, {customers}) => ({...state, customers})),
    on(CustomerActions.AddCusomter, (state: CustomersState, {customer}) => ({...state, customers: [...state.customers, {...customer}]})),
    on(CustomerActions.UpdateCustomer, (state: CustomersState, {customer}) => {
        const mappedCustomer = state.customers.map((ogCustomer) => {
            if (ogCustomer._id === customer._id) { return {...customer}; }
            return ogCustomer;
        });
        return { ...state, customers: [...mappedCustomer] };
    }),
    on(CustomerActions.DeleteCustomer, (state: CustomersState, {_id}) => {
        const filteredCustomers = state.customers.filter(customer => customer._id !== _id);
        return { ...state, customers: [...filteredCustomers] };
    }),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: CustomersState | undefined, action: Action): any {
    return CustomersReducer(state, action);
}

export const getCustomers = (state: CustomersState) => state.customers;
