import * as fromExpertDetails from './expertDetails.reducer';

export interface expertDetailsState {
  expertDetails: fromExpertDetails.ExpertDetailsState;
}

export const expertDetailsReducerMap = {
  expertDetails: fromExpertDetails.reducer,
};

export const getExpertDetails = (state: expertDetailsState) =>
  state.expertDetails;
