import { Component, OnDestroy, OnInit } from '@angular/core';
import { registerLocaleData } from "@angular/common";
import localeAr from "@angular/common/locales/ar";
registerLocaleData(localeAr, 'ar')
import { select, Store } from '@ngrx/store';
import { ChartDataSets, ChartOptions } from 'chart.js';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, Observable, of, Subject } from 'rxjs';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { DashboardService } from '../dashboard.service';
import * as moment from 'moment';
import { map, mergeMap, catchError, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { NumberService } from 'src/app/shared/services/number.service';
import { selectUserSubscription } from "../../../store/selectors/subscription.selectors";
import { Router } from '@angular/router';
import { ChatsService } from '../../chats/chats.service';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit, OnDestroy {
  number = '1.2-2'
  year = moment().format('YYYY');
  previousYear = moment().subtract(1, 'year').format('YYYY');
  public barChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero : true,
          suggestedMax: 5,
          fontFamily: 'Gilroy',
          padding: 10,
        },
        gridLines: {
          color: '#c8c8c8A4'
        },
        stacked: true
      }],
      xAxes: [{
          ticks: {
            padding: 10,
            fontFamily: 'Gilroy',
            fontSize: 10,
            stepSize: 1,
          },
          gridLines: {
            display: false
          },
          stacked: true
        }]
    },
    maintainAspectRatio: false,
  };

  public barChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July' , 'August', 'September', 'October', 'November', 'December'].forEach(item => (this.translateService.instant(item)));
  public barChartType = 'bar';
  public barChartLegend = true;
  public barChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Net'),
      type: 'line',
      fill: false,
      backgroundColor: 'rgba(0, 0, 0, 0)',
      pointBackgroundColor: '#fff',
      borderColor: '#181818',
      tension: 0
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Outflow'),
      backgroundColor: '#c8c8c8'
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Inflow'),
      backgroundColor: '#238636'
    },
  ];

  public chartColors: any[] = [];
  public doughnutChartData = [250, 80, 80, 40];
  public doughnutChartType = 'doughnut';
  public chartColors1: any[] = [
  {
  backgroundColor: [ '#0B5878', '#01CC9B', '#14A0C0', '#7DE314']
  }
  ];

  public profitLossChartOptions: (ChartOptions) = {
    responsive: true,
    scales: {
      yAxes: [{
        ticks: {
          beginAtZero : true,
          suggestedMax: 5,
          fontFamily: 'Gilroy',
          padding: 10,
        },
        gridLines: {
          color: '#c8c8c8A4'
        },
      }],
      xAxes: [{
          ticks: {
            padding: 10,
            fontFamily: 'Gilroy',
            fontSize: 10,
            stepSize: 1,
          },
          gridLines: {
            display: false
          },
        }]
    },
    maintainAspectRatio: false,
  };

  public profitLossChartLabels = ['January', 'February', 'March', 'April', 'May', 'June', 'July' , 'August', 'September', 'October', 'November', 'December'].forEach(item => (this.translateService.instant(item)));
  public profitLossChartType = 'bar';
  public profitLossChartLegend = true;
  public profitLossChartData = [
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Expense'),
      backgroundColor: '#c8c8c8'
    },
    {
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
      label: this.translateService.instant('Income'),
      backgroundColor: '#238636'
    },
  ];
  notificationsCount: any;
  allNotifications: any;
  userDetails: any;
  user$: Observable<any>;

  constructor(private dashboardService: DashboardService,
              private spinner: NgxSpinnerService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private router: Router,
              private chatService: ChatsService,
              private deviceService: DeviceDetectorService,
              private store: Store<RootReducerState>) {
                if(this.deviceService.isMobile()) {
                  this.router.navigate(['/chats']);
                };
                this.business$ = this.store.pipe(select(selectBusiness));
                this.subscription$ = this.store.pipe(select(selectUserSubscription));
                this.user$ = this.store.pipe(select(selectUser));
              }
  subscription$: Observable<any>;
  business$: Observable<any>;
  businessId;
  currencyName = 'USD';
  unsubscribe = new Subject();
  dateFilters = {
    currentWeek : {
      fromDate: '',
      endDate: ''
    },
    Last7Days : {
      fromDate: '',
      endDate: ''
    },
    LastMonth: {
      fromDate: '',
      endDate: ''
    },
    Last30Days : {
      fromDate: '',
      endDate: ''
    },
    currentMonth : {
      fromDate: '',
      endDate: ''
    },
    currentYear : {
      fromDate: '',
      endDate: ''
    },
  };
  filters = []
  selectedFilter = {
    text: this.translateService.instant('Current Week')
  }
  profitLossSelectedFilter = {
    text: this.translateService.instant('Current Week')
  }
  overdueInvoices = []
  overdueBills = []
  payable = {
    firstPhase: 0,
    secondPhase: 0,
    thirdPhase: 0,
    lastPhase: 0
  }
  owing = {
    firstPhase: 0,
    secondPhase: 0,
    thirdPhase: 0,
    lastPhase: 0
  }
  netIncome = {
    currentYear: {
      income: 0,
      outcome: 0
    },
    previousYear: {
      income: 0,
      outcome: 0
    }
  }
  
  ngOnInit(): void {
    this.loadDates();
    this.loadFilters();
    this.loadInitialData();
    this.loadNumberConfig();

    // this.chatService.listen('getAllNotifications').subscribe((data)=>{
    //   // console.log('allNotifications', user, data);
    //   this.allNotifications = data.filter((item)=>item.toUser.includes(this.userDetails._id) && item.fromUser !== this.userDetails._id).slice().reverse();
    //   this.notificationsCount = this.allNotifications.filter((item)=>!item.seen).length || 0;
    // });
    
    // this.user$.subscribe((user) => {
    //   if (user) {
    //     this.userDetails = user; 
    //     this.chatService.emit('getAllNotifications', this.userDetails._id);
    //   }
    // });
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`;
    })
  }

  loadDates(): void {
    this.dateFilters.currentWeek.fromDate = moment().startOf('week').format('YYYY-MM-DD');
    this.dateFilters.currentWeek.endDate = moment().endOf('week').format('YYYY-MM-DD');
    this.dateFilters.Last7Days.fromDate = moment().subtract(6, 'days').format('YYYY-MM-DD');
    this.dateFilters.Last7Days.endDate = moment().format('YYYY-MM-DD');
    this.dateFilters.currentMonth.fromDate = moment().startOf('month').format('YYYY-MM-DD');
    this.dateFilters.currentMonth.endDate = moment().endOf('month').format('YYYY-MM-DD');
    this.dateFilters.Last30Days.fromDate = moment().subtract(30, 'days').format('YYYY-MM-DD');
    this.dateFilters.Last30Days.endDate = moment().format('YYYY-MM-DD');
    this.dateFilters.LastMonth.fromDate = moment().subtract(1, 'month').startOf('month').format('YYYY-MM-DD');
    this.dateFilters.LastMonth.endDate = moment().subtract(1, 'month').endOf('month').format('YYYY-MM-DD');
    this.dateFilters.currentYear.fromDate = moment().startOf('year').format('YYYY-MM-DD');
    this.dateFilters.currentYear.endDate = moment().endOf('year').format('YYYY-MM-DD');
  }

  loadFilters(): void {
    this.filters = [
      {
        text: `${this.translateService.instant('Current Week')} (${moment(this.dateFilters.currentWeek.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.currentWeek.endDate).format('DD-MM-YYYY')})`,
        type: 'week',
        fromDate: this.dateFilters.currentWeek.fromDate,
        endDate: this.dateFilters.currentWeek.endDate
      },
      {
        text: `${this.translateService.instant('Last 7 Days')} (${moment(this.dateFilters.Last7Days.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.Last7Days.endDate).format('DD-MM-YYYY')})`,
        type: 'month',
        fromDate: this.dateFilters.Last7Days.fromDate,
        endDate: this.dateFilters.Last7Days.endDate
      },
      {
        text: `${this.translateService.instant('Last 30 Days')} (${moment(this.dateFilters.Last30Days.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.Last30Days.endDate).format('DD-MM-YYYY')})`,
        type: 'month',
        fromDate: this.dateFilters.Last30Days.fromDate,
        endDate: this.dateFilters.Last30Days.endDate
      },
      {
        text: `${this.translateService.instant('Current Month')} (${moment(this.dateFilters.currentMonth.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.currentMonth.endDate).format('DD-MM-YYYY')})`,
        type: 'month',
        fromDate: this.dateFilters.currentMonth.fromDate,
        endDate: this.dateFilters.currentMonth.endDate
      },
      {
        text: `${this.translateService.instant('Last Month')} (${moment(this.dateFilters.LastMonth.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.LastMonth.endDate).format('DD-MM-YYYY')})`,
        type: 'month',
        fromDate: this.dateFilters.LastMonth.fromDate,
        endDate: this.dateFilters.LastMonth.endDate
      },
      {
        text: `${this.translateService.instant('Current Year')} (${moment(this.dateFilters.currentYear.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.currentYear.endDate).format('DD-MM-YYYY')})`,
        type: 'year',
        fromDate: this.dateFilters.currentYear.fromDate,
        endDate: this.dateFilters.currentYear.endDate
      },
    ]
    this.selectedFilter = {
      text: `${this.translateService.instant('Current Week')} (${moment(this.dateFilters.currentWeek.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.currentWeek.endDate).format('DD-MM-YYYY')})`
    }
    this.profitLossSelectedFilter = {
      text: `${this.translateService.instant('Current Week')} (${moment(this.dateFilters.currentWeek.fromDate).format('DD-MM-YYYY')} - ${moment(this.dateFilters.currentWeek.endDate).format('DD-MM-YYYY')})`
    }
  }

  loadInitialData(): void {
    this.spinner.show();
    this.business$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId?._id;
        const accounting = business?.businessId?.accounting;
        this.currencyName = business?.businessId?.currency;
        const year = moment().format('YYYY');
        const prevYear = moment().subtract(1, 'year').format('YYYY');
        const currentFiscalFromDate = moment(`${accounting?.date || '01'} ${accounting?.month || 'January'} ${year}`).format('YYYY-MM-DD');
        const currentFiscalEndDate = moment(`${accounting?.date || '01'} ${accounting?.month  || 'January'} ${year}`).add(1, 'year').format('YYYY-MM-DD');
        const previousFiscalFromDate = moment(`${accounting?.date || '01'} ${accounting?.month || 'January'} ${prevYear}`).format('YYYY-MM-DD');
        const previousFiscalEndDate = moment(`${accounting?.date || '01'} ${accounting?.month || 'January'} ${prevYear}`).add(1, 'year').format('YYYY-MM-DD');
        console.log(currentFiscalFromDate, currentFiscalEndDate);
        const dataForIncomeCurrent = {
          businessId: business.businessId._id,
          startDate: currentFiscalFromDate,
          endDate: currentFiscalEndDate
        }
        const dataForIncomePrevious = {
          businessId: business.businessId._id,
          startDate: previousFiscalFromDate,
          endDate: previousFiscalEndDate
        }
        const data = {
          businessId: business.businessId._id,
          fromDate: this.dateFilters.currentWeek.fromDate,
          endDate: this.dateFilters.currentWeek.endDate
        }
        this.cashFlowApiCaller(data, 'week');
        this.profitLossApiCaller(data, 'week');
        const apiList = [
          this.dashboardService.loadOverdueInvoiceData({businessId :this.businessId}).pipe(catchError(error => of(null))),
          this.dashboardService.loadOverdueBillData({businessId :this.businessId}).pipe(catchError(error => of(null))),
          this.dashboardService.loadPayableData({businessId: this.businessId}).pipe(catchError(error => of(null))),
          this.dashboardService.loadOwingData({businessId: this.businessId}).pipe(catchError(error => of(null))),
          this.dashboardService.loadIncomeExpenseByYear(dataForIncomeCurrent).pipe(catchError(error => of(null))),
          this.dashboardService.loadIncomeExpenseByYear(dataForIncomePrevious).pipe(catchError(error => of(null))),
        ]
        this.spinner.show();
        forkJoin(apiList).subscribe((resp) => {
          this.spinner.hide();
          this.overdueInvoices = resp[0]?.data?.reverse()?.splice(0, 4);
          this.overdueBills = resp[1].data.splice(0, 4);
          this.payable = resp[2].data[0];
          this.owing = resp[3].data[0];
          if (resp[4] && resp[5]) {
            this.netIncome.currentYear.income = resp[4]?.data?.income;
            this.netIncome.currentYear.outcome = resp[4]?.data?.expense;
            this.netIncome.previousYear.income = resp[5]?.data?.income;
            this.netIncome.previousYear.outcome = resp[5]?.data?.expense;
          }
        }, (error) => {
          this.spinner.hide();
        })
      }
    })
  }

  loadInflowData(data, type): Observable<any> {
    switch(type) {
      case 'year': return this.dashboardService.loadInflowDataByYear(data);
      case 'week': return this.dashboardService.loadInflowDataByWeek(data);
      case 'month': return this.dashboardService.loadInflowDataByMonth(data);
    }
  }

  loadOutFlowData(data, type): Observable<any> {
    switch (type) {
      case 'year': return this.dashboardService.loadOutFlowDataByYear(data);
      case 'week': return this.dashboardService.loadOutFlowDataByWeek(data);
      case 'month': return this.dashboardService.loadOutFlowDataByMonth(data); 
    }
  }

  loadProfitData(data, type): Observable<any> {
    switch(type) {
      case 'year': return this.dashboardService.loadProfitByYear(data);
      case 'week': return this.dashboardService.loadProfitByWeek(data);
      case 'month': return this.dashboardService.loadProfitByMonth(data);
    }
  }

  loadLossData(data, type): Observable<any> {
    switch(type) {
      case 'year': return this.dashboardService.loadLossByYear(data);
      case 'week': return this.dashboardService.loadLossByWeek(data);
      case 'month': return this.dashboardService.loadLossByMonth(data);
    }
  }



  cashFlowChange(data): void {
    const filterData = {
      businessId: this.businessId,
      fromDate: data.fromDate,
      endDate: data.endDate 
    }
    this.cashFlowApiCaller(filterData, data.type);
  }

  cashFlowApiCaller(data, type): void {
    this.spinner.show();
    this.loadInflowData(data, type).pipe(
      mergeMap((inflowData) => {
        if(inflowData.success) {
          return this.loadOutFlowData(data, type).pipe(
            map((outFlowData) => {
              let labels = type == 'year'  ? this.getMonthsArray(data) : this.getDatesArray(data);
              let outFlowValues = [];
              let inFlowVales = [];
              if (type == 'year') {
                outFlowValues = this.mapDataForYear(outFlowData?.data[0]?.data || [], labels);
                inFlowVales = this.mapDataForYear(inflowData?.data[0]?.data || [], labels);
              } else if (type == 'month') {
                outFlowValues = this.mapDataForMonth(outFlowData.data, labels);
                inFlowVales = this.mapDataForMonth(inflowData.data, labels);
              } else {
                outFlowValues = this.mapDataForWeek(outFlowData.data, labels);
                inFlowVales = this.mapDataForWeek(inflowData.data, labels);
              }
              return {
                outFlowData: outFlowValues,
                inFlowData: inFlowVales,
                labels
              }
            })
          );
        }
      })
    ).subscribe((resp) => {
      this.spinner.hide();
      const outflow: Array<any> = Object.values(resp.outFlowData).map(val => -val);
      const inflow: Array<any> = Object.values(resp.inFlowData);
      let line = new Array(outflow.length).fill(0);
      line = line.map((ele, i) => inflow[i] + outflow[i])
      this.barChartData[0].data = line;
      this.barChartData[1].data = outflow;
      this.barChartData[2].data = inflow;
      this.barChartLabels = resp.labels;
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    })
  }

  profitLossChange(data): void {
    const filterData = {
      businessId: this.businessId,
      fromDate: data.fromDate,
      endDate: data.endDate 
    }
    this.profitLossApiCaller(filterData, data.type);
  }

  profitLossApiCaller(data, type): void {
    this.spinner.show();
    this.loadProfitData(data, type).pipe(
      mergeMap((inflowData) => {
        if(inflowData.success) {
          return this.loadLossData(data, type).pipe(
            map((outFlowData) => {
              let labels = type == 'year'  ? this.getMonthsArray(data) : this.getDatesArray(data);
              let outFlowValues = [];
              let inFlowVales = [];
              if (type == 'year') {
                outFlowValues = this.mapDataForYear(outFlowData?.data[0]?.data || [], labels);
                inFlowVales = this.mapDataForYear(inflowData?.data[0]?.data || [], labels);
              } else if (type == 'month') {
                outFlowValues = this.mapDataForMonth(outFlowData?.data, labels);
                inFlowVales = this.mapDataForMonth(inflowData?.data, labels);
              } else {
                outFlowValues = this.mapDataForWeek(outFlowData?.data, labels);
                inFlowVales = this.mapDataForWeek(inflowData?.data, labels);
              }
              return {
                outFlowData: outFlowValues,
                inFlowData: inFlowVales,
                labels
              }
            })
          );
        }
      })
    ).subscribe((resp) => {
      this.spinner.hide();
      const outflow: Array<any> = Object.values(resp.outFlowData);
      const inflow: Array<any> = Object.values(resp.inFlowData);
      this.profitLossChartData[0].data = outflow;
      this.profitLossChartData[1].data = inflow;
      this.profitLossChartLabels = resp.labels;
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    })
  }

  mapDataForYear(data, monthsArray): any {
   // Object.keys(data).map((month) => ({_id: month, total: data[month]}))
    const results = Object.keys(data).map((month) => ({_id: month, total: data[month] }));
    console.log("...",results)
    const values = new Array(monthsArray.length).fill(0);
    monthsArray.map((month, idx) => {
      results.forEach((dataElement) => {
        if (dataElement._id === month) {
          values[idx] = dataElement.total;
        }
      });
    });
    return values;
  }

  mapDataForMonth(data, labels): any {
    const values = new Array(labels.length).fill(0);
    labels.map((date, idx) => {
      data.forEach((dataElement) => {
        if (dataElement._id === date) {
          values[idx] = dataElement.total;
        }
      });
    });
    return values;
  }

  mapDataForWeek(data, labels): any {
    const array  = new Array(labels.length).fill(0);
    data.map((count) => (array[count._id - 1] = count.total));
    return array;
  }

  getDatesArray(filter): any {
    let datesArray = [];
    let startDate: any = moment(filter.fromDate).format('YYYY-MM-DD');
    const endDate =  moment(filter.endDate).format('YYYY-MM-DD');
    while (!moment(startDate).isAfter(endDate)) {
      datesArray.push(startDate);
      startDate = moment(startDate).add(1, 'day').format('YYYY-MM-DD');
    }
    datesArray = datesArray.map(el => {
      return moment(el).format('DD-MM-YYYY')
    })
    console.log(datesArray);
    
    return datesArray;
  }

  getMonthsArray(filter): any {
    const monthsArray = [];
    let startDate: any = moment(filter.fromDate).format('YYYY-MM-DD');
    const endDate =  moment(filter.endDate).format('YYYY-MM-DD');
    while (!moment(startDate).isAfter(endDate)) {
      monthsArray.push(moment(startDate).format('MMMM-YYYY'));
      startDate = moment(startDate).add(1, 'month').format('YYYY-MM-DD');
    }
    monthsArray.forEach(el => {
      return moment(el).format('DD-MM-YYYY')
    })

    return monthsArray;
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  goToNotification(id){
    // console.log(chat, id);
    const payload = {
      seen: true,
      updateId: id._id
    }
    this.chatService.emit('updateNotification', payload);
    this.router.navigate(['/chats']);
  }
}
