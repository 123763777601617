import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { updateCurrentAdminLimit } from 'src/app/store/actions/usage.action';
import { UpdateAdminLimit } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser, selectUserEmails } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
  styleUrls: ['./emails.component.scss']
})
export class EmailsComponent implements OnInit {

  constructor(private store: Store<RootReducerState>, private toster: ToastrService, private userService: UserService, private spinner: NgxSpinnerService) {
    this.emails$ = this.store.pipe(select(selectUserEmails));
    this.usage$ = store.pipe(select(selectUsage));
    this.user$ = this.store.pipe(select(selectUser));
  }

  emails$: Observable<any>;
  usage$: Observable<any>;
  adminLimit;
  addNewEmail = false;
  newEmail: FormControl = new FormControl('', [Validators.required]);
  error = '';
  response = '';
  emailToDelete = null;
  deleteEmailModal = false;
  user$: Observable<any>;
  availableLanguages = [{name: 'English', code: 'en'}, {name: 'Arabic', code: 'ar'}, ]
  communicationLanguage: FormControl = new FormControl('en', [Validators.required]);;

  ngOnInit(): void {
    this.getAdminLimit();
    this.user$.subscribe((user) => {
      if (user) {
        console.log("gettingUser", user);
        this.communicationLanguage.setValue(user.language)
      }
    });
  }

  getAdminLimit(): void {
    this.usage$.subscribe(({currentUsage}) => {
      if(currentUsage){
        const { adminLimit } = currentUsage;
        this.adminLimit = adminLimit;
      }
    })
  }

  trackByFn(index: number): number {
    return index;
  }

  verifyEmail(): void {
    if (this.newEmail.invalid) {
      this.newEmail.markAsDirty();
      return;
    }
    this.spinner.show();
    this.userService.addNewEmail(this.newEmail.value)
    .subscribe((resp) => {
      this.spinner.hide();
      this.newEmail.reset();
      this.addNewEmail = false;
      if(resp?.success){
        this.store.dispatch(updateCurrentAdminLimit({adminLimit:this.adminLimit + 1}));
      }
    }, (error) => { this.error = error?.error?.message || 'Internal Server Error'; this.spinner.hide(); });
  }

  confirmEmailDelete(email): void {
    this.emailToDelete = email;
    this.deleteEmailModal = true;
  }

  deleteEmail(): void {
    this.spinner.show();
    this.userService.deleteEmail({email: this.emailToDelete}).subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.deleteEmailModal = true;
        this.userService.deleteEmailInStore(this.emailToDelete);
        this.store.dispatch(updateCurrentAdminLimit({adminLimit:this.adminLimit - 1}));
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

  setLanguage(): void {
    this.spinner.show();
    const body = {
      language: this.communicationLanguage.value
    }
    this.userService.setCommunicationLanguage(body).subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.userService.setUserDetails(resp.data);
        this.toster.success('Language updated successfully');
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

}
