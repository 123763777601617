import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { PurchasesService } from '../../purchases.service';
import { selectedProducts } from '../../state/selectors/product.selector';
import { selectSalesTaxes } from '../../state/selectors/salesTaxes.selector';

import { PurchaseProductsService } from './products.service';

@Component({
  selector: 'app-products-and-services',
  templateUrl: './products-and-services.component.html',
  styleUrls: ['./products-and-services.component.scss']
})
export class ProductsAndServicesComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private productService: PurchaseProductsService,
              private toastr: ToastrService,
              private purchasesService: PurchasesService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private spinner: NgxSpinnerService) {
    this.products$ = this.store.pipe(select(selectedProducts));
    this.salesTaxes$ = this.store.pipe(select(selectSalesTaxes));
    this.accounts$ = this.store.pipe(select(selectAccounts));
  }
  createProductForm: FormGroup;
  openAddProductModal = false;
  openDeleteConfirmModal = false;
  openUpdateProduct = false;
  error = '';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  number = '1.2-2';
  products$: Observable<any>;
  salesTaxes$: Observable<any>;
  accounts$: Observable<any>;
  businessId = null;
  productToUpdate = null;
  productToDelete = null;
  salesTaxes = [];
  expenseAccounts = [];
  id;
  update = false;
  unsubscribe$ = new Subject();
  response = '';
  formErrors = {
    name: '',
    description: '',
    accountDetails: '',
    price: '',
  };

  selectedCar;

  formErrorMessages = {
    name: {
      required: 'Name is Required'
    },
    description: {
      required: 'Description is Required'
    },
    accountDetails: {
      required: 'Expense Account is Required'
    },
    price: {
      required: 'Price is Required'
    },
  };

  tableHeadings = [
    'Name',
    'Description',
    'Price',
    'Actions'
  ];

  tableDataKeys = [];

  products = [
    {
      _id: '',
      name: '',
      description: '',
      price: null,
      accountDetails: null,
      tax: null
    }
  ];

  tableData = [
    {
      name: '',
      description: '',
      price: '',
    }
  ];

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.getProducts();
    this.getBusinessId();
    this.loadForm();
    this.loadSalesTaxes();
    this.loadAccounts();
    this.loadNumberConfig();
  }

  getBusinessId(): void {
    this.purchasesService.businessId$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((id) => {
      this.businessId = id;
  });
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  getProducts(): void {
    this.products$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(products => {
      this.tableData = products.map(item => ({
        ...item,
        price: this.numberService.currencier(item?.price)
      }));
      this.products = products;
      this.tableDataKeys = ['name', 'description', 'price'];
    });
  }

  getCurrencyDetails(): void {
    this.purchasesService.currencyDetails
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(details => {
      if (details) {
        this.currencyDetails = details;
      }
    });
  }

  loadSalesTaxes(): void {
    this.salesTaxes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(taxes => {
      this.salesTaxes = taxes;
    });
  }

  loadAccounts(): void {
    this.accounts$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(accounts => {
      if (accounts?.length > 0) {
        this.expenseAccounts = accounts
        .filter(account => account.accountSection === 'Expenses' && !account.isArchive);
        console.log(this.expenseAccounts);

      }
    });
  }

  loadForm(product?): void {
    this.createProductForm = this.fb.group({
      name: [product?.name || '', [Validators.required]],
      description: [product?.description || '', [Validators.required]],
      tax: [product?.tax || null],
      price: [this.numberService.toFixed(product?.price) || null, [Validators.required]],
      accountDetails: [product?.accountDetails || null, [Validators.required]]
    });

    this.createProductForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
  }

  taxChange(taxes): void {
    const mappedTaxes = taxes.map(({taxName, taxRate, accountDetails}) => ({taxName, tax: this.numberService.toFixed(taxRate), accountDetails}));
    this.createProductForm.get('tax').setValue(mappedTaxes);
  }

  createProduct(): void {
    if (this.createProductForm.invalid) {
      this.createProductForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createProductForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    const body = {
      _id: this.businessId,
      ...this.createProductForm.value,
      accountDetails: {
        accountId: this.createProductForm.value.accountDetails._id,
        accountName: this.createProductForm.value.accountDetails.accountName,
        accountSection: this.createProductForm.value.accountDetails.accountSection,
      },
      price: this.numberService.toFixed(this.createProductForm.value?.price),
      productType: 'buy'
    };
    this.spinner.show();
    this.productService.createProducts(body).subscribe((resp) => {
      this.spinner.hide();
      this.openAddProductModal = false;
      if (resp?.success){
        this.productService.addProductToStore(resp?.data);
        this.toastr.success(resp?.message);
      }
      else {
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
      this.createProductForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }

  modalProductUpdate(id): void {
    this.update = true;
    const product = this.products.find(({ _id }) => _id === id);
    const mappedAccountDetails = {
      ...product?.accountDetails,
      _id: product?.accountDetails?.accountId,
    };
    const productToUpdate = (({
      name,
      description,
      price,
      tax
    }) => ({
      name,
      description,
      accountDetails: mappedAccountDetails,
      price: this.numberService.toFixed(price),
      tax
    }))(product);
    this.openAddProductModal = true;
    this.loadForm(productToUpdate);
    this.productToUpdate = id;
  }

  closeProductUpdateModal(): void {
    this.openAddProductModal = false;
    this.createProductForm.reset();
  }

  updateProduct(): void {
    this.error = '';
    this.response = '';
    if (this.createProductForm.invalid) {
      this.createProductForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createProductForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }
    const body = {
      _id: this.productToUpdate,
      ...this.createProductForm.value,
      accountDetails: {
        accountId: this.createProductForm.value.accountDetails._id,
        accountName: this.createProductForm.value.accountDetails.accountName,
        accountSection: this.createProductForm.value.accountDetails.accountSection,
      },
      price: this.numberService.toFixed(this.createProductForm.value?.price),
      productType: 'buy'
    };
    this.spinner.show();
    this.productService.updateProduct(body).subscribe((resp) => {
      this.spinner.hide();
      this.openAddProductModal = false;
      if (resp?.success){
        this.productService.updateProductInStore(body);
        this.toastr.success(resp?.message);
      }
      else {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
      this.createProductForm.reset();
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }

  openDeleteConfirmation(id): void {
    this.productToDelete = id;
    this.openDeleteConfirmModal = true;
  }

  deleteProduct(): void {
    this.spinner.show();
    this.productService.deleteProducts(this.productToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.openDeleteConfirmModal = false;
      if (resp?.success){
        this.productService.deleteProductInStore(this.productToDelete);
        this.toastr.success(resp?.message);
      }
      else {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
