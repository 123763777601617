import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { UserService } from 'src/app/shared/services/user.service';
import { AuthService } from '../../auth.service';
import { UtilityService } from 'src/app/shared/services/utility.service';

@Component({
  selector: 'app-invitation',
  templateUrl: './invitation.component.html',
  styleUrls: ['./invitation.component.scss'],
})
export class InvitationComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private router: Router,
    private authService: AuthService,
    private userService: UserService,
    private utility: UtilityService
  ) {}

  verifying = true;
  verificationSuccess = false;
  verificationFailed = false;
  error = '';

  ngOnInit(): void {
    this.route.params.subscribe(({ inviteId, businessId }) => {
      this.verifyInvite({ inviteId, businessId });
    });
  }

  verifyInvite({ inviteId, businessId }): void {
    this.spinner.show();
    this.authService.verifyInvitation({ inviteId }).subscribe(
      (resp) => {
        this.spinner.hide();
        console.log(resp);
        if (resp.data) {
          this.verifying = false;
          this.verificationSuccess = true;
          this.userService.setUserDetailsWithToken({
            user: { ...resp.data, token: resp.token },
          });
          if (resp?.data?.businesses?.length > 1) {
            this.router.navigate(['/auth/select-business']);
          } else {
            localStorage.setItem(
              'selectedBusiness',
              resp?.data?.businesses[0]?.businessId?._id
            );
            // setTimeout(() => this.router.navigate(['/settings/profile']), 3000);
            setTimeout(() => {
              this.router.navigate(['/dashboard']);
              this.utility.showHideSettingsModal.next({
                open: true,
                tab: 'userProfile',
              });
            }, 3000);
          }
        } else {
          this.router.navigate([`/auth/signup/${inviteId}/${businessId}`]);
        }
      },
      (error) => {
        this.verifying = false;
        this.verificationFailed = true;
        this.error = error?.error?.message;
        console.log(error);
        this.spinner.hide();
      }
    );
  }
}
