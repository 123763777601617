import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ReferrralService {

  constructor(private http: HttpService, private httpClient: HttpClient) { }

  getReferralCode(referralCode): Observable<any> {
    return this.http.post('/admin/getReferralbyCode', {referralCode});
  }

  getExpertReferralCode(): Observable<any> {
    return this.httpClient.get('https://yybzj4o5j42kpj4qcqp4k66y540bpoev.lambda-url.us-east-2.on.aws/');
    // return this.httpClient.get('https://hetyj3rt43yzq7ej477npunkvy0vkvwe.lambda-url.eu-north-1.on.aws/');
  }
}
