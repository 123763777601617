import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { PurchaseOrderService } from '../../PurchaseOrder.service';

@Component({
  selector: 'app-purchase-order-list',
  templateUrl: './purchase-order-list.component.html',
  styleUrls: ['./purchase-order-list.component.scss']
})
export class PurchaseOrderListComponent implements OnInit {

  constructor(private purchaseService: PurchasesService,
              private spinner: NgxSpinnerService,
              private router: Router,
              private route: ActivatedRoute,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private purchaseOrderService: PurchaseOrderService) { }
  deleteOpenModal = false;
  purchaseToDelete = null;
  tableHeadings = [
    'Vendor',
    'PO Number',
    'Date',
    'Due Date',
    'Total amount',
    'Actions'
  ];
  currencyDetails = {
    currency:'',
    currencySymbol:''
  };
  number = '1.2-2';
  tableKeys = ['vendor', 'purchaseOrder', 'date', 'dueDate', 'totalAmount'];
  tableData = [];

  ngOnInit(): void {
     this.loadPurchaseOrder();
     this.getCurrencyDetails();
  }

  loadPurchaseOrder(): void{
    this.purchaseService.businessId$.subscribe((id) => {
      if (id) {
        this.spinner.show();
        this.purchaseOrderService.getPurchaseOrders(id).subscribe(resp => {
          this.spinner.hide();
          this.tableData = resp.data.map(purchaseOrder =>
            ({
              ...purchaseOrder,
              totalAmount: this.numberService.currencier(purchaseOrder?.toatalAmount),
              date: moment(purchaseOrder.date.split('T')[0]).format('DD-MM-YYYY'),
              dueDate: moment(purchaseOrder?.dueDate?.split('T')[0]).format('DD-MM-YYYY'),
              vendor: purchaseOrder?.vendor?.vendorName
            }));
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }

  getCurrencyDetails(){
    this.purchaseService.currencyDetails.subscribe(details=>{
      if(details)
        this.currencyDetails = details;
    })    
  }

  deletePurchaseOrder(id): void {
    this.purchaseToDelete = id;
    this.deleteOpenModal = true;
  }

  deletePurchaseList(): void {
    this.spinner.show();
    this.purchaseOrderService.deleteOrder(this.purchaseToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.deleteOpenModal = false;
      this.tableData = this.tableData.filter(({_id}) => _id !== this.purchaseToDelete);
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  editOrder(id): void {
    this.router.navigate(['/purchases/purchase-order/create-purchase-order'], { queryParams: { id } });
  }
  viewOrder(id): void {
    this.router.navigate([`/purchases/purchase-order/view-purchase-order`], { queryParams: { id } });
  }
}
