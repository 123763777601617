<div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
  <div class="flex items-center justify-between">
    <h1 class="text-2xl Poppins-Medium inline-flex items-center">
      # {{ receiptData?.recieptNumber }}
    </h1>
  </div>
  <div class="gap-x-2 flex ms-auto">
    <app-button
      (handleClick)="emailModal = true; fetchSendInvoiceEmail()"
      type="primary"
      buttonText="{{ 'Send' | translate }} {{ fieldNames?.type | translate }}"
    ></app-button>
    <app-button
      (handleClick)="downloadPdf()"
      type="primary"
      buttonText="{{ 'Download' | translate }} {{
        fieldNames?.type | translate
      }}"
    ></app-button>
    <div class="border rounded-xl flex items-center ps-2">
      <a
        [href]="shareURL"
        class="max-w-[250px] overflow-hidden whitespace-nowrap overflow-ellipsis"
      >
        {{ shareURL }}
      </a>
      <app-button
        type="primary"
        buttonText="{{ copyButtonText | translate }}"
        (handleClick)="copyLink()"
      ></app-button>
    </div>
  </div>
  <div class="flex">
    <div class="w-5/6 mx-auto">
      <table class="w-full d-block overflow-hidden box-shadow-sales rounded-xl">
        <div style="padding: 2rem">
          <table class="w-full" style="border-collapse: collapse">
            <tr>
              <td valign="top" class="ps-8">
                <div>
                  <h1 class="text-2xl font-semibold">
                    {{ fieldNames?.type | translate }}
                  </h1>
                  <table style="border-collapse: collapse">
                    <tr>
                      <td class="Poppins-Bold">
                        {{ fieldNames?.type | translate }}
                        {{ "Number" | translate }}:
                      </td>
                      <td align="right" class="ps-4">
                        {{ receiptData?.recieptNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold">
                        {{ fieldNames?.type | translate }}
                        {{ "Date" | translate }}:
                      </td>
                      <td align="right" class="ps-4">
                        {{ receiptData?.payDate | date }}
                      </td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold">
                        {{ "Reference Number:" | translate }}
                      </td>
                      <td align="right" class="ps-4">
                        {{ receiptData?.referanceNumber }}
                      </td>
                    </tr>
                    <tr>
                      <td class="Poppins-Bold">{{ "Memo:" | translate }}</td>
                      <td align="right" class="ps-4">
                        {{ receiptData?.memo }}
                      </td>
                    </tr>
                  </table>
                </div>
              </td>
              <td valign="top" align="right" class="pe-8 w-1/2">
                <img
                  *ngIf="companyLogo"
                  [src]="companyLogo"
                  width="200"
                  alt=""
                />
                <div class="Poppins-Bold mt-2">
                  {{ receiptData.businessDetails?.businessId?.companyName }}
                </div>
                <div>
                  {{
                    receiptData.businessDetails?.businessId?.address
                      ?.addressLineFirst
                  }}
                </div>
                <div>
                  {{
                    receiptData.businessDetails?.businessId?.address
                      ?.addressLine2
                  }}
                </div>
                <div>
                  {{
                    receiptData.businessDetails?.businessId?.address?.city
                      ? receiptData.businessDetails?.businessId?.address?.city +
                        ","
                      : ""
                  }}
                  {{ receiptData.businessDetails?.businessId?.address?.state }}
                </div>
                <div>
                  {{ receiptData.businessDetails?.businessId?.country }}
                </div>
              </td>
            </tr>
          </table>
        </div>
        <div class="px-16">
          <table class="w-full border-b" style="border-collapse: collapse">
            <tr>
              <td>
                <table>
                  <tr>
                    <td>
                      <div class="Poppins-Bold">
                        {{ fieldNames?.billTo | translate }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId
                                ?.customerName
                            : receiptData?.vendorId?.vendorName
                        }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId?.email
                            : receiptData?.vendorId?.email
                        }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId
                                ?.billingAddress?.addressLine1
                            : receiptData?.vendorId?.address?.addressLine1
                        }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId
                                ?.billingAddress?.addressLine2
                            : receiptData?.vendorId?.address?.addressLine2
                        }}
                      </div>
                      <div
                        *ngIf="
                          receiptData?.type === 'Receipt' ||
                          receiptData?.type === 'Refund'
                        "
                      >
                        {{
                          receiptData?.customerDetails?.customerId
                            ?.billingAddress?.city
                            ? receiptData?.customerDetails?.customerId
                                ?.billingAddress?.city + ","
                            : ""
                        }}
                        {{
                          receiptData?.customerDetails?.customerId
                            ?.billingAddress?.state
                        }}
                      </div>
                      <div
                        *ngIf="
                          receiptData?.type !== 'Receipt' ||
                          receiptData?.type !== 'Refund'
                        "
                      >
                        {{
                          receiptData?.vendorId?.address?.city
                            ? receiptData?.vendorId?.address?.city + ","
                            : ""
                        }}
                        {{ receiptData?.vendorId?.address?.state }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId
                                ?.billingAddress?.postalCode
                            : receiptData?.vendorId?.address?.postalCode
                        }}
                      </div>
                      <div>
                        {{
                          receiptData?.type === "Receipt" ||
                          receiptData?.type === "Refund"
                            ? receiptData?.customerDetails?.customerId
                                ?.billingAddress?.country
                            : receiptData?.vendorId?.address?.country
                        }}
                      </div>
                    </td>
                  </tr>
                </table>
              </td>
              <td align="right"></td>
            </tr>
          </table>
        </div>
        <div
          class="px-16"
          *ngIf="receiptData.type === 'Refund' || receiptData.type === 'Return'"
        >
          <span class="text-lg mb-2 Poppins-Bold">{{
            "Return Items" | translate
          }}</span>
          <table class="w-full">
            <tr>
              <td class="bg-gray-100 Poppins-Bold p-2">
                {{ "Item Name" | translate }}
              </td>
              <td class="bg-gray-100 Poppins-Bold">{{ "SKU" | translate }}</td>
              <td class="bg-gray-100 Poppins-Bold">
                {{ "HSN/SAC" | translate }}
              </td>
              <td class="bg-gray-100 Poppins-Bold">
                {{ "Quantity" | translate }}
              </td>
              <td class="bg-gray-100 Poppins-Bold">
                {{ "Price" | translate }}
              </td>
              <td class="bg-gray-100 Poppins-Bold">
                {{ "Total" | translate }}
              </td>
              <td class="bg-gray-100 Poppins-Bold">{{ "Tax" | translate }}</td>
            </tr>
            <tr *ngFor="let itemData of returnItems">
              <td class="p-2">{{ itemData.item }}</td>
              <td>{{ itemData.sku }}</td>
              <td>{{ itemData.hsn_sac }}</td>
              <td>{{ itemData.unit }}</td>
              <td>
                {{
                  itemData.price
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td>
                {{
                  itemData.unit * itemData.price
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td>
                {{
                  itemData?.tax?.length > 0 && itemData?.tax[0]?.tax
                    ? itemData.tax[0].tax + "%"
                    : "No Tax"
                }}
              </td>
            </tr>
          </table>
        </div>
        <div class="px-16">
          <table class="w-full mt-2" style="margin-bottom: 2em">
            <tr>
              <td style="width: 50%" *ngIf="receiptData.type !== 'Return'">
                <table class="">
                  <tr>
                    <td class="Poppins-Bold pe-2">
                      {{ "Payment Method" | translate }}
                    </td>
                    <td>{{ receiptData?.paymentMethod }}</td>
                  </tr>
                </table>
              </td>
              <td align="right" style="width: 50%">
                <table class="">
                  <tr>
                    <td class="Poppins-Bold pe-2">
                      {{ fieldNames?.type | translate }}
                      {{ "Amount" | translate }}
                    </td>
                    <td>{{ receiptData?.paidAmount }}</td>
                  </tr>
                  <tr>
                    <td class="Poppins-Bold pe-2">
                      {{ fieldNames?.issuer | translate }}:
                    </td>
                    <td>
                      {{
                        receiptData?.userId?.firstName
                          ? receiptData?.userId?.firstName +
                            " " +
                            receiptData?.userId?.lastName
                          : ""
                      }}
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
          </table>
        </div>
      </table>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="emailModal">
  <div header class="Poppins-Bold">
    {{ "Share " + fieldNames?.type + " via email" | translate }}
  </div>
  <div content class="flex flex-col">
    <label for="">{{ "Email" | translate }}</label>
    <input
      [formControl]="email"
      type="text"
      class="w-full rounded-xl bg-gray-100 border-0 p-3"
    />
    <div
      *ngIf="email.getError('required') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email is required" | translate }}
    </div>
    <div
      *ngIf="email.getError('pattern') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email must be in correct format" | translate }}
    </div>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="primary"
      buttonText="{{ 'Send' | translate }}"
      (handleClick)="sendEmail()"
    ></app-button>
    <app-button
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="emailModal = false"
    ></app-button>
  </div>
</app-modal-container>
