import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ExpertsComponent } from './experts.component';
import { EnquiryHistoryComponent } from './components/enquiry-history/enquiry-history.component';

const routes: Routes = [
  {
    path: '',
    component: ExpertsComponent,
    children: [
      {
        path: 'hire-expert',
        loadChildren: () =>
          import('./components/hire-expert/hire-expert.module').then(
            (m) => m.HireExpertModule
          ),
      },
      {
        path: 'become-expert',
        loadChildren: () =>
          import('./components/become-expert/become-expert.module').then(
            (m) => m.BecomeExpertModule
          ),
      },
      {
        path: 'enquiry-history',
        component: EnquiryHistoryComponent,
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ExpertsRoutingModule {}
