import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { RecurringJvService } from '../../recurring-jv.service';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-list-recurring-jv',
  templateUrl: './list-recurring-jv.component.html',
  styleUrls: ['./list-recurring-jv.component.scss']
})
export class ListRecurringJvComponent implements OnInit, OnDestroy {

  tableData = [];
  tableKeys = [
    'recurringJVId',
    'createdAt',
    'startDate',
    'endDate',
    'nextJVDate',
    'frequency',
    'jvCount',
  ];
  tableHeadings = [
    'Recurring JV Number',
    'Created At',
    'Start Date',
    'End Date',
    'Next JV Date',
    'Frequency',
    'JV Created',
    'Status',
    'Actions'
  ];
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  businessId: string;

  unsubscribe$ = new Subject();
  business$: Observable<any>;

  constructor(
    private router: Router,
    private store: Store<RootReducerState>,
    private numberService: NumberService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private jvService: RecurringJvService,
  ) {
    this.business$ = store.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.loadNumberConfig();
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(business => {
      this.businessId = business?.businessId?._id;
      this.loadRecurringJV();
    })
  }



  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  loadRecurringJV(): void {
    this.spinner.show();
    this.jvService.getAllRecurringJV(this.businessId)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
         this.tableData = resp?.data?.map(item => {
          return {
            _id: item?._id,
            recurringJVId: item?.recurringJVId,
            createdAt: moment(item?.createdAt).format('YYYY-MM-DD'),
            startDate: moment(item?.startDate).format('YYYY-MM-DD'),
            endDate: moment(item?.endDate).format('YYYY-MM-DD'),
            nextJVDate: item?.nextJVDate ? moment(item?.nextJVDate).format('YYYY-MM-DD') : null,
            frequency: item?.frequency,
            jvCount: item?.jvCount,
            isCanceled: item?.isCanceled,
            isEnded: item?.isEnded
          }
         })
      }
      else {
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      console.log(err);
      
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  viewJV(id: string): void {
    this.router.navigate(['accounting/recurring-jv/view-recurring-jv'], {queryParams: {id}})
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
