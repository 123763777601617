<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col">
    <div class="flex items-center justify-end">
      <app-button
        appAccessControl
        moduleType="projects"
        accessType="edit"
        type="primary"
        buttonText="{{ 'Create a project' | translate }}"
        size="large"
        (click)="showHideProject()"
      ></app-button>
    </div>
    <app-table-container
      [tableHeadings]="tableHeadings"
      [tableData]="projects"
      [keys]="tableKeys"
      [actionTemplate]="actionTemplate"
    >
      <ng-template let-item #actionTemplate>
        <td>{{ (item?.isActive ? "Open" : "Close") | translate }}</td>
        <td align="right" class="p-2 last:pe-10 text-center">
          <app-dropdown #dropdown>
            <div
              button
              (click)="dropdown.open = true"
              (clickOutside)="dropdown.open = false"
            >
              <div
                class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
              </div>
            </div>
            <div options class="new-shadow p-1">
              <div
                (click)="showDetails(item._id)"
                class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
              >
                {{ "View" | translate }}
              </div>
              <div
                appAccessControl
                moduleType="projects"
                accessType="edit"
                (click)="showHideProject(item)"
                disabled="!projectForm.valid"
                class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
              >
                {{ "Edit" | translate }}
              </div>
              <div
                appAccessControl
                moduleType="projects"
                accessType="edit"
                (click)="showCloseModal(item)"
                class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
              >
                {{ (item.isActive ? "Close" : "Open") | translate }}
              </div>
            </div>
          </app-dropdown>
        </td>
      </ng-template>
    </app-table-container>
  </div>
</div>

<app-modal-container [isOpen]="deleteOpenModal">
  <div header class="border-b pb-2 Poppins-Medium">
    {{ (projectSelected?.isActive ? "Close" : "Open") | translate }}
    {{ "Project" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      [buttonText]="projectSelected?.isActive ? 'Close' : 'Open'"
      [type]="projectSelected?.isActive ? 'danger' : 'primary'"
      (handleClick)="changeProjectStatus()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="deleteOpenModal = false"
    ></app-button>
  </div>
</app-modal-container>
<app-modal-container [isOpen]="createUpdateModal">
  <div header class="border-b pb-2 Poppins-Medium">
    {{ (newProject ? "Create" : "Update") | translate }}
    {{ "Project" | translate }}
  </div>
  <div content>
    <my-tabs className="w-[37%]">
      <my-tab [tabTitle]="'Project Details' | translate">
        <div [formGroup]="projectForm">
          <div>
            <label
              for=""
              class="block mb-1 Poppins-Medium text-md text-black"
              >{{ "Project Name" | translate }}</label
            >
            <input
              type="text"
              class="new-input"
              formControlName="projectName"
              required
            />
            <span
              class="text-red-600 text-sm"
              *ngIf="formErrors?.projectName"
              >{{ formErrors?.projectName }}</span
            >
          </div>
          <div class="mt-2">
            <label
              for=""
              class="block mb-1 Poppins-Medium text-md text-black"
              >{{ "Project Description" | translate }}</label
            >
            <textarea
              class="new-input !h-12"
              formControlName="description"
            ></textarea>
          </div>
          <div class="mt-2">
            <label
              for=""
              class="block mb-1 Poppins-Medium text-md text-black"
              >{{ "Estimated Earning" | translate }}</label
            >
            <input
              type="number"
              min="0"
              class="new-input"
              formControlName="earnings"
            />
            <span *ngIf="formErrors?.earnings" class="text-red-600 text-sm">{{
              formErrors?.earnings
            }}</span>
          </div>
          <div class="mt-2">
            <label
              for=""
              class="block mb-1 Poppins-Medium text-md text-black"
              >{{ "Estimated Expenses" | translate }}</label
            >
            <input
              type="number"
              min="0"
              class="new-input"
              formControlName="expenses"
            />
            <span *ngIf="formErrors?.expenses" class="text-red-600 text-sm">{{
              formErrors?.expenses
            }}</span>
          </div>
          <div class="mt-2">
            <label class="Poppins-Medium" for="">{{
              "Start date" | translate
            }}</label>
            <input
              formControlName="estimatedStartDate"
              type="date"
              class="new-date"
            />
            <span *ngIf="formErrors?.estimatedStartDate" class="text-red-600">{{
              formErrors.estimatedStartDate
            }}</span>
          </div>
          <div class="mt-2">
            <label class="Poppins-Medium" for="">{{
              "End date" | translate
            }}</label>
            <input
              formControlName="estimatedEndDate"
              type="date"
              class="new-date"
            />
            <span *ngIf="formErrors.estimatedEndDate" class="text-red-600">{{
              formErrors.estimatedEndDate
            }}</span>
          </div>
          <div class="mt-2">
            <label
              for=""
              class="block mb-1 Poppins-Medium text-md text-black"
              >{{ "Estimated Project Hours" | translate }}</label
            >
            <input
              type="number"
              min="0"
              class="new-input"
              formControlName="estimatedHour"
            />
            <span
              *ngIf="formErrors?.estimatedHour"
              class="text-red-600 text-sm"
              >{{ formErrors?.estimatedHour }}</span
            >
          </div>
        </div>
      </my-tab>
      <my-tab
        appAccessControl
        moduleType="storage"
        accessType="edit"
        [tabTitle]="'Files' | translate"
      >
        <app-file-upload
          #fileUploadComponent
          [addedFiles]="addedFiles"
          (emitter)="saveFiles($event)"
          type="compact"
          tableType="new"
        ></app-file-upload>
      </my-tab>
    </my-tabs>
  </div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      buttonText="{{ newProject ? 'Create' : 'Update' }}"
      type="primary"
      (handleClick)="handleProject()"
    ></app-button>
    <app-button buttonText="Cancel" (handleClick)="closeModal()"></app-button>
  </div>
</app-modal-container>
