<div class="mb-6">
  <h3 class="mb-4 Poppins-Medium text-lg inline-flex">
    {{ companyName }} {{ "Users" | translate }}
  </h3>
  <p class="text-sm">{{ "User invite message" | translate }}</p>
</div>
<ul class="space-y-4">
  <li *ngIf="businessOwner.email" class="bg-input-light py-4 px-8 rounded-lg">
    <div class="flex items-center justify-between">
      <div class="space-y-1">
        <p class="">
          {{ businessOwner.firstName }} {{ businessOwner.lastName }} |
          {{ "Owner" | translate }}
        </p>
        <p class="text-sm">{{ businessOwner.email }}</p>
      </div>
    </div>
  </li>
  <li
    *ngFor="let user of usersList"
    class="bg-input-light text-sm py-4 px-8 rounded-lg"
  >
    <div class="flex items-center justify-between">
      <div class="space-y-1">
        <p>
          {{ user.firstName }} {{ user.lastName }} |
          {{
            user.position === "custom"
              ? "3rd Party"
              : (user.position | titlecase)
          }}
        </p>
        <p class="text-xs">{{ user.email }}</p>
      </div>
      <div class="flex items-center gap-4">
        <app-submit
          *ngIf='businessOwner.email === userFromStore?.email || userAdminType'
          className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10 w-20"
          [value]="'Edit' | translate"
          (clicked)="editUser.emit({ inviteId: user._id, role: user.position })"
        >
        </app-submit>
        <button (click)="removeInvitedUser(user._id)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            name="delete"
            class="h-5 w-5 text-red-500"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
            />
          </svg>
        </button>
      </div>
    </div>
  </li>
</ul>
