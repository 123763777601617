import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'timeFormat'
})
export class TimeFormatPipe implements PipeTransform {

  /**
   * Transforms a total time value into a formatted string.
   * 
   * @param totalWorked - The total time worked, in seconds or minutes.
   * @param includeSeconds - Whether to include seconds in the output format.
   * @param isInSeconds - Indicates if the input time value is in seconds. If false, the value is in minutes.
   * @param padLength - The number of digits to pad the hours, minutes, and seconds with.
   * @returns A formatted time string.
   */
  transform(totalWorked: number, includeSeconds: boolean = false, isInSeconds: boolean = true, padLength: number = 2): string {
    // Handle invalid or negative input
    if (totalWorked == null || isNaN(totalWorked) || totalWorked < 0) {
      return includeSeconds ? '00:00:00' : '00:00';
    }

    // Convert minutes to seconds if necessary
    if (!isInSeconds) {
      totalWorked = totalWorked * 60;
    }

    // Calculate hours, minutes, and seconds
    const hours = Math.floor(totalWorked / 3600);
    const minutes = Math.floor((totalWorked % 3600) / 60);
    const seconds = totalWorked % 60;

    // Pad the time values with leading zeroes
    const hoursStr = hours.toString().padStart(padLength, '0');
    const minutesStr = minutes.toString().padStart(padLength, '0');
    const secondsStr = seconds.toString().padStart(padLength, '0');

    // Return the formatted time string
    return includeSeconds ? `${hoursStr}:${minutesStr}:${secondsStr}` : `${hoursStr}:${minutesStr}`;
  }
}
