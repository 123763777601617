import { Action, createReducer, on } from "@ngrx/store";
import * as StoreActions from "../actions/store.actions";
import * as UserActions from "../actions/user.actions";

export interface StoreState {
    stores: Array<any>;
}

export const initialState: StoreState = {
    stores: []
}

export const StoreReducer = createReducer(initialState,
    on(StoreActions.SetStore, (state: StoreState, {stores}) => {
        console.log(state,stores,' in reducer');
        return {...state, stores}
    }),
    on(StoreActions.AddStore, (state: StoreState, {store}) => ({...state, stores: [...state.stores, store]})),
    on(StoreActions.updateStore, (state: StoreState, {store}) => {
        const mappedStores = state.stores.map(singleStore => {
            if(singleStore?._id === store?._id){
                return {...store}
            }
            else {
                return singleStore
            }
        });
        return { ...state,  stores: [...mappedStores]};
    }),
    on(StoreActions.DeleteStore, (state: StoreState, {storeId}) => ({...state, stores: state.stores.filter(singleStore => {
            return singleStore?._id != storeId?.storeId;
        })
    })),
    on(UserActions.UserLogout, (state: StoreState) => ({...initialState}))
    )

    export function reducer(state: StoreState | undefined, action: Action): any {
        return StoreReducer(state, action);
    }
    
    export const getStores = (state: StoreState) => state.stores;