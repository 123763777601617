import { CurrencyPipe, getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { forkJoin, of, Subject } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { BillsService } from '../../purchases/components/bills/bills.services';
import { OpenService } from '../open.service';

@Component({
  selector: 'app-bill',
  templateUrl: './bill.component.html',
  styleUrls: ['./bill.component.scss'],
  providers: [CurrencyPipe]
})
export class BillComponent implements OnInit {

  constructor(private billService: BillsService,
    private openService: OpenService,
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private currencyPipe: CurrencyPipe,
    private numberService: NumberService,
    private spinner: NgxSpinnerService) { }
  billDetails;
  number = '1.2-2';
  decimalSize = 2;
  customizationSettings;
  unsubscribe = new Subject();
  fieldNames = {
    bill: 'Bill',
    billNumber: 'Bill Number',
    date: 'Bill Date',
    dueDate: 'Due Date',
    vendorDetails: 'Vendor Details',
    itemName: 'Item Name',
    quantity: 'Quantity',
    price: 'Price',
    total: 'Total',
    tax: 'Tax',
    subtotal: 'Subtotal',
    purchaseOrder: 'Purchase Order',
    notes: 'Notes',
    issuer: 'Issued By'
  }

  ngOnInit(): void {
    this.route.params.subscribe(({ businessId, billId }) => {
      if (businessId && billId) {
        this.loadData(businessId, billId);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  loadData(businessId, billId): void {
    const apiList = [
      this.billService.getBillId(billId).pipe(catchError((error) => of(null))),
      this.openService.getInvoiceCustomizationSettings(businessId).pipe(catchError((error) => of(null)))
    ];

    this.spinner.show();
    forkJoin(apiList)
      .subscribe((results) => {
        this.spinner.hide();
        if (results[0].success) {
          this.billDetails = results[0].data;
        }
        if (results[1].success) {
          this.customizationSettings = results[1].data;
        }
      }, (error) => {
        this.spinner.hide();
        console.log(error);
      });
  }

  loadCustomizationSettings(): void {

  }

  downloadPdf(): void {
    const config = {
      fieldNames: this.languageService.translate(this.fieldNames),
      data: {...this.billDetails,
        items: this.billDetails.items.map(item => {
          return {...item,
            price: this.numberService.currencier(item?.price),
            total: this.numberService.currencier((item.price * item.unit))
          }
        }),
        date: moment(this.billDetails.date).format('DD-MM-YYYY'),
        dueDate: moment(this.billDetails.dueDate).format('DD-MM-YYYY'),
        dueAmount: this.numberService.currencier(this.billDetails?.dueAmount),
        totalAmount: this.numberService.currencier(this.billDetails?.totalAmount),
        tax: this.numberService.currencier(this.billDetails?.tax),
        subtotal: this.numberService.currencier(this.billDetails?.subtotal),
            },
      direction: localStorage.getItem('NuMetric|lang') === 'en'? 'ltr': 'rtl',
      decimalSize: this.decimalSize,
      companyLogo: this.customizationSettings?.companyLogo
    };
    this.spinner.show();
    this.billService.createBillPdf({config}).subscribe((resp) => {
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Bill ${this.billDetails.billNumber}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
    })
  }

}
