import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { RecurringBillComponent } from './recurring-bill.component';
import { ListRecurringBillComponent } from './components/list-recurring-bill/list-recurring-bill.component';
import { ViewRecurringBillComponent } from './components/view-recurring-bill/view-recurring-bill.component';
import { CreateRecurringBillComponent } from './components/create-recurring-bill/create-recurring-bill.component';

const routes: Routes = [
  {
    path: '',
    component: RecurringBillComponent,
    children: [
      {
        path: '',
        component: ListRecurringBillComponent
      },
      {
        path: 'view-template',
        component: ViewRecurringBillComponent
      },
      {
        path: 'create-bill',
        component: CreateRecurringBillComponent
      },
      {
        path: '*',
        component: ListRecurringBillComponent
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class RecurringBillRoutingModule { }
