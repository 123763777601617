<div
  [class]="
    'w-full progress-bar-animation rounded-[80px] bg-[#1C1C1C0D]' +
    ' ' +
    outerClass
  "
>
  <div
    [class]="className + ' ' + 'max-w-full transition-width duration-[2000ms]'"
    [ngStyle]="{ width: percent + '%' }"
  ></div>
</div>
