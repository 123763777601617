<div class="new-design-round-border text-[#1C1C1C] text-sm p-4">
  <div class="flex flex-col">
    <span>
      {{ "Estimate Settings" | translate }}
    </span>
    <span class="text-xxs">
      {{ "Default settings can be edited on each estimate." | translate }}
    </span>
  </div>
  <form [formGroup]="formGroup" class="flex flex-col gap-4">
    <div
      class="flex flex-col gap-4 items-start rtl:space-x-reverse text-[#1C1C1C66]"
    >
      <div class="flex w-full items-center justify-between gap-3">
        <label for="title2" class="block w-full">{{
          "Title" | translate
        }}</label>
        <input
          id="title2"
          formControlName="title"
          type="text"
          class="new-input !text-black"
          placeholder="{{ 'Estimate' | translate }}"
        />
      </div>
      <div class="flex w-full items-center justify-between gap-3">
        <label for="footer2" class="block w-full">{{
          "Footers" | translate
        }}</label>
        <input
          id="footer2"
          formControlName="footer"
          type="text"
          class="new-input !text-black"
          placeholder="{{ 'Thank you for your business!' | translate }}"
        />
      </div>
      <div class="flex w-full items-center justify-between gap-3">
        <label for="memo" class="block w-full">{{
          "Notes & Terms" | translate
        }}</label>
        <textarea
          id="memo"
          formControlName="memo"
          type="text"
          class="new-input !text-black !h-8"
        ></textarea>
      </div>
    </div>
  </form>
</div>
