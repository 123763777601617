import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { EnrollExpertComponent } from './components/enroll-expert/enroll-expert.component';
import { ExpertApplicationComponent } from './components/expert-application/expert-application.component';
import { ApplicationHistoryComponent } from './components/application-history/application-history.component';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccordionModule } from 'ngx-accordion';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';

const routes: Routes = [
  // {
  //   path: '',
  //   component: EnrollExpertComponent,
  // },
  {
    path: 'expert-application',
    component: ExpertApplicationComponent,
  },
  {
    path: 'expert-application/:type',
    component: ExpertApplicationComponent,
  },
  {
    path: 'application-history',
    component: ApplicationHistoryComponent,
  },
];

@NgModule({
  declarations: [
    EnrollExpertComponent,
    ExpertApplicationComponent,
    ApplicationHistoryComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    FormsModule,
    AccordionModule,
    DirectivesModule,
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    ClickOutsideModule,
  ],
  providers: [DatePipe]
})
export class BecomeExpertModule {}
