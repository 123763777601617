import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessControlDirective, PermissionCheckDirective } from './access-control.directive';
import { FeatureCheckDirective, InventoryCheckDirective, RouteToSubDirective, SubscriptionCheckDirective, SubscriptionDirective } from './subscription.directive';



@NgModule({
  declarations: [
    AccessControlDirective,
    SubscriptionDirective,
    FeatureCheckDirective,
    RouteToSubDirective,
    InventoryCheckDirective,
    SubscriptionCheckDirective,
    PermissionCheckDirective
  ],
  imports: [
    CommonModule
  ],
  exports: [
    AccessControlDirective,
    SubscriptionDirective,
    FeatureCheckDirective,
    RouteToSubDirective,
    InventoryCheckDirective,
    SubscriptionCheckDirective,
    PermissionCheckDirective
  ]
})
export class DirectivesModule { }
