<div subscriptionCheck featureToCheck="recurring" class="min-h-screen">
  <router-outlet></router-outlet>
</div>

<div
  routeToSubscription
  fromComponent="recurring"
  class="hidden absolute bottom-[40%] z-[29] w-[456px] left-0 right-0 m-auto"
>
  <app-send-to-sub from="recurring"></app-send-to-sub>
</div>
