import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { DiscountCodeService } from '../../services/discount-code.service';

@Component({
  selector: 'app-discount-create-edit',
  templateUrl: './dc-create-edit.component.html',
})
export class DiscountCodeCreateEditComponent implements OnInit {
  @Output() discard = new EventEmitter();
  @Input() codeToEdit!: any;
  discountCodeForm: FormGroup;
  formErrors = {
    code: '',
    discountType: '',
    discountValue: '',
    discountCount: '',
    expiryDate: '',
    customerUsageCount: '',
    description: '',
  };
  formErrorMessages = {
    code: {
      required: 'Discount code is required',
    },
    discountType: {
      required: 'Discount type is required',
    },
    discountValue: {
      required: 'Discount value is required',
    },
    discountCount: {
      required: 'Usage limit is required',
    },
    customerUsageCount: {
      required: 'Single customer usage limit is required',
    },
    expiryDate: {
      required: 'Expiry Date is required',
    },
    description: {
      required: 'Description is required',
    },
  };
  tableData: any = [];
  businessId: any;
  business$: Observable<any>;
  editCode = false;
  constructor(
    private fb: FormBuilder,
    private discountCodeService: DiscountCodeService,
    private spinner: NgxSpinnerService,
    private toast: ToastrService,
    private rootStore: Store<RootReducerState>,
    private translateService: TranslateService
  ) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.loadBusiness();
    this.loadDiscountCodeForm();
    if (this.codeToEdit) {
      const expiryDate = this.codeToEdit.expiryDate.split('T')[0];
      this.discountCodeForm.patchValue(this.codeToEdit);
      this.discountCodeForm.get('expiryDate').setValue(expiryDate);
      this.editCode = true;
    }
  }
  loadBusiness(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId) {
        this.businessId = business?.businessId._id;
        // this.loadDiscountCodes(this.businessId);
      }
    });
  }

  loadDiscountCodeForm() {
    this.discountCodeForm = this.fb.group({
      code: [null, [Validators.required]],
      discountType: [null, [Validators.required]],
      discountValue: [null, [Validators.required]],
      discountCount: [null, Validators.required],
      customerUsageCount: [null, Validators.required],
      expiryDate: [null, [Validators.required]],
      description: [null, Validators.required],
    });
    this.discountCodeForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.discountCodeForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.discountCodeForm,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }
  createDiscountCode() {
    if (this.discountCodeForm.invalid) {
      this.discountCodeForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.discountCodeForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    const payload = {
      ...this.discountCodeForm.value,
    };
    this.spinner.show();
    this.discountCodeService
      .createDiscountCode(this.businessId, payload)
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          this.discard.emit();
          this.discountCodeForm.reset();
          this.toast.success(
            this.translateService.instant('Discount code created successfully')
          );
          console.log(response);
        },
        error: (error) => {
          this.spinner.hide();
          console.log(error);
        },
      });
  }
  updateDiscountCode() {
    const payload = {
      ...this.discountCodeForm.value,
    };
    this.spinner.show();
    this.discountCodeService
      .updateDiscountCode(this.codeToEdit._id, payload)
      .subscribe({
        next: (response) => {
          this.spinner.hide();
          this.discard.emit();
          this.editCode = false;
          this.toast.success(
            this.translateService.instant('Discount code updated successfully')
          );
          console.log(response);
        },
        error: (error) => {
          this.spinner.hide();
          console.log(error);
        },
      });
  }
}
