import { CurrencyPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CurrencywithspacePipe } from 'src/app/pipes/currencywithspace.pipe';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { copyToClipBoard } from 'src/app/shared/utils/copyToClipBoard';
import { dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
import { SalesService } from '../../sales.service';
import { selectCustomers } from '../../state/selectors/customers.selector';
import { selectCustomizationSettings } from '../../state/selectors/customizationSettings.selector';
import { CustomerStatementService } from './customer-statement.service';

@Component({
  selector: 'app-customer-statement',
  templateUrl: './customer-statement.component.html',
  styleUrls: ['./customer-statement.component.scss'],
  providers: [CurrencyPipe],
})
export class CustomerStatementComponent implements OnInit, OnDestroy {
  constructor(
    private spinner: NgxSpinnerService,
    private customerStatementService: CustomerStatementService,
    private store: Store<RootReducerState>,
    private fb: FormBuilder,
    private salesService: SalesService,
    private translateService: TranslateService,
    private languageService: LanguageService,
    private toaster: ToastrService,
    private numberService: NumberService,
    private currencyPipe: CurrencyPipe
  ) {
    this.user$ = store.pipe(select(selectUser));
    this.customers$ = this.store.pipe(select(selectCustomers));
    this.business$ = this.store.pipe(select(selectBusiness));
    this.customizationSettings$ = this.store.pipe(
      select(selectCustomizationSettings)
    );
  }

  customers$: Observable<any>;
  business$: Observable<any>;
  customizationSettings$: Observable<any>;
  unsubscribe$ = new Subject();
  customizationSettings;
  business;
  user$: Observable<any>;
  user;
  userId;
  sendStatementModalOpen = false;
  copyButtonText = 'copy';
  shareURL = '';
  baseURL = environment.frontBaseURL;
  config = {
    businessInfo: {
      businessName: '',
      businessAddress: {
        addressLineFirst: null,
        addressLineSecond: null,
        city: null,
        state: null,
        country: null,
      },
      businessLogo: '',
    },
    customerInfo: {
      customerName: '',
      customerFirstName: '',
      customerLastName: '',
    },
    userId: {
      firstName: '',
      lastName: '',
    },
    tableHeadings: [],
    tableData: [],
    tableKeys: [],
    quickStats: [],
    fromDate: null,
    endDate: null,
    type: '',
  };
  customers = [];
  form: FormGroup;
  formErrors = {
    customer: '',
    fromDate: '',
    endDate: '',
  };
  formErrorMessages = {
    customer: {
      required: 'Customer is required',
    },
    fromDate: {
      required: 'Start Date is Required',
    },
    endDate: {
      required: 'Due Date is Required',
      dates: 'Invalid Date',
    },
  };
  emailForm: FormGroup;
  emailFormErrors = {
    email: '',
  };

  emailFormErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Invalid email',
    },
  };
  number = '1.2-2';
  decimalSize = 2;
  currencyDetails = {
    currency: '',
    currencySymbol: '',
  };

  fieldNames = {
    type: 'Invoice',
    estimateNumber: 'Estimate Number',
    date: 'Estimate Date',
    expiryDate: 'Expiry Date',
    purchaseOrder: 'PO Number/SO Number',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Memo',
    invoiceNumber: 'Invoice Number',
    invoiceDate: 'Invoice Date',
    dueDate: 'Due Date',
    status: 'Status',
    paid: 'Paid',
    due: 'Due',
    tableDate: 'Date',
    amount: 'Amount',
    item: 'Item',
    balance: 'Balance',
    billTo: 'Bill To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By',
  };

  showStatement = false;
  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadBusiness();
    this.loadCustomizations();
    this.loadCustomers();
    this.loadForm();
    this.loadEmailForm();
    this.getUserData();
    this.loadNumberConfig();
  }

  loadCustomers(): void {
    this.customers$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((customers) => {
        this.customers = customers?.map((c) => {
          return {
            ...c,
            display: `${c?.mobileNumber} ${
              c?.firstName && c?.lastName
                ? `- ${c?.firstName} ${c?.lastName}`
                : ''
            }`,
          };
        });
      });
  }

  loadNumberConfig(): void {
    this.numberService.number
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number) => {
        this.number = number;
      });
    this.numberService.decimalSize
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((decimalSize) => {
        this.decimalSize = decimalSize;
      });
  }

  getUserData(): void {
    this.user$.subscribe((user) => {
      const { firstName, lastName, _id } = user;
      this.userId = _id;
      this.user = {
        firstName,
        lastName,
      };
    });
  }

  loadEmailForm(): void {
    this.emailForm = this.fb.group({
      email: [
        null,
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });

    this.emailForm.valueChanges.subscribe(() => {
      this.emailFormErrors = valueChanges(
        this.emailForm,
        { ...this.emailFormErrors },
        this.emailFormErrorMessages,
        this.translateService
      );
    });

    this.emailFormErrors = valueChanges(
      this.emailForm,
      { ...this.emailFormErrors },
      this.emailFormErrorMessages,
      this.translateService
    );
  }

  loadCustomizations(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        this.customizationSettings = settings;
      }
    });
  }

  loadBusiness(): void {
    this.business$.subscribe((business) => {
      console.log(business);

      if (business?.businessId?._id) {
        this.business = business?.businessId;
      }
    });
  }

  getCurrencyDetails(): void {
    this.salesService.currencyDetail.subscribe((details) => {
      if (details) this.currencyDetails = details;
    });
  }

  currencier(amount): string {
    const currencyWithSpacePipe = new CurrencywithspacePipe();
    return currencyWithSpacePipe.transform(
      amount,
      this.currencyDetails.currency,
      'narrowSymbol',
      this.number
    );
  }

  loadForm(): void {
    this.form = this.fb.group(
      {
        customer: [null, [Validators.required]],
        type: ['Outstanding Invoices'],
        fromDate: [null],
        endDate: [null],
      },
      { validator: dateLessThan('fromDate', 'endDate') }
    );

    this.form.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.form,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
    });

    this.formErrors = valueChanges(
      this.form,
      { ...this.formErrors },
      this.formErrorMessages,
      this.translateService
    );
  }

  createStatement(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.formErrors = valueChanges(
        this.form,
        { ...this.formErrors },
        this.formErrorMessages,
        this.translateService
      );
      return;
    }
    const formValue = this.form.value;
    console.log(formValue.customer);

    if (formValue.type === 'Outstanding Invoices') {
      this.spinner.show();
      this.customerStatementService
        .loadCustomer(this.form.value.customer._id)
        .subscribe(
          (resp) => {
            this.spinner.hide();
            if (resp.success) {
              this.showStatement = true;
              this.generateStatementData(resp.data);
              this.shareURL = `${this.baseURL}/open/customer-statement/o/${this.business._id}/${this.form.value.customer._id}/${this.form.value.fromDate}/${this.form.value.endDate}/${this.userId}`;
            }
            console.log(resp);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
          }
        );
    }
    if (formValue.type === 'Account Activity') {
      const data = {
        fromDate: formValue.fromDate,
        endDate: formValue.endDate,
        _id: formValue.customer._id,
        businessId: this.business._id,
        accountId: formValue.customer?.accountDetails?.accountId,
      };
      this.spinner.show();
      this.customerStatementService.loadCustomerActivity(data).subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.showStatement = true;
            this.generateActivityData(resp.data);
            this.shareURL = `${this.baseURL}/open/customer-statement/a/${this.business._id}/${this.form.value.customer._id}/${this.form.value.fromDate}/${this.form.value.endDate}/${this.userId}`;
          }
        },
        (error) => {
          this.spinner.hide();
          console.log(error);
        }
      );
    }
  }

  generateStatementData(customerDetails): void {
    let outstandingBalance = 0;
    let overdueAmount = 0;
    let yetToBePaid = 0;
    const tableData = customerDetails.invoices
      .filter((invoice) => {
        if (
          invoice.status.primaryStatus === 'Overdue' ||
          invoice.status.primaryStatus === 'Unpaid'
        ) {
          return invoice;
        }
      })
      .map((invoice) => {
        outstandingBalance += invoice?.dueAmount ?? 0;
        if (invoice.status.primaryStatus === 'Overdue') {
          overdueAmount += invoice?.dueAmount ?? 0;
        }
        if (invoice.status.primaryStatus === 'Unpaid') {
          yetToBePaid += invoice?.dueAmount ?? 0;
        }
        return {
          totalAmount: this.numberService.currencier(invoice?.totalAmount),
          dueAmount: this.numberService.currencier(invoice?.dueAmount),
          paidAmount: this.numberService.currencier(
            invoice?.totalAmount - (invoice?.dueAmount ?? 0)
          ),
          dueDate: moment(invoice.dueDate.split('T')[0]).format('DD-MM-YYYY'),
          invoiceDate: moment(invoice.invoiceDate.split('T')[0]).format(
            'DD-MM-YYYY'
          ),
          invoiceNumber: invoice.invoiceNumber,
          status: invoice.status.primaryStatus,
        };
      });
    this.config = {
      businessInfo: {
        businessName: this.business?.companyName,
        businessAddress: {
          addressLineFirst: this.business?.address?.addressLineFirst,
          addressLineSecond: this.business?.address?.addressLine2,
          city: this.business?.address?.city,
          state: this.business?.address?.state,
          country: this.business?.country,
        },
        businessLogo: this.customizationSettings?.companyLogo,
      },
      customerInfo: {
        customerName: customerDetails?.customer?.customerName,
        customerFirstName: customerDetails?.customer?.firstName,
        customerLastName: customerDetails?.customer?.lastName,
      },
      tableHeadings: [
        'Invoice Number',
        'Invoice Date',
        'Due Date',
        'Status',
        'Total',
        'Paid',
        'Due',
      ],
      tableKeys: [
        'invoiceNumber',
        'invoiceDate',
        'dueDate',
        'status',
        'totalAmount',
        'paidAmount',
        'dueAmount',
      ],
      tableData: tableData,
      fromDate: null,
      endDate: null,
      type: 'Outstanding invoices',
      quickStats: [
        {
          type: this.translateService.instant(`Overdue amount`),
          value: this.numberService.currencier(overdueAmount),
        },
        {
          type: this.translateService.instant('Yet to be paid'),
          value: this.numberService.currencier(yetToBePaid),
        },
        {
          type: this.translateService.instant('Outstanding Balance'),
          value: this.numberService.currencier(outstandingBalance),
        },
      ],
      userId: this.user,
    };
    console.log('Config', this.config);
  }

  generateActivityData(data): void {
    const config = {
      businessInfo: {
        businessName: this.business?.companyName,
        businessAddress: {
          addressLineFirst: this.business?.address?.addressLineFirst,
          addressLineSecond: this.business?.address?.addressLine2,
          city: this.business?.address?.city,
          state: this.business?.address?.state,
          country: this.business?.address?.country,
        },
        businessLogo: this.customizationSettings?.companyLogo,
      },
      customerInfo: {
        customerName: data?.customer?.customerName,
        customerFirstName: data?.customer?.firstName,
        customerLastName: data?.customer?.lastName,
      },
      currencySymbol: this.currencyDetails?.currencySymbol,
      userId: this.user,
      fromDate: moment(this.form.value.fromDate ?? new Date()).format(
        'DD-MM-YYYY'
      ),
      endDate: moment(this.form.value.endDate).format('DD-MM-YYYY') ?? null,
      openingBalnce: data?.openingBalnce?.toFixed(2),
    };

    this.config = this.customerStatementService.generateActivityData(
      config,
      data.invoices
    );
    console.log(this.config);
  }

  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  sendStatementEmail(): void {
    if (this.emailForm.invalid) {
      this.emailForm.markAllAsTouched();
      this.emailFormErrors = valueChanges(
        this.emailForm,
        { ...this.emailFormErrors },
        this.emailFormErrorMessages,
        this.translateService
      );
      return;
    }
    const data = {
      businessId: this.business?._id,
      businessName: this.business.companyName,
      email: this.emailForm.value.email,
      url: this.shareURL,
      ownerId: this.business.userId,
    };
    this.spinner.show();
    this.customerStatementService.sendStatementEmail(data).subscribe(
      (resp) => {
        this.sendStatementModalOpen = false;
        this.emailForm.reset();
        this.spinner.hide();
        console.log(resp);
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  downloadPdf(): void {
    const direction =
      localStorage.getItem('NuMetric|lang') === 'en' ? 'ltr' : 'rtl';
    const body = {
      config: {
        data: {
          ...this.config,
          currencyDetails: {
            currencySymbol: this.currencyDetails.currencySymbol,
          },
        },
        direction: direction,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames),
      },
    };
    this.spinner.show();
    this.customerStatementService.createCustomerStatementPdf(body).subscribe(
      (resp) => {
        this.spinner.hide();
        const a = document.createElement('a');
        const blob = new Blob([resp], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `Customer Statement.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.toaster.success('PDF downloaded');
      },
      (error) => {
        this.spinner.hide();
        this.toaster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
