import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchasesService } from '../../purchases.service';
import * as VendorActions from '../../state/actions/vendors.action';
import { Store } from '@ngrx/store';
import { PurchaseReducerState } from '../../state/reducers';



@Injectable({
  providedIn: 'root'
})
export class VendorsService {


  constructor(private httpService: HttpService,
              private store: Store<PurchaseReducerState>,
              private purchaseServices: PurchasesService) {}
              createVendor(data): Observable<any> {
        return this.purchaseServices.createVendors(data);
    }

    getAllVendors(id): Observable<any> {
      return this.purchaseServices.getAllVendors(id);
    }

    getVendorId(id): Observable<any> {
      return this.purchaseServices.getVendorDetails(id);
    }
    deleteVendor(id): Observable<any> {
      return this.purchaseServices.deleteVendor(id);
    }
    addVendorInStore(vendor): void {
      this.store.dispatch(VendorActions.AddVendor({vendor}));
    }
    updateVendorInStore(vendor): void {
     this.store.dispatch(VendorActions.UpdateVendor({vendor})); 
    }
    updateVendor(body): Observable<any> {
      return this.purchaseServices.updateVendor(body);
    }

}
