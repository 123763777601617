  import * as fromProducts from '../reducers/products.reducer';
  import * as fromCustomers from '../reducers/customers.reducer';
  import * as fromSalesTaxes from '../reducers/salesTax.reducer';
  import * as fromCustomerizationSettings from '../reducers/customizationSettings.reducer';
  import * as fromRoot from '../../../../store/reducers';

  export interface SalesReducerState {
    products: fromProducts.ProductsState;
    customers: fromCustomers.CustomersState;
    salesTaxes: fromSalesTaxes.SalesTaxesState;
    customizationSettings: fromCustomerizationSettings.CustomizationSettingsState;
  }

  export interface State extends fromRoot.RootReducerState {
    sales: SalesReducerState;
  }
  export const salesReducerMap = {
    products: fromProducts.reducer,
    customers: fromCustomers.reducer,
    salesTaxes: fromSalesTaxes.reducer,
    customizationSettings: fromCustomerizationSettings.reducer
  };

  export const getProducts = (state: SalesReducerState) => state.products;
  export const getSalesTaxes = (state: SalesReducerState) => state.salesTaxes;
  export const getCustomers = (state: SalesReducerState) => state.customers;
  export const getCustomizationSettings = (state: SalesReducerState) => state.customizationSettings;