import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchasesService } from '../../purchases.service';



@Injectable({
  providedIn: 'root'
})
export class AssetsService {


  constructor(private httpService: HttpService,
              private purchaseServices: PurchasesService) {}
    createAsset(data): Observable<any> {
        return this.purchaseServices.createAssets(data);
    }

    getAllAssets(id): Observable<any> {
      return this.purchaseServices.getAllAssets(id);
    }

    getVendorId(id): Observable<any> {
      return this.purchaseServices.getVendorDetails(id);
    }
    deleteAsset(id): Observable<any> {
      return this.purchaseServices.deleteAsset(id);
    }

    updateAsset(body, assetId): Observable<any> {
      return this.purchaseServices.updateAssets(body, assetId);
    }

}
