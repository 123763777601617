<div class="new-design-round-border text-[#1C1C1C] p-4 flex flex-col gap-3">
  <div class="text-sm">
    {{ "change column message" | translate }}
  </div>
  <div class="flex flex-col gap-2">
    <div class="gap-1" *ngFor="let column of columns">
      <span class="text-base">
        {{ column.name | translate }}
      </span>
      <div
        [formGroup]="columnSettingsForm"
        class="flex gap-4 w-full items-center justify-start"
      >
        <ng-select
          [items]="column.options"
          class="pt-2 text-sm new-dropdown text-[#1C1C1C]"
          style="width: 82%"
          [formControlName]="column.key"
          [searchable]="false"
          [clearable]="false"
          placeholder="{{'Select One' | translate}}"
          [multiple]="false"
        >
          <ng-template ng-option-tmp let-item="item" let-index="index">
            <div class="px-2 py-1">
              <div
                class="cursor-pointer text-[#1C1C1C] p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
              >
                {{ item }}
              </div>
            </div>
          </ng-template>
        </ng-select>
      </div>
    </div>
  </div>
</div>
