import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { TimeSheetRoutingModule } from './time-sheet-routing.module';
import { TimeSheetComponent } from './time-sheet.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { RegisterHoursComponent } from './components/register-hours/register-hours.component';
import { HoursReportComponent } from './components/hours-report/hours-report.component';
import { EmployeesReportComponent } from './components/employees-report/employees-report.component';
import { WeekPaginatorComponent } from './components/register-hours/components/week-paginator/week-paginator.component';
import { RegisterHoursFormComponent } from './components/register-hours/components/register-hours-form/register-hours-form.component';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxPaginationModule } from 'ngx-pagination';

@NgModule({
  declarations: [
    TimeSheetComponent,
    WeekPaginatorComponent,
    RegisterHoursFormComponent,
    RegisterHoursComponent,
    HoursReportComponent,
    EmployeesReportComponent,
  ],
  imports: [
    CommonModule,
    TranslateModule,
    SharedModule,
    TimeSheetRoutingModule,
    FormsModule,
    NgxPaginationModule,
    NgSelectModule,
    ReactiveFormsModule,
    ClickOutsideModule
  ],
})
export class TimeSheetModule {}
