<div class="flex w-full gap-1">
  <div
    class="border px-4 flex items-center bg-[#F7F9FA] rounded-l-xl cursor-pointer rtl:rotate-180"
    (click)="paginatorClick(previous)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      class="w-6"
      fill="#48484A"
    >
      <path d="M560-240 320-480l240-240 56 56-184 184 184 184-56 56Z" />
    </svg>
  </div>
  <div
    class="paginate"
    *ngFor="let item of weekDays; let idx = index"
    (click)="handleActive(item.timeSheetDate)"
    [ngClass]="{ active: item.active }"
  >
    <span class="Poppins-Medium"> {{ item.day }} </span>
    <span>
      {{ item.dailyTotalWorked  | timeFormat }}
    </span>
  </div>

  <div
    class="border px-4 flex items-center bg-[#F7F9FA] rounded-r-xl cursor-pointer rtl:rotate-180"
    (click)="paginatorClick(next)"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="24px"
      viewBox="0 -960 960 960"
      class="w-6"
      fill="#48484A"
    >
      <path d="M504-480 320-664l56-56 240 240-240 240-56-56 184-184Z" />
    </svg>
  </div>
</div>
