<div class="p-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
    <h3 class="Poppins-Bold text-lg">{{'Email Notifications' | translate }}</h3>
    <p *ngIf="user$ | async as user" class="mt-3">{{'Email privacy message' | translate }} {{user.email}}.</p>
    <p class="mt-3">{{'Email notification setting message' | translate }}</p>

    <div [formGroup]='notificationsForm' class="space-y-2">
        <div class="flex items-center">
            <input formControlName='sales' id='sales' type="checkbox" class="mr-3 form-radio">
            <label for='sales' class="items-center mb-3 mt-3">
                <div class="block Poppins-Medium">{{'Sales' | translate }}</div>
                <p class="font-color-black-05 Poppins-Medium">When accounting and bookkeeping transactions need your attention.</p>
            </label>
        </div>
        <div class="flex items-center">
            <input formControlName='purchase' id='Purchases' type="checkbox" class="mr-3 form-radio">
            <label for='Purchases' class="items-center mb-3 mt-3">
                <div class="block Poppins-Medium">{{'Purchases' | translate }}</div>
                <p class="font-color-black-05 Poppins-Medium">When accounting and bookkeeping transactions need your attention.</p>
            </label>
        </div>
        <div class="flex items-center">
            <input formControlName='accounting' id='Accounting' type="checkbox" class="mr-3 form-radio">
            <label for='Accounting' class="items-center mb-3 mt-3">
                <div class="block Poppins-Medium">{{'Accounting' | translate }}</div>
                <p class="font-color-black-05 Poppins-Medium">When accounting and bookkeeping transactions need your attention.</p>
            </label>
        </div>
        <div class="flex items-center">
            <input formControlName='payment' id='Payments' type="checkbox" class="mr-3 form-radio">
            <label for='Payments' class="items-center mb-3 mt-3">
                <div class="block Poppins-Medium">{{'Payments' | translate }}</div>
                <p class="font-color-black-05 Poppins-Medium">When accounting and bookkeeping transactions need your attention.</p>
            </label>
        </div>
        <div class="flex items-center gap-x-2">
            <app-button buttonText='{{"Update" | translate}}' type='primary' [rounded]='true' (handleClick)='updateNotifications()'></app-button>
            <span class="text-red-500" *ngIf='error'>{{error}}</span>
            <span class="text-green-500" *ngIf='response'>{{response}}</span>
        </div>
    </div>

</div>