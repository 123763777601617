import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router} from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { PurchasesService } from 'src/app/modules/purchases/purchases.service';
import { VendorsReducer } from 'src/app/modules/purchases/state/reducers/vendors.reducer';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { UpdateTransactionLimit } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { BillsService } from '../../bills.services';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-bills-list',
  templateUrl: './bills-list.component.html',
  styleUrls: ['./bills-list.component.scss']
})
export class BillsListComponent implements OnInit, OnDestroy {

  constructor(private purchaseService: PurchasesService,
              private billsService: BillsService,
              private router: Router,
              private fb: FormBuilder,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private fileUploadService: FileUploadService,
              private numberService: NumberService,
              private rootStore: Store<RootReducerState>) {
                this.vendors$ = rootStore.pipe(select(selectedVendors));
                this.projects$ = rootStore.pipe(select(selectProjects));
                this.usage$ = rootStore.pipe(select(selectUsage));
               }

  unsubscribe = new Subject();
  vendors$: Observable<any>;
  usage$: Observable<any>;
  transactionLimit;
  vendors = [];
  projects$: Observable<any>;
  projects = [];
  deleteOpenModal = false;
  recordPaymentModal = false;
  recordPaymentFor = null;
  amountDue = 0;
  businessId;
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  billToDelete = '';
  filterStatus = 'all';
  unfilteredList = [];
  filterForm: FormGroup;
  tableHeadings = [
    'Date',
    'Number',
    'Vendor',
    'Due Amount',
    'Total Amount',
    'Actions'
  ];
  tableKeys = ['date', 'billNumber', 'vendor', 'dueAmount', 'totalAmount'];
  tableData = [];
  formErrors = {
    fromDate  : '',
    endDate: ''
  };

  formErrorMessages = {
    fromDate: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date'
    },
    endDate: {
      required: 'Due Date is Required',
      invalidDate: 'Invalid Date',
      dates: 'Invalid Date'
    }
  };
  minDate = null;
  ngOnInit(): void {
    this.getBusinessId();
    this.getCurrencySymbol();
    this.getTransactionLimit();
    this.loadBillsList();
    this.loadVendors();
    this.loadProjects();
    this.loadFilterForm();
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe)))
    .subscribe((number) => {
      this.number = number
    })
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage}) => {
      if (currentUsage){
        const { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    });
  }

  loadVendors(): void {
    this.vendors$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(vendors => {
      this.vendors = vendors;
    });
  }
  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(projects => {
      this.projects = projects;
    });
  }
  getCurrencySymbol(): void {
    this.purchaseService.currencyDetails
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(details => {
      if (details) {
        this.currencyDetails = details;
      }
    });
  }

  loadBillsList(): void {
    this.purchaseService.businessId$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((id) => {
      if (id) {
        this.spinner.show();
        this.billsService.getAllBills(id).subscribe(resp => {
          this.spinner.hide();
          if (resp?.success){
            this.unfilteredList = resp.data;
            this.tableData = this.mapBillsListForTable(resp.data).reverse();
          }
        }, (error) => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }
  deleteBill(id): void {
    this.billToDelete = id;
    this.deleteOpenModal = true;
  }

  deleteBillslist(): void {
    this.spinner.show();
    this.billsService.deleteBill(this.billToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.deleteOpenModal = false;
      if (resp?.success){
        this.rootStore.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit - 1}));
        this.tableData = this.tableData.filter(({_id}) => _id !== this.billToDelete);
        this.toastr.success(resp?.message);
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  editBill(id): void {
    this.router.navigate(['/purchases/bills/create-bill'], { queryParams: { id } });
  }
  viewBill(id): void {
    this.router.navigate(['/purchases/bills/view-bill'], { queryParams: { id } });
  }

  openRecordPayment(item): void {
    this.recordPaymentModal = true;
    this.amountDue = Number(item.dueAmount);
    this.recordPaymentFor = item;
    this.minDate = item.date;
  }

  recordPayment(paymentData): void {
    const billData = this.unfilteredList.find((bill) => bill._id === this.recordPaymentFor._id);
    const data = {
      _id: this.recordPaymentFor._id,
      businessId: this.businessId,
      category: `Journal Entry`,
      paymentData,
      journalData: [
        {
          description: 'For vendor debit',
          businessId: this.businessId,
          accountId: billData?.vendor?.vendorId?.accountDetails?.accountId,
          accountName: billData?.vendor?.vendorId?.accountDetails?.accountName,
          account: billData?.vendor?.vendorId?.accountDetails?.accountSection,
          debit: paymentData.amount,
          credit: 0,
          type: 'Debit'
        },
        {
          description: 'Payment',
          businessId: this.businessId,
          accountId: paymentData.accountId,
          accountName: paymentData.accountName,
          account: paymentData.account,
          debit: 0,
          credit: paymentData.amount,
          type: 'Credit'
        }
      ]
    };
    console.log(data);
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    paymentData?.files?.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(data));
    this.billsService.recordPayment({formData, _id: data?._id}).subscribe(resp => {
      this.spinner.hide();
      this.recordPaymentModal = false;
      if (resp.success) {
        this.tableData = this.tableData.map((bill) => {
          if (bill._id === resp.data._id) {
            bill = {
              ...resp.data,
              date: resp?.data?.date.split('T')[0],
              vendor: resp?.data?.vendor?.vendorName,
              dueAmount: this.numberService.currencier(resp.data?.dueAmount),
              totalAmount: this.numberService.currencier(resp.data?.totalAmount),
            };
          }
          return bill;
        });
          this.rootStore.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}));
        this.toastr.success(resp?.message);
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group({
      vendorId: [],
      projectId: [],
      fromDate: [null, DateValidator()],
      endDate: [null, DateValidator()]
    }, {validator: dateLessThan('fromDate', 'endDate')});

    this.filterForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }

  getBusinessId(): void{
    this.purchaseService.businessId$.subscribe(id => {
      this.businessId = id;
    });
  }

  filterData(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }
    const body = this.filterForm.value;
    this.spinner.show();
      this.billsService.filterBills(this.businessId, body).subscribe((resp) => {
        this.spinner.hide();
        this.unfilteredList = [...resp.data];
        this.tableData = this.mapBillsListForTable(resp.data);
      }, error => {
        this.spinner.hide();
    });

    // this.invoiceData.
  }

  mapBillsListForTable(billsList): Array<object> {
    return [...billsList.map(bill => ({
      ...bill,
      date: moment(bill?.date?.split('T')[0]).format('DD-MM-YYYY'),
      vendor: bill?.vendor?.vendorName,
      projects: bill?.projectDetails?.projectId,
      dueAmount: this.numberService.currencier(bill?.dueAmount),
      totalAmount: this.numberService.currencier(bill?.totalAmount),
    }))];
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }
}
