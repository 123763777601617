<div class="expert-details-container">
  <div class="details-header flex justify-between">
    <span>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="w-6 h-6 inline cursor-pointer ltr:rotate-0 rtl:rotate-180"
        routerLink="/experts/hire-expert"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
        />
      </svg>

      <h3 class="text-xl Poppins-SemiBold inline align-middle ml-1">
        {{ "Expert Details" | translate }}
      </h3>
    </span>
    <app-button
      type="primary"
      (click)="enquiryNow()"
      buttonText="{{ 'Enquire Now' | translate }}"
    ></app-button>
  </div>
  <div class="details-content mt-4 border-t-2 border-[#EAEAEA]">
    <div class="name-badge flex justify-between py-4">
      <div class="flex gap-2">
        <img
          class="h-28 w-28 rounded-full"
          src="{{ environmentPath }}{{ expertDetail?.profileImage }}"
          alt=""
        />
        <div class="name-contact flex justify-center flex-col gap-2">
          <h4 class="flex Poppins-SemiBold gap-2">
            {{ expertDetail?.firstName + " " + expertDetail?.lastName }}
            <!-- <img class="w-5 h-5" src="/assets/images/india-flag.png" alt="" /> -->
          </h4>
          <p class="flex text-sm items-center opacity-50 gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
              />
            </svg>
            {{ expertDetail?.email }}
          </p>
          <p class="flex text-sm items-center opacity-50 gap-1">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="currentColor"
              class="w-4 h-4"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
              />
            </svg>
            {{ expertDetail?.contactNumber }}
          </p>
        </div>
      </div>
      <div class="badge flex items-center justify-end w-[20%]">
        <img
          class="badge-image min-h-[4.5rem] w-36 max-w-[8rem]"
          [src]="'/assets/images/' + expertBadge + '.jpeg'"
          alt=""
        />
      </div>
    </div>
    <div class="details-card flex gap-3">
      <div class="about-corporate w-1/2">
        <div class="card-background min-h-[164px]">
          <h2 class="text-xl Poppins-SemiBold">{{ "About" | translate }}</h2>
          <p class="text-xs opacity-50 mt-2">
            {{ expertDetail?.description }}
          </p>
        </div>
        <div class="card-background">
          <h2 class="text-xl Poppins-SemiBold">
            {{ "Corporate Information" | translate }}
          </h2>
          <div class="flex flex-col gap-4">
            <div>
              <span class="text-xs opacity-40">{{
                "Profile" | translate
              }}</span>
              <img
                class="h-20 w-20 rounded-[50%]"
                src="{{ environmentPath }}{{ expertDetail?.companyImage }}"
                alt=""
              />
            </div>
            <div>
              <span class="text-xs opacity-40">{{
                "Company Name" | translate
              }}</span>
              <p class="text-sm Poppins-Medium">
                {{ expertDetail?.companyName }}
              </p>
            </div>
            <div class="min-h-[90px]">
              <span class="text-xs opacity-40">{{ "About" | translate }}</span>
              <p class="text-xs Poppins-Medium">
                {{ expertDetail?.aboutCompany }}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="contact-language w-1/2">
        <div class="card-background min-h-[164px]">
          <h2 class="text-xl Poppins-SemiBold">
            {{ "Contact Information" | translate }}
          </h2>
          <div class="flex flex-col gap-2">
            <p class="flex text-xs items-center opacity-50 gap-1 leading-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                />
              </svg>
              {{ expertDetail?.email }}
            </p>
            <p class="flex text-xs items-center opacity-50 gap-1 leading-6">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-4 h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
                />
              </svg>
              {{ expertDetail?.contactNumber }}
            </p>
            <p class="flex text-xs items-center opacity-50 gap-1 leading-6">
              <img src="/assets/images/location.svg" class="w-4 h-4" alt="" />
              {{ expertDetail?.address }}
            </p>
          </div>
        </div>
        <div class="card-background">
          <h2 class="text-xl Poppins-SemiBold">
            {{ "Specializations" | translate }}
          </h2>
          <div class="flex gap-3 mt-2">
            <span
              *ngFor="let item of expertDetail?.specialization"
              class="w-[105px] p-4 h-10 rounded-xl bg-white flex items-center justify-center text-sm Poppins-Bold"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
        <div class="card-background">
          <h2 class="text-xl Poppins-SemiBold">{{ "Language" | translate }}</h2>
          <div class="flex gap-3 mt-2">
            <span
              *ngFor="let item of expertDetail?.language"
              class="w-[105px] h-10 rounded-xl bg-white flex items-center justify-center text-sm Poppins-Bold"
            >
              {{ item.name }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-container class="small" [isOpen]="openEnquiryModal">
  <div header class="border-b pb-2 Poppins-SemiBold text-xl">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke-width="1.5"
      stroke="currentColor"
      class="w-5 h-6 inline cursor-pointer"
      (click)="closeModal()"
    >
      <path
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M19.5 12h-15m0 0l6.75 6.75M4.5 12l6.75-6.75"
      />
    </svg>
    {{ "Raise an Enquiry" | translate }}
  </div>
  <div content>
    <div class="w-full" [formGroup]="enquiryForm">
      <div class="flex">
        <div class="rounded-xl py-2 w-[48%]">
          <label
            for="firstName"
            class="block text-xs mb-2 opacity-50 font-color-05"
            >{{ "First Name" | translate }}</label
          >
          <input
            id="firstName"
            formControlName="firstName"
            class="w-full h-12 opacity-50 rounded-lg p-4 border border-[#e6e6e6] focus:ring-0 placeholder-gray-200"
            type="text"
            placeholder="{{ 'Enter First Name' | translate }}"
          />
          <span
            class="text-red-500 text-sm"
            *ngIf="enquiryFormErrors?.firstName"
            >{{ enquiryFormErrors.firstName }}</span
          >
        </div>
        <div class="rounded-xl ltr:ml-4 rtl:mr-4 py-2 w-[48%]">
          <label
            for="lastName"
            class="block text-xs mb-2 opacity-50 font-color-05"
            >{{ "Last Name" | translate }}</label
          >
          <input
            id="lastName"
            formControlName="lastName"
            class="w-full h-12 opacity-50 rounded-lg p-4 border border-[#e6e6e6] focus:ring-0 placeholder-gray-200"
            type="text"
            placeholder="{{ 'Enter Last Name' | translate }}"
          />
          <span
            class="text-red-500 text-sm"
            *ngIf="enquiryFormErrors?.lastName"
            >{{ enquiryFormErrors.lastName }}</span
          >
        </div>
      </div>
      <div class="rounded-xl w-full py-2">
        <label
          for="email"
          class="block text-xs mb-2 opacity-50 font-color-05"
          >{{ "Email Address" | translate }}</label
        >
        <input
          id="email"
          formControlName="email"
          class="w-full h-12 opacity-50 rounded-lg p-4 border border-[#e6e6e6] focus:ring-0 placeholder-gray-200"
          type="text"
          placeholder="{{ 'Enter email address' | translate }}"
        />
        <span class="text-red-500 text-sm" *ngIf="enquiryFormErrors?.email">{{
          enquiryFormErrors.email
        }}</span>
      </div>
      <div class="rounded-xl w-full py-2">
        <label
          for="phone"
          class="block text-xs mb-2 opacity-50 font-color-05"
          >{{ "Phone No." | translate }}</label
        >
        <div class="flex gap-2 enquiry-modal">
          <ng-select
            class="custom-selector border-0 p-0 w-[45%]"
            placeholder="Code"
            [items]="countryCodeList"
            bindLabel="code"
            formControlName="code"
          >
          </ng-select>
          <input
            id="phone"
            formControlName="phone"
            class="w-full h-12 rounded-lg p-4 border border-[#e6e6e6] focus:ring-0 placeholder-gray-200"
            type="number"
            placeholder="{{ 'Enter phone number' | translate }}"
          />
        </div>
        <span
          class="text-red-500 text-sm"
          *ngIf="enquiryFormErrors?.phone || enquiryFormErrors?.code"
          >{{ enquiryFormErrors.phone || enquiryFormErrors?.code }}</span
        >
      </div>
      <div class="mt-4">
        <label
          for="description"
          class="block text-xs mb-2 opacity-50 font-color-05"
          >{{ "Description" | translate }}</label
        >
        <textarea
          tabindex="5"
          id="description"
          formControlName="description"
          style="white-space: pre-wrap;"
          class="w-full h-36 bg-white border border-[#e6e6e6] rounded-lg p-4 focus:ring-0 placeholder-gray-200"
          placeholder="{{ 'Enter Description' | translate }}"
        ></textarea>
        <span
          class="text-red-500 text-sm"
          *ngIf="enquiryFormErrors?.description"
          >{{ enquiryFormErrors.description }}</span
        >
      </div>
    </div>
  </div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      buttonText="{{ 'Submit' | translate }}"
      type="primary"
      rounded="true"
      (handleClick)="submitEnquiry()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="closeModal()"
    ></app-button>
  </div>
</app-modal-container>
