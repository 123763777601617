import { Injectable } from '@angular/core';
import { HttpService } from 'src/app/shared/services/http.service';
@Injectable({
  providedIn: 'root'
})
export class TaskServiceService {

  constructor(private http : HttpService) { }
   
  createTask(businessId:any,payload:any) {
    return this.http.postMultipart(`/task/create-task/${businessId}`,payload)
  }

  getAllTask(businessId:any) {
    return this.http.get(`/task/all-task/${businessId}`)
  }

  viewTask(taskId:any) {
    return this.http.get(`/task/view-task/${taskId}`)
  }

  updateTask(taskId:any , payload:any) {
    return this.http.putMultipart(`/task/update-task/${taskId}`, payload)
  }
  
  updateRecurringTask(taskId:any , payload:any) {
    return this.http.putMultipart(`/task/update-recurring-task/${taskId}`, payload)
  }

  addCommentInTask(taskId:any, payload:any) {
    return this.http.patch(`/task/add-comment-in-task/${taskId}`,payload)
  }
  getAllBusinessUsers(id) {
    return this.http.get(`/users/getBusinessUsers/${id}`)
  }

  deleteTask(id) {
    return this.http.get(`/task/delete-task/${id}`)
  }

  deleteRecurringTask(id) {
    return this.http.get(`/task/delete-recurring-task/${id}`)
  }

  deleteFile(fileId:any) {
    return this.http.delete(`/storage/deleteDocument/${fileId}`)
  }
  markAsCompleted(taskId:any,payload:any) {
    return this.http.patch(`/task/mark-as-completed/${taskId}`, payload)
  }

  addEmailSettings(userId:any, payload:any) {
    return this.http.patch(`/task/add-email-settings/${userId}`, payload)
  }
  
  addReminder(taskId:any,payload:any) {
    return this.http.patch(`/task/add-reminder/${taskId}`,payload)
  }
}
