import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { AuthService } from '../../auth.service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-otp-verify',
  templateUrl: './otp-verify.component.html',
  styleUrls: ['./otp-verify.component.scss']
})
export class OtpVerifyComponent implements OnInit, OnDestroy {

  unsubscribe$ = new Subject();

  otp = new FormControl('', [Validators.required]);
  userId: string = '';

  constructor(private authService: AuthService, private route: ActivatedRoute,
    private router: Router, private spinner: NgxSpinnerService,
    private toast: ToastrService
  ) { }

  ngOnInit(): void {
    this.route.queryParams
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(({userId}) => {
      if(userId){
        this.userId = userId
      }
    })
  }

  verifyOtp(): void {
    this.spinner.show();
    this.authService.verifyOtp(this.otp.value)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      console.log(resp);
      if(resp?.success){
        this.authService.onLoginSuccess({...resp.data, token: resp.token, refreshtoken: resp.refreshtoken})
      }
      else{
        this.toast.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toast.error(err?.error?.message);
      this.spinner.hide();
    })
    
  }

  resendOtp(): void {
    this.spinner.show();
    this.authService.resendOtp(this.userId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      console.log((resp));
      if(resp?.success){
        this.toast.success(resp?.message);
      }
      else{
        this.toast.error(resp?.message)
      }
      this.spinner.hide()
    }, (err) => {
      this.toast.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
