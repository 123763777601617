import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { ExpensesListComponent } from './components/expenses-list/expenses-list.component';
import { CreateExpensesComponent } from './components/create-expenses/create-expenses.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { AccountingRoutingModule } from 'src/app/modules/accounting/accounting-routing.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { AccountingModule } from 'src/app/modules/accounting/accounting.module';


const routes: Routes = [
  {
    path: '',
    component: ExpensesListComponent
  },
  {
    path: 'expenses-list',
    component: ExpensesListComponent
  },
  {
    path: 'create-expense',
    component: CreateExpensesComponent
  }
];

@NgModule({
  declarations: [
    ExpensesListComponent,
    CreateExpensesComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    SharedModule,
    NgSelectModule,
    FormsModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    DirectivesModule
  ]
})
export class ExpensesModule { }
