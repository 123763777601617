import { AfterViewInit, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ReportsService } from '../../reports.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-reports-view',
  templateUrl: './reports-view.component.html',
  styleUrls: ['./reports-view.component.scss']
})
export class ReportsViewComponent implements OnInit, OnDestroy, AfterViewInit {

  constructor(private reportsService: ReportsService, private router: Router, private cdRef:ChangeDetectorRef) { }

  @Input() config = {
    tableHeadings: [],
    tableData: [
      {
        key1: '',
        key2: ''
      }
    ],
    tableKeys: [
      'key1', 'key2'
    ],
    templateName: 'normal'
  };
  unsubscribe = new Subject();
  currencyDetails = null;
  number = '1.2-2';
  transactionReport: boolean;

  @ViewChild('normal') normal: TemplateRef<any>;
  @ViewChild('accountTransactions') accountTransactions: TemplateRef<any>;
  @ViewChild('salesTaxes') salesTaxes: TemplateRef<any>;
  @ViewChild('assets') assets: TemplateRef<any>;
  @ViewChild('cash') cash: TemplateRef<any>;
  @ViewChild('cashierSales') cashierSales: TemplateRef<any>;
  ngOnInit(): void {
    this.loadCurrencyDetails();
    console.log("router",);
    this.transactionReport = this.router.url.includes('trial-balances' || 'partial-trial-balances')
    
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  loadCurrencyDetails(): void {
    this.reportsService.currencyDetails$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe(details => {
      if (details) {
        this.currencyDetails = details;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
