import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import * as moment from 'moment';
import { DateValidator } from 'src/app/shared/utils/formValidator';

@Component({
  selector: 'app-reports-filter-form',
  templateUrl: './reports-filter-form.component.html',
  styleUrls: ['./reports-filter-form.component.scss'],
  exportAs: 'FilterForm'
})
export class ReportsFilterFormComponent implements OnChanges, OnInit {

  constructor() { }

  @Input() title;
  @Output() formData = new EventEmitter();
  @Input() lastDate = true;
  @Input() asOfDate = true;
  @Input() accountsFilter = false;
  @Input() projectsFilter = false;
  @Input() contactsFilter = false;
  @Input() depreciationFilter = false;
  @Input() assetsAccountFilter = false;
  @Input() itemFilter = false;
  @Input() cashierFilter = false;
  @Input() partialTrialFilter = false;
  @Input() salesTaxTransaction = false;
  @Input() accounts = [];
  @Input() cashiers = [];
  @Input() projects = [];
  @Input() contacts = [];
  @Input() products = [];
  @Input() categories = [];
  @Input() assetCategory = [];
  @Input() assetSubCategory = [];
  @Input() assetAccounts = [];
  @Input() allTaxes = [];
  @Input() selectedProduct = '';
  filteredSubCategory: any;

  @Output() download = new EventEmitter();

  startDate = new FormControl(moment().startOf('year').format('YYYY-MM-DD'), [Validators.required, DateValidator()]);
  endDate = new FormControl(moment().format('YYYY-MM-DD'), [Validators.required, DateValidator()]);
  account = new FormControl(null);
  cashierEmail = new FormControl(null);
  project = new FormControl(null);
  contact = new FormControl(null);
  item = new FormControl(null);
  withDepreciation = new FormControl(true);
  assetId = new FormControl(null);
  assetValue = new FormControl(null);
  subAssetValue = new FormControl(null);
  selectedTax = new FormControl(null);

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['selectedProduct']?.firstChange && changes['selectedProduct']?.currentValue){
      this.item.setValue(changes['selectedProduct']?.currentValue);
      console.log(changes['selectedProduct'], 'oncahnges');
    }
  }

  ngOnInit(): void {    
    this.emitFormData();
    console.log("assetCategory", this.assetCategory);
    
  }

  emitFormData(): void {
    const data = {
      startDate: this.startDate.value,
      endDate: this.endDate.value,
      projectId: this.project.value,
      accountId: this.account.value,
      customerId: this.contact.value,
      itemId: this.item.value,
      withDepreciation: this.withDepreciation.value,
      assetAccountId: this.assetId.value,
      cashierEmail : this.cashierEmail.value,
      assetValue : this.assetValue.value,
      subAssetValue : this.subAssetValue.value,
      selectedTax : this.selectedTax.value,
    };
    if (!this.lastDate) {
      data.startDate = moment().subtract(30, 'years').format('YYYY-MM-DD');
    }
    this.formData.emit(data);
  }

  downloadPdf(): void {
    this.download.emit()
  }

  filterSubCategory(event): void {
    // console.log(event, this.assetSubCategory);
    // if(!event) {
    //   return;
    // }
    this.subAssetValue.setValue(null);
    this.filteredSubCategory = this.assetSubCategory.filter((item)=>item.accountSection === event);
    console.log(this.filteredSubCategory);

  }

  subCategoryChange(event): void {
    console.log(event);
    if(!event){
      this.assetValue.setValue(null);
    }
  }

}
