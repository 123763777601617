import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { updateCurrentStoreLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectStore } from 'src/app/store/selectors/store.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-store-list',
  templateUrl: './store-list.component.html',
})
export class StoreListComponent implements OnInit {
  constructor(
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private businessService: BusinessService
  ) {
    this.businesses$ = this.store.pipe(select(selectBusiness));
    this.stores$ = store.pipe(select(selectStore));
    this.user$ = this.store.pipe(select(selectUser));
    this.usage$ = store.pipe(select(selectUsage));
  }

  storeLimit;
  businesses$: Observable<any>;
  stores$: Observable<any>;
  usage$: Observable<any>;
  businessId;
  storeId = null;
  @Output()
  editStore = new EventEmitter();
  openDeleteModal: boolean = false;
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  userId = '';
  tableHeadings = [
    { name: 'Store Name' },
    { name: 'Store Address' },
    { name: 'Country' },
  ];
  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userId = user._id;
    });
    this.getStores();
    this.getBusinessLimit();
  }

  getBusinessLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const { storeLimit } = currentUsage;
        this.storeLimit = storeLimit;
      }
    });
  }

  getStores(): void {
    this.stores$.pipe(takeUntil(this.unsubscribe$)).subscribe(
      (stores) => {},
      (err) => {
        this.spinner.hide();
        this.toastr.error(err?.message || 'Something went wrong!');
      }
    );
  }

  deleteStore(): void {
    this.spinner.show();
    this.businessService
      .deleteStore(this.storeId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp?.success) {
            this.spinner.hide();
            this.toastr.success(resp?.message);
            this.businessService.deleteStoreFromStore({
              storeId: this.storeId,
            });
            console.log(this.storeLimit);
            this.store.dispatch(
              updateCurrentStoreLimit({ storeLimit: this.storeLimit - 1 })
            );
            this.openDeleteModal = false;
            this.storeId = null;
          } else {
            this.spinner.hide();
            this.toastr.error(resp?.message);
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message);
        }
      );
  }

  showDeleteModal(storeId): void {
    this.storeId = storeId;
    this.openDeleteModal = true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
