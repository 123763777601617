import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import * as cc from 'currency-codes';
import { getAllAccountType } from 'src/app/shared/utils/accountType';
import { valueChanges } from 'src/app/shared/utils/formValidator';

@Component({
  selector: 'app-account-form',
  templateUrl: './account-form.component.html',
  styleUrls: ['./account-form.component.scss']
})

export class AccountFormComponent implements OnInit, OnChanges {
  constructor(private fb: FormBuilder,private translateService: TranslateService) {}

  accountForm: FormGroup;
  @Input() data;
  @Input() accountData;
  @Input() isOpen;
  @Input() update;
  @Output() accountFormValue = new EventEmitter();
  @Output() closeAccountForm = new EventEmitter();
  accountTypes;
  currenySelect;

  formErrors = {
    accountType: '',
    accountName: '',
  };
  

  errorMessages = {
    accountType: {
      required: 'Account Type is required',
    },
    accountName: {
      required: 'Account Name is required',
    }
  };

  ngOnInit(): void {
    this.loadForm();
    this.loadData();
  }

  ngOnChanges(changes: SimpleChanges): void{
    if (changes?.data?.currentValue) {
      this.accountForm.get('accountType').setValue(changes?.data?.currentValue);
    }
    if (changes?.accountData?.currentValue) {
      const accountEditData = changes?.accountData?.currentValue;
      const data = {
        accountType: {
          accountType: accountEditData?.accountType,
          section: accountEditData?.accountSection
        },
        accountName: accountEditData?.accountName ?? null,
        description: accountEditData?.description ?? null,
        accountId: accountEditData?.accountId ?? null,
        isArchive: accountEditData?.isArchive ?? false
      };
      this.accountForm.setValue(data);
    }
  }

  loadForm(): void{
    this.accountForm = this.fb.group({
      accountType: [null, [Validators.required]],
      accountName: [null, [Validators.required]],
      accountId: [null],
      description: [null],
      isArchive: [false]
    });
    this.accountForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.accountForm, { ...this.formErrors }, this.errorMessages, this.translateService);
    });
  }

  loadData(): void {
    this.currenySelect = cc.codes();
    this.accountTypes = getAllAccountType();
  }

  addAccount(): void{
    if (this.accountForm.invalid){
      this.accountForm.markAllAsTouched();
      this.formErrors = valueChanges(this.accountForm, { ...this.formErrors }, this.errorMessages, this.translateService);
      return;
    }
    const body = {
      data: {
        accountType: this.accountForm.value.accountType.accountType,
        accountName: this.accountForm.value.accountName,
        description: this.accountForm.value.description,
        accountSection: this.accountForm.value.accountType.section,
        accountId: this.accountForm.value.accountId
      },
      update: false
    };
    this.accountFormValue.emit(body);
    this.closeForm();
    
  }

  updateAccount(): void {
    if (this.accountForm.invalid){
      this.accountForm.markAllAsTouched();
      this.formErrors = valueChanges(this.accountForm, { ...this.formErrors }, this.errorMessages);
      return;
    }
    const body = {
      data: {
        accountType: this.accountForm.value.accountType.accountType,
        accountName: this.accountForm.value.accountName,
        description: this.accountForm.value.description,
        accountSection: this.accountForm.value.accountType.section,
        accountId: this.accountForm.value.accountId,
        isArchive: this.accountForm.value.isArchive
      },
      update: true,
    };
    this.accountFormValue.emit(body);
    this.closeForm(); 
  }

  closeForm(): void {
    this.accountForm.reset();
    this.closeAccountForm.emit();
  }

}
