import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';
import { SalesReducerState } from '../sales/state/reducers';
import * as VendorActions from './state/actions/vendors.action';
import * as SalesTaxesActions from './state/actions/salesTaxes.action';
import * as ProductsActions from './state/actions/product.action';


@Injectable({
    providedIn: 'root'
  })
export class PurchasesService {
    constructor(private rootStore: Store<SalesReducerState>,
                private httpService: HttpService) {
    }
    private businessId = new BehaviorSubject(null);
    currencyDetails = new BehaviorSubject(null);
    businessId$ = this.businessId.asObservable();

    setBusinessId(id): void {
        this.businessId.next(id);
    }

    setCurrencyDetails(details): void {
        this.currencyDetails.next(details);
    }
     // Store
     setVendors(vendors): void {
        this.rootStore.dispatch(VendorActions.SetVendors({vendors}));
    }
    setSalesTaxes(salesTaxes): void {
        this.rootStore.dispatch(SalesTaxesActions.SetSalesTaxes({salesTaxes}));
    }
    setProducts(products): void {
        this.rootStore.dispatch(ProductsActions.SetProducts({products}));
    }
    // Vendor
    // APIs
    createVendors(data): Observable<any> {
        return this.httpService.post(`/purchase/createVendor/${data.businessId}`, data);
    }
    getAllVendors(id): Observable<any> {
        console.log(id);
        return this.httpService.get(`/purchase/getVendors/${id}`);
    }

    getVendorDetails(id): Observable<any> {
        return this.httpService.get(`/purchase/getVendorDetails/${id}`);
    }
    deleteVendor(id): Observable<any> {
        return this.httpService.delete(`/purchase/deleteVendor/${id}`);
    }
    updateVendor(body): Observable<any> {
        return this.httpService.put(`/purchase/updateVendor/${body.id}`, body);
    }

    // assets api

    createAssets(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/createAssets/${data.businessId}`, data?.formData);
    }
    getAllAssets(id): Observable<any> {
        return this.httpService.get(`/purchase/getAssets/${id}`);
    }
    getAssetDetails(id): Observable<any> {
        return this.httpService.get(`/purchase/getAssetDetaills/${id}`);
    }
    deleteAsset(id): Observable<any> {
        return this.httpService.delete(`/purchase/deleteAssest/${id}`);
    }
    updateAssets(body, assetId): Observable<any> {
        return this.httpService.putMultipart(`/purchase/updateAssets/${assetId}`, body);
    }
    // bill
    convertBill(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/createBill/${data.businessId}`, data);
    }

    createBill(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/createBill/${data.businessId}`, data?.formData);
    }
    createRecurringBill(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/createRecurringBill/${data?.businessId}`, data?.formData)
    }

    getAllBills(id): Observable<any> {
        return this.httpService.get(`/purchase/getBills/${id}`);
    }

    getRecurringBills(businessId): Observable<any> {
        return this.httpService.get(`/purchase/getRecurringBills/${businessId}`)
    }
    
    getBillDetails(id): Observable<any> {
        return this.httpService.get(`/purchase/getBillDetails/${id}`);
    }

    getRecurringBillDetails(id): Observable<any> {
        return this.httpService.get(`/purchase/viewRecurringBill/${id}`);
    }

    deleteBill(id): Observable<any> {
        return this.httpService.delete(`/purchase/deleteBill/${id}`);
    }

    cancelRecurringBill(id): Observable<any> {
        return this.httpService.get(`/purchase/cancelRecurringBill/${id}`);
    }

    updateBill(data): Observable<any> {
        return this.httpService.putMultipart(`/purchase/updateBillDetails/${data.billId}`, data?.formData);
    }

    updateRecurringBill(id, data): Observable<any> {
        return this.httpService.put(`/purchase/updateRecurringBill/${id}`, data)
    }

    recordPayment(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/recordPaymentForBill/${data._id}`, data?.formData);
    }

    refundPayment(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/refundPayment/${data._id}`, data?.formData);
    }
    updateRecord(data): Observable<any> {
        return this.httpService.put(`/purchase/updaterecordForBill/${data.invoiceId}`, data);
    }
    deleteRecord(data): Observable<any> {
        return this.httpService.delete(`/purchase/deleteBillRecord/${data.invoiceId}`, data);
    }
    filterBills(id, data): Observable<any> {
        return this.httpService.post(`/purchase/filterBills/${id}`,data);
    }
    getBillNumber(data): Observable<any> {
        return this.httpService.post(`/purchase/generateBill/${data.businessId}`, data)
    }
    createBillPdf(data): Observable<any> {
        return this.httpService.postPdf(`/pdf/bill`, data);
    }
    sendBill(data): Observable<any> {
        return this.httpService.post(`/purchase/sendBill/${data?.id}`, data);
    }
    // purchaseOrder
    createPurchaseOrder(data): Observable<any> {
        return this.httpService.postMultipart(`/purchase/createPurchaseOrder/${data.businessId}`, data?.formData);
    }
    getPurchaseOrders(id): Observable<any> {
        return this.httpService.get(`/purchase/getPurchaseOrders/${id}`);
    }
    deleteOrder(id): Observable<any> {
        return this.httpService.delete(`/purchase/deleteOrder/${id}`);
    }
    updateOrderDetails(body): Observable<any> {
        return this.httpService.putMultipart(`/purchase/updateOrderDetails/${body.poId}`, body?.formData);
    }
    getOrderDetails(id): Observable<any> {
        return this.httpService.get(`/purchase/orderDetails/${id}`);
    }
    getCustomizationSettings(): Observable<any> {
        return this.httpService.get(`/setting/getTemplateConfiguration/${this.businessId.getValue()}`);
    }
    generatePurchaseOrderNumber(data): Observable<any> {
        return this.httpService.post(`/purchase/generatePurchaseNumber/${data.businessId}`,data);
    }
    sendPurchaseOrder(data): Observable<any> {
        return this.httpService.post(`/purchase/sendPO/${data.id}`, data);
    }
    generatePOPdf(data): Observable<any> {
        return this.httpService.postPdf(`/pdf/purchaseOrder`, data);
    }
}
