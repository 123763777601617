<div class="p-8 w-full">
  <div class="bg-white rounded-xl space-y-6 p-8">
    <!-- <div *ngIf='!update' header class="text-start text-lg border-b pb-2 Poppins-Bold">{{'Add a Product or Service' | translate}}</div>
        <div *ngIf='update'  header class="txet-start text-lg border-b pb-2 Poppins-Bold">{{'Update a Product or Service' | translate}}</div> -->
    <div content>
      <!-- <p class="text-start text-sm Poppins-Bold">{{'Create Product ' | translate}} -->
      <!-- </p> -->
      <form [formGroup]="createProductForm" class="text-start flex space-x-2">
        <div class="w-1/2">
          <div class="flex mb-3 items-center">
            <div class="w-1/5">
              <p>{{ "Name:" | translate }}</p>
            </div>
            <div class="w-2/5">
              <input
                type="text"
                formControlName="name"
                class="new-input"
                placeholder="Enter product name"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.name"
              >
                {{ formErrors.name }}
              </div>
            </div>
          </div>

          <div class="flex mb-3 w-full">
            <div class="w-1/5">
              <p>{{ "Description:" | translate }}</p>
            </div>
            <div class="w-2/5">
              <textarea
                formControlName="description"
                placeholder="Enter Description"
                class="!h-16 new-input"
              ></textarea>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.description"
              >
                {{ formErrors.description }}
              </div>
            </div>
          </div>

          <div class="flex mb-3 items-center">
            <div class="w-1/5 self-start">
              <p>{{ "Item Type" | translate }}</p>
            </div>
            <div class="w-2/5">
              <!-- <ng-select class="custom-selector border-0 p-0" [items]="itemType" 
                        formControlName = 'itemType'> -->
              <!-- </ng-select> -->
              <div class="flex items-center">
                <fieldset
                  class="flex items-center"
                  (change)="changeIsSell($event)"
                >
                  <div>
                    <input
                      type="radio"
                      value="Product"
                      formControlName="itemType"
                    />
                    Product
                  </div>
                  <div class="ms-3">
                    <input
                      type="radio"
                      value="Service"
                      formControlName="itemType"
                    />
                    Service
                  </div>
                </fieldset>
              </div>

              <div
                class="w-full text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.itemType"
              >
                {{ formErrors.itemType }}
              </div>
            </div>
          </div>
          <div class="flex mb-3 items-center">
            <div class="w-1/5">
              <span>{{ "Mark as favorite" | translate }}</span>
            </div>
            <div class="2/5">
              <label class="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  class="cursor-pointer"
                  formControlName="isFavorite"
                />
              </label>
            </div>
          </div>

          <div
            *ngIf="
              (createProductForm.value.isSale === false ||
                createProductForm.value.isSale === 'false') &&
              createProductForm.value.itemType !== 'Service' &&
              hasInventorySubscription
            "
            class="flex mb-3 items-center"
          >
            <div class="w-1/5">
              <span>{{ "Manage Stock" | translate }}</span>
            </div>
            <!-- <div class="2/5" [disabled]="productToUpdate"> -->
            <fieldset [disabled]="productToUpdate" class="flex items-center">
              <label class="flex items-center gap-x-2">
                <input
                  type="checkbox"
                  class="cursor-pointer"
                  formControlName="isManage"
                />
              </label>
            </fieldset>
            <!-- </div> -->
          </div>

          <div class="flex mb-3 items-center">
            <div class="w-1/5 self-start">
              <p>{{ "I sell this" | translate }}</p>
            </div>
            <div class="w-2/5">
              <fieldset
                (change)="changeIsSell($event)"
                [disabled]="productToUpdate"
                class="flex items-center"
              >
                <div>
                  <input
                    type="radio"
                    value="true"
                    formControlName="isSale"
                    [disabled]="productToUpdate"
                  />
                  Yes
                </div>
                <div class="ms-3">
                  <input
                    type="radio"
                    value="false"
                    formControlName="isSale"
                    [disabled]="productToUpdate"
                  />
                  No
                </div>
              </fieldset>
            </div>
          </div>

          <div class="flex mb-3 w-full">
            <div class="w-1/5">
              <p>{{ "SKU:" | translate }}</p>
            </div>
            <div class="w-2/5">
              <input
                type="text"
                formControlName="sku"
                class="ps-2 new-input"
                placeholder="Enter product SKU"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.sku"
              >
                {{ formErrors.sku }}
              </div>
            </div>
          </div>

          <!-- 
                <div class="flex mb-3 items-center">
                    <div class="w-1/5">
                        <p>{{'Item Category' | translate }}</p>
                    </div>
                    <div class="w-2/5">
                        <ng-select class="custom-selector border-0 p-0" [items]="itemCategory" 
                        formControlName = 'productType'>
                        </ng-select>
                        <div  class="text-sm text-red-500 ms-2 mt-1" *ngIf='formErrors.productType'>
                        {{formErrors.productType}}
                        </div>
                    </div>
                </div> -->

          <div
            *ngIf="createProductForm?.controls['itemType'].value === 'Product'"
            class="flex mb-3 items-center"
          >
            <div class="w-1/5">
              <p>{{ "HSN Code" | translate }}</p>
            </div>
            <div class="w-2/5">
              <!-- <ng-select class="custom-selector border-0 p-0" [items]="hsnCode" 
                        bindLabel="description"
                        bindValue="code"
                        formControlName = 'hsn'
                        (search)='hsnChange($event,i)'
                        >
                        </ng-select> -->
              <input
                type="number"
                min="1"
                maxlength="7"
                formControlName="hsn"
                class="new-input"
                placeholder="Enter HSN ID"
              />
            </div>
          </div>

          <div
            *ngIf="createProductForm?.controls['itemType'].value === 'Service'"
            class="flex mb-3 items-center"
          >
            <div class="w-1/5">
              <p>{{ "SAC Code" | translate }}</p>
            </div>
            <div class="w-2/5">
              <!-- <ng-select class="custom-selector border-0 p-0" [items]="sacCode" 
                        bindLabel="description"
                        bindValue="code"
                        formControlName = 'sac'
                        virtualScroll="scroll"
                        (search)='sacChange($event,i)'
                        >
                        </ng-select> -->
              <input
                type="number"
                min="1"
                maxlength="7"
                formControlName="sac"
                class="new-input"
                placeholder="Enter SAC ID"
              />
            </div>
          </div>

          <div
            *ngIf="createProductForm.value.isManage !== true"
            class="flex mb-3 items-center"
          >
            <div class="w-1/5">
              <p>{{ "Account" | translate }}</p>
            </div>
            <div class="w-2/5">
              <ng-select
                class="new-dropdown"
                placeholder="Select product account"
                groupBy="accountSection"
                [items]="incomeAccounts"
                bindLabel="accountName"
                formControlName="accountDetails"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.accountName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.accountDetails"
              >
                {{ formErrors.accountDetails }}
              </div>
            </div>
          </div>
          <div class="flex mb-3 items-center">
            <div class="w-1/5">
              <p>{{ "Category" | translate }}</p>
            </div>
            <div class="w-2/5">
              <ng-select
                #selectRef
                class="new-dropdown"
                [items]="categories"
                placeholder="{{ 'Select category' | translate }}"
                formControlName="category"
                addTagText=""
                [addTag]="addNewCategory"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.category"
              >
                {{ formErrors.category }}
              </div>
            </div>
          </div>

          <div class="flex mb-3 items-center">
            <div class="w-1/5">
              <p>{{ "Units" | translate }}</p>
            </div>
            <div class="w-2/5">
              <ng-select
                class="new-dropdown"
                [items]="units"
                bindLabel="name"
                bindValue="value"
                placeholder="Select applicable unit"
                formControlName="units"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.name }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.units"
              >
                {{ formErrors.units }}
              </div>
            </div>
          </div>
        </div>
        <div class="w-1/2 p-4 border-l-[1px] border-l-[#F7F9FA]">
          <div class="border-b-[1px] border-b-[#F7F9FA] mb-2">
            <p class="Poppins-Bold text-lg">Pricing Details</p>
            <div
              *ngIf="
                createProductForm?.controls['itemType'].value === 'Product' ||
                createProductForm?.controls['isSale'].value === 'false'
              "
              class="flex ms-5 mb-3 items-center"
            >
              <div class="w-1/5">
                <p>{{ "Purchase Price:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="number"
                  min="0"
                  formControlName="purchasePrice"
                  class="new-input"
                  placeholder="Enter Purchsae price"
                />
              </div>
            </div>
            <div
              *ngIf="createProductForm?.controls['isSale'].value === 'true'"
              class="flex mb-3 ms-5 items-center"
            >
              <div class="w-1/5">
                <p>{{ "Selling Price:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="number"
                  min="0"
                  formControlName="sellingPrice"
                  class="new-input"
                  placeholder="Enter Selling price"
                />
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.sellingPrice"
                >
                  {{ formErrors.sellingPrice }}
                </div>
              </div>
            </div>
            <div class="flex mb-3 ms-5 items-center">
              <div class="w-1/5">
                <p>{{ "Sales Tax" | translate }}</p>
              </div>
              <div class="w-2/5">
                <ng-select
                  class="new-dropdown border-0 p-0"
                  [items]="taxes"
                  bindLabel="taxName"
                  formControlName="tax"
                  placeholder="Select applicable tax"
                  (change)="taxChange($event)"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.taxName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </div>
            </div>
          </div>
          <div
            inventoryCheck
            *ngIf="
              (createProductForm?.controls['itemType'].value === 'Product' &&
                createProductForm?.controls['isSale'].value === 'true') ||
              createProductForm?.controls['isManage'].value === true
            "
            class=""
          >
            <p class="Poppins-Bold text-lg">Stock Details</p>
            <div
              inventoryCheck
              *ngIf="
                (createProductForm?.controls['itemType'].value === 'Product' &&
                  createProductForm?.controls['isSale'].value === 'true') ||
                createProductForm?.controls['isManage'].value === true
              "
              class="flex mb-3 ms-5 items-center"
            >
              <div class="w-1/5">
                <p>{{ "Opening Stock:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="number"
                  formControlName="openingStock"
                  class="new-input"
                  placeholder="Enter Opening Stock"
                  min="0"
                />
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.openingStock"
                >
                  {{ formErrors.openingStock }}
                </div>
              </div>
            </div>
            <div
              inventoryCheck
              *ngIf="
                (createProductForm?.controls['itemType'].value === 'Product' &&
                  createProductForm?.controls['isSale'].value === 'true') ||
                createProductForm?.controls['isManage'].value === true
              "
              class="flex mb-3 ms-5 items-center"
            >
              <div class="w-1/5">
                <p>{{ "As of Date:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="date"
                  formControlName="asOfDate"
                  class="new-date"
                  placeholder="Select Date"
                />
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.asOfDate"
                >
                  {{ formErrors.asOfDate }}
                </div>
              </div>
            </div>
            <div
              inventoryCheck
              *ngIf="
                (createProductForm?.controls['itemType'].value === 'Product' &&
                  createProductForm?.controls['isSale'].value === 'true') ||
                createProductForm?.controls['isManage'].value === true
              "
              class="flex mb-3 ms-5 items-center"
            >
              <div class="w-1/5">
                <p>{{ "Low Stock below:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="number"
                  formControlName="lowStock"
                  class="new-input"
                  placeholder="Low stock quantity"
                  min="0"
                />
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.lowStock"
                >
                  {{ formErrors.lowStock }}
                </div>
              </div>
            </div>
            <div inventoryCheck class="flex mb-3 ms-5 items-center">
              <div class="w-1/5">
                <p>{{ "Item Code:" | translate }}</p>
              </div>
              <div class="w-2/5">
                <input
                  type="text"
                  formControlName="itemCode"
                  class="new-input"
                  placeholder="Enter/Generate Item Code"
                />
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.itemCode"
                >
                  {{ formErrors.itemCode }}
                </div>
              </div>
              <div class="ms-2">
                <app-button
                  (handleClick)="generateBarcode()"
                  type="third"
                  [buttonText]="
                    createProductForm?.controls['itemCode'].value
                      ? 'View Barcode'
                      : 'Generate Barcode'
                  "
                ></app-button>
              </div>
            </div>
            <div inventoryCheck class="flex mb-3 ms-5 items-center">
              <div class="w-1/5">
                <p>{{ "Store" | translate }}</p>
              </div>
              <div class="w-2/5">
                <ng-select
                  class="new-dropdown"
                  [items]="stores"
                  bindLabel="storeName"
                  bindValue="_id"
                  formControlName="store"
                  placeholder="Select Store"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.storeName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div
                  class="text-sm text-red-500 ms-2 mt-1"
                  *ngIf="formErrors.store"
                >
                  {{ formErrors.store }}
                </div>
              </div>
            </div>
          </div>
          <div
            *ngIf="
              createProductForm?.controls['isSale'].value === 'true' ||
              createProductForm?.controls['isManage'].value === true
            "
            routeToSubscription
            fromComponent="inventory"
            class="hidden absolute bottom-6 z-[29] w-[456px] left-0 right-0 m-auto"
          >
            <app-send-to-sub from="inventory"></app-send-to-sub>
          </div>
        </div>
      </form>
      <div class="mt-4 flex items-end gap-12">
        <app-file-upload
          appAccessControl
          moduleType="storage"
          accessType="edit"
          [addedFiles]="addedFiles"
          (emitter)="saveFiles($event)"
          [type]="'compact'"
          tableType="new"
          class="w-[90%]"
        ></app-file-upload>
        <div
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          class="flex flex-col items-start ms-auto justify-start"
        >
          <div class="flex flex-col gap-4">
            <app-button
              [buttonText]="update ? 'Update' : 'Save'"
              (handleClick)="update ? updateProduct() : createProduct()"
              type="primary"
            ></app-button>
            <app-button
              buttonText="Discard"
              routerLink="/inventory/single-product"
            ></app-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="viewBarcode">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Barcode" | translate }}
  </div>
  <div content>
    <img
      class="mx-auto"
      width="300"
      height="300"
      [src]="barcode"
      alt="Barcode"
    />
  </div>
  <div footer class="flex items-center gap-x-2">
    <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteProduct()'></app-button> -->
    <app-button
      buttonText="{{ 'Close' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="viewBarcode = false"
    ></app-button>
  </div>
</app-modal-container>
