import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { SalesService } from '../../sales.service';
import { SalesReducerState } from '../../state/reducers';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Injectable({
  providedIn: 'root'
})
export class EstimatesService {

  constructor(private store: Store<SalesReducerState>,
              private fileUploadService: FileUploadService,
              private salesService: SalesService) {}

  getEstimateNumber(): Observable<any> {
    return this.salesService.getEstimateNumber();
  }

  getEstimates(): Observable<any> {
    return this.salesService.getEstimateList();
  }

  createEstimate(data): Observable<any> {
    return this.salesService.createEstimate(data);
  }

  updateEstimate(data): Observable<any> {
    return this.salesService.updateEstimate(data);
  }

  getEstimate(id): Observable<any> {
    return this.salesService.getEstimate(id);
  }
  deleteEstimate(id): Observable<any> {
    return this.salesService.deleteEstimate(id);
  }

  sendEmail(data): Observable<any> {
    return this.salesService.sendEstimateEmail(data);
  }

  createEstimatePdf(data): Observable<any> {
    return this.salesService.createEstimatePdf(data);
  }

  uploadFileForEstimate(data): Observable<any> {
    return this.fileUploadService.uploadFileForDocument(data);
  }
}
