import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { ChartsModule } from 'ng2-charts';
import { NgxEchartsModule } from 'ngx-echarts';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { GraphCashFlowComponent } from './components/graph-cash-flow/graph-cash-flow.component';
import { GraphProfitLossComponent } from './components/graph-profit-loss/graph-profit-loss.component';
import { OverdueDetailsComponent } from './components/overdue-details/overdue-details.component';
import { DashboardNewComponent } from './dashboard-new/dashboard-new.component';
import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardComponent } from './dashboard/dashboard.component';

@NgModule({
  declarations: [
    DashboardComponent,
    DashboardNewComponent,
    GraphCashFlowComponent,
    GraphProfitLossComponent,
    OverdueDetailsComponent,
  ],
  imports: [
    CommonModule,
    NgSelectModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
    DashboardRoutingModule,
    TranslateModule,
    ClickOutsideModule,
    DirectivesModule,
    ChartsModule,
    NgxEchartsModule.forRoot({ echarts: () => import('echarts') }),
  ],
})
export class DashboardModule {}
