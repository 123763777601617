import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { NumplusService } from '../../../numplus.service';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { takeUntil } from 'rxjs/operators';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { BudgetService } from '../budget.service';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-budget-list',
  templateUrl: './budget-list.component.html',
  styleUrls: ['./budget-list.component.scss'
  ],
  providers: [DatePipe]
})
export class BudgetListComponent implements OnInit, OnDestroy {

  unsubscribe = new Subject();

  currencyDetails ={
    currency: '',
    currencySymbol: ''
  }

  tableHeadings = [
    'Budget Name',
    'Period',
    'Created On',
    'Last Update',
    'Actions'
  ];

  tableKeys = [
    'name',
    '_id',
    'createdAt',
    'updatedAt',
  ];

  tableData = [];
  deleteOpenModal = false;
  budgetToDelete = null;

  accounts$:Observable<any>;
  business$: Observable<any>;
  businessId = null;
  fiscalMonth =null
  unsubscribe$ = new Subject();

  constructor(
    private route: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private rootStore:Store<RootReducerState>,
    private numplusService: NumplusService,
    private budgetService: BudgetService,
    private router: Router,) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
     }

  ngOnInit(): void {
    this.loadBudgetList()

     this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
      }
    })
  }

  loadBudgetList():void{
    this.numplusService.businessId$.subscribe((id) =>{
      console.log("Business Id", id)
      if(id){
        this.spinner.show();
        this.budgetService.getBudgetList().subscribe(resp =>{
          this.spinner.hide();
          this.tableData = resp.data.map(budget =>({
            ...budget,
            createdAt: moment(budget?.createdAt?.split('T')[0]).format('DD-MM-YYYY'),
            updatedAt: moment(budget?.updatedAt?.split('T')[0]).format('DD-MM-YYYY')
          }))
        }, (error)=>{
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        })
      }
    })
  }

  deleteBudget(id){
    this.deleteOpenModal = true
    this.budgetToDelete = id
  }

  deleteBudgetlist(): void {
    let body = {
      "year": this.budgetToDelete
    }
    this.spinner.show();
    this.budgetService.deleteBudget(body).subscribe((resp) => {
      this.spinner.hide();
      this.deleteOpenModal = false;
      this.tableData = this.tableData.filter(({_id}) => _id !== this.budgetToDelete);
      this.toastr.success(this.translateService.instant('Budget deleted successfully'));
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  excelReport(id, type){
    let body ={
      "financialMonth": this.fiscalMonth,
      "year": id,
      "type": type
    }
    this.spinner.show();
    this.budgetService.excelReportOfBudget(body).subscribe((resp) => {
      const a = document.createElement('a');
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/vnd.openxmlformats-ficedocument.spreadsheetml.sheet'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${id} ${this.fiscalMonth}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('Excel downloaded'));
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  pdfReport(id, type){
    let body ={
      "financialMonth": this.fiscalMonth,
      "year": id,
      "type": type
    }
    this.spinner.show();
    this.budgetService.excelReportOfBudget(body).subscribe((resp) => {
      const a = document.createElement('a');
      console.log("Pdf rewsponse", resp)
      const newResp = resp.data.fileBuffer.data
      const blob = new Blob([new Uint8Array(newResp)], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Budget ${id}${this.fiscalMonth}.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success(this.translateService.instant('PDF downloaded'));
      this.spinner.hide();
    }, error => {
      console.log(error);
      this.spinner.hide();
      this.toastr.error(error?.error?.message || 'Something went wrong');
    })
  }

  editBudget(id){
    this.router.navigateByUrl(`/plus/budget/edit-budget/${id}`)
  }

  viewBudget(id){
    this.router.navigateByUrl(`/plus/budget/view-budget/${id}`)
  }

  ngOnDestroy():void{
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


}
