import { Component, OnInit } from '@angular/core';
import { ExpertService } from './expert.service';
import { Router } from '@angular/router';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { RootReducerState } from 'src/app/store/reducers';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-experts',
  templateUrl: './experts.component.html',
  styleUrls: ['./experts.component.scss'],
})
export class ExpertsComponent implements OnInit {
  expertStatus: any;
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  userId: string;

  constructor(
    private expertService: ExpertService,
    private router: Router,
    private store: Store<RootReducerState>,
    private toster: ToastrService,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService
  ) {}

  ngOnInit(): void {}
}
