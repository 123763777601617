<div class="pt-10 w-full flex">
  <div
    [formGroup]="onlinePaymentForm"
    class="flex gap-4 flex-col border-r border-[#e6e3e3cc] w-1/2 px-8"
  >
    <table>
      <tr class="">
        <td class="py-2">
          <label
            for="Paytabs Profile ID"
            class="text-sm text-[#1C1C1C66] flex items-center gap-1 whitespace-nowrap"
          >
            {{ "Profile ID" | translate }}
            <span [title]="profileIdConfiguration">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </span>
          </label>
        </td>
        <td class="px-4 py-2">
          <input
            id="Paytabs Profile ID"
            formControlName="paytabsProfileId"
            placeholder="{{ 'Enter paytabs profile ID' | translate }}"
            type="text"
            class="new-input"
          />
        </td>
      </tr>
      <tr class="">
        <td class="py-2">
          <label
            for="Paytabs Authorization Token"
            class="text-sm text-[#1C1C1C66] flex items-center gap-1 whitespace-nowrap"
          >
            {{ "Server Key" | translate }}
            <span [title]="authorizationToken">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="1.5"
                stroke="currentColor"
                class="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
                />
              </svg>
            </span>
          </label>
        </td>
        <td class="px-4 py-2">
          <input
            id="Paytabs Authorization Token"
            formControlName="paytabsAuthorizationToken"
            placeholder="{{ 'Enter Paytabs Authorization Token' | translate }}"
            type="text"
            class="new-input"
          />
        </td>
      </tr>
      <tr class="">
        <td class="py-2">
          <label
            for="Paytabs Country"
            class="text-sm text-[#1C1C1C66] flex items-center"
          >
            {{ "Paytabs Country" | translate }}
          </label>
        </td>
        <td class="px-4 py-2">
          <ng-select
            [items]="countries"
            id="Paytabs Country"
            class="new-dropdown text-sm"
            bindLabel="name"
            bindValue="name"
            placeholder="{{ 'Select Country' | translate }}"
            formControlName="paytabsCountry"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.name }}
                </div>
              </div>
            </ng-template>
          </ng-select>
        </td>
      </tr>
    </table>
    <app-submit
      className="bg-[#026AA2] text-white h-8 w-16"
      value="Save"
      (clicked)="saveDetails()"
    >
    </app-submit>
    <p
      *ngIf="
        onlinePaymentForm?.controls?.paytabsCountry?.errors?.required &&
        onlinePaymentForm?.controls?.paytabsCountry?.touched
      "
      class="text-red-500 text-sm"
    >
      Please select a country
    </p>
  </div>
  <div class="logo-text w-1/2">
    <div class="flex justify-center">
      <img src="assets/images/PayTabs.png" width="200" alt="" srcset="" />
    </div>
    <div class="flex flex-col gap-2 mt-2 px-8">
      <p class="text-center text-sm">
        {{ "Enhance your payment options with PayTabs." | translate }}
      </p>
      <p class="text-center text-sm">
        {{
          "Contact PayTabs customer care to set up your account for seamless online transactions."
            | translate
        }}
      </p>
      <p class="text-center text-sm">
        {{ "Visit" | translate
        }}<a
          href="https://site.paytabs.com/en/"
          target="_blank"
          class="text-[#004b99]"
        >
          https://site.paytabs.com/en/</a
        >
        {{ "or reach out to them at" | translate }}
        <span class="text-[#004b99]">{{ "customercare@paytabs.com." }}</span>
      </p>
    </div>
  </div>
</div>
