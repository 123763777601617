<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-xl flex flex-col">
    <div>
      <!-- <div class="flex items-center justify-between">
        <div class="">
          <h1 class="text-2xl Poppins-Bold inline-flex items-center">
          <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" class="h-6 w-6 me-2">
                            <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                            </path>
                        </svg>
          <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                            <path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                        </svg>
                        {{'Vendors' | translate}}
                    </h1>
        </div>
        <div class="">
          
        </div>
      </div> -->

      <div class="flex justify-between items-center mb-8">
        <div class="w-80">
          <input
            type="text"
            name="search"
            [(ngModel)]="searchText"
            autocomplete="off"
            class="new-input"
            placeholder="{{ 'Search by name' | translate }}"
          />
        </div>

        <app-button
          appAccessControl
          moduleType="purchase"
          accessType="edit"
          routerLink="create-vendor"
          type="primary"
          size="large"
          buttonText="{{ 'Create a Vendor' | translate }}"
        ></app-button>
      </div>

      <app-table-container
        [tableHeadings]="tableHeadings"
        [tableData]="tableData | filter : searchText"
        [keys]="tableKeys"
        [actionTemplate]="actionTemplate"
      >
        <ng-template let-item #actionTemplate>
          <td
            appAccessControl
            moduleType="purchase"
            accessType="edit"
            class="p-2 text-center"
          >
            <app-dropdown #dropdown [isFromSettings]="true">
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options class="new-shadow p-1">
                <div
                  (click)="editVendor(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Edit" | translate }}
                </div>
                <div
                  (click)="deleteConfirmation(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Delete" | translate }}
                </div>
              </div>
            </app-dropdown>
          </td>
        </ng-template>
      </app-table-container>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="deleteConfirmationModal">
  <div header class="border-b pb-2 Poppins-Bold">Delete Vendor</div>
  <div content>Are you sure?</div>
  <div footer class="flex items-center space-x-2">
    <app-button
      buttonText="Delete"
      type="danger"
      (handleClick)="deleteVendor()"
    ></app-button>
    <app-button
      buttonText="Cancel"
      (handleClick)="deleteConfirmationModal = false"
    ></app-button>
  </div>
</app-modal-container>
