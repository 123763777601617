import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { filter, map } from 'rxjs/operators';
import * as moment from 'moment';
import { NumberService } from 'src/app/shared/services/number.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'record-payment',
  templateUrl: './record-payment.component.html',
  styleUrls: ['./record-payment.component.scss']
})
export class RecordPaymentComponent implements OnInit, OnChanges {

  constructor(private fb: FormBuilder,
              private numberService: NumberService,
              private fileUploadSerivce: FileUploadService,
              private store: Store<RootReducerState>) {
                this.paymentAccounts$ = this.store.pipe(
                  select(selectAccounts),
                  map(accounts => accounts.filter((account) => !account.isArchive && !account.isDeleted && ( this.type === 'bill' ? account.accountType === 'Cash and Bank' : account.accountType === 'Cash and Bank' || account.accountType === 'Money in Transit' ))));
              }

  @Input() type = ''
  @Input() isOpen = false;
  @Input() amountDue = 0;
  @Input() recordData = null;
  @Input() minDate = null;
  @Output() closeModal = new EventEmitter();
  @Output() paymentData = new EventEmitter();
  paymentAccounts$: Observable<any>;

  paymentForm: FormGroup;

  formErrors = {
    amount: '',
    paymentMethod: '',
    paymentAccount: '',
    paymentDate: ''
  };

  formErrorMessages = {
    amount: {
      required: 'Amount is Required',
      min:'Invalid Amount'
    },
    paymentMethod: {
      required: 'Payment Method is Required',
    },
    paymentAccount: {
      required: 'Payment Account is required'
    },
    paymentDate: {
      required: 'Payment Date is required',
      invalidDate: 'Invalid Date'
    }
  };

  invalidDateByMinDate = false;

  invalidAmount = false;
  paymentMethods = [
    'Cash',
    'Bank Transfer',
    'Cheque',
    'Online Payment',
    'Other'
  ];
  files: File[] = [];
  addedFiles = [];

  ngOnInit(): void {
    console.log(this.minDate);
    this.loadPaymentForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes?.recordData?.currentValue) {
      const recordData = changes?.recordData?.currentValue;
      const data = {
        paymentDate: recordData?.paymentDate.split('T')[0],
        amount: Number(recordData?.amount) || 0,
        memo: recordData.memo || null,
        paymentMethod: recordData.paymentMethod || null,
        paymentAccount: {_id:recordData?.accountId || null, accountType:recordData?.account || null, accountName: recordData.accountName || null}
      }
      this.addedFiles = recordData?.files ?? [];
      this.paymentForm.setValue(data);
    }
    if(changes?.minDate?.currentValue) {
      this.minDate = changes?.minDate?.currentValue;
      console.log(this.minDate);

    }
  }

  loadPaymentForm(): void {
    this.paymentForm = this.fb.group({
      paymentDate: [null, [Validators.required, DateValidator()]],
      amount: [null, [Validators.required, Validators.min(1)]],
      memo: [null],
      paymentMethod: [null, [Validators.required]],
      paymentAccount: [null, [Validators.required]]
    });

    this.paymentForm.valueChanges.subscribe(({amount, paymentDate}) => {
      this.formErrors = valueChanges(this.paymentForm, {...this.formErrors}, this.formErrorMessages);
      (amount > (this.amountDue + (this.recordData?.amount || 0) ) && !this.formErrors.amount) ? this.invalidAmount = true : this.invalidAmount = false;
      (moment(paymentDate).isBefore(moment(moment(this.minDate).format('YYYY-MM-DD')))) ? this.invalidDateByMinDate = true : this.invalidDateByMinDate = false;
    });
    this.formErrors = valueChanges(this.paymentForm, {...this.formErrors}, this.formErrorMessages);
  }

  generatePaymentDetails(): void {
    if (this.paymentForm.invalid) {
      this.paymentForm.markAllAsTouched();
      this.formErrors = valueChanges(this.paymentForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    if(this.invalidAmount){
      return
    }
    if (this.invalidDateByMinDate) {
      return;
    }
    const formValue = this.paymentForm.value;
    this.fileUploadSerivce.emitFiles.next(true);
    this.fileUploadSerivce.emitFiles.next(false);
    const data = {
      paymentDate: moment(formValue?.paymentDate).format('YYYY-MM-DD'),
      amount: this.numberService.toFixed(formValue?.amount),
      paymentMethod: formValue?.paymentMethod,
      memo: formValue?.memo,
      accountId: formValue?.paymentAccount?._id,
      account: formValue?.paymentAccount?.accountType,
      accountName: formValue?.paymentAccount?.accountName,
      files: this.files
    }
    this.paymentForm.reset();
    this.files =  [];
    this.paymentData.emit(data);
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  close(): void {
    this.paymentForm.reset();
    this.files = [];
    this.closeModal.emit();
  }

}
