import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-payroll-subscription',
  templateUrl: './payroll-subscription.component.html',
  styleUrls: ['./payroll-subscription.component.scss']
})
export class PayrollSubscriptionComponent implements OnInit {

  unsubscribe$ = new Subject();
  subscriptionPlans = [];
  currentSubscription = null;

  constructor(
    private spinner: NgxSpinnerService,
    private subscriptionService: SubscriptionService,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadCurrentSubscription();
  }

  loadCurrentSubscription(): void {
    this.spinner.show();
    this.subscriptionService.getPayrollSubscriptionInfo()
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.currentSubscription = resp?.data;
        this.spinner.hide();
      }
      else{
        this.toastr.success(resp?.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

}
