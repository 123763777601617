import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-verify-failure',
  templateUrl: './verify-failure.component.html',
  styleUrls: ['./verify-failure.component.scss']
})
export class VerifyFailureComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
