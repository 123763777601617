<div class="flex flex-col p-4">
  <div class="flex flex-col space-y-5">
    <span class="text-sm">
      {{ "Fiscal Year Start" | translate }}
    </span>
    <form [formGroup]="fiscalform" class="space-y-6">
      <div class="flex gap-4 rtl:space-x-reverse">
        <div class="calendar-picker w-full" (clickOutside)="isPickerOpen = false">
          <button (click)="togglePicker()" class="cursor-pointer bg-transparent w-full">
            <span class="block w-1/2 rounded-md border border-[#DCDCDC] px-4 py-2">{{selectedDay +"/"+ (currentMonth + 1)}}</span>
          </button>
        
          <div class="picker-overlay" *ngIf="isPickerOpen">
            <div class="picker-container">
              <div class="month-selector">
                <button (click)="changeMonth(-1)">&#10094;</button>
                <span>{{ months[currentMonth] }}</span>
                <button (click)="changeMonth(1)">&#10095;</button>
              </div>
        
              <div class="day-selector">
                <button *ngFor="let day of days" 
                        [ngClass]="{'selected': day === selectedDay}"
                        (click)="selectDay(day)">
                  {{ day }}
                </button>
              </div>
            </div>
          </div>
        </div>
        
        
        <!-- <div class="w-1/2">
          <label for="Country" class="text-xs">{{ "Month" | translate }}</label>
          <ng-select
            (change)="chnageMonthEvent($event)"
            id="month-select"
            [items]="months"
            class="w-full text-sm focus:ring-0 new-dropdown"
            bindLabel="text"
            bindValue="text"
            formControlName="month"
            placeholder="{{ 'Select one' | translate }}"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.text }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <label *ngIf="formErrors.month" class="text-xs text-red-400 ms-2">{{
            formErrors.month
          }}</label>
        </div>

        <div class="w-[32%]">
          <label class="text-xs">{{ "Date" | translate }}</label>
          <ng-select
            id="date-select"
            [items]="dates"
            class="w-full text-sm focus:ring-0 new-dropdown"
            bindLabel="text"
            [bindValue]="'text'"
            formControlName="date"
            placeholder="{{ 'Select one' | translate }}"
            [clearable]="false"
            [searchable]="false"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.text }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <label *ngIf="formErrors.date" class="text-sm text-red-400 ms-2">{{
            formErrors.date
          }}</label>
        </div> -->
      </div>
      <div
        class="flex rtl:space-x-revers flex-col justify-start items-start gap-2"
      >
        <div class="block text-sm">
          {{ "Business Currency:" | translate }}
          <span class="Poppins-Medium">
            {{ currencySelect }}
          </span>
        </div>
        <span class="text-xxs">
          {{ "Can't be changed." | translate }}
        </span>
      </div>
      <div class="flex items-end justify-end gap-x-2">
        <span *ngIf="response" class="text-green-500 text-sm">{{
          response
        }}</span>
        <span *ngIf="error" class="text-red-500 text-sm">{{ error }}</span>
        <!-- <app-submit
          appAccessControl
          moduleType="accounting"
          accessType="edit"
          value="Update"
          (clicked)="addFiscal()"
          className="bg-[#026AA2] text-white h-10 w-30"
        >
        </app-submit> -->
      </div>
    </form>
  </div>
</div>
