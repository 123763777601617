<div class="p-8 w-full" [ngClass]="{'!w-[100vw] !p-0': isMobileDevice}">
    <div class="bg-white px-14 py-11 rounded-xl space-y-6" [ngClass]="{'!p-6': isMobileDevice}">
        <div class="flex justify-between space-x-4 gap-2 items-center cursor-pointer">
            <div class="flex space-x-4 gap-2 items-center cursor-pointer">
                <div (click)="back()" class="bg-gray-100 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 ltr:rotate-0 rtl:rotate-180">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5L3 12m0 0l7.5-7.5M3 12h18" />
                      </svg>              
                </div>
                <h1 class="text-2xl Poppins-Bold">{{"Task Details" | translate}}</h1>
            </div>
        </div>
        <div *ngIf="taskDetails?.status === 'Completed'" class="p-4">
            <div class="bg-gray-100 rounded-2xl p-4 space-y-2">
                <div class="flex justify-between space-x-16">
                    <div class="flex gap-5 completed-card">
                        <div class="space-y-1">
                            <p class="Poppins-Medium text-gray-500">{{"Task Title" | translate}}</p>
                            <div class="flex space-x-2 items-center">
                                <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M16 7L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg>
                                <h2 class="Poppins-Bold text-xl">{{"Completed" | translate}}</h2>
                            </div>
                        </div>
                        <div class="space-y-1">
                            <p class="Poppins-Medium text-gray-500">{{"Hours Consumed" | translate}}</p>
                            <div class="flex space-x-2 items-center">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                                </svg>  
                                <h2 class="Poppins-Bold text-xl">{{taskDetails?.hoursConsume}} {{"Hours" | translate}}</h2>
                            </div>
                        </div>
                    </div>
    
                    <div class="flex">
                        <svg (click)="editDetails()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125" />
                        </svg>               
                        <!-- <p class="Poppins-SemiBold">{{"Edit Task" | translate}}</p> -->
                    </div>
                </div>
                <p class="Poppins-Medium text-sm text-gray-500">{{taskDetails?.note}}</p>
            </div>
        </div>
        <div class="flex justify-between p-4 view-task-top">
            <div class="space-y-4 w-1/5 view-task-header">
                <div class="flex flex-col space-y-1">
                    <span class="Poppins-Medium text-gray-500"> {{"Task Title" | translate}}</span>
                    <h1 class="Poppins-Bold text-2xl">{{taskDetails?.taskTitle}}</h1>
                </div>
                <p class="Poppins-Medium text-sm text-gray-500 min-h-[60px]">{{taskDetails?.description}}</p>
            </div>
            <div class="w-1/2 gap-5 flex justify-end reminder-status">
                <app-dropdown *ngIf="assignedtoMe && taskDetails?.status !== 'Completed'" #rDropdown>
                    <app-button type="third" button (handleClick)='openReminderModal = true; reminderFieldError = false; selectedReminderDate = null;'>
                        {{"Set Reminder" | translate}}
                    </app-button>
                    
                </app-dropdown>
                <app-dropdown #dropdown>
                    <app-button type="third" button (handleClick)='dropdown.open = true' (clickOutside)='dropdown.open = false'>
                        <p class="Poppins-Medium">{{"Status:" | translate}} {{taskDetails?.status}}</p>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                        </svg>                  
                    </app-button>
                    <div options class="p-4 space-y-4">
                        <!-- <div *ngIf="taskDetails?.status !== 'Upcoming'" class="flex space-x-2 items-center cursor-pointer" (click)="changeStatus('Upcoming'); dropdown.open = false">
                            <svg width="20px" height="20px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M16 7L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg>
                            <p class="Poppins-SemiBold">Upcoming</p>
                        </div> -->
                        <div class="flex space-x-2 items-center cursor-pointer" (click)="changeStatus('In Progress'); dropdown.open = false">
                            <svg width="16px" height="16px" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M11.25 4.5l7.5 7.5-7.5 7.5m-6-15l7.5 7.5-7.5 7.5" />
                            </svg>
                            <p class="Poppins-SemiBold">{{"In Progress" | translate}}</p>
                        </div>
                        <div *ngIf="taskDetails?.status === 'In Progress' || taskDetails?.status !== 'Overdue' || taskDetails?.status !== 'Upcoming'" class="flex space-x-2 items-center cursor-pointer" (click)="dropdown.open = false; isOpen=true">
                            <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M1.5 12.5L5.57574 16.5757C5.81005 16.8101 6.18995 16.8101 6.42426 16.5757L9 14" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M16 7L12 11" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path><path d="M7 12L11.5757 16.5757C11.8101 16.8101 12.1899 16.8101 12.4243 16.5757L22 7" stroke="currentColor" stroke-width="1.5" stroke-linecap="round"></path></svg>
                            <p class="Poppins-SemiBold">{{"Completed" | translate}}</p>
                        </div>
                        <!-- <div *ngIf="taskDetails?.status !== 'Overdue'" class="flex space-x-2 items-center cursor-pointer" (click)="changeStatus('Overdue'); dropdown.open = false">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M3 8.25V18a2.25 2.25 0 002.25 2.25h13.5A2.25 2.25 0 0021 18V8.25m-18 0V6a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 6v2.25m-18 0h18M5.25 6h.008v.008H5.25V6zM7.5 6h.008v.008H7.5V6zm2.25 0h.008v.008H9.75V6z" />
                            </svg> 
                            <p class="Poppins-SemiBold">Overdue</p>
                        </div> -->
                    </div>
                </app-dropdown>
            </div>
        </div>
        <div class="flex gap-16 !mt-0 p-4 view-task-details">
            <div class="flex flex-col space-y-1 view-task-item">
                <span class="Poppins-Medium text-gray-500">{{"Assigned To" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg width="24px" height="24px" stroke-width="1.5" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="#000000"><path d="M5 20V19C5 15.134 8.13401 12 12 12V12C15.866 12 19 15.134 19 19V20" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M12 12C14.2091 12 16 10.2091 16 8C16 5.79086 14.2091 4 12 4C9.79086 4 8 5.79086 8 8C8 10.2091 9.79086 12 12 12Z" stroke="#000000" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <p class="Poppins-SemiBold">{{taskDetails?.assignToUserId?.firstName + ' ' + taskDetails?.assignToUserId?.lastName}}</p>
                </div>
            </div>
            <div class="flex flex-col space-y-1 view-task-item">
                <span class="Poppins-Medium text-gray-500">{{"Start Date" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                      </svg>                          
                    <p class="Poppins-SemiBold">{{taskDetails?.startDate | date}}</p>
                </div>
            </div>
            <!-- <div class="flex flex-col space-y-1">
                <span class="Poppins-Medium text-gray-500">{{"Start Time" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>                          
                    <p class="Poppins-SemiBold">{{taskDetails?.startTime || '-'}}</p>
                </div>
            </div> -->
            <div class="flex flex-col space-y-1 view-task-item">
                <span class="Poppins-Medium text-gray-500">{{"Reminder" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                      </svg>
                    <p class="Poppins-SemiBold" *ngIf="taskDetails?.reminder">{{ taskDetails?.reminder | date : 'MMM d, y, h:mm a'}}</p>
                    <p class="Poppins-SemiBold" *ngIf="!taskDetails?.reminder">{{ '---' }}</p>
                </div>
            </div>
            <div class="flex flex-col space-y-1 view-task-item">
                <span class="Poppins-Medium text-gray-500">{{"Due Date" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 012.25-2.25h13.5A2.25 2.25 0 0121 7.5v11.25m-18 0A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75m-18 0v-7.5A2.25 2.25 0 015.25 9h13.5A2.25 2.25 0 0121 11.25v7.5" />
                      </svg>                          
                    <p class="Poppins-SemiBold">{{taskDetails?.dueDate | date}}</p>
                </div>
            </div>
            <!-- <div class="flex flex-col space-y-1">
                <span class="Poppins-Medium text-gray-500">{{"Due Time" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v6h4.5m4.5 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>                          
                    <p class="Poppins-SemiBold">{{taskDetails?.dueTime || "-"}}</p>
                </div>
            </div> -->
        </div>
        <div class="border rounded-2xl p-4 flex space-x-8">
            <div class="flex flex-col space-y-1">
                <span class="Poppins-Medium text-gray-500">{{"Task Type" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg width="24px" stroke-width="1.5" height="24px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                    <h2 class="Poppins-SemiBold">{{taskDetails?.taskType}}</h2>
                </div>
            </div>
            <div *ngIf="taskDetails?.taskType === 'Recurring'" class="flex flex-col space-y-1">
                <span class="Poppins-Medium text-gray-500">{{"Frequency" | translate}}</span>
                <div class="flex space-x-2 items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" height="16" width="16" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H352c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V80c0-17.7-14.3-32-32-32s-32 14.3-32 32v35.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V432c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H160c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z"/></svg>
                    <h2 class="Poppins-SemiBold">{{taskDetails?.frequency | translate}}</h2>
                </div>
            </div>
        </div>
        <div class="border rounded-2xl p-4 flex space-x-8">
            <ng-container *ngIf="taskDetails?.files?.length > 0; else noFilesAttached">
                <ng-container *ngFor="let file of taskDetails?.files">
                    <div (click)="viewFile(file._id)" class="bg-gray-100 cursor-pointer rounded-2xl px-4 py-2 flex space-x-2">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M18.375 12.739l-7.693 7.693a4.5 4.5 0 01-6.364-6.364l10.94-10.94A3 3 0 1119.5 7.372L8.552 18.32m.009-.01l-.01.01m5.699-9.941l-7.81 7.81a1.5 1.5 0 002.112 2.13" />
                        </svg>
                        <p class="Poppins-Medium">{{file.displayFileName}}</p>               
                    </div>
                </ng-container>
            </ng-container>
            <ng-template #noFilesAttached>
                {{"No files attached to this task" | translate}}
            </ng-template>
        </div>
        <div class="bg-gray-50 rounded-lg min-h-[46vh] relative p-4 space-y-6">
            <div class="max-h-[33vh] overflow-y-auto space-y-4">
                <ng-container *ngFor="let comment of taskDetails?.taskComments">
                    <div class="flex space-x-2">
                        <img [src]="comment.userId?.profileImageName ? baseURL+comment.userId?.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" class="w-10 h-10 rounded-full object-cover"/>
                        <div class="flex flex-col space-y-1">
                            <div class="flex space-x-8">
                                <span class="Poppins-Medium text-gray-500 text-sm">{{comment.userId?.firstName + ' ' + comment.userId?.lastName}}</span>
                                <span class="Poppins-Medium text-gray-500 text-sm">{{comment.addedDate | date}} {{comment.addedDate | date : 'hh:mm a'}}</span>
                            </div>
                            <p class="Poppins-Medium">{{comment.comment}}</p>
                        </div>
                    </div>
                </ng-container>
                <ng-container *ngIf="!taskDetails?.taskComments.length">
                    <div class="text-center Poppins-Medium h-44 flex justify-center items-center">{{"No comment found" | translate}}</div>
                </ng-container>
            </div>
            <div class="absolute bottom-0 w-[98%] mx-auto flex items-center bg-white px-4 py-2 rounded-3xl comment-field" style="margin-bottom: 1rem;">
                <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="avatar" srcset="">
               <input type="text" [formControl]="addComment" class="outline-none w-full border-none bg-transparent" placeholder="{{'Add a comment...' | translate}}"/>
               <svg (click)="addComments()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-8 h-8 cursor-pointer">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5" />
              </svg>          
            </div>
        </div>
    </div>
</div>

<app-modal-container [isOpen]="isOpen">
    <div header class="flex justify-between items-center">
        <h1 class="Poppins-Bold text-2xl">{{"Task Completed" | translate}}</h1>
        <svg (click)="isOpen=false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg> 
    </div>
    <div content >
        <form [formGroup]="completeTaskForm" class="space-y-6">
            <div class="space-y-3 border-b pb-8">
                <div class="flex flex-col space-y-1">
                    <label class="Poppins-SemiBold text-gray-600">{{'Hours Consumed' | translate}}</label>
                    <input type="number" formControlName="hoursConsume" class="bg-transparent w-full outline-none border rounded-2xl px-4 py-3" placeholder="e.g. 5 hours"/>
                    <span
                      class="text-red-500 text-sm"
                      *ngIf="createTaskFormErrors?.hoursConsume"
                      >{{ createTaskFormErrors.hoursConsume }}</span
                    >
                </div>
                <div class="flex flex-col space-y-1">
                    <label class="Poppins-SemiBold text-gray-600">{{'Notes' | translate}}</label>
                    <textarea rows="5" formControlName="note" class="bg-transparent w-full outline-none border rounded-2xl px-4 py-3"></textarea>
                    <span
                      class="text-red-500 text-sm"
                      *ngIf="createTaskFormErrors?.note"
                      >{{ createTaskFormErrors.note }}</span
                    >
                </div>
            </div>
        </form>
    </div>
    <div footer>
        <app-button (handleClick)="submitForm()" type="primary">{{"Next" | translate}}</app-button>
    </div>
</app-modal-container>

<app-modal-container [isOpen]="openReminderModal">
    <div header class="flex justify-between items-center">
        <h1 class="Poppins-Bold text-2xl">{{"Reminder" | translate}}</h1>
        <svg (click)="openReminderModal=false; reminderFieldError= false" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 cursor-pointer">
            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
        </svg> 
    </div>
    <div content >
        <label class="example-input-wrapper flex items-center gap-2">
          {{"Date and Time:" | translate}}
          <input placeholder="Please click here to select date and time"
            class="w-3/4 p-4 border border-[#e6e6e6] rounded-xl h-[45px]" [(ngModel)]="selectedReminderDate"
            [owlDateTimeTrigger]="dt1" [owlDateTime]="dt1" [min]="today" (ngModelChange)="reminderDateChange($event)">
          <owl-date-time #dt1></owl-date-time>
        </label>
        <span
            class="text-red-500 text-sm"
            *ngIf="reminderFieldError"
            >{{"Field is required" | translate}}</span
        >
    </div>
    <div footer>
        <app-button (handleClick)="addReminder()" type="primary">{{"Set" | translate}}</app-button>
    </div>
</app-modal-container>