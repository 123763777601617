<div class="rounded-lg p-4 border-[1px] new-design-round-border flex gap-4">
  <div class="text-sm text-[#1C1C1C66] mt-2">
    {{ "Emails" | translate }}
  </div>
  <div class="flex flex-col gap-2">
    <div class="flex w-full items-center rounded-lg bg-input-light px-4 py-2">
      <input
        id="newEmail"
        [formControl]="newEmail"
        type="text"
        class="border-0 w-full rounded-lg text-sm bg-transparent flex items-end p-0 content-end flex-wrap gap-4 focus:ring-0 placeholder-gray-200"
        placeholder="{{ 'Add new email' | translate }}"
      />
      <span class="text-base cursor-pointer" (click)="verifyEmail()"> + </span>
    </div>
    <p class="text-xxs text-[#1C1C1C66] py-2">
      {{ "Email listing message" | translate }}
    </p>

    <div class="flex flex-col" *ngIf="emails$ | async as emails">
      <table class="table-fixed">
        <tr *ngFor="let email of emails; trackBy: trackByFn" class="mt-2">
          <td class="w-0">
            <div
              class="flex ltr:mr-2 rtl:ml-2 items-center text-xxs whitespace-nowrap justify-center p-2 gap-2 rounded-md bg-[#F6F6F6]"
              [ngClass]="
                email.type == 'primary' ? 'text-[#12B76A]' : 'text-[#F04438]'
              "
            >
              {{ email.type | titlecase }}
            </div>
          </td>
          <td
            style="word-break: break-word"
            [colSpan]="email.type == 'primary' ? 2 : ''"
          >
            <div class="text-xxs mr-2 text-[#1C1C1C66]">
              {{ email.email }}
            </div>
          </td>
          <td *ngIf="email.type !== 'primary'">
            <div class="flex">
              <button (click)="confirmEmailDelete(email.email)">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="w-6 h-6"
                  height="48px"
                  viewBox="0 -960 960 960"
                  width="48px"
                  fill="#1c1c1c66"
                >
                  <path
                    d="m330-288 150-150 150 150 42-42-150-150 150-150-42-42-150 150-150-150-42 42 150 150-150 150 42 42ZM480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"
                  />
                </svg>
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
    <div class="flex flex-wrap">
      <div *ngIf="response" class="text-green-500 py-1 text-xs">
        {{ response }}
      </div>
      <div *ngIf="error" class="text-[#F04438] py-1 text-xs">
        {{ error }}
      </div>
    </div>
  </div>
</div>

<!-- <div class="p-8 rounded-3xl bg-light-gray-1 mt-6 mb-2 communication-language">
  <h3 class="Gilroy-Bold text-lg">
    {{ "Email Communication Language" | translate }}
  </h3>
  <ng-select
    [items]="availableLanguages"
    bindLabel="name"
    bindValue="code"
    [clearable]="false"
    class="custom-selector my-4"
    [formControl]="communicationLanguage"
    placeholder="{{ 'Select Language' | translate }}"
  >
  </ng-select>
  <app-button type='primary' [rounded]='true' buttonText='{{"Set Language" | translate }}' (handleClick)='setLanguage()'></app-button>
</div> -->

<app-modal-container [isOpen]="deleteEmailModal">
  <div header class="Poppins-Bold border-b pb-2">
    {{ "Remove email" | translate }}
  </div>
  <div content>
    <p class="mb-2">
      {{ "Delete email confirmation message" | translate }}
    </p>
    <p *ngIf="emailToDelete">{{ "Email -" | translate }} {{ emailToDelete }}</p>
  </div>
  <div footer class="flex items-center gap-3">
    <app-submit
      className="bg-[#026AA2] text-white h-10 w-28"
      value="Remove"
      (clicked)="deleteEmail()"
    >
    </app-submit>
    <app-submit
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10"
      value="Cancel"
      (clicked)="deleteEmailModal = false"
    >
    </app-submit>
  </div>
</app-modal-container>
