import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ChartOptions, ChartType } from 'chart.js';
import DataLabelsPlugin from 'chartjs-plugin-datalabels';
import * as moment from 'moment';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { NumplusService } from '../../numplus.service';


@Component({
  selector: 'app-salesdashboard',
  templateUrl: './salesdashboard.component.html',
  styleUrls: ['./salesdashboard.component.scss',
]
})
export class SalesdashboardComponent implements OnInit, OnDestroy {


  public years: Array<any>= [
    {
      name:moment().format('YYYY'),
      value: moment().format('YYYY')
    },
    {
      name:(Number(moment().format('YYYY')) + 1).toString(),
      value:(Number(moment().format('YYYY')) +1).toString()
    },
    {
      name:(Number(moment().format('YYYY')) + 2).toString(),
      value:(Number(moment().format('YYYY')) +2).toString()
    }
  ]

  public monthsName:Array<any> = [
    {
      name:'January',
      value:'January'
    },
    {
      name:'February',
      value:'February'
    },
    {
      name:'March',
      value:'March'
    },
    {
      name:'April',
      value:'April'
    },
    {
      name:'May',
      value:'May'
    },
    {
      name:'June',
      value:'June'
    },
    {
      name:'July',
      value:'July'
    },
    {
      name:'August',
      value:'August'
    },
    {
      name:'September',
      value:'September'
    }, {
      name:'October',
      value:'October'
    }, {
      name:'November',
      value:'November'
    }, {
      name:'December',
      value:'December'
    }
  ]

  public typeOfChart:Array<any> =[
    {
      name:'Sales Growth',
      value:'sales'
    },
    {
      name:'Sales & Target',
      value:'salestarget'
    }
  ]

  public monthsData = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
  'September', 'October', 'November', 'December']

  public salesGrowthChartColors: any[] = [];
  public salesGrowthNumbers:Array<any> = []
  public salesGrowOptions = {
    responsive: true,
    maintainAspectRatio: true,
    scales:{
      yAxes:[{
        gridLines:{
          display:false,
          color: '#c8c8c8A4',
        },
        ticks: {
          beginAtZero: true,
          suggestedMax: 500,
          callback: function(value) {
            // Format the value
            if (value >= 100) {
              return (value / 100) + 'k';
            }
            return value;
          }
        },
        stacked:true,
        display:true,
      }],
      xAxes:[{
        gridLines:{
          display:false
        },
        ticks:{
          fontFamily:'Poppins',
          padding:10,
        },
        stacked:true,
        display:true
      }]
    },
    plugins:{
      datalabels:{
        color:'#333',
        anchor: 'start',
        align:'top',
        rotation:270,
        font: {
          weight: 'bold'
        }
      },

    }
  };

  public salesGrowLabels: Label[] = [];
  public salesGrowType: ChartType = 'bar';
  public salesGrowLegend = false;
  public salesGrowPlugins = [
    DataLabelsPlugin
  ];

  public salesGrowData: Array<any> = [
      {
        data: [],
        label:this.translateService.instant('Sales Growth in %'),
        type:'bar',
        fill:false,
        barPercentage: 0.5  ,
        backgroundColor:[],
        borderColor:[]
      },
  ];

  // Sales And Target Starts here

  public salesAndTargetChartColors: any[] = [];

  public salesAndTargetNumbers:Array<number> = []
  public salesTargetNumbers:Array<number> = []
  public salesAndTargetOptions = {
      responsive: true,
      maintainAspectRatio: true,
      scales:{
        yAxes:[{
          gridLines:{
            display:false,
            color: '#c8c8c8A4'
          },
          ticks: {
            beginAtZero: true,
            suggestedMax: 500,
            callback: function(value) {
              // Format the value
              if (value >= 100) {
                return (value / 100) + 'k';
              }
              return value;
            }
          },
          stacked:false,

          display:true
        }],
        xAxes:[{
          gridLines:{
            display:false
          },
          ticks:{
            fontFamily:'Poppins'
          },
          stacked:false,
          display:true
        }],
      },
      plugins:{
        datalabels:{
          color:'#333',
          anchor: 'start',
          align:'top',
          rotation:270,
          font: {
            weight: 'bold'
          },
        },
      }
  };

  public salesAndTargetLabels: Label[] = [];
  public salesAndTargetType: ChartType = 'bar';
  public salesAndTargetLegend = false;
  public salesAndTargetPlugins = [
      DataLabelsPlugin
  ];

  public salesAndTargetData: Array<any> = [
        {
          data: [],
          label:[this.translateService.instant('Sales')],
          type:'bar',
          backgroundColor:[],
          borderColor:[],
        },
        {
          data: [],
          label:this.translateService.instant('Target'),
          type:'bar',

          backgroundColor:['rgb(220,220,220)'],
          borderColor:['rgb(21,71,6)'],
        },
  ];

  // Sales And Target ends here

  public selectedMonth;
  public selectedYear;
  public selectedType= 'sales'
  unsubscribe$ = new Subject();
  unsubscribe = new Subject();
  salesData;
  budgetSalesData;
  totalYearSales = 0;
  totalYearTarget = 0;
  totalYearGrowth
  totalPreviousYearSales = 0;
  accounts$:Observable<any>;
  business$: Observable<any>;
  businessId = null;
  fiscalMonth =null



  constructor(
    private store: Store<RootReducerState>,
    private rootStore:Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private translateService: TranslateService,
    private numberService:NumberService,
    private numplusService: NumplusService,
    private toastr: ToastrService) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
    }

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id;
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
        this.years= this.years.map(item => item.name +"(" + this.fiscalMonth?.slice(0,3) +" " + item.name + "-" + this.createMonthsAccToFiscalMonth(this.monthsData,this.fiscalMonth, item.name) +")" )
        this.selectedYear = moment().format('YYYY') +"(" + this.fiscalMonth?.slice(0,3) +" " + moment().format('YYYY') + "-" + this.createMonthsAccToFiscalMonth(this.monthsData,this.fiscalMonth,  moment().format('YYYY')) +")"
        console.log("this.monthsData,this.fiscalMonth", this.monthsData,this.fiscalMonth);
      }
        
    })

    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.dataChange();
      }
    });
  }

  createMonthsAccToFiscalMonth(monthsData:any, fiscalMonth:string, year:any){
    console.log("createMonthsAccToFiscalMonth", monthsData);
    
    const index = monthsData.findIndex((month) => month === fiscalMonth)
    const firstHalf = monthsData.slice().splice(0,index)
    const secondHalf = monthsData.slice().splice(index,monthsData.length)
    console.log("First Half", monthsData, firstHalf, secondHalf)
    let newMOnths = [...secondHalf,...firstHalf]
    if(firstHalf.length > 0 ){
      let newYear = Number(year) + 1
      return newMOnths[11].slice(0,3) +" " + newYear
    }
    return newMOnths[11].slice(0,3) +" " + year
  }

  salesAndTargetPercentage(sales, target){
    if(sales === 0 && target === 0){
      return 0
    }
    if(sales !== 0 && target === 0){
      let target2 = 1
      let result = (sales/target2)*100
      return result
    }
    let result = (sales/target)*100
    return result
  }

  dataChange(): void {
    this.salesGrowLabels = []
    // this.salesGrowData[0].data = []

    this.salesAndTargetLabels = []
    // this.salesAndTargetData[0].data =[]
    // this.salesAndTargetData[1].data = []
    const filterData = {
      "financialMonth": this.fiscalMonth,
      "year": this.selectedYear.split('(')[0]
    }
    this.getSalesData(filterData)
    this.getAccountBudgetSalesData(filterData)
  }

  getSalesData(filterData){
    this.numplusService.businessId$.subscribe((id) =>{
      if(id){
        this.spinner.show();
        this.numplusService.getSalesStatisticFlow(filterData).subscribe(resp =>{
          this.salesData = resp.data.statisticArray
          console.table(this.salesData)
          this.salesData.forEach(data =>{
            this.salesGrowLabels.push(data.month + "-" + data.year)
            this.totalYearSales += data.totalIncome
            this.totalPreviousYearSales += data.previousTotalIncome
            let growth = this.calculateGrowth(data.totalIncome, data.previousTotalIncome)
            let colorCode = growth < 0 ? 'rgb(206, 19, 19)' : 'rgb(12, 156, 213)';
            console.log("salesGrowData", growth, this.salesGrowData);
            if(this.salesGrowData[0]){
              this.salesGrowData[0].backgroundColor.push(colorCode)
              this.salesGrowData[0].data.push(growth)
            }
          })
          this.totalYearGrowth = this.calculateGrowth(this.totalYearSales, this.totalPreviousYearSales)
          this.spinner.hide();
        }, (error)=>{
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        })
      }
    })
  }

  getAccountBudgetSalesData(filterData){
    this.numplusService.businessId$.subscribe((id) =>{
      if(id){
        this.spinner.show();
        this.numplusService. getAccountBudgetStatisticFlow(filterData).subscribe(resp =>{
           let respData = resp.data.statisticArray
           respData.forEach(data =>{
            this.salesAndTargetLabels.push(data.month + "-" + data.year)
            let sales = data.totalIncome
            let target = data.totalIncomeBudget
            this.totalYearTarget += data.totalIncomeBudget
            let colorCode =this.salesAndTargetPercentage(sales, target ) >= 100 ? 'rgb(12, 156, 213)'
            :this.salesAndTargetPercentage(sales, target) <  90 ? 'rgb(206, 19, 19)'
            : 'rgb(255, 234, 0)';
            this.salesAndTargetData[0].backgroundColor.push(colorCode);
            this.salesAndTargetData[0].data.push(sales);
            this.salesAndTargetData[1].data.push(target);
          })
          console.table(resp.data.statisticArray)

          this.spinner.hide();
        }, (error)=>{
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        })
      }
    })
  }

  calculateGrowth(currentIncome: number, lastIncome: number): number{
    console.log("Calculate Growth ", currentIncome, lastIncome)
    if(currentIncome === 0 && lastIncome === 0){
      return 0
    } else  if(currentIncome !== 0 && lastIncome === 0){
      let lastIncome2 = 1
      let amount = ((currentIncome - lastIncome)/lastIncome2)*100
      return amount
    } else {
      let amount = ((currentIncome - lastIncome)/lastIncome)*100
      return amount
    }
  }



  ngOnDestroy():void{
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }


}
