import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-overdue-details',
  templateUrl: './overdue-details.component.html',
  styleUrls: ['./overdue-details.component.scss']
})
export class OverdueDetailsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
