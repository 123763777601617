import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class InventoryService {

  constructor(private httpService: HttpService) { }

  getBarCode(businessId, itemCode): Observable<any> {
    return this.httpService.post(`/inventory/generateBarCode/${businessId}`, {itemCode});
  }

  createProduct(businessId, productData): Observable<any> {
    return this.httpService.postMultipart(`/inventory/createItem/${businessId}`, productData);
  }

  updateProduct(productData, productId): Observable<any> {
    return this.httpService.putMultipart(`/inventory/updateItem/${productId}`, productData);
  }

  getProducts(businessId): Observable<any> {
    return this.httpService.get(`/inventory/getProduct/${businessId}`);
  }

  getProductionRun(businessId): Observable<any> {
    return this.httpService.get(`/inventory/getProductionRun/${businessId}`);
  }

  getComplexProduct(businessId): Observable<any> {
    return this.httpService.get(`/inventory/getComplexProduct/${businessId}`);
  }

  runProduction(businessId, data): Observable<any> {
    return this.httpService.post(`/inventory/runProduction/${businessId}`, data);
  }

  getWriteOffProducts(businessId): Observable<any> {
    return this.httpService.get(`/inventory/getWriteOff/${businessId}`);
  }

  createWriteOff(businessId, data): Observable<any> {
    return this.httpService.post(`/inventory/writeOffProduct/${businessId}`, data);
  }

  getAccountsOfWriteOff(businessId): Observable<any> {
    return this.httpService.get(`/account/getWriteOffAccount/${businessId}`);
  }

  reverseProduction(businessId, data): Observable<any> {
    return this.httpService.post(`/inventory/reverseProduction/${businessId}`, data);
  }

  getProductById(productId): Observable<any> {
    return this.httpService.get(`/inventory/getProductById/${productId}`);
  }

  deleteProduct(productId): Observable<any> {
    return this.httpService.delete(`/inventory/deleteProduct/${productId}`);
  }

  getStatistics(businessId): Observable<any> {
    return this.httpService.get(`/inventory/getStatics/${businessId}`);
  }

  filterProducts(storeId): Observable<any> {
    return this.httpService.get(`/inventory/getItemByStore/${storeId}`);
  }

  checkSKU(businessId, sku): Observable<any> {
    return this.httpService.post(`/inventory/checkSku/${businessId}`, {sku});
  }
  getProdCategoryByBusiness(businessId:any) {
    return this.httpService.get(`/inventory/getProdCategoryByBusiness/${businessId}`)
  }
  getProductsListByBusiness(businessId:any) {
    return this.httpService.get(`/inventory/getRequireProduct/${businessId}`)
  }
}
