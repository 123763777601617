import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ChartsOfAccountsComponent } from './charts-of-accounts.component';
import { AccountFormComponent } from './component/account-form/account-form.component';
import { RouterModule, Routes } from '@angular/router';
import { AssetsComponent } from './component/assets/assets.component';
import { AccountsHeaderComponent } from './component/accounts-header/accounts-header.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';

const routes: Routes = [
    {
        path: ':type',
        component: AssetsComponent
    },
    {
        path: '**',
        redirectTo: 'assets'
    }
];

@NgModule({
    declarations: [
        ChartsOfAccountsComponent,
        AccountFormComponent,
        AssetsComponent,
        AccountsHeaderComponent,
    ],
    imports: [
        DirectivesModule,
        CommonModule,
        SharedModule,
        NgSelectModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(routes),
        TranslateModule
    ]
})

export class ChartsModule { }
