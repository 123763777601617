<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <div>
      <form [formGroup]="billForm">
        <div class="flex flex-col justify-between">
          <div class="flex gap-2 text-xl items-center pb-2">
            <label class="text-[#1C1C1C66]">{{
              "Bill Number" | translate
            }}</label>
            <fieldset disabled="disabled">
              <input
                id="billNumber"
                formControlName="billNumber"
                type="text"
                class="w-full bg-input-light border-[#1c1c1c33] rounded-lg h-11"
              />
            </fieldset>
            <div
              class="text-sm text-red-500 ms-2 mt-1"
              *ngIf="formErrors.billNumber"
            >
              {{ formErrors.purchaseOrder }}
            </div>
          </div>
          <div class="grid grid-cols-3 gap-4 gap-x-6">
            <div class="flex-grow flex flex-col gap-2">
              <div class="flex justify-between items-end">
                <label class="text-base Poppins-Medium">{{
                  "Bill to" | translate
                }}</label>
                <span
                  class="text-xxs leading-0 text-[#898989] cursor-pointer"
                  routerLink="/purchases/vendors/create-vendor"
                >
                  {{ "Add New Vendor" | translate }}
                </span>
              </div>
              <ng-select
                [items]="vendors"
                class="new-dropdown"
                bindLabel="vendorName"
                formControlName="vendor"
                placeholder="{{ 'Select one' | translate }}"
                (change)="changeEventVendor($event)"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.vendorName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.vendor"
              >
                {{ formErrors.vendor }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Date" | translate
              }}</label>
              <input
                (change)="reloadBillNumber()"
                id="date"
                formControlName="date"
                format="DD/MM"
                type="date"
                class="new-date"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.date"
              >
                {{ formErrors.date }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Due Date" | translate
              }}</label>
              <input
                id="dueDate"
                formControlName="dueDate"
                type="date"
                class="new-date"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.dueDate"
              >
                {{ formErrors.dueDate }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Vendor Invoice" | translate
              }}</label>
              <input
                id="purchaseOrder"
                formControlName="purchaseOrder"
                type="text"
                class="new-input"
                placeholder="{{ 'Ex. 1234567' | translate }}"
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.purchaseOrder"
              >
                {{ formErrors.purchaseOrder }}
              </div>
            </div>
            <div class="flex-grow flex flex-col gap-2">
              <label class="text-base Poppins-Medium">{{
                "Project" | translate
              }}</label>
              <ng-select
                [items]="projects"
                class="new-dropdown"
                bindLabel="projectName"
                bindValue="_id"
                formControlName="projectId"
                placeholder="{{ 'Select Project Name' | translate }}"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.projectName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
            </div>
          </div>
        </div>
        <table class="min-w-full divide-y divide-gray-300 mt-[2rem]">
          <thead class="h-12 text-[#1C1C1C66] border-[#1C1C1C33] border-b">
            <tr class="text-left">
              <td class="ps-5">
                {{ "Item" | translate }}
              </td>
              <td class="px-2">{{ "Quantity" | translate }}</td>
              <td class="px-2">{{ "Unit Price" | translate }}</td>
              <td class="px-2">{{ "Select Tax" | translate }}</td>
              <td class="px-2">{{ "Total" | translate }}</td>
              <td class="rounded-te-xl"></td>
            </tr>
          </thead>
          <tbody>
            <ng-container formArrayName="items">
              <ng-container
                *ngFor="let itemList of items.controls; let i = index"
              >
                <tr [formGroup]="itemList" class="">
                  <td class="w-2/12 p-2">
                    <ng-select
                      [items]="availableProducts"
                      groupBy="type"
                      class="new-dropdown"
                      bindLabel="name"
                      bindValue="name"
                      formControlName="item"
                      placeholder="{{ 'Select one' | translate }}"
                      [clearable]="false"
                      (change)="changeEvent($event, i)"
                    >
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2">
                    <input
                      id="unit"
                      formControlName="unit"
                      type="number"
                      class="new-input"
                      placeholder="0"
                    />
                  </td>
                  <td class="p-2">
                    <input
                      id="price"
                      formControlName="price"
                      type="number"
                      class="new-input"
                      placeholder="0"
                    />
                  </td>
                  <td class="p-2 w-2/12">
                    <ng-select
                      class="new-dropdown"
                      [items]="salesTaxes"
                      bindLabel="name"
                      formControlName="tax"
                      (change)="changeTax($event, i)"
                      placeholder="{{ 'Select Tax' | translate }}"
                    >
                      <ng-template ng-label-tmp let-item="item">
                        <span class="ng-value-label"
                          >{{ item.taxName }}
                          {{ item.tax | number : number }}%</span
                        >
                      </ng-template>
                      <ng-template
                        ng-option-tmp
                        let-item="item"
                        let-index="index"
                      >
                        <div class="px-2 py-1">
                          <div
                            class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                          >
                            {{ item.name }}
                          </div>
                        </div>
                      </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2">
                    <p>
                      {{
                        items.controls[i].get("unit").value *
                          items.controls[i].get("price").value
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                  </td>
                  <td class="w-1/12">
                    <svg
                      (click)="removeItem(i)"
                      xmlns="http://www.w3.org/2000/svg"
                      name="delete"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="mx-auto h-5 w-5 text-red-500"
                    >
                      <path
                        _ngcontent-hsm-c58=""
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      ></path>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td colspan="2"></td>
                  <td>
                    <p
                      class="text-red-500 whitespace-nowrap"
                      *ngIf="itemList.get('unit').hasError('min')"
                    >
                      {{ "Quantity should be more than 0" }}
                    </p>
                  </td>
                  <td colspan="5"></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="items.controls.length === 0">
                <tr>
                  <td colspan="7" class="text-center p-2">
                    {{ "No item added" | translate }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td vAlign="top" colspan="4">
                <div class="ps-2 text-black">
                  <button
                    (click)="addNewItem()"
                    id="add"
                    class="flex items-center space-x-4"
                  >
                    <span
                      class="p-2 rounded-md border border-[#1C1C1C0D] bg-[#f6f6f6c9] flex gap-2 me-2 px-11"
                    >
                      <img
                        src="assets/images/delete-icon.svg"
                        style="transform: rotate(45deg)"
                        alt=""
                      />
                      {{ "Add a Line" | translate }}
                    </span>
                  </button>
                </div>
              </td>
              <td colspan="4" class="pe-2">
                <div class="flex justify-between py-2">
                  <p>{{ "Subtotal:" | translate }}</p>
                  <p>
                    {{
                      subTotal
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                </div>
                <div class="flex justify-between py-2">
                  <p>{{ "Tax:" | translate }}</p>
                  <p>
                    {{
                      totalTax
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                </div>
                <div class="flex justify-between py-2 border-t Poppins-Medium">
                  <p>{{ "Total Amount:" | translate }}</p>
                  <p>
                    {{
                      totalAmount
                        | currency
                          : currencyDetails?.currency
                          : "symbol-narrow"
                          : number
                    }}
                  </p>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <div
          class="mt-4 flex flex-col gap-2 max-h-[7.5rem] h-[7.5rem] relative"
        >
          <label class="text-sm text-[#1C1C1C66] Poppins-Medium">{{
            "Notes & Terms" | translate
          }}</label>
          <div class="flex gap-28">
            <div class="flex flex-col w-1/3">
              <textarea
                formControlName="notes"
                class="new-input !min-h-[7rem]"
                placeholder="{{ 'Add some notes' | translate }}"
              ></textarea>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.notes"
              >
                {{ formErrors.notes }}
              </div>
            </div>
            <div class="w-1/3">
              <app-file-upload
                appAccessControl
                moduleType="storage"
                accessType="edit"
                [addedFiles]="addedFiles"
                (emitter)="saveFiles($event)"
                [type]="'compact'"
              ></app-file-upload>
            </div>
            <div
              appAccessControl
              moduleType="purchase"
              accessType="edit"
              class="flex flex-col items-center w-1/3 justify-start"
            >
              <div class="flex flex-col w-1/2 gap-4">
                <app-button
                  [buttonText]="update ? 'Update' : 'Save'"
                  type="primary"
                  (handleClick)="update ? updatebill() : createBill()"
                  fullWidth="true"
                ></app-button>
                <app-button
                  buttonText="Discard"
                  routerLink="../"
                  fullWidth="true"
                ></app-button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>

  <app-modal-container [isOpen]="outOfStock">
    <div header class="text-start border-b pb-2">
      {{ "Check Items" | translate }}
    </div>
    <div class="text-start" content>
      <p>Following items have insufficient quantity in inventory:</p>
      <ul>
        <li class="Poppins list-disc" *ngFor="let item of checkQuantityInStock">
          <div>
            {{ item?.item }} added with {{ item?.quantityAdded }} units has
            {{ item?.openingStock }} units only
          </div>
        </li>
      </ul>
    </div>
    <div footer class="flex items-center ms-auto gap-x-2">
      <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
      <app-button
        buttonText="{{ 'Ok' | translate }}"
        type="primary"
        (handleClick)="outOfStock = false"
      ></app-button>
    </div>
  </app-modal-container>
</div>
