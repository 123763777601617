<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-xl space-y-6">
    <div class="w-full flex justify-end">
      <div class="flex gap-2 items-center">
        <app-button
          appAccessControl
          moduleType="projects"
          accessType="edit"
          type="primary"
          buttonText="{{ 'Today' | translate }}"
          size="large"
          (click)="today()"
        ></app-button>
        <div
          class="px-3 h-[50px] flex text-center bg-input-light justify-center items-center rounded-lg shadow gap-2"
        >
          <div class="text-sm font-Medium">{{ "Total Work:" | translate }}</div>
          <div class="text-sm">
            {{ weeklyTotal | timeFormat }}
          </div>
        </div>
      </div>
    </div>
    <div class="w-full">
      <app-week-paginator
        [inputDate]="activeDate"
        [week]="week"
        (handlePagination)="handlePagination($event)"
        (handleActiveDate)="handleActiveDate($event)"
      ></app-week-paginator>
    </div>
    <div class="w-full">
      <app-register-hours-form
        [registerHourForm]="registerHourForm"
        [projects]="projects"
        [customers]="customers"
        [tasks]="tasks"
        [min]="minDateTime"
        [max]="maxDateTime"
        [isTimer]="isTimer"
        [disabled]="notClickable"
        [formErrors]="formErrors"
        (submit)="createTimeSheet($event)"
      ></app-register-hours-form>
    </div>
    <div class="w-full flex flex-col">
      <table
        class="w-full border-separate max-w-[90rem]"
        style="border-spacing: 0 1rem"
      >
        <thead>
          <tr class="h-12 mb-4">
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left w-20"
            >
              {{ "From" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left w-20"
            >
              {{ "Until" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left"
            >
              {{ "Description" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left"
            >
              {{ "Customer" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left"
            >
              {{ "Project" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left"
            >
              {{ "Task" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 text-left w-20"
            >
              {{ "Type" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 w-20"
            >
              {{ "Paused" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 w-20"
            >
              {{ "Worked" | translate }}
            </th>
            <th
              class="Poppins border-b text-[#1C1C1C66] border-[#1C1C1C33] font-light p-2 w-24"
            >
              {{ "Action" | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="divide-y">
          <tr
            *ngFor="
              let dataItem of tableData
                | paginate
                  : {
                      itemsPerPage: itemsPerPage,
                      currentPage: currentPage,
                      totalItems: totalCount
                    }
            "
            class="h-12"
            [ngClass]="{ 'bg-yellow-100 rounded-xl': dataItem?.active }"
          >
            <td class="p-2">
              {{ dataItem?.by | date : "HH:mm" | empty }}
            </td>
            <td class="p-2">
              <ng-container
                *ngIf="
                  dataItem.state === 'START' || dataItem.state === 'RESUME';
                  then nowTimerBlock;
                  else untilTimerBlock
                "
              ></ng-container>
              <ng-template #nowTimerBlock>NOW</ng-template>
              <ng-template #untilTimerBlock>{{
                dataItem?.until | date : "HH:mm" | empty
              }}</ng-template>
            </td>
            <td class="p-2">{{ dataItem?.description | empty }}</td>
            <td class="p-2">{{ dataItem?.customerName | empty }}</td>
            <td class="p-2">{{ dataItem?.projectName | empty }}</td>
            <td class="p-2">{{ dataItem?.taskTitle | empty }}</td>
            <td class="p-2">
              {{ (dataItem?.isManual ? "Manual" : "Auto") | translate }}
            </td>
            <td class="p-2 text-center">{{ dataItem?.paused | timeFormat }}</td>
            <td class="p-2 text-center">
              <ng-container
                *ngIf="
                  dataItem.state === 'START' || dataItem.state === 'RESUME';
                  then secondTimerBlock;
                  else minuteTimerBlock
                "
              ></ng-container>
              <ng-template #secondTimerBlock>{{
                dataItem.totalWorked + dataItem.tempTotalWorked
                  | timeFormat : true
              }}</ng-template>
              <ng-template #minuteTimerBlock>{{
                dataItem.totalWorked | timeFormat
              }}</ng-template>
            </td>
            <td class="p-2 flex justify-center items-center">
              <ng-container *ngIf="dataItem.state; else nullBlock">
                <ng-container
                  *ngIf="
                    dataItem.state === 'START' || dataItem.state === 'RESUME';
                    then stopTimerBlock;
                    else resumeTimerBlock
                  "
                ></ng-container>
              </ng-container>
              <ng-template #stopTimerBlock>
                <span
                  class="me-2 h-8 w-8 rounded-lg flex justify-center items-center border border-gray-200"
                  title="Stop timer"
                  (click)="stopTimer(dataItem._id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                    fill="#333333"
                    class="w-6 h-6"
                  >
                    <path
                      d="M320-640v320-320Zm-80 400v-480h480v480H240Zm80-80h320v-320H320v320Z"
                    />
                  </svg>
                </span>
              </ng-template>
              <ng-template #resumeTimerBlock>
                <span
                  class="me-2 h-8 w-8 rounded-lg flex justify-center items-center border border-gray-200"
                  title="Resume timer"
                  (click)="resumeTimer(dataItem._id)"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -960 960 960"
                    fill="#333333"
                    class="w-6 h-6"
                  >
                    <path
                      d="M320-200v-560l440 280-440 280Zm80-280Zm0 134 210-134-210-134v268Z"
                    />
                  </svg>
                </span>
              </ng-template>
              <ng-template #nullBlock>
                <span
                  class="me-2 flex justify-center items-center h-8 w-8"
                ></span>
              </ng-template>
              <app-dropdown #dropdown>
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 9l-7 7-7-7"
                      />
                    </svg>
                  </div>
                </div>
                <div options class="p-2">
                  <div
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                    (click)="openUpdateModal(dataItem)"
                  >
                    {{ "View" | translate }}
                  </div>
                  <div
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                    (click)="openUpdateModal(dataItem, true)"
                    *ngIf="!dataItem.isMark"
                  >
                    {{ "Edit" | translate }}
                  </div>
                  <div
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left text-red-600"
                    (click)="openConfirmDeleteTimeSheetModal(dataItem)"
                  >
                    {{ "Delete" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </tr>
          <tr *ngIf="tableData.length !== 0">
            <td colspan="10" class="text-right Poppins-Medium pe-16">
              {{"Page Total" | translate }} - {{ pageTotal | timeFormat }}
            </td>
          </tr>
          <tr *ngIf="tableData.length === 0">
            <td colspan="10" class="text-center">{{"No record found" | translate}}</td>
          </tr>
        </tbody>
      </table>
      <pagination-controls
        class="new-pagination"
        previousLabel=""
        nextLabel=""
        autoHide="true"
        (pageChange)="handleActiveDate(activeDate, $event)"
      ></pagination-controls>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="showUpdateModal">
  <div header class="Poppins-Medium" *ngIf="!isEditable">
    {{ "Time Sheet Detail" | translate }}
  </div>
  <div header class="Poppins-Medium" *ngIf="isEditable">
    {{ "Update Time Sheet" | translate }}
  </div>
  <div
    content
    class="flex flex-col space-y-2"
    [formGroup]="updateRegisterHourForm"
  >
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label for="status" class="block text-sm Poppins-Medium font-color-05">
        {{ "Status" | translate }}
      </label>
      <div class="flex gap-4">
        <div class="flex gap-4">
          <span
            class="text-white py-2 px-4 rounded-xl text-sm font-semiMedium"
            [title]="dataItem?.isMark ? 'Reviewed' : 'Not Reviewed yet'"
            [ngClass]="dataItem?.isMark ? 'bg-green-400' : 'bg-gray-300'"
            >{{ "Reviewed" | translate }}</span
          >
        </div>
      </div>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label
        for="time-sheet-date"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Time Sheet Date" | translate }}
      </label>
      <p>{{ dataItem?.timeSheetDate | empty }}</p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="from" class="block text-sm Poppins-Medium font-color-05">
        {{ "From" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="from"
        [min]="minDateTime"
        [max]="maxDateTime"
        formControlName="by"
        type="datetime-local"
        class="new-date"
        placeholder="00:00"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.by | date : "MMM dd, y HH:mm" | empty }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.by" class="text-red-600">{{
      updateFormErrors?.by
    }}</span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="until" class="block text-sm Poppins-Medium font-color-05">
        {{ "Until" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="until"
        [min]="minDateTime"
        [max]="maxDateTime"
        formControlName="until"
        type="datetime-local"
        class="new-date"
        placeholder="00:00"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.until | date : "MMM dd, y HH:mm" | empty }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.until" class="text-red-600">{{
      updateFormErrors?.until
    }}</span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="paused" class="block text-sm Poppins-Medium font-color-05">
        {{ "Break duration" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="paused"
        formControlName="paused"
        type="number"
        min="0"
        class="new-input"
        placeholder="{{ 'Enter break duration in minutes' | translate }}"
      />
      <p *ngIf="!isEditable">
        {{ dataItem?.paused | timeFormat }}
      </p>
    </div>
    <span *ngIf="updateFormErrors?.paused" class="text-red-600">{{
      updateFormErrors?.paused
    }}</span>
    <div
      class="rounded-xl px-3 py-2 space-y-2 bg-white"
      *ngIf="!isEditable && dataItem?.taskDuration"
    >
      <label for="paused" class="block text-sm Poppins-Medium font-color-05">
        {{ "Task duration" | translate }}
      </label>
      <p>
        {{ dataItem?.taskDuration * 3600 | timeFormat }}
      </p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white" *ngIf="!isEditable">
      <label
        for="time-sheet-date"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Total Work" | translate }}
      </label>
      <p>
        {{ dataItem?.totalWorked | timeFormat }}
      </p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label
        for="description"
        class="block text-sm Poppins-Medium font-color-05"
      >
        {{ "Description" | translate }}
      </label>
      <input
        *ngIf="isEditable"
        tabindex="1"
        id="description"
        formControlName="description"
        type="text"
        class="new-input"
        placeholder="{{ 'Enter description' | translate }}"
      />
      <p *ngIf="!isEditable">{{ dataItem?.description | empty }}</p>
    </div>
    <span *ngIf="updateFormErrors?.description" class="text-red-600">{{
      updateFormErrors?.description
    }}</span>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="customer" class="block text-sm Poppins-Medium font-color-05">
        {{ "Customer" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="5"
        [items]="customers"
        class="new-dropdown"
        bindLabel="customerName"
        bindValue="_id"
        formControlName="customerId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.customerName }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.customerName | empty }}</p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="project" class="block text-sm Poppins-Medium font-color-05">
        {{ "Project" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="6"
        [items]="projects"
        class="new-dropdown"
        bindLabel="projectName"
        bindValue="_id"
        formControlName="projectId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.projectName }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.projectName | empty }}</p>
    </div>
    <div class="rounded-xl px-3 py-2 space-y-2 bg-white">
      <label for="task" class="block text-sm Poppins-Medium font-color-05">
        {{ "Task" | translate }}
      </label>
      <ng-select
        *ngIf="isEditable"
        tabindex="6"
        [items]="tasks"
        class="new-dropdown"
        bindLabel="taskTitle"
        bindValue="_id"
        formControlName="taskId"
        placeholder="{{ 'Select One' | translate }}"
        groupBy="section"
      >
        <ng-template ng-option-tmp let-item="item" let-index="index">
          <div class="px-2 py-1">
            <div
              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
            >
              {{ item.taskTitle }}
            </div>
          </div>
        </ng-template>
      </ng-select>
      <p *ngIf="!isEditable">{{ dataItem?.taskTitle | empty }}</p>
    </div>
    <span *ngIf="updateFormErrors?.customerId" class="text-red-600">{{
      updateFormErrors?.customerId
    }}</span>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      *ngIf="isEditable"
      type="primary"
      buttonText="{{ 'Update' | translate }}"
      (handleClick)="handleUpdate()"
    ></app-button>
    <app-button
      *ngIf="isEditable"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="closeUpdateModal()"
    ></app-button>
    <app-button
      *ngIf="!isEditable"
      buttonText="{{ 'Close' | translate }}"
      (handleClick)="showUpdateModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="confirmDeleteTimeSheetModal">
  <div header class="Poppins-Medium">
    {{ "Delete Time Sheet" | translate }}
  </div>
  <div content>
    <p>{{ "Are you sure you want to delete this time sheet?" | translate }}</p>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="danger"
      buttonText="{{ 'Delete' | translate }}"
      (handleClick)="handleDelete()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="confirmDeleteTimeSheetModal = false"
    ></app-button>
  </div>
</app-modal-container>
