<app-document-container>
    <div id="template-option-one" class="w-full">
        <div class="p-8 text-white" style="border-bottom: 1px solid #0C4D71; background-color: #0C4D71">
            <table class="w-full border-collapse">
                <tr>
                    <td vAlign='top' class="w-1/2 ps-8">
                        <div class="text-3xl Poppins-Bold uppercase">{{invoiceData?.title}}</div>
                        <div class="text-xl">{{invoiceData?.subHeading}}</div>
                    </td>
                    <td vAlign='top' class="pe-8 text-end" >
                        <div *ngIf='invoiceData?.companylogo' class="w-[200px] h-[112px] mb-2 ms-auto">
                            <img [src]="invoiceData?.companylogo" alt="">
                        </div>
                        <div class="Poppins-Bold text-lg">{{invoiceData?.businessDetails?.businessId?.companyName}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.addressLineFirst}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.addressLine2}}</div>
                        <div >{{invoiceData?.businessDetails?.businessId?.address?.city ? invoiceData?.businessDetails?.businessId?.address?.city + ',' : '' }} {{invoiceData?.businessDetails?.businessId?.address?.state || ''}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.country}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.entityId ?  'Entity ID -' + invoiceData?.businessDetails?.businessId?.entityId : ''}}</div>
                        <div>{{invoiceData?.businessDetails?.businessId?.taxId ? 'Tax ID -' + invoiceData?.businessDetails?.businessId?.taxId : ''}}</div>
                    </td>
                </tr>
            </table>
        </div>
        <div class="py-8 px-12">
            <table class="w-full border-collapse">
                <tr>
                    <td class="w-1/2">
                        <table>
                            <tr >
                                <td valign='top' class="Poppins-Bold" style="color: #0C4D71;">{{'BILL TO' | translate }}</td>
                                <td class="ps-4 text-start">
                                    <div>{{invoiceData?.customerDetails?.customerId?.customerName ?? invoiceData?.customerDetails?.customerId?.mobileNumber  | titlecase}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine1}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.addressLine2}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.city + invoiceData?.customerDetails?.customerId?.billingAddress?.city ? ', ' : '' }} {{invoiceData?.customerDetails?.customerId?.billingAddress?.state}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.postalCode}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.billingAddress?.country}}</div>
                                    <div>{{invoiceData?.customerDetails?.customerId?.mobileNumber}}</div>
                                </td>
                            </tr>
                        </table>
                    </td>
                    <td >
                        <table class="border-collapse text-end w-full">
                            <tr>
                                <td class="Poppins-Bold" style="color: #0C4D71;">{{'Invoice Number' | translate }}</td>
                                <td class="ps-4" >{{invoiceData?.invoiceNumber}}</td>
                            </tr>
                            <tr>
                                <td class="Poppins-Bold" style="color: #0C4D71;">{{'PO Number' | translate }}</td>
                                <td class="ps-4" >{{invoiceData?.purchaseOrder}}</td>
                            </tr>
                            <tr>
                                <td class="Poppins-Bold" style="color: #0C4D71;">{{'Invoice Date' | translate }}</td>
                                <td class="ps-4" >{{invoiceData?.invoiceDate | date}}</td>
                            </tr>
                            <tr>
                                <td class="Poppins-Bold" style="color: #0C4D71;">{{'Due Date' | translate }}</td>
                                <td class="ps-4" >{{invoiceData?.dueDate | date}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>
            <table class="w-full" style="margin-top: 2em; margin-bottom: 2em; border-bottom: 1px solid black;">
                <thead>
                    <tr>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Item Name' | translate}}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Description' | translate}}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'SKU' | translate }}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'HSN/SAC' | translate }}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Quantity' | translate }}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Unit Price' | translate }}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Tax' | translate }}</td>
                        <td class="Poppins-Bold p-2 text-white" style="background-color: #0C4D71;">{{'Total Amount' | translate }}</td>
                    </tr>
                </thead>
                <tbody>
                    <tr class="" *ngFor='let item of invoiceData?.items'>
                        <td class="p-2" >{{item.item}}</td>
                        <td class="p-2 break-words" >{{item.itemDescription || '-' }}</td>
                        <td class="p-2" >{{item.hsn_sac }}</td>
                        <td class="p-2" >{{item.unit }}</td>
                        <td class="p-2" >{{item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                        <td class="p-2" *ngIf='item?.tax?.tax'>{{item?.tax?.tax}}%</td>
                        <td class="p-2" *ngIf='!item?.tax?.tax'>No Tax</td>
                        <td class="p-2" >{{item.unit * item.price | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                    </tr>
                </tbody>
            </table>
            <table class="w-full mb-8">
                <tr>
                    <td class="w-4/6"></td>
                    <td class="w-2/6" >
                        <table class="w-full">
                            <tr>
                                <td class="Poppins-Bold" style="color: #0C4D71;">{{'Subtotal' | translate }}</td>
                                <td class="text-end">{{invoiceData?.subtotal | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr *ngIf='invoiceData?.discount'>
                                <td class="" >{{'Discount:' | translate }} ({{invoiceData?.discount | number : number}}%)</td>
                                <td class="text-end">-{{invoiceData?.subtotal - invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr *ngIf='invoiceData?.discount'>
                                <td class="" >{{'Discounted Subtotal' | translate }}</td>
                                <td class="text-end">{{invoiceData?.discountValue | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr>
                                <td class="pb-2 Poppins-Bold" style="color: #0C4D71;">{{'Tax' | translate }}</td>
                                <td class="pb-2 text-end" >{{invoiceData?.tax | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                            <tr class="border-t">
                                <td class="Poppins-Bold pt-2" style="color: #0C4D71;">{{'Total' | translate }}</td>
                                <td  class="Poppins-Bold pt-2 text-end">{{invoiceData?.totalAmount | currency: invoiceData?.currencyDetails?.currencySymbol : 'symbol-narrow' : number}}</td>
                            </tr>
                        </table>
                    </td>
                </tr>
            </table>

            <div *ngIf='invoiceData?.notesTerms' class="Poppins-Bold" style="color: #0C4D71;">{{'Terms and Conditions' | translate }}</div>
            <pre *ngIf='invoiceData?.notesTerms' id='pre' class="whitespace-pre-wrap Poppins">{{invoiceData?.notesTerms}}</pre>
            <div *ngIf='invoiceData?.eSign' class="flex">
                <div *ngIf='invoiceData?.eSign' class="max-w-sm mr-5 mb-5">
                    <h3 class="block text-sm Poppins-Bold font-color-05 mb-2">{{'Digitally signed document' | translate }}
                    </h3>
                   <div class="px-8 py-5 rounded-xl items-center justify-center">
                        <img class="w-300" src="{{invoiceData?.eSign}}" />
                    </div>
                </div>
            </div>
            <div class="flex rounded-xl my-5">
                <div class="w-1/4 p-5">
                    <h3 class="block text-sm Poppins-Bold font-color-05 mb-2 border">{{'E-Invoice' | translate }}</h3>
                    <div class="">
                        <img src="{{invoiceData?.qrCodeWithUuid}}" />
                    </div>
                </div>
                <div *ngIf='invoiceData?.qrCode' class="w-1/4 p-5">
                    <h3 class="block text-sm Poppins-Bold font-color-05 mb-2 border">{{invoiceData?.qrHeading}}</h3>
                    <div class="">
                        <img src="{{invoiceData?.qrCode}}" />
                    </div>
                </div>
            </div>
            <div class="w-full justify-end flex gap-x-2">
                <h5 class="Poppins-Bold">{{"Issued By" | translate }}:</h5>
                <div>{{invoiceData?.userId?.firstName ? invoiceData?.userId?.firstName+' '+invoiceData?.userId?.lastName : ''}}</div>
            </div>
        </div>
    </div>
</app-document-container>
