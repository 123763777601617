import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  contactForm: FormGroup;
  unsubscribe$ = new Subject();

  formErrors = {
    firstName: '',
    lastName: '',
    email: ''
  }

  formErrorMessages = {
    firstName: {
      required: 'First Name is required'
    },
    lastName: {
      required: 'Last Name is required'
    },
    email: {
      required: 'Email is required'
    }
  }

  constructor(private fb: FormBuilder,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService,
              private http: HttpService,
              private router: Router,
              private translateService: TranslateService
              ) { }

  ngOnInit(): void {
    this.createForm();
  }

  createForm(): void {
    this.contactForm = this.fb.group({
      firstName: ['', Validators.required ],
      lastName: ['', Validators.required],
      email: ['', Validators.required],
      message: ['']
    });

    this.contactForm.valueChanges.subscribe(()=>{
      this.formErrors = valueChanges(this.contactForm, this.formErrors, this.formErrorMessages, this.translateService)
    });

  }

  submit(): void{
    this.spinner.show()
    if(this.contactForm.invalid){
      this.formErrors = valueChanges(this.contactForm, this.formErrors, this.formErrorMessages, this.translateService);
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Please fill required details'));
      return
    }
    this.http.post('/admin/sendQueryTOAdmin', this.contactForm.value)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(this.translateService.instant('Details submitted'));
      }
      this.spinner.hide();
    },(err) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    },()=> {
      this.router.navigate(['home'])
    })
  }

}
