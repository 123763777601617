import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { DateValidator, dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { ProjectsService } from '../../projects.service';

@Component({
  selector: 'app-project-details',
  templateUrl: './project-details.component.html',
  styleUrls: ['./project-details.component.scss']
})
export class ProjectDetailsComponent implements OnInit, OnDestroy {
  filterForm: FormGroup;
  buisness$: Observable<any>;
  unsubscribe$ = new Subject();
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  number = '1.2-2'
  projectId: any;
  viewProjectDetails;
  invoices;
  unfilteredInvoices;
  unfilteresBills;
  estimatedStartDate; 
  estimatedEndDate; 
  estimatedHour; 
  projectStartDate; 
  projectCloseDate; 
  projectHour; 
  estimatedEarnings; 
  estimatedExpenses; 
  bills;
  totalInvoiceAmount = 0;
  totalBillAmount = 0;
  files: File[] = [];
  addedFiles = [];
  invoiceTableHeadings = [
    'Customer Name',
    'Invoice Number',
    'Invoice Date',
    'Due Date',
    'Total Amount',
  ];

  invoiceTableKeys = [
    'customerName',
    'invoiceNumber',
    'invoiceDate',
    'dueDate',
    'totalAmount'
  ];

  billTableHeadings = [
    'Date',
    'Number',
    'Vendor',
    'Due Amount',
    'Total amount',
  ];
  billTableKeys = [
    'date',
    'billNumber',
    'vendor',
    'dueAmount',
    'totalAmount'
  ];

  filterFormErrors = {
    fromDate: '',
    endDate: ''
  }
  filterFormErrorMessages = {
    fromDate: {
      invalidDate: 'Invalid Date'
    },
    endDate: {
      invalidDate: 'Invalid Date',
      dates: 'Invalid Date'
    }
  }

  constructor(
    private route: ActivatedRoute,
    private projectService: ProjectsService,
    private toastr: ToastrService,
    private spinner: NgxSpinnerService,
    private rootStore: Store<RootReducerState>,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private numberService: NumberService,
    private fileUploadService: FileUploadService
  ) {
    this.buisness$ = rootStore.pipe(select(selectBusiness))
  }

  ngOnInit(): void {
    this.spinner.show();
    this.getBusiness();
    this.route.params.subscribe(({ id }) => {
      if (id) {
        this.projectId = id;
        this.getProjectDetails(this.projectId);
      }
    }, () => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
    this.loadFilterForm();
    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number
    })
  }

  getBusiness() {
    this.buisness$.subscribe(business => {
      if (business?.businessId?._id) {
        this.currencyDetails = (({ currency, currencySymbol }) => ({
          currency, currencySymbol
        }))(business?.businessId);
      }
    })
  }

  getProjectDetails(projectId) {
    this.projectService.getProjectDetails(projectId)
      .subscribe(resp => {
        if (resp?.success) {
          this.viewProjectDetails = resp?.data;
          this.invoices = this.mapInvoiceListForTable(this.viewProjectDetails?.invoices);
          this.bills = this.mapBillsListForTable(this.viewProjectDetails?.bills);
          this.unfilteredInvoices = this.viewProjectDetails?.invoices;
          this.unfilteresBills = this.viewProjectDetails?.bills;
          this.totalInvoiceAmount = this.viewProjectDetails?.totalOfInvoices;
          this.totalBillAmount = this.viewProjectDetails?.totalOfBills;
          this.estimatedStartDate = this.viewProjectDetails?.projectDetails?.estimatedStartDate ? moment(this.viewProjectDetails?.projectDetails?.estimatedStartDate).format('DD-MM-YYYY') : '';
          this.estimatedEndDate = this.viewProjectDetails?.projectDetails?.estimatedEndDate ? moment(this.viewProjectDetails?.projectDetails?.estimatedEndDate).format('DD-MM-YYYY') : '';
          this.projectStartDate = this.viewProjectDetails?.projectStartDate ? moment(this.viewProjectDetails?.projectStartDate).format('DD-MM-YYYY') : ''; 
          this.projectCloseDate = this.viewProjectDetails.projectDetails?.projectCloseDate ? moment(this.viewProjectDetails?.projectDetails?.projectCloseDate).format('DD-MM-YYYY') : ''; 
          this.projectHour = this.viewProjectDetails?.projectHour; 
          this.estimatedHour = this.viewProjectDetails?.projectDetails?.estimatedHour;
          this.estimatedExpenses = this.viewProjectDetails?.projectDetails?.budget?.expenses; 
          this.estimatedEarnings = this.viewProjectDetails?.projectDetails?.budget?.earnings; 
          this.addedFiles = this.viewProjectDetails?.projectDetails?.file
        }
        else
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        this.spinner.hide();
      }, () => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'))
      })
  }

  mapBillsListForTable(billsList): Array<object> {
    return [...billsList.map(bill => ({
      ...bill,
      date: moment(bill.date.split('T')[0]).format('DD-MM-YYYY'),
      vendor: bill?.vendor?.vendorName,
      projects: bill?.projectDetails?.projectId,
      dueAmount: this.numberService.currencier(bill?.dueAmount),
      totalAmount: this.numberService.currencier(bill?.totalAmount),
    }))];
  }

  mapInvoiceListForTable(invoiceList): Array<object> {
    return [...invoiceList.map(invoice => ({
      ...invoice,
      totalAmount: this.numberService.currencier(invoice?.totalAmount),
      customerName: invoice?.customerDetails?.customerId?.customerName,
      projectName: invoice?.projectDetails?.projectId?.projectName,
      invoiceDate: moment(invoice?.invoiceDate?.split('T')[0]).format('DD-MM-YYYY'),
      dueDate: moment(invoice?.dueDate?.split('T')[0]).format('DD-MM-YYYY'),
      createdAt: moment(invoice?.createdAt?.split('T')[0]).format('DD-MM-YYYY')
    }))];
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group({
      fromDate: [null, DateValidator()],
      endDate: [null, DateValidator()]
    }, { validator: dateLessThan('fromDate', 'endDate') });

    this.filterForm.valueChanges.subscribe(() => {
      this.filterFormErrors = valueChanges(this.filterForm, { ...this.filterFormErrors }, this.filterFormErrorMessages, this.translateService);
    });

    this.filterFormErrors = valueChanges(this.filterForm, { ...this.filterFormErrors }, this.filterFormErrorMessages, this.translateService);
  }

  filterData(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.filterFormErrors = valueChanges(this.filterForm, { ...this.filterFormErrors }, this.filterFormErrorMessages, this.translateService);
      return;
    }

    this.spinner.show();
    const { fromDate, endDate } = this.filterForm.value;
    if (fromDate && endDate) {
      console.log(fromDate, endDate);
      this.projectService.filterProject(fromDate, endDate, this.projectId)
        .subscribe(resp => {
          if (resp?.success) {
            const { invoices, bills, totalOfInvoices, totalOfBills } = resp?.data;
            this.invoices = this.mapInvoiceListForTable(invoices);
            this.bills = this.mapBillsListForTable(bills);
            this.totalInvoiceAmount = totalOfInvoices;
            this.totalBillAmount = totalOfBills;
            this.estimatedStartDate = 0;
            this.estimatedEndDate = 0;
            this.estimatedHour = 0;
            this.spinner.hide();
          }
        }, err => {
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'))
        })
    }
    else {
      this.invoices = this.mapInvoiceListForTable(this.unfilteredInvoices);
      this.bills = this.mapBillsListForTable(this.unfilteresBills);
      this.spinner.hide();
    }
  }

  uploadFiles(): void {
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if(!this.files.length){
      this.toastr.error('Select files to upload');
      return;
    }
    this.spinner.show();
    const body = {
      businessId: this.viewProjectDetails?.projectDetails?.businessId,
      referenceId: this.projectId,
      documentType: 'Project',
      parentDocType: 'Project'
    }
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    })
    formData.append('payload', JSON.stringify(body));
    this.fileUploadService.uploadFileForDocument(formData)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success){
        console.log(resp?.data, 'view project ');
        this.addedFiles = resp?.data?.files ?? [];
        this.toastr.success(resp?.message);
      }
      else{
        this.toastr.error(resp?.message)
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
