<div class="h-[100vh] w-[100vw] flex flex-col items-center bg-gray-50 pt-52 " >
    <div class="max-h-64 flex flex-col justify-center items-center bg-white shadow rounded-xl p-5 space-y-3">
        <svg *ngIf="response?.transactionStatus === 'Approved'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="green" class="w-20 h-20 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9 12.75 11.25 15 15 9.75M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
        <svg *ngIf="response?.transactionStatus === 'Failed'" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="red" class="w-20 h-20 ">
            <path stroke-linecap="round" stroke-linejoin="round" d="m9.75 9.75 4.5 4.5m0-4.5-4.5 4.5M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
        </svg>
          
        <p class=" text-2xl Poppins-Bold" >{{'Payment Status - ' + response?.transactionStatus ?? ''}}</p>
        <p class=" text-xl Poppins-Bold " >{{'Payment message - ' + response?.transactionMessage ?? ''}}</p>
        <p class="" >{{'Transaction Time - ' + (response?.transactionTime | date: 'medium')}}</p>
    </div>
    <p class="text-center pt-2" >{{'You will be redirected to the source automatically in 5 seconds, if not redirected'}} <a class="text-blue-600 underline cursor-pointer" (click)="close()" >Click Here</a></p>
</div>