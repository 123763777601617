import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { RootReducerState } from 'src/app/store/reducers';
import { RecurringBillService } from '../../recurring-bill.service';
import { TranslateService } from '@ngx-translate/core';
import { NumberService } from 'src/app/shared/services/number.service';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { ToastrService } from 'ngx-toastr';
import { selectedProducts } from 'src/app/modules/purchases/state/selectors/product.selector';
import { selectedVendors } from 'src/app/modules/purchases/state/selectors/vendors.selector';
import { selectSalesTaxes } from 'src/app/modules/purchases/state/selectors/salesTaxes.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';
import { DateValidator, dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { takeUntil } from 'rxjs/operators';
import { NgSelectComponent } from '@ng-select/ng-select';
import { AssetsService } from '../../../assets/assetsServices';

@Component({
  selector: 'app-create-recurring-bill',
  templateUrl: './create-recurring-bill.component.html',
  styleUrls: ['./create-recurring-bill.component.scss']
})
export class CreateRecurringBillComponent implements OnInit {

  unsubscribe$ = new Subject();

  billForm: FormGroup;
  paymentTermForm: FormGroup;

  products$: Observable<any>;
  vendors$: Observable<any>;
  taxes$: Observable<any>;
  business$: Observable<any>;
  projects$: Observable<any>;
  subscription$: Observable<any>;
  customizationSettings$: Observable<any>;

  products = [];
  assets = [];
  avaialbleProducts = [];
  projects = [];
  taxes = [];
  vendors = [];
  files: File[] = [];
  frequency = [
    'Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'Yearly'
  ];
  paymentTerms = [];

  business = null;
  subscription = null;

  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  itemTotal = 0;
  tax = 0;
  discount = 0;

  customizationSettings = {
    companyLogo: ''
  };

  tableFields = {
    items: 'Items',
    units: 'Units',
    sku: 'SKU',
    hsn_sac: 'HSN/SAC',
    price: 'Price',
    amount: 'Amount'
  }

  billDetails = null
  imageDataEsign = {
    event: '',
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  openCreateEsign =  false;
  openModal = false;
  openModalForEsign = false;
  openDeleteModal = false;
  removeQRCodeModal = false;
  disableFields = false;
  removeESignModal = false;
  eSignSettingModal = false;
  previewSignatureForDoc = false;
  isFieldEditable:boolean = true;
  showCustomPaymentTerms: boolean = false;


  eSignValue = '';
  eSignSelected = '';
  discountType = 'percent';
  discountValue = 0;

  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload'
    }
  };
  imageDataQR = {
    event: new Event(''),
    openModal: false,
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  formErrors = {
    vendor  : '',
    projectId  : '',
    startDate: '',
    endDate: '',
    paymentTerms: '',
    frequency: '',
    purchaseOrder: '',
    title: '',
    unit: '',
    price: ''
  };

  paymentTermFormErrors = {
    paymentTerm: '',
    days: ''
  }

  formErrorMessages = {
    vendor: {
      required: 'Vendor is Required'
    },
    projectId: {
      required: 'Project is Required'
    },
    startDate: {
      required: 'Start Date is Required',
      pattern: 'Invalid Date',
      max: 'Invalid Date',
      min: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    endDate: {
      required: 'End Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    paymentTerms: {
      required: 'Payment Terms are required'
    },
    frequency: {
      required: 'Frequency is required'
    },
    purchaseOrder: {
      required: 'Vendor Invoice is Required'
    },
    title: {
      required: 'Title is required'
    },
    items: {
      price: {
        min: "Price can't be negative"
      },
    },
    unit: {
      min: "Quantity can't be negative"
    }
  };

  paymentTermFormErrorMessages = {
    paymentTerm: {
      required: 'Payment Term is required'
    },
    days: {
      required: 'Days are required',
      min: 'Days cannot be less than 1'
    }
  }

  number = '1.2-2';

  currencySymbol = null;
  minDate = moment('01/01/1000').format('YYYY-MM-DD');
  maxDate = moment('31/12/3000').format('YYYY-MM-DD');
  today = moment().format('YYYY-MM-DD')

  constructor(
    private fb: FormBuilder,
    private store: Store<SalesReducerState>,
    private rootStore: Store<RootReducerState>,
    private assetsService: AssetsService,
    private router: Router,
    private spinner: NgxSpinnerService,
    private recurringBillService: RecurringBillService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService,
    private toaster: ToastrService) {
this.products$ = this.store.pipe(select(selectedProducts));
this.vendors$ = this.store.pipe(select(selectedVendors));
this.taxes$ = this.store.pipe(select(selectSalesTaxes));
this.business$ = this.rootStore.pipe(select(selectBusiness));
this.projects$ = this.rootStore.pipe(select(selectProjects));
this.subscription$ = rootStore.pipe(select(selectUserSubscription));
// this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
}

  ngOnInit(): void {
    this.loadBillForm();
    this.loadPaymentTermForm()
    this.loadSubscription();
    this.loadBusiness();
    this.loadVendors();
    // this.loadCustomizationSettings();
    this.loadNumberConfig();
    this.loadTaxes();
    this.loadProjects();
    this.loadAssets();
  }


  loadProducts(): void {

  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((projects: any[]) => {
      this.projects = projects;
    });
  }

  loadTaxes(): void {
    this.taxes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(taxes => {
      this.taxes = taxes.map((tax) => ({...tax, name: tax.taxName, tax: tax.taxRate, accountDetails: tax.accountDetails}));
    });
  }

  loadVendors(): void {
    this.vendors$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((vendors: any[]) => {
      this.vendors = vendors;
    });
  }

  loadBusiness(): void {
    this.business$.subscribe(business => {
      if(business?.businessId){
        this.business = business?.businessId;
        this.currencySymbol = this.business?.currency;
        this.loadPaymentTerms();
      }
    });
  }

  loadAssets(): void {
    this.assetsService.getAllAssets(this.business?._id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      if(resp?.success) {
        this.assets = resp?.data?.map(asset => (
          {...asset,type: 'Asset',
            name: asset.assetsName,
            price: asset.amount,
            tax: []
          }));
        this.products.push(...this.assets);  
        console.log(this.products,this.assets);
      }
    });
  }

  loadPaymentTerms(): void {
    this.spinner.show();
    this.recurringBillService.getPaymentTerms(this.business?._id)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((resp: any) => {
      if(resp?.success){
        this.paymentTerms = resp?.data;
      }
      else{
        this.toaster.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => { 
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  loadPaymentTermForm(): void {
    this.paymentTermForm = this.fb.group({
      paymentTerm: ['', Validators.required],
      days: [0, [Validators.required, Validators.min(1)]]
    })
    this.paymentTermFormErrors = valueChanges(this.paymentTermForm, this.paymentTermFormErrors, this.paymentTermFormErrorMessages, this.translateService)
    this.paymentTermForm.valueChanges.subscribe(() => {
      this.paymentTermFormErrors = valueChanges(this.paymentTermForm, this.paymentTermFormErrors, this.paymentTermFormErrorMessages, this.translateService)
    })
  }

  loadBillForm(): void {
    this.billForm = this.fb.group({
      vendor: [null, [Validators.required]],
      projectId: [null],
      purchaseOrder: [null, [Validators.required]],
      startDate: [new Date().toISOString().split('T')[0], [Validators.required, DateValidator()]],
      endDate: [null, [Validators.required, DateValidator()]],
      frequency: [null, Validators.required],
      paymentTerms: [null, Validators.required],
      title: [{value:null, disabled: true}, [Validators.required, ]],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      notesTerms: [null],
      items: this.fb.array([])
    }, {validator: dateLessThan('startDate', 'endDate')});
    this.billForm.valueChanges.subscribe(({items}) => {
      this.formErrors = valueChanges(this.billForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      this.calculateTotal(items);
    });
    this.formErrors = valueChanges(this.billForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }

  loadSubscription(): void {
    this.subscription$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((plan: any) => {
      this.subscription = plan?.planName;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(products => {
        if(this.subscription === 'Retail' || this.subscription === 'Retail Plus'){
          products = products?.filter(product => product?.isReviewed)
        }
        this.products = products.filter(item => {
           if(item?.itemType === 'Service' && item?.isSale){
             return false;
           }
           return true;
        });
        this.avaialbleProducts = this.products;
      });
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number;
    })
  }

  billDataMapper(): any {
    const body = {
      ...this.billForm.value,
      projectDetails: {projectId:this.billForm.value.projectId},
      businessId: this.business?._id,
      totalAmount: this.numberService.toFixed(this.totalAmount),
      dueAmount: this.numberService.toFixed(this.totalAmount),
      subtotal: this.numberService.toFixed(this.subTotal),
      tax: this.numberService.toFixed(this.tax),
      itemTotal: this.numberService.toFixed(this.itemTotal)
    };

    return body;
  }

  get items(): FormArray {
    return this.billForm?.get('items') as FormArray;
  }

  changeTax(event, i) {
    // console.log(event,i);
    if(this.items.length>0){
      if(event.length > 1){
        event.shift()
        this.items.controls[i].get('tax').setValue(event)
      }
    }
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      sku: '',
      hsn_sac: '',
      price: [null,[Validators.min(0)]],
      unit: [1,[Validators.min(0.01)]],
      tax: null,
      type: '',
      totalCost: [null,[Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      depreciationAccount: null
    });
    return itemForm;
  }

  addNewItem(): void {
    this.items.push(this.createItem());
  }

  removeItem(index): void {
    this.items.removeAt(index);
    this.updateAvailableItems();
  }
  updateAvailableItems(){
    const allIds = this.items.value.map(item=>item.itemId)
    this.avaialbleProducts = this.products.filter(el => !allIds.includes(el?._id));
  }

  changeEvent(event, index): void {
    const { _id, name, price, itemType, tax, accountDetails, depreciationAccount = null,  sku = null, hsn, sac, isSale = false, cogsAccountDetails = null, inventoryAccountDetails = null } = event;
    this.items.controls[index].setValue({
      item: name,
      itemId: _id,
      sku,
      hsn_sac: hsn || sac? hsn || sac : null,
      type:itemType  || 'assets',
      price: this.numberService.toFixed(price),
      tax,
      unit: 1,
      totalCost: this.numberService.toFixed((price * 1)),
      accountDetails,
      isSale,
      cogsAccountDetails,
      inventoryAccountDetails,
      depreciationAccount
    });
    this.updateAvailableItems()
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let totalTax = 0;
    let itemTotal = 0;
    estimateItems.forEach((item) => {
      const { price, unit, tax } = item;
      subTotal += price * unit;
      if (item.type === 'Product') {
        itemTotal += price * unit;
      }
      if (tax) {
        totalTax += this.calculateItemTax(price * unit, tax);
      }
    });
    this.subTotal = this.numberService.toFixed(subTotal);
    this.tax = this.numberService.toFixed(totalTax);
    this.totalAmount = this.numberService.toFixed((subTotal + totalTax));
    this.itemTotal = this.numberService.toFixed(itemTotal);
  }

  calculateItemTax(price, taxes): any {
    return [taxes].reduce((a, b) =>  a += (price) * (b.tax / 100), 0);
  }

  createBill(): void {
    if (this.billForm.invalid) {
      this.billForm.markAllAsTouched();
      this.formErrors = valueChanges(this.billForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }

    const billData: any = this.billDataMapper();

    if (billData.items.length === 0) {
      return;
    }

    if (billData.items[0].item === null) {
      return;
    }

    if(billData?.items?.some(item => !item.itemId)){
      this.toaster.error('Please select item for each line item before proceeding');
      return;
    }

    // if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
    //   let stockCheck: Array<StockCheck | null> = [];
    //   billData.items?.forEach(item => {
    //     const product = this.products.find(product => product?._id == item?.itemId);
    //     if(product && product?.stockDetails?.openingStock < item?.quantity){
    //       stockCheck.push({
    //         item: item?.item,
    //         openingStock: product?.stockDetails?.openingStock,
    //         quantityAdded: item?.quantity
    //       })
    //       console.log(stockCheck, this.checkQuantityInStock);
    //     }
    //   })
    //   this.checkQuantityInStock = stockCheck;

    //   if(this.checkQuantityInStock.length){
    //     this.outOfStock = true;
    //     return
    //   }
    // }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(billData));
    this.recurringBillService.createBill({formData, businessId: this.business?._id}).subscribe(resp => {
      if(resp?.success){
        this.toaster.success(resp?.message || 'Bill created successfully');
        this.router.navigate(['/purchases/recurring-bill']);
      }
      else{
        this.toaster.error(resp?.message);
      }
      this.spinner.hide();
    }, error => {
      this.toaster.error(error?.error?.message)
      this.spinner.hide();
    });
  }

  showCustomPaymentTermsModal(select: NgSelectComponent): void {
    this.showCustomPaymentTerms = true;
    select.close();
  }

  createPaymentTerms(): void {
    this.spinner.show();
    console.log(this.paymentTermForm.value);
    this.recurringBillService.createPaymentTerm(this.paymentTermForm.value, this.business?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.paymentTerms = resp?.data;
        this.showCustomPaymentTerms = false;
        this.paymentTermForm.reset()
      }
      else {
        this.toaster.error(resp?.message);
      }
      this.spinner.hide()
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
    
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
