<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-xl space-y-6 flex flex-col">
    <div>
      <div class="flex items-center justify-between">
        <div class="">
          <!-- <h1 class="text-2xl Poppins-Medium inline-flex items-center"> -->
          <!-- <svg _ngcontent-hbd-c18="" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor" class="h-6 w-6 me-2">
                            <path _ngcontent-hbd-c18="" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M16 8v8m-4-5v5m-4-2v2m-2 4h12a2 2 0 002-2V6a2 2 0 00-2-2H6a2 2 0 00-2 2v12a2 2 0 002 2z">
                            </path>
                        </svg> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                          <path d="M280-280h80v-280h-80v280Zm160 0h80v-400h-80v400Zm160 0h80v-160h-80v160ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                        </svg>
                        {{'Assets' | translate}}
                    </h1> -->
        </div>
        <div class="">
          <app-button
            appAccessControl
            moduleType="purchase"
            accessType="edit"
            (click)="this.openAddAssetsModal = true"
            buttonText="{{ 'Add an asset' | translate }}"
            type="primary"
            size="large"
          ></app-button>
        </div>
      </div>
      <app-table-container
        [tableHeadings]="tableHeadings"
        [tableData]="tableData"
        [keys]="tableKeys"
        [actionTemplate]="actionTemplate"
      >
        <!-- <ng-template let-item #actionTemplate>
                    <td align="right" class="p-2 last:pr-10 text-center">
                        <div appAccessControl moduleType='purchase' accessType='edit' class="flex justify-center mt-2">
                            <svg (click)='modalAssetUpdate(item._id)' xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1 cursor-pointer" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z" />
                            </svg>
                            <svg (click)='deleteConfirmation(item._id)'  xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer" fill="none"
                                viewBox="0 0 24 24" stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                            </svg>
                        </div>
                    </td>
                </ng-template> -->
        <ng-template let-item #actionTemplate>
          <td
            appAccessControl
            moduleType="purchase"
            accessType="edit"
            class="p-2 text-center"
          >
            <app-dropdown #dropdown [isFromSettings]="true">
              <div
                button
                (click)="dropdown.open = true"
                (clickOutside)="dropdown.open = false"
              >
                <div
                  class="h-8 w-8 rounded-lg flex justify-center items-center border-2 border-[#DCDCDC]"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-4 w-4"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="2"
                      d="M19 9l-7 7-7-7"
                    />
                  </svg>
                </div>
              </div>
              <div options class="new-shadow p-1">
                <div
                  (click)="modalAssetUpdate(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Edit" | translate }}
                </div>
                <div
                  (click)="deleteConfirmation(item._id)"
                  class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF] text-left"
                >
                  {{ "Delete" | translate }}
                </div>
              </div>
            </app-dropdown>
          </td>
        </ng-template>
      </app-table-container>
    </div>
  </div>
</div>
<app-modal-container [isOpen]="openAddAssetsModal">
  <div *ngIf="!update" header class="border-b pb-2 Poppins-Medium">
    {{ "Add an asset" | translate }}
  </div>
  <div *ngIf="update" header class="border-b pb-2 Poppins-Medium">
    {{ "Update an Asset" | translate }}
  </div>
  <div content>
    <my-tabs>
      <my-tab tabTitle="{{ 'Details' | translate }}">
        <p class="text-sm text-start Poppins-Medium">
          {{ "Asset Info" | translate }}
        </p>
        <form [formGroup]="createAssetsForm" class="mt-5 text-start">
          <div class="flex mb-3 items-center">
            <div class="w-1/3">
              <p>{{ "Asset Name:" | translate }}</p>
            </div>
            <div class="w-full">
              <input
                type="text"
                formControlName="assetsName"
                class="new-input"
                placeholder=""
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.assetsName"
              >
                {{ formErrors.assetsName }}
              </div>
            </div>
          </div>
          <div class="flex items-center mb-3">
            <div class="w-1/3">
              <p>{{ "Asset Type:" | translate }}</p>
            </div>
            <div class="w-full">
              <input
                type="text"
                formControlName="assetsType"
                class="new-input"
                placeholder=""
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.assetsType"
              >
                {{ formErrors.assetsType }}
              </div>
            </div>
          </div>
          <div class="flex mb-3 w-full">
            <div class="w-1/3">
              <p>{{ "Description:" | translate }}</p>
            </div>
            <div class="w-full">
              <textarea
                formControlName="description"
                class="new-input !h-44"
              ></textarea>
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.description"
              >
                {{ formErrors.description }}
              </div>
            </div>
          </div>

          <div class="flex mb-3 items-center">
            <div class="w-1/3">
              <p>Select Account</p>
            </div>
            <fieldset class="w-full" [disabled]="update">
              <ng-select
                [readonly]="update"
                [items]="accounts"
                class="new-dropdown"
                bindLabel="accountName"
                formControlName="accountDetails"
                placeholder="Select one category"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.accountName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                *ngIf="formErrors.accountDetails"
                class="text-sm text-red-500 ms-2 mt-1"
              >
                {{ formErrors.accountDetails }}
              </div>
            </fieldset>
          </div>
          <div class="flex mb-3 items-center">
            <div class="w-1/3">
              <p>{{ "Depreciation %:" | translate }}</p>
            </div>
            <div class="w-full">
              <input
                type="number"
                formControlName="depreciation"
                class="new-input"
                placeholder=""
              />
              <div
                class="text-sm text-red-500 ms-2 mt-1"
                *ngIf="formErrors.depreciation"
              >
                {{ formErrors.depreciation }}
              </div>
            </div>
          </div>
          <div class="flex mb-3 items-center">
            <div class="w-1/3">
              <p>Calculate Depreciation</p>
            </div>
            <input
              type="checkbox"
              formControlName="calDepreciation"
              name="calDepreciation"
              id="calDepreciation"
            />
          </div>

          <div class="flex mb-3 items-center">
            <div class="w-1/3">
              <p>Select Depreciation Account</p>
            </div>
            <fieldset class="w-full" [disabled]="update">
              <ng-select
                [readonly]="update"
                [items]="depreciationAccounts"
                class="new-dropdown"
                bindLabel="accountName"
                formControlName="depreciationAccount"
                placeholder="Select one category"
                [searchable]="false"
                [clearable]="false"
                [multiple]="false"
              >
                <ng-template ng-option-tmp let-item="item" let-index="index">
                  <div class="px-2 py-1">
                    <div
                      class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ item.accountName }}
                    </div>
                  </div>
                </ng-template>
              </ng-select>
              <div
                *ngIf="formErrors.depreciationAccount"
                class="text-sm text-red-500 ms-2 mt-1"
              >
                {{ formErrors.depreciationAccount }}
              </div>
            </fieldset>
          </div>
        </form>
      </my-tab>
      <my-tab tabTitle="{{ 'Files' | translate }}">
        <div>
          <app-file-upload
            #fileUploadComponent
            [addedFiles]="addedFiles"
            (emitter)="saveFiles($event)"
            type="compact"
          ></app-file-upload>
        </div>
      </my-tab>
    </my-tabs>
  </div>
  <div footer class="flex w-full gap-x-2">
    <app-button
      *ngIf="!update"
      buttonText="{{ 'Save' | translate }}"
      (handleClick)="createAsset()"
      type="primary"
    ></app-button>
    <app-button
      *ngIf="update"
      buttonText="{{ 'Update' | translate }}"
      (handleClick)="updateAsset()"
      type="primary"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      class="ms-2"
      (click)="cancelModal()"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="deleteConfirmationModal">
  <div header class="border-b pb-2 Poppins-Medium">
    {{ "Delete Asset" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-x-2">
    <app-button
      buttonText="{{ 'Delete' | translate }}"
      type="danger"
      (handleClick)="deleteAsset()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="deleteConfirmationModal = false"
    ></app-button>
  </div>
</app-modal-container>
