<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-5">
        <div class="flex items-center">
            <button routerLink='../' class="bg-light-gray-1 p-3 rounded-full me-2 border">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4" fill="none" viewBox="0 0 24 24"
                    stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
                </svg>
            </button>
            <h1 class="text-2xl Poppins-Bold inline-flex items-center">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                  </svg>
               
                <span *ngIf='!update' class="text-2xl Poppins-Bold">{{'Create Sales Tax' | translate }} </span>
                <span *ngIf='update' class="text-2xl Poppins-Bold">{{'Update Sales Tax' | translate }}</span>
            </h1>
        </div>
        <div class="py-6 px-8 rounded-3xl bg-light-gray-1 mt-2 mb-2">
            <form [formGroup]='taxform' novalidate class="w-1/2 space-y-4">
                <div class="w-full">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 ">
                        <label for='taxname' class="block text-sm Poppins-Bold font-color-05">{{'Tax Name' | translate }}</label>
                        <input id='taxname' formControlName='taxName' type="text" class="w-full p-0 border-0 focus:ring-0" placeholder="{{'Enter Tax Name' | translate }}" />
                    </div>
                    <label *ngIf='formErrors.taxName' class="text-sm text-red-400 ms-2">{{formErrors.taxName}}</label>
                </div>
                <div class="w-full">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                        <label for='abbreviation' class="block text-sm Poppins-Bold font-color-05">{{'Abbreviation' | translate }}</label>
                        <input id='abbreviation' formControlName='abbreviation' type="text" class="w-full p-0 border-0 focus:ring-0" placeholder="{{'Enter Abbreviation' | translate}}" />
                    </div>
                    <div *ngIf='formErrors.abbreviation' class="text-sm text-red-400 ms-2">{{formErrors.abbreviation}}</div>
                </div>
                <div class="w-full">
                    <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                        <label for='description' class="block text-sm Poppins-Bold font-color-05">{{'Description' | translate }}</label>
                        <textarea id="description" formControlName="description" class="w-full h-52 p-0 border-0 focus:ring-0" placeholder="{{'Enter Description' | translate }}"></textarea>
                    </div>
                
                <div *ngIf='formErrors.description' class="text-sm text-red-400 ms-2">{{formErrors.description}}</div>
                </div>
                <div class="flex space-x-8 rtl:space-x-reverse mb-4">
                    <div class="w-full">
                        <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2 w-56">
                            <label for='taxRate' class="block text-sm Poppins-Bold font-color-05">{{'Tax Rate (%)' | translate }}</label>
                            <input id='taxRate' formControlName='taxRate' type="number" class="w-full p-0 border-0 focus:ring-0" placeholder="{{'Enter Tax Rate' | translate }}" />
                        </div>
                        <div *ngIf='formErrors.taxRate' class="text-sm text-red-400 ms-2">{{formErrors.taxRate}}</div>
                    </div>
                </div>
                
                <div  class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
                    <label for='email' class="block text-sm Poppins-Bold font-color-05">Select Accounts</label>
                    <fieldset [disabled]="update">
                        <ng-select [readonly]="update" [items]="['Assets', 'Liabilities']"
                            class="custom-selector1" 
                            formControlName="account"
                            placeholder='Select one category'
                            [searchable]='false'
                            [clearable]='false'
                            [multiple]='false'
                            (change)='changeService($event)' >
                        </ng-select>
                    </fieldset>

                </div>
                <div *ngIf='formErrors.account' class="text-sm text-red-400 ms-2">{{formErrors.account}}</div>

                <div class="flex items-center gap-x-2">
                    <button appAccessControl moduleType='accounting' accessType='edit' *ngIf='!update' class="inline-block rounded-full py-2 px-6 text-sm button-new-class text-white" (click)='createTax()'>{{'Save' | translate }}</button>
                    <button appAccessControl moduleType='accounting' accessType='edit' *ngIf='update'  class="inline-block rounded-full py-2 px-6 text-sm button-new-class text-white" (click)='updateTax()'>{{'Update' | translate }}</button>
                    <span *ngIf='response' class="text-green-500">{{response}}</span>
                    <span *ngIf='error' class="text-red-500">{{error}}</span>
                </div>
        </form>        
        </div>
    </div>
</div>