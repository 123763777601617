import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import  * as io from 'socket.io-client';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class ChatsService {

  socket;
  redirectionChatId = new BehaviorSubject('');

  constructor(private httpService: HttpService) {
    // this.socket = io.connect('http://localhost:3001', {
    this.socket = io.connect('https://api.numetric.co', {
      withCredentials: true,
      extraHeaders: {
        'Access-Control-Allow-Origin': 'https://numetric.co', // Replace with your front-end origin
        // 'Access-Control-Allow-Origin': 'http://localhost:4200', // Replace with your front-end origin
      },
    });
  };


  emit(eventName: string, msg:any){
    // console.log(msg)
    this.socket.emit(eventName, msg);
  }


  listen(eventName: string){ 
  return  new Observable((observer: Observer<any>)=>{
      this.socket.on(eventName, (message:string)=>{
        observer.next(message)
      });
      return () => {
        this.socket.off(eventName);
      };
    })
  }

  createNewChat(data): Observable<any> {
    return this.httpService.post(`/chats/createChat/`, data);
  }
  
  addChatMessage(data): Observable<any> {
    return this.httpService.post(`/chats/addChatMessage/`, data);
  }

  updateChatDetails(data, chatId): Observable<any> {
    return this.httpService.post(`/chats/updateChatDetails/${chatId}`, data);
  }

  addMembers(data, chatId): Observable<any> {
    return this.httpService.post(`/chats/addMembers/${chatId}`, data);
  }

  removeMembers(data, chatId): Observable<any> {
    return this.httpService.post(`/chats/removeMembers/${chatId}`, data);
  }

  getAllChats(id, businessId): Observable<any> {
    return this.httpService.get(`/chats/getAllChats/${id}/${businessId}`);
  }

  setredirectionChatId(id){
    if(id){
      this.redirectionChatId.next(id)
    }
  }

}
