<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <div class="flex items-center justify-between">
      <h1 class="text-2xl Poppins-Medium inline-flex items-center">
        #{{ billDetails?.billNumber }}
      </h1>
      <app-button
        appAccessControl
        moduleType="purchases"
        accessType="edit"
        type="primary"
        buttonText="{{ 'Create another Bill' | translate }}"
        size="large"
        routerLink="../create-bill"
      ></app-button>
    </div>
    <div class="w-4/5 mx-auto">
      <div class="flex justify-between p-6 my-3">
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Poppins-Medium text-lg">{{ "Status" | translate }}</p>
            <app-button
              [customClass]="
                (billDetails?.status === 'paid'
                  ? 'bg-green-400'
                  : 'bg-red-800') + ' cursor-auto'
              "
              type="primary"
              buttonText="{{ billDetails?.status | titlecase }}"
            >
            </app-button>
          </div>
          <div>
            <p class="Poppins-Medium text-lg">{{ "Vendor" | translate }}</p>
            <h3 class="Poppins-Medium text-xl">
              {{ billDetails?.vendor?.vendorName | titlecase }}
            </h3>
          </div>
        </div>
        <div class="flex space-x-8 rtl:space-x-reverse">
          <div>
            <p class="Poppins-Medium text-lg">{{ "Amount due" | translate }}</p>
            <h3 class="Poppins-Medium text-xl">
              {{
                amountDue
                  | currency
                    : currencyDetails?.currency
                    : "symbol-narrow"
                    : number
              }}
            </h3>
          </div>
          <div>
            <p class="Poppins-Medium text-lg">{{ "Due on" | translate }}</p>
            <h3 class="Poppins-Medium text-xl">
              {{ billDetails?.dueDate | date : "longDate" }}
            </h3>
          </div>
        </div>
      </div>
      <div
        class="flex items-center rounded-xl border border-[#D7D7D7] p-6 my-3"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="w-6 h-6 me-4"
          height="24px"
          viewBox="0 -960 960 960"
          width="24px"
          fill="#5D6166"
        >
          <path
            d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"
          />
        </svg>
        <div>
          <p class="Poppins-Medium text-lg">{{ "Create" | translate }}</p>
          <h4 class="Poppins-Medium text-lg">
            {{ "Created:" | translate }}
            {{ billDetails?.createdAt | date : "longDate" }}
          </h4>
        </div>
        <div class="flex gap-x-2 ms-auto">
          <app-button
            (handleClick)="downloadPdf()"
            type="primary"
            buttonText="{{ 'Download PDF' | translate }}"
          ></app-button>
          <app-button
            (handleClick)="emailModal = true"
            type="primary"
            buttonText="{{ 'Share Bill' | translate }}"
          ></app-button>
          <app-button
            (handleClick)="shareURLModal = true"
            type="third"
            buttonText="Get share link"
          ></app-button>
          <app-button
            appAccessControl
            moduleType="purchases"
            accessType="edit"
            (handleClick)="editBill(billId)"
            type="third"
            buttonText="Edit Bill"
          ></app-button>
        </div>
      </div>
      <div class="rounded-xl border border-[#D7D7D7] p-6 my-3">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 me-4"
              height="24px"
              viewBox="0 -960 960 960"
              width="24px"
              fill="#5D6166"
            >
              <path
                d="M880-720v480q0 33-23.5 56.5T800-160H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720Zm-720 80h640v-80H160v80Zm0 160v240h640v-240H160Zm0 240v-480 480Z"
              />
            </svg>
            <div>
              <p class="Poppins-Medium text-lg">{{ "Get paid" | translate }}</p>
              <h4 class="Poppins-Medium text-lg">
                {{ "Amount due:" | translate }}
                {{
                  amountDue
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </h4>
            </div>
          </div>
          <app-button
            appSubscriptionCheck
            featureToCheck="transactionLimit"
            *ngIf="amountDue > 0"
            (handleClick)="openRecordPayment()"
            buttonText="Record Payment"
            type="third"
          ></app-button>
        </div>
        <div class="mt-2" *ngIf="billDetails?.paymentHistory?.length > 0">
          <table class="w-full">
            <tr>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium p-2"
              >
                {{ "Date" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Amount" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Payment Method" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Memo" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Actions" | translate }}
              </td>
            </tr>
            <tr
              *ngFor="
                let payment of billDetails?.paymentHistory
                  | paginate : { itemsPerPage: 5, currentPage: page }
              "
            >
              <td class="p-2">
                {{ payment?.paymentDate | date : "dd-MM-YYYY" }}
              </td>
              <td>
                {{
                  payment?.amount
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td>{{ payment?.paymentMethod }}</td>
              <td>{{ payment?.memo }}</td>
              <td>
                <div
                  *ngIf="payment?.paymentMethod !== 'paypal'"
                  class="flex item-center"
                >
                  <app-tooltip
                    *ngIf="payment?.paymentMethod !== 'Credit Amount'"
                    customClass="mb-8"
                    class="me-2"
                    tooltipName="{{ 'Receipt' | translate }}"
                  >
                    <svg
                      [routerLink]="[
                        '/purchases/bills/view-receipt',
                        payment.recieptId
                      ]"
                      ro
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </app-tooltip>
                  <app-tooltip
                    *ngIf="payment?.paymentMethod !== 'Credit Amount'"
                    customClass="mb-8"
                    class="me-2"
                    tooltipName="{{ 'Edit' | translate }}"
                  >
                    <div
                      (click)="editRecord(payment)"
                      class="h-6 w-6 rounded-full flex justify-center items-center bg-gray-50 border border-gray-200 cursor-pointer"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
                        />
                      </svg>
                    </div>
                  </app-tooltip>
                </div>
              </td>
            </tr>
          </table>
          <pagination-controls
            autoHide="true"
            (pageChange)="page = $event"
          ></pagination-controls>
        </div>
      </div>
      <!-- Refund Bill Start -->

      <div class="rounded-xl border border-[#D7D7D7] p-6 my-3">
        <div class="flex items-center justify-between">
          <div class="flex items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              class="w-6 h-6 me-4"
              width="512"
              height="512"
              viewBox="0 0 512 511"
              xml:space="preserve"
            >
              <g fill="#5d6166" stroke="#5D6166" stroke-width="8">
                <path
                  d="M481.732 125.757 302.264 27.211a9 9 0 0 0-8.662 0l-67.644 37.143a9 9 0 0 0-3.864 2.124L114.14 125.757a9 9 0 0 0-4.668 7.89v119.186c-18.21 5.696-34.9 15.74-48.776 29.62-36.167 36.165-45.39 91.325-22.946 137.257 2.18 4.464 7.569 6.318 12.034 4.134a9 9 0 0 0 4.138-12.037c-19.07-39.028-11.232-85.895 19.501-116.628 19.22-19.22 44.772-29.806 71.95-29.806s52.732 10.586 71.951 29.806c19.217 19.216 29.803 44.768 29.803 71.947s-10.586 52.731-29.806 71.95c-30.734 30.735-77.6 38.571-116.627 19.502a8.996 8.996 0 0 0-12.035 4.134c-2.182 4.469-.333 9.858 4.135 12.038a119.4 119.4 0 0 0 52.502 12.15c31.09-.004 61.763-12.105 84.755-35.096 10.544-10.544 18.876-22.707 24.77-35.93l38.782 21.294a8.99 8.99 0 0 0 8.662 0l179.466-98.543a9 9 0 0 0 4.669-7.89V268.19a9 9 0 0 0-18 0v57.22l-161.437 88.648V237.5l53.28-29.257v46.378a8.996 8.996 0 0 0 9 9 9 9 0 0 0 4.338-1.115l38.457-21.168a8.99 8.99 0 0 0 4.658-7.882v-56.214l51.704-28.387v47.337a9 9 0 0 0 18 0v-62.547a9 9 0 0 0-4.67-7.889zM297.934 45.365 458.7 133.646l-50.713 27.848-160.767-88.28zm0 176.559-160.765-88.278 52.572-28.87 160.77 88.278zm71.273-39.136L208.44 94.511l20.082-11.029 160.77 88.278zM230.05 282.452c-22.62-22.62-52.692-35.075-84.677-35.075a121 121 0 0 0-17.901 1.322v-99.843l161.493 88.677v176.555l-28.03-15.392a120.3 120.3 0 0 0 4.187-31.57c0-31.986-12.456-62.057-35.072-84.674M398.7 228.14l-20.458 11.26v-41.038L398.7 187.13zm0 0"
                  data-original="#000000"
                />
                <path
                  d="M477.4 223.147a9.07 9.07 0 0 0-6.363 2.637 9.06 9.06 0 0 0-2.637 6.363 9.06 9.06 0 0 0 2.637 6.36c1.674 1.677 3.997 2.637 6.363 2.637s4.689-.96 6.363-2.637a9.06 9.06 0 0 0 2.637-6.36 9.07 9.07 0 0 0-2.637-6.363 9.07 9.07 0 0 0-6.363-2.637m-362.289 152.98a8.97 8.97 0 0 0 6.363-2.634 9 9 0 0 0 0-12.73l-6.232-6.237h53.19c12.903 0 23.4 10.498 23.4 23.4s-10.497 23.4-23.4 23.4h-31.517a9 9 0 0 0-9 9c0 4.968 4.029 8.997 9 8.997h31.518c22.826 0 41.4-18.57 41.4-41.397s-18.574-41.396-41.4-41.396h-53.191l6.232-6.237a9 9 0 0 0 0-12.73c-3.511-3.512-9.21-3.512-12.726 0l-21.6 21.6a9.006 9.006 0 0 0 0 12.73l21.6 21.596a8.97 8.97 0 0 0 6.363 2.637m-48.044 78.329a8.96 8.96 0 0 1-6.876-3.204l-.018-.02a8.995 8.995 0 0 1 1.097-12.678c3.803-3.202 9.481-2.71 12.68 1.093 3.196 3.807 2.714 9.493-1.09 12.692a8.98 8.98 0 0 1-5.793 2.116zm0 0"
                  data-original="#000000"
                />
              </g>
            </svg>
            <div>
              <p class="Poppins-Medium text-lg">
                {{ "Issue Return" | translate }}
              </p>
              <h4 class="Poppins-Medium text-lg">
                {{ "Total Amount Return:" | translate }}
                {{
                  refundAmount
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </h4>
            </div>
          </div>
          <app-button
            appSubscriptionCheck
            featureToCheck="transactionLimit"
            (handleClick)="openRefundPayment()"
            buttonText="Record Return"
            type="third"
          ></app-button>
        </div>
        <div class="mt-2" *ngIf="billDetails?.refundHistory?.length > 0">
          <table class="w-full">
            <tr>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium p-2"
              >
                {{ "Date" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Amount" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Payment Method" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Memo" | translate }}
              </td>
              <td
                class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
              >
                {{ "Actions" | translate }}
              </td>
            </tr>
            <tr
              *ngFor="
                let payment of billDetails?.refundHistory
                  | paginate : { itemsPerPage: 5, currentPage: page }
              "
            >
              <td class="p-2">
                {{ payment?.refundDate | date : "dd-MM-YYYY" }}
              </td>
              <td>
                {{
                  payment?.amount
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
              <td>{{ payment?.paymentMethod }}</td>
              <td>{{ payment?.memo }}</td>
              <td>
                <div
                  *ngIf="payment?.paymentMethod !== 'paypal'"
                  class="flex item-center"
                >
                  <app-tooltip
                    customClass="mb-8"
                    class="me-2"
                    tooltipName="{{ 'Receipt' | translate }}"
                  >
                    <svg
                      [routerLink]="[
                        '/purchases/bills/view-receipt',
                        payment.recieptId
                      ]"
                      ro
                      xmlns="http://www.w3.org/2000/svg"
                      class="h-6 w-6"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z"
                      />
                    </svg>
                  </app-tooltip>
                </div>
              </td>
            </tr>
          </table>
          <pagination-controls
            autoHide="true"
            (pageChange)="page = $event"
          ></pagination-controls>
        </div>
      </div>

      <!-- Refund Bill End  -->
      <div class="rounded-xl border border-[#D7D7D7] p-6 my-3">
        <span class="text-lg mb-2 Poppins-Medium">{{
          "Items" | translate
        }}</span>
        <table class="w-full">
          <tr>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium p-2"
            >
              {{ "Item Name" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "SKU" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "HSN/SAC" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "Quantity" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "Price" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "Total" | translate }}
            </td>
            <td
              class="border-b border-[#1C1C1C33] text-[#1C1C1C33] Poppins-Medium"
            >
              {{ "Tax" | translate }}
            </td>
          </tr>
          <tr *ngFor="let item of billDetails2?.items">
            <td class="p-2">{{ item.item }}</td>
            <td>{{ item.sku }}</td>
            <td>{{ item.hsn_sac }}</td>
            <td>{{ item.unit }}</td>
            <td>
              {{
                item.price
                  | currency
                    : currencyDetails?.currency
                    : "symbol-narrow"
                    : number
              }}
            </td>
            <td>
              {{
                item.unit * item.price
                  | currency
                    : currencyDetails?.currency
                    : "symbol-narrow"
                    : number
              }}
            </td>
            <td>
              {{
                item?.tax && item?.tax?.tax > 0
                  ? item.tax.tax + "%"
                  : "No Tax"
              }}
            </td>
          </tr>
        </table>
      </div>

      <div class="rounded-xl border border-[#D7D7D7] p-6 my-3">
        <div class="">
          <span class="Poppins-Medium text-lg mb-2">{{
            "Total Amount" | translate
          }}</span>
          <table>
            <tr>
              <td class="pe-2">{{ "Subtotal" | translate }}</td>
              <td>
                {{
                  billDetails2?.subtotal || 0
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
            </tr>
            <tr>
              <td class="pe-2">{{ "Tax" | translate }}</td>
              <td>
                {{
                  billDetails2?.tax || 0
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
            </tr>
            <tr>
              <td class="pe-2">{{ "Total Amount" | translate }}</td>
              <td>
                {{
                  billDetails2?.totalAmount || 0
                    | currency
                      : currencyDetails?.currency
                      : "symbol-narrow"
                      : number
                }}
              </td>
            </tr>
          </table>
        </div>
      </div>

      <div
        class="rounded-xl border border-[#D7D7D7] p-6 flex flex-col space-y-4 justify-between my-3"
      >
        <app-file-upload
          appAccessControl
          moduleType="storage"
          accessType="view"
          [addedFiles]="addedFiles"
          (emitter)="saveFiles($event)"
          type="compact"
          tableType="new"
        ></app-file-upload>
      </div>
    </div>
  </div>
</div>

<!-- Refund Billl App Modal Start -->

<app-modal-container [isOpen]="recordRefundModal">
  <div class="Poppins-Medium" header>{{ "Return Record" | translate }}</div>
  <my-tabs content>
    <my-tab tabTitle="{{ 'Refund Details' | translate }}">
      <div [formGroup]="refundForm" class="space-y-2 flex flex-col">
        <div class="mt-2">
          <table class="w-full">
            <tr>
              <td class="p-2 bg-input Poppins-Medium rounded-s-xl w-3/14">
                {{ tableFields.items | translate }}
              </td>
              <td class="p-2 bg-input Poppins-Medium w-1/12">
                {{ tableFields.units | translate }}
              </td>
              <td class="p-2 bg-input Poppins-Medium w-2/12">
                {{ tableFields.price | translate }}
              </td>
              <td class="p-2 bg-input Poppins-Medium w-3/12">
                {{ tableFields.tax | translate }}
              </td>
              <td class="p-2 bg-input Poppins-Medium">
                {{ tableFields.amount | translate }}
              </td>
              <td class="p-2 bg-input Poppins-Medium rounded-e-xl"></td>
            </tr>
            <ng-container formArrayName="items">
              <ng-container
                *ngFor="let control of items.controls; let i = index"
              >
                <tr [formGroup]="control" class="">
                  <td class="w-3/12 p-2">
                    <ng-select
                      [items]="itemList"
                      class="new-dropdown"
                      bindLabel="item"
                      bindValue="item"
                      formControlName="item"
                      [clearable]="false"
                      placeholder="{{ 'Select one' | translate }}"
                      (change)="changeEvent($event, i)"
                    >
                    <ng-template ng-option-tmp let-item="item" let-index="index">
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.item }}
                        </div>
                      </div>
                    </ng-template>
                    </ng-select>
                  </td>
                  <td class="p-2 w-1/12">
                    <fieldset>
                      <input
                        id="quantity"
                        formControlName="unit"
                        type="number"
                        class="new-input"
                      />
                    </fieldset>
                  </td>
                  <td class="p-2 w-2/12">
                    <fieldset disabled="true">
                      <input
                        id="price"
                        formControlName="price"
                        type="number"
                        min="0"
                        class="new-input"
                      />
                    </fieldset>
                  </td>
                  <td class="p-2 w-3/12">
                    <fieldset disabled="true">
                      <input
                        formControlName="tax"
                        type="text"
                        value="{{
                          items.controls[i].get('tax').value
                            ? items.controls[i].get('tax').value?.taxName
                            : (null | json)
                        }}"
                        class="new-input"
                      />
                    </fieldset>
                  </td>
                  <td class="p-2">
                    <p>
                      {{
                        items.controls[i].get("unit").value *
                          items.controls[i].get("price").value
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </td>
                  <td class="w-1/12">
                    <svg
                      (click)="removeItem(i)"
                      xmlns="http://www.w3.org/2000/svg"
                      name="delete"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      class="mx-auto h-5 w-5 text-black-500"
                    >
                      <path
                        _ngcontent-hsm-c58=""
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                      ></path>
                    </svg>
                  </td>
                </tr>
                <tr>
                  <td colspan="1"></td>
                  <td>
                    <p
                      class="text-red-500"
                      *ngIf="control.get('unit').hasError('min')"
                    >
                      {{ "Atleast 1 unit is required" }}
                    </p>
                    <p
                      class="text-red-500"
                      *ngIf="control.get('unit').hasError('max')"
                    >
                      {{ "limit Reached" }}
                    </p>
                  </td>
                  <td colspan="4"></td>
                </tr>
              </ng-container>
              <ng-container *ngIf="items.controls.length === 0">
                <tr>
                  <td colspan="8" class="text-center p-2">
                    {{ "No items added" | translate }}
                  </td>
                </tr>
              </ng-container>
            </ng-container>
            <tr>
              <td vAlign="top" colspan="3">
                <div class="ps-2">
                  <app-button (handleClick)="addNewItem()" type="add-line">
                    <img
                      src="assets/images/delete-icon.svg"
                      style="transform: rotate(45deg)"
                      alt=""
                    />
                    {{ "Add a Line" | translate }}
                  </app-button>
                </div>
              </td>
            </tr>
          </table>
          <!-- <pagination-controls autoHide="true" (pageChange)="page = $event"></pagination-controls> -->
        </div>
        <div>
          <label for="amount">{{ "Amount" | translate }}</label>
          <input
            formControlName="amount"
            id="amount"
            type="number"
            [value]="totalAmount"
            class="new-input"
            disabled
          />
          <!-- <div class="text-red-500 text-sm ms-2" *ngIf='invalidRefundAmount'>{{'Invalid Amount' | translate }}</div> -->
        </div>
        <!-- <div>
          <label for='paymentType'>{{'Payment Method' | translate }}</label>
          <ng-select [items]="paymentMethods"
                        [searchable]='false'
                        [clearable]='false'
                        class="custom-selector" 
                        formControlName="paymentMethod"
                        placeholder='{{"Select one" | translate }}'>
              </ng-select>
          <div class="text-red-500 text-sm ms-2" *ngIf='refundFormErrors.paymentMethod'>{{refundFormErrors.paymentMethod}}</div>
          </div> -->
        <div>
          <label for="paymentAccount">{{
            "Payment Account" | translate
          }}</label>
          <input
            formControlName="paymentAccount"
            [value]="billDetails?.vendor?.vendorName"
            id="memo"
            type="text"
            class="new-input"
            disabled
          />
          <div
            class="text-red-500 text-sm ms-2"
            *ngIf="refundFormErrors.paymentAccount"
          >
            {{ refundFormErrors.paymentAccount }}
          </div>
        </div>
        <div>
          <label for="memo">{{ "Memo" | translate }}</label>
          <input
            formControlName="memo"
            id="memo"
            type="text"
            class="new-input"
          />
        </div>
      </div>
    </my-tab>
    <my-tab
      appAccessControl
      moduleType="storage"
      accessType="edit"
      tabTitle="{{ 'Files' | translate }}"
    >
      <app-file-upload (emitter)="saveRefundFiles($event)"></app-file-upload>
    </my-tab>
  </my-tabs>

  <div footer class="flex gap-x-2">
    <app-button
      buttonText="{{ 'Record' | translate }}"
      type="primary"
      rounded="true"
      (click)="generateRefundPayment()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      type="third"
      rounded="true"
      (click)="cancelRefund()"
    ></app-button>
  </div>
</app-modal-container>

<!-- Refund Billl App Modal End -->

<app-modal-container [isOpen]="emailModal">
  <div header class="Poppins-Medium">
    {{ "Share bill via email" | translate }}
  </div>
  <div content class="flex flex-col">
    <label for="">{{ "Email" | translate }}</label>
    <input [formControl]="email" type="text" class="new-input" />
    <div
      *ngIf="email.getError('required') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email is required" | translate }}
    </div>
    <div
      *ngIf="email.getError('pattern') && email.dirty"
      class="text-sm text-red-400 ms-2"
    >
      {{ "Email must be in correct format" | translate }}
    </div>
    <div *ngIf="error" class="text-red-500 ms-2">{{ error }}</div>
    <div *ngIf="response" class="text-green-500 ms-2">{{ response }}</div>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="primary"
      buttonText="{{ 'Send' | translate }}"
      (handleClick)="sendEmail()"
    ></app-button>
    <app-button
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="emailModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="shareURLModal">
  <div header class="Poppins-Medium">
    {{ "Bill Shareable link" | translate }}
  </div>
  <div content class="flex items-center me-2">
    <div class="border rounded-xl flex items-center ps-2">
      <a
        [href]="shareURL"
        class="max-w-[400px] overflow-hidden whitespace-nowrap overflow-ellipsis"
      >
        {{ shareURL }}
      </a>
      <app-button
        type="primary"
        [buttonText]="copyButtonText"
        (handleClick)="copyLink()"
      ></app-button>
    </div>
  </div>
  <div footer>
    <app-button
      type="third"
      buttonText="{{ 'Close' | translate }}"
      (handleClick)="shareURLModal = false"
    ></app-button>
  </div>
</app-modal-container>

<app-modal-container [isOpen]="confirmDeleteModal">
  <div header>
    {{ "Delete Payment Record" | translate }}
  </div>
  <div content>
    <p>{{ "Delete payment confirmation" | translate }}</p>
    <p>{{ "Delete payment warning" | translate }}</p>
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      type="danger"
      buttonText="{{ 'Delete' | translate }}"
      (handleClick)="confirmDelete()"
    ></app-button>
    <app-button
      type="third"
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="confirmDeleteModal = false"
    ></app-button>
  </div>
</app-modal-container>

<record-payment
  type="bill"
  [isOpen]="recordPaymentModal"
  [amountDue]="amountDue"
  [minDate]="minDate"
  (closeModal)="recordPaymentModal = false"
  (paymentData)="recordPayment($event)"
></record-payment>

<record-payment
  type="bill"
  [isOpen]="editRecordPaymentModal"
  [amountDue]="amountDue"
  [recordData]="recordData"
  [minDate]="minDate"
  (closeModal)="editRecordPaymentModal = false"
  (paymentData)="saveEditedRecord($event)"
></record-payment>

<ngx-spinner
  name="view"
  bdColor="rgba(138,170,172,0.49)"
  size="medium"
  color="#0C4D71"
  type="ball-clip-rotate"
  [fullScreen]="false"
></ngx-spinner>
