import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { CustomerService } from '../../customer.service';

@Component({
  selector: 'app-customers-list',
  templateUrl: './customers-list.component.html',
  styleUrls: ['./customers-list.component.scss']
})

export class CustomersListComponent implements OnInit, OnDestroy {
  constructor(private store: Store<RootReducerState>,
              private router: Router,
              private customerService: CustomerService,
              private salesService: SalesService,
              private translateService: TranslateService,
              private toastr: ToastrService,
              private numberService: NumberService,
              private spinner: NgxSpinnerService) {
  this.customers$ = this.store.pipe(select(selectCustomers));
  }
  customers$: Observable<any>;
  businessId = null;
  deleteConfirmationModal = false;
  unsubscribe$ = new Subject();
  customerToDelete = null;
  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  tableHeadings = [
    'Name',
    'Email',
    'Phone',
    'Balance Overdue',
    'Actions'
  ];
  tableKeys = ['customerName', 'email', 'mobileNumber', 'total'];
  tableData = [];

  ngOnInit(): void {
    this.getCurrencyDetails();
    this.loadCustomers();
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail.subscribe(details=>{
      if(details)
      this.currencyDetails = details;
    })
  }

  loadCustomers(): void {
    this.spinner.show();
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((customers) => {
      this.spinner.hide();
      this.tableData = customers.map(item =>
        ({
          ...item,
          total: item?.total ? this.numberService.currencier(item?.total) : null
        })
      );
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  editCustomer(id): void {
    this.router.navigate(['/sales/customers/create-customer'], { queryParams: { id } });
  }

  viewCustomer(id): void {
    this.router.navigate(['/sales/customers/view-customer'], { queryParams: { id } });
  }

  deleteCustomer(): void {
    this.spinner.show();
    this.customerService.deleteCustomer(this.customerToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.deleteConfirmationModal = false;
      this.customerService.deleteCustomerInStore(this.customerToDelete);
    }, (error) => {
      if(error.status === 400){
        this.toastr.error('Cannot delete customer');
      }
      else{
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    });
  }

  deleteConfirmation(id): void {
    this.customerToDelete = id;
    this.deleteConfirmationModal = true;
  }
}
