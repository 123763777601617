import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';

@Component({
  selector: 'app-progress-bar',
  templateUrl: './progress-bar.component.html',
})
export class ProgressBarComponent implements OnInit, OnChanges {
  constructor() {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes['progress']) {
      setTimeout(() => {
        this.percent = changes.progress.currentValue;
      },300);
    }
  }
  @Input() className = '';
  @Input() outerClass = '';
  @Input() progress = 0;
  percent = 0;

  ngOnInit() {}
}
