import { Action, createReducer, on } from '@ngrx/store';
import { ISubscription } from '../models/subscription';
import * as SubscriptionAction from '../actions/subscription.actions';
import { UserLogout } from '../actions/user.actions';

export interface SubscriptionState {
    subscription: ISubscription;
}

export const initialState: SubscriptionState = {
    subscription: {
        subscriptionPlans: [],
        userSubscription: {
            subscriptionId: '',
            subId: '',
            date: '',
            expiryDate: '',
            planName: '',
            planPrice: null
        }
    }
};

export const subscriptionReducer = createReducer(initialState,
    on(SubscriptionAction.setSubscriptionPlans, (state: SubscriptionState, {subscriptionPlans}) =>
    ({...state, subscription: { ...state.subscription, subscriptionPlans }})),
    on(SubscriptionAction.setUsersSubscription, (state: SubscriptionState, {subscription}) => {
        const currentPlan = state.subscription.subscriptionPlans.find(({_id}) => _id === subscription.subscriptionId);
        return {...state, subscription: { ...state.subscription, userSubscription: { ...currentPlan, ...subscription } }};
    }),
    on(UserLogout, (state: SubscriptionState) => ({
        ...state,
        subscription: {
            ...state.subscription,
            userSubscription: { ...initialState.subscription.userSubscription }
        }
    }))
);

export function reducer(state: SubscriptionState | undefined, action: Action): any {
    return subscriptionReducer(state, action);
}

export const getSubscriptionPlans = (state: SubscriptionState) => state.subscription.subscriptionPlans;
export const getUserSubscription = (state: SubscriptionState) => state.subscription.userSubscription;
