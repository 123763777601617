import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { map, takeUntil, tap } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from '../settings/settings-modules/accounting/accounting.service';
import { EstimatesService } from './components/estimates/estimates.service';
import { ProductsService } from './components/products-and-services/products.service';
import { SalesService } from './sales.service';

@Component({
  selector: 'app-sales-container',
  templateUrl: './sales-container.component.html',
  styleUrls: ['./sales-container.component.scss']
})
export class SalesContainerComponent implements OnInit, OnDestroy {

  constructor(private store: Store<RootReducerState>,
              private salesServices: SalesService,
              private productService: ProductsService,
              private accoutingService: AccountingService,
              private estimateService: EstimatesService) {
               this.business$ = this.store.pipe(select(selectBusiness));
              }
  
  business$: Observable<any>;
  unsubscribe$ = new Subject();

  ngOnInit(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        const id = business?.businessId._id;
        const currencyDetails = (({currency,currencySymbol})=>({currency,currencySymbol}))(business?.businessId);
        this.salesServices.setCurrencyDetails(currencyDetails);
        this.productService.businessId = id;
        this.salesServices.setBusinessId(id);
        this.loadProducts(id);
        this.loadSalesTaxes(id);
        this.loadCustomers(id);
        this.loadCustomizationSettings();
      }
    });
  }
  loadProducts(id): void {
    this.productService.listProduct(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.salesServices.setProducts(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  loadSalesTaxes(id): void {
    this.accoutingService.listSalesTaxes(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.salesServices.setSalesTaxes(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  loadCustomizationSettings(): void {
    this.salesServices.getInvoiceCustomizationSettings()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.salesServices.setCustomizationSettings(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  loadCustomers(id): void {
    this.salesServices.customerList(id)
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(resp => {
      this.salesServices.setCustomers(resp.data);
    }, (error) => {
      console.log(error);
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
