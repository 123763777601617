<div
  class="fixed z-[30] inset-0 overflow-y-hidden"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <!-- 
<div class="flex modal-mobile-height items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity z-[-1] backdrop-blur-md" aria-hidden="true"></div>
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >
    <div class="inline-block modal-mobile-view align-bottom bg-[#f9f9f9] rounded-lg text-start shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-xl sm:w-full" -->
  <div
    class="flex modal-mobile-height items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
    (click)="closeModal($event)"
    id="modal-parent-click"
  >
    <div
      class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity modal-backdrop z-[-1]"
      aria-hidden="true"
    ></div>

    <!-- This element is to trick the browser into centering the modal contents. -->
    <span
      class="hidden sm:inline-block sm:align-middle sm:h-screen"
      aria-hidden="true"
      >&#8203;</span
    >
    <div
      class="z-[50] inline-block modal-mobile-view align-bottom text-start shadow-xl transition-all sm:my-8 sm:align-middle sm:w-full sm:max-w-[100rem] rounded-3xl bg-white p-3 h-[75vh]"
      [ngClass]="{
        'settings-container-safari': isSafari,
        'settings-container-other': !isSafari
      }"
    >
      <div class="px-4 pt-5 pb-4 sm:pb-4 rounded-t-lg bg-white sm:p-6">
        <div class="text-lg mb-4">
          <ng-content select="[header]"></ng-content>
        </div>
        <div class="mb-4">
          <ng-content select="[content]"></ng-content>
        </div>
      </div>
    </div>
  </div>
</div>
