import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TimeSheetComponent } from './time-sheet.component';
import { RegisterHoursComponent } from './components/register-hours/register-hours.component';
import { HoursReportComponent } from './components/hours-report/hours-report.component';
import { EmployeesReportComponent } from './components/employees-report/employees-report.component';

const routes: Routes = [
  {
    path: '',
    component: TimeSheetComponent,
    children: [
      {
        path: 'register',
        component: RegisterHoursComponent
      },
      {
        path: 'hours-report',
        component: HoursReportComponent
      },
      {
        path: 'employees-report',
        component: EmployeesReportComponent
      }
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TimeSheetRoutingModule {}
