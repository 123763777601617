import { Component, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-estimate-settings',
  templateUrl: './estimate-settings.component.html',
})
export class EstimateSettingsComponent {
  @Input() formGroup = new FormGroup({});
}
