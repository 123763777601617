import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-verify-alternate-email',
  templateUrl: './verify-alternate-email.component.html',
  styleUrls: ['./verify-alternate-email.component.scss']
})
export class VerifyAlternateEmailComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private authService: AuthService,
              private spinner: NgxSpinnerService,
              private router: Router) { }

  verifying = true;
  verificationSuccess = false;
  verificationFailed = false;
  error = '';

  ngOnInit(): void {
    this.route.params.subscribe(({emailId, userId}) => {
      console.log(emailId, userId);
      if (emailId && userId) {
        this.verifyAlternateEmail({primaryId: userId, id: emailId});
      }
    });
  }

  verifyAlternateEmail(data): void {
    this.spinner.show();
    this.authService.verifyAlternateEmail(data)
    .subscribe((resp) => {
      this.spinner.hide();
      console.log(resp);
      this.verifying = false;
      this.verificationSuccess = true;
      setTimeout(() => this.router.navigate(['/auth/login']), 3000);
    }, (error) => {
      this.error = error?.error?.message || 'Internal server error';
      this.spinner.hide();
      this.verifying = false;
      this.verificationFailed = true;
    });
  }

}
