import { Location } from '@angular/common';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { UserManagementService } from './user-management.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
})
export class UserManagementComponent implements OnInit {
  business$: Observable<any>;
  user$: Observable<any>;
  isThisMyBusiness: boolean = false;
  showInviteUser = false;
  inviteId: any;
  constructor(
    public router: Router,
    private location: Location,
    private store: Store<RootReducerState>,
    private userManagementService: UserManagementService
  ) {
    this.business$ = store.pipe(select(selectBusiness));
    this.user$ = store.pipe(select(selectUser));
  }

  ngOnInit(): void {
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        this.user$.subscribe((user) => {
          if (business?.businessId?.userId === user?._id) {
            this.isThisMyBusiness = true;
          } else this.isThisMyBusiness = false;
        });
        this.userManagementService.setCompanyName(
          business?.businessId?.companyName
        );
      }
    });
  }

  back(): void {
    if (this.showInviteUser) {
      this.showInviteUser = false;
      this.inviteId = null;
    }
  }
  roleChosen(role: string) {
    this.showInviteUser = true;
  }
  editInvite(user) {
    this.inviteId = user.inviteId;
    this.showInviteUser = true;
  }
}
