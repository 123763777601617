import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { LanguageService } from 'src/app/shared/services/language.service';
import { getAllAccountType } from 'src/app/shared/utils/accountType';
import { dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { getAlltransaction } from 'src/app/shared/utils/transaction';

@Component({
  selector: 'app-filter-form',
  templateUrl: './filter-form.component.html',
  styleUrls: ['./filter-form.component.scss']
})
export class FilterFormComponent implements OnInit {

  @Input() form: FormGroup;
  @Input() formErrors;
  constructor(private fb: FormBuilder,private languageService: LanguageService) {}
  openFilterModal = true;
  category = ['Invoice', 'Bill', 'Journal Entry', 'Credit Note', 'Debit Note', 'Receipt', 'Payment Voucher', 'Expenses Entry'];
  status = ['Reviewed', 'Not Reviewed'];
  type = this.languageService.translateArray([
    {id: 1, name: 'Deposit'},
    {id: 2, name: 'Withdrawl'},
    {id: 3, name: 'Journal'}, 
  ]);
  receipt = this.languageService.translateArray([
    {id: 1, name: 'Attached'},
    {id: 2, name: 'None'},
    {id: 3, name: 'Deposit'},
  ]);

  ngOnInit(): void {
  }

}
