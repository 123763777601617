import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountingComponent } from './accounting.component';
import { RouterModule, Routes } from '@angular/router';
import { DateAndCurrencyComponent } from './components/date-and-currency/date-and-currency.component';
import { SalesTaxesComponent } from './components/sales-taxes/sales-taxes.component';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { SalesTaxesListComponent } from './components/sales-taxes/components/sales-taxes-list/sales-taxes-list.component';
import { CreateTaxComponent } from './components/sales-taxes/components/create-tax/create-tax.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    component: AccountingComponent,
    children: [
      {
        path: '',
        component: DateAndCurrencyComponent
      },
      {
        path: 'date-and-currency',
        component: DateAndCurrencyComponent
      },
      {
        path: 'sales-taxes',
        component: SalesTaxesComponent,
        children: [
          {
            path: '',
            component: SalesTaxesListComponent
          },
          {
            path: 'create-tax',
            component: CreateTaxComponent
          }
        ]
      }
    ]
  }
];

@NgModule({
  declarations: [
    AccountingComponent,
    DateAndCurrencyComponent,
    SalesTaxesComponent,
    SalesTaxesListComponent,
    CreateTaxComponent
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    NgSelectModule,
    SharedModule,
    DirectivesModule,
    TranslateModule,
    RouterModule.forChild(routes)
  ]
})
export class AccountingModule { }
