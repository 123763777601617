import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ListProjectsComponent } from './components/list-projects/list-projects.component';
import { ProjectDetailsComponent } from './components/project-details/project-details.component';
import { ProjectsComponent } from './projects.component';

const routes: Routes = [
  {
    path: '',
    component: ProjectsComponent,
    children: [
      {
        path: 'list',
        component: ListProjectsComponent,
      },
      {
        path: ':id',
        component: ProjectDetailsComponent,
      },
    ],
  },
];

@NgModule({
  declarations: [
    ProjectsComponent,
    ListProjectsComponent,
    ProjectDetailsComponent,
  ],
  imports: [
    ReactiveFormsModule,
    CommonModule,
    SharedModule,
    ClickOutsideModule,
    RouterModule.forChild(routes),
    TranslateModule,
    DirectivesModule,
    PipesModule,
  ],
})
export class ProjectsModule {}
