<div
  class="w-full flex flex-col items-center space-y-4 bg-white border border-[#898989] rounded-lg p-6"
>
  <div class="w-full my-2 flex flex-col gap-[10px]">
    <p class="Poppins-Medium text-base">
      {{ "Features you'll get" | translate }}:
    </p>
    <ol class="Poppins text-sm leading-6">
      <li>{{ features[from] | translate }}</li>
    </ol>
  </div>
  <div
    class="w-full rounded-2xl flex justify-between text-sm Poppins text-[#1C1C1CCC] items-center bg-[#F0F5FF] px-5 py-3"
  >
    <div>Subscribe to unlock this feature</div>
    <app-button
      type="primary"
      buttonText="Buy Now"
      (handleClick)="showConfirmationDialog = true"
    >
    </app-button>
  </div>
</div>

<app-modal-container [isOpen]="showConfirmationDialog">
  <div header class="border-b Gilory-SemiBold pb-2">
    {{ "Purchase Subscription Plan" | translate }}
  </div>
  <div content>
    {{ "Are you sure you want to purchase this plan?" | translate }}
  </div>
  <div footer class="flex gap-x-2">
    <app-button
      (handleClick)="getSubscriptionLink()"
      buttonText="{{ 'Yes' | translate }}"
      type="primary"
      rounded="true"
    ></app-button>
    <app-button
      buttonText="{{ 'No' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="showConfirmationDialog = false"
    >
    </app-button>
  </div>
</app-modal-container>
