<div class="flex flex-col h-full" *ngIf="!(showCreate || showView)">
  <div class="items-start justify-start flex">
    <app-submit
      value="Create"
      className="bg-[#026AA2] text-white w-28 h-12"
      (clicked)="showCreate = true"
    >
    </app-submit>
  </div>
  <div class="w-full mt-2 text-xs relative h-full">
    <table class="w-full text-left rtl:text-right pos-discount-management">
      <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
        <tr>
          <td
            *ngFor="let heading of tableHeadings"
            scope="col"
            class="px-4 py-2"
          >
            {{ heading | translate }}
          </td>
        </tr>
      </thead>
      <ng-container *ngIf="tableData.length; else noData">
        <tbody>
          <tr *ngFor="let row of tableData; trackBy: row?._id" class="bg-white">
            <td *ngFor="let key of tableKeys" class="px-6 py-4">
              {{ row[key] | empty : "-" }}
            </td>
            <td class="px-6 py-4">
              {{ row?.usedByMobile.length }}
            </td>
            <td class="px-6 py-4">
              <label class="flex items-center cursor-pointer relative">
                <input
                  type="checkbox"
                  class="sr-only peer"
                  [(ngModel)]="row.isActive"
                  (change)="changeStatus(row)"
                />
                <div
                  [ngClass]="{
                    'after:bg-white': row?.isActive,
                    'after:bg-black bg-[#d9d9d9]': !row?.isActive
                  }"
                  class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
                ></div>
              </label>
            </td>
            <td class="pl-6 py-4">
              <app-dropdown #dropdown [isFromSettings]="true">
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M19 9l-7 7-7-7"
                  />
                </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1">
                  <div
                    (click)="openShowModal(row)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Usage Info" | translate }}
                  </div>
                  <div
                    (click)="openEditModal(row)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Edit" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </table>
  </div>
</div>

<app-discount-create-edit
  *ngIf="showCreate"
  (discard)="showCreate = false; loadBusiness()"
  [codeToEdit]="codeDetails"
></app-discount-create-edit>
<app-discount-code-view
  *ngIf="showView"
  (close)="showView = false"
  [codeToView]="codeDetails"
>
</app-discount-code-view>
<ng-template #noData class="flex items-center justify-center">
  <tbody>
    <tr>
      <td class="pt-8 text-center" colspan="7">
        {{ "No Data to Show" | translate }}
      </td>
    </tr>
  </tbody>
</ng-template>
