<p class="text-md Poppins-Bold mb-4">{{'Choose the plan which suits you' | translate }}</p>
<div class="flex mobilescreen">
    <div *ngFor='let plan of subscriptionPlans; let i = index' class="w-1/4 mobileWidth">
        <div *ngIf="plan?.planName === currentSubscription?.planName && currentSubscription?.planName === 'Payroll-Starter'">
            <div class="ms-4 bg-white shadow-xl rounded-xl h-[600px] overflow-hidden">
                <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                    <div class="bg-gradient text-center py-1 text-white text-sm font-bold PoppinsBold">{{'Everything you need' | translate}}</div>
                    <div class="px-4">
                        <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                        <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold PoppinsBold mt-3">{{plan?.planName | translate}}</p>
                        <!-- </div> -->
                        <!-- <p class="Poppins-Bold text-lg">{{plan?.planName}}</p> -->
                        <div class="flex space-x-2  py-5">
                            <p class="text-xl pt-2 text-black Poppins-Bold">$</p>
                            <p class="text-[50px] text-black Poppins-Bold">{{plan?.planPrice | translate}}</p>
                            <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
                        </div>
                    </div>
                </div>
                <!-- <hr class="border-2 mt-2 mb-2" /> -->
                <ol class="space-y-4 list-disc ms-4 p-6">
                    <li class="flex list-item items-center gap-x-2 ">

                        <p class="text-sm Poppins-Bold">{{plan?.features?.maxEmployee | translate}} {{'Employee Limit.' | translate}}</p>
                    </li>
                </ol>
            </div>
        </div>
        <div *ngIf="plan?.planName === 'Payroll'">
            <div class="ms-4 flex flex-col bg-white shadow-xl rounded-xl h-[600px] overflow-hidden">
                <div class="bg-blue-100 h-1/3 max-h-[33.33%] rounded-t-xl overflow-hidden  pb-2 pt-0 ">
                    <div class="bg-gradient text-center py-1 text-white text-sm font-bold PoppinsBold">{{'Everything you need' | translate}}</div>
                    <div class="px-4">
                        <!-- <img src="assets/images/Group 4653.svg" class="w-full" > -->
                        <p class="text-white text-sm px-2 py-1 w-fit bg-gradient rounded-md font-bold PoppinsBold mt-3">{{plan?.planName | translate}}</p>
                        <!-- </div> -->
                        <!-- <p class="Poppins-Bold text-lg">{{plan?.planName}}</p> -->
                        <div class="flex space-x-2  py-5">
                            <p class="text-xl pt-2 text-black Poppins-Bold">$</p>
                            <p class="text-[50px] text-black Poppins-Bold">{{plan?.planPrice | translate }}</p>
                            <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate }}</p>
                        </div>
                    </div>
                </div>
                <!-- <hr class="border-2 mt-2 mb-2" /> -->
                <ol class="flex-1 space-y-4 list-disc ms-4 p-6">
                    <li class="flex list-item items-center gap-x-2 ">

                        <p class="text-sm Poppins-Bold">{{plan?.features?.maxEmployee | translate}} {{'Employee Limit.' | translate }}</p>
                    </li>
                </ol>
                <div class="my-4 flex justify-center">
                    <ng-container *ngIf='currentSubscription!== null else buyButtons'>
                        <app-button
                            *ngIf='plan?.planName === currentSubscription?.planName'
                            buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                            (handleClick)='cancelSubscriptionPlanModal = true'>
                        </app-button>
                        <app-button *ngIf='plan?.planName !== currentSubscription?.planName' buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                        (handleClick)='getSubscriptionLink(plan)'></app-button>
                    </ng-container>
                    <ng-template #buyButtons>
                        <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
                            (handleClick)='getSubscriptionLink(plan)'></app-button>
                    </ng-template>
                </div>
            </div>
        </div>
    </div>
</div>

<app-modal-container [isOpen]='cancelSubscriptionPlanModal'>
    <div header class="border-b Gilory-Bold pb-2">
        {{'Cancel Subscription Plan' | translate}}
    </div>
    <div content>
        {{'Are you sure you want to cancel your subscription plan.' | translate }}
    </div>
    <div footer class="flex gap-x-2">
        <app-button (handleClick)='cancelPlan()' buttonText='{{"Yes" | translate }}' type='danger'></app-button>
        <app-button buttonText='{{"No" | translate }}' (handleClick)='cancelSubscriptionPlanModal = false'>
        </app-button>
    </div>
</app-modal-container>
