import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as htmlToImage from 'html-to-image';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesReducerState } from 'src/app/modules/sales/state/reducers';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { selectProducts } from 'src/app/modules/sales/state/selectors/products.selector';
import { selectSalesTaxes } from 'src/app/modules/sales/state/selectors/salesTaxes.selector';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { DateValidator, dateLessThan, sumLessThanValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { InvoicesService } from '../../invoices.servcies';

interface StockCheck {
  item: string;
  openingStock: number,
  quantityAdded: number
}

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss'],
  providers: [DatePipe]
})
export class CreateInvoiceComponent implements OnInit, OnDestroy {

  constructor(
              private fb: FormBuilder,
              private store: Store<SalesReducerState>,
              private rootStore: Store<RootReducerState>,
              private router: Router,
              private spinner: NgxSpinnerService,
              private invoiceService: InvoicesService,
              private route: ActivatedRoute,
              private translateService: TranslateService,
              private numberService: NumberService,
              private fileUploadService: FileUploadService,
              private toaster: ToastrService) {
    this.products$ = this.store.pipe(select(selectProducts));
    this.customers$ = this.store.pipe(select(selectCustomers));
    this.taxes$ = this.store.pipe(select(selectSalesTaxes));
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.projects$ = this.rootStore.pipe(select(selectProjects));
    this.subscription$ = rootStore.pipe(select(selectUserSubscription));
    this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
  }

  invoiceForm: FormGroup;
  products$: Observable<any>;
  customers$: Observable<any>;
  taxes$: Observable<any>;
  business$: Observable<any>;
  projects$: Observable<any>;
  subscription$: Observable<any>;
  customizationSettings$: Observable<any>;

  products = [];
  availableProducts = []
  projects = [];
  taxes = [];
  customers = [];
  files: File[] = [];
  addedFiles = [];
  business = null;
  subscription = null;
  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  tax = 0;
  discount = 0;
  invoiceToUpdate = null;
  unsubscribe$ = new Subject();
  isInstallment: boolean = false;
  instalmentPayDueDate: any;
  totalMisMatchError: string;
  customizationSettings = {
    companyLogo: ''
  };
  instalmentTableFields = {
    amount: 'Amount',
    dueAmount: 'Due Amount',
    dueDate: 'Due Date',
  };
  tableFields = {
    items: 'Items',
    itemDescription: 'Description',
    units: 'Units',
    sku: 'SKU',
    hsn_sac: 'HSN/SAC',
    price: 'Price',
    amount: 'Amount'
  }
  invoiceData = {
    _id: '',
    qrCode: '',
    eSign: '',
    currencySymbol: '',
    discount: null,
    discountValue: null,
    qrHeading: '',
    currencyDetails: {
      currencySymbol: null,
      currencyValue: null,
      convertedAmount: null
    },
    dueAmount: null,
    paymentLink: null,
    paypalInvoiceId: null,
    businessDetails: null,
    customerDetails: null,
    paymentHistory: []
  };
  imageDataEsign = {
    event: '',
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  openCreateEsign =  false;
  update = false;
  openModal = false;
  openModalForEsign = false;
  openDeleteModal = false;
  removeQRCodeModal = false;
  disableFields = false;
  removeESignModal = false;
  eSignSettingModal = false;
  previewSignatureForDoc = false;
  isFieldEditable:boolean = true;
  instalmentTotal:number = 0;

  eSignValue = '';
  eSignSelected = '';
  discountType = 'percent';
  discountValue = 0;

  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload'
    }
  };
  imageDataQR = {
    event: new Event(''),
    openModal: false,
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  formErrors = {
    invoiceNumber: '',
    customerId  : '',
    projectId  : '',
    invoiceDate: '',
    dueDate: '',
    purchaseOrder: '',
    discount: '',
    title: '',
    unit: '',
    price: '',
    installments: ''
  };

  formErrorMessages = {
    invoiceNumber: {
      required: 'Invoice Number is Required'
    },
    customerId: {
      required: 'Customers is Required'
    },
    projectId: {
      required: 'Project is Required'
    },
    invoiceDate: {
      required: 'Date is Required',
      pattern: 'Invalid Date',
      max: 'Invalid Date',
      min: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    dueDate: {
      required: 'Due Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    purchaseOrder: {
      required: 'Purchase Order is Required'
    },
    title: {
      required: 'Title is required'
    },
    discount: {
      min: "Discount can't be less than 0%",
      max: "Discount can't be more than 100%",
    },
    items: {
      price: {
        min: "Price can't be negative"
      },
    },
    unit: {
      min: "Quantity can't be negative"
    },
    installments: {
      sumShouldBe: "installments can not less than total amount",
      sumExceedsTotal: "installments can not excide total amount",
    }

  };

  outOfStock: boolean = false;
  checkQuantityInStock : Array<StockCheck | null> = []

  number = '1.2-2';

  currencySymbol = null;
  minDate = moment('01/01/1000').format('YYYY-MM-DD');
  maxDate = moment('31/12/3000').format('YYYY-MM-DD');

  ngOnInit(): void {
    this.loadInvoiceForm();
    this.loadSubscription();
    this.loadCustomers();
    this.loadProjects();
    this.loadTaxes();
    this.loadBusiness();
    this.loadData();
    this.loadCustomizationSettings();
    this.loadNumberConfig();
    this.route.queryParams.subscribe(({id}) => {
      if (id) {
        this.invoiceToUpdate = id;
        this.spinner.show();
        this.invoiceService.getInvoice(id).subscribe(
          (resp) => {
            this.update = true;
            this.spinner.hide();
            this.invoiceData = resp.data;
            this.appendInvoiceData(resp.data);
          },
          (error) => {
            this.spinner.hide();
            this.router.navigate(['/sales/invoices']);
            console.log(error);
          }
        );
      } else {
        this.invoiceForm.get('invoiceNumber').setValue('DRAFT');
        // this.loadInvoiceNumber();
      }
    });
  }

  changeTotalAmount(event) {
    if(this.isInstallment) {
      this.invoiceForm.controls.dueDate.setValidators([]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
      this.invoiceForm.controls.installments.setValidators([
        sumLessThanValidator(this.totalAmount),
      ]);
      this.invoiceForm.controls.installments.updateValueAndValidity();
    } else {
      this.invoiceForm.controls.installments.setValidators([]);
      this.invoiceForm.controls.installments.updateValueAndValidity();
      this.invoiceForm.controls.dueDate.setValidators([
        Validators.required, 
        DateValidator()
      ]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
    }
  }

  instalmentEvent(event, idx) {
    const installments = this.invoiceForm.get('installments') as FormArray;
    const installment = installments.at(idx) as FormGroup;
    const amount = installment.get('amount')?.value;
    const dueAmountControl = installment.get('dueAmount');
    
    if (dueAmountControl) {
      dueAmountControl.setValue(amount);
    }
  }

  loadSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribe$))
    .subscribe((subscription) => {
      this.subscription = subscription;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(products => {
        if(this.subscription?.subscriptions?.find(sub => sub?.hostName === 'Inventory' )){
          products = products?.filter(product => product?.isReviewed)
        }
                
        this.products = products.filter(product =>{
          return product?.isSale
        });

        this.availableProducts = [...this.products];
        console.log("check1", this.availableProducts);
        
      });
    })
  }

  loadData(): void {

  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number;
    })
  }

  createSignature(): void {
    htmlToImage.toPng(document.getElementById(this.eSignSelected))
    .then((dataUrl) => {
      this.updateEsign(dataUrl);
    })
    .catch((error) => {
      console.error(this.translateService.instant('Something went wrong!'));
    });
  }

  updateEsign(base64): void {
    this.invoiceData.eSign = base64;
    this.eSignSettingModal = false;
    this.openCreateEsign = false;
    this.eSignValue = null;
    this.previewSignatureForDoc = true;
  }

  appendInvoiceData(invoiceData): void {
    const {
      invoiceNumber,
      customerDetails: {
        customerId: { _id }
      },
      invoiceDate,
      dueDate,
      purchaseOrder,
      Footer,
      notesTerms,
      subTotal,
      tax,
      totalAmount,
      items,
      title,
      subHeading,
      discount,
      discountValue,
      reminder = false,
      acceptOnlinePayment = false,
      qrHeading,
      isInstallment,
      installments,
      files = []
    } = invoiceData;
    const mappedItems = items.map(
      ({ item, itemId, itemDescription, unit, tax, sku='',type, hsn_sac='', isSale = false, cogsAccountDetails = null, inventoryAccountDetails = null, productNature, price, totalCost, accountDetails, averagePrice }) => {
        return { item, itemId, itemDescription: (itemDescription || ''), unit, sku, type, hsn_sac, tax, price, totalCost, accountDetails, isSale, inventoryAccountDetails, productNature, cogsAccountDetails, averagePrice: (averagePrice || price) };
      }
    );
    const mappedInstallments = installments?.map(({amount, dueAmount, dueDate, status}) => ({amount, dueAmount, dueDate: dueDate.split('T')[0], status})) ?? []
    this.addedFiles = files ?? [];
    const data = {
      invoiceNumber,
      invoiceDate: invoiceDate.split('T')[0],
      dueDate: dueDate.split('T')[0],
      purchaseOrder: purchaseOrder ?? null,
      subHeading: subHeading ?? null,
      Footer: Footer ?? null,
      title: title ?? 'Invoice',
      notesTerms: notesTerms ?? null,
      items: mappedItems,
      customerId: _id,
      projectId: invoiceData?.projectDetails?.projectId ?? null,
      discount,
      reminder,
      qrHeading,
      isInstallment: isInstallment ?? false,
      installments: mappedInstallments,
      acceptOnlinePayment
    };
    if(invoiceData?.paymentHistory.length>0 || invoiceData?.refundHistory.length>0 ){
      this.isFieldEditable = false;
    }
    this.discountValue = discountValue;
    this.subTotal = subTotal;
    this.tax = tax;
    this.totalAmount = totalAmount;
    this.isInstallment = isInstallment ?? false
    mappedItems.forEach(() => this.items.push(this.createItem()));
    mappedInstallments.forEach(() => this.installments.push(this.createInstalment()));
    this.invoiceForm.setValue(data);
    if(isInstallment) {
      this.invoiceForm.controls.dueDate.setValidators([]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
      this.invoiceForm.controls.installments.setValidators([
        sumLessThanValidator(this.totalAmount),
      ]);
      this.invoiceForm.controls.installments.updateValueAndValidity();
    } else {
      this.invoiceForm.controls.installments.setValidators([]);
      this.invoiceForm.controls.installments.updateValueAndValidity();
      this.invoiceForm.controls.dueDate.setValidators([
        Validators.required, 
        DateValidator()
      ]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
    }
    this.updateAvailableItems()
  }


  fileEventForESign(event): void {
    this.openCreateEsign = false;
    this.openModalForEsign = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 16 / 9,
        title: 'Add E-Sign'
      }
    };
  }

  fileEventForQR(event): void {
    if(!this.isFieldEditable)
      return
    this.openModal =  true,
    this.imageDataQR = {
      event,
      openModal: true,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 1 / 1,
        title: 'Add QR Code'
      }
    };
  }
  
  openEsignModal(): void{
    if(!this.isFieldEditable)
      return;
    this.openCreateEsign = true;
    this.previewSignatureForDoc = true;
  }

  loadInvoiceNumber(): void {
    this.spinner.show();
    const from = moment().startOf('year').format('YYYY-MM-DD');
    const to = moment().endOf('year').format('YYYY-MM-DD');
    const year = moment().format('YYYY');
    const body = {
      from,
      to,
      year
    };
    this.invoiceService.getInvoiceNumber(body).subscribe((resp) => {
      this.invoiceForm.get('invoiceNumber').setValue(resp.data);
      this.spinner.hide();
    }, (error) => {
      this.spinner.hide();
      this.router.navigate(['/sales/invoices']);
      this.toaster.error(this.translateService.instant('Unable to generate invoice number'));
    });
  }

  reloadInvoiceNumber(): void {
    const invoiceDateField = this.invoiceForm.get('invoiceDate');

    if (invoiceDateField.valid && this.invoiceForm.get('invoiceNumber').value !== 'DRAFT' && this.update) {
      console.log(invoiceDateField);
      const invoiceDateYear = new Date(invoiceDateField.value).getFullYear();
      const invoiceNumber = this.invoiceForm.get('invoiceNumber').value;
      const invoiceYear = invoiceNumber.replace(/^\D+|\D.*$/g, '');
      console.log(invoiceYear, invoiceDateYear, Number(invoiceYear) === invoiceDateYear);
      if (Number(invoiceYear) !== invoiceDateYear) {
        const from = moment(`${invoiceDateYear}`).startOf('year').format('YYYY-MM-DD');
        const to = moment(`${invoiceDateYear}`).endOf('year').format('YYYY-MM-DD');
        const body = {
          from,
          to,
          year: invoiceDateYear
        };
        this.spinner.show();
        this.invoiceService.getInvoiceNumber(body).subscribe((resp) => {
          this.invoiceForm.get('invoiceNumber').setValue(resp.data);
          this.spinner.hide();
        }, (error) => {
          this.spinner.hide();
          this.toaster.error(this.translateService.instant('Something went wrong!'));
        });
      }
    }
  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(projects => {
      this.projects = projects;
    });
  }

  loadTaxes(): void {
    this.taxes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(taxes => {
      this.taxes = taxes.map((tax) => ({...tax, name: tax.taxName, tax: tax.taxRate, accountDetails: tax.accountDetails}));
    });
  }

  loadCustomers(): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(customers => {
      this.customers = customers?.filter(customer => customer?.isValidate);
    });
  }

  loadBusiness(): void {
    this.business$.subscribe(business => {
      if(business?.businessId){
        this.business = business?.businessId;
        this.currencySymbol = this.business?.currency;
      }
    });
  }

  loadCustomizationSettings(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        console.log(settings);

        this.customizationSettings = settings;
        this.tableFields = {
          ...this.tableFields,
          items: settings?.items || this.tableFields.items,
          amount: settings?.amount || this.tableFields.amount,
          units: settings?.units || this.tableFields.units,
          price: settings?.price || this.tableFields.price
        }
        this.invoiceForm.get('title').setValue(settings?.invoiceSettings?.title ?? 'Invoice');
        this.invoiceForm.get('subHeading').setValue(settings?.invoiceSettings?.scheduling);
        this.invoiceForm.get('notesTerms').setValue(settings?.invoiceSettings?.terms);
        this.invoiceForm.get('Footer').setValue(settings?.invoiceSettings?.footer);
        this.invoiceForm.get('reminder').setValue(settings?.reminder);
      }
    });
  }

  loadInvoiceForm(): void {
    this.invoiceForm = this.fb.group({
      customerId: [null, [Validators.required]],
      projectId: [null],
      invoiceNumber: [{value: null, disabled: true}],
      purchaseOrder: [null],
      invoiceDate: [new Date().toISOString().split('T')[0], [Validators.required, DateValidator()]],
      dueDate: [null, [Validators.required, DateValidator()]],
      title: [{value:null, disabled: true}, [Validators.required, ]],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      subHeading: [null],
      notesTerms: [null],
      Footer: [null],
      qrHeading: [''],
      reminder: [''],
      acceptOnlinePayment: [true],
      items: this.fb.array([]),
      isInstallment: [false],
      installments: this.fb.array([])
    }, {validator: dateLessThan('invoiceDate', 'dueDate')});
    this.invoiceForm.valueChanges.subscribe(({items}) => {
      this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      this.calculateTotal(items);
      this.totalMisMatchError = '';
      if (this.invoiceForm.value.isInstallment) 
        this.calculateInstalmentTotal();
    });
    this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }

  instalmentChk(event: any) {
    console.log("check1");
    
    if (!this.isFieldEditable) return;
    const inputElement = event.target as HTMLInputElement;
    this.isInstallment = inputElement.checked;
    console.log("check2", this.isInstallment);
    if(this.isInstallment) {
      this.invoiceForm.controls.dueDate.setErrors(null);
      this.invoiceForm.controls.dueDate.setValidators([]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
      this.invoiceForm.controls.installments.setValidators([
        sumLessThanValidator(this.totalAmount),
        ]);
      console.log("check3", this.invoiceForm.controls.dueDate);
        this.invoiceForm.controls.installments.updateValueAndValidity();
        } else {
      console.log("check4");
      this.invoiceForm.controls.installments.setErrors(null);
      this.invoiceForm.controls.installments.setValidators([]);
      this.invoiceForm.controls.installments.updateValueAndValidity();
      this.invoiceForm.controls.dueDate.setValidators([
        Validators.required, 
        DateValidator()
      ]);
      this.invoiceForm.controls.dueDate.updateValueAndValidity();
    }
  }

  calculateInstalmentTotal(): void {
    let total          = 0;
    let lastIdx = -1;
    this.invoiceForm.value.installments.forEach((instalment, idx) => {
      lastIdx = idx
      this.instalmentPayDueDate = instalment.dueDate
      return total += instalment.amount
    })
    this.instalmentTotal = total;
    // if (lastIdx > -1)
    //   this.invoiceForm.controls['dueDate'].setValue(this.invoiceForm.value.installments[lastIdx]?.dueDate)
  }

  changeTax(event, i) {
    console.log(event,i);
    if(this.items.length>0){
        this.items.controls[i].get('tax').setValue(event)
      }
    this.changeTotalAmount(event)
  }

  createInvoice(): void {
    console.log("this.invoiceForm");
    if (this.isInstallment) {
      this.invoiceForm.controls['dueDate'].setValue(this.instalmentPayDueDate)
    } 
    const invoiceData: any = this.invoiceDataMapper();
    console.log("this.invoiceForm1", invoiceData);

    if (invoiceData.items.length === 0) {
      this.toaster.error('Please select item to proceed');
      return;
    }

    console.log("invoiceData.items", invoiceData.items);
    
    if (invoiceData.items[0].item === null) {
      this.toaster.error('Please select item to proceed');
      return;
    }

    if(invoiceData?.items?.some(item => !item.itemId)){
      this.toaster.error('Please select item for each line item before proceeding');
      return;
    }
    
    if (this.isInstallment && invoiceData.installments.length === 0) {
      this.toaster.error('Please enter installment details');
      return;
    }

    if (this.isInstallment && this.totalAmount !== this.instalmentTotal) {
      this.totalMisMatchError = this.formErrorMessages.installments.sumShouldBe;
      return;
    }
    
    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }
    
    console.log("invoiceData", invoiceData);

    if(this.subscription?.subscriptions?.find(sub => sub?.subHost === 'Inventory') ){

      let stockCheck: Array<StockCheck | null> = [];
      invoiceData.items?.forEach(item => {
        console.log("itemProductType", item);
        
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product?.itemType === 'Product' && product.productNature === 'normal' && product?.stockDetails?.openingStock < item?.unit){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.quantity
          })
          console.log('stockCheck', stockCheck, this.checkQuantityInStock);
        }
      })
      this.checkQuantityInStock = stockCheck;
      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    let formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(invoiceData))
    this.spinner.show();
    this.invoiceService.createInvoice(formData).subscribe(resp => {
      this.spinner.hide();
      this.toaster.success(resp?.message || 'Invoice created successfully');
      this.router.navigate(['/sales/invoices/view-invoice'], { queryParams: { id: resp.data._id } });
    }, error => {
      console.log("checkingError", error)
      this.spinner.hide();
      this.toaster.error(error?.error?.message)
    });
  }

  updateInvoice(): void {
    if (this.invoiceForm.invalid) { 
      this.invoiceForm.markAllAsTouched();
      this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }

    if (this.isInstallment && this.totalAmount !== this.instalmentTotal) {
      this.totalMisMatchError = this.formErrorMessages.installments.sumShouldBe;
      return;
    }

    const invoiceData: any = {
      ...this.invoiceData,
      ...this.invoiceDataMapper(),
      dueAmount: this.invoiceData?.paymentHistory.length > 0 ? this.numberService.toFixed(this.invoiceData?.dueAmount) : this.numberService.toFixed(this.totalAmount),
      businessId: this.business?._id,
    };

    if(invoiceData?.items?.some(item => !item.itemId)){
      this.toaster.error('Please select item for each line item before proceeding');
      return;
    }

    if(this.subscription?.subscriptions?.find(sub => sub?.subHost === 'Inventory') ){
      let stockCheck: Array<StockCheck | null> = [];
      invoiceData.items?.forEach(item => {
        const product = this.products.find(product => product?._id == item?.itemId);
        if(product?.itemType === 'Product' && product.productNature === 'normal' && product?.stockDetails?.openingStock < item?.unit){
          stockCheck.push({
            item: item?.item,
            openingStock: product?.stockDetails?.openingStock,
            quantityAdded: item?.unit
          })
          console.log(stockCheck, this.checkQuantityInStock);
        }
      })
      this.checkQuantityInStock = stockCheck;

      if(this.checkQuantityInStock.length){
        this.outOfStock = true;
        return
      }
    }

    const updatePaypalInvoice = (invoiceData.paymentLink && invoiceData.paypalInvoiceId) ?? false;
    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    if (this.isInstallment) {
      this.invoiceForm.controls['dueDate'].setValue(this.instalmentPayDueDate)
    } 
    let formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(invoiceData))
    this.invoiceService.updateInvoice({formData,_id: invoiceData?._id}).subscribe(resp => {
      this.spinner.hide();
      if (updatePaypalInvoice) {
        const body = {
          paypalInvoiceId: this.invoiceData.paypalInvoiceId,
          businessId: this.invoiceData.businessDetails.businessId._id,
          data: this.invoiceService.generatePaypalData(invoiceData)
        };
        this.spinner.show();
        this.invoiceService.updatePaypalInvoice(body).subscribe((paypalUpdateResp) => {
          this.spinner.hide();
          if (paypalUpdateResp.success) {
            this.toaster.success('Invoice updated');
            this.router.navigate(['/sales/invoices/view-invoice'], { queryParams: { id: this.invoiceToUpdate } });
          }
        }, (error) => {
          this.spinner.hide();
          this.toaster.error('Paypal invoice updation failed');
        });
      } else {
        this.toaster.success('Invoice updated');
        this.router.navigate(['/sales/invoices/view-invoice'], { queryParams: { id: this.invoiceToUpdate } });
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error(error?.error?.message)
    });
  }

  invoiceDataMapper(): object {
    const body = {
      ...this.invoiceForm.getRawValue(),
      businessDetails: {
        businessId: this.business._id
      },
      customerDetails: {
        customerId: this.invoiceForm.value.customerId
      },
      projectDetails: {
        projectId: this.invoiceForm.value.projectId
      },
      currencyDetails: {
        currencySymbol: this.invoiceData?.currencyDetails?.currencySymbol ?? this.currencySymbol,
        currencyValue: this.invoiceData?.currencyDetails?.currencyValue ?? null,
        convertedAmount: this.invoiceData?.currencyDetails?.convertedAmount ?? null
      },
      companylogo: this.customizationSettings.companyLogo || '',
      totalAmount: this.numberService.toFixed(this.totalAmount),
      averageTotalAmount: this.numberService.toFixed(this.averageTotalAmount),
      dueAmount: this.numberService.toFixed(this.totalAmount),
      tax: this.numberService.toFixed(this.tax),
      subtotal: this.numberService.toFixed(this.subTotal),
      discountValue: this.numberService.toFixed(this.discountValue),
      discount: this.numberService.toFixed(this.invoiceForm.value?.discount),
      qrCode: this.invoiceData.qrCode,
      eSign: this.invoiceData.eSign
    };

    return body;
  }

  get items(): FormArray {
    return this.invoiceForm.get('items') as FormArray;
  }

  get installments(): FormArray {
    return this.invoiceForm.get('installments') as FormArray;
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      itemDescription: '',
      sku: '',
      hsn_sac: '',
      price: [null,[Validators.min(0)]],
      unit: [1,[Validators.min(0.01)]],
      tax: null,
      type: '',
      totalCost: [null,[Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      productNature: null,
      averagePrice: 0
    });
    return itemForm;
  }

  createInstalment(): FormGroup {
    const instalmentForm = this.fb.group({
      amount: [0, Validators.required],
      dueAmount: [0],
      dueDate: [null, [Validators.required, DateValidator()]],
      status: ['draft']
    });
    return instalmentForm;
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    this.invoiceForm.controls.items.valueChanges.subscribe(items=>{
      console.log("valueChanges", items);
      
    })
  }

  addNewInstalment(): void {
    this.installments.push(this.createInstalment());
  }

  removeInstalment(index): void {
    this.installments.removeAt(index);
  }

  removeItem(index): void {
    this.items.removeAt(index);
    this.updateAvailableItems();
  }

  changeEvent(event, index): void {
    const { _id, name, itemDescription='', sellPrice=0, itemType='', tax, accountDetails, sku='', hsn='', sac='', isSale = false, inventoryAccountDetails = null, cogsAccountDetails = null, averagePrice, productNature } = event;
    this.items.controls[index].setValue({itemId: _id, item: name, itemDescription, type:itemType, sku, hsn_sac: hsn || sac, price:sellPrice, tax, isSale, cogsAccountDetails, inventoryAccountDetails, productNature, accountDetails, unit: 1, totalCost: sellPrice * 1, averagePrice: this.numberService.toFixed(averagePrice || sellPrice)});   
    this.updateAvailableItems()
    this.changeTotalAmount(event)
  }
  updateAvailableItems(){
    const allIds = this.items.value.map(item=>item.itemId) 
    this.availableProducts = this.products.filter(el => !allIds.includes(el?._id));
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let total = 0;
    let totalTax = 0;
    let discountPercent = 0;
    let averageTotalAmount = 0;
    const discountFormValue = this.invoiceForm.get('discount').value;
    const discountFormField = this.invoiceForm.get('discount');
    if (discountFormField.valid) {
      discountPercent = this.numberService.toFixed(discountFormValue);
    }
    estimateItems.forEach(item => {
      const { price, unit, tax, averagePrice } = item;
      if(price > 0 && unit > 0){
        let itemTotal = price * unit;
        let averageItemTotal = averagePrice * unit;
        subTotal += itemTotal;
        if(discountFormValue > 0){
          const discount = itemTotal * (discountPercent / 100);
          itemTotal -= discount;
          averageItemTotal -= discount;
        }
        total += itemTotal;
        averageTotalAmount += averageItemTotal;
        if (tax) { totalTax += this.calculateItemTax(itemTotal, tax); }
      }

    });
    this.tax = this.numberService.toFixed(totalTax);
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalAmount = this.numberService.toFixed(total + totalTax);
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount)
    this.discount = this.numberService.toFixed(subTotal - total);
    this.discountValue =  this.numberService.toFixed(total);
    console.log('total', this.totalAmount, this.subTotal, estimateItems);
  }

  calculateItemTax(price, taxes): any {
    return [taxes].reduce((a, b) =>  a += (price) * (b.tax / 100), 0);
  }

  calculateDiscountPercent(total, discountValue): number {
    return this.numberService.toFixed((discountValue * 100) / this.numberService.toFixed(total));
  }
  removeQRCode(): void {
    this.invoiceData.qrCode = '';
    this.removeQRCodeModal = false;
  }

  removeEsign(): void {
    this.invoiceData.eSign = '';
  }

  // updateEsign(base64): void {
  //   this.invoiceData.eSign = base64;
  //   this.eSignSettingModal = false;
  // }

  loadEsign(event): void {
    this.openModalForEsign = false;
    this.invoiceData.eSign = event;
  }

  loadQR(event): void {
    this.openModal = false;
    this.invoiceData.qrCode = event;
  }

  saveFiles(event): void {
    console.log(event);
    this.files = event;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
