import { Component, OnInit } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { RootReducerState } from 'src/app/store/reducers';
import { FileManagerService } from '../../file-manager.service';
import { StorageService } from 'src/app/modules/storage/storage.service';
import { TaskServiceService } from 'src/app/modules/task-manager/task-service.service';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-shared-with-me',
  templateUrl: './shared-with-me.component.html',
  styleUrls: ['./shared-with-me.component.scss'],
})
export class SharedWithMeComponent implements OnInit {
  tableHeadings = ['File Name', 'File Type', 'Shared On', 'Shared By', 'View', 'Download'];
  allFiles: any;
  tableKeys = ['fileName', 'fileType'];
  businessId$: Observable<any>;
  businessId: string;
  unsubscribe = new Subject();

  constructor(
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private rootStore: Store<RootReducerState>,
    private filesService: FileManagerService,
    private storageService: StorageService,
    private taskService: TaskServiceService
  ) {
    this.businessId$ = rootStore.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.businessId$.pipe(takeUntil(this.unsubscribe)).subscribe((business) => {
      if (business?.businessId?._id) {
        this.businessId = business?.businessId?._id;
        if(this.businessId) {
          this.getAllSharedFiles(this.businessId);
        }
      }
      console.log('business?', this.businessId);
    });
  }

  getAllSharedFiles(businessId): void {
    this.spinner.show();
    this.filesService.getAllSharedFiles(businessId).subscribe(
      (resp) => {
        console.log('resp', resp);
        this.allFiles = resp.data;
        this.spinner.hide();
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }

  viewOrDownloadFile(fileId): void {
    this.spinner.show();
    this.storageService.getFile(fileId).subscribe(
      (resp) => {
        if (resp?.success) {
          console.log(resp?.data);
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data;
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {
            type: resp?.data?.docData?.fileType,
          });
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = resp?.data?.docData?.displayFileName;
          a.click();
          window.URL.revokeObjectURL(url);
          this.spinner.hide();
        }
      },
      (err) => {
        this.spinner.hide();
      }
    );
  }


  viewFileInNewTab(fileId): void {
    this.spinner.show();
      this.storageService.getFile(fileId)
      .subscribe(resp => {
        if(resp?.success){
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data; 
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {type: resp?.data?.docData?.fileType});
          const url = window.URL.createObjectURL(blob);
          const newTab = window.open(url, '_blank');
          if (newTab) {
            newTab.onbeforeunload = () => {
              window.URL.revokeObjectURL(url);
            };
          }
          this.spinner.hide();
        }
      }, (err)=>{
        this.spinner.hide();
      })
  }
}
