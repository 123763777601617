<div class="flex justify-center p-8">
  <div class="w-full flex flex-col space- y-4">
    <div class="m-auto mt-5 w-full">
      <form class="space-y-4 mb-4" [formGroup]="invoiceForm">
        <div class="rounded-xl px-9 py-12 bg-white">
          <div class="w-full space-y-8">
            <div class="grid grid-cols-4 gap-8 rtl:space-x-reverse">
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Country Code" | translate
                }}</label>
                <fieldset>
                  <ng-select
                    [items]="countryWithCode"
                    class="new-dropdown h-11"
                    bindLabel="text"
                    bindValue="code"
                    formControlName="countryCode"
                    placeholder="{{ 'Select Country Code' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.text }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                  <div class="text-red-500" *ngIf="formErrors.countryCode">
                    {{ formErrors.countryCode }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Customer Mobile Number" | translate
                }}</label>
                <fieldset>
                  <input
                    (focusout)="searchCustomerByMobileNumber()"
                    pattern="^[1-9][0-9]*$"
                    oninput="if(!this.value.match('^[1-9][0-9]*$'))this.value='';"
                    formControlName="customerNumber"
                    type="number"
                    class="new-input"
                  />
                  <div class="text-red-500" *ngIf="formErrors.customerNumber">
                    {{ formErrors.customerNumber }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Add a Project" | translate
                }}</label>
                <fieldset>
                  <ng-select
                    [items]="projects"
                    class="new-dropdown h-11"
                    bindLabel="projectName"
                    bindValue="_id"
                    formControlName="projectId"
                    [readonly]="!isFieldEditable"
                    placeholder="{{ 'Select Project Name' | translate }}"
                  >
                    <ng-template
                      ng-option-tmp
                      let-item="item"
                      let-index="index"
                    >
                      <div class="px-2 py-1">
                        <div
                          class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                        >
                          {{ item.projectName }}
                        </div>
                      </div>
                    </ng-template>
                  </ng-select>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Customer First Name" | translate
                }}</label>
                <fieldset>
                  <input
                    formControlName="customerFirstName"
                    type="text"
                    class="new-input"
                  />
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Customer Last Name" | translate
                }}</label>
                <fieldset>
                  <input
                    formControlName="customerLastName"
                    type="text"
                    class="new-input"
                  />
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Customer Email" | translate
                }}</label>
                <fieldset>
                  <input
                    formControlName="customerEmail"
                    type="text"
                    class="new-input"
                  />
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Customer Company Name" | translate
                }}</label>
                <fieldset>
                  <input
                    formControlName="customerCompanyName"
                    type="text"
                    class="new-input"
                  />
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Purchase Order #" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <input
                    formControlName="purchaseOrder"
                    type="text"
                    class="new-input"
                  />
                  <div class="text-red-500" *ngIf="formErrors.purchaseOrder">
                    {{ formErrors.purchaseOrder }}
                  </div>
                </fieldset>
              </div>
              <div class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Invoice Date" | translate
                }}</label>
                <fieldset [disabled]="!isFieldEditable">
                  <input
                    [readOnly]="true"
                    formControlName="invoiceDate"
                    type="date"
                    class="new-date"
                  />
                  <div class="text-red-500" *ngIf="formErrors.invoiceDate">
                    {{ formErrors.invoiceDate }}
                  </div>
                </fieldset>
              </div>
              <div *ngIf="showPaymentMethod()" class="flex flex-col gap-[10px]">
                <label class="block text-base Poppins-Medium leading-5">{{
                  "Payment Account" | translate
                }}</label>
                <ng-select
                  [items]="paymentAccounts$ | async"
                  class="new-dropdown h-11 p-0"
                  bindLabel="accountName"
                  formControlName="paymentMethod"
                  [clearable]="false"
                  placeholder="{{ 'Select payment account' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.accountName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
                <div class="text-red-500" *ngIf="formErrors.paymentMethod">
                  {{ formErrors.paymentMethod }}
                </div>
              </div>
              <div
                *ngIf="invoiceForm.get('customerBalance').value"
                class="flex flex-col gap-[10px]"
              >
                <label class="block text-base Poppins-Medium leading-5"
                  >{{ "Customer Balance" | translate }}({{ "As of" }}-{{
                    customerBalanceUpdatedAt | date : "dd/MM/yyyy HH:mm"
                  }})</label
                >
                <div class="flex items-center">
                  <fieldset class="w-4/5" [disabled]="true">
                    <input
                      [readOnly]="true"
                      formControlName="customerBalance"
                      type="text"
                      class="border-0 bg-input-sales h-11 w-full rounded-lg text-sm flex items-end content-end flex-wrap gap-4 p-2 focus:ring-0 placeholder-gray-200"
                    />
                    <div class="text-red-500" *ngIf="formErrors.invoiceDate">
                      {{ formErrors.invoiceDate }}
                    </div>
                  </fieldset>
                  <span class="w-1/5">
                    <svg
                      (click)="searchCustomerByMobileNumber()"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke-width="1.5"
                      stroke="currentColor"
                      class="w-6 h-6"
                    >
                      <path
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
            <div
              *ngIf="
                invoiceForm.get('customerBalance').value &&
                invoiceForm.get('customerBalance').value > 0
              "
              class="flex gap-x-2"
            >
              <div class="w-1/4"></div>
              <div class="w-1/4"></div>
              <div *ngIf="showPaymentMethod()" class="w-1/4"></div>
              <div class="w-1/4 flex space-x-2">
                <input type="checkbox" formControlName="useCustomerBalance" />
                <p class="Poppins text-sm">
                  {{ "Use customer balance for payment" | translate }}
                </p>
              </div>
            </div>
            <table class="w-full">
              <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
                <tr>
                  <td class="px-4 py-2">
                    {{ tableFields.items | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.itemDescription | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.units | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.price | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ "Tax:" | translate }}
                  </td>
                  <td class="px-4 py-2">
                    {{ tableFields.amount | translate }}
                  </td>
                  <td class="p-2 rounded-e-xl"></td>
                </tr>
              </thead>
              <ng-container formArrayName="items">
                <ng-container
                  *ngFor="let control of items.controls; let i = index"
                >
                  <tr [formGroup]="control" class="">
                    <td class="w-3/12 p-1">
                      <ng-select
                        [items]="availableProducts"
                        class="new-dropdown h-11"
                        bindLabel="name"
                        bindValue="name"
                        formControlName="item"
                        [readonly]="!isFieldEditable"
                        [clearable]="false"
                        placeholder="{{ 'Select one' | translate }}"
                        (change)="changeEvent($event, i)"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-1 w-3/12">
                      <fieldset>
                        <input
                          formControlName="itemDescription"
                          type="text"
                          class="new-input"
                        />
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/12">
                      <fieldset [disabled]="!isFieldEditable">
                        <input
                          id="quantity"
                          formControlName="unit"
                          type="number"
                          min="0"
                          class="new-input"
                        />
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/6">
                      <fieldset [disabled]="!isFieldEditable">
                        <input
                          id="price"
                          formControlName="price"
                          type="number"
                          min="0"
                          class="new-input"
                        />
                        <div class="text-red-500" *ngIf="formErrors.price">
                          {{ formErrors.price }}
                        </div>
                      </fieldset>
                    </td>
                    <td class="p-1 w-1/12">
                      <ng-select
                        class="new-dropdown h-11 p-0 border-0"
                        [readonly]="!isFieldEditable"
                        [items]="taxes"
                        bindLabel="name"
                        formControlName="tax"
                        [clearable]="true"
                        placeholder="{{ 'Select Tax' | translate }}"
                      >
                        <ng-template
                          ng-label-tmp
                          let-item="item"
                          let-clear="clear"
                        >
                          <span class="ng-value-label"
                            >{{ item.taxName }}
                            {{ item.tax | number : number }}%</span
                          >
                          <span
                            class="ng-value-icon right"
                            (click)="clear(item)"
                            aria-hidden="true"
                            >×</span
                          >
                        </ng-template>
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.name }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-1">
                      <p>
                        {{
                          items.controls[i].get("unit").value *
                            items.controls[i].get("price").value
                            | currency
                              : currencySymbol
                              : "symbol-narrow"
                              : number
                        }}
                      </p>
                    </td>
                    <td *ngIf="isFieldEditable" class="w-1/12">
                      <svg
                        (click)="removeItem(i)"
                        xmlns="http://www.w3.org/2000/svg"
                        name="delete"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                        class="mx-auto h-5 w-5 text-red-500"
                      >
                        <path
                          _ngcontent-hsm-c58=""
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        ></path>
                      </svg>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="3"></td>
                    <td>
                      <p
                        class="text-red-500 whitespace-nowrap"
                        *ngIf="control.get('unit').hasError('min')"
                      >
                        {{ "Quantity should be more than 0" }}
                      </p>
                    </td>
                    <td colspan="4"></td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="items.controls.length === 0">
                  <tr>
                    <td colspan="8" class="text-center p-2">
                      {{ "No items added" | translate }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <td vAlign="top" colspan="4">
                  <div class="ps-2 text-black">
                    <button
                      (click)="addNewItem()"
                      id="add"
                      class="flex items-center space-x-4"
                    >
                      <span
                        class="p-2 rounded-sm border border-[#1C1C1C0D] bg-[#f6f6f6c9] flex gap-2 me-2"
                      >
                        <img
                          src="assets/images/delete-icon.svg"
                          style="transform: rotate(45deg)"
                          alt=""
                        />
                        {{ "Add a Line" | translate }}
                      </span>
                    </button>
                  </div>
                </td>
                <td colspan="4">
                  <div class="flex justify-between py-2">
                    <p>{{ "Subtotal:" | translate }}</p>
                    <p>
                      {{
                        subTotal
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex items-center justify-between py-2">
                    <p>{{ "Discount (%):" | translate }}</p>
                    <fieldset class="w-1/4" [disabled]="!isFieldEditable">
                      <input
                        type="number"
                        class="new-input"
                        formControlName="discount"
                        min="0"
                      />
                      <div class="text-red-400" *ngIf="formErrors.discount">
                        {{ formErrors.discount }}
                      </div>
                    </fieldset>
                    <p>
                      {{
                        discount
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ "Discount Subtotal:" | translate }}</p>
                    <p>
                      {{
                        discountValue
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-between py-2">
                    <p>{{ "Tax:" | translate }}</p>
                    <p>
                      {{
                        tax
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                  <div
                    class="flex justify-between py-2 Poppins-Medium border-t"
                  >
                    <p>{{ "Total Amount:" | translate }}</p>
                    <p>
                      {{
                        totalAmount
                          | currency : currencySymbol : "symbol-narrow" : number
                      }}
                    </p>
                  </div>
                </td>
              </tr>
            </table>
            <div class="pt-4 border-t border-gray-100 space-y-4"></div>
          </div>
          <div class="mt-4 flex flex-col gap-[10px]">
            <label class="block text-base Poppins-Medium leading-5">{{
              "Notes & Terms" | translate
            }}</label>
            <div class="flex gap-28">
              <fieldset [disabled]="!isFieldEditable" class="w-1/3">
                <textarea
                  formControlName="Footer"
                  class="new-input !h-full"
                ></textarea>
              </fieldset>
              <app-file-upload
                appAccessControl
                moduleType="storage"
                accessType="edit"
                [type]="'compact'"
                (emitter)="saveFiles($event)"
                class="w-1/3"
              ></app-file-upload>
              <div class="flex flex-col items-start justify-start ms-auto">
                <div class="flex flex-col gap-4">
                  <app-submit
                    [value]="'Create Invoice'"
                    className="bg-[#026AA2] text-white w-44"
                    (clicked)="createInvoice()"
                  ></app-submit>
                  <app-submit
                    value="Discard"
                    className="bg-[#F6F6F6CC] text-[#1C1C1C66] w-44 shadow-btn"
                    routerLink="../"
                  ></app-submit>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="outOfStock">
  <div header class="text-start border-b pb-2">
    {{ "Check Items" | translate }}
  </div>
  <div class="text-start" content>
    <p>Following items have insufficient quantity in inventory:</p>
    <ul>
      <li class="Poppins list-disc" *ngFor="let item of checkQuantityInStock">
        <div>
          {{ item?.item }} added with {{ item?.quantityAdded }} units has
          {{ item?.openingStock }} units only
        </div>
      </li>
    </ul>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <!-- <app-button buttonText='{{"Delete" | translate}}' type='danger' rounded=true (handleClick)='deleteEstimateslist()' ></app-button> -->
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="third"
      rounded="true"
      (handleClick)="outOfStock = false"
    ></app-button>
  </div>
</app-modal-container>

<!-- <app-image-cropper [imageData]='imageDataEsign' (base64Image)='loadEsign($event)'></app-image-cropper> -->
