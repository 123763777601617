import { Action, createReducer, on } from '@ngrx/store';
import * as ResetActions from '../actions/reset.actions';
import * as UserActions from '../actions/user.actions';
import * as BusinessActions from '../actions/business.actions';
import { User } from '../models/user';
import { UsageState } from './usage.reducer';


export const userFeatureKey = 'user';

export interface UserState {
  user: User;
  loading: boolean;
  error: any;
}

export const initialState: UserState = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    token: '',
    DOB: '',
    refreshtoken: '',
    businesses: [],
    userEmails: [],
    subscription: {},
    currentUsage: {
      businessLimit:0,
      transactionLimit:0,
      adminLimit:0,
      supportEmail:0,
  }
  },
  loading: false,
  error: ''
};

export const userReducer = createReducer(
  initialState,
  on(UserActions.UserLogin,
      (state: UserState) => ({ ...state, loading: true })
  ),
  on(UserActions.UserLoginSuccess,
    (state: UserState, {user}) => ({...state, loading: false, user: {...state.user, ...user}, error: ''})
  ),
  on(UserActions.UserLoginFailure,
    (state: UserState, {error}) => ({...state, loading: false, error})
  ),
  on(UserActions.UserSignUp,
    (state: UserState) => ({ ...state, loading: true })
  ),
  on(UserActions.UserSignUpSuccess,
    (state: UserState, {user, token, refreshtoken}) => ({ ...state, loading: false, user: { ...state.user, token, refreshtoken }, error: '' })
  ),
  on(UserActions.UserSignUpFailure,
    (state: UserState, {error}) => ({ ...state, loading: false, error })
  ),
  on(UserActions.SetUser,
    (state: UserState, {user}) => ({ ...state, loading: false, user: { ...state.user, ...user } })
  ),
  on(ResetActions.ResetErrors, (state: UserState) => ({ ...state, error: '' })
  ),
  on(UserActions.UserLogout, (state: UserState) => ({...initialState})),
  on(UserActions.UserEmailRemove, (state: UserState, {email}) => {
    const emailList = state.user.userEmails.filter((ogemail) => ogemail.email !== email);
    return {...state, user: { ...state.user, userEmails: [...emailList]}};
  }),
  on(BusinessActions.UpdateBusiness, (state: UserState, {business}) => {
    const newBusinesses = state.user.businesses.map((ogbusiness) => {
      if (ogbusiness.businessId._id === business._id) {
        return {...ogbusiness, businessId: business };
      }
      return ogbusiness;
    });
    return {...state, user: { ...state.user, businesses: newBusinesses }};
  }),
  on(UserActions.UpdateTransactionLimit, (state: UserState, {transactionLimit}) => {
    return {...state, user:{...state.user, currentUsage:{...state.user.currentUsage, transactionLimit}}}
  }),
  on(UserActions.UpdateBusinessLimit, (state: UserState, {businessLimit}) => {
    return {...state, user:{...state.user, currentUsage:{...state.user.currentUsage, businessLimit}}}
  }),
  on(UserActions.UpdateSupportEmailLimit, (state: UserState, {supportEmailLimit}) => {
    return {...state, user:{...state.user, currentUsage:{...state.user.currentUsage, supportEmailLimit}}}
  }),
  on(UserActions.UpdateBusinessLimit, (state: UserState, {businessLimit}) => {
    return {...state, user:{...state.user, currentUsage:{...state.user.currentUsage, businessLimit}}}
  })
);

export function reducer(state: UserState | undefined, action: Action): any {
  return userReducer(state, action);
}

export const getUser = (state: UserState) => state.user;
export const getUserBusinesses = (state: UserState) => state.user.businesses;
export const getUserEmails = (state: UserState) => state.user.userEmails;
export const getUserSubscription = (state: UserState) => state.user.subscription;
export const getLoading = (state: UserState) => state.loading;
export const getError = (state: UserState) => state.error;
