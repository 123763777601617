import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { StorageService } from '../../storage.service';
import { Observable, Subject } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as moment from 'moment';
import { HttpUrlEncodingCodec } from '@angular/common/http';

@Component({
  selector: 'app-file-listing',
  templateUrl: './file-listing.component.html',
  styleUrls: ['./file-listing.component.scss']
})
export class FileListingComponent implements OnInit {
  
  codec = new HttpUrlEncodingCodec();
  unsubscribe$ = new Subject();
  business$: Observable<any>;

  businessId: string = null;
  documentType: string = null;
  openIndex: number | null = null;

  filesData = [];


  tableHeadings = [
    'File Name', 'File Size', 'File Type', 'Created At', 'Created By', 'Actions'
  ]

  tableKeys = [
    'displayFileName', 'memory', 'fileType', 'createdAt', 'userName'
  ]

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store<RootReducerState>,
    private storageService: StorageService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService
  ) { 
    this.business$ = store.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.route.params
    .subscribe(params => {
      if(params?.documentType){
        this.documentType = this.codec.decodeValue(params?.documentType);
      }
    })
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(business => {
      if(business){
        this.businessId = business?.businessId?._id;
        this.loadData();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    })
  }

  loadData(): void {
    if(this.documentType && this.businessId){
      this.spinner.hide();
      this.storageService.getFilesBbySection(this.documentType, this.businessId)
      .pipe(
        takeUntil(this.unsubscribe$)
      )
      .subscribe(resp => {
        if(resp?.success){
          this.filesData = resp?.data?.map(item => {
            return {
              ...item,
              files: item?.files?.map(file =>  ({
                ...file,
                createdAt: moment(file?.createdAt).format('DD-MM-YYYY'),
                memory: ((file?.memory)/(1024)).toFixed(2)+'KB'
              }))
            }
          }) ?? [];
        }
        else{
          this.toastr.error(resp?.message);
        }
        this.spinner.hide();
      }, (err) => {
        this.spinner.hide();
        this.toastr.error(err?.error?.message);
      })
    }
  }

  toggleAccordion(index: number): void {
    if (this.openIndex === index) {
      // If the clicked item is already open, close it
      this.openIndex = null;
    } else {
      // If the clicked item is closed, open it
      this.openIndex = index;
    }
  }

  isAccordionOpen(index: number): boolean {
    return this.openIndex === index;
  }

  viewOrDownloadFile(fileId: string, action: string): void {
    console.log(action);
    
    if(action === 'View'){
      console.log('in if');
      
      this.router.navigate(['/storage/view-file'], {queryParams: {fileId}})
    }
    else {
      console.log('in else');
      this.spinner.show();
      this.storageService.getFile(fileId)
      .subscribe(resp => {
        console.log('in resp', resp);
        if(resp?.success){
          console.log(resp?.data);
          const a = document.createElement('a');
          const bufferArray = resp?.data?.fileBuffer?.data; // Adjust the property name based on your API response

          // Convert Buffer array to Uint8Array
          const uint8Array = new Uint8Array(bufferArray);
          const blob = new Blob([uint8Array], {type: resp?.data?.docData?.fileType});
          const url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = resp?.data?.docData?.displayFileName;
          a.click();
          window.URL.revokeObjectURL(url);
        }
        else{
          this.toastr.error(resp?.message)
        }
        this.spinner.hide();
      }, (err) => {
        console.log(err);
        
        this.toastr.error(err?.error?.message);
        this.spinner.hide();
      })
    }
  }

  downloadZip(item: any): void {
    this.spinner.show();
    this.storageService.getReferenceZip(item?.referenceId)
    .subscribe(resp => {
      this.spinner.hide();
      const a = document.createElement('a');
      const bufferArray = resp?.data?.data; 
      const uint8Array = new Uint8Array(bufferArray);
      const blob = new Blob([uint8Array], {type: 'application/zip'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `${item?.referenceNumber}.zip`;
      a.click();
      window.URL.revokeObjectURL(url);
      this.toastr.success('File downloaded');
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message);
    })
  }

  check(): void {
    console.log("check")
  }

}
