<div class="flex justify-center p-4">
    <div class="w-3/4 flex flex-col space-y-4">
        <div class="flex items-center">
            <h1 class="text-2xl Poppins-Bold inline-flex items-center">
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-7 w-7 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                    <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/>
                </svg>
                {{'Personal Profile Management' | translate}}
            </h1>
        </div>
        <p class="text-lg mb-4 Poppins-SemiBold text-gray-800">{{'Profile detail message' | translate }}</p>
        
        <app-personal-information ></app-personal-information>
        <app-two-factor-authentication></app-two-factor-authentication>
        <app-emails></app-emails>
        <app-mailing-config></app-mailing-config>
        <app-password-settings></app-password-settings>
        <!-- <app-email-notifications></app-email-notifications> -->
        <app-businesses-management></app-businesses-management>
    </div>
</div>

