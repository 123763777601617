import { Component, OnDestroy, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { InvoiceCustomizationService } from './invoice-customization.service';

@Component({
  selector: 'app-invoice-customization',
  templateUrl: './invoice-customization.component.html',
  styleUrls: ['./invoice-customization.component.scss']
})
export class InvoiceCustomizationComponent implements OnInit, OnDestroy {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
              private store: Store<RootReducerState>,
              private spinner: NgxSpinnerService) {
                this.business$ = this.store.pipe(select(selectBusiness));
              }

  business$: Observable<any>;
  unsubscribe$ = new Subject();
  businessId = null;

  ngOnInit(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.invoiceCustomizationService.getInvoiceCustomizationSettings(business.businessId._id).subscribe((resp) => {
          this.spinner.hide();
          this.invoiceCustomizationService.setInvoiceCustomizationSettings(resp.data, business.businessId._id);
        }, (error) => {
          this.spinner.hide();
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
