import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'messageFilter'
})
export class MessageFilterPipe implements PipeTransform {

  transform(messages: any[], keyword: string): any[] {
    console.log("messages: any[], keyword", messages, keyword);
    
    if (!messages || !keyword) {
      return messages;
    }
    
    return messages.map(group => {
      const filteredMessages = group.messages.filter(message => {
        console.log("messages: any[], keyword1", message);
        return message?.message?.toLowerCase().includes(keyword?.toLowerCase());
      });
      if (filteredMessages.length > 0) {
        return {
          ...group,
          messages: filteredMessages
        };
      }

      return null;
    }).filter(group => group !== null);
  }

}
