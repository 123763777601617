<app-modal-container [isOpen]="isOpen">
  <div class="Poppins-Mediumm," header>{{ "Record Payment" | translate }}</div>
  <my-tabs content>
    <my-tab tabTitle="{{ 'Payment Details' | translate }}">
      <div [formGroup]="paymentForm" content class="space-y-2 flex flex-col">
        <div class="flex flex-col gap-[10px]">
          <label class="Poppins-Medium text-base" for="amount">{{
            "Amount" | translate
          }}</label>
          <input
            formControlName="amount"
            id="amount"
            type="number"
            class="new-input"
          />
          <div class="text-red-500 text-sm ms-2" *ngIf="formErrors.amount">
            {{ formErrors.amount }}
          </div>
          <div class="text-red-500 text-sm ms-2" *ngIf="invalidAmount">
            {{ "Invalid Amount" | translate }}
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="Poppins-Medium text-base" for="amount">{{
            "Date" | translate
          }}</label>
          <input
            formControlName="paymentDate"
            id="paymentDate"
            type="date"
            class="new-date"
          />
          <div class="text-red-500 text-sm ms-2" *ngIf="formErrors.paymentDate">
            {{ formErrors.paymentDate }}
          </div>
          <div
            class="text-red-500 text-sm ms-2"
            *ngIf="!formErrors.paymentDate && invalidDateByMinDate"
          >
            {{ "Invalid Date, lower than document date" | translate }}
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="Poppins-Medium text-base" for="paymentType">{{
            "Payment Method" | translate
          }}</label>
          <ng-select
            [items]="paymentMethods"
            [searchable]="false"
            [clearable]="false"
            class="new-dropdown"
            formControlName="paymentMethod"
            placeholder="{{ 'Select one' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <div
            class="text-red-500 text-sm ms-2"
            *ngIf="formErrors.paymentMethod"
          >
            {{ formErrors.paymentMethod }}
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="Poppins-Medium text-base" for="paymentAccount">{{
            "Payment Account" | translate
          }}</label>
          <ng-select
            [items]="paymentAccounts$ | async"
            [clearable]="false"
            class="new-dropdown"
            bindLabel="accountName"
            groupBy="accountType"
            formControlName="paymentAccount"
            placeholder="{{ 'Select one' | translate }}"
          >
            <ng-template ng-option-tmp let-item="item" let-index="index">
              <div class="px-2 py-1">
                <div
                  class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                >
                  {{ item.accountName }}
                </div>
              </div>
            </ng-template>
          </ng-select>
          <div
            class="text-red-500 text-sm ms-2"
            *ngIf="formErrors.paymentAccount"
          >
            {{ formErrors.paymentAccount }}
          </div>
        </div>
        <div class="flex flex-col gap-[10px]">
          <label class="Poppins-Medium text-base" for="memo">{{
            "Memo" | translate
          }}</label>
          <input
            formControlName="memo"
            id="memo"
            type="text"
            class="new-input"
          />
        </div>
      </div>
    </my-tab>
    <my-tab tabTitle="{{ 'Files' | translate }}">
      <app-file-upload
        type="compact"
        tableType="new"
        [addedFiles]="addedFiles"
        (emitter)="saveFiles($event)"
      ></app-file-upload>
    </my-tab>
  </my-tabs>
  <div footer class="flex gap-x-2">
    <app-button
      buttonText="{{ 'Record' | translate }}"
      type="primary"
      (handleClick)="generatePaymentDetails()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="close()"
    ></app-button>
  </div>
</app-modal-container>
