import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { PaymentSettingService } from './payment-settings.service';

@Component({
  selector: 'app-payment-settings',
  templateUrl: './payment-settings.component.html',
  styleUrls: ['./payment-settings.component.scss']
})
export class PaymentSettingsComponent implements OnInit, OnDestroy {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private toaster: ToastrService,
              private paymentSettingService: PaymentSettingService,
              private spinner: NgxSpinnerService) {
    this.business$ = this.store.pipe(select(selectBusiness))
  }

  paypalForm: FormGroup;
  showPaypalDisconnectButton = false;
  email = null;
  business$: Observable<any>;
  business = null;
  unsubscribe = new Subject();
  showPaypalSettingsForm = true;

  formErrors = {
    email: '',
    clientId: '',
    secretCode: ''
  }

  formErrorMessages = {
    email: {
      required: 'Email is required',
      pattern: 'Invalid email'
    },
    clientId: {
      required: 'Client ID is required'
    },
    secretCode: {
      required: 'Secret Code is required'
    }
  }

  ngOnInit(): void {
    this.loadForm();
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe))
    .subscribe((business) => {
      console.log('business', business);
      if (business?.businessId?._id) {
        this.business = business?.businessId;
        if (this.business.paypalConnected) {
          this.showPaypalDisconnectButton = true;
          this.showPaypalSettingsForm = false;
        } else {
          this.showPaypalDisconnectButton = false;
          this.showPaypalSettingsForm = true;
        }
      }
    })
  }

  loadForm(): void {
    this.paypalForm = this.fb.group({
      email: [null, [Validators.required]],
      clientId: [null, [Validators.required]],
      secretCode: [null, [Validators.required]]
    })

    this.paypalForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.paypalForm, this.formErrors, this.formErrorMessages);
    })

    this.formErrors = valueChanges(this.paypalForm, this.formErrors, this.formErrorMessages);
  }

  connectToPaypal(): void {
    if (this.paypalForm.invalid) {
      this.paypalForm.markAllAsTouched();
      this.formErrors = valueChanges(this.paypalForm, this.formErrors, this.formErrorMessages);
      return;
    }
    const data = {
      ...this.paypalForm.value,
      businessId: this.business?._id
    }
    console.log(data);
    this.spinner.show();
    this.paymentSettingService.connectPaypal(data).subscribe((resp) => {
      this.spinner.hide();
      console.log('resp', resp);
      if (resp.success) {
        this.toaster.success('Connected to PayPal', 'Success');
        this.paypalForm.reset();
        this.showPaypalSettingsForm = false;
        this.showPaypalDisconnectButton = true;
      } else {
        this.toaster.error(resp.message, 'Error');
      }
    }, error => {
      this.spinner.hide();
      this.toaster.error('Something went wrong', 'Error');
    });
  }

  disconnectPaypal(): void {
    const data = {
      businessId: this.business._id
    }
    this.spinner.show();
    this.paymentSettingService.disconnectPaypal(data).subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.toaster.success('Disconnected to PayPal', 'Success');
        this.showPaypalDisconnectButton = false;
        this.showPaypalSettingsForm = true;
      }
    }, (error) => {
      this.spinner.hide();
      this.toaster.error('Something went wrong', 'Error');
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

}
