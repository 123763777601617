import { Component, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import * as htmlToImage from 'html-to-image';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NumberService } from 'src/app/shared/services/number.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUserSubscription } from 'src/app/store/selectors/user.selectors';
import { SalesReducerState } from '../../../state/reducers';
import { selectCustomers } from '../../../state/selectors/customers.selector';
import { selectCustomizationSettings } from '../../../state/selectors/customizationSettings.selector';
import { selectProducts } from '../../../state/selectors/products.selector';
import { selectSalesTaxes } from '../../../state/selectors/salesTaxes.selector';
import { DateValidator, dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { RecurringInvoicesService } from '../recurring-invoices.service';
import { NgSelectComponent } from '@ng-select/ng-select';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-create-invoice',
  templateUrl: './create-invoice.component.html',
  styleUrls: ['./create-invoice.component.scss']
})
export class CreateInvoiceComponent implements OnInit {
  
  unsubscribe$ = new Subject();

  invoiceForm: FormGroup;
  paymentTermForm: FormGroup;

  products$: Observable<any>;
  customers$: Observable<any>;
  taxes$: Observable<any>;
  business$: Observable<any>;
  projects$: Observable<any>;
  subscription$: Observable<any>;
  customizationSettings$: Observable<any>;

  products = [];
  avaialbleProducts = [];
  projects = [];
  taxes = [];
  customers = [];
  files: File[] = [];
  frequency = [
    'Daily', 'Weekly', 'Bi-Weekly', 'Monthly', 'Yearly'
  ];
  paymentTerms = [];

  business = null;
  subscription = null;

  subTotal = 0;
  totalAmount = 0;
  averageTotalAmount = 0;
  tax = 0;
  discount = 0;

  customizationSettings = {
    companyLogo: ''
  };

  tableFields = {
    items: 'Items',
    itemDescription: 'Description',
    units: 'Units',
    sku: 'SKU',
    hsn_sac: 'HSN/SAC',
    price: 'Price',
    amount: 'Amount'
  }

  invoiceData = {
    _id: '',
    qrCode: '',
    eSign: '',
    currencySymbol: '',
    discount: null,
    discountValue: null,
    qrHeading: '',
    currencyDetails: {
      currencySymbol: null,
      currencyValue: null,
      convertedAmount: null
    },
    dueAmount: null,
    paymentLink: null,
    paypalInvoiceId: null,
    businessDetails: null,
    customerDetails: null,
    paymentHistory: []
  };
  imageDataEsign = {
    event: '',
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  openCreateEsign =  false;
  openModal = false;
  openModalForEsign = false;
  openDeleteModal = false;
  removeQRCodeModal = false;
  disableFields = false;
  removeESignModal = false;
  eSignSettingModal = false;
  previewSignatureForDoc = false;
  isFieldEditable:boolean = true;
  showCustomPaymentTerms: boolean = false;


  eSignValue = '';
  eSignSelected = '';
  discountType = 'percent';
  discountValue = 0;

  imageCropperConfig = {
    event: '',
    config: {
      maintainAspectRatio: true,
      roundCropper: true,
      aspectRatio: 1 / 1,
      title: 'Upload'
    }
  };
  imageDataQR = {
    event: new Event(''),
    openModal: false,
    config: {
      maintainAspectRatio: false,
      roundCropper: false,
      aspectRatio: 16 / 9,
      title: 'Upload'
    }
  };

  formErrors = {
    customerId  : '',
    projectId  : '',
    startDate: '',
    endDate: '',
    paymentTerms: '',
    frequency: '',
    purchaseOrder: '',
    discount: '',
    title: '',
    unit: '',
    price: ''
  };

  paymentTermFormErrors = {
    paymentTerm: '',
    days: ''
  }

  formErrorMessages = {
    customerId: {
      required: 'Customers is Required'
    },
    projectId: {
      required: 'Project is Required'
    },
    startDate: {
      required: 'Start Date is Required',
      pattern: 'Invalid Date',
      max: 'Invalid Date',
      min: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    endDate: {
      required: 'End Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date'
    },
    paymentTerms: {
      required: 'Payment Terms are required'
    },
    frequency: {
      required: 'Frequency is required'
    },
    purchaseOrder: {
      required: 'Purchase Order is Required'
    },
    title: {
      required: 'Title is required'
    },
    discount: {
      min: "Discount can't be less than 0%",
      max: "Discount can't be more than 100%",
    },
    items: {
      price: {
        min: "Price can't be negative"
      },
    },
    unit: {
      min: "Quantity can't be negative"
    }
  };

  paymentTermFormErrorMessages = {
    paymentTerm: {
      required: 'Payment Term is required'
    },
    days: {
      required: 'Days are required',
      min: 'Days cannot be less than 1'
    }
  }

  number = '1.2-2';

  currencySymbol = null;
  minDate = moment('01/01/1000').format('YYYY-MM-DD');
  maxDate = moment('31/12/3000').format('YYYY-MM-DD');
  today = moment().format('YYYY-MM-DD')

  constructor(
    private fb: FormBuilder,
    private store: Store<SalesReducerState>,
    private rootStore: Store<RootReducerState>,
    private router: Router,
    private spinner: NgxSpinnerService,
    private invoiceService: RecurringInvoicesService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private numberService: NumberService,
    private fileUploadService: FileUploadService,
    private toaster: ToastrService) {
this.products$ = this.store.pipe(select(selectProducts));
this.customers$ = this.store.pipe(select(selectCustomers));
this.taxes$ = this.store.pipe(select(selectSalesTaxes));
this.business$ = this.rootStore.pipe(select(selectBusiness));
this.projects$ = this.rootStore.pipe(select(selectProjects));
this.subscription$ = rootStore.pipe(select(selectUserSubscription));
this.customizationSettings$ = this.store.pipe(select(selectCustomizationSettings));
}

  ngOnInit(): void {
    this.loadInvoiceForm();
    this.loadPaymentTermForm()
    this.loadSubscription();
    this.loadBusiness();
    this.loadCustomers();
    this.loadCustomizationSettings();
    this.loadNumberConfig();
    this.loadTaxes();
    this.loadProjects();
  }


  loadProducts(): void {

  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(projects => {
      this.projects = projects;
    });
  }

  loadTaxes(): void {
    this.taxes$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(taxes => {
      this.taxes = taxes.map((tax) => ({...tax, name: tax.taxName, tax: tax.taxRate, accountDetails: tax.accountDetails}));
    });
  }

  loadCustomers(): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(customers => {
      this.customers = customers?.filter(customer => customer?.isValidate);
    });
  }

  loadBusiness(): void {
    this.business$.subscribe(business => {
      if(business?.businessId){
        this.business = business?.businessId;
        this.currencySymbol = this.business?.currency;
        this.loadPaymentTerms();
      }
    });
  }

  loadCustomizationSettings(): void {
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        this.customizationSettings = settings;
        this.tableFields = {
          ...this.tableFields,
          items: settings?.items || this.tableFields.items,
          amount: settings?.amount || this.tableFields.amount,
          units: settings?.units || this.tableFields.units,
          price: settings?.price || this.tableFields.price
        }
        this.invoiceForm.get('title').setValue(settings?.invoiceSettings?.title ?? 'Invoice');
        this.invoiceForm.get('subHeading').setValue(settings?.invoiceSettings?.scheduling);
        this.invoiceForm.get('notesTerms').setValue(settings?.invoiceSettings?.terms);
        this.invoiceForm.get('Footer').setValue(settings?.invoiceSettings?.footer);
        this.invoiceForm.get('reminder').setValue(settings?.reminder);
      }
    });
  }

  loadPaymentTerms(): void {
    this.spinner.show();
    this.invoiceService.getPaymentTerms(this.business?._id)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.paymentTerms = resp?.data;
      }
      else{
        this.toaster.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => { 
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
  }

  loadPaymentTermForm(): void {
    this.paymentTermForm = this.fb.group({
      paymentTerm: ['', Validators.required],
      days: [0, [Validators.required, Validators.min(1)]]
    })
    this.paymentTermFormErrors = valueChanges(this.paymentTermForm, this.paymentTermFormErrors, this.paymentTermFormErrorMessages, this.translateService)
    this.paymentTermForm.valueChanges.subscribe(() => {
      this.paymentTermFormErrors = valueChanges(this.paymentTermForm, this.paymentTermFormErrors, this.paymentTermFormErrorMessages, this.translateService)
    })
  }

  loadInvoiceForm(): void {
    this.invoiceForm = this.fb.group({
      customerId: [null, [Validators.required]],
      projectId: [null],
      purchaseOrder: [null],
      startDate: [new Date().toISOString().split('T')[0], [Validators.required, DateValidator()]],
      endDate: [null, [Validators.required, DateValidator()]],
      frequency: [null, Validators.required],
      paymentTerms: [null, Validators.required],
      title: [{value:null, disabled: true}, [Validators.required, ]],
      discount: [0, [Validators.min(0), Validators.max(100)]],
      subHeading: [null],
      notesTerms: [null],
      Footer: [null],
      qrHeading: [''],
      reminder: [''],
      items: this.fb.array([])
    }, {validator: dateLessThan('startDate', 'endDate')});
    this.invoiceForm.valueChanges.subscribe(({items}) => {
      this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      this.calculateTotal(items);
    });
    this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }

  loadSubscription(): void {
    this.subscription$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe((plan: any) => {
      this.subscription = plan?.planName;
      this.products$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((products: any[]) => {
        if(this.subscription === 'Retail' || this.subscription === 'Retail Plus'){
          products = products?.filter(product => product?.isReviewed)
        }
        this.products = products.filter(product => product?.isSale);
        this.avaialbleProducts = this.products;
      });
    })
  }

  loadNumberConfig(): void {
    this.numberService.number
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = number;
    })
  }

  invoiceDataMapper(): object {
    const body = {
      ...this.invoiceForm.getRawValue(),
      businessDetails: {
        businessId: this.business._id
      },
      customerDetails: {
        customerId: this.invoiceForm.value.customerId
      },
      projectDetails: {
        projectId: this.invoiceForm.value.projectId
      },
      currencyDetails: {
        currencySymbol: this.invoiceData?.currencyDetails?.currencySymbol ?? this.currencySymbol,
        currencyValue: this.invoiceData?.currencyDetails?.currencyValue ?? null,
        convertedAmount: this.invoiceData?.currencyDetails?.convertedAmount ?? null
      },
      companylogo: this.customizationSettings.companyLogo || '',
      totalAmount: this.numberService.toFixed(this.totalAmount),
      averageTotalAmount: this.numberService.toFixed(this.averageTotalAmount),
      dueAmount: this.numberService.toFixed(this.totalAmount),
      tax: this.numberService.toFixed(this.tax),
      subtotal: this.numberService.toFixed(this.subTotal),
      discountValue: this.numberService.toFixed(this.discountValue),
      discount: this.numberService.toFixed(this.invoiceForm.value?.discount),
      qrCode: this.invoiceData.qrCode,
      eSign: this.invoiceData.eSign
    };

    return body;
  }

  createSignature(): void {
    htmlToImage.toPng(document.getElementById(this.eSignSelected))
    .then((dataUrl) => {
      this.updateEsign(dataUrl);
    })
    .catch((error) => {
      console.error(this.translateService.instant('Something went wrong!'));
    });
  }

  updateEsign(base64): void {
    this.invoiceData.eSign = base64;
    this.eSignSettingModal = false;
    this.openCreateEsign = false;
    this.eSignValue = null;
    this.previewSignatureForDoc = true;
  }

  fileEventForQR(event): void {
    if(!this.isFieldEditable)
      return
    this.openModal =  true,
    this.imageDataQR = {
      event,
      openModal: true,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 1 / 1,
        title: 'Add QR Code'
      }
    };
  }

  fileEventForESign(event): void {
    this.openCreateEsign = false;
    this.openModalForEsign = true;
    this.imageCropperConfig = {
      event,
      config: {
        maintainAspectRatio: true,
        roundCropper: false,
        aspectRatio: 16 / 9,
        title: 'Add E-Sign'
      }
    };
  }

  get items(): FormArray {
    return this.invoiceForm?.get('items') as FormArray;
  }

  changeTax(event, i) {
    // console.log(event,i);
    if(this.items.length>0){
      if(event.length > 1){
        event.shift()
        this.items.controls[i].get('tax').setValue(event)
      }
    }
  }

  createItem(): FormGroup {
    const itemForm = this.fb.group({
      item: null,
      itemId: '',
      itemDescription: '',
      sku: '',
      hsn_sac: '',
      price: [null,[Validators.min(0)]],
      unit: [1,[Validators.min(0.01)]],
      tax: null,
      type: '',
      totalCost: [null,[Validators.min(0)]],
      accountDetails: null,
      cogsAccountDetails: null,
      isSale: false,
      inventoryAccountDetails: null,
      averagePrice: 0
    });
    return itemForm;
  }

  addNewItem(): void {
    this.items.push(this.createItem());
    this.invoiceForm.controls.items.valueChanges.subscribe(items=>{
    })
  }

  removeItem(index): void {
    this.items.removeAt(index);
    this.updateAvailableItems()
  }

  updateAvailableItems(){
    const allIds = this.items.value.map(item=>item.itemId)
    this.avaialbleProducts = this.products.filter(el => !allIds.includes(el?._id));
  }
  changeEvent(event, index): void {
    const { _id, name, itemDescription='', sellPrice, itemType, tax, accountDetails, sku, hsn, sac, isSale = false, inventoryAccountDetails = null, cogsAccountDetails = null, averagePrice } = event;
    this.items.controls[index].setValue({itemId: _id, item: name, itemDescription, type:itemType, sku, hsn_sac: hsn || sac, price:sellPrice, tax, isSale, cogsAccountDetails, inventoryAccountDetails, accountDetails, unit: 1, totalCost: sellPrice * 1, averagePrice: this.numberService.toFixed(averagePrice || sellPrice)});
    this.updateAvailableItems()
  }

  calculateTotal(estimateItems): void {
    let subTotal = 0;
    let total = 0;
    let totalTax = 0;
    let discountPercent = 0;
    let averageTotalAmount = 0;
    const discountFormValue = this.invoiceForm.get('discount').value;
    const discountFormField = this.invoiceForm.get('discount');
    if (discountFormField.valid) {
      discountPercent = this.numberService.toFixed(discountFormValue);
    }
    estimateItems.forEach(item => {
      const { price, unit, tax, averagePrice } = item;
      if(price > 0 && unit > 0){
        let itemTotal = price * unit;
        let averageItemTotal = averagePrice * unit;
        subTotal += itemTotal;
        if(discountFormValue > 0){
          const discount = itemTotal * (discountPercent / 100);
          itemTotal -= discount;
          averageItemTotal -= discount;
        }
        total += itemTotal;
        averageTotalAmount += averageItemTotal;
        if (tax) { totalTax += this.calculateItemTax(itemTotal, tax); }
      }

    });
    this.tax = this.numberService.toFixed(totalTax);
    this.subTotal = this.numberService.toFixed(subTotal);
    this.totalAmount = this.numberService.toFixed(total + totalTax);
    this.averageTotalAmount = this.numberService.toFixed(averageTotalAmount)
    this.discount = this.numberService.toFixed(subTotal - total);
    this.discountValue =  this.numberService.toFixed(total);  }

  calculateItemTax(price, taxes): any {
    return [taxes].reduce((a, b) =>  a += (price) * (b.tax / 100), 0);
  }

  calculateDiscountPercent(total, discountValue): number {
    return this.numberService.toFixed((discountValue * 100) / this.numberService.toFixed(total));
  }
  removeQRCode(): void {
    this.invoiceData.qrCode = '';
    this.removeQRCodeModal = false;
  }

  removeEsign(): void {
    this.invoiceData.eSign = '';
  }

  // updateEsign(base64): void {
  //   this.invoiceData.eSign = base64;
  //   this.eSignSettingModal = false;
  // }

  loadEsign(event): void {
    this.openModalForEsign = false;
    this.invoiceData.eSign = event;
  }

  loadQR(event): void {
    this.openModal = false;
    this.invoiceData.qrCode = event;
  }

  createInvoice(): void {
    if (this.invoiceForm.invalid) {
      this.invoiceForm.markAllAsTouched();
      this.formErrors = valueChanges(this.invoiceForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
      return;
    }

    const invoiceData: any = this.invoiceDataMapper();

    if (invoiceData.items.length === 0) {
      this.toaster.error('Please Add atleast One Item.')
      return;
    }

    if (invoiceData.items[0].item === null) {
      return;
    }

    // if(this.subscription === 'Retail' || this.subscription === 'Retail Plus' ){
    //   let stockCheck: Array<StockCheck | null> = [];
    //   invoiceData.items?.forEach(item => {
    //     const product = this.products.find(product => product?._id == item?.itemId);
    //     if(product && product?.stockDetails?.openingStock < item?.quantity){
    //       stockCheck.push({
    //         item: item?.item,
    //         openingStock: product?.stockDetails?.openingStock,
    //         quantityAdded: item?.quantity
    //       })
    //       console.log(stockCheck, this.checkQuantityInStock);
    //     }
    //   })
    //   this.checkQuantityInStock = stockCheck;

    //   if(this.checkQuantityInStock.length){
    //     this.outOfStock = true;
    //     return
    //   }
    // }

    this.spinner.show();
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    this.files.forEach((file, i) => {
      formData.append(`file${i}`, file);
    });
    formData.append('payload', JSON.stringify(invoiceData));
    this.invoiceService.createInvoice(formData).subscribe(resp => {
      if(resp?.success){
        this.toaster.success(resp?.message || 'Invoice created successfully');
        this.router.navigate(['/sales/recurring-invoices']);
      }
      else{
        this.toaster.error(resp?.message);
      }
      this.spinner.hide();
    }, error => {
      this.toaster.error(error?.error?.message)
      this.spinner.hide();
    });
  }

  showCustomPaymentTermsModal(select: NgSelectComponent): void {
    this.showCustomPaymentTerms = true;
    select.close();
  }

  createPaymentTerms(): void {
    this.spinner.show();
    console.log(this.paymentTermForm.value);
    this.invoiceService.createPaymentTerm(this.paymentTermForm.value, this.business?._id)
    .subscribe(resp => {
      if(resp?.success){
        this.paymentTerms = resp?.data;
        this.showCustomPaymentTerms = false;
        this.paymentTermForm.reset()
      }
      else {
        this.toaster.error(resp?.message);
      }
      this.spinner.hide()
    }, (err) => {
      this.toaster.error(err?.error?.message);
      this.spinner.hide();
    })
    
  }

  saveFiles(files: File[]): void {
    this.files = files;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  openEsignModal(): void{
    if(!this.isFieldEditable)
      return;
    this.openCreateEsign = true;
    this.previewSignatureForDoc = true;
  }
}
