import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AssetsComponent } from './components/assets/assets.component';
import { ProductsAndServicesComponent } from './components/products-and-services/products-and-services.component';
import { PurchaseContainerComponent } from './purchase-container.component';

const routes: Routes = [
    {
        path: '',
        component: PurchaseContainerComponent,
        children: [
            {
                path: 'bills',
                loadChildren: () => import('./components/bills/bills.module').then((m) => m.BillsModule)
            },
            {
                path: 'products-and-services',
                component: ProductsAndServicesComponent
            },
            {
                path: 'assets',
                component: AssetsComponent
            },
            {
                path: 'purchase-order',
                loadChildren: () => import('./components/purchase-order/purchase-order.module').then((m) => m.PurchaseOrderModule)
            },
            {
                path: 'vendors',
                loadChildren: () => import('./components/vendors/vendors.module').then((m) => m.VendorsModule)
            },
            {
                path: 'recurring-bill',
                loadChildren: () => import('./components/recurring-bill/recurring-bill.module').then(m => m.RecurringBillModule)
            },
            {
                path: 'expenses',
                loadChildren: () => import('./components/expenses/expenses.module').then(m => m.ExpensesModule)
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class PurchaseRoutingModule {}
