import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { UserService } from 'src/app/shared/services/user.service';
import { updateCurrentAdminLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectUserEmails } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-emails',
  templateUrl: './emails.component.html',
})
export class EmailsComponent implements OnInit {
  constructor(
    private store: Store<RootReducerState>,
    private userService: UserService,
    private spinner: NgxSpinnerService
  ) {
    this.emails$ = this.store.pipe(select(selectUserEmails));
    this.usage$ = store.pipe(select(selectUsage));
  }

  emails$: Observable<any>;
  usage$: Observable<any>;
  adminLimit;
  addNewEmail = false;
  newEmail: FormControl = new FormControl('', [
    Validators.required,
    Validators.pattern(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
  ]);
  error = '';
  response = '';
  emailToDelete = null;
  deleteEmailModal = false;

  ngOnInit(): void {
    this.getAdminLimit();
    this.newEmail.valueChanges.subscribe(() => {
      if (this.newEmail?.errors?.pattern) {
        this.error = 'Email is Invalid';
      } else if (this.newEmail?.errors?.required) {
        this.error = 'Email is Required';
      } else this.error = '';
    });
  }

  getAdminLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const { adminLimit } = currentUsage;
        this.adminLimit = adminLimit;
      }
    });
  }

  trackByFn(index: number): number {
    return index;
  }

  verifyEmail(): void {
    if (this.newEmail.invalid) {
      this.newEmail.markAsDirty();
      return;
    }
    this.spinner.show();
    this.userService.addNewEmail(this.newEmail.value).subscribe(
      (resp) => {
        this.spinner.hide();
        this.newEmail.reset();
        this.addNewEmail = false;
        if (resp?.success) {
          this.store.dispatch(
            updateCurrentAdminLimit({ adminLimit: this.adminLimit + 1 })
          );
        }
        this.response = resp?.message;
        setTimeout(() => {
          this.response = '';
        }, 2000);
        this.error = null;
      },
      (error) => {
        this.error = error?.error?.message || 'Internal Server Error';
        this.response = null;
        setTimeout(() => {
          this.error = '';
        }, 2000);
        this.spinner.hide();
      }
    );
  }

  confirmEmailDelete(email): void {
    this.emailToDelete = email;
    this.deleteEmailModal = true;
  }

  deleteEmail(): void {
    this.spinner.show();
    this.userService.deleteEmail({ email: this.emailToDelete }).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          this.deleteEmailModal = false;
          this.userService.deleteEmailInStore(this.emailToDelete);
          this.store.dispatch(
            updateCurrentAdminLimit({ adminLimit: this.adminLimit - 1 })
          );
        }
      },
      (error) => {
        this.spinner.hide();
        console.log(error);
      }
    );
  }
}
