import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe that formats a number as currency with a space after the currency symbol.
 *
 * @example
 * {{ 1234.56 | currencywithspace:'USD':'symbol':'1.0-2' }}
 * Output: "$ 1,234.56"
 *
 * @param value - The numeric value to be formatted.
 * @param currencyCode - The ISO 4217 currency code (e.g., 'USD', 'EUR').
 * @param symbolDisplay - How to display the currency (e.g., 'symbol', 'narrowSymbol', 'code', 'name').
 * @param digitsInfo - String representing the format of the decimal places (e.g., '1.2-2').
 *                     This defines the minimum and maximum number of decimal places.
 *                     Defaults to '1.2-2'.
 *
 * @returns A string formatted as currency with a space after the currency symbol.
 */
@Pipe({
  name: 'currencywithspace',
})
export class CurrencywithspacePipe implements PipeTransform {
  /**
   * Transforms a numeric value into a formatted currency string with a space after the currency symbol.
   *
   * @param value - The number to format. If null or undefined, returns an empty string.
   * @param currencyCode - The currency code (e.g., 'USD').
   * @param symbolDisplay - The type of currency display (e.g., 'symbol', 'narrowSymbol').
   * @param digitsInfo - The format for decimal digits (e.g., '1.2-2').
   *
   * @returns A formatted currency string with a space after the currency symbol.
   */
  transform(
    value: number | null | undefined,
    currencyCode: string,
    symbolDisplay: 'symbol' | 'narrowSymbol' | 'code' | 'name' = 'symbol',
    digitsInfo: string = '1.2-2'
  ): string {
    if (value == null) return ''; // Return an empty string for null or undefined values
  
    const [minDigits, maxDigits] = this.parseDigitsInfo(digitsInfo);
  
    const formattedValue = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: currencyCode,
      currencyDisplay: symbolDisplay,
      minimumFractionDigits: 2, // Force 2 decimal places
      maximumFractionDigits: 2, // Force 2 decimal places
    }).format(value);
  
    console.log("formattedValue", formattedValue);
  
    return this.addSpaceAfterCurrencySymbol(formattedValue);
  }
  
  /**
   * Parses the digits info string and returns the minimum and maximum fraction digits.
   *
   * @param digitsInfo - The digits info string in the format 'minDigits-maxDigits'.
   *
   * @returns A tuple [minDigits, maxDigits] representing the minimum and maximum fraction digits.
   */
  private parseDigitsInfo(digitsInfo: string): [number, number] {
    // Ensure that at least 2 fractional digits are parsed for both min and max
    const [min, max] = digitsInfo
      .split('-')
      .map((digit, index) => parseInt(digit) || (index === 0 ? 1 : 2));
    return [min, max];
  }
  
  /**
   * Adds a single space after the currency symbol in the formatted value.
   *
   * @param value - The formatted currency string.
   *
   * @returns The formatted currency string with a space after the currency symbol.
   */
  private addSpaceAfterCurrencySymbol(value: string): string {
    return value
      .replace(/(\D+)(\d+)/, '$1 $2')
      .replace(/\s+/g, ' ')
      .trim();
  }
}
