<div class="p-8 rounded-3xl bg-light-gray-1">
    <div class="flex items-center justify-between">
        <h3 class="Poppins-Bold text-lg">{{'Businesses' | translate }}</h3>
        <app-button *ngIf ="businessCount == 0"  appSubscriptionCheck featureToCheck='businessLimit' type='primary' buttonText='{{"Create Business" | translate }}' [rounded]='true' (handleClick)='route()'></app-button>
    </div>
    <table class="mt-5 min-w-full divide-y divide-gray-300">
        <thead>
            <tr class="h-12 text-start">
                <th class="Poppins-Bold">{{'Business Name' | translate }}</th>
                <th class="Poppins-Bold">{{'Industry' | translate }}</th>
                <th class="Poppins-Bold">{{'Country' | translate }}</th>
                <th class="Poppins-Bold">{{'Currency' | translate }}</th>
                <th class="Poppins-Bold">{{'Organization' | translate }}</th>
                <th></th>
            </tr>
        </thead>
        <tbody *ngIf="businesses$ | async as businesses" class="divide-y divide-gray-300">
            <tr *ngFor="let business of businesses" class="h-12">
                <td>{{business.businessId?.companyName}}</td>
                <td>{{business.businessId?.businessType}}</td>
                <td>{{business.businessId?.country}}</td>
                <td>{{business.businessId?.currency}}</td>
                <td>{{business.businessId?.organization}}</td>
                <td>
                    <div class="flex justify-center">
                        <button *ngIf='userId === business.businessId?.userId' (click)='editBusiness(business._id)'>
                            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mx-auto" viewBox="0 0 20 20" fill="currentColor">
                                <path d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z" />
                            </svg>
                        </button>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>

<div class="p-8 mt-4 rounded-3xl bg-light-gray-1">
    <div>
        <h3 class="Poppins-Bold text-lg">{{'Close Your Account' | translate }}</h3>
        <p class="mt-2">{{'Close account message' | translate }}</p>
    </div>
    <div class="mt-4"><app-button buttonText='{{"Close Account" | translate }}' [rounded]='true' size='large' type='danger' (handleClick)="openDeleteAccountModal = true"></app-button></div>
</div>


<app-modal-container [isOpen]='openDeleteAccountModal'>
    <div header class="border-b pb-2 Poppins-Bold">{{'Close my Account' | translate }} </div>
    <div content>
        <p>Are you sure? You want to delete your account permanently, this action is irreversible.</p>
        <p class="text-gray-600">Note - Your subscription will be cancelled immediately.</p>
    </div>
    <div footer class="flex items-center gap-x-2">
        <app-button buttonText='{{"Delete Account" | translate}}' type='danger' rounded=true (handleClick)='deleteAccount()'></app-button>
        <app-button buttonText='{{"Cancel" | translate}}' type='third' rounded=true (handleClick)='openDeleteAccountModal = false'></app-button>
    </div>
</app-modal-container>