import { getCurrencySymbol } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { forkJoin, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { LanguageService } from 'src/app/shared/services/language.service';
import { NumberService } from 'src/app/shared/services/number.service';
import { UserService } from 'src/app/shared/services/user.service';
import { CustomerStatementService } from '../../sales/components/customer-statement/customer-statement.service';
import { SalesService } from '../../sales/sales.service';

@Component({
  selector: 'app-customer-statement',
  templateUrl: './customer-statement.component.html',
  styleUrls: ['./customer-statement.component.scss']
})
export class CustomerStatementComponent implements OnInit {

  constructor(private route: ActivatedRoute,
              private router: Router,
              private spinner: NgxSpinnerService,
              private businessService: BusinessService,
              private customerStatementService: CustomerStatementService,
              private userService: UserService,
              private languageService: LanguageService,
              private numberService: NumberService,
              private toastr: ToastrService,
              private salesService: SalesService) { }

  showStatement = false;

  config = {
    businessInfo: {
      businessName: '',
      businessAddress: {
        addressLineFirst: null,
        addressLineSecond: null,
        city: null,
        state: null,
        country: null
      },
      currency: '',
      businessLogo: ''
    },
    currencySymbol: '',
    customerInfo: {
      customerName: '',
      customerFirstName: '',
      customerLastName: ''
    },
    userId: {
      firstName: '',
      lastName: ''
    },
    tableHeadings: [],
    tableData: [],
    tableKeys: [],
    quickStats: [],
    fromDate: null,
    endDate: null,
    type: ''
  }

  fieldNames = {
    type:"Invoice",
    estimateNumber:"Estimate Number",
    date:"Estimate Date",
    expiryDate:"Expiry Date",
    purchaseOrder:"PO Number/SO Number",
    billingAddress:"Billing Address",
    shippingAddress:"Shipping Address",
    total:"Total",
    receiptAmount:"Receipt Amount",
    subtotal:"Subtotal",
    tax:"Tax",
    terms:"Memo",
    invoiceNumber:"Invoice Number",
    invoiceDate:"Invoice Date",
    dueDate:"Due Date",
    status:"Status",
    paid:"Paid",
    due: "Due",
    tableDate: 'Date',
    amount: 'Amount',
    item: 'Item',
    balance: 'Balance',
    billTo: "Bill To",
    eSign:"Digitally signed document",
    discount:"Discount",
    discountSubtotal: "Discount Subtotal",
    issuer: 'Issued By'
  }

  decimalSize = 2;

  ngOnInit(): void {
    this.route.params.subscribe(({type, businessId, customerId, fromDate, endDate, userId}) => {
      if (businessId && customerId && type && fromDate && endDate && userId) {
        const config = { type, businessId, customerId, fromDate, endDate, userId }
        this.loadData(config);
      } else {
        this.router.navigate(['/']);
      }
    });
  }

  loadData({businessId, customerId, type, fromDate, endDate, userId}): void {
    const apiList = [
      this.businessService.getBusinessDetailsById(businessId).pipe(catchError((error) => of(null))),
      this.customerStatementService.getInvoiceCustomizationSettings(businessId).pipe(catchError((error) => of(null))),
      this.userService.getBasicUserDetails(userId).pipe(catchError((error) => of(null)))
    ]
    if (type === 'o') {
      apiList.push(this.customerStatementService.loadCustomer(customerId).pipe(catchError((error) => of(null))));
    }
    if (type === 'a') {
      const data = {
        fromDate: fromDate,
        endDate: endDate,
        _id: customerId,
        businessId: businessId
      }
      apiList.push(this.customerStatementService.loadCustomerActivity(data).pipe(catchError((error) => of(null))));
    }
    this.spinner.show();
    forkJoin(apiList).subscribe((results) => {
      this.spinner.hide();
      console.log(results);
      if (results[0]?.success && results[1].success && results[2].success && results[3].success) {
        const businessData = results[0].data;
        const decimalSize = businessData?.decimalSize ?? 2;
        this.decimalSize = decimalSize;
        this.numberService.decimalSize.next(decimalSize);
        this.numberService.number.next(`1.${decimalSize}-${decimalSize}`)
        const customizationSettings = results[1].data;
        const userDetails = results[2]?.data;
        const customerData = results[3].data;
        const config = {
          businessInfo: {
            businessName: businessData.companyName,
            businessAddress: {
              addressLineFirst: businessData?.address?.addressLineFirst,
              addressLineSecond: businessData?.address?.addressLine2,
              city: businessData?.address?.city,
              state: businessData?.address?.state,
              country: businessData?.address?.country
            },
            currency: businessData?.currency,
            businessLogo: customizationSettings?.companyLogo
          },
          currencySymbol: getCurrencySymbol(businessData?.currency, 'narrow'),
          customerInfo: {
            customerName: customerData?.customer?.customerName,
            customerFirstName: customerData?.customer?.firstName ?? null,
            customerLastName: customerData?.customer?.lastName ?? null
          },
          userId: {
            firstName: userDetails?.firstName,
            lastName: userDetails?.lastName
          },
          fromDate,
          endDate: endDate,
          openingBalnce: results[3].data.openingBalnce ?? null
        }

        if (type == 'o') {
          delete config.fromDate;
          delete config.endDate;
          this.config = this.customerStatementService.generateOutstandingData(config, customerData.invoices);
        }

        if (type == 'a') {
          this.config = this.customerStatementService.generateActivityData(config, customerData.invoices)
        }
        
        

        this.showStatement = true;
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error('Invalid Link');
    })
  }

  loadCustomer(): void {

  }

  downloadPdf(): void {
    const direction = localStorage.getItem('NuMetric|lang') === 'en'? 'ltr' : 'rtl'
    const body = {
      config: {
        data: {...this.config, currencyDetails: { currencySymbol: getCurrencySymbol(this.config.businessInfo.currency, 'narrow') }},
        direction: direction,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames)
      }
    }
    this.spinner.show();
    this.customerStatementService.createCustomerStatementPdf(body).subscribe((resp) => {
      this.spinner.hide();
      const a = document.createElement('a');
      const blob = new Blob([resp], {type: 'application/pdf'});
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `Customer Statement.pdf`;
      a.click();
      window.URL.revokeObjectURL(url);
    }, (error) => {
      this.spinner.hide();
    })
  }

}
