import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor',
})
export class FloorPipe implements PipeTransform {
  /**
   * Transforms the input number by applying Math.floor to it.
   * @param value The number to transform.
   * @returns The floored number or an error if the input is invalid.
   */
  transform(value: number | string): number {
    // Check if the input is a number or a numeric string
    if (typeof value === 'number') {
      return Math.floor(value);
    } else if (typeof value === 'string' && !isNaN(Number(value))) {
      return Math.floor(Number(value));
    } else {
      throw new Error('Invalid input: value must be a number or a string representation of a number.');
    }
  }
}
