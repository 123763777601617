<div class="new-design-round-border p-4">
  <form [formGroup]="changePasswordForm" class="">
    <div class="flex flex-col">
      <div class="flex flex-col items-start content-start">
        <div class="flex justify-between items-start gap-1 w-full">
          <div class="text-sm self-stretch">
            {{ "Password" | translate }}
          </div>
          <div
            class="text-xxs text-[#1C1C1C66] cursor-pointer"
            routerLink="/auth/forgot-password"
          >
            {{ "Forgot Password?" | translate }}
          </div>
        </div>
        <div class="text-xxs text-[#1C1C1C66]">
          {{ "Set a password to login to your account." | translate }}
        </div>
        <div class="grid grid-cols-2 gap-4 mt-4 w-full">
          <div class="grid grid-cols-1 gap-2 items-center">
            <label for="currentPassword" class="text-sm text-[#1C1C1C66]">{{
              "Current Password" | translate
            }}</label>
            <label for="newPassword" class="text-sm text-[#1C1C1C66]">{{
              "New Password" | translate
            }}</label>
            <label for="confirmPassword" class="text-sm text-[#1C1C1C66]">{{
              "Confirm Password" | translate
            }}</label>
          </div>
          <div class="grid grid-cols-1 gap-2">
            <div class="flex flex-col w-full justify-start gap-1">
              <input
                id="currentPassword"
                formControlName="currentPassword"
                type="text"
                class="max-w-[340px] new-input"
                placeholder="{{ 'Enter current password' | translate }}"
              />
              <div
                class="text-red-500 ms-2 text-sm"
                *ngIf="formErrors.currentPassword"
              >
                {{ formErrors.currentPassword }}
              </div>
            </div>
            <div class="flex flex-col w-full justify-start gap-1">
              <input
                id="newPassword"
                formControlName="newPassword"
                type="text"
                class="max-w-[340px] new-input"
                placeholder="{{ 'Enter new password' | translate }}"
              />
              <div
                class="text-red-500 ms-2 text-sm"
                *ngIf="formErrors.newPassword"
              >
                {{ formErrors.newPassword }}
              </div>
            </div>
            <div class="flex flex-col w-full justify-start gap-1">
              <input
                id="confirmPassword"
                formControlName="confirmPassword"
                type="text"
                class="max-w-[340px] new-input"
                placeholder="{{ 'Confirm your password' | translate }}"
              />
              <div
                class="text-red-500 ms-2 text-sm"
                *ngIf="formErrors.confirmPassword"
              >
                {{ formErrors.confirmPassword }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex gap-2 mt-4">
      <app-submit
        [value]="'Change Password'"
        className="bg-[#026AA2] text-white"
        (clicked)="changePassword()"
      ></app-submit>
      <div class="text-green-500" *ngIf="respMessage">
        {{ respMessage }}
      </div>
      <div class="text-red-500" *ngIf="error">{{ error }}</div>
    </div>
  </form>
</div>
