import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromUser from './user.reducer';
import * as fromBusiness from './business.reducer';
import * as fromSubscription from './subscription.reducer';
import * as fromSales from '../../modules/sales/state/reducers';
import * as fromAccounts from './accounts.reducer';
import * as fromProjects from './projects.reducer';
import * as fromUsage from './usage.reducer';
import * as fromStore from "./store.reducer";


export interface RootReducerState {
  user: fromUser.UserState;
  business: fromBusiness.BusinessState;
  subscription: fromSubscription.SubscriptionState;
  accounts: fromAccounts.AccountsState;
  projects: fromProjects.ProjectState;
  usage: fromUsage.UsageState;
  store: fromStore.StoreState;
}

export const rootReducer: ActionReducerMap<RootReducerState> = {
  user: fromUser.reducer,
  business: fromBusiness.reducer,
  subscription: fromSubscription.reducer,
  accounts: fromAccounts.reducer,
  projects: fromProjects.reducer,
  usage: fromUsage.reducer,
  store: fromStore.reducer
};

export const metaReducers: MetaReducer<RootReducerState>[] = !environment.production ? [] : [];
