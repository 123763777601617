import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxStripeModule } from 'ngx-stripe';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { environment } from 'src/environments/environment';
import { AccountingAndTaxesComponent } from './accounting-and-tax/accounting-and-taxes.component';
import { CreateTaxComponent } from './accounting-and-tax/components/create-tax/create-tax.component';
import { DateAndCurrencyComponent } from './accounting-and-tax/components/date-and-currency/date-and-currency.component';
import { AccountingService } from './accounting-and-tax/services/accounting.service';
import { BusinessAndStoresComponent } from './business-and-stores/business-and-stores.component';
import { BussinessListComponent } from './business-and-stores/components/business-list/bussiness-list.component';
import { BusinessUpdateComponent } from './business-and-stores/components/business-update/business-update.component';
import { StoreEditCreateComponent } from './business-and-stores/components/store-create-edit/store-edit-create.component';
import { StoreListComponent } from './business-and-stores/components/store-list/store-list.component';
import { MarketPlaceComponent } from './marketplace/marketplace.component';
import { OnlinePaymentComponent } from './online-payment/online-payment.component';
import { PayButtonComponent } from './pay-button/pay-button.component';
import { DeleteAccountComponent } from './profile-management/components/delete-account/delete-account.component';
import { EmailConfigComponent } from './profile-management/components/email-config/email-config.component';
import { EmailsComponent } from './profile-management/components/emails/emails.component';
import { LanguageConfigComponent } from './profile-management/components/language-config/language-config.component';
import { PasswordSettingsComponent } from './profile-management/components/password-settings/password-settings.component';
import { TwoFactorAuthenticationComponent } from './profile-management/components/two-factor-auth/two-factor-auth.component';
import { ProfileManagementComponent } from './profile-management/profile-management.component';
import { ProgressBarComponent } from './progress-bar/progress-bar.component';
import { DiscountCodeCreateEditComponent } from './sales-settings/components/discount-code-create-edit/dc-create-edit.component';
import { DiscountCodeViewComponent } from './sales-settings/components/discount-code-view/discount-code-view.component';
import { EstimateSettingsComponent } from './sales-settings/components/estimate-settings/estimate-settings.component';
import { InvoiceColumnSettingsComponent } from './sales-settings/components/invoice-column-settings/invoice-column-settings.component';
import { InvoiceCustomization } from './sales-settings/components/invoice-customization/invoice-customization.component';
import { InvoiceSettingsComponent } from './sales-settings/components/invoice-settings/invoice-settings.component';
import { POSAndCashInvoiceSettings } from './sales-settings/components/pos-cash-invoice/pos-cash-invoice-settings.component';
import { POSDiscountManagement } from './sales-settings/components/pos-discount-management/pos-discount-management.component';
import { TemplateListComponent } from './sales-settings/components/template-list/template-list.component';
import { SalesSettingsComponent } from './sales-settings/sales-settings.component';
import { InvoiceCustomizationService } from './sales-settings/services/invoice-customization.service';
import { SubscriptionsComponent } from './subscriptions/subscriptions.component';
import { InviteUserComponent } from './user-management/components/invite-user/invite-user.component';
import { UsersListComponent } from './user-management/components/users-list/users-list.component';
import { UserManagementComponent } from './user-management/user-management.component';
import { UserManagementService } from './user-management/user-management.service';
@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    NgxSpinnerModule,
    NgSelectModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    DirectivesModule,
    PipesModule,
    FormsModule,
    SharedModule,
    ClickOutsideModule,
    NgxStripeModule.forChild(environment.stripePublishKey),
  ],
  exports: [
    ProfileManagementComponent,
    PasswordSettingsComponent,
    TwoFactorAuthenticationComponent,
    EmailsComponent,
    EmailConfigComponent,
    DeleteAccountComponent,
    ProgressBarComponent,
    SubscriptionsComponent,
    PayButtonComponent,
    TemplateListComponent,
    MarketPlaceComponent,
    InvoiceCustomization,
    SalesSettingsComponent,
    EstimateSettingsComponent,
    InvoiceColumnSettingsComponent,
    POSAndCashInvoiceSettings,
    POSDiscountManagement,
    DiscountCodeCreateEditComponent,
    DiscountCodeViewComponent,
    AccountingAndTaxesComponent,
    BusinessAndStoresComponent,
    UserManagementComponent,
    OnlinePaymentComponent,
  ],
  declarations: [
    ProfileManagementComponent,
    PasswordSettingsComponent,
    TwoFactorAuthenticationComponent,
    EmailsComponent,
    EmailConfigComponent,
    DeleteAccountComponent,
    SubscriptionsComponent,
    ProgressBarComponent,
    PayButtonComponent,
    TemplateListComponent,
    MarketPlaceComponent,
    InvoiceCustomization,
    InvoiceSettingsComponent,
    SalesSettingsComponent,
    EstimateSettingsComponent,
    InvoiceColumnSettingsComponent,
    POSAndCashInvoiceSettings,
    POSDiscountManagement,
    DiscountCodeCreateEditComponent,
    DiscountCodeViewComponent,
    AccountingAndTaxesComponent,
    CreateTaxComponent,
    DateAndCurrencyComponent,
    BussinessListComponent,
    BusinessAndStoresComponent,
    StoreListComponent,
    StoreEditCreateComponent,
    BusinessUpdateComponent,
    LanguageConfigComponent,
    UserManagementComponent,
    InviteUserComponent,
    UsersListComponent,
    OnlinePaymentComponent,
  ],

  providers: [
    InvoiceCustomizationService,
    AccountingService,
    UserManagementService,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class SettingsModalModule {}
