import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { BusinessService } from 'src/app/shared/services/business.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { ProfileManagementService } from '../../../services/profile-management.service';

@Component({
  selector: 'app-email-config',
  templateUrl: './email-config.component.html',
})
export class EmailConfigComponent implements OnInit {
  formErrors = {
    email: '',
  };

  formErrorMessages = {
    email: {
      required: 'Email is required',
    },
  };

  business$: Observable<any>;
  businessId;
  addNewEmail = false;
  emailToDelete = null;
  deleteEmailModal = false;
  instructionModal = false;
  configMail = {
    email: '',
    isVerified: false,
  };

  businessDetails;
  mailConfigForm: FormGroup;
  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private toastr: ToastrService,
    private profileService: ProfileManagementService,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>
  ) {
    this.business$ = store.pipe(select(selectBusiness));
  }

  ngOnInit(): void {
    this.createForm();
    this.getConfigMail();
  }

  getConfigMail(): void {
    this.business$.subscribe((business) => {
      console.log(business);

      this.businessId = business?.businessId?._id;
      this.businessDetails = business;
      const { emailConfig: { email = '', isVerified = false } = {} } =
        business?.businessId;
      this.configMail = { email, isVerified };
      this.mailConfigForm.patchValue({
        email: this.configMail.email,
      });
    });
  }

  createForm() {
    this.mailConfigForm = this.fb.group({
      email: [
        '',
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
    });

    this.formErrors = valueChanges(
      this.mailConfigForm,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }

  addNewMail() {
    if (this.mailConfigForm.invalid) {
      this.mailConfigForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.mailConfigForm,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    this.spinner.show();
    this.businessService
      .addMailingId(this.businessId, this.mailConfigForm.value.email)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp?.success) {
            this.configMail = {
              email: this.mailConfigForm.value.email,
              isVerified: this.mailConfigForm.value.isVerified,
            };
            this.addNewEmail = false;
            const data = {
              businessId: resp.data,
              permission: this.businessDetails.permission,
              _id: this.businessDetails._id,
            };
            this.businessService.setBusiness(data);
            this.toastr.success(
              'Mailing configuration added, please check your mailbox.'
            );
          } else {
            this.toastr.error(resp?.message);
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.error?.message || 'Something went wrong');
        }
      );
  }

  editMail(): void {
    if (this.addNewEmail) {
      this.addNewMail();
      return;
    }
    this.mailConfigForm.patchValue({
      email: this.configMail.email,
    });
    this.addNewEmail = true;
  }

  verifyMailingId(): void {
    this.spinner.show();
    this.businessService
      .checkMailingId(this.businessId, this.configMail.email)
      .subscribe(
        (resp) => {
          if (resp?.success) {
            this.spinner.hide();
            this.configMail.isVerified = true;
            const data = {
              businessId: resp.data,
              permission: this.businessDetails.permission,
              _id: this.businessDetails._id,
            };
            this.businessService.setBusiness(data);
            this.toastr.success(resp?.message);
          } else {
            this.spinner.hide();
            this.toastr.error(resp?.message);
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message);
        }
      );
  }

  disconnectEmailing(): void {
    this.spinner.show();
    this.businessService
      .deleteMailingId(this.businessId, this.configMail.email)
      .subscribe(
        (resp) => {
          this.spinner.hide();
          if (resp.success) {
            this.configMail = null;
            this.addNewEmail = true;
            const data = {
              businessId: resp.data,
              permission: this.businessDetails.permission,
              _id: this.businessDetails._id,
            };
            this.businessService.setBusiness(data);
            this.toastr.success('Disconnect successfully');
          }
        },
        (error) => {
          this.toastr.error(error?.error?.message || 'Internal server error');
          this.spinner.hide();
        }
      );
  }
}
