import { Action, createReducer, on } from '@ngrx/store';
import { UserLogout } from 'src/app/store/actions/user.actions';
import * as ProductActions from '../actions/products.action';

export interface ProductsState {
    products: Array<any>;
}

export const initialState: ProductsState = {
    products: []
};

export const ProductsReducer = createReducer(initialState,
    on(ProductActions.SetProducts, (state: ProductsState, {products}) => ({...state, products})),
    on(ProductActions.AddProdcut, (state: ProductsState, {product}) => ({...state, products: [...state.products, {...product}]})),
    on(ProductActions.DeleteProduct, (state: ProductsState, {_id}) => {
        const filterProducts = state.products.filter((product) => product._id !== _id);
        return {...state, products: [...filterProducts]};
    }),
    on(ProductActions.UpdateProduct, (state: ProductsState, {product}) => {
        const mappedProducts = state.products.map((ogProduct) => {
            if (ogProduct._id === product._id) {
                return {...product};
            }
            return ogProduct;
        });
        return {...state, products: [...mappedProducts]};
    }),
    on(UserLogout, () => ({...initialState}))
);

export function reducer(state: ProductsState | undefined, action: Action): any {
    return ProductsReducer(state, action);
}

export const getProducts = (state: ProductsState) => state.products;
