import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { CreateInvoiceComponent } from './create-invoice/create-invoice.component';
import { ListInvoicesComponent } from './list-invoices/list-invoices.component';
import { RecurringInvoicesRoutingModule } from './recurring-invoices-routing.module';
import { RecurringInvoicesComponent } from './recurring-invoices.component';
import { ViewInvoiceTemplateComponent } from './view-invoice-template/view-invoice-template.component';

@NgModule({
  declarations: [
    ViewInvoiceTemplateComponent,
    ListInvoicesComponent,
    CreateInvoiceComponent,
    RecurringInvoicesComponent,
  ],
  imports: [
    CommonModule,
    RecurringInvoicesRoutingModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DirectivesModule,
    ClickOutsideModule,
  ],
})
export class RecurringInvoicesModule {}
