<form [formGroup]="invoiceCustomizationForm">
  <div class="flex text-[#1C1C1C] flex-col pb-4">
    <div class="flex justify-between w-full gap-28">
      <div class="bg-input-light rounded-lg p-4 flex gap-5 items-center h-auto">
        <label for="file">
          <div
            class="h-16 w-16 flex justify-center items-center rounded-[80px] bg-[#1C1C1C0D] shadow-btn"
          >
            <img src="assets/images/upload.svg" alt="" class="" />
            <input
              type="file"
              id="file"
              accept="image/png, image/jpeg, image/jpg"
              (change)="fileEvent($event)"
              hidden
            />
          </div>
        </label>
        <div class="flex flex-col">
          <div class="text-[#1C1C1CCC]">
            {{ "Company Logo" | translate }}
          </div>
          <div class="flex flex-col text-[13px] text-[#1C1C1C66]">
            <span>{{ "Recommended PNG" | translate }}</span>
            <span>{{ "16:9 | Min 950 x 534 px" }}</span>
          </div>
        </div>
        <div class="w-36 flex flex-col items-end justify-end gap-1">
          <img [src]="companyLogo" alt="" />
          <span
            class="text-[13px] text-[#1C1C1C66] underline cursor-pointer"
            *ngIf="companyLogo && companyLogo !== 'assets/images/business.jpg'"
            (click)="openDeleteModal = true"
          >
            {{ "Remove" | translate }}
          </span>
        </div>
      </div>
      <div class="flex flex-col gap-3 pr-16">
        <app-submit
          className="bg-[#026AA2] text-white w-84"
          value="{{ 'Save All Changes' | translate }}"
          (clicked)="saveChanges()"
        >
        </app-submit>
        <app-submit
          className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] w84"
          value="{{ 'Discard' | translate }}"
          (clicked)="discard()"
        >
        </app-submit>
        <span *ngIf="response" class="text-green-500 text-sm">{{
          response
        }}</span>
        <span *ngIf="error" class="text-red-500 text-sm">{{ error }}</span>
      </div>
    </div>
  </div>
  <app-template-list (selectedTemplate)="templateChange($event)">
  </app-template-list>
  <div class="flex text-[#1C1C1C] gap-4 mt-4 w-full">
    <div class="flex flex-col gap-4 w-[55%]">
      <app-invoice-settings
        [formGroup]="invoiceCustomizationForm.get('invoiceSettings')"
      ></app-invoice-settings>
      <app-estimate-settings
        [formGroup]="invoiceCustomizationForm.get('estimateSettings')"
      ></app-estimate-settings>
    </div>
    <div class="flex text-[#1C1C1C] flex-col gap-4 w-[45%]">
      <!-- PAYMENT REMINDER -->

      <div class="new-design-round-border text-sm p-4 flex flex-col gap-3">
        <div class="flex gap-4 items-center">
          <div class="text-sm">
            {{ "Payment reminder message" | translate }}
          </div>
          <label
            for="togglePR"
            class="flex items-center cursor-pointer relative"
          >
            <input
              type="checkbox"
              formControlName="reminder"
              id="togglePR"
              class="sr-only peer"
            />
            <div
              [ngClass]="{
                'after:bg-white':
                  invoiceCustomizationForm.get('reminder')?.value,
                'after:bg-black bg-[#d9d9d9]':
                  !invoiceCustomizationForm.get('reminder')?.value
              }"
              class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
            ></div>
          </label>
        </div>
        <p class="text-xxs" style="line-height: 16px">
          {{ "Payment reminder message info" | translate }}
        </p>
      </div>
      <!-- AUTOMATIC SMSes -->

      <div class="new-design-round-border text-sm p-4 flex flex-col">
        <div class="flex gap-4 items-center">
          <div class="text-sm">
            {{ "auto send SMS message" | translate }}
          </div>
          <label for="toggle" class="flex items-center cursor-pointer relative">
            <input
              type="checkbox"
              formControlName="sendSMS"
              id="toggle"
              class="sr-only peer"
            />
            <div
              [ngClass]="{
                'after:bg-white':
                  invoiceCustomizationForm.get('sendSMS')?.value,
                'after:bg-black bg-[#d9d9d9]':
                  !invoiceCustomizationForm.get('sendSMS')?.value
              }"
              class="toggle-bg peer-checked:bg-black h-6 w-11 rounded-full"
            ></div>
          </label>
        </div>
      </div>

      <!-- INVOICE COLUMN SETTINGS -->
      <app-invoice-column-settings
        [columnSettingsForm]="invoiceCustomizationForm"
      >
      </app-invoice-column-settings>
    </div>
  </div>
</form>
<app-image-cropper
  [isOpen]="openModal"
  [imageCropperConfig]="imageCropperConfig"
  (closeImage)="openModal = false"
  (base64Image)="saveImage($event)"
  >>
</app-image-cropper>

<app-modal-container [isOpen]="openDeleteModal">
  <div header class="border-b pb-2 Poppins-Bold">
    {{ "Remove Profile Image" | translate }}
  </div>
  <div content>{{ "Are you sure?" | translate }}</div>
  <div footer class="flex items-center gap-3">
    <app-submit
      className="bg-[#026AA2] text-white h-10"
      value="Delete"
      (clicked)="removeLogo()"
    >
    </app-submit>
    <app-submit
      className="bg-[#F6F6F6CC] shadow-btn text-[#1C1C1C66] h-10"
      value="Cancel"
      (clicked)="openDeleteModal = false"
    >
    </app-submit>
  </div>
</app-modal-container>
