import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sales-settings',
  templateUrl: 'sales-settings.component.html',
})
export class SalesSettingsComponent implements OnInit {
  selectedTab = 'invoice';
  constructor() {}

  ngOnInit() {}
  onTabChange(tab = '') {
    this.selectedTab = tab;
  }
}
