import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { PipesModule } from 'src/app/pipes/pipes.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { SettingsModalModule } from '../settings-modal/settings-modal.module';
import { CollapsibleSidebarComponent } from './collapsible-sidebar/collapsible-sidebar.component';
import { CollapsibleSidebarService } from './collapsible-sidebar/collapsible-sidebar.service';

@NgModule({
  imports: [
    PipesModule,
    CommonModule,
    ClickOutsideModule,
    TranslateModule,
    RouterModule,
    SharedModule,
    SettingsModalModule,
    DirectivesModule,
  ],
  exports: [CollapsibleSidebarComponent],
  declarations: [CollapsibleSidebarComponent],
  providers: [CollapsibleSidebarService],
})
export class SideBarModule {}
