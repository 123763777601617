import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'padStart',
})
export class PadStartPipe implements PipeTransform {
  /**
   * Transforms the input number by padding it with leading characters to a specified length.
   * @param value The number to transform.
   * @param padding The total length of the output string. Default is 2.
   * @param padChar The character to pad with. Default is '0'.
   * @returns The padded string or an error message if the input is invalid.
   */
  transform(value: number | string, padding: number = 2, padChar: string = '0'): string {
    // Ensure the padChar is a single character
    if (padChar.length !== 1) {
      throw new Error('padChar must be a single character.');
    }
    
    // Convert value to string and handle non-number inputs
    let valueStr: string;
    if (typeof value === 'number') {
      valueStr = value.toString();
    } else if (typeof value === 'string' && !isNaN(Number(value))) {
      valueStr = value;
    } else {
      throw new Error('Invalid input: value must be a number or a string representation of a number.');
    }
    
    // Pad the string and return
    return valueStr.padStart(padding, padChar);
  }
}
