import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ChartsModule } from 'ng2-charts';
// import DataLabelsPlugin from 'chartjs-plugin-datalabels';

import { NumplusRoutingModule } from './numplus-routing.module';
import { NumplusContainerComponent } from './numplus-container.component';
import { FinancedashboardComponent } from './components/financedashboard/financedashboard.component';
import { SalesdashboardComponent } from './components/salesdashboard/salesdashboard.component';
import { CashflowdashboardComponent } from './components/cashflowdashboard/cashflowdashboard.component';
import { SharedModule } from 'src/app/shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';



@NgModule({
  declarations: [

    NumplusContainerComponent,
    FinancedashboardComponent,
    SalesdashboardComponent,
    CashflowdashboardComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule,
    ReactiveFormsModule,
    NumplusRoutingModule,
    DirectivesModule,
    TranslateModule,
    ChartsModule,
    NgSelectModule
  ]
})
export class NumplusModule { }
