import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, pipe, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { DateValidator, dateLessThan, valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectStore } from 'src/app/store/selectors/store.selector';
import { selectUserSubscription } from 'src/app/store/selectors/subscription.selectors';
import { InventoryService } from '../../inventory.service';

@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit {

  businessId = '';
  filterForm: FormGroup;
  productId = null;
  showDeleteModal: boolean = false;
  filtered: boolean = false;
  currencyDetails = null;
  

  tableHeadings = [
    'Name',
    'SKU',
    'Purchase Price',
    'Selling Price',
    'Quantity',
    'Stock Value',
    'Actions'
  ];

  tableKeys = [
    'name',
    'sku',
    'price',
    'sellPrice',
  ];
  
  constructor(private fb: FormBuilder, private store: Store<RootReducerState>, private spinner: NgxSpinnerService, private toastr: ToastrService, private inventoryService: InventoryService, private router: Router) {
    this.stores$ = store.pipe(select(selectStore));
    this.buisness$ = store.pipe(select(selectBusiness));
    this.subscription$ = store.pipe(select(selectUserSubscription))
  }
  
  inventoryStats = {
    retailValue: 0,
    inventoryValue: 0,
    profit: 0,
    margin: 0
  } 
  stores$: Observable<any>;
  buisness$: Observable<any>;
  subscription$: Observable<any>;
  unsubscribed = new Subject();
  showItemSummaryOption = false;

  stores=[];
  products = [];
  allProducts = [];

  ngOnInit(): void {
    this.loadFilterForm();
    this.loadStores();
    this.loadBusiness();
    this.loadSubscription();
  }

  loadBusiness():void {
    this.spinner.show();
    this.buisness$
    .pipe(takeUntil(this.unsubscribed))
    .subscribe(business => {
      console.log(business,'business');
      
      if(business){
        this.businessId = business?.businessId?._id;
        this.currencyDetails = {
          currencySymbol: business?.businessId?.currencySymbol,
          decimal: business?.businessId?.decimalSize
        }
        this.tableHeadings = [
          'Name',
          'SKU',
          `Purchase Price (${this.currencyDetails?.currencySymbol ?? '-'})`,
          `Selling Price (${this.currencyDetails?.currencySymbol ?? '-'})`,
          'Actions'
        ];
        this.loadProducts();
      }
    },(err)=> {
      this.spinner.hide();
      this.toastr.error('Something went wrong!')
    })
  }

  loadProducts():void {
    console.log(this.businessId,'businessid');
    if(this.businessId){
      this.inventoryService.getProducts(this.businessId)
      .pipe(takeUntil(this.unsubscribed))
      .subscribe(resp => {
        if (resp?.success) {
          this.spinner.hide();
          this.products = resp?.data.filter((item)=>item.productNature !== 'complex');
          this.allProducts = resp?.data.filter((item)=>item.productNature !== 'complex');
        }
        else{
          this.spinner.hide();
          this.toastr.error(resp?.message)
        }
      },(err)=> {
        this.spinner.hide();
        this.toastr.error(err?.message || 'Something went wrong!');
      })
    }
  }

  loadSubscription(): void {
    this.subscription$.pipe(takeUntil(this.unsubscribed))
    .subscribe((sub) => {
      if(sub?.planName === "Retail" || sub?.planName === "Retail Plus") {
        this.showItemSummaryOption = true;
        this.tableHeadings = [
          'Name',
          'SKU',
          `Purchase Price (${this.currencyDetails?.currencySymbol ?? '-'})`,
          `Selling Price (${this.currencyDetails?.currencySymbol ?? '-'})`,
          'Quantity',
          `Stock Value (${this.currencyDetails?.currencySymbol ?? '-'})`,
          'Reviewed',
          'Actions'
        ];
        this.loadStatistics();
      }
    });
  }

  loadStores(): void {
    this.stores$.pipe(takeUntil(this.unsubscribed))
    .subscribe((store) => {
      console.log(store);
      if(store?.length){
        this.stores = store?.map(({storeName, _id}) => ({storeName, _id}));
        this.stores.unshift({storeName:'All', _id:'All'})
      }
      console.log(this.stores,'final');
      
    })
  }

  loadStatistics(): void {
    if(this.businessId){
      this.inventoryService.getStatistics(this.businessId)
      .pipe(takeUntil(this.unsubscribed))
      .subscribe(resp =>{
        if(resp?.success){
          this.inventoryStats = {
            ...resp?.data,
            margin: isNaN(resp?.data?.margin) ? 0 : resp?.data?.margin
          };
          
        }
        else{
          this.inventoryStats = {
            retailValue: 0,
            inventoryValue: 0,
            profit: 0,
            margin: 0
          }
        }
      },(err) => {
        this.toastr.error('Cannot fetch statistics');
      });
    }
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group({
      storeId: []
    });
  }

  filterProduct(){
    this.spinner.show();
    if(this.filtered){
      this.products = this.allProducts;
      this.filterForm.reset();
      this.spinner.hide();
    }
    else{
      const storeId = this.filterForm.controls['storeId'].value;
      if(storeId === 'All'){
        this.products = this.allProducts;
        this.spinner.hide();
        return;
      }
      this.inventoryService.filterProducts(storeId)
      .pipe(takeUntil(this.unsubscribed))
      .subscribe(resp => {
        if(resp?.success){
          this.products = resp?.data;
          this.spinner.hide();
        }
        else{
          this.products = this.allProducts;
          this.spinner.hide();
          this.toastr.error('Cannot filter by this store');
        }
      },(err)=> {
        this.spinner.hide();
        this.products = this.allProducts;
        this.toastr.error('something went wrong')
      })
    }
    // this.filtered = !this.filtered
  }

  editProduct(productId): void {
    this.router.navigate(['inventory/add-item'],{queryParams: {productId}})
  }

  deleteProduct(productId): void {
    this.productId = productId;
    this.showDeleteModal = true;
  }

  confirmDeleteProduct(): void {
    this.spinner.show();
    this.inventoryService.deleteProduct(this.productId)
    .pipe(takeUntil(this.unsubscribed))
    .subscribe(resp => {
      if(resp?.success){
        this.toastr.success(resp?.message || 'Product deleted');
        this.products = this.products.filter(product => product?._id !== this.productId);
        this.showDeleteModal = false
        this.spinner.hide();
      }
      else {
        this.spinner.hide();
        this.showDeleteModal = false;
        this.toastr.error(resp?.message || 'Something went wrong');
      }
    }, (err) => {
      this.spinner.hide();
        this.toastr.error(err?.message || 'Something went wrong');
    })
  }

  showItemSummary(itemId): void {
    this.router.navigate(['/reports/view/item-summary'], {queryParams: {itemId}})
  }

}
