<div class="w-full p-8">
  <div class="bg-white px-14 py-11 rounded-lg flex flex-col space-y-4">
    <form [formGroup]="journalEntryForm">
      <my-tabs className="w-[37%]">
        <div class="flex justify-between mb-4">
          <div class="flex ms-6">
            <div>
              <div class="rounded-xl bg-white px-3 py-2 w-64">
                <fieldset [disabled]="transaction?.type !== 'journal voucher'">
                  <label
                    for="date"
                    class="block text-sm Poppins-Medium bg-transparent font-color-05"
                  >
                    {{ "Date" | translate }}
                  </label>
                  <input
                    id="date"
                    formControlName="date"
                    type="date"
                    class="new-date"
                  />
                </fieldset>
              </div>
              <p *ngIf="formErrors.date" class="text-red-600">
                {{ formErrors.date }}
              </p>
            </div>
            <div class="rounded-xl bg-white px-3 py-2">
              <label
                for="description"
                class="block text-sm Poppins-Medium bg-transparent font-color-05"
                >{{ "Description" | translate }}</label
              >
              <input
                id="description"
                formControlName="description"
                placeholder="{{ 'Enter Description' | translate }}"
                type="text"
                class="new-input"
              />
            </div>
            <div class="rounded-xl bg-white px-3 py-2 w-96">
              <label class="block text-sm Poppins-Medium font-color-05">{{
                "Add a Project" | translate
              }}</label>
              <fieldset>
                <ng-select
                  [items]="projects"
                  class="new-dropdown"
                  bindLabel="projectName"
                  bindValue="_id"
                  formControlName="projectId"
                  placeholder="{{ 'Select Project Name' | translate }}"
                >
                  <ng-template ng-option-tmp let-item="item" let-index="index">
                    <div class="px-2 py-1">
                      <div
                        class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                      >
                        {{ item.projectName }}
                      </div>
                    </div>
                  </ng-template>
                </ng-select>
              </fieldset>
            </div>
          </div>
        </div>
        <my-tab tabTitle="{{ 'Details' | translate }}">
          <div class="ps-5 pe-7">
            <table class="w-full">
              <tr>
                <td class="p-3 bg-input Poppins-Medium rounded-s-xl w-1/12">
                  {{ "Type" | translate }}
                </td>
                <td class="p-3 bg-input Poppins-Medium w-3/12">
                  {{ "Description" | translate }}
                </td>
                <td class="p-3 bg-input Poppins-Medium">
                  {{ "Account" | translate }}
                </td>
                <td class="p-3 bg-input Poppins-Medium w-2/12">
                  {{ "Debit" | translate }}
                </td>
                <td
                  class="p-3 bg-input Poppins-Medium w-2/12 last:rounded-e-xl"
                >
                  {{ "Credit" | translate }}
                </td>
                <td
                  *ngIf="transaction?.type !== 'journal voucher'"
                  class="p-3 bg-input Poppins-Medium w-1/12 last:rounded-e-xl"
                ></td>
              </tr>
              <ng-container formArrayName="journal">
                <ng-container
                  *ngFor="let control of journal.controls; let i = index"
                >
                  <tr [formGroup]="control" class="">
                    <td class="w-2/12 p-2">
                      <ng-select
                        [items]="['Credit', 'Debit']"
                        class="new-dropdown"
                        formControlName="type"
                        placeholder="{{ 'Select one' | translate }}"
                        [searchable]="false"
                        [clearable]="false"
                        (change)="changeType($event, i)"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="w-3/12 p-2">
                      <input
                        formControlName="description"
                        type="text"
                        class="new-input"
                      />
                    </td>
                    <td class="p-2">
                      <ng-select
                        [items]="
                          control.value.type === 'Debit'
                            ? debitAccounts
                            : creditAccounts
                        "
                        class="new-dropdown"
                        bindLabel="accountName"
                        formControlName="account"
                        placeholder="{{ 'Select one' | translate }}"
                        groupBy="accountType"
                      >
                        <ng-template
                          ng-option-tmp
                          let-item="item"
                          let-index="index"
                        >
                          <div class="px-2 py-1">
                            <div
                              class="cursor-pointer p-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                            >
                              {{ item.accountName }}
                            </div>
                          </div>
                        </ng-template>
                      </ng-select>
                    </td>
                    <td class="p-2 w-2/12">
                      <fieldset>
                        <input
                          [ngClass]="
                            control.value.type === 'Credit' ? 'hidden' : 'block'
                          "
                          formControlName="debit"
                          type="number"
                          [class.new-input]="control.value.type === 'Debit'"
                        />
                      </fieldset>
                    </td>
                    <td class="p-2 w-2/12">
                      <fieldset>
                        <input
                          [ngClass]="
                            control.value.type === 'Debit' ? 'hidden' : 'block'
                          "
                          formControlName="credit"
                          type="number"
                          [class.new-input]="control.value.type === 'Credit'"
                        />
                      </fieldset>
                    </td>
                    <td
                      *ngIf="transaction?.type === 'journal voucher'"
                      class="p-2 w-1/12"
                    >
                      <svg
                        (click)="removeLine(i)"
                        xmlns="http://www.w3.org/2000/svg"
                        name="delete"
                        class="h-5 w-5 text-red-500"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"
                        />
                      </svg>
                    </td>
                  </tr>
                </ng-container>
                <ng-container *ngIf="journal.controls.length === 0">
                  <tr>
                    <td colspan="7" class="text-center p-2">
                      {{ "No items added" | translate }}
                    </td>
                  </tr>
                </ng-container>
              </ng-container>
              <tr>
                <td vAlign="top" colspan="3">
                  <div
                    *ngIf="
                      transaction?.type === 'journal voucher' &&
                      journal.controls.length !== 20
                    "
                    class="ps-2"
                  >
                    <app-button (handleClick)="addLine()" type="add-line">
                      <img
                        src="assets/images/delete-icon.svg"
                        style="transform: rotate(45deg)"
                        alt=""
                      />
                      {{ "Add a Line" | translate }}
                    </app-button>
                  </div>
                </td>
                <td colspan="3" class="p-3 bg-input-light rounded-xl">
                  <div class="flex justify-between py-2">
                    <p class="">{{ "Total Debits" | translate }}</p>
                    <p></p>
                    <p class="">{{ "Total Credits" | translate }}</p>
                  </div>
                  <div class="flex justify-between">
                    <p class="text-lg Poppins-Medium">
                      {{
                        debitTotal
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                    <p>{{ creditTotal === debitTotal ? "=" : "≠" }}</p>
                    <p class="text-lg Poppins-Medium">
                      {{
                        creditTotal
                          | currency
                            : currencyDetails?.currency
                            : "symbol-narrow"
                            : number
                      }}
                    </p>
                  </div>
                  <div class="flex justify-center">
                    <p>
                      <span> {{ "Difference" | translate }} </span> =
                      <span
                        [ngClass]="
                          difference === 0 ? 'text-green-600' : 'text-red-600'
                        "
                        >{{
                          difference
                            | currency
                              : currencyDetails?.currency
                              : "symbol-narrow"
                              : number
                        }}</span
                      >
                    </p>
                  </div>
                </td>
              </tr>
            </table>
          </div>
        </my-tab>
        <my-tab tabTitle="{{ 'Notes' | translate }}">
          <div class="px-6">
            <textarea
              name="notes"
              id="notes"
              formControlName="notes"
              placeholder="{{ 'Write notes here' | translate }}"
              class="new-input !h-[11rem]"
              cols="30"
              rows="11"
            ></textarea>
          </div>
        </my-tab>
        <my-tab
          appAccessControl
          moduleType="storage"
          accessType="view"
          tabTitle="{{ 'Files' | translate }}"
        >
          <app-file-upload
            [addedFiles]="addedFiles"
            (emitter)="saveFiles($event)"
            type="compact"
            tableType="new"
          ></app-file-upload>
        </my-tab>
      </my-tabs>
    </form>
    <div class="flex items-center px-8 border-t py-4">
      <app-button
        buttonText="{{ 'Cancel' | translate }}"
        routerLink="/purchases/expenses"
      >
      </app-button>
      <div class="ms-auto flex items-center">
        <span *ngIf="issuedBy" class="me-2">
          <span class="Poppins-Medium me-2"
            >{{ "Issued By" | translate }} -</span
          >
          <span>{{ issuedBy }}</span>
        </span>
        <app-button
          buttonText="{{ 'Save' | translate }}"
          [disabled]="!enableSave"
          (handleClick)="update ? (showDialog = true) : saveJournal()"
          type="primary"
        ></app-button>
      </div>
    </div>
  </div>
</div>

<app-modal-container [isOpen]="showConfirmDialog">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Confirm Change" | translate }}
  </div>
  <div class="text-start" content>
    <p>
      {{
        "The customer's balance from this JV has already been used in Cash Invoice/s. If you wish to proceed, you may notice difference in report amount which can't be reversed."
          | translate
      }}
    </p>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="primary"
      (handleClick)="saveJournal()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="showConfirmDialog = false"
    ></app-button>
  </div>
</app-modal-container>
<app-modal-container [isOpen]="showDialog">
  <div header class="text-start border-b pb-2 Poppins-Medium">
    {{ "Confirm Change" | translate }}
  </div>
  <div class="text-start" content>
    <p>
      {{
        "You may incur difference in balance of the account used in the JV, do you still want to proceed?"
          | translate
      }}
    </p>
  </div>
  <div footer class="flex items-center ms-auto gap-x-2">
    <app-button
      buttonText="{{ 'Ok' | translate }}"
      type="primary"
      (handleClick)="saveJournal()"
    ></app-button>
    <app-button
      buttonText="{{ 'Cancel' | translate }}"
      (handleClick)="showDialog = false"
    ></app-button>
  </div>
</app-modal-container>
