import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-charts-of-accounts',
  templateUrl: './charts-of-accounts.component.html',
  styleUrls: ['./charts-of-accounts.component.scss']
})
export class ChartsOfAccountsComponent implements OnInit {

  constructor() {}
  ngOnInit(): void {
  }

}
