import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CurrentUsageDto } from '../dtos/user.dto';

@Injectable({ providedIn: 'root' })
export class UtilityService {
  constructor() {}
  showHideSettingsModal = new BehaviorSubject<{ open: boolean; tab?: string }>({
    open: false,
  });
  userUsage = new BehaviorSubject<CurrentUsageDto | null>(null);
}
