import * as fromVendors from '../reducers/vendors.reducer';
import * as fromProducts from '../reducers/product.reducer';
import * as fromSalesTaxes from '../reducers/salesTaxes.reducer';
import * as fromRoot from '../../../../store/reducers';

export interface PurchaseReducerState {
  vendors: fromVendors.VendorsState;
  products: fromProducts.ProductsState;
  salesTaxes: fromSalesTaxes.SalesTaxesState;

}
export interface State extends fromRoot.RootReducerState {
  purchase: PurchaseReducerState;
}
export const purchaseReducerMap = {
  vendors: fromVendors.reducer,
  products: fromProducts.reducer,
  salesTaxes: fromSalesTaxes.reducer,

};
export const getVendors = (state: PurchaseReducerState) => state.vendors;
export const getProducts = (state: PurchaseReducerState) => state.products;
export const getSalesTaxes = (state: PurchaseReducerState) => state.salesTaxes;

