import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BusinessService } from 'src/app/shared/services/business.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import {
  getAllCountries,
  getAllStates,
} from 'src/app/shared/utils/countryStateData';
import { NgxSpinnerService } from 'ngx-spinner';
import { RootReducerState } from 'src/app/store/reducers';
import { select, Store } from '@ngrx/store';
import { selectUserBusinesses } from 'src/app/store/selectors/user.selectors';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';
import { updateCurrentStoreLimit } from 'src/app/store/actions/usage.action';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
@Component({
  selector: 'app-store-edit-create',
  templateUrl: './store-edit-create.component.html',
})
export class StoreEditCreateComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private businessService: BusinessService,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private store: Store<RootReducerState>
  ) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = store.pipe(select(selectBusiness));
    this.usage$ = store.pipe(select(selectUsage));
  }

  business$: Observable<any>;
  storeForm: FormGroup;
  states = [];

  @Output() close = new EventEmitter();

  formErrors = {
    storeName: '',
    addressLineFirst: '',
    country: '',
    city: '',
    state: '',
  };

  storeFormErrorMessages = {
    storeName: {
      required: 'Store Name is Required',
    },
    addressLineFirst: {
      required: 'Address Line 1 is Required',
    },
    addressLineSecond: {
      required: 'Address Line 2 is Required',
    },
    country: {
      required: 'Country is Required',
    },
    state: {
      required: 'State is Required',
    },
    city: {
      required: 'City is Required',
    },
  };

  countrySelect;
  businesses$: Observable<any>;
  usage$: Observable<any>;
  businessLimit;
  storeLimit;
  unsubscribe$ = new Subject();
  update = false;
  business_id = null;
  @Input()
  storeId = null;
  ngOnInit(): void {
    this.loadStoreForm();
    this.loadCountries();
    this.getBusinessLimit();
    this.business$.subscribe((business) => {
      const businessId = business?.businessId?._id;
      this.business_id = businessId;
    });
    if (this.storeId) {
      this.getStoreDetails(this.storeId);
    }
  }

  getStoreDetails(storeId): void {
    this.spinner.show();
    this.businessService
      .getStoreDetails(storeId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp?.success) {
            this.spinner.hide();
            this.newStore(resp?.data);
            this.storeId = storeId;
            this.update = true;
          } else {
            this.spinner.hide();
            this.toastr.error(resp?.message || 'Something went wrong!');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message || 'Something went wrong!');
        }
      );
  }

  getBusinessLimit(): void {
    this.usage$.subscribe(({ currentUsage }) => {
      if (currentUsage) {
        const { businessLimit, storeLimit } = currentUsage;
        this.businessLimit = businessLimit;
        console.log('businessLimit', businessLimit);
        this.storeLimit = storeLimit;
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
  loadStoreForm(): void {
    this.storeForm = this.fb.group({
      storeName: ['', Validators.required],
      addressLineFirst: ['', Validators.required],
      addressLineSecond: [''],
      city: ['', Validators.required],
      state: [null, Validators.required],
      country: [null, Validators.required],
    });
    this.storeForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.formErrors = valueChanges(
          this.storeForm,
          { ...this.formErrors },
          this.storeFormErrorMessages
        );
      });
    this.formErrors = valueChanges(
      this.storeForm,
      { ...this.formErrors },
      this.storeFormErrorMessages
    );
  }

  newStore(storeDetails) {
    this.storeForm.patchValue({
      storeName: storeDetails?.storeName,
      addressLineFirst: storeDetails?.address?.addressLineFirst,
      addressLineSecond: storeDetails?.address?.addressLineSecond,
      city: storeDetails?.address?.city,
      state: storeDetails?.address?.state,
      country: storeDetails?.address?.country,
    });
  }

  loadCountries(): void {
    const countries = getAllCountries();
    this.countrySelect = countries;
  }

  changeEventContry(event): void {
    if (event?.text) {
      this.states = getAllStates(event.text);
      this.storeForm.get('store').setValue(null);
    }
  }

  createStore(): void {
    if (this.storeForm.invalid) {
      this.storeForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.storeForm,
        { ...this.formErrors },
        this.storeFormErrorMessages
      );
      return;
    }
    const {
      storeName,
      addressLineFirst,
      addressLineSecond,
      city,
      state,
      country,
    } = this.storeForm.value;
    this.spinner.show();
    const newStore = {
      storeId: this.storeId,
      storeName,
      address: {
        addressLineFirst,
        addressLineSecond,
        city,
        state,
        country,
      },
    };
    this.businessService
      .createStore(newStore, this.business_id)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp?.success) {
            this.spinner.hide();
            this.toastr.success(resp?.success);
            this.storeForm.reset();
            this.businessService.addStoreInStore(resp?.data);
            console.log(this.storeLimit);
            this.store.dispatch(
              updateCurrentStoreLimit({ storeLimit: this.storeLimit + 1 })
            );
            this.close.emit();
          } else {
            this.spinner.hide();
            this.toastr.error(resp?.message || 'Something went wrong');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message || 'Something went wrong');
        }
      );
  }

  updateStore(): void {
    if (this.storeForm.invalid) {
      this.storeForm.markAllAsTouched();
      this.formErrors = valueChanges(
        this.storeForm,
        { ...this.formErrors },
        this.storeFormErrorMessages
      );
      return;
    }
    const {
      storeName,
      addressLineFirst,
      addressLineSecond,
      city,
      state,
      country,
    } = this.storeForm.value;
    this.spinner.show();
    const updatedStore = {
      storeId: this.storeId,
      storeName,
      address: {
        addressLineFirst,
        addressLineSecond,
        city,
        state,
        country,
      },
    };
    this.businessService
      .updateStore(updatedStore)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (resp) => {
          if (resp?.success) {
            this.spinner.hide();
            this.toastr.success(resp?.message);
            this.businessService.updateStoreInStore({
              store: { ...resp?.data },
            });
            this.close.emit();
          } else {
            this.spinner.hide();
            this.toastr.error(resp?.message || 'Something went wrong');
          }
        },
        (err) => {
          this.spinner.hide();
          this.toastr.error(err?.message || 'Something went wrong');
        }
      );
  }
}
