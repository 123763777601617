<div class="application-history">
  <div class="process-bar px-8 py-6 rounded-xl">
    <div class="flex justify-between items-center w-full h-8">
      <h2 class="Poppins-SemiBold text-2xl text-white flex gap-3 items-center">
        <span class="check-mark-icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="9.849"
            height="7.33"
            viewBox="0 0 9.849 7.33"
          >
            <path
              id="Path_5173"
              data-name="Path 5173"
              d="M5,10.312,7.208,12.52,12.728,7"
              transform="translate(-3.939 -5.939)"
              fill="none"
              stroke="#000"
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="1.5"
            />
          </svg>
        </span>
        {{ "Your application is in process." | translate }}
      </h2>
      <img [src]="logo" class="h-8 w-24" alt="Logo" />
    </div>
    <p class="text-white text-xs w-[75%] opacity-70 mt-2">
      {{
        "Admin is reviewing your application. Once approved you will be able to
      login in expert panel and manage enquiries, referral codes and provide
      your services to other numetric users." | translate
      }}
    </p>
  </div>
  <div class="history-list mt-6">
    <h4 class="Poppins-SemiBold text-xl">
      {{ "Application History" | translate }}
    </h4>
    <div class="grid-list">
      <table class="w-full">
        <thead>
          <tr
            class="text-center text-sm w-full flex justify-between px-3 text-black opacity-50 mt-4"
          >
            <th class="ltr:text-left rtl:text-right w-full">
              {{ "Application Date" | translate }}
            </th>
            <th class="ltr:text-right rtl:text-left w-full pr-10">
              {{ "Request Status" | translate }}
            </th>
          </tr>
        </thead>
        <tbody class="w-full">
          <tr
            class="text-center w-full flex border-top justify-between px-3 pt-4 mt-2"
          >
            <td class="ltr:text-left rtl:text-right Poppins-SemiBold w-full">
              {{ appliedData?.createdAt | date : "MMM d, y" }}
            </td>
            <td
              class="ltr:text-right rtl:text-left Poppins-SemiBold w-full pr-10"
            >
              <span class="status-badge">{{ appliedData?.status }}</span>
              <div
                routerLink="/experts/become-expert/expert-application/apply-again"
                class="text-xs mt-4 cursor-pointer"
                *ngIf="appliedData?.status === 'Rejected'"
              >
                Apply again
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
