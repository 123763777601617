import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-payroll-subscription-history',
  templateUrl: './payroll-subscription-history.component.html',
  styleUrls: ['./payroll-subscription-history.component.scss']
})
export class PayrollSubscriptionHistoryComponent implements OnInit {

  unsubscribe$ = new Subject();
  subscriptionHistory = [];

  constructor(
    private subscriptionService: SubscriptionService,
    private spinner: NgxSpinnerService,
    private datePipe: DatePipe,
    private toastr: ToastrService
  ) { }

  ngOnInit(): void {
    this.loadSubscriptionHistory();
  }

  loadSubscriptionHistory(): void {
    this.spinner.show();
    this.subscriptionService.getPayrollSubscriptionHistoryOfUser()
    .pipe(
      map((result: any) => {
        if (result?.success) {
          const subscriptionHistory = result.data.map((history: any) => {
            history.date = history.date ? this.datePipe.transform(history.date, 'yyyy/MM/dd') : history.date;
            history.expiryDate = history.expiryDate ? this.datePipe.transform(history.expiryDate, 'yyyy/MM/dd') : history.expiryDate;
            return history;
          });
          // const sorted = subscriptionHistory.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          result = subscriptionHistory;
        }
        else{
          this.toastr.error(result?.message);
        }
        return result;
      })
    )
    .subscribe((result) => {
        this.spinner.hide();
        this.subscriptionHistory = [...result];
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(error?.error?.message);
    });
  }

}
