import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountingComponent } from './accounting.component';
import { JournalEntryComponent } from './component/journal-entry/journal-entry.component';
import { TransactionComponent } from './component/transaction/transaction.component';

const routes: Routes = [
  {
    path: '',
    component: AccountingComponent,
    children: [
      {
        path: 'charts-of-account',
        loadChildren: () => import('./component/charts-of-accounts/charts.module').then((m) => m.ChartsModule)
      },
      {
        path: 'transaction',
        component: TransactionComponent
      },
      {
        path: 'journal-entry',
        component: JournalEntryComponent
      },
      {
        path: 'recurring-jv',
        loadChildren: () => import('./component/recurring-jv/recurring-jv.module').then(m => m.RecurringJvModule)
      }
  ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AccountingRoutingModule { }
