import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { select, Store } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { NumplusService } from '../../../numplus.service';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectAccounts } from 'src/app/store/selectors/account.selector';
import { takeUntil } from 'rxjs/operators';
import { loadAccountsOf } from 'src/app/shared/utils/accountType';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { ThemeService } from 'ng2-charts';
import { BudgetService } from '../budget.service';

@Component({
  selector: 'app-budget-view',
  templateUrl: './budget-view.component.html',
  styleUrls: ['./budget-view.component.scss']
})
export class BudgetViewComponent implements OnInit {
  financialPeriod:Array<any> = [
    {
      name:'2020',
      value:'2020'
    },
    {
      name:'2021',
      value:'2021'
    },
    {
      name:'2022',
      value:'2022'
    },
    {
      name:'2023',
      value:'2023'
    },
    {
      name:'2024',
      value:'2024'
    },
    {
      name:'2025',
      value:'2025'
    }
  ]

  selectedRowDetais;

  interval:Array<string> =['Monthly', 'Yearly', 'Quarterly']

   public tableIncomeCol: Array<string> = ["INCOME","APR", "MAY", "JUN", "JUL",
  "AUG", "SEP", "OCT", "NOV", "DEC",
  "JAN", "FEB", "MAR", "TOTALS"];

  public tableExpensesCol: Array<string> = ["EXPENSES","APR", "MAY", "JUN", "JUL",
  "AUG", "SEP", "OCT", "NOV", "DEC",
  "JAN", "FEB", "MAR", "TOTALS"];

  public monthsName = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August',
                       'September', 'October', 'November', 'December']

  public newMonthsName:Array<string> = []

  tableData: Array<any> = []

  budgetCreation: Array<any> = []

  budgetSingleRow: Array<any> = [
    {
      "month": "",
      "year": "",
      "amount": null
  },
  {
      "month": "",
      "year": "",
      "amount": null
  },
  {
      "month": "",
      "year": "",
      "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "month": "",
    "year": "",
    "amount": null
  },
  {
    "Total": null
  }
]
  incomeBudget:Array<any> = []

  expensesBudget:Array<any> = []


  view:boolean = true;
  editData:boolean = false

  firstForm: FormGroup;
  secondForm:FormGroup;
  yearBudget:FormGroup;

  firstFormError = {
    budgetName:'',
    period:'',
  }

  firstFormErrorMessage= {
    budgetName:{
      required:'Name is required'
    },
    period:{
      required:'Financial Period is required'
    },
  }

  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };

  accounts$:Observable<any>;
  business$: Observable<any>;
  businessId = null;
  fiscalMonth =null
  unsubscribe$ = new Subject();
  accountTypesBySection;
  accountData;
  incomeData;
  createBudgetModal = false;
  accountText = ""
  budget:Array<any> = []
  expensesData;
  netotalIncome:Array<number> = []
  netotalExpense:Array<number> = []
  update:boolean = false
  selectedRowForUpdate;
  ycod;
  viewRecord = null
  tablemargin

  @ViewChild('createModal', { static: false }) divCreateModal: ElementRef;

  constructor( private activateRoute: ActivatedRoute,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private translateService: TranslateService,
    private toastr: ToastrService,
    private rootStore:Store<RootReducerState>,
    private numplusService: NumplusService,
    private fb:FormBuilder,
    private budgetService: BudgetService,
    private router: Router,
    private elementRef: ElementRef,
    private renderer: Renderer2) {
      this.business$ = this.rootStore.pipe(select(selectBusiness));
      this.accounts$ = this.store.pipe(select(selectAccounts));
    }

  ngOnInit(): void {
    this.loadSecondForm()
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) =>{
      console.log("Business Details", business?.businessId)
      if(business?.businessId?._id){
        this.businessId = business.businessId._id
      }
      if(business?.businessId?.accounting?.month){
        this.fiscalMonth = business?.businessId?.accounting?.month
      }

      this.getCurrencyDetails()

    this.viewRecord = this.activateRoute.snapshot.params['id']
    console.log("view Record", this.viewRecord)
    if(this.viewRecord !== undefined && this.businessId !== null ){
      this.spinner.show()
      this.loadAccount()

      this.updateFormatAccToFiscalMonth(this.monthsName, this.fiscalMonth)
      this.retrieveData(this.viewRecord)
      this.view = false
      this.editData = true
      this.spinner.hide()
    }
    })


  }

  loadAccount(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.numplusService.getAllAccounts(business.businessId._id).subscribe(resp => {
          this.spinner.hide();
          this.accountData = resp.data;
          this.incomeData = this.accountData.filter((data) =>{
             return data.accountSection.toLowerCase() === 'income';
          })
          console.log(this.incomeData)
          this.expensesData = this.accountData.filter((data) =>{
            return data.accountSection.toLowerCase() === 'expenses'
         })
        }
        , (error) => {
          this.spinner.hide();
          console.log(error);
        });
      }
    });
  }

  getCurrencyDetails(): void {
    this.numplusService.currencyDetail.subscribe(details => {
      if (details) {
      this.currencyDetails = details;
      }
    });
  }

  updateFormatAccToFiscalMonth(monthsData:any, fiscalMonth:string){
    let newMonths = this.createMonthsAccToFiscalMonth(monthsData,fiscalMonth)
    this.newMonthsName.push(...newMonths)
    this.tableIncomeCol.splice(1,this.tableIncomeCol.length-2)
    this.tableExpensesCol.splice(1,this.tableExpensesCol.length-2)
    this.tableIncomeCol.splice(1,0, ...newMonths)
    this.tableExpensesCol.splice(1,0,...newMonths)
  }


  createMonthsAccToFiscalMonth(monthsData:any, fiscalMonth:string){
    const index = monthsData.findIndex((month) => month === fiscalMonth)
    const firstHalf = monthsData.slice().splice(0,index)
    const secondHalf = monthsData.slice().splice(index,monthsData.length)
    return [...secondHalf,...firstHalf]
  }

  loadSecondForm():void{
    this.secondForm= this.fb.group({
      name:[],
      year:[]
    })
  }

  counter(i:number){
    return new Array(i);
  }

  retrieveData(year){
    let retrieveBody = {
      "year":year
    }
    this.budgetService.getBusinessBudget(retrieveBody).subscribe(
      (resp) =>{
        if(resp.status === 200){
          let retriveData = resp.data
          console.table(retriveData)
          console.log("Retrieve Data", retriveData)
          this.secondForm.get('name').setValue(retriveData[0].name)
          this.secondForm.get('year').setValue(retriveData[0].year)
          this.incomeBudget = retriveData.filter((data) =>{
            return data.accountSection.toLowerCase() === 'income';
         })
         this.netTotalFor("Income")
         this.expensesBudget = retriveData.filter((data) =>{
           return data.accountSection.toLowerCase() === 'expenses'
        })
        this.netTotalFor("Expenses")
        this.spinner.hide()
        }
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Something went wrong!'));
      }
    )
  }

  getDataArray(incomeName:any){
    for(let i=0; i < this.incomeBudget.length; i++){
      if(this.incomeBudget[i].accountName === incomeName){
        return  this.incomeBudget[i].budget
      }
    }
    return this.budgetSingleRow
  }

  getDataArray2(expenseName:any){
    for(let i=0; i < this.expensesBudget.length; i++){
      if(this.expensesBudget[i].accountName === expenseName){
        return  this.expensesBudget[i].budget
      }
    }
    return this.budgetSingleRow
  }

  totalAmount(arr){
    let arry = Object.values(arr)
    console.log(arry)
    const total = arry.reduce((previousValue:any, currentValue:any) => previousValue + currentValue,0);
    return total
  }

  netTotalFor(netTotalOf){
    let total =0
    if(netTotalOf === 'Income'){
      this.netotalIncome.splice(0,this.netotalIncome.length)
      for( let j=0; j<13;j++){
        for(let i=0; i < this.incomeBudget.length; i++){
           total += this.incomeBudget[i]?.budget[j]?.amount ? this.incomeBudget[i]?.budget[j]?.amount : 0
        }
      this.netotalIncome.push(total)
      total = 0
      }
    } else if(netTotalOf === 'Expenses'){
      this.netotalExpense.splice(0,this.netotalExpense.length)
      for( let j=0; j<13;j++){
        for(let i=0; i < this.expensesBudget.length; i++){
           total += this.expensesBudget[i]?.budget[j]?.amount ?  this.expensesBudget[i]?.budget[j]?.amount : 0
        }
      this.netotalExpense.push(total)
      total = 0
    }
  }
}


ngAfterViewInit(){
  this.elementRef.nativeElement.focus();
}

}
