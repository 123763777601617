<div class="min-h-[85svh] p-8 w-full" [ngClass]="{'!w-[100vw] min-h-[94svh] !p-0': isMobileDevice}">
    <div class="bg-white px-14 py-11 rounded-xl space-y-6" [ngClass]="{'!px-3 !py-3': isMobileDevice}">
        <div class="flex justify-between relative items-start">
            <div class="1/3 flex flex-col items-start">
                <!-- <svg (click)="toggleSidebar()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>  -->
                <!-- <h1 class="text-2xl Poppins-Bold inline-flex items-center">
                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#000000">
                        <path d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z"/>
                    </svg>
                    {{'File Manager' | translate}}
                </h1> -->
            </div>
            <app-dropdown class="absolute ltr:right-0 rtl:left-0 right-options connect-redirection-options" #dropdownOptions>
                <!-- <app-button button type='primary' buttonText='{{"Add New Transaction" | translate}}' size='large' (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false'></app-button> -->
                <svg button (click)='dropdownOptions.open=true' (clickOutside)='dropdownOptions.open=false' xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                </svg>                          
                <div options>
                    <span routerLink="/chats" class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-t-xl"><svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                    </svg>{{'Chats' | translate}}</span>
                    <span routerLink="/task" class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl">
                        <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg>
                        {{'Task-Manager' | translate}}</span>
                    <span routerLink="/files" class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl"><svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z" fill="#000"/>
                        <path d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z" fill="#000"/>
                        <path d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z" fill="#000"/>
                    </svg>{{'Files' | translate}}</span>
                    <a (click)="goToBusiness()" class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21 3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                      </svg>
                      {{'Switch Business' | translate}}</a>
                    <a href="https://onelink.to/q8xxk4" target="_blank" class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-6 w-6 me-2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 1.5H8.25A2.25 2.25 0 0 0 6 3.75v16.5a2.25 2.25 0 0 0 2.25 2.25h7.5A2.25 2.25 0 0 0 18 20.25V3.75a2.25 2.25 0 0 0-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3" />
                        </svg>
                        {{'Download App' | translate}}</a>
                    <span (click)='logout()'  class="flex items-center cursor-pointer px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 text-center rounded-b-xl">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-6 me-2" viewBox="0 0 20 20" fill="currentColor">
                            <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                        </svg>
                        {{'Logout' | translate}}
                    </span>
                </div>
            </app-dropdown>
        </div>
        <div class="flex gap-8 justify-start items-start border-b pb-8" [ngClass]="{'!mt-12': isMobileDevice}">
            <div routerLink="/files" (click)="activeRoute = '/files'" class="flex gap-4 px-8 py-2 flex-col cursor-pointer" [ngClass]="{'active-background' : activeRoute === '/files'}">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="h-16 outline-none">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                </svg>
                <p class="text-sm Poppins-SemiBold text-center">{{"My Files" | translate}}</p>
            </div>
            <div routerLink="/files/shared" (click)="activeRoute = '/files/shared'" class="flex gap-4 px-4 py-2 flex-col cursor-pointer" [ngClass]="{'active-background' : activeRoute === '/files/shared'}">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" data-slot="icon" class="h-16 outline-none">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 12.75V12A2.25 2.25 0 0 1 4.5 9.75h15A2.25 2.25 0 0 1 21.75 12v.75m-8.69-6.44-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z" />
                </svg>
                <p class="text-sm Poppins-SemiBold text-center">{{"Shared with me" | translate}}</p>
            </div>
        </div>
        <router-outlet></router-outlet>
    </div>
</div>