import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService {

  companyName = new BehaviorSubject('');
  invitedUsers = new BehaviorSubject([]);

  constructor(private httpService: HttpService) { }

  setCompanyName(name){
    if(name){
      this.companyName.next(name)
    }
  }

  inviteNewUser(data): Observable<any> {
    return this.httpService.post('/users/inviteUserForBusiness', data).pipe(catchError((error) => throwError(error)));
  }

  getInvitedUsers(id): Observable<any> {
    return this.httpService.get(`/users/getInvites/${id}`).pipe(catchError((error) => throwError(error)));
  }

  findInvite(id): Observable<any> {
    return this.httpService.get(`/users/findInvite/${id}`).pipe(catchError((error) => throwError(error)));
  }

  updateInvitedUser(data): Observable<any> {
    return this.httpService.put(`/users/updatePermission`, data).pipe(catchError((error) => throwError(error)));
  }

  removeInvitedUser(inviteId): Observable<any> {
    return this.httpService.delete(`/users/removeInvite/${inviteId}`).pipe(catchError((error) => throwError(error)));
  }
}
