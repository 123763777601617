<ng-container *ngTemplateOutlet='primary ? primarySidebar : plus ? numPlusSideBar : secondarySidebar'></ng-container>
<ng-template #primarySidebar>
    <div class="h-full flex flex-col w-1/6 shadow-2xl Poppins-Bold fixed bg-white rounded-e-3xl">
        <img [src]="logo" class="m-4 ms-6 w-[150px]" alt="Logo">
        <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
            <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
                <div class="Poppins-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div *ngIf='toggleBussiness' id="business-account"
                class="max-h-96 overflow-y-auto z-30 bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 rounded-xl dropdown-shadow focus:outline-none">
            <div class="px-4 py-2 border-b border-gray-200 Poppins-Bold ">{{'Businesses' | translate}}</div>
            <ng-container *ngIf="businesses$ | async as businesses">
                <span
                    *ngFor="let business of businesses"
                    [ngClass] = "currentBusiness?.businessId?._id === business?.businessId?._id ? 'bg-gray-50' : ''"
                    class="flex items-center px-4  py-2 text-sm m-2 rounded-full Poppins-Bold hover:bg-gray-50 cursor-pointer"
                    (click)='selectBusiness(business)'>{{business?.businessId?.companyName}}
                </span>
            </ng-container>
          </div>
        </div>
        <div class="flex-1 flex flex-col overflow-x-hidden overscroll-y-auto">
            <a routerLink="/settings/profile" routerLinkActive='active' class="2xl:py-2 py-1 px-4 inline-flex text-gray-400 Poppins-Bold items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                    <path d="M480-480q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM160-160v-112q0-34 17.5-62.5T224-378q62-31 126-46.5T480-440q66 0 130 15.5T736-378q29 15 46.5 43.5T800-272v112H160Zm80-80h480v-32q0-11-5.5-20T700-306q-54-27-109-40.5T480-360q-56 0-111 13.5T260-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T560-640q0-33-23.5-56.5T480-720q-33 0-56.5 23.5T400-640q0 33 23.5 56.5T480-560Zm0-80Zm0 400Z"/>
                </svg>
                {{'Profile Management' | translate}}
            </a>
            <a appAccessControl moduleType="setting" accessType="edit" routerLink='/settings/users-management' routerLinkActive='active' class="2xl:py-2 py-1 px-4 text-gray-400 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                    <path d="M0-240v-63q0-43 44-70t116-27q13 0 25 .5t23 2.5q-14 21-21 44t-7 48v65H0Zm240 0v-65q0-32 17.5-58.5T307-410q32-20 76.5-30t96.5-10q53 0 97.5 10t76.5 30q32 20 49 46.5t17 58.5v65H240Zm540 0v-65q0-26-6.5-49T754-397q11-2 22.5-2.5t23.5-.5q72 0 116 26.5t44 70.5v63H780Zm-455-80h311q-10-20-55.5-35T480-370q-55 0-100.5 15T325-320ZM160-440q-33 0-56.5-23.5T80-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T160-440Zm640 0q-33 0-56.5-23.5T720-520q0-34 23.5-57t56.5-23q34 0 57 23t23 57q0 33-23 56.5T800-440Zm-320-40q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-600q0 50-34.5 85T480-480Zm0-80q17 0 28.5-11.5T520-600q0-17-11.5-28.5T480-640q-17 0-28.5 11.5T440-600q0 17 11.5 28.5T480-560Zm1 240Zm-1-280Z"/>
                </svg>
                {{'Users Management' | translate}}
            </a>
            <!-- <a appAccessControl moduleType="setting" accessType="edit" routerLink='/settings/discount-code' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m9 14.25 6-6m4.5-3.493V21.75l-3.75-1.5-3.75 1.5-3.75-1.5-3.75 1.5V4.757c0-1.108.806-2.057 1.907-2.185a48.507 48.507 0 0 1 11.186 0c1.1.128 1.907 1.077 1.907 2.185ZM9.75 9h.008v.008H9.75V9Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm4.125 4.5h.008v.008h-.008V13.5Zm.375 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Z" />
                </svg>
                {{'Discount Code Management' | translate}}
            </a> -->
            <!-- <a routerLink='/settings/subscription/subscription-plans' [ngClass]="router.url.split('/')[2] === 'subscription' ? 'active' : ''" class="2xl:py-2 py-1 px-4 text-gray-400 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 14l6-6m-5.5.5h.01m4.99 5h.01M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16l3.5-2 3.5 2 3.5-2 3.5 2zM10 8.5a.5.5 0 11-1 0 .5.5 0 011 0zm5 5a.5.5 0 11-1 0 .5.5 0 011 0z" />
                </svg>
                {{'Subscription' | translate}}
            </a> -->
            <div appAccessControl moduleType="setting" accessType="edit" class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='subscription-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/subscription/subscription-plans' [ngClass]="router.url.split('/')[2] === 'subscription' ? 'active' : ''" class="text-gray-400 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                            <path d="M160-80q-33 0-56.5-23.5T80-160v-400q0-33 23.5-56.5T160-640h640q33 0 56.5 23.5T880-560v400q0 33-23.5 56.5T800-80H160Zm0-80h640v-400H160v400Zm240-40 240-160-240-160v320ZM160-680v-80h640v80H160Zm120-120v-80h400v80H280ZM160-160v-400 400Z"/>
                        </svg>
                        {{'Subscription' | translate}}
                    </a>
                    <!-- <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/settings/subscription/subscription-plans' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Core' | translate}}</a>
                            <a routerLink='/settings/subscription/payroll' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Add-Ons' | translate}}</a>
                        </div>
                    </div> -->
                </div>
            </div>
            <div appAccessControl moduleType='sales' accessType='edit' class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='sales-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/sales/invoice-customization' [ngClass]="router.url.split('/')[2] === 'sales' ? 'sales-active' : ''" class="text-gray-400 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" class="me-2" width="24px" fill="#a2a8b3">
                            <path d="M160-200h160v-320H160v320Zm240 0h160v-560H400v560Zm240 0h160v-240H640v240ZM80-120v-480h240v-240h320v320h240v400H80Z"/>
                        </svg>
                        {{'Sales' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/invoice-customization' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Invoice Customization' | translate}}</a>
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/pos-settings' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Cash Invoice and POS Settings' | translate}}</a>
                            <a appAccessControl moduleType='sales' accessType='edit' routerLink='/settings/sales/discount-code' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Discount Code Management' | translate}}</a>
                            <!-- <a routerLink='/settings/sales/payment-settings' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm text-gray-400 Poppins-Bold font-color-black-05">{{'Payment Settings' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='accounting' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-400 inline-flex items-center">
                <span id='accounting-bar' class="opacity-0 bg-gradient w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/settings/accounting/date-and-currency' [ngClass]="router.url.split('/')[2] === 'accounting' ? 'sales-active' : ''" class="Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                            <path d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                        </svg>
                        {{'Accounting' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-black w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/settings/accounting/date-and-currency' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm Poppins-Bold font-color-black-05">{{'Date and Currency' | translate}}</a>
                            <a routerLink='/settings/accounting/sales-taxes' routerLinkActive='sub-menu-active' class="2xl:text-base text-sm Poppins-Bold font-color-black-05">{{'Sales Taxes' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <a routerLink='/settings/data-export' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M17 16l4-4m0 0l-4-4m4 4H7m6 4v1a3 3 0 01-3 3H6a3 3 0 01-3-3V7a3 3 0 013-3h4a3 3 0 013 3v1" />
                </svg>
                Data Export
            </a> -->
            <a appAccessControl moduleType="setting" accessType="edit" routerLink='/settings/businesses' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                    <path d="M120-120v-560h160v-160h400v320h160v400H520v-160h-80v160H120Zm80-80h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 320h80v-80h-80v80Zm0-160h80v-80h-80v80Zm0-160h80v-80h-80v80Zm160 480h80v-80h-80v80Zm0-160h80v-80h-80v80Z"/>
                </svg>
                {{'Businesses' | translate}}
            </a>
            <a routerLink='/settings/online-payment' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 0 0 2.25-2.25V6.75A2.25 2.25 0 0 0 19.5 4.5h-15a2.25 2.25 0 0 0-2.25 2.25v10.5A2.25 2.25 0 0 0 4.5 19.5Z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#a2a8b3">
                    <path d="M160-240v-320 13-173 480Zm0-400h640v-80H160v80Zm303 480H160q-33 0-56.5-23.5T80-240v-480q0-33 23.5-56.5T160-800h640q33 0 56.5 23.5T880-720v213q-35-25-76.5-39T716-560q-57 0-107.5 21.5T520-480H160v240h279q3 21 9 41t15 39Zm213 80-12-60q-12-5-22.5-10.5T620-164l-58 18-40-68 46-40q-2-13-2-26t2-26l-46-40 40-68 58 18q11-8 21.5-13.5T664-420l12-60h80l12 60q12 5 22.5 10.5T812-396l58-18 40 68-46 40q2 13 2 26t-2 26l46 40-40 68-58-18q-11 8-21.5 13.5T768-140l-12 60h-80Zm40-120q33 0 56.5-23.5T796-280q0-33-23.5-56.5T716-360q-33 0-56.5 23.5T636-280q0 33 23.5 56.5T716-200Z"/>
                </svg>
                {{'Online Payment' | translate}}
            </a>
            <a routerLink='/dashboard' routerLinkActive='active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-400 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                </svg>
                {{'Dashboard' | translate}}
            </a>
        </div>
        <!-- <a routerLink='/dashboard'>
            <span class="flex items-center px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 hover:rounded-xl">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M11 3.055A9.001 9.001 0 1020.945 13H11V3.055z" />
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M20.488 9H15V3.512A9.025 9.025 0 0120.488 9z" />
                  </svg>
                {{'Dashboard' | translate}}</span>
        </a> -->
        <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
            <div class="relative">
                <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                    <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="{{'avatar' | translate}}" srcset="">
                    <div class="flex flex-col">
                        <div class="Poppins-Bold break-words" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>
                <div *ngIf='open'
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">

                <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 hover:roundex-xl cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                    </svg>
                    {{'Logout' | translate}}
                </span>
              </div>
            </div>
        </div>
    </div>
</ng-template>
<ng-template #secondarySidebar>
    <div class="h-full flex flex-col w-1/6 shadow-2xl Poppins-Bold fixed bg-sidebar" [ngClass]="{'rounded-e-3xl' : router.url !== '/chats'}" id="secondary-sidebar">
        <div class="flex justify-between">
            <img [src]="logo2" class="m-4 w-[160px]" alt="Logo">
            <svg (click)="toggleSidebar()" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 grip-icon-mobile">
                <path stroke-linecap="round" stroke-linejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>              
        </div>

        <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
            <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
                <div class="Poppins-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                </svg>
            </div>
            <div *ngIf='toggleBussiness' id="business-account"
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 max-h-96 overflow-y-auto z-30 rounded-xl dropdown-shadow focus:outline-none">
            <div class="px-4 py-2 border-b border-gray-200 Poppins-Bold">{{'Businesses' | translate}}</div>
            <ng-container *ngIf="businesses$ | async as businesses">
                <span
                    *ngFor="let business of businesses"
                    [ngClass] = "currentBusiness.businessId?._id === business.businessId?._id ? 'bg-gray-50' : ''"
                    class="flex items-center px-4 py-2 text-sm m-2 rounded-full Poppins-Bold hover:bg-gray-50 cursor-pointer"
                    (click)='selectBusiness(business)'>{{business.businessId?.companyName}}
                </span>
            </ng-container>
          </div>
        </div>
        <div class="flex flex-row items-center p-2 mx-auto mb-4 text-white">
            <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
            </svg> -->
            <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff">
                <path d="M480-80q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-155.5t86-127Q252-817 325-848.5T480-880q83 0 155.5 31.5t127 86q54.5 54.5 86 127T880-480q0 82-31.5 155t-86 127.5q-54.5 54.5-127 86T480-80Zm0-82q26-36 45-75t31-83H404q12 44 31 83t45 75Zm-104-16q-18-33-31.5-68.5T322-320H204q29 50 72.5 87t99.5 55Zm208 0q56-18 99.5-55t72.5-87H638q-9 38-22.5 73.5T584-178ZM170-400h136q-3-20-4.5-39.5T300-480q0-21 1.5-40.5T306-560H170q-5 20-7.5 39.5T160-480q0 21 2.5 40.5T170-400Zm216 0h188q3-20 4.5-39.5T580-480q0-21-1.5-40.5T574-560H386q-3 20-4.5 39.5T380-480q0 21 1.5 40.5T386-400Zm268 0h136q5-20 7.5-39.5T800-480q0-21-2.5-40.5T790-560H654q3 20 4.5 39.5T660-480q0 21-1.5 40.5T654-400Zm-16-240h118q-29-50-72.5-87T584-782q18 33 31.5 68.5T638-640Zm-234 0h152q-12-44-31-83t-45-75q-26 36-45 75t-31 83Zm-200 0h118q9-38 22.5-73.5T376-782q-56 18-99.5 55T204-640Z"/>
            </svg>
            <div class="flex items-center" dir='ltr'>
                <span class="me-3 text-sm Poppins-Bold">EN</span>
                <label for="toggle-example" class="flex items-center cursor-pointer relative">
                    <input type="checkbox" [(ngModel)]='arabicSelected' (change)='changeLanguage()' id="toggle-example" class="sr-only">
                    <div class="toggle-bg bg-green-500 border border-green-500 h-6 w-11 rounded-full"></div>
                </label>
                <span class="ms-3 text-sm Poppins-Bold">AR</span>
            </div>
        </div>
        <div class="flex-1 flex flex-col sidebarContent overflow-y-auto overflow-x-hidden">
            <div class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/chats' [ngClass]="router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'primary-sales-active' : ''" class="Poppins-Bold text-gray-300 pt-2 flex items-center 2xl:text-base text-sm relative">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M8.625 12a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H8.25m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0H12m4.125 0a.375.375 0 1 1-.75 0 .375.375 0 0 1 .75 0Zm0 0h-.375M21 12c0 4.556-4.03 8.25-9 8.25a9.764 9.764 0 0 1-2.555-.337A5.972 5.972 0 0 1 5.41 20.97a5.969 5.969 0 0 1-.474-.065 4.48 4.48 0 0 0 .978-2.025c.09-.457-.133-.901-.467-1.226C3.93 16.178 3 14.189 3 12c0-4.556 4.03-8.25 9-8.25s9 3.694 9 8.25Z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                            <path d="M320-520q17 0 28.5-11.5T360-560q0-17-11.5-28.5T320-600q-17 0-28.5 11.5T280-560q0 17 11.5 28.5T320-520Zm160 0q17 0 28.5-11.5T520-560q0-17-11.5-28.5T480-600q-17 0-28.5 11.5T440-560q0 17 11.5 28.5T480-520Zm160 0q17 0 28.5-11.5T680-560q0-17-11.5-28.5T640-600q-17 0-28.5 11.5T600-560q0 17 11.5 28.5T640-520ZM80-80v-720q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v480q0 33-23.5 56.5T800-240H240L80-80Zm126-240h594v-480H160v525l46-45Zm-46 0v-480 480Z"/>
                        </svg>
                        <span class="relative">
                            {{ (router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files' ? 'Chat' : 'Connect') | translate }}
                            <span *ngIf="(router.url.split('/')[1] === 'chats' || router.url.split('/')[1] === 'task' || router.url.split('/')[1] === 'files') && chatUpdated > 0" class="w-4 h-4 text-xs bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                            <span *ngIf="(router.url.split('/')[1] !== 'chats' && router.url.split('/')[1] !== 'task' && router.url.split('/')[1] !== 'files') && (chatUpdated > 0 || taskCountNumber > 0 || fileCountNumber >  0)" class="w-4 h-4 text-xs bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                        </span>
                    </a>
                    <div class="hidden sub-menu mt-2 items-center">
                        <!-- <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span> -->
                        <div class="flex flex-col">
                            <a routerLink="/task" routerLinkActive='primary-active' class="2xl:py-2 py-1 relative Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                                <!-- <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" color="currentColor"><path d="M9 6L20 6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 5.79999L4.60002 6.59998L6.60001 4.59999" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 11.8L4.60002 12.6L6.60001 10.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M3.80002 17.8L4.60002 18.6L6.60001 16.6" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 12L20 12" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path><path d="M9 18L20 18" stroke="currentColor" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"></path></svg> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db"><path d="M222-200 80-342l56-56 85 85 170-170 56 57-225 226Zm0-320L80-662l56-56 85 85 170-170 56 57-225 226Zm298 240v-80h360v80H520Zm0-320v-80h360v80H520Z"/></svg>
                                {{'Task-Manager' | translate}}
                                <span *ngIf="taskCountNumber > 0" class="w-4 h-4 text-xs bg-red-600 absolute rounded-full flex justify-center items-center top-[-2px] ltr:-right-3 rtl:-left-3"></span>
                            </a>
                            <a routerLink="/files" routerLinkActive='primary-active' class="2xl:py-2 relative py-1 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                                <!-- <svg class="h-6 w-6 me-2" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6776 2.30337C11.5101 1.89888 12.4899 1.89888 13.3223 2.30337L20.6379 5.85815C22.454 6.74064 22.454 9.25936 20.6379 10.1419L13.3223 13.6966C12.4899 14.1011 11.5101 14.1011 10.6776 13.6966L3.36208 10.1419C1.54595 9.25936 1.54595 6.74064 3.36208 5.85815L10.6776 2.30337ZM12.4408 4.01685C12.1633 3.88202 11.8367 3.88202 11.5592 4.01685L4.24364 7.57163C3.88042 7.74813 3.88042 8.25187 4.24364 8.42837L11.5592 11.9831C11.8367 12.118 12.1633 12.118 12.4408 11.9831L19.7563 8.42837C20.1196 8.25187 20.1195 7.74813 19.7563 7.57163L12.4408 4.01685Z" fill="#fff"/>
                                    <path d="M2.10555 16.8172C2.35254 16.3232 2.95321 16.123 3.44719 16.37L11.5528 20.4227C11.8343 20.5635 12.1657 20.5635 12.4472 20.4227L20.5528 16.37C21.0467 16.123 21.6474 16.3232 21.8944 16.8172C22.1414 17.3111 21.9412 17.9118 21.4472 18.1588L13.3416 22.2116C12.497 22.6339 11.5029 22.6339 10.6583 22.2116L2.55276 18.1588C2.05878 17.9118 1.85856 17.3111 2.10555 16.8172Z" fill="#fff"/>
                                    <path d="M3.44742 12.1058C2.95344 11.8588 2.35277 12.059 2.10578 12.553C1.85879 13.047 2.05901 13.6477 2.55299 13.8946L10.6586 17.9474C11.5031 18.3697 12.4973 18.3697 13.3418 17.9474L21.4474 13.8946C21.9414 13.6477 22.1416 13.047 21.8946 12.553C21.6476 12.059 21.047 11.8588 20.553 12.1058L12.4474 16.1586C12.1659 16.2993 11.8345 16.2993 11.553 16.1586L3.44742 12.1058Z" fill="#fff"/>
                                </svg> -->
                                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                                    <path d="M480-400 40-640l440-240 440 240-440 240Zm0 160L63-467l84-46 333 182 333-182 84 46-417 227Zm0 160L63-307l84-46 333 182 333-182 84 46L480-80Zm0-411 273-149-273-149-273 149 273 149Zm0-149Z"/>
                                </svg>
                                <span class="relative">
                                {{'Files' | translate}}
                                <span *ngIf="fileCountNumber > 0" class="w-4 h-4 text-xs bg-red-600 absolute rounded-full flex justify-center items-center -top-2 ltr:-right-3 rtl:-left-3"></span>
                                </span>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
            <a routerLink='/dashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" viewBox="0 -960 960 960" fill="#d0d4db">
                    <path d="M120-440v-320q0-33 23.5-56.5T200-840h240v400H120Zm240-80Zm160-320h240q33 0 56.5 23.5T840-760v160H520v-240Zm0 720v-400h320v320q0 33-23.5 56.5T760-120H520ZM120-360h320v240H200q-33 0-56.5-23.5T120-200v-160Zm240 80Zm240-400Zm0 240Zm-400-80h160v-240H200v240Zm400-160h160v-80H600v80Zm0 240v240h160v-240H600ZM200-280v80h160v-80H200Z"/>
                </svg>
                {{'Dashboard' | translate}}
            </a>
            <div appAccessControl moduleType='sales' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'sales' ? 'opacity-100' : 'opacity-0'" class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/sales/estimates' [ngClass]="router.url.split('/')[1] === 'sales' ? 'primary-sales-active' : ''" class="text-gray-300 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" class="me-2" width="24px" fill="#d0d4db">
                            <path d="M160-200h160v-320H160v320Zm240 0h160v-560H400v560Zm240 0h160v-240H640v240ZM80-120v-480h240v-240h320v320h240v400H80Z"/>
                        </svg>
                        {{'Sales' | translate}}
                    </a> 
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[100px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/sales/estimates' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Estimates' | translate}}</a>
                            <a routerLink='/sales/invoices' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Regular Invoices' | translate}}</a>
                            <a routerLink='/sales/recurring-invoices' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Recurring Invoices' | translate}}</a>
                            <a routerLink='/sales/cash-invoice' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Cash Invoices' | translate}}</a>
                            <a routerLink='/sales/customers' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Customers' | translate}}</a>
                            <a routerLink='/sales/customers-statement' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Customers statement' | translate}}</a>
                            <!-- <a routerLink='/sales/products-and-services' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Poppins-Bold">{{'Products and services' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='purchase' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'purchases' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/purchases/purchase-order' [ngClass]="router.url.split('/')[1] === 'purchases' ? 'primary-sales-active' : ''" class="text-gray-300 Poppins-Bold inline-flex items-center 2xl:text-base text-sm">

                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2 text-gray-300 Poppins-Bold inline-flex items-center 2xl:text-base text-sm" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" height="24px" class="me-2" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                            <path d="M280-80q-33 0-56.5-23.5T200-160q0-33 23.5-56.5T280-240q33 0 56.5 23.5T360-160q0 33-23.5 56.5T280-80Zm400 0q-33 0-56.5-23.5T600-160q0-33 23.5-56.5T680-240q33 0 56.5 23.5T760-160q0 33-23.5 56.5T680-80ZM246-720l96 200h280l110-200H246Zm-38-80h590q23 0 35 20.5t1 41.5L692-482q-11 20-29.5 31T622-440H324l-44 80h480v80H280q-45 0-68-39.5t-2-78.5l54-98-144-304H40v-80h130l38 80Zm134 280h280-280Z"/>
                        </svg>
                        {{'Purchases' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[100px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/purchases/purchase-order' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Purchase Order' | translate}}</a>
                            <a routerLink='/purchases/expenses' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Expenses' | translate}}</a>
                            <a routerLink='/purchases/bills' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Bills' | translate}}</a>
                            <a routerLink='/purchases/recurring-bill' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Recurring Bill' | translate}}</a>
                            <a routerLink='/purchases/vendors' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Vendors' | translate}}</a>
                            <a routerLink='/purchases/assets' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Assets' | translate}}</a>
                            <!-- <a routerLink='/purchases/products-and-services' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm text-gray-300 Gilroy-Bold">{{'Products and services' | translate}}</a> -->
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='inventory' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'inventory' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/inventory/single-product' [ngClass]="router.url.split('/')[1] === 'inventory' ? 'primary-sales-active' : ''" class="Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <span [ngClass]="router.url.split('/')[1] === 'inventory' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span> -->
                        <!-- <svg class="svg-icon" class="h-6 w-6 me-2" stroke="white" fill="white" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M867.84 437.76h-686.08c-14.336 0-25.6-11.264-25.6-25.6v-174.08c0-14.336 11.264-25.6 25.6-25.6h686.08c14.336 0 25.6 11.264 25.6 25.6v174.08c0 14.336-11.264 25.6-25.6 25.6z m-660.48-51.2h634.88v-122.88h-634.88v122.88z"  /><path d="M264.704 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M517.12 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M775.168 540.672m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M262.144 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M514.56 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M772.608 674.304m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M262.144 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M514.56 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M772.608 807.936m-34.816 0a34.816 34.816 0 1 0 69.632 0 34.816 34.816 0 1 0-69.632 0Z"  /><path d="M990.72 949.76H48.64c-14.336 0-25.6-11.264-25.6-25.6v-803.84c0-14.336 11.264-25.6 25.6-25.6h942.08c14.336 0 25.6 11.264 25.6 25.6v803.84c0 14.336-11.264 25.6-25.6 25.6z m-916.48-51.2h890.88v-752.64h-890.88v752.64z"  /></svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                            <path d="M200-80q-33 0-56.5-23.5T120-160v-451q-18-11-29-28.5T80-680v-120q0-33 23.5-56.5T160-880h640q33 0 56.5 23.5T880-800v120q0 23-11 40.5T840-611v451q0 33-23.5 56.5T760-80H200Zm0-520v440h560v-440H200Zm-40-80h640v-120H160v120Zm200 280h240v-80H360v80Zm120 20Z"/>
                        </svg>
                        {{'Inventory' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/inventory/single-product' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Item' | translate}}</a>
                            <a routerLink='/inventory/bundled-products' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Bundled Products' | translate}}</a>
                            <a routerLink='/inventory/production-run' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Production Run' | translate}}</a>
                            <a routerLink='/inventory/write-off' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Write Off' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <div appAccessControl moduleType='accounting' accessType='view' class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
                <span [ngClass]="router.url.split('/')[1] === 'accounting' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <div class="flex flex-col">
                    <a routerLink='/accounting/transaction' [ngClass]="router.url.split('/')[1] === 'accounting' ? 'primary-sales-active' : ''" class="Poppins-Bold inline-flex items-center 2xl:text-base text-sm">
                        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 7h6m0 10v-3m-3 3h.01M9 17h.01M9 14h.01M12 14h.01M15 11h.01M12 11h.01M9 11h.01M7 21h10a2 2 0 002-2V5a2 2 0 00-2-2H7a2 2 0 00-2 2v14a2 2 0 002 2z" />
                        </svg> -->
                        <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                            <path d="M320-240h60v-80h80v-60h-80v-80h-60v80h-80v60h80v80Zm200-30h200v-60H520v60Zm0-100h200v-60H520v60Zm44-152 56-56 56 56 42-42-56-58 56-56-42-42-56 56-56-56-42 42 56 56-56 58 42 42Zm-314-70h200v-60H250v60Zm-50 472q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z"/>
                        </svg>
                        {{'Accounting' | translate}}
                    </a>
                    <div class="hidden sub-menu ms-3 mt-2 items-center">
                        <span class="opacity-0 bg-white w-[2px] h-full min-h-[42px] me-4 rounded-full"></span>
                        <div class="flex flex-col">
                            <a routerLink='/accounting/transaction' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Transactions' | translate}}</a>
                            <a routerLink='/accounting/recurring-jv' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Recurring JV' | translate}}</a>
                            <a routerLink='/accounting/charts-of-account' routerLinkActive='primary-sub-menu-active' class="2xl:text-base text-sm Poppins-Bold text-gray-300">{{'Charts of Accounts' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div>
            <a appAccessControl moduleType='projects' accessType='view' routerLink='/projects/list' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span [ngClass]="router.url.split('/')[1] === 'projects' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" viewBox="0 0 20 20" stroke="currentColor" fill="none">
                    <path d="M17.414 2.586a2 2 0 00-2.828 0L7 10.172V13h2.828l7.586-7.586a2 2 0 000-2.828z" />
                    <path fill-rule="evenodd" d="M2 6a2 2 0 012-2h4a1 1 0 010 2H4v10h10v-4a1 1 0 112 0v4a2 2 0 01-2 2H4a2 2 0 01-2-2V6z" clip-rule="evenodd" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h357l-80 80H200v560h560v-278l80-80v358q0 33-23.5 56.5T760-120H200Zm280-360ZM360-360v-170l367-367q12-12 27-18t30-6q16 0 30.5 6t26.5 18l56 57q11 12 17 26.5t6 29.5q0 15-5.5 29.5T897-728L530-360H360Zm481-424-56-56 56 56ZM440-440h56l232-232-28-28-29-28-231 231v57Zm260-260-29-28 29 28 28 28-28-28Z"/>
                </svg>
                {{'Projects' | translate}}
            </a>
            <div appAccessControl moduleType='budget' accessType='view' >
                <a  routerLink='/plus/budget' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm" >
                  <span [ngClass]="router.url.split('/')[1] === 'plus' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                  <svg fill = "lightgray"  class="h-6 w-6 me-2 text-gray-300" width="24" height="24" id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 109.03"><path d="M0,19.4a6.13,6.13,0,0,1,12.26,0V96.78H116.75a6.13,6.13,0,1,1,0,12.25H0V19.4ZM107.82,8.22a6.77,6.77,0,1,1-4,12.24l-14.45,11a6.54,6.54,0,0,1,.06.89,6.77,6.77,0,0,1-13.53,0,6.59,6.59,0,0,1,.12-1.27l-7.42-5.35,0,0a15.08,15.08,0,0,1-21.33,0c-.29-.29-.57-.6-.83-.91l-7.57,5.38a6.51,6.51,0,0,1,.34,2.13,6.76,6.76,0,1,1-6.76-6.77,6.65,6.65,0,0,1,2.75.59l8.51-6.06a15.09,15.09,0,1,1,29.31-5,14.94,14.94,0,0,1-1.32,6.17l7.41,5.35a6.76,6.76,0,0,1,7.43.19L101.1,15.73a6.17,6.17,0,0,1,0-.75,6.76,6.76,0,0,1,6.76-6.76ZM63,12.27c-1.92-.21-3.32-.31-4.19-.31A8.07,8.07,0,0,0,57.3,12a.29.29,0,0,0-.21.29.4.4,0,0,0,.25.35,9.64,9.64,0,0,0,1.39.37c.75.17,1.38.32,1.86.47a9.47,9.47,0,0,1,1.4.55,2.47,2.47,0,0,1,1,.79,4.92,4.92,0,0,1,.63,2.73,4.44,4.44,0,0,1-1.24,3.52,3.31,3.31,0,0,1-2.76,1.16v1.52H56.35V22.26a14,14,0,0,1-3.28-.58l-.64-.14.19-3.61a36,36,0,0,0,5.12.46,2.2,2.2,0,0,0,.83-.11.38.38,0,0,0,.23-.35.32.32,0,0,0-.24-.31,12,12,0,0,0-1.32-.25A7.69,7.69,0,0,1,53.36,16a4.08,4.08,0,0,1-1-3.11c0-1.76.41-3,1.23-3.68a3.32,3.32,0,0,1,2.8-1.08V6.38h3.24V8.09a19.28,19.28,0,0,1,2.87.39l.67.11L63,12.27ZM58,2.52A12.56,12.56,0,1,1,45.39,15.08,12.56,12.56,0,0,1,58,2.52Zm43.51,33.82h12.69a1.33,1.33,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H101.47A1.34,1.34,0,0,1,100.14,87V37.67a1.33,1.33,0,0,1,1.33-1.33ZM76.36,53.23H89.05a1.34,1.34,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H76.36A1.34,1.34,0,0,1,75,87V54.56a1.34,1.34,0,0,1,1.33-1.33ZM51.24,36.34H63.93a1.33,1.33,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H51.24A1.34,1.34,0,0,1,49.91,87V37.67a1.33,1.33,0,0,1,1.33-1.33ZM26.13,56H38.82a1.34,1.34,0,0,1,1.33,1.33V87a1.34,1.34,0,0,1-1.33,1.33H26.13A1.34,1.34,0,0,1,24.8,87V57.29A1.34,1.34,0,0,1,26.13,56Z"/></svg>
                  {{'Budget' | translate}}
                </a>
            </div>
            <a appAccessControl moduleType='reports' accessType='view' routerLink="/reports" routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
                <span [ngClass]="router.url.split('/')[1] === 'reports' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 17v-2m3 2v-4m3 4v-6m2 10H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" height="24px" class="me-2" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                    <path d="M320-240h320v-80H320v80Zm0-160h320v-80H320v80ZM240-80q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h320l240 240v480q0 33-23.5 56.5T720-80H240Zm280-520v-200H240v640h480v-440H520ZM240-800v200-200 640-640Z"/>
                </svg>
                {{'Reports' | translate}}
            </a>
            <div appAccessControl moduleType='payroll' accessType='view' >
                <a (click)="redirect('payroll')"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                    <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M12 6v12m-3-2.818l.879.659c1.171.879 3.07.879 4.242 0 1.172-.879 1.172-2.303 0-3.182C13.536 12.219 12.768 12 12 12c-.725 0-1.45-.22-2.003-.659-1.106-.879-1.106-2.303 0-3.182s2.9-.879 4.006 0l.415.33M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                        <path d="M444-200h70v-50q50-9 86-39t36-89q0-42-24-77t-96-61q-60-20-83-35t-23-41q0-26 18.5-41t53.5-15q32 0 50 15.5t26 38.5l64-26q-11-35-40.5-61T516-710v-50h-70v50q-50 11-78 44t-28 74q0 47 27.5 76t86.5 50q63 23 87.5 41t24.5 47q0 33-23.5 48.5T486-314q-33 0-58.5-20.5T390-396l-66 26q14 48 43.5 77.5T444-252v52Zm36 120q-83 0-156-31.5T197-197q-54-54-85.5-127T80-480q0-83 31.5-156T197-763q54-54 127-85.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 83-31.5 156T763-197q-54 54-127 85.5T480-80Zm0-80q134 0 227-93t93-227q0-134-93-227t-227-93q-134 0-227 93t-93 227q0 134 93 227t227 93Zm0-320Z"/>
                    </svg>
                    {{'Payroll' | translate}}
                </a>
            </div>
            <div class="2xl:py-2 py-1 px-4 text-gray-300 inline-flex items-center">
              <span
                [ngClass]="
                  router.url.split('/')[1] === 'experts' ? 'opacity-100' : 'opacity-0'
                "
                class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"
              ></span>
              <div class="flex flex-col">
                <a
                  routerLink="/experts/hire-expert"
                  [ngClass]="
                    router.url.split('/')[1] === 'experts'
                      ? 'primary-sales-active'
                      : ''
                  "
                  class="text-gray-300 Poppins-Bold inline-flex items-center 2xl:text-base text-sm"
                  >
                  <!-- <svg
                    class="h-5 w-[26px] me-2 text-gray-300 Poppins-Bold expert-icon inline-flex items-center 2xl:text-base text-sm"
                    xmlns="http://www.w3.org/2000/svg"
                    width="15.238"
                    height="13.83"
                    viewBox="0 0 15.238 13.83"
                  >
                    <g
                      id="Group_7353"
                      data-name="Group 7353"
                      transform="translate(-1.5 -3.5)"
                    >
                      <path
                        id="Path_5308"
                        data-name="Path 5308"
                        d="M2,17.635v-.7A4.931,4.931,0,0,1,6.931,12h0"
                        transform="translate(0 -2.365)"
                        fill="none"
                        stroke="#2c03f5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                      <path
                        id="Path_5309"
                        data-name="Path 5309"
                        d="M14.616,12.157a1.14,1.14,0,0,1,1.685,0h0a1.137,1.137,0,0,0,.9.371h0a1.14,1.14,0,0,1,1.192,1.192h0a1.14,1.14,0,0,0,.37.9h0a1.139,1.139,0,0,1,0,1.685h0a1.139,1.139,0,0,0-.37.9h0A1.14,1.14,0,0,1,17.2,18.388h0a1.14,1.14,0,0,0-.9.37h0a1.14,1.14,0,0,1-1.685,0h0a1.14,1.14,0,0,0-.9-.371h0A1.14,1.14,0,0,1,12.528,17.2h0a1.14,1.14,0,0,0-.371-.9h0a1.14,1.14,0,0,1,0-1.685h0a1.137,1.137,0,0,0,.371-.9h0a1.14,1.14,0,0,1,1.192-1.192h0a1.138,1.138,0,0,0,.9-.371h0Z"
                        transform="translate(-2.893 -2.301)"
                        fill="none"
                        stroke="#2c03f5"
                        stroke-width="1"
                      />
                      <path
                        id="Path_5310"
                        data-name="Path 5310"
                        d="M12.3,13.157l.768.768L14.6,12.389M7.817,9.635A2.817,2.817,0,1,0,5,6.817,2.817,2.817,0,0,0,7.817,9.635Z"
                        transform="translate(-0.887)"
                        fill="none"
                        stroke="#2c03f5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="1"
                      />
                    </g>
                  </svg> -->
                  <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                    <path d="M702-480 560-622l57-56 85 85 170-170 56 57-226 226Zm-342 0q-66 0-113-47t-47-113q0-66 47-113t113-47q66 0 113 47t47 113q0 66-47 113t-113 47ZM40-160v-112q0-34 17.5-62.5T104-378q62-31 126-46.5T360-440q66 0 130 15.5T616-378q29 15 46.5 43.5T680-272v112H40Zm80-80h480v-32q0-11-5.5-20T580-306q-54-27-109-40.5T360-360q-56 0-111 13.5T140-306q-9 5-14.5 14t-5.5 20v32Zm240-320q33 0 56.5-23.5T440-640q0-33-23.5-56.5T360-720q-33 0-56.5 23.5T280-640q0 33 23.5 56.5T360-560Zm0 260Zm0-340Z"/>
                  </svg>
                  {{ "Experts" | translate }}
                </a>
                <div class="hidden sub-menu ms-3 mt-2 items-center">
                  <span
                    class="opacity-0 bg-gray-300 w-[2px] h-full min-h-[50px] me-4 rounded-full"
                  ></span>
                  <div class="flex flex-col">
                    <!-- <a
                      routerLink="/experts/become-expert"
                      routerLinkActive="primary-sub-menu-active"
                      class="2xl:text-base text-sm text-gray-300 Poppins-Bold"
                      >{{ "Become an expert" | translate }}</a
                    > -->
                    <a
                      routerLink="/experts/hire-expert"
                      routerLinkActive="primary-sub-menu-active"
                      class="2xl:text-base text-sm text-gray-300 Poppins-Bold"
                      >{{ "Hire an expert" | translate }}</a
                    >
                    <a
                      routerLink="/experts/enquiry-history"
                      routerLinkActive="primary-sub-menu-active"
                      class="2xl:text-base text-sm text-gray-300 Poppins-Bold"
                      >{{ "Enquiry History" | translate }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
            <div appAccessControl moduleType='files' accessType='view' *ngIf="haveStoragePlan" >
                <a routerLink="/storage"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                    <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                    <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M20.25 6.375c0 2.278-3.694 4.125-8.25 4.125S3.75 8.653 3.75 6.375m16.5 0c0-2.278-3.694-4.125-8.25-4.125S3.75 4.097 3.75 6.375m16.5 0v11.25c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125V6.375m16.5 0v3.75m-16.5-3.75v3.75m16.5 0v3.75C20.25 16.153 16.556 18 12 18s-8.25-1.847-8.25-4.125v-3.75m16.5 0c0 2.278-3.694 4.125-8.25 4.125s-8.25-1.847-8.25-4.125" />
                    </svg> -->
                    <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                        <path d="M480-120q-151 0-255.5-46.5T120-280v-400q0-66 105.5-113T480-840q149 0 254.5 47T840-680v400q0 67-104.5 113.5T480-120Zm0-479q89 0 179-25.5T760-679q-11-29-100.5-55T480-760q-91 0-178.5 25.5T200-679q14 30 101.5 55T480-599Zm0 199q42 0 81-4t74.5-11.5q35.5-7.5 67-18.5t57.5-25v-120q-26 14-57.5 25t-67 18.5Q600-528 561-524t-81 4q-42 0-82-4t-75.5-11.5Q287-543 256-554t-56-25v120q25 14 56 25t66.5 18.5Q358-408 398-404t82 4Zm0 200q46 0 93.5-7t87.5-18.5q40-11.5 67-26t32-29.5v-98q-26 14-57.5 25t-67 18.5Q600-328 561-324t-81 4q-42 0-82-4t-75.5-11.5Q287-343 256-354t-56-25v99q5 15 31.5 29t66.5 25.5q40 11.5 88 18.5t94 7Z"/>
                    </svg>
                    {{'Documents' | translate}}
                </a>
            </div>
            <a (click)="redirect('education')"  routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm cursor-pointer" >
                <span  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 me-2 mr-2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 6.042A8.967 8.967 0 006 3.75c-1.052 0-2.062.18-3 .512v14.25A8.987 8.987 0 016 18c2.305 0 4.408.867 6 2.292m0-14.25a8.966 8.966 0 016-2.292c1.052 0 2.062.18 3 .512v14.25A8.987 8.987 0 0018 18a8.967 8.967 0 00-6 2.292m0-14.25v14.25" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                    <path d="M260-320q47 0 91.5 10.5T440-278v-394q-41-24-87-36t-93-12q-36 0-71.5 7T120-692v396q35-12 69.5-18t70.5-6Zm260 42q44-21 88.5-31.5T700-320q36 0 70.5 6t69.5 18v-396q-33-14-68.5-21t-71.5-7q-47 0-93 12t-87 36v394Zm-40 118q-48-38-104-59t-116-21q-42 0-82.5 11T100-198q-21 11-40.5-1T40-234v-482q0-11 5.5-21T62-752q46-24 96-36t102-12q58 0 113.5 15T480-740q51-30 106.5-45T700-800q52 0 102 12t96 36q11 5 16.5 15t5.5 21v482q0 23-19.5 35t-40.5 1q-37-20-77.5-31T700-240q-60 0-116 21t-104 59ZM280-494Z"/>
                </svg>
                {{'Learn and Support' | translate}}
            </a>
            <a routerLink='/settings/profile' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm" >
                <span [ngClass]="router.url.split('/')[1] === 'settings' ? 'opacity-100' : 'opacity-0'"  class="opacity-0 bg-white w-1 h-full min-h-[32px] me-4 rounded-full"></span>
                <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M5 4a1 1 0 00-2 0v7.268a2 2 0 000 3.464V16a1 1 0 102 0v-1.268a2 2 0 000-3.464V4zM11 4a1 1 0 10-2 0v1.268a2 2 0 000 3.464V16a1 1 0 102 0V8.732a2 2 0 000-3.464V4zM16 3a1 1 0 011 1v7.268a2 2 0 010 3.464V16a1 1 0 11-2 0v-1.268a2 2 0 010-3.464V4a1 1 0 011-1z" />
                </svg> -->
                <svg xmlns="http://www.w3.org/2000/svg" class="me-2" height="24px" viewBox="0 -960 960 960" width="24px" fill="#d0d4db">
                    <path d="M200-160v-280h-80v-80h240v80h-80v280h-80Zm0-440v-200h80v200h-80Zm160 0v-80h80v-120h80v120h80v80H360Zm80 440v-360h80v360h-80Zm240 0v-120h-80v-80h240v80h-80v120h-80Zm0-280v-360h80v360h-80Z"/>
                </svg>
                {{'Settings' | translate}}
            </a>

        </div>
        <!-- <a routerLink='/settings/businesses' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
            <span class="opacity-0 bg-gradient w-1 h-10 me-4 rounded-full"></span>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 21V5a2 2 0 00-2-2H7a2 2 0 00-2 2v16m14 0h2m-2 0h-5m-9 0H3m2 0h5M9 7h1m-1 4h1m4-4h1m-1 4h1m-5 10v-5a1 1 0 011-1h2a1 1 0 011 1v5m-4 0h4" />
              </svg>
            Hire an Advisor
        </a> -->
        <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
            <div class="relative">
                <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                    <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="avatar" srcset="">
                    <div class="flex flex-col">
                        <div class="Poppins-Bold break-words text-white" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                    </div>
                </div>
                <div *ngIf='open' (click)='open = true'
                class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">
                    <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 hover:rounded-xl cursor-pointer">
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                    </svg>
                    {{'Logout' | translate}}
                    </span>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #numPlusSideBar>
  <div class="h-full flex flex-col w-1/6 shadow-2xl Poppins-Bold fixed bg-sidebar rounded-e-3xl">
    <img [src]="logo2" class="m-4 w-[160px]" alt="Logo">

    <div class="relative mb-4 mx-4" *ngIf="business$ | async as currentBusiness">
        <div (click)='toggleBussiness = !toggleBussiness' (clickOutside)='toggleBussiness = false' class="cursor-pointer flex flex-row bg-light-gray-1 p-3 px-6 rounded-2xl items-center">
            <div class="Poppins-Bold break-words">{{currentBusiness.businessId.companyName}}</div>
            <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 ms-auto" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
            </svg>
        </div>
        <div *ngIf='toggleBussiness' id="business-account"
            class="bg-white absolute ltr:left-0 rtl:right-0 w-full top-14 max-h-96 overflow-y-auto z-30 rounded-xl dropdown-shadow focus:outline-none">
        <div class="px-4 py-2 border-b border-gray-200 Poppins-Bold">{{'Businesses' | translate}}</div>
        <ng-container *ngIf="businesses$ | async as businesses">
            <span
                *ngFor="let business of businesses"
                [ngClass] = "currentBusiness.businessId?._id === business.businessId?._id ? 'bg-gray-50' : ''"
                class="flex items-center px-4 py-2 text-sm m-2 rounded-full Poppins-Bold hover:bg-gray-50 cursor-pointer"
                (click)='selectBusiness(business)'>{{business.businessId?.companyName}}
            </span>
        </ng-container>
      </div>
    </div>
    <div class="flex flex-row items-center p-2 mx-auto mb-4 text-white">
        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
        </svg>
        <div class="flex items-center" dir='ltr'>
            <span class="me-3 text-sm Poppins-Bold">EN</span>
            <label for="toggle-example" class="flex items-center cursor-pointer relative">
                <input type="checkbox" [(ngModel)]='arabicSelected' (change)='changeLanguage()' id="toggle-example" class="sr-only">
                <div class="toggle-bg bg-green-500 border border-green-500 h-6 w-11 rounded-full"></div>
            </label>
            <span class="ms-3 text-sm Poppins-Bold">AR</span>
        </div>
    </div>

    <div class="flex-1 flex flex-col sidebarContent overflow-y-auto overflow-x-hidden">
      <a routerLink='/plus/budget' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'P/L Budget' | translate}}
      </a>
      <a routerLink='/plus/financedashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'P/L Analysis' | translate}}
      </a>
      <a routerLink='/plus/salesdashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
        <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
        <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
        </svg> -->
        {{'Sales Dashboard' | translate}}
      </a>
        <!-- <a routerLink='/plus/cashflowdashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm"> -->
          <!-- <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span> -->
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg> -->
          <!-- {{'Cash Flow Dashboard' | translate}} -->
        <!-- </a> -->

        <a routerLink='/dashboard' routerLinkActive='primary-active' class="2xl:py-2 py-1 px-4 Poppins-Bold text-gray-300 inline-flex items-center 2xl:text-base text-sm">
          <span class="opacity-0 bg-white w-1 h-10 me-4 rounded-full"></span>
          <!-- <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 me-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
          </svg> -->
          {{'Dashboard' | translate}}
        </a>
    </div>
    <div class="h-16 bottom-0 px-2 py-2 w-full cursor-pointer">
        <div class="relative">
            <div (click)='open = !open' (clickOutside)='open = false' class="flex flex-row border-t border-gray-300 p-2 items-center">
                <img *ngIf="user$ | async as user" [src]="user.profileImageName ? baseURL+user.profileImageName : 'assets/images/avatar.jpg'" onerror="this.src='assets/images/avatar.jpg'" width="40" class="rounded-full me-2" alt="avatar" srcset="">
                <div class="flex flex-col">
                    <div class="Poppins-Bold break-words text-white" *ngIf="user$ | async as user">{{user.firstName}} {{user.lastName}}</div>
                </div>
            </div>
            <div *ngIf='open' (click)='open = true'
            class="bg-white absolute ltr:left-0 rtl:right-0 w-full bottom-20 rounded-xl dropdown-shadow focus:outline-none">
                <span (click)='logout()' class="flex items-center px-4 py-2 text-sm Poppins-Bold hover:bg-gray-50 hover:rounded-xl cursor-pointer">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-4 w-4 me-2" viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3 3a1 1 0 00-1 1v12a1 1 0 102 0V4a1 1 0 00-1-1zm10.293 9.293a1 1 0 001.414 1.414l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L14.586 9H7a1 1 0 100 2h7.586l-1.293 1.293z" clip-rule="evenodd" />
                </svg>
                {{'Logout' | translate}}
                </span>
            </div>
        </div>
    </div>
</div>

</ng-template>

