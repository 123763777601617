import { Action, createReducer, on } from '@ngrx/store';
import { IBusiness } from '../models/business';
import * as BusinessActions from '../actions/business.actions';
import * as UserActions from '../actions/user.actions';

export interface BusinessState {
    business: IBusiness;
}

export const initialState: BusinessState = {
    business: {
        businessId: {
            _id: '',
            companyName: ''
        },
        _id: ''
    }
};

export const BusinessReducer = createReducer(initialState,
    on(BusinessActions.SetBusiness, (state: BusinessState, {business}) => ({...state, business})),
    on(UserActions.UserLogout, () => ({...initialState}))
);

export function reducer(state: BusinessState | undefined, action: Action): any {
    return BusinessReducer(state, action);
}

export const getBusiness = (state: BusinessState) => state.business;
