import { Component, Input, OnInit } from '@angular/core';
import { InvoiceCustomizationService } from '../../services/invoice-customization.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-invoice-settings',
  templateUrl: './invoice-settings.component.html',
})
export class InvoiceSettingsComponent {
  @Input() formGroup = new FormGroup({});
}
