import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';
import { ClickOutsideModule } from 'ng-click-outside';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { BudgetListComponent } from './budget-list/budget-list.component';
import { BudgetViewComponent } from './budget-view/budget-view.component';
import { CreateBudgetComponent } from './create-budget/create-budget.component';

const routes: Routes = [
  {
    path: '',
    component: BudgetListComponent,
  },
  {
    path: 'create-budget',
    component: CreateBudgetComponent,
  },
  {
    path: 'edit-budget/:id',
    component: CreateBudgetComponent,
  },
  {
    path: 'view-budget/:id',
    component: BudgetViewComponent,
  },
];

@NgModule({
  declarations: [
    BudgetListComponent,
    CreateBudgetComponent,
    BudgetViewComponent,
  ],
  imports: [
    CommonModule,
    ClickOutsideModule,
    SharedModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    NgSelectModule,
    DirectivesModule,
    RouterModule.forChild(routes),
  ],
})
export class BudgetModule {}
