import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from '../../services/accounting.service';

@Component({
  selector: 'app-create-tax',
  templateUrl: './create-tax.component.html',
})
export class CreateTaxComponent implements OnInit {
  constructor(
    private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private accountingService: AccountingService,
    private spinner: NgxSpinnerService,
    private toaster: ToastrService
  ) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }

  taxform: FormGroup;
  @Output() close = new EventEmitter();
  @Input() taxId = 0;
  response = '';
  error = '';
  business$: Observable<any>;
  businessId = null;
  update = false;
  unsubscribe$ = new Subject();
  formErrors = {
    taxName: '',
    abbreviation: '',
    description: '',
    taxNumber: '',
    taxRate: '',
    account: '',
  };
  formErrorMessages = {
    taxName: {
      required: 'Taxname is required',
    },
    abbreviation: {
      required: 'abbreviation is required',
    },
    description: {
      required: 'Description is required',
    },
    taxNumber: {
      required: 'Tax number is required',
    },
    taxRate: {
      required: 'Taxrate is required',
    },
    account: {
      required: 'Account is required',
    },
  };

  ngOnInit(): void {
    this.loadForm();
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      this.businessId = business.businessId._id;
      if (this.taxId) {
        this.spinner.show();
        this.accountingService.getTax(this.taxId).subscribe(
          (resp) => {
            this.update = true;
            this.spinner.hide();
            this.loadForm(resp.data);
            console.log(resp);
          },
          (error) => {
            this.spinner.hide();
            console.log(error);
          }
        );
      }
    });
  }

  loadForm(data?): void {
    this.taxform = this.fb.group({
      taxName: [data?.taxName ? data?.taxName : '', [Validators.required]],
      abbreviation: [
        data?.abbreviation ? data?.abbreviation : '',
        [Validators.required],
      ],
      description: [
        data?.description ? data?.description : '',
        [Validators.required],
      ],
      taxRate: [data?.taxRate ? data?.taxRate : '', [Validators.required]],
      account: [data?.account ? data?.account : '', [Validators.required]],
    });
    this.taxform.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.taxform,
        { ...this.formErrors },
        this.formErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.taxform,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }

  createTax(): void {
    console.log(this.taxform.valid, this.taxform.value);

    if (this.taxform.invalid) {
      this.taxform.markAllAsTouched();
      this.formErrors = valueChanges(
        this.taxform,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    const body = {
      businessId: this.businessId,
      ...this.taxform.value,
    };
    this.spinner.show();
    this.accountingService.createSalesTax(body).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.status === 200) {
          this.updateAccountsInStore();
          this.toaster.success('Tax saved sucessfully');
          this.taxform.reset();
          this.close.emit();
        } else {
          this.toaster.error('Something went wrong');
        }
      },
      (error) => {
        this.toaster.error(error?.error?.message || 'Internal server error');
        this.spinner.hide();
      }
    );
  }

  updateTax(): void {
    this.error = '';
    this.response = '';
    if (this.taxform.invalid) {
      this.taxform.markAllAsTouched();
      this.formErrors = valueChanges(
        this.taxform,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }

    this.spinner.show();
    const body = {
      _id: this.taxId,
      ...this.taxform.value,
    };
    this.accountingService.updateSalesTax(body).subscribe(
      (updatedBusiness) => {
        this.spinner.hide();
        this.toaster.success('Tax updated sucessfully');
        this.updateAccountsInStore();
        this.taxform.reset();
        this.close.emit();
      },
      (error) => {
        this.toaster.error(error?.error?.message || 'Internal server error');
        this.spinner.hide();
      }
    );
  }

  changeService(event) {
    console.log(event);
  }

  updateAccountsInStore(): void {
    this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
      if (resp.success) {
        this.accountingService.setAccountsInStore(resp.data);
      }
    });
  }
}
