import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { select, Store } from '@ngrx/store';
import { getAllMonths, getDates } from 'src/app/shared/utils/monthDateData';
import { RootReducerState } from 'src/app/store/reducers';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { BusinessService } from 'src/app/shared/services/business.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-date-and-currency',
  templateUrl: './date-and-currency.component.html',
  styleUrls: ['date-and-currency.component.scss']
})
export class DateAndCurrencyComponent implements OnInit, OnDestroy {
  constructor(
    private fb: FormBuilder,
    private store: Store<RootReducerState>,
    private businessService: BusinessService,
    private spinner: NgxSpinnerService
  ) {
    this.businesses$ = this.store.pipe(select(selectBusiness));
    const today = new Date();
  }
  fiscalform: FormGroup;
  error = '';
  response = '';
  currencySelect;
  dates;
  businesses$: Observable<any>;
  unsubscribe$ = new Subject();
  businessId = null;
  update = false;
  loginRespMessage = '';

  formErrors = {
    month: '',
    date: '',
  };

  formErrorMessages = {
    month: {
      required: 'Month is required',
    },
    date: {
      required: 'Date is required',
    },
  };
  combinedDate;
  selectedMonth: number;
  selectedDay: number;
  currentMonth: number;
  months = [
    'January', 'February', 'March', 'April', 'May', 'June',
    'July', 'August', 'September', 'October', 'November', 'December'
  ];

  days = Array.from({ length: 31 }, (_, i) => i + 1);
  isPickerOpen: boolean = false;

  ngOnInit(): void {
    this.loadForm();
    this.updateDays();
    this.businesses$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        if (business?.businessId?._id) {
          (this.businessId = business.businessId._id),
            (this.currencySelect = business?.businessId?.currencySymbol);
          console.log(business?.businessId?.currencySymbol);
          this.selectedDay = business?.businessId?.accounting?.date;
          console.log("business?.accounting", this.fiscalform.value.month);

          this.loadForm(business.businessId);
          this.currentMonth = this.months.indexOf(business?.businessId?.accounting?.month)+1;
          this.dates = getDates(this.months[this.currentMonth]);

          this.updateDays();
          console.log("selected1", this.currentMonth, this.selectedDay);
        }
      });
  }

  loadForm(business?): void {
    this.fiscalform = this.fb.group({
      month: [
        business?.accounting?.month ? business.accounting?.month : '',
        [Validators.required],
      ],
      date: [
        business?.accounting?.date ? business.accounting?.date : '',
        [Validators.required],
      ],
    });
    this.fiscalform.valueChanges.subscribe((data) => {
      this.formErrors = valueChanges(
        this.fiscalform,
        { ...this.formErrors },
        this.formErrorMessages
      );
    });
    this.formErrors = valueChanges(
      this.fiscalform,
      { ...this.formErrors },
      this.formErrorMessages
    );
  }

  chnageMonthEvent(event): void {
    const { text } = event;
    const result = getDates(text);
    this.dates = result;
    this.fiscalform.get('date').setValue('1');
  }

  addFiscal(): void {
    this.error = '';
    this.response = '';
    if (this.fiscalform.invalid) {
      this.fiscalform.markAllAsTouched();
      this.formErrors = valueChanges(
        this.fiscalform,
        { ...this.formErrors },
        this.formErrorMessages
      );
      return;
    }
    console.log('huss', this.fiscalform.value);
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      accounting: {
        month: this.fiscalform.value.month,
        date: this.fiscalform.value.date,
      },
      ...this.fiscalform.value,
    };
    this.businessService.updateBusiness(body).subscribe(
      (updatedBusiness) => {
        this.spinner.hide();
        this.businessService.updateBusinessInStore(updatedBusiness.data);
        this.response = 'Date and Month update sucessful';
        this.isPickerOpen = false;
        setTimeout(() => {
          this.response = '';
        }, 2000);
      },
      (error) => {
        this.error = error?.error?.message || 'Internal Server Error';
        this.spinner.hide();
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  combinedDateChange(value): void {
    this.combinedDate = value;
  }
  
  changeMonth(direction: number) {
    this.currentMonth = (this.currentMonth + direction + 12) % 12;
    this.updateDays();
  }

  selectDay(day: number) {
    this.selectedDay = day;
    console.log("selectedM", this.currentMonth);
    
    this.fiscalform.get('month').setValue(this.months[this.currentMonth-1]);
    this.fiscalform.get('date').setValue(this.selectedDay);
    this.addFiscal();
  }

  togglePicker() {
    this.isPickerOpen = !this.isPickerOpen;
  }

  updateDays() {
    const daysInMonth = new Date(new Date().getFullYear(), this.currentMonth + 1, 0).getDate();
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }
}
