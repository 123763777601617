<div
  class="w-full flex flex-col relative h-full new-design-round-border p-4 gap-3"
>
  <!-- max-h-[14.1rem] -->
  <div class="flex items-center justify-start ml-4">
    <p class="text-sm">
      {{ "Businesses" | translate }}
    </p>
  </div>
  <table class="w-full text-xs text-left rtl:text-right">
    <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
      <tr>
        <td *ngFor="let column of tableHeadings" scope="col" class="px-4 py-2">
          {{ column.name | translate }}
        </td>
        <td scope="col" class="px-4 py-2">
          {{ "Actions" | translate }}
        </td>
      </tr>
    </thead>
    <ng-container *ngIf="businesses$ | async as tableData">
      <ng-container *ngIf="tableData.length; else noData">
        <tbody>
          <tr *ngFor="let row of tableData; trackBy: row?._id" class="bg-white">
            <td *ngFor="let key of tableHeadings" class="px-6 py-4">
              {{ row?.businessId[key.key] | empty : "-" }}
            </td>
            <td class="pl-6 py-4">
              <app-dropdown #dropdown [isFromSettings]="true">
                <div
                  button
                  (click)="dropdown.open = true"
                  (clickOutside)="dropdown.open = false"
                >
                  <div
                    class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                  >
                  <svg
                          xmlns="http://www.w3.org/2000/svg"
                          class="h-4 w-4"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M19 9l-7 7-7-7"
                          />
                        </svg>
                  </div>
                </div>
                <div options class="new-shadow p-1">
                  <div
                    (click)="editBusiness.emit(row?._id)"
                    class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                  >
                    {{ "Edit" | translate }}
                  </div>
                </div>
              </app-dropdown>
            </td>
          </tr>
        </tbody>
      </ng-container>
    </ng-container>
  </table>
</div>
<ng-template #noData class="flex items-center justify-center">
  <tbody>
    <tr>
      <td class="pt-8 text-center" colspan="5">
        {{ "No Data to Show" | translate }}
      </td>
    </tr>
  </tbody>
</ng-template>
