import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { StorageService } from '../../storage.service';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { takeUntil } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'app-folder-listing',
  templateUrl: './folder-listing.component.html',
  styleUrls: ['./folder-listing.component.scss']
})
export class FolderListingComponent implements OnInit, OnDestroy {

  folderColorMap = {
    0: '#C9D8FC',
    1: '#FAE686',
    2: '#AAD9C8',
    3: '#F4ADB6',
    4: '#F4BB81',
  }

  unsubscribe$ = new Subject();
  business$: Observable<any>;

  listView: boolean = false;
  businessId;
  folderData = [];
  statisticsData: {
    totalStorage: number | string,
    usedStorage: number | string,
    remainingStorage: number | string,
    totalFiles: number | string,
    usedPercentage: number | string
  } = {
    totalStorage: 0,
    usedStorage: 0,
    remainingStorage: 0,
    totalFiles: 0,
    usedPercentage: 0
  };

  sections = ['sales', 'purchase', 'inventory', 'accounting', 'project', 'business'];

  subsections = {
    'sales': ['Estimate', 'Regular Invoice', 'Cash Invoice', 'Recurring Invoice', 'Receipt', 'Refund']
  }

  constructor(
    private storageService: StorageService,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private toastr: ToastrService,
    private router: Router
  ) 
  {
    this.business$ = store.pipe(select(selectBusiness))
  }

  ngOnInit(): void {
    this.loadBusiness();
  }

  loadBusiness(): void {
    this.business$.pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(business => {
      this.businessId = business?.businessId?._id;      
      if(this.businessId){
        this.loadStatisticsData();
        this.loadFolderData();
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    })

  }

  loadStatisticsData(): void {
    this.storageService.getStatisticsData(this.businessId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.statisticsData = {
          totalStorage: ((resp?.data?.totalStorage)/ (Math.pow(1024, 3)))?.toFixed(2),
          usedStorage: ((resp?.data?.usedStorage)/ (Math.pow(1024, 3)))?.toFixed(2),
          remainingStorage: ((resp?.data?.remainingStorage)/ (Math.pow(1024, 3)))?.toFixed(2),
          totalFiles: resp?.data?.totalFiles,
          usedPercentage: ((resp?.data?.usedStorage / resp?.data?.totalStorage)*100)?.toFixed(2)
        };
      }
      else{
        this.toastr.error(resp?.message);
      }
    }, (err) => {
      this.toastr.error(err?.error?.message);
    })
  }

  loadFolderData(): void {
    this.spinner.show();
    this.storageService.getAllSectionData(this.businessId)
    .pipe(
      takeUntil(this.unsubscribe$)
    )
    .subscribe(resp => {
      if(resp?.success){
        this.folderData = resp?.data;
      }
      else{
        this.toastr.error(resp?.message);
      }
      this.spinner.hide();
    }, (err) => {
      this.toastr.error(err?.error?.message);
      this.spinner.hide()
    })
  }

  getFillColor(index: number): string {
    return index > 4 ? this.folderColorMap[index%4] : this.folderColorMap[index] 
  }

  showFilesForSection(docSectionType: string): void {
    console.log(docSectionType);
    this.router.navigate([`/storage/folder/${docSectionType}`]);
    
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

}
