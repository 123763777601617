<div
  class="rounded-lg p-4 border-[1px] border-solid border-[#E6E3E3CC] bg-[#D9D9D900] flex gap-4"
>
  <div class="text-sm text-[#1C1C1C66] mt-2">
    {{ "Send as" | translate }}
  </div>
  <form [formGroup]="mailConfigForm" class="w-full">
    <div class="flex flex-col gap-2 w-full">
      <div
        class="text-sm flex w-full items-center rounded-lg bg-input-light px-4 py-2"
      >
        <fieldset [disabled]="configMail?.isVerified" class="w-full">
          <input
            id="newEmail"
            formControlName="email"
            type="text"
            class="border-0 w-full rounded-lg text-sm bg-transparent flex items-end content-end p-0 flex-wrap gap-4 focus:ring-0 placeholder-gray-200"
            placeholder="{{ 'Add new email' | translate }}"
          />
        </fieldset>
        <span
          class="text-base cursor-pointer"
          (click)="addNewMail()"
          *ngIf="!configMail?.email"
        >
          +
        </span>
        <span
          class="text-base cursor-pointer Poppins-Medium"
          (click)="editMail()"
          *ngIf="configMail?.email && !configMail?.isVerified"
        >
          <img src="assets/images/edit.svg" alt="" />
        </span>
      </div>
      <p class="text-xxs text-[#1C1C1C66] py-2">
        {{ "Mailing config message" | translate }}
      </p>
      <table *ngIf="configMail?.email" class="text-xxs table-fixed">
        <tr>
          <td class="w-[35%]">
            <div
              class="flex items-center whitespace-nowrap justify-center p-2 ltr:mr-2 rtl:ml-2 rounded-md bg-[#F6F6F6]"
              [ngClass]="
                configMail.isVerified ? 'text-[#12B76A]' : 'text-[#F04438]'
              "
            >
              {{
                configMail.isVerified
                  ? "Connected"
                  : ("Not Connected" | titlecase)
              }}
            </div>
          </td>
          <td style="word-break: break-word">
            <div class="text-xxs text-[#1C1C1C66]">
              {{ configMail.email }}
            </div>
          </td>
          <td *ngIf="configMail.isVerified">
            <div class="flex">
              <button (click)="disconnectEmailing()">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" height="48px" viewBox="0 -960 960 960" width="48px" fill="#1c1c1c66"><path d="m750-291-49-49q51-10 85-48t34-89q0-58-41-99t-99-41H525v-60h155q83 0 141.5 58.5T880-477q0 62-36 112t-94 74ZM594-447l-60-60h101v60h-41ZM814-56 63-807l43-43L857-99l-43 43ZM450-280H280q-83 0-141.5-58.5T80-480q0-72 44.5-127T238-676l56 56h-14q-58 0-99 41t-41 99q0 58 41 99t99 41h170v60ZM325-450v-60h79l60 60H325Z"/></svg>
              </button>
            </div>
          </td>
          <td *ngIf="!configMail.isVerified">
            <div class="flex">
              <button (click)="verifyMailingId()">
                <svg xmlns="http://www.w3.org/2000/svg" class="w-6 h-6" height="48px" viewBox="0 -960 960 960" width="48px" fill="#1c1c1c66"><path d="m421-298 283-283-46-45-237 237-120-120-45 45 165 166Zm59 218q-82 0-155-31.5t-127.5-86Q143-252 111.5-325T80-480q0-83 31.5-156t86-127Q252-817 325-848.5T480-880q83 0 156 31.5T763-763q54 54 85.5 127T880-480q0 82-31.5 155T763-197.5q-54 54.5-127 86T480-80Zm0-60q142 0 241-99.5T820-480q0-142-99-241t-241-99q-141 0-240.5 99T140-480q0 141 99.5 240.5T480-140Zm0-340Z"/></svg>
              </button>
            </div>
          </td>
        </tr>
      </table>
    </div>
  </form>

  <!-- <div
    class="rounded-lg p-4 border-[1px] border-solid border-[#E6E3E3CC] bg-[#D9D9D900]"
  >
    <span class="flex items-center">
      <h3 class="Poppins-Bold text-lg">{{ "Mailing Config" | translate }}</h3>
    </span>
    <p class="mt-2 Gilory-Medium">{{ "Mailing config message" | translate }}</p>
    <div
      *ngIf="configMail?.email"
      class="flex justify-between mt-6 min-w-full border-t pt-2"
    >
      <div>
        <span class="Poppins-Bold me-2"
          >{{ "Email Address" | translate }}:</span
        >
        <span>{{ configMail.email }}</span>
      </div>
      <app-button
        *ngIf="configMail?.isVerified"
        (handleClick)="disconnectEmailing()"
        type="danger"
        buttonText="Disconnect"
        [rounded]="true"
      >
      </app-button>
      <div class="flex space-x-2">
        <app-button
          (handleClick)="editMail()"
          *ngIf="!configMail?.isVerified"
          type="primary"
          buttonText="Edit"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5 mx-auto"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
            />
          </svg>
        </app-button>
        <app-button
          *ngIf="!configMail?.isVerified"
          (handleClick)="verifyMailingId()"
          type="primary"
          buttonText="Check"
          [rounded]="true"
        >
        </app-button>
      </div>
    </div>
    <div class="mt-6">
      <button
        *ngIf="!addNewEmail && !configMail?.email"
        (click)="addNewEmail = true"
        id="add"
        class="flex items-center Poppins-Bold space-x-4 rtl:space-x-reverse"
      >
        <span class="rounded-full button-new-class text-white p-1 me-2">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            class="h-5 w-5"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M12 6v6m0 0v6m0-6h6m-6 0H6"
            />
          </svg>
        </span>
        {{ "Add email for sending mail" | translate }}
      </button>
      <div
        [formGroup]="mailConfigForm"
        *ngIf="addNewEmail"
        class="flex flex-col border-t border-gray-300 space-y-4 pt-4 w-full lg:w-1/2"
      >
        <div class="custom-input-box-shadow rounded-xl bg-white px-3 py-2">
          <label for="email" class="block text-sm Poppins-Bold font-color-05">{{
            "Email Address" | translate
          }}</label>
          <input
            formControlName="email"
            type="email"
            class="w-full p-0 border-0 focus:ring-0 placeholder-gray-200"
            placeholder="{{ 'Enter email' | translate }}"
          />
        </div>
        <div class="flex gap-x-2">
          <app-button
            buttonText="{{ 'Add' | translate }}"
            type="primary"
            (handleClick)="addNewMail()"
          >
          </app-button>
          <app-button
            buttonText="{{ 'Cancel' | translate }}"
            type="third"
            (handleClick)="addNewEmail = false"
          >
          </app-button>
        </div>
      </div>
    </div>
  </div> -->
</div>
