import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpertService } from '../../expert.service';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { Store, select } from '@ngrx/store';
import { RootReducerState } from 'src/app/store/reducers';

@Component({
  selector: 'app-enquiry-history',
  templateUrl: './enquiry-history.component.html',
  styleUrls: ['./enquiry-history.component.scss'],
})
export class EnquiryHistoryComponent implements OnInit {
  isOpen: boolean = false;
  openFilterModal = false;
  enquiriesList: any;
  originalEnquiriesList: any;
  finalData: any;
  userId: any;
  user$: Observable<any>;
  unsubscribe$ = new Subject();
  values = {
    accounting: false,
    bookkeeping: false,
    payroll: false,
    internal: false,
    external: false,
    sales: false,
    income: false,
    financial: false,
    under: false,
    graduate: false,
    post: false,
    global: false,
    jo: false,
    sa: false,
  };
  countryFilters = [];
  specialisationFilters = [];
  qualificationFilters = [];

  constructor(
    private expertService: ExpertService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private store: Store<RootReducerState>
  ) {
    this.user$ = this.store.pipe(select(selectUser));
  }

  ngOnInit(): void {
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userId = user._id;
    });
    this.allEnquiries();
  }

  allEnquiries(): void {
    this.spinner.show();
    console.log("this.userId", this.userId);
    
    this.expertService.getAllEnquiriesLambda({ id: this.userId }).subscribe(
      (result) => {
        this.enquiriesList = result.data;
        this.finalData = JSON.parse(JSON.stringify(result.data))
        this.originalEnquiriesList = JSON.parse(JSON.stringify(result.data))
        console.log('test', this.enquiriesList);
        this.spinner.hide();
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  openRow(): void {
    this.isOpen = !this.isOpen;
  }

  openFilter(): void {
    this.openFilterModal = true;
  }

  selectOption(option: string, type: string): void {
    this.finalData = JSON.parse(JSON.stringify(this.originalEnquiriesList));
    const filters =
      type === 'specialisation'
        ? this.specialisationFilters
        : type === 'qualification'
        ? this.qualificationFilters
        : this.countryFilters;
    if (!filters.includes(option)) {
      filters.push(option);
    } else {
      const index = filters.indexOf(option);
      filters.splice(index, 1);
    }

    if(this.specialisationFilters.length === 0 && this.qualificationFilters.length === 0 && this.countryFilters.length === 0){
      this.finalData = JSON.parse(JSON.stringify(this.originalEnquiriesList));
    } else {
      this.finalData = this.originalEnquiriesList.filter((k) => {
        return this.countryFilters.includes(k.region) || k.specialization?.some((e) => this.specialisationFilters.includes(e.name)) || k.qualification?.some((e) => this.qualificationFilters.includes(e.name))
      });
    }
  }

  closeFilterForm(): void {
    this.enquiriesList = JSON.parse(JSON.stringify(this.originalEnquiriesList));
    for (var key of Object.keys(this.values)) {
      this.values[key] = false
  }
    this.openFilterModal = false;
  }

  submitFilterForm(): void {
    this.enquiriesList = JSON.parse(JSON.stringify(this.finalData));
    this.openFilterModal = false;
  }
}
