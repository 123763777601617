<div subscriptionCheck featureToCheck="budget" class="flex min-h-screen p-8">
  <div class="bg-white w-full min-h-screen p-8 rounded-lg">
    <router-outlet></router-outlet>
  </div>
</div>

<div
  routeToSubscription
  fromComponent="budget"
  class="hidden absolute bottom-[40%] z-[29] w-[456px] left-0 right-0 m-auto"
>
  <app-send-to-sub from="budget"></app-send-to-sub>
</div>
