import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ExpertService } from 'src/app/modules/experts/expert.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { environment } from 'src/environments/environment';
import { CountryCodes } from './countryCodeList';
import { ToastrService } from 'ngx-toastr';
import { RootReducerState } from 'src/app/store/reducers';
import { Store, select } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { selectExpertDetails } from 'src/app/modules/experts/state/selectors/expertDetails.selector';
// import { expertDetailsState } from 'src/app/modules/experts/state/reducers';

@Component({
  selector: 'app-expert-details',
  templateUrl: './expert-details.component.html',
  styleUrls: ['./expert-details.component.scss'],
})
export class ExpertDetailsComponent implements OnInit {
  openEnquiryModal = false;
  expertDetail: any;
  environmentPath = 'https://numetric-expert.s3.us-east-2.amazonaws.com/';
  enquiryForm: FormGroup;
  enquiryFormErrors = {
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    code: '',
    description: '',
  };

  enquiryFormErrorMessages = {
    firstName: {
      required: 'Field is required',
    },
    lastName: {
      required: 'Field is required',
    },
    email: {
      required: 'Field is required',
      pattern: 'Email must be in correct format',
    },
    phone: {
      required: 'Field is required',
    },
    code: {
      required: 'Field is required',
    },
    description: {
      required: 'Field is required',
    },
  };
  countryCodeList = CountryCodes.countries;
  expertId: string;
  userDetails: any;
  user$: Observable<any>;
  expertDetail$: Observable<any>;
  unsubscribe$ = new Subject();
  expertBadge: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private expertService: ExpertService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private toster: ToastrService,
    private store: Store<RootReducerState>,
    private rootStore: Store<any>,
    private router: Router
  ) {
    this.user$ = this.store.pipe(select(selectUser));
    this.expertDetail$ = this.rootStore.pipe(select(selectExpertDetails));
  }

  ngOnInit(): void {
    this.expertId = this.activatedRoute.snapshot.params.id;
    // this.spinner.show();
    // this.getExpertDetails(this.expertId);
    this.loadAddMoreForm();
    this.user$.pipe(takeUntil(this.unsubscribe$)).subscribe((user) => {
      this.userDetails = user;
      console.log('this.userDetails', this.userDetails);
    });
    this.expertDetail$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((expert) => {
        console.log('check1', expert);
        if (expert.expertDetails === '') {
          this.router.navigate(['/experts/hire-expert']);
        }
        console.log('this.userId', this.expertId);

        this.expertDetail = expert?.expertDetails?.filter(
          (item) => item.numetricUserId === this.expertId
        )[0];
        console.log(this.expertDetail);

        this.expertBadge =
          this.expertDetail.expertType === 'Silver'
            ? 'ExpertSilver'
            : this.expertDetail.expertType === 'Platinum'
            ? 'ExpertPlatinum'
            : this.expertDetail.expertType === 'Gold'
            ? 'ExpertGold'
            : 'ExpertElite';
      });
  }

  loadAddMoreForm(): void {
    this.enquiryForm = this.fb.group({
      firstName: [{value: '', disabled: true}, [Validators.required]],
      lastName: [{value: '', disabled: true}, [Validators.required]],
      code: [null, [Validators.required]],
      phone: [null, [Validators.required]],
      email: [
        {value: '', disabled: true},
        [
          Validators.required,
          Validators.pattern(
            /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ),
        ],
      ],
      description: [null, [Validators.required]],
    });

    this.enquiryForm.valueChanges.subscribe(() => {
      this.enquiryFormErrors = valueChanges(
        this.enquiryForm,
        { ...this.enquiryFormErrors },
        this.enquiryFormErrorMessages,
        this.translateService
      );
    });

    this.onEnquiryChanges();
  }

  getExpertDetails(id: string): void {
    this.expertService.getExpertDetails(id).subscribe(
      (result) => {
        this.expertDetail = result.data;
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
  }

  enquiryNow(): void {
    this.enquiryForm.reset();
    this.enquiryForm.get('firstName').setValue(this.userDetails?.firstName);
    this.enquiryForm.get('lastName').setValue(this.userDetails?.lastName);
    this.enquiryForm.get('email').setValue(this.userDetails?.email);
    this.openEnquiryModal = true;
  }

  closeModal(): void {
    this.enquiryForm.reset();
    this.openEnquiryModal = false;
  }

  submitEnquiry(): void {
    if (this.enquiryForm.invalid) {
      this.enquiryForm.markAllAsTouched();
      this.onEnquiryChanges();
      return;
    }
    this.spinner.show();

    let data = { ...this.enquiryForm.getRawValue() };
    data.expertId = this.expertDetail._id;
    data.numetricUserId = this.userDetails._id;
    data.expertDetails = {
      name: this.expertDetail.firstName + ' ' + this.expertDetail.lastName,
      email: this.expertDetail.email,
      contactNumber: this.expertDetail.contactNumber,
      description: this.expertDetail.description,
    };

    this.expertService.submitEnquiryLambda(data).subscribe(
      (result) => {
        this.spinner.hide();
        this.toster.success('Enquiry has been submitted');
        console.log('submit', result);
      },
      (error) => {
        this.spinner.hide();
        this.toster.error(
          this.translateService.instant('Something went wrong!')
        );
      }
    );
    this.openEnquiryModal = false;
  }

  onEnquiryChanges(): void {
    this.enquiryFormErrors = valueChanges(
      this.enquiryForm,
      { ...this.enquiryFormErrors },
      this.enquiryFormErrorMessages,
      this.translateService
    );
  }

  badgeType(type: string): string {
    const expertBadge =
      type === 'Silver'
        ? 'ExpertSilver'
        : type === 'Platinum'
        ? 'ExpertPlatinum'
        : type === 'Gold'
        ? 'ExpertGold'
        : 'ExpertElite';
    return expertBadge;
  }
}
