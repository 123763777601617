import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { SalesService } from 'src/app/modules/sales/sales.service';
import { selectCustomers } from 'src/app/modules/sales/state/selectors/customers.selector';
import { NumberService } from 'src/app/shared/services/number.service';
import { dateLessThan, DateValidator, valueChanges } from 'src/app/shared/utils/formValidator';
import { updateCurrentTransactionLimit } from 'src/app/store/actions/usage.action';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectProjects } from 'src/app/store/selectors/project.selector';
import { selectUsage } from 'src/app/store/selectors/usage.selector';
import { InvoicesService } from '../../invoices.servcies';
import { FileUploadService } from 'src/app/shared/services/file-upload.service';

@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit, OnDestroy {
  constructor(private invoiceService: InvoicesService,
              private store: Store<RootReducerState>,
              private salesService: SalesService,
              private router: Router,
              private route: ActivatedRoute,
              private spinner: NgxSpinnerService,
              private toastr: ToastrService,
              private translateService: TranslateService,
              private numberService: NumberService,
              private fileUploadService: FileUploadService,
              private fb: FormBuilder) {
                this.customers$ = this.store.pipe(select(selectCustomers));
                this.projects$ = this.store.pipe(select(selectProjects));
                this.usage$ = store.pipe(select(selectUsage));
                this.business$ = store.pipe(select(selectBusiness));
              }
  page = 1;
  tableData = [];
  unfilteredList = [];
  filterStatus = 'all';

  number = '1.2-2';
  currencyDetails = {
    currency: '',
    currencySymbol: ''
  };
  transactionLimit;

  unsubscribe$ = new Subject();
  business$: Observable<any>;
  customers$: Observable<any>;
  projects$: Observable<any>;
  usage$: Observable<any>;
  customers = [];
  projects = [];
  invoiceStats;
  files: File[] = [];
  selectedTab = 'all'


  filterForm: FormGroup;
  formErrors = {
    fromDate  : '',
    endDate: ''
  };

  formErrorMessages = {
    fromDate: {
      required: 'Date is Required',
      invalidDate: 'Invalid Date'
    },
    endDate: {
      required: 'Due Date is Required',
      dates: 'Invalid Date',
      invalidDate: 'Invalid Date'
    }
  };

  recordPaymentModal = false;
  amountDue = null;
  recordPaymentFor = null;
  minDate = null;
  currentPage = 1;
  invoiceType = 'all';
  totalCount = 0;

  selectOption(option): void {
    console.log(option);
  }

  ngOnInit(): void {
    this.getTransactionLimit();
    this.getCurrencyDetails();
    this.getInvoiceStats();
    this.loadCustomers();
    this.loadProjects();
    this.loadFilterForm();
    this.loadNumberConfig();
    this.loadInvoices();
  }

  loadNumberConfig(): void {
    this.numberService.decimalSize
    .pipe((takeUntil(this.unsubscribe$)))
    .subscribe((number) => {
      this.number = `1.${number}-${number}`
    })
  }

  getTransactionLimit(): void {
    this.usage$.subscribe(({currentUsage})=>{
      if(currentUsage){
        const
        { transactionLimit } = currentUsage;
        this.transactionLimit = transactionLimit;
      }
    })
  }

  getInvoiceStats(){
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(({businessId}) => {
      const year = moment().format('YYYY');
      const prevYear = moment().subtract(1, 'year').format('YYYY');
      const currentYear = moment(`${businessId?.accounting?.date || '01'} ${businessId?.accounting?.month || 'January'} ${year}`)
      const previousYear = moment(`${businessId?.accounting?.date || '01'} ${businessId?.accounting?.month || 'January'} ${prevYear}`)
      const body = {
        fromDate: previousYear,
        endDate:currentYear
      }
      this.salesService.getInvoiceStats(body)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(resp=>{
        if(resp?.success){
          this.invoiceStats = resp?.data;
          console.log(this.invoiceStats);

        }
      })
    })
  }

  getCurrencyDetails():void {
    this.salesService.currencyDetail
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(details=>{
      if(details)
      this.currencyDetails = details;
    })
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  loadFilterForm(): void {
    this.filterForm = this.fb.group({
      projectId: [],
      customerId: [],
      fromDate: [null, DateValidator()],
      endDate: [null, DateValidator()]
    }, {validator: dateLessThan('fromDate', 'endDate')});

    this.filterForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages, this.translateService);
  }


  loadCustomers(): void {
    this.customers$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((customers) => {
      this.customers = customers?.filter(customer => customer?.isValidate);
    },()=>{
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  loadProjects(): void {
    this.projects$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((projects) => {
      this.projects = projects;
    },()=>{
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  loadInvoices(): void {
    this.spinner.show();
    this.salesService.businessId$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((id) => {
      if (id) {
        this.invoiceService.getInvoices(this.currentPage, this.invoiceType).subscribe(resp => {
          this.spinner.hide();
          if (resp.status===200) {
            if( resp?.data?.invoiceData?.length > 0){
              this.tableData = this.mapInvoiceListForTable(resp.data.invoiceData);
              this.totalCount = resp.data.totalInvoiceCount;
              console.log("totalCount0", this.totalCount)
              this.unfilteredList = [...resp?.data.invoiceData];
            } else {
              this.tableData = [];
              this.totalCount = 0;
              console.log("totalCount0", this.totalCount)
              this.unfilteredList = [];
            }
          }
        },()=>{
          console.log("errirrrrrrererer")
          this.spinner.hide();
          this.toastr.error(this.translateService.instant('Something went wrong!'));
        });
      }
    });
  }

  updateInvoice(id): void {
    this.router.navigate(['/sales/invoices/create-invoice'], { queryParams: { id } });
  }

  viewInvoice(id): void {
    this.router.navigate(['/sales/invoices/view-invoice'], { queryParams: { id } });
  }

  deleteInvoice(id): void {
    this.spinner.show();
    this.invoiceService.deleteInvoice(id).subscribe((resp) => {
      if(resp?.success){
        this.tableData = this.tableData.filter(({_id}) => _id !== id);
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit - 1}))
      }
      this.spinner.hide();
    }, error => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });
  }

  mapInvoiceListForTable(invoiceList): Array<object> {
    return [...invoiceList.map(invoice => ({
      ...invoice,
      totalAmount: this.numberService.currencier(invoice?.totalAmount),
      customerName: invoice?.customerDetails?.customerId?.customerName,
      projectName: invoice?.projectDetails?.projectId?.projectName,
      invoiceDate: moment(invoice?.invoiceDate?.split('T')[0]).format('DD-MM-YYYY'),
      dueDate: moment(invoice?.dueDate?.split('T')[0]).format('DD-MM-YYYY'),
      createdAt: moment(invoice?.createdAt?.split('T')[0]).format('DD-MM-YYYY')
    }))];
  }

  filterInvoicesByStatus(statusType: string): void {
    this.selectedTab = statusType;
    this.invoiceType = statusType;
    this.loadInvoices();
    // if (statusType !== 'all') {
    //   const filtered = this.unfilteredList.filter((invoice) => {
    //     if (invoice?.status?.primaryStatus.toLowerCase() ===  statusType.toLowerCase()) {
    //       return invoice;
    //     }
    //   });
    //   this.tableData = this.mapInvoiceListForTable([...filtered]);
    // } else {
    //   this.tableData = this.mapInvoiceListForTable([...this.unfilteredList]);
    // }
  }

  filterData(): void {
    if (this.filterForm.invalid) {
      this.filterForm.markAllAsTouched();
      this.formErrors = valueChanges(this.filterForm, {...this.formErrors}, this.formErrorMessages);
      return;
    }
    const body = this.filterForm.value;
    this.spinner.show();
    this.invoiceService.filterInvoice(body).subscribe((resp) => {
      this.spinner.hide();
      if(resp?.success){
        this.unfilteredList = [...resp.data];
        this.tableData = this.mapInvoiceListForTable(resp.data);
      }
    }, error => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Something went wrong!'));
    });

  }

  openRecordPayment(item): void {
    const invoiceData = this.unfilteredList.find((invoice) => invoice._id === item._id);
    if (invoiceData.dueAmount > 0) {
      this.recordPaymentModal = true;
      this.amountDue = Number(item.dueAmount);
      this.recordPaymentFor = item;
      this.minDate = item.invoiceDate;
    }
  }

  recordPayment(paymentData): void {
    const invoiceData = this.unfilteredList.find((invoice) => invoice._id === this.recordPaymentFor._id);
    const body = {
      _id: invoiceData._id,
      category: `Journal Entry`,
      paymentData,
      businessId: invoiceData?.businessDetails?.businessId?._id,
      journalData: [
        {
          description: 'For customer credit',
          businessId: invoiceData?.businessDetails?.businessId?._id,
          accountId: invoiceData?.customerDetails?.customerId?.accountDetails?.accountId,
          accountName: invoiceData?.customerDetails?.customerId?.accountDetails?.accountName,
          account: invoiceData?.customerDetails?.customerId?.accountDetails?.accountSection,
          debit: 0,
          credit: paymentData.amount,
          type: 'Credit'
        },
        {
          description: 'Payment',
          businessId: invoiceData?.businessDetails?.businessId?._id,
          accountId: paymentData.accountId,
          accountName: paymentData.accountName,
          account: paymentData.account,
          debit: paymentData.amount,
          credit: 0,
          type: 'Debit'
        }
      ]
    };
    console.log(JSON.stringify(body));
    this.spinner.show();
    this.recordPaymentModal = false;
    this.fileUploadService.emitFiles.next(true);
    this.fileUploadService.emitFiles.next(false);
    const formData = new FormData();
    paymentData?.files?.forEach((file, i) => {
      formData.append(`file${i}`, file)
    });
    formData.append('payload', JSON.stringify(body));
    this.invoiceService.recordPayment({formData, _id: body?._id}).subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.unfilteredList = this.unfilteredList.map((invoice) => {
          if (invoice._id === resp.data._id) {
            return { ...resp.data };
          }
          return invoice;
        });
        this.store.dispatch(updateCurrentTransactionLimit({transactionLimit: this.transactionLimit + 1}));
        this.toastr.success('Payment Recorded');
      }
      else{
        this.spinner.hide();
        this.toastr.error(this.translateService.instant('Cannot record payment'));
      }
    }, (error) => {
      this.spinner.hide();
      this.toastr.error(this.translateService.instant('Cannot record payment'));
    });
  };

  pageChange(page): void {
    console.log('parentPage', page)
    this.currentPage = page;
    this.loadInvoices();
  }
}
