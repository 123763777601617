import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RootReducerState } from '../store/reducers';
import { selectBusiness } from '../store/selectors/business.selector';

@Injectable({
  providedIn: 'root'
})
export class AccessControlGuard implements CanActivate {
  constructor(private store: Store<RootReducerState>,
              private router: Router) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }

  business$: Observable<any>;
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    let access = false;
    this.business$.subscribe((business) => {
      if (business?.businessId?._id) {
        return access = true;
      } else {
        return access = false;
      }
    });
    if (!access) { this.router.navigate(['/dashboard']); }
    return access;
  }
}
