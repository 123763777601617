import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DatePipe } from '@angular/common';
import { map } from 'rxjs/operators';
import { SubscriptionService } from '../subscription.service';

@Component({
  selector: 'app-subcription-history',
  templateUrl: './subcription-history.component.html',
  styleUrls: ['./subcription-history.component.scss'],
  providers: [DatePipe]
})
export class SubcriptionHistoryComponent implements OnInit {
  constructor(private subscriptionService: SubscriptionService,
              private spinner: NgxSpinnerService,
              private datePipe: DatePipe, ) { }
  subscriptionHistory = [];
  ngOnInit(): void {
    this.loadSubscriptionPlans();
  }

  loadSubscriptionPlans(): void {
    this.spinner.show();
    this.subscriptionService.getSubscriptionHistoryOfUser()
    .pipe(
      map((result: any) => {
        if (result?.success) {
          this.subscriptionHistory = [];
          const subscriptionHistory = [];
          result.data.forEach(history => {
            history?.subscriptions?.forEach(sub => {
              let item = {
                planName: sub?.planName,
                planPrice: sub?.planPrice,
                date: history?.date,
                expiryDate: history?.expiryDate,
                status: new Date(history?.expiryDate).getTime() > new Date().getTime(),
                invoiceUrl: history?.invoiceUrl
              }
              subscriptionHistory.push(item);
            })
          })
          console.log(subscriptionHistory);
          
          // result.data.map((history: any) => {
          //   if (history.expiryDate) {
          //     history.status = new Date(history.expiryDate).getTime() > new Date().getTime();
          //   }
          //   history.date = history.date ? this.datePipe.transform(history.date, 'yyyy/MM/dd') : history.date;
          //   history.expiryDate = history.expiryDate ? this.datePipe.transform(history.expiryDate, 'yyyy/MM/dd') : history.expiryDate;
          //   return history;
          // });
          const sorted = subscriptionHistory.sort((a: any, b: any) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime());
          result = sorted;
        }
        return result;
      })
    )
    .subscribe((result) => {
      this.spinner.hide();
      this.subscriptionHistory = [...result];
      console.log('ibu', this.subscriptionHistory);
    }, (error) => {
      this.spinner.hide();
      console.log(error);
    });
  }

}
