import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { UserService } from 'src/app/shared/services/user.service';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { UserSignUp } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import {
  selectUserError,
  selectUserLoading,
} from 'src/app/store/selectors/user.selectors';
import { AuthService } from '../../auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-signup-admin-invite',
  templateUrl: './signup-admin-invite.component.html',
  styleUrls: ['./signup-admin-invite.component.scss'],
})
export class SignupAdminInviteComponent implements OnInit {
  signupRespMessage = '';
  signupError$: Observable<any>;
  signupLoading$: Observable<boolean>;

  formErrors = {
    password: '',
  };
  formErrorMessage = {
    password: {
      required: 'Password is required',
      minlength: 'Password must be of minimum 8 characters',
    },
  };
  showPassword = false;

  unsubscribe = new Subject();
  signupForm: FormGroup;
  inviteId: string;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private userService: UserService,
    private store: Store<RootReducerState>,
    private spinner: NgxSpinnerService,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute,
    private toastr: ToastrService
  ) {
    this.signupError$ = this.store.pipe(select(selectUserError));
    this.signupLoading$ = this.store.pipe(select(selectUserLoading));
    this.inviteId = this.activatedRoute.snapshot.params.inviteId;
  }

  ngOnInit(): void {
    // this.getInviteId();
    this.signupLoading$.subscribe((loading) => {
      loading ? this.spinner.show() : this.spinner.hide();
    });
    this.createForm();
  }

  createForm(): void {
    this.signupForm = this.fb.group({
      email: ['', { disabled: true }],
      referralCode: [''],
      password: ['', [Validators.required, Validators.minLength(8)]],
    });
    this.signupForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(
        this.signupForm,
        { ...this.formErrors },
        this.formErrorMessage
      );
    });
  }

  getInviteId(): void {
    this.route.params.pipe(takeUntil(this.unsubscribe)).subscribe((params) => {
      if (params?.inviteId) {
        this.getUserDetails(params?.inviteId);
      }
    });
  }

  getUserDetails(id) {
    this.userService
      .getUserDetailsById(id)
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((resp) => {
        if (resp?.success) {
          //  const { email } = resp?.data;
          //  this.signupForm.patchValue({
          //    email:email
          //  });
          //  this.signupForm.get('email').setValue(email)
        }
      });
  }

  signup(): void {
    if (this.signupForm.invalid) {
      this.signupForm.markAllAsTouched();
      return;
    }
    this.spinner.show();
    if (this.inviteId === 'invitedExpert') {
      this.authService.getInviteExpertUser(this.signupForm.value).subscribe(
        (resp) => {
          console.log('resp', resp);
          if (resp.success && resp.data.length) {
            const data = {
              email: this.signupForm.value.email,
              expertReferralCode: this.signupForm.value.referralCode,
              password: this.signupForm.value.password,
              isInvitedExpert: true,
              expertType: resp.data[0].expertType
            };
            this.authService
              .userSignup(data)
              .pipe(takeUntil(this.unsubscribe))
              .subscribe(
                (resp) => {
                  if (resp?.success) {
                    this.spinner.hide();
                    localStorage.setItem('auth_token', resp?.token);
                    localStorage.setItem('refresh_token', resp?.refreshtoken);
                    this.router.navigate(['/auth/personal-details']);
                  }
                },
                (err) => {
                  console.log("error", err);
                  this.spinner.hide();
                  this.toastr.error(err.error.message);
                }
              );
          } else {
            this.spinner.hide();
            this.toastr.error('Not an invited email for expert');
          }
        },
        (err) => {
          console.log("error", err);
          
          this.spinner.hide();
          this.toastr.error(err.error.message);
        }
      );
    } else {
      this.spinner.show();
      // this.signupForm.get('email').enable();
      const data = {
        email: this.signupForm.value.email,
        expertReferralCode: this.signupForm.value.referralCode,
        password: this.signupForm.value.password,
      };
      this.authService
        .userSignup(data)
        .pipe(takeUntil(this.unsubscribe))
        .subscribe((resp) => {
          if (resp?.success) {
            this.spinner.hide();
            localStorage.setItem('auth_token', resp?.token);
            localStorage.setItem('refresh_token', resp?.refreshtoken);
            this.router.navigate(['/auth/personal-details']);
          }
        });
    }
  }
}
