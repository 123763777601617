import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import SlimSelect from 'slim-select';
import { AccountingService } from 'src/app/modules/accounting/accounting.service';
import { getAllCountries, getAllStates, getCountryPhoneCode } from 'src/app/shared/utils/countryStateData';
import { valueChanges } from 'src/app/shared/utils/formValidator';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { CustomerService } from '../../customer.service';

@Component({
  selector: 'app-create-customer',
  templateUrl: './create-customer.component.html',
  styleUrls: ['./create-customer.component.scss']
})
export class CreateCustomerComponent implements OnInit {

  constructor(private fb: FormBuilder,
              private store: Store<RootReducerState>,
              private router: Router,
              private route: ActivatedRoute,
              private customerService: CustomerService,
              private translateService: TranslateService,
              private accountingService:AccountingService,
              private toastr: ToastrService,
              private spinner: NgxSpinnerService) {
    this.business$ = this.store.pipe(select(selectBusiness));
  }

  createCustomerForm: FormGroup;
  error = '';
  business$: Observable<any>;
  businessId = null;
  customerToUpdate = null;
  customerData = null;
  unsubscribe$ = new Subject();
  update = false;
  countries = [];
  billingStates = [];
  shippingStates = [];
  countryWithCode = [];
  taxId = null;
  countrySelect;
  countrySelectship;
  stateSelect;
  stateSlectship;
  sameAsBillingAddress = false;
  response = '';
  formErrors = {
    customerName: '',
    firstName: '',
    lastName: '',
    countryCode: '',
    mobileNumber: '',
    email: '',
    addressLine1: '',
    country: '',
    state: '',
    city: '',
    postalCode: '',
    shipTo: '',
    shippingaddressLine1: '',
    shippingcountry: '',
    shippingstate: '',
    shippingcity: '',
    shippingpostalCode: '',
    shippingmobileNumber: '',
    tollFree: ''
  };

  formErrorMessages = {
    customerName: {
      required: 'Customer name is required'
    },
    firstName: {
      required: 'First Name  is required'
    },
    lastName: {
      required: 'Last Name is required'
    },
    countryCode: {
      required: 'Country Code is Required',
    },
    mobileNumber: {
      required: 'Mobile Number is required',
      min: 'Mobile number must be equal or more than 4 digits'
    },
    email: {
      required: 'Email is required'
    },
    addressLine1: {
      required: 'Address Line 1 is required'
    },
    country: {
      required: 'Country is required'
    },
    state: {
      required: 'State is required'
    },
    city: {
      required: 'City is required'
    },
    postalCode: {
      required: 'Postal code is required'
    },
    shipTo: {
      required: 'Shipping Address is required'
    },
    shippingaddressLine1: {
      required: 'Shipping Address Line 1 is required'
    },
    shippingmobileNumber: {
      required: 'Mobile Number is required',
      min: 'Mobile number must be equal or more than 4 digits'
    },
    shippingemailAddress: {
      required: 'Email Address is required'
    },
    tollFree: {
      required: 'Landline Number is required',
      min: 'Landline Number must be atleast 4 digits'
    }
  };

  ngOnInit(): void {
    this.loadForm();
    this.countries = getAllCountries();
    this.loadSelectData();
    this.mapSelectData();
    this.loadCountryCodeData();
    this.business$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((business) => {
        this.businessId = business.businessId._id;
      });
    this.route.queryParams.subscribe(({ id }) => {
      if (id) {
        this.customerToUpdate = id;
        this.update = true;
        this.spinner.show();
        this.customerService.getCustomer(id).subscribe((resp) => {
          this.spinner.hide();
          this.mapSelectData(resp.data);
          this.loadForm(resp.data);
        }, (error) => {
          this.spinner.hide();
          console.error("🚀 ~ CreateCustomerComponent ~ this.customerService.getCustomer ~ error:", error)
        });
      }
    });
  }

  loadCountryCodeData(): void {
    this.countryWithCode = getCountryPhoneCode()
  }

  loadForm(customer?): void {
    this.createCustomerForm = this.fb.group({
      customerName: [customer?.customerName || '', [Validators.required]],
      firstName: [customer?.firstName || '', [Validators.required]],
      lastName: [customer?.lastName || '', [Validators.required]],
      countryCode: [customer?.countryCode, Validators.required],
      mobileNumber: [customer?.mobileNumber || '', [Validators.required, Validators.min(1000)]],
      email: [customer?.email || '', [Validators.required]],
      tollFree: [customer?.tollFree || '', [Validators.min(1000)]],
      fax: [customer?.fax || ''],
      webSite: [customer?.webSite || ''],
      notes: [customer?.notes || ''],
      addressLine1: [customer?.billingAddress?.addressLine1 || '', [Validators.required]],
      addressLine2: [customer?.billingAddress?.addressLine2 || ''],
      country: [customer?.billingAddress?.country || null, [Validators.required]],
      state: [customer?.billingAddress?.state || null, [Validators.required]],
      city: [customer?.billingAddress?.city || '', [Validators.required]],
      postalCode: [customer?.billingAddress?.postalCode || '', [Validators.required]],
      shipTo: [customer?.shippingDetails?.shipTo || ''],
      shippingaddressLine1: [
        customer?.shippingDetails?.addressLine1 || ''],
      shippingLine2: [customer?.shippingDetails?.addressLine2 || ''],
      shippingcountry: [customer?.shippingDetails?.country || ''],
      shippingstate: [customer?.shippingDetails?.state || ''],
      shippingcity: [customer?.shippingDetails?.city || ''],
      shippingpostalCode: [customer?.shippingDetails?.postalCode || ''],
      shippingmobileNumber: [customer?.contact?.mobileNumber || '', [Validators.required, Validators.min(1000)]],
      shippingemailAddress: [customer?.contact?.emailAddress || ''],
      instruction: [customer?.instruction || ''],
      taxId: [customer?.taxId || '']
    });
    this.createCustomerForm.valueChanges.subscribe(() => {
      this.formErrors = valueChanges(this.createCustomerForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
    });

    this.formErrors = valueChanges(this.createCustomerForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
  }
  mapSelectData(user?): void {
    if (user?.billingAddress?.country) {
      this.onBillingCountryChange({text: user?.billingAddress?.country});
    }
    if (user?.shippingDetails?.country) {
      this.onShippingCountryChange({text: user?.shippingDetails?.country});
    }
    // const countries = this.countries.map((c) => {
    //   if (user && user?.billingAddress?.country === c.text) { return { text: c.text, selected: true }; }
    //   return c;
    // });
    // this.countrySelect.setData([...countries]);
    // if (user?.billingAddress?.country) {
    //   this.states = getAllStates(user?.billingAddress?.country);
    //   const states = this.states.map((s) => {
    //     if (user?.billingAddress?.state === s.text) { return { text: s.text, selected: true }; }
    //     return s;
    //   });
    //   this.stateSelect.setData([...states]);
    // }
    // this.countrySelectship.setData([...countries]);
    // if (user?.shippingDetails?.country) {
    //   this.states = getAllStates(user?.shippingDetails?.country);
    //   const states = this.states.map((s) => {
    //     if (user?.shippingDetails?.state === s.text) { return { text: s.text, selected: true }; }
    //     return s;
    //   });
    //   this.stateSlectship.setData([...states]);
    // }
  }
  loadSelectData(): void {
  }

  onBillingCountryChange(event): void {
    this.billingStates = getAllStates(event?.text);
  }

  onShippingCountryChange(event): void {
    this.shippingStates = getAllStates(event?.text);
  }
  sameAsBilling(): void {
    if (this.sameAsBillingAddress) {
      this.createCustomerForm.get('shipTo').setValue(this.createCustomerForm.get('customerName').value);
      this.createCustomerForm.get('shippingaddressLine1').setValue(this.createCustomerForm.get('addressLine1').value);
      this.createCustomerForm.get('shippingLine2').setValue(this.createCustomerForm.get('addressLine2').value);
      this.createCustomerForm.get('shippingcountry').setValue(this.createCustomerForm.get('country').value);
      this.createCustomerForm.get('shippingstate').setValue(this.createCustomerForm.get('state').value);
      this.createCustomerForm.get('shippingcity').setValue(this.createCustomerForm.get('city').value);
      this.createCustomerForm.get('shippingpostalCode').setValue( this.createCustomerForm.get('postalCode').value);
      this.createCustomerForm.get('shippingmobileNumber').setValue(this.createCustomerForm.get('mobileNumber').value);
      this.createCustomerForm.get('shippingemailAddress').setValue(this.createCustomerForm.get('email').value);
    } else {
      this.createCustomerForm.get('shipTo').setValue(null);
      this.createCustomerForm.get('shippingaddressLine1').setValue(null);
      this.createCustomerForm.get('shippingLine2').setValue(null);
      this.createCustomerForm.get('shippingcountry').setValue(null);
      this.createCustomerForm.get('shippingstate').setValue(null);
      this.createCustomerForm.get('shippingcity').setValue(null);
      this.createCustomerForm.get('shippingpostalCode').setValue(null);
      this.createCustomerForm.get('shippingmobileNumber').setValue(null);
      this.createCustomerForm.get('shippingemailAddress').setValue(null);
    }
  }
  customerDataBody(): object {
    const formValue = this.createCustomerForm.value;
    const body = {
      customerName: formValue?.customerName,
      firstName: formValue?.firstName,
      lastName: formValue?.lastName,
      countryCode: formValue?.countryCode,
      mobileNumber: formValue?.mobileNumber,
      email: formValue?.email,
      tollFree: formValue?.tollFree,
      fax: formValue?.fax,
      webSite: formValue?.webSite,
      notes: formValue?.notes,
      taxId:formValue?.taxId,
      billingAddress: {
        addressLine1: formValue?.addressLine1,
        addressLine2: formValue?.addressLine2,
        country: formValue?.country,
        state: formValue?.state,
        city: formValue?.city,
        postalCode: formValue?.postalCode,
      },
      shippingDetails: {
        shipTo: formValue?.shipTo,
        addressLine1: formValue?.shippingaddressLine1,
        addressLine2: formValue?.shippingLine2,
        country: formValue?.shippingcountry,
        state: formValue?.shippingstate,
        city: formValue?.shippingcity,
        postalCode: formValue?.shippingpostalCode,
      },
      contact: {
        mobileNumber: formValue?.shippingmobileNumber,
        emailAddress: formValue?.shippingemailAddress,
      },
      instruction: formValue?.instruction
    };
    return body;
  }
  createCustomer(): void {
    console.log("this.createCustomerForm.invalid", this.createCustomerForm);
    
    if (this.createCustomerForm.invalid) {
      this.createCustomerForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createCustomerForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    const body = {
      businessId: this.businessId,
      ...this.customerDataBody(),
    };
    this.spinner.show();
    this.customerService.createCustomer(body).subscribe((resp) => {
      this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
        if (resp.success) {
          this.accountingService.setAccountsInStore(resp.data);
        }
      });
      this.customerService.addCustomerInStore(resp.data);
      this.createCustomerForm.reset();
      this.spinner.hide();
      this.router.navigate(['/sales/customers']);
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }
  updateCustomer(): void {
    this.error = '';
    this.response = '';
    if (this.createCustomerForm.invalid) {
      this.createCustomerForm.markAllAsTouched();
      this.formErrors = valueChanges(this.createCustomerForm, { ...this.formErrors }, this.formErrorMessages, this.translateService);
      return;
    }
    this.spinner.show();
    const body = {
      id: this.customerToUpdate,
      ...this.customerDataBody(),
    };
    this.customerService.updateCustomer(body).subscribe((resp) => {
      this.spinner.hide();
      this.createCustomerForm.reset();
      this.customerService.updateCustomerInStore(resp.data);
      this.router.navigate(['/sales/customers']);
    }, (error) => {
      this.toastr.error(this.translateService.instant('Something went wrong!'));
      this.spinner.hide();
    });
  }
}
