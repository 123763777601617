import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { SalesService } from '../../sales.service';
import { SalesReducerState } from '../../state/reducers';
import * as ProductsActions from '../../state/actions/products.action';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {

  constructor(private httpService: HttpService,
              private store: Store<SalesReducerState>) {}

  businessId = null;

  createProducts(body): Observable<any> {
    return this.httpService.post(`/sales/addProduct/${this.businessId}`, body);
  }
  listProduct(businessId): Observable<any> {
    return this.httpService.post(`/sales/getProduct/${businessId}`, {productType: 'sell'});
  }
  updateProduct(body): Observable<any> {
    return this.httpService.put(`/sales/updateProduct/${body._id}`, body);
  }
  deleteProducts(id): Observable<any> {
    return this.httpService.delete(`/sales/deleteProduct/${id}`);
  }

  addProductToStore(product): void {
    this.store.dispatch(ProductsActions.AddProdcut({product}));
  }

  deleteProductInStore(_id): void {
    this.store.dispatch(ProductsActions.DeleteProduct({_id}));
  }

  updateProductInStore(product): void {
    this.store.dispatch(ProductsActions.UpdateProduct({product}));
  }
}
