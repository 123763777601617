import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';

import { SharedModule } from 'src/app/shared/shared.module';
import { ClickOutsideModule } from 'ng-click-outside';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { CreatePurchaseOrderComponent } from './components/create-purchase-order/create-purchase-order.component';
import { PurchaseOrderListComponent } from './components/purchase-order-list/purchase-order-list.component';
import { TranslateModule } from '@ngx-translate/core';
import { DirectivesModule } from 'src/app/directives/directives.module';
import { ViewPurchaseOrderComponent } from './components/view-purchase-order/view-purchase-order.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgxPaginationModule } from 'ngx-pagination';


const routes: Routes = [
    {
    path: '',
    component: PurchaseOrderListComponent
   },
   {
    path: 'purchase-order-list',
    component: PurchaseOrderListComponent
   },
   {
    path: 'create-purchase-order',
    component: CreatePurchaseOrderComponent
   },
   {
     path: 'view-purchase-order',
     component: ViewPurchaseOrderComponent
   }
];

@NgModule({
  declarations: [
    PurchaseOrderListComponent,
     CreatePurchaseOrderComponent,
     ViewPurchaseOrderComponent
  ],
  imports: [
    CommonModule,
    SharedModule,
    NgSelectModule,
    FormsModule,
    ClickOutsideModule,
    ReactiveFormsModule,
    TranslateModule,
    NgxSpinnerModule,
    NgxPaginationModule,
    DirectivesModule,
    RouterModule.forChild(routes)
  ]
})
export class PurchaseOrderModule { }
