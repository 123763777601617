<div class="light-color-1 flex justify-center min-h-screen items-center">
    <div *ngIf='subscripitionVerified' class="w-1/2 bg-white flex flex-col rounded-2xl px-8 py-20 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-green-500 h-40 w-40 text-center m-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        <p class="mb-4 text-3xl Poppins-Bold">Thank you for subscribing</p>
        <p class="mb-2 text-xl">We will email your subscription paid invoice shortly.</p>
        <p>Redirecting to subscription page in 3 seconds</p>
    </div>
    <div *ngIf='verifingSubscription' class="w-1/2 bg-white flex flex-col rounded-2xl px-8 py-20 text-center">
        <p class="mb-4 text-3xl Poppins-Bold">Verifying subscription</p>
        <p class="mb-2 text-xl">Please wait...</p>
    </div>
    <div *ngIf='subscripitionNotVerified' class="w-1/2 bg-white flex flex-col rounded-2xl px-8 py-20 text-center">
        <svg xmlns="http://www.w3.org/2000/svg" class="text-red-500 h-40 w-40 text-center m-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z" />
          </svg>
        <p class="mb-4 text-3xl Poppins-Bold">Subscription Verification Failed</p>
        <p class="mb-2 text-xl">Incorrect Subscription ID or Token</p>
    </div>
    <div *ngIf='userDataNotFetched' class="w-1/2 bg-white flex flex-col rounded-2xl px-8 py-20 text-center">
          
          <svg xmlns="http://www.w3.org/2000/svg" class="text-yellow-500 h-40 w-40 text-center m-auto mb-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
          </svg>
        <p class="mb-4 text-3xl Poppins-Bold">It seems you are not logged in.</p>
        <p class="mb-2 text-xl">Login to your account and then visit this link</p>
        <a class="text-blue">Verifying Subcription</a>
    </div>
</div>