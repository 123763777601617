import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { AccountingService } from '../../../../accounting.service';

@Component({
  selector: 'app-sales-taxes-list',
  templateUrl: './sales-taxes-list.component.html',
  styleUrls: ['./sales-taxes-list.component.scss']
})
export class SalesTaxesListComponent implements OnInit, OnDestroy {

  constructor(private accountingService: AccountingService,
              private store: Store<RootReducerState>,
              private router: Router,
              private spinner: NgxSpinnerService,
              private toaster: ToastrService) {
                this.business$ = this.store.pipe(select(selectBusiness));
              }

  business$: Observable<any>;
  unsubscribe$ = new Subject();
  businessId;
  salesTaxes = [];
  taxId;
  userId = '';
  taxToDelete = null;
  openDeleteModal = false;
  ngOnInit(): void {
    this.business$
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe((business) => {
      if (business?.businessId?._id) {
        this.spinner.show();
        this.accountingService.listSalesTaxes(business.businessId._id).subscribe((resp) => {
          this.spinner.hide();
          this.salesTaxes = resp?.data?.filter(tax => !tax?.isDeleted);
        }, (error) => {
          this.spinner.hide();
          this.toaster.error('Something went wrong');
        });
      }
    });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  deletesalestax(): void {
    this.spinner.show();
    this.accountingService.deleteSalesTax(this.taxToDelete).subscribe((resp) => {
      this.spinner.hide();
      this.salesTaxes = this.salesTaxes.filter(({_id}) => _id !== this.taxToDelete);
      this.updateAccountsInStore();
      this.openDeleteModal = false;
      this.toaster.success('Tax deleted');
    }, (error) => {
      this.openDeleteModal = false;
      this.spinner.hide();
      this.toaster.error('Something went wrong');
    });
  }

  editSalestax(id): void {
    this.router.navigate(['/settings/accounting/sales-taxes/create-tax'], { queryParams: { id } });
  }

  openDeleteConfirmation(id): void {
    this.taxToDelete = id;
    this.openDeleteModal = true;
  }

  updateAccountsInStore(): void {
    this.accountingService.getAllAccounts(this.businessId).subscribe((resp) => {
      if (resp.success) {
        this.accountingService.setAccountsInStore(resp.data);
      }
    });
  }

}
