import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { UserService } from 'src/app/shared/services/user.service';
import { UtilityService } from 'src/app/shared/services/utility.service';
import { SetUser } from 'src/app/store/actions/user.actions';
import { RootReducerState } from 'src/app/store/reducers';
import { environment } from 'src/environments/environment';
// import Tap from "@tapfiliate/tapfiliate-js";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  constructor(
    private httpService: HttpService,
    private router: Router,
    private store: Store<RootReducerState>,
    private userService: UserService,
    private toastr: ToastrService,
    private utility: UtilityService
  ) {}

  AUTH_TOKEN = 'auth_token';
  REFRESH_TOKEN = 'refresh_token';
  userLogin(data): Observable<any> {
    return this.httpService
      .post('/users/login', data)
      .pipe(catchError((error) => throwError(error)));
  }

  onLoginSuccess(user): void {
    // Tap.conversion(user.token, 500)
    // tap('conversion',)
    console.log(user);
    if (!user?.token) {
      this.router.navigate(['/auth/otp-verify'], {
        queryParams: { userId: user?.userId },
      });
      return;
    }
    localStorage.setItem(this.AUTH_TOKEN, user.token);
    localStorage.setItem(this.REFRESH_TOKEN, user.refreshtoken);
    localStorage.removeItem('currentLocation');
    this.userService.checkUserSubscription(user);
    // if (user?.businesses?.length > 0) {
    //   const doIhaveBusiness = user?.businesses.find((business) => {
    //     if (business?.businessId?.userId === user?._id) {
    //       return business;
    //     }
    //   });
    //   if(doIhaveBusiness){
    //     if (!user?.subscription?.subscripitionId) {
    //       this.router.navigate(['/auth/subscription-plans']);
    //     } else if (user?.subscription?.subscripitionId  && !moment(user?.subscription?.expiryDate).isAfter(moment())) {
    //       this.router.navigate(['/auth/subscription-plans']);
    //     } else {
    //       this.router.navigate(['/dashboard']);
    //     }
    //   }
    //   else {
    //     this.router.navigate(['/dashboard']);
    //   }
    // } else {
    //   if (user?.subscription?.subscripitionId && !moment(user?.subscription?.expiryDate).isAfter(moment()) ) {
    //     this.router.navigate(['/auth/personal-details']);
    //   } else {
    //     this.router.navigate(['/auth/subscription-plans']);
    //   }
    // }
  }

  userSignup(data): Observable<any> {
    return this.httpService
      .post(`/users/register`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  onSignupSuccess(user, token, refreshtoken): void {
    if (!user) {
      this.toastr.success(
        'Verification mail sent successfully to your registered email-id',
        'Welcome!'
      );
    }
    this.userService.checkUserSubscription(user);
  }

  addPersonDetails(data): Observable<any> {
    return this.httpService
      .post(`/users/saveUserDatail`, data)
      .pipe(catchError((error) => throwError(error)));
  }

  onAddPersonalDetailsSuccess(data): void {
    localStorage.setItem(
      'selectedBusiness',
      data?.data?.businesses[0]?.businessId?._id
    );
    this.store.dispatch(SetUser({ user: data.data }));
    // this.router.navigate(['/settings/profile']);
    this.utility.showHideSettingsModal.next({ open: true, tab: 'userProfile' });
  }

  onAddPersonalDetailsFailure(): void {}

  forgotPassword(data): Observable<any> {
    return this.httpService
      .post('/users/forgetpassword', data)
      .pipe(catchError((error) => throwError(error)));
  }

  resetPassword(data): Observable<any> {
    return this.httpService
      .post('/users/resetPassword', data)
      .pipe(catchError((error) => throwError(error)));
  }

  verifyAlternateEmail(data): Observable<any> {
    return this.httpService.post('/users/verifyEmail', data);
  }

  verifyInvitation(data): Observable<any> {
    return this.httpService
      .post('/users/injectBusiness', data)
      .pipe(catchError((error) => throwError(error)));
  }

  signupForInvitedUser(data): Observable<any> {
    return this.httpService
      .post('/users/injectBusinessForNewUser', data)
      .pipe(catchError((error) => throwError(error)));
  }

  findInvite(id): Observable<any> {
    return this.httpService
      .get(`/users/findInvite/${id}`)
      .pipe(catchError((error) => throwError(error)));
  }

  verifySubscription(data): Observable<any> {
    return this.httpService.post('/subscription/verifySubscription', data);
  }

  resendOtp(userId: string): Observable<any> {
    return this.httpService.post(`/users/resendOtp`, { userId });
  }

  verifyOtp(otp: string): Observable<any> {
    return this.httpService.post(`/users/verifyOtp`, { otp });
  }

  getInviteExpertUser(data): Observable<any> {
    return this.httpService
      .post(`/users/getInviteExpertUser`, data)
      .pipe(catchError((error) => throwError(error)));
  }
}
