<div class="flex p-4 flex-col gap-6">
  <div class="flex flex-col gap-4">
    <span class="text-sm Poppins-Medium">
      {{ "Integrated Platforms" | translate }}
    </span>
    <div class="flex gap-4">
      <div class="w-72 p-6 rounded-lg bg-input-light flex flex-col gap-4">
        <div class="flex justify-between">
          <div class="text-sm Poppins-Medium max-w-[80%]">
            {{ "POS Platform" | translate }}
          </div>
          <span class="w-10 h-10 bg-[#FA5936] p-1 rounded-full overflow-hidden flex justify-center items-center" style="width: 46px; height: 46px;">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-full" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M280-640q-33 0-56.5-23.5T200-720v-80q0-33 23.5-56.5T280-880h400q33 0 56.5 23.5T760-800v80q0 33-23.5 56.5T680-640H280Zm0-80h400v-80H280v80ZM160-80q-33 0-56.5-23.5T80-160v-40h800v40q0 33-23.5 56.5T800-80H160ZM80-240l139-313q10-22 30-34.5t43-12.5h376q23 0 43 12.5t30 34.5l139 313H80Zm260-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm120 160h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Zm0-80h40q8 0 14-6t6-14q0-8-6-14t-14-6h-40q-8 0-14 6t-6 14q0 8 6 14t14 6Z"/></svg>
          </span> 
          <!-- <img
            class="rounded-[80px] bg-[#FA5936] h-9 w-9"
            src="assets/images/pos.svg"
            alt=""
          /> -->
        </div>

        <div class="flex items-start justify-between">
          <a
            class="text-xxs underline cursor-pointer flex gap-1 text-[#1C1C1C66]"
            href="https://pos.numetric.work/auth/login"
            target="_blank"
          >
            <span>
              {{ "Access Now" | translate }}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z"/></svg>
          </a>

          <div class="flex items-center gap-1">
            <div class="h-2 w-2 rounded-full bg-[#12B76A]"></div>
            <span class="pl-1 text-xxs text-[#1C1C1C66]">
              {{ "Activated" | translate }}
            </span>
          </div>
        </div>
      </div>
      <div class="w-72 p-6 rounded-lg bg-input-light flex flex-col gap-4">
        <div class="flex justify-between">
          <div class="text-sm Poppins-Medium max-w-[80%]">
            {{ "Payroll" | translate }}
          </div>
          <span class="w-10 h-10 bg-[#36BFFA] p-1 rounded-full overflow-hidden flex justify-center items-center" style="width: 46px; height: 46px;">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-full" height="24px" viewBox="0 -960 960 960" width="24px" fill="#ffffff"><path d="M80-200v-560h800v560H80Zm120-200h120q17 0 28.5-11.5T360-440v-70q0-17-11.5-28.5T320-550h-60v-30h100v-60H200v150h100v30H200v60Zm240 80h320v-22q0-45-44-71.5T600-440q-72 0-116 26.5T440-342v22Zm160-160q33 0 56.5-23.5T680-560q0-33-23.5-56.5T600-640q-33 0-56.5 23.5T520-560q0 33 23.5 56.5T600-480ZM160-280h640v-400H160v400Zm0 0v-400 400Z"/></svg>
          </span> 
          <!-- <div
            class="flex items-center justify-center rounded-[80px] bg-[#36BFFA] h-9 w-9"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 -960 960 960"
              class="h-7 w-7"
              fill="white"
              data-tooltip-target="payrollTooltip"
              data-tooltip-placement="right"
              data-tooltip-trigger="hover"
            >
              <path
                d="M80-200v-560h800v560H80Zm120-200h120q17 0 28.5-11.5T360-440v-70q0-17-11.5-28.5T320-550h-60v-30h100v-60H200v150h100v30H200v60Zm240 80h320v-22q0-45-44-71.5T600-440q-72 0-116 26.5T440-342v22Zm160-160q33 0 56.5-23.5T680-560q0-33-23.5-56.5T600-640q-33 0-56.5 23.5T520-560q0 33 23.5 56.5T600-480ZM160-280h640v-400H160v400Zm0 0v-400 400Z"
              />
            </svg>
          </div> -->
        </div>

        <div class="flex items-start justify-between">
          <div
            class="text-xxs underline cursor-pointer flex gap-1 text-[#1C1C1C66]"
            (click)="redirect('payroll')"
            target="_blank"
          >
            <span>
              {{ "Access Now" | translate }}
            </span>
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4" height="24px" viewBox="0 -960 960 960" width="24px" fill="#5f6368"><path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h280v80H200v560h560v-280h80v280q0 33-23.5 56.5T760-120H200Zm188-212-56-56 372-372H560v-80h280v280h-80v-144L388-332Z"/></svg>
          </div>

          <div class="flex items-center gap-1">
            <div
              class="h-2 w-2 rounded-full"
              [ngClass]="{
                'bg-[#12B76A]': isPayrollPurchased,
                'bg-[#F04438]': !isPayrollPurchased
              }"
            ></div>
            <span class="pl-1 text-xxs text-[#1C1C1C66]">
              {{
                (isPayrollPurchased ? "Activated" : "Not Activated") | translate
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="flex flex-col">
    <span class="text-sm pb-2 Poppins-Medium">
      {{ "Zapier Integration" | translate }}
    </span>

    <zapier-full-experience
      [clientId]="'ld4reRqHDNjn0PUCeDvzpizCnyAAjoTgy2DYejEv'"
      [theme]="'light'"
      [appSearchBarDisplay]="'show'"
    ></zapier-full-experience>
  </div>
</div>
