import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/shared/services/http.service';

@Injectable({
  providedIn: 'root'
})
export class RecurringJvService {

  constructor(private http: HttpService) { }

  createRecurringJV(payload): Observable<any> {    
    return this.http.postMultipart(`/transaction/createRecurringJournal/${payload?.businessId}`, payload?.formData);
  }

  getAllRecurringJV(businessId: string): Observable<any> {
    console.log('in service');

    return this.http.get(`/transaction/getRecurringJournal/${businessId}`);
  }

  getRecurringJV(id: string): Observable<any> {
    return this.http.get(`/transaction/viewRecurringJournal/${id}`);
  }

  updateRecurringJV(payload, id: string): Observable<any> {
    return this.http.put(`/transaction/updateRecurringJournal/${id}`, payload);
  }

  cancelRecurringJV(id: string): Observable<any> {
    return this.http.get(`/transaction/cancelRecurringJournal/${id}`);
  }
}
