<div class="flex bg-white h-full">
  <div
    class="flex flex-col h-full p-4 gap-2 new-design-round-bordr w-[44%]"
    style="background-color: #f7f9fb"
  >
    <div class="flex items-center gap-2 justify-end text-xxs text-[#1C1C1C66]">
      <div
        class="h-2 w-2 rounded-full"
        [ngClass]="{
          'bg-[#F63D68]': !codeToView?.isActive,
          'bg-[#12B76A]': codeToView?.isActive
        }"
      ></div>
      <span>
        {{ (codeToView?.isActive ? "Active" : "Inactive") | translate }}
      </span>
    </div>
    <table class="text-xs">
      <tr *ngFor="let row of tableHeadings">
        <td class="px-2 py-2">
          {{ row.heading | translate }}
        </td>
        <td class="px-2 py-2 Poppins-Medium">
          {{
            row.isDate
              ? (codeToView[row.key] | date)
              : (codeToView[row.key] | empty : "-")
          }}
        </td>
      </tr>
    </table>
    <span class="text-xs pl-2">
      {{ "Description" | translate }}
    </span>
    <p class="Poppins-Medium text-xs pl-2">
      {{ codeToView?.description }}
    </p>
  </div>
  <div class="w-full flex flex-col p-3 gap-4 justify-between">
    <div class="relative overflow-auto">
      <table class="w-full text-xs text-left rtl:text-right">
        <thead class="text-[#1C1C1C66] border-[#1C1C1C33] border-b">
          <tr>
            <td
              *ngFor="let heading of customerDetails"
              scope="col"
              class="px-4 py-2"
            >
              {{ heading | translate }}
            </td>
          </tr>
        </thead>
        <ng-container *ngIf="customerTable?.length; else noData">
          <tbody>
            <tr *ngFor="let row of customerTable" class="bg-white">
              <td class="px-6 py-4">
                {{ row?.mobileNumber | empty : "-" }}
              </td>
              <td class="px-6 py-4">
                {{ row?.name | empty : "-" }}
              </td>
              <td class="px-6 py-4">
                {{ row.email | empty : "-" }}
              </td>
            </tr>
          </tbody>
        </ng-container>
      </table>
    </div>
    <div class="flex items-center justify-end">
      <app-submit
        value="Go Back"
        className="bg-[#026AA2] text-white h-10 w-30"
        (clicked)="close.emit()"
      ></app-submit>
    </div>
  </div>
</div>

<ng-template #noData class="flex items-center justify-center">
  <tbody>
    <tr>
      <td class="pt-8 text-center" colspan="7">
        {{ "No Data to Show" | translate }}
      </td>
    </tr>
  </tbody>
</ng-template>
