import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Store, select } from '@ngrx/store';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { BusinessService } from 'src/app/shared/services/business.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUserBusinesses } from 'src/app/store/selectors/user.selectors';

@Component({
  selector: 'app-pos-cash-invoice',
  templateUrl: 'pos-cash-invoice-settings.component.html',
})
export class POSAndCashInvoiceSettings implements OnInit {
  business$: Observable<any>;
  businesses$: Observable<any>;
  constructor(
    private store: Store<RootReducerState>,
    private businessService: BusinessService,
    private spinner: NgxSpinnerService
  ) {
    this.businesses$ = this.store.pipe(select(selectUserBusinesses));
    this.business$ = store.pipe(select(selectBusiness));
  }
  deliveryEmailAddress = new FormControl(null);
  sendSms = new FormControl(true);
  isLogo = new FormControl(false);
  footerNote = new FormControl(null);
  unsubscribe$ = new Subject();
  businessId: any;
  response: any;
  error: any;
  business;
  ngOnInit(): void {
    this.loadBusinessDetails();
  }
  loadBusinessDetails(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      this.businessId = business?.businessId?._id;
      this.business = business;
      this.updateForm(business);
    });
  }
  updateForm(business): void {
    this.deliveryEmailAddress.patchValue(
      business.businessId?.deliveryEmailAddress || ''
    );
    this.isLogo.patchValue(business.businessId?.isLogo || false);
    this.footerNote.patchValue(business.businessId?.footerNote || '');
    this.sendSms.patchValue(business.businessId?.sendSms || false);
  }

  updateBusiness(): void {
    this.spinner.show();
    const body = {
      businessId: this.businessId,
      deliveryEmailAddress: this.deliveryEmailAddress.value || '',
      footerNote: this.footerNote.value || '',
      isLogo: this.isLogo.value,
      sendSms: this.sendSms.value,
    };
    this.businessService.updateBusiness(body).subscribe(
      (updatedBusiness) => {
        this.spinner.hide();
        this.businessService.updateBusinessInStore(updatedBusiness.data);
      },
      (error) => {
        this.error = error?.error?.message || 'Internal Server Error';
        this.spinner.hide();
      }
    );
  }

  cancelUpdate() {
    this.updateForm(this.business);
  }
}
