import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { InvoiceCustomizationService } from '../../invoice-customization.service';

@Component({
  selector: 'app-invoice-column-settings',
  templateUrl: './invoice-column-settings.component.html',
  styleUrls: ['./invoice-column-settings.component.scss']
})
export class InvoiceColumnSettingsComponent implements OnInit {

  constructor(private invoiceCustomizationService: InvoiceCustomizationService,
              private spinner: NgxSpinnerService,
              private fb: FormBuilder) { }
  columnSettingsForm: FormGroup;
  error = '';
  response = '';
  itemsOther = false;
  unitsOther = false;
  priceOther = false;
  amountOther = false;

  @ViewChild('otherItem') otherItem: ElementRef<HTMLInputElement>;

  ngOnInit(): void {
    this.loadForm();
    this.invoiceCustomizationService.invoiceCustomizationSettings$.subscribe((settings) => {
      if (settings) {
        this.loadForm(settings);
      } else {
        this.loadForm();
      }
    });
  }

  loadForm(settings?): void {
    this.columnSettingsForm = this.fb.group({
      item: [settings?.item ? settings.item : 'Items'],
      price: [settings?.price ? settings.price : 'Price'],
      units: [settings?.units ? settings.units : 'Quantity'],
      amount: [settings?.amount ? settings.amount : 'Amount'],
    });
    this.itemsOther = !['Products', 'Services', 'Items'].includes(this.columnSettingsForm.get('item').value);
    this.unitsOther = !['Hours', 'Quantity'].includes(this.columnSettingsForm.get('units').value);
    this.priceOther = !['Price', 'Rate'].includes(this.columnSettingsForm.get('price').value);
    this.amountOther = !['Amount'].includes(this.columnSettingsForm.get('amount').value);
  }

  saveChanges(): void {
    this.spinner.show();
    this.invoiceCustomizationService.saveInvoiceCustomizationSettings(this.columnSettingsForm.value)
    .subscribe((resp) => {
      this.spinner.hide();
      this.response = 'Changes Saved';
      setTimeout(() => this.response = '', 2000);
      console.log(resp);
    }, (error) => {
      this.spinner.hide();
      this.error = error?.error?.message || 'Internal server error';
    });
  }

}
