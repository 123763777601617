import { CurrencyPipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { selectCustomizationSettings } from 'src/app/modules/sales/state/selectors/customizationSettings.selector';
import { CurrencywithspacePipe } from 'src/app/pipes/currencywithspace.pipe';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { environment } from 'src/environments/environment';
import { LanguageService } from '../../services/language.service';
import { NumberService } from '../../services/number.service';
import { ReceiptService } from '../../services/receipt.service';
import { copyToClipBoard } from '../../utils/copyToClipBoard';

@Component({
  selector: 'app-receipt',
  templateUrl: './receipt.component.html',
  styleUrls: ['./receipt.component.scss'],
  providers: [CurrencyPipe],
})
export class ReceiptComponent implements OnInit {
  constructor(
    private router: ActivatedRoute,
    private receiptService: ReceiptService,
    private spinner: NgxSpinnerService,
    private store: Store<RootReducerState>,
    private currencyPipe: CurrencyPipe,
    private languageService: LanguageService,
    private numberService: NumberService,
    private translateService: TranslateService,
    private toastr: ToastrService
  ) {
    this.business$ = this.store.pipe(select(selectBusiness));
    this.customizationSettings$ = this.store.pipe(
      select(selectCustomizationSettings)
    );
  }

  business$: Observable<any>;
  customizationSettings$: Observable<any>;
  customizationSettings = null;
  businessId = null;
  business = null;
  decimalSize = 2;
  number = '1.2-2';
  receiptData = {
    _id: '',
    recieptNumber: '',
    referanceId: '',
    recordId: '',
    referanceNumber: '',
    paidAmount: null,
    payDate: null,
    returnItems: [],
    currencyDetails: {
      currencySymbol: '$',
    },
    paymentMethod: '',
    memo: '',
    type: this.translateService.instant('Receipt'),
    businessDetails: {
      businessId: {
        _id: '',
        companyLogo: '',
        companyName: '',
        address: {
          addressLineFirst: '',
          addressLine2: '',
          city: '',
          state: '',
        },
        country: '',
        currency: '',
      },
    },
    customerDetails: {
      customerId: {
        customerName: '',
        email: '',
        billingAddress: {
          addressLine1: '',
          addressLine2: '',
          city: '',
          state: '',
          postalCode: '',
          country: '',
        },
        mobileNumber: '',
      },
    },
    vendorId: {
      vendorName: '',
      email: '',
      address: {
        addressLine1: '',
        addressLine2: '',
        city: '',
        state: '',
        postalCode: '',
        country: '',
      },
      contactDetails: {
        mobileNumber: '',
      },
    },
    userId: {
      firstName: '',
      lastName: '',
    },
  };

  currencyDetails = {
    currency: '',
    currencySymbol: '',
  };

  companyLogo = null;
  returnItems = [];

  shareURL = '';
  copyButtonText = 'Copy';
  email = new FormControl(null, [
    Validators.required,
    Validators.pattern(
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ),
  ]);
  emailModal = false;
  unsubscribe$ = new Subject();

  fieldNames = {
    type: 'Invoice',
    receiptNumber: 'Receipt Number',
    refundNumber: 'Refund Number',
    payDate: 'Receipt Date',
    billingAddress: 'Billing Address',
    shippingAddress: 'Shipping Address',
    total: 'Total',
    referanceNumber: 'Reference Number',
    paymentType: 'Payment Method',
    receiptAmount: 'Receipt Amount',
    subtotal: 'Subtotal',
    tax: 'Tax',
    terms: 'Memo',
    itemName: 'Item Name',
    quantity: 'Quantity',
    unitPrice: 'Unit Price',
    totalAmount: 'Total Amount',
    billTo: 'Bill To',
    eSign: 'Digitally signed document',
    discount: 'Discount',
    discountSubtotal: 'Discount Subtotal',
    issuer: 'Issued By',
    memo: 'Memo',
  };

  ngOnInit(): void {
    this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
      this.businessId = business.businessId._id;
      this.business = business.businessId;
      const { currency, currencySymbol } = business?.businessId;
      this.currencyDetails = {
        currency: currency,
        currencySymbol: currencySymbol,
      };
    });
    this.customizationSettings$.subscribe((settings) => {
      if (settings) {
        this.customizationSettings = settings;
        this.companyLogo = settings?.companyLogo;
      }
    });
    this.router.params.subscribe(({ id }) => {
      if (id) {
        this.loadReceipt(id);
      }
    });

    this.loadNumberConfig();
  }

  loadNumberConfig(): void {
    this.numberService.number
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((number) => {
        this.number = number;
      });

    this.numberService.decimalSize
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((decimalSize) => {
        this.decimalSize = decimalSize;
      });
  }

  loadReceipt(id): void {
    this.spinner.show();
    this.receiptService.getReceiptById(id).subscribe(
      (resp) => {
        this.spinner.hide();
        if (resp.success) {
          console.log(resp.data);
          this.receiptData = resp.data;
          this.returnItems = [...resp.data.returnItems];
          console.log('Return Items', this.returnItems);
          const currencyWithSpacePipe = new CurrencywithspacePipe();
          this.receiptData = {
            ...this.receiptData,
            paidAmount: currencyWithSpacePipe.transform(
              this.receiptData.paidAmount,
              this.receiptData.businessDetails.businessId.currency,
              'narrowSymbol',
              this.number
            ),
          };
          console.log('receipt Data', this.receiptData);
          console.log(this.receiptData?.userId);
          this.loadFieldName(resp.data.type);
          this.shareURL = `${environment.frontBaseURL}/open/receipt/${resp.data.businessDetails.businessId._id}/${resp.data._id}`;
          console.log('Link', this.shareURL);
        }
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  loadFieldName(type): void {
    if (type === 'Receipt') {
      this.fieldNames.type = 'Receipt';
      this.fieldNames.payDate = 'Receipt Date';
      this.fieldNames.totalAmount = 'Receipt Amount';
      this.fieldNames.billTo = 'Customer Details';
    } else if (type === 'Refund') {
      this.fieldNames.receiptNumber = 'Refund Number';
      this.fieldNames.type = 'Refund';
      this.fieldNames.payDate = 'Refund Date';
      this.fieldNames.totalAmount = 'Refund Amount';
      this.fieldNames.billTo = 'Customer Details';
    } else if (type === 'Payment Voucher') {
      this.fieldNames.receiptNumber = 'Payment Voucher Number';
      this.fieldNames.type = 'Payment Voucher';
      this.fieldNames.payDate = 'Payment Voucher Date';
      this.fieldNames.totalAmount = 'Payment Voucher Amount';
      this.fieldNames.billTo = 'Vendor Details';
    } else if (type === 'Return') {
      this.fieldNames.receiptNumber = 'Return Number';
      this.fieldNames.type = 'Return';
      this.fieldNames.payDate = 'Return Date';
      this.fieldNames.totalAmount = 'Return Amount';
      this.fieldNames.billTo = 'Vendor Details';
    }
    // this.fieldNames.type = type === 'Receipt' ? 'Receipt' : 'Payment Voucher';
    // this.fieldNames.type = type === 'Refund' ? 'Refund Receipt' : 'Return Receipt';
    // this.fieldNames.billTo = type === 'Receipt'|| 'Refund' ? 'Customer Details' : 'Vendor Details';
    // this.fieldNames.receiptNumber = type === 'Receipt'|| 'Refund' || 'Return' ? 'Receipt Number' : 'Payment Voucher Number';
    // this.fieldNames.payDate = type === 'Receipt' ? 'Receipt Date' : 'Payment Voucher Date';
    // this.fieldNames.totalAmount = type === 'Receipt' ? 'Receipt Amount' : 'Payment Voucher Amount';
  }
  copyLink(): void {
    copyToClipBoard(this.shareURL);
    this.copyButtonText = 'Copied!';
    setTimeout(() => {
      this.copyButtonText = 'Copy';
    }, 1500);
  }

  sendEmail(): void {
    if (this.email.invalid) {
      this.email.markAsDirty();
      return;
    }
    const data = {
      email: this.email.value,
      id: this.receiptData._id,
      ownerId: this.business.userId,
      url: this.shareURL,
      businessName: this.business.businessName,
      type: this.fieldNames?.type === 'Receipt' ? 'record' : 'refund',
    };
    this.spinner.show();
    this.receiptService.sendEmail(data).subscribe(
      (resp) => {
        this.spinner.hide();
        this.email.reset();
        this.emailModal = false;
        this.toastr.success(
          this.translateService.instant('Email sent successfully')
        );
      },
      (error) => {
        this.spinner.hide();
        this.toastr.error(
          error?.error?.message ||
            this.translateService.instant('Email not sent')
        );
        console.log(error);
      }
    );
  }

  downloadPdf(): void {
    if (this.receiptData.type === 'Refund') {
      delete this.receiptData.vendorId;
    }
    const body = {
      config: {
        data: {
          ...this.receiptData,
          payDate: moment(this.receiptData.payDate).format('DD-MM-YYYY'),
          currencyDetails: {
            currencySymbol: this.currencyDetails.currencySymbol,
          },
          companylogo: this.customizationSettings?.companyLogo,
        },
        direction:
          localStorage.getItem('NuMetric|lang') === 'ar' ? 'rtl' : 'ltr',
        showTax: true,
        decimalSize: this.decimalSize,
        fieldNames: this.languageService.translate(this.fieldNames),
      },
    };
    this.spinner.show();
    this.receiptService.createReceiptPdf(body).subscribe(
      (resp) => {
        this.spinner.hide();
        const a = document.createElement('a');
        const blob = new Blob([resp], { type: 'application/pdf' });
        const url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = `${this.receiptData.type} ${this.receiptData.recieptNumber}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
        this.toastr.success(this.translateService.instant('PDF downloaded'));
      },
      (error) => {
        this.spinner.hide();
        const errorMessage =
          error?.error?.message ||
          this.translateService.instant('Something went wrong!');
        this.toastr.error(errorMessage);
      }
    );
  }

  fetchSendInvoiceEmail(): void {
    this.email.setValue(this.receiptData.customerDetails.customerId.email);
  }
}
