<div id="home" class="md:w-5/6 w-full mx-auto block mt-20 rounded-xl max-w-[1440px] bg-white">
  <div class="flex md:flex-row flex-col">
    <div class="flex-1 md:mt-8 mt-8 md:w-5/12 lg:p-0 p-2 w-full">
      <h3 class="text-start Poppins-ExtraBold md:text-5xl text-3xl w-full md:w-5/6" style="color:'#5e5e5e'">{{'Cloud-based Accounting.' | translate}}</h3>
      <h3 class="text-start Poppins-ExtraBold md:text-5xl text-3xl w-full mb-4 md:w-5/6" style="color:'#5e5e5e'"><span class="text-yellow-300">{{"Free"| translate}}</span>{{' & Simple.' | translate}}
      </h3>
      <p class="homep text-start md:mb-4 mb-2 text-sm Poppins-Bold w-full md:w-5/6" style="color:'#5e5e5e'"> {{'Create professinal invoices and manage expenses. All you need for your growing business.' | translate}}</p>
      <div class="md:w-5/6 rounded-xl box-shadow-started shadow-3xl shadow-gray-900 bg-white w-full max-w-[1440px]">



        <form [formGroup]='signupForm'>
          <div class="p-3 pading-custom">
            <div class="mb-3">
              <p class="text-sm Poppins-Bold">{{"Get Started Now"| translate}}</p>
            </div>
            <div class="mb-2 relative">
              <input formControlName="email" placeholder="Email" type="email" class="border-1 border-gray-300 rounded-lg md:w-full w-full text-sm bg-white">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer absolute inset-y-0 ltr:right-[10px] rtl:left-0 top-2 flex items-center me-4">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75" />
                </svg>

                <div *ngIf='formErrors.email' class="text-sm text-red-400 ms-2">{{formErrors.email}}</div>
            </div>
            <div class="mb-3 relative">
              <input formControlName='password' [type]='showPassword ? "text": "password"' name="password" id="" placeholder="Password" class="border-1 border-gray-300 rounded-lg w-full text-sm bg-white">

                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer absolute inset-y-0 ltr:right-[10px] rtl:left-0 top-2 flex items-center me-4" [ngClass]="showPassword ? 'hidden' : 'block'" (click)="showPassword = !showPassword" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M10 12a2 2 0 100-4 2 2 0 000 4z" />
                    <path fill-rule="evenodd" d="M.458 10C1.732 5.943 5.522 3 10 3s8.268 2.943 9.542 7c-1.274 4.057-5.064 7-9.542 7S1.732 14.057.458 10zM14 10a4 4 0 11-8 0 4 4 0 018 0z" clip-rule="evenodd" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer absolute inset-y-0 ltr:right-[10px] rtl:left-0 top-2 flex items-center me-4" (click)="showPassword = !showPassword" [ngClass]="showPassword ? 'block' : 'hidden'"  viewBox="0 0 20 20" fill="currentColor">
                    <path fill-rule="evenodd" d="M3.707 2.293a1 1 0 00-1.414 1.414l14 14a1 1 0 001.414-1.414l-1.473-1.473A10.014 10.014 0 0019.542 10C18.268 5.943 14.478 3 10 3a9.958 9.958 0 00-4.512 1.074l-1.78-1.781zm4.261 4.26l1.514 1.515a2.003 2.003 0 012.45 2.45l1.514 1.514a4 4 0 00-5.478-5.478z" clip-rule="evenodd" />
                    <path d="M12.454 16.697L9.75 13.992a4 4 0 01-3.742-3.741L2.335 6.578A9.98 9.98 0 00.458 10c1.274 4.057 5.065 7 9.542 7 .847 0 1.669-.105 2.454-.303z" />
                </svg>
                <div class='text-red-400' *ngIf="signupError$ | async as error">{{error | titlecase}}</div>
                <div *ngIf='formErrors.password' class="text-sm text-red-400 ms-2">{{formErrors.password}}</div>
            </div>
            <div class="mb-3">
              <ng-select [items]="countries"
              class="border-1 border-gray-300 rounded-lg md:w-full w-full text-sm bg-white"
                bindLabel="countryName"
                bindValue="_id"
                [clearable]="false"
                formControlName="country"
                placeholder='{{"Select Country" | translate}}'
                [(ngModel)]="country"
                (change) = "changeCountry($event)">
             </ng-select>
              <div class="text-sm text-red-400 ms-2" *ngIf='formErrors.country'>
              {{formErrors.country}}</div>
              </div>
              <div class="mb-2 relative">
                <input formControlName="expertReferralCode" placeholder="Referral Code(optional)" type="text" class="border-1 border-gray-300 rounded-lg md:w-full w-full text-sm bg-white">
              </div>
            <div class="">
              <button (click)="signup()" class='w-full px-6 py-2 background-landing rounded-full text-white border mb-[0.5rem] Poppins-Bold text-xs me-4 flex justify-center'>
                {{'Sign Up For Free' | translate}}
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-3 pt-1 opacity-1 font-bold text-white">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 19.5l15-15m0 0H8.25m11.25 0v11.25" />
                </svg>
                </button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="md:w-7/12 w-full" >
      <img src="assets/images/Group 3690.png" class="object-contain w-full mt-16 mt-queries" />
    </div>
  </div>
</div>

<div id="solution" class="md:w-5/6 w-full mx-auto mt-20 max-w-[1280px] px-3">
  <h3 class="text-start Poppins-ExtraBold md:text-2xl text-3xl pt-3 pb-4" style="color:'#5e5e5e'">{{'Make your business smarter. Everything is done to make accounting with NuMetric understandable and accessible!' | translate}}</h3>
</div>
<div class="md:w-5/6 w-full mx-auto px-3 rounded-xl hidden sm:flex max-w-[1440px] bg-white mt-3">
  <div class="w-6/12">
    <ul class="w-full list-none inline-block overflow-x-auto">
      <li [ngClass]="{'change-color': type==='professional'}" class="flex cursor-pointer outline-0 whitespace-normal list-style-none text-gray-400" (click)="changeType('professional')">
          <a class="flex w-full border-s-2 flex-wrap">
              <div class="border-b-0 flex w-1/12 h-full"></div>
              <div class="border-b-2 border-opacity-1 flex item-center w-10/12 py-2 flex-wrap">
                  <div class="w-6 h-auto">
                      <img src="assets/images/invoice_landing.png"  class="w-6 h-auto opacity-[0.5]" loading="lazy">
                  </div>
                  <div class="w-11/12 pl-2 sm:w-5/6">
                      <div class="text-md w-full Poppins-Bold">
                          {{'Professional Invoicing'| translate}}
                      </div>
                  </div>
                  <div class="flex text-xs text-gray-400 py-3 Poppins-Bold opacity-[0.5]" id="content">
                      {{"Create professional and elegante-looking estimates and invoice in a matter of seconds, and quickly deliver to the client"| translate}}
                  </div>
              </div>
              <span class="flex h-full item-center py-10 w-1/12 border-b-2 border-opacity-1 text-black font-bold text-2xl ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 transform rtl:rotate-180">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                </svg>
              </span>
          </a>
      </li>
      <li [ngClass]="{'change-color': type==='expenses'}" class="flex cursor-pointer outline-0 whitespace-normal list-style-none text-gray-400" (click)="changeType('expenses')">
        <a class="flex w-full border-s-2 flex-wrap">
            <div class="border-b-0 flex w-1/12 h-full"></div>
            <div class="border-b-2 border-opacity-1 flex item-center w-10/12 py-2 flex-wrap">
                <div class="w-6 h-auto">
                    <img src="assets/images/expenses-landing-2.png"  class="w-6 h-auto opacity-[0.5]" loading="lazy">
                </div>
                <div class="w-11/12 pl-2 sm:w-5/6">
                    <div class="text-md w-full Poppins-Bold">
                        {{'Never Miss an Expense'| translate}}
                    </div>
                </div>
                <div class="flex text-xs text-gray-400 py-3 Poppins-Bold opacity-[0.5]" id="content">
                    {{'Expense description'| translate}}
                </div>
            </div>
            <span class="flex h-full item-center py-10 w-1/12 border-b-2 border-opacity-1 text-black font-bold text-2xl">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 transform rtl:rotate-180">
                <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
              </svg>
            </span>
        </a>
      </li>
      <li [ngClass]="{'change-color': type==='portfolio'}" class="flex cursor-pointer outline-0 whitespace-normal list-style-none text-gray-400" (click)="changeType('portfolio')">
        <a class="flex w-full border-s-2 flex-wrap">
          <div class="border-b-0 flex w-1/12 h-full"></div>
          <div class="border-b-2 border-opacity-1 flex item-center w-10/12 py-2 flex-wrap">
              <div class="w-6 h-auto">
                  <img src="assets/images/portfolio-landing.png"  class="w-6 h-auto opacity-[0.5]" loading="lazy">
              </div>
              <div class="w-11/12 pl-2 sm:w-5/6">
                  <div class="text-md w-full Poppins-Bold">
                      {{'Know Your Business Better'| translate}}
                  </div>
              </div>
              <div class="flex text-xs text-gray-400 py-3 Poppins-Bold opacity-[0.5]" id="content">
                  {{'Reporting description'| translate}}
              </div>
          </div>
          <span class="flex h-full item-center py-10 w-1/12 border-b-2 border-opacity-1 text-black font-bold text-2xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 transform rtl:rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </span>
        </a>
      </li>
      <li [ngClass]="{'change-color': type==='dashboard'}" class="flex cursor-pointer outline-0 whitespace-normal list-style-none text-gray-400" (click)="changeType('dashboard')">
        <a class="flex w-full border-s-2 flex-wrap">
          <div class="border-b-0 flex w-1/12 h-full"></div>
          <div class="border-b-2 border-opacity-1 flex item-center w-10/12 py-2 flex-wrap">
            <div class="w-6 h-autp">
                <img src="assets/images/dashboard-landing.png"  class="w-6 h-auto opacity-[0.5]" loading="lazy">
            </div>
            <div class="w-11/12 pl-2 sm:w-5/6">
                <div class="text-md w-full Poppins-Bold">
                    {{'Fast & Easy, for You and Your Clients'| translate}}
                </div>
            </div>
            <div class="flex text-xs text-gray-400 py-3 Poppins-Bold opacity-[0.5]" id="content">
                {{'Dashboard description'| translate}}
            </div>
          </div>
          <span class="flex h-full item-center py-10 w-1/12 border-b-2 border-opacity-1 text-black font-bold text-2xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 transform rtl:rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </span>
        </a>
      </li>
      <li [ngClass]="{'change-color': type==='verified'}" class="flex cursor-pointer outline-0 whitespace-normal list-style-none text-gray-400" (click)="changeType('verified')">
        <a class="flex w-full border-s-2 flex-wrap">
          <div class="border-b-0 flex w-1/12 h-full"></div>
          <div class="flex item-center w-10/12 py-2 flex-wrap">
            <div class="w-6 h-auto ">
              <img src="assets/images/verified-landing.png"  class="w-6 h-auto opacity-[0.5]" loading="lazy">
            </div>
            <div class="w-11/12 pl-2 sm:w-5/6">
              <div class="text-md w-full Poppins-Bold">
                  {{'Keep Tabs of Your Business Health'| translate}}
              </div>
            </div>
            <div class="flex text-xs text-gray-400 py-3 Poppins-Bold opacity-[0.5]" id="content">
              {{"No more happing between apps to track your books NuMetric.work bring all of your accounts, transaction and money info into one place"| translate}}
            </div>
          </div>
          <span class="flex h-full item-center py-10 w-1/12 text-black font-bold text-2xl">
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-4 transform rtl:rotate-180">
              <path stroke-linecap="round" stroke-linejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </span>
        </a>
      </li>
    </ul>
  </div>
  <div class="w-6/12">
  <div class="w-full pl-8 items-center flex justify-center">
    <div class="w-full outline-none block m-auto" *ngIf="type === 'professional'">
      <img src="assets/images/Invoicing.png" class="w-full h-full block my-auto ps-3 pt-16" loading="lazy"/>
    </div>
    <div class="w-full outline-none flex items-center justify-center" *ngIf="type === 'expenses'">
      <img src="assets/images/expenses.png" class="w-full h-auto block my-auto ps-3 pt-16" loading="lazy"/>
    </div>
    <div class="w-full outline-none block m-auto" *ngIf="type === 'portfolio'">
      <img src="assets/images/reports.png" class="w-full h-auto block my-auto ps-3 pt-16" loading="lazy"/>
    </div>
    <div class="w-full outline-none block m-auto" *ngIf="type === 'dashboard'">
      <img src="assets/images/minidashboard.png" class="w-full h-auto block my-auto ps-3 pt-16" loading="lazy"/>
    </div>
    <div class="w-full outline-none block m-auto" *ngIf="type === 'verified'">
      <img src="assets/images/accounting.png" class="w-full h-auto block my-auto ps-3 pt-16" loading="lazy"/>
    </div>
  </div>
  </div>
</div>
<div class="md:hidden mx-auto px-3 rounded-xl flex max-w-[1280px] bg-white w-full">
<my-tabs id="landing-tabs" [navigation]="true ">
  <my-tab tabTitle="{{'Invoicing' | translate}}">
    <div class="flex md:flex-row flex-col-reverse ">
      <div class="flex-1">
        <h3 class="text-start text-black Poppins-Bold text-2xl pt-3 pb-4">{{'Professional Invoicing' | translate}}</h3>
        <p class="text-start text-black mb-4 Poppins-Light">{{'invoice description' | translate}}</p>
      </div>
      <div class="flex-1">
        <img src="assets/images/Invoicing.png" class="w-96 block mx-auto text-center mt-3" />
      </div>
    </div>
  </my-tab>
  <my-tab tabTitle="{{'Expenses' | translate }}">
    <div class="flex md:flex-row flex-col-reverse ">
      <div class="flex-1">
        <h3 class="text-start text-black Poppins-Bold text-2xl pt-3 pb-4">{{'Never Miss an Expense' | translate }} </h3>
        <p class="text-start text-black mb-4 Poppins-Light">{{'Expense description' | translate }}</p>

      </div>
      <div class="flex-1">
        <img src="assets/images/expenses.png" class="w-96 block mx-auto text-center mt-3" />
      </div>
    </div>
  </my-tab>
  <my-tab tabTitle="{{'Reporting' | translate }}">
    <div class="flex md:flex-row flex-col-reverse ">
      <div class="flex-1">
        <h3 class="text-start text-black Poppins-Bold text-2xl pt-3 pb-4">{{'Know Your Business Better' | translate }}</h3>
        <p class="text-start text-black mb-4 Poppins-Light">{{'Reporting description' | translate }}</p>

      </div>
      <div class="flex-1">
        <img src="assets/images/reports.png" class="w-96 block mx-auto text-center mt-3" />
      </div>
    </div>
  </my-tab>
  <my-tab tabTitle="{{'Dashboard' | translate }}">
    <div class="flex md:flex-row flex-col-reverse ">
      <div class="flex-1">
        <h3 class="text-start text-black Poppins-Bold text-2xl pt-3 pb-4">{{'Fast & Easy, for You and Your Clients' | translate }} </h3>
        <p class="text-start text-black mb-4 Poppins-Light">{{'Dashboard description' | translate }}</p>
      </div>
      <div class="flex-1">
        <img src="assets/images/minidashboard.png" class="w-96 block mx-auto text-center mt-3" />
      </div>
    </div>
  </my-tab>
  <my-tab tabTitle="{{'Accounting' | translate }}">
    <div class="flex md:flex-row flex-col-reverse ">
      <div class="flex-1">
        <h3 class="text-start text-black Poppins-Bold text-2xl pt-3 pb-4">{{'Keep Tabs of Your Business Health' | translate }}</h3>
        <p class="text-start text-black mb-4 Poppins-Light">{{'Dashboard description' | translate }}</p>
      </div>
      <div class="flex-1">
        <img src="assets/images/accounting.png" class="w-96 block mx-auto text-center mt-3" />
      </div>
    </div>
  </my-tab>
</my-tabs>
</div>

<div id="about" class="md:w-5/6 w-full mx-auto md:py-12 mt-36 rounded-xl bg-white max-w-[1280px] box-shadow-landing">
  <div class="flex md:flex-row flex-col-reverse">
    <div class="md:w-5/12 w-full md:pt-8 pt-8">
      <h3 class="text-start Poppins-ExtraBold md:text-3xl text-3xl pl-16 pr-16" style="color:'#5e5e5e'">{{'Accounting software for Small Businesses' | translate }}
      </h3>
      <p class="text-start text-gray-800 md:mb-4 mb-2 Poppins-Bold pl-16 pr-16 text-xs">{{'About description' | translate }}</p>
      <button routerLink='auth/signup' class="w-32 ps-2 h-12 ml-16 mr-16 bg-gradient rounded-full text-white border Poppins-Light text-xs">
        <div class="Poppins-Light flex justify-around items-center">
          {{'Sign Up For Free' | translate}}
        </div>
      </button>
    </div>
    <div class="md:w-7/12">
      <img src="assets/images/airfocus-IoINVPQe738-unsplash.png" class="md:w-[474px] w-full block mx-auto text-center mt-3" />
    </div>
  </div>
</div>

<div id="pricing" class="md:w-5/6 w-full mt-16 mx-auto block md:py-12 max-w-[1280px]">
  <h3 class="text-center Poppins-Bold md:text-3xl text-xl pt-3" style="color:'#5e5e5e'">{{'Simple Transparent Pricing' | translate}}</h3>
    <section class="dark:bg-gray-800 dark:text-gray-100">
      <div class="container mx-auto p-6 overflow-x-auto">
        <table class="w-full border-t-2">
          <caption class="sr-only">Pricing plan comparison</caption>
          <div class="flex w-full mt-4 space-x-2 justify-center  mobilescreen">
            <div *ngFor='let plan of subscriptionPlans; let i = index' class=" w-1/4 mobileWidth">
                <ng-container [ngTemplateOutlet]="this[subscriptionPlans[i].planName]"
                    [ngTemplateOutletContext]='{$implicit: plan}'>
                </ng-container>
            </div>
          </div>
          <div class="my-3" >
              <p class="mt-10 text-2xl Poppins-Bold" >{{'Pay-As-You-Go' | translate}}</p>
              <div>
                  <p class="text-md Poppins-SemiBold mb-4 mt-10">{{'Storage' | translate }}</p>
                  <div class="flex space-x-2 mobilescreen">
                      <div *ngFor='let plan of storagePlans; let i = index' class="w-1/4 mobileWidth">
                          <ng-container [ngTemplateOutlet]="Storage"
                          [ngTemplateOutletContext]='{$implicit: plan}'>
                      </ng-container>
                      </div>
                  </div>
              </div>
              <div>
                  <p class="text-md Poppins-SemiBold mb-4 mt-10">{{'Select the required transaction limit' | translate }}</p>
                  <div class="flex space-x-2  mobilescreen">
                      <div *ngFor='let plan of transactionPlans; let i = index' class="flex-1 w-1/4 mobileWidth">
                          <ng-container [ngTemplateOutlet]="transaction"
                          [ngTemplateOutletContext]='{$implicit: plan}'>
                      </ng-container>
                      </div>
                  </div>
              </div>
          </div>
        </table>
      </div>
    </section>
</div>


<div id="faq" class="md:w-5/6 w-full block md:py-8  max-w-[1280px] mx-auto">
  <h3 class="text-center text-black Poppins-Bold text-3xl pt-3 pb-4">{{'Frequently Asked Questions' | translate}}</h3>

  <div class="md:p-3 p-5 border-b-2 border-gray-200 mb-1">
    <details open>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg justify-between">1. {{'Do I need to install any software?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 1' | translate }}</p>
      </div>
    </details>
  </div>
  <div class="md:p-3 p-5 border-b-2 border-gray-200 mb-1">
    <details>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg">2. {{'If I sign up, what do I get?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 2' | translate }}</p>

      </div>
    </details>
  </div>

  <div class="md:p-3 p-5 border-b-2 border-gray-200 mb-1">
    <details>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg">3. {{'If I use NuMetric.work, do I still need an accountant?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 3' | translate }}</p>

      </div>
    </details>
  </div>

  <div class="md:p-3 p-5 border-b-2 border-gray-200 mb-1">
    <details>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg">4. {{'Can I upgrade or downgrade my account at any time?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 4' | translate }}</p>

      </div>
    </details>
  </div>
  <div class="md:p-3 p-5 border-b-2 border-gray-200 mb-1">
    <details>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg">5. {{'Do you provide support?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 5' | translate }}</p>

      </div>
    </details>
  </div>
  <div class="md:p-3 p-5 mb-1 border-b-0 border-gray-200">
    <details>
      <summary class="flex justify-between items-center">
        <h3 class="Poppins-Bold md:text-lg">6. {{'How secure is Numetric.work?' | translate }}</h3>
      </summary>
      <div class="pt-2">
        <p>{{'faq 6' | translate }}</p>
      </div>
    </details>
  </div>

</div>


<div *ngIf="showModal" class="overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none justify-center items-center flex">
  <div class="relative w-auto my-6 mx-auto max-w-6xl">
    <!--content-->
    <div class="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
      <!--header-->
      <div class="flex items-start justify-center p-5 rounded-t">
        <h3 class="text-3xl font-semibold text-center">
          Welcome To Numetric
        </h3>
      </div>
      <!--body-->
      <div class="relative p-6 flex-auto">
        <p class="my-4 text-slate-500 text-lg leading-relaxed">
          To Checkout out Product specifically your country, please visit the {{this.country == 'Jordan' ? "Jordan site" : "Saudi Arabia site"}}
        </p>
      </div>
      <!--footer-->
      <div class="flex items-center justify-center p-6 rounded-b">
        <button class="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button" (click)="toggleModal()">
          Close
        </button>
        <button class="bg-green-500 text-white active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150" type="button">
          <a href="{{redirectLink}}">Take me there</a>
        </button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="showModal" class="opacity-25 fixed inset-0 z-40 bg-black"></div>



<ng-template #Budget let-plan>
  <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
      <div class="bg-gradient h-1/3 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Poppins-Bold mt-3">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-3">
              <p class="text-xl pt-4 text-white Poppins-Bold">$</p>
              <p class="text-[50px] text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <ol class="space-y-4 flex-1 list-none ms-4 p-3">
          <li class="flex list-item items-center gap-x-2 ">
              <p class="text-sm Poppins-Bold">{{"P/L Budget Subscription Description" | translate }}</p>
          </li>
      </ol>
      <div class="mt-4 flex justify-center">
        <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
        (handleClick)='sendToSignup()'></app-button>
      </div>
  </div>
</ng-template>
<ng-template #Payroll let-plan>
  <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
      <div class="bg-gradient h-1/3 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Poppins-Bold mt-3">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-3">
              <p class="text-xl pt-4 text-white Poppins-Bold">$</p>
              <p class="text-[50px] text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <ol class="space-y-4 flex-1 list-none ms-4 p-3">
          <li class="flex list-item items-center gap-x-2 ">
              <p class="text-sm Poppins-Bold">{{"Payroll Subscription Description" | translate }}</p>
          </li>
      </ol>
      <div class="mt-4 flex justify-center">
          <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
          (handleClick)='sendToSignup()'></app-button>
      </div>
  </div>
</ng-template>
<ng-template #Inventory let-plan>
  <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
      <div class="bg-gradient h-1/3 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Poppins-Bold mt-3">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-3">
              <p class="text-xl pt-4 text-white Poppins-Bold">$</p>
              <p class="text-[50px] text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <ol class="space-y-4 flex-1 list-none ms-4 p-3">
          <li class="flex list-item items-center gap-x-2 ">
              <p class="text-sm Poppins-Bold">{{"Inventory Subscription Description" | translate }}</p>
          </li>
      </ol>
      <div class="mt-2 flex justify-center">
        <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
        (handleClick)='sendToSignup()'></app-button>
      </div>
  </div>
</ng-template>
<ng-template #Recurring let-plan>
  <div class="rounded-xl pb-2 flex flex-col bg-white shadow-xl h-[400px] overflow-hidden">
      <div class="bg-gradient h-1/3 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Poppins-Bold mt-3">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-3">
              <p class="text-xl pt-4 text-white Poppins-Bold">$</p>
              <p class="text-[50px] text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-4 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <ol class="space-y-4 flex-1 list-none ms-4 p-3">
          <li class="flex list-item items-center gap-x-2 ">
              <p class="text-sm Poppins-Bold">{{"Recurring Subscription Description" | translate }}</p>
          </li>
      </ol>
      <div class="mt-4 flex justify-center">
        <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
        (handleClick)='sendToSignup(plan)'></app-button>
      </div>
  </div>
</ng-template>
<ng-template #Storage let-plan>
  <div class="rounded-xl flex flex-col bg-gradient shadow-xl h-[250px] overflow-hidden">
      <div class="flex-1 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-1 w-fit rounded-md font-bold Poppins-Bold mt-3">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-5">
              <p class="text-xl pt-2 text-white Poppins-Bold">$</p>
              <p class="text-5xl text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <div class="h-[30%] bg-white flex justify-center items-center">
          <!-- <ng-container *ngIf="isSubscribed(plan?._id) else buyButtons">
              <div *ngIf="!isCanceled(plan?._id) else lastDate" class="flex w-full justify-center space-x-2">
                  <app-button
                      buttonText='{{"Add more storage" | translate }}' type='primary' rounded='full'
                      (handleClick)='updateQuantity(plan)'>
                  </app-button>
                  <app-button
                      buttonText='{{"Cancel" | translate }}' type='danger' rounded='full'
                      (handleClick)='confirmCancelPlan(plan)'>
                  </app-button>
              </div>
              <ng-template #lastDate>
                  <p class="Poppins" >{{'Valid till:' | translate}} {{getLastDate(plan?._id) | date: 'dd/MM/yyyy'}}</p>
              </ng-template>
          </ng-container>
          <ng-template #buyButtons>
              <app-button buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
              (handleClick)='showConfiramtionDialoge(plan)'></app-button>
          </ng-template> -->
          <!-- <app-button buttonText='{{"Coming Soon!" | translate }}' type='primary' rounded='full'></app-button> -->
          <app-button  buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
          (handleClick)='sendToSignup()'></app-button>
        </div>
  </div>
</ng-template>

<!-- Transaction Plan -->
<ng-template #transaction let-plan>
  <div class="rounded-xl flex flex-col bg-gradient shadow-xl h-[250px] overflow-hidden">
      <div class="flex-1 px-3 py-2">
          <div class="">
              <p class="text-white text-xl px-2 py-2 mb-2 w-fit rounded-md font-bold Poppins-Bold ">{{plan?.planName | translate}}</p>
          </div>
          <div class="flex space-x-2  py-2">
              <p class="text-xl pt-2 text-white Poppins-Bold">$</p>
              <p class="text-5xl text-white Poppins-Bold">{{plan?.planPrice | translate }}</p>
              <p class="text-sm pt-2 text-gray-400">/ {{plan?.planDuration | translate}}</p>
          </div>
      </div>
      <div class="h-[30%] flex justify-center items-center space-x-2 bg-white">
        <app-button  buttonText='{{"Buy this" | translate }}' type='primary' rounded='full'
        (handleClick)='sendToSignup()'></app-button>
      </div>
  </div>
</ng-template>