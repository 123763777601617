import { Location } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store, select } from '@ngrx/store';
import { DeviceDetectorService } from 'ngx-device-detector';
import { QuillEditorComponent } from 'ngx-quill';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import * as QuillNamespace from 'quill';
import QuillAutoDetectUrl, { QuillAutoDetectUrlOptions } from 'quill-auto-detect-url';
import QuillEmoji from 'quill-emoji';
import QuillMention from 'quill-mention';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LanguageService } from 'src/app/shared/services/language.service';
import { UserService } from 'src/app/shared/services/user.service';
import { RootReducerState } from 'src/app/store/reducers';
import { selectBusiness } from 'src/app/store/selectors/business.selector';
import { selectUser } from 'src/app/store/selectors/user.selectors';
import { environment } from 'src/environments/environment';
import { ChatsService } from '../../chats.service';



const SOCKET_ENDPOINT = 'https://api.numetric.co';

@Component({
  selector: 'app-chats-home',
  templateUrl: './chats-home.component.html',
  styleUrls: ['./chats-home.component.scss']
})
export class ChatsHomeComponent implements OnInit {
  @ViewChild('messageContainer') messageContainer: ElementRef;
  // @ViewChild(QuillEditorComponent, { static: true }) editor: QuillEditorComponent;
  @ViewChild('editor') editor: QuillEditorComponent;
  socket: any;
  message!: string;
  createChatType!: string;
  output: any[] = [];
  feedback!: string;
  business$: Observable<any>;
  unsubscribe$ = new Subject();
  user$: Observable<any>;
  userDetail: any;
  originalUserDetails: any;
  usersList: any;
  membersToAdd: any;
  memberToRemove: any;
  leaveGroup = false;
  userBusiness: any;
  channelPermission: any;
  showDialog = false;
  newChatUser: string;
  allChatList: any;
  individualChatList: any;
  groupChatList: any;
  channelChatList: any;
  selectedChatToShow: any;
  chatDetailsToEdit: any;
  isMemberOfChat = false;
  searchText: any;
  selectedByUser: any;
  initialiseMessage: string;
  channelGroupName: string;
  createChatStep: string;
  channelType: string;
  chatToShowId: any;
  baseURL = environment.apiBaseURL;
  html = '';
  mentionSelected = false;
  choices: any;
  originalUsersList;
  modules = {};
  editDialog = false;
  findMembers: string;
  addMoreDialog = false;
  addMoreUsers: any;
  editName = false;
  editDescription = false;
  warningDialog = false;
  mentionedItems = [];
  remainingUsersList: any;
  today = new Date().toDateString();
  onlineUsers: any;
  toMakeAdmin: any;
  membersForAdmin: any;
  allNotifications: any;
  notificationsCount: number;
  lang: any;
  chatSubscription: any;
  typingSubscription;
  notificationListen;
  getChatSubscription;
  isMobileDevice = false;
  globalSearchModal = false;
  globalSearchText = null;
  globalSearchData: any;
  routeChatId: any;
  globalSearchMessageId: any;
  isFirstLoad = false;

  constructor(private chatService: ChatsService, private elementRef: ElementRef, private deviceService: DeviceDetectorService, private route: Router, private location: Location, private activatedRoute: ActivatedRoute, private languageService: LanguageService, private toastr: ToastrService, private spinner: NgxSpinnerService, private store: Store<RootReducerState>, private rootStore: Store<RootReducerState>, private userService: UserService) {
    this.business$ = this.rootStore.pipe(select(selectBusiness));
    this.user$ = this.store.pipe(select(selectUser));

    const Quill: any = QuillNamespace;
    Quill.register({ 'modules/mention': QuillMention }, true);
    Quill.register('modules/emoji-shortname', QuillEmoji.ShortNameEmoji);
    Quill.register("modules/autoDetectUrl", QuillAutoDetectUrl);

    console.log('text-change', this.editor);
    // this.editor?.quillEditor?.on('text-change', () => {
    //   console.log('text-change');
      
    //   // this.transformText();
    // });
    
    this.modules = {
      'emoji-shortname': true,
      'emoji-textarea': false,
      'emoji-toolbar': true,
      toolbar: {
        container: [
          ['bold', 'italic', 'strike'],
          [{ 'list': 'ordered'}, { list: 'bullet' }],
          ['blockquote'],
          // [{ 'mention': 'http://localhost:3001/uploads/image1704963300209.png' }],
          ['emoji'],
          ['link'],
        ],
        handlers: { 
          emoji: function () {},
          mention: function() {
            // Custom handler for the mention button
            const editor = this.quill;
            const range = editor.getSelection(true);
            const index = range.index + 1;
            console.log("index1234", index, range.index);
            
            editor.insertText(index, '@');
            editor.setSelection(index + 1);
          }
        },
      },
      autoDetectUrl: {
        urlRegularExpression: /(https?:\/\/|www\.)[\w-.]+\.[\w-.]+[\S]+/i,
      } as QuillAutoDetectUrlOptions,
      keyboard: {
        bindings: {
          shift_enter: {
            key: 13,
            shiftKey: true,
            handler: (range, ctx) => {
              console.log("shift+enter");

                // Insert a line break
                this.editor.quillEditor.insertText(range.index, '\n');

                setTimeout(() => {
                  this.editor.quillEditor.setSelection(range.index + 1);
                  const bounds = this.editor.quillEditor.getBounds(range.index + 1);
                  if (bounds) {
                    const cursorTop = bounds.top;
                    const cursorBottom = bounds.bottom;
                    const windowHeight = window.innerHeight;
                    const windowScrollTop = window.pageYOffset || document.documentElement.scrollTop;
            
                    if (cursorBottom > windowHeight + windowScrollTop) {
                      // Cursor is below the visible window area, scroll down
                      window.scrollTo(0, cursorBottom - windowHeight + 20);
                    } else if (cursorTop < windowScrollTop) {
                      // Cursor is above the visible window area, scroll up
                      window.scrollTo(0, cursorTop - 20);
                    }
                  }
                }, 0);
            },
          },
          enter: {
            key: 13,
            handler: (event) => {
              const mention = this.editor.quillEditor.getSelection().index;
              // console.log('mention1', mention);
              this.sendMessage();
              // console.log(event) // submit form }
            },
          },
        },
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: (searchTerm, renderList, mentionChar) => {
          const values = this.usersList;

          if (searchTerm.length === 0) {
            // console.log(values);

            renderList(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i++)
              if (
                ~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())
              )
                matches.push(values[i]);
            renderList(matches, searchTerm);
          }
        },
        onSelect: (item, insertItem) => {
          // this.mentionedItems.push({mentionedTo: item.id, mentionedById: this.userDetail._id, mentionedByName: this.userDetail.firstName + ' ' + this.userDetail.lastName});
          this.mentionedItems.push(item.id);

          insertItem(item);
        },
      },
    };
    this.isMobileDevice = this.deviceService.isMobile();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.adjustHeight();
  }

  @HostListener('window:scroll')
  onWindowScroll() {
    // this.adjustHeight();
    // document.getElementById('chat-window').style.height = `calc(100vh - ${250 + (window.outerHeight - window.innerHeight)}px)`;
    console.log("window.innerHeight", window.innerHeight, window.outerHeight);
  }

  private adjustHeight() {
    // const windowHeight = window.innerHeight;
    // const adjustedHeight = windowHeight - 500; // Adjust as needed
    
    // console.log("classCheck", this.elementRef);
    const container = document.getElementById("main-container");
    if(container) {
      document.getElementById("main-container").classList.remove("min-h-screen")
    }
    // this.elementRef.nativeElement.style.height = `${adjustedHeight}px`;
  }

  getText() {
    console.log(this.editor.quillEditor.getContents());
    const { ops } = this.editor.quillEditor.getContents();
    const result = ops.reduce((acc, { insert }) => {
      if (typeof insert === 'string') {
        acc += insert;
      } else {
        acc += insert.mention.value;
      }

      return acc;
    }, '');

    console.log({ result });
  }

  toggleSidebar(): void {
    // document.getElementById("chat-sidebar").classList.add("toggle-sidebar");
    const classes = document.getElementById("chat-sidebar").classList;
    console.log('classes', classes.contains('toggle-sidebar'));
    if(classes.contains('toggle-sidebar')){
      document.getElementById("chat-sidebar").classList.remove('toggle-sidebar');
      this.isFirstLoad = false;
    } else {
      document.getElementById("chat-sidebar").classList.add("toggle-sidebar");
    }
    // document.getElementById('chat-window').style.height = `calc(100vh - ${250 + (window.outerHeight - window.innerHeight)}px)`;
    console.log("window.innerHeight", window.innerHeight, window.outerHeight);    
    

  };

  closeSidebar(): void {
    this.isMobileDevice = this.deviceService.isMobile();
    console.log('isMobileDevice', this.isMobileDevice);
    
    if(this.isMobileDevice){
      this.toggleSidebar();
    }
  }

  ngOnInit(): void {
    // this.routeChatId = this.activatedRoute.snapshot.params.id;
    this.adjustHeight();
    
    this.languageService.getLanguage().subscribe((language) => {
      this.lang = language;      
    });
    this.typingSubscription = this.chatService.listen('typing').subscribe((data)=>{
      this.updateFeedback(data)
      // console.log("listening", data);
      
    });
    // this.notificationListen = this.chatService.listen('notification').subscribe((data)=>{
    //   // console.log("mention", data);
    //   // if(data.toUser.includes(this.userDetail._id) && data.fromUser !== this.userDetail._id){
    //   //   this.toastr.info(data.message);
    //   // }
    // });
    this.chatService.listen('getAllNotifications').subscribe((data)=>{
      // console.log("getAllNotifications", data);
      this.allNotifications = data.filter((item)=>(item.toUser?.includes(this.userDetail?._id) || item.toUser === this.userDetail?._id)&& (item.fromUser !== this.userDetail?._id || (item.fromUser === this.userDetail?._id && item.entityType === 'taskReminder')) && item.businessId === this.userBusiness._id).slice().reverse();
      this.notificationsCount = this.allNotifications.filter((item)=>!item.seen).length;
      // this.allNotifications;
      // console.log("getAllNotifications1", this.allNotifications);
      // if(data.toUser.includes(this.userDetail._id) && data.fromUser !== this.userDetail._id){
      //   this.toastr.info(data.message);
      // }
    });
    this.getChatSubscription = this.chatService.listen('getChat').subscribe((data)=>{
      this.spinner.hide();
      console.log("getChat", data, this.allChatList);
      if(data.chatUserId === this.userDetail?._id) {
        console.log( this.groupMessagesByDate(data.result));
        
        this.output = this.groupMessagesByDate(data.result);
        // console.log("changed2", this.output);

        if(this.allChatList){
          this.spinner.hide();
          switch(data.chatType){
            case "Individual":
              console.log("this.individualChatList1234", this.individualChatList);
              
              const indexOne = this.individualChatList.findIndex((item)=>item._id === data.chatId);
              if(this.individualChatList[indexOne]){
                this.individualChatList[indexOne].unreadCount = 0;
              }
              console.log('2134item', this.individualChatList[indexOne]);
              break;
            case "Group":
              const indexTwo = this.groupChatList.findIndex((item)=>item._id === data.chatId);
              this.groupChatList[indexTwo].unreadCount = 0;
              console.log('2134item', this.groupChatList[indexTwo].unreadCount);
              break;
            case 'Channel':
              const indexThree = this.channelChatList.findIndex((item)=>item._id === data.chatId);
              this.channelChatList[indexThree].unreadCount = 0;
              console.log('2134item', this.channelChatList[indexThree].unreadCount);
              break;
          }
        }
        setTimeout(() => {
          let id = this.globalSearchMessageId ? this.globalSearchMessageId : 'lastMessage';
          console.log("document.getElementById(id)", this.output, document.getElementById(id));
          
          document.getElementById(id)?.scrollIntoView({
            block: 'nearest',
          });

          if(this.globalSearchMessageId){
            this.globalSearchMessageId = null;
            this.globalSearchModal = false;
            this.globalSearchText = null;
            this.globalSearchData = null;
            const container = document.getElementById(id);
            container.style.backgroundColor = '#F7F7F7';


            // const container = document.getElementById(id);
            // const tempDiv = document.createElement('div');
            // tempDiv.innerHTML = container.innerHTML;
            // // Find the <p> element within the temporary <div>
            // const p = tempDiv.querySelector('p');

            // // Check if the <p> element exists
            // if (p) {
            //     // Set the id attribute of the <p> element
            //     p.id = 'test';
            //     // Update the innerHTML of the parent container with the modified HTML
            //     container.innerHTML = tempDiv.innerHTML;
            // } else {
            //     console.error('No <p> element found within the container.');
            // }
            
            

            setTimeout(() => {
              container.style.backgroundColor = 'transparent';
            }, 2000);
            
          }
        });
      }
    });
    this.chatSubscription = this.chatService.listen('chat').subscribe((data)=>{
console.log("kaise", data);

      const myMessage = this.allChatList.find(item=>item._id === data.chatId)
      // console.log('chat1234', myMessage, this.selectedChatToShow._id !== data.chatId, this.userDetail._id !== data.messageOwner._id);
      if(myMessage && this.selectedChatToShow._id !== data.chatId && this.userDetail._id !== data.messageOwner._id && myMessage.chatUsers.some(item=>item._id === this.userDetail._id)){
        this.toastr.info(`${data.messageOwner.firstName + ' ' + data.messageOwner.lastName} has sent a message in the ${myMessage.chatType === 'Individual' ? 'Personal' : myMessage.channelGroupName} chat`)
        switch(data.chatType){
          case "Individual":
            const indexOne = this.individualChatList.findIndex((item)=>item._id === data.chatId);
            this.individualChatList[indexOne].unreadCount = this.individualChatList[indexOne].unreadCount+1;
            // console.log('2134item', this.individualChatList[indexOne]);
            break;
          case "Group":
            const indexTwo = this.groupChatList.findIndex((item)=>item._id === data.chatId);
            this.groupChatList[indexTwo].unreadCount = this.groupChatList[indexTwo].unreadCount+1;
            // console.log('2134item', this.groupChatList[indexTwo].unreadCount);
            break;
          case 'Channel':
            const indexThree = this.channelChatList.findIndex((item)=>item._id === data.chatId);
            this.channelChatList[indexThree].unreadCount = this.channelChatList[indexThree].unreadCount+1;
            // console.log('2134item', this.channelChatList[indexThree].unreadCount);
            break;
        }
      }
      // console.log("gettingChatDetails", data.chatUsers.some(item=>item._id === this.userDetail._id));
      
      console.log("data12341234", this.selectedChatToShow);
      if(this.selectedChatToShow._id === data.chatId){

        // this.goToChat(this.selectedChatToShow, this.userDetail._id);
        // this.chatService.emit('markAsRead', { id: this.selectedChatToShow._id, userId: this.userDetail._id });

        this.updateMessage(data);
      }
    });
    this.chatService.listen('onlineUsers').subscribe((data)=>{
      this.onlineUsers = data;
    });
    //listening on chat created
    this.chatService.listen('chatCreated').subscribe((data)=>{
      const myChat = data.chatUsers.some(item=>(item.userId === this.userDetail._id && data.chatCreatedBy !== this.userDetail._id));
      if(myChat){
        this.getAllChats(false);
      }
    });
    //listening on users added or removed from group/channel
    this.chatService.listen('chatUpdated').subscribe((data)=>{
      console.log('chatUpdated12341234', data);
      
      if(data.updateType === 'remove'){
        const myChat = this.allChatList.find(item=>item._id === data._id) && data.chatUsers.some(item=>(item.userId === this.userDetail._id));
        if(myChat){
          this.getAllChats(false);
        }
      } else {
        const myChat = data.chatUsers.some(item=>(item.userId === this.userDetail._id));
        if(myChat){
          this.getAllChats(false);
        }
      }
    });
    this.chatService.listen('globalSearch').subscribe((data)=>{
      console.log('globalSearch', data);
      if(data.userId === this.userDetail._id){
        this.globalSearchData = data.messages;
      }
    });
    
    this.getUserBusiness();
  }

  isUserOnline(userId): boolean {    
    const userOnline = this.onlineUsers?.some(item=>item === userId);
    // console.log('userOnline', userOnline);
    
    return userOnline;
  }

  ngOnDestroy() {
    // console.log("disconnect", this.originalUserDetails);
    this.chatService.emit('logout', this.userDetail?._id);
    if(this.chatSubscription) {
      this.chatSubscription.unsubscribe();
    }
    if(this.getChatSubscription) {
      this.getChatSubscription.unsubscribe();
    }
    // this.notificationListen.unsubscribe();
    const container = document.getElementById("main-container");
    if(container) {
      document.getElementById("main-container").classList.add("min-h-screen")
    }
  }

  groupMessagesByDate(messages): any[] {
    const groupedMessages: any[] = [];
    const intervalThreshold = 2 * 60 * 1000;

    messages.forEach((message, index) => {
      const date = new Date(message.createdAt).toDateString();
      const currentTime = new Date(message.createdAt).getTime();
      const previousTime = index > 0 ? new Date(messages[index - 1].createdAt).getTime() : 0;
      const lastItem = messages[index - 1];
      // console.log('previousTime', message);
      
      const timeDifference = currentTime - previousTime;

      const showNameHeader = timeDifference > intervalThreshold || lastItem.messageOwner._id !== message.messageOwner._id;

      // Check if there's already a group for this date
      const existingGroup = groupedMessages.find(group => group.date === date);

      if (existingGroup) {
        // Add the message to the existing group
        existingGroup.messages.push({ ...message, showNameHeader });
      } else {
        // Create a new group for this date
        groupedMessages.push({ date, messages: [{ ...message, showNameHeader }] });
      }
    });

    return groupedMessages;
  }

  getUserBusiness(): void {
    console.log("getAllChatsOne");
    this.user$.subscribe((user) => {
      if (user._id && this.route.url === '/chats') {
        console.log("getAllChatsTwo", user);
        this.userDetail = JSON.parse(JSON.stringify((user)));

        this.chatService.emit('login', this.userDetail?._id);
        this.chatService.emit('getAllNotifications', this.userDetail._id);

        this.originalUserDetails = JSON.parse(JSON.stringify((this.userDetail)));

        this.business$.pipe(takeUntil(this.unsubscribe$)).subscribe((business) => {
          this.userBusiness = business?.businessId;
          if(this.userDetail?._id && this.userBusiness?._id){            
            console.log("getAllChatsThree", business);
            this.getAllChats(true);
            this.channelPermission = business?.permission?.mangeChannel === 'edit' || this.userDetail?._id === this.userBusiness?.userId;
            console.log('business123', business, this.channelPermission);
            this.userService.getBusinessUsers(business?.businessId?._id).subscribe((result)=>{
              console.log('business1234', result, this.channelPermission);
              this.usersList = result.data.filter(item=> item._id !== this.userDetail._id);
              this.usersList = this.usersList.map(({ _id, firstName, lastName })=>({id: _id, value: firstName + ' ' + lastName}));
              this.originalUsersList = [...this.usersList];
              // console.log('business123', this.usersList.map(({ _id, firstName, lastName })=>({_id, name: firstName + ' ' + lastName})));
            })
          }          
        });
      }
    });
  };

  getAllChats(type): void {
    console.log("running", this.userDetail._id, this.userBusiness._id);
    if(type){
      this.spinner.show();
    }
    this.chatService.getAllChats(this.userDetail._id, this.userBusiness._id).subscribe((resp)=>{
      console.log("running1", resp);
      resp.data.forEach(element => {
        element.chatUsers = element.chatUsers?.map(({ userId, startedDate })=>({_id: userId?._id, name: userId?.firstName + ' ' + userId?.lastName, profileImageName: userId?.profileImageName, startedDate}));
        element.chatCreatedBy = {_id: element.chatCreatedBy?._id, name: element.chatCreatedBy?.firstName + ' ' + element.chatCreatedBy?.lastName, profileImageName: element.chatCreatedBy?.profileImageName};
        if(element.chatType === 'Individual'){
          const name = element.chatUsers.filter(item=>item._id !== this.userDetail._id);
          element.chatTitle = name[0];
        }
      });
      this.individualChatList = resp.data.filter(item => item.chatType === "Individual");
      this.groupChatList = resp.data.filter(item => item.chatType === "Group");
      this.channelChatList = resp.data.filter(item => item.chatType === "Channel");
      this.allChatList = [...this.individualChatList, ...this.groupChatList, ...this.channelChatList];
      this.chatService.redirectionChatId.subscribe((id)=>{
        console.log('redirectionChatId', id);
        this.routeChatId = id;
      });
      console.log("this.isMobileDevice", this.isMobileDevice);
      
      if(this.allChatList){
        this.isFirstLoad = true;
        if (this.routeChatId) {
          const item = this.allChatList.find(item=>item._id === this.routeChatId);
          console.log('checkReloadIssue1', item);
          this.goToChat(item, this.userDetail?._id);
          this.chatService.setredirectionChatId(null);
        } else if(this.allChatList.length > 0 && !this.selectedChatToShow) {
          console.log('checkReloadIssue2');
          this.goToChat(this.allChatList[0], this.userDetail._id);
        } else if(this.selectedChatToShow) {
          console.log('checkReloadIssue3');
          this.goToChat(this.selectedChatToShow, this.userDetail?._id);
        } 
      }
      console.log("getAllChats", this.allChatList, this.output);
      console.log("checkMention", document.getElementsByClassName('ql-mention'));

      // document.getElementsByClassName('ql-mention')[0].innerHTML = '@';
      this.spinner.hide();
    }, (err)=>{
      this.spinner.hide();
    })
  }

  updateMessage(data: any): void {
    console.log('updateMessage', data);
    
    // this.feedback = '';
    // if(!!!data) return;
    const date = new Date().toDateString();

    const intervalThreshold = 2 * 60 * 1000;
    const allMessages = this.output[this.output?.length - 1];
    const lastMessage = allMessages?.messages[allMessages?.messages?.length - 1].createdAt;
    // console.log('sadfsadf', allMessages?.messages[allMessages?.messages?.length - 1]);
    const lastItem = allMessages?.messages[allMessages?.messages?.length - 1];
    
    const currentTime = new Date().getTime();
    const previousTime = new Date(lastMessage).getTime();
    const timeDifference = currentTime - previousTime;

    const showNameHeader = timeDifference > intervalThreshold || this.output?.length === 0 || lastItem.messageOwner._id !== data.messageOwner._id;
    // console.log(showNameHeader, lastMessage);

    const existingGroup = this.output.find(group => group.date === date);
    if (existingGroup) {
      existingGroup.messages.push({...data, showNameHeader});
    } else {
      this.output.push({ date, messages: [{...data, showNameHeader}] });
    }
    // console.log(this.output);
    
    setTimeout(() => {
      document.getElementById('lastMessage')?.scrollIntoView({
        block: 'nearest',
      });
      this.chatService.emit('markAsRead', { id: this.selectedChatToShow._id, userId: this.userDetail._id });
    });
    // console.log('updateMessage1', this.output);
  };

  updateFeedback(data: any): void {
    
    if(this.selectedChatToShow?._id === data.chatId && this.userDetail._id !== data.userId) {
      this.feedback = `${data.userName} is typing a message`;
    }
    
    setTimeout(()=>{
      this.feedback = '';
    }, 2000)
  }

  messageTyping(chatId){
    console.log("typing", chatId);
    this.chatService.emit('typing', {userName: this.userDetail.firstName, chatId, userId: this.userDetail._id});
    
  }

  sendMessage(){
    console.log(this.selectedChatToShow._id);
    console.log("message1234", this.message);
    
    if(this.message === ''){
      return;
    }
    console.log("kaise1");
    
    this.chatService.emit('chat', {message: this.message, chatType: this.selectedChatToShow.chatType, chatId: this.chatToShowId, messageOwner: {_id: this.userDetail._id, firstName: this.userDetail.firstName, lastName: this.userDetail.lastName, profileImageName: this.userDetail.profileImageName}, createdAt: new Date(), chatUsers: this.selectedChatToShow.chatUsers});
    if(this.mentionedItems.length > 0) {
      // this.chatService.emit('mention', this.mentionedItems);
      this.chatService.emit('notification', {
        entityId: this.selectedChatToShow._id,
        entityType: 'mention',
        message: `${this.userDetail.firstName + ' ' + this.userDetail.lastName} has mentioned you in a ${this.selectedChatToShow.chatType === 'Individual' ? 'Personal' : this.selectedChatToShow.channelGroupName} chat`,
        fromUser: this.userDetail._id,
        toUser: this.mentionedItems,
        businessId: this.userBusiness._id
      });
    } else {
      this.chatService.emit('notification', {
        entityId: this.selectedChatToShow._id,
        entityType: 'message',
        message: `${this.userDetail.firstName + ' ' + this.userDetail.lastName} has sent a message in ${this.selectedChatToShow.chatType === 'Individual' ? 'Personal' : this.selectedChatToShow.channelGroupName} chat`,
        fromUser: this.userDetail._id,
        toUser: this.mentionedItems,
        businessId: this.userBusiness._id
      });
    }

    this.chatService.emit('anyUpdate', {chatDetails: this.selectedChatToShow, sendBy: this.userDetail});
    // setTimeout(() => {
    // }, 5000);
    setTimeout(() => {
      document.getElementById('lastMessage')?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
      this.chatService.emit('getAllNotifications', this.userDetail._id);
    });
    this.message = '';
    this.feedback = '';
    this.mentionedItems = [];
  };

  scrollToBottom(){
    setTimeout(() => {
      const element = document.getElementById("lastMessage");

      document.getElementById('lastMessage')?.scrollIntoView({
        block: 'nearest',
      });
      // const yOffset = element.getBoundingClientRect().top + window.scrollY;
      // window.scroll({
      //   top: yOffset,
      //   behavior: 'smooth'
      // });
    });
  }

  submitCreate(type): void {
    // if(type === 'Individual' && (!this.initialiseMessage || !this.newChatUser)){
    //   this.individualSubmit = true;
    //   return;
    // }
    this.spinner.show();
    console.log(this.createChatType, this.initialiseMessage, this.channelType, this.channelGroupName, this.newChatUser);
    const chatUsersArray = this.newChatUser ? Array.isArray(this.newChatUser) ? this.newChatUser : [this.newChatUser] : '';
    const payload = {
      businessId: this.userBusiness._id,
      chatUsers: [this.userDetail._id, ...chatUsersArray],
      chatType: this.createChatType,
      channelType: this.channelType,
      channelGroupName: this.channelGroupName,
      chatCreatedBy: this.userDetail._id,
      chatSentTo: this.createChatType === 'Individual' ? this.newChatUser : null
    }
    console.log("channelGroupName", payload);
    
    this.chatService.createNewChat(payload).subscribe((result)=>{
      console.log(result);
      this.chatToShowId = result.data._id;
      if(type === 'Individual'){
        this.selectedChatToShow = result.data;
        this.isMemberOfChat = true;
        console.log("selectedChatToShow", this.selectedChatToShow);
        
        this.chatService.emit('chat', {message: this.initialiseMessage, chatId: this.chatToShowId, messageOwner: {_id: this.userDetail._id, firstName: this.userDetail.firstName, lastName: this.userDetail.lastName}, createdAt: new Date(), chatUsers: result.data.chatUsers}); 
        this.initialiseMessage = '';
      }

      this.chatService.emit('chatCreated', result.data);
      // this.goToChat(this.chatToShowId);
      this.showDialog = false;
      this.channelGroupName = '';
      this.newChatUser = null;
      this.initialiseMessage = null;
      this.spinner.hide();
      this.getAllChats(true);
    }, (err)=>{
      this.spinner.hide();
      console.log();
    })
  }

  goToChat(chatDetails, userId): void {
    this.spinner.show();
    this.selectedByUser = userId;
    this.message = '';
    this.searchText = '';
    this.output = null;
    if(userId === this.userDetail._id) {
      // console.log("changed1", userId === this.userDetail._id);
      this.selectedChatToShow = this.allChatList?.filter(item=>item._id === chatDetails?._id)[0];
      this.chatDetailsToEdit = {...this.selectedChatToShow};
      this.isMemberOfChat = this.selectedChatToShow?.chatUsers.some(item=>item._id === this.userDetail._id)
      console.log("changed3", this.isMemberOfChat);
      // console.log('sendgoToChat1',this.selectedChatToShow, this.allChatList.filter(item=>item._id === chatDetails._id)[0]);
      // if(this.selectedChatToShow.chatType === "Channel" || this.selectedChatToShow.chatType === "Group") {
      //   this.images
      // }
      this.chatToShowId = chatDetails?._id;
      
      if(this.allChatList){
        const startedDate = new Date(chatDetails?.chatUsers?.find(item=>item.userId === this.userDetail._id || item._id === this.userDetail._id)?.startedDate);
        if(startedDate) {
          console.log("sendingDate", startedDate);
          this.chatService.emit('getChat', {id: chatDetails?._id, userId: this.userDetail._id, startedDate: startedDate, chatType: chatDetails?.chatType, channelType: chatDetails?.channelType});
        }
      }
      this.usersList = this.selectedChatToShow?.chatUsers.map(({ _id, name })=>({id: _id, value: name} ));
      this.remainingUsersList = this.originalUsersList?.filter((item)=> {
        return !this.usersList?.some((user) => item.id === user.id);
      });
      if(!this.isFirstLoad) {
        this.closeSidebar();
      } else {
        this.isFirstLoad = false;
      }
      this.chatService.emit('markAsRead', { id: this.selectedChatToShow?._id, userId: this.userDetail._id });
    }
    console.log('sendgoToChat3', chatDetails);
    // this.chatService.emit('joinRoom', { chatId: id });
  }

  selectedChatType(type): void {
    this.createChatType = type;
    this.createChatStep = 'two';
    
    console.log("selectedChatType", this.originalUsersList, this.individualChatList);
    if(type === 'Individual') {
      this.usersList = this.originalUsersList?.filter((item)=> !this.individualChatList.some((i)=>i.chatSentTo === item.id || (i.chatCreatedBy._id === item.id && i.chatSentTo === this.userDetail._id)));
      console.log("allMyUsers", this.usersList, this.originalUsersList, this.individualChatList);
      
    }
  }

  filterExistingUsers(): void {
    this.usersList = this.originalUsersList?.filter((item)=> !this.selectedChatToShow.chatUsers.some((i)=>i._id === item._id));
  }

  joinChannel(): void {
    this.chatService.emit('joinChannel', {chatId: this.selectedChatToShow._id, memberId: this.userDetail._id})
  }

  addMember(): void {
    console.log(this.membersToAdd);
    
    const payload = {
      memberId: this.membersToAdd
    };
    this.chatService.addMembers(payload, this.selectedChatToShow._id).subscribe((resp)=>{
      console.log("payload", resp);
      if(resp.success){
        this.addMoreDialog = false;
        this.editDialog = false;
        this.editName = false;
        this.editDescription = false;
        this.membersToAdd = null;
        resp.data.updateType = 'add';
        this.chatService.emit('chatUpdated', resp.data);
        this.getAllChats(true);
      }
    })
  }

  removeMember(): void {
    const payload = {
      memberId: this.memberToRemove._id,
      memberForAdmin: this.toMakeAdmin
    };
    console.log(payload);
    this.chatService.removeMembers(payload, this.selectedChatToShow._id).subscribe((resp)=>{
      console.log("payload", resp);
      if(resp.success){
        this.warningDialog = false;
        this.editDialog = false;
        this.editName = false;
        this.editDescription = false;
        this.memberToRemove = null;
        this.toMakeAdmin = null;
        this.selectedChatToShow = null;
        this.leaveGroup = false;
        resp.data.updateType = 'remove';
        this.chatService.emit('chatUpdated', resp.data);
        this.getAllChats(true);
      }
    });
  }

  decideNextStep(): void {
    this.createChatStep = this.createChatType === 'Group' ?  'four' : 'three';
    console.log("this.createChatStep", this.createChatStep); 
  }

  saveChatDetails(){
    console.log('check1');
    this.spinner.show();
    const payload = {
      channelDescription: this.chatDetailsToEdit.channelDescription,
      channelGroupName: this.chatDetailsToEdit.channelGroupName
    }
    this.chatService.updateChatDetails(payload, this.selectedChatToShow._id).subscribe((resp)=>{
      this.spinner.hide();
      if(resp.success){
        this.editDialog = false;
        this.editName = false;
        this.editDescription = false;
        this.getAllChats(true);
        this.goToChat(resp.data, this.userDetail._id);
      }
      console.log("resp", resp);
    }, (err)=>{
      this.spinner.hide();
    })
    
  }

  getFilteredMessages(event) {
    this.searchText = event.target.value;
  }

  searchTextGlobally() {
    console.log('searchTextGlobally');
    
    this.chatService.emit("globalSearch", {userId: this.userDetail._id, text: this.globalSearchText})
  }

  filterAdminMembers(){
    this.membersForAdmin = this.selectedChatToShow?.chatUsers.filter(item=>item._id !== this.userDetail._id);
  }

  goToNotification(item): void {
    switch (item.entityType) {
      case "taskAssigned":
        const payload = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payload);
        this.route.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "sharedFile":
        const payloadThree = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadThree);
        this.route.navigate([`/files/shared`]);
      break;
      case "taskReminder":
        const payloadTwo = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadTwo);
        this.route.navigate([`/task/view-task/${item.entityId}`]);
        break;
      case "mention":
        console.log(this.allChatList, item);
        
        const chat = this.allChatList.find((resp)=>resp._id === item.entityId);
        console.log(chat, item);
        const payloadOne = {
          seen: true,
          updateId: item._id
        }
        this.chatService.emit('updateNotification', payloadOne);
        this.goToChat(chat, this.userDetail._id); 
        break;
    }
  }

  logout(): void {
    this.spinner.show();
    this.userService.logoutAPI().subscribe((resp) => {
      this.spinner.hide();
      if (resp.success) {
        this.userService.logout();
      }
    }, (error) => {
      this.spinner.hide();
      console.log(error);
      this.toastr.error('Something went wrong');
    })
  }

  checkIfPublic(): void {
    console.log("checkIfPublic", this.channelType);
    
  }

  messageScrollIntoView(message){
    console.log("scroll", message);
    
    this.globalSearchMessageId = message._id;
    const chat = this.allChatList.find((resp)=>resp._id === message.chatId._id);
    this.goToChat(chat, this.userDetail?._id);
  }

  downloadApp(): void {
    window.open("https://onelink.to/q8xxk4", '_blank')
  }

  goToBusiness(): void {
    localStorage.removeItem('selectedBusiness')
    this.route.navigate(['/auth/select-business'])
  }

  handleTextChange(event: any) {
    const containerHeight = this.editor.elementRef.nativeElement.offsetHeight;
    const chatWindow = document.getElementById("chat-window");
    const windowHeight = window.innerHeight;
    // if(containerHeight > 104) {
    //   console.log("check1");
      
    //   const newChatWindowHeight = (windowHeight - containerHeight) - 99;
    //   chatWindow.style.height = `${newChatWindowHeight}px`;
    // } else {
    //   console.log("check2");
    //   chatWindow.style.height = `calc(100vh - 202px)`
    // }
    this.scrollToBottom();
  }
}
