<div class="flex justify-center px-14 py-11 bg-white">
  <div class="w-full flex flex-col space-y-4">
    <div>
      <div class="flex items-center justify-between ms-auto">
        <app-button
          appAccessControl
          class="ms-auto"
          moduleType="sales"
          accessType="edit"
          (click)="editCustomer(viewCustomerDetail._id)"
          buttonText="{{ 'Edit Customer' | translate }}"
          type="primary"
          >{{ "Edit Customer" | translate }}</app-button
        >
      </div>
      <ul
        class="w-1/12 hidden mb-4 h-12 text-sm font-medium text-center text-gray-500 rounded-lg shadow sm:flex"
      >
        <li
          class="w-full flex bg-[#F0F5FF] text-black items-center focus-within:z-10 hover:text-gray-700 hover:bg-gray-50 cursor-pointer border-r border-gray-200"
          tabindex="1"
        >
          <div
            class="inline-block rounded-s-lg w-full rounded-e-lg p-4 focus:ring-4 focus:ring-blue-300 focus:outline-none whitespace-nowrap"
          >
            {{ "Overview" | translate }}
          </div>
        </li>
      </ul>
      <div class="flex mt-5">
        <div class="flex-col flex gap-4 w-1/3">
          <div class="rounded-lg p-5 me-3 bg-input-light shadow-card">
            <div class="m-auto flex justify-center">
              <svg
                width="153"
                height="128"
                viewBox="0 0 153 128"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M132.7 101.04C129.1 105.36 124.82 109.2 120.1 112.4C110.66 118.8 99.3 122.88 87.26 124.4C84.82 124.72 82.34 124.92 79.9 125C75.3 125.16 70.66 124.96 66.1 124.4C53.74 122.84 41.7 118.56 31.78 111.36C28.66 109.12 25.74 106.56 23.1 103.72C8.98001 88.5599 2.54001 65.0399 9.10001 47.5999C15.66 30.0799 31.62 16.68 50.86 9.43995C70.1 2.15995 92.58 1.07995 109.14 7.27995C125.66 13.48 139.74 28.8799 144.54 47.0799C149.3 65.2799 144.82 86.2799 132.7 101.04Z"
                  fill="url(#paint0_linear)"
                />
                <path
                  d="M112.34 98.1199V103.4H39.66V98.3999C39.66 91.1999 43.06 85.9599 49.9 83.6399L67.7 77.2799C69.54 72.7199 68.66 67.0399 68.06 66.6399C63.34 63.4799 59.3 56.6399 58.62 50.9999C57.94 45.3599 58.66 41.2399 58.42 35.6399C58.42 35.6399 58.58 20.5599 76.1 20.5599C93.62 20.5599 93.9 35.6399 93.9 35.6399C93.9 42.1199 94.3 44.9999 94.18 50.9999C94.06 56.6799 89.46 64.1999 85.94 66.5199C85.3 66.9599 84.22 72.6799 86.34 77.3199L102.66 83.5599C109.26 85.9199 112.34 91.0399 112.34 98.1199Z"
                  fill="url(#paint1_linear)"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear"
                    x1="6.50001"
                    y1="64.1216"
                    x2="146.393"
                    y2="64.1216"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#9AE5FC" />
                    <stop offset="0.8391" stop-color="#E0F4FF" />
                  </linearGradient>
                  <linearGradient
                    id="paint1_linear"
                    x1="76.0053"
                    y1="-8.60662"
                    x2="76.0053"
                    y2="112.42"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#E84855" />
                    <stop offset="1" stop-color="#744AF0" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
            <p class="Poppins-Medium py-3 text-lg">
              {{ "Primary Contact" | translate }}
            </p>
            <div class="">
              <p class="Poppins-Medium text-lg">
                {{ viewCustomerDetail?.customerName }}
              </p>
              <p class="text-lg">{{ viewCustomerDetail?.email }}</p>
              <p class="text-lg">{{ viewCustomerDetail?.mobileNumber }}</p>
            </div>
          </div>

          <div class="rounded-lg p-5 me-3 bg-input-light shadow-card">
            <div class="">
              <p class="Poppins-Bold text-lg">{{ "Balance" | translate }}</p>
              <p class="text-2xl Poppins-Medium">
                {{
                  balance
                    | currency
                      : currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="w-2/3">
          <div class="grid grid-cols-3 gap-6 leading-0 text-[#202020CC]">
            <div class="bg-input-light shadow-card rounded-7 p-5">
              <p class="text-base Poppins-Medium">
                {{ "Paid" | translate }}
              </p>
              <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
                {{
                  customerStats?.paidInvoice?.total
                    | currency
                      : currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </h3>
              <p class="text-sm">
                {{ "from" | translate }}
                {{ customerStats?.paidInvoice?.count }}
                {{ "invoices" | translate }}
              </p>
            </div>
            <div class="bg-input-light shadow-card rounded-7 p-5">
              <p class="text-base Poppins-Medium">
                {{ "Total Overdue" | translate }}
              </p>
              <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
                {{
                  customerStats?.overdueInvoice?.total
                    | currency
                      : currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </h3>
              <p class="text-sm">
                {{ "from" | translate }}
                {{ customerStats?.overdueInvoice?.count }}
                {{ "invoices" | translate }}
              </p>
            </div>
            <div class="bg-input-light shadow-card rounded-7 p-5">
              <p class="text-base Poppins-Medium">
                {{ "Total Invoice amount" | translate }}
              </p>
              <h3 class="text-[30px] Poppins-SemiBold text-[#030723B2]">
                {{
                  customerStats?.totalInvoice?.total
                    | currency
                      : currencyDetails?.currencySymbol
                      : "symbol-narrow"
                      : number
                }}
              </h3>
              <p class="text-sm">
                {{ "from" | translate }}
                {{ customerStats?.totalInvoice?.count }}
                {{ "invoices" | translate }}
              </p>
            </div>
          </div>
          <app-table-container
            [tableHeadings]="tableHeadings"
            [tableData]="tableData"
            [keys]="tableKeys"
            [actionTemplate]="actions"
          >
            <ng-template let-item #actions>
              <td class="pe-8 text-end">
                <app-dropdown [isFromSetting]="true" #dropdown>
                  <div
                    button
                    (click)="dropdown.open = true"
                    (clickOutside)="dropdown.open = false"
                  >
                    <div
                      class="h-8 w-8 rounded-[4px] flex justify-center items-center border-2 border-[#DCDCDC]"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        class="h-4 w-4"
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                      >
                        <path
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke-width="2"
                          d="M19 9l-7 7-7-7"
                        />
                      </svg>
                    </div>
                  </div>
                  <div options class="new-shadow p-1 text-start">
                    <div
                      (click)="viewInvoice(item._id)"
                      class="cursor-pointer pl-2 py-2 rounded-lg text-sm hover:bg-[#F0F5FF]"
                    >
                      {{ "View" | translate }}
                    </div>
                  </div>
                </app-dropdown>
              </td>
            </ng-template>
          </app-table-container>
        </div>
      </div>
    </div>
  </div>
</div>
