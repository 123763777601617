import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { HttpService } from 'src/app/shared/services/http.service';
import { PurchaseReducerState } from '../../state/reducers';
// import { SalesReducerState } from '../../state/reducers';
import * as ProductsActions from '../../state/actions/product.action';

@Injectable({
  providedIn: 'root'
})
export class PurchaseProductsService {

  constructor(private httpService: HttpService,
              private store: Store<PurchaseReducerState>)
   {}


  createProducts(body): Observable<any> {
    return this.httpService.post(`/sales/addProduct/${body._id}`, body);
  }
  listProduct(businessId): Observable<any> {
    return this.httpService.post(`/sales/getProduct/${businessId}`, {});
  }
  updateProduct(body): Observable<any> {
    return this.httpService.put(`/sales/updateProduct/${body._id}`, body);
  }
  deleteProducts(id): Observable<any> {
    return this.httpService.delete(`/sales/deleteProduct/${id}`);
  }

  addProductToStore(product): void {
    this.store.dispatch(ProductsActions.AddProduct({product}));
  }

  deleteProductInStore(id): void {
    this.store.dispatch(ProductsActions.DeleteProduct({_id: id}));
  }

  updateProductInStore(product): void {
    this.store.dispatch(ProductsActions.UpdateProduct({product}));
  }
}
