import { Location } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DiscountCodeService } from '../../discount-code.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-view-discount-code',
  templateUrl: './view-discount-code.component.html',
  styleUrls: ['./view-discount-code.component.scss']
})
export class ViewDiscountCodeComponent implements OnInit {

  constructor(
    private location : Location,
    private router : ActivatedRoute,
    private discountCodeService : DiscountCodeService,
    private spinner : NgxSpinnerService
  ) { }
  codeId:any;
  codeDetails:any;
  tableHeadings = [
    'Mobile Number',
    'Customer Name',
    'Customer Email',
  ]
  tableKeys = [
    'mobileNumber',
    'name',
    'email'
  ]
  tableData = []
  ngOnInit(): void {
    this.codeId = this.router.snapshot.paramMap.get('id')
    this.loadDiscountCodeDetails(this.codeId)
  }
  loadDiscountCodeDetails(codeId) {
    this.spinner.show();
    this.discountCodeService.getDiscountCodeDetails(codeId).subscribe({
      next : (response)=>{
        this.spinner.hide();
        this.codeDetails = response.data
        this.tableData = this.codeDetails.usedByMobile.map((customer)=>{
          return {
            mobileNumber : customer.contact.mobileNumber,
            name : customer.firstName + ' ' + customer.lastName,
            email :  customer.email
          }
        })
        console.log(response)
      },
      error : (error)=>{
        this.spinner.hide()
        console.log(error)
      }
    })
  }
  back() {
    this.location.back();
  }
}
