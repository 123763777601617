import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  private baseUrl = environment.apiBaseURL;
  AUTH_TOKEN = 'auth_token';

  get(url: string, params?: any): Observable<any> {
    const data = {params, headers: this.getToken(), observe: 'response'};
    return this.http
    .get(this.baseUrl + url, {params, headers: this.getToken(), observe: 'response'})
    .pipe(
      map((resp) => {
        return resp.body;
      }),
      // catchError((error) => {
      //   if (error?.error?.status === 401 && error?.error?.message === 'Token Expired') {
      //     return retry(1)
      //   }
      // })
    );
  }

  getPdf(url): Observable<any> {
    return this.http.get(this.baseUrl + url, { responseType: 'arraybuffer' });
  }

  post(url, body): Observable<any>{
    return this.http
    .post(this.baseUrl + url, body, { headers: this.getToken(), observe: 'response' })
    .pipe(
      map((resp) => {
        return resp.body;
      })
    );
  }
  patch(url, body): Observable<any>{
    return this.http
    .patch(this.baseUrl + url, body, { headers: this.getToken(), observe: 'response' })
    .pipe(
      map((resp) => {
        return resp.body;
      })
    );
  }
  

  postPdf(url, body): Observable<any> {
    return this.http.post(this.baseUrl + url, body, { responseType: 'arraybuffer' });
  }

  put(url, body): Observable<any>{
    return this.http.put(this.baseUrl + url, body, { headers: this.getToken() });
  }

  putMultipart(url, body): Observable<any>{
    return this.http
    .put(this.baseUrl + url, body, { headers: this.getTokenForMultiPart(), observe: 'response' })
    .pipe(
      map((resp) => {
        return resp.body;
      })
    );
  }

  delete(url: string, body?: any): Observable<any> {
    return this.http.delete(this.baseUrl + url, {body: body, headers: this.getToken()});
  }

  private getToken(): { [header: string]: string | string[]; } {
    const token = localStorage.getItem(this.AUTH_TOKEN) ? localStorage.getItem(this.AUTH_TOKEN) : '';
    return { Authorization: token ? `Bearer ${token}` : 'undefined','Content-Type':'application/json'};
  }


  postMultipart(url, body): Observable<any> {
    return this.http
      .post(this.baseUrl + url, body, {
        headers: this.getTokenForMultiPart(),
        observe: 'response',
      })
      .pipe(
        map((resp) => {
          return resp.body;
        })
      );
  }


  private getTokenForMultiPart(): { [header: string]: string | string[] } {
    const token = localStorage.getItem(this.AUTH_TOKEN)
      ? localStorage.getItem(this.AUTH_TOKEN)
      : '';
    return {
      Authorization: token ? `Bearer ${token}` : 'undefined',
      enctype: 'multipart/form-data',
    };
  }
}
