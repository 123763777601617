import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-profile-management',
  templateUrl: './profile-management.component.html',
  styleUrls: ['./profile-management.component.scss']
})
export class ProfileManagementComponent implements OnInit {

  constructor() { }

  list1: Array<string> = ['Transaction', 'Estimate', 'Invoice', 'Recurring Invoice', 'Bill', 'Customer', 'Vendor', 'Product or Service'];
  list2: Array<string> = ['CSV', 'PDF', 'HTML', 'JS'];
  list3: Array<string> = ['Food', 'Games', 'Movies'];

  ngOnInit(): void {
  }

}
