import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { FolderListingComponent } from './components/folder-listing/folder-listing.component';
import { FileListingComponent } from './components/file-listing/file-listing.component';
import { FileViewComponent } from './components/file-view/file-view.component';

const routes: Routes = [
  {
    path: '',
    component: FolderListingComponent
  },
  {
    path: 'folder/:documentType',
    component: FileListingComponent
  },
  {
    path: 'view-file',
    component: FileViewComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class StorageRoutingModule { }
